import styled from "styled-components";

export const ProjectFormModalComponent = styled.div`
  background-color: var(--white);
  display: grid;
  gap: 50px;
  grid-template-rows: 100px calc(100% - 150px);
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 2;

  &>header {
    align-items: flex-end;
    display: flex;
    height: 100px;
    justify-content: space-between;
    width: 100%;

    & h1 { // 타이틀
      font-size: 40px;
    }

    & .btn-close { // 닫기 버튼
      height: 60px;
      width: 30px;

      &:hover {background: unset;}

      &>svg {
        height: 30px;
        width: 30px;
      }
    }
  }

  &>div {
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;    
    
    &>section {
      align-content: flex-start;
      display: grid;
      gap: 50px;
      height: 100%;
      overflow-y: auto;
      width: 100%;

      & .section-form { // 입력 폼 섹션
        align-content: flex-start;
        border: 1px solid #C6C6C6;
        border-radius: 12px;
        box-sizing: border-box;
        gap: 20px;
        display: grid;
        height: fit-content;
        padding: 50px;

        &>h2 { // 폼 타이틀
          padding-block-end: 10px;
        }

        & .form-content { // 폼 컨텐츠
          align-content: flex-start;
          display: grid;
          gap: 10px;

          & h4 { // 인풋 이름
            font-weight: 400;
          }
          
          & input[type="text"] { // 텍스트 인풋 스타일
            background-color: var(--white);
            border: 1px solid #717171;
            border-radius: 8px;
            padding: 16px;
            max-width: 60%;

            &:focus {border: 2px solid #246BEB;}
          }

          &.color { // 색상 선택
            align-items: center;
            display: flex;
            gap: 15px;
            justify-content: flex-start;
            position: relative;

            & h4 { // 인풋 이름
              line-height: 45px;
            }

            & .current-color { // 현재 색상
              border-radius: 50%;
              box-sizing: border-box;
              height: 25px;
              width: 25px;
            }
            & .current-btn { // 현재 색상 버튼
              align-items: center;
              box-sizing: border-box;
              display: flex;
              gap: 10px;
              height: fit-content;
              justify-content: space-between;
              padding: 10px 10px;
              position: relative;
              width: fit-content;
  
              &:hover {
                background: unset;
                background-color: #f1f1f1;
              }
              & svg { // 화살표
  
              }
            }

            & .color-select { // 색상 선택 모달
              align-items: center;
              background-color: var(--white);
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              box-sizing: border-box;
              display: flex;
              gap: 10px;
              flex-wrap: wrap;
              padding: 30px;
              position: absolute;
              left: 150px;
              width: 380px;

              

              & .btn-close { // 닫기 버튼
                height: fit-content;
                position: absolute;
                right: 15px;
                top: 15px;
                width: fit-content;
                &:hover {background: unset;}
              }
  
              & .color-btn { // 색상 버튼
                border-radius: 50%;
                box-sizing: border-box;
                cursor: pointer;
                height: 35px;
                position: relative;
                width: 35px;
  
                &.active::after, &:hover::after { // 현재 색상, 호버 색상 체크 표시
                  content: '✔';
  
                  align-items: center;
                  color: var(--white);
                  display: flex;
                  font-size: 20px;
                  justify-content: center;
                  left: 50%;
                  position: absolute;
                  top: 50%;
                  transform: translate(-50%, -50%);
                }
              }

              #custom-color {display: none;} // 커스텀 인풋 숨기기
              & .custom-btn { // 사용자 정의 색상 버튼
                align-items: center;
                border: 1px solid #1D1D1D;
                border-radius: 50%;
                box-sizing: border-box;
                cursor: pointer;
                display: flex;
                height: 35px;
                justify-content: center;
                width: 35px;

                & svg { // 플러스 아이콘
                  color: #1D1D1D;
                }
              }
            }
          }
        }
      }
      & .section-action { // 액션 버튼 섹션
        align-items: flex-start;
        background-color: var(--white);
        bottom: 0;
        box-sizing: border-box;
        display: flex;
        height: 100px;
        justify-content: space-between;
        padding-block-start: 10px;
        position: sticky;
        width: 100%;

        & button {
          background-color: #246BEB;
          border-radius: 8px;
          box-sizing: border-box;
          color: var(--white);
          height: fit-content;
          padding: 10px 16px;
          width: fit-content;
          
          &.btn-save {width: 120px;}
          &.btn-delete {
            background-color: #EB003B;
            width: 120px;
          }
          &.btn-cancel {
            background-color: var(--white);
            border: 1px solid #1D1D1D;
            color: #1D1D1D;
            &:hover {background: unset;}
          }
        }
      }
    }
  }
`;