import styled from "styled-components";

export const ClusterMainComponent = styled.section`
  background-color: var(--white);
  display: grid;
  grid-template-rows: 100px calc(100% - 100px);
  height: 100svh;
  overflow: hidden;
  position: relative;
  width: 100svw;
  
  &>section.main {
    display: grid;
    grid-template-columns: 280px calc(100% - 280px);
    height: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 90%;
    
    &>section:not(section.home) {
      max-width: 1540px;
      width: auto;
    }
    &>section.home {
      margin: unset;
      max-width: 1540px;
    }
  }

  &>section.login {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    padding-block-end: 100px;

    &>h1 {
      font-size: 40px;
      margin-inline: auto;
      max-width: 1240px;
      width: 95%;
    }
    
    &>div {
      align-items: center;
      border-block: 1px solid #b0b0b0;
      display: flex;
      gap: 20px;
      height: fit-content;
      justify-content: space-between;
      margin-inline: auto;
      max-width: 1240px;
      overflow: hidden;
      padding-block: 100px;
      padding-inline: 50px;
      width: 95%;

      &>div:first-of-type {
        display: grid;
        gap: 30px;
        width: 45%;
  
        &>div:first-of-type {
          display: grid;
          gap: 28px;
  
          & label {
            display: grid;
            gap: 8px;
  
            & p {
              font-size: 16px;
              font-weight: 600;
            }
  
            & input {
              border: 1px solid #C6C6C6;
              border-radius: 8px;
              box-sizing: border-box;
              padding: 12px 16px;
            }
          }
        }
        &>button {
          background-color: #246BEB;
          border-radius: 8px;
          color: var(--white);
          height: 60px;
          width: 100%;
        }
        &>div:last-of-type {
          align-items: center;
          display: flex;
          font-size: 16px;
          gap: 20px;
          justify-content: center;
          width: 100%;
  
          & div {
            background-color: #C6C6C6;
            height: 60%;
            width: 1px;
          }
        }
      }
      &>div:nth-of-type(2) {
        background-color: #C6C6C6;
        width: 1px;
      }
      &>div:last-of-type {
        align-content: flex-start;
        display: grid;
        height: 340px;
        gap: 8px;
        width: 45%;
        
        & p {
          font-size: 16px;
          font-weight: 600;
        }
  
        &>button {
          background-color: #003675;
          border-radius: 8px;
          color: var(--white);
          height: 60px;
          width: 100%;
        }
      }
    }
  }
`;