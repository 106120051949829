import React, { useEffect, useState } from 'react';

import { FolderStyle } from './Folder.style';
import { checkNullArray } from 'components/checkValues/checkValues';

const Folder = ({ 
  fileCategory, 
  depth = 0, 
  currentFolder, 
  handleCurrentFolder, 
  reset, 
  setReset 
}) => {
  const [_toggle, setToggle] = useState(false);
  useEffect(() => { }, [_toggle]);
  const handleToggle = () => { setToggle(prev => !prev); }

  useEffect(() => {
    if(reset) {
      setToggle(() => {return false});
      setReset(() => {return false});
    }
  }, [reset])

  return (
    <>
      {checkNullArray(fileCategory.childrenFileCategory, false) ? (
        <FolderStyle depth={depth}
          className={currentFolder.fileCategoryId === fileCategory.fileCategoryId ? 'current' : ''}
          onClick={(e) => {
            e.stopPropagation();
            handleCurrentFolder(fileCategory);
          }}
        >
          <div className={currentFolder.fileCategoryId === fileCategory.fileCategoryId ? 'current' : ''}
            onClick={handleToggle}
          >
            <div data-toggle={_toggle ? 'open' : 'close'}></div>
            <p>{fileCategory.fileCategoryName}</p>
          </div>
          <div>
            {_toggle && fileCategory.childrenFileCategory.map((child, index) => {
              return (
                <Folder
                  key={depth + '_' + index + '_Folder'} 
                  fileCategory={child} 
                  depth={depth + 1} 
                  currentFolder={currentFolder} 
                  handleCurrentFolder={handleCurrentFolder} 
                  reset={reset}
                  setReset={setReset}  
                />
              );
            })}
          </div>
        </FolderStyle>
      ) : (
        <FolderStyle depth={depth}
          className={currentFolder.fileCategoryId === fileCategory.fileCategoryId ? 'current' : ''}
          onClick={(e) => {
            e.stopPropagation();
            handleCurrentFolder(fileCategory);
          }}
        >
          <div className={currentFolder.fileCategoryId === fileCategory.fileCategoryId ? 'current' : ''}
            onClick={handleToggle}
          >
            <div data-toggle={'open'}></div>
            <p>{fileCategory.fileCategoryName}</p>
          </div>
        </FolderStyle>
      )}
    </>
  );
};

export default Folder;