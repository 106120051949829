import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

import { workActions_setCurrentMessage, workActions_setWorkCase } from 'store/modules/actions/default/workActions';

import { sensorDataApi } from 'api/apis/sensorDataApi';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import { zoomMinus, zoomPlus } from 'components/icons/src';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px calc(100% - 205px) 150px;
  height: 90%;
  overflow: hidden;
  width: 95%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const UpdateButton = styled.button`
  position: absolute;
  right: 55px;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;

    input, textarea {
      border: 1px solid var(--gray-200);
      border-radius: 5px;
      box-sizing: border-box;
      color: var(--MainBlue);
      font-size: 20px;
      font-weight: 600;
      height: 50px;
      min-width: 200px;
      padding: 5px 15px;
      width: 98%;

      &[name="realCamber"] {padding-right: 120px;}
    }
    textarea {height: 95%;}

    & .realCamber {
      display: flex;
      gap: 3px;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      z-index: auto;
      & button:last-child {background-color: var(--MainNavy)}
    }
  }
`;
const CamberSection = styled.div`
  background-color: var(--MainNavy);
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  gap: 10px;
  height: 150px;
  justify-content: flex-start;
  overflow-x: auto;
  width: 100%;

  & .monitoring {
    border-right: 2px solid var(--white);
    box-sizing: border-box;
    color: var(--MainYellow);
    display: grid;
    grid-template-rows: 35px auto;
    height: 100%;
    min-width: 300px;
    padding: 20px;
    width: 30%;
    
    & p {
      align-items: center;
      display: flex;
      font-size: 3em;
      font-weight: 600;
      justify-content: flex-start;
    }
  }
`;

const ViewerButtons = styled.div`
  z-index: 999;
  position: fixed;
  right: 126px;
  bottom: 24px;
  column-gap: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ViewMinus = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  border-radius: 50%;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 40px;
  font-weight: 600;
  justify-content: center;
  height: 40px;
  width: 40px;
`;
const ViewMinusIcon = styled.div`
  background-color: var(--white);
  height: 30px;
  width: 30px;
  mask-image: url(${zoomMinus});mask-repeat: no-repeat;mask-size: contain;
  --webkit-mask-image: url(${zoomMinus});--webkit-mask-repeat: no-repeat;--webkit-mask-size: contain;
`;
const ViewPlus = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  border-radius: 50%;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 40px;
  font-weight: 600;
  justify-content: center;
  height: 40px;
  width: 40px;
`;
const ViewPlusIcon = styled.div`
  background-color: var(--white);
  height: 30px;
  width: 30px;
  mask-image: url(${zoomPlus});mask-repeat: no-repeat;mask-size: contain;
  --webkit-mask-image: url(${zoomPlus});--webkit-mask-repeat: no-repeat;--webkit-mask-size: contain;
`;

const GSBLStatus = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { userReducer, workReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const workOrderLog = props.content;
  const [_processLogList, setProcessLogList] = useState(props.content.processLogList.filter((thisProcess) => thisProcess.processTypeName === 'BL'));

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    userId: userReducer.user.id,
    workOrderStatus: workOrderLog.workOrderStatus,
    workOrderLogId: workOrderLog.workOrderLogId,
    workOrderCode: workOrderLog.workOrderCode,
    workOrderName: workOrderLog.workOrderName,
    workOrderScheduledStartDate: DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledStartDate, '')),
    workOrderScheduledEndDate: DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledEndDate, '')),
    accountId: workOrderLog.accountId,
    workPlace: workOrderLog.workPlace,
    detailedWorkPlace: workOrderLog.detailedWorkPlace,
    message: workOrderLog.message,
    remark: workOrderLog.remark,
    workOrderCustomizedContent: workOrderLog.workOrderCustomizedContent,
    processLogList: [],
    deleteProcessList: [],
  });

  const [_updateWorkOrderList, setUpdateWorkOrderList] = useState([]);
  const [_deleteWorkOrderList, setDeleteWorkOrderList] = useState([]);

  const [_CPData, setCPData] = useState([]);
  const [_CLData, setCLData] = useState([]);

  const [_viewScale, setViewScale] = useState(100);

  const [_realtimeBar, setRealtimeBar] = useState(0); // 실시간 압력값
  const [_realtimeLength, setRealtimeLength] = useState(0); // 실시간 절곡값
  const [_realtimeCamber, setRealtimeCamber] = useState(0); // 실시간 캠버값

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('501') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(workActions_setWorkCase('process'));

    const processLogList = props.content.processLogList.filter((thisItem) => thisItem.processTypeName === 'BL');
    setProcessLogList(processLogList);
    setCPData(props.content.processLogList.filter((thisItem) => thisItem.processTypeName === 'CP'));
    setCLData(props.content.processLogList.filter((thisItem) => thisItem.processTypeName === 'CL'));
    const updateWorkOrderList = processLogList.map((thisItem) => {
      return {
        existProcessOutElement: false,
        processTypeName: 'BL',
        processStatus: thisItem.processStatus,
        processLogId: thisItem.processLogId,
        processingCount: thisItem.processingCount,
        processCustomizedContent: JSON.parse(thisItem.processCustomizedContent),
        deleteTaskIds: [],
        taskLogList: [],

        processName: thisItem.processName,
        processCode: thisItem.processCode,
      };
    });
    setUpdateWorkOrderList(updateWorkOrderList);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (workReducer.connectStatus === 'connect') {
      if (!checkNullObject(workReducer.currentMessage, false)) return;

      if (workReducer.currentMessage.userId === userReducer.user.id && checkEmptyNull(workReducer.currentMessage.errorMessage, false)) {
        return dispatch(workActions_setCurrentMessage({}));;
      }

      if (workReducer.currentMessage.workOrderLogId === props.content.workOrderLogId) {
        if (workReducer.currentMessage.type === '/work') applyStatus();
        if (workReducer.currentMessage.type === 'process') applyProcessStatus();
        if (workReducer.currentMessage.type === 'processes') applyProcessesStatus();
      }
    }

    return () => { };
  }, [workReducer.currentMessage]);

  useEffect(() => { }, [workReducer.connectStatus]);

  useEffect(() => {
    const getSensorData = setInterval(async () => {
      const BodyToPost = {
        companyId: userReducer.company.companyId,
        code: 'bar',
        name: null,
      }
      await sensorDataApi.sensorRealTimeData(BodyToPost).then(response => {
        if (response === undefined) return;
        // console.log(moment().format('HH:mm:ss') + ' - 압력 bar : ', checkNullObject(response.data.content[0], {}));
        const sensorData = checkEmptyNull(response.data.content[0]?.value, 0);
        setRealtimeBar(() => { return sensorData });
      })
    }, 1000);
    return () => clearInterval(getSensorData);
  }, [_realtimeBar]);
  // useEffect(() => {
  //   const getSensorData = setInterval(async() => {
  //     const BodyToPost = {
  //       companyId: userReducer.company.companyId,
  //       name: 'length'
  //     }
  //     await sensorDataApi.sensorRealTimeData(BodyToPost).then(response => {
  //       if(response === undefined) return;
  //       // console.log(moment().format('HH:mm:ss') + ' - 절곡 length : ', checkNullObject(response.data.content[0], {}));
  //       const sensorData = checkEmptyNull(response.data.content[0]?.value, 0);
  //       setRealtimeLength(() => {return sensorData});
  //     })
  //   }, 1000);
  //   return () => clearInterval(getSensorData);
  // }, [_realtimeLength]);
  useEffect(() => {
    const getSensorData = setInterval(async () => {
      const BodyToPost = {
        companyId: userReducer.company.companyId,
        code: 'camber',
        name: null,
      }
      await sensorDataApi.sensorRealTimeData(BodyToPost).then(response => {
        if (response === undefined) return;
        // console.log(moment().format('HH:mm:ss') + ' - 캠버 camber : ', checkNullObject(response.data.content[0], {}));
        const sensorData = checkEmptyNull(response.data.content[0]?.value, 0);
        setRealtimeCamber(() => { return sensorData });
      })
    }, 1000);
    return () => clearInterval(getSensorData);
  }, [_realtimeCamber]);

  /* ====================================================================== #4 */
  /* 웹소켓 */
  const applyStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);
    setFormData(prev => { return { ...prev, workOrderStatus: applyContent.workOrderStatus } })
  };
  const applyProcessStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventProcessLogList = [..._processLogList];
    const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === applyContent.processLog.processLogId);
    const eventContent = eventProcessLogList[eventProcessLogIndex];
    if (eventContent === undefined) return;
    eventContent.processStatus = applyContent.processLog.processStatus;
    eventContent.managers = checkNullArray(applyContent.processLog.managers, []);
    eventContent.workers = checkNullArray(applyContent.processLog.workers, []);
    eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
    setFormData((prev) => { return { ...prev, processLogList: eventProcessLogList }; });
  };
  const applyProcessesStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventProcessLogList = [..._processLogList];
    if (checkNullArray(applyContent.socketProcessResults, false)) {
      applyContent.socketProcessResults.forEach((processResult) => {
        const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === processResult.processLog.processLogId);
        if (eventProcessLogIndex === -1) return;
        const eventContent = eventProcessLogList[eventProcessLogIndex];
        eventContent.processStatus = processResult.processLog.processStatus;
        eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
      });
    } else {
      applyContent.processLogIds.forEach((processLogIds) => {
        const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === processLogIds);
        if (eventProcessLogIndex === -1) return;
        const eventContent = eventProcessLogList[eventProcessLogIndex];
        eventContent.processStatus = applyContent.processStatus;
        eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
      });
    }
    setFormData((prev) => { return { ...prev, processLogList: eventProcessLogList }; });
  };

  /* ====================================================================== #5 */
  const actViewMinus = () => {
    const prevScale = _viewScale;
    let setScale = parseFloat(prevScale - 5);
    if (setScale < 10) setScale = 10;
    setViewScale(setScale);
  };
  const actViewPlus = () => {
    const prevScale = _viewScale;
    let setScale = parseFloat(prevScale + 5);
    if (setScale > 125) setScale = 125;
    setViewScale(setScale);
  };

  const handleCustomEvent = (e, index, processCustomizedContent, processLog) => {
    const { name, value } = e.target;
    setUpdateWorkOrderList((prev) => {
      const returnData = [...prev];
      const newData = { ...processLog, processCustomizedContent: { ...processCustomizedContent, [name]: value } }
      returnData.splice(index, 1, newData);
      return returnData;
    });
  };
  const handleRealtimeCamber = (index, processCustomizedContent, processLog) => {
    setUpdateWorkOrderList((prev) => {
      const returnData = [...prev];
      const newData = { ...processLog, processCustomizedContent: { ...processCustomizedContent, realCamber: _realtimeCamber } }
      returnData.splice(index, 1, newData);
      return returnData;
    });
  };

  const actUpdate = async () => {
    if (_authority.indexOf('501-2') === -1) return alert('권한이 없습니다.');
    const BodyToPut = { ..._formData };
    const BLData = _updateWorkOrderList.map((thisItem) => { return { ...thisItem, processCustomizedContent: JSON.stringify(thisItem.processCustomizedContent) } });
    const setAllProcessLogList = [...BLData, ..._CPData, ..._CLData];
    BodyToPut.processLogList = setAllProcessLogList;
    BodyToPut.deleteProcessLogList = _deleteWorkOrderList;
    console.log('BodyToPut : ', BodyToPut);

    await workOrderLogApi.updateWorkOrderLog(BodyToPut.workOrderLogId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.updateWorkOrderLog : ', response);
      alert('저장되었습니다.');
    })
  };


  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection type="update" style={{ zoom: '100%' }}>
            <ModalHeader>
              <ModalTitle>캠버리스트</ModalTitle>
              {/* <UpdateButton className="formButton" onClick={(e) => {e.preventDefault(); actUpdate();}}>리스트 저장</UpdateButton> */}
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <TableSection content={
                <table style={{ zoom: `${_viewScale}%` }}>
                  <thead>
                    <tr>
                      <th style={{ minWidth: '120px', padding: 'unset', width: '120px' }}>작업상태</th>
                      <th style={{ minWidth: '65px', width: '65px' }}>순번</th>
                      <th>부재번호</th>
                      <th>규격</th>
                      <th>단위</th>
                      <th>길이(m)</th>
                      <th>수량</th>
                      <th>기준<br />캠버값(mm)</th>
                      <th>실제<br />캠버값(mm)</th>
                      <th>비고</th>
                    </tr>
                  </thead>

                  <tbody>
                    {checkNullArray(_updateWorkOrderList, []).map((processLog, index) => {
                      const processCustomizedContent = checkNullObject(processLog.processCustomizedContent, {
                        num: (index + 1).toString().padStart(2, '0'),
                        absentNumber: '',
                        standard: '',
                        unit: '',
                        length: '',
                        quantity: '',
                        defaultCamber: '',
                        realCamber: '',
                        remark: '',
                      })
                      return (
                        <tr key={index + '_BLRow'}>
                          <StatusTdButton
                            statusStyle={{ cursor: 'default', height: '120px', minWidth: '120px', width: '120px' }}
                            status={processLog.processStatus}
                          />
                          <td style={{ minWidth: '65px', width: '65px' }} data-col="num" data-colname="순번">{checkEmptyNull(processCustomizedContent.num, (index + 1).toString().padStart(2, '0'))}</td>
                          <td data-col="absentNumber" data-colname="부재번호">{processCustomizedContent.absentNumber}</td>
                          <td data-col="standard" data-colname="규격">{processCustomizedContent.standard}</td>
                          <td data-col="unit" data-colname="단위">{processCustomizedContent.unit}</td>
                          <td data-col="length" data-colname="길이">{processCustomizedContent.length}</td>
                          <td data-col="quantity" data-colname="수량">{processCustomizedContent.quantity}</td>
                          <td data-col="defaultCamber" data-colname="기준캠버값">{processCustomizedContent.defaultCamber}</td>
                          <td data-col="realCamber" data-colname="실제캠버값">{processCustomizedContent.realCamber}</td>
                          <td data-col="remark" data-colname="비고">{processCustomizedContent.remark}</td>
                          {/* <td data-col="defaultCamber" data-colname="기준캠버값">
                            <input
                              type="text"
                              name="defaultCamber"
                              disabled={_authority.indexOf('501-2') !== -1 ? false : true}
                              value={processCustomizedContent.defaultCamber}
                              onInput={(e) => {e.preventDefault(); handleCustomEvent(e, index, processCustomizedContent, processLog);}}
                            />                                                            
                          </td>
                          <td data-col="realCamber" data-colname="실제캠버값" style={{position: 'relative'}}>
                            <input
                              type="text"
                              name="realCamber"
                              disabled={_authority.indexOf('501-2') !== -1 ? false : true}
                              value={processCustomizedContent.realCamber}
                              onInput={(e) => {e.preventDefault(); handleCustomEvent(e, index, processCustomizedContent, processLog);}}
                            />                                                            
                            <div className='realCamber'>
                              <button className='btn-inven-1' onClick={(e) => {e.preventDefault(); handleRealtimeCamber(index, processCustomizedContent, processLog);}}>입력</button>
                              <button className='btn-inven-1' onClick={(e) => {e.preventDefault(); actUpdate();}}>저장</button>
                            </div>
                          </td>
                          <td data-col="remark" data-colname="비고">
                            <textarea
                              type="text"
                              name="remark"
                              disabled={_authority.indexOf('501-2') !== -1 ? false : true}
                              value={processCustomizedContent.remark}
                              onInput={(e) => {e.preventDefault(); handleCustomEvent(e, index, processCustomizedContent, processLog);}}
                            />                                                            
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
              />
            </ModalMain>

            <CamberSection>
              <div className='monitoring'>
                <h4>압력</h4>
                <p>{checkEmptyNull(_realtimeBar, 0).toLocaleString()}</p>
              </div>
              {/* <div className='monitoring'>
                <h4>절곡</h4>
                <p>{checkEmptyNull(_realtimeLength, 0).toLocaleString()}</p>
              </div> */}
              <div className='monitoring'>
                <h4>캠버</h4>
                <p>{checkEmptyNull(_realtimeCamber, 0).toLocaleString()}</p>
              </div>
            </CamberSection>
          </ModalSection>
        </ModalBg>
      )}

      <ViewerButtons>
        <ViewMinus onClick={actViewMinus}><ViewMinusIcon /></ViewMinus>
        <ViewPlus onClick={actViewPlus}><ViewPlusIcon /></ViewPlus>
      </ViewerButtons>
    </>
  );
};

export default GSBLStatus;
