const initialState = {
  accountType: 0,
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,

  bookmark: true,
};
const resetState = {
  accountType: 0,
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETACCOUNTTYPE': return { ...state, accountType: action.payload };

    case 'SETACCOUNTSEARCHOPTION': return { ...state, searchOption: action.payload };
    case 'SETACCOUNTSEARCHTEXT': return { ...state, searchText: action.payload };

    case 'SETACCOUNTPAGENUMBER': return { ...state, pageNumber: action.payload };
    case 'SETACCOUNTTOTALSIZE': return { ...state, totalSize: action.payload };
    case 'SETACCOUNTISPUSHEDSEARCHBUTTON': return { ...state, isPushedSearchButton: action.payload };

    case 'SETACCOUNTBOOKMARK': return { ...state, bookmark: action.payload };

    case 'SETACCOUNTRESET': return { ...state, ...resetState };
    default: return state;
  }
}

export default accountReducer;