import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';

import {
  AXIOS_BASE_HEADERS,
  AXIOS_BASE_URL,
} from 'preferences/server/constants';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';

const DeleteFailedReason = () => {
  /* ========================================================================= #1 */
  const navigate = useNavigate();
  const { pageReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();

  const failedReasonId = searchParams.get('failedReasonId');
  const failedReasonContent = searchParams.get('failedReasonContent');
  const failedReasonType = searchParams.get('failedReasonType');
  console.table({
    failedReasonId: failedReasonId,
    failedReasonContent: failedReasonContent,
    failedReasonType: failedReasonType,
  });

  /* ====================================================================== #3 */

  /* ========================================================================= #4 */

  /* ====================================================================== #5 */
  const actDelete = async () => {
    await axios
      .delete(
        AXIOS_BASE_URL + '/failedReason/' + failedReasonId,
        AXIOS_BASE_HEADERS,
      )
      .then((response) => {
        if (response === undefined) return;
        console.log('actDelete - response : ', response);
        if (response.status === 200) {
          alert('불량사유를 삭제했습니다.');
          navigate(pageReducer.currentPage, { replace: true });
        }
      })
      .catch((error) => {
        console.log('actDelete - error : ', error);
      });
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body
      contents={
        <>
          <main className="Main">
            <NavBar title={'불량사유관리'} nav={''} />

            <DeleteForm
              delteTitle={'불량사유'}
              deleteItem={
                <>
                  {'불량유형 : ' + failedReasonType}
                  <br />
                  {'불량사유 : ' + failedReasonContent}
                  <br />
                </>
              }
              deleteText={
                <>
                  불량사유를 삭제합니다.
                  <br />
                </>
              }
              deleteButton={
                <>
                  <button
                    className="formButton cancle"
                    onClick={() => {
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                  <button
                    className="formButton delete"
                    onClick={() => {
                      if (window.confirm('불량사유를 삭제합니다.')) actDelete();
                      else return;
                    }}
                  >
                    삭제
                  </button>
                </>
              }
            />
          </main>
        </>
      }
    />
  );
};

export default DeleteFailedReason;
