import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  modalFileActions_setFileCurrentPage,
  modalFileActions_setFileList,
  modalFileActions_setFileSearch,
  modalFileActions_setFileTotalSize,
} from 'store/modules/actions/modal/modalFileActions';

import { fileDataApi } from 'api/apis/fileDataApi';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import CreateFileModal from 'pages/file/modal/crud/CreateFileModal';
import File from 'pages/file/assets/File';
import ModalFolderManagement from 'pages/file/folder/modal/ModalFolderManagement';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const ModalHeaderButtons = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 55px;
`;

const ModalHeaderButton = styled.button``;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
    /* zoom: 67%; */

    td.blankCell {
      background-color: var(--Bg);
      opacity: 0.8;
    }
  }
`;

const MainContents = styled.div`
  display: grid;
  grid-template-columns: 300px calc(100% - 300px);
  height: 100%;
  overflow: hidden;
  width: 100%;
`;

const FileSection = styled.div`
  column-gap: 10px;
  display: grid;
  grid-template-columns: 60% calc(40% - 10px);
  height: 100%;
  overflow: hidden;
  width: 100%;
`;
const FileList = styled.div`
  display: grid;
  grid-template-rows: auto 50px;
  overflow: hidden;
  height: 100%;
  width: 100%;

  .TableSection {
    height: 100%;
    tbody {
      tr {
        &:hover {
          /* background-color: var(--gray-200); */
          cursor: pointer;
          /* opacity: 0.6; */
        }
      }
    }
  }
`;

const Preview = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 6px;
  }

  img {
    cursor: pointer;
    width: 100%;
  }
`;

const DownloadSection = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 2em;
  height: 100%;
  justify-content: center;
  width: 100%;

  &:hover {
    background-color: var(--Bg);
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const SelectFiles = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { modalFileReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const CheckBox = useRef();

  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_fileList, setFileList] = useState([]);

  const [_currentFile, setCurrentFile] = useState({});

  const [_checkedItem, setCheckedItem] = useState([]);

  const [_createModalStatus, setCreateModalStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter(
      (thisItem) => thisItem.authority.startsWith('109') === true,
    );
    const authorityCodes = authorityContent.map(
      (thisItem) => thisItem.authority,
    );
    setAuthority(authorityCodes);

    setOnload('loaded');
    return () => {};
  }, []);

  useEffect(() => {
    getCurrentFolderFileList(modalFileReducer.currentPage - 1);

    return () => {};
  }, [modalFileReducer.currentPage]);

  useEffect(() => {
    setFileList(() => {
      return modalFileReducer.fileList;
    });
    setCurrentFile(() => {
      return {};
    });

    return () => {};
  }, [modalFileReducer.fileList]);

  useEffect(() => {
    // if (
    //   modalFileReducer.currentFolder !== undefined &&
    //   modalFileReducer.currentFolder !== null &&
    //   typeof modalFileReducer.currentFolder === 'object' &&
    //   Object.keys(modalFileReducer.currentFolder).length > 0 &&
    //   !modalFileReducer.currentFolder.root
    // ) {
    //   getCurrentFolderFileList(0);
    // }

    if (_onload === 'loaded') {
      if (modalFileReducer.currentPage === 1) {
        getCurrentFolderFileList(0);
      } else {
        dispatch(modalFileActions_setFileCurrentPage(1));
      }
    }

    return () => {};
  }, [modalFileReducer.currentFolder]);

  useEffect(() => {}, [_checkedItem]);

  /* ====================================================================== #4 */
  const getCurrentFolderFileList = async (page, clear) => {
    if (
      modalFileReducer.currentFolder === undefined ||
      modalFileReducer.currentFolder === null ||
      typeof modalFileReducer.currentFolder !== 'object'
    ) {
      return;
    }

    const paging = `?page=${page}&size=10`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (
      !clear &&
      modalFileReducer.currentFolder !== undefined &&
      modalFileReducer.currentFolder !== null &&
      typeof modalFileReducer.currentFolder === 'object' &&
      Object.keys(modalFileReducer.currentFolder).length > 0 &&
      !modalFileReducer.currentFolder.root
    ) {
      BodyToPost.fileCategoryId = modalFileReducer.currentFolder.fileCategoryId;
    }
    if (
      !clear &&
      modalFileReducer.search &&
      modalFileReducer.search.fileDataName
    ) {
      BodyToPost.fileDataName = modalFileReducer.search.fileDataName;
    }

    await fileDataApi.searchFileData(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('fileDataApi.searchFileData : ', response);

      dispatch(modalFileActions_setFileList(response.data.content));
      dispatch(modalFileActions_setFileTotalSize(response.data.totalElements));
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (item, isChecked) => {
    const checkedItem = [..._checkedItem];
    const checkedIndex = checkedItem.findIndex(
      (thisItem) => thisItem.fileDataId === item.fileDataId,
    );

    if (checkedIndex !== -1) {
      if (!isChecked) {
        checkedItem.splice(checkedIndex, 1);
      }
    } else {
      if (isChecked) {
        checkedItem.push(item);
      }
    }
    console.log('checkedItem : ', checkedItem);

    setCheckedItem(() => {
      return checkedItem;
    });
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(modalFileActions_setFileCurrentPage(1));
    await getCurrentFolderFileList(0);
  };

  const actReset = async () => {
    dispatch(
      modalFileActions_setFileSearch({
        fileDataName: '',
      }),
    );
    dispatch(modalFileActions_setFileCurrentPage(1));
    await getCurrentFolderFileList(0, true);
  };

  const handleDownload = (file) => {
    const element = document.createElement('a');
    element.href = file.fileDataS3URL;
    element.download = file.fileDataName;
    element.target = '_blank';
    element.click();
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <>
          <ModalBg>
            <ModalSection>
              <ModalHeader>
                <ModalTitle>파일 선택</ModalTitle>
                <ModalHeaderButtons>
                  {_authority.indexOf('109-2') !== -1 &&
                    modalFileReducer.currentFolder !== undefined &&
                    modalFileReducer.currentFolder !== null &&
                    typeof modalFileReducer.currentFolder === 'object' &&
                    Object.keys(modalFileReducer.currentFolder).length > 0 &&
                    !modalFileReducer.currentFolder.root && (
                      <ModalHeaderButton
                        className="formButton"
                        onClick={(e) => {
                          e.preventDefault();
                          setTimeout(setCreateModalStatus(true), 1000);
                        }}
                      >
                        파일 추가
                      </ModalHeaderButton>
                    )}
                </ModalHeaderButtons>
                <CloseButton onClick={props.close}>
                  <CloseButtonIcon />
                </CloseButton>
              </ModalHeader>

              <ModalMain>
                <NavBar
                  title={
                    <>
                      <div className="ContentCBox">
                        <div className="ContentCBox">
                          <select className="SearchOption detail">
                            <option value="fileDataName">파일이름</option>
                          </select>
                          <input
                            className="SearchBar detail"
                            placeholder="Search..."
                            value={
                              modalFileReducer.search &&
                              modalFileReducer.search.fileDataName
                            }
                            onInput={(e) => {
                              dispatch(
                                modalFileActions_setFileSearch({
                                  fileDataName: e.target.value,
                                }),
                              );
                            }}
                          />
                        </div>

                        <button data-searchbutton="true" onClick={actSearch}>
                          <SearchButtonIcon />
                        </button>
                        <div className="ResetButton" onClick={actReset}>
                          초기화
                        </div>
                      </div>
                    </>
                  }
                  nav={''}
                />

                <MainContents>
                  <ModalFolderManagement />
                  <FileSection>
                    <FileList>
                      <TableSection
                        content={
                          <table>
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    minWidth: '50px',
                                    width: '50px',
                                  }}
                                >
                                  <input
                                    type="checkBox"
                                    name="allElements"
                                    ref={CheckBox}
                                    onChange={(e) => {
                                      const isChecked = e.target.checked;

                                      if (isChecked) {
                                        setCheckedItem((prev) => {
                                          const prevData = [...prev];

                                          const newData = _fileList.map(
                                            (thisItem) => {
                                              const thisFindIndex =
                                                prevData.findIndex(
                                                  (thisIndex) =>
                                                    thisIndex.fileDataId ===
                                                    thisItem.fileDataId,
                                                );

                                              if (thisFindIndex !== -1)
                                                return null;
                                              else return thisItem;
                                            },
                                          );

                                          const filterData = newData.filter(
                                            (thisItem) => thisItem !== null,
                                          );

                                          return [...prevData, ...filterData];
                                        });

                                        e.target.checked = true;
                                      } else {
                                        setCheckedItem((prev) => {
                                          const prevData = prev.map(
                                            (thisItem) => {
                                              const thisFindIndex =
                                                _fileList.findIndex(
                                                  (thisIndex) =>
                                                    thisIndex.fileDataId ===
                                                    thisItem.fileDataId,
                                                );

                                              if (thisFindIndex !== -1)
                                                return null;
                                              else return thisItem;
                                            },
                                          );

                                          const filterData = prevData.filter(
                                            (thisItem) => thisItem !== null,
                                          );

                                          return filterData;
                                        });

                                        e.target.checked = false;
                                      }
                                    }}
                                  />
                                </th>
                                {modalFileReducer.currentFolder !== undefined &&
                                  modalFileReducer.currentFolder !== null &&
                                  typeof modalFileReducer.currentFolder ===
                                    'object' &&
                                  Object.keys(modalFileReducer.currentFolder)
                                    .length > 0 &&
                                  modalFileReducer.currentFolder.root && (
                                    <th>경로</th>
                                  )}
                                <th>파일이름</th>
                                {/* <th
                                  style={{
                                    minWidth: '150px',
                                    width: '150px',
                                  }}
                                >
                                  확장자
                                </th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {_fileList.map((thisItem, index) => {
                                return (
                                  <tr
                                    key={index + '_files'}
                                    onClick={() => {
                                      setCurrentFile(() => {
                                        return thisItem;
                                      });
                                    }}
                                  >
                                    <td
                                      style={{
                                        minWidth: '50px',
                                        width: '50px',
                                      }}
                                    >
                                      <input
                                        type="checkBox"
                                        name="m_files"
                                        checked={(() => {
                                          const checkedId = _checkedItem
                                            ? _checkedItem.findIndex(
                                                (thisIndex) =>
                                                  thisIndex.fileDataId ===
                                                  thisItem.fileDataId,
                                              )
                                            : -1;
                                          if (checkedId !== -1) {
                                            return true;
                                          } else {
                                            return false;
                                          }
                                        })()}
                                        onChange={(e) => {
                                          const isChecked = e.target.checked;
                                          handleCheckedItem(
                                            thisItem,
                                            isChecked,
                                          );
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                      />
                                    </td>
                                    {modalFileReducer.currentFolder !==
                                      undefined &&
                                      modalFileReducer.currentFolder !== null &&
                                      typeof modalFileReducer.currentFolder ===
                                        'object' &&
                                      Object.keys(
                                        modalFileReducer.currentFolder,
                                      ).length > 0 &&
                                      modalFileReducer.currentFolder.root && (
                                        <td>
                                          {userReducer.company.companyName +
                                            '/' +
                                            (thisItem.fileCategoryPath !== null
                                              ? thisItem.fileCategoryPath
                                              : '')}
                                        </td>
                                      )}
                                    <td>
                                      <File content={thisItem} />
                                    </td>
                                    {/* <td
                                      style={{
                                        minWidth: '150px',
                                        width: '150px',
                                      }}
                                    >
                                      {thisItem.fileDataType}
                                    </td> */}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        }
                      />

                      <PagingComponent
                        page={modalFileReducer.currentPage}
                        count={modalFileReducer.totalSize}
                        size={10}
                        pageEvent={(page) => {
                          console.log('page : ', page);
                          dispatch(modalFileActions_setFileCurrentPage(page));
                        }}
                      />
                    </FileList>
                    <Preview>
                      {(() => {
                        if (
                          _currentFile !== undefined &&
                          _currentFile !== null &&
                          typeof _currentFile === 'object' &&
                          Object.keys(_currentFile).length > 0
                        ) {
                          if (_currentFile.fileDataType.startsWith('image/')) {
                            return (
                              <img
                                src={_currentFile.fileDataS3URL}
                                alt={_currentFile.fileDataName}
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      '파일을 다운로드 하시겠습니까?',
                                    )
                                  ) {
                                    handleDownload(_currentFile);
                                  } else {
                                    return;
                                  }
                                }}
                              />
                            );
                          } else {
                            return (
                              <DownloadSection
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      '파일을 다운로드 하시겠습니까?',
                                    )
                                  ) {
                                    handleDownload(_currentFile);
                                  } else {
                                    return;
                                  }
                                }}
                              >
                                <p>{_currentFile.fileDataName}</p>
                                <p>다운로드</p>
                              </DownloadSection>
                            );
                          }
                        }
                      })()}
                    </Preview>
                  </FileSection>
                </MainContents>
              </ModalMain>

              <ModalFooter>
                <ModalButton
                  onClick={() => {
                    props.buttonEvent(_checkedItem);
                  }}
                >
                  {props.buttonTitle}
                </ModalButton>
              </ModalFooter>
            </ModalSection>
          </ModalBg>

          {_createModalStatus === true &&
            !modalFileReducer.currentFolder.root && (
              <CreateFileModal
                currentFolder={modalFileReducer.currentFolder}
                open={_createModalStatus}
                close={(data) => {
                  if (data) {
                    getCurrentFolderFileList(modalFileReducer.currentPage - 1);
                  }

                  setCreateModalStatus(false);
                }}
              />
            )}
        </>
      )}
    </>
  );
};

export default SelectFiles;
