import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { order } from 'api/apis/mall/order';
import { qna } from 'api/apis/mall/qna';

import ByteFormat from 'components/format/ByteFormat';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import File from 'pages/file/assets/File';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px calc(100% - 55px);
  height: 85%;
  /* max-height: 300px; */
  /* max-width: 400px; */
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const ModalHeaderButtons = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 55px;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  /* height: calc(100% - 55px); */
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
`;

const FileSection = styled.div`
  column-gap: 10px;
  display: grid;
  grid-template-columns: 60% calc(40% - 10px);
  height: 100%;
  overflow: hidden;
  width: 100%;
`;
const FileList = styled.div`
  display: grid;
  grid-template-rows: 100%;
  overflow: hidden;
  height: 100%;
  width: 100%;

  .TableSection {
    height: 100%;
    tbody {
      tr {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
`;

const FileButton = styled.td`
  color: var(--white);
  cursor: pointer;
  min-width: 100px;
  width: 100px;

  &[data-type='다운로드'] {
    background-color: var(--ThirdGreen);
  }
  &[data-type='수정'] {
    background-color: var(--MainNavy);
  }
  &[data-type='삭제'] {
    background-color: var(--ThirdRed);
  }
`;

const Preview = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 6px;
  }

  img {
    cursor: pointer;
    width: 100%;
  }
`;

const DownloadSection = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 2em;
  height: 100%;
  justify-content: center;
  width: 100%;

  &:hover {
    background-color: var(--Bg);
  }
`;

const ShowMallFileList = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const { type, content } = props;

  const [_onload, setOnload] = useState('unload');

  const [_fileList, setFileList] = useState([]);
  const [_currentFile, setCurrentFile] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    setFileList(() => {
      return content.attachFileList === null ? [] : content.attachFileList;
    });

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    setCurrentFile(() => {
      return {};
    });

    return () => { };
  }, [_fileList]);

  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const handleDownload = (file) => {
    const element = document.createElement('a');
    element.href = file.fileDataS3URL;
    element.download = file.fileDataName;
    element.target = '_blank';
    element.click();
  };

  const actDelete = async (index, eventContent) => {
    console.log('actDelete : ', index, eventContent);
    if (
      window.confirm(`
    파일을 삭제하시겠습니까?
    `)
    ) {
      const fileDataId = eventContent.fileDataId;
      if (fileDataId === undefined || fileDataId === null) return;
      switch (type) {
        case 'mallOrder':
          const mallOrderToPut = {
            ...content,
            deleteMallOrderElementList: [],
            deleteFileDataList: [fileDataId],
            userId: userReducer.user.id,
          };
          console.log('mallOrderToPut : ', mallOrderToPut);

          const mallOrderFormData = new FormData();
          mallOrderFormData.append(
            'key',
            new Blob([JSON.stringify(mallOrderToPut)], {
              type: 'application/json',
            }),
          );
          for (let values of mallOrderFormData.values()) {
            console.log('mallOrderFormData : ', values);
          }

          await order.updateMallOrder(content.mallOrderId, mallOrderFormData)
            .then((response) => {
              if (response === undefined) return;
              console.log('order.updateMallOrder : ', response);

              setFileList((prev) => {
                const prevData = [...prev];
                prevData.splice(index, 1);

                return prevData;
              });

              alert('파일이 삭제되었습니다.');
              props.update();
            });

          break;

        case 'mallQnA':
          const mallQnAToPut = {
            ...content,
            deleteMallQnAElementList: [],
            deleteFileDataList: [fileDataId],
            userId: userReducer.user.id,
          };
          console.log('mallQnAToPut : ', mallQnAToPut);

          const mallQnAFormData = new FormData();
          mallQnAFormData.append(
            'key',
            new Blob([JSON.stringify(mallQnAToPut)], {
              type: 'application/json',
            }),
          );
          for (let values of mallQnAFormData.values()) {
            console.log('mallQnAFormData : ', values);
          }

          await qna.updateMallQnA(content.mallQnAId, mallQnAFormData)
            .then((response) => {
              if (response === undefined) return;
              console.log('qna.updateMallQnA : ', response);

              setFileList((prev) => {
                const prevData = [...prev];
                prevData.splice(index, 1);

                return prevData;
              });

              alert('파일이 삭제되었습니다.');
              props.update();
            });

          break;

        default:
          return;
      }
    }
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>첨부파일</ModalTitle>
              <ModalHeaderButtons></ModalHeaderButtons>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              <FileSection>
                <FileList>
                  <TableSection
                    content={
                      <table>
                        <thead>
                          <tr>
                            <th>경로</th>
                            <th>파일이름</th>
                            <th>파일크기</th>
                            <th
                              style={{
                                minWidth: '100px',
                                width: '100px',
                              }}
                            ></th>
                            <th
                              style={{
                                minWidth: '100px',
                                width: '100px',
                              }}
                            ></th>
                          </tr>
                        </thead>
                        <tbody>
                          {_fileList.map((thisItem, index) => {
                            let fileData = { ...thisItem };
                            if (
                              thisItem.fileData !== undefined &&
                              thisItem.fileData !== null &&
                              Object.keys(thisItem).length > 0
                            ) {
                              fileData = {
                                ...thisItem,
                                ...thisItem.fileData,
                              };
                            }

                            return (
                              <tr
                                key={index + '_files'}
                                onClick={() => {
                                  setCurrentFile(() => {
                                    return fileData;
                                  });
                                }}
                              >
                                <td>
                                  {userReducer.company.companyName +
                                    '/' +
                                    (fileData.fileCategoryPath !== null
                                      ? fileData.fileCategoryPath
                                      : '')}
                                </td>
                                <td>
                                  <File content={fileData} />
                                </td>
                                <td>{ByteFormat(fileData.fileDataSize)}</td>
                                <FileButton
                                  style={{
                                    height: '60px',
                                    minWidth: '100px',
                                    width: '100px',
                                  }}
                                  data-type="다운로드"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setCurrentFile(() => {
                                      return fileData;
                                    });
                                    handleDownload(fileData);
                                  }}
                                >
                                  다운로드
                                </FileButton>
                                <FileButton
                                  style={{
                                    height: '60px',
                                    minWidth: '100px',
                                    width: '100px',
                                  }}
                                  data-type="삭제"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    actDelete(index, fileData);
                                  }}
                                >
                                  삭제
                                </FileButton>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    }
                  />
                </FileList>
                <Preview>
                  {(() => {
                    if (
                      _currentFile !== undefined &&
                      _currentFile !== null &&
                      typeof _currentFile === 'object' &&
                      Object.keys(_currentFile).length > 0
                    ) {
                      if (_currentFile.fileDataType.startsWith('image/')) {
                        return (
                          <img
                            src={_currentFile.fileDataS3URL}
                            alt={_currentFile.fileDataName}
                            onClick={() => {
                              if (
                                window.confirm('파일을 다운로드 하시겠습니까?')
                              ) {
                                handleDownload(_currentFile);
                              } else {
                                return;
                              }
                            }}
                          />
                        );
                      } else {
                        return (
                          <DownloadSection
                            onClick={() => {
                              if (
                                window.confirm('파일을 다운로드 하시겠습니까?')
                              ) {
                                handleDownload(_currentFile);
                              } else {
                                return;
                              }
                            }}
                          >
                            <p>{_currentFile.fileDataName}</p>
                            <p>다운로드</p>
                          </DownloadSection>
                        );
                      }
                    }
                  })()}
                </Preview>
              </FileSection>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default ShowMallFileList;
