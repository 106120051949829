import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import SelectOneAccount from 'pages/account/modal/SelectOneAccount';
import TableSection from 'components/layouts/table/TableSection';

const FileTableSection = styled.div`
  cursor: pointer;
  width: 100%;
  &:hover {opacity: 0.6;}
  & > .TableSection {
    max-height: 200px;
    overflow: auto;
    td, th {min-width: unset;}
    &::-webkit-scrollbar-thumb {background-color: var(--ThirdBlue);}
  }
`;
const FileLabelStyle = styled.label`
  align-items: center;
  background-color: var(--MainNavy);
  border-radius: 5px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const ContractDate = styled.input`
  border: 1px solid var(--gray-200);
  border-radius: 5px;
  box-sizing: border-box;
  height: 40px;
  padding: 5px;
  &:focus {border: 1px solid var(--gray-200);}
`;

const SelectSection = styled.div``;
const SelectButtonSection = styled.div`
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 50px;
  overflow: hidden;
  width: 100%;
`;
const SelectButton = styled.div`
  align-items: center;
  background-color: ${(props) => {
    return props.type.startsWith('add_') === true ? 'var(--MainBlue)'
      : props.type.startsWith('replace_') === true ? 'var(--ThirdBlue)'
        : props.type.startsWith('cancel_') === true ? 'var(--gray-200)'
          : null;
  }};
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 8px;
  white-space: nowrap;
  width: 100%;
`;

const UpdateGSProductionOrder = () => {
  /* ===================================================#======================================================*/
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { gsReducer, pageReducer, userReducer, stockReducer } = useSelector((state) => state);

  /* =================================================== #2 ===================================================*/
  const [_onload, setOnload] = useState('unload');

  const updateContent = checkNullObject(gsReducer.updateOrder, {});
  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    userId: userReducer.user.id,
    workOrderStatus: updateContent.workOrderStatus,
    workOrderLogId: updateContent.workOrderLogId,
    workOrderCode: updateContent.workOrderCode,
    workOrderName: updateContent.workOrderName,
    workOrderScheduledStartDate: DateFormat(checkEmptyNull(updateContent.workOrderScheduledStartDate, '')),
    workOrderScheduledEndDate: DateFormat(checkEmptyNull(updateContent.workOrderScheduledEndDate, '')), // ?
    accountId: updateContent.accountId,
    workPlace: updateContent.workPlace,
    detailedWorkPlace: updateContent.detailedWorkPlace,
    message: '',
    remark: '',
    workOrderCustomizedContent: JSON.stringify({}),
    processLogList: checkNullArray(updateContent.processLogList, []).map((processLog) => {
      return {
        existProcessOutElement: false,
        processTypeName: processLog.processTypeName,
        processStatus: processLog.processStatus,
        processLogId: processLog.processLogId,
        processName: processLog.processName,
        processCode: processLog.processCode,
        processingCount: processLog.processingCount,
        processCustomizedContent: processLog.processCustomizedContent,
        deleteTaskIds: [],
        taskLogList: [],
      };
    }),
    deleteProcessList: [],
  });
  console.log(_formData);

  const [_account, setAccount] = useState({ accountId: updateContent.accountId, accountName: updateContent.accountName }); // 거래처
  
  const [_modalStatus, setModalStatus] = useState(false);
  const [_prevBL, setPrevBL] = useState(checkNullArray(updateContent.processLogList, []).filter((thisItem) => thisItem.processTypeName === 'BL'));
  const [_prevCP, setPrevCP] = useState(checkNullArray(updateContent.processLogList, []).filter((thisItem) => thisItem.processTypeName === 'CP'));
  const [_prevCL, setPrevCL] = useState(checkNullArray(updateContent.processLogList, []).filter((thisItem) => thisItem.processTypeName === 'CL'));
  const [_fileBL, setFileBL] = useState([]);
  const [_fileCP, setFileCP] = useState([]);
  const [_fileCL, setFileCL] = useState([]);
  const [_selectBLType, setSelectBLType] = useState();
  const [_selectCPType, setSelectCPType] = useState();
  const [_selectCLType, setSelectCLType] = useState();

  /* =================================================== #3 =================================================== */
  useEffect(() => {
    setOnload('loaded');
    return () => { };
  }, []);

  /* =================================================== #4들 =================================================== */
  /* =================================================== #5들 =================================================== */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData({ ..._formData, [name]: value });
  };

  const setCamberFile = (e) => {
    const excelType = 'camber';
    excelExportCommon(e, excelSetting, excelType);
  };
  const setCombinationFile = (e) => {
    const excelType = 'combination';
    excelExportCommon(e, excelSetting, excelType);
  };
  const setCheckFile = (e) => {
    const excelType = 'check';
    excelExportCommon(e, excelSetting, excelType);
  };

  // 엑셀
  const excelExportCommon = (e, callback, excelType) => {
    const fileList = e.target.files;
    const thisFile = fileList[0];
    console.log(thisFile);
    if (thisFile === undefined) return;

    switch (excelType) {
      case 'camber': document.getElementById('camberFileTable').innerHTML = '';  break;
      case 'combination': document.getElementById('combinationFileTable').innerHTML = '';  break;
      case 'check': document.getElementById('checkFileTable').innerHTML = ''; break;
      default: return;
    }

    const reader = new FileReader();
    reader.onload = async () => {
      const fileData = reader.result;
      const wb = XLSX.read(fileData, { type: 'binary' });
      const sheetNameList = wb.SheetNames; // 시트 이름 목록 가져오기
      for (let i = 0; i < sheetNameList.length; i++) {
        const thisSheetName = sheetNameList[i];
        const thisSheet = wb.Sheets[thisSheetName];

        await callback(thisSheet, excelType);
      }
    };
    reader.readAsBinaryString(thisFile);
  };
  const excelSetting = (sheet, excelType) => {
    switch (excelType) {
      case 'camber':
        const camber_table_result = XLSX.utils.sheet_to_html(sheet);
        const camber_result = XLSX.utils.sheet_to_json(sheet);
        console.log(excelType, ' : ', camber_result);
        document.getElementById('camberFileTable').innerHTML = camber_table_result;
        setCamberTable();
        break;
      case 'combination':
        const combination_table_result = XLSX.utils.sheet_to_html(sheet);
        const combination_result = XLSX.utils.sheet_to_json(sheet);
        console.log(excelType, ' : ', combination_result);
        document.getElementById('combinationFileTable').innerHTML = combination_table_result;
        setCombinationTable();
        break;
      case 'check':
        const check_result = XLSX.utils.sheet_to_html(sheet);
        console.log(excelType, ' : ', check_result);
        document.getElementById('checkFileTable').innerHTML = check_result;
        setCheckTable();
        break;

      default: return;
    }
  };

  // 캠버리스트 테이블
  const setCamberTable = () => {
    const resultRows = document.querySelectorAll('#camberFileTable tr');
    console.log('setCamberTable : ', resultRows);

    let headerRowIndex = null;
    let sumRowIndex = null;
    if (resultRows.length > 0) {
      resultRows.forEach((thisItem) => {
        if (thisItem.cells[0].innerHTML === '순번') {
          headerRowIndex = thisItem.rowIndex;
          console.log('headerRowIndex : ', headerRowIndex);
        }
        if (thisItem.cells[0].innerHTML.trim() === '' || thisItem.cells[1].innerHTML.replaceAll(' ', '') === '[합계]') {
          sumRowIndex = thisItem.rowIndex;
          console.log('sumRowIndex : ', sumRowIndex);
        }
      });
    }

    if (headerRowIndex !== null) {
      let result = [];
      resultRows.forEach((thisItem) => {
        if (thisItem.rowIndex >= headerRowIndex + 1 && thisItem.rowIndex < sumRowIndex) {
          if (thisItem.cells[1].innerHTML === '' && thisItem.cells[2].innerHTML === '') return;
          const OneRow = {
            existProcessOutElement: false,
            processTypeName: 'BL',
            processingCount: 1,
            processCustomizedContent: {},
            taskLogList: [],
            processCode: thisItem.cells[1].innerHTML.trim(),
            processName: thisItem.cells[2].innerHTML.trim(),
          };

          OneRow.processCustomizedContent.num = thisItem.cells[0].innerHTML.trim(); // 순번
          OneRow.processCustomizedContent.absentNumber = thisItem.cells[1].innerHTML.trim(); // 부재번호
          OneRow.processCustomizedContent.standard = thisItem.cells[2].innerHTML.trim(); // 규격
          OneRow.processCustomizedContent.unit = thisItem.cells[3].innerHTML.trim(); // 단위
          OneRow.processCustomizedContent.length = thisItem.cells[4].innerHTML.trim(); // 길이
          OneRow.processCustomizedContent.quantity = thisItem.cells[5].innerHTML.trim(); // 수량
          OneRow.processCustomizedContent.defaultCamber = thisItem.cells[6].innerHTML.trim(); // 기준캠버값
          OneRow.processCustomizedContent.realCamber = ''; // 실제캠버값
          OneRow.processCustomizedContent.remark = thisItem.cells[7].innerHTML.trim(); // 비고

          result.push(OneRow);
        }
      });
      console.log('result : ', result);

      setFileBL(result);
    }
  };
  // 조합리스트 테이블
  const setCombinationTable = () => {
    const resultRows = document.querySelectorAll('#combinationFileTable tr');
    console.log('setCombinationTable : ', resultRows);

    let headerRowIndex = null;
    if (resultRows.length > 0) {
      resultRows.forEach((thisItem) => {
        if (thisItem.cells[0].innerHTML.replaceAll(' ', '') === '재질') {
          headerRowIndex = thisItem.rowIndex;
          console.log('headerRowIndex : ', headerRowIndex);
        }
      });
    }

    if (headerRowIndex !== null) {
      let result = [];
      let count = 0;
      resultRows.forEach((thisItem) => {
        if (thisItem.rowIndex >= headerRowIndex + 1) {
          if (thisItem.cells[9] !== undefined && thisItem.cells[9].innerHTML.trim() !== null && thisItem.cells[9].innerHTML.trim() !== '') {
            const OneRow = {
              existProcessOutElement: false,
              processTypeName: 'CP',
              processingCount: 1,
              processCustomizedContent: {},
              taskLogList: [],

              processName: thisItem.cells[1].innerHTML.trim(),
            };

            count++;
            OneRow.processCustomizedContent.num = count.toString(); // 순번
            OneRow.processCustomizedContent.name = thisItem.cells[0].innerHTML.trim(); // 재질
            OneRow.processCustomizedContent.profile = thisItem.cells[1].innerHTML.trim(); // 규격
            OneRow.processCustomizedContent.length = thisItem.cells[2].innerHTML.trim(); // 길이
            OneRow.processCustomizedContent.quantity = thisItem.cells[3].innerHTML.trim(); // 수량
            OneRow.processCustomizedContent.combined = thisItem.cells[4].innerHTML.trim(); // 조합
            OneRow.processCustomizedContent.part = thisItem.cells[5].innerHTML.trim(); // 잔재
            OneRow.processCustomizedContent.partWidth = thisItem.cells[6].innerHTML.trim(); // 부재길이
            OneRow.processCustomizedContent.each = thisItem.cells[7].innerHTML.trim(); // 수량
            OneRow.processCustomizedContent.total = thisItem.cells[8].innerHTML.trim(); // 총수량
            OneRow.processCustomizedContent.mark = thisItem.cells[9].innerHTML.trim(); // Mark

            result.push(OneRow);
          }
        }
      });
      console.log('result : ', result);

      setFileCP(result);
    }
  };
  // 체크리스트 테이블
  const setCheckTable = () => {
    const resultRows = document.querySelectorAll('#checkFileTable tr');
    console.log('setCheckTable : ', resultRows);

    let headerRowIndex = null;
    if (resultRows.length > 0) {
      resultRows.forEach((thisItem) => {
        // if(thisItem.cells[0].innerHTML.replaceAll(' ', '') === 'AsmblyPos.') {
        if (thisItem.cells[0].innerHTML.replaceAll(' ', '') === '부재번호') {
          headerRowIndex = thisItem.rowIndex;
          console.log('headerRowIndex : ', headerRowIndex);
        }
      });
    }

    if (headerRowIndex !== null) {
      let result = [];
      resultRows.forEach((thisItem) => {
        if (thisItem.rowIndex >= headerRowIndex + 1) {
          if (thisItem.cells[0] !== undefined && thisItem.cells[0].innerHTML.trim() !== null && thisItem.cells[0].innerHTML.trim() !== '') {
            const OneRow = {
              existProcessOutElement: false,
              processTypeName: 'CL',
              processingCount: 1,
              processCustomizedContent: {},
              taskLogList: [],

              processName: thisItem.cells[1].innerHTML.trim(),
            };

            OneRow.processCustomizedContent.asmbly = thisItem.cells[0].innerHTML.trim(); // 부재번호
            OneRow.processCustomizedContent.profile = thisItem.cells[1].innerHTML.trim(); // 규격
            OneRow.processCustomizedContent.length = thisItem.cells[2].innerHTML.trim(); // 길이
            OneRow.processCustomizedContent.quantity = thisItem.cells[3].innerHTML.trim(); // 수량
            OneRow.processCustomizedContent.part = thisItem.cells[4].innerHTML.trim(); // 종류
            OneRow.processCustomizedContent.weight = thisItem.cells[5].innerHTML.trim(); // 개별중량
            OneRow.processCustomizedContent.name = thisItem.cells[6].innerHTML.trim(); // 비고

            result.push(OneRow);
          }
        }
      });
      console.log('result : ', result);

      setFileCL(result);
    }
  };

  const actSelectType = (e) => {
    const { type } = e.target.dataset;
    if (type.endsWith('_bl') === true) setSelectBLType(type);
    if (type.endsWith('_cp') === true) setSelectCPType(type);
    if (type.endsWith('_cl') === true) setSelectCLType(type);
  };

  // 작업저장
  const actUpdate = async (e) => {
    e.preventDefault();
    if (!checkEmptyNull(_account.accountId, false)) return alert('거래처를 선택해주세요.');
    if (!checkEmptyNull(_formData.workOrderCode, false)) return alert('작업코드를 입력해주세요.');
    if (!checkEmptyNull(_formData.workOrderName, false)) return alert('공사명을 입력해주세요.');

    const formData = { ..._formData };

    const setBL = _fileBL.map((BL) => {
      return { ...BL, processCustomizedContent: JSON.stringify(BL.processCustomizedContent) };
    });
    const setCP = _fileCP.map((CP) => {
      return { ...CP, processCustomizedContent: JSON.stringify(CP.processCustomizedContent) };
    });
    const setCL = _fileCL.map((CL) => {
      return { ...CL, processCustomizedContent: JSON.stringify(CL.processCustomizedContent) };
    });
    
    let deleteProcessLogList = [];
    let setProcessLogList_BL = [];
    switch (_selectBLType) {
      case 'replace_bl': 
        if(!checkNullArray(setBL, false)) {
          setProcessLogList_BL = setBL
          _prevBL.forEach((thisItem) => { deleteProcessLogList.push(thisItem.processLogId); });
        } else {
          setProcessLogList_BL = _prevBL
        }
        break;
      case 'add_bl': setProcessLogList_BL = [..._prevBL, ...setBL]; break;
      default: setProcessLogList_BL = _prevBL; break;
    }
    let setProcessLogList_CP = [];
    switch (_selectCPType) {
      case 'replace_cp': 
        if(!checkNullArray(setCP, false)) {
          setProcessLogList_CP = setBL
          _prevCP.forEach((thisItem) => { deleteProcessLogList.push(thisItem.processLogId); });
        } else {
          setProcessLogList_CP = _prevCP
        }
        break;
      case 'add_cp': setProcessLogList_CP = [..._prevCP, ...setCP]; break;
      default: setProcessLogList_CP = _prevCP; break;
    }
    let setProcessLogList_CL = [];
    switch (_selectCLType) {
      case 'replace_cl': 
        if(!checkNullArray(setCL, false)) {
          setProcessLogList_CL = setBL
          _prevCL.forEach((thisItem) => { deleteProcessLogList.push(thisItem.processLogId); });
        } else {
          setProcessLogList_CL = _prevCL
        }
        break;
      case 'add_cl': setProcessLogList_CL = [..._prevCL, ...setCL]; break;
      default: setProcessLogList_CL = _prevCL; break;
    }

    const BodyToPut = {
      ...formData,
      workOrderCustomizedContent: JSON.stringify({}),

      accountId: _account.accountId,

      processLogList: [...setProcessLogList_BL, ...setProcessLogList_CP, ...setProcessLogList_CL],
      deleteProcessLogList: checkNullArray(deleteProcessLogList, []),
    };
    console.log('BodyToPut : ', BodyToPut);

    await workOrderLogApi.updateWorkOrderLog(_formData.workOrderLogId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.updateWorkOrderLog : ', response);
      if (response.status === 200) {
        alert('작업지시를 수정했습니다.');
        navigate(pageReducer.currentPage, { replace: true });
      }
    })
  };

  /* =================================================== #6들 =================================================== */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <>
        <main className="Main">
        <NavBar nav={''} title={<NavTitle menuCode={'403'} />} />

        <Form
          title={<><NavTitle menuCode={'403'} />수정</>}
          buttons={
            <>
              <button className="formButton" onClick={actUpdate}>저장</button>
              <button className="formButton cancle"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(pageReducer.currentPage, { replace: true });
                }}
              >
                취소
              </button>
            </>
          }
          forms={
            <>
            <div className="formBox">
              <div style={{display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%'}}>
                <h4>거래처</h4>
                <div className="formButton"
                  style={{backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px'}}
                  onClick={(e) => {e.preventDefault(); setModalStatus(() => {return true});}}
                >
                  거래처 선택
                </div>
              </div>
              <input
                type="data"
                name="accountId"
                data-required="required"
                readOnly
                placeholder=" 거래처를 선택해주세요."
                value={checkEmptyNull(_account.accountName, '')}
              />
            </div>

              <div className="formBox">
                <h4>작업코드</h4>
                <input
                  type="data"
                  name="workOrderCode"
                  data-required="required"
                  placeholder="* 작업코드..."
                  value={_formData.workOrderCode}
                  onChange={handleInputEvent}
                />
              </div>

              <div className="formBox">
                <h4>공사명</h4>
                <input
                  type="text"
                  name="workOrderName"
                  data-required="required"
                  placeholder="* 공사명..."
                  value={_formData.workOrderName}
                  onChange={handleInputEvent}
                />
              </div>

              <div className="formBox">
                <h4>{userReducer.endDateName[0]}</h4>
                <ContractDate
                  type="date"
                  name="workOrderScheduledStartDate"
                  value={_formData.workOrderScheduledStartDate}
                  onChange={handleInputEvent}
                ></ContractDate>
              </div>

              <div className="formBox">
                <h4>{userReducer.endDateName[1]}</h4>
                <ContractDate
                  type="date"
                  name="workOrderScheduledEndDate"
                  value={_formData.workOrderScheduledEndDate}
                  onChange={handleInputEvent}
                ></ContractDate>
              </div>

              <div className="formBox">
                <h4>현장명</h4>
                <input
                  type="text"
                  name="workPlace"
                  placeholder="현장명..."
                  value={_formData.workPlace}
                  onChange={handleInputEvent}
                />
              </div>

              <div className="formBox">
                <h4>현장주소</h4>
                <input
                  type="text"
                  name="detailedWorkPlace"
                  placeholder="현장주소..."
                  value={_formData.detailedWorkPlace}
                  onChange={handleInputEvent}
                />
              </div>

              <div className="formBox w100">
                <h4>캠버리스트</h4>
                <SelectButtonSection>
                  <SelectButton type="add_bl" data-type="add_bl" onClick={actSelectType}>엑셀 추가하기</SelectButton>
                  <SelectButton type="replace_bl" data-type="replace_bl" onClick={actSelectType}>엑셀 덮어쓰기</SelectButton>
                  <SelectButton type="cancel_bl" data-type="cancel_bl" onClick={actSelectType}>취소</SelectButton>
                </SelectButtonSection>

                {(_selectBLType === 'replace_bl' || _selectBLType === 'add_bl') ? (
                  <SelectSection className="formBox w100">
                    <h4>
                      캠버리스트 - 엑셀 {_selectBLType === 'replace_bl' ? '덮어쓰기' : _selectBLType === 'add_bl' ? '추가하기' : null} 업로드
                    </h4>
                    <input
                      type="file"
                      id="camberFileInput"
                      style={{ display: 'none' }}
                      onChange={setCamberFile}
                    />
                    <FileLabelStyle htmlFor="camberFileInput"
                      style={_selectBLType === 'replace_bl' ? { backgroundColor: 'var(--ThirdBlue)' } : _selectBLType === 'add_bl' ? { backgroundColor: 'var(--MainBlue)' } : null}
                    >
                      업로드
                    </FileLabelStyle>
                    <FileTableSection
                      onClick={() => {
                        document.getElementById('camberFileInput').value = '';
                        document.getElementById('camberFileTable').innerHTML = '';
                        setFileCP([]);
                      }}
                    >
                      <TableSection content={<table id="camberFileTable"></table>} />
                    </FileTableSection>

                    <div className="cautionText" style={{ fontSize: '14px', textAlign: 'right', width: '100%' }}>
                      {_selectBLType === 'replace_bl' ? '※ 기존 캠버리스트의 모든 정보가 새 엑셀 데이터로 교체됩니다.' : _selectBLType === 'add_bl' ? '※ 기존 캠버리스트에 새 엑셀 데이터가 추가됩니다.' : null}
                    </div>
                  </SelectSection>
                ) : null}
              </div>

              <div className="formBox w100">
                <h4>조합리스트</h4>
                <SelectButtonSection>
                  <SelectButton type="add_cp" data-type="add_cp" onClick={actSelectType}>엑셀 추가하기</SelectButton>
                  <SelectButton type="replace_cp" data-type="replace_cp" onClick={actSelectType}>엑셀 덮어쓰기</SelectButton>
                  <SelectButton type="cancel_cp" data-type="cancel_cp" onClick={actSelectType}>취소</SelectButton>
                </SelectButtonSection>

                {(_selectCPType === 'replace_cp' || _selectCPType === 'add_cp') ? (
                  <SelectSection className="formBox w100">
                    <h4>
                      조합리스트 - 엑셀 {_selectCPType === 'replace_cp' ? '덮어쓰기' : _selectCPType === 'add_cp' ? '추가하기' : null} 업로드
                    </h4>
                    <input
                      type="file"
                      id="combinationFileInput"
                      style={{ display: 'none' }}
                      onChange={setCombinationFile}
                    />
                    <FileLabelStyle htmlFor="combinationFileInput"
                      style={_selectCPType === 'replace_cp' ? { backgroundColor: 'var(--ThirdBlue)' } : _selectCPType === 'add_cp' ? { backgroundColor: 'var(--MainBlue)' } : null}
                    >
                      업로드
                    </FileLabelStyle>
                    <FileTableSection
                      onClick={() => {
                        document.getElementById('combinationFileInput').value = '';
                        document.getElementById('combinationFileTable').innerHTML = '';
                        setFileCP([]);
                      }}
                    >
                      <TableSection content={<table id="combinationFileTable"></table>} />
                    </FileTableSection>

                    <div className="cautionText" style={{ fontSize: '14px', textAlign: 'right', width: '100%' }}>
                      {_selectCPType === 'replace_cp' ? '※ 기존 조합리스트의 모든 정보가 새 엑셀 데이터로 교체됩니다.' : _selectCPType === 'add_cp' ? '※ 기존 조합리스트에 새 엑셀 데이터가 추가됩니다.' : null}
                    </div>
                  </SelectSection>
                ) : null}
              </div>

              <div className="formBox w100">
                <h4>체크리스트</h4>
                <SelectButtonSection>
                  <SelectButton type="add_cl" data-type="add_cl" onClick={actSelectType}>엑셀 추가하기</SelectButton>
                  <SelectButton type="replace_cl" data-type="replace_cl" onClick={actSelectType}>엑셀 덮어쓰기</SelectButton>
                  <SelectButton type="cancel_cl" data-type="cancel_cl" onClick={actSelectType}>취소</SelectButton>
                </SelectButtonSection>

                {(_selectCLType === 'replace_cl' || _selectCLType === 'add_cl') ? (
                  <SelectSection className="formBox w100">
                    <h4>
                      체크리스트 - 엑셀 {_selectCLType === 'replace_cl' ? '덮어쓰기' : _selectCLType === 'add_cl' ? '추가하기' : null} 업로드
                    </h4>
                    <input
                      type="file"
                      id="checkFileInput"
                      style={{ display: 'none' }}
                      onChange={setCheckFile}
                    />
                    <FileLabelStyle htmlFor="checkFileInput"
                      style={_selectCLType === 'replace_cl' ? { backgroundColor: 'var(--ThirdBlue)' } : _selectCLType === 'add_cl' ? { backgroundColor: 'var(--MainBlue)' } : null}
                    >
                      업로드
                    </FileLabelStyle>
                    <FileTableSection
                      onClick={() => {
                        document.getElementById('checkFileInput').value = '';
                        document.getElementById('checkFileTable').innerHTML = '';
                        setFileCL([]);
                      }}
                    >
                      <TableSection content={<table id="checkFileTable"></table>} />
                    </FileTableSection>

                    <div className="cautionText" style={{ fontSize: '14px', textAlign: 'right', width: '100%' }}>
                      {_selectCLType === 'replace_cl' ? '※ 기존 체크리스트의 모든 정보가 새 엑셀 데이터로 교체됩니다.' : _selectCLType === 'add_cl' ? '※ 기존 체크리스트에 새 엑셀 데이터가 추가됩니다.' : null}
                    </div>
                  </SelectSection>
                ) : null}
              </div>
            </>
          }
          formSectionStyle={{paddingTop: '10px', width: '100%'}}
          />
        </main>

        {_modalStatus === true ? (
          <SelectOneAccount
            buttonTitle={'거래처 선택'}
            buttonEvent={(data) => {
              setAccount(() => { return data; });
              setTimeout(setModalStatus(false), 1000);
            }}
            open={_modalStatus}
            close={() => { setModalStatus(false); }}
          ></SelectOneAccount>
        ) : null}
      </>
    }
    />
  );
};

export default UpdateGSProductionOrder;
