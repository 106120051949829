import { AxiosInterceptor } from "api/core/consts";

export const mallUserApi = {
  getMallUser: (userId) => AxiosInterceptor.get('user/' + userId),
  searchMallUser: (paging, body) => AxiosInterceptor.post('user/search' + paging, body),
  createMallUser: (body) => AxiosInterceptor.post('user', body),
  updateMallUser: (userId, body) => AxiosInterceptor.put('user/' + userId, body),
  deleteMallUser: (userId) => AxiosInterceptor.delete('user/' + userId),
  permitMallUserEmail: (userId) => AxiosInterceptor.get('user/permitEmail/' + userId), // 이메일 인증
  findMallUserEmail: (body) => AxiosInterceptor.post('user/findUserEmail', body), // 이메일 찾기
  findMallUserPassword: (body) => AxiosInterceptor.post('user/findUserPassword', body), // 비밀번호 찾기
};
