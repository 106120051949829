export const mallSiteElementActions_setElementType = (elementType) => {
  return {
    type: 'SETMALLSITEELEMENTTYPE',
    payload: elementType,
  };
};
export const mallSiteElementActions_setSearchData = (searchData) => {
  return {
    type: 'SETMALLSITEELEMENTSEARCHDATA',
    payload: searchData,
  };
};
export const mallSiteElementActions_setSearchAttrs = (serachAttrs) => {
  return {
    type: 'SETMALLSITEELEMENTSEARCHATTS',
    payload: serachAttrs,
  };
};

export const mallSiteElementActions_setClickedCategory = (category) => {
  return {
    type: 'SETMALLSITEELEMENTCLICKEDCATEGORY',
    payload: category,
  };
};
export const mallSiteElementActions_setClickedLevel1Category = (category) => {
  return {
    type: 'SETMALLSITEELEMENTCLICKEDLEVEL1CATEGORY',
    payload: category,
  };
};
export const mallSiteElementActions_setClickedLevel2Category = (category) => {
  return {
    type: 'SETMALLSITEELEMENTCLICKEDLEVEL2CATEGORY',
    payload: category,
  };
};
export const mallSiteElementActions_setClickedLevel3Category = (category) => {
  return {
    type: 'SETMALLSITEELEMENTCLICKEDLEVEL3CATEGORY',
    payload: category,
  };
};
export const mallSiteElementActions_setClickedLevel4Category = (category) => {
  return {
    type: 'SETMALLSITEELEMENTCLICKEDLEVEL4CATEGORY',
    payload: category,
  };
};
export const mallSiteElementActions_setClickedLevel5Category = (category) => {
  return {
    type: 'SETMALLSITEELEMENTCLICKEDLEVEL5CATEGORY',
    payload: category,
  };
};

export const mallSiteElementActions_setPageNumber = (pageNum) => {
  return {
    type: 'SETMALLSITEELEMENTPAGENUMBER',
    payload: pageNum,
  };
};
export const mallSiteElementActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETMALLSITEELEMENTTOTALSIZE',
    payload: totalSize,
  };
};
export const mallSiteElementActions_setIsPushedSearchButton = (status) => {
  return {
    type: 'SETMALLSITEELEMENTISPUSHEDSEARCHBUTTON',
    payload: status,
  };
};

export const mallSiteElementActions_setReset = () => {
  return {
    type: 'SETMALLSITEELEMENTRESET',
  };
};

export const mallSiteElementActions_selectOneElement = (element) => {
  return {
    type: 'SELECTONEELEMENT',
    payload: element,
  };
};

export const mallSiteElementActions_selectElements = (elements) => {
  return {
    type: 'SELECTELEMENTS',
    payload: elements,
  };
};

export const mallSiteElementActions_setLevel1Categories = (category) => {
  return {
    type: 'SETLEVEL1CATEGORIES',
    payload: category,
  };
};
export const mallSiteElementActions_setLevel2Categories = (category) => {
  return {
    type: 'SETLEVEL2CATEGORIES',
    payload: category,
  };
};
export const mallSiteElementActions_setLevel3Categories = (category) => {
  return {
    type: 'SETLEVEL3CATEGORIES',
    payload: category,
  };
};
export const mallSiteElementActions_setLevel4Categories = (category) => {
  return {
    type: 'SETLEVEL4CATEGORIES',
    payload: category,
  };
};
export const mallSiteElementActions_setLevel5Categories = (category) => {
  return {
    type: 'SETLEVEL5CATEGORIES',
    payload: category,
  };
};
