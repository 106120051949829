import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { fileDataApi } from 'api/apis/fileDataApi';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import FileEvent from 'components/file/FileEvent';
import Form from 'components/layouts/form/Form';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  max-height: 550px;
  max-width: 800px;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  label {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 60px;
    justify-content: flex-end;
    padding: 0px 20px;
    width: 100%;
  }

  & form > div {
    background-color: unset;
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const CreateFileModal = (props) => {
  /* ====================================================================== #1 */
  const { fileReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== 2 */
  const [_onload, setOnload] = useState('unload');

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    fileCategoryId: props.currentFolder.fileCategoryId,
    fileDataName: '',
  });

  const [_fileForm, setFileForm] = useState();

  /* ====================================================================== 3 */
  useEffect(() => {
    setOnload('loaded');
    return () => {};
  }, []);

  /* ====================================================================== 4 */
  const fileEvent = (act, e) => {
    let file = null;
    switch (act) {
      case 'click':
        console.log(act, e.target.files);
        file = e.target.files;
        break;

      case 'drag':
        console.log(act, e.dataTransfer.files);
        file = e.dataTransfer.files;
        break;

      default:
        return;
    }

    if (file !== null) {
      const setFile = Array.prototype.slice.call(file);
      const newFile = [...setFile];

      setFileForm(() => {
        return newFile;
      });
    }
  };

  const actCreateFile = async () => {
    const postFormData = new FormData();

    console.log('_formData : ', _formData);
    const BodyToPost = { ..._formData };
    if (
      BodyToPost.fileCategoryId === undefined ||
      BodyToPost.fileCategoryId === null
    ) {
      delete BodyToPost.fileCategoryId;
    }
    postFormData.append(
      'key',
      new Blob([JSON.stringify(_formData)], {
        type: 'application/json',
      }),
    );

    const files = Array.prototype.slice.call(_fileForm);
    const setFile = [...files];
    if (setFile.length > 0) {
      setFile.forEach((file) => {
        postFormData.append('multipartFile', file);
      });
    }

    for (let values of postFormData.values()) {
      console.log('postFormData : ', values);
    }

    await fileDataApi.createFileData(postFormData).then((response) => {
      if (response === undefined) return;
      console.log('fileDataApi.createFileData : ', response);

      props.close(response.data);
    });
  };

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>파일추가</ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              <Form
                title={
                  <>
                    파일
                    <br />
                    추가
                    <br />
                  </>
                }
                buttons={<></>}
                forms={
                  <>
                    <div className="formBox">
                      <h4>파일이름</h4>
                      <input
                        type="text"
                        name="fileDataName"
                        placeholder="파일이름..."
                        value={_formData.fileDataName}
                        onInput={(e) => {
                          setFormData((prev) => {
                            return { ...prev, fileDataName: e.target.value };
                          });
                        }}
                      />
                    </div>

                    <FileEvent
                      act={'create'}
                      fileInputName={'createFileInput'}
                      fileDataList={_fileForm || []}
                      fileEvent={fileEvent}
                    />
                  </>
                }
                formStyle={{
                  height: '100%',
                }}
                formLayoutStyle={{
                  padding: '0px 30px',
                  width: '95%',
                }}
                formSectionStyle={{
                  paddingTop: '10px',
                  width: '100%',
                }}
              />
            </ModalMain>

            <ModalFooter>
              <ModalButton onClick={actCreateFile}>추가</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default CreateFileModal;
