// 경서 현재고

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { currentStockActions_setBookmark, currentStockActions_setClickedLevel1Category, currentStockActions_setClickedLevel2Category, currentStockActions_setClickedLevel3Category, currentStockActions_setElementType, currentStockActions_setPageNumber, currentStockActions_setReset, currentStockActions_setSearchData, currentStockActions_setTotalSize } from 'store/modules/actions/common/currentStockActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { bookmark } from 'api/apis/bookmark/bookmark';
import { elementStockApi } from 'api/apis/elementStockApi';

import { checkEmptyNull, checkNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { LoadingMsg } from 'components/loading/LoadingMsg';

import Bookmark from 'components/icons/Bookmark';
import Categories from 'components/category/Categories';
import DOCButtonIcon from 'components/icons/DOCButtonIcon';
import DetailedStockModal from 'pages/stock/modal/DetailedStockModal';
import ExcelDownloadModal from 'components/excel/ExcelDownloadModal';
import Grid3Body from 'components/layouts/body/Grid3Body';
import MobileNumpad from 'pages/stock/numpad/MobileNumpad';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import ShowFileList from 'pages/file/modal/ShowFileList';
import StockPlaceHistoryModal from 'pages/stockPlaceHistory/modal/StockPlaceHistoryModal';
import TableSection from 'components/layouts/table/TableSection';

const CurrentStockSection = styled.main`
  button.detailStockButton {
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 30px;
    justify-content: center;
    width: 30px;
  }

  & .Title {
    display: flex;
    align-items: center;
    gap: 10px;
    h4 {
      padding-right: 20px;
      margin-right: 10px;
      border-right: 1px solid #ddd;
    }

    & .elementTypeButtons {
      display: flex;
      gap: 5px;
    }
  }

  & .TableSection {
    td, th {
      white-space: unset;
    }
  }
`;

const GsCurrentStock = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { currentStockReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_stockList, setStockList] = useState([]);

  const [_numpadModalStatus, setNumpadModalStatus] = useState(false);
  const [_stockType, setStockType] = useState();
  const [_stockContent, setStockContent] = useState();

  const [_barcodeModal, setBarcodeModal] = useState(false);
  const [_downloadModalStatus, setDownloadModalStatus] = useState(false);

  const [_stockPlaceModalStatus, setStockPlaceModalStatus] = useState(false);
  const [_stockDetailedModalStatus, setStockDetailedModalStatus] = useState(false);
  const [_eventPosition, setEventPosition] = useState();
  const [_eventStockElement, setEventStockElement] = useState(false);

  const [_fileListModalStatus, setFileListModalStatus] = useState(false);
  const [_eventContent, setEventContent] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('109') === true || // 파일
      thisItem.authority.startsWith('301') === true
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getStockList(currentStockReducer.pageNumber - 1, currentStockReducer.elementType);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getStockList(currentStockReducer.pageNumber - 1, currentStockReducer.elementType);
    }

    return () => { };
  }, [currentStockReducer.pageNumber]);

  useEffect(() => {
    // if (_onload === 'loaded' && Object.keys(currentStockReducer.clickedCategory).length > 0) {
    if (_onload === 'loaded') {
      dispatch(currentStockActions_setPageNumber(1));
      getStockList(0, currentStockReducer.elementType);
    }

    return () => { };
  }, [currentStockReducer.bookmark, currentStockReducer.clickedCategory]);

  /* ====================================================================== #4 */
  const getStockList = async (page, elementType, clear) => {
    let paging = `?page=${page}&size=15`;
    if (currentStockReducer.bookmark) paging += '&sort=bookMark,DESC&sort=id,DESC';
    else paging += '&sort=id,DESC';

    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && checkEmptyNull(elementType, false) && elementType !== 'all') {
      BodyToPost.elementType = elementType;
    }
    if (!clear && checkNullObject(currentStockReducer.clickedCategory, false) && currentStockReducer.clickedCategory !== 'all') {
      BodyToPost.categoryId = currentStockReducer.clickedCategory.categoryId;
    }
    if (!clear && checkEmptyNull(currentStockReducer.searchData.elementName, false)) {
      BodyToPost.elementName = currentStockReducer.searchData.elementName;
    }
    if (!clear && checkEmptyNull(currentStockReducer.searchData.elementCode, false)) {
      BodyToPost.elementCode = currentStockReducer.searchData.elementCode;
    }
    console.log('getStockList - BodyToPost : ', BodyToPost);

    await elementStockApi.searchStockList(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('elementStockApi.searchStockList : ', response);

      setStockList(() => { return response.data.content });
      dispatch(currentStockActions_setTotalSize(response.data.totalElements));
    })
  };

  /* ====================================================================== #5 */
  const actSearch = async () => {
    dispatch(currentStockActions_setPageNumber(1));
    await getStockList(0, currentStockReducer.elementType);
  };

  const actReset = async () => {
    dispatch(currentStockActions_setReset());
    dispatch(currentStockActions_setPageNumber(1));
    await getStockList(0, currentStockReducer.elementType, true);
  };

  const actDownload = () => { setDownloadModalStatus(true) };

  const handleElementType = (elementType) => {
    if (elementType === 'all') {
      dispatch(currentStockActions_setClickedLevel3Category({}));
      dispatch(currentStockActions_setClickedLevel2Category({}));
      dispatch(currentStockActions_setClickedLevel1Category({}));
    }

    dispatch(currentStockActions_setElementType(elementType));
    dispatch(currentStockActions_setPageNumber(1));

    getStockList(0, elementType);
  }

  const handleBookmark = async (element, index) => {
    await bookmark.elementBookmark(element.elementId).then(response => {
      if (response === undefined) return;
      console.log('bookmark.elementBookmark : ', response);

      getStockList(currentStockReducer.pageNumber - 1, currentStockReducer.elementType);
    })
  }

  const handleStockAct = async (stockType, element) => {
    if (!checkNullObject(element, false)) return;

    setStockType(() => { return stockType });
    setStockContent(() => { return element });

    setTimeout(setNumpadModalStatus(true), 1000);
  };

  const showStockPlace = (stockElement) => {
    setEventStockElement(() => { return stockElement });
    setTimeout(setStockPlaceModalStatus(() => { return true }), 1000);
  }
  const showDetailedStock = (e, stockElement) => {
    const positionData = e.target.getBoundingClientRect();
    // console.log('positionData : ', positionData);
    setEventPosition(() => { return positionData });

    setEventStockElement(() => { return stockElement });
    setTimeout(setStockDetailedModalStatus(() => { return true }), 1000);
  }

  /* 파일 리스트 */
  const showFileList = (element) => {
    setEventContent(() => { return element });
    setTimeout(setFileListModalStatus(true), 1000);
  };

  /* ====================================================================== #6 */

  const handleOnKeyUp = (e) => {
    if (e.key === 'Enter') {
      actSearch();
    }
  };

  return (
    <Grid3Body contents={
      <>
        <CurrentStockSection className="Main">
          <NavBar
            title={
              <div className='Title'>
                <NavTitle menuCode={'301'} />
                <button className={currentStockReducer.elementType === 'all' ? 'btn-inven-1' : 'btn-set'} onClick={() => { handleElementType('all') }}>전체</button>
                <div className='elementTypeButtons'>
                  {userReducer.elementType.map((elementType, index) => {
                    let typeText = '';
                    switch (elementType) {
                      case 'material': typeText = '자재'; break;
                      case 'semi': typeText = '부자재'; break;
                      case 'half': typeText = '판매물품'; break;
                      case 'product': typeText = '보유물품'; break;
                      case 'tool': typeText = '공구'; break;

                      default: return null;
                    }
                    return (
                      <button
                        key={index + '_elementTypeButtons'}
                        className={currentStockReducer.elementType === elementType ? 'btn-inven-1' : 'btn-set'}
                        onClick={() => { handleElementType(elementType) }}>{typeText}
                      </button>
                    );
                  })}
                </div>
              </div>
            }
            buttons={<><button className='btn-download' onClick={actDownload}>다운로드</button></>}
            nav={'all'}
            firstRow={<><div className="SearchSection"><Categories categoryType={'currentStock'} /></div></>}
            secondRow={
              <>
                <div className="SearchSection">
                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="elementName">물품이름</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkNull(currentStockReducer.searchData.elementName, '')}
                      onInput={(e) => {
                        const elementName = e.target.value;
                        dispatch(currentStockActions_setSearchData({ ...currentStockReducer.searchData, elementName: elementName }));
                      }}
                      onKeyUp={handleOnKeyUp}
                    />
                  </div>

                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="elementCode">물품코드</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkNull(currentStockReducer.searchData.elementCode, '')}
                      onInput={(e) => {
                        const elementCode = e.target.value;
                        dispatch(currentStockActions_setSearchData({ ...currentStockReducer.searchData, elementCode: elementCode }));
                      }}
                      onKeyUp={handleOnKeyUp}
                    />
                  </div>

                  <div className="ContentCBox">
                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton" onClick={actReset}>초기화</div>
                  </div>
                </div>
              </>
            }
          />
          <TableSection content={
            <table>
              <thead>
                <tr>
                  <th style={{ minWidth: '50px', width: '50px' }}>
                    <Bookmark
                      bookmark={currentStockReducer.bookmark}
                      clickEvent={() => { dispatch(currentStockActions_setBookmark(!currentStockReducer.bookmark)) }}
                    />
                  </th>
                  {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? <th style={{ minWidth: '150px', width: '150px' }}>파일</th> : null}
                  <th>물품유형</th>
                  {(() => {
                    const defaultColumns = ['패밀리', '물품이름', '물품코드', '안전재고', '적정재고', '현재고'];
                    const returnArray = [];
                    defaultColumns.forEach((column, index) => {
                      if (![1, 2, 3].includes(userReducer.company.companyId) && column === '적정재고') return;
                      returnArray.push(<th key={index + '_column'}>{column}</th>)
                    });
                    return returnArray;
                  })()}
                  <th style={{ minWidth: '260px', width: '260px' }}>입고/출고</th>
                </tr>
              </thead>
              <tbody>
                {!checkNullArray(_stockList, false) ? LoadingMsg() :
                  checkNullArray(_stockList, []).map((stockElement, index) => {
                    const elementUnit = checkNull(stockElement.elementUnit, '');
                    return (<tr key={index + '_stockElement'}>
                      <td style={{ minWidth: '50px', width: '50px' }}>
                        <Bookmark
                          bookmark={checkEmptyNull(stockElement.bookMark, false)}
                          clickEvent={() => { handleBookmark(stockElement, index) }}
                        />
                      </td>

                      {/* 파일 */}
                      {(() => {
                        if (_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) {
                          if (checkNullArray(stockElement.attachFileList, false)) {
                            return (
                              <td className='table-preview'>
                                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    showFileList(stockElement);
                                  }}
                                >
                                  <img src={stockElement.attachFileList[0]?.fileData?.fileDataS3URL || ''} alt='image preview' className='img-preview' />
                                  <div className='btn-file'><DOCButtonIcon /></div>
                                </div>
                              </td>
                            );
                          } else {
                            return (
                              <td className='table-preview'>
                                <div
                                  style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    showFileList(stockElement);
                                  }}
                                >
                                  <div className='no-image'><p>이미지가 없습니다.</p></div>
                                  <div className='btn-file'><DOCButtonIcon /></div>
                                </div>
                              </td>
                            );
                          }
                        }
                      })()}
                      <td>{(
                        stockElement.elementType === 'material' ? '자재'
                          : stockElement.elementType === 'half' ? '판매물품'
                            : stockElement.elementType === 'product' ? '보유물품'
                              : stockElement.elementType === 'semi' ? '부자재'
                                : stockElement.elementType === 'tool' ? '공구'
                                  : null
                      )}</td>
                      <td>{checkEmptyNull(stockElement.categoryPath, checkEmptyNull(stockElement.categoryName, ''))}</td>
                      <td className={checkEmptyNull(stockElement.elementName, false) && stockElement.elementName.startsWith('*') ? 'outsourcingText' : null}>
                        {stockElement.elementName}
                      </td>
                      <td>{stockElement.elementCode}</td>
                      <td>{checkEmptyNull(stockElement.elementSafeStock, 0).toLocaleString() + elementUnit}</td>
                      {[1, 2, 3].includes(userReducer.company.companyId) ? <td>{checkEmptyNull(stockElement.elementSafeStock, 0).toLocaleString() + elementUnit}</td> : null}
                      <td className={checkEmptyNull(stockElement.elementSafeStock, false) ? stockElement.elementSafeStock <= stockElement.elementStock ? 'safeStock' : 'dangerStock' : ''}>
                        {checkEmptyNull(stockElement.elementStock, 0).toLocaleString() + ' ' + elementUnit}
                      </td>
                      <td style={{ minWidth: '260px', padding: 'unset', width: '260px' }}>
                        <div className='StockButtons'>
                          <button className='btn-set' data-type="재고위치" onClick={() => { showStockPlace(stockElement) }}>재고위치</button>
                          {_authority.indexOf('301-2') !== -1 ? <>
                            <button className='btn-inven-2' data-type="입고" onClick={() => { handleStockAct('In', stockElement) }}>입고</button>
                            <button className='btn-inven-4' data-type="출고" onClick={() => { handleStockAct('Out', stockElement) }}>출고</button>
                          </> : null}
                          <button className='detailStockButton' onClick={(e) => { showDetailedStock(e, stockElement) }}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="var(--MainBlue)">
                              <path d="M0 0h24v24H0V0z" fill="none" />
                              <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                            </svg>
                          </button>
                        </div>
                      </td>
                    </tr>)
                  })}
              </tbody>
            </table>
          }
            sectionStyle={{ height: 'calc(100% - 66px)' }}
          ></TableSection>
        </CurrentStockSection>

        <PagingComponent
          page={currentStockReducer.pageNumber}
          count={currentStockReducer.totalSize}
          size={15}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(currentStockActions_setPageNumber(page));
          }}
        />

        {_stockPlaceModalStatus === true && (
          <StockPlaceHistoryModal
            stockElement={_eventStockElement}
            open={_stockPlaceModalStatus}
            close={() => { setStockPlaceModalStatus(false) }}
          ></StockPlaceHistoryModal>
        )}

        {_numpadModalStatus === true && (
          <MobileNumpad
            type={_stockType}
            content={_stockContent}
            open={_numpadModalStatus}
            close={() => {
              setNumpadModalStatus(false);
              getStockList(currentStockReducer.pageNumber - 1, currentStockReducer.elementType);
            }}
          ></MobileNumpad>
        )}

        {_stockDetailedModalStatus === true && (
          <DetailedStockModal
            stockElement={_eventStockElement}
            eventPosition={_eventPosition}
            open={_stockDetailedModalStatus}
            close={() => { setStockDetailedModalStatus(false) }}
          ></DetailedStockModal>
        )}

        {_downloadModalStatus === true && (
          <ExcelDownloadModal
            mainTitle={'gsproducts'}
            subTitle={'CurrentStock'}
            open={_downloadModalStatus}
            close={() => { setDownloadModalStatus(false) }}
          ></ExcelDownloadModal>
        )}

        {_fileListModalStatus === true && checkEmptyNull(_eventContent.elementId, false) ? (
          <ShowFileList
            type={'element'}
            content={_eventContent}
            open={_fileListModalStatus}
            close={() => { setFileListModalStatus(false) }}
          ></ShowFileList>
        ) : null}
      </>
    }
    />
  );
};

export default GsCurrentStock;
