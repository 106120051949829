import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Element from 'pages/barcode/scan/infos/Element';
import StockLog from 'pages/barcode/scan/infos/StockLog';
import Lot from 'pages/barcode/scan/infos/Lot';
import Serial from 'pages/barcode/scan/infos/Serial';

const BarcodeSection = styled.section`
  align-content: stretch;
  box-sizing: border-box;
  cursor: default;
  display: grid;
  gap: 10px;
  grid-template-rows: 120px calc(100% - 200px) 60px;
  height: 100svh;
  max-width: 1200px;
  padding: 3svh 3svw;
  width: 100svw;

  & .header-barcode {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: space-between;
    width: 100%;

    & h2 {
      align-items: center;
      color: var(--white);
      display: flex;
      height: 100%;
      justify-content: center;
      gap: 15px;
      max-width: 60%;
      
      & div {
        background-color: rgba(255, 255, 255, 0.7);
        height: 25px;
        width: 4px;
      }
    }

    & .btn-infos {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 10px;
      height: 100%;
      width: 40%;

      & .btn-info {
        background-color: var(--white);
        height: 100%;
        width: 100%;

        &.active {
          background-color: var(--MainBlue);
          color: var(--white);
        }
      }
    }
  }

  & .main-barcode {
    background-color: rgba(255, 255, 255, 0.7);
    box-sizing: border-box;
    height: 100%;
    overflow: auto;
    padding: 20px;
    position: relative;
    width: 100%;
  }

  & .footer-barcode {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    gap: 20px;
    width: 100%;

    & .link-barcode {
      & div, & a {
        border-bottom: 1px solid var(--white);
        box-sizing: border-box;
        color: var(--white);
        cursor: pointer;
        font-weight: 600;
        justify-self: flex-start;
        padding-bottom: 3px;
        width: fit-content;
        &:hover {
          transform: scale(1.1);
        }
      }
      & p {
          color: var(--white);
          font-size: 0.7em;
          justify-self: flex-start;
          margin-top: 2px;
          opacity: 0.7;
      }
    }
  }
`;

const BarcodeInfo = (props) => {
  const { barcodeReducer } = useSelector(state => state);

  useEffect(() => {
    console.log('barcode : ', barcodeReducer.barcode);
  }, [barcodeReducer.barcode])

  return (
    <BarcodeSection>
      {(() => {
        const barcodeCode = barcodeReducer.barcode.charAt(0).toUpperCase();
        switch (barcodeCode) {
          case 'A': return <Element />;
          case 'B': return <StockLog />;
          case 'C': return <Lot />;
          case 'D': return <Serial />;

          default: return null;
        }
      })()}
      <div className='footer-barcode'>
        <div className='link-barcode'>
          <div onClick={() => { props.actClear(); }}>바코드 스캔</div>
          <p>바코드 스캔 페이지로 넘어갑니다.</p>
        </div>
        <div className='link-barcode'>
          <Link to={'/barcode/print'}>바코드 출력</Link>
          <p>바코드 출력 페이지로 넘어갑니다.</p>
        </div>
      </div>
    </BarcodeSection>
  );
};

export default BarcodeInfo;