import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

import { element } from 'api/apis/mall/element';

import { checkNullArray } from 'components/checkValues/checkValues';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 85%;
  justify-content: flex-start;
  max-height: 520px;
  max-width: 710px;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  height: calc(100% - 55px);
  overflow: auto;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}
`;

const ContentSection = styled.div`
  box-sizing: border-box;
  display: grid;
  gap: 20px;
  height: 100%;
  padding: 20px;
  width: 100%;

  &>ul {
    & li {
      color: var(--MainRed);
      font-size: 15px;
    }
  }
`;
const ButtonsSection = styled.div`
  align-items: center;
  column-gap: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
`;
const FormatButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  border-radius: 10px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 200px;
  justify-content: center;
  width: 50%;
`;
const UploadModalButton = styled.label`
  align-items: center;
  background-color: var(--ThirdBlue);
  border-radius: 10px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 200px;
  justify-content: center;
  width: 50%;
`;

const LoadingScreen = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  color: var(--white);
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 999;
`;

const MallElementUploadModal = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_loadingStatus, setLoadingStatus] = useState(false);

  /* ====================================================================== #3 */
  /* ====================================================================== #4 */
  const excelExportCommon = (e, callback) => {
    const files = e.target.files;
    const file = files[0];
    if (file === undefined) return;

    const reader = new FileReader();
    reader.onload = async () => {
      const fileData = reader.result;
      const wb = XLSX.read(fileData, { type: 'binary' });
      const sheetNameList = wb.SheetNames; // 시트 이름 목록 가져오기
      for (let i = 0; i < sheetNameList.length; i++) {
        const sheetName = sheetNameList[i];
        const sheet = wb.Sheets[sheetName];
        await callback(sheet);
      }
    };

    reader.readAsBinaryString(file);
  };
  const excelSetting = async (sheet) => {
    const sendData = { companyId: userReducer.company.companyId };
    const result = XLSX.utils.sheet_to_json(sheet);

    const sendDataList = result.map((element) => {
      const newData = {
        attributeAndValue: {},
      };
      for (const key in element) {
        const value = element[key];
        switch (key) {
          case '카테고리': newData.mallCategoryName = value; break;
          case '등록물품코드': newData.elementCode = value; break;
          case '판매물품코드': newData.mallElementCode = value; break;
          case '판매물품이름': newData.mallElementName = value; break;
          case '가격': newData.mallElementPrice = value; break;
          case '규격': newData.mallElementSize = value; break;
          case '설명': newData.mallElementContent = value; break;

          default: newData[key] = value; break;
        }
      }
      return newData;
    });
    sendData['mallElements'] = checkNullArray(sendDataList, []);

    if (result.length === sendData.mallElements.length) {
      console.log('sendData : ', sendData);
      console.log('sendData - stringify : ', JSON.stringify(sendData));
      await postElements(sendData);
    }
  };
  const postElements = async (sendData) => {
    const successAlertText = `
      판매물품을 업로드했습니다.
    `;
    const failedAlertText = `
      판매물품을 업로드 할 수 없습니다.
      해당 파일을 확인해주세요.
    `;
    await element.uploadMallElements(sendData).then((response) => {
      if (response === undefined) return;
      console.log('element.uploadMallElements : ', response);
      if (response.data === 'success') alert(successAlertText);
      else alert(failedAlertText);
    }).catch((error) => {
      console.log('elementApi.uploadElements : ', error);
      alert(failedAlertText);
    }).finally(() => {
      setLoadingStatus(false);
      props.close();
    });
  };

  /* ====================================================================== #5 */

  const downloadFormat = () => {
    const headerRow = [{
      카테고리: '',
      등록물품코드: '',
      판매물품코드: '',
      판매물품이름: '',
      가격: '',
      규격: '',
      설명: '',
    }];
    const ws = XLSX.utils.json_to_sheet(headerRow);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `판매물품 리스트`);
    XLSX.writeFile(wb, `판매물품 업로드 양식.xlsx`);
  };

  const selectUploadFile = (e) => {
    const confirmText = `판매물품을 업로드 하시겠습니까?`;
    if (window.confirm(confirmText) === true) {
      setLoadingStatus(true);
      const files = e.target.files;
      const file = files[0];
      if (file === undefined) return;
      excelExportCommon(e, excelSetting);
    } else return;
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>판매물품 업로드</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <ContentSection>
                <ButtonsSection>
                  <FormatButton onClick={downloadFormat}>양식 다운로드</FormatButton>

                  <input
                    type="file"
                    id="UploadModalInput"
                    style={{ display: 'none' }}
                    accept=".xlsx, .xls, .csv"
                    onChange={selectUploadFile}
                  />
                  <UploadModalButton htmlFor="UploadModalInput">업로드</UploadModalButton>
                </ButtonsSection>

                <ul>
                  <li>※ 순서 ※</li>
                  <li>1. 양식 다운로드 → 양식에 맞춘 엑셀 준비</li>
                  <li>※ 엑셀 내 소수점이 있는 경우 셀서식 - 표시형식 - 숫자 - 소수자릿수를 0으로 설정한 후 업로드 해주세요.</li>
                  <li>※ 패밀리를 <span>{`>>`}</span> 기호로 구분해주세요</li>
                  <li>2. 업로드</li>
                </ul>
              </ContentSection>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}

      {_loadingStatus === true ? (<LoadingScreen>판매물품을 업로드하고 있습니다...</LoadingScreen>) : null}
    </>
  );
};

export default MallElementUploadModal;
