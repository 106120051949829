const initialState = {
  elementType: 'material',
  searchData: {
    elementName: '',
    elementCode: '',
  },
  searchAttrs: {},

  clickedCategory: {},
  clickedLevel1Category: {},
  clickedLevel2Category: {},
  clickedLevel3Category: {},
  clickedLevel4Category: {},
  clickedLevel5Category: {},

  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,

  element: {},
  elements: [],

  level1Categories: [],
  level2Categories: [],
  level3Categories: [],
  level4Categories: [],
  level5Categories: [],

  bookmark: true,
};

const resetState = {
  elementType: 'material',
  searchData: {
    elementName: '',
    elementCode: '',
  },
  searchAttrs: {},

  clickedCategory: {},
  clickedLevel1Category: {},
  clickedLevel2Category: {},
  clickedLevel3Category: {},
  clickedLevel4Category: {},
  clickedLevel5Category: {},

  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const elementReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETELEMENTTYPE':
      return {
        ...state,
        elementType: action.payload,
      };
    case 'SETELEMENTSEARCHDATA':
      return {
        ...state,
        searchData: action.payload,
      };
    case 'SETELEMENTSEARCHATTS':
      return {
        ...state,
        searchAttrs: action.payload,
      };

    case 'SETELEMENTCLICKEDCATEGORY':
      return {
        ...state,
        clickedCategory: action.payload,
      };
    case 'SETELEMENTCLICKEDLEVEL1CATEGORY':
      return {
        ...state,
        clickedLevel1Category: action.payload,
      };
    case 'SETELEMENTCLICKEDLEVEL2CATEGORY':
      return {
        ...state,
        clickedLevel2Category: action.payload,
      };
    case 'SETELEMENTCLICKEDLEVEL3CATEGORY':
      return {
        ...state,
        clickedLevel3Category: action.payload,
      };
    case 'SETELEMENTCLICKEDLEVEL4CATEGORY':
      return {
        ...state,
        clickedLevel4Category: action.payload,
      };
    case 'SETELEMENTCLICKEDLEVEL5CATEGORY':
      return {
        ...state,
        clickedLevel5Category: action.payload,
      };

    case 'SETELEMENTPAGENUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'SETELEMENTTOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };
    case 'SETELEMENTISPUSHEDSEARCHBUTTON':
      return {
        ...state,
        isPushedSearchButton: action.payload,
      };

    case 'SETELEMENTRESET':
      return {
        ...state,
        ...resetState,
      };

    case 'SELECTONEELEMENT':
      return {
        ...state,
        element: action.payload,
      };

    case 'SELECTELEMENTS':
      return {
        ...state,
        elements: action.payload,
      };

    case 'SETLEVEL1CATEGORIES':
      return {
        ...state,
        level1Categories: action.payload,
      };
    case 'SETLEVEL2CATEGORIES':
      return {
        ...state,
        level2Categories: action.payload,
      };
    case 'SETLEVEL3CATEGORIES':
      return {
        ...state,
        level3Categories: action.payload,
      };
    case 'SETLEVEL4CATEGORIES':
      return {
        ...state,
        level4Categories: action.payload,
      };
    case 'SETLEVEL5CATEGORIES':
      return {
        ...state,
        level5Categories: action.payload,
      };

    case 'SETELEMENTBOOKMARK':
      return {
        ...state,
        bookmark: action.payload,
      };

    default:
      return state;
  }
};

export default elementReducer;
