import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

import { accountTypeApi } from 'api/apis/accountTypeApi';
import { accountAttributeApi } from 'api/apis/accountAttributeApi';
import { accountApi } from 'api/apis/accountApi';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 85%;
  justify-content: flex-start;
  max-height: 500px;
  max-width: 710px;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  height: calc(100% - 55px);
  overflow: auto;
  width: 100%;

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 6px;
  }
`;

const ContentSection = styled.div`
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
  width: 100%;
`;

const SelectSection = styled.select`
  background-color: var(--MainNavy);
  border-radius: 5px;
  color: var(--white);
  font-size: 18px;
  height: 50px;
  padding: 10px;
  width: 100%;
`;

const ButtonsSection = styled.div`
  align-items: center;
  column-gap: 10px;
  display: flex;
  justify-content: center;
  margin: 20px auto;
  width: 100%;
`;

const FormatButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  border-radius: 10px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 200px;
  justify-content: center;
  width: 50%;
`;

const UploadModalButton = styled.label`
  align-items: center;
  background-color: var(--ThirdBlue);
  border-radius: 10px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 200px;
  justify-content: center;
  width: 50%;
`;

const NoticeSection = styled.div`
  color: var(--MainRed);
  font-size: 15px;
  padding-bottom: 20px;
`;

const LoadingScreen = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  color: var(--white);
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 999;
`;

const AccountUploadModal = (props) => {
  const UploadAccountInput = useRef();

  const { userReducer } = useSelector((state) => state);

  const [_accountTypeList, setAccountTypeList] = useState([]);
  const [_accountType, setAccountType] = useState('');

  const [_accountAttrList, setAccountAttrList] = useState([]);

  const [_loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => {
    getAccountTypes();

    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const getAccountTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await accountTypeApi.searchAccountType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('accountTypeApi.searchAccountType : ', response);

      setAccountTypeList(() => {
        return response.data;
        // return response.data.content;
      });
    });
  };

  const getAccountAttrs = async (thisType) => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      accountTypeId: thisType,
    };
    await accountAttributeApi
      .searchAccountAttribute(BodyToPost)
      .then((response) => {
        if (response === undefined) return;
        console.log('accountAttributeApi.searchAccountAttribute : ', response);

        setAccountAttrList(() => {
          return response.data;
        });
      });
  };

  const excelExportCommon = (e, callback) => {
    const fileList = e.target.files;
    const thisFile = fileList[0];
    console.log(thisFile);
    if (thisFile === undefined) return;

    const reader = new FileReader();
    reader.onload = async () => {
      const fileData = reader.result;
      const wb = XLSX.read(fileData, { type: 'binary' });
      const sheetNameList = wb.SheetNames; // 시트 이름 목록 가져오기
      for (let i = 0; i < sheetNameList.length; i++) {
        const thisSheetName = sheetNameList[i];
        const thisSheet = wb.Sheets[thisSheetName];

        await callback(thisSheet);
      }
    };

    reader.readAsBinaryString(thisFile);
  };

  const excelSetting = async (sheet) => {
    console.log('sheet : ', sheet);
    const sendData = {
      companyId: userReducer.company.companyId,
      accounts: [],
    };
    const result = XLSX.utils.sheet_to_json(sheet);

    const typeData = _accountTypeList.find((thisItem) => {
      if (thisItem.accountTypeId === Number(_accountType)) {
        return true;
      } else return false;
    });

    if (typeData === undefined)
      return alert(`
              업로드를 할 수 없습니다.
              해당 파일을 확인해주세요.
            `);

    const setSendData = result.map((thisItem) => {
      const newData = {
        attributeAndValue: {},
      };
      newData.accountTypeId = typeData.accountTypeId;
      newData.accountTypeName = typeData.accountTypeName;

      for (const key in thisItem) {
        const value = thisItem[key];

        switch (key) {
          case '수요기관코드':
            newData.accountCode = value;
            break;
          case '사업자등록번호':
            newData.businessNumber = value;
            break;
          case '회사명':
            newData.accountName = value;
            break;
          case '지역명':
            newData.regionName = value;
            break;
          case '주소':
            newData.address = value;
            break;
          case '상세주소':
            newData.detailAddress = value;
            break;
          case '우편번호':
            newData.zip = value;
            break;
          case '담당자':
            newData.managerName = value;
            break;
          case '전화번호':
            newData.telNumber = value;
            break;
          case '휴대폰':
            newData.phoneNumber = value;
            break;
          case '팩스번호':
            newData.faxNumber = value;
            break;
          case '이메일':
            newData.email = value;
            break;

          default:
            newData.attributeAndValue[key] = value;
            break;
        }
      }

      return newData;
    });
    console.log('setSendData : ', setSendData);
    sendData['accounts'] = setSendData;

    console.log('sendData : ', sendData);
    console.log('sendData - stringify : ', JSON.stringify(sendData));

    if (result.length === sendData.accounts.length) {
      await postAccounts(sendData);
    }
  };
  const postAccounts = async (sendData) => {
    await accountApi
      .uploadAccount(sendData)
      .then((response) => {
        if (response === undefined) return;
        console.log('accountApi.uploadAccount : ', response);

        if (response.data === 'success') {
          alert('거래처정보를 업로드했습니다.');
        } else if (
          response.data.status !== undefined &&
          response.status === 200
        ) {
          alert('거래처정보를 업로드했습니다.');
        } else if (
          response.data.status !== undefined &&
          response.data.status === 409 &&
          response.data.message !== undefined &&
          response.data.message !== null &&
          response.data.message.length > 0
        ) {
          alert(response.data.message);
        } else {
          alert(`
            업로드를 할 수 없습니다.
            해당 파일을 확인해주세요.
          `);
        }
      })
      .catch((error) => {
        console.log('accountApi.uploadAccount : ', error.response);
        alert(`
          업로드를 할 수 없습니다.
          해당 파일을 확인해주세요.
        `);
      })
      .finally(() => {
        setLoadingStatus(false);
        props.close();
      });
  };

  /* ====================================================================== #5 */

  const downloadFormat = () => {
    if (_accountType === '') return alert('거래처 유형을 선택해주세요.');

    const typeData = _accountTypeList.find((thisItem) => {
      if (thisItem.accountTypeId === Number(_accountType)) {
        return true;
      } else return false;
    });

    let headerRow = [];

    const OneRow = {};
    OneRow['수요기관코드'] = '';
    OneRow['사업자등록번호'] = '';
    OneRow['회사명'] = '';
    OneRow['지역명'] = '';
    OneRow['주소'] = '';
    OneRow['상세주소'] = '';
    OneRow['우편번호'] = '';
    OneRow['담당자'] = '';
    OneRow['전화번호'] = '';
    OneRow['휴대폰'] = '';
    OneRow['팩스번호'] = '';
    OneRow['이메일'] = '';
    _accountAttrList.forEach((thisItem) => {
      OneRow[thisItem.accountAttributeName] = '';
    });
    headerRow.push(OneRow);
    console.log('headerRow : ', headerRow);

    if (headerRow.length > 0) {
      const ws = XLSX.utils.json_to_sheet(headerRow);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        `${typeData.accountTypeName} 업로드 양식`,
      );
      XLSX.writeFile(wb, `${typeData.accountTypeName} 업로드 양식.xlsx`);
    }
  };

  const selectUploadFile = (e) => {
    const typeData = _accountTypeList.find((thisItem) => {
      if (thisItem.accountTypeId === Number(_accountType)) {
        return true;
      } else return false;
    });

    const confirmText = `
      [ ${typeData.accountTypeName} ] 정보를 업로드 하시겠습니까?
      `;
    if (window.confirm(confirmText) === true) {
      setLoadingStatus(true);
      const fileList = e.target.files;
      const thisFile = fileList[0];
      console.log(thisFile);
      if (thisFile === undefined) return;

      excelExportCommon(e, excelSetting);
    } else {
      return;
    }
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>엑셀 업로드</ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              <ContentSection>
                <SelectSection
                  defaultValue={''}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      UploadAccountInput.current.disabled = true;
                    }
                    setAccountType(() => {
                      return e.target.value;
                    });
                    getAccountAttrs(e.target.value);
                  }}
                >
                  <option value="">거래처 유형</option>
                  {_accountTypeList.map((thisType) => {
                    if (
                      thisType.accountTypeName ===
                      userReducer.company.companyName
                    )
                      return null;
                    return (
                      <option
                        key={thisType.accountTypeId}
                        value={thisType.accountTypeId}
                      >
                        {thisType.accountTypeName}
                      </option>
                    );
                  })}
                </SelectSection>
                <ButtonsSection>
                  <FormatButton onClick={downloadFormat}>
                    양식 다운
                  </FormatButton>

                  <input
                    type="file"
                    ref={UploadAccountInput}
                    id="UploadModalInput"
                    style={{ display: 'none' }}
                    accept=".xlsx, .xls, .csv"
                    disabled={true}
                    onChange={(e) => {
                      selectUploadFile(e);
                    }}
                  />
                  <UploadModalButton
                    htmlFor="UploadModalInput"
                    onClick={() => {
                      if (_accountType === '') {
                        return alert('거래처 유형을 선택해주세요.');
                      } else {
                        UploadAccountInput.current.disabled = false;
                      }
                    }}
                  >
                    업로드
                  </UploadModalButton>
                </ButtonsSection>

                <NoticeSection>
                  <p>※ 순서 ※</p>
                  <p>1. 양식 다운 → 양식에 맞춘 엑셀 준비</p>
                  <p>
                    ※ 엑셀 내 소수점이 있는 경우 셀서식 - 표시형식 - 숫자 - 소수
                    자릿수를 0으로 설정한 후 업로드 해주세요.
                  </p>
                  <p>2. 업로드</p>
                </NoticeSection>
              </ContentSection>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}

      {_loadingStatus === true ? (
        <LoadingScreen>거래처 정보를 업로드하고 있습니다...</LoadingScreen>
      ) : null}
    </>
  );
};

export default AccountUploadModal;
