import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { attachFileApi } from 'api/apis/attachFileApi';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import SelectOneAccount from 'pages/account/modal/SelectOneAccount';
import SelectUsers from 'pages/user/modal/SelectUsers';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: inherit;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  label {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 60px;
    justify-content: flex-end;
    padding: 0px 20px;
    width: 100%;
  }

  input:read-only, textarea:read-only {
    background-color: #ccc;
    color: var(--white);
    &::placeholder {color: var(--white); font-weight: 15px;}
  }

  & form > div {background-color: unset;}
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const CopyUriWorkOrderInfo = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const copyContent = props.copyContent;

  const [_onload, setOnload] = useState('unload');
  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    userId: copyContent.userId,
    user: { id: copyContent.userId, name: copyContent.userName },

    workOrderTypeId: '',

    workOrderStatus: 'work',
    workOrderName: copyContent.workOrderName,
    workOrderCode: copyContent.workOrderCode,

    workOrderScheduledStartDate: DateFormat(checkEmptyNull(copyContent.workOrderScheduledStartDate, '')),
    workOrderScheduledEndDate: DateFormat(checkEmptyNull(copyContent.workOrderScheduledEndDate, '')),

    accountId: copyContent.accountId, // 거래처
    account: { accountId: copyContent.accountId, accountName: copyContent.accountName },

    workPlace: copyContent.workPlace, // 주소
    // detailedWorkPlace: '', // 현장명

    // message: '', // 작업자 알림
    remark: copyContent.remark, // 비고

    workOrderCustomizedContent: checkNullParse(copyContent.workOrderCustomizedContent, {
      BF: { date: '', amount: '' },
      SF: { date: '', amount: '' },
      MF: { date: '', amount: '' },
      total: { date: '', amount: '' },

      // workOrderProducts: '', // 품목
      colors: '', // 색상
      phoneNumber: '', // 전화번호
    }),

    processLogList: checkNullArray(copyContent.processLogList, []),
    deleteProcessList: [],
  });
  const [_processLogList, setProcessLogList] = useState(() => {
    return checkNullArray(copyContent.processLogList, []).map((processLog, index) => {
      const processLogData = {
        ...processLog,
        processCustomizedContent: checkNullParse(processLog.processCustomizedContent, {
          existProcessOutElement: false,
          processingCount: '',

          processCustomizedContent: {
            no: (index + 1).toString().padStart(2, '0'), // 순번
            numStatus: 'white', // NO 컬러
            bundle: '', // 몇 짝
            bundleSize: '', //
            materials: '', // 자재
            materialStatus: 'normal', // 자재 글꼴
            colors: '', // 색상
            colorStatus: 'white', // 색상 컬러
            glassType_W: '', // 유리종류_W
            glassType_H: '', // 유리종류_H
            remark: '', // 비고
          },
        }),
      };
      return processLogData;
    })
  });

  const [_userModalStatus, setUserModalStatus] = useState(false);
  const [_accountModalStatus, setAccountModalStatus] = useState(false);

  /* ====================================================================== #3 */
  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData(prev => {
      const returnData = { ...prev, [name]: value };
      return returnData;
    })
  }
  const handleCustomData = (e) => {
    const { name, value } = e.target;
    setFormData(prev => {
      const returnData = {
        ...prev,
        workOrderCustomizedContent: {
          ...prev?.workOrderCustomizedContent,
          [name]: value
        }
      };
      return returnData;
    })
  }
  const handleCustomDataValue = (name, value) => {
    setFormData(prev => {
      const returnData = {
        ...prev,
        workOrderCustomizedContent: {
          ...prev?.workOrderCustomizedContent,
          [name]: value
        }
      };
      return returnData;
    })
  }

  const selectUser = () => {
    setTimeout(setUserModalStatus(() => { return true }), 1000);
  }
  const selectAccount = () => {
    setTimeout(setAccountModalStatus(() => { return true }), 1000);
  }

  const actCopy = async () => {
    const processLogList = _processLogList.map(processLog => {
      return {
        ...processLog,
        processStatus: 'wait',
        processAmount: 0, managers: [], workers: [],
        processCustomizedContent: JSON.stringify(processLog.processCustomizedContent),
        taskLogList: checkNullArray(processLog.taskLogList, []).map(taskLog => {
          return {
            taskType: taskLog.taskType,
            taskName: taskLog.taskName,
          }
        })
      }
    });
    const BodyToPost = {
      ..._formData,
      workOrderCustomizedContent: JSON.stringify(_formData.workOrderCustomizedContent),
      processLogList: processLogList
    }
    console.log('BodyToPost : ', BodyToPost);
    await workOrderLogApi.createWorkOrderLog(BodyToPost).then(response => {
      if (response === undefined) return;
      console.log('workOrderLogApi.createWorkOrderLog : ', response);
      response.data.processLogList.forEach(async (processLog, index) => {
        console.log('processLog : ', processLog);
        const postProcessLog = processLogList[index];
        console.log('postProcessLog : ', postProcessLog);
        if (checkNullArray(postProcessLog.attachFileList, false)) {
          const BodyToAttach = {
            companyId: userReducer.company.companyId,
            fileDataId: postProcessLog.attachFileList[0]?.fileData.fileDataId,
            processLogId: processLog.processLogId,
          }
          console.log('BodyToAttach : ', BodyToAttach);
          await attachFileApi.createAttachFile(BodyToAttach).then(fileResponse => {
            if (fileResponse === undefined) return;
            console.log('attachFileApi.createAttachFile : ', response);
          })
        }
      })
    }).then(() => {
      alert(`불량 작업지시를 추가했습니다.`);
      props.close();
    })
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>불량 작업지시 추가</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <Form
                title={<>불량<br />작업지시<br />추가<br /></>}
                buttons={<></>}
                forms={
                  <>
                    <div className="formBox">
                      <h4>지시일자</h4>
                      <input
                        type="date"
                        name="workOrderScheduledStartDate"
                        value={DateFormat(checkEmptyNull(_formData.workOrderScheduledStartDate, ''))}
                        onChange={handleInputEvent}
                      />
                    </div>

                    <div className="formBox">
                      <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                        <h4>작성자</h4>
                        <div className="formButton"
                          style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                          onClick={(e) => { e.preventDefault(); selectUser(); }}
                        >
                          작성자 선택
                        </div>
                      </div>
                      <input
                        type="text"
                        name="accountName"
                        style={{ backgroundColor: 'var(--ThirdBlue)', color: 'var(--white)' }}
                        placeholder="* 작성자..."
                        readOnly
                        value={_formData?.user?.name}
                      />
                    </div>

                    <div className="formBox">
                      <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                        <h4>거래처</h4>
                        <div
                          className="formButton"
                          style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                          onClick={(e) => { e.preventDefault(); selectAccount(); }}
                        >
                          거래처 선택
                        </div>
                      </div>
                      <input
                        type="text"
                        name="accountName"
                        style={{ backgroundColor: 'var(--ThirdBlue)', color: 'var(--white)' }}
                        placeholder="* 거래처..."
                        readOnly
                        value={_formData?.account?.accountName}
                      />
                    </div>

                    <div className="formBox">
                      <h4>주소</h4>
                      <input
                        type="text"
                        name="workPlace"
                        placeholder="주소..."
                        value={checkEmptyNull(_formData?.workPlace, '')}
                        onChange={handleInputEvent}
                      />
                    </div>

                    <div className="formBox">
                      <h4>전화번호</h4>
                      <input
                        type="text"
                        name="phoneNumber"
                        placeholder="전화번호..."
                        defaultValue={_formData?.workOrderCustomizedContent?.phoneNumber}
                        onChange={handleCustomData}
                      />
                    </div>

                    <div className="formBox">
                      <h4>색상</h4>
                      <input
                        type="text"
                        name="colors"
                        placeholder="색상..."
                        value={checkEmptyNull(_formData?.workOrderCustomizedContent?.colors, '')}
                        onChange={handleCustomData}
                      />
                    </div>

                    <div className="formBox">
                      <h4>B/F 납기</h4>
                      <div style={{ display: 'flex', gap: '5px' }}>
                        <input
                          type="date"
                          value={DateFormat(checkEmptyNull(_formData?.workOrderCustomizedContent?.BF?.date, ''))}
                          onChange={(e) => {
                            e.preventDefault();
                            const value = {
                              date: DateFormat(e.target.value),
                              amount: checkEmptyNull(_formData?.workOrderCustomizedContent?.BF?.amount, ''),
                            }
                            handleCustomDataValue('BF', value);
                          }}
                        />
                        <input
                          type="text"
                          placeholder="B/F 수량..."
                          value={checkEmptyNull(_formData?.workOrderCustomizedContent?.BF?.amount, '')}
                          onChange={(e) => {
                            e.preventDefault();
                            const value = {
                              date: checkEmptyNull(_formData?.workOrderCustomizedContent?.BF?.date, ''),
                              amount: e.target.value,
                            }
                            handleCustomDataValue('BF', value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="formBox">
                      <h4>S/F 납기</h4>
                      <div style={{ display: 'flex', gap: '5px' }}>
                        <input
                          type="date"
                          value={DateFormat(checkEmptyNull(_formData?.workOrderCustomizedContent?.SF?.date, ''))}
                          onChange={(e) => {
                            e.preventDefault();
                            const value = {
                              date: DateFormat(e.target.value),
                              amount: checkEmptyNull(_formData?.workOrderCustomizedContent?.SF?.amount, ''),
                            }
                            handleCustomDataValue('SF', value);
                          }}
                        />
                        <input
                          type="text"
                          placeholder="S/F 수량..."
                          value={checkEmptyNull(_formData?.workOrderCustomizedContent?.SF?.amount, '')}
                          onChange={(e) => {
                            e.preventDefault();
                            const value = {
                              date: checkEmptyNull(_formData?.workOrderCustomizedContent?.SF?.date, ''),
                              amount: e.target.value,
                            }
                            handleCustomDataValue('SF', value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="formBox">
                      <h4>M/F 납기</h4>
                      <div style={{ display: 'flex', gap: '5px' }}>
                        <input
                          type="date"
                          value={DateFormat(checkEmptyNull(_formData?.workOrderCustomizedContent?.MF?.date, ''))}
                          onChange={(e) => {
                            e.preventDefault();
                            const value = {
                              date: DateFormat(e.target.value),
                              amount: checkEmptyNull(_formData?.workOrderCustomizedContent?.MF?.amount, ''),
                            }
                            handleCustomDataValue('MF', value);
                          }}
                        />
                        <input
                          type="text"
                          placeholder="M/F 수량..."
                          value={checkEmptyNull(_formData?.workOrderCustomizedContent?.MF?.amount, '')}
                          onChange={(e) => {
                            e.preventDefault();
                            const value = {
                              date: checkEmptyNull(_formData?.workOrderCustomizedContent?.MF?.date, ''),
                              amount: e.target.value,
                            }
                            handleCustomDataValue('MF', value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="formBox" style={{ width: '100%' }}>
                      <h4>비고</h4>
                      <textarea
                        name="remark"
                        placeholder="비고..."
                        value={checkEmptyNull(_formData?.remark, '')}
                        onChange={handleInputEvent}
                      ></textarea>
                    </div>
                  </>
                }
              />
            </ModalMain>

            <ModalFooter><ModalButton onClick={actCopy}>추가</ModalButton></ModalFooter>
          </ModalSection>
        </ModalBg>
      )}

      {/* 작성자 선택 */}
      {_userModalStatus === true ? (
        <SelectUsers
          buttonTitle={'작성자 선택'}
          buttonEvent={(data) => {
            setFormData(prev => {
              const returnData = {
                ...prev,
                userId: data?.id,
                user: { ...data }
              };
              return returnData;
            })
            setTimeout(setUserModalStatus(false), 1000);
          }}
          open={_userModalStatus}
          close={() => { setUserModalStatus(false) }}
        />
      ) : null}

      {/* 거래처 선택 */}
      {_accountModalStatus === true ? (
        <SelectOneAccount
          buttonTitle={'거래처 선택'}
          buttonEvent={(data) => {
            setFormData(prev => {
              const returnData = {
                ...prev,
                accountId: data?.accountId,
                account: { ...data },
                workOrderCustomizedContent: { ...prev.workOrderCustomizedContent, phoneNumber: data?.phoneNumber }
              };
              return returnData;
            })
            setTimeout(setAccountModalStatus(false), 1000);
          }}
          open={_accountModalStatus}
          close={() => { setAccountModalStatus(false) }}
        />
      ) : null}
    </>
  );
};

export default CopyUriWorkOrderInfo;
