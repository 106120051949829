export const barcodeActions_setContentType = (type) => {
  return { type: 'SETBARCODECONTENTTYPE', payload: type };
};
export const barcodeActions_setContentList = (content) => {
  return { type: 'SETBARCODECONTENTLIST', payload: content };
};
export const barcodeActions_setPrintList = (content) => {
  return { type: 'SETBARCODEPRINTLIST', payload: content };
};
export const barcodeActions_setBarcode = (barcode) => {
  return { type: 'SETBARCODE', payload: barcode };
};
export const barcodeActions_setBarcodeContent = (content) => {
  return { type: 'SETBARCODECONTENT', payload: content };
};

export const barcodeActions_setReset = () => {
  return { type: 'SETBARCODERESET' };
};