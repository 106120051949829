import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";

import { mallSiteElementActions_setPageNumber, mallSiteElementActions_setSearchData, mallSiteElementActions_setTotalSize, mallSiteElementActions_setReset } from 'store/modules/actions/mallSite/mallSiteElementActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { element } from 'api/apis/mall/element';

import { checkEmptyNull, checkNullObject } from 'components/checkValues/checkValues';

import CardV from 'mall/components/CardV';
import MallSiteCategories from 'mall/components/MallSiteCategories';
import PagingComponent from 'components/paging/PagingComponent';

const MallProductList = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const location = useLocation();
  const { mallSiteElementReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const companyCode = location.pathname.split('/')[1];

  const [_onload, setOnload] = useState('unload');
  const [_mallElementList, setMallElementList] = useState([]);

  /* ====================================================================== #3 */
  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getMallElementList(mallSiteElementReducer.pageNumber - 1, mallSiteElementReducer.mallElementType);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getMallElementList(mallSiteElementReducer.pageNumber - 1, mallSiteElementReducer.mallElementType);
    }
    return () => { };
  }, [mallSiteElementReducer.pageNumber]);

  useEffect(() => {
    if (_onload === 'loaded') {
      dispatch(mallSiteElementActions_setPageNumber(1));
      getMallElementList(0, mallSiteElementReducer.mallElementType);
    }
    return () => { };
  }, [mallSiteElementReducer.clickedCategory]);

  useEffect(() => { }, [mallSiteElementReducer]);

  /* ====================================================================== #4 */
  const getMallElementList = async (page, mallElementType, clear) => {
    const paging = `?page=${page}&size=8&sort=id,DESC`;
    const BodyToPost = { companyCode: companyCode };
    if (!clear && mallElementType !== undefined && mallElementType !== 'all') {
      BodyToPost.mallElementType = mallElementType;
    }
    if (!clear && checkNullObject(mallSiteElementReducer.clickedCategory, false) && mallSiteElementReducer.clickedCategory !== 'all') {
      BodyToPost.mallCategoryId = mallSiteElementReducer.clickedCategory.mallCategoryId;
    }
    if (!clear && checkEmptyNull(mallSiteElementReducer.searchData.mallElementName, false)) {
      BodyToPost.mallElementName = mallSiteElementReducer.searchData.mallElementName;
    }
    if (!clear && checkEmptyNull(mallSiteElementReducer.searchData.mallElementCode, false)) {
      BodyToPost.mallElementCode = mallSiteElementReducer.searchData.mallElementCode;
    }
    await element.searchMallElement(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('element.searchMallElement : ', response);
      setMallElementList(() => { return response.data.content });
      dispatch(mallSiteElementActions_setTotalSize(response.data.totalElements));
    });
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(mallSiteElementActions_setPageNumber(1));
    await getMallElementList(0, mallSiteElementReducer.mallElementType);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(mallSiteElementActions_setReset());
    dispatch(mallSiteElementActions_setPageNumber(1));
    await getMallElementList(0, 'all', true);
  };

  return (
    <>
      {['Dklab', 'KyungSeo'].includes(companyCode) ? (
        <Swiper
          cssMode={true}
          navigation={true}
          pagination={true}
          mousewheel={true}
          keyboard={true}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className={'mySwiper ' + companyCode}
          loop={true}
        >
          {['Dklab', 'KyungSeo'].includes(companyCode) ? (
            <>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/src/images/gs/mall_banner_1.jpg'} /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/src/images/gs/mall_banner_2.jpg'} /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/src/images/gs/mall_banner_3.jpg'} /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/src/images/gs/mall_banner_4.jpg'} /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/src/images/gs/mall_banner_5.jpg'} /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/src/images/gs/mall_banner_6.jpg'} /></SwiperSlide>
            </>
          ) : null}
          {/* <SwiperSlide><img src='http://www.wibearing.kr/new_images/main_visu07.jpg' /></SwiperSlide>
          <SwiperSlide><img src='http://www.wibearing.kr/new_images/main_visu02.jpg' /></SwiperSlide>
          <SwiperSlide><img src='http://www.wibearing.kr/new_images/main_visu08.jpg' /></SwiperSlide> */}
        </Swiper>
      ) : null}

      <section className='mall-product-list-search' style={{ paddingTop: ['Dklab', 'KyungSeo'].includes(companyCode) ? 'unset' : '60px' }}>
        <ul>
          <MallSiteCategories categoryType={'mallSiteElement'} className='product-list-category' />
          <li className='mall-product-list-search-family'>
            <div>
              <select disabled value={'mallElementCode'}><option value={'mallElementCode'}>상품코드</option></select>
              <input className="SearchBar" placeholder="검색할 상품코드를 입력해주세요."
                value={checkEmptyNull(mallSiteElementReducer.searchData.mallElementCode, '',)}
                onInput={(e) => { dispatch(mallSiteElementActions_setSearchData({ ...mallSiteElementReducer.searchData, mallElementCode: e.target.value })); }}
              />
            </div>
            <div>
              <select disabled value={'mallElementName'}><option value={'mallElementName'}>상품이름</option></select>
              <input className="SearchBar" placeholder="검색할 상품이름을 입력해주세요."
                value={checkEmptyNull(mallSiteElementReducer.searchData.mallElementName, '',)}
                onInput={(e) => { dispatch(mallSiteElementActions_setSearchData({ ...mallSiteElementReducer.searchData, mallElementName: e.target.value })); }}
              />
              <a onClick={actReset}><img src='https://cdn-icons-png.flaticon.com/512/82/82004.png' style={{ width: '16px' }} /></a>
            </div>
          </li>
        </ul>
        <button onClick={actSearch}>검색</button>
      </section>
      <section className='mall-product-list-card'>
        {_mallElementList.map((el, idx) => { return <CardV key={idx + '_mallElements'} mallElement={el} />; })}
      </section>
      <section>
        <PagingComponent
          page={mallSiteElementReducer.pageNumber}
          count={mallSiteElementReducer.totalSize}
          size={9}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(mallSiteElementActions_setPageNumber(page));
          }}
        />
      </section>
    </>
  );
};

export default MallProductList;
