export const mallElementActions_setElementType = (elementType) => {
  return {
    type: 'SETMALLELEMENTTYPE',
    payload: elementType,
  };
};
export const mallElementActions_setSearchData = (searchData) => {
  return {
    type: 'SETMALLELEMENTSEARCHDATA',
    payload: searchData,
  };
};
export const mallElementActions_setSearchAttrs = (serachAttrs) => {
  return {
    type: 'SETMALLELEMENTSEARCHATTS',
    payload: serachAttrs,
  };
};

export const mallElementActions_setClickedCategory = (category) => {
  return {
    type: 'SETMALLELEMENTCLICKEDCATEGORY',
    payload: category,
  };
};
export const mallElementActions_setClickedLevel1Category = (category) => {
  return {
    type: 'SETMALLELEMENTCLICKEDLEVEL1CATEGORY',
    payload: category,
  };
};
export const mallElementActions_setClickedLevel2Category = (category) => {
  return {
    type: 'SETMALLELEMENTCLICKEDLEVEL2CATEGORY',
    payload: category,
  };
};
export const mallElementActions_setClickedLevel3Category = (category) => {
  return {
    type: 'SETMALLELEMENTCLICKEDLEVEL3CATEGORY',
    payload: category,
  };
};
export const mallElementActions_setClickedLevel4Category = (category) => {
  return {
    type: 'SETMALLELEMENTCLICKEDLEVEL4CATEGORY',
    payload: category,
  };
};
export const mallElementActions_setClickedLevel5Category = (category) => {
  return {
    type: 'SETMALLELEMENTCLICKEDLEVEL5CATEGORY',
    payload: category,
  };
};

export const mallElementActions_setPageNumber = (pageNum) => {
  return {
    type: 'SETMALLELEMENTPAGENUMBER',
    payload: pageNum,
  };
};
export const mallElementActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETMALLELEMENTTOTALSIZE',
    payload: totalSize,
  };
};
export const mallElementActions_setIsPushedSearchButton = (status) => {
  return {
    type: 'SETMALLELEMENTISPUSHEDSEARCHBUTTON',
    payload: status,
  };
};

export const mallElementActions_setReset = () => {
  return {
    type: 'SETMALLELEMENTRESET',
  };
};

export const mallElementActions_selectOneElement = (element) => {
  return {
    type: 'SELECTONEELEMENT',
    payload: element,
  };
};

export const mallElementActions_selectElements = (elements) => {
  return {
    type: 'SELECTELEMENTS',
    payload: elements,
  };
};

export const mallElementActions_setLevel1Categories = (category) => {
  return {
    type: 'SETLEVEL1CATEGORIES',
    payload: category,
  };
};
export const mallElementActions_setLevel2Categories = (category) => {
  return {
    type: 'SETLEVEL2CATEGORIES',
    payload: category,
  };
};
export const mallElementActions_setLevel3Categories = (category) => {
  return {
    type: 'SETLEVEL3CATEGORIES',
    payload: category,
  };
};
export const mallElementActions_setLevel4Categories = (category) => {
  return {
    type: 'SETLEVEL4CATEGORIES',
    payload: category,
  };
};
export const mallElementActions_setLevel5Categories = (category) => {
  return {
    type: 'SETLEVEL5CATEGORIES',
    payload: category,
  };
};
