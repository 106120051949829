import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { shipmentInspectionItem } from 'api/apis/shipmentInspection/shipmentInspectionItem';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

const DeleteShipmentInspectionItem = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();
  const deleteContent = JSON.parse(searchParams.get('deleteContent'));

  /* ====================================================================== #3 */

  /* ====================================================================== #4 */

  /* ====================================================================== #5 */
  const actDelete = async () => {
    await shipmentInspectionItem.deleteShipmentInspectionItem(deleteContent.shipmentInspectionItemId).then((response) => {
      if (response === undefined) return;
      console.log('shipmentInspectionItem.deleteShipmentInspectionItem : ', response);
      alert('점검 / 검사를 삭제했습니다.');
      setTimeout(navigate(pageReducer.currentPage, { replace: true }), 1000);
    });
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <main className="Main">
        <NavBar title={<NavTitle menuCode={'113'} />} nav={''} />

        <DeleteForm
          delteTitle={<NavTitle menuCode={'113'} />}
          deleteItem={
            <>
              {'점검 / 검사 항목 : ' + deleteContent.shipmentInspectionItemTypeName}<br />
              {'점검 / 검사 내용 : ' + deleteContent.content}<br />
              {'판정 기준 : ' + deleteContent.criteria}<br />
            </>
          }
          deleteText={<>해당 <span className="cautionText">점검 / 검사</span>가 삭제됩니다.</>}
          deleteButton={
            <>
              <button className="formButton cancle" onClick={() => { navigate(pageReducer.currentPage, { replace: true }) }}>
                취소
              </button>
              <button className="formButton delete" onClick={() => { if (window.confirm(`점검 / 검사를 삭제합니다.`)) actDelete(); }}>
                삭제
              </button>
            </>
          }
        />
      </main>
    }
    />
  );
};

export default DeleteShipmentInspectionItem;
