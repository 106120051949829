export const accountActions_setAccountType = (accountType) => {
  return { type: 'SETACCOUNTTYPE', payload: accountType };
};

export const accountActions_setSearchOption = (searchOption) => {
  return { type: 'SETACCOUNTSEARCHOPTION', payload: searchOption };
};
export const accountActions_setSearchText = (searchText) => {
  return { type: 'SETACCOUNTSEARCHTEXT', payload: searchText };
};

export const accountActions_setPageNumber = (pageNumber) => {
  return { type: 'SETACCOUNTPAGENUMBER', payload: pageNumber };
};
export const accountActions_setTotalSize = (totalSize) => {
  return { type: 'SETACCOUNTTOTALSIZE', payload: totalSize };
};
export const accountActions_setIsPushedSearchButton = (isPushedSearchButton) => {
  return { type: 'SETACCOUNTISPUSHEDSEARCHBUTTON', payload: isPushedSearchButton };
};

export const accountActions_setBookmark = (status) => {
  return { type: 'SETACCOUNTBOOKMARK', payload: status };
};

export const accountActions_setReset = () => {
  return { type: 'SETACCOUNTRESET' };
};