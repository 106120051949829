import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

import { manHour } from 'api/apis/manHour/manHour';
import { userApi } from 'api/apis/userApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import DetailWorkOrderLog from 'pages/manHour/custom/company/gs/result/modal/DetailWorkOrderLog';
// import GSShowManHourList from 'pages/manHour/custom/company/gs/modal/GSShowManHourList';
import TableSection from 'components/layouts/table/TableSection';

const Section = styled.section`
  display: grid;
  grid-template-columns: 30% 70%;
  height: 100%;
  overflow: hidden;
  width: 100%;

  section {
    height: 100%;
    width: 100%;
  }
  .section-select {
    display: grid;
    gap: 10px;
    grid-template-rows: 40px calc(100% - 50px);
    overflow: hidden;
    .search {
      display: grid;
      gap: 10px;
      grid-template-columns: calc(100% - 80px) 60px;
      height: 40px;
      width: 100%;

      input {
        box-sizing: border-box;
        padding: 5px;
        height: 100%;
        width: 100%;
      }
      button {
        background-color: var(--MainNavy);
        color: var(--white);
        height: 100%;
        width: 60px;
      }
    }
    .TableSection {
      height: 100%;
      td, th {min-width: unset;}
    }
  }
  .section-result {
    align-content: flex-start;
    box-sizing: border-box;
    display: grid;
    gap: 10px;
    grid-template-rows: 40px 80px 40px calc(100% - 190px);
    height: 100%;
    overflow: hidden;
    padding: 20px;
    width: 100%;

    .title {
      align-items: center;
      display: flex;
      height: 40px;
      justify-content: space-between;

      nav {
        align-items: center;
        display: flex;
        gap: 5px;
        height: 100%;
        justify-content: center;

        input, select {
          border-radius: 10px;
          box-sizing: border-box;
          height: 100%;
          padding: 5px 10px;
        }
        button {
          padding: unset;
          width: 60px;
          &.btn-reset {
            background: none;
            box-shadow: none;
          }
        }
      }
    }
    .totalTime {
      align-items: center;
      background-color: var(--white);
      border-radius: 10px;
      box-sizing: border-box;
      display: flex;
      height: 80px;
      padding: 20px;
      justify-content: flex-start;
      span {
        border-right: 1px solid var(--gray-200);
        height: fit-content;
        padding-inline-end: 20px;
        margin-inline-end: 20px;
      }
    }

    .section-workingTime {
      align-content: flex-start;
      display: grid;
      gap: 10px;
      height: 100%;
      overflow-y: scroll;
      width: 100%;

      .workingTimeContent {
        background-color: var(--white);
        border-radius: 10px;
        display: grid;
        gap: 10px;
        grid-template-columns: calc(100% - 110px) 100px;
        height: fit-content;
        padding: 20px;
        width: 100%;

        &>div {
          display: grid;
          gap: 10px;
        }
        span:first-child {
          border-right: 1px solid var(--gray-200);
          height: fit-content;
          padding-inline-end: 20px;
          margin-inline-end: 20px;
        }
        p.date {
          font-weight: 600;
        }
        p.note {
          display: flex;
          flex-wrap: wrap;
        }
        button {
          background-color: var(--MainNavy);
          color: var(--white);
          width: 100px;
        }
      }
    }
  }
`;

const Worker = () => {
  const { userReducer } = useSelector((state) => state);

  const [_searchWorker, setSearchWorker] = useState('');
  const [_workerList, setWorkerList] = useState([]);

  const [_worker, setWorker] = useState({});
  const [_manHourData, setManHourData] = useState({});

  const [_dayOption, setDayOption] = useState('all');
  const [_date, setDate] = useState('');
  const [_viewDate, setViewDate] = useState('');

  const [_workingTimeWorkOrderLogs, setWorkingTimeWorkOrderLogs] = useState([]);
  const [_workingTimeProcessLogs, setWorkingTimeProcessLogs] = useState([]);

  const [_workingTimeData, setWorkingTimeData] = useState({});
  const [_workingTimeModalStatus, setWorkingTimeModalStatus] = useState(false);

  useEffect(() => {
    getWorkerList(_searchWorker);

    return () => { }
  }, []);
  useEffect(() => { }, [_manHourData])

  const getWorkerList = async (worker) => {
    const paging = `?page=0&size=100`;
    const body = { companyId: userReducer.company.companyId };
    if (checkEmptyNull(worker, false)) body.name = worker;
    await userApi.searchUser(paging, body).then(response => {
      if (response === undefined) return;
      setWorkerList(() => { return response.data.content });
    })
  };

  const handlerMin = (min) => {
    const days = Math.floor(min / 60 / 24)
    const hours = Math.floor((min - (days * 60 * 24)) / 60);
    const mins = min - (days * 60 * 24) - (hours * 60);

    let returnData = '';
    if (days > 0) returnData += days + '일 ';
    if (hours > 0) returnData += hours + '시간 ';
    if (mins > 0) returnData += mins + '분';

    console.log('returnData : ', days, hours, mins, returnData);
    return returnData;
  }
  const handleManHourData = async (worker) => {
    setManHourData(() => { return {} });
    setWorker(() => { return worker });
    const companyId = userReducer.company.companyId;
    const workerName = worker.name;
    const searchData = `companyId=${companyId}&workerName=${workerName}`;
    await manHour.groupingUser(searchData).then(response => {
      if (response === undefined) return;
      console.log('manHour.groupingUser : ', response);
      setViewDate(() => { return '' });
      if (checkNullObject(response.data[0], false)) {
        setManHourData(() => { return response.data[0] });
      } else {
        setManHourData(() => { return {} });
      }
    })
  };

  const actSearch = async (e) => {
    e.preventDefault();
    if (_dayOption === 'all') return handleManHourData(_worker);

    const companyId = userReducer.company.companyId;
    const workerName = _worker.name;
    let searchData = `companyId=${companyId}&workerName=${workerName}`;
    if (checkEmptyNull(_date, false)) {
      const startWDate = moment(_date).startOf(_dayOption).format('YYYY-MM-DD');
      const endWDate = moment(_date).endOf(_dayOption).format('YYYY-MM-DD');
      searchData += `&startDate=${startWDate}&endDate=${endWDate}&grouping=USER`;
    }
    // http://localhost:8080/api/workingTime/group?companyId=1&workerName=ifis&startDate=2023-11-01&endDate=2023-11-30&grouping=USER
    await manHour.groupingUser(searchData).then(response => {
      if (response === undefined) return;
      console.log('manHour.groupingUser : ', response);
      setViewDate(() => { return `${moment(_date).startOf(_dayOption).format('YYYY-MM-DD')} ~ ${moment(_date).endOf(_dayOption).format('YYYY-MM-DD')}` });
      if (checkNullObject(response.data[0], false)) {
        setManHourData(() => { return response.data[0] });
      } else {
        setManHourData(() => { return {} });
      }
    })
  }
  const actReset = () => {
    setDayOption(() => { return 'all' });
    setDate(() => { return '' });
    handleManHourData(_worker);
  }

  const handleModalStatus = (workingTimeData) => {
    const returnData = {
      ...workingTimeData,
      worker: _manHourData.worker
    }
    setWorkingTimeData(() => { return returnData });
    setWorkingTimeModalStatus(true);
  }

  return (
    <>
      <Section>
        <section className='section-select'>
          <div className='search'>
            <input
              type='text'
              placeholder='이름...'
              value={_searchWorker}
              onInput={(e) => { setSearchWorker(() => { return e.target.value }) }}
            />
            <button className='btn-set' onClick={(e) => { e.preventDefault(); getWorkerList(_searchWorker); }}>검색</button>
          </div>
          <TableSection content={
            <table>
              <colgroup>
                <col />
                <col />
                <col width={'50px'} />
              </colgroup>
              <thead>
                <tr>
                  <th>팀</th>
                  <th>이름</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {_workerList.map((worker, index) => {
                  return (
                    <tr key={index + '_worker'}>
                      <td>{worker.team?.teamName}</td>
                      <td>{worker.name}</td>
                      <td>
                        <button className='btn-set' onClick={(e) => { e.preventDefault(); handleManHourData(worker) }}>보기</button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          }
          />
        </section>
        <section className='section-result'>
          {checkNullObject(_worker, false) ? (
            <>
              <div className='title'>
                <h3>{_worker.name} 공수실적</h3>
                <nav>
                  {/* <select value={_dayOption} onChange={e => { e.preventDefault(); setDayOption(e.target.value) }}>
                    {[
                      { name: '전체', value: 'all' },
                      { name: '월별', value: 'month' },
                      { name: '주별', value: 'week' },
                      { name: '일별', value: 'date' },
                    ].map((item, index) => {
                      return (<option key={index + '_dayOptions'} value={item.value}>{item.name}</option>)
                    })}
                  </select>
                  {_dayOption !== 'all' ? (
                    <input type={_dayOption} className='SelectDate' value={_date} onChange={(e) => { e.preventDefault(); setDate(e.target.value) }} />
                  ) : null}
                  <button className='btn-search' onClick={actSearch}>조회</button>
                  <button className='btn-reset' onClick={actReset}>초기화</button> */}
                </nav>
              </div>
              {checkNullObject(_manHourData, false) ? <>
                <p className='totalTime'><span>총 공수시간</span>{handlerMin(checkEmptyNull(_manHourData.diffMinutes, 0))}</p>
              </> : null}
              <div className='title'>
                <h4>공수리스트</h4>
                {/* <h4>{_viewDate}</h4> */}
              </div>
              {checkNullObject(_manHourData, false) ? <>
                <section className='section-workingTime'>
                  {_manHourData.workingTimes.map((workingTimeData, index) => {
                    const workingTimeWorkOrderLogs = checkNullArray(workingTimeData.workingTimeWorkOrderLogs, []);
                    const workingTimeProcessLogs = checkNullArray(workingTimeData.workingTimeProcessLogs, []);
                    return (
                      <div key={index + '_workingTimeData'} className='workingTimeContent'>
                        <div>
                          <p className='date'>{moment(workingTimeData.startTime).format('YYYY-MM-DD HH:mm')} - {moment(workingTimeData.endTime).format('YYYY-MM-DD HH:mm')}</p>
                          <p><span>공수시간</span>{handlerMin(checkEmptyNull(workingTimeData.diffMinutes, 0))}</p>
                          <p className='note'><span>작업내용</span><span>{workingTimeData.note}</span></p>
                        </div>
                        <div>
                          {/* <p><span>작업</span>{workingTimeWorkOrderLogs.length}</p>
                          <p><span>공정</span>{workingTimeProcessLogs.length}</p> */}
                          <button className='btn-set btn-list' onClick={(e) => { e.preventDefault(); handleModalStatus(workingTimeData) }}>작업<br />상세보기</button>
                        </div>
                      </div>
                    );
                  })}
                </section>
              </> : null}
            </>
          ) : null}
        </section>
      </Section>

      {_workingTimeModalStatus ? (
        <DetailWorkOrderLog
          content={_workingTimeData}
          open={_workingTimeModalStatus}
          close={() => { setWorkingTimeModalStatus(false); }}
        />
      ) : null}
    </>
  );
};

export default Worker;