export const monitoringActions_setMaterialFilter = (content) => { return { type: 'SETMONITORINGMATERIALFILTER', payload: content }; };
export const monitoringActions_setHalfFilter = (content) => { return { type: 'SETMONITORINGHALFFILTER', payload: content }; };
export const monitoringActions_setProductFilter = (content) => { return { type: 'SETMONITORINGPRODUCTFILTER', payload: content }; };

export const monitoringActions_setProductionFilter = (content) => { return { type: 'SETMONITORINGPRODUCTIONFILTER', payload: content }; };
export const monitoringActions_setQualityFilter = (content) => { return { type: 'SETMONITORINGQUALITYFILTER', payload: content }; };

export const monitoringActions_setMonitoringView = (content) => { return { type: 'SETMONITORINGMONITORINGVIEW', payload: content }; };

export const monitoringActions_setSensorView = (content) => { return { type: 'SETMONITORINGSENSORVIEW', payload: content }; };
