import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { OperatingKPIMonitoringComponent } from './OperatingKPIMonitoring.style'

import { companyApi } from 'api/apis/companyApi';
import { kpi } from 'api/apis/kpi/kpi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

import KPIGraph from 'pages/kpi/contents/KPIGraph';
import KPITable from 'pages/kpi/contents/KPITable';

const OperatingKPIMonitoring = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [_kpiYear, setKpiYear] = useState('');
  const [_kpiUnits, setKpiUnits] = useState([]); // KPI 유형 리스트
  const [_kpiUnit, setKpiUnit] = useState({}); // KPI 유형
  const [_kpiDataList, setKpiDataList] = useState([]);

  const months = Array.from({ length: 12 }, (_, index) => (index + 1).toString().padStart(2, '0'));
  const [_month, setMonth] = useState('01');
  const [_weeks, setWeeks] = useState(5);
  const [_days, setDays] = useState(31);

  const operatingId = location.pathname.split('/platform/operating')[0];
  const [_companyId, setCompanyId] = useState('');
  const [_companyList, setCompanyList] = useState([]);

  useEffect(() => {
    getClusterCompanyList();

    return () => { }
  }, []);

  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getKpiUnits(_companyId, _kpiYear);
    findDays(_kpiYear, _month);

    return () => { }
  }, [])
  useEffect(() => { }, [_kpiYear, _kpiUnits, _kpiUnit, _kpiDataList, _month, _weeks, _days, _companyId]);

  const getClusterCompanyList = async () => { // 소공인 리스트 불러오기
    const id = Number(operatingId.replace('/', ''));
    const body = { clusterId: !isNaN(id) ? id : '' };

    await companyApi.searchCompanies('', body).then(response => {
      if (response === undefined) return;
      setCompanyList(() => { return response.data });
    })
  };

  const getKpiUnits = async (companyId, year) => { // KPI 유형 리스트 불러오기
    // 로컬에서 모헨에서 2024-1 로 조회하면 확인할 수 있음
    const body = { companyId: Number(companyId), type: "shop", year: Number(year) }
    await kpi.searchKpiUnit(body).then(response => {
      if (response === undefined || !response.data) return;
      setKpiUnits(() => { return response.data });
    });
  }

  const getKpiData = async (kpiUnit, month) => { // KPI 데이터 불러오기
    if (!checkEmptyNull(month, false)) return;

    const fromDate = `${_kpiYear}-${month}-01`
    const toDate = moment(fromDate).endOf('month').format('YYYY-MM-DD');

    const body = {
      fromDate: fromDate,
      toDate: toDate,
      kpiUnitId: kpiUnit?.id
    }
    await kpi.searchKpiData(body).then(response => {
      if (response === undefined || !response.data) return;
      console.log('kpi.searchKpiData : ', response);
      setKpiDataList(() => { return response.data });
    })
  }

  const findDays = (kpiYear, month) => { // 선택한 달의 주수, 일수 구하기
    const firstDay = moment(`${kpiYear}-${month}-01`);
    const lastDay = moment(firstDay).endOf('month');
    const firstDayOfWeek = firstDay.day() === 0 ? 7 : firstDay.day();
    const lastWeekNo = Math.ceil((firstDayOfWeek - 1 + lastDay.date()) / 7);

    setWeeks(lastWeekNo);
    setDays(lastDay.date());
  }

  const actReset = () => { // 초기화
    setCompanyId(() => { return ''; });
    setKpiYear(() => { return ''; });
    setKpiUnits(() => { return []; });
    setKpiUnit(() => { return {}; });
    setMonth(() => { return '01'; });
    setKpiDataList(() => { return []; });
  }

  return (
    <>
      <OperatingKPIMonitoringComponent>
        <nav>
          <h1>KPI 모니터링</h1>
          <div>
            <div className='nav-bar'>
              <select
                name='companyId'
                value={_companyId || ''}
                onChange={(e) => {
                  const companyId = e.target.value;
                  setCompanyId(() => { return companyId; })
                  if (checkEmptyNull(companyId, false)) {
                    setKpiYear(() => { return ''; });
                    setKpiUnits(() => { return []; });
                    setKpiUnit(() => { return {}; });
                    setMonth(() => { return '01'; });
                    setKpiDataList(() => { return []; });
                  }
                }}
              >
                <option value={''}>소공인 전체</option>
                {checkNullArray(_companyList, []).map((company, index) => {
                  return (<option key={index + '_company'} value={company.companyId}>{company.companyName}</option>);
                })}
              </select>
            </div>
            <div className='nav-bar'>
              <input
                type='text'
                value={_kpiYear}
                onChange={(e) => {
                  const year = e.target.value;
                  setKpiYear(() => { return year; });
                  if (year.length >= 4) {
                    setKpiUnits(() => { return []; });
                    setKpiUnit(() => { return {}; });
                    setMonth(() => { return '01'; });
                    setKpiDataList(() => { return []; });
                    findDays(year, '01');
                    getKpiUnits(_companyId, year);
                  }
                }}
              />
              <span>년</span>
            </div>
            {(checkEmptyNull(_kpiYear, false) && checkNullArray(_kpiUnits, false)) ? (<>
              <div className='nav-bar'>
                <select
                  value={_kpiUnit?.id || ''}
                  onChange={(e) => {
                    const unitId = e.target.value * 1;
                    const unitData = _kpiUnits.find(item => item.id === unitId);
                    setKpiUnit(() => { return unitData || {} });
                    if (checkEmptyNull(e.target.value, false)) getKpiData(unitData, _month);
                  }}
                >
                  <option value={''}>KPI 유형 선택</option>
                  {checkNullArray(_kpiUnits, []).map((unit, index) => {
                    if (checkNullArray(unit.kpiDataDtoList, false)) {
                      return (<option key={index + '_unit'} value={unit.id}>{unit.kpiCode.kpiDtlNm}</option>);
                    } else return null;
                  })}
                </select>
              </div>
              {checkEmptyNull(_kpiUnit?.id, false) ? (<>
                <div className='nav-bar'>
                  <select
                    className='month'
                    value={_month}
                    onChange={(e) => {
                      const month = e.target.value;
                      setMonth(() => { return e.target.value; })
                      getKpiData(_kpiUnit, month);
                      findDays(_kpiYear, month);
                    }}
                  >
                    {months.map((month, index) => {
                      return (<option key={index + '_month'} value={month}>{index + 1}월</option>);
                    })}
                  </select>
                </div>
                <div className='nav-bar'>
                  <button className='ResetButton' onClick={(e) => { e.preventDefault(); actReset(); }}>초기화</button>
                </div>
              </>) : null}
            </>) : null}
          </div>
        </nav>

        <div className='contents'>
          <div className='contentWrap'>
            {checkEmptyNull(_kpiUnit?.id, false) ? (
              <>
                <KPIGraph unit={_kpiUnit} contents={_kpiDataList} />
                <KPITable unit={_kpiUnit} contents={_kpiDataList} days={_days} weeks={_weeks} />
              </>
            ) : null}
          </div>
        </div>
      </OperatingKPIMonitoringComponent>
    </>
  );
};

export default OperatingKPIMonitoring;