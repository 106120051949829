import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { elementActions_setElementType, elementActions_setSearchData, elementActions_setSearchAttrs, elementActions_setPageNumber, elementActions_setTotalSize, elementActions_setReset, elementActions_setClickedLevel3Category, elementActions_setClickedLevel2Category, elementActions_setClickedLevel1Category, elementActions_setBookmark } from 'store/modules/actions/common/elementActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { bookmark } from 'api/apis/bookmark/bookmark';
import { elementApi } from 'api/apis/elementApi';
import { elementAttributeApi } from 'api/apis/elementAttributeApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { LoadingMsg } from 'components/loading/LoadingMsg';

import Bookmark from 'components/icons/Bookmark';
import Categories from 'components/category/Categories';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import DOCButtonIcon from 'components/icons/DOCButtonIcon';
import ElementUploadModal from 'components/excel/upload/ElementUploadModal';
import ExcelDownloadModal from 'components/excel/ExcelDownloadModal';
import Grid3Body from 'components/layouts/body/Grid3Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import RelatedWorkOrder from 'pages/workOrder/modal/RelatedWorkOrder';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import ShowFileList from 'pages/file/modal/ShowFileList';
import TableSection from 'components/layouts/table/TableSection';

const ElementSection = styled.main`
  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
    & .TypeButtons {display: flex; gap: 5px;}
  }

  & .TableSection {
    tr[name='elementRows']:hover {cursor: pointer;}
  }
`;

const SearchAttrs = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  /* width: calc(100vw - 450px); */
`;
const SearchAttr = styled.div`
  align-items: center;
  box-sizing: border-box;
  column-gap: 10px;
  display: flex;
  height: unset;
  padding: 6px 12px;
  position: relative;
  justify-content: space-between;
  width: fit-content;
  & p {width: calc(100% - 20px); word-break: break-all;}
`;
const DeleteButton = styled.div`
  height: 24px;
  width: 24px;
  div {background-color: var(--white); cursor: pointer; height: 24px; width: auto;}
`;

const RelatedWorkOrderButton = styled.td`
  div {
    cursor: pointer;
    background-color: var(--MainBlue);
    color: var(--white);
    font-size: 1.2rem;
    padding: 16px;
    vertical-align: middle;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(196,196,196,0.8);
    &:hover {opacity: .7;}
  }
`;

const ElementManagement = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { elementReducer, pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_originAttrList, setOriginAttrList] = useState([]);
  const [_elementAttrList, setElementAttrList] = useState([]);
  const [_elementAttrNameList, setElementAttrNameList] = useState({});

  const [_originList, setOriginList] = useState([]);
  const [_elementList, setElementList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_searchOption, setSearchOption] = useState('');
  const [_searchText, setSearchText] = useState('');

  const [_detailStatus, setDetailStatus] = useState(false);

  const [_downloadModalStatus, setDownloadModalStatus] = useState(false);
  const [_uploadModalStatus, setUploadModalStatus] = useState(false);

  const [_relatedWorkOrderModalStatus, setRelatedWorkOrderModalStatus] = useState(false);
  const [_eventElementId, setEventElementId] = useState();

  const [_fileListModalStatus, setFileListModalStatus] = useState(false);
  const [_eventContent, setEventContent] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('101') === true || // 기준정보
      thisItem.authority.startsWith('109') === true || // 파일
      thisItem.authority.startsWith('111') === true, // 작업정의
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    (async () => {
      await getElementAttrs(elementReducer.elementType);
      await getElementList(elementReducer.pageNumber - 1, elementReducer.elementType);
    })();

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getElementList(elementReducer.pageNumber - 1, elementReducer.elementType);
    }
    return () => { };
  }, [elementReducer.pageNumber]);

  useEffect(() => {
    if (_onload === 'loaded') {
      dispatch(elementActions_setPageNumber(1));
      getElementList(0, elementReducer.elementType);
    }
    return () => { };
  }, [elementReducer.bookmark, elementReducer.clickedCategory]);

  useEffect(() => { }, [elementReducer]);

  /* ====================================================================== #4 */
  const getElementAttrs = async (elementType) => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (checkEmptyNull(elementType, false) && elementType !== 'all') {
      BodyToPost.elementType = elementType;
    }
    console.log('getElementAttrs - BodyToPost : ', BodyToPost);

    await elementAttributeApi.searchElementAttribute(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('elementAttributeApi.searchElementAttribute : ', response);
      let temp = [];
      let result = [];
      response.data.forEach((thisItem) => {
        if (!temp.includes(thisItem.elementAttributeName)) {
          temp.push(thisItem.elementAttributeName);
          result.push(thisItem);
        }
      });
      setOriginAttrList(() => { return response.data });
      setElementAttrList(() => { return result });

      const nameList = {};
      response.data.forEach((thisItem) => {
        nameList[thisItem.elementAttributeId] = thisItem.elementAttributeName;
      });
      setElementAttrNameList(() => { return nameList });
    });
  };

  const getElementList = async (page, elementType, clear) => {
    let paging = `?page=${page}&size=15`;
    if (elementReducer.bookmark) paging += '&sort=bookMark,DESC&sort=id,DESC';
    else paging += '&sort=id,DESC';

    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && checkEmptyNull(elementType, false) && elementType !== 'all') {
      BodyToPost.elementType = elementType;
    }
    if (!clear && checkNullObject(elementReducer.clickedCategory, false) && elementReducer.clickedCategory !== 'all') {
      BodyToPost.categoryId = elementReducer.clickedCategory.categoryId;
    }

    if (!clear && checkEmptyNull(elementReducer.searchData.elementName, false)) {
      BodyToPost.elementName = elementReducer.searchData.elementName;
    }
    if (!clear && checkEmptyNull(elementReducer.searchData.elementCode, false)) {
      BodyToPost.elementCode = elementReducer.searchData.elementCode;
    }

    if (!clear && _detailStatus && checkNullObject(elementReducer.searchAttrs, false)) {
      BodyToPost.attributeAndValue = elementReducer.searchAttrs;
    }

    await elementApi.searchElement(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('elementApi.searchElement : ', response);
      setOriginList(() => { return response.data.content; });
      setElementList(() => { return response.data.content; });

      dispatch(elementActions_setTotalSize(response.data.totalElements));
    });
  };

  /* 같은 항목 모으기 */
  // const forRenderElementAttrs = (elementAttrList) => {
  //   let temp = [];
  //   let result = [];
  //   elementAttrList.forEach((attr) => {
  //     if (!temp.includes(attr.elementAttributeName)) {
  //       temp.push(attr);
  //       // temp.push(attr.elementAttributeName);
  //       // result.push(<th key={attr.elementAttributeId + '_elementAttrs'}>{attr.elementAttributeName}</th>);
  //     }
  //   });
  //   return temp;
  // };

  /* ====================================================================== #5 */
  const handleElementType = async (elementType) => {
    if (elementType === 'all') {
      dispatch(elementActions_setClickedLevel3Category({}));
      dispatch(elementActions_setClickedLevel2Category({}));
      dispatch(elementActions_setClickedLevel1Category({}));
    }

    dispatch(elementActions_setElementType(elementType));
    dispatch(elementActions_setPageNumber(1));

    await getElementAttrs(elementType);
    await getElementList(0, elementType);
  }

  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set([..._checkedItem]);
    if (isChecked) checkedItem.add(id);
    else if (!isChecked && checkedItem.has(id)) checkedItem.delete(id);

    setCheckedItem(() => { return checkedItem });
  };

  const handleBookmark = async (element, index) => {
    await bookmark.elementBookmark(element.elementId).then(response => {
      if (response === undefined) return;
      console.log('bookmark.elementBookmark : ', response);

      getElementList(elementReducer.pageNumber - 1, elementReducer.elementType);
    })
  }

  /* 검색 */
  const actSearch = async () => {
    dispatch(elementActions_setPageNumber(1));
    await getElementList(0, elementReducer.elementType);
  };

  /* 상세 검색 */
  const addSearchAttr = async () => {
    if (!checkEmptyNull(_searchOption, false) && !checkEmptyNull(_searchText, false)) return;
    dispatch(elementActions_setSearchAttrs({ ...elementReducer.searchAttrs, [_searchOption]: _searchText }));
  };

  /* 초기화 */
  const actReset = async () => {
    setSearchOption(() => { return '' });
    setSearchText(() => { return '' });

    dispatch(elementActions_setReset());

    dispatch(elementActions_setPageNumber(1));
    await getElementAttrs('all');
    await getElementList(0, 'all', true);
  };

  /* 업로드 모달 */
  const actUpload = () => { setUploadModalStatus(true); };

  /* 다운로드 모달 */
  const actDownload = () => { setDownloadModalStatus(true); };

  /* 파일 리스트 */
  const showFileList = (element) => {
    setEventContent(() => { return element });
    setTimeout(setFileListModalStatus(true), 1000);
  };

  /* 상세보기 */
  const goDetails = (element) => {
    const elementIndex = _originList.findIndex((thisItem) => thisItem.elementId === element.elementId);
    const elementData = _originList[elementIndex];
    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({
        version: 1,
        updateContent: JSON.stringify({
          ...elementData,
          elementAttributeList: _originAttrList.filter((thisItem) => thisItem.elementType === elementData.elementType),
        }),
      })}`,
      replace: true,
    });
  };

  /* 복사 */
  const actCopy = () => {
    const checkedElementList = Array.from(_checkedItem);

    if (checkedElementList.length < 1) return;
    else if (checkedElementList.length > 1) return alert('다중 선택되어 있습니다.');

    const elementIndex = _originList.findIndex((thisItem) => thisItem.elementId === checkedElementList[0]);
    if (elementIndex === -1) return;
    const elementData = _originList[elementIndex];

    navigate({
      pathname: pageReducer.currentPage + '/copy',
      search: `?${createSearchParams({
        version: 1,
        copyContent: JSON.stringify({
          ...elementData,
          elementAttributeList: _originAttrList.filter((thisItem) => thisItem.elementType === elementData.elementType),
        }),
      })}`,
      replace: true,
    });
  };

  /* 수정 */
  const actUpdate = () => {
    const checkedElementList = Array.from(_checkedItem);

    if (checkedElementList.length < 1) return;
    else if (checkedElementList.length > 1) return alert('다중 선택되어 있습니다.');

    const elementIndex = _originList.findIndex((thisItem) => thisItem.elementId === checkedElementList[0]);
    if (elementIndex === -1) return;
    const elementData = _originList[elementIndex];

    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({
        version: 1,
        updateContent: JSON.stringify({
          ...elementData,
          elementAttributeList: _originAttrList.filter((thisItem) => thisItem.elementType === elementData.elementType),
        }),
      })}`,
      replace: true,
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const checkedElementList = Array.from(_checkedItem);
    console.log('checkedElementList : ', checkedElementList);

    if (checkedElementList.length < 1) return;

    const deleteContents = checkedElementList.map((elementId) => {
      const elementIndex = _originList.findIndex((thisItem) => thisItem.elementId === elementId);
      if (elementIndex === -1) return null;
      const elementData = _originList[elementIndex];

      return {
        deleteContent: {
          ...elementData,
          elementAttributeList: _originAttrList.filter((thisItem) => thisItem.elementType === elementData.elementType),
        },
      };
    });
    const setDeleteContents = deleteContents.filter((thisItem) => thisItem !== null);
    console.log('deleteContents : ', setDeleteContents);

    if (setDeleteContents.length < 1) return;
    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({
        deleteContents: JSON.stringify(setDeleteContents),
      })}`,
      replace: true,
    });
  };

  /* ====================================================================== #6 */
  // const handleOnKeyUp = (e) => {
  //   if (e.key === 'Enter') {
  //     actSearch();
  //   }
  // };

  return (
    <Grid3Body contents={
      <>
        <ElementSection className="Main" detailStatus={_detailStatus}>
          <NavBar
            title={
              <div className='Title'>
                <NavTitle menuCode={'101'} />
                {/* 버튼 컴포넌트로 분리중 */}
                {/* <TypeButton
                  elementReducer={elementReducer}
                  userReducer={userReducer}
                /> */}
                {/* 유형 select 를 버튼으로 변경 */}
                <div className='TypeButtons'>
                  <button className={elementReducer.elementType === 'all' ? 'btn-inven-1' : 'btn-set'}
                    onClick={() => { handleElementType('all') }}
                  >
                    전체
                  </button>
                  {userReducer.elementType.map((type, index) => {
                    let typeText = '';
                    switch (type) {
                      case 'material': typeText = '자재'; break;
                      case 'semi': typeText = '부자재'; break;
                      case 'half': typeText = '반제품'; break;
                      case 'product': typeText = '완제품'; break;
                      case 'tool': typeText = '공구'; break;
                      default: return null;
                    }
                    return (
                      <button key={index + '_elementTypeButtons'}
                        className={elementReducer.elementType === type ? 'btn-inven-1' : 'btn-set'}
                        onClick={() => { handleElementType(type) }}
                      >
                        {typeText}
                      </button>
                    );
                  })}
                </div>
                {/* 기존 select 옵션 선택으로 유형 필터링 */}
                {/* <select
                    className="SelectType"
                    value={elementReducer.elementType}
                    onChange={(e) => { handleElementType(e.target.value) }}
                  >
                    {userReducer.elementType.map((elementType, index) => {
                      let typeText = '';
                      switch (elementType) {
                        case 'material': typeText = '자재'; break;
                        case 'semi': typeText = '부자재'; break;
                        case 'half': typeText = '반제품'; break;
                        case 'product': typeText = '완제품'; break;
                        case 'tool': typeText = '공구'; break;

                        default: return null;
                      }
                      return (<option key={index + '_elementTypes'} value={elementType}>{typeText}</option>);
                    })}

                    <option value="all">전체</option>
                  </select> */}
              </div>
            }
            buttons={
              <>
                {_authority.indexOf('101-2') !== -1 ? (
                  <>
                    {elementReducer.elementType !== 'all' ?
                      <button
                        className='btn-add'
                        onClick={(e) => {
                          e.preventDefault();
                          // if (!checkNullObject(elementReducer.clickedCategory, false)) return alert('물품 추가할 패밀리를 선택한 후 추가해주세요.');
                          navigate({
                            pathname: pageReducer.currentPage + '/create',
                            search: `?${createSearchParams({
                              version: 1,
                              createContent: JSON.stringify({
                                // categoryId: elementReducer.clickedCategory,
                                elementType: elementReducer.elementType,
                                // elementAttributeList: _elementAttrList,
                              })
                            })}`,
                            replace: true,
                          });
                        }}
                      >
                        추가
                      </button>
                      : null}
                    <button className='btn-copy' onClick={actCopy}>복사</button>
                    <button className='btn-edit' onClick={actUpdate}>수정</button>
                    <button className='btn-delete' onClick={actDelete}>삭제</button>
                    <button className='btn-upload' onClick={actUpload}>물품정보 업로드</button>
                  </>
                ) : null}
                <button className='btn-download' onClick={actDownload}>다운로드</button>
              </>
            }
            nav={!_detailStatus ? 'all' : 'details'}
            firstRow={
              <>
                <div className="SearchSection">
                  <Categories categoryType={'element'} />
                </div>
              </>
            }
            secondRow={
              <>
                <div className="SearchSection">
                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="elementName">물품이름</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkEmptyNull(elementReducer.searchData.elementName, '')}
                      onInput={(e) => {
                        dispatch(elementActions_setSearchData({ ...elementReducer.searchData, elementName: e.target.value }));
                      }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="elementCode">물품코드</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkEmptyNull(elementReducer.searchData.elementCode, '')}
                      onInput={(e) => {
                        dispatch(elementActions_setSearchData({ ...elementReducer.searchData, elementCode: e.target.value }));
                      }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <div className="ResetButton detail" onClick={actReset}>초기화</div>

                    <div className={`DetailButton ${!_detailStatus && 'active'}`}
                      onClick={() => { setDetailStatus((prev) => { return !prev }) }}
                    >
                      상세 검색
                    </div>

                    <div className="DetailButton search" style={{ backgroundColor: 'var(--ThirdBlue)', columnGap: '3px' }}
                      onClick={actSearch}
                    >
                      <SearchButtonIcon />
                      검색
                    </div>
                  </div>
                </div>
              </>
            }
            thirdRow={
              <>
                {_detailStatus && (
                  <div className="SearchSection">
                    <div className="ContentCBox">
                      <div className="ContentCBox">
                        <select
                          className="SearchOption"
                          value={_searchOption}
                          onChange={(e) => { setSearchOption(() => { return e.target.value }); }}
                        >
                          <option value="">항목선택</option>
                          {checkNullArray(_elementAttrList, []).map((attr, attrIndex) => {
                            return (<option key={attrIndex + '_searchOptionAttrs'} value={attr.elementAttributeId}>{attr.elementAttributeName}</option>);
                          })}
                        </select>
                        <input
                          className="SearchBar"
                          placeholder="Search..."
                          value={_searchText}
                          onInput={(e) => { setSearchText(() => { return e.target.value }); }}
                        />
                      </div>

                      <button data-searchbutton="true" onClick={addSearchAttr}><div style={{ fontSize: '30px', lineHeight: '35px' }}>+</div></button>
                    </div>

                    <SearchAttrs>
                      {Object.keys(elementReducer.searchAttrs).map((thisKey) => {
                        return (<SearchAttr key={thisKey + '_searchAttr'} className="formButton"
                          onClick={(e) => {
                            e.preventDefault();
                            const prevData = { ...elementReducer.searchAttrs };
                            delete prevData[thisKey];
                            dispatch(elementActions_setSearchAttrs(prevData));
                          }}
                        >
                          <p>{`${_elementAttrNameList[thisKey]}: ${elementReducer.searchAttrs[thisKey]}`}</p>
                          <DeleteButton><CloseButtonIcon /></DeleteButton>
                        </SearchAttr>);
                      },
                      )}
                    </SearchAttrs>
                  </div>
                )}
              </>
            }
          />

          <TableSection content={
            <table>
              <thead>
                <tr>
                  {_authority.indexOf('101-2') !== -1 ? (
                    <th style={{ minWidth: '50px', width: '50px' }}>
                      <input
                        type="checkBox"
                        name="allElements"
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          const checkedItem = new Set([..._checkedItem]);

                          if (isChecked) {
                            document.getElementsByName('elements').forEach((thisAttr) => {
                              checkedItem.add(Number(thisAttr.dataset.key));
                              thisAttr.checked = true;
                            });

                            e.target.checked = true;
                          } else {
                            document.getElementsByName('elements').forEach((thisAttr) => {
                              checkedItem.delete(Number(thisAttr.dataset.key));
                              thisAttr.checked = false;
                            });
                            e.target.checked = false;
                          }

                          setCheckedItem(() => { return checkedItem });
                        }}
                      />
                    </th>
                  ) : null}
                  <th style={{ minWidth: '50px', width: '50px' }}>
                    <Bookmark
                      bookmark={elementReducer.bookmark}
                      clickEvent={() => {
                        dispatch(elementActions_setBookmark(!elementReducer.bookmark))
                      }}
                    />
                  </th>
                  {(() => {
                    if (userReducer.company.companyId !== 2 && (_authority.indexOf('111-1') !== -1 || _authority.indexOf('111-2') !== -1)) {
                      return (<th style={{ minWidth: '80px', width: '80px' }}>BOM</th>);
                    }
                  })()}
                  {(() => {
                    if (_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) {
                      return (<th rowSpan={2} style={{ minWidth: '70px', width: '70px' }}>파일</th>);
                    }
                  })()}
                  <th>물품유형</th>
                  <th>패밀리</th>
                  <th>물품이름</th>
                  <th>물품코드</th>
                  <th>안전재고</th>
                  <th>단가</th>
                  {checkNullArray(_elementAttrList, []).map((attr, attrIndex) => {
                    return <th key={attrIndex + '_attrNames'}>{attr.elementAttributeName}</th>
                  })}
                </tr>
              </thead>
              <tbody>
                {!checkNullArray(_elementList, false) ? LoadingMsg() :
                  checkNullArray(_elementList, []).map((thisItem, index) => {
                    return (
                      <tr key={index + '_elements'}
                        name={_authority.indexOf('101-2') !== -1 && 'elementRows'}
                        onClick={(e) => { _authority.indexOf('101-2') !== -1 && goDetails(thisItem) }}
                      >
                        {_authority.indexOf('101-2') !== -1 ? (
                          <td style={{ minWidth: '50px', width: '50px' }}>
                            <input
                              type="checkBox"
                              name="elements"
                              data-key={thisItem.elementId}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                handleCheckedItem(thisItem.elementId, isChecked);

                                if (isChecked) e.target.checked = true;
                                else e.target.checked = false;
                              }}
                              onClick={(e) => { e.stopPropagation() }}
                            />
                          </td>
                        ) : null}
                        <td style={{ minWidth: '50px', width: '50px' }}>
                          <Bookmark
                            bookmark={checkEmptyNull(thisItem.bookMark, false)}
                            clickEvent={() => { handleBookmark(thisItem, index) }}
                          />
                        </td>
                        {(() => {
                          if (userReducer.company.companyId !== 2 && (_authority.indexOf('111-1') !== -1 || _authority.indexOf('111-2') !== -1)) {
                            return (
                              <RelatedWorkOrderButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEventElementId(() => { return thisItem.elementId });
                                  setTimeout(setRelatedWorkOrderModalStatus(true), 1000);
                                }}
                              >
                                <div>BOM</div>
                              </RelatedWorkOrderButton>
                            );
                          }
                        })()}
                        {/* 파일 */}
                        {(() => {
                          if (_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) {
                            if (checkNullArray(thisItem.attachFileList, false)) {
                              return (
                                <td className='table-preview'>
                                  <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      showFileList(thisItem);
                                    }}
                                  >
                                    <img src={thisItem.attachFileList[0]?.fileData?.fileDataS3URL || ''} alt='image preview' className='img-preview' />
                                    <div className='btn-file'><DOCButtonIcon /></div>
                                  </div>
                                </td>
                              );
                            } else {
                              return (
                                <td className='table-preview'>
                                  <div
                                    style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      showFileList(thisItem);
                                    }}
                                  >
                                    <div className='no-image'><p>이미지가 없습니다.</p></div>
                                    <div className='btn-file'><DOCButtonIcon /></div>
                                  </div>
                                </td>
                              );
                            }
                          }
                        })()}
                        <td>
                          {checkEmptyNull(
                            thisItem.elementType === 'material' ? '자재'
                              : thisItem.elementType === 'half' ? '반제품'
                                : thisItem.elementType === 'product' ? '완제품'
                                  : thisItem.elementType === 'semi' ? '부자재'
                                    : thisItem.elementType === 'tool' ? '공구'
                                      : null
                          )}
                        </td>
                        <td>{checkEmptyNull(thisItem.categoryPath, checkEmptyNull(thisItem.categoryName, ''))}</td>
                        <td className={checkEmptyNull(thisItem.elementName, false) && thisItem.elementName.startsWith('*') ? 'outsourcingText' : null}>
                          {thisItem.elementName}
                        </td>
                        <td>{thisItem.elementCode}</td>
                        <td>{checkEmptyNull(thisItem.elementSafeStock, 0).toLocaleString() + ' ' + checkEmptyNull(thisItem.elementUnit, '')}</td>
                        <td>{checkEmptyNull(thisItem.price, 0).toLocaleString()}</td>
                        {checkNullArray(thisItem.elementAttributeValueList, []) ? checkNullArray(_elementAttrList, []).map((attr, attrIndex) => {
                          const attrValueIndex = thisItem.elementAttributeValueList.findIndex((attrValue) => attrValue.elementAttribute.elementAttributeName === attr.elementAttributeName && checkEmptyNull(attrValue.value, false));
                          // attrValue.elementAttribute.elementType === elementReducer.elementType && 
                          if (attrValueIndex !== -1) {
                            return <td key={thisItem.elementId + '_elementAttr_' + attrIndex}>
                              {thisItem.elementAttributeValueList[attrValueIndex].value}
                            </td>;
                          } else {
                            return <td key={thisItem.elementId + '_elementAttr_' + attrIndex}></td>;
                          }
                        }) : null}
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          }
            sectionStyle={{ height: 'calc(100% - 66px)' }}
          ></TableSection>
        </ElementSection>

        <PagingComponent
          page={elementReducer.pageNumber}
          count={elementReducer.totalSize}
          size={15}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(elementActions_setPageNumber(page));
          }}
        />

        {_downloadModalStatus === true ? (
          <ExcelDownloadModal
            mainTitle={'Products'}
            subTitle={'Information'}
            open={_downloadModalStatus}
            close={() => { setDownloadModalStatus(false) }}
          />
        ) : null}

        {_uploadModalStatus === true ? (
          <ElementUploadModal
            open={_uploadModalStatus}
            close={() => {
              setUploadModalStatus(false);
              getElementList(0);
            }}
          />
        ) : null}

        {_relatedWorkOrderModalStatus === true ? (
          <RelatedWorkOrder
            elementId={_eventElementId}
            open={_relatedWorkOrderModalStatus}
            close={() => { setRelatedWorkOrderModalStatus(false) }}
          />
        ) : null}

        {_fileListModalStatus === true && checkEmptyNull(_eventContent.elementId, false) ? (
          <ShowFileList
            type={'element'}
            content={_eventContent}
            open={_fileListModalStatus}
            close={() => { setFileListModalStatus(false) }}
          ></ShowFileList>
        ) : null}
      </>
    }
    />
  );
};

export default ElementManagement;
