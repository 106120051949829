import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { getCookie } from 'preferences/cookie/cookie';
import { auth } from 'api/apis/auth/auth';

import { checkEmptyNull } from 'components/checkValues/checkValues';

const CompanySelectSection = styled.main`
  align-items: center;
  background-color: var(--MainNavy);
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;

  .sign {
    display: grid; justify-content: center; align-items: center; padding:10% 0; row-gap: 30px;
    &>p{color:#898989;margin:8px;}
    fieldset {display:grid;gap:18px;
      input{width:450px;padding:16px 24px;margin:0;border-radius:8px;border:1px solid #ddd;}
    }
    .submit {width:100%;height:60px; background-color: var(--MainBlue);color:var(--white); border-radius:10px;font-size:1.25rem;}
    .signitem {display:flex;gap:16px;margin-top:24px; justify-content: space-between;
      button{color:var(--white);}
    }
  }
  .signTitle {
    align-items: center;
    column-gap: 20px;
    display: grid;
    grid-template-columns: 50px auto;
    width: fit-content;

    img {height: 50px; width: 50px;}
    h3 {color: var(--white); font-size: 30px;}
  }
  .signup {
    padding:4% 0;
    fieldset{
      &>p{color:#898989;margin:8px;span{color:#000;}}
    }
  }
`;


const CompanySelect = () => {
  const navigate = useNavigate();

  const [_joinActive, setJoinActive] = useState(false);
  const [_agreementModalStatus, setAgreementModalStatus] = useState(true);
  const [_myData, setMyData] = useState({});
  const [_companyJoinCode, setCompanyJoinCode] = useState(''); // dGVhbS8xMg==

  const actJoin = async () => {
    if (!checkEmptyNull(_companyJoinCode, false)) return alert('가입코드를 입력해주세요.');
    setJoinActive(() => { return true }); // 중복클릭방지
    const BodyToPost = { joinCode: _companyJoinCode };
    await auth.joinCode(getCookie('accessToken'), BodyToPost).then(async (response) => { // 가입코드
      if (response === undefined) return;
      console.log('auth.joinCode : ', response);
      const alertText = `
      ${response.data}
      로그인 화면으로 이동합니다.
      `;
      alert(alertText);
      setJoinActive(() => { return false; });
      navigate(`/sign/login`, { replace: true });
    }).catch((error) => {
      console.log('auth.joinCode - error : ', error);
      setJoinActive(() => { return false; });
    })
  }

  return (
    <CompanySelectSection>
      <section className='sign'>
        <div className='signTitle'>
          <img src={process.env.PUBLIC_URL + '/logo_Ifis.svg'} alt="아이피스 로고" />
          <h3>아이피스 가입코드</h3>
        </div>
        <fieldset>
          <input
            type='text'
            placeholder='가입코드를 입력해주세요.'
            onInput={(e) => {
              e.preventDefault();
              setCompanyJoinCode(() => { return e.target.value });
            }}
          />
        </fieldset>
        <button type="submit" className='submit' onClick={(e) => { e.preventDefault(); if (!_joinActive) actJoin(); }}>가입하기</button>
        <div className='signitem'>
          <button onClick={() => { navigate(`/sign/login`, { replace: true }); }} >로그인</button>
          <button onClick={() => { navigate(`/sign/email/find`, { replace: true }); }} >아이디 찾기</button>
          <button onClick={() => { navigate(`/sign/pw/find`, { replace: true }); }} >비밀번호 찾기</button>
        </div>
      </section>
    </CompanySelectSection>
  );
};

export default CompanySelect;
