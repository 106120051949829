import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import styled from 'styled-components';

import { manHour } from 'api/apis/manHour/manHour';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import TableSection from 'components/layouts/table/TableSection';
import SelectOneUser from 'pages/user/modal/SelectOneUser';
import SelectWorkOrderLog from 'pages/productionOrder/modal/SelectWorkOrderLog';
import SelectWorkOrderLogs from 'pages/productionOrder/modal/SelectWorkOrderLogs';
import StatusTdButton from 'components/buttons/StatusTdButton';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  /* max-height: 700px;
  max-width: 880px; */
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & form > div {background-color: unset;}
  & input:disabled {
    background-color: var(--ThirdBlue);
    color: var(--white);
  }

  .TableSection {zoom: 85%;}
`;
const DeleteButton = styled.div`
  height: 15px;
  width: 15px;
  div {background-color: var(--MainRed); cursor: pointer; height: 20px; width: 20px;}
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainBlue);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const CreateManHour = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    date: '',
    time: '',
    note: '',
    workerId: '',
    workOrderLogIds: [],
    processLogIds: [],
  });

  const [_workerModalStatus, setWorkerModalStatus] = useState(false);
  const [_worker, setWorker] = useState({ id: '', name: '' });

  const [_workOrderLogModalStatus, setWorkOrderLogModalStatus] = useState(false);
  const [_workOrderLogList, setWorkOrderLogList] = useState([]);

  const [_modalStatus, setModalStatus] = useState(false);
  const [_processLogList, setProcessLogList] = useState([]);

  /* ====================================================================== #3 */
  useEffect(() => { console.log('_workOrderLogList : ', _workOrderLogList); }, [_workOrderLogList])
  useEffect(() => { console.log('_processLogList : ', _processLogList); }, [_processLogList])

  /* ====================================================================== #4 */

  /* ====================================================================== #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value } });
  };

  const updateWorkOrderLog = (data) => {
    console.log('updateWorkOrderLog - data : ', data);
    setWorkOrderLogList((prev) => {
      const workOrderLogIdArray = prev.map((item) => item.workOrderLogId);
      console.log(workOrderLogIdArray);
      const workOrderLogList = data.map((workOrderLogContent) => {
        const filterIndex = workOrderLogIdArray.findIndex((item) => item === workOrderLogContent.workOrderLogId);
        console.log(filterIndex);
        if (filterIndex === -1) return workOrderLogContent;
        else return null;
      });
      const returnList = workOrderLogList.filter(item => item !== null);
      return [...prev, ...checkNullArray(returnList, [])];
    });
  }
  const deleteWorkOrderLog = (index) => {
    setWorkOrderLogList(prev => {
      const returnData = [...prev];
      returnData.splice(index, 1);
      return returnData;
    })
  }

  const updateProcessLog = (data) => {
    console.log('updateProcessLog - data : ', data);
    setProcessLogList((prev) => { return [...prev, ...checkNullArray(data, [])] });
  }
  const deleteProcessLog = (processLog) => {
    setProcessLogList(prev => {
      const returnData = [...prev];
      const processLogIndex = returnData.findIndex(item => item.processLogId === processLog.processLogId);
      returnData.splice(processLogIndex, 1);
      return returnData;
    })
  }

  const actCreate = async (e) => {
    e.preventDefault();
    if (!checkEmptyNull(_worker.id, false)) return alert('작업자를 선택해주세요.');
    if (!checkEmptyNull(_formData.date, false)) return alert('날짜를 선택해주세요.');

    const BodyToPost = {
      ..._formData,
      date: DateFormat(_formData.date, ''),
      workerId: _worker.id,
      workOrderLogIds: _workOrderLogList.map(workOrderLog => workOrderLog.workOrderLogId),
      processLogIds: _processLogList.map(processLog => processLog.processLogId)
    }
    console.log('BodyToPost : ', BodyToPost);
    await manHour.createhWorkingTime(BodyToPost).then(response => {
      if (response === undefined) return;
      console.log('manHour.createhWorkingTime : ', response);
      alert('공수를 추가했습니다.')
      setTimeout(() => { props.close(moment(BodyToPost.date).format('YYYY-MM-DD')); }, 1000);
    })
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>공수 추가</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <Form
                title={<>공수<br />추가</>}
                buttons={<></>}
                forms={
                  <>
                    <div className="formBox w100">
                      <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                        <h4>작업자</h4>
                        <div className="formButton"
                          style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                          onClick={(e) => { e.preventDefault(); setWorkerModalStatus(true); }}
                        >
                          작업자 선택
                        </div>
                      </div>
                      <div style={{ columnGap: '5px', display: 'grid', gridTemplateColumns: 'auto 50px', width: '100%' }}>
                        <input type="text" disabled value={_worker.name} />
                        <div className="formButton"
                          style={{ backgroundColor: 'var(--MainNavy)', borderRadius: '5px', height: '40px', width: '50px' }}
                          onClick={(e) => { e.preventDefault(); setWorker(() => { return { id: '', name: '' } }) }}
                        >
                          삭제
                        </div>
                      </div>
                    </div>

                    <div className="formBox">
                      <h4>날짜</h4>
                      <input type="date" name='date' onChange={handleInputEvent} />
                    </div>

                    <div className="formBox">
                      <h4>시간</h4>
                      <input type="text" name='time' onInput={handleInputEvent} />
                    </div>

                    <div className="formBox" style={{ width: '100%' }}>
                      <h4>작업내용</h4>
                      <textarea name='note' onInput={handleInputEvent} />
                    </div>

                    <div className="formBox" style={{ width: '100%' }}>
                      <h4>작업지시</h4>
                      <div className="formButton"
                        style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', cursor: 'pointer', height: '50px', width: '100%' }}
                        onClick={(e) => { e.preventDefault(); setWorkOrderLogModalStatus(true); }}
                      >
                        작업지시 선택
                      </div>
                    </div>

                    {checkNullArray(_workOrderLogList, false) ? <>
                      <div className="formBox" style={{ width: '100%' }}>
                        <h4>작업지시</h4>
                        <TableSection content={
                          <table>
                            <thead>
                              <tr>
                                <th style={{ minWidth: '50px', width: '50px' }}></th>
                                <th>작업상태</th>
                                <th>작업유형</th>
                                <th>작업코드</th>
                                <th>작업이름</th>
                                <th>거래처</th>
                                <th>작업장소</th>
                                <th>기간</th>
                              </tr>
                            </thead>
                            <tbody>
                              {checkNullArray(_workOrderLogList, []).map((workOrderLog, index) => {
                                return (
                                  <tr key={index + '_workOrderRow'} data-key={workOrderLog.workOrderLogId}>
                                    <td style={{ minWidth: '50px', width: '50px' }}>
                                      <DeleteButton onClick={(e) => { e.preventDefault(); deleteWorkOrderLog(index) }}>
                                        <CloseButtonIcon />
                                      </DeleteButton>
                                    </td>
                                    <StatusTdButton
                                      statusStyle={{ cursor: 'default', height: '120px', minWidth: '120px', width: '120px' }}
                                      status={workOrderLog.workOrderStatus}
                                    />
                                    <td>{workOrderLog.workOrderTypeName}</td>
                                    <td>{workOrderLog.workOrderCode}</td>
                                    <td>{workOrderLog.workOrderName}</td>
                                    <td>{workOrderLog.accountName}</td>
                                    <td>{workOrderLog.workPlace}</td>
                                    <td>{DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledStartDate, ''))}<br /> ~ <br />{DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledEndDate, ''))}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        }></TableSection>
                      </div>
                    </> : null}

                    <div className="formBox" style={{ width: '100%' }}>
                      <h4>공정</h4>
                      <div className="formButton"
                        style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', cursor: 'pointer', height: '50px', width: '100%' }}
                        onClick={(e) => { e.preventDefault(); setModalStatus(true); }}
                      >
                        공정 선택
                      </div>
                    </div>

                    {checkNullArray(_processLogList, false) ? <>
                      <div className="formBox" style={{ width: '100%' }}>
                        <h4>공정</h4>
                        <TableSection content={
                          <table>
                            <thead>
                              <tr>
                                <th rowSpan={2} style={{ minWidth: '50px', width: '50px' }}></th>
                                <th rowSpan={2}>공정상태</th>
                                <th rowSpan={2} style={{ minWidth: '80px', width: '80px' }}>순번</th>
                                <th colSpan={2}>공정정보</th>
                                <th colSpan={2}>진행상태</th>
                              </tr>
                              <tr>
                                <th>공정코드</th>
                                <th>공정이름</th>
                                <th>클릭</th>
                                <th>진행도</th>
                              </tr>
                            </thead>
                            <tbody>
                              {checkNullArray(_processLogList, []).map((processLog, index) => {
                                const currentClick = BigNumber(checkEmptyNull(processLog.completedAmount, 0)).dividedBy(BigNumber(checkEmptyNull(processLog.processingCount, 0))).toNumber();
                                const totalClick = BigNumber(checkEmptyNull(processLog.processAmount, 0)).dividedBy(BigNumber(checkEmptyNull(processLog.processingCount, 0))).toNumber();
                                return (
                                  <tr key={index + '_processLogs'}>
                                    <td style={{ minWidth: '50px', width: '50px' }}>
                                      <DeleteButton onClick={(e) => { e.preventDefault(); deleteProcessLog(processLog) }}>
                                        <CloseButtonIcon />
                                      </DeleteButton>
                                    </td>
                                    <StatusTdButton
                                      statusStyle={{ cursor: 'default', height: '120px', minWidth: '120px', width: '120px' }}
                                      status={processLog.processStatus}
                                    />
                                    <td style={{ minWidth: '80px', width: '80px' }}>{processLog.processNumber}</td>
                                    <td>{processLog.processCode}</td>
                                    <td>{processLog.processName}</td>
                                    <td>{currentClick.toLocaleString()} / {totalClick.toLocaleString()}</td>
                                    <td>{(checkEmptyNull(processLog.completedAmount, 0) * 1).toLocaleString()} / {(checkEmptyNull(processLog.processAmount, 0) * 1).toLocaleString()}</td>
                                  </tr>
                                );
                              })}

                            </tbody>
                          </table>
                        }></TableSection>
                      </div>
                    </> : null}
                  </>
                }
                formLayoutStyle={{ paddingInlineEnd: 'unset' }}
              />
            </ModalMain>

            <ModalFooter>
              <ModalButton onClick={actCreate}>추가</ModalButton>
            </ModalFooter>
          </ModalSection>

          {_workerModalStatus === true ? (
            <SelectOneUser
              buttonTitle={'작업자 선택'}
              buttonEvent={(data) => {
                console.log('data : ', data);
                setWorker(() => { return data });
                setTimeout(setWorkerModalStatus(false), 1000);
              }}
              open={_workerModalStatus}
              close={() => { setWorkerModalStatus(false) }}
            />
          ) : null}

          {_workOrderLogModalStatus === true ? (
            <SelectWorkOrderLogs
              buttonTitle={'작업지시 선택'}
              buttonEvent={(data) => {
                updateWorkOrderLog(data)
                setTimeout(setWorkOrderLogModalStatus(false), 1000);
              }}
              open={_workOrderLogModalStatus}
              close={() => { setWorkOrderLogModalStatus(false) }}
            ></SelectWorkOrderLogs>
          ) : null}
          {_modalStatus === true ? (
            <SelectWorkOrderLog
              content={_processLogList}
              update={(data) => { updateProcessLog(data) }}
              open={_modalStatus}
              close={() => { setModalStatus(false); }}
            ></SelectWorkOrderLog>
          ) : null}
        </ModalBg>
      )}
    </>
  );
};

export default CreateManHour;
