import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import styled from 'styled-components';

import { accountApi } from 'api/apis/accountApi';
import { shipmentApi } from 'api/apis/shipmentApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import GuideText from 'components/buttons/GuideText';
import NavBar from 'components/nav/NavBar';
import SelectElements from 'pages/element/modal/SelectElements';
import SelectOneAccount from 'pages/account/modal/SelectOneAccount';
import TableSection from 'components/layouts/table/TableSection';
import { ShipmentFormComponent, AccountTable } from './ShipmentForm.style';

const CreateShipment = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId, // 회사

    accountId: undefined,

    shipmentStatus: 'wait',
    shipmentCode: '',
    shipmentName: '',
    shipmentDate: moment().format('YYYY-MM-DD'),

    remark: '',
    customizedContent: {
      delivery: {
        driver: '',
        telNumber: '',
        licensePlateNumber: '',
      }
    },

    shipmentElementList: [],
  });

  const [_company, setCompany] = useState({
    selfAccountCode: '', // 사업자등록번호
    selfAccountName: '',

    selfBusinessNumber: '', // 계좌번호
    selfAddress: '',
    selfTelNumber: '',

    selfManagerName: '',
  });

  const [_account, setAccount] = useState({
    accountId: undefined,
    shipmentAccountCode: '', // 수요기관코드
    shipmentAccountName: '',

    shipmentBusinessNumber: '', // 사업자등록번호
    shipmentAddress: '',
    shipmentTelNumber: '',

    shipmentManagerName: '',
  });

  const [_deliveryData, setDeliveryData] = useState({
    driver: '',
    telNumber: '',
    licensePlateNumber: '',
  })

  const [_shipmentPrice, setShipmentPrice] = useState(0);

  const [_shipmentElements, setShipmentElements] = useState([]);

  const [_modalStatus, setModalStatus] = useState(false);
  const [_accountModalStatus, setAccountModalStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('205') === true, // shipment
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    getCompanyInformation();

    setOnload('loaded');
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  /* 회사 정보 */
  const getCompanyInformation = async () => {
    const paging = `?page=0`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      accountName: userReducer.company.companyName,
    };
    console.log('BodyToPost : ', BodyToPost);

    await accountApi.searchAccount(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('accountApi.searchAccount : ', response);

      if (checkNullArray(response.data.content, false)) {
        setCompany((prev) => {
          const returnData = { ...prev };
          const companyData = { ...response.data.content[0] };
          returnData.selfAccountCode = companyData.accountCode;
          returnData.selfAccountName = companyData.accountName;
          returnData.selfBusinessNumber = companyData.businessNumber;
          returnData.selfAddress = companyData.address;
          returnData.selfTelNumber = companyData.telNumber;
          return returnData;
        });
      }
    });
  };

  /* ====================================================================== #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value } });
  };
  const handleCompanyData = (e) => {
    const { name, value } = e.target;
    setCompany((prev) => { return { ...prev, [name]: value } });
  };
  const handleAccountData = (e) => {
    const { name, value } = e.target;
    setAccount((prev) => { return { ...prev, [name]: value } });
  };
  const handleDeliveryData = (e) => {
    const { name, value } = e.target;
    setDeliveryData((prev) => { return { ...prev, [name]: value } });
  };

  const handleShipmentPrice = (e) => {
    e.preventDefault();

    let eventValue = e.target.value.replace(/[^\d.-]/g, '');
    if (eventValue.length > 15) return;
    if (!checkEmptyNull(eventValue, false)) eventValue = 0;
    if (isNaN(eventValue) || eventValue === '0') eventValue = 0;
    eventValue = BigNumber(eventValue).toNumber();
    console.log('eventValue : ', eventValue);

    setShipmentPrice(() => { return eventValue });
  };

  const handleElementCustomData = (e, index, element) => {
    const { name, value } = e.target;

    const newData = { ...element, customizedContent: { ...element.customizedContent, [name]: value } };

    setShipmentElements(prev => {
      const returnData = [...prev];
      returnData.splice(index, 1, newData);
      return returnData;
    })
  }
  const handleElementInput = (e, index, element) => {
    const { name, value } = e.target;
    const numberType = ['price', 'quantity', 'calPrice'];
    let eventValue = value;

    if (numberType.includes(name)) {
      eventValue = e.target.value.replace(/[^\d.-]/g, '');
      if (eventValue.length > 15) return;
      if (!checkEmptyNull(eventValue, false)) eventValue = 0;
      if (isNaN(eventValue) || eventValue === '0') eventValue = 0;
      eventValue = BigNumber(eventValue).toNumber();
      console.log('eventValue : ', eventValue);
    }

    let newData = {};
    switch (name) {
      case 'price':
        newData = {
          ...element,
          price: BigNumber(eventValue).toNumber(),
          calPrice: handleCalPrice(eventValue, element.quantity),
        };
        break;

      case 'quantity':
        newData = {
          ...element,
          quantity: BigNumber(eventValue).toNumber(),
          calPrice: handleCalPrice(element.price, eventValue),
        };
        break;

      case 'calPrice':
        newData = { ...element, calPrice: BigNumber(eventValue).toNumber() };
        break;

      default: newData = { ...element, [name]: value }; break;
    }

    setShipmentElements(prev => {
      const returnData = [...prev];
      returnData.splice(index, 1, newData);
      if (numberType.includes(name)) calShipmentPrice(returnData);
      return returnData;
    })
  };
  const handleCalPrice = (price, quantity) => {
    const calPrice = BigNumber(price).multipliedBy(BigNumber(quantity)).toNumber();
    if (isNaN(calPrice)) return 0;
    else return calPrice;
  };
  const calShipmentPrice = (shipmentElementList) => {
    const sumArray = shipmentElementList.map((item) => item.calPrice);
    const setSumArray = sumArray.filter((item) => item !== undefined && item !== null && !isNaN(item));
    const sum = setSumArray.reduce((a, b) => BigNumber(a).plus(BigNumber(b)).toNumber(), 0);
    console.log('setSumArray : ', setSumArray);
    setShipmentPrice(() => { return sum });
  };

    // 버튼
  const handleButtonClick = () => {
    const currentDate = `#${moment().format('YYYY-MM-DD')}`;
    setFormData((prev) => {
      const updatedCode = prev.shipmentCode.includes(currentDate)
        ? prev.shipmentCode.replace(currentDate, '')
        : prev.shipmentCode + currentDate;
      return { ...prev, shipmentCode: updatedCode };
    });
  };
  const currentDate = `#${moment().format('YYYY-MM-DD')}`;
  const isDateIncluded = _formData.shipmentCode.includes(currentDate);

  const deleteElement = (index, element) => {
    setShipmentElements((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);

      const sumArray = prevData.map((item) => item.calPrice);
      const setSumArray = sumArray.filter((item) => item !== null);
      const sum = setSumArray.reduce((a, b) => BigNumber(a).plus(BigNumber(b)).toNumber(), 0);
      setShipmentPrice(() => { return BigNumber(sum).toNumber() });

      return prevData;
    });
  };

  const actCreate = async (e) => {
    e.preventDefault();

    if (!checkEmptyNull(_formData.shipmentCode, false)) return alert('출하코드를 입력해주세요.');
    if (!checkEmptyNull(_account.accountId, false)) return alert('거래처를 선택해주세요.');

    const BodyToPost = {
      ..._formData,

      ..._account,
      ..._company,

      shipmentPrice: _shipmentPrice,
      stockFlag: false,

      // selfAccountCode: _company.accountCode, // 사업자등록번호
      // selfAccountName: _company.accountName, // 회사명

      customizedContent: JSON.stringify({ delivery: _deliveryData }),

      shipmentElementList: _shipmentElements.map((element) => {
        const returnData = {
          accountId: _account.accountId,
          elementId: element.elementId,
          quantity: checkEmptyNull(element.quantity, 0),
          price: checkEmptyNull(element.calPrice, 0),
          remark: checkEmptyNull(element.remark, ''),

          amount: 0,
          note: '',

          customizedContent: JSON.stringify(element.customizedContent),
        };
        return returnData;
      }),
    };
    console.log('BodyToPost : ', BodyToPost);

    await shipmentApi.createShipment(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('shipmentApi.createShipment : ', response);

      alert('출하를 추가했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  /* ====================================================================== #6 */

  return (
    <Grid2Body
      contents={
        <>
          <ShipmentFormComponent>
            <NavBar title={'출하관리'} nav={''} />

            <Form
              title={
                <>
                  출하<br />추가
                </>
              }
              buttons={
                <>
                  <button className="formButton" onClick={actCreate}>저장</button>
                  <button className="formButton cancle"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                </>
              }
              forms={
                <>
                  <div className="formBox w100">
                    <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                      <h4>출하물품</h4>
                      <div className="formButton"
                        style={{ backgroundColor: 'var(--MainBlue)', borderRadius: '5px', width: '150px' }}
                        onClick={() => { setModalStatus(true) }}
                      >
                        출하물품 추가
                      </div>
                    </div>

                      <TableSection content={
                        <table>
                          <thead style={{ backgroundColor: 'var(--MainNavy)' }}>
                            <tr>
                              <th>물품이름</th>
                              <th>물품코드</th>
                              <th>현재고</th>
                              <th>수량</th>
                              <th>단가</th>
                              <th>금액</th>
                              <th>메모</th>
                              <th style={{ minWidth: '50px', width: '50px' }}></th>
                            </tr>
                          </thead>
                          <tbody>
                            {checkNullArray(_shipmentElements, []).map((element, index) => {

                              return (<tr key={index + '_shipmentElements'} data-key={element.elementId}>
                                <td className={checkEmptyNull(element.elementName, false) && element.elementName.startsWith('*') ? 'outsourcingText' : null}>
                                  {element.elementName}
                                </td>
                                <td>{element.elementCode}</td>
                                <td className={(() => {
                                  if (checkEmptyNull(element.elementSafeStock, false)) {
                                    if (element.elementSafeStock <= element.elementStock) return 'safeStock';
                                    else return 'dangerStock';
                                  } else {
                                    return '';
                                  }
                                })()}
                                >
                                  {checkEmptyNull(element.elementStock, 0).toLocaleString() + ' ' + checkEmptyNull(element.elementUnit, '')}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="quantity"
                                    min={0}
                                    value={checkEmptyNull(element.quantity, 0).toLocaleString()}
                                    onInput={(e) => { handleElementInput(e, index, element) }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="price"
                                    min={0}
                                    value={checkEmptyNull(element.price, 0).toLocaleString()}
                                    onInput={(e) => { handleElementInput(e, index, element) }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="calPrice"
                                    min={0}
                                    value={checkEmptyNull(element.calPrice, 0).toLocaleString()}
                                    onInput={(e) => { handleElementInput(e, index, element) }}
                                  />
                                </td>
                                <td>
                                  <textarea
                                    type="text"
                                    name="remark"
                                    maxLength={1000}
                                    rows={3}
                                    value={element.remark}
                                    onInput={(e) => { handleElementInput(e, index, element) }}
                                  />
                                </td>
                                <td style={{ minWidth: '50px', width: '50px' }}>
                                  <div className='DelButton' onClick={() => { deleteElement(index, element) }}><CloseButtonIcon /></div>
                                </td>
                              </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      }
                        formSectionStyle={{ paddingTop: '10px', width: '100%' }}
                      />

                    <div className="cautionText" style={{ fontSize: '14px', textAlign: 'right', width: '100%' }}>※ 출하수량을 꼭! 입력해 주세요.</div>
                  </div>

                  <div className="formBox">
                    <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                      <h4>출하코드(일련번호)</h4>
                      <div className='DateCheckBox'>
                        <div
                          className="formButton"
                          style={
                            isDateIncluded
                              ? { backgroundColor: 'var(--MainNavy)', borderRadius: '5px', width: '100px', userSelect: 'none' }
                              : { backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '100px', userSelect: 'none' }
                          }
                          onClick={handleButtonClick}
                        >
                          {isDateIncluded ? '날짜삭제' : '날짜추가'}
                        </div>
                      </div>
                    </div>
                    <input
                      type="data"
                      name="shipmentCode"
                      placeholder="* 출하코드(일련번호)..."
                      data-required="required"
                      value={_formData.shipmentCode}
                      onInput={handleInputEvent}
                    />
                  </div>

                  <div className="formBox">
                    <h4>출하이름</h4>
                    <input
                      type="text"
                      name="shipmentName"
                      placeholder="* 출하이름..."
                      value={_formData.shipmentName}
                      onInput={handleInputEvent}
                    />
                  </div>

                  <div className="formBox w100">
                    <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                      <h4>회사 / 거래처</h4>
                      <div
                        className="formButton"
                        style={{ backgroundColor: 'var(--MainNavy)', borderRadius: '5px', width: '80px' }}
                        onClick={async () => {
                          await getCompanyInformation();
                          setAccount(() => {
                            const returnData = {
                              shipmentAccountCode: '', // 수요기관코드
                              shipmentAccountName: '',

                              shipmentBusinessNumber: '', // 사업자등록번호
                              shipmentAddress: '',
                              shipmentTelNumber: '',

                              shipmentManagerName: '',
                            }
                            return returnData;
                          });
                        }}
                      >
                        초기화
                      </div>
                    </div>
                    <AccountTable>
                      <thead>
                        <tr>
                          <th></th>
                          <th>회사</th>
                          <th className="account"
                            onClick={(e) => {
                              e.preventDefault();
                              setAccountModalStatus(true);
                            }}
                          >
                            거래처 선택
                            <GuideText />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>사업자등록번호</th>
                          <td>{_company.selfAccountCode}</td>
                          <td>{checkEmptyNull(_account.shipmentBusinessNumber, '')}</td>
                        </tr>
                        <tr>
                          <th>회사명</th>
                          <td>{_company.selfAccountName}</td>
                          <td>{checkEmptyNull(_account.shipmentAccountName, '')}</td>
                        </tr>
                        <tr>
                          <th>주소</th>
                          <td>
                            <input
                              type="text"
                              name="selfAddress"
                              value={_company.selfAddress}
                              onInput={handleCompanyData}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="shipmentAddress"
                              value={checkEmptyNull(_account.shipmentAddress, '')}
                              onInput={handleAccountData}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>전화번호</th>
                          <td>
                            <input
                              type="text"
                              name="selfTelNumber"
                              value={_company.selfTelNumber}
                              onInput={handleCompanyData}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="shipmentTelNumber"
                              value={checkEmptyNull(_account.shipmentTelNumber, '')}
                              onInput={handleAccountData}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>담당자</th>
                          <td>
                            <input
                              type="text"
                              name="selfManagerName"
                              value={_company.selfManagerName}
                              onInput={handleCompanyData}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="shipmentManagerName"
                              value={checkEmptyNull(_account.shipmentManagerName, '')}
                              onInput={handleAccountData}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>계좌번호</th>
                          <td>
                            <input
                              type="text"
                              name="selfBusinessNumber"
                              value={_company.selfBusinessNumber}
                              onInput={handleCompanyData}
                            />
                          </td>
                          <td style={{ backgroundColor: 'var(--gray-100)' }}></td>
                        </tr>
                      </tbody>
                    </AccountTable>
                  </div>

                  <div className="formBox">
                    <h4>출하일자(거래일자)</h4>
                    <input
                      type="date"
                      name="shipmentDate"
                      value={checkEmptyNull(_formData.shipmentDate, '')}
                      onChange={handleInputEvent}
                    />
                  </div>

                  <div className="formBox">
                    <h4>출하총금액</h4>
                    <input
                      type="text"
                      name="shipmentPrice"
                      placeholder="* 출하총금액..."
                      value={checkEmptyNull(_shipmentPrice, 0).toLocaleString()}
                      onInput={handleShipmentPrice}
                    />
                  </div>

                  <div className="formBox">
                    <h4>비고</h4>
                    <textarea
                      name="remark"
                      placeholder="* 비고..."
                      value={_formData.remark}
                      onInput={handleInputEvent}
                    />
                  </div>
                </>
              }
              formSectionStyle={{ paddingTop: '10px', width: 'calc(100% - 210px)' }}
            />
          </ShipmentFormComponent>

          {_accountModalStatus === true && (
            <SelectOneAccount
              buttonTitle={'거래처 선택'}
              buttonEvent={(data) => {
                if (checkNullObject(data, false)) {
                  setAccount((prev) => {
                    const returnData = {
                      ...prev,

                      accountId: data.accountId,

                      shipmentAccountCode: data.accountCode,
                      shipmentAccountName: data.accountName,
                      shipmentBusinessNumber: data.businessNumber,
                      shipmentAddress: data.address,
                      shipmentTelNumber: data.telNumber,
                    };
                    return returnData;
                  });
                }
                setTimeout(setAccountModalStatus(false), 1000);
              }}
              open={_accountModalStatus}
              close={() => { setAccountModalStatus(false) }}
            ></SelectOneAccount>
          )}

          {_modalStatus === true && (
            <SelectElements
              buttonEvent={(data) => {
                console.log('data : ', data);
                const shipmentElementIds = _shipmentElements.map((thisItem) => thisItem.elementId);
                const newElements = data.map((element) => {
                  if (shipmentElementIds.indexOf(element.elementId) !== -1) return null;
                  else {
                    const returnData = { ...element, calPrice: 0 };
                    return returnData;
                  }
                });
                const setNewElements = newElements.filter((thisItem) => thisItem !== null);
                setShipmentElements((prev) => { return [...prev, ...setNewElements] });
                setTimeout(setModalStatus(false), 1000);
              }}
              buttonTitle="물품 선택"
              open={_modalStatus}
              close={() => { setModalStatus(false) }}
            />
          )}
        </>
      }
    />
  );
};

export default CreateShipment;
