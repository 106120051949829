import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {monitoringActions_setHalfFilter } from 'store/modules/actions/common/monitoringActions';

import { elementApi } from 'api/apis/elementApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';

import { SortContent, TableTitle } from 'pages/monitoring/styles';
import TableSection from 'components/layouts/table/TableSection';

const HalfMonitoring = ({total, setTimeCounter}) => {
  const dispatch = useDispatch();
  const {monitoringReducer, userReducer } = useSelector((state) => state);

  const [_halfList, setHalfList] = useState([]);
  const [_halfPage, setHalfPage] = useState({page: 0, size: total ? 5: 10, totalSize: 0});
  const [_halfView, setHalfView] = useState(monitoringReducer.halfFilter);

  useEffect(() => {
    getHalfList(_halfPage.page, _halfView);

    return () => {}
  }, [])
  useEffect(() => {}, [monitoringReducer.halfFilter])

  const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    useEffect(() => {savedCallback.current = callback;}, [callback]);
    useEffect(() => {
      const tick = () => {savedCallback.current();};
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };
  useInterval(async () => {
    setHalfPage((prev) => {
      if (prev.page >= parseFloat(_halfPage.totalSize / _halfPage.size) - 1) {
        getHalfList(0, _halfView);
        return { ...prev, page: 0 };
      } else {
        getHalfList(prev.page + 1, _halfView);
        return { ...prev, page: prev.page + 1 };
      }
    });
    setTimeCounter(() => {return 30});
  }, 30000);

  const getHalfList = async(page, safeStock) => {
    const paging = `?page=${page}&size=${_halfPage.size}&sort=id,DESC`;
    const body={
      companyId: userReducer.company.companyId,
      elementType: 'half'
    }
    if(safeStock === 'safeStock') body.lessThanSafeStock = true;
    await elementApi.searchElementStockList(paging, body).then(response => {
      if(response === undefined) return;
      console.log('elementApi.searchElementStockList : ', response);
      setHalfList(() => {return response.data.content})
      setHalfPage((prev) => {return {...prev, totalSize: response.data.totalElements}})
    })
  }

  const handleSorting = (option) => {
    setHalfPage((prev) => {return{...prev, page: 0}});
    setHalfView(() => {
      dispatch(monitoringActions_setHalfFilter(option));
      getHalfList(0, option); 
      return option;
    })
  }

  return (
    <TableSection content={
      <table>
        <colgroup>
          <col />
          <col />
          {!total ? (<><col /><col /><col /><col /></>) : null}
          <col width={'140px'} />
        </colgroup>
        <thead>
          <tr>
            <TableTitle colSpan={total ? 3 : 7}>
              <div className='TableTitleContent element'>
                <p>반제품 재고현황</p>
                <SortContent>
                  <div className={monitoringReducer.halfFilter === 'all' ? 'active' : ''}
                    onClick={(e) => {e.preventDefault(); handleSorting('all');}}
                  >
                    전체보기
                  </div>
                  <div className={monitoringReducer.halfFilter === 'safeStock' ? 'active' : ''}
                    onClick={(e) => {e.preventDefault(); handleSorting('safeStock');}}
                  >
                    안전재고
                  </div>
                </SortContent>
              </div>
            </TableTitle>
          </tr>
          <tr>
            <th>물품이름</th>
            <th>물품코드</th>
            {!total ? (
              <>
                <th>당일입고</th>
                <th>당일투입</th>
                <th>당일출고</th>
                <th>안전재고</th>
              </>
            ) : null}
            <th>현재고</th>
          </tr>
        </thead>
        <tbody>
          {_halfList.map((element, index) => {
            let safeStockStatus = '';
            if((checkEmptyNull(element.elementStock, 0) * 1) < (checkEmptyNull(element.elementSafeStock, 0) * 1)) safeStockStatus = 'dangerStock';
            else safeStockStatus = 'safeStock';
            return (
              <tr key={index + '_halfs'}>
                <td>{element.elementName}</td>
                <td>{element.elementCode}</td>
                {!total ? (
                  <>
                    <td>{(checkEmptyNull(element.todayInStock, 0) * 1).toLocaleString()}{element.elementUnit}</td>
                    <td>{(checkEmptyNull(element.todayPutStock, 0) * 1).toLocaleString()}{element.elementUnit}</td>
                    <td>{(checkEmptyNull(element.todayOutStock, 0) * 1).toLocaleString()}{element.elementUnit}</td>
                    <td>{(checkEmptyNull(element.elementSafeStock, 0) * 1).toLocaleString()}</td>
                  </>
                ) : null}
                <td className={safeStockStatus}>{(checkEmptyNull(element.elementStock, 0) * 1).toLocaleString()}{element.elementUnit}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    } />
  );
};

export default HalfMonitoring;