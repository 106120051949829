import React, { Fragment, useEffect, useState } from 'react';

import { checkEmptyNull, checkNullObject } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import StatusTdButton from 'components/buttons/StatusTdButton';

const SelectProcurementRow = (props) => {
  /* ========================================================================= #1 */
  /* ========================================================================= #2 */
  const [procurementData, setprocurementData] = useState(props.content);

  const [checkedItem, setCheckedItem] = useState(props.checkedItem);

  const [procurementElements, setProcurementElements] = useState(props.content.procurementElementList);
  const [_rowLength, setRowLength] = useState(procurementElements.length);

  /* ========================================================================= #3 */
  useEffect(() => {
    setprocurementData(() => { return props.content; });

    setCheckedItem(() => { return props.checkedItem; });

    setProcurementElements(() => { return props.content.procurementElementList; });
    setRowLength(props.content.procurementElementList.length);
  }, [props]);

  /* ========================================================================= #4 */
  /* ========================================================================= #5 */
  /* ========================================================================= #6 */
  const returnElements = () => {
    let returnArray = [];
    for (let i = 1; i < _rowLength; i++) {
      returnArray.push(
        <tr key={i + '_procurementElements'}>
          {procurementElements[i] !== undefined ? (
            <Fragment>
              <td style={{ borderLeft: '1px solid var(--gray-200)' }}>{checkNullObject(procurementElements[i].element, false) ? checkEmptyNull(procurementElements[i].element.elementName, '') : checkEmptyNull(procurementElements[i].elementName, '')}</td>
              <td>{checkNullObject(procurementElements[i].element, false) ? checkEmptyNull(procurementElements[i].element.elementCode, '') : checkEmptyNull(procurementElements[i].elementCode, '')}</td>
              <td>{checkNullObject(procurementElements[i].element, false) ? checkEmptyNull(procurementElements[i].element.scheduledInputStock, 0).toLocaleString() : checkEmptyNull(procurementElements[i].scheduledInputStock, 0).toLocaleString()}</td>
              <td
                className={(() => {
                  const elementSafeStock = checkNullObject(procurementElements[i].element, false) ? checkEmptyNull(procurementElements[i].element.elementSafeStock, 0) : checkEmptyNull(procurementElements[i].elementSafeStock, 0);
                  const elementStock = checkNullObject(procurementElements[i].element, false) ? checkEmptyNull(procurementElements[i].element.elementStock, 0) : checkEmptyNull(procurementElements[i].elementStock, 0);
                  if (elementSafeStock <= elementStock) return 'safeStock';
                  else return 'dangerStock';
                })()}
              >
                {checkNullObject(procurementElements[i].element, false) ? checkEmptyNull(procurementElements[i].element.elementStock, 0) : checkEmptyNull(procurementElements[i].elementStock, 0).toLocaleString()}
              </td>
              <td>{checkNullObject(procurementElements[i].element, false) ? checkEmptyNull(procurementElements[i].element.price, 0) : checkEmptyNull(procurementElements[i].price, 0).toLocaleString()}</td>
              <td>{checkEmptyNull(procurementElements[i].quantity, 0).toLocaleString()}</td>
              <td style={{ borderRight: '1px solid var(--gray-200)' }}>{checkEmptyNull(procurementElements[i].price, 0).toLocaleString()}</td>
            </Fragment>
          ) : (
            <Fragment>
              <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
              <td className="blankCell" style={{ borderRight: '1px solid var(--gray-200)' }}></td>
            </Fragment>
          )}
        </tr>
      );
    }

    return returnArray;
  };

  return (
    <Fragment>
      <tr>
        <StatusTdButton
          rowSpan={_rowLength === 0 ? 1 : _rowLength}
          statusStyle={{ cursor: 'default', minWidth: '100px', width: '100px' }}
          status={procurementData.procurementStatus}
        />
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ minWidth: '50px', width: '50px' }}>
          <input
            type="checkBox"
            name="procurements"
            data-key={procurementData.procurementId}
            checked={(() => {
              const checkedId = checkedItem ? checkedItem.findIndex((thisIndex) => thisIndex.procurementId === procurementData.procurementId) : -1;
              if (checkedId !== -1) return true;
              else return false;
            })()}
            onChange={(e) => {
              const isChecked = e.target.checked;
              props.handleCheckedItem(procurementData, isChecked);
            }}
            onClick={(e) => { e.stopPropagation(); }}
          />
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{DateFormat(procurementData.createdDate)}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{DateFormat(procurementData.scheduledEndDate)}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{procurementData.procurementCode}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{procurementData.procurementName}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{procurementData?.account?.accountName}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{checkEmptyNull(procurementData.procurementPrice, 0).toLocaleString()}</td>

        {procurementElements[0] !== undefined ? (
          <Fragment>
            <td style={{ borderLeft: '1px solid var(--gray-200)' }}>{checkNullObject(procurementElements[0].element, false) ? checkEmptyNull(procurementElements[0].element.elementName, '') : checkEmptyNull(procurementElements[0].elementName, '')}</td>
            <td>{checkNullObject(procurementElements[0].element, false) ? checkEmptyNull(procurementElements[0].element.elementCode, '') : checkEmptyNull(procurementElements[0].elementCode, '')}</td>
            <td>{checkNullObject(procurementElements[0].element, false) ? checkEmptyNull(procurementElements[0].element.scheduledInputStock, 0).toLocaleString() : checkEmptyNull(procurementElements[0].scheduledInputStock, 0).toLocaleString()}</td>
            {/* <td
              className={(() => {
                const elementSafeStock = checkNullObject(procurementElements[0].element, false) ? checkEmptyNull(procurementElements[0].element.elementSafeStock, 0) : checkEmptyNull(procurementElements[0].elementSafeStock, 0);
                const elementStock = checkNullObject(procurementElements[0].element, false) ? checkEmptyNull(procurementElements[0].element.elementStock, 0) : checkEmptyNull(procurementElements[0].elementStock, 0);
                if (elementSafeStock <= elementStock) return 'safeStock';
                else return 'dangerStock';
              })()}
            >
              {checkNullObject(procurementElements[0].element, false) ? checkEmptyNull(procurementElements[0].element.elementStock, 0) : checkEmptyNull(procurementElements[0].elementStock, 0).toLocaleString()}
            </td> */}
            <td>{checkNullObject(procurementElements[0].element, false) ? checkEmptyNull(procurementElements[0].element.price, 0) : checkEmptyNull(procurementElements[0].price, 0).toLocaleString()}</td>
            <td>{checkEmptyNull(procurementElements[0].quantity, 0).toLocaleString()}</td>
            <td style={{ borderRight: '1px solid var(--gray-200)' }}>{checkEmptyNull(procurementElements[0].price, 0).toLocaleString()}</td>
          </Fragment>
        ) : (
          <Fragment>
            <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
            <td className="blankCell" style={{ borderRight: '1px solid var(--gray-200)' }}></td>
          </Fragment>
        )}
      </tr>
      {_rowLength > 1 && returnElements()}
    </Fragment>
  );
};

export default SelectProcurementRow;
