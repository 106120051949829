export const shipmentActions_setShipmentStatus = (status) => {return {type: 'SETSHIPMENTSTATUS', payload: status};};
export const shipmentActions_setStartDate = (startDate) => {return {type: 'SETSHIPMENTSTARTDATE', payload: startDate};};
export const shipmentActions_setEndDate = (endDate) => {return {type: 'SETSHIPMENTENDDATE', payload: endDate};};
export const shipmentActions_setSearchOption = (searchOption) => {return {type: 'SETSHIPMENTSEARCHOPTION', payload: searchOption};};
export const shipmentActions_setSearchText = (searchText) => {return {type: 'SETSHIPMENTSEARCHTEXT', payload: searchText};};
export const shipmentActions_setSearchData = (searchData) => {return {type: 'SETSHIPMENTSEARCHDATA', payload: searchData};};
export const shipmentActions_setPageNumber = (pageNumber) => {return {type: 'SETSHIPMENTPAGENUMBER', payload: pageNumber};};
export const shipmentActions_setTotalSize = (totalSize) => {return {type: 'SETSHIPMENTTOTALSIZE', payload: totalSize};};
export const shipmentActions_setIsPushedSearchButton = (isPushedSearchButton) => {return {type: 'SETSHIPMENTISPUSHEDSEARCHBUTTON', payload: isPushedSearchButton};};

export const shipmentActions_setViewScale = (viewScale) => {return {type: 'SETSHIPMENTVIEWSCALE', payload: viewScale};};

export const shipmentActions_setReset = () => {return {type: 'SETSHIPMENTRESET'};};
