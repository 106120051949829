export const mallSiteCartActions_setMallAddCart = (cart) => {
  return {
    type: 'SETMALLSITEADDCART',
    payload: cart,
  };
};

export const mallSiteCartActions_setMallClearStatus = (status) => {
  return {
    type: 'SETMALLSITECLEARSTATUS',
    payload: status,
  };
};

export const mallSiteCartActions_setMallResetCart = () => {
  return {
    type: 'SETMALLSITERESETCART',
  };
};
