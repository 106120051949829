import { useEffect } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import { getCookie } from 'preferences/cookie/cookie';

import { guestMallRoutes, guestPlatformClusterRoutes, guestPlatformOperatingRoutes, guestRoutes } from './routes/guest';
import { userRoutes } from './routes/user';
import { operaterRoutes } from './routes/operater';
import { mallRouters } from './routes/mall';
import { clusterRouters } from './routes/platform/cluster';
import { operatingRouters } from './routes/platform/operating';

const RouteList = () => {
  const location = useLocation();

  useEffect(() => {
    let check;
    if (getCookie('accessToken') !== undefined) {
      if (location.pathname.includes('/mall')) check = '>>> mall';
      else if (location.pathname.includes('/platform/cluster')) check = '>>> platform/cluster';
      else if (location.pathname.includes('/platform/operating')) check = '>>> platform/operating';
      else check = '>>> user';
    } else {
      if (location.pathname.includes('/mall')) check = '>>> guest-mall';
      else if (location.pathname.includes('/platform/cluster')) check = '>>> guest-platform/cluster';
      else if (location.pathname.includes('/platform/operating')) check = '>>> guest-platform/operating';
      else check = '>>> guest';
    }
    console.log('check : ', check);
  }, [location.pathname]);


  let routes;
  if (getCookie('accessToken') === undefined) { // guest
    if (location.pathname.includes('/mall')) routes = guestMallRoutes;
    else if (location.pathname.includes('/platform/cluster')) routes = guestPlatformClusterRoutes;
    else if (location.pathname.includes('/platform/operating')) routes = guestPlatformOperatingRoutes;
    else routes = guestRoutes;
  } else { // user
    if (location.pathname.includes('/mall')) routes = mallRouters;
    else if (location.pathname.includes('/platform/cluster')) routes = clusterRouters;
    else if (location.pathname.includes('/platform/operating')) routes = operatingRouters;
    else routes = [...userRoutes, ...operaterRoutes];
  }
  useEffect(() => { }, [routes]);

  return useRoutes([...routes]);
};

export default RouteList;