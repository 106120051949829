export const costActions_setWorkOrderType = (workOrderType) => {return {type: 'SETCOSTWORKORDERTYPE', payload: workOrderType};};
export const costActions_setWorkOrderStatus = (workOrderStatus) => {return {type: 'SETCOSTWORKORDERSTATUS', payload: workOrderStatus};};
export const costActions_setStartDate = (startDate) => {return {type: 'SETCOSTSTARTDATE', payload: startDate};};
export const costActions_setEndDate = (endDate) => {return {type: 'SETCOSTENDDATE', payload: endDate};};
export const costActions_setSearchOption = (searchData) => {return {type: 'SETCOSTSEARCHOPTION', payload: searchData};};
export const costActions_setSearchText = (searchData) => {return {type: 'SETCOSTSEARCHTEXT', payload: searchData};};
export const costActions_setSearchData = (searchData) => {return {type: 'SETCOSTSEARCHDATA', payload: searchData};};
export const costActions_setPageNumber = (pageNumber) => {return {type: 'SETCOSTPAGENUMBER', payload: pageNumber};};
export const costActions_setTotalSize = (totalSize) => {return {type: 'SETCOSTTOTALSIZE', payload: totalSize};};
export const costActions_setIsPushedSearchButton = (isPushedSearchButton) => {return {type: 'SETCOSTISPUSHEDSEARCHBUTTON', payload: isPushedSearchButton};};
export const costActions_setReset = () => {return {type: 'SETCOSTRESET'};};