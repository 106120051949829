const initialState = {
  searchData: {},
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,

  viewScale: 100,
};
const resetState = {
  searchData: {},
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,

  viewScale: 100,
};

const userManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETUSERMANAGEMENTSEARCHDATA':
      return {
        ...state,
        searchData: action.payload,
      };
    case 'SETUSERMANAGEMENTSEARCHOPTION':
      return {
        ...state,
        searchOption: action.payload,
      };
    case 'SETUSERMANAGEMENTSEARCHTEXT':
      return {
        ...state,
        searchText: action.payload,
      };
    case 'SETUSERMANAGEMENTPAGENUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'SETUSERMANAGEMENTTOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };
    case 'SETUSERMANAGEMENTISPUSHEDSEARCHBUTTON':
      return {
        ...state,
        isPushedSearchButton: action.payload,
      };
    case 'SETUSERMANAGEMENTRESET':
      return {
        ...state,
        ...resetState,
      };
    case 'SETUSERMANAGEMENTVIEWSCALE':
      return {
        ...state,
        viewScale: action.payload,
      };

    default:
      return state;
  }
};

export default userManagementReducer;
