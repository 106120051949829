import styled from 'styled-components';

export const Content = styled.div`
  background-color: var(--white);
  box-sizing: border-box;
  border-radius: 8px;
  display: grid;
  grid-template-rows: 60px auto;
  width: 100%; height: 100%;
`;
export const ContentHeader = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  padding: 24px 16px;
`;
export const Title = styled.h1`
  font-size: 20px;
`;
export const SearchBar = styled.div`
  align-items: center;
  border-radius: 10px;
  display: flex; gap: 8px;
  input {
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    padding: 4px 16px;
    border-radius: 4px;
    width: 100%;
    font-size: 1rem;
  }
`;
export const ButtonsWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  height: 100%;
`;
