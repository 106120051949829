import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat';

import { sensorDataApi } from 'api/apis/sensorDataApi';

import Grid2Body from 'components/layouts/body/Grid2Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const SensorDataSection = styled.main`
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 66px calc(100% - 66px);

  & .Title {
    display: flex;
    gap: 10px;
    align-items: center;

    h4 {
      padding-right: 20px;
      margin-right: 10px;
      border-right: 1px solid #ddd;
    }

    & .contentTypeButtons {
      display: flex;
      gap: 5px;
    }
  }
`;
const DataSection = styled.div`
  height: 100%;
  width: 100%;
`;

const MonitoringTable = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
  
  & table {
    border-collapse: collapse;
    height: 100%;
    width: 100%;

    th, td {
      border: 10px solid var(--MainNavy);
    }
    th {
      background-color: var(--MainNavy);
      color: var(--white);
      font-size: 2.2em;
      font-weight: 400;
      line-height: 1.2em;
      padding-block: 20px;
    }
    td {
      font-size: 6em;
      font-weight: 600;
      text-align: center;
    }
  }
`;
const SearchTable = styled.div`
  display: grid;
  grid-template-rows: calc(100% - 60px) 60px;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

const UriSensorData = () => {
  const [_onload, setOnload] = useState('unload');
  const [_contentType, setContentType] = useState('all');

  const [_startDate, setStartDate] = useState(moment().add('-1', 'm').format('YYYY-MM-DD hh:mm:ss'));
  const [_endDate, setEndDate] = useState(moment().format('YYYY-MM-DD hh:mm:ss'));
  const [_name, setName] = useState('');

  const [_cuttingCount, setCuttingCount] = useState(0);
  const [_moCount, setMoCount] = useState(0);

  const [_logList, setLogList] = useState([]);

  const [_pageNumber, setPageNumber] = useState(1);
  const [_totalSize, setTotalSize] = useState(0);

  useEffect(() => {
    getSensorData(_pageNumber - 1, _contentType);

    setOnload('loaded');
    return () => { }
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getSensorData(_pageNumber - 1, _contentType);
    }

    return () => { }
  }, [_pageNumber]);

  const getSensorData = async (page, contentType) => {
    const paging = `?page=${page}&size=20&sort=id,DESC`;
    const BodyToPost = {
      companyId: 2,
      startDate: moment().add('-1', 'm').add(9, 'hour').toISOString(),
      endDate: moment().add(9, 'hour').toISOString(),
      name: '',
    }
    if (checkEmptyNull(_startDate, false)) BodyToPost.startDate = moment(_startDate).add(9, 'hour').toISOString();
    if (checkEmptyNull(_endDate, false)) BodyToPost.endDate = moment(_endDate).add(9, 'hour').toISOString();
    // if (checkEmptyNull(_name, false)) BodyToPost.name = _name;
    if (checkEmptyNull(contentType, false) && contentType !== 'all') {
      BodyToPost.name = contentType;
    }
    console.log(paging, BodyToPost);
    await sensorDataApi.uriSensorData(paging, BodyToPost).then(response => {
      if (response === undefined) return;
      console.log('sensorDataApi.uriSensorData : ', response);
      setLogList(() => { return response.data.content });
      setTotalSize(() => { return response.data.totalElements });;
    })
  }

  const handleContentType = (contentType) => {
    setContentType(() => { return contentType });
    setPageNumber(() => { return 1 });
    getSensorData(0, contentType);
  }

  const actSearch = () => {
    setPageNumber(() => { return 1 });
    getSensorData(0);
  }
  const actReset = () => {
    setStartDate(() => { return '' })
    setEndDate(() => { return '' })
    setName(() => { return '' })

    setPageNumber(() => { return 1 });
    getSensorData(0);
  }

  return (
    <Grid2Body contents={
      <>
        <SensorDataSection className='Main'>
          <NavBar
            title={
              <>
                <div className='Title'>
                  <NavTitle menuCode={'702'} />
                  <div className='contentTypeButtons'>
                    <button className={_contentType === 'all' ? 'btn-inven-1' : 'btn-set'} onClick={() => { handleContentType('all') }}>전체</button>
                    <button className={_contentType === 'cuttingCount' ? 'btn-inven-1' : 'btn-set'} onClick={() => { handleContentType('cuttingCount') }}>절단</button>
                    <button className={_contentType === 'moCount' ? 'btn-inven-1' : 'btn-set'} onClick={() => { handleContentType('moCount') }}>체결</button>
                  </div>
                </div>
              </>
            }
            buttons={
              <>
                <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <div className="SearchSection">
                    <input
                      type="datetime-local"
                      className="SelectDate"
                      value={checkEmptyNull(_startDate, false) ? DateTimeFormat(checkEmptyNull(_startDate, '')) : ''}
                      onChange={(e) => { e.preventDefault(); setStartDate(() => { return e.target.value }) }}
                    />
                    <IconRightArrow />
                    <input
                      type="datetime-local"
                      className="SelectDate"
                      value={checkEmptyNull(_endDate, false) ? DateTimeFormat(checkEmptyNull(_endDate, '')) : ''}
                      onChange={(e) => { e.preventDefault(); setEndDate(() => { return e.target.value }) }}
                    />
                  </div>
                  <div className="ContentCBox">
                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton" onClick={actReset}>초기화</div>
                  </div>
                </div>
              </>
            }
            nav={''}
          />

          <DataSection>
            <SearchTable>
              <TableSection content={
                <table>
                  <thead>
                    <tr>
                      <th>작업 시각</th>
                      {(_contentType === 'all' || _contentType === 'cuttingCount') ? <th>절단수량</th> : null}
                      {(_contentType === 'all' || _contentType === 'moCount') ? <th>체결수량</th> : null}
                    </tr>
                  </thead>
                  <tbody>
                    {checkNullArray(_logList, []).map((logData, index) => {
                      const date = moment(logData.date).format('YYYY-MM-DD HH:mm:ss');
                      // const date = moment(logData.date).subtract(9, 'hour').format('YYYY-MM-DD HH:mm:ss');
                      return (
                        <tr key={index + '_logData'}>
                          <td>{date}</td>
                          {(_contentType === 'all' || _contentType === 'cuttingCount') ? <td>{logData.name === 'cuttingCount' ? checkEmptyNull(logData.value, 0).toLocaleString() : null}</td> : null}
                          {(_contentType === 'all' || _contentType === 'moCount') ? <td>{logData.name === 'moCount' ? checkEmptyNull(logData.value, 0).toLocaleString() : null}</td> : null}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              } />

              <PagingComponent
                page={_pageNumber}
                count={_totalSize}
                size={20}
                pageEvent={(page) => {
                  console.log('page : ', page);
                  setPageNumber(() => { return page })
                }}
              />
            </SearchTable>
            {/* {_contentType === 'monitoring' ?
              <MonitoringTable>
                <table>
                  <colgroup>
                    <col width={'160px'}></col>
                    <col></col>
                    <col></col>
                  </colgroup>
                  <thead>
                    <tr>
                      <th></th>
                      <th>절단</th>
                      <th>체결</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>작업<br />완료<br />수량</th>
                      <td>{checkEmptyNull(_cuttingCount, 0)}</td>
                      <td>{checkEmptyNull(_moCount, 0)}</td>
                    </tr>
                  </tbody>
                </table>
              </MonitoringTable>
              : } */}
          </DataSection>
        </SensorDataSection>
      </>
    } />
  );
};

export default UriSensorData;