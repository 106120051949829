import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { userManagementActions_setPageNumber, userManagementActions_setReset, userManagementActions_setSearchData, userManagementActions_setTotalSize } from 'store/modules/actions/common/userManagementActions';

import { userApi } from 'api/apis/userApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { LoadingMsg } from 'components/loading/LoadingMsg';
import { UserManagementComponent } from './UserManagement.style';

import Grid3Body from 'components/layouts/body/Grid3Body';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';
import UserModal from './modal/UserModal';

const UserManagement = () => {
  const dispatch = useDispatch();
  const { userReducer, userManagementReducer } = useSelector((state) => state);

  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_userList, setUserList] = useState([]);
  const [_user, setUser] = useState({});

  const [_modalStatus, setModalStatus] = useState(false);
  const [_modalType, setModalType] = useState();
  const [_modalData, setModalData] = useState({});

  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((teamRole) => teamRole.authority.startsWith('901') === true);
    const authorityCodes = authorityContent.map((authority) => authority.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getUserList(userManagementReducer.pageNumber - 1);

    setOnload('loaded');
    return () => {}
  }, []);
  useEffect(() => {}, [_userList, _user, userManagementReducer.pageNumber])

  const getUserList = async(page, clear) => { // 사용자 리스트 불러오기
    const paging = `?page=${page}&size=20`;
    const body = {companyId: userReducer.company.companyId};
    if(!clear) {
      console.log('userManagementReducer.searchData : ', userManagementReducer.searchData);
      if (checkEmptyNull(userManagementReducer.searchData?.teamName, false)) {
        body.teamName = userManagementReducer.searchData.teamName;
      }
      if (checkEmptyNull(userManagementReducer.searchData?.name, false)) {
        body.name = userManagementReducer.searchData.name;
      }
    }

    await userApi.searchUser(paging, body).then((response) => {
      if (response === undefined) return;
      setUserList(() => { return response.data.content });
      dispatch(userManagementActions_setTotalSize(response.data.totalElements));
    });
  };

  const actSearch = async (e) => { // 검색
    dispatch(userManagementActions_setPageNumber(1));
    await getUserList(0);
  };

  const actReset = async (e) => { // 초기화
    dispatch(userManagementActions_setReset());
    setUser(() => {return {}});
    await getUserList(0, true);
  };

  const handleModalStatus = (type, userData) => { // 작업자 추가, 사용자 수정/삭제, 사용자 리스트 다운로드 
    setModalType(() => {return type});
    setModalData(() => {return userData});
    
    setTimeout(() => {
      setModalStatus(() => {return true});
    }, 1000);
  }

  return (
    <>
    <Grid3Body contents={
      <>
      <UserManagementComponent className='Main'>
        <NavBar
          title={
            <div className="SearchSection">
              <div className="ContentCBox">
                <select className="SearchOption detail"><option value="teamName">팀</option></select>
                <input
                  className="SearchBar detail"
                  placeholder="팀 이름..."
                  value={checkEmptyNull(userManagementReducer.searchData?.teamName, '')}
                  onInput={(e) => {
                    if(e.keyCode === 13 || e.key === 'Enter') return;
                    const teamName = e.target.value;
                    dispatch(userManagementActions_setSearchData({ ...userManagementReducer.searchData, teamName: teamName }))
                  }}
                  onKeyUp={(e) => {if(e.keyCode === 13 || e.key === 'Enter') actSearch();}}
                />
              </div>

              <div className="ContentCBox">
                <select className="SearchOption detail"><option value="name">이름</option></select>
                <input
                  className="SearchBar detail"
                  placeholder="사용자 이름..."
                  value={checkEmptyNull(userManagementReducer.searchData?.name, '')}
                  onInput={(e) => {
                    if(e.keyCode === 13 || e.key === 'Enter') return;
                    const name = e.target.value;
                    dispatch(userManagementActions_setSearchData({ ...userManagementReducer.searchData, name: name }))
                  }}
                  onKeyUp={(e) => {if(e.keyCode === 13 || e.key === 'Enter') actSearch();}}
                />
              </div>

              <div className="ContentCBox">
                <div className="DetailButton search" onClick={actSearch}><SearchButtonIcon />검색</div>
                <div className="ResetButton detail" onClick={actReset}>초기화</div>
              </div>
            </div>
          }
          buttons={
            <>
            {_authority.indexOf('901-2') !== -1 ? (
              <>
              {[1, 12].includes(userReducer.company.companyId) ? (
                <button className='btn-add' 
                  onClick={(e) => {
                    e.preventDefault();
                    handleModalStatus('workerCreate', {companyId: userReducer.company.companyId})
                  }}
                >작업자 추가</button>
              ) : null}
              </>
            ) : null}
            <button className='btn-download' 
              onClick={(e) => {
                e.preventDefault();
                handleModalStatus('download');
              }}
            >다운로드</button>
            </>
          }
          nav={''}
        />

        <TableSection content={
          <table>
            <thead>
              <tr>
                <th>팀 정보</th>
                <th>이름</th>
                <th>이메일</th>
                <th>연락처</th>
                <th>메모</th>
                <th>아이디</th>
                {_authority.indexOf('901-2') !== -1 ? (<th style={{ minWidth: '70px', width: '70px' }}>설정</th>) : null}
              </tr>
            </thead>
            <tbody>
              {!checkNullArray(_userList, false) ? LoadingMsg() :
                checkNullArray(_userList, []).map((userData, index) => {
                  return (
                    <tr key={index + '_user'}>
                      <td>{userData.teamName}</td>
                      <td>
                        {!checkEmptyNull(userData.email, false) ? (<span>작업자</span>) : null}
                        {userData.name}
                      </td>
                      <td>{checkEmptyNull(userData.email, '-')}</td>
                      <td>{checkEmptyNull(userData.phoneNumber, '')}</td>
                      <td>{checkEmptyNull(userData.memo, '')}</td>
                      <td>{userData.loginId}</td>
                      {_authority.indexOf('901-2') !== -1 ? (
                        <td className='act-cell' style={{ minWidth: '70px', width: '70px' }}>
                          <button className='btn-set' title={userData.name + ' 설정'}
                            onClick={(e) => {
                              e.preventDefault();
                              if(_user?.id === userData.id) setUser(() => {return {}});
                              else setUser(() => {return userData});
                            }}
                          >&#8942;</button>
                          {_user?.id === userData.id ? (
                            <div className='act-content'>
                              <div onClick={() => {handleModalStatus('team', userData)}}>팀 변경</div>
                              <div onClick={() => {handleModalStatus(!checkEmptyNull(userData.email, false) ? 'workerUpdate' : 'update', userData)}}>수정</div>
                              <div onClick={() => {handleModalStatus(!checkEmptyNull(userData.email, false) ? 'workerDelete' : 'delete', userData)}}>삭제</div>
                            </div>
                          ) : null}
                        </td>
                      ) : null}
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        } />
      </UserManagementComponent>

      <PagingComponent
        page={userManagementReducer.pageNumber}
        count={userManagementReducer.totalSize}
        size={20}
        pageEvent={async(page) => {
          console.log('page : ', page);
          dispatch(userManagementActions_setPageNumber(page));
          await getUserList(page - 1);
        }}
      />
      </>
    } />
    {_modalStatus ? (
      <UserModal
        open={_modalStatus}
        modalType={_modalType}
        modalData={_modalData}
        close={(update) => {
          setModalStatus(() => {return false});
          setUser(() => {return {}});
          if(update === 'update') {
            console.log('update');
            getUserList(userManagementReducer.pageNumber - 1);
          }
        }}
      />
    ) : null}
    </>
  );
};

export default UserManagement;