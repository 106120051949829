import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';

import { gsReducer_setProcurementContent } from 'store/modules/actions/company/GSActions';
import {
  modalReducer_setCurrentPageNum,
  modalReducer_setPageSize,
  modalReducer_setSearchOption,
  modalReducer_setSearchText,
  modalReducer_setTotalCount,
} from 'store/modules/actions/default/modalActions';
import {
  pageReducer_setModalPageNum,
  pageReducer_setModalPageSize,
  pageReducer_setModalTotalCount,
} from 'store/modules/actions/default/pageActions';

import {
  ACCESS_TOKEN,
  AXIOS_BASE_HEADERS,
  AXIOS_BASE_URL,
} from 'preferences/server/constants';

import CheckBox from 'components/checkbox/CheckBox';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import CommaNum from 'components/format/CommaNum';
import Paging from 'components/paging/Paging';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 40px 50px;
  height: 85%;
  /* max-height: 300px; */
  /* max-width: 400px; */
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  /* height: calc(100% - 55px); */
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
    /* zoom: 67%; */
  }
`;

const NavSection = styled.nav`
  align-items: center;
  background-color: var(--white);
  box-sizing: border-box;
  column-gap: 10px;
  display: flex;
  height: 60px;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0px 15px;
  width: 100%;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 4px;
  }
`;

const SearchBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

const SearchOption = styled.select`
  border: 1px solid var(--gray-200);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-sizing: border-box;
  height: 35px;
  padding: 2px 10px;
  width: 100px;
`;

const SearchBar = styled.input`
  border: 1px solid var(--gray-200);
  border-left: unset;
  /* border-right: unset; */
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-sizing: border-box;
  height: 35px;
  margin-right: 5px;
  padding: 5px;
  width: 250px;

  &:focus {
    border: 1px solid var(--gray-200);

    border-left: unset;
    border-right: unset;
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const Navigation = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { modalReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */

  /* ====================================================================== #3 */

  /* ====================================================================== #4 */

  /* ====================================================================== #5 */
  /* 검색옵션 선택 */
  const actSelectSearchOption = (e) => {
    dispatch(modalReducer_setSearchOption(e.target.value));
  };

  /* 검색바 입력 */
  const actInputSearchText = (e) => {
    dispatch(modalReducer_setSearchText(e.target.value));
  };

  /* ====================================================================== #6 */

  return (
    <NavSection>
      {/* 텍스트 */}
      <SearchBox>
        {/* 검색 옵션 */}
        <SearchOption
          id="Search_Option"
          value={modalReducer.searchOption || ''}
          onChange={actSelectSearchOption}
        >
          <option value="" disabled>
            검색옵션
          </option>
          <option value="procurementName">발주명</option>
          <option value="procurementCode">발주코드</option>
        </SearchOption>

        {/* 검색 바 */}
        <SearchBar
          type="text"
          value={modalReducer.searchText || ''}
          placeholder="Search..."
          onInput={actInputSearchText}
        ></SearchBar>

        {/* 검색 버튼 */}
        <button id="searchButton" onClick={props.searchEvent}>
          <SearchButtonIcon />
        </button>
      </SearchBox>
    </NavSection>
  );
};

const GSProcurementModal = (props) => {
  /* ====================================================================== #1 */
  const { pageReducer, modalReducer, stockReducer, userReducer } = useSelector(
    (state) => state,
  );
  const dispatch = useDispatch();

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  let rowCount = 0;
  const [_procurementList, setProcurementList] = useState([]);

  /* ====================================================================== #3 */
  useEffect(() => {
    dispatch(modalReducer_setCurrentPageNum(1));
    dispatch(modalReducer_setPageSize(10));

    setProcurementTable();

    setOnload('loaded');

    return () => {};
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      setProcurementTable();
    }

    return () => {};
  }, [modalReducer.currentPageNum, modalReducer.totalCount]);

  /* ====================================================================== #4 */
  const setDateFormat = (thisDate) => {
    // console.log('setDateFormat : ', thisDate)
    if (
      thisDate !== undefined &&
      thisDate !== null &&
      typeof thisDate === 'object'
    ) {
      const newtDate = new Date(thisDate.join('-'));
      return moment(newtDate).format('YYYY-MM-DD');
    } else {
      return thisDate;
    }
  };

  const setProcurementTable = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    if (modalReducer.searchOption !== '')
      BodyToPost[modalReducer.searchOption] = modalReducer.searchText;
    console.log('setProcurementTable-body : ', BodyToPost);

    const paging = `?page=${modalReducer.currentPageNum - 1}&size=${
      modalReducer.pageSize
    }&sort=id,DESC`;
    console.log(paging, modalReducer.currentPageNum);

    await axios
      .post(
        AXIOS_BASE_URL + '/procurement/search' + paging,
        BodyToPost,
        AXIOS_BASE_HEADERS,
      )
      .then((response) => {
        if (response === undefined) return;
        console.log('/procurement/search - response : ', response);
        Finish_setProcurementTable(response.data);

        dispatch(modalReducer_setTotalCount(response.data.totalElements));
      })
      .catch((error) => {
        console.log('/procurement/search - error : ', error);
      });
  };
  const Finish_setProcurementTable = (Result) => {
    const resultList = Result.content;
    console.log('post/procurement/search : ', Result);
    console.log('post/procurement/search - content : ', resultList);

    setProcurementList(resultList);
  };

  /* ====================================================================== #5 */
  const actSearch = async () => {
    dispatch(pageReducer_setModalPageNum(1));
    dispatch(pageReducer_setModalPageSize(10));

    await setProcurementTable();
  };

  const actSelect = async () => {
    // gsReducer_setProcurementContent
    if (
      document.querySelectorAll('input[name="Procurement"]:checked').length < 1
    )
      return alert('발주서를 선택해주세요.');

    const CheckedBox = document.querySelector(
      'input[name="Procurement"]:checked',
    );
    const CheckedRow = CheckedBox.parentNode.parentNode;
    const thisProcurementId = CheckedRow.dataset.key;
    const thisProcurementIndex = _procurementList.findIndex(
      (ThisData) => ThisData.procurementId === Number(thisProcurementId),
    );
    const thisProcurement = _procurementList[thisProcurementIndex];
    console.log('actSelect - thisProcurement : ', thisProcurement);

    dispatch(gsReducer_setProcurementContent(thisProcurement));

    props.close();
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>발주선택</ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              <Navigation
                searchEvent={actSearch}
                StockProductsType={props.StockProductsType}
              />

              <TableSection
                content={
                  <table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '50px', width: '50px' }}></th>
                        <th>거래처</th>
                        <th>발주코드</th>
                        <th>발주명</th>
                        <th>발주총금액</th>
                        <th>발주일자</th>
                        <th>입고예정일자</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_procurementList !== undefined &&
                        _procurementList.length > 0 &&
                        _procurementList.map((thisList) => {
                          rowCount++;

                          let tdAccountName = null;
                          if (
                            thisList.procurementElementList !== undefined &&
                            thisList.procurementElementList !== null &&
                            thisList.procurementElementList.length > 0
                          ) {
                            if (
                              thisList.procurementElementList[0].account !==
                                undefined &&
                              thisList.procurementElementList[0].account !==
                                null
                            ) {
                              if (
                                Object.keys(
                                  thisList.procurementElementList[0].account,
                                ).length > 0
                              ) {
                                tdAccountName =
                                  thisList.procurementElementList[0].account
                                    .accountName;
                              }
                            }
                          }

                          return (
                            <tr
                              key={rowCount + '_발주'}
                              data-key={thisList.procurementId}
                            >
                              <td style={{ minWidth: '50px', width: '50px' }}>
                                <CheckBox
                                  act={'one'}
                                  all={false}
                                  name={'Procurement'}
                                />
                              </td>
                              <td>{tdAccountName}</td>
                              <td>{thisList.procurementCode}</td>
                              <td>{thisList.procurementName}</td>
                              <td>
                                <CommaNum
                                  displayType={'text'}
                                  num={thisList.procurementPrice}
                                />
                              </td>
                              <td>{setDateFormat(thisList.procurementDate)}</td>
                              <td>
                                {setDateFormat(thisList.scheduledEndDate)}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                }
              />
            </ModalMain>

            <Paging
              page={modalReducer.currentPageNum}
              count={modalReducer.totalCount}
              size={modalReducer.pageSize}
              modal={true}
            />

            <ModalFooter>
              <ModalButton onClick={actSelect}>선택</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default GSProcurementModal;
