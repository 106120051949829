const initialState = {
  mallQnAStatus: 'all',

  startDate: undefined,
  endDate: undefined,
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const resetState = {
  mallQnAStatus: 'all',

  startDate: undefined,
  endDate: undefined,
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const mallQnAReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETMALLQNASTATUS':
      return {
        ...state,
        mallQnAStatus: action.payload,
      };
    case 'SETMALLQNASTARTDATE':
      return {
        ...state,
        startDate: action.payload,
      };
    case 'SETMALLQNAENDDATE':
      return {
        ...state,
        endDate: action.payload,
      };
    case 'SETMALLQNASEARCHOPTION':
      return {
        ...state,
        searchOption: action.payload,
      };
    case 'SETMALLQNASEARCHTEXT':
      return {
        ...state,
        searchText: action.payload,
      };
    case 'SETMALLQNAPAGENUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'SETMALLQNATOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };
    case 'SETMALLQNAISPUSHEDSEARCHBUTTON':
      return {
        ...state,
        isPushedSearchButton: action.payload,
      };
    case 'SETMALLQNARESET':
      return {
        ...state,
        ...resetState,
      };

    default:
      return state;
  }
};

export default mallQnAReducer;
