import { AxiosInterceptor } from "api/core/consts";

export const contractApi = {
  getContract: (contractId) => AxiosInterceptor.get('contract/' + contractId),
  searchContract: (paging, body) =>
    AxiosInterceptor.post('contract/search' + paging, body),
  createContract: (body) => AxiosInterceptor.post('contract', body),
  uploadContract: (body) => AxiosInterceptor.post('contracts', body),
  updateContract: (contractId, body) =>
    AxiosInterceptor.put('contract/' + contractId, body),
  deleteContract: (contractId) =>
    AxiosInterceptor.delete('contract/' + contractId),
};
