export const productionStatusActions_setWorkOrderType = (workOrderType) => {
  return {
    type: 'SETPRODUCTIONSTATUSWORKORDERTYPE',
    payload: workOrderType,
  };
};

export const productionStatusActions_setWorkOrderStatus = (workOrderStatus) => {
  return {
    type: 'SETPRODUCTIONSTATUSWORKORDERSTATUS',
    payload: workOrderStatus,
  };
};

export const productionStatusActions_setStartDate = (startDate) => {
  return {
    type: 'SETPRODUCTIONSTATUSSTARTDATE',
    payload: startDate,
  };
};

export const productionStatusActions_setEndDate = (endDate) => {
  return {
    type: 'SETPRODUCTIONSTATUSENDDATE',
    payload: endDate,
  };
};

export const productionStatusActions_setSearchOption = (searchData) => {
  return {
    type: 'SETPRODUCTIONSTATUSSEARCHOPTION',
    payload: searchData,
  };
};
export const productionStatusActions_setSearchText = (searchData) => {
  return {
    type: 'SETPRODUCTIONSTATUSSEARCHTEXT',
    payload: searchData,
  };
};
export const productionStatusActions_setSearchData = (searchData) => {
  return {
    type: 'SETPRODUCTIONSTATUSSEARCHDATA',
    payload: searchData,
  };
};

export const productionStatusActions_setPageNumber = (pageNumber) => {
  return {
    type: 'SETPRODUCTIONSTATUSPAGENUMBER',
    payload: pageNumber,
  };
};

export const productionStatusActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETPRODUCTIONSTATUSTOTALSIZE',
    payload: totalSize,
  };
};

export const productionStatusActions_setIsPushedSearchButton = (isPushedSearchButton) => {
  return {
    type: 'SETPRODUCTIONSTATUSISPUSHEDSEARCHBUTTON',
    payload: isPushedSearchButton,
  };
};

export const productionStatusActions_setReset = () => {
  return {
    type: 'SETPRODUCTIONSTATUSRESET',
  };
};