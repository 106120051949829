import styled from "styled-components";

export const OperatingKPIMonitoringComponent = styled.section`
  align-content: flex-start;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 50px auto auto auto;
  gap: 15px;
  padding: 30px 50px;
  width: 100%;

  & nav { // 검색
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;

    & h1 {
      font-size: 30px;
      font-weight: 600;
    }

    & > div {
      display: flex;
      gap: 10px;

      & .nav-bar {
        align-items: center;
        box-sizing: border-box;
        border: 1px solid #1D1D1D;
        border-radius: 5px;
        display: flex;
        gap: 10px;
        height: 40px;
        justify-content: space-between;
        padding-inline: 15px;
        position: relative;
        width: fit-content;
  
        &>p {
          font-size: 14px;
          left: 5px;
          position: absolute;
          top: -60%;
        }
  
        &>select, &>input {
          font-size: 16px;
          width: 120px;
          &[type="text"] {width: 60px;}
          &.month {width: 60px;}
        }
      }
  
      & > button.ResetButton {
        box-shadow: none;
        &:hover {background: none;}
      }
    }
  }

  .contents {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    box-sizing: border-box;
    height: 100%;
    padding: 20px;
    width: 100%;
    background-color: var(--white);
    
    .contentWrap {
      align-items: center;
      box-sizing: border-box;
      display: grid;
      gap: 10px;
      grid-template-columns: 65% 35%;
      height: 600px;
      padding: 20px;
      width: 100%;      

      & > div.graph {
        height: 100%;
      }

      & > section.section-table {
        display: grid;
        grid-gap: 10px;
        height: 100%;
        overflow-y: hidden;
      }

      .TableSection {
        height: 100%;
        
        & > table {
          th {
            background-color: #ffffff; /* 배경색 설정 */
            color: #000000; /* 글자색 설정 */
          }

          td, th {
            border-top: 1.5px solid #bdbdbd;
            border-bottom: 1.5px solid #bdbdbd;
            border-left: none;
            border-right: none;
            padding-block: 5px;
          }
          
        }
      }
    }
  }
`;