import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { accountTypeApi } from 'api/apis/accountTypeApi';
import { marketApi } from 'api/apis/marketApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { MarketFormModalComponent } from './MarketFormModal.style';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';

const CreateMarketModal = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const { accountTypeId, typeId, marketCode, marketEvent } = props;
  console.log(accountTypeId, typeId, marketCode, marketEvent);

  const [_onload, setOnload] = useState('unload');

  const [_formData, setFormData] = useState(() => {
    const returnData = {
      companyId: userReducer.company.companyId,
      accountTypeId: accountTypeId,
    };
    if (typeId === 1) { // 수요기관코드
      returnData.accountCode = '';
      if (checkEmptyNull(marketCode, false)) returnData.accountCode = marketCode;
    }
    if (typeId === 3) returnData.businessNumber = ''; // 사업자등록번호

    return returnData;
  });

  const [_accountTypes, setAccountTypes] = useState([]);

  /* ====================================================================== #3 */
  useEffect(() => {
    if(!checkEmptyNull(marketCode, false)) return props.close();
    getAccountTypes();

    setOnload('loaded');
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const getAccountTypes = async () => { // 수주 유형
    const BodyToPost = {companyId: userReducer.company.companyId};
    await accountTypeApi.searchAccountType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('getAccountTypes : ', response);
      setAccountTypes(() => {return response.data;});
    });
  };

  /* ====================================================================== #5 */
  const actCreate = async (e) => {
    e.preventDefault();
    if (!checkEmptyNull(_formData.accountTypeId, false)) return alert('거래처 유형을 선택해주세요.');
    if (typeId === 1 && _formData.accountCode.toString().length < 1) return alert('수요기관코드를 입력해주세요.');
    if (typeId === 3 && _formData.businessNumber.toString().length < 1) return alert('사업자등록번호를 입력해주세요.');

    const id = typeof _formData.accountTypeId === 'string' ? Number(_formData.accountTypeId) : _formData.accountTypeId;
    const BodyToPost = {..._formData, accountTypeId: id};
    await marketApi.createMarket(typeId, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('actCreate : ', response);
      alert('나라장터 거래처를 추가했습니다.');
      if (!checkEmptyNull(accountTypeId, false) && response.data) marketEvent(response.data);
      else props.close();
    });
  };

  /* ====================================================================== #6 */

  return (
    <>
    {props.open && (
      <MarketFormModalComponent>
        <section>
          <div className='header-area'>
            <div className='title'>나라장터</div>
            <div className='close-btn' onClick={props.close}><CloseButtonIcon /></div>
          </div>

          <div className='main-area'>
            <Form
              title={
                <>
                  거래처<br />
                  추가<br />
                </>
              }
              buttons={<></>}
              forms={
                <>
                  <div className="formBox">
                    <h4>거래처유형</h4>
                    <select
                      name="accountType"
                      value={checkEmptyNull(_formData.accountTypeId, '')}
                      onChange={(e) => {setFormData((prev) => {return { ...prev, accountTypeId: e.target.value };});}}
                    >
                      <option value="">거래처유형</option>
                      {checkNullArray(_accountTypes, []).map((type) => {
                        if (type.accountTypeName === userReducer.company.companyName) return null;
                        return (<option key={type.accountTypeId + '_typeOption'} value={type.accountTypeId}>{type.accountTypeName}</option>);
                      })}
                    </select>
                  </div>
                  {typeId === 1 && (
                    <div className="formBox">
                      <h4>수요기관코드</h4>
                      <input
                        type="data"
                        name="accountCode"
                        placeholder="* 수요기관코드..."
                        data-required="required"
                        value={_formData.accountCode}
                        onInput={(e) => {setFormData((prev) => {return {...prev, accountCode: e.target.value.trim()};});}}
                      />
                    </div>
                  )}
                  {typeId === 3 && (
                    <div className="formBox">
                      <h4>사업자등록번호</h4>
                      <input
                        type="data"
                        name="businessNumber"
                        placeholder="* 사업자등록번호..."
                        data-required="required"
                        value={_formData.businessNumber}
                        onInput={(e) => {setFormData((prev) => {return {...prev, businessNumber: e.target.value};});}}
                      />
                    </div>
                  )}
                </>
              }
            />
          </div>

          <div className='footer-area'><div onClick={actCreate}>추가</div></div>
        </section>
      </MarketFormModalComponent>
    )}
    </>
  );
};

export default CreateMarketModal;
