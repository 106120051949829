import React from 'react';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto;
  height: 85%;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
  }
`;

const EquipmentLogListModal = (props) => {
  const { equipment } = props;

  const handleStatusName = (status) => {
    switch (status) {
      case 'waiting': return '대기';
      case 'working': return '작동';
      case 'pause': return '일시정지';
      case 'inspection': return '점검';

      default: return '';
    }
  }

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>{`${equipment.equipmentName} | ${equipment.equipmentCode}`}</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <TableSection content={
                <table>
                  <thead>
                    <tr>
                      <th>변경시간</th>
                      <th>상태변경</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkNullArray(equipment.equipmentLogList, []).map((equipmentLog, index) => {
                      return (
                        <tr key={index + '_equipmentLog'}>
                          <td>{DateTimeFormat(checkEmptyNull(equipmentLog.createdDate, ''))}</td>
                          <td>{handleStatusName(equipmentLog.preStatus)} → {handleStatusName(equipmentLog.postStatus)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
              />
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default EquipmentLogListModal;
