import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { auth } from 'api/apis/auth/auth';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import { checkEmail, checkPw } from 'components/checkValues/sign';
import AgreementModal from 'pages/auth/modal/AgreementModal';

const JoinSection = styled.main`
  align-items: center;
  background-color: var(--MainNavy);
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;

  .sign {
    display: grid; justify-content: center; align-items: center; padding:10% 0; row-gap: 30px;
    &>p{color:#898989;margin:8px;}
    fieldset {display:grid;gap:18px;
      input{width:450px;padding:16px 24px;margin:0;border-radius:8px;border:1px solid #ddd;}
    }
    .submit {width:100%;height:60px; background-color: var(--MainBlue);color:var(--white); border-radius:10px;font-size:1.25rem;}
    .submit.google {background-color: var(--ThirdBlue); margin-top: 24px;}
    .signitem {display:flex;gap:16px;margin-top:24px; justify-content: space-between;
      button{color:var(--white);}
    }
    .googleBtn {background-color: var(--white); color: var(--gray-800);border:1px solid #ddd;}
  }
  .signTitle {
    align-items: center;
    column-gap: 20px;
    display: grid;
    grid-template-columns: 50px auto;
    width: fit-content;

    img {height: 50px; width: 50px;}
    h3 {color: var(--white); font-size: 30px;}
  }
  .signup {
    padding:4% 0;
    fieldset{
      &>p{color:#898989;margin:8px;span{color:#000;}}
    }
  }

  input[name="email"] {
    &[data-status="false"] {
      background-color: var(--MainRed);
      border-color: var(--MainRed);
      &::-webkit-input-placeholder {color: var(--white);}
      &:focus {border-color: var(--MainRed);}
    }
  }
  input[type="password"] {
    &[data-status="false"] {
      background-color: var(--MainRed);
      border-color: var(--MainRed);
      &::-webkit-input-placeholder {color: var(--white);}
      &:focus {border-color: var(--MainRed);}
    }
  }
`;


const Join = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();

  /* ====================================================================== #2 */
  const [_loginId, setLoginId] = useState('');

  const [_emailStatus, setEmailStatus] = useState(false);
  const [_email, setEmail] = useState('');

  const [_userName, setUserName] = useState('');

  const [_phoneNumber, setPhoneNumber] = useState('');

  const [_pwStatus, setPwStatus] = useState(false);
  const [_pw, setPw] = useState('');
  const [_confirmPw, setConfirmPw] = useState('');

  const [_joinActive, setJoinActive] = useState(false); // 동의여부 > 가입하기 버튼
  const [_agreementModalStatus, setAgreementModalStatus] = useState(true);

  /* ====================================================================== #3 */
  useEffect(() => { checkPassword(); return () => { } }, [_pw, _confirmPw]);

  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const checkPassword = () => {
    setPwStatus(() => {return checkPw(_pw, _confirmPw);})
  }
  const makePhoneNumber = (e) => {
    const phoneNumber = e.target.value.toString().replace(/[^0-9]/g, '').replace(/(^02.{0}|^01.{1}|[0-9]{3,4})([0-9]{3,4})([0-9]{4})/g, '$1-$2-$3');
    setPhoneNumber(() => { return phoneNumber });
  };

  // 동의 팝업 > 회원가입
  const actJoin = async () => {
    if (!checkEmptyNull(_loginId, false)) return alert('아이디를 확인해주세요.');
    if (!checkEmptyNull(_email, false) || !checkEmail(_email)) return alert('이메일을 확인해주세요.');
    if (!checkEmptyNull(_userName, false)) return alert('이름을 확인해주세요.');
    if (!checkEmptyNull(_phoneNumber, false)) return alert('연락처를 확인해주세요.');
    if (_pw.length < 8 || !_pwStatus) return alert('비밀번호를 확인해주세요.');
    const reg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
    if (!reg.test(_pw)) return alert('비밀번호는 최소 8자리 이상 영문 + 숫자 + 특수기호 조합으로 등록하셔야 합니다.');

    setJoinActive(() => { return false });
    const BodyToPost = {
      role: 'ROLE_GUEST',
      permission: true,

      loginId: _loginId,
      password: _pw,

      name: _userName,
      email: _email,
      phoneNumber: _phoneNumber,
    };
    console.log('BodyToPost : ', BodyToPost);
    await auth.join(BodyToPost).then(async(response) => {
      if (response === undefined) return setJoinActive(() => { return true });
      console.log('auth.join : ', response);
      const alertText = `
      이메일 인증 후 다시 접속해 주시기 바랍니다.
      로그인 페이지로 이동합니다.
      `;
      alert(alertText);
      navigate(`/sign/login`, { replace: true });
    }).catch((error) => {
      console.log('auth.join - error : ', error);
      setJoinActive(() => { return true });
    });
  };

  return (
    <>
      <JoinSection>
        <section className='sign'>
          <div className='signTitle'>
            <img src={process.env.PUBLIC_URL + '/logo_Ifis.svg'} alt="아이피스 로고" />
            <h3>아이피스 회원가입</h3>
          </div>
          <fieldset>
            <input
              type="text" placeholder="아이디" required
              value={_loginId}
              onInput={(e) => {
                const loginId = e.target.value;
                setLoginId(() => { return loginId; });
              }}
            />
            <input
              type="email"
              name="email"
              placeholder="이메일"
              data-status={_emailStatus}
              value={_email}
              onInput={(e) => {
                const email = e.target.value;
                setEmailStatus(() => { return checkEmail(email) });
                setEmail(() => { return email });
              }}
            />
            <input
              type="text" placeholder="이름" required
              value={_userName}
              onInput={(e) => {
                const userName = e.target.value;
                setUserName(() => { return userName; });
              }}
            />
            <input
              type="text"
              name="phoneNumber"
              placeholder="연락처 - 를 제외하고 입력해주세요. ex) 01012341234"
              maxLength={14}
              value={_phoneNumber}
              onInput={makePhoneNumber}
            />
            <input required
              type="password" name="password" placeholder="비밀번호 (8자리 이상 영문+숫자+특수기호)"
              minLength={8} value={_pw} data-status={_pwStatus}
              onInput={(e) => {
                const pw = e.target.value;
                setPw(() => { return pw; });
              }}
            />
            <input required
              type="password" name="confirmPassword" placeholder="비밀번호 확인"
              minLength={8} data-status={_pwStatus} value={_confirmPw}
              onInput={(e) => {
                const pw = e.target.value;
                setConfirmPw(() => { return pw; });
              }}
            />
          </fieldset>
          <div>
            {_joinActive ? <button type="submit" className='submit' onClick={actJoin}>가입하기</button> : null}
          </div>
          <div className='signitem'>
            <button onClick={() => { navigate(`/sign/login`, { replace: true }); }} >로그인</button>
            <button onClick={() => { navigate(`/sign/email/find`, { replace: true }); }} >아이디 찾기</button>
            <button onClick={() => { navigate(`/sign/pw/find`, { replace: true }); }} >비밀번호 찾기</button>
          </div>
        </section>
      </JoinSection>

      {_agreementModalStatus ? (
        <AgreementModal
          activeJoin={() => {
            setJoinActive(() => { return true });
            setAgreementModalStatus(() => { return false });
          }}
          open={_agreementModalStatus}
          close={() => {navigate(`/sign/login`, { replace: true });}}
        />
      ) : null}
    </>
  );
};

export default Join;
