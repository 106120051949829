import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { currentStockActions_setClickedCategory, currentStockActions_setClickedLevel1Category, currentStockActions_setClickedLevel2Category, currentStockActions_setClickedLevel3Category } from 'store/modules/actions/common/currentStockActions';
import { elementActions_setClickedCategory, elementActions_setClickedLevel1Category, elementActions_setClickedLevel2Category, elementActions_setClickedLevel3Category } from 'store/modules/actions/common/elementActions';
import { productionPerformanceActions_setClickedCategory, productionPerformanceActions_setClickedLevel1Category, productionPerformanceActions_setClickedLevel2Category, productionPerformanceActions_setClickedLevel3Category } from 'store/modules/actions/common/productionPerformanceActions';
import { stockHistoryActions_setClickedCategory, stockHistoryActions_setClickedLevel1Category, stockHistoryActions_setClickedLevel2Category, stockHistoryActions_setClickedLevel3Category, } from 'store/modules/actions/common/stockHistoryActions';
import { stockPlaceHistoryActions_setClickedCategory, stockPlaceHistoryActions_setClickedLevel1Category, stockPlaceHistoryActions_setClickedLevel2Category, stockPlaceHistoryActions_setClickedLevel3Category, } from 'store/modules/actions/common/stockPlaceHistoryActions';

import { CategoryLevel1, CategoryLevel2, CategoryLevel3, } from 'components/category/Category';
import IconRightArrow from 'components/icons/IconRightArrow';

/* ~카테고리3 */
const Categories = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { currentStockReducer, elementReducer, productionPerformanceReducer, stockHistoryReducer, stockPlaceHistoryReducer, } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const { categoryType } = props;

  /* ====================================================================== #3 */
  useEffect(() => { }, [currentStockReducer.clickedCategory]);
  useEffect(() => { }, [elementReducer.clickedCategory]);
  useEffect(() => { }, [stockHistoryReducer.clickedCategory]);
  useEffect(() => { }, [stockPlaceHistoryReducer.clickedCategory]);
  useEffect(() => { }, [productionPerformanceReducer.clickedCategory]);

  /* ====================================================================== #4 */
  const actClickCategory = (category) => {
    switch (categoryType) {
      case 'currentStock': return dispatch(currentStockActions_setClickedCategory(category));
      case 'element': return dispatch(elementActions_setClickedCategory(category));
      case 'stockHistory': return dispatch(stockHistoryActions_setClickedCategory(category));
      case 'stockPlaceHistory': return dispatch(stockPlaceHistoryActions_setClickedCategory(category));
      case 'productionPerformance': return dispatch(productionPerformanceActions_setClickedCategory(category));
      default: return;
    }
  };

  /* ====================================================================== #5 */
  /* ====================================================================== #6 */

  return (
    <Fragment>
      <CategoryLevel1
        categoryType={categoryType}
        clickedLevel1Category={(() => {
          switch (categoryType) {
            case 'currentStock': return currentStockReducer.clickedLevel1Category;
            case 'element': return elementReducer.clickedLevel1Category;
            case 'stockHistory': return stockHistoryReducer.clickedLevel1Category;
            case 'stockPlaceHistory': return stockPlaceHistoryReducer.clickedLevel1Category;
            case 'productionPerformance': return productionPerformanceReducer.clickedLevel1Category;
            default: return {};
          }
        })()}
        setClickedCategory={(category) => { actClickCategory(category); }}
        setClickedLevel1Category={(category) => {
          switch (categoryType) {
            case 'currentStock':
              dispatch(currentStockActions_setClickedLevel3Category({}));
              dispatch(currentStockActions_setClickedLevel2Category({}));
              return dispatch(currentStockActions_setClickedLevel1Category(category));
            case 'element':
              dispatch(elementActions_setClickedLevel3Category({}));
              dispatch(elementActions_setClickedLevel2Category({}));
              return dispatch(elementActions_setClickedLevel1Category(category));
            case 'stockHistory':
              dispatch(stockHistoryActions_setClickedLevel3Category({}));
              dispatch(stockHistoryActions_setClickedLevel2Category({}));
              return dispatch(stockHistoryActions_setClickedLevel1Category(category));
            case 'stockPlaceHistory':
              dispatch(stockPlaceHistoryActions_setClickedLevel3Category({}));
              dispatch(stockPlaceHistoryActions_setClickedLevel2Category({}));
              return dispatch(stockPlaceHistoryActions_setClickedLevel1Category(category));
            case 'productionPerformance':
              dispatch(productionPerformanceActions_setClickedLevel3Category({}));
              dispatch(productionPerformanceActions_setClickedLevel2Category({}));
              return dispatch(productionPerformanceActions_setClickedLevel1Category(category));
            default: return;
          }
        }}
      ></CategoryLevel1>

      <IconRightArrow />

      <CategoryLevel2
        categoryType={categoryType}
        clickedLevel1Category={(() => {
          switch (categoryType) {
            case 'currentStock': return currentStockReducer.clickedLevel1Category;
            case 'element': return elementReducer.clickedLevel1Category;
            case 'stockHistory': return stockHistoryReducer.clickedLevel1Category;
            case 'stockPlaceHistory': return stockPlaceHistoryReducer.clickedLevel1Category;
            case 'productionPerformance': return productionPerformanceReducer.clickedLevel1Category;
            default: return {};
          }
        })()}
        clickedLevel2Category={(() => {
          switch (categoryType) {
            case 'currentStock': return currentStockReducer.clickedLevel2Category;
            case 'element': return elementReducer.clickedLevel2Category;
            case 'stockHistory': return stockHistoryReducer.clickedLevel2Category;
            case 'stockPlaceHistory': return stockPlaceHistoryReducer.clickedLevel2Category;
            case 'productionPerformance': return productionPerformanceReducer.clickedLevel2Category;
            default: return {};
          }
        })()}
        setClickedCategory={(category) => { actClickCategory(category); }}
        setClickedLevel2Category={(category) => {
          switch (categoryType) {
            case 'currentStock':
              dispatch(currentStockActions_setClickedLevel3Category({}));
              return dispatch(currentStockActions_setClickedLevel2Category(category));
            case 'element':
              dispatch(elementActions_setClickedLevel3Category({}));
              return dispatch(elementActions_setClickedLevel2Category(category));
            case 'stockHistory':
              dispatch(stockHistoryActions_setClickedLevel3Category({}));
              return dispatch(stockHistoryActions_setClickedLevel2Category(category));
            case 'stockPlaceHistory':
              dispatch(stockPlaceHistoryActions_setClickedLevel3Category({}));
              return dispatch(stockPlaceHistoryActions_setClickedLevel2Category(category));
            case 'productionPerformance':
              dispatch(productionPerformanceActions_setClickedLevel3Category({}));
              return dispatch(productionPerformanceActions_setClickedLevel2Category(category));
            default: return;
          }
        }}
      ></CategoryLevel2>

      <IconRightArrow />

      <CategoryLevel3
        categoryType={categoryType}
        clickedLevel2Category={(() => {
          switch (categoryType) {
            case 'currentStock': return currentStockReducer.clickedLevel2Category;
            case 'element': return elementReducer.clickedLevel2Category;
            case 'stockHistory': return stockHistoryReducer.clickedLevel2Category;
            case 'stockPlaceHistory': return stockPlaceHistoryReducer.clickedLevel2Category;
            case 'productionPerformance': return productionPerformanceReducer.clickedLevel2Category;
            default: return {};
          }
        })()}
        clickedLevel3Category={(() => {
          switch (categoryType) {
            case 'currentStock': return currentStockReducer.clickedLevel3Category;
            case 'element': return elementReducer.clickedLevel3Category;
            case 'stockHistory': return stockHistoryReducer.clickedLevel3Category;
            case 'stockPlaceHistory': return stockPlaceHistoryReducer.clickedLevel3Category;
            case 'productionPerformance': return productionPerformanceReducer.clickedLevel3Category;
            default: return {};
          }
        })()}
        setClickedCategory={(category) => { actClickCategory(category); }}
        setClickedLevel3Category={(category) => {
          switch (categoryType) {
            case 'currentStock': return dispatch(currentStockActions_setClickedLevel3Category(category));
            case 'element': return dispatch(elementActions_setClickedLevel3Category(category));
            case 'stockHistory': return dispatch(stockHistoryActions_setClickedLevel3Category(category));
            case 'stockPlaceHistory': return dispatch(stockPlaceHistoryActions_setClickedLevel3Category(category));
            case 'productionPerformance': return dispatch(productionPerformanceActions_setClickedLevel3Category(category));
            default: return;
          }
        }}
      ></CategoryLevel3>
    </Fragment>
  );
};

export default Categories;
