import React, { Fragment, useEffect, useState } from 'react';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

import Bookmark from 'components/icons/Bookmark';
import DOCButtonIcon from 'components/icons/DOCButtonIcon';

const ProcessManagementRow = (props) => {
  /* ====================================================================== #1 */
  /* ====================================================================== #2 */
  const [ProcessData, setProcessData] = useState(props.content);

  const [ProcessPutItems, setProcessPutItems] = useState(checkNullArray(props.content.processElementList, []).filter((thisItem) => thisItem.processElementType === 'Put'));
  const [ProcessMakeItems, setProcessMakeItems] = useState(checkNullArray(props.content.processElementList, []).filter((thisItem) => thisItem.processElementType === 'Make'));

  // const [_rowLength, setRowLength] = useState(ProcessMakeItems.length);
  const [_rowLength, setRowLength] = useState(ProcessPutItems.length > ProcessMakeItems.length ? ProcessPutItems.length : ProcessMakeItems.length);

  /* ====================================================================== #3 */
  useEffect(() => {
    setProcessData(props.content);

    const PutItems = checkNullArray(props.content.processElementList, []).filter((thisItem) => thisItem.processElementType === 'Put');
    const MakeItems = checkNullArray(props.content.processElementList, []).filter((thisItem) => thisItem.processElementType === 'Make');

    setProcessPutItems(() => { return PutItems });
    setProcessMakeItems(() => { return MakeItems });

    // setRowLength(() => { return ProcessMakeItems.length });
    setRowLength(() => { return PutItems.length > MakeItems.length ? PutItems.length : MakeItems.length });
  }, [props]);

  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  /* ====================================================================== #6 */
  const returnItems = () => {
    let returnArray = [];
    for (let i = 1; i < _rowLength; i++) {
      returnArray.push(
        <tr key={i + '_productRows'}>
          {ProcessMakeItems[i] !== undefined ? (
            <Fragment>
              <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                {checkNullObject(ProcessMakeItems[i].element, false) ? checkEmptyNull(ProcessMakeItems[i].element.elementName, '') : checkEmptyNull(ProcessMakeItems[i].elementName, '')}
              </td>
              <td>{checkNullObject(ProcessMakeItems[i].element, false) ? checkEmptyNull(ProcessMakeItems[i].element.elementCode, '') : checkEmptyNull(ProcessMakeItems[i].elementCode, '')}</td>
              <td>{checkEmptyNull(ProcessMakeItems[i].amount, 0).toLocaleString()}</td>
            </Fragment>
          ) : (
            <Fragment>
              <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
            </Fragment>
          )}
          {ProcessPutItems[i] !== undefined ? (
            <Fragment>
              <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                {checkNullObject(ProcessPutItems[i].element, false) ? checkEmptyNull(ProcessPutItems[i].element.elementName, '') : checkEmptyNull(ProcessPutItems[i].elementName, '')}
              </td>
              <td>{checkNullObject(ProcessPutItems[i].element, false) ? checkEmptyNull(ProcessPutItems[i].element.elementCode, '') : checkEmptyNull(ProcessPutItems[i].elementCode, '')}</td>
              <td>{checkEmptyNull(ProcessPutItems[i].amount, 0).toLocaleString()}</td>
            </Fragment>
          ) : (
            <Fragment>
              <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
            </Fragment>
          )}
        </tr>,
      );
    }
    return returnArray;
  };

  return (
    <Fragment>
      <tr
        style={{ borderBottom: '1px solid var(--gray-200)' }}
        name={props.authority.indexOf('112-2') !== -1 && 'processRows'}
        data-key={ProcessData.processId}
        onClick={() => { if (props.authority.indexOf('112-2') !== -1) props.goDetails(ProcessData); }}
      >
        {props.authority.indexOf('112-2') !== -1 ? (
          <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ minWidth: '50px', width: '50px' }}>
            <input
              type="checkBox"
              name="processes"
              data-key={ProcessData.processId}
              onChange={(e) => {
                const isChecked = e.target.checked;
                props.handleCheckedItem(ProcessData.processId, isChecked);

                if (isChecked) e.target.checked = true;
                else e.target.checked = false;
              }}
              onClick={(e) => { e.stopPropagation(); }}
            />
          </td>
        ) : null}
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ minWidth: '50px', width: '50px' }}>
          <Bookmark
            bookmark={checkEmptyNull(ProcessData.bookMark, false)}
            clickEvent={() => { props.handleBookmark(ProcessData, props.index) }}
          />
        </td>

        {/* 파일 */}
        {(() => {
          if (props.authority.indexOf('109-1') !== -1 || props.authority.indexOf('109-2') !== -1) {
            if (checkNullArray(ProcessData.attachFileList, false)) {
              return (
                <td rowSpan={_rowLength === 0 ? 1 : _rowLength} className='table-preview'>
                  <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                    onClick={(e) => { e.stopPropagation(); props.showFileList(ProcessData); }}
                  >
                    <img src={ProcessData.attachFileList[0]?.fileData?.fileDataS3URL || ''} alt='image preview' className='img-preview' />
                    <div className='btn-file'><DOCButtonIcon /></div>
                  </div>
                </td>
              );
            } else {
              return (
                <td className='table-preview' rowSpan={_rowLength === 0 ? 1 : _rowLength}>
                  <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                    onClick={(e) => { e.stopPropagation(); props.showFileList(ProcessData); }}
                  >
                    <div className='no-image'><p>이미지가 없습니다.</p></div>
                    <div className='btn-file'><DOCButtonIcon /></div>
                  </div>
                </td>
              );
            }
          }
        })()}
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{ProcessData.processTypeName}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{ProcessData.processCode}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{ProcessData.processName}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{ProcessData.place?.placeName}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{checkEmptyNull(ProcessData.remark, '')}</td>

        {ProcessMakeItems[0] !== undefined ? (
          <Fragment>
            <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
              {checkNullObject(ProcessMakeItems[0].element, false) ? checkEmptyNull(ProcessMakeItems[0].element.elementName, '') : checkEmptyNull(ProcessMakeItems[0].elementName, '')}
            </td>
            <td>{checkNullObject(ProcessMakeItems[0].element, false) ? checkEmptyNull(ProcessMakeItems[0].element.elementCode, '') : checkEmptyNull(ProcessMakeItems[0].elementCode, '')}</td>
            <td>{checkEmptyNull(ProcessMakeItems[0].amount, 0).toLocaleString()}</td>
          </Fragment>
        ) : (
          <Fragment>
            <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
          </Fragment>
        )}
        {ProcessPutItems[0] !== undefined ? (
          <Fragment>
            <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
              {checkNullObject(ProcessPutItems[0].element, false) ? checkEmptyNull(ProcessPutItems[0].element.elementName, '') : checkEmptyNull(ProcessPutItems[0].elementName, '')}
            </td>
            <td>{checkNullObject(ProcessPutItems[0].element, false) ? checkEmptyNull(ProcessPutItems[0].element.elementCode, '') : checkEmptyNull(ProcessPutItems[0].elementCode, '')}</td>
            <td>{checkEmptyNull(ProcessPutItems[0].amount, 0).toLocaleString()}</td>
          </Fragment>
        ) : (
          <Fragment>
            <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
          </Fragment>
        )}
        {/* <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          <button className='btn-inven-3'
            style={{ fontSize: '1.3em', fontWeight: '600', height: '100%', lineHeight: '1.1em' }}
            onClick={(e) => { e.preventDefault(); e.stopPropagation(); props.showPutElementList(ProcessData); }}
          >
            투입<br />정보
          </button>
        </td> */}
      </tr>
      {_rowLength > 1 && returnItems()}
    </Fragment>
  );
};

export default ProcessManagementRow;
