import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { qualityActions_setEndDate, qualityActions_setPageNumber, qualityActions_setReset, qualityActions_setSearchText, qualityActions_setStartDate, qualityActions_setTotalSize, qualityActions_setWorkOrderStatus } from 'store/modules/actions/common/qualityActions';

import { workOrderTypeApi } from 'api/apis/workOrderTypeApi';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import ProcessResultModal from 'pages/qualityControl/_old/modal/ProcessResultModal';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';

import UriHandleAmount from 'pages/qualityControl/custom/company/uri/modal/UriHandleAmount';
import UriLeadTime from 'pages/qualityControl/custom/company/uri/modal/UriLeadTime';
import ExcelDownloadModal from 'components/excel/ExcelDownloadModal';
import UriQualityDownload from './modal/UriQualityDownload';

const MainContent = styled.div`
  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
    & .selectButtons {
      display: flex;
      gap: 5px;
    }
  }

  & .TableSection {
    & td.failedAmount {
      background-color: var(--MainRed);
      color: var(--white);
      font-size: 0.9em;
    }
  }
`;

const ResultButton = styled.div`
  align-items: center;
  background-color: var(--Violet);
  border: 4px solid var(--white);
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  font-size: 20px;
  font-weight: 600;
  min-height: 100px;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const UriQualityControl = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const { qualityReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_workOrderTypes, setWorkOrderTypes] = useState([]);

  const [_workOrderLogList, setWorkOrderLogList] = useState([]);

  const [_downloadModalStatus, setDownloadModalStatus] = useState(false);

  const [_modalStatus, setModalStatus] = useState(false);
  const [_leadTimeModalStatus, setLeadTimeModalStatus] = useState(false);
  const [_handleAmountModalStatus, setHandleAmountModalStatus] = useState(false);
  const [_modalContent, setModalContent] = useState({});


  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('601') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));


    getWorkOrderTypes();
    getWorkOrderLogList(qualityReducer.pageNumber - 1, qualityReducer.workOrderType, qualityReducer.workOrderStatus);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getWorkOrderLogList(qualityReducer.pageNumber - 1, qualityReducer.workOrderType, qualityReducer.workOrderStatus);
    }

    return () => { };
  }, [qualityReducer.pageNumber]);

  /* ========================================================================= #4 */
  const getWorkOrderTypes = async () => {
    const BodyToPost = { companyId: userReducer.company.companyId };
    await workOrderTypeApi.searchWorkOrderType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderTypeApi.searchWorkOrderType : ', response);
      setWorkOrderTypes(() => { return response.data; });
    });
  };

  const getWorkOrderLogList = async (page, workOrderType, workOrderStatus, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = { companyId: userReducer.company.companyId };

    if (checkEmptyNull(workOrderType, false) && workOrderType !== 'all') {
      BodyToPost.workOrderTypeId = Number(workOrderType);
    }
    if (checkEmptyNull(workOrderStatus, false)) {
      switch (workOrderStatus) {
        case 'all': BodyToPost.workOrderStatus = ['end', 'cancel']; break;

        default: BodyToPost.workOrderStatus = [workOrderStatus]; break;
      }
    }

    if (checkEmptyNull(qualityReducer.startDate, false) && checkEmptyNull(qualityReducer.endDate)) {
      BodyToPost.scheduledStartDate = DateFormat(qualityReducer.startDate);
      BodyToPost.scheduledEndDate = DateFormat(qualityReducer.endDate);
      if (qualityReducer.startDate > qualityReducer.endDate) {
        BodyToPost.scheduledStartDate = DateFormat(qualityReducer.endDate);
        BodyToPost.scheduledEndDate = DateFormat(qualityReducer.startDate);
      }
    } else if (checkEmptyNull(qualityReducer.startDate, false)) {
      BodyToPost.scheduledStartDate = DateFormat(qualityReducer.startDate);
    } else if (checkEmptyNull(qualityReducer.endDate)) {
      BodyToPost.scheduledEndDate = DateFormat(qualityReducer.endDate);
    }

    if (checkEmptyNull(qualityReducer.searchText)) {
      BodyToPost.accountName = qualityReducer.searchText;
    }

    await workOrderLogApi.searchWorkOrderLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.searchWorkOrderLog : ', response);
      setWorkOrderLogList(() => { return response.data.data.content; });
      dispatch(qualityActions_setTotalSize(response.data.data.totalElements));

      if (!clear && checkEmptyNull(qualityReducer.searchOption) && checkEmptyNull(qualityReducer.searchText)) {
        if (qualityReducer.startDate > qualityReducer.endDate) {
          dispatch(qualityActions_setStartDate(qualityReducer.endDate))
          dispatch(qualityActions_setEndDate(qualityReducer.startDate))
        }
      }
    });
  };

  /* ========================================================================= #5 */
  /* 검색 - 작업상태 */
  const handleSearchWorkOrderStatus = async (workOrderStatus) => {
    dispatch(qualityActions_setWorkOrderStatus(workOrderStatus));
    dispatch(qualityActions_setPageNumber(1));
    await getWorkOrderLogList(0, qualityReducer.workOrderType, workOrderStatus)
  }

  /* 다운로드 모달 */
  const actDownload = () => {
    setDownloadModalStatus(true);
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(qualityActions_setPageNumber(1));
    await getWorkOrderLogList(0, qualityReducer.workOrderType, qualityReducer.workOrderStatus)
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(qualityActions_setReset());
    dispatch(qualityActions_setPageNumber(1));
    getWorkOrderLogList(0, 'all', 'all', true);
  };

  /* ========================================================================= #6 */

  return (
    <Grid3Body contents={
      <>
        <MainContent className="Main">
          <NavBar
            title={
              <div className='Title'>
                <NavTitle menuCode={'601'} />
                <div className='selectButtons'>
                  {[
                    { status: 'all', name: '전체' },
                    { status: 'end', name: '완료' },
                    { status: 'cancel', name: '취소' },
                  ].map((workOrderStatus, index) => {
                    return (
                      <button key={index + '_statusButton'}
                        className={qualityReducer.workOrderStatus === workOrderStatus.status ? 'btn-inven-1' : 'btn-set'}
                        onClick={() => { handleSearchWorkOrderStatus(workOrderStatus.status); }}
                      >
                        {workOrderStatus.name}
                      </button>
                    )
                  })}
                </div>
              </div>
            }
            buttons={
              <>
                <button className='btn-download' onClick={actDownload}>다운로드</button>
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <div className="SearchSection">
                    <input
                      type="date"
                      className="SelectDate"
                      value={qualityReducer.startDate}
                      onChange={(e) => { dispatch(qualityActions_setStartDate(e.target.value)) }}
                    />

                    <IconRightArrow />

                    <input
                      type="date"
                      className="SelectDate"
                      value={qualityReducer.endDate}
                      onChange={(e) => { dispatch(qualityActions_setEndDate(e.target.value)) }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="accountName">거래처</option>
                      </select>
                      <input
                        className="SearchBar detail"
                        placeholder="Search..."
                        value={qualityReducer.searchText}
                        onInput={(e) => { dispatch(qualityActions_setSearchText(e.target.value)) }}
                      />
                    </div>

                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton" onClick={actReset}>초기화</div>
                  </div>
                </div>
              </>
            }
          />

          <TableSection content={
            <MainContent>
              <table>
                <thead>
                  <tr>
                    <th style={{ minWidth: '120px', padding: 'unset', width: '120px' }}>작업상태</th>
                    <th>지시일자</th>
                    <th>거래처</th>
                    <th>생산수량</th>
                    <th>성공수량</th>
                    <th>불량수량</th>
                    {_authority.indexOf('601-2') !== -1 ? (
                      <th style={{ minWidth: '60px', padding: 'unset', width: '60px' }}></th>
                    ) : null}
                    <th style={{ minWidth: '150px', padding: 'unset', width: '150px' }}></th>
                    <th style={{ minWidth: '150px', padding: 'unset', width: '150px' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {_workOrderLogList.map((workOrderLog, index) => {
                    return (
                      <tr key={index + '_workOrderLog'} data-key={workOrderLog.workOrderLogId} name="workOrderRow">
                        <StatusTdButton
                          statusStyle={{ cursor: 'default', minHeight: '100px', minWidth: '120px', width: '120px' }}
                          content={workOrderLog}
                          contentId={workOrderLog.workOrderLogId}
                          status={workOrderLog.workOrderStatus}
                        />
                        <td>{DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledStartDate, ''))}</td>
                        <td>{workOrderLog.accountName}</td>
                        <td>{checkEmptyNull(workOrderLog.amount, 0).toLocaleString()}</td>
                        <td>{checkEmptyNull(workOrderLog.successfulAmount, 0).toLocaleString()}</td>
                        <td>{checkEmptyNull(workOrderLog.failedAmount, 0).toLocaleString()}</td>
                        {_authority.indexOf('601-2') !== -1 ? (
                          <td className='failedAmount' style={{ cursor: 'pointer', minWidth: '60px', padding: 'unset', width: '60px' }}
                            onClick={(e) => {
                              e.preventDefault();
                              setModalContent(() => { return workOrderLog });
                              setTimeout(setHandleAmountModalStatus(true), 1000);
                            }}
                          >
                            수량<br />변경
                          </td>
                        ) : null}
                        <td style={{ minWidth: '150px', padding: 'unset', width: '150px' }}>
                          <ResultButton style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              e.preventDefault();
                              setModalContent(() => { return workOrderLog });
                              setTimeout(setModalStatus(true), 1000);
                            }}
                          >
                            불량데이터
                          </ResultButton>
                        </td>
                        <td style={{ minWidth: '150px', padding: 'unset', width: '150px' }}>
                          <ResultButton style={{ backgroundColor: 'var(--MainBlue)', cursor: 'pointer' }}
                            onClick={(e) => {
                              e.preventDefault();
                              setModalContent(() => { return workOrderLog });
                              setTimeout(setLeadTimeModalStatus(true), 1000);
                            }}
                          >
                            리드타임
                          </ResultButton>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </MainContent>
          }
          ></TableSection>
        </MainContent>

        <PagingComponent
          page={qualityReducer.pageNumber}
          count={qualityReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(qualityActions_setPageNumber(page));
          }}
        />

        {/* 모달 */}
        {/* 엑셀 다운로드 */}
        {_downloadModalStatus === true ? (
          <UriQualityDownload
            open={_downloadModalStatus}
            close={() => { setDownloadModalStatus(false) }}
          />
        ) : null}
        {/* 수량변경 */}
        {_handleAmountModalStatus === true ? (
          <UriHandleAmount
            content={_modalContent}
            open={_handleAmountModalStatus}
            update={() => {
              getWorkOrderLogList(qualityReducer.pageNumber - 1, qualityReducer.workOrderType, qualityReducer.workOrderStatus);
              setHandleAmountModalStatus(false);
            }}
            close={() => { setHandleAmountModalStatus(false); }}
          />
        ) : null}
        {/* 공정결과 */}
        {_modalStatus === true ? (
          <ProcessResultModal
            content={_modalContent}
            open={_modalStatus}
            close={() => { setModalStatus(false); }}
          />
        ) : null}
        {/* 리드타임 */}
        {_leadTimeModalStatus === true ? (
          <UriLeadTime
            content={_modalContent}
            open={_leadTimeModalStatus}
            close={() => { setLeadTimeModalStatus(false); }}
          />
        ) : null}
      </>
    }
    />
  );
};

export default UriQualityControl;
