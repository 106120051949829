import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { calendarType } from 'api/apis/calendar/calendarType';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

import { FiPlusCircle } from "react-icons/fi";
import { IoSearch } from "react-icons/io5";
import { MdDeleteForever } from "react-icons/md";
import { RiEditFill } from "react-icons/ri";
import { ProjectTypeManagementComponent } from './ProjectTypeManagement.style';

import ProjectFormModal from './modal/ProjectFormModal';

const ProjectTypeManagement = () => {
  const { userReducer } = useSelector(state => state);

  const [_searchData, setSearchData] = useState({});
  const [_types, setTypes] = useState([]);

  const [_modalType, setModalType] = useState();
  const [_modalData, setModalData] = useState();
  const [_modalStatus, setModalStatus] = useState(false);

  useEffect(() => {
    getCalendarTypes();
  }, []);
  useEffect(() => { }, [_searchData, _types]);

  const getCalendarTypes = async () => { // 캘린더 유형 리스트 불러오기 >> 페이징X?
    const body = { companyId: userReducer.company.companyId };
    if (checkEmptyNull(_searchData.calenderTypeName, false)) body.calenderTypeName = _searchData.calenderTypeName;

    await calendarType.search(body).then(response => {
      if (response === undefined) return;
      console.log('getCalendarTypes : ', response);
      setTypes(() => { return response.data });
    })
  }

  const handleSearchData = (e) => { // 검색바 입력 이벤트
    const { name, value } = e.target;
    setSearchData((prev) => { return { ...prev, [name]: value } });
  }
  const actSearch = () => { // 검색
    getCalendarTypes();
  }

  const handleModalStatus = (type, data) => { // 모달 상태 변경(열기/닫기)
    setModalType(() => { return type });
    setModalData(() => { return data || {} });
    setTimeout(setModalStatus(() => { return true }), 1000);
  }

  return (
    <>
      <ProjectTypeManagementComponent>
        <header>
          <h1>프로젝트 유형 관리</h1>
          <div>
            <button onClick={() => { handleModalStatus('type-create') }}><FiPlusCircle />유형 추가</button>
          </div>
        </header>
        <nav>
          <div className='search-bar'>
            <input
              type='text'
              name='calenderTypeName'
              placeholder='검색어를 입력해주세요.'
              value={_searchData.calenderTypeName || ''}
              onInput={handleSearchData}
              onKeyDown={(e) => {
                if (e.key === 'Enter') actSearch();
              }}
            />
            <button onClick={actSearch}><IoSearch /></button>
          </div>
        </nav>
        <div>
          <table>
            <colgroup>
              <col width={'15%'} /><col width={'15%'} /><col /><col width={'20%'} />
            </colgroup>
            <thead>
              <tr>
                {['ID', '색상', '유형이름', ''].map(((key, index) => {
                  return (<th key={index + '_tr'}>{key}</th>)
                }))}
              </tr>
            </thead>
            <tbody>
              {!checkNullArray(_types, false) ? (<tr><td colSpan={4}>데이터가 없습니다.</td></tr>) :
                checkNullArray(_types, []).map((type, index) => {
                  return (
                    <tr key={index + '_td'}>
                      <td>{type.calenderTypeId}</td>
                      <td><div className='type-color' style={{ backgroundColor: type.color || 'var(--white)' }}></div></td>
                      <td>{type.calenderTypeName}</td>
                      <td>
                        <div>
                          <div></div>
                          <button onClick={() => { handleModalStatus('type-update', type) }}><RiEditFill />수정</button>
                          <div></div>
                          <button onClick={() => { handleModalStatus('type-delete', type) }}><MdDeleteForever />삭제</button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </ProjectTypeManagementComponent>

      {_modalStatus ? (
        <ProjectFormModal
          modalType={_modalType}
          modalData={_modalData}
          update={() => {
            console.log('update');
            getCalendarTypes();
            setTimeout(setModalStatus(() => { return false }), 1000);
          }}
          open={_modalStatus}
          close={() => { setModalStatus(() => { return false }); }}
        />
      ) : null}
    </>
  );
};

export default ProjectTypeManagement;