const initialState = {
  mallSiteOrderStatus: 'all',

  startDate: undefined,
  endDate: undefined,
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const resetState = {
  mallSiteOrderStatus: 'all',

  startDate: undefined,
  endDate: undefined,
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const mallMyOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETMALLMYORDERSTATUS':
      return {
        ...state,
        mallSiteOrderStatus: action.payload,
      };
    case 'SETMALLMYORDERSTARTDATE':
      return {
        ...state,
        startDate: action.payload,
      };
    case 'SETMALLMYORDERENDDATE':
      return {
        ...state,
        endDate: action.payload,
      };
    case 'SETMALLMYORDERSEARCHOPTION':
      return {
        ...state,
        searchOption: action.payload,
      };
    case 'SETMALLMYORDERSEARCHTEXT':
      return {
        ...state,
        searchText: action.payload,
      };
    case 'SETMALLMYORDERPAGENUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'SETMALLMYORDERTOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };
    case 'SETMALLMYORDERISPUSHEDSEARCHBUTTON':
      return {
        ...state,
        isPushedSearchButton: action.payload,
      };
    case 'SETMALLMYORDERRESET':
      return {
        ...state,
        ...resetState,
      };

    default:
      return state;
  }
};

export default mallMyOrderReducer;
