import React from 'react';
import styled from 'styled-components';

const Table = styled.div`
  height: inherit;
  overflow: auto;
  position: relative;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}
  * {
    -ms-user-select: none; 
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
  table {
    background-color: var(--white);
    border-collapse: collapse;
    font-weight: 400;
    height: fit-content;
    min-width: 100%;
    position: relative;
    height: fit-content;
  }
  thead {
    background-color: var(--MainNavy);
    color: var(--white);
    height: 40px;
    position: sticky;
    top: 0px;
    z-index: 1;
  }
  tbody tr {
    /* height: 128px; */
  }
  td, th {
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    font-size: 1.25rem;
    line-height: 120%;
    max-width: fit-content;
    min-width: 120px;
    padding: 16px;
    text-align: center;
    vertical-align: middle;
    /* white-space: nowrap; */
    word-break: break-word;
  }
  .table-price { width: 100px; min-width: 100px; }
  .table-safeStock { width: 100px; min-width: 100px; }

  .img-preview {
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 124px;

    &:hover {box-shadow: 0 2px 6px 0 rgba(196,196,196,0.8);}
  }
  .no-image {
    width: 124px;
    height: 124px;
    border: 1px solid #ddd;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: .5rem;
    color: #ddd;
  }
  .btn-file {position: absolute; right: 8px; bottom: 8px;}
  .btn-doc {
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(196,196,196,0.8);
    box-sizing: border-box;
    color: #fff0;
    cursor: pointer;
    /* display: flex; */
    display: grid;
    gap: 8px;
    max-height: 51px;
    justify-content: center;
    margin: 0px auto;
    padding: 10px;
    place-items: center;
    vertical-align: middle;
    width: fit-content;
    &:hover {opacity: .7;}
  }
  .gs-table {
    td, th {
      min-width: 140px;
    }
  }
`;

const TableSection = (props) => {
  return <Table className="TableSection" /* style={props.sectionStyle} */>{props.content}</Table>;
};

export default TableSection;
