import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

import { processApi } from 'api/apis/processApi';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 85%;
  justify-content: flex-start;
  max-height: 500px;
  max-width: 710px;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  height: calc(100% - 55px);
  overflow: auto;
  width: 100%;

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 6px;
  }
`;

const ContentSection = styled.div`
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
  width: 100%;
`;

const ButtonsSection = styled.div`
  align-items: center;
  column-gap: 10px;
  display: flex;
  justify-content: center;
  margin: 20px auto;
  width: 100%;
`;

const FormatButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  border-radius: 10px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 200px;
  justify-content: center;
  width: 50%;
`;

const UploadModalButton = styled.label`
  align-items: center;
  background-color: var(--ThirdBlue);
  border-radius: 10px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 200px;
  justify-content: center;
  width: 50%;
`;

const NoticeSection = styled.div`
  color: var(--MainRed);
  font-size: 15px;
  padding-bottom: 20px;
`;

const LoadingScreen = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  color: var(--white);
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 999;
`;

const ProcessUploadModal = (props) => {
  const { userReducer } = useSelector((state) => state);

  const [_onload, setOnload] = useState('unload');

  const [_loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => {
    setOnload('loaded');

    return () => {};
  }, []);

  const excelExportCommon = (e, callback) => {
    const fileList = e.target.files;
    const thisFile = fileList[0];
    console.log(thisFile);
    if (thisFile === undefined) return;

    const reader = new FileReader();
    reader.onload = async () => {
      const fileData = reader.result;
      const wb = XLSX.read(fileData, { type: 'binary' });
      const sheetNameList = wb.SheetNames;
      for (let i = 0; i < sheetNameList.length; i++) {
        const thisSheetName = sheetNameList[i];
        const thisSheet = wb.Sheets[thisSheetName];

        await callback(thisSheet);
      }
    };

    reader.readAsBinaryString(thisFile);
  };

  const excelSetting = async (sheet) => {
    console.log('sheet : ', sheet);
    const sendData = {
      companyId: userReducer.company.companyId,
    };
    const result = XLSX.utils.sheet_to_json(sheet);
    console.table(result);

    const setSendData = result.map((thisItem) => {
      console.log(thisItem);
      const newData = {
        putProcessElement: {},
        makeProcessElement: {},
      };

      for (const key in thisItem) {
        const value = thisItem[key];

        switch (key) {
          case '공정유형':
            newData.processTypeName = value;
            break;
          case '공정코드':
            newData.processCode = value;
            break;
          case '공정이름':
            newData.processName = value;
            break;
          case '비고':
            newData.remark = value;
            break;
          case '투입물품코드':
            if (value !== '') newData.putProcessElement.elementCode = value;
            break;
          // case '투입물품이름':
          //   if (value !== '') newData.putProcessElement.elementName = value;
          //   break;
          case '투입량':
            if (value !== '') newData.putProcessElement.amount = value;
            break;
          case '생산물품코드':
            if (value !== '') newData.makeProcessElement.elementCode = value;
            break;
          // case '생산물품이름':
          //   if (value !== '') newData.makeProcessElement.elementName = value;
          //   break;
          case '생산량':
            if (value !== '') newData.makeProcessElement.amount = value;
            break;

          default:
        }
      }

      if (Object.keys(newData.putProcessElement).length !== 2) {
        delete newData.putProcessElement;
      }
      if (Object.keys(newData.makeProcessElement).length !== 2) {
        delete newData.makeProcessElement;
      }

      return newData;
    });
    console.log('setSendData : ', setSendData);
    sendData['processList'] = setSendData;

    if (result.length === sendData.processList.length) {
      console.log('sendData : ', sendData);
      console.log('sendData - stringify : ', JSON.stringify(sendData));
      await postProcesses(sendData);
    }
  };
  const postProcesses = async (sendData) => {
    console.log('postProcesses - sendData : ', sendData);
    await processApi
      .uploadProcess(sendData)
      .then((response) => {
        if (response === undefined) return;
        console.log('processApi.uploadProcess : ', response);

        alert('공정을 업로드했습니다.');
      })
      .finally(() => {
        setLoadingStatus(false);
        props.reset();
        props.close();
      });
  };

  const selectUploadFile = (e) => {
    const confirmText = `
    공정을 업로드 하시겠습니까?
    `;

    if (window.confirm(confirmText) === true) {
      setLoadingStatus(true);
      const fileList = e.target.files;
      const thisFile = fileList[0];
      console.log(thisFile);
      if (thisFile === undefined) return;

      excelExportCommon(e, excelSetting);
    } else {
      return;
    }
  };

  const downloadFormat = () => {
    let headerRow = [];
    const OneRow = {
      공정유형: '',
      공정코드: '',
      공정이름: '',
      비고: '',
      투입물품코드: '',
      // 투입물품이름: '',
      투입량: '',
      생산물품코드: '',
      // 생산물품이름: '',
      생산량: '',
    };

    headerRow.push(OneRow);
    console.log('headerRow : ', headerRow);

    if (headerRow.length > 0) {
      const ws = XLSX.utils.json_to_sheet(headerRow);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `공정 업로드 양식`);
      XLSX.writeFile(wb, `공정 업로드 양식.xlsx`);
    }
  };

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>공정 업로드</ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              <ContentSection>
                <ButtonsSection>
                  <FormatButton onClick={downloadFormat}>
                    양식 다운
                  </FormatButton>

                  <input
                    type="file"
                    id="ProcessUploadModalInput"
                    style={{ display: 'none' }}
                    accept=".xlsx, .xls, .csv"
                    onChange={selectUploadFile}
                  />
                  <UploadModalButton htmlFor="ProcessUploadModalInput">
                    업로드
                  </UploadModalButton>
                </ButtonsSection>

                <NoticeSection>
                  <p>※ 순서 ※</p>
                  <p>1. 항목명을 한줄로 만들어주세요.</p>
                  <p>
                    2. 항목명을 겹치지 않게 수정해주세요. → 투입물품코드,
                    생산물품코드
                  </p>
                  <p>3. 데이터가 없는 줄은 삭제하고 업로드 해주세요.</p>
                  <p>4. 업로드</p>
                </NoticeSection>
              </ContentSection>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}

      {_loadingStatus === true ? (
        <LoadingScreen>공정을 업로드하고 있습니다...</LoadingScreen>
      ) : null}
    </>
  );
};

export default ProcessUploadModal;
