import styled from 'styled-components';

export const MarketFormModalComponent = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;

  &>section { // 모달 섹션
    background-color: var(--white);
    border-radius: 10px;
    display: grid;
    grid-template-rows: 55px auto 50px;
    height: 85%;
    max-height: 500px;
    max-width: 800px;
    overflow: hidden;
    width: 85%;

    & .header-area { // 모달 헤더
      align-items: center;
      background-color: var(--gray-100);
      box-sizing: border-box;
      display: flex;
      height: 55px;
      justify-content: flex-start;
      padding: 0px 20px;
      position: relative;
      width: 100%;

      & .title {
        font-size: 20px;
        font-weight: 400;
        white-space: nowrap;
      }
      & .close-btn {
        height: 25px;
        position: absolute;
        right: 15px;
        top: 15px;
        width: 25px;
      }
    }

    & .main-area { // 모달 메인
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;

      & form > div {background-color: unset;}
      & .formBox {width: 100%;}
    }

    & .footer-area { // 모달 푸터
      align-items: center;
      display: flex;
      height: 50px;
      justify-content: center;
      width: 100%;

      & div {
        align-items: center;
        background-color: var(--MainNavy);
        color: var(--white);
        cursor: pointer;
        display: flex;
        height: 50px;
        justify-content: center;
        width: 100%;
      }
    }
  }
`;