import React from 'react';
import { Outlet } from 'react-router-dom';
import { ClusterAPIInterceptor } from 'api/core/ClusterAPIInterceptor';

const ClusterRouteLayout = ({ children }) => {
  return (<>
    <ClusterAPIInterceptor></ClusterAPIInterceptor>
    <Outlet {...children} />
  </>);
};

export default ClusterRouteLayout;
