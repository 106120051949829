export const treeStyles = {
  tree: {
    base: {
      backgroundColor: 'var(--white)',
      color: 'var(--gray-700)',
      fontSize: '0.9em',
      listStyle: 'none',
      margin: 0,
      minWidth: 'fit-content',
      padding: 0,
      paddingRight: '16px',
    },
    node: {
      base: {
        position: 'relative',
        whiteSpace: 'pre',
      },
      activeLink: {
        backgroundColor: 'var(--white)',
        borderBottom: '1px solid var(--MainBlue)',
      },
      header: {
        base: {
          display: 'inline-block',
          verticalAlign: 'top',
          whiteSpace: 'pre',
          padding: '4px 0',
        },
        connector: {
          width: '2px',
          height: '12px',
          borderLeft: 'solid 2px black',
          borderBottom: 'solid 2px black',
          position: 'absolute',
          top: '0px',
          left: '-21px',
        },
        title: {
          lineHeight: '24px',
          verticalAlign: 'middle',
        },
      },
      link: {
        cursor: 'pointer',
        display: 'block',
        position: 'relative',
        padding: '0px 5px',
      },
      loading: {
        color: 'var(--gray-400)',
      },
      subtree: {
        listStyle: 'none',
        paddingLeft: '15px',
      },
      toggle: {
        base: {
          display: 'inline-block',
          height: '18px',
          marginLeft: '-5px',
          position: 'relative',
          verticalAlign: 'top',
          width: '24px',
        },
        arrow: {
          fill: 'var(--MainBlue)',
          strokeWidth: 0,
        },
        wrapper: {
          height: '14px',
          left: '50%',
          margin: '-8px 0 0 1px',
          position: 'absolute',
          top: '50%',
        },
        height: 8,
        width: 8,
      },
    },
  },
};
