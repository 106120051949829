const initialState = {
  contractType: 'all',
  contractStatus: 'all',
  startDate: undefined,
  endDate: undefined,
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,

  account: {},
  contractElementList: [],

  viewScale: 100,
};
const resetState = {
  contractType: 'all',
  contractStatus: 'all',
  startDate: undefined,
  endDate: undefined,
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const contractReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETCONTRACTTYPE':
      return {
        ...state,
        contractType: action.payload,
      };
    case 'SETCONTRACTSTATUS':
      return {
        ...state,
        contractStatus: action.payload,
      };
    case 'SETCONTRACTSTARTDATE':
      return {
        ...state,
        startDate: action.payload,
      };
    case 'SETCONTRACTENDDATE':
      return {
        ...state,
        endDate: action.payload,
      };
    case 'SETCONTRACTSEARCHOPTION':
      return {
        ...state,
        searchOption: action.payload,
      };
    case 'SETCONTRACTSEARCHTEXT':
      return {
        ...state,
        searchText: action.payload,
      };
    case 'SETCONTRACTPAGENUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'SETCONTRACTTOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };
    case 'SETCONTRACTISPUSHEDSEARCHBUTTON':
      return {
        ...state,
        isPushedSearchButton: action.payload,
      };
    case 'SETCONTRACTRESET':
      return {
        ...state,
        ...resetState,
      };

    case 'SETCONTRACTACCOUNT':
      return {
        ...state,
        account: action.payload,
      };

    case 'SETCONTRACTELEMENTLIST':
      return {
        ...state,
        contractElementList: action.payload,
      };

    case 'SETCONTRACTVIEWSCALE':
      return {
        ...state,
        viewScale: action.payload,
      };

    default:
      return state;
  }
};

export default contractReducer;

/*
actSelectStatus, 작업상태
actSelectStartDate, 시작일
actSelectEndDate, 끝일
props.actSelectStartDate(thisStartDate);
props.actSelectEndDate(thisEndDate);
actSelectSearchOption, 검색옵션
actInputSearchText, 검색바
resetPageNumber, 초기화
*/
