const initialState = {
  productType: '',

  currentFamilyId: 0,
  allFamilies: [],
  firstFamily: '',
  secondFamily: '',
  thirdFamily: '',
  fourthFamily: '',
  fifthFamily: '',
  firstFamilies: [],
  secondFamilies: [],
  thirdFamilies: [],
  fourthFamilies: [],
  fifthFamilies: [],


  workStatus: '',
  startDate: '',
  endDate: '',
  searchOption: '',
  searchText: '',
};

const resetState = {
  productType: '',

  currentFamilyId: 0,
  firstFamily: '',
  secondFamily: '',
  thirdFamily: '',
  fourthFamily: '',
  fifthFamily: '',
  firstFamilies: [],
  secondFamilies: [],
  thirdFamilies: [],
  fourthFamilies: [],
  fifthFamilies: [],

  workStatus: '',
  startDate: '',
  endDate: '',
  searchOption: '',
  searchText: '',
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETPRODUCTTYPE':
      return {
        ...state,
        productType: action.payload,
      };

    case 'SETCURRENTFAMILYID':
      return {
        ...state,
        currentFamilyId: action.payload,
      };

    case 'SETALLFAMILIES':
      return {
        ...state,
        allFamilies: action.payload,
      };

    case 'SETFIRSTFAMILY':
      return {
        ...state,
        firstFamily: action.payload,
      };

    case 'SETFIRSTFAMILIES':
      return {
        ...state,
        firstFamilies: action.payload,
      };

    case 'SETSECONDFAMILY':
      return {
        ...state,
        secondFamily: action.payload,
      };

    case 'SETSECONDFAMILIES':
      return {
        ...state,
        secondFamilies: action.payload,
      };

    case 'SETTHIRDFAMILY':
      return {
        ...state,
        thirdFamily: action.payload,
      };

    case 'SETTHIRDFAMILIES':
      return {
        ...state,
        thirdFamilies: action.payload,
      };
    /* 패밀리 4*/
    case 'SETFOURTHFAMILY':
      return {
        ...state,
        fourthFamily: action.payload,
      };
      
    case 'SETFOURTHFAMILIES':
      return {
        ...state,
        fourthFamilies: action.payload,
      };
    /* 패밀리 5*/
    case 'SETFIFTHFAMILY':
      return {
        ...state,
        fifthFamily: action.payload,
      };
      
    case 'SETFIFTHFAMILIES':
      return {
        ...state,
        fifthFamilies: action.payload,
      };

    case 'SETWORKSTATUS':
      return {
        ...state,
        workStatus: action.payload,
      };

    case 'SETSTARTDATE':
      return {
        ...state,
        startDate: action.payload,
      };

    case 'SETENDDATE':
      return {
        ...state,
        endDate: action.payload,
      };

    case 'SETSEARCHOPTION':
      return {
        ...state,
        searchOption: action.payload,
      };

    case 'SETSEARCHTEXT':
      return {
        ...state,
        searchText: action.payload,
      };

    case 'SEARCHRESET':
      return {
        ...state,
        ...resetState,
      };

    default:
      return state;
  }
};

export default searchReducer;
