import React from 'react';

import { IoClose } from "react-icons/io5";
import { CommentComponent } from './Comment.style';
import PurchaseCommentForm from '../mypage/board/purchase/PurchaseCommentForm';
import SalesCommentForm from '../mypage/board/sales/SalesCommentForm';

const CommentForm = ({
  modalType, modalData,
  update,
  open, close
}) => {
  const handleModalTitle = (type) => { // 모달 타이틀 설정
    const titleType = `${type.split('-')[1]}-${type.split('-')[2]}`;
    switch (titleType) {
      case 'comment-create': return '신청'; case 'comment-update': return '수정'; case 'comment-delete': return '삭제';

      default: return '';
    }
  }
  const handleModalContent = (type) => { // 모달 컨텐츠 설정
    if (type.split('-')[0] === 'purchase') { // 공동 구매
      return (<PurchaseCommentForm modalType={modalType} modalData={modalData} update={update} close={close} />);
    } else if (type.split('-')[0] === 'sales') { // 상호 거래
      return (<SalesCommentForm modalType={modalType} modalData={modalData} update={update} close={close} />);
    }
  }

  return (
    <>
      {open && (
        <CommentComponent className='form'>
          <header>
            <h1>{handleModalTitle(modalType)}</h1>
            <button className='btn-close' onClick={close}><IoClose /></button>
          </header>
          <div>
            {handleModalContent(modalType)}
          </div>
        </CommentComponent>
      )}
    </>
  );
};

export default CommentForm;