import React from 'react';

import { UserModalComponent } from './UserModal.style';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';

import DownloadContent from './content/DownloadContent';
import TeamContent from './content/TeamContent';
import WorkerCreateContent from './content/WorkerCreateContent';
import WorkerUpdateContent from './content/WorkerUpdateContent';
import UpdateContent from './content/UpdateContent';
import DeleteContent from './content/DeleteContent';

const UserModal = ({
  open, close,
  modalType,
  modalData,
}) => {
  const translateType = (type) => {
    switch(type) {
      case 'download': return '사용자 리스트 다운로드';
      case 'team': return '팀 변경';
      case 'workerCreate': return '작업자 추가';
      case 'workerUpdate': return '작업자 정보 수정';
      case 'workerDelete': return '작업자 삭제';
      case 'update': return '사용자 정보 수정';
      case 'delete': return '사용자 삭제';

      default: return '';
    }
  }
  const typeContent = (type) => {
    switch(type) {
      case 'download': return <DownloadContent close={close} modalData={modalData} />;
      case 'team': return <TeamContent close={close} modalData={modalData} />;
      case 'workerCreate': return <WorkerCreateContent close={close} modalData={modalData} />;
      case 'workerUpdate': return <WorkerUpdateContent close={close} modalData={modalData} />;
      case 'workerDelete': return <DeleteContent type={modalType} close={close} modalData={modalData} />;
      case 'update': return <UpdateContent close={close} modalData={modalData} />;
      case 'delete': return <DeleteContent type={modalType} close={close} modalData={modalData} />;

      default: return <></>;
    }
  }

  return (
    <>
    {open && (
      <UserModalComponent>
        <div className={modalType}>
          <header>
            <h4>{translateType(modalType)}</h4>
            <div onClick={() => {close();}}><CloseButtonIcon /></div>
          </header>
          {typeContent(modalType)}
        </div>
      </UserModalComponent>
    )}
    </>
  );
};

export default UserModal;