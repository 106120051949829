import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { daerimReducer_setCopyContent, daerimReducer_setEquipment, daerimReducer_setProcessUser, daerimReducer_setUser, daerimReducer_setWorkOrderContent, daerimReducer_setWorkOrderContents } from 'store/modules/actions/company/daerimActions';
import { failedProductionOrderActions_setEndDate, failedProductionOrderActions_setPageNumber, failedProductionOrderActions_setReset, failedProductionOrderActions_setSearchData, failedProductionOrderActions_setSearchOption, failedProductionOrderActions_setSearchText, failedProductionOrderActions_setStartDate, failedProductionOrderActions_setTotalSize, failedProductionOrderActions_setWorkOrderType } from 'store/modules/actions/common/failedProductionOrderActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { workerReducer_resetWorkerReducer } from 'store/modules/actions/common/workerActions';

import { failedWorkOrderLogApi } from 'api/apis/failedWorkOrderLogApi';
import { workOrderTypeApi } from 'api/apis/workOrderTypeApi';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

import { DateFormat } from 'components/format/DateFormat';
import { LoadingMsg } from 'components/loading/LoadingMsg';

import FailedWorkRow from 'components/row/FailedWorkRow';
import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import ProductionProcessTracking from 'pages/qualityControl/modal/ProductionProcessTracking';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import ShowFileList from 'pages/file/modal/ShowFileList';
import ShowWorkOrderLog from 'pages/productionOrder/modal/ShowWorkOrderLog';
import TableSection from 'components/layouts/table/TableSection';

const Section = styled.main`
  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
    & .typeButtons {
      display: flex;
      gap: 5px;
    }
  }

  .TableSection {
    thead tr {border: none;}
    td {height: 100px;}
  }
`;

const FailedProductionOrder = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { failedProductionOrderReducer, userReducer, pageReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_workOrderLogList, setWorkOrderLogList] = useState([]);

  const [_workOrderTypes, setWorkOrderTypes] = useState([]);

  const [_processModalStatus, setProcessModalStatus] = useState(false); // 공정 리스트
  const [_showWokrOrderLog, setShowWorkOrderLog] = useState();
  const [_showProcessList, setShowProcessList] = useState([]);

  const [_trackingModalStatus, setTrackingModalStatus] = useState(false); // 생산추적
  const [_trackingWorkOrderLogId, setTrackingWorkOrderLogId] = useState();

  const [_fileListModalStatus, setFileListModalStatus] = useState(false);
  const [_eventcontent, setEventContent] = useState({});

  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('104') === true || // 거래처
      thisItem.authority.startsWith('108') === true || // 설비
      thisItem.authority.startsWith('109') === true || // 파일
      thisItem.authority.startsWith('411') === true, // 불량재작업
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getWorkOrderTypes();
    getWorkOrderLogList(failedProductionOrderReducer.pageNumber - 1, failedProductionOrderReducer.workOrderType);
    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getWorkOrderLogList(failedProductionOrderReducer.pageNumber - 1, failedProductionOrderReducer.workOrderType);
    }

    return () => { };
  }, [failedProductionOrderReducer.pageNumber]);

  /* ========================================================================= #4 */
  const getWorkOrderTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await workOrderTypeApi.searchWorkOrderType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderTypeApi.searchWorkOrderType : ', response);
      setWorkOrderTypes(() => { return response.data; });
    });
  };

  const getWorkOrderLogList = async (page, workOrderType, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      workOrderStatus: ['end'],
    };

    if (!clear && checkEmptyNull(workOrderType, false) && workOrderType !== 'all') {
      BodyToPost.workOrderTypeId = Number(workOrderType);
      // BodyToPost.workOrderLogId = Number(workOrderType);
    }
    if (!clear && checkEmptyNull(failedProductionOrderReducer.startDate, false) && checkEmptyNull(failedProductionOrderReducer.endDate, false)) {
      BodyToPost.actualStartDate = DateFormat(failedProductionOrderReducer.startDate);
      BodyToPost.actualEndDate = DateFormat(failedProductionOrderReducer.endDate);
      if (failedProductionOrderReducer.startDate > failedProductionOrderReducer.endDate) {
        BodyToPost.actualStartDate = DateFormat(failedProductionOrderReducer.endDate);
        BodyToPost.actualEndDate = DateFormat(failedProductionOrderReducer.startDate);
      }
    } else if (!clear && checkEmptyNull(failedProductionOrderReducer.startDate, false) && !checkEmptyNull(failedProductionOrderReducer.endDate, false)) {
      BodyToPost.actualStartDate = DateFormat(failedProductionOrderReducer.startDate);
    } else if (!clear && checkEmptyNull(failedProductionOrderReducer.endDate, false) && !checkEmptyNull(failedProductionOrderReducer.startDate, false)) {
      BodyToPost.actualEndDate = DateFormat(failedProductionOrderReducer.endDate);
    }
    if (!clear && checkEmptyNull(failedProductionOrderReducer.searchData.workOrderCode, false)) {
      BodyToPost.workOrderCode = failedProductionOrderReducer.searchData.workOrderCode;
    }
    if (!clear && checkEmptyNull(failedProductionOrderReducer.searchData.workOrderName, false)) {
      BodyToPost.workOrderName = failedProductionOrderReducer.searchData.workOrderName;
    }
    await failedWorkOrderLogApi.searchFailedWorkOrderLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('failedWorkOrderLogApi.searchFailedWorkOrderLog : ', response);

      setWorkOrderLogList(() => { return response.data.content; });
      dispatch(failedProductionOrderActions_setTotalSize(response.data.totalElements));

      if (checkEmptyNull(failedProductionOrderReducer.startDate, false) && checkEmptyNull(failedProductionOrderReducer.endDate, false)) {
        if (failedProductionOrderReducer.startDate > failedProductionOrderReducer.endDate) {
          dispatch(failedProductionOrderActions_setStartDate(failedProductionOrderReducer.endDate))
          dispatch(failedProductionOrderActions_setEndDate(failedProductionOrderReducer.startDate))
        }
      }
    });
  };

  /* ========================================================================= #5 */
  /* 검색 */
  const actSearch = async () => {
    dispatch(failedProductionOrderActions_setPageNumber(1));
    await getWorkOrderLogList(0, failedProductionOrderReducer.workOrderType);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(failedProductionOrderActions_setReset());
    getWorkOrderLogList(0, 'all', 'all', true);
  };

  /* 공정 리스트 */
  const showProcessList = async (workOrderLog) => {
    setShowWorkOrderLog(workOrderLog.workOrderLogId);
    setShowProcessList(workOrderLog);
    setTimeout(setProcessModalStatus(true), 1000);
  };

  /* 생산추적 */
  const showProductionProcessTracking = (workOrderLog) => {
    setTrackingWorkOrderLogId(workOrderLog.workOrderLogId);
    setTimeout(setTrackingModalStatus(true), 1000);
  };

  /* 재작업 */
  const actRecreate = async () => {
    if (document.querySelectorAll('input[name="failedWorkOrder"]:checked').length < 1) return;
    if (document.querySelectorAll('input[name="failedWorkOrder"]:checked').length > 1) return;

    const checkedItem = document.querySelector('input[name="failedWorkOrder"]:checked');
    const workOrderLogId = checkedItem.dataset.key;

    await workOrderLogApi.getWorkOrderLog(workOrderLogId).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.getWorkOrderLog : ', response);

      dispatch(workerReducer_resetWorkerReducer());

      dispatch(daerimReducer_setEquipment({}));
      dispatch(daerimReducer_setUser({}));
      dispatch(daerimReducer_setProcessUser({}));
      dispatch(daerimReducer_setWorkOrderContent({}));
      dispatch(daerimReducer_setWorkOrderContents([]));

      dispatch(daerimReducer_setCopyContent(response.data));

      setTimeout(navigate(pageReducer.currentPage + '/recreate', { replace: true }), 1000);
    });
  };

  const showFileList = (content, work) => {
    console.log('showFileList : ', work, content);
    setEventContent(() => {
      if (work === true) return { ...content, work: true };
      else return content;
    });
    setTimeout(setFileListModalStatus(true), 1000);
  };

  /* ========================================================================= #6 */

  return (
    <Grid3Body contents={
      <>
        <Section className="Main">
          <NavBar
            title={
              <>
                <NavTitle menuCode={'411'} />
                {/* <div className='Title'>
                  <NavTitle menuCode={'411'} />
                  <div className='typeButtons' style={{display: 'flex'}}>
                    <button
                      className={failedProductionOrderReducer.workOrderType === "all" ? 'btn-inven-1' : 'btn-set'}
                      key={'all' + '_workOrderType'}
                      value='all'
                      onClick={(e) => {
                        dispatch(failedProductionOrderActions_setWorkOrderType("all"));
                        dispatch(failedProductionOrderActions_setPageNumber(1));
                        getWorkOrderLogList(0, "all", "all", failedProductionOrderReducer.isPushedSearchButton);
                      }}
                    >
                      전체
                    </button>
                    {_workOrderTypes.map((type) => {
                      return (
                        <button
                          className={failedProductionOrderReducer.workOrderType === type ? 'btn-inven-1' : 'btn-set'}
                          key={type.workOrderTypeId + '_workOrderType'}
                          value={type.workOrderTypeId}
                          onClick={(e) => {
                            dispatch(failedProductionOrderActions_setWorkOrderType(e.target.value && type));
                            dispatch(failedProductionOrderActions_setPageNumber(1));
                            getWorkOrderLogList(0, e.target.value, failedProductionOrderReducer.workOrderStatus, failedProductionOrderReducer.isPushedSearchButton);
                          }}
                        >
                          {type.workOrderTypeName}
                        </button>
                      );
                    })}
                  </div>
                </div> */}
              </>
            }
            buttons={
              <>
                {_authority.indexOf('411-2') !== -1 ? <button className='btn-restart' onClick={actRecreate}>재작업</button> : null}
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <select
                    className="SelectType"
                    value={failedProductionOrderReducer.workOrderType}
                    onChange={(e) => {
                      dispatch(failedProductionOrderActions_setWorkOrderType(e.target.value));
                      dispatch(failedProductionOrderActions_setPageNumber(1));
                      getWorkOrderLogList(0, e.target.value);
                    }}
                  >
                    <option value="all">전체</option>
                    {_workOrderTypes.map((thisItem) => {
                      return (
                        <option key={thisItem.workOrderTypeId + '_workOrderType'} value={thisItem.workOrderTypeId}>
                          {thisItem.workOrderTypeName}
                        </option>
                      );
                    })}
                  </select>
                  <div className="SearchSection">
                    <input
                      type="date"
                      className="SelectDate"
                      value={failedProductionOrderReducer.startDate}
                      onChange={(e) => {
                        dispatch(failedProductionOrderActions_setStartDate(e.target.value));
                      }}
                    />

                    <IconRightArrow />

                    <input
                      type="date"
                      className="SelectDate"
                      value={failedProductionOrderReducer.endDate}
                      onChange={(e) => {
                        dispatch(failedProductionOrderActions_setEndDate(e.target.value));
                      }}
                    />
                  </div>
                </div>
              </>
            }
            secondRow={
              <div className="SearchSection">
                <div className="ContentCBox">
                  <select className="SearchOption detail">
                    <option value="workOrderCode">작업코드</option>
                  </select>
                  <input
                    className="SearchBar detail"
                    placeholder="Search..."
                    value={checkEmptyNull(failedProductionOrderReducer.searchData.workOrderCode, '')}
                    onInput={(e) => {
                      dispatch(failedProductionOrderActions_setSearchData({ ...failedProductionOrderReducer.searchData, workOrderCode: e.target.value }));
                    }}
                  />
                </div>

                <div className="ContentCBox">
                  <select className="SearchOption detail">
                    <option value="workOrderName">작업이름</option>
                  </select>
                  <input
                    className="SearchBar detail"
                    placeholder="Search..."
                    value={checkEmptyNull(failedProductionOrderReducer.searchData.workOrderName, '')}
                    onInput={(e) => {
                      dispatch(failedProductionOrderActions_setSearchData({ ...failedProductionOrderReducer.searchData, workOrderName: e.target.value }));
                    }}
                  />
                </div>

                <div className="ContentCBox">
                  <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                  <div className="ResetButton" onClick={actReset}>초기화</div>
                </div>
              </div>
            }
          />

          <TableSection content={
            <table>
              <thead>
                <tr>
                  <th rowSpan={2} style={{ minWidth: '50px', width: '50px' }}></th>
                  <th rowSpan={2} style={{ minWidth: '100px', padding: 'unset', width: '100px' }}>
                    생산추적
                  </th>
                  <th rowSpan={2} style={{ minWidth: '100px', padding: 'unset', width: '100px' }}>
                    공정리스트
                  </th>
                  {(() => {
                    if (_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) {
                      return <th rowSpan={2}>파일</th>;
                    } else return null;
                  })()}
                  <th rowSpan={2}>작업유형</th>
                  <th rowSpan={2}>작업코드</th>
                  <th rowSpan={2}>작업이름</th>
                  {(() => {
                    if (_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) {
                      return <th rowSpan={2}>거래처</th>;
                    } else return null;
                  })()}
                  <th colSpan={5} style={{ backgroundColor: 'var(--ThirdBlue)' }}>
                    생산목표품목
                  </th>
                  <th rowSpan={2}>작업기간</th>
                  <th rowSpan={2}>작성자</th>
                  <th rowSpan={2}>책임자</th>
                  <th rowSpan={2}>작업자</th>
                </tr>
                <tr>
                  <th>물품정보</th>
                  <th className="resultProductStyle">목표 생산량</th>
                  <th className="resultProductStyle" style={{ backgroundColor: 'var(--MainBlue)' }}>총 생산량</th>
                  <th className="resultProductStyle" style={{ backgroundColor: 'var(--MainGreen)' }}>성공수량</th>
                  <th className="resultProductStyle" style={{ backgroundColor: 'var(--MainRed)' }}>실패수량</th>
                </tr>
              </thead>
              <tbody>
                {!checkNullArray(_workOrderLogList, false) ? LoadingMsg() :
                  checkNullArray(_workOrderLogList, []).map((thisItem, index) => {
                    return (
                      <FailedWorkRow
                        key={index + '_failedWorkRow'}
                        authority={_authority}
                        content={thisItem}
                        showFileList={showFileList}
                        showProductionProcessTracking={showProductionProcessTracking}
                        showProcessList={showProcessList}
                      />
                    );
                  })}
              </tbody>
            </table>
          }
            sectionStyle={{ height: 'calc(100% - 66px)' }}
          ></TableSection>
        </Section>

        <PagingComponent
          page={failedProductionOrderReducer.pageNumber}
          count={failedProductionOrderReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(failedProductionOrderActions_setPageNumber(page));
          }}
        />

        {_processModalStatus === true && (
          <ShowWorkOrderLog
            status={false}
            workOrderLogId={_showWokrOrderLog}
            processList={_showProcessList}
            open={_processModalStatus}
            close={() => { setProcessModalStatus(false); }}
          />
        )}

        {_trackingModalStatus === true && (
          <ProductionProcessTracking
            productionId={_trackingWorkOrderLogId}
            open={_trackingModalStatus}
            close={() => { setTrackingModalStatus(false); }}
          />
        )}

        {_fileListModalStatus === true &&
          _eventcontent.workOrderLogId !== undefined ? (
          <ShowFileList
            type={'workOrderLog'}
            content={_eventcontent}
            open={_fileListModalStatus}
            close={() => { setFileListModalStatus(false); }}
          />
        ) : null}
      </>
    }
    />
  );
};

export default FailedProductionOrder;
