import React from 'react';
import styled from 'styled-components';

import GuideText from './GuideText';

const StatusTdButtonStyle = styled.td`
  box-sizing: border-box;
  font-size: ${(props) => {
    if (props.statusEvent !== undefined && props.statusEvent !== null) return '1.2em';
    else return '20px';
  }};
  font-weight: 600;
  height: 100%;
  width: 100%;

  background-color: ${(props) => {
    switch (props.status) {
      case 'wait': return 'var(--gray-900)';
      case 'waiting': return 'var(--gray-900)';
      case 'work': return 'var(--WorkingColor)';
      case 'working': return 'var(--WorkingColor)';
      case 'proceeding': return 'var(--WorkingColor)';
      case 'incomplete': return 'var(--ThirdBlue)';
      case 'end': return 'var(--ThirdBlue)';
      case 'stop': return 'var(--MainRed)';
      case 'pause': return 'var(--MainYellow)';
      case 'cancel': return 'var(--Bg)';

      case 'inspection': return 'var(--MainRed)';

      default: return null;
    }
  }};

  color: ${(props) => {
    switch (props.status) {
      case 'cancel': return 'var(--gray-200)'

      default: return 'var(--white)';
    }
  }};

  ${(props) => {
    switch (props.status) {
      case 'wait': return 'animation: WaitingStatus 1.5s infinite;'
      case 'waiting': return 'animation: WaitingStatus 1.5s infinite;'

      default: return null;
    }
  }};

  ${(props) => {
    switch (props.status) {
      // case 'incomplete': return 'cursor: default;'
      case 'end': return 'cursor: default;'
      case 'stop': return 'cursor: default;'
      case 'cancel': return 'cursor: default;'

      default: return 'cursor: pointer';
    }
  }};
`;

const StatusTdButton = (props) => {
  return (
    <StatusTdButtonStyle
      rowSpan={props.rowSpan}
      style={props.statusStyle}
      status={props.status}
      onClick={props.statusEvent}
    >
      {(() => {
        switch (props.status) {
          case 'wait': return '대기';
          case 'waiting': return '대기';
          case 'work': return '작업중';
          case 'working': return '작동중';
          case 'proceeding': return '진행';
          case 'incomplete': return '완료';
          case 'end': return '완료';
          case 'stop': return '중단';
          case 'pause': return '일시정지';
          case 'cancel': return '취소';

          case 'inspection': return '점검';

          default: return null;
        }
      })()}

      {(() => {
        if (
          props.statusEvent !== undefined &&
          props.statusEvent !== null &&
          // props.status !== 'incomplete' &&
          props.status !== 'end' &&
          props.status !== 'cancel'
        ) {
          return <GuideText />;
        } else {
          return null;
        }
      })()}
    </StatusTdButtonStyle>
  );
};

export default StatusTdButton;
