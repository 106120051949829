import React from 'react';

const CheckBox = (props) => {
  return (
    <input type="checkbox" name={ props.name } data-key={ props.checkboxKey } 
    defaultChecked={ props.checked !== undefined ? props.checked === true ? true : false : false }
    onChange={ (e) => {
      if(props.all === true)
      {
        let CheckedAllCheck = true;
        let CheckedAllCheckCount = 0;

        let AllCheckBoxID = 'AllCheckBox' + props.name;
  
        document.getElementsByName(e.target.name).forEach(ThisCheckBox =>
        {
            CheckedAllCheckCount++;
            
            if(!ThisCheckBox.checked)
              CheckedAllCheck = false;
  
            if(CheckedAllCheckCount === document.getElementsByName(e.target.name).length)
            {
                if(CheckedAllCheck === true)
                    document.getElementById(AllCheckBoxID).checked = true;
                else
                  document.getElementById(AllCheckBoxID).checked = false;
            }
        })
      }

      if(props.act === 'one')
      {
        if(e.target.checked)
        {
          document.getElementsByName(e.target.name).forEach(ThisCheckBox => ThisCheckBox.checked = false);
          e.target.checked = true;
        }
        else
          e.target.checked = false;
      }
    } } />
  );
};

export default CheckBox;