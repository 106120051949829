import React from 'react';
import styled from 'styled-components';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';

const GridBody = styled.div`
  display: block;
  grid-template-rows: unset;
  height: 100svh;
  /* overflow: hidden; */
  position: relative;
  width: 100svw;
  .monitoringBody {
    display: grid;
    grid-template-rows: calc(100% - 20px) 20px;
    height: 100%;
    /* overflow: hidden; */
    width: 100%;
    .subContents {
      zoom: 80%;
      display: grid; gap: 24px;
      height: 100%;
      justify-content: flex-start;
      grid-template-columns: 100%;
      /* width: 100%; */
      margin: 24px;
      .TableSection {
        width: 100%;
        border-radius: 10px;
        /* max-height: 500px; */
        box-shadow: 0 6px 6px 0 rgba(196,196,196,0.8);
      }
    }
  }
  .monitoringBody .Main::-webkit-scrollbar {display: none;}
`;

const GridMonitoringBody = (props) => {
  return (
    <>
      <GridBody>
        {props.header}
        {props.contents}
      </GridBody>
      <Footer footerStyle={props.footerStyle} />
    </>
  );
};

export default GridMonitoringBody;