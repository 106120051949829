import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { elementApi } from 'api/apis/elementApi';
import { fileDataApi } from 'api/apis/fileDataApi';

import { checkNullArray, checkNullParse } from 'components/checkValues/checkValues';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

const DeleteNaraElement = () => {
  /* ========================================================================= #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();

  const deleteContents = checkNullParse(searchParams.get('deleteContents'), []);
  const [_deleteContents, setDeleteContents] = useState(deleteContents);

  /* ====================================================================== #3 */
  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  let delCount = 0;
  const actAllDelete = () => {
    deleteContents.forEach(async (thisItem) => {
      console.log('deleteContent : ', thisItem.deleteContent);
      await actDelete(thisItem.deleteContent);
    });
  };

  const actDelete = async (element) => {
    const actDelete_element = async () => {
      await elementApi.deleteElement(element.elementId).then((response) => {
        if (response === undefined) return;

        const contents = [..._deleteContents];
        const thisFindIndex = contents.findIndex((thisIndex) => thisIndex.elementId === element.elementId);
        setDeleteContents(() => {
          contents.splice(thisFindIndex, 1);
          return contents;
        });
      }).finally(() => {
        delCount++;
        if (delCount === deleteContents.length) {
          alert('물품을 삭제했습니다.');
          setTimeout(navigate(pageReducer.currentPage, { replace: true }), 1000);
        }
      });
    };

    let count = 0;
    const actDelete_fileData = async (fileDataId) => {
      await fileDataApi.deleteFileData(fileDataId).then((response) => {
        if (response === undefined) return;
        console.log('fileDataApi.deleteFileData : ', response);
      }).finally(() => {
        count++;
        if (count === element.fileDataList.length) {
          actDelete_element();
        }
      });
    };

    checkNullArray(element.fileDataList, false)
      ? element.fileDataList.forEach(async (thisItem) => {
        await actDelete_fileData(thisItem.fileDataId);
      })
      : actDelete_element();
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body
      contents={
        <>
          <main className="Main">
            <NavBar
              title={<NavTitle menuCode={'101'} />}
              nav={''}
            />

            <DeleteForm
              delteTitle={<NavTitle menuCode={'101'} />}
              deleteItem={_deleteContents.map((thisItem) => {
                const deleteContent = thisItem.deleteContent;
                return (<Fragment key={deleteContent.elementId + '_deleteContent'}>
                  <p><b>물품이름:</b> {deleteContent.elementName}</p>
                  <p><b>물품코드:</b> {deleteContent.elementCode}</p>
                  <br />
                </Fragment>);
              })}
              deleteText={
                <>
                  물품정보와 관련된 <span className="cautionText">모든 기준정보 및 재고, 공정, 작업지시, 작업지시, 작업실적 등</span>이 삭제됩니다.
                </>
              }
              deleteButton={
                <>
                  <button className="formButton cancle"
                    onClick={() => { navigate(pageReducer.currentPage, { replace: true }) }}
                  >
                    취소
                  </button>
                  <button className="formButton delete"
                    onClick={() => {
                      if (window.confirm('물품정보를 삭제합니다.')) actAllDelete();
                      else return;
                    }}
                  >
                    삭제
                  </button>
                </>
              }
            />
          </main>
        </>
      }
    />
  );
};

export default DeleteNaraElement;
