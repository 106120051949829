import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { company } from 'api/apis/operator/company';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';

const DeleteCompany = () => {
  const navigate = useNavigate();
  const { operatorReducer, pageReducer } = useSelector((state) => state);

  const [_formData, setFormData] = useState({ ...operatorReducer.deleteCompany });

  const actDelete = async () => {
    if (operatorReducer.deleteCompany.companyId === 1) {
      alert('어드민 회사로 삭제가 불가합니다.');
      return navigate(pageReducer.currentPage, { replace: true });
    }
    await company.deleteCompany(_formData.companyId).then((response) => {
      if (response === undefined) return;
      alert('회사를 삭제했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    })
  };

  window.onkeydown = (e) => { // F5 막기
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  };

  return (
    <Grid2Body contents={
      <main className="Main">
        <NavBar title={'회사관리'} nav={''} />

        <DeleteForm
          delteTitle={'회사'}
          deleteItem={<>
            {'companyId : ' + _formData.companyId}<br />
            {'companyName : ' + _formData.companyName}<br />
          </>}
          deleteText={<>
            <span className="cautionText">회사의 모든 정보가 삭제됩니다.</span>
          </>}
          deleteButton={<>
            <button className="formButton cancle"
              onClick={() => { navigate(pageReducer.currentPage, { replace: true }); }}
            >
              취소
            </button>
            <button className="formButton delete"
              onClick={() => {
                if (window.confirm('회사를 삭제합니다.')) actDelete();
                else return;
              }}
            >
              삭제
            </button>
          </>}
        />
      </main>
    }
    />
  );
};

export default DeleteCompany;
