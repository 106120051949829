import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { mallSiteUserActions_setUser } from 'store/modules/actions/mallSite/mallSiteUserActions';
import { userReducer_setUser } from 'store/modules/actions/user/userActions';

import { companyApi } from 'api/apis/companyApi';
import { userApi } from 'api/apis/userApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

const Content = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;

  & .content-main {
    box-sizing: border-box;
    display: grid;
    grid-template-rows: 50px 50px;
    justify-items: center;
    margin: 0px auto;
    row-gap: 40px;
    transform: translateY(-30px);
    width: 240px;

    & .content-title {
      align-items: flex-end;
      color: var(--white);
      display: flex;
      font-size: 18px;
      font-weight: 600;
      justify-content: center;
    }
    & input, & select {
      box-sizing: border-box;
      font-size: 18px;
      font-weight: 600;
      height: 50px;
      padding: 0px 10px;
      width: 300px;
    }
    & .content-btn {
      align-items: center;
      background-color: var(--white);
      border: none;
      border-radius: 25px;
      color: var(--MainNavy);
      cursor: pointer;
      display: flex;
      font-size: 18px;
      font-weight: 900;
      height: 50px;
      justify-content: center;
      text-align: center;
      width: 240px;
      
      &:hover {
        background: unset;
        background-color: var(--white);
      }
    }
  }
`;

const UpdateMasterCompany = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_currentCompany, setCurrentComapny] = useState({});
  const [_companyList, setCompanyList] = useState([]);
  const [_user, setUser] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    if (userReducer.user.role !== 'ROLE_MASTER') {
      alert('권한이 없습니다.')
      return navigate('/', { replace: true });
    } else getCompanyList();

    setOnload('loaded');
    return () => { };
  }, []);
  useEffect(() => { }, [_user])

  /* ====================================================================== #4 */
  const getCompanyList = async () => { // 회사 리스트 불러오기
    // paging: '', body: {} 빈 값 보내야 함!! >> 없는 경우 에러 뜸
    await companyApi.searchCompanies('', {}).then((response) => {
      if (response === undefined) return;
      setCompanyList(() => { return response.data });
      getMyData(response.data);
    });
  };
  const getMyData = async (companyList) => { // 내 정보 불러오기
    await userApi.getUserMe().then((response) => {
      if (response === undefined) return;
      // 회사 정보
      const findIndex = companyList.findIndex((company) => company.companyId === response.data.companyId);
      const companyData = companyList[findIndex];
      setCurrentComapny(() => { return companyData });

      // 내 정보
      setUser(response.data);
    });
  };

  /* ====================================================================== #5 */
  const selectCompany = (e) => { // 회사 선택 이벤트
    const companyId = Number(e.target.value);
    setUser({ ..._user, companyId: companyId });
  };

  const updateMasterCompany = async () => { // 회사 변경
    if (!checkEmptyNull(_user.companyId, false)) return alert('회사를 선택해주세요.');

    // const findIndex = _companyList.findIndex((company) => company.companyId === _user.companyId);
    // const companyData = _companyList[findIndex];
    const body = { companyId: _user.companyId };

    await userApi.updateUserMe(body).then((response) => {
      if (response === undefined) return;
      // 회사 변경한 경우 >> 유저 정보(reducer) 삭제 한 후 로그인 화면으로 보냄!!
      dispatch(userReducer_setUser({}));
      dispatch(mallSiteUserActions_setUser({}));
      navigate('/', { replace: true });
    }).catch((error) => {
      console.log('put/user/selfCompany-error :', error);
    });
  };

  /* ====================================================================== #6 */

  return (
    <Content>
      <div className='content-main'>
        <div className='content-title'>현재 회사: {_currentCompany?.companyName || ''}</div>

        <select defaultValue="" onChange={selectCompany}>
          <option value="" disabled>회사선택</option>
          {checkNullArray(_companyList, []).map((company) => (
            <option key={company.companyId} value={company.companyId}>{company.companyId} - {company.companyName}</option>
          ))}
        </select>

        <button className='content-btn' onClick={updateMasterCompany}>변경하기</button>
      </div>
    </Content>
  );
};

export default UpdateMasterCompany;
