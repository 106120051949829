import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { stockPlace } from 'api/apis/stock/stockPlace';

import { checkEmptyNull, checkNull } from 'components/checkValues/checkValues';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import NavBar from 'components/nav/NavBar';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const ModalHeaderButtons = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 55px;
`;
const ModalHeaderButton = styled.button``;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const SelectWorkPlaceModal = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');
  const [_workPlaceList, setWorkPlaceList] = useState([]);
  const [_searchData, setSearchData] = useState({});
  const [_checkedItem, setCheckedItem] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    getWorkPlaceList();

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => { }, [_checkedItem]);

  /* ====================================================================== #4 */
  const getWorkPlaceList = async () => {
    const body = { companyId: userReducer.company.companyId, type: 'Work' };
    if (checkEmptyNull(_searchData.placeName, false)) body.placeName = _searchData.placeName;
    await stockPlace.searchStockPlace(body).then((response) => {
      if (response === undefined) return;
      console.log('stockPlace.searchStockPlace : ', response);
      setWorkPlaceList(() => { return response.data; });
    });
  };

  /* ====================================================================== #5 */
  const actSearch = async () => {
    await getWorkPlaceList();
  };
  const actReset = async () => {
    setSearchData(() => { return {}; });
    await getWorkPlaceList();
  };

  const actCreate = async () => {
    const promptText = `추가할 작업 위치를 입력해주세요.`;
    const CreatePrompt = window.prompt(promptText, '');
    if (CreatePrompt === null) return;

    const body = {
      companyId: userReducer.company.companyId,
      type: 'Work',
      placeName: CreatePrompt,
    };
    await stockPlace.createStockPlace(body).then((response) => {
      if (response === undefined) return;
      console.log('stockPlace.createStockPlace : ', response);
      getWorkPlaceList();
      alert('작업 위치를 추가했습니다.');
    });
  }

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>작업 위치 선택</ModalTitle>
              <ModalHeaderButtons>
                <ModalHeaderButton className="formButton" onClick={(e) => { e.preventDefault(); actCreate(); }}>위치 추가</ModalHeaderButton>
              </ModalHeaderButtons>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <NavBar
                title={
                  <div className="SearchSection">
                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="processName">작업 위치</option>
                      </select>
                      <input
                        className="SearchBar detail"
                        placeholder="Search..."
                        value={checkNull(_searchData.placeName, '')}
                        onInput={(e) => { setSearchData((prev) => { return { ...prev, placeName: e.target.value }; }); }}
                      />
                    </div>

                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton" onClick={actReset}>초기화</div>
                  </div>
                }
                nav={''}
              />

              <TableSection content={
                <table>
                  <thead>
                    <tr style={{ border: 'none' }}>
                      <th style={{ minWidth: '50px', width: '50px' }}></th>
                      <th>작업 위치</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_workPlaceList.map((place, index) => {
                      return (
                        <tr key={index + '_workPlace'}>
                          <td style={{ minWidth: '50px', width: '50px' }}>
                            <input
                              type="checkBox"
                              name="stockPlaces"
                              // checked={place.stockPlaceId === _checkedItem.stockPlaceId}
                              onChange={(e) => {
                                setCheckedItem(() => { return place; });
                                document.querySelectorAll('input[name="stockPlaces"]').forEach(item => item.checked = false);
                                e.target.checked = true;
                              }}
                            />
                          </td>
                          <td>{place.placeName}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              }
              ></TableSection>
            </ModalMain>

            <ModalFooter>
              <ModalButton onClick={() => { props.buttonEvent(_checkedItem); }}>{props.buttonTitle}</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default SelectWorkPlaceModal;
