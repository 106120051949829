import React, { useEffect, useState } from 'react';

import { fileCategoryApi } from 'api/apis/fileCategoryApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import LoadingComponent from 'components/loading/LoadingComponent';

const CreateContent = ({
  close, modalData
}) => {
  const [_body, setBody] = useState(modalData);
  const [_loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => {}, [_body, _loadingStatus]);

  const handleInputEvent = (e) => { // 폴더 정보 입력
    const {name, value} = e.target;
    setBody((prev) => {
      const returnData = {...prev, [name] : value};
      return returnData;
    })
  };
  const actCreate = async() => { // 폴더 추가
    setLoadingStatus(() => {return true});
    await fileCategoryApi.createFileCategory(_body).then(response => {
      if(response === undefined) return;
      console.log('actCreate : ', response);
      alert('폴더를 추가했습니다.');
      setLoadingStatus(() => {return false});
      close('update');
    }).finally(() => {
      setLoadingStatus(() => {return false});
    })
  };

  return (
    <>
    <section className='create-content'>
      <div>
        {[
          {name: 'fileCategoryName', title: '폴더이름', disabled: false},
        ].map(((folderData) => {
          return (
            <div key={folderData.name}>
              <h4>{folderData.title}</h4>
              <input
                type='text'
                name={folderData.name}
                disabled={folderData.disabled}
                value={checkEmptyNull(_body[folderData.name], '')}
                onInput={handleInputEvent}
              />
            </div>
          )
        }))}
      </div>

      <button className='btn-save' onClick={actCreate}>저장</button>
    </section>

    {_loadingStatus ? (<LoadingComponent close={close} title={'폴더를 추가하고 있습니다...'} />) : null}
    </>
  );
};

export default CreateContent;