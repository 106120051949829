import { useEffect, useState } from "react";

const RealTimeTable = ({ data }) => {
  const [usage, setUsage] = useState({
    present: 0,
    Average: 0,
  });
    
  function division() {
    const dataList = data.map(item => !isNaN(Number(item.value)) ? Number(item.value) : 0);
    const dataAverage =  dataList.reduce((a, b) => a + b, 0) / dataList.length;
    const todayData = {
      present: data[data.length - 1].value,
      Average: dataAverage,
    }
    return todayData; // 가공한 데이터 반환
  };

  useEffect(() => {
    const processedData = division(data);
    setUsage(processedData); // 상태를 업데이트
  }, [data]); // data가 변경될 때만 실행

  return (
    <div>
      <p>실시간 에너지 사용 현황 : {usage.present}kWh</p>
      <p>실시간 에너지 사용 평균 : {usage.Average}kWh</p>
    </div>
  );
};

export default RealTimeTable;
