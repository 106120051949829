import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { accountAttributeApi } from 'api/apis/accountAttributeApi';
import { accountApi } from 'api/apis/accountApi';

import { checkEmptyNull, checkNullArray, checkNullParse } from 'components/checkValues/checkValues';

import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';

const UpdateAccount = () => {
  /* ========================================================================= #1들 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state,);

  /* ========================================================================= #2 */
  const [searchParams, setSearchParams] = useSearchParams();
  const updateContent = checkNullParse(searchParams.get('updateContent'), {});

  const {
    accountTypeId, accountTypeName,
    accountId, accountCode, businessNumber, accountName,
    regionName, address, detailAddress, zip,
    managerName, telNumber, phoneNumber, faxNumber, email,
    accountAttributeValueList,
  } = updateContent;

  const [_onload, setOnload] = useState('unload');

  const [_accountAttrList, setAccountAttrList] = useState([]);

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    accountId: accountId,
    accountTypeId: accountTypeId,
    accountTypeName: accountTypeName,

    accountCode: accountCode, // 수요기관코드
    businessNumber: businessNumber, // 사업자등록번호
    accountName: accountName, // 회사명,
    regionName: regionName, // 지역명,
    address: address, // 주소,
    detailAddress: detailAddress, // 상세주소,
    zip: zip, // 우편번호,

    managerName: managerName, // 담당자,
    telNumber: telNumber, // 전화번호,
    phoneNumber: phoneNumber, // 휴대폰,
    faxNumber: faxNumber, // 팩스번호,
    email: email, // 이메일,

    accountAttributeValueList: accountAttributeValueList.map((thisItem) => {
      const {
        accountAttributeId,
        accountAttributeName,
        accountAttributeValueId,
        value,
      } = thisItem;

      return {
        accountTypeId: accountTypeId,
        accountAttributeId: accountAttributeId,
        accountAttributeName: accountAttributeName,
        accountAttributeValueId: accountAttributeValueId,
        value: value,
      };
    }),
    deleteAccountAttributeValueList: [],
  });

  const [_accountAttrValues, setAccountAttrValues] = useState(() => {
    const attrValues = {};
    accountAttributeValueList.forEach((thisItem) => {
      attrValues[thisItem.accountAttributeId] = thisItem.value;
    });

    return attrValues;
  });

  /* ========================================================================= #3 */
  useEffect(() => {
    getAccountAttrs(accountTypeId);

    setOnload('loaded');
    return () => { };
  }, []);

  /* ========================================================================= #4 */
  const getAccountAttrs = async (accountType) => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!checkEmptyNull(accountType, false)) {
      BodyToPost.accountTypeId = Number(accountType);
    }
    console.log('getAccountAttrs - BodyToPost : ', BodyToPost);

    await accountAttributeApi.searchAccountAttribute(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('accountAttributeApi.searchAccountAttribute : ', response);
      setAccountAttrList(() => { return response.data; });
    });
  };

  /* ========================================================================= #5 */
  const actUpdate = async (e) => {
    e.preventDefault();
    if (!checkEmptyNull(_formData.accountName, '')) return alert('회사명을 입력해주세요');

    const attrValues = Object.keys(_accountAttrValues).map((thisKey) => {
      const returnData = {
        accountTypeId: accountTypeId,
        accountAttributeId: !isNaN(Number(thisKey)) && Number(thisKey),
        value: _accountAttrValues[thisKey],
      };
      const checkAttrValue = accountAttributeValueList.find((thisItem) => {
        if (thisItem.accountAttributeId === Number(thisKey)) return true;
        else return false;
      });
      if (checkAttrValue !== undefined && checkAttrValue !== null) {
        returnData.accountAttributeValueId =
          checkAttrValue.accountAttributeValueId;
      }
      return returnData;
    });

    const BodyToPost = {
      ..._formData,
      accountAttributeValueList: attrValues,
    };
    console.log('actUpdate - BodyToPost : ', BodyToPost);

    await accountApi.updateAccount(accountId, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('accountApi.updateAccount : ', response);

      alert('거래처를 수정했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body
      contents={
        <>
          <main className="Main">
            <NavBar title={'거래처 설정'} nav={''} />

            <Form
              title={<>거래처<br />수정</>}
              buttons={
                <>
                  <button className="formButton" onClick={actUpdate}>저장</button>
                  <button className="formButton cancle"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                </>
              }
              forms={
                <>
                  <div className="formBox">
                    <h4>수요기관코드</h4>
                    <input
                      type="text"
                      name="accountCode"
                      placeholder="* 수요기관코드..."
                      value={_formData.accountCode}
                      onInput={(e) => {
                        setFormData((prev) => { return { ...prev, accountCode: e.target.value } });
                      }}
                    />
                  </div>

                  <div className="formBox">
                    <h4>사업자등록번호</h4>
                    <input
                      type="text"
                      name="businessNumber"
                      placeholder="* 사업자등록번호..."
                      value={_formData.businessNumber}
                      onInput={(e) => {
                        setFormData((prev) => { return { ...prev, businessNumber: e.target.value } });
                      }}
                    />
                  </div>

                  <div className="formBox">
                    <h4>회사명</h4>
                    <input
                      type="text"
                      name="accountName"
                      data-required="required"
                      placeholder="* 회사명..."
                      value={_formData.accountName}
                      onInput={(e) => {
                        setFormData((prev) => { return { ...prev, accountName: e.target.value } });
                      }}
                    />
                  </div>

                  <div className="formBox">
                    <h4>지역명</h4>
                    <input
                      type="text"
                      name="regionName"
                      placeholder="* 지역명..."
                      value={_formData.regionName}
                      onInput={(e) => {
                        setFormData((prev) => { return { ...prev, regionName: e.target.value } });
                      }}
                    />
                  </div>

                  <div className="formBox">
                    <h4>주소</h4>
                    <input
                      type="text"
                      name="address"
                      placeholder="* 주소..."
                      value={_formData.address}
                      onInput={(e) => {
                        setFormData((prev) => { return { ...prev, address: e.target.value } });
                      }}
                    />
                  </div>

                  <div className="formBox">
                    <h4>상세주소</h4>
                    <input
                      type="text"
                      name="detailAddress"
                      placeholder="* 상세주소..."
                      value={_formData.detailAddress}
                      onInput={(e) => {
                        setFormData((prev) => { return { ...prev, detailAddress: e.target.value } });
                      }}
                    />
                  </div>

                  <div className="formBox">
                    <h4>우편번호</h4>
                    <input
                      type="text"
                      name="zip"
                      placeholder="* 우편번호..."
                      value={_formData.zip}
                      onInput={(e) => {
                        setFormData((prev) => { return { ...prev, zip: e.target.value } });
                      }}
                    />
                  </div>

                  <div className="formBox">
                    <h4>담당자</h4>
                    <input
                      type="text"
                      name="managerName"
                      placeholder="* 담당자..."
                      value={_formData.managerName}
                      onInput={(e) => {
                        setFormData((prev) => { return { ...prev, managerName: e.target.value } });
                      }}
                    />
                  </div>

                  <div className="formBox">
                    <h4>전화번호</h4>
                    <input
                      type="text"
                      name="telNumber"
                      placeholder="* 전화번호..."
                      value={_formData.telNumber}
                      onInput={(e) => {
                        setFormData((prev) => { return { ...prev, telNumber: e.target.value } });
                      }}
                    />
                  </div>
                  <div className="formBox">
                    <h4>휴대폰</h4>
                    <input
                      type="text"
                      name="phoneNumber"
                      placeholder="* 휴대폰..."
                      value={_formData.phoneNumber}
                      onInput={(e) => {
                        setFormData((prev) => { return { ...prev, phoneNumber: e.target.value } });
                      }}
                    />
                  </div>
                  <div className="formBox">
                    <h4>팩스번호</h4>
                    <input
                      type="text"
                      name="faxNumber"
                      placeholder="* 팩스번호..."
                      value={_formData.faxNumber}
                      onInput={(e) => {
                        setFormData((prev) => { return { ...prev, faxNumber: e.target.value } });
                      }}
                    />
                  </div>
                  <div className="formBox">
                    <h4>이메일</h4>
                    <input
                      type="text"
                      name="email"
                      placeholder="* 이메일..."
                      value={_formData.email}
                      onInput={(e) => {
                        setFormData((prev) => { return { ...prev, email: e.target.value } });
                      }}
                    />
                  </div>

                  <br /><hr /><br />

                  {checkNullArray(_accountAttrList, []).map((thisItem, index) => {
                    return (<div key={index + '_accountAttr'} className="formBox">
                      <h4>{thisItem.accountAttributeName}</h4>
                      <input
                        type="text"
                        name="value"
                        placeholder={thisItem.accountAttributeName + '...'}
                        value={checkEmptyNull(_accountAttrValues[thisItem.accountAttributeId], '')}
                        onInput={(e) => {
                          setAccountAttrValues((prev) => { return { ...prev, [thisItem.accountAttributeId]: e.target.value } });
                        }}
                      />
                    </div>);
                  })}
                </>
              }
            />
          </main>
        </>
      }
    />
  );
};

export default UpdateAccount;
