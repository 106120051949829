import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

import { contractTypeApi } from 'api/apis/contractTypeApi';
import { contractAttributeApi } from 'api/apis/contractAttributeApi';
import { contractApi } from 'api/apis/contractApi';

import { checkNull } from 'components/checkValues/checkValues';

import { DateFormat } from 'components/format/DateFormat';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 85%;
  justify-content: flex-start;
  max-height: 550px;
  max-width: 710px;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  height: calc(100% - 55px);
  overflow: auto;
  width: 100%;

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 6px;
  }
`;

const ContentSection = styled.div`
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
  width: 100%;
`;

const SelectSection = styled.select`
  background-color: var(--MainNavy);
  border-radius: 5px;
  color: var(--white);
  font-size: 18px;
  height: 50px;
  padding: 10px;
  width: 100%;
`;

const CheckSection = styled.div`
  align-items: center;
  column-gap: 10px;
  display: flex;
  color: var(--ThirdBlue);
  height: 50px;
  justify-content: flex-start;
  width: inherit;

  input {
    height: 20px;
    width: 20px;
  }
`;

const ButtonsSection = styled.div`
  align-items: center;
  column-gap: 10px;
  display: flex;
  justify-content: center;
  margin: 20px auto;
  width: 100%;
`;

const FormatButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  border-radius: 10px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 200px;
  justify-content: center;
  width: 50%;
`;

const UploadModalButton = styled.label`
  align-items: center;
  background-color: var(--ThirdBlue);
  border-radius: 10px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 200px;
  justify-content: center;
  width: 50%;
`;

const NoticeSection = styled.div`
  color: var(--MainRed);
  font-size: 15px;
  padding-bottom: 20px;
`;

const LoadingScreen = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  color: var(--white);
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 999;
`;

const MarketContractUploadModal = (props) => {
  /* ========================================================================= #1 */
  const { userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const UploadContractInput = useRef();

  const [_contractTypes, setContractTypes] = useState([]);
  const [_contractType, setContractType] = useState('all');

  const [_contractAttrList, setContractAttrList] = useState([]);

  const [_naraElement, setNaraElement] = useState(false);

  const [_loadingStatus, setLoadingStatus] = useState(false);

  /* ========================================================================= #3 */
  useEffect(() => {
    (async () => {
      await getContractTypes();
      await getContractAttrs(_contractType);
    })();

    return () => { };
  }, []);

  /* ========================================================================= #4 */
  /* 수주 유형 */
  const getContractTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    await contractTypeApi.searchContractType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('contractTypeApi.searchContractType : ', response);

      setContractTypes(() => {
        return response.data;
      });
    });
  };

  /* 수주 항목 */
  const getContractAttrs = async (contractType) => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (
      contractType !== undefined &&
      contractType !== null &&
      contractType !== '' &&
      contractType !== 'all'
    ) {
      BodyToPost.contractTypeId = Number(contractType);
    }
    console.log('getContractAttrs - BodyToPost : ', BodyToPost);

    await contractAttributeApi
      .searchContractAttribute(BodyToPost)
      .then((response) => {
        if (response === undefined) return;
        console.log(
          'contractAttributeApi.searchContractAttribute : ',
          response,
        );

        setContractAttrList(() => {
          return response.data;
        });
      });
  };

  /* ========================================================================= #5 */
  const excelExportCommon = (e, callback) => {
    const fileList = e.target.files;
    const thisFile = fileList[0];
    console.log(thisFile);
    if (thisFile === undefined) return;

    const reader = new FileReader();
    reader.onload = async () => {
      const fileData = reader.result;
      const wb = XLSX.read(fileData, { type: 'binary', cellDates: true });
      const sheetNameList = wb.SheetNames;
      for (let i = 0; i < sheetNameList.length; i++) {
        const thisSheetName = sheetNameList[i];
        const thisSheet = wb.Sheets[thisSheetName];

        await callback(thisSheet);
      }
    };

    reader.readAsBinaryString(thisFile);
  };

  const excelSetting = async (sheet) => {
    console.log('sheet : ', sheet);
    const sendData = {
      companyId: userReducer.company.companyId,
      naraElement: _naraElement,
      contractList: [],
    };
    const result = XLSX.utils.sheet_to_json(sheet);
    console.table(result);

    let typeData;
    if (_contractType !== 'all') {
      typeData = _contractTypes.find((thisItem) => {
        if (thisItem.contractTypeId === Number(_contractType)) {
          return true;
        } else return false;
      });

      if (typeData === undefined)
        return alert(`
          업로드를 할 수 없습니다.
          해당 파일을 확인해주세요.
        `);
    }

    const setSendData = result.map((thisItem) => {
      console.log(thisItem);
      const newData = {
        attributeAndValue: {},
        // contractElement: {},
      };
      if (_contractType !== 'all') {
        newData.contractTypeId = typeData.contractTypeId;
        newData.contractTypeName = typeData.contractTypeName;
      }

      for (const key in thisItem) {
        const value = thisItem[key];

        switch (key) {
          case '수주일자':
            // newData.contractDate = checkNull(value, '');
            // newData.contractDate = checkNull(value, false) && value !== '' ? moment(value).format('YYYY-MM-DD') : '';
            newData.contractDate = checkNull(value, false) && value !== '' ? moment(value).add(9, 'h').format('YYYY-MM-DD') : '';
            break;
          case '납품요구접수일자':
            // newData.contractRegDate = checkNull(value, '');
            newData.contractRegDate = checkNull(value, false) && value !== '' ? moment(value).add(9, 'h').format('YYYY-MM-DD') : '';
            break;
          case '납품요구번호/계약번호':
            newData.contractCode = value;
            break;
          case '수주이름':
            newData.contractName = value;
            break;
          case '수요기관명':
            newData.accountName = value;
            break;
          case '수주주소':
            newData.address = value;
            break;
          case '수주총금액':
            newData.contractPrice = value;
            break;
          case '납품기한일자':
            // newData.scheduledEndDate = checkNull(value, '');
            newData.scheduledEndDate = checkNull(value, false) && value !== '' ? moment(value).add(9, 'h').format('YYYY-MM-DD') : '';
            break;
          case '납품일':
            // newData.contractActualEndDate = checkNull(value, '');
            newData.contractActualEndDate = checkNull(value, false) && value !== '' ? moment(value).add(9, 'h').format('YYYY-MM-DD') : '';
            break;
          // case '수주유형':
          //   newData.contractTypeName = value;
          //   break;
          case '특이사항':
            newData.monitoringRemark = value;
            break;
          case '비고':
            newData.remark = value;
            break;
          // case '수주물품이름':
          //   newData.elementName = value;
          //   break;
          case '수주물품코드':
            newData.elementCode = value;
            break;
          case '수주단가':
            newData.elementPrice = value;
            break;
          case '수주수량':
            newData.elementQuantity = value;
            break;

          default:
            if (_contractType !== 'all') {
              newData.attributeAndValue[key] = value;
            }
            break;
        }
      }

      // if (Object.keys(newData.contractElement).length !== 2) {
      //   delete newData.contractElement;
      // }

      return newData;
    });
    console.log('setSendData : ', setSendData);
    sendData['contractList'] = setSendData;

    if (result.length === sendData.contractList.length) {
      console.log('sendData : ', sendData);
      console.log('sendData - stringify : ', JSON.stringify(sendData));
      await postContract(sendData);
    }
  };
  const postContract = async (sendData) => {
    console.log('postContract - sendData : ', sendData);
    // return;
    await contractApi
      .uploadContract(sendData)
      .then((response) => {
        if (response === undefined) return;
        console.log('contractApi.uploadContract : ', response);

        if (response.data) {
          if (response.data.message === 'OK') alert('수주를 업로드했습니다.');
          else
            alert(`
              업로드를 할 수 없습니다.
              해당 파일을 확인해주세요.
            `);
        }
      })
      .finally(() => {
        setLoadingStatus(false);
        props.close();
      });
  };

  const selectUploadFile = (e) => {
    const confirmText = `
    수주를 업로드 하시겠습니까?
    `;

    if (window.confirm(confirmText) === true) {
      setLoadingStatus(true);
      const fileList = e.target.files;
      const thisFile = fileList[0];
      console.log(thisFile);
      if (thisFile === undefined) return;

      excelExportCommon(e, excelSetting);
    } else {
      return;
    }
  };

  const downloadFormat = () => {
    let headerRow = [];

    const OneRow = {};
    OneRow['수주일자'] = '';
    OneRow['납품요구접수일자'] = '';
    OneRow['납품요구번호/계약번호'] = '';
    OneRow['수주이름'] = '';
    OneRow['수요기관명'] = '';
    OneRow['수주주소'] = '';
    OneRow['수주총금액'] = '';
    OneRow['납품기한일자'] = '';
    // OneRow['수주유형'] = '';
    OneRow['특이사항'] = '';
    OneRow['비고'] = '';
    if (_contractType !== 'all') {
      _contractAttrList.forEach((thisItem) => {
        OneRow[thisItem.contractAttributeName] = '';
      });
    }
    // OneRow['수주물품이름'] = '';
    OneRow['수주물품코드'] = '';
    OneRow['수주단가'] = '';
    OneRow['수주수량'] = '';
    headerRow.push(OneRow);
    console.log('headerRow : ', headerRow);

    if (headerRow.length > 0) {
      const ws = XLSX.utils.json_to_sheet(headerRow);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `수주 업로드 양식`);
      XLSX.writeFile(wb, `수주 업로드 양식.xlsx`);
    }
  };

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>수주 업로드</ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              <ContentSection>
                <CheckSection>
                  <div>
                    * 나라장터 물품규격명을 그대로 사용하는 경우 체크해주세요.
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      value={_naraElement}
                      onChange={() => {
                        setNaraElement((prev) => {
                          return !prev;
                        });
                      }}
                    />
                  </div>
                </CheckSection>

                <SelectSection
                  value={_contractType}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      UploadContractInput.current.disabled = true;
                    }
                    setContractType(() => {
                      return e.target.value;
                    });
                    getContractAttrs(e.target.value);
                  }}
                >
                  <option value="all">수주유형 전체</option>
                  {_contractTypes.map((type) => {
                    return (
                      <option
                        key={type.contractTypeId}
                        value={type.contractTypeId}
                      >
                        {type.contractTypeName}
                      </option>
                    );
                  })}
                </SelectSection>

                <ButtonsSection>
                  <FormatButton onClick={downloadFormat}>
                    양식 다운
                  </FormatButton>

                  <input
                    type="file"
                    ref={UploadContractInput}
                    id="ContractUploadModalInput"
                    style={{ display: 'none' }}
                    accept=".xlsx, .xls, .csv"
                    disabled={false}
                    onChange={(e) => {
                      selectUploadFile(e);
                    }}
                  />
                  <UploadModalButton
                    htmlFor="ContractUploadModalInput"
                    onClick={() => {
                      if (_contractType === '') {
                        return alert('수주유형을 선택해주세요.');
                      } else {
                        UploadContractInput.current.disabled = false;
                      }
                    }}
                  >
                    업로드
                  </UploadModalButton>
                </ButtonsSection>

                <NoticeSection>
                  <p>※ 순서 ※</p>
                  <p>1. 항목명을 한줄로 만들어주세요.</p>
                  <p>2. 데이터가 없는 줄은 삭제하고 업로드 해주세요.</p>
                  <p>
                    ※ 엑셀 내 소수점이 있는 경우 셀서식 - 표시형식 - 숫자 - 소수
                    자릿수를 0으로 설정한 후 업로드 해주세요.
                  </p>
                  <p>3. 업로드</p>
                </NoticeSection>
              </ContentSection>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}

      {_loadingStatus === true ? (
        <LoadingScreen>수주를 업로드하고 있습니다...</LoadingScreen>
      ) : null}
    </>
  );
};

export default MarketContractUploadModal;
