import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { elementAttributeApi } from 'api/apis/elementAttributeApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';

import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const Content = styled.div`
  background-color: var(--white);
  box-sizing: border-box;
  border-radius: 8px;
  display: grid;
  grid-template-rows: 60px auto;
  width: 100%;
  height: 100%;
`;
const ContentHeader = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  padding: 24px 16px;
`;
const Title = styled.h1`
  font-size: 20px;
`;
const SelectType = styled.select`
  background-color: var(--white);
  border: 1px solid var(--gray-200);
  border-radius: 5px;
  height: 35px;
  padding: 2px 10px;
  width: 150px;
`;
const SearchBar = styled.div`
  align-items: center;
  border-radius: 10px;
  display: flex;
  gap: 8px;
  input {
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    padding: 4px 16px;
    border-radius: 8px;
    width: 100%;
    font-size: 1rem;
  }
`;
const ButtonsWrap = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  height: 100%;
  justify-content: flex-end;
`;

const GsElementAttr = (props) => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_defaultList, setDefaultList] = useState([
    { key: 'elementName', value: '물품이름' },
    { key: 'elementCode', value: '물품코드' },
    { key: 'elementUnit', value: '단위' },
    { key: 'price', value: '단가' },
    { key: 'elementSafeStock', value: '안전재고' },
    // { key: 'scheduledInputStock', value: '투입예정수량', },
    // { key: 'elementStock', value: '현재고', },
  ]);
  const [_attrList, setAttrList] = useState([]);

  const [_attrType, setAttrType] = useState(userReducer.elementType[0]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_searchText, setSearchText] = useState();

  /* ====================================================================== #3 */
  useEffect(() => {
    getElementAttrs();
  }, [_attrType]);

  /* ====================================================================== #4 */
  const getElementAttrs = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      elementType: _attrType,
    };
    if (checkEmptyNull(_searchText, false)) {
      BodyToPost.elementAttributeName = _searchText;
    }

    await elementAttributeApi
      .searchElementAttribute(BodyToPost)
      .then((response) => {
        if (response === undefined) return;
        console.log('elementAttributeApi.searchElementAttribute : ', response);

        setAttrList(() => {
          return response.data;
        });
      });
  };

  const setAttributeText = (attr) => {
    switch (attr.toLowerCase()) {
      case 'material':
        return '자재';
      case 'semi':
        return '부자재';
      case 'half':
        return '판매물품';
      case 'product':
        return '보유물품';
      case 'tool':
        return '공구';
      case 'equipment':
        return '설비';

      default:
        return;
    }
  };

  /* ====================================================================== #5 */
  const handleAttrType = (e) => {
    const attrType = e.target.value;
    setAttrType(() => {
      return attrType;
    });
  };

  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) {
      checkedItem.add(id);
    }
    setCheckedItem(() => {
      return checkedItem;
    });
  };

  const actSearch = async () => {
    getElementAttrs();
  };

  /* 추가 */
  const actCreate = async () => {
    const promptText = `
    물품유형 : ${setAttributeText(_attrType)}
    추가할 항목명을 입력해주세요.
    `;
    const CreatePrompt = window.prompt(promptText, '');
    if (!checkEmptyNull(CreatePrompt, false)) return;

    const BodyToPost = {
      companyId: userReducer.company.companyId,
      elementType: _attrType,
      elementAttributeName: CreatePrompt,
    };
    console.log('actCreate - body : ', BodyToPost);

    await elementAttributeApi
      .createElementAttribute(BodyToPost)
      .then((response) => {
        if (response === undefined) return;
        console.log('elementAttributeApi.createElementAttribute : ', response);

        setAttrList((prev) => {
          return [...prev, response.data];
        });

        alert('항목을 추가했습니다.');
      });
  };

  /* 수정 */
  const actUpdate = async () => {
    const updateContentIndex = _attrList.findIndex(
      (thisItem) => thisItem.elementAttributeId === Array.from(_checkedItem)[0],
    );
    if (updateContentIndex === -1) return alert('수정할 항목을 선택해 주세요.');
    const updateContent = _attrList[updateContentIndex];
    console.log('updateContent : ', updateContent);

    const promptText = `
    물품유형 : ${setAttributeText(_attrType)}
    항목을 수정합니다.
    `;
    const UpdatePrompt = window.prompt(
      promptText,
      updateContent.elementAttributeName,
    );
    if (UpdatePrompt === null) return;

    const BodyToPut = {
      companyId: userReducer.company.companyId,
      elementType: updateContent.elementType,
      elementAttributeId: updateContent.elementAttributeId,
      elementAttributeName: UpdatePrompt,
    };
    console.log('actUpdate - body : ', BodyToPut);

    await elementAttributeApi
      .updateElementAttribute(updateContent.elementAttributeId, BodyToPut)
      .then((response) => {
        if (response === undefined) return;
        console.log('elementAttributeApi.updateElementAttribute : ', response);

        setAttrList((prev) => {
          const prevData = [...prev];
          prevData.splice(updateContentIndex, 1, response.data);

          return prevData;
        });

        alert('항목을 수정했습니다.');
      });
  };

  /* 삭제 */
  const actDelete = () => {
    const deleteContentIndex = _attrList.findIndex(
      (thisItem) => thisItem.elementAttributeId === Array.from(_checkedItem)[0],
    );
    if (deleteContentIndex === -1) return alert('삭제할 항목을 선택해 주세요.');
    const deleteContent = _attrList[deleteContentIndex];
    console.log('deleteContent : ', deleteContent);

    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({
        content: JSON.stringify(deleteContent),
        typeName: setAttributeText(_attrType),
        type: 'element',
      })}`,
      replace: true,
    });
  };

  /* ====================================================================== #6 */

  return (
    <Content>
      <ContentHeader>
        <Title>물품 항목 설정</Title>
        <div className="content-snb">
          <SelectType value={_attrType} onChange={handleAttrType}>
            <option value="all">물품 유형 선택</option>
            {userReducer.elementType.map((thisItem) => {
              const typeText = setAttributeText(thisItem);
              if (typeText === '') return null;
              return (
                <>
                  <option key={thisItem} value={thisItem}>
                    {typeText}
                  </option>
                </>
              );
            })}
          </SelectType>
          <ButtonsWrap>
            {props.authority.indexOf('103-2') !== -1 ? (
              props.authority.indexOf('101-2') !== -1 ? (
                <>
                  <button className="btn-add" onClick={actCreate}>
                    추가
                  </button>
                  <button className="btn-edit" onClick={actUpdate}>
                    수정
                  </button>
                  <button className="btn-delete" onClick={actDelete}>
                    삭제
                  </button>
                </>
              ) : null
            ) : null}
          </ButtonsWrap>
          <SearchBar>
            <input
              placeholder="항목명..."
              onInput={(e) => {
                setSearchText(() => {
                  return e.target.value;
                });
              }}
            />
            <button data-searchbutton="true" onClick={actSearch}>
              <SearchButtonIcon />
            </button>
          </SearchBar>
        </div>
      </ContentHeader>
      <TableSection
        content={
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th style={{ minWidth: 'unset', width: '50px' }}></th>
                <th>항목명</th>
              </tr>
            </thead>
            <tbody>
              {_defaultList.map((attr, index) => {
                return (
                  <tr key={index + '_default'}>
                    <td
                      style={{
                        backgroundColor: 'var(--MainNavy)',
                        minWidth: 'unset',
                        width: '50px',
                      }}
                    ></td>
                    <td>{attr.value}</td>
                  </tr>
                );
              })}
              {_attrList.map((attr, index) => {
                return (
                  <tr key={index + '_attr'}>
                    <td style={{ minWidth: 'unset', width: '50px' }}>
                      <input
                        type="checkBox"
                        name="elementAttrs"
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          handleCheckedItem(attr.elementAttributeId, isChecked);

                          if (isChecked) {
                            document
                              .getElementsByName('elementAttrs')
                              .forEach((thisAttr) => {
                                thisAttr.checked = false;
                              });
                            e.target.checked = true;
                          } else {
                            e.target.checked = false;
                          }
                        }}
                      />
                    </td>
                    <td>{attr.elementAttributeName}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        }
      ></TableSection>
    </Content>
  );
};

export default GsElementAttr;
