import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const DetailedStockModalSection = styled.div`
  &.detailedStockModal {
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 1px 4px 8px 8px #e8e8e8;
    display: grid;
    grid-template-rows: 45px auto;
    height: 90%;
    max-height: 400px;
    max-width: 400px;
    overflow: hidden;
    position: fixed;
    left: 50%;
    top: 50%;
    /* top: ${(props) => { return (props.eventPosition.top + 40) + 'px' }}; */
    transform: translate(-50%, -50%);
    width: 95%;
    z-index: 900;

    .modalHeader {
      align-items: center;
      box-sizing: border-box;
      display: grid;
      height: 45px;
      justify-content: flex-start;
      padding-inline: 20px;
      position: relative;
      width: 100%;

      h4 {font-size: 0.9em; font-weight: 400; white-space: nowrap;}
      .closeBtn {height: 25px; position: absolute; right: 15px; top: 15px; width: 25px;}
    }

    .modalMain {
      box-sizing: border-box;
      height: 100%;
      padding: 20px;
      width: 100%;

      table {
        height: 100%;
        width: 100%;

        /* tbody {
          td[data-stocktitle='당일입고'] {color: var(--MainGreen)};
          td[data-stocktitle='당일투입'] {color: var(--MainYellow)};
          td[data-stocktitle='당일생산'] {color: var(--MainBlue)};
          td[data-stocktitle='당일차감/출하'] {color: var(--MainRed)};
        } */
        td:last-child {text-align: right;}

        tfoot td {
          height: 60px;
          border-top: 1px dashed var(--gray-200);
        }
      }
    }
  }
`;

const DetailedStockModal = (props) => {
  const { userReducer } = useSelector((state) => state);

  const { stockElement, eventPosition } = props;
  console.log('stockElement : ', stockElement);

  const handleStockAmount = (stockTitle) => {
    switch (stockTitle) {
      case '당일입고': return checkEmptyNull(stockElement.todayInStock, 0).toLocaleString();
      case '당일투입': return checkEmptyNull(stockElement.todayPutStock, 0).toLocaleString();
      case '당일차감/출하': return checkEmptyNull(stockElement.todayOutStock, 0).toLocaleString();
      case '안전재고': return checkEmptyNull(stockElement.elementSafeStock, 0).toLocaleString();
      case '적정재고': return checkEmptyNull(stockElement.elementSafeStock, 0).toLocaleString();
      case '투입예정수량': return checkEmptyNull(stockElement.scheduledInputStock, 0).toLocaleString();

      default: return 0;
    }
  }

  return (
    <DetailedStockModalSection className='detailedStockModal' eventPosition={eventPosition}>
      <div className='modalHeader'>
        <h4>{stockElement.elementName}</h4>
        <h4>{stockElement.elementCode}</h4>
        <div className='closeBtn' onClick={props.close}><CloseButtonIcon /></div>
      </div>
      <div className='modalMain'>
        <table>
          <tbody>
            {['당일입고', '당일투입', '당일차감/출하', '안전재고', '적정재고', '투입예정수량'].map(stockTitle => {
              if (![1, 2, 3].includes(userReducer.company.companyId) && stockTitle === '적정재고') return <></>;
              return (
                <tr key={stockTitle + '_stockTitle'}>
                  <td>{stockTitle}</td>
                  <td>{handleStockAmount(stockTitle)}{stockElement.elementUnit}</td>
                </tr>
              )
            })}
          </tbody>
          <tfoot>
            <tr>
              <td>현재고</td>
              <td>{stockElement.elementStock.toLocaleString()}{stockElement.elementUnit}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </DetailedStockModalSection>
  );
};

export default DetailedStockModal;