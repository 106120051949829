import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { monitoringActions_setHalfFilter, monitoringActions_setMaterialFilter } from 'store/modules/actions/common/monitoringActions';

import { elementApi } from 'api/apis/elementApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import TableSection from 'components/layouts/table/TableSection';

const Section = styled.section`
  display: grid;
  grid-area: material;
  grid-template-rows: 50% 50%;
  height: 100%;
  overflow: hidden;
  width: 100%;

  .monitoring {
    height: 100%;
    width: 100%;
  }
  .sortingCell {
    background-color: var(--ThirdBlue);
    position: relative;

    .sorting {
      align-items: center;
      background-color: var(--MainBlue);
      cursor: pointer;
      display: flex;
      font-size: 0.9em;
      font-weight: 400;
      gap: 0px;
      height: 100%;
      justify-content: center;
      position: absolute;
      right: 0px;
      top: 0px;
      width: 140px;
    }
  }

  .TableSection {
    height: 100%;

    table {height: 100%;}
    td, th {min-width: unset;}
    td.safe, td.danger {
      background-color: var(--MainGreen);
      color: var(--white);
      font-size: 1.5em;
      font-weight: 600;
    }
    td.safe {background-color: var(--MainGreen);}
    td.danger {background-color: var(--MainRed);}
  }
`;

const MaterialMonitoring = ({total}) => {
  const dispatch = useDispatch();
  const {monitoringReducer, userReducer } = useSelector((state) => state);

  const [_materialList, setMaterialList] = useState([]);
  const [_materialPage, setMaterialPage] = useState({page: 0, size: total ? 5: 10, totalSize: 0});
  const [_materialView, setMaterialView] = useState(monitoringReducer.materialFilter);

  const [_semiList, setSemiList] = useState([]);
  const [_semiPage, setSemiPage] = useState({page: 0, size: total ? 5: 10, totalSize: 0});
  const [_semiView, setSemiView] = useState(monitoringReducer.halfFilter);

  useEffect(() => {
    getMaterialList(_materialPage.page, _materialView);
    getSemiList(_semiPage.page, _semiView);

    return () => {}
  }, [])
  useEffect(() => {}, [monitoringReducer.materialFilter, monitoringReducer.halfFilter])

  const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    useEffect(() => {savedCallback.current = callback;}, [callback]);
    useEffect(() => {
      const tick = () => {savedCallback.current();};
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };
  useInterval(async () => {
    setMaterialPage((prev) => {
      if (prev.page >= parseFloat(_materialPage.totalSize / _materialPage.size) - 1) {
        getMaterialList(0, _materialView);
        return { ...prev, page: 0 };
      } else {
        getMaterialList(prev.page + 1, _materialView);
        return { ...prev, page: prev.page + 1 };
      }
    });
    setSemiPage((prev) => {
      if (prev.page >= parseFloat(_semiPage.totalSize / _semiPage.size) - 1){
        getSemiList(0, _semiView);
        return { ...prev, page: 0 };
      } else {
        getSemiList(prev.page + 1, _semiView);
        return { ...prev, page: prev.page + 1 };
      }
    });
  }, 30000);

  const getMaterialList = async(page, safeStock) => {
    const paging = `?page=${page}&size=${_materialPage.size}&sort=id,DESC`;
    const body={
      companyId: userReducer.company.companyId,
      elementType: 'material'
    }
    if(safeStock === 'safeStock') body.lessThanSafeStock = true;
    await elementApi.searchElementStockList(paging, body).then(response => {
      if(response === undefined) return;
      console.log('elementApi.searchElementStockList : ', response);
      setMaterialList(() => {return response.data.content})
      setMaterialPage((prev) => {return {...prev, totalSize: response.data.totalElements}})
    })
  }
  const getSemiList = async(page, safeStock) => {
    const paging = `?page=${page}&size=${_semiPage.size}&sort=id,DESC`;
    const body={
      companyId: userReducer.company.companyId,
      elementType: 'semi'
    }
    if(safeStock === 'safeStock') body.lessThanSafeStock = true;
    await elementApi.searchElementStockList(paging, body).then(response => {
      if(response === undefined) return;
      console.log('elementApi.searchElementStockList : ', response);
      setSemiList(() => {return response.data.content})
      setSemiPage((prev) => {return {...prev, totalSize: response.data.totalElements}})
    })
  }

  const handleSorting = (elementType) => {
    if(elementType === 'material') {
      setMaterialPage((prev) => {return{...prev, page: 0}});
      setMaterialView(prev => {
        if(prev === 'safeStock') {
          getMaterialList(0, ''); 
          dispatch(monitoringActions_setMaterialFilter(''));
          return '';
        } else {
          getMaterialList(0, 'safeStock');
          dispatch(monitoringActions_setMaterialFilter('safeStock'));
          return 'safeStock';
        }
      })
    }
    if(elementType === 'semi') {
      setSemiPage((prev) => {return{...prev, page: 0}});
      setSemiView(prev => {
        if(prev === 'safeStock') {
          getSemiList(0, '');
          dispatch(monitoringActions_setHalfFilter(''));
          return '';
        } else {
          getSemiList(0, 'safeStock');
          dispatch(monitoringActions_setHalfFilter('safeStock'));
          return 'safeStock';
        }
      })
    }
  }

  return (
    <Section>
      <div className='monitoring'>
        <TableSection content={
          <table>
            <colgroup>
              <col />
              <col />
              <col width={'140px'} />
              <col width={'140px'} />
            </colgroup>
            <thead>
              <tr>
                <th colSpan={4} className='sortingCell'>
                  자재 재고현황
                  <div className='sorting' onClick={(e) => {e.preventDefault(); handleSorting('material')}}>{monitoringReducer.materialFilter === 'safeStock' ? '기본' : '안전재고'} 정렬</div>
                </th>
              </tr>
              <tr>
                <th>물품이름</th>
                <th>물품코드</th>
                <th>안전재고</th>
                <th>현재고</th>
              </tr>
            </thead>
            <tbody>
              {_materialList.map((element, index) => {
                let safeStockStatus = '';
                if((checkEmptyNull(element.elementStock, 0) * 1) < (checkEmptyNull(element.elementSafeStock, 0) * 1)) safeStockStatus = 'danger';
                else safeStockStatus = 'safe';
                return (
                  <tr key={index + '_materials'}>
                    <td>{element.elementName}</td>
                    <td>{element.elementCode}</td>
                    <td>{(checkEmptyNull(element.elementSafeStock, 0) * 1).toLocaleString()}</td>
                    <td className={safeStockStatus}>{(checkEmptyNull(element.elementStock, 0) * 1).toLocaleString()}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        } />
      </div>
      <div className='monitoring'>
        <TableSection content={
          <table>
            <colgroup>
              <col />
              <col />
              <col width={'140px'} />
              <col width={'140px'} />
            </colgroup>
            <thead>
              <tr>
                <th colSpan={4} className='sortingCell'>
                  부자재 재고현황
                  <div className='sorting' onClick={(e) => {e.preventDefault(); handleSorting('semi')}}>{monitoringReducer.halfFilter === 'safeStock' ? '기본' : '안전재고'} 정렬</div>
                </th>
              </tr>
              <tr>
                <th>물품이름</th>
                <th>물품코드</th>
                <th>안전재고</th>
                <th>현재고</th>
              </tr>
            </thead>
            <tbody>
              {_semiList.map((element, index) => {
                let safeStockStatus = '';
                if((checkEmptyNull(element.elementStock, 0) * 1) < (checkEmptyNull(element.elementSafeStock, 0) * 1)) safeStockStatus = 'danger';
                else safeStockStatus = 'safe';
                return (
                  <tr key={index + '_semis'}>
                    <td>{element.elementName}</td>
                    <td>{element.elementCode}</td>
                    <td>{(checkEmptyNull(element.elementSafeStock, 0) * 1).toLocaleString()}</td>
                    <td className={safeStockStatus}>{(checkEmptyNull(element.elementStock, 0) * 1).toLocaleString()}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        } />
      </div>
    </Section>
  );
};

export default MaterialMonitoring;