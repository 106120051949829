import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { getCookie } from 'preferences/cookie/cookie';
import { any } from 'api/apis/any/any';

import { securityIcon } from 'components/icons/src';
import Agreement from 'components/role/agreements/Agreement';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  display: grid;
  grid-template-rows: auto 50px;
  height: 80%;
  max-height: 700px;
  max-width: 600px;
  overflow: hidden;
  position: relative;
  width: 90%;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 140px auto;
  height: 100%;
  overflow: hidden;
  padding: 20px;
  padding-top: 50px;
  row-gap: 30px;
  width: 100%;
`;
const TopSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 140px;
  justify-content: space-around;
  width: 100%;
`;
const SecurityIcon = styled.div`
  height: 50px;
  width: 50px;
  & img {height: 100%; width: fit-content;}
`;

const MiddleSection = styled.div`
  border: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 100%;
  overflow-y: scroll;
  padding: 20px;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}
`;
const AgreementContent = styled.div`
  font-size: 0.9em;
  font-weight: 400;
`;

const ModalFooter = styled.div`
  align-items: center;
  background-color: var(--MainBlue);
  color: var(--white);
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const AgreementModalFromG = (props) => {
  const [_myData, setMyData] = useState({});

  useEffect(() => {
    getMyData();
    return () => {}
  }, []);

  const getMyData = async () => {
    await any.getMyData(getCookie('accessToken')).then((response) => {
      if (response === undefined) return;
      console.log('any.getMyData : ', response);
      setMyData(() => { return response.data });
    });
  };

  const actJoin = async () => {
    const returnData = {
      ..._myData,
      permission: true
    }
    props.setMyData(() => {return returnData;})
    setTimeout(props.close, 1000)
  };

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>

            <ModalMain>
              <TopSection>
                <SecurityIcon><img src={securityIcon} alt={'개인정보처리방침 동의 팝업'} /></SecurityIcon>
                <h3>개인정보처리방침</h3>
              </TopSection>

              <MiddleSection>
                <AgreementContent><Agreement /></AgreementContent>
              </MiddleSection>
            </ModalMain>

            <ModalFooter onClick={(e) => { e.preventDefault(); actJoin(); }}>동의하고 가입하기</ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default AgreementModalFromG;
