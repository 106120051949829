import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { contentsReducer_setUpdateContents } from 'store/modules/actions/default/contentsActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { shipmentActions_setIsPushedSearchButton, shipmentActions_setPageNumber, shipmentActions_setReset, shipmentActions_setSearchData, shipmentActions_setTotalSize } from 'store/modules/actions/common/shipmentActions';

import { shipmentApi } from 'api/apis/shipmentApi';

import { checkEmptyNull, checkNullParse } from 'components/checkValues/checkValues';

import Grid3Body from 'components/layouts/body/Grid3Body';
import GuideText from 'components/buttons/GuideText';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

import GSDeliveryPdfModal from 'pages/shipment/custom/company/gs/invoice/modal/GSDeliveryPdfModal';
import GSInvoicePdfModal from 'pages/shipment/custom/company/gs/invoice/modal/GSInvoicePdfModal';

const ActButton = styled.div`
  align-items: center;
  background-color: var(--MainBlue);
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  min-height: 50px;
  width: 100%;
`;

const GSShipmentManagement = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, shipmentReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_shipmentList, setShipmentList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_printType, setPrintType] = useState('');
  const [_pdfPreviewModalStatus, setPdfPreviewModalStatus] = useState(false); //PDF 미리보기 모달

  /* ========================================================================= #3*/
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('205') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getShipmentList(shipmentReducer.pageNumber - 1, shipmentReducer.shipmentStatus);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getShipmentList(shipmentReducer.pageNumber - 1, shipmentReducer.shipmentStatus);
    }
    return () => { }
  }, [shipmentReducer.pageNumber])
  useEffect(() => { }, [shipmentReducer]);

  /* ========================================================================= #4*/
  const getShipmentList = async (page, shipmentStatus, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = { companyId: userReducer.company.companyId };
    if (checkEmptyNull(shipmentStatus, false) && shipmentStatus !== 'all') {
      BodyToPost.shipmentStatus = shipmentStatus;
    }
    if (checkEmptyNull(shipmentReducer.searchData?.shipmentCode, false)) {
      BodyToPost.shipmentCode = shipmentReducer.searchData.shipmentCode;
    }
    if (checkEmptyNull(shipmentReducer.searchData?.shipmentName, false)) {
      BodyToPost.shipmentName = shipmentReducer.searchData.shipmentName;
    }

    await shipmentApi.searchShipment(paging, BodyToPost).then(response => {
      if (response === undefined) return;
      console.log('shipmentApi.searchShipment : ', response);
      setShipmentList(() => { return response.data.content });
      dispatch(shipmentActions_setTotalSize(response.data.totalElements));
    })
  }

  /* ========================================================================= #5*/
  const handleCheckedItem = (shipment, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(shipment);
    setCheckedItem(() => { return checkedItem });
  };

  const actSearch = async () => {
    dispatch(shipmentActions_setPageNumber(1));
    dispatch(shipmentActions_setIsPushedSearchButton(true));
    await getShipmentList(0, shipmentReducer.shipmentStatus, true);
  };

  const actPrint = (shipment, type) => {
    setPrintType(() => { return type });
    dispatch(contentsReducer_setUpdateContents(shipment));
    setPdfPreviewModalStatus(true);
  };

  /* 복사 */
  const actCopy = () => {
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return alert('복사할 송장을 선택해주세요.');
    const checkedContent = checkedList[0];
    console.log('checkedContents : ', checkedContent);
    navigate({
      pathname: pageReducer.currentPage + '/copy',
      search: `?${createSearchParams({ copyContent: JSON.stringify(checkedContent) })}`,
      replace: true,
    });
  };

  /* 수정 */
  const actUpdate = () => {
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return alert('수정할 송장을 선택해주세요.');
    const checkedContent = checkedList[0];
    console.log('checkedContents : ', checkedContent);
    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({ updateContent: JSON.stringify(checkedContent) })}`,
      replace: true,
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return alert('삭제할 송장을 선택해주세요.');
    const checkedContent = checkedList[0];
    console.log('checkedContents : ', checkedContent);
    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({ deleteContent: JSON.stringify(checkedContent) })}`,
      replace: true,
    });
  };

  /* ====================================================================== #6 */

  return (
    <Grid3Body contents={
      <>
        <main className="Main">
          <NavBar
            title={<NavTitle menuCode={'205'} />}
            buttons={
              <>
                <button className='btn-add' onClick={() => { navigate(pageReducer.currentPage + '/create', { replace: true }); }}>추가</button>
                <button className='btn-copy' onClick={actCopy}>복사</button>
                <button className='btn-edit' onClick={actUpdate}>수정</button>
                <button className='btn-delete' onClick={actDelete}>삭제</button>
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <div className="ContentCBox">
                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="">송장코드</option>
                      </select>
                      <input
                        className="SearchBar"
                        placeholder="Search..."
                        value={shipmentReducer.searchData?.shipmentCode}
                        onInput={(e) => { dispatch(shipmentActions_setSearchData((prev) => { return { ...prev, shipmentCode: e.target.value } })) }}
                      />
                    </div>
                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="">송장이름</option>
                      </select>
                      <input
                        className="SearchBar"
                        placeholder="Search..."
                        value={shipmentReducer.searchData?.shipmentName}
                        onInput={(e) => { dispatch(shipmentActions_setSearchData((prev) => { return { ...prev, shipmentName: e.target.value } })) }}
                      />
                    </div>

                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton" onClick={() => { dispatch(shipmentActions_setReset()); getShipmentList(0, false); }}>초기화</div>
                  </div>
                </div>
              </>
            }
          />

          <TableSection content={
            <table>
              <thead>
                <tr>
                  <th rowSpan={2} style={{ minWidth: '50px', width: '50px' }}></th>
                  <th rowSpan={2} style={{ minWidth: '80px', width: '80px' }}></th>
                  <th rowSpan={2} style={{ minWidth: '80px', width: '80px' }}></th>
                  <th rowSpan={2}>송장코드</th>
                  {/* <th rowSpan={2}>송장명</th> */}
                  <th rowSpan={2}>현장명</th>
                  <th rowSpan={2}>상호</th>
                  <th rowSpan={2}>주소</th>
                  <th rowSpan={2}>담당</th>
                  <th colSpan={3} style={{ backgroundColor: 'var(--MainBlue)', padding: '7px 0px' }}>운전자</th>
                </tr>
                <tr>
                  <th style={{ padding: '7px 0px' }}>운전자</th>
                  <th style={{ padding: '7px 0px' }}>차량번호</th>
                  <th style={{ padding: '7px 0px' }}>H.P</th>
                </tr>
              </thead>

              <tbody>
                {_shipmentList.map((shipment, index) => {
                  const parseCustomizedContent = checkNullParse(shipment.customizedContent, {
                    shipmentStatus: 'wait',
                    supplyPlaceName: '',
                    supplyCompanyName: '',
                    supplyAddress: '',
                    supplyCharge: '',
                    driverName: '',
                    carNumber: '',
                    driverHP: '',
                  });

                  return (
                    <tr key={index + '_shipment'} data-shipmentid={shipment.shipmentId}>
                      <td style={{ minWidth: '50px', width: '50px' }}>
                        <input
                          type="checkBox"
                          name="shipments"
                          data-key={shipment.shipmentId}
                          checked={(() => {
                            const checkedIndex = _checkedItem ? Array.from(_checkedItem).findIndex((item) => item.shipmentId === shipment.shipmentId) : -1;
                            if (checkedIndex !== -1) return true;
                            else return false;
                          })()}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            handleCheckedItem(shipment, isChecked);
                          }}
                          onClick={(e) => { e.stopPropagation(); }}
                        />
                      </td>
                      <td style={{ height: '100px', minWidth: '100px', padding: 'unset', width: '100px' }} data-shipmentid={shipment.shipmentId}>
                        <ActButton onClick={(e) => { e.preventDefault(); actPrint(shipment, 'deliveryInvoice') }}>송장<GuideText /></ActButton>
                      </td>
                      <td style={{ height: '100px', minWidth: '100px', padding: 'unset', width: '100px' }} data-shipmentid={shipment.shipmentId}>
                        <ActButton style={{ backgroundColor: 'var(--ThirdBlue)', }} onClick={(e) => { e.preventDefault(); actPrint(shipment, 'invoice') }}>거래<br />명세서<GuideText /></ActButton>
                      </td>
                      <td>{shipment.shipmentCode}</td>
                      {/* <td>{shipment.shipmentName}</td> */}
                      <td>{parseCustomizedContent.supplyPlaceName}</td>
                      <td>{parseCustomizedContent.supplyCompanyName}</td>
                      <td>{parseCustomizedContent.supplyAddress}</td>
                      <td>{parseCustomizedContent.supplyCharge}</td>
                      <td>{parseCustomizedContent.driverName}</td>
                      <td>{parseCustomizedContent.carNumber}</td>
                      <td>{parseCustomizedContent.driverHP}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          }
          ></TableSection>
        </main>

        <PagingComponent
          page={shipmentReducer.pageNumber}
          count={shipmentReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(shipmentActions_setPageNumber(page));
          }}
        />

        {_pdfPreviewModalStatus === true && _printType === 'deliveryInvoice' ? (
          <GSDeliveryPdfModal
            open={_pdfPreviewModalStatus}
            close={() => { setPdfPreviewModalStatus(false); }}
          ></GSDeliveryPdfModal>
        ) : null}
        {_pdfPreviewModalStatus === true && _printType === 'invoice' ? (
          <GSInvoicePdfModal
            open={_pdfPreviewModalStatus}
            close={() => { setPdfPreviewModalStatus(false); }}
          ></GSInvoicePdfModal>
        ) : null}
      </>
    }
    />
  );
};

export default GSShipmentManagement;
