import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

import { contractTypeApi } from 'api/apis/contractTypeApi';
import { contractAttributeApi } from 'api/apis/contractAttributeApi';
import { contractApi } from 'api/apis/contractApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 85%;
  justify-content: flex-start;
  max-height: 500px;
  max-width: 710px;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  height: calc(100% - 55px);
  overflow: auto;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}
`;
const ContentSection = styled.div`
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
  width: 100%;
`;
const SelectSection = styled.select`
  background-color: var(--MainNavy);
  border-radius: 5px;
  color: var(--white);
  font-size: 18px;
  height: 50px;
  padding: 10px;
  width: 100%;
`;
const ButtonsSection = styled.div`
  align-items: center;
  column-gap: 10px;
  display: flex;
  justify-content: center;
  margin: 20px auto;
  width: 100%;
`;
const FormatButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  border-radius: 10px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 200px;
  justify-content: center;
  width: 50%;
`;
const UploadModalButton = styled.label`
  align-items: center;
  background-color: var(--ThirdBlue);
  border-radius: 10px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 200px;
  justify-content: center;
  width: 50%;
`;

const NoticeSection = styled.div`
  color: var(--MainRed);
  font-size: 15px;
  padding-bottom: 20px;
`;

const LoadingScreen = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  color: var(--white);
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 999;
`;

const ContractUploadModal = (props) => {
  /* ========================================================================= #1 */
  const { userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const UploadContractInput = useRef();

  const [_contractTypes, setContractTypes] = useState([]);
  const [_contractType, setContractType] = useState('all');

  const [_contractAttrList, setContractAttrList] = useState([]);

  const [_loadingStatus, setLoadingStatus] = useState(false);

  /* ========================================================================= #3 */
  useEffect(() => {
    (async () => {
      await getContractTypes();
      await getContractAttrs(_contractType);
    })();

    return () => { };
  }, []);

  /* ========================================================================= #4 */
  const getContractTypes = async () => { // 수주 유형
    const BodyToPost = {companyId: userReducer.company.companyId};
    await contractTypeApi.searchContractType(BodyToPost).then((response) => {
      if (response === undefined) return;
      setContractTypes(() => {return response.data;});
    });
  };

  const getContractAttrs = async (contractType) => { // 수주 항목
    const BodyToPost = {companyId: userReducer.company.companyId};
    if (checkEmptyNull(contractType, false) && contractType !== 'all') BodyToPost.contractTypeId = Number(contractType);
    await contractAttributeApi.searchContractAttribute(BodyToPost).then((response) => {
      if (response === undefined) return;
      setContractAttrList(() => {return response.data;});
    });
  };

  /* ========================================================================= #5 */
  const excelExportCommon = (e, callback) => {
    const fileList = e.target.files;
    const file = fileList[0];
    if (file === undefined) return;

    const reader = new FileReader();
    reader.onload = async () => {
      const fileData = reader.result;
      const wb = XLSX.read(fileData, { type: 'binary', cellDates: true, dateNF: 'YYYY-MM-DD' });
      const sheetNameList = wb.SheetNames;
      for (let i = 0; i < sheetNameList.length; i++) {
        const sheetName = sheetNameList[i];
        const sheet = wb.Sheets[sheetName];
        await callback(sheet);
      }
    };

    reader.readAsBinaryString(file);
  };

  const excelSetting = async (sheet) => {
    const sendData = {
      companyId: userReducer.company.companyId,
      naraElement: false,
      contractList: [],
    };
    const result = XLSX.utils.sheet_to_json(sheet);
    console.table(result);

    let typeData;
    if (_contractType !== 'all') { // 수주 유형 확인
      typeData = _contractTypes.find((contractType) => {
        if (contractType.contractTypeId === Number(_contractType)) return true;
        else return false;
      });

      const alertText = `
      업로드를 할 수 없습니다.
      해당 파일을 확인해주세요.
      `;
      if (typeData === undefined) return alert(alertText);
    }

    const formatDate = (date) => { // 날짜 포멧
      return moment(date).add(9, 'h').format('YYYY-MM-DD');
    }
    const setSendData = result.map((contract) => { // 수주 리스트 설정
      const newData = {
        attributeAndValue: {},
      };
      if (_contractType !== 'all') {
        newData.contractTypeId = typeData.contractTypeId;
        newData.contractTypeName = typeData.contractTypeName;
      }
      for (const key in contract) {
        const value = contract[key];

        /* cotractName, contractDate, contractRegDate, scheduledEndDate, contractPrice, contractActualEndDate, address, remark, monitoringRemark */

        switch (key) {
          case '수주일':
            const contractDate = checkEmptyNull(value, false) ? formatDate(value) : '';
            newData.contractDate = contractDate;
            // newData.contractDate = checkNull(value, false) && value !== '' ? moment(value).add(9, 'h').format('YYYY-MM-DD') : '';
            break;
          case '납품요구일':
            const contractRegDate = checkEmptyNull(value, false) ? formatDate(value) : '';
            newData.contractRegDate = contractRegDate;
            // newData.contractRegDate = checkNull(value, false) && value !== '' ? moment(value).add(9, 'h').format('YYYY-MM-DD') : '';
            break;
          case '납기일':
            const scheduledEndDate = checkEmptyNull(value, false) ? formatDate(value) : '';
            newData.scheduledEndDate = scheduledEndDate;
            // newData.scheduledEndDate = checkNull(value, false) && value !== '' ? moment(value).add(9, 'h').format('YYYY-MM-DD') : '';
            break;
          case '납품일':
            const contractActualEndDate = checkEmptyNull(value, false) ? formatDate(value) : '';
            newData.contractActualEndDate = contractActualEndDate;
            // newData.contractActualEndDate = checkNull(value, false) && value !== '' ? moment(value).add(9, 'h').format('YYYY-MM-DD') : '';
            break;
          case '수주코드': newData.contractCode = value; break;
          case '수주이름': newData.contractName = value; break;
          case '수요기관명': newData.accountName = value; break;
          case '거래처': newData.accountName = value; break;
          case '수주주소': newData.address = value; break;
          case '수주총금액': newData.contractPrice = value; break;
          case '비고': newData.remark = value; break;
          case '수주물품코드': newData.elementCode = value; break;
          case '수주단가': newData.elementPrice = value; break;
          case '수주수량': newData.elementQuantity = value; break;

          default: 
            if (_contractType !== 'all') newData.attributeAndValue[key] = value;
            break;
        }
      }
      return newData;
    });
    sendData['contractList'] = setSendData;

    if (result.length === sendData.contractList.length) {
      console.log('sendData : ', sendData);
      console.log('sendData - stringify : ', JSON.stringify(sendData));
      await postContract(sendData);
    }
  };
  const postContract = async (sendData) => { // 수주 업로드
    console.log('postContract - sendData : ', sendData);
    await contractApi.uploadContract(sendData).then((response) => {
      if (response === undefined) return;
      if (response.data) {
        if (response.data.message === 'OK') alert('수주를 업로드했습니다.');
        else {
          const alertText = `
          업로드를 할 수 없습니다.
          해당 파일을 확인해주세요.
          `;
          alert(alertText);
        }
      }
    }).finally(() => {
      setLoadingStatus(false);
      props.close();
    });
  };

  const selectUploadFile = (e) => {
    const confirmText = `수주를 업로드 하시겠습니까?`;
    if (window.confirm(confirmText) === true) {
      setLoadingStatus(true);
      const fileList = e.target.files;
      const file = fileList[0];
      if (file === undefined) return;
      excelExportCommon(e, excelSetting);
    } else return;
  };

  const downloadFormat = () => {
    let headerRow = [];

    const OneRow = {};
    OneRow['수주일'] = '';
    OneRow['납품요구일'] = '';
    OneRow['납기일'] = '';
    OneRow['수주코드'] = '';
    OneRow['수주이름'] = '';
    OneRow['거래처'] = '';
    OneRow['수주주소'] = '';
    OneRow['수주총금액'] = '';
    OneRow['비고'] = '';
    if (_contractType !== 'all') { // 수주 항목
      _contractAttrList.forEach((thisItem) => {OneRow[thisItem.contractAttributeName] = '';});
    }
    OneRow['수주물품코드'] = '';
    OneRow['수주단가'] = '';
    OneRow['수주수량'] = '';
    headerRow.push(OneRow);
    console.log('headerRow : ', headerRow);

    if (headerRow.length > 0) {
      const ws = XLSX.utils.json_to_sheet(headerRow);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `수주 업로드 양식`);
      XLSX.writeFile(wb, `수주 업로드 양식.xlsx`);
    }
  };

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>수주 업로드</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <ContentSection>
                <SelectSection
                  value={_contractType}
                  onChange={(e) => {
                    const contractType = e.target.value;
                    if (!checkEmptyNull(contractType, false)) UploadContractInput.current.disabled = true;
                    setContractType(() => {return contractType;});
                    getContractAttrs(contractType);
                  }}
                >
                  <option value="all">수주유형</option>
                  {_contractTypes.map((type) => {
                    return (
                      <option key={type.contractTypeId} value={type.contractTypeId}>{type.contractTypeName}</option>
                    );
                  })}
                </SelectSection>
                <ButtonsSection>
                  <FormatButton onClick={downloadFormat}>양식 다운</FormatButton>

                  <input
                    type="file"
                    ref={UploadContractInput}
                    id="ContractUploadModalInput"
                    style={{ display: 'none' }}
                    accept=".xlsx, .xls, .csv"
                    disabled={false}
                    onChange={(e) => {selectUploadFile(e);}}
                  />
                  <UploadModalButton
                    htmlFor="ContractUploadModalInput"
                    onClick={() => {
                      if (_contractType === '') return alert('수주유형을 선택해주세요.');
                      else UploadContractInput.current.disabled = false;
                    }}
                  >
                    업로드
                  </UploadModalButton>
                </ButtonsSection>

                <NoticeSection>
                  <p>※ 순서 ※</p>
                  <p>1. 항목명을 한줄로 만들어주세요.</p>
                  <p>2. 데이터가 없는 줄은 삭제하고 업로드 해주세요.</p>
                  <p>
                    ※ 엑셀 내 소수점이 있는 경우 셀서식 - 표시형식 - 숫자 - 소수
                    자릿수를 0으로 설정한 후 업로드 해주세요.
                  </p>
                  <p>3. 업로드</p>
                </NoticeSection>
              </ContentSection>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}

      {_loadingStatus === true ? (<LoadingScreen>수주를 업로드하고 있습니다...</LoadingScreen>) : null}
    </>
  );
};

export default ContractUploadModal;
