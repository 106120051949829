import React, { useEffect, useState } from 'react';

import Information from '../content/Information';
import ItemList from '../content/ItemList';

const SalesBoardDetail = ({
  modalType, modalData,
  update,
  close
}) => {
  const [_body, setBody] = useState({ ...modalData });
  const [_tab, setTab] = useState('info'); // 탭 설정

  const dataKeys = [
    // { key: 'title', title: '제목', },
    { key: 'type', title: '거래유형', },
    { key: 'targetDate', title: '마감일', },
    { key: 'manager', title: '담당자', },
    { key: 'phoneNumber', title: '연락처', },
    { key: 'detail', title: '상세 내용', },
  ];
  const tabKeys = [
    { key: 'info', title: '게시글', content: <Information dataKeys={dataKeys} data={_body} /> },
    { key: 'list', title: '상품 목록', content: <ItemList modalType={modalType} data={_body} update={update} /> },
  ];

  useEffect(() => { }, [_body, _tab]);

  const handleTabContent = (tab) => {
    const currentTab = tabKeys.find(item => item.key === tab);
    return currentTab.content;
  }

  return (
    <section className='common'>
      <nav>
        {tabKeys.map((tabKey, index) => {
          return (
            <button
              key={index + '_tab'}
              className={_tab === tabKey.key ? 'active' : ''}
              onClick={() => { setTab(() => { return tabKey.key }) }}
            >{tabKey.title}</button>
          );
        })}
      </nav>
      {handleTabContent(_tab)}
    </section>
  );
};

export default SalesBoardDetail;