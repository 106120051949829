import React, { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  max-height: 400px;
  max-width: 500px;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: inherit;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
`;
const FormSection = styled.div`
  align-items: center;
  row-gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const FormBox = styled.div`
  align-items: center;
  display: grid;
  column-gap: 10px;
  grid-template-columns: 100px auto;
  width: 100%;

  input {
    border: 1px solid var(--Text);
    border-radius: 5px;
    box-sizing: border-box;
    font-weight: 600;
    padding: 10px 20px;

    &[name='successfulAmount'] {
      border-color: var(--MainGreen);
      color: var(--MainGreen);
    }
    &[name='failedAmount'] {
      border-color: var(--MainRed);
      color: var(--MainRed);
    }
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const UriHandleAmount = (props) => {
  /* ====================================================================== #1 */
  /* ====================================================================== #2 */
  const [_body, setBody] = useState(props.content);

  /* ====================================================================== #3 */
  useEffect(() => {
    setBody(() => { return props.content });

    return () => { };
  }, [props]);

  useEffect(() => { }, [_body]);

  /* ====================================================================== #4 */
  const handleAmountEvent = (e) => {
    const { name, value } = e.target;
    setBody((prev) => { return { ...prev, [name]: value }; })
  };

  /* ====================================================================== #5 */
  const actUpdate = async () => {
    const amount = BigNumber(checkEmptyNull(_body.amount, 0)).toNumber();
    const successfulAmount = BigNumber(checkEmptyNull(_body.successfulAmount, 0)).toNumber();
    const failedAmount = BigNumber(checkEmptyNull(_body.failedAmount, 0)).toNumber();
    const body = {
      ..._body,
      amount: !isNaN(amount) ? amount : 0,
      successfulAmount: !isNaN(successfulAmount) ? successfulAmount : 0,
      failedAmount: !isNaN(failedAmount) ? failedAmount : 0,
    };
    await workOrderLogApi.updateWorkOrderLog(body.workOrderLogId, body).then(response => {
      if (response === undefined) return;
      console.log('workOrderLogApi.updateWorkOrderLog : ', response);
      props.update();
    })
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>수량 변경</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <FormSection>
                <FormBox>
                  <h4>총 완료 수량</h4>
                  <input
                    type="number"
                    name="amount"
                    min={0}
                    data-required="required"
                    placeholder="* 총 완료 수량..."
                    value={_body.amount}
                    onChange={handleAmountEvent}
                  />
                </FormBox>
                <FormBox>
                  <h4>성공수량</h4>
                  <input
                    type="number"
                    name="successfulAmount"
                    min={0}
                    data-required="required"
                    placeholder="* 성공수량..."
                    value={_body.successfulAmount}
                    onChange={handleAmountEvent}
                  />
                </FormBox>
                <FormBox>
                  <h4>불량수량</h4>
                  <input
                    type="number"
                    name="failedAmount"
                    min={0}
                    data-required="required"
                    placeholder="* 불량수량..."
                    value={_body.failedAmount}
                    onChange={handleAmountEvent}
                  />
                </FormBox>
              </FormSection>
            </ModalMain>

            <ModalFooter>
              <ModalButton onClick={actUpdate}>저장</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default UriHandleAmount;
