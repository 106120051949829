import styled from 'styled-components';

export const UserModalComponent = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100svh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100svw;
  z-index: 999;

  &>div { // modal 레이아웃
    background-color: var(--white);
    border-radius: 10px;
    display: grid;
    grid-template-rows: 50px calc(100% - 50px);
    height: 85%;
    min-height: 300px;
    width: 85%;

    &.download {max-height: 300px; max-width: 400px;}
    &.team {max-height: 650px; max-width: 550px;}
    &.workerCreate {max-height: 430px; max-width: 400px;}
    &.workerUpdate {max-height: 400px; max-width: 400px;}
    &.update {max-height: 550px; max-width: 400px;}
    &.email {max-height: 300px; max-width: 400px;}
    &.delete {max-height: 350px; max-width: 400px;}

    & header { // modal 헤더
      align-items: center;
      box-sizing: border-box;
      display: flex;
      height: 50px;
      justify-content: space-between;
      padding-inline: 20px;
      width: 100%;
    }

    & section { // modal content
      box-sizing: border-box;
      height: 100%;
      padding: 30px;
      width: 100%;

      &.download-content {
        display: grid;
        gap: 10px;
        grid-template-rows: calc(100% - 70px) 60px;

        &>div {
          align-content: flex-start;
          display: grid;
          gap: 20px;
          height: 100%;
          overflow-y: auto;
          width: 100%;

          &>div {
            display: grid;
            gap: 5px;
            grid-template-columns: 100px calc(100% - 105px);
            
            & select, & input{ // 검색 정보
              border: 1px solid var(--gray-200);
              border-radius: 8px;
              box-sizing: border-box;
              height: 35px;
              padding-inline: 10px;
              width: 100%;

              &:disabled {
                background-color: var(--Bg);
              }
            }
          }
        }
        & button.btn-save { // 저장 버튼
          background: linear-gradient(to right, #240000, #bb0f0f);
          height: 100%;
          width: 100%;
        }
      }

      &.team-content {
        display: grid;
        gap: 20px;
        grid-template-rows:  50px 80px calc(100% - 240px) 50px;

        &>div:nth-of-type(1) { // 검색
          display: grid;
          gap: 5px;
          height: 100%;
          width: 100%;

          &>select, & input {
            border: 1px solid var(--gray-200);
            border-radius: 8px;
            box-sizing: border-box;
            height: 35px;
            padding-inline: 10px;
            width: 100%;
          }

          &>div {
            display: flex;
            gap: 10px;

            &>button {
              align-items: center;
              background-color: var(--MainNavy);
              box-sizing: border-box;
              color: var(--white);
              cursor: pointer;
              display: flex;
              font-size: 15px;
              font-weight: 600;
              height: 35px;
              justify-content: center;
              text-align: center;
              width: 80px;
            }
          }
        }

        &>div:nth-of-type(2) { // 현재 팀
          display: grid;
          gap: 10px;
          height: fit-content;
          width: 100%;

          &>p {
            background-color: var(--MainNavy);
            border: 1px solid var(--MainNavy);
            box-sizing: border-box;
            color: var(--white);
            cursor: default;
            display: grid;
            height: 40px;
            width: 100%;

            & span {
              box-sizing: border-box;
              display: inline-block;
              height: 40px;
              line-height: 40px;
              text-align: center;
            }
          }
        }

        &>div:nth-of-type(3) { // team 리스트
          align-content: flex-start;
          display: grid;
          gap: 20px;
          height: 100%;
          overflow-y: auto;
          position: relative;
          width: 100%;
          
          &>h4 {
            background-color: var(--white);
            box-sizing: border-box;
            display: block;
            height: 40px;
            position: sticky;
            top: 0px;
          }

          &>p { // team
            border: 1px solid var(--MainNavy);
            box-sizing: border-box;
            cursor: pointer;
            display: grid;
            height: 40px;
            transition: all.3s;
            width: 100%;

            &:hover {
              background: linear-gradient(to right, #001d49, #0046af);
              color: var(--white);
            }

            & span {
              box-sizing: border-box;
              display: inline-block;
              height: 40px;
              line-height: 40px;
              text-align: center;
            }
          }
        }
        &>ul {
          transform: scale(0.9);
        }
      }

      &.user-content {
        display: grid;
        gap: 10px;
        grid-template-rows: calc(100% - 70px) 60px;

        &>div {
          align-content: flex-start;
          display: grid;
          gap: 20px;
          height: 100%;
          overflow-y: auto;
          width: 100%;

          &>div {
            display: grid;
            gap: 5px;
            grid-template-columns: 100px calc(100% - 105px);
            
            & select, & input, & textarea { // 사용자 정보 입력
              border: 1px solid var(--gray-200);
              border-radius: 8px;
              box-sizing: border-box;
              height: 35px;
              padding-inline: 10px;
              width: 100%;

              &:disabled {
                background-color: var(--Bg);
              }
            }
            & textarea {
              height: 80px;
            }
          }

          &>p {
            color: var(--SeconBlue);
            font-size: 0.8em;
            line-height: 40px;
            text-align: center;
          }
        }
        & button.btn-save { // 저장 버튼
          background: linear-gradient(to right, #001d49, #0046af);
          height: 100%;
          width: 100%;
        }
      }
      
      &.email-content {
        display: grid;
        gap: 10px;
        grid-template-rows: calc(100% - 70px) 60px;

        &>div:nth-of-type(1) { // 이메일 정보
          display: grid;
          gap: 5px;
          grid-template-columns: 100px calc(100% - 105px);
          
          & input { // email
            border: 1px solid var(--gray-200);
            border-radius: 8px;
            box-sizing: border-box;
            height: 35px;
            padding-inline: 10px;
            width: 100%;

            &:disabled {
              background-color: var(--Bg);
            }
          }
        }
        &>div:nth-of-type(2) { // 이메일 동의
          align-items: center;
          display: flex;
          gap: 10px;
          justify-content: center;
          
          & button.btn-save { // 동의 버튼
            height: 100%;
            width: 100%;
            
            &:nth-of-type(1) {background: linear-gradient(to right, #001d49, #0046af);}
            &:nth-of-type(2) {background: linear-gradient(to right, #240000, #bb0f0f);}
          }
        }
      }
      
      &.delete-content {
        display: grid;
        gap: 10px;
        grid-template-rows: calc(100% - 70px) 60px;

        &>div {
          align-content: flex-start;
          display: grid;
          gap: 20px;
          height: 100%;
          overflow-y: auto;
          width: 100%;

          &>div {
            display: grid;
            gap: 5px;
            grid-template-columns: 100px calc(100% - 105px);
            
            & input{ // 사용자 정보
              border: 1px solid var(--gray-200);
              border-radius: 8px;
              box-sizing: border-box;
              height: 35px;
              padding-inline: 10px;
              width: 100%;

              &:disabled {
                background-color: var(--Bg);
              }
            }
          }
        }
        & button.btn-save { // 저장 버튼
          background: linear-gradient(to right, #240000, #bb0f0f);
          height: 100%;
          width: 100%;
        }
      }
    }
  }
`;

export const LoadingScreen = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  color: var(--white);
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 999;
`;
