export const productionPerformanceActions_setWorkOrderType = (workOrderType) => {
  return { type: 'SETPRODUCTIONPERFORMANCEWORKORDERTYPE', payload: workOrderType };
};

export const productionPerformanceActions_setWorkOrderStatus = (workOrderStatus) => {
  return { type: 'SETPRODUCTIONPERFORMANCEWORKORDERSTATUS', payload: workOrderStatus };
};

export const productionPerformanceActions_setStartDate = (startDate) => {
  return { type: 'SETPRODUCTIONPERFORMANCESTARTDATE', payload: startDate };
};

export const productionPerformanceActions_setEndDate = (endDate) => {
  return { type: 'SETPRODUCTIONPERFORMANCEENDDATE', payload: endDate };
};

export const productionPerformanceActions_setSearchOption = (searchData) => {
  return { type: 'SETPRODUCTIONPERFORMANCESEARCHOPTION', payload: searchData };
};
export const productionPerformanceActions_setSearchText = (searchData) => {
  return { type: 'SETPRODUCTIONPERFORMANCESEARCHTEXT', payload: searchData };
};
export const productionPerformanceActions_setSearchData = (searchData) => {
  return { type: 'SETPRODUCTIONPERFORMANCESEARCHDATA', payload: searchData };
};

export const productionPerformanceActions_setPageNumber = (pageNumber) => {
  return { type: 'SETPRODUCTIONPERFORMANCEPAGENUMBER', payload: pageNumber };
};
export const productionPerformanceActions_setTotalSize = (totalSize) => {
  return { type: 'SETPRODUCTIONPERFORMANCETOTALSIZE', payload: totalSize };
};
export const productionPerformanceActions_setIsPushedSearchButton = (isPushedSearchButton) => {
  return { type: 'SETPRODUCTIONPERFORMANCEISPUSHEDSEARCHBUTTON', payload: isPushedSearchButton };
};

export const productionPerformanceActions_setElementType = (elementType) => {
  return { type: 'SETPRODUCTIONPERFORMANCEELEMENTTYPE', payload: elementType };
};
export const productionPerformanceActions_setSearchAttrs = (serachAttrs) => {
  return { type: 'SETPRODUCTIONPERFORMANCESEARCHATTS', payload: serachAttrs };
};

export const productionPerformanceActions_setClickedCategory = (category) => {
  return { type: 'SETPRODUCTIONPERFORMANCECLICKEDCATEGORY', payload: category };
};
export const productionPerformanceActions_setClickedLevel1Category = (category) => {
  return { type: 'SETPRODUCTIONPERFORMANCECLICKEDLEVEL1CATEGORY', payload: category };
};
export const productionPerformanceActions_setClickedLevel2Category = (category) => {
  return { type: 'SETPRODUCTIONPERFORMANCECLICKEDLEVEL2CATEGORY', payload: category };
};
export const productionPerformanceActions_setClickedLevel3Category = (category) => {
  return { type: 'SETPRODUCTIONPERFORMANCECLICKEDLEVEL3CATEGORY', payload: category };
};
export const productionPerformanceActions_setClickedLevel4Category = (category) => {
  return { type: 'SETPRODUCTIONPERFORMANCECLICKEDLEVEL4CATEGORY', payload: category };
};
export const productionPerformanceActions_setClickedLevel5Category = (category) => {
  return { type: 'SETPRODUCTIONPERFORMANCECLICKEDLEVEL5CATEGORY', payload: category };
};

export const productionPerformanceActions_selectOneElement = (element) => {
  return { type: 'SETPRODUCTIONPERFORMANCESELECTONEELEMENT', payload: element };
};

export const productionPerformanceActions_selectElements = (elements) => {
  return { type: 'SETPRODUCTIONPERFORMANCESELECTELEMENTS', payload: elements };
};

export const productionPerformanceActions_setLevel1Categories = (category) => {
  return { type: 'SETPRODUCTIONPERFORMANCESETLEVEL1CATEGORIES', payload: category };
};
export const productionPerformanceActions_setLevel2Categories = (category) => {
  return { type: 'SETPRODUCTIONPERFORMANCESETLEVEL2CATEGORIES', payload: category };
};
export const productionPerformanceActions_setLevel3Categories = (category) => {
  return { type: 'SETPRODUCTIONPERFORMANCESETLEVEL3CATEGORIES', payload: category };
};
export const productionPerformanceActions_setLevel4Categories = (category) => {
  return { type: 'SETPRODUCTIONPERFORMANCESETLEVEL4CATEGORIES', payload: category };
};
export const productionPerformanceActions_setLevel5Categories = (category) => {
  return { type: 'SETPRODUCTIONPERFORMANCESETLEVEL5CATEGORIES', payload: category };
};

export const productionPerformanceActions_setBookmark = (status) => {
  return { type: 'SETPRODUCTIONPERFORMANCEBOOKMARK', payload: status };
};


export const productionPerformanceActions_setReset = () => {
  return { type: 'SETPRODUCTIONPERFORMANCERESET' };
};