import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { shipmentInspection } from 'api/apis/shipmentInspection/shipmentInspection';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import NavText from 'components/nav/NavText';

const DeleteShipmentInspection = () => {
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  const [searchParams, setSearchParams] = useSearchParams();
  const deleteContent = JSON.parse(searchParams.get('deleteContent'));

  const actDelete = async () => {
    await shipmentInspection.deleteShipmentInspection(deleteContent.shipmentInspectionId).then((response) => {
      if (response === undefined) return;
      console.log('shipmentInspection.deleteShipmentInspection : ', response);
      alert('삭제했습니다.');
      setTimeout(navigate(pageReducer.currentPage, { replace: true }), 1000);
    });
  };

  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <main className="Main">
        <NavBar title={<NavTitle menuCode={'114'} />} nav={''} />

        <DeleteForm
          delteTitle={<NavTitle menuCode={'114'} />}
          deleteItem={
            <>
              <NavText menuCode={'604'} />{' 이름 : ' + deleteContent.shipmentInspectionCode}<br />
              {'프로젝트 : ' + deleteContent.shipmentInspectionName}<br />
            </>
          }
          deleteText={<>해당 <span className="cautionText"><NavText menuCode={'604'} /></span>가 삭제됩니다.</>}
          deleteButton={
            <>
              <button className="formButton cancle" onClick={() => { navigate(pageReducer.currentPage, { replace: true }) }}>
                취소
              </button>
              <button className="formButton delete" onClick={() => { if (window.confirm(`삭제합니다.`)) actDelete(); }}>
                삭제
              </button>
            </>
          }
        />
      </main>
    }
    />
  );
};

export default DeleteShipmentInspection;
