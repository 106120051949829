const initialState = {
  workOrderContents: [],
  workOrderContent: {},

  updateWorkOrder: {},

  updateContent: {},

  drawingList: [],

  wokerScreenContent: null,

  viewScale: 65,
};

const uriReducer = (state = initialState, action) => {
  // console.log(state, action);
  switch (action.type) {
    case 'SETURIWORKORDERCONTENTS':
      return {
        ...state,
        workOrderContents: action.payload,
      };

    case 'SETURIWORKORDERCONTENT':
      return {
        ...state,
        workOrderContent: action.payload,
      };

    case 'SETURIUPDATEWORKORDER':
      return {
        ...state,
        updateWorkOrder: action.payload,
      };

    case 'SETURIUPDATECONTENT':
      return {
        ...state,
        updateContent: action.payload,
      };

    case 'SETURIDRAWINGLIST':
      return {
        ...state,
        drawingList: action.payload,
      };

    case 'SETURIWORKERSCREENCONTENT':
      return {
        ...state,
        wokerScreenContent: action.payload,
      };

    case 'SETURIVIEWSCALE':
      return {
        ...state,
        viewScale: action.payload,
      };

    case 'URIRESET':
      return initialState;

    default:
      return state;
  }
};

export default uriReducer;
