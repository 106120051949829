import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { checkEmptyNull } from 'components/checkValues/checkValues';

const TotalMenuSection = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 24px 60px;
  z-index: 901 !important;
  background-color: var(--MainNavy);
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0px 8px 16px #333;
  width: 100%;
  padding-bottom: 40px;

  @media screen and (max-width: 1260px) {
    display: flex;
    padding: 24px;
    &::-webkit-scrollbar {display: none;}
  }
`;

const SubMenuSection = styled.li`
  display: grid;
  align-items: flex-start;
  padding: 0px 10px;
  gap: 24px;
  width: inherit;
  height: 100%;

  &>p {
    border-bottom: 1px solid #27426d;
    padding-bottom: 16px;
    width: 100%;
    text-align: center;
    font-weight: bold;
  }
  a {
    display: flex;
    width: 100%;
    opacity: .9;
    color: var(--white);
    gap: 6px;
    align-items: center;
    &:hover {
      color: var(--MainBlue);
      p {
        &:nth-of-type(1){
          opacity: 1;
          color: var(--MainBlue);
        }
      }
    }
  }
  @media screen and (max-width: 1260px) {
    /* width: 100%; */
    font-size: .875rem;
  }
  @media screen and (min-width: 1920px) {
    font-size: 16px;
  }
  
  a {
    p {
      &:nth-of-type(1){
        opacity: .5;
        width: 16px;
        text-align: center;
        line-height: 120%;
        color: #fff;
        font-size: .875rem;
      }
    }
  }
`;

const TotalMenus = () => {
  const { userReducer } = useSelector((state) => state);

  const [_userMenus, setUserMenus] = useState([...userReducer.userMenu]);
  const [_topMenus, setTopMenus] = useState([]);
  const [_subMenus, setSubMenus] = useState([]);

  useEffect(() => {
    setMenuList();
    return () => { };
  }, []);

  useEffect(() => { }, [_topMenus, _subMenus])

  const setMenuList = () => {
    const topMenuList = [];
    const subMenuList = [];
    const sortList = [..._userMenus].sort((a, b) => {
      if (a.indexX > b.indexX) return 1;
      if (a.indexX < b.indexX) return -1;
      if (a.indexY > b.indexY) return 1;
      if (a.indexY < b.indexY) return -1;
      else return 0;
    });
    sortList.forEach((menu, index) => {
      if (!checkEmptyNull(menu.companyPageCodeId, false)) return;
      if (menu.pageCode.includes('00')) topMenuList.push(menu);
      else subMenuList.push(menu)
      if (index === _userMenus.length - 1) {
        setTopMenus(topMenuList);
        setSubMenus(subMenuList);
      }
    });
  };

  return (
    <TotalMenuSection id="TotalMenuSection" menuAmount={_topMenus.length}>
      {_topMenus.map((menu) => {
        let menuNum = 0;
        return (
          <SubMenuSection key={menu.pageCode}>
            <p>{menu.pageName}</p>
            {_subMenus.map((subMenu, index) => {
              if (menu.indexX === subMenu.indexX) {
                menuNum++;
                return (
                  <Link key={index + '_subMenu'} to={subMenu.pageUrl} data-menucode={menu.pageCode}>
                    <p>{menuNum}</p>
                    <p>{subMenu.pageName}</p>
                  </Link>
                );
              } else return null;
            })}
          </SubMenuSection>
        );
      })}
    </TotalMenuSection>
  );
};

export default TotalMenus;
