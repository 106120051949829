import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat';

const ProcessSection = styled.section`
  align-items: flex-start;
  background-color: var(--white);
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  height: 100%;
  justify-content: center;
  overflow: auto;
  padding-block: 30px;
  width: 100%;

  & .processContent {
    align-items: flex-start;
    background-color: var(--white);
    border: 1px solid var(--gray-200);
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    box-sizing: border-box;
    cursor: pointer;
    display: grid;
    gap: 12px;
    height: fit-content;
    padding: 20px 30px;
    width: 90%;

    &:hover {box-shadow: unset;}
    &.active {
      background-color: var(--Bg);
      border: unset;
      box-shadow: unset;
      opacity: 0.7;
    }

    & .content-header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;

      & input[type="checkbox"] {
        margin: unset;
        z-index: 0;
      }
    }

    & .content-main {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(3, auto);
      width: 100%;

      & .content-data {
        align-items: flex-start;
        display: flex;
        /* flex-wrap: wrap; */
        gap: 10px;

        & b {
          color: var(--gray-300);
          font-size: 0.9em;
          font-weight: 600;
          min-width: 60px;
          text-align: right;
        }
      }

      & table {
        border-collapse: collapse;
        grid-column: 2/3;
        grid-row: 1/4;
        height: 100%;
        width: 100%;

        & th, & td {
          border: 1px solid var(--gray-200);
          box-sizing: border-box;
          padding: 5px;
          font-size: 0.8em;
          text-align: center;
        }
        & th {
          background-color: var(--MainNavy);
          color: var(--white);
        }
      }
    }
  }
`;

const Process = (props) => {
  const { currentProcessEvent } = props;
  const [_processList, setProcessList] = useState(props.processList);
  const [_currentProcess, setCurrentProcess] = useState(props.currentProcess);

  useEffect(() => {
    setProcessList(() => { return props.processList });
    setCurrentProcess(() => { return props.currentProcess });
  }, [props])

  return (
    <>
      <ProcessSection className='processSection'>
        {checkNullArray(_processList, []).map((process, index) => {
          return (
            <div key={index + '_process'}
              className={_currentProcess.processId === process.processId ? 'processContent active' : 'processContent'}
              onClick={() => {
                currentProcessEvent(() => { return process });
              }}
            >
              <div className='content-header'>
                <div></div>
                <div>{DateTimeFormat(checkEmptyNull(process.createdDate, ''))}</div>
              </div>
              <div className='content-main'>
                {[
                  { name: '공정유형', value: process.processTypeName },
                  { name: '공정코드', value: process.processCode },
                  { name: '공정이름', value: process.processName },
                ].map((content, contentIndex) => {
                  return (
                    <div key={contentIndex + '_content_process'} className='content-data'>
                      <b>{content.name}</b>{content.value}
                    </div>
                  )
                })}

                <table>
                  <thead>
                    <tr>
                      <th colSpan={3}>공정완료제품</th>
                    </tr>
                    <tr>
                      <th>물품이름</th>
                      <th>물품코드</th>
                      <th>생산량</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkNullArray(process.processElementList, []).map((element, elementIndex) => {
                      if (element.processElementType !== 'Make') return null;
                      return (
                        <tr key={elementIndex + '_processElement'}>
                          <td>{element.elementName}</td>
                          <td>{element.elementCode}</td>
                          <td>{checkEmptyNull(element.amount, 0).toLocaleString()} {checkEmptyNull(element.elementUnit, '')}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )
        })}
      </ProcessSection>
    </>
  );
};

export default Process;