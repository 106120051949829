import React, { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  height: 85%;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: space-between;
  padding: 0px 60px 0px 20px;
  position: relative;
  width: 100%;
  box-shadow: 0 4px 18px 0 rgba(196,196,196,0.8);
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const ModalButton = styled.div`
  width: 20%;
  border-radius: 8px;
  height: fit-content;
  padding: 8px;
  box-shadow: 0 2px 6px 0 rgba(196,196,196,0.8);
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {height: 100%; overflow-x: auto; width: 100%;}
`;
const MainContents = styled.div`
  box-sizing: border-box;
  height: 100%;
  justify-content: center;
  padding: 10px;
  width: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {height: 8px; width: 8px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 4px;}
`;

const LoadingScreen = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  color: var(--white);
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

/* ========================= PrintContent ========================= */
const ExportSection = styled.div`
  box-sizing: border-box;
  display: grid;
  font-size: 0.75em;
  grid-template-rows: repeat(2, calc(50% - 15px));
  justify-content: center;
  margin: 30px auto;
  overflow: hidden;
  row-gap: 30px;

  height: 297mm;
  width: 210mm;

  table {
    border-collapse: collapse;
    border-spacing: 0;
    height: 100%;
    width: 100%;

    td {
      border-style: solid;
      border-width: 1px;
      box-sizing: border-box;
      line-height: 15px;
      overflow: hidden;
      text-align: center;
      vertical-align: middle;
      word-break: break-all;
    }
  }

`;

const ForSupplier = styled.div`
  box-sizing: border-box;
  color: red;
  display: grid;
  grid-template-rows: 70px 110px auto 45px 25px;
  height: 100%;
  row-gap: 4px;
  width: 100%;

  td, th {border: 1px solid ${(props) => props.color || 'red'};}
`;
const ForSupply = styled(ForSupplier)`
  color: blue;
  td, th {border: 1px solid ${(props) => props.color || 'blue'};}
`;

/* ========================= Section1 ========================= */
const Section1 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 70px;
  width: 100%;
`;
const Section1Left = styled.div`
  display: flex;
  flex: 1;
  width: 100%;

  td.infoBirth {min-width: 80px; width: 80px;}
`;
const Section1Center = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;
const Section1Right = styled.div`
  display: flex;
  flex: 1;

  & table {
    table-layout: unset;
    & > tr:first-child {height: 20px;}
  }
`;

/* ========================= Section2 ========================= */
const Section2 = styled.div`
  height: 100%;
  width: 100%;

  .tg-o5vb.non-input {line-height: 15px; width: 40px;}
  .tg-o5vb.input {color: black; width: 200px;}
`;

/* ========================= Section3 ========================= */
const Section3 = styled.div`
  width: 100%;

  table {
    table-layout: fixed;

    td, th {
      min-width: 60px;
  
      &.long {min-width: 150px; width: 150px;}
    }
  
    tbody {
      tr {height: 20px;}
      td {color: var(--Text); font-weight: normal; font-size: 0.8em;}
    }
  }
`;

/* ========================= Section4 ========================= */
const Section4 = styled.div`
  height: 100%;
  width: 100%;

  table {
    table-layout: fixed;

    td.input {color: var(--Text); padding-right: 10px; text-align: right;}
  }
`;

const PrintContent = (props) => {
  /* ====================================================================== #1 */
  /* ====================================================================== #2 */
  const [_printPage, setPrintPage] = useState(`${props.currentPage} / ${props.totalPage}`);
  const [_companyInformation, setCompanyInformation] = useState(props.companyInformation);
  const [_accountInformation, setAccountInformation] = useState(props.accountInformation);
  const [_shipment, setProcurement] = useState(props.shipment);
  const [_shipmentElementList, setProcurementElementList] = useState(() => {
    const returnArray = [];
    for (let i = 0; i < parseFloat(10 - props.content.length); i++) {
      const emptyData = {
        empty: true,
        elementName: '',
        elementCode: '',

        price: 0, // 단가
        quantity: 0, // 수량
        calPrice: 0, // 금액

        elementStandard: '', // 규격
        elementDetailStandard: '', // 상세규격
      };

      returnArray.push(emptyData);
    }

    const returnData = [
      ...props.content.map((element) => {
        return {
          ...element,
          price: checkEmptyNull(element.price, 0).toLocaleString('ko-KR'),
          quantity: checkEmptyNull(element.quantity, 0).toLocaleString('ko-KR'),
          calPrice: checkEmptyNull(element.calPrice, 0).toLocaleString('ko-KR'),
        };
      }),
      ...returnArray,
    ];

    return returnData;
  });

  const [_viewPrice, setViewPrice] = useState(() => {
    const sumArray = props.content.map((item) => Number(checkEmptyNull(item.calPrice, 0).toString().replaceAll(',', '')));
    const setSumArray = sumArray.filter((item) => item !== null && !isNaN(item));
    const sum = setSumArray.reduce((a, b) => BigNumber(a).plus(BigNumber(b)).toNumber(), 0);
    const tax = BigNumber(checkEmptyNull(sum, 0)).multipliedBy(10).dividedBy(100).toNumber();
    const totalSum = BigNumber(checkEmptyNull(sum, 0)).plus(BigNumber(tax)).toNumber();

    const returnData = {
      sum: sum.toLocaleString('ko-KR'),
      tax: tax.toLocaleString('ko-KR'),
      totalSum: totalSum.toLocaleString('ko-KR'),
    };

    return returnData;
  });

  /* ====================================================================== #3 */
  useEffect(() => {
    setPrintPage(() => { return `${props.currentPage} / ${props.totalPage}` });
    setCompanyInformation(() => { return props.companyInformation });
    setAccountInformation(() => { return props.accountInformation });
    setProcurement(() => { return props.shipment });
    setProcurementElementList(() => {
      const returnArray = [];
      for (let i = 0; i < parseFloat(10 - props.content.length); i++) {
        const emptyData = {
          empty: true,
          elementName: '',
          elementCode: '',

          price: 0, // 단가
          quantity: 0, // 수량
          calPrice: 0, // 금액

          elementStandard: '', // 규격
          elementDetailStandard: '', // 상세규격
        };

        returnArray.push(emptyData);
      }

      const returnData = [
        ...props.content.map((element) => {
          return {
            ...element,
            price: checkEmptyNull(element.price, 0).toLocaleString('ko-KR'),
            quantity: checkEmptyNull(element.quantity, 0).toLocaleString('ko-KR'),
            calPrice: checkEmptyNull(element.calPrice, 0).toLocaleString('ko-KR'),
          };
        }),
        ...returnArray,
      ];

      return returnData;
    });

    setViewPrice(() => {
      const sumArray = props.content.map((item) => Number(checkEmptyNull(item.calPrice, 0).toString().replaceAll(',', '')));
      const setSumArray = sumArray.filter((item) => item !== null && !isNaN(item));
      const sum = setSumArray.reduce((a, b) => BigNumber(a).plus(BigNumber(b)).toNumber(), 0);
      const tax = BigNumber(checkEmptyNull(sum, 0)).multipliedBy(10).dividedBy(100).toNumber();
      const totalSum = BigNumber(checkEmptyNull(sum, 0)).plus(BigNumber(tax)).toNumber();

      const returnData = {
        sum: sum.toLocaleString('ko-KR'),
        tax: tax.toLocaleString('ko-KR'),
        totalSum: totalSum.toLocaleString('ko-KR'),
      };

      return returnData;
    });
  }, []);

  useEffect(() => { }, [_companyInformation]);
  useEffect(() => { }, [_shipmentElementList]);
  useEffect(() => { }, [_viewPrice]);

  /* ====================================================================== #4 */
  const calTax = (calPrice) => {
    if (!checkEmptyNull(calPrice, false)) return;
    const num = calPrice.toString().replace(/,/g, '');
    return BigNumber(isNaN(num) ? 0 : num).multipliedBy(10).dividedBy(100).toNumber();
  };

  /* ====================================================================== #5 */
  /* ====================================================================== #6 */

  return (
    <ExportSection id="ExportSection" name={'printContent'}>
      <ForSupplier>
        {/* ========================= Section1 ========================= */}
        <Section1>
          <Section1Left>
            <table>
              <tbody>
                <tr>
                  <td className="infoBirth">거래일자</td>
                  <td>
                    {(() => {
                      const date = moment(_shipment.shipmentDate).format('YYYY-MM-DD');
                      const splitDate = date.split('-');
                      return (
                        <>
                          <span style={{ color: 'var(--Text)' }}>{splitDate[0]}</span>
                          <span>년</span>
                          <span style={{ color: 'var(--Text)', marginLeft: '10px' }}>{splitDate[1]}</span>
                          <span>월</span>
                          <span style={{ color: 'var(--Text)', marginLeft: '10px' }}>{splitDate[2]}</span>
                          <span>일</span>
                        </>
                      );
                    })()}
                  </td>
                </tr>
                <tr>
                  <td className="infoBirth">일련번호</td>
                  <td style={{ color: 'var(--Text)' }}>{_shipment.shipmentCode}</td>
                </tr>
              </tbody>
            </table>
          </Section1Left>
          <Section1Center>
            <h2>거 래 명 세 표</h2>
            <div>{`(공급자용)`}</div>
            <div>{`${_printPage} 페이지`}</div>
          </Section1Center>
          <Section1Right>
            <table className="tg">
              <tbody>
                <tr>
                  <td rowSpan="2" className="tg-ubzu" style={{ width: '20px' }}>결재</td>
                  <td className="tg-o5vb"></td>
                  <td className="tg-o5vb"></td>
                  <td className="tg-o5vb"></td>
                  <td className="tg-o5vb"></td>
                  <td className="tg-o5vb"></td>
                </tr>
                <tr>
                  <td className="tg-o5vb"></td>
                  <td className="tg-o5vb"></td>
                  <td className="tg-o5vb"></td>
                  <td className="tg-o5vb"></td>
                  <td className="tg-o5vb"></td>
                </tr>
              </tbody>
            </table>
          </Section1Right>
        </Section1>

        {/* ========================= Section2 ========================= */}
        <Section2>
          <table className="tg">
            <thead>
              <tr style={{ height: '30px' }}>
                <td rowSpan="3" className="tg-ubzu" style={{ width: '20px' }}>공급자</td>
                <td className="tg-o5vb non-input">등록번호</td>
                <td className="tg-o5vb input">{_companyInformation.selfAccountCode}</td>
                <td className="tg-o5vb non-input">전화번호</td>
                <td className="tg-o5vb input">{_companyInformation.selfTelNumber}</td>
                <td rowSpan="3" className="tg-o5vb" style={{ width: '20px' }}>공급받는자</td>
                <td className="tg-o5vb non-input">등록번호</td>
                <td className="tg-o5vb input">{_accountInformation.shipmentBusinessNumber}</td>
                <td className="tg-o5vb non-input">전화번호</td>
                <td className="tg-o5vb input">{_accountInformation.shipmentTelNumber}</td>
              </tr>
              <tr style={{ height: '30px' }}>
                <td className="tg-o5vb non-input">상호</td>
                <td className="tg-o5vb input">{_companyInformation.selfAccountName}</td>
                <td className="tg-o5vb non-input">성명</td>
                <td className="tg-o5vb input">{_companyInformation.selfManagerName}</td>
                <td className="tg-o5vb non-input">상호</td>
                <td className="tg-o5vb input">{_accountInformation.shipmentAccountName}</td>
                <td className="tg-o5vb non-input">성명</td>
                <td className="tg-o5vb input">{_accountInformation.shipmentManagerName}</td>
              </tr>
              <tr style={{ height: '50px' }}>
                <td className="tg-o5vb non-input">주소</td>
                <td colSpan="3" className="tg-o5vb input">{_companyInformation.selfAddress}</td>
                <td className="tg-o5vb non-input">주소</td>
                <td colSpan="3" className="tg-o5vb input">{_accountInformation.shipmentAddress}</td>
              </tr>
            </thead>
          </table>
        </Section2>

        {/* ========================= Section3 ========================= */}
        <Section3>
          <table className="tg">
            <thead>
              <tr>
                <th className="tg-ubzu non-input long">상품코드</th>
                <th className="tg-coru non-input long">상품명</th>
                <th className="tg-coru non-input">규격</th>
                <th className="tg-coru non-input">상세규격</th>
                <th className="tg-coru non-input">수량</th>
                <th className="tg-coru non-input">단가</th>
                <th className="tg-coru non-input">공급가액</th>
                <th className="tg-coru non-input">세액</th>
                <th className="tg-coru non-input">메모</th>
              </tr>
            </thead>
            <tbody>
              {_shipmentElementList.map((element, index) => {
                return (<tr key={index + 'ForSupplier_elements'}>
                  <td className="tg-coru input long">{element.elementCode}</td>
                  <td className="tg-coru input long">{element.elementName}</td>
                  <td className="tg-coru input">{element.elementStandard}</td>
                  <td className="tg-coru input">{element.elementDetailStandard}</td>
                  <td className="tg-coru input">{checkEmptyNull(element.quantity, '') !== 0 ? checkEmptyNull(element.quantity, 0) : ''}</td>
                  <td className="tg-coru input">{checkEmptyNull(element.price, '') !== 0 ? checkEmptyNull(element.price, 0) : ''}</td>
                  <td className="tg-coru input">{checkEmptyNull(element.calPrice, '') !== 0 ? checkEmptyNull(element.calPrice, 0) : ''}</td>
                  <td className="tg-coru input">{checkEmptyNull(calTax(checkEmptyNull(element.calPrice, 0)), 0) !== 0 ? checkEmptyNull(calTax(checkEmptyNull(element.calPrice, 0)), 0) : ''}</td>
                  <td className="tg-coru input">{element.remark}</td>
                </tr>
                );
              })}
            </tbody>
          </table>
        </Section3>

        {/* ========================= Section4 ========================= */}
        <Section4>
          <table className="tg">
            <thead>
              <tr>
                <td className="tg-ubzu" style={{ width: '5%' }}>매출<br />총액</td>
                <td className="tg-coru input">{checkEmptyNull(_viewPrice.sum, '') !== 0 && checkEmptyNull(_viewPrice.sum, '') !== '0' ? checkEmptyNull(_viewPrice.sum, 0) : ''}</td>
                <td className="tg-coru" style={{ width: '5%' }}>세액</td>
                <td className="tg-coru input">{checkEmptyNull(_viewPrice.tax, '') !== 0 && checkEmptyNull(_viewPrice.tax, '') !== '0' ? checkEmptyNull(_viewPrice.tax, 0) : ''}</td>
                <td className="tg-coru" style={{ width: '5%' }}>합계</td>
                <td className="tg-coru input">{checkEmptyNull(_viewPrice.totalSum, '') !== 0 && checkEmptyNull(_viewPrice.totalSum, '') !== '0' ? checkEmptyNull(_viewPrice.totalSum, 0) : ''}</td>
                <td className="tg-coru" style={{ width: '5%' }}>전<br />잔금</td>
                <td className="tg-coru"></td>
                <td className="tg-coru" style={{ width: '5%' }}>미수<br />총계</td>
                <td className="tg-coru"></td>
              </tr>
            </thead>
          </table>
        </Section4>

        {/* ========================= * ========================= */}
        <div style={{ fontSize: '13px' }}>※ 계좌번호 : {_companyInformation.selfBusinessNumber}</div>
      </ForSupplier>

      {/* ForSupply */}
      <ForSupply>
        {/* ========================= Section1 ========================= */}
        <Section1>
          <Section1Left>
            <table>
              <tbody>
                <tr>
                  <td className="infoBirth">거래일자</td>
                  <td>
                    {(() => {
                      const date = moment(_shipment.shipmentDate).format('YYYY-MM-DD');
                      const splitDate = date.split('-');
                      return (
                        <>
                          <span style={{ color: 'var(--Text)' }}>{splitDate[0]}</span>
                          <span>년</span>
                          <span style={{ color: 'var(--Text)', marginLeft: '10px' }}>{splitDate[1]}</span>
                          <span>월</span>
                          <span style={{ color: 'var(--Text)', marginLeft: '10px' }}>{splitDate[2]}</span>
                          <span>일</span>
                        </>
                      );
                    })()}
                  </td>
                </tr>
                <tr>
                  <td className="infoBirth">일련번호</td>
                  <td style={{ color: 'var(--Text)' }}>{_shipment.shipmentCode}</td>
                </tr>
              </tbody>
            </table>
          </Section1Left>
          <Section1Center>
            <h2>거 래 명 세 표</h2>
            <div>{`(공급받는자용)`}</div>
            <div>{`${_printPage} 페이지`}</div>
          </Section1Center>
          <Section1Right>
            <table className="tg">
              <tbody>
                <tr>
                  <td rowSpan="2" className="tg-ubzu" style={{ width: '20px' }}>결재</td>
                  <td className="tg-o5vb"></td>
                  <td className="tg-o5vb"></td>
                  <td className="tg-o5vb"></td>
                  <td className="tg-o5vb"></td>
                  <td className="tg-o5vb"></td>
                </tr>
                <tr>
                  <td className="tg-o5vb"></td>
                  <td className="tg-o5vb"></td>
                  <td className="tg-o5vb"></td>
                  <td className="tg-o5vb"></td>
                  <td className="tg-o5vb"></td>
                </tr>
              </tbody>
            </table>
          </Section1Right>
        </Section1>

        {/* ========================= Section2 ========================= */}
        <Section2>
          <table className="tg">
            <thead>
              <tr style={{ height: '30px' }}>
                <td rowSpan="3" className="tg-ubzu" style={{ width: '20px' }}>공급자</td>
                <td className="tg-o5vb non-input">등록번호</td>
                <td className="tg-o5vb input">{_companyInformation.selfAccountCode}</td>
                <td className="tg-o5vb non-input">전화번호</td>
                <td className="tg-o5vb input">{_companyInformation.selfTelNumber}</td>
                <td rowSpan="3" className="tg-o5vb" style={{ width: '20px' }}>공급받는자</td>
                <td className="tg-o5vb non-input">등록번호</td>
                <td className="tg-o5vb input">{_accountInformation.shipmentBusinessNumber}</td>
                <td className="tg-o5vb non-input">전화번호</td>
                <td className="tg-o5vb input">{_accountInformation.shipmentTelNumber}</td>
              </tr>
              <tr style={{ height: '30px' }}>
                <td className="tg-o5vb non-input">상호</td>
                <td className="tg-o5vb input">{_companyInformation.selfAccountName}</td>
                <td className="tg-o5vb non-input">성명</td>
                <td className="tg-o5vb input">{_companyInformation.selfManagerName}</td>
                <td className="tg-o5vb non-input">상호</td>
                <td className="tg-o5vb input">{_accountInformation.shipmentAccountName}</td>
                <td className="tg-o5vb non-input">성명</td>
                <td className="tg-o5vb input">{_accountInformation.shipmentManagerName}</td>
              </tr>
              <tr style={{ height: '50px' }}>
                <td className="tg-o5vb non-input">주소</td>
                <td colSpan="3" className="tg-o5vb input">{_companyInformation.selfAddress}</td>
                <td className="tg-o5vb non-input">주소</td>
                <td colSpan="3" className="tg-o5vb input">{_accountInformation.shipmentAddress}</td>
              </tr>
            </thead>
          </table>
        </Section2>

        {/* ========================= Section3 ========================= */}
        <Section3>
          <table className="tg">
            <thead>
              <tr>
                <th className="tg-ubzu non-input long">상품코드</th>
                <th className="tg-coru non-input long">상품명</th>
                <th className="tg-coru non-input">규격</th>
                <th className="tg-coru non-input">상세규격</th>
                <th className="tg-coru non-input">수량</th>
                <th className="tg-coru non-input">단가</th>
                <th className="tg-coru non-input">공급가액</th>
                <th className="tg-coru non-input">세액</th>
                <th className="tg-coru non-input">메모</th>
              </tr>
            </thead>
            <tbody>
              {_shipmentElementList.map((element, index) => {
                return (<tr key={index + 'ForSupplier_elements'}>
                  <td className="tg-coru input long">{element.elementCode}</td>
                  <td className="tg-coru input long">{element.elementName}</td>
                  <td className="tg-coru input">{element.elementStandard}</td>
                  <td className="tg-coru input">{element.elementDetailStandard}</td>
                  <td className="tg-coru input">{checkEmptyNull(element.quantity, '') !== 0 ? checkEmptyNull(element.quantity, 0) : ''}</td>
                  <td className="tg-coru input">{checkEmptyNull(element.price, '') !== 0 ? checkEmptyNull(element.price, 0) : ''}</td>
                  <td className="tg-coru input">{checkEmptyNull(element.calPrice, '') !== 0 ? checkEmptyNull(element.calPrice, 0) : ''}</td>
                  <td className="tg-coru input">{checkEmptyNull(calTax(checkEmptyNull(element.calPrice, 0)), 0) !== 0 ? checkEmptyNull(calTax(checkEmptyNull(element.calPrice, 0)), 0) : ''}</td>
                  <td className="tg-coru input">{element.remark}</td>
                </tr>
                );
              })}
            </tbody>
          </table>
        </Section3>

        {/* ========================= Section4 ========================= */}
        <Section4>
          <table className="tg">
            <thead>
              <tr>
                <td className="tg-ubzu" style={{ width: '5%' }}>매출<br />총액</td>
                <td className="tg-coru input">{checkEmptyNull(_viewPrice.sum, '') !== 0 && checkEmptyNull(_viewPrice.sum, '') !== '0' ? checkEmptyNull(_viewPrice.sum, 0) : ''}</td>
                <td className="tg-coru" style={{ width: '5%' }}>세액</td>
                <td className="tg-coru input">{checkEmptyNull(_viewPrice.tax, '') !== 0 && checkEmptyNull(_viewPrice.tax, '') !== '0' ? checkEmptyNull(_viewPrice.tax, 0) : ''}</td>
                <td className="tg-coru" style={{ width: '5%' }}>합계</td>
                <td className="tg-coru input">{checkEmptyNull(_viewPrice.totalSum, '') !== 0 && checkEmptyNull(_viewPrice.totalSum, '') !== '0' ? checkEmptyNull(_viewPrice.totalSum, 0) : ''}</td>
                <td className="tg-coru" style={{ width: '5%' }}>전<br />잔금</td>
                <td className="tg-coru"></td>
                <td className="tg-coru" style={{ width: '5%' }}>미수<br />총계</td>
                <td className="tg-coru"></td>
              </tr>
            </thead>
          </table>
        </Section4>

        {/* ========================= * ========================= */}
        <div style={{ fontSize: '13px' }}>※ 계좌번호 : {_companyInformation.selfBusinessNumber}</div>
      </ForSupply>
    </ExportSection>
  );
};

const InvoicePdfModal = (props) => {
  /* ========================================================================= #1 */
  /* ========================================================================= #2 */
  const { shipment } = props;

  const [_companyInformation, setCompanyInformation] = useState(() => {
    const returnData = {
      selfAccountCode: shipment.selfAccountCode,
      selfAccountName: shipment.selfAccountName,

      selfBusinessNumber: shipment.selfBusinessNumber,
      selfAddress: shipment.selfAddress,
      selfTelNumber: shipment.selfTelNumber,

      selfManagerName: shipment.selfManagerName,
    };
    return returnData;
  });
  const [_accountInformation, setAccountInformation] = useState(() => {
    const returnData = {
      accountId: shipment.accountId,
      shipmentAccountCode: shipment.shipmentAccountCode,
      shipmentAccountName: shipment.shipmentAccountName,

      shipmentBusinessNumber: shipment.shipmentBusinessNumber,
      shipmentAddress: shipment.shipmentAddress,
      shipmentTelNumber: shipment.shipmentTelNumber,

      shipmentManagerName: shipment.shipmentManagerName,
    };
    return returnData;
  });

  const [_printContents, setPrintContents] = useState(() => {
    const shipmentElementList = shipment.shipmentElementList;
    const returnList = [];
    if (checkNullArray(shipmentElementList, false)) {
      for (let i = 0; i < shipmentElementList.length; i += 10) {
        const sliceArray = shipmentElementList.slice(i, i + 10);
        const returnArray = sliceArray.map((element) => {
          const customizedContent = checkNullParse(element.customizedContent, {
            elementStandard: '',
            elementDetailStandard: '',
          });
          if (checkNullObject(element.element, false)) {
            const elementData = {
              ...element,

              elementId: element.element.elementId,
              elementName: element.element.elementName,
              elementCode: element.element.elementCode,
              scheduledInputStock: element.element.scheduledInputStock,
              elementSafeStock: element.element.elementSafeStock,
              elementStock: element.element.elementStock,

              price: element.element.price || 0, // 단가
              calPrice: element.price, // 금액

              elementStandard: customizedContent.elementStandard, // 규격
              elementDetailStandard: customizedContent.elementDetailStandard, // 상세규격
            };

            return elementData;
          } else {
            const elementData = {
              ...element,

              elementStandard: customizedContent.elementStandard, // 규격
              elementDetailStandard: customizedContent.elementDetailStandard, // 상세규격
            }
            return elementData;
          }
        });
        returnList.push(returnArray);
      }
      return returnList;
    } else {
      return [];
    }
  });

  const [_loadingStatus, setLoadingStatus] = useState(false);

  /* ========================================================================= #3 */
  /* ========================================================================= #4 */
  /* ========================================================================= #5 */
  const actPrintContent = () => {
    console.log('dd');
    console.log(document.getElementsByName('printContent'));

    const style = document.createElement('style');
    document.head.appendChild(style);
    style.sheet?.insertRule('body > div:last-child img { display: inline-block; }');

    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
    });

    document.getElementsByName('printContent').forEach(async (thisItem, index) => {
      const thisHeight = thisItem.offsetHeight;
      const thisWidth = thisItem.offsetWidth;
      const ratio = thisWidth / thisHeight;

      const option = {
        height: thisHeight,
        width: thisWidth,
      };

      await html2canvas(thisItem, option).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');

        let width = doc.internal.pageSize.getWidth();
        let height = 280;
        width = ratio * height;

        const margin = 6;
        const position = 6;

        doc.addImage(imgData.replace('data:image/png;base64,', ''), 'PNG', margin, position, width, height);
        if (index < document.getElementsByName('printContent').length - 1) {
          doc.addPage();
        }
      });

      if (index === document.getElementsByName('printContent').length - 1) {
        if (document.getElementsByName('printContent').length > 10) {
          style.remove();
          doc.save();
          setLoadingStatus(false);
        } else {
          style.remove();
          window.open(doc.output('bloburl'));
          setLoadingStatus(false);
        }
      }
    });
  };

  /* ========================================================================= #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>거래명세서 출력 미리보기</ModalTitle>
              <ModalButton onClick={() => { setTimeout(actPrintContent, 1000) }}>PDF 출력</ModalButton>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <MainContents id="PrintSection">
                {/* checkEmptyNull(_companyInformation.accountId, false) && */
                  !checkNullArray(_printContents, false) ? <>
                    <PrintContent
                      totalPage={1}
                      currentPage={1}
                      content={[]}
                      accountInformation={_accountInformation}
                      companyInformation={_companyInformation}
                      shipment={shipment}
                    />
                  </>
                    : checkNullArray(_printContents, []).map((content, index) => {
                      return (
                        <PrintContent
                          key={index + '_printContents'}
                          totalPage={_printContents.length}
                          currentPage={index + 1}
                          content={content}
                          accountInformation={_accountInformation}
                          companyInformation={_companyInformation}
                          shipment={shipment}
                        />
                      );
                    })}
              </MainContents>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}
      ;
      {_loadingStatus === true ? (<LoadingScreen>파일 생성 중입니다...</LoadingScreen>) : null}
    </>
  );
};

export default InvoicePdfModal;
