import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { equipmentSharingBoard, equipmentSharingType } from 'api/apis/platform/cluster/cluster';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

const RequestEquipment = ({
  modalType, modalData,
  update,
  close
}) => {
  const location = useLocation();
  const clusterId = location.pathname.split('/platform/cluster')[0];
  const clusterPath = clusterId + '/platform/cluster/mypage';

  const { clusterReducer } = useSelector(state => state);
  const { user } = clusterReducer;

  const [_sharingTypeLsit, setSharingTypeList] = useState([]); // 유형 리스트
  const [_sharingType, setSharingType] = useState(modalData.equipmentSharingTypeId || ''); // 유형 선택

  const [_body, setBody] = useState({ ...modalData });

  const dataKeys = [
    { key: 'title', title: '제목', },
    { key: 'term', title: '기간', },
    { key: 'price', title: '금액', },
    { key: 'manager', title: '담당자', },
    { key: 'phoneNumber', title: '연락처', },
    { key: 'address', title: '주소', },
    { key: 'detail', title: '상세 내용', },
  ];

  useEffect(() => {
    getTypeList();
    return () => { }
  }, []);
  useEffect(() => { }, [_body, _sharingType]);

  const getTypeList = async () => { // 유형 불러오기
    const id = Number(clusterId.replace('/', ''));
    const body = { clusterId: id };
    await equipmentSharingType.search('', body).then(response => {
      if (response === undefined) return;
      setSharingTypeList(() => { return response.data });
    })
  };

  const actSave = async (e) => { // create/update
    e.preventDefault();
    const id = Number(clusterId.replace('/', ''));
    const body = {
      // clusterId: id,
      companyId: user.companyId,
      ..._body,
    };

    if (!checkEmptyNull(body.equipmentSharingTypeId, false)) return alert('유형을 선택해주세요.');

    if (modalType.includes('create')) { // create
      await equipmentSharingBoard.create(body).then(response => {
        if (response === undefined) return;
        console.log('equipmentSharingBoard.create : ', response);
        update();
      })
    } else { // update
      delete body?.createdDate;
      delete body?.modifiedDate;

      await equipmentSharingBoard.update(body.equipmentSharingBoardId, body).then(response => {
        if (response === undefined) return;
        console.log('equipmentSharingBoard.update : ', response);
        update();
      })
    }
  };
  const actDelete = async () => { // delete
    const confirmText = `해당 요청을 삭제하시겠습니까?`;
    if (window.confirm(confirmText)) {
      await equipmentSharingBoard.delete(_body.equipmentSharingBoardId).then(response => {
        if (response === undefined) return;
        console.log('equipmentSharingBoard.delete : ', response);
        update();
      })
    }
  };

  return (
    <section>
      <div className='section-form'>
        <h2>장비 공유 요청</h2>

        <div className='form-content'>
          <h4>장비 유형</h4>
          <select
            disabled={modalType.endsWith('delete')}
            value={_body.equipmentSharingTypeId || ''}
            onChange={(e) => {
              setSharingType(() => { return Number(e.target.value); });
              setBody((prev) => {
                return { ...prev, equipmentSharingTypeId: Number(e.target.value) };
              });
            }}
          >
            <option value={''}>유형 선택</option>
            {checkNullArray(_sharingTypeLsit, []).map((sharingType, index) => {
              return (<option key={index + '_sharingType'} value={sharingType.equipmentSharingTypeId}>{sharingType.equipmentSharingTypeName}</option>);
            })}
          </select>
        </div>

        {checkEmptyNull(_sharingType, false) ? (<>
          {dataKeys.map((dataKey, index) => {
            return (
              <div key={index + '_form-content'} className='form-content term'>
                <h4>{dataKey.title}</h4>
                {dataKey.key === 'term' ? ( // 기간
                  <div>
                    <input
                      type='date'
                      disabled={modalType.endsWith('delete')}
                      value={_body.sharingStartDate || ''}
                      onInput={(e) => {
                        setBody((prev) => {
                          return { ...prev, sharingStartDate: DateFormat(e.target.value) }
                        })
                      }}
                    />
                    <span>-</span>
                    <input
                      type='date'
                      disabled={modalType.endsWith('delete')}
                      value={_body.sharingEndDate || ''}
                      onInput={(e) => {
                        setBody((prev) => {
                          return { ...prev, sharingEndDate: DateFormat(e.target.value) }
                        })
                      }}
                    />
                  </div>
                ) : dataKey.key === 'price' ? (<>
                  <input
                    type='number'
                    placeholder={`숫자만 입력할 수 있습니다.`}
                    disabled={modalType.endsWith('delete')}
                    pattern="\d*"
                    min="0"
                    value={_body[dataKey.key] || ''}
                    onInvalid={(e) => { e.target.setCustomValidity('숫자만 입력할 수 있습니다.'); }}
                    onInput={(e) => {
                      e.target.setCustomValidity('');
                      setBody((prev) => {
                        return { ...prev, [dataKey.key]: e.target.value }
                      })
                    }}
                  />
                </>) : dataKey.key === 'detail' ? (<>
                  <textarea
                    placeholder={`${dataKey.title}을(를) 입력해주세요.`}
                    disabled={modalType.endsWith('delete')}
                    defaultValue={_body[dataKey.key] || ''}
                    onInput={(e) => {
                      setBody((prev) => {
                        return { ...prev, [dataKey.key]: e.target.value }
                      })
                    }}
                  />
                </>) : (<>
                  <input
                    type='text'
                    placeholder={`${dataKey.title}을(를) 입력해주세요.`}
                    disabled={modalType.endsWith('delete')}
                    value={_body[dataKey.key] || ''}
                    onInput={(e) => {
                      setBody((prev) => {
                        return { ...prev, [dataKey.key]: e.target.value }
                      })
                    }}
                  />
                </>)}
              </div>
            );
          })}
        </>) : null}
      </div>
      <div className='section-action'>
        <button className='btn-cancel' onClick={close}>취소</button>
        {!modalType.endsWith('delete') ? (<button className='btn-save' onClick={actSave}>저장</button>) : (<button className='btn-delete' onClick={actDelete}>삭제</button>)}
      </div>
    </section>
  );
};

export default RequestEquipment;