import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { shipmentInspectionItemActions_setPageNumber, shipmentInspectionItemActions_setReset, shipmentInspectionItemActions_setSearchOption, shipmentInspectionItemActions_setShipmentInspectionItemType, shipmentInspectionItemActions_setTotalSize } from 'store/modules/actions/common/shipmentInspectionItemActions';

import { shipmentInspectionItemType } from 'api/apis/shipmentInspection/shipmentInspectionItemType';
import { shipmentInspectionItem } from 'api/apis/shipmentInspection/shipmentInspectionItem';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { LoadingMsg } from 'components/loading/LoadingMsg'
import Grid3Body from 'components/layouts/body/Grid3Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ShipmentInspectionItem = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, shipmentInspectionItemReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_shipmentInspectionItemTypes, setShipmentInspectionItemTypes] = useState([]);
  const [_shipmentInspectionItemList, setShipmentInspectionItemList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState({});

  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('113') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getShipmentInspectionItemTypes();
    getShipmentInspectionItemList(shipmentInspectionItemReducer.pageNumber - 1, shipmentInspectionItemReducer.shipmentInspectionItemTypeId);

    setOnload('loaded');

    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getShipmentInspectionItemList(shipmentInspectionItemReducer.pageNumber - 1);
    }

    return () => { };
  }, [shipmentInspectionItemReducer.pageNumber]);

  /* ========================================================================= #4 */
  const getShipmentInspectionItemTypes = async () => {
    await shipmentInspectionItemType.searchShipmentInspectionItemType({ companyId: userReducer.company.companyId }).then((response) => {
      if (response === undefined) return;
      console.log('shipmentInspectionItemType.searchShipmentInspectionItemType : ', response);
      setShipmentInspectionItemTypes(() => { return response.data; });
    })
  };

  const getShipmentInspectionItemList = async (page, shipmentInspectionItemTypeId, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && checkEmptyNull(shipmentInspectionItemTypeId, false) && shipmentInspectionItemTypeId !== 'all') {
      BodyToPost.shipmentInspectionItemTypeId = Number(shipmentInspectionItemTypeId);
    }
    if (!clear && checkEmptyNull(shipmentInspectionItemReducer.searchOption, false) && checkEmptyNull(shipmentInspectionItemReducer.searchText, false)) {
      BodyToPost[shipmentInspectionItemReducer.searchOption] = shipmentInspectionItemReducer.searchText;
    }

    await shipmentInspectionItem.searchShipmentInspectionItem(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('shipmentInspectionItem.searchShipmentInspectionItem : ', response);
      setShipmentInspectionItemList(() => { return response.data.content; });
      dispatch(shipmentInspectionItemActions_setTotalSize(response.data.totalElements));
    })
  };

  /* ========================================================================= #5 */
  const handleCheckedItem = (shipmentInspectionItem) => {
    setCheckedItem(() => { return shipmentInspectionItem; });
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(shipmentInspectionItemActions_setPageNumber(1));
    await getShipmentInspectionItemList(0, shipmentInspectionItemReducer.shipmentInspectionItemTypeId);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(shipmentInspectionItemActions_setReset());
    dispatch(shipmentInspectionItemActions_setPageNumber(1));
    await getShipmentInspectionItemList(0, 'all', true);
  };

  /* 수정 */
  const actUpdate = () => {
    const updateContent = _checkedItem;
    if (!checkNullObject(updateContent, false)) return alert('수정할 점검 / 검사를 선택해주세요.');
    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({ updateContent: JSON.stringify(updateContent) })}`,
      replace: true,
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const deleteContent = _checkedItem;
    if (!checkNullObject(deleteContent, false)) return alert('삭제할 점검 / 검사를 선택해주세요.');
    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({ deleteContent: JSON.stringify(deleteContent) })}`,
      replace: true,
    });
  };

  /* ========================================================================= #6 */

  return (
    <Grid3Body contents={
      <>
        <main className="Main">
          <NavBar
            title={<NavTitle menuCode={'113'} />}
            buttons={
              <>
                {_authority.indexOf('113-2') !== -1 ? (
                  <>
                    <button className='btn-add' onClick={() => { navigate(pageReducer.currentPage + '/create', { replace: true }); }}>
                      추가
                    </button>
                    <button className='btn-edit' onClick={actUpdate}>수정</button>
                    <button className='btn-delete' onClick={actDelete}>삭제</button>
                  </>
                ) : null}
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <select
                    className="SelectType"
                    style={{ maxWidth: '300px', width: 'fit-content' }}
                    value={shipmentInspectionItemReducer.shipmentInspectionItemTypeId}
                    onChange={(e) => {
                      const shipmentInspectionItemTypeId = e.target.value;
                      dispatch(shipmentInspectionItemActions_setShipmentInspectionItemType(shipmentInspectionItemTypeId));
                      dispatch(shipmentInspectionItemActions_setPageNumber(1));
                      getShipmentInspectionItemList(0, shipmentInspectionItemTypeId);
                    }}
                  >
                    <option value="all">전체</option>
                    {_shipmentInspectionItemTypes.map((shipmentInspectionItemType, index) => {
                      return (<option key={index + '_shipmentInspectionItemTypes'} value={shipmentInspectionItemType.shipmentInspectionItemTypeId}>{shipmentInspectionItemType.shipmentInspectionItemTypeName}</option>);
                    })}
                  </select>

                  <div className="ContentCBox">
                    <div className="ContentCBox">
                      <select
                        className="SearchOption"
                        value={shipmentInspectionItemReducer.searchOption}
                        onChange={(e) => {
                          const searchOption = e.target.value;
                          dispatch(shipmentInspectionItemActions_setSearchOption(searchOption))
                        }}
                      >
                        {[
                          { optionValue: '', optionText: '검색옵션' },
                          { optionValue: 'content', optionText: '점검 / 검사 내용' },
                          { optionValue: 'criteria', optionText: '판정 기준' },
                        ].map((option) => {
                          return (<option key={option.optionValue + '_shipmentInspectionSearchOption'} value={option.optionValue}>{option.optionText}</option>);
                        })}
                      </select>

                      <input
                        className="SearchBar"
                        placeholder="Search..."
                        value={shipmentInspectionItemReducer.searchText}
                        onInput={(e) => {
                          const searchText = e.target.value;
                          dispatch(shipmentInspectionItemActions_setSearchOption(searchText))
                        }}
                      />
                    </div>

                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton" onClick={actReset}>초기화</div>
                  </div>
                </div>
              </>
            }
          />

          <TableSection content={
            <table>
              <thead>
                <tr>
                  <th style={{ minWidth: '50px', width: '50px' }}></th>
                  <th>점검 / 검사 항목</th>
                  <th>점검 / 검사 내용</th>
                  <th>판정 기준</th>
                </tr>
              </thead>
              <tbody>
                {_shipmentInspectionItemList.length == 0 ? LoadingMsg() :
                  checkNullArray(_shipmentInspectionItemList, []).map((shipmentInspectionItem, index) => {
                    return (<tr key={index + '_shipmentInspectionItem'}>
                      {_authority.indexOf('113-2') !== -1 ? (
                        <td style={{ minWidth: '50px', width: '50px' }}>
                          <input
                            type="checkBox"
                            name="shipmentInspectionItems"
                            checked={shipmentInspectionItem.shipmentInspectionItemId === _checkedItem.shipmentInspectionItemId}
                            onChange={(e) => {
                              e.preventDefault();
                              handleCheckedItem(shipmentInspectionItem);
                            }}
                          />
                        </td>
                      ) : null}
                      <td>{shipmentInspectionItem.shipmentInspectionItemTypeName}</td>
                      <td>{shipmentInspectionItem.content}</td>
                      <td>{shipmentInspectionItem.criteria}</td>
                    </tr>
                    );
                  })}
              </tbody>
            </table>
          }
          />
        </main>

        <PagingComponent
          page={shipmentInspectionItemReducer.pageNumber}
          count={shipmentInspectionItemReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(shipmentInspectionItemActions_setPageNumber(page));
          }}
        />
      </>
    }
    />
  );
};

export default ShipmentInspectionItem;
