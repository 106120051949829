import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { workActions_setCurrentMessage, workActions_setEndDate, workActions_setPageNumber, workActions_setReset, workActions_setSearchText, workActions_setStartDate, workActions_setTotalSize, workActions_setWorkCase, workActions_setWorkOrderStatus } from 'store/modules/actions/default/workActions';

import { getCookie } from 'preferences/cookie/cookie';
import { WS_CLIENT } from 'preferences/server/constants';
import { workOrderTypeApi } from 'api/apis/workOrderTypeApi';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import GridNonBody from 'components/layouts/body/GridNonBody';
import Header from 'components/header/Header';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';
import WorkOrderStatusModal from 'components/modal/status/WorkOrderStatusModal';

import UriWorkEndModal from 'pages/working/custom/company/uri/status/UriWorkEndModal';

const WorkerScreenSection = styled.div`
  display: grid;
  grid-template-rows: auto minmax(calc(100% - 166px), calc(100% - 66px));
  height: ${props => {
    return props.onHeader ? 'calc(100svh - 120px)' : 'calc(100svh - 60px)';
  }};
  
  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
    & .statusButtons {display: flex; gap: 5px;}
  }

  & .TableSection {
    height: 100%;
    &>table {
      background-color: var(--Bg);
      & td:nth-child(2), & td:nth-child(3) {background-color: var(--white);}
    }
    & .table-preview {min-width: 150px; width: 150px;}
  }
`;

const WorkOrderTable = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  table {
    background-color: var(--white);
    border-collapse: collapse;
    height: 96%;
    margin: 0px auto;
    table-layout: auto;
    width: 98%;

    td, th {
      border: 1px solid var(--gray-200);
      box-sizing: border-box;
      height: 40px;
      min-width: unset;
      padding: unset;
    }
    th {font-size: 0.8em;}
    & td.colors {
      font-size: 25px;
      font-weight: 600;
      outline: 4px solid var(--Text);
      outline-offset: -4px;
      text-align: center;
    }
    & td.remark {
      color: var(--ThirdBlue);
      font-size: 1.1em;
      padding: 10px;
      text-align: left;
      vertical-align: top;
    }
  }

  &:hover {cursor: pointer; opacity: 0.6;}
`;

const ProcessCell = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 100%;
  width: 100%;
`;
const ProcessSection = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 60px repeat(auto-fit, minmax(100px, 1fr));
  height: 100%;
  width: 100%;
`;
const Process = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  justify-content: center;
  width: 100%;

  background-color: ${(props) => {
    switch (props.status) {
      case 'wait': return 'var(--gray-200)'
      case 'work': return 'var(--WorkingColor)'
      case 'end': return 'var(--ThirdBlue)'
      case 'stop': return 'var(--MainRed)'
      case 'pause': return 'var(--MainYellow)'
      case 'cancel': return 'var(--Bg)'

      case 'NO': return 'var(--MainNavy)';
      case 'cut': return '#BCE55C'
      case 'welding': return '#9FC93C'
      case 'last': return '#689900'

      default: return 'unset';
    }
  }};

  color: ${(props) => {
    return props.status.toLowerCase() === 'cancel' ? 'var(--gray-200)' : 'var(--white)';
  }};
`;

const DashboardButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  bottom: 10px;
  color: var(--white);
  column-gap: 5px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  left: 10px;
  line-height: 30px;
  justify-content: center;
  padding: 6px 16px;
  position: fixed;
  border-radius: 8px;
`;
const ConnectStatus = styled.div`
  background-color: ${(props) => {
    return props.connectStatus === 'connect' ? 'var(--MainGreen)' : 'var(--MainRed)';
  }};
  border-radius: 50%;
  cursor: pointer;
  right: 76px;
  bottom: 26px;
  position: fixed;
  height: 40px;
  width: 40px;
  z-index: 1000;
`;

const UriWorkerScreen = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userReducer, workReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const RoomId = userReducer.company.companyId;

  const taskTypeArray = ['BF', 'SF', 'MF'];

  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_workOrderTypes, setWorkOrderTypes] = useState([]);

  const [_workOrderLogList, setWorkOrderLogList] = useState([]);

  const [_detailModalStatus, setDetailModalStatus] = useState(false); // 공정리스트 모달
  const [_modalContent, setModalContent] = useState({});
  const [_workOrderStatusModal, setWorkOrderStatusModal] = useState(false); // 작업상태 변경 모달
  const [_eventWorkOrderLogId, setEventWorkOrderLogId] = useState('');
  const [_eventWorkOrderStatus, setEventWorkOrderStatus] = useState('');
  const [_workEndStatus, setWorkEndStatus] = useState(false); // 작업 완료 모달
  const [_workEndOrder, setWorkEndOrder] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('501') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));
    dispatch(workActions_setWorkCase('work'));

    getWorkOrderTypes();
    getWorkOrderLogList(workReducer.pageNumber - 1, workReducer.workOrderType, workReducer.workOrderStatus);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getWorkOrderLogList(workReducer.pageNumber - 1, workReducer.workOrderType, workReducer.workOrderStatus);
    }

    return () => { };
  }, [workReducer.pageNumber]);

  useEffect(() => {
    if (!checkNullObject(workReducer.currentMessage, false)) return;
    if (workReducer.currentMessage.userId === userReducer.user.id && checkNull(workReducer.currentMessage.errorMessage, false)) {
      alert(workReducer.currentMessage.errorMessage);
      dispatch(workActions_setCurrentMessage({}));
      return;
    }
    if (workReducer.currentMessage.type === '/work') applyStatus();
    if (workReducer.currentMessage.type === 'processes') applyProcessesStatus();
    if (workReducer.currentMessage.type === 'process') applyProcessStatus();
    if (workReducer.currentMessage.type === '/task') applyTaskStatus();
    return () => { };
  }, [workReducer.currentMessage]);

  useEffect(() => { }, [workReducer.connectStatus]);

  /* ====================================================================== #4 */
  const getWorkOrderTypes = async () => {
    const BodyToPost = { companyId: userReducer.company.companyId };
    await workOrderTypeApi.searchWorkOrderType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderTypeApi.searchWorkOrderType : ', response);
      setWorkOrderTypes(() => { return response.data; });
    });
  };

  const getWorkOrderLogList = async (page, workOrderType, workOrderStatus, clear) => {
    const paging = `?page=${page}&size=5&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && checkEmptyNull(workOrderType, false) && workOrderType !== 'all') {
      BodyToPost.workOrderTypeId = Number(workOrderType);
    }
    if (!clear && checkEmptyNull(workOrderStatus, false)) {
      switch (workOrderStatus) {
        case 'all': BodyToPost.workOrderStatus = ['wait', 'work', 'pause', 'stop', 'end', 'cancel']; break;
        case 'waitNwork': BodyToPost.workOrderStatus = ['wait', 'work']; break;
        default: BodyToPost.workOrderStatus = [workOrderStatus]; break;
      }
    }
    if (!clear && checkEmptyNull(workReducer.startDate, false) && checkEmptyNull(workReducer.endDate, false)) {
      BodyToPost.scheduledStartDate = DateFormat(workReducer.startDate);
      BodyToPost.scheduledEndDate = DateFormat(workReducer.endDate);
      if (workReducer.startDate > workReducer.endDate) {
        BodyToPost.scheduledStartDate = DateFormat(workReducer.endDate);
        BodyToPost.scheduledEndDate = DateFormat(workReducer.startDate);
      }
    } else if (!clear && checkEmptyNull(workReducer.startDate, false) && !checkEmptyNull(workReducer.endDate, false)) {
      BodyToPost.scheduledStartDate = DateFormat(workReducer.startDate);
    } else if (!clear && checkEmptyNull(workReducer.endDate, false) && !checkEmptyNull(workReducer.startDate, false)) {
      BodyToPost.scheduledEndDate = DateFormat(workReducer.endDate);
    }
    if (!clear && checkEmptyNull(workReducer.searchText, false)) {
      BodyToPost.accountName = workReducer.searchText;
    }

    await workOrderLogApi.searchWorkOrderLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.searchWorkOrderLog : ', response);

      setWorkOrderLogList(() => { return response.data.data.content; });
      dispatch(workActions_setTotalSize(response.data.data.totalElements));

      if (checkEmptyNull(workReducer.startDate, false) && checkEmptyNull(workReducer.endDate, false)) {
        if (workReducer.startDate > workReducer.endDate) {
          dispatch(workActions_setStartDate(workReducer.endDate))
          dispatch(workActions_setEndDate(workReducer.startDate))
        }
      }
    });
  };

  const endCheck = (workOrderLog, processLogList) => {
    let checkCount = 0;
    processLogList.forEach(processLog => {
      if (processLog.processStatus !== 'end') checkCount++;
    })
    if (workOrderLog.workOrderStatus !== 'end' && checkCount === 0) {
      const BodyToWS = {
        roomId: RoomId,
        type: '/work',
        workOrderLogId: workOrderLog.workOrderLogId,
        workOrderStatus: 'end',
      };
      try {
        WS_CLIENT.publish({
          destination: '/pub/work',
          headers: { 'auth-token': getCookie('accessToken')?.replace('Bearer ', '') },
          body: JSON.stringify(BodyToWS),
        });
        setWorkEndOrder(() => { return { BodyToWS: BodyToWS, eventWorkOrder: { ...workOrderLog, workOrderStatus: 'end' } } });
        setTimeout(setWorkEndStatus(true), 1000);
      } catch (error) {
        console.log('client.publish/pub/work - error : ', error);
        alert(`
연결이 불안정합니다.
네트워크 상태 확인 후 다시 시도하여 주시기 바랍니다.
        `);
      }
    }
  }

  /* 웹소켓 */
  const applyStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    if (eventWorkOrder === undefined) return;
    eventWorkOrder.workOrderStatus = applyContent.workOrderStatus;
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, eventWorkOrder);
    setWorkOrderLogList(eventWorkOrderList);
  };
  const applyProcessesStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    if (eventWorkOrder === undefined) return;
    const eventProcessLogList = [...eventWorkOrder.processLogList];
    checkNullArray(applyContent.socketProcessResults, []).forEach((processResult, index) => {
      const eventIndex = eventProcessLogList.findIndex(item => item.processLogId === processResult.processLog.processLogId);
      if (eventIndex === -1) return;
      const eventContent = checkNullObject(eventProcessLogList[eventIndex], false);
      if (!eventContent) return;
      const processData = { ...eventContent, processStatus: processResult.processLog.processStatus };
      eventProcessLogList.splice(eventIndex, 1, processData);
    })
    const detailWorkOrder = {
      ...eventWorkOrder,
      processLogList: eventProcessLogList,
    };
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, detailWorkOrder);
    setWorkOrderLogList(eventWorkOrderList);
    endCheck(detailWorkOrder, eventProcessLogList);
  }
  const applyProcessStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    if (eventWorkOrder === undefined) return;
    const eventProcessLogList = [...eventWorkOrder.processLogList];
    const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === applyContent.processLog.processLogId);
    const eventContent = eventProcessLogList[eventProcessLogIndex];
    eventContent.processStatus = applyContent.processLog.processStatus;
    eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
    const detailWorkOrder = {
      ...eventWorkOrder,
      processLogList: eventProcessLogList,
    };
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, detailWorkOrder);
    setWorkOrderLogList(eventWorkOrderList);
    endCheck(detailWorkOrder, eventProcessLogList);
  };
  const applyTaskStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    if (eventWorkOrder === undefined) return;
    const eventProcessLogList = [...eventWorkOrder.processLogList];
    const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === applyContent.processLogId);
    const eventContent = eventProcessLogList[eventProcessLogIndex];
    const eventTaskLogList = [...eventContent.taskLogList];
    applyContent.taskSocketDtos.forEach((thisApplyTask) => {
      const eventTaskLogIndex = eventTaskLogList.findIndex((thisItem) => thisItem.taskLogId === thisApplyTask.taskLogId);
      const eventTaskContent = eventTaskLogList[eventTaskLogIndex];
      eventTaskContent.taskStatus = thisApplyTask.taskStatus;
      eventTaskLogList.splice(eventTaskLogIndex, 1, eventTaskContent);
    });
    eventContent.taskLogList = eventTaskLogList;
    eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
    const detailWorkOrder = {
      ...eventWorkOrder,
      processLogList: eventProcessLogList,
    };
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, detailWorkOrder);
    setWorkOrderLogList(eventWorkOrderList);
  };

  /* ====================================================================== #5 */
  /* 검색 - 작업상태 */
  const handleSearchWorkOrderStatus = async (workOrderStatus) => {
    dispatch(workActions_setWorkOrderStatus(workOrderStatus));
    dispatch(workActions_setPageNumber(1));
    await getWorkOrderLogList(0, workReducer.workOrderType, workOrderStatus)
  }

  /* 검색 */
  const actSearch = async () => {
    dispatch(workActions_setPageNumber(1));
    await getWorkOrderLogList(0, workReducer.workOrderType, workReducer.workOrderStatus);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(workActions_setReset());
    dispatch(workActions_setPageNumber(1));
    await getWorkOrderLogList(0, 'all', 'all', true);
  };

  /* 상세보기 */
  const showDetailWorkOrder = (item) => {
    setTimeout(
      navigate({
        pathname: '/monitoring/company/uri/working',
        search: `?${createSearchParams({ prevPage: '/monitoring/company/uri', workOrderLogId: item.workOrderLogId })}`,
        replace: true,
      }), 1000
    );
  };

  /* 작업 상태 변경 */
  const actWorkOrderStatusModal = (workOrderLog) => {
    if (workReducer.connectStatus !== 'connect') return alert(`
연결이 불안정합니다.
네트워크 상태 확인 후 다시 시도하여 주시기 바랍니다.
    `);

    const { workOrderLogId, workOrderStatus } = workOrderLog;
    if (workOrderStatus === 'end') return;

    setEventWorkOrderLogId(workOrderLogId);
    setEventWorkOrderStatus(workOrderStatus);
    setTimeout(setWorkOrderStatusModal(true), 1000);
  };

  /* 작업상태 설정 */
  const handleWorkOrderStatus = (e) => {
    const { workordercode, status } = e.currentTarget.dataset;
    const workOrderLogId = Number(workordercode);
    const workOrderStatus = status;
    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((item) => item.workOrderLogId === workOrderLogId);
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];

    let endProcess = [];
    let allProcess = [];
    if (workOrderStatus === 'end') {
      allProcess = eventWorkOrder.processLogList.map((item) => {
        if (item.processStatus === 'end') endProcess.push(item);
        return item;
      });
    }

    if (allProcess.length !== endProcess.length) {
      const alertText = `
      마무리가 안 된 작업이 있습니다.
      작업을 모두 마무리한 후 완료를 진행해 주세요.
      `;
      alert(alertText);
      setWorkOrderStatusModal(false);
      return;
    } else {
      let alertStatus = '';
      switch (workOrderStatus) {
        case 'work': alertStatus = '진행'; break;
        case 'pause': alertStatus = '일시정지'; break;
        case 'stop': alertStatus = '중단'; break;
        case 'end': alertStatus = '완료'; break;
        case 'cancel': alertStatus = '취소'; break;

        default: break;
      }
      const alertText = `작업지시를 ${alertStatus}합니다.`;
      alert(alertText);

      eventWorkOrder.workOrderStatus = status;
      const BodyToWS = {
        roomId: RoomId,
        type: '/work',
        workOrderLogId: workOrderLogId,
        workOrderStatus: workOrderStatus,
      };
      console.log('client.publish/pub/work - body : ', JSON.stringify(BodyToWS));

      if (status === 'end') {
        setWorkEndOrder(() => { return { BodyToWS: BodyToWS, eventWorkOrder: eventWorkOrder } });
        setWorkEndStatus(true);
        return;
      } else {
        if (workReducer.connectStatus !== 'connect' || !checkNullArray(eventWorkOrderList, false)) return;
        eventWorkOrderList.splice(eventWorkOrderIndex, 1, eventWorkOrder);
        try {
          WS_CLIENT.publish({
            destination: '/pub/work',
            headers: { 'auth-token': getCookie('accessToken')?.replace('Bearer ', '') },
            body: JSON.stringify(BodyToWS),
          });
        } catch (error) {
          console.log('client.publish/pub/work - error : ', error);
          alert(`
연결이 불안정합니다.
네트워크 상태 확인 후 다시 시도하여 주시기 바랍니다.
          `);
        }
        setWorkOrderStatusModal(false);
      }
    }
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  const [onHeader, setOnHeader] = useState(false)
  const clickHeader = (e) => { setOnHeader(!onHeader) };

  return (
    <GridNonBody
      header={<>{onHeader ? (<Header />) : null}</>}
      contents={
        <>
          <WorkerScreenSection className="Main" onHeader={onHeader}>
            <NavBar
              title={
                <div className='Title'>
                  <NavTitle menuCode={'501'} />
                  <div className='statusButtons'>
                    {[
                      { status: 'all', name: '전체' },
                      { status: 'waitNwork', name: '대기/작업중' },
                      { status: 'wait', name: '대기' },
                      { status: 'work', name: '작업중' },
                      { status: 'pause', name: '일시정지' },
                      { status: 'stop', name: '중단' },
                      { status: 'end', name: '완료' },
                      { status: 'cancel', name: '취소' },
                    ].map((workOrderStatus, index) => {
                      return (
                        <button key={index + '_statusButton'}
                          className={workReducer.workOrderStatus === workOrderStatus.status ? 'btn-inven-1' : 'btn-set'}
                          onClick={() => { handleSearchWorkOrderStatus(workOrderStatus.status); }}
                        >
                          {workOrderStatus.name}
                        </button>
                      )
                    })}
                  </div>
                </div>
              }
              nav={''}
              buttons={
                <>
                  <div className="SearchSection" style={{ columnGap: '20px' }}>
                    <div className="ContentCBox">
                      <div className="ContentCBox">
                        <select className="SearchOption detail">
                          <option value="accountName">거래처</option>
                        </select>
                        <input
                          className="SearchBar detail"
                          placeholder="Search..."
                          value={workReducer.searchText}
                          onInput={(e) => { dispatch(workActions_setSearchText(e.target.value)) }}
                        />
                      </div>

                      <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                      <div className="ResetButton" onClick={actReset}>초기화</div>
                    </div>
                  </div>
                </>
              }

            />

            <TableSection content={
              <table>
                <thead style={{ zIndex: '800' }}>
                  <tr>
                    <th style={{ minWidth: '120px', padding: 'unset', width: '120px' }}>작업상태</th>
                    <th style={{ minWidth: '150px', width: '150px' }}>거래처</th>
                    <th style={{ minWidth: '960px' }}>작업지시</th>
                  </tr>
                </thead>
                <tbody>
                  {_workOrderLogList.map((workOrderLog, index) => {
                    const workOrderInfo = checkNullParse(workOrderLog.workOrderCustomizedContent, {
                      BF: { date: '', amount: '' },
                      SF: { date: '', amount: '' },
                      MF: { date: '', amount: '' },
                      total: { date: '', amount: '' },

                      colors: '', // 색상
                      phoneNumber: '', // 전화번호
                    });

                    const processLogList = workOrderLog.processLogList;
                    processLogList.sort((a, b) => { return a.processLogId - b.processLogId });

                    return (<Fragment key={workOrderLog.workOrderLogId + '_' + index}>
                      <tr name="workOrderRow" data-key={workOrderLog.workOrderLogId}>
                        <StatusTdButton
                          statusStyle={{ cursor: (_authority.indexOf('501-2') !== -1 || _authority.indexOf('501-3') !== -1 || _authority.indexOf('501-4') !== -1 || _authority.indexOf('501-5') !== -1) ? 'pointer' : 'default', minHeight: '100px', minWidth: '120px', width: '120px' }}
                          content={workOrderLog}
                          contentId={workOrderLog.workOrderLogId}
                          status={workOrderLog.workOrderStatus}
                          statusEvent={(e) => {
                            e.stopPropagation();
                            if (_authority.indexOf('501-2') !== -1 || _authority.indexOf('501-3') !== -1 || _authority.indexOf('501-4') !== -1 || _authority.indexOf('501-5') !== -1) actWorkOrderStatusModal(workOrderLog);
                          }}
                        />
                        <td style={{ minWidth: '150px', width: '150px' }}>{workOrderLog.accountName}</td>
                        <td style={{ minWidth: '960px', padding: 'unset' }}>
                          <WorkOrderTable onClick={() => { showDetailWorkOrder(workOrderLog); }}>
                            <table data-key={workOrderLog.workOrderLogId}>
                              <colgroup>
                                <col width='50px'></col>
                                <col width='350px'></col>
                                <col width='50px'></col>
                                <col width='200px'></col>
                                <col width='50px'></col>
                                <col width='100px'></col>
                                <col width='50px'></col>
                                <col width='50px'></col>
                              </colgroup>
                              <tbody>
                                <tr>
                                  <th rowSpan={3}>색상</th>
                                  <td rowSpan={3} className='colors'>{workOrderInfo.colors}</td>
                                  <th>거래처</th>
                                  <td>{workOrderLog.accountName}</td>
                                  <th>B/F납기</th>
                                  <td>{checkNullObject(workOrderInfo?.BF, false) ? workOrderInfo?.BF?.date : workOrderInfo.BFDate}</td>
                                  <th>B/F</th>
                                  <td>{checkNullObject(workOrderInfo?.BF, false) ? workOrderInfo?.BF?.amount : workOrderInfo.BFAmount}</td>
                                </tr>
                                <tr>
                                  <th>전화번호</th>
                                  <td>{workOrderInfo.phoneNumber}</td>
                                  <th>S/F납기</th>
                                  <td>{checkNullObject(workOrderInfo?.SF, false) ? workOrderInfo?.SF?.date : workOrderInfo.TotalDate}</td>
                                  <th>S/F</th>
                                  <td>{checkNullObject(workOrderInfo?.SF, false) ? workOrderInfo?.SF?.amount : workOrderInfo.TotalAmount}</td>
                                </tr>
                                <tr>
                                  <th>주소</th>
                                  <td>{workOrderLog.workPlace}</td>
                                  <th>M/F납기</th>
                                  <td>{workOrderInfo?.MF?.date}</td>
                                  <th>M/F</th>
                                  <td>{workOrderInfo?.MF?.amount}</td>
                                </tr>
                                <tr>
                                  <th rowSpan={2}>비고</th>
                                  <td colSpan={3} rowSpan={2} className='remark'>{workOrderLog.remark}</td>
                                  <th>지시일자</th>
                                  <td colSpan={3}>{DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledStartDate, ''))}</td>
                                </tr>
                                <tr>
                                  <th>작성자</th>
                                  <td colSpan={3}>{workOrderLog.userName}</td>
                                </tr>
                              </tbody>
                            </table>
                          </WorkOrderTable>
                        </td>
                      </tr>

                      <tr key={workOrderLog.workOrderLogId + '_ProcessRow'} style={{ borderBottom: '2px solid var(--Bg)', borderTop: '2px solid var(--Bg)' }}>
                        <td colSpan={5} style={{ height: '20px', padding: 'unset' }}>
                          <ProcessCell>
                            {processLogList.map((process, processIndex) => {
                              return (
                                <ProcessSection key={process.processLogId + '_ProcessSection'}>
                                  <Process style={{ border: '1px solid var(--white)', minWidth: '60px', width: '60px' }} status={'NO'}>
                                    {(processIndex + 1).toString().padStart(2, '0')}
                                  </Process>

                                  {taskTypeArray.map((thisTask) => {
                                    let AllTask = [];
                                    let thisTaskList = { BF: [], CP: [], SF: [], MF: [], GB: [], MC: [] };

                                    if (process.taskLogList.length > 0) {
                                      AllTask = process.taskLogList.filter((thisItem) => thisItem.taskType.split('_')[0] === thisTask);
                                      AllTask.forEach((thisItem) => thisTaskList[thisItem.taskType.split('_')[0]].push(thisItem));
                                    }

                                    let thisTaskCutEndContent = { BF: [], CP: [], SF: [], MF: [], GB: [], MC: [] };
                                    let thisTaskWeldingEndContent = { BF: [], CP: [], SF: [], MF: [], GB: [], MC: [] };
                                    let thisTaskLastEndContent = { BF: [], CP: [], SF: [], MF: [], GB: [], MC: [] };

                                    if (process.taskLogList.length > 0) {
                                      AllTask.forEach((task) => {
                                        switch (task.taskStatus) {
                                          case 'cutend':
                                            thisTaskCutEndContent[task.taskType.split('_')[0]].push(task);
                                            return;
                                          case 'weldingend':
                                            thisTaskWeldingEndContent[task.taskType.split('_')[0]].push(task);
                                            thisTaskCutEndContent[task.taskType.split('_')[0]].push(task);
                                            return;
                                          case 'lastend':
                                            thisTaskLastEndContent[task.taskType.split('_')[0]].push(task);
                                            thisTaskWeldingEndContent[task.taskType.split('_')[0]].push(task);
                                            thisTaskCutEndContent[task.taskType.split('_')[0]].push(task);
                                            return;

                                          default: return;
                                        }
                                      });
                                    }

                                    if (
                                      checkNullArray(thisTaskList[thisTask], false) && checkEmptyNull(thisTaskCutEndContent[thisTask], false) &&
                                      checkNullArray(thisTaskWeldingEndContent[thisTask], false) && checkEmptyNull(thisTaskLastEndContent[thisTask], false)
                                    ) {
                                      if (process.processStatus === 'end') {
                                        return (<Process key={thisTask.processLogId + thisTask + '_Process'} status={'end'}>{`${thisTask}`}</Process>);
                                      } else {
                                        return (<Process key={thisTask.processLogId + thisTask + '_Process'} status={'last'}>{`${thisTask} - 마무리`}</Process>);
                                      }
                                    } else if (
                                      checkNullArray(thisTaskList[thisTask], false) && checkEmptyNull(thisTaskCutEndContent[thisTask], false) &&
                                      checkNullArray(thisTaskWeldingEndContent[thisTask], false) && !checkEmptyNull(thisTaskLastEndContent[thisTask], false)
                                    ) {
                                      return (<Process key={thisTask.processLogId + thisTask + '_Process'} status={'welding'}>{`${thisTask} - 용접`}</Process>);
                                    } else if (
                                      checkNullArray(thisTaskList[thisTask], false) && checkEmptyNull(thisTaskCutEndContent[thisTask], false) &&
                                      !checkNullArray(thisTaskWeldingEndContent[thisTask], false) && !checkEmptyNull(thisTaskLastEndContent[thisTask], false)
                                    ) {
                                      return (<Process key={thisTask.processLogId + thisTask + '_Process'} status={'cut'}>{`${thisTask} - 절단`}</Process>);
                                    } else {
                                      if (checkNullArray(thisTaskList[thisTask], false)) {
                                        return (<Process key={thisTask.processLogId + thisTask + '_Process'} status={process.processStatus}>{thisTask}</Process>);
                                      } else {
                                        return null;
                                      }
                                    }
                                  })}
                                </ProcessSection>
                              );
                            })}
                          </ProcessCell>
                        </td>
                      </tr>
                    </Fragment>);
                  })}
                </tbody>
              </table>
            }
            ></TableSection>
          </WorkerScreenSection>

          <div style={{ display: 'flex', height: '60px', justifyContent: 'space-between', alignItems: 'center', gap: '16px', margin: '0 24px' }}>
            <DashboardButton onClick={clickHeader} data-name='on'>메뉴 {onHeader ? '닫기' : '열기'}</DashboardButton>

            <PagingComponent
              page={workReducer.pageNumber}
              count={workReducer.totalSize}
              size={5}
              pageEvent={(page) => {
                console.log('page : ', page);
                dispatch(workActions_setPageNumber(page));
              }}
            />

            <ConnectStatus connectStatus={workReducer.connectStatus} onClick={() => { navigate(0); }}></ConnectStatus>
          </div>

          {/* 모달 */}
          {/* 작업상태 설정 모달 */}
          {workReducer.connectStatus === 'connect' && _workOrderStatusModal === true ? (
            <WorkOrderStatusModal
              workOrderLogId={_eventWorkOrderLogId}
              workOrderStatus={_eventWorkOrderStatus}
              statusEvent={handleWorkOrderStatus}
              open={_workOrderStatusModal}
              close={() => {
                setWorkOrderStatusModal(false);
                getWorkOrderLogList(workReducer.pageNumber - 1, workReducer.workOrderType, workReducer.workOrderStatus);
              }}
            />
          ) : null}
          {/* 작업완료 모달 */}
          {_workEndStatus === true ? (
            <UriWorkEndModal
              content={_workEndOrder}
              open={_workEndStatus}
              close={() => {
                setWorkEndStatus(false);
                setWorkOrderStatusModal(false);
                getWorkOrderLogList(workReducer.pageNumber - 1, workReducer.workOrderType, workReducer.workOrderStatus);
              }}
            ></UriWorkEndModal>
          ) : null}
        </>
      }
      bodyStyle={{ display: 'grid', gridTemplateRows: 'calc(100% - 60px) 60px' }}
    />
  );
};

export default UriWorkerScreen;
