const initialState = {
  shipmentInspectionItemType: 'all',

  searchData: {},
  searchOption: '',
  searchText: '',

  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
}
const resetState = {
  shipmentInspectionItemType: 'all',

  searchData: {},
  searchOption: '',
  searchText: '',

  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const shipmentInspectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETSHIPMENTINSPECTIONTYPE':
      return {
        ...state,
        shipmentInspectionItemType: action.payload,
      };
    case 'SETSHIPMENTINSPECTIONSEARCHDATA':
      return {
        ...state,
        searchData: action.payload,
      };
    case 'SETSHIPMENTINSPECTIONPAGENUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'SETSHIPMENTINSPECTIONTOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };
    case 'SETSHIPMENTINSPECTIONISPUSHEDSEARCHBUTTON':
      return {
        ...state,
        isPushedSearchButton: action.payload,
      };
    case 'SETSHIPMENTINSPECTIONRESET':
      return {
        ...state,
        ...resetState,
      };
    default:
      return state;
  };
};

export default shipmentInspectionReducer;