import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {mallQnAActions_setPageNumber, mallQnAActions_setTotalSize, mallQnAActions_setReset, mallQnAActions_setSearchOption, mallQnAActions_setSearchText, mallQnAActions_setMallQnAStatus, mallQnAActions_setIsPushedSearchButton } from 'store/modules/actions/mall/mallQnAActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { qna } from 'api/apis/mall/qna';

import {MallQnACancelTdButtons, MallQnACompleteTdButtons, MallQnARequestCancelTdButtons, MallQnAWaitingTdButtons} from 'components/buttons/MallQnAStatusTdButtons';
import MallBody from 'components/layouts/body/MallBody';
import MallQnAReply from 'pages/mallManagement/mallQnA/reply/MallQnAReply';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import ShowMallFileList from '../modal/ShowMallFileList';
import TableSection from 'components/layouts/table/TableSection';
import { checkEmptyNull } from 'components/checkValues/checkValues';

const MainSection = styled.main`
  .TableSection {
    & td, & th {min-width: unset;}
    & tbody tr {height: 300px;}
    & td {user-select: text;}
    & td.OrderInfos {
      border-right: 1px solid var(--gray-200);
      box-sizing: border-box;
      padding: unset;
      vertical-align: top;
    }
  }
`;

const StatusTd = styled.td`
  position: relative;
`;
const ActButtons = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fit, min(33.3%));
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
`;
const ActButton = styled.div`
  align-items: center;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 1em;
  height: 100%;
  justify-content: center;
  width: 100%;

  & input[type="radio"] {transform: scale(1.5);}
  &.file {background-color: var(--MainBlue);}
  &.reply {background-color: var(--MainNavy);}
`;

const MallElementList = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(auto-fit, minmax(40px, fit-content));
  justify-content: flex-start;
  width: inherit;
  zoom: 100%;
`;
const MallElement = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 100%;
  min-height: 40px;
  height: fit-content;
  width: 100%;

  & p {
    align-items: center;
    border: 1px solid var(--gray-200);
    display: flex;
    height: 100%;
    justify-content: center;
  }
  &.title p {background-color: var(--MainNavy); color: var(--white);}
`;

const OrderCancelButton = styled.td`
  background-color: var(--ThirdRed);
  color: white;
  cursor: pointer;
  font-size: 1em;
  min-width: 60px;
  width: 60px;
`;


const MallQnAManagement = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mallQnAReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_mallQnAList, setMallQnAList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_fileListModalStatus, setFileListModalStatus] = useState(false);
  const [_replyListModalStatus, setReplyListModalStatus] = useState(false);
  const [_eventContent, setEventContent] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getMallQnAList(mallQnAReducer.pageNumber - 1, mallQnAReducer.mallQnAStatus, mallQnAReducer.isPushedSearchButton);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getMallQnAList(mallQnAReducer.pageNumber - 1, mallQnAReducer.mallQnAStatus, mallQnAReducer.isPushedSearchButton);
    }
    return () => { };
  }, [mallQnAReducer.pageNumber]);

  useEffect(() => { }, [mallQnAReducer]);

  /* ====================================================================== #4 */
  const getMallQnAList = async (page, mallQnAStatus, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {companyId: userReducer.company.companyId};
    if (checkEmptyNull(mallQnAStatus, false) && mallQnAStatus !== 'all') {
      BodyToPost.status = mallQnAStatus;
    }
    if (!clear && checkEmptyNull(mallQnAReducer.searchOption, false) && checkEmptyNull(mallQnAReducer.searchText, false)) {
      BodyToPost[mallQnAReducer.searchOption] = mallQnAReducer.searchText;
    }
    await qna.searchMallQnA(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('qna.searchMallQnA : ', response);
      setMallQnAList(() => {return response.data.content;});
      dispatch(mallQnAActions_setTotalSize(response.data.totalElements));
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set([..._checkedItem]);
    if (isChecked) checkedItem.add(id);
    else if (!isChecked && checkedItem.has(id)) checkedItem.delete(id);
    setCheckedItem(() => {return checkedItem;});
  };

  const handleMallQnAStatus = (mallQnA, index) => {
    setMallQnAList((prev) => {
      const returnData = [...prev];
      const newData = { ...mallQnA };
      returnData.splice(index, 1, newData);
      return returnData;
    });
  };

  /* 파일 리스트 */
  const showFileList = (mallQnA) => {
    setEventContent(() => {return mallQnA;});
    setTimeout(setFileListModalStatus(true), 1000);
  };

  /* 댓글 리스트 */
  const showReplyList = (mallQnA) => {
    setEventContent(() => {return mallQnA;});
    setTimeout(setReplyListModalStatus(true), 1000);
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(mallQnAActions_setPageNumber(1));
    dispatch(mallQnAActions_setIsPushedSearchButton(true));
    await getMallQnAList(0, mallQnAReducer.mallQnAStatus, true);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(mallQnAActions_setReset());
    dispatch(mallQnAActions_setPageNumber(1));
    await getMallQnAList(0, 'all', false);
  };

  /* 수정 */
  const actUpdate = () => {
    const checkedMallQnAList = Array.from(_checkedItem);

    if (checkedMallQnAList.length < 1) return;
    else if (checkedMallQnAList.length > 1) return alert('다중 선택되어 있습니다.');

    const updateContentIndex = _mallQnAList.findIndex((thisItem) => thisItem.mallQnAId === checkedMallQnAList[0]);
    if (updateContentIndex === -1) return alert('수정할 물품을 선택해 주세요.');
    const updateContent = _mallQnAList[updateContentIndex];

    navigate({
      pathname: '/mallManagement/qna/update',
      search: `?${createSearchParams({updateContent: JSON.stringify(updateContent)})}`,
      replace: true,
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const checkedMallQnAList = Array.from(_checkedItem);
    console.log('checkedMallQnAList : ', checkedMallQnAList);

    if (checkedMallQnAList.length < 1) return;

    const deleteContents = checkedMallQnAList.map((thisKey) => {
      const deleteContentIndex = _mallQnAList.findIndex((thisIndex) => thisIndex.mallQnAId === thisKey);
      if (deleteContentIndex === -1) return null;
      const deleteContent = _mallQnAList[deleteContentIndex];
      return deleteContent;
    });
    const setDeleteContents = deleteContents.filter((thisItem) => thisItem !== null);

    if (setDeleteContents.length < 1) return;

    navigate({
      pathname: '/mallManagement/qna/delete',
      search: `?${createSearchParams({deleteContents: JSON.stringify(setDeleteContents)})}`,
      replace: true,
    });
  };

  /* 취소 */
  const actCancel = async (mallQnA, index) => {
    const confirmText = `
    [ 취소 ]상태로 바꿀 시,
    문의정보를 다시 변경할 수 없습니다.
    문의를 취소하시겠습니까?
    `;
    if (window.confirm(confirmText) === true) {
      const BodyToPut = {
        ...mallQnA,
        deleteMallQnAElementList: [],
        deleteFileDataList: [],
        userId: userReducer.user.id,
        status: 'cancel',
      };
      console.log('actCancel : ', BodyToPut);

      const putFormData = new FormData();
      putFormData.append('key', new Blob([JSON.stringify(BodyToPut)], { type: 'application/json' }));
      for (let values of putFormData.values()) {
        console.log('putFormData : ', values);
      }

      await qna.updateMallQnA(mallQnA.mallQnAId, putFormData).then((response) => {
        if (response === undefined) return;
        console.log('qna.updateMallQnA : ', response);
        alert('문의를 취소했습니다.');
        handleMallQnAStatus(response.data, index);
      });
    } else return;
  };

  /* ====================================================================== #6 */

  return (
    <MallBody contents={
      <>
        <MainSection className="Main">
          <NavBar
            title={'문의관리'}
            buttons={
              <>
                <button className='btn-edit' onClick={actUpdate}>수정</button>
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="SearchSection" style={{columnGap: '20px'}}>
                  <select
                    className="SelectStatus"
                    value={mallQnAReducer.mallQnAStatus}
                    onChange={(e) => {
                      dispatch(mallQnAActions_setMallQnAStatus(e.target.value));
                      dispatch(mallQnAActions_setPageNumber(1));
                      getMallQnAList(0, e.target.value, mallQnAReducer.isPushedSearchButton);
                    }}
                  >
                    {[
                      { optionValue: 'all', optionText: '전체' },
                      { optionValue: 'waiting', optionText: '대기' },
                      { optionValue: 'complete', optionText: '답변 완료' },
                      {optionValue: 'requestCancel', optionText: '취소 요청'},
                      { optionValue: 'cancel', optionText: '취소' },
                    ].map((thisItem) => {
                      return (<option key={thisItem.optionValue + '_mallQnAStatus'} value={thisItem.optionValue}>{thisItem.optionText}</option>);
                    })}
                  </select>

                  <div className="ContentCBox">
                    <div className="ContentCBox">
                      <select
                        className="SearchOption"
                        onChange={(e) => {dispatch(mallQnAActions_setSearchOption(e.target.value));}}
                      >
                        {[
                          { optionValue: '', optionText: '검색옵션' },
                          {optionValue: 'mallQnACode', optionText: '문의코드'},
                          {optionValue: 'mallQnAContent', optionText: '문의내용'},
                        ].map((thisItem) => {
                          return (<option key={thisItem.optionValue + '_mallQnASearchOption'} value={thisItem.optionValue}>{thisItem.optionText}</option>);
                        })}
                      </select>
                      <input
                        className="SearchBar"
                        placeholder="Search..."
                        value={mallQnAReducer.searchText}
                        onInput={(e) => {dispatch(mallQnAActions_setSearchText(e.target.value));}}
                      />
                    </div>

                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton" onClick={actReset}>초기화</div>
                  </div>
                </div>
              </>
            }
          />

          <TableSection content={
            <table>
              <colgroup>
                <col width={'120px'} />
                <col width={'60px'} />
                <col width={'15%'}/>
                <col width={'15%'}/>  
                <col/>
                <col/>
                <col width={'60px'} />
              </colgroup>
              <thead>
                <tr>
                  <th>문의상태</th>
                  <th></th>
                  <th>문의코드</th>
                  <th>문의자</th>
                  <th>문의내용</th>
                  <th>문의물품</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {_mallQnAList.map((mallQnA, index) => {
                  return (
                    <tr key={index + '_mallQnAs'} name={'mallQnARows'}>
                      <StatusTd>
                        {(() => {
                          switch (mallQnA.status) {
                            case 'complete': return (<MallQnACompleteTdButtons mallQnA={mallQnA} handleMallQnAStatus={(data) => {handleMallQnAStatus(data, index);}} />);
                            case 'requestCancel':  return (<MallQnARequestCancelTdButtons mallQnA={mallQnA} handleMallQnAStatus={(data) => {handleMallQnAStatus(data, index);}} />);
                            case 'cancel': return (<MallQnACancelTdButtons mallQnA={mallQnA} handleMallQnAStatus={(data) => {handleMallQnAStatus(data, index);}} />);

                            default: return (<MallQnAWaitingTdButtons mallQnA={mallQnA} handleMallQnAStatus={(data) => {handleMallQnAStatus(data, index);}} />);
                          }
                        })()}
                      </StatusTd>
                      <td style={{padding: 'unset', position: 'relative'}}>
                        <ActButtons>
                          <ActButton>
                            <input
                              type="radio"
                              name="mallQnAs"
                              data-key={mallQnA.mallQnAId}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                handleCheckedItem(mallQnA.mallQnAId, isChecked);
                              }}
                              onClick={(e) => {e.stopPropagation();}}
                            />
                          </ActButton>
                          <ActButton className="file" onClick={() => {showFileList(mallQnA);}}>첨부<br />파일</ActButton>
                          <ActButton className="reply" onClick={() => {showReplyList(mallQnA);}}>댓글</ActButton>
                        </ActButtons>
                      </td>

                      <td>{mallQnA.mallQnACode}</td>
                      <td>{mallQnA.userName}</td>
                      <td>{mallQnA.mallQnAContent}</td>
                      <td style={{minWidth: '350px', padding: 'unset', verticalAlign: 'top'}}>
                        <MallElementList>
                          <MallElement className="title">
                            <p>이름</p>
                            <p>코드</p>
                          </MallElement>
                          {mallQnA.mallQnAElementList.map((element, elementIndex) => {
                            return (
                              <MallElement key={index + '_' + elementIndex + '_mallElements'}>
                                <p>{element.mallElementName}</p>
                                <p>{element.mallElementCode}</p>
                              </MallElement>
                            );
                          })}
                        </MallElementList>
                      </td>
                      {(() => {
                        if (mallQnA.status !== 'cancel') {
                          return (<OrderCancelButton onClick={() => {actCancel(mallQnA, index);}}>취소</OrderCancelButton>);
                        } else {
                          return(<td></td>);
                        }
                      })()}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          }
          ></TableSection>
        </MainSection>

        <PagingComponent
          page={mallQnAReducer.pageNumber}
          count={mallQnAReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(mallQnAActions_setPageNumber(page));
          }}
        />

        {_fileListModalStatus === true && _eventContent.mallQnAId !== undefined ? (
          <ShowMallFileList
            type={'mallQnA'}
            content={_eventContent}
            update={() => {getMallQnAList(mallQnAReducer.pageNumber - 1, mallQnAReducer.mallQnAStatus, mallQnAReducer.isPushedSearchButton);}}
            open={_fileListModalStatus}
            close={() => {setFileListModalStatus(false);}}
          ></ShowMallFileList>
        ) : null}

        {_replyListModalStatus === true && _eventContent.mallQnAId !== undefined ? (
          <MallQnAReply
            mallQnA={_eventContent}
            open={_replyListModalStatus}
            close={() => {setReplyListModalStatus(false);}}
          ></MallQnAReply>
        ) : null}
      </>
    }
    />
  );
};

export default MallQnAManagement;
