import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { inquiryApi } from 'api/apis/inquiryApi';

import { checkNullArray } from 'components/checkValues/checkValues';

const InquiryForm = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector(state => state);

  /* ====================================================================== #2 */
  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    userId: userReducer.user.id,
    status: 'waiting',
    inquiryContent: '',
    inquiryList: [],
  });

  const [_fileDataList, setFileDataList] = useState([]);

  /* ====================================================================== #3 */

  /* ====================================================================== #4 */

  /* ====================================================================== #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value }; });
  };

  const handleFileEvent = (type, e) => {
    let files = [];
    switch (type) {
      case 'click': files = e.target.files; break;
      case 'drag': files = e.dataTransfer.files; break;

      default: return;
    }

    if (!checkNullArray(files, false)) return;
    // if (files === undefined || files === null || files.length === 0) return;

    const setFiles = Array.prototype.slice.call(files);
    setFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      return new Promise((resolve) => {
        reader.onload = () => {
          const returnData = file;
          returnData['preview'] = reader.result;
          setFileDataList((prev) => { return [...prev, returnData]; });
          resolve();
        };
      });
    });
  };
  const actDeleteFileData = (file, index) => {
    setFileDataList((prev) => {
      const returnData = [...prev];
      returnData.splice(index, 1);

      return returnData;
    });
  };

  /* 추가 */
  const actCreate = async (e) => {
    e.preventDefault();

    const BodyToPost = { ..._formData };
    console.log('BodyToPost : ', BodyToPost);

    const postFormData = new FormData();
    postFormData.append('key', new Blob([JSON.stringify(BodyToPost)], { type: 'application/json' }));
    if (checkNullArray(_fileDataList, false)) {
      _fileDataList.forEach((file) => { postFormData.append('multipartFileList', file); });
    }
    for (let values of postFormData.values()) {
      console.log('postFormData : ', values);
    }
    await inquiryApi.createInquiry(postFormData).then((response) => {
      if (response === undefined) return;
      console.log('inquiry.createInquiry : ', response);
      alert('문의를 추가했습니다.');
      props.update();
    });

  };

  /* ====================================================================== #6 */

  return (
    <section className='inquiry-form'>
      <div>
        <div className='inquiry-form-title'>
          <h2>문의하기</h2>
          <button onClick={props.close}>닫기</button>
        </div>
        <textarea
          type="text"
          name="inquiryContent"
          placeholder="문의 내용을 이곳에 작성해주세요."
          value={_formData.inquiryContent}
          onInput={handleInputEvent}
        />
        {/* <div className='file'>
          <ol>
            {_fileDataList.map((fileData, index) => {
              return (
                <li key={index + '_fileDatas'}>
                  {(() => {
                    const returnArray = [];
                    if (fileData.attachFileId === undefined) {
                      if (fileData.type && fileData.type.startsWith('image')) {
                        returnArray.push(
                          <img key={index + '_fileDataImg'} src={fileData.preview} alt={fileData.name} />,
                        );
                      } else {
                        returnArray.push(<>{fileData.name}</>);
                      }
                    } else {
                      if (fileData.fileData) {
                        if (fileData.fileData.fileDataType && fileData.fileData.fileDataType.startsWith('image')) {
                          returnArray.push(
                            <img key={index + '_fileDataImg'} src={fileData.fileData.fileDataS3URL} alt={fileData.fileData.fileDataName} />,
                          );
                        } else {
                          returnArray.push(
                            <>{fileData.fileData.fileDataName}</>,
                          );
                        }
                      } else {
                        returnArray.push(<></>);
                      }
                    }
                    return returnArray;
                  })()}
                  <button onClick={() => { actDeleteFileData(fileData, index); }}><CloseButtonIcon /></button>
                </li>
              );
            })}
          </ol>
          <input
            type="file"
            id="fileInput"
            style={{ display: 'none' }}
            multiple
            onChange={(e) => {
              handleFileEvent('click', e);
            }}
          />
          <label
            htmlFor="fileInput"
            onDragEnter={(e) => { e.stopPropagation(); e.preventDefault(); }}
            onDragLeave={(e) => { e.stopPropagation(); e.preventDefault(); }}
            onDragOver={(e) => { e.stopPropagation(); e.preventDefault(); }}
            onDrop={(e) => { e.preventDefault(); handleFileEvent('drag', e); }}
          >
            <div className='file-icon'></div>
            <p>첨부 파일 드래그 또는 <span>선택</span></p>
          </label>
        </div> */}
        <button className="submit" onClick={actCreate}>문의 등록</button>
      </div>
    </section>
  );
};

export default InquiryForm;