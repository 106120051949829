const initialState = {
  mallOrderStatus: 'all',

  startDate: undefined,
  endDate: undefined,
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const resetState = {
  mallOrderStatus: 'all',

  startDate: undefined,
  endDate: undefined,
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const mallOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETMALLORDERSTATUS':
      return {
        ...state,
        mallOrderStatus: action.payload,
      };
    case 'SETMALLORDERSTARTDATE':
      return {
        ...state,
        startDate: action.payload,
      };
    case 'SETMALLORDERENDDATE':
      return {
        ...state,
        endDate: action.payload,
      };
    case 'SETMALLORDERSEARCHOPTION':
      return {
        ...state,
        searchOption: action.payload,
      };
    case 'SETMALLORDERSEARCHTEXT':
      return {
        ...state,
        searchText: action.payload,
      };
    case 'SETMALLORDERPAGENUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'SETMALLORDERTOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };
    case 'SETMALLORDERISPUSHEDSEARCHBUTTON':
      return {
        ...state,
        isPushedSearchButton: action.payload,
      };
    case 'SETMALLORDERRESET':
      return {
        ...state,
        ...resetState,
      };

    default:
      return state;
  }
};

export default mallOrderReducer;
