import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import moment from 'moment';

import { contractTypeApi } from 'api/apis/contractTypeApi';
import { contractAttributeApi } from 'api/apis/contractAttributeApi';
import { contractApi } from 'api/apis/contractApi';

import { checkEmptyNull, checkNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import SelectElements from 'pages/element/modal/SelectElements';
import SelectOneAccount from 'pages/account/modal/SelectOneAccount';
import SelectProcurements from 'pages/procurement/modal/SelectProcurements';
import SelectWorkOrderLogs from 'pages/productionOrder/modal/SelectWorkOrderLogs';
import TableSection from 'components/layouts/table/TableSection';
import { ContractFormComponent } from './ContractForm.style';

const CreateContract = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { contractReducer, pageReducer, userReducer } = useSelector((state) => state);
  console.log('contractReducer : ', contractReducer);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId, // 회사
    userId: userReducer.user.id, // 작성자

    contractDate: DateFormat(new Date()), // 수주일자
    contractRegDate: DateFormat(new Date()), // 납품요구접수일자
    scheduledEndDate: '', // 납품기한일자
    contractActualEndDate: '', // 납품일자

    contractTypeId: checkEmptyNull(contractReducer.contractType, false) && contractReducer.contractType !== 'all' ? Number(contractReducer.contractType) : null, // 수주유형
    contractStatus: 'waiting', // 수주상태
    contractCode: '', // 수주코드
    contractName: '', // 수주이름

    accountId: undefined, // 거래처

    contractPrice: 0, // 수주총금액

    address: '', // 수주주소

    remark: '', // 비고
    monitoringRemark: '', // 특이사항

    contractCustomizedContent: JSON.stringify({}), // 커스텀

    contractAttributeValueList: [], // 항목

    contractElementList: [], // 수주물품
    deleteContractElementList: [], // 수주물품 삭제

    procurementList: [], // 발주
    workOrderLogList: [], // 작업지시
  });

  const [_contractTypes, setContractTypes] = useState([]);
  const [_contractAttrList, setContractAttrList] = useState([]);
  const [_contractAttrValueList, setContractAttrValueList] = useState({});

  const [_account, setAccount] = useState({});

  const [_contractElements, setContractElements] = useState([]);
  const [_procurements, setProcurements] = useState([]);
  const [_workOrderLogs, setWorkOrderLogs] = useState([]);

  const [_modalStatus, setModalStatus] = useState(false);
  const [_accountModalStatus, setAccountModalStatus] = useState(false);
  const [_procurementModalStatus, setProcurementModalStatus] = useState(false);
  const [_workOrderLogModalStatus, setWorkOrderLogModalStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter(
      (thisItem) =>
        thisItem.authority.startsWith('201') === true || // contract
        thisItem.authority.startsWith('202') === true || // procurement
        thisItem.authority.startsWith('205') === true || // procurement
        thisItem.authority.startsWith('403') === true, // workOrderLog
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    getContractTypes();
    if (checkEmptyNull(contractReducer.contractType, false) || contractReducer.contractType !== 'all') {
      getContractAttrs(contractReducer.contractType);
    }

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => { }, [_account, _contractAttrList, _procurements, _workOrderLogs]);

  /* ====================================================================== #4 */
  /* 수주 유형 */
  const getContractTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    await contractTypeApi.searchContractType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('contractTypeApi.searchContractType : ', response);

      setContractTypes(() => { return response.data });
    });
  };

  /* 수주 항목 */
  const getContractAttrs = async (contractType) => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (checkEmptyNull(contractType, false) && contractType !== 'all') {
      BodyToPost.contractTypeId = Number(contractType);
    }
    console.log('getContractAttrs - BodyToPost : ', BodyToPost);

    await contractAttributeApi.searchContractAttribute(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('contractAttributeApi.searchContractAttribute : ', response);

      setContractAttrList(() => { return response.data });

      setContractAttrValueList((prev) => {
        const returnData = { ...prev };
        response.data.forEach((attr) => {
          const checkIndex = Object.keys(returnData).findIndex((key) => Number(key) === Number(attr.contractAttributeId));
          if (checkIndex === -1) {
            returnData[attr.contractAttributeId] = '';
          }
        });

        return returnData;
      });
    });
  };

  /* ====================================================================== #5 */
  // 코드 날짜 추가 버튼
  const handleButtonClick = () => {
    const currentDate = `#${moment().format('YYYY-MM-DD')}`;
    setFormData((prev) => {
      const updatedCode = prev.contractCode.includes(currentDate)
        ? prev.contractCode.replace(currentDate, '')
        : prev.contractCode + currentDate;
      return { ...prev, contractCode: updatedCode };
    });
  };
  const currentDate = `#${moment().format('YYYY-MM-DD')}`;
  const isDateIncluded = _formData.contractCode.includes(currentDate);
  
  const handleInputEvent = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => { return { ...prev, [name]: value } });
  };

  const handleContractPrice = (e) => {
    const { name, value } = e.target;

    let eventValue = value;
    if (!eventValue.toString().startsWith('0.')) eventValue = eventValue.toString().replace(/^0+/, '');
    if (eventValue < 0 || eventValue === '') eventValue = 0;
    if (eventValue.length > 15) return;

    setFormData((prev) => { return { ...prev, [name]: BigNumber(eventValue).toNumber() } });
  };

  const handleAttributeValue = (e, attr) => {
    const { value } = e.target;

    setContractAttrValueList((prev) => { return { ...prev, [attr.contractAttributeId]: value } });
  };

  const handleElementInput = (e, index, element) => {
    const { value } = e.target;

    let eventValue = value;
    if (!eventValue.toString().startsWith('0.')) eventValue = eventValue.toString().replace(/^0+/, '');
    if (eventValue < 0 || eventValue === '') eventValue = 0;
    if (eventValue.length > 15) return;

    const prevData = [..._contractElements];
    switch (e.target.name) {
      case 'quantity':
        const newData_quantity = {
          ...element,
          quantity: BigNumber(eventValue).toNumber(),
          calPrice: BigNumber(eventValue).multipliedBy(BigNumber(checkEmptyNull(element.price, 0))).toNumber(),
        };
        prevData.splice(index, 1, newData_quantity);
        break;

      case 'calPrice':
        const newData_calPrice = {
          ...element,
          calPrice: BigNumber(eventValue).toNumber(),
        };
        prevData.splice(index, 1, newData_calPrice);
        break;

      default: return;
    }

    const sumArray = prevData.map((item) => item.calPrice);
    const setSumArray = sumArray.filter((item) => item !== null);
    const sum = setSumArray.reduce((a, b) => BigNumber(a).plus(BigNumber(b)).toNumber(), 0);

    setFormData((prev) => { return { ...prev, contractPrice: sum } });

    setContractElements(() => { return prevData });
  };

  const deleteElement = (index, element) => {
    setContractElements((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);

      const sumArray = prevData.map((item) => item.calPrice);
      const setSumArray = sumArray.filter((item) => item !== null);
      const sum = setSumArray.reduce((a, b) => BigNumber(a).plus(BigNumber(b)).toNumber(), 0);

      setFormData((prev) => { return { ...prev, contractPrice: sum } });

      return prevData;
    });
  };

  const deleteProcurement = (index, procurement) => {
    setProcurements((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);

      return prevData;
    });
  };

  const deleteWorkOrderLog = (index, workOrderLog) => {
    setWorkOrderLogs((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);

      return prevData;
    });
  };

  const actCreate = async (e) => {
    e.preventDefault();

    if (!checkEmptyNull(_formData.contractCode, false)) return alert('수주코드를 입력해주세요.');
    if (!checkEmptyNull(_formData.contractName, false)) return alert('수주이름을 입력해주세요.');

    // 항목
    const mapContractAttrValueList = Object.keys(_contractAttrValueList).map((contractAttributeId) => {
      const returnData = {
        contractAttributeId: Number(contractAttributeId),
        value: _contractAttrValueList[contractAttributeId],
      };
      return returnData;
    },
    );

    const BodyToPost = {
      ..._formData,

      accountId: _account.accountId,

      contractAttributeValueList: mapContractAttrValueList,
      contractElementList: _contractElements.map((element) => {
        const returnData = {
          accountId: _account.accountId,
          elementId: element.elementId,
          quantity: element.quantity,
          price: element.calPrice,
        };

        return returnData;
      }),
    };

    // return;
    await contractApi.createContract(BodyToPost).then((response) => {
      if (response === undefined) return;

      alert('수주를 추가했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  /* ====================================================================== #6 */

  return (
    <Grid2Body
      contents={
        <>
          <ContractFormComponent>
            <NavBar title={'수주관리'} nav={''} />

            <Form
              title={
                <>
                  수주
                  <br />
                  추가
                </>
              }
              buttons={
                <>
                  <button className="formButton" onClick={actCreate}>저장</button>
                  <button className="formButton cancle" onClick={(e) => {
                    e.preventDefault();
                    navigate(pageReducer.currentPage, { replace: true });
                  }}
                  >
                    취소
                  </button>
                </>
              }
              forms={
                <>
                  <div className="formBox w100">
                    <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                      <h4>수주물품</h4>
                      <div className="formButton"
                        style={{ backgroundColor: 'var(--MainBlue)', borderRadius: '5px', width: '150px' }}
                        onClick={() => { setModalStatus(true) }}
                      >
                        수주물품 추가
                      </div>
                    </div>

                    <TableSection
                      content={
                        <table>
                          <thead style={{ backgroundColor: 'var(--MainNavy)' }}>
                            <tr>
                              <th>물품정보</th>
                              <th>수량</th>
                              <th>금액</th>
                              {/* <th>메모</th> */}
                              <th style={{ minWidth: '50px', width: '50px' }}></th>
                            </tr>
                          </thead>
                          <tbody>
                            {checkNullArray(_contractElements, false) && _contractElements.map((element, index) => {
                              return (
                                <tr key={index + '_ContractElements'} data-key={element.elementId}>
                                  <td className={checkEmptyNull(element.elementName, false) && element.elementName.startsWith('*') ? 'outsourcingText' : ''}>
                                    {element.elementName}
                                    <tr/>
                                    ({element.elementCode})
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      name="quantity"
                                      min={0}
                                      value={element.quantity}
                                      onInput={(e) => { handleElementInput(e, index, element) }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      name="calPrice"
                                      min={0}
                                      value={element.calPrice}
                                      onInput={(e) => { handleElementInput(e, index, element) }}
                                    />
                                  </td>
                                  {/* <td>
                                    <input
                                      type="text"
                                      name="memo"
                                      value={element.memo}
                                      onInput={(e) => { handleElementInput(e, index, element) }}
                                    />
                                  </td> */}
                                  <td style={{ minWidth: '50px', width: '50px' }}>
                                    <div className='DelButton' onClick={() => { deleteElement(index, element) }}><CloseButtonIcon /></div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      }
                      formSectionStyle={{ paddingTop: '10px', width: '100%' }}
                    />

                    <div className="cautionText" style={{ fontSize: '14px', textAlign: 'right', width: '100%' }}>
                      ※ 수주수량을 꼭! 입력해 주세요.
                    </div>
                  </div>

                  <div className="formBox">
                    <h4>수주유형</h4>
                    <select
                      name="contractType"
                      value={checkNull(_formData.contractTypeId, '')}
                      onChange={(e) => {
                        getContractAttrs(e.target.value);
                        setFormData((prev) => {
                          return { ...prev, contractTypeId: Number(e.target.value) };
                        });
                      }}
                    >
                      <option value="">수주유형</option>
                      {_contractTypes.map((type) => {
                        return (
                          <option key={type.contractTypeId} value={type.contractTypeId}>
                            {type.contractTypeName}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="formBox">
                    <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                      <h4>수주코드</h4>
                      <div className='DateCheckBox'>
                        <div
                          className="formButton"
                          style={
                            isDateIncluded
                              ? { backgroundColor: 'var(--MainNavy)', borderRadius: '5px', width: '100px', userSelect: 'none' }
                              : { backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '100px', userSelect: 'none' }
                          }
                          onClick={handleButtonClick}
                        >
                          {isDateIncluded ? '날짜삭제' : '날짜추가'}
                        </div>
                      </div>
                    </div>
                    <input
                      type="data"
                      name="contractCode"
                      placeholder="* 수주코드..."
                      data-required="required"
                      value={_formData.contractCode}
                      onInput={handleInputEvent}
                    />
                  </div>

                  <div className="formBox">
                    <h4>수주이름</h4>
                    <input
                      type="text"
                      name="contractName"
                      placeholder="* 수주이름..."
                      data-required="required"
                      value={_formData.contractName}
                      onInput={handleInputEvent}
                    />
                  </div>

                  <div className="formBox">
                    <div style={{ display: 'grid', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                      <h4>거래처</h4>
                      <div className="formButton" style={{ borderRadius: '5px', width: '150px', backgroundColor: 'var(--ThirdBlue)' }} onClick={() => { setAccountModalStatus(true) }}>
                        거래처 선택
                      </div>
                    </div>
                    <div style={{ columnGap: '5px', display: 'grid', gridTemplateColumns: 'auto 50px', width: '100%' }}>
                      <input
                        type="text"
                        name="accountName"
                        value={checkNullObject(_account, false) ? checkEmptyNull(_account.accountName, '') : ''}
                        readOnly
                        placeholder="거래처..."
                      />
                      <div className="formButton" style={{ backgroundColor: 'var(--MainNavy)', borderRadius: '5px', height: '40px', width: '50px', }} onClick={() => { setAccount(() => { return {} }) }}>
                        삭제
                      </div>
                    </div>
                  </div>

                  <div className="formBox">
                    <h4>수주일</h4>
                    <input
                      type="date"
                      name="contractDate"
                      value={_formData.contractDate}
                      onChange={handleInputEvent}
                    />
                  </div>

                  <div className="formBox">
                    <h4>납품요구일</h4>
                    <input
                      type="date"
                      name="contractRegDate"
                      value={_formData.contractRegDate}
                      onChange={handleInputEvent}
                    />
                  </div>

                  <div className="formBox">
                    <h4>납기일</h4>
                    <input
                      type="date"
                      name="scheduledEndDate"
                      value={_formData.scheduledEndDate}
                      onChange={handleInputEvent}
                    />
                  </div>

                  <div className="formBox">
                    <h4>납품일</h4>
                    <input
                      type="date"
                      name="contractActualEndDate"
                      value={_formData.contractActualEndDate}
                      onChange={handleInputEvent}
                    />
                  </div>

                  <div className="formBox">
                    <h4>수주주소</h4>
                    <input
                      type="text"
                      name="address"
                      placeholder="* 수주주소..."
                      value={_formData.address}
                      onInput={handleInputEvent}
                    />
                  </div>

                  <div className="formBox">
                    <h4>수주총금액</h4>
                    <input
                      type="number"
                      name="contractPrice"
                      placeholder="* 수주총금액..."
                      value={_formData.contractPrice}
                      onInput={handleContractPrice}
                    />
                  </div>

                  <div className="formBox">
                    <h4>비고</h4>
                    <textarea
                      name="remark"
                      placeholder="* 비고..."
                      value={_formData.remark}
                      onInput={handleInputEvent}
                    />
                  </div>

                  {_formData.contractTypeId !== null && checkNullArray(_contractAttrList, false) && _contractAttrList.map((attr, attrIndex) => {
                    return (
                      <div key={attrIndex + '_attr'} className="formBox">
                        <h4>{attr.contractAttributeName}</h4>
                        <input
                          type="text"
                          placeholder={attr.contractAttributeName + '...'}
                          value={_contractAttrValueList[attr.contractAttributeId]}
                          onInput={(e) => { handleAttributeValue(e, attr) }}
                        />
                      </div>
                    );
                  })}

                  {(() => {
                    if (
                      _authority.indexOf('202-1') !== -1 ||
                      _authority.indexOf('202-2') !== -1 ||
                      _authority.indexOf('205-1') !== -1 ||
                      _authority.indexOf('205-2') !== -1
                    ) {
                      return null;
                    } else {
                      return null;
                    }
                  })()}

                  {(() => {
                    if (
                      _authority.indexOf('403-1') !== -1 ||
                      _authority.indexOf('403-2') !== -1
                    ) {
                      return null;
                    } else {
                      return null;
                    }
                  })()}
                </>
              }
              formSectionStyle={{ paddingTop: '10px', width: 'calc(100% - 210px)' }}
            />
          </ContractFormComponent>

          {_accountModalStatus === true && (
            <SelectOneAccount
              buttonTitle={'거래처 선택'}
              buttonEvent={(data) => {
                setAccount(() => { return data });
                setFormData((prev) => { return { ...prev, address: `${checkNull(data.address, '')} ${checkNull(data.detailAddress, '')}` } });

                setTimeout(setAccountModalStatus(false), 1000);
              }}
              open={_accountModalStatus}
              close={() => { setAccountModalStatus(false) }}
            ></SelectOneAccount>
          )}

          {_modalStatus === true && (
            <SelectElements
              buttonEvent={(data) => {
                console.log('data : ', data);
                const contractElementIds = _contractElements.map((thisItem) => thisItem.elementId);
                const newElements = data.map((element) => {
                  if (contractElementIds.indexOf(element.elementId) !== -1) return null;
                  else return { ...element, calPrice: 0 };
                });
                const setNewElements = newElements.filter((thisItem) => thisItem !== null);

                setContractElements((prev) => { return [...prev, ...setNewElements] });

                setTimeout(setModalStatus(false), 1000);
              }}
              buttonTitle="물품 선택"
              open={_modalStatus}
              close={() => { setModalStatus(false) }}
            />
          )}

          {_procurementModalStatus === true && (
            <SelectProcurements
              buttonTitle={'발주 선택'}
              buttonEvent={(data) => {
                const procurementIds = _procurements.map((thisItem) => thisItem.procurementId);
                const newProcurements = data.map((thisItem) => {
                  if (procurementIds.indexOf(thisItem.procurementId) !== -1) return null;
                  else return thisItem;
                });
                const setNewProcurements = newProcurements.filter((thisItem) => thisItem !== null);

                setProcurements((prev) => { return [...prev, ...setNewProcurements] });

                setTimeout(setProcurementModalStatus(false), 1000);
              }}
              open={_procurementModalStatus}
              close={() => { setProcurementModalStatus(false) }}
            ></SelectProcurements>
          )}

          {_workOrderLogModalStatus === true && (
            <SelectWorkOrderLogs
              buttonTitle={'작업지시 선택'}
              buttonEvent={(data) => {
                const workOrderLogIds = _workOrderLogs.map((thisItem) => thisItem.workOrderLogId);
                const newWorkOrder = data.map((workOrderLog) => {
                  if (workOrderLogIds.indexOf(workOrderLog.workOrderLogId) !== -1) return null;
                  else return workOrderLog;
                });
                const setNewWorkOrder = newWorkOrder.filter((thisItem) => thisItem !== null);

                setWorkOrderLogs((prev) => { return [...prev, ...setNewWorkOrder] });

                setTimeout(setWorkOrderLogModalStatus(false), 1000);
              }}
              open={_workOrderLogModalStatus}
              close={() => { setWorkOrderLogModalStatus(false) }}
            ></SelectWorkOrderLogs>
          )}
        </>
      }
    />
  );
};

export default CreateContract;
