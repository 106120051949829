import { AxiosInterceptor } from "api/core/consts";

export const inquiryApi = {
  getInquiryId
    : (inquiryId) => AxiosInterceptor
      .get('inquiry/' + inquiryId),
  searchAllInquiry
    : (paging, body) => AxiosInterceptor
      .post('inquiry/search' + paging, body),
  createInquiry
    : (body) => AxiosInterceptor
      .post('inquiry', body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
  modifiedInquiry
    : (inquiryId, body) => AxiosInterceptor
      .put('inquiry/' + inquiryId, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
  deleteInquiry
    : (inquiryId) => AxiosInterceptor
      .delete('inquiry/' + inquiryId),
};
