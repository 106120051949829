import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 40px 50px;
  height: 85%;
  overflow: hidden;
  width: 90%;

  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
    & .statusButtons {
      display: flex;
      gap: 5px;
    }
  }
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: inherit;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;

    td {white-space: unset;}
  }
`;

const WorkOrderTable = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  table {
    background-color: var(--white);
    border-collapse: collapse;
    height: 96%;
    margin: 0px auto;
    table-layout: auto;
    width: 98%;

    td, th {
      border: 1px solid var(--gray-200);
      box-sizing: border-box;
      height: 40px;
      min-width: unset;
      padding: unset;
    }
    th {font-size: 0.8em;}
    & td.colors {
      font-size: 25px;
      font-weight: 600;
      outline: 4px solid var(--Text);
      outline-offset: -4px;
      text-align: center;
    }
    & td.remark {
      color: var(--ThirdBlue);
      font-size: 1.1em;
      padding: 10px;
      text-align: left;
      vertical-align: top;
    }
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const UriSelectWorkOrderModal = (props) => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_workOrderLogList, setWorkOrderLogList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_workOrderStatus, setWorkOrderStatus] = useState('all');
  const [_searchText, setSearchText] = useState('');

  const [_pageNum, setPageNum] = useState(1);
  const [_pageCount, setPageCount] = useState(0);

  /* ====================================================================== #3 */
  useEffect(() => {
    getWorkOrderLogList(_pageNum - 1, 'all', _workOrderStatus);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getWorkOrderLogList(_pageNum - 1, 'all', _workOrderStatus);
    }

    return () => { };
  }, [_pageNum]);

  /* ====================================================================== #4 */
  const getWorkOrderLogList = async (page, workOrderType, workOrderStatus, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && checkEmptyNull(workOrderType, false) && workOrderType !== 'all') {
      BodyToPost.workOrderTypeId = Number(workOrderType);
    }
    if (!clear && checkEmptyNull(workOrderStatus, false)) {
      switch (workOrderStatus) {
        case 'all': BodyToPost.workOrderStatus = ['wait', 'work', 'pause', 'stop']; break;
        case 'waitNwork': BodyToPost.workOrderStatus = ['wait', 'work']; break;

        default: BodyToPost.workOrderStatus = [workOrderStatus]; break;
      }
    }

    if (!clear && checkEmptyNull(_searchText)) BodyToPost.accountName = _searchText;

    await workOrderLogApi.searchWorkOrderLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.searchWorkOrderLog : ', response);
      setWorkOrderLogList(() => { return response.data.data.content; });
      setPageCount(() => { return response.data.data.totalElements; });
    });
  };

  /* ====================================================================== #5 */
  /* 검색 - 작업상태 */
  const handleSearchWorkOrderStatus = async (workOrderStatus) => {
    setWorkOrderStatus(() => { return workOrderStatus });
    setPageNum(() => { return 1 });
    await getWorkOrderLogList(0, 'all', workOrderStatus)
  }

  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(id);
    setCheckedItem(() => { return checkedItem; });
  };

  /* 검색 */
  const actSearch = async () => {
    setPageNum(() => { return 1; });
    await getWorkOrderLogList(0, 'all', _workOrderStatus);
  };

  /* 초기화 */
  const actReset = async () => {
    setWorkOrderStatus(() => { return 'all'; });
    setSearchText(() => { return ''; });
    setPageNum(() => { return 1; });
    await getWorkOrderLogList(0, 'all', _workOrderStatus, true);
  };

  const actSelect = () => {
    const checkedContentIndex = _workOrderLogList.findIndex((item) => item.workOrderLogId === Array.from(_checkedItem)[0]);
    if (checkedContentIndex === -1) return alert('작업지시를 선택해 주세요.');
    const checkedContent = _workOrderLogList[checkedContentIndex];
    console.log('checkedContent : ', checkedContent);

    // dispatch(YoungContentsReducer_setScheduleWorkOrder(checkedContent));
    if (props.setWorkOrderLogData) {
      props.setWorkOrderLogData(() => { return checkedContent });
    }
    setTimeout(() => { props.close() }, 1000);
  };

  /* ========================================================================= #5 */
  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>작업지시 선택</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <NavBar
                title={
                  <div className='Title'>
                    <NavTitle menuCode={'403'} />
                    <div className='statusButtons'>
                      {[
                        { status: 'all', name: '전체' },
                        { status: 'waitNwork', name: '대기/작업중' },
                        { status: 'wait', name: '대기' },
                        { status: 'work', name: '작업중' },
                        { status: 'pause', name: '일시정지' },
                        { status: 'stop', name: '중단' },
                      ].map((workOrderStatus, index) => {
                        return (
                          <button key={index + '_statusButton'}
                            className={_workOrderStatus === workOrderStatus.status ? 'btn-inven-1' : 'btn-set'}
                            onClick={() => { handleSearchWorkOrderStatus(workOrderStatus.status); }}
                          >
                            {workOrderStatus.name}
                          </button>
                        )
                      })}
                    </div>
                  </div>
                }
                nav={''}
                buttons={
                  <>
                    <div className="SearchSection" style={{ columnGap: '20px' }}>
                      <div className="ContentCBox">
                        <div className="ContentCBox">
                          <select className="SearchOption detail">
                            <option value="accountName">거래처</option>
                          </select>
                          <input
                            className="SearchBar detail"
                            placeholder="Search..."
                            value={_searchText}
                            onInput={(e) => { setSearchText(() => { return e.target.value }) }}
                          />
                        </div>

                        <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                        <div className="ResetButton" onClick={actReset}>초기화</div>
                      </div>
                    </div>
                  </>
                }
              />

              <TableSection content={
                <table>
                  <thead style={{ zIndex: '800' }}>
                    <tr>
                      <th style={{ minWidth: '100px', padding: 'unset', width: '100px' }}>작업상태</th>
                      <th style={{ minWidth: '50px', width: '50px' }}></th>
                      <th style={{ minWidth: '150px', width: '150px' }}>거래처</th>
                      {/* <th style={{ minWidth: '150px', width: '150px' }}>현장명</th> */}
                      <th style={{ minWidth: '960px' }}>작업지시</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_workOrderLogList.map((workOrderLog, index) => {
                      const workOrderInfo = checkNullParse(workOrderLog.workOrderCustomizedContent, {
                        BF: { date: '', amount: '' },
                        SF: { date: '', amount: '' },
                        MF: { date: '', amount: '' },
                        total: { date: '', amount: '' },

                        colors: '', // 색상
                        phoneNumber: '', // 전화번호
                      });

                      return (<Fragment key={workOrderLog.workOrderLogId + '_' + index}>
                        <tr data-key={workOrderLog.workOrderLogId} name="workOrderRow">
                          <StatusTdButton
                            statusStyle={{ cursor: 'default', minHeight: '100px', minWidth: '80px', width: '80px' }}
                            content={workOrderLog}
                            contentId={workOrderLog.workOrderLogId}
                            status={workOrderLog.workOrderStatus}
                          />
                          <td style={{ minWidth: '50px', width: '50px' }}>
                            <input
                              type="checkBox"
                              name="workOrder"
                              data-key={workOrderLog.workOrderLogId}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                handleCheckedItem(workOrderLog.workOrderLogId, isChecked);
                                if (isChecked) {
                                  document.getElementsByName('workOrder').forEach((checkbox) => { checkbox.checked = false });
                                  e.target.checked = true;
                                } else {
                                  e.target.checked = false;
                                }
                              }}
                            />
                          </td>
                          <td style={{ minWidth: '150px', width: '150px' }}>{workOrderLog.accountName}</td>
                          {/* <td style={{ minWidth: '150px', width: '150px' }}>{workOrderLog.detailedWorkPlace}</td> */}
                          <td style={{ minWidth: '960px', padding: 'unset' }}>
                            <WorkOrderTable>
                              <table data-key={workOrderLog.workOrderLogId}>
                                <colgroup>
                                  <col width='50px'></col>
                                  <col width='350px'></col>
                                  <col width='50px'></col>
                                  <col width='200px'></col>
                                  <col width='50px'></col>
                                  <col width='100px'></col>
                                  <col width='50px'></col>
                                  <col width='50px'></col>
                                </colgroup>
                                <tbody>
                                  {/* "{"BF":{"date":"","amount":""},"SF":{"date":"","amount":""},"MF":{"date":"","amount":""},"total":{"date":"","amount":""},"colors":"","phoneNumber":null}" */}
                                  <tr>
                                    <th rowSpan={3}>색상</th>
                                    <td rowSpan={3} className='colors'>
                                      {workOrderInfo.colors}
                                    </td>
                                    <th>거래처</th>
                                    <td>{workOrderLog.accountName}</td>
                                    <th>B/F납기</th>
                                    <td>{checkNullObject(workOrderInfo?.BF, false) ? workOrderInfo?.BF?.date : workOrderInfo.BFDate}</td>
                                    <th>B/F</th>
                                    <td>{checkNullObject(workOrderInfo?.BF, false) ? workOrderInfo?.BF?.amount : workOrderInfo.BFAmount}</td>
                                  </tr>
                                  <tr>
                                    <th>전화번호</th>
                                    <td>{workOrderInfo.phoneNumber}</td>
                                    <th>S/F납기</th>
                                    <td>{checkNullObject(workOrderInfo?.SF, false) ? workOrderInfo?.SF?.date : workOrderInfo.TotalDate}</td>
                                    <th>S/F</th>
                                    <td>{checkNullObject(workOrderInfo?.SF, false) ? workOrderInfo?.SF?.amount : workOrderInfo.TotalAmount}</td>
                                  </tr>
                                  <tr>
                                    <th>주소</th>
                                    <td>{workOrderLog.workPlace}</td>
                                    <th>M/F납기</th>
                                    <td>{workOrderInfo?.MF?.date}</td>
                                    <th>M/F</th>
                                    <td>{workOrderInfo?.MF?.amount}</td>
                                  </tr>
                                  <tr>
                                    <th rowSpan={2}>비고</th>
                                    <td colSpan={3} rowSpan={2} className='remark'>{workOrderLog.remark}</td>
                                    <th>지시일자</th>
                                    <td colSpan={3}>{DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledStartDate, ''))}</td>
                                  </tr>
                                  <tr>
                                    <th>작성자</th>
                                    <td colSpan={3}>{workOrderLog.userName}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </WorkOrderTable>
                          </td>
                        </tr>
                      </Fragment>
                      );
                    })}
                  </tbody>
                </table>
              }
              ></TableSection>
            </ModalMain>

            <PagingComponent
              page={_pageNum}
              count={_pageCount}
              size={10}
              pageEvent={(page) => {
                console.log('page : ', page);
                setPageNum(() => {
                  return page;
                });
              }}
            />

            <ModalFooter>
              <ModalButton onClick={actSelect}>작업지시 선택</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default UriSelectWorkOrderModal;
