import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { bookmark } from 'api/apis/bookmark/bookmark';
import { elementAttributeApi } from 'api/apis/elementAttributeApi';
import { elementApi } from 'api/apis/elementApi';

import {
  checkEmptyNull,
  checkNullArray,
  checkNullObject,
} from 'components/checkValues/checkValues';

import Bookmark from 'components/icons/Bookmark';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import InlineCategories from 'components/category/InlineCategories';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 40px 50px;
  height: 85%;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
  }
`;

const SearchAttrs = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  width: calc(100vw - 450px);
`;
const SearchAttr = styled.div`
  align-items: center;
  column-gap: 10px;
  display: flex;
  height: unset;
  padding: 6px 20px;
  justify-content: center;
  width: fit-content;

  & p {
    width: calc(100% - 20px);
    word-break: break-all;
  }
`;

const DeleteButton = styled.div`
  height: 24px;
  width: 24px;

  div {
    background-color: var(--white);
    cursor: pointer;
    height: 15px;
    width: 15px;
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const GsSelectElements = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const ElementCheckBox = useRef();

  const [_onload, setOnload] = useState('unload');

  const [_elementAttrList, setElementAttrList] = useState([]);
  const [_elementAttrNameList, setElementAttrNameList] = useState({});
  const [_elementList, setElementList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState([]);

  const [_elementType, setElementType] = useState('all');
  const [_clickedCategory, setClickedCategory] = useState({});
  const [_bookmark, setBookmark] = useState(true);
  const [_searchElementData, setSearchElementData] = useState({});
  const [_searchElementAttrs, setSearchElementAttrs] = useState({});
  const [_searchOption, setSearchOption] = useState('');
  const [_searchText, setSearchText] = useState('');

  const [_detailStatus, setDetailStatus] = useState(false);

  const [_pageNum, setPageNum] = useState(1);
  const [_pageCount, setPageCount] = useState(0);

  const [_reset, setReset] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    getElementAttrs();
    getElementList(0, 'all');

    setOnload('loaded');
    return () => {};
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getElementList(_pageNum - 1, _elementType);
      ElementCheckBox.current.checked = false;
    }
    return () => {};
  }, [_pageNum]);

  useEffect(() => {
    if (_onload === 'loaded') {
      getElementList(0, _elementType);
      ElementCheckBox.current.checked = false;
    }
    return () => {};
  }, [_bookmark]);
  useEffect(() => {
    if (_onload === 'loaded') {
      if (
        _clickedCategory === 'all' ||
        checkNullObject(_clickedCategory, false)
      ) {
        setPageNum(() => {
          return 1;
        });
        getElementList(0, _elementType);
      }
    }
    return () => {};
  }, [_clickedCategory]);

  useEffect(() => {}, [_checkedItem, _reset]);

  /* ====================================================================== #4 */
  const getElementAttrs = async (elementType) => {
    const BodyToPost = { companyId: userReducer.company.companyId };
    if (checkEmptyNull(elementType, false) && elementType !== 'all') {
      BodyToPost.elementType = elementType;
    }
    console.log('getElementAttrs - BodyToPost : ', BodyToPost);

    await elementAttributeApi
      .searchElementAttribute(BodyToPost)
      .then((response) => {
        if (response === undefined) return;
        console.log('elementAttributeApi.searchElementAttribute : ', response);
        setElementAttrList(() => {
          return response.data;
        });
        const nameList = {};
        response.data.forEach((thisItem) => {
          nameList[thisItem.elementAttributeId] = thisItem.elementAttributeName;
        });
        setElementAttrNameList(() => {
          return nameList;
        });
      });
  };

  const getElementList = async (page, elementType, clear) => {
    let paging = `?page=${page}&size=10`;
    if (_bookmark) paging += '&sort=bookMark,DESC&sort=id,DESC';
    else paging += '&sort=id,DESC';

    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && checkEmptyNull(elementType, false) && elementType !== 'all') {
      BodyToPost.elementType = elementType;
    }
    if (
      !clear &&
      checkEmptyNull(_clickedCategory, false) &&
      _clickedCategory !== 'all'
    ) {
      BodyToPost.categoryId = _clickedCategory.categoryId;
    }
    if (!clear && checkEmptyNull(_searchElementData.elementName, false)) {
      BodyToPost.elementName = _searchElementData.elementName;
    }
    if (!clear && checkEmptyNull(_searchElementData.elementCode, false)) {
      BodyToPost.elementCode = _searchElementData.elementCode;
    }
    if (
      !clear &&
      _detailStatus &&
      checkNullObject(_searchElementAttrs, false)
    ) {
      BodyToPost.attributeAndValue = _searchElementAttrs;
    }
    console.log('getElementList - BodyToPost : ', BodyToPost);

    await elementApi.searchElement(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('elementApi.searchElement : ', response);
      setElementList(() => {
        return response.data.content;
      });
      setPageCount(() => {
        return response.data.totalElements;
      });
      setReset(() => {
        return false;
      });
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (item, isChecked) => {
    const checkedItem = [..._checkedItem];
    const checkedIndex = checkedItem.findIndex(
      (thisItem) => thisItem.elementId === item.elementId,
    );
    if (checkedIndex !== -1) {
      if (!isChecked) checkedItem.splice(checkedIndex, 1);
    } else {
      if (isChecked) checkedItem.push(item);
    }
    console.log('checkedItem : ', checkedItem);
    setCheckedItem(() => {
      return checkedItem;
    });
  };

  const handleBookmark = async (element, index) => {
    await bookmark.elementBookmark(element.elementId).then((response) => {
      if (response === undefined) return;
      console.log('bookmark.elementBookmark : ', response);
      getElementList(_pageNum - 1, _elementType);
    });
  };

  /* 검색 */
  const actSearch = async () => {
    setPageNum(() => {
      return 1;
    });
    await getElementList(0, _elementType);
  };

  /* 상세 검색 */
  const addSearchAttr = async () => {
    if (
      !checkEmptyNull(_searchOption, false) ||
      !checkEmptyNull(_searchText, false)
    )
      return;
    setSearchElementAttrs((prev) => {
      return { ...prev, [_searchOption]: _searchText };
    });
  };

  /* 초기화 */
  const actReset = async () => {
    setReset(() => {
      return true;
    });

    setElementType(() => {
      return 'all';
    });
    setSearchElementData(() => {
      return {};
    });
    setSearchElementAttrs(() => {
      return {};
    });
    setSearchOption(() => {
      return '';
    });
    setSearchText(() => {
      return '';
    });
    setClickedCategory(() => {
      return {};
    });
    setPageNum(() => {
      return 1;
    });
    await getElementAttrs();
    await getElementList(0, 'all', true);
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>물품 선택</ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              <NavBar
                nav={!_detailStatus ? 'search' : 'all'}
                title={
                  <>
                    <div className="SearchSection">
                      <select
                        className="SelectType"
                        value={_elementType}
                        onChange={(e) => {
                          setElementType(() => {
                            return e.target.value;
                          });
                          setPageNum(() => {
                            return 1;
                          });
                          getElementAttrs(e.target.value);
                          getElementList(0, e.target.value);
                        }}
                      >
                        <option value="all">전체</option>
                        {userReducer.elementType.map((thisItem) => {
                          let typeText = '';
                          switch (thisItem) {
                            case 'material':
                              typeText = '자재';
                              break;
                            case 'semi':
                              typeText = '부자재';
                              break;
                            case 'half':
                              typeText = '판매물품';
                              break;
                            case 'product':
                              typeText = '보유물품';
                              break;
                            case 'tool':
                              typeText = '공구';
                              break;

                            default:
                              return null;
                          }
                          return (
                            <option
                              key={thisItem + '_elementTypes'}
                              value={thisItem}
                            >
                              {typeText}
                            </option>
                          );
                        })}
                      </select>

                      <InlineCategories
                        handlerCategory={setClickedCategory}
                        reset={_reset}
                      />
                    </div>
                  </>
                }
                firstRow={
                  <>
                    <div className="SearchSection">
                      <div className="ContentCBox">
                        <select className="SearchOption detail">
                          <option value="elementName">물품이름</option>
                        </select>
                        <input
                          className="SearchBar detail"
                          placeholder="Search..."
                          value={checkEmptyNull(
                            _searchElementData.elementName,
                            '',
                          )}
                          onInput={(e) => {
                            setSearchElementData((prev) => {
                              return { ...prev, elementName: e.target.value };
                            });
                          }}
                        />
                      </div>

                      <div className="ContentCBox">
                        <select className="SearchOption detail">
                          <option value="elementCode">물품코드</option>
                        </select>
                        <input
                          className="SearchBar detail"
                          placeholder="Search..."
                          value={checkEmptyNull(
                            _searchElementData.elementCode,
                            '',
                          )}
                          onInput={(e) => {
                            setSearchElementData((prev) => {
                              return { ...prev, elementCode: e.target.value };
                            });
                          }}
                        />
                      </div>

                      <div className="ContentCBox">
                        <div className="ResetButton detail" onClick={actReset}>
                          초기화
                        </div>

                        <div
                          className={`DetailButton ${
                            !_detailStatus && 'active'
                          }`}
                          onClick={() => {
                            setDetailStatus((prev) => {
                              return !prev;
                            });
                          }}
                        >
                          상세 검색
                        </div>

                        <div
                          className="DetailButton search"
                          style={{
                            backgroundColor: 'var(--ThirdBlue)',
                            columnGap: '3px',
                          }}
                          onClick={actSearch}
                        >
                          <SearchButtonIcon />
                          검색
                        </div>
                      </div>
                    </div>
                  </>
                }
                secondRow={
                  <>
                    {_detailStatus && (
                      <div className="SearchSection">
                        <div className="ContentCBox">
                          <div className="ContentCBox">
                            <select
                              className="SearchOption"
                              value={_searchOption}
                              onChange={(e) => {
                                setSearchOption(() => {
                                  return e.target.value;
                                });
                              }}
                            >
                              <option value="">항목선택</option>
                              {_elementAttrList.map((thisItem) => {
                                return (
                                  <option
                                    key={
                                      thisItem.elementAttributeId +
                                      '_searchOptionAttrs'
                                    }
                                    value={thisItem.elementAttributeId}
                                  >
                                    {thisItem.elementAttributeName}
                                  </option>
                                );
                              })}
                            </select>
                            <input
                              className="SearchBar"
                              placeholder="Search..."
                              value={_searchText}
                              onInput={(e) => {
                                setSearchText(() => {
                                  return e.target.value;
                                });
                              }}
                            />
                          </div>

                          <button
                            data-searchbutton="true"
                            onClick={addSearchAttr}
                          >
                            <div
                              style={{ fontSize: '30px', lineHeight: '35px' }}
                            >
                              +
                            </div>
                          </button>
                        </div>

                        <SearchAttrs>
                          {Object.keys(_searchElementAttrs).map((thisKey) => {
                            return (
                              <SearchAttr
                                key={thisKey + '_searchAttr'}
                                className="formButton"
                                onClick={(e) => {
                                  setSearchElementAttrs((prev) => {
                                    const prevData = { ...prev };
                                    delete prevData[thisKey];
                                    return prevData;
                                  });
                                }}
                              >
                                <p>{`${_elementAttrNameList[thisKey]}: ${_searchElementAttrs[thisKey]}`}</p>
                                <DeleteButton>
                                  <CloseButtonIcon />
                                </DeleteButton>
                              </SearchAttr>
                            );
                          })}
                        </SearchAttrs>
                      </div>
                    )}
                  </>
                }
              />
              <TableSection
                content={
                  <table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '50px', width: '50px' }}>
                          <input
                            type="checkBox"
                            name="allElements"
                            ref={ElementCheckBox}
                            onChange={(e) => {
                              const isChecked = e.target.checked;

                              if (isChecked) {
                                setCheckedItem((prev) => {
                                  const prevData = [...prev];
                                  const newData = _elementList.map(
                                    (thisItem) => {
                                      const thisFindIndex = prevData.findIndex(
                                        (thisIndex) =>
                                          thisIndex.elementId ===
                                          thisItem.elementId,
                                      );
                                      if (thisFindIndex !== -1) return null;
                                      else return thisItem;
                                    },
                                  );
                                  const filterData = newData.filter(
                                    (thisItem) => thisItem !== null,
                                  );
                                  return [...prevData, ...filterData];
                                });

                                e.target.checked = true;
                              } else {
                                setCheckedItem((prev) => {
                                  const prevData = prev.map((thisItem) => {
                                    const thisFindIndex =
                                      _elementList.findIndex(
                                        (thisIndex) =>
                                          thisIndex.elementId ===
                                          thisItem.elementId,
                                      );
                                    if (thisFindIndex !== -1) return null;
                                    else return thisItem;
                                  });
                                  const filterData = prevData.filter(
                                    (thisItem) => thisItem !== null,
                                  );
                                  return filterData;
                                });

                                e.target.checked = false;
                              }
                            }}
                          />
                        </th>
                        <th style={{ minWidth: '50px', width: '50px' }}>
                          <Bookmark
                            bookmark={_bookmark}
                            clickEvent={() => {
                              setBookmark((prev) => {
                                return !prev;
                              });
                            }}
                          />
                        </th>
                        <th>패밀리</th>
                        <th>물품이름</th>
                        <th>물품코드</th>
                        <th>단위</th>
                        <th>안전재고</th>
                        <th>단가</th>
                        {_elementAttrList.map((thisItem) => (
                          <th
                            key={thisItem.elementAttributeId + '_elementAttrs'}
                          >
                            {thisItem.elementAttributeName}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {_elementList.map((thisItem, index) => {
                        return (
                          <tr key={index + '_elements'}>
                            <td style={{ minWidth: '50px', width: '50px' }}>
                              <input
                                type="checkBox"
                                name="elements"
                                data-key={thisItem.elementId}
                                checked={(() => {
                                  const checkedId = _checkedItem
                                    ? _checkedItem.findIndex(
                                        (thisIndex) =>
                                          thisIndex.elementId ===
                                          thisItem.elementId,
                                      )
                                    : -1;
                                  if (checkedId !== -1) return true;
                                  else return false;
                                })()}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  handleCheckedItem(thisItem, isChecked);
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              />
                            </td>
                            <td style={{ minWidth: '50px', width: '50px' }}>
                              <Bookmark
                                bookmark={checkEmptyNull(
                                  thisItem.bookMark,
                                  false,
                                )}
                                clickEvent={() => {
                                  handleBookmark(thisItem, index);
                                }}
                              />
                            </td>
                            <td>{thisItem.categoryPath}</td>
                            <td
                              className={
                                checkEmptyNull(thisItem.elementName, false) &&
                                thisItem.elementName.startsWith('*')
                                  ? 'outsourcingText'
                                  : null
                              }
                            >
                              {thisItem.elementName}
                            </td>
                            <td>{thisItem.elementCode}</td>
                            <td>{thisItem.elementUnit}</td>
                            <td>
                              {checkEmptyNull(
                                thisItem.elementSafeStock,
                                0,
                              ).toLocaleString() +
                                ' ' +
                                checkEmptyNull(thisItem.elementUnit, '')}
                            </td>
                            <td>
                              {checkEmptyNull(
                                thisItem.price,
                                0,
                              ).toLocaleString()}
                            </td>
                            {checkNullArray(
                              thisItem.elementAttributeValueList,
                              false,
                            ) &&
                              checkNullArray(_elementAttrList, []).map(
                                (thisAttribute) => {
                                  const thisAttributeValue =
                                    thisItem.elementAttributeValueList.findIndex(
                                      (thisItem) =>
                                        thisItem.elementAttribute
                                          .elementAttributeId ===
                                        thisAttribute.elementAttributeId,
                                    );
                                  if (thisAttributeValue !== -1)
                                    return (
                                      <td
                                        key={
                                          thisItem.elementId +
                                          '_elementAttr_' +
                                          thisItem.elementAttributeValueList[
                                            thisAttributeValue
                                          ].elementAttribute.elementAttributeId
                                        }
                                      >
                                        {
                                          thisItem.elementAttributeValueList[
                                            thisAttributeValue
                                          ].value
                                        }
                                      </td>
                                    );
                                  else
                                    return (
                                      <td
                                        key={
                                          thisItem.elementId +
                                          '_elementAttr_' +
                                          thisAttribute.elementAttributeId
                                        }
                                      ></td>
                                    );
                                },
                              )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                }
              ></TableSection>
            </ModalMain>

            <PagingComponent
              page={_pageNum}
              count={_pageCount}
              size={10}
              pageEvent={(page) => {
                console.log('page : ', page);
                setPageNum(() => {
                  return page;
                });
              }}
            />

            <ModalFooter>
              <ModalButton
                onClick={() => {
                  props.buttonEvent(_checkedItem);
                }}
              >
                {props.buttonTitle}
              </ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default GsSelectElements;
