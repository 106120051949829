import Chart from 'react-apexcharts';
import styled from 'styled-components';

const ChartWrapper = styled.div`
  width: 100%;
`

const HistoricalDataGraph = ({ toggle, data }) => {

  // x-axis에 표시할 레이블 데이터를 가공
  const xaxisData = data.map(item => {
    if (toggle === "year") {
      return item.date.substring(0, 4); // 년도만 표시
    } else if (toggle === "month") {
      return item.date.substring(5, 7); // 월만 표시
    } else if (toggle === "day") {
      return item.date.substring(8, 10); // 일만 표시
    }
    return item.date; // 기본적으로는 전체 날짜를 표시
  });

  const seriesData = data.map(item => item.value);

  const options = {
    chart: {
      id: 'test2'
    },
    xaxis: {
      // categories: getXAxisCategories()
      categories: xaxisData,
    }
  };

  const series = [
    {
      name: "series-1",
      // data: getSeriesData()
      data: seriesData,
    }
  ];

  return (
    <ChartWrapper>
      <Chart options={options} series={series} type="bar" width="100%" height="auto" />
    </ChartWrapper>
  );
}

export default HistoricalDataGraph;
