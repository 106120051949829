import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';

import {
  stockReducer_reset,
  stockReducer_setInItem,
} from 'store/modules/actions/default/stockActions';

import {
  AXIOS_BASE_HEADERS,
  AXIOS_BASE_URL,
} from 'preferences/server/constants';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import CommaNum from 'components/format/CommaNum';
import SelectElements from 'pages/element/modal/SelectElements';
import GsSelectElements from 'pages/element/modal/GsSelectElements';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  /* max-height: 300px; */
  /* max-width: 900px; */
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  /* height: calc(100% - 55px); */
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
    /* zoom: 67%; */

    input[type='number'] {
      background-color: var(--ThirdBlue);
      box-sizing: border-box;
      color: var(--white);
      font-size: 20px;
      font-weight: 600;
      margin: 0px auto;
      min-height: 40px;
      padding: 5px 10px 5px 20px;
      text-align: left;
      width: 200px;
    }
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainGreen);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const DelButton = styled.div`
  height: 15px;
  margin: 0px auto;
  width: 15px;

  div {
    background-color: var(--MainRed);
    cursor: pointer;
    height: 15px;
    width: 15px;
  }
`;

const GSWorkEndStockIn = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { stockReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const newDate = new Date();
  newDate.setHours(newDate.getHours() + 9);
  const CurrentTime = newDate.toISOString();

  const [_stockArray, setStockArray] = useState([]);
  const [_modalStatus, setModalStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    setOnload('loaded');

    return () => {};
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      setStockArray(stockReducer.inItem);
    }

    return () => {};
  }, [stockReducer.inItem]);

  /* ====================================================================== #4 */

  /* ====================================================================== #5 */
  const delStockProduct = (e) => {
    const delElement = e.target.closest('tr');
    const delElementId = delElement.dataset.key;
    const setDelStockList = _stockArray.filter(
      (thisItem) => thisItem.elementId !== Number(delElementId),
    );
    console.log(delElement, delElementId, setDelStockList);

    dispatch(stockReducer_setInItem(setDelStockList));
  };

  const actIncoming = async () => {
    if (document.getElementsByName('stockAmounts').length === 0)
      return alert('물품을 추가해주세요.');

    let count = 0;
    const BodyToPost = [];
    document.getElementsByName('stockAmounts').forEach((thisProduct) => {
      count++;
      if (Number(thisProduct.value) === 0) return;

      const thisProductInfo = _stockArray.filter(
        (thisItem) =>
          thisItem.elementId === Number(thisProduct.dataset.elementid),
      )[0];
      console.log(thisProductInfo);

      let BTP = {};
      BTP.companyId = userReducer.company.companyId;
      BTP.userId = userReducer.user.id;
      BTP.elementId = thisProductInfo.elementId;

      BTP.checkedDate = CurrentTime;

      BTP.logType = 'In';
      BTP.amount = Number(thisProduct.value);

      BTP.fileFath = '';
      BTP.fileName = '';
      BTP.note = '';

      BodyToPost.push(BTP);
    });

    if (count === document.getElementsByName('stockAmounts').length) {
      console.log(BodyToPost);
      createElementStockLogs(BodyToPost);
    }
  };
  const createElementStockLogs = async (BodyToPost) => {
    await axios
      .post(
        AXIOS_BASE_URL + '/elementStockLogs',
        BodyToPost,
        AXIOS_BASE_HEADERS,
      )
      .then((response) => {
        if (response === undefined) return;
        console.log(response);
        if (response.status === 200) {
          dispatch(stockReducer_reset());
          return alert('입고되었습니다.');
        }
      })
      .catch((error) => {
        console.log('post/elementStockLogs-error : ', error);
      });
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>잔여자재 입고</ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              <TableSection
                content={
                  <table key={'StockInTable'}>
                    <thead>
                      <tr>
                        <th>물품코드</th>
                        <th>물품이름</th>
                        <th>현재고</th>
                        <th>입고수량</th>
                        <th style={{ minWidth: '50px', width: '50px' }}></th>
                      </tr>
                    </thead>

                    <tbody>
                      {_stockArray.map((thisRowData) => {
                        const thisRowKey = thisRowData.elementId;
                        return (
                          <tr key={thisRowKey} data-key={thisRowKey}>
                            <td key={thisRowKey + '물품코드'}>
                              {thisRowData.elementCode}
                            </td>
                            <td
                              key={thisRowKey + '물품이름'}
                              className={
                                thisRowData.elementName.startsWith('*')
                                  ? 'outsourcingText'
                                  : null
                              }
                            >
                              {thisRowData.elementName}
                            </td>
                            <td
                              key={thisRowKey + '현재고'}
                              className={
                                thisRowData.elementSafeStock === null
                                  ? null
                                  : thisRowData.elementSafeStock <=
                                    thisRowData.elementStock
                                  ? 'safeStock'
                                  : 'dangerStock'
                              }
                            >
                              <CommaNum
                                key={thisRowKey}
                                displayType={'text'}
                                num={thisRowData.elementStock}
                              />{' '}
                              {thisRowData.elementUnit}
                            </td>
                            <td>
                              <input
                                type="number"
                                name="stockAmounts"
                                data-elementid={thisRowKey}
                                min={0}
                                defaultValue="0"
                              />
                            </td>
                            <td style={{ minWidth: '50px', width: '50px' }}>
                              <DelButton
                                key={thisRowKey}
                                onClick={delStockProduct}
                              >
                                <CloseButtonIcon />
                              </DelButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                }
              ></TableSection>
            </ModalMain>

            <ModalFooter>
              <ModalButton
                style={{ backgroundColor: 'var(--MainNavy)' }}
                onClick={() => {
                  setModalStatus(true);
                }}
              >
                물품추가
              </ModalButton>
              <ModalButton onClick={actIncoming}>입고</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}

      {/* 경서 커스텀 */}
      {_modalStatus === true && (
        <GsSelectElements
          buttonEvent={(data) => {
            console.log('data : ', data);
            const inItemIdList = stockReducer.inItem.map(
              (thisItem) => thisItem.elementId,
            );
            const newInItem = data.map((thisItem) => {
              if (inItemIdList.indexOf(thisItem.elementId) !== -1) return null;
              else return thisItem;
            });
            const setNewInItem = newInItem.filter(
              (thisItem) => thisItem !== null,
            );
            const inItem = [...stockReducer.inItem, ...setNewInItem];
            dispatch(stockReducer_setInItem(inItem));

            setModalStatus(false);
          }}
          buttonTitle="물품 선택"
          open={_modalStatus}
          close={() => {
            setModalStatus(false);
          }}
        />
      )}
    </>
  );
};

export default GSWorkEndStockIn;
