import React from 'react';

import { LoadingComponentStyle } from './LoadingComponent.style';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const LoadingComponent = ({
  close, title
}) => {
  return (
    <LoadingComponentStyle>
      <div onClick={() => {close();}}><CloseButtonIcon /></div>
      <p>{title}</p>
    </LoadingComponentStyle>
  );
};

export default LoadingComponent;