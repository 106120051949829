import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { contractApi } from 'api/apis/contractApi';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

const DeleteContract = () => {
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  const [searchParams, setSearchParams] = useSearchParams();

  const deleteContent = JSON.parse(searchParams.get('deleteContent'));
  console.log(deleteContent);

  const actDelete = async () => {
    await contractApi.deleteContract(deleteContent.contractId).then((response) => {
      if (response === undefined) return;
      console.log('contractApi.deleteContract : ', response);
      alert('수주를 삭제했습니다.');
      setTimeout(navigate(pageReducer.currentPage, { replace: true }), 1000);
    });
  };

  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <main className="Main">
        <NavBar title={<NavTitle menuCode={'201'} />} nav={''} />

        <DeleteForm
          delteTitle={<NavTitle menuCode={'201'} />}
          deleteItem={
            <>
              {'수주코드 : ' + deleteContent.contractCode}<br />
              {'수주이름 : ' + deleteContent.contractName}<br />
            </>
          }
          deleteText={<>해당 <span className="cautionText"><NavTitle menuCode={'201'} /></span>가 삭제됩니다.</>}
          deleteButton={
            <>
              <button className="formButton cancle" onClick={() => { navigate(pageReducer.currentPage, { replace: true }); }}>
                취소
              </button>
              <button className="formButton delete"
                onClick={() => {
                  const confirmText = `
                  ${userReducer.userMenu.find((thisItem) => {
                    if (thisItem.pageCode === '201') return true;
                    else return false;
                  }).pageName} 을 삭제합니다.
                  `;
                  if (window.confirm(confirmText)) actDelete();
                }}
              >
                삭제
              </button>
            </>
          }
        />
      </main>
    }
    />
  );
};

export default DeleteContract;
