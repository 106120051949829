import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { QRCodeSVG } from 'qrcode.react';

import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  height: 85%;
  overflow: hidden;
  width: 90%;

  grid-template-rows: ${(props) => {
    return props.type === 'list' ? '55px calc(100% - 55px)' : '55px auto 50px';
  }};
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const UpdateButton = styled.button`
  position: absolute;
  right: 55px;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;

    input {
      border: 1px solid var(--gray-200);
      border-radius: 5px;
      box-sizing: border-box;
      color: var(--MainBlue);
      font-size: 20px;
      font-weight: 600;
      height: 40px;
      min-width: 200px;
      padding: 5px 15px;
      width: 98%;
    }
  }
`;
const QRCode = styled(QRCodeSVG)`
  cursor: pointer;
  height: 50px;
  width: 50px;
`;
const DelButton = styled.div`
  height: 15px;
  margin: 0px auto;
  width: 15px;
  div {background-color: var(--MainRed); cursor: pointer; height: 15px; width: 15px; }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const GSCLWorkList = (props) => {
  /* ====================================================================== #1 */
  const { gsReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const workOrderLog = gsReducer.workOrderContent;
  const [_processLogList, setProcessLogList] = useState(gsReducer.workOrderContent.processLogList.filter((thisProcess) => thisProcess.processTypeName === 'CL'));

  const [_showUpdateList, setShowUpdateList] = useState(false);

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    userId: userReducer.user.id,
    workOrderStatus: workOrderLog.workOrderStatus,
    workOrderLogId: workOrderLog.workOrderLogId,
    workOrderCode: workOrderLog.workOrderCode,
    workOrderName: workOrderLog.workOrderName,
    workOrderScheduledStartDate: DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledStartDate, '')),
    workOrderScheduledEndDate: DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledEndDate, '')),
    accountId: workOrderLog.accountId,
    workPlace: workOrderLog.workPlace,
    detailedWorkPlace: workOrderLog.detailedWorkPlace,
    message: workOrderLog.message,
    remark: workOrderLog.remark,
    workOrderCustomizedContent: workOrderLog.workOrderCustomizedContent,
    processLogList: [],
    deleteProcessList: [],
  });

  const [_updateCLWorkOrderList, setUpdateCLWorkOrderList] = useState([]);
  const [_deleteCLWorkOrderList, setDeleteCLWorkOrderList] = useState([]);
  
  const [_BLData, setBLData] = useState([]);
  const [_CPData, setCPData] = useState([]);

  /* ====================================================================== #3 */
  useEffect(() => {
    const processLogList = gsReducer.workOrderContent.processLogList.filter((thisItem) => thisItem.processTypeName === 'CL');
    setProcessLogList(processLogList);
    setBLData(gsReducer.workOrderContent.processLogList.filter((thisItem) => thisItem.processTypeName === 'BL'));
    setCPData(gsReducer.workOrderContent.processLogList.filter((thisItem) => thisItem.processTypeName === 'CP'));
    const updateWorkOrderList = processLogList.map((thisItem) => {
      return {
        existProcessOutElement: false,
        processTypeName: 'CL',
        processStatus: thisItem.processStatus,
        processLogId: thisItem.processLogId,
        processingCount: thisItem.processingCount,
        processCustomizedContent: JSON.parse(thisItem.processCustomizedContent),
        deleteTaskIds: [],
        taskLogList: [],

        processName: thisItem.processName,
        processCode: thisItem.processCode,
      };
    });
    setUpdateCLWorkOrderList(updateWorkOrderList);

    setOnload('loaded');
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const copyBarcode = (e) => {
    const thisBarcode = e.target.closest('td').dataset.barcode;
    console.log(thisBarcode);

    const textArea = document.createElement('textarea');
    document.body.appendChild(textArea);

    textArea.value = thisBarcode;
    textArea.select();
    textArea.setSelectionRange(0, 99999);
    document.execCommand('copy');
    textArea.setSelectionRange(0, 0);

    document.body.removeChild(textArea);
  };
  const handleCustomEvent = (e) => {
    const { dataset, name, value } = e.target;
    const processLogId = dataset.processlogid;
    const thisProcessIndex = _updateCLWorkOrderList.findIndex((thisItem) => thisItem.processLogId === Number(processLogId));
    if (thisProcessIndex === -1) return;

    const thisProcessCon = _updateCLWorkOrderList[thisProcessIndex];
    const copyList = [..._updateCLWorkOrderList];
    const newCustom = {...thisProcessCon.processCustomizedContent, [name]: value};
    const newProcessCon = {...thisProcessCon, processCustomizedContent: newCustom};
    copyList.splice(thisProcessIndex, 1, newProcessCon);
    setUpdateCLWorkOrderList(copyList);
  };
  const delCL = (e) => {
    const processLogId = e.target.closest('tr').dataset.processlogid;
    const newList = _updateCLWorkOrderList.filter((thisItem) => thisItem.processLogId !== Number(processLogId));
    setUpdateCLWorkOrderList(newList);
    setDeleteCLWorkOrderList([..._deleteCLWorkOrderList, Number(processLogId)]);
  };

  const actUpdate = async () => {
    const BodyToPut = { ..._formData };
    const setAllProcessLogList = [
      ..._updateCLWorkOrderList.map((thisItem) => {
        return {...thisItem, processCustomizedContent: JSON.stringify(thisItem.processCustomizedContent)};
      }),
      ..._BLData, ..._CPData
    ];
    BodyToPut.processLogList = setAllProcessLogList;
    BodyToPut.deleteProcessLogList = _deleteCLWorkOrderList;
    console.log('BodyToPut : ', BodyToPut);

    await workOrderLogApi.updateWorkOrderLog(BodyToPut.workOrderLogId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.updateWorkOrderLog : ', response);
      alert('작업지시가 수정되었습니다.');
      props.close();
    })
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          {_showUpdateList !== true ? (
            <ModalSection type="list" style={{ zoom: '100%' }}>
              <ModalHeader>
                <ModalTitle>체크리스트</ModalTitle>
                <UpdateButton
                  className="formButton"
                  onClick={() => {
                    setUpdateCLWorkOrderList([]);
                    setDeleteCLWorkOrderList([]);

                    const updateCLWorkOrderList = _processLogList.map((thisItem) => {
                      return {
                        existProcessOutElement: false,
                        processTypeName: 'CL',
                        processStatus: thisItem.processStatus,
                        processLogId: thisItem.processLogId,
                        processingCount: thisItem.processingCount,
                        processCustomizedContent: JSON.parse(thisItem.processCustomizedContent),
                        deleteTaskIds: [],
                        taskLogList: [],

                        processName: thisItem.processName,
                      };
                    });
                    setUpdateCLWorkOrderList(updateCLWorkOrderList);

                    setShowUpdateList(true);
                  }}
                >
                  리스트 수정
                </UpdateButton>
                <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
              </ModalHeader>

              <ModalMain>
                <TableSection content={
                  <table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '65px', width: '65px' }}>순번</th>
                        <th>부재번호</th>
                        <th>규격</th>
                        <th>길이</th>
                        <th>수량</th>
                        <th>종류</th>
                        <th>개별중량</th>
                        <th>비고</th>
                        <th style={{ minWidth: '100px', width: '100px' }}>바코드</th>
                      </tr>
                    </thead>

                    <tbody>
                      {_processLogList.map((thisItem, index) => {
                        const processCustomizedContent = JSON.parse(thisItem.processCustomizedContent);
                        const thisNum = (index + 1).toString().padStart(2, '0');
                        const thisBarcode = `WorkOrder-${_formData.workOrderLogId}-${thisItem.processLogId}-${processCustomizedContent.asmbly}`;

                        return (
                          <tr key={index + '_CLRow'}>
                            <td style={{ minWidth: '65px', width: '65px' }} data-col="Num">{thisNum}</td>
                            <td data-col="asmbly" data-colname="부재번호">{processCustomizedContent.asmbly}</td>
                            <td data-col="profile" data-colname="규격">{processCustomizedContent.profile}</td>
                            <td data-col="length" data-colname="길이">{processCustomizedContent.length}</td>
                            <td data-col="quantity" data-colname="수량">{processCustomizedContent.quantity}</td>
                            <td data-col="part" data-colname="종류">{processCustomizedContent.part}</td>
                            <td data-col="weight" data-colname="개별중량">{processCustomizedContent.weight}</td>
                            <td data-col="name" data-colname="비고">{processCustomizedContent.name}</td>
                            <td style={{ minWidth: '100px', width: '100px' }} data-col="바코드" data-barcode={thisBarcode}>
                              <QRCode value={thisBarcode} onClick={copyBarcode} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                }
                />
              </ModalMain>
            </ModalSection>
          ) : (
            <ModalSection type="update" style={{ zoom: '100%' }}>
              <ModalHeader>
                <ModalTitle>체크리스트 수정</ModalTitle>
                <UpdateButton className="formButton" onClick={() => {setShowUpdateList(false);}}>리스트 보기</UpdateButton>
                <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
              </ModalHeader>

              <ModalMain>
                <TableSection content={
                  <table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '65px', width: '65px' }}>순번</th>
                        <th>부재번호</th>
                        <th>규격</th>
                        <th>길이</th>
                        <th>수량</th>
                        <th>종류</th>
                        <th>개별중량</th>
                        <th>비고</th>
                        <th style={{ minWidth: '50px', width: '50px' }}></th>
                      </tr>
                    </thead>

                    <tbody>
                      {_updateCLWorkOrderList.map((thisItem, index) => {
                        const processCustomizedContent = thisItem.processCustomizedContent;
                        const thisNum = (index + 1).toString().padStart(2, '0');

                        return (
                          <tr key={index + '_CLRow'} data-processlogid={thisItem.processLogId}>
                            <td style={{ minWidth: '65px', width: '65px' }} data-col="Num">{thisNum}</td>
                            <td data-col="asmbly" data-colname="부재번호">
                              <input
                                type="text"
                                name="asmbly"
                                data-processlogid={thisItem.processLogId}
                                value={processCustomizedContent.asmbly}
                                onInput={handleCustomEvent}
                              />
                            </td>
                            <td data-col="profile" data-colname="규격">
                              <input
                                type="text"
                                name="profile"
                                data-processlogid={thisItem.processLogId}
                                value={processCustomizedContent.profile}
                                onInput={handleCustomEvent}
                              />
                            </td>
                            <td data-col="length" data-colname="길이">
                              <input
                                type="text"
                                name="length"
                                data-processlogid={thisItem.processLogId}
                                value={processCustomizedContent.length}
                                onInput={handleCustomEvent}
                              />
                            </td>
                            <td data-col="quantity" data-colname="수량">
                              <input
                                type="text"
                                name="quantity"
                                data-processlogid={thisItem.processLogId}
                                value={processCustomizedContent.quantity}
                                onInput={handleCustomEvent}
                              />
                            </td>
                            <td data-col="part" data-colname="종류">
                              <input
                                type="text"
                                name="part"
                                data-processlogid={thisItem.processLogId}
                                value={processCustomizedContent.part}
                                onInput={handleCustomEvent}
                              />
                            </td>
                            <td data-col="weight" data-colname="개별중량">
                              <input
                                type="text"
                                name="weight"
                                data-processlogid={thisItem.processLogId}
                                value={processCustomizedContent.weight}
                                onInput={handleCustomEvent}
                              />
                            </td>
                            <td data-col="name" data-colname="비고">
                              <input
                                type="text"
                                name="name"
                                data-processlogid={thisItem.processLogId}
                                value={processCustomizedContent.name}
                                onInput={handleCustomEvent}
                              />
                            </td>
                            <td style={{ minWidth: '50px', width: '50px' }} data-processlogid={thisItem.processLogId}>
                              <DelButton onClick={delCL}><CloseButtonIcon /></DelButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                }
                />
              </ModalMain>

              <ModalFooter>
                <ModalButton onClick={actUpdate}>저장</ModalButton>
              </ModalFooter>
            </ModalSection>
          )}
        </ModalBg>
      )}
    </>
  );
};

export default GSCLWorkList;
