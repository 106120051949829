import styled from "styled-components";

// 스타일 컴포넌트 만들기
// 공통되는 스타일 옮기기
// main > section

export const ShipmentFormComponent = styled.section`

  & .TableSection {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    width: 100%;

    max-height: 350px;
    overflow: auto;

    &::-webkit-scrollbar-thumb {background-color: var(--ThirdBlue);}

    & > table td {
      vertical-align: middle;
      textarea {display: block; height: 40px; resize: none;}
    }
  }

  & .DelButton {
    height: 15px;
    margin: 0px auto;
    width: 15px;

    div {background-color: var(--MainRed); cursor: pointer; height: 15px; width: 15px;}
  }

  & .DateCheckBox {
    align-items: center;
    column-gap: 10px;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100px;
    input {margin: unset;}
  }

`;


export const AccountTable = styled.table`
  background-color: var(--white);
  border-collapse: collapse;
  font-size: 0.8em;
  table-layout: fixed;
  width: 100%;

  td, th {border: 1px solid var(--gray-200); box-sizing: border-box; padding: 10px;}
  th {
    background-color: var(--MainNavy);
    color: var(--white);

    &.account {
      background-color: var(--MainBlue);
      cursor: pointer;
      &:hover {background-color: var(--SeconBlue);}
    }
  }
  th:first-child {min-width: 150px; width: 150px;}
`;