import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  mallElementActions_setClickedCategory,
  mallElementActions_setClickedLevel1Category,
  mallElementActions_setClickedLevel2Category,
  mallElementActions_setClickedLevel3Category,
} from 'store/modules/actions/mall/mallElementActions';

import {
  MallCategoryLevel1,
  MallCategoryLevel2,
  MallCategoryLevel3,
} from 'components/mallCategory/MallCategory';
import IconRightArrow from 'components/icons/IconRightArrow';

/* ~카테고리3 */
const MallCategories = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { mallElementReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const { categoryType } = props;

  /* ====================================================================== #3 */
  useEffect(() => {
    return () => {};
  }, [mallElementReducer.clickedCategory]);

  /* ====================================================================== #4 */
  const actClickCategory = (category) => {
    switch (categoryType) {
      case 'mallElement':
        return dispatch(mallElementActions_setClickedCategory(category));
      default:
        return;
    }
  };

  /* ====================================================================== #5 */
  /* ====================================================================== #6 */

  return (
    <Fragment>
      <MallCategoryLevel1
        mallCategoryType={categoryType}
        clickedLevel1Category={(() => {
          switch (categoryType) {
            case 'mallElement':
              return mallElementReducer.clickedLevel1Category;
            default:
              return {};
          }
        })()}
        setClickedCategory={(category) => {
          actClickCategory(category);
        }}
        setClickedLevel1Category={(category) => {
          switch (categoryType) {
            case 'mallElement':
              dispatch(mallElementActions_setClickedLevel3Category({}));
              dispatch(mallElementActions_setClickedLevel2Category({}));
              return dispatch(
                mallElementActions_setClickedLevel1Category(category),
              );
            default:
              return;
          }
        }}
      ></MallCategoryLevel1>

      <IconRightArrow />

      <MallCategoryLevel2
        mallCategoryType={categoryType}
        clickedLevel1Category={(() => {
          switch (categoryType) {
            case 'mallElement':
              return mallElementReducer.clickedLevel1Category;
            default:
              return {};
          }
        })()}
        clickedLevel2Category={(() => {
          switch (categoryType) {
            case 'mallElement':
              return mallElementReducer.clickedLevel2Category;
            default:
              return {};
          }
        })()}
        setClickedCategory={(category) => {
          actClickCategory(category);
        }}
        setClickedLevel2Category={(category) => {
          switch (categoryType) {
            case 'mallElement':
              dispatch(mallElementActions_setClickedLevel3Category({}));
              return dispatch(
                mallElementActions_setClickedLevel2Category(category),
              );
            default:
              return;
          }
        }}
      ></MallCategoryLevel2>

      <IconRightArrow />

      <MallCategoryLevel3
        mallCategoryType={categoryType}
        clickedLevel2Category={(() => {
          switch (categoryType) {
            case 'mallElement':
              return mallElementReducer.clickedLevel2Category;
            default:
              return {};
          }
        })()}
        clickedLevel3Category={(() => {
          switch (categoryType) {
            case 'mallElement':
              return mallElementReducer.clickedLevel3Category;
            default:
              return {};
          }
        })()}
        setClickedCategory={(category) => {
          actClickCategory(category);
        }}
        setClickedLevel3Category={(category) => {
          switch (categoryType) {
            case 'mallElement':
              return dispatch(
                mallElementActions_setClickedLevel3Category(category),
              );
            default:
              return;
          }
        }}
      ></MallCategoryLevel3>
    </Fragment>
  );
};

export default MallCategories;
