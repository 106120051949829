import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { operatingActions_setProcessTracking } from 'store/modules/actions/operating/operatingActions';

import { companyApi } from 'api/apis/companyApi';
import { workOrderLog } from 'api/apis/platform/operating/workOrderLog';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { IoSearch } from "react-icons/io5";
import { OperatingProcessTrackingComponent, ProcessLogComponent, WorkOrderStatusComponent } from './OperatingProcessTracking.style';
import OperatingPagination from 'platform/operating/components/OperatingPagination';

const OperatingProcessTracking = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const operatingId = location.pathname.split('/platform/operating')[0];
  const operatingPath = operatingId + '/platform/operating';

  const { operatingReducer } = useSelector(state => state);
  const { processTracking } = operatingReducer;

  const [_companyList, setCompanyList] = useState([]);
  const [_workOrderLogs, setWorkOrderLogs] = useState([]);
  const [_searchData, setSearchData] = useState({});

  const statusList = [
    { key: 'wait', title: '대기' },
    { key: 'work', title: '작업중' },
    { key: 'pause', title: '일시정지' },
    { key: 'stop', title: '중단' },
    { key: 'end', title: '완료' },
    { key: 'cancel', title: '취소' },
  ];

  useEffect(() => {
    getClusterCompanyList();

    return () => { }
  }, []);
  useEffect(() => {
    getWorkOrderLogs(processTracking.pageNumber - 1);

    return () => { }
  }, [processTracking.pageNumber]);
  useEffect(() => { }, [_workOrderLogs, _searchData]);

  const getClusterCompanyList = async () => { // 소공인 리스트 불러오기
    const id = Number(operatingId.replace('/', ''));
    const body = { clusterId: !isNaN(id) ? id : '' };

    await companyApi.searchCompanies('', body).then(response => {
      if (response === undefined) return;
      setCompanyList(() => { return response.data });
    })
  };
  const getWorkOrderLogs = async (page) => { // 작업지시 리스트 불러오기
    const id = Number(operatingId.replace('/', ''));
    const paging = `?page=${page}&size=15&sort=id,DESC`;
    const body = {
      clusterId: !isNaN(id) ? id : '', // 필수값
    };
    Object.keys(processTracking.searchData).forEach((searchKey) => { // 검색 조건
      if (checkEmptyNull(processTracking.searchData[searchKey], false)) {
        if (searchKey === 'companyId') {
          body[searchKey] = Number(processTracking.searchData[searchKey]);
        } else if (searchKey === 'workOrderStatus') {
          body[searchKey] = [processTracking.searchData[searchKey]];
        } else {
          body[searchKey] = processTracking.searchData[searchKey];
        }
      }
    })

    await workOrderLog.search(paging, body).then(response => {
      if (response === undefined) return;
      setWorkOrderLogs(() => { return response.data.data.content });
      dispatch(operatingActions_setProcessTracking({ ...processTracking, totalSize: response.data.data.totalElements }));
    })
  };

  const handleSearchData = (e) => { // 검색바 입력 이벤트
    const { name, value } = e.target;
    setSearchData((prev) => { return { ...prev, [name]: value } });
    dispatch(operatingActions_setProcessTracking({
      ...processTracking,
      searchData: { ...processTracking.searchData, [name]: value }
    }));
  }
  const actSearch = () => { // 검색
    dispatch(operatingActions_setProcessTracking({ ...processTracking, pageNumber: 1 }));
    getWorkOrderLogs(0);
  }

  return (
    <OperatingProcessTrackingComponent>
      <header>
        <h1>공정 트래킹</h1>
      </header>
      <nav>
        <div className='nav-bar'>
          <p>소공인</p>
          <select
            name='companyId'
            value={processTracking.searchData?.companyId || ''}
            onChange={handleSearchData}
          >
            <option value={''}>소공인 전체</option>
            {checkNullArray(_companyList, []).map((company, index) => {
              return (<option key={index + '_company'} value={company.companyId}>{company.companyName}</option>);
            })}
          </select>
        </div>
        <div className='nav-bar'>
          <p>작업상태</p>
          <select
            name='workOrderStatus'
            value={processTracking.searchData?.workOrderStatus || ''}
            onChange={handleSearchData}
          >
            <option value={''}>작업상태 선택</option>
            {checkNullArray(statusList, []).map((status, index) => {
              return (<option key={index + '_status'} value={status.key}>{status.title}</option>);
            })}
          </select>
        </div>
        <div className='nav-bar'>
          <p>작업이름</p>
          <input
            type='text'
            name='workOrderName'
            placeholder='검색어를 입력해주세요.'
            value={processTracking.searchData?.workOrderName || ''}
            onInput={handleSearchData}
            onKeyDown={(e) => {
              if (e.key === 'Enter') actSearch();
            }}
          />
        </div>
        <button onClick={actSearch}><IoSearch /></button>
      </nav>
      <div>
        <table>
          <colgroup>
            <col width={''} /><col width={'120px'} /><col width={''} /><col width={'70%'} />
          </colgroup>
          <thead>
            <tr>
              <th>소공인</th><th>작업상태</th><th>작업이름</th><th>공정</th>
            </tr>
          </thead>
          <tbody>
            {!checkNullArray(_workOrderLogs, false) ? (<tr><td colSpan={4}>데이터가 없습니다.</td></tr>) :
              checkNullArray(_workOrderLogs, []).map((workOrderLog, index) => {
                const status = statusList.find(item => item.key === workOrderLog.workOrderStatus);
                const processLogList = workOrderLog.processLogList;
                return (<>
                  <tr key={index + '+tr'}>
                    <td>{workOrderLog.companyName}</td>
                    <td className='cell-status'>
                      <div>
                        <WorkOrderStatusComponent className={workOrderLog.workOrderStatus} />
                        <p>{status.title}</p>
                      </div>
                    </td>
                    <td>{workOrderLog.workOrderName || '-'}</td>
                    {!checkNullArray(processLogList, false) ? (<td>공정이 없습니다.</td>) : (
                      <td className='cell-processLog'>
                        <div>
                          {checkNullArray(processLogList, []).map((processLog, processLogIndex) => {
                            return (
                              <ProcessLogComponent
                                key={processLogIndex + '_processLog'}
                                className={processLog.processStatus}
                              >{(processLogIndex + 1) + '. '}{processLog.processName || '-'}</ProcessLogComponent>
                            );
                          })}
                        </div>
                      </td>
                    )}
                  </tr>
                </>);
              })}
          </tbody>
        </table>
      </div>
      <OperatingPagination
        page={processTracking.pageNumber}
        count={processTracking.totalSize}
        size={15}
        pageEvent={(page) => { dispatch(operatingActions_setProcessTracking({ ...processTracking, pageNumber: page })); }}
      />
    </OperatingProcessTrackingComponent>
  );
};

export default OperatingProcessTracking;