import React from 'react';

import { DateFormat } from 'components/format/DateFormat';

const Information = ({ modalType, dataKeys, body, setBody }) => {
  return (
    <div className='section-form'>
      <div className='form-content term'>
        <h4>거래 유형</h4>
        <div>
          <select
            disabled={modalType.endsWith('delete')}
            value={body.type || ''}
            onChange={(e) => {
              setBody((prev) => {
                return { ...prev, type: e.target.value };
              });
            }}
          >
            {[
              { title: '유형 선택', value: '' },
              { title: '구매', value: 'buy' },
              { title: '판매', value: 'sell' },
            ].map((option) => {
              return (<option key={option.value} value={option.value}>{option.title}</option>);
            })}
          </select>
        </div>
      </div>

      {dataKeys.map((dataKey, index) => {
        return (
          <div key={index + '_form-content'} className='form-content term'>
            <h4>{dataKey.title}</h4>
            {dataKey.key === 'targetDate' ? ( // 마감일
              <div>
                <input
                  type='date'
                  disabled={modalType.endsWith('delete')}
                  value={body[dataKey.key] || ''}
                  onInput={(e) => {
                    setBody((prev) => {
                      return { ...prev, [dataKey.key]: DateFormat(e.target.value) }
                    })
                  }}
                />
              </div>
            ) : dataKey.key === 'price' ? (<>
              <input
                type='number'
                placeholder={`숫자만 입력할 수 있습니다.`}
                disabled={modalType.endsWith('delete')}
                pattern="\d*"
                min="0"
                value={body[dataKey.key] || ''}
                onInvalid={(e) => { e.target.setCustomValidity('숫자만 입력할 수 있습니다.'); }}
                onInput={(e) => {
                  e.target.setCustomValidity('');
                  setBody((prev) => {
                    return { ...prev, [dataKey.key]: e.target.value }
                  })
                }}
              />
            </>) : dataKey.key === 'detail' ? (<>
              <textarea
                placeholder={`${dataKey.title}을(를) 입력해주세요.`}
                disabled={modalType.endsWith('delete')}
                defaultValue={body[dataKey.key] || ''}
                onInput={(e) => {
                  setBody((prev) => {
                    return { ...prev, [dataKey.key]: e.target.value }
                  })
                }}
              />
            </>) : (<>
              <input
                type='text'
                placeholder={`${dataKey.title}을(를) 입력해주세요.`}
                disabled={modalType.endsWith('delete')}
                value={body[dataKey.key] || ''}
                onInput={(e) => {
                  setBody((prev) => {
                    return { ...prev, [dataKey.key]: e.target.value }
                  })
                }}
              />
            </>)}
          </div>
        );
      })}
    </div>
  );
};

export default Information;