import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import moment from 'moment';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { uriReducer_setUpdateContent } from 'store/modules/actions/company/UriActions';

import { calendarApi } from 'api/apis/calendarApi';

import { DateFormat } from 'components/format/DateFormat';
import { checkEmptyNull } from 'components/checkValues/checkValues';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

import CreateUriCalendarPlanModal from 'pages/calendar/custom/company/uri/modal/crud/CreateUriCalendarPlanModal';
import UpdateUriCalendarPlanModal from 'pages/calendar/custom/company/uri/modal/crud/UpdateUriCalendarPlanModal';

const CalendarSection = styled.main`
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 66px calc(100% - 66px);
`;

const SchedulerSection = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: grid;  
  height: 100%;
  justify-content: center;
  overflow: hidden;
  padding: 20px 40px;
  width: 100%;

  &>div {
    box-sizing: border-box;
    overflow: scroll;
    width: 95svw;
  }

  .fc .fc-toolbar.fc-header-toolbar {box-sizing: border-box; padding: 16px 10px; margin: unset;}
  .fc .fc-button {padding: 8px 16px;}
  .fc .fc-button, .fc .fc-button-group, .fc .fc-button:disabled {font-size: 0.9em;}
  .fc .fc-view-harness-active > .fc-view,
  .gBRwOh .fc .fc-toolbar.fc-header-toolbar,
  .fc .fc-media-screen .fc-direction-ltr .fc-theme-standard {background-color: var(--white);}
  .fc-today-button:disabled {opacity: unset;}
  .fc-daygrid-block-event .fc-event-time,
  .fc-daygrid-block-event .fc-event-title {
    overflow: unset;
    max-width: 100%;
    white-space: pre-line;
    word-break: break-all;
  }
  .fc-daygrid-dot-event {align-items: flex-start;
    .fc-daygrid-event-dot {transform: translateY(7px);}
  }
  .fc-daygrid-dot-event .fc-event-title {
    font-weight: 400;
    line-height: 17px;
    overflow: unset;
    max-width: 89%;
    white-space: pre-line;
    word-break: break-all;
  }
`;

const UriCalendarPlan = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userReducer, workReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const fullCalendar = useRef();

  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_currentCalenderList, setCurrentCalendarList] = useState([]);
  const [_calenderDataList, setCalendarDataList] = useState([]);
  const [_dataUpdateStatus, setDataUpdateStatus] = useState(false);

  const [_startDate, setStartDate] = useState();
  const [_endDate, setEndDate] = useState();
  const [_searchDate, setSearchDate] = useState();
  const [_searchText, setSearchText] = useState('');

  const [_createWorkPlanStatus, setCreateWorkPlanStatus] = useState(false);
  const [_updateWorkPlanStatus, setUpdateWorkPlanStatus] = useState(false);
  const [_updateContent, setUpdateContent] = useState(false);

  const [_updateButtonStatus, setUpdateButtonStatus] = useState(false);
  const [_deleteButtonStatus, setDeleteButtonStatus] = useState(false);

  /* ========================================================================= #3*/
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('406') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => { }, [_currentCalenderList]);
  useEffect(() => { }, [workReducer.connectStatus]);

  /* ========================================================================= #4*/
  const getCalendarList = async (fetchInfo, successCallback) => {
    const startDate = moment(fetchInfo.startStr).format('YYYY-MM-DD');
    const endDate = moment(fetchInfo.endStr).format('YYYY-MM-DD');
    if (startDate === _startDate && endDate === _endDate && !_dataUpdateStatus) {
      return successCallback(_calenderDataList);
    }
    setStartDate(() => { return startDate; });
    setEndDate(() => { return endDate; });
    setDataUpdateStatus(() => { return false; });
    const BodyToPost = {companyId: userReducer.company.companyId};
    BodyToPost.startDate = startDate;
    BodyToPost.endDate = endDate;
    console.log('getCalendarList - BodyToPost : ', BodyToPost);

    await calendarApi.searchCalendar(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('calendarApi.searchCalendar : ', response);

      const matchArray = [];
      const returnMapData = response.data.map((thisItem) => {
        const noteData = JSON.parse(thisItem.note);

        let typeText = '';
        switch (noteData.type) {
          case 'Work': typeText = '작업'; break;
          case 'Glass': typeText = '유리'; break;
          case 'Out': typeText = '출고'; break;
          default: break;
        }

        let typeColor = '';
        switch (noteData.type) {
          case 'Work': typeColor = 'var(--WorkingColor)'; break;
          case 'Glass': typeColor = 'var(--MainBlue)'; break;
          case 'Out': typeColor = 'var(--MainRed)'; break;
          default: break;
        }

        const returnData = {};
        returnData['id'] = thisItem.calenderId;
        returnData['groupId'] = noteData.note3;
        returnData['title'] = `
${typeText} 

${noteData.note1} 

${noteData.note2}

        `;
        returnData['start'] = DateFormat(thisItem.calenderDate);
        returnData['color'] = typeColor;

        if (checkEmptyNull(_searchText, false)) {
          if (noteData.note1.includes(_searchText) || noteData.note2.includes(_searchText)) {
            matchArray.push({
              color: 'rgba(52, 175, 56, 0.8)',
              display: 'background',
              start: DateFormat(thisItem.calenderDate),
            });
          }
        }

        return returnData;
      });

      setCurrentCalendarList(() => { return response.data });
      setCalendarDataList(() => { return [...returnMapData, ...matchArray] });
      successCallback([...returnMapData, ...matchArray]);
    });
  };

  /* ========================================================================= #5*/
  const actSearch = async () => {
    if (checkEmptyNull(_searchDate, false)) {
      if (checkEmptyNull(_searchText, false)) {
        setDataUpdateStatus(() => { return true });
      } else {
        const fullCalendarApi = fullCalendar.current.getApi();
        fullCalendarApi.gotoDate(_searchDate);
      }
    } else {
      if (checkEmptyNull(_searchText, false)) setDataUpdateStatus(() => { return true });
    }
  };
  const actReset = () => {
    setSearchDate(() => { return '' });
    setSearchText(() => { return '' });
    setDataUpdateStatus(() => { return true });
    const fullCalendarApi = fullCalendar.current.getApi();
    fullCalendarApi.gotoDate(moment().format('YYYY-MM-DD'));
  };

  /* 수정 */
  const actUpdate = async (info) => {
    const calenderId = info.event.id;
    const updateContent = _currentCalenderList.find((thisItem) => {
      if (thisItem.calenderId === Number(calenderId)) return true;
      else return false;
    });
    setUpdateContent(() => { return updateContent });
    dispatch(uriReducer_setUpdateContent(updateContent));
    setUpdateWorkPlanStatus(true);
  };

  /* 삭제 */
  const actDelete = async (info) => {
    const calenderId = info.event.id;
    const confirmText = `
${info.event.title}      
일정을 삭제하시겠습니까?
    `;
    if (window.confirm(confirmText)) {
      await calendarApi.deleteCalendar(calenderId).then((response) => {
        if (response === undefined) return;
        console.log('calendarApi.deleteCalendar : ', response);
        alert('일정을 삭제 했습니다.');
        setDataUpdateStatus(() => { return true });
      });
    }
  };

  const handleClickEvent = (info) => {
    if (_authority.indexOf('406-2') === -1) return alert('권한이 없습니다.');

    const workOrderLogId = info.event.groupId;
    const eventContent = _currentCalenderList.find((thisItem) => {
      if (thisItem.workOrderLogId === Number(workOrderLogId)) return true;
      else return false;
    });

    if (!checkEmptyNull(workOrderLogId, false)) return alert('연관 작업지시가 없습니다.');
    else {
      if (!checkEmptyNull(eventContent.workOrderLogId, false)) {
        return alert('연관 작업지시가 없습니다.');
      } else if (!checkEmptyNull(eventContent.workOrderLogId, false) && !checkEmptyNull(eventContent.note.note3, false)) {
        return alert('연관 작업지시가 없습니다.');
      } else {
        setTimeout(
          navigate({
            pathname: '/monitoring/company/uri/working',
            search: `?${createSearchParams({ prevPage: '/production/calendar/uri', workOrderLogId: eventContent.workOrderLogId })}`,
            replace: true,
          })
        , 1000);
      }
    }
  };

  /* ========================================================================= #6 */
  window.oncontextmenu = () => { return false };

  return (
    <Grid2Body contents={
      <>
        <CalendarSection className="Main">
          <NavBar
            title={<NavTitle menuCode={'406'} />}
            buttons={
              <>
                <div className="SearchSection">
                  <div className="ContentCBox">
                    <input
                      type={'date'}
                      className="SelectDate"
                      style={{ marginRight: '10px' }}
                      value={_searchDate}
                      onChange={(e) => {setSearchDate(() => { return moment(e.target.value).format('YYYY-MM-DD'); });}}
                    />

                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="">일정내용</option>
                      </select>
                      <input
                        className="SearchBar"
                        placeholder="Search..."
                        value={_searchText}
                        onInput={(e) => { setSearchText(() => { return e.target.value; }); }}
                      />
                    </div>
                  </div>

                  <button className='btn-search' onClick={actSearch}>검색</button>
                  <button className='btn-reset' onClick={actReset}>초기화</button>
                </div>
              </>
            }
            nav={''}
          />

          <SchedulerSection>
            <FullCalendar
              ref={fullCalendar}
              plugins={[dayGridPlugin]}
              dayHeaders={true}
              initialView="dayGridWeek"
              locale="ko" // 한글로 변경
              firstDay="1" // 시작 요일(월요일)
              buttonText={{week: '주별', today: '오늘'}}
              events={(fetchInfo, successCallback, failureCallback) => {getCalendarList(fetchInfo, successCallback, failureCallback);}} // 이벤트 데이터
              displayEventTime={false} // 이벤트 시간 표기 여부
              customButtons={{
                createWorkPlan: { text: '일정추가', click: function () { setCreateWorkPlanStatus(true); setUpdateButtonStatus(false); setDeleteButtonStatus(false); } },
                activeUpdate: { text: '수정 활성화', click: function () { setUpdateButtonStatus(true); setDeleteButtonStatus(false); } },
                disabledUpdate: { text: '수정 비활성화', click: function () { setUpdateButtonStatus(false); } },
                activeDelete: { text: '삭제 활성화', click: function () { setUpdateButtonStatus(false); setDeleteButtonStatus(true); } },
                desabledDelete: { text: '삭제 비활성화', click: function () { setDeleteButtonStatus(false); } },
              }}
              headerToolbar={{
                left: 'title',
                right: (() => {
                  if (_authority.indexOf('406-2') !== -1) {
                    if (_updateButtonStatus && _deleteButtonStatus) return 'createWorkPlan disabledUpdate desabledDelete prev,today,next';
                    else if (!_updateButtonStatus && _deleteButtonStatus) return 'createWorkPlan activeUpdate desabledDelete prev,today,next';
                    else if (_updateButtonStatus && !_deleteButtonStatus) return 'createWorkPlan disabledUpdate activeDelete prev,today,next';
                    else return 'createWorkPlan activeUpdate activeDelete prev,today,next';
                  } else {
                    return 'prev,today,next';
                  }
                })(),
              }}
              eventClick={(e) => {
                if (_updateButtonStatus) actUpdate(e);
                else if (_deleteButtonStatus) actDelete(e);
                else handleClickEvent(e);
              }} // 이벤트 클릭 이벤트
              eventDisplay="list-item" // 이벤트 표기
              eventBackgroundColor="var(--SeconYellow)" // 오늘 날짜 색상
              height="100%" // 캘린더 전체 높이
            />
          </SchedulerSection>

          {/* 일정 이벤트 */}
          {_createWorkPlanStatus === true ? (
            <CreateUriCalendarPlanModal
              open={_createWorkPlanStatus}
              close={(date) => {
                setCreateWorkPlanStatus(false);
                if (!checkEmptyNull(date, false) || typeof date !== 'string') return;
                const fullCalendarApi = fullCalendar.current.getApi();
                fullCalendarApi.gotoDate(date);
                setDataUpdateStatus(() => { return true });
              }}
            />
          ) : null}
          {_updateWorkPlanStatus === true ? (
            <UpdateUriCalendarPlanModal
              content={_updateContent}
              open={_updateWorkPlanStatus}
              close={(date) => {
                setUpdateWorkPlanStatus(false);
                if (!checkEmptyNull(date, false) || typeof date !== 'string') return;
                const fullCalendarApi = fullCalendar.current.getApi();
                fullCalendarApi.gotoDate(date);
                setDataUpdateStatus(() => { return true });
              }}
            />
          ) : null}
        </CalendarSection>
      </>
    }
    />
  );
};

export default UriCalendarPlan;
