const initialState = {
  workOrderList: [],

  workCase: '',
  workStatus: '',

  workContent: {},

  connectStatus: 'disconnect',
  currentMessage: {},

  workOrderType: 'all',
  workOrderStatus: 'waitNwork',
  startDate: '',
  endDate: '',
  searchData: {},
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,

  viewScale: 65,

  setWorker: false,
};
const resetState = {
  workOrderType: 'all',
  workOrderStatus: 'waitNwork',
  startDate: '',
  endDate: '',
  searchData: {},
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const workReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETWORKORDERLIST': return {...state, workOrderList: action.payload};

    case 'SETWORKCASE': return {...state, workCase: action.payload};
    case 'SETWORKSTATUS': return {...state, workStatus: action.payload};

    case 'SETWORKCONTENT': return {...state, workContent: action.payload};

    case 'SETCONNECTSTATUS': return {...state, connectStatus: action.payload};
    case 'SETCURRENTMESSAGE': return {...state, currentMessage: action.payload};

    case 'SETWORKINGWORKORDERTYPE': return {...state, workOrderType: action.payload};
    case 'SETWORKINGWORKORDERSTATUS': return {...state, workOrderStatus: action.payload};
    case 'SETWORKINGSTARTDATE': return {...state, startDate: action.payload};
    case 'SETWORKINGENDDATE': return {...state, endDate: action.payload};
    case 'SETWORKINGSEARCHDATA': return {...state, searchData: action.payload};
    case 'SETWORKINGSEARCHOPTION': return {...state, searchOption: action.payload};
    case 'SETWORKINGSEARCHTEXT': return {...state, searchText: action.payload};
    case 'SETWORKINGPAGENUMBER': return {...state, pageNumber: action.payload};
    case 'SETWORKINGTOTALSIZE': return {...state, totalSize: action.payload};
    case 'SETWORKINGISPUSHEDSEARCHBUTTON': return {...state, isPushedSearchButton: action.payload};

    case 'SETWORKINGVIEWSCALE': return {...state, viewScale: action.payload};

    case 'SETWORKINGWORKER': return {...state, setWorker: action.payload};

    case 'SETWORKINGRESET': return {...state, ...resetState};

    default: return state;
  }
}

export default workReducer;