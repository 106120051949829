import React from 'react';

const Agreement = () => {
  return (
    <>
      ① 개인정보란 생존하는 개인에 관한 정보로서 당해 정보에
      포함되어 있는 성명, 전화번호 등의 사항에 의해 개인을 식별할 수
      있는 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도
      다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)를
      말합니다.
      <br />
      <br />
      ② 디케이랩은 고객의 개인정보보호를 매우 중요시하며,
      『정보통신망이용촉진 및 정보보호에관한법률』상의 개인정
      보보호규정 및 정보통신부가 제정한 『개인정보보호지침』을
      준수하고 있습니다. 디케이랩은 개인정보취급방침을 통하여 고객이
      제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며
      개인정보보호를 위해 어떠한 조치가 취해 지고 있는지
      알려드립니다.
      <br />
      <br />
      ③ 디케이랩은 개인정보취급방침을 아이피스 첫 화면에서 동의를
      받고, 시스템 내에 공개함으로써 고객이 언제나 용이하게 볼 수
      있도록 조치하고 있습니다.
      <br />
      <br />
      <br />
      1. 개인정보의 수집범위
      <br />
      디케이랩의 아이피스를 이용하고자 할 경우 다음의 정보를
      입력해야 합니다.
      <br />
      ① 사용자 최초 접속 시 수집하는 개인정보의 범위
      <br />
      - 필수항목 : 성명, 휴대폰번호, 지메일 주소
      <br />
      <br />
      2. 개인정보 수집에 대한 동의
      <br />
      디케이랩은 고객께서 개인정보보호방침 또는 이용약관의 내용에
      대해 「동의한다」버튼을 클릭할 수 있는 절차를 마련하여,
      「동의한다」버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로
      봅니다.
      <br />
      <br />
      3. 개인정보의 수집목적 및 이용목적
      <br />
      ① 디케이랩은 사용자에게 최대한으로 최적화되고 맞춤화된
      서비스를 제공하기 위하여 다음과 같은 목적으로 개인정보를
      수집하고 있습니다.
      <br />
      - 성명 : 사용자 본인 식별 절차에 이용
      <br />
      - 휴대폰번호 : 시스템 사용 중 이벤트 발생 시 알림을 위한
      시스템 링크 전달
      <br />
      - 지메일주소 : Google에 등록된 아이디는 Google Suite를 통해
      아이피스 시스템 연계에 사용, 사용자 본인 식별에 사용
      <br />
      <br />
      ② 이 외의 민감한 개인정보(주민등록번호, 주소, 성별, 인종 및
      민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록,
      건강상태 및 성생활 등)는 수집하지 않습니다.
      <br />
      <br />
      4. 목적 외 사용 및 제3자에 대한 제공 및 공유
      <br />
      ① 디케이랩은 고객의 개인정보를 「개인정보의 수집목적 및
      이용목적」에서 고지한 범위내에서 사용하며, 동 범위를 초과하여
      이용하거나 타인 또는 타기업, 기관에 제공하지 않습니다. 단,
      다음의 경우는 주의를 기울여 개인정보를 이용 및 제공할
      것입니다.
      <br />
      - 제휴관계 : 보다 나은 서비스 제공을 위하여 고객의 개인정보를
      제휴사에게 제공하거나 또는 제휴사와 공유할 수 있습니다.
      개인정보를 제공하거나 공유할 경우에는 사전에 고객께 제휴사가
      누구인지, 제공 또는 공유되는 개인정보항 목이 무엇인지, 왜
      그러한 개인정보가 제공되거나 공유되어야 하는지, 그리고
      언제까지 어떻게 보호, 관리되는지에 대 해 개별적으로 전자우편
      및 서면을 통해 고지하여 동의를 구하는 절차를 거치게 되며,
      고객께서 동의하지 않는 경우에 는 제휴사에게 제공하거나
      제휴사와 공유하지 않습니다. 제휴관계에 변화가 있거나
      제휴관계가 종결될 때도 같은 절차 에 의하여 고지하거나 동의를
      구합니다.
      <br />
      - 위탁 처리 : 원활한 업무 처리를 위해 이용자의 개인정보를 위탁
      처리할 경우 반드시 사전에 위탁처리 업체명과 위탁 처리되는
      개인정보의 범위, 업무위탁 목적, 위탁 처리되는 과정, 위탁관계
      유지기간 등에 대해 상세하게 고지합니다.
      <br />
      - 매각,인수합병 등 : 서비스제공자의 권리와 의무가 완전
      승계,이전되는 경우 반드시 사전에 정당한 사유와 절차에 대해
      상세하게 고지할 것이며 이용자의 개인정보에 대한 동의 철회의
      선택권을 부여합니다.
      <br />
      <br />
      ② 고지 및 동의방법은 온라인 홈페이지 초기화면의 공지사항을
      통해 최소 10일 이전부터 고지함과 동시에 이메일 등을 이용하여
      1회 이상 개별적으로 고지합니다.
      <br />
      <br />
      ③ 다음은 예외로 합니다.
      <br />
      - 관계법령에 의하여 수사상의 목적으로 관계기관으로부터의
      요구가 있을 경우
      <br />
      - 통계작성, 학술연구나 시장조사를 위하여 특정 개인을 식별할 수
      없는 형태로 광고주, 협력사나 연구단체 등에 제공하는 경우
      <br />
      - 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우
      <br />
      - 그러나 예외 사항에서도 관계법령에 의하거나 수사기관의 요청에
      의해 정보를 제공한 경우에는 이를 당사자에게 고지하는 것을
      원칙으로 운영하고 있습니다. 법률상의 근거에 의해 부득이하게
      고지를 하지 못할 수도 있습니다. 본래의 수집목적 및 이용목적에
      반하여 무분별하게 정보가 제공되지 않도록 최대한
      노력하겠습니다.
      <br />
      <br />
      ④ 개인정보 자동수집 장치 설치, 운영 및 거부 사항
      <br />
      - 디케이랩은 웹기반 서비스 제공을 위하여 쿠키를 이용하는
      경우가 있습니다. 쿠키는 보다 빠르고 편리한 이용을 지원하기위해
      사용됩니다.
      <br />
      <br />
      [쿠키란?] 회사의 웹사이트를 운영하는데 이용되는 서버가
      이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자
      컴퓨터에 저장됩니다.
      <br />
      [쿠키 사용목적] 이용자의 취향과 관심분야를 파악하여 개인형
      맞춤화 된 서비스를 제공하기 위해 사용합니다. 이용자가
      웹사이트에 방문할 경우 웹 사이트 서버는 이용자의 디바이스에
      저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고
      최적화된 서비스를 제공하게 됩니다.
      <br />
      [쿠키 수집 거부] 쿠키에는 개인을 식별하는 이름, 전화번호 등을
      저장하지 않으며, 이용자는 쿠키 설치에 대한 선택권을 가지고
      있습니다. 따라서 이용자는 웹 브라우저에서 옵션을 설정하므로
      모든 쿠키를 허용하거나, 저장될 때마다 확인을 거치거나, 모든
      쿠키의 저장을 거부할 수 있습니다. 단, 이용자가 쿠키 설치를
      거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
      <br />
      [설정 방법의 예]
      <br />
      • Internet Explorer 경우 웹 브라우저 상단의 도구 &gt; 인터넷
      옵션 &gt; 개인정보 &gt; 설정
      <br />
      • Chrome 경우 웹 브라우저 우측의 설정 메뉴 &gt; 화면 하단의
      고급 설정 표시 &gt; 개인정보의 콘텐츠 설정 버튼 &gt; 쿠키
      <br />
      <br />
      5. 개인정보의 열람 및 정정
      <br />
      ① 사용자는 언제든지 등록되어 있는 사용자의 개인정보를 아이피스
      시스템의 사용자 관리 메뉴를 통해 열람하거나 정정할 수
      있습니다.
      <br />
      <br />
      ② 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정
      처리결과를 제3자에게 지체없이 통지하여 정정하도록
      조치하겠습니다.
      <br />
      <br />
      6. 개인정보 수집, 이용, 제공에 대한 동의철회
      <br />
      ① 아이피스 사용자가 퇴사하는 경우, 아이피스를 사용할 수 없으며
      이를 위해서는 시스템 관리자가 사용자 관리 화면에서 사용자를
      삭제함으로 『동의철회(사용자탈퇴)』로 간주하고 모든 개인정보를
      삭제합니다.
      <br />
      <br />
      7. 개인정보의 보유기간 및 이용기간
      <br />
      ① 사용자의 개인정보는 다음과 같이 개인정보의 수집목적 또는
      제공받은 목적이 달성되면 파기됩니다. 단, 상법 등 관련법령의
      규정에 의하여 다음과 같이 거래 관련 권리 의무 관계의 확인 등을
      이유로 일정기간 보유하여야 할 필요가 있을 경우에는 일정기간
      보유합니다.
      <br />
      - 계약 또는 청약철회 등에 관한 기록 : 5년
      <br />
      - 대금결제 및 재화등의 공급에 관한 기록 : 5년
      <br />
      - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
      <br />
      <br />
      8. 개인정보의 파기절차 및 방법
      <br />
      디케이랩은 원칙적으로 개인정보 수집 및 이용목적이 달성된
      후에는 해당 정보를 지체없이 파기합니다.
      <br />
      ① 파기절차
      <br />
      사용자의 개인정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의
      경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한
      정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된
      후 파기되어집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한
      경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지
      않습니다.
      <br />
      <br />
      ② 파기방법
      <br />
      종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
      파기하고 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수
      없는 기술적 방법을 사용하여 삭제합니다.
      <br />
      <br />
      9. 개인정보보호를 위한 기술 및 관리적 대책
      <br />
      ① 기술적 대책
      <br />
      디케이랩은 사용자의 개인정보를 취급함에 있어 개인정보가 분실,
      도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여
      다음과 같은 기술적 대책을 강구하고 있습니다.
      <br />
      - 사용자의 개인정보는 비밀번호에 의해 보호되며 파일 및
      전송데이터를 암호화하거나 파일 잠금기능(Lock)을 사용 하여
      중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.
      <br />
      - 디케이랩은 백신프로그램을 이용하여 컴퓨터바이러스에 의한
      피해를 방지하기 위한 조치를 취하고 있습니다. 백신 프로그램은
      주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이
      나오는 즉시 이를 제공함으로써 개인 정보가 침해되는 것을
      방지하고 있습니다.
      <br />
      - 디케이랩은 암호알고리즘을 이용하여 네트워크 상의 개인정보를
      안전하게 전송할 수 있는 보안장치(SSL 또는 SET) 를 채택하고
      있습니다.
      <br />
      <br />
      ② 관리적 대책
      <br />
      디케이랩은 사용자의 개인정보에 대한 접근권한을 최소한의
      인원으로 제한하고 있습니다. 그 최소한의 인원에 해당하는 자는
      다음과 같습니다.
      <br />
      · 사용자를 직접 상대로 하여 아이피스(시스템) 유지, 보수를
      수행하는 자
      <br />
      · 개인정보관리책임자 및 담당자 등 개인정보관리업무를 수행하는
      자<br />
      · 기타 업무상 개인정보의 취급이 불가피한 자<br />
      - 개인정보 관련 취급자의 업무 인수인계는 보안이 유지된
      상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보
      사고에 대한 책임을 명확화하고 있습니다.
      <br />
      - 개인정보와 일반 데이터를 혼합하여 보관하지 않고 별도로
      분리하여 보관하고 있습니다.
      <br />
      - 디케이랩은 이용자 개인의 실수나 기본적인 인터넷의 위험성
      때문에 일어나는 일들에 대해 책임을 지지 않습니다. 사용자
      본인의 개인정보를 보호하기 위해서 자신의 지메일 아이디와
      비밀번호를 적절하게 관리하고 여기에 대한 책임을 져야 합니다.
      <br />
      - 그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해
      개인정보의 상실, 유출, 변조, 훼손이 유발될 경우 디케이랩은
      즉각 고객에게 사실을 알리고 적절한 대책과 보상을 강구할
      것입니다.
      <br />
      <br />
      10. 사용자의 권리와 의무
      <br />
      ① 사용자의 개인정보를 최신의 상태로 정확하게 입력하여 불의의
      사고를 예방해 주시기 바랍니다. 사용자가 입력한 부정확한 정보로
      인해 발생하는 사고의 책임은 사용자 자신에게 있으며 타인 정보의
      도용 등 허위정보를 입력할 경우 사용 권한이 상실될 수 있습니다.
      <br />
      <br />
      ② 사용자는 개인정보를 보호받을 권리와 함께 스스로를 보호하고
      타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 비밀번호를
      포함한 사용자의 개인정보가 유출되지 않도록 조심하시고 타인의
      개인정보를 훼손하지 않도록 유의해 주십시오. 만약 이 같은
      책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는
      『정보통신망 이용촉진 및 정보보호 등에 관한 법률』등에 의해
      처벌받을 수 있습니다.
      <br />
      <br />
      ◆ 의견수렴 및 불만처리
      <br />
      ① 디케이랩은 사용자의 의견을 소중하게 생각하며, 사용자는
      의문사항으로부터 언제나 성실한 답변을 받을 권리가 있습니다.
      <br />
      <br />
      ② 디케이랩은 사용자와의 원활환 의사소통을 위해 민원처리센터를
      운영하고 있으며 연락처는 다음과 같습니다.
      <br />
      <br />
      ◆ 민원처리센터
      <br />
      - 전자우편 : mjsk@dklab.kr
      <br />
      - 전화번호 : 070-4354-0782
      <br />
      - 팩스번호 : 070-7543-0790
      <br />
      - 주 소 : 경기도 성남시 성남대로331번길 8, 19층 14호 디케이랩
      <br />
      <br />
      ③ 전화상담은 평일 오전 09:00 ~ 오후 05:30 주말 및 공휴일은
      홈페이지 게시판을 이용해주세요.
      <br />
      <br />
      ④ 전자우편이나 팩스 및 우편을 이용한 상담은 접수 후 24시간
      내에 성실하게 답변 드리겠습니다. 다만, 근무시간 이후 또는 주말
      및 공휴일에는 익일 처리하는 것을 원칙으로 합니다.
      <br />
      <br />
      ⑤ 기타 개인정보에 관한 상담이 필요한 경우에는
      개인정보침해신고센터, 정보보호마크 인증위원회, 대검찰청
      인터넷범죄수사센터, 경찰청 사이버범죄수사대 등으로 문의하실 수
      있습니다.
      <br />
      * 전자거래분쟁조정위원회
      <br />
      - 전화 : 118
      <br />
      - URL : http://www.ecmc.or.kr
      <br />
      * 개인정보침해신고센터
      <br />
      - 전화 : 1336
      <br />
      - URL : http://www.cyberprivacy.or.kr
      <br />
      * 정보보호마크 인증위원회
      <br />
      - 전화 : 02-580-0533
      <br />
      - URL : http://www.privacymark.or.kr
      <br />
      * 대검찰청 인터넷범죄수사센터
      <br />
      - 전화 : 02-3480-3600
      <br />
      - URL : http://icic.sppo.go.kr
      <br />
      * 경찰청 사이버범죄수사대
      <br />
      - 전화 : 182
      <br />
      - URL : http://www.police.go.kr/cybercenter
      <br />
      <br />
      11. 개인정보관리책임자
      <br />
      디케이랩은 사용자가 아이피스를 안전하게 이용할 수 있도록
      최선을 다하고 있습니다. 개인정보를 보호하는데 있어 고객께
      고지한 사항들에 반하는 사고가 발생할 시에 개인정보관리책임자가
      모든 책임을 집니다. 그러나 기술적인 보완조 치를 했음에도
      불구하고, 해킹 등 기본적인 네트워크상의 위험성에 의해 발생하는
      예기치 못한 사고로 인한 정보의 훼 손 및 방문자가 작성한
      게시물에 의한 각종 분쟁에 관해서는 책임이 없습니다. 고객의
      개인정보를 취급하는 책임자는 다음과 같으며 개인정보 관련
      문의사항에 신속하고 성실하게 답변해드리고 있습니다.
      <br />
      <br />
      개인정보관리책임자
      <br />
      성 명 : 고정석
      <br />
      직 위 : 대표
      <br />
      - 전자우편 : mjsk@dklab.kr
      <br />
      - 전화번호 : 070-4354-0782
      <br />
      - 팩스번호 : 070-7543-0790
      <br />
      <br />
      12. 고지의 의무
      <br />
      현 개인정보취급방침은 2007년 7월 27일에 제정되었으며 정부의
      정책 또는 보안기술의 변경에 따라 내용의 추가 ,삭제 및 수정이
      있을 시에는 개정 최소 10일 전부터 홈페이지의 공지란을 통해
      고지할 것입니다.
      <br />
      개인정보취급방침 시행일자: 2015-06-26
      <br />
      개인정보취급방침 변경일자: 2023-02-16
      <br />
    </>
  );
};

export default Agreement;