import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SpringBoardComponent = styled.section`
  align-items: center;
  background-color: var(--MainNavy);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 60px calc(100% - 120px) 60px;
  height: 100svh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100svw;

  & header {box-shadow: unset;}
  & footer {bottom: 8px; box-shadow: unset;}

  & section:first-of-type { // 메인 메뉴
    align-content: center;
    background-color: var(--MainNavy);
    display: flex;
    flex-wrap: wrap;
    height: 90%;
    /* justify-content: center; */
    margin: auto;
    overflow: hidden;
    width: 90%;

    &>a {
      height: 30%;
      width: 25%;
    }
  }
  & section:last-of-type { // 푸터 메뉴
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding-inline: 50px 80px;

    &>div {
      align-items: center;
      bottom: 25px;
      column-gap: 15px;
      display: flex;
      justify-content: flex-start;
      left: 15px;
    }
  }
`;

export const SpringBoardMenu = styled(Link)`
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--MainNavy);
  box-shadow: rgba(255, 255, 255, 0.2) 0px 10px 15px -3px, rgba(255, 255, 255, 0.1) 0px 4px 6px -2px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  gap: 15px;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
  
  &>svg {
    height: 2.5em;
    /* left: 20px;
    position: absolute;
    top: 20px; */
    width: fit-content;
  }
  &>p {
    color: var(--MainNavy);
    font-size: 2.5em;
    font-weight: 500;
    width: fit-content;
    white-space: nowrap;
  }
`;

export const SpringBoardButton = styled(Link)`
  align-items: center;
  background-color: var(--white);
  border-radius: 5px;
  color: var(--MainNavy);
  cursor: pointer;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  justify-content: center;
  padding: 4px 20px;
`;