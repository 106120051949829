import { AxiosInterceptor } from "api/core/consts";

export const processTypeApi = {
  searchProcessType: (body) =>
    AxiosInterceptor.post('processType/search', body),
  createProcessType: (body) => AxiosInterceptor.post('processType', body),
  updateProcessType: (processTypeId, body) =>
    AxiosInterceptor.put('processType/' + processTypeId, body),
  deleteProcessType: (processTypeId) =>
    AxiosInterceptor.delete('processType/' + processTypeId),
};
