import { AxiosInterceptor } from "api/core/consts";

export const alarmTemplate = {
  getAlarmTemplates: () => AxiosInterceptor.get('alarmTemplate'),
  getAlarmTemplate: (id) => AxiosInterceptor.get('alarmTemplate/' + id),
  searchAlarmTemplate: (body) => AxiosInterceptor.post('alarmTemplate/search', body),
  createAlarmTemplate: (body) => AxiosInterceptor.post('alarmTemplate', body),
  updateAlarmTemplate: (id, body) => AxiosInterceptor.put('alarmTemplate/' + id, body),
  deleteAlarmTemplate: (id) => AxiosInterceptor.delete('alarmTemplate/' + id),
};
