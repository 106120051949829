/* state 초기값 */
const initialState = {
  ordersContents: [],
  ProcurementContents: [],
  account: [],
  showProcessData: '',
  setWorkOrderId: [],
  setDelContents: [],
  workOrderContent: null,
  workOrderLogAmount: 0,
  calenderUpdateContent: [],
  equipmentAttributeList: [],

  // 엑셀 다운로드 변수
  excelAccountAttributeType: '',
  excelProductType: '',

  excelCurrentFamilyId: 0,
  excelAllFamilies: [],
  excelFirstFamily: '',
  excelSecondFamily: '',
  excelThirdFamily: '',
  excelFirstFamilies: [],
  excelSecondFamilies: [],
  excelThirdFamilies: [],

  excelWorkStatus: '',
  excelStartDate: '',
  excelEndDate: '',
  excelSearchOption: '',
  excelSearchText: '',

  //일일 일정표
  selectType: '',
  note1Text: '',
  note2Text: '',
  calenderStatus: '',
};

const JunContentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETORDERSCONTENTS':
      return {
        ...state,
        ordersContents: action.payload,
      };
    case 'SETPLACINGORDERSCONTENTS':
      return {
        ...state,
        ProcurementContents: action.payload,
      };

    case 'SETPLACINGACCOUNT':
      return {
        ...state,
        procurementAccountContents: action.payload,
      };

    // 엑셀 다운로드
    case 'EXCELSETACCOUNTATTRIBUTETYPE':
      return {
        ...state,
        excelAccountAttributeType: action.payload,
      };

    case 'EXCELSETPRODUCTTYPE':
      return {
        ...state,
        excelProductType: action.payload,
      };

    case 'EXCELSETCURRENTFAMILYID':
      return {
        ...state,
        excelCurrentFamilyId: action.payload,
      };

    case 'EXCELSETALLFAMILIES':
      return {
        ...state,
        excelAllFamilies: action.payload,
      };

    case 'EXCELSETFIRSTFAMILY':
      return {
        ...state,
        excelFirstFamily: action.payload,
      };

    case 'EXCELSETFIRSTFAMILIES':
      return {
        ...state,
        excelFirstFamilies: action.payload,
      };

    case 'EXCELSETSECONDFAMILY':
      return {
        ...state,
        excelSecondFamily: action.payload,
      };

    case 'EXCELSETSECONDFAMILIES':
      return {
        ...state,
        excelSecondFamilies: action.payload,
      };

    case 'EXCELSETTHIRDFAMILY':
      return {
        ...state,
        excelThirdFamily: action.payload,
      };

    case 'EXCELSETTHIRDFAMILIES':
      return {
        ...state,
        excelThirdFamilies: action.payload,
      };

    case 'EXCELSETWORKSTATUS':
      return {
        ...state,
        excelWorkStatus: action.payload,
      };

    case 'EXCELSETSTARTDATE':
      return {
        ...state,
        excelStartDate: action.payload,
      };

    case 'EXCELSETENDDATE':
      return {
        ...state,
        excelEndDate: action.payload,
      };

    case 'EXCELSETSEARCHOPTION':
      return {
        ...state,
        excelSearchOption: action.payload,
      };

    case 'EXCELSETSEARCHTEXT':
      return {
        ...state,
        excelSearchText: action.payload,
      };

    case 'EXCELSEARCHRESET':
      return initialState;

    // 일일 일정표
    case 'SETSELECTTYPE':
      return {
        ...state,
        selectType: action.payload,
      };

    case 'SETNOTE1TEXT':
      return {
        ...state,
        note1Text: action.payload,
      };

    case 'SETNOTE2TEXT':
      return {
        ...state,
        note2Text: action.payload,
      };

    case 'SETCALENDERSTATUS':
      return {
        ...state,
        calenderStatus: action.payload,
      };

    case 'SHOWPROCESSLIST':
      return {
        ...state,
        showProcessData: action.payload,
      };

    case 'SETWORKORDERID':
      return {
        ...state,
        setWorkOrderId: action.payload,
      };

    case 'SETDELCONTENTS':
      return {
        ...state,
        setDelContents: action.payload,
      };
    case 'SETWORKORDERCONTENT':
      return {
        ...state,
        workOrderContent: action.payload,
      };
    case 'SETWORKORDERLOGAMOUNT':
      return {
        ...state,
        workOrderLogAmount: action.payload,
      };

    case 'SETCALENDERUPDATECONTENT':
      return {
        ...state,
        calenderUpdateContent: action.payload,
      };

    case 'SETEQUIPMENTATTRIBUTELIST':
      return {
        ...state,
        equipmentAttributeList: action.payload,
      };
    default:
      return state;
  }
};

export default JunContentsReducer;
