export const contractActions_setContractType = (contractType) => {
  return {
    type: 'SETCONTRACTTYPE',
    payload: contractType,
  };
};

export const contractActions_setContractStatus = (contractStatus) => {
  return {
    type: 'SETCONTRACTSTATUS',
    payload: contractStatus,
  };
};

export const contractActions_setStartDate = (startDate) => {
  return {
    type: 'SETCONTRACTSTARTDATE',
    payload: startDate,
  };
};

export const contractActions_setEndDate = (endDate) => {
  return {
    type: 'SETCONTRACTENDDATE',
    payload: endDate,
  };
};

export const contractActions_setSearchOption = (searchOption) => {
  return {
    type: 'SETCONTRACTSEARCHOPTION',
    payload: searchOption,
  };
};

export const contractActions_setSearchText = (searchText) => {
  return {
    type: 'SETCONTRACTSEARCHTEXT',
    payload: searchText,
  };
};

export const contractActions_setPageNumber = (pageNumber) => {
  return {
    type: 'SETCONTRACTPAGENUMBER',
    payload: pageNumber,
  };
};

export const contractActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETCONTRACTTOTALSIZE',
    payload: totalSize,
  };
};

export const contractActions_setIsPushedSearchButton = (
  isPushedSearchButton,
) => {
  return {
    type: 'SETCONTRACTISPUSHEDSEARCHBUTTON',
    payload: isPushedSearchButton,
  };
};

export const contractActions_setReset = () => {
  return {
    type: 'SETCONTRACTRESET',
  };
};

export const contractActions_setContractAccount = (account) => {
  return {
    type: 'SETCONTRACTACCOUNT',
    payload: account,
  };
};

export const contractActions_setContractElementList = (elementList) => {
  return {
    type: 'SETCONTRACTELEMENTLIST',
    payload: elementList,
  };
};

export const contractActions_setViewScale = (viewScale) => {
  return {
    type: 'SETCONTRACTVIEWSCALE',
    payload: viewScale,
  };
};
