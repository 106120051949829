import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { shipmentApi } from 'api/apis/shipmentApi';

import { checkNullParse } from 'components/checkValues/checkValues';
import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

const DeleteGSShipment = () => {
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  const [searchParams, setSearchParams] = useSearchParams();
  const deleteContent = checkNullParse(searchParams.get('deleteContent'), {});
  const [_deleteContent, setDeleteContent] = useState({
    companyId: userReducer.company.companyId,
    shipmentCode: deleteContent.shipmentCode,
    shipmentId: deleteContent.shipmentId,
    shipmentName: deleteContent.shipmentName,
    customizedContent: checkNullParse(deleteContent.customizedContent, {
      shipmentStatus: deleteContent.shipmentStatus,
      supplierCompanyName: '',
      supplierName: '',
      supplierAddress: '',
      supplierCharge: '',
      driverName: '',
      driverHP: '',
      carNumber: '',
      supplyPlaceName: '',
      supplyCompanyName: '',
      supplyAddress: '',
      supplyCharge: '',
      chargeName: '',
      chargeNumber: '',
      chargeHP: '',
    }),
    shipmentElementList: [],
  })

  const actDelete = async () => {
    await shipmentApi.deleteShipment(deleteContent.shipmentId).then((response) => {
      if (response === undefined) return;
      console.log('shipmentApi.deleteShipment : ', response);
      alert('송장을 삭제했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    })
  };

  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <main className="Main">
        <NavBar title={<NavTitle menuCode={'205'} />} nav={''} />

        <DeleteForm
          delteTitle={<><NavTitle menuCode={'205'} /></>}
          deleteItem={
            <>
              <p>{'송장코드 : ' + _deleteContent.shipmentCode}</p><br />
              {/* <p>{'송장명 : ' + _deleteContent.shipmentName}</p><br /> */}
              <p>{'현장명 : ' + _deleteContent.customizedContent.supplyPlaceName}</p>
              <p>{'상호 : ' + _deleteContent.customizedContent.supplyCompanyName}</p>
              <p>{'주소 : ' + _deleteContent.customizedContent.supplyAddress}</p>
              <p>{'담당 : ' + _deleteContent.customizedContent.supplyCharge}</p><br />
              <p>{'운전자 : ' + _deleteContent.customizedContent.chargeName}</p>
              <p>{'차량번호 : ' + _deleteContent.customizedContent.chargeNumber}</p>
              <p>{'H.P : ' + _deleteContent.customizedContent.chargeHP}</p><br />
            </>
          }
          deleteText={<>해당 모든 송장 정보가 삭제됩니다.</>}
          deleteButton={
            <>
              <button className="formButton cancle"
                onClick={() => { navigate(pageReducer.currentPage, { replace: true }); }}
              >
                취소
              </button>
              <button className="formButton delete"
                onClick={() => {
                  if (window.confirm(' 송장을 삭제합니다.')) actDelete();
                  else return;
                }}
              >
                삭제
              </button>
            </>
          }
        />
      </main>
    }
    />
  );
};

export default DeleteGSShipment;
