import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';

import {
  modalReducer_setCurrentPageNum,
  modalReducer_setPageSize,
  modalReducer_setTotalCount,
} from 'store/modules/actions/default/modalActions';

import {
  ACCESS_TOKEN,
  AXIOS_BASE_HEADERS,
  AXIOS_BASE_URL,
} from 'preferences/server/constants';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import CommaNum from 'components/format/CommaNum';
import Paging from 'components/paging/Paging';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;

    td.amountCell,
    th.amountCell {
      min-width: 220px;
      width: 220px;
    }
    td.noteCell {
      text-align: left;
    }
  }
`;

const NavSection = styled.nav`
  align-items: center;
  background-color: var(--white);
  box-sizing: border-box;
  column-gap: 10px;
  display: flex;
  height: 60px;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0px 15px;
  width: 100%;

  &::--webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  &::--webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::--webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 4px;
  }
`;

const SelectLogType = styled.select`
  background-color: var(--ThirdBlue);
  border: 1px solid var(--ThirdBlue);
  border-radius: 17px;
  box-sizing: border-box;
  color: var(--white);
  height: 35px;
  padding: 5px 20px;
  width: 150px;

  &:focus {
    background-color: var(--MainNavy);
  }
`;

const setDateFormat = (thisDate) => {
  if (
    thisDate !== undefined &&
    thisDate !== null &&
    typeof thisDate === 'object'
  ) {
    const setDate =
      thisDate.length === 3
        ? thisDate.join('-')
        : thisDate.length > 6
        ? `${thisDate[0]}-${thisDate[1]}-${thisDate[2]} ${thisDate[3]}:${thisDate[4]}:${thisDate[5]}`
        : thisDate;
    const newDate = new Date(setDate);
    return moment(newDate).format('YYYY-MM-DD HH:mm:ss');
  } else {
    return thisDate;
  }
};

const ProductionPerformanceElements = (props) => {
  const { eventElement, eventStartDate, eventEndDate, open, close } = props;

  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { modalReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_viewType, setViewType] = useState('all');
  const [_elementLogList, setElementLogList] = useState([]);

  /* ====================================================================== #3 */
  useEffect(() => {
    dispatch(modalReducer_setCurrentPageNum(1));
    dispatch(modalReducer_setPageSize(10));

    getElementLogList();

    setOnload('loaded');

    return () => {};
  }, []);

  useEffect(() => {
    console.log('_viewType : ', _viewType);
    if (_onload === 'loaded') {
      getElementLogList();
    }

    return () => {};
  }, [_viewType]);

  useEffect(() => {
    if (_onload === 'loaded') {
      getElementLogList();
    }

    return () => {};
  }, [modalReducer.currentPageNum]);

  /* ====================================================================== #4 */
  // logList 불러오기
  const getElementLogList = async () => {
    const logType = _viewType === 'all' ? ['Put', 'Make'] : [_viewType];
    console.log('logType : ', logType);
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      elementId: eventElement.elementId,
      ElementStockLogStartDate: eventStartDate,
      ElementStockLogEndDate: eventEndDate,
      logTypes: logType,
    };
    const paging = `?page=${modalReducer.currentPageNum - 1}&size=${
      modalReducer.pageSize
    }&sort=id,DESC`;
    console.log(paging, modalReducer.currentPageNum, BodyToPost);

    await axios
      .post(
        AXIOS_BASE_URL + '/performance/elementStockLog/search' + paging,
        BodyToPost,
        AXIOS_BASE_HEADERS,
      )
      .then((response) => {
        if (response === undefined) return;
        console.log(
          '/performance/elementStockLog/search - response : ',
          response,
        );
        Finish_getElementLogList(response.data);
        dispatch(modalReducer_setTotalCount(response.data.totalElements));
      })
      .catch((error) => {
        console.log('/performance/elementStockLog/search - error : ', error);
      });
  };
  const Finish_getElementLogList = (Result) => {
    setElementLogList(() => {
      return Result.content;
    });
  };

  /* ====================================================================== #5 */
  /* ====================================================================== #6 */

  return (
    <>
      {open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>{`${eventElement.elementName} [${eventElement.elementCode}]   ( ${eventStartDate}  ~  ${eventEndDate} )`}</ModalTitle>
              <CloseButton onClick={close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              <NavSection>
                <SelectLogType
                  id="SelectType"
                  value={_viewType}
                  onChange={(e) => {
                    setViewType(() => {
                      return e.target.value;
                    });
                  }}
                >
                  <option value="all">전체</option>
                  <option value="Put">투입정보</option>
                  <option value="Make">생산정보</option>
                </SelectLogType>
              </NavSection>

              <TableSection
                content={
                  <table>
                    <thead>
                      <tr>
                        <th className="amountCell">시각</th>
                        {_viewType === 'Make' ? null : (
                          <th className="amountCell">투입</th>
                        )}
                        {_viewType === 'Put' ? null : (
                          <th className="amountCell">생산</th>
                        )}
                        <th>내용</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_elementLogList.length > 0 ? (
                        _elementLogList.map((thisItem) => {
                          return (
                            <tr key={thisItem.elementStockLogId + '_logRow'}>
                              <td className="amountCell">
                                {setDateFormat(thisItem.createdDate)}
                              </td>
                              {_viewType === 'Make' ? null : (
                                <td className="amountCell">
                                  <CommaNum
                                    displayType={'text'}
                                    num={
                                      thisItem.logType === 'Put'
                                        ? thisItem.amount
                                        : 0
                                    }
                                  />
                                </td>
                              )}
                              {_viewType === 'Put' ? null : (
                                <td className="amountCell">
                                  <CommaNum
                                    displayType={'text'}
                                    num={
                                      thisItem.logType === 'Make'
                                        ? thisItem.amount
                                        : 0
                                    }
                                  />
                                </td>
                              )}
                              <td className="noteCell">{thisItem.note}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={_viewType === 'all' ? 4 : 3}>
                            기간 내 생산 기록이 없습니다.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                }
              />
            </ModalMain>

            <Paging
              page={modalReducer.currentPageNum}
              count={modalReducer.totalCount}
              size={modalReducer.pageSize}
              modal={true}
            />
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default ProductionPerformanceElements;
