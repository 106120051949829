const initialState = {
  search: {},
  currentPageNum: 1,
  totalSize: 0,

  remark: '',
  fileList: [],
};

const numpadReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETNUMPADSEARCH':
      return {
        ...state,
        search: action.payload,
      };

    case 'SETNUMPADCURRENTPAGENUM':
      return {
        ...state,
        currentPageNum: action.payload,
      };

    case 'SETNUMPADTOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };

    case 'SETNUMPADREMARK':
      return {
        ...state,
        remark: action.payload,
      };

    case 'SETNUMPADFILELIST':
      return {
        ...state,
        fileList: action.payload,
      };

    case 'RESETNUMPADREDUCER':
      return initialState;

    default:
      return state;
  }
};

export default numpadReducer;
