import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { operatorActions_setUser } from 'store/modules/actions/operator/operatorActions';

import { auth } from 'api/apis/auth/auth';
import { userApi } from 'api/apis/userApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { LoadingMsg } from 'components/loading/LoadingMsg';
import { MasterUserManagementComponent } from './MasterUserManagement.style';

import NavBar from 'components/nav/NavBar';
import OperatorBody from 'components/layouts/body/OperatorBody';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

import MasterUserModal from './modal/MasterUserModal';

const MasterUserManagement = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { operatorReducer, pageReducer, userReducer } = useSelector((state) => state);
  const { user } = operatorReducer;

  /* ========================================================================= #2 */
  const types = ['role', 'company', 'team', 'user', /* 'email' */];

  const [_onload, setOnload] = useState('unload');

  const [_companyList, setCompanyList] = useState([]);
  const [_userList, setUserList] = useState([]);

  const [_user, setUser] = useState(user?.name);

  const [_modalStatus, setModalStatus] = useState(false);
  const [_modalType, setModalType] = useState();
  const [_modalData, setModalData] = useState({});

  /* ========================================================================= #3 */
  useEffect(() => {
    if(userReducer.user.role !== 'ROLE_MASTER') {
      alert('권한이 없습니다.');
      navigate(pageReducer.currentPage, { replace: true });
      return;
    }

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getCompanyList();
    getUserList(user.pageNumber - 1, user.companyId);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') getUserList(user.pageNumber - 1, user.companyId);

    return () => { };
  }, [user.pageNumber, user.companyId]);
  useEffect(() => {}, [user.name, _user]);
  useEffect(() => {}, [_modalStatus, _modalType, _modalData]);

  /* ========================================================================= #4 */
  const getCompanyList = async () => { // 회사 전체 리스트 불러오기
    await auth.searchCompanies().then((response) => {
      if (response === undefined) return;
      setCompanyList(() => { return checkNullArray(response.data.content, response.data); });
    });
  };

  const getUserList = async (page, companyId, clear) => { // 유저 리스트 불러오기
    const paging = `?page=${page}&size=10`;
    const body = {};
    if (checkEmptyNull(companyId, false) && companyId !== 'all') body.companyId = companyId;
    if (checkEmptyNull(_user, false)) {
      body.name = _user;
      if(clear) delete body.name;
    }

    await userApi.searchUser(paging, body).then((response) => {
      if (response === undefined) return;
      setUserList(() => { return response.data.content });
      dispatch(operatorActions_setUser({...user, totalSize: response.data.totalElements}));
    });
  };

  /* ========================================================================= #5 */
  const actSearch = async (e) => { // 검색
    // e.preventDefault();
    console.log('search');
    dispatch(operatorActions_setUser({...user, pageNumber: 1}));
    await getUserList(0, user.companyId);
  };

  const actReset = async (e) => { // 초기화
    // e.preventDefault();
    console.log('reset');
    dispatch(operatorActions_setUser({
      companyId: '',
      name: '',
      pageNumber: 1,
      totalSize: 0,
    }));
    setUser(() => {return ''});
    await getUserList(0, '', true);
  };

  const handleModalStatus = (type, userData) => {
    setModalType(() => {return type});
    setModalData(() => {return userData});

    setTimeout(() => {
      setModalStatus(() => {return true});
    }, 1000);
  }

  /* ========================================================================= #6 */

  return (
    <>
    <OperatorBody contents={
      <>
        <MasterUserManagementComponent className="Main">
          <NavBar
            title={'사용자 관리 - 마스터'}
            buttons={
              <>
              <div className="SearchSection">
                <div className="ContentCBox">
                  <select className='SearchOption detail'><option>회사</option></select>
                  <select className='SearchOption detail company' value={user.companyId} 
                    onChange={(e) => {
                      const companyId = e.target.value;
                      dispatch(operatorActions_setUser({...user, pageNumber: 1, companyId: companyId}));
                    }}
                  >
                    <option value={'all'}>전체</option>
                    {checkNullArray(_companyList, []).map((companyData, index) => {
                      return (
                        <option key={index + '_companyData'} value={companyData.companyId}>{companyData.companyName}</option>
                      )
                    })}
                  </select>
                </div>
                <div className="ContentCBox">
                  <select className='SearchOption detail'><option>이름</option></select>

                  <input type='text' name='name' className="SearchBar detail"
                    placeholder="검색..."
                    value={_user}
                    onInput={(e) => {
                      if(e.keyCode === 13 || e.key === 'Enter') return;
                      const name = e.target.value;
                      dispatch(operatorActions_setUser({...user, name: _user}));
                      setUser(() => {return name});
                    }}
                    onKeyUp={(e) => {if(e.keyCode === 13 || e.key === 'Enter') actSearch();}}
                  />
                </div>
                <div className="DetailButton search" onClick={actSearch}><SearchButtonIcon />검색</div>
                <div className="ResetButton detail" onClick={actReset}>초기화</div>
              </div>
              </>
            }
            nav={''}
          />

          <TableSection content={
            <table>
              <thead>
                <tr>
                  <th>role</th>
                  <th>회사 정보</th>
                  <th>팀 정보</th>
                  <th>사용자 정보</th>
                  <th>이메일 정보</th>
                  <th>알람 정보</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {!checkNullArray(_userList, false) ? LoadingMsg() :
                  checkNullArray(_userList, []).map((userData, index) => {
                    return (
                      <Fragment key={index + '_userData'}>
                        <tr>
                          <td>{userData.role}</td>
                          <td>
                            <p>companyId: {userData.companyId}</p>
                            <p>회사명: {userData.companyName}</p>
                          </td>
                          <td>
                            <p>companyId: {userData.team?.companyId}</p>
                            <p>팀회사명: {userData.team?.companyName}</p>
                            <p>teamId: {userData.team?.teamId}</p>
                            <p>팀이름: {userData.team?.teamName}</p>
                          </td>
                          <td>
                            <p>userId: {userData.id}</p>
                            <p>loginId: {userData.loginId}</p>
                            <p>이름: <span>{userData.name}</span></p>
                            <p>연락처: {userData.phoneNumber}</p>
                            <p>메모: {userData.memo}</p>
                          </td>
                          <td rowSpan={2}>
                            <p>이메일: {userData.email}</p>
                            {/* <p>이메일동의: {userData.emailVerified.toString()}</p> */}
                          </td>
                          <td rowSpan={2}>{userData.alarmAgreementFlag?.toString()}</td>
                          <td rowSpan={2}>
                            <button className='btn-delete'
                              onClick={(e) => {e.preventDefault(); handleModalStatus('delete', userData);}}
                            >삭제</button>
                          </td>
                        </tr>
                        <tr className='row-update'>
                          {types.map(type => {
                            return (
                              <td key={type + '_edit'}>
                                <button className='btn-edit'
                                  onClick={(e) => {e.preventDefault(); handleModalStatus(type, userData);}}
                                >수정</button>
                              </td>
                            );
                          })}
                        </tr>
                      </Fragment>
                    );
                  })}
              </tbody>
            </table>
          }
          ></TableSection>
        </MasterUserManagementComponent>

        <PagingComponent
          page={user.pageNumber}
          count={user.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(operatorActions_setUser({...user, pageNumber: page}));
          }}
        />
      </>
    }
    />
    {_modalStatus ? (
      <MasterUserModal
        open={_modalStatus}
        modalType={_modalType}
        modalData={_modalData}
        close={(update) => {
          setModalStatus(() => {return false});
          if(update === 'update') {
            console.log('update');
            getUserList(user.pageNumber - 1, user.companyId);
          }
        }}
      />
    ) : null}
    </>
  );
};

export default MasterUserManagement;
