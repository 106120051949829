import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {mallUserActions_setPageNumber, mallUserActions_setTotalSize, mallUserActions_setReset, mallUserActions_setSearchOption, mallUserActions_setSearchText, mallUserActions_setIsPushedSearchButton} from 'store/modules/actions/mall/mallUserActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { mallUserApi } from 'api/apis/mallUserApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import MallBody from 'components/layouts/body/MallBody';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const MainSection = styled.main`
  .TableSection {}
`;

const MallUserManagement = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mallUserReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_mallUserList, setMallUserList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  /* ====================================================================== #3 */
  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getMallUserList(mallUserReducer.pageNumber - 1, mallUserReducer.isPushedSearchButton);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getMallUserList(mallUserReducer.pageNumber - 1, mallUserReducer.isPushedSearchButton);
    }
    return () => { };
  }, [mallUserReducer.pageNumber]);

  useEffect(() => { }, [mallUserReducer]);

  /* ====================================================================== #4 */
  const getMallUserList = async (page, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {companyId: userReducer.company.companyId, role: 'ROLE_MALL'};
    if (checkEmptyNull(mallUserReducer.searchOption, false) && checkEmptyNull(mallUserReducer.searchText, false)) {
      BodyToPost[mallUserReducer.searchOption] = mallUserReducer.searchText;
    }
    await mallUserApi.searchMallUser(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('mallUserApi.searchMallUser : ', response);
      setMallUserList(() => {return response.data.content;});
      dispatch(mallUserActions_setTotalSize(response.data.totalElements));
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set([..._checkedItem]);
    if (isChecked) checkedItem.add(id);
    else if (!isChecked && checkedItem.has(id)) checkedItem.delete(id);
    setCheckedItem(() => {return checkedItem;});
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(mallUserActions_setPageNumber(1));
    dispatch(mallUserActions_setIsPushedSearchButton(true));
    await getMallUserList(0, true);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(mallUserActions_setReset());
    dispatch(mallUserActions_setPageNumber(1));
    await getMallUserList(0, false);
  };

  /* 수정 */
  const actUpdate = () => {
    const checkedMallUserList = Array.from(_checkedItem);

    if (checkedMallUserList.length < 1) return;
    else if (checkedMallUserList.length > 1) return alert('다중 선택되어 있습니다.');

    const updateContentIndex = _mallUserList.findIndex((thisItem) => thisItem.id === checkedMallUserList[0]);
    if (updateContentIndex === -1) return alert('수정할 고객을 선택해 주세요.');
    const updateContent = _mallUserList[updateContentIndex];

    navigate({
      pathname: '/mallManagement/user/update',
      search: `?${createSearchParams({updateContent: JSON.stringify(updateContent)})}`,
      replace: true,
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const checkedMallUserList = Array.from(_checkedItem);
    console.log('checkedMallUserList : ', checkedMallUserList);

    if (checkedMallUserList.length < 1) return;

    const deleteContents = checkedMallUserList.map((thisKey) => {
      const deleteContentIndex = _mallUserList.findIndex((thisIndex) => thisIndex.id === thisKey);
      if (deleteContentIndex === -1) return null;
      const deleteContent = _mallUserList[deleteContentIndex];
      return deleteContent;
    });
    const setDeleteContents = deleteContents.filter((thisItem) => thisItem !== null);

    if (setDeleteContents.length < 1) return;

    navigate({
      pathname: '/mallManagement/user/delete',
      search: `?${createSearchParams({deleteContents: JSON.stringify(setDeleteContents)})}`,
      replace: true,
    });
  };

  /* ====================================================================== #6 */

  return (
    <MallBody contents={
      <>
        <MainSection className="Main">
          <NavBar
            title={'고객관리'}
            buttons={
              <>
                <button className='btn-edit' onClick={actUpdate}>수정</button>
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="ContentCBox">
                  <div className="ContentCBox">
                    <select
                      className="SearchOption"
                      value={mallUserReducer.searchOption}
                      onChange={(e) => {dispatch(mallUserActions_setSearchOption(e.target.value));}}
                    >
                      {[
                        { optionValue: '', optionText: '검색옵션' },
                        {optionValue: 'mallUserName', optionText: '이름'},
                        {optionValue: 'phoneNumber', optionText: '연락처'},
                        {optionValue: 'email', optionText: '이메일'},
                        {optionValue: 'memo', optionText: '메모'},
                      ].map((thisItem) => {
                        return (<option key={thisItem.optionValue + '_mallUserSearchOption'} value={thisItem.optionValue}>{thisItem.optionText}</option>);
                      })}
                    </select>
                    <input
                      className="SearchBar"
                      placeholder="Search..."
                      value={mallUserReducer.searchText}
                      onInput={(e) => {dispatch(mallUserActions_setSearchText(e.target.value));}}
                    />
                  </div>

                  <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                  <div className="ResetButton" onClick={actReset}>초기화</div>
                </div>
              </>
            }
          />

          <TableSection content={
            <table>
              <thead>
                <tr>
                  <th style={{ minWidth: '50px', width: '50px' }}></th>
                  <th>이름</th>
                  <th>이메일</th>
                  <th>연락처</th>
                  <th>우편번호</th>
                  <th>주소</th>
                  <th>메모</th>
                </tr>
              </thead>
              <tbody>
                {_mallUserList.map((mallUser, index) => {
                  return (
                    <tr key={index + '_mallUsers'} name={'mallUserRows'}>
                      <td style={{minWidth: '50px', width: '50px'}}>
                        <input
                          type="checkBox"
                          name="mallUsers"
                          data-key={mallUser.id}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            handleCheckedItem(mallUser.id, isChecked);
                            if (isChecked) e.target.checked = true;
                            else e.target.checked = false;
                          }}
                          onClick={(e) => {e.stopPropagation();}}
                        />
                      </td>

                      <td>{mallUser.name}</td>
                      <td>{mallUser.email}</td>
                      <td>{mallUser.phoneNumber}</td>
                      <td>{mallUser.zip}</td>
                      <td>{mallUser.address}</td>
                      <td>{mallUser.memo}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          }
          ></TableSection>
        </MainSection>

        <PagingComponent
          page={mallUserReducer.pageNumber}
          count={mallUserReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(mallUserActions_setPageNumber(page));
          }}
        />
      </>
      }
    />
  );
};

export default MallUserManagement;
