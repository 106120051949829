import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';

import { inquiryApi } from 'api/apis/inquiryApi';
import { inquiryReplyApi } from 'api/apis/inquiryReplyApi';
import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateTimeFormatKR } from 'components/format/DateTimeFormatKR';

const InquiryReplyContext = React.createContext({ editingInquiryReplyId: -1 });

// 댓글 - Update, Delete 컴포넌트 타입
/**
 * @typedef {Object} InquiryReply
 * @property {number} inquiryReplyId - Reply ID
 * @property {string} inquiryReplyContent - Reply 내용
 */

/**
 * 
 * @param {Object} param
 * @param {InquiryReply} param.inquiryReply
 * @return {JSX.Element}
 */
function InquiryReply({ inquiryReply, cbDeleted, cbModified }) {
  const { editingInquiryReplyId, setEditingInquiryReplyId } = useContext(InquiryReplyContext)
  const [_formData, setFormData] = useState({    
    inquiryReplyContent: checkEmptyNull(inquiryReply.content, ''),
  });

  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value }; });
  };

  const doneEdit = () => {
    setEditingInquiryReplyId(-1)
    inquiryReply.inquiryReplyContent = _formData.inquiryReplyContent
    cbModified(inquiryReply)
  }

  return (
    <>
    { editingInquiryReplyId !== inquiryReply.inquiryReplyId ? (
      <li key={'_inquiry'}>
        <p>{inquiryReply.userName}</p>
        <p>{inquiryReply.content}</p>
        <p>{DateTimeFormatKR(inquiryReply.createdDate)}</p>
        {/* 댓글 수정 API 없어서 주석처리 */}
        {/* <button onClick={() => setEditingInquiryReplyId(inquiryReply.inquiryReplyId)}>수정</button> */}
        <button onClick={() => cbDeleted(inquiryReply.inquiryReplyId)}>삭제</button>
      </li>
    ) :
      // 수정 인풋 및 버튼
      <li key={'_inquiry'}>
      <p>{inquiryReply.userName}</p>
      <p>
        <input type='text' 
          placeholder={inquiryReply.content} 
          name="inquiryReplyContent" 
          value={_formData.inquiryReplyContent}
          onInput={handleInputEvent} />
      </p>
      <p>{DateTimeFormatKR(inquiryReply.createdDate)}</p>
      <button onClick={() => doneEdit()}>취소</button>
    </li>
    }
    </>
  )  
}


// 댓글 - Create, Read 컴포넌트
const InquiryReplyList = (props) => {
  const [editingInquiryReplyId, setEditingInquiryReplyId] = useState(-1);
  const contextValue = { editingInquiryReplyId, setEditingInquiryReplyId };

  const [_inquiryReplyList, setInquiryReplyList] = useState([]);
  const [inquiryReplyInfo, setInquiryReplyInfo] = useState({});

  const {
    userId,
    inquiryId,
    inquiryReplyId,
    inquiryReplyContent,
    inquiryReplyList
  } = props;
  const [_formData, setFormData] = useState({
    userId: userId,
    inquiryId: inquiryId,
    inquiryReplyId: inquiryReplyId,
    inquiryReplyContent: checkEmptyNull(inquiryReplyContent, ''),
    inquiryReplyList: checkEmptyNull(inquiryReplyList, []),
    deleteInquiryReplyList: [],
  });
  useEffect(() => {
    setFormData(() => {
      return {
        userId: userId,
        inquiryId: inquiryId,
        inquiryReplyId: inquiryReplyId,
        inquiryReplyContent: checkEmptyNull(inquiryReplyContent, ''),
        inquiryReplyList: checkEmptyNull(inquiryReplyList, []),
        deleteInquiryReplyList: [],
      };
    });
    getInquiryReplyList()
    return () => { };
}, [props]);

  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value }; });
  };

  // 댓글 리스트
  const getInquiryReplyList = async () => {
    let paging = ''
    const BodyToPut = {
      inquiryId: inquiryId,
    }
    await inquiryReplyApi.searchAllInquiryReply(inquiryReplyId, BodyToPut).then((response) => {
      console.log('searchAllInquiryReply : ', response);
      if (response.data) {
        setInquiryReplyList(() => { return response.data.content; });
      }
    });
  };

  // 댓글 생성
  const actReplyUpdate = async (e) => {
    e.preventDefault();
    const BodyToPut = {
        userId: userId,
        inquiryId: inquiryId,
        content: checkEmptyNull(_formData.inquiryReplyContent, ''),
    };
    console.log('BodyToPut 댓글 : ', BodyToPut);

    const res = await inquiryReplyApi.createInquiryReply(BodyToPut)
      .then((response) => {
        console.log('createInquiryReply : ', response);
        return response.data
      })
      setInquiryReplyList(() => {
        return _inquiryReplyList.concat([res])
      })
    };

  // 댓글 삭제
  const deleteReply = async (inquiryReplyId) => {
    await inquiryReplyApi.deleteInquiryReply(inquiryReplyId)
    setInquiryReplyList(() => {
      return _inquiryReplyList.filter((inquiryReply) => inquiryReply.inquiryReplyId !== inquiryReplyId)
    })
    // });
  }

  // 댓글 수정
  /**
   * 
   * @param {InquiryReply} inquiryReply - 수정할 InquiryReply
   */
  const modifyReply = async (inquiryReply) => {
    console.log('수정 업데이트 구현중')
    // const BodyToPut = {
    //   userId: userId,
    //   inquiryId: inquiryReply.inquiryReplyId,
    //   content: checkEmptyNull(inquiryReply.inquiryReplyContent),
    // };
    // const res = await inquiryReplyApi.modifiedInquiryReply(, BodyToPut)
    // setInquiryReplyList(() => {
    //   return _inquiryReplyList.concat([res])
    // })
    // });
  }

  return (
    <InquiryReplyContext.Provider value={contextValue}>
      <ul className='reply'>
        {_inquiryReplyList.map((inquiryReply, index) => {
          return (
            <InquiryReply 
              inquiryReply={inquiryReply} 
              cbModified={modifyReply}
              cbDeleted={deleteReply}
            />
          )
        })}
        <div>
          <textarea type="text" placeholder="댓글을 입력해주세요."
                    name="inquiryReplyContent" value={_formData.inquiryReplyContent}
                    onInput={handleInputEvent}
          />
          <button onClick={actReplyUpdate}>댓글 등록</button>
        </div>
      </ul>
    </InquiryReplyContext.Provider>
  );
};

export default InquiryReplyList;