import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

/* chart */
// import { Chart as ChartJS } from 'chart.js/auto';
// import { Line } from 'react-chartjs-2';

import {
  pageReducer_setPageNum,
  pageReducer_setPageSize,
  pageReducer_setTotalCount,
} from 'store/modules/actions/default/pageActions';

import { sensorDataApi } from 'api/apis/sensorDataApi';

import { rightArrow } from 'components/icons/src';
import Grid3Body from 'components/layouts/body/Grid3Body';
import NavBar from 'components/nav/NavBar';
import Paging from 'components/paging/Paging';
import TableSection from 'components/layouts/table/TableSection';
import userReducer from 'store/modules/reducers/user/userReducer';

const MainSection = styled.main``;

const SearchBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;

  &[data-section='date'] {
    column-gap: 5px;
  }
`;

const RightArrow = styled.div`
  background-color: var(--ThirdBlue);
  height: 24px;
  width: 24px;

  mask-image: url(${rightArrow});
  mask-repeat: no-repeat;
  mask-size: contain;

  --webkit-mask-image: url(${rightArrow});
  --webkit-mask-repeat: no-repeat;
  --webkit-mask-size: contain;
`;

const SelectDate = styled.input`
  border: 1px solid var(--gray-200);
  border-radius: 5px;
  box-sizing: border-box;
  height: 35px;
  padding: 2px 10px;
  width: 150px;

  &:focus {
    border: 1px solid var(--gray-200);
  }
`;

const DataSection = styled.div`
  box-sizing: border-box;
  display: grid;
  height: calc(100% - 70px);
  width: 100%;

  ${(props) => {
    if (props.contentType === 'all') {
      return {
        gridTemplateColumns: 'repeat(6, 16.666%)',
        zoom: '67%',
      };
    } else if (props.contentType === 'dataTable') {
      return {
        gridTemplateColumns: '100%',
      };
    } else {
      return {
        gridTemplateColumns: '100%',
      };
    }
  }}

  ${(props) => {
    return props.contentType === 'Search'
      ? { gridTemplateRows: 'calc(100% - 60px) 60px' }
      : null;
  }}

  .TableSection {
    td,
    th {
      border: 1px solid var(--gray-400);
    }
  }
`;

const ButtonsSection = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

// const ChartContainer = styled.div`
//   box-sizing: border-box;
//   overflow: auto;
//   padding: 10px;
//   width: 100%;

//   &::-webkit-scrollbar {
//     height: 12px;
//     width: 12px;
//   }
//   &::-webkit-scrollbar-track {
//     background-color: var(--gray-100);
//   }
//   &::-webkit-scrollbar-thumb {
//     background-color: var(--gray-400);
//     border: 2px solid var(--gray-100);
//     border-radius: 6px;
//   }
// `;

const DataTable = styled.table`
  background-color: var(--white);
  border-collapse: collapse;
  min-height: 100%;
  min-width: 100%;
  table-layout: fixed;
  width: 100%;
  & td {
    border: 1px solid var(--gray-400);
    box-sizing: border-box;
    min-width: unset;
    padding: 10px;
    width: unset;
  }
  & td.title {
    background-color: var(--MainNavy);
    color: var(--white);
  }
`;
const RobotDataTableStyle = styled.table`
  background-color: var(--white);
  border-collapse: collapse;
  min-height: 100%;
  min-width: 100%;
  table-layout: fixed;
  width: 100%;
  & td {
    border: 1px solid var(--gray-400);
    box-sizing: border-box;
    font-size: 4em;
    font-weight: 600;
    min-width: unset;
    text-align: center;
    width: unset;
  }
  & td.title {
    background-color: var(--MainNavy);
    color: var(--white);
    font-size: 2em;
    font-weight: 400;
    padding: 10px;
  }
`;

const SelectContentType = styled.select`
  background-color: var(--ThirdBlue);
  border: 1px solid var(--ThirdBlue);
  border-radius: 17px;
  box-sizing: border-box;
  color: var(--white);
  height: 35px;
  padding: 5px 20px;
  margin-right: 10px;
  width: 150px;

  &:focus {
    background-color: var(--MainNavy);
  }
`;

const LoadingScreen = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  color: var(--white);
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 999;
`;

const setDateFormat = (thisDate) => {
  if (
    thisDate !== undefined &&
    thisDate !== null &&
    typeof thisDate === 'object'
  ) {
    const setDate =
      thisDate.length === 3
        ? thisDate.join('-')
        : thisDate.length > 6
        ? `${thisDate[0]}-${thisDate[1]}-${thisDate[2]} ${thisDate[3]}:${thisDate[4]}:${thisDate[5]}`
        : thisDate;
    const newDate = new Date(setDate);
    return moment(newDate).format('MM-DD HH:mm:ss');
  } else {
    return thisDate;
  }
};

// const RobotData = (props) => {
//   const [_contentData, setContentData] = useState({
//     labels: props.contentData.map(({ date }) => date),
//     datasets: [
//       {
//         data: props.contentData.map(({ value }) => value),
//         label: '각도',

//         backgroundColor: 'transparent',
//         borderColor: 'navy',
//         fill: true,
//         pointRadius: 2,
//         pointBackgroundColor: 'color',
//       },
//     ],
//   });
//   useEffect(() => {
//     setContentData({
//       labels: props.contentData.map(({ date }) => date),
//       datasets: [
//         {
//           data: props.contentData.map(({ value }) => value),
//           label: '각도',

//           backgroundColor: 'transparent',
//           borderColor: 'navy',
//           fill: true,
//           pointRadius: 2,
//           pointBackgroundColor: 'color',
//         },
//       ],
//     });
//   }, [props]);

//   return (
//     <ChartContainer style={props.style}>
//       <Line type="line" data={_contentData} options={props.options} />
//     </ChartContainer>
//   );
// };

const RobotDataTable = (props) => {
  const [_contentData, setContentData] = useState(props.contentData);

  useEffect(() => {
    setContentData(props.contentData);
  }, [props]);

  return (
    <>
      {props.contentType === 'Injection_Angle' ? (
        <RobotDataTableStyle>
          <thead>
            <tr>
              <td className="title"></td>
              <td className="title">측면</td>
              <td className="title">정면 좌금형</td>
              <td className="title">정면 우금형</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="title">Station 1</td>
              <td>{_contentData['Injection_Angle_Side_Station1']}</td>
              <td>{_contentData['Injection_Angle_Frontleft_Station1']}</td>
              <td>{_contentData['Injection_Angle_Frontright_Station1']}</td>
            </tr>
            <tr>
              <td className="title">Station 2</td>
              <td>{_contentData['Injection_Angle_Side_Station2']}</td>
              <td>{_contentData['Injection_Angle_Frontleft_Station2']}</td>
              <td>{_contentData['Injection_Angle_Frontright_Station2']}</td>
            </tr>
            <tr>
              <td className="title">Station 3</td>
              <td>{_contentData['Injection_Angle_Side_Station3']}</td>
              <td>{_contentData['Injection_Angle_Frontleft_Station3']}</td>
              <td>{_contentData['Injection_Angle_Frontright_Station3']}</td>
            </tr>
            <tr>
              <td className="title">Station 4</td>
              <td>{_contentData['Injection_Angle_Side_Station4']}</td>
              <td>{_contentData['Injection_Angle_Frontleft_Station4']}</td>
              <td>{_contentData['Injection_Angle_Frontright_Station4']}</td>
            </tr>
          </tbody>
        </RobotDataTableStyle>
      ) : props.contentType === 'Mold_Temp' ? (
        <RobotDataTableStyle>
          <thead>
            <tr>
              <td className="title"></td>
              <td className="title">좌 금형온도</td>
              <td className="title">우 금형온도</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="title">현재값</td>
              <td>{_contentData['Mold_Temp_Left_PV']}</td>
              <td>{_contentData['Mold_Temp_Right_PV']}</td>
            </tr>
            <tr>
              <td className="title">상한값</td>
              <td>{_contentData['Mold_Temp_Left_Upper_SV']}</td>
              <td>{_contentData['Mold_Temp_Right_Upper_SV']}</td>
            </tr>
            <tr>
              <td className="title">하한값</td>
              <td>{_contentData['Mold_Temp_Left_Down_SV']}</td>
              <td>{_contentData['Mold_Temp_Right_Down_SV']}</td>
            </tr>
          </tbody>
        </RobotDataTableStyle>
      ) : props.contentType === 'BSmoke_Temp' ? (
        <RobotDataTableStyle>
          <thead>
            <tr>
              <td className="title"></td>
              <td className="title">냉각통(좌) 온도</td>
              <td className="title">냉각통(우) 온도</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="title">현재값</td>
              <td>{_contentData['BSmoke_Temp_Left_PV']}</td>
              <td>{_contentData['BSmoke_Temp_Right_PV']}</td>
            </tr>
            <tr>
              <td className="title">설정값</td>
              <td>{_contentData['BSmoke_Temp_Left_SV']}</td>
              <td>{_contentData['BSmoke_Temp_Right_SV']}</td>
            </tr>
          </tbody>
        </RobotDataTableStyle>
      ) : props.contentType === 'Count' ? (
        <RobotDataTableStyle>
          <thead>
            <tr>
              <td className="title"></td>
              <td className="title">생산수량</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="title">Station 1</td>
              <td>{_contentData['Count_Station1']}</td>
            </tr>
            <tr>
              <td className="title">Station 2</td>
              <td>{_contentData['Count_Station2']}</td>
            </tr>
            <tr>
              <td className="title">Station 3</td>
              <td>{_contentData['Count_Station3']}</td>
            </tr>
            <tr>
              <td className="title">Station 4</td>
              <td>{_contentData['Count_Station4']}</td>
            </tr>
            <tr>
              <td className="title">총수량</td>
              <td>{_contentData['All_Count']}</td>
            </tr>
          </tbody>
        </RobotDataTableStyle>
      ) : null}
    </>
  );
};

const RobotSearchTable = (props) => {
  const [_contentData, setContentData] = useState(props.contentData);

  useEffect(() => {
    setContentData(props.contentData);
  }, [props]);

  return (
    <>
      {props.contentType === 'Injection_Angle' ? (
        <table>
          <thead>
            <tr>
              <th colSpan={13}>주입각도</th>
            </tr>
            <tr>
              <th rowSpan={2}>시각</th>
              <th colSpan={3}>Station 1</th>
              <th colSpan={3}>Station 2</th>
              <th colSpan={3}>Station 3</th>
              <th colSpan={3}>Station 4</th>
            </tr>
            <tr>
              <th>측면</th>
              <th>정면 좌금형</th>
              <th>정면 우금형</th>
              <th>측면</th>
              <th>정면 좌금형</th>
              <th>정면 우금형</th>
              <th>측면</th>
              <th>정면 좌금형</th>
              <th>정면 우금형</th>
              <th>측면</th>
              <th>정면 좌금형</th>
              <th>정면 우금형</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(_contentData).map((thisTime) => {
              return (
                <tr key={thisTime + '_searchTable'}>
                  <td>{thisTime}</td>
                  <td>
                    {_contentData[thisTime]['Injection_Angle_Side_Station1']}
                  </td>
                  <td>
                    {
                      _contentData[thisTime][
                        'Injection_Angle_Frontleft_Station1'
                      ]
                    }
                  </td>
                  <td>
                    {
                      _contentData[thisTime][
                        'Injection_Angle_Frontright_Station1'
                      ]
                    }
                  </td>
                  <td>
                    {_contentData[thisTime]['Injection_Angle_Side_Station2']}
                  </td>
                  <td>
                    {
                      _contentData[thisTime][
                        'Injection_Angle_Frontleft_Station2'
                      ]
                    }
                  </td>
                  <td>
                    {
                      _contentData[thisTime][
                        'Injection_Angle_Frontright_Station2'
                      ]
                    }
                  </td>
                  <td>
                    {_contentData[thisTime]['Injection_Angle_Side_Station3']}
                  </td>
                  <td>
                    {
                      _contentData[thisTime][
                        'Injection_Angle_Frontleft_Station3'
                      ]
                    }
                  </td>
                  <td>
                    {
                      _contentData[thisTime][
                        'Injection_Angle_Frontright_Station3'
                      ]
                    }
                  </td>
                  <td>
                    {_contentData[thisTime]['Injection_Angle_Side_Station4']}
                  </td>
                  <td>
                    {
                      _contentData[thisTime][
                        'Injection_Angle_Frontleft_Station4'
                      ]
                    }
                  </td>
                  <td>
                    {
                      _contentData[thisTime][
                        'Injection_Angle_Frontright_Station4'
                      ]
                    }
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : props.contentType === 'Mold_Temp' ? (
        <table>
          <thead>
            <tr>
              <th colSpan={7}>금형온도</th>
            </tr>
            <tr>
              <th rowSpan={2}>시각</th>
              <th colSpan={3}>좌 금형온도</th>
              <th colSpan={3}>우 금형온도</th>
            </tr>
            <tr>
              <th>현재값</th>
              <th>상한값</th>
              <th>하한값</th>
              <th>현재값</th>
              <th>상한값</th>
              <th>하한값</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(_contentData).map((thisTime) => {
              return (
                <tr key={thisTime + '_searchTable'}>
                  <td>{thisTime}</td>
                  <td>{_contentData[thisTime]['Mold_Temp_Left_PV']}</td>
                  <td>{_contentData[thisTime]['Mold_Temp_Left_Upper_SV']}</td>
                  <td>{_contentData[thisTime]['Mold_Temp_Left_Down_SV']}</td>
                  <td>{_contentData[thisTime]['Mold_Temp_Right_PV']}</td>
                  <td>{_contentData[thisTime]['Mold_Temp_Right_Upper_SV']}</td>
                  <td>{_contentData[thisTime]['Mold_Temp_Right_Down_SV']}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : props.contentType === 'BSmoke_Temp' ? (
        <table>
          <thead>
            <tr>
              <th colSpan={5}>흑연온도</th>
            </tr>
            <tr>
              <th rowSpan={2}>시각</th>
              <th colSpan={2}>냉각통(좌) 온도</th>
              <th colSpan={2}>냉각통(우) 온도</th>
            </tr>
            <tr>
              <th>현재값</th>
              <th>설정값</th>
              <th>현재값</th>
              <th>설정값</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(_contentData).map((thisTime) => {
              return (
                <tr key={thisTime + '_searchTable'}>
                  <td>{thisTime}</td>
                  <td>{_contentData[thisTime]['BSmoke_Temp_Left_PV']}</td>
                  <td>{_contentData[thisTime]['BSmoke_Temp_Left_SV']}</td>
                  <td>{_contentData[thisTime]['BSmoke_Temp_Right_PV']}</td>
                  <td>{_contentData[thisTime]['BSmoke_Temp_Right_SV']}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : props.contentType === 'Count' ? (
        <table>
          <thead>
            <tr>
              <th colSpan={6}>생산수량</th>
            </tr>
            <tr>
              <th>시각</th>
              <th>Station 1</th>
              <th>Station 2</th>
              <th>Station 3</th>
              <th>Station 4</th>
              <th>총수량</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(_contentData).map((thisTime) => {
              return (
                <tr key={thisTime + '_searchTable'}>
                  <td>{thisTime}</td>
                  <td>{_contentData[thisTime]['Count_Station1']}</td>
                  <td>{_contentData[thisTime]['Count_Station2']}</td>
                  <td>{_contentData[thisTime]['Count_Station3']}</td>
                  <td>{_contentData[thisTime]['Count_Station4']}</td>
                  <td>{_contentData[thisTime]['All_Count']}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : null}
    </>
  );
};

const DLSensorData = () => {
  const dispatch = useDispatch();
  const { pageReducer } = useSelector((state) => state);

  const _ref = useRef();

  const [_onLoad, setOnload] = useState('unload');
  const [_startDate, setStartDate] = useState('');
  const [_endDate, setEndDate] = useState('');

  const [_contentType, setContentType] = useState('');
  const [_searchType, setSearchType] = useState('');

  const [_dataTable, setDataTable] = useState({}); // 전체
  const [_dataInjectionAngle, setDataInjectionAngle] = useState({}); // 주입각도
  const [_dataMoldTemp, setDataMoldTemp] = useState({}); // 금형온도
  const [_dataBSmokeTemp, setDataBSmokeTemp] = useState({}); // 흑연온도
  const [_dataMoltenTemp, setDataMoltenTemp] = useState({}); // 용탕온도
  const [_dataCount, setDataCount] = useState({}); // 생산수량

  const [_searchData, setSearchData] = useState([]); // 검색

  const [_loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => {
    setContentType('dataTable');
    getSearchData('dataTable');

    const search = setInterval(() => {
      if (_contentType === 'dataTable') getSearchData(_contentType);
    }, 60000);

    setOnload('loaded');
    return () => clearInterval(search);
  }, []);

  useEffect(() => {
    if (pageReducer.currentPage !== window.location.pathname) {
      dispatch(pageReducer_setPageNum(1));
      dispatch(pageReducer_setPageSize(10));
    }

    return () => {};
  }, [pageReducer.currentPage]);

  useEffect(() => {
    if (_onLoad === 'loaded') {
      getSearchData(_contentType, _searchType);
    }

    return () => {};
  }, [pageReducer.currentPageNum]);

  useEffect(() => {
    _ref.current = _contentType;
  }, [_contentType]);

  useEffect(() => {
    if (
      _onLoad === 'loaded' &&
      _contentType === 'Search' &&
      _searchType !== ''
    ) {
      dispatch(pageReducer_setPageNum(1));
      getSearchData(_contentType, _searchType);
    }
  }, [_searchType]);

  const getSearchData = async (contentType, searchType) => {
    const axiosBody = {
      companyId: 6,
      startDate: moment().add('-1', 'm').add(9, 'hour').toISOString(),
      endDate: moment().add(9, 'hour').toISOString(),
      name: '',
    };
    if (
      contentType !== 'all' &&
      contentType !== 'dataTable' &&
      contentType !== 'Search'
    ) {
      axiosBody.name = contentType;
    }
    if (
      contentType === 'Search' &&
      searchType !== '' &&
      _startDate !== null &&
      _startDate !== '' &&
      _endDate !== null &&
      _endDate !== ''
    ) {
      setLoadingStatus(() => {
        return true;
      });

      axiosBody.startDate = moment(_startDate).add(9, 'hour').toISOString();
      axiosBody.endDate = moment(_endDate).add(9, 'hour').toISOString();
      axiosBody.name = _searchType;
    }

    const page = contentType === 'Search' ? pageReducer.currentPageNum - 1 : 0;
    const size =
      contentType === 'Search'
        ? searchType === 'Injection_Angle'
          ? '&size=200'
          : searchType === 'Mold_Temp'
          ? '&size=100'
          : searchType === 'BSmoke_Temp'
          ? '&size=70'
          : searchType === 'Count'
          ? '&size=100'
          : '&size=100'
        : '&size=100';

    dispatch(
      pageReducer_setPageSize(
        contentType === 'Search'
          ? searchType === 'Injection_Angle'
            ? 200
            : searchType === 'Mold_Temp'
            ? 100
            : searchType === 'BSmoke_Temp'
            ? 70
            : searchType === 'Count'
            ? 100
            : 100
          : 100,
      ),
    );

    const paging = `?page=${page + size}&sort=id,DESC`;
    console.log(_ref.current, paging, axiosBody);

    await sensorDataApi.daeLimSensorData(paging, axiosBody).then((response) => {
      if (response === undefined) return;
      console.log('sensorDataApi.daeLimSensorData : ', response);

      if (response.data.content.length < 1) return;
      if (_contentType !== 'Search') {
        const allData = { ..._dataTable };
        const InjectionAngleData = { ..._dataInjectionAngle };
        const MoldTempData = { ..._dataMoldTemp };
        const BSmokeTempData = { ..._dataBSmokeTemp };
        const MoltenTempData = { ..._dataMoltenTemp };
        const CountData = { ..._dataCount };

        response.data.content.forEach((thisItem) => {
          const returnData = { ...thisItem };
          returnData[thisItem.name] = thisItem.value;
          returnData.date = setDateFormat(thisItem.date);

          if (thisItem.name.indexOf('Injection_Angle_') !== -1) {
            InjectionAngleData[thisItem.name] = thisItem.value;
          }
          if (thisItem.name.indexOf('Mold_Temp_') !== -1) {
            MoldTempData[thisItem.name] = thisItem.value;
          }
          if (thisItem.name.indexOf('BSmoke_Temp_') !== -1) {
            BSmokeTempData[thisItem.name] = thisItem.value;
          }
          if (thisItem.name.indexOf('Molten_Temp_') !== -1) {
            MoltenTempData[thisItem.name] = thisItem.value;
          }
          if (thisItem.name.indexOf('Count') !== -1) {
            CountData[thisItem.name] = thisItem.value;
          }

          allData[thisItem.name] = thisItem.value;
        });

        setDataTable(allData);
        setDataInjectionAngle(InjectionAngleData);
        setDataMoldTemp(MoldTempData);
        setDataBSmokeTemp(BSmokeTempData);
        setDataMoltenTemp(MoltenTempData);
        setDataCount(CountData);
      } else {
        dispatch(pageReducer_setTotalCount(response.data.totalElements));

        const searchData = {};
        response.data.content.forEach((thisItem) => {
          const returnData = { ...thisItem };
          returnData[thisItem.name] = thisItem.value;
          returnData.date = moment(thisItem.date)
            .subtract(9, 'hour')
            .format('YYYY-MM-DD HH:mm:ss');
          // returnData.date = setDateFormat(thisItem.date);

          const date = moment(thisItem.date)
            .subtract(9, 'hour')
            .format('YYYY-MM-DD HH:mm:ss');

          searchData[date] = {
            ...searchData[date],
          };
          searchData[date][thisItem.name] = thisItem.value;
        });

        console.log('searchData : ', searchData);
        setSearchData(() => {
          return searchData;
        });

        setLoadingStatus(() => {
          return false;
        });
      }
    });
  };

  const actSearch = () => {
    console.log('_contentType, _searchType : ', _contentType, _searchType);
    getSearchData(_contentType, _searchType);
  };

  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid3Body
      contents={
        <>
          <MainSection className="Main">
            <NavBar
              title={'주조로봇데이터'}
              buttons={
                <>
                  {_contentType === 'Search' && (
                    <SearchBox data-section="date">
                      <SelectContentType
                        value={_searchType}
                        onChange={(e) => {
                          setSearchType(e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          데이터 선택
                        </option>
                        <option value="Injection_Angle">주입각도</option>
                        <option value="Mold_Temp">금형온도</option>
                        <option value="BSmoke_Temp">흑연온도</option>
                        <option value="Count">생산수량</option>
                      </SelectContentType>
                      <SelectDate
                        type="datetime-local"
                        value={_startDate}
                        onChange={(e) => {
                          setStartDate(e.target.value);
                        }}
                      ></SelectDate>
                      <RightArrow />
                      <SelectDate
                        type="datetime-local"
                        value={_endDate}
                        onChange={(e) => {
                          setEndDate(e.target.value);
                        }}
                      ></SelectDate>
                      <button
                        className="formButton"
                        onClick={actSearch}
                        style={{ marginLeft: '10px', width: '80px' }}
                      >
                        조회
                      </button>
                    </SearchBox>
                  )}
                </>
              }
              nav={''}
            />

            <DataSection contentType={_contentType}>
              {_onLoad === 'loaded' && _contentType === 'all' ? (
                <>
                  {/* <RobotData
                    contentData={_robotDataA}
                    options={{
                      scales: {
                        x: {
                          ticks: {
                            display: false,
                          },

                          grid: {
                            drawBorder: false,
                            display: false,
                          },
                        },
                      },
                    }}
                    style={{
                      gridColumn: '1 / span 2',
                    }}
                  ></RobotData>
                  <RobotData
                    contentData={_robotDataA}
                    options={{
                      scales: {
                        x: {
                          ticks: {
                            display: false,
                          },

                          grid: {
                            drawBorder: false,
                            display: false,
                          },
                        },
                      },
                    }}
                    style={{
                      gridColumn: '3 / span 2',
                    }}
                  ></RobotData>
                  <RobotData
                    contentData={_robotDataA}
                    options={{
                      scales: {
                        x: {
                          ticks: {
                            display: false,
                          },

                          grid: {
                            drawBorder: false,
                            display: false,
                          },
                        },
                      },
                    }}
                    style={{
                      gridColumn: '5 / span 2',
                    }}
                  ></RobotData>
                  <RobotData
                    contentData={_robotDataA}
                    options={{
                      scales: {
                        x: {
                          ticks: {
                            display: false,
                          },

                          grid: {
                            drawBorder: false,
                            display: false,
                          },
                        },
                      },
                    }}
                    style={{
                      gridColumn: '1 / span 3',
                      gridRow: 2,
                    }}
                  ></RobotData>
                  <RobotData
                    contentData={_robotDataA}
                    options={{
                      scales: {
                        x: {
                          ticks: {
                            display: false,
                          },

                          grid: {
                            drawBorder: false,
                            display: false,
                          },
                        },
                      },
                    }}
                    style={{
                      gridColumn: '4 / span 3',
                      gridRow: 2,
                    }}
                  ></RobotData> */}
                </>
              ) : _contentType === 'dataTable' ? (
                <>
                  <DataTable>
                    <tbody>
                      <tr>
                        <td rowSpan={5} className="title">
                          주입각도
                        </td>
                        <td className="title"></td>
                        <td className="title">측면</td>
                        <td className="title">정면 좌금형</td>
                        <td className="title">정면 우금형</td>
                        <td className="title"></td>
                      </tr>
                      <tr>
                        <td className="title">Station 1</td>
                        <td>{_dataTable['Injection_Angle_Side_Station1']}</td>
                        <td>
                          {_dataTable['Injection_Angle_Frontleft_Station1']}
                        </td>
                        <td>
                          {_dataTable['Injection_Angle_Frontright_Station1']}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td className="title">Station 2</td>
                        <td>{_dataTable['Injection_Angle_Side_Station2']}</td>
                        <td>
                          {_dataTable['Injection_Angle_Frontleft_Station2']}
                        </td>
                        <td>
                          {_dataTable['Injection_Angle_Frontright_Station2']}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td className="title">Station 3</td>
                        <td>{_dataTable['Injection_Angle_Side_Station3']}</td>
                        <td>
                          {_dataTable['Injection_Angle_Frontleft_Station3']}
                        </td>
                        <td>
                          {_dataTable['Injection_Angle_Frontright_Station3']}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td className="title">Station 4</td>
                        <td>{_dataTable['Injection_Angle_Side_Station4']}</td>
                        <td>
                          {_dataTable['Injection_Angle_Frontleft_Station4']}
                        </td>
                        <td>
                          {_dataTable['Injection_Angle_Frontright_Station4']}
                        </td>
                        <td></td>
                      </tr>

                      <tr>
                        <td rowSpan={2} className="title">
                          금형온도
                        </td>
                        <td className="title"></td>
                        <td className="title">좌 금형온도</td>
                        <td className="title">우 금형온도</td>
                        <td className="title"></td>
                        <td className="title"></td>
                      </tr>
                      <tr>
                        <td className="title">현재값</td>
                        <td>{_dataTable['Mold_Temp_Left_PV']}</td>
                        <td>{_dataTable['Mold_Temp_Right_PV']}</td>
                        <td></td>
                        <td></td>
                      </tr>

                      <tr>
                        <td rowSpan={2} className="title">
                          흑연온도
                        </td>
                        <td className="title"></td>
                        <td className="title">냉각통(좌) 온도</td>
                        <td className="title">냉각통(우) 온도</td>
                        <td className="title"></td>
                        <td className="title"></td>
                      </tr>
                      <tr>
                        <td className="title">현재값</td>
                        <td>{_dataTable['BSmoke_Temp_Left_PV']}</td>
                        <td>{_dataTable['BSmoke_Temp_Right_PV']}</td>
                        <td>{_dataTable['']}</td>
                        <td>{_dataTable['']}</td>
                      </tr>

                      <tr>
                        <td rowSpan={2} className="title">
                          생산수량
                        </td>
                        <td className="title">Station 1</td>
                        <td className="title">Station 2</td>
                        <td className="title">Station 3</td>
                        <td className="title">Station 4</td>
                        <td className="title">총수량</td>
                      </tr>
                      <tr>
                        <td>{_dataTable['Count_Station1']}</td>
                        <td>{_dataTable['Count_Station2']}</td>
                        <td>{_dataTable['Count_Station3']}</td>
                        <td>{_dataTable['Count_Station4']}</td>
                        <td>{_dataTable['All_Count']}</td>
                      </tr>
                    </tbody>
                  </DataTable>
                </>
              ) : _contentType === 'Injection_Angle' ? (
                <>
                  <RobotDataTable
                    contentType={_contentType}
                    contentData={_dataInjectionAngle}
                  ></RobotDataTable>
                </>
              ) : _contentType === 'Mold_Temp' ? (
                <>
                  <RobotDataTable
                    contentType={_contentType}
                    contentData={_dataMoldTemp}
                  ></RobotDataTable>
                </>
              ) : _contentType === 'BSmoke_Temp' ? (
                <>
                  <RobotDataTable
                    contentType={_contentType}
                    contentData={_dataBSmokeTemp}
                  ></RobotDataTable>
                </>
              ) : _contentType === 'Molten_Temp' ? (
                <>
                  <RobotDataTable
                    contentType={_contentType}
                    contentData={_dataMoltenTemp}
                  ></RobotDataTable>
                </>
              ) : _contentType === 'Count' ? (
                <>
                  <RobotDataTable
                    contentType={_contentType}
                    contentData={_dataCount}
                  ></RobotDataTable>
                </>
              ) : _contentType === 'Search' ? (
                <>
                  <TableSection
                    content={
                      <>
                        {_searchType !== '' ? (
                          <>
                            <RobotSearchTable
                              contentType={_searchType}
                              contentData={_searchData}
                            ></RobotSearchTable>
                          </>
                        ) : null}
                      </>
                    }
                  />
                  <Paging
                    page={pageReducer.currentPageNum}
                    count={pageReducer.totalCount}
                    size={pageReducer.pageSize}
                  />
                </>
              ) : null}
            </DataSection>
          </MainSection>

          <ButtonsSection>
            {/* <button
              onClick={() => {
                setContentType('all');
              }}
            >
              전체보기 - 그래프
            </button> */}
            <button
              onClick={() => {
                setContentType('dataTable');
              }}
            >
              전체보기
            </button>
            <button
              onClick={() => {
                setContentType('Injection_Angle');
              }}
            >
              주입각도
            </button>
            <button
              onClick={() => {
                setContentType('Mold_Temp');
              }}
            >
              금형온도
            </button>
            <button
              onClick={() => {
                setContentType('BSmoke_Temp');
              }}
            >
              흑연온도
            </button>
            {/* <button
              onClick={() => {
                setContentType('Molten_Temp');
              }}
            >
              용탕온도
            </button> */}
            <button
              onClick={() => {
                setContentType('Count');
              }}
            >
              생산수량
            </button>
            <button
              onClick={() => {
                setContentType('Search');
              }}
            >
              조회
            </button>
          </ButtonsSection>

          {_loadingStatus === true ? (
            <LoadingScreen>조회하고 있습니다...</LoadingScreen>
          ) : null}
        </>
      }
    ></Grid3Body>
  );
};

export default DLSensorData;
