import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { barcodeActions_setPrintList } from 'store/modules/actions/common/barcodeActions';

import { barcodeApi } from 'api/apis/barcode/barcodeApi';
import { processTypeApi } from 'api/apis/processTypeApi';
import { processApi } from 'api/apis/processApi';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';
import { workOrderTypeApi } from 'api/apis/workOrderTypeApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import Process from 'pages/barcode/print/contents/table/Process';
import ProcessResult from 'pages/barcode/print/contents/table/ProcessResult';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';
import WorkOrderLog from 'pages/barcode/print/contents/table/WorkOrderLog';

const SerialSection = styled.main`
  display: grid;
  grid-template-rows: auto minmax(calc(100% - 226px), calc(100% - 66px)) 60px;
  height: 100%;
  overflow: hidden;
  width: 100svw;

  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    h4 {
      padding-right: 20px;
      margin-right: 10px;
      border-right: 1px solid #ddd;
    }
    & .searchTypeButtons {
      display: flex;
      gap: 5px;
    }
  }

  & .resultSection {
    display: grid;
    grid-template-columns: ${props => { return props.searchType === 'element' ? '100%' : '40% 60%' }};
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;

    & section {
      width: 100%;
    }

    & .TableSection {
      & td, & th {
        min-width: 100px;
        &:first-child {
          min-width: 50px;
        }

        &.cell-amount {
          font-size: 1.5em;
        }
        & .failedReason {
          text-align: left;
          & b {
            margin-right: 10px;
            font-weight: 600;
          }
        }
      }
      & th {
        /* font-size: 0.9em; */
        line-height: 1.4em;
      }
    }
  }

  & .paginationSection {
    align-items: center;
    display: grid;
    grid-template-columns: ${props => { return props.searchType === 'element' ? '100%' : '40% 60%' }};
    justify-content: center;

    & .pagination:last-child li {
      & a {color: var(--MainNavy);}

      &.active {
        background-color: var(--MainNavy);
        & a {color: var(--white);}
      }
    }
  }
`;

const Serial = (props) => {
  const dispatch = useDispatch();
  const { barcodeReducer, userReducer } = useSelector(state => state);

  const { printList } = props;

  const SerialCheckBox = useRef();

  const [_onload, setOnload] = useState('unload');

  const [_searchType, setSearchType] = useState('workOrderLog');

  const [_processTypes, setProcessTypes] = useState([]);
  const [_processResultList, setProcessResultList] = useState([]);
  const [_processResultSearchData, setProcessResultSearchData] = useState({
    companyId: userReducer.company.companyId,
    processTypeId: '',
    workOrderId: '',
    startDate: '',
    endDate: '',
    processCode: '',
    processName: '',
  });
  const [_processResultPageNum, setProcessResultPageNum] = useState(1);
  const [_processResultTotalSize, setProcessResultTotalSize] = useState(0);

  const [_workOrderTypes, setWorkOrderTypes] = useState([]);
  const [_workOrderLogList, setWorkOrderLogList] = useState([]);
  const [_workOrderLogSearchData, setWorkOrderLogSearchData] = useState({
    companyId: userReducer.company.companyId,
    workOrderTypeId: '',
    workOrderStatus: 'all',
    scheduledStartDate: '',
    scheduledEndDate: '',
    workOrderCode: '',
    workOrderName: '',
  });
  const [_pageNum, setPageNum] = useState(1);
  const [_totalSize, setTotalSize] = useState(0);

  const [_processList, setProcessList] = useState([]);
  const [_processSearchData, setProcessSearchData] = useState({
    companyId: userReducer.company.companyId,
    processTypeId: '',
    processCode: '',
    processName: '',
  });
  const [_processPageNum, setProcessPageNum] = useState(1);
  const [_processTotalSize, setProcessTotalSize] = useState(0);

  // const [_elementList, setElementList] = useState([]);
  const [_elementSearchData, setElementSearchData] = useState({
    companyId: userReducer.company.companyId,
    elementTypeId: '',
    elementCode: '',
    elementName: '',
  });
  // const [_elementPageNum, setElementPageNum] = useState(1);
  // const [_elementTotalSize, setElementTotalSize] = useState(0);


  const [_currentProcessResult, setCurrentProcessResult] = useState({});
  const [_currentWorkOrderLog, setCurrentWorkOrderLog] = useState({});
  const [_currentProcess, setCurrentProcess] = useState({});

  const [_resultElementList, setResultElementList] = useState([]);
  // const [_resultElementSearchData, setResultElementSearchData] = useState({
  //   companyId: userReducer.company.companyId,
  //   startDate: '',
  //   endDate: '',
  // })
  const [_resultElementPageNum, setResultElementPageNum] = useState(1);
  const [_resultElementTotalSize, setResultElementTotalSize] = useState(0);

  useEffect(() => {
    if (_searchType === 'workOrderLog') {
      // 작업지시 기준
      getWorkOrderTypes();
      setPageNum(() => { return 1 });
      getWorkOrderLogList(_pageNum - 1, _workOrderLogSearchData.workOrderTypeId, _workOrderLogSearchData.workOrderStatus);
    }
    if (_searchType === 'processResult') {
      // 공정결과 기준
      getProcessTypes();
      setProcessResultPageNum(() => { return 1 });
      getProcessResultList(_processResultPageNum - 1, _processResultSearchData.processTypeId);
    }
    if (_searchType === 'process') {
      // 공정 기준
      getProcessTypes();
      setProcessPageNum(() => { return 1 });
      getProcessList(_processPageNum - 1, _processSearchData.processTypeId);
    }

    setOnload('loaded');
  }, [])

  useEffect(() => { }, [printList])
  useEffect(() => { }, [_workOrderLogList, _processResultList, _processList])
  useEffect(() => { }, [_workOrderLogSearchData, _processResultSearchData, _processSearchData])

  useEffect(() => {
    if (_onload === 'loaded') {
      setResultElementPageNum(() => { return 1 });
      if (_searchType === 'workOrderLog') {
        // 작업지시 기준
        if (!checkNullArray(_workOrderTypes, false)) getWorkOrderTypes();
        getWorkOrderLogList(_pageNum - 1, _workOrderLogSearchData.workOrderTypeId, _workOrderLogSearchData.workOrderStatus);
        getResultElementList(_resultElementPageNum - 1, _searchType);
      }
      if (_searchType === 'processResult') {
        // 공정결과 기준
        if (!checkNullArray(_processTypes, false)) getProcessTypes();
        getProcessResultList(_processResultPageNum - 1, _processResultSearchData.processTypeId);
        getResultElementList(_resultElementPageNum - 1, _searchType);
      }
      if (_searchType === 'process') {
        // 공정 기준
        if (!checkNullArray(_processTypes, false)) getProcessTypes();
        getProcessList(_processPageNum - 1, _processSearchData.processTypeId);
        getResultElementList(_resultElementPageNum - 1, _searchType);
      }
      if (_searchType === 'element') {
        getResultElementList(_resultElementPageNum - 1, _searchType);
      }
    }
  }, [_searchType])

  useEffect(() => {
    if (_onload === 'loaded') {
      getResultElementList(_resultElementPageNum - 1, _searchType);
    }
    return () => { };
  }, [_resultElementPageNum]);

  useEffect(() => {
    if (_onload === 'loaded') {
      getProcessResultList(_processResultPageNum - 1, _processResultSearchData.processTypeId);
    }
    return () => { };
  }, [_processResultPageNum]);

  useEffect(() => {
    if (_onload === 'loaded') {
      getWorkOrderLogList(_pageNum - 1, _workOrderLogSearchData.workOrderTypeId, _workOrderLogSearchData.workOrderStatus);
    }
    return () => { };
  }, [_pageNum]);

  useEffect(() => {
    if (_onload === 'loaded') {
      getProcessList(_processPageNum - 1, _processSearchData.processTypeId);
    }
    return () => { };
  }, [_processPageNum]);

  useEffect(() => {
    if (_onload === 'loaded') {
      setResultElementPageNum(() => { return 1 });
      getResultElementList(_resultElementPageNum - 1, _searchType);

      SerialCheckBox.current.checked = false;
    }
  }, [_currentWorkOrderLog, _currentProcessResult, _currentProcess]);

  // 공정결과
  const getProcessTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await processTypeApi.searchProcessType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('processTypeApi.searchProcessType : ', response);
      setProcessTypes(() => { return response.data });
    });
  };
  const getProcessResultList = async (page, processTypeId, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    let BodyToPost = { ..._processResultSearchData };
    delete BodyToPost.processTypeId;

    if (!clear && checkEmptyNull(processTypeId, false) && processTypeId !== 'all') {
      BodyToPost.processTypeId = Number(processTypeId);
    }

    if (clear) {
      BodyToPost = {
        companyId: userReducer.company.companyId,
        processTypeId: '',
        workOrderId: '',
        startDate: '',
        endDate: '',
        processCode: '',
        processName: '',
      }
    }

    await barcodeApi.searchLot(paging, BodyToPost).then(response => {
      if (response === undefined) return;
      console.log('barcodeApi.searchLot : ', response);

      setProcessResultList(() => { return response.data.content });
      setProcessResultTotalSize(() => { return response.data.totalElements });

      if (checkEmptyNull(_processResultSearchData.startDate, false) && checkEmptyNull(_processResultSearchData.endDate, false)) {
        if (_processResultSearchData.startDate > _processResultSearchData.endDate) {
          setProcessResultSearchData((prev) => {
            return { ...prev, scheduledStartDate: _processResultSearchData.endDate, scheduledEndDate: _processResultSearchData.startDate }
          })
        }
      }
    })
  }

  // 작업지시
  const getWorkOrderTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await workOrderTypeApi.searchWorkOrderType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderTypeApi.searchWorkOrderType : ', response);
      setWorkOrderTypes(() => { return response.data });
    });
  };
  const getWorkOrderLogList = async (page, workOrderTypeId, workOrderStatus, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    let BodyToPost = { ..._workOrderLogSearchData };
    delete BodyToPost.workOrderTypeId;

    if (!clear && checkEmptyNull(workOrderTypeId, false) && workOrderTypeId !== 'all') {
      BodyToPost.workOrderTypeId = Number(workOrderTypeId);
    }
    if (!clear && checkEmptyNull(workOrderStatus, false)) {
      switch (workOrderStatus) {
        case 'all': BodyToPost.workOrderStatus = ['work', 'pause', 'stop', 'end', 'cancel']; break;
        default: BodyToPost.workOrderStatus = [workOrderStatus]; break;
      }
    }
    if (!clear && checkEmptyNull(_workOrderLogSearchData.scheduledStartDate, false) && checkEmptyNull(_workOrderLogSearchData.scheduledEndDate, false)) {
      BodyToPost.scheduledStartDate = DateFormat(_workOrderLogSearchData.scheduledStartDate);
      BodyToPost.scheduledEndDate = DateFormat(_workOrderLogSearchData.scheduledEndDate);
      if (_workOrderLogSearchData.scheduledStartDate > _workOrderLogSearchData.scheduledEndDate) {
        BodyToPost.scheduledStartDate = DateFormat(_workOrderLogSearchData.scheduledEndDate);
        BodyToPost.scheduledEndDate = DateFormat(_workOrderLogSearchData.scheduledStartDate);
      }
    } else if (!clear && checkEmptyNull(_workOrderLogSearchData.scheduledStartDate, false) && !checkEmptyNull(_workOrderLogSearchData.scheduledEndDate, false)) {
      BodyToPost.scheduledStartDate = DateFormat(_workOrderLogSearchData.scheduledStartDate);
    } else if (!clear && checkEmptyNull(_workOrderLogSearchData.scheduledEndDate, false) && !checkEmptyNull(_workOrderLogSearchData.scheduledStartDate, false)) {
      BodyToPost.scheduledEndDate = DateFormat(_workOrderLogSearchData.scheduledEndDate);
    }

    if (clear) {
      BodyToPost = {
        companyId: userReducer.company.companyId,
        workOrderTypeId: '',
        workOrderStatus: 'all',
        scheduledStartDate: '',
        scheduledEndDate: '',
        workOrderCode: '',
        workOrderName: '',
      }
    }

    await workOrderLogApi.searchWorkOrderLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.searchWorkOrderLog : ', response);

      setWorkOrderLogList(() => { return response.data.data.content });
      setTotalSize(() => { return response.data.data.totalElements });

      if (checkEmptyNull(_workOrderLogSearchData.scheduledStartDate, false) && checkEmptyNull(_workOrderLogSearchData.scheduledEndDate, false)) {
        if (_workOrderLogSearchData.scheduledStartDate > _workOrderLogSearchData.scheduledEndDate) {
          setWorkOrderLogSearchData((prev) => {
            return { ...prev, scheduledStartDate: _workOrderLogSearchData.scheduledEndDate, scheduledEndDate: _workOrderLogSearchData.scheduledStartDate }
          })
        }
      }
    });
  }

  const getProcessList = async (page, processTypeId, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    let BodyToPost = { ..._processSearchData };
    delete BodyToPost.processTypeId;

    if (!clear && checkEmptyNull(processTypeId, false) && processTypeId !== 'all') {
      BodyToPost.processTypeId = Number(processTypeId);
    }

    if (clear) {
      BodyToPost = {
        companyId: userReducer.company.companyId,
        processTypeId: '',
        processCode: '',
        processName: '',
      }
    }

    await processApi.searchProcess(paging, BodyToPost).then(response => {
      if (response === undefined) return;
      console.log('processApi.searchProcess : ', response);

      setProcessList(() => { return response.data.content });
      setProcessTotalSize(() => { return response.data.totalElements });
    })
  }

  const getResultElementList = async (page, searchType, elementType) => {
    console.log('searchType : ', searchType);
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    let BodyToPost = {
      companyId: userReducer.company.companyId
    }
    if (searchType === 'workOrderLog') {
      if (checkNullObject(_currentWorkOrderLog, false)) {
        BodyToPost.workOrderId = _currentWorkOrderLog.workOrderLogId;
      } else {
        setResultElementList(() => { return [] });
        setResultElementTotalSize(() => { return 0 });
        return;
      }
    }
    if (searchType === 'processResult') {
      if (checkNullObject(_currentProcessResult, false)) {
        BodyToPost.processId = _currentProcessResult.processResultId;
      } else {
        setResultElementList(() => { return [] });
        setResultElementTotalSize(() => { return 0 });
        return;
      }
    }
    if (searchType === 'process') {
      if (checkNullObject(_currentProcess, false)) {
        BodyToPost.processId = _currentProcess.processId;
      } else {
        setResultElementList(() => { return [] });
        setResultElementTotalSize(() => { return 0 });
        return;
      }
    }
    if (searchType === 'element') {
      BodyToPost = {
        ...BodyToPost,
        ..._elementSearchData
      }
      if (checkEmptyNull(elementType, false) && elementType !== 'all') {
        BodyToPost.elementType = elementType;
      }
    }

    await barcodeApi.searchSerial(paging, BodyToPost).then(response => {
      if (response === undefined) return;
      console.log('barcodeApi.searchSerial : ', response);

      setResultElementList(() => { return response.data.content });
      setResultElementTotalSize(() => { return response.data.totalElements });
    })
  }

  // 검색 기준 변경
  const handleSearchType = (searchType) => {
    setSearchType(() => { return searchType });
  }

  // 검색 엔터 키
  const handleOnKeyUp = (e) => {
    if (e.key === 'Enter') actSearch();
  };

  /* 검색 */
  const actSearch = async () => {
    if (_searchType === 'workOrderLog') {
      setPageNum(() => { return 1 });
      await getWorkOrderLogList(0, _workOrderLogSearchData.workOrderTypeId, _workOrderLogSearchData.workOrderStatus);
    }
    if (_searchType === 'processResult') {
      setProcessResultPageNum(() => { return 1 });
      await getProcessResultList(0, _processResultSearchData.processTypeId);
    }
    if (_searchType === 'process') {
      setProcessPageNum(() => { return 1 });
      await getProcessList(0, _processSearchData.processTypeId);
    }
    if (_searchType === 'element') {
      setResultElementPageNum(() => { return 1 });
      await getResultElementList(0, _searchType);
    }
  };

  /* 초기화 */
  const actReset = async () => {
    if (_searchType === 'workOrderLog') {
      setWorkOrderLogSearchData(() => {
        return {
          companyId: userReducer.company.companyId,
          workOrderTypeId: '',
          workOrderStatus: 'all',
          scheduledStartDate: '',
          scheduledEndDate: '',
          workOrderCode: '',
          workOrderName: '',
        }
      })

      setPageNum(() => { return 1 });
      await getWorkOrderLogList(0, 'all', 'all', true);
    }
    if (_searchType === 'processResult') {
      setProcessResultSearchData(() => {
        return {
          companyId: userReducer.company.companyId,
          processTypeId: '',
          startDate: '',
          endDate: '',
          processCode: '',
          processName: '',
        };
      })

      setProcessResultPageNum(() => { return 1 });
      await getProcessResultList(0, 'all', true);
    }
    if (_searchType === 'process') {
      setProcessSearchData(() => {
        return {
          companyId: userReducer.company.companyId,
          processTypeId: '',
          processCode: '',
          processName: '',
        };
      })

      setProcessPageNum(() => { return 1 });
      await getProcessList(0, 'all', true);
    }
    if (_searchType === 'element') {
      setElementSearchData(() => {
        return {
          companyId: userReducer.company.companyId,
          elementTypeId: '',
          elementCode: '',
          elementName: '',
        };
      })

      setResultElementPageNum(() => { return 1 });
      await getResultElementList(0, _searchType, 'all', true);
    }
  };

  /* 물품 전체 선택 */
  const handleAllCheckBox = (e) => {
    const isChecked = e.target.checked;
    const printList = [...barcodeReducer.printList];

    if (isChecked) {
      const newData = _resultElementList.map((serial) => {
        const thisFindIndex = printList.findIndex((item) => item.processResultElementId === serial.processResultElementId);
        if (thisFindIndex !== -1) return null;
        else return serial;
      });
      const filterData = newData.filter((data) => data !== null);
      const returnData = filterData.map(serial => { return { ...serial, type: 'serial' } })
      dispatch(barcodeActions_setPrintList([...printList, ...returnData]))
      e.target.checked = true;
    } else {
      const filterData = printList.filter(data => _resultElementList.findIndex(element => element.processResultElementId === data.processResultElementId) === -1);
      dispatch(barcodeActions_setPrintList([...filterData]))
      e.target.checked = false;
    }
  }

  /* 물품 선택 */
  const handleCheckedItem = (serial, isChecked) => {
    const checkedItem = [...barcodeReducer.printList];
    // const checkedIndex = checkedItem.findIndex((content) => content.processResultElementId === serial.processResultElementId);
    const checkedIndex = checkedItem.findIndex((content) => content.type === 'serial' && content.processResultElementId === serial.processResultElementId);
    if (checkedIndex !== -1) {
      if (!isChecked) checkedItem.splice(checkedIndex, 1);
    } else {
      if (isChecked) checkedItem.push({ ...serial, type: 'serial' });
    }
    dispatch(barcodeActions_setPrintList(checkedItem))
  };

  return (
    <>
      <SerialSection searchType={_searchType}>
        <NavBar nav={'all'}
          title={
            <div className='Title'>
              <h4>Serial</h4>
              <div className='searchTypeButtons'>
                {[
                  { type: 'workOrderLog', name: '작업지시', },
                  { type: 'processResult', name: '공정결과', },
                  // { type: 'process', name: '공정', },
                  { type: 'element', name: '물품', },
                ].map(searchType => {
                  return (
                    <button key={searchType.type + '_searchType'} className={_searchType === searchType.type ? 'btn-inven-1' : 'btn-set'}
                      onClick={() => { handleSearchType(searchType.type) }}
                    >
                      {searchType.name} 기준
                    </button>
                  )
                })}
              </div>
            </div>
          }
          firstRow={
            <>
              {_searchType === 'workOrderLog' ?
                <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <select
                    className="SelectType"
                    value={_workOrderLogSearchData.workOrderTypeId}
                    onChange={(e) => {
                      setWorkOrderLogSearchData((prev) => {
                        return { ...prev, workOrderTypeId: e.target.value };
                      })
                      setPageNum(() => { return 1 });
                      getWorkOrderLogList(0, e.target.value, _workOrderLogSearchData.workOrderStatus)
                    }}
                  >
                    <option value="all">전체</option>
                    {_workOrderTypes.map((workOrderType) => {
                      return (
                        <option key={workOrderType.workOrderTypeId + '_workOrderType'} value={workOrderType.workOrderTypeId}>
                          {workOrderType.workOrderTypeName}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    className="SelectStatus"
                    value={_workOrderLogSearchData.workOrderStatus}
                    onChange={(e) => {
                      setWorkOrderLogSearchData((prev) => {
                        return { ...prev, workOrderStatus: e.target.value };
                      })
                      setPageNum(() => { return 1 });
                      getWorkOrderLogList(0, _workOrderLogSearchData.workOrderTypeId, e.target.value)
                    }}
                  >
                    {[
                      { optionValue: 'all', optionText: '전체' },
                      { optionValue: 'work', optionText: '작업중' },
                      { optionValue: 'pause', optionText: '일지정지' },
                      { optionValue: 'stop', optionText: '중단' },
                      { optionValue: 'end', optionText: '완료' },
                      { optionValue: 'cancel', optionText: '취소' },
                    ].map((workOrderStatus) => {
                      return (
                        <option key={workOrderStatus.optionValue + '_workOrderStatus'} value={workOrderStatus.optionValue}>
                          {workOrderStatus.optionText}
                        </option>
                      );
                    })}
                  </select>
                  <div className="SearchSection">
                    <input
                      type="date"
                      className="SelectDate"
                      value={_workOrderLogSearchData.scheduledStartDate}
                      onChange={(e) => {
                        setWorkOrderLogSearchData((prev) => {
                          return { ...prev, scheduledStartDate: e.target.value };
                        })
                      }}
                    />

                    <IconRightArrow />

                    <input
                      type="date"
                      className="SelectDate"
                      value={_workOrderLogSearchData.scheduledEndDate}
                      onChange={(e) => {
                        setWorkOrderLogSearchData((prev) => {
                          return { ...prev, scheduledEndDate: e.target.value };
                        })
                      }}
                    />
                  </div>
                </div>
                : null}
              {_searchType === 'processResult' ?
                <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <select
                    className="SelectType"
                    value={_processResultSearchData.processTypeId}
                    onChange={(e) => {
                      setProcessResultSearchData((prev) => {
                        return { ...prev, processTypeId: e.target.value };
                      })
                      setProcessResultPageNum(() => { return 1 });
                      getProcessResultList(0, e.target.value)
                    }}
                  >
                    <option value="all">전체</option>
                    {_processTypes.map((processType) => {
                      return (
                        <option key={processType.processTypeId + '_processType'} value={processType.processTypeId}>
                          {processType.processTypeName}
                        </option>
                      );
                    })}
                  </select>
                  <div className="SearchSection">
                    <input
                      type="date"
                      className="SelectDate"
                      value={_processResultSearchData.startDate}
                      onChange={(e) => {
                        setProcessResultSearchData((prev) => {
                          return { ...prev, startDate: e.target.value };
                        })
                      }}
                    />

                    <IconRightArrow />

                    <input
                      type="date"
                      className="SelectDate"
                      value={_processResultSearchData.endDate}
                      onChange={(e) => {
                        setProcessResultSearchData((prev) => {
                          return { ...prev, endDate: e.target.value };
                        })
                      }}
                    />
                  </div>
                </div>
                : null}
              {_searchType === 'process' ?
                <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <select
                    className="SelectType"
                    value={_processSearchData.processTypeId}
                    onChange={(e) => {
                      setProcessSearchData((prev) => {
                        return { ...prev, processTypeId: e.target.value };
                      })
                      setProcessPageNum(() => { return 1 });
                      getProcessList(0, e.target.value)
                    }}
                  >
                    <option value="all">전체</option>
                    {_processTypes.map((processType) => {
                      return (
                        <option key={processType.processTypeId + '_processType'} value={processType.processTypeId}>
                          {processType.processTypeName}
                        </option>
                      );
                    })}
                  </select>

                  <div className="SearchSection">
                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="processCode">공정코드</option>
                      </select>
                      <input
                        className="SearchBar detail"
                        placeholder="Search..."
                        value={_processSearchData.processCode}
                        onInput={(e) => {
                          setProcessSearchData((prev) => {
                            return { ...prev, processCode: e.target.value };
                          })
                        }}
                        onKeyUp={handleOnKeyUp}
                      />
                    </div>

                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="processName">공정이름</option>
                      </select>
                      <input
                        className="SearchBar detail"
                        placeholder="Search..."
                        value={_processSearchData.processName}
                        onInput={(e) => {
                          setProcessSearchData((prev) => {
                            return { ...prev, processName: e.target.value };
                          })
                        }}
                        onKeyUp={handleOnKeyUp}
                      />
                    </div>

                    <div className="ContentCBox">
                      <div className="ResetButton detail" onClick={actReset}>초기화</div>
                      <div className="DetailButton search"
                        style={{ backgroundColor: 'var(--ThirdBlue)', columnGap: '3px' }}
                        onClick={actSearch}
                      >
                        <SearchButtonIcon />검색
                      </div>
                    </div>
                  </div>
                </div>
                : null}
              {_searchType === 'element' ?
                <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <select
                    className="SelectType"
                    value={_elementSearchData.elementType}
                    onChange={(e) => {
                      setElementSearchData((prev) => {
                        return { ...prev, elementType: e.target.value };
                      })
                      setResultElementPageNum(() => { return 1 });
                      getResultElementList(0, _searchType, e.target.value)
                    }}
                  >
                    <option value="all">전체</option>
                    {[
                      { type: 'material', name: '자재', },
                      { type: 'semi', name: '부자재', },
                      { type: 'half', name: '반제품', },
                      { type: 'product', name: '완제품', },
                    ].map((elementType) => {
                      return (
                        <option key={elementType.type + '_elementType'} value={elementType.type}>
                          {elementType.name}
                        </option>
                      );
                    })}
                  </select>

                  <div className="SearchSection">
                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="elementName">물품이름</option>
                      </select>
                      <input
                        className="SearchBar detail"
                        placeholder="Search..."
                        value={_elementSearchData.elementName}
                        onInput={(e) => {
                          setElementSearchData((prev) => {
                            return { ...prev, elementName: e.target.value };
                          })
                        }}
                        onKeyUp={handleOnKeyUp}
                      />
                    </div>

                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="elementCode">물품코드</option>
                      </select>
                      <input
                        className="SearchBar detail"
                        placeholder="Search..."
                        value={_elementSearchData.elementCode}
                        onInput={(e) => {
                          setElementSearchData((prev) => {
                            return { ...prev, elementCode: e.target.value };
                          })
                        }}
                        onKeyUp={handleOnKeyUp}
                      />
                    </div>

                    <div className="ContentCBox">
                      <div className="ResetButton detail" onClick={actReset}>초기화</div>
                      <div className="DetailButton search"
                        style={{ backgroundColor: 'var(--ThirdBlue)', columnGap: '3px' }}
                        onClick={actSearch}
                      >
                        <SearchButtonIcon />검색
                      </div>
                    </div>
                  </div>
                </div>
                : null}
            </>
          }
          secondRow={
            <>
              {_searchType === 'workOrderLog' ?
                <div className="SearchSection">
                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="workOrderCode">작업코드</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={_workOrderLogSearchData.workOrderCode}
                      onInput={(e) => {
                        setWorkOrderLogSearchData((prev) => {
                          return { ...prev, workOrderCode: e.target.value };
                        })
                      }}
                      onKeyUp={handleOnKeyUp}
                    />
                  </div>

                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="workOrderName">작업이름</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={_workOrderLogSearchData.workOrderName}
                      onInput={(e) => {
                        setWorkOrderLogSearchData((prev) => {
                          return { ...prev, workOrderName: e.target.value };
                        })
                      }}
                      onKeyUp={handleOnKeyUp}
                    />
                  </div>

                  <div className="ContentCBox">
                    <div className="ResetButton detail" onClick={actReset}>초기화</div>
                    <div className="DetailButton search"
                      style={{ backgroundColor: 'var(--ThirdBlue)', columnGap: '3px' }}
                      onClick={actSearch}
                    >
                      <SearchButtonIcon />검색
                    </div>
                  </div>
                </div>
                : null}
              {_searchType === 'processResult' ?
                <div className="SearchSection">
                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="processCode">공정코드</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={_processResultSearchData.processCode}
                      onInput={(e) => {
                        setProcessResultSearchData((prev) => {
                          return { ...prev, processCode: e.target.value };
                        })
                      }}
                      onKeyUp={handleOnKeyUp}
                    />
                  </div>

                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="processName">공정이름</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={_processResultSearchData.processName}
                      onInput={(e) => {
                        setProcessResultSearchData((prev) => {
                          return { ...prev, processName: e.target.value };
                        })
                      }}
                      onKeyUp={handleOnKeyUp}
                    />
                  </div>

                  <div className="ContentCBox">
                    <div className="ResetButton detail" onClick={actReset}>초기화</div>
                    <div className="DetailButton search"
                      style={{ backgroundColor: 'var(--ThirdBlue)', columnGap: '3px' }}
                      onClick={actSearch}
                    >
                      <SearchButtonIcon />검색
                    </div>
                  </div>
                </div>
                : null}
            </>
          }
        />

        <section className='resultSection'>
          {_searchType === 'workOrderLog' ?
            <WorkOrderLog workOrderLogList={_workOrderLogList} currentWorkOrderLog={_currentWorkOrderLog} currentWorkOrderLogEvent={setCurrentWorkOrderLog} />
            : null}

          {_searchType === 'processResult' ?
            <ProcessResult processResultList={_processResultList} currentProcessResult={_currentProcessResult} currentProcessResultEvent={setCurrentProcessResult} />
            : null}

          {_searchType === 'process' ?
            <Process processList={_processList} currentProcess={_currentProcess} currentProcessEvent={setCurrentProcess} />
            : null}

          <TableSection content={
            <table>
              <colgroup>
                <col width="50px"></col>
                <col></col><col></col><col></col><col></col><col></col><col></col>
                <col width="30%"></col>
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkBox"
                      ref={SerialCheckBox}
                      name="allCheckBox"
                      onChange={handleAllCheckBox}
                    />
                  </th>
                  {[
                    { name: '물품코드', color: '' },
                    { name: '물품이름', color: '' },
                    { name: '목표 생산량', color: '' },
                    { name: '총 생산량', color: 'var(--MainBlue)' },
                    { name: '성공수량', color: 'var(--MainGreen)' },
                    { name: '실패수량', color: 'var(--MainRed)' },
                  ].map((key, index) => {
                    return <th key={index + '_key'} style={{ backgroundColor: key.color }}>{key.name}</th>
                  })}
                  <th style={{ backgroundColor: 'var(--ThirdRed)' }}>
                    불량<br />
                    [수량] 사유
                  </th>
                </tr>
              </thead>
              <tbody>
                {checkNullArray(_resultElementList, []).map((serial, index) => {
                  const completedAmount = checkEmptyNull(BigNumber(checkEmptyNull(serial.makeAmount, 0))
                    .plus(BigNumber(checkEmptyNull(serial.failedAmount, 0))).toNumber(), 0).toLocaleString();

                  return (
                    <tr key={index + '_elements'}>
                      <td style={{ minWidth: '50px', width: '50px' }}>
                        <input
                          type="checkBox"
                          name="stockLogs"
                          data-key={serial.processResultElementId}
                          checked={(() => {
                            const checkedId = checkNullArray(barcodeReducer.printList, []).findIndex((content) => content.type === 'serial' && content.processResultElementId === serial.processResultElementId);
                            if (checkedId !== -1) return true;
                            else return false;
                          })()}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            handleCheckedItem(serial, isChecked);
                          }}
                          onClick={(e) => { e.stopPropagation() }}
                        />
                      </td>
                      <td className={serial.elementName.startsWith('*') ? 'outsourcingText' : null}>{serial.elementName}</td>
                      <td>{serial.elementCode}</td>
                      <td className='cell-amount'>{checkEmptyNull(serial.amount, 0).toLocaleString()}</td>
                      <td className='cell-amount'>{completedAmount.toLocaleString()}</td>
                      <td className='cell-amount'>{checkEmptyNull(serial.makeAmount, 0).toLocaleString()}</td>
                      <td className='cell-amount'>{checkEmptyNull(serial.failedAmount, 0).toLocaleString()}</td>
                      <td>
                        {checkNullArray(serial.processResultElementFailedReasons, []).map((failedReason, failedReasonIndex) => {
                          return (
                            <div key={failedReasonIndex + '_failedReason'} className='failedReason'>
                              <b>[ {checkEmptyNull(failedReason.amount, 0).toLocaleString()} ]</b>
                              <span>{failedReason.failedReason}</span>
                            </div>
                          )
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          } />
        </section>

        <div className='paginationSection'>
          {_searchType === 'workOrderLog' ?
            <PagingComponent
              page={_pageNum}
              count={_totalSize}
              size={10}
              pageEvent={(page) => {
                console.log('page : ', page);
                setPageNum(() => { return page; });
              }}
            /> : null}
          {_searchType === 'processResult' ?
            <PagingComponent
              page={_processResultPageNum}
              count={_processResultTotalSize}
              size={10}
              pageEvent={(page) => {
                console.log('page : ', page);
                setProcessResultPageNum(() => { return page; });
              }}
            /> : null}
          <PagingComponent
            page={_resultElementPageNum}
            count={_resultElementTotalSize}
            size={10}
            pageEvent={(page) => {
              console.log('page : ', page);
              setResultElementPageNum(() => { return page; });
            }}
          />
        </div>
      </SerialSection>
    </>
  );
};

export default Serial;