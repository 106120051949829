export const mallMyQnAActions_setMallQnAStatus = (status) => {
  return {
    type: 'SETMALLMYQNASTATUS',
    payload: status,
  };
};
export const mallMyQnAActions_setStartDate = (date) => {
  return {
    type: 'SETMALLMYQNASTARTDATE',
    payload: date,
  };
};
export const mallMyQnAActions_setEndDate = (date) => {
  return {
    type: 'SETMALLMYQNAENDDATE',
    payload: date,
  };
};
export const mallMyQnAActions_setSearchOption = (searchOption) => {
  return {
    type: 'SETMALLMYQNASEARCHOPTION',
    payload: searchOption,
  };
};
export const mallMyQnAActions_setSearchText = (searchText) => {
  return {
    type: 'SETMALLMYQNASEARCHTEXT',
    payload: searchText,
  };
};
export const mallMyQnAActions_setPageNumber = (pageNumber) => {
  return {
    type: 'SETMALLMYQNAPAGENUMBER',
    payload: pageNumber,
  };
};
export const mallMyQnAActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETMALLMYQNATOTALSIZE',
    payload: totalSize,
  };
};
export const mallMyQnAActions_setIsPushedSearchButton = (
  isPushedSearchButton,
) => {
  return {
    type: 'SETMALLMYQNAISPUSHEDSEARCHBUTTON',
    payload: isPushedSearchButton,
  };
};
export const mallMyQnAActions_setReset = () => {
  return {
    type: 'SETMALLMYQNARESET',
  };
};
