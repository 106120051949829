import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const DelButton = styled.div`
  height: 15px;
  margin: 0px auto;
  width: 15px;
  div {background-color: var(--MainRed); cursor: pointer; height: 15px; width: 15px;}
`;

const UpdateWorkOrderRow = (props) => {
  const [ProcessData, setProcessData] = useState(props.content);

  const [_processNumberArray, setProcessNumberArray] = useState(() => {
    const numberArray = [];
    for (let i = 1; i < props.processLength + 1; i++) {
      numberArray.push(i);
    }
    return numberArray;
  });

  const [ProcessPutItems, setProcessPutItems] = useState(checkNullArray(props.content.processElementList, []).filter((thisItem) => thisItem.processElementType === 'Put'));
  const [ProcessMakeItems, setProcessMakeItems] = useState(checkNullArray(props.content.processElementList, []).filter((thisItem) => thisItem.processElementType === 'Make'));

  const [_rowLength, setRowLength] = useState(ProcessPutItems.length > ProcessMakeItems.length ? ProcessPutItems.length : ProcessMakeItems.length);

  useEffect(() => {
    setProcessData(() => { return props.content; });

    setProcessNumberArray(() => {
      const numberArray = [];
      for (let i = 1; i < props.processLength + 1; i++) {
        numberArray.push(i);
      }
      return numberArray;
    });

    const PutItems = checkNullArray(props.content.processElementList, []).filter((thisItem) => thisItem.processElementType === 'Put');
    const MakeItems = checkNullArray(props.content.processElementList, []).filter((thisItem) => thisItem.processElementType === 'Make');
    setProcessPutItems(() => { return PutItems; });
    setProcessMakeItems(() => { return MakeItems; });

    setRowLength(PutItems.length > MakeItems.length ? PutItems.length : MakeItems.length);
  }, [props]);

  const returnItems = () => {
    let returnArray = [];
    for (let i = 1; i < _rowLength; i++) {
      returnArray.push(
        <tr key={i + '_productRows'}>
          {ProcessMakeItems[i] !== undefined ? (
            <Fragment>
              <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                {checkNullObject(ProcessMakeItems[i].element, false) ? checkEmptyNull(ProcessMakeItems[i].element.elementName, '') : checkEmptyNull(ProcessMakeItems[i].elementName, '')}
              </td>
              <td>{checkNullObject(ProcessMakeItems[i].element, false) ? checkEmptyNull(ProcessMakeItems[i].element.elementCode, '') : checkEmptyNull(ProcessMakeItems[i].elementCode, '')}</td>
              <td>{checkEmptyNull(ProcessMakeItems[i].amount, 0).toLocaleString()}</td>
            </Fragment>
          ) : (
            <Fragment>
              <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
            </Fragment>
          )}
          {ProcessPutItems[i] !== undefined ? (
            <Fragment>
              <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                {checkNullObject(ProcessPutItems[i].element, false) ? checkEmptyNull(ProcessPutItems[i].element.elementName, '') : checkEmptyNull(ProcessPutItems[i].elementName, '')}
              </td>
              <td>{checkNullObject(ProcessPutItems[i].element, false) ? checkEmptyNull(ProcessPutItems[i].element.elementCode, '') : checkEmptyNull(ProcessPutItems[i].elementCode, '')}</td>
              <td>{checkEmptyNull(ProcessPutItems[i].amount, 0).toLocaleString()}</td>
            </Fragment>
          ) : (
            <Fragment>
              <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
            </Fragment>
          )}
        </tr>,
      );
    }
    return returnArray;
  };

  return (
    <Fragment>
      <tr data-key={ProcessData.processId} style={{ borderBottom: '1px solid var(--gray-200)' }}>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ minWidth: '50px', width: '50px' }}>
          <DelButton onClick={() => { props.delEvent(props.index, ProcessData); }}><CloseButtonIcon /></DelButton>
        </td>
        {/* <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ minWidth: '60px', width: '60px' }}>
          <input
            type="checkBox"
            name="processes"
            checked={checkEmptyNull(ProcessData.target, false) && ProcessData.target === true && true}
            onChange={(e) => {
              const isChecked = e.target.checked;
              props.handleCheckedItem(props.index, ProcessData, isChecked);

              if (isChecked) {
                document.getElementsByName('processes').forEach((thisAttr) => {
                  thisAttr.checked = false;
                });
                e.target.checked = true;
              } else {
                e.target.checked = false;
              }
            }}
            onClick={(e) => { e.stopPropagation(); }}
          />
        </td> */}
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ minWidth: '80px', width: '80px' }}>
          <select
            name="processNumber"
            value={ProcessData.processNumber}
            onInput={(e) => { props.handleNumberEvent(e, props.index, ProcessData); }}
          >
            {_processNumberArray.map((thisNumber) => {
              return (<option key={thisNumber} value={thisNumber}>{thisNumber}</option>);
            })}
          </select>
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{ProcessData.processTypeName}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{ProcessData.processCode}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{ProcessData.processName}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{ProcessData.place?.placeName}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{ProcessData.remark || ''}</td>

        {ProcessMakeItems[0] !== undefined ? (
          <Fragment>
            <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
              {checkNullObject(ProcessMakeItems[0].element, false) ? checkEmptyNull(ProcessMakeItems[0].element.elementName, '') : checkEmptyNull(ProcessMakeItems[0].elementName, '')}
            </td>
            <td>{checkNullObject(ProcessMakeItems[0].element, false) ? checkEmptyNull(ProcessMakeItems[0].element.elementCode, '') : checkEmptyNull(ProcessMakeItems[0].elementCode, '')}</td>
            <td>{checkEmptyNull(ProcessMakeItems[0].amount, 0).toLocaleString()}</td>
          </Fragment>
        ) : (
          <Fragment>
            <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
          </Fragment>
        )}
        {ProcessPutItems[0] !== undefined ? (
          <Fragment>
            <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
              {checkNullObject(ProcessPutItems[0].element, false) ? checkEmptyNull(ProcessPutItems[0].element.elementName, '') : checkEmptyNull(ProcessPutItems[0].elementName, '')}
            </td>
            <td>{checkNullObject(ProcessPutItems[0].element, false) ? checkEmptyNull(ProcessPutItems[0].element.elementCode, '') : checkEmptyNull(ProcessPutItems[0].elementCode, '')}</td>
            <td>{checkEmptyNull(ProcessPutItems[0].amount, 0).toLocaleString()}</td>
          </Fragment>
        ) : (
          <Fragment>
            <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
          </Fragment>
        )}
      </tr>
      {_rowLength > 1 && returnItems()}
    </Fragment>
  );
};

export default UpdateWorkOrderRow;
