export const JuncontentsReducer_setContractContents = (thisContract) => {
  return {
    type: 'SETORDERSCONTENTS',
    payload: thisContract,
  };
};
export const JuncontentsReducer_setProcurement = (thisProcurement) => {
  return {
    type: 'SETPLACINGORDERSCONTENTS',
    payload: thisProcurement,
  };
};
export const JuncontentsReducer_setPlacingAccount = (thisPlacingAccount) => {
  return {
    type: 'SETPLACINGACCOUNT',
    payload: thisPlacingAccount,
  };
};

export const JuncontentsReducer_showProcessList = (thisProcessData) => {
  return {
    type: 'SHOWPROCESSLIST',
    payload: thisProcessData,
  };
};

export const JuncontentsReducer_setWorkOrderId = (thisContents) => {
  return {
    type: 'SETWORKORDERID',
    payload: thisContents,
  };
};
export const JuncontentsReducer_setDelContents = (thisContents) => {
  return {
    type: 'SETDELCONTENTS',
    payload: thisContents,
  };
};
export const JuncontentsReducer_setWorkOrderContent = (thisContents) => {
  return {
    type: 'SETWORKORDERCONTENT',
    payload: thisContents,
  };
};

export const JuncontentsReducer_setworkOrderLogAmount = (thisContents) => {
  return {
    type: 'SETWORKORDERLOGAMOUNT',
    payload: thisContents,
  };
};
export const JuncontentsReducer_setCalenderUpdateContent = (thisContents) => {
  return {
    type: 'SETCALENDERUPDATECONTENT',
    payload: thisContents,
  };
};
// 엑셀 다운로드

export const JuncontentsReducer_setEquipmentAttributeList = (
  thisAttributes,
) => {
  return {
    type: 'SETEQUIPMENTATTRIBUTELIST',
    payload: thisAttributes,
  };
};
/* 거래처 타입 */
export const JunContentsReducer_setAccountAttributeType = (thisAttribute) => {
  return {
    type: 'EXCELSETACCOUNTATTRIBUTETYPE',
    payload: thisAttribute,
  };
};

export const JunContentsReducer_reset = () => {
  return {
    type: 'SEARCHRESET',
  };
};

export const JunContentsReducer_setProductType = (thisType) => {
  return {
    type: 'EXCELSETPRODUCTTYPE',
    payload: thisType,
  };
};

export const JunContentsReducer_setCurrentFamilyId = (thisFamilyId) => {
  return {
    type: 'EXCELSETCURRENTFAMILYID',
    payload: thisFamilyId,
  };
};

export const JunContentsReducer_setAllFamiles = (thisFamily) => {
  return {
    type: 'EXCELSETALLFAMILIES',
    payload: thisFamily,
  };
};

export const JunContentsReducer_setFirstFamily = (thisFamily) => {
  return {
    type: 'EXCELSETFIRSTFAMILY',
    payload: thisFamily,
  };
};

export const JunContentsReducer_setFirstFamilies = (thisFamilies) => {
  return {
    type: 'EXCELSETFIRSTFAMILIES',
    payload: thisFamilies,
  };
};

export const JunContentsReducer_setSecondFamily = (thisFamily) => {
  return {
    type: 'EXCELSETSECONDFAMILY',
    payload: thisFamily,
  };
};

export const JunContentsReducer_setSecondFamilies = (thisFamilies) => {
  return {
    type: 'EXCELSETSECONDFAMILIES',
    payload: thisFamilies,
  };
};

export const JunContentsReducer_setThirdFamily = (thisFamily) => {
  return {
    type: 'EXCELSETTHIRDFAMILY',
    payload: thisFamily,
  };
};

export const JunContentsReducer_setThirdFamilies = (thisFamilies) => {
  return {
    type: 'EXCELSETTHIRDFAMILIES',
    payload: thisFamilies,
  };
};

export const JunContentsReducer_setWorkStatus = (thisStatus) => {
  return {
    type: 'EXCELSETWORKSTATUS',
    payload: thisStatus,
  };
};

export const JunContentsReducer_setStartDate = (thisDate) => {
  return {
    type: 'EXCELSETSTARTDATE',
    payload: thisDate,
  };
};

export const JunContentsReducer_setEndDate = (thisDate) => {
  return {
    type: 'EXCELSETENDDATE',
    payload: thisDate,
  };
};

export const JunContentsReducer_setSearchOption = (thisOption) => {
  return {
    type: 'EXCELSETSEARCHOPTION',
    payload: thisOption,
  };
};

export const JunContentsReducer_setSearchText = (thisText) => {
  return {
    type: 'EXCELSETSEARCHTEXT',
    payload: thisText,
  };
};

// 일정표 액션

export const JunContentsReducer_setSelectType = (thisText) => {
  return {
    type: 'SETSELECTTYPE',
    payload: thisText,
  };
};

export const JunContentsReducer_setNote1Text = (thisText) => {
  return {
    type: 'SETNOTE1TEXT',
    payload: thisText,
  };
};
export const JunContentsReducer_setNote2Text = (thisText) => {
  return {
    type: 'SETNOTE2TEXT',
    payload: thisText,
  };
};

export const JunContentsReducer_setCalenderStatus = (thisStatus) => {
  return {
    type: 'SETCALENDERSTATUS',
    payload: thisStatus,
  };
};
