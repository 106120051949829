import styled from 'styled-components';

export const Section = styled.main`
  grid-template-rows: auto 120px minmax(calc(100% - 186px), calc(100% - 286px));

  input[type="text"] {
    border: 1px solid var(--gray-200);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 5px 10px;
    width: 180px;
  }

  button.btn-save {width: 60px;}
  button.create {background-color: var(--MainBlue);}
  button.update {background-color: var(--MainNavy);}
  button.delete {background-color: var(--MainRed);}

  .section-form {
    align-content: center;
    box-sizing: border-box;
    display: grid;
    gap: 5px;
    height: 100%;
    padding: 20px;
    width: 100%;
    
    &>div {
      align-items: center;
      display: flex;
      gap: 10px;
      height: fit-content;
      justify-content: flex-start;
    }
  }

  .TableSection {
    td, th {
      min-width: unset;
      padding-inline: 8px;
    }
    li {
      font-size: 0.8em;
      text-align: left;
    }
  }
`;

export const SearchBar = styled.div`
  display: flex;
  padding: 20px;
  height: fit-content;
`;
export const Wrapper = styled.div`
  padding: 0 50px;
`;

export const Title = styled.div`
  padding: 20px 0;
`;

export const ContentWrapper = styled.div`
  display: flex;
  padding: 10px;
`;

export const DataWrapper = styled.div`
  width: 80%;
  height: 400px;
  overflow: auto;
  padding: 0 20px 0 0;
`;

export const DataListWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const DataList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ListTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 10px;
  padding: 10px 0;
  width: 200px;
  height: fit-content;
`;

export const ListContent = styled.div`
  font-weight: 700;
  margin-right: 10px;
  padding: 0 0 10px 0;
`;

export const ListBtnWrapper = styled.div`
  display: flex;
  padding: 10px;
`;
