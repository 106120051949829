import styled from 'styled-components';

export const MasterUserManagementComponent = styled.section`
  & .SearchSection select.SearchOption.detail { // select
    font-weight: normal;
    width: fit-content;

    &.company { // 회사 선택 select
      color: var(--MainNavy);
      font-weight: 600;
    }
  }
  & .SearchSection div.DetailButton.search { // 검색 버튼
    background-color: var(--ThirdBlue);
    column-gap: 3px;
  }

  & .TableSection {
    & table {
      & td {
        font-size: 0.9em;

        & button {
          margin: auto;
          min-width: unset;
          width: fit-content;
        }
        & p {
          text-align: left;
          width: 100%;

          & span {
            background-color: var(--MainBlue);
            display: inline-block;
            color: var(--white);
            padding-inline: 5px;
          }
        }
      }

      & tr.row-update {
        border-bottom: 2px solid var(--MainNavy);
      }
    }
  }
`;