import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { workActions_setWorkCase } from 'store/modules/actions/default/workActions';

import { WS_CLIENT } from 'preferences/server/constants';
import { getCookie } from 'preferences/cookie/cookie';

import { checkNullArray } from 'components/checkValues/checkValues';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import StatusDivButton from 'components/buttons/StatusDivButton';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 85%;
  justify-content: flex-start;
  max-width: 1100px;
  min-width: 200px;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: inherit;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100% - 55px);
  justify-content: space-between;
  padding: 20px;
  row-gap: 20px;
  width: 100%;
`;
const TaskTable = styled.table`
  border-collapse: separate;
  border-spacing: 5px;
  margin: 0px auto;
  width: 95%;

  th {
    background-color: var(--MainNavy);
    color: var(--white);
    font-size: 1.2em;
    padding-block: 5px;
  }
  td {
    border-radius: 10px;
    color: var(--white);
    cursor: pointer;
    font-size: 1.5em;
    font-weight: 600;
    height: 100px;
    text-align: center;

    -ms-user-select: none; 
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    &.clear {background-color: var(--gray-200); height: 50px;}
    &.cutend {background-color: #BCE55C;}
    &.weldingend {background-color: #9FC93C;}
    &.lastend {background-color: #689900;}

    &:hover {opacity: 0.6;}
  }
`;

const StatusSection = styled.div`
  align-items: center;
  box-sizing: border-box;
  column-gap: 10px;
  display: flex;
  height: 160px;
  justify-content: center;
  width: 100%;
`;

const UriMultiStatusModal = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { userReducer, workReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const { workOrderLog, processList } = props;

  const taskTypeList = ['BF', 'CP', 'SF', 'MF', 'GB', 'MC'];

  const [_authority, setAuthority] = useState([]);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('406') === true || // calendar
      thisItem.authority.startsWith('501') === true || // workOrderLog
      thisItem.authority.startsWith('701') === true, // monitoring
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);
  }, [])

  /* ====================================================================== #4 */
  const setTaskName = (task) => {
    switch (task) {
      case 'cutend': return '절단';
      case 'weldingend': return '용접';
      case 'lastend': return '마무리';
      default: return '초기화';
    }
  }

  /* ====================================================================== #5 */
  const publishTask = async (BodyToWS) => {
    try {
      WS_CLIENT.publish({
        destination: '/pub/task',
        headers: { 'auth-token': getCookie('accessToken') && getCookie('accessToken').replace('Bearer ', '') },
        body: JSON.stringify(BodyToWS),
      });
    } catch (error) {
      console.log('client.publish/pub/task - error : ', error);
    }
  }
  const handleTaskStatus = (task, taskType) => {
    if (_authority.indexOf('501-2') !== -1 || _authority.indexOf('501-3') !== -1 || _authority.indexOf('501-4') !== -1 || _authority.indexOf('501-5') !== -1) {
      if (workReducer.connectStatus !== 'connect') return alert(`
  연결이 불안정합니다.
  네트워크 상태 확인 후 다시 시도하여 주시기 바랍니다.
      `);
      if (processList.length < 1) return;
      dispatch(workActions_setWorkCase('task'));

      let checkStatus = false;
      checkNullArray(processList, []).forEach((item) => {
        if (!['work', 'end', 'cancel'].includes(item.processStatus)) checkStatus = true;
      });
      if (checkStatus) {
        const alertText = `
  공정상태를 확인해주세요.
  공정 전체가 [ 작업중 ]이여야 합니다.
        `;
        return alert(alertText);
      } else {
        checkNullArray(processList, []).forEach(async (processLog, index) => {
          const taskList = checkNullArray(processLog.taskLogList, []).map(taskLog => {
            const taskLogType = taskLog.taskType.split('_')[0];
            if (taskLogType === taskType) {
              const returnData = { ...taskLog, taskStatus: task }
              return returnData;
            } else {
              return null;
            }
          })
          const taskSocketDtos = taskList.filter(item => item !== null);

          const BodyToWS = {
            roomId: userReducer.company.companyId,
            type: '/task',
            workOrderLogId: workOrderLog.workOrderLogId,
            processLogId: processLog.processLogId,
            processStatus: processLog.processstatus,
            taskSocketDtos: taskSocketDtos,
          }
          console.log('client.publish/pub/task - body : ', JSON.stringify(BodyToWS));
          await publishTask(BodyToWS);

          if (index === checkNullArray(processList, []).length - 1) props.close();
        })
      }
    } else alert('권한이 없습니다.')
  };

  const publishProcess = async (BodyToWS) => {
    try {
      WS_CLIENT.publish({
        destination: '/pub/processes',
        headers: { 'auth-token': getCookie('accessToken') && getCookie('accessToken').replace('Bearer ', ''), },
        body: JSON.stringify(BodyToWS),
      });
    } catch (error) {
      console.log('client.publish/pub/processes - error : ', error);
    } finally {
      setTimeout(() => { props.close() }, 1000);
    }
  }
  const handleProcessStatus = async (process) => {
    if (_authority.indexOf('501-2') !== -1 || _authority.indexOf('501-3') !== -1 || _authority.indexOf('501-4') !== -1 || _authority.indexOf('501-5') !== -1) {
      if (workReducer.connectStatus !== 'connect') return alert(`
  연결이 불안정합니다.
  네트워크 상태 확인 후 다시 시도하여 주시기 바랍니다.
      `);
      if (processList.length < 1) return;
      dispatch(workActions_setWorkCase('process'));

      const BodyToWS = {
        roomId: userReducer.company.companyId,
        type: '/processes',
        workOrderLogId: workOrderLog.workOrderLogId,
        processLogIds: processList.map((item) => item.processLogId),
        processStatus: process.status,
      };
      console.log('client.publish/pub/processes - body : ', JSON.stringify(BodyToWS));

      if (process.status === 'end') {
        let checkStatus = false;
        let checkTaskStatus = false;
        processList.forEach((item) => {
          if (['wait', 'pause', 'stop'].includes(item.processStatus)) return checkStatus = true;
          item.taskLogList.forEach(taskLog => { if (taskLog.taskStatus !== 'lastend') return checkTaskStatus = true; })
        });

        if (checkStatus) {
          const alertText = `
  공정상태를 확인해주세요.
  공정 전체가 [ 작업중 ]이여야 합니다.
          `;
          return alert(alertText);
        } else {
          if (checkTaskStatus) {
            const confirmText = `
  마무리가 안 된 작업이 있습니다.
  계속 진행하시겠습니까?
            `;
            if (window.confirm(confirmText)) await publishProcess(BodyToWS);
            else return;
          } else {
            return;
          }
        }
      } else {
        await publishProcess(BodyToWS);
      }
    } else alert('권한이 없습니다.')
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>일괄 상태 변경</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <TaskTable>
                <thead>
                  <tr>
                    <th>B/F</th><th>C/P</th><th>S/F</th><th>M/F</th><th>G/B</th><th>M/C</th>
                  </tr>
                </thead>
                <tbody>
                  {['clear', 'cutend', 'weldingend', 'lastend'].map(task => {
                    return (<tr key={task + '_task'}>
                      {taskTypeList.map(taskType => {
                        return (
                          <td key={taskType + '_taskType'} className={task}
                            onClick={(e) => {
                              e.preventDefault();
                              switch (task) {
                                case 'clear':
                                  if (_authority.indexOf('501-2') !== -1 && _authority.indexOf('501-3') !== -1 && _authority.indexOf('501-4') !== -1 && _authority.indexOf('501-5') !== -1) handleTaskStatus(task, taskType);
                                  else alert('권한이 없습니다.')
                                  break;
                                case 'cutend':
                                  if (_authority.indexOf('501-3') !== -1) handleTaskStatus(task, taskType);
                                  else alert('권한이 없습니다.')
                                  break;
                                case 'weldingend':
                                  if (_authority.indexOf('501-4') !== -1) handleTaskStatus(task, taskType);
                                  else alert('권한이 없습니다.')
                                  break;
                                case 'lastend':
                                  if (_authority.indexOf('501-5') !== -1) handleTaskStatus(task, taskType);
                                  else alert('권한이 없습니다.')
                                  break;
                                default: return;
                              }
                            }}
                          >
                            {setTaskName(task)}
                          </td>
                        );
                      })}
                    </tr>)
                  })}
                </tbody>
              </TaskTable>

              <StatusSection>
                <StatusDivButton status={'work'} statusEvent={handleProcessStatus}></StatusDivButton>
                <StatusDivButton status={'pause'} statusEvent={handleProcessStatus}></StatusDivButton>
                <StatusDivButton status={'stop'} statusEvent={handleProcessStatus}></StatusDivButton>
                <StatusDivButton status={'end'} statusEvent={handleProcessStatus}></StatusDivButton>
              </StatusSection>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default UriMultiStatusModal;
