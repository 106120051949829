const initialState = {
  IfisPageCode: [],

  updateCompany: {},
  deleteCompany: {},

  pageNumber: 1,
  totalSize: 0,

  company: {
    cluster: '',
    searchText: '',
    pageNumber: 1,
    totalSize: 0,
  },

  qna: {
    currentCompany: 'all',
    inquiryStatus: 'all',

    searchOption: '',
    searchText: '',

    isPushedSearchButton: false,

    pageNumber: 1,
    totalSize: 0,
  },

  user: {
    companyId: 'all',
    name: '',
    pageNumber: 1,
    totalSize: 0,
  }
};

const operatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETIFISPAGECODE': return { ...state, IfisPageCode: action.payload };

    case 'SETUPDATEIFISCOMPANY': return { ...state, updateCompany: action.payload };
    case 'SETDELETEIFISCOMPANY': return { ...state, deleteCompany: action.payload };

    case 'SETOPERATORPAGENUMBER': return { ...state, pageNumber: action.payload };
    case 'SETOPERATORTOTALSIZE': return { ...state, totalSize: action.payload };

    case 'SETOPERATORCOMPANY': return { ...state, company: action.payload };
    case 'SETOPERATORQNA': return { ...state, qna: action.payload };
    case 'SETOPERATORUSER': return { ...state, user: action.payload };

    default: return state;
  }
}

export default operatorReducer;