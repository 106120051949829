import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

import { sensorDataApi } from 'api/apis/sensorDataApi';
import { equipmentApi } from 'api/apis/equipmentApi';

import HsSensorDataModal from './modal/HsSensorDataModal';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

const MainSection = styled.main`
  position: relative;
`;

const DataSection = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 1fr 1fr;
  width: 100%;
`;

const DataTable = styled.table`
  background-color: var(--white);
  border-collapse: collapse;
  min-height: 100%;
  min-width: 100%;
  table-layout: fixed;
  width: 100%;

  & td {
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    font-size: 1.8em;
    font-weight: 600;
    min-width: unset;
    padding: 10px;
    text-align: center;
    width: unset;
    word-break: break-all;
  }
  & td.title { background-color: var(--MainNavy); color: var(--white); font-size: 2em;}

  & thead {
    th {
      background-color: var(--MainNavy);
      border: 1px solid var(--gray-200);
      color: var(--white);
      font-size: 1.5em;
      height: 50px;
    }
  }
`;

const TempData = styled.td`
  cursor: pointer;

  &.waiting {background-color: var(--gray-200); color: var(--white);}
  &.normal {background-color: var(--MainGreen); color: var(--white);}
  &.high {background-color: var(--MainRed); color: var(--white);}
  &.low {background-color: var(--ThirdBlue); color: var(--white);}
`;

const PressureData = styled.td`
  &.waiting {background-color: var(--gray-200); color: var(--white);}
  &.normal {background-color: var(--MainGreen); color: var(--white);}
  &.high {background-color: var(--MainRed); color: var(--white);}
  &.low {background-color: var(--ThirdBlue); color: var(--white);}
`;

const ColorSection = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;
const ColorBox = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  padding: 0px 20px;
  width: max-content;

  &.normal {background-color: var(--MainGreen); color: var(--white);}
  &.high {background-color: var(--MainRed); color: var(--white);}
  &.low {background-color: var(--ThirdBlue); color: var(--white);}
`;

const TempStatus = styled.th`
  background-color: ${(props) => {
    return props.status === 'working' ? 'var(--WorkingColor)' : 'var(--gray-400)';
  }} !important;

  cursor: pointer;
`;

const HsSensorData = () => {
  /* ============================================================================================ #1 */
  const { userReducer } = useSelector((state) => state);

  /* ============================================================================================ #2 */
  const [_onLoad, setOnload] = useState('unload');

  const [_contentType, setContentType] = useState('dataTable');

  const [_dataTable, setDataTable] = useState(() => {
    const keys = [
      // 온도
      // 스팀온도조절
      'STEAM_1HO_PV', 'STEAM_1HO_SV', 'STEAM_1HO_HIGH', 'STEAM_1HO_LOW',// 1
      'STEAM_2HO_PV', 'STEAM_2HO_SV', 'STEAM_2HO_HIGH', 'STEAM_2HO_LOW',//2
      'STEAM_3HO_PV', 'STEAM_3HO_SV', 'STEAM_3HO_HIGH', 'STEAM_3HO_LOW',//3

      // 가열온도조절
      'HEAT_1HO_PV', 'HEAT_1HO_SV', 'HEAT_1HO_HIGH', 'HEAT_1HO_LOW',// 1
      'HEAT_2HO_PV', 'HEAT_2HO_SV', 'HEAT_2HO_HIGH', 'HEAT_2HO_LOW',//2
      'HEAT_3HO_PV', 'HEAT_3HO_SV', 'HEAT_3HO_HIGH', 'HEAT_3HO_LOW',//3
      'HEAT_9HO_PV', 'HEAT_9HO_SV', 'HEAT_9HO_HIGH', 'HEAT_9HO_LOW',//9

      // 실온
      'ROOM_1HO_PV', 'ROOM_1HO_SV', 'ROOM_1HO_HIGH', 'ROOM_1HO_LOW',// 1
      'ROOM_2HO_PV', 'ROOM_2HO_SV', 'ROOM_2HO_HIGH', 'ROOM_2HO_LOW',//2
      'ROOM_3HO_PV', 'ROOM_3HO_SV', 'ROOM_3HO_HIGH', 'ROOM_3HO_LOW',//3
      'ROOM_4HO_PV', 'ROOM_4HO_SV', 'ROOM_4HO_HIGH', 'ROOM_4HO_LOW',//4
      'ROOM_5HO_PV', 'ROOM_5HO_SV', 'ROOM_5HO_HIGH', 'ROOM_5HO_LOW',//5
      'ROOM_6HO_PV', 'ROOM_6HO_SV', 'ROOM_6HO_HIGH', 'ROOM_6HO_LOW',//6
      'ROOM_7HO_PV', 'ROOM_7HO_SV', 'ROOM_7HO_HIGH', 'ROOM_7HO_LOW',//7
      'ROOM_8HO_PV', 'ROOM_8HO_SV', 'ROOM_8HO_HIGH', 'ROOM_8HO_LOW',//8
      'ROOM_9HO_PV', 'ROOM_9HO_SV', 'ROOM_9HO_HIGH', 'ROOM_9HO_LOW',//9

      // 압력
      'left_1', // 4호기
      'left_2', // 5호기
      'left_3', // 보일러1
      'left_4', // 2호기
      'left_5', // 1호기
      'left_6', // 3호기
      'right_6', // 보일러2
      'right_7', // 7호기
      'right_8', // 8호기
    ];

    const returnData = {};
    keys.forEach((key) => { returnData[key] = '00.000'; });
    return returnData;
  });

  const [_tempModalStatus, setTempModalStatus] = useState(false);
  const [_eventTemp, setEventTemp] = useState({});

  const [_equipmentStatus, setEquipmentStatus] = useState(() => {
    const returnData = {};
    ['온도', '압력'].forEach(async (type) => {
      for (let i = 1; i <= 9; i++) {
        returnData[`${type} ${i}호기`] = { equipment: {}, equipmentStatus: 'waiting' };
      }
    });
    return returnData;
  });

  /* ============================================================================================ #3 */
  useEffect(() => {
    (async () => {
      await getSensorData(_contentType);
      await getEquipmentStatus();
    })();

    const search = setInterval(async () => {
      await getSensorData(_contentType);
      await getEquipmentStatus();
    }, 60000);

    setOnload('loaded');
    return () => clearInterval(search);
  }, []);

  /* ============================================================================================ #4 */
  const getSensorData = async (contentType) => {
    const paging = `?page=0&size=73&sort=id,DESC`;
    const BodyToPost = {
      companyId: 13,
      startDate: moment().add('-1', 'm').add(9, 'hour').toISOString(),
      endDate: moment().add(9, 'hour').toISOString(),
      name: '',
    };
    console.log('paging : ', paging);
    console.log('BodyToPost : ', BodyToPost);

    await sensorDataApi.hslandSensorData(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('sensorDataApi.hslandSensorData : ', response);
      if (response.data.content.length < 1) return;
      const allData = { ..._dataTable };
      response.data.content.forEach((result) => { allData[result.name] = result.value; });
      setDataTable((prev) => { return { ...prev, ...allData }; });
    });
  };

  const getEquipmentStatus = () => {
    ['온도', '압력'].forEach(async (type) => {
      for (let i = 1; i <= 9; i++) { await getEquipmentData(`${type} ${i}호기`); }
    });
  };
  const getEquipmentData = async (equipmentCode) => {
    const paging = `?page=0`;
    const BodyToPost = {
      companyId: 13,
      equipmentCode: equipmentCode,
    };

    await equipmentApi.searchEquipment(paging, BodyToPost).then((response) => {
      if (response === undefined || response.data.content.length === 0) return;
      console.log('equipmentApi.searchEquipment : ', response);
      if (response.data.content[0].equipmentCode !== equipmentCode) return;
      setEquipmentStatus((prev) => {
        return { ...prev, [equipmentCode]: { equipment: response.data.content[0], equipmentStatus: response.data.content[0].status } };
      });
    });
  };

  /* ============================================================================================ #5 */
  const handleEquipmentStatus = async (equipment, equipmentStatus) => {
    const confirmText = `설비 상태를 변경하시겠습니까?`;
    if (!window.confirm(confirmText)) return;
    if (equipment.equipmentId === undefined) return;
    const eventStatus = equipmentStatus === 'working' ? 'waiting' : 'working';
    const BodyToPut = {
      companyId: 13,
      equipmentId: equipment.equipmentId,
      equipmentCode: equipment.equipmentCode,
      equipmentName: equipment.equipmentName,
      status: eventStatus,

      customAttr: '',

      equipmentLogList: [
        // {
        //   postStatus: eventStatus,
        //   preStatus: equipmentStatus,
        //   note: '',
        // },
      ],
      deleteEquipmentLogList: [],

      equipmentAttributeValueList: [],
      deleteEquipmentAttributeValueList: [],
    };

    await equipmentApi.updateEquipment(equipment.equipmentId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('equipmentApi.updateEquipment : ', response);
      setEquipmentStatus((prev) => {
        return { ...prev, [equipment.equipmentCode]: { equipment: response.data, equipmentStatus: response.data.status } };
      });
    });
  };

  const goDetail = (tempData) => {
    setEventTemp(() => { return tempData; });
    setTimeout(setTempModalStatus(true), 1000);
  };

  /* ============================================================================================ #6 */
  const checkNum = (num) => {
    const eventNum = Number(num);
    if (isNaN(eventNum)) return 0;
    else return eventNum;
  };
  const tempDiv = (tempName, tempKeys) => {
    return (
      <>
        <td className="title" style={{ minWidth: '100px', width: '100px' }}>{tempName}</td>
        {tempKeys.map((tempKey, index) => {
          const { equipment, equipmentStatus } = _equipmentStatus[`온도 ${index + 1}호기`];
          if (tempKey === '') return (<td key={tempName + '_' + index} style={{ backgroundColor: 'var(--gray-300)' }}></td>);
          let status = 'normal';
          if (checkNum(_dataTable[tempKey + '_PV']) > checkNum(_dataTable[tempKey + '_HIGH'])) status = 'high';
          if (checkNum(_dataTable[tempKey + '_PV']) < checkNum(_dataTable[tempKey + '_LOW'])) status = 'low';
          return (
            <TempData key={tempName + '_' + index}
              className={equipmentStatus === 'waiting' ? 'waiting' : status}
              onClick={(e) => {
                e.preventDefault();
                goDetail({
                  tempName: tempName,
                  tempKey: tempKey,
                  PV: _dataTable[tempKey + '_PV'],
                  SV: _dataTable[tempKey + '_SV'],
                  HIGH: _dataTable[tempKey + '_HIGH'],
                  LOW: _dataTable[tempKey + '_LOW'],
                });
              }}
            >
              {_dataTable[tempKey + '_PV']}
            </TempData>
          );
        })}
      </>
    );
  };

  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <>
        <MainSection className="Main">
          <NavBar
            title={<NavTitle menuCode={'702'} />}
            buttons={
              <>
                <p style={{ color: 'var(--MainNavy)', fontWeight: 600 }}>* 각 온도를 클릭하면 세부 설정값을 볼 수 있습니다.</p>
                <ColorSection>
                  <ColorBox className="low">하한값보다 낮음</ColorBox>
                  <ColorBox className="normal">정상</ColorBox>
                  <ColorBox className="high">상한값보다 높음</ColorBox>
                </ColorSection>
              </>
            }
            nav={''}
          />

          <DataSection contentType={_contentType}>
            {_onLoad === 'loaded' && _contentType === 'dataTable' ? (
              <>
                <DataTable>
                  <thead>
                    <tr>
                      <th></th>
                      {(() => {
                        const returnData = [];
                        for (let i = 1; i <= 9; i++) {
                          returnData.push(<th key={i + '_tempTh'}>{i}호기</th>);
                        }
                        return returnData;
                      })()}
                    </tr>
                    <tr style={{ height: '100px' }}>
                      <th></th>
                      {(() => {
                        const returnData = [];
                        for (let i = 1; i <= 9; i++) {
                          const { equipment, equipmentStatus } = _equipmentStatus[`온도 ${i}호기`];
                          const equipmentStatusText = equipmentStatus === 'working' ? '가동' : '중지';
                          returnData.push(
                            <TempStatus key={i + '_tempStatus'}
                              status={equipmentStatus}
                              onClick={() => { handleEquipmentStatus(equipment, equipmentStatus); }}
                            >
                              {equipmentStatusText}
                            </TempStatus>,
                          );
                        }

                        return returnData;
                      })()}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {tempDiv('스팀온도', [
                        'STEAM_1HO',
                        'STEAM_2HO',
                        'STEAM_3HO',
                        '', // 4
                        '', // 5
                        '', // 6
                        '', // 7
                        '', // 8
                        '', // 9
                      ])}
                    </tr>
                    <tr>
                      {tempDiv('가열온도', [
                        'HEAT_1HO',
                        'HEAT_2HO',
                        'HEAT_3HO',
                        '', // 4
                        '', // 5
                        '', // 6
                        '', // 7
                        '', // 8
                        'HEAT_9HO',
                      ])}
                    </tr>
                    <tr>
                      {tempDiv('실온', [
                        'ROOM_1HO',
                        'ROOM_2HO',
                        'ROOM_3HO',
                        'ROOM_4HO',
                        'ROOM_5HO',
                        'ROOM_6HO',
                        'ROOM_7HO',
                        'ROOM_8HO',
                        'ROOM_9HO',
                      ])}
                    </tr>
                  </tbody>
                </DataTable>
                <DataTable>
                  <thead>
                    <tr>
                      <th></th>
                      {(() => {
                        const returnData = [];
                        for (let i = 1; i <= 9; i++) {
                          if (i === 8) returnData.push(<th key={i + '_tempTh'}>보일러1</th>);
                          else if (i === 9) returnData.push(<th key={i + '_tempTh'}>보일러2</th>);
                          else returnData.push(<th key={i + '_tempTh'}>{i}호기</th>);
                        }
                        return returnData;
                      })()}
                    </tr>
                    <tr style={{ height: '100px' }}>
                      <th></th>
                      {(() => {
                        const returnData = [];
                        for (let i = 1; i <= 9; i++) {
                          const { equipment, equipmentStatus } = _equipmentStatus[`압력 ${i}호기`];
                          const equipmentStatusText = equipmentStatus === 'working' ? '가동' : '중지';
                          returnData.push(
                            <TempStatus key={i + '_pressStatus'}
                              status={equipmentStatus}
                              onClick={() => { handleEquipmentStatus(equipment, equipmentStatus); }}
                            >
                              {equipmentStatusText}
                            </TempStatus>,
                          );
                        }
                        return returnData;
                      })()}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="title">압력</td>
                      {[
                        'left_5',
                        'left_4',
                        'left_6',
                        'left_1',
                        'left_2',
                        'right_8',
                        'right_7',
                        'left_3',
                        'right_6',
                      ].map((pressureKey, index) => {
                        const { equipment, equipmentStatus } = _equipmentStatus[`압력 ${index + 1}호기`];
                        return (
                          <PressureData key={pressureKey + '_' + index}
                            className={(() => {
                              if (equipmentStatus === 'waiting') {
                                return 'waiting';
                              } else {
                                if (_dataTable[pressureKey] === 'HHHHHH') return 'high';
                                else if (_dataTable[pressureKey] === 'LLLLLL') return 'low';
                                else return 'normal';
                              }
                            })()}
                          >
                            {(() => {
                              if (_dataTable[pressureKey] === 'HHHHHH') return (<>영점조절<br />센서확인<br />필요</>);
                              else if (_dataTable[pressureKey] === 'LLLLLL') return (<>영점조절<br />센서확인<br />필요</>);
                              else return (<>{Number(_dataTable[pressureKey])}<br />bar</>);
                            })()}
                          </PressureData>
                        );
                      })}
                    </tr>
                  </tbody>
                </DataTable>
              </>
            ) : null}
          </DataSection>
        </MainSection>

        {_tempModalStatus === true ? (
          <HsSensorDataModal
            tempData={_eventTemp}
            open={_tempModalStatus}
            close={() => { setTempModalStatus(false); }}
          />
        ) : null}
      </>
    }
    ></Grid2Body>
  );
};

export default HsSensorData;