import React, { useEffect, useState } from "react";
import CloseButtonIcon from "components/icons/CloseButtonIcon";
import ContractInfoTab from "pages/contract/modal/ContractInfoTab";
import ContractItemsTab from "pages/contract/modal/ContractItemsTab";
import ContractProcurement from "pages/contract/modal/ContractProcurement";
import ContractShipment from "pages/contract/modal/ContractShipment";
import ProcurementInfoTab from "pages/procurement/modal/ProcurementInfoTab";
import ProcurementItemsTab from "pages/procurement/modal/ProcurementItemsTab";
import ShipmentInfoTab from "pages/shipment/modal/ShipmentInfoTab";
import ShipmentItemsTab from "pages/shipment/modal/ShipmentItemsTab";
import {
  CloseButton, ModalBg, ModalHeader, ModalHeaderButtons, ModalMain, ModalSection, ModalTitle, TabButton
} from "./OrderDetailModal.style";

const OrderDetailModal = (props) => {
  const [type, setType] = useState(props.orderType);
  const [detailData, setDetailData] = useState([]);
  const [title, setTitle] = useState('');
  const [buttons, setButtons] = useState([]);
  const [selectedTab, setSelectedTab] = useState('');

  const allButtons = [
    { name: '수주정보보기', tab: 'contractInfo', data: props.contractList || [] },
    { name: '수주물품보기', tab: 'contractItems', data: props.contractList || [] },
    { name: '발주상세보기', tab: 'contractProcurement', data: props.procurementList || [] },
    { name: '출하상세보기', tab: 'contractShipment', data: props.shipmentList || [] },
    { name: '발주정보보기', tab: 'procurementInfo', data: props.procurementList || [] },
    { name: '발주물품보기', tab: 'procurementItems', data: props.procurementList || [] },
    { name: '출하정보보기', tab: 'shipmentInfo', data: props.shipmentList || [] },
    { name: '출하물품보기', tab: 'shipmentItems', data: props.shipmentList || [] },
  ];

  useEffect(() => {
    let filteredButtons = [];
    let initialTab = '';
    let initialData = [];

    /* 버튼 필터 */
    if (type === 'contract') {
      setTitle('수주');
      filteredButtons = allButtons.slice(0, 2);
      initialTab = 'contractInfo';
      initialData = props.contractList || [];
    } else if (type === 'procurement') {
      setTitle('발주');
      filteredButtons = allButtons.slice(4, 6);
      initialTab = 'procurementInfo';
      initialData = props.procurementList || [];
    } else if (type === 'shipment') {
      setTitle('출하');
      filteredButtons = allButtons.slice(6, 8);
      initialTab = 'shipmentInfo';
      initialData = props.shipmentList || [];
    }

    setButtons(filteredButtons);
    setSelectedTab(initialTab);
    setDetailData(initialData);
  }, [type, props.contractList, props.procurementList, props.shipmentList, props.orderType]);

  const handleButtonClick = (tab, data) => {
    setSelectedTab(tab);
    setDetailData(data);
  };

  const renderButtons = () => {
    return buttons.map((button, index) => (
      <TabButton
        key={index}
        onClick={() => handleButtonClick(button.tab, button.data)}
        className={selectedTab === button.tab ? 'active' : ''}
      >
        {button.name}
      </TabButton>
    ));
  };

  const renderTabContent = () => {
    const contractId = props.contractId;
    switch (selectedTab) {
      case 'contractInfo':
        return <ContractInfoTab data={detailData} contractId={contractId} />;
      case 'contractItems':
        return <ContractItemsTab data={detailData} />;
      case 'contractProcurement':
        return <ContractProcurement data={detailData} />;
      case 'contractShipment':
        return <ContractShipment data={detailData} />;
      case 'procurementInfo':
        return <ProcurementInfoTab data={detailData} />;
      case 'procurementItems':
        return <ProcurementItemsTab data={detailData} />;
      case 'shipmentInfo':
        return <ShipmentInfoTab data={detailData} />;
      case 'shipmentItems':
        return <ShipmentItemsTab data={detailData} />;
      default:
        return null;
    }
  };

  return (
    <>
      {props.open && (
        <ModalBg>
          <ModalSection>

            <ModalHeader>
              <ModalTitle>{title} 상세보기</ModalTitle>
              <ModalHeaderButtons>
                {renderButtons()}
              </ModalHeaderButtons>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              {renderTabContent()}
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default OrderDetailModal;
