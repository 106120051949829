import React, { useEffect, useState } from 'react';

const MyBoardDetail = ({
  modalType, modalData,
  update,
  close
}) => {
  const [_body, setBody] = useState({ ...modalData });

  const dataKeys = [
    // { key: 'title', title: '제목', },
    { key: 'detail', title: '게시글', },
  ];

  useEffect(() => { }, [_body]);

  return (
    <section>
      <div className='section-form'>
        {dataKeys.map((dataKey, index) => {
          return (
            <div key={index + '_form-content'} className='form-content'>
              <h4>{dataKey.title}</h4>
              <textarea
                readOnly
                defaultValue={_body[dataKey.key] || ''}
              />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default MyBoardDetail;