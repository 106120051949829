import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { alarmSetting } from 'api/apis/alarm/alarmSetting';
import { alarmTemplate } from 'api/apis/alarm/alarmTemplate';
import { userApi } from 'api/apis/userApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import TableSection from 'components/layouts/table/TableSection';

const Section = styled.main`
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 66px calc(100% - 66px);
`;
const AlarmSection = styled.section`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 30% 70%;
  height: 100%;
  overflow: hidden;
  padding: 10px;
  width: 100%;

  .section-select {
    display: grid;
    gap: 10px;
    grid-template-rows: 40px calc(100% - 50px);
    overflow: hidden;
    .search {
      display: grid;
      gap: 10px;
      grid-template-columns: calc(100% - 80px) 60px;
      height: 40px;
      width: 100%;

      input {
        box-sizing: border-box;
        padding: 5px;
        height: 100%;
        width: 100%;
      }
      button {
        background-color: var(--MainNavy);
        color: var(--white);
        height: 100%;
        width: 60px;
      }
    }
    .TableSection {
      height: 100%;
      td, th {min-width: unset;}
    }
  }
  .section-result {
    align-content: flex-start;
    box-sizing: border-box;
    display: grid;
    gap: 20px;
    height: 100%;
    overflow: hidden;
    padding: 50px;
    width: 100%;

    .title {
      align-items: center;
      display: flex;
      height: 40px;
      justify-content: space-between;

      .btn-save:hover {background-color: var(--MainNavy);}
    }

    .contents {
      display: grid;
      gap: 10px;
      width: 100%;

      p {
        align-items: center;
        display: flex;
        gap: 10px;
        justify-content: flex-start;

        input {
          margin: unset;
        }
      }
    }

    .TableSection {
      td, th {
        min-width: unset;
        padding-inline: 10px;
      }
    }
  }
`;

const AlarmManagement = () => {
  const dispatch = useDispatch();
  const { userReducer } = useSelector((state) => state);

  const [_searchWorker, setSearchWorker] = useState('');
  const [_workerList, setWorkerList] = useState([]);

  const [_worker, setWorker] = useState({});

  const [_alarmList, setAlarmList] = useState([]);
  const [_alarmData, setAlarmData] = useState([]);

  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getWorkerList(_searchWorker);
    getAlarmList();

    return () => { }
  }, []);
  useEffect(() => { /* console.log('_worker : ', _worker); */ }, [_worker])
  useEffect(() => { }, [_alarmData])

  const getWorkerList = async (worker) => {
    const paging = `?page=0&size=100`;
    const body = { companyId: userReducer.company.companyId };
    if (checkEmptyNull(worker, false)) body.name = worker;
    await userApi.searchUser(paging, body).then(response => {
      if (response === undefined) return;
      setWorkerList(() => { return response.data.content });
    })
  };
  const getAlarmList = async () => {
    await alarmTemplate.getAlarmTemplates().then(response => {
      if (response === undefined) return;
      console.log('alarmTemplate.getAlarmTemplates : ', response);
      setAlarmList(() => { return response.data.content });
    })
  }

  const handleAlarmData = async (worker) => {
    setAlarmData(() => { return [] });
    setWorker(() => { return worker });
    const body = {
      companyId: worker.companyId,
      userId: worker.id,
    }
    await alarmSetting.searchAlarmSetting(body).then(response => {
      if (response === undefined) return;
      // console.log('alarmSetting.searchAlarmSetting : ', response);
      if (checkNullArray(response.data.content, false)) {
        setAlarmData(() => { return response.data.content });
      }
    })
  };
  const checkAlarm = (id) => {
    const findAlarmIndex = _alarmData.findIndex(item => item.alarmTemplate.id === id);
    if (findAlarmIndex === -1) return false;
    else return true;
  }
  const checkAlarmFlag = (id, name) => {
    const findAlarmIndex = _alarmData.findIndex(item => item.alarmTemplate.id === id);
    if (findAlarmIndex === -1) return false;
    else {
      const findAlarm = _alarmData[findAlarmIndex];
      if (findAlarm[name]) return true;
      else return false;
    }
  }
  const updateAlarm = async (alarm, e) => {
    // console.log('alarm : ', alarm);
    if (e.target.checked) {
      const body = {
        companyId: _worker.companyId,
        userId: _worker.id,
        alarmTemplateId: alarm.id,
        mailSendFlag: true,
        kakaoSendFlag: true,
      }
      await alarmSetting.createAlarmSetting(body).then(response => {
        if (response === undefined) return;
        console.log('alarmSetting.createAlarmSetting : ', response);
        handleAlarmData(_worker);
      }).catch(error => {
        return e.target.checked = false;
      })
    } else {
      const findAlarmIndex = _alarmData.findIndex(item => item.alarmTemplate.id === alarm.id);
      if (findAlarmIndex === -1) return e.target.checked = false;
      else {
        const findAlarm = _alarmData[findAlarmIndex];
        // console.log('findAlarm : ', findAlarm);
        await alarmSetting.deleteAlarmSetting(findAlarm.id).then(response => {
          if (response === undefined) return;
          console.log('alarmSetting.deleteAlarmSetting : ', response);
          handleAlarmData(_worker);
        }).catch(error => {
          return e.target.checked = false;
        })
      }
    }
  }
  const updateAlarmFlag = async (alarm, e) => {
    const { name, checked } = e.target;
    const findAlarmIndex = _alarmData.findIndex(item => item.alarmTemplate.id === alarm.id);
    if (findAlarmIndex === -1) return e.target.checked = false;
    else {
      const findAlarm = _alarmData[findAlarmIndex];
      console.log('findAlarm : ', findAlarm);
      const body = {
        companyId: _worker.companyId,
        userId: _worker.id,
        alarmTemplateId: alarm.id,
        mailSendFlag: true,
        kakaoSendFlag: true,
      }
      body[name] = checked;
      await alarmSetting.updateAlarmSetting(findAlarm.id, body).then(response => {
        if (response === undefined) return;
        console.log('alarmSetting.updateAlarmSetting : ', response);
        handleAlarmData(_worker);
      }).catch(error => {
        return e.target.checked = false;
      })
    }
  }

  return (
    <Grid2Body contents={
      <>
        <Section className='Main'>
          <NavBar
            title={<NavTitle menuCode={'905'} />}
            nav={''}
          />
          <AlarmSection>
            <section className='section-select'>
              <div className='search'>
                <input
                  type='text'
                  placeholder='이름...'
                  value={_searchWorker}
                  onInput={(e) => { setSearchWorker(() => { return e.target.value }) }}
                />
                <button className='btn-set' onClick={(e) => { e.preventDefault(); getWorkerList(_searchWorker); }}>검색</button>
              </div>
              <TableSection content={
                <table>
                  <colgroup>
                    <col />
                    <col />
                    <col width={'50px'} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>팀</th>
                      <th>이름</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {_workerList.map((worker, index) => {
                      return (
                        <tr key={index + '_worker'}>
                          <td>{worker.team?.teamName}</td>
                          <td>{worker.name}</td>
                          <td>
                            <button className='btn-set' onClick={(e) => { e.preventDefault(); handleAlarmData(worker) }}>보기</button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              }
              />
            </section>
            <section className='section-result'>
              {checkNullObject(_worker, false) ? (
                <>
                  <div className='title'>
                    <h3>{_worker.name} 알람설정</h3>
                  </div>
                  <TableSection content={
                    <table>
                      <colgroup>
                        <col />
                        <col width={'100px'} />
                        <col width={'100px'} />
                        <col width={'100px'} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th></th>
                          <th>알람</th>
                          <th>메일</th>
                          <th>카카오톡</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_alarmList.map((alarm, index) => {
                          return (
                            <tr key={index + '_alarm'}>
                              <td>{alarm.templateName}</td>
                              <td><input type='checkbox' checked={checkAlarm(alarm.id)} onChange={(e) => { updateAlarm(alarm, e) }} /></td>
                              <td>
                                {checkAlarm(alarm.id) ? (
                                  <input type='checkbox' name="mailSendFlag" checked={checkAlarmFlag(alarm.id, 'mailSendFlag')} onChange={(e) => { updateAlarmFlag(alarm, e) }} />
                                ) : null}
                              </td>
                              <td>
                                {checkAlarm(alarm.id) ? (
                                  <input type='checkbox' name="kakaoSendFlag" checked={checkAlarmFlag(alarm.id, 'kakaoSendFlag')} onChange={(e) => { updateAlarmFlag(alarm, e) }} />
                                ) : null}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  } />
                </>
              ) : null}
            </section>
          </AlarmSection>
        </Section>
      </>
    } />
  );
};

export default AlarmManagement;