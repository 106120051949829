import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  inquiryActions_setIsPushedSearchButton,
  inquiryActions_setInquiryStatus,
  inquiryActions_setPageNumber,
  inquiryActions_setReset,
  inquiryActions_setSearchOption,
  inquiryActions_setSearchText,
  inquiryActions_setTotalSize
} from 'store/modules/actions/inquiry/inquiryActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { inquiryApi } from 'api/apis/inquiryApi';

import Grid4Body from 'components/layouts/body/Grid4Body';
import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateTimeFormatKR } from 'components/format/DateTimeFormatKR';

import { uploadSection } from 'components/icons/src';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';

import { InquiryStatus } from 'pages/inquiry/components/status';
import InquiryPaging from 'pages/inquiry/components/paging';
import InquiryForm from 'pages/inquiry/components/form';
import NavBar from 'components/nav/NavBar';

import { InquiryStyle } from './style'

const InquiryList = () => {
  const dispatch = useDispatch();
  const { inquiryReducer, userReducer } = useSelector((state) => state);
  const [_onload, setOnload] = useState('unload');
  const [_inquiryList, setInquiryList] = useState([]);
  const [_createStatus, setCreateStatus] = useState(false);
  const [_currentQnA, setCurrentQnA] = useState({});

  // 첫 페이지 로드
  useEffect(() => {
    getInquiryList(
      inquiryReducer.pageNumber - 1, 
      inquiryReducer.inquiryStatus, 
      inquiryReducer.isPushedSearchButton);
    dispatch(pageReducer_getCurrentPage(window.location.pathname));
    setOnload('loaded');
    return () => { };
  }, []);
  // 페이지네이션 페이지 로드
  // useEffect(() => {
  //   if (_onload === 'loaded') {
  //     getInquiryList(
  //       inquiryReducer.pageNumber - 1, 
  //       inquiryReducer.inquiryStatus, 
  //       inquiryReducer.isPushedSearchButton);
  //   }
  //   return () => { };
  // }, [inquiryReducer.pageNumber]);
  // useEffect(() => { }, [inquiryReducer]);
  // useEffect(() => { }, [_createStatus, _currentQnA]);

  /* ====================================================================== #4 */
  const getInquiryList = async (page, inquiryStatus, clear) => {
    const paging = `?page=${page}&size=1000&sort=id,DESC`;
    const BodyToPost = { companyId: userReducer.company.companyId, };

    if (checkEmptyNull(inquiryStatus, false) && inquiryStatus !== 'all') {
      BodyToPost.status = inquiryStatus;
    }

    if (clear && checkEmptyNull(inquiryReducer.searchOption, false) && checkEmptyNull(inquiryReducer.searchText, false)) {
      BodyToPost[inquiryReducer.searchOption] = inquiryReducer.searchText;
    }

    await inquiryApi.searchAllInquiry(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('inquiry.searchAllInquiry : ', response);

      if (response.data) {
        setInquiryList(() => { return response.data.content; });

        if (checkNullArray(response.data.content, false) && _currentQnA.inquiryId !== undefined) {
          const eventOrderIndex = response.data.content.findIndex((order) => order.inquiryId === _currentQnA.inquiryId);
          if (eventOrderIndex !== -1) {
            setCurrentQnA(() => { return response.data.content[eventOrderIndex]; });
          }
        }

        dispatch(inquiryActions_setTotalSize(response.data.totalElements));
      }
    });
  };

  /* ====================================================================== #5 */
  /* 검색 */
  const actSearch = async () => {
    setCreateStatus(() => { return false; });
    setCurrentQnA(() => { return {}; });

    dispatch(inquiryActions_setPageNumber(1));
    dispatch(inquiryActions_setIsPushedSearchButton(true));
    await getInquiryList(0, inquiryReducer.inquiryStatus, true);
  };

  /* 초기화 */
  const actReset = async () => {
    setCreateStatus(() => { return false; });
    setCurrentQnA(() => { return {}; });

    dispatch(inquiryActions_setReset());
    dispatch(inquiryActions_setPageNumber(1));
    await getInquiryList(0, 'all', false);
  };

  return (
    <InquiryStyle>
      <Grid4Body
        contents={
        <main className='inquiryList'>
          <NavBar
            title='문의'
            buttons={
              <>
                {!_createStatus ? (
                  <button className='inquiry-form-btn'
                    onClick={(e) => { setCreateStatus(() => { return true; }); }}
                  >
                    문의하기
                  </button>
                ) : null}
              </>
            }
            firstRow={
              <>
                <div className="SearchSection">
                  <div className="ContentCBox">
                    <div className='qna-search'>
                      <select className="SearchOption detail"
                        value={inquiryReducer.inquiryStatus}
                        onChange={(e) => {
                          dispatch(inquiryActions_setInquiryStatus(e.target.value));
                          dispatch(inquiryActions_setPageNumber(1));
                          getInquiryList(0, e.target.value, inquiryReducer.isPushedSearchButton);
                        }}
                      >
                        {[
                          { status: 'all', name: '전체' },
                          { status: 'wait', name: '대기', color: '#E0E0E0' },
                          { status: 'requestCancel', name: '취소요청', color: '#C0C0C0' },
                          { status: 'cancel', name: '취소 승인', color: '#B4BCFD' },
                          { status: 'check', name: '확인중', color: '#B2DFEE' },
                          { status: 'end', name: '답변완료', color: '#0063C4' },
                        ].map((inquiryStatus) => {
                          return (<option key={inquiryStatus.status + '_inquiryStatus'} value={inquiryStatus.status}>{inquiryStatus.name}</option>);
                        })}
                      </select>
                      <select className="SearchOption detail"
                        value={inquiryReducer.searchOption}
                        onChange={(e) => { dispatch(inquiryActions_setSearchOption(e.target.value)); }}
                      >
                        {[
                          { optionValue: '', optionText: '검색옵션' },
                          { optionValue: 'inquiryCode', optionText: '문의코드' },
                          { optionValue: 'inquiryContent', optionText: '문의내용' },
                        ].map((option) => {
                          return (<option key={option.optionValue + '_inquirySearchOption'} value={option.optionValue} >{option.optionText}</option>);
                        })}
                      </select>
                      <input
                        className="SearchBar"
                        placeholder="Search..."
                        value={inquiryReducer.searchText}
                        onInput={(e) => { dispatch(inquiryActions_setSearchText(e.target.value)); }}
                      />
                      <Link onClick={actReset} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 8px' }}>
                        <img src='https://cdn-icons-png.flaticon.com/512/82/82004.png' style={{ width: '16px' }} />
                      </Link>
                      <button onClick={actSearch} className='btn-search'><SearchButtonIcon />검색</button>
                    </div>
                  </div>
                </div>
              </>
            }
          />

          {/* 문의 등록 */}
          {(() => {
            if (_createStatus) {
              return (
                <InquiryForm
                  update={actReset}
                  close={(e) => {
                    e.preventDefault();
                    setCreateStatus(() => { return false; });
                  }}
                />
              );
            } else {
              return null;
            }
          })()}

          {/* 문의 리스트 */}
          <section>
            <div className='inquiry-title'>
              <p className='inquiry-num'>문의번호</p>
              <p className='inquiry-status'>진행상태</p>
              <p className='inquiry-content'>문의내용</p>
              <p className='inquiry-date'>등록일</p>
              <p className='inquiry-detail'>상세보기</p>
            </div>
            <ul>
              {_inquiryList.map((inquiry, index) => {
                return (
                  <>
                    {inquiry.userId === userReducer.user.id ? (
                      <li key={index + '_inquiry'}>
                        <>
                          <p className='inquiry-num'>{inquiry.inquiryId}</p>
                          <p className='inquiry-status'>{InquiryStatus(checkEmptyNull(inquiry.status, 'wait'))}</p>
                          <p className='inquiry-content'>{inquiry.inquiryContent}</p>
                          <p className='inquiry-date'>{DateTimeFormatKR(inquiry.createdDate)}</p>
                          <Link className='inquiry-btn' to={`/inquiry/${inquiry.inquiryId}`}>상세보기</Link>
                        </>
                      </li>
                    ): null}
                  </>
                );
              })}
            </ul>
          </section>

          {/* 문의 페이지네이션 사용 안함 */}
          {/* <InquiryPaging
            page={inquiryReducer.pageNumber}
            count={inquiryReducer.totalSize}
            size={10}
            pageEvent={(page) => {
              console.log('page : ', page);
              dispatch(inquiryActions_setPageNumber(page));
            }}
          /> */}
        </main>
      }
      />
    </InquiryStyle>
  );
};

export default InquiryList;
