import styled from "styled-components";

export const SettingComponent = styled.section`
  align-content: flex-start;
  box-sizing: border-box;
  display: grid;
  /* grid-template-rows: 50px 40px calc(100% - 120px); */
  grid-template-rows: 100px auto auto 60px;
  gap: 15px;
  height: 100%;
  overflow: hidden;
  padding: 30px 50px;
  width: 100%;

  & header {
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    height: 100px;
    justify-content: space-between;
    position: relative;
    width: 100%;

    & h1 {
      font-size: 30px;
      font-weight: 600;
    }
    & button {
      border-radius: 50%;
      height: 50px;
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      width: 50px;
      &>svg {
        height: 50px;
        width: 50px;
      }
      &>p {
        bottom: 0px;
        left: -80px;
        position: absolute;
      }
    }
  }

  & nav { // 검색
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;
    padding-block: 10px;

    & .nav-bar {
      align-items: center;
      box-sizing: border-box;
      border: 1px solid #1D1D1D;
      border-radius: 5px;
      display: flex;
      gap: 10px;
      height: 40px;
      justify-content: space-between;
      padding-inline: 15px;
      position: relative;
      width: fit-content;

      /* &:first-of-type {
        max-width: 200px;
      } */

      &>p {
        font-size: 14px;
        left: 5px;
        position: absolute;
        top: -60%;
      }

      &>select, &>input {
        font-size: 16px;
        width: 120px;
        /* width: 100%; */
        &[type="text"] {width: 200px;}
      }
    }

    &>button {
      height: 50px;
      /* width: 50px; */
      &:hover {background: unset;}
      &>svg {height: 24px; width: 24px;}
    }
  }

  &>div { // 유형 리스트
    /* height: 100%; */
    overflow-y: scroll;
    width: 100%;

    & table {
      border-collapse: collapse;
      min-width: 100%;
      height: fit-content;
      position: relative;

      & thead {
        background-color: var(--white);
        position: sticky;
        top: 0;
        z-index: 1;
      }
  
      & th, & td {
        box-sizing: border-box;
        padding: 10px;
        text-align: left;
        word-break: keep-all;
        width: fit-content;
      }
      & thead th {border-block-end: 1px solid #1D1D1D; font-size: 17px;}
      & tbody td {border-block-end: 1px solid #d8d8d8; font-size: 16px;}

      & td:last-of-type { // 수정/삭제 버튼
        background-color: var(--white);
        position: sticky;
        right: 0;
        
        &>div {
          align-items: center;
          display: flex;
          justify-content: space-between;
          width: 180px;
  
          &>div {
            background-color: #717171;
            height: 15px;
            width: 1px;
          }
          &>button {
            align-items: center;
            border-radius: 3px;
            box-sizing: border-box;
            display: flex;
            font-size: 15px;
            /* color: #717171; */
            gap: 2px;
            justify-content: center;
            padding-inline: 5px;
            width: fit-content;
  
            &>svg {
              height: 20px;
              width: 20px;
            }
  
            &:hover {background: unset;}
            &:first-of-type:hover {color: #173E99;}
            &:last-of-type:hover {
              background-color: #EB003B;
              color: var(--white);
            }
          }
        }
      }
    }
  }
`;