import React from 'react';

import { IoClose } from "react-icons/io5";
import { ClusterFormModalComponent } from './FormModal.style';
/* equipement */
import EquipmentType from '../mypage/equipment/setting/EquipmentType';
import EquipmentAttributes from '../mypage/equipment/setting/EquipmentAttributes';
import ClusterEquipmentManagement from '../mypage/equipment/EquipmentManagement';
import RequestEquipment from '../mypage/equipment/RequestEquipment';
/* people */
import PeopleType from '../mypage/people/setting/PeopleType';
import PeopleAttributes from '../mypage/people/setting/PeopleAttributes';
import PeopleManagement from '../mypage/people/PeopleManagement';
import RequestPeople from '../mypage/people/RequestPeople';
/* board */
import MyBoard from '../mypage/board/MyBoard';
import PurchaseBoard from '../mypage/board/purchase/PurchaseBoard';
import SalesBoard from '../mypage/board/sales/SalesBoard';

const ClusterFormModal = ({
  modalType, modalData,
  update,
  open, close
}) => {
  const handleModalTitle = (type) => { // 모달 타이틀 설정
    const titleType = `${type.split('-')[1]}-${type.split('-')[2]}`;
    switch (titleType) {
      case 'type-create': return '유형 추가'; case 'type-update': return '유형 수정'; case 'type-delete': return '유형 삭제';
      case 'attr-create': return '속성 추가'; case 'attr-update': return '속성 수정'; case 'attr-delete': return '속성 삭제';
      case 'data-create': return '추가'; case 'data-update': return '수정'; case 'data-delete': return '삭제';
      case 'request-create': return '공유 요청'; case 'request-update': return '수정'; case 'request-delete': return '삭제';
      case 'board-create': return '게시글 등록'; case 'board-update': return '수정'; case 'board-delete': return '삭제';

      default: return '';
    }
  }
  const handleModalContent = (type) => { // 모달 컨텐츠 설정
    if (type.split('-')[1] === 'type') { // 유형
      if (type.split('-')[0] === 'equipment') { // 장비 유형
        return (<EquipmentType modalType={modalType} modalData={modalData} update={update} close={close} />);
      } else if (type.split('-')[0] === 'people') { // 인력 유형
        return (<PeopleType modalType={modalType} modalData={modalData} update={update} close={close} />);
      }
    } else if (type.split('-')[1] === 'attr') { // 속성
      if (type.split('-')[0] === 'equipment') { // 장비 속성
        return (<EquipmentAttributes modalType={modalType} modalData={modalData} update={update} close={close} />);
      } else if (type.split('-')[0] === 'people') { // 인력 속성
        return (<PeopleAttributes modalType={modalType} modalData={modalData} update={update} close={close} />);
      }
    } else if (type.split('-')[1] === 'data') { // 장비/인력
      if (type.split('-')[0] === 'equipment') { // 장비
        return (<ClusterEquipmentManagement modalType={modalType} modalData={modalData} update={update} close={close} />);
      } else if (type.split('-')[0] === 'people') { // 인력
        return (<PeopleManagement modalType={modalType} modalData={modalData} update={update} close={close} />);
      }
    } else if (type.split('-')[1] === 'request') { // 요청
      if (type.split('-')[0] === 'equipment') { // 장비 요청
        return (<RequestEquipment modalType={modalType} modalData={modalData} update={update} close={close} />);
      } else if (type.split('-')[0] === 'people') { // 인력 요청
        return (<RequestPeople modalType={modalType} modalData={modalData} update={update} close={close} />);
      }
    } else if (type.split('-')[1] === 'board') { // 게시글
      if (type.split('-')[0] === 'public') { // 공용 게시판
        return (<MyBoard modalType={modalType} modalData={modalData} update={update} close={close} />);
      } else if (type.split('-')[0] === 'purchase') { // 공동 구매
        return (<PurchaseBoard modalType={modalType} modalData={modalData} update={update} close={close} />);
      } else if (type.split('-')[0] === 'sales') { // 상호 거래
        return (<SalesBoard modalType={modalType} modalData={modalData} update={update} close={close} />);
      }
    }
  }

  return (
    <>
      {open && (
        <ClusterFormModalComponent className={['purchase', 'sales'].includes(modalType?.split('-')[0]) ? 'common' : ''}>
          <header>
            <h1>{handleModalTitle(modalType)}</h1>
            <button className='btn-close' onClick={close}><IoClose /></button>
          </header>
          <div>
            {handleModalContent(modalType)}
          </div>
        </ClusterFormModalComponent>
      )}
    </>
  );
};

export default ClusterFormModal;