import React from 'react';
import Pagination from 'react-js-pagination';

import 'platform/cluster/components/ClusterPagination.css';
import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";
import { MdFirstPage } from "react-icons/md";
import { MdLastPage } from "react-icons/md";

const ClusterPagination = (props) => {
  const { page, count, size, pageEvent } = props;

  return (
    <Pagination
      activePage={page}
      itemsCountPerPage={size}
      onChange={(e) => { pageEvent(e); }}
      pageRangeDisplayed={5}
      totalItemsCount={count}
      firstPageText={<MdFirstPage />}
      lastPageText={<MdLastPage />}
      prevPageText={<GrFormPrevious />}
      nextPageText={<GrFormNext />}
    />
  );
};

export default ClusterPagination;
