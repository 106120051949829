export const equipmentActions_setSearchData = (searchData) => {
  return {type: 'SETEQUIPMENTSEARCHDATA', payload: searchData};
};
export const equipmentActions_setIsPushedSearchButton = (isPushedSearchButton) => {
  return {type: 'SETEQUIPMENTISPUSHEDSEARCHBUTTON', payload: isPushedSearchButton};
};
export const equipmentActions_setPageNumber = (pageNumber) => {
  return {type: 'SETEQUIPMENTPAGENUMBER', payload: pageNumber};
};
export const equipmentActions_setTotalSize = (totalSize) => {
  return {type: 'SETEQUIPMENTTOTALSIZE', payload: totalSize};
};
export const equipmentActions_setReset = () => {
  return {type: 'SETEQUIPMENTRESET'};
};

