import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { accountAttributeApi } from 'api/apis/accountAttributeApi';
import { contractAttributeApi } from 'api/apis/contractAttributeApi';
import { elementAttributeApi } from 'api/apis/elementAttributeApi';
import { equipmentAttributeApi } from 'api/apis/equipmentAttributeApi';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';

const DeleteAttributes = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();
  const deleteType = searchParams.get('type');
  const deleteContent = JSON.parse(searchParams.get('content'));

  const [_onload, setOnload] = useState('unload');

  const [_deleteContent, setDeleteContent] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    switch (deleteType) {
      case 'element':
        setDeleteContent(() => {
          return {
            ...deleteContent,
            deleteTypeName: searchParams.get('typeName'),
          };
        });
        break;

      case 'account':
        setDeleteContent(() => {
          return {
            ...deleteContent,
            deleteTypeData: JSON.parse(searchParams.get('typeData')),
          };
        });
        break;

      case 'equipment':
        setDeleteContent(() => {
          return {
            ...deleteContent,
            deleteTypeName: searchParams.get('typeName'),
          };
        });
        break;

      case 'contract':
        setDeleteContent(() => {
          return {
            ...deleteContent,
            deleteTypeData: JSON.parse(searchParams.get('typeData')),
          };
        });
        break;

      default:
        return;
    }

    setOnload('loaded');
  }, []);

  /* ====================================================================== #4 */

  /* ====================================================================== #5 */
  const actDelete_element = async () => {
    await elementAttributeApi
      .deleteElementAttribute(deleteContent.elementAttributeId)
      .then((response) => {
        if (response === undefined) return;
        console.log('elementAttributeApi.deleteElementAttribute : ', response);
        alert('항목을 삭제했습니다.');
        navigate(pageReducer.currentPage, { replace: true });
      });
  };

  const actDelete_account = async () => {
    await accountAttributeApi
      .deleteAccountAttribute(deleteContent.accountAttributeId)
      .then((response) => {
        if (response === undefined) return;
        console.log('accountAttributeApi.deleteAccountAttribute : ', response);
        alert('항목을 삭제했습니다.');
        navigate(pageReducer.currentPage, { replace: true });
      });
  };

  const actDelete_equipment = async () => {
    await equipmentAttributeApi
      .deleteEquipementAttribute(deleteContent.equipmentAttributeId)
      .then((response) => {
        if (response === undefined) return;
        console.log(
          'equipmentAttributeApi.deleteEquipementAttribute : ',
          response,
        );
        alert('항목을 삭제했습니다.');
        navigate(pageReducer.currentPage, { replace: true });
      });
  };

  const actDelete_contract = async () => {
    await contractAttributeApi
      .deleteContractAttribute(deleteContent.contractAttributeId)
      .then((response) => {
        if (response === undefined) return;
        console.log(
          'contractAttributeApi.deleteContractAttribute : ',
          response,
        );
        alert('항목을 삭제했습니다.');
        navigate(pageReducer.currentPage, { replace: true });
      });
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body
      contents={
        <>
          <main className="Main">
            <NavBar
              title={(() => {
                switch (deleteType) {
                  case 'element':
                    return '물품 항목 설정';
                  case 'account':
                    return '거래처 항목 설정';
                  case 'equipment':
                    return '설비 항목 설정';
                  case 'contract':
                    return '수주 항목 설정';

                  default:
                    return null;
                }
              })()}
              nav={''}
            />

            <DeleteForm
              delteTitle={
                <>
                  {(() => {
                    switch (deleteType) {
                      case 'element':
                        return (
                          <>
                            {_deleteContent.deleteTypeName} <br />
                          </>
                        );

                      default:
                        return null;
                    }
                  })()}
                  {(() => {
                    switch (deleteType) {
                      case 'element':
                        return '물품 항목';
                      case 'account':
                        return '거래처 항목';
                      case 'equipment':
                        return '설비 항목';
                      case 'contract':
                        return '수주 항목';

                      default:
                        return null;
                    }
                  })()}
                </>
              }
              deleteItem={
                <>
                  항목명 :{' '}
                  {(() => {
                    switch (deleteType) {
                      case 'element':
                        return _deleteContent.elementAttributeName;
                      case 'account':
                        return _deleteContent.accountAttributeName;
                      case 'equipment':
                        return _deleteContent.equipmentAttributeName;
                      case 'contract':
                        return _deleteContent.contractAttributeName;

                      default:
                        return null;
                    }
                  })()}
                  <br />
                </>
              }
              deleteText={
                <>
                  {(() => {
                    switch (deleteType) {
                      case 'element':
                        return _deleteContent.elementAttributeName;
                      case 'account':
                        return _deleteContent.accountAttributeName;
                      case 'equipment':
                        return _deleteContent.equipmentAttributeName;
                      case 'contract':
                        return _deleteContent.contractAttributeName;

                      default:
                        return null;
                    }
                  })()}{' '}
                  항목에 포함된{' '}
                  <span className="cautionText">
                    모든{' '}
                    {(() => {
                      switch (deleteType) {
                        case 'element':
                          return '물품';
                        case 'account':
                          return '거래처';
                        case 'equipment':
                          return '설비';
                        case 'contract':
                          return '수주';

                        default:
                          return null;
                      }
                    })()}{' '}
                    정보
                  </span>
                  가 삭제됩니다.
                </>
              }
              deleteButton={
                <>
                  <button
                    className="formButton cancle"
                    onClick={() => {
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                  <button
                    className="formButton delete"
                    onClick={() => {
                      if (window.confirm('항목을 삭제합니다.')) {
                        switch (deleteType) {
                          case 'element':
                            return actDelete_element();
                          case 'account':
                            return actDelete_account();
                          case 'equipment':
                            return actDelete_equipment();
                          case 'contract':
                            return actDelete_contract();

                          default:
                            return;
                        }
                      } else return;
                    }}
                  >
                    삭제
                  </button>
                </>
              }
            />
          </main>
        </>
      }
    />
  );
};

export default DeleteAttributes;
