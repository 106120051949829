import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import TableSection from 'components/layouts/table/TableSection';
import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 951;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  display: grid;
  grid-template-rows: 80px calc(100% - 80px);
  height: 100%;
  overflow: hidden;
  width: 100%;

  & .section-alarm {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    gap: 20px;
    height: 100%;
    justify-content: flex-start;
    padding-inline: 20px;
    width: 100%;

    &>select {
      background-color: var(--ThirdBlue);
      border-radius: 10px;
      box-sizing: border-box;
      color: var(--white);
      height: 40px;
      padding: 5px 10px;
    }
    
    & .alarm-form {
      align-items: center;
      display: flex;
      gap: 10px;
      justify-content: flex-start;
      width: fit-content;

      input, select {
        border: 1px solid var(--gray-200);
        border-radius: 10px;
        box-sizing: border-box;
        height: 40px;
        padding: 5px 10px;
        width: 80px;
      }
      label {width: 50px;}
    }
  }

  & .TableSection {
    height: 100%;
    max-height: unset;
    overflow-x: auto;
    width: 100%;
  }

  button.btn-save {height: 40px; min-width: unset; width: 60px;}
  button.create {background-color: var(--MainBlue);}
  button.delete {background-color: var(--MainRed); margin: auto;}
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const SetWorkOrderAlarmModal = (props) => {
  const [_workOrderData, setWorkOrderData] = useState({});

  const [_alarmData, setAlarmData] = useState({
    beforeDay: 0,
    alarmHour: '00',
    alarmMinute: '00'
  });
  const [_alarmList, setAlarmList] = useState([]);

  const [_hourList, setHourList] = useState(() => {
    const returnList = [];
    for (let i = 0; i < 25; i++) {
      const hour = i.toString().padStart(2, '0');
      returnList.push(hour);
    }
    return returnList;
  })
  const [_minList, setMinList] = useState(() => {
    const returnList = [];
    for (let i = 0; i < 60; i++) {
      const min = i.toString().padStart(2, '0');
      returnList.push(min);
    }
    return returnList;
  })

  useEffect(() => {
    setWorkOrderData(() => { return props.workOrderData });
    setAlarmList(() => { return checkNullArray(props.workOrderAlarmList, []) });
  }, [props]);
  useEffect(() => { }, [_workOrderData, _alarmData, _alarmList]);

  const actCreate = (e) => {
    e.preventDefault();
    if (!checkEmptyNull(_workOrderData?.workOrderScheduledStartDate, false)) return alert('작업 시작일를 먼저 설정해주세요.');
    setAlarmList((prev) => { return [...prev, _alarmData]; })
  }
  const actDelete = (alarm, index) => {
    setAlarmList((prev => {
      const returnData = [...prev];
      returnData.splice(index, 1);
      return returnData;
    }))
  }

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>알람</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <div className='section-alarm'>
                <div className='alarm-form'>
                  <input
                    id='alarmDate'
                    type="number"
                    min={0}
                    value={_alarmData?.beforeDay}
                    onChange={(e) => setAlarmData((prev) => { return { ...prev, beforeDay: e.target.value * 1 } })}
                  />
                  <label htmlFor='alarmDate'> 일 전</label>
                </div>
                <div className='alarm-form'>
                  <select onChange={(e) => setAlarmData((prev) => { return { ...prev, alarmHour: e.target.value } })}>
                    {_hourList.map((hour, index) => {
                      return (<option key={index + '_hour'} value={hour}>{hour}</option>)
                    })}
                  </select>
                  <p>:</p>
                  <select onChange={(e) => setAlarmData((prev) => { return { ...prev, alarmMinute: e.target.value } })}>
                    {_minList.map((min, index) => {
                      return (<option key={index + '_min'} value={min}>{min}</option>)
                    })}
                  </select>
                </div>
                <button className="btn-save create" onClick={actCreate}>추가</button>
              </div>

              <TableSection content={
                <table>
                  <thead>
                    <tr style={{ border: 'none' }}>
                      <th>작업일자</th>
                      <th>알람</th>
                      <th style={{ minWidth: '80px', width: '80px' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {_alarmList.map((alarm, index) => {
                      return (
                        <tr key={index + '_alarm'}>
                          {index === 0 ? (<td rowSpan={_alarmList.length}>{_workOrderData?.workOrderScheduledStartDate}</td>) : null}
                          <td>{alarm.beforeDay} 일 전 {alarm.alarmHour}:{alarm.alarmMinute}</td>
                          <td style={{ minWidth: '80px', padding: 'unset', width: '80px' }}>
                            <button className="btn-save delete" onClick={(e) => { e.preventDefault(); actDelete(alarm, index); }}>삭제</button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              }
              ></TableSection>
            </ModalMain>

            <ModalFooter>
              <ModalButton onClick={() => { props.buttonEvent(_alarmList); }}>확인</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default SetWorkOrderAlarmModal;
