import styled from "styled-components";

export const OperatingMyPageComponent = styled.section`
  align-content: flex-start;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 50px auto;
  gap: 15px;
  height: 100%;
  overflow: hidden;
  padding: 30px 50px;
  width: 100%;

  & header {
    align-items: baseline;
    box-sizing: border-box;
    display: flex;
    height: 50px;
    justify-content: space-between;
    width: 100%;

    & h1 {
      font-size: 30px;
      font-weight: 600;
    }
  }

  &>div {
    & p {
      & span {
        padding-left: 20px;
      }
    }
  }
`;