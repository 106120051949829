import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';

import HistoricalDataGraph from "./HistoricalDataGraph"
import HistoricalDataTable from "./HistoricalDataTable"

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`

const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px 0 0;
`

const Btn = styled.button`
  padding: 10px 20px;
  margin-bottom: 10px;
`

const ComponentsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  padding: 20px;
`

const HistoricalDataPage = () => {
  const [toggle, setToggle] = useState('day');
  const [data, setData] = useState([]);
  const [processedData, setProcessedData] = useState([]);

  useEffect(() => {
    // 데이터를 비동기적으로 가져오기 위한 fetchData 함수 정의
    const fetchData = async () => {
      const apiUrl = '/api/sensorRealTimeData/search';
      const requestBody = {
        name: null,
        companyId: 1,
      };

      try {
        // axios를 사용하여 API에 POST 요청
        const response = await axios.post(apiUrl, requestBody);

        // API 응답에서 'content' 필드를 추출하여 데이터를 설정
        const { content } = response.data;
        setData(content);
      } catch (error) {
        // 오류가 발생한 경우 콘솔에 오류 메시지를 기록
        console.error('Error fetching data:', error);
      }
    };

    // 컴포넌트가 마운트될 때 fetchData 함수를 호출하여 초기 데이터를 가져옴
    fetchData();
  }, [toggle]); //toggle 상태 변화로 새로 API 호출


  useEffect(() => {
    // 데이터가 비어있다면 함수 실행을 중단
    if (data.length === 0) return;

    // 그룹화된 데이터를 저장할 객체를 초기화
    let groupedData = {};

    // 데이터 배열을 반복하면서 각 아이템의 'date' 필드를 가공
    data.forEach(item => {
      const date = new Date(item.date); // 데이터의 날짜 정보
      const dataYear = date.getFullYear(); // 연도 정보를 추출
      const dataMonth = date.getMonth() + 1; // 월 정보를 추출 (월은 0부터 시작하므로 +1)
      const dataDay = date.getDate(); // 일 정보를 추출

      let key;
      // 'toggle' 상태에 따라 그룹화에 사용할 키를 설정
      if (toggle === 'year') { // 연도별 그룹화
        key = `${dataYear}`;
      } else if (toggle === 'month') { // 월별 그룹화
        key = `${dataYear}-${dataMonth}`;
      } else { // 일별 그룹화 (기본)
        key = `${dataYear}-${dataMonth}-${dataDay}`;
      }

      // 그룹화된 데이터 객체에 키가 없으면 초기화
      if (!groupedData[key]) {
        groupedData[key] = 0;
      }

      // 그룹화된 데이터의 값을 업데이트
      groupedData[key] += parseFloat(item.value);
    });

    // 그룹화된 데이터를 배열로 변환
    const processedData = Object.entries(groupedData).map(([date, value]) => ({
      date,
      value
    }));

    // 처리된 데이터를 상태에 저장
    setProcessedData(processedData);
  }, [data, toggle]); // data와 toggle의 상태 변화로 컴포넌트 재렌더링 발생


  const handleToggle = (value) => {
    setToggle(value);
  };


  const getButtonStyle = (value) => ({
    border: toggle === value ? "" : "1px solid #282a3a",
    backgroundColor: toggle === value ? "#001d49" : "#f5f5f5",
    color: toggle === value ? "#fff" : "#001d49",
    borderRadius:
      value === "year" ? "10px 0 0 10px" :
        value === "month" ? "0" :
          value === "day" ? "0 10px 10px 0" :
            "0",
  });


  return (
    <PageWrapper>
      <BtnWrapper>
        <Btn onClick={() => handleToggle("year")} style={getButtonStyle("year")}>
          년
        </Btn>
        <Btn onClick={() => handleToggle("month")} style={getButtonStyle("month")}>
          월
        </Btn>
        <Btn onClick={() => handleToggle("day")} style={getButtonStyle("day")}>
          일
        </Btn>
      </BtnWrapper>
      <ComponentsWrapper>
        <HistoricalDataGraph toggle={toggle} data={processedData} />
        <HistoricalDataTable toggle={toggle} data={processedData} />
      </ComponentsWrapper>
    </PageWrapper>
  )
}

export default HistoricalDataPage