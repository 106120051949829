export const pageReducer_reset = () => {
  return {
    type: 'PAGERESET'
  }
};

export const pageReducer_getCurrentPage = (thisPage) => {
  return {
    type: 'GETCURRENTPAGE',
    payload: thisPage
  }
};

export const pageReducer_setMenuStauts = (thisStatus) => {
  return {
    type: 'SETMENUSTATUS',
    payload: thisStatus
  }
};

export const pageReducer_setPageNum = (thisNum) => {
  return {
    type: 'SETPAGENUM',
    payload: thisNum
  }
};

export const pageReducer_setTotalCount = (thisCount) => {
  return {
    type: 'SETTOTALCOUNT',
    payload: thisCount
  }
};

export const pageReducer_setPageSize = (thisSize) => {
  return {
    type: 'SETPAGESIZE',
    payload: thisSize
  }
};

export const pageReducer_setModalPageNum = (thisNum) => {
  return {
    type: 'SETMODALPAGENUM',
    payload: thisNum
  }
};

export const pageReducer_setModalTotalCount = (thisCount) => {
  return {
    type: 'SETMODALTOTALCOUNT',
    payload: thisCount
  }
};

export const pageReducer_setModalPageSize = (thisSize) => {
  return {
    type: 'SETMODALPAGESIZE',
    payload: thisSize
  }
};
