import React, { Fragment, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import GuideText from 'components/buttons/GuideText';
import SelectWorkPlaceModal from 'pages/types/modal/SelectWorkPlaceModal';

const DelProcessButton = styled.div`
  height: 15px;
  margin: 0px auto;
  width: 15px;

  div {background-color: var(--MainRed); cursor: pointer; height: 15px; width: 15px;}
`;

const ContentBox = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  height: 40px;
  justify-content: flex-start;
  margin: 5px auto;
  padding: 0px 15px;
  position: relative;
  width: 100%;
`;
const ContentButton = styled.div`
  align-items: center;
  background-color: var(--ThirdBlue);
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 0.8em;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const DeleteButton = styled.div`
  height: 15px;
  position: absolute;
  right: 15px;
  width: 15px;

  div {background-color: var(--white); cursor: pointer; height: 15px; width: 15px;}
`;

const ElementStockLogButton = styled.td`
  background-color: var(--MainYellow);
  color: var(--white);
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
`;

const CreateProcessRow = (props) => {
  const [ProcessData, setProcessData] = useState(props.content);

  const [ProcessKey, setProcessKey] = useState(ProcessData.processId + '_' + props.thisIndex);
  const [_processNumberArray, setProcessNumberArray] = useState(() => {
    const numberArray = [];
    for (let i = 1; i < props.processLength + 1; i++) {
      numberArray.push(i);
    }
    return numberArray;
  });
  const [ProcessEquipment, setProcessEquipment] = useState({ equipmentId: ProcessData.equipmentId, equipmentName: ProcessData.equipmentName });

  const [ProcessPutItems, setProcessPutItems] = useState(checkNullArray(props.content.processElementList, false) ? props.content.processElementList.filter((thisItem) => thisItem.processElementType === 'Put') : []);
  const [ProcessMakeItems, setProcessMakeItems] = useState(checkNullArray(props.content.processElementList, false) ? props.content.processElementList.filter((thisItem) => thisItem.processElementType === 'Make') : []);

  const [_rowLength, setRowLength] = useState(ProcessPutItems.length > ProcessMakeItems.length ? ProcessPutItems.length : ProcessMakeItems.length);

  const [ProcessAmount, setProcessAmount] = useState(checkEmptyNull(ProcessData.processAmount, 0));
  const [ProcessDivisors, setProcessDivisors] = useState(() => {
    if (checkEmptyNull(ProcessAmount, false)) {
      const divisors = [];
      let index = 1;
      while (index <= ProcessData.processAmount) {
        if (ProcessData.processAmount % index === 0) divisors.push(index);
        index++;
      }
      divisors.sort((a, b) => b - a);
      return divisors;
    } else return [1];
  });
  const [_touchCount, setTouchCount] = useState(BigNumber(checkEmptyNull(ProcessData.processAmount, 0)).dividedBy(BigNumber(checkEmptyNull(ProcessData.processingCount, 0))).toNumber());

  const [_workPlace, setWorkPlace] = useState({});
  const [_workPlaceModalStatus, setWorkPlaceModalStatus] = useState(false);

  const [_alarmList, setAlarmList] = useState([]);
  const [_alarmModal, setAlarmModal] = useState(false);

  const [_viewStatus, setViewStatus] = useState(props.viewStatus);

  useEffect(() => {
    setProcessData(() => { return props.content });
    setProcessKey(() => { return ProcessData.processId + '_' + props.thisIndex });
    setProcessNumberArray(() => {
      const numberArray = [];
      for (let i = 1; i < props.processLength + 1; i++) {
        numberArray.push(i);
      }
      return numberArray;
    });

    setProcessEquipment(() => { return { equipmentId: ProcessData.equipmentId, equipmentName: ProcessData.equipmentName } });

    const PutItems = checkNullArray(props.content.processElementList, false) ? props.content.processElementList.filter((thisItem) => thisItem.processElementType === 'Put') : [];
    const MakeItems = checkNullArray(props.content.processElementList, false) ? props.content.processElementList.filter((thisItem) => thisItem.processElementType === 'Make') : [];

    setProcessPutItems(() => { return PutItems });
    setProcessMakeItems(() => { return MakeItems });

    setRowLength(() => { return PutItems.length > MakeItems.length ? PutItems.length : MakeItems.length });

    setProcessAmount(() => { return props.content.processAmount });
    setProcessDivisors(() => {
      if (checkEmptyNull(ProcessAmount, false)) {
        const divisors = [];
        let index = 1;
        while (index <= props.content.processAmount) {
          if (props.content.processAmount % index === 0) divisors.push(index);
          index++;
        }
        divisors.sort((a, b) => b - a);
        return divisors;
      } else return [1];
    });

    const processTotalAmount = new BigNumber(checkEmptyNull(props.content.processAmount, 0)).toNumber();
    const processingCount = new BigNumber(checkEmptyNull(props.content.processingCount, 0)).toNumber();
    const touchCount = BigNumber(processTotalAmount).dividedBy(BigNumber(processingCount)).toNumber();
    setTouchCount(() => { return touchCount });

    setViewStatus(() => { return props.viewStatus });
  }, [props]);

  useEffect(() => { }, [_alarmList, _workPlace, ProcessData])

  const handleWorkPlace = (data) => {
    setWorkPlace(() => { return {} });
    const newDate = { ...data, place: {} };
    props.setProcessList((prev) => {
      const returnData = [...prev];
      returnData.splice(props.thisIndex, 1, newDate);
      return returnData;
    })
  }
  const handleEquipment = (data) => {
    setWorkPlace(() => { return {} });
    const newDate = { ...data, equipmentId: null, equipmentName: null };
    props.setProcessList((prev) => {
      const returnData = [...prev];
      returnData.splice(props.thisIndex, 1, newDate);
      return returnData;
    })
  }

  const returnTotalAmount = (amount) => {
    const unitAmount = new BigNumber(checkEmptyNull(amount, 0)).toNumber();
    const processTotalAmount = new BigNumber(checkEmptyNull(ProcessAmount, 0)).toNumber();
    const totalAmount = BigNumber(unitAmount).multipliedBy(BigNumber(processTotalAmount)).toNumber();
    return totalAmount;
  };

  const returnItems = () => {
    let returnArray = [];
    for (let i = 1; i < _rowLength; i++) {
      returnArray.push(
        <tr key={i + '_productRows'}>
          {ProcessMakeItems[i] !== undefined ? (
            <Fragment>
              {_viewStatus === 'detail' ? (
                <>
                  <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                    {checkNullObject(ProcessMakeItems[i].element, false) ? checkEmptyNull(ProcessMakeItems[i].element.elementName, '') : checkEmptyNull(ProcessMakeItems[i].elementName, '')}
                  </td>
                  <td>{checkNullObject(ProcessMakeItems[i].element, false) ? checkEmptyNull(ProcessMakeItems[i].element.elementCode, '') : checkEmptyNull(ProcessMakeItems[i].elementCode, '')}</td>
                  <td>
                    {checkEmptyNull(returnTotalAmount(ProcessMakeItems[i].amount), 0).toLocaleString() + ' '}
                    {checkNullObject(ProcessMakeItems[i].element, false) ? checkEmptyNull(ProcessMakeItems[i].element.elementUnit, '') : checkEmptyNull(ProcessMakeItems[i].elementUnit, '')}
                  </td>
                  <td>
                    {checkNullObject(ProcessMakeItems[i].element, false) ? checkEmptyNull(ProcessMakeItems[i].element.elementStock, 0).toLocaleString() + ' ' : checkEmptyNull(ProcessMakeItems[i].elementStock, 0).toLocaleString() + ' '}
                    {checkNullObject(ProcessMakeItems[i].element, false) ? checkEmptyNull(ProcessMakeItems[i].element.elementUnit, '') : checkEmptyNull(ProcessMakeItems[i].elementUnit, '')}
                  </td>
                </>
              ) : (
                <>
                  <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                    {checkNullObject(ProcessMakeItems[i].element, false) ? checkEmptyNull(ProcessMakeItems[i].element.elementName, '') : checkEmptyNull(ProcessMakeItems[i].elementName, '')}<br />
                    ({checkNullObject(ProcessMakeItems[i].element, false) ? checkEmptyNull(ProcessMakeItems[i].element.elementCode, '') : checkEmptyNull(ProcessMakeItems[i].elementCode, '')})
                  </td>
                  <td>
                    {checkEmptyNull(returnTotalAmount(ProcessMakeItems[i].amount), 0).toLocaleString() + ' '}
                    {checkNullObject(ProcessMakeItems[i].element, false) ? checkEmptyNull(ProcessMakeItems[i].element.elementUnit, '') : checkEmptyNull(ProcessMakeItems[i].elementUnit, '')}
                  </td>
                </>
              )}
            </Fragment>) : (
            <Fragment>
              {_viewStatus === 'detail' ? (
                <>
                  <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                  <td className="blankCell"></td>
                  <td className="blankCell"></td>
                  <td className="blankCell"></td>
                </>
              ) : (
                <>
                  <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                  <td className="blankCell"></td>
                </>
              )}
            </Fragment>
          )}
          {ProcessPutItems[i] !== undefined ? (
            <Fragment>
              {_viewStatus === 'detail' ? (
                <>
                  <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                    {checkNullObject(ProcessPutItems[i].element, false) ? checkEmptyNull(ProcessPutItems[i].element.elementName, '') : checkEmptyNull(ProcessPutItems[i].elementName, '')}
                  </td>
                  <td>{checkNullObject(ProcessPutItems[i].element, false) ? checkEmptyNull(ProcessPutItems[i].element.elementCode, '') : checkEmptyNull(ProcessPutItems[i].elementCode, '')}</td>
                  <td>
                    {checkEmptyNull(ProcessPutItems[i].amount, 0).toLocaleString() + ' '}
                    {checkNullObject(ProcessPutItems[i].element, false) ? checkEmptyNull(ProcessPutItems[i].element.elementUnit, '') : checkEmptyNull(ProcessPutItems[i].elementUnit, '')}
                  </td>
                  <td>
                    {checkEmptyNull(returnTotalAmount(ProcessPutItems[i].amount), 0).toLocaleString() + ' '}
                    {checkNullObject(ProcessPutItems[i].element, false) ? checkEmptyNull(ProcessPutItems[i].element.elementUnit, '') : checkEmptyNull(ProcessPutItems[i].elementUnit, '')}
                  </td>
                  <td>
                    {checkNullObject(ProcessPutItems[i].element, false) ? checkEmptyNull(ProcessPutItems[i].element.scheduledInputStock, 0).toLocaleString() + ' ' : checkEmptyNull(ProcessPutItems[i].scheduledInputStock, 0).toLocaleString() + ' '}
                    {checkNullObject(ProcessPutItems[i].element, false) ? checkEmptyNull(ProcessPutItems[i].element.elementUnit, '') : checkEmptyNull(ProcessPutItems[i].elementUnit, '')}
                  </td>
                  <td>
                    {checkNullObject(ProcessPutItems[i].element, false) ? checkEmptyNull(ProcessPutItems[i].element.elementStock, 0).toLocaleString() : checkEmptyNull(ProcessPutItems[i].elementStock, 0).toLocaleString()}
                    {checkNullObject(ProcessPutItems[i].element, false) ? checkEmptyNull(ProcessPutItems[i].element.elementUnit, '') : checkEmptyNull(ProcessPutItems[i].elementUnit, '')}
                  </td>
                  <ElementStockLogButton onClick={(e) => { e.preventDefault(); props.actTrackingElementStockLog(ProcessPutItems[i], `${props.thisIndex}_${i}`, ProcessData); }}>
                    재고연동<GuideText />
                  </ElementStockLogButton>
                </>
              ) : (
                <>
                  <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                    {checkNullObject(ProcessPutItems[i].element, false) ? checkEmptyNull(ProcessPutItems[i].element.elementName, '') : checkEmptyNull(ProcessPutItems[i].elementName, '')}<br />
                    ({checkNullObject(ProcessPutItems[i].element, false) ? checkEmptyNull(ProcessPutItems[i].element.elementCode, '') : checkEmptyNull(ProcessPutItems[i].elementCode, '')})
                  </td>
                  <td>
                    {checkEmptyNull(returnTotalAmount(ProcessPutItems[i].amount), 0).toLocaleString() + ' '}
                    {checkNullObject(ProcessPutItems[i].element, false) ? checkEmptyNull(ProcessPutItems[i].element.elementUnit, '') : checkEmptyNull(ProcessPutItems[i].elementUnit, '')}
                  </td>
                  <ElementStockLogButton onClick={(e) => { e.preventDefault(); props.actTrackingElementStockLog(ProcessPutItems[i], `${props.thisIndex}_${i}`, ProcessData); }}>
                    재고연동<GuideText />
                  </ElementStockLogButton>
                </>
              )}
            </Fragment>) : (
            <Fragment>
              {_viewStatus === 'detail' ? (
                <>
                  <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                  <td className="blankCell"></td>
                  <td className="blankCell"></td>
                  <td className="blankCell"></td>
                  <td className="blankCell"></td>
                  <td className="blankCell"></td>
                  <td className="blankCell"></td>
                </>
              ) : (
                <>
                  <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                  <td className="blankCell"></td>
                  <td className="blankCell"></td>
                </>
              )}
            </Fragment>
          )}
        </tr>
      );
    }
    return returnArray;
  };

  return (
    <Fragment key={ProcessKey}>
      <tr data-key={ProcessKey}>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ minWidth: '50px', width: '50px' }}>
          <DelProcessButton onClick={() => { props.delEvent(props.thisIndex, ProcessData) }}><CloseButtonIcon /></DelProcessButton>
        </td>
        {/* <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ minWidth: '80px', width: '80px' }}>
          <input
            type="checkBox"
            name="processes"
            checked={checkEmptyNull(ProcessData.target, false)}
            onChange={(e) => {
              const isChecked = e.target.checked;
              props.handleCheckedItem(props.thisIndex, ProcessData, isChecked);

              if (isChecked) {
                document.getElementsByName('processes').forEach((thisAttr) => { thisAttr.checked = false });
                e.target.checked = true;
              } else {
                e.target.checked = false;
              }
            }}
            onClick={(e) => { e.stopPropagation() }}
          />
        </td> */}
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ minWidth: '80px', width: '80px' }}>
          <select
            name="processNumber"
            value={ProcessData.processNumber}
            onInput={(e) => { props.handleNumberEvent(e, props.thisIndex, ProcessData) }}
          >
            {_processNumberArray.map((thisNumber) => {
              return (<option key={thisNumber + '_processNumber'} value={thisNumber}>{thisNumber}</option>);
            })}
          </select>
        </td>
        {/* <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          <ContentButton onClick={() => {setAlarmModal(true);}}>알림설정</ContentButton>
          {_alarmList.map((alarm, index) => {
            return (<ContentBox key={index + '_alarm'}>{alarm?.date}일 전 {alarm?.hour}:{alarm?.min}</ContentBox>)
          })}
          {_alarmModal === true && (
            <SetAlarmModal
              buttonTitle={'확인'}
              buttonEvent={(data) => {
                setWorkPlace(() => { return data });
                // setProcessData((prev) => {return {...prev, alarm: data};})
                setProcessData((prev) => {
                  const newDate = {...prev, alarm: data};
                  props.setProcessList((prev) => {
                    const returnData = [...prev];
                    returnData(props.thisIndex, 1, newDate);
                    return returnData;
                  })
                  return newDate;
                })            
                setTimeout(setAlarmModal(false), 1000);
              }}
              open={_alarmModal}
              close={() => { setAlarmModal(false) }}
            />
          )}
        </td> */}
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{ProcessData.processTypeName}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{ProcessData.processCode}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{ProcessData.processName}</td>

        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          <input
            type='date'
            value={checkEmptyNull(ProcessData.processScheduledStartDate, '')}
            onChange={(e) => {
              if (!checkEmptyNull(e.target.value, false)) {
                props.setProcessAlarmList((prev) => {
                  const returnData = [...prev];
                  const alarmIndex = returnData.findIndex(item => item.process.processId === ProcessData.processId);
                  returnData.splice(alarmIndex, 1);
                  return returnData;
                })
              }
              props.setProcessList((prev) => {
                const newDate = { ...ProcessData, processScheduledStartDate: e.target.value };
                const returnData = [...prev];
                returnData.splice(props.thisIndex, 1, newDate);
                return returnData;
              })
            }}
          />
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          <ContentButton onClick={() => { setWorkPlaceModalStatus(true); }}>위치 선택</ContentButton>
          {checkNullObject(ProcessData.place, false) ? (
            <ContentBox>
              {ProcessData.place?.placeName}
              <DeleteButton onClick={() => { handleWorkPlace(ProcessData); }}><CloseButtonIcon /></DeleteButton>
            </ContentBox>
          ) : null}
          {_workPlaceModalStatus === true && (
            <SelectWorkPlaceModal
              buttonTitle={'위치 선택'}
              buttonEvent={(data) => {
                setWorkPlace(() => { return data });
                // setProcessData((prev) => {return {...prev, place: data};})
                const newDate = { ...ProcessData, place: data };
                props.setProcessList((prev) => {
                  const returnData = [...prev];
                  returnData.splice(props.thisIndex, 1, newDate);
                  return returnData;
                })
                setTimeout(setWorkPlaceModalStatus(false), 1000);
              }}
              open={_workPlaceModalStatus}
              close={() => { setWorkPlaceModalStatus(false) }}
            />
          )}
        </td>

        {(props.authority.indexOf('108-1') !== -1 || props.authority.indexOf('108-2') !== -1) ? (
          <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ position: 'relative' }}>
            <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'flex-start', width: '100%' }}>
              <ContentButton data-key={ProcessKey} onClick={props.selectEquipmentEvent}>선택</ContentButton>
              {checkEmptyNull(ProcessEquipment.equipmentName, false) ? (
                <ContentBox name="processEquipemnt" data-key={ProcessKey}>
                  {ProcessEquipment.equipmentName}
                  <DeleteButton onClick={() => { handleEquipment(ProcessData); }}><CloseButtonIcon /></DeleteButton>
                </ContentBox>
              ) : null}
            </div>
          </td>
        ) : null}

        {_viewStatus === 'detail' ? <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{ProcessData.remark}</td> : null}

        {/* <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ position: 'relative' }}>
          <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'flex-start', width: '100%' }}>
            <ContentButton
              onClick={() => {
                props.handleEventProcessLog(props.thisIndex, ProcessData);
                props.setWorkerModal('processLogManagerIds');
                setTimeout(props.setWorkerModalStatus(true), 1000);
              }}
            >
              선택
            </ContentButton>
            <ContentList>
              {checkNullArray(ProcessData.managers, []).map((thisItem, index) => {
                return (
                  <ContentBox key={thisItem.id + '_processLogManagerIds'}>
                    {thisItem.name}
                    <DeleteButton data-key={ProcessKey} data-index={props.thisIndex} onClick={() => { props.actDeleteWorkers('processLogManagerIds', props.thisIndex, index) }}><CloseButtonIcon /></DeleteButton>
                  </ContentBox>
                );
              })}
            </ContentList>
          </div>
        </td> */}

        {/* <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ position: 'relative' }}>
          <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'flex-start', width: '100%' }}>
            <ContentButton
              onClick={() => {
                props.handleEventProcessLog(props.thisIndex, ProcessData);
                props.setWorkerModal('processLogWorkerIds');
                setTimeout(props.setWorkerModalStatus(true), 1000);
              }}
            >
              선택
            </ContentButton>
            <ContentList>
              {checkNullArray(ProcessData.workers, []).map((thisItem, index) => {
                return (
                  <ContentBox key={thisItem.id + '_processLogWorkerIds'}>
                    {thisItem.name}
                    <DeleteButton data-key={ProcessKey} data-index={props.thisIndex} onClick={() => { props.actDeleteWorkers('processLogWorkerIds', props.thisIndex, index) }}><CloseButtonIcon /></DeleteButton>
                  </ContentBox>
                );
              })}
            </ContentList>
          </div>
        </td> */}

        {/* <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          <select
            name="processingCount"
            data-key={ProcessData.processId}
            data-index={props.thisIndex}
            value={ProcessData.processingCount}
            onChange={(e) => { props.handleProcessCountingEvent(e) }}
          >
            {ProcessDivisors.map((thisItem) => {
              return (<option key={ProcessKey + '_ProcessDivisors_' + thisItem} value={thisItem}>{thisItem}</option>);
            })}
          </select>
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}><CommaNum displayType="text" num={_touchCount} /></td> */}

        {ProcessMakeItems[0] !== undefined ? (
          <Fragment>
            {_viewStatus === 'detail' ? (
              <>
                <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                  {checkNullObject(ProcessMakeItems[0].element, false) ? checkEmptyNull(ProcessMakeItems[0].element.elementName, '') : checkEmptyNull(ProcessMakeItems[0].elementName, '')}
                </td>
                <td>{checkNullObject(ProcessMakeItems[0].element, false) ? checkEmptyNull(ProcessMakeItems[0].element.elementCode, '') : checkEmptyNull(ProcessMakeItems[0].elementCode, '')}</td>
                <td>
                  {checkEmptyNull(returnTotalAmount(ProcessMakeItems[0].amount), 0).toLocaleString() + ' '}
                  {checkNullObject(ProcessMakeItems[0].element, false) ? checkEmptyNull(ProcessMakeItems[0].element.elementUnit, '') : checkEmptyNull(ProcessMakeItems[0].elementUnit, '')}
                </td>
                <td>
                  {checkNullObject(ProcessMakeItems[0].element, false) ? checkEmptyNull(ProcessMakeItems[0].element.elementStock, 0).toLocaleString() + ' ' : checkEmptyNull(ProcessMakeItems[0].elementStock, 0).toLocaleString() + ' '}
                  {checkNullObject(ProcessMakeItems[0].element, false) ? checkEmptyNull(ProcessMakeItems[0].element.elementUnit, '') : checkEmptyNull(ProcessMakeItems[0].elementUnit, '')}
                </td>
              </>
            ) : (
              <>
                <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                  {checkNullObject(ProcessMakeItems[0].element, false) ? checkEmptyNull(ProcessMakeItems[0].element.elementName, '') : checkEmptyNull(ProcessMakeItems[0].elementName, '')}<br />
                  ({checkNullObject(ProcessMakeItems[0].element, false) ? checkEmptyNull(ProcessMakeItems[0].element.elementCode, '') : checkEmptyNull(ProcessMakeItems[0].elementCode, '')})
                </td>
                <td>
                  {checkEmptyNull(returnTotalAmount(ProcessMakeItems[0].amount), 0).toLocaleString() + ' '}
                  {checkNullObject(ProcessMakeItems[0].element, false) ? checkEmptyNull(ProcessMakeItems[0].element.elementUnit, '') : checkEmptyNull(ProcessMakeItems[0].elementUnit, '')}
                </td>
              </>
            )}
          </Fragment>) : (
          <Fragment>
            {_viewStatus === 'detail' ? (
              <>
                <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                <td className="blankCell"></td>
                <td className="blankCell"></td>
                <td className="blankCell"></td>
              </>
            ) : (
              <>
                <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                <td className="blankCell"></td>
              </>
            )}
          </Fragment>
        )}
        {ProcessPutItems[0] !== undefined ? (
          <Fragment>
            {_viewStatus === 'detail' ? (
              <>
                <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                  {checkNullObject(ProcessPutItems[0].element, false) ? checkEmptyNull(ProcessPutItems[0].element.elementName, '') : checkEmptyNull(ProcessPutItems[0].elementName, '')}
                </td>
                <td>{checkNullObject(ProcessPutItems[0].element, false) ? checkEmptyNull(ProcessPutItems[0].element.elementCode, '') : checkEmptyNull(ProcessPutItems[0].elementCode, '')}</td>
                <td>
                  {checkEmptyNull(ProcessPutItems[0].amount, 0).toLocaleString() + ' '}
                  {checkNullObject(ProcessPutItems[0].element, false) ? checkEmptyNull(ProcessPutItems[0].element.elementUnit, '') : checkEmptyNull(ProcessPutItems[0].elementUnit, '')}
                </td>
                <td>
                  {checkEmptyNull(returnTotalAmount(ProcessPutItems[0].amount), 0).toLocaleString() + ' '}
                  {checkNullObject(ProcessPutItems[0].element, false) ? checkEmptyNull(ProcessPutItems[0].element.elementUnit, '') : checkEmptyNull(ProcessPutItems[0].elementUnit, '')}
                </td>
                <td>
                  {checkNullObject(ProcessPutItems[0].element, false) ? checkEmptyNull(ProcessPutItems[0].element.scheduledInputStock, 0).toLocaleString() + ' ' : checkEmptyNull(ProcessPutItems[0].scheduledInputStock, 0).toLocaleString() + ' '}
                  {checkNullObject(ProcessPutItems[0].element, false) ? checkEmptyNull(ProcessPutItems[0].element.elementUnit, '') : checkEmptyNull(ProcessPutItems[0].elementUnit, '')}
                </td>
                <td>
                  {checkNullObject(ProcessPutItems[0].element, false) ? checkEmptyNull(ProcessPutItems[0].element.elementStock, 0).toLocaleString() : checkEmptyNull(ProcessPutItems[0].elementStock, 0).toLocaleString()}
                  {checkNullObject(ProcessPutItems[0].element, false) ? checkEmptyNull(ProcessPutItems[0].element.elementUnit, '') : checkEmptyNull(ProcessPutItems[0].elementUnit, '')}
                </td>
                <ElementStockLogButton onClick={(e) => { e.preventDefault(); props.actTrackingElementStockLog(ProcessPutItems[0], `${props.thisIndex}_${0}`, ProcessData); }}>
                  재고연동<GuideText />
                </ElementStockLogButton>
              </>
            ) : (
              <>
                <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                  {checkNullObject(ProcessPutItems[0].element, false) ? checkEmptyNull(ProcessPutItems[0].element.elementName, '') : checkEmptyNull(ProcessPutItems[0].elementName, '')}<br />
                  ({checkNullObject(ProcessPutItems[0].element, false) ? checkEmptyNull(ProcessPutItems[0].element.elementCode, '') : checkEmptyNull(ProcessPutItems[0].elementCode, '')})
                </td>
                <td>
                  {checkEmptyNull(returnTotalAmount(ProcessPutItems[0].amount), 0).toLocaleString() + ' '}
                  {checkNullObject(ProcessPutItems[0].element, false) ? checkEmptyNull(ProcessPutItems[0].element.elementUnit, '') : checkEmptyNull(ProcessPutItems[0].elementUnit, '')}
                </td>
                <ElementStockLogButton onClick={(e) => { e.preventDefault(); props.actTrackingElementStockLog(ProcessPutItems[0], `${props.thisIndex}_${0}`, ProcessData); }}>
                  재고연동<GuideText />
                </ElementStockLogButton>
              </>
            )}
          </Fragment>) : (
          <Fragment>
            {_viewStatus === 'detail' ? (
              <>
                <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                <td className="blankCell"></td>
                <td className="blankCell"></td>
                <td className="blankCell"></td>
                <td className="blankCell"></td>
                <td className="blankCell"></td>
                <td className="blankCell"></td>
              </>
            ) : (
              <>
                <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                <td className="blankCell"></td>
                <td className="blankCell"></td>
              </>
            )}
          </Fragment>
        )}
      </tr>
      {_rowLength > 1 && returnItems()}
    </Fragment>
  );
};

export default CreateProcessRow;
