export const workerReducer_resetWorkerReducer = (content) => {
  return {
    type: 'RESETWORKDERREDUCER',
    payload: content,
  };
};

export const workerReducer_setWorkOrderLogManagerIds = (content) => {
  return {
    type: 'SETWORKORDERLOGMANAGERIDS',
    payload: content,
  };
};
export const workerReducer_setWorkOrderLogWorkderIds = (content) => {
  return {
    type: 'SETWORKORDERLOGWORKERIDS',
    payload: content,
  };
};

export const workerReducer_setProcessLogManagerIds = (content) => {
  return {
    type: 'SETPROCESSLOGMANAGERIDS',
    payload: content,
  };
};
export const workerReducer_setProcessLogWorkderIds = (content) => {
  return {
    type: 'SETPROCESSLOGWORKERIDS',
    payload: content,
  };
};
