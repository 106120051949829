import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { barcodeActions_setBarcode, barcodeActions_setBarcodeContent } from 'store/modules/actions/common/barcodeActions';

import { barcodeApi } from 'api/apis/barcode/barcodeApi';

import { checkEmptyNull, checkNullObject } from 'components/checkValues/checkValues';
import { barcodeButton } from 'components/icons/src';

import BarcodeInfo from 'pages/barcode/scan/BarcodeInfo';

const BarcodeBg = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  cursor: pointer;
  display: flex;
  height: 100svh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100svw;
  z-index: 950;
`;
const BarcodeSection = styled.div`
  align-content: center;
  display: grid;
  justify-items: center;
  /* overflow: hidden; */

  #barcode {
    background: unset;
    border: 1px solid var(--white);
    box-sizing: border-box;
    color: var(--white);
    height: 60px;
    margin-top: 20px;
    padding: 8px 16px;
    text-align: center;
  }
  #barcode ~ div, #barcode ~ a {
    border-bottom: 1px solid var(--white);
    box-sizing: border-box;
    color: var(--white);
    font-weight: 600;
    justify-self: flex-start;
    margin-top: 20px;
    padding-bottom: 3px;
    &:hover {
      transform: scale(1.1);
    }
  }
  #barcode ~ p {
    color: var(--white);
    font-size: 0.7em;
    justify-self: flex-start;
    margin-top: 2px;
    opacity: 0.7;
  }
`;
const Barcode = styled.div`
  background-color: var(--white);
  height: 200px;
  width: 200px;

  mask-image: url(${barcodeButton}); mask-repeat: no-repeat; mask-size: contain;
  --webkit-mask-image: url(${barcodeButton}); --webkit-mask-repeat: no-repeat; --webkit-mask-size: contain;
`;

const ScanBarcode = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { barcodeReducer, pageReducer } = useSelector(state => state);

  const BarcodeRef = useRef();
  const [_scanStatus, setScanStatus] = useState(false);

  let scan = null;

  useEffect(() => { }, [barcodeReducer])
  useEffect(() => { }, [_scanStatus])
  useEffect(() => { }, [BarcodeRef.current])

  const goPrev = () => {
    // 스캔 이전 페이지로 이동
    console.log('pageReducer.currentPage : ', pageReducer.currentPage);
    dispatch(barcodeActions_setBarcode(null));
    dispatch(barcodeActions_setBarcodeContent({}));
    navigate(pageReducer.currentPage, { replace: true });
  }
  const actClear = () => {
    dispatch(barcodeActions_setBarcode(null));
    dispatch(barcodeActions_setBarcodeContent({}));
    if (BarcodeRef.current) {
      BarcodeRef.current.value = '';
      // BarcodeRef.current.autoFocus = true;
      BarcodeRef.current.disabled = false;
      BarcodeRef.current.focus();
    }
    setScanStatus(() => { return false });
  }
  const actAlert = () => {
    alert(`
데이터를 찾을 수 없습니다.
바코드를 확인해 주세요.
    `);
    actClear();
  }

  const setResponse = (barcode, response) => {
    if (response === undefined) return actAlert();
    console.log('response : ', response.data);
    dispatch(barcodeActions_setBarcode(barcode));
    dispatch(barcodeActions_setBarcodeContent(response.data));
  }
  const getElement = async (barcode) => {
    // console.log('barcode : ', barcode);
    const elementId = barcode.toString().substring(1);
    await barcodeApi.getElement(elementId).then(response => {
      setResponse(barcode, response);
    })
  }
  const getStockLog = async (barcode) => {
    const elementStockLogId = barcode.toString().substring(1);
    await barcodeApi.getStockLog(elementStockLogId).then(response => {
      setResponse(barcode, response);
    })
  }
  const getLot = async (barcode) => {
    const processResultId = barcode.toString().substring(1);
    await barcodeApi.getLot(processResultId).then(response => {
      setResponse(barcode, response);
    })
  }
  const getSerial = async (barcode) => {
    const processResultElementId = barcode.toString().substring(1);
    await barcodeApi.getSerial(processResultElementId).then(response => {
      setResponse(barcode, response);
    })
  }
  const actScan = async (barcode) => {
    // console.log('barcode : ', barcode);
    if (_scanStatus) return;
    setScanStatus(() => { return true });

    if (checkEmptyNull(barcode, false)) {
      BarcodeRef.current.disabled = true;
      const reg = /[^(a-zA-Z0-9)]/;
      if (reg.test(barcode)) {
        // console.log('barcode test : ', barcode);
        alert(`
영어, 숫자만 스캔 가능합니다.
한/영 전환 후 다시 스캔해주세요.
        `);
        actClear();
        return;
      }
      if (!checkEmptyNull(barcode, false)) {
        alert(`
바코드를 읽을 수 없습니다.
다시 스캔해주세요.
        `);
        actClear();
        return;
      }

      const barcodeCode = barcode.charAt(0).toUpperCase();
      // console.log('barcodeCode : ', barcodeCode);

      switch (barcodeCode) {
        case 'A': await getElement(barcode); break; // 기준정보
        case 'B': await getStockLog(barcode); break; // 재고상세이력
        case 'C': await getLot(barcode); break; // Lot
        case 'D': await getSerial(barcode); break; // Serial

        default: return actAlert();
      }
    }
  }

  return (
    <BarcodeBg onClick={goPrev}>
      <BarcodeSection onClick={e => { e.stopPropagation(); }}>
        {checkNullObject(barcodeReducer.barcodeContent, false) ?
          <BarcodeInfo actClear={actClear} />
          : <>
            <Barcode />
            <input type="text"
              ref={BarcodeRef} id="barcode"
              autoFocus={true}
              onChange={(e) => {
                clearTimeout(scan);
                scan = setTimeout(() => {
                  const barcode = e.target.value;
                  actScan(barcode);
                }, 1000)
              }}
            />
            <div onClick={() => { actScan(BarcodeRef.current.value); }}>바코드 스캔</div>
            <p>화면이 자동으로 안 넘어가는 경우 스캔 버튼을 눌려주세요.</p>
            <div onClick={() => { actClear(); }}>바코드 초기화</div>
            <p>바코드를 스캔란을 초기화합니다.</p>
            <Link to={'/barcode/print'}>바코드 출력</Link>
            <p>바코드 출력 페이지로 넘어갑니다.</p>
          </>
        }
      </BarcodeSection>
    </BarcodeBg>
  );
};

export default ScanBarcode;