import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

import { closeButtonIconUrl } from 'components/icons/src';
import SelectDrawingModal from 'pages/drawing/modal/SelectDrawingModal';
import TableSection from 'components/layouts/table/TableSection';
import { DateTimeFormat } from 'components/format/DateTimeFormat';

const ProcessFormSection = styled.div`
  height: 100%;
  width: 100%;

  & .TableSection {
    height: 100%;
    width: 100%;

    tr {border-bottom: 10px solid var(--MainNavy);}
    td, th {
      padding: 0px;
      min-height: 250px;
      min-width: unset;
      white-space: inherit!important;
    }
  }
`;

const ProcessNumber = styled.td`
  background-color: ${props => { return `var(--${props.status})`; }};
  cursor: pointer;
  width: 60px;
`;
const ContentCell = styled.td`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 60px 60px 250px;
  height: 100%;
  width: 200px;
  
  & .content-top, & .content-middle {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    & input {
      align-self: center;
      border: 4px solid var(--MainNavy);
      box-sizing: border-box;
      font-size: 18px;
      height: 45px;
      justify-self: center;
      padding: 10px;
      width: calc(100% - 10px);
    }
  }

  & .content-top {
    display: grid;
    gap: 2%;
    grid-template-columns: 49% 49%;
    height: 100%;
    width: 100%;
  }
  & .content-bottom {
    display: grid;
    gap: 2%;
    grid-template-rows: 30% 68%;
    height: 100%;
    width: 100%;

    & .button-file {
      align-items: center;
      background-color: var(--MainNavy);
      color: var(--white);
      cursor: pointer;
      display: flex;
      font-size: 18px;
      font-weight: 600;
      justify-content: center;
      outline: 4px solid var(--white);
      outline-offset: -8px;
      height: 100%;
      width: 100%;
    }
    & .preview-file {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      position: relative;
      width: 100%;

      & img{
        height: auto;
        left: 50%;
        max-height: 90%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        z-index: 10;
      }
    }
  }
`;
const MaterialsCell = styled.td`
  background-color: ${props => { return props.status === 'styled' ? 'var(--gray-100)' : ''; }};
  cursor: pointer;
  
  & textarea {
    font-style: ${props => { return props.status === 'styled' ? 'italic' : ''; }};
    font-weight: ${props => { return props.status === 'styled' ? '600' : ''; }};
    height: calc(100% - 60px);
    width: calc(100% - 50px);
  }
`;
const ColorsCell = styled(MaterialsCell)`
  background-color: ${props => { return `var(--${props.status})`; }};
`;

const TaskCells = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100%;
  width: 100%;
`;
const TaskCell = styled.div`
  border: 1px solid var(--gray-200);
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 1fr 40px;
  position: relative;

  &.h100 {
    grid-template-rows: 100%;
  }

  &>div>div {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    padding: 10px 30px 10px 10px;
    position: relative;
  }

  & .button-create-task {
    align-items: center;
    background-color: var(--MainBlue);
    color: var(--white);
    cursor: pointer;
    display: flex;
    font-weight: 600;
    height: 40px;
    justify-content: center;
    transition: all 0.2s;
    width: 100%;
  }
  & .button-delete-task {
    background-color: var(--MainNavy);
    cursor: pointer;
    position: absolute;
    height: 15px;
    right: 10px;
    width: 15px;
    mask-image: url(${closeButtonIconUrl}); mask-repeat: no-repeat; mask-size: cover;
    --webkit-mask-image: url(${closeButtonIconUrl}); --webkit-mask-repeat: no-repeat; --webkit-mask-size: cover;
    &:hover {background-color: var(--MainRed); transform: scale(1.2);}
  }
`;

const RemarkCell = styled.td`
  & textarea {
    height: 100%;
    width: 100%;
  }
`;

const DeleteProcessButton = styled.td`
  background-color: var(--ThirdRed);
  color: var(--white);
  cursor: pointer;
  font-weight: 600;
`;

const ProcessForm = (props) => {
  const taskTypeArray = ['BF', 'CP', 'SF', 'MF', 'GB', 'MC'];

  const [_processLogList, setProcessLogList] = useState(props.processLogList);

  const [_eventProcessLog, setEventProcessLog] = useState({});
  const [_fileModalStatus, setFileModalStatus] = useState(false);

  useEffect(() => { setProcessLogList(() => { return props.processLogList }); }, [props]);
  useEffect(() => { console.log('ProcessForm_processLogList : ', _processLogList); }, [_processLogList]);

  const handleInputEvent = (e, processLog, index) => {
    const { name, value } = e.target;
    const newData = { ...processLog, [name]: value }
    props.setProcessLogList(prev => {
      const returnData = [...prev];
      returnData.splice(index, 1, newData);
      return returnData;
    })
  }
  const handleCustomData = (e, processLog, index) => {
    const { name, value } = e.target;
    const processCustomizedContent = checkNullObject(processLog.processCustomizedContent, {});
    const newData = {
      ...processLog,
      processCustomizedContent: { ...processCustomizedContent, [name]: value }
    }
    props.setProcessLogList(prev => {
      const returnData = [...prev];
      returnData.splice(index, 1, newData);
      return returnData;
    })
  }
  const handleProcessNumber = (processLog, index) => {
    const processCustomizedContent = checkNullObject(processLog.processCustomizedContent, {});
    const currentColor = processCustomizedContent?.numStatus;
    let nextColor = '';
    switch (currentColor) {
      case 'white': nextColor = 'LightPink'; break;
      case 'LightPink': nextColor = 'LightBlue'; break;
      case 'LightBlue': nextColor = 'white'; break;
      default: nextColor = 'white'; break;
    }

    const newData = {
      ...processLog,
      processCustomizedContent: { ...processCustomizedContent, numStatus: nextColor }
    }
    props.setProcessLogList(prev => {
      const returnData = [...prev];
      returnData.splice(index, 1, newData);
      return returnData;
    })
  }
  const handleMaterials = (processLog, index) => {
    const processCustomizedContent = checkNullObject(processLog.processCustomizedContent, {});
    const currentStyle = processCustomizedContent?.materialStatus;
    let nextStyle = '';
    switch (currentStyle) {
      case 'normal': nextStyle = 'styled'; break;
      case 'styled': nextStyle = 'normal'; break;
      default: nextStyle = 'normal'; break;
    }

    const newData = {
      ...processLog,
      processCustomizedContent: { ...processCustomizedContent, materialStatus: nextStyle }
    }
    props.setProcessLogList(prev => {
      const returnData = [...prev];
      returnData.splice(index, 1, newData);
      return returnData;
    })
  }
  const handleColors = (processLog, index) => {
    const processCustomizedContent = checkNullObject(processLog.processCustomizedContent, {});
    const currentStyle = processCustomizedContent?.colorStatus;
    let nextColor = '';
    switch (currentStyle) {
      case 'white': nextColor = 'Yellow'; break;
      case 'Yellow': nextColor = 'white'; break;
      default: nextColor = 'white'; break;
    }

    const newData = {
      ...processLog,
      processCustomizedContent: { ...processCustomizedContent, colorStatus: nextColor }
    }
    props.setProcessLogList(prev => {
      const returnData = [...prev];
      returnData.splice(index, 1, newData);
      return returnData;
    })
  }

  const selectFile = (processLog, index) => {
    setEventProcessLog(() => { return { ...processLog, index: index } });
    setTimeout(setFileModalStatus(() => { return true }), 1000);
  }

  const actCreateTask = (taskType, processLog, index) => {
    const promptText = `
추가할 작업을 입력해주세요.
    `;
    const taskName = window.prompt(promptText, '');
    if (!checkEmptyNull(taskName, false)) return;
    const taskData = {
      taskType: taskType,
      taskName: taskName,
    }
    const taskLogList = checkNullArray(processLog?.taskLogList, []);
    taskLogList.push(taskData);
    const newData = { ...processLog, taskLogList: taskLogList }
    props.setProcessLogList(prev => {
      const returnData = [...prev];
      returnData.splice(index, 1, newData);
      return returnData;
    })
  }
  const actDeleteTask = (taskData, processLog, index) => {
    const taskLogList = [...checkNullArray(processLog?.taskLogList, [])];
    const checkedIndex = taskLogList.findIndex(item => item.taskType === taskData?.taskType && item.taskName === taskData?.taskName);
    taskLogList.splice(checkedIndex, 1);
    const newData = { ...processLog, taskLogList: taskLogList }
    props.setProcessLogList(prev => {
      const returnData = [...prev];
      returnData.splice(index, 1, newData);
      return returnData;
    })
  }
  const actDeleteProcess = (processLog, index) => {
    props.setProcessLogList(prev => {
      const returnData = [...prev];
      returnData.splice(index, 1);
      return returnData;
    })
  }

  return (
    <>
      <ProcessFormSection>
        <TableSection content={
          <table style={{ zoom: `96%` }}>
            <colgroup>
              <col width={'60px'}></col>

              <col width={'200px'}></col>

              <col width={'250px'}></col>
              <col width={'250px'}></col>

              <col width={'150px'}></col>
              <col width={'150px'}></col>
              <col width={'150px'}></col>
              <col width={'150px'}></col>
              <col width={'150px'}></col>

              <col width={'150px'}></col>

              <col width={'150px'}></col>

              <col width={'150px'}></col>

              <col width={'60px'}></col>
            </colgroup>
            <thead>
              <tr>
                {['NO', '', '자재', '색상', 'B/F', 'C/P', 'S/F', 'M/F', 'G/B', '유리종류', 'M/C', '비고', ''].map((key, index) => {
                  return (<th key={index + '_key'}>{key}</th>)
                })}
              </tr>
            </thead>
            <tbody id='processLogList'>
              {checkNullArray(_processLogList, []).map((processLog, index) => {
                const key = checkEmptyNull(processLog.processLogId, 'key_' + checkEmptyNull(processLog.key, false) ? processLog.key : index);
                const processCustomizedContent = checkNullObject(processLog.processCustomizedContent, {});
                const materials = processCustomizedContent?.materials.split(processCustomizedContent?.materials.indexOf('\n') === -1 ? ' ' : '\n');
                const colors = processCustomizedContent?.colors.split(processCustomizedContent?.colors.indexOf('\n') === -1 ? ' ' : '\n');

                return (
                  <tr key={key + '_processLog'}>
                    <ProcessNumber status={processCustomizedContent?.numStatus}
                      onClick={e => {
                        e.preventDefault();
                        handleProcessNumber(processLog, index);
                      }}
                    >
                      {(index + 1).toString().padStart(2, '0')}
                    </ProcessNumber>
                    <ContentCell>
                      <div className='content-top'>
                        <input
                          type="text"
                          name='bundle'
                          key={key + '_bundle'}
                          defaultValue={processCustomizedContent?.bundle}
                          onInput={(e) => {
                            e.preventDefault();
                            handleCustomData(e, processLog, index);
                          }}
                        />
                        <input
                          type="number"
                          name='processingCount'
                          key={key + '_processingCount'}
                          defaultValue={processLog.processingCount}
                          onInput={(e) => {
                            e.preventDefault();
                            handleInputEvent(e, processLog, index);
                          }}
                        />
                      </div>
                      <div className='content-middle'>
                        <input
                          type="text"
                          name='bundleSize'
                          key={key + '_bundleSize'}
                          defaultValue={processCustomizedContent?.bundleSize}
                          onInput={(e) => {
                            e.preventDefault();
                            handleCustomData(e, processLog, index);
                          }}
                        />
                      </div>
                      <div className='content-bottom'>
                        <div className='button-file'
                          onClick={(e) => {
                            e.preventDefault();
                            selectFile(processLog, index)
                          }}
                        >
                          파일 선택
                        </div>
                        <div className='preview-file'>
                          {processLog?.fileData?.fileDataS3URL ? (
                            <img src={processLog?.fileData?.fileDataS3URL} alt={(index + 1).toString().padStart(2, '0') + '파일'} />
                          ) : checkNullArray(processLog?.attachFileList, false) && checkNullArray(processLog?.attachFileList, false).length > 0 ? (
                            <img src={processLog?.attachFileList[0]?.fileData?.fileDataS3URL} alt={(index + 1).toString().padStart(2, '0') + '파일'} />
                          ) : checkNullArray(processLog?.drawingList, false) && checkNullArray(processLog?.drawingList, false).length > 0 ?
                            <img src={processLog?.drawingList[0]?.fileData?.fileDataS3URL} alt={(index + 1).toString().padStart(2, '0') + '파일'} />
                            : null}
                        </div>
                      </div>
                    </ContentCell>
                    <MaterialsCell status={processCustomizedContent?.materialStatus}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleMaterials(processLog, index);
                      }}
                    >
                      <textarea
                        name='materials'
                        placeholder="자재..."
                        key={key + '_materials'}
                        defaultValue={materials?.join('\n')}
                        onInput={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleCustomData(e, processLog, index);
                        }}
                        onClick={e => { e.stopPropagation(); }}
                      ></textarea>
                    </MaterialsCell>
                    <ColorsCell status={processCustomizedContent?.colorStatus}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleColors(processLog, index);
                      }}
                    >
                      <textarea
                        name='colors'
                        placeholder="색상..."
                        key={key + '_colors'}
                        defaultValue={colors?.join('\n')}
                        onInput={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleCustomData(e, processLog, index);
                        }}
                        onClick={e => { e.stopPropagation(); }}
                      ></textarea>
                    </ColorsCell>

                    {/* ---------------------------------------------------------------------- */}

                    {taskTypeArray.map((taskType, typeIndex) => {
                      const wContents = checkNullArray(processLog?.taskLogList, []).filter((item) => item.taskType === taskType + '_W');
                      const hContents = checkNullArray(processLog?.taskLogList, []).filter((item) => item.taskType === taskType + '_H');
                      return (
                        <Fragment key={key + '_task_' + typeIndex}>
                          <td>
                            <TaskCells>
                              {/* 가로 */}
                              <TaskCell>
                                <div>
                                  {checkEmptyNull(wContents, []).map((wContent, wIndex) => {
                                    const taskData = {
                                      taskType: taskType + '_W',
                                      index: wIndex,
                                      ...wContent
                                    };
                                    return (
                                      <div key={key + '_task_w_' + wIndex}>
                                        <p>{wContent.taskName}</p>
                                        <div className='button-delete-task'
                                          onClick={(e) => {
                                            e.preventDefault();
                                            actDeleteTask(taskData, processLog, index)
                                          }}
                                        ></div>
                                      </div>
                                    )
                                  })}
                                </div>
                                <div className='button-create-task'
                                  onClick={e => {
                                    e.preventDefault();
                                    actCreateTask(taskType + '_W', processLog, index)
                                  }}
                                >
                                  추가
                                </div>
                              </TaskCell>

                              {/* 세로 */}
                              <TaskCell>
                                <div>
                                  {checkEmptyNull(hContents, []).map((hContent, hIndex) => {
                                    const taskData = {
                                      taskType: taskType + '_W',
                                      index: hIndex,
                                      ...hContent
                                    };
                                    return (
                                      <div key={key + '_task_h_' + hIndex}>
                                        <p>{hContent.taskName}</p>
                                        <div className='button-delete-task'
                                          onClick={(e) => {
                                            e.preventDefault();
                                            actDeleteTask(taskData, processLog, index)
                                          }}
                                        ></div>
                                      </div>
                                    )
                                  })}
                                </div>
                                <div className='button-create-task'
                                  onClick={e => {
                                    e.preventDefault();
                                    actCreateTask(taskType + '_H', processLog, index)
                                  }}
                                >
                                  추가
                                </div>
                              </TaskCell>
                            </TaskCells>
                          </td>

                          {taskType === 'GB' ? (
                            <td>
                              <TaskCells>
                                {/* 가로 */}
                                <TaskCell className='h100'>
                                  <textarea
                                    name='glassType_W'
                                    placeholder="유리종류..."
                                    key={key + '_glassType_W'}
                                    defaultValue={processCustomizedContent?.glassType_W}
                                    onInput={(e) => {
                                      e.preventDefault();
                                      handleCustomData(e, processLog, index);
                                    }}
                                  ></textarea>
                                </TaskCell>

                                {/* 세로 */}
                                <TaskCell className='h100'>
                                  <textarea
                                    name='glassType_H'
                                    placeholder="유리종류..."
                                    key={key + '_glassType_H'}
                                    defaultValue={processCustomizedContent?.glassType_H}
                                    onInput={(e) => {
                                      e.preventDefault();
                                      handleCustomData(e, processLog, index);
                                    }}
                                  ></textarea>
                                </TaskCell>
                              </TaskCells>
                            </td>
                          ) : null}

                          {taskType === 'MC' ? (
                            <Fragment>
                              <RemarkCell>
                                <textarea
                                  name='remark'
                                  placeholder="비고..."
                                  key={key + '_remark'}
                                  defaultValue={processCustomizedContent?.remark}
                                  onInput={(e) => {
                                    e.preventDefault();
                                    handleCustomData(e, processLog, index);
                                  }}
                                ></textarea>
                              </RemarkCell>

                              <DeleteProcessButton
                                onClick={(e) => {
                                  e.preventDefault();
                                  actDeleteProcess(processLog, index);
                                }}
                              >
                                삭제
                              </DeleteProcessButton>
                            </Fragment>
                          ) : null}
                        </Fragment>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        } />
      </ProcessFormSection>

      {/* 모달 */}
      {/* 파일 모달 */}
      {checkNullObject(_eventProcessLog, false) && _fileModalStatus === true ? (
        <SelectDrawingModal
          fileEvent={(data) => {
            console.log('data : ', data);
            const newData = { ..._eventProcessLog, fileData: data }
            props.setProcessLogList(prev => {
              const returnData = [...prev];
              returnData.splice(_eventProcessLog.index, 1, newData);
              return returnData;
            })
          }}
          open={_fileModalStatus}
          close={() => { setFileModalStatus(false) }}
        />
      ) : null}
    </>
  );
};

export default ProcessForm;