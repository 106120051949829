import React from 'react';
import styled from 'styled-components';

import { searchButtonIcon } from 'components/icons/src';

const Icon = styled.div`
  background-color: var(--white);
  height: 20px;
  width: 20px;

  mask-image: url(${searchButtonIcon});
  mask-repeat: no-repeat;
  mask-size: contain;

  --webkit-mask-image: url(${searchButtonIcon});
  --webkit-mask-repeat: no-repeat;
  --webkit-mask-size: contain;

  &:hover {
    transform: scale(1.2);
  }
`;

const SearchButtonIcon = () => {
  return <Icon />;
};

export default SearchButtonIcon;
