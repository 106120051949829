const initialState = {
    searchOption: '',
    searchText: '',
    pageNumber: 0,
    isPushedSearchButton: false,
};
const resetState = {
    searchOption: '',
    searchText: '',
    pageNumber: 0,
    isPushedSearchButton: false,
};

const gsIncomingInspectionReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SETGSINCOMINGINSPECTIONSEARCHOPTION':
            return {
                ...state,
                searchOption: action.payload,
            };
        case 'SETGSINCOMINGINSPECTIONSEARCHTEXT':
            return {
                ...state,
                searchText: action.payload,
            };
        case 'SETGSINCOMINGINSPECTIONPAGENUMBER':
            return {
                ...state,
                pageNumber: action.payload,
            };
        case 'SETGSINCOMINGINSPECTIONISPUSHEDSEARCHBUTTON':
            return {
                ...state,
                isPushedSearchButton: action.payload,
            };
        case 'GSINCOMINGINSPECTIONRESET':
            return {
                ...state,
                ...resetState,
            };
        default: 
            return state;
    };
};

export default gsIncomingInspectionReducer;