import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { companyMenu } from 'api/apis/operator/companyMenu';
import { teamApi } from 'api/apis/teamApi';

import { checkEmptyNull, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import TableSection from 'components/layouts/table/TableSection';

const AllCheckButton = styled.div`
  align-items: center;
  background-color: var(--ThirdBlue);
  border-radius: 10px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 0.9em;
  height: fit-content;
  justify-content: center;
  margin: 0px auto;
  padding: 10px;
  width: fit-content;
  zoom: 90%;
  & p {font-size: 0.8em;}
`;

const WorkAuthorityCell = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;
const WorkAuthority = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & h4 {
    align-items: center;
    background-color: var(--MainNavy);
    color: var(--white);
    display: flex;
    font-size: 0.8em;
    height: 40px;
    justify-content: center;
    padding: 10px;
    width: 100%;
  }

  & div {
    align-items: center;
    background-color: var(--white);
    border: 1px solid var(--gray-200);
    display: flex;
    height: 40px;
    justify-content: center;
    width: 100%;
  }
`;

const AuthorityTable = (props) => {
  const { userReducer } = useSelector((state) => state);

  const { companyMenus, teamRoleList, handleTeamRoleList } = props;
  // console.log({ companyMenus: companyMenus, teamRoleList: teamRoleList, handleTeamRoleList: handleTeamRoleList });

  const checkAuth = (authList, pageCode, auth) => {
    // console.log({ authList: authList, pageCode: pageCode, auth: auth });
    let status = false;
    const teamMenuList = authList.filter(item => item.pageCode === pageCode);
    const authority = teamMenuList.map(teamMenu => teamMenu.authority);
    if (authority.includes(auth)) status = true;
    else status = false;
    return status;
  }

  return (
    <TableSection content={
      <table style={{ margin: '10px auto 0px auto' }}>
        <thead>
          <tr>
            <th rowSpan={2}>메뉴</th>
            <th colSpan={2}>권한</th>
          </tr>
          <tr>
            <th>
              <AllCheckButton data-status={'off'} onClick={(e) => {
                e.preventDefault();
                handleTeamRoleList(prev => {
                  let teamRoles = [...prev.teamRoleList];
                  if (e.target.dataset.status === 'off') {
                    teamRoles = [...prev.teamRoleList];
                    companyMenus.forEach(menu => {
                      const findTeamRoleIndex = prev.teamRoleList.findIndex(item => item.pageCode === menu.pageCode && item.authority === '1');
                      if (findTeamRoleIndex === -1) teamRoles.push({ ...menu, authority: '1' });
                      else teamRoles.push(prev.teamRoleList[findTeamRoleIndex]);
                    })
                    e.target.dataset.status = 'on';
                  } else {
                    teamRoles = prev.teamRoleList.filter(item => item.authority !== '1');
                    e.target.dataset.status = 'off';
                  }

                  const returnData = { ...prev, teamRoleList: teamRoles };
                  return returnData;
                })
              }}>
                읽기<p>[ 클릭 ]</p>
              </AllCheckButton>
            </th>
            <th>
              <AllCheckButton data-status={'off'} onClick={(e) => {
                e.preventDefault();
                handleTeamRoleList(prev => {
                  let teamRoles = [...prev.teamRoleList];
                  if (e.target.dataset.status === 'off') {
                    teamRoles = [...prev.teamRoleList];
                    companyMenus.forEach(menu => {
                      const findTeamRoleIndex = prev.teamRoleList.findIndex(item => item.pageCode === menu.pageCode && ['2', '3', '4', '5'].includes(item.authority));
                      if (findTeamRoleIndex === -1) {
                        if (menu.pageCode === '501') {
                          teamRoles.push(...[{ ...menu, authority: '2' }, { ...menu, authority: '3' }, { ...menu, authority: '4' }, { ...menu, authority: '5' }]);
                        } else {
                          teamRoles.push({ ...menu, authority: '2' });
                        }
                      }
                      else teamRoles.push(prev.teamRoleList[findTeamRoleIndex]);
                    })
                    e.target.dataset.status = 'on';
                  } else {
                    teamRoles = prev.teamRoleList.filter(item => !['2', '3', '4', '5'].includes(item.authority));
                    e.target.dataset.status = 'off';
                  }

                  const returnData = { ...prev, teamRoleList: teamRoles };
                  return returnData;
                })
              }}>
                읽기 & 쓰기<p>[ 클릭 ]</p>
              </AllCheckButton>
            </th>
          </tr>
        </thead>
        <tbody>
          {checkEmptyNull(companyMenus, []).map((menu, index) => {
            if (menu.pageCode.includes('00')) {
              return (
                <tr key={index + '_00'}>
                  <td colSpan={3} style={{ backgroundColor: 'var(--MainBlue)', color: 'var(--white)' }}>{menu.pageName}</td>
                </tr>
              )
            } else {
              return (
                <tr key={index + '_menu'}>
                  <td>{menu.pageName}</td>
                  <td>
                    <input
                      type="checkbox"
                      name="authority_read"
                      checked={checkAuth(teamRoleList, menu.pageCode, '1')}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleTeamRoleList(prev => {
                          const returnTeamRoleList = [...teamRoleList];
                          const findTeamRoleIndex = returnTeamRoleList.findIndex(item => item.pageCode === menu.pageCode && item.authority === '1');
                          if (e.target.checked) returnTeamRoleList.push({ ...menu, authority: '1' });
                          else {
                            if (findTeamRoleIndex !== -1) returnTeamRoleList.splice(findTeamRoleIndex, 1);
                          }
                          const returnData = { ...prev, teamRoleList: returnTeamRoleList };
                          return returnData;
                        })
                      }} />
                    {/* <CheckBox
                      act={''}
                      all={true}
                      checkboxKey={JSON.stringify({ ...menu, authority: '1' })}
                      name={'authority_read'}
                      checked={checkAuth(teamRoleList, menu.pageCode, '1')}
                    /> */}
                  </td>
                  <td>
                    {(() => {
                      if ((userReducer.company.companyId === 1 || userReducer.company.companyId === 2) && menu.pageCode === '501') {
                        return (
                          <WorkAuthorityCell>
                            <WorkAuthority>
                              <h4>보강재</h4>
                              <div>
                                <input
                                  type="checkbox"
                                  name="authority_readNwrite"
                                  checked={checkAuth(teamRoleList, menu.pageCode, '2')}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    handleTeamRoleList(prev => {
                                      const returnTeamRoleList = [...teamRoleList];
                                      const findTeamRoleIndex = returnTeamRoleList.findIndex(item => item.pageCode === menu.pageCode && item.authority === '2');
                                      if (e.target.checked) returnTeamRoleList.push({ ...menu, authority: '2' });
                                      else {
                                        if (findTeamRoleIndex !== -1) returnTeamRoleList.splice(findTeamRoleIndex, 1);
                                      }
                                      const returnData = { ...prev, teamRoleList: returnTeamRoleList };
                                      return returnData;
                                    })
                                  }} />
                                {/* <CheckBox
                                  act={''}
                                  all={true}
                                  checkboxKey={JSON.stringify({ ...menu, authority: '2' })}
                                  name={'authority_readNwrite'}
                                  checked={checkAuth(teamRoleList, menu.pageCode, '2')}
                                /> */}
                              </div>
                            </WorkAuthority>
                            <WorkAuthority>
                              <h4>절단</h4>
                              <div>
                                <input
                                  type="checkbox"
                                  name="authority_readNwrite"
                                  checked={checkAuth(teamRoleList, menu.pageCode, '3')}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    handleTeamRoleList(prev => {
                                      const returnTeamRoleList = [...teamRoleList];
                                      const findTeamRoleIndex = returnTeamRoleList.findIndex(item => item.pageCode === menu.pageCode && item.authority === '3');
                                      if (e.target.checked) returnTeamRoleList.push({ ...menu, authority: '3' });
                                      else {
                                        if (findTeamRoleIndex !== -1) returnTeamRoleList.splice(findTeamRoleIndex, 1);
                                      }
                                      const returnData = { ...prev, teamRoleList: returnTeamRoleList };
                                      return returnData;
                                    })
                                  }} />
                              </div>
                            </WorkAuthority>
                            <WorkAuthority>
                              <h4>용접</h4>
                              <div>
                                <input
                                  type="checkbox"
                                  name="authority_readNwrite"
                                  checked={checkAuth(teamRoleList, menu.pageCode, '4')}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    handleTeamRoleList(prev => {
                                      const returnTeamRoleList = [...teamRoleList];
                                      const findTeamRoleIndex = returnTeamRoleList.findIndex(item => item.pageCode === menu.pageCode && item.authority === '4');
                                      if (e.target.checked) returnTeamRoleList.push({ ...menu, authority: '4' });
                                      else {
                                        if (findTeamRoleIndex !== -1) returnTeamRoleList.splice(findTeamRoleIndex, 1);
                                      }
                                      const returnData = { ...prev, teamRoleList: returnTeamRoleList };
                                      return returnData;
                                    })
                                  }} />
                              </div>
                            </WorkAuthority>
                            <WorkAuthority>
                              <h4>마무리</h4>
                              <div>
                                <input
                                  type="checkbox"
                                  name="authority_readNwrite"
                                  checked={checkAuth(teamRoleList, menu.pageCode, '5')}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    handleTeamRoleList(prev => {
                                      const returnTeamRoleList = [...teamRoleList];
                                      const findTeamRoleIndex = returnTeamRoleList.findIndex(item => item.pageCode === menu.pageCode && item.authority === '5');
                                      if (e.target.checked) returnTeamRoleList.push({ ...menu, authority: '5' });
                                      else {
                                        if (findTeamRoleIndex !== -1) returnTeamRoleList.splice(findTeamRoleIndex, 1);
                                      }
                                      const returnData = { ...prev, teamRoleList: returnTeamRoleList };
                                      return returnData;
                                    })
                                  }} />
                              </div>
                            </WorkAuthority>
                          </WorkAuthorityCell>
                        );
                      } else {
                        return (
                          <input
                            type="checkbox"
                            name="authority_readNwrite"
                            checked={checkAuth(teamRoleList, menu.pageCode, '2')}
                            onChange={(e) => {
                              e.stopPropagation();
                              handleTeamRoleList(prev => {
                                const returnTeamRoleList = [...teamRoleList];
                                const findTeamRoleIndex = returnTeamRoleList.findIndex(item => item.pageCode === menu.pageCode && item.authority === '2');
                                if (e.target.checked) returnTeamRoleList.push({ ...menu, authority: '2' });
                                else {
                                  if (findTeamRoleIndex !== -1) returnTeamRoleList.splice(findTeamRoleIndex, 1);
                                }
                                const returnData = { ...prev, teamRoleList: returnTeamRoleList };
                                return returnData;
                              })
                            }} />
                        );
                      }
                    })()}
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    }
    />
  );
};

const UpdateTeam = () => {
  /* ========================================================================= #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [searchParams, setSearchParams] = useSearchParams();
  const updateContent = checkNullParse(searchParams.get('updateContent'), {});

  const [_onload, setOnload] = useState('unload');

  const [_companyMenus, setCompanyMenus] = useState([]);

  const [_formData, setFormData] = useState({
    companyId: updateContent.companyId,
    teamName: updateContent.teamName,
    teamRoleList: updateContent.teamRoleList,
  });

  const [_prevTeamRoleList, setPrevTeamRoleList] = useState(updateContent.teamRoleList);

  /* ========================================================================= #3 */
  useEffect(() => {
    getCompanyMenu();
    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => { return () => { } }, [_formData.teamRoleList])

  /* ========================================================================= #4 */
  const getCompanyMenu = async () => {
    const BodyToPost = { companyId: updateContent.companyId }
    await companyMenu.searchCompanyMenu(BodyToPost).then(response => {
      if (!checkEmptyNull(response, false)) return;
      console.log('companyMenuApi.searchCompanyMenu : ', response);
      const companyMenus = response.data.content.sort((a, b) => {
        if (a.indexX > b.indexX) return 1;
        if (a.indexX < b.indexX) return -1;
        if (a.indexY > b.indexY) return 1;
        if (a.indexY < b.indexY) return -1;
        else return 0;
      });
      setCompanyMenus(() => { return companyMenus; })
    })
  }

  /* ========================================================================= #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value } })
  };

  /* 저장 버튼 누를때 */
  const actUpdate = async (e) => {
    e.preventDefault();
    if (!checkEmptyNull(_formData.teamName, false)) return alert('팀 이름을 입력해주세요.');

    const teamRoleList = Object.values(_formData.teamRoleList).map(teamRole => {
      if (!teamRole.pageCode.includes('00')) {
        const prevPageCodeIndex = _prevTeamRoleList.findIndex(prevTeamRole => prevTeamRole.pageCode === teamRole.pageCode && prevTeamRole.authority === teamRole.authority);
        const returnData = {
          companyMenuId: teamRole.companyMenuId,
          pageCode: teamRole.pageCode,
          authority: teamRole.authority,
        };
        if (prevPageCodeIndex !== -1) {
          const prevPageCodeData = _prevTeamRoleList[prevPageCodeIndex];
          returnData.teamRoleId = prevPageCodeData.teamRoleId;
        }
        return returnData;
      } else {
        return null;
      }
    })
    const filterTeamRoleList = new Set(teamRoleList.filter(item => checkNullObject(item, false)));

    const topRoleList = [];
    console.log('filterTeamRoleList : ', filterTeamRoleList);
    [...filterTeamRoleList].forEach(teamRole => {
      const teamRolePageCode = checkEmptyNull(teamRole.pageCode, false) ? teamRole.pageCode.charAt(0) : '';
      topRoleList.push(teamRolePageCode);
    })
    const setTopRoleList = new Set(topRoleList);
    const mapTopRoleList = [...setTopRoleList].map(teamRole => {
      const topPageCode = teamRole + '00';
      const pageCodeIndex = _companyMenus.findIndex(companyMenu => companyMenu.pageCode === topPageCode);
      const pageCodeData = _companyMenus[pageCodeIndex];
      if (checkNullObject(pageCodeData, false)) {
        const prevPageCodeIndex = _prevTeamRoleList.findIndex(prevTeamRole => prevTeamRole.pageCode === topPageCode);
        const returnData = {
          companyMenuId: pageCodeData.companyMenuId,
          pageCode: topPageCode,
          authority: '2',
        };
        if (prevPageCodeIndex !== -1) {
          const prevPageCodeData = _prevTeamRoleList[prevPageCodeIndex];
          returnData.teamRoleId = prevPageCodeData.teamRoleId;
        }
        return returnData;
      } else {
        return null;
      }
    })
    console.log('mapTopRoleList : ', mapTopRoleList);
    const filterTopRoleList = mapTopRoleList.filter(teamRole => checkEmptyNull(teamRole.companyMenuId, false));
    // const filterTopRoleList = mapTopRoleList.filter(teamRole => checkNullObject(teamRole, false) && [...filterTeamRoleList].findIndex(role => role.pageCode === teamRole.pageCode) === -1);
    const setAllRoleList = new Set([...filterTopRoleList, ...filterTeamRoleList]);

    const deleteTeamRoleList = [];
    _prevTeamRoleList.forEach((prevTeamRole) => {
      const checkedIndex = [...setAllRoleList].findIndex((teamRole) => teamRole.teamRoleId === prevTeamRole.teamRoleId);
      if (checkedIndex === -1) deleteTeamRoleList.push(prevTeamRole.teamRoleId);
    });

    const BodyToPut = {
      ..._formData,
      teamRoleList: [...setAllRoleList],
      deleteTeamRoleList: deleteTeamRoleList,
    };
    console.log('BodyToPut : ', BodyToPut);

    await teamApi.updateTeam(updateContent.teamId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('teamApi.updateTeam : ', response);
      alert('팀을 수정했습니다');
      navigate(pageReducer.currentPage, { replace: true });
    })
  };

  /* ========================================================================= #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <>
        <main className="Main">
          <NavBar title={<NavTitle menuCode={'902'} />} nav={''} />

          <Form
            title={<>팀<br />수정</>}
            buttons={
              <>
                <button className="formButton" onClick={actUpdate}>저장</button>
                <button className="formButton cancle"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(pageReducer.currentPage, { replace: true });
                  }}
                >
                  취소
                </button>
              </>
            }
            forms={
              <>
                <div className="formBox w100">
                  <h4>팀 이름</h4>
                  <input
                    type="text"
                    name="teamName"
                    data-required="required"
                    placeholder="* 팀 이름..."
                    value={_formData.teamName}
                    onInput={handleInputEvent}
                  />
                </div>

                <div className="formBox w100">
                  <h4>팀 권한</h4>
                  <AuthorityTable companyMenus={_companyMenus} teamRoleList={_formData.teamRoleList} handleTeamRoleList={setFormData} />
                </div>
              </>
            }
          />
        </main>
      </>
    }
    />
  );
};

export default UpdateTeam;
