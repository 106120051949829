import styled from 'styled-components';

export const SafetyMain = styled.main`
  display: grid;
  grid-template-rows: auto minmax(calc(100% - 60px), 100%);
  height: ${props => {
    return props.onHeader ? 'calc(100% - 60px)' : '100%';
  }};
  justify-content: center;
  padding: 20px;
  width: 100%;
  overflow: hidden;
  
  & > section {
    display: grid;
    height: 100%;
    width: 100%;
    gap: 20px;
  }
`;

// 온습도
export const TmpHmdSection = styled.div`
  background-color: var(--white);
  box-shadow: 0 6px 6px 0 rgba(196, 196, 196, 0.8);
  border-radius: 10px;
  display: grid; /* 가로는 flex, 세로는 grid */
  height: 100%;
  padding: 20px;
  width: 100%;

  & > div.nav {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  & > div.contents {
    & > div {
      align-items: center;
      display: grid;
      justify-items: center;
      padding: 5px;
    }
  }
`;

export const TmpHmdContents = styled.div`
  border: 2px solid rgba(196, 196, 196, 0.8); /* 두께, 스타일, 색상 */
  border-radius: 10px;
  display: grid;
  gap: 10px;
  height: 100%;
  justify-content: center;
  padding: 10px;
  width: 100%;

  & > h4 {
    font-size: 1.2rem;
  }

  & > div {
    display: grid;
  }
  
  &.emphasis {
    border: none;
    display: flex;
    height: 100%;
    align-items: center;
    gap: 100px;
    padding: 10px;
    width: 100%;

    & > h4 {
      font-size: 2rem;
    }

    & > div {
      display: flex;
      gap: 100px;
    }
  }
`;

export const OrderTmpHmd = styled.div`
  display: grid;
  justify-items: center;
  gap: 10px;
  grid-template-columns: repeat(${props => props.rightSection}, 1fr);
`

// 화재, 누전
export const Sensing = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr; /* 두 컴포넌트가 동일한 너비를 가지도록 설정 */
  gap: 20px; /* 간격 조정 */
`;

export const SafetySection = styled.div`
  background-color: var(--white);
  box-shadow: 0 6px 6px 0 rgba(196, 196, 196, 0.8);
  border-radius: 10px;
  padding: 20px;
  height: 90%;
  width: 100%;

  & > div.safety-group {
    align-items: center;
    box-sizing: border-box;
    height: 90%;
    display: flex;
    gap: 50px; /* 컴포넌트 간의 간격을 넓힘 */
    justify-content: center;
    padding: 10px; /* 내부 여백 추가 */

    & > p.fire-status {
      text-align: center;
      font-size: 2rem;
      font-weight: 700;
      width: fit-content;
    }

    & > p.electric-status {
      text-align: center;
      font-size: 2rem;
      font-weight: 700;
      width: fit-content;
    }
  }
`;

// 버튼
export const DashboardBtn = styled.div`
  background-color: var(--MainNavy);
  border-radius: 8px;
  left: 83%;
  bottom: 26px;
  color: var(--white);
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  gap: 4px;
  padding: 4px 10px;
  position: fixed;
  right: 24px;
  text-align: center;
  width: 110px;
`;
  
// 범례
// export const ColorLegend = styled.div`
//   height: 150px;
//   width: 30px;

//   background: linear-gradient(180deg, #FF0000 0%, #FF9100 33%, #FFEE00 66%, #00FF00 100%);
//   border-radius: 150px;
// `;

// 아이콘
export const FireIconWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 5px; /* 아이콘과 텍스트 사이 간격 */

  & > .fire-icon {
    color: ${props => {return props.temperature}};
    font-size: 6rem; /* 아이콘 크기 설정 */
  }

  & > p.temperature {
    font-size: 1.5rem; /* 온도 텍스트 크기 */
    text-align: center;
  }
`;

export const ShortIconWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 5px; /* 아이콘과 텍스트 사이 간격 */

  & > .electric-icon {
    color: ${props => {return props.status}};
    font-size: 7rem; /* 아이콘 크기 설정 */
  }
`;

export const TmpHmpIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > .tmp-icon {
    font-size: 8rem; /* 아이콘 크기 설정 */
  }

  & > .hmd-icon {
    font-size: 9rem; /* 아이콘 크기 설정 */
  }

  & > p {
    font-size: 1.2rem; /* 텍스트 크기 */
    text-align: center;
  }

  & > p.emphasis {
    font-size: 2rem; /* 텍스트 크기 */
    text-align: center;
  }
`;