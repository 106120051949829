import React, { useEffect, useState, useRef } from 'react';

import { sensorDataApi } from 'api/apis/sensorDataApi';
import { checkEmptyNull } from 'components/checkValues/checkValues';

const RealTime = () => {
  const [_power, setPower] = useState(0);

  useEffect(() => { getSensorRealTimeData(); return () => { } }, [])
  useEffect(() => { }, [_power])

  const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    useEffect(() => { savedCallback.current = callback; }, [callback]);
    useEffect(() => {
      const tick = () => { savedCallback.current(); };
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };
  useInterval(async () => { await getSensorRealTimeData(); }, 60000);

  const getSensorRealTimeData = async () => {
    const body = { companyId: 3, code: 'power', name: null };
    await sensorDataApi.sensorRealTimeData(body).then(response => {
      if (response === undefined) return;
      console.log('sensorDataApi.sensorRealTimeData : ', response);
      // setPower(() => { return Math.floor(Math.random() * 10) });
      setPower(() => { return response.data.content[0]?.value });
    })
  }

  return (
    <div className='RealTime'>
      <h4>실시간 전력 정보</h4>
      <p>{(checkEmptyNull(_power, 0) * 1).toLocaleString()} <span>kW</span></p>
    </div>
  );
};

export default RealTime;