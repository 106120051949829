import React from 'react';
import styled from 'styled-components';

import { closeButtonIconUrl } from 'components/icons/src';

const Icon = styled.div`
  background-color: var(--MainNavy);
  cursor: pointer;
  height: 24px;
  width: 24px;

  mask-image: url(${closeButtonIconUrl});
  mask-repeat: no-repeat;
  mask-size: contain;

  --webkit-mask-image: url(${closeButtonIconUrl});
  --webkit-mask-repeat: no-repeat;
  --webkit-mask-size: contain;

  &:hover {
    transform: scale(1.2);
  }
`;

const CloseButtonIcon = () => {
  return <Icon />;
};

export default CloseButtonIcon;
