import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { mallUserApi } from 'api/apis/mallUserApi';

import DeleteForm from 'components/layouts/form/DeleteForm';
import MallBody from 'components/layouts/body/MallBody';
import MallManagementHeader from 'components/header/MallManagementHeader';
import NavBar from 'components/nav/NavBar';

const DeleteMallUser = () => {
  /* ========================================================================= #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();

  const deleteContents = JSON.parse(searchParams.get('deleteContents'));
  const [_deleteContents, setDeleteContents] = useState(deleteContents);

  /* ====================================================================== #3 */

  /* ========================================================================= #4 */

  /* ====================================================================== #5 */
  let delCount = 0;
  const actAllDelete = () => {
    deleteContents.forEach(async (thisItem) => {
      await actDelete(thisItem.userId, thisItem);
    });
  };

  const actDelete = async (userId, mallUser) => {
    await mallUserApi
      .deleteMallUser(userId)
      .then((response) => {
        if (response === undefined) return;
        console.log('mallUserApi.deleteMallUser : ', response);

        const contents = [..._deleteContents];
        const thisFindIndex = contents.findIndex(
          (thisIndex) => thisIndex.userId === userId,
        );
        setDeleteContents(() => {
          contents.splice(thisFindIndex, 1);
          return contents;
        });
      })
      .finally(() => {
        delCount++;
        if (delCount === deleteContents.length) {
          alert(`고객정보를 삭제했습니다.`);

          setTimeout(
            navigate(pageReducer.currentPage, { replace: true }),
            1000,
          );
        }
      });
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <MallBody
      contents={
        <>
          <main className="Main">
            <NavBar title={'고객관리'} nav={''} />

            <DeleteForm
              delteTitle={'고객정보'}
              deleteItem={_deleteContents.map((thisItem) => (
                <Fragment key={thisItem.userId}>
                  <p>
                    <b>이름:</b> {thisItem.mallUserName}
                  </p>
                  <p>
                    <b>이메일:</b> {thisItem.email}
                  </p>
                  <p>
                    <b>연락처:</b> {thisItem.phoneNumber}
                  </p>
                  <p>
                    <b>메모:</b> {thisItem.memo}
                  </p>
                  <br />
                </Fragment>
              ))}
              deleteText={
                <>
                  해당 <span className="cautionText">고객정보</span>를
                  삭제합니다.
                </>
              }
              deleteButton={
                <>
                  <button
                    className="formButton cancle"
                    onClick={() => {
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                  <button
                    className="formButton delete"
                    onClick={() => {
                      if (window.confirm('고객정보를 삭제합니다.'))
                        actAllDelete();
                      else return;
                    }}
                  >
                    삭제
                  </button>
                </>
              }
            />
          </main>
        </>
      }
    />
  );
};

export default DeleteMallUser;
