import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { sensor } from 'api/apis/sensor';

import { checkNullArray } from 'components/checkValues/checkValues';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  height: 80%;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const Section = styled.div`
  display: grid;
  grid-template-rows: 80px calc(100% - 80px);
  height: calc(100% - 55px);
  overflow: hidden;
  width: 100%;

  button.create {background-color: var(--MainBlue); &:hover {background-color: var(--MainBlue);}}
  button.btn-save, button.update {background-color: var(--MainNavy); &:hover {background-color: var(--MainNavy);}}
  button.delete {background-color: var(--MainRed); &:hover {background-color: var(--MainRed);}}

  .section-data {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    padding: 10px 20px;

    .inputWrap {
      align-items: center;
      display: flex;
      gap: 5px;
      justify-content: flex-start;

      input[type="text"], input[type="number"], select {
        border: 1px solid var(--gray-200);
        box-sizing: border-box;
        padding: 10px;
        width: 100px;
      }
      input[name="name"] {width: 200px;}
    }
  }

  .TableSection {
    height: 100%;
    td, th {
      min-width: unset;
      /* padding: 8px; */

      &.non-flag {
        background-color: var(--gray-100);
      }
      button {
        margin: auto;
      }
    }
  }
`;

const GLSensorSetting = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_sensorData, setSensorData] = useState(props.content);
  // console.log('_sensorData : ', _sensorData);
  const [_sensorSettings, setSensorSettings] = useState(checkNullArray(props.content?.sensorSettingList, []));
  const body = {
    name: '',
    value: '',
    isMore: true,
    // alarmFlag: true,
    alarmCycle: 0,
    reAlarmFlag: false,
    sensorId: props.content.id
  };
  const [_formData, setFormData] = useState(body);
  // console.log('_formData : ', _formData);
  const [_update, setUpdate] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    getSensorData();
  }, [])
  useEffect(() => { }, [_sensorSettings])

  /* ====================================================================== #4 */
  const getSensorData = async () => {
    await sensor.getSensor(props.content.id).then(response => {
      if (response === undefined) return;
      console.log('sensor.getSensor : ', response);
      const moreList = checkNullArray(response.data.sensorSettingList, []).filter(item => item.isMore);
      const moreSortingList = moreList.sort((a, b) => b.level * 1 - a.level * 1);
      const lessList = checkNullArray(response.data.sensorSettingList, []).filter(item => !item.isMore);
      const lessSortingList = lessList.sort((a, b) => a.level * 1 - b.level * 1);
      const sortingList = [...moreSortingList, ...lessSortingList];
      setSensorSettings(() => { return sortingList; })
    })
  }

  /* ====================================================================== #5 */
  const handleAlarmCycle = (alarmCycle) => {
    switch (alarmCycle) {
      case 1: return '1분';
      case 2: return '2분';
      case 5: return '5분';
      case 10: return '10분';
      case 15: return '15분';
      case 30: return '30분';
      case 60: return '1시간';
      case 120: return '2시간';
      case 180: return '3시간';
      case 360: return '6시간';
      case 540: return '9시간';
      case 720: return '12시간';
      case 1440: return '1일';
      default: return '';
    }
  }

  const handleInputEvent = (e) => {
    let { name, value } = e.target;
    if (name === 'isMore') value = value === 'more' ? true : false;
    if (name === 'reAlarmFlag') value = e.target.checked;
    if (name === 'alarmCycle') value = value * 1;
    setFormData((prev) => { return { ...prev, [name]: value } });
  }

  const actCreate = async () => {
    await sensor.createSensorSetting(_formData).then(response => {
      if (response === undefined) return;
      console.log('sensor.createSensorSetting : ', response);
      getSensorData();
      setFormData(() => { return body; }); setUpdate(() => { return false; });
      alert('센서 기준값이 추가되었습니다.');
    })
  }
  const activateUpdate = (sensorSetting) => {
    setUpdate(() => { return true });
    setFormData((prev) => { return { ...prev, ...sensorSetting } });
  }
  const actUpdate = async () => {
    const BodyToPost = { ..._formData };
    delete BodyToPost.level;
    await sensor.updateSensorSetting(_formData.id, BodyToPost).then(response => {
      if (response === undefined) return;
      console.log('sensor.updateSensorSetting : ', response);
      getSensorData();
      const alertText = `${_formData.name} 기준값이 수정되었습니다.`;
      setFormData(() => { return body; }); setUpdate(() => { return false; });
      alert(alertText);
    })
  }
  const actDelete = async (sensorSetting, index) => {
    const confirmText = `${sensorSetting.name} 기준값을 삭제하시겠습니까?`;
    if (window.confirm(confirmText)) {
      await sensor.deleteSensorSetting(sensorSetting.id).then(response => {
        if (response === undefined) return;
        getSensorData();
        // setSensorSettings((prev) => {
        //   const returnData = [...prev];
        //   returnData.splice(index, 1);
        //   return returnData;
        // })
        alert('삭제되었습니다.');
      })
    }
  }

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>센서 알람 설정</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <Section>
              <div className='section-data'>
                <div className='inputWrap'>
                  <input type='text' name='name' placeholder='상태명' value={_formData.name} onInput={handleInputEvent} />
                  <input type='text' name='value' placeholder='설정값' value={_formData.value} onInput={handleInputEvent} />
                  {!_update ? (
                    <select name='isMore' value={_formData.isMore ? 'more' : 'less'} onChange={handleInputEvent}>
                      <option value='more'>이상</option>
                      <option value='less'>이하</option>
                    </select>
                  ) : (
                    <p>{_formData.isMore ? '이상' : '이하'}</p>
                  )}
                </div>
                <div className='inputWrap'>
                  <label htmlFor='reAlarmFlag'>알람 여부</label>
                  <input type='checkbox' id='reAlarmFlag' name='reAlarmFlag' checked={_formData.reAlarmFlag} onChange={handleInputEvent} />
                </div>
                {_formData.reAlarmFlag ? (
                  <div className='inputWrap'>
                    <label htmlFor='alarmCycle'>알람 주기</label>
                    <select id='alarmCycle' name='alarmCycle' value={_formData.alarmCycle} onInput={handleInputEvent}>
                      {[
                        { name: '1분', value: 1 },
                        { name: '2분', value: 2 },
                        { name: '5분', value: 5 },
                        { name: '10분', value: 10 },
                        { name: '15분', value: 15 },
                        { name: '30분', value: 30 },
                        { name: '1시간', value: 60 },
                        { name: '2시간', value: 120 },
                        { name: '3시간', value: 180 },
                        { name: '6시간', value: 360 },
                        { name: '9시간', value: 540 },
                        { name: '12시간', value: 720 },
                        { name: '1일', value: 1440 },
                      ].map((cycle, index) => {
                        return (<option key={index + '_cycle'} value={cycle.value}>{cycle.name}</option>);
                      })}
                    </select>
                    {/* <input type='number' id='alarmCycle' name='alarmCycle' placeholder='알람 주기' min={0} value={_formData.alarmCycle} onInput={handleInputEvent} /> */}
                  </div>
                ) : null}
                {_update ? (
                  <div className='inputWrap'>
                    <button className='btn-save' onClick={actUpdate}>저장</button>
                    <button className='btn-set' onClick={(e) => { e.preventDefault(); setFormData(() => { return body; }); setUpdate(() => { return false; }); }}>취소</button>
                  </div>
                ) : (<button className='btn-save create' onClick={actCreate}>추가</button>)}
              </div>
              <TableSection content={
                <table>
                  <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col width={'80px'} />
                    <col width={'80px'} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>상태명</th>
                      <th colSpan={2}>설정값</th>
                      <th colSpan={2}>알람</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {_sensorSettings.map((sensorSetting, index) => {
                      return (
                        <tr key={index + '_sensorSetting'}>
                          <td>{sensorSetting.name}</td>
                          <td>{sensorSetting.value}</td>
                          <td>{sensorSetting.isMore ? '이상' : '이하'}</td>
                          <td className={!sensorSetting.reAlarmFlag ? 'non-flag' : ''}><input type='checkbox' checked={sensorSetting.reAlarmFlag} readOnly onClick={(e) => { e.preventDefault(); return false }} /></td>
                          <td className={!sensorSetting.reAlarmFlag ? 'non-flag' : ''}>{sensorSetting.reAlarmFlag ? handleAlarmCycle(sensorSetting.alarmCycle) : ''}</td>
                          <td>
                            <button className='btn-save update' onClick={(e) => { e.preventDefault(); activateUpdate(sensorSetting, index) }}>수정</button>
                          </td>
                          <td>
                            <button className='btn-save delete' onClick={(e) => { e.preventDefault(); actDelete(sensorSetting, index) }}>삭제</button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              } />
            </Section>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default GLSensorSetting;
