const initialState = {
    searchData: {},
    pageNumber: 0,
    isPushedSearchButton: false,
}
const resetState = {
    searchData: {},
    pageNumber: 0,
    isPushedSearchButton: false, 
};

const systemReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SETSYSTEMSEARCHDATA':
            return {
                ...state,
                searchData: action.payload,
            };
        case 'SETSYSTEMPAGENUMBER':
            return {
                ...state,
                pageNumber: action.payload,
            };
        case 'SETSYSTEMISPUSHEDSEARCHBUTTON':
            return {
                ...state,
                isPushedSearchButton: action.payload,
            };
        case 'SETSYSTEMRESET':
            return {
                ...state,
                ...resetState,
            };
        default:
            return state;
    };
};

export default systemReducer;