import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { shipmentApi } from 'api/apis/shipmentApi';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';

const AccountTable = styled.table`
  background-color: var(--white);
  border-collapse: collapse;
  font-size: 0.8em;
  table-layout: fixed;
  width: 100%;

  td, th {border: 1px solid var(--gray-200); box-sizing: border-box; padding: 10px;}
  th {
    background-color: var(--MainNavy);
    color: var(--white);

    &.account {
      background-color: var(--MainBlue);
      cursor: pointer;
      &:hover {background-color: var(--SeconBlue);}
    }
  }
  th:first-child {min-width: 150px; width: 150px;}
`;


const UpdateInvoice = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();
  const shipment = JSON.parse(searchParams.get('updateContent'));

  const {
    shipmentId,
    shipmentStatus,
    shipmentCode,
    shipmentName,
    shipmentDate,

    accountId,

    selfAccountCode,
    selfAccountName,
    selfBusinessNumber,
    selfAddress,
    selfTelNumber,
    selfManagerName,

    shipmentAccountCode,
    shipmentAccountName,
    shipmentBusinessNumber,
    shipmentAddress,
    shipmentTelNumber,
    shipmentManagerName,

    remark,
    customizedContent,

    shipmentElementList,
  } = shipment;

  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId, // 회사

    shipmentId: shipmentId,
    accountId: accountId,

    shipmentStatus: checkEmptyNull(shipmentStatus, 'wait'),
    shipmentCode: checkEmptyNull(shipmentCode, ''),
    shipmentName: checkEmptyNull(shipmentName, ''),

    shipmentDate: checkEmptyNull(shipmentDate, false) ? DateFormat(shipmentDate) : '',

    remark: checkEmptyNull(remark, ''),

    customizedContent: checkNullParse(customizedContent, {
      delivery: {
        driver: '',
        telNumber: '',
        licensePlateNumber: '',
      }
    }),

    shipmentElementList: [],
    deleteShipmentElementList: [],
  });

  const [_company, setCompany] = useState({
    selfAccountCode: selfAccountCode,
    selfAccountName: selfAccountName,

    selfBusinessNumber: selfBusinessNumber,
    selfAddress: selfAddress,
    selfTelNumber: selfTelNumber,

    selfManagerName: selfManagerName,
  });

  const [_account, setAccount] = useState({
    accountId: accountId,
    shipmentAccountCode: shipmentAccountCode,
    shipmentAccountName: shipmentAccountName,

    shipmentBusinessNumber: shipmentBusinessNumber,
    shipmentAddress: shipmentAddress,
    shipmentTelNumber: shipmentTelNumber,

    shipmentManagerName: shipmentManagerName,
  });

  const [_deliveryData, setDeliveryData] = useState(() => {
    const deliveryData = checkNullParse(customizedContent, {
      delivery: {
        driver: '',
        telNumber: '',
        licensePlateNumber: '',
      }
    });
    return deliveryData.delivery;
  })

  const [_shipmentElements, setShipmentElements] = useState(() => {
    if (checkNullArray(shipmentElementList, false)) {
      const returnData = shipmentElementList.map((element) => {
        if (checkNullObject(element.element, false)) {
          const elementData = {
            ...element,

            elementId: element.element.elementId,
            elementName: checkEmptyNull(element.element.elementName),
            elementCode: checkEmptyNull(element.element.elementCode),
            scheduledInputStock: element.element.scheduledInputStock,
            elementSafeStock: element.element.elementSafeStock,
            elementStock: element.element.elementStock,

            price: checkEmptyNull(element.element.price, 0), // 단가
            calPrice: element.price, // 금액

            customizedContent: checkNullParse(element.customizedContent, {
              elementStandard: '',
              elementDetailStandard: '',
            })
          };
          return elementData;
        } else {
          const elementData = {
            ...element,

            customizedContent: checkNullParse(element.customizedContent, {
              elementStandard: '',
              elementDetailStandard: '',
            })
          }
          return elementData;
        }
      });

      return returnData;
    } else {
      return [];
    }
  });

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('205') === true, // shipment
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    setOnload('loaded');
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const handleDeliveryData = (e) => {
    const { name, value } = e.target;
    setDeliveryData((prev) => { return { ...prev, [name]: value } });
  };

  const actUpdate = async (e) => {
    e.preventDefault();

    const originElementList = shipmentElementList;
    const findElement = (shipmentElemntId) => {
      const index = _shipmentElements.findIndex((shipmentElement) => shipmentElement.shipmentElemntId === shipmentElemntId);
      return index;
    };
    const deleteShipmentElementList = originElementList.filter((element) => findElement(element.shipmentElemntId) === -1);

    const BodyToPut = {
      ..._formData,

      ..._account,
      ..._company,

      stockFlag: false,

      customizedContent: JSON.stringify({ delivery: _deliveryData }),

      shipmentElementList: _shipmentElements.map((element) => {
        const returnData = {
          accountId: _account.accountId,
          elementId: element.elementId,

          customizedContent: JSON.stringify(element.customizedContent),
        };
        if (checkEmptyNull(element.shipmentElementId, false)) {
          returnData['shipmentElementId'] = element.shipmentElementId;
        }

        return returnData;
      }),
    };
    if (deleteShipmentElementList.length > 0) {
      BodyToPut.deleteShipmentElementList = deleteShipmentElementList;
    }

    // return;
    await shipmentApi.updateShipment(shipmentId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('shipmentApi.updateShipment : ', response);

      alert('운전자를 수정했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  /* ====================================================================== #6 */

  return (
    <Grid2Body
      contents={
        <>
          <section className="Main">
            <NavBar title={'송장관리'} nav={''} />

            <Form
              title={
                <>
                  운전자<br />추가/수정
                </>
              }
              buttons={
                <>
                  <button className="formButton" onClick={actUpdate}>저장</button>
                  <button className="formButton cancle"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                </>
              }
              forms={
                <>
                  <div className="formBox" style={{ width: '100%', height: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                      <h4>운전자</h4>
                      <div
                        className="formButton"
                        style={{ backgroundColor: 'var(--MainNavy)', borderRadius: '5px', width: '80px' }}
                        onClick={() => {
                          setDeliveryData(() => {
                            const returnData = checkNullParse(customizedContent, {
                              delivery: {
                                driver: '',
                                telNumber: '',
                                licensePlateNumber: '',
                              }
                            });
                            return returnData.delivery;
                          })
                        }}
                      >
                        초기화
                      </div>
                    </div>
                    <AccountTable>
                      <thead>
                        <tr>
                          <th></th>
                          <th>운전자</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>이름</th>
                          <td>
                            <input
                              type="text"
                              name="driver"
                              value={_deliveryData?.driver}
                              onInput={handleDeliveryData}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>전화번호</th>
                          <td>
                            <input
                              type="text"
                              name="telNumber"
                              value={_deliveryData?.telNumber}
                              onInput={handleDeliveryData}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>차량번호</th>
                          <td>
                            <input
                              type="text"
                              name="licensePlateNumber"
                              value={_deliveryData?.licensePlateNumber}
                              onInput={handleDeliveryData}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </AccountTable>
                  </div>
                </>
              }
              formSectionStyle={{ paddingTop: '10px', width: 'calc(100% - 210px)' }}
            />
          </section>
        </>
      }
    />
  );
};

export default UpdateInvoice;
