import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { equipmentApi } from 'api/apis/equipmentApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import PagingComponent from 'components/paging/PagingComponent';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 40px 50px;
  height: 85%;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const SelectOneEquipment = (props) => {
  /* ========================================================================= #1 */
  const { userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_equipmentList, setEquipmentList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState({});

  const [_pageNumber, setPageNumber] = useState(1);
  const [_totalSize, setTotalSize] = useState(0);

  /* ========================================================================= #3 */
  useEffect(() => {
    getEquipmentList(_pageNumber - 1);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getEquipmentList(_pageNumber - 1);
    }
    return () => { };
  }, [_pageNumber]);

  /* ========================================================================= #4 */
  const getEquipmentList = async (page) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = { companyId: userReducer.company.companyId };
    await equipmentApi.searchEquipment(paging, BodyToPost).then(response => {
      if (response === undefined) return;
      console.log('equipmentApi.searchEquipment : ', response);
      setEquipmentList(() => { return response.data.content });
      setTotalSize(() => { return response.data.totalElements });
    })
  };

  /* ========================================================================= #5 */
  const actSelect = () => {
    const returnData = { ..._checkedItem };
    if (checkEmptyNull(props.equipmentPos, false)) returnData.processKey = props.equipmentPos;
    if (props.handleEquipment) props.handleEquipment(returnData);
    props.buttonEvent(returnData);
  };

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>설비 선택</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <TableSection content={
                <table>
                  <thead>
                    <tr>
                      <th style={{ minWidth: 'unset', width: '50px' }}></th>
                      <th>설비코드</th>
                      <th>설비명</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_equipmentList.map((equipment, index) => {
                      return (
                        <tr key={index + '_equipment'}>
                          <td style={{ minWidth: 'unset', width: '50px' }}>
                            <input
                              type="checkBox"
                              name="equipments"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setCheckedItem(() => { return equipment });
                                  document.querySelectorAll('input[name="equipments"]').forEach(item => item.checked = false);
                                  e.target.checked = true;
                                }
                              }}
                            />
                          </td>
                          <td>{equipment.equipmentCode}</td>
                          <td>{equipment.equipmentName}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
              />
            </ModalMain>

            <PagingComponent
              page={_pageNumber}
              count={_totalSize}
              size={10}
              pageEvent={(page) => {
                console.log('page : ', page);
                setPageNumber(() => { return page; });
              }}
            />

            <ModalFooter>
              <ModalButton onClick={actSelect}>설비 선택</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default SelectOneEquipment;
