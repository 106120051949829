import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { setCookie } from 'preferences/cookie/cookie';

// 구글 로그인 페이지 리다이렉트 반환 페이지
const OAuth2RedirectHandler = () => {
  const navigate = useNavigate();

  const getUrlParameter = (name) => {
    const setName = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + setName + '=([^&#]*)');

    const results = regex.exec(window.location.search);
    console.log(name, ' : ', results);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  useEffect(() => {
    const accessToken = getUrlParameter('accessToken');
    const refreshToken = getUrlParameter('refreshToken');
    if (
      accessToken !== null &&
      accessToken !== '' &&
      refreshToken !== null &&
      refreshToken !== ''
    ) {
      const options = {
        // httpOnly: true,
      };
      setCookie('accessToken', accessToken, options);
      setCookie('refreshToken', refreshToken, options);
      setCookie('tokenTime', moment().format('YYYY-MM-DD HH:mm:ss'), options);

      setTimeout(navigate('/', { replace: true }), 1000);
    } else {
      console.log('OAuth2RedirectHandler - error : ', accessToken);
      navigate('/', { replace: true });
    }

    return () => {};
  }, []);

  return <></>;
};

export default OAuth2RedirectHandler;
