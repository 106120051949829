import React from 'react';
import styled from 'styled-components';

import MaterialMonitoring from './MaterialMonitoring';
import ProductionOrderMonitoring from './ProductionOrderMonitoring';
import CalendarMonitoring from './CalendarMonitoring';

const Section = styled.main`
  display: grid;
  grid-template-columns: 35% 65%;
  grid-template-rows: repeat(2, 50%);
  grid-template-areas:
  "material production"
  "material calendar"
  ;
  height: 100%;
  width: 100%;
`;

const TotalMonitoring = () => {
  return (
    <Section>
      <MaterialMonitoring total={true} />
      <ProductionOrderMonitoring total={true} />
      <CalendarMonitoring total={true} />
    </Section>
  );
};

export default TotalMonitoring;