export const MallStatusName = (status, type) => {
  switch (status) {
    case 'waiting':
      return '대기';
    case 'consulting':
      return '협의 중';
    case 'preparing':
      return '상품 준비 중';
    case 'complete' && type === 'qna':
      return '답변 완료';
    case 'complete':
      return '상품 발송';
    case 'requestCancel':
      return '취소 요청';
    case 'cancel':
      return '취소';

    default:
      return '';
  }
};
