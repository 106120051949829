import React from 'react';
import styled from 'styled-components';

import { detailViewIcon } from 'components/icons/src';

const DetailViewButtonStyle = styled.div`
  display: grid;
  justify-content: center;
`;
const DetailViewIcon = styled.div`
  background-color: #000;
  height: 25px;
  width: 25px;

  mask-image: url(${detailViewIcon});
  mask-repeat: no-repeat;
  mask-size: contain;

  --webkit-mask-image: url(${detailViewIcon});
  --webkit-mask-repeat: no-repeat;
  --webkit-mask-size: contain;
`;

const DetailViewButton = (props) => {
  return (
    <DetailViewButtonStyle
      rowSpan={props.rowSpan}
      style={props.statusStyle}>
      <DetailViewIcon />
    </DetailViewButtonStyle>
  );
};

export default DetailViewButton;
