import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Treebeard, decorators } from 'react-treebeard';

import {
  modalFileActions_setCurrentFolder,
  modalFileActions_setFileCurrentPage,
  modalFileActions_setFolderList,
  modalFileActions_setFolderTreeList,
} from 'store/modules/actions/modal/modalFileActions';

import { fileCategoryApi } from 'api/apis/fileCategoryApi';

import { treeStyles } from 'pages/file/folder/lib/treeStyles';
import TreeHeader from 'pages/file/folder/lib/TreeHeader';

const rename = require('deep-rename-keys');

const FolderSection = styled.div`
  background-color: var(--white);
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 50px calc(100% - 55px);
  height: 100%;
  overflow: hidden;
  padding: 10px;
  padding-top: 0px;
  row-gap: 5px;
  width: 100%;
`;

const CurrentFolder = styled.div`
  /* align-items: center; */
  background-color: var(--MainBlue);
  /* display: grid; */
  /* grid-template-columns: calc(100% - 50px) 50px; */
  height: 100%;
  /* justify-content: flex-start; */
  width: 100%;
`;

const CurrentFolderTitle = styled.div`
  align-items: center;
  box-sizing: border-box;
  color: var(--white);
  /* cursor: pointer; */
  display: flex;
  height: 100%;
  justify-content: flex-start;
  padding-left: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  /* &:hover {
    background-color: var(--MainNavy);
  } */
`;

const FolderList = styled.div`
  background-color: var(--Bg);
  height: 100%;
  overflow-y: scroll;
  width: 100%;

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 6px;
  }
`;

const ModalFolderManagement = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { modalFileReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */

  const [cursor, setCursor] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    dispatch(
      modalFileActions_setCurrentFolder({
        name: userReducer.company.companyName,
        active: true,
        toggled: true,
        root: true,
        children: [],
      }),
    );

    getFileCategories(true);

    return () => {};
  }, []);

  /* ====================================================================== #4 */

  const treeData = (result) => {
    const rootData = {
      name: userReducer.company.companyName,
      active: true,
      toggled: true,
      root: true,
      children: result.map((thisItem) => {
        const returnData = rename(thisItem, (key) => {
          if (key === 'fileCategoryName') return 'name';
          if (key === 'childrenFileCategory') return 'children';

          return key;
        });

        const setReturnData = { ...returnData };
        if (
          modalFileReducer.currentFolder !== undefined &&
          modalFileReducer.currentFolder !== null &&
          typeof modalFileReducer.currentFolder === 'object' &&
          Object.keys(modalFileReducer.currentFolder).length > 0 &&
          !modalFileReducer.currentFolder.root
        ) {
          if (
            modalFileReducer.currentFolder.fileCategoryId ===
            setReturnData.fileCategoryId
          ) {
            setReturnData.active = true;
          } else if (
            modalFileReducer.currentFolder.fileCategoryParentId ===
            setReturnData.fileCategoryId
          ) {
            setReturnData.active = true;
          }
        }

        return setReturnData;
      }),
    };
    return rootData;
  };
  const getFileCategories = async (prev) => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      fileCategoryParentId: null,
      fileCategoryLevel: 1,
    };
    console.log('getFileCategories - BodyToPost : ', BodyToPost);

    await fileCategoryApi.searchFileCategory(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('fileCategoryApi.searchFileCategory : ', response);

      const result = treeData(response.data);
      // console.log('result : ', result);

      dispatch(modalFileActions_setFolderList(response.data));
      dispatch(modalFileActions_setFolderTreeList(result));
    });
  };

  /* ====================================================================== #5 */
  const onToggle = (node, toggled) => {
    console.log('node : ', node);
    if (cursor) {
      cursor.active = false;
    }
    node.active = true;
    if (node.children) {
      node.toggled = toggled;
    }
    setCursor(node);

    if (!node.root) {
      dispatch(modalFileActions_setCurrentFolder(node));
      dispatch(modalFileActions_setFileCurrentPage(1));
    } else {
      dispatch(
        modalFileActions_setCurrentFolder({
          name: userReducer.company.companyName,
          active: true,
          toggled: true,
          root: true,
          children: [],
        }),
      );
      dispatch(modalFileActions_setFileCurrentPage(1));
    }
    dispatch(
      modalFileActions_setFolderTreeList(
        Object.assign({}, modalFileReducer.folderTreeList),
      ),
    );
  };

  /* ====================================================================== #6 */

  return (
    <FolderSection>
      <CurrentFolder>
        <CurrentFolderTitle>
          {modalFileReducer.currentFolder.name ||
            userReducer.company.companyName}
        </CurrentFolderTitle>
      </CurrentFolder>
      <FolderList>
        <Treebeard
          data={modalFileReducer.folderTreeList}
          decorators={{ ...decorators, TreeHeader }}
          style={treeStyles}
          onToggle={onToggle}
        />
      </FolderList>
    </FolderSection>
  );
};

export default ModalFolderManagement;
