import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { teamApi } from 'api/apis/teamApi';
import { userApi } from 'api/apis/userApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';

const WorkerCreateContent = ({
  close
}) => {
  const {userReducer} = useSelector((state) => state);

  const body = {
    companyId: userReducer.company.companyId,
    teamId: '',
    name: '',
    memo: '',
    password: '',
  };
  const [_body, setBody] = useState(body);

  const [_teamList, setTeamList] = useState([]);

  useEffect(() => {
    getTeamList();
  }, []);
  useEffect(() => {}, [_body]);

  const getTeamList = async () => { // 팀 리스트 불러오기
    const paging = `?page=0&size=1000`;
    const body = {
      companyId: userReducer.company.companyId,
    };
    await teamApi.searchTeam(paging, body).then((response) => {
      if (response === undefined) return;
      setTeamList(() => {return response.data.content;});
    });
  };

  const handleInputEvent = (e) => { // 사용자 정보 입력
    const {name, value} = e.target;
    setBody((prev) => {
      const returnData = {...prev, [name] : value};
      return returnData;
    })
  };
  const actWorkerCreate = async() => { // 작업자 추가
    if(!checkEmptyNull(_body.teamId, false)) return alert('팀을 선택해주세요.');
    const createBody = {
      ..._body,
      teamId: Number(_body.teamId)
    }
    await userApi.createWorker(createBody).then(response => {
      if(response === undefined) return;
      console.log('actWorkerCreate : ', response);
      alert('작업자를 추가했습니다.');
      close('update');
    })
  };

  return (
    <section className='user-content'>
      <div>
        <div>
          <h4>팀</h4>
          <select name='teamId' value={_body.teamId} onChange={handleInputEvent}>
            <option value={''}>팀 선택</option>
          {_teamList.map((teamData, index) => {
            return (
              <option key={index + '_team'} value={teamData.teamId}>{teamData.teamName}</option>
            )
          })}
          </select>
        </div>
        {[
          {name: 'name', title: '이름', disabled: false},
          {name: 'memo', title: '메모', disabled: false},
        ].map(((userData) => {
          return (
            <div key={userData.name}>
              <h4>{userData.title}</h4>
              {userData.name === 'memo' ? (
                <textarea
                  name={userData.name}
                  value={checkEmptyNull(_body[userData.name], '')}
                  onInput={handleInputEvent}
                ></textarea>
              ) : (
                <input
                  type='text'
                  name={userData.name}
                  disabled={userData.disabled}
                  value={checkEmptyNull(_body[userData.name], '')}
                  onInput={handleInputEvent}
                />
              )}
            </div>
          )
        }))}

        <p>※ 사용자는 회원가입을 통하여 추가가 가능합니다.</p>
      </div>

      <button className='btn-save' onClick={actWorkerCreate}>저장</button>
    </section>
  );
};

export default WorkerCreateContent;