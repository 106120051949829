import React, { Fragment, useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import styled from 'styled-components';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto;
  height: 90%;
  overflow: hidden;
  width: 98%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: inherit;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 6px;
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const LoadingScreen = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  color: var(--white);
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const PrintSection = styled.div`
  display: grid;
  grid-template-rows: 25px 150px calc(100% - 175px);
  height: 297mm;
  margin: 0px auto;
  min-height: 297mm;
  width: 210mm;
`;

const HeaderContents = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 28px;
  justify-content: flex-end;
  padding: 0px 10px 10px 10px;
  position: relative;
  width: 100%;
  z-index: 600;
`;
const HeaderCon = styled.div`
  font-size: 0.6em;
`;

const WorkOrderInfo = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 210mm;

  table {
    background-color: var(--white);
    border-collapse: collapse;
    height: 146px;
    max-height: 146px;
    margin: 0px auto;
    width: 100%;

    td,
    th {
      border: 1px solid var(--gray-200);
      box-sizing: border-box;
      font-size: 0.55em;
      height: 25px;
      padding: 0px 5px 10px 5px;
      vertical-align: middle;
    }

    thead {
      background-color: var(--MainBlue);
      border: 1px soild var(--MainBlue);
      color: var(--white);

      th {
        border: unset;
      }
    }

    tbody th {
      min-width: 42px !important;
      padding-left: 0px;
      padding-right: 0px;
      width: 42px !important;
    }

    td.EctInfo {
      width: 60px;
      padding: 0px 2px 10px 2px;
    }
  }
`;

const PrintTable = styled.div`
  overflow: hidden;
  width: 210mm;

  table {
    border-collapse: collapse;
    max-height: 100%;
    max-width: 100%;
    min-width: 100%;

    thead {
      background-color: var(--MainNavy);
      position: initial;
      th {
        color: var(--white);
        height: 28px;
        padding: unset;
        padding-bottom: 10px;
      }
    }

    td,
    th {
      box-sizing: border-box;
      max-width: unset;
      min-width: unset;
      padding: unset;
      text-align: center;
      white-space: normal;
      width: unset;
      word-break: break-all;
    }
    td {
      border: 1px solid var(--gray-200);
      font-size: 0.6em;
      height: 180px /* 47.625mm */;
      line-height: 1em;
      min-width: 60px;
      overflow: hidden;
    }
    th {
      font-size: 0.6em;
    }

    td.materialStatus,
    td.colorStatus {
      p {
        font-size: 0.9em;
        padding: 2px;
        text-align: left;
      }
    }
  }
`;

const DrawingCell = styled.div`
  display: grid;
  grid-template-rows: 40px 40px 100px;
  height: 100%;
  width: 100px;

  div {
    align-items: center;
    border: 1px solid var(--gray-100);
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  div[data-pos='Top'] {
    border: unset;
    display: grid;
    grid-template-columns: 50% 50%;

    p {
      align-items: center;
      border: 1px solid var(--gray-100);
      box-sizing: border-box;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;
    }
  }

  div[data-pos='Bottom'] {
    border: unset;
  }
`;
const Drawing = styled.div`
  align-items: center;
  background-color: var(--white);
  border: unset;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  img {
    max-height: 95px;
    max-width: 95px;
  }
`;

const AmountCell = styled.div`
  display: grid;
  grid-template-rows: repeat(2, minmax(50%, auto));
  height: 100%;
  width: 100%;
`;

const TaskCell = styled.div`
  align-self: flex-start;
  border: 1px solid var(--gray-100);
  box-sizing: border-box;
  height: 100%;
  width: 100%;

  div[name='taskCells'] {
    display: grid;

    p[name='tasks'] {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding: 2px;
    }
  }

  div[name='glassType_W'],
  div[name='glassType_H'] {
    border: none;
    box-sizing: border-box;
    display: grid;
    font-size: 0.9em;
    height: 100%;
    padding: unset;
    width: 100%;

    p {
      padding: 2px;
    }
  }
`;

const PrintDrawing = (props) => {
  const imgToBase64ByCanvas = async (thisfileDataS3URL) => {
    const newUrl = thisfileDataS3URL + '?' + new Date().getTime(); // ******************************** 중요!!!!!!!!!
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = newUrl;
      image.crossOrigin = 'Anonymous';
      image.onload = () => {
        let canvas = document.createElement('canvas');
        canvas.width = image.naturalWidth;
        canvas.height = image.naturalHeight;
        canvas.getContext('2d').drawImage(image, 0, 0);

        let thisSrc = canvas.toDataURL('image/png', 1.0);

        resolve(thisSrc);
      };
    });
  };

  const [_drawingSrc, setDrawingScr] = useState(
    imgToBase64ByCanvas(props.content)
      .then((response) => {
        if (response === undefined) return;
        setDrawingScr(response);
        return response;
      })
      .catch((error) => {
        console.log('imgToBase64ByCanvas - error : ', error);
        return '';
      }),
  );

  return (
    <>
      <img src={_drawingSrc} alt="" />
    </>
  );
};

const PrintContent = (props) => {
  let materials_count = 0;
  let colors_count = 0;

  const taskTypeArray = ['BF', 'CP', 'SF', 'MF', 'GB', 'MC'];

  const [_printDatas, setPrintDatas] = useState(props.content);

  const [_printInfo, setPrintInfo] = useState(() => {
    if (
      props.info.workOrderCustomizedContent !== undefined &&
      props.info.workOrderCustomizedContent !== null &&
      props.info.workOrderCustomizedContent.startsWith('{') &&
      props.info.workOrderCustomizedContent.endsWith('}')
    ) {
      return {
        ...props.info,
        workOrderCustomizedContent: JSON.parse(
          props.info.workOrderCustomizedContent,
        ),
      };
    } else {
      return {
        ...props.info,
        workOrderCustomizedContent: {
          workOrderProducts: '',
          BFDate: '',
          phoneNumber: '',
          TotalDate: '',
          colors: '',
        },
      };
    }
  });
  console.log('_printInfo : ', props);

  const [_printPage, setPrintPage] = useState(
    `${props.currentPage} / ${props.totalPage}`,
  );

  return (
    <PrintSection name={props.name}>
      <HeaderContents>
        <HeaderCon>{_printPage}</HeaderCon>
      </HeaderContents>

      <WorkOrderInfo>
        <table>
          <thead>
            <tr>
              <th colSpan={8} style={{ textAlign: 'left' }}>
                {_printInfo.message}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th
                rowSpan={2}
                style={{
                  backgroundColor: 'var(--Yellow)',
                  fontWeight: '600',
                }}
              >
                품목
              </th>
              <td
                rowSpan={2}
                style={{
                  backgroundColor: 'var(--Yellow)',
                  fontWeight: '600',
                  width: '30%',
                }}
              >
                {_printInfo.workOrderCustomizedContent.workOrderProducts}
              </td>

              <th
              // style={{
              //   backgroundColor: 'var(--Yellow)',
              //   fontWeight: '600',
              // }}
              >
                거래처
              </th>
              <td
              // style={{
              //   backgroundColor: 'var(--Yellow)',
              //   fontWeight: '600',
              // }}
              >
                {_printInfo.accountName}
              </td>

              <th>B/F납기</th>
              <td className="EctInfo">
                {checkEmptyNull(_printInfo.workOrderCustomizedContent.BFDate, false) ? DateFormat(_printInfo.workOrderCustomizedContent.BFDate) : ''}
              </td>
              <th>B/F</th>
              <td className="EctInfo">
                {_printInfo.workOrderCustomizedContent.BFAmount}
              </td>
            </tr>
            <tr>
              <th>전화번호</th>
              <td>{_printInfo.workOrderCustomizedContent.phoneNumber}</td>

              <th>S/F납기</th>
              <td className="EctInfo">
                {checkEmptyNull(_printInfo.workOrderCustomizedContent.TotalDate, false) ? DateFormat(_printInfo.workOrderCustomizedContent.TotalDate) : ''}
              </td>
              <th>S/F</th>
              <td className="EctInfo">
                {_printInfo.workOrderCustomizedContent.TotalAmount}
              </td>
            </tr>
            <tr>
              <th>색상</th>
              <td
                style={{
                  border: '4px solid var(--Text)',
                  boxSizing: 'border-box',
                  padding: 'unset',
                  width: '30%',
                }}
              >
                <div
                  style={{
                    boxSizing: 'border-box',
                    fontSize: '1.4em',
                    fontWeight: '600',
                    height: '100%',
                    paddingBottom: '5px',
                    textAlign: 'center',
                    transform: 'translateY(-5px)',
                    width: '100%',
                  }}
                >
                  {_printInfo.workOrderCustomizedContent.colors}
                </div>
              </td>

              <th>주소</th>
              <td>{_printInfo.workPlace}</td>

              <th>M/F납기</th>
              <td className="EctInfo"></td>
              <th>M/F</th>
              <td className="EctInfo"></td>
            </tr>
            <tr>
              <th>비고</th>
              <td colSpan={3}>{_printInfo.remark}</td>

              <th>지시일자</th>
              <td className="EctInfo">
                {checkEmptyNull(_printInfo.workOrderScheduledStartDate, false) ? DateFormat(_printInfo.workOrderScheduledStartDate) : ''}
              </td>
              <th>작성자</th>
              <td
                className="EctInfo"
                style={{
                  fontSize: '0.5em',
                }}
              >
                {_printInfo.userName}
              </td>
            </tr>
          </tbody>
        </table>
      </WorkOrderInfo>

      <PrintTable>
        <table>
          <thead>
            <tr
              style={{
                borderLeft: '1px solid var(--gray-200)',
                borderRight: '1px solid var(--gray-200)',
              }}
            >
              <th style={{ minWidth: '30px', width: '30px' }}>NO</th>
              <th>도면</th>
              <th style={{ minWidth: '80px', width: '80px' }}>자재</th>
              <th style={{ minWidth: '80px', width: '80px' }}>색상</th>
              <th>B/F</th>
              <th>C/P</th>
              <th>S/F</th>
              <th>M/F</th>
              <th>G/B</th>
              <th>유리종류</th>
              <th>M/C</th>
              <th>비고</th>
            </tr>
          </thead>

          <tbody>
            {_printDatas !== undefined &&
              _printDatas.length > 0 &&
              _printDatas.map((thisItem, index) => {
                const processCustomizedContent = {
                  numStatus: '',
                  bundle: '',
                  bundleSize: '',
                  materialStatus: '',
                  materials: '',
                  colorStatus: '',
                  colors: '',
                  glassType_W: '',
                  glassType_H: '',
                  remark: '',
                };
                if (
                  thisItem.processCustomizedContent !== undefined &&
                  thisItem.processCustomizedContent !== null &&
                  thisItem.processCustomizedContent.startsWith('{') &&
                  thisItem.processCustomizedContent.endsWith('}')
                ) {
                  const customizedContent = JSON.parse(
                    thisItem.processCustomizedContent,
                  );
                  processCustomizedContent.numStatus =
                    customizedContent.numStatus;
                  processCustomizedContent.bundle = customizedContent.bundle;
                  processCustomizedContent.bundleSize =
                    customizedContent.bundleSize;
                  processCustomizedContent.materialStatus =
                    customizedContent.materialStatus;
                  processCustomizedContent.materials =
                    customizedContent.materials;
                  processCustomizedContent.colorStatus =
                    customizedContent.colorStatus;
                  processCustomizedContent.colors = customizedContent.colors;
                  processCustomizedContent.glassType_W =
                    customizedContent.glassType_W;
                  processCustomizedContent.glassType_H =
                    customizedContent.glassType_H;
                  processCustomizedContent.remark = customizedContent.remark;
                }

                return (
                  <tr key={index + '_workOrder'}>
                    <td
                      className={`numStatus ${processCustomizedContent.numStatus}`}
                      style={{ minWidth: '30px', width: '30px' }}
                      data-key={index}
                    >
                      {(index + 1).toString().padStart(2, '0')}
                    </td>

                    <td
                      style={{
                        minWidth: '100px',
                        padding: 'unset',
                        width: '100px',
                        verticalAlign: 'top',
                      }}
                    >
                      <DrawingCell>
                        <div data-pos="Top">
                          <p>{processCustomizedContent.bundle}</p>
                          <p>{thisItem.processingCount}</p>
                        </div>

                        <div data-pos="Middle">
                          {processCustomizedContent.bundleSize}
                        </div>

                        <div data-pos="Bottom">
                          <Drawing id={'Drawing_' + thisItem.processLogId}>
                            <PrintDrawing content={thisItem.fileDataS3URL} />
                          </Drawing>
                        </div>
                      </DrawingCell>
                    </td>

                    <td
                      className={`materialStatus ${processCustomizedContent.materialStatus}`}
                    >
                      {processCustomizedContent.materials !== undefined
                        ? processCustomizedContent.materials !== null
                          ? processCustomizedContent.materials
                            .split(
                              processCustomizedContent.materials.indexOf(
                                '\n',
                              ) === -1
                                ? ' '
                                : '\n',
                            )
                            .map((thisMaterial) => {
                              materials_count++;
                              return (
                                <p
                                  key={index + materials_count + thisMaterial}
                                >
                                  {thisMaterial}
                                </p>
                              );
                            })
                          : null
                        : null}
                    </td>

                    <td
                      className={`colorStatus ${processCustomizedContent.colorStatus}`}
                    >
                      {processCustomizedContent.colors !== undefined
                        ? processCustomizedContent.colors !== null
                          ? processCustomizedContent.colors
                            .split(
                              processCustomizedContent.colors.indexOf(
                                '\n',
                              ) === -1
                                ? ' '
                                : '\n',
                            )
                            .map((thisColor) => {
                              colors_count++;
                              return (
                                <p key={index + colors_count + thisColor}>
                                  {thisColor}
                                </p>
                              );
                            })
                          : null
                        : null}
                    </td>

                    {taskTypeArray.map((thisType) => {
                      let thisWContent = [];
                      let thisHContent = [];
                      if (thisItem.taskLogList.length > 0) {
                        thisWContent = thisItem.taskLogList.filter(
                          (thisItem) => thisItem.taskType === thisType + '_W',
                        );
                        thisHContent = thisItem.taskLogList.filter(
                          (thisItem) => thisItem.taskType === thisType + '_H',
                        );
                      }

                      let glassCount_W = 0;
                      let glassCount_H = 0;
                      let remarkCount = 0;

                      return (
                        <Fragment key={index + thisType + 'smallProcesses'}>
                          <td style={{ padding: 'unset' }}>
                            <AmountCell>
                              <TaskCell>
                                <div name="taskCells">
                                  {thisItem.taskLogList.length > 0
                                    ? thisWContent.map((thisAmountW) => {
                                      return (
                                        <p
                                          key={
                                            index +
                                            thisType +
                                            '_W' +
                                            thisAmountW.taskName
                                          }
                                          name="tasks"
                                        >
                                          {thisAmountW.taskName}
                                        </p>
                                      );
                                    })
                                    : null}
                                </div>
                              </TaskCell>

                              <TaskCell>
                                <div name="taskCells">
                                  {thisItem.taskLogList.length > 0
                                    ? thisHContent.map((thisAmountH) => {
                                      return (
                                        <p
                                          key={
                                            index +
                                            thisType +
                                            '_H' +
                                            thisAmountH.taskName
                                          }
                                          name="tasks"
                                        >
                                          {thisAmountH.taskName}
                                        </p>
                                      );
                                    })
                                    : null}
                                </div>
                              </TaskCell>
                            </AmountCell>
                          </td>

                          {thisType === 'GB' && (
                            <td style={{ padding: 'unset' }}>
                              <AmountCell>
                                <TaskCell>
                                  <div name="glassType_W">
                                    {processCustomizedContent.glassType_W !==
                                      undefined
                                      ? processCustomizedContent.glassType_W !==
                                        null
                                        ? processCustomizedContent.glassType_W
                                          .split(
                                            processCustomizedContent.glassType_W.indexOf(
                                              '\n',
                                            ) === -1
                                              ? ' '
                                              : '\n',
                                          )
                                          .map((thisItem) => {
                                            glassCount_W++;
                                            return (
                                              <p
                                                key={
                                                  index +
                                                  'glassType_W' +
                                                  glassCount_W
                                                }
                                              >
                                                {thisItem}
                                              </p>
                                            );
                                          })
                                        : null
                                      : null}
                                  </div>
                                </TaskCell>

                                <TaskCell>
                                  <div name="glassType_H">
                                    {processCustomizedContent.glassType_H !==
                                      undefined
                                      ? processCustomizedContent.glassType_H !==
                                        null
                                        ? processCustomizedContent.glassType_H
                                          .split(
                                            processCustomizedContent.glassType_H.indexOf(
                                              '\n',
                                            ) === -1
                                              ? ' '
                                              : '\n',
                                          )
                                          .map((thisItem) => {
                                            glassCount_H++;
                                            return (
                                              <p
                                                key={
                                                  index +
                                                  'glassType_H' +
                                                  glassCount_H
                                                }
                                              >
                                                {thisItem}
                                              </p>
                                            );
                                          })
                                        : null
                                      : null}
                                  </div>
                                </TaskCell>
                              </AmountCell>
                            </td>
                          )}

                          {thisType === 'MC' && (
                            <td>
                              {processCustomizedContent.remark !== undefined
                                ? processCustomizedContent.remark !== null
                                  ? processCustomizedContent.remark
                                    .split(
                                      processCustomizedContent.remark.indexOf(
                                        '\n',
                                      ) === -1
                                        ? ' '
                                        : '\n',
                                    )
                                    .map((thisItem) => {
                                      remarkCount++;
                                      return (
                                        <p
                                          key={index + 'remark' + remarkCount}
                                          style={{
                                            fontSize: '0.9em',
                                            padding: '2px',
                                          }}
                                        >
                                          {thisItem}
                                        </p>
                                      );
                                    })
                                  : null
                                : null}
                            </td>
                          )}
                        </Fragment>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </PrintTable>
    </PrintSection>
  );
};

const UriWorkOrderPdfModal = (props) => {
  /* ====================================================================== #2 */
  const { content } = props;

  const [_onload, setOnload] = useState('unload');

  const [_processLogList, setProcessLogList] = useState(() => {
    const processLogList = content.processLogList.map((thisItem) => {
      const returnData = {
        ...thisItem,
        fileDataS3URL: '',
      };
      if (thisItem.drawingList.length > 0) {
        returnData.fileDataS3URL = thisItem.drawingList[0].fileDataS3URL;
      }

      return returnData;
    });

    processLogList.sort((a, b) => {
      return a.processLogId - b.processLogId;
    });

    return processLogList;
  });
  const [_pdfContents, setPdfContents] = useState(() => {
    if (_processLogList === undefined || _processLogList === null) return [];

    const pdfContents = [];
    for (let i = 0; i < _processLogList.length; i += 5) {
      pdfContents.push(_processLogList.slice(i, i + 5));
    }

    return pdfContents;
  });

  const [_loadingStatus, setLoadingStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    setOnload('loaded');
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const actPrint = () => {
    console.log(document.getElementsByName('PrintContents'));

    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
    });

    let count = 0;
    document.getElementsByName('PrintContents').forEach(async (thisItem) => {
      const thisHeight = thisItem.offsetHeight;
      const thisWidth = thisItem.offsetWidth;
      const ratio = thisWidth / thisHeight;

      const option = {
        height: thisHeight,
        width: thisWidth,
      };

      await html2canvas(thisItem, option).then((canvas) => {
        const image = canvas.toDataURL('image/png');
        let width = doc.internal.pageSize.getWidth();
        let height = 290;
        width = ratio * height;

        const margin = 2;
        const position = 2;

        doc.addImage(image, 'jpeg', margin, position, width, height);
        if (count < document.getElementsByName('PrintContents').length - 1) {
          doc.addPage();
        }
      });

      count++;
      console.log(
        count === document.getElementsByName('PrintContents').length,
        count,
        document.getElementsByName('PrintContents').length,
      );
      if (count === document.getElementsByName('PrintContents').length) {
        if (document.getElementsByName('PrintContents').length > 6) {
          doc.save();
          setLoadingStatus(false);
        } else {
          window.open(doc.output('bloburl'));
          setLoadingStatus(false);
        }
      }
    });
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>작업지시 미리보기</ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              {_pdfContents.map((thisItem, index) => {
                return (
                  <PrintContent
                    key={index + '_PrintContents'}
                    name="PrintContents"
                    totalPage={_pdfContents.length}
                    currentPage={index + 1}
                    content={thisItem}
                    info={content}
                  />
                );
              })}
            </ModalMain>

            <ModalFooter>
              <ModalButton
                onClick={() => {
                  setLoadingStatus(true);
                  setTimeout(actPrint, 1000);
                }}
              >
                출력
              </ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}

      {_loadingStatus === true ? (
        <LoadingScreen>파일 생성 중입니다...</LoadingScreen>
      ) : null}
    </>
  );
};

export default UriWorkOrderPdfModal;
