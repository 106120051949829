import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { procurementApi } from 'api/apis/procurementApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import SelectProcurementRow from 'components/row/SelectProcurementRow';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 40px 50px;
  height: 85%;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const SelectProcurements = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_procurementList, setProcurementList] = useState([]);
  const [_procurementStatus, setProcurementStatus] = useState('all');

  const [_checkedItem, setCheckedItem] = useState([]);

  const [_startDate, setStartDate] = useState('');
  const [_endDate, setEndDate] = useState('');

  const [_searchOption, setSearchOption] = useState('');
  const [_searchText, setSearchText] = useState('');

  const [_pageNum, setPageNum] = useState(1);
  const [_pageCount, setPageCount] = useState(0);

  /* ====================================================================== #3 */
  useEffect(() => {
    getProcurementList(0, _procurementStatus);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getProcurementList(_pageNum - 1, _procurementStatus);
    }

    return () => { };
  }, [_pageNum]);

  /* ====================================================================== #4 */
  const getProcurementList = async (page, procurementStatus, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    if (!clear && checkEmptyNull(procurementStatus, false) && procurementStatus !== 'all') {
      BodyToPost.procurementStatus = procurementStatus;
    }

    if (!clear && checkEmptyNull(_startDate, false) && checkEmptyNull(_endDate, false)) {
      const startDate = new Date(_startDate);
      const setStartDate = startDate.toISOString();
      BodyToPost.procurementStartDate = setStartDate;

      const endDate = new Date(_endDate);
      endDate.setDate(endDate.getDate() + 1);
      const setEndDate = endDate.toISOString();
      BodyToPost.procurementEndDate = setEndDate;

      // 시작 날짜가 종료날짜보다 클 때
      if (_startDate > _endDate) {
        const reverseStartDate = new Date(_startDate);
        reverseStartDate.setDate(reverseStartDate.getDate() + 1);
        const setReverseStartDate = reverseStartDate.toISOString();

        const reverseEndDate = new Date(_endDate);
        const setReversetEndDate = reverseEndDate.toISOString();

        BodyToPost.procurementStartDate = setReversetEndDate;
        BodyToPost.procurementEndDate = setReverseStartDate;
      }
    }
    if (!clear && checkEmptyNull(_searchOption, false) && checkEmptyNull(_searchText, false)) {
      BodyToPost[_searchOption] = _searchText;
    }
    console.log('getProcurementList - BodyToPost : ', BodyToPost);

    await procurementApi.searchProcurement(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('procurementApi.searchProcurement : ', response);
      setProcurementList(() => { return response.data.content });
      setPageCount(() => { return response.data.totalElements; });
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (item, isChecked) => {
    const checkedItem = [..._checkedItem];
    const checkedIndex = checkedItem.findIndex((thisItem) => thisItem.procurementId === item.procurementId);

    if (checkedIndex !== -1) {
      if (!isChecked) checkedItem.splice(checkedIndex, 1);
    } else {
      if (isChecked) checkedItem.push(item);
    }
    console.log('checkedItem : ', checkedItem);

    setCheckedItem(() => { return checkedItem });
  };

  /* 검색 */
  const actSearch = async () => {
    setPageNum(() => { return 1 });
    await getProcurementList(0);
  };

  /* 초기화 */
  const actReset = async () => {
    setProcurementStatus(() => { return 'all' });
    setStartDate(() => { return '' });
    setEndDate(() => { return '' });
    setSearchOption(() => { return '' });
    setSearchText(() => { return '' });
    setPageNum(() => { return 1 });

    await getProcurementList(0, 'all', true);
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>발주 선택</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <NavBar
                title={
                  <>
                    <div className="SearchSection" style={{ columnGap: '20px' }}>
                      <select
                        className="SelectStatus"
                        value={_procurementStatus}
                        onChange={(e) => {
                          setProcurementStatus(() => { return e.target.value });
                          setPageNum(() => { return 1 });
                          getProcurementList(0, e.target.value);
                        }}
                      >
                        {[
                          { optionValue: 'all', optionText: '전체' },
                          { optionValue: 'waiting', optionText: '대기' },
                          { optionValue: 'proceeding', optionText: '진행' },
                          { optionValue: 'end', optionText: '완료' },
                          { optionValue: 'cancel', optionText: '취소' },
                        ].map((thisItem, index) => {
                          return (
                            <option key={index + '_procurementStatus'} value={thisItem.optionValue}>{thisItem.optionText}</option>
                          );
                        })}
                      </select>
                      <div className="SearchSection">
                        <input
                          type="date"
                          className="SelectDate"
                          value={_startDate}
                          onChange={(e) => { setStartDate(() => { return e.target.value }); }}
                        />

                        <IconRightArrow />

                        <input
                          type="date"
                          className="SelectDate"
                          value={_endDate}
                          onChange={(e) => { setEndDate(() => { return e.target.value }); }}
                        />
                      </div>

                      <div className="ContentCBox">
                        <div className="ContentCBox">
                          <select
                            className="SearchOption"
                            onChange={(e) => { setSearchOption(() => { return e.target.value }); }}
                          >
                            {[
                              { optionValue: '', optionText: '검색옵션' },
                              { optionValue: 'procurementCode', optionText: '발주코드' },
                              { optionValue: 'procurementName', optionText: '발주이름' },
                              { optionValue: 'accountName', optionText: '거래처명' },
                            ].map((thisItem, index) => {
                              return (<option key={index + '_procurementSearchOption'} value={thisItem.optionValue}>{thisItem.optionText}</option>);
                            })}
                          </select>
                          <input
                            className="SearchBar"
                            placeholder="Search..."
                            value={_searchText}
                            onInput={(e) => { setSearchText(() => { return e.target.value }); }}
                          />
                        </div>

                        <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                        <div className="ResetButton" onClick={actReset}>초기화</div>
                      </div>
                    </div>
                  </>
                }
                nav={''}
              />

              <TableSection content={
                <table>
                  <thead>
                    <tr style={{ border: 'none' }}>
                      <th rowSpan={2} style={{ minWidth: '100px', padding: 'unset', width: '100px' }}>발주상태</th>
                      <th rowSpan={2} style={{ minWidth: '50px', width: '50px' }}>
                        <input
                          type="checkBox"
                          name="allElements"
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            if (isChecked) {
                              setCheckedItem((prev) => {
                                const prevData = [...prev];
                                const newData = _procurementList.map((thisItem) => {
                                  const thisFindIndex = prevData.findIndex((thisIndex) => thisIndex.procurementId === thisItem.procurementId);
                                  if (thisFindIndex !== -1) return null;
                                  else return thisItem;
                                });
                                const filterData = newData.filter((thisItem) => thisItem !== null);
                                return [...prevData, ...filterData];
                              });
                              e.target.checked = true;
                            } else {
                              setCheckedItem((prev) => {
                                const prevData = prev.map((thisItem) => {
                                  const thisFindIndex = _procurementList.findIndex((thisIndex) => thisIndex.procurementId === thisItem.procurementId);
                                  if (thisFindIndex !== -1) return null;
                                  else return thisItem;
                                });
                                const filterData = prevData.filter((thisItem) => thisItem !== null);
                                return filterData;
                              });
                              e.target.checked = false;
                            }
                          }}
                        />
                      </th>
                      <th rowSpan={2}>발주일자</th>
                      <th rowSpan={2}>입고예정일자</th>
                      <th rowSpan={2}>발주코드</th>
                      <th rowSpan={2}>발주이름</th>
                      <th rowSpan={2}>거래처</th>
                      <th rowSpan={2}>발주총금액</th>
                      <th colSpan={7} style={{ backgroundColor: 'var(--ThirdBlue)' }}>발주물품</th>
                    </tr>
                    <tr>
                      <th>물품이름</th>
                      <th>물품코드</th>
                      <th>투입예정수량</th>
                      <th>현재고</th>
                      <th>단가</th>
                      <th>수량</th>
                      <th>금액</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkNullArray(_procurementList, []).map((procurement, index) => {
                      return (
                        <SelectProcurementRow
                          key={index + '_procurements'}
                          index={index}
                          content={procurement}
                          checkedItem={_checkedItem}
                          handleCheckedItem={handleCheckedItem}
                        />
                      );
                    })}
                  </tbody>
                </table>
              }
              ></TableSection>
            </ModalMain>

            <PagingComponent
              page={_pageNum}
              count={_pageCount}
              size={10}
              pageEvent={(page) => {
                console.log('page : ', page);
                setPageNum(() => { return page });
              }}
            />

            <ModalFooter>
              <ModalButton onClick={() => { const checkedElementList = Array.from(_checkedItem); props.buttonEvent(checkedElementList); }}>
                {props.buttonTitle}
              </ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default SelectProcurements;
