import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { costActions_setEndDate, costActions_setPageNumber, costActions_setReset, costActions_setSearchData, costActions_setStartDate, costActions_setTotalSize, costActions_setWorkOrderStatus, costActions_setWorkOrderType } from 'store/modules/actions/common/costActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { qualityApi } from 'api/apis/qualityApi';
import { workOrderTypeApi } from 'api/apis/workOrderTypeApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import { LoadingMsg } from 'components/loading/LoadingMsg';

import Detail from 'pages/cost/modal/Detail';
import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import ShowFileList from 'pages/file/modal/ShowFileList';
import TableSection from 'components/layouts/table/TableSection';
import StatusTdButton from 'components/buttons/StatusTdButton';
import BigNumber from 'bignumber.js';
import GuideText from 'components/buttons/GuideText';
import DOCButtonIcon from 'components/icons/DOCButtonIcon';

const Section = styled.main`
  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
    & .typeButtons {display: flex; gap: 5px;}
  }
  .TableSection {
    thead tr {border: none;}
  }
`;
const QualityButton = styled.td`
  background-color: var(--Violet);
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  flex-direction: column;
  font-size: 20px;
  font-weight: 600;
  min-height: 100px;
  height: 100%;
  width: 100%;
`;


const Cost = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const { costReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_workOrderLogList, setWorkOrderLogList] = useState([]);

  const [_workOrderTypes, setWorkOrderTypes] = useState([]);

  const [_modalStatus, setModalStatus] = useState(false);
  const [_eventWorkOrderLog, setEventWorkOrderLog] = useState();

  const [_downloadModalStatus, setDownloadModalStatus] = useState(false);

  const [_fileListModalStatus, setFileListModalStatus] = useState(false);
  const [_eventcontent, setEventContent] = useState({});

  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('104') === true || // 거래처
      thisItem.authority.startsWith('109') === true || // 파일
      thisItem.authority.startsWith('415') === true,
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getWorkOrderTypes();
    getWorkOrderLogList(costReducer.pageNumber - 1, costReducer.workOrderType, costReducer.workOrderStatus);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getWorkOrderLogList(costReducer.pageNumber - 1, costReducer.workOrderType, costReducer.workOrderStatus);
    }

    return () => { };
  }, [costReducer.pageNumber]);

  /* ========================================================================= #4 */
  const getWorkOrderTypes = async () => {
    const BodyToPost = {companyId: userReducer.company.companyId};
    await workOrderTypeApi.searchWorkOrderType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderTypeApi.searchWorkOrderType : ', response);
      setWorkOrderTypes(() => { return response.data; });
    });
  };

  const getWorkOrderLogList = async (page, workOrderType, workOrderStatus, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {companyId: userReducer.company.companyId};
    if (!clear && checkEmptyNull(workOrderType, false) && workOrderType !== 'all') {
      BodyToPost.workOrderTypeId = Number(workOrderType);
    }
    if (!clear && checkEmptyNull(workOrderStatus, false)) {
      switch (workOrderStatus) {
        case 'all': BodyToPost.workOrderStatus = ['wait', 'work', 'pause', 'stop', 'end', 'cancel']; break;
        case 'waitNwork': BodyToPost.workOrderStatus = ['wait', 'work']; break;
        default: BodyToPost.workOrderStatus = [workOrderStatus]; break;
      }
    }
    if (!clear && checkEmptyNull(costReducer.startDate, false) && checkEmptyNull(costReducer.endDate, false)) {
      BodyToPost.actualStartDate = DateFormat(costReducer.startDate);
      BodyToPost.actualEndDate = DateFormat(costReducer.endDate);
      if (costReducer.startDate > costReducer.endDate) {
        BodyToPost.actualStartDate = DateFormat(costReducer.endDate);
        BodyToPost.actualEndDate = DateFormat(costReducer.startDate);
      }
    }
    if (!clear && checkEmptyNull(costReducer.searchData.workOrderCode, false)) {
      BodyToPost.workOrderCode = costReducer.searchData.workOrderCode;
    }
    if (!clear && checkEmptyNull(costReducer.searchData.workOrderName, false)) {
      BodyToPost.workOrderName = costReducer.searchData.workOrderName;
    }
    await qualityApi.searchQualityWorkOrderLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('qualityApi.searchQualityWorkOrderLog : ', response);
      setWorkOrderLogList(() => { return response.data.content; });
      dispatch(costActions_setTotalSize(response.data.totalElements));
      if (checkEmptyNull(costReducer.startDate, false) && checkEmptyNull(costReducer.endDate, false)) {
        if (costReducer.startDate > costReducer.endDate) {
          dispatch(costActions_setStartDate(costReducer.endDate))
          dispatch(costActions_setEndDate(costReducer.startDate))
        }
      }
    });
  };

  /* ========================================================================= #5 */
  /* 검색 - 작업상태 */
  const handleSearchWorkOrderStatus = async (workOrderStatus) => {
    dispatch(costActions_setWorkOrderStatus(workOrderStatus));
    dispatch(costActions_setPageNumber(1));
    await getWorkOrderLogList(0, costReducer.workOrderType, workOrderStatus)
  }

  /* 검색 */
  const actSearch = async () => {
    dispatch(costActions_setPageNumber(1));
    await getWorkOrderLogList(0, costReducer.workOrderType, costReducer.workOrderStatus);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(costActions_setReset());
    getWorkOrderLogList(0, 'all', 'all', true);
  };

  /* 생산추적 모달 */
  const showProductionProcessTracking = (workOrderLog) => {
    setEventWorkOrderLog(workOrderLog);
    setTimeout(setModalStatus(true), 1000);
  };

  const showFileList = (content, work) => {
    setEventContent(() => {
      if (work === true) return { ...content, work: true };
      else return content;
    });
    setTimeout(setFileListModalStatus(true), 1000);
  };

  /* ========================================================================= #6 */
  const returnItems = (qualityElementList, index) => {
    let returnArray = [];
    for (let i = 1; i < qualityElementList.length; i++) {
      returnArray.push(
        <tr key={index + '_' + i + '_qualityElement'}>
          {qualityElementList[i] ? (
            <Fragment>
              <td>{checkEmptyNull(qualityElementList[i].elementName, '')}</td>
              <td>{checkEmptyNull(qualityElementList[i].elementCode, '')}</td>
              <td>{checkEmptyNull(qualityElementList[i].baseAmount, 0).toLocaleString()}</td>
              <td>{checkEmptyNull(BigNumber(checkEmptyNull(qualityElementList[i].makeAmount, 0)).plus(BigNumber(checkEmptyNull(qualityElementList[i].failedAmount, 0))).toNumber(), 0).toLocaleString()}</td>
            </Fragment>
          ) : (
            <Fragment>
              <td></td><td></td><td></td><td></td>
            </Fragment>
          )}
        </tr>,
      );
      return returnArray;
    }
  };

  return (
    <Grid3Body contents={
      <>
        <Section className="Main">
          <NavBar
            title={
              <>
                <div className='Title'>
                  <NavTitle menuCode={'415'} />
                  <div className='typeButtons'>
                    {[
                      { status: 'all', name: '전체' },
                      { status: 'waitNwork', name: '대기/작업중' },
                      { status: 'wait', name: '대기' },
                      { status: 'work', name: '작업중' },
                      { status: 'pause', name: '일시정지' },
                      { status: 'stop', name: '중단' },  
                      { status: 'end', name: '완료' },
                      { status: 'cancel', name: '취소' },
                    ].map((workOrderStatus, index) => {
                      return (
                        <button key={index + '_statusButton'}
                          className={costReducer.workOrderStatus === workOrderStatus.status ? 'btn-inven-1' : 'btn-set'}
                          onClick={() => { handleSearchWorkOrderStatus(workOrderStatus.status); }}
                        >
                          {workOrderStatus.name}
                        </button>
                      )
                    })}
                  </div>
                </div>
              </>
            }
            buttons={<></>}
            nav={'search'}
            firstRow={
              <>
                <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <select
                    className="SelectType"
                    value={costReducer.workOrderType}
                    onChange={(e) => {
                      dispatch(costActions_setWorkOrderType(e.target.value))
                      dispatch(costActions_setPageNumber(1));
                      getWorkOrderLogList(0, e.target.value, costReducer.workOrderStatus);
                    }}
                  >
                    <option value="all">유형 전체</option>
                    {_workOrderTypes.map((thisItem) => {
                      return (<option key={thisItem.workOrderTypeId + '_workOrderType'} value={thisItem.workOrderTypeId}>{thisItem.workOrderTypeName}</option>);
                    })}
                  </select>
                  <div className="SearchSection">
                    <input
                      type="date"
                      className="SelectDate"
                      value={costReducer.startDate}
                      onChange={(e) => { dispatch(costActions_setStartDate(e.target.value)); }}
                    />
                    <IconRightArrow />
                    <input
                      type="date"
                      className="SelectDate"
                      value={costReducer.endDate}
                      onChange={(e) => { dispatch(costActions_setEndDate(e.target.value)); }}
                    />
                  </div>
                </div>
              </>
            }
            secondRow={
              <div className="SearchSection">
                <div className="ContentCBox">
                  <select className="SearchOption detail">
                    <option value="workOrderCode">작업코드</option>
                  </select>
                  <input
                    className="SearchBar detail"
                    placeholder="Search..."
                    value={checkEmptyNull(costReducer.searchData.workOrderCode, '')}
                    onInput={(e) => {dispatch(costActions_setSearchData({ ...costReducer.searchData, workOrderCode: e.target.value }));}}
                  />
                </div>

                <div className="ContentCBox">
                  <select className="SearchOption detail">
                    <option value="workOrderName">작업이름</option>
                  </select>
                  <input
                    className="SearchBar detail"
                    placeholder="Search..."
                    value={checkEmptyNull(costReducer.searchData.workOrderName, '')}
                    onInput={(e) => {dispatch(costActions_setSearchData({ ...costReducer.searchData, workOrderName: e.target.value }));}}
                  />
                </div>

                <div className="ContentCBox">
                  <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                  <div className="ResetButton" onClick={actReset}>초기화</div>
                </div>
              </div>
            }
          />

          <TableSection content={
            <table>
              <thead>
                <tr>
                  <th rowSpan={2} style={{ minWidth: '100px', padding: 'unset', width: '100px' }}></th>
                  <th rowSpan={2} style={{ minWidth: '60px', padding: 'unset', width: '60px' }}>작업<br />상태</th>
                  {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? (<th rowSpan={2}>파일</th>) : null}
                  <th rowSpan={2}>작업유형</th>
                  <th rowSpan={2}>작업코드</th>
                  <th rowSpan={2}>작업이름</th>
                  {(_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) ? (<th rowSpan={2}>거래처</th>) : null}
                  <th colSpan={4} style={{ backgroundColor: 'var(--ThirdBlue)' }}>생산목표품목</th>
                  <th rowSpan={2}>총원가</th>
                  <th rowSpan={2}>작업기간</th>
                  <th rowSpan={2}>작성자</th>
                  <th rowSpan={2}>책임자</th>
                  <th rowSpan={2}>작업자</th>
                </tr>
                <tr>
                  <th>물품이름</th>
                  <th>물품코드</th>
                  <th className="resultProductStyle">목표<br />생산량</th>
                  <th className="resultProductStyle" style={{ backgroundColor: 'var(--MainBlue)' }}>총 생산량</th>
                </tr>
              </thead>
              <tbody>
                {!checkNullArray(_workOrderLogList, false) ? LoadingMsg() :
                  checkNullArray(_workOrderLogList, []).map((workOrderLog, index) => {
                    const qualityElementList = checkNullArray(workOrderLog.qualityElementList, []);
                    const _rowLength = qualityElementList.length;
                    return (
                      <Fragment key={index + '_workOrderLog'}>
                        <tr>
                          <QualityButton
                            rowSpan={_rowLength === 0 ? 1 : _rowLength}
                            style={{ minWidth: '100px', padding: 'unset', width: '100px' }}
                            onClick={() => { showProductionProcessTracking(workOrderLog) }}
                          >
                            상세보기
                            <GuideText />
                          </QualityButton>
                          <StatusTdButton
                            rowSpan={_rowLength === 0 ? 1 : _rowLength}
                            statusStyle={{ cursor: 'default', minHeight: '100px', minWidth: '60px', width: '60px' }}
                            content={workOrderLog}
                            contentId={workOrderLog.workOrderLogId}
                            status={workOrderLog.workOrderStatus}
                          />
                          {(() => {
                            if (_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) {
                              if (checkNullArray(workOrderLog.attachFileList, false)) {
                                return (
                                  <td rowSpan={_rowLength === 0 ? 1 : _rowLength} className='table-preview'>
                                    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                                      onClick={(e) => {e.stopPropagation(); showFileList(workOrderLog, true);}}
                                    >
                                      <img src={workOrderLog.attachFileList[0]?.fileData?.fileDataS3URL || ''} alt='image preview' className='img-preview' />
                                      <div className='btn-file'><DOCButtonIcon /></div>
                                    </div>
                                  </td>
                                );
                              } else {
                                return (
                                  <td rowSpan={_rowLength === 0 ? 1 : _rowLength} className='table-preview'>
                                    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                                      onClick={(e) => {e.stopPropagation(); showFileList(workOrderLog, true);}}
                                    >
                                      <div className='no-image'><p>이미지가 없습니다.</p></div>
                                      <div className='btn-file'><DOCButtonIcon /></div>
                                    </div>
                                  </td>
                                );
                              }
                            }
                          })()}
                          <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{workOrderLog.workOrderTypeName}</td>
                          <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{workOrderLog.workOrderCode}</td>
                          <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{workOrderLog.workOrderName}</td>
                          {(_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) ? (
                            <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{checkEmptyNull(workOrderLog.accountName, '')}</td>
                          ) : null}
                          {_rowLength > 0 && checkNullObject(qualityElementList[0], false) ? (
                            <Fragment>
                              <td>{checkEmptyNull(qualityElementList[0].elementName, '')}</td>
                              <td>{checkEmptyNull(qualityElementList[0].elementCode, '')}</td>
                              <td>{checkEmptyNull(qualityElementList[0].baseAmount, 0).toLocaleString()}</td>
                              <td>{checkEmptyNull(BigNumber(checkEmptyNull(qualityElementList[0].makeAmount, 0)).plus(BigNumber(checkEmptyNull(qualityElementList[0].failedAmount, 0))).toNumber(), 0).toLocaleString()}</td>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <td></td><td></td><td></td><td></td>
                            </Fragment>
                          )}
                          <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{workOrderLog.cost.toLocaleString()}</td>
                          <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
                            {DateFormat(checkEmptyNull(workOrderLog.workOrderActualStartDate, ''))}<br />{DateFormat(checkEmptyNull(workOrderLog.workOrderActualEndDate, ''))}
                          </td>
                          <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{workOrderLog.userName}</td>
                          <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
                            {checkNullArray(workOrderLog.managers, []).map((manager, managerIndex) => {return (<p key={index + '_' + managerIndex + '_managers'}>{manager.managerName}</p>);})}
                          </td>
                          <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
                            {checkNullArray(workOrderLog.workers, []).map((worker, workerIndex) => {return (<p key={index + '_' + workerIndex + '_workers'}>{worker.workerName}</p>);})}
                          </td>
                        </tr>
                        {_rowLength > 1 && returnItems(qualityElementList, index)}
                      </Fragment>
                    )
                  })}
              </tbody>
            </table>
          }
            sectionStyle={{ height: 'calc(100% - 66px)' }}
          ></TableSection>
        </Section>

        <PagingComponent
          page={costReducer.pageNumber}
          count={costReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(costActions_setPageNumber(page));
          }}
        />

        {_modalStatus === true ? (
          <Detail
            workOrderLog={_eventWorkOrderLog}
            open={_modalStatus}
            close={() => { setModalStatus(false) }}
          />
        ) : null}

        {_fileListModalStatus === true && _eventcontent.workOrderLogId !== undefined ? (
          <ShowFileList
            type={'workOrderLog'}
            content={_eventcontent}
            open={_fileListModalStatus}
            close={() => { setFileListModalStatus(false) }}
          ></ShowFileList>
        ) : null}
      </>
    }
    />
  );
};

export default Cost;
