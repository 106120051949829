import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { calendarApi } from 'api/apis/calendarApi';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import CreateUriCalendarPlanModal from 'pages/calendar/custom/company/uri/modal/crud/CreateUriCalendarPlanModal';
import UpdateUriCalendarPlanModal from 'pages/calendar/custom/company/uri/modal/crud/UpdateUriCalendarPlanModal';

const Section = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-area: calendar;
  justify-content: flex-start;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;
const TimeTableSection = styled.div`
  position: static;
  height: 100%;
  overflow: scroll;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}
`;
const TimeTable = styled.table`
  background-color: var(--white);
  border-collapse: collapse;
  font-weight: 400;
  height: 100%;
  min-width: 100%;
  /* table-layout: fixed; */
  width: 100%;

  thead {
    background-color: var(--MainNavy);
    color: var(--white);
    left: 0px;
    position: sticky;
    top: 0px;
    z-index: 1;
  }
  tr {border: none;
    &.titleRow {
      td, th {background-color: var(--ThirdBlue);}
    }
  }
  tbody tr {border-bottom: 1px solid var(--MainNavy);}

  td, th {
    box-sizing: border-box;
    padding: 5px 15px;
    max-width: 300px;
    min-width: unset;
    text-align: center;
    white-space: normal;
    word-break: break-all;
  }
  td {
    background-color: var(--Bg); 
    vertical-align: top;
  }
  td.workTypeCell {
    background-color: var(--MainNavy);
    color: var(--white);
    font-weight: 600;
    min-width: 50px;
    width: 50px;
    vertical-align: middle;
  }
  th.btnCell {
    height: 50px;
    position: relative;

    .btn-set {
      height: fit-content;
      left: 10px;
      position: absolute;
      top: 10px;

      &:hover {background-color: var(--Bg);}
    }

    .btnWrap {
      align-items: center;
      display: flex;
      gap: 10px;
      height: fit-content;
      justify-content: center;
      position: absolute;
      right: 10px;
      top: 10px;
      width: fit-content;

      button:hover {background-color: var(--Bg);}
    }
  }
`;
const CalendarContents = styled.div`
  align-items: center;
  display: grid;
  gap: 20px;
  height: fit-content;
  justify-items: center;
  width: 100%;

  & .calendarContent {
    align-items: center;
    background-color: var(--gray-800);
    border-radius: 25px;
    color: var(--white);
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    min-height: 80px;
    overflow: hidden;
    padding: 5px;
    position: relative;
    width: 100%;
    
    &:hover {box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;}
    &.update, &.delete {padding-bottom: 20px;}
    & .calendarData {
      display: grid;
      grid-template-columns: 65% 35%;
      width: 100%;
      & p:first-child {border-right: 1px solid var(--gray-400);}
    }
    & .btn-calendar {
      bottom: 0px;
      color: var(--white);
      cursor: pointer;
      font-size: 0.8em;
      font-weight: 600;
      padding: 5px 0px;
      position: absolute;
      width: 100%;
  
      &.update {background-color: var(--MainBlue);}
      &.delete {background-color: var(--MainRed);}
    }
  }
`;

const CalendarMonitoring = () => {
  let currentWeek = [];
  let currentDay = new Date();
  let year = currentDay.getFullYear();
  let month = currentDay.getMonth();
  let date = currentDay.getDate();
  let dayOfWeek = currentDay.getDay();
  for (let i = 0; i < 7; i++) {
    let resultDay = new Date(year, month, date + (i - dayOfWeek));
    let yyyy = resultDay.getFullYear();
    let mm = Number(resultDay.getMonth()) + 1;
    let dd = resultDay.getDate();
    mm = String(mm).length === 1 ? '0' + mm : mm;
    dd = String(dd).length === 1 ? '0' + dd : dd;
    currentWeek[i] = yyyy + '-' + mm + '-' + dd;
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {monitoringReducer, userReducer } = useSelector((state) => state);

  const [_authority, setAuthority] = useState([]);

  const [_calendarList, setCalendarList] = useState([]);

  let UpdateText = '일정수정';
  let DeleteText = '일정삭제';

  // calender-status
  const [_createCalendarPlan, setCreateCalendarPlan] = useState(false);
  const [_updateCalendarPlan, setUpdateCalendarPlan] = useState(false);
  // calender-buttons
  const [_activeUpdateButton, setActiveUpdateButton] = useState(false);
  const [_activeDelButton, setActiveDelButton] = useState(false);
  // calender-content
  const [_calenderUpdateContent, setcalenderUpdateContent] = useState([]);
  const [_calenderParseContent, setcalenderParseContent] = useState([]);

  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => 
      thisItem.authority.startsWith('406') === true ||
      thisItem.authority.startsWith('701') === true
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    getCalendarList();

    return () => {}
  }, [])

  const getCalendarList = async() => {
    const body={
      companyId: userReducer.company.companyId,
      startDate: currentWeek[1],
      endDate: currentWeek[6]
    }
    await calendarApi.searchCalendar(body).then(response => {
      if(response === undefined) return;
      console.log('calendarApi.searchCalendar : ', response);
      setCalendarList(() => {return response.data})
    })
  }

  const showCalendarWorkerScreen = async (item) => {
    if (!checkNullObject(item, false)) return alert('연관 작업지시가 없습니다.');
    if (!checkEmptyNull(item.workOrderLogId, false)) return alert('연관 작업지시가 없습니다.');
    navigate({
      pathname: '/monitoring/company/uri/working',
      search: `?${createSearchParams({ prevPage: '/monitoring/company/uri', workOrderLogId: item.workOrderLogId })}`,
      replace: true,
    })
  };

  const actCreateCalendarPlan = () => {
    if (_authority.indexOf('406-2') !== -1 || _authority.indexOf('701-3') !== -1) {
      setCreateCalendarPlan(true);
    } else alert('권한이 없습니다.');
  };
  const actUpdateCalendarPlan = () => {
    if (_authority.indexOf('406-2') !== -1 || _authority.indexOf('701-3') !== -1) {
      if (_activeUpdateButton !== true) {
        UpdateText = '수정완료';
        setActiveUpdateButton(true);
      } else {
        UpdateText = '일정 수정';
        setActiveUpdateButton(false);
      }
    } else alert('권한이 없습니다.');
  };
  const updateCalendarPlan = async (item) => {
    if (_authority.indexOf('406-2') !== -1 || _authority.indexOf('701-3') !== -1) {
      if (item === undefined || item === null) return;
      const setCalenderData = JSON.parse(item.note);
      setcalenderParseContent(setCalenderData);
      setcalenderUpdateContent(item);
      setUpdateCalendarPlan(true);
    } else alert('권한이 없습니다.');
  };
  const actDeleteCalendarPlan = () => {
    if (_authority.indexOf('406-2') !== -1 || _authority.indexOf('701-3') !== -1) {
      if (_activeDelButton !== true) {
        DeleteText = '삭제완료';
        setActiveDelButton(true);
      } else {
        DeleteText = '일정 삭제';
        setActiveDelButton(false);
      }
    } else alert('권한이 없습니다.');
  };
  const deleteCalendarPlan = async (item) => {
    if (_authority.indexOf('406-2') !== -1 || _authority.indexOf('701-3') !== -1) {
      if (item === undefined || item === null) return;
      const confirmText = `일정을 삭제 하시겠습니까?`;
      if (window.confirm(confirmText) === true) {
        await calendarApi.deleteCalendar(item.calenderId).then((response) => {
          if (response === undefined) return;
          alert('일정을 삭제 했습니다.');
          getCalendarList();
        });
      }
    } else alert('권한이 없습니다.');
  };

  return (
    <>
      <Section>
        <TimeTableSection>
          <TimeTable>
            <colgroup>
              <col width={'50px'} />
              <col width={'16%'} />
              <col width={'16%'} />
              <col width={'16%'} />
              <col width={'16%'} />
              <col width={'16%'} />
              <col width={'16%'} />
            </colgroup>
            <thead>
              <tr className='titleRow'>
                <th colSpan={7} className='btnCell'>
                  <button className='btn-set' onClick={(e) => {e.preventDefault(); getCalendarList();}}>일정 새로고침</button>
                  일일일정표
                  <div className='btnWrap'>
                    <button className='btn-add' onClick={(e) => {e.preventDefault(); actCreateCalendarPlan()}}>일정추가</button>
                    <button className='btn-edit' onClick={(e) => {e.preventDefault(); actUpdateCalendarPlan()}}>일정수정</button>
                    <button className='btn-delete' onClick={(e) => {e.preventDefault(); actDeleteCalendarPlan()}}>일정삭제</button>
                  </div>
                </th>
              </tr>
              <tr>
                <th style={{ minWidth: '50px', width: '50px' }}></th>
                {['월', '화', '수', '목', '금', '토'].map((day, index) => {
                  return (<th key={day + '_calendarTable_day'}>{day} <br /> {currentWeek[index + 1]}</th>);
                })}
              </tr>
            </thead>
            <tbody>
              {['작업', '유리', '출하'].map((workType, index) => {
                let eventType = '';
                if (workType === '작업') eventType = 'Work';
                if (workType === '유리') eventType = 'Glass';
                if (workType === '출하') eventType = 'Out';
                return (
                  <tr key={index + '_calendarTable'} style={{ borderBottom: '2px solid var(--MainNavy)' }}>
                    <td className='workTypeCell'>{workType}</td>
                    {(() => {
                      const returnArray = [];
                      for (let i = 1; i <= 6; i++) {
                        returnArray.push(
                          <td key={i + '_calendarTable_' + eventType}>
                            {(() => {
                              const returnCalenderList = [];
                              checkNullArray(_calendarList, []).forEach((calendarData, index) => {
                                const calenderDate = DateFormat(checkEmptyNull(calendarData.calenderDate, ''));
                                const noteData = checkNullParse(calendarData.note, {
                                  type: '',
                                  note1: '',
                                  note2: '',
                                  note3: '',
                                })
                                if (calenderDate === currentWeek[i] && noteData.type === eventType) {
                                  returnCalenderList.push(
                                    <div key={calenderDate + '_calendar_' + index} className={'calendarContent ' + (_activeUpdateButton ? 'update' : _activeDelButton ? 'delete' : '')}
                                      data-key={calendarData.calenderId}
                                      data-workorderkey={checkEmptyNull(calendarData.workOrderLogId, noteData.note3)}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        showCalendarWorkerScreen(calendarData);
                                      }}
                                    >
                                      <div className='calendarData'>
                                        <p>{noteData?.note1}</p>
                                        <p>{noteData?.note2}</p>
                                      </div>
                                      {_activeUpdateButton ? (<div className='btn-calendar update' onClick={(e) => { e.stopPropagation(); updateCalendarPlan(calendarData); }}>수정</div>) : null}
                                      {_activeDelButton ? (<div className='btn-calendar delete' onClick={(e) => { e.stopPropagation(); deleteCalendarPlan(calendarData); }}>삭제</div>) : null}
                                    </div>
                                  )
                                }
                              })
                              if (checkNullArray(returnCalenderList, false)) return (<CalendarContents>{returnCalenderList}</CalendarContents>)
                              else return null;
                            })()}
                          </td>
                        );
                      }
                      return returnArray;
                    })()}
                  </tr>
                );
              })}
            </tbody>
          </TimeTable>
        </TimeTableSection>
      </Section>

      {/* 모달 */}
      {/* 일정 추가 */}
      {_createCalendarPlan === true ? (
        <CreateUriCalendarPlanModal
          open={_createCalendarPlan}
          close={() => { setCreateCalendarPlan(false); getCalendarList(); }}
        />
      ) : null}
      {/* 일정 수정 */}
      {_updateCalendarPlan === true ? (
        <UpdateUriCalendarPlanModal
          open={_updateCalendarPlan}
          content={_calenderUpdateContent}
          close={() => { setUpdateCalendarPlan(false); getCalendarList(); }}
        />
      ) : null}
    </>
  );
};

export default CalendarMonitoring;