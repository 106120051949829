import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { getCookie } from 'preferences/cookie/cookie';
import { WS_CLIENT } from 'preferences/server/constants';

import { checkNullArray } from 'components/checkValues/checkValues';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import StatusButton from 'components/buttons/StatusButton';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 250px;
  justify-content: flex-start;
  max-width: 1100px;
  min-width: 200px;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100% - 55px);
  justify-content: center;
  padding: 20px;
  row-gap: 20px;
  width: 100%;
`;

const StatusSection = styled.div`
  align-items: center;
  box-sizing: border-box;
  column-gap: 10px;
  display: flex;
  height: 160px;
  justify-content: center;
  width: 100%;
`;

const GSWorkOrderAllStatusModal = (props) => {
  /* ====================================================================== #1 */
  const { userReducer, workReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const { workOrderLogId, workOrderStatus, eventProcesses, statusEvent } = props;

  /* ====================================================================== #3 */
  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const actStatusEvent = (e) => {
    const { workordercode, status } = e.currentTarget.dataset;

    const BodyToWS = {
      roomId: userReducer.company.companyId,
      type: '/processes',
      workOrderLogId: workOrderLogId,
      processLogIds: eventProcesses,
      processStatus: status,
    };
    console.log('client.publish/pub/processes - body : ', JSON.stringify(BodyToWS));
    if (workReducer.connectStatus !== 'connect' || !checkNullArray(eventProcesses, false)) return;

    try {
      WS_CLIENT.publish({
        destination: '/pub/processes',
        headers: {
          'auth-token':
            getCookie('accessToken') &&
            getCookie('accessToken').replace('Bearer ', ''),
        },
        body: JSON.stringify(BodyToWS),
      });
    } catch (error) {
      console.log('client.publish/pub/processes - error : ', error);
    }

    statusEvent();
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection status={workOrderStatus}>
            <ModalHeader>
              <ModalTitle>일괄 상태 변경</ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              <StatusSection>
                <StatusButton
                  workOrderCode={workOrderLogId}
                  status={'wait'}
                  statusEvent={actStatusEvent}
                  statusStyle={{ fontSize: '30px' }}
                ></StatusButton>
                <StatusButton
                  workOrderCode={workOrderLogId}
                  status={'work'}
                  modal={true}
                  statusEvent={actStatusEvent}
                  statusStyle={{ fontSize: '30px' }}
                ></StatusButton>
                <StatusButton
                  workOrderCode={workOrderLogId}
                  status={'pause'}
                  statusEvent={actStatusEvent}
                  statusStyle={{ fontSize: '30px' }}
                ></StatusButton>
                <StatusButton
                  workOrderCode={workOrderLogId}
                  status={'stop'}
                  statusEvent={actStatusEvent}
                  statusStyle={{ fontSize: '30px' }}
                ></StatusButton>
                <StatusButton
                  workOrderCode={workOrderLogId}
                  status={'end'}
                  statusEvent={actStatusEvent}
                  statusStyle={{ cursor: 'pointer', fontSize: '30px' }}
                ></StatusButton>
                <StatusButton
                  workOrderCode={workOrderLogId}
                  status={'cancel'}
                  statusEvent={actStatusEvent}
                  statusStyle={{
                    backgroundColor: 'var(--white)',
                    color: 'var(--MainRed)',
                    cursor: 'pointer',
                    fontSize: '30px',
                    outline: '8px solid var(--MainRed)',
                    outlineOffset: '-12px',
                  }}
                ></StatusButton>
              </StatusSection>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default GSWorkOrderAllStatusModal;
