import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { getCookie } from 'preferences/cookie/cookie';
import {
  ACCESS_TOKEN,
  AXIOS_BASE_HEADERS,
  AXIOS_BASE_URL,
} from 'preferences/server/constants';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import Nav from 'components/nav/Nav';

const DeleteGSProcurement = () => {
  /* ===================================================#======================================================*/
  const navigate = useNavigate();
  const { gsReducer, pageReducer, userReducer } = useSelector((state) => state);
  console.log('gsReducer : ', gsReducer.deleteOrder);

  /* =================================================== #2 ===================================================*/

  /* =================================================== #3 =================================================== */

  /* =================================================== #4들 =================================================== */

  /* =================================================== #5들 =================================================== */
  const actDelOrder = async () => {
    await axios
      .delete(
        AXIOS_BASE_URL + '/procurement/' + gsReducer.deleteOrder.procurementId,
        AXIOS_BASE_HEADERS,
      )
      .then((response) => {
        if (response === undefined) return;
        console.log('delete/procurement - response : ', response);
        if (response.status === 200) {
          alert('발주를 삭제했습니다.');
          navigate(pageReducer.currentPage, { replace: true });
        }
      })
      .catch((error) => {
        console.log('delete/procurement - error : ', error);
      });
  };
  const actAllDelete = () => {
    let delCount = 0;
    gsReducer.deleteOrder.forEach(async (thisItem) => {
      await axios
        .delete(AXIOS_BASE_URL + '/procurement/' + thisItem.procurementId, {
          headers: {
            Authorization: getCookie('accessToken'),
          },
        })
        .then((response) => {
          if (response === undefined) return;
          console.log('delete/procurement - response : ', response);
          if (response.status === 200) {
            delCount++;
            if (delCount >= gsReducer.deleteOrder.length) {
              alert('발주를 삭제했습니다.');
              navigate(pageReducer.currentPage, { replace: true });
            }
          }
        })
        .catch((error) => {
          console.log('delete/procurement - error : ', error);
        });
    });
  };

  /* =================================================== #6들 =================================================== */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body
      contents={
        <>
          <main className="Main">
            <Nav
              thisTitle={'발주관리'}
              content={''}
              nav={''}
              search={''}
              searchButton={''}
              searchOptions={''}
              statusOptions={''}
            />

            <DeleteForm
              delteTitle={'발주'}
              deleteItem={
                <>
                  {userReducer.company.companyId === 1 ? (
                    <>
                      {gsReducer.deleteOrder.map((thisItem) => {
                        return (
                          <div
                            key={thisItem.procurementId + '_delProcurement'}
                            style={{ marginBottom: '10px' }}
                          >
                            {'발주코드 : ' + thisItem.procurementCode} <br />
                            {'발주명 : ' + thisItem.procurementName} <br />
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {'발주코드 : ' + gsReducer.deleteOrder.procurementCode}{' '}
                      <br />
                      {'발주명 : ' + gsReducer.deleteOrder.procurementName}{' '}
                      <br />
                    </>
                  )}
                </>
              }
              deleteText={
                <>
                  해당 <span className="cautionText">모든 발주 내용</span>이
                  삭제됩니다.
                </>
              }
              deleteButton={
                <>
                  <button
                    className="formButton cancle"
                    onClick={() => {
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                  <button
                    className="formButton delete"
                    onClick={
                      userReducer.company.companyId === 1
                        ? actAllDelete
                        : actDelOrder
                    }
                  >
                    삭제
                  </button>
                </>
              }
            />
          </main>
        </>
      }
    />
  );
};

export default DeleteGSProcurement;
