import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

import { qualityApi } from 'api/apis/qualityApi';
import { workOrderTypeApi } from 'api/apis/workOrderTypeApi';

import { checkNull } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import { StatusName } from 'components/status/StatusName';
import IconRightArrow from 'components/icons/IconRightArrow';

const QualityModal = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 6px;
  }
`;

const MainContents = styled.div`
  box-sizing: border-box;
  /* height: 100%; */
  margin-bottom: 20px;
  padding: 5px 20px;
  width: 100%;
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ModalButton = styled.div`
  align-items: center;
  background-color: var(--ThirdGreen);
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 400;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const StatusSelect = styled.select`
  border: 1px solid var(--ThirdGreen);
  border-radius: 5px;
  box-sizing: border-box;
  height: fit-content;
  margin-top: 5px;
  padding: 10px;
  width: 100%;
`;

const DateContents = styled.div`
  align-items: center;
  column-gap: 10px;
  display: grid;
  grid-template-columns: 1fr 15px 1fr;
  width: 100%;
`;

const SearchContents = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 150px calc(100% - 160px);
  margin-top: 5px;
  width: 100%;

  &.detail {
    grid-template-columns: 150px calc(100% - 210px) 50px;
  }

  select {
    border: 1px solid var(--ThirdGreen);
    border-radius: 5px;
    box-sizing: border-box;
    height: fit-content;
    padding: 10px;
    width: 100%;

    &.detail {
      appearance: none;
      --webkit-appearance: none;

      background-color: var(--ThirdGreen);
      color: var(--white);
    }
  }

  input {
    border: 1px solid var(--ThirdGreen);
    border-radius: 5px;
    box-sizing: border-box;
    height: fit-content;
    /* outline: var(--ThirdGreen); */
    padding: 10px;
    width: 100%;

    &:focus {
      border: 1px solid var(--ThirdGreen);
    }
  }
`;

const SelectDate = styled.input`
  border: 1px solid var(--gray-200);
  border-radius: 5px;
  box-sizing: border-box;
  height: fit-content;
  margin-top: 5px;
  min-width: 250px;
  padding: 10px;
  width: 100%;

  border: 1px solid var(--ThirdGreen);
  &:focus {
    border: 1px solid var(--ThirdGreen);
  }
`;

const QualityDownloadModal = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_workOrderTypes, setWorkOrderTypes] = useState([]);
  const [_workOrderType, setWorkOrderType] = useState('all');

  const [_startDate, setStartDate] = useState('');
  const [_endDate, setEndDate] = useState('');

  const [_searchOption, setSearchOption] = useState('');
  const [_searchText, setSearchText] = useState('');

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter(
      (thisItem) =>
        thisItem.authority.startsWith('104') === true || // 거래처
        thisItem.authority.startsWith('109') === true || // 파일
        thisItem.authority.startsWith('601') === true,
    );
    const authorityCodes = authorityContent.map(
      (thisItem) => thisItem.authority,
    );
    setAuthority(authorityCodes);

    getWorkOrderTypes();

    setOnload('loaded');

    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const getWorkOrderTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await workOrderTypeApi.searchWorkOrderType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderTypeApi.searchWorkOrderType : ', response);

      setWorkOrderTypes(() => {
        return response.data;
        // return response.data.content;
      });
    });
  };

  const actDownload = async () => {
    const paging = `?page=0&size=7000&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      workOrderStatus: ['end'],
    };

    if (
      _workOrderType !== undefined &&
      _workOrderType !== null &&
      _workOrderType !== 'all'
    ) {
      BodyToPost.workOrderTypeId = Number(_workOrderType);
    }

    if (_startDate !== '' && _endDate !== '') {
      BodyToPost.actualStartDate = DateFormat(_startDate);
      BodyToPost.actualEndDate = DateFormat(_endDate);
      if (_startDate > _endDate) {
        BodyToPost.actualStartDate = DateFormat(_endDate);
        BodyToPost.actualEndDate = DateFormat(_startDate);
      }
    }

    if (
      _searchOption !== undefined &&
      _searchOption !== null &&
      _searchOption !== '' &&
      _searchText !== undefined &&
      _searchText !== null &&
      _searchText !== ''
    ) {
      BodyToPost[_searchOption] = _searchText;
    }
    console.log('getWorkOrderLogList - BodyToPost : ', BodyToPost);

    searchAllQuality(paging, BodyToPost);
  };
  const searchAllQuality = async (paging, BodyToPost) => {
    await qualityApi
      .searchQualityWorkOrderLog(paging, BodyToPost)
      .then((response) => {
        if (response === undefined) return;
        console.log('qualityApi.searchQualityWorkOrderLog : ', response);

        setExcelData_quality(response.data.content);
      });
  };
  const setExcelData_quality = async (Result) => {
    const qualityList = Result;
    console.log(qualityList);
    const qualityElements = qualityList.map((thisItem) =>
      thisItem.qualityElementList.length > 0
        ? thisItem.qualityElementList.length
        : 1,
    );
    const qualityRow = qualityElements.reduce((prev, cur) => {
      return prev + cur;
    }, 0);
    const excelList = [];
    try {
      for (let i = 0; i < qualityList.length; i++) {
        const managers =
          qualityList[i].managers !== undefined
            ? qualityList[i].managers.map((worker) => worker.managerName)
            : [];
        const workers =
          qualityList[i].worekrs !== undefined
            ? qualityList[i].workers.map((worker) => worker.workerName)
            : [];

        if (qualityList[i].qualityElementList[0] !== undefined) {
          const pushData = {
            작업상태: StatusName(qualityList[i].workOrderStatus) || '',
            작업유형: checkNull(qualityList[i].workOrderTypeName, ''),
            작업코드: checkNull(qualityList[i].workOrderCode, ''),
            작업이름: checkNull(qualityList[i].workOrderName, ''),
            거래처: checkNull(qualityList[i].accountName, ''),
            생산목표품목_물품이름: checkNull(qualityList[i].qualityElementList[0].elementName, ''),
            생산목표품목_물품코드: checkNull(qualityList[i].qualityElementList[0].elementCode, ''),
            생산목표품목_목표생산량: checkNull(qualityList[i].qualityElementList[0].baseAmount, false) ?
              qualityList[i].qualityElementList[0].baseAmount.toLocaleString() : '',
            생산목표품목_총생산량: BigNumber(checkNull(qualityList[i].qualityElementList[0].makeAmount, 0)).plus(BigNumber(checkNull(qualityList[i].qualityElementList[0].failedAmount, 0))).toNumber().toLocaleString(),
            생산목표품목_성공수량: checkNull(qualityList[i].qualityElementList[0].makeAmount, false) ?
              qualityList[i].qualityElementList[0].makeAmount.toLocaleString() : '',
            생산목표품목_실패수량: checkNull(qualityList[i].qualityElementList[0].failedAmount, false) ?
              qualityList[i].qualityElementList[0].failedAmount.toLocaleString() : '',
            작업기간_시작: checkNull(qualityList[i].workOrderActualStartDate, false) ?
              DateFormat(qualityList[i].workOrderActualStartDate) : '',
            작업기간_끝: checkNull(qualityList[i].workOrderActualEndDate, false) ?
              DateFormat(qualityList[i].workOrderActualEndDate) : '',
            작성자: checkNull(qualityList[i].userName, ''),
            책임자: managers.join('/'),
            작업자: workers.join('/'),
          };
          if (
            _authority.indexOf('109-1') === -1 &&
            _authority.indexOf('109-2') === -1
          ) {
            delete pushData['거래처'];
          }

          excelList.push(pushData);
        } else {
          const pushData = {
            작업상태: StatusName(qualityList[i].workOrderStatus) || '',
            작업유형: checkNull(qualityList[i].workOrderTypeName, ''),
            작업코드: checkNull(qualityList[i].workOrderCode, ''),
            작업이름: checkNull(qualityList[i].workOrderName, ''),
            거래처: checkNull(qualityList[i].accountName, ''),
            생산목표품목_물품이름: '',
            생산목표품목_물품코드: '',
            생산목표품목_목표생산량: '',
            생산목표품목_총생산량: '',
            생산목표품목_성공수량: '',
            생산목표품목_실패수량: '',
            작업기간_시작: checkNull(qualityList[i].workOrderActualStartDate, false) ?
              DateFormat(qualityList[i].workOrderActualStartDate) : '',
            작업기간_끝: checkNull(qualityList[i].workOrderActualEndDate, false) ?
              DateFormat(qualityList[i].workOrderActualEndDate) : '',
            작성자: checkNull(qualityList[i].userName, ''),
            책임자: managers.join('/'),
            작업자: workers.join('/'),
          };

          if (
            _authority.indexOf('109-1') === -1 &&
            _authority.indexOf('109-2') === -1
          ) {
            delete pushData['거래처'];
          }

          excelList.push(pushData);
        }

        for (let j = 1; j < qualityList[i].qualityElementList.length; j++) {
          excelList.push({
            생산목표품목_물품이름: checkNull(qualityList[i].qualityElementList[j].elementName, ''),
            생산목표품목_물품코드: checkNull(qualityList[i].qualityElementList[j].elementCode, ''),
            생산목표품목_목표생산량: checkNull(qualityList[i].qualityElementList[j].baseAmount, false) ?
              qualityList[i].qualityElementList[j].baseAmount.toLocaleString() : '',
            생산목표품목_총생산량: BigNumber(checkNull(qualityList[i].qualityElementList[j].makeAmount, 0)).plus(BigNumber(checkNull(qualityList[i].qualityElementList[j].failedAmount, 0))).toNumber().toLocaleString(),
            생산목표품목_성공수량: checkNull(qualityList[i].qualityElementList[j].makeAmount, false) ?
              qualityList[i].qualityElementList[j].makeAmount.toLocaleString() : '',
            생산목표품목_실패수량: checkNull(qualityList[i].qualityElementList[j].failedAmount, false) ?
              qualityList[i].qualityElementList[j].failedAmount.toLocaleString() : '',
          });
        }
      }
    } finally {
      console.log('excelList : ', excelList);
      if (excelList.length === qualityRow) {
        // json형식인 데이터를 시트형식으로 바꿔서 ws변수에 담는다.
        const ws = XLSX.utils.json_to_sheet(excelList);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, '품질관리목록');
        XLSX.writeFile(wb, '품질관리목록.xlsx');

        props.close();
      }
    }
  };

  /* ====================================================================== #5 */
  /* ====================================================================== #6 */

  return (
    <>
      <QualityModal>
        <MainContents>
          <div>작업유형</div>
          <StatusSelect
            className="SelectType"
            value={_workOrderType}
            onChange={(e) => {
              setWorkOrderType(() => {
                return e.target.value;
              });
            }}
          >
            <option value="all">전체</option>
            {_workOrderTypes.map((thisItem) => {
              return (
                <option
                  key={thisItem.workOrderTypeId + '_workOrderType'}
                  value={thisItem.workOrderTypeId}
                >
                  {thisItem.workOrderTypeName}
                </option>
              );
            })}
          </StatusSelect>
        </MainContents>
        <MainContents>
          <div>날짜</div>
          <DateContents>
            <SelectDate
              type="date"
              className="SelectDate"
              value={_startDate}
              onChange={(e) => {
                setStartDate(() => {
                  return e.target.value;
                });
              }}
            />

            <IconRightArrow />

            <SelectDate
              type="date"
              className="SelectDate"
              value={_endDate}
              onChange={(e) => {
                setEndDate(() => {
                  return e.target.value;
                });
              }}
            />
          </DateContents>
        </MainContents>

        <MainContents>
          <div>검색</div>
          <SearchContents>
            <select
              className="SearchOption"
              value={_searchOption}
              onChange={(e) => {
                setSearchOption(() => {
                  return e.target.value;
                });
              }}
            >
              <option value="">검색옵션</option>
              <option value={'workOrderCode'}>작업코드</option>
              <option value={'workOrderName'}>작업이름</option>
            </select>
            <input
              className="SearchBar"
              placeholder="Search..."
              value={_searchText}
              onInput={(e) => {
                setSearchText(() => {
                  return e.target.value;
                });
              }}
            />
          </SearchContents>
        </MainContents>
      </QualityModal>

      <ModalFooter>
        <ModalButton onClick={actDownload}>다운로드</ModalButton>
      </ModalFooter>
    </>
  );
};

export default QualityDownloadModal;
