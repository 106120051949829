import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { equipmentAttributeApi } from 'api/apis/equipmentAttributeApi';
import { equipmentApi } from 'api/apis/equipmentApi';

import { checkEmptyNull, checkNullArray, checkNullParse } from 'components/checkValues/checkValues';

import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';

const UpdateEquipment = () => {
  /* ========================================================================= #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [searchParams, setSearchParams] = useSearchParams();
  const updateContent = checkNullParse(searchParams.get('updateContent'), {});

  const [_onload, setOnload] = useState('unload');

  const [_equipmentAttributeList, setEquipmentAttributeList] = useState([]);
  const equipmentAttrValueList = {};
  if (checkNullArray(updateContent?.equipmentAttributeValueList, false)) {
    updateContent?.equipmentAttributeValueList.forEach(attr => {
      equipmentAttrValueList[attr.equipmentAttribute.equipmentAttributeId] = attr.value;
    })
  }
  const [_equipmentAttrValueList, setEquipmentAttrValueList] = useState(equipmentAttrValueList);

  const [_formData, setFormData] = useState({
    ...updateContent,
    companyId: userReducer.company.companyId,
    deleteEquipmentLogList: [],
    deleteEquipmentAttributeValueList: [],
  });

  /* ========================================================================= #3 */
  useEffect(() => {
    getEquipmentAttributes();
    setOnload('loaded');
    return () => { };
  }, []);

  /* ========================================================================= #4 */
  const getEquipmentAttributes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId
    }
    await equipmentAttributeApi.searchEquipementAttribute(BodyToPost).then(response => {
      if (response === undefined) return;
      setEquipmentAttributeList(() => { return response.data });
    })
  }

  /* ========================================================================= #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value } });
  };

  const handleAttributeValue = (e, attr, attrValue) => {
    const { value } = e.target;
    setEquipmentAttrValueList((prev) => { return { ...prev, [attr.equipmentAttributeId]: value }; });
  };

  const actUpdate = async (e) => {
    e.preventDefault();

    if (!checkEmptyNull(_formData.equipmentName, false)) return alert('설비이름을 입력해주세요.');

    const mapEquipmentAttrValueList = Object.keys(_equipmentAttrValueList).map(key => {
      const returnData = {
        equipmentAttributeId: Number(key),
        value: _equipmentAttrValueList[key]
      }
      const checkIndex = updateContent?.equipmentAttributeValueList.findIndex((item) => item.equipmentAttribute.equipmentAttributeId === Number(key));
      if (checkIndex !== -1) {
        const checkedItem = updateContent?.equipmentAttributeValueList[checkIndex];
        returnData['equipmentAttributeValueId'] = checkedItem.equipmentAttributeValueId;
      }
      return returnData;
    })
    const BodyToPut = {
      ..._formData,
      equipmentAttributeValueList: mapEquipmentAttrValueList
    };
    console.log('BodyToPut : ', BodyToPut);

    await equipmentApi.updateEquipment(BodyToPut.equipmentId, BodyToPut).then(response => {
      if (response === undefined) return;
      console.log('equipmentApi.updateEquipment : ', response);
      alert('설비를 수정했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    })

  };

  /* ========================================================================= #6 */

  return (
    <Grid2Body contents={
      <>
        <main className="Main">
          <NavBar title={'설비설정'} nav={''} />

          <Form
            title={<>설비<br />수정</>}
            buttons={
              <>
                <button className="formButton" onClick={actUpdate}>저장</button>
                <button className="formButton cancle"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(pageReducer.currentPage, { replace: true });
                  }}
                >
                  취소
                </button>
              </>
            }
            forms={
              <>
                <div className="formBox">
                  <h4>설비이름</h4>
                  <input
                    type="text"
                    name="equipmentName"
                    data-required="required"
                    placeholder="* 설비이름.."
                    value={_formData.equipmentName}
                    onInput={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>설비코드</h4>
                  <input
                    type="text"
                    name="equipmentCode"
                    placeholder="설비코드..."
                    value={_formData.equipmentCode}
                    onInput={handleInputEvent}
                  />
                </div>

                {checkNullArray(_equipmentAttributeList, []).map((attr, index) => {
                  const equipmentAttrValueList = { ..._equipmentAttrValueList };
                  const attrIndex = Object.keys(equipmentAttrValueList).findIndex(attrId => Number(attrId) === Number(attr.equipmentAttributeId))
                  if (attrIndex !== -1) {
                    const attrValue = _equipmentAttrValueList[attr.equipmentAttributeId];
                    return (
                      <div key={index + '_attrInputs'} className="formBox">
                        <h4>{attr.equipmentAttributeName}</h4>
                        <input
                          type="text"
                          placeholder={attr.equipmentAttributeName + '...'}
                          value={attrValue}
                          onInput={(e) => { handleAttributeValue(e, attr, attrValue) }}
                        />
                      </div>
                    )
                  } else {
                    return (
                      <div key={index + '_attrInputs'} className="formBox">
                        <h4>{attr.equipmentAttributeName}</h4>
                        <input
                          type="text"
                          placeholder={attr.equipmentAttributeName + '...'}
                          defaultValue=''
                          onInput={(e) => { handleAttributeValue(e, attr) }}
                        />
                      </div>
                    )
                  }
                })}
              </>
            }
          />
        </main>
      </>
    }
    />
  );
};

export default UpdateEquipment;
