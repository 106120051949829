import React from 'react';

const UpdateWorker = () => {
  return (
    <div>

    </div>
  );
};

export default UpdateWorker;