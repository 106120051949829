export const ColorPalette = [
  { name: '토마토', hex: '#D50000' },
  { name: '연분홍', hex: '#E67C73' },
  { name: '귤', hex: '#F4511E' },
  { name: '바나나', hex: '#F6BF26' },
  { name: '세이지', hex: '#33B679' },
  { name: '바질', hex: '#0B8043' },
  { name: '공작', hex: '#039BE5' },
  { name: '블루베리', hex: '#3F51B5' },
  { name: '라벤더', hex: '#7986CB' },
  { name: '포도', hex: '#8E24AA' },
  { name: '흑연', hex: '#616161' },


  // { name: 'white', hex: '#fff' },
  // { name: 'black', hex: '#000' },
  // { name: 'navy', hex: '#232ea3' },
  // { name: 'blue', hex: '#1c4efc' }, // default
  // { name: 'royal-blue', hex: '#0074e5' },
  // { name: 'sky-blue', hex: '#27c1f8' },
  // { name: 'aqua', hex: '#15dfdf' },
  // { name: 'teal', hex: '#0b9b9b' },
  // { name: 'green', hex: '#008000' },
  // { name: 'lime-green', hex: '#32cd32' },
  // { name: 'lime', hex: '#a1f524' },
  // { name: 'olive', hex: '#999900' },
  // { name: 'yellow', hex: '#ffd400' },
  // { name: 'gold', hex: '#e7af22' },
  // { name: 'saddle-brown', hex: '#a05107' },
  // { name: 'brown', hex: '#a83a24' },
  // { name: 'dark-red', hex: '#b90910' },
  // { name: 'red', hex: '#ee0000' },
  // { name: 'orange', hex: '#ff4500' },
  // { name: 'coral', hex: '#fe8052' },
  // { name: 'salmon', hex: '#fe7565' },
  // { name: 'hot-pink', hex: '#ff6fce' },
  // { name: 'pink', hex: '#ff1493' },
  // { name: 'fuchsia', hex: '#df19df' },
  // { name: 'purple', hex: '#6b0080' },
  // { name: 'blue-violet', hex: '#5917b8' },
];