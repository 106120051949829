import TableSection from 'components/layouts/table/TableSection';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const TableStyle = styled.table`
  white-space: nowrap;
  overflow: auto;
`

const HistoricalDataTable = ({ toggle, data }) => {
  const [title, setTitle] = useState("")

  useEffect(() => {
    // toggle 값에 따라 title을 설정
    if (toggle === "day") {
      setTitle("일 별");
    } else if (toggle === "month") {
      setTitle("월 별");
    } else if (toggle === "year") {
      setTitle("연 별");
    }
  }, [toggle]);

  return (
      <TableSection content={
        <div>
          <h2>{title}</h2>
          <TableStyle>
            <thead>
              <tr>
                <th>
                  {toggle === "year" && "년"}
                  {toggle === "month" && "월"}
                  {toggle === "day" && "일"}
                </th>
                <th>전력량</th>
              </tr>
            </thead>
            <tbody>
                {data.map((item, index) => (
                  <tr key={index + '_power'}>
                    <td>
                      {toggle === "year" && item.date.substring(0, 4)} {/* 년도만 표시 */}
                      {toggle === "month" && item.date.substring(5, 7)} {/* 월만 표시 */}
                      {toggle === "day" && item.date.substring(8, 10)} {/* 일만 표시 */}
                    </td>
                    <td>{item.value}</td>
                  </tr>
                ))}
            </tbody>
          </TableStyle>
        </div>
      }
      ></TableSection>
  );
}

export default HistoricalDataTable;
