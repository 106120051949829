import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { OperatingMenuComponent, OperatingSideBarComponent } from '../styles/sidebar.style';

const OperatingSideBar = ({ path }) => {
  const location = useLocation();
  const operatingId = location.pathname.split('/platform/operating')[0];
  const operatingPath = operatingId + '/platform/operating';

  const menuList = path.includes('mypage') ? [
    { title: '마이 페이지', link: `${operatingPath}/mypage` },
  ] : [
    { title: 'KPI 모니터링', link: `${operatingPath}/monitoring` },
    { title: '작업 현황', link: `${operatingPath}/productionStatus` },
    { title: '공정 트래킹', link: `${operatingPath}/processTracking` }
  ];

  useEffect(() => { }, [location.pathname]);

  return (
    <OperatingSideBarComponent>
      <Link to={operatingPath} className='platformTitle'>
        <h1>운영 플랫폼</h1>
      </Link>
      {menuList.map((menu, index) => {
        return (
          <OperatingMenuComponent key={index + '_PMMenu'} to={menu.link}
            className={location.pathname === menu.link ? 'active' : ''}
          >
            {menu.title}
          </OperatingMenuComponent>
        )
      })}
    </OperatingSideBarComponent>
  );
};

export default OperatingSideBar;