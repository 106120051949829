import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

import { procurementApi } from 'api/apis/procurementApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import SelectElements from 'pages/element/modal/SelectElements';
import GsSelectElements from 'pages/element/modal/GsSelectElements';
import SelectOneAccount from 'pages/account/modal/SelectOneAccount';
import TableSection from 'components/layouts/table/TableSection';

const ProcurementDate = styled.input`
  border: 1px solid var(--gray-200);
  border-radius: 5px;
  box-sizing: border-box;
  height: 40px;
  padding: 5px;
  &:focus {border: 1px solid var(--gray-200);}
`;

const FileTableSection = styled.div`
  cursor: pointer;
  width: 100%;
  &:hover {opacity: 0.6;}
  & > .TableSection {
    max-height: 200px;
    overflow: auto;
    td, th {min-width: unset;}
    &::-webkit-scrollbar-thumb {background-color: var(--ThirdBlue);}
  }
`;
const FileLabelStyle = styled.label`
  align-items: center;
  background-color: var(--MainBlue);
  border-radius: 5px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const CreateGSProcurement = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_formData, setFormData] = useState({
    companyId: userReducer.user.companyId,
    userId: userReducer.user.id, // 작성자

    accountId: '',

    procurementStatus: 'waiting',
    procurementCode: '',
    procurementName: '',

    procurementDate: DateFormat(new Date()),
    scheduledEndDate: '',

    procurementPrice: 0,

    customizedContent: JSON.stringify({}),

    procurementElementList: [],
    deleteProcurementElementList: [],
  });

  const [_modalStatus, setModalStatus] = useState(false); // 물품 선택 모달

  /* 엑셀 */
  const [_fileProcurement, setFileProcurement] = useState([]);
  const [_selectType, setSelectType] = useState();

  /* 거래처 */
  const [_account, setAccount] = useState();
  const [_accountModalStatus, setAccountModalStatus] = useState(false); // 거래처 선택 모달

  /* ====================================================================== #3 */
  useEffect(() => {
    setOnload('loaded');
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData(prev => { return { ...prev, [name]: value } });
  };

  // 엑셀
  const excelExportCommon = (e, callback) => {
    const fileList = e.target.files;
    const file = fileList[0];
    console.log(file);
    if (file === undefined) return;

    document.getElementById('procurementFileTable').innerHTML = '';

    const reader = new FileReader();
    reader.onload = async () => {
      const fileData = reader.result;
      const wb = XLSX.read(fileData, { type: 'binary' });
      const sheetNameList = wb.SheetNames; // 시트 이름 목록 가져오기
      for (let i = 0; i < sheetNameList.length; i++) {
        const sheetName = sheetNameList[i];
        const sheet = wb.Sheets[sheetName];
        await callback(sheet);
      }
    };
    reader.readAsBinaryString(file);
  };
  const excelSetting = (sheet) => {
    const procurement_result = XLSX.utils.sheet_to_html(sheet);
    console.log('procurement_result : ', procurement_result);
    document.getElementById('procurementFileTable').innerHTML = procurement_result;
    setProcurementTable();
  };

  // 발주 테이블
  const setProcurementTable = () => {
    const resultRows = document.querySelectorAll('#procurementFileTable tr');
    console.log('setProcurementTable : ', resultRows);
    let headerRowIndex = null;
    let sumRowIndex = null;
    if (resultRows.length > 0) {
      resultRows.forEach((thisItem) => {
        if (thisItem.cells[0].innerHTML === '순번') {
          headerRowIndex = thisItem.rowIndex;
          console.log('headerRowIndex : ', headerRowIndex);
        }
        if (thisItem.cells[0].innerHTML.trim() === '' || thisItem.cells[1].innerHTML.replaceAll(' ', '') === '[합계]') {
          sumRowIndex = thisItem.rowIndex;
          console.log('sumRowIndex : ', sumRowIndex);
        }
      });
    }

    if (headerRowIndex !== null && sumRowIndex !== null) {
      let result = [];
      resultRows.forEach((thisItem) => {
        if (thisItem.rowIndex >= headerRowIndex + 1 && thisItem.rowIndex < sumRowIndex) {
          if (thisItem.cells[1].innerHTML === '' && thisItem.cells[2].innerHTML === '') return;
          const OneRow = {
            status: 'waiting',
            quantity: isNaN(thisItem.cells[5].innerHTML.trim()) !== true ? Number(thisItem.cells[5].innerHTML.trim()) : 0, // 수량
            price: 0, // 금액
            // price: isNaN(thisItem.cells[9].innerHTML.trim()) !== true ? Number(thisItem.cells[9].innerHTML.trim()) : 0, // 금액
            customizedContent: {
              num: thisItem.cells[0].innerHTML.trim(), // 순번
              weight: thisItem.cells[7].innerHTML.trim(), // 중량
            },
            elementForExcelDto: {
              elementType: 'material',

              bcodeSearchCode: '',
              elementCode: thisItem.cells[2].innerHTML.trim(), // 규격
              elementName: thisItem.cells[1].innerHTML.trim(), // 재질(품명)
              elementUnit: thisItem.cells[3].innerHTML.trim(), // 단위
              elementSafeStock: 0,
              elementStock: 0,

              attributeAndValue: {},
            },
          };

          OneRow.elementForExcelDto.attributeAndValue['길이'] = thisItem.cells[4].innerHTML.trim(); // 길이
          OneRow.elementForExcelDto.attributeAndValue['단중'] = thisItem.cells[6].innerHTML.trim(); // 단중
          // OneRow.elementForExcelDto.attributeAndValue['단가'] = thisItem.cells[8].innerHTML.trim(); // 단가
          OneRow.elementForExcelDto.attributeAndValue['비고'] = thisItem.cells[8].innerHTML.trim(); // 비고
          // OneRow.elementForExcelDto.attributeAndValue['비고'] = thisItem.cells[10].innerHTML.trim(); // 비고

          result.push(OneRow);
        }
      });
      console.log('result : ', result, JSON.stringify(result));
      setFileProcurement(result);
    }
  };

  /* ====================================================================== #5 */
  // 발주서 업로드
  const setProcurementFile = (e) => {
    excelExportCommon(e, excelSetting);
  };

  const saveForm = async (e) => {
    e.preventDefault();

    if (!checkEmptyNull(_formData.accountId, false)) return alert('거래처를 선택해주세요.');
    if (!checkEmptyNull(_formData.procurementCode, false)) return alert('발주코드를 입력해주세요.');
    if (!checkEmptyNull(_formData.procurementName, false)) return alert('발주명을 입력해주세요.');

    const procurementElementList = checkNullArray(_fileProcurement, []).map((thisItem) => {
      return {
        ...thisItem,
        companyId: userReducer.company.companyId,
        customizedContent: JSON.stringify(thisItem.customizedContent),
      };
    });
    const BodyToPost = {
      ..._formData,
      accountId: _account?.accountId,

      procurementElementList: procurementElementList,

      isCustom: true, // 경서 > 커스텀 표시
    };
    console.log('BodyToPost : ', BodyToPost, JSON.stringify(BodyToPost));

    await procurementApi.createProcurement(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('procurementApi.createProcurement : ', response);
      alert('발주를 추가했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    })
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <>
        <main className="Main">
          <NavBar title={'발주관리'} nav={''} />

          <Form
            title={<>발주<br />추가</>}
            buttons={
              <>
                <button className="formButton" onClick={saveForm}>저장</button>
                <button className="formButton cancle"
                  onClick={() => {
                    navigate(pageReducer.currentPage, { replace: true });
                  }}
                >
                  취소
                </button>
              </>
            }
            forms={
              <>
                <div className="formBox">
                  <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                    <h4>거래처</h4>
                    <div className="formButton"
                      style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                      onClick={() => { setAccountModalStatus(true); }}
                    >
                      거래처 선택
                    </div>
                  </div>
                  <input
                    type="data"
                    name="accountId"
                    readOnly
                    data-required="required"
                    defaultValue={_account?.accountName}
                    placeholder=" 거래처를 선택해주세요."
                  />
                </div>

                <div className="formBox">
                  <h4>발주코드</h4>
                  <input
                    type="data"
                    name="procurementCode"
                    data-required="required"
                    placeholder="* 발주코드..."
                    defaultValue={_formData.procurementCode}
                    onChange={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>발주명</h4>
                  <input
                    type="text"
                    name="procurementName"
                    data-required="required"
                    placeholder="* 발주명..."
                    defaultValue={_formData.procurementName}
                    onChange={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>발주일자</h4>
                  <ProcurementDate
                    type="date"
                    name="procurementDate"
                    data-required="required"
                    defaultValue={_formData.procurementDate}
                    onChange={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>입고예정일자</h4>
                  <ProcurementDate
                    type="date"
                    name="scheduledEndDate"
                    defaultValue={_formData.scheduledEndDate}
                    onChange={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>발주금액</h4>
                  <input
                    type="text"
                    name="procurementPrice"
                    placeholder="* 발주금액..."
                    defaultValue={_formData.procurementPrice}
                    onInput={handleInputEvent}
                  />
                </div>

                <div className="formBox w100">
                  <h4>발주서 업로드</h4>
                  <input
                    type="file"
                    id="procurementFileInput"
                    style={{ display: 'none' }}
                    onChange={setProcurementFile}
                  />
                  <FileLabelStyle htmlFor="procurementFileInput">업로드</FileLabelStyle>
                  <FileTableSection
                    onClick={() => {
                      document.getElementById('procurementFileInput').value = '';
                      document.getElementById('procurementFileTable').innerHTML = '';
                      setFileProcurement([]);
                    }}
                  >
                    <TableSection content={<table id="procurementFileTable"></table>} />
                  </FileTableSection>
                </div>
              </>
            }
            formSectionStyle={{ paddingTop: '10px', width: '100%' }}
          />
        </main>

        {/* 경서 커스텀 */}
        {_modalStatus === true ? (
          <GsSelectElements
            buttonEvent={(data) => {
              console.log('data : ', data);
              const procruementElementIds = checkEmptyNull(_formData.procurementElementList, []).map((thisItem) => thisItem.elementId);
              const newElements = data.map((element) => {
                if (procruementElementIds.indexOf(element.elementId) !== -1) return null;
                else return {
                  ...element,

                  element: element,
                  elementId: element.elementId,
                  elementCode: element.elementCode,
                  elementName: element.elementName,

                  customizedContent: { num: '', weight: '' },

                  price: 0,
                  quantity: 0,

                  storeQuantity: 0,
                };
              });
              const setNewElements = newElements.filter((thisItem) => thisItem !== null);
              setFormData((prev) => {
                const returnData = { ...prev, procurementElementList: [...prev.procurementElementList, ...setNewElements] }
                return returnData;
              });
              setTimeout(setModalStatus(false), 1000);
            }}
            buttonTitle="물품 선택"
            open={_modalStatus}
            close={() => { setModalStatus(false) }}
          />
        ) : null}

        {_accountModalStatus === true ? (
          <SelectOneAccount
            buttonTitle={'거래처 선택'}
            buttonEvent={(data) => {
              const accountId = data.accountId;
              setAccount(data);
              setFormData(prev => { return { ...prev, accountId: accountId } });
              setTimeout(setAccountModalStatus(false), 1000);
            }}
            open={_accountModalStatus}
            close={() => { setAccountModalStatus(false); }}
          ></SelectOneAccount>
        ) : null}
      </>
    }
    />
  );
};

export default CreateGSProcurement;
