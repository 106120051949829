import React from 'react';

const Month = () => {
  return (
    <div className='Month'>
      <h4>월별 사용비율 현황</h4>
      <div>

      </div>
    </div>
  );
};

export default Month;