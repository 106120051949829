import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { checkEmptyNull, checkNullObject } from 'components/checkValues/checkValues';
import { DeleteAttrButton, SearchAttr, SearchAttrs } from './ElementModalNav.style';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import ElementCategory from 'components/category/ElementCategory';
import NavBar from 'components/nav/NavBar';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';

const ElementModalNav = ({
  elementAttrList, elementAttrNameList,
  actSearch, actReset
}) => {
  const { userReducer } = useSelector((state) => state);

  const [_elementType, setElementType] = useState('all'); // 물품유형
  const [_clickedCategory, setClickedCategory] = useState({}); // 선택한 패밀리
  const [_searchElementData, setSearchElementData] = useState({}); // 검색 (물품코드/물품이름)

  const [_detailStatus, setDetailStatus] = useState(false); // 항목 검색 열기/닫기
  const [_searchElementAttrs, setSearchElementAttrs] = useState({}); // 검색할 항목 리스트
  const [_searchOption, setSearchOption] = useState(''); // 항목 검색 옵션
  const [_searchText, setSearchText] = useState(''); // 항목 검색 입력

  const [_resetStatus, setResetStatus] = useState(false); // 초기화 >> 카테고리

  useEffect(() => {}, [
    _elementType, _clickedCategory, _searchElementData, 
    _detailStatus, _searchElementAttrs, _searchOption, _searchText,
    _resetStatus
  ])

  const translateElementType = (elementType) => { // 물품유형 텍스트
    let typeText = '';
    switch (elementType) {
      case 'material': typeText = '자재'; break;
      case 'semi': typeText = '부자재'; break;
      case 'half': typeText = '반제품'; break;
      case 'product': typeText = '완제품'; break;

      default: break;
    }

    return typeText;
  }
  const addSearchAttr = async () => { // 항목 검색 추가
    if (!checkEmptyNull(_searchOption, false) || !checkEmptyNull(_searchText, false)) return;
    setSearchElementAttrs((prev) => {return { ...prev, [_searchOption]: _searchText };});
  };
  const handleSearch = (name, value) => { // 검색
    const searchData = { // 값이 있을 때만
      elementType: checkEmptyNull(_elementType, false) && _elementType !== 'all' ? _elementType : undefined,
      categoryId: checkEmptyNull(_clickedCategory.categoryId, undefined),
      elementName: checkEmptyNull(_searchElementData.elementName, undefined),
      elementCode: checkEmptyNull(_searchElementData.elementCode, undefined),
      attributeAndValue: checkNullObject(_searchElementAttrs, undefined),
    }
    if(checkEmptyNull(name, false)) searchData[name] = checkEmptyNull(value, undefined);

    actSearch(searchData);
  }
  const handleReset = () => { // 초기화
    setElementType(() => {return 'all'});
    setClickedCategory(() => {return {}});
    setSearchElementData(() => {return {}});
    setSearchElementAttrs(() => {return {}});
    setSearchOption(() => {return ''});
    setSearchText(() => {return ''});

    setResetStatus(() => {return true});
    actReset();
  }


  return (
    <NavBar
      nav={!_detailStatus ? 'search' : 'all'}
      title={
        <>
          <div className="SearchSection">
            <select
              className="SelectType"
              name='elementType'
              value={_elementType}
              onChange={(e) => {
                setElementType(() => {return e.target.value});
                handleSearch(e.target.name, e.target.value);
              }}
            >
              <option value="all">전체</option>
              {userReducer.elementType.map((elementType) => {
                return (<option key={elementType + '_elementTypes'} value={elementType}>{translateElementType(elementType)}</option>);
              })}
            </select>

            <ElementCategory handleSearch={handleSearch} resetStatus={_resetStatus} setResetStatus={setResetStatus}  />
          </div>
        </>
      }
      firstRow={
        <>
          <div className="SearchSection">
            <div className="ContentCBox">
              <select className="SearchOption detail"><option value="elementName">물품이름</option></select>
              <input
                className="SearchBar detail"
                placeholder="Search..."
                value={checkEmptyNull(_searchElementData.elementName, '')}
                onInput={(e) => {setSearchElementData((prev) => {return { ...prev, elementName: e.target.value };});}}
              />
            </div>

            <div className="ContentCBox">
              <select className="SearchOption detail"><option value="elementCode">물품코드</option></select>
              <input
                className="SearchBar detail"
                placeholder="Search..."
                value={checkEmptyNull(_searchElementData.elementCode, '')}
                onInput={(e) => {setSearchElementData((prev) => {return { ...prev, elementCode: e.target.value };});}}
              />
            </div>

            <div className="ContentCBox">
              <div className="ResetButton detail" onClick={handleReset}>초기화</div>
              <div className={`DetailButton ${!_detailStatus && 'active'}`}
                onClick={() => { setDetailStatus((prev) => { return !prev }) }}
              >상세 검색</div>

              <div className="DetailButton search"
                style={{ backgroundColor: 'var(--ThirdBlue)', columnGap: '3px' }}
                onClick={handleSearch}
              ><SearchButtonIcon />검색</div>
            </div>
          </div>
        </>
      }
      secondRow={
        <>
          {_detailStatus && <div className="SearchSection">
            <div className="ContentCBox">
              <div className="ContentCBox">
                <select
                  className="SearchOption"
                  value={_searchOption}
                  onChange={(e) => { setSearchOption(() => { return e.target.value }) }}
                >
                  <option value="">항목선택</option>
                  {elementAttrList.map((attr) => {
                    return (<option key={attr.elementAttributeId + '_searchOptionAttrs'} value={attr.elementAttributeId}>{attr.elementAttributeName}</option>);
                  })}
                </select>
                <input
                  className="SearchBar"
                  placeholder="Search..."
                  value={_searchText}
                  onInput={(e) => { setSearchText(() => { return e.target.value }) }}
                />
              </div>

              <button data-searchbutton="true" onClick={addSearchAttr}><div style={{ fontSize: '30px', lineHeight: '35px' }}>+</div></button>
            </div>

            <SearchAttrs>
              {Object.keys(_searchElementAttrs).map((key) => {
                return (
                  <SearchAttr key={key + '_searchAttr'}
                    className="formButton"
                    onClick={(e) => {
                      e.preventDefault();
                      setSearchElementAttrs((prev) => {
                        const prevData = { ...prev };
                        delete prevData[key];
                        return prevData;
                      });
                    }}
                  >
                    <p>{`${elementAttrNameList[key]}: ${_searchElementAttrs[key]}`}</p>
                    <DeleteAttrButton><CloseButtonIcon /></DeleteAttrButton>
                  </SearchAttr>
                );
              })}
            </SearchAttrs>
          </div>}
        </>
      }
    />
  );
};

export default ElementModalNav;