import React from 'react';
import styled from 'styled-components';

import GuideText from 'components/buttons/GuideText';

const StatusButtonStyle = styled.div`
  align-items: center;
  border: 4px solid var(--white);
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  width: 100%;

  background-color: ${(props) => {
    switch (props.status) {
      case 'wait': return 'var(--gray-900)';
      case 'waiting': return 'var(--gray-900)';
      case 'work': return 'var(--WorkingColor)';
      case 'working': return 'var(--WorkingColor)';
      case 'proceeding': return 'var(--WorkingColor)';
      case 'completed': return 'var(--ThirdBlue)';
      case 'end': return 'var(--ThirdBlue)';
      case 'stop': return 'var(--MainRed)';
      case 'pause': return 'var(--MainYellow)';
      case 'cancel': return 'var(--Bg)';
      case 'canceled': return 'var(--Bg)';
      case 'inspection': return 'var(--MainRed)';
      case 'intermediate': return 'var(--MainBlue)';
      default: return '';
    }
  }};

  color: ${(props) => {
    return props.status === 'canceled' ? 'var(--gray-200)' : 'var(--white)';
  }};

  ${(props) => {
    return ((props.status === 'wait' || props.status === 'waiting') ? 'animation: WaitingStatus 1.5s infinite;' : '');
  }};

  ${(props) => {
    return props.status === 'completed' ? 'cursor: default;'
      : props.status === 'end' ? 'cursor: default;'
        : 'cursor: pointer';
  }};
`;

const StatusButton = (props) => {
  return (
    <StatusButtonStyle
      style={props.statusStyle}
      data-status={props.status}
      data-workordercode={props.workOrderCode}
      status={props.status}
      onClick={props.statusEvent}
    >
      {(() => {
        switch (props.status) {
          case 'wait': return '대기';
          case 'waiting': return '대기';
          case 'work':
            if (props.modal === 'equipment') return '작동';
            else if (props.modal === true) return '진행';
            else return '작업중';
          case 'working':
            if (props.modal === 'equipment') return '작동';
            else if (props.modal === true) return '진행';
            else return '작업중';
          case 'proceeding': return '진행';
          case 'completed': return '완료';
          case 'end': return '완료';
          case 'stop': return '중단';
          case 'pause': return '일시정지';
          case 'cancel': return '취소';
          case 'canceled': return '취소';
          case 'inspection': return '점검';
          case 'intermediate': return '부분저장';
          default: return '';
        }
      })()}

      {(props.statusEvent !== undefined && props.statusEvent !== null) ? <GuideText /> : null}
    </StatusButtonStyle>
  );
};

export default StatusButton;
