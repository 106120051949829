const Conditions = (value) => {
  if (value === false || value === undefined || value === null || value === 'null') {
    return false;
  } else {
    return true;
  }
};

const EmptyConditions = (value) => {
  if (value === false || value === undefined || value === null || value === 'null' || value === '') {
    return false;
  } else {
    return true;
  }
};

const ArrayConditions = (value) => {
  if (!Conditions(value) || typeof value !== 'object' || value.length === 0) {
    return false;
  } else {
    return true;
  }
};
const ObjectConditions = (value) => {
  if (!Conditions(value) || typeof value !== 'object' || Object.keys(value).length === 0) {
    return false;
  } else {
    return true;
  }
};

/* ====================================================================== # */
const checkNull = (value, replaceContent) => {
  if (!Conditions(value)) {
    return replaceContent;
  } else {
    return value;
  }
};

const checkEmptyNull = (value, replaceContent) => {
  if (!EmptyConditions(value)) {
    return replaceContent;
  } else {
    return value;
  }
};

const checkNullParse = (value, replaceContent) => {
  if (!Conditions(value)) {
    return replaceContent;
  } else {
    return JSON.parse(value);
  }
};

const checkNullArray = (value, replaceContent) => {
  if (!ArrayConditions(value)) {
    return replaceContent;
  } else {
    return value;
  }
};

const checkNullObject = (value, replaceContent) => {
  if (!ObjectConditions(value)) {
    return replaceContent;
  } else {
    return value;
  }
};

export { checkNull, checkEmptyNull, checkNullParse, checkNullArray, checkNullObject };
