import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

import { elementAttributeApi } from 'api/apis/elementAttributeApi';
import { elementApi } from 'api/apis/elementApi';
import { stockLogApi } from 'api/apis/stockLogApi';

import { checkEmptyNull, checkNull, checkNullObject } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat'
;
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import IconRightArrow from 'components/icons/IconRightArrow';
import InlineCategories from 'components/category/InlineCategories';

const ElementDownloadSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {height: 12px;width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400);border: 2px solid var(--gray-100);    border-radius: 6px;}
`;
const MainContents = styled.div`
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 5px 20px;
  width: 100%;
`;

const StatusSelect = styled.select`
  border: 1px solid var(--ThirdGreen);
  border-radius: 5px;
  box-sizing: border-box;
  height: fit-content;
  margin-top: 5px;
  padding: 10px;
  width: 100%;
`;
const Familycontent = styled.div`
  align-items: center;
  grid-gap: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 5px;
  width: 100%;

  select {
    border: 1px solid var(--ThirdGreen);
    border-radius: 5px;
    box-sizing: border-box;
    height: fit-content;
    padding: 2px 10px;
    width: 150px;
  }
`;
const DateContents = styled.div`
  align-items: center;
  column-gap: 10px;
  display: grid;
  grid-template-columns: 1fr 15px 1fr;
  width: 100%;
`;
const SearchContents = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 150px calc(100% - 160px);
  margin-top: 5px;
  width: 100%;
  &.detail {grid-template-columns: 150px calc(100% - 210px) 50px;}

  select {
    border: 1px solid var(--ThirdGreen);
    border-radius: 5px;
    box-sizing: border-box;
    height: fit-content;
    padding: 10px;
    width: 100%;
    &.detail {appearance: none;--webkit-appearance: none;background-color: var(--ThirdGreen);color: var(--white);}
  }
  input {
    border: 1px solid var(--ThirdGreen);
    border-radius: 5px;
    box-sizing: border-box;
    height: fit-content;
    padding: 10px;
    width: 100%;
    &:focus {border: 1px solid var(--ThirdGreen);}
  }
`;
const SelectDate = styled.input`
  border: 1px solid var(--ThirdGreen);
  border-radius: 5px;
  box-sizing: border-box;
  height: 40px;
  margin-top: 5px;
  min-width: 250px;
  padding: 10px;
  width: 100%;
  &:focus {border: 1px solid var(--ThirdGreen);}
`;
const SearchAttrs = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  margin-top: 10px;
  width: calc(100vw - 450px);
`;
const SearchAttr = styled.div`
  align-items: center;
  column-gap: 10px;
  display: flex;
  height: unset;
  padding: 6px 20px;
  justify-content: center;
  width: fit-content;
  & p {width: calc(100% - 20px);word-break: break-all;}
`;
const DetailButton = styled.div`
  align-items: center;
  background-color: var(--ThirdGreen);
  border-radius: 50%;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 1.5em;
  font-weight: 600;
  line-height: 40px;
  height: 40px;
  justify-content: center;
  width: 40px;
`;
const DeleteButton = styled.div`
  height: 24px;
  width: 24px;
  div {background-color: var(--white);cursor: pointer;height: 15px;width: 15px;}
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--ThirdGreen);
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 400;
  height: 100%;
  justify-content: center;
  width: 100%;
`;


function NaraElementDownloadModal(props) {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_elementAttrList, setElementAttrList] = useState([]);
  const [_elementAttrNameList, setElementAttrNameList] = useState({});

  const [_elementType, setElementType] = useState('all');
  const [_clickedCategory, setClickedCategory] = useState({});
  const [_startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [_endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [_searchElementData, setSearchElementData] = useState({});
  const [_searchElementAttrs, setSearchElementAttrs] = useState({});
  const [_searchOption, setSearchOption] = useState('');
  const [_searchText, setSearchText] = useState('');


  /* ====================================================================== #3 */
  useEffect(() => {
    getElementAttrs();

    setOnload('loaded');
  }, []);

  useEffect(() => { }, [_clickedCategory]);

  /* ====================================================================== #4 */
  const getElementAttrs = async (elementType) => {
    const BodyToPost = {companyId: userReducer.company.companyId};
    if (elementType !== undefined && elementType !== 'all') {
      BodyToPost.elementType = elementType;
    }
    console.log('getElementAttrs - BodyToPost : ', BodyToPost);

    await elementAttributeApi.searchElementAttribute(BodyToPost).then((response) => {
        if (response === undefined) return;
        console.log('elementAttributeApi.searchElementAttribute : ', response);
        setElementAttrList(() => {return response.data;});
        const nameList = {};
        response.data.forEach((thisItem) => {
          nameList[thisItem.elementAttributeId] = thisItem.elementAttributeName;
        });
        setElementAttrNameList(() => {return nameList;});
      });
  };

  /* 현재고 엑셀 다운로드 */
  const CurrentStockExcelDownload = async () => {
    const paging = `?page=0&size=7000&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.user.companyId,
    };
    if (_elementType !== undefined && _elementType !== 'all') {
      BodyToPost.elementType = _elementType;
    }
    if (checkEmptyNull(_clickedCategory, false) && _clickedCategory !== 'all') {
      BodyToPost.categoryId = _clickedCategory.categoryId;
    }
    if (checkEmptyNull(_searchElementData.elementName, false)) {
      BodyToPost.elementName = _searchElementData.elementName;
    }
    if (checkEmptyNull(_searchElementData.elementCode, false)) {
      BodyToPost.elementCode = _searchElementData.elementCode;
    }
    if (checkNullObject(_searchElementAttrs, false)) {
      BodyToPost.attributeAndValue = _searchElementAttrs;
    }

    await elementApi.searchElementStockList(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('elementApi.searchElementStockList : ', response);
      setExcelData_currentStock(response.data.content);
    });
  };
  const setExcelData_currentStock = async (Result) => {
    const excelList = Result.map((stockElement) => {
      const prevStock = BigNumber(checkEmptyNull(stockElement.elementStock, 0)).minus(BigNumber(checkEmptyNull(stockElement.todayInStock, 0)))
      .plus(BigNumber(checkEmptyNull(stockElement.todayPutStock, 0))).plus(BigNumber(checkEmptyNull(stockElement.todayOutStock, 0))).toNumber();

      const returnData = {
        패밀리: stockElement.categoryPath,
        물품이름: stockElement.elementName,
        물품코드: stockElement.elementCode,
        전일재고: checkEmptyNull(prevStock, 0).toLocaleString() + ' ' + checkEmptyNull(stockElement.elementUnit, ''),
        당일입고: checkEmptyNull(stockElement.todayInStock, 0).toLocaleString() + ' ' + checkEmptyNull(stockElement.elementUnit, ''),
        당일투입: checkEmptyNull(stockElement.todayPutStock, 0).toLocaleString() + ' ' + checkEmptyNull(stockElement.elementUnit, ''),
        당일출고: checkEmptyNull(stockElement.todayOutStock, 0).toLocaleString() + ' ' + checkEmptyNull(stockElement.elementUnit, ''),
        안전재고: checkEmptyNull(stockElement.elementSafeStock, 0).toLocaleString() + ' ' + checkEmptyNull(stockElement.elementUnit, ''),
        투입예정수량: checkEmptyNull(stockElement.scheduledInputStock, 0).toLocaleString() + ' ' + checkEmptyNull(stockElement.elementUnit, ''),
        현재고: checkEmptyNull(stockElement.elementStock, 0).toLocaleString() + ' ' + checkEmptyNull(stockElement.elementUnit, ''),
      };
      return returnData;
    });
    console.log('excelList : ', excelList);

    const TodayDate = moment(new Date()).format('YYYY-MM-DD_HH시mm분');
    const ws = XLSX.utils.json_to_sheet(excelList);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `${TodayDate}_현재고`);
    XLSX.writeFile(wb, `${TodayDate}_현재고.xlsx`);

    props.close();
  };

  /* 재고 상세 이력 엑셀 다운로드 */
  const StockHistoryExcelDownload = async () => {
    const paging = `?page=0&size=7000&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.user.companyId,
    };
    if (checkEmptyNull(_elementType, false) && _elementType !== 'all') {
      BodyToPost.elementType = _elementType;
    }
    if (checkNullObject(_clickedCategory, false)) {
      BodyToPost.categoryId = _clickedCategory.categoryId;
    }
    if (checkEmptyNull(_searchElementData.elementName, false)) {
      BodyToPost.elementName = _searchElementData.elementName;
    }
    if (checkEmptyNull(_searchElementData.elementCode, false)) {
      BodyToPost.elementCode = _searchElementData.elementCode;
    }

    if (checkEmptyNull(_startDate, false) && checkEmptyNull(_endDate, false)) {
      BodyToPost.startDate = new Date(_startDate).toISOString();
      BodyToPost.endDate = new Date(_endDate).toISOString();
      if (_startDate > _endDate) {
        BodyToPost.startDate = new Date(_startDate).toISOString();
        BodyToPost.endDate = new Date(_endDate).toISOString();
      }
    }
    await stockLogApi.searchElementStockLogs(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('stockLogApi.searchElementStockLogs : ', response);
      setExcelData_history(response.data.content);
    });
  };
  const setExcelData_history = async (Result) => {
    const excelList = Result.map((thisItem) => {
      const returnData = {
        입력시각: checkEmptyNull(thisItem.createdDate, false) ? DateTimeFormat(thisItem.createdDate) : '',
        물품이름: thisItem.element.elementName,
        물품코드: thisItem.element.elementCode,
        나라장터물품코드: thisItem.naraElementCode,
        입고: thisItem.logType === 'In' ? checkEmptyNull(thisItem.amount, 0).toLocaleString() + ' ' + checkEmptyNull(thisItem.element.elementUnit, '') : '',
        투입: thisItem.logType === 'Put' ? checkEmptyNull(thisItem.amount, 0).toLocaleString() + ' ' + checkEmptyNull(thisItem.element.elementUnit, '') : '',
        생산: thisItem.logType === 'Make' ? checkEmptyNull(thisItem.amount, 0).toLocaleString() + ' ' + checkEmptyNull(thisItem.element.elementUnit, '') : '',
        출고: thisItem.logType === 'Out' ? checkEmptyNull(thisItem.amount, 0).toLocaleString() + ' ' + checkEmptyNull(thisItem.element.elementUnit, '') : '',
        금액: checkEmptyNull(thisItem.price, ''),
        유통기한: checkEmptyNull(thisItem.checkedDate, false) ? DateTimeFormat(thisItem.checkedDate) : '',
        재고위치: checkEmptyNull(thisItem.stockPlaceName, ''),
        비고: thisItem.note,
        작업자: checkEmptyNull(thisItem.userName, ''),
      };
      return returnData;
    });
    console.log('excelList : ', excelList);

    const ws = XLSX.utils.json_to_sheet(excelList);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '재고상세이력');
    XLSX.writeFile(wb, '재고상세이력.xlsx');

    props.close();
  };

  /* 물품정보 엑셀 다운로드 */
  const InformationExcelDownload = async () => {
    const paging = `?page=0&size=7000&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.user.companyId,
    };
    if (checkEmptyNull(_elementType, false) && _elementType !== 'all') {
      BodyToPost.elementType = _elementType;
    }
    if (checkNullObject(_clickedCategory, false) && _clickedCategory !== 'all') {
      BodyToPost.categoryId = _clickedCategory.categoryId;
    }
    if (checkEmptyNull(_searchElementData.elementName, false)) {
      BodyToPost.elementName = _searchElementData.elementName;
    }
    if (checkEmptyNull(_searchElementData.elementCode, false)) {
      BodyToPost.elementCode = _searchElementData.elementCode;
    }
    if (checkNullObject(_searchElementAttrs, false)) {
      BodyToPost.attributeAndValue = _searchElementAttrs;
    }

    await elementApi.searchElement(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('elementApi.searchElement : ', response);
      setExcelData_element(response.data.content);
    });
  };
  const setExcelData_element = async (Result) => {
    const elementList = Result;
    const excelList = elementList.map((thisItem) => {
      const OneRow = {
        패밀리: thisItem.categoryPath,
        물품이름: thisItem.elementName,
        물품코드: thisItem.elementCode,
        나라장터물품코드: checkNull(thisItem.naraElementCode, ''),
        단위: thisItem.elementUnit,
        // 투입예정수량: thisItem.scheduledInputStock,
        // 현재고: thisItem.elementStock,
        안전재고: thisItem.elementSafeStock,
        단가: thisItem.price,
      };
      thisItem.elementAttributeValueList.forEach((thisItem) => {
        OneRow[thisItem.elementAttribute.elementAttributeName] = thisItem.value;
      });
      return OneRow;
    });
    console.log('excelList : ', excelList);

    const ws = XLSX.utils.json_to_sheet(excelList);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '물품정보');
    XLSX.writeFile(wb, '물품정보.xlsx');

    props.close();
  };

  /* ====================================================================== #5 */
  /* 상세 검색 */
  const addSearchAttr = async () => {
    if (checkEmptyNull(_searchOption, false) && checkEmptyNull(_searchText, false)) return;
    setSearchElementAttrs((prev) => { return { ...prev, [_searchOption]: _searchText }; });
  };

  /* ====================================================================== #6 */

  return (
    <>
      <ElementDownloadSection>
        <MainContents>
          <div>물품유형</div>
          <StatusSelect
            value={_elementType}
            onChange={(e) => {
              setElementType(() => { return e.target.value });
              getElementAttrs(e.target.value);
            }}
          >
            <option value="all">전체</option>
            {userReducer.elementType.map((thisItem) => {
              let typeText = '';
              switch (thisItem) {
                case 'material': typeText = '자재'; break;
                case 'semi': typeText = '부자재'; break;
                case 'half': typeText = '반제품'; break;
                case 'product': typeText = '완제품'; break;
                case 'tool': typeText = '공구'; break;

                default: return null;
              }
              return (<option key={thisItem + '_elementTypes'} value={thisItem}>{typeText}</option>);
            })}
          </StatusSelect>
        </MainContents>

        <MainContents>
          <div>패밀리 설정</div>
          <Familycontent>
            <InlineCategories handlerCategory={setClickedCategory} reset={false} />
          </Familycontent>
        </MainContents>

        {props.subTitle === 'StockHistory' ? (
          <MainContents>
            <div>날짜</div>
            <DateContents>
              <SelectDate
                type="date"
                className="SelectDate"
                value={_startDate}
                onChange={(e) => { setStartDate(() => { return e.target.value }) }}
              />

              <IconRightArrow />

              <SelectDate
                type="date"
                className="SelectDate"
                value={_endDate}
                onChange={(e) => { setEndDate(() => { return e.target.value }) }}
              />
            </DateContents>
          </MainContents>
        ) : null}

        <MainContents>
          <div>검색</div>
          <SearchContents>
            <select className="SearchOption detail">
              <option value="elementName">물품이름</option>
            </select>
            <input
              className="SearchBar detail"
              placeholder="Search..."
              value={checkEmptyNull(_searchElementData.elementName, '')}
              onInput={(e) => { setSearchElementData((prev) => { return { ...prev, elementName: e.target.value }; }); }}
            />
          </SearchContents>
          <SearchContents>
            <select className="SearchOption detail">
              <option value="elementCode">물품코드</option>
            </select>
            <input
              className="SearchBar detail"
              placeholder="Search..."
              value={checkEmptyNull(_searchElementData.elementCode, '')}
              onInput={(e) => { setSearchElementData((prev) => { return { ...prev, elementCode: e.target.value }; }); }}
            />
          </SearchContents>
        </MainContents>

        {props.subTitle === 'Information' ? (
          <MainContents>
            <div>상세 검색</div>
            <SearchContents className="detail">
              <select
                className="SearchOption"
                value={_searchOption}
                onChange={(e) => { setSearchOption(() => { return e.target.value }) }}
              >
                <option value="">항목선택</option>
                {_elementAttrList.map((thisItem) => {
                  return (<option key={thisItem.elementAttributeId + '_searchOptionAttrs'} value={thisItem.elementAttributeId}>{thisItem.elementAttributeName}</option>);
                })}
              </select>
              <input
                className="SearchBar"
                placeholder="Search..."
                value={_searchText}
                onInput={(e) => { setSearchText(() => { return e.target.value }) }}
              />

              <DetailButton onClick={addSearchAttr}>+</DetailButton>
            </SearchContents>

            <SearchAttrs>
              {Object.keys(_searchElementAttrs).map((thisKey) => {
                return (<SearchAttr
                  key={thisKey + '_searchAttr'}
                  className="formButton"
                  style={{ backgroundColor: 'var(--ThirdGreen)' }}
                  onClick={(e) => {
                    setSearchElementAttrs((prev) => {
                      const prevData = { ...prev };
                      delete prevData[thisKey];
                      return prevData;
                    });
                  }}
                >
                  <p>{`${_elementAttrNameList[thisKey]}: ${_searchElementAttrs[thisKey]}`}</p>
                  <DeleteButton><CloseButtonIcon /></DeleteButton>
                </SearchAttr>
                );
              })}
            </SearchAttrs>
          </MainContents>
        ) : null}
      </ElementDownloadSection>

      <ModalFooter>
        <ModalButton
          onClick={() => {
            if (props.subTitle === 'Information') {
              InformationExcelDownload();
            } else if (props.subTitle === 'CurrentStock') {
              CurrentStockExcelDownload();
            } else if (props.subTitle === 'CurrentStockV2') {
              CurrentStockExcelDownload();
            } else if (props.subTitle === 'StockHistory') {
              StockHistoryExcelDownload();
            } else return;
          }}
        >
          다운로드
        </ModalButton>
      </ModalFooter>
    </>
  );
}

export default NaraElementDownloadModal;
