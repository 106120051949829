import React, { useEffect, useState } from 'react';

import { fileDataApi } from 'api/apis/fileDataApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import LoadingComponent from 'components/loading/LoadingComponent';

const DeleteContent = ({
  close, modalData
}) => {
  const body = {
    companyId: modalData.companyId,
    fileDataId: modalData.fileDataId,
    fileDataName: modalData.fileDataName,
  };
  const [_body, setBody] = useState(body);
  const [_loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => {}, [_body]);

  const actDelete = async(e) => { // 파일 삭제
    const confirmText = `${body.fileDataName} 파일을 삭제하시겠습니까?`;
    if(window.confirm(confirmText)) {
      setLoadingStatus(() => {return true});
      await fileDataApi.deleteFileData(body.fileDataId).then(response => {
        if(response === undefined) return;
        console.log('actDelete : ', response);
        alert(`${body.fileDataName} 파일을 삭제했습니다.`);
        setLoadingStatus(() => {return false});
        close('update');
      }).finally(() => {
        setLoadingStatus(() => {return false});
      })
    } else {
      close();
    }
  };

  return (
    <>
    <section className='delete-content'>
      <div>
        {[
          {name: 'fileDataName', title: '파일이름', disabled: true},
        ].map(((fileData) => {
          return (
            <div key={fileData.name}>
              <h4>{fileData.title}</h4>
              <input
                type='text'
                name={fileData.name}
                disabled={fileData.disabled}
                value={checkEmptyNull(_body[fileData.name], '')}
              />
            </div>
          )
        }))}
      </div>

      <button className='btn-save' onClick={actDelete}>삭제</button>
    </section>

    {_loadingStatus ? (<LoadingComponent close={close} title={'파일을 삭제하고 있습니다...'} />) : null}
    </>
  );
};

export default DeleteContent;