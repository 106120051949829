import React, { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

import AllCheckBox from 'components/checkbox/AllCheckBox';
import CheckBox from 'components/checkbox/CheckBox';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const SelectProcessLogs = (props) => {
  const workOrderLog = props.content;
  const [_processLogList, setProcessLogList] = useState(workOrderLog.processLogList.filter((thisProcess) => thisProcess.processTypeName === props.processType));

  useEffect(() => {
    if (_processLogList.length < 1) {
      alert('해당 작업이 없습니다.');
      props.close();
    }
  }, [])

  const actSelect = () => {
    const processLogList = [];
    console.log('props.processLogList : ', props.processLogList);
    const processLogIdArray = props.processLogList.map((item) => item.processLogId);

    document.querySelectorAll('input[name="processLogs"]:checked').forEach((processLogContent, index) => {
      const workOrderLogId = workOrderLog.workOrderLogId;
      const processLogId = Number(processLogContent.dataset.key);

      const processLogIndex = _processLogList.findIndex((item) => item.processLogId === processLogId);
      const processLogData = _processLogList[processLogIndex];
      const returnData = { ...processLogData, workOrderLogId: workOrderLogId };
      const filterIndex = processLogIdArray.findIndex((item) => item === processLogId);
      if (filterIndex === -1) processLogList.push(returnData);
      if (index === document.querySelectorAll('input[name="processLogs"]:checked').length - 1) {
        console.log('processLogList: ', processLogList);
        props.update(processLogList);
        props.close();
      }
    });
  };

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection type="list" style={{ zoom: '100%' }}>
            <ModalHeader>
              <ModalTitle>공정 선택</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <TableSection content={
                <table>
                  <thead>
                    <tr>
                      <th rowSpan={2} style={{ minWidth: '50px', width: '50px' }}><AllCheckBox key={'AllCheckBox'} checkboxName={'processLogs'} /></th>
                      <th rowSpan={2}>공정상태</th>
                      <th rowSpan={2} style={{ minWidth: '80px', width: '80px' }}>순번</th>
                      <th colSpan={2}>공정정보</th>
                      <th colSpan={2}>진행상태</th>
                    </tr>
                    <tr>
                      <th>공정코드</th>
                      <th>공정이름</th>
                      <th>클릭</th>
                      <th>진행도</th>
                    </tr>
                  </thead>
                  <tbody>
                  {checkNullArray(_processLogList, []).map((processLog, index) => {
                    const currentClick = BigNumber(checkEmptyNull(processLog.completedAmount, 0)).dividedBy(BigNumber(checkEmptyNull(processLog.processingCount, 0))).toNumber();
                    const totalClick = BigNumber(checkEmptyNull(processLog.processAmount, 0)).dividedBy(BigNumber(checkEmptyNull(processLog.processingCount, 0))).toNumber();
                    return (
                      <tr key={index + '_processLogs'}>
                        <td style={{ minWidth: '50px', width: '50px' }}>
                          <CheckBox
                            act={''}
                            all={true}
                            checkboxKey={processLog.processLogId}
                            name={'processLogs'}
                          />
                        </td>
                        <StatusTdButton
                          statusStyle={{ cursor: 'default', height: '120px', minWidth: '120px', width: '120px' }}
                          status={processLog.processStatus}
                        />
                        <td style={{ minWidth: '80px', width: '80px' }}>{processLog.processNumber}</td>
                        <td>{processLog.processCode}</td>
                        <td>{processLog.processName}</td>
                        <td>{currentClick.toLocaleString()} / {totalClick.toLocaleString()}</td>
                        <td>{(checkEmptyNull(processLog.completedAmount, 0) * 1).toLocaleString()} / {(checkEmptyNull(processLog.processAmount, 0) * 1).toLocaleString()}</td>
                      </tr>
                    );
                  })}
                  </tbody>
                </table>
              }></TableSection>
            </ModalMain>
            <ModalFooter>
              <ModalButton onClick={actSelect}>공정 선택</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};
export default SelectProcessLogs;
