const initialState = {
  cluster: {}, company: {}, user: {},
  searchData: {},
  openMenus: [],
  companyList: [],
  mypage: {},
  pageNumber: 1, totalSize: 0,
};
const resetState = {
  cluster: {}, company: {}, user: {},
  searchData: {},
  openMenus: [],
  companyList: [],
  mypage: {},
  pageNumber: 1, totalSize: 0,
};

const clusterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETCLUSTERDATA': return { ...state, cluster: action.payload };
    case 'SETCLUSTERCOMPANYDATA': return { ...state, company: action.payload };
    case 'SETCLUSTERUSERDATA': return { ...state, user: action.payload };
    case 'SETCLUSTEROPENMENUS': return { ...state, openMenus: action.payload };

    case 'SETCLUSTERCOMPANYLIST': return { ...state, companyList: action.payload };

    case 'SETCLUSTERMYPAGE': return { ...state, mypage: action.payload };

    case 'SETCLUSTERSEARCHDATA': return { ...state, searchData: action.payload };
    case 'SETCLUSTERPAGENUMBER': return { ...state, pageNumber: action.payload };
    case 'SETCLUSTERTOTALSIZE': return { ...state, totalSize: action.payload };

    case 'SETCLUSTERUSERRESET': return { ...state, ...resetState };
    default: return state;
  }
};

export default clusterReducer;
