import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { FaPlus } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { MdArrowDropDown } from "react-icons/md";
import { ColorPalette } from 'pages/operator/consts/ColorPalette';
import { calendarType } from 'api/apis/calendar/calendarType';

const ProjectType = ({
  modalType, modalData,
  update,
  close
}) => {
  const { userReducer } = useSelector(state => state);

  const [_body, setBody] = useState({ ...modalData });
  const [_color, setColor] = useState(modalData.color || '#000');
  const [_customColor, setCustomColor] = useState('#000');
  const [_selectHandler, setSelectHandler] = useState(false); // 색상 선택 모달 상태


  useEffect(() => { }, [_body, _color, _customColor, _selectHandler]);

  const actSave = async (e) => { // create/update
    e.preventDefault();
    const body = {
      companyId: userReducer.company.companyId,
      ..._body,
      color: _color
    };
    if (modalType.includes('create')) { // create
      await calendarType.create(body).then(response => {
        if (response === undefined) return;
        console.log('calendarType.create : ', response);
        update();
      })
    } else { // update
      delete body?.createdDate;
      delete body?.modifiedDate;

      await calendarType.update(body.calenderTypeId, body).then(response => {
        if (response === undefined) return;
        console.log('calendarType.update : ', response);
        update();
      })
    }
  };
  const actDelete = async () => { // delete
    const confirmText = `해당 프로젝트 유형을 삭제하시겠습니까?`;
    if (window.confirm(confirmText)) {
      await calendarType.delete(_body.calenderTypeId).then(response => {
        if (response === undefined) return;
        console.log('calendarType.delete : ', response);
        update();
      })
    }
  };

  return (
    <section>
      <div className='section-form'>
        <h2>프로젝트 유형 정보</h2>
        <div className='form-content color'>
          <h4>색상</h4>
          {!modalType.endsWith('delete') ? ( // 추가/수정
            <>
              <button className='current-btn' onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectHandler(() => { return true });
              }}>
                <div className='current-color' style={{ backgroundColor: _color }}></div>
                <MdArrowDropDown />
              </button>
              {_selectHandler ? (
                <div className='color-select'>
                  <button className='btn-close' onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSelectHandler(() => { return false });
                  }}><IoClose /></button>

                  {ColorPalette.map((color, index) => {
                    return (
                      <button
                        key={index + '_color'}
                        className={'color-btn ' + (color.hex === _color ? 'active' : '')}
                        style={{ backgroundColor: color.hex }}
                        title={color.name}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setColor(() => { return color.hex });
                        }}
                      ></button>
                    );
                  })}

                  <button
                    className={'color-btn ' + (_customColor === _color ? 'active' : '')}
                    style={{ backgroundColor: _customColor }}
                    title={'사용자 정의 색상'}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setColor(() => { return _customColor });
                    }}
                  ></button>
                  <input
                    type='color'
                    id='custom-color'
                    value={_customColor || '#000'}
                    onChange={(e) => { setCustomColor(() => { return e.target.value }); }}
                  />
                  <label
                    htmlFor='custom-color'
                    className='custom-btn'
                    title={'사용자 정의'}
                  >
                    <FaPlus />
                  </label>
                </div>
              ) : null}
            </>
          ) : ( // 삭제
            <div className='current-color' style={{ backgroundColor: _color }}></div>
          )}
        </div>
        <div className='form-content'>
          <h4>유형 이름</h4>
          <input
            type='text'
            placeholder='유형 이름을 입력해주세요.'
            disabled={modalType.endsWith('delete')}
            value={_body.calenderTypeName || ''}
            onInput={(e) => {
              setBody((prev) => {
                return { ...prev, calenderTypeName: e.target.value }
              })
            }}
          />
        </div>
      </div>
      <div className='section-action'>
        <button className='btn-cancel' onClick={close}>취소</button>
        {!modalType.endsWith('delete') ? (<button className='btn-save' onClick={actSave}>저장</button>) : (<button className='btn-delete' onClick={actDelete}>삭제</button>)}
      </div>
    </section>
  );
};

export default ProjectType;