import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';

import {
  pageReducer_getCurrentPage,
  pageReducer_setPageNum,
  pageReducer_setPageSize,
  pageReducer_setTotalCount,
} from 'store/modules/actions/default/pageActions';
import { searchReducer_setWorkStatus } from 'store/modules/actions/default/searchActions';
import { YoungContentsReducer_setWorkOrderContent } from 'store/modules/actions/dev/YoungContentsActions';

import { getCookie } from 'preferences/cookie/cookie';
import { AXIOS_BASE_URL } from 'preferences/server/constants';

import CommaNum from 'components/format/CommaNum';
import Grid3Body from 'components/layouts/body/Grid3Body';
import Nav from 'components/nav/Nav';
import Paging from 'components/paging/Paging';
import ProcessResultModal from 'pages/qualityControl/_old/modal/ProcessResultModal';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import StatusButton from 'components/buttons/StatusButton';
import TableSection from 'components/layouts/table/TableSection';
import { LoadingMsg } from 'components/loading/LoadingMsg';

const MainContent = styled.div``;

const ResultButton = styled.div`
  align-items: center;
  background-color: var(--Violet);
  border: 4px solid var(--white);
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  font-size: 20px;
  font-weight: 600;
  min-height: 100px;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const GSQualityControl = () => {
  /* ========================================================================= #1 */
  const { userReducer, searchReducer, pageReducer } = useSelector(
    (state) => state,
  );
  const dispatch = useDispatch();

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_workOrderLogList, setWorkOrderLogList] = useState([]);
  const [_modalContent, setModalContent] = useState([]);

  const [_ProcessResultModalStatus, setProcessResultModalStatus] =
    useState(false);

  const [_authority, setAuthority] = useState([]);
  console.log('_authority : ', _authority);

  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter(
      (thisItem) => thisItem.authority.startsWith('601') === true,
    );
    const authorityCodes = authorityContent.map(
      (thisItem) => thisItem.authority,
    );
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    setOnload('loaded');

    if (searchReducer.workStatus !== 'end') {
      return dispatch(searchReducer_setWorkStatus('end'));
    }
    setProcessTable();

    return () => {};
  }, []);

  useEffect(() => {
    dispatch(pageReducer_setPageNum(1));
    dispatch(pageReducer_setPageSize(10));

    return () => {};
  }, [pageReducer.currentPage]);

  useEffect(() => {
    if (_onload === 'loaded') {
      setProcessTable();
    }

    return () => {};
  }, [pageReducer.currentPageNum, pageReducer.totalCount]);

  useEffect(() => {
    if (_onload === 'loaded') {
      if (searchReducer.searchOption === '') {
        dispatch(pageReducer_setPageNum(1));
        return setProcessTable();
      }
      setProcessTable();
    }

    return () => {};
  }, [searchReducer.workStatus]);

  useEffect(() => {
    if (_onload === 'loaded') {
      if (searchReducer.searchOption === '') {
        setProcessTable();
      }
    }

    return () => {};
  }, [searchReducer.searchOption]);

  /* ========================================================================= #4 */
  const setDateFormat = (thisDate) => {
    if (
      thisDate !== undefined &&
      thisDate !== null &&
      typeof thisDate === 'object'
    ) {
      const newtDate = new Date(thisDate.join('-'));
      return moment(newtDate).format('YYYY-MM-DD');
    } else {
      return thisDate;
    }
  };

  const setProcessTable = async () => {
    const BodyToPost = {};
    if (searchReducer.workStatus !== '') {
      if (searchReducer.workStatus === 'all') {
        BodyToPost.workOrderStatus = ['end'];
      } else {
        BodyToPost.workOrderStatus = [searchReducer.workStatus];
      }
    } else {
      return dispatch(searchReducer_setWorkStatus('end'));
    }
    if (searchReducer.searchOption !== '') {
      BodyToPost[searchReducer.searchOption] = searchReducer.searchText;
    }
    if (searchReducer.startDate !== '' && searchReducer.endDate !== '') {
      BodyToPost.scheduledStartDate = moment(searchReducer.startDate).format(
        'YYYY-MM-DD',
      );
      BodyToPost.scheduledEndDate = moment(searchReducer.endDate).format(
        'YYYY-MM-DD',
      );
    }
    // 시작날짜가 종료날짜보다 클 때
    if (searchReducer.startDate > searchReducer.endDate) {
      const reverseStartDate = new Date(searchReducer.startDate);
      reverseStartDate.setDate(reverseStartDate.getDate() + 1);
      const setReverseStartDate = reverseStartDate.toISOString();

      const reverseEndDate = new Date(searchReducer.endDate);
      const setReversetEndDate = reverseEndDate.toISOString();

      BodyToPost.scheduledStartDate = setReversetEndDate;
      BodyToPost.scheduledEndDate = setReverseStartDate;
    }

    console.log('BodyToPost.workStatus: ', BodyToPost.workStatus);
    console.log('setProcessTable-body : ', BodyToPost.searchOption);

    searchAllWorkOrderLog(BodyToPost);
  };

  /* 작업지시 불러오기 */
  const searchAllWorkOrderLog = async (BodyToPost) => {
    const paging = `?page=${pageReducer.currentPageNum - 1}&size=${
      pageReducer.pageSize
    }&sort=id,DESC`;
    console.log(AXIOS_BASE_URL + '/workOrderLog/search' + paging, BodyToPost);

    await axios
      .post(
        AXIOS_BASE_URL + '/workOrderLog/search' + paging,
        {
          ...BodyToPost,
          companyId: userReducer.company.companyId,
        },
        {
          headers: {
            Authorization: getCookie('accessToken'),
          },
        },
      )
      .then((response) => {
        if (response === undefined) return;
        if (response.status === 200) {
          // if(response.data.data.totalElements === 0)
          //   alert('검색 결과가 없습니다.');
        }
        console.log('post/workOrderLog/search - response : ', response);
        Finish_searchAllWorkOrderLog(response.data.data);
        dispatch(pageReducer_setTotalCount(response.data.data.totalElements));
      })
      .catch((error) => {
        console.log('post/workOrderLog/search - error : ', error);
      });
  };
  const Finish_searchAllWorkOrderLog = (Result) => {
    const workOrderList = Result.content;
    // if(workOrderList.length === 0){
    //   alert('검색 결과가 없습니다. 조건을 다시 한번 확인 해주세요.')
    // }
    console.log('post/workOrderLog/search - content : ', workOrderList);
    setWorkOrderLogList(workOrderList);
  };
  console.log('_workOrderLogList: ', _workOrderLogList);

  /* 완료, 취소인 작업지시서만 담기 */
  const workOrderStatusList = _workOrderLogList.filter(
    (thisData) =>
      thisData.workOrderStatus === 'end' ||
      thisData.workOrderStatus === 'cancel',
  );
  console.log('workOrderStatusList: ', workOrderStatusList);

  /* ========================================================================= #5 */
  /* 검색 */
  const actSearch = async () => {
    dispatch(pageReducer_setPageNum(1));
    dispatch(pageReducer_setPageSize(10));

    await setProcessTable();
  };

  /* 공정결과 모달 */
  const showProcessResultModal = (e) => {
    const thisWorkOrderId = Number(
      e.target.closest('tr[name="workOrderRow"]').dataset.key,
    );
    const thisFindIndex = workOrderStatusList.findIndex(
      (thisItem) => thisItem.workOrderLogId === thisWorkOrderId,
    );
    const thisWorkOrder = workOrderStatusList[thisFindIndex];
    console.log('thisWorkOrder: ', thisWorkOrder);

    setModalContent(thisWorkOrder);

    dispatch(YoungContentsReducer_setWorkOrderContent(thisWorkOrder));
    setProcessResultModalStatus(true);
  };

  /* ========================================================================= #6 */

  return (
    <Grid3Body
      contents={
        <>
          <main className="Main">
            <Nav
              thisTitle={'품질관리'}
              content={''}
              nav={'search'}
              search={'status/date'}
              searchButton={
                <button id="searchButton" onClick={actSearch}>
                  <SearchButtonIcon />
                </button>
              }
              searchOptions={[
                { optionValue: 'accountName', optionText: '거래처' },
              ]}
              statusOptions={[
                { optionValue: 'end', optionText: '완료' },
                { optionValue: 'cancel', optionText: '취소' },
              ]}
            />

            <TableSection
              content={
                <MainContent>
                  <table className='gs-table'>
                    <thead>
                      <tr>
                        <th
                          style={{
                            minWidth: '100px',
                            padding: 'unset',
                            width: '100px',
                          }}
                        >
                          작업상태
                        </th>
                        <th>거래처</th>
                        <th>작업코드</th>
                        <th>공장명</th>
                        <th>현장명</th>
                        <th>공사기간</th>
                        <th>생산수량</th>
                        <th>성공수량</th>
                        <th>불량수량</th>
                        {/* <th>납기일</th> */}
                        <th
                          style={{
                            minWidth: '150px',
                            padding: 'unset',
                            width: '150px',
                          }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                      workOrderStatusList.length == 0 ? LoadingMsg() :
                      workOrderStatusList.map((thisResult) => {
                        const processCustomizedContent = JSON.parse(
                          thisResult.workOrderCustomizedContent,
                        );
                        console.log(
                          'processCustomizedContent: ',
                          processCustomizedContent,
                        );

                        return (
                          <tr
                            key={thisResult.workOrderLogId}
                            data-key={thisResult.workOrderLogId}
                            name="workOrderRow"
                          >
                            <td
                              style={{
                                minWidth: '60px',
                                padding: 'unset',
                                width: '60px',
                              }}
                            >
                              <StatusButton
                                workOrderCode={thisResult.workOrderLogId}
                                status={thisResult.workOrderStatus}
                                statusStyle={{
                                  cursor: 'default',
                                  minHeight: '100px',
                                }}
                              />
                            </td>
                            <td>{thisResult.accountName}</td>
                            <td>{thisResult.workOrderCode}</td>
                            <td>{thisResult.workOrderName}</td>
                            <td>{thisResult.detailedWorkPlace}</td>
                            <td>
                              {setDateFormat(
                                thisResult.workOrderScheduledStartDate,
                              )}
                              <br /> ~ <br />
                              {setDateFormat(
                                thisResult.workOrderScheduledEndDate,
                              )}
                            </td>
                            <td>
                              <CommaNum
                                displayType={'text'}
                                num={thisResult.amount}
                              />
                            </td>
                            <td>
                              <CommaNum
                                displayType={'text'}
                                num={thisResult.successfulAmount}
                              />
                            </td>
                            <td>
                              <CommaNum
                                displayType={'text'}
                                num={thisResult.failedAmount}
                              />
                            </td>
                            {/* <td></td> */}
                            <td
                              style={{
                                minWidth: '150px',
                                padding: 'unset',
                                width: '150px',
                              }}
                            >
                              <ResultButton
                                onClick={showProcessResultModal}
                                style={{ cursor: 'pointer' }}
                              >
                                불량데이터
                              </ResultButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </MainContent>
              }
            ></TableSection>
          </main>

          <Paging
            page={pageReducer.currentPageNum}
            count={pageReducer.totalCount}
            size={pageReducer.pageSize}
          />

          {_ProcessResultModalStatus === true && (
            <ProcessResultModal
              open={_ProcessResultModalStatus}
              close={() => {
                setProcessResultModalStatus(false);
              }}
            />
          )}
        </>
      }
    />
  );
};

export default GSQualityControl;
