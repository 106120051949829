import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { mallUserApi } from 'api/apis/mallUserApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import Form from 'components/layouts/form/Form';
import MallBody from 'components/layouts/body/MallBody';
import NavBar from 'components/nav/NavBar';

const MainSection = styled.main`
  & input:disabled, & textarea:disabled {
    background-color: var(--Bg);
    border: none;
    color: var(--Text);
    outline: none;
    resize: none;
  }
  & input[name='password'] {
    border-color: var(--MainRed);
    color: var(--MainRed);
    &::-webkit-input-placeholder {color: var(--MainRed);}
  }
`;

const UpdateMallUser = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();
  const updateContent = JSON.parse(searchParams.get('updateContent'));
  const {companyId, id, name, email, phoneNumber, zip, address, memo, } = updateContent;

  const [_formData, setFormData] = useState({
    role: 'ROLE_MALL',
    companyId: companyId,
    id: id,
    name: checkEmptyNull(name, ''),
    email: checkEmptyNull(email, ''),
    phoneNumber: checkEmptyNull(phoneNumber, ''),
    zip: checkEmptyNull(zip, ''),
    address: checkEmptyNull(address, ''),
    memo: checkEmptyNull(memo, ''),
    password: '',
  });

  /* ====================================================================== #3 */
  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {return { ...prev, [name]: value };});
  };

  /* 수정 */
  const actUpdate = async (e) => {
    e.preventDefault();
    await mallUserApi.updateMallUser(id, _formData).then((response) => {
      if (response === undefined) return;
      console.log('mallUserApi.updateMallUser : ', response);
      alert('고객정보를 수정했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  };

  return (
    <MallBody contents={
      <MainSection className="Main">
        <NavBar title={'고객관리'} nav={''} />

        <Form
          title={<>고객정보<br />수정</>}
          buttons={
            <>
              <button className="formButton" onClick={actUpdate}>저장</button>
              <button className="formButton cancle"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(pageReducer.currentPage, { replace: true });
                }}
              >
                취소
              </button>
            </>
          }
          forms={
            <>
              <div className="formBox">
                <h4>이름</h4>
                <input
                  type="text"
                  name="name"
                  disabled
                  placeholder="이름..."
                  value={_formData.name}
                />
              </div>
              <div className="formBox">
                <h4>이메일</h4>
                <input
                  type="text"
                  name="email"
                  disabled
                  placeholder="이메일..."
                  value={_formData.email}
                />
              </div>
              <div className="formBox">
                <h4>연락처</h4>
                <input
                  type="text"
                  name="phoneNumber"
                  disabled
                  placeholder="연락처..."
                  value={_formData.phoneNumber}
                  onInput={handleInputEvent}
                />
              </div>
              <div className="formBox">
                <h4>우편번호</h4>
                <input
                  type="text"
                  name="zip"
                  disabled
                  placeholder="우편번호..."
                  value={_formData.zip}
                  onInput={handleInputEvent}
                />
              </div>
              <div className="formBox">
                <h4>주소</h4>
                <textarea
                  type="text"
                  name="address"
                  disabled
                  placeholder="주소..."
                  value={_formData.address}
                  onInput={handleInputEvent}
                />
              </div>

              <div className="formBox">
                <h4>메모</h4>
                <textarea
                  type="text"
                  name="memo"
                  placeholder="* 메모..."
                  value={_formData.memo}
                  onInput={handleInputEvent}
                />
              </div>

              {userReducer.user.role === 'ROLE_MASTER' ? (
                <div className="formBox">
                  <h4>비밀번호</h4>
                  <input
                    type="password"
                    name="password"
                    placeholder="* 비밀번호..."
                    value={_formData.password}
                    onInput={handleInputEvent}
                    onMouseOver={(e) => {
                      e.stopPropagation();
                      e.target.type = 'text';
                    }}
                    onMouseOut={(e) => {
                      e.stopPropagation();
                      e.target.type = 'password';
                    }}
                  />
                </div>
              ) : null}
            </>
          }
        />
      </MainSection>
    }
    />
  );
};

export default UpdateMallUser;
