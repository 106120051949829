import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { category } from 'api/apis/mall/category';

import { checkEmptyNull, checkNullObject } from 'components/checkValues/checkValues';
import MallBody from 'components/layouts/body/MallBody';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const MainContents = styled.div`
  box-sizing: border-box;
  column-gap: 10px;
  display: flex;
  height: inherit;
  justify-content: flex-start;
  overflow-x: auto;
  padding: 10px;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px; }
  &::-webkit-scrollbar-track {background-color: var(--gray-100);  }
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px; }
`;

const Content = styled.div`
  background-color: var(--white);
  border: 1px solid var(--gray-300);
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 40px 40px auto;
  height: 100%;
  min-width: 400px;
  padding: 10px;
  row-gap: 10px;
  width: 100%;

  .TableSection {
    tbody tr:hover {
      background-color: ${(props) => props.isLevel3 ? 'var(--white)' : 'var(--gray-200)'};
      cursor: pointer;
      opacity: 0.8;
    }
    tbody[data-level='3'] tr:hover {
      background-color: var(--white);
      cursor: default;
      opacity: unset;
    }
  }
`;

const ContentMallManagementHeader = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`;
const Title = styled.h1`
  font-size: 20px;
`;
const SearchBar = styled.div`
  align-items: center;
  border-radius: 10px;
  column-gap: 10px;
  display: grid;
  grid-template-columns: calc(100% - 60px) 50px;
  input {
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    padding: 10px 15px;
    width: 100%;
  }
`;
const Buttons = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  height: 100%;
  justify-content: center;
`;

const CategoryLevel1 = (props) => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');
  const [_categoryList, setCategoryList] = useState([]);
  const [_checkedItem, setCheckedItem] = useState(new Set());
  const [_searchText, setSearchText] = useState('');

  /* ====================================================================== #3 */
  useEffect(() => {
    getCategoryLevel1List();
    setOnload('loaded');
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const getCategoryLevel1List = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      mallCategoryLevel: 1,
    };
    if (checkEmptyNull(_searchText, false)) BodyToPost.mallCategoryName = _searchText;
    await category.searchMallCategory(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('category.searchMallCategory : ', response);
      setCategoryList(() => { return response.data; });
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(id);
    setCheckedItem(() => { return checkedItem; });
  };

  /* 검색 */
  const actSearch = () => {
    getCategoryLevel1List();
  };

  /* 추가 */
  const actCreate = async () => {
    const promptText = `추가할 카테고리 이름을 입력해주세요.`;
    const CreatePrompt = window.prompt(promptText, '');
    if (!checkEmptyNull(CreatePrompt, false)) return;

    const BodyToPost = {
      companyId: userReducer.company.companyId,
      mallCategoryLevel: 1,
      mallCategoryName: CreatePrompt,
      mallCategoryParentCode: 0,
      mallCategoryParentLevel: 0,
    };
    await category.createMallCategory(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('category.createMallCategory : ', response);
      if (response.data === '') return alert(`${CreatePrompt} 카테고리가 이미 존재합니다.`);
      else setCategoryList((prev) => { return [...prev, response.data]; });
    });
  };

  /* 수정 */
  const actUpdate = async (e) => {
    const updateContentIndex = _categoryList.findIndex((thisItem) => thisItem.mallCategoryId === Array.from(_checkedItem)[0]);
    if (updateContentIndex === -1) return alert('수정할 카테고리를 선택해 주세요.');
    const updateContent = _categoryList[updateContentIndex];
    console.log('updateContent : ', updateContent);

    const promptText = `카테고리 이름을 수정합니다.`;
    const UpdatePrompt = window.prompt(promptText, updateContent.mallCategoryName);
    if (UpdatePrompt === null) return;

    const BodyToPut = {
      companyId: userReducer.company.companyId,
      mallCategoryParentCode: 0,
      mallCategoryParentLevel: 0,
      mallCategoryLevel: 1,
      mallCategoryId: updateContent.mallCategoryId,
      mallCategoryCode: updateContent.mallCategoryCode,
      mallCategoryName: UpdatePrompt,
    };
    await category.updateMallCategory(updateContent.mallCategoryId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('category.updateMallCategory : ', response);
      if (response.data === '') return alert(`${UpdatePrompt} 카테고리가 이미 존재합니다.`);
      else {
        setCategoryList((prev) => {
          const prevData = [...prev];
          prevData.splice(updateContentIndex, 1, response.data);
          return prevData;
        });
        alert('카테고리를 수정했습니다.');
      }
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const deleteContentIndex = _categoryList.findIndex((thisItem) => thisItem.mallCategoryId === Array.from(_checkedItem)[0]);
    if (deleteContentIndex === -1) return alert('삭제할 카테고리를 선택해 주세요.');
    const deleteContent = _categoryList[deleteContentIndex];
    console.log('deleteContent : ', deleteContent);
    navigate({
      pathname: '/mallManagement/category/delete',
      search: `?${createSearchParams(deleteContent)}`,
      replace: true,
    });
  };

  return (
    <Content>
      <ContentMallManagementHeader>
        <Title>카테고리 1</Title>

        <Buttons>
          <button className='btn-add' onClick={actCreate}>추가</button>
          <button className='btn-edit' onClick={actUpdate}>수정</button>
          <button className='btn-delete' onClick={actDelete}>삭제</button>
        </Buttons>
      </ContentMallManagementHeader>

      <SearchBar>
        <input placeholder="카테고리 1..." onInput={(e) => { setSearchText(() => { return e.target.value; }); }} />
        <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
      </SearchBar>

      <TableSection content={
        <table>
          <thead>
            <tr>
              <th style={{ minWidth: '50px', width: '50px' }}></th>
              <th>카테고리 이름</th>
            </tr>
          </thead>
          <tbody data-level="1">
            {_categoryList.map((category, index) => {
              return (
                <tr
                  key={index + '_category_1'}
                  style={props.clickedLevel1Category && props.clickedLevel1Category.mallCategoryId === category.mallCategoryId ? { backgroundColor: 'var(--gray-200)' } : {}}
                  onClick={(e) => { props.setClickedLevel1Category(() => { return category; }); }}
                >
                  <td style={{ minWidth: '50px', width: '50px' }}>
                    <input
                      type="checkBox"
                      name="categories_Level1"
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        handleCheckedItem(category.mallCategoryId, isChecked);
                        if (isChecked) {
                          document.getElementsByName('categories_Level1').forEach((thisAttr) => { thisAttr.checked = false; });
                          e.target.checked = true;
                        } else {
                          e.target.checked = false;
                        }
                      }}
                      onClick={(e) => { e.stopPropagation(); }}
                    />
                  </td>
                  <td>{category.mallCategoryName}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      }
      ></TableSection>
    </Content>
  );
};

const CategoryLevel2 = (props) => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_clickedLevel1Category, setClickedLevel1Category] = useState([]);

  const [_categoryList, setCategoryList] = useState([]);
  const [_checkedItem, setCheckedItem] = useState(new Set());
  const [_searchText, setSearchText] = useState('');

  /* ====================================================================== #3 */
  useEffect(() => {
    setClickedLevel1Category(() => { return props.clickedLevel1Category; });
    setSearchText(() => { return ''; });
  }, [props]);

  useEffect(() => {
    getCategoryLevel2List();
  }, [_clickedLevel1Category]);

  /* ====================================================================== #4 */
  const getCategoryLevel2List = async () => {
    if (!checkNullObject(_clickedLevel1Category, false)) return setCategoryList(() => { return []; });

    const BodyToPost = {
      companyId: userReducer.company.companyId,
      mallCategoryParentLevel: 1,
      mallCategoryParentCode: _clickedLevel1Category.mallCategoryCode,
      mallCategoryLevel: 2,
    };
    if (checkEmptyNull(_searchText, false)) BodyToPost.mallCategoryName = _searchText;
    await category.searchMallCategory(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('category.searchMallCategory : ', response);
      setCategoryList(() => { return response.data; });
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(id);
    setCheckedItem(() => { return checkedItem; });
  };

  /* 검색 */
  const actSearch = () => {
    getCategoryLevel2List();
  };

  /* 추가 */
  const actCreate = async () => {
    if (!checkNullObject(_clickedLevel1Category, false)) return alert('상위 카테고리를 선택해주세요.');

    const promptText = `추가할 카테고리 이름을 입력해주세요.`;
    const CreatePrompt = window.prompt(promptText, '');
    if (CreatePrompt === null) return;

    const BodyToPost = {
      companyId: userReducer.company.companyId,
      mallCategoryParentLevel: 1,
      mallCategoryParentCode: _clickedLevel1Category.mallCategoryCode,
      mallCategoryLevel: 2,
      mallCategoryName: CreatePrompt,
    };
    await category.createMallCategory(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('category.createMallCategory : ', response);
      if (response.data === '') return alert(`${CreatePrompt} 카테고리가 이미 존재합니다.`);
      else setCategoryList((prev) => { return [...prev, response.data]; });
    });
  };

  /* 수정 */
  const actUpdate = async (e) => {
    const updateContentIndex = _categoryList.findIndex((thisItem) => thisItem.mallCategoryId === Array.from(_checkedItem)[0]);
    if (updateContentIndex === -1) return alert('수정할 카테고리를 선택해 주세요.');
    const updateContent = _categoryList[updateContentIndex];
    console.log('updateContent : ', updateContent);

    const promptText = `카테고리 이름을 수정합니다.`;
    const UpdatePrompt = window.prompt(promptText, updateContent.mallCategoryName);
    if (UpdatePrompt === null) return;

    const BodyToPut = {
      companyId: userReducer.company.companyId,
      mallCategoryParentLevel: 1,
      mallCategoryParentCode: _clickedLevel1Category.mallCategoryCode,
      mallCategoryLevel: 2,
      mallCategoryId: updateContent.mallCategoryId,
      mallCategoryCode: updateContent.mallCategoryCode,
      mallCategoryName: UpdatePrompt,
    };
    await category.updateMallCategory(updateContent.mallCategoryId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('category.updateMallCategory : ', response);
      if (response.data === '') return alert(`${UpdatePrompt} 카테고리가 이미 존재합니다.`);
      else {
        setCategoryList((prev) => {
          const prevData = [...prev];
          prevData.splice(updateContentIndex, 1, response.data);
          return prevData;
        });
        alert('카테고리를 수정했습니다.');
      }
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const deleteContentIndex = _categoryList.findIndex((thisItem) => thisItem.mallCategoryId === Array.from(_checkedItem)[0]);
    if (deleteContentIndex === -1) return alert('삭제할 카테고리를 선택해 주세요.');
    const deleteContent = _categoryList[deleteContentIndex];
    console.log('deleteContent : ', deleteContent);
    navigate({
      pathname: '/mallManagement/category/delete',
      search: `?${createSearchParams({ ...deleteContent, categoryParentName: _clickedLevel1Category.mallCategoryName })}`,
      replace: true,
    });
  };

  return (
    <Content>
      <ContentMallManagementHeader>
        <Title>카테고리 2</Title>

        <Buttons>
          <button className='btn-add' onClick={actCreate}>추가</button>
          <button className='btn-edit' onClick={actUpdate}>수정</button>
          <button className='btn-delete' onClick={actDelete}>삭제</button>
        </Buttons>
      </ContentMallManagementHeader>

      <SearchBar>
        <input placeholder="카테고리 2..." value={_searchText} onInput={(e) => { setSearchText(() => { return e.target.value; }); }} />
        <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
      </SearchBar>

      <TableSection content={
        <table>
          <thead>
            <tr>
              <th style={{ minWidth: '50px', width: '50px' }}></th>
              <th>카테고리 이름</th>
            </tr>
          </thead>
          <tbody data-level="2">
            {_categoryList.map((category, index) => {
              return (
                <tr
                  key={index + '_category_2'}
                  style={props.clickedLevel2Category && props.clickedLevel2Category.mallCategoryId === category.mallCategoryId ? { backgroundColor: 'var(--gray-200)' } : {}}
                  onClick={(e) => { props.setClickedLevel2Category(() => { return category; }); }}
                >
                  <td style={{ minWidth: '50px', width: '50px' }}>
                    <input
                      type="checkBox"
                      name="categories_Level2"
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        handleCheckedItem(category.mallCategoryId, isChecked);
                        if (isChecked) {
                          document.getElementsByName('categories_Level2').forEach((thisAttr) => { thisAttr.checked = false; });
                          e.target.checked = true;
                        } else {
                          e.target.checked = false;
                        }
                      }}
                      onClick={(e) => { e.stopPropagation(); }}
                    />
                  </td>
                  <td>{category.mallCategoryName}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      }
      ></TableSection>
    </Content>
  );
};

const CategoryLevel3 = (props) => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_clickedLevel2Category, setClickedLevel2Category] = useState([]);

  const [_categoryList, setCategoryList] = useState([]);
  const [_checkedItem, setCheckedItem] = useState(new Set());
  const [_searchText, setSearchText] = useState('');

  /* ====================================================================== #3 */
  useEffect(() => {
    setClickedLevel2Category(() => { return props.clickedLevel2Category; });
    setSearchText(() => { return ''; });
  }, [props]);

  useEffect(() => {
    getCategoryLevel3List();
  }, [_clickedLevel2Category]);

  /* ====================================================================== #4 */
  const getCategoryLevel3List = async () => {
    if (!checkNullObject(_clickedLevel2Category, false)) return setCategoryList(() => { return []; });

    const BodyToPost = {
      companyId: userReducer.company.companyId,
      mallCategoryParentLevel: 2,
      mallCategoryParentCode: _clickedLevel2Category.mallCategoryCode,
      mallCategoryLevel: 3,
    };
    if (checkEmptyNull(_searchText, false)) BodyToPost.mallCategoryName = _searchText;
    await category.searchMallCategory(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('category.searchMallCategory : ', response);
      setCategoryList(() => { return response.data; });
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(id);
    setCheckedItem(() => { return checkedItem; });
  };

  /* 검색 */
  const actSearch = () => {
    getCategoryLevel3List();
  };

  /* 추가 */
  const actCreate = async () => {
    if (!checkNullObject(_clickedLevel2Category, false)) return alert('상위 카테고리를 선택해주세요.');

    const promptText = `추가할 카테고리 이름을 입력해주세요.`;
    const CreatePrompt = window.prompt(promptText, '');
    if (CreatePrompt === null) return;

    const BodyToPost = {
      companyId: userReducer.company.companyId,
      mallCategoryParentLevel: 2,
      mallCategoryParentCode: _clickedLevel2Category.mallCategoryCode,
      mallCategoryLevel: 3,
      mallCategoryName: CreatePrompt,
    };
    await category.createMallCategory(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('category.createMallCategory : ', response);
      if (response.data === '') return alert(`${CreatePrompt} 카테고리가 이미 존재합니다.`);
      else setCategoryList((prev) => { return [...prev, response.data]; });
    });
  };

  /* 수정 */
  const actUpdate = async (e) => {
    const updateContentIndex = _categoryList.findIndex((thisItem) => thisItem.mallCategoryId === Array.from(_checkedItem)[0]);
    if (updateContentIndex === -1) return alert('수정할 카테고리를 선택해 주세요.');
    const updateContent = _categoryList[updateContentIndex];
    console.log('updateContent : ', updateContent);

    const promptText = `카테고리 이름을 수정합니다.`;
    const UpdatePrompt = window.prompt(promptText, updateContent.mallCategoryName);
    if (UpdatePrompt === null) return;

    const BodyToPut = {
      companyId: userReducer.company.companyId,
      mallCategoryParentLevel: 2,
      mallCategoryParentCode: _clickedLevel2Category.mallCategoryCode,
      mallCategoryLevel: 3,
      mallCategoryId: updateContent.mallCategoryId,
      mallCategoryCode: updateContent.mallCategoryCode,
      mallCategoryName: UpdatePrompt,
    };
    await category.updateMallCategory(updateContent.mallCategoryId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('category.updateMallCategory : ', response);
      if (response.data === '') return alert(`${UpdatePrompt} 카테고리가 이미 존재합니다.`);
      else {
        setCategoryList((prev) => {
          const prevData = [...prev];
          prevData.splice(updateContentIndex, 1, response.data);
          return prevData;
        });
        alert('카테고리를 수정했습니다.');
      }
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const deleteContentIndex = _categoryList.findIndex((thisItem) => thisItem.mallCategoryId === Array.from(_checkedItem)[0]);
    if (deleteContentIndex === -1) return alert('삭제할 카테고리를 선택해 주세요.');
    const deleteContent = _categoryList[deleteContentIndex];
    console.log('deleteContent : ', deleteContent);
    navigate({
      pathname: '/mallManagement/category/delete',
      search: `?${createSearchParams({ ...deleteContent, categoryParentName: _clickedLevel2Category.mallCategoryName })}`,
      replace: true,
    });
  };

  return (
    <Content isLevel3={true}>
      <ContentMallManagementHeader>
        <Title>카테고리 3</Title>

        <Buttons>
          <button className='btn-add' onClick={actCreate}>추가</button>
          <button className='btn-edit' onClick={actUpdate}>수정</button>
          <button className='btn-delete' onClick={actDelete}>삭제</button>
        </Buttons>
      </ContentMallManagementHeader>

      <SearchBar>
        <input placeholder="카테고리 3..." value={_searchText} onInput={(e) => { setSearchText(() => { return e.target.value; }); }} />
        <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
      </SearchBar>

      <TableSection content={
        <table>
          <thead>
            <tr>
              <th style={{ minWidth: '50px', width: '50px' }}></th>
              <th>카테고리 이름</th>
            </tr>
          </thead>
          <tbody data-level="3">
            {_categoryList.map((category, index) => {
              return (
                <tr key={index + '_category_3'}>
                  <td style={{ minWidth: '50px', width: '50px' }}>
                    <input
                      type="checkBox"
                      name="categories_Level3"
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        handleCheckedItem(category.mallCategoryId, isChecked);
                        if (isChecked) {
                          document.getElementsByName('categories_Level3').forEach((thisAttr) => { thisAttr.checked = false; });
                          e.target.checked = true;
                        } else {
                          e.target.checked = false;
                        }
                      }}
                    />
                  </td>
                  <td>{category.mallCategoryName}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      }
      ></TableSection>
    </Content>
  );
};

const MallCategoryManagement = () => {
  const dispatch = useDispatch();

  const [_onload, setOnload] = useState('unload');

  const [_clickedLevel1Category, setClickedLevel1Category] = useState({});
  const [_clickedLevel2Category, setClickedLevel2Category] = useState({});
  const [_clickedLevel3Category, setClickedLevel3Category] = useState({});

  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));
    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => { setClickedLevel2Category(() => { return {}; }); }, [_clickedLevel1Category]);
  useEffect(() => { setClickedLevel3Category(() => { return {}; }); }, [_clickedLevel2Category]);
  useEffect(() => { }, [_clickedLevel3Category]);

  return (
    <MallBody contents={
      <main className="Main">
        <MainContents>
          <CategoryLevel1
            clickedLevel1Category={_clickedLevel1Category}
            setClickedLevel1Category={setClickedLevel1Category}
          ></CategoryLevel1>

          <CategoryLevel2
            clickedLevel1Category={_clickedLevel1Category}
            clickedLevel2Category={_clickedLevel2Category}
            setClickedLevel2Category={setClickedLevel2Category}
          ></CategoryLevel2>

          <CategoryLevel3
            clickedLevel2Category={_clickedLevel2Category}
            clickedLevel3Category={_clickedLevel3Category}
            setClickedLevel3Category={setClickedLevel3Category}
          ></CategoryLevel3>
        </MainContents>
      </main>
    } />
  );
};

export default MallCategoryManagement;
