import styled from 'styled-components';

export const FileModalComponent = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100svh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100svw;
  z-index: 999;

  &>div { // modal 레이아웃
    background-color: var(--white);
    border-radius: 10px;
    display: grid;
    grid-template-rows: 50px calc(100% - 50px);
    height: 85%;
    min-height: 300px;
    width: 85%;

    &.create {max-height: 600px; max-width: 450px;}
    &.update {max-height: 300px; max-width: 400px;}
    &.delete {max-height: 300px; max-width: 400px;}
    &.stl {height: 100%; width: 100%;}

    & header { // modal 헤더
      align-items: center;
      box-sizing: border-box;
      display: flex;
      height: 50px;
      justify-content: space-between;
      padding-inline: 20px;
      width: 100%;
    }

    & section { // modal content
      box-sizing: border-box;
      height: 100%;
      padding: 30px;
      width: 100%;

      &.create-content, &.update-content {
        display: grid;
        gap: 10px;
        grid-template-rows: calc(100% - 70px) 60px;

        &>div {
          align-content: flex-start;
          display: grid;
          gap: 20px;
          height: 100%;
          overflow-y: auto;
          width: 100%;

          &>div {
            display: grid;
            gap: 5px;
            grid-template-columns: 100px calc(100% - 105px);
            
            & select, & input, & textarea { // 파일 정보 입력
              border: 1px solid var(--gray-200);
              border-radius: 8px;
              box-sizing: border-box;
              height: 35px;
              padding-inline: 10px;
              width: 100%;

              &:disabled {
                background-color: var(--Bg);
              }
            }
            & textarea {
              height: 80px;
            }
          }
        }
        & button.btn-save { // 저장 버튼
          background: linear-gradient(to right, #001d49, #0046af);
          height: 100%;
          width: 100%;
        }
      }

      &.create-content {
        &>div>div:nth-last-of-type(1) { // 파일 이벤트
          grid-template-columns: 100%;
        }
      }
      
      &.delete-content {
        display: grid;
        gap: 10px;
        grid-template-rows: calc(100% - 70px) 60px;

        &>div {
          align-content: flex-start;
          display: grid;
          gap: 20px;
          height: 100%;
          overflow-y: auto;
          width: 100%;

          &>div {
            display: grid;
            gap: 5px;
            grid-template-columns: 100px calc(100% - 105px);
            
            & input{ // 파일 정보
              border: 1px solid var(--gray-200);
              border-radius: 8px;
              box-sizing: border-box;
              height: 35px;
              padding-inline: 10px;
              width: 100%;

              &:disabled {
                background-color: var(--Bg);
              }
            }
          }
        }
        & button.btn-save { // 저장 버튼
          background: linear-gradient(to right, #240000, #bb0f0f);
          height: 100%;
          width: 100%;
        }
      }

      &.stl-content {
        position: relative;
        &>p {
          color: var(--MainBlue);
          /* font-size: 0.8em; */
          position: absolute;
          right: 20px;
          top: 0px;
        }
      }
    }
  }
`;

export const LoadingScreen = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 999;
  
  & p {
    animation: opacityAni 1.5s infinite;
    color: var(--white);
    font-size: 1.5em;
    font-weight: 500;

    @keyframes opacityAni {
      0% {opacity: 0;}
      50% {opacity: 1;}
      100% {opacity: 0;}
    }
  }
`;