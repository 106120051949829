import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { userApi } from 'api/apis/userApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import { checkPw } from 'components/checkValues/sign';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  max-height: 500px;
  max-width: 800px;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  form {
    &>div {background: none;}
  }
  .formBox.password {
    width: 100%;

    input {
      width: fit-content;
      &[data-status='false'] {
        border-color: var(--MainRed);
        color: var(--MainRed);
        &::-webkit-input-placeholder {color: var(--MainRed);}
        &:focus {border-color: var(--MainRed); color: var(--MainRed);}
      }
    }

    p {
      &[data-status='false'] {
        color: var(--MainRed);
        &:focus {color: var(--MainRed);}
      }
    }
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const UpdatePassword = (props) => {
  const { userReducer } = useSelector((state) => state);

  const [_pwStatus, setPwStatus] = useState(false);
  const [_pw, setPw] = useState('');
  const [_confirmPw, setConfirmPw] = useState('');

  useEffect(() => { checkPassword(); return () => { } }, [_pw, _confirmPw]);

  const checkPassword = () => {
    setPwStatus(() => {return checkPw(_pw, _pw);})
  }

  const actUpdate = async() => {
    if (checkEmptyNull(_pw, false) && _pw.length > 0 && _pw.length < 8) return alert('비밀번호를 확인해주세요.');
    if (checkEmptyNull(_pw, false) && _pw.length > 0 && !_pwStatus) return alert('비밀번호를 확인해주세요.');

    const reg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
    if (checkEmptyNull(_pw, false) && !reg.test(_pw)) return alert('비밀번호는 최소 8자리 이상 영문 + 숫자 + 특수기호 조합으로 등록하셔야 합니다.');

    const body = {password: _pw}
    await userApi.updatePassword(userReducer.user.id, body).then(response => {
      if(response === undefined) return;
      console.log('userApi.updatePassword : ', response);
      alert('비밀번호를 변경했습니다.');
      props.close();
    })
  };

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>비밀번호 변경</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <Form
                title={<>비밀번호<br />변경</>}
                buttons={<></>}
                forms={
                  <>
                    <div className='formBox password'>
                      <h4>새 비밀번호</h4>
                      <input required
                        type="password" name="password" placeholder="새 비밀번호"
                        data-status={_pwStatus} value={_pw} minLength={8}
                        onInput={(e) => {setPw(() => { return e.target.value; });}}
                        onMouseOver={(e) => { e.stopPropagation(); e.target.type = 'text'; }}
                        onMouseOut={(e) => { e.stopPropagation(); e.target.type = 'password'; }}
                      />
                    </div>
                    <div className='formBox password'>
                      <h4>새 비밀번호 확인</h4>
                      <input required
                        type="password" name="confirmPassword" placeholder="새 비밀번호 확인"
                        data-status={_pwStatus} value={_confirmPw} minLength={8}
                        onInput={(e) => {setConfirmPw(() => { return e.target.value; });}}
                        onMouseOver={(e) => { e.stopPropagation(); e.target.type = 'text'; }}
                        onMouseOut={(e) => { e.stopPropagation(); e.target.type = 'password'; }}
                      />
                      <p data-status={_pwStatus}>비밀번호 변경은 최소 8자리 이상 <span>영문 + 숫자 + 특수기호</span> 조합으로 등록해주세요.</p>
                    </div>
                  </>
                }
              />
            </ModalMain>

            <ModalFooter>
              <ModalButton onClick={actUpdate}>변경</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default UpdatePassword;
