import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { productionStatusActions_setEndDate, productionStatusActions_setPageNumber, productionStatusActions_setReset, productionStatusActions_setSearchData, productionStatusActions_setSearchOption, productionStatusActions_setSearchText, productionStatusActions_setStartDate, productionStatusActions_setTotalSize, productionStatusActions_setWorkOrderStatus, productionStatusActions_setWorkOrderType } from 'store/modules/actions/common/productionStatusActions';

import { qualityApi } from 'api/apis/qualityApi';
import { workOrderTypeApi } from 'api/apis/workOrderTypeApi';

import { checkEmptyNull, checkNull, checkNullArray } from 'components/checkValues/checkValues';

import { DateFormat } from 'components/format/DateFormat';
import { LoadingMsg } from 'components/loading/LoadingMsg';

import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import ProductionProcessTracking from 'pages/qualityControl/modal/ProductionProcessTracking';
import QualityRow from 'components/row/QualityRow';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import ShowFileList from 'pages/file/modal/ShowFileList';
import TableSection from 'components/layouts/table/TableSection';

const Section = styled.main`
  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
    & .statusButtons {
      display: flex;
      gap: 5px;
    }
  }

  .TableSection {
    thead tr {border: none;}
    td {height: 100px;}
  }
`;

const ProductionStatus = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const { userReducer, productionStatusReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_workOrderLogList, setWorkOrderLogList] = useState([]);

  const [_workOrderTypes, setWorkOrderTypes] = useState([]);

  const [_modalStatus, setModalStatus] = useState(false);
  const [_eventWorkOrderLogId, setEventWorkOrderLogId] = useState();

  const [_fileListModalStatus, setFileListModalStatus] = useState(false);
  const [_eventcontent, setEventContent] = useState({});

  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('104') === true || // 거래처
      thisItem.authority.startsWith('109') === true || // 파일
      thisItem.authority.startsWith('405') === true,
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getWorkOrderTypes();
    getWorkOrderLogList(productionStatusReducer.pageNumber - 1, productionStatusReducer.workOrderType, productionStatusReducer.workOrderStatus)

    setOnload('loaded');

    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getWorkOrderLogList(productionStatusReducer.pageNumber - 1, productionStatusReducer.workOrderType, productionStatusReducer.workOrderStatus)
    }

    return () => { };
  }, [productionStatusReducer.pageNumber]);

  /* ========================================================================= #4 */
  const getWorkOrderTypes = async () => {
    const BodyToPost = { companyId: userReducer.company.companyId };
    await workOrderTypeApi.searchWorkOrderType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderTypeApi.searchWorkOrderType : ', response);
      setWorkOrderTypes(() => { return response.data; });
    });
  };

  const getWorkOrderLogList = async (page, workOrderType, workOrderStatus, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = { companyId: userReducer.company.companyId };
    if (!clear && checkEmptyNull(workOrderType, false) && workOrderType !== 'all') {
      BodyToPost.workOrderTypeId = Number(workOrderType);
    }
    if (!clear && checkEmptyNull(workOrderStatus, false)) {
      switch (workOrderStatus) {
        case 'all': BodyToPost.workOrderStatus = ['wait', 'work', 'pause', 'stop']; break;
        case 'waitNwork': BodyToPost.workOrderStatus = ['wait', 'work']; break;
        default: BodyToPost.workOrderStatus = [workOrderStatus]; break;
      }
    }
    if (!clear && checkEmptyNull(productionStatusReducer.startDate, false) && checkEmptyNull(productionStatusReducer.endDate)) {
      BodyToPost.scheduledStartDate = DateFormat(productionStatusReducer.startDate);
      BodyToPost.scheduledEndDate = DateFormat(productionStatusReducer.endDate);
      if (productionStatusReducer.startDate > productionStatusReducer.endDate) {
        BodyToPost.scheduledStartDate = DateFormat(productionStatusReducer.endDate);
        BodyToPost.scheduledEndDate = DateFormat(productionStatusReducer.startDate);
      }
    } else if (!clear && checkEmptyNull(productionStatusReducer.startDate, false)) {
      BodyToPost.scheduledStartDate = DateFormat(productionStatusReducer.startDate);
    } else if (!clear && checkEmptyNull(productionStatusReducer.endDate)) {
      BodyToPost.scheduledEndDate = DateFormat(productionStatusReducer.endDate);
    }
    if (!clear && checkEmptyNull(productionStatusReducer.searchData.workOrderCode, false)) {
      BodyToPost.workOrderCode = productionStatusReducer.searchData.workOrderCode;
    }
    if (!clear && checkEmptyNull(productionStatusReducer.searchData.workOrderName, false)) {
      BodyToPost.workOrderName = productionStatusReducer.searchData.workOrderName;
    }
    await qualityApi.searchQualityWorkOrderLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('qualityApi.searchQualityWorkOrderLog : ', response);

      setWorkOrderLogList(() => { return response.data.content; });
      dispatch(productionStatusActions_setTotalSize(response.data.totalElements));

      if (!clear && checkEmptyNull(productionStatusReducer.searchOption) && checkEmptyNull(productionStatusReducer.searchText)) {
        if (productionStatusReducer.startDate > productionStatusReducer.endDate) {
          dispatch(productionStatusActions_setStartDate(productionStatusReducer.endDate))
          dispatch(productionStatusActions_setEndDate(productionStatusReducer.startDate))
        }
      }
    });
  };

  /* ========================================================================= #5 */
  /* 검색 - 작업상태 */
  const handleSearchWorkOrderStatus = async (workOrderStatus) => {
    dispatch(productionStatusActions_setWorkOrderStatus(workOrderStatus));
    dispatch(productionStatusActions_setPageNumber(1));
    await getWorkOrderLogList(0, productionStatusReducer.workOrderType, workOrderStatus)
  }

  /* 검색 */
  const actSearch = async () => {
    dispatch(productionStatusActions_setPageNumber(1));
    await getWorkOrderLogList(0, productionStatusReducer.workOrderType, productionStatusReducer.workOrderStatus)
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(productionStatusActions_setReset());
    dispatch(productionStatusActions_setPageNumber(1));
    getWorkOrderLogList(0, 'all', 'all', true);
  };

  /* 생산추적 모달 */
  const showProductionProcessTracking = (eventId) => {
    setEventWorkOrderLogId(eventId);
    setTimeout(setModalStatus(true), 1000);
  };

  const showFileList = (content, work) => {
    setEventContent(() => {
      if (work === true) return { ...content, work: true };
      else return content;
    });

    setTimeout(setFileListModalStatus(true), 1000);
  };

  /* ========================================================================= #6 */

  return (
    <Grid3Body contents={
      <>
        <Section className="Main">
          <NavBar
            title={
              <div className='Title'>
                <NavTitle menuCode={'405'} />
                <div className='statusButtons'>
                  {[
                    { status: 'all', name: '전체' },
                    { status: 'waitNwork', name: '대기/작업중' },
                    { status: 'wait', name: '대기' },
                    { status: 'work', name: '작업중' },
                    { status: 'pause', name: '일시정지' },
                    { status: 'stop', name: '중단' },
                  ].map((workOrderStatus, index) => {
                    return (
                      <button key={index + '_statusButton'}
                        className={productionStatusReducer.workOrderStatus === workOrderStatus.status ? 'btn-inven-1' : 'btn-set'}
                        onClick={() => { handleSearchWorkOrderStatus(workOrderStatus.status); }}
                      >
                        {workOrderStatus.name}
                      </button>
                    )
                  })}
                </div>
              </div>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <select
                    className="SelectType"
                    value={productionStatusReducer.workOrderType}
                    onChange={(e) => {
                      dispatch(productionStatusActions_setWorkOrderType(e.target.value))
                      dispatch(productionStatusActions_setPageNumber(1));
                      getWorkOrderLogList(0, e.target.value, productionStatusReducer.workOrderStatus);
                    }}
                  >
                    <option value="all">유형 전체</option>
                    {_workOrderTypes.map((thisItem) => {
                      return (<option key={thisItem.workOrderTypeId + '_workOrderType'} value={thisItem.workOrderTypeId}>{thisItem.workOrderTypeName}</option>);
                    })}
                  </select>
                  <div className="SearchSection">
                    <input
                      type="date"
                      className="SelectDate"
                      value={productionStatusReducer.startDate}
                      onChange={(e) => { dispatch(productionStatusActions_setStartDate(e.target.value)) }}
                    />
                    <IconRightArrow />
                    <input
                      type="date"
                      className="SelectDate"
                      value={productionStatusReducer.endDate}
                      onChange={(e) => { dispatch(productionStatusActions_setEndDate(e.target.value)) }}
                    />
                  </div>
                </div>
              </>
            }
            secondRow={
              <div className="SearchSection">
                <div className="ContentCBox">
                  <select className="SearchOption detail">
                    <option value="workOrderCode">작업코드</option>
                  </select>
                  <input
                    className="SearchBar detail"
                    placeholder="Search..."
                    value={checkEmptyNull(productionStatusReducer.searchData.workOrderCode, '')}
                    onInput={(e) => {
                      dispatch(productionStatusActions_setSearchData({ ...productionStatusReducer.searchData, workOrderCode: e.target.value }));
                    }}
                  />
                </div>

                <div className="ContentCBox">
                  <select className="SearchOption detail">
                    <option value="workOrderName">작업이름</option>
                  </select>
                  <input
                    className="SearchBar detail"
                    placeholder="Search..."
                    value={checkEmptyNull(productionStatusReducer.searchData.workOrderName, '')}
                    onInput={(e) => {
                      dispatch(productionStatusActions_setSearchData({ ...productionStatusReducer.searchData, workOrderName: e.target.value }));
                    }}
                  />
                </div>

                <div className="ContentCBox">
                  <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                  <div className="ResetButton" onClick={actReset}>초기화</div>
                </div>
              </div>
            }
          />

          <TableSection content={
            <table>
              <thead>
                <tr>
                  <th rowSpan={2} style={{ minWidth: '100px', padding: 'unset', width: '100px' }}></th>
                  <th rowSpan={2} style={{ minWidth: '80px', padding: 'unset', width: '80px' }}>작업<br />상태</th>
                  {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? (<th rowSpan={2}>파일</th>) : null}
                  <th rowSpan={2}>작업유형</th>
                  <th rowSpan={2}>작업코드</th>
                  <th rowSpan={2}>작업이름</th>
                  {(_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) ? (<th rowSpan={2}>거래처</th>) : null}
                  <th colSpan={5} style={{ backgroundColor: 'var(--ThirdBlue)' }}>생산목표품목</th>
                  <th rowSpan={2}>작업위치</th>
                  <th rowSpan={2}>작업기간</th>
                  <th rowSpan={2}>작성자</th>
                  <th rowSpan={2}>책임자</th>
                  <th rowSpan={2}>작업자</th>
                </tr>
                <tr>
                  <th>물품정보</th>
                  <th className="resultProductStyle">목표<br />생산량</th>
                  <th className="resultProductStyle" style={{ backgroundColor: 'var(--MainBlue)' }}>총 생산량</th>
                  <th className="resultProductStyle" style={{ backgroundColor: 'var(--MainGreen)' }}>성공수량</th>
                  <th className="resultProductStyle" style={{ backgroundColor: 'var(--MainRed)' }}>실패수량</th>
                </tr>
              </thead>
              <tbody>
                {!checkNullArray(_workOrderLogList, false) ? LoadingMsg() :
                  checkNullArray(_workOrderLogList, []).map((thisItem, index) => {
                    return (
                      <QualityRow
                        key={index + '_qualityRow'}
                        authority={_authority}
                        content={thisItem}
                        index={index}
                        showFileList={showFileList}
                        showProductionProcessTracking={showProductionProcessTracking}
                      />
                    );
                  })}
              </tbody>
            </table>
          }
          ></TableSection>
        </Section>

        <PagingComponent
          page={productionStatusReducer.pageNumber}
          count={productionStatusReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(productionStatusActions_setPageNumber(page));
          }}
        />

        {_modalStatus === true && (
          <ProductionProcessTracking
            productionId={_eventWorkOrderLogId}
            open={_modalStatus}
            close={() => { setModalStatus(false); }}
          />
        )}

        {_fileListModalStatus === true && checkNull(_eventcontent.workOrderLogId, false) ? (
          <ShowFileList
            type={'workOrderLog'}
            content={_eventcontent}
            open={_fileListModalStatus}
            close={() => { setFileListModalStatus(false); }}
          ></ShowFileList>
        ) : null}
      </>
    }
    />
  );
};

export default ProductionStatus;
