import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';

import {
  pageReducer_getCurrentPage,
  pageReducer_setPageNum,
  pageReducer_setPageSize,
  pageReducer_setTotalCount,
} from 'store/modules/actions/default/pageActions';

import {
  CategoryLevel1,
  CategoryLevel2,
  CategoryLevel3,
  CategoryLevel4,
  CategoryLevel5,
} from 'components/category/Category';

import {
  AXIOS_BASE_HEADERS,
  AXIOS_BASE_URL,
} from 'preferences/server/constants';

import CommaNum from 'components/format/CommaNum';
import ExcelDownloadModal from 'components/excel/ExcelDownloadModal';
import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import Paging from 'components/paging/Paging';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const setDateFormat = (thisDate) => {
  if (
    thisDate !== undefined &&
    thisDate !== null &&
    typeof thisDate === 'object'
  ) {
    const setDate =
      thisDate.length === 3
        ? thisDate.join('-')
        : thisDate.length > 6
          ? `${thisDate[0]}-${thisDate[1]}-${thisDate[2]} ${thisDate[3]}:${thisDate[4]}:${thisDate[5]}`
          : thisDate;
    const newDate = new Date(setDate);
    return moment(newDate).format('YYYY-MM-DD HH:mm:ss');
  } else {
    return thisDate;
  }
};

const UriStockHistory = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_logList, setLogList] = useState([]);

  const [_elementType, setElementType] = useState('all');

  const [_clickedCategory, setClickedCategory] = useState({});
  const [_clickedLevel1Category, setClickedLevel1Category] = useState({});
  const [_clickedLevel2Category, setClickedLevel2Category] = useState({});
  const [_clickedLevel3Category, setClickedLevel3Category] = useState({});
  const [_clickedLevel4Category, setClickedLevel4Category] = useState({});
  const [_clickedLevel5Category, setClickedLevel5Category] = useState({});

  const [_startDate, setStartDate] = useState();
  const [_endDate, setEndDate] = useState();

  const [_searchElementData, setSearchElementData] = useState({});
  const [_searchOption, setSearchOption] = useState('');
  const [_searchText, setSearchText] = useState('');

  const [_excelDownloadModalStatus, setExcelDownloadModalStatus] =
    useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter(
      (thisItem) => thisItem.authority.startsWith('305') === true,
    );
    const authorityCodes = authorityContent.map(
      (thisItem) => thisItem.authority,
    );
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    setOnload('loaded');

    getLogList(0, 'all');

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    dispatch(pageReducer_setPageNum(1));
    dispatch(pageReducer_setPageSize(10));

    return () => { };
  }, [pageReducer.currentPage]);

  useEffect(() => {
    if (_onload === 'loaded') {
      getLogList(pageReducer.currentPageNum - 1);
    }

    return () => { };
  }, [pageReducer.currentPageNum]);

  useEffect(() => {
    if (_onload === 'loaded' && Object.keys(_clickedCategory).length > 0) {
      dispatch(pageReducer_setPageNum(1));
      getLogList(0, _elementType);
    }

    return () => { };
  }, [_clickedCategory]);

  useEffect(() => {
    if (_onload === 'loaded' && Object.keys(_clickedCategory).length > 0) {
      setClickedLevel2Category(() => {
        return {};
      });
    }

    return () => { };
  }, [_clickedLevel1Category]);

  useEffect(() => {
    if (_onload === 'loaded' && Object.keys(_clickedCategory).length > 0) {
      setClickedLevel3Category(() => {
        return {};
      });
    }

    return () => { };
  }, [_clickedLevel2Category]);

  useEffect(() => {
    if (_onload === 'loaded' && Object.keys(_clickedCategory).length > 0) {
      setClickedLevel4Category(() => {
        return {};
      });
    }

    return () => { };
  }, [_clickedLevel3Category]);

  useEffect(() => {
    if (_onload === 'loaded' && Object.keys(_clickedCategory).length > 0) {
      setClickedLevel5Category(() => {
        return {};
      });
    }

    return () => { };
  }, [_clickedLevel4Category]);

  useEffect(() => { }, [_clickedLevel5Category]);

  /* ====================================================================== #4 */
  const getLogList = async (page, elementType, clear) => {
    const paging = `?page=${page}&size=${pageReducer.pageSize}&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && elementType !== undefined && elementType !== 'all') {
      BodyToPost.elementType = elementType;
    }

    if (
      !clear &&
      _startDate !== undefined &&
      _endDate !== undefined &&
      _startDate !== '' &&
      _endDate !== ''
    ) {
      BodyToPost.elementStockLogStartDate = new Date(_startDate).toISOString();
      BodyToPost.elementStockLogEndDate = new Date(_endDate).toISOString();
      if (_startDate > _endDate) {
        BodyToPost.elementStockLogStartDate = new Date(_endDate).toISOString();
        BodyToPost.elementStockLogEndDate = new Date(_startDate).toISOString();
      }
    }
    if (!clear && Object.keys(_clickedCategory).length > 0) {
      BodyToPost.categoryId = _clickedCategory.categoryId;
    }
    if (
      !clear &&
      _searchElementData.elementName !== undefined &&
      _searchElementData.elementName !== null &&
      _searchElementData.elementName !== ''
    ) {
      BodyToPost.elementName = _searchElementData.elementName;
    }
    if (
      !clear &&
      _searchElementData.elementCode !== undefined &&
      _searchElementData.elementCode !== null &&
      _searchElementData.elementCode !== ''
    ) {
      BodyToPost.elementCode = _searchElementData.elementCode;
    }
    // if (
    //   !clear &&
    //   _searchOption !== undefined &&
    //   _searchOption !== null &&
    //   _searchOption !== ''
    // ) {
    //   BodyToPost[_searchOption] = _searchText;
    // }
    console.log('getLogList - BodyToPost : ', BodyToPost);

    await axios
      .post(
        AXIOS_BASE_URL + '/elementStockLog/search' + paging,
        BodyToPost,
        AXIOS_BASE_HEADERS,
      )
      .then((response) => {
        if (response === undefined) return;
        console.log('getLogList - response : ', response);
        setLogList(() => {
          return response.data.content;
        });

        dispatch(pageReducer_setTotalCount(response.data.totalElements));
      })
      .catch((error) => {
        console.log('getLogList - error : ', error);
      });
  };

  /* ====================================================================== #5 */
  /* 검색 */
  const actSearch = async () => {
    dispatch(pageReducer_setPageNum(1));
    await getLogList(0, _elementType);
  };

  /* 초기화 */
  const actReset = async () => {
    setElementType(() => {
      return 'all';
    });
    setStartDate(() => {
      return '';
    });
    setEndDate(() => {
      return '';
    });

    setSearchElementData(() => {
      return {};
    });
    setSearchOption(() => {
      return '';
    });
    setSearchText(() => {
      return '';
    });

    setClickedCategory(() => {
      return {};
    });
    setClickedLevel1Category(() => {
      return {};
    });
    setClickedLevel2Category(() => {
      return {};
    });
    setClickedLevel3Category(() => {
      return {};
    });
    setClickedLevel4Category(() => {
      return {};
    });
    setClickedLevel5Category(() => {
      return {};
    });

    dispatch(pageReducer_setPageNum(1));
    await getLogList(0, 'all', true);
  };

  const actDownload = () => {
    setExcelDownloadModalStatus(true);
  };

  /* ====================================================================== #6 */

  return (
    <Grid3Body
      contents={
        <>
          <main className="Main">
            <NavBar
              title={'재고상세이력'}
              buttons={
                <>
                  <button className='btn-download' onClick={actDownload}>다운로드</button>
                </>
              }
              nav={'all'}
              firstRow={
                <>
                  <div className="SearchSection">
                    <select
                      className="SelectType"
                      value={_elementType}
                      onChange={(e) => {
                        setElementType(() => {
                          return e.target.value;
                        });

                        dispatch(pageReducer_setPageNum(1));
                        getLogList(0, e.target.value);
                      }}
                    >
                      <option value="all">전체</option>
                      {userReducer.elementType.map((thisItem) => {
                        let typeText = '';
                        switch (thisItem) {
                          case 'material':
                            typeText = '자재';
                            break;
                          case 'semi':
                            typeText = '부자재';
                            break;
                          case 'half':
                            typeText = '반제품';
                            break;
                          case 'product':
                            typeText = '완제품';
                            break;
                          case 'tool':
                            typeText = '공구';
                            break;

                          default:
                            return null;
                        }

                        return (
                          <option
                            key={thisItem + '_elementTypes'}
                            value={thisItem}
                          >
                            {typeText}
                          </option>
                        );
                      })}
                    </select>

                    <CategoryLevel1
                      clickedLevel1Category={_clickedLevel1Category}
                      setClickedCategory={setClickedCategory}
                      setClickedLevel1Category={setClickedLevel1Category}
                    ></CategoryLevel1>

                    <IconRightArrow />

                    <CategoryLevel2
                      clickedLevel1Category={_clickedLevel1Category}
                      setClickedCategory={setClickedCategory}
                      setClickedLevel2Category={setClickedLevel2Category}
                    ></CategoryLevel2>

                    <IconRightArrow />

                    <CategoryLevel3
                      clickedLevel2Category={_clickedLevel2Category}
                      setClickedCategory={setClickedCategory}
                      setClickedLevel3Category={setClickedLevel3Category}
                    ></CategoryLevel3>

                    {userReducer.company.companyId === 1 ||
                      userReducer.company.companyId === 12 ? (
                      <>
                        <IconRightArrow />

                        <CategoryLevel4
                          clickedLevel3Category={_clickedLevel3Category}
                          setClickedCategory={setClickedCategory}
                          setClickedLevel4Category={setClickedLevel4Category}
                        ></CategoryLevel4>

                        <IconRightArrow />

                        <CategoryLevel5
                          clickedLevel4Category={_clickedLevel4Category}
                          setClickedCategory={setClickedCategory}
                          setClickedLevel5Category={setClickedLevel5Category}
                        ></CategoryLevel5>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              }
              secondRow={
                <div className="SearchSection">
                  <div className="SearchSection">
                    <input
                      type="date"
                      className="SelectDate"
                      value={_startDate}
                      onChange={(e) => {
                        setStartDate(() => {
                          return e.target.value;
                        });
                      }}
                    />

                    <IconRightArrow />

                    <input
                      type="date"
                      className="SelectDate"
                      value={_endDate}
                      onChange={(e) => {
                        setEndDate(() => {
                          return e.target.value;
                        });
                      }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="elementName">물품이름</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={_searchElementData.elementName || ''}
                      onInput={(e) => {
                        setSearchElementData((prev) => {
                          return { ...prev, elementName: e.target.value };
                        });
                      }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="elementCode">물품코드</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={_searchElementData.elementCode || ''}
                      onInput={(e) => {
                        setSearchElementData((prev) => {
                          return { ...prev, elementCode: e.target.value };
                        });
                      }}
                    />
                  </div>

                  <div className="ContentCBox">
                    {/* <div className="ContentCBox">
                      <select
                        className="SearchOption"
                        onChange={(e) => {
                          setSearchOption(() => {
                            return e.target.value;
                          });
                        }}
                      >
                        <option value="">검색옵션</option>
                        <option value="elementName">물품이름</option>
                        <option value="elementCode">물품코드</option>
                        <option value="userName">작업자</option>
                      </select>
                      <input
                        className="SearchBar"
                        placeholder="Search..."
                        value={_searchText}
                        onInput={(e) => {
                          setSearchText(() => {
                            return e.target.value;
                          });
                        }}
                      />
                    </div> */}

                    <button data-searchbutton="true" onClick={actSearch}>
                      <SearchButtonIcon />
                    </button>
                    <div className="ResetButton" onClick={actReset}>
                      초기화
                    </div>
                  </div>
                </div>
              }
            />

            <TableSection
              content={
                <table>
                  <thead>
                    <tr>
                      <th>입력시각</th>
                      <th>물품이름</th>
                      <th>물품코드</th>
                      <th style={{ minWidth: '150px', width: '150px' }}>
                        입고
                      </th>
                      <th style={{ minWidth: '150px', width: '150px' }}>
                        투입
                      </th>
                      <th style={{ minWidth: '150px', width: '150px' }}>
                        생산
                      </th>
                      <th style={{ minWidth: '150px', width: '150px' }}>
                        출하
                      </th>
                      <th>비고</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_logList.map((thisLog) => {
                      return (
                        <tr key={thisLog.elementStockLogId + '_logs'}>
                          <td>{setDateFormat(thisLog.checkedDate)}</td>
                          <td>{thisLog.element.elementName}</td>
                          <td>{thisLog.element.elementCode}</td>
                          <td
                            style={{
                              color: 'var(--MainGreen)',
                              minWidth: '150px',
                              width: '150px',
                            }}
                          >
                            {thisLog.logType === 'In' && (
                              <CommaNum
                                displayType={'text'}
                                num={thisLog.amount}
                              />
                            )}
                            {thisLog.logType === 'In' &&
                              ' ' + thisLog.element.elementUnit}
                          </td>
                          <td
                            style={{
                              color: 'var(--MainYellow)',
                              minWidth: '150px',
                              width: '150px',
                            }}
                          >
                            {thisLog.logType === 'Put' && (
                              <CommaNum
                                displayType={'text'}
                                num={thisLog.amount}
                              />
                            )}
                            {thisLog.logType === 'Put' &&
                              ' ' + thisLog.element.elementUnit}
                          </td>
                          <td
                            style={{
                              color: 'var(--MainBlue)',
                              minWidth: '150px',
                              width: '150px',
                            }}
                          >
                            {thisLog.logType === 'Make' && (
                              <CommaNum
                                displayType={'text'}
                                num={thisLog.amount}
                              />
                            )}
                            {thisLog.logType === 'Make' &&
                              ' ' + thisLog.element.elementUnit}
                          </td>
                          <td
                            style={{
                              color: 'var(--MainRed)',
                              minWidth: '150px',
                              width: '150px',
                            }}
                          >
                            {thisLog.logType === 'Out' && (
                              <CommaNum
                                displayType={'text'}
                                num={thisLog.amount}
                              />
                            )}
                            {thisLog.logType === 'Out' &&
                              ' ' + thisLog.element.elementUnit}
                          </td>
                          <td>{thisLog.note}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
            ></TableSection>
          </main>

          <Paging
            page={pageReducer.currentPageNum}
            count={pageReducer.totalCount}
            size={pageReducer.pageSize}
          />

          {_excelDownloadModalStatus === true && (
            <ExcelDownloadModal
              open={_excelDownloadModalStatus}
              mainTitle={'Products'}
              subTitle={'StockHistory'}
              close={() => {
                setExcelDownloadModalStatus(false);
              }}
            />
          )}
        </>
      }
    />
  );
};

export default UriStockHistory;
