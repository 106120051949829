export const ViewResetBtn = () => {
  document.querySelector('table').style.zoom = '100%';
}
export const ViewPlusBtn = () => {
  let zoom = document.querySelector('table').style.zoom
  document.querySelector('table').style.zoom = parseInt(
    zoom.slice(0, zoom.length -1)
  ) + 20 + '%' 
}
export const ViewMinusBtn = () => {
  let zoom = document.querySelector('table').style.zoom
  document.querySelector('table').style.zoom = parseInt(
    zoom.slice(0, zoom.length -1)
  ) - 20 + '%' 
}