export const StatusName = (status) => {
  switch (status) {
    case 'wait':
      return '대기';
    case 'waiting':
      return '대기';
    case 'work':
      return '진행';
    case 'proceeding':
      return '진행';
    case 'working':
      return '작업 중';
    case 'end':
      return '완료';
    case 'stop':
      return '중단';
    case 'pause':
      return '일시정지';

    default:
      return '';
  }
};
