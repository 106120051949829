import { AxiosInterceptor } from "api/core/consts";

export const elementApi = {
  getElement: (elementId) => AxiosInterceptor.get('element/' + elementId),
  searchElement: (paging, body) =>
    AxiosInterceptor.post('element/search' + paging, body),
  searchElementStockList: (paging, body) =>
    AxiosInterceptor.post('element/stockListSearch' + paging, body),
  createElement: (body) =>
    AxiosInterceptor.post('element', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  uploadElements: (body) => AxiosInterceptor.post('elements', body),
  updateElement: (elementId, body) =>
    AxiosInterceptor.put('element/' + elementId, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  deleteElement: (elementId) => AxiosInterceptor.delete('element/' + elementId),
};
