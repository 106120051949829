import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { companyPageCode } from 'api/apis/operator/companyPageCode';

import { checkEmptyNull, checkNullParse } from 'components/checkValues/checkValues';

import Form from 'components/layouts/form/Form';
import NavBar from 'components/nav/NavBar';
import OperatorBody from 'components/layouts/body/OperatorBody';

const MainSection = styled.main`
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 60px calc(100% - 60px);
`;

const UpdatePageCode = () => {
  /* ========================================================================= #1 */
  const navigate = useNavigate();
  const { userReducer, pageReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [searchParams, setSearchParams] = useSearchParams();
  const updateContent = checkNullParse(searchParams.get('updateContent'), {});
  console.log('updateContent : ', updateContent);

  const [_onload, setOnload] = useState('unload');

  const [_formData, setFormData] = useState({
    companyPageCodeId: updateContent.companyPageCodeId,
    pageCode: updateContent.pageCode,
    defaultPageName: updateContent.defaultPageName,
    defaultPageUrl: updateContent.defaultPageUrl,
    defaultIndexX: updateContent.defaultIndexX,
    defaultIndexY: updateContent.defaultIndexY,
  });

  /* ========================================================================= #3 */
  useEffect(() => {
    if (userReducer.user.role !== 'ROLE_MASTER') return navigate('/', { replace: true });

    setOnload('loaded');
    return () => { };
  }, []);

  /* ========================================================================= #4 */
  /* ========================================================================= #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData(prev => { return { ...prev, [name]: value } })
  };

  const actUpdate = async (e) => {
    e.preventDefault();

    const BodyToPost = {
      ..._formData,
      defaultIndexX: Number(_formData.defaultIndexX),
      defaultIndexY: Number(_formData.defaultIndexY),
    };
    console.log('BodyToPost : ', BodyToPost);

    await companyPageCode.updateCompanyPageCode(updateContent.companyPageCodeId, BodyToPost).then(response => {
      if (!checkEmptyNull(response, false)) return;
      console.log('companyPageCodeApi.updateCompanyPageCode : ', response);
      alert('페이지코드를 수정했습니다.')
      navigate(pageReducer.currentPage, { replace: true });
    })
  };

  /* ========================================================================= #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <OperatorBody contents={
      <>
        <MainSection className="Main">
          <NavBar title={'페이지코드'} nav={''} />

          <Form
            title={<>페이지코드<br />수정</>}
            buttons={
              <>
                <button className="formButton" onClick={actUpdate}>저장</button>
                <button className="formButton cancle"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(pageReducer.currentPage, { replace: true });
                  }}
                >
                  취소
                </button>
              </>
            }
            forms={
              <>
                <div className="formBox w100">
                  <h4>pageCode</h4>
                  <input
                    type="text"
                    name="pageCode"
                    placeholder="pageCode"
                    data-required="required"
                    value={_formData.pageCode}
                    onInput={handleInputEvent}
                  />
                </div>
                <div className="formBox w100">
                  <h4>defaultPageName</h4>
                  <input
                    type="text"
                    name="defaultPageName"
                    placeholder="defaultPageName"
                    data-required="required"
                    value={_formData.defaultPageName}
                    onInput={handleInputEvent}
                  />
                </div>
                <div className="formBox w100">
                  <h4>defaultPageUrl</h4>
                  <input
                    type="text"
                    name="defaultPageUrl"
                    placeholder="defaultPageUrl"
                    data-required="required"
                    value={_formData.defaultPageUrl}
                    onInput={handleInputEvent}
                  />
                </div>
                <div className="formBox w100">
                  <h4>defaultIndexX</h4>
                  <input
                    type="text"
                    name="defaultIndexX"
                    placeholder="defaultIndexX"
                    data-required="required"
                    value={_formData.defaultIndexX}
                    onInput={handleInputEvent}
                  />
                </div>
                <div className="formBox w100">
                  <h4>defaultIndexY</h4>
                  <input
                    type="text"
                    name="defaultIndexY"
                    placeholder="defaultIndexY"
                    data-required="required"
                    value={_formData.defaultIndexY}
                    onInput={handleInputEvent}
                  />
                </div>
              </>
            }
          />
        </MainSection>
      </>
    }
    />
  );
};

export default UpdatePageCode;
