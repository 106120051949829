import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { fileDataApi } from 'api/apis/fileDataApi';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';

const DeleteFile = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { fileReducer, pageReducer } = useSelector((state) => state);

  /* ====================================================================== 2 */
  const [searchParams, setSearchParams] = useSearchParams();

  const fileDataId = searchParams.get('fileDataId');
  const fileDataName = searchParams.get('fileDataName');
  const fileDataType = searchParams.get('fileDataType');
  console.table({
    fileDataId: fileDataId,
    fileDataName: fileDataName,
    fileDataType: fileDataType,
  });

  /* ====================================================================== 3 */
  const actDelete = async () => {
    await fileDataApi.deleteFileData(fileDataId).then((response) => {
      if (response === undefined) return;
      console.log('fileDataApi.deleteFileData : ', response);

      alert('파일을 삭제했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  /* ====================================================================== 4 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body
      contents={
        <>
          <main className="Main">
            <NavBar title={'파일관리'} nav={''} />

            <DeleteForm
              delteTitle={'파일'}
              deleteItem={
                <>
                  {/* {'폴더이름 : ' + fileReducer.currentFolder.fileCategoryName} */}
                  {'파일이름 : ' + fileDataName}
                  {/* {'확장자 : ' + fileDataType} */}
                  <br />
                </>
              }
              deleteText={<>파일을 삭제합니다.</>}
              deleteButton={
                <>
                  <button
                    className="formButton cancle"
                    onClick={() => {
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                  <button
                    className="formButton delete"
                    onClick={() => {
                      if (window.confirm('파일을 삭제합니다.')) actDelete();
                      else return;
                    }}
                  >
                    삭제
                  </button>
                </>
              }
            />
          </main>
        </>
      }
    />
  );
};

export default DeleteFile;
