export const reProductionOrderActions_setWorkOrderType = (workOrderType) => {
  return {
    type: 'SETREPRODUCTIONORDERWORKORDERTYPE',
    payload: workOrderType,
  };
};

export const reProductionOrderActions_setWorkOrderStatus = (workOrderStatus) => {
  return {
    type: 'SETREPRODUCTIONORDERWORKORDERSTATUS',
    payload: workOrderStatus,
  };
};

export const reProductionOrderActions_setStartDate = (startDate) => {
  return {
    type: 'SETREPRODUCTIONORDERSTARTDATE',
    payload: startDate,
  };
};

export const reProductionOrderActions_setEndDate = (endDate) => {
  return {
    type: 'SETREPRODUCTIONORDERENDDATE',
    payload: endDate,
  };
};

export const reProductionOrderActions_setSearchOption = (searchData) => {
  return {
    type: 'SETREPRODUCTIONORDERSEARCHOPTION',
    payload: searchData,
  };
};
export const reProductionOrderActions_setSearchText = (searchData) => {
  return {
    type: 'SETREPRODUCTIONORDERSEARCHTEXT',
    payload: searchData,
  };
};
export const reProductionOrderActions_setSearchData = (searchData) => {
  return {
    type: 'SETREPRODUCTIONORDERSEARCHDATA',
    payload: searchData,
  };
};

export const reProductionOrderActions_setPageNumber = (pageNumber) => {
  return {
    type: 'SETREPRODUCTIONORDERPAGENUMBER',
    payload: pageNumber,
  };
};

export const reProductionOrderActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETREPRODUCTIONORDERTOTALSIZE',
    payload: totalSize,
  };
};

export const reProductionOrderActions_setIsPushedSearchButton = (isPushedSearchButton) => {
  return {
    type: 'SETREPRODUCTIONORDERISPUSHEDSEARCHBUTTON',
    payload: isPushedSearchButton,
  };
};

export const reProductionOrderActions_setReset = () => {
  return {
    type: 'SETREPRODUCTIONORDERRESET',
  };
};