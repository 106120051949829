import React, { useState, useMemo } from 'react';
import { Container, Table, Tbody, Td, Th, Thead, Title, Tr, StyledTr, AccountInfoWrapper, PaginationContainer, ListTableContainer, ListTableWrapper } from './Tab.style';
import ContractProcurementItems from './ContractProcurementItems';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';

const STATUS_LABELS = {
  waiting: '대기',
  proceeding: '진행',
  end: '완료',
};

const formatNum = (num) =>
  num != null ? new Intl.NumberFormat().format(num) : '-';

export default function ContractProcurement({ data }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProcurementId, setSelectedProcurementId] = useState(null);
  const itemsPerPage = 5;

  const totalPages = useMemo(
    () => Math.ceil(data.length / itemsPerPage),
    [data.length],
  );
  const currentItems = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    return data.slice(start, start + itemsPerPage);
  }, [data, currentPage]);

  const selectedProcurement = useMemo(
    () =>
      data.find(
        (procurement) => procurement.procurementId === selectedProcurementId,
      ),
    [data, selectedProcurementId],
  );

  const handlePageChange = (direction) => {
    setCurrentPage((prev) => prev + direction);
    setSelectedProcurementId(null);
  };

  const handleRowClick = (procurementId) =>
    setSelectedProcurementId(
      procurementId === selectedProcurementId ? null : procurementId,
    );

  return (
    <Container>
      <ListTableContainer>
        <ListTableWrapper>
          <Title>
            발주목록
            <PaginationContainer>
              <button
                onClick={() => handlePageChange(-1)}
                disabled={currentPage === 1}
              >
                <AiFillCaretLeft style={{ fontSize: '24px' }} />
              </button>
              <button
                onClick={() => handlePageChange(1)}
                disabled={currentPage === totalPages}
              >
                <AiFillCaretRight style={{ fontSize: '24px' }} />
              </button>
            </PaginationContainer>
          </Title>
          <Table>
            <Thead>
              <Tr>
                <Th>발주상태</Th>
                <Th>발주코드</Th>
                <Th>발주이름</Th>
                <Th>발주일</Th>
                <Th>발주총금액</Th>
                <Th>거래처</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentItems.map((procurement) => {
                const { account } = procurement;

                return (
                  <StyledTr
                    key={procurement.procurementId}
                    onClick={() => handleRowClick(procurement.procurementId)}
                    isSelected={
                      procurement.procurementId === selectedProcurementId
                    }
                  >
                    <Td>
                      {STATUS_LABELS[procurement.procurementStatus] || '-'}
                    </Td>
                    <Td>{procurement.procurementCode ?? '-'}</Td>
                    <Td>{procurement.procurementName ?? '-'}</Td>
                    <Td>{procurement.procurementDate ?? '-'}</Td>
                    <Td style={{ textAlign: 'right' }}>
                      {formatNum(procurement.procurementPrice)}
                    </Td>
                    <Td>
                      {account ? (
                        <AccountInfoWrapper>
                          {account.accountName && ( <div>거래처명: {account.accountName}</div> )}
                          {account.businessNumber && ( <div>사업자등록번호: {account.businessNumber}</div> )}
                          {account.regionName && ( <div>지역명: {account.regionName}</div> )}
                          {account.address && ( <div>주소: {account.address}</div> )}
                          {account.telNumber && ( <div>전화번호: {account.telNumber}</div> )}
                          {account.faxNumber && ( <div>팩스번호: {account.faxNumber}</div> )}
                        </AccountInfoWrapper>
                      ) : ( '-' )}
                    </Td>
                  </StyledTr>
                );
              })}
            </Tbody>
          </Table>
        </ListTableWrapper>

        {selectedProcurement && (
          <ListTableWrapper>
            <ContractProcurementItems procurement={selectedProcurement} />
          </ListTableWrapper>
        )}
      </ListTableContainer>
    </Container>
  );
};
