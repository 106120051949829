const initialState = {
  contentType: 'element',
  contentList: [],
  printList: [],

  barcode: null,
  barcodeContent: {},
};
const resetState = {
  contentList: [],
  printList: [],
};

const barcodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETBARCODECONTENTTYPE': return { ...state, contentType: action.payload };
    case 'SETBARCODECONTENTLIST': return { ...state, contentList: action.payload };
    case 'SETBARCODEPRINTLIST': return { ...state, printList: action.payload };

    case 'SETBARCODE': return { ...state, barcode: action.payload };
    case 'SETBARCODECONTENT': return { ...state, barcodeContent: action.payload };

    case 'SETBARCODERESET': return { ...state, ...resetState };
    default: return state;
  }
}

export default barcodeReducer;