import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { QRCodeSVG } from 'qrcode.react';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import styled from 'styled-components';

import { procurementApi } from 'api/apis/procurementApi';
import { sensorDataApi } from 'api/apis/sensorDataApi';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 55px auto 150px 50px;
  height: 85%;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;

    &>table tr:nth-child(even) {
      border-bottom: 2px solid var(--MainNavy);
    }
    & td.quantityCell {
      border: none;
      padding: 5px;
      & table {
        & thead {
          background-color: unset;
          color: var(--Text);
          z-index: 0;
        }
        & td, & th {
          min-width: unset;
          padding: 4px 8px;
        }
        & th {
          font-size: 0.9em;
          font-weight: 400;
        }
        & td {
          &[data-col="미입고수량"] {
            color: var(--MainRed);
            font-weight: 600;
          }
          &[data-col="입고수량"] {
            padding: unset;
            & input {
              background-color: var(--MainGreen);
              color: var(--white);
              font-size: 2em;
              font-weight: 600;
              height: 100%;
              min-width: 200px;
              text-align: right;
              width: 100%;
              &::placeholder {
                color: var(--white);
                font-weight: 300;
                font-size: 0.5em;
                opacity: 0.6;
              }
            }
          }
        }
      }
    }
  }
`;
const QRCode = styled(QRCodeSVG)`
  cursor: pointer;
  height: 50px;
  width: 50px;
`;

const WeightSection = styled.div`
  background-color: var(--MainNavy);
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  gap: 10px;
  height: 150px;
  justify-content: flex-start;
  overflow-x: auto;
  width: 100%;
  
  & .monitoring {
    border-right: 2px solid var(--white);
    box-sizing: border-box;
    color: var(--MainYellow);
    display: grid;
    grid-template-rows: 35px auto;
    height: 100%;
    min-width: 300px;
    padding: 20px;
    width: 30%;
    
    & p {
      align-items: center;
      display: flex;
      font-size: 3em;
      font-weight: 600;
      justify-content: flex-start;
    }
  }

  & .calSection {
    display: grid;
    gap: 10px;
    height: 100%;
    padding: 20px;
    width: 100%;

    & .calTopSection, & .calBottomSection {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: flex-end;
      width: 100%;

      & .calWeight {
        align-items: center;
        display: flex;
        gap: 5px;

        & .calInput {
          height: 40px;
          position: relative;
          max-width: 150px;

          & input {
            box-sizing: border-box;
            cursor: initial;
            height: 100%;
            padding-inline: 10px 52px;
            width: 100%;
            &:disabled {
              background-color: var(--ThirdBlue);
              color: var(--white);
              &[name="sumWeight"] {padding-inline-end: 5px;}
            }
          }

          & button {
            max-height: 90%;
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
            z-index: auto;
            
            &.btn-set {
              background-color: var(--white);
            }
          }
        }

        & button.btn-set:hover {
          background-color: var(--gray-100);
        }
      }
    }
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainGreen);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;


const GSNotInStock = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_procurement, setProcurement] = useState({
    ...props.content,
    userId: userReducer.user.id,
    accountId: props.content?.account?.accountId,
    procurementElementList: [],
    deleteProcurementElementList: [],
    isCustom: true,
  });
  const [_procurementElementList, setProcurementElementList] = useState(() => {
    const returnData = checkNullArray(props.content.procurementElementList, []).map((procurementElement, index) => {
      let customizedContent = {
        num: (index + 1).toString().padStart(2, '0'),
        weight: 0,
        actualWeight: 0,
      };
      if (checkNullParse(procurementElement.customizedContent, false)) {
        customizedContent = checkNullParse(procurementElement.customizedContent, customizedContent);
      }

      return {
        // status: procurementElement.status,
        procurementElementId: procurementElement.procurementElementId,

        elementId: procurementElement.element?.elementId,
        element: procurementElement.element,

        customizedContent: customizedContent,

        price: procurementElement.price,
        quantity: procurementElement.quantity,

        storeQuantity: checkEmptyNull(procurementElement.storeQuantity, procurementElement.quantity),
        savedQuantity: checkEmptyNull(procurementElement.storeQuantity, ''), // 입고 입력 수량
      };
    });
    return returnData;
  });

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    userId: userReducer.user.id,

    procurementId: _procurement.procurementId,
    procurementDate: DateFormat(checkEmptyNull(_procurement.procurementDate, '')),
    scheduledEndDate: DateFormat(checkEmptyNull(_procurement.scheduledEndDate, '')),

    procurementStatus: 'end',
    procurementCode: _procurement.procurementCode,
    procurementName: _procurement.procurementName,

    accountId: _procurement?.account?.accountId,

    procurementPrice: _procurement.procurementPrice,

    procurementElementList: [],
    deleteProcurementElementList: [],

    customizedContent: (() => {
      let customizedContent = { actualWeight: 0 };
      if (checkNullParse(_procurement.customizedContent, false)) {
        customizedContent = checkNullParse(_procurement.customizedContent, customizedContent);
      }
      return customizedContent;
    })(),
  });

  /* 계근대 */
  const [_realtimeWeight, setRealtimeWeight] = useState(0); // 실시간 계근대 중량

  /* 발주 */
  const [_actualWeight, setActualWeight] = useState(() => {
    let customizedContent = { actualWeight: 0 };
    if (checkNullParse(_procurement.customizedContent, false)) {
      customizedContent = checkNullParse(_procurement.customizedContent, customizedContent);
    }
    return customizedContent.actualWeight;
  });
  const [_entryWeight, setEntryWeight] = useState(); // 입차
  const [_unladenWeight, setUnladenWeight] = useState(); // 공차
  const [_defaultWeight, setDefaultWeight] = useState(); // 기준
  const [_calWeight, setCalWeight] = useState();

  /* 발주물품 */
  const [_sumWeight, setSumWeight] = useState(() => {
    const weigthArray = _procurementElementList.map((thisItem) => {
      const weight = checkEmptyNull(thisItem.customizedContent?.weight, 0).toString().replace(/[^0-9.]/g, '');
      const elementWeight = BigNumber(weight).multipliedBy(BigNumber(checkEmptyNull(thisItem?.quantity, 0))).toNumber();
      // console.log(thisItem, weight, elementWeight);
      return elementWeight;
    });
    const cal = weigthArray.reduce((a, b) => a + b, 0);
    // console.log('cal-weight : ', cal);
    return cal;
  });
  const [_sumActualWeight, setSumActualWeight] = useState(() => {
    const actualWeightArray = _procurementElementList.map((thisItem) => {
      return thisItem.customizedContent.actualWeight;
    });
    const cal = actualWeightArray.reduce((a, b) => a += b, 0);
    // console.log('cal-actualWeight : ', cal);
    return cal;
  });

  /* ====================================================================== #3 */
  useEffect(() => {
    const getSensorData = setInterval(async () => {
      const BodyToPost = {
        companyId: userReducer.company.companyId,
        code: 'weight',
        name: null,
      }
      await sensorDataApi.sensorRealTimeData(BodyToPost).then(response => {
        if (response === undefined) return;
        console.log(moment().format('HH:mm:ss') + ' - ', checkNullObject(response.data.content[0], {}));
        const sensorData = checkEmptyNull(response.data.content[0]?.value, 0);
        setRealtimeWeight(() => { return sensorData });
      })
    }, 1000);
    return () => clearInterval(getSensorData);
  }, [_realtimeWeight])
  useEffect(() => { }, [_entryWeight, _unladenWeight, _defaultWeight, _calWeight, _actualWeight]);

  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  /* 입고수량 */
  const handleInputEvent = (e, index, item) => {
    const { value } = e.target;
    let eventNum = value;
    if (Number(value) > e.target.max) {
      eventNum = e.target.max;
      e.target.value = e.target.max;
    }
    const newData = { ...item, savedQuantity: Number(eventNum) };
    setProcurementElementList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1, newData);
      return prevData;
    });
  };

  /* 바코드 복사 */
  const copyBarcode = (barcode) => {
    const textArea = document.createElement('textarea');
    document.body.appendChild(textArea);

    textArea.value = barcode;
    textArea.select();
    textArea.setSelectionRange(0, 99999);
    document.execCommand('copy');
    textArea.setSelectionRange(0, 0);

    document.body.removeChild(textArea);
  };

  /* 무게계산 */
  const actCalculate = (entryWeight, unladenWeight, defaultWeight) => {
    const calWeight = BigNumber(checkEmptyNull(entryWeight, 0)).minus(BigNumber(checkEmptyNull(unladenWeight, 0))).minus(BigNumber(checkEmptyNull(defaultWeight, 0))).toNumber();
    setCalWeight(() => { return calWeight });
  }

  /* 무게저장 */
  const actSaveWeight = async () => {
    const weightData = { actualWeight: BigNumber(checkEmptyNull(_actualWeight, 0)).plus(BigNumber(checkEmptyNull(_calWeight, 0))).toNumber() }
    const BodyToPut = {
      ..._procurement,
      customizedContent: JSON.stringify(weightData),
    }
    console.log('BodyToPut : ', BodyToPut);
    await procurementApi.updateProcurement(_procurement.procurementId, BodyToPut).then(async (response) => {
      if (response === undefined) return;
      console.log('procurementApi.updateProcurement : ', response);
    })
    setEntryWeight(() => { return '' })
    setUnladenWeight(() => { return '' })
    setDefaultWeight(() => { return '' })
    setCalWeight(() => { return '' });
    setActualWeight(() => { return weightData.actualWeight });
  }

  /* 무게수정 */
  const updateWeight = async () => {
    const promptText = `
누적 중량을 수정합니다.
    `;
    const promptWeight = window.prompt(promptText, '');
    if (checkEmptyNull(promptWeight, false)) {
      let weightData = { actualWeight: 0 }
      if (checkNullParse(_procurement.customizedContent, false)) {
        weightData = checkNullParse(_procurement.customizedContent, weightData);
      }
      const cumulativeWeight = Number(promptWeight);
      if (!isNaN(cumulativeWeight)) weightData.actualWeight = cumulativeWeight;
      const BodyToPut = {
        ..._procurement,
        customizedContent: JSON.stringify(weightData),
      }
      console.log('BodyToPut : ', BodyToPut);
      await procurementApi.updateProcurement(_procurement.procurementId, BodyToPut).then(async (response) => {
        if (response === undefined) return;
        console.log('procurementApi.updateProcurement : ', response);
      })
      setActualWeight(() => { return weightData.actualWeight });
    } else return;
  }

  /* 입고 */
  const actIncoming = async () => {
    const weightData = { actualWeight: BigNumber(checkEmptyNull(_actualWeight, 0)).toNumber() }
    const BodyToPost = {
      ..._formData,
      customizedContent: JSON.stringify(weightData),

      procurementElementList: _procurementElementList.map((element) => {
        const returnData = {
          ...element,

          procurementElementId: element.procurementElementId,
          elementId: element.elementId,
          quantity: element.quantity,
          price: element.price,

          // storeQuantity: element.storeQuantity, // 미입고
          savedQuantity: checkEmptyNull(element.savedQuantity, 0), // 입력 입고 수량

          customizedContent: JSON.stringify(element.customizedContent),

          checkedDate: checkEmptyNull(element.checkedDate, ''), // 유통기한
          stockPlaceId: checkEmptyNull(element.stockPlaceId, null), // 재고위치
        };
        delete returnData['element'];
        return returnData;
      }),

      isCustom: true, // 경서 > 커스텀 표시
    };
    console.log('BodyToPost : ', BodyToPost, JSON.stringify(BodyToPost));
    await procurementApi.savePartStock(BodyToPost).then(async (response) => {
      if (response === undefined) return;
      console.log('/post/procurement/savePartStock - response : ', response);
      alert('물품을 추가 입고했습니다.');
      props.close();
    })
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>발주입고</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <TableSection content={
                <table>
                  <thead>
                    <tr>
                      <th style={{ minWidth: '60px', width: '60px' }}>순번</th>
                      <th>재질</th>
                      <th>규격</th>
                      <th>단위</th>
                      <th>길이</th>
                      <th>단중</th>
                      <th>중량</th>
                      <th>비고</th>
                    </tr>
                  </thead>

                  <tbody>
                    {checkNullArray(_procurementElementList, []).map((procurement, index) => {
                      const customizedContent = procurement.customizedContent;
                      const element = procurement.element;
                      const elementData = {
                        length: null,
                        weightByUnit: null,
                        remark: null,
                      };
                      checkNullArray(element?.elementAttributeValueList, []).forEach((attr) => {
                        switch (attr.elementAttribute.elementAttributeName) {
                          case '길이': elementData.length = attr.value; break;
                          case '단중': elementData.weightByUnit = attr.value; break;
                          case '비고': elementData.remark = attr.value; break;
                          default: return;
                        }
                      });

                      const barcode = `Procurement-${props.content.procurementId}-${procurement.procurementElementId}-${element?.elementId}`;

                      return (
                        <Fragment key={index + '_procurement_incoming'}>
                          <tr data-procurementelementid={procurement.procurementElementId}>
                            <td rowSpan={2} style={{ minWidth: '80px', width: '80px' }} data-col="순번">{customizedContent.num}</td>
                            <td rowSpan={2} data-col="재질">{element.elementName}</td>
                            <td rowSpan={2} data-col="규격">{element.elementCode}</td>
                            <td data-col="단위">{element.elementUnit}</td>
                            <td data-col="길이">{elementData.length}</td>
                            <td data-col="단중">{elementData.weightByUnit}</td>
                            <td data-col="중량">{customizedContent.weight}</td>
                            <td data-col="비고">{elementData.remark}</td>
                          </tr>
                          <tr>
                            <td colSpan={5} className='quantityCell'>
                              <table>
                                <colgroup>
                                  <col width={'150px'}></col>
                                  <col width={'150px'}></col>
                                  <col width={'150px'}></col>
                                  <col></col>
                                  <col width={'120px'}></col>
                                </colgroup>
                                <thead>
                                  <tr>
                                    <th>수량</th>
                                    <th>기입고 수량</th>
                                    <th>미입고 수량</th>
                                    <th>입고 수량</th>
                                    <th>바코드</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td data-col="수량">{checkEmptyNull(procurement.quantity, 0) + ' ' + checkEmptyNull(element.elementUnit, '')}</td>
                                    <td data-col="기입고수량">
                                      {BigNumber(checkEmptyNull(procurement.quantity, 0)).minus(BigNumber(checkEmptyNull(procurement.storeQuantity, 0))).toNumber().toLocaleString() + ' ' + checkEmptyNull(element.elementUnit, '')}
                                    </td>
                                    <td data-col="미입고수량">
                                      {checkEmptyNull(procurement.storeQuantity, 0).toLocaleString() + ' ' + checkEmptyNull(element.elementUnit, '')}
                                    </td>
                                    <td data-col="입고수량">
                                      <input
                                        type="number"
                                        name="savedQuantity"
                                        min={0}
                                        max={procurement.quantity}
                                        placeholder='수량을 입력해주세요.'
                                        value={procurement.savedQuantity}
                                        onInput={(e) => { handleInputEvent(e, index, procurement); }}
                                      />
                                    </td>
                                    <td style={{ minWidth: '100px', width: '100px' }} data-col="바코드" data-barcode={barcode}>
                                      <QRCode value={barcode} onClick={() => { copyBarcode(barcode) }} />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
                  </tbody>
                </table>
              }
              />
            </ModalMain>

            <WeightSection>
              <div className='monitoring'>
                <h4>계근대 중량</h4>
                <p>{checkEmptyNull(_realtimeWeight, '').toLocaleString()}</p>
              </div>
              <div className='calSection'>
                <div className='calTopSection'>
                  <div className='calWeight'>
                    <h4>입차</h4>
                    <div className='calInput'>
                      <input
                        type='number'
                        name='entryWeight'
                        value={_entryWeight}
                        onInput={(e) => {
                          e.preventDefault();
                          const weight = e.target.value;
                          setEntryWeight(() => { return weight });
                          actCalculate(weight, _unladenWeight, _defaultWeight);
                        }}
                      />
                      <button className='btn-set'
                        onClick={(e) => {
                          e.preventDefault();
                          setEntryWeight(() => { return _realtimeWeight });
                          actCalculate(_realtimeWeight, _unladenWeight, _defaultWeight);
                        }}
                      >
                        입력
                      </button>
                    </div>
                  </div>
                  <p>-</p>
                  <div className='calWeight'>
                    <h4>공차</h4>
                    <div className='calInput'>
                      <input
                        type='number'
                        name='unladenWeight'
                        value={_unladenWeight}
                        onInput={(e) => {
                          e.preventDefault();
                          const weight = e.target.value;
                          setUnladenWeight(() => { return weight });
                          actCalculate(_entryWeight, weight, _defaultWeight);
                        }}
                      />
                      <button className='btn-set'
                        onClick={(e) => {
                          e.preventDefault();
                          setUnladenWeight(() => { return _realtimeWeight });
                          actCalculate(_entryWeight, _realtimeWeight, _defaultWeight);
                        }}
                      >
                        입력
                      </button>
                    </div>
                  </div>
                  <p>-</p>
                  <div className='calWeight'>
                    <h4>기준</h4>
                    <div className='calInput'>
                      <input
                        type='number'
                        name='defaultWeight'
                        value={_defaultWeight}
                        onInput={(e) => {
                          e.preventDefault();
                          const weight = e.target.value;
                          setDefaultWeight(() => { return weight });
                          actCalculate(_entryWeight, _unladenWeight, weight);
                        }}
                      />
                      <button className='btn-set'
                        onClick={(e) => {
                          e.preventDefault();
                          setDefaultWeight(() => { return _realtimeWeight });
                          actCalculate(_entryWeight, _unladenWeight, _realtimeWeight);
                        }}
                      >
                        입력
                      </button>
                    </div>
                  </div>
                  <div className='calWeight'>
                    <p>=</p>
                    <h4>실제 중량</h4>
                    <div className='calInput'>
                      <input
                        type='number'
                        name='calWeight'
                        value={_calWeight}
                        onInput={(e) => {
                          e.preventDefault();
                          const weight = e.target.value;
                          setEntryWeight(() => { return 0 })
                          setUnladenWeight(() => { return 0 })
                          setDefaultWeight(() => { return 0 })
                          setCalWeight(() => { return weight });
                        }}
                      />
                    </div>
                    <button className='btn-inven-2'
                      onClick={(e) => {
                        e.preventDefault();
                        actCalculate(_entryWeight, _unladenWeight, _defaultWeight);
                      }}
                    >
                      계산
                    </button>
                    <button className='btn-inven-1'
                      onClick={(e) => {
                        e.preventDefault();
                        actSaveWeight();
                      }}
                    >
                      저장
                    </button>
                    <button className='btn-set'
                      onClick={(e) => {
                        e.preventDefault();
                        setEntryWeight(() => { return '' })
                        setUnladenWeight(() => { return '' })
                        setDefaultWeight(() => { return '' })
                        setCalWeight(() => { return '' });
                      }}
                    >
                      초기화
                    </button>
                  </div>
                </div>
                <div className='calBottomSection'>
                  <div className='calWeight'>
                    <h4>누적 중량</h4>
                    <div className='calInput'>
                      <input
                        type='text'
                        name='actualWeight'
                        disabled
                        value={checkEmptyNull(_actualWeight, '').toLocaleString()}
                      />
                      <button className='btn-inven-4'
                        onClick={(e) => {
                          e.preventDefault();
                          updateWeight();
                        }}
                      >
                        수정
                      </button>
                    </div>
                  </div>
                  <p>/</p>
                  <div className='calWeight'>
                    <h4>총 중량</h4>
                    <div className='calInput'>
                      <input
                        type='text'
                        name='sumWeight'
                        disabled
                        value={checkEmptyNull(_sumWeight, '').toLocaleString()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </WeightSection>

            <ModalFooter>
              <ModalButton onClick={actIncoming}>입고</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default GSNotInStock;
