import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { mallMyOrderActions_setIsPushedSearchButton, mallMyOrderActions_setMallOrderStatus, mallMyOrderActions_setPageNumber, mallMyOrderActions_setReset, mallMyOrderActions_setSearchOption, mallMyOrderActions_setSearchText, mallMyOrderActions_setTotalSize } from 'store/modules/actions/mallSite/mallMyOrderActions';

import { order } from 'api/apis/mall/order';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import { DateTimeFormatKR } from 'components/format/DateTimeFormatKR';
import { MallStatusName } from 'components/status/MallStatusName';
import MallPaging from 'mall/components/MallPaging';
import MypageOrderInfo from 'mall/components/MypageOrderInfo';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';

export default function MallMypageOrderList() {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const location = useLocation();
  const { mallMyOrderReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const companyCode = location.pathname.split('/')[1];

  const [_onload, setOnload] = useState('unload');

  const [_mallSiteOrderList, setMallSiteOrderList] = useState([]);

  const [_infoStatus, setInfoStatus] = useState(false);
  const [_currentOrder, setCurrentOrder] = useState({});

  const [isShown, setIsShown] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    getMallSiteOrderList(mallMyOrderReducer.pageNumber - 1, mallMyOrderReducer.mallSiteOrderStatus, mallMyOrderReducer.isPushedSearchButton);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getMallSiteOrderList(mallMyOrderReducer.pageNumber - 1, mallMyOrderReducer.mallSiteOrderStatus, mallMyOrderReducer.isPushedSearchButton);
    }
    return () => { };
  }, [mallMyOrderReducer.pageNumber]);

  useEffect(() => { }, [mallMyOrderReducer]);
  useEffect(() => { }, [_infoStatus, _currentOrder]);

  /* ====================================================================== #4 */
  const getMallSiteOrderList = async (page, mallSiteOrderStatus, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.user.companyId,
      companyCode: companyCode,
      userId: userReducer.user.id,
    };
    if (checkEmptyNull(mallSiteOrderStatus, false) && mallSiteOrderStatus !== 'all') {
      BodyToPost.status = mallSiteOrderStatus;
    }
    if (clear && checkEmptyNull(mallMyOrderReducer.searchOption, false) && checkEmptyNull(mallMyOrderReducer.searchText, false)) {
      BodyToPost[mallMyOrderReducer.searchOption] = mallMyOrderReducer.searchText;
    }
    await order.searchMallOrder(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('order.searchMallOrder : ', response);
      if (response.data) {
        setMallSiteOrderList(() => { return response.data.content; });
        if (response.data.content.length > 0 && _currentOrder.mallOrderId !== undefined) {
          const eventOrderIndex = response.data.content.findIndex((order) => order.mallOrderId === _currentOrder.mallOrderId);
          if (eventOrderIndex !== -1) setCurrentOrder(() => { return response.data.content[eventOrderIndex]; });
        }
        dispatch(mallMyOrderActions_setTotalSize(response.data.totalElements));
      }
    });
  };

  /* ====================================================================== #5 */
  /* 검색 */
  const actSearch = async () => {
    setInfoStatus(() => { return false; });
    setCurrentOrder(() => { return {}; });
    dispatch(mallMyOrderActions_setPageNumber(1));
    dispatch(mallMyOrderActions_setIsPushedSearchButton(true));
    await getMallSiteOrderList(0, mallMyOrderReducer.mallSiteOrderStatus, true);
  };

  /* 초기화 */
  const actReset = async () => {
    setInfoStatus(() => { return false; });
    setCurrentOrder(() => { return {}; });
    dispatch(mallMyOrderActions_setReset());
    dispatch(mallMyOrderActions_setPageNumber(1));
    await getMallSiteOrderList(0, 'all', false);
  };

  /* 상세보기 */
  const goDetails = (mallSiteOrder) => {
    setIsShown(() => { return true; });
    setCurrentOrder(() => { return mallSiteOrder; });
    setInfoStatus(() => { return true; });
  };

  return (
    <>
      <div className='mypage-info-area-order-list mall-product-qna non-padding'>
        <div className='mypage-info-area-order-list-search'>
          <select
            value={mallMyOrderReducer.mallSiteOrderStatus}
            onChange={(e) => {
              dispatch(mallMyOrderActions_setMallOrderStatus(e.target.value));
              dispatch(mallMyOrderActions_setPageNumber(1));
              getMallSiteOrderList(0, e.target.value, mallMyOrderReducer.isPushedSearchButton);
            }}
          >
            {[
              { optionValue: 'all', optionText: '주문상태' },
              { optionValue: 'waiting', optionText: '대기' },
              { optionValue: 'consulting', optionText: '협의 중' },
              { optionValue: 'preparing', optionText: '상품 준비 중' },
              { optionValue: 'complete', optionText: '상품 발송' },
              { optionValue: 'requestCancel', optionText: '취소 요청' },
              { optionValue: 'cancel', optionText: '취소' },
            ].map((option) => {
              return (<option key={option.optionValue + '_mallOrderStatus'} value={option.optionValue}>{option.optionText}</option>);
            })}
          </select>
          <select value={mallMyOrderReducer.searchOption} onChange={(e) => { dispatch(mallMyOrderActions_setSearchOption(e.target.value)); }}>
            {[
              { optionValue: '', optionText: '검색옵션' },
              { optionValue: 'mallOrderCode', optionText: '주문코드' },
              { optionValue: 'mallOrderContent', optionText: '주문내용' },
            ].map((option) => {
              return (<option key={option.optionValue + '_mallOrderSearchOption'} value={option.optionValue}>{option.optionText}</option>);
            })}
          </select>
          <input
            className="SearchBar"
            placeholder="Search..."
            value={mallMyOrderReducer.searchText}
            onInput={(e) => { dispatch(mallMyOrderActions_setSearchText(e.target.value)); }}
          />
          <div onClick={actReset}><img src='https://cdn-icons-png.flaticon.com/512/82/82004.png' style={{ width: '16px' }} /></div>
          <button onClick={actSearch}><SearchButtonIcon />검색</button>
        </div>
        {/* 주문 리스트 */}
        <div className='qna-wrap'>
          <ul data-info={_infoStatus} className='qna-status'>
            <li className='qna-status-title'>
              <p>주문번호</p>
              <p className='first'>진행상태</p>
              <p>주문코드</p>
              <p>주문정보</p>
              <p>등록일</p>
              <p>상세보기</p>
            </li>
            {_mallSiteOrderList.map((mallSiteOrder, index) => {
              return (
                <li key={index + '_mallSiteOrders'}>
                  <p className='first'>{mallSiteOrder.mallOrderId}</p>
                  <p className='first'>{MallStatusName(mallSiteOrder.status || 'waiting')}</p>
                  <p>{mallSiteOrder.mallOrderCode}</p>
                  <p data-type="info" style={{ textAlign: 'left' }}>
                    {`받는사람: ${mallSiteOrder.recipientName}`}<br />
                    {`주소: ${mallSiteOrder.address}`}
                  </p>
                  <p data-type="date">{DateTimeFormatKR(mallSiteOrder.createdDate)}</p>
                  <p className='goDetaile' onClick={(e) => { _currentOrder.mallOrderId === mallSiteOrder.mallOrderId ? setCurrentOrder({}) : goDetails(mallSiteOrder) }}>상세보기</p>
                </li>
              );
            })}
          </ul>
        </div>

        <MallPaging
          page={mallMyOrderReducer.pageNumber}
          count={mallMyOrderReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(mallMyOrderActions_setPageNumber(page));
          }}
        />
      </div>

      {/* 상세보기 모달 */}
      {isShown ? (
        <div className='qna-modal'>
          <div className='closeBtn' onClick={(e) => { e.preventDefault(); setIsShown(() => { return false; }) }}>닫기</div>
          <MypageOrderInfo
            mallOrder={_currentOrder}
            update={() => {
              getMallSiteOrderList(mallMyOrderReducer.pageNumber - 1, mallMyOrderReducer.mallSiteOrderStatus, mallMyOrderReducer.isPushedSearchButton);
              setIsShown(() => { return false });
            }}
          />
        </div>
      ) : null}
    </>
  );
};