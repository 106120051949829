export const chatReducer_setChatList = (thisChat) => {
  return {
    type: 'SETCHATLIST',
    payload: thisChat
  }
};

export const chatReducer_setChatRoomOpen = (thisChat) => {
  return {
    type: 'SETCHATROOMOPEN',
    payload: thisChat
  }
};

export const chatReducer_setChatRoomRead = (thisChat) => {
  return {
    type: 'SETCHATROOMREAD',
    payload: thisChat
  }
};

export const chatReducer_setChatInput = (thisChat) => {
  return {
    type: 'SETCHATINPUT',
    payload: thisChat
  }
};

export const chatReducer_setChatConnectStatus = (thisChat) => {
  return {
    type: 'SETCHATCONNECTSTATUS',
    payload: thisChat
  }
};