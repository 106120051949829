import React from 'react';

import { IoClose } from "react-icons/io5";
import { ClusterContentModalComponent } from './ContentModal.style';
/* equipement */
import RequestEquipmentDetail from '../mypage/equipment/RequestEquipmentDetail';
import SharedEquipment from '../mypage/equipment/SharedEquipment';
/* people */
import RequestPeopleDetail from '../mypage/people/RequestPeopleDetail';
import SharedPeople from '../mypage/people/SharedPeople';
/* board */
import MyBoardDetail from '../mypage/board/MyBoardDetail';
import PurchaseBoardDetail from '../mypage/board/purchase/PurchaseBoardDetail';
import SalesBoardDetail from '../mypage/board/sales/SalesBoardDetail';

const ClusterContentModal = ({
  modalType, modalData,
  update,
  open, close
}) => {
  const handleModalTitle = (type) => { // 모달 타이틀 설정
    const titleType = `${type.split('-')[1]}`;
    const sharingTypeName = `${type.split('-')[0]}SharingTypeName`;
    switch (titleType) {
      case 'detail': return modalData.title || '';
      case 'detailShared': return sharingTypeName || '';
      case 'detailComment': return modalData.title || '';

      default: return '';
    }
  }
  const handleModalContent = (type) => { // 모달 컨텐츠 설정
    if (type.split('-')[1] === 'detail') { // 상세 보기
      if (type.split('-')[0] === 'equipment') { // 요청 장비
        return (<RequestEquipmentDetail modalType={modalType} modalData={modalData} close={close} />);
      } else if (type.split('-')[0] === 'people') { // 요청 인력
        return (<RequestPeopleDetail modalType={modalType} modalData={modalData} close={close} />);
      } else if (type.split('-')[0] === 'public') { // 공용 게시판
        return (<MyBoardDetail modalType={modalType} modalData={modalData} close={close} />);
      } else if (type.split('-')[0] === 'purchase') { // 공동 구매
        return (<PurchaseBoardDetail modalType={modalType} modalData={modalData} close={close} />);
      } else if (type.split('-')[0] === 'sales') { // 상호 거래
        return (<SalesBoardDetail modalType={modalType} modalData={modalData} close={close} />);
      }
    } else if (type.split('-')[1] === 'detailShared') { // 공유 상세 보기
      if (type.split('-')[0] === 'equipment') { // 공유 장비
        return (<SharedEquipment modalType={modalType} modalData={modalData} close={close} />);
      } else if (type.split('-')[0] === 'people') { // 공유 인력
        return (<SharedPeople modalType={modalType} modalData={modalData} close={close} />);
      }
    } else if (type.split('-')[1] === 'detailComment') { // 상세 보기 + 신청
      if (type.split('-')[0] === 'purchase') { // 공동 구매
        return (<PurchaseBoardDetail modalType={modalType} modalData={modalData} update={update} close={close} />);
      } else if (type.split('-')[0] === 'sales') { // 상호 거래
        return (<SalesBoardDetail modalType={modalType} modalData={modalData} update={update} close={close} />);
      }
    }
  }

  return (
    <>
      {open && (
        <ClusterContentModalComponent className={['purchase', 'sales'].includes(modalType?.split('-')[0]) ? 'common' : ''}>
          <header>
            <h1>{handleModalTitle(modalType)}</h1>
            <button className='btn-close' onClick={close}><IoClose /></button>
          </header>
          <div>
            {handleModalContent(modalType)}
          </div>
        </ClusterContentModalComponent>
      )}
    </>
  );
};

export default ClusterContentModal;