import styled from "styled-components";

export const ClusterMyPageComponent = styled.section`
  background-color: var(--white);
  display: grid;
  grid-template-rows: 100px calc(100% - 100px);
  height: 100svh;
  overflow: hidden;
  position: relative;
  width: 100svw;

  &>section.main {
    display: grid;
    grid-template-columns: 280px calc(100% - 280px);
    height: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 90%;
    
    &>section {
      max-width: 1540px;
      width: auto;
    }
  }
`;