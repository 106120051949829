import React from 'react';
import styled from 'styled-components';

import { uploadSection } from 'components/icons/src';

const EventSection = styled.label`
  align-items: center;
  border: 1px dashed var(--MainNavy);
  box-sizing: border-box;
  column-gap: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center !important;
  min-height: 200px;
  width: 100%;

  p {
    font-weight: 600;
  }
`;

const EventIcon = styled.div`
  background-color: var(--MainNavy);
  height: 50px;
  width: 50px;

  mask-image: url(${uploadSection});
  mask-repeat: no-repeat;
  mask-size: contain;

  --webkit-mask-image: url(${uploadSection});
  --webkit-mask-repeat: no-repeat;
  --webkit-mask-size: contain;
`;

const Preview = styled.div`
  width: 100%;

  div[name='FilePreviews'] {
    align-items: center;
    background-color: var(--MainNavy);
    box-sizing: border-box;
    color: var(--white);
    display: flex;
    height: 40px;
    justify-content: flex-start;
    margin: 5px auto;
    padding: 0px 15px;
    position: relative;
    width: 100%;
  }
`;

const FileEvent = (props) => {
  return (
    <>
      <input
        type="file"
        id="FileInput"
        name={props.fileInputName}
        style={{ display: 'none' }}
        // accept="image/*"
        // multiple
        onChange={(e) => {
          props.fileEvent('click', e);
        }}
      />

      <EventSection
        htmlFor="FileInput"
        onDragEnter={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onDragLeave={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onDragOver={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onDrop={(e) => {
          e.preventDefault();
          props.fileEvent('drag', e);
        }}
      >
        <EventIcon />
        <p>파일을 드래그해주세요.</p>
      </EventSection>

      <Preview id="FilePreview">
        {props.fileDataList.map((thisFile, index) => {
          if (props.act === 'create') {
            return (
              <div key={index + 'FilePreviews'} name="FilePreviews">
                {thisFile.name}
              </div>
            );
          } else {
            return (
              <div key={index + 'FilePreviews'} name="FilePreviews">
                {thisFile.name || thisFile.fileDataName}
              </div>
            );
          }
        })}
      </Preview>
    </>
  );
};

export default FileEvent;
