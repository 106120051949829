import React from 'react';

const ClusterBoardSvg = () => {
  return (
    <svg width="224" height="162" viewBox="0 0 224 162" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path d="M167.981 26.6612L161.569 15.4571L185.624 1.48964C185.631 1.48553 185.639 1.4809 185.646 1.47679L185.673 1.46137L185.673 1.4624C189.706 -0.843406 195.264 -0.499539 201.402 3.04449C213.718 10.1552 223.702 27.4477 223.702 41.6685C223.702 48.7936 221.195 53.7948 217.145 56.1186L217.146 56.1196L192.895 70.1986L186.308 58.6876C176.869 51.4098 169.438 38.4509 167.981 26.6612Z" fill="#455A64" />
        <path d="M177.19 17.1021C189.505 24.2128 199.489 41.5047 199.489 55.7261C199.489 69.9469 189.506 75.7114 177.19 68.6008C164.875 61.4901 154.891 44.1976 154.891 29.9768C154.891 15.7559 164.875 9.99192 177.19 17.1021Z" fill="#263238" />
        <path d="M203.136 53.6923C203.136 55.9025 204.299 57.0225 205.735 56.1934C207.171 55.3643 208.334 52.9007 208.334 50.6905C208.334 48.4803 207.171 47.3603 205.735 48.1894C204.299 49.0185 203.136 51.4821 203.136 53.6923Z" fill="#263238" />
        <path d="M213.583 48.3954C213.583 50.6056 214.747 51.7256 216.183 50.8965C217.618 50.0675 218.782 47.6039 218.782 45.3937C218.782 43.1835 217.618 42.0635 216.183 42.8925C214.747 43.7216 213.583 46.1852 213.583 48.3954Z" fill="#263238" />
        <path d="M203.982 53.9228C203.982 56.133 205.146 57.253 206.582 56.4239C208.017 55.5953 209.181 53.1312 209.181 50.921C209.181 48.7108 208.017 47.5908 206.582 48.4199C205.146 49.249 203.982 51.7126 203.982 53.9228Z" fill="#4355F4" />
        <path d="M214.43 48.6278C214.43 50.838 215.593 51.9581 217.029 51.129C218.465 50.2999 219.628 47.8363 219.628 45.6261C219.628 43.4159 218.465 42.2959 217.029 43.125C215.593 43.9535 214.43 46.4171 214.43 48.6278Z" fill="#4355F4" />
        <path d="M163.262 26.2491C174.932 32.9872 184.393 49.374 184.393 62.8495C184.393 76.3256 174.932 81.7879 163.262 75.0498C151.592 68.3118 142.131 51.925 142.131 38.4489C142.131 24.9734 151.592 19.5116 163.262 26.2491Z" fill="#E0E0E0" />
        <path d="M39.7749 93.1739C53.8837 86.239 70.0937 76.565 76.5023 73.0091C84.3012 68.6818 93.2864 61.0982 98.0301 60.0918L109.158 80.0412L150.707 91.3461L87.6812 123.645L84.021 126.208C76.1409 131.741 70.012 136.629 61.844 143.18L39.7749 93.1739Z" fill="#FFBDA7" />
        <path d="M90.6001 101.192C97.6491 104.155 102.346 101.059 102.346 101.059C102.346 101.059 99.8315 111.344 92.3158 119.157C90.2537 121.301 91.1223 121.507 93.4641 120.681L116.177 109.041L120.919 87.4992L109.159 80.041C109.008 81.0351 107.455 82.7662 107.455 82.7662C106.443 89.4688 103.953 96.0506 103.953 96.0506C103.953 96.0506 98.4998 102.34 90.6001 101.192Z" fill="#F0997A" />
        <path d="M184.194 69.5317C184.194 69.5317 166.009 78.9841 162.612 84.3503C159.214 89.7165 150.954 108.925 146.837 113.042C142.725 117.154 133.35 125.624 128.503 128.724C123.424 131.967 121.43 132.615 117.009 131.561C114.506 132.821 108.246 134.424 106.277 131.119C103.234 133.36 96.5215 133.149 94.9126 130.107C92.4043 131.535 84.8794 130.333 84.021 126.211L87.6807 123.646C92.0342 119.492 97.5392 114.234 99.6055 110.585C104.365 102.746 108.246 88.6422 109.156 80.043L98.0275 60.0946C97.7448 60.1563 97.4467 60.2386 97.1331 60.3465C96.9892 60.3979 96.8401 60.4493 96.6911 60.5058C92.7847 61.9964 86.9302 66.4425 81.1426 70.1896C83.3733 67.1364 86.3031 63.0913 89.4231 59.0718C97.0098 49.3006 99.0401 48.7815 111.905 45.1013C127.798 40.5575 133.612 37.417 137.184 35.9212C143.357 33.3358 159.826 20.9844 159.826 20.9844L184.194 69.5317Z" fill="#B16668" />
        <path d="M183.86 69.6859C191.732 66.3362 193.313 52.3414 187.391 38.4277C181.47 24.514 170.289 15.9503 162.418 19.3C154.547 22.6497 152.966 36.6445 158.887 50.5582C164.808 64.4719 175.989 73.0356 183.86 69.6859Z" fill="#B16668" />
        <path d="M113.035 107.641C106.279 119.692 96.3955 127.909 90.5447 128.555C86.1767 129.037 84.021 126.207 84.021 126.207C84.8835 130.328 92.4085 131.535 94.9116 130.104C98.1683 129.394 108.104 119.829 113.035 107.641Z" fill="#9A4A4D" />
        <path d="M123.374 110.037C119.471 117.021 109.805 126.865 104.298 129.653C101.085 131.28 96.8314 131.447 94.9116 130.105C96.521 133.145 103.239 133.357 106.279 131.118C114.101 125.241 119.54 118.117 123.374 110.037Z" fill="#9A4A4D" />
        <path d="M133.92 112.723C129.058 119.418 121.59 126.663 116.082 129.689C110.574 132.715 107.358 131.73 106.28 131.118C108.247 134.423 114.506 132.813 117.01 131.561C118.938 130.641 130.085 121.061 133.92 112.723Z" fill="#9A4A4D" />
        <path d="M60.7527 143.75C68.6241 140.401 70.2051 126.406 64.284 112.492C58.3629 98.5785 47.1819 90.0147 39.3105 93.3644C31.4391 96.7142 29.8581 110.709 35.7792 124.623C41.7003 138.536 52.8813 147.1 60.7527 143.75Z" fill="#F0997A" />
        <path d="M133.429 126.776C130.298 125.134 128.989 121.924 131.147 118.611C133.304 115.298 137.233 115.53 139.852 116.993C137.926 113.757 138.157 110.753 140.314 108.288C142.471 105.823 147.016 105.687 149.712 107.642C146.708 103.974 145.937 101.277 147.632 97.8881C149.327 94.4983 153.975 93.7561 157.185 95.8851C161.23 98.5682 161.884 101.201 160.343 104.513C158.802 107.826 155.49 110.368 151.792 108.904C153.409 111.986 152.793 114.605 151.021 116.685C149.25 118.765 145.602 121.412 142.289 119.324C142.905 121.704 142.186 123.683 140.645 125.455C139.105 127.227 136.529 128.403 133.429 126.776Z" fill="#FFBDA7" />
        <path d="M168.28 98.3455C165.792 98.4267 160.578 96.8811 156.989 95.1546C156.989 95.1546 160.909 87.3007 161.579 86.1221C162.25 84.9435 163.152 83.2596 164.99 81.7402L171.375 85.4313L168.28 98.3455Z" fill="#F0997A" />
        <path d="M173.85 91.9878C173.206 97.337 170.724 98.8101 166.657 98.2293C164.822 97.9671 161.049 95.3416 161.11 90.6755C161.154 87.2276 164.785 83.8969 168.414 84.3955C171.91 84.8766 174.462 86.9017 173.85 91.9878Z" fill="#FFBDA7" />
        <path opacity="0.2" d="M152.325 106.225C153 103.298 155.561 99.9787 158.02 99.0391C161.623 102.18 157.436 108.998 152.325 106.225Z" fill="white" />
        <path opacity="0.3" d="M158.442 99.4718C158.314 99.3223 158.177 99.1763 158.02 99.0391C155.561 99.9787 153 103.298 152.325 106.225C152.483 106.311 152.639 106.387 152.795 106.455C153.681 103.74 156.481 100.344 158.442 99.4718Z" fill="white" />
        <path opacity="0.2" d="M166.001 95.1C165.581 92.1254 166.782 88.108 168.74 86.3496C173.229 87.9888 171.767 95.8555 166.001 95.1Z" fill="white" />
        <path opacity="0.3" d="M169.289 86.602C169.116 86.5079 168.936 86.4211 168.74 86.3496C166.782 88.108 165.581 92.1254 166.002 95.1C166.18 95.1231 166.353 95.138 166.522 95.1457C166.375 92.2935 167.771 88.1193 169.289 86.602Z" fill="white" />
        <path opacity="0.2" d="M142.701 116.328C143.672 113.685 146.491 111.021 148.947 110.588C152.044 114.226 147.281 119.898 142.701 116.328Z" fill="white" />
        <path opacity="0.3" d="M149.302 111.075C149.197 110.91 149.082 110.746 148.947 110.588C146.491 111.021 143.672 113.684 142.701 116.328C142.843 116.438 142.984 116.539 143.125 116.632C144.274 114.228 147.33 111.536 149.302 111.075Z" fill="white" />
        <path opacity="0.2" d="M134.412 125.137C135.054 122.832 137.26 120.384 139.311 119.85C142.181 122.719 138.534 127.848 134.412 125.137Z" fill="white" />
        <path opacity="0.3" d="M139.645 120.238C139.544 120.106 139.436 119.975 139.311 119.85C137.26 120.384 135.054 122.832 134.412 125.137C134.539 125.22 134.666 125.296 134.792 125.365C135.601 123.252 138.006 120.764 139.645 120.238Z" fill="white" />
        <path d="M156.74 95.6206C153.559 93.834 149.251 94.6502 147.632 97.8874C145.938 101.277 146.708 103.973 149.713 107.642C147.016 105.686 142.471 105.822 140.314 108.287C138.157 110.752 137.926 113.757 139.852 116.992C137.233 115.528 133.304 115.298 131.147 118.61C129.503 121.134 129.875 123.597 131.529 125.353C130.77 124.003 130.259 120.595 133.088 118.364C135.924 116.129 139.53 118.734 140.587 117.461C141.643 116.187 139.425 111.975 142.012 109.176C144.6 106.378 148.772 109.123 150.197 108.173C151.623 107.223 148.401 103.474 149.088 100.358C149.772 97.2475 152.985 94.8219 156.74 95.6206Z" fill="#F0997A" />
        <path d="M150.3 74.2486C149.718 72.5807 147.498 71.7603 142.449 67.4273C137.559 63.231 133.684 59.0738 128.602 58.3223C126.201 57.9672 119.745 58.3943 113.035 58.8908C107.287 59.3159 100.219 59.6279 98.0303 60.092C100.646 66.9349 104.994 73.6745 109.158 80.0414C114.046 80.0414 122.94 77.9571 127.595 74.5981C133.345 77.677 134.661 79.3629 140.446 80.0435C147.778 80.9059 150.97 76.1684 150.3 74.2486Z" fill="#FFBDA7" />
        <path d="M139.317 70.3141C139.317 70.3141 144.73 73.7059 146.594 74.2472C149.303 75.0341 150.301 74.2472 150.301 74.2472C150.301 74.2472 150.491 73.0095 146.66 70.3793C142.829 67.7492 142.142 67.1602 142.142 67.1602C142.142 67.1602 140.694 67.4295 139.317 70.3141Z" fill="#FFBDA7" />
        <path opacity="0.2" d="M139.317 70.3141C139.317 70.3141 144.73 73.7059 146.594 74.2472C149.303 75.0341 150.301 74.2472 150.301 74.2472C150.301 74.2472 150.491 73.0095 146.66 70.3793C142.829 67.7492 142.142 67.1602 142.142 67.1602C142.142 67.1602 140.694 67.4295 139.317 70.3141Z" fill="white" />
        <path opacity="0.2" d="M140.293 70.9129C142.396 69.6515 143.373 68.8332 143.808 68.3912C142.455 67.4274 142.142 67.1602 142.142 67.1602C142.142 67.1602 140.693 67.4295 139.317 70.3135C139.317 70.3135 139.702 70.5536 140.293 70.9129Z" fill="white" />
        <path d="M140.833 80.075C140.709 80.075 140.578 80.0596 140.447 80.0442C134.664 79.3647 133.344 77.6741 127.593 74.6014C122.938 77.9599 114.044 80.0442 109.157 80.0442C108.964 81.8971 108.625 84.0045 108.177 86.2435L106.002 89.545C107.23 84.9596 107.912 80.3567 107.325 79.5847C106.739 78.8126 104.695 77.7209 103.29 74.9802C104.772 76.0458 106.108 77.1884 107.783 77.8828C107.891 77.9291 107.999 77.9676 108.115 78.0062C109.473 78.5078 111.887 78.6852 113.96 78.4045C120.919 77.4613 125.197 74.6066 126.447 73.7261C125.655 72.6318 125.326 71.3422 125.148 69.959C126.787 72.8209 128.484 73.8381 129.993 74.9335C133.843 77.725 138.853 79.7373 140.833 80.075Z" fill="#F0997A" />
        <path d="M196.552 55.8307C196.552 62.5795 194.175 67.3186 190.336 69.5247L178.145 76.5654V76.5603C182.004 74.3691 184.391 69.6203 184.391 62.8509C184.391 49.3728 174.934 32.9849 163.264 26.251C157.5 22.9244 152.273 22.5723 148.465 24.6931L148.459 24.6879L160.47 17.7577C160.475 17.7525 160.48 17.7479 160.49 17.7428L160.515 17.7279C162.108 16.8181 163.952 16.3457 165.983 16.3457C168.822 16.3457 172.028 17.2704 175.42 19.2303C187.09 25.9698 196.552 42.3526 196.552 55.8307Z" fill="#F5F5F5" />
        <path d="M196.552 55.831C196.552 62.5799 194.175 67.3189 190.336 69.525L178.145 76.5658V76.5607C182.004 74.3695 184.391 69.6206 184.391 62.8513C184.391 60.7305 184.155 58.5393 183.718 56.3235L195.874 49.3027C196.316 51.5196 196.552 53.7108 196.552 55.831Z" fill="#EBEBEB" />
        <path d="M44.7639 95.3743C56.4342 102.112 65.8949 118.499 65.8949 131.975C65.8949 145.451 56.4342 150.913 44.7639 144.175C33.0935 137.437 23.6328 121.05 23.6328 107.575C23.6328 94.0986 33.0935 88.6363 44.7639 95.3743Z" fill="#E0E0E0" />
        <path d="M78.0548 124.956C78.0548 131.705 75.6776 136.444 71.8385 138.65L59.647 145.69V145.685C63.5066 143.494 65.8936 138.745 65.8936 131.976C65.8936 118.498 56.436 102.11 44.7667 95.376C39.0027 92.0494 33.7763 91.6973 29.9671 93.8181L29.9619 93.8129L41.9726 86.8827C41.9777 86.8775 41.9828 86.8724 41.9926 86.8677L42.0178 86.8528C43.6107 85.9431 45.4549 85.4707 47.4852 85.4707C50.3245 85.4707 53.5309 86.3954 56.9228 88.3553C68.5916 95.0948 78.0548 111.478 78.0548 124.956Z" fill="#F5F5F5" />
        <path d="M78.0548 124.956C78.0548 131.705 75.6776 136.443 71.8385 138.65L59.647 145.69V145.685C63.5066 143.494 65.8936 138.745 65.8936 131.976C65.8936 129.855 65.6572 127.664 65.2203 125.448L77.3769 118.428C77.8184 120.644 78.0548 122.835 78.0548 124.956Z" fill="#EBEBEB" />
        <path d="M13.0899 116.63L6.67822 105.426L30.7329 91.4586C30.7401 91.4545 30.7478 91.4499 30.755 91.4458L30.7817 91.4303L30.7823 91.4314C34.8146 89.125 40.373 89.4694 46.5112 93.0135C58.8266 100.124 68.8105 117.417 68.8105 131.637C68.8105 138.763 66.3038 143.764 62.254 146.087L62.2545 146.088L38.004 160.167L31.4165 148.656C21.9774 141.379 14.547 128.42 13.0899 116.63Z" fill="#455A64" />
        <path d="M22.2989 107.071C34.6143 114.182 44.5982 131.474 44.5982 145.695C44.5982 159.916 34.6148 165.68 22.2994 158.57C9.98394 151.459 1.98457e-08 134.166 1.98457e-08 119.945C-0.00051398 105.725 9.98342 99.9607 22.2989 107.071Z" fill="#263238" />
        <path d="M48.2441 143.661C48.2441 145.871 49.4078 146.991 50.8434 146.162C52.279 145.334 53.4427 142.869 53.4427 140.659C53.4427 138.449 52.279 137.329 50.8434 138.158C49.4078 138.987 48.2441 141.451 48.2441 143.661Z" fill="#263238" />
        <path d="M58.6919 138.364C58.6919 140.574 59.8556 141.694 61.2912 140.865C62.7268 140.036 63.8905 137.573 63.8905 135.362C63.8905 133.152 62.7268 132.032 61.2912 132.861C59.8561 133.69 58.6919 136.154 58.6919 138.364Z" fill="#263238" />
        <path d="M49.0913 143.892C49.0913 146.102 50.255 147.222 51.6906 146.393C53.1262 145.564 54.2899 143.1 54.2899 140.89C54.2899 138.68 53.1262 137.56 51.6906 138.389C50.255 139.218 49.0913 141.681 49.0913 143.892Z" fill="#4355F4" />
        <path d="M59.5381 138.597C59.5381 140.807 60.7018 141.927 62.1374 141.098C63.573 140.269 64.7367 137.805 64.7367 135.595C64.7367 133.385 63.573 132.265 62.1374 133.094C60.7023 133.922 59.5381 136.386 59.5381 138.597Z" fill="#4355F4" />
      </g>
    </svg>
  );
};

export default ClusterBoardSvg;