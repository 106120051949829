import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';

import GSSelectProcessLogs from 'pages/productionOrder/custom/company/gs/modal/GSSelectProcessLogs';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 40px 50px;
  height: 85%;
  overflow: hidden;
  width: 95%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  & .statusButtons {display: flex; gap: 5px;}
  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
  }
`;
const ListButton = styled.div`
  align-items: center;
  background-color: ${(props) => {
    switch (props.type) {
      case 'BL': return 'var(--MainBlue)';
      case 'CP': return 'var(--ThirdBlue)';
      case 'CL': return 'var(--MainNavy)';
      default: return null;
    }
  }};
  border: 4px solid var(--white);
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  min-height: 100px;
  width: 100%;
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const GSSelectWorkOrderLogs = (props) => {
  /* ========================================================================= #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_workOrderLogList, setWorkOrderLogList] = useState([]);

  const [_workOrderStatus, setWorkOrderStatus] = useState('work');
  const [_searchData, setSearchData] = useState({});

  const [_pageNumber, setPageNumber] = useState(1);
  const [_totalSize, setTotalSize] = useState(0);

  const [_modalStatus, setModalStatus] = useState(false);
  const [_selectedWorkOrder, setSelectedWorkOrder] = useState({});
  const [_processType, setProcessType] = useState();

  /* ====================================================================== #3 */
  useEffect(() => {
    getWorkOrderLogList(0, _workOrderStatus);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getWorkOrderLogList(_pageNumber - 1, _workOrderStatus);
    }

    return () => { };
  }, [_pageNumber]);

  /* ====================================================================== #4 */
  const getWorkOrderLogList = async (page, workOrderStatus, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = { companyId: userReducer.company.companyId };
    if (checkEmptyNull(workOrderStatus, false)) {
      switch (workOrderStatus) {
        case 'all': BodyToPost.workOrderStatus = ['wait', 'work', 'pause', 'stop', 'end', 'cancel']; break;
        case 'waitNwork': BodyToPost.workOrderStatus = ['wait', 'work']; break;
        default: BodyToPost.workOrderStatus = [workOrderStatus]; break;
      }
    }
    if (checkEmptyNull(_searchData.workOrderCode, false)) {
      BodyToPost.workOrderCode = _searchData.workOrderCode;
    }
    if (checkEmptyNull(_searchData.workOrderName, false)) {
      BodyToPost.workOrderName = _searchData.workOrderName;
    }
    await workOrderLogApi.searchWorkOrderLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.searchWorkOrderLog : ', response);
      setWorkOrderLogList(() => { return response.data.data.content });
      setTotalSize(() => { return response.data.data.totalElements });
    });
  };

  /* ====================================================================== #5 */
  const handleWorkOrderStatus = async (workOrderStatus) => {
    setWorkOrderStatus(() => { return workOrderStatus });
    setPageNumber(() => { return 1 });
    await getWorkOrderLogList(0, workOrderStatus);
  }

  const actSearch = async () => {
    setPageNumber(() => { return 1 });
    await getWorkOrderLogList(0, _workOrderStatus);
  };
  const actReset = async () => {
    setWorkOrderStatus(() => { return 'all' });
    setSearchData(() => { return {} });
    setPageNumber(() => { return 1 });
    await getWorkOrderLogList(0, 'all', true);
  };

  const actShowList = (processType, workOrderLog) => {
    setSelectedWorkOrder(workOrderLog);
    setProcessType(processType);
    setTimeout(setModalStatus(true), 1000);
  };

  /* ========================================================================= #5 */
  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>작업지시 선택</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <NavBar
                title={
                  <>
                    <div className='statusButtons'>
                      {[
                        { status: 'all', name: '전체' },
                        { status: 'waitNwork', name: '대기/작업중' },
                        { status: 'wait', name: '대기' },
                        { status: 'work', name: '작업중' },
                        { status: 'pause', name: '일시정지' },
                        { status: 'stop', name: '중단' },
                        { status: 'end', name: '완료' },
                        { status: 'cancel', name: '취소' },
                      ].map((workOrderStatus, index) => {
                        return (
                          <button key={index + '_statusButton'}
                            className={_workOrderStatus === workOrderStatus.status ? 'btn-inven-1' : 'btn-set'}
                            onClick={() => { handleWorkOrderStatus(workOrderStatus.status); }}
                          >
                            {workOrderStatus.name}
                          </button>
                        )
                      })}
                    </div>
                  </>
                }
                nav={'search'}
                firstRow={
                  <div className="SearchSection" style={{ columnGap: '20px' }}>
                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="workOrderCode">작업코드</option>
                      </select>
                      <input
                        className="SearchBar detail"
                        placeholder="Search..."
                        value={checkEmptyNull(_searchData.workOrderCode, '')}
                        onInput={(e) => { setSearchData((prev) => { return { ...prev, workOrderCode: e.target.value } }) }}
                      />
                    </div>
                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="workOrderName">공사명</option>
                      </select>
                      <input
                        className="SearchBar detail"
                        placeholder="Search..."
                        value={checkEmptyNull(_searchData.workOrderName, '')}
                        onInput={(e) => { setSearchData((prev) => { return { ...prev, workOrderName: e.target.value } }) }}
                      />
                    </div>
                    <div className="ContentCBox">
                      <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                      <div className="ResetButton" onClick={actReset}>초기화</div>
                    </div>
                  </div>
                }
              />

              <TableSection content={
                <table>
                  <thead style={{ zIndex: '800' }}>
                    <tr>
                      <th style={{ minWidth: '160px', padding: 'unset', width: '160px' }}>캠버리스트</th>
                      <th style={{ minWidth: '160px', padding: 'unset', width: '160px' }}>조합리스트</th>
                      <th style={{ minWidth: '160px', padding: 'unset', width: '160px' }}>체크리스트</th>
                      <th style={{ minWidth: '120px', padding: 'unset', width: '120px' }}>작업상태</th>
                      <th>거래처</th>
                      <th>작업코드</th>
                      <th>공사명</th>
                      <th>현장명</th>
                      <th>현장주소</th>
                      <th style={{ minWidth: '180px', padding: 'unset', width: '180px' }}>공사기간</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkNullArray(_workOrderLogList, []).map((workOrderLog, index) => {
                      return (
                        <tr key={index + '_workOrderRow'} data-key={workOrderLog.workOrderLogId}>
                          <td style={{ minWidth: '160px', padding: 'unset', width: '160px' }}>
                            <ListButton
                              type="BL"
                              data-type={'BL'}
                              data-workorderlogid={workOrderLog.workOrderLogId}
                              onClick={() => { actShowList('BL', workOrderLog) }}
                            >
                              캠버리스트
                            </ListButton>
                          </td>
                          <td style={{ minWidth: '160px', padding: 'unset', width: '160px' }}>
                            <ListButton
                              type="CP"
                              data-type={'CP'}
                              data-workorderlogid={workOrderLog.workOrderLogId}
                              onClick={() => { actShowList('CP', workOrderLog) }}
                            >
                              조합리스트
                            </ListButton>
                          </td>
                          <td style={{ minWidth: '160px', padding: 'unset', width: '160px' }}>
                            <ListButton
                              type="CL"
                              data-type={'CL'}
                              data-workorderlogid={workOrderLog.workOrderLogId}
                              onClick={() => { actShowList('CL', workOrderLog) }}
                            >
                              체크리스트
                            </ListButton>
                          </td>
                          <StatusTdButton
                            statusStyle={{ cursor: 'default', height: '120px', minWidth: '120px', width: '120px' }}
                            status={workOrderLog.workOrderStatus}
                          />
                          <td>{workOrderLog.accountName}</td>
                          <td>{workOrderLog.workOrderCode}</td>
                          <td>{workOrderLog.workOrderName}</td>
                          <td>{workOrderLog.workPlace}</td>
                          <td>{workOrderLog.detailedWorkPlace}</td>
                          <td style={{ minWidth: '180px', padding: 'unset', width: '180px' }}>
                            {DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledStartDate, ''))}<br /> - <br />{DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledEndDate, ''))}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
              ></TableSection>
            </ModalMain>

            <PagingComponent
              page={_pageNumber}
              count={_totalSize}
              size={10}
              pageEvent={(page) => {
                console.log('page : ', page);
                setPageNumber(() => { return page });
              }}
            />

            <ModalFooter>
              <ModalButton onClick={(e) => { e.preventDefault(); props.close() }}>확인</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}

      {_modalStatus === true ? (
        <GSSelectProcessLogs
          content={_selectedWorkOrder}
          processType={_processType}
          update={(data) => { props.update(data); }}
          open={_modalStatus}
          close={() => {
            setModalStatus(false);
            getWorkOrderLogList(_pageNumber - 1, 'all', _workOrderStatus);
          }}
        ></GSSelectProcessLogs>
      ) : null}
    </>
  );
};

export default GSSelectWorkOrderLogs;
