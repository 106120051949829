import styled from 'styled-components';

export const ContractFormComponent = styled.section`
  & div.formBox {
    & div.flexBox {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      width: 100%;
    }
    &.w100:nth-of-type(1) {
      & div.formButton { // 수주물품 추가 버튼
        background-color: var(--MainBlue);
        border-radius: 5px;
        width: 150px;
      }

      & div.cautionText { // 수주수량 입력 안내 문구
        font-size: 14px;
        text-align: right;
        width: 100%;
      }
    }
    &.w100:nth-of-type(2) {
      place-content: unset;
      & div.searchBox {width: 30%;}
      & div.formButton { // 나라장터 수주조회 버튼
        background-color: var(--ThirdBlue);
        border-radius: 5px;
        /* width: 150px; */
        width: 100%;
      }
    }
    &.account {
      &>div.flexBox div.formButton { // 거래처 선택 버튼
        background-color: var(--ThirdBlue);
        border-radius: 5px;
        width: 150px;
      }

      &>div:nth-of-type(2) { // 거래처 표시란
        column-gap: 5px;
        display: grid;
        grid-template-columns: auto 50px;
        width: 100%;

        & input {
          background-color: var(--ThirdBlue);
          color: var(--white);
          &::placeholder {color: var(--white);}
        }

        & div.formButton { // 거래처 삭제 버튼
          background-color: var(--MainNavy);
          border-radius: 5px;
          height: 40px;
          width: 50px;
        }
      }
    }
  }

  & div.table-box { // 수주 물품 테이블
    align-items: flex-start;
    display: flex;
    justify-content: center;
    width: 100%;

    & .TableSection {
      overflow: auto;

      table {
        thead {background-color: var(--MainNavy);}
      }
    }
    & > .TableSection {
      max-height: 350px;
      &::-webkit-scrollbar-thumb {background-color: var(--ThirdBlue);}
    }
  }

  & div.delete { // 수주 물품 삭제 버튼
    height: 15px;
    margin: 0px auto;
    width: 15px;

    & div {
      background-color: var(--MainRed);
      cursor: pointer;
      height: 15px;
      width: 15px;
    }
  }
`;