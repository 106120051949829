export const gsReducer_reset = () => {
  return {
    type: 'GSRESET',
  };
};

export const gsReducer_setUpdateOrder = (thisContents) => {
  return {
    type: 'SETGSUPDATEORDER',
    payload: thisContents,
  };
};

export const gsReducer_setDeleteOrder = (thisContents) => {
  return {
    type: 'SETGSDELETEORDER',
    payload: thisContents,
  };
};

export const gsReducer_setProcurementContent = (thisContents) => {
  return {
    type: 'SETGSSHIPMENTCONTENT',
    payload: thisContents,
  };
};

export const gsReducer_setWorkOrderContent = (thisContents) => {
  return {
    type: 'SETGSWORKORDERCONTENT',
    payload: thisContents,
  };
};

export const gsReducer_setInspectionContent = (thisContents) => {
  return {
    type: 'SETGSINSPECTIONCONTENT',
    payload: thisContents,
  };
};

export const gsReducer_setOrderProducts = (thisContents) => {
  return {
    type: 'SETGSORDERPRODUCTS',
    payload: thisContents,
  };
};

export const gsReducer_setBarcodeContent = (thisContents) => {
  return {
    type: 'SETGSBARCODECONTENT',
    payload: thisContents,
  };
};

export const gsReducer_setStatusContent = (thisContents) => {
  return {
    type: 'SETGSSTATUSCONTENT',
    payload: thisContents,
  };
};
