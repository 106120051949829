import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { shipmentInspectionItemType } from 'api/apis/shipmentInspection/shipmentInspectionItemType';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { Content, ContentHeader, Title, SearchBar, ButtonsWrap } from 'pages/types/typeStyle'

import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ShipmentInspectionItemType = (props) => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_typeList, setTypeList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState({});
  const [_searchText, setSearchText] = useState();

  /* ====================================================================== #3 */
  useEffect(() => {
    getShipmentInspectionItemTypes();

    setOnload('loaded');
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const getShipmentInspectionItemTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (checkEmptyNull(_searchText, false)) {
      BodyToPost.shipmentInspectionItemTypeName = _searchText;
    }

    await shipmentInspectionItemType.searchShipmentInspectionItemType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('shipmentInspectionItemType.searchShipmentInspectionItemType : ', response);
      setTypeList(() => { return response.data; });
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (shipmentInspection) => {
    setCheckedItem(() => { return shipmentInspection; });
  };

  const actSearch = async () => {
    getShipmentInspectionItemTypes();
  };

  /* 추가 */
  const actCreate = async () => {
    const promptText = `추가할 점검 / 검사 항목을 입력해주세요.`;
    const CreatePrompt = window.prompt(promptText, '');
    if (CreatePrompt === null) return;

    const BodyToPost = {
      companyId: userReducer.company.companyId,
      shipmentInspectionItemTypeName: CreatePrompt,
    };

    await shipmentInspectionItemType.createShipmentInspectionItemType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('shipmentInspectionItemType.createShipmentInspectionItemType : ', response);

      setTypeList((prev) => {
        return [...prev, response.data];
      });
      alert('점검 / 검사 항목을 추가했습니다.');
    });
  };

  /* 수정 */
  const actUpdate = async () => {
    const updateContent = _checkedItem;
    if (!checkNullObject(updateContent, false)) return alert('수정할 점검 / 검사 항목을 선택해주세요.');
    const updateContentIndex = _typeList.findIndex(type => type.shipmentInspectionItemTypeId === updateContent.shipmentInspectionItemTypeId);

    const promptText = `점검 / 검사 항목을 수정합니다.`;
    const UpdatePrompt = window.prompt(promptText, updateContent.shipmentInspectionItemTypeName);
    if (UpdatePrompt === null) return;

    const BodyToPut = {
      companyId: userReducer.company.companyId,
      shipmentInspectionItemTypeId: updateContent.shipmentInspectionItemTypeId,
      shipmentInspectionItemTypeName: UpdatePrompt,
    };

    await shipmentInspectionItemType.updateShipmentInspectionItemType(updateContent.shipmentInspectionItemTypeId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('shipmentInspectionItemType.updateShipmentInspectionItemType : ', response);

      setTypeList((prev) => {
        const prevData = [...prev];
        prevData.splice(updateContentIndex, 1, response.data);
        return prevData;
      });
      alert('점검 / 검사 항목을 수정했습니다.');
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const deleteContent = _checkedItem;
    if (!checkNullObject(deleteContent, false)) return alert('삭제할 점검 / 검사 항목을 선택해주세요.');

    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({
        ...deleteContent,
        type: 'shipmentInspectionItemType',
      })}`,
      replace: true,
    });
  };

  /* ====================================================================== #6 */

  return (
    <Content>
      <ContentHeader>
        <Title>점검 / 검사 항목</Title>
        <div className='content-snb'>
          <ButtonsWrap>
            {props.authority.indexOf('113-2') !== -1 ? (
              <>
                <button className='btn-add' onClick={actCreate}>추가</button>
                <button className='btn-edit' onClick={actUpdate}>수정</button>
                <button className='btn-delete' onClick={actDelete}>삭제</button>
              </>
            ) : null}
            <SearchBar>
              <input
                placeholder="점검 / 검사 항목..."
                onInput={(e) => { setSearchText(() => { return e.target.value; }); }}
              />
              <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
            </SearchBar>
          </ButtonsWrap>
        </div>
      </ContentHeader>
      <TableSection content={
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th style={{ minWidth: 'unset', width: '50px' }}></th>
              <th>점검 / 검사 항목</th>
            </tr>
          </thead>
          <tbody>
            {checkNullArray(_typeList, []).map((type, index) => {
              return (
                <tr key={index + '_shipmentInspectionItemTypes'}>
                  <td style={{ minWidth: 'unset', width: '50px' }}>
                    <input
                      type="checkBox"
                      name="shipmentInspectionItemTypes"
                      checked={type.shipmentInspectionItemTypeId === _checkedItem.shipmentInspectionItemTypeId}
                      onChange={(e) => {
                        e.preventDefault();
                        handleCheckedItem(type);
                      }}
                    />
                  </td>
                  <td>{type.shipmentInspectionItemTypeName}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      }
      ></TableSection>
    </Content>
  );
};

export default ShipmentInspectionItemType;
