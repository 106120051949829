import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { daerimReducer_setDeleteContent, daerimReducer_setEquipment, daerimReducer_setProcessUser, daerimReducer_setUser, daerimReducer_setWorkOrderContent, daerimReducer_setWorkOrderContents } from 'store/modules/actions/company/daerimActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { productionOrderActions_setEndDate, productionOrderActions_setPageNumber, productionOrderActions_setReset, productionOrderActions_setSearchData, productionOrderActions_setStartDate, productionOrderActions_setTotalSize, productionOrderActions_setWorkOrderStatus, productionOrderActions_setWorkOrderType } from 'store/modules/actions/common/productionOrderActions';
import { workActions_setCurrentMessage } from 'store/modules/actions/default/workActions';
import { workerReducer_resetWorkerReducer } from 'store/modules/actions/common/workerActions';

import { getCookie } from 'preferences/cookie/cookie';
import { WS_CLIENT } from 'preferences/server/constants';
import { workOrderTypeApi } from 'api/apis/workOrderTypeApi';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

import { DateFormat } from 'components/format/DateFormat';
import { LoadingMsg } from 'components/loading/LoadingMsg';

import { baseInformationIcon } from 'components/icons/src';
import DOCButtonIcon from 'components/icons/DOCButtonIcon';
import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import ShowFileList from 'pages/file/modal/ShowFileList';
import ShowWorkOrderLog from 'pages/productionOrder/modal/ShowWorkOrderLog';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';

const Section = styled.main`
  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
    & .statusButtons {
      display: flex;
      gap: 5px;
    }
  }

  & .TableSection {
    tr[name='contractRows']:hover {cursor: pointer;}
  }
`;

const ProcessSection = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  height: 100%;
  width: 100%;
`;
const Process = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  font-size: 0.7em;
  font-weight: 400;
  height: 40px;
  justify-content: flex-start;
  padding-left: 20px;
  width: 100%;

  background-color: ${(props) => {
    switch (props.status) {
      case 'wait': return 'var(--gray-200)'
      case 'work': return 'var(--WorkingColor)'
      case 'end': return 'var(--ThirdBlue)'
      case 'stop': return 'var(--MainRed)'
      case 'pause': return 'var(--MainYellow)'
      case 'cancel': return 'var(--Bg)'

      case 'NO': return 'var(--MainNavy)';
      case 'cut': return '#BCE55C'
      case 'welding': return '#9FC93C'
      case 'last': return '#689900'

      default: return 'unset';
    }
  }};

  color: ${(props) => {
    return props.status === 'cancel' ? 'var(--gray-200)' : 'var(--white)';
  }};
`;

const CancelWorkOrderLogButton = styled.td`
  background-color: var(--ThirdRed);
  color: white;
  cursor: pointer;
  font-size: 1em;
  min-width: 60px;
  width: 60px;
`;

const ConnectStatus = styled.div`
  background-color: ${(props) => {
    return props.connectStatus === 'connect' ? 'var(--MainGreen)' : 'var(--MainRed)';
  }};
  border-radius: 50%;
  bottom: 25px;
  cursor: pointer;
  height: 42px;
  position: fixed;
  right: 80px;
  width: 42px;
`;

const ProductionOrder = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, productionOrderReducer, userReducer, workReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const RoomId = userReducer.company.companyId;

  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_workOrderTypes, setWorkOrderTypes] = useState([]);
  const [_workOrderLogList, setWorkOrderLogList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState([]);

  const [_modalStatus, setModalStatus] = useState(false);
  const [_showWokrOrderLog, setShowWorkOrderLog] = useState();
  const [_showProcessList, setShowProcessList] = useState([]);

  const [_fileListModalStatus, setFileListModalStatus] = useState(false);
  const [_eventcontent, setEventContent] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('104') === true || // 거래처
      thisItem.authority.startsWith('109') === true || // 파일
      thisItem.authority.startsWith('403') === true ||
      thisItem.authority.startsWith('409') === true ||
      thisItem.authority.startsWith('410') === true,
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getWorkOrderTypes();
    getWorkOrderLogList(productionOrderReducer.pageNumber - 1, productionOrderReducer.workOrderType, productionOrderReducer.workOrderStatus)
    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getWorkOrderLogList(productionOrderReducer.pageNumber - 1, productionOrderReducer.workOrderType, productionOrderReducer.workOrderStatus)
    }
    return () => { };
  }, [productionOrderReducer.pageNumber]);

  useEffect(() => {
    if (workReducer.connectStatus === 'connect') {
      if (!checkNullObject(workReducer.currentMessage, false)) return;

      if (workReducer.currentMessage.userId === userReducer.user.id && checkEmptyNull(workReducer.currentMessage.errorMessage, false)) {
        return dispatch(workActions_setCurrentMessage({}));;
      }

      if (workReducer.currentMessage.type === '/work') applyStatus();
      if (workReducer.currentMessage.type === 'process') applyProcessStatus();
      if (workReducer.currentMessage.type === 'processes') applyProcessesStatus();
    }

    return () => { };
  }, [workReducer.currentMessage]);

  /* ====================================================================== #4 */
  const getWorkOrderTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await workOrderTypeApi.searchWorkOrderType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderTypeApi.searchWorkOrderType : ', response);
      setWorkOrderTypes(() => { return response.data });
    });
  };

  const getWorkOrderLogList = async (page, workOrderType, workOrderStatus, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    if (!clear && checkEmptyNull(workOrderType, false) && workOrderType !== 'all') {
      BodyToPost.workOrderTypeId = Number(workOrderType);
    }
    if (!clear && checkEmptyNull(workOrderStatus, false)) {
      switch (workOrderStatus) {
        case 'all': BodyToPost.workOrderStatus = ['wait', 'work', 'pause', 'stop', 'end', 'cancel']; break;
        case 'waitNwork': BodyToPost.workOrderStatus = ['wait', 'work']; break;
        default: BodyToPost.workOrderStatus = [workOrderStatus]; break;
      }
    }
    if (!clear && checkEmptyNull(productionOrderReducer.startDate, false) && checkEmptyNull(productionOrderReducer.endDate, false)) {
      BodyToPost.scheduledStartDate = DateFormat(productionOrderReducer.startDate);
      BodyToPost.scheduledEndDate = DateFormat(productionOrderReducer.endDate);
      if (productionOrderReducer.startDate > productionOrderReducer.endDate) {
        BodyToPost.scheduledStartDate = DateFormat(productionOrderReducer.endDate);
        BodyToPost.scheduledEndDate = DateFormat(productionOrderReducer.startDate);
      }
    } else if (!clear && checkEmptyNull(productionOrderReducer.startDate, false) && !checkEmptyNull(productionOrderReducer.endDate, false)) {
      BodyToPost.scheduledStartDate = DateFormat(productionOrderReducer.startDate);
    } else if (!clear && checkEmptyNull(productionOrderReducer.endDate, false) && !checkEmptyNull(productionOrderReducer.startDate, false)) {
      BodyToPost.scheduledEndDate = DateFormat(productionOrderReducer.endDate);
    }
    if (!clear && checkEmptyNull(productionOrderReducer.searchData.workOrderCode, false)) {
      BodyToPost.workOrderCode = productionOrderReducer.searchData.workOrderCode;
    }
    if (!clear && checkEmptyNull(productionOrderReducer.searchData.workOrderName, false)) {
      BodyToPost.workOrderName = productionOrderReducer.searchData.workOrderName;
    }
    // if (!clear && checkNull(productionOrderReducer.searchOption, false) && checkNull(productionOrderReducer.searchText, false)) {
    //   BodyToPost[productionOrderReducer.searchOption] = productionOrderReducer.searchText;
    // }
    console.log('getWorkOrderLogList - BodyToPost : ', BodyToPost);

    await workOrderLogApi.searchWorkOrderLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.searchWorkOrderLog : ', response);

      setWorkOrderLogList(() => { return response.data.data.content });
      dispatch(productionOrderActions_setTotalSize(response.data.data.totalElements));

      if (checkEmptyNull(productionOrderReducer.startDate, false) && checkEmptyNull(productionOrderReducer.endDate, false)) {
        if (productionOrderReducer.startDate > productionOrderReducer.endDate) {
          dispatch(productionOrderActions_setStartDate(productionOrderReducer.endDate))
          dispatch(productionOrderActions_setEndDate(productionOrderReducer.startDate))
        }
      }
    });
  };

  /* 웹소켓 */
  const applyStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    if (eventWorkOrder === undefined) return;
    eventWorkOrder.workOrderStatus = applyContent.workOrderStatus;
    eventWorkOrder.managers = checkNullArray(applyContent.managers, []);
    eventWorkOrder.workers = checkNullArray(applyContent.workers, []);
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, eventWorkOrder);
    setWorkOrderLogList(() => { return eventWorkOrderList });
  };
  const applyProcessStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    if (eventWorkOrder === undefined) return;

    const eventProcessLogList = [...eventWorkOrder.processLogList];
    const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === applyContent.processLog.processLogId);
    const eventContent = eventProcessLogList[eventProcessLogIndex];
    if (eventContent === undefined) return;

    eventContent.processStatus = applyContent.processLog.processStatus;
    eventContent.completedAmount = applyContent.processLog.completedAmount;
    eventContent.processAmount = applyContent.processLog.processAmount;
    eventContent.processingCount = applyContent.processLog.processingCount;
    eventContent.managers = checkNullArray(applyContent.managers, []);
    eventContent.workers = checkNullArray(applyContent.workers, []);
    if (checkNullArray(eventContent.processLogElementList, false)) {
      const eventResultElements = eventContent.processLogElementList.map((thisItem) => {
        const thisFindIndex = applyContent.processResultElements.findIndex((thisElement) => thisElement.processLogElementId === thisItem.processLogElementId);
        if (thisFindIndex === -1) return thisItem;

        const thisFindItem = applyContent.processResultElements[thisFindIndex];
        const returnData = { ...thisItem, ...thisFindItem };
        return returnData;
      });
      eventContent.processLogElementList = eventResultElements;
    }
    eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);

    const detailWorkOrder = { ...eventWorkOrder, processLogList: eventProcessLogList };
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, detailWorkOrder);
    setWorkOrderLogList(eventWorkOrderList);
  };
  const applyProcessesStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    if (eventWorkOrder === undefined) return;

    const eventProcessLogList = [...eventWorkOrder.processLogList];
    if (checkNullArray(applyContent.socketProcessResults, false)) {
      applyContent.socketProcessResults.forEach((processResult) => {
        const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === processResult.processLog.processLogId);
        if (eventProcessLogIndex === -1) return;

        const eventContent = eventProcessLogList[eventProcessLogIndex];
        eventContent.processStatus = processResult.processLog.processStatus;
        eventContent.completedAmount = processResult.processLog.completedAmount;
        eventContent.processAmount = processResult.processLog.processAmount;
        eventContent.processingCount = processResult.processLog.processingCount;
        eventContent.managers = checkNullArray(processResult.managers, []);
        eventContent.workers = checkNullArray(processResult.workers, []);
        if (checkNullArray(eventContent.processLogElementList, false)) {
          const eventResultElements = eventContent.processLogElementList.map((thisItem) => {
            const thisFindIndex = processResult.processResultElements.findIndex((thisElement) => thisElement.processLogElementId === thisItem.processLogElementId);
            if (thisFindIndex === -1) return thisItem;
            const thisFindItem = processResult.processResultElements[thisFindIndex];
            const returnData = { ...thisItem, ...thisFindItem };
            return returnData;
          });
          eventContent.processLogElementList = eventResultElements;
        }
        eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
      });
    } else {
      applyContent.processLogIds.forEach((processLogIds) => {
        const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === processLogIds);
        if (eventProcessLogIndex === -1) return;

        const eventContent = eventProcessLogList[eventProcessLogIndex];
        eventContent.processStatus = applyContent.processStatus;
        eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
      });
    }

    const detailWorkOrder = { ...eventWorkOrder, processLogList: eventProcessLogList };
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, detailWorkOrder);
    setWorkOrderLogList(eventWorkOrderList);
  };

  /* ====================================================================== #5 */
  const handleWorkOrderStatus = async (workOrderStatus) => {
    dispatch(productionOrderActions_setWorkOrderStatus(workOrderStatus));
    dispatch(productionOrderActions_setPageNumber(1));
    await getWorkOrderLogList(0, productionOrderReducer.workOrderType, workOrderStatus)
  }

  const handleCheckedItem = (item, isChecked) => {
    const checkedItem = [..._checkedItem];
    const checkedIndex = checkedItem.findIndex((thisItem) => thisItem.workOrderLogId === item.workOrderLogId);
    if (checkedIndex !== -1) {
      if (!isChecked) checkedItem.splice(checkedIndex, 1);
    } else {
      if (isChecked) checkedItem.push(item);
    }
    console.log('checkedItem : ', checkedItem);
    setCheckedItem(() => { return checkedItem });
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(productionOrderActions_setPageNumber(1));
    await getWorkOrderLogList(0, productionOrderReducer.workOrderType, productionOrderReducer.workOrderStatus);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(productionOrderActions_setReset());
    getWorkOrderLogList(0, 'all', 'all', true);
  };

  // 리스트 팝업
  const actShowList = (e) => {
    e.preventDefault();

    const workOrderLogId = Number(e.currentTarget.dataset.workorderlogid);
    const thisFindIndex = _workOrderLogList.findIndex((thisItem) => thisItem.workOrderLogId === workOrderLogId);
    if (thisFindIndex === -1) return;

    const thisFindItem = _workOrderLogList[thisFindIndex];
    setShowWorkOrderLog(workOrderLogId);
    setShowProcessList(thisFindItem);
    setTimeout(setModalStatus(true), 1000);
  };

  const actCreate = () => {
    setTimeout(navigate(pageReducer.currentPage + '/create', { replace: true }), 1000);
  };

  const actCopy = () => {
    if (checkEmptyNull(_checkedItem, []).length < 1 || checkEmptyNull(_checkedItem, []).length > 1) return;
    const checkedItem = _checkedItem[0];

    navigate({
      pathname: pageReducer.currentPage + '/copy',
      search: `?${createSearchParams({ copyContent: JSON.stringify(checkedItem) })}`,
      replace: true,
    });
  };

  const actUpdate = () => {
    if (checkEmptyNull(_checkedItem, []).length < 1 || checkEmptyNull(_checkedItem, []).length > 1) return;
    const checkedItem = _checkedItem[0];

    dispatch(workerReducer_resetWorkerReducer());
    setTimeout(navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({ updateContent: JSON.stringify(checkedItem) })}`,
      replace: true,
    }), 1000)
  };

  const actDelete = () => {
    if (checkEmptyNull(_checkedItem, []).length < 1 || checkEmptyNull(_checkedItem, []).length > 1) return;
    const checkedItem = _checkedItem[0];

    // 초기화
    dispatch(workerReducer_resetWorkerReducer());

    dispatch(daerimReducer_setEquipment({}));
    dispatch(daerimReducer_setUser({}));
    dispatch(daerimReducer_setProcessUser({}));
    dispatch(daerimReducer_setWorkOrderContent({}));
    dispatch(daerimReducer_setWorkOrderContents([]));

    dispatch(daerimReducer_setDeleteContent(checkedItem));
    setTimeout(navigate(pageReducer.currentPage + '/delete', { replace: true }), 1000);
  };

  const actCancel = (workOrderLog) => {
    const confirmText = `
    [ 취소 ]상태로 바꿀 시,
    작업 상태를 다시 변경할 수 없습니다.
    작업을 취소하시겠습니까?
    `;
    if (window.confirm(confirmText) === true) {
      const BodyToWS = {
        roomId: RoomId,
        type: '/work',
        workOrderLogId: workOrderLog.workOrderLogId,
        workOrderStatus: 'cancel',
      };
      console.log('client.publish/pub/work - body : ', JSON.stringify(BodyToWS));

      try {
        WS_CLIENT.publish({
          destination: '/pub/work',
          headers: { 'auth-token': getCookie('accessToken')?.replace('Bearer ', '') },
          body: JSON.stringify(BodyToWS),
        });
      } catch (error) {
        console.log('client.publish/pub/work - error : ', error);
      }
    }
  };

  const showFileList = (content, work) => {
    console.log('showFileList : ', work, content);
    setEventContent(() => {
      if (work === true) return { ...content, work: true };
      else return content;
    });

    setTimeout(setFileListModalStatus(true), 1000);
  };

  /* ====================================================================== #6 */

  return (
    <Grid3Body
      contents={
        <>
          <Section className="Main">
            <NavBar
              title={
                <div className='Title'>
                  <NavTitle menuCode={'403'} />
                  <div className='statusButtons'>
                    {[
                      { status: 'all', name: '전체' },
                      { status: 'waitNwork', name: '대기/작업중' },
                      { status: 'wait', name: '대기' },
                      { status: 'work', name: '작업중' },
                      { status: 'pause', name: '일시정지' },
                      { status: 'stop', name: '중단' },
                      { status: 'end', name: '완료' },
                      { status: 'cancel', name: '취소' },
                    ].map((workOrderStatus, index) => {
                      return (
                        <button key={index + '_statusButton'}
                          className={productionOrderReducer.workOrderStatus === workOrderStatus.status ? 'btn-inven-1' : 'btn-set'}
                          onClick={() => { handleWorkOrderStatus(workOrderStatus.status); }}
                        >
                          {workOrderStatus.name}
                        </button>
                      )
                    })}
                  </div>
                </div>
              }
              buttons={
                <>
                  {_authority.indexOf('403-2') !== -1 ? (
                    <>
                      <button className='btn-add' onClick={actCreate}>추가</button>
                      <button className='btn-copy' onClick={actCopy}>복사</button>
                      <button className='btn-edit' onClick={actUpdate}>수정</button>
                      <button className='btn-delete' onClick={actDelete}>삭제</button>
                    </>
                  ) : null}
                </>
              }
              nav={'search'}
              firstRow={
                <>
                  <div className="SearchSection" style={{ columnGap: '20px' }}>
                    <select
                      className="SelectType"
                      value={productionOrderReducer.workOrderType}
                      onChange={(e) => {
                        dispatch(productionOrderActions_setWorkOrderType(e.target.value));
                        dispatch(productionOrderActions_setPageNumber(1));
                        getWorkOrderLogList(0, e.target.value, productionOrderReducer.workOrderStatus)
                      }}
                    >
                      <option value="all">전체</option>
                      {_workOrderTypes.map((thisItem) => {
                        return (<option key={thisItem.workOrderTypeId + '_workOrderType'} value={thisItem.workOrderTypeId}>{thisItem.workOrderTypeName}</option>);
                      })}
                    </select>
                    <div className="SearchSection">
                      <input
                        type="date"
                        className="SelectDate"
                        value={productionOrderReducer.startDate}
                        onChange={(e) => { dispatch(productionOrderActions_setStartDate(e.target.value)); }}
                      />
                      <IconRightArrow />
                      <input
                        type="date"
                        className="SelectDate"
                        value={productionOrderReducer.endDate}
                        onChange={(e) => { dispatch(productionOrderActions_setEndDate(e.target.value)); }}
                      />
                    </div>
                  </div>
                </>
              }
              secondRow={
                <div className="SearchSection">
                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="workOrderCode">작업코드</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkEmptyNull(productionOrderReducer.searchData.workOrderCode, '')}
                      onInput={(e) => { dispatch(productionOrderActions_setSearchData({ ...productionOrderReducer.searchData, workOrderCode: e.target.value })); }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="workOrderName">작업이름</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkEmptyNull(productionOrderReducer.searchData.workOrderName, '')}
                      onInput={(e) => { dispatch(productionOrderActions_setSearchData({ ...productionOrderReducer.searchData, workOrderName: e.target.value })); }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton" onClick={actReset}>초기화</div>
                  </div>
                </div>
              }
            />

            <TableSection content={
              <table>
                <thead style={{ zIndex: '800' }}>
                  <tr>
                    {_authority.indexOf('403-2') !== -1 ? (
                      <th style={{ minWidth: '50px', width: '50px' }}>
                        <input
                          type="checkBox"
                          name="allElements"
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            if (isChecked) {
                              setCheckedItem((prev) => {
                                const prevData = [...prev];
                                const newData = checkNullArray(_workOrderLogList, []).map((thisItem) => {
                                  const thisFindIndex = prevData.findIndex((thisIndex) => thisIndex.workOrderLogId === thisItem.workOrderLogId);
                                  if (thisFindIndex !== -1) return null;
                                  else return thisItem;
                                });
                                const filterData = newData.filter((thisItem) => thisItem !== null);
                                return [...prevData, ...filterData];
                              });
                              e.target.checked = true;
                            } else {
                              setCheckedItem((prev) => {
                                const prevData = prev.map((thisItem) => {
                                  const thisFindIndex = checkNullArray(_workOrderLogList, []).findIndex((thisIndex) => thisIndex.workOrderLogId === thisItem.workOrderLogId);
                                  if (thisFindIndex !== -1) return null;
                                  else return thisItem;
                                });
                                const filterData = prevData.filter((thisItem) => thisItem !== null);
                                return filterData;
                              });
                              e.target.checked = false;
                            }
                          }}
                        />
                      </th>
                    ) : null}
                    <th style={{ minWidth: '120px', padding: 'unset', width: '120px' }}>작업상태</th>
                    <th>공정<br />리스트</th>
                    {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? <th>파일</th> : null}
                    <th style={{ minWidth: '120px', width: '120px' }}>작업유형</th>
                    <th>작업코드</th>
                    <th>작업이름</th>
                    {(_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) ? <th>거래처</th> : null}
                    <th>생산<br />목표수량</th>
                    <th>작업위치</th>
                    <th>기간</th>
                    <th>작성자</th>
                    <th>책임자</th>
                    <th>작업자</th>
                    <th>비고</th>
                    <th style={{ minWidth: '60px', width: '60px' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {!checkNullArray(_workOrderLogList, false) ? LoadingMsg() :
                    checkNullArray(_workOrderLogList, []).map((workOrderLog, index) => {
                      let processLogList = [];
                      if (checkNullArray(workOrderLog.processLogList, false)) {
                        const copyList = [...workOrderLog.processLogList];
                        copyList.sort((a, b) => { return a.processNumber - b.processNumber; });
                        processLogList = copyList;
                      }

                      let colNum = 14;
                      if (_authority.indexOf('403-2') !== -1) colNum = colNum + 1;
                      if (_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) colNum = colNum + 1;
                      if (_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) colNum = colNum + 1;

                      return (
                        <Fragment key={index + '_workOrderLog'}>
                          <tr data-key={workOrderLog.workOrderLogId}>
                            {_authority.indexOf('403-2') !== -1 ? (
                              <td style={{ minWidth: '50px', width: '50px' }}>
                                <input
                                  type="checkBox"
                                  name="workOrderLog"
                                  data-key={workOrderLog.workOrderLogId}
                                  checked={(() => {
                                    const checkedId = _checkedItem ? _checkedItem.findIndex((item) => item.workOrderLogId === workOrderLog.workOrderLogId) : -1;
                                    if (checkedId !== -1) return true;
                                    else return false;
                                  })()}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    handleCheckedItem(workOrderLog, isChecked);
                                  }}
                                  onClick={(e) => { e.stopPropagation() }}
                                />
                              </td>) : null}
                            <StatusTdButton
                              statusStyle={{ cursor: 'default', height: '120px', minWidth: '120px', width: '120px' }}
                              status={workOrderLog.workOrderStatus}
                            />
                            <td>
                              <div className='btn-doc'
                                data-workorderlogid={workOrderLog.workOrderLogId}
                                onClick={actShowList}
                              >
                                <img src={baseInformationIcon} alt={'공정리스트 버튼'} />
                              </div>
                            </td>

                            {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? (
                              <>
                                {(checkNullArray(workOrderLog.attachFileList, false)) ? (
                                  <td className='table-preview'>
                                    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        showFileList(workOrderLog);
                                      }}
                                    >
                                      <img src={workOrderLog.attachFileList[0]?.fileData?.fileDataS3URL || ''} alt='image preview' className='img-preview' />
                                      <div className='btn-file'><DOCButtonIcon /></div>
                                    </div>
                                  </td>
                                ) : (
                                  <td>
                                    <div className='btn-doc' onClick={(e) => { e.stopPropagation(); showFileList(workOrderLog); }}>
                                      <DOCButtonIcon />파일
                                    </div>
                                  </td>
                                )}
                              </>
                            ) : null}

                            <td style={{ minWidth: '120px', width: '120px' }}>{workOrderLog.workOrderTypeName}</td>
                            <td>{workOrderLog.workOrderCode}</td>
                            <td>{workOrderLog.workOrderName}</td>
                            {(_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) ? (<td>{workOrderLog.accountName || ''}</td>) : null}
                            <td>{checkEmptyNull(workOrderLog.amount, 0).toLocaleString()}</td>
                            <td>{workOrderLog.place?.placeName}<br />{workOrderLog.workPlace}</td>
                            <td>{DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledStartDate, ''))}<br />~ {DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledEndDate, ''))}</td>
                            <td>{workOrderLog.userName}</td>
                            <td>{checkNullArray(workOrderLog.managers, []).map((worker, index) => { return (<p key={index + '_managers'}>{worker.managerName}</p>); })}</td>
                            <td>{checkNullArray(workOrderLog.workers, []).map((worker, index) => { return (<p key={index + '_workers'}>{worker.workerName}</p>); })}</td>
                            <td>{workOrderLog.remark}</td>
                            {(workOrderLog.workOrderStatus !== 'cancel' && workOrderLog.workOrderStatus !== 'end') ? (
                              <CancelWorkOrderLogButton
                                style={{ minWidth: '60px', width: '60px' }}
                                onClick={() => { actCancel(workOrderLog) }}
                              >
                                취소
                              </CancelWorkOrderLogButton>
                            ) : (<td style={{ minWidth: '60px', width: '60px' }}></td>)}
                          </tr>

                          <tr>
                            <td colSpan={colNum} style={{ height: '20px', padding: 'unset' }}>
                              <ProcessSection>
                                {processLogList.map((thisProcess) => {
                                  return (<Process key={thisProcess.processLogId + '_processCell'} status={thisProcess.processStatus}>{'• ' + checkEmptyNull(thisProcess.processName, '')}</Process>);
                                })}
                              </ProcessSection>
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
                </tbody>
              </table>
            }
            ></TableSection>
          </Section>

          <PagingComponent
            page={productionOrderReducer.pageNumber}
            count={productionOrderReducer.totalSize}
            size={10}
            pageEvent={(page) => {
              console.log('page : ', page);
              dispatch(productionOrderActions_setPageNumber(page));
            }}
          />
          <ConnectStatus connectStatus={workReducer.connectStatus} onClick={() => { navigate(0) }}></ConnectStatus>

          {_modalStatus === true && (
            <ShowWorkOrderLog
              status={false}
              workOrderLogId={_showWokrOrderLog}
              processList={_showProcessList}
              open={_modalStatus}
              close={() => { setModalStatus(false) }}
            />
          )}

          {(_fileListModalStatus === true && checkEmptyNull(_eventcontent.workOrderLogId, false)) ? (
            <ShowFileList
              type={'workOrderLog'}
              content={_eventcontent}
              open={_fileListModalStatus}
              close={() => { setFileListModalStatus(false) }}
            ></ShowFileList>
          ) : null}
        </>
      }
    />
  );
};

export default ProductionOrder;
