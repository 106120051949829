import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { contractActions_setContractAccount, contractActions_setContractElementList, contractActions_setContractStatus, contractActions_setContractType, contractActions_setEndDate, contractActions_setPageNumber, contractActions_setReset, contractActions_setSearchOption, contractActions_setSearchText, contractActions_setStartDate, contractActions_setTotalSize } from 'store/modules/actions/common/contractActions';
import { gsReducer_setDeleteOrder, gsReducer_setOrderProducts, gsReducer_setUpdateOrder } from 'store/modules/actions/company/GSActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { contractAttributeApi } from 'api/apis/contractAttributeApi';
import { contractApi } from 'api/apis/contractApi';

import { checkEmptyNull, checkNullArray, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import { LoadingMsg } from 'components/loading/LoadingMsg';

import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';

import GSProcurementList from 'pages/procurement/custom/company/gs/modal/GSProcurementList';
import GSBLFromContract from 'pages/contract/custom/company/gs/modal/GSBLFromContract';
import GSCPFromContract from 'pages/contract/custom/company/gs/modal/GSCPFromContract';
import GSCLFromContract from 'pages/contract/custom/company/gs/modal/GSCLFromContract';

const Section = styled.main`
  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }

    & .statusButtons {display: flex; gap: 5px;}
  }
`;

const StockTable = styled.table`
  tr {border: none;}
  td, th {min-width: 100px;}
  thead tr:nth-child(2) th {height: 40px;}
  tbody tr {border-bottom: 1px solid var(--MainNavy);}
`;
const GSButton = styled.div`
  align-items: center;
  background-color: ${(props) => {
    return props.type === 'procurement' ? 'var(--MainGreen)'
      : props.type === 'BL' ? 'var(--MainBlue)'
      : props.type === 'CP' ? 'var(--ThirdBlue)'
        : props.type === 'CL' ? 'var(--MainNavy)'
          : null;
  }};
  border-radius: 5px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 15px;
  height: calc(100px / 3);
  justify-content: center;
  margin: 0px auto;
  width: 96%;
`;

const GSContract = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { contractReducer, pageReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_contractAttrList, setContractAttrList] = useState([]);
  const [_contractList, setContractList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_showProcurementStatus, setShowProcurementStatus] = useState(false);
  const [_showBLStatus, setShowBLStatus] = useState(false);
  const [_showCPStatus, setShowCPStatus] = useState(false);
  const [_showCLStatus, setShowCLStatus] = useState(false);

  const [_selectedOrder, setSelectedOrder] = useState();
  const [_selectedWorkOrderId, setSelectedWorkOrderId] = useState();

  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('201') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    (async () => {
      await getContractAttrs(contractReducer.contractType);
      await getContractList(contractReducer.pageNumber - 1, contractReducer.contractType, contractReducer.contractStatus, contractReducer.isPushedSearchButton);
    })();

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getContractList(contractReducer.pageNumber - 1, contractReducer.contractType, contractReducer.contractStatus, contractReducer.isPushedSearchButton);
    }

    return () => { };
  }, [contractReducer.pageNumber]);

  /* ========================================================================= #4 */
  const getContractAttrs = async (contractType) => {
    const BodyToPost = {companyId: userReducer.company.companyId};
    if (checkEmptyNull(contractType, false) && contractType !== 'all') {
      BodyToPost.contractTypeId = Number(contractType);
    }
    await contractAttributeApi.searchContractAttribute(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('contractAttributeApi.searchContractAttribute : ', response);
      setContractAttrList(() => { return response.data });
    });
  };

  const getContractList = async (page, contractType, contractStatus, isPushedSearchButton) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (checkEmptyNull(contractType, false) && contractType !== 'all') {
      BodyToPost.contractTypeId = Number(contractType);
    }
    if (checkEmptyNull(contractStatus, false) && contractStatus !== 'all') {
      BodyToPost.contractStatus = contractStatus;
    }
    if (checkEmptyNull(contractReducer.startDate, false) && checkEmptyNull(contractReducer.endDate, false)) {
      const startDate = new Date(contractReducer.startDate);
      const setStartDate = startDate.toISOString();
      BodyToPost.contractStartDate = setStartDate;

      const endDate = new Date(contractReducer.endDate);
      endDate.setDate(endDate.getDate() + 1);
      const setEndDate = endDate.toISOString();
      BodyToPost.contractEndDate = setEndDate;

      if (contractReducer.startDate > contractReducer.endDate) {
        const reverseStartDate = new Date(contractReducer.startDate);
        reverseStartDate.setDate(reverseStartDate.getDate() + 1);
        const setReverseStartDate = reverseStartDate.toISOString();

        const reverseEndDate = new Date(contractReducer.endDate);
        const setReversetEndDate = reverseEndDate.toISOString();

        BodyToPost.contractStartDate = setReversetEndDate;
        BodyToPost.contractEndDate = setReverseStartDate;
      }
    }
    if (!isPushedSearchButton && checkEmptyNull(contractReducer.searchOption, false) && checkEmptyNull(contractReducer.searchText, false)) {
      BodyToPost[contractReducer.searchOption] = contractReducer.searchText;
    }
    await contractApi.searchContract(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('contractApi.searchContract : ', response);
      setContractList(() => { return response.data.content });
      dispatch(contractActions_setTotalSize(response.data.totalElements));
    });
  };

  /* ========================================================================= #5 */
  const handleWorkOrderStatus = async (contractStatus) => {
    dispatch(contractActions_setContractStatus(contractStatus));
    dispatch(contractActions_setPageNumber(1));
    await getContractList(0, contractReducer.contractType, contractStatus);
  }
  const handleCheckedItem = (contract, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(contract);
    setCheckedItem(() => { return checkedItem });
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(contractActions_setPageNumber(1));
    await getContractList(0, contractReducer.contractType, contractReducer.contractStatus);
  };

  /* 수주상태 버튼 눌렀을때 */
  const handleContractStatus = async (contract, index) => {
    const contractIndex = index;

    // 수주상태
    let eventStatusText = '';
    let eventStatus = '';
    switch (contract.contractStatus) {
      case 'waiting': eventStatusText = '진행'; eventStatus = 'proceeding'; break;
      case 'proceeding': eventStatusText = '완료'; eventStatus = 'end'; break;
      default: return;
    }

    // body
    const BodyToPut = {
      ...contract,

      companyId: userReducer.company.companyId,
      userId: userReducer.user.id,
      contractStatus: eventStatus,

      deleteContractAttributeValueList: [],
      deleteContractElementList: [],
    };

    // delete
    delete BodyToPut['createdDate'];
    delete BodyToPut['modifiedDate'];
    delete BodyToPut['account'];
    delete BodyToPut['company'];

    console.log('handleContractStatus - BodyToPut : ', BodyToPut);

    // confirm
    const confirmText = `수주를 ${eventStatusText}시키겠습니까?`;
    if (window.confirm(confirmText) === true) {
      await contractApi.updateContract(contract.contractId, BodyToPut).then((response) => {
        if (response === undefined) return;
        console.log('contractApi.updateContract : ', response);
        setContractList((prev) => {
          const returnData = [...prev];
          returnData.splice(contractIndex, 1, response.data);
          return returnData;
        });
      })
    } else {
      return;
    }
  };

  /* 수정 */
  const actUpdate = () => {
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return alert('수정할 수주를 선택해주세요.');

    const checkedContent = checkedList[0];
    console.log('checkedContents : ', checkedContent);

    dispatch(contractActions_setContractAccount({}));
    dispatch(contractActions_setContractElementList([]));

    dispatch(gsReducer_setUpdateOrder(checkedContent));
    dispatch(gsReducer_setOrderProducts(checkedContent.procurementElementList));
    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({updateContent: JSON.stringify(checkedContent)})}`,
      replace: true,
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return alert('삭제할 수주를 선택해주세요.');

    const checkedContent = checkedList[0];
    console.log('checkedContents : ', checkedContent);

    dispatch(gsReducer_setDeleteOrder(checkedContent));
    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({deleteContent: JSON.stringify(checkedContent)})}`,
      replace: true,
    });
  };

  const showProcurement = (procurement) => {
    setSelectedOrder(() => { return procurement });
    setShowProcurementStatus(true);
  };
  const showBL = (BL) => {
    setSelectedOrder(BL);
    setSelectedWorkOrderId(BL.workOrderLogId);
    setShowBLStatus(true);
  };
  const showCP = (CP) => {
    setSelectedOrder(CP);
    setSelectedWorkOrderId(CP.workOrderLogId);
    setShowCPStatus(true);
  };
  const showCL = (CL) => {
    setSelectedOrder(CL);
    setSelectedWorkOrderId(CL.workOrderLogId);
    setShowCLStatus(true);
  };

  /* ========================================================================= #6 */

  return (
    <Grid3Body contents={
      <>
        <Section className="Main">
          <NavBar
            title={
              <div className='Title'>
                <NavTitle menuCode={'201'} />
                <div className='statusButtons'>
                  {[
                    { status: 'all', name: '전체' },
                    { status: 'waiting', name: '대기' },
                    { status: 'proceeding', name: '진행' },
                    { status: 'end', name: '완료' },
                  ].map((contractStatus, index) => {
                    return (
                      <button key={index + '_statusButton'}
                        className={contractReducer.contractStatus === contractStatus.status ? 'btn-inven-1' : 'btn-set'}
                        onClick={() => { handleWorkOrderStatus(contractStatus.status); }}
                      >
                        {contractStatus.name}
                      </button>
                    )
                  })}
                </div>
              </div>
            }
            buttons={
              <>
                {_authority.indexOf('201-2') !== -1 ? (
                  <>
                    <button
                      className='btn-add'
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(contractActions_setContractAccount({}));
                        dispatch(contractActions_setContractElementList([]));
                        setTimeout(navigate(pageReducer.currentPage + '/create', { replace: true }), 1000);
                      }}
                    >
                      추가
                    </button>
                    <button className='btn-edit' onClick={actUpdate}>수정</button>
                    <button className='btn-delete' onClick={actDelete}>삭제</button>
                  </>
                ) : null}
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <div className="SearchSection">
                    <input
                      type="date"
                      className="SelectDate"
                      value={contractReducer.startDate}
                      onChange={(e) => { dispatch(contractActions_setStartDate(e.target.value)) }}
                    />

                    <IconRightArrow />

                    <input
                      type="date"
                      className="SelectDate"
                      value={contractReducer.endDate}
                      onChange={(e) => { dispatch(contractActions_setEndDate(e.target.value)) }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <div className="ContentCBox">
                      <select
                        className="SearchOption"
                        value={contractReducer.searchOption}
                        onChange={(e) => { dispatch(contractActions_setSearchOption(e.target.value)) }}
                      >
                        {[
                          { optionValue: '', optionText: '검색옵션' },
                          { optionValue: 'contractCode', optionText: '수주코드' },
                          { optionValue: 'contractName', optionText: '수주이름' },
                          { optionValue: 'accountName', optionText: '거래처명' },
                        ].map((thisItem) => {
                          return (
                            <option key={thisItem.optionValue + '_contractSearchOption'} value={thisItem.optionValue}>
                              {thisItem.optionText}
                            </option>
                          );
                        })}
                      </select>
                      <input
                        className="SearchBar"
                        placeholder="Search..."
                        value={contractReducer.searchText}
                        onInput={(e) => { dispatch(contractActions_setSearchText(e.target.value)) }}
                      />
                    </div>

                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton"
                      onClick={() => {
                        dispatch(contractActions_setReset());
                        getContractList(0, 'all', 'all', true);
                      }}
                    >
                      초기화
                    </div>
                  </div>
                </div>
              </>
            }
          />


          <TableSection content={
            <StockTable>
              <thead>
                <tr>
                  {_authority.indexOf('201-2') !== -1 ? (<th style={{ minWidth: '50px', width: '50px' }}></th>) : null}
                  <th>수주상태</th>
                  <th style={{ minWidth: '100px', width: '100px' }}>리스트</th>
                  <th>거래처</th>
                  <th>수주코드</th>
                  <th>공사명</th>
                  <th>현장명</th>
                  <th>현장주소</th>
                  <th>수주총금액</th>
                  <th>수주일자</th>
                  <th>{userReducer.endDateName[0]}</th>
                  <th>{userReducer.endDateName[1]}</th>
                </tr>
              </thead>

              <tbody>
                {!checkNullArray(_contractList, false) ? LoadingMsg() :
                  checkNullArray(_contractList, []).map((contract, index) => {
                    const customizedContent = checkNullParse(contract.contractCustomizedContent, {
                      workPlaceName: '',
                      workPlaceAddress: '',
                    });

                    const procurementList = checkNullArray(contract.procurementList, false) ? contract.procurementList[0] : null;

                    let BL = null;
                    let CP = null;
                    let CL = null;
                    checkNullArray(contract.workOrderLogList, []).forEach(workOrderLog => {
                      if (checkEmptyNull(workOrderLog.workOrderName, false)) {
                        if (workOrderLog.workOrderName.endsWith('BL')) BL = workOrderLog;
                        if (workOrderLog.workOrderName.endsWith('CP')) CP = workOrderLog;
                        if (workOrderLog.workOrderName.endsWith('CL')) CL = workOrderLog;
                      }
                    })

                    return (
                      <tr key={index + '_contract'} data-key={contract.contractId} data-status={contract.contractStatus}>
                        {_authority.indexOf('201-2') !== -1 ? (
                          <td style={{ minWidth: '50px', width: '50px' }}>
                            <input
                              type="checkBox"
                              name="contracts"
                              data-key={contract.contractId}
                              checked={(() => {
                                const checkedIndex = _checkedItem ? Array.from(_checkedItem).findIndex((item) => item.contractId === contract.contractId) : -1;
                                if (checkedIndex !== -1) return true;
                                else return false;
                              })()}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                handleCheckedItem(contract, isChecked);
                              }}
                              onClick={(e) => { e.stopPropagation(); }}
                            />
                          </td>
                        ) : null}
                        <StatusTdButton
                          statusStyle={{ minWidth: '120px', width: '120px' }}
                          status={contract.contractStatus}
                          statusEvent={(e) => {
                            e.stopPropagation();
                            handleContractStatus(contract, index);
                          }}
                        />
                        <td style={{ height: '150px', minWidth: '100px', padding: '5px 0px', width: '100px' }}>
                          <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', rowGap: '2px' }}>
                            <GSButton type="procurement" data-contractid={contract.contractId} onClick={() => { showProcurement(contract) }}>발주리스트</GSButton>
                            <GSButton type="BL" data-contractid={contract.contractId} onClick={() => { showBL(contract) }}>캠버리스트</GSButton>
                            <GSButton type="CP" data-contractid={contract.contractId} onClick={() => { showCP(contract) }}>조합리스트</GSButton>
                            <GSButton type="CL" data-contractid={contract.contractId} onClick={() => { showCL(contract) }}>체크리스트</GSButton>
                          </div>
                        </td>
                        <td>{contract.accountName}</td>
                        <td>{contract.contractCode}</td>
                        <td>{contract.contractName}</td>
                        <td>{customizedContent.workPlaceName}</td>
                        <td>{customizedContent.workPlaceAddress}</td>
                        <td>{checkEmptyNull(contract.contractPrice, 0).toLocaleString()}</td>
                        <td>{DateFormat(checkEmptyNull(contract.contractDate, ''))}</td>
                        <td>{DateFormat(checkEmptyNull(contract.scheduledEndDate, ''))}</td>
                        <td>{DateFormat(checkEmptyNull(contract.scheduledEndDate2, ''))}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </StockTable>
          }
          ></TableSection>
        </Section>

        <PagingComponent
          page={contractReducer.pageNumber}
          count={contractReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(contractActions_setPageNumber(page));
          }}
        />

        {/* 발주리스트 */}
        {_showProcurementStatus === true ? (
          <GSProcurementList
            content={_selectedOrder}
            open={_showProcurementStatus}
            close={() => { setShowProcurementStatus(false); }}
          />
        ) : null}
        {/* 캠버리스트 */}
        {_showBLStatus === true ? (
          <GSBLFromContract
            content={_selectedOrder}
            open={_showBLStatus}
            close={() => { setShowBLStatus(false); }}
          />
        ) : null}
        {/* 조합리스트 */}
        {_showCPStatus === true ? (
          <GSCPFromContract
            content={_selectedOrder}
            open={_showCPStatus}
            close={() => { setShowCPStatus(false); }}
          />
        ) : null}
        {/* 체크리스트 */}
        {_showCLStatus === true ? (
          <GSCLFromContract
            content={_selectedOrder}
            open={_showCLStatus}
            close={() => { setShowCLStatus(false); }}
          />
        ) : null}
      </>
    }
    />
  );
};

export default GSContract;
