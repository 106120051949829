export const uriReducer_reset = () => {
  return {
    type: 'URIRESET',
  };
};

export const uriReducer_setWorkOrderContents = (data) => {
  return {
    type: 'SETURIWORKORDERCONTENTS',
    payload: data,
  };
};

export const uriReducer_setWorkOrderContent = (data) => {
  return {
    type: 'SETURIWORKORDERCONTENT',
    payload: data,
  };
};

export const uriReducer_setUpdateWorkOrder = (data) => {
  return {
    type: 'SETURIUPDATEWORKORDER',
    payload: data,
  };
};

export const uriReducer_setUpdateContent = (data) => {
  return {
    type: 'SETURIUPDATECONTENT',
    payload: data,
  };
};

export const uriReducer_setDrawingList = (data) => {
  return {
    type: 'SETURIDRAWINGLIST',
    payload: data,
  };
};

export const uriReducer_setWorkerScreenContent = (data) => {
  return {
    type: 'SETURIWORKERSCREENCONTENT',
    payload: data,
  };
};

export const uriReducer_setViewScale = (data) => {
  return {
    type: 'SETURIVIEWSCALE',
    payload: data,
  };
};
