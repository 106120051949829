import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { modalFileActions_setFileReset } from 'store/modules/actions/modal/modalFileActions';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

import { zoomMinus, zoomPlus } from 'components/icons/src';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import TableSection from 'components/layouts/table/TableSection';
import ShowFileList from 'pages/file/modal/ShowFileList';
import ShowInputElementStockLog from 'pages/stockHistory/inputElementStockLog/ShowInputElementStockLog';
import WorkProcessRow from 'components/row/WorkProcessRow';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px calc(100% - 55px);
  height: 85%;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: space-between;
  padding: 0px 20px;
  position: relative;
  width: 100%;

  & .btn-set {
    border: 1px solid var(--gray-300);
    box-shadow: unset;
    font-size: 15px;
    height: 35px;
    margin-right: 40px;
    padding-inline: 20px;
    width: fit-content;
  }
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
    td, th {
      min-width: 150px;
    }
    td.amountCell, th.amountCell {min-width: 100px;}
  }
`;

const ViewerButtons = styled.div`
  z-index: 999;
  position: fixed;
  right: 76px;
  bottom: 24px;
  column-gap: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ViewMinus = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  border-radius: 50%;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 50px;
  font-weight: 600;
  justify-content: center;
  height: 50px;
  width: 50px;
`;
const ViewMinusIcon = styled.div`
  background-color: var(--white);
  height: 30px;
  width: 30px;
  mask-image: url(${zoomMinus}); mask-repeat: no-repeat; mask-size: contain;
  --webkit-mask-image: url(${zoomMinus}); --webkit-mask-repeat: no-repeat; --webkit-mask-size: contain;
`;

const ViewPlus = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  border-radius: 50%;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 50px;
  font-weight: 600;
  justify-content: center;
  height: 50px;
  width: 50px;
`;
const ViewPlusIcon = styled.div`
  background-color: var(--white);
  height: 30px;
  width: 30px;
  mask-image: url(${zoomPlus}); mask-repeat: no-repeat; mask-size: contain;
  --webkit-mask-image: url(${zoomPlus}); --webkit-mask-repeat: no-repeat; --webkit-mask-size: contain;
`;

const ShowWorkOrderLog = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { userReducer, workReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_workOrderData, setWorkOrderData] = useState();

  const [_processList, setProcessList] = useState([]);

  const [_fileListModalStatus, setFileListModalStatus] = useState(false);
  const [_eventcontent, setEventContent] = useState({});

  const [_inputElementStockLogModalStatus, setInputElementStockLogModalStatus] = useState(false);
  const [_inputElementStockLogs, setInputElementStockLogs] = useState([]);

  const [_viewScale, setViewScale] = useState(100);

  const [_viewStatus, setViewStatus] = useState('simple');

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('109') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    getWorkOrderLog();

    setOnload('loaded');
    return () => { };
  }, []);
  useEffect(() => { }, [_viewStatus])

  /* ====================================================================== #4 */
  const getWorkOrderLog = async () => {
    await workOrderLogApi.getWorkOrderLog(props.workOrderLogId).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.getWorkOrderLog : ', response);

      setWorkOrderData(() => { return response.data; });
      const Processes = response.data.processLogList;
      Processes.sort((a, b) => { return a.processNumber - b.processNumber; });
      setProcessList(() => { return Processes; });
    });
  };

  /* ====================================================================== #5 */
  const showFileList = (process) => {
    setEventContent(() => { return process; });
    dispatch(modalFileActions_setFileReset());
    setTimeout(setFileListModalStatus(true), 1000);
  };
  const showInputElementStockLog = (processLogElement, processLog) => {
    setInputElementStockLogs(() => {
      return {
        processLog: processLog,
        processLogElement: processLogElement,
        inputElementStockLogs: checkNullArray(processLogElement.inputElementStockLogs, []),
      }
    })
    setTimeout(setInputElementStockLogModalStatus(true), 1000)
  }

  const actViewMinus = () => {
    const prevScale = _viewScale;
    let setScale = parseFloat(prevScale - 5);
    if (setScale < 10) setScale = 10;
    setViewScale(setScale);
  };
  const actViewPlus = () => {
    const prevScale = _viewScale;
    let setScale = parseFloat(prevScale + 5);
    if (setScale > 125) setScale = 125;
    setViewScale(setScale);
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>공정 리스트</ModalTitle>
              <button className="btn-set"
                onClick={(e) => {
                  e.preventDefault();
                  setViewStatus((prev) => { return prev === 'simple' ? 'detail' : 'simple' });
                }}
              >
                {_viewStatus === 'simple' ? '공정 상세보기' : '공정 요약보기'}
              </button>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain style={{ zoom: `${_viewScale}%` }}>
              <TableSection content={
                <table>
                  <thead>
                    <tr style={{ border: 'none' }}>
                      {props.status === true ? (<th rowSpan={2} style={{ minWidth: '150px', width: '150px' }}></th>) : null}
                      <th rowSpan={2} style={{ minWidth: '60px', padding: 'unset', width: '60px' }}>순서</th>
                      {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? (<th rowSpan={2} style={{ minWidth: '60px', padding: 'unset', width: '60px' }}>파일</th>) : null}
                      {_viewStatus === 'detail' ? (
                        <>
                          <th rowSpan={2}>공정유형</th>
                          <th rowSpan={2}>공정코드</th>
                          <th rowSpan={2}>공정이름</th>
                          <th rowSpan={2}>작업<br />시작일</th>
                          <th rowSpan={2}>작업위치</th>
                          <th rowSpan={2}>비고</th>
                        </>
                      ) : (<th rowSpan={2}>공정정보</th>)}
                      {/* <th colSpan={2} style={{ backgroundColor: 'var(--MainBlue)' }}>진행상태</th> */}
                      <th colSpan={_viewStatus === 'detail' ? 7 : 5} style={{ backgroundColor: 'var(--ThirdBlue)' }}>공정완료제품</th>
                      <th colSpan={_viewStatus === 'detail' ? 6 : 3} style={{ backgroundColor: 'var(--SeconYellow)' }}>투입 자재/반제품</th>
                      {userReducer.user.team.teamRoleList.findIndex((thisIndex) => thisIndex.authority.startsWith('108')) !== -1 ? (<th rowSpan={2}>설비</th>) : null}
                      {_viewStatus === 'detail' ? (
                        <>
                          <th rowSpan={2}>책임자</th>
                          <th rowSpan={2}>작업자</th>
                        </>
                      ) : null}
                    </tr>
                    <tr>
                      {/* <th>클릭</th>
                      <th>진행도</th> */}
                      {_viewStatus === 'detail' ? (
                        <>
                          <th>물품이름</th>
                          <th>물품코드</th>
                          <th className="amountCell">목표<br />생산량</th>
                          <th className="amountCell">현재고</th>
                          <th className="amountCell" style={{ backgroundColor: 'var(--MainBlue)' }}>총<br />생산량</th>
                          <th className="amountCell" style={{ backgroundColor: 'var(--MainGreen)' }}>성공<br />수량</th>
                          <th className="amountCell" style={{ backgroundColor: 'var(--MainRed)' }}>실패<br />수량</th>
                        </>
                      ) : (
                        <>
                          <th>물품정보</th>
                          <th className="amountCell">목표<br />생산량</th>
                          <th className="amountCell" style={{ backgroundColor: 'var(--MainBlue)' }}>총<br />생산량</th>
                          <th className="amountCell" style={{ backgroundColor: 'var(--MainGreen)' }}>성공<br />수량</th>
                          <th className="amountCell" style={{ backgroundColor: 'var(--MainRed)' }}>실패<br />수량</th>
                        </>
                      )}
                      {_viewStatus === 'detail' ? (
                        <>
                          <th>물품이름</th>
                          <th>물품코드</th>
                          <th className="amountCell">단위<br />투입량</th>
                          <th className="amountCell">총<br />투입량</th>
                          <th className="amountCell">현재고</th>
                          <th style={{ minWidth: '120px', width: '120px' }}></th>
                        </>
                      ) : (
                        <>
                          <th>물품정보</th>
                          <th className="amountCell">총<br />투입량</th>
                          <th style={{ minWidth: '120px', width: '120px' }}></th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {checkNullObject(_workOrderData, false) && checkNullArray(_processList, []).map((thisItem) => {
                      return (
                        <WorkProcessRow
                          key={thisItem.processLogId}
                          authority={_authority}
                          content={thisItem}
                          status={props.status}
                          showFileList={showFileList}
                          showInputElementStockLog={showInputElementStockLog}
                          viewStatus={_viewStatus}
                        />
                      );
                    })}
                  </tbody>
                </table>
              }
              ></TableSection>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}

      <ViewerButtons>
        <ViewMinus onClick={actViewMinus}><ViewMinusIcon /></ViewMinus>
        <ViewPlus onClick={actViewPlus}><ViewPlusIcon /></ViewPlus>
      </ViewerButtons>

      {_fileListModalStatus === true && checkEmptyNull(_eventcontent.processLogId, false) ? (
        <ShowFileList
          type={'processLog'}
          content={_eventcontent}
          open={_fileListModalStatus}
          close={() => { setFileListModalStatus(false) }}
        ></ShowFileList>
      ) : null}

      {_inputElementStockLogModalStatus === true ? (
        <ShowInputElementStockLog
          content={_inputElementStockLogs}
          open={_inputElementStockLogModalStatus}
          close={() => { setInputElementStockLogModalStatus(false) }}
        ></ShowInputElementStockLog>
      ) : null}
    </>
  );
};

export default ShowWorkOrderLog;
