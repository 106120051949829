import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { checkEmptyNull, checkNullParse } from 'components/checkValues/checkValues';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto;
  height: 85%;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
  }
`;

const UriLeadTime = (props) => {
  const [_workOrderContent, setWorkOrderContent] = useState(props?.content);
  const [_processLogList, setProcessLogList] = useState(props?.content?.processLogList)

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>리드타임 관리</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <TableSection content={
                <table>
                  <thead>
                    <tr>
                      <th>NO</th>
                      <th>사이즈</th>
                      <th></th>
                      <th>작업시작</th>
                      <th>작업종료</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkEmptyNull(_processLogList, []).map((processLog, index) => {
                      const customizedContent = checkNullParse(processLog.processCustomizedContent, {
                        bundleSize: '',
                        bundle: '',
                      })
                      return (
                        <tr key={index + 'processLog'}>
                          <td>{(index + 1).toString().padStart(2, '0')}</td>
                          <td>{customizedContent.bundleSize}</td>
                          <td>{customizedContent.bundle}</td>
                          <td>{checkEmptyNull(processLog.processActualStartDate, false) ? moment(checkEmptyNull(processLog.processActualStartDate, '')).format('YYYY-MM-DD HH:mm:ss') : ''}</td>
                          <td>{checkEmptyNull(processLog.processActualStartDate, false) ? moment(checkEmptyNull(processLog.processActualEndDate, '')).format('YYYY-MM-DD HH:mm:ss') : ''}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              }
              />
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default UriLeadTime;
