import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { workActions_setCurrentMessage, workActions_setWorkCase } from 'store/modules/actions/default/workActions';

import { getCookie } from 'preferences/cookie/cookie';
import { WS_CLIENT } from 'preferences/server/constants';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import { zoomMinus, zoomPlus } from 'components/icons/src';
import AllCheckBox from 'components/checkbox/AllCheckBox';
import CheckBox from 'components/checkbox/CheckBox';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';

import GSWorkOrderStatusModal from 'pages/working/custom/company/gs/modal/GSWorkOrderStatusModal';
import GSWorkOrderAllStatusModal from 'pages/working/custom/company/gs/modal/GSWorkOrderAllStatusModal';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px calc(100% - 55px);
  height: 90%;
  overflow: hidden;
  width: 95%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
  }
`;

const ViewerButtons = styled.div`
  z-index: 999;
  position: fixed;
  right: 126px;
  bottom: 24px;
  column-gap: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ViewMinus = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  border-radius: 50%;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 40px;
  font-weight: 600;
  justify-content: center;
  height: 40px;
  width: 40px;
`;
const ViewMinusIcon = styled.div`
  background-color: var(--white);
  height: 30px;
  width: 30px;
  mask-image: url(${zoomMinus});mask-repeat: no-repeat;mask-size: contain;
  --webkit-mask-image: url(${zoomMinus});--webkit-mask-repeat: no-repeat;--webkit-mask-size: contain;
`;
const ViewPlus = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  border-radius: 50%;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 40px;
  font-weight: 600;
  justify-content: center;
  height: 40px;
  width: 40px;
`;
const ViewPlusIcon = styled.div`
  background-color: var(--white);
  height: 30px;
  width: 30px;
  mask-image: url(${zoomPlus});mask-repeat: no-repeat;mask-size: contain;
  --webkit-mask-image: url(${zoomPlus});--webkit-mask-repeat: no-repeat;--webkit-mask-size: contain;
`;

const GSCPWorkerScreen = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { userReducer, workReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const workOrderLog = props.content;
  const [_processLogList, setProcessLogList] = useState(props.content.processLogList.filter((thisProcess) => thisProcess.processTypeName === 'CP'));

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    userId: userReducer.user.id,
    workOrderStatus: workOrderLog.workOrderStatus,
    workOrderLogId: workOrderLog.workOrderLogId,
    workOrderCode: workOrderLog.workOrderCode,
    workOrderName: workOrderLog.workOrderName,
    workOrderScheduledStartDate: DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledStartDate, '')),
    workOrderScheduledEndDate: DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledEndDate, '')),
    accountId: workOrderLog.accountId,
    workPlace: workOrderLog.workPlace,
    detailedWorkPlace: workOrderLog.detailedWorkPlace,
    message: workOrderLog.message,
    remark: workOrderLog.remark,
    workOrderCustomizedContent: workOrderLog.workOrderCustomizedContent,
    processLogList: [],
    deleteProcessList: [],
  });

  const [_updateWorkOrderList, setUpdateWorkOrderList] = useState([]);
  const [_deleteWorkOrderList, setDeleteWorkOrderList] = useState([]);

  const [_BLData, setBLData] = useState([]);
  const [_CLData, setCLData] = useState([]);

  const [_processLogListStatusModal, setProcessLogListStatusModal] = useState(false);
  const [_processLogListAllStatusModal, setProcessLogListAllStatusModal] = useState(false);
  const [_eventProcessLogId, setEventProcessLogId] = useState('');
  const [_eventProcessStatus, setEventProcessStatus] = useState('');
  const [_eventProcesses, setEventProcesses] = useState([]);

  const [_viewScale, setViewScale] = useState(100);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('501') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(workActions_setWorkCase('process'));

    const processLogList = props.content.processLogList.filter((thisItem) => thisItem.processTypeName === 'CP');
    setProcessLogList(processLogList);
    setBLData(props.content.processLogList.filter((thisItem) => thisItem.processTypeName === 'BL'));
    setCLData(props.content.processLogList.filter((thisItem) => thisItem.processTypeName === 'CL'));
    const updateWorkOrderList = processLogList.map((thisItem) => {
      return {
        existProcessOutElement: false,
        processTypeName: 'CP',
        processStatus: thisItem.processStatus,
        processLogId: thisItem.processLogId,
        processingCount: thisItem.processingCount,
        processCustomizedContent: JSON.parse(thisItem.processCustomizedContent),
        deleteTaskIds: [],
        taskLogList: [],

        processName: thisItem.processName,
        processCode: thisItem.processCode,
      };
    });
    setUpdateWorkOrderList(updateWorkOrderList);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (workReducer.connectStatus === 'connect') {
      if (!checkNullObject(workReducer.currentMessage, false)) return;

      if (workReducer.currentMessage.userId === userReducer.user.id && checkEmptyNull(workReducer.currentMessage.errorMessage, false)) {
        return dispatch(workActions_setCurrentMessage({}));;
      }

      if (workReducer.currentMessage.workOrderLogId === props.content.workOrderLogId) {
        if (workReducer.currentMessage.type === '/work') applyStatus();
        if (workReducer.currentMessage.type === 'process') applyProcessStatus();
        if (workReducer.currentMessage.type === 'processes') applyProcessesStatus();
      }
    }

    return () => { };
  }, [workReducer.currentMessage]);

  useEffect(() => { }, [workReducer.connectStatus]);

  /* ====================================================================== #4 */
  /* 웹소켓 */
  const applyStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);
    setFormData(prev => { return { ...prev, workOrderStatus: applyContent.workOrderStatus } })
  };
  const applyProcessStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventProcessLogList = [..._processLogList];
    const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === applyContent.processLog.processLogId);
    const eventContent = eventProcessLogList[eventProcessLogIndex];
    if (eventContent === undefined) return;
    eventContent.processStatus = applyContent.processLog.processStatus;
    eventContent.managers = checkNullArray(applyContent.processLog.managers, []);
    eventContent.workers = checkNullArray(applyContent.processLog.workers, []);
    eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
    setFormData((prev) => { return { ...prev, processLogList: eventProcessLogList }; });
  };
  const applyProcessesStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventProcessLogList = [..._processLogList];
    if (checkNullArray(applyContent.socketProcessResults, false)) {
      applyContent.socketProcessResults.forEach((processResult) => {
        const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === processResult.processLog.processLogId);
        if (eventProcessLogIndex === -1) return;
        const eventContent = eventProcessLogList[eventProcessLogIndex];
        eventContent.processStatus = processResult.processLog.processStatus;
        eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
      });
    } else {
      applyContent.processLogIds.forEach((processLogIds) => {
        const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === processLogIds);
        if (eventProcessLogIndex === -1) return;
        const eventContent = eventProcessLogList[eventProcessLogIndex];
        eventContent.processStatus = applyContent.processStatus;
        eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
      });
    }
    setFormData((prev) => { return { ...prev, processLogList: eventProcessLogList }; });
  };

  /* ====================================================================== #5 */
  const actViewMinus = () => {
    const prevScale = _viewScale;
    let setScale = parseFloat(prevScale - 5);
    if (setScale < 10) setScale = 10;
    setViewScale(setScale);
  };
  const actViewPlus = () => {
    const prevScale = _viewScale;
    let setScale = parseFloat(prevScale + 5);
    if (setScale > 125) setScale = 125;
    setViewScale(setScale);
  };

  const handleCustomEvent = (e, index, processCustomizedContent, processLog) => {
    const { name, value } = e.target;
    setUpdateWorkOrderList((prev) => {
      const returnData = [...prev];
      const newData = { ...processLog, processCustomizedContent: { ...processCustomizedContent, [name]: value } }
      returnData.splice(index, 1, newData);
      return returnData;
    });
  };

  const setProcessStatus = (processLog) => {
    if (workReducer.connectStatus !== 'connect') return alert(`
연결이 불안정합니다.
네트워크 상태 확인 후 다시 시도하여 주시기 바랍니다.
    `);
    if (_formData.workOrderStatus !== 'work') { return alert('작업지시가 작업 중이 아닙니다.') }

    setEventProcessLogId(processLog.processLogId);
    setEventProcessStatus(processLog.processStatus);
    setProcessLogListStatusModal(true);
  };
  const processStatusEvent = (e) => {
    const { workordercode, status } = e.currentTarget.dataset;
    console.log(workordercode, status);

    const eventProcessLogList = [..._processLogList];
    const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === Number(workordercode));
    if (eventProcessLogIndex === -1) return;

    const eventProcessLog = eventProcessLogList[eventProcessLogIndex];
    eventProcessLog.processStatus = status;
    eventProcessLogList.splice(eventProcessLogIndex, 1, eventProcessLog);

    if (workReducer.connectStatus !== 'connect' || !checkNullArray(eventProcessLogList, false)) return;

    const BodyToWS = {
      roomId: userReducer.company.companyId,
      type: '/process',
      workOrderLogId: _formData.workOrderLogId,
      processLogId: Number(workordercode),
      processStatus: status,
    };
    console.log('client.publish/pub/process - body : ', JSON.stringify(BodyToWS));

    try {
      WS_CLIENT.publish({
        destination: '/pub/process',
        headers: { 'auth-token': getCookie('accessToken') && getCookie('accessToken').replace('Bearer ', '') },
        body: JSON.stringify(BodyToWS),
      });
    } catch (error) {
      console.log('client.publish/pub/process - error : ', error);
    }

    setProcessLogListStatusModal(false);
  };

  const setAllStatus = () => {
    setEventProcessLogId(_formData.workOrderLogId);
    setEventProcessStatus('all');
    const thisCheckList = document.querySelectorAll('input[name="statusCheckBoxes"]:checked');
    const thisIdList = [];
    thisCheckList.forEach((thisItem) => {
      thisIdList.push(Number(thisItem.dataset.key));
    });
    console.log('thisIdList: ', thisIdList);
    setEventProcesses(thisIdList);
    setProcessLogListAllStatusModal(true);
  };
  const allStatusEvent = (e) => { setProcessLogListAllStatusModal(false); };

  const actUpdate = async () => {
    if (_authority.indexOf('501-2') === -1) return alert('권한이 없습니다.');
    const BodyToPut = { ..._formData };
    const CPList = _updateWorkOrderList.map((thisItem) => { return { ...thisItem, processCustomizedContent: JSON.stringify(thisItem.processCustomizedContent) } });
    const setAllProcessLogList = [...CPList, ..._BLData, ..._CLData];
    BodyToPut.processLogList = setAllProcessLogList;
    BodyToPut.deleteProcessLogList = _deleteWorkOrderList;
    console.log('BodyToPut : ', BodyToPut);

    await workOrderLogApi.updateWorkOrderLog(BodyToPut.workOrderLogId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.updateWorkOrderLog : ', response);
      alert('작업지시가 수정되었습니다.');
    })
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>체크리스트</ModalTitle>
              <div style={{ display: 'flex', gap: '10px', position: 'absolute', right: '55px' }}>
                <button className="formButton" style={{ backgroundColor: 'var(--MainBlue)' }} onClick={(e) => { e.preventDefault(); setAllStatus(); }}>일괄 상태 변경</button>
                {/* <button className="formButton" onClick={(e) => {e.preventDefault(); actUpdate();}}>리스트 저장</button> */}
              </div>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <TableSection content={
                <table style={{ zoom: `${_viewScale}%` }}>
                  <thead>
                    <tr>
                      <th style={{ minWidth: '120px', width: '120px' }}></th>
                      <th style={{ minWidth: '50px', width: '50px' }}><AllCheckBox key={'AllCheckBox'} checkboxName={'statusCheckBoxes'} /></th>
                      <th style={{ minWidth: '65px', width: '65px' }}>순번</th>
                      <th>재질</th>
                      <th>규격</th>
                      <th>길이</th>
                      <th>수량</th>
                      <th>조합</th>
                      <th>잔재</th>
                      <th>부재길이</th>
                      <th>수량</th>
                      <th>총수량</th>
                      <th style={{ minWidth: '300px', width: '300px' }}>Mark</th>
                    </tr>
                  </thead>

                  <tbody>
                    {checkNullArray(_processLogList, []).map((processLog, index) => {
                      const processCustomizedContent = checkNullParse(processLog.processCustomizedContent, {
                        num: (index + 1).toString().padStart(2, '0'),
                        name: '',
                        profile: '',
                        quantity: '',
                        combined: '',
                        part: '',
                        partWidth: '',
                        each: '',
                        total: '',
                        mark: '',
                      })

                      return (
                        <tr key={processLog.processLogId + '_CPRow'}>
                          <StatusTdButton
                            statusStyle={{ cursor: _authority.indexOf('501-2') !== -1 ? 'pointer' : 'default', height: '120px', minWidth: '120px', width: '120px' }}
                            status={processLog.processStatus}
                            statusEvent={(e) => { e.preventDefault(); if (_authority.indexOf('501-2') !== -1) setProcessStatus(processLog) }}
                          />
                          <td style={{ minWidth: '50px', width: '50px' }}>
                            <CheckBox
                              act={''}
                              all={true}
                              checkboxKey={processLog.processLogId}
                              name={'statusCheckBoxes'}
                            />
                          </td>
                          <td style={{ minWidth: '65px', width: '65px' }} data-col="Num">{(index + 1)}</td>
                          <td data-col="Name" data-colname="재질">{processCustomizedContent.name}</td>
                          <td data-col="Profile" data-colname="규격">{processCustomizedContent.profile}</td>
                          <td data-col="Length" data-colname="길이">{processCustomizedContent.length}</td>
                          <td data-col="Quantity" data-colname="수량">{processCustomizedContent.quantity}</td>
                          <td data-col="Combined" data-colname="조합">{processCustomizedContent.combined}</td>
                          <td data-col="Part" data-colname="잔재">{processCustomizedContent.part}</td>
                          <td data-col="PartWidth" data-colname="부재길이">{processCustomizedContent.partWidth}</td>
                          <td data-col="Each" data-colname="수량">{processCustomizedContent.each}</td>
                          <td data-col="Total" data-colname="총수량">{processCustomizedContent.total}</td>
                          <td style={{ minWidth: '300px', width: '300px' }} data-col="Mark">{processCustomizedContent.mark}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
              />
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}

      <ViewerButtons>
        <ViewMinus onClick={actViewMinus}><ViewMinusIcon /></ViewMinus>
        <ViewPlus onClick={actViewPlus}><ViewPlusIcon /></ViewPlus>
      </ViewerButtons>

      {_processLogListStatusModal === true && (
        <GSWorkOrderStatusModal
          workOrderLogId={_eventProcessLogId}
          workOrderStatus={_eventProcessStatus}
          statusEvent={processStatusEvent}
          open={_processLogListStatusModal}
          close={() => { setProcessLogListStatusModal(false); }}
        />
      )}

      {_processLogListAllStatusModal === true && (
        <GSWorkOrderAllStatusModal
          workOrderLogList={_processLogList}
          workOrderLogId={_eventProcessLogId}
          workOrderStatus={_eventProcessStatus}
          eventProcesses={_eventProcesses}
          statusEvent={allStatusEvent}
          open={_processLogListAllStatusModal}
          close={() => { setProcessLogListAllStatusModal(false); }}
        />
      )}
    </>
  );
};

export default GSCPWorkerScreen;
