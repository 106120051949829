import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { clusterActions_setCompanyList, clusterActions_setUserData } from 'store/modules/actions/cluster/clusterActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { getCookie } from 'preferences/cookie/cookie';
import { companyApi } from 'api/apis/companyApi';

import { ClusterMainComponent } from 'platform/cluster/styles/main.style';
import ClusterHeader from 'platform/cluster/components/ClusterHeader';
import ClusterSideBar from '../components/ClusterSideBar';

export default function ClusterMain({ children }) {
  const location = useLocation();
  const clusterId = location.pathname.split('/platform/cluster')[0];
  const clusterPath = clusterId + '/platform/cluster';
  // console.log('clusterPath : ', clusterPath);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clusterReducer } = useSelector(state => state);

  useEffect(() => {
    const splits = location.pathname.split('/');
    const last = splits[splits.length - 1];
    if (last === 'cluster') return navigate(clusterPath, { replace: true });
  }, []);
  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));
    if (!getCookie('accessToken')) return navigate(clusterPath + '/sign/login');
    getClusterCompanyList();
    getMyData();
  }, [location.pathname]);
  useEffect(() => {
    if (getCookie('accessToken')) {
      console.log('clusterReducer.user : ', clusterReducer.user);
      // navigate(clusterPath + '/sign/login');
    }
  }, [clusterReducer.user]);

  const getClusterCompanyList = async () => { // 소공인 리스트 불러오기
    const id = Number(clusterId.replace('/', ''));
    const body = { clusterId: !isNaN(id) ? id : '' };

    await companyApi.searchCompanies('', body).then(response => {
      if (response === undefined) return;
      dispatch(clusterActions_setCompanyList(response.data));
    })
  };
  const getMyData = async () => { // 소공인 정보 불러오기
    const headers = { headers: { Authorization: getCookie('accessToken') } };
    await axios.get('/any/oneSelf', headers).then(async (response) => {
      if (response === undefined) return;
      console.log('mydata : ', response);
      dispatch(clusterActions_setUserData(response.data));
    });
  };

  return (
    <ClusterMainComponent>
      <ClusterHeader />
      <section className='main'>
        <ClusterSideBar />
        <Outlet {...children} />
      </section>
    </ClusterMainComponent>
  );
};
