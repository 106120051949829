export const mallMyOrderActions_setMallOrderStatus = (status) => {
  return {
    type: 'SETMALLMYORDERSTATUS',
    payload: status,
  };
};
export const mallMyOrderActions_setStartDate = (date) => {
  return {
    type: 'SETMALLMYORDERSTARTDATE',
    payload: date,
  };
};
export const mallMyOrderActions_setEndDate = (date) => {
  return {
    type: 'SETMALLMYORDERENDDATE',
    payload: date,
  };
};
export const mallMyOrderActions_setSearchOption = (searchOption) => {
  return {
    type: 'SETMALLMYORDERSEARCHOPTION',
    payload: searchOption,
  };
};
export const mallMyOrderActions_setSearchText = (searchText) => {
  return {
    type: 'SETMALLMYORDERSEARCHTEXT',
    payload: searchText,
  };
};
export const mallMyOrderActions_setPageNumber = (pageNumber) => {
  return {
    type: 'SETMALLMYORDERPAGENUMBER',
    payload: pageNumber,
  };
};
export const mallMyOrderActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETMALLMYORDERTOTALSIZE',
    payload: totalSize,
  };
};
export const mallMyOrderActions_setIsPushedSearchButton = (
  isPushedSearchButton,
) => {
  return {
    type: 'SETMALLMYORDERISPUSHEDSEARCHBUTTON',
    payload: isPushedSearchButton,
  };
};
export const mallMyOrderActions_setReset = () => {
  return {
    type: 'SETMALLMYORDERRESET',
  };
};
