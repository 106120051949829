import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { companyMenu } from 'api/apis/operator/companyMenu';
import { companyPageCode } from 'api/apis/operator/companyPageCode';

import { checkEmptyNull, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import NavBar from 'components/nav/NavBar';
import OperatorBody from 'components/layouts/body/OperatorBody';
import TableSection from 'components/layouts/table/TableSection';

const MainSection = styled.main`
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 60px calc(100% - 60px);

  & .TableSection {
    th, td {min-width: unset;}
    td.btn-act {
      color: var(--white);
      cursor: pointer;
      font-weight: 600;
      &.update {background-color: var(--MainYellow);}
      &.delete {background-color: var(--MainRed);}
    }
  }
`;

const CompanyMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  const [searchParams, setSearchParams] = useSearchParams();
  const company = checkNullParse(searchParams.get('company'), {});
  const { companyId, companyName } = company;

  const [_onload, setOnload] = useState('unload');

  const [_companyPageCodes, setCompanyPageCodes] = useState({});
  const [_companyMenuList, setCompanyMenuList] = useState([]);

  useEffect(() => {
    if (userReducer.user.role !== 'ROLE_MASTER') return navigate('/', { replace: true });
    dispatch(pageReducer_getCurrentPage(window.location.pathname));
    getCompanyPageCode();
    getCompanyMenu();
    setOnload('loaded');
    return () => { };
  }, []);

  const getCompanyPageCode = async () => {
    const body = { companyId: companyId };
    await companyPageCode.searchCompanyPageCode(body).then(response => {
      if (!checkEmptyNull(response, false)) return;
      const returnData = {};
      response.data?.content.forEach(pageCode => { returnData[pageCode.pageCode] = pageCode })
      setCompanyPageCodes(() => { return returnData; });
    })
  }
  const getCompanyMenu = async () => {
    const body = { companyId: companyId };
    await companyMenu.searchCompanyMenu(body).then(response => {
      if (!checkEmptyNull(response, false)) return;
      setCompanyMenuList(() => { return response.data.content; });
    })
  };

  const actUpdate = (companyMenu) => {
    const companyMenuData = checkNullObject(companyMenu, false);
    if (!companyMenuData) return;
    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({
        company: JSON.stringify(company),
        companyId: companyId, companyName: companyName,
        updateContent: JSON.stringify(companyMenuData)
      })}`,
      replace: true,
    })
  };
  const actDelete = (companyMenu) => {
    const companyMenuData = checkNullObject(companyMenu, false);
    if (!companyMenuData) return;
    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({
        company: JSON.stringify(company),
        companyId: companyId, companyName: companyName,
        deleteContent: JSON.stringify(companyMenuData)
      })}`,
      replace: true,
    })
  };

  return (
    <OperatorBody contents={
      <MainSection className="Main">
        <NavBar
          title={`${companyName} 메뉴관리`}
          buttons={
            <>
              <button className='btn-set'
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/operator/companyManagement', { replace: true });
                }}
              >
                뒤로
              </button>
              <button className='btn-add'
                onClick={(e) => {
                  e.preventDefault();
                  navigate({
                    pathname: pageReducer.currentPage + '/modules',
                    // search: `?${createSearchParams({ company: JSON.stringify(company) })}`,
                    search: `?${createSearchParams({
                      company: JSON.stringify(company),
                      companyId: companyId, companyName: companyName
                    })}`,
                    replace: true,
                  })
                }}
              >
                모듈 추가
              </button>
              <button className='btn-add'
                onClick={(e) => {
                  e.preventDefault();
                  navigate({
                    pathname: pageReducer.currentPage + '/create',
                    search: `?${createSearchParams({
                      company: JSON.stringify(company),
                      companyId: companyId, companyName: companyName
                    })}`,
                    replace: true,
                  })
                }}
              >
                추가
              </button>
            </>
          }
          nav={''}
        />

        <TableSection content={
          <table>
            <colgroup>
              <col width={'100px'}></col>
              <col></col>
              <col></col>
              <col></col>
              <col></col>
              <col width={'80px'}></col>
              <col width={'80px'}></col>
              <col width={'80px'}></col>
              <col width={'80px'}></col>
              <col width={'80px'}></col>
              <col width={'80px'}></col>
            </colgroup>
            <thead>
              <tr>
                <th rowSpan={2}>page<br />Code</th>
                <th colSpan={2}>pageName</th>
                <th colSpan={2}>pageUrl</th>
                <th colSpan={2}>indexX</th>
                <th colSpan={2}>indexY</th>
                <th rowSpan={2}></th>
                <th rowSpan={2}></th>
              </tr>
              <tr>
                <th>D</th>
                <th className='current'>C</th>
                <th>D</th>
                <th className='current'>C</th>
                <th>D</th>
                <th className='current'>C</th>
                <th>D</th>
                <th className='current'>C</th>
              </tr>
            </thead>
            <tbody>
              {(() => {
                const returnData = [];
                const sortList = _companyMenuList.sort((a, b) => {
                  if (a.indexX > b.indexX) return 1;
                  if (a.indexX < b.indexX) return -1;
                  if (a.indexY > b.indexY) return 1;
                  if (a.indexY < b.indexY) return -1;
                  else return 0;
                });
                sortList.forEach((companyMenu, index) => {
                  const defaultData = _companyPageCodes[companyMenu.pageCode];
                  returnData.push(<tr key={index + '_companyMenu'} className={companyMenu.indexY === 0 ? 'top' : ''}>
                    <td>{companyMenu.pageCode}</td>
                    <td>{defaultData?.defaultPageName}</td>
                    <td>{companyMenu.pageName}</td>
                    <td style={{ textAlign: 'left' }}>{defaultData?.defaultPageUrl}</td>
                    <td style={{ textAlign: 'left' }}>{companyMenu.pageUrl}</td>
                    <td>{defaultData?.defaultIndexX}</td>
                    <td>{companyMenu.indexX}</td>
                    <td>{defaultData?.defaultIndexY}</td>
                    <td>{companyMenu.indexY}</td>
                    <td className='btn-act update' onClick={(e) => { e.preventDefault(); actUpdate({ ...companyMenu, ...defaultData }); }}>수정</td>
                    <td className='btn-act delete' onClick={(e) => { e.preventDefault(); actDelete({ ...companyMenu, ...defaultData }); }}>삭제</td>
                  </tr>)
                })
                return returnData;
              })()}
            </tbody>
          </table>
        }
        ></TableSection>
      </MainSection>
    } />
  );
};

export default CompanyMenu;