import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { accountTypeApi } from 'api/apis/accountTypeApi';
import { accountAttributeApi } from 'api/apis/accountAttributeApi';
import { accountApi } from 'api/apis/accountApi';
import { bookmark } from 'api/apis/bookmark/bookmark';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

import Bookmark from 'components/icons/Bookmark';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import CreateAccount from 'pages/account/modal/create/CreateAccount';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 40px 50px;
  height: 85%;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {height: 100%; overflow-x: auto; width: 100%;}
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const SelectOneAccount = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_accountAttrList, setAccountAttrList] = useState([]);
  const [_accountList, setAccountList] = useState([]);

  const [_accountTypeList, setAccountTypeList] = useState([]);
  const [_accountType, setAccountType] = useState('all');
  const [_accountTypeData, setAccountTypeData] = useState({});

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_searchOption, setSearchOption] = useState('');
  const [_searchText, setSearchText] = useState('');

  const [_modalStatus, setModalStatus] = useState(false);

  const [_pageNum, setPageNum] = useState(1);
  const [_pageCount, setPageCount] = useState(0);

  const [_bookmark, setBookmark] = useState(true);

  /* ====================================================================== #3 */
  useEffect(() => {
    (async () => {
      await getAccountTypes();
      await getAccountAttrs(_accountType);
      await getAccountList(0, _accountType);
    })();

    setOnload('loaded');

    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getAccountList(_pageNum - 1, _accountType);
    }

    return () => { };
  }, [_pageNum]);

  /* ====================================================================== #4 */
  const getAccountTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await accountTypeApi.searchAccountType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('accountTypeApi.searchAccountType : ', response);
      setAccountTypeList(() => { return response.data; });
    });
  };

  const getAccountAttrs = async (accountType) => {
    console.log('accountTypeName : ', accountType);
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (checkEmptyNull(accountType, false) && accountType !== 'all') {
      BodyToPost.accountTypeId = Number(accountType);
    }
    console.log('getAccountAttrs - BodyToPost : ', BodyToPost);

    await accountAttributeApi.searchAccountAttribute(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('accountAttributeApi.searchAccountAttribute : ', response);
      setAccountAttrList(() => { return response.data });
    });
  };

  const getAccountList = async (page, accountType, clear) => {
    let paging = `?page=${page}&size=10`;
    if (_bookmark) paging += '&sort=bookMark,DESC&sort=id,DESC';
    else paging += '&sort=id,DESC';

    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && checkEmptyNull(accountType, false) && accountType !== 'all') {
      BodyToPost.accountTypeId = Number(accountType);
    }
    if (!clear && checkEmptyNull(_searchOption, false) && checkEmptyNull(_searchText, false)) {
      BodyToPost[_searchOption] = _searchText;
    }
    console.log('getAccountList - BodyToPost : ', BodyToPost);

    await accountApi.searchAccount(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('accountApi.searchAccount : ', response);
      setAccountList(() => { return response.data.content });
      setPageCount(() => { return response.data.totalElements });
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(id);
    setCheckedItem(() => { return checkedItem });
  };

  const handleBookmark = async (account, index) => {
    await bookmark.accountBookmark(account.accountId).then(response => {
      if (response === undefined) return;
      console.log('bookmark.accountBookmark : ', response);

      getAccountList(_pageNum - 1, _accountType);
    })
  }

  /* 검색 */
  const actSearch = async () => {
    setPageNum(() => { return 1 });
    await getAccountList(0, _accountType);
  };

  /* 초기화 */
  const actReset = async () => {
    setAccountType(() => { return 'all' });
    setSearchOption(() => { return '' });
    setSearchText(() => { return '' });
    setPageNum(() => { return 1 });
    await getAccountAttrs('all');
    await getAccountList(0, 'all', true);
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>거래처 선택</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <NavBar
                title={
                  <div className="SearchSection">
                    <select
                      className="SelectType"
                      value={_accountType}
                      onChange={(e) => {
                        setAccountType(() => { return e.target.value });
                        setPageNum(() => { return 1 });
                        getAccountAttrs(e.target.value);
                        getAccountList(0, e.target.value);
                      }}
                    >
                      <option value="all">전체</option>
                      {_accountTypeList.map((thisType) => {
                        if (thisType.accountTypeName === userReducer.company.companyName) return null;
                        return (<option key={thisType.accountTypeId} value={thisType.accountTypeId}>{thisType.accountTypeName}</option>);
                      })}
                    </select>

                    <div className="ContentCBox">
                      <div className="ContentCBox">
                        <select
                          className="SearchOption"
                          value={_searchOption}
                          onChange={(e) => { setSearchOption(() => { return e.target.value }) }}
                        >
                          <option value="">검색옵션</option>
                          <option value="accountCode">수요기관코드</option>
                          <option value="businessNumber">사업자등록번호</option>
                          <option value="accountName">회사명</option>
                        </select>
                        <input
                          className="SearchBar"
                          placeholder="Search..."
                          value={_searchText}
                          onInput={(e) => { setSearchText(() => { return e.target.value }) }}
                        />
                      </div>

                      <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                      <div className="ResetButton" onClick={actReset}>초기화</div>
                    </div>
                  </div>
                }
                buttons={
                  <button
                    className="formButton"
                    onClick={() => {
                      if (!checkEmptyNull(_accountType, false) || _accountType === 'all') return alert('거래처 유형을 선택해주세요.');
                      const typeData = _accountTypeList.find((thisItem) => {
                        if (thisItem.accountTypeId === Number(_accountType)) return true;
                        else return false;
                      });
                      if (typeData === undefined) return;

                      setAccountTypeData(() => { return typeData });
                      setTimeout(setModalStatus(true), 1000);
                    }}
                  >
                    거래처 추가
                  </button>
                }
                nav={''}
              />
              <TableSection
                content={
                  <table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '50px', width: '50px' }}></th>
                        <th style={{ minWidth: '50px', width: '50px' }}>
                          <Bookmark
                            bookmark={_bookmark}
                            clickEvent={() => {
                              setBookmark(prev => { return !prev });
                            }}
                          />
                        </th>
                        <th>수요기관코드</th>
                        <th>사업자등록번호</th>
                        <th>회사명</th>
                        <th>지역명</th>
                        <th>주소</th>
                        <th>상세주소</th>
                        <th>우편번호</th>
                        <th>이메일</th>
                        <th>전화번호</th>
                        <th>팩스번호</th>
                        {_accountAttrList.map((thisItem) => {
                          return (<th key={thisItem.accountAttributeId + '_accountAttrs'}>{thisItem.accountAttributeName}</th>);
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {_accountList.map((account, index) => {
                        if (account.accountName === userReducer.company.companyName) return null;
                        return (
                          <tr key={index + '_accounts'}>
                            <td style={{ minWidth: '50px', width: '50px' }}>
                              <input
                                type="checkBox"
                                name="accounts"
                                data-key={account.accountId}
                                checked={(() => {
                                  const checkedId = _checkedItem ? Array.from(_checkedItem).findIndex((accountId) => accountId === account.accountId) : -1;
                                  if (checkedId !== -1) return true;
                                  else return false;
                                })()}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  handleCheckedItem(account.accountId, isChecked);
                                }}
                                onClick={(e) => { e.stopPropagation() }}
                              />
                            </td>
                            <td style={{ minWidth: '50px', width: '50px' }}>
                              <Bookmark
                                bookmark={checkEmptyNull(account.bookMark, false)}
                                clickEvent={() => { handleBookmark(account, index) }}
                              />
                            </td>
                            <td>{account.accountCode}</td>
                            <td>{account.businessNumber}</td>
                            <td>{account.accountName}</td>
                            <td>{account.regionName}</td>
                            <td>{account.address}</td>
                            <td>{account.detailAddress}</td>
                            <td>{account.zip}</td>
                            <td>{account.email}</td>
                            <td>{account.telNumber}</td>
                            <td>{account.faxNumber}</td>
                            {checkNullArray(account.accountAttributeValueList, false) && checkNullArray(_accountAttrList, []).map((thisAttribute) => {
                              const thisAttributeValue = account.accountAttributeValueList.findIndex((thisIndex) => thisIndex.accountAttributeId === thisAttribute.accountAttributeId);
                              if (thisAttributeValue !== -1)
                                return (
                                  <td key={account.accountAttributeValueList[thisAttributeValue].accountAttributeId + '_accountAttrsValue'}>
                                    {account.accountAttributeValueList[thisAttributeValue].value}
                                  </td>
                                );
                              else
                                return (
                                  <td key={account.accountId + '_accountAttr_' + thisAttribute.accountAttributeId}></td>
                                );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                }
              ></TableSection>
            </ModalMain>

            <PagingComponent
              page={_pageNum}
              count={_pageCount}
              size={10}
              pageEvent={(page) => {
                console.log('page : ', page);
                setPageNum(() => { return page });
              }}
            />

            <ModalFooter>
              <ModalButton
                onClick={() => {
                  const checkedElementList = Array.from(_checkedItem);
                  const checkedContentIndex = _accountList.findIndex((thisItem) => thisItem.accountId === checkedElementList[0]);
                  if (checkedContentIndex === -1) return;
                  const checkedContent = _accountList[checkedContentIndex];
                  console.log('checkedContents : ', checkedContent);

                  props.buttonEvent(checkedContent);
                }}
              >
                {props.buttonTitle}
              </ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}

      {_modalStatus === true && (
        <CreateAccount
          accountTypeData={_accountTypeData}
          accountAttribute={_accountAttrList}
          open={_modalStatus}
          close={() => {
            setModalStatus(false);
            getAccountList(0, _accountType);
          }}
        />
      )}
    </>
  );
};

export default SelectOneAccount;
