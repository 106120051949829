import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { procurementApi } from 'api/apis/procurementApi';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import TableSection from 'components/layouts/table/TableSection';
import { DateFormat } from 'components/format/DateFormat';
import CommaNum from 'components/format/CommaNum';
import BigNumber from 'bignumber.js';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  height: 85%;
  /* max-height: 300px; */
  /* max-width: 400px; */
  overflow: hidden;
  width: 85%;

  & form > div {
    background-color: unset;
  }
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  padding: 0px 20px;
  position: relative;
  `;

const ModalTitle = styled.div`
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  width: 25px;
  height: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
`;

const ModalButton = styled.div`
  width: 20%;
  border-radius: 8px;
  height: fit-content;
  padding: 8px;
  box-shadow: 0 2px 6px 0 rgba(196,196,196,0.8);
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  /* height: 50px; */
  justify-content: flex-end;
  width: 100%;
  /* padding: 24px; */
`;

const TableFormbox = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  width: 100%;

  .TableSection {
    overflow: auto;
  }

  & > .TableSection {
    max-height: 350px;

    &::-webkit-scrollbar-thumb {
      background-color: var(--ThirdBlue);
    }
  }
`;

const AccountInput = styled.input`
  background-color: var(--MainNavy);
  color: var(--white);
`;

const DelButton = styled.div`
  height: 15px;
  margin: 0px auto;
  width: 15px;

  div {
    background-color: var(--MainRed);
    cursor: pointer;
    height: 15px;
    width: 15px;
  }
`;

const NotInStockToProcurement = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const checkValue = (value) => {
    if (value === 'null') {
      return '';
    } else {
      return value;
    }
  };

  const { notInStock } = props;

  const [_onload, setOnload] = useState('unload');

  const [_notInStockElements, setNotInStockElements] = useState(() => {
    const notInStockElementList = notInStock.notInStockElementList;
    if (
      typeof notInStockElementList === 'object' &&
      notInStockElementList.length > 0
    ) {
      const returnData = notInStockElementList.map((element) => {
        if (element.element !== undefined && element.element !== null) {
          const elementData = {
            ...element,

            elementId: element.element.elementId,
            elementName: element.element.elementName,
            elementCode: element.element.elementCode,
            scheduledInputStock: element.element.scheduledInputStock,
            elementSafeStock: element.element.elementSafeStock,
            elementStock: element.element.elementStock,

            quantity: element.notInStockQuantity || 0,
            price: element.element.price || 0, // 단가
            calPrice: element.price || 0, // 금액
          };

          return elementData;
        } else {
          return element;
        }
      });

      return returnData;
    } else {
      return [];
    }
  });
  const [_account, setAccount] = useState(() => {
    const notInStockElementList = notInStock.notInStockElementList;
    if (
      typeof notInStockElementList === 'object' &&
      notInStockElementList.length > 0 &&
      notInStockElementList[0].account !== undefined &&
      notInStockElementList[0].account !== null
    ) {
      return notInStockElementList[0].account;
    } else {
      return {};
    }
  });
  const [_procurement, setProcurement] = useState(notInStock.procurement);

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId, // 회사
    userId: userReducer.user.id, // 작성자

    // procurementId: searchParams.get('procurementId'),
    procurementDate: DateFormat(notInStock.procurement.procurementDate), // 발주일자
    scheduledEndDate: DateFormat(notInStock.procurement.scheduledEndDate), // 입고예정일자

    procurementStatus: 'waiting', // 발주상태
    // procurementStatus: searchParams.get('procurementStatus'), // 발주상태
    procurementCode: checkValue(notInStock.procurement.procurementCode), // 발주코드
    procurementName: checkValue(notInStock.procurement.procurementName), // 발주이름

    accountId: undefined, // 거래처
    accountName: undefined, // 거래처

    procurementPrice: (() => {
      const notInStockElementList = notInStock.notInStockElementList;
      if (
        typeof notInStockElementList === 'object' &&
        notInStockElementList.length > 0
      ) {
        const returnData = notInStockElementList.map((element) => {
          if (element.element !== undefined && element.element !== null) {
            const elementData = {
              ...element,

              elementId: element.element.elementId,
              elementName: element.element.elementName,
              elementCode: element.element.elementCode,
              scheduledInputStock: element.element.scheduledInputStock,
              elementSafeStock: element.element.elementSafeStock,
              elementStock: element.element.elementStock,

              quantity: element.notInStockQuantity || 0,
              price: element.element.price || 0, // 단가
              calPrice: element.price || 0, // 금액
            };

            return elementData;
          } else {
            return element;
          }
        });

        const sumArray = returnData.map((item) => item.calPrice);
        const setSumArray = sumArray.filter((item) => item !== null);
        const sum = setSumArray.reduce(
          (a, b) => BigNumber(a).plus(BigNumber(b)).toNumber(),
          0,
        );
        return sum;
      } else {
        return 0;
      }
    })(), // 발주총금액

    customizedContent: JSON.stringify({}), // 커스텀

    procurementElementList: [], // 발주물품
    deleteProcurementElementList: [], // 발주물품 삭제
  });

  /* ====================================================================== #3 */
  useEffect(() => {
    setOnload('loaded');

    return () => {};
  }, []);

  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleProcurementPrice = (e) => {
    const { name, value } = e.target;

    let eventValue = value;
    if (!eventValue.toString().startsWith('0.')) {
      eventValue = eventValue.toString().replace(/^0+/, '');
    }
    if (eventValue < 0 || eventValue === '') eventValue = 0;
    if (eventValue.length > 15) return;

    setFormData((prev) => {
      return { ...prev, [name]: BigNumber(eventValue).toNumber() };
    });
  };

  const handleElementInput = (e, index, element) => {
    const { value } = e.target;

    let eventValue = value;
    if (!eventValue.toString().startsWith('0.')) {
      eventValue = eventValue.toString().replace(/^0+/, '');
    }
    if (eventValue < 0 || eventValue === '') eventValue = 0;
    if (eventValue.length > 15) return;

    const prevData = [..._notInStockElements];
    switch (e.target.name) {
      case 'quantity':
        const newData_quantity = {
          ...element,
          quantity: BigNumber(eventValue).toNumber(),
          calPrice: BigNumber(eventValue)
            .multipliedBy(BigNumber(element.price))
            .toNumber(),
        };
        prevData.splice(index, 1, newData_quantity);

        (() => {
          const sumArray = prevData.map((item) => item.calPrice);
          const setSumArray = sumArray.filter((item) => item !== null);
          const sum = setSumArray.reduce(
            (a, b) => BigNumber(a).plus(BigNumber(b)).toNumber(),
            0,
          );

          setFormData((prev) => {
            return {
              ...prev,
              procurementPrice: sum,
            };
          });
        })();

        break;

      case 'calPrice':
        const newData_calPrice = {
          ...element,
          calPrice: BigNumber(eventValue).toNumber(),
        };
        prevData.splice(index, 1, newData_calPrice);

        const sumArray = prevData.map((item) => item.calPrice);
        const setSumArray = sumArray.filter((item) => item !== null);
        const sum = setSumArray.reduce(
          (a, b) => BigNumber(a).plus(BigNumber(b)).toNumber(),
          0,
        );

        setFormData((prev) => {
          return {
            ...prev,
            procurementPrice: sum,
          };
        });

        break;
      default:
        return;
    }
    setNotInStockElements(() => {
      return prevData;
    });
  };

  const deleteElement = (index, element) => {
    setNotInStockElements((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);

      const sumArray = prevData.map((item) => item.calPrice);
      const setSumArray = sumArray.filter((item) => item !== null);
      const sum = setSumArray.reduce(
        (a, b) => BigNumber(a).plus(BigNumber(b)).toNumber(),
        0,
      );

      setFormData((prev) => {
        return {
          ...prev,
          procurementPrice: sum,
        };
      });

      return prevData;
    });
  };

  const actToProcurement = async (e) => {
    e.preventDefault();

    const procurementElementList = _notInStockElements.map((element) => {
      return {
        accountId: _account.accountId,
        elementId: element.elementId,
        price: element.calPrice,
        quantity: element.quantity,
      };
    });

    const BodyToPost = {
      ..._formData,
      accountId: _account.accountId,
      procurementElementList: procurementElementList,
    };
    console.log('actToProcurement - BodyToPost : ', BodyToPost);

    await procurementApi.createProcurement(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('procurementApi.createProcurement : ', response);

      alert('발주가 추가되었습니다.');
      props.close();
    });
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>미입고 발주추가</ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>
            <ModalMain>
              <Form
                title={null}
                buttons={null}
                forms={
                  <>
                    <div className="formBox w100">
                      <h4>발주물품</h4>
                      <TableFormbox>
                        <TableSection
                          content={
                            <table>
                              <thead
                                style={{ backgroundColor: 'var(--MainNavy' }}
                              >
                                <tr>
                                  <th>물품이름</th>
                                  <th>물품코드</th>
                                  {/* <th>투입예정수량</th>
                                  <th>현재고</th> */}
                                  <th>단가</th>
                                  <th>수량</th>
                                  <th>금액</th>
                                  <th
                                    style={{ minWidth: '50px', width: '50px' }}
                                  ></th>
                                </tr>
                              </thead>
                              <tbody>
                                {_notInStockElements.length > 0 &&
                                  _notInStockElements.map((element, index) => {
                                    return (
                                      <tr
                                        key={index + '_notInStockElements'}
                                        data-key={element.elementId}
                                      >
                                        <td
                                          className={
                                            element.elementName !== undefined &&
                                            element.elementName !== null &&
                                            element.elementName.startsWith('*')
                                              ? 'outsourcingText'
                                              : null
                                          }
                                        >
                                          {element.elementName}
                                        </td>
                                        <td>{element.elementCode}</td>
                                        {/* <td>
                                          <CommaNum
                                            displayType={'text'}
                                            num={element.scheduledInputStock}
                                          />
                                        </td>
                                        <td
                                          className={(() => {
                                            if (
                                              element.elementSafeStock <=
                                              element.elementStock
                                            ) {
                                              return 'safeStock';
                                            } else {
                                              return 'dangerStock';
                                            }
                                          })()}
                                        >
                                          <CommaNum
                                            displayType={'text'}
                                            num={element.elementStock || 0}
                                          />
                                        </td> */}
                                        <td>
                                          <CommaNum
                                            displayType={'text'}
                                            num={element.price || 0}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            name="quantity"
                                            min={0}
                                            value={element.quantity}
                                            onInput={(e) => {
                                              handleElementInput(
                                                e,
                                                index,
                                                element,
                                              );
                                            }}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            name="calPrice"
                                            min={0}
                                            value={element.calPrice}
                                            onInput={(e) => {
                                              handleElementInput(
                                                e,
                                                index,
                                                element,
                                              );
                                            }}
                                          />
                                          {/* <CommaNum
                                          displayType={'text'}
                                          num={element.calPrice || 0}
                                        /> */}
                                        </td>

                                        <td
                                          style={{
                                            minWidth: '50px',
                                            width: '50px',
                                          }}
                                        >
                                          <DelButton
                                            data-type="Put"
                                            onClick={() => {
                                              deleteElement(index, element);
                                            }}
                                          >
                                            <CloseButtonIcon />
                                          </DelButton>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          }
                          formLayoutStyle={{
                            padding: '0px 30px',
                            width: '95%',
                          }}
                          formSectionStyle={{
                            paddingTop: '10px',
                            width: '100%',
                          }}
                        />
                      </TableFormbox>

                      <div
                        className="cautionText"
                        style={{
                          fontSize: '14px',
                          width: '100%',
                          textAlign: 'right',
                        }}
                      >
                        ※ 발주수량을 꼭! 입력해 주세요.
                      </div>
                    </div>

                    <div className="formBox">
                      <h4>발주코드</h4>
                      <input
                        type="data"
                        name="procurementCode"
                        placeholder="* 발주코드..."
                        data-required="required"
                        value={_formData.procurementCode}
                        onInput={handleInputEvent}
                      />
                    </div>

                    <div className="formBox">
                      <h4>발주이름</h4>
                      <input
                        type="text"
                        name="procurementName"
                        placeholder="* 발주이름..."
                        data-required="required"
                        value={_formData.procurementName}
                        onInput={handleInputEvent}
                      />
                    </div>

                    <div className="formBox">
                      <h4>거래처</h4>
                      <AccountInput
                        type="text"
                        name="accountName"
                        value={
                          Object.keys(_account).length < 2
                            ? ''
                            : _account.accountName
                        }
                        readOnly
                        placeholder="거래처..."
                      />
                    </div>

                    <div className="formBox">
                      <h4>발주일자</h4>
                      <input
                        type="date"
                        name="procurementDate"
                        value={_formData.procurementDate}
                        onChange={handleInputEvent}
                      />
                    </div>

                    <div className="formBox">
                      <h4>입고예정일자</h4>
                      <input
                        type="date"
                        name="scheduledEndDate"
                        value={_formData.scheduledEndDate}
                        onChange={handleInputEvent}
                      />
                    </div>

                    <div className="formBox">
                      <h4>발주총금액</h4>
                      <input
                        type="number"
                        name="procurementPrice"
                        placeholder="* 발주총금액..."
                        value={_formData.procurementPrice}
                        onInput={handleProcurementPrice}
                      />
                    </div>
                  </>
                }
                formStyle={{
                  height: '100%',
                }}
                formLayoutStyle={{
                  padding: '0px 30px',
                  width: '95%',
                }}
                formSectionStyle={{
                  paddingTop: '10px',
                  width: '100%',
                }}
              />
            </ModalMain>
            <ModalFooter>
              <ModalButton onClick={actToProcurement}>등록</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default NotInStockToProcurement;
