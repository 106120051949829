export const equipmentStatusActions_setEquipmentStatus = (status) => {
  return {type: 'SETEQUIPMENTSTATUS', payload: status};
};
export const equipmentStatusActions_setSearchData = (searchData) => {
  return {type: 'SETEQUIPMENTSTATUSSEARCHDATA', payload: searchData};
};
export const equipmentStatusActions_setIsPushedSearchButton = (isPushedSearchButton) => {
  return {type: 'SETEQUIPMENTSTATUSISPUSHEDSEARCHBUTTON', payload: isPushedSearchButton};
};
export const equipmentStatusActions_setPageNumber = (pageNumber) => {
  return {type: 'SETEQUIPMENTSTATUSPAGENUMBER', payload: pageNumber};
};
export const equipmentStatusActions_setTotalSize = (totalSize) => {
  return {type: 'SETEQUIPMENTSTATUSTOTALSIZE', payload: totalSize};
};
export const equipmentStatusActions_setReset = () => {
  return {type: 'SETEQUIPMENTSTATUSRESET'};
};

