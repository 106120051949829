import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { contentsReducer_setWorkOrderContents } from 'store/modules/actions/default/contentsActions';
import { modalFileActions_setFileReset } from 'store/modules/actions/modal/modalFileActions';

import { attachFileApi } from 'api/apis/attachFileApi';
import { workOrderTypeApi } from 'api/apis/workOrderTypeApi';
import { workOrderApi } from 'api/apis/workOrderApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import CreateWorkOrderRow from 'components/row/CreateWorkOrderRow';
import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import SelectFiles from 'pages/file/modal/SelectFiles';
import SelectOneAccount from 'pages/account/modal/SelectOneAccount';
import SelectProcesses from 'pages/process/modal/SelectProcesses';
import SelectWorkPlaceModal from 'pages/types/modal/SelectWorkPlaceModal';
import TableSection from 'components/layouts/table/TableSection';

const MainSection = styled.main`
  width: 100vw;

  .placeBox {
    &>div:first-child {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      width: 100%;

      & .formButton {
        background-color: var(--ThirdBlue);
        border-radius: 5px;
        width: 150px;
      }
    }
    &>div:nth-child(2) {
      column-gap: 5px;
      display: grid;
      grid-template-columns: auto 50px;
      width: 100%;

      & .formButton {
        background-color: var(--MainNavy);
        border-radius: 5px;
        height: 40px;
        width: 50px;
      }
    }

    .place {
      background-color: var(--ThirdBlue);
      color: var(--white);
      &::placeholder {color: var(--white);}
    }
  }
`;

const AccountInput = styled.input`
  background-color: var(--ThirdBlue);
  color: var(--white);
  &::placeholder {color: var(--white);}
`;

const FileList = styled.div`
  width: 100%;
`;
const FileBox = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  height: 40px;
  justify-content: flex-start;
  margin: 5px auto;
  padding: 0px 15px;
  position: relative;
  width: 100%;
`;
const DeleteFileButton = styled.div`
  height: 15px;
  position: absolute;
  right: 15px;
  width: 15px;
  div {background-color: var(--white); cursor: pointer; height: 15px; width: 15px;}
`;

const CreateWorkOrder = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_formData, setFormData] = useState({
    workOrderTypeId: '',

    workOrderName: '',
    workOrderCode: '',

    workPlace: '',
    detailedWorkPlace: '',

    message: '',
    remark: '',

    workOrderCustomizedContent: JSON.stringify({}),

    companyId: userReducer.company.companyId,
    userId: userReducer.user.id,

    workOrderProcessList: [],
  });

  const [_processDataList, setProcessDataList] = useState([]);
  const [_processModalStatus, setProcessModalStatus] = useState(false);

  const [_account, setAccount] = useState({});
  const [_accountModalStatus, setAccountModalStatus] = useState(false);

  const [_workOrderTypes, setWorkOrderTypes] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_fileList, setFileList] = useState([]);
  const [_fileModalStatus, setFileModalStatus] = useState(false);

  const [_workPlace, setWorkPlace] = useState({});
  const [_workPlaceModalStatus, setWorkPlaceModalStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('104') === true || // 거래처
      thisItem.authority.startsWith('109') === true,
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    getWorkOrderTypes();

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => { }, [_workPlace, _processDataList]);

  /* ====================================================================== #4 */
  const getWorkOrderTypes = async () => {
    const BodyToPost = { companyId: userReducer.company.companyId };
    await workOrderTypeApi.searchWorkOrderType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderTypeApi.searchWorkOrderType : ', response);
      setWorkOrderTypes(() => { return response.data; });
    });
  };

  /* ====================================================================== #5 */
  const handleChangeEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value }; });
  };

  const actDeleteFile = (e, index, file) => {
    setFileList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);
      return prevData;
    });
  };

  const handleCheckedItem = (index, process, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(process);
    setCheckedItem(() => { return checkedItem; });
    console.log(index, process, isChecked);
    setProcessDataList((prev) => {
      const newData = prev.map((thisItem, prevIndex) => {
        const returnData = { ...thisItem };
        delete returnData.target;
        if (isChecked && thisItem.processId === process.processId && prevIndex === index) returnData.target = true;
        return returnData;
      });
      return newData;
    });
  };

  // 공정순서 변경 이벤트
  const handleNumberEvent = (e, index, process) => {
    const newData = { ...process, processNumber: BigNumber(e.target.value).toNumber() };
    setProcessDataList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);
      prevData.splice(BigNumber(e.target.value).minus(1).toNumber(), 0, newData);
      const setPrevData = prevData.map((thisItem, index) => {
        return { ...thisItem, processNumber: index + 1 };
      });
      return setPrevData;
    });
  };

  // 공정진행횟수
  const handleProcessCount = (e, index, process) => {
    const newData = { ...process, count: Number(e.target.value) };
    setProcessDataList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1, newData);
      return prevData;
    });
  };

  // 공정 삭제
  const delItem = (index, process) => {
    setProcessDataList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);
      const setPrevData = prevData.map((thisItem, index) => {
        return { ...thisItem, processNumber: index + 1 };
      });
      return setPrevData;
    });
  };

  // 작업정의 추가
  const actCreate = async (e) => {
    e.preventDefault();

    if (_formData.workOrderCode === '') return alert('작업코드를 입력해주세요.');
    if (_formData.workOrderName === '') return alert('작업이름을 입력해주세요.');

    // 목표공정 체크
    // const lastProcessKey = document.querySelector('input[name="processes"]:checked');
    // console.log('lastProcessKey : ', lastProcessKey);
    // if (lastProcessKey === undefined || lastProcessKey === null) return alert('목표공정을 선택해주세요.');

    const processNumberList = _processDataList.map((thisItem) => thisItem.processNumber);
    const setProcessNumberList = new Set(processNumberList);
    if (processNumberList.length !== setProcessNumberList.size) return alert('중복된 공정 순서가 존재합니다.');

    const workOrderProcessList = _processDataList.map((thisItem, index) => {
      const returnData = {};
      returnData.companyId = userReducer.company.companyId;
      returnData.processId = thisItem.processId;

      returnData.processingType = thisItem.processingType || 'auto';

      if (thisItem.workOrderProcessId !== undefined) returnData.workOrderProcessId = thisItem.workOrderProcessId;

      returnData.count = thisItem.count || 1;
      returnData.processNumber = thisItem.processNumber;

      const processElementList = thisItem.processElementList.map((thisElment) => {
        const returnElementData = {
          amount: thisElment.amount,
          elementId: thisElment.element === undefined ? thisElment.elementId : thisElment.element.elementId,
          processElementType: thisElment.processElementType,
        };
        return returnElementData;
      });
      returnData.processElementList = processElementList;

      if (index === _processDataList.length - 1) returnData.target = true;
      else returnData.target = false;
      // if (thisItem.target !== undefined && thisItem.target !== null && thisItem.target === true) returnData.target = true;

      return returnData;
    });

    const BodyToPost = {
      ..._formData,
      companyId: userReducer.company.companyId,
      workOrderProcessList: workOrderProcessList.filter((thisFilter) => thisFilter !== null),
    };
    if (checkNullObject(_account, false)) BodyToPost.accountId = _account.accountId;
    if (checkNullObject(_workPlace, false)) BodyToPost.placeId = _workPlace.stockPlaceId;

    if (BodyToPost.workOrderProcessList.length === 0) return alert('공정을 추가 해주세요.');

    await workOrderApi.createWorkOrder(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderApi.createWorkOrder : ', response);

      if (_fileList.length > 0) {
        _fileList.forEach(async (file) => {
          const BodyToFilePost = {
            companyId: userReducer.company.companyId,
            fileDataId: file.fileDataId,
            workOrderId: response.data.workOrderId,
          };
          await attachFileApi.createAttachFile(BodyToFilePost).then((response) => {
            if (response === undefined) return;
            console.log('attachFileApi.createAttachFile : ', response);
          });
        });
      }

      const alertText = `
      ${userReducer.userMenu.find((thisItem) => {
        if (thisItem.pageCode === '111') return true;
        else return false;
      })?.pageName}를 추가했습니다.
      `;
      alert(alertText);
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  /* ====================================================================== #6 */

  return (
    <Grid2Body contents={
      <>
        <MainSection>
          <NavBar title={<NavTitle menuCode={'111'} />} nav={''} />

          <Form
            title={<><NavTitle menuCode={'111'} />추가</>}
            buttons={
              <>
                <button className="formButton" onClick={actCreate}>저장</button>
                <button className="formButton cancle"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(pageReducer.currentPage, { replace: true });
                  }}
                >
                  취소
                </button>
              </>
            }
            forms={
              <>
                <div className="formBox w100">
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', lineHeight: '30px' }}>
                    <h4>공정</h4>
                    <div className="formButton"
                      style={{ borderRadius: '5px', width: '150px', backgroundColor: 'var(--MainBlue)' }}
                      onClick={() => {
                        dispatch(contentsReducer_setWorkOrderContents([]));
                        setTimeout(setProcessModalStatus(true), 1000);
                      }}
                    >
                      공정 선택
                    </div>
                  </div>

                  <TableSection content={
                    <table>
                      <thead>
                        <tr style={{ border: 'none' }}>
                          <th rowSpan={2} style={{ minWidth: '50px', width: '50px' }}></th>
                          {/* <th rowSpan={2} style={{ minWidth: '60px', width: '60px' }}>목표공정</th> */}
                          <th rowSpan={2} style={{ minWidth: '60px', padding: 'unset', width: '60px' }}>순서</th>
                          <th rowSpan={2}>공정유형</th>
                          <th rowSpan={2}>공정코드</th>
                          <th rowSpan={2}>공정이름</th>
                          <th rowSpan={2}>작업위치</th>
                          <th rowSpan={2}>비고</th>
                          <th colSpan={3} style={{ backgroundColor: 'var(--ThirdBlue)' }}>공정완료제품</th>
                          <th colSpan={3} style={{ backgroundColor: 'var(--SeconYellow)' }}>투입 자재/반제품</th>
                        </tr>
                        <tr>
                          <th>물품이름</th>
                          <th>물품코드</th>
                          <th>생산량</th>
                          <th>물품이름</th>
                          <th>물품코드</th>
                          <th>투입량</th>
                        </tr>
                      </thead>
                      <tbody>
                        {checkNullArray(_processDataList, []).map((thisItem, index) => {
                          return (
                            <CreateWorkOrderRow
                              key={index + '_processDatas'}
                              index={index}
                              content={thisItem}
                              processLength={_processDataList.length}
                              delEvent={delItem}
                              handleCheckedItem={handleCheckedItem}
                              handleNumberEvent={handleNumberEvent}
                              handleProcessCount={handleProcessCount}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  }
                    formSectionStyle={{ paddingTop: '10px', width: '100%' }}
                  />
                </div>

                <div className="formBox">
                  <h4>작업유형</h4>
                  <select
                    name="workOrderType"
                    value={_formData.workOrderTypeId || ''}
                    onChange={(e) => { setFormData((prev) => { return { ...prev, workOrderTypeId: e.target.value }; }); }}
                  >
                    <option value="">작업유형</option>
                    {_workOrderTypes.map((thisType) => {
                      return (<option key={thisType.workOrderTypeId} value={thisType.workOrderTypeId}>{thisType.workOrderTypeName}</option>);
                    })}
                  </select>
                </div>

                <div className="formBox">
                  <h4>작업코드</h4>
                  <input
                    type="data"
                    name="workOrderCode"
                    data-required="required"
                    placeholder="* 작업코드..."
                    value={_formData.workOrderCode || ''}
                    onInput={handleChangeEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>작업이름</h4>
                  <input
                    type="text"
                    name="workOrderName"
                    data-required="required"
                    placeholder="* 작업이름..."
                    value={_formData.workOrderName || ''}
                    onInput={handleChangeEvent}
                  />
                </div>

                {(_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) ? (
                  <div className="formBox">
                    <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                      <h4>거래처</h4>
                      <div className="formButton"
                        style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                        onClick={() => { setAccountModalStatus(true); }}
                      >
                        거래처 선택
                      </div>
                    </div>
                    <div style={{ columnGap: '5px', display: 'grid', gridTemplateColumns: 'auto 50px', width: '100%', }}>
                      <AccountInput
                        type="text"
                        name="accountName"
                        value={_account.accountName || ''}
                        readOnly
                        placeholder="거래처..."
                      />
                      <div className="formButton"
                        style={{ backgroundColor: 'var(--MainNavy)', borderRadius: '5px', height: '40px', width: '50px' }}
                        onClick={() => { setAccount(() => { return {}; }); }}
                      >
                        삭제
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="formBox placeBox">
                  <div>
                    <h4>작업 위치</h4>
                    <div className="formButton" onClick={() => { setWorkPlaceModalStatus(true); }}>위치 선택</div>
                  </div>
                  <div>
                    <input
                      type="text"
                      className='place'
                      name="workPlace"
                      placeholder="* 작업 위치..."
                      readOnly
                      value={checkEmptyNull(_workPlace.placeName, '')}
                    />
                    <div className="formButton" onClick={() => { setWorkPlace(() => { return {}; }); }}>삭제</div>
                  </div>
                  <div>
                    <input
                      type="text"
                      name="workPlace"
                      placeholder="작업위치..."
                      value={_formData.workPlace || ''}
                      onInput={handleChangeEvent}
                    />
                  </div>
                </div>

                <div className="formBox">
                  <h4>비고</h4>
                  <textarea
                    name="remark"
                    placeholder="비고..."
                    value={_formData.remark || ''}
                    onInput={handleChangeEvent}
                  ></textarea>
                </div>

                {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? (
                  <div className="formBox">
                    <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                      <h4>파일</h4>
                      <div className="formButton"
                        style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                        onClick={() => {
                          dispatch(modalFileActions_setFileReset());
                          setTimeout(setFileModalStatus(true), 1000);
                        }}
                      >
                        파일 선택
                      </div>
                    </div>

                    <FileList>
                      {_fileList.map((thisItem, index) => {
                        return (
                          <FileBox key={thisItem.attachFileId + '_files'}>
                            {thisItem.fileDataName}
                            <DeleteFileButton onClick={(e) => { actDeleteFile(e, index, thisItem); }}><CloseButtonIcon /></DeleteFileButton>
                          </FileBox>
                        );
                      })}
                    </FileList>
                  </div>
                ) : null}
              </>
            }
            formSectionStyle={{ paddingTop: '10px', width: 'calc(100% - 210px)' }}
          />
        </MainSection>

        {_processModalStatus === true && (
          <SelectProcesses
            buttonTitle={'공정 선택'}
            buttonEvent={(data) => {
              console.log('data : ', data);
              const newDataList = [..._processDataList, ...data];
              const processList = newDataList.map((thisItem, index) => {
                const returnData = { ...thisItem };
                if (!checkEmptyNull(returnData.processNumber, false)) returnData.processNumber = index + 1;
                return returnData;
              });
              setFormData((prev) => { return { ...prev, workOrderProcessList: processList }; });
              setProcessDataList(() => { return processList; });
              setTimeout(setProcessModalStatus(false), 1000);
            }}
            open={_processModalStatus}
            close={() => { setProcessModalStatus(false); }}
          />
        )}

        {_accountModalStatus === true && (
          <SelectOneAccount
            buttonTitle={'거래처 선택'}
            buttonEvent={(data) => {
              setAccount(() => { return data; });
              setTimeout(setAccountModalStatus(false), 1000);
            }}
            open={_accountModalStatus}
            close={() => { setAccountModalStatus(false); }}
          />
        )}

        {_fileModalStatus === true && (
          <SelectFiles
            buttonTitle={'파일 선택'}
            buttonEvent={(data) => {
              const newDataList = data.filter((thisItem) => _fileList.findIndex((thisIndex) => thisIndex.fileDataId === thisItem.fileDataId) === -1);
              setFileList((prev) => { return [...prev, ...newDataList]; });
              setTimeout(setFileModalStatus(false), 1000);
            }}
            open={_fileModalStatus}
            close={() => { setFileModalStatus(false); }}
          />
        )}

        {_workPlaceModalStatus === true && (
          <SelectWorkPlaceModal
            buttonTitle={'위치 선택'}
            buttonEvent={(data) => {
              setWorkPlace(() => { return data });
              setTimeout(setWorkPlaceModalStatus(false), 1000);
            }}
            open={_workPlaceModalStatus}
            close={() => { setWorkPlaceModalStatus(false) }}
          />
        )}
      </>
    }
    />
  );
};

export default CreateWorkOrder;
