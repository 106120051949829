import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import styled from 'styled-components';

import { checkEmptyNull, checkNullObject } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import NavText from 'components/nav/NavText';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  height: 85%;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: space-between;
  padding: 0px 60px 0px 20px;
  position: relative;
  width: 100%;
  box-shadow: 0 4px 18px 0 rgba(196,196,196,0.8);
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
  }
`;

const MainContents = styled.div`
  box-sizing: border-box;
  height: 100%;
  justify-content: center;
  padding: 10px;
  width: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 4px;
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const ModalButton = styled.div`
  width: 20%;
  border-radius: 8px;
  height: fit-content;
  padding: 8px;
  box-shadow: 0 2px 6px 0 rgba(196,196,196,0.8);
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingScreen = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  color: var(--white);
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

/* ========================= PrintContent ========================= */
const ExportSectionOne = styled.div`
  border: 1px solid var(--gray-500);
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 70%;
  grid-template-rows: auto 40px;
  justify-content: center;
  margin: 30px auto;
  overflow: hidden;
  padding: 30px;
  position: relative;
  row-gap: 5px;
  text-align: center;

  height: 297mm;
  width: 210mm;

  table {
    max-width: 100%;
  }

  .print {
    input {
      vertical-align: middle;
    }
  }

  .tg {
    border-collapse:collapse;
    border-spacing:0;
  }
  .tg td{
    border-color:black;
    border-style:solid;
    border-width:1px;
    font-family:Arial, sans-serif;
    font-size:14px;
    overflow:hidden;
    padding:10px 5px;
    word-break:normal;
  }
  .tg th{
    border-color:black;
    border-style:solid;
    border-width:1px;
    font-family:Arial, sans-serif;
    font-size:14px;
    font-weight:normal;
    overflow:hidden;
    padding:10px 5px;
    word-break:normal;
  }
  .tg .tg-coru{
    background-color:#ffffff;
    border-color:black;
    text-align:center;
    vertical-align: middle;
  }
  .tg .tg-0lax{
    text-align:center;
    vertical-align:middle;
  }

  .for-heigth-wide {
    td {
      height: 80px;
    }
  }

`;

const ExportSectionTwo = styled.div`
  border: 1px solid var(--gray-100);
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 98%;
  grid-template-rows: 60px auto 40px;
  justify-content: center;
  margin: 30px auto;
  padding: 30px;
  overflow: hidden;
  position: relative;
  row-gap: 5px;

  height: 297mm;
  width: 210mm;

  table {
    height: fit-content;
  }

  .print {
    input {
      vertical-align: middle;
    }
  }
  .tg {
    border-collapse:collapse;
    border-spacing:0;
  }
  .tg td{
    border-color:black;
    border-style:solid;
    border-width:1px;
    font-family:Arial, sans-serif;
    font-size:14px;
    overflow:hidden;
    padding:10px 5px;
    word-break:normal;
  }
  .tg th{
    border-color:black;
    border-style:solid;
    border-width:1px;
    font-family:Arial, sans-serif;
    font-size:14px;
    font-weight:normal;
    overflow:hidden;
    padding:10px 5px;
    word-break:normal;
  }
  .tg .tg-9wq8{
    border-color:inherit;
    text-align:center;
    vertical-align:middle
  }

`;

const PrintContent = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector(state => state);
  /* ====================================================================== #2 */
  const { content, currentPage, printContent, shipmentInspection, totalPage } = props;

  /* ====================================================================== #3 */
  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  /* ====================================================================== #6 */

  return (
    <>
      <ExportSectionTwo name={'printContent'}>
        <div style={{
          alignItems: 'flex-end',
          boxSizing: 'border-box',
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: '10px',
          width: '100%',
        }}>
          <h5>{shipmentInspection.shipmentInspectionCode}</h5>
          <h6>{`${currentPage} / ${totalPage}`}</h6>
        </div>

        <table className="tg">
          <thead style={{ backgroundColor: 'var(--Bg)' }}>
            <tr>
              <th className="tg-9wq8" style={{ width: '10mm' }}>No.</th>
              <th className="tg-9wq8">점검 / 검사 항목</th>
              <th className="tg-9wq8" style={{ width: '40mm' }}>점검 / 검사 내용</th>
              <th className="tg-9wq8" style={{ width: '40mm' }}>판정 기준</th>
              <th className="tg-9wq8" style={{ width: '40mm' }}>점검 / 검사 결과</th>
            </tr>
          </thead>
          <tbody>
            {content.map((el, idx) => {
              return (
                <tr key={idx}>
                  <td className="tg-9wq8">{idx + 1}</td>
                  <td className="tg-9wq8">{el.shipmentInspectionItem.shipmentInspectionItemTypeName}</td>
                  <td className="tg-9wq8">{el.shipmentInspectionItem.content}</td>
                  <td className="tg-9wq8">{el.shipmentInspectionItem.criteria}</td>
                  <td className="tg-9wq8"></td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <h2 style={{ textAlign: 'center' }}>{userReducer.company.companyName}</h2>
      </ExportSectionTwo>
    </>
  );
};

const ShipmentInspectionFileModal = (props) => {
  /* ========================================================================= #1 */
  const { userReducer } = useSelector(state => state);

  /* ========================================================================= #2 */
  const { shipmentInspection } = props;

  const [_printContents, setPrintContents] = useState(() => {
    const shipmentInspectionItemConnectorList = shipmentInspection.shipmentInspectionItemConnectorList;
    const returnList = [];
    if (checkNullObject(shipmentInspectionItemConnectorList, false)) {
      for (let i = 0; i < shipmentInspectionItemConnectorList.length; i += 12) {
        const sliceArray = shipmentInspectionItemConnectorList.slice(i, i + 12);
        const returnArray = sliceArray.map((inspection) => {
          return inspection;
        });
        returnList.push(returnArray);
      }
      return returnList;
    } else {
      return [];
    }
  });

  const [_loadingStatus, setLoadingStatus] = useState(false);

  /* ========================================================================= #3 */
  /* ========================================================================= #4 */
  /* ========================================================================= #5 */
  const acrPrintContent = () => {
    const style = document.createElement('style');
    document.head.appendChild(style);
    style.sheet?.insertRule('body > div:last-child img { display: inline-block; }');

    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
    });

    document.getElementsByName('printContent').forEach(async (thisItem, index) => {
      const thisHeight = thisItem.offsetHeight;
      const thisWidth = thisItem.offsetWidth;
      const ratio = thisWidth / thisHeight;

      const option = {
        height: thisHeight,
        width: thisWidth,
      };

      await html2canvas(thisItem, option).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');

        let width = doc.internal.pageSize.getWidth();
        let height = 280;
        width = ratio * height;

        const margin = 6;
        const position = 6;

        doc.addImage(imgData.replace('data:image/png;base64,', ''), 'PNG', margin, position, width, height);
        if (index < document.getElementsByName('printContent').length - 1) {
          doc.addPage();
        }
      });

      if (index === document.getElementsByName('printContent').length - 1) {
        if (document.getElementsByName('printContent').length > 10) {
          style.remove();
          doc.save();
          setLoadingStatus(false);
        } else {
          style.remove();
          window.open(doc.output('bloburl'));
          setLoadingStatus(false);
        }
      }
    });
  };

  /* ========================================================================= #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle><NavText menuCode={'604'} /> 출력 미리보기</ModalTitle>
              <ModalButton
                onClick={() => {
                  setTimeout(acrPrintContent, 1000);
                }}
              >
                PDF 출력
              </ModalButton>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>
            <ModalMain>
              <MainContents id="PrintSection">
                <ExportSectionOne name={'printContent'}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                  }}>
                    <div style={{
                      display: 'flex',
                      flex: '0.85 auto',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      rowGap: '20px'
                    }}>
                      <h2 style={{ fontSize: '60px' }}>{shipmentInspection.shipmentInspectionCode}</h2>
                      <div style={{ textAlign: 'center' }}>{shipmentInspection.shipmentInspectionName}</div>
                    </div>

                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 'auto 0.15'
                    }}>
                      <table className="tg">
                        <thead>
                          <tr>
                            <th className="tg-coru" style={{ textAlign: 'center' }}>{userReducer.company.companyName}</th>
                            <th className="tg-0lax" style={{ textAlign: 'center' }}>담당</th>
                            <th className="tg-0lax" style={{ textAlign: 'center' }}>검토</th>
                            <th className="tg-0lax" style={{ textAlign: 'center' }}>승인</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className='for-heigth-wide'>
                            <td className="tg-0lax"><h3>{userReducer.company.companyName}</h3></td>
                            <td className="tg-0lax">{shipmentInspection.managerName}</td>
                            <td className="tg-0lax">{shipmentInspection.checkerName}</td>
                            <td className="tg-0lax">{shipmentInspection.approvalUserName}</td>
                          </tr>
                        </tbody>
                      </table>

                      <h2 style={{
                        marginTop: '10mm',
                        textAlign: 'center'
                      }}>
                        {checkEmptyNull(shipmentInspection.shipmentInspectionDate, false) ? moment(shipmentInspection.shipmentInspectionDate).format('YYYY. MM. DD') : ''}
                      </h2>
                    </div>
                  </div>

                  <h2 style={{ textAlign: 'center' }}>{userReducer.company.companyName}</h2>
                </ExportSectionOne>

                {_printContents.map((content, index) => {
                  return (
                    <PrintContent
                      key={index + '_printContents'}
                      totalPage={_printContents.length}
                      currentPage={index + 1}
                      content={content}
                      shipmentInspection={shipmentInspection}
                      printContent={_printContents}
                    />
                  )
                })}
              </MainContents>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}
      ;
      {_loadingStatus === true ? (
        <LoadingScreen>파일 생성 중입니다...</LoadingScreen>
      ) : null}
    </>
  );
};

export default ShipmentInspectionFileModal;
