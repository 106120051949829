const initialState = {
  currentFamilyId: 0,

  allFamilies: [],
  firstFamilies: [],
  secondFamilies: [],
  thirdFamilies: [],
  fourthFamilies: [],
  fifthFamilies: [],

  firstFamily: '',
  secondFamily: '',
  thirdFamily: '',
  fourthFamily: '',
  fifthFamily: '',

  productType: '',
  workStatus: '',
  startDate: '',
  endDate: '',
  searchOption: '',
  searchText: '',

  currentPageNum: 1,
  totalCount: 0,
  pageSize: 20,
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'MODALCURRENTFAMILYID':
      return {
        ...state,
        currentFamilyId: action.payload
      };

    case 'MODALALLFAMILIES':
      return {
        ...state,
        allFamilies: action.payload
      };
    case 'MODALFIRSTFAMILIES':
      return {
        ...state,
        firstFamilies: action.payload
      };
    case 'MODALSECONDFAMILIES':
      return {
        ...state,
        secondFamilies: action.payload
      };
    case 'MODALTHIRDFAMILIES':
      return {
        ...state,
        thirdFamilies: action.payload
      };
    case 'MODALFOURTHFAMILIES':
      return {
        ...state,
        fourthFamilies: action.payload
      }
    case 'MODALFIFTHFAMILIES':
      return {
        ...state,
        fifthFamilies: action.payload
      }


    case 'MODALFIRSTFAMILY':
      return {
        ...state,
        firstFamily: action.payload
      };
    case 'MODALSECONDFAMILY':
      return {
        ...state,
        secondFamily: action.payload
      };
    case 'MODALTHIRDFAMILY':
      return {
        ...state,
        thirdFamily: action.payload
      };
    case 'MODALFOURTHFAMILY':
      return {
        ...state,
        fourthFamily: action.payload
      }
    case 'MODALFIFTHFAMILY':
      return {
        ...state,
        fifthFamily: action.payload
      }

    case 'MODALPRODUCTTYPE':
      return {
        ...state,
        productType: action.payload
      };
    case 'MODALWORKSTATUS':
      return {
        ...state,
        workStatus: action.payload
      };
    case 'MODALSTARTDATE':
      return {
        ...state,
        startDate: action.payload
      };
    case 'MODALENDDATE':
      return {
        ...state,
        endDate: action.payload
      };
    case 'MODALSEARCHOPTION':
      return {
        ...state,
        searchOption: action.payload
      };
    case 'MODALSEARCHTEXT':
      return {
        ...state,
        searchText: action.payload
      };

    case 'MODALCURRENTPAGENUM':
      return {
        ...state,
        currentPageNum: action.payload
      };
    case 'MODALTOTALCOUNT':
      return {
        ...state,
        totalCount: action.payload
      };
    case 'MODALPAGESIZE':
      return {
        ...state,
        pageSize: action.payload
      };

    case 'MODALRESET':
      return initialState;

    default:
      return state;
  }
}

export default modalReducer;