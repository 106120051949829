import styled from 'styled-components';

import { downArrow } from 'components/icons/src';
import { uploadSection } from 'components/icons/src';

// header
export const MallHeader = styled.header`
  align-items: center;
  background-color: var(--white);
  box-shadow: 0px 4px 24px 2px rgb(0 0 0 / 10%);
  box-sizing: border-box;
  color: var(--color-2);
  display: flex;
  font-weight: bold;
  height: 60px;
  justify-content: space-between;
  padding: 0px 40px;
  position: sticky;
  top: 0px;
  width: inherit;
  z-index: 99999;
  &::-webkit-scrollbar {display: none;}
  nav {
    align-items: center; 
    display: flex; gap: 8px; 
    justify-content: center;
    a, button {
      font-size: 1.125rem; 
      font-weight: bold;
      margin:0 8px; 
      padding:18px 24px; 
      &:hover {
        background: none; 
        color: var(--color-1);
      }
    }
    p {font-size: 1.125rem;}
  }
  .subnav {
    align-items: center;
    background-color: var(--gray-700);
    border-radius: 0px 0px 10px 10px;
    display: grid; 
    overflow: hidden;
    position: absolute; 
    right: 0px; 
    top: 60px; 
    width: 190px; 
    z-index: 900;
  }
  .menu-items {
    align-items: center;
    display: flex; 
    font-size: 14px;
    justify-content: center; 
    position: relative;
    width: inherit; 
    button {
      align-items: center;
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      font-size: inherit;
      font-size: 1rem;
      margin: 0;
      position: absolute;
      width: 100%;
      &:hover { 
        background: none; 
        color: var(--color-1); 
      }
    }
    p {
      color: inherit;
      cursor: pointer;
      display: block;
      padding: 18px 24px;
      text-decoration: none;
      &:hover { 
        background: none; 
        color: var(--color-1); 
      }
    }
  }
  .arrow::after {
    content: "";
    border-left: 0.32em solid transparent;
    border-right: 0.32em solid transparent;
    border-top: 0.42em solid;
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
  }
  .dropdown {
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08), 0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    display: none;
    left: auto;
    list-style: none;
    min-width: 10rem;
    padding: 0.5rem 0;
    position: absolute;
    right: -20px;
    text-align: center;
    top: 20px;
    z-index: 9999;
    .dropdown-submenu {
      left: 100%;
      position: absolute;
      top: -7px;
    }
  }
  .show {
    color: #000;
    display: block;
  }
  .content {
    margin: 0 auto;
    max-width: 1200px;
    padding: 3rem 20px;
    h2 {margin-bottom: 1rem;}
    a {
      color: #cc3852;
      margin-right: 10px;
    }
  }
`;


// Mall 페이지 --------------------------------------
export const Mall = styled.main`
  display: grid; 
  /* grid-template-rows: auto 40px; */
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: inherit;
  h3 {
    font-size:1.5rem; 
    margin: 40px 0;
    /* width:1200px; */
  }

  .mySwiper {
    &.KyungSeo {
      height: 600px;
      & img {height: 100%; width: auto;}
    }
  }

  .mall-product {
    // 카테고리 페이지
    // /mall/components/MallSiteCategories
    &-category {
      align-items: center; 
      display: flex; 
      flex-wrap: wrap;
      gap: 16px;
      justify-content: flex-start; 
      width: inherit;
      select {
        align-items: center; 
        border-radius: 8px;
        display: flex; 
        height: 40px; 
        justify-content: center; 
        padding-left: 8px; 
        width: 276px; 
      }
    }
    // 전체 상품 페이지 
    &-all {
      align-items: center; 
      box-sizing: border-box;
      display: grid; 
      gap: 16px;
      justify-content: center; 
      padding-block: 60px;
      padding-inline: 20px;
      /* overflow: hidden; */
      overflow-x: hidden;
      overflow-y: scroll;
      width: inherit;
      section {
        &:nth-of-type(2) {
          /* justify-content: space-between; */
          margin-top: 40px;
          /* width: 1200px; */
        }
      }
    }
    // 목록 페이지
    // /mall/pages/product/list
    &-list {
      align-items: flex-start; 
      box-sizing: border-box;
      display: grid; 
      gap: 16px;
      justify-content: center; 
      padding-bottom: 60px;
      padding-inline: 20px;
      /* overflow: hidden; */
      overflow-x: hidden;
      overflow-y: scroll;
      width: inherit;
      // 검색 섹션
      &-search {
        display: grid;
        grid-template-columns: calc(80% - 96px) 80px;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: center;
        width: inherit;
        &-family{
          align-items: center; 
          display: flex;
          flex-wrap: wrap;
          gap: 16px;
          justify-content: flex-start; 
          width: inherit;
          &>div {
            align-items: center; 
            display: flex; 
            gap: 12px;
            justify-content: center; 
            select {
              border-radius: 8px;
              height: 40px; 
              padding:0 8px; 
              width: 150px; 
            }
            input {
              border-radius: 8px;
              height: 40px; 
              padding:0 16px; 
              width: 300px; 
            }
            &:nth-of-type(3) {
              justify-content: flex-end;
              button {
                &:nth-of-type(2) {width: 100px;}
              }
            }
          }
        }
        ul {
          display: grid; 
          gap:16px;
        }
        button {
          background-color: var(--white); 
          border-bottom: none;
          border-radius: 8px;
          color: var(--gray-800); 
          display: flex; 
          gap: 10px; 
          height: inherit;
          overflow: hidden;
          width: 80px; 
        }
        ul {
          background-color: #fff;
          border: 1px solid #ddd;
          border-radius: 8px;
          display: grid;
          padding: 24px;
          /* place-items: center; */
          width: 100%;
          select, input {
            background: #f3f3f3;
            border: 1px solid #ddd;
          }
        }
        button {
          background-color: #fff;
          border-radius: 8px;
          border: 1px solid #ddd;
          padding: 24px;
        }
        @media (max-width: 1500px) {
          /* ul {width: fit-content;} */
        }
      }
      &-card {
        display: flex; 
        flex-wrap: wrap; 
        gap: 24px; 
        justify-content: center;
        margin-bottom: 40px;
        padding: 0;
        padding: 0 38px;
        // 카드 컴포넌트
        // /mall/components/CardV
        .card {
          background: #fff;
          border: 1px solid #ddd; 
          border-radius: 10px; 
          display: grid; 
          overflow: hidden;
          &:hover{
            background-position: 100% 0; 
            box-shadow: 0 4px 15px 0 rgba(196, 196, 196, 0.55);
            transition: all .4s ease-in-out; -moz-transition: all .4s ease-in-out; -o-transition: all .4s ease-in-out; -webkit-transition: all .4s ease-in-out;
          }
          .card-thumbnail {
            cursor: pointer;
            height: 300px; 
            overflow: hidden;
            position: relative; 
            width: 348px; 
            img {
              left:0;
              position: absolute; 
              top:0; 
              width: inherit; 
            }
          }
          ul {
            box-shadow: 0px -6px 200px 10px rgb(0 0 0 / 30%);
            display: grid;
            gap:2px;
            padding: 32px 24px; 
            z-index: 2;
            li {
              align-items: center; 
              display: flex; 
              gap:16px; 
              position: relative;
              p {
                color: var(--gray-700);
                font-size: 1.125rem;
                &:nth-of-type(1){}
              }
              button {
                border-radius: 50%;
                bottom:0;
                height: 40px; 
                padding: 16px; 
                position: absolute; 
                right:4px;
                width: 40px; 
                img{opacity: 0.7;}
              }
            }
          }
        }
      }
      .mypage-info-area {
        display: grid; 
        gap:16px;
        .mall-product-qna{
          display: grid;
          gap:24px; 
          position: relative;
          .qna-status {
            li {
              p{font-size: 1rem;}
            }
          }
          .qna-sub{
            .qna-sub-title {font-size: 2rem;}
            .qna-sub-list {
              li {
                flex-wrap: nowrap;
                p {
                  display: flex; 
                  gap: 8px;
                  input {width: 40px;}
                  button {
                    background: #ddd; 
                    border-radius:8px;
                    width: 24px; 
                  }
                }
              }
              div {
                li {
                  p {}
                  &:nth-of-type(1) {
                    &::before {
                      content: '주문 상품';
                      height: 100%;
                      left: 10px;
                      position: absolute;
                      top: -30px;
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
        &-order-list {
          display: grid;
          gap: 20px;
          grid-template-rows: 60px calc(100% - 140px) 40px;
          padding-block: 60px 0px;
          // QnA > 문의 리스트
          .qna-wrap {
            height: 100%;
            margin: auto;
            max-width: 1200px;
            overflow-y: scroll;
            position: relative;
            width: 100%;
          }
          .qna-status {
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 10px;
            color: var(--gray-500);
            display: grid;
            &-title {
              p{
                /* &:nth-last-of-type(1){width: 10%;} */
              }
            }
            li {
              display: grid;
              gap: 10px;
              grid-template-columns: 80px 80px 250px 2fr 1fr 1fr;
              text-align: center;
              p {
                width: 100%;
              }
              /* .first {width: 10%;} */
              /* p{} */
              /* &:nth-of-type(1) {
                display: flex; 
                justify-content: space-between;
              } */
            }
          }
          .qna-li{
            display: flex; 
            font-size: 1rem;
            justify-content: space-between;    
            &-image {
              background: #f1f1f1;
              border-radius: 8px;
              margin: 0 16px;
              padding: 8px 24px;
              width: 260px;
            }
          }
          // QnA > 검색 네비
          &-search {
            align-items: center;
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 8px;
            box-sizing: border-box;
            display: flex; 
            gap:16px; 
            justify-content: flex-start; 
            margin: auto;
            max-width: 1200px;
            padding: 12px;
            position: relative;
            width: 100%;
            select {
              align-items: center; 
              background: #f3f3f3; 
              border: 1px solid #ddd;
              border-radius: 8px;
              display: flex; 
              height: 40px; 
              justify-content: center; 
              padding-left: 8px; 
              width: 100px; 
            }
            input {
              background: #f3f3f3; 
              border: 1px solid #ddd;
              border-radius: 8px;
              height: 40px; 
              padding:0 16px; 
              width: 300px; 
            }
            button {
              align-items: center;
              background-color: var(--gray-800); 
              border-bottom: none;
              border-radius: 8px;
              color: var(--white); 
              display: flex; 
              gap: 10px; 
              height: inherit;
              justify-content: center;
              padding:8px 16px;
              width: fit-content; 
              &[name="createQnA"] {
                position: absolute;
                right: 10px;
              }
            }
          }
        }
      }
    }
    // 디테일 페이지
    // /mall/pages/product/detaile
    &-detail {
      &>div {
        display: flex; 
        gap: 40px; 
        margin-top: 80px;
        .detailTitle {
          color: var(--color-3);
          font-size: 1.25rem; 
          margin: 8px 16px 24px;
          position: relative;
          &::before {
            content: '';
            background: #000;
            height: 20px;
            left: -16px; 
            position: absolute; 
            top: 6px;
            width: 8px; 
          }
        }
        .image {
          display: grid;
          overflow: hidden;
          img {
            background: #eee; 
            border-radius: 16px;
            height: 640px; 
            width: 100%;
            width: 640px; 
          }
        }
        ul {
          background: #fff;
          border-radius: 16px;
          box-shadow: 0px 4px 10px 4px rgb(0 0 0 / 4%);
          display: grid; 
          gap: 16px;
          padding: 36px 60px;
          hr {
            border: 1px dashed #ddd; 
            margin: 8px 0px;
          }
          li {
            align-items: center;
            display: flex; 
            gap: 16px; 
            &>p {
              align-items: center; 
              display: flex; 
              font-size: 1.25rem;
              &:nth-of-type(1) {
                font-weight: bold;
                width: 150px; 
              }
              &:nth-of-type(2) {width: 300px;}
            }
            .totalprice {
              border-top:1px solid;
              padding-top:24px; 
            }
            &:nth-last-of-type(1) {
              button {
                border:none; 
                border-radius: 8px;
                color: var(--white); 
                padding: 16px; 
                width: 100%; 
                &:hover{opacity:.8;}
                &:nth-of-type(1) {background-color: var(--gray-800);}
                &:nth-of-type(2) {background-color: var(--gray-700);}
                &:nth-of-type(3) {background-color: var(--MainBlue);}
              }
            }
          }
          .quantity {
            input {
              background-color: #eee;
              border-radius: 8px;
              padding: 8px 16px; 
              width: 110px; 
              width: 40%;
            }
            button {
              border-radius: 4px;
              font-size: 1.5rem; 
              line-height: 150%;
              width: 40px; 
              &:hover{opacity:.8;}
            }
          }
        }
      }
    }
    // 장바구니 페이지
    // /mall/product/cart/index
    &-cart {
      align-items: flex-start; 
      box-sizing: border-box;
      display: grid; 
      gap: 16px;
      grid-template-rows: calc(100% - 182px) 40px 110px;
      height: 100%;
      justify-content: center;    
      margin: auto;
      max-width: 95%;
      overflow: hidden;
      padding-block: 60px; 
      width: inherit;
      .cart-wrap {
        height: 100%;
        overflow-y: scroll;
        position: relative;
        width: 100%;
      }
      &-list {
        display: grid; 
        gap:24px;
        width: 100%;
        &>li {
          &:hover{
            background-position: 100% 0; 
            box-shadow: 0 4px 15px 0 rgba(196, 196, 196, 0.55);
            transition: all .4s ease-in-out; -moz-transition: all .4s ease-in-out; -o-transition: all .4s ease-in-out; -webkit-transition: all .4s ease-in-out;
          }
          &:nth-of-type(1){
            background-color: var(--white);
            border: 1px solid #ddd;
            font-size: 1.25rem;
            font-weight: bold;
            padding: 16px 24px;
            position: sticky;
            top: 0px;
            z-index: 1;
            &:hover{box-shadow: none;}
          }
          align-items: center; 
          background-color:var(--white);
          border-radius:10px;
          display:grid;
          grid-template-columns: 50px 140px auto 50px;
          gap:24px; 
          justify-content:space-between; 
          padding:24px;
          width: 100%;
          input {
            background-color: #eee;
            border-radius: 8px;
            cursor: pointer;
            height: 28px;
            padding: 0 20px;
            width: 100px;
            &[type='checkbox'] {
              height: 20px; 
              margin: 0 20px;
              width: 20px; 
              &:checked {background-color: red;}
            }
          }
          .thumbnail{
            align-items: center;
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0px 4px 10px 4px rgb(0 0 0 / 4%);
            display: flex;
            height: 140px;
            justify-content: center;
            overflow: hidden;
            width: fit-content;
            img{
              height: 100%;
              width: auto; 
            }
          }
          .infos {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: 24px;
          }
          ol {
            align-items: center;
            display: flex; 
            font-size: 1rem;
            font-weight: bold;
            flex-wrap: wrap; 
            gap: 10px;
            height:100%; 
            width: min-content;
            &:nth-of-type(1) {
              li {
                &:nth-of-type(1) {
                  align-items: center;
                  height: 50%; 
                }
                &:nth-of-type(2) {
                  align-items: center;
                  height: 40%; 
                }
              }
            }
            li {
              border-bottom: 1px solid #ddd;
              display: flex;
              gap:10px;
              padding: 8px;
              div{
                display:flex;
                gap:8px;
                justify-content:space-between;
                button{
                  border:none;
                  height:24px;
                  margin:0;
                  min-width:inherit;
                  padding:0;
                  width:24px;
                }
              }
              p{
                &:nth-of-type(1){width:60px;}
                &:nth-of-type(2){
                  opacity:.6;
                  width:max-content; 
                }
              }
            }
          }
          button {
            align-items:center;
            background:none;
            border:none;
            display:flex;
            height:100%;
          }
        }
      }
      &-order {
        background-color: var(--white);
        bottom: 0;
        box-shadow: 0px -14px 24px 2px rgb(0 0 0 / 10%);
        position: sticky; 
        .total {
          align-items: center;
          display: flex;
          font-size: 1.25rem;
          font-weight: bold;
          gap: 24px;
          height: 60px;
          justify-content: flex-end;
          margin-right: 40px;
        }
        &>div{
          display:flex;
          button {
            align-items: center;
            background-color: var(--gray-800);
            border:none;
            border-radius:0;
            color: var(--white); 
            cursor: pointer;
            display: flex;
            height: 50px;
            justify-content: center;
            width: 100%;
          }
        }
      }
    }
    // 제품 문의 페이지
    // /mall/product/qna
    &-qna {
      box-sizing: border-box;
      display: grid;
      gap: 20px;
      grid-template-rows: calc(100% - 550px) 450px 60px;
      height: 100%;
      margin: auto;
      max-width: 95%;
      padding-block: 60px;
      overflow: hidden;
      position: relative;
      width: inherit;
      &.non-padding {padding-block: 0px;}
      .qna-wrap {
        height: 100%;
        margin: auto;
        max-width: 1200px;
        overflow-y: scroll;
        position: relative;
        width: 100%;
      }
      ul:not(.pagination) {
        background:#fff;
        border-radius:10px;
        color: var(--gray-500);
        &[data-info='true']{
          li{
            &[data-status='open']{
              background: #fff;
              box-shadow: 0 10px 15px -3px rgba(46,41,51,0.08), 0 4px 6px -2px rgba(71,63,79,0.16);
              margin: 0;
              .qna-li{color: var(--MainRed);}
              .toggle{transform: rotate(0deg);}
            }
          }
        }
        &[data-info='false']{
          li{
            &[data-status='open'] {
              background: #fff;
              box-shadow: 0 10px 15px -3px rgba(46,41,51,0.08), 0 4px 6px -2px rgba(71,63,79,0.16);
              margin: 0;
              .qna-li{color: var(--MainRed);}
              .toggle{transform: rotate(-180deg);}
            }
          }
        }
        li {
          align-items: center;
          background-color: var(--white);
          /* box-sizing: content-box; */
          /* border-bottom: none; */
          border-bottom: 1px dashed #898989;
          display: grid;
          grid-template-columns : repeat(4, 1fr) 70px;
          justify-content: space-between; 
          margin: 8px 0; 
          padding: 8px;
          /* padding: 18px;
          padding: 18px 0; */
          width: 100%; 
          // 첫 row
          &:nth-of-type(1){
            border: none;
            font-weight:bold;
            margin:0;
            position: sticky;
            top: 0px;
            z-index: 1;
            /* p{&:nth-last-of-type(1){width:auto;}} */
          }
          &:nth-last-of-type(1){border-bottom:none;}
          .image {
            background: #f1f1f1f1;
            border-radius:10px;
            display: flex;
            height:40px;
            justify-content: center;
            width: 100%;
            /* img {width: 60%;} */
          }
          p {
            text-align:center;
            width:100%; 
          }
          button{
            background:none;
            /* width:38%; */
          }
        }
      }
      
      // 상품 문의 폼과 일반 문의 폼 스타일은 동일함
      .form {
        box-sizing: border-box;
        margin: auto;
        max-width: 1200px;
        padding-bottom: 32px;
        width: 100%;
        h3 {
          background-color: var(--Bg);
          padding-bottom: 10px;
          margin: 0px auto 20px auto;
          position: sticky;
          top: 0px;
        }
        textarea {
          border:none;
          border-radius:8px;
          box-sizing: border-box;
          height:180px;
          /* margin:16px 0; */
          padding:24px;
          resize:none;
          width:100%;
        }
        .file {
          background: #fff;
          border-radius: 10px;
          margin-top:32px;
          padding: 16px;
          label{
            align-items: center;
            background: none;
            border: none;
            color:#898989;
            display: flex;
            flex-direction: row;
            gap:20px; 
            height: inherit;
            justify-content: center; 
            max-width: inherit;
            padding: 8px;
            width: 100%; 
          }
          ol {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            justify-content: flex-start;
          }
          img {
            cursor: pointer;
            height: auto;
            max-height: 100px;
            max-width: 100px;
            width: auto;
            &:hover{opacity:.8;}
          }
        }
      }
      .submit {
        background-color: var(--gray-800);
        border-radius:10px;
        color:var(--white); 
        font-size:1.25rem;
        height:60px; 
        margin: auto;
        max-width: 1200px;
        width:100%;
      }
      &-info {
        background: #fff;
        height: max-content;
      }
      .qna-status{
        display: grid;
        li {
          display: flex; 
          flex-wrap: wrap;
          /* p {
            width: 20%;
            &:nth-of-type(2){
              font-size: 1rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 150px;
              word-break: break-all;
            }
          } */
          &:nth-of-type(1){
            &:hover{opacity:1;}
            cursor: auto;
          }
          .qna-li {
            display: flex;
            justify-content: space-between;
            width: 100%;
          }
          .goDetaile {
            background: #ddd;
            border-radius: 8px;
            cursor: pointer;
            margin-right: 16px;
            padding: 8px 16px;
            width: 10%;
            &:hover {
              background: #000;
              color: #fff;
            }
          }
          .toggle{
            background-color: var(--gray-800);
            height: 20px;
            margin: 0 50px;
            transform: rotate(270deg);
            width: 20px;
            mask-image: url(${downArrow});mask-repeat: no-repeat;mask-size: contain;
            --webkit-mask-image: url(${downArrow});--webkit-mask-repeat: no-repeat;--webkit-mask-size: contain;
            &[data-status='open'] {}
          }
          &[data-status='open'] {
            .mall-product-qna-info {display: block;}
          }
        }
      }
    }
    // 주문하기 페이지
    // /mall/product/order
    &-order {
      box-sizing: border-box;
      display: grid;
      gap: 20px;
      grid-template-rows: calc(100% - 550px) 450px 60px;
      height: 100%;
      margin: auto;
      max-width: 95%;
      padding-block: 60px;
      overflow: hidden;
      width: inherit;
      .order-wrap {
        height: 100%;
        margin: auto;
        max-width: 1200px;
        overflow-y: scroll;
        position: relative;
        width: 100%;
        &>ul {
          background:#fff; 
          border-radius:10px;
          li {
            align-items: center;
            background-color: var(--white);
            border-bottom: 1px dashed #898989;
            display: grid;
            grid-template-columns : repeat(6, 1fr) 70px;
            justify-content: space-between; 
            margin: 8px 0; 
            padding: 8px;
            width: 100%; 
            // 레코드의 첫 row
            &:nth-of-type(1){
              border:none;
              font-weight:bold;
              margin:0;
              position: sticky;
              top: 0px;
              z-index: 1;
              /* p{&:nth-last-of-type(1){width:50%;}} */
            }
            &:nth-last-of-type(1){border-bottom:none;}
            .image {
              background: #f1f1f1f1;
              border-radius:10px;
              display: flex;
              height:40px;
              justify-content: center;
              /* max-width: 140px; */
              width: 100%;
            }
            .thumbnail {
              img{width:100px;}
            }
            .count {
              align-items: center; 
              display: flex; 
              gap: 8px; 
              justify-content: center; 
              text-align: center;
              input {
                text-align: right;
                width:40px; 
              }
              button{color:var(--gray-500);}
            }
            p {
              text-align:center;
              width:100%; 
              &:nth-last-of-type(1){font-weight:bold;}
            }
            button{
              background:none;
              /* width:50%; */
            }
          }
        }
      }
      .form {
        box-sizing: border-box;
        margin: auto;
        max-width: 1200px;
        padding-bottom: 32px;
        width: 100%;
        h3 {
          background-color: var(--Bg);
          padding-bottom: 10px;
          margin: 0px auto 20px auto;
          position: sticky;
          top: 0px;
        }
        &-input {
          display:grid;
          gap:16px;
          li {
            align-items: center; 
            background:#fff; 
            border-radius: 8px;
            display: flex; 
            justify-content: center; 
            label{
              padding-left:16px;
              text-align:left;
              width:160px;
            }
            input{
              border-radius:8px;
              margin:0;
              padding:16px 24px;
              width:100%;
            }
            textarea {
              border:none;
              border-radius:8px;
              height:180px;
              margin:16px 0;
              padding:24px;
              resize:none;
              width:100%;
              &:nth-of-type(1){
                height:60px;
                margin:0;
                padding:16px 24px;
              }
            }
          }
        }
        .file {
          background: #fff;
          border-radius: 10px;
          margin-top:32px;
          padding: 16px;
          label{
            align-items: center;
            background: none;
            border: none;
            color:#898989;
            display: flex;
            flex-direction: row;
            gap:20px; 
            height: inherit;
            justify-content: center; 
            max-width: inherit;
            padding: 8px;
            width: 100%; 
          }
          ol {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            justify-content: flex-start;
          }
          img {
            cursor: pointer;
            height: auto;
            max-height: 100px;
            max-width: 100px;
            width: auto;
            &:hover{opacity:.8;}
          }
        }
      }
      .submit {
        background-color: var(--gray-800);
        border-radius:10px;
        color:var(--white); 
        font-size:1.25rem;
        height:60px; 
        margin: auto;
        max-width: 1200px;
        width: 100%;
      }
    }
    // 내정보 페이지
    // /mall/mypage/info
    &-info {
      box-sizing: border-box;
      display: grid;
      gap: 16px;
      grid-template-rows: 45px calc(100% - 61px);
      height: 100%;
      margin: auto;
      max-width: 95%;
      overflow: hidden;
      padding-block: 60px;
      position: relative;
      width: inherit;
      .mypage-info {
        &-area {
          height: 100%;
          margin: auto;
          max-width: 1200px;
          overflow-x: hidden;
          overflow-y: auto;
          width: 100%;
          &-subnav {
            display: flex;
            gap: 24px;
            justify-content: center;
            margin: 24px 180px;
            button{
              background-color:var(--white);
              border-radius: 8px; 
              height:40px; 
              width: 200px; 
              &:hover{
                background:none;
                color:var(--color-1);
                transition:.1;
              }
            }
          }
          .form {
            display:grid;
            gap:16px;
            margin:60px 0;
            li {
              align-items: center; 
              background:#fff; 
              border-radius: 8px;
              display: flex; 
              justify-content: center; 
              p{
                padding-left:40px;
                width:140px;
              }
              label{
                padding-left:16px;
                text-align:left;
                width:100px;
              }
              input{
                border-radius:8px;
                margin:0;
                padding:16px 24px;
                width:100%;
              }
              textarea {
                border:none;
                border-radius:8px;
                height:180px;
                margin:16px 0;
                padding:24px;
                resize:none;
                width:100%;
                &:nth-of-type(1){
                  height:60px;
                  margin:0;
                  padding:16px 24px;
                }
              }
            }
            .file {
              background: #fff;
              border-radius: 10px;
              margin-top:32px;
              padding: 16px;
              &:hover{opacity:.8;}
              label{
                align-items: center;
                background: none;
                border: none;
                color:#898989;
                display: flex;
                flex-direction: row;
                gap:20px; 
                height: inherit;
                justify-content: center; 
                max-width: inherit;
                padding: 8px;
                width: 100%; 
              }
            }
          }
          .pwchange {
            input{
              border-radius:8px;
              margin:0;
              padding:16px 24px;
              width:100%;
            }
            li{
              p{width:220px;}
            }
            &>p{
              color:var(--gray-500);
              padding:0 16px; 
              span{color:#000;}
            }
          }
          .submit {
            background-color: var(--gray-800);
            border-radius:10px;
            color:var(--white); 
            font-size:1.25rem;
            height:60px; 
            /* margin-bottom:150px; */
            /* margin-top:24px; */
            width:100%;
          }
        }
      }
    }
  }
  .qna-modal {
    background-color: var(--white);
    display: grid;
    height: 80%;
    /* left: 0px; */
    margin: auto;
    /* overflow: hidden; */
    position: fixed;
    /* top: 0px; */
    width: 95%;
    z-index: 999;
    .closeBtn {
      background: #ddd;
      border-radius: 8px;
      color: #333;
      cursor: pointer;
      font-size: 1rem;
      font-weight: bold;
      height: auto;
      padding: 8px 16px;
      position: absolute;
      top:   40px;
      right: 40px;
      width: auto;
      z-index: 9999;
    }
    .qna-sub {
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 8px;
      cursor: auto;
      display: grid;
      grid-template-rows: 40px 40px calc(100% - 250px) 50px;
      gap: 40px;
      height: 100%;
      left: 0;
      overflow: hidden;
      padding: 40px;
      position: absolute;
      top: 0;
      width: 100%;
      button {height: 40px;}
      p {
        text-align: left;
        width: 100%;
      }
      &-title {
        display: flex;
        gap: 10px;
        margin-right: 100px;
        h2 {
          color: var(--color-2);
          width: 100%;
        }
        p {
          color: #ccc;
          line-height: 200%;
          width: fit-content;
        }
      }
      &-list {
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        font-size: 1rem;
        gap: 8px;
        width: 100%;
        li{
          align-items: center;
          border: none;
          border: 1px solid #ddd;
          border-radius: 8px;
          cursor: auto;
          flex-direction: column;
          justify-content: flex-start;
          padding: 0%;
          padding: 8px;
          text-align: left;
          &:nth-of-type(1){
            font-weight: inherit;
            margin: inherit; 
            position: relative;
          }
          &:hover {color: var(--MainRed);}
          p{
            cursor: auto;
            display: flex;
            justify-content: space-between;
            text-align: left;
            width: 100%;
          }
        }
      }
      .qna-sub-list-product {
        flex-wrap: wrap;
        li {width: auto;}
        p.amount {
          align-items: center;
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          &>p:last-child {
            align-items: center;
            display: flex;
            gap: 0px;
            justify-content: flex-end;
            input {
              background-color: var(--Bg);
              border: none;
              font-weight: 600;
              height: fit-content;
              text-align: left;
              width: 100px;
            }
            button {
              width: 40px;
              &:hover {
                background: unset;
                opacity: 1;
              }
            }
          }
        }
      }
      &-info {
        border-radius: 10px;
        box-sizing: border-box;
        display: grid;
        font-size: 1rem;
        gap: 10px;
        height: 100%;
        padding-bottom: 24px;
        overflow-x: hidden;
        overflow-y: scroll;
        width: 100%;
        p {
          display: flex; 
          gap: 24px;
          width: 100%; 
          word-break: keep-all;
          span {height:fit-content;}
          input, textarea {
            border: 1px solid #ddd;
            border-radius: 4px;
            height: 122px;
            padding: 8px;
            resize: none;
            width: 100%;
          }
          input {height: fit-content}
        }
        label, div {
          display: flex; 
          gap: 16px;
          .list-file {
            border: 1px solid #ddd;
            border-radius: 2px;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            justify-content: space-around;
            padding: 8px 16px;
            width: 100%;
          }
        }
        &-content {
          flex-direction: column;
          width: 100%;
          &>div {
            display: flex;
            flex-direction: column;
            max-width: inherit;
            .btn {
              align-items: center;
              background: unset;
              background-color: var(--MainNavy);
              border: unset;
              border-radius: 16px;
              display: flex;
              color: var(--white);
              cursor: pointer;
              font-size: 15px;
              font-weight: 600;
              height: 40px;
              justify-content: center;
            }
          }
          &>p{
            display: grid;
            grid-template-columns: 70px auto;
            /* &:nth-of-type(2){width: 100%;} */
          }
        }
      }
      .qna-sub-btn {
        align-items: center;
        display: flex;
        gap: 10px;
        justify-content: center;
      }
      &-submit {
        background: var(--MainNavy);
        border-radius: 10px;
        color: var(--white);
        font-size: 1.25rem;
        padding: 24px;
        width: 100%;
      }
      .qnaBtn {background: #888;}
    }
  }

  .swiper {
    height: 480px;
    overflow: hidden;
    width: inherit;
  }
  .swiper-slide {
    background: #fff;
    font-size: 18px;
    text-align: center;

    /* Center slide text vertically */
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .swiper-slide img {
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .mainpage{
    gap: 40px;
    &>h3{display:none;}
  }
  .swiper-button-next:after, .swiper-button-prev:after {
    color: var(--gray-300);
    font-size: 3rem;
  }
  .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    background-color: white;
    height: 14px;
    margin: 0 10px;
    width: 14px;
  }
  .swiper-button-next, .swiper-rtl .swiper-button-prev {
    left: auto;
    right: var(--swiper-navigation-sides-offset,40px);
  }
  .swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset,40px);
    right: auto;
  }
`;

// Sign 페이지 --------------------------------------
export const Sign = styled.main`
  width: 100%;
  h3 {
    font-size:1.5rem; 
    margin: 40px 0;
    text-align: center;
  }
  header {
    align-items: center; 
    background-color: var(--gray-800);
    display: flex; 
    height: 60px; 
    justify-content: space-between;
    padding: 0 40px; 
    width: 100%; 
    a{color:var(--white);}
  }
  footer {
    margin:16px 0;
    text-align:center;
    width: 100%;
  }
  .sign {
    align-items: center; 
    display: grid; 
    justify-content: center; 
    padding:10% 0;
    &>p{
      color:#898989;
      margin:8px;
    }
    fieldset {
      display:grid;
      gap:18px;
      input{
        border:1px solid #ddd;
        border-radius:8px;
        margin:0;
        padding:16px 24px;
        width:450px;
      }
    }
    .submit {
      background-color: var(--gray-800);
      border-radius:10px;
      color:var(--white); 
      font-size:1.25rem;
      height:60px; 
      margin-top:24px;
      width:100%;
    }
    .signitem {
      display:flex;
      gap:16px;
      justify-content: space-between;
      margin-top:24px; 
      button{color:var(--gray-800);}
    }
    .googleBtn {
      background-color: var(--white); 
      border:1px solid #ddd;
      color: var(--gray-800);
    }
  }
  .signup {
    padding:4% 0;
    fieldset{
      &>p{
        color:#898989;
        margin:8px;
        span{color:#000;}
      }
    }
  }
`;

export const EmailInput = styled.input`
  &[data-status='false'] {
    border-color: var(--MainRed);
    &:focus {border-color: var(--MainRed);}
  }
`;
export const PasswordInput = styled.input`
  margin-bottom: 10px;
  &[data-status='false'] {
    border-color: var(--MainRed);
    &:focus {border-color: var(--MainRed);}
  }
`;


// main
export const BodySection = styled.main`
  height: 100%;
  overflow: hidden;
  width: 100%;
`;
export const PageSection = styled.div`
  background-color: var(--white);
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 60px calc(100% - 60px);
  height: calc(100% - 60px);
  overflow: hidden;
  width: 100%;
  &.productList {grid-template-rows: 100%;}
`;
export const HeaderSection = styled.div`
  border-bottom: 1px solid var(--gray-800);
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  height: 100%;
  width: 100%;
  & h3 {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: flex-start;
    padding: 0px 20px;
    width: 100%;
  }
`;
export const ContentSection = styled.div`
  height: 100%;
  overflow: hidden;
  width: 100%;
`;


// cart
export const Section = styled.div`
  background-color: var(--white);
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  height: 100%;
  width: 100%;
  .pagination li.active {background-color: var(--gray-800);}
`;
export const MainContent = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1200px;
  grid-template-rows: 100%;
  height: 100%;
  justify-content: center;
  margin: 0px auto;
  overflow: hidden;
  width: 100%;
  &[data-cart='order'] {grid-template-columns: 40% 60%;}
  &[data-cart='qna'] {grid-template-columns: 40% 60%;}
`;
export const CartSection = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: calc(100% - 210px) 60px 150px;
  height: 100%;
  width: 100%;
`;
export const MallCartList = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(auto-fill, min(200px));
  height: 100%;
  overflow: auto;
  row-gap: 10px;
  width: 100%;
  &::-webkit-scrollbar {height: 12px;width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-800); border: 2px solid var(--gray-100); border-radius: 6px;}
`;
export const MallCart2 = styled.div`
  border-bottom: 1px solid var(--gray-800);
  box-sizing: border-box;
  cursor: pointer;
  display: grid;
  grid-template-columns: 50px 200px calc(100% - 350px) 100px;
  height: 200px;
  overflow: hidden;
  padding: 20px;
  width: 100%;
  &[data-status='open'] {
    background-color: var(--Bg);
    opacity: 60%;
  }
`;
export const CheckSection = styled.div`
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 20px 0px;
  width: 100%;
  & input[type='checkbox'] {
    height: 20px;
    width: 20px;
  }
`;
export const LeftSection = styled.div`
  align-items: center;
  background-color: var(--Bg);
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  & img {
    height: 100%;
    width: fit-content;
  }
`;
export const Thumbnail = styled.div`
  background-color: var(--Bg);
  height: 100%;
  width: 100%;
`;
export const CenterSection = styled.div`
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 10px 20px;
  width: 100%;
`;
export const TitleBox = styled.div`
  align-items: flex-start;
  display: flex;
  height: fit-content;
  justify-content: space-between;
  width: 100%;
`;
export const InfoBox = styled.div`
  align-items: flex-start;
  color: var(--gray-600);
  display: flex;
  flex-direction: column;
  font-size: 0.8em;
  font-weight: 300;
  height: fit-content;
  justify-content: flex-start;
  align-self: flex-end;
  overflow: hidden;
  width: 100%;
  & p {
    height: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: inherit;
  }
`;
export const NumFormBox = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;
  input {
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    height: 40px;
    max-width: 230px;
    padding: 5px 0px 5px 5px;
    width: 60px;
  }
`;
export const NumButtonsBox = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;
`;
export const NumButton = styled.div`
  align-items: center;
  background-color: var(--gray-800);
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  font-size: 1.8em;
  display: flex;
  height: 40px;
  justify-content: center;
  padding-bottom: 5px;
  width: 40px;
`;
export const RightSection = styled.div`
  align-items: flex-start;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;
export const DeleteButton = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 20px;
  justify-content: center;
  transform: scale(1.5);
  width: 20px;
  div {
    background-color: var(--MainRed);
    cursor: pointer;
    height: 15px;
    width: 15px;
  }
`;
export const ConfirmSection = styled.div`
  align-items: flex-end;
  background-color: var(--Bg);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  row-gap: 20px;
  width: 100%;
`;
export const MallCartContent = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;
  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-800); border: 2px solid var(--gray-100); border-radius: 6px;}
`;
export const TotalPriceNum = styled.div`
  align-items: baseline;
  box-sizing: border-box;
  column-gap: 10px;
  display: flex;
  font-size: 1.5em;
  height: 50px;
  padding-right: 30px;
  width: fit-content;
  & span {font-weight: 600;}
`;
export const ButtonsBox = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: fit-content;
  justify-content: center;
  width: 100%;
`;
export const ActButton = styled.div`
  align-items: center;
  background-color: var(--gray-800);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;


// mypage/order/list
export const SearchNav = styled.div`
  align-items: flex-end;
  background-color: var(--gray-100);
  box-sizing: border-box;
  column-gap: 10px;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  padding: 0px 20px 10px 20px;
  width: 100%;
  & input, & select {
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    font-size: 0.7em;
    height: 35px;
    padding: 0px 10px;
  }
  & input {width: 250px;}
  & select {width: 120px;}
`;
export const SearchBar = styled.div`
  align-items: flex-end;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  width: fit-content;
`;
export const SearchButton = styled.div`
  align-items: center;
  background-color: var(--gray-800);
  box-sizing: border-box;
  color: var(--white);
  column-gap: 5px;
  cursor: pointer;
  display: flex;
  font-size: 0.9em;
  height: 35px;
  justify-content: center;
  padding: 0px 10px;
`;
export const ResetButton = styled(SearchButton)`
  background-color: unset;
  color: var(--gray-400);
  padding: 0px 5px;
  &:hover {color: var(--gray-800);}
`;
export const MallOrderList = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(auto-fill, min(200px));
  height: 100%;
  overflow: auto;
  row-gap: 10px;
  width: 100%;
  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-800);border: 2px solid var(--gray-100);border-radius: 6px;}
`;
export const MallOrder = styled.div`
  border-bottom: 1px solid var(--gray-800);
  box-sizing: border-box;
  cursor: pointer;
  display: grid;
  grid-template-columns: 200px calc(100% - 250px) 50px;
  height: 200px;
  overflow: hidden;
  padding: 20px;
  width: 100%;
  &[data-status='open'] {
    background-color: var(--Bg);
    opacity: 60%;
  }
`;
export const TitleBoxOrder = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  & h5 {
    align-items: center;
    background-color: var(--gray-800);
    border-radius: 14px;
    color: var(--white);
    display: flex;
    font-weight: 300;
    height: 28px;
    justify-content: center;
    min-width: 35px;
    padding: 0px 15px;
    zoom: 90%;
  }
`;
export const InfoBoxOrder = styled.div`
  align-items: flex-start;
  color: var(--gray-600);
  display: flex;
  flex-direction: column;
  font-size: 0.8em;
  font-weight: 300;
  height: fit-content;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;
  & p {
    height: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: inherit;
  }
`;
export const ArrowIcon = styled.div`
  background-color: var(--gray-800);
  height: 20px;
  width: 20px;
  mask-image: url(${downArrow}); mask-repeat: no-repeat; mask-size: contain;
  --webkit-mask-image: url(${downArrow}); --webkit-mask-repeat: no-repeat; --webkit-mask-size: contain;
  &[data-status='open'] {transform: rotate(-90deg);  }
`;
export const MallOrderContent = styled.div`
  height: 100%;
  width: 100%;
  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-800); border: 2px solid var(--gray-100); border-radius: 6px;}
`;


// qna, order
export const FileContent = styled.li`
  align-items: center;
  column-gap: 10px;
  display: flex;
  justify-content: flex-start;
  width: fit-content;
  button {
    position: initial; 
    right: initial; 
    top: unset; 
  }
`;