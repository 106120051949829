import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { mallSiteUserActions_setUser } from 'store/modules/actions/mallSite/mallSiteUserActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { userReducer_setUser } from 'store/modules/actions/user/userActions';

import { companyApi } from 'api/apis/companyApi';
import { userApi } from 'api/apis/userApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';

import { MyProfileComponent } from './MyProfile.style';
import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import UpdatePassword from 'pages/user/myProfile/modal/UpdatePassword';

const MyProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userReducer } = useSelector((state) => state);

  const [_onload, setOnload] = useState('unload');

  const [_myProfile, setMyProfile] = useState({ ...userReducer.user });

  const [_companyList, setCompanyList] = useState([]);
  const [_masterCompanyId, setMasterCompanyId] = useState(userReducer.company.companyId || 1);

  const [_pwModalStatus, setPwModalStatus] = useState(false);

  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getMyData(); // 내 정보 불러오기
    if (userReducer.user.role === 'ROLE_MASTER') getCompanyList(); // 회사 리스트 불러오기

    setOnload('loaded');
    return () => { };
  }, []);
  useEffect(() => { }, [_masterCompanyId]);

  const getMyData = async () => { // 내 정보 불러오기
    await userApi.getUserMe().then((response) => {
      if (response === undefined) return;
      console.log('userApi.getUserMe : ', response);
      setMyProfile(() => { return response.data });
    });
  };
  const getCompanyList = async () => { // 회사 리스트 불러오기
    // paging: '', body: {} 빈 값 보내야 함!! >> 없는 경우 에러 뜸
    await companyApi.searchCompanies('', {}).then((response) => {
      if (response === undefined) return;
      setCompanyList(() => { return response.data });
    });
  };

  const handleInputValue = (e) => { // 입력 이벤트
    const { name, value } = e.target;
    setMyProfile((prev) => { return { ...prev, [name]: value }; });
  };
  const makePhoneNumber = (e) => {
    const phoneNumber = e.target.value.toString().replace(/[^0-9]/g, '').replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/g, '$1-$2-$3');
    setMyProfile((prev) => { return { ...prev, phoneNumber: phoneNumber }; });
  };

  const actSave = async (e) => { // 내 정보 저장
    e.preventDefault();
    if (!checkEmptyNull(_myProfile.name, false)) { return alert('이름을 입력해주세요.'); }
    await userApi.updateUser(_myProfile.id, _myProfile).then(response => {
      if (response === undefined) return;
      alert('내 정보를 저장했습니다.');
    })
    if (_myProfile.role === 'ROLE_MASTER') {
      // 내 정보 회사ID랑 _masterCompanyId가 다른 경우
      if (_myProfile.companyId !== _masterCompanyId) updateMasterCompany();
    }
  }
  const updateMasterCompany = async () => { // 어드민 >> 회사 변경
    const findCompany = _companyList.findIndex((company) => company.companyId === Number(_masterCompanyId));
    const masterComapny = _companyList[findCompany];
    const body = { companyId: _masterCompanyId };
    console.log('body : ', body, masterComapny, _myProfile);
    await userApi.updateUserMe(body).then((response) => {
      if (response === undefined) return;
      // 회사 변경한 경우 >> 유저 정보(reducer) 삭제 한 후 로그인 화면으로 보냄!!
      dispatch(userReducer_setUser({}));
      dispatch(mallSiteUserActions_setUser({}));
      navigate('/', { replace: true });
    }).catch((error) => {
      console.log('put/user/selfCompany-error :', error);
    });
  };

  /* ========================================================================= #6 */

  return (
    <Grid2Body contents={
      <>
        <MyProfileComponent>
          <NavBar title={'내 정보'} buttons={<></>} nav={''} />

          <Form
            title={<>내<br />정보</>}
            buttons={<>
              <div className='myprofile-btns'>
                <button className='formButton' onClick={actSave}>저장</button>
                {checkEmptyNull(_myProfile.loginId, false) ? ( // 아이디가 있는 경우
                  <button className='formButton pw'
                    onClick={(e) => {
                      e.preventDefault();
                      setPwModalStatus(() => { return true });
                    }}
                  >비밀번호 변경</button>
                ) : null}
              </div>
            </>}
            forms={
              <div className='myprofile-main'>
                <div className='myprofile-info'>
                  <div className="formBox">
                    <h4>회사</h4><p>{checkEmptyNull(_myProfile?.companyName, '')}</p>
                  </div>

                  <div className="formBox">
                    <h4>팀</h4><p>{checkEmptyNull(_myProfile?.team.teamName, '')}</p>
                  </div>

                  {checkEmptyNull(_myProfile.loginId, false) ? (
                    <div className="formBox">
                      <h4>아이디</h4><p>{checkEmptyNull(_myProfile.loginId, '')}</p>
                    </div>
                  ) : null}

                  <div className="formBox">
                    <h4>이메일</h4><p>{checkEmptyNull(_myProfile.email, '')}</p>
                  </div>
                </div>

                {userReducer.user.role === 'ROLE_MASTER' ? ( // 어드민 >> 회사/권한 변경
                  <div className='myprofile-form'>
                    <div className="formBox master">
                      <h4>* 어드민</h4>
                    </div>

                    <div className="formBox master">
                      <h4>회사</h4>
                      <input
                        type="number"
                        name="companyId"
                        data-required="required"
                        value={_masterCompanyId}
                        onInput={(e) => {
                          setMasterCompanyId(() => { return e.target.value; });
                        }}
                      />
                      <select
                        name="companyId"
                        data-required="required"
                        value={_masterCompanyId}
                        onChange={(e) => {
                          setMasterCompanyId(() => { return e.target.value; });
                        }}
                      >
                        {_companyList.map((company) => (<option key={company.companyId} value={company.companyId}>{company.companyId} - {company.companyName}</option>))}
                      </select>
                    </div>

                    <div className="formBox master">
                      <h4>권한</h4>
                      <select
                        name="role"
                        data-required="required"
                        value={_myProfile.role}
                        onChange={handleInputValue}
                      >
                        <option value="ROLE_GUEST">ROLE_GUEST</option>
                        <option value="ROLE_USER">ROLE_USER</option>
                        <option value="ROLE_ADMIN">ROLE_ADMIN</option>
                        <option value="ROLE_MASTER">ROLE_MASTER</option>
                      </select>
                    </div>
                  </div>
                ) : null}

                <div className='myprofile-form'>
                  <div className="formBox">
                    <h4>이름</h4>
                    <input
                      type="text"
                      name="name"
                      data-required="required"
                      value={checkEmptyNull(_myProfile.name, '')}
                      onInput={handleInputValue}
                    />
                  </div>

                  <div className="formBox">
                    <h4>연락처</h4>
                    <input
                      type="text"
                      name="phoneNumber"
                      data-required="required"
                      placeholder="연락처 - 를 제외하고 입력해주세요. ex) 01012341234"
                      maxLength={14}
                      value={checkEmptyNull(_myProfile.phoneNumber, '')}
                      onInput={makePhoneNumber}
                    />
                  </div>
                </div >
              </div >
            }
          />
        </MyProfileComponent >

        {_pwModalStatus ? ( // 비밀번호 변경 모달
          <UpdatePassword
            open={_pwModalStatus}
            close={() => {
              setPwModalStatus(() => { return false });
            }}
          />
        ) : null}
      </>
    }
    />
  );
};

export default MyProfile;
