import React, { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { CiCirclePlus } from "react-icons/ci";
import { FaTrashCan } from "react-icons/fa6";
import { ItemBox } from 'platform/cluster/pages/modal/FormModal.style';

const ItemList = ({ modalType, data, itemList, setItemList }) => {
  const [_currentType, setCurrentType] = useState('');
  const [_dataKeys, setDataKeys] = useState({
    id: '', list: '', delList: '', itemId: '', proposerList: '', proposerDelList: '',
  });
  const [_itemKeys, setItemKeys] = useState([]);

  useEffect(() => {
    const type = modalType.split('-')[0];
    setCurrentType(() => { return type; });
    setDataKeys(() => {
      const returnData = {};
      returnData.id = 'jointPurchaseId';
      returnData.list = 'jointPurchaseItemList';
      returnData.delList = 'deleteJointPurchaseItemList';
      returnData.itemId = 'jointPurchaseItemId';
      returnData.proposerList = 'jointPurchaseProposerList';
      returnData.proposerDelList = 'deleteJointPurchaseProposerList';
      return returnData;
    });
    setItemKeys(() => {
      let returnData = [
        { key: 'itemName', title: '상품명', inputType: 'text' },
        { key: 'targetNumber', title: '목표 수량', inputType: 'number' },
        { key: 'originalPrice', title: '원가', inputType: 'number' },
        { key: 'discountRate', title: '할인율', inputType: 'number' },
        { key: 'detail', title: '설명', inputType: 'textarea' },
      ];
      return returnData;
    });
  }, [modalType]);
  useEffect(() => { }, [_dataKeys]);

  const calDiscount = (originalPrice, discountRate) => { // 할인 가격 계산
    const rate = BigNumber(checkEmptyNull(discountRate, 0)).dividedBy(100);
    const cal = BigNumber(checkEmptyNull(originalPrice, 0)).multipliedBy(BigNumber(rate));
    const calPrice = BigNumber(checkEmptyNull(originalPrice, 0)).minus(BigNumber(cal));
    const returnPrice = calPrice.toNumber();
    return returnPrice;
  }

  const actAddItem = () => {
    setItemList((prev) => {
      const newItem = {};
      _itemKeys.forEach((itemKey => {
        newItem[itemKey.key] = '';
      }))
      const returnList = [...prev, newItem];
      return returnList;
    })
  };
  const handleItemData = (e, index, item, itemKey) => {
    setItemList((prev) => {
      const returnList = [...prev];
      const spliceData = { ...item, [itemKey.key]: e.target.value };
      returnList.splice(index, 1, spliceData);
      return returnList;
    })
  }

  return (<>
    <div className='list'>
      {!modalType.endsWith('delete') ? (<button onClick={actAddItem}><CiCirclePlus /><p>상품 추가</p></button>) : null}
      <div className='section-form'>
        {checkNullArray(itemList, []).map((item, index) => {
          return (
            <ItemBox key={index + '_item'}>
              <div className='item-header'>
                <button
                  title='상품 삭제'
                  onClick={() => {
                    setItemList((prev) => {
                      const returnList = [...prev];
                      returnList.splice(index, 1);
                      return returnList;
                    })
                  }}
                ><FaTrashCan /></button>
              </div>

              {_itemKeys.map((itemKey, keyIndex) => {
                return (
                  <div key={index + '_form-content_' + keyIndex} className='form-content term'>
                    <h4>{itemKey.title}</h4>
                    {itemKey.inputType === 'number' ? (<>
                      <div>
                        <input
                          type='number'
                          placeholder={`숫자만 입력할 수 있습니다.`}
                          disabled={modalType.endsWith('delete')}
                          pattern="\d*"
                          min="0"
                          value={item[itemKey.key] || ''}
                          onInvalid={(e) => { e.target.setCustomValidity('숫자만 입력할 수 있습니다.'); }}
                          onInput={(e) => {
                            e.target.setCustomValidity('');
                            handleItemData(e, index, item, itemKey);
                          }}
                        />
                        {itemKey.key === 'discountRate' ? (
                          <div className='item-price'>
                            <p className='discountInfo'>
                              <span>할인가</span> {item.discountRate?.toLocaleString()}% <span>{item.originalPrice?.toLocaleString()}원</span>
                            </p>
                            <p className='cal'><span>{calDiscount(item.originalPrice, item.discountRate)?.toLocaleString()}</span>원</p>
                          </div>
                        ) : null}
                      </div>
                    </>) : itemKey.inputType === 'textarea' ? (<>
                      <textarea
                        placeholder={`${itemKey.title}을(를) 입력해주세요.`}
                        disabled={modalType.endsWith('delete')}
                        defaultValue={item[itemKey.key] || ''}
                        onInput={(e) => {
                          handleItemData(e, index, item, itemKey);
                        }}
                      />
                    </>) : (<>
                      <input
                        type='text'
                        placeholder={`${itemKey.title}을(를) 입력해주세요.`}
                        disabled={modalType.endsWith('delete')}
                        value={item[itemKey.key] || ''}
                        onInput={(e) => {
                          handleItemData(e, index, item, itemKey);
                        }}
                      />
                    </>)}
                  </div>
                );
              })}
            </ItemBox>
          );
        })}
      </div>
    </div>
  </>);
};

export default ItemList;