import styled from "styled-components";

export const ProjectManagementIndexComponent = styled.section`
  background-color: var(--white);
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-columns: 300px calc(100% - 300px);
  height: 100%;
  margin: auto;
  max-width: 1280px;
  position: relative;
  width: 100%;
`;