import React from 'react';
import styled from 'styled-components';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import StatusButton from 'components/buttons/StatusButton';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: center;
  max-width: 650px;
  min-width: 200px;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  box-sizing: border-box;
  display: grid;
  gap: 30px 20px;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  height: fit-content;
  padding: 20px;
  width: 100%;

  &>div {
    height: 180px;
  }
`;

const GSCLStatusModal = (props) => {
  const { workOrderLogId, workOrderStatus, statusEvent } = props;

  const style_wait = { fontSize: '30px' };
  const style_work = { fontSize: '30px' };
  const style_pause = { fontSize: '30px' };
  const style_stop = { fontSize: '30px' };
  const style_intermediate = { cursor: 'pointer', fontSize: '30px' };
  const style_end = { cursor: 'pointer', fontSize: '30px' };
  const style_cancel = { backgroundColor: 'var(--white)', color: 'var(--MainRed)', cursor: 'pointer', fontSize: '30px', outline: '8px solid var(--MainRed)', outlineOffset: '-12px' };

  const _wait = () => {
    return (
      <>
        <StatusButton workOrderCode={workOrderLogId} status={'work'} modal={true} statusEvent={statusEvent} statusStyle={style_work} />
        <StatusButton workOrderCode={workOrderLogId} status={'pause'} statusEvent={statusEvent} statusStyle={style_pause} />
        <StatusButton workOrderCode={workOrderLogId} status={'stop'} statusEvent={statusEvent} statusStyle={style_stop} />
        <StatusButton workOrderCode={workOrderLogId} status={'intermediate'} statusEvent={statusEvent} statusStyle={style_intermediate} />
        <StatusButton workOrderCode={workOrderLogId} status={'end'} statusEvent={statusEvent} statusStyle={style_end} />
        <StatusButton workOrderCode={workOrderLogId} status={'cancel'} statusEvent={statusEvent} statusStyle={style_cancel} />
      </>
    );
  };
  const _work = () => {
    return (
      <>
        <StatusButton workOrderCode={workOrderLogId} status={'wait'} statusEvent={statusEvent} statusStyle={style_wait} />
        <StatusButton workOrderCode={workOrderLogId} status={'pause'} statusEvent={statusEvent} statusStyle={style_pause} />
        <StatusButton workOrderCode={workOrderLogId} status={'stop'} statusEvent={statusEvent} statusStyle={style_stop} />
        <StatusButton workOrderCode={workOrderLogId} status={'intermediate'} statusEvent={statusEvent} statusStyle={style_intermediate} />
        <StatusButton workOrderCode={workOrderLogId} status={'end'} statusEvent={statusEvent} statusStyle={style_end} />
        <StatusButton workOrderCode={workOrderLogId} status={'cancel'} statusEvent={statusEvent} statusStyle={style_cancel} />
      </>
    );
  };
  const _pause = () => {
    return (
      <>
        <StatusButton workOrderCode={workOrderLogId} status={'wait'} statusEvent={statusEvent} statusStyle={style_wait} />
        <StatusButton workOrderCode={workOrderLogId} status={'work'} modal={true} statusEvent={statusEvent} statusStyle={style_work} />
        <StatusButton workOrderCode={workOrderLogId} status={'stop'} statusEvent={statusEvent} statusStyle={style_stop} />
        <StatusButton workOrderCode={workOrderLogId} status={'intermediate'} statusEvent={statusEvent} statusStyle={style_intermediate} />
        <StatusButton workOrderCode={workOrderLogId} status={'end'} statusEvent={statusEvent} statusStyle={style_end} />
        <StatusButton workOrderCode={workOrderLogId} status={'cancel'} statusEvent={statusEvent} statusStyle={style_cancel} />
      </>
    );
  };
  const _stop = () => {
    return (
      <>
        <StatusButton workOrderCode={workOrderLogId} status={'wait'} statusEvent={statusEvent} statusStyle={style_wait} />
        <StatusButton workOrderCode={workOrderLogId} status={'work'} modal={true} statusEvent={statusEvent} statusStyle={style_work} />
        <StatusButton workOrderCode={workOrderLogId} status={'pause'} statusEvent={statusEvent} statusStyle={style_pause} />
        <StatusButton workOrderCode={workOrderLogId} status={'intermediate'} statusEvent={statusEvent} statusStyle={style_intermediate} />
        <StatusButton workOrderCode={workOrderLogId} status={'end'} statusEvent={statusEvent} statusStyle={style_end} />
        <StatusButton workOrderCode={workOrderLogId} status={'cancel'} statusEvent={statusEvent} statusStyle={style_cancel} />
      </>
    );
  };
  const _end = () => {
    return (
      <>
        <StatusButton workOrderCode={workOrderLogId} status={'wait'} statusEvent={statusEvent} statusStyle={style_wait} />
        <StatusButton workOrderCode={workOrderLogId} status={'work'} modal={true} statusEvent={statusEvent} statusStyle={style_work} />
        <StatusButton workOrderCode={workOrderLogId} status={'pause'} statusEvent={statusEvent} statusStyle={style_pause} />
        <StatusButton workOrderCode={workOrderLogId} status={'stop'} statusEvent={statusEvent} statusStyle={style_stop} />
        <StatusButton workOrderCode={workOrderLogId} status={'intermediate'} statusEvent={statusEvent} statusStyle={style_intermediate} />
        <StatusButton workOrderCode={workOrderLogId} status={'cancel'} statusEvent={statusEvent} statusStyle={style_cancel} />
      </>
    );
  };
  const _cancel = () => {
    return (
      <>
        <StatusButton workOrderCode={workOrderLogId} status={'wait'} statusEvent={statusEvent} statusStyle={style_wait} />
        <StatusButton workOrderCode={workOrderLogId} status={'work'} modal={true} statusEvent={statusEvent} statusStyle={style_work} />
        <StatusButton workOrderCode={workOrderLogId} status={'pause'} statusEvent={statusEvent} statusStyle={style_pause} />
        <StatusButton workOrderCode={workOrderLogId} status={'stop'} statusEvent={statusEvent} statusStyle={style_stop} />
        <StatusButton workOrderCode={workOrderLogId} status={'intermediate'} statusEvent={statusEvent} statusStyle={style_intermediate} />
        <StatusButton workOrderCode={workOrderLogId} status={'end'} statusEvent={statusEvent} statusStyle={style_end} />
      </>
    );
  };
  const _all = () => {
    return (
      <>
        <StatusButton workOrderCode={workOrderLogId} status={'wait'} statusEvent={statusEvent} statusStyle={style_wait} />
        <StatusButton workOrderCode={workOrderLogId} status={'work'} modal={true} statusEvent={statusEvent} statusStyle={style_work} />
        <StatusButton workOrderCode={workOrderLogId} status={'pause'} statusEvent={statusEvent} statusStyle={style_pause} />
        <StatusButton workOrderCode={workOrderLogId} status={'stop'} statusEvent={statusEvent} statusStyle={style_stop} />
        <StatusButton workOrderCode={workOrderLogId} status={'intermediate'} statusEvent={statusEvent} statusStyle={style_intermediate} />
        <StatusButton workOrderCode={workOrderLogId} status={'end'} statusEvent={statusEvent} statusStyle={style_end} />
        <StatusButton workOrderCode={workOrderLogId} status={'cancel'} statusEvent={statusEvent} statusStyle={style_cancel} />
      </>
    );
  };

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection status={workOrderStatus}>
            <ModalHeader>
              <ModalTitle>작업상태 설정</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              {workOrderStatus === 'wait' ? _wait()
                : workOrderStatus === 'work' ? _work()
                  : workOrderStatus === 'pause' ? _pause()
                    : workOrderStatus === 'stop' ? _stop()
                      : workOrderStatus === 'end' ? _end()
                        : workOrderStatus === 'cancel' ? _cancel()
                          : _all()}
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default GSCLStatusModal;
