import React, { Fragment } from 'react';

const GuideText = () => {
  return (
    <Fragment>
      <br />
      <span
        style={{
          display: 'block',
          fontSize: '0.7em',
        }}
      >
        {'[ 클릭 ]'}
      </span>
    </Fragment>
  );
};

export default GuideText;
