import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import styled from 'styled-components';
import bwipjs from 'bwip-js'
import { saveAs } from 'file-saver'
import { checkEmptyNull, checkNullObject } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  height: 85%;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: space-between;
  padding: 0px 60px 0px 20px;
  position: relative;
  width: 100%;
  box-shadow: 0 4px 18px 0 rgba(196,196,196,0.8);
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
  }
`;

const MainContents = styled.div`
  box-sizing: border-box;
  height: 100%;
  justify-content: center;
  padding: 10px;
  width: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 4px;
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const ModalButton = styled.div`
  width: 20%;
  border-radius: 8px;
  height: fit-content;
  padding: 8px;
  box-shadow: 0 2px 6px 0 rgba(196,196,196,0.8);
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingScreen = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  color: var(--white);
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

/* ========================= PrintContent ========================= */
const ExportSectionOne = styled.div`
  border: 1px solid var(--gray-500);
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 70%;
  grid-template-rows: auto 40px;
  justify-content: center;
  margin: 30px auto;
  overflow: hidden;
  padding: 30px;
  position: relative;
  row-gap: 5px;
  text-align: center;

  height: 297mm;
  width: 210mm;

  /* height: 40mm; */
  /* width: 60mm; */

  table {
    max-width: 100%;
  }

  .print {
    input {
      vertical-align: middle;
    }
  }

  .tg {
    border-collapse:collapse;
    border-spacing:0;
  }
  .tg td{
    border-color:black;
    border-style:solid;
    border-width:1px;
    font-family:Arial, sans-serif;
    font-size:14px;
    overflow:hidden;
    padding:10px 5px;
    word-break:normal;
  }
  .tg th{
    border-color:black;
    border-style:solid;
    border-width:1px;
    font-family:Arial, sans-serif;
    font-size:14px;
    font-weight:normal;
    overflow:hidden;
    padding:10px 5px;
    word-break:normal;
  }
  .tg .tg-coru{
    background-color:#ffffff;
    border-color:black;
    text-align:center;
    vertical-align: middle;
  }
  .tg .tg-0lax{
    text-align:center;
    vertical-align:middle;
  }

  .for-heigth-wide {
    td {
      height: 80px;
    }
  }

`;

const ExportSectionTwo = styled.div`
  border: 1px solid var(--gray-100);
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 98%;
  grid-template-rows: 60px auto 40px;
  justify-content: center;
  margin: 30px auto;
  padding: 30px;
  overflow: hidden;
  position: relative;
  row-gap: 5px;

  /* height: 297mm; */
  /* width: 210mm; */
  height: 40mm;
  width: 60mm;

  table {
    height: fit-content;
  }

  .print {
    input {
      vertical-align: middle;
    }
  }
  .tg {
    border-collapse:collapse;
    border-spacing:0;
  }
  .tg td{
    border-color:black;
    border-style:solid;
    border-width:1px;
    font-family:Arial, sans-serif;
    font-size:14px;
    overflow:hidden;
    padding:10px 5px;
    word-break:normal;
  }
  .tg th{
    border-color:black;
    border-style:solid;
    border-width:1px;
    font-family:Arial, sans-serif;
    font-size:14px;
    font-weight:normal;
    overflow:hidden;
    padding:10px 5px;
    word-break:normal;
  }
  .tg .tg-9wq8{
    border-color:inherit;
    text-align:center;
    vertical-align:middle
  }

`;

const PrintContent = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector(state => state);
  /* ====================================================================== #2 */
  const { content, currentPage, printContent, shipmentInspection, totalPage } = props;

  /* ====================================================================== #3 */
  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  /* ====================================================================== #6 */

  return (
    <>
      <ExportSectionTwo name={'printContent'}>
        <div style={{
          alignItems: 'flex-end',
          boxSizing: 'border-box',
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: '10px',
          width: '100%',
        }}>
          <h5>{shipmentInspection.shipmentInspectionCode}</h5>
          <h6>{`${currentPage} / ${totalPage}`}</h6>
        </div>

        <h2 style={{ textAlign: 'center' }}>{userReducer.company.companyName}</h2>
      </ExportSectionTwo>
    </>
  );
};

const generateBarcode = (thisItem) => {
  //setCreateBarcode(true);
  const canvas = document.createElement('canvas')
  bwipjs.toCanvas(canvas, {
    bcid: 'code128', 
    scale: 2, 
    height: 8, 
    includetext: true, 
    textxalign: 'center', 
    backgroundcolor: 'FFFFFF', 
    padding: 8,
    paddingright: 20,
    // paddingleft: 20,
    text: `A_${thisItem.elementId}`,
  })
  return canvas.toDataURL('image/png')
}

// useEffect(() => (
//   //handleCreateBarcode()
// ))

// const [barcodeInfo, setBarcodeInfo] = useState(false);
// const BarcodeInfo = () => {
  // setBarcodeInfo(true);
// }
export default function BarcodeOutElementModal(props, thisItem) {
  /* ========================================================================= #1 */
  const { userReducer } = useSelector(state => state);

  /* ========================================================================= #2 */
  const { shipmentInspection } = props;

  const [_printContents, setPrintContents] = useState(() => {
    const shipmentInspectionItemConnectorList = shipmentInspection.shipmentInspectionItemConnectorList;
    const returnList = [];
    if (checkNullObject(shipmentInspectionItemConnectorList, false)) {
      for (let i = 0; i < shipmentInspectionItemConnectorList.length; i += 12) {
        const sliceArray = shipmentInspectionItemConnectorList.slice(i, i + 12);
        const returnArray = sliceArray.map((inspection) => {
          return inspection;
        });
        returnList.push(returnArray);
      }
      return returnList;
    } else {
      return [];
    }
  });

  const [_loadingStatus, setLoadingStatus] = useState(false);

  /* ========================================================================= #3 */
  /* ========================================================================= #4 */
  /* ========================================================================= #5 */
  const acrPrintContent = () => {
    const style = document.createElement('style');
    document.head.appendChild(style);
    style.sheet?.insertRule('body > div:last-child img { display: inline-block; }');

    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
    });

    document.getElementsByName('printContent').forEach(async (thisItem, index) => {
      const thisHeight = thisItem.offsetHeight;
      const thisWidth = thisItem.offsetWidth;
      const ratio = thisWidth / thisHeight;

      const option = {
        height: thisHeight,
        width: thisWidth,
      };

      await html2canvas(thisItem, option).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');

        let width = doc.internal.pageSize.getWidth();
        let height = 280;
        width = ratio * height;

        const margin = 6;
        const position = 6;

        doc.addImage(imgData.replace('data:image/png;base64,', ''), 'PNG', margin, position, width, height);
        if (index < document.getElementsByName('printContent').length - 1) {
          doc.addPage();
        }
      });

      if (index === document.getElementsByName('printContent').length - 1) {
        if (document.getElementsByName('printContent').length > 10) {
          style.remove();
          doc.save();
          setLoadingStatus(false);
        } else {
          style.remove();
          window.open(doc.output('bloburl'));
          setLoadingStatus(false);
        }
      }
    });
  };

  /* ========================================================================= #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>바코드 출력 미리보기</ModalTitle>
              <ModalButton
                onClick={() => {
                  setTimeout(acrPrintContent, 1000);
                }}
              >
                PDF 출력
              </ModalButton>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>
            <ModalMain>
              <MainContents id="PrintSection">
                <ExportSectionOne name={'printContent'}>
                <div className='barcodeImage'>
                  <div>
                    <img src={generateBarcode(thisItem)} />
                    <ul>
                      <li>1. 재고상세이력 ID : <b>B_{thisItem.elementId}</b></li>
                      <li>2. 물품코드 : {thisItem.elementCode}</li>
                      <li>3. 물품이름 : {thisItem.elementName}</li>
                      {/* <li>3.단가 : {checkEmptyNull(thisItem.price, 0).toLocaleString()}</li> */}
                    </ul>
                  </div>
                </div>
                </ExportSectionOne>

                {_printContents.map((content, index) => {
                  return (
                    <PrintContent
                      key={index + '_printContents'}
                      totalPage={_printContents.length}
                      currentPage={index + 1}
                      content={content}
                      shipmentInspection={shipmentInspection}
                      printContent={_printContents}
                    />
                  )
                })}
              </MainContents>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}
      ;
      {_loadingStatus === true ? (
        <LoadingScreen>파일 생성 중입니다...</LoadingScreen>
      ) : null}
    </>
  );
};