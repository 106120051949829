import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { daerimReducer_setFailedProcess } from 'store/modules/actions/company/daerimActions';
import { workActions_setCurrentMessage } from 'store/modules/actions/default/workActions';

import { getCookie } from 'preferences/cookie/cookie';
import { WS_CLIENT } from 'preferences/server/constants';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

import { DateFormat } from 'components/format/DateFormat';
import { zoomMinus, zoomPlus } from 'components/icons/src';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import IntermediateSaveModal from 'pages/working/process/status/IntermediateSaveModal';
import ProcessEndModal from 'pages/working/process/status/ProcessEndModal';
import ProcessMultiStatusModal from './modal/ProcessMultiStatusModal';
import SelectWorkerModal from 'pages/user/worker/modal/SelectWorkerModal';
import ShowFileList from 'pages/file/modal/ShowFileList';
import TableSection from 'components/layouts/table/TableSection';
import WorkerConfirmModal from 'pages/working/modal/WorkerConfirmModal';
import WorkerScreenProcessRow from 'components/row/WorkerScreenProcessRow';
import WorkerProcessStatusModal from 'pages/working/modal/WorkerProcessStatusModal';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px calc(100% - 55px);
  height: 85%;
  overflow: hidden;
  width: 95%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalHeaderButtons = styled.div`
  align-items: center;
  gap: 10px;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 55px;

  & .btn-set {
    border: 1px solid var(--gray-300);
    box-shadow: unset;
    font-size: 15px;
    height: 35px;
    padding-inline: 20px;
    width: fit-content;
  }
`;
const ModalHeaderButton = styled.button``;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
    td, th {min-width: 150px;}
    td.amountCell, th.amountCell {min-width: 100px;}
  }
`;

const ViewerButtons = styled.div`
  z-index: 999;
  position: fixed;
  right: 76px;
  bottom: 24px;
  column-gap: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ViewMinus = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  border-radius: 50%;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 50px;
  font-weight: 600;
  justify-content: center;
  height: 50px;
  width: 50px;
`;
const ViewMinusIcon = styled.div`
  background-color: var(--white);
  height: 30px;
  width: 30px;
  mask-image: url(${zoomMinus}); mask-repeat: no-repeat; mask-size: contain;
  --webkit-mask-image: url(${zoomMinus}); --webkit-mask-repeat: no-repeat; --webkit-mask-size: contain;
`;
const ViewPlus = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  border-radius: 50%;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 50px;
  font-weight: 600;
  justify-content: center;
  height: 50px;
  width: 50px;
`;
const ViewPlusIcon = styled.div`
  background-color: var(--white);
  height: 30px;
  width: 30px;

  mask-image: url(${zoomPlus}); mask-repeat: no-repeat; mask-size: contain;
  --webkit-mask-image: url(${zoomPlus}); --webkit-mask-repeat: no-repeat; --webkit-mask-size: contain;
`;

const WorkerScreenModal = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { userReducer, workReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const RoomId = userReducer.company.companyId;

  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const sortProcessList = [...props.processList.processLogList];
  sortProcessList.sort((a, b) => {
    return a.processNumber - b.processNumber;
  });
  const [_processList, setProcessList] = useState(sortProcessList);

  const processData = { ...props.processList };
  const [_formData, setFormData] = useState({
    workOrderTypeName: processData.workOrderTypeName,

    workOrderStatus: processData.workOrderStatus,

    workOrderLogId: processData.workOrderLogId,
    workOrderCode: processData.workOrderCode,
    workOrderName: processData.workOrderName,

    workPlace: processData.workPlace,
    detailedWorkPlace: processData.detailedWorkPlace,

    message: processData.message,
    remark: processData.remark,

    workOrderCustomizedContent: processData.workOrderCustomizedContent,

    companyId: userReducer.company.companyId,
    accountId: processData.accountId,
    userId: userReducer.user.id,

    workOrderScheduledStartDate: DateFormat(checkEmptyNull(processData.workOrderScheduledStartDate, '')),
    workOrderScheduledEndDate: DateFormat(checkEmptyNull(processData.workOrderScheduledEndDate, '')),

    amount: processData.amount,

    processLogList: [],
    deleteProcessList: [],

    managers: [],
    workers: [],
  });

  const [_viewScale, setViewScale] = useState(100);

  const [_eventProcessLog, setEventProcessLog] = useState({});
  const [_workOrderStatusModal, setWorkOrderStatusModal] = useState(false); // 작업상태 변경 모달
  const [_processEndModal, setProcessEndModal] = useState(false); // 공정 완료 모달
  const [_intermediateModal, setIntermediateModal] = useState(false); // 부분 저장 모달
  const [_multiStatusModalStatus, setMultiStatusModalStatus] = useState(false); // 일괄 공정상태 변경 모달
  const [_confirmModalStatus, setConfirmModalStatus] = useState(false); // 책임자/작업자 변경 선택 모달
  const [_workerModalStatus, setWorkerModalStatus] = useState(false); // 책임자/작업자 선택 모달
  const [_fileListModalStatus, setFileListModalStatus] = useState(false); // 파일 모달

  const [_endBody, setEndBody] = useState({}); // 공정 완료

  const [_viewStatus, setViewStatus] = useState('simple');

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('109') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    getWorkOrderLog();

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      if (workReducer.connectStatus === 'connect') {
        if (!checkNullObject(workReducer.currentMessage, false)) return;

        if (workReducer.currentMessage.userId === userReducer.user.id && checkNull(workReducer.currentMessage.errorMessage, false)) {
          dispatch(workActions_setCurrentMessage({}));
          return;
        }

        if (workReducer.currentMessage.workOrderLogId === processData.workOrderLogId) {
          if (workReducer.currentMessage.type === '/work') applyStatus();
          if (workReducer.currentMessage.type === 'process') applyProcessStatus();
          if (workReducer.currentMessage.type === 'processes') applyProcessesStatus();
        }
      }
    }

    return () => { };
  }, [workReducer.currentMessage]);

  /* ====================================================================== #4 */
  const getWorkOrderLog = async () => {
    await workOrderLogApi.getWorkOrderLog(props.workOrderLogId).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.getWorkOrderLog : ', response);
      const Processes = response.data.processLogList;
      Processes.sort((a, b) => { return a.processNumber - b.processNumber; });
      setProcessList(() => { return Processes; });
    });
  };

  /* 웹소켓 */
  const applyStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);
    setFormData(prev => { return { ...prev, workOrderStatus: applyContent.workOrderStatus } })
  };
  const applyProcessStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventProcessLogList = [..._processList];
    const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === applyContent.processLog.processLogId);
    if (eventProcessLogIndex === -1) return;

    const eventContent = eventProcessLogList[eventProcessLogIndex];
    eventContent.processStatus = applyContent.processLog.processStatus;
    eventContent.completedAmount = applyContent.processLog.completedAmount;
    eventContent.processAmount = applyContent.processLog.processAmount;
    eventContent.processingCount = applyContent.processLog.processingCount;
    eventContent.managers = checkNullArray(applyContent.managers, []);
    eventContent.workers = checkNullArray(applyContent.workers, []);
    if (checkNullArray(eventContent.processLogElementList, false)) {
      const eventResultElements = eventContent.processLogElementList.map((thisItem) => {
        const thisFindIndex = applyContent.processResultElements.findIndex((thisElement) => thisElement.processLogElementId === thisItem.processLogElementId);
        if (thisFindIndex === -1) return thisItem;
        const thisFindItem = applyContent.processResultElements[thisFindIndex];
        const returnData = { ...thisItem, ...thisFindItem };
        return returnData;
      });
      eventContent.processLogElementList = eventResultElements;
    }
    eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
    setProcessList((prev) => { return eventProcessLogList; });
    setFormData((prev) => { return { ...prev, processLogList: eventProcessLogList }; });
  };
  const applyProcessesStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventProcessLogList = [..._processList];
    if (checkNullArray(applyContent.socketProcessResults, false)) {
      applyContent.socketProcessResults.forEach((processResult) => {
        const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === processResult.processLog.processLogId);
        if (eventProcessLogIndex === -1) return;

        const eventContent = eventProcessLogList[eventProcessLogIndex];
        eventContent.processStatus = processResult.processLog.processStatus;
        eventContent.completedAmount = processResult.processLog.completedAmount;
        eventContent.processAmount = processResult.processLog.processAmount;
        eventContent.processingCount = processResult.processLog.processingCount;
        eventContent.managers = checkNullArray(processResult.managers, []);
        eventContent.workers = checkNullArray(processResult.workers, []);
        if (checkNullArray(eventContent.processLogElementList, [])) {
          const eventResultElements = eventContent.processLogElementList.map((thisItem) => {
            const thisFindIndex = processResult.processResultElements.findIndex((thisElement) => thisElement.processLogElementId === thisItem.processLogElementId);
            if (thisFindIndex === -1) return thisItem;
            const thisFindItem = processResult.processResultElements[thisFindIndex];
            const returnData = { ...thisItem, ...thisFindItem };
            return returnData;
          });
          eventContent.processLogElementList = eventResultElements;
        }
        eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
      });
    } else {
      applyContent.processLogIds.forEach((processLogIds) => {
        const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === processLogIds);
        if (eventProcessLogIndex === -1) return;
        const eventContent = eventProcessLogList[eventProcessLogIndex];
        eventContent.processStatus = applyContent.processStatus;
        eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
      });
    }
    setProcessList((prev) => { return eventProcessLogList; });
    setFormData((prev) => { return { ...prev, processLogList: eventProcessLogList }; });
  };

  /* ====================================================================== #5 */
  /* 공정 상태 변경 팝업 */
  const setProcessStatus = (process) => {
    const { processLogId, processStatus } = process;
    console.log('setProcessStatus : ', processLogId, processStatus);

    if (!checkEmptyNull(processLogId, false) || !checkEmptyNull(processStatus, false)) return;
    if (workReducer.connectStatus !== 'connect') return alert(`
연결이 불안정합니다.
네트워크 상태 확인 후 다시 시도하여 주시기 바랍니다.
    `);
    if (processStatus === 'end' || processStatus === 'cancel') return;
    if (_formData.workOrderStatus === 'end') return;
    if (_formData.workOrderStatus !== 'work') return alert('작업지시가 작업 중이 아닙니다.');

    setEventProcessLog(() => { return process });
    setTimeout(setWorkOrderStatusModal(() => { return true }), 1000);
  };

  /* 공정 상태 변경 이벤트 */
  const handleProcessStatus = async (processLog, processStatus) => {
    console.log('check : ', processLog, processStatus);
    if (!checkNullObject(processLog, false) || !checkEmptyNull(processStatus, false)) return;

    if (processStatus === 'end') {
      const _putElementList = checkNullArray(processLog.processLogElementList, []).filter((thisType) => thisType.processLogElementType === 'In' || thisType.processLogElementType === 'Put');
      const _makeElementList = checkNullArray(processLog.processLogElementList, []).filter((thisType) => thisType.processLogElementType === 'Out' || thisType.processLogElementType === 'Make');

      if (_putElementList.length === 0 && _makeElementList.length === 0) { // 공정 투입/완료 물품이 없을 때
        if (workReducer.connectStatus !== 'connect') return alert(`
연결이 불안정합니다.
네트워크 상태 확인 후 다시 시도하여 주시기 바랍니다.
        `);

        const processResultElements_put = _putElementList.map((thisItem) => {
          const returnData = {
            processResultElementType: thisItem.processLogElementType,
            processLogElementId: thisItem.processLogElementId,
          };
          return returnData;
        });
        const processResultElements_make = _makeElementList.map((thisItem) => {
          const returnData = {
            processResultElementType: thisItem.processLogElementType,
            processLogElementId: thisItem.processLogElementId,
            processResultElementFailedReasons: [],
          };
          return returnData;
        });

        const BodyToWS = {
          roomId: RoomId,
          type: '/process',
          userId: userReducer.user.id,
          workOrderLogId: _formData.workOrderLogId,
          processLogId: processLog.processLogId,
          processStatus: processStatus,
          processResult: { processResultElements: [...processResultElements_put, ...processResultElements_make] },
        };
        console.log('client.publish/pub/process - body : ', BodyToWS, JSON.stringify(BodyToWS));
        if (processStatus === 'end' && workReducer.setWorker) {// 완료 + 작업자/책임자 변경 시
          setEndBody(() => { return { processLog: processLog, BodyToWS: BodyToWS } });
          setTimeout(setConfirmModalStatus(true), 1000);
        } else {
          try {
            WS_CLIENT.publish({
              destination: '/pub/process',
              headers: { 'auth-token': getCookie('accessToken')?.replace('Bearer ', '') },
              body: JSON.stringify(BodyToWS),
            });
          } catch (error) {
            console.log('client.publish/pub/process - error : ', error);
          }
          setWorkOrderStatusModal(false);
        }
      } else {
        dispatch(daerimReducer_setFailedProcess([]));
        setTimeout(setProcessEndModal(true), 1000);
      }
      return;
    } else if (processStatus === 'intermediate') {
      const _putElementList = checkNullArray(processLog.processLogElementList, []).filter((thisType) => thisType.processLogElementType === 'In' || thisType.processLogElementType === 'Put');
      const _makeElementList = checkNullArray(processLog.processLogElementList, []).filter((thisType) => thisType.processLogElementType === 'Out' || thisType.processLogElementType === 'Make');

      dispatch(daerimReducer_setFailedProcess([]));
      setTimeout(setIntermediateModal(true), 1000);
      // if (_putElementList.length !== 0 && _makeElementList.length !== 0) {
      // } else {
      //   return alert('부분 저장할 투입 자재/반제품 및 공정완료제품이 없습니다.')
      // }
    } else {
      if (workReducer.connectStatus !== 'connect') return alert(`
연결이 불안정합니다.
네트워크 상태 확인 후 다시 시도하여 주시기 바랍니다.
      `);
      const BodyToWS = {
        roomId: RoomId,
        type: '/process',
        userId: userReducer.user.id,
        workOrderLogId: _formData.workOrderLogId,
        processLogId: processLog.processLogId,
        processStatus: processStatus,
      };
      console.log('client.publish/pub/process - body : ', BodyToWS, JSON.stringify(BodyToWS));

      try {
        WS_CLIENT.publish({
          destination: '/pub/process',
          headers: { 'auth-token': getCookie('accessToken')?.replace('Bearer ', '') },
          body: JSON.stringify(BodyToWS),
        });
      } catch (error) {
        console.log('client.publish/pub/process - error : ', error);
      }
      setWorkOrderStatusModal(false);
    }
  };

  /* 책임자/작업자 변경 선택 */
  const actWorkEnd = (type) => {
    if (type === 'workerEvent') {
      // 책임자/작업자 변경 O
      setTimeout(setWorkerModalStatus(true), 1000);
    } else {
      // 책임자/작업자 변경 X
      if (window.confirm('공정을 완료합니다.')) {
        try {
          WS_CLIENT.publish({
            destination: '/pub/process',
            headers: { 'auth-token': getCookie('accessToken')?.replace('Bearer ', '') },
            body: JSON.stringify({
              ..._endBody.BodyToWS,
              processStatus: 'end',
              managerIds: _endBody.processLog.managers.map((worker) => worker.managerId),
              workerIds: _endBody.processLog.workers.map((worker) => worker.workerId),
            }),
          });
        } catch (error) {
          console.log('client.publish/pub/process - error : ', error);
        }
        setWorkOrderStatusModal(false);
      } else {
        return;
      }
    }
  };

  /* 취소 */
  const cancelStatusEvent = async (processLog, processStatus) => {
    if (_formData.workOrderStatus === 'end') return;
    if (_formData.workOrderStatus !== 'work') return alert('작업지시가 작업 중이 아닙니다.');
    if (processLog === undefined) return;
    if (workReducer.connectStatus !== 'connect') return alert(`
연결이 불안정합니다.
네트워크 상태 확인 후 다시 시도하여 주시기 바랍니다.
    `);

    const BodyToWS = {
      roomId: RoomId,
      type: '/process',
      userId: userReducer.user.id,
      workOrderLogId: _formData.workOrderLogId,
      processLogId: processLog.processLogId,
      processStatus: processStatus,
    };
    console.log('client.publish/pub/process - body : ', BodyToWS, JSON.stringify(BodyToWS));

    if (window.confirm('공정을 취소합니다.')) {
      try {
        WS_CLIENT.publish({
          destination: '/pub/process',
          headers: { 'auth-token': getCookie('accessToken')?.replace('Bearer ', '') },
          body: JSON.stringify({
            ...BodyToWS,
            processStatus: 'cancel',
            managerIds: processLog.managers.map((worker) => worker.managerId),
            workerIds: processLog.workers.map((worker) => worker.workerId),
          }),
        });
      } catch (error) {
        console.log('client.publish/pub/process - error : ', error);
      }
      setWorkOrderStatusModal(false);
    } else {
      return;
    }
  };

  /* 강제 완료 */
  const forceEndStatusEvent = async (processLog, processStatus) => {
    if (_formData.workOrderStatus === 'end') return;
    if (_formData.workOrderStatus !== 'work') return alert('작업지시가 작업 중이 아닙니다.');
    if (processLog === undefined) return;
    if (workReducer.connectStatus !== 'connect') return alert(`
연결이 불안정합니다.
네트워크 상태 확인 후 다시 시도하여 주시기 바랍니다.
    `);

    const BodyToWS = {
      roomId: RoomId,
      type: '/process',
      userId: userReducer.user.id,
      workOrderLogId: _formData.workOrderLogId,
      processLogId: processLog.processLogId,
      processStatus: processStatus,
    };
    console.log('client.publish/pub/process - body : ', BodyToWS, JSON.stringify(BodyToWS));

    if (window.confirm('공정을 강제완료합니다.')) {
      try {
        WS_CLIENT.publish({
          destination: '/pub/process',
          headers: { 'auth-token': getCookie('accessToken')?.replace('Bearer ', '') },
          body: JSON.stringify({
            ...BodyToWS,
            processStatus: 'end',
            managerIds: processLog.managers.map((worker) => worker.managerId),
            workerIds: processLog.workers.map((worker) => worker.workerId),
          }),
        });
      } catch (error) {
        console.log('client.publish/pub/process - error : ', error);
      }
      setWorkOrderStatusModal(false);
    } else {
      return;
    }
  };

  const showFileList = (content, work) => {
    setEventProcessLog(() => {
      if (work === true) return { ...content, work: true };
      else return content;
    });
    setTimeout(setFileListModalStatus(true), 1000);
  };

  /* ====================================================================== #6 */
  const actViewMinus = () => {
    const prevScale = _viewScale;
    let setScale = parseFloat(prevScale - 5);
    if (setScale < 10) setScale = 10;
    setViewScale(setScale);
  };
  const actViewPlus = () => {
    const prevScale = _viewScale;
    let setScale = parseFloat(prevScale + 5);
    if (setScale > 125) setScale = 125;
    setViewScale(setScale);
  };

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>공정 리스트</ModalTitle>
              <ModalHeaderButtons>
                <button className="btn-set"
                  onClick={(e) => {
                    e.preventDefault();
                    setViewStatus((prev) => { return prev === 'simple' ? 'detail' : 'simple' });
                  }}
                >
                  {_viewStatus === 'simple' ? '공정 상세보기' : '공정 요약보기'}
                </button>
                {(_formData.workOrderStatus !== 'end' && _formData.workOrderStatus !== 'cancel') ? (
                  <ModalHeaderButton className="formButton" onClick={() => { setMultiStatusModalStatus(true); }}>일괄 상태 변경</ModalHeaderButton>
                ) : null}
              </ModalHeaderButtons>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain style={{ zoom: `${_viewScale}%` }}>
              <TableSection content={
                <table>
                  <thead>
                    <tr style={{ border: 'none' }}>
                      <th rowSpan={2} style={{ minWidth: '150px', width: '150px' }}></th>
                      <th rowSpan={2} style={{ minWidth: '60px', padding: 'unset', width: '60px' }}>순서</th>
                      {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? (<th rowSpan={2} style={{ minWidth: '60px', padding: 'unset', width: '60px' }}>파일</th>) : null}
                      {_viewStatus === 'detail' ? (
                        <>
                          <th rowSpan={2}>공정유형</th>
                          <th rowSpan={2}>공정코드</th>
                          <th rowSpan={2}>공정이름</th>
                          <th rowSpan={2}>작업<br />시작일</th>
                          <th rowSpan={2}>작업위치</th>
                          <th rowSpan={2}>비고</th>
                        </>
                      ) : (<th rowSpan={2}>공정정보</th>)}
                      {/* <th colSpan={2} style={{ backgroundColor: 'var(--MainBlue)' }}>진행상태</th> */}
                      <th colSpan={_viewStatus === 'detail' ? 7 : 5} style={{ backgroundColor: 'var(--ThirdBlue)' }}>공정완료제품</th>
                      <th colSpan={_viewStatus === 'detail' ? 5 : 2} style={{ backgroundColor: 'var(--SeconYellow)' }}>투입 자재/반제품</th>
                      {userReducer.user.team.teamRoleList.findIndex((teamRole) => teamRole.authority.startsWith('108')) !== -1 ? (<th rowSpan={2}>설비</th>) : null}
                      {_viewStatus === 'detail' ? (
                        <>
                          <th rowSpan={2}>책임자</th>
                          <th rowSpan={2}>작업자</th>
                        </>
                      ) : null}
                      <th rowSpan={2} style={{ minWidth: '60px', width: '60px' }}></th>
                      <th rowSpan={2} style={{ minWidth: '60px', width: '60px' }}></th>
                    </tr>
                    <tr>
                      {/* <th>클릭</th>
                      <th>진행도</th> */}
                      {_viewStatus === 'detail' ? (
                        <>
                          <th>물품이름</th>
                          <th>물품코드</th>
                          <th className="amountCell">목표<br />생산량</th>
                          <th className="amountCell">현재고</th>
                          <th className="amountCell" style={{ backgroundColor: 'var(--MainBlue)' }}>총<br />생산량</th>
                          <th className="amountCell" style={{ backgroundColor: 'var(--MainGreen)' }}>성공<br />수량</th>
                          <th className="amountCell" style={{ backgroundColor: 'var(--MainRed)' }}>실패<br />수량</th>
                        </>
                      ) : (
                        <>
                          <th>물품정보</th>
                          <th className="amountCell">목표<br />생산량</th>
                          <th className="amountCell" style={{ backgroundColor: 'var(--MainBlue)' }}>총<br />생산량</th>
                          <th className="amountCell" style={{ backgroundColor: 'var(--MainGreen)' }}>성공<br />수량</th>
                          <th className="amountCell" style={{ backgroundColor: 'var(--MainRed)' }}>실패<br />수량</th>
                        </>
                      )}
                      {_viewStatus === 'detail' ? (
                        <>
                          <th>물품이름</th>
                          <th>물품코드</th>
                          <th className="amountCell">단위<br />투입량</th>
                          <th className="amountCell">총<br />투입량</th>
                          <th className="amountCell">현재고</th>
                          {/* <th style={{ minWidth: '120px', width: '120px' }}></th> */}
                        </>
                      ) : (
                        <>
                          <th>물품정보</th>
                          <th className="amountCell">총<br />투입량</th>
                          {/* <th style={{ minWidth: '120px', width: '120px' }}></th> */}
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {checkNullArray(_processList, []).map((processLog, index) => {
                      return (
                        <WorkerScreenProcessRow
                          key={index + '_processLog'}
                          workOrderType={_formData.workOrderTypeName}
                          content={processLog}
                          cancelStatusEvent={cancelStatusEvent}
                          forceEndStatusEvent={forceEndStatusEvent}
                          setProcessStatus={setProcessStatus}
                          showFileList={showFileList}
                          viewStatus={_viewStatus}
                        />
                      );
                    })}
                  </tbody>
                </table>
              }
              ></TableSection>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}

      <ViewerButtons>
        <ViewMinus onClick={actViewMinus}><ViewMinusIcon /></ViewMinus>
        <ViewPlus onClick={actViewPlus}><ViewPlusIcon /></ViewPlus>
      </ViewerButtons>

      {/* 모달 */}
      {/* 작업상태 변경 모달 */}
      {_workOrderStatusModal === true ? (
        <WorkerProcessStatusModal
          content={_eventProcessLog}
          statusEvent={(processLog, processStatus) => { handleProcessStatus(processLog, processStatus) }}
          open={_workOrderStatusModal}
          close={() => { setWorkOrderStatusModal(false); }}
        />
      ) : null}
      {/* 일괄 작업상태 변경 모달 */}
      {_multiStatusModalStatus === true ? (
        <ProcessMultiStatusModal
          workOrderLog={processData}
          processList={_processList}
          open={_multiStatusModalStatus}
          close={() => { setMultiStatusModalStatus(false); }}
        />
      ) : null}
      {/* 공정 완료 모달 */}
      {_processEndModal === true && (
        <ProcessEndModal
          workOrderLogId={processData.workOrderLogId}
          processLogId={_eventProcessLog.processLogId}
          processStatus={_eventProcessLog.processStatus}
          statusEvent={handleProcessStatus}
          statusModalCloseEvent={() => { setWorkOrderStatusModal(false); }}
          open={_processEndModal}
          close={() => { setProcessEndModal(false); }}
        />
      )}
      {_intermediateModal === true && (
        <IntermediateSaveModal
          workOrderLogId={processData.workOrderLogId}
          processLogId={_eventProcessLog.processLogId}
          processStatus={_eventProcessLog.processStatus}
          statusEvent={_intermediateModal}
          statusModalCloseEvent={() => { setWorkOrderStatusModal(false); }}
          open={_processEndModal}
          close={() => { setIntermediateModal(false); }}
        />
      )}

      {/* 작업지시 완료 모달 */}
      {/* 책임자/작업자 변경X -> 바로 완료 */}
      {/* 책임자/작업자 변경 선택 모달 */}
      {workReducer.setWorker && _confirmModalStatus === true ? (
        <WorkerConfirmModal
          workerEvent={() => {
            actWorkEnd('workerEvent');
            setTimeout(setConfirmModalStatus(false), 1000);
          }}
          endEvent={() => {
            actWorkEnd('endEvent');
            setTimeout(setConfirmModalStatus(false), 1000);
          }}
          open={_confirmModalStatus}
          close={() => { setConfirmModalStatus(false) }}
        />
      ) : null}

      {/* 책임자/작업자 선택 모달 */}
      {_workerModalStatus === true ? (
        <SelectWorkerModal
          type={'processLog'}
          eventLog={_endBody}
          open={_workerModalStatus}
          close={() => {
            setWorkerModalStatus(false);
            setWorkOrderStatusModal(false);
          }}
        />
      ) : null}

      {/* 파일 모달 */}
      {_fileListModalStatus === true && checkEmptyNull(_eventProcessLog.processLogId, false) ? (
        <ShowFileList
          type={'processLog'}
          content={_eventProcessLog}
          open={_fileListModalStatus}
          close={() => { setFileListModalStatus(false); }}
        ></ShowFileList>
      ) : null}
    </>
  );
};

export default WorkerScreenModal;
