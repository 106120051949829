const initialState = {
  invoiceStatus: 'all',
  startDate: undefined,
  endDate: undefined,
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,

  viewScale: 100,
};
const resetState = {
  invoiceStatus: 'all',
  startDate: undefined,
  endDate: undefined,
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETINVOICESTATUS':
      return {
        ...state,
        invoiceStatus: action.payload,
      };
    case 'SETINVOICESTARTDATE':
      return {
        ...state,
        startDate: action.payload,
      };
    case 'SETINVOICEENDDATE':
      return {
        ...state,
        endDate: action.payload,
      };
    case 'SETINVOICESEARCHOPTION':
      return {
        ...state,
        searchOption: action.payload,
      };
    case 'SETINVOICESEARCHTEXT':
      return {
        ...state,
        searchText: action.payload,
      };
    case 'SETINVOICEPAGENUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'SETINVOICETOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };
    case 'SETINVOICEISPUSHEDSEARCHBUTTON':
      return {
        ...state,
        isPushedSearchButton: action.payload,
      };
    case 'SETINVOICERESET':
      return {
        ...state,
        ...resetState,
      };

    case 'SETINVOICEVIEWSCALE':
      return {
        ...state,
        viewScale: action.payload,
      };

    default:
      return state;
  }
};

export default invoiceReducer;
