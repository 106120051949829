import React from 'react';
import styled from 'styled-components';

import {
  AccessHistoryDownloadModal,
  AccountDownloadModal,
  ContractDownloadModal,
  ElementDownloadModal,
  GsElementDownloadModal,
  MarketContractDownloadModal,
  NaraElementDownloadModal,
  ProcessDownloadModal,
  QualityDownloadModal,
  UserDownloadModal,
} from 'components/excel/download/ExcelDownloadImport';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  max-width: 800px;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseModalButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ExcelDownloadModal = (props) => {
  const { open, close, mainTitle, subTitle } = props;
  return (
    <div className={open ? 'openModal modal' : 'modal'}>
      {open ? (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>엑셀 다운로드</ModalTitle>
              <CloseModalButton className="close" onClick={() => { close() }}><CloseButtonIcon /></CloseModalButton>
            </ModalHeader>

            {(() => {
              switch (mainTitle.toString().toLowerCase()) {
                case 'accesshistory': return <AccessHistoryDownloadModal subTitle={subTitle} close={close}></AccessHistoryDownloadModal>;
                case 'account': return <AccountDownloadModal subTitle={subTitle} close={close}></AccountDownloadModal>;
                case 'contract': return <ContractDownloadModal subTitle={subTitle} close={close}></ContractDownloadModal>;
                case 'marketcontract': return <MarketContractDownloadModal subTitle={subTitle} close={close}></MarketContractDownloadModal>;
                case 'naraproducts': return <NaraElementDownloadModal subTitle={subTitle} close={close} ></NaraElementDownloadModal>;
                case 'gsproducts': return <GsElementDownloadModal subTitle={subTitle} close={close} ></GsElementDownloadModal>;
                case 'products': return <ElementDownloadModal subTitle={subTitle} close={close}></ElementDownloadModal>;
                case 'process': return <ProcessDownloadModal subTitle={subTitle} close={close}></ProcessDownloadModal>;
                case 'quality': return <QualityDownloadModal subTitle={subTitle} close={close}></QualityDownloadModal>;
                case 'user': return <UserDownloadModal subTitle={subTitle} close={close}></UserDownloadModal>;

                default: return;
              }
            })()}
          </ModalSection>
        </ModalBg>
      ) : null}
    </div>
  );
};

export default ExcelDownloadModal;
