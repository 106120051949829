import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { cluster } from 'api/apis/operator/cluster';

import { checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { ClusterComponent } from './Cluster.style';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import LoadingComponent from 'components/loading/LoadingComponent';
import TableSection from 'components/layouts/table/TableSection';

const ClusterManagement = (props) => {
  const { userReducer } = useSelector((state) => state);

  const [_clusterList, setClusterList] = useState([]);
  const [_checkedItem, setCheckedItem] = useState({});

  const [_loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => {
    getClusterList();
    return () => { }
  }, [])
  useEffect(() => { }, [_checkedItem, _loadingStatus]);

  const getClusterList = async () => { // 클러스터 리스트 불러오기
    await cluster.search('', {}).then((response) => {
      if (response === undefined) return;
      setClusterList(() => { return response.data.content });
    })
  }

  const actCreate = async (e) => { // 클러스터 추가
    e.preventDefault();
    const promptText = `클러스터를 입력해주세요.`;
    const clusterName = window.prompt(promptText, '');
    setLoadingStatus(() => { return true }); // 로딩 화면 표시
    await cluster.create(
      { companyId: userReducer.company.companyId, clusterName: clusterName }
    ).then((response) => {
      if (response === undefined) return;
      alert('클러스터를 추가했습니다.');
      getClusterList();
      setLoadingStatus(() => { return false }); // 로딩 화면 숨기기
      props.update();
    })
  };

  const actDelete = async (e) => { // 클러스터 삭제
    e.preventDefault();
    if (!checkNullObject(_checkedItem, false)) return alert('삭제할 클러스터를 선택해주세요.');
    const confirmText = `클러스터를 삭제합니다.`;
    if (window.confirm(confirmText)) {
      await cluster.delete(_checkedItem.clusterId).then((response) => {
        if (response === undefined) return;
        alert('클러스터를 삭제했습니다.');
        getClusterList();
        props.update();
      })
    }
  };

  return (<>
    {props.open === true && (
      <ClusterComponent>
        <section className='management'>
          <header>
            <h4>클러스터 관리</h4>
            <div className='btn-close' onClick={props.close}><CloseButtonIcon /></div>
          </header>

          <div>
            <nav>
              <div></div>
              <div>
                <button className='btn-set' onClick={actCreate}>추가</button>
                <button className='btn-set' onClick={actDelete}>삭제</button>
              </div>
            </nav>

            <TableSection content={
              <table>
                <colgroup>
                  <col width={'50px'} />
                  <col />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th>clusterId</th>
                    <th>클러스터</th>
                  </tr>
                </thead>
                <tbody>
                  {checkNullArray(_clusterList, []).map((cluster, index) => {
                    return (
                      <tr key={cluster.clusterId + '_cluster'}>
                        <td>
                          <input
                            type="radio"
                            name="clusters"
                            onChange={() => { setCheckedItem(() => { return cluster }) }}
                          />
                        </td>
                        <td>{cluster.clusterId}</td>
                        <td>{cluster.clusterName}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            } />
          </div>
        </section>
      </ClusterComponent>
    )}

    {_loadingStatus ? (<LoadingComponent close={props.close} title={'클러스터를 저장하고 있습니다...'} />) : null}
  </>);
};

export default ClusterManagement;
