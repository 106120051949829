import React, { Fragment, useEffect, useState } from 'react';

import { checkNull } from 'components/checkValues/checkValues';

import { DateFormat } from 'components/format/DateFormat';
import CommaNum from 'components/format/CommaNum';
import StatusTdButton from 'components/buttons/StatusTdButton';
import DetailViewButton from 'components/buttons/DetailViewButton';

const ContractRow = (props) => {
  /* ========================================================================= #1 */
  /* ========================================================================= #2 */
  const [contractData, setContractData] = useState(props.content);

  const [checkedItem, setCheckedItem] = useState(props.checkedItem);

  const [contractElements, setContractElements] = useState(
    props.content.contractElementList,
  );

  const [_rowLength, setRowLength] = useState(contractElements.length);

  /* ========================================================================= #3 */
  useEffect(() => {
    setContractData(() => {
      return props.content;
    });

    setCheckedItem(() => {
      return props.checkedItem;
    });

    setContractElements(() => {
      return props.content.contractElementList;
    });

    setRowLength(props.content.contractElementList.length);
  }, [props]);

  /* ========================================================================= #4 */
  /* ========================================================================= #5 */
  /* ========================================================================= #6 */
  const returnElements = () => {
    let returnArray = [];
    for (let i = 1; i < _rowLength; i++) {
      returnArray.push(
        <tr key={i + '_contractElements'}>
          {contractElements[i] !== undefined ? (
            <Fragment>
              <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                {(() => {
                    if (
                      contractElements[i].elementName !== undefined &&
                      contractElements[i].elementName !== null
                    ) {
                      return contractElements[i].elementName;
                    } else if (
                      contractElements[i].element !== undefined &&
                      contractElements[i].element !== null &&
                      contractElements[i].element.elementName !== undefined &&
                      contractElements[i].element.elementName !== null
                    ) {
                      return contractElements[i].element.elementName;
                    } else {
                      return '';
                    }
                  })()}
              <tr/>
                ({(() => {
                      if (
                        contractElements[i].elementCode !== undefined &&
                        contractElements[i].elementCode !== null
                      ) {
                        return contractElements[i].elementCode;
                      } else if (
                        contractElements[i].element !== undefined &&
                        contractElements[i].element !== null &&
                        contractElements[i].element.elementCode !== undefined &&
                        contractElements[i].element.elementCode !== null
                      ) {
                        return contractElements[i].element.elementCode;
                      } else {
                        return '';
                      }
                })()})
              </td>
              <td>
                <CommaNum
                  displayType={'text'}
                  num={contractElements[i].quantity || 0}
                />
              </td>
              <td style={{ borderRight: '1px solid var(--gray-200)' }}>
                <CommaNum
                  displayType={'text'}
                  num={contractElements[i].price || 0}
                />
              </td>
            </Fragment>
          ) : (
            <Fragment>
              <td
                className="blankCell"
                style={{ borderLeft: '1px solid var(--gray-200)' }}
              ></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
              <td
                className="blankCell"
                style={{ borderRight: '1px solid var(--gray-200)' }}
              ></td>
            </Fragment>
          )}
        </tr>,
      );
    }

    return returnArray;
  };

  return (
    <Fragment>
      <tr
        name={props.authority.indexOf('202-2') !== -1 ? 'contractRows' : ''}
      >
        {props.authority.indexOf('201-2') !== -1 ? (
          <td
            rowSpan={_rowLength === 0 ? 1 : _rowLength}
            style={{ minWidth: '50px', width: '50px' }}
          >
            <input
              type="checkBox"
              name="contracts"
              data-key={contractData.contractId}
              checked={(() => {
                const checkedIndex = checkedItem
                  ? Array.from(checkedItem).findIndex(
                    (contract) =>
                      contract.contractId === contractData.contractId,
                  )
                  : -1;

                if (checkedIndex !== -1) {
                  return true;
                } else {
                  return false;
                }
              })()}
              onChange={(e) => {
                const isChecked = e.target.checked;
                props.handleCheckedItem(contractData, isChecked);
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </td>
        ) : null}
        <StatusTdButton
          rowSpan={_rowLength === 0 ? 1 : _rowLength}
          status={contractData.contractStatus}
          statusStyle={{ minWidth: '80px', width: '80px' }}
          statusEvent={(e) => {
            e.stopPropagation();
            props.handleContractStatus(contractData, props.index);
          }}
        />
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ minWidth: '50px', width: '50px', cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            props.goDetail(contractData, props.index);
          }}
        >
          <DetailViewButton/>
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {contractData.contractTypeName}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {contractData.contractCode}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {contractData.contractName}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {(() => {
            if (
              contractData.account !== undefined &&
              contractData.account !== null
            ) {
              return contractData.account.accountName;
            } else {
              return contractData.accountName;
            }
          })()}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {contractData.address}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {checkNull(contractData.contractDate, false) ? DateFormat(contractData.contractDate) : ''}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {checkNull(contractData.scheduledEndDate, false) ? DateFormat(contractData.scheduledEndDate) : ''}
        </td>
        {contractElements[0] !== undefined ? (
          <Fragment>
            <td>
              {(() => {
                if (
                  contractElements[0].elementName !== undefined &&
                  contractElements[0].elementName !== null
                ) {
                  return contractElements[0].elementName;
                } else if (
                  contractElements[0].element !== undefined &&
                  contractElements[0].element !== null &&
                  contractElements[0].element.elementName !== undefined &&
                  contractElements[0].element.elementName !== null
                ) {
                  return contractElements[0].element.elementName;
                } else {
                  return '';
                }
              })()}
              <tr/>(
              {(() => {
                if (
                  contractElements[0].elementCode !== undefined &&
                  contractElements[0].elementCode !== null
                ) {
                  return contractElements[0].elementCode;
                } else if (
                  contractElements[0].element !== undefined &&
                  contractElements[0].element !== null &&
                  contractElements[0].element.elementCode !== undefined &&
                  contractElements[0].element.elementCode !== null
                ) {
                  return contractElements[0].element.elementCode;
                } else {
                  return '';
                }
              })()})
            </td>
            <td>
              <CommaNum
                displayType={'text'}
                num={contractElements[0].quantity || 0}
              />
            </td>
            <td>
              <CommaNum
                displayType={'text'}
                num={contractElements[0].price || 0}
              />
            </td>
          </Fragment>
        ) : (
          <Fragment>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
          </Fragment>
        )}
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          <CommaNum displayType={'text'} num={contractData.contractPrice} />
        </td>
      </tr>
      {_rowLength > 1 && returnElements()}
    </Fragment>
  );
};

export default ContractRow;
