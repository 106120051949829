import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import Form from 'components/layouts/form/Form';
import SelectOneAccount from 'pages/account/modal/SelectOneAccount';
import SelectUsers from 'pages/user/modal/SelectUsers';

const InfoFormSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .formBox textarea[name="remark"] {
    border: 3px solid var(--ThirdBlue);
    color: var(--ThirdBlue);
    font-weight: 600;
    &:hover, &:focus {border-color: var(--ThirdBlue); outline-color: var(--ThirdBlue);}
    &::placeholder{color: var(--ThirdBlue);}
  }
`;

const InfoForm = (props) => {
  const [_processLogList, setProcessLogList] = useState(props.processLogList);
  const [_formData, setFormData] = useState(props.formData);

  const [_userModalStatus, setUserModalStatus] = useState(false); // 작성자선택 팝업
  const [_accountModalStatus, setAccountModalStatus] = useState(false); // 거래처선택 팝업

  useEffect(() => {
    setProcessLogList(() => { return props.processLogList });
    setFormData(() => { return props.formData });
  }, [props]);

  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    props.setFormData(prev => {
      const returnData = { ...prev, [name]: value };
      return returnData;
    })
  }
  const handleCustomData = (e) => {
    const { name, value } = e.target;
    props.setFormData(prev => {
      const returnData = {
        ...prev,
        workOrderCustomizedContent: { ...prev?.workOrderCustomizedContent, [name]: value }
      };
      return returnData;
    })
  }
  const handleCustomDataValue = (name, value) => {
    props.setFormData(prev => {
      const returnData = {
        ...prev,
        workOrderCustomizedContent: { ...prev?.workOrderCustomizedContent, [name]: value }
      };
      return returnData;
    })
  }

  const selectUser = () => {
    setTimeout(setUserModalStatus(() => { return true }), 1000);
  }
  const selectAccount = () => {
    setTimeout(setAccountModalStatus(() => { return true }), 1000);
  }

  return (
    <>
      <InfoFormSection>
        <Form
          title={<>작업<br />정보</>}
          buttons={<></>}
          forms={
            <>
              <div className="formBox">
                <h4>지시일자</h4>
                <input
                  type="date"
                  name="workOrderScheduledStartDate"
                  value={DateFormat(checkEmptyNull(_formData.workOrderScheduledStartDate, ''))}
                  onChange={handleInputEvent}
                />
              </div>

              <div className="formBox">
                <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                  <h4>작성자</h4>
                  <div className="formButton"
                    style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                    onClick={(e) => { e.preventDefault(); selectUser(); }}
                  >
                    작성자 선택
                  </div>
                </div>
                <input
                  type="text"
                  name="accountName"
                  style={{ backgroundColor: 'var(--ThirdBlue)', color: 'var(--white)' }}
                  placeholder="* 작성자..."
                  readOnly
                  value={_formData?.user?.name}
                />
              </div>

              <div className="formBox">
                <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                  <h4>거래처</h4>
                  <div
                    className="formButton"
                    style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                    onClick={(e) => { e.preventDefault(); selectAccount(); }}
                  >
                    거래처 선택
                  </div>
                </div>
                <input
                  type="text"
                  name="accountName"
                  style={{ backgroundColor: 'var(--ThirdBlue)', color: 'var(--white)' }}
                  placeholder="* 거래처..."
                  readOnly
                  value={_formData?.account?.accountName}
                />
              </div>

              <div className="formBox">
                <h4>주소</h4>
                <input
                  type="text"
                  name="workPlace"
                  placeholder="주소..."
                  defaultValue={checkEmptyNull(_formData?.workPlace, '')}
                  onChange={handleInputEvent}
                />
              </div>

              <div className="formBox">
                <h4>전화번호</h4>
                <input
                  type="text"
                  name="phoneNumber"
                  placeholder="전화번호..."
                  defaultValue={_formData?.workOrderCustomizedContent?.phoneNumber}
                  onChange={handleCustomData}
                />
              </div>

              <div className="formBox">
                <h4>색상</h4>
                <input
                  type="text"
                  name="colors"
                  placeholder="색상..."
                  defaultValue={_formData?.workOrderCustomizedContent?.colors}
                  onChange={handleCustomData}
                />
              </div>

              <div className="formBox">
                <h4>B/F 납기</h4>
                <div style={{ display: 'flex', gap: '5px' }}>
                  <input
                    type="date"
                    value={DateFormat(checkEmptyNull(_formData?.workOrderCustomizedContent?.BF?.date, ''))}
                    onChange={(e) => {
                      e.preventDefault();
                      const value = {
                        date: DateFormat(e.target.value),
                        amount: checkEmptyNull(_formData?.workOrderCustomizedContent?.BF?.amount, ''),
                      }
                      handleCustomDataValue('BF', value);
                    }}
                  />
                  <input
                    type="text"
                    placeholder="B/F 수량..."
                    value={checkEmptyNull(_formData?.workOrderCustomizedContent?.BF?.amount, '')}
                    onChange={(e) => {
                      e.preventDefault();
                      const value = {
                        date: checkEmptyNull(_formData?.workOrderCustomizedContent?.BF?.date, ''),
                        amount: e.target.value,
                      }
                      handleCustomDataValue('BF', value);
                    }}
                  />
                </div>
              </div>

              <div className="formBox">
                <h4>S/F 납기</h4>
                <div style={{ display: 'flex', gap: '5px' }}>
                  <input
                    type="date"
                    value={DateFormat(checkEmptyNull(_formData?.workOrderCustomizedContent?.SF?.date, ''))}
                    onChange={(e) => {
                      e.preventDefault();
                      const value = {
                        date: DateFormat(e.target.value),
                        amount: checkEmptyNull(_formData?.workOrderCustomizedContent?.SF?.amount, ''),
                      }
                      handleCustomDataValue('SF', value);
                    }}
                  />
                  <input
                    type="text"
                    placeholder="S/F 수량..."
                    value={checkEmptyNull(_formData?.workOrderCustomizedContent?.SF?.amount, '')}
                    onChange={(e) => {
                      e.preventDefault();
                      const value = {
                        date: checkEmptyNull(_formData?.workOrderCustomizedContent?.SF?.date, ''),
                        amount: e.target.value,
                      }
                      handleCustomDataValue('SF', value);
                    }}
                  />
                </div>
              </div>

              <div className="formBox">
                <h4>M/F 납기</h4>
                <div style={{ display: 'flex', gap: '5px' }}>
                  <input
                    type="date"
                    value={DateFormat(checkEmptyNull(_formData?.workOrderCustomizedContent?.MF?.date, ''))}
                    onChange={(e) => {
                      e.preventDefault();
                      const value = {
                        date: DateFormat(e.target.value),
                        amount: checkEmptyNull(_formData?.workOrderCustomizedContent?.MF?.amount, ''),
                      }
                      handleCustomDataValue('MF', value);
                    }}
                  />
                  <input
                    type="text"
                    placeholder="M/F 수량..."
                    value={checkEmptyNull(_formData?.workOrderCustomizedContent?.MF?.amount, '')}
                    onChange={(e) => {
                      e.preventDefault();
                      const value = {
                        date: checkEmptyNull(_formData?.workOrderCustomizedContent?.MF?.date, ''),
                        amount: e.target.value,
                      }
                      handleCustomDataValue('MF', value);
                    }}
                  />
                </div>
              </div>

              <div className="formBox" style={{ width: '100%' }}>
                <h4>비고</h4>
                <textarea
                  name="remark"
                  placeholder="비고..."
                  defaultValue={checkEmptyNull(_formData?.remark, '')}
                  onChange={handleInputEvent}
                ></textarea>
              </div>
            </>
          }
        />
      </InfoFormSection>

      {/* 작성자 선택 */}
      {_userModalStatus === true ? (
        <SelectUsers
          buttonTitle={'작성자 선택'}
          buttonEvent={(data) => {
            props.setFormData(prev => {
              const returnData = {
                ...prev,
                userId: data?.id,
                user: { ...data }
              };
              return returnData;
            })
            setTimeout(setUserModalStatus(false), 1000);
          }}
          open={_userModalStatus}
          close={() => { setUserModalStatus(false) }}
        />
      ) : null}

      {/* 거래처 선택 */}
      {_accountModalStatus === true ? (
        <SelectOneAccount
          buttonTitle={'거래처 선택'}
          buttonEvent={(data) => {
            props.setFormData(prev => {
              let phoneNumber = data?.phoneNumber;
              if (checkNullArray(data.accountAttributeValueList, false)) {
                const findItem = data.accountAttributeValueList.filter(item => item.accountAttributeName === '연락처' || item.accountAttributeName === '전화번호')[0];
                if (checkNullObject(findItem, false)) phoneNumber = findItem.value;
              }
              const returnData = {
                ...prev,
                accountId: data?.accountId,
                account: { ...data },
                workOrderCustomizedContent: { ...prev.workOrderCustomizedContent, phoneNumber: phoneNumber }
              };
              return returnData;
            })
            setTimeout(setAccountModalStatus(false), 1000);
          }}
          open={_accountModalStatus}
          close={() => { setAccountModalStatus(false) }}
        />
      ) : null}
    </>
  );
};

export default InfoForm;