import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { gsReducer_setWorkOrderContent } from 'store/modules/actions/company/GSActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { productionPerformanceActions_setEndDate, productionPerformanceActions_setPageNumber, productionPerformanceActions_setReset, productionPerformanceActions_setSearchData, productionPerformanceActions_setStartDate, productionPerformanceActions_setTotalSize, productionPerformanceActions_setWorkOrderStatus } from 'store/modules/actions/common/productionPerformanceActions';
import { workActions_setCurrentMessage } from 'store/modules/actions/default/workActions';

import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import { LoadingMsg } from 'components/loading/LoadingMsg';
import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';

import GSBLStatus from 'pages/working/custom/company/gs/BL/status/GSBLStatus';
import GSCPStatus from 'pages/working/custom/company/gs/CP/status/GSCPStatus';
import GSCLStatus from 'pages/working/custom/company/gs/CL/status/GSCLStatus';

const Section = styled.main`
  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
    & .statusButtons {display: flex; gap: 5px;}
  }
`;

const ListButton = styled.div`
  align-items: center;
  background-color: ${(props) => {
    switch(props.type) {
      case 'BL': return 'var(--MainBlue)';
      case 'CP': return 'var(--ThirdBlue)';
      case 'CL': return 'var(--MainNavy)';
      default: return null;
    }
  }};
  border: 4px solid var(--white);
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 600;
  gap: 6px;
  height: 100%;
  justify-content: center;
  min-height: 100px;
  width: 100%;

  & p {font-size: 0.8em;}
`;

const ProcessCell = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 100%;
  width: 100%;
`;
const ProcessSection = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 60px repeat(auto-fit, minmax(50px, 1fr));
  grid-template-rows: repeat(auto-fit, 40px);
  height: 100%;
  width: 100%;
`;
const Process = styled.div`
  align-items: center;
  background-color: ${(props) => {
    switch (props.status) {
      case 'wait': return 'var(--gray-200)'
      case 'work': return 'var(--WorkingColor)'
      case 'end': return 'var(--ThirdBlue)'
      case 'stop': return 'var(--MainRed)'
      case 'pause': return 'var(--MainYellow)'
      case 'cancel': return 'var(--Bg)'

      case 'NO': return 'var(--MainNavy)';
      case 'cut': return '#BCE55C'
      case 'welding': return '#9FC93C'
      case 'last': return '#689900'

      default: return 'unset';
    }
  }};
  box-sizing: border-box;
  color: ${(props) => {
    return props.status.toLowerCase() === 'cancel' ? 'var(--gray-200)' : 'var(--white)';
  }};
  cursor: default;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  justify-content: center;
  width: 100%;
`;

const ConnectStatus = styled.div`
  background-color: ${(props) => {
    return props.connectStatus === 'connect' ? 'var(--MainGreen)' : 'var(--MainRed)';
  }};
  border-radius: 50%;
  right: 76px;
  bottom: 26px;
  position: fixed;
  height: 40px;
  width: 40px;
  z-index: 1000;
`;

const GSProductionPerformance = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productionPerformanceReducer, userReducer, workReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_workOrderLogList, setWorkOrderLogList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState([]);

  const [_modalStatus_BL, setModalStatus_BL] = useState(false);
  const [_modalStatus_CP, setModalStatus_CP] = useState(false);
  const [_modalStatus_CL, setModalStatus_CL] = useState(false);
  const [_selectedWorkOrder, setSelectedWorkOrder] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('407') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getWorkOrderLogList(productionPerformanceReducer.pageNumber - 1, 'all', productionPerformanceReducer.workOrderStatus)
    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getWorkOrderLogList(productionPerformanceReducer.pageNumber - 1, 'all', productionPerformanceReducer.workOrderStatus)
    }

    return () => { };
  }, [productionPerformanceReducer.pageNumber]);

  useEffect(() => {
    if (workReducer.connectStatus === 'connect') {
      if (!checkNullObject(workReducer.currentMessage, false)) return;

      if (workReducer.currentMessage.userId === userReducer.user.id && checkEmptyNull(workReducer.currentMessage.errorMessage, false)) {
        return dispatch(workActions_setCurrentMessage({}));;
      }

      if (workReducer.currentMessage.type === '/work') applyStatus();
      if (workReducer.currentMessage.type === 'process') applyProcessStatus();
      if (workReducer.currentMessage.type === 'processes') applyProcessesStatus();
    }

    return () => { };
  }, [workReducer.currentMessage]);

  useEffect(() => { }, [workReducer.connectStatus])

  /* ====================================================================== #4 */
  const getWorkOrderLogList = async (page, workOrderType, workOrderStatus, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    if (checkEmptyNull(workOrderType, false) && workOrderType !== 'all') {
      BodyToPost.workOrderTypeId = Number(workOrderType);
    }
    if (checkEmptyNull(workOrderStatus, false)) {
      switch (workOrderStatus) {
        case 'all': BodyToPost.workOrderStatus = ['end', 'cancel']; break;
        default: BodyToPost.workOrderStatus = [workOrderStatus]; break;
      }
    }
    if (checkEmptyNull(productionPerformanceReducer.startDate, false) && checkEmptyNull(productionPerformanceReducer.endDate, false)) {
      BodyToPost.scheduledStartDate = DateFormat(productionPerformanceReducer.startDate);
      BodyToPost.scheduledEndDate = DateFormat(productionPerformanceReducer.endDate);
      if (productionPerformanceReducer.startDate > productionPerformanceReducer.endDate) {
        BodyToPost.scheduledStartDate = DateFormat(productionPerformanceReducer.endDate);
        BodyToPost.scheduledEndDate = DateFormat(productionPerformanceReducer.startDate);
      }
    } else if (checkEmptyNull(productionPerformanceReducer.startDate, false) && !checkEmptyNull(productionPerformanceReducer.endDate, false)) {
      BodyToPost.scheduledStartDate = DateFormat(productionPerformanceReducer.startDate);
    } else if (checkEmptyNull(productionPerformanceReducer.endDate, false) && !checkEmptyNull(productionPerformanceReducer.startDate, false)) {
      BodyToPost.scheduledEndDate = DateFormat(productionPerformanceReducer.endDate);
    }
    if (checkEmptyNull(productionPerformanceReducer.searchData.accountName, false)) {
      BodyToPost.accountName = productionPerformanceReducer.searchData.accountName;
    }
    if (checkEmptyNull(productionPerformanceReducer.searchData.workOrderCode, false)) {
      BodyToPost.workOrderCode = productionPerformanceReducer.searchData.workOrderCode;
    }
    if (checkEmptyNull(productionPerformanceReducer.searchData.workOrderName, false)) {
      BodyToPost.workOrderName = productionPerformanceReducer.searchData.workOrderName;
    }
    console.log('getWorkOrderLogList - BodyToPost : ', BodyToPost);

    await workOrderLogApi.searchWorkOrderLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.searchWorkOrderLog : ', response);

      setWorkOrderLogList(() => { return response.data.data.content });
      dispatch(productionPerformanceActions_setTotalSize(response.data.data.totalElements));

      if (checkEmptyNull(productionPerformanceReducer.startDate, false) && checkEmptyNull(productionPerformanceReducer.endDate, false)) {
        if (productionPerformanceReducer.startDate > productionPerformanceReducer.endDate) {
          dispatch(productionPerformanceActions_setStartDate(productionPerformanceReducer.endDate))
          dispatch(productionPerformanceActions_setEndDate(productionPerformanceReducer.startDate))
        }
      }
    });
  };

  /* 웹소켓 */
  const applyStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    if (eventWorkOrder === undefined) return;
    eventWorkOrder.workOrderStatus = applyContent.workOrderStatus;
    eventWorkOrder.managers = checkNullArray(applyContent.managers, []);
    eventWorkOrder.workers = checkNullArray(applyContent.workers, []);
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, eventWorkOrder);
    setWorkOrderLogList(() => { return eventWorkOrderList });
  };
  const applyProcessStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    if (eventWorkOrder === undefined) return;

    const eventProcessLogList = [...eventWorkOrder.processLogList];
    const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === applyContent.processLog.processLogId);
    const eventContent = eventProcessLogList[eventProcessLogIndex];
    if (eventContent === undefined) return;

    eventContent.processStatus = applyContent.processLog.processStatus;
    eventContent.completedAmount = applyContent.processLog.completedAmount;
    eventContent.processAmount = applyContent.processLog.processAmount;
    eventContent.processingCount = applyContent.processLog.processingCount;
    eventContent.managers = checkNullArray(applyContent.managers, []);
    eventContent.workers = checkNullArray(applyContent.workers, []);
    if (checkNullArray(eventContent.processLogElementList, false)) {
      const eventResultElements = eventContent.processLogElementList.map((thisItem) => {
        const thisFindIndex = applyContent.processResultElements.findIndex((thisElement) => thisElement.processLogElementId === thisItem.processLogElementId);
        if (thisFindIndex === -1) return thisItem;
        const thisFindItem = applyContent.processResultElements[thisFindIndex];
        const returnData = {...thisItem, ...thisFindItem};
        return returnData;
      });
      eventContent.processLogElementList = eventResultElements;
    }
    eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);

    const detailWorkOrder = {...eventWorkOrder, processLogList: eventProcessLogList};
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, detailWorkOrder);
    setWorkOrderLogList(eventWorkOrderList);
  };
  const applyProcessesStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    if (eventWorkOrder === undefined) return;

    const eventProcessLogList = [...eventWorkOrder.processLogList];
    if (checkNullArray(applyContent.socketProcessResults, false)) {
      applyContent.socketProcessResults.forEach((processResult) => {
        const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === processResult.processLog.processLogId);
        if (eventProcessLogIndex === -1) return;

        const eventContent = eventProcessLogList[eventProcessLogIndex];
        eventContent.processStatus = processResult.processLog.processStatus;
        eventContent.completedAmount = processResult.processLog.completedAmount;
        eventContent.processAmount = processResult.processLog.processAmount;
        eventContent.processingCount = processResult.processLog.processingCount;
        eventContent.managers = checkNullArray(processResult.managers, []);
        eventContent.workers = checkNullArray(processResult.workers, []);
        if (checkNullArray(eventContent.processLogElementList, false)) {
          const eventResultElements = eventContent.processLogElementList.map((thisItem) => {
            const thisFindIndex = processResult.processResultElements.findIndex((thisElement) => thisElement.processLogElementId === thisItem.processLogElementId);
            if (thisFindIndex === -1) return thisItem;
            const thisFindItem = processResult.processResultElements[thisFindIndex];
            const returnData = { ...thisItem, ...thisFindItem };
            return returnData;
          });
          eventContent.processLogElementList = eventResultElements;
        }
        eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
      });
    } else {
      applyContent.processLogIds.forEach((processLogIds) => {
        const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === processLogIds);
        if (eventProcessLogIndex === -1) return;

        const eventContent = eventProcessLogList[eventProcessLogIndex];
        eventContent.processStatus = applyContent.processStatus;
        eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
      });
    }

    const detailWorkOrder = {
      ...eventWorkOrder,
      processLogList: eventProcessLogList,
    };
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, detailWorkOrder);
    setWorkOrderLogList(eventWorkOrderList);
  };

  /* ====================================================================== #5 */
  const handleWorkOrderStatus = async (workOrderStatus) => {
    dispatch(productionPerformanceActions_setWorkOrderStatus(workOrderStatus));
    dispatch(productionPerformanceActions_setPageNumber(1));
    await getWorkOrderLogList(0, 'all', workOrderStatus)
  }

  const actSearch = async () => {
    dispatch(productionPerformanceActions_setPageNumber(1));
    await getWorkOrderLogList(0, 'all', productionPerformanceReducer.workOrderStatus);
  };
  const actReset = async () => {
    dispatch(productionPerformanceActions_setReset());
    getWorkOrderLogList(0, 'all', 'all', true);
  };

  /* 상세보기 */
  const actShowList = (type, workOrderLog) => {
    switch (type) {
      case 'BL':
        setSelectedWorkOrder(workOrderLog);
        dispatch(gsReducer_setWorkOrderContent(workOrderLog));
        setModalStatus_BL(true);
        return;
      case 'CP':
        setSelectedWorkOrder(workOrderLog);
        dispatch(gsReducer_setWorkOrderContent(workOrderLog));
        setModalStatus_CP(true);
        return;
      case 'CL':
        setSelectedWorkOrder(workOrderLog);
        dispatch(gsReducer_setWorkOrderContent(workOrderLog));
        setModalStatus_CL(true);
        return;

      default: return;
    }
  };

  /* ====================================================================== #6 */

  return (
    <Grid3Body contents={
      <>
        <Section className="Main">
          <NavBar
            title={
              <div className='Title'>
                <NavTitle menuCode={'407'} />
                <div className='statusButtons'>
                  {[
                    { status: 'all', name: '전체' },
                    { status: 'end', name: '완료' },
                    { status: 'cancel', name: '취소' },
                  ].map((workOrderStatus, index) => {
                    return (
                      <button key={index + '_statusButton'}
                        className={productionPerformanceReducer.workOrderStatus === workOrderStatus.status ? 'btn-inven-1' : 'btn-set'}
                        onClick={() => { handleWorkOrderStatus(workOrderStatus.status); }}
                      >
                        {workOrderStatus.name}
                      </button>
                    )
                  })}
                </div>
              </div>
            }
            nav={'search'}
            firstRow={
              <div className="SearchSection" style={{columnGap: '20px'}}>
                <div className="SearchSection">
                  <input
                    type="date"
                    className="SelectDate"
                    value={productionPerformanceReducer.startDate}
                    onChange={(e) => { dispatch(productionPerformanceActions_setStartDate(e.target.value)); }}
                  />
                  <IconRightArrow />
                  <input
                    type="date"
                    className="SelectDate"
                    value={productionPerformanceReducer.endDate}
                    onChange={(e) => { dispatch(productionPerformanceActions_setEndDate(e.target.value)); }}
                  />
                </div>

                <div className="SearchSection">
                  <div className="ContentCBox">
                    <select className="SearchOption detail"><option value="accountName">거래처</option></select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkEmptyNull(productionPerformanceReducer.searchData.accountName, '')}
                      onInput={(e) => {dispatch(productionPerformanceActions_setSearchData({ ...productionPerformanceReducer.searchData, accountName: e.target.value }));}}
                    />
                  </div>

                  <div className="ContentCBox">
                    <select className="SearchOption detail"><option value="workOrderCode">작업코드</option></select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkEmptyNull(productionPerformanceReducer.searchData.workOrderCode, '')}
                      onInput={(e) => {dispatch(productionPerformanceActions_setSearchData({ ...productionPerformanceReducer.searchData, workOrderCode: e.target.value }));}}
                    />
                  </div>

                  <div className="ContentCBox">
                    <select className="SearchOption detail"><option value="workOrderName">공사명</option></select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkEmptyNull(productionPerformanceReducer.searchData.workOrderName, '')}
                      onInput={(e) => {dispatch(productionPerformanceActions_setSearchData({ ...productionPerformanceReducer.searchData, workOrderName: e.target.value }));}}
                    />
                  </div>

                  <div className="ContentCBox">
                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton" onClick={actReset}>초기화</div>
                  </div>
                </div>
              </div>
            }
          />

          <TableSection content={
            <table className='gs-table'>
              <thead style={{ zIndex: '800' }}>
                <tr>
                  <th style={{ minWidth: '120px', padding: 'unset', width: '120px'}}>작업상태</th>
                  <th>거래처</th>
                  <th>작업코드</th>
                  <th>공사명</th>
                  <th>현장명</th>
                  <th>현장주소</th>
                  <th>공사기간</th>
                  <th colSpan={3}>작업지시</th>
                </tr>
              </thead>
              <tbody>
                {!checkNullArray(_workOrderLogList, false) ? LoadingMsg() :
                checkNullArray(_workOrderLogList, []).map((workOrderLog, index) => {
                  return (
                    <Fragment key={index + '_workOrderRow'}>
                      <tr data-key={workOrderLog.workOrderLogId}>
                        <StatusTdButton
                          statusStyle={{ cursor: 'default', height: '120px', minWidth: '120px', width: '120px' }}
                          status={workOrderLog.workOrderStatus}
                        />
                        <td>{workOrderLog.accountName}</td>
                        <td>{workOrderLog.workOrderCode}</td>
                        <td>{workOrderLog.workOrderName}</td>
                        <td>{workOrderLog.workPlace}</td>
                        <td>{workOrderLog.detailedWorkPlace}</td>
                        <td>{DateFormat(workOrderLog.workOrderScheduledStartDate)}<br /> ~ <br />{DateFormat(workOrderLog.workOrderScheduledEndDate)}</td>
                        <td style={{minWidth: '160px', padding: 'unset', width: '160px'}}>
                          <ListButton
                            type="BL"
                            data-type={'BL'}
                            data-workorderlogid={workOrderLog.workOrderLogId}
                            onClick={(e) => {e.preventDefault(); actShowList('BL', workOrderLog)}}
                          >
                            캠버리스트<p>[ BL ]</p>
                          </ListButton>
                        </td>
                        <td style={{minWidth: '160px', padding: 'unset', width: '160px'}}>
                          <ListButton
                            type="CP"
                            data-type={'CP'}
                            data-workorderlogid={workOrderLog.workOrderLogId}
                            onClick={(e) => {e.preventDefault(); actShowList('CP', workOrderLog)}}
                          >
                            조합리스트<p>[ CP ]</p>
                          </ListButton>
                        </td>
                        <td style={{ minWidth: '160px', padding: 'unset', width: '160px'}}>
                          <ListButton
                            type="CL"
                            data-type={'CL'}
                            data-workorderlogid={workOrderLog.workOrderLogId}
                            onClick={(e) => {e.preventDefault(); actShowList('CL', workOrderLog)}}
                          >
                            체크리스트<p>[ CL ]</p>
                          </ListButton>
                        </td>
                      </tr>
                      <tr style={{borderBottom: '2px solid var(--Bg)', borderTop: '2px solid var(--Bg)'}}>
                        <td colSpan={10} style={{height: '20px', padding: 'unset'}}>
                          <ProcessCell>
                            <ProcessSection>
                              <Process status={'NO'} style={{border: '1px solid var(--white)', minWidth: '60px', width: '60px'}}>BL</Process>
                              {checkNullArray(workOrderLog.processLogList, []).map((processLog, index) => {
                                if(processLog.processTypeName !== 'BL') return null;
                                const processCustomizedContent = checkNullParse(processLog.processCustomizedContent, {
                                  num: (index + 1).toString().padStart(2, '0'),
                                  absentNumber: '',
                                  standard: '',
                                  unit: '',
                                  length: '',
                                  quantity: '',
                                  defaultCamber: '',
                                  realCamber: '',
                                  remark: '',
                                })
                                return (
                                  <Process key={processLog.processLogId + '_Process'} status={processLog.processStatus} title={processCustomizedContent.absentNumber}>
                                    {checkEmptyNull(processCustomizedContent.num, (index + 1).toString().padStart(2, '0'))}
                                  </Process>
                                )
                              })}
                            </ProcessSection>
                            <ProcessSection>
                              <Process status={'NO'} style={{border: '1px solid var(--white)', minWidth: '60px', width: '60px'}}>CP</Process>
                              {checkNullArray(workOrderLog.processLogList, []).map((processLog, index) => {
                                if(processLog.processTypeName !== 'CP') return null;
                                const processCustomizedContent = checkNullParse(processLog.processCustomizedContent, {
                                  num: (index + 1).toString().padStart(2, '0'),
                                  name: '',
                                  profile: '',
                                  quantity: '',
                                  combined: '',
                                  part: '',
                                  partWidth: '',
                                  each: '',
                                  total: '',
                                  mark: '',
                                })
                                return (
                                  <Process key={processLog.processLogId + '_Process'} status={processLog.processStatus} title={processCustomizedContent.name}>
                                    {checkEmptyNull(processCustomizedContent.num, (index + 1).toString().padStart(2, '0'))}
                                  </Process>
                                )
                              })}
                            </ProcessSection>
                            <ProcessSection>
                              <Process status={'NO'} style={{border: '1px solid var(--white)', minWidth: '60px', width: '60px'}}>CL</Process>
                              {checkNullArray(workOrderLog.processLogList, []).map((processLog, index) => {
                                if(processLog.processTypeName !== 'CL') return null;
                                const processCustomizedContent = checkNullParse(processLog.processCustomizedContent, {
                                  num: (index + 1).toString().padStart(2, '0'),
                                  asmbly: '',
                                  profile: '',
                                  length: '',
                                  quantity: '',
                                  part: '',
                                  weight: '',
                                  name: '',
                                })
                                return (
                                  <Process key={processLog.processLogId + '_Process'} status={processLog.processStatus} title={processCustomizedContent.name}>
                                    {checkEmptyNull(processCustomizedContent.num, (index + 1).toString().padStart(2, '0'))}
                                  </Process>
                                )
                              })}
                            </ProcessSection>
                          </ProcessCell>
                        </td>
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          }
          ></TableSection>
        </Section>

        <PagingComponent
          page={productionPerformanceReducer.pageNumber}
          count={productionPerformanceReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(productionPerformanceActions_setPageNumber(page));
          }}
        />
        <ConnectStatus connectStatus={workReducer.connectStatus} onClick={() => { navigate(0) }}></ConnectStatus>

        {_modalStatus_BL === true && (
          <GSBLStatus
            content={_selectedWorkOrder}
            open={_modalStatus_BL}
            close={() => {
              setModalStatus_BL(false);
              getWorkOrderLogList(productionPerformanceReducer.pageNumber - 1, 'all', productionPerformanceReducer.workOrderStatus)
            }}
          ></GSBLStatus>
        )}
        {_modalStatus_CP === true && (
          <GSCPStatus
            content={_selectedWorkOrder}
            open={_modalStatus_CP}
            close={() => {
              setModalStatus_CP(false);
              getWorkOrderLogList(productionPerformanceReducer.pageNumber - 1, 'all', productionPerformanceReducer.workOrderStatus)
            }}
          ></GSCPStatus>
        )}
        {_modalStatus_CL === true && (
          <GSCLStatus
            content={_selectedWorkOrder}
            open={_modalStatus_CL}
            close={() => {
              setModalStatus_CL(false);
              getWorkOrderLogList(productionPerformanceReducer.pageNumber - 1, 'all', productionPerformanceReducer.workOrderStatus)
            }}
          ></GSCLStatus>
        )}
      </>
    }
    />
  );
};

export default GSProductionPerformance;
