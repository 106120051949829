const initialState = {
  searchData: {},
  searchOption: '',
  searchText: '',

  pageNumber: 1,
  totalSize: 0,
};
const resetState = {
  searchData: {},
  searchOption: '',
  searchText: '',

  pageNumber: 1,
  totalSize: 0,
};

const alarmReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETALARMSEARCHDATA': return { ...state, searchData: action.payload };
    case 'SETALARMSEARCHOPTION': return { ...state, searchOption: action.payload };
    case 'SETALARMSEARCHTEXT': return { ...state, searchText: action.payload };

    case 'SETALARMPAGENUMBER': return { ...state, pageNumber: action.payload };
    case 'SETALARMTOTALSIZE': return { ...state, totalSize: action.payload };

    case 'SETALARMRESET': return { ...state, ...resetState };
    default: return state;
  }
}

export default alarmReducer;