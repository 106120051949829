import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { mallElementActions_setSearchData, mallElementActions_setPageNumber, mallElementActions_setTotalSize, mallElementActions_setReset } from 'store/modules/actions/mall/mallElementActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { element } from 'api/apis/mall/element';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import DOCButtonIcon from 'components/icons/DOCButtonIcon';
import MallBody from 'components/layouts/body/MallBody';
import MallCategories from 'components/mallCategory/MallCategories';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import ShowFileList from 'pages/file/modal/ShowFileList';
import TableSection from 'components/layouts/table/TableSection';

import MallElementUploadModal from 'components/excel/upload/MallElementUploadModal';

const MainSection = styled.main`
  .TableSection {
    tr[name='mallElementRows']:hover {cursor: pointer;}
    & td.ElementInfos {
      border-right: 1px solid var(--gray-200);
      box-sizing: border-box;
      padding: unset;
    }
  }
`;

const ElementInfos = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
  & div:last-child {border-top: 1px solid var(--gray-200);}
`;
const ElementInfo = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 50px auto;
  height: 100%;
  width: 100%;
  & h5 {
    align-items: center;
    background-color: var(--MainNavy);
    color: var(--white);
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
  &.mall h5 {background-color: var(--MainBlue);}
  & p {
    align-items: center;
    box-sizing: border-box;
    justify-content: flex-start;
    padding: 10px;
    width: 100%;
  }
`;

const MallElementManagement = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mallElementReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_originList, setOriginList] = useState([]);
  const [_mallElementList, setMallElementList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_excelModalStatus, setExcelModalStatus] = useState(false); // ==240218 다현: 판매물품 엑셀 업로드 state
  const [_fileListModalStatus, setFileListModalStatus] = useState(false);
  const [_eventContent, setEventContent] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('109') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getMallElementList(mallElementReducer.pageNumber - 1);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getMallElementList(mallElementReducer.pageNumber - 1);
    }
    return () => { };
  }, [mallElementReducer.pageNumber]);

  useEffect(() => {
    if (_onload === 'loaded') {
      dispatch(mallElementActions_setPageNumber(1));
      getMallElementList(0);
    }
    return () => { };
  }, [mallElementReducer.clickedCategory]);

  useEffect(() => { }, [mallElementReducer]);

  /* ====================================================================== #4 */
  const getMallElementList = async (page, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = { companyId: userReducer.company.companyId };
    if (!clear && mallElementReducer.clickedCategory !== 'all' && Object.keys(mallElementReducer.clickedCategory).length > 0) {
      BodyToPost.mallCategoryId = mallElementReducer.clickedCategory.mallCategoryId;
    }
    if (!clear && checkEmptyNull(mallElementReducer.searchData.mallElementName, false)) {
      BodyToPost.mallElementName = mallElementReducer.searchData.mallElementName;
    }
    if (!clear && checkEmptyNull(mallElementReducer.searchData.mallElementCode, false)) {
      BodyToPost.mallElementCode = mallElementReducer.searchData.mallElementCode;
    }
    await element.searchMallElement(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('element.searchMallElement : ', response);
      if (response.data) {
        setOriginList(() => { return response.data.content; });
        setMallElementList(() => { return response.data.content; });
        dispatch(mallElementActions_setTotalSize(response.data.totalElements));
      }
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set([..._checkedItem]);
    if (isChecked) checkedItem.add(id);
    else if (!isChecked && checkedItem.has(id)) checkedItem.delete(id);
    setCheckedItem(() => { return checkedItem; });
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(mallElementActions_setPageNumber(1));
    await getMallElementList(0);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(mallElementActions_setReset());
    dispatch(mallElementActions_setPageNumber(1));
    await getMallElementList(0, true);
  };

  /* 상세보기 */
  const goDetails = (mallElement) => {
    const mallElementIndex = _originList.findIndex((thisItem) => thisItem.mallElementId === mallElement.mallElementId);
    const mallElementData = _originList[mallElementIndex];
    const detailContent = mallElementData;
    navigate({
      pathname: '/mallManagement/element/update',
      search: `?${createSearchParams({ updateContent: JSON.stringify(detailContent) })}`,
      replace: true,
    });
  };

  /* 수정 */
  const actUpdate = () => {
    const checkedMallElementList = Array.from(_checkedItem);
    if (checkedMallElementList.length < 1) return;
    else if (checkedMallElementList.length > 1) return alert('다중 선택되어 있습니다.');

    const updateContentIndex = _originList.findIndex((thisItem) => thisItem.mallElementId === checkedMallElementList[0]);
    if (updateContentIndex === -1) return alert('수정할 물품을 선택해 주세요.');
    const updateContent = _originList[updateContentIndex];
    navigate({
      pathname: '/mallManagement/element/update',
      search: `?${createSearchParams({ updateContent: JSON.stringify(updateContent) })}`,
      replace: true,
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const checkedMallElementList = Array.from(_checkedItem);
    console.log('checkedMallElementList : ', checkedMallElementList);
    if (checkedMallElementList.length < 1) return;

    const deleteContents = checkedMallElementList.map((thisKey) => {
      const deleteContentIndex = _originList.findIndex((thisIndex) => thisIndex.mallElementId === thisKey);
      if (deleteContentIndex === -1) return null;
      const deleteContent = _originList[deleteContentIndex];
      return { ...deleteContent, mallCategory: JSON.stringify(deleteContent.mallCategory) };
    });
    const setDeleteContents = deleteContents.filter((thisItem) => thisItem !== null);
    if (setDeleteContents.length < 1) return;

    navigate({
      pathname: '/mallManagement/element/delete',
      search: `?${createSearchParams({ deleteContents: JSON.stringify(setDeleteContents) })}`,
      replace: true,
    });
  };

  // ==240218 다현: 판매물품 엑셀 업로드 모달 핸들러
  const actUpload = () => { setExcelModalStatus(true); }

  /* 파일 리스트 */
  const showFileList = (mallElement) => {
    setEventContent(() => { return mallElement; });
    setTimeout(setFileListModalStatus(true), 1000);
  };

  /* ====================================================================== #6 */

  return (
    <MallBody contents={
      <>
        <MainSection className="Main">
          <NavBar
            title={'판매 물품정보'}
            buttons={
              <>
                <button className='btn-add' onClick={(e) => { e.preventDefault(); navigate({ pathname: '/mallManagement/element/create', replace: true }); }}>추가</button>
                <button className='btn-edit' onClick={actUpdate}>수정</button>
                <button className='btn-delete' onClick={actDelete}>삭제</button>

                <button className='btn-upload' onClick={actUpload}>판매물품 업로드</button>
              </>
            }
            nav={'all'}
            firstRow={
              <>
                <div className="SearchSection"><MallCategories categoryType={'mallElement'} /></div>
              </>
            }
            secondRow={
              <>
                <div className="SearchSection">
                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="mallElementName">판매 이름</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkEmptyNull(mallElementReducer.searchData.mallElementName, '')}
                      onInput={(e) => { dispatch(mallElementActions_setSearchData({ ...mallElementReducer.searchData, mallElementName: e.target.value })); }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="mallElementCode">판매 코드</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkEmptyNull(mallElementReducer.searchData.mallElementCode, '')}
                      onInput={(e) => { dispatch(mallElementActions_setSearchData({ ...mallElementReducer.searchData, mallElementCode: e.target.value })); }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <div className="DetailButton search" style={{ backgroundColor: 'var(--ThirdBlue)', columnGap: '3px' }} onClick={actSearch}><SearchButtonIcon />검색</div>
                    <div className="ResetButton detail" onClick={actReset}>초기화</div>
                  </div>
                </div>
              </>
            }
          />

          <TableSection content={
            <table>
              <thead>
                <tr>
                  <th style={{ minWidth: '50px', width: '50px' }}>
                    <input
                      type="checkBox"
                      name="allMallElements"
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        const checkedItem = new Set([..._checkedItem]);

                        if (isChecked) {
                          document.getElementsByName('mallElements').forEach((thisAttr) => {
                            checkedItem.add(Number(thisAttr.dataset.key));
                            thisAttr.checked = true;
                          });
                          e.target.checked = true;
                        } else {
                          document.getElementsByName('mallElements').forEach((thisAttr) => {
                            checkedItem.delete(Number(thisAttr.dataset.key));
                            thisAttr.checked = false;
                          });
                          e.target.checked = false;
                        }
                        setCheckedItem(() => { return checkedItem; });
                      }}
                    />
                  </th>
                  {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? (
                    <th rowSpan={2} style={{ minWidth: '70px', width: '70px' }}>파일</th>
                  ) : null}
                  <th>카테고리</th>
                  <th>판매 물품정보</th>
                  <th>물품정보</th>
                  <th>단위</th>
                  <th>가격</th>
                  <th>규격</th>
                  <th>설명</th>
                </tr>
              </thead>
              <tbody>
                {_mallElementList.map((thisItem) => {
                  return (
                    <tr key={thisItem.mallElementId + '_mallElements'} name={'mallElementRows'} onClick={(e) => { goDetails(thisItem); }}>
                      <td style={{ minWidth: '50px', width: '50px' }}>
                        <input
                          type="checkBox"
                          name="mallElements"
                          data-key={thisItem.mallElementId}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            handleCheckedItem(thisItem.mallElementId, isChecked);
                            if (isChecked) e.target.checked = true;
                            else e.target.checked = false;
                          }}
                          onClick={(e) => { e.stopPropagation(); }}
                        />
                      </td>
                      {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? checkNullArray(thisItem.attachFileList, false) ? (
                        <td className='table-preview'>
                          <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                            onClick={(e) => { e.stopPropagation(); showFileList(thisItem); }}
                          >
                            <img src={thisItem.attachFileList[0]?.fileData?.fileDataS3URL || ''} alt='image preview' className='img-preview' />
                            <div className='btn-file'><DOCButtonIcon /></div>
                          </div>
                        </td>
                      ) : (
                        <td className='table-preview'>
                          <div
                            style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                            onClick={(e) => { e.stopPropagation(); showFileList(thisItem); }}
                          >
                            <div className='no-image'><p>이미지가 없습니다.</p></div>
                            <div className='btn-file'><DOCButtonIcon /></div>
                          </div>
                        </td>
                      ) : null}
                      <td>{checkEmptyNull(thisItem.mallCategoryName, '')}</td>
                      <td className="ElementInfos">
                        <ElementInfos>
                          <ElementInfo className="mall"><h5>이름</h5><p>{thisItem.mallElementName}</p></ElementInfo>
                          <ElementInfo className="mall"><h5>코드</h5><p>{thisItem.mallElementCode}</p></ElementInfo>
                        </ElementInfos>
                      </td>
                      <td className="ElementInfos">
                        <ElementInfos>
                          <ElementInfo><h5>이름</h5><p>{thisItem.elementName}</p></ElementInfo>
                          <ElementInfo><h5>코드</h5><p>{thisItem.elementCode}</p></ElementInfo>
                        </ElementInfos>
                      </td>
                      <td>{thisItem.elementUnit}</td>
                      <td>{checkEmptyNull(thisItem.mallElementPrice, 0).toLocaleString()}</td>
                      <td>{thisItem.mallElementSize}</td>
                      <td>{thisItem.mallElementContent}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          }
          ></TableSection>
        </MainSection>

        <PagingComponent
          page={mallElementReducer.pageNumber}
          count={mallElementReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(mallElementActions_setPageNumber(page));
          }}
        />

        {_excelModalStatus === true ? (
          <MallElementUploadModal
            open={_excelModalStatus}
            close={() => {
              setExcelModalStatus(false);
              getMallElementList(mallElementReducer.pageNumber - 1);
            }}
          />
        ) : null}

        {_fileListModalStatus === true && checkEmptyNull(_eventContent.mallElementId, false) ? (
          <ShowFileList
            type={'mallElement'}
            content={_eventContent}
            open={_fileListModalStatus}
            close={() => { setFileListModalStatus(false); }}
          />
        ) : null}
      </>
    }
    />
  );
};

export default MallElementManagement;
