import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { contentsReducer_setStockItem } from 'store/modules/actions/default/contentsActions';
import { gsReducer_setWorkOrderContent } from 'store/modules/actions/company/GSActions';

import { elementApi } from 'api/apis/elementApi';
import { procurementApi } from 'api/apis/procurementApi';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { barcodeButton } from 'components/icons/src';
import GSNumpad from 'pages/stock/custom/company/gs/numpad/GSNumpad';
import GSCLWorkerScreenB from 'pages/working/custom/company/gs/CL/GSCLWorkerScreenB';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;

  input {
    background-color: transparent;
    border: 1px solid var(--white);
    color: var(--white);
    cursor: default;
    display: block;
    padding: 10px 10px;
    width: 200px;
  }
`;

const BarcodeIcon = styled.div`
  background-color: var(--white);
  height: 200px;
  width: 200px;
  mask-image: url(${barcodeButton}); mask-repeat: no-repeat; mask-size: contain;
  --webkit-mask-image: url(${barcodeButton}); --webkit-mask-repeat: no-repeat; --webkit-mask-size: contain;
`;
const PrintBarcodeButton = styled(Link)`
  align-items: center;
  background-color: var(--white);
  border-radius: 5px;
  bottom: 25px;
  color: var(--MainNavy);
  column-gap: 5px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  left: 15px;
  line-height: 30px;
  justify-content: center;
  padding: 4px 20px;
  position: fixed;
`;

const ScanGSBarcode = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_check, setCheck] = useState(false);
  
  const [_selectedProcurement, setSelectedProcurement] = useState({});
  const [_modalStatus, setModalStatus] = useState(false);

  const [_selectedBarcode, setSelectedBarcode] = useState({});
  const [_workModalStatus, setWorkModalStatus] = useState(false);

  /* ====================================================================== #3 */
  /* ====================================================================== #4 */
  const ScanFromProcurement = async (Barcode, Result) => {
    console.log('ScanFromProcurement : ', Barcode, Result);
    setSelectedBarcode(Barcode);
    setSelectedProcurement(Result);

    const BodyToPost = {
      companyId: userReducer.company.companyId,
      elementId: Number(Barcode.split('-')[3]),
    }
    await elementApi.searchElementStockList('/', BodyToPost).then((response) => {
      if (response === undefined) return;
      if (response.data.content.length > 0) {
        dispatch(contentsReducer_setStockItem(response.data.content[0]));
        setModalStatus(true);
      } else {
        alert('다시 시도해주세요.');
        document.getElementById('barcodeInput').value = '';
        setCheck(false);
      }
    }).catch((error) => {
      console.log('/element/stockListSearch - error : ', error);
      alert('다시 시도해주세요.');
      document.getElementById('barcodeInput').value = '';
      setCheck(false);
    });
  };

  const ScanFromWorkOrder = async (Barcode, Result) => {
    console.log('ScanFromWorkOrder : ', Result);
    document.getElementById('barcodeInput').value = '';
    setSelectedBarcode(Barcode);

    const workOrderLogId = Number(Barcode.split('-')[1]);
    await workOrderLogApi.getWorkOrderLog(workOrderLogId).then((response) => {
      if (response === undefined) return;
      if (Object.keys(response.data).length > 0) {
        dispatch(gsReducer_setWorkOrderContent(response.data));
        setWorkModalStatus(true);
      } else {
        alert('다시 시도해주세요.');
        document.getElementById('barcodeInput').value = '';
        setCheck(false);
      }
    }).catch((error) => {
      console.log('/workOrderLog/search - error : ', error);
      alert('다시 시도해주세요.');
      document.getElementById('barcodeInput').value = '';
      setCheck(false);
    });
  };

  /* ====================================================================== #5 */
  const actScan = (e) => {
    if (_check !== true) {
      setCheck(true);
      setTimeout(async () => {
        const thisBarcode = e.target.value;
        if (thisBarcode.startsWith('Procurement-') === true) {
          const procurementId = thisBarcode.split('-')[1];
          await procurementApi.getProcurement(procurementId).then((response) => {
            if (response === undefined) return;
            ScanFromProcurement(thisBarcode, response.data);
          }).catch((error) => {
            console.log('get/procurement - error : ', error);
            alert('다시 시도해주세요.');
            document.getElementById('barcodeInput').value = '';
            setCheck(false);
          });
        } else if (thisBarcode.startsWith('WorkOrder-') === true) {
          const workOrderLogId = thisBarcode.split('-')[1];
          await workOrderLogApi.getWorkOrderLog(workOrderLogId).then((response) => {
            if (response === undefined) return;
            ScanFromWorkOrder(thisBarcode, response.data);
          }).catch((error) => {
            console.log('get/workOrderLog - error : ', error);
            alert('다시 시도해주세요.');
            document.getElementById('barcodeInput').value = '';
            setCheck(false);
          });
        } else {
          alert('다시 시도해주세요.');
          document.getElementById('barcodeInput').value = '';
          setCheck(false);
          return;
        }
      }, 1000);
      clearTimeout();
    }
  };

  /* ====================================================================== #6 */

  return (
    <>
      <ModalBg onClick={(e) => {if ( e.target.id !== 'barcodeInput' && e.target.id !== 'PrintBarcodeButton') navigate('/dashboard', { replace: true });}}>
        <ModalSection>
          <BarcodeIcon />
          <input id="barcodeInput" type="text" autoFocus onChange={actScan} />
        </ModalSection>
        <PrintBarcodeButton id="PrintBarcodeButton" to="/barcode/gs/print">바코드 출력</PrintBarcodeButton>
      </ModalBg>

      {_modalStatus === true && (
        <GSNumpad
          Barcode={_selectedBarcode}
          Procurement={_selectedProcurement}
          open={_modalStatus}
          close={() => {
            setModalStatus(false);
            document.getElementById('barcodeInput').value = '';
            setCheck(false);
          }}
        ></GSNumpad>
      )}

      {_workModalStatus === true && (
        <GSCLWorkerScreenB
          Barcode={_selectedBarcode}
          Procurement={_selectedProcurement}
          open={_workModalStatus}
          close={() => {
            setWorkModalStatus(false);
            document.getElementById('barcodeInput').value = '';
            setCheck(false);
          }}
        ></GSCLWorkerScreenB>
      )}
    </>
  );
};

export default ScanGSBarcode;
