import React from 'react';
import { Outlet } from 'react-router-dom';
import { OperatingAPIInterceptor } from 'api/core/OperatingAPIInterceptor';

const OperatingRouteLayout = ({ children }) => {
  return (<>
    <OperatingAPIInterceptor></OperatingAPIInterceptor>
    <Outlet {...children} />
  </>);
};

export default OperatingRouteLayout;
