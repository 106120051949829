import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { workActions_setCurrentMessage, workActions_setEndDate, workActions_setPageNumber, workActions_setReset, workActions_setSearchData, workActions_setSearchOption, workActions_setSearchText, workActions_setStartDate, workActions_setTotalSize, workActions_setWorkCase, workActions_setWorkOrderList, workActions_setWorkOrderStatus } from 'store/modules/actions/default/workActions';
import { gsReducer_setWorkOrderContent } from 'store/modules/actions/company/GSActions';

import { getCookie } from 'preferences/cookie/cookie';
import { WS_CLIENT } from 'preferences/server/constants';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import { LoadingMsg } from 'components/loading/LoadingMsg';

import GridNonBodyGS from 'components/layouts/body/GridNonBodyGS';
import IconRightArrow from 'components/icons/IconRightArrow';
import Header from 'components/header/Header';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';

import GSBLWorkerScreen from 'pages/working/custom/company/gs/BL/GSBLWorkerScreen';
import GSCPWorkerScreen from 'pages/working/custom/company/gs/CP/GSCPWorkerScreen';
import GSCLWorkerScreen from 'pages/working/custom/company/gs/CL/GSCLWorkerScreen';
import GSWorkEndModal from 'pages/working/custom/company/gs/status/GSWorkEndModal';
import GSWorkOrderStatusModal from 'pages/working/custom/company/gs/modal/GSWorkOrderStatusModal';

const WorkerScreenSection = styled.main`
  display: grid;
  grid-template-rows: auto minmax(calc(100% - 166px), calc(100% - 66px));
  height: ${props => {
    return props.onHeader ? 'calc(100svh - 120px)' : 'calc(100svh - 60px)';
  }};

  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
    & .statusButtons {display: flex; gap: 5px;}
  }

  & .TableSection {
    height: 100%;
    & .table-preview {min-width: 150px; width: 150px;}
  }
`;
const ListButton = styled.div`
  align-items: center;
  background-color: ${(props) => {
    switch (props.type) {
      case 'BL': return 'var(--MainBlue)';
      case 'CP': return 'var(--ThirdBlue)';
      case 'CL': return 'var(--MainNavy)';
      default: return null;
    }
  }};
  border: 4px solid var(--white);
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 600;
  gap: 6px;
  height: 100%;
  justify-content: center;
  min-height: 100px;
  width: 100%;

  & p {font-size: 0.8em;}
`;
const ProcessCell = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 100%;
  width: 100%;
`;
const ProcessSection = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 60px repeat(auto-fit, minmax(50px, 1fr));
  grid-template-rows: repeat(auto-fit, 40px);
  height: 100%;
  width: 100%;
`;
const Process = styled.div`
  align-items: center;
  box-sizing: border-box;
  cursor: default;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  justify-content: center;
  width: 100%;

  background-color: ${(props) => {
    switch (props.status) {
      case 'wait': return 'var(--gray-200)'
      case 'work': return 'var(--WorkingColor)'
      case 'end': return 'var(--ThirdBlue)'
      case 'stop': return 'var(--MainRed)'
      case 'pause': return 'var(--MainYellow)'
      case 'cancel': return 'var(--Bg)'

      case 'NO': return 'var(--MainNavy)';
      case 'cut': return '#BCE55C'
      case 'welding': return '#9FC93C'
      case 'last': return '#689900'

      default: return 'unset';
    }
  }};

  color: ${(props) => {
    return props.status.toLowerCase() === 'cancel' ? 'var(--gray-200)' : 'var(--white)';
  }};
`;

const ConnectStatus = styled.div`
  background-color: ${(props) => {
    return props.connectStatus === 'connect' ? 'var(--MainGreen)' : 'var(--MainRed)';
  }};
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  right: 80px;
  bottom: 26px;
  height: 40px;
  width: 40px;
  z-index: 999;
`;
const DashboardButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  border-radius: 8px;
  bottom: 80px;
  color: var(--white);
  column-gap: 5px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  right: 24px;
  line-height: 30px;
  justify-content: center;
  padding: 6px 16px;
  position: fixed;
`;

const GSWorkerScreen = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userReducer, workReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const RoomId = userReducer.company.companyId;

  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_workOrderLogList, setWorkOrderLogList] = useState([]);

  const [_workOrderStatusModal, setWorkOrderStatusModal] = useState(false);
  const [_eventWorkOrderLogId, setEventWorkOrderLogId] = useState('');
  const [_eventWorkOrderStatus, setEventWorkOrderStatus] = useState('');

  const [_workEndStatus, setWorkEndStatus] = useState(false);
  const [_workEndOrder, setWorkEndOrder] = useState({});

  const [_modalStatus_BL, setModalStatus_BL] = useState(false);
  const [_modalStatus_CP, setModalStatus_CP] = useState(false);
  const [_modalStatus_CL, setModalStatus_CL] = useState(false);
  const [_selectedWorkOrder, setSelectedWorkOrder] = useState({});

  const [onHeader, setOnHeader] = useState(false)
  const clickHeader = (e) => { setOnHeader(!onHeader) };

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('501') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));
    dispatch(workActions_setWorkCase('work'));

    getWorkOrderLogList(workReducer.pageNumber - 1, 'all', workReducer.workOrderStatus);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getWorkOrderLogList(workReducer.pageNumber - 1, 'all', workReducer.workOrderStatus);
    }

    return () => { };
  }, [workReducer.pageNumber]);

  useEffect(() => {
    if (workReducer.connectStatus === 'connect') {
      if (!checkNullObject(workReducer.currentMessage, false)) return;

      if (workReducer.currentMessage.userId === userReducer.user.id && checkEmptyNull(workReducer.currentMessage.errorMessage, false)) {
        alert(workReducer.currentMessage.errorMessage);
        dispatch(workActions_setCurrentMessage({}));
        return;
      }

      if (workReducer.currentMessage.type === '/work') applyStatus();
      if (workReducer.currentMessage.type === 'process') applyProcessStatus();
      if (workReducer.currentMessage.type === 'processes') applyProcessesStatus();
    }

    return () => { };
  }, [workReducer.currentMessage]);

  useEffect(() => { }, [workReducer.connectStatus]);

  /* ====================================================================== #4 */
  const getWorkOrderLogList = async (page, workOrderType, workOrderStatus, clear) => {
    const paging = `?page=${page}&size=5&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && checkEmptyNull(workOrderStatus, false)) {
      switch (workOrderStatus) {
        case 'all': BodyToPost.workOrderStatus = ['wait', 'work', 'pause', 'stop', 'end', 'cancel'];
        case 'waitNwork': BodyToPost.workOrderStatus = ['wait', 'work']; break;
        default: BodyToPost.workOrderStatus = [workOrderStatus]; break;
      }
    }
    if (!clear && checkEmptyNull(workReducer.startDate, false) && checkEmptyNull(workReducer.endDate, false)) {
      BodyToPost.scheduledStartDate = DateFormat(workReducer.startDate);
      BodyToPost.scheduledEndDate = DateFormat(workReducer.endDate);
      if (workReducer.startDate > workReducer.endDate) {
        BodyToPost.scheduledStartDate = DateFormat(workReducer.endDate);
        BodyToPost.scheduledEndDate = DateFormat(workReducer.startDate);
      }
    } else if (!clear && checkEmptyNull(workReducer.startDate, false) && !checkEmptyNull(workReducer.endDate, false)) {
      BodyToPost.scheduledStartDate = DateFormat(workReducer.startDate);
    } else if (!clear && checkEmptyNull(workReducer.endDate, false) && !checkEmptyNull(workReducer.startDate, false)) {
      BodyToPost.scheduledEndDate = DateFormat(workReducer.endDate);
    }
    if (!clear && checkEmptyNull(workReducer.searchData.accountName, false)) {
      BodyToPost.accountName = workReducer.searchData.accountName;
    }
    if (!clear && checkEmptyNull(workReducer.searchData.workOrderCode, false)) {
      BodyToPost.workOrderCode = workReducer.searchData.workOrderCode;
    }
    if (!clear && checkEmptyNull(workReducer.searchData.workOrderName, false)) {
      BodyToPost.workOrderName = workReducer.searchData.workOrderName;
    }

    await workOrderLogApi.searchWorkOrderLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.searchWorkOrderLog : ', response);
      setWorkOrderLogList(() => { return response.data.data.content; });
      dispatch(workActions_setTotalSize(response.data.data.totalElements));

      if (checkEmptyNull(workReducer.startDate, false) && checkEmptyNull(workReducer.endDate, false)) {
        if (workReducer.startDate > workReducer.endDate) {
          dispatch(workActions_setStartDate(workReducer.endDate))
          dispatch(workActions_setEndDate(workReducer.startDate))
        }
      }
    })
  };

  /* 웹소켓 */
  const applyStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    if (eventWorkOrder === undefined) return;
    eventWorkOrder.workOrderStatus = applyContent.workOrderStatus;
    eventWorkOrder.managers = checkNullArray(applyContent.managers, []);
    eventWorkOrder.workers = checkNullArray(applyContent.workers, []);
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, eventWorkOrder);
    setWorkOrderLogList(eventWorkOrderList);
    dispatch(workActions_setWorkOrderList(eventWorkOrderList));
  };
  const applyProcessStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    if (eventWorkOrder === undefined) return;

    const eventProcessLogList = [...eventWorkOrder.processLogList];
    const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === applyContent.processLog.processLogId);
    const eventContent = eventProcessLogList[eventProcessLogIndex];
    eventContent.processStatus = applyContent.processLog.processStatus;
    eventContent.managers = checkNullArray(applyContent.managers, []);
    eventContent.workers = checkNullArray(applyContent.workers, []);
    eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);

    const detailWorkOrder = { ...eventWorkOrder, processLogList: eventProcessLogList };
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, detailWorkOrder);
    setWorkOrderLogList(eventWorkOrderList);
    dispatch(workActions_setWorkOrderList(eventWorkOrderList));
  };
  const applyProcessesStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    if (eventWorkOrder === undefined) return;

    const eventProcessLogList = [...eventWorkOrder.processLogList];
    if (applyContent.socketProcessResults !== undefined && applyContent.socketProcessResults.length > 0) {
      applyContent.socketProcessResults.forEach((processResult) => {
        const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === processResult.processLog.processLogId);
        if (eventProcessLogIndex === -1) return;

        const eventContent = eventProcessLogList[eventProcessLogIndex];
        eventContent.processStatus = processResult.processLog.processStatus;
        eventContent.managers = checkNullArray(applyContent.managers, []);
        eventContent.workers = checkNullArray(applyContent.workers, []);
        eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
      });
    } else {
      applyContent.processLogIds.forEach((processLogIds) => {
        const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === processLogIds);
        if (eventProcessLogIndex === -1) return;

        const eventContent = eventProcessLogList[eventProcessLogIndex];
        eventContent.processStatus = applyContent.processStatus;
        eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
      });
    }

    const detailWorkOrder = { ...eventWorkOrder, processLogList: eventProcessLogList };
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, detailWorkOrder);
    setWorkOrderLogList(eventWorkOrderList);
    dispatch(workActions_setWorkOrderList(eventWorkOrderList));
  };

  /* ====================================================================== #5 */
  const handleWorkOrderStatus = async (workOrderStatus) => {
    dispatch(workActions_setWorkOrderStatus(workOrderStatus));
    dispatch(workActions_setPageNumber(1));
    await getWorkOrderLogList(0, 'all', workOrderStatus)
  }
  const actSearch = async () => {
    dispatch(workActions_setPageNumber(1));
    await getWorkOrderLogList(0, 'all', workReducer.workOrderStatus);
  };
  const actReset = async () => {
    dispatch(workActions_setReset());
    dispatch(workActions_setPageNumber(1));
    await getWorkOrderLogList(0, 'all', 'all', true);
  };

  /* 상세보기 */
  const actShowList = (processType, workOrderLog) => {
    switch (processType) {
      case 'BL':
        setSelectedWorkOrder(workOrderLog);
        dispatch(gsReducer_setWorkOrderContent(workOrderLog));
        setModalStatus_BL(true);
        return;
      case 'CP':
        setSelectedWorkOrder(workOrderLog);
        dispatch(gsReducer_setWorkOrderContent(workOrderLog));
        setModalStatus_CP(true);
        return;
      case 'CL':
        setSelectedWorkOrder(workOrderLog);
        dispatch(gsReducer_setWorkOrderContent(workOrderLog));
        setModalStatus_CL(true);
        return;

      default: return;
    }
  };

  /* 작업 상태 변경 */
  const actChangeWorkOrderStatus = (workOrderLog) => {
    if (workReducer.connectStatus !== 'connect') return alert(`
연결이 불안정합니다.
네트워크 상태 확인 후 다시 시도하여 주시기 바랍니다.
    `);

    setEventWorkOrderLogId(workOrderLog.workOrderLogId);
    setEventWorkOrderStatus(workOrderLog.workOrderStatus);
    setWorkOrderStatusModal(true);
  };

  /* 작업상태 설정 */
  const workOrderStatusEvent = (e) => {
    const { workordercode, status } = e.currentTarget.dataset;
    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === Number(workordercode));
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    eventWorkOrder.workOrderStatus = status;

    let alertStatus = null;
    switch (status) {
      case 'wait': alertStatus = '대기'; break;
      case 'work': alertStatus = '진행'; break;
      case 'pause': alertStatus = '일시정지'; break;
      case 'stop': alertStatus = '중단'; break;
      case 'end': alertStatus = '완료'; break;
      case 'cancel': alertStatus = '취소'; break;
      default: return;
    }
    const alertText = `작업지시를 ${alertStatus}합니다.`;
    alert(alertText);

    const BodyToWS = {
      roomId: RoomId,
      type: '/work',
      workOrderLogId: Number(workordercode),
      workOrderStatus: status,
    };
    console.log('client.publish/pub/work - body : ', JSON.stringify(BodyToWS));

    if (status === 'end') {
      setWorkEndOrder({ BodyToWS: BodyToWS, eventWorkOrder: eventWorkOrder });
      setWorkEndStatus(true);
      return;
    } else {
      eventWorkOrderList.splice(eventWorkOrderIndex, 1, eventWorkOrder);
      if (workReducer.connectStatus !== 'connect' || !checkNullArray(eventWorkOrderList, false)) return;

      try {
        WS_CLIENT.publish({
          destination: '/pub/work',
          headers: { 'auth-token': getCookie('accessToken')?.replace('Bearer ', '') },
          body: JSON.stringify(BodyToWS),
        });
        setWorkOrderLogList(eventWorkOrderList);
        dispatch(workActions_setWorkOrderList(eventWorkOrderList));
      } catch (error) {
        console.log('client.publish/pub/work - error : ', error);
      }

      setWorkOrderStatusModal(false);
    }
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기


  return (
    <GridNonBodyGS
      header={<>{onHeader ? (<Header />) : null}</>}
      contents={
        <>
          <WorkerScreenSection className="Main" onHeader={onHeader}>
            <NavBar
              title={
                <div className='Title'>
                  <NavTitle menuCode={'501'} />
                  <div className='statusButtons'>
                    {[
                      { status: 'all', name: '전체' },
                      { status: 'waitNwork', name: '대기/작업중' },
                      { status: 'wait', name: '대기' },
                      { status: 'work', name: '작업중' },
                      { status: 'pause', name: '일시정지' },
                      { status: 'stop', name: '중단' },
                      { status: 'end', name: '완료' },
                      { status: 'cancel', name: '취소' },
                    ].map((workOrderStatus, index) => {
                      return (
                        <button key={index + '_statusButton'}
                          className={workReducer.workOrderStatus === workOrderStatus.status ? 'btn-inven-1' : 'btn-set'}
                          onClick={() => { handleWorkOrderStatus(workOrderStatus.status); }}
                        >
                          {workOrderStatus.name}
                        </button>
                      )
                    })}
                  </div>
                </div>
              }
              nav={'search'}
              firstRow={
                <>
                  <div className="SearchSection" style={{ columnGap: '20px' }}>
                    <div className="SearchSection">
                      <input
                        type="date"
                        className="SelectDate"
                        value={workReducer.startDate}
                        onChange={(e) => { dispatch(workActions_setStartDate(e.target.value)) }}
                      />
                      <IconRightArrow />
                      <input
                        type="date"
                        className="SelectDate"
                        value={workReducer.endDate}
                        onChange={(e) => { dispatch(workActions_setEndDate(e.target.value)) }}
                      />
                    </div>

                    <div className="SearchSection">
                      <div className="ContentCBox">
                        <select className="SearchOption detail"><option value="accountName">거래처</option></select>
                        <input
                          className="SearchBar detail"
                          placeholder="Search..."
                          value={checkEmptyNull(workReducer.searchData.accountName, '')}
                          onInput={(e) => { dispatch(workActions_setSearchData({ ...workReducer.searchData, accountName: e.target.value })); }}
                        />
                      </div>

                      <div className="ContentCBox">
                        <select className="SearchOption detail"><option value="workOrderCode">작업코드</option></select>
                        <input
                          className="SearchBar detail"
                          placeholder="Search..."
                          value={checkEmptyNull(workReducer.searchData.workOrderCode, '')}
                          onInput={(e) => { dispatch(workActions_setSearchData({ ...workReducer.searchData, workOrderCode: e.target.value })); }}
                        />
                      </div>

                      <div className="ContentCBox">
                        <select className="SearchOption detail"><option value="workOrderName">공사명</option></select>
                        <input
                          className="SearchBar detail"
                          placeholder="Search..."
                          value={checkEmptyNull(workReducer.searchData.workOrderName, '')}
                          onInput={(e) => { dispatch(workActions_setSearchData({ ...workReducer.searchData, workOrderName: e.target.value })); }}
                        />
                      </div>

                      <div className="ContentCBox">
                        <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                        <div className="ResetButton" onClick={actReset}>초기화</div>
                      </div>
                    </div>
                  </div>
                </>
              }
            />

            <TableSection content={
              <table className='gs-table'>
                <thead style={{ zIndex: '800' }}>
                  <tr>
                    <th style={{ minWidth: '120px', padding: 'unset', width: '120px' }}>작업상태</th>
                    <th>거래처</th>
                    <th>작업코드</th>
                    <th>공사명</th>
                    <th>현장명</th>
                    <th>현장주소</th>
                    <th>공사기간</th>
                    <th colSpan={3}>작업지시</th>
                  </tr>
                </thead>
                <tbody>
                  {!checkNullArray(_workOrderLogList, false) ? LoadingMsg() :
                    checkNullArray(_workOrderLogList, []).map((workOrderLog, index) => {
                      return (
                        <Fragment key={index + '_workOrderRow'}>
                          <tr data-key={workOrderLog.workOrderLogId}>
                            <StatusTdButton
                              statusStyle={{ cursor: _authority.indexOf('501-2') !== -1 ? 'pointer' : 'default', height: '120px', minWidth: '120px', width: '120px' }}
                              status={workOrderLog.workOrderStatus}
                              statusEvent={(e) => { e.preventDefault(); if (_authority.indexOf('501-2') !== -1) actChangeWorkOrderStatus(workOrderLog) }}
                            />
                            <td>{workOrderLog.accountName}</td>
                            <td>{workOrderLog.workOrderCode}</td>
                            <td>{workOrderLog.workOrderName}</td>
                            <td>{workOrderLog.workPlace}</td>
                            <td>{workOrderLog.detailedWorkPlace}</td>
                            <td>{DateFormat(workOrderLog.workOrderScheduledStartDate)}<br /> ~ <br />{DateFormat(workOrderLog.workOrderScheduledEndDate)}</td>
                            <td style={{ minWidth: '160px', padding: 'unset', width: '160px' }}>
                              <ListButton
                                type="BL"
                                data-type={'BL'}
                                data-workorderlogid={workOrderLog.workOrderLogId}
                                onClick={(e) => { e.preventDefault(); actShowList('BL', workOrderLog) }}
                              >
                                캠버리스트<p>[ BL ]</p>
                              </ListButton>
                            </td>
                            <td style={{ minWidth: '160px', padding: 'unset', width: '160px' }}>
                              <ListButton
                                type="CP"
                                data-type={'CP'}
                                data-workorderlogid={workOrderLog.workOrderLogId}
                                onClick={(e) => { e.preventDefault(); actShowList('CP', workOrderLog) }}
                              >
                                조합리스트<p>[ CP ]</p>
                              </ListButton>
                            </td>
                            <td style={{ minWidth: '160px', padding: 'unset', width: '160px' }}>
                              <ListButton
                                type="CL"
                                data-type={'CL'}
                                data-workorderlogid={workOrderLog.workOrderLogId}
                                onClick={(e) => { e.preventDefault(); actShowList('CL', workOrderLog) }}
                              >
                                체크리스트<p>[ CL ]</p>
                              </ListButton>
                            </td>
                          </tr>
                          <tr style={{ borderBottom: '2px solid var(--Bg)', borderTop: '2px solid var(--Bg)' }}>
                            <td colSpan={10} style={{ height: '20px', padding: 'unset' }}>
                              {(() => {
                                const BL = checkNullArray(workOrderLog.processLogList, []).filter(item => item.processTypeName === 'BL');
                                const CP = checkNullArray(workOrderLog.processLogList, []).filter(item => item.processTypeName === 'CP');
                                const CL = checkNullArray(workOrderLog.processLogList, []).filter(item => item.processTypeName === 'CL');

                                return (
                                  <ProcessCell>
                                    <ProcessSection>
                                      <Process status={'NO'} style={{ border: '1px solid var(--white)', minWidth: '60px', width: '60px' }}>BL</Process>
                                      {checkNullArray(BL, []).map((processLog, processIndex) => {
                                        const processCustomizedContent = checkNullParse(processLog.processCustomizedContent, {
                                          num: (processIndex + 1).toString().padStart(2, '0'),
                                          absentNumber: '',
                                          standard: '',
                                          unit: '',
                                          length: '',
                                          quantity: '',
                                          defaultCamber: '',
                                          realCamber: '',
                                          remark: '',
                                        })
                                        return (
                                          <Process key={processLog.processLogId + '_Process'} status={processLog.processStatus} title={processCustomizedContent.absentNumber}>
                                            {/* {checkEmptyNull(processCustomizedContent.num, (processIndex + 1).toString().padStart(2, '0'))} */}
                                            {(processIndex + 1)}
                                          </Process>
                                        )
                                      })}
                                    </ProcessSection>
                                    <ProcessSection>
                                      <Process status={'NO'} style={{ border: '1px solid var(--white)', minWidth: '60px', width: '60px' }}>CP</Process>
                                      {checkNullArray(CP, []).map((processLog, processIndex) => {
                                        const processCustomizedContent = checkNullParse(processLog.processCustomizedContent, {
                                          num: (processIndex + 1).toString().padStart(2, '0'),
                                          name: '',
                                          profile: '',
                                          quantity: '',
                                          combined: '',
                                          part: '',
                                          partWidth: '',
                                          each: '',
                                          total: '',
                                          mark: '',
                                        })
                                        return (
                                          <Process key={processLog.processLogId + '_Process'} status={processLog.processStatus} title={processCustomizedContent.name}>
                                            {/* {checkEmptyNull(processCustomizedContent.num, (processIndex + 1).toString().padStart(2, '0'))} */}
                                            {(processIndex + 1)}
                                          </Process>
                                        )
                                      })}
                                    </ProcessSection>
                                    <ProcessSection>
                                      <Process status={'NO'} style={{ border: '1px solid var(--white)', minWidth: '60px', width: '60px' }}>CL</Process>
                                      {checkNullArray(CL, []).map((processLog, processIndex) => {
                                        const processCustomizedContent = checkNullParse(processLog.processCustomizedContent, {
                                          num: (processIndex + 1).toString().padStart(2, '0'),
                                          asmbly: '',
                                          profile: '',
                                          length: '',
                                          quantity: '',
                                          part: '',
                                          weight: '',
                                          name: '',
                                        })
                                        return (
                                          <Process key={processLog.processLogId + '_Process'} status={processLog.processStatus} title={processCustomizedContent.name}>
                                            {/* {checkEmptyNull(processCustomizedContent.num, (processIndex + 1).toString().padStart(2, '0'))} */}
                                            {(processIndex + 1)}
                                          </Process>
                                        )
                                      })}
                                    </ProcessSection>
                                  </ProcessCell>
                                );
                              })()}
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
                </tbody>
              </table>
            }
            ></TableSection>
          </WorkerScreenSection>

          <div style={{ display: 'flex', height: '60px', justifyContent: 'space-between', alignItems: 'center', gap: '16px', margin: '0 24px' }}>
            <PagingComponent
              page={workReducer.pageNumber}
              count={workReducer.totalSize}
              size={5}
              pageEvent={(page) => {
                console.log('page : ', page);
                dispatch(workActions_setPageNumber(page));
              }}
            />

            <ConnectStatus connectStatus={workReducer.connectStatus} onClick={() => { navigate(0); }}></ConnectStatus>
          </div>

          <DashboardButton onClick={clickHeader} data-name='on'>메뉴 {onHeader ? '닫기' : '열기'}</DashboardButton>

          {(workReducer.connectStatus === 'connect' && _workOrderStatusModal) ? (
            <GSWorkOrderStatusModal
              workOrderLogId={_eventWorkOrderLogId}
              workOrderStatus={_eventWorkOrderStatus}
              statusEvent={workOrderStatusEvent}
              open={_workOrderStatusModal}
              close={() => {
                setWorkOrderStatusModal(false);
                getWorkOrderLogList(workReducer.pageNumber - 1, 'all', workReducer.workOrderStatus);
              }}
            />
          ) : null}

          {_modalStatus_BL === true ? (
            <GSBLWorkerScreen
              content={_selectedWorkOrder}
              open={_modalStatus_BL}
              close={() => {
                setModalStatus_BL(false);
                getWorkOrderLogList(workReducer.pageNumber - 1, 'all', workReducer.workOrderStatus);
              }}
            ></GSBLWorkerScreen>
          ) : null}

          {_modalStatus_CP === true ? (
            <GSCPWorkerScreen
              content={_selectedWorkOrder}
              open={_modalStatus_CP}
              close={() => {
                setModalStatus_CP(false);
                getWorkOrderLogList(workReducer.pageNumber - 1, 'all', workReducer.workOrderStatus);
              }}
            ></GSCPWorkerScreen>
          ) : null}

          {_modalStatus_CL === true ? (
            <GSCLWorkerScreen
              content={_selectedWorkOrder}
              open={_modalStatus_CL}
              close={() => {
                setModalStatus_CL(false);
                getWorkOrderLogList(workReducer.pageNumber - 1, 'all', workReducer.workOrderStatus);
              }}
            ></GSCLWorkerScreen>
          ) : null}

          {_workEndStatus === true ? (
            <GSWorkEndModal
              content={_workEndOrder}
              open={_workEndStatus}
              close={() => {
                setWorkEndStatus(false);
                setWorkOrderStatusModal(false);
                getWorkOrderLogList(workReducer.pageNumber - 1, 'all', workReducer.workOrderStatus);
              }}
            ></GSWorkEndModal>
          ) : null}
        </>
      }
      bodyStyle={{
        display: 'grid',
        gridTemplateRows: 'calc(100% - 60px) 60px',
      }}
    />
  );
};

export default GSWorkerScreen;
