import styled from "styled-components";

export const LoadingComponentStyle = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 999;

  & div { // 닫기 버튼
    position: absolute;
    right: 20px;
    top: 20px;

    & div {background-color: var(--white);} // X 색상
  }
  
  & p { // 로딩 문구
    animation: opacityAni 1.5s infinite;
    color: var(--white);
    font-size: 1.5em;
    font-weight: 500;

    @keyframes opacityAni { 
      0% {opacity: 0;}
      50% {opacity: 1;}
      100% {opacity: 0;}
    }
  }
`;