import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import { calendarApi } from 'api/apis/calendarApi';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import TableSection from 'components/layouts/table/TableSection';

import UriSelectWorkOrderModal from 'pages/productionOrder/custom/company/uri/modal/UriSelectWorkOrderModal';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  max-height: 700px;
  max-width: 880px;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & form > div {background-color: unset;}
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const ScheduleTextContent = styled.div`
  column-gap: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const ScheduleText = styled.textarea`
  height: 80px;
  width: 100%;
`;

const FormDate = styled.input`
  border: 1px solid var(--gray-200);
  border-radius: 5px;
  box-sizing: border-box;
  height: 40px;
  padding: 5px;

  &:focus {border: 1px solid var(--gray-200);}
`;

const UpdateUriCalendarPlanModal = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const { content } = props;

  const [_onload, setOnload] = useState('unload');

  const noteData = checkNullParse(content.note, {
    type: 'Work',
    note1: '',
    note2: '',
    note3: null,
  });
  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    calenderId: content.calenderId,
    calenderDate: DateFormat(content.calenderDate),
    note: noteData,
    workOrderLogId: content.workOrderLogId || noteData?.note3,
  });

  const [_workOrderLogData, setWorkOrderLogData] = useState({});
  const [_workOrderLogModalStatus, setWorkOrderLogModalStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    getWorkOrderLog();

    setOnload('loaded');
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const getWorkOrderLog = async () => {
    const prevWorkOrderLogId = _formData.workOrderLogId;
    console.log('prevWorkOrderLogId:', prevWorkOrderLogId);
    if (!checkEmptyNull(prevWorkOrderLogId, false)) return;
    await workOrderLogApi.getWorkOrderLog(prevWorkOrderLogId).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.getWorkOrderLog : ', response);
      setWorkOrderLogData(() => { return response.data; });
    });
  };

  /* ====================================================================== #5 */
  const actUpdate = async () => {
    if (!checkEmptyNull(_formData.calenderDate, false)) return alert('날짜를 선택해주세요.');
    if (!checkEmptyNull(_formData?.note?.type, false)) return alert('일정유형을 선택해주세요.');

    const BodyToPost = { ..._formData };
    if (checkNullObject(_workOrderLogData, false)) {
      BodyToPost.note.note3 = _workOrderLogData.workOrderLogId;
      BodyToPost.workOrderLogId = _workOrderLogData.workOrderLogId;
    }
    BodyToPost.note = JSON.stringify(BodyToPost.note);
    await calendarApi.updateCalendar(content.calenderId, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('calendarApi.updateCalendar : ', response);
      alert('일정이 수정 되었습니다.');
      setTimeout(() => { props.close(moment(_formData.calenderDate).format('YYYY-MM-DD')) }, 1000);
    });
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>일정 수정</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <Form
                title={<>일정<br />수정</>}
                buttons={<></>}
                forms={
                  <>
                    <div className="formBox">
                      <h4>날짜</h4>
                      <FormDate
                        type="date"
                        name="calenderDate"
                        value={_formData.calenderDate}
                        onChange={(e) => {
                          setFormData((prev) => {
                            return { ...prev, calenderDate: e.target.value };
                          });
                        }}
                      />
                    </div>

                    <div className="formBox">
                      <h4>일정유형</h4>
                      <select
                        name="type"
                        value={checkEmptyNull(_formData?.note?.type, '')}
                        onChange={(e) => {
                          setFormData((prev) => {
                            const newData = { ...prev.note, type: e.target.value };
                            return { ...prev, note: newData };
                          });
                        }}
                      >
                        <option value="">일정유형</option>
                        <option value={'Work'}>작업</option>
                        <option value={'Glass'}>유리</option>
                        <option value={'Out'}>출고</option>
                      </select>
                    </div>

                    <div className="formBox w100">
                      <h4>일정내용</h4>
                      <ScheduleTextContent>
                        <ScheduleText
                          name="note1"
                          placeholder="일정내용을 입력해주세요."
                          value={checkEmptyNull(_formData?.note?.note1, '')}
                          onInput={(e) => {
                            setFormData((prev) => {
                              const newData = { ...prev.note, note1: e.target.value };
                              return { ...prev, note: newData };
                            });
                          }}
                        />
                        <ScheduleText
                          name="note2"
                          placeholder="일정내용을 입력해주세요."
                          value={checkEmptyNull(_formData?.note?.note2, '')}
                          onInput={(e) => {
                            setFormData((prev) => {
                              const newData = { ...prev.note, note2: e.target.value };
                              return { ...prev, note: newData };
                            });
                          }}
                        />
                      </ScheduleTextContent>
                    </div>

                    <div className="formBox" style={{ width: '100%' }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                        <h4>작업지시</h4>
                        <div
                          className="formButton"
                          style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                          onClick={() => { setWorkOrderLogModalStatus(true) }}
                        >
                          작업지시 선택
                        </div>
                      </div>

                      <TableSection content={
                        <table>
                          <thead>
                            <tr><th>거래처</th><th>주소</th></tr>
                          </thead>
                          <tbody>
                            <tr><td>{_workOrderLogData?.accountName}</td><td>{_workOrderLogData?.workPlace}</td></tr>
                          </tbody>
                        </table>
                      }></TableSection>
                    </div>
                  </>
                }
                formLayoutStyle={{ paddingInlineEnd: 'unset' }}
              />
            </ModalMain>

            <ModalFooter>
              <ModalButton onClick={actUpdate}>저장</ModalButton>
            </ModalFooter>
          </ModalSection>

          {_workOrderLogModalStatus === true && (
            <UriSelectWorkOrderModal
              setWorkOrderLogData={setWorkOrderLogData}
              open={_workOrderLogModalStatus}
              close={() => { setWorkOrderLogModalStatus(false); }}
            />
          )}
        </ModalBg>
      )}
    </>
  );
};

export default UpdateUriCalendarPlanModal;
