import React from 'react';
import styled from 'styled-components';

import Footer from 'components/footer/Footer';
import OperatorHeader from 'pages/operator/components/OperatorHeader';

const GridBody = styled.div`
  background-color: var(--white);
  display: grid;
  grid-template-rows: 60px calc(100% - 60px);
  height: 100svh;
  overflow: hidden;
  width: 100%;
`;

const OperatorBody = (props) => {
  return (
    <GridBody>
      <OperatorHeader />
      <div className='main-pagination'>{props.contents}</div>
      <Footer />
    </GridBody>
  );
};

export default OperatorBody;