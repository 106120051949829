import { AxiosInterceptor } from "api/core/consts";

export const getSensorSearch = async (sensorId) => {
  const getResponse = await AxiosInterceptor.get('/sensor/' + sensorId);
  return getResponse;
};

export const postSensorSearch = async (searchBody) => {
  const postResponse = await AxiosInterceptor.post('/sensor/searchList?sort=id,DESC', searchBody);
  return postResponse;
};

export const postSensorSave = async (searchBody) => {
  await AxiosInterceptor.post('/sensor', searchBody)
  // window.location.reload();
};

export const putSensorUpdate = async (sensorId, updateBody, reloadBody) => {
  await AxiosInterceptor.put('/sensor/' + sensorId, updateBody)
  // window.location.reload();
  // .then(() => postSensorSearch(reloadBody))
};

export const deleteSensor = async (sensorId, reloadBody) => {
  await AxiosInterceptor.delete('/sensor/' + sensorId)
  // window.location.reload();
  // .then(() => postSensorSearch(reloadBody))
};