import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { QRCodeSVG } from 'qrcode.react';

import { gsReducer_setBarcodeContent } from 'store/modules/actions/company/GSActions';
import { JuncontentsReducer_setProcurement } from 'store/modules/actions/dev/JunContentActions';

import { AXIOS_BASE_URL } from 'preferences/server/constants';
import { getCookie } from 'preferences/cookie/cookie';

import AllCheckBox from 'components/checkbox/AllCheckBox';
import CheckBox from 'components/checkbox/CheckBox';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  /* max-height: 300px; */
  /* max-width: 900px; */
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  /* height: calc(100% - 55px); */
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const QRCode = styled(QRCodeSVG)`
  cursor: pointer;
  height: 50px;
  width: 50px;
`;

const SearchSection = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-end;
  padding: 0px 10px;
  width: 100%;

  input {
    border: 1px solid var(--MainNavy);
    border-radius: 5px;
    box-sizing: border-box;
    height: 40px;
    min-width: 400px;
    padding: 10px;
    width: 40%;
  }
`;

const GSBarcodeSList = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { gsReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const setDateFormat = (thisDate) => {
    // console.log('setDateFormat : ', thisDate)
    if (
      thisDate !== undefined &&
      thisDate !== null &&
      typeof thisDate === 'object'
    ) {
      const newtDate = new Date(thisDate.join('-'));
      return moment(newtDate).format('YYYY-MM-DD');
    } else {
      return thisDate;
    }
  };

  const [_onload, setOnload] = useState('unload');

  const [_contract, setContract] = useState(props.content);
  const [_procurementList, setProcurementList] = useState([]);

  // const procurementData = props.content.procurementList[0];
  const [_formData, setFormData] = useState({});

  const [_updateProcurementList, setUpdateProcurementList] = useState([]);
  const [_deleteProcurementList, setDeleteProcurementList] = useState([]);
  console.log(
    '_updateProcurementList : ',
    _updateProcurementList,
    '_deleteProcurementList : ',
    _deleteProcurementList,
  );

  /* ====================================================================== #3 */
  useEffect(() => {
    getProcurementData();

    setOnload('loaded');

    return () => {};
  }, []);

  /* ====================================================================== #4 */
  const getProcurementData = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      contractId: _contract.contractId,
    };
    console.log('getProcurementData - BodyToPost : ', BodyToPost);

    await axios
      .post(AXIOS_BASE_URL + '/procurement/search', BodyToPost, {
        headers: {
          Authorization: getCookie('accessToken'),
        },
      })
      .then((response) => {
        if (response === undefined) return;
        console.log('getProcurementData - response : ', response);

        Finished_getProcurementData(response.data.content[0]);
      })
      .catch((error) => {
        console.log('getProcurementData - error : ', error);
      });
  };
  const Finished_getProcurementData = (Result) => {
    setFormData({
      companyId: userReducer.company.companyId,

      procurementStatus: Result.procurementStatus,

      procurementId: Result.procurementId,
      procurementCode: Result.procurementCode,
      procurementName: Result.procurementName,

      procurementDate: setDateFormat(Result.procurementDate),
      scheduledEndDate: setDateFormat(Result.scheduledEndDate),

      procurementPrice: Result.procurementPrice,

      procurementElementList: [],
      deleteProcurementElementList: [],
    });
    setProcurementList(Result.procurementElementList);
    dispatch(JuncontentsReducer_setProcurement(Result.procurementElementList));

    const updateProcurementList = Result.procurementElementList.map(
      (thisItem) => {
        return {
          accountId: thisItem.account.accountId,

          status: thisItem.status,
          procurementElementId: thisItem.procurementElementId,

          element: thisItem.element,
          elementId: thisItem.element.elementId,

          customizedContent:
            JSON.parse(thisItem.customizedContent) !== null
              ? JSON.parse(thisItem.customizedContent)
              : {
                  num: '',
                  weight: '',
                },

          price: thisItem.price,
          quantity: thisItem.quantity,
          storeQuantity: thisItem.storeQuantity,
        };
      },
    );
    setUpdateProcurementList(updateProcurementList);
  };

  /* ====================================================================== #5 */
  const actBarcodeProduct = async () => {
    const checkedProducts = document.querySelectorAll(
      'input[name="barcode_procurements"]:checked',
    );

    let BarcodeProducts = [];
    checkedProducts.forEach((thisItem) => {
      const thisItemKey = thisItem.closest('tr').dataset.key;
      const thisItemBarcode = thisItem.closest('tr').dataset.barcode;

      const thisFindIndex = _procurementList.findIndex(
        (thisIndex) => thisIndex.procurementElementId === Number(thisItemKey),
      );
      const thisFindItem = _procurementList[thisFindIndex];

      const thisData = {
        type: 'procurement',
        place: _contract,
        info: thisFindItem,
        barcode: thisItemBarcode,
      };

      BarcodeProducts.push(thisData);
    });

    if (checkedProducts.length === BarcodeProducts.length) {
      console.log('BarcodeProducts : ', BarcodeProducts);
      const updateData = [...gsReducer.barcodeContent, ...BarcodeProducts];

      dispatch(gsReducer_setBarcodeContent(updateData));
      props.close();
    }
  };

  // 바코드 복사
  const copyBarcode = (e) => {
    const thisBarcode = e.target.closest('td').dataset.barcode;
    console.log(thisBarcode);

    const textArea = document.createElement('textarea');
    document.body.appendChild(textArea);

    textArea.value = thisBarcode;
    textArea.select();
    textArea.setSelectionRange(0, 99999);
    document.execCommand('copy');
    textArea.setSelectionRange(0, 0);

    document.body.removeChild(textArea);
  };

  const actFilter = (e) => {
    const eventValue = e.target.value;

    document.querySelectorAll('#procurementListTbody tr').forEach((thisRow) => {
      let checkInclude = 0;
      thisRow.querySelectorAll('td').forEach((thisCell) => {
        if (thisCell.dataset.search !== undefined) {
          if (
            thisCell.dataset.search
              .toLowerCase()
              .includes(eventValue.toLowerCase()) === true
          ) {
            checkInclude++;
          }
        }
      });

      if (checkInclude > 0) {
        thisRow.style.display = '';
      } else {
        thisRow.style.display = 'none';
      }
    });
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>발주리스트</ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              <SearchSection>
                <input onKeyUp={actFilter} placeholder="Search..." />
              </SearchSection>
              <TableSection
                content={
                  <table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '50px', width: '50px' }}>
                          <AllCheckBox checkboxName={'barcode_procurements'} />
                        </th>
                        <th style={{ minWidth: '60px', width: '60px' }}>
                          순번
                        </th>
                        <th>재질</th>
                        <th>규격</th>
                        <th>단위</th>
                        <th>길이</th>
                        <th>수량</th>
                        <th>단중</th>
                        <th>중량</th>
                        <th>비고</th>
                        <th style={{ minWidth: '100px', width: '100px' }}>
                          바코드
                        </th>
                      </tr>
                    </thead>

                    <tbody id="procurementListTbody">
                      {_procurementList.length > 0 &&
                        _procurementList.map((thisItem) => {
                          let thisNum = null;
                          let thisWeight = null;
                          if (
                            thisItem.customizedContent !== undefined &&
                            thisItem.customizedContent !== null
                          ) {
                            const thisCustomizedContent = JSON.parse(
                              thisItem.customizedContent,
                            );
                            thisNum =
                              thisCustomizedContent.num !== undefined &&
                              thisCustomizedContent.num !== null &&
                              thisCustomizedContent.num !== ''
                                ? thisCustomizedContent.num.padStart(2, '0')
                                : (_procurementList.indexOf(thisItem) + 1)
                                    .toString()
                                    .padStart(2, '0');
                            thisWeight = thisCustomizedContent.weight;
                          } else {
                            thisNum = (_procurementList.indexOf(thisItem) + 1)
                              .toString()
                              .padStart(2, '0');
                          }

                          const thisElement = thisItem.element;

                          let thisLength = null;
                          let thisWeightByUnit = null;
                          let thisRemark = null;
                          const thisElementAttr =
                            thisItem.element.elementAttributeValueList;
                          thisElementAttr.forEach((thisAttr) => {
                            switch (
                              thisAttr.elementAttribute.elementAttributeName
                            ) {
                              case '길이':
                                thisLength = thisAttr.value;
                                break;

                              case '단중':
                                thisWeightByUnit = thisAttr.value;
                                break;

                              case '비고':
                                thisRemark = thisAttr.value;
                                break;

                              default:
                                return;
                            }
                          });

                          const thisBarcode = `Procurement-${_formData.procurementId}-${thisItem.procurementElementId}-${thisElement.elementId}`;

                          return (
                            <tr
                              key={thisNum + '_row'}
                              data-key={thisItem.procurementElementId}
                              data-barcode={thisBarcode}
                            >
                              <td style={{ minWidth: '50px', width: '50px' }}>
                                <CheckBox
                                  act={''}
                                  all={true}
                                  name={'barcode_procurements'}
                                />
                              </td>
                              <td
                                style={{ minWidth: '60px', width: '60px' }}
                                data-col="순번"
                              >
                                {thisNum}
                              </td>
                              <td
                                data-col="재질"
                                data-search={thisElement.elementName}
                              >
                                {thisElement.elementName}
                              </td>
                              <td
                                data-col="규격"
                                data-search={thisElement.elementCode}
                              >
                                {thisElement.elementCode}
                              </td>
                              <td
                                data-col="단위"
                                data-search={thisElement.elementUnit}
                              >
                                {thisElement.elementUnit}
                              </td>
                              <td data-col="길이" data-search={thisLength}>
                                {thisLength}
                              </td>
                              <td
                                data-col="수량"
                                data-search={thisItem.quantity}
                              >
                                {thisItem.quantity}
                              </td>
                              <td
                                data-col="단중"
                                data-search={thisWeightByUnit}
                              >
                                {thisWeightByUnit}
                              </td>
                              <td data-col="중량" data-search={thisWeight}>
                                {thisWeight}
                              </td>
                              <td data-col="비고" data-search={thisRemark}>
                                {thisRemark}
                              </td>
                              <td
                                style={{ minWidth: '100px', width: '100px' }}
                                data-col="바코드"
                                data-barcode={thisBarcode}
                              >
                                <QRCode
                                  value={thisBarcode}
                                  onClick={copyBarcode}
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                }
              />
            </ModalMain>
            <ModalFooter>
              <ModalButton onClick={actBarcodeProduct}>추가</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default GSBarcodeSList;
