import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import AllCheckBox from 'components/checkbox/AllCheckBox';
import CheckBox from 'components/checkbox/CheckBox';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const GSSelectProcessLogs = (props) => {
  const workOrderLog = props.content;
  const [_processLogList, setProcessLogList] = useState(workOrderLog.processLogList.filter((thisProcess) => thisProcess.processTypeName === props.processType));

  const [_checkedItem, setCheckedItem] = useState({});

  useEffect(() => {
    if (_processLogList.length < 1) {
      alert('해당 작업이 없습니다.');
      props.close();
    }
  }, [])

  const actSelect = () => {
    props.update(_checkedItem);
    props.close();
  };

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection type="list" style={{ zoom: '100%' }}>
            <ModalHeader>
              <ModalTitle>공정 선택</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              {props.processType === 'BL' ? <>
                <TableSection content={
                  <table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '50px', width: '50px' }}></th>
                        <th style={{ minWidth: '80px', width: '80px' }}>순번</th>
                        <th>부재번호</th>
                        <th>규격</th>
                        <th>단위</th>
                        <th>길이(m)</th>
                        <th>수량</th>
                        <th>기준<br />캠버값(mm)</th>
                        <th>실제<br />캠버값(mm)</th>
                        <th>비고</th>
                      </tr>
                    </thead>
                    <tbody>
                      {checkNullArray(_processLogList, []).map((processLog, index) => {
                        if (processLog.processTypeName !== 'BL') return null;
                        const processCustomizedContent = checkNullParse(processLog.processCustomizedContent, {
                          num: (index + 1).toString().padStart(2, '0'),
                          absentNumber: '',
                          standard: '',
                          unit: '',
                          length: '',
                          quantity: '',
                          defaultCamber: '',
                          realCamber: '',
                          remark: '',
                        })
                        return (
                          <tr key={index + '_BLRow'}>
                            <td style={{ minWidth: '50px', width: '50px' }}>
                              <input
                                type="checkBox"
                                name="processLogs"
                                onChange={(e) => {
                                  setCheckedItem(() => { return processLog; });
                                  document.querySelectorAll('input[name="processLogs"]').forEach(item => item.checked = false);
                                  e.target.checked = true;
                                }}
                                onClick={(e) => { e.stopPropagation() }}
                              />
                            </td>
                            <td style={{ minWidth: '80px', width: '80px' }} data-col="num" data-colname="순번">{processCustomizedContent.num}</td>
                            <td data-col="absentNumber" data-colname="부재번호">{processCustomizedContent.absentNumber}</td>
                            <td data-col="standard" data-colname="규격">{processCustomizedContent.standard}</td>
                            <td data-col="unit" data-colname="단위">{processCustomizedContent.unit}</td>
                            <td data-col="length" data-colname="길이">{processCustomizedContent.length}</td>
                            <td data-col="quantity" data-colname="수량">{processCustomizedContent.quantity}</td>
                            <td data-col="defaultCamber" data-colname="기준캠버값">{processCustomizedContent.defaultCamber}</td>
                            <td data-col="realCamber" data-colname="실제캠버값">{processCustomizedContent.realCamber}</td>
                            <td data-col="remark" data-colname="비고">{processCustomizedContent.remark}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                }></TableSection>
              </> : props.processType === 'CP' ? <>
                <TableSection content={
                  <table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '50px', width: '50px' }}></th>
                        <th style={{ minWidth: '80px', width: '80px' }}>순번</th>
                        <th>재질</th>
                        <th>규격</th>
                        <th>길이</th>
                        <th>수량</th>
                        <th>조합</th>
                        <th>잔재</th>
                        <th>부재길이</th>
                        <th>수량</th>
                        <th>총수량</th>
                        <th>Mark</th>
                      </tr>
                    </thead>
                    <tbody>
                      {checkNullArray(_processLogList, []).map((processLog, index) => {
                        if (processLog.processTypeName !== 'CP') return null;
                        const processCustomizedContent = checkNullParse(processLog.processCustomizedContent, {
                          num: (index + 1).toString().padStart(2, '0'),
                          name: '',
                          profile: '',
                          length: '',
                          quantity: '',
                          combined: '',
                          part: '',
                          partWidth: '',
                          each: '',
                          total: '',
                          mark: '',
                        })
                        return (
                          <tr key={index + '_CPRow'}>
                            <td style={{ minWidth: '50px', width: '50px' }}>
                              <input
                                type="checkBox"
                                name="processLogs"
                                onChange={(e) => {
                                  setCheckedItem(() => { return processLog; });
                                  document.querySelectorAll('input[name="processLogs"]').forEach(item => item.checked = false);
                                  e.target.checked = true;
                                }}
                                onClick={(e) => { e.stopPropagation() }}
                              />
                            </td>
                            <td style={{ minWidth: '80px', width: '80px' }} data-col="Num">{processCustomizedContent.num}</td>
                            <td data-col="Name" data-colname="재질">{processCustomizedContent.name}</td>
                            <td data-col="Profile" data-colname="규격">{processCustomizedContent.profile}</td>
                            <td data-col="Length" data-colname="길이">{processCustomizedContent.length}</td>
                            <td data-col="Quantity" data-colname="수량">{processCustomizedContent.quantity}</td>
                            <td data-col="Combined" data-colname="조합">{processCustomizedContent.combined}</td>
                            <td data-col="Part" data-colname="잔재">{processCustomizedContent.part}</td>
                            <td data-col="PartWidth" data-colname="부재길이">{processCustomizedContent.partWidth}</td>
                            <td data-col="Each" data-colname="수량">{processCustomizedContent.each}</td>
                            <td data-col="Total" data-colname="총수량">{processCustomizedContent.total}</td>
                            <td data-col="Mark">{processCustomizedContent.mark}</td>
                          </tr>
                        );
                      })}

                    </tbody>
                  </table>
                }></TableSection>
              </> : props.processType === 'CL' ? <>
                <TableSection content={
                  <table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '50px', width: '50px' }}></th>
                        <th style={{ minWidth: '80px', width: '80px' }}>순번</th>
                        <th>부재번호</th>
                        <th>규격</th>
                        <th>길이</th>
                        <th>수량</th>
                        <th>종류</th>
                        <th>개별중량</th>
                        <th>비고</th>
                      </tr>
                    </thead>
                    <tbody>
                      {checkNullArray(_processLogList, []).map((processLog, index) => {
                        if (processLog.processTypeName !== 'CL') return null;
                        const processCustomizedContent = checkNullParse(processLog.processCustomizedContent, {
                          num: (index + 1).toString().padStart(2, '0'),
                          asmbly: '',
                          profile: '',
                          length: '',
                          quantity: '',
                          part: '',
                          weight: '',
                          name: '',
                        })
                        return (
                          <tr key={index + '_CLRow'}>
                            <td style={{ minWidth: '50px', width: '50px' }}>
                              <input
                                type="checkBox"
                                name="processLogs"
                                onChange={(e) => {
                                  setCheckedItem(() => { return processLog; });
                                  document.querySelectorAll('input[name="processLogs"]').forEach(item => item.checked = false);
                                  e.target.checked = true;
                                }}
                                onClick={(e) => { e.stopPropagation() }}
                              />
                            </td>
                            <td style={{ minWidth: '80px', width: '80px' }} data-col="num" data-colname="순번">{processCustomizedContent.num}</td>
                            <td data-col="asmbly" data-colname="부재번호">{processCustomizedContent.asmbly}</td>
                            <td data-col="profile" data-colname="규격">{processCustomizedContent.profile}</td>
                            <td data-col="length" data-colname="길이">{processCustomizedContent.length}</td>
                            <td data-col="quantity" data-colname="수량">{processCustomizedContent.quantity}</td>
                            <td data-col="part" data-colname="종류">{processCustomizedContent.part}</td>
                            <td data-col="weight" data-colname="개별중량">{processCustomizedContent.weight}</td>
                            <td data-col="name" data-colname="비고">{processCustomizedContent.name}</td>
                          </tr>
                        );
                      })}

                    </tbody>
                  </table>
                }></TableSection>
              </> : null}
            </ModalMain>
            <ModalFooter>
              <ModalButton onClick={actSelect}>공정 선택</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};
export default GSSelectProcessLogs;
