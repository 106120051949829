import React, { Fragment, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { qualityApi } from 'api/apis/qualityApi';

import { checkEmptyNull, checkNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat';
import { StatusName } from 'components/status/StatusName';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import GuideText from 'components/buttons/GuideText';
import ProcessResultModal from 'pages/qualityControl/modal/ProcessResultModal';
import ShowInputElementStockLog from 'pages/stockHistory/inputElementStockLog/ShowInputElementStockLog';
import ShowInputElementStockResultLog from 'pages/stockHistory/inputElementStockLog/ShowInputElementStockResultLog';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 55px calc(100% - 55px);
  height: 85%;
  overflow: hidden;
  width: 95%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: flex-start;
  column-gap: 10px;
  display: flex;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}

  & .TableSection {
    width: 100%;
    &::-webkit-scrollbar {display: none;}
  }
`;
const ViewSection = styled.div`
  display: grid;
  grid-template-rows: 150px min-content;
  height: inherit;
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}
`;
const ViewTitle = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  .TableSection {
    height: 100%;
    
    table {height: 100%; table-layout: fixed; width: 100%;}
    td {font-size: 2em; font-weight: 600; max-width: unset; min-width: unset;}
    th {max-width: unset; min-width: unset;}
  }
`;
const SummaryProductionProcess = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100%;
  width: 100%;

  .TableSection {
    td, th {min-width: unset;}
    td.amountCell, th.amountCell {min-width: unset;}
  }
`;
const ElementStockLogButton = styled.td`
  background-color: var(--MainYellow);
  color: var(--white);
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
`;

const Detail = (props) => {
  /* ========================================================================= #1 */
  /* ========================================================================= #2 */
  const { open, close, workOrderLog } = props;

  const [_onload, setOnload] = useState('unload');

  // 작업지시
  const [_productionData, setProductionData] = useState({});
  const [_productionElementList, setProductionElementList] = useState({});

  // 공정 리스트
  const [_processLogList, setProcessLogList] = useState([]);

  // 선택한 공정 데이터
  const [_processLogData, setProcessLogData] = useState({});
  const [_processLogElementList, setProcessLogElementList] = useState([]);

  // 공정 결과 팝업
  const [_eventProcessResult, setEventProcessResult] = useState({});

  const [_inputElementStockLogModalStatus, setInputElementStockLogModalStatus] = useState(false);
  const [_inputElementStockLogs, setInputElementStockLogs] = useState([]);

  /* ========================================================================= #3 */
  useEffect(() => {
    getProductionProcessData();

    setOnload('loaded');
    return () => { };
  }, []);

  /* ========================================================================= #4 */
  const getProductionProcessData = async () => {
    await qualityApi.getQualityTracking(workOrderLog.workOrderLogId).then((response) => {
      if (response === undefined) return;
      console.log('qualityApi.getQualityTracking : ', response);

      setProductionData(response.data);
      setProcessLogList(response.data.processLogs);

      const totalElements = {};
      response.data.processLogs.forEach((thisItem) => {
        thisItem.elements.forEach((thisElement) => {
          if (totalElements[thisElement.id]) {
            const prevData = totalElements[thisElement.id];
            const returnData = { ...prevData };
            if (thisElement.type === 'Put') {
              returnData.baseAmount = new BigNumber(checkNull(prevData.baseAmount, 0)).plus(new BigNumber(checkNull(thisElement.baseAmount, 0))).toNumber();
              returnData.reInputAmount = new BigNumber(prevData.reInputAmount).plus(new BigNumber(thisElement.reInputAmount)).toNumber();
              returnData.reUsedAmount = new BigNumber(prevData.reUsedAmount).plus(new BigNumber(thisElement.reUsedAmount)).toNumber();
            } else {
              returnData.baseAmount = new BigNumber(checkNull(prevData.baseAmount, 0)).plus(new BigNumber(checkNull(thisElement.baseAmount, 0))).toNumber();
              returnData.makeAmount = new BigNumber(prevData.makeAmount).plus(new BigNumber(thisElement.makeAmount)).toNumber();
              returnData.failedAmount = new BigNumber(prevData.failedAmount).plus(new BigNumber(thisElement.failedAmount)).toNumber();
            }

            totalElements[thisElement.id] = returnData;
          } else {
            totalElements[thisElement.id] = thisElement;
          }
        });
      });
      setProductionElementList(totalElements);
    });
  };

  /* ========================================================================= #5 */
  const showInputElementStockLog = (processLogElement, processLog) => {
    setInputElementStockLogs(() => {
      return {
        processLog: processLog,
        processLogElement: processLogElement,
        inputElementStockLogs: checkNullArray(processLogElement.inputElementStockLogs, []),
      }
    })
    setTimeout(setInputElementStockLogModalStatus(true), 1000)
  }

  /* ========================================================================= #6 */

  return (
    <>
      {open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>원가확인 상세보기</ModalTitle>
              <CloseButton onClick={close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <ViewSection>
                <ViewTitle>
                  <TableSection content={
                    <table>
                      <thead>
                        <tr>
                          <th>작업코드</th>
                          <th>작업이름</th>
                          <th>총원가</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{workOrderLog.workOrderName}</td>
                          <td>{workOrderLog.workOrderCode}</td>
                          <td>{checkEmptyNull(workOrderLog.cost, 0).toLocaleString()}</td>
                        </tr>
                      </tbody>
                    </table>
                  }
                  ></TableSection>
                </ViewTitle>
                <SummaryProductionProcess>
                  <TableSection content={
                    <table>
                      <thead>
                        <tr>
                          <th colSpan={5} style={{ backgroundColor: 'var(--MainYellow)' }}>투입 자재/반제품</th>
                        </tr>
                        <tr>
                          <th>물품이름</th>
                          <th>믈품코드</th>
                          <th className="amountCell">총 투입량</th>
                          <th>원가</th>
                          <th style={{ minWidth: '120px', width: '120px' }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(_productionElementList).map((key, index) => {
                          const elementData = _productionElementList[key];
                          if (elementData.type !== 'Put') return null;
                          return (
                            <tr key={index + 'summary-put'}>
                              <td>{elementData.name}</td>
                              <td>{elementData.code}</td>
                              <td>{checkEmptyNull(elementData.baseAmount, 0).toLocaleString()}</td>
                              <td>{checkEmptyNull(elementData.cost, 0).toLocaleString()}</td>
                              <ElementStockLogButton style={{ minWidth: '120px', width: '120px' }} onClick={(e) => {e.preventDefault(); showInputElementStockLog(elementData, _processLogData);}}>
                                투입추적
                                <GuideText />
                              </ElementStockLogButton>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  }
                  />
                  <TableSection content={
                    <table>
                      <thead>
                        <tr>
                          <th colSpan={6} style={{ backgroundColor: 'var(--ThirdBlue)' }}>공정완료제품</th>
                        </tr>
                        <tr>
                          <th>물품이름</th>
                          <th>믈품코드</th>
                          <th className="amountCell">목표 생산량</th>
                          <th className="amountCell" style={{ backgroundColor: 'var(--MainBlue)' }}>총 생산량</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(_productionElementList).map((key, index) => {
                          const elementData = _productionElementList[key];
                          if (elementData.type !== 'Make') return null;
                          const completedAmount = BigNumber(checkNull(elementData.makeAmount, 0)).plus(BigNumber(checkNull(elementData.failedAmount, 0))).toNumber();
                          return (<tr key={index + 'summary-make'}>
                            <td>{elementData.name}</td>
                            <td>{elementData.code}</td>
                            <td>{checkEmptyNull(elementData.baseAmount, 0).toLocaleString()}</td>
                            <td>{checkEmptyNull(completedAmount, 0).toLocaleString()}</td>
                          </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  }
                  />
                </SummaryProductionProcess>
              </ViewSection>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}

      {_inputElementStockLogModalStatus === true  ? (
        <ShowInputElementStockLog
          content={_inputElementStockLogs}
          open={_inputElementStockLogModalStatus}
          close={() => { setInputElementStockLogModalStatus(false) }}
        ></ShowInputElementStockLog>
      ) : null}
    </>
  );
};

export default Detail;
