import { AxiosInterceptor } from "api/core/consts";

export const sensor = {
  sensorGroup: (body) => AxiosInterceptor.post('sensorGroup/searchList', body),

  getSensor: (id) => AxiosInterceptor.get('sensor/' + id),
  searchSensor: (body) => AxiosInterceptor.post('sensor/searchList?sort=id,DESC', body),
  createSensor: (body) => AxiosInterceptor.post('sensor', body),
  updateSensor: (id, body) => AxiosInterceptor.put('sensor/' + id, body),
  deleteSensor: (id) => AxiosInterceptor.delete('sensor/' + id),

  getSensorSetting: (id) => AxiosInterceptor.get('sensorSetting/' + id),
  createSensorSetting: (body) => AxiosInterceptor.post('sensorSetting', body),
  updateSensorSetting: (id, body) => AxiosInterceptor.put('sensorSetting/' + id, body),
  deleteSensorSetting: (id) => AxiosInterceptor.delete('sensorSetting/' + id),
};
