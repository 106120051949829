import styled from 'styled-components';

export const FileManagementComponent = styled.section`
  background-color: var(--white);
  grid-template-rows: 66px calc(100% - 66px); // 네비 / 메인 컨텐츠
  grid-row-start: 1;
  grid-row-end: 3;
  width: 100%;

  &>div { // 메인 컨텐츠
    display: grid;
    grid-template-columns: 300px calc(100% - 305px);
    gap: 5px;
    height: auto;
    overflow: hidden;
    width: 100%;

    & .file-content { // 파일 컨텐츠
      column-gap: 10px;
      display: grid;
      grid-template-columns: 60% calc(40% - 10px);
      height: 100%;
      overflow: hidden;
      width: 100%;

      & .file-list { // 파일 리스트
        display: grid;
        grid-template-rows: auto 50px;
        overflow: hidden;
        height: 100%;
        width: 100%;

        & .TableSection { // 파일 테이블
          height: 100%;
          & thead, & thead th {
            height: 50px;
            padding-block: unset;
          }
          & tbody tr:hover {cursor: pointer;}

          & td {
            min-width: unset;
            padding-block: 5px;
            word-break: keep-all;

            &.act-cell { // 작업자 설정
              position: relative;

              & button { // 설정 버튼
                min-width: unset;
                width: fit-content;
              }

              & .act-content { // 팀 변경/수정/삭제
                background-color: var(--Bg);
                border-radius: 8px;
                box-shadow: 0 2px 6px 0 rgba(196, 196, 196, 0.8);
                box-sizing: border-box;
                height: fit-content;
                overflow: hidden;
                position: absolute;
                top: calc(50% + 25px);
                right: calc(50% - 15px);
                width: fit-content;
                z-index: 1;

                & div {
                  align-items: center;
                  cursor: pointer;
                  display: flex;
                  font-size: 0.8em;
                  height: 50px;
                  justify-content: center;
                  width: 120px;

                  &:hover {opacity: 0.6;}
                  &:nth-of-type(2) { // 수정
                    border-block: 1px solid #eee;
                  }
                  &:nth-of-type(3) { // 삭제
                    color: var(--MainRed);
                  }
                }
              }
            }
          }
        }
      }

      & .preview { // 미리보기
        align-items: center;
        border: 1px solid #ddd;
        display: flex;
        height: 94%;
        justify-content: center;
        overflow: auto;
        position: relative;
        width: 96%;

        &::-webkit-scrollbar {height: 12px; width: 12px;}
        &::-webkit-scrollbar-track {background-color: var(--gray-100);}
        &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}

        & img { // 이미지
          cursor: pointer; 
          width: 100%;
        }
        & button { // 다운로드 버튼
          border-radius: unset;
          height: 100%;
          width: 100%;
          /* height: fit-content;
          width: fit-content; */
        }
        & .download-content { // 다운로드
          align-items: center;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          font-size: 2em;
          height: 100%;
          justify-content: center;
          width: 100%;

          &:hover {background-color: var(--Bg);}
        }

        & .stl-content { // stl
          height: 100%;
          position: relative;
          width: 100%;

          &>p {
            color: var(--MainBlue);
            font-size: 0.9em;
            position: absolute;
            top: 0px;
            text-align: center;
            width: 100%;
          }
        }
      }
    }
  }
`;