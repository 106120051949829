import { AxiosInterceptor } from "api/core/consts";

export const workOrderApi = {
  getWorkOrder: (workOrderId) => AxiosInterceptor.get('workOrder/' + workOrderId),
  searchWorkOrder: (paging, body) => AxiosInterceptor.post('workOrder/search' + paging, body),
  createWorkOrder: (body) => AxiosInterceptor.post('workOrder', body),
  copyWorkOrder: (body) => AxiosInterceptor.post('workOrder', body),
  updateWorkOrder: (workOrderId, body) => AxiosInterceptor.put('workOrder/' + workOrderId, body),
  deleteWorkOrder: (workOrderId) => AxiosInterceptor.delete('workOrder/' + workOrderId),
};
