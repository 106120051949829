import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  gap: 10px;
  height: 100%;
  padding: 20px 40px;
  width: 100%;
  &.contract {
    max-width: 1240px;
  }
`;

export const Title = styled.h3`
  align-items: center;
  display: flex;
  font-size: 30px;
  justify-content: space-between;
  margin-bottom: 20px;
  max-width: 1240px;
  height: fit-content;
`;

export const PaginationContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;

  button {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    padding: 8px 10px;
    transition: background-color 0.3s;
    width: fit-content;

    &:disabled {
      /* cursor: not-allowed; */
      cursor: default;
      color: var(--gray-500);
    }
  }
`;

export const ListTableContainer = styled.div`
  box-sizing: border-box;
  display: grid;
  gap: 20px;
  grid-template-columns: 3fr 1fr;
  flex-grow: 1;
  overflow: hidden;
  width: 100%;
`;
export const ListTableWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
`;

export const TableContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  height: 100%;
  /* overflow-y: auto; */
  width: 100%;
  &.column {
    flex-wrap: none;
    flex-direction: column !important;
  }
`;
export const TableRowContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const TableWrapper = styled.div`
  flex: ${(props) => props.flex || 1};
`;

export const Table = styled.table`
  border-collapse: collapse;
  height: fit-content;
  width: fit-content;
`;

export const Thead = styled.thead`
  background-color: #f5f5f5;
`;

export const Tbody = styled.tbody``;

export const Tr = styled.tr``;

export const StyledTr = styled.tr`
  background-color: ${(props) => {
    // return "#e1f8ff";
    if (props.isSelected) return '#EDF1F5';
    return 'transparent';
  }};

  color: ${(props) => (props.isSelected ? '#003675' : 'inherit')};
  cursor: pointer;
  font-weight: ${(props) => (props.isSelected ? 'bold' : 'normal')};
`;

export const Th = styled.th`
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  color: var(--black);
  font-size: 16px;
  font-weight: 500;
  padding-block: 9px;
  padding-inline: 18px;
  text-align: left;
  width: fit-content;
`;

export const Td = styled.td`
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  font-weight: 400;
  padding-block: 9px;
  padding-inline: 18px;
`;

export const AccountInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
