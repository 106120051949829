import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import moment from 'moment';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { manHour } from 'api/apis/manHour/manHour';

import { DateFormat } from 'components/format/DateFormat';
import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import DetailWorkOrderLog from 'pages/manHour/custom/company/gs/result/modal/DetailWorkOrderLog';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

import GSCreateManHour from 'pages/manHour/custom/company/gs/crud/GSCreateManHour';

const Section = styled.main`
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 66px calc(100% - 66px);

  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
  }
`;
const SchedulerSection = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: grid;  
  height: 100%;
  justify-content: center;
  overflow: hidden;
  padding: 20px 40px;
  width: 100%;

  &>div {
    box-sizing: border-box;
    overflow: scroll;
    width: 95svw;
  }

  .fc .fc-toolbar.fc-header-toolbar {box-sizing: border-box; padding: 16px 10px; margin: unset;}
  .fc .fc-button {padding: 8px 16px;}
  .fc .fc-button, .fc .fc-button-group, .fc .fc-button:disabled {font-size: 0.9em;}
  .fc .fc-view-harness-active > .fc-view,
  .gBRwOh .fc .fc-toolbar.fc-header-toolbar,
  .fc .fc-media-screen .fc-direction-ltr .fc-theme-standard {background-color: var(--white);}
  .fc-today-button:disabled {opacity: unset;}
  .fc-daygrid-block-event .fc-event-time, .fc-daygrid-block-event .fc-event-title {
    overflow: unset;
    max-width: 100%;
    white-space: pre-line;
    word-break: break-all;
  }
  .fc-daygrid-dot-event {align-items: flex-start;
    .fc-daygrid-event-dot {transform: translateY(7px);}
  }
  .fc-daygrid-dot-event .fc-event-title {
    font-weight: 400;
    line-height: 17px;
    overflow: unset;
    max-width: 89%;
    white-space: pre-line;
    word-break: break-all;
  }
  .fc-event-main {
    color: var(--Text);
  }

  button[title="공수추가"] {
    background-color: var(--MainBlue); border: none;
    &:hover {background-color: var(--MainBlue); opacity: 0.6;}
  }
  button[title="수정 활성화"] {
    background-color: var(--MainYellow); border: none;
    &:hover {background-color: var(--MainYellow); opacity: 0.6;}
  }
  button[title="수정 비활성화"] {
    background-color: var(--ThirdYellow); border: none;
    &:hover {background-color: var(--ThirdYellow); opacity: 0.6;}
  }
  button[title="삭제 활성화"] {
    background-color: var(--SeconRed); border: none;
    &:hover {background-color: var(--SeconRed); opacity: 0.6;}
  }
  button[title="삭제 비활성화"] {
    background-color: var(--ThirdRed); border: none;
    &:hover {background-color: var(--ThirdRed); opacity: 0.6;}
  }
`;

const GSManHourManagement = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const fullCalendar = useRef();

  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_manHourDataList, setManHourDataList] = useState([]);
  const [_manHourList, setManHourList] = useState([]);
  const [_dataUpdateStatus, setDataUpdateStatus] = useState(true);

  const [_dayOption, setDayOption] = useState('week');
  const [_date, setDate] = useState();
  const [_workOrderName, setWorkOrderName] = useState('');

  const [_modalStatus, setModalStatus] = useState(false);
  const [_createStatus, setCreateStatus] = useState(false);
  const [_updateStatus, setUpdateStatus] = useState(false);
  const [_deleteStatus, setDeleteStatus] = useState(false);

  const [_modalContent, setModalContent] = useState({});

  const [_currentStartDate, setCurrentStartDate] = useState();
  const [_currentEndDate, setCurrentEndDate] = useState();

  /* ========================================================================= #3*/
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('414') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => { }, [_dataUpdateStatus, _date, _manHourList]);
  useEffect(() => {}, [_currentStartDate, _currentEndDate]);
  useEffect(() => {}, [fullCalendar]);

  /* ========================================================================= #4*/
  const handlerMin = (min) => {
    const days = Math.floor(min / 60 / 24)
    const hours = Math.floor((min - (days * 60 * 24)) / 60);
    const mins = min - (days * 60 * 24) - (hours * 60);

    let returnData = '';
    if (days > 0) returnData += days + '일';
    if (hours > 0) returnData += hours + '시간';
    if (mins > 0) returnData += mins + '분';

    return returnData;
  }
  const mapWorkingTimeData = async(startStr, endStr, successCallback) => {
    const startDate = moment(startStr).format('YYYY-MM-DD');
    const endDate = moment(endStr).format('YYYY-MM-DD');

    setCurrentStartDate(() => {return startDate});
    setCurrentEndDate(() => {return endDate});
    setDataUpdateStatus(() => { return false; });

    const BodyToPost = { companyId: userReducer.company.companyId };
    BodyToPost.startDate = startDate;
    BodyToPost.endDate = endDate;
    let searchData = `companyId=${userReducer.company.companyId}&startDate=${startDate}&endDate=${endDate}`;
    if(checkEmptyNull(_workOrderName, false)) searchData += `&workOrderLogName=${_workOrderName}`;
    const response = await manHour.groupingWork(searchData);

    const workOrderLogs = {};
    const results = {};
    checkNullArray(response?.data, []).forEach(data => { // workOrderLog
      const workOrderLogId = data?.workOrderLog.workOrderLogId;
      workOrderLogs[workOrderLogId] = data?.workOrderLog;
      data?.userGroups.forEach(userGroup => { // userGroups
        userGroup?.workingTimes.forEach(workingTime => { // workingTimes
          const start = DateFormat(checkEmptyNull(workingTime.occurrenceDate, checkEmptyNull(workingTime.startTime, '')));
          // const end = DateFormat(checkEmptyNull(workingTime.occurrenceDate, checkEmptyNull(workingTime.endTime, '')));

          const pushData = {
            name: userGroup.worker.name,
            value: checkEmptyNull(workingTime.value, handlerMin(workingTime.diffMinutes))
          };
          if(!checkNullObject(results[start], false)) {results[start] = {};}
          if(!checkNullArray(results[start][workOrderLogId], false)) {results[start][workOrderLogId] = [];}
          results[start][workOrderLogId].push(pushData);
        })
      })
    })

    const result = Object.keys(results).map(resultDate => {
      // const color_r = Math.floor(Math.random() * 127 + 128).toString(16);
      // const color_g = Math.floor(Math.random() * 127 + 128).toString(16);
      // const color_b = Math.floor(Math.random() * 127 + 128).toString(16);

      const pushData = {
        id: resultDate,
        start: resultDate,
        color: '#edf2f9',
        // color: '#d9e4f2',
        // color: '#' + color_r + color_g + color_b + 99,
      }

      const returnData = Object.keys(results[resultDate]).map(workOrderLogId => {
        const resultWorkOrderLog = workOrderLogs[workOrderLogId];
        const resultUserGroups = results[resultDate][workOrderLogId];
        const userGroups = resultUserGroups.map(userGroup => {return `${userGroup.name}(${checkEmptyNull(userGroup.value, 0)})`;});
        const userGroupsText = userGroups.join(' / ');
        const title = `${checkEmptyNull(resultWorkOrderLog.workOrderCode, '')}
${checkEmptyNull(resultWorkOrderLog.workOrderName, '')}
${userGroupsText}`;
        pushData.id = resultDate + '_' + workOrderLogId;
        pushData.title = title;

        // returnData.push(pushData);
        return pushData;
      })

      return returnData;
    });
    const callbackData = result.reduce((prev, next) => {return prev.concat(next)}, []);
    console.log('callbackData : ', callbackData);

    successCallback(checkNullArray(callbackData, []));
    setManHourList(() => { return checkNullArray(callbackData, []) });
    setManHourDataList(() => { return checkNullArray(response?.data, []) });
  }

  const getManHourList = async (fetchInfo, successCallback) => {
    const startDate = moment(fetchInfo.startStr).format('YYYY-MM-DD');
    const endDate = moment(fetchInfo.endStr).format('YYYY-MM-DD');

    if(_currentStartDate !== startDate && _currentEndDate !== endDate) {
      mapWorkingTimeData(startDate, endDate, successCallback);
    } else if(_dataUpdateStatus && (_currentStartDate === startDate && _currentEndDate === endDate)) {
      mapWorkingTimeData(startDate, endDate, successCallback);
    } else {
      return successCallback(_manHourList);
    }
  }

  /* ========================================================================= #5*/
  const actFetch = (date) => {
    console.log('fetchDate : ', date || '');
    setDataUpdateStatus(() => { return true; });
    const fullCalendarApi = fullCalendar.current.getApi();
    fullCalendarApi.gotoDate(moment(date || '').format('YYYY-MM-DD'));
  }
  const actSearch = (e) => {
    e.preventDefault();
    const date = moment(_date).startOf('day').format('YYYY-MM-DD');
    actFetch(date || '');
  }
  const actReset = (e) => {
    e.preventDefault();
    setWorkOrderName(() => { return ''; });
    actFetch();
  }

  const actShow = (info) => {
    const searchDate = info.event.id.split('_')[0];
    const workOrderLogId = info.event.id.split('_')[1];
    setModalContent(() => { return {
      searchDate: searchDate,
      workOrderLogId: workOrderLogId,
    } });
    setTimeout(setModalStatus(true), 1000);
  }

  /* ========================================================================= #6 */
  window.oncontextmenu = () => { return false };

  return (
    <Grid2Body contents={
      <>
        <Section className="Main">
          <NavBar
            title={
              <div className='Title'>
                <NavTitle menuCode={'414'} />
                <Link to={'/production/manHour/gs/result'} style={{ color: '#ccc' }}>공수실적</Link>
              </div>
            }
            buttons={
              <>
                <div className='SearchSection'>
                  <div className='ContentCBox'>
                    <select
                      className='SearchOption'
                      name='dayOptions'
                      value={_dayOption}
                      onChange={e => { e.preventDefault(); setDayOption(e.target.value) }}
                    >
                      {[
                        { name: '월별', value: 'month' },
                        { name: '주별', value: 'week' },
                      ].map((item, index) => {
                        return (<option key={index + '_dayOptions'} value={item.value}>{item.name}</option>)
                      })}
                    </select>

                    <input type={_dayOption} className='SelectDate' value={_date} onChange={(e) => { e.preventDefault(); setDate(e.target.value) }} />
                  </div>

                  <div className='ContentCBox'>
                    <input type='text' className='SearchBar' placeholder='작업이름' value={_workOrderName} onChange={(e) => { e.preventDefault(); setWorkOrderName(e.target.value) }} />
                  </div>

                  <button className='btn-search' onClick={actSearch}>검색</button>
                  <button className='btn-reset' onClick={actReset}>초기화</button>
                </div>
              </>
            }
            nav={''}
          />

          <SchedulerSection>
            <FullCalendar
              ref={fullCalendar}
              plugins={[dayGridPlugin]}
              dayHeaders={true}
              initialView="dayGridWeek"
              locale="ko"
              firstDay="1"
              buttonText={{ day: '일별', week: '주별', month: '월별', today: '오늘' }}
              events={(fetchInfo, successCallback, failureCallback) => { getManHourList(fetchInfo, successCallback, failureCallback); }}
              displayEventTime={false}
              customButtons={{
                create: { text: '공수추가', click: function () { setCreateStatus(true); setUpdateStatus(false); setDeleteStatus(false); } },
                activeUpdate: { text: '수정 활성화', click: function () { setUpdateStatus(true); setDeleteStatus(false); } },
                disableUpdate: { text: '수정 비활성화', click: function () { setUpdateStatus(false); } },
                activeDelete: { text: '삭제 활성화', click: function () { setUpdateStatus(false); setDeleteStatus(true); } },
                disableDelete: { text: '삭제 비활성화', click: function () { setDeleteStatus(false); } },
                customPrev: { text: '<', click: function () { setDataUpdateStatus(() => { return true; }); fullCalendar.current.getApi().prev(); } },
                customToday: { text: '오늘', click: function () { setDataUpdateStatus(() => { return true; }); fullCalendar.current.getApi().today(); } },
                customNext: { text: '>', click: function () { setDataUpdateStatus(() => { return true; }); fullCalendar.current.getApi().next(); } }
              }}
              headerToolbar={{
                left: 'dayGridWeek dayGridMonth',
                center: 'title',
                right: (() => {
                  if (_authority.indexOf('414-2') !== -1) {
                    return 'create customPrev,customToday,customNext';
                  } else {
                    return 'customPrev,customToday,customNext';
                  }
                })()
              }}
              eventClick={(e) => {
                actShow(e);
              }} // 이벤트 클릭 이벤트
              // eventDisplay="list-item" // 이벤트 표기
              eventBackgroundColor="var(--SeconYellow)" // 오늘 날짜 색상
              height="100%" // 캘린더 전체 높이
            />
          </SchedulerSection>
        </Section>

        {/* 상세보기 */}
        {_modalStatus === true ? (
          <DetailWorkOrderLog
            searchContent={_modalContent}
            update={() => {actFetch(_modalContent.searchDate);}}
            open={_modalStatus}
            close={() => { setModalStatus(false); setModalContent(() => { return {} }); }}
          />
        ) : null}

        {/* 공수추가 */}
        {_createStatus === true ? (
          <GSCreateManHour
            open={_createStatus}
            update={(date) => {
              actFetch(date);
              setCreateStatus(false);
            }}
            close={() => {setCreateStatus(false);}}
          />
        ) : null}
      </>
    }
    />
  );
};

export default GSManHourManagement;
