import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { accountApi } from 'api/apis/accountApi';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  /* max-height: 300px; */
  /* max-width: 400px; */
  overflow: hidden;
  width: 85%;

  & form > div {
    background-color: unset;
  }
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  label {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 60px;
    justify-content: flex-end;
    padding: 0px 20px;
    width: 100%;
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const CreateAccount = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const { accountTypeData, accountAttribute } = props;

  const [_onload, setOnload] = useState('unload');

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    accountTypeId: accountTypeData.accountTypeId,

    accountCode: '', // 수요기관코드
    businessNumber: '', // 사업자등록번호
    accountName: '', // 회사명,
    regionName: '', // 지역명,
    address: '', // 주소,
    detailAddress: '', // 상세주소,
    zip: '', // 우편번호,
    email: '', // 이메일,
    telNumber: '', // 전화번호,
    faxNumber: '', // 팩스번호,

    accountAttributeValueList: [],
  });

  const [_accountAttrValues, setAccountAttrValues] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    setOnload('loaded');
    return () => {};
  }, []);

  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  /* 저장 버튼 누를때 */
  const actCreate = async (e) => {
    e.preventDefault();
    if (_formData.accountName === '') return alert('회사명을 입력해주세요');

    const attrValues = Object.keys(_accountAttrValues).map((thisKey) => {
      const returnData = {
        accountAttributeId: !isNaN(Number(thisKey)) && Number(thisKey),
        value: _accountAttrValues[thisKey],
      };

      return returnData;
    });
    const BodyToPost = {
      ..._formData,
      accountAttributeValueList: attrValues,
    };
    console.log('actCreate - BodyToPost : ', BodyToPost);

    await accountApi.createAccount(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('accountApi.createAccount : ', response);

      if (
        response.data.message !== undefined &&
        response.data.message !== null &&
        response.data.message.length > 0
      ) {
        alert(response.data.message);
      } else {
        alert('거래처를 추가했습니다');
        props.close();
      }
    });
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>거래처 추가</ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              <Form
                title={
                  <>
                    {accountTypeData.accountTypeName}
                    <br />
                    추가
                  </>
                }
                buttons={<></>}
                forms={
                  <>
                    <div className="formBox">
                      <h4>수요기관코드</h4>
                      <input
                        type="text"
                        name="accountCode"
                        placeholder="* 수요기관코드..."
                        value={_formData.accountCode}
                        onInput={(e) => {
                          setFormData((prev) => {
                            return { ...prev, accountCode: e.target.value };
                          });
                        }}
                      />
                    </div>

                    <div className="formBox">
                      <h4>사업자등록번호</h4>
                      <input
                        type="text"
                        name="businessNumber"
                        placeholder="* 사업자등록번호..."
                        value={_formData.businessNumber}
                        onInput={(e) => {
                          setFormData((prev) => {
                            return { ...prev, businessNumber: e.target.value };
                          });
                        }}
                      />
                    </div>

                    <div className="formBox">
                      <h4>회사명</h4>
                      <input
                        type="text"
                        name="accountName"
                        data-required="required"
                        placeholder="* 회사명..."
                        value={_formData.accountName}
                        onInput={(e) => {
                          setFormData((prev) => {
                            return { ...prev, accountName: e.target.value };
                          });
                        }}
                      />
                    </div>

                    <div className="formBox">
                      <h4>지역명</h4>
                      <input
                        type="text"
                        name="regionName"
                        placeholder="* 지역명..."
                        value={_formData.regionName}
                        onInput={(e) => {
                          setFormData((prev) => {
                            return { ...prev, regionName: e.target.value };
                          });
                        }}
                      />
                    </div>

                    <div className="formBox">
                      <h4>주소</h4>
                      <input
                        type="text"
                        name="address"
                        placeholder="* 주소..."
                        value={_formData.address}
                        onInput={(e) => {
                          setFormData((prev) => {
                            return { ...prev, address: e.target.value };
                          });
                        }}
                      />
                    </div>

                    <div className="formBox">
                      <h4>상세주소</h4>
                      <input
                        type="text"
                        name="detailAddress"
                        placeholder="* 상세주소..."
                        value={_formData.detailAddress}
                        onInput={(e) => {
                          setFormData((prev) => {
                            return { ...prev, detailAddress: e.target.value };
                          });
                        }}
                      />
                    </div>

                    <div className="formBox">
                      <h4>우편번호</h4>
                      <input
                        type="text"
                        name="zip"
                        placeholder="* 우편번호..."
                        value={_formData.zip}
                        onInput={(e) => {
                          setFormData((prev) => {
                            return { ...prev, zip: e.target.value };
                          });
                        }}
                      />
                    </div>

                    <div className="formBox">
                      <h4>이메일</h4>
                      <input
                        type="text"
                        name="email"
                        placeholder="* 이메일..."
                        value={_formData.email}
                        onInput={(e) => {
                          setFormData((prev) => {
                            return { ...prev, email: e.target.value };
                          });
                        }}
                      />
                    </div>

                    <div className="formBox">
                      <h4>전화번호</h4>
                      <input
                        type="text"
                        name="telNumber"
                        placeholder="* 전화번호..."
                        value={_formData.telNumber}
                        onInput={(e) => {
                          setFormData((prev) => {
                            return { ...prev, telNumber: e.target.value };
                          });
                        }}
                      />
                    </div>

                    <div className="formBox">
                      <h4>팩스번호</h4>
                      <input
                        type="text"
                        name="faxNumber"
                        placeholder="* 팩스번호..."
                        value={_formData.faxNumber}
                        onInput={(e) => {
                          setFormData((prev) => {
                            return { ...prev, faxNumber: e.target.value };
                          });
                        }}
                      />
                    </div>

                    {accountAttribute !== undefined &&
                      accountAttribute !== null &&
                      accountAttribute.length > 0 &&
                      accountAttribute.map((thisItem) => {
                        return (
                          <div
                            key={thisItem.accountAttributeId + '_accountAttr'}
                            className="formBox"
                          >
                            <h4>{thisItem.accountAttributeName}</h4>
                            <input
                              type="text"
                              name="value"
                              placeholder={
                                thisItem.accountAttributeName + '...'
                              }
                              value={
                                _accountAttrValues[
                                  thisItem.accountAttributeId
                                ] || ''
                              }
                              onInput={(e) => {
                                setAccountAttrValues((prev) => {
                                  return {
                                    ...prev,
                                    [thisItem.accountAttributeId]:
                                      e.target.value,
                                  };
                                });
                              }}
                            />
                          </div>
                        );
                      })}
                  </>
                }
                formStyle={{
                  height: '100%',
                }}
                formLayoutStyle={{
                  padding: '0px 30px',
                  width: '95%',
                }}
                formSectionStyle={{
                  paddingTop: '10px',
                  width: '100%',
                }}
              />
            </ModalMain>

            <ModalFooter>
              <ModalButton onClick={actCreate}>추가</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default CreateAccount;
