import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { accountActions_setAccountType, accountActions_setBookmark, accountActions_setPageNumber, accountActions_setReset, accountActions_setSearchOption, accountActions_setSearchText, accountActions_setTotalSize } from 'store/modules/actions/common/accountActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { accountTypeApi } from 'api/apis/accountTypeApi';
import { accountAttributeApi } from 'api/apis/accountAttributeApi';
import { accountApi } from 'api/apis/accountApi';
import { bookmark } from 'api/apis/bookmark/bookmark';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { LoadingMsg } from 'components/loading/LoadingMsg';

import AccountUploadModal from 'components/excel/upload/AccountUploadModal';
import Bookmark from 'components/icons/Bookmark';
import ExcelDownloadModal from 'components/excel/ExcelDownloadModal';
import Grid3Body from 'components/layouts/body/Grid3Body';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const AccountManagement = () => {
  /* ========================================================================= #1들 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accountReducer, pageReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_accountAttrList, setAccountAttrList] = useState([]);
  const [_accountList, setAccountList] = useState([]);

  const [_accountTypeList, setAccountTypeList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_excelDownloadModalStatus, setExcelDownloadModalStatus] = useState(false);
  const [_accountUploadModalStatus, setAccountUploadModalStatus] = useState(false);

  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('104') === true,
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    (async () => {
      await getAccountTypes();

      if (accountReducer.accountType !== 0) {
        await getAccountAttrs(accountReducer.accountType);
        await getAccountList(accountReducer.pageNumber - 1, accountReducer.accountType);
      }
    })()

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getAccountList(accountReducer.pageNumber - 1, accountReducer.accountType);
    }
    return () => { };
  }, [accountReducer.pageNumber]);

  useEffect(() => {
    if (_onload === 'loaded') {
      dispatch(accountActions_setPageNumber(1));
      getAccountList(0, accountReducer.accountType);
    }
    return () => { };
  }, [accountReducer.bookmark]);

  useEffect(() => { }, [accountReducer]);

  /* ========================================================================= #4 */
  const getAccountTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await accountTypeApi.searchAccountType(BodyToPost).then(async (response) => {
      if (response === undefined) return;
      console.log('accountTypeApi.searchAccountType : ', response);

      setAccountTypeList(() => { return response.data });

      if (checkNullArray(response.data, false) && accountReducer.accountType === 0) {
        const firstType = response.data[0].accountTypeId;
        dispatch(accountActions_setAccountType(firstType));
        await getAccountAttrs(firstType);
        await getAccountList(accountReducer.pageNumber - 1, firstType);
      }
    });
  };

  const getAccountAttrs = async (accountType) => {
    console.log('accountTypeName : ', accountType);
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!checkEmptyNull(accountType, false) || accountType !== 'all') {
      BodyToPost.accountTypeId = Number(accountType);
    }
    console.log('getAccountAttrs - BodyToPost : ', BodyToPost);

    await accountAttributeApi.searchAccountAttribute(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('accountAttributeApi.searchAccountAttribute : ', response);
      setAccountAttrList(() => { return response.data; });
    });
  };

  const getAccountList = async (page, accountType, clear) => {
    let paging = `?page=${page}&size=20`;
    if (accountReducer.bookmark) paging += '&sort=bookMark,DESC&sort=id,DESC';
    else paging += '&sort=id,DESC';

    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && checkEmptyNull(accountType, false) && accountType !== 'all') {
      BodyToPost.accountTypeId = Number(accountType);
    }

    if (!clear && checkEmptyNull(accountReducer.searchOption, false) && checkEmptyNull(accountReducer.searchText, false)) {
      BodyToPost[accountReducer.searchOption] = accountReducer.searchText;
    }
    console.log('getAccountList - BodyToPost : ', BodyToPost);

    await accountApi.searchAccount(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('accountApi.searchAccount : ', response);
      setAccountList(() => { return response.data.content });
      dispatch(accountActions_setTotalSize(response.data.totalElements))
    });
  };

  /* ========================================================================= #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(id);
    setCheckedItem(() => { return checkedItem });
  };

  const handleBookmark = async (account, index) => {
    await bookmark.accountBookmark(account.accountId).then(response => {
      if (response === undefined) return;
      console.log('bookmark.accountBookmark : ', response);

      getAccountList(accountReducer.pageNumber - 1, accountReducer.accountType);
    })
  }

  /* 검색 */
  const actSearch = async () => {
    dispatch(accountActions_setPageNumber(1));
    await getAccountList(0, accountReducer.accountType);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(accountActions_setReset());
    dispatch(accountActions_setPageNumber(1));
    await getAccountAttrs(accountReducer.accountType);
    await getAccountList(0, accountReducer.accountType, true);
  };

  // 엑셀 팝업
  const showExcelDownloadModal = () => { setExcelDownloadModalStatus(true) };
  const showAccountUploadModal = () => { setAccountUploadModalStatus(true) };

  /* 수정 */
  const actUpdate = () => {
    const checkedElementList = Array.from(_checkedItem);
    if (checkedElementList.length < 1) return alert('수정할 거래처를 선택해주세요.');
    else if (checkedElementList.length > 1) return alert('다중 선택되어 있습니다.');

    const checkedContentIndex = _accountList.findIndex((thisItem) => thisItem.accountId === checkedElementList[0]);
    if (checkedContentIndex === -1) return;
    const checkedContent = _accountList[checkedContentIndex];
    console.log('checkedContents : ', checkedContent);

    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({
        updateContent: JSON.stringify(checkedContent),
      })}`,
      replace: true,
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const checkedElementList = Array.from(_checkedItem);
    if (checkedElementList.length < 1) return alert('삭제할 거래처를 선택해주세요.');
    else if (checkedElementList.length > 1) return alert('다중 선택되어 있습니다.');

    const checkedContentIndex = _accountList.findIndex((thisItem) => thisItem.accountId === checkedElementList[0],);
    if (checkedContentIndex === -1) return;
    const checkedContent = _accountList[checkedContentIndex];
    console.log('checkedContents : ', checkedContent);

    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({
        deleteContent: JSON.stringify(checkedContent),
      })}`,
      replace: true,
    });
  };

  return (
    <Grid3Body contents={
      <>
        <main className="Main">
          <NavBar
            title={
              <div className="SearchSection">
                <p>거래처 설정</p>
              </div>
            }
            subNavTabBtnLink='/system/types'
            subNavTabBtn='거래처 유형 정의'
            buttons={
              <>
                {_authority.indexOf('104-2') !== -1 ? (
                  <>
                    <select
                      className="SelectType wIn"
                      value={accountReducer.accountType}
                      onChange={(e) => {
                        const accountType = e.target.value;
                        dispatch(accountActions_setAccountType(accountType));
                        dispatch(accountActions_setPageNumber(1));
                        getAccountAttrs(accountType);
                        getAccountList(0, accountType);
                      }}
                    >
                      {_accountTypeList.map((thisType, index) => {
                        if (thisType.accountTypeName === userReducer.company.companyName) return null;
                        return (
                          <option key={index + '_accountType'} value={thisType.accountTypeId}>
                            {thisType.accountTypeName}
                          </option>
                        );
                      })}
                      <option value="all">전체</option>
                    </select>
                    <button
                      className='btn-add'
                      onClick={() => {
                        if (!checkEmptyNull(accountReducer.accountType, false) || accountReducer.accountType === 'all') return alert('거래처 유형을 선택해주세요.');
                        const typeData = _accountTypeList.find((thisItem) => {
                          if (thisItem.accountTypeId === Number(accountReducer.accountType)) return true;
                          else return false;
                        });
                        if (typeData === undefined) return;

                        navigate({
                          pathname: pageReducer.currentPage + '/create',
                          search: `?${createSearchParams(typeData)}`,
                          replace: true,
                        });
                      }}
                    >
                      추가
                    </button>
                    <button className='btn-edit' onClick={actUpdate}>수정</button>
                    <button className='btn-delete' onClick={actDelete}>삭제</button>

                    <button className='btn-upload' onClick={showAccountUploadModal}>엑셀 업로드</button>
                  </>
                ) : null}
                <button className='btn-download' onClick={showExcelDownloadModal}>다운로드</button>
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="ContentCBox">
                  <div className="ContentCBox">
                    <select
                      className="SearchOption"
                      value={accountReducer.searchOption}
                      onChange={(e) => { dispatch(accountActions_setSearchOption(e.target.value)) }}
                    >
                      <option value="">검색옵션</option>
                      <option value="accountCode">수요기관코드</option>
                      <option value="businessNumber">사업자등록번호</option>
                      <option value="accountName">회사명</option>
                    </select>
                    <input
                      className="SearchBar"
                      placeholder="Search..."
                      value={accountReducer.searchText}
                      onInput={(e) => { dispatch(accountActions_setSearchText(e.target.value)) }}
                    />
                  </div>

                  <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                  <div className="ResetButton" onClick={actReset}>초기화</div>
                </div>
              </>
            }
          />

          <TableSection
            sectionStyle={{ height: 'calc(100% - 66px)' }}
            content={
              <table>
                <thead>
                  <tr>
                    {_authority.indexOf('104-2') !== -1 ? (<th style={{ minWidth: 'unset', width: '50px' }}></th>) : null}
                    <th style={{ minWidth: '50px', width: '50px' }}>
                      <Bookmark
                        bookmark={accountReducer.bookmark}
                        clickEvent={() => {
                          dispatch(accountActions_setBookmark(!accountReducer.bookmark))
                        }}
                      />
                    </th>
                    <th>수요기관코드</th>
                    <th>사업자등록번호</th>
                    <th>회사명</th>
                    <th>지역명</th>
                    <th>주소</th>
                    <th>상세주소</th>
                    <th>우편번호</th>

                    <th>담당자</th>
                    <th>전화번호/휴대폰</th>
                    <th>팩스번호</th>
                    <th>이메일</th>

                    {_accountAttrList.map((thisItem, index) => {
                      return (<th key={index + '_accountAttrs'}>{thisItem.accountAttributeName}</th>);
                    })}
                  </tr>
                </thead>
                <tbody>
                  {!checkNullArray(_accountList, false) ? LoadingMsg() :
                    checkNullArray(_accountList, []).map((account, index) => {
                      if (account.accountName === userReducer.company.companyName) return null;
                      return (<tr key={index + '_accounts'}>
                        {_authority.indexOf('104-2') !== -1 ? (
                          <td style={{ minWidth: '50px', width: '50px' }}>
                            <input
                              type="checkBox"
                              name="accounts"
                              data-key={account.accountId}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                handleCheckedItem(account.accountId, isChecked);

                                if (isChecked) e.target.checked = true;
                                else e.target.checked = false;
                              }}
                              onClick={(e) => { e.stopPropagation() }}
                            />
                          </td>
                        ) : null}
                        <td style={{ minWidth: '50px', width: '50px' }}>
                          <Bookmark
                            bookmark={checkEmptyNull(account.bookMark, false)}
                            clickEvent={() => { handleBookmark(account, index) }}
                          />
                        </td>
                        <td>{account.accountCode}</td>
                        <td>{account.businessNumber}</td>
                        <td>{account.accountName}</td>
                        <td>{account.regionName}</td>
                        <td>{account.address}</td>
                        <td>{account.detailAddress}</td>
                        <td>{account.zip}</td>

                        <td>{account.managerName}</td>
                        <td>{account.telNumber}<br />{account.phoneNumber}</td>
                        <td>{account.faxNumber}</td>
                        <td>{account.email}</td>
                        {checkNullArray(account.accountAttributeValueList, false) &&
                          checkNullArray(_accountAttrList, []).map((thisAttribute, attrIndex) => {
                            const thisAttributeValue = account.accountAttributeValueList.findIndex((thisIndex) => thisIndex.accountAttributeId === thisAttribute.accountAttributeId);
                            if (thisAttributeValue !== -1) {
                              return (<td key={attrIndex + '_accountAttrsValue'}>{account.accountAttributeValueList[thisAttributeValue].value}</td>);
                            } else {
                              return (<td key={attrIndex + '_accountAttrsValue'}></td>);
                            }
                          })}
                      </tr>
                      );
                    })}
                </tbody>
              </table>
            }
          ></TableSection>
        </main>

        <PagingComponent
          page={accountReducer.pageNumber}
          count={accountReducer.totalSize}
          size={20}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(accountActions_setPageNumber(page));
          }}
        />

        {_accountUploadModalStatus === true && (
          <AccountUploadModal
            open={_accountUploadModalStatus}
            close={() => {
              setAccountUploadModalStatus(false);
              getAccountList(accountReducer.pageNumber - 1, accountReducer.accountType);
            }}
          />
        )}

        {_excelDownloadModalStatus === true && (
          <ExcelDownloadModal
            open={_excelDownloadModalStatus}
            mainTitle={'Account'}
            subTitle={''}
            close={() => { setExcelDownloadModalStatus(false) }}
          />
        )}
      </>
    }
    />
  );
};

export default AccountManagement;
