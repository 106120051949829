import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

import { pageReducer_getCurrentPage, pageReducer_setPageNum, pageReducer_setTotalCount } from 'store/modules/actions/default/pageActions';

import { userLogApi } from 'api/apis/userLogApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import { DateTimeFormat } from 'components/format/DateTimeFormat';
import { LoadingMsg } from 'components/loading/LoadingMsg'

import ExcelDownloadModal from 'components/excel/ExcelDownloadModal';
import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import Paging from 'components/paging/Paging';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';
import NavTitle from 'components/nav/NavTitle';

const ExcelButton = styled.button`
  background: unset;
  background-color: var(--Excel);
  color: var(--white);

  &:hover {background: unset; background-color: var(--ThirdGreen);}
`;

const AccessHistory = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_logList, setLogList] = useState([]);

  const [_startDate, setStartDate] = useState('');
  const [_endDate, setEndDate] = useState('');

  const [_searchOption, setSearchOption] = useState('');
  const [_searchText, setSearchText] = useState('');

  const [_excelDownloadModalStatus, setExcelDownloadModalStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getUserLogs(0);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    dispatch(pageReducer_setPageNum(1));

    return () => { };
  }, [pageReducer.currentPage]);

  useEffect(() => {
    if (_onload === 'loaded') {
      getUserLogs(pageReducer.currentPageNum - 1);
    }

    return () => { };
  }, [pageReducer.currentPageNum]);

  /* ====================================================================== #4 */
  const getUserLogs = async (page, clear) => {
    const paging = `?page=${page}&size=20&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && checkEmptyNull(_startDate, false) && checkEmptyNull(_endDate, false)) {
      if (_startDate < _endDate) {
        BodyToPost.startDate = DateFormat(_startDate);
        BodyToPost.endDate = DateFormat(_endDate);
      } else {
        BodyToPost.startDate = DateFormat(_endDate);
        BodyToPost.endDate = DateFormat(_startDate);
      }
    }
    if (!clear && checkEmptyNull(_searchOption, false) && checkEmptyNull(_searchText, false)) {
      BodyToPost[_searchOption] = _searchText;
    }
    console.log('getUserLogs - BodyToPost : ', BodyToPost);

    await userLogApi.searchUserLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('userLogApi.searchUserLog : ', response);

      setLogList(() => { return response.data.content });

      dispatch(pageReducer_setTotalCount(response.data.totalElements));

      if (_startDate !== '' && _endDate !== '') {
        if (_startDate > _endDate) {
          setStartDate(() => { return _endDate });
          setEndDate(() => { return _startDate });
        }
      }
    });
  };

  const setDurationTime = (seconds) => {
    const hour = parseInt(seconds / 3600) < 10 ? '0' + parseInt(seconds / 3600) : parseInt(seconds / 3600);
    const min = parseInt(parseInt(seconds % 3600) / 60) < 10 ? '0' + parseInt(parseInt(seconds % 3600) / 60) : parseInt(parseInt(seconds % 3600) / 60);
    const sec = parseInt(seconds % 60) < 10 ? '0' + parseInt(seconds % 60) : parseInt(seconds % 60);

    const DurationTime = `${hour}:${min}:${sec}`;

    return DurationTime;
  };

  /* ====================================================================== #5 */
  /* 검색 */
  const actSearch = async () => {
    dispatch(pageReducer_setPageNum(1));
    await getUserLogs(0);
  };

  /* 초기화 */
  const actReset = async () => {
    setStartDate(() => {
      return '';
    });
    setEndDate(() => {
      return '';
    });

    setSearchOption(() => {
      return '';
    });

    setSearchText(() => {
      return '';
    });

    dispatch(pageReducer_setPageNum(1));
    await getUserLogs(0, true);
  };

  // 엑셀 다운로드 팝업창
  const showExcelDownloadModal = () => {
    setExcelDownloadModalStatus(true);
  };

  /* ====================================================================== #6 */

  return (
    <Grid3Body contents={
      <>
        <main className="Main">
          <NavBar
            title={<NavTitle menuCode={'704'} />}
            buttons={
              <>
                <ExcelButton onClick={showExcelDownloadModal} className='btn-download'>다운로드</ExcelButton>
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="ContentCBox">
                  <div className="SearchSection">
                    <div className="SearchSection">
                      <input
                        type="date"
                        className="SelectDate"
                        value={_startDate}
                        onChange={(e) => { setStartDate(() => { return e.target.value; }); }}
                      />

                      <IconRightArrow />

                      <input
                        type="date"
                        className="SelectDate"
                        value={_endDate}
                        onChange={(e) => { setEndDate(() => { return e.target.value; }); }}
                      />
                    </div>

                    <div className="ContentCBox">
                      <select
                        className="SearchOption"
                        value={_searchOption}
                        onChange={(e) => { setSearchOption(() => { return e.target.value; }); }}
                      >
                        <option value="">검색옵션</option>
                        <option value="name">사용자</option>
                      </select>
                      <input
                        className="SearchBar"
                        placeholder="Search..."
                        value={_searchText}
                        onInput={(e) => { setSearchText(() => { return e.target.value; }); }}
                      />
                    </div>
                  </div>

                  <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                  <div className="ResetButton" onClick={actReset}>초기화</div>
                </div>
              </>
            }
          />

          <TableSection sectionStyle={{ height: 'calc(100% - 66px)' }}
            content={
              <table>
                <thead>
                  <tr>
                    <th>사용자</th>
                    <th>로그인</th>
                    <th>로그아웃</th>
                    <th>접속시간</th>
                  </tr>
                </thead>
                <tbody>
                  {_logList.length == 0 ? LoadingMsg() :
                    _logList.map((log, index) => {
                      const logInTime = DateTimeFormat(log.logInTime);
                      let logOutTime = null;
                      let DurationSec = null;
                      if (checkEmptyNull(log.logOutTime, false)) {
                        logOutTime = DateTimeFormat(log.logOutTime);
                        DurationSec = moment.duration(moment(logOutTime).diff(moment(logInTime))).asSeconds();
                      }
                      return (
                        <tr key={index + '_accessLog'}>
                          <td>{log.userName}</td>
                          <td>{logInTime}</td>
                          <td>{logOutTime}</td>
                          <td>{checkEmptyNull(logOutTime, false) ? setDurationTime(DurationSec) : '접속중'}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            }
          ></TableSection>
        </main>

        <Paging
          page={pageReducer.currentPageNum}
          count={pageReducer.totalCount}
          size={'20'}
        />

        {_excelDownloadModalStatus === true && (
          <ExcelDownloadModal
            mainTitle={'accesshistory'}
            subTitle={''}
            open={_excelDownloadModalStatus}
            close={() => { setExcelDownloadModalStatus(false); }}
          />
        )}
      </>
    }
    />
  );
};

export default AccessHistory;
