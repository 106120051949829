import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

import { contractApi } from 'api/apis/contractApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import SelectOneAccount from 'pages/account/modal/SelectOneAccount';
import TableSection from 'components/layouts/table/TableSection';

const FileTableSection = styled.div`
  cursor: pointer;
  width: 100%;

  &:hover {opacity: 0.6;}

  & > .TableSection {
    max-height: 200px;
    overflow: auto;

    td, th {min-width: unset;}
    &::-webkit-scrollbar-thumb {background-color: var(--ThirdBlue);}
  }
`;
const FileLabelStyle = styled.label`
  align-items: center;
  background-color: var(--MainBlue);
  border-radius: 5px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const ContractDate = styled.input`
  border: 1px solid var(--gray-200);
  border-radius: 5px;
  box-sizing: border-box;
  height: 40px;
  padding: 5px;
  &:focus {border: 1px solid var(--gray-200);}
`;

const CreateGSContract = () => {
  /* ===================================================#======================================================*/
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state,);

  /* =================================================== #2 ===================================================*/
  const [_onload, setOnload] = useState('unload');

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    userId: userReducer.user.id,

    contractDate: DateFormat(new Date()), // 수주일자
    contractRegDate: '', // 납품요구접수일자
    scheduledEndDate: '', // 납품기한일자
    scheduledEndDate2: '', // ?
    contractActualEndDate: '', // 납품일자

    contractTypeId: null, // 수주유형
    contractStatus: 'waiting', // 수주상태
    contractCode: '', // 수주코드
    contractName: '', // 수주이름

    accountId: null, // 거래처
    accountName: '', // 거래처

    contractPrice: 0, // 수주총금액

    address: '', // 수주주소

    remark: '', // 비고
    monitoringRemark: '', // 특이사항

    contractCustomizedContent: {
      workPlaceName: '',
      workPlaceAddress: '',
    }, // 커스텀

    contractAttributeValueList: [], // 항목

    contractElementList: [], // 수주물품
    deleteContractElementList: [], // 수주물품 삭제

    procurementList: [], // 발주
    workOrderLogList: [], // 작업지시
  });

  const [_account, setAccount] = useState({ accountId: null, accountName: '' }); // 거래처

  const [_modalStatus, setModalStatus] = useState(false);

  const [_fileProcurement, setFileProcurement] = useState([]);
  const [_fileBL, setFileBL] = useState([]);
  const [_fileCP, setFileCP] = useState([]);
  const [_fileCL, setFileCL] = useState([]);

  /* =================================================== #3 =================================================== */
  useEffect(() => {
    setOnload('loaded');
    return () => { };
  }, []);

  /* =================================================== #4들 =================================================== */
  /* =================================================== #5들 =================================================== */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {return {...prev, [name]: value}});
  };

  const handleCustomEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {...prev, contractCustomizedContent: {...prev.contractCustomizedContent, [name]: value}}
    });
  };

  const setProcurementFile = (e) => {
    const excelType = 'procurement';
    excelExportCommon(e, excelSetting, excelType);
  };
  const setCamberFile = (e) => {
    const excelType = 'camber';
    excelExportCommon(e, excelSetting, excelType);
  };
  const setCombinationFile = (e) => {
    const excelType = 'combination';
    excelExportCommon(e, excelSetting, excelType);
  };
  const setCheckFile = (e) => {
    const excelType = 'check';
    excelExportCommon(e, excelSetting, excelType);
  };

  // 엑셀
  const excelExportCommon = (e, callback, excelType) => {
    const fileList = e.target.files;
    const file = fileList[0];
    console.log(file);
    if (file === undefined) return;

    switch (excelType) {
      case 'procurement': document.getElementById('procurementFileTable').innerHTML = ''; break;
      case 'camber': document.getElementById('camberFileTable').innerHTML = '';  break;
      case 'combination': document.getElementById('combinationFileTable').innerHTML = '';  break;
      case 'check': document.getElementById('checkFileTable').innerHTML = ''; break;
      default: return;
    }

    const reader = new FileReader();
    reader.onload = async () => {
      const fileData = reader.result;
      const wb = XLSX.read(fileData, { type: 'binary' });
      const sheetNameList = wb.SheetNames; // 시트 이름 목록 가져오기
      for (let i = 0; i < sheetNameList.length; i++) {
        const sheetName = sheetNameList[i];
        const sheet = wb.Sheets[sheetName];
        await callback(sheet, excelType);
      }
    };
    reader.readAsBinaryString(file);
  };
  const excelSetting = (sheet, excelType) => {
    switch (excelType) {
      case 'procurement':
        const procurement_result = XLSX.utils.sheet_to_html(sheet);
        console.log(excelType, ' : ', procurement_result);
        document.getElementById('procurementFileTable').innerHTML = procurement_result;
        setProcurementTable();
        break;

      case 'camber':
        const camber_table_result = XLSX.utils.sheet_to_html(sheet);
        const camber_result = XLSX.utils.sheet_to_json(sheet);
        console.log(excelType, ' : ', camber_result);
        document.getElementById('camberFileTable').innerHTML = camber_table_result;
        setCamberTable();
        break;
      case 'combination':
        const combination_table_result = XLSX.utils.sheet_to_html(sheet);
        const combination_result = XLSX.utils.sheet_to_json(sheet);
        console.log(excelType, ' : ', combination_result);
        document.getElementById('combinationFileTable').innerHTML = combination_table_result;
        setCombinationTable();
        break;
      case 'check':
        const check_result = XLSX.utils.sheet_to_html(sheet);
        console.log(excelType, ' : ', check_result);
        document.getElementById('checkFileTable').innerHTML = check_result;
        setCheckTable();
        break;

      default: return;
    }
  };

  // 발주 테이블
  const setProcurementTable = () => {
    const resultRows = document.querySelectorAll('#procurementFileTable tr');
    console.log('setProcurementTable : ', resultRows);
    let headerRowIndex = null;
    let sumRowIndex = null;
    if (resultRows.length > 0) {
      resultRows.forEach((thisItem) => {
        if (thisItem.cells[0].innerHTML === '순번') {
          headerRowIndex = thisItem.rowIndex;
          console.log('headerRowIndex : ', headerRowIndex);
        }
        if (thisItem.cells[0].innerHTML.trim() === '' || thisItem.cells[1].innerHTML.replaceAll(' ', '') === '[합계]') {
          sumRowIndex = thisItem.rowIndex;
          console.log('sumRowIndex : ', sumRowIndex);
        }
      });
    }

    if (headerRowIndex !== null && sumRowIndex !== null) {
      let result = [];
      resultRows.forEach((thisItem) => {
        if (thisItem.rowIndex >= headerRowIndex + 1 && thisItem.rowIndex < sumRowIndex) {
          if (thisItem.cells[1].innerHTML === '' && thisItem.cells[2].innerHTML === '') return;
          const OneRow = {
            status: 'waiting',
            quantity: isNaN(thisItem.cells[5].innerHTML.trim()) !== true ? Number(thisItem.cells[5].innerHTML.trim()) : 0, // 수량
            price: 0, // 금액
            // price: isNaN(thisItem.cells[9].innerHTML.trim()) !== true ? Number(thisItem.cells[9].innerHTML.trim()) : 0, // 금액
            customizedContent: JSON.stringify({
              num: thisItem.cells[0].innerHTML.trim(), // 순번
              weight: thisItem.cells[7].innerHTML.trim(), // 중량
            }),
            elementForExcelDto: {
              // 경서 > 기타 패밀리에 항상 엑셀 물품 추가 됨
              companyId: userReducer.company.companyId,

              elementType: 'material',

              bcodeSearchCode: '',
              elementCode: thisItem.cells[2].innerHTML.trim(), // 규격
              elementName: thisItem.cells[1].innerHTML.trim(), // 재질(품명)
              elementUnit: thisItem.cells[3].innerHTML.trim(), // 단위
              elementSafeStock: 0,
              elementStock: 0,

              attributeAndValue: {},
            },
          };

          OneRow.elementForExcelDto.attributeAndValue['길이'] = thisItem.cells[4].innerHTML.trim(); // 길이
          OneRow.elementForExcelDto.attributeAndValue['단중'] = thisItem.cells[6].innerHTML.trim(); // 단중
          // OneRow.elementForExcelDto.attributeAndValue['단가'] = thisItem.cells[8].innerHTML.trim(); // 단가
          OneRow.elementForExcelDto.attributeAndValue['비고'] = thisItem.cells[8].innerHTML.trim(); // 비고
          // OneRow.elementForExcelDto.attributeAndValue['비고'] = thisItem.cells[10].innerHTML.trim(); // 비고

          result.push(OneRow);
        }
      });
      console.log('result : ', result, JSON.stringify(result));

      const procurementData = {
        procurementStatus: 'waiting',
        procurementName: '',
        procurementCode: '',

        procurementDate: moment(new Date()).format('YYYY-MM-DD'),
        scheduledEndDate: '',

        procurementPrice: 0,

        procurementElementList: result,

        isCustom: true, // 경서 > 커스텀 표시
      };
      console.log('procurementData : ', procurementData, JSON.stringify(procurementData));
      setFileProcurement(procurementData);
    }
  };
  // 캠버리스트 테이블
  const setCamberTable = () => {
    const resultRows = document.querySelectorAll('#camberFileTable tr');
    console.log('setCamberTable : ', resultRows);

    let headerRowIndex = null;
    let sumRowIndex = null;
    if (resultRows.length > 0) {
      resultRows.forEach((thisItem) => {
        if (thisItem.cells[0].innerHTML === '순번') {
          headerRowIndex = thisItem.rowIndex;
          console.log('headerRowIndex : ', headerRowIndex);
        }
        if (thisItem.cells[0].innerHTML.trim() === '' || thisItem.cells[1].innerHTML.replaceAll(' ', '') === '[합계]') {
          sumRowIndex = thisItem.rowIndex;
          console.log('sumRowIndex : ', sumRowIndex);
        }
      });
    }

    if (headerRowIndex !== null) {
      let result = [];
      resultRows.forEach((thisItem) => {
        if (thisItem.rowIndex >= headerRowIndex + 1 && thisItem.rowIndex < sumRowIndex) {
          if (thisItem.cells[1].innerHTML === '' && thisItem.cells[2].innerHTML === '') return;
          const OneRow = {
            existProcessOutElement: false,
            processTypeName: 'BL',
            processingCount: 1,
            processCustomizedContent: {},
            taskLogList: [],
            processCode: thisItem.cells[1].innerHTML.trim(),
            processName: thisItem.cells[2].innerHTML.trim(),
          };

          OneRow.processCustomizedContent.num = thisItem.cells[0].innerHTML.trim(); // 순번
          OneRow.processCustomizedContent.absentNumber = thisItem.cells[1].innerHTML.trim(); // 부재번호
          OneRow.processCustomizedContent.standard = thisItem.cells[2].innerHTML.trim(); // 규격
          OneRow.processCustomizedContent.unit = thisItem.cells[3].innerHTML.trim(); // 단위
          OneRow.processCustomizedContent.length = thisItem.cells[4].innerHTML.trim(); // 길이
          OneRow.processCustomizedContent.quantity = thisItem.cells[5].innerHTML.trim(); // 수량
          OneRow.processCustomizedContent.defaultCamber = thisItem.cells[6].innerHTML.trim(); // 기준캠버값
          OneRow.processCustomizedContent.realCamber = ''; // 실제캠버값
          OneRow.processCustomizedContent.remark = thisItem.cells[7].innerHTML.trim(); // 비고

          result.push(OneRow);
        }
      });
      console.log('result : ', result);

      setFileBL(result);
    }
  };
  // 조합리스트 테이블
  const setCombinationTable = () => {
    const resultRows = document.querySelectorAll('#combinationFileTable tr');
    console.log('setCombinationTable : ', resultRows);

    let headerRowIndex = null;
    if (resultRows.length > 0) {
      resultRows.forEach((thisItem) => {
        if (thisItem.cells[0].innerHTML.replaceAll(' ', '') === '재질') {
          headerRowIndex = thisItem.rowIndex;
          console.log('headerRowIndex : ', headerRowIndex);
        }
      });
    }

    if (headerRowIndex !== null) {
      let result = [];
      let count = 0;
      resultRows.forEach((thisItem) => {
        if (thisItem.rowIndex >= headerRowIndex + 1) {
          if (thisItem.cells[9] !== undefined && thisItem.cells[9].innerHTML.trim() !== null && thisItem.cells[9].innerHTML.trim() !== '') {
            const OneRow = {
              existProcessOutElement: false,
              processTypeName: 'CP',
              processingCount: 1,
              processCustomizedContent: {},
              taskLogList: [],

              processName: thisItem.cells[1].innerHTML.trim(),
            };

            count++;
            OneRow.processCustomizedContent.num = count.toString(); // 순번
            OneRow.processCustomizedContent.name = thisItem.cells[0].innerHTML.trim(); // 재질
            OneRow.processCustomizedContent.profile = thisItem.cells[1].innerHTML.trim(); // 규격
            OneRow.processCustomizedContent.length = thisItem.cells[2].innerHTML.trim(); // 길이
            OneRow.processCustomizedContent.quantity = thisItem.cells[3].innerHTML.trim(); // 수량
            OneRow.processCustomizedContent.combined = thisItem.cells[4].innerHTML.trim(); // 조합
            OneRow.processCustomizedContent.part = thisItem.cells[5].innerHTML.trim(); // 잔재
            OneRow.processCustomizedContent.partWidth = thisItem.cells[6].innerHTML.trim(); // 부재길이
            OneRow.processCustomizedContent.each = thisItem.cells[7].innerHTML.trim(); // 수량
            OneRow.processCustomizedContent.total = thisItem.cells[8].innerHTML.trim(); // 총수량
            OneRow.processCustomizedContent.mark = thisItem.cells[9].innerHTML.trim(); // Mark

            result.push(OneRow);
          }
        }
      });
      console.log('result : ', result);

      setFileCP(result);
    }
  };
  // 체크리스트 테이블
  const setCheckTable = () => {
    const resultRows = document.querySelectorAll('#checkFileTable tr');
    console.log('setCheckTable : ', resultRows);

    let headerRowIndex = null;
    if (resultRows.length > 0) {
      resultRows.forEach((thisItem) => {
        // if(thisItem.cells[0].innerHTML.replaceAll(' ', '') === 'AsmblyPos.') {
        if (thisItem.cells[0].innerHTML.replaceAll(' ', '') === '부재번호') {
          headerRowIndex = thisItem.rowIndex;
          console.log('headerRowIndex : ', headerRowIndex);
        }
      });
    }

    if (headerRowIndex !== null) {
      let result = [];
      resultRows.forEach((thisItem) => {
        if (thisItem.rowIndex >= headerRowIndex + 1) {
          if (thisItem.cells[0] !== undefined && thisItem.cells[0].innerHTML.trim() !== null && thisItem.cells[0].innerHTML.trim() !== '') {
            const OneRow = {
              existProcessOutElement: false,
              processTypeName: 'CL',
              processingCount: 1,
              processCustomizedContent: {},
              taskLogList: [],

              processName: thisItem.cells[1].innerHTML.trim(),
            };

            OneRow.processCustomizedContent.asmbly = thisItem.cells[0].innerHTML.trim(); // 부재번호
            OneRow.processCustomizedContent.profile = thisItem.cells[1].innerHTML.trim(); // 규격
            OneRow.processCustomizedContent.length = thisItem.cells[2].innerHTML.trim(); // 길이
            OneRow.processCustomizedContent.quantity = thisItem.cells[3].innerHTML.trim(); // 수량
            OneRow.processCustomizedContent.part = thisItem.cells[4].innerHTML.trim(); // 종류
            OneRow.processCustomizedContent.weight = thisItem.cells[5].innerHTML.trim(); // 개별중량
            OneRow.processCustomizedContent.name = thisItem.cells[6].innerHTML.trim(); // 비고

            result.push(OneRow);
          }
        }
      });
      console.log('result : ', result);

      setFileCL(result);
    }
  };

  const updateProcurementData = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      userId: userReducer.user.id,

      procurementStatus: 'waiting',
      procurementName: _formData.contractName,
      procurementCode: _formData.contractCode,

      procurementDate: checkEmptyNull(_formData.contractDate, ''),
      scheduledEndDate: checkEmptyNull(_formData.scheduledEndDate, ''),

      accountId: _account.accountId,

      procurementElementList: checkNullArray(_fileProcurement.procurementElementList, []).map((procurementElement) => {
        return {...procurementElement, accountId: _account.accountId};
      }),

      isCustom: true, // 경서 > 커스텀 표시
    };
    console.log('BodyToPost - procurement : ', BodyToPost);
    return BodyToPost;
  };
  const updateWorkOrderData = async () => {
    const BLList = _fileBL.map((BL) => {
      return { ...BL, processCustomizedContent: JSON.stringify(BL.processCustomizedContent) };
    });
    const CPList = _fileCP.map((CP) => {
      return { ...CP, processCustomizedContent: JSON.stringify(CP.processCustomizedContent) };
    });
    const CLList = _fileCL.map((CL) => {
      return { ...CL, processCustomizedContent: JSON.stringify(CL.processCustomizedContent) };
    });
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      userId: userReducer.user.id,

      workOrderStatus: 'wait',
      workOrderCode: _formData.contractCode,
      workOrderName: _formData.contractName,

      workOrderScheduledStartDate: checkEmptyNull(_formData.scheduledEndDate, ''),
      workOrderScheduledEndDate: checkEmptyNull(_formData.scheduledEndDate2, ''),
      
      workPlace: _formData.contractCustomizedContent.workPlaceName,
      detailedWorkPlace: _formData.contractCustomizedContent.workPlaceAddress,
      
      message: '',
      remark: '',

      workOrderCustomizedContent: JSON.stringify({}),
      
      accountId: _account.accountId,

      processLogList: [...BLList, ...CPList, ...CLList],
    };
    console.log('BodyToPost - workOrderLog : ', BodyToPost);
    return BodyToPost;
  };
  const actCreate = async (e) => {
    e.preventDefault();
    if (!checkEmptyNull(_account.accountId, false)) return alert('거래처를 선택해주세요.');
    if (!checkEmptyNull(_formData.contractCode, false)) return alert('수주코드를 입력해주세요.');
    if (!checkEmptyNull(_formData.contractName, false)) return alert('공사명을 입력해주세요.');

    const procurement = await updateProcurementData();
    const workOrder = await updateWorkOrderData();

    const BodyToPost = { 
      ..._formData,
      accountId: _account.accountId,
      contractCustomizedContent: JSON.stringify(_formData.contractCustomizedContent),
      procurementList : [procurement],
      workOrderLogList: [workOrder],
    };
    console.log('BodyToPost - contract : ', BodyToPost);

    await contractApi.createContract(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('contractApi.createContract : ', response);
      alert('수주를 추가했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    })
  };

  /* =================================================== #6들 =================================================== */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <>
        <main className="Main">
          <NavBar nav={''} title={<NavTitle menuCode={'201'} />} />

          <Form
            title={<><NavTitle menuCode={'201'} />추가</>}
            buttons={
              <>
                <button className="formButton" onClick={actCreate}>저장</button>
                <button className="formButton cancle"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(pageReducer.currentPage, { replace: true });
                  }}
                >
                  취소
                </button>
              </>
            }
            forms={
              <>
                <div className="formBox">
                  <div style={{display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%'}}>
                    <h4>거래처</h4>
                    <div className="formButton"
                      style={{backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px'}}
                      onClick={(e) => {e.preventDefault(); setModalStatus(() => {return true});}}
                    >
                      거래처 선택
                    </div>
                  </div>
                  <input
                    type="data"
                    name="accountId"
                    data-required="required"
                    readOnly
                    placeholder=" 거래처를 선택해주세요."
                    value={checkEmptyNull(_account.accountName, '')}
                  />
                </div>

                <div className="formBox">
                  <h4>수주코드</h4>
                  <input
                    type="data"
                    name="contractCode"
                    data-required="required"
                    placeholder="* 수주코드..."
                    onChange={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>공사명</h4>
                  <input
                    type="text"
                    name="contractName"
                    data-required="required"
                    placeholder="* 공사명..."
                    onChange={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>수주일자</h4>
                  <ContractDate
                    type="date"
                    name="contractDate"
                    defaultValue={moment(new Date()).format('YYYY-MM-DD')}
                    onChange={handleInputEvent}
                  ></ContractDate>
                </div>

                <div className="formBox">
                  <h4>{userReducer.endDateName[0]}</h4>
                  <ContractDate
                    type="date"
                    name="scheduledEndDate"
                    onChange={handleInputEvent}
                  ></ContractDate>
                </div>

                <div className="formBox">
                  <h4>{userReducer.endDateName[1]}</h4>
                  <ContractDate
                    type="date"
                    name="scheduledEndDate2"
                    onChange={handleInputEvent}
                  ></ContractDate>
                </div>

                <div className="formBox">
                  <h4>현장명</h4>
                  <input
                    type="text"
                    name="workPlaceName"
                    placeholder="현장명..."
                    onChange={handleCustomEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>현장주소</h4>
                  <input
                    type="text"
                    name="workPlaceAddress"
                    placeholder="현장주소..."
                    onChange={handleCustomEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>수주금액</h4>
                  <input
                    type="number"
                    name="contractPrice"
                    placeholder="* 수주금액은 숫자만 입력해주세요."
                    onInput={handleInputEvent}
                  />
                </div>

                <div className="formBox w100">
                  <h4>발주서 업로드</h4>
                  <input
                    type="file"
                    id="procurementFileInput"
                    style={{ display: 'none' }}
                    onChange={setProcurementFile}
                  />
                  <FileLabelStyle htmlFor="procurementFileInput">업로드</FileLabelStyle>
                  <FileTableSection
                    onClick={() => {
                      document.getElementById('procurementFileInput').value = '';
                      document.getElementById('procurementFileTable').innerHTML = '';
                      setFileProcurement([]);
                    }}
                  >
                    <TableSection content={<table id="procurementFileTable"></table>} />
                  </FileTableSection>
                </div>

                <div className="formBox w100">
                  <h4>캠버리스트 업로드</h4>
                  <input
                    type="file"
                    id="camberFileInput"
                    style={{ display: 'none' }}
                    onChange={setCamberFile}
                  />
                  <FileLabelStyle htmlFor="camberFileInput">업로드</FileLabelStyle>
                  <FileTableSection
                    onClick={() => {
                      document.getElementById('camberFileInput').value = '';
                      document.getElementById('camberFileTable').innerHTML = '';
                      setFileCP([]);
                    }}
                  >
                    <TableSection content={<table id="camberFileTable"></table>} />
                  </FileTableSection>
                </div>
                <div className="formBox w100">
                  <h4>조합리스트 업로드</h4>
                  <input
                    type="file"
                    id="combinationFileInput"
                    style={{ display: 'none' }}
                    onChange={setCombinationFile}
                  />
                  <FileLabelStyle htmlFor="combinationFileInput">업로드</FileLabelStyle>
                  <FileTableSection
                    onClick={() => {
                      document.getElementById('combinationFileInput').value = '';
                      document.getElementById('combinationFileTable').innerHTML = '';
                      setFileCP([]);
                    }}
                  >
                    <TableSection content={<table id="combinationFileTable"></table>} />
                  </FileTableSection>
                </div>
                <div className="formBox w100">
                  <h4>체크리스트 업로드</h4>
                  <input
                    type="file"
                    id="checkFileInput"
                    style={{ display: 'none' }}
                    onChange={setCheckFile}
                  />
                  <FileLabelStyle htmlFor="checkFileInput">업로드</FileLabelStyle>
                  <FileTableSection
                    onClick={() => {
                      document.getElementById('checkFileInput').value = '';
                      document.getElementById('checkFileTable').innerHTML = '';
                      setFileCL([]);
                    }}
                  >
                    <TableSection content={<table id="checkFileTable"></table>} />
                  </FileTableSection>
                </div>
              </>
            }
          />
        </main>

        {_modalStatus === true ? (
          <SelectOneAccount
            buttonTitle={'거래처 선택'}
            buttonEvent={(data) => {
              setAccount(() => { return data; });
              setTimeout(setModalStatus(false), 1000);
            }}
            open={_modalStatus}
            close={() => { setModalStatus(false); }}
          ></SelectOneAccount>
        ) : null}
      </>
    }
    />
  );
};

export default CreateGSContract;
