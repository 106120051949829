import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { qna } from 'api/apis/mall/qna';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { uploadSection } from 'components/icons/src';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import MallBody from 'components/layouts/body/MallBody';
import NavBar from 'components/nav/NavBar';
import SelectMallElements from 'pages/mallManagement/mallElement/modal/SelectMallElements';

const MallCodeStyle = styled.p`
  word-break: break-all;
  width: 100%;
`;

const MallUserTable = styled.table`
  background-color: var(--white);
  border-collapse: collapse;
  font-size: 0.9em;
  table-layout: fixed;
  width: 100%;

  & td, & th {
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    padding: 5px;
  }
  & th {
    background-color: var(--MainNavy);
    color: var(--white);
    height: 45px;
  }
  tbody th {min-width: 100px; width: 100px;}
  & input, & textarea {
    border-color: var(--MainBlue);
    &:focus { border-color: var(--MainBlue); border-width: 2px; outline: unset;}
  }
`;

const ElementTable = styled.table`
  background-color: var(--white);
  border-collapse: collapse;
  font-size: 0.9em;
  height: fit-content;
  table-layout: fixed;
  width: 100%;

  td, th {
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    height: 35px;
    padding: 2px 10px;
  }
  th {background-color: var(--MainNavy); color: var(--white);}
  thead th {height: 45px;}
  & td.ElementInfos {box-sizing: border-box; padding: unset;}
`;
const ElementInfos = styled.div`
  height: 100%;
  width: 100%;
`;
const ElementInfo = styled.div`
  border-bottom: 1px solid var(--gray-200);
  display: grid;
  grid-template-columns: 50px auto;
  height: 50%;
  width: 100%;

  & h5 {
    align-items: center;
    background-color: var(--MainNavy);
    color: var(--white);
    display: flex;
    justify-content: center;
    width: 100%;
  }
  &.mall h5 {background-color: var(--MainBlue);}
  & p {
    align-items: center;
    box-sizing: border-box;
    justify-content: flex-start;
    padding: 10px;
    width: 100%;
  }
`;

const DeleteButton = styled.div`
  height: 15px;
  margin: 0px auto;
  width: 15px;

div {background-color: var(--MainRed); cursor: pointer; height: 15px; width: 15px;}
`;

const DragFileSection = styled.label`
  align-items: center;
  background-color: var(--white);
  border: 1px dashed var(--gray-200);
  column-gap: 50px;
  cursor: pointer;
  display: flex;

  flex-direction: column;
  height: 200px;
  justify-content: center;
  width: 100%;

  & p {font-size: 0.9em;}
  & span {
    color: var(--MainBlue);
    &:hover {border-bottom: 2px solid var(--MainBlue); padding-bottom: 2px;}
  }
`;
const DragFileIcon = styled.div`
  background-color: var(--gray-200);
  height: 80px;
  width: 80px;
  mask-image: url(${uploadSection}); mask-repeat: no-repeat; mask-size: contain;
  --webkit-mask-image: url(${uploadSection}); --webkit-mask-repeat: no-repeat; --webkit-mask-size: contain;
`;
const FileList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
  margin-top: 10px;
  width: 100%;
`;
const FileBox = styled.div`
  align-items: center;
  background-color: var(--white);
  box-sizing: border-box;
  color: var(--gray-400);
  display: flex;
  height: 200px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;

  & img {height: inherit; max-width: inherit; width: auto;}
`;
const DeleteFileButton = styled.div`
  align-items: center;
  background-color: var(--MainRed);
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  height: 20px;
  justify-content: center;
  padding-left: 3px;
  position: absolute;
  right: 15px;
  top: 15px;
  transform: scale(1.5);
  width: 20px;

  div {background-color: var(--white); cursor: pointer; height: 15px; width: 15px;}
`;

const UpdateMallQnA = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();

  const updateContent = JSON.parse(searchParams.get('updateContent'));
  const {mallQnAId, mallQnACode, status, mallUserName, mallQnAContent, mallQnAElementList, attachFileList} = updateContent;
  console.log('updateContent : ', updateContent);

  const FileInput = useRef();

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    mallQnAId: mallQnAId,
    mallQnACode: mallQnACode,
    status: checkEmptyNull(status, 'waiting'),
    userId: userReducer.user.id,
    mallUserName: checkEmptyNull(mallUserName, ''),
    mallQnAContent: checkEmptyNull(mallQnAContent, ''),
    mallQnAElementList: mallQnAElementList,
    deleteMallQnAElementList: [],
    attachFileList: attachFileList,
    deleteFileDataList: [],
  });

  const [_modalStatus, setModalStatus] = useState(false);
  const [_originOrderElementList, setOriginOrderElementList] = useState(mallQnAElementList);
  const [_mallQnAElementList, setMallQnAElementList] = useState(mallQnAElementList);

  const [_prevFileDataList, setPrevFileDataList] = useState(checkNullArray(attachFileList, []));
  const [_fileDataList, setFileDataList] = useState(checkNullArray(attachFileList, []));

  /* ====================================================================== #3 */
  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {return { ...prev, [name]: value };});
  };

  const actDeleteOrderElement = (e, index, element) => {
    setMallQnAElementList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);
      return prevData;
    });
  };

  const handleFileEvent = (type, e) => {
    let files = [];
    switch (type) {
      case 'click': files = e.target.files; break;
      case 'drag': files = e.dataTransfer.files; break;

      default: return;
    }

    if (files === undefined || files === null || files.length === 0) return;
    const setFiles = Array.prototype.slice.call(files);
    setFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onload = () => {
          const returnData = file;
          returnData['preview'] = reader.result;
          setFileDataList((prev) => {return [...prev, returnData];});
          resolve();
        };
      });
    });
  };
  const actDeleteFileData = (file, index) => {
    setFileDataList((prev) => {
      const returnData = [...prev];
      returnData.splice(index, 1);
      return returnData;
    });
  };

  /* 수정 */
  const actUpdate = async (e) => {
    e.preventDefault();

    const findElement = (mallQnAElementId) => {
      const index = _mallQnAElementList.findIndex((mallQnAElement) => mallQnAElement.mallQnAElementId === mallQnAElementId);
      return index;
    };
    const deleteMallQnAElementList = _originOrderElementList.filter((element) => findElement(element.mallQnAElementId) === -1);
    const setDeleteMallQnAElementList = deleteMallQnAElementList.filter((element) => element !== undefined && element !== null);

    const findFileData = (attachFileId) => {
      const index = _fileDataList.findIndex((fileData) => fileData.attachFileId === attachFileId);
      return index;
    };
    const deleteFileDataList = _prevFileDataList.filter((fileData) => findFileData(fileData.attachFileId) === -1);
    const setDeleteFileDataList = deleteFileDataList.filter((fileData) => fileData !== undefined && fileData !== null);

    const BodyToPut = {
      ..._formData,
      mallQnAElementList: _mallQnAElementList,
      deleteMallQnAElementList: setDeleteMallQnAElementList.map((deleteElement) => deleteElement.mallQnAElementId),
      deleteFileDataList: setDeleteFileDataList.map((deleteFileData) => deleteFileData.attachFileId),
    };
    console.log('BodyToPut : ', BodyToPut);

    const putFormData = new FormData();
    putFormData.append('key', new Blob([JSON.stringify(BodyToPut)], { type: 'application/json' }));
    if (_fileDataList.length > 0) {
      _fileDataList.forEach((file) => {
        console.log(file.attachFileId);
        if (file.attachFileId === undefined) putFormData.append('multipartFileList', file);
      });
    }
    for (let values of putFormData.values()) {
      console.log('putFormData : ', values);
    }

    await qna.updateMallQnA(mallQnAId, putFormData).then((response) => {
      if (response === undefined) return;
      console.log('qna.updateMallQnA : ', response);
      alert('문의정보를 수정했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  };

  return (
    <MallBody contents={
      <>
        <main className="Main">
          <NavBar title={'문의관리'} nav={''} />

          <Form
            title={
              <>
                <MallCodeStyle>{mallQnACode}</MallCodeStyle>
                문의정보
                <br />
                수정
              </>
            }
            buttons={
              <>
                <button className="formButton" onClick={actUpdate}>저장</button>
                <button className="formButton cancle" onClick={(e) => {e.preventDefault(); navigate(pageReducer.currentPage, { replace: true });}}>취소</button>
              </>
            }
            forms={
              <>
                <div className="formBox">
                  <h4>문의자</h4>
                  <MallUserTable>
                    <thead>
                      <tr>
                        <th style={{minWidth: '100px', width: '100px'}}></th>
                        <th>문의자</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>이름</th>
                        <td>{mallUserName}</td>
                      </tr>
                    </tbody>
                  </MallUserTable>
                </div>

                <div className="formBox">
                  <h4>문의내용</h4>
                  <textarea
                    type="text"
                    name="mallQnAContent"
                    placeholder="* 문의내용..."
                    value={_formData.mallQnAContent}
                    onInput={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <div style={{display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%'}}>
                    <h4>문의물품</h4>
                    <div className="formButton"
                      style={{backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px'}}
                      onClick={(e) => {
                        e.preventDefault();
                        setModalStatus(true);
                      }}
                    >
                      물품 선택
                    </div>
                  </div>

                  <ElementTable>
                    <thead>
                      <tr>
                        <th>판매 물품정보</th>
                        <th style={{ minWidth: '50px', width: '50px' }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {_mallQnAElementList.map((element, index) => {
                        return (
                          <tr key={index + '_mallQnAElements'}>
                            <td className="ElementInfos">
                              <ElementInfos>
                                <ElementInfo className="mall"><h5>이름</h5><p>{element.mallElementName}</p></ElementInfo>
                                <ElementInfo className="mall"><h5>코드</h5><p>{element.mallElementCode}</p></ElementInfo>
                              </ElementInfos>
                            </td>
                            <td style={{minWidth: '50px', positon: 'relative', width: '50px'}}>
                              <DeleteButton onClick={(e) => {actDeleteOrderElement(e, index, element);}}><CloseButtonIcon /></DeleteButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </ElementTable>
                </div>

                <div className="formBox">
                  <h4>첨부파일</h4>
                  <input
                    type="file"
                    ref={FileInput}
                    id="FileInput"
                    style={{ display: 'none' }}
                    multiple
                    onChange={(e) => {handleFileEvent('click', e);}}
                  />
                  <DragFileSection
                    htmlFor="FileInput"
                    onDragEnter={(e) => {e.stopPropagation(); e.preventDefault();}}
                    onDragLeave={(e) => {e.stopPropagation(); e.preventDefault();}}
                    onDragOver={(e) => {e.stopPropagation(); e.preventDefault();}}
                    onDrop={(e) => {e.preventDefault(); handleFileEvent('drag', e);}}
                  >
                    <DragFileIcon />
                    <p>첨부할 파일 드래그 또는 <span>선택</span></p>
                  </DragFileSection>
                  <FileList>
                    {_fileDataList.map((fileData, index) => {
                      return (
                        <FileBox key={index + '_fileDatas'}>
                          {(() => {
                            const returnArray = [];
                            if (fileData.attachFileId === undefined) {
                              if (fileData.type && fileData.type.startsWith('image')) {
                                returnArray.push(<img key={index + '_fileDataImg'} src={fileData.preview} alt={fileData.name} />);
                              } else {
                                returnArray.push(<>{fileData.name}</>);
                              }
                            } else {
                              if (fileData.fileDataType && fileData.fileDataType.startsWith('image')) {
                                returnArray.push(<img key={index + '_fileDataImg'} src={fileData.fileDataS3URL} alt={fileData.fileDataName} />);
                              } else {
                                returnArray.push(<>{fileData.fileDataName}</>);
                              }
                            }
                            return returnArray;
                          })()}

                          <DeleteFileButton onClick={() => {actDeleteFileData(fileData, index);}}><CloseButtonIcon /></DeleteFileButton>
                        </FileBox>
                      );
                    })}
                  </FileList>
                </div>
              </>
            }
          />
        </main>

        {_modalStatus === true && (
          <SelectMallElements
            buttonEvent={async (data) => {
              console.log('data : ', data);
              const mallQnAElementIds = _mallQnAElementList.map((thisItem) => thisItem.mallElementId);
              const newElements = data.map((element) => {
                if (mallQnAElementIds.indexOf(element.mallElementId) !== -1) return null;
                else return {...element, count: 0};
              });
              const setNewElements = newElements.filter((thisItem) => thisItem !== null);
              setMallQnAElementList((prev) => {return [...prev, ...setNewElements];});
              setTimeout(setModalStatus(false), 1000);
            }}
            buttonTitle={`물품 선택`}
            open={_modalStatus}
            close={() => {setModalStatus(false);}}
          />
        )}
      </>
      }
    />
  );
};

export default UpdateMallQnA;
