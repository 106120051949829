import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { reProductionOrderActions_setEndDate, reProductionOrderActions_setPageNumber, reProductionOrderActions_setReset, reProductionOrderActions_setSearchData, reProductionOrderActions_setSearchOption, reProductionOrderActions_setSearchText, reProductionOrderActions_setStartDate, reProductionOrderActions_setTotalSize, reProductionOrderActions_setWorkOrderType } from 'store/modules/actions/common/reProductionOrderActions';

import { reWorkOrderLogApi } from 'api/apis/reWorkOrderLogApi';
import { workOrderTypeApi } from 'api/apis/workOrderTypeApi';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import { LoadingMsg } from 'components/loading/LoadingMsg'
import { baseInformationIcon } from 'components/icons/src'

import CommaNum from 'components/format/CommaNum';
import DOCButtonIcon from 'components/icons/DOCButtonIcon';
import Grid3Body from 'components/layouts/body/Grid3Body';
import GuideText from 'components/buttons/GuideText';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import ProductionProcessTracking from 'pages/qualityControl/modal/ProductionProcessTracking';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import ShowFileList from 'pages/file/modal/ShowFileList';
import ShowWorkOrderLog from 'pages/productionOrder/modal/ShowWorkOrderLog';
import TableSection from 'components/layouts/table/TableSection';

const Section = styled.main`
  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
    & .typeButtons {
      display: flex;
      gap: 5px;
    }
  }
  
  .TableSection {
    thead tr {border: none;}
    td {height: 100px;}
  }
`;

const PrevButton = styled.div`
  align-items: center;
  background-color: var(--MainBlue);
  border: 4px solid var(--white);
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  min-height: 100px;
  width: 100%;
`;

const QualityButton = styled.div`
  align-items: center;
  background-color: var(--Violet);
  border: 4px solid var(--white);
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 600;
  min-height: 100px;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const ReProductionOrder = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { reProductionOrderReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_workOrderLogList, setWorkOrderLogList] = useState([]);

  const [_workOrderTypes, setWorkOrderTypes] = useState([]);

  const [_processModalStatus, setProcessModalStatus] = useState(false); // 공정 리스트
  const [_showWokrOrderLog, setShowWorkOrderLog] = useState();
  const [_showProcessList, setShowProcessList] = useState([]);

  const [_trackingModalStatus, setTrackingModalStatus] = useState(false); // 생산추적
  const [_trackingWorkOrderLogId, setTrackingWorkOrderLogId] = useState();

  const [_eventWorkOrderLog, setEventWorkOrderLog] = useState(); // 기존 작업지시

  const [_fileListModalStatus, setFileListModalStatus] = useState(false);
  const [_eventcontent, setEventContent] = useState({});

  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('104') === true ||
      thisItem.authority.startsWith('109') === true ||
      thisItem.authority.startsWith('411') === true || // 불량재작업
      thisItem.authority.startsWith('413') === true, // 재작업이력
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    setOnload('loaded');

    getWorkOrderTypes();
    getWorkOrderLogList(reProductionOrderReducer.pageNumber - 1, reProductionOrderReducer.workOrderType);

    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getWorkOrderLogList(reProductionOrderReducer.pageNumber - 1, reProductionOrderReducer.workOrderType);
    }

    return () => { };
  }, [reProductionOrderReducer.pageNumber]);

  useEffect(() => { }, [_eventWorkOrderLog]);

  /* ========================================================================= #4 */
  const getWorkOrderTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await workOrderTypeApi.searchWorkOrderType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderTypeApi.searchWorkOrderType : ', response);
      setWorkOrderTypes(() => { return response.data; });
    });
  };

  const getWorkOrderLogList = async (page, workOrderType, workOrderStatus, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    if (!clear && checkEmptyNull(workOrderType, false) && workOrderType !== 'all') {
      BodyToPost.workOrderTypeId = Number(workOrderType);
    }
    if (!clear && checkEmptyNull(workOrderStatus, false)) {
      switch (workOrderStatus) {
        case 'all': BodyToPost.workOrderStatus = ['wait', 'work', 'pause', 'stop', 'end', 'cancel']; break;
        case 'waitNwork': BodyToPost.workOrderStatus = ['wait', 'work']; break;
        default: BodyToPost.workOrderStatus = [workOrderStatus]; break;
      }
    }
    if (!clear && checkEmptyNull(reProductionOrderReducer.startDate, false) && checkEmptyNull(reProductionOrderReducer.endDate, false)) {
      BodyToPost.scheduledStartDate = DateFormat(reProductionOrderReducer.startDate);
      BodyToPost.scheduledEndDate = DateFormat(reProductionOrderReducer.endDate);
      if (reProductionOrderReducer.startDate > reProductionOrderReducer.endDate) {
        BodyToPost.scheduledStartDate = DateFormat(reProductionOrderReducer.endDate);
        BodyToPost.scheduledEndDate = DateFormat(reProductionOrderReducer.startDate);
      }
    } else if (!clear && checkEmptyNull(reProductionOrderReducer.startDate, false) && !checkEmptyNull(reProductionOrderReducer.endDate, false)) {
      BodyToPost.scheduledStartDate = DateFormat(reProductionOrderReducer.startDate);
    } else if (!clear && checkEmptyNull(reProductionOrderReducer.endDate, false) && !checkEmptyNull(reProductionOrderReducer.startDate, false)) {
      BodyToPost.scheduledEndDate = DateFormat(reProductionOrderReducer.endDate);
    }
    if (!clear && checkEmptyNull(reProductionOrderReducer.searchData.workOrderCode, false)) {
      BodyToPost.workOrderCode = reProductionOrderReducer.searchData.workOrderCode;
    }
    if (!clear && checkEmptyNull(reProductionOrderReducer.searchData.workOrderName, false)) {
      BodyToPost.workOrderName = reProductionOrderReducer.searchData.workOrderName;
    }

    await reWorkOrderLogApi.searchReWorkOrderLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('reWorkOrderLogApi.searchReWorkOrderLog : ', response);

      setWorkOrderLogList(() => { return response.data.content; });
      dispatch(reProductionOrderActions_setTotalSize(response.data.totalElements));

      if (checkEmptyNull(reProductionOrderReducer.startDate, false) && checkEmptyNull(reProductionOrderReducer.endDate, false)) {
        if (reProductionOrderReducer.startDate > reProductionOrderReducer.endDate) {
          dispatch(reProductionOrderActions_setStartDate(reProductionOrderReducer.endDate))
          dispatch(reProductionOrderActions_setEndDate(reProductionOrderReducer.startDate))
        }
      }
    })
  };

  /* ========================================================================= #5 */
  /* 검색 */
  const actSearch = async () => {
    dispatch(reProductionOrderActions_setPageNumber(1));
    await getWorkOrderLogList(0, reProductionOrderReducer.workOrderType);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(reProductionOrderActions_setReset());
    await getWorkOrderLogList(0, 'all', true);
  };

  /* 공정 리스트 */
  const showProcessList = async (workOrderLog) => {
    setShowWorkOrderLog(() => { return workOrderLog.workOrderLogId; });
    setShowProcessList(() => { return workOrderLog; });
    setTimeout(setProcessModalStatus(true), 1000);
  };

  /* 생산추적 */
  const showProductionProcessTracking = (workOrderLog) => {
    setTrackingWorkOrderLogId(() => { return workOrderLog.workOrderLogId; });
    setTimeout(setTrackingModalStatus(true), 1000);
  };

  /* 기존 작업지시 */
  const showPrevWorkOrder = async (workOrderLog) => {
    await workOrderLogApi.getWorkOrderLog(workOrderLog.parentId).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.getWorkOrderLog : ', response);
      setEventWorkOrderLog(() => { return response.data; });
    })
  };

  const showFileList = (content, work) => {
    console.log('showFileList : ', work, content);
    setEventContent(() => {
      if (work === true) return { ...content, work: true };
      else return content;
    });
    setTimeout(setFileListModalStatus(true), 1000);
  };

  /* ====================================================================== #6 */

  return (
    <Grid3Body contents={
      <>
        <Section className="Main">
          <NavBar
            title={
              <>
                <NavTitle menuCode={'413'} />
                {/* <div className='Title'>
                  <NavTitle menuCode={'413'} />
                  <div className='typeButtons' style={{ display: 'flex' }}>
                    <button
                      className={reProductionOrderReducer.workOrderType === "all" ? 'btn-inven-1' : 'btn-set'}
                      key={'all' + '_workOrderType'}
                      value='all'
                      onClick={(e) => {
                        dispatch(reProductionOrderActions_setWorkOrderType("all"));
                        dispatch(reProductionOrderActions_setPageNumber(1));
                        getWorkOrderLogList(0, "all", "all", reProductionOrderReducer.isPushedSearchButton);
                      }}
                    >
                      전체
                    </button>
                    {_workOrderTypes.map((type) => {
                      return (
                        <button
                          className={reProductionOrderReducer.workOrderType === type ? 'btn-inven-1' : 'btn-set'}
                          key={type.workOrderTypeId + '_workOrderType'}
                          value={type.workOrderTypeId}
                          onClick={(e) => {
                            dispatch(reProductionOrderActions_setWorkOrderType(e.target.value && type));
                            dispatch(reProductionOrderActions_setPageNumber(1));
                            getWorkOrderLogList(0, e.target.value, reProductionOrderReducer.workOrderStatus, reProductionOrderReducer.isPushedSearchButton);
                          }}
                        >
                          {type.workOrderTypeName}
                        </button>
                      );
                    })}
                  </div>
                </div> */}
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <select
                    className="SelectType"
                    value={reProductionOrderReducer.workOrderType}
                    onChange={(e) => {
                      dispatch(reProductionOrderActions_setWorkOrderType(e.target.value));
                      dispatch(reProductionOrderActions_setPageNumber(1));
                      getWorkOrderLogList(0, e.target.value, reProductionOrderReducer.workOrderStatus);
                    }}
                  >
                    <option value="all">전체</option>
                    {_workOrderTypes.map((thisItem) => {
                      return (
                        <option key={thisItem.workOrderTypeId + 'reProductionOrderReducer.workOrderType'} value={thisItem.workOrderTypeId}>
                          {thisItem.workOrderTypeName}
                        </option>
                      );
                    })}
                  </select>
                  <div className="SearchSection">
                    <input
                      type="date"
                      className="SelectDate"
                      value={reProductionOrderReducer.startDate}
                      onChange={(e) => {
                        dispatch(reProductionOrderActions_setStartDate(e.target.value));
                      }}
                    />

                    <IconRightArrow />

                    <input
                      type="date"
                      className="SelectDate"
                      value={reProductionOrderReducer.endDate}
                      onChange={(e) => {
                        dispatch(reProductionOrderActions_setEndDate(e.target.value));
                      }}
                    />
                  </div>
                </div>
              </>
            }
            secondRow={
              <div className="SearchSection">
                <div className="ContentCBox">
                  <select className="SearchOption detail">
                    <option value="workOrderCode">작업코드</option>
                  </select>
                  <input
                    className="SearchBar detail"
                    placeholder="Search..."
                    value={checkEmptyNull(reProductionOrderReducer.searchData.workOrderCode, '')}
                    onInput={(e) => {
                      dispatch(reProductionOrderActions_setSearchData({ ...reProductionOrderReducer.searchData, workOrderCode: e.target.value }));
                    }}
                  />
                </div>

                <div className="ContentCBox">
                  <select className="SearchOption detail">
                    <option value="workOrderName">작업이름</option>
                  </select>
                  <input
                    className="SearchBar detail"
                    placeholder="Search..."
                    value={checkEmptyNull(reProductionOrderReducer.searchData.workOrderName, '')}
                    onInput={(e) => {
                      dispatch(reProductionOrderActions_setSearchData({ ...reProductionOrderReducer.searchData, workOrderName: e.target.value }));
                    }}
                  />
                </div>

                <div className="ContentCBox">
                  <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                  <div className="ResetButton" onClick={actReset}>초기화</div>
                </div>
              </div>
            }
          />

          <TableSection content={
            <table>
              <thead style={{ zIndex: '800' }}>
                <tr>
                  <th style={{ minWidth: '150px', padding: 'unset', width: '150px' }}></th>
                  <th style={{ minWidth: '150px', padding: 'unset', width: '150px' }}>
                    생산추적
                  </th>
                  <th style={{ minWidth: '120px', padding: 'unset', width: '120px' }}>
                    공정리스트
                  </th>
                  {(() => {
                    if (_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) {
                      return <th rowSpan={2} style={{ minWidth: '150px', padding: 'unset', width: '150px' }}>파일</th>;
                    } else return null;
                  })()}
                  <th style={{ minWidth: '120px', width: '120px' }}>
                    작업유형
                  </th>
                  <th>작업코드</th>
                  <th>작업이름</th>
                  {(() => {
                    if (_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) {
                      return <th rowSpan={2}>거래처</th>;
                    } else return null;
                  })()}
                  <th>생산목표수량</th>
                  <th>기간</th>
                  <th>작성자</th>
                  <th>책임자</th>
                  <th>작업자</th>
                </tr>
              </thead>
              <tbody>
                {!checkNullArray(_workOrderLogList, false) ? LoadingMsg() :
                  checkNullArray(_workOrderLogList, []).map((thisItem, index) => {
                    let processLogList = [];
                    if (checkNullArray(thisItem.processLogList, false)) {
                      const copyList = [...thisItem.processLogList];
                      copyList.sort((a, b) => { return a.processNumber - b.processNumber; });
                      processLogList = copyList;
                    }
                    // const thisProcess = processLogList[0];

                    return (
                      <Fragment key={thisItem.workOrderLogId + '_workOrderRow'}>
                        <tr data-key={thisItem.workOrderLogId}>
                          <td style={{ minWidth: '150px', padding: 'unset', width: '150px' }}>
                            <PrevButton onClick={(e) => {
                              e.preventDefault();
                              showPrevWorkOrder(thisItem);
                            }}
                            >
                              기존<br />작업지시
                              <GuideText />
                            </PrevButton>
                          </td>
                          <td style={{ minWidth: '150px', padding: 'unset', width: '150px' }}>
                            <QualityButton
                              data-workorderlogid={thisItem.workOrderLogId}
                              onClick={(e) => {
                                e.preventDefault();
                                showProductionProcessTracking(thisItem);
                              }}
                            >
                              생산추적
                              <GuideText />
                            </QualityButton>
                          </td>
                          <td>
                            <div className='btn-doc' onClick={(e) => {
                              e.preventDefault();
                              showProcessList(thisItem);
                            }}
                            >
                              <img src={baseInformationIcon} alt={'공정리스트 버튼'} />
                            </div>
                          </td>

                          {/* 파일 */}
                          {(() => {
                            if (_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) {
                              if (checkNullArray(thisItem.attachFileList, false)) {
                                return (
                                  <td className='table-preview'>
                                    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        showFileList(thisItem);
                                      }}
                                    >
                                      <img src={thisItem.attachFileList[0]?.fileData?.fileDataS3URL || ''} alt='image preview' className='img-preview' />
                                      <div className='btn-file'><DOCButtonIcon /></div>
                                    </div>
                                  </td>
                                );
                              } else {
                                return (
                                  <td className='table-preview'>
                                    <div
                                      style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        showFileList(thisItem);
                                      }}
                                    >
                                      <div className='no-image'><p>이미지가 없습니다.</p></div>
                                      <div className='btn-file'><DOCButtonIcon /></div>
                                    </div>
                                  </td>
                                );
                              }
                            }
                          })()}

                          <td style={{ minWidth: '120px', width: '120px' }}>{thisItem.workOrderTypeName}</td>
                          <td>{thisItem.workOrderCode}</td>
                          <td>{thisItem.workOrderName}</td>
                          {(() => {
                            if (_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) {
                              return <td>{thisItem.accountName || ''}</td>;
                            } else return null;
                          })()}
                          <td>
                            <CommaNum
                              displayType={'text'}
                              num={thisItem.amount}
                            />
                          </td>
                          <td>
                            {DateFormat(thisItem.workOrderActualStartDate) !== 'Invalid date'
                              ? DateFormat(thisItem.workOrderActualStartDate) : ''}
                            <br /> ~{' '}
                            {DateFormat(thisItem.workOrderActualEndDate) !== 'Invalid date'
                              ? DateFormat(thisItem.workOrderActualEndDate) : ''}
                          </td>
                          <td>{thisItem.userName}</td>
                          <td>
                            {checkNullArray(thisItem.managers, []).map((worker, index) => {
                              return (<p key={index + '_managers'}>{worker.managerName}</p>);
                            })}
                          </td>
                          <td>
                            {checkNullArray(thisItem.workers, []).map((worker, index) => {
                              return (<p key={index + '_workers'}>{worker.workerName}</p>);
                            })}
                          </td>
                        </tr>

                        {checkNullObject(_eventWorkOrderLog, false) &&
                          _eventWorkOrderLog.workOrderLogId === thisItem.parentId ? (
                          <tr style={{ backgroundColor: 'var(--gray-100)' }}>
                            <td style={{ minWidth: '150px', padding: 'unset', width: '150px' }}></td>
                            <td style={{ minWidth: '150px', padding: 'unset', width: '150px' }}>
                              <QualityButton onClick={(e) => {
                                e.preventDefault();
                                showProductionProcessTracking(_eventWorkOrderLog);
                              }}
                              >
                                생산추적
                                <GuideText />
                              </QualityButton>
                            </td>
                            <td>
                              <div className='btn-doc' onClick={(e) => {
                                e.preventDefault();
                                showProcessList(_eventWorkOrderLog);
                              }}
                              >
                                <img src={baseInformationIcon} alt={'공정리스트 버튼'} />
                              </div>
                            </td>

                            {/* 파일 */}
                            {(() => {
                              if (_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) {
                                if (checkNullArray(_eventWorkOrderLog.attachFileList, false)) {
                                  return (
                                    <td className='table-preview'>
                                      <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          showFileList(_eventWorkOrderLog);
                                        }}
                                      >
                                        <img src={_eventWorkOrderLog.attachFileList[0]?.fileData?.fileDataS3URL || ''} alt='image preview' className='img-preview' />
                                        <div className='btn-file'><DOCButtonIcon /></div>
                                      </div>
                                    </td>
                                  );
                                } else {
                                  return (
                                    <td className='table-preview'>
                                      <div
                                        style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          showFileList(_eventWorkOrderLog);
                                        }}
                                      >
                                        <div className='no-image'><p>이미지가 없습니다.</p></div>
                                        <div className='btn-file'><DOCButtonIcon /></div>
                                      </div>
                                    </td>
                                  );
                                }
                              }
                            })()}

                            <td style={{ minWidth: '120px', width: '120px' }}>{_eventWorkOrderLog.workOrderTypeName}</td>
                            <td>{_eventWorkOrderLog.workOrderCode}</td>
                            <td>{_eventWorkOrderLog.workOrderName}</td>
                            {(() => {
                              if (_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) {
                                return (<td>{_eventWorkOrderLog.accountName || ''}</td>);
                              } else return null;
                            })()}
                            <td>
                              <CommaNum
                                displayType={'text'}
                                num={_eventWorkOrderLog.amount}
                              />
                            </td>
                            <td>
                              {DateFormat(_eventWorkOrderLog.workOrderActualStartDate)}
                              <br /> ~{' '}
                              {DateFormat(_eventWorkOrderLog.workOrderActualEndDate)}
                            </td>
                            <td>{_eventWorkOrderLog.userName}</td>
                            <td>
                              {checkNullArray(_eventWorkOrderLog.managers, []).map((worker, index) => {
                                return (<p key={index + '_managers'}>{worker.managerName}</p>);
                              })}
                            </td>
                            <td>
                              {checkNullArray(_eventWorkOrderLog.workers, []).map((worker, index) => {
                                return (<p key={index + '_workers'}>{worker.workerName}</p>);
                              })}
                            </td>
                          </tr>
                        ) : null}
                      </Fragment>
                    );
                  })}
              </tbody>
            </table>
          }
            sectionStyle={{ height: 'calc(100% - 66px)' }}
          ></TableSection>
        </Section>

        <PagingComponent
          page={reProductionOrderReducer.pageNumber}
          count={reProductionOrderReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(reProductionOrderActions_setPageNumber(page));
          }}
        />

        {_processModalStatus === true && (
          <ShowWorkOrderLog
            status={false}
            workOrderLogId={_showWokrOrderLog}
            processList={_showProcessList}
            open={_processModalStatus}
            close={() => { setProcessModalStatus(false); }}
          />
        )}

        {_trackingModalStatus === true && (
          <ProductionProcessTracking
            productionId={_trackingWorkOrderLogId}
            open={_trackingModalStatus}
            close={() => { setTrackingModalStatus(false); }}
          />
        )}

        {_fileListModalStatus === true && _eventcontent.workOrderLogId !== undefined ? (
          <ShowFileList
            type={'workOrderLog'}
            content={_eventcontent}
            open={_fileListModalStatus}
            close={() => { setFileListModalStatus(false); }}
          />
        ) : null}
      </>
    }
    />
  );
};

export default ReProductionOrder;
