import React, { Fragment, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import GuideText from 'components/buttons/GuideText';
import SelectWorkPlaceModal from 'pages/types/modal/SelectWorkPlaceModal';

const DelButton = styled.div`
// 공정 삭제 버튼
  height: 15px;
  margin: 0px auto;
  width: 15px;
  div {background-color: var(--MainRed); cursor: pointer; height: 15px; width: 15px;}
`;

const SelectDiv = styled.div`
// 설비, 책임자, 작업자 선택 DIV
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  width: 100%;
  & p{margin-top: 10px;}
`;
const ProcessContentButton = styled.div`
// 설비, 책임자, 작업자 선택 버튼
  align-items: center;
  background-color: var(--ThirdBlue);
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 0.8em;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ContentList = styled.div`
// 책임자, 작업자 리스트
  width: 100%;
`;
const ContentBox = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  height: 40px;
  justify-content: flex-start;
  margin: 5px auto;
  padding: 0px 15px;
  position: relative;
  width: 100%;
`;
const DeleteButton = styled.div`
  height: 15px;
  position: absolute;
  right: 15px;
  width: 15px;
  div {background-color: var(--white); cursor: pointer; height: 15px; width: 15px;}
`;

const ElementStockLogButton = styled.td`
// 재고연동 버튼
  background-color: var(--MainYellow);
  color: var(--white);
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
`;

const UpdateProcessRow = (props) => {
  const [_processData, setProcessData] = useState(props.content);
  console.log('_processData : ', _processData);

  const [_processKey, setProcessKey] = useState(() => {
    if (props.content.processLogId !== undefined) return props.content.processLogId + '_' + props.thisIndex + '_prev';
    else return props.content.processId + '_' + props.thisIndex + '_new';
  });
  const [_processNumberArray, setProcessNumberArray] = useState(() => {
    const numberArray = [];
    for (let i = 1; i < props.processLength + 1; i++) {
      numberArray.push(i);
    }
    return numberArray;
  });

  const [_processEquipment, setProcessEquipment] = useState(() => { return { equipmentId: props.content.equipmentId, equipmentName: props.content.equipmentName } });

  const [_processPutElements, setProcessPutElements] = useState(() => {
    if (_processKey.toString().endsWith('_new') === true) {
      return props.content.processElementList.filter((thisItem) => thisItem.processElementType === 'In' || thisItem.processElementType === 'Put');
    } else {
      return checkNullArray(props.content.processLogElementList, []).filter((thisItem) => thisItem.processLogElementType === 'In' || thisItem.processLogElementType === 'Put');
    }
  });
  const [_processMakeElements, setProcessMakeElements] = useState(() => {
    if (_processKey.toString().endsWith('_new') === true) {
      return props.content.processElementList.filter((thisItem) => thisItem.processElementType === 'Out' || thisItem.processElementType === 'Make');
    } else {
      return checkNullArray(props.content.processLogElementList, []).filter((thisItem) => thisItem.processLogElementType === 'Out' || thisItem.processLogElementType === 'Make');
    }
  });
  const [_rowLength, setRowLength] = useState(_processPutElements.length > _processMakeElements.length ? _processPutElements.length : _processMakeElements.length);

  // 묶음처리횟수
  const [_processDivisors, setProcessDivisors] = useState(() => {
    if (checkEmptyNull(props.content.processAmount, false)) {
      const divisors = [];
      let index = 1;
      while (index <= props.content.processAmount) {
        if (props.content.processAmount % index === 0) divisors.push(index);
        index++;
      }
      divisors.sort((a, b) => b - a);
      return divisors;
    } else {
      return [1];
    }
  });
  // 클릭횟수
  const [_touchCount, setTouchCount] = useState(BigNumber(checkEmptyNull(props.content.processAmount, 0)).dividedBy(BigNumber(checkEmptyNull(_processData.processingCount, 0))).toNumber());

  const [_workPlace, setWorkPlace] = useState({});
  const [_workPlaceModalStatus, setWorkPlaceModalStatus] = useState(false);

  const [_alarmList, setAlarmList] = useState([]);
  const [_alarmModal, setAlarmModal] = useState(false);

  const [_viewStatus, setViewStatus] = useState(props.viewStatus);

  useEffect(() => {
    setProcessData(() => { return props.content });
    setProcessKey(() => {
      if (props.content.processLogId !== undefined) return props.content.processLogId + '_' + props.thisIndex + '_prev';
      else return props.content.processId + '_' + props.thisIndex + '_new';
    });
    setProcessNumberArray(() => {
      const numberArray = [];
      for (let i = 1; i < props.processLength + 1; i++) {
        numberArray.push(i);
      }
      return numberArray;
    });

    setProcessEquipment(() => {
      return { equipmentId: props.content.equipmentId, equipmentName: props.content.equipmentName }
    });

    setProcessPutElements(() => {
      if (_processKey.toString().endsWith('_new') === true) {
        return props.content.processElementList.filter((thisItem) => thisItem.processElementType === 'In' || thisItem.processElementType === 'Put');
      } else {
        return checkNullArray(props.content.processLogElementList, []).filter((thisItem) => thisItem.processLogElementType === 'In' || thisItem.processLogElementType === 'Put');
      }
    });
    setProcessMakeElements(() => {
      if (_processKey.toString().endsWith('_new') === true) {
        return props.content.processElementList.filter((thisItem) => thisItem.processElementType === 'Out' || thisItem.processElementType === 'Make');
      } else {
        return checkNullArray(props.content.processLogElementList, []).filter((thisItem) => thisItem.processLogElementType === 'Out' || thisItem.processLogElementType === 'Make');
      }
    });
    setRowLength(() => { return _processPutElements.length > _processMakeElements.length ? _processPutElements.length : _processMakeElements.length });

    setProcessDivisors(() => {
      if (checkEmptyNull(props.content.processAmount, false)) {
        const divisors = [];
        let index = 1;
        while (index <= props.content.processAmount) {
          if (props.content.processAmount % index === 0) divisors.push(index);
          index++;
        }
        divisors.sort((a, b) => b - a);
        return divisors;
      } else {
        return [1];
      }
    });
    setTouchCount(() => { return BigNumber(checkEmptyNull(props.content.processAmount, 0)).dividedBy(BigNumber(checkEmptyNull(_processData.processingCount, 0))).toNumber(); });

    setViewStatus(() => { return props.viewStatus });
  }, [props]);

  useEffect(() => { }, [_alarmList, _workPlace, _processData])

  const handleWorkPlace = (data) => {
    setWorkPlace(() => { return {} });
    const newDate = { ...data, place: {} };
    props.setProcessList((prev) => {
      const returnData = [...prev];
      returnData.splice(props.thisIndex, 1, newDate);
      return returnData;
    })
  }
  const handleEquipment = (data) => {
    setWorkPlace(() => { return {} });
    const newDate = { ...data, equipmentId: null, equipmentName: null };
    props.setProcessList((prev) => {
      const returnData = [...prev];
      returnData.splice(props.thisIndex, 1, newDate);
      return returnData;
    })
  }

  const returnTotalAmount = (amount) => {
    const unitAmount = BigNumber(checkEmptyNull(amount, 0));
    const processTotalAmount = BigNumber(checkEmptyNull(props.content.processAmount, 0));
    const totalAmount = unitAmount.multipliedBy(processTotalAmount);
    return totalAmount.toNumber();
  };

  const returnItems = () => {
    let returnArray = [];
    for (let i = 1; i < _rowLength; i++) {
      returnArray.push(
        <tr key={i + '_productRows'}>
          {_processMakeElements[i] !== undefined ? (
            <Fragment>
              {_viewStatus === 'detail' ? (
                <>
                  <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                    {checkNullObject(_processMakeElements[i].element, false) ? checkEmptyNull(_processMakeElements[i].element.elementName, '') : checkEmptyNull(_processMakeElements[i].elementName, '')}
                  </td>
                  <td>{checkNullObject(_processMakeElements[i].element, false) ? checkEmptyNull(_processMakeElements[i].element.elementCode, '') : checkEmptyNull(_processMakeElements[i].elementCode, '')}</td>
                  <td>
                    {checkEmptyNull(returnTotalAmount(_processMakeElements[i].amount), 0).toLocaleString() + ' '}
                    {checkNullObject(_processMakeElements[i].element, false) ? checkEmptyNull(_processMakeElements[i].element.elementUnit, '') : checkEmptyNull(_processMakeElements[i].elementUnit, '')}
                  </td>
                  <td>
                    {checkNullObject(_processMakeElements[i].element, false) ? checkEmptyNull(_processMakeElements[i].element.elementStock, 0).toLocaleString() + ' ' : checkEmptyNull(_processMakeElements[i].elementStock, 0).toLocaleString() + ' '}
                    {checkNullObject(_processMakeElements[i].element, false) ? checkEmptyNull(_processMakeElements[i].element.elementUnit, '') : checkEmptyNull(_processMakeElements[i].elementUnit, '')}
                  </td>
                </>
              ) : (
                <>
                  <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                    {checkNullObject(_processMakeElements[i].element, false) ? checkEmptyNull(_processMakeElements[i].element.elementName, '') : checkEmptyNull(_processMakeElements[i].elementName, '')}<br />
                    ({checkNullObject(_processMakeElements[i].element, false) ? checkEmptyNull(_processMakeElements[i].element.elementCode, '') : checkEmptyNull(_processMakeElements[i].elementCode, '')})
                  </td>
                  <td>
                    {checkEmptyNull(returnTotalAmount(_processMakeElements[i].amount), 0).toLocaleString() + ' '}
                    {checkNullObject(_processMakeElements[i].element, false) ? checkEmptyNull(_processMakeElements[i].element.elementUnit, '') : checkEmptyNull(_processMakeElements[i].elementUnit, '')}
                  </td>
                </>
              )}
            </Fragment>) : (
            <Fragment>
              {_viewStatus === 'detail' ? (
                <>
                  <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                  <td className="blankCell"></td>
                  <td className="blankCell"></td>
                  <td className="blankCell"></td>
                </>
              ) : (
                <>
                  <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                  <td className="blankCell"></td>
                </>
              )}
            </Fragment>
          )}
          {_processPutElements[i] !== undefined ? (
            <Fragment>
              {_viewStatus === 'detail' ? (
                <>
                  <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                    {checkNullObject(_processPutElements[i].element, false) ? checkEmptyNull(_processPutElements[i].element.elementName, '') : checkEmptyNull(_processPutElements[i].elementName, '')}
                  </td>
                  <td>{checkNullObject(_processPutElements[i].element, false) ? checkEmptyNull(_processPutElements[i].element.elementCode, '') : checkEmptyNull(_processPutElements[i].elementCode, '')}</td>
                  <td>
                    {checkEmptyNull(_processPutElements[i].amount, 0).toLocaleString() + ' '}
                    {checkNullObject(_processPutElements[i].element, false) ? checkEmptyNull(_processPutElements[i].element.elementUnit, '') : checkEmptyNull(_processPutElements[i].elementUnit, '')}
                  </td>
                  <td>
                    {checkEmptyNull(returnTotalAmount(_processPutElements[i].amount), 0).toLocaleString() + ' '}
                    {checkNullObject(_processPutElements[i].element, false) ? checkEmptyNull(_processPutElements[i].element.elementUnit, '') : checkEmptyNull(_processPutElements[i].elementUnit, '')}
                  </td>
                  <td>
                    {checkNullObject(_processPutElements[i].element, false) ? checkEmptyNull(_processPutElements[i].element.scheduledInputStock, 0).toLocaleString() + ' ' : checkEmptyNull(_processPutElements[i].scheduledInputStock, 0).toLocaleString() + ' '}
                    {checkNullObject(_processPutElements[i].element, false) ? checkEmptyNull(_processPutElements[i].element.elementUnit, '') : checkEmptyNull(_processPutElements[i].elementUnit, '')}
                  </td>
                  <td>
                    {checkNullObject(_processPutElements[i].element, false) ? checkEmptyNull(_processPutElements[i].element.elementStock, 0).toLocaleString() : checkEmptyNull(_processPutElements[i].elementStock, 0).toLocaleString()}
                    {checkNullObject(_processPutElements[i].element, false) ? checkEmptyNull(_processPutElements[i].element.elementUnit, '') : checkEmptyNull(_processPutElements[i].elementUnit, '')}
                  </td>
                  <ElementStockLogButton onClick={(e) => { e.preventDefault(); props.actTrackingElementStockLog(_processPutElements[i], `${props.thisIndex}_${i}`, _processData); }}>
                    재고연동<GuideText />
                  </ElementStockLogButton>
                </>
              ) : (
                <>
                  <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                    {checkNullObject(_processPutElements[i].element, false) ? checkEmptyNull(_processPutElements[i].element.elementName, '') : checkEmptyNull(_processPutElements[i].elementName, '')}<br />
                    ({checkNullObject(_processPutElements[i].element, false) ? checkEmptyNull(_processPutElements[i].element.elementCode, '') : checkEmptyNull(_processPutElements[i].elementCode, '')})
                  </td>
                  <td>
                    {checkEmptyNull(returnTotalAmount(_processPutElements[i].amount), 0).toLocaleString() + ' '}
                    {checkNullObject(_processPutElements[i].element, false) ? checkEmptyNull(_processPutElements[i].element.elementUnit, '') : checkEmptyNull(_processPutElements[i].elementUnit, '')}
                  </td>
                  <ElementStockLogButton onClick={(e) => { e.preventDefault(); props.actTrackingElementStockLog(_processPutElements[i], `${props.thisIndex}_${i}`, _processData); }}>
                    재고연동<GuideText />
                  </ElementStockLogButton>
                </>
              )}
            </Fragment>) : (
            <Fragment>
              {_viewStatus === 'detail' ? (
                <>
                  <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                  <td className="blankCell"></td>
                  <td className="blankCell"></td>
                  <td className="blankCell"></td>
                  <td className="blankCell"></td>
                  <td className="blankCell"></td>
                  <td className="blankCell"></td>
                </>
              ) : (
                <>
                  <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                  <td className="blankCell"></td>
                  <td className="blankCell"></td>
                </>
              )}
            </Fragment>
          )}
        </tr>
      );
    }
    return returnArray;
  };

  return (
    <Fragment key={_processKey}>
      <tr data-key={_processKey}>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ minWidth: '50px', width: '50px' }}>
          <DelButton onClick={() => { props.delEvent(props.thisIndex, _processData); }}><CloseButtonIcon /></DelButton>
        </td>
        {/* <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ minWidth: '60px', width: '60px' }}>
          <input
            type="checkBox"
            name="processes"
            checked={checkEmptyNull(_processData.target, false)}
            onChange={(e) => {
              const isChecked = e.target.checked;
              props.handleCheckedItem(props.thisIndex, _processData, isChecked);
              if (isChecked) {
                document.getElementsByName('processes').forEach((thisAttr) => { thisAttr.checked = false });
                e.target.checked = true;
              } else {
                e.target.checked = false;
              }
            }}
            onClick={(e) => { e.stopPropagation(); }}
          />
        </td> */}
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ minWidth: '80px', width: '80px' }}>
          <select
            name="processNumber"
            value={_processData.processNumber}
            onInput={(e) => { props.handleNumberEvent(e, props.thisIndex, _processData); }}
          >
            {_processNumberArray.map((processNumber) => {
              return (<option key={processNumber} value={processNumber}>{processNumber}</option>);
            })}
          </select>
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{_processData.processTypeName}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{_processData.processCode}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{_processData.processName}</td>

        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          <input
            type='date'
            value={checkEmptyNull(_processData.processScheduledStartDate, '')}
            onChange={(e) => {
              if (!checkEmptyNull(e.target.value, false)) {
                props.setProcessAlarmList((prev) => {
                  const returnData = [...prev];
                  const alarmIndex = returnData.findIndex(item => item.process.processLogId === _processData.processLogId || item.process.processId === _processData.processId);
                  returnData.splice(alarmIndex, 1);
                  return returnData;
                })
              }
              props.setProcessList((prev) => {
                const newDate = { ..._processData, processScheduledStartDate: e.target.value };
                const returnData = [...prev];
                returnData.splice(props.thisIndex, 1, newDate);
                return returnData;
              })
            }}
          />
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          <ProcessContentButton onClick={() => { setWorkPlaceModalStatus(true); }}>위치 선택</ProcessContentButton>
          {checkNullObject(_processData.place, false) ? (
            <ContentBox>
              {_processData.place?.placeName}
              <DeleteButton onClick={() => { handleWorkPlace(_processData); }}><CloseButtonIcon /></DeleteButton>
            </ContentBox>
          ) : null}
          {_workPlaceModalStatus === true && (
            <SelectWorkPlaceModal
              buttonTitle={'위치 선택'}
              buttonEvent={(data) => {
                setWorkPlace(() => { return data });
                // setProcessData((prev) => {return {...prev, place: data};})
                const newDate = { ..._processData, place: data };
                props.setProcessList((prev) => {
                  const returnData = [...prev];
                  returnData.splice(props.thisIndex, 1, newDate);
                  return returnData;
                })
                setTimeout(setWorkPlaceModalStatus(false), 1000);
              }}
              open={_workPlaceModalStatus}
              close={() => { setWorkPlaceModalStatus(false) }}
            />
          )}
        </td>

        {(props.authority.indexOf('108-1') !== -1 || props.authority.indexOf('108-2') !== -1) ? (
          <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ position: 'relative' }}>
            <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'flex-start', width: '100%' }}>
              <ProcessContentButton data-key={_processKey} onClick={props.selectEquipmentEvent}>선택</ProcessContentButton>
              {checkEmptyNull(_processEquipment.equipmentName, false) ? (
                <ContentBox name="processEquipemnt" data-key={_processKey}>
                  {_processEquipment.equipmentName}
                  <DeleteButton onClick={() => { handleEquipment(_processData); }}><CloseButtonIcon /></DeleteButton>
                </ContentBox>
              ) : null}
            </div>
          </td>
        ) : null}

        {_viewStatus === 'detail' ? <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{_processData.remark}</td> : null}

        {/* <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{_processData.remark}</td> */}
        {/* {(props.authority.indexOf('108-1') !== -1 || props.authority.indexOf('108-2') !== -1) ? (
          <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
            <SelectDiv>
              <ProcessContentButton onClick={() => { props.selectEquipmentEvent(_processKey) }}>선택</ProcessContentButton>
              <p name="processEquipemnt" data-key={_processKey}>{_processEquipment.equipmentName}</p>
            </SelectDiv>
          </td>
        ) : null} */}

        {/* <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ position: 'relative' }}>
          <SelectDiv>
            <ProcessContentButton
              onClick={() => {
                props.handleEventProcessLog(props.thisIndex, _processData);
                props.setWorkerType('processLogManagerIds');
                setTimeout(props.setWorkerModalStatus(true), 1000);
              }}
            >
              선택
            </ProcessContentButton>
            <ContentList>
              {checkNullArray(_processData.managers, []).map((manager, index) => {
                return (
                  <ContentBox key={props.thisIndex + '_' + index + '_processLogManagerIds'}>
                    <>{manager.name}</>
                    <DeleteButton
                      data-key={_processKey}
                      data-index={props.thisIndex}
                      onClick={(e) => {
                        e.preventDefault();
                        props.actDeleteWorkers('processLogManagerIds', props.thisIndex, index);
                      }}
                    >
                      <CloseButtonIcon />
                    </DeleteButton>
                  </ContentBox>
                );
              })}
            </ContentList>
          </SelectDiv>
        </td> */}

        {/* <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ position: 'relative' }}>
          <SelectDiv>
            <ProcessContentButton
              onClick={() => {
                props.handleEventProcessLog(props.thisIndex, _processData);
                props.setWorkerType('processLogWorkerIds');
                setTimeout(props.setWorkerModalStatus(true), 1000);
              }}
            >
              선택
            </ProcessContentButton>
            <ContentList>
              {checkNullArray(_processData.workers, []).map((worker, index) => {
                return (
                  <ContentBox key={props.thisIndex + '_' + index + '_processLogWorkerIds'}>
                    <>{worker.name}</>
                    <DeleteButton
                      data-key={_processKey}
                      data-index={props.thisIndex}
                      onClick={(e) => {
                        e.preventDefault();
                        props.actDeleteWorkers('processLogWorkerIds', props.thisIndex, index);
                      }}
                    >
                      <CloseButtonIcon />
                    </DeleteButton>
                  </ContentBox>
                );
              })}
            </ContentList>
          </SelectDiv>
        </td> */}

        {/* <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          <select
            name="processingCount"
            data-key={_processKey}
            data-index={props.thisIndex}
            value={_processData.processingCount}
            onChange={(e) => { props.handleProcessCountingEvent(e); }}
          >
            {_processDivisors.map((divisor) => {
              return (<option key={props.thisIndex + '_ProcessDivisors_' + divisor} value={divisor}>{divisor}</option>);
            })}
          </select>
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}><CommaNum displayType="text" num={_touchCount} /></td> */}

        {_processMakeElements[0] !== undefined ? (
          <Fragment>
            {_viewStatus === 'detail' ? (
              <>
                <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                  {checkNullObject(_processMakeElements[0].element, false) ? checkEmptyNull(_processMakeElements[0].element.elementName, '') : checkEmptyNull(_processMakeElements[0].elementName, '')}
                </td>
                <td>{checkNullObject(_processMakeElements[0].element, false) ? checkEmptyNull(_processMakeElements[0].element.elementCode, '') : checkEmptyNull(_processMakeElements[0].elementCode, '')}</td>
                <td>
                  {checkEmptyNull(returnTotalAmount(_processMakeElements[0].amount), 0).toLocaleString() + ' '}
                  {checkNullObject(_processMakeElements[0].element, false) ? checkEmptyNull(_processMakeElements[0].element.elementUnit, '') : checkEmptyNull(_processMakeElements[0].elementUnit, '')}
                </td>
                <td>
                  {checkNullObject(_processMakeElements[0].element, false) ? checkEmptyNull(_processMakeElements[0].element.elementStock, 0).toLocaleString() + ' ' : checkEmptyNull(_processMakeElements[0].elementStock, 0).toLocaleString() + ' '}
                  {checkNullObject(_processMakeElements[0].element, false) ? checkEmptyNull(_processMakeElements[0].element.elementUnit, '') : checkEmptyNull(_processMakeElements[0].elementUnit, '')}
                </td>
              </>
            ) : (
              <>
                <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                  {checkNullObject(_processMakeElements[0].element, false) ? checkEmptyNull(_processMakeElements[0].element.elementName, '') : checkEmptyNull(_processMakeElements[0].elementName, '')}<br />
                  ({checkNullObject(_processMakeElements[0].element, false) ? checkEmptyNull(_processMakeElements[0].element.elementCode, '') : checkEmptyNull(_processMakeElements[0].elementCode, '')})
                </td>
                <td>
                  {checkEmptyNull(returnTotalAmount(_processMakeElements[0].amount), 0).toLocaleString() + ' '}
                  {checkNullObject(_processMakeElements[0].element, false) ? checkEmptyNull(_processMakeElements[0].element.elementUnit, '') : checkEmptyNull(_processMakeElements[0].elementUnit, '')}
                </td>
              </>
            )}
          </Fragment>) : (
          <Fragment>
            {_viewStatus === 'detail' ? (
              <>
                <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                <td className="blankCell"></td>
                <td className="blankCell"></td>
                <td className="blankCell"></td>
              </>
            ) : (
              <>
                <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                <td className="blankCell"></td>
              </>
            )}
          </Fragment>
        )}
        {_processPutElements[0] !== undefined ? (
          <Fragment>
            {_viewStatus === 'detail' ? (
              <>
                <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                  {checkNullObject(_processPutElements[0].element, false) ? checkEmptyNull(_processPutElements[0].element.elementName, '') : checkEmptyNull(_processPutElements[0].elementName, '')}
                </td>
                <td>{checkNullObject(_processPutElements[0].element, false) ? checkEmptyNull(_processPutElements[0].element.elementCode, '') : checkEmptyNull(_processPutElements[0].elementCode, '')}</td>
                <td>
                  {checkEmptyNull(_processPutElements[0].amount, 0).toLocaleString() + ' '}
                  {checkNullObject(_processPutElements[0].element, false) ? checkEmptyNull(_processPutElements[0].element.elementUnit, '') : checkEmptyNull(_processPutElements[0].elementUnit, '')}
                </td>
                <td>
                  {checkEmptyNull(returnTotalAmount(_processPutElements[0].amount), 0).toLocaleString() + ' '}
                  {checkNullObject(_processPutElements[0].element, false) ? checkEmptyNull(_processPutElements[0].element.elementUnit, '') : checkEmptyNull(_processPutElements[0].elementUnit, '')}
                </td>
                <td>
                  {checkNullObject(_processPutElements[0].element, false) ? checkEmptyNull(_processPutElements[0].element.scheduledInputStock, 0).toLocaleString() + ' ' : checkEmptyNull(_processPutElements[0].scheduledInputStock, 0).toLocaleString() + ' '}
                  {checkNullObject(_processPutElements[0].element, false) ? checkEmptyNull(_processPutElements[0].element.elementUnit, '') : checkEmptyNull(_processPutElements[0].elementUnit, '')}
                </td>
                <td>
                  {checkNullObject(_processPutElements[0].element, false) ? checkEmptyNull(_processPutElements[0].element.elementStock, 0).toLocaleString() : checkEmptyNull(_processPutElements[0].elementStock, 0).toLocaleString()}
                  {checkNullObject(_processPutElements[0].element, false) ? checkEmptyNull(_processPutElements[0].element.elementUnit, '') : checkEmptyNull(_processPutElements[0].elementUnit, '')}
                </td>
                <ElementStockLogButton onClick={(e) => { e.preventDefault(); props.actTrackingElementStockLog(_processPutElements[0], `${props.thisIndex}_${0}`, _processData); }}>
                  재고연동<GuideText />
                </ElementStockLogButton>
              </>
            ) : (
              <>
                <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                  {checkNullObject(_processPutElements[0].element, false) ? checkEmptyNull(_processPutElements[0].element.elementName, '') : checkEmptyNull(_processPutElements[0].elementName, '')}<br />
                  ({checkNullObject(_processPutElements[0].element, false) ? checkEmptyNull(_processPutElements[0].element.elementCode, '') : checkEmptyNull(_processPutElements[0].elementCode, '')})
                </td>
                <td>
                  {checkEmptyNull(returnTotalAmount(_processPutElements[0].amount), 0).toLocaleString() + ' '}
                  {checkNullObject(_processPutElements[0].element, false) ? checkEmptyNull(_processPutElements[0].element.elementUnit, '') : checkEmptyNull(_processPutElements[0].elementUnit, '')}
                </td>
                <ElementStockLogButton onClick={(e) => { e.preventDefault(); props.actTrackingElementStockLog(_processPutElements[0], `${props.thisIndex}_${0}`, _processData); }}>
                  재고연동<GuideText />
                </ElementStockLogButton>
              </>
            )}
          </Fragment>) : (
          <Fragment>
            {_viewStatus === 'detail' ? (
              <>
                <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                <td className="blankCell"></td>
                <td className="blankCell"></td>
                <td className="blankCell"></td>
                <td className="blankCell"></td>
                <td className="blankCell"></td>
                <td className="blankCell"></td>
              </>
            ) : (
              <>
                <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                <td className="blankCell"></td>
                <td className="blankCell"></td>
              </>
            )}
          </Fragment>
        )}
      </tr>
      {_rowLength > 1 && returnItems()}
    </Fragment>
  );
};

export default UpdateProcessRow;
