const initialState = {
  cart: [],
  clearStatus: false,
};

const resetState = {
  cart: [],
  clearStatus: false,
};

const mallSiteCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETMALLSITEADDCART':
      return {
        ...state,
        cart: action.payload,
      };
    case 'SETMALLSITECLEARSTATUS':
      return {
        ...state,
        clearStatus: action.payload,
      };
    case 'SETMALLSITERESETCART':
      return resetState;
    default:
      return state;
  }
};

export default mallSiteCartReducer;
