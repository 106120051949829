import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { drawingManagementActions_setPageNumber, drawingManagementActions_setReset, drawingManagementActions_setSearchText, drawingManagementActions_setTotalSize } from 'store/modules/actions/common/drawingManagementActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { fileDataApi } from 'api/apis/fileDataApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import Grid3Body from 'components/layouts/body/Grid3Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const Section = styled.main`
  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
  }
`;

const DrawingCell = styled.td`
  embed {height: auto; width: 100%;}
  &:hover {outline: 5px solid var(--MainNavy); outline-offset: -10px;}
`;
const DrawingImg = styled.img`
  display: block;
  width: 100%;
`;

const DrawingManagement = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { drawingManagementReducer, pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_fileDataList, setFileDataList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('110') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getDrawings(drawingManagementReducer.pageNumber - 1);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getDrawings(drawingManagementReducer.pageNumber - 1);
    }

    return () => { };
  }, [drawingManagementReducer.pageNumber]);

  /* ====================================================================== #4 */
  // 도면관리 데이터 get
  const getDrawings = async (page, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      fileCategoryId: 121,
      // fileDataClass: 'drawing',
    };
    if (!clear && checkEmptyNull(drawingManagementReducer.searchText, false)) {
      BodyToPost.fileDataName = drawingManagementReducer.searchText;
    }
    await fileDataApi.searchFileData(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('fileDataApi.searchFileData : ', response);
      setFileDataList(() => { return response.data.content; });
      dispatch(drawingManagementActions_setTotalSize(response.data.totalElements));
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set([..._checkedItem]);
    if (isChecked) checkedItem.add(id);
    else if (!isChecked && checkedItem.has(id)) checkedItem.delete(id);
    setCheckedItem(() => { return checkedItem });
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(drawingManagementActions_setPageNumber(1));
    await getDrawings(0);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(drawingManagementActions_setReset());
    dispatch(drawingManagementActions_setPageNumber(1));
    await getDrawings(0, true);
  };

  // 삭제
  const actDelete = () => {
    const checkedList = Array.from(_checkedItem);
    console.log('checkedList : ', checkedList);
    if (checkedList.length < 1) return;
    const deleteContents = checkedList.map((fileDataId) => {
      const checkedIndex = _fileDataList.findIndex((item) => item.fileDataId === fileDataId);
      if (checkedIndex === -1) return null;
      const checkedContent = _fileDataList[checkedIndex];
      return { deleteContent: { ...checkedContent } };
    });
    const setDeleteContents = deleteContents.filter((item) => item !== null);
    console.log('deleteContents : ', setDeleteContents);

    if (setDeleteContents.length < 1) return;
    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({ deleteContents: JSON.stringify(setDeleteContents) })}`,
      replace: true,
    });
  };

  /* ====================================================================== #6 */

  return (
    <Grid3Body contents={
      <>
        <Section className="Main">
          <NavBar
            title={
              <>
                <div className='Title'>
                  <NavTitle menuCode={'110'} />

                  <div className="SearchSection">
                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="fileDataName">도면이름</option>
                      </select>
                      <input
                        className="SearchBar detail"
                        placeholder="Search..."
                        value={drawingManagementReducer.searchText}
                        onInput={(e) => { dispatch(drawingManagementActions_setSearchText(e.target.value)); }}
                      />
                    </div>

                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton" onClick={actReset}>초기화</div>
                  </div>
                </div>
              </>
            }
            buttons={
              <>
                {_authority.indexOf('110-2') !== -1 ? (
                  <>
                    <button className='btn-add'
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(pageReducer.currentPage + '/create', { replace: true });
                      }}
                    >
                      추가
                    </button>
                    <button className='btn-delete' onClick={actDelete}>삭제</button>
                  </>
                ) : null}
              </>
            }
            nav={''}
          />
          <TableSection content={
            <table>
              <thead>
                <tr>
                  <th style={{ minWidth: '200px', width: '200px' }}>파일</th>
                  {_authority.indexOf('110-2') !== -1 ? <th style={{ minWidth: '50px', width: '50px' }}></th> : null}
                  <th>도면이름</th>
                </tr>
              </thead>
              <tbody>
                {checkNullArray(_fileDataList, []).map((fileData) => {
                  return (
                    <tr key={fileData.fileDataId + 'DrawingRow'}>
                      <DrawingCell
                        style={{ cursor: 'pointer', height: '200px', minWidth: '200px' }}
                        onClick={() => { window.open(fileData.fileDataS3URL, fileData.fileDataName); }}
                      >
                        {fileData.fileDataType === 'application/pdf' ? (
                          <embed src={fileData.fileDataS3URL} />
                        ) : (
                          <DrawingImg src={fileData.fileDataS3URL}></DrawingImg>
                        )}
                      </DrawingCell>

                      {_authority.indexOf('110-2') !== -1 ? (
                        <td style={{ minWidth: '50px', width: '50px' }}>
                          <input
                            type="checkBox"
                            name="fileData"
                            data-key={fileData.fileDataId}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              handleCheckedItem(fileData.fileDataId, isChecked);
                              if (isChecked) e.target.checked = true;
                              else e.target.checked = false;
                            }}
                            onClick={(e) => { e.stopPropagation() }}
                          />
                        </td>
                      ) : null}
                      <td>{fileData.fileDataName}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          }
          ></TableSection>
        </Section>

        <PagingComponent
          page={drawingManagementReducer.pageNumber}
          count={drawingManagementReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(drawingManagementActions_setPageNumber(page));
          }}
        />
      </>
    }
    />
  );
};

export default DrawingManagement;
