import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import bwipjs from 'bwip-js'
import { saveAs } from 'file-saver'

import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import NavBar from 'components/nav/NavBar';

import ReactToPrint from "react-to-print";

import { forwardRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { elementActions_setElementType, elementActions_setSearchData, elementActions_setSearchAttrs, elementActions_setPageNumber, elementActions_setTotalSize, elementActions_setReset, elementActions_setClickedLevel3Category, elementActions_setClickedLevel2Category, elementActions_setClickedLevel1Category, elementActions_setBookmark } from 'store/modules/actions/common/elementActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { bookmark } from 'api/apis/bookmark/bookmark';
import { elementApi } from 'api/apis/elementApi';
import { elementAttributeApi } from 'api/apis/elementAttributeApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { LoadingMsg } from 'components/loading/LoadingMsg';

import Bookmark from 'components/icons/Bookmark';
import Categories from 'components/category/Categories';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import DOCButtonIcon from 'components/icons/DOCButtonIcon';
import ElementUploadModal from 'components/excel/upload/ElementUploadModal';
import ExcelDownloadModal from 'components/excel/ExcelDownloadModal';
import Grid3Body from 'components/layouts/body/Grid3Body';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import RelatedWorkOrder from 'pages/workOrder/modal/RelatedWorkOrder';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import ShowFileList from 'pages/file/modal/ShowFileList';
import TableSection from 'components/layouts/table/TableSection';
import ShipmentInspectionFileModal from 'pages/shipmentInspection/modal/ShipmentInspectionFileModal';
import BarcodeOutElementModal from 'pages/barcode/print/ElementModal';


export default function BarcodeOutElement() {
  const [_navStatus, setNavStatus] = useState(false);
  const [_nav, setNav] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { elementReducer, pageReducer, userReducer } = useSelector((state) => state);
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_originAttrList, setOriginAttrList] = useState([]);
  const [_elementAttrList, setElementAttrList] = useState([]);
  const [_elementAttrNameList, setElementAttrNameList] = useState({});

  const [_originList, setOriginList] = useState([]);
  const [_elementList, setElementList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_searchOption, setSearchOption] = useState('');
  const [_searchText, setSearchText] = useState('');

  const [_detailStatus, setDetailStatus] = useState(false);

  const [_downloadModalStatus, setDownloadModalStatus] = useState(false);
  const [_uploadModalStatus, setUploadModalStatus] = useState(false);

  const [_relatedWorkOrderModalStatus, setRelatedWorkOrderModalStatus] = useState(false);
  const [_eventElementId, setEventElementId] = useState();

  const [_fileListModalStatus, setFileListModalStatus] = useState(false);
  const [_eventContent, setEventContent] = useState({});

  const [createBarcode, setCreateBarcode] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  
  const [_eventShipmentInspection, setEventShipmentInspection] = useState({});
  const [_modalStatus, setModalStatus] = useState(false);

  const [content, setContent] = useState("");

  const refs = useRef([]);

  const selectedRefs = useRef();

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('101') === true || // 기준정보
      thisItem.authority.startsWith('109') === true || // 파일
      thisItem.authority.startsWith('111') === true, // 작업정의
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    (async () => {
      await getElementAttrs(elementReducer.elementType);
      await getElementList(elementReducer.pageNumber - 1, elementReducer.elementType);
    })();

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getElementList(elementReducer.pageNumber - 1, elementReducer.elementType);
    }
    return () => { };
  }, [elementReducer.pageNumber]);

  useEffect(() => {
    if (_onload === 'loaded') {
      dispatch(elementActions_setPageNumber(1));
      getElementList(0, elementReducer.elementType);
    }
    return () => { };
  }, [elementReducer.bookmark, elementReducer.clickedCategory]);

  useEffect(() => { }, [elementReducer]);

  /* ====================================================================== #4 */
  const getElementAttrs = async (elementType) => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (checkEmptyNull(elementType, false) && elementType !== 'all') {
      BodyToPost.elementType = elementType;
    }
    console.log('getElementAttrs - BodyToPost : ', BodyToPost);

    await elementAttributeApi.searchElementAttribute(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('elementAttributeApi.searchElementAttribute : ', response);
      let temp = [];
      let result = [];
      response.data.forEach((thisItem) => {
        if (!temp.includes(thisItem.elementAttributeName)) {
          temp.push(thisItem.elementAttributeName);
          result.push(thisItem);
        }
      });
      setOriginAttrList(() => { return response.data });
      setElementAttrList(() => { return result });

      const nameList = {};
      response.data.forEach((thisItem) => {
        nameList[thisItem.elementAttributeId] = thisItem.elementAttributeName;
      });
      setElementAttrNameList(() => { return nameList });
    });
  };

  const getElementList = async (page, elementType, clear) => {
    let paging = `?page=${page}&size=15`;
    if (elementReducer.bookmark) paging += '&sort=bookMark,DESC&sort=id,DESC';
    else paging += '&sort=id,DESC';

    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && checkEmptyNull(elementType, false) && elementType !== 'all') {
      BodyToPost.elementType = elementType;
    }
    if (!clear && checkNullObject(elementReducer.clickedCategory, false) && elementReducer.clickedCategory !== 'all') {
      BodyToPost.categoryId = elementReducer.clickedCategory.categoryId;
    }

    if (!clear && checkEmptyNull(elementReducer.searchData.elementName, false)) {
      BodyToPost.elementName = elementReducer.searchData.elementName;
    }
    if (!clear && checkEmptyNull(elementReducer.searchData.elementCode, false)) {
      BodyToPost.elementCode = elementReducer.searchData.elementCode;
    }

    if (!clear && _detailStatus && checkNullObject(elementReducer.searchAttrs, false)) {
      BodyToPost.attributeAndValue = elementReducer.searchAttrs;
    }

    await elementApi.searchElement(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('elementApi.searchElement : ', response);
      setOriginList(() => { return response.data.content; });
      setElementList(() => { return response.data.content; });

      dispatch(elementActions_setTotalSize(response.data.totalElements));
    });
  };

  /* 같은 항목 모으기 */
  const forRenderElementAttrs = () => {
    let temp = [];
    let result = [];
    _elementAttrList.forEach((thisItem) => {
      if (!temp.includes(thisItem.elementAttributeName)) {
        temp.push(thisItem.elementAttributeName);
        result.push(<th key={thisItem.elementAttributeId + '_elementAttrs'}>{thisItem.elementAttributeName}</th>);
      }
    });
    return result;
  };

  /* 초기화 */
  const actReset = async () => {
    setSearchOption(() => { return '' });
    setSearchText(() => { return '' });

    dispatch(elementActions_setReset());

    dispatch(elementActions_setPageNumber(1));
    await getElementAttrs('all');
    await getElementList(0, 'all', true);
  };
  
  /* 상세보기 */
  const goDetails = (element) => {
    const elementIndex = _originList.findIndex((thisItem) => thisItem.elementId === element.elementId);
    const elementData = _originList[elementIndex];
    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({
        version: 1,
        updateContent: JSON.stringify({
          ...elementData,
          elementAttributeList: _originAttrList.filter((thisItem) => thisItem.elementType === elementData.elementType),
        }),
      })}`,
      replace: true,
    });
  };

  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set([..._checkedItem]);
    if (isChecked) checkedItem.add(id);
    else if (!isChecked && checkedItem.has(id)) checkedItem.delete(id);

    setCheckedItem(() => { return checkedItem });
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(elementActions_setPageNumber(1));
    await getElementList(0, elementReducer.elementType);
  };

  /* 수정 */
  const actUpdate = () => {
    const checkedElementList = Array.from(_checkedItem);

    if (checkedElementList.length < 1) return;
    else if (checkedElementList.length > 1) return alert('다중 선택되어 있습니다.');

    const elementIndex = _originList.findIndex((thisItem) => thisItem.elementId === checkedElementList[0]);
    if (elementIndex === -1) return;
    const elementData = _originList[elementIndex];

    navigate({
      pathname: pageReducer.currentPage + '',
      search: `?${createSearchParams({
        version: 1,
        updateContent: JSON.stringify({
          ...elementData,
          elementAttributeList: _originAttrList.filter((thisItem) => thisItem.elementType === elementData.elementType),
        }),
      })}`,
      replace: true,
    });
  };

  const generateBarcode = (thisItem) => {
    const canvas = document.createElement('canvas')
    bwipjs.toCanvas(canvas, {
      bcid: 'code128', 
      scale: 2, 
      height: 8, 
      includetext: false, 
      textxalign: 'center', 
      backgroundcolor: 'FFFFFF', 
      padding: 8,
      paddingright: 20,
      text: `A_${thisItem.elementId}`,
    })
    return canvas.toDataURL('image/png')
  }

  const [barcodeInfo, setBarcodeInfo] = useState(false);
  const BarcodeInfo = () => {
    setBarcodeInfo(true);
  }

  const BarcodeRenderComponent = forwardRef((props, ref) => {
    return (
      <div ref={ref} className='barcodeImage'>
        <div>
            <img src={generateBarcode(props.thisItem)} />
            <ul>
              <li>1. 기준정보 ID : <b>A_{props.thisItem.elementId}</b></li>
              <li>2. 물품코드 : {props.thisItem.elementCode}</li>
              <li>3. 물품이름 : {props.thisItem.elementName}</li>
            </ul>
        </div>
      </div>
    );
  });

  const handleOnKeyUp = e => {
    if (e.key === 'Enter') {
      actSearch();
    }
  };

  return (
    <>
      <Header />
      <NavBar
        title={
          <div className='Title'>
            <Link to='/barcode/scan'className='btn-barcode'>바코드 스캔</Link>
            <Link to='/barcode/print' className='btn-barcode btn-barcode-active'>바코드 출력</Link>
          </div>
        }
        buttons={
          <>
            <Link to='/barcode/print/element'className='btn-barcode btn-barcode-active'><b>A</b>기준정보</Link>
            <Link to='/barcode/print/stock' className='btn-barcode'><b>B</b>재고상세이력</Link>
            <Link to='/barcode/print/lot' className='btn-barcode'><b>C</b>LOT</Link>
            <Link to='/barcode/print/serial' className='btn-barcode'><b>D</b>Serial</Link>
            <input
              className="barcodeSearch" placeholder='검색'
              value={checkEmptyNull(elementReducer.searchData.elementCode, '')}
              onInput={(e) => {
                dispatch(elementActions_setSearchData({ ...elementReducer.searchData, elementCode: e.target.value }));
              }}
              onKeyUp={handleOnKeyUp}
            />
            <div className="DetailButton search" style={{ backgroundColor: 'var(--ThirdBlue)', columnGap: '3px' }}
              onClick={actSearch}
            >
              검색
            </div>
            <div className="ResetButton detail" onClick={actReset}>초기화</div>
            <ReactToPrint
              trigger={() => <img src={process.env.PUBLIC_URL + '/src/icons/print.svg'}/>}
              content={() => selectedRefs.current}
            />
          </>
        }
        nav=''
      />
      <div className='barcode'>
        <div className='bacodeScan'>
        <TableSection
          content={
            <>
            <table className='barcodeTable'>
              <thead>
                <tr>
                  {_authority.indexOf('101-2') !== -1 ? (
                    <th style={{ minWidth: '50px', width: '50px' }}>
                      <input
                        type="checkBox"
                        name="allElements"
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          const checkedItem = new Set([..._checkedItem]);

                          if (isChecked) {
                            document.getElementsByName('elements').forEach((thisAttr) => {
                              checkedItem.add(Number(thisAttr.dataset.key));
                              thisAttr.checked = true;
                            });

                            e.target.checked = true;
                          } else {
                            document.getElementsByName('elements').forEach((thisAttr) => {
                              checkedItem.delete(Number(thisAttr.dataset.key));
                              thisAttr.checked = false;
                            });
                            e.target.checked = false;
                          }

                          setCheckedItem(() => { return checkedItem });
                        }}
                      />
                    </th>
                  ) : null}
                  <th>기준정보 </th>
                  <th>바코드</th>
                  <th>인쇄</th>
                </tr>
              </thead>
              <tbody>
                {!checkNullArray(_elementList, false) ? LoadingMsg() :
                  checkNullArray(_elementList, []).map((thisItem, index) => {
                    return (
                      <tr key={index + '_elements'}
                        name={_authority.indexOf('101-2') !== -1 && 'elementRows'}
                      >
                        {_authority.indexOf('101-2') !== -1 ? (
                          <td style={{ minWidth: '50px', width: '50px' }}>
                            <input
                              type="checkBox"
                              name="elements"
                              data-key={thisItem.elementId}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                handleCheckedItem(thisItem.elementId, isChecked);
                                if (isChecked) e.target.checked = true;
                                else e.target.checked = false;
                              }}
                              onClick={(e) => {
                                e.stopPropagation()
                              }}
                            />
                          </td>
                        ) : null}
                        <td>
                          <button className='barcodeId'>A_{thisItem.elementId}</button>
                        </td>
                        <td>
                          <BarcodeRenderComponent
                            ref={el => refs.current[index] = el}
                            thisItem={thisItem}
                          />
                        </td>
                        <td className='barcodePrintBtn'>
                          <button>
                            <ReactToPrint
                              trigger={() => <img src={process.env.PUBLIC_URL + '/src/icons/print.svg'}/>}
                              content={() => refs.current[index]}
                              />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                }
                <div ref={selectedRefs} style={{position: 'absolute', zIndex: '-999', top: '0px'}}>
                  {_elementList.filter((i)=>{
                    return _checkedItem.has(i.elementId)
                  }).map((item) => <BarcodeRenderComponent thisItem={item}/>)}
                </div>
              </tbody>
            </table>
            <PagingComponent
              page={elementReducer.pageNumber}
              count={elementReducer.totalSize}
              size={15}
              pageEvent={(page) => {
                console.log('page : ', page);
                dispatch(elementActions_setPageNumber(page));
              }}
            />
            </>
          }
        ></TableSection>
        </div>
      </div>
      <Footer />
    </>
  )
}
