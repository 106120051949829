import styled from 'styled-components';

export const MainContents = styled.main`
  box-sizing: border-box;
  column-gap: 10px;
  display: grid;
  grid-template-columns: auto 150px;
  justify-content: unset;
  padding: 0 20px;
  width: 100%;

  .TableSection {
    & td,
    & th {
      & input[name='amount'] {
        background-color: var(--ThirdBlue);
        box-sizing: border-box;
        color: var(--white);
        font-size: 2em;
        font-weight: 600;
        height: 50%;
        max-width: 250px;
        padding: 10px;
      }
      & textarea {
        border: 1px solid var(--gray-200);
        box-sizing: border-box;
        height: 50%;
        padding: 10px;
      }
    }
  }
`;
export const EtcSection = styled.div`
  display: grid;
  grid-gap: 10px;
  & input,
  & select {
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    min-height: 40px;
    padding: 10px;
  }
`;
export const DelButton = styled.div`
  height: 15px;
  margin: 0px auto;
  width: 15px;
  div {
    background-color: var(--MainRed);
    cursor: pointer;
    height: 20px;
    width: 20px;
  }
`;

export const ButtonSection = styled.div`
  display: grid;
  grid-template-rows: 150px auto;
  row-gap: 10px;
  height: 100%;
`;
export const Button = styled.div`
  align-items: center;
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  width: 100%;
  &:first-child {
    background-color: var(--MainNavy);
  }
  &:last-child {
    padding: 24px;

    &[data-type='In'] {
      background-color: var(--MainGreen);
    }
    &[data-type='Put'] {
      background-color: var(--MainYellow);
    }
    &[data-type='Out'] {
      background-color: var(--MainRed);
    }
    &[data-type='Consume'] {
      background-color: var(--MainYellow);
    }
  }
`;
