import React, { useEffect } from 'react';

import { PMMenuComponent, PMSideBarComponent } from './PMSideBar.style';
import { useLocation } from 'react-router-dom';

const PMSideBar = () => {
  const location = useLocation();
  useEffect(() => { }, [location.pathname]);

  return (
    <PMSideBarComponent>
      <h1>프로젝트</h1>
      {[
        { title: '일정 관리', link: '/operator/projectManagement', },
        { title: '유형 관리', link: '/operator/projectManagement/type', }
      ].map((menu, index) => {
        return (
          <PMMenuComponent key={index + '_PMMenu'} to={menu.link}
            className={location.pathname === menu.link ? 'active' : ''}
          >
            {menu.title}
          </PMMenuComponent>
        )
      })}
    </PMSideBarComponent>
  );
};

export default PMSideBar;