import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Chart from 'react-apexcharts';
import moment from 'moment';
import styled from 'styled-components';

import { sensorDataApi } from 'api/apis/sensorDataApi';

import { checkNullArray } from 'components/checkValues/checkValues';

import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

const Main = styled.main`
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 60px calc(100% - 60px);
`;
const Section = styled.main`
  box-sizing: border-box;
  display: grid;
  height: 100%;
  overflow-y: scroll;
  padding: 30px;
  width: 100%;

  &>div {
    background-color: var(--white);
    border-radius: 10px;
    display: grid;
    grid-template-rows: 50px calc(100% - 50px);
    height: 100%;
    overflow: hidden;
    width: 100%;

    h4 {
      background-color: var(--MainNavy);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
      box-sizing: border-box;
      color: var(--white);
      display: inline-block;
      /* font-size: 1.4em; */
      line-height: 50px;
      padding-inline-start: 20px;
      text-align: left;
    }
  }
`;

const Search = () => {
  const SearchContent = useRef();
  const SearchChart = useRef();

  const options = {
    chart: {
      id: moment().format('YYYY-MM-DD') + '_기간_사용량_조회',
      toolbar: { show: true },
    },
    grid: {
      xaxis: { lines: { show: false } },
      yaxis: { lines: { show: true } },
      padding: { right: 30 }
    },
    dataLabels: { enabled: false },
    xaxis: {
      type: '시간',
      categories: [],
      tickPlacement: 'on',
    },
    yaxis: {
      min: 0,
      tickAmount: 5,
      labels: { formatter: (val) => { return val.toFixed(2) + ' kWh' } }
    }
  };
  const [_options, setOptions] = useState(options);

  const series = {
    name: '전력',
    data: [],
  };
  const [_series, setSeries] = useState([series]);



  useEffect(() => { getSensorData(); return () => { } }, []);
  useEffect(() => { }, [_options, _series]);

  const [_height, setHeight] = useState(250);
  useEffect(() => {
    const handleResize = () => { setHeight(() => { return SearchContent.current.offsetHeight - 50 }); };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize); }
  }, []);

  const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    useEffect(() => { savedCallback.current = callback; }, [callback]);
    useEffect(() => {
      const tick = () => { savedCallback.current(); };
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };
  useInterval(async () => { await getSensorData(); }, 600000);

  const getSensorData = async () => {
    const body = {
      companyId: 3,
      startDate: moment().startOf('date').add(9, 'hour').toISOString()?.replace(/\.\d+Z/, ''),
      endDate: moment().add(9, 'hour').toISOString()?.replace(/\.\d+Z/, ''),
      name: 'power',
    }
    const search = `?companyId=${body.companyId}&startDate=${body.startDate}&endDate=${body.endDate}&name=${body.name}`;
    await sensorDataApi.getSensorDatas(search).then(response => {
      if (response === undefined) return;
      console.log('sensorDataApi.getSensorDatas : ', response);
      const array = response.data;
      const result = {};
      array.forEach(data => {
        const date = moment(data.date).subtract(9, 'hour').format('YYYY-MM-DD HH');
        if (!checkNullArray(result[date], false)) {
          result[date] = [];
          result[date].push(data);
        } else {
          result[date].push(data);
        }
      })
      const returnDataList = Object.keys(result).map(hourData => {
        const data = result[hourData];
        const valueList = data.map(item => Math.floor(Math.random() * 10));
        // const valueList = data.map(item => item.value);
        const average = (valueList.reduce((a, b) => (a * 1) + (b * 1), 0)) / valueList.length;
        const returnData = { date: hourData, value: Math.floor(average) };
        return returnData;
      });
      returnDataList.reverse();
      setOptions((prev) => {
        const returnData = {
          ...prev,
          xaxis: { ...prev.xaxis, categories: returnDataList.map(item => moment(item.date).format('HH:00')) }
        };
        for (let i = returnDataList.length; i < 25; i++) {
          returnData.xaxis.categories.push(`${i.toString().padStart(2, '0')}:00`);
        }
        return returnData;
      })
      setSeries(() => {
        const returnData = { ...series, data: returnDataList.map(item => item.value) };
        for (let i = returnDataList.length; i < 25; i++) {
          returnData.data.push(0);
        }
        return [returnData];
      });
    })
  }

  return (
    <Grid2Body contents={
      <Main className="Main">
        <NavBar
          title={'기간 사용량 조회'}
          buttons={
            <>
              <Link to={'/monitoring/fems/gs'}>
                <div className='btn-set' style={{ backgroundColor: 'var(--MainNavy)', color: 'var(--white)', fontWeight: '600' }}><NavTitle menuCode={'705'} /></div>
              </Link>
            </>
          }
          nav={''}
        />
        <Section>
          <div ref={SearchContent} className='Search'>
            <h4>{moment().format('YYYY-MM-DD')} 기간 사용량 조회</h4>
            <Chart ref={SearchChart} options={_options} series={_series} type='area' width={'100%'} height={_height} />
          </div>
        </Section>
      </Main>
    }
    />
  );
};

export default Search;