import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { companyPageCode } from 'api/apis/operator/companyPageCode';
import { priceMenu } from 'api/apis/operator/priceMenu';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { ModuleFormComponent } from './ModuleForm.style';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import LoadingComponent from 'components/loading/LoadingComponent';

const CreateModule = (props) => {
  const { userReducer } = useSelector((state) => state);

  const [_companyPageCodeList, setCompanyPageCodeList] = useState([]);
  const [_selectedOption, setSelectedOption] = useState([]);
  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    priceMenuTypeId: '',
    companyPageCodeId: ''
  });
  const [_loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => {
    getCompanyPageCode();
    return () => { }
  }, [])
  useEffect(() => { }, [_formData, _loadingStatus]);

  const getCompanyPageCode = async () => { // companyPageCode 불러오기
    await companyPageCode.getCompanyPageCodes().then(response => {
      if (!checkEmptyNull(response, false)) return;
      const companyPageCodes = response.data.content;
      companyPageCodes.sort((a, b) => a.defaultIndexX - b.defaultIndexX || a.defaultIndexY - b.defaultIndexY);
      setCompanyPageCodeList(() => { return companyPageCodes });
    })
  }

  const handleChange = (e, companyPageCodeId) => { // companyPageCode 선택 이벤트
    const isChecked = e.target.checked;
    if (isChecked) { // 체크한 경우 => _selectedOption 에 companyPageCodeId 추가
      setSelectedOption(prevSelected => [...prevSelected, companyPageCodeId]);
    } else { // 체크 해제한 경우 => _selectedOption 에서 companyPageCodeId 삭제
      setSelectedOption(prevSelected =>
        prevSelected.filter(id => id !== companyPageCodeId)
      );
    }
  };

  const actCreate = async (e) => { // 모듈 추가
    e.preventDefault();
    if (!checkEmptyNull(_formData.priceMenuTypeId, false)) { // 요금유형이 빈값인 경우 => 경고
      return alert('요금유형을 선택해주세요.');
    }
    if (_selectedOption.length === 0 || !_selectedOption.every(option => checkEmptyNull(option, false))) { // 선택한 companyPageCode 가 없는 경우 => 경고
      return alert('companyPageCode');
    }

    setLoadingStatus(() => { return true }); // 로딩 화면 표시
    const formDataArray = _selectedOption.map(option => ({
      ..._formData,
      companyPageCodeId: option // Update companyPageCodeId for each selected option
    }));

    let check = 0; // 체크용
    for (const formData of formDataArray) {
      try {
        const response = await priceMenu.createPriceMenu(formData);
        if (response === undefined) return;
      } catch (error) {
        console.error('Error creating price menu:', error);
      } finally {
        check++;
      }
    }

    if (check >= formDataArray.length) { // 전부 추가된 후 알림창 띄우고 모달 닫기
      setLoadingStatus(() => { return false }); // 로딩 화면 숨기기
      alert('모듈을 추가했습니다.');
      props.update();
    }
  };

  return (<>
    {props.open === true && (
      <ModuleFormComponent>
        <section>
          <header>
            <h4>모듈 추가</h4>
            <div className='btn-close' onClick={props.close}><CloseButtonIcon /></div>
          </header>

          <div>
            <Form
              title={<>모듈 추가</>}
              buttons={<></>}
              forms={<>
                <div className="formBox w100">
                  <h4>요금유형</h4>
                  <select className="SearchBar detail"
                    value={_formData.priceMenuTypeId}
                    onChange={(e) => { setFormData((prev) => { return { ...prev, priceMenuTypeId: e.target.value } }) }}
                  >
                    <option value={''}>선택</option>
                    {checkNullArray(props.priceType, []).map((priceType, index) => {
                      return (<option key={index + '_priceType'} value={priceType.priceMenuTypeId}>{priceType.priceMenuTypeName}</option>)
                    })}
                  </select>
                </div>

                <div className="formBox">
                  <div className="companyPageCodeContainer">
                    {checkNullArray(_companyPageCodeList, []).map((companyPageCode, index) => (
                      <label
                        key={index + '_companyPageCode'}
                        htmlFor={`checkbox_${companyPageCode.companyPageCodeId}`}
                        className='companyPageCodeItem'
                      >
                        <input
                          type="checkBox"
                          id={`checkbox_${companyPageCode.companyPageCodeId}`}
                          value={companyPageCode.companyPageCodeId}
                          checked={_selectedOption.includes(companyPageCode.companyPageCodeId)}
                          onChange={(e) => { handleChange(e, companyPageCode.companyPageCodeId) }}
                        />
                        <div>{companyPageCode.pageCode + ' | ' + companyPageCode.defaultPageName}</div>
                      </label>
                    ))}
                  </div>
                </div>
              </>}
            />
          </div>

          <div><div onClick={actCreate}>추가</div></div>
        </section>
      </ModuleFormComponent>
    )}

    {_loadingStatus ? (<LoadingComponent close={props.close} title={'모듈을 저장하고 있습니다...'} />) : null}
  </>);
};

export default CreateModule;
