import { AxiosInterceptor } from "api/core/consts";

export const notInStockApi = {
  getNotInStock: (notInStockId) =>
    AxiosInterceptor.get('notInStock/' + notInStockId),
  searchNotInStock: (paging, body) =>
    AxiosInterceptor.post('notInStock/search' + paging, body),
  createNotInStock: (body) => AxiosInterceptor.post('notInStock', body),
  updateNotInStock: (notInStockId, body) =>
    AxiosInterceptor.put('notInStock/' + notInStockId, body),
  deleteNotInStock: (notInStockId) =>
    AxiosInterceptor.delete('notInStock/' + notInStockId),
};
