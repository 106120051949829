// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ~ */
.pagination {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  list-style: none;
  padding: 0px;
  width: 100%;
}

.pagination li {
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--Bg);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  transition: all.3s;
  width: 40px;
}
.pagination li:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.pagination li:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pagination li a {
  align-items: center;
  color: var(--ThirdBlue);
  display: flex;
  font-size: 15px;
  height: 100%;
  justify-content: center;
  transition: all.5s;
  width: 100%;
}
.pagination li.active {
  background-color: var(--ThirdBlue);
}
.pagination li.active a {
  color: var(--white);
}

.pagination li:hover a {
  transform: scale(1.2);
}
`, "",{"version":3,"sources":["webpack://./src/components/paging/Paging.css"],"names":[],"mappings":"AAAA,MAAM;AACN;EACE,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,gBAAgB;EAChB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,8BAA8B;EAC9B,2BAA2B;EAC3B,sBAAsB;EACtB,eAAe;EACf,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,2BAA2B;EAC3B,8BAA8B;AAChC;AACA;EACE,4BAA4B;EAC5B,+BAA+B;AACjC;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,eAAe;EACf,YAAY;EACZ,uBAAuB;EACvB,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,kCAAkC;AACpC;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["/* ~ */\r\n.pagination {\r\n  align-items: center;\r\n  display: flex;\r\n  height: 100%;\r\n  justify-content: center;\r\n  list-style: none;\r\n  padding: 0px;\r\n  width: 100%;\r\n}\r\n\r\n.pagination li {\r\n  align-items: center;\r\n  background-color: var(--white);\r\n  border: 1px solid var(--Bg);\r\n  box-sizing: border-box;\r\n  cursor: pointer;\r\n  display: flex;\r\n  height: 40px;\r\n  justify-content: center;\r\n  transition: all.3s;\r\n  width: 40px;\r\n}\r\n.pagination li:first-child {\r\n  border-top-left-radius: 5px;\r\n  border-bottom-left-radius: 5px;\r\n}\r\n.pagination li:last-child {\r\n  border-top-right-radius: 5px;\r\n  border-bottom-right-radius: 5px;\r\n}\r\n\r\n.pagination li a {\r\n  align-items: center;\r\n  color: var(--ThirdBlue);\r\n  display: flex;\r\n  font-size: 15px;\r\n  height: 100%;\r\n  justify-content: center;\r\n  transition: all.5s;\r\n  width: 100%;\r\n}\r\n.pagination li.active {\r\n  background-color: var(--ThirdBlue);\r\n}\r\n.pagination li.active a {\r\n  color: var(--white);\r\n}\r\n\r\n.pagination li:hover a {\r\n  transform: scale(1.2);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
