import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { peopleSharing, peopleSharingAttribute, peopleSharingType } from 'api/apis/platform/cluster/cluster';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

const PeopleManagement = ({
  modalType, modalData,
  update,
  close
}) => {
  const location = useLocation();
  const clusterId = location.pathname.split('/platform/cluster')[0];
  const clusterPath = clusterId + '/platform/cluster/mypage';

  const { clusterReducer } = useSelector(state => state);
  const { user } = clusterReducer;

  const [_sharingTypeLsit, setSharingTypeList] = useState([]); // 유형 리스트
  const [_sharingType, setSharingType] = useState(modalData.peopleSharingTypeId || ''); // 유형 선택
  const [_attributeList, setAttributeList] = useState([]); // 속성 리스트
  const [_attributeValueList, setAttributeValueList] = useState([]); // 속성값 리스트

  const [_body, setBody] = useState({ ...modalData });

  useEffect(() => {
    getTypeList();
    return () => { }
  }, []);
  useEffect(() => {
    if (checkEmptyNull(_sharingType, false)) {
      getAttributeList(_sharingType);
    }
  }, [_sharingType]);
  useEffect(() => { }, [_body, _attributeList]);

  const getTypeList = async () => { // 유형 불러오기
    const id = Number(clusterId.replace('/', ''));
    const body = { clusterId: id };
    await peopleSharingType.search('', body).then(response => {
      if (response === undefined) return;
      setSharingTypeList(() => { return response.data });
    })
  };
  const getAttributeList = async (sharingType) => { // 속성 불러오기
    const id = Number(clusterId.replace('/', ''));
    const body = { clusterId: id };
    if (checkEmptyNull(sharingType, false)) body.peopleSharingTypeId = Number(sharingType);
    await peopleSharingAttribute.search('', body).then(response => {
      if (response === undefined) return;
      setAttributeList(() => { return response.data });

      const attributeValueList = response.data.map(attr => {
        const returnData = {
          peopleSharingAttributeId: attr.peopleSharingAttributeId,
          peopleSharingAttributeName: attr.peopleSharingAttributeName,
          value: '',
        };
        const prevAttrData = checkNullObject(modalData, false) &&
          modalData.peopleSharingAttributeValueList.find(item => item.peopleSharingAttributeId === attr.peopleSharingAttributeId);
        if (checkNullObject(prevAttrData, false)) { // 기존 속성값이 있는 경우
          returnData.peopleSharingAttributeValueId = prevAttrData.peopleSharingAttributeValueId;
          returnData.value = prevAttrData.value;
        }
        return returnData;
      });
      setAttributeValueList(() => { return attributeValueList });
    })
  };

  const actSave = async (e) => { // create/update
    e.preventDefault();
    const id = Number(clusterId.replace('/', ''));
    const body = {
      // clusterId: id,
      companyId: user.companyId,
      ..._body,
      peopleSharingAttributeValueList: _attributeValueList,
    };

    if (!checkEmptyNull(body.peopleSharingTypeId, false)) return alert('유형을 선택해주세요.');

    if (modalType.includes('create')) { // create
      await peopleSharing.create(body).then(response => {
        if (response === undefined) return;
        console.log('peopleSharing.create : ', response);
        update();
      })
    } else { // update
      delete body?.createdDate;
      delete body?.modifiedDate;
      body.deletePeopleSharingAttributeValueList = [];

      await peopleSharing.update(body.peopleSharingId, body).then(response => {
        if (response === undefined) return;
        console.log('peopleSharing.update : ', response);
        update();
      })
    }
  };
  const actDelete = async () => { // delete
    const confirmText = `해당 인력을 삭제하시겠습니까?`;
    if (window.confirm(confirmText)) {
      await peopleSharing.delete(_body.peopleSharingId).then(response => {
        if (response === undefined) return;
        console.log('peopleSharing.delete : ', response);
        update();
      })
    }
  };

  return (
    <section>
      <div className='section-form'>
        <h2>인력 정보</h2>

        <div className='form-content'>
          <h4>인력 유형</h4>
          <select
            disabled={modalType.endsWith('delete')}
            value={_body.peopleSharingTypeId || ''}
            onChange={(e) => {
              setSharingType(() => { return Number(e.target.value); });
              setBody((prev) => {
                return { ...prev, peopleSharingTypeId: Number(e.target.value) };
              });
            }}
          >
            <option value={''}>유형 선택</option>
            {checkNullArray(_sharingTypeLsit, []).map((sharingType, index) => {
              return (<option key={index + '_sharingType'} value={sharingType.peopleSharingTypeId}>{sharingType.peopleSharingTypeName}</option>);
            })}
          </select>
        </div>

        {checkEmptyNull(_sharingType, false) ? (<>
          <div className='form-content'>
            <h4>이름</h4>
            <input
              type='text'
              placeholder='이름을 입력해주세요.'
              disabled={modalType.endsWith('delete')}
              value={_body.peopleSharingName || ''}
              onInput={(e) => {
                setBody((prev) => {
                  return { ...prev, peopleSharingName: e.target.value }
                })
              }}
            />
          </div>
          {_attributeValueList.map((attr, attrIndex) => {
            return (
              <div key={attrIndex + '_form-content'} className='form-content'>
                <h4>{attr.peopleSharingAttributeName}</h4>
                <input
                  type='text'
                  placeholder={modalType.endsWith('delete') ? '-' : `${attr.peopleSharingAttributeName}을(를) 입력해주세요.`}
                  disabled={modalType.endsWith('delete')}
                  defaultValue={attr.value}
                  onInput={(e) => {
                    setAttributeValueList((prev) => {
                      const returnList = [...prev];
                      const spliceData = { ...attr, value: e.target.value };
                      returnList.splice(attrIndex, 1, spliceData);
                      return returnList;
                    })
                  }}
                />
              </div>
            );
          })}
        </>) : null}
      </div>
      <div className='section-action'>
        <button className='btn-cancel' onClick={close}>취소</button>
        {!modalType.endsWith('delete') ? (<button className='btn-save' onClick={actSave}>저장</button>) : (<button className='btn-delete' onClick={actDelete}>삭제</button>)}
      </div>
    </section>
  );
};

export default PeopleManagement;