import { AuthAxiosInterceptor, AxiosInterceptor } from "api/core/consts";

export const auth = {
  me: (token) => AuthAxiosInterceptor.get('/me', { headers: { Authorization: token } }),
  join: (body) => AuthAxiosInterceptor.post('/register', body),
  login: (body) => AuthAxiosInterceptor.post('/login', body),
  logout: (token) => AuthAxiosInterceptor.delete('/logout', { headers: { Authorization: token } }),
  findUserLoginId: (body) => AuthAxiosInterceptor.post('/findUserLoginId', body),
  findUserPassword: (body) => AuthAxiosInterceptor.post('/findUserPassword', body),
  searchCompanies: () => AuthAxiosInterceptor.get('/company'),
  /* ================================================================================ */
  joinCode: (token, body) => AxiosInterceptor.post('/team/joinCode', body, { headers: { Authorization: token } }),
};
