import { Cluster2RedirectHandler, ClusterBoard, ClusterMain, ClusterSignEmailFind, ClusterSignJoin, ClusterSignLogin, ClusterSignPwFind, MainBoard, MainPageBoardContent, MainPageRequestContent, MainPageSharingContent, MyInfo, MyPage, MyPageRequestContent, MyPageSettingAttributes, MyPageSettingType, MyPageSharedContent, MyPageShareManagement, NotFound } from "../../RouteImport";
import ClusterRouteLayout from "../../layouts/ClusterRouteLayout";

export const clusterRouters = [
  { path: '*', element: <NotFound /> },

  {
    path: '/', element: <ClusterRouteLayout />,
    children: [
      { path: '', element: <ClusterRouteLayout /> },

      { // 클러스터
        path: ':code/platform/cluster', element: <ClusterRouteLayout />,
        children: [
          { // 토큰 받아옴
            path: 'oauth2/redirect', element: <Cluster2RedirectHandler />,
          },

          { // 로그인 페이지
            path: 'sign', element: <ClusterRouteLayout />,
            children: [
              { path: '', element: <ClusterSignLogin />, },
              { path: 'login', element: <ClusterSignLogin />, },
              { path: 'join', element: <ClusterSignJoin />, },
              { path: 'id/find', element: <ClusterSignEmailFind />, },
              { path: 'pw/find', element: <ClusterSignPwFind />, },
            ],
          },
          { // 메인 페이지
            path: '', element: <ClusterMain />,
            children: [
              { path: '', element: <ClusterBoard /> },
              { // 장비 공유
                path: 'equipment', element: <ClusterRouteLayout />,
                children: [
                  { path: 'request', element: <MainPageRequestContent /> },
                  { path: 'share', element: <MainPageSharingContent /> },
                ]
              },
              { // 인력 공유
                path: 'people', element: <ClusterRouteLayout />,
                children: [
                  { path: 'request', element: <MainPageRequestContent /> },
                  { path: 'share', element: <MainPageSharingContent /> },
                ]
              },
              { // 공동 거래
                path: 'common', element: <ClusterRouteLayout />,
                children: [
                  { path: 'purchase', element: <MainPageBoardContent /> },
                  { path: 'sales', element: <MainPageBoardContent /> },
                ]
              },
              { // 공용 게시판
                path: 'board/public', element: <MainPageBoardContent />
              },
            ]
          },
          { // 마이페이지
            path: 'mypage', element: <MyPage />,
            children: [
              { // 내 정보
                path: '', element: <MyInfo />
              },
              { // 공유 장비
                path: 'equipment', element: <ClusterRouteLayout />,
                children: [
                  { path: 'request', element: <MyPageRequestContent /> },
                  { path: 'share', element: <MyPageSharedContent /> },
                  { // 장비 관리
                    path: 'manage', element: <MyPageShareManagement />
                  },
                  { // 장비 설정 - 유형, 속성
                    path: 'setting', element: <ClusterRouteLayout />,
                    children: [
                      { path: 'type', element: <MyPageSettingType /> },
                      { path: 'attributes', element: <MyPageSettingAttributes /> },
                    ]
                  },
                ]
              },
              { // 공유 인력
                path: 'people', element: <ClusterRouteLayout />,
                children: [
                  { path: 'request', element: <MyPageRequestContent /> },
                  { path: 'share', element: <MyPageSharedContent /> },
                  { // 인력 관리
                    path: 'manage', element: <MyPageShareManagement />
                  },
                  { // 인력 설정 - 유형, 속성
                    path: 'setting', element: <ClusterRouteLayout />,
                    children: [
                      { path: 'type', element: <MyPageSettingType /> },
                      { path: 'attributes', element: <MyPageSettingAttributes /> },
                    ]
                  },
                ]
              },
              { // 내 게시글
                path: 'board', element: <ClusterRouteLayout />,
                children: [
                  { path: 'public', element: <MainBoard /> },
                  { path: 'purchase', element: <MainBoard /> },
                  { path: 'sales', element: <MainBoard /> },
                ]
              },
            ]
          },
        ],
      },
    ]
  }
];