import React, { useState } from 'react';
import Pagination from 'react-js-pagination';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { daerimReducer_setCurrentPageNum } from 'store/modules/actions/company/daerimActions';
import { modalReducer_setCurrentPageNum } from 'store/modules/actions/default/modalActions';
import {
  pageReducer_setModalPageNum,
  pageReducer_setPageNum,
} from 'store/modules/actions/default/pageActions';

import 'components/paging/Paging.css';

//
const PageButton = styled.div`
  background-color: var(--ThirdBlue);

  mask-repeat: no-repeat;
  mask-size: contain;
  --webkit-mask-repeat: no-repeat;
  --webkit-mask-size: contain;
`;

const LastPageButton = styled(PageButton)`
  mask-image: url(${process.env.PUBLIC_URL + '/src/icons/lastPageButton.svg'});
  --webkit-mask-image: url(${process.env.PUBLIC_URL +
  '/src/icons/lastPageButton.svg'});

  height: 16px;
  width: 16px;
`;

const FirstPageButton = styled(LastPageButton)`
  transform: rotate(-180deg);
`;

const NextPageButton = styled(PageButton)`
  mask-image: url(${process.env.PUBLIC_URL + '/src/icons/nextPageButton.svg'});
  --webkit-mask-image: url(${process.env.PUBLIC_URL +
  '/src/icons/nextPageButton.svg'});

  height: 20px;
  width: 20px;
`;

const PrevPageButton = styled(NextPageButton)`
  transform: rotate(-180deg);
`;

const Paging = ({ page, count, size, modal }) => {
  const dispatch = useDispatch();

  const setPage = (e) => {
    console.log(page, count, e);
    console.log('modal : ', modal);

    if (modal === true) {
      dispatch(pageReducer_setModalPageNum(e));
      dispatch(modalReducer_setCurrentPageNum(e));
    } else if (modal === 'daerim') {
      dispatch(daerimReducer_setCurrentPageNum(e));
    } else {
      dispatch(pageReducer_setPageNum(e));
    }
  };

  return (
    <Pagination
      activePage={page}
      itemsCountPerPage={size}
      onChange={setPage}
      pageRangeDisplayed={5}
      totalItemsCount={count}
      firstPageText={<FirstPageButton />}
      lastPageText={<LastPageButton />}
      prevPageText={<PrevPageButton />}
      nextPageText={<NextPageButton />}
    />
  );
};

export default Paging;
