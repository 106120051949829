export const MallSnb = {
  top: [
    {menuName: '전체상품', route: '/mall/product/all'},
    {menuName: '장바구니', route: '/mall/cart'},
    {menuName: 'Q&A', route: '/mall/qna/list'},
  ],
  product: [
    {menuName: '상품 상세정보', route: '/mall/product/detail'},
    {menuName: '상품 문의', route: '/mall/product/qna'},
    {menuName: '상품 주문', route: '/mall/product/order'},
  ],
  mypage: [
    {menuName: '내정보', route: '/mall/mypage/info'},
    {menuName: '주문내역', route: '/mall/mypage/order/list'},
    {menuName: '문의내역', route: '/mall/mypage/qna/list'},
  ],
};
