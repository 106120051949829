import React, { Fragment } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import {Container, Table, Td, Th, Title, TableContainer, Tr, Tbody, PaginationContainer } from 'pages/contract/modal/Tab.style';

export default function ProcurementInfoTab({ data }) {
  const navigate = useNavigate();

  const formatValue = (value) => (value != null ? value : '-');

  const formatPrice = (price) =>
    price != null ? new Intl.NumberFormat().format(price) : '-';

  const getStatusLabel = (status) => {
    const statusLabels = {
      waiting: '대기',
      proceeding: '진행',
      end: '완료',
    };
    return statusLabels[status] || '-';
  };

  const handleNavigation = (path, contentKey) => {
    const searchParams = createSearchParams({
      [contentKey]: JSON.stringify(data),
    });

    navigate({
      pathname: path,
      search: `?${searchParams}`,
      replace: true,
    });
  };

  const tableRows = [
    { label: '발주코드', value: data.procurementCode },
    { label: '발주이름', value: data.procurementName },
    { label: '발주일', value: data.procurementDate },
    { label: '입고예정일', value: data.scheduledEndDate },
    {
      label: '발주총금액',
      value: formatPrice(data.procurementPrice),
      align: 'right',
    },
    { label: '발주상태', value: getStatusLabel(data.procurementStatus) },
  ];

  const accountRows = data.account
    ? [
        { label: '거래처명', value: data.account.accountName },
        { label: '수요기관코드', value: data.account.accountCode },
        { label: '사업자등록번호', value: data.account.businessNumber },
        { label: '지역명', value: data.account.regionName },
        { label: '주소', value: data.account.address },
        { label: '상세주소', value: data.account.detailAddress },
        { label: '전화번호', value: data.account.telNumber },
        { label: '팩스번호', value: data.account.faxNumber },
      ]
    : [];

  return (
    <Container className="contract">
      <Title>
        <Fragment>발주 상세정보</Fragment>
        <PaginationContainer>
          <button
            onClick={() =>
              handleNavigation('/order/procurement/update', 'updateContent')
            }
          >
            수정
          </button>
          <button
            onClick={() =>
              handleNavigation('/order/procurement/copy', 'copyContent')
            }
          >
            복사
          </button>
        </PaginationContainer>
      </Title>
      <TableContainer>
        <Table>
          <Tbody>
            {tableRows.map((row, index) => (
              <Tr key={index}>
                <Th>{row.label}</Th>
                <Td style={{ textAlign: row.align || 'left' }}>
                  {formatValue(row.value)}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        {accountRows.length > 0 && (
          <Table>
            <Tbody>
              {accountRows.map((row, index) => (
                <Tr key={index}>
                  <Th>{row.label}</Th>
                  <Td>{formatValue(row.value)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </TableContainer>
    </Container>
  );
};
