import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

import RealTime from 'pages/fems/gs/contents/RealTime';
import Today from 'pages/fems/gs/contents/Today';
import Month from 'pages/fems/gs/contents/Month';
import Compare from 'pages/fems/gs/contents/Compare';

const Main = styled.main`
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 60px calc(100% - 60px);
`;
const Section = styled.main`
  box-sizing: border-box;
  display: grid;
  gap: 50px;
  grid-template-areas:
  "RealTime Today"
  "Month Compare"
  ;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 40% calc(60% - 50px);
  height: 100%;
  overflow-y: scroll;
  padding: 30px;
  width: 100%;

  &>div {
    background-color: var(--white);
    border-radius: 10px;
    display: grid;
    grid-template-rows: 50px calc(100% - 50px);
    height: 100%;
    overflow: hidden;
    width: 100%;

    h4 {
      background-color: var(--MainNavy);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
      box-sizing: border-box;
      color: var(--white);
      display: inline-block;
      /* font-size: 1.4em; */
      line-height: 50px;
      padding-inline-start: 20px;
      text-align: left;
    }
  }

  .RealTime {
    grid-area: RealTime;
    grid-column-start: 1;
    grid-column-end: 2;

    p {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      font-size: 3em;
      gap: 5px;
      height: 100%;
      justify-content: center;
      padding-block-end: 20px;
      width: 100%;
      span {
        padding-block-start: 26px;
        font-size: 0.4em;
        font-weight: 600;
      }
    }
  }
  .Today {
    grid-area: Today;
    grid-column-start: 2;
    grid-column-end: 5;

    &>div {
      &>div {
        &>svg {
        }
      }
    }
  }
  .Month {
    grid-area: Month;
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .Compare {
    grid-area: Compare;
    grid-column-start: 4;
    grid-column-end: 5;
  }

  @media all and (max-width: 1000px) {
    grid-template-areas:
    "RealTime"
    "Today"
    "Month"
    "Compare"
    ;
    grid-template-columns: 100%;
    grid-template-rows: auto;

    .RealTime, .Today, .Month, .Compare {
      grid-column-start: unset;
      grid-column-end: unset;
    }
  }
`;

const GSEsg = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    return () => { }
  }, [])

  return (
    <Grid2Body contents={
      <Main className="Main">
        <NavBar
          title={<><NavTitle menuCode={'705'} /></>}
          // buttons={
          //   <>
          //     <Link to={'/monitoring/fems/gs/search'}>
          //       <div className='btn-set' style={{ backgroundColor: 'var(--MainNavy)', color: 'var(--white)', fontWeight: '600' }}>기간 사용량 조회</div>
          //     </Link>
          //   </>
          // }
          nav={''}
        />
        <Section>
          <RealTime />
          <Today />
          {/* <Month />
          <Compare /> */}
        </Section>
      </Main>
    }
    />
  );
};

export default GSEsg;