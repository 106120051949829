import React from 'react';
import styled from 'styled-components';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import StatusButton from 'components/buttons/StatusButton';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 1000;
`;

const ModalSection = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 250px;
  justify-content: flex-start;
  min-width: 200px;
  overflow: hidden;
  width: 400px;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  box-sizing: border-box;
  column-gap: 10px;
  display: flex;
  height: calc(100% - 55px);
  justify-content: center;
  padding: 20px;
  width: 100%;
`;

const ConfirmButton = styled.div`
  align-items: center;
  background-color: var(--ThirdBlue);
  border: 4px solid var(--white);
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 1.3em;
  font-weight: 600;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const WorkerConfirmModal = (props) => {
  const { workerEvent, endEvent } = props;

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>책임자/작업자</ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              <ConfirmButton
                style={{ backgroundColor: 'var(--MainNavy)' }}
                onClick={workerEvent}
              >
                <p>변경</p>
              </ConfirmButton>
              <ConfirmButton onClick={endEvent}>
                <p>변경없이</p>
                <p>완료</p>
              </ConfirmButton>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default WorkerConfirmModal;
