/*
1. axios, 에러 구분
2. token
*/

import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import { getCookie, removeCookie, setCookie } from 'preferences/cookie/cookie';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import { AnyAxiosInterceptor, AuthAxiosInterceptor, AxiosInterceptor } from './consts';

// 모든 API, token, 리듀서 사용해야해서 함수 안에 전부 넣음
export const APIInterceptor = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pageReducer } = useSelector((state) => state);

  // null 확인 : path, 토큰 없을 떄 로그인 페이지로 이동
  // currentPage 가 페이지마다 있어야함 -> 이유는 토큰 체크
  useEffect(() => {
    console.log('main - location.pathname : ', location.pathname);

    if (
      location.pathname !== '' && location.pathname !== '/' &&
      location.pathname !== '/login' &&
      location.pathname !== '/oauth2/redirect' &&
      location.pathname !== '/dashboard' &&
      !location.pathname.includes('/sign') &&
      !location.pathname.includes('/mall') &&
      !location.pathname.includes('/platform/cluster') &&
      !location.pathname.includes('/platform/operating')
    ) {
      // 아이피스
      if (getCookie('accessToken') !== undefined && getCookie('accessToken') !== null && getCookie('accessToken') !== '') {
        console.log('토큰 O');
        return; // 토큰 O
      } else {
        console.log('토큰 X');
        return navigate('/', { replace: true }); // 토큰 X
      }
    }
    // else if (location.pathname.includes('/mall/') || pageReducer.currentPage.includes('/mall/')) {
    //   // 아이피스 몰
    //   if (getCookie('accessToken') !== undefined && getCookie('accessToken') !== null && getCookie('accessToken') !== '') {
    //     console.log('mall - 토큰 O');
    //     return; // 토큰 O
    //   } else if (location.pathname.includes('/mall/product')) {
    //     console.log('mall - 토큰 O');
    //     return;
    //   } else {
    //     console.log('mall - 토큰 X');
    //     const companyCode = location.pathname.split('/')[1];
    //     return navigate(`/${companyCode}/mall/sign/login`, { replace: true }) // 토큰 X
    //   }
    // } else if (location.pathname.includes('/platform/cluster/') || pageReducer.currentPage.includes('/platform/cluster/')) {
    //   // 클러스터
    // }
  }, [pageReducer.currentPage]);

  // 1분마다 토큰 체크
  const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
    useEffect(() => {
      const tick = () => { savedCallback.current(); };
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };
  useInterval(async () => {
    const currentTime = moment(new Date());
    const tokenTime = getCookie('tokenTime');
    const calMinutes = moment.duration(currentTime.diff(tokenTime)).asMinutes();
    const BodyToPost = {
      accessToken: getCookie('accessToken'),
      refreshToken: getCookie('refreshToken'),
    };
    // console.log('calMinutes : ', calMinutes);

    if (BodyToPost.accessToken !== undefined && BodyToPost.refreshToken !== undefined && !isNaN(calMinutes) && calMinutes >= 55) {
      // 9시간 55분 이상
      await axios.post('/auth/refreshToken', BodyToPost).then((result) => {
        const options = {};
        // httpOnly: true,
        setCookie('accessToken', result.data, options);
        setCookie('tokenTime', moment().format('YYYY-MM-DD HH:mm:ss'), options);
      }).catch((error) => {
        console.log('/auth/refreshToken - error : ', error.response);
      }).finally(() => { return; });
    }
  }, 60000); // 1분 마다 체크

  // API 넘기기 전에 헤더에 토큰 전달
  /* request */
  const request_config = (config) => {
    if (checkEmptyNull(getCookie('accessToken'), false)) config.headers.Authorization = getCookie('accessToken');
    return config;
  }
  const request_error = (error) => {
    return console.error(error);
  }

  // auth 에러
  /* response */
  const auth_error = (response, type) => {
    removeCookie('accessToken');
    removeCookie('refreshToken');

    alert(`
      부여받은 권한이 없습니다.
      관리자에게 문의해주세요.
    `);

    return setTimeout(navigate('/', { replace: true }), 1000);
  }
  // 토큰 에러
  const token_error = (response, type) => {
    removeCookie('accessToken');
    removeCookie('refreshToken');

    alert(`
      ${moment().format('YYYY-MM-DD HH:mm:ss')}

      토큰이 만료되었습니다.
      다시 접속해 주시기 바랍니다.
    `);

    return setTimeout(navigate('/', { replace: true }), 1000);
  }
  // 상태 정상 체크 - 400, 403,409, 777, 200 후에 위에 auth와 토큰 에러 체크
  const response_response = (response, type) => {
    if (response === undefined) return;
    if (response.status === undefined) return;
    switch (response.status) {
      case 403: return auth_error(response, type);
      default: break;
    }
    if (response.status !== 200) return;
    if (response.data.status === undefined) return response;
    if (response.data.status !== 'OK' && response.data.message !== undefined) console.log('response : ', response);

    switch (response.data.status) {
      case 403: return auth_error(response, type);
      case 409:
        if (response.data.message !== undefined && response.data.message === 'Invalid Token') {
          return token_error(response, type);
        } else if (response.data.message !== undefined && response.data.message === 'Illegal Token') {
          return token_error(response, type);
        } else if (response.data.message !== undefined && response.data.message === 'Expired Token') {
          return token_error(response, type);
        }
        break;
      case 777: return;

      default: break;
    }

    if (response.data.status !== 'OK' && response.data.message !== undefined && response.data.message !== '정상적인 데이터') {
      return alert(response.data.message);
    } else {
      return response;
    }
  }
  // 
  const response_error = (error, type) => {
    if (error.response === undefined) return console.error(error);
    if (error.response.status === undefined) return console.error(error);

    switch (error.response.status) {
      case 403: return auth_error(error.response, type);

      default: break;
    }
    switch (error.response.data.status) {
      case 400:
        if (error.response.data.message !== undefined && error.response.data.message === 'Invalid Token') {
          return token_error(error.response, type);
        } else if (error.response.data.message !== undefined && error.response.data.message === 'Illegal Token') {
          return token_error(error.response, type);
        }
        return;

      case 403: return auth_error(error.response, type);
      case 409:
        if (error.response.data.message !== undefined && error.response.data.message === 'Invalid Token') {
          return token_error(error.response, type);
        } else if (error.response.data.message !== undefined && error.response.data.message === 'Illegal Token') {
          return token_error(error.response, type);
        } else if (error.response.data.message !== undefined && error.response.data.message === 'Expired Token') {
          return token_error(error.response, type);
        }
        return alert(error.response.data.message);

      default: break;
    }

    if (error.response.data.message !== undefined) return alert(error.response.data.message);
    return console.error(error);
  }

  // API ----------------------------------------------------------------------------------
  // 초창기 코드, 시작할 떄
  axios.interceptors.request.use(
    (config) => { return request_config(config); },
    (error) => { return request_error(error); }
  );
  axios.interceptors.response.use(
    (response) => { return response_response(response); },
    (error) => { return response_error(error); },
  );

  // 로그인 전 화면 볼 때, 토큰 없을 때
  AnyAxiosInterceptor.interceptors.request.use(
    (config) => { return config; },
    (error) => { return request_error(error); }
  );
  AnyAxiosInterceptor.interceptors.response.use(
    (response) => { return response_response(response); },
    (error) => { return response_error(error); },
  );

  // 로그인 후 화면, 토큰 있을 때
  AuthAxiosInterceptor.interceptors.request.use(
    (config) => { return config; },
    (error) => { return request_error(error); }
  );
  AuthAxiosInterceptor.interceptors.response.use(
    (response) => { return response_response(response); },
    (error) => { return response_error(error); },
  );

  // 각종 API 사용, 주 메인
  AxiosInterceptor.interceptors.request.use(
    (config) => { return request_config(config); },
    (error) => { return request_error(error); }
  );
  AxiosInterceptor.interceptors.response.use(
    (response) => { return response_response(response); },
    (error) => { return response_error(error); },
  );

  return (<></>);
};
