import React from 'react';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px calc(100% - 55px);
  /* grid-template-rows: 55px auto 40px 50px; */
  height: 85%;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const ModalHeaderButtons = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 55px;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;

    td.blankCell {
      background-color: var(--Bg);
      opacity: 0.8;
    }

    td.eventCell,
    th.eventCell {
      background-color: var(--MainNavy);
      color: var(--white);
    }
    td.eventCell {
      padding: 5px;
      input {
        background-color: var(--white);
        box-sizing: border-box;
        font-size: 1.2em;
        height: 100%;
        margin: 0px auto;
        min-height: 50px;
        padding: 10px 15px;
        text-align: right;
        width: 100%;

        &:disabled {
          background-color: var(--gray-200);
        }
      }
    }
  }
`;

const ShowInputElementStockLog = (props) => {
  const { content } = props;
  const { inputElementStockLogs } = content;

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>투입추적</ModalTitle>
              <ModalHeaderButtons></ModalHeaderButtons>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <TableSection content={
                <table>
                  <thead>
                    <tr>
                      <th>투입예정수량</th>
                      <th>투입량</th>
                      <th>입력시각</th>
                      <th>물품이름</th>
                      <th>물품코드</th>
                      <th>유통기한</th>
                      <th>금액</th>
                      <th>재고장소</th>
                      <th>비고</th>
                      <th>작업자</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkNullArray(inputElementStockLogs, []).map((elementStockLog, index) => {
                      const elementStockLogData = {
                        ...checkNullObject(elementStockLog.elementStockLog, false) ? elementStockLog.elementStockLog : {},
                        ...elementStockLog,
                        scheduledInputStock: elementStockLog.scheduledInputStock,
                        usedStock: elementStockLog.usedStock,
                      }
                      return (
                        <tr key={index + '_inputElementStockLog'}>
                          <td className='eventCell'>{checkEmptyNull(elementStockLogData.scheduledInputStock, 0).toLocaleString()}</td>
                          <td>{checkEmptyNull(elementStockLogData.usedStock, 0).toLocaleString()}</td>
                          <td>{DateTimeFormat(checkEmptyNull(elementStockLogData.createdDate, ''))}</td>
                          <td>
                            {(() => {
                              if (checkEmptyNull(elementStockLogData.elementName, false)) {
                                return elementStockLogData.elementName;
                              } else if (checkNullObject(elementStockLogData.element, false) && checkEmptyNull(elementStockLogData.element.elementName, false)) {
                                return elementStockLogData.element.elementName;
                              } else {
                                return '';
                              }
                            })()}
                          </td>
                          <td>
                            {(() => {
                              if (checkEmptyNull(elementStockLogData.elementCode, false)) {
                                return elementStockLogData.elementCode;
                              } else if (checkNullObject(elementStockLogData.element, false) && checkEmptyNull(elementStockLogData.element.elementCode, false)) {
                                return elementStockLogData.element.elementCode;
                              } else {
                                return '';
                              }
                            })()}
                          </td>
                          <td>{DateTimeFormat(checkEmptyNull(elementStockLogData.checkedDate, ''))}</td>
                          <td>{(checkEmptyNull(elementStockLogData.price, 0) * 1).toLocaleString()}</td>
                          <td>{checkEmptyNull(elementStockLogData.stockPlaceName, '')}</td>
                          <td>{elementStockLogData.note}</td>
                          <td>{checkEmptyNull(elementStockLogData.userName, '')}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
              ></TableSection>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default ShowInputElementStockLog;
