import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

const DeleteGSProductionOrder = () => {
  const navigate = useNavigate();
  const { gsReducer, pageReducer, userReducer } = useSelector((state) => state);

  const actDelete = async () => {
    await workOrderLogApi.deleteWorkOrderLog(gsReducer.deleteOrder.workOrderLogId).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.deleteWorkOrderLog : ', response);
      alert('작업지시를 삭제했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    })
  };
  const actAllDelete = () => {
    gsReducer.deleteOrder.forEach(async (workOrderLog, index) => {
      await workOrderLogApi.deleteWorkOrderLog(workOrderLog.workOrderLogId).then((response) => {
        if (response === undefined) return;
        console.log('delete/workOrderLog - response : ', response);
        if (response.status === 200) {
          if (index === gsReducer.deleteOrder.length - 1) {
            alert('작업지시를 삭제했습니다.');
            navigate(pageReducer.currentPage, { replace: true });
          }
        }
      })
    });
  };

  /* =================================================== #6들 =================================================== */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <main className="Main">
        <NavBar title={<NavTitle menuCode={'403'} />} nav={''} />

        <DeleteForm
          delteTitle={<NavTitle menuCode={'403'} />}
          deleteItem={
            <>
              {userReducer.company.companyId === 1 ? (
                <>
                  {gsReducer.deleteOrder.map((workOrderLog, index) => {
                    return (
                      <div key={index + '_delContract'} style={{ marginBottom: '10px' }}>
                        {'작업코드 : ' + checkEmptyNull(workOrderLog.workOrderCode, '')}<br />
                        {'공사명 : ' + checkEmptyNull(workOrderLog.workOrderName, '')} <br />
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  {'작업코드 : ' + checkEmptyNull(gsReducer.deleteOrder.workOrderCode, '')}<br />
                  {'공사명 : ' + checkEmptyNull(gsReducer.deleteOrder.workOrderName, '')}<br />
                </>
              )}
            </>
          }
          deleteText={<>해당 <span className="cautionText">작업지시</span>가 삭제됩니다.</>}
          deleteButton={
            <>
              <button className="formButton cancle"
                onClick={() => {navigate(pageReducer.currentPage, { replace: true });}}
              >
                취소
              </button>
              <button className="formButton delete"
                onClick={userReducer.company.companyId === 1 ? actAllDelete : actDelete}
              >
                삭제
              </button>
            </>
          }
        />
      </main>
    }
    />
  );
};

export default DeleteGSProductionOrder;
