import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { FaComment, FaRegComment } from "react-icons/fa";
import { CommentButton, ItemBox } from '../../../modal/ContentModal.style';
import Comment from '../../../modal/Comment';
import CommentForm from 'platform/cluster/pages/modal/CommentForm';
import { jointPurchase, reciprocalTransaction } from 'api/apis/platform/cluster/cluster';

const ItemList = ({ modalType, data, update }) => {
  const { clusterReducer } = useSelector(state => state);
  const { user } = clusterReducer;

  const [_currentType, setCurrentType] = useState('');
  const [_dataKeys, setDataKeys] = useState({
    id: '', list: '', delList: '', itemId: '', proposerList: '', proposerDelList: '',
  });

  const [_itemList, setItemList] = useState([]);

  const [_modalType, setModalType] = useState(); // Comment
  const [_modalData, setModalData] = useState();
  const [_commentStatus, setCommentStatus] = useState(false); // 신청 목록
  const [_modalStatus, setModalStatus] = useState(false); // 신청

  useEffect(() => {
    const type = modalType.split('-')[0];
    setCurrentType(() => { return type; });
    setDataKeys(() => {
      const returnData = {};
      if (type === 'purchase') {
        returnData.id = 'jointPurchaseId';
        returnData.list = 'jointPurchaseItemList';
        returnData.delList = 'deleteJointPurchaseItemList';
        returnData.itemId = 'jointPurchaseItemId';
        returnData.proposerList = 'jointPurchaseProposerList';
        returnData.proposerDelList = 'deleteJointPurchaseProposerList';
      } else if (type === 'sales') {
        returnData.id = 'reciprocalTransactionId';
        returnData.list = 'reciprocalTransactionItemList';
        returnData.delList = 'deleteReciprocalTransactionItemList';
        returnData.itemId = 'reciprocalTransactionItemId';
        returnData.proposerList = 'reciprocalTransactionProposerList';
        returnData.proposerDelList = 'deleteReciprocalTransactionProposerList';
      }
      setItemList(() => {
        return data[returnData.list];
      })
      return returnData;
    });
    getData();
  }, [modalType]);
  useEffect(() => { }, [_dataKeys, _itemList]);

  const getData = async () => {
    if (modalType.includes('purchase')) {
      await jointPurchase.get(data.jointPurchaseId).then(response => {
        if (response === undefined) return;
        console.log('jointPurchase : ', response);
        setItemList(() => {
          return response.data.jointPurchaseItemList || [];
        })
      })
    } else if (modalType.includes('sales')) {
      await reciprocalTransaction.get(data.reciprocalTransactionId).then(response => {
        if (response === undefined) return;
        console.log('reciprocalTransaction : ', response);
        setItemList(() => {
          return response.data.reciprocalTransactionItemList || [];
        })
      })
    }
  };

  const calDiscount = (originalPrice, discountRate) => { // 할인 가격 계산
    const rate = BigNumber(checkEmptyNull(discountRate, 0)).dividedBy(100);
    const cal = BigNumber(checkEmptyNull(originalPrice, 0)).multipliedBy(BigNumber(rate));
    const calPrice = BigNumber(checkEmptyNull(originalPrice, 0)).minus(BigNumber(cal));
    const returnPrice = calPrice.toNumber();
    return returnPrice;
  }

  const goComment = (type, data) => { // 모달 상태 변경(열기/닫기) >> 신청 목록
    if (!checkNullArray(data[_dataKeys?.proposerList], false)) return alert('신청이 없습니다.');
    setModalType(() => { return type });
    setModalData(() => { return data || {} });
    setTimeout(setCommentStatus(() => { return true }), 1000);
  }
  const handleModalStatus = (data, index) => { // 신청하기
    setModalType(() => { return `${_currentType}-comment-create` });
    setModalData(() => {
      return {
        data: {
          companyId: user.companyId,
          [_dataKeys.itemId]: data[_dataKeys.itemId],
          number: 1,
          detail: '',
        },
        index: index
      };
    });
    setTimeout(setModalStatus(() => { return true }), 1000);
  }

  return (<>
    <div className='list'>
      {checkNullArray(_itemList, []).map((item, index) => {
        return (
          <ItemBox key={index + '_item'}>
            <div className='item-header'>
              <h3>{item.itemName}</h3>
              {_currentType === 'purchase' ? (
                <p>목표 수량 <span>{item.targetNumber?.toLocaleString()}</span>개</p>
              ) : (
                <p>수량 <span>{item.number?.toLocaleString()}</span>개</p>
              )}
            </div>
            <div className='item-price'>
              {_currentType === 'purchase' ? (<>
                <p className='discountInfo'>
                  <span>할인가</span> {item.discountRate?.toLocaleString()}% <span>{item.originalPrice?.toLocaleString()}원</span>
                </p>
                <p className='cal'><span>{calDiscount(item.originalPrice, item.discountRate)?.toLocaleString()}</span>원</p>
              </>) : (<>
                <p className='price'><span>{item.price?.toLocaleString()}</span>원</p>
              </>)}
            </div>
            <div className='detail'>{item.detail}</div>
            <div className='comment'>
              <CommentButton
                className={!checkNullArray(item[_dataKeys?.proposerList], false) ? 'empty' : ''}
                onClick={() => { goComment(`${_currentType}-comment-detail`, item) }}
              >
                {!checkNullArray(item[_dataKeys?.proposerList], false) ? <FaRegComment /> : <FaComment />}
                <p>신청 목록</p>
              </CommentButton>
              {modalType.split('-')[1] === 'detailComment' ? (<>
                <button onClick={() => { handleModalStatus(item, index) }}>신청하기</button>
              </>) : null}
            </div>
          </ItemBox>
        );
      })}
    </div>

    {_commentStatus ? (
      <Comment
        modalType={_modalType}
        modalData={_modalData}
        update={() => {
          getData();
          setTimeout(setCommentStatus(() => { return false }), 1000);
        }}
        open={_commentStatus}
        close={() => {
          getData();
          setTimeout(setCommentStatus(() => { return false }), 1000);
        }}
      />
    ) : null}
    {_modalStatus ? (
      <CommentForm
        modalType={_modalType}
        modalData={_modalData}
        update={() => {
          getData();
          setTimeout(setModalStatus(() => { return false }), 1000);
        }}
        open={_modalStatus}
        close={() => {
          getData();
          setTimeout(setModalStatus(() => { return false }), 1000);
        }}
      />
    ) : null}
  </>);
};

export default ItemList;