export const elementActions_setElementType = (elementType) => {
  return {
    type: 'SETELEMENTTYPE',
    payload: elementType,
  };
};
export const elementActions_setSearchData = (searchData) => {
  return {
    type: 'SETELEMENTSEARCHDATA',
    payload: searchData,
  };
};
export const elementActions_setSearchAttrs = (serachAttrs) => {
  return {
    type: 'SETELEMENTSEARCHATTS',
    payload: serachAttrs,
  };
};

export const elementActions_setClickedCategory = (category) => {
  return {
    type: 'SETELEMENTCLICKEDCATEGORY',
    payload: category,
  };
};
export const elementActions_setClickedLevel1Category = (category) => {
  return {
    type: 'SETELEMENTCLICKEDLEVEL1CATEGORY',
    payload: category,
  };
};
export const elementActions_setClickedLevel2Category = (category) => {
  return {
    type: 'SETELEMENTCLICKEDLEVEL2CATEGORY',
    payload: category,
  };
};
export const elementActions_setClickedLevel3Category = (category) => {
  return {
    type: 'SETELEMENTCLICKEDLEVEL3CATEGORY',
    payload: category,
  };
};
export const elementActions_setClickedLevel4Category = (category) => {
  return {
    type: 'SETELEMENTCLICKEDLEVEL4CATEGORY',
    payload: category,
  };
};
export const elementActions_setClickedLevel5Category = (category) => {
  return {
    type: 'SETELEMENTCLICKEDLEVEL5CATEGORY',
    payload: category,
  };
};

export const elementActions_setPageNumber = (pageNum) => {
  return {
    type: 'SETELEMENTPAGENUMBER',
    payload: pageNum,
  };
};
export const elementActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETELEMENTTOTALSIZE',
    payload: totalSize,
  };
};
export const elementActions_setIsPushedSearchButton = (status) => {
  return {
    type: 'SETELEMENTISPUSHEDSEARCHBUTTON',
    payload: status,
  };
};

export const elementActions_setReset = () => {
  return {
    type: 'SETELEMENTRESET',
  };
};

export const elementActions_selectOneElement = (element) => {
  return {
    type: 'SELECTONEELEMENT',
    payload: element,
  };
};

export const elementActions_selectElements = (elements) => {
  return {
    type: 'SELECTELEMENTS',
    payload: elements,
  };
};

export const elementActions_setLevel1Categories = (category) => {
  return {
    type: 'SETLEVEL1CATEGORIES',
    payload: category,
  };
};
export const elementActions_setLevel2Categories = (category) => {
  return {
    type: 'SETLEVEL2CATEGORIES',
    payload: category,
  };
};
export const elementActions_setLevel3Categories = (category) => {
  return {
    type: 'SETLEVEL3CATEGORIES',
    payload: category,
  };
};
export const elementActions_setLevel4Categories = (category) => {
  return {
    type: 'SETLEVEL4CATEGORIES',
    payload: category,
  };
};
export const elementActions_setLevel5Categories = (category) => {
  return {
    type: 'SETLEVEL5CATEGORIES',
    payload: category,
  };
};

export const elementActions_setBookmark = (status) => {
  return {
    type: 'SETELEMENTBOOKMARK',
    payload: status,
  };
};
