import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { shipmentInspectionLog } from 'api/apis/shipmentInspection/shipmentInspectionLog';

import { checkEmptyNull, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import SelectOneUser from 'pages/user/modal/SelectOneUser';
import TableSection from 'components/layouts/table/TableSection';
import NavText from 'components/nav/NavText';

const ShipmentInspectionLogSection = styled.main`
  .TableSection {overflow: unset;}
  & .selectUser>.TableSection {overflow: unset;}
`;
const SelectTdButton = styled.td`
  background-color: var(--ThirdBlue);
  color: var(--white);
  cursor: pointer;
  height: 60px;
  text-align: center;
  vertical-align: middle;
`;

const UpdateShipmentInspection = () => {
  /* ========================================================================= #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [searchParams, setSearchParams] = useSearchParams();
  const updateContent = checkNullParse(searchParams.get('updateContent'), {});

  const [_onload, setOnload] = useState('unload');

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    shipmentInspectionLogCode: updateContent.shipmentInspectionLogCode,
    shipmentInspectionLogName: updateContent.shipmentInspectionLogName,
    shipmentInspectionItemLogList: updateContent.shipmentInspectionItemLogList.map(item => {
      const returnData = { shipmentInspectionItemId: item.shipmentInspectionItemId, shipmentInspectionItemLogId: item.shipmentInspectionItemLogId }
      return returnData;
    }),

    shipmentInspectionLogDate: DateFormat(checkEmptyNull(updateContent.shipmentInspectionLogDate, '')),

    managerId: updateContent.managerId,
    managerName: updateContent.managerName,

    checkerId: updateContent.checkerId,
    checkerName: updateContent.checkerName,

    approvalUserId: updateContent.approvalUserId,
    approvalUserName: updateContent.approvalUserName,
  });

  const [_manager, setManager] = useState({ id: updateContent.managerId, name: checkEmptyNull(updateContent.managerName, '') });
  const [_checker, setChecker] = useState({ id: updateContent.checkerId, name: checkEmptyNull(updateContent.checkerName, '') });
  const [_approvalUser, setApprovalUser] = useState({ id: updateContent.approvalUserId, name: checkEmptyNull(updateContent.approvalUserName, '') });

  const [_inspectionItemList, setInspectionItemList] = useState(() => {
    const logList = [...updateContent.shipmentInspectionItemLogList].map((item, index) => {
      return { ...item, number: checkEmptyNull(item.number, index + 1) };
    });
    return logList;
  });
  const [_inspectionNumberArray, setInspectionNumberArray] = useState(() => {
    const numberArray = [];
    for (let i = 1; i < updateContent.shipmentInspectionItemLogList.length + 1; i++) {
      numberArray.push(i);
    }
    return numberArray;
  });

  const [_userType, setUserType] = useState('');
  const [_userModalStatus, setUserModalStatus] = useState(false);

  /* ========================================================================= #3 */
  useEffect(() => {
    setInspectionNumberArray(() => {
      const numberArray = [];
      for (let i = 1; i < _inspectionItemList.length + 1; i++) {
        numberArray.push(i);
      }
      return numberArray;
    })
    return () => { }
  }, [_inspectionItemList])

  useEffect(() => { return () => { } }, [_inspectionNumberArray]);

  /* ========================================================================= #4 */
  /* ========================================================================= #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value }; });
  };
  const handleNumberEvent = (e, index, item) => {
    const newData = { ...item, number: BigNumber(e.target.value).toNumber() };
    setInspectionItemList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);
      prevData.splice(BigNumber(e.target.value).minus(1).toNumber(), 0, newData);
      const returnData = prevData.map((item, index) => {
        return { ...item, number: index + 1 };
      });
      return returnData;
    });
  };
  const handleInspection = (e, index, item) => {
    const { name, value } = e.target;
    const newData = { ...item, [name]: value };
    setInspectionItemList((prev) => {
      const returnData = [...prev];
      returnData.splice(index, 1, newData);
      return returnData;
    });
  }

  const actUpdate = async (e) => {
    e.preventDefault();
    if (!checkEmptyNull(_formData.shipmentInspectionLogCode, false)) return alert('이름를 입력해 주세요.');
    if (!checkEmptyNull(_formData.shipmentInspectionLogName, false)) return alert('프로젝트를 입력해 주세요.');

    const shipmentInspectionItemLogList = _inspectionItemList.map(item => {
      const returnData = {
        number: item.number,
        shipmentInspectionItemId: item.shipmentInspectionItemId,
        shipmentInspectionItemTypeName: item.shipmentInspectionItemTypeName,
        content: item.content,
        criteria: item.criteria,
        result: item.result,
      };
      if (checkEmptyNull(item.shipmentInspectionItemLogId, false)) {
        returnData['shipmentInspectionItemLogId'] = item.shipmentInspectionItemLogId;
      }
      return returnData;
    })

    // const deleteinspectionItemList = updateContent.shipmentInspectionItemLogList.filter(prevItem => shipmentInspectionItemLogList.findIndex(item => item.shipmentInspectionItemLogId === prevItem.shipmentInspectionItemLogId) === -1);
    // const deletedShipmentInspectionItemLogList = deleteinspectionItemList.map(item => item.shipmentInspectionItemLogId);

    const BodyToPut = {
      ..._formData,

      managerId: _manager.id,
      managerName: _manager.name,

      checkerId: _checker.id,
      checkerName: _checker.name,

      approvalUserId: _approvalUser.id,
      approvalUserName: _approvalUser.name,

      shipmentInspectionItemLogList: shipmentInspectionItemLogList,
      deletedShipmentInspectionItemLogList: []
      // deletedShipmentInspectionItemLogList: deletedShipmentInspectionItemLogList
    };
    await shipmentInspectionLog.updateShipmentInspectionLog(updateContent.shipmentInspectionLogId, BodyToPut).then((response) => {
      if (response === undefined) return;
      alert('수정했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    })
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <>
        <ShipmentInspectionLogSection className="Main">
          <NavBar title={<><NavTitle menuCode={'604'} /></>} nav={''} />

          <Form
            title={<><NavTitle menuCode={'604'} />수정</>}
            buttons={
              <>
                <button className="formButton" onClick={actUpdate}>저장</button>
                <button className="formButton cancle" onClick={(e) => {
                  e.preventDefault();
                  navigate(pageReducer.currentPage, { replace: true });
                }}
                >
                  취소
                </button>
              </>
            }
            forms={
              <>
                <div className="formBox">
                  <h4><NavText menuCode={'604'} /> 이름</h4>
                  <input
                    type="text"
                    name="shipmentInspectionLogCode"
                    placeholder="* 이름..."
                    data-required="required"
                    value={_formData.shipmentInspectionLogCode}
                    onInput={handleInputEvent}
                  />
                </div>
                <div className="formBox">
                  <h4>프로젝트</h4>
                  <input
                    type="text"
                    name="shipmentInspectionLogName"
                    placeholder="* 프로젝트..."
                    data-required="required"
                    value={_formData.shipmentInspectionLogName}
                    onInput={handleInputEvent}
                  />
                </div>
                <div className="formBox">
                  <h4>일자</h4>
                  <input
                    type="date"
                    name="shipmentInspectionLogDate"
                    value={_formData.shipmentInspectionLogDate}
                    onInput={handleInputEvent}
                  />
                </div>

                <div className="formBox selectUser w100">
                  <h4>담당자 / 검토자 / 승인자</h4>
                  <TableSection content={
                    <table>
                      <thead>
                        <tr>
                          <th>담당자</th>
                          <th>검토자</th>
                          <th>승인자</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{ height: '100px' }}>
                          <td>{checkEmptyNull(_manager.name, '')}</td>
                          <td>{checkEmptyNull(_checker.name, '')}</td>
                          <td>{checkEmptyNull(_approvalUser.name, '')}</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <SelectTdButton onClick={() => { setUserType(() => { return 'manager' }); setTimeout(setUserModalStatus(true), 1000); }}>담당자 선택</SelectTdButton>
                          <SelectTdButton onClick={() => { setUserType(() => { return 'checker' }); setTimeout(setUserModalStatus(true), 1000); }}>검토자 선택</SelectTdButton>
                          <SelectTdButton onClick={() => { setUserType(() => { return 'approvalUser' }); setTimeout(setUserModalStatus(true), 1000); }}>승인자 선택</SelectTdButton>
                        </tr>
                      </tfoot>
                    </table>
                  } />
                </div>

                <div className="formBox w100">
                  <h4>점검 / 검사 리스트</h4>
                  <TableSection content={
                    <table>
                      <thead>
                        <tr>
                          <th style={{ minWidth: '80px', width: '80px' }}>No.</th>
                          <th>점검 / 검사 항목</th>
                          <th>점검 / 검사 내용</th>
                          <th>판정 기준</th>
                          <th>결과</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_inspectionItemList.map((item, index) => {
                          return (
                            <tr key={index + '_inspectionItems'}>
                              <td style={{ minWidth: '80px', width: '80px' }}>
                                <select
                                  name="number"
                                  value={checkEmptyNull(item.number, index + 1)}
                                  onInput={(e) => { handleNumberEvent(e, index, item) }}
                                >
                                  {_inspectionNumberArray.map((number) => {
                                    return (<option key={index + '_inspectionNumber_' + number} value={number}>{number}</option>);
                                  })}
                                </select>
                              </td>
                              <td>
                                <textarea
                                  name='shipmentInspectionItemTypeName'
                                  value={checkEmptyNull(item.shipmentInspectionItemTypeName, '')}
                                  onInput={e => { handleInspection(e, index, item); }}
                                ></textarea>
                              </td>
                              <td>
                                <textarea
                                  name='content'
                                  value={checkEmptyNull(item.content, '')}
                                  onInput={e => { handleInspection(e, index, item); }}
                                ></textarea>
                              </td>
                              <td>
                                <textarea
                                  name='criteria'
                                  value={checkEmptyNull(item.criteria, '')}
                                  onInput={e => { handleInspection(e, index, item); }}
                                ></textarea>
                              </td>
                              <td>
                                <textarea
                                  name='result'
                                  value={checkEmptyNull(item.result, '')}
                                  onInput={e => { handleInspection(e, index, item); }}
                                ></textarea>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  } />
                </div>
              </>
            }
          />
        </ShipmentInspectionLogSection>

        {/* 담당자/검토자/승인자 선택 모달 */}
        {checkEmptyNull(_userType, false) && _userModalStatus ? <>
          <SelectOneUser
            buttonTitle={'선택'}
            buttonEvent={(data) => {
              console.log('data : ', data);
              switch (_userType) {
                case 'manager': setManager(() => { return data }); break;
                case 'checker': setChecker(() => { return data }); break;
                case 'approvalUser': setApprovalUser(() => { return data }); break;
                default: return;
              }
              setTimeout(setUserModalStatus(false), 1000);
            }}
            open={_userModalStatus}
            close={() => { setUserModalStatus(false) }}
          />
        </> : null}
      </>
    } />
  );
};

export default UpdateShipmentInspection;
