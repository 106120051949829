import styled from "styled-components";

export const ClusterBoardComponent = styled.section`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  /* margin: auto; */
  overflow: hidden;
  width: 100%;

  &>div {
    align-content: center;
    box-sizing: border-box;
    display: grid;
    grid-template-areas:
    "equipment equipment equipment people people people"
    "common common common common board board"
    ;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 42%);
    gap: 60px;
    height: 100%;
    /* margin: auto; */
    /* max-width: 1240px; */
    width: 90%;

    &>div:first-of-type {grid-area: equipment;}
    &>div:nth-of-type(2) {grid-area: people;}

    &>div:nth-of-type(3) { // 공동거래
      border: 1px solid #e0e0e0;
      border-radius: 12px;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      box-sizing: border-box;
      display: grid;
      gap: 10px;
      grid-template-rows: 60px calc(100% - 70px);
      grid-area: common;
      height: 100%;
      padding: 30px;
      width: 100%;


      &>div:first-of-type { // header
        align-items: center;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        padding-inline: 20px;

        & h1 {
          font-size: 40px;
        }

        &>div {
          align-items: flex-end;
          display: flex;
          gap: 15px;
          justify-content: center;

          & button {
            background-color: #d9d9d9;
            border-radius: 25px;
            box-sizing: border-box;
            font-size: 20px;
            font-weight: 600;
            padding: 8px 20px;
          }
        }
      }
      &>section {
        align-content: flex-start;
        box-sizing: border-box;
        display: grid;
        /* gap: 10px; */
        height: 100%;
        overflow: auto;
        padding: 20px;
        width: 100%;

        & .board-content {
          align-items: baseline;
          border-block-end: 1px solid #e0e0e0;
          box-sizing: border-box;
          cursor: pointer;
          display: grid;
          font-size: 24px;
          font-weight: 300;
          grid-template-columns: auto 120px;
          padding-block: 20px 10px;
          padding-inline: 10px;

          &:hover {
            opacity: 0.7;
          }

          & p {
            & span {
              color: #003675;
              font-size: 20px;
              font-weight: 600;
              padding-inline-end: 5px;
            }
            &:last-of-type {
              font-size: 20px;
            }
          }
        }
      }
    }

    &>div:last-of-type {grid-area: board;}
  }
`;

export const ClustBoardMenu = styled.div`
  align-content: flex-start;
  border-radius: 12px;
  box-sizing: border-box;
  cursor: pointer;
  display: grid;
  gap: 2px;
  height: 100%;
  padding: 50px;
  position: relative;
  width: 100%;

  & h1 {
    font-size: 50px;
  }
  & h4 {
    font-size: 20px;
    padding-inline-start: 10px;
  }
  & p {
    font-size: 20px;
    font-weight: 400;
    padding-inline-start: 20px;
  }
  &>svg:first-of-type {
    bottom: 30px;
    height: auto;
    position: absolute;
    right: 30px;
    width: 200px;
  }
  &>svg:last-of-type {
    bottom: 30px;
    height: 30px;
    position: absolute;
    right: 30px;
    width: 30px;
  }
  
  &:first-of-type {
    background-color: #F4F9FF;
    box-shadow: #8A91CE 4px 4px 5px;
    
    &>p {color: #8A91CE;}
    &>svg {color: #8A91CE;}
  }
  &:nth-of-type(2) {
    background-color: #F5FDFF;
    box-shadow: #8ABECE 4px 4px 5px;
    
    &>p {color: #8ABECE;}
    &>svg {color: #8ABECE;}
  }
  &:last-of-type {
    background-color: #FBFBFB;
    box-shadow: #929292 4px 4px 5px;
  
    &>svg {color: #929292;}
  }
`;