import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { priceTypeApi } from 'api/apis/operator/priceType';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { PriceTypeManagementComponent } from './PriceTypeManagement.style';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import LoadingComponent from 'components/loading/LoadingComponent';
import TableSection from 'components/layouts/table/TableSection';

const PriceTypeManagement = (props) => {
  const { userReducer } = useSelector((state) => state);

  const [_priceTypes, setPriceTypes] = useState([]);
  const [_checkedItem, setCheckedItem] = useState({});

  const [_loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => {
    getPriceTypes();
    return () => { }
  }, [])
  useEffect(() => { }, [_checkedItem, _loadingStatus]);

  const getPriceTypes = async () => {
    await priceTypeApi.search({ companyId: userReducer.company.companyId }).then((response) => {
      if (response === undefined) return;
      setPriceTypes(() => { return response.data.content });
    })
  }

  const actCreate = async (e) => { // 요금유형 추가
    e.preventDefault();
    const promptText = `요금유형을 입력해주세요.`;
    const priceType = window.prompt(promptText, '');
    if (!checkEmptyNull(priceType, false)) return; // 요금유형이 빈값인 경우 => 경고
    setLoadingStatus(() => { return true }); // 로딩 화면 표시
    await priceTypeApi.create(
      { companyId: userReducer.company.companyId, priceMenuTypeName: priceType }
    ).then((response) => {
      if (response === undefined) return;
      alert('요금유형을 추가했습니다.');
      getPriceTypes();
      setLoadingStatus(() => { return false }); // 로딩 화면 숨기기
      props.update();
    })
  };
  // const actUpdate = async (e) => { // 요금유형 수정
  //   e.preventDefault();
  //   if (!checkNullObject(_checkedItem, false)) return alert('수정할 요금유형을 선택해주세요.');
  //   const promptText = `요금유형을 입력해주세요.`;
  //   const priceType = window.prompt(promptText, _checkedItem.priceMenuTypeName);
  //   if (!checkEmptyNull(priceType, false)) return; // 요금유형이 빈값인 경우 => 경고
  //   setLoadingStatus(() => { return true }); // 로딩 화면 표시
  //   await priceTypeApi.update(
  //     _checkedItem.priceMenuTypeId,
  //     { companyId: userReducer.company.companyId, priceMenuTypeName: priceType }
  //   ).then((response) => {
  //     if (response === undefined) return;
  //     alert('요금유형을 수정했습니다.');
  //     getPriceTypes();
  //     setLoadingStatus(() => { return false }); // 로딩 화면 숨기기
  //   })
  // };
  const actDelete = async (e) => { // 요금유형 삭제
    e.preventDefault();
    if (!checkNullObject(_checkedItem, false)) return alert('삭제할 요금유형을 선택해주세요.');
    const confirmText = `요금유형을 삭제합니다.`;
    if (window.confirm(confirmText)) {
      await priceTypeApi.delete(_checkedItem.priceMenuTypeId).then((response) => {
        if (response === undefined) return;
        alert('요금유형을 삭제했습니다.');
        getPriceTypes();
        props.update();
      })
    }
  };

  return (<>
    {props.open === true && (
      <PriceTypeManagementComponent>
        <section>
          <header>
            <h4>요금유형</h4>
            <div className='btn-close' onClick={props.close}><CloseButtonIcon /></div>
          </header>

          <div>
            <nav>
              <div></div>
              <div>
                <button className='btn-set' onClick={actCreate}>추가</button>
                {/* <button className='btn-set' onClick={actUpdate}>수정</button> */}
                <button className='btn-set' onClick={actDelete}>삭제</button>
              </div>
            </nav>

            <TableSection content={
              <table>
                <colgroup>
                  <col width={'50px'} />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th>요금유형</th>
                  </tr>
                </thead>
                <tbody>
                  {checkNullArray(_priceTypes, []).map((priceType, index) => {
                    return (
                      <tr key={priceType.priceMenuTypeId + '_priceType'}>
                        <td>
                          <input
                            type="radio"
                            name="priceTypes"
                            onChange={() => { setCheckedItem(() => { return priceType }) }}
                          />
                        </td>
                        <td>{priceType.priceMenuTypeName}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            } />
          </div>
        </section>
      </PriceTypeManagementComponent>
    )}

    {_loadingStatus ? (<LoadingComponent close={props.close} title={'요금유형을 저장하고 있습니다...'} />) : null}
  </>);
};

export default PriceTypeManagement;
