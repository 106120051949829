import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

const WorkOrderLogSection = styled.section`
  align-items: flex-start;
  background-color: var(--white);
  box-sizing: border-box;
  display: grid;
  flex-direction: column;
  gap: 40px;
  height: 100%;
  justify-items: center;
  overflow: auto;
  padding-block: 30px;
  width: 50%;

  & .workOrderLogContent {
    align-items: flex-start;
    background-color: var(--white);
    border: 1px solid var(--gray-200);
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    box-sizing: border-box;
    cursor: pointer;
    display: grid;
    gap: 12px;
    height: fit-content;
    padding: 20px 30px;
    width: 90%;

    &:hover {box-shadow: unset;}
    &.active {
      background-color: var(--Bg);
      border: unset;
      box-shadow: unset;
      opacity: 0.7;
    }

    & .content-header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    & .status {
      border-radius: 21px;
      box-sizing: border-box;
      color: var(--white);
      font-size: 0.8em;
      font-weight: 600;
      padding: 8px 20px;
      width: fit-content;

      &.work {background-color: var(--WorkingColor);}
      &.pause {background-color: var(--MainYellow);}
      &.stop {background-color: var(--MainRed);}
      &.end {background-color: var(--ThirdBlue);}
      &.cancel {background-color: var(--Bg); color: var(--gray-200);}
    }

    & .content-main {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(5, auto);
      width: 100%;

      & .content-data {
        align-items: flex-start;
        display: flex;
        /* flex-wrap: wrap; */
        gap: 10px;

        & b {
          color: var(--gray-300);
          font-size: 0.9em;
          font-weight: 600;
          min-width: 100px;
          text-align: right;
        }

        &.remark {
          grid-column: 1/3;

          & p {
            border: 1px solid var(--gray-200);
            box-sizing: border-box;
            padding: 10px;
            width: calc(100% - 110px);
          }
        }
      }

      & table {
        border-collapse: collapse;
        grid-column: 2/3;
        grid-row: 3/5;
        height: 100%;
        width: 100%;

        & th, & td {
          border: 1px solid var(--gray-200);
          box-sizing: border-box;
          padding: 5px;
          font-size: 0.8em;
          text-align: center;
        }
        & th {
          background-color: var(--MainNavy);
          color: var(--white);
        }
      }
    }
  }
`;

const WorkOrderLog = (props) => {
  const { currentWorkOrderLogEvent } = props;
  const [_workOrderLogList, setWorkOrderLogList] = useState(props.workOrderLogList);
  const [_currentWorkOrderLog, setCurrentWorkOrderLog] = useState(props.currentWorkOrderLog);

  useEffect(() => {
    setWorkOrderLogList(() => { return props.workOrderLogList });
    setCurrentWorkOrderLog(() => { return props.currentWorkOrderLog });
  }, [props])

  const handleStatusName = (status) => {
    switch (status) {
      case 'work': return '작업 중';
      case 'pause': return '일시정지';
      case 'stop': return '중단';
      case 'end': return '완료';
      case 'cancel': return '취소';

      default: return null;
    }
  }

  return (
    <WorkOrderLogSection>
      {checkNullArray(_workOrderLogList, []).map((workOrderLog, index) => {
        return (
          <div key={index + '_workOrderLog'}
            className={_currentWorkOrderLog?.workOrderLogId === workOrderLog.workOrderLogId ? 'workOrderLogContent active' : 'workOrderLogContent'}
            onClick={() => {
              currentWorkOrderLogEvent(() => { return workOrderLog });
            }}
          >
            <div className='content-header'>
              <div className={'status ' + workOrderLog.workOrderStatus}>{handleStatusName(workOrderLog.workOrderStatus)}</div>
              <div>{DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledStartDate, ''))} ~ {DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledEndDate, ''))}</div>
            </div>
            <div className='content-main'>
              {[
                { name: '작업유형', value: workOrderLog.workOrderTypeName },
                { name: '거래처', value: workOrderLog.accountName },

                { name: '작업코드', value: workOrderLog.workOrderCode },
                { name: '작업장소', value: workOrderLog.workPlace },

                { name: '작업이름', value: workOrderLog.workOrderName },
              ].map((content, contentIndex) => {
                return (
                  <div key={contentIndex + '_content'} className='content-data'>
                    <b>{content.name}</b>{content.value}
                  </div>
                )
              })}
              <table>
                <thead>
                  <tr>
                    <th>작성자</th>
                    <th>책임자</th>
                    <th>작업자</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{workOrderLog.userName}</td>
                    <td>
                      {checkNullArray(workOrderLog.managers, []).map((manager, managerIndex) => {
                        return <Fragment key={managerIndex + '_managers'}>{manager.managerName}<br /></Fragment>
                      })}
                    </td>
                    <td>
                      {checkNullArray(workOrderLog.workers, []).map((worker, workerIndex) => {
                        return <Fragment key={workerIndex + '_workers'}>{worker.workerName}<br /></Fragment>
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className='content-data'>
                <b>생산목표수량</b>{workOrderLog.amount}
              </div>
              <div className='content-data remark'>
                <b>비고</b>
                <p>{workOrderLog.remark}</p>
              </div>
            </div>
          </div>
        )
      })}
    </WorkOrderLogSection>
  );
};

export default WorkOrderLog;