import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import { MallHeader } from 'mall/styles/style';
import { MallSnb } from 'mall/components/MallSnb';
import MallCompanyLogo from 'mall/components/MallCompanyLogo';
import Navbar from 'mall/components/nav/Navbar';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userReducer } = useSelector(state => state);

  const companyCode = location.pathname.split('/')[1];

  useEffect(() => { }, [userReducer.user])

  return (
    <>
      <MallHeader>
        <MallCompanyLogo />
        <nav>
          {checkEmptyNull(userReducer.user.id, false) ? (
            <>
              {MallSnb.top.map((menu, index) => {
                return (<button key={index + '_menu'} onClick={(e) => { e.preventDefault(); navigate(`/${companyCode + menu.route}`, { replace: true }); }}>{menu.menuName}</button>);
              })}
            </>
          ) : null}
          <Navbar />
        </nav>
      </MallHeader>
    </>
  );
};

export default Header;
