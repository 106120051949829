const initialState = {
  procurementType: 'all',
  procurementStatus: 'all',
  startDate: undefined,
  endDate: undefined,
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,

  account: {},
  procurementElementList: [],

  viewScale: 100,
};
const resetState = {
  procurementType: 'all',
  procurementStatus: 'all',
  startDate: undefined,
  endDate: undefined,
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const procurementReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETPROCUREMENTTYPE':
      return {
        ...state,
        procurementType: action.payload,
      };
    case 'SETPROCUREMENTSTATUS':
      return {
        ...state,
        procurementStatus: action.payload,
      };
    case 'SETPROCUREMENTSTARTDATE':
      return {
        ...state,
        startDate: action.payload,
      };
    case 'SETPROCUREMENTENDDATE':
      return {
        ...state,
        endDate: action.payload,
      };
    case 'SETPROCUREMENTSEARCHOPTION':
      return {
        ...state,
        searchOption: action.payload,
      };
    case 'SETPROCUREMENTSEARCHTEXT':
      return {
        ...state,
        searchText: action.payload,
      };
    case 'SETPROCUREMENTPAGENUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'SETPROCUREMENTTOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };
    case 'SETPROCUREMENTISPUSHEDSEARCHBUTTON':
      return {
        ...state,
        isPushedSearchButton: action.payload,
      };
    case 'SETPROCUREMENTRESET':
      return {
        ...state,
        ...resetState,
      };

    case 'SETPROCUREMENTACCOUNT':
      return {
        ...state,
        account: action.payload,
      };

    case 'SETPROCUREMENTELEMENTLIST':
      return {
        ...state,
        procurementElementList: action.payload,
      };

    case 'SETPROCUREMENTVIEWSCALE':
      return {
        ...state,
        viewScale: action.payload,
      };

    default:
      return state;
  }
};

export default procurementReducer;
