import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { companyApi } from 'api/apis/companyApi';
import { sensorDataApi } from 'api/apis/sensorDataApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat';

import { SensorDataSection, SensorTable } from './SensorData.style';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import OperatorBody from 'components/layouts/body/OperatorBody';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import { sensor } from 'api/apis/sensor';

const OperatorSensorData = () => {
  const [_onload, setOnload] = useState('unload');

  const [_companyList, setCompanyList] = useState([]);
  const [_companyId, setCompanyId] = useState(1);

  const [_startDate, setStartDate] = useState(moment().add('-1', 'm').format('YYYY-MM-DD hh:mm:ss'));
  const [_endDate, setEndDate] = useState(moment().format('YYYY-MM-DD hh:mm:ss'));
  const [_name, setName] = useState('');

  const [_logList, setLogList] = useState([]);

  const [_pageNumber, setPageNumber] = useState(1);
  const [_totalSize, setTotalSize] = useState(0);

  useEffect(() => {
    getCompanyList();
    getSensorData(_pageNumber - 1, _companyId);

    setOnload('loaded');
    return () => { }
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') getSensorData(_pageNumber - 1, _companyId);

    return () => { }
  }, [_pageNumber]);
  useEffect(() => { }, [_companyId]);

  const getCompanyList = async () => {
    await companyApi.searchCompanies('', {}).then(response => {
      if (response === undefined) return;
      setCompanyList(() => { return response.data });
    })
  }

  const getSensorData = async (page, companyId) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: companyId,
      startDate: moment().add('-1', 'm').add(9, 'hour').toISOString(),
      endDate: moment().add(9, 'hour').toISOString(),
      name: '',
    }
    if (checkEmptyNull(_startDate, false)) BodyToPost.startDate = moment(_startDate).add(9, 'hour').toISOString();
    if (checkEmptyNull(_endDate, false)) BodyToPost.endDate = moment(_endDate).add(9, 'hour').toISOString();
    
    // await sensorDataApi.sensorRealTimeData({ companyId: companyId }).then(response => { // 모르겠음..?
    await sensor.searchSensor({ companyId: companyId }).then(response => { // 안전관리 모니터링 데이터 호출 API
      if (response === undefined) return;
      console.log('sensor.searchSensor : ', response);
    })
    await sensorDataApi.countData(paging, BodyToPost).then(response => { // 센서 누적 데이터
      if (response === undefined) return;
      setLogList(() => { return response.data.content });
      setTotalSize(() => { return response.data.totalElements });
    })
  }

  const actSearch = () => {
    setPageNumber(() => { return 1 });
    getSensorData(0, _companyId);
  }
  const actReset = () => {
    setCompanyId(() => { return 1; });
    setStartDate(() => { return ''; })
    setEndDate(() => { return ''; })
    setName(() => { return ''; })

    setPageNumber(() => { return 1; });
    getSensorData(0, 1);
  }

  return (
    <OperatorBody contents={
      <>
        <SensorDataSection className='Main'>
          <NavBar
            title={
              <div className='Title'>
                <h4>센서데이터</h4>
                <Link to='/operator/sensor' style={{ color: '#ccc' }}>센서</Link>
              </div>
            }
            buttons={
              <>
                <div className="SearchSection" style={{ columnGap: '20px'}}>
                  <div className='SearchSection'>
                    <select
                      className='SearchOption'
                      value={_companyId}
                      onChange={(e) => {
                        const companyId = Number(e.target.value);
                        setCompanyId(() => { return companyId; });
                        setPageNumber(() => { return 1; });
                        getSensorData(0, companyId);
                      }}
                    >
                      {checkEmptyNull(_companyList, []).map((company, index) => {
                        return (
                          <option key={index + '_index'} value={company.companyId}>{company.companyId} - { company.companyName}</option>
                        );
                      })}
                    </select>
                    <input
                      type='number'
                      className='SearchBar'
                      title='검색 버튼 눌러야 합니당!'
                      value={_companyId}
                      onInput={(e) => {
                        const companyId = e.target.value;
                        setCompanyId(() => { return companyId; });
                      }}
                    />
                  </div>
                  <div className="SearchSection">
                    <input
                      type="datetime-local"
                      className="SelectDate"
                      value={checkEmptyNull(_startDate, false) ? DateTimeFormat(checkEmptyNull(_startDate, '')) : ''}
                      onChange={(e) => { e.preventDefault(); setStartDate(() => { return e.target.value }) }}
                    />
                    <IconRightArrow />
                    <input
                      type="datetime-local"
                      className="SelectDate"
                      value={checkEmptyNull(_endDate, false) ? DateTimeFormat(checkEmptyNull(_endDate, '')) : ''}
                      onChange={(e) => { e.preventDefault(); setEndDate(() => { return e.target.value }) }}
                    />
                  </div>
                  <div className="ContentCBox">
                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton" onClick={actReset}>초기화</div>
                  </div>
                </div>
              </>
            }
            nav={''}
          />
            <SensorTable>
              <table>
                <colgroup><col width={'250px'}/><col/><col /><col /></colgroup>
                <thead>
                  <tr><th>시각</th><th>name</th><th>value</th></tr>
                </thead>
                <tbody>
                  {checkNullArray(_logList, false) ? (<>
                  {checkNullArray(_logList, []).map((logData, index) => {

                    // console.log(_logList);
                    const date = moment(logData.date).format('YYYY-MM-DD HH:mm:ss');
                    return (
                      <tr key={index + '_logData'}>
                        <td>{date}</td>
                        <td>{logData.name || ''}</td>
                        <td>{checkEmptyNull(logData.value, 0).toLocaleString()}</td>
                      </tr>
                    )
                  })}
                  </>) : <tr><td colSpan={2}>데이터가 없습니다</td></tr> }
                </tbody>
              </table>
            </SensorTable>
        </SensorDataSection>

        <PagingComponent
          page={_pageNumber}
          count={_totalSize}
          size={10}
          pageEvent={(page) => {setPageNumber(() => { return page })}}
        />
      </>
    } />
  );
};

export default OperatorSensorData;