import React, { useState } from 'react';
import styled from 'styled-components';

import { checkNullArray } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 1000;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  max-width: 800px;
  max-height: 800px;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    width: 100%;
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const FailedProcessModal = (props) => {
  /* ====================================================================== #1 */

  /* ====================================================================== #2 */
  const { putElementList, makeElementList } = props;

  const [_checkedItem, setCheckedItem] = useState([]);

  /* ====================================================================== #3 */
  /* ====================================================================== #4 */
  const handleCheckedItem = (item, isChecked) => {
    const checkedItem = [..._checkedItem];
    const checkedIndex = checkedItem.findIndex((thisItem) => thisItem.processLogElementId === item.processLogElementId);
    if (checkedIndex !== -1) {
      if (!isChecked) checkedItem.splice(checkedIndex, 1);
    } else {
      if (isChecked) checkedItem.push(item);
    }
    console.log('checkedItem : ', checkedItem);
    setCheckedItem(() => {return checkedItem;});
  };

  /* ====================================================================== #5 */

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>불량물품 등록</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <TableSection content={
                <table>
                  <thead>
                    <tr>
                      <th style={{ minWidth: '60px', width: '60px' }}>
                        <input
                          type="checkBox"
                          name="allElements"
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            if (isChecked) {
                              setCheckedItem((prev) => {
                                const prevData = [...prev];
                                const newData = [...putElementList, ...makeElementList].map((thisItem) => {
                                  const thisFindIndex = prevData.findIndex((thisIndex) => thisIndex.processLogElementId === thisItem.processLogElementId);
                                  if (thisFindIndex !== -1) return null;
                                  else return thisItem;
                                });
                                const filterData = newData.filter((thisItem) => thisItem !== null);
                                return [...prevData, ...filterData];
                              });

                              e.target.checked = true;
                            } else {
                              setCheckedItem((prev) => {
                                const prevData = prev.map((thisItem) => {
                                  const thisFindIndex = [...putElementList, ...makeElementList].findIndex((thisIndex) => thisIndex.processLogElementId === thisItem.processLogElementId);
                                  if (thisFindIndex !== -1) return null;
                                  else return thisItem;
                                });
                                const filterData = prevData.filter((thisItem) => thisItem !== null);
                                return filterData;
                              });

                              e.target.checked = false;
                            }
                          }}
                        />
                      </th>
                      <th>물품코드</th>
                      <th>물품이름</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...putElementList, ...makeElementList].map((element, index) => {
                      return (
                        <tr key={index + '_elements'}>
                          <td style={{ minWidth: '60px', width: '60px' }}>
                            <input
                              type="checkBox"
                              name="elements"
                              data-key={element.processLogElementId}
                              checked={(() => {
                                const checkedId = checkNullArray(_checkedItem, false) ? _checkedItem.findIndex((thisIndex) => thisIndex.processLogElementId === element.processLogElementId) : -1;
                                if (checkedId !== -1) return true; 
                                else return false;
                              })()}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                handleCheckedItem(element, isChecked);
                              }}
                              onClick={(e) => {e.stopPropagation();}}
                            />
                          </td>
                          <td>{element.elementCode}</td>
                          <td>{element.elementName}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
              ></TableSection>
            </ModalMain>

            <ModalFooter>
              <ModalButton style={{ backgroundColor: 'var(--ThirdBlue)' }} onClick={() => {props.buttonEvent(_checkedItem);}}>
                등록
              </ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default FailedProcessModal;
