import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

import { userLogApi } from 'api/apis/userLogApi';
import IconRightArrow from 'components/icons/IconRightArrow';
import { DateFormat } from 'components/format/DateFormat';
import { checkEmptyNull } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat';

const DownloadModal = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 6px;
  }
`;

const MainContents = styled.div`
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 5px 20px;
  width: 100%;
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ModalButton = styled.div`
  align-items: center;
  background-color: var(--ThirdGreen);
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 400;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const DateContents = styled.div`
  column-gap: 10px;
  display: grid;
  grid-template-columns: 1fr 15px 1fr;
  width: 100%;
`;

const SelectDate = styled.input`
  border: 1px solid var(--gray-200);
  border-radius: 5px;
  box-sizing: border-box;
  height: fit-content;
  margin-top: 5px;
  min-width: 250px;
  padding: 10px;
  width: 100%;

  border: 1px solid var(--ThirdGreen);
  &:focus {
    border: 1px solid var(--ThirdGreen);
  }
`;

const SearchContents = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 150px calc(100% - 160px);
  margin-top: 5px;
  width: 100%;

  &.detail {
    grid-template-columns: 150px calc(100% - 210px) 50px;
  }

  select {
    border: 1px solid var(--ThirdGreen);
    border-radius: 5px;
    box-sizing: border-box;
    height: fit-content;
    padding: 10px;
    width: 100%;

    &.detail {
      appearance: none;
      --webkit-appearance: none;

      background-color: var(--ThirdGreen);
      color: var(--white);
    }
  }

  input {
    border: 1px solid var(--ThirdGreen);
    border-radius: 5px;
    box-sizing: border-box;
    height: fit-content;
    /* outline: var(--ThirdGreen); */
    padding: 10px;
    width: 100%;

    &:focus {
      border: 1px solid var(--ThirdGreen);
    }
  }
`;

const AccessHistoryDownloadModal = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_startDate, setStartDate] = useState('');
  const [_endDate, setEndDate] = useState('');

  const [_searchText, setSearchText] = useState('');

  /* ====================================================================== #3 */
  useEffect(() => {
    setOnload('loaded');
  }, []);

  /* ====================================================================== #4 */
  const setDurationTime = (seconds) => {
    const hour = parseInt(seconds / 3600) < 10 ? '0' + parseInt(seconds / 3600) : parseInt(seconds / 3600);
    const min = parseInt(parseInt(seconds % 3600) / 60) < 10 ? '0' + parseInt(parseInt(seconds % 3600) / 60) : parseInt(parseInt(seconds % 3600) / 60);
    const sec = parseInt(seconds % 60) < 10 ? '0' + parseInt(seconds % 60) : parseInt(seconds % 60);

    const DurationTime = `${hour}:${min}:${sec}`;

    return DurationTime;
  };

  /* ====================================================================== #5 */
  const getUserLogs = async () => {
    const paging = `?page=0&size=7000&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (checkEmptyNull(_startDate, false) && checkEmptyNull(_endDate, false)) {
      BodyToPost.startDate = DateFormat(_startDate);
      BodyToPost.endDate = DateFormat(_endDate);
      if (_startDate > _endDate) {
        BodyToPost.startDate = DateFormat(_endDate);
        BodyToPost.endDate = DateFormat(_startDate);
      }
    }
    if (checkEmptyNull(_searchText, false)) {
      BodyToPost.name = _searchText;
    }
    console.log('getUserLogs - BodyToPost : ', BodyToPost);

    await userLogApi.searchUserLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('userLogApi.searchUserLog : ', response);

      setExcelData(response.data.content);

      if (checkEmptyNull(_startDate, false) && checkEmptyNull(_endDate, false)) {
        if (_startDate > _endDate) {
          setStartDate(() => { return _endDate });
          setEndDate(() => { return _startDate });
        }
      }
    });
  };
  const setExcelData = (Result) => {
    const logList = Result;
    const excelList = logList.map((thisItem) => {
      const logInTime = DateTimeFormat(thisItem.logInTime);
      const logOutTime = checkEmptyNull(thisItem.logOutTime, false) ? DateTimeFormat(thisItem.logOutTime) : null;
      const DurationSec = checkEmptyNull(thisItem.logOutTime, false) && moment.duration(moment(logOutTime).diff(moment(logInTime))).asSeconds();

      const returnData = {
        사용자: thisItem.userName,
        로그인: checkEmptyNull(thisItem.logInTime, false) ? DateTimeFormat(thisItem.logInTime) : '',
        로그아웃: checkEmptyNull(thisItem.logOutTime, false) ? DateTimeFormat(thisItem.logOutTime) : '',
        접속시간: checkEmptyNull(thisItem.logOutTime, false) ? setDurationTime(DurationSec) : '접속중',
      };

      return returnData;
    });
    console.log('excelList : ', excelList);

    const ws = XLSX.utils.json_to_sheet(excelList);
    const wb = XLSX.utils.book_new();

    let fileTitle = '사용자 접속 현황';
    XLSX.utils.book_append_sheet(wb, ws, fileTitle);
    XLSX.writeFile(wb, fileTitle + '.xlsx');

    props.close();
  };

  /* ====================================================================== #6 */

  return (
    <>
      <DownloadModal>
        <MainContents>
          <div>날짜</div>
          <DateContents>
            <SelectDate
              type="date"
              className="SelectDate"
              value={_startDate}
              onChange={(e) => {
                setStartDate(() => {
                  return e.target.value;
                });
              }}
            />

            <IconRightArrow />

            <SelectDate
              type="date"
              className="SelectDate"
              value={_endDate}
              onChange={(e) => {
                setEndDate(() => {
                  return e.target.value;
                });
              }}
            />
          </DateContents>
        </MainContents>

        <MainContents>
          <div>검색</div>
          <SearchContents>
            <select className="SearchOption detail">
              <option value="userName">사용자</option>
            </select>
            <input
              className="SearchBar detail"
              placeholder="Search..."
              value={_searchText}
              onInput={(e) => {
                setSearchText(() => { return e.target.value });
              }}
            />
          </SearchContents>
        </MainContents>
      </DownloadModal>

      <ModalFooter>
        <ModalButton onClick={getUserLogs}>다운로드</ModalButton>
      </ModalFooter>
    </>
  );
};

export default AccessHistoryDownloadModal;
