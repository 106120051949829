import React from 'react';

const AllCheckBox = (props) => {
  const AllCheckBoxID = 'AllCheckBox' + props.checkboxName;

  return (
    <input id={ AllCheckBoxID } type="checkbox" onChange={(e) => {
      document.getElementsByName(props.checkboxName).forEach(ThisCheckBox => {
        if(e.target.checked === true)
          ThisCheckBox.checked = true;
        else
          ThisCheckBox.checked = false;
      })
    }} />
  );
};

export default AllCheckBox;