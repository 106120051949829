import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { currentStockActions_setLevel1Categories, currentStockActions_setLevel2Categories, currentStockActions_setLevel3Categories, currentStockActions_setLevel4Categories, currentStockActions_setLevel5Categories } from 'store/modules/actions/common/currentStockActions';
import { elementActions_setLevel1Categories, elementActions_setLevel2Categories, elementActions_setLevel3Categories, elementActions_setLevel4Categories, elementActions_setLevel5Categories } from 'store/modules/actions/common/elementActions';
import { productionPerformanceActions_setLevel1Categories, productionPerformanceActions_setLevel2Categories, productionPerformanceActions_setLevel3Categories, productionPerformanceActions_setLevel4Categories, productionPerformanceActions_setLevel5Categories } from 'store/modules/actions/common/productionPerformanceActions';
import { stockHistoryActions_setLevel1Categories, stockHistoryActions_setLevel2Categories, stockHistoryActions_setLevel3Categories, stockHistoryActions_setLevel4Categories, stockHistoryActions_setLevel5Categories, } from 'store/modules/actions/common/stockHistoryActions';
import { stockPlaceHistoryActions_setLevel1Categories, stockPlaceHistoryActions_setLevel2Categories, stockPlaceHistoryActions_setLevel3Categories, stockPlaceHistoryActions_setLevel4Categories, stockPlaceHistoryActions_setLevel5Categories, } from 'store/modules/actions/common/stockPlaceHistoryActions';

import { categoryApi } from 'api/apis/categoryApi';
import { checkNullObject } from 'components/checkValues/checkValues';

const CategoryLevel1 = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { currentStockReducer, elementReducer, productionPerformanceReducer, stockHistoryReducer, stockPlaceHistoryReducer, userReducer, } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_categoryList, setCategoryList] = useState(() => {
    switch (props.categoryType) {
      case 'currentStock': return currentStockReducer.level1Categories;
      case 'element': return elementReducer.level1Categories;
      case 'stockHistory': return stockHistoryReducer.level1Categories;
      case 'stockPlaceHistory': return stockPlaceHistoryReducer.level1Categories;
      case 'productionPerformance': return productionPerformanceReducer.level1Categories;
      default: return [];
    }
  });

  /* ====================================================================== #3 */
  useEffect(() => {
    getCategoryLevel1List();

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      if (Object.keys(props.clickedLevel1Category).length === 0) {
        setCategoryList(() => { return []; });
        getCategoryLevel1List();
      }
    }
  }, [props.clickedLevel1Category]);

  /* ====================================================================== #4 */
  const getCategoryLevel1List = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      categoryLevel: 1,
    };
    await categoryApi.searchCategory(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('categoryApi.searchCategory : ', response);
      switch (props.categoryType) {
        case 'currentStock': dispatch(currentStockActions_setLevel1Categories(response.data)); break;
        case 'element': dispatch(elementActions_setLevel1Categories(response.data)); break;
        case 'stockHistory': dispatch(stockHistoryActions_setLevel1Categories(response.data)); break;
        case 'stockPlaceHistory': dispatch(stockPlaceHistoryActions_setLevel1Categories(response.data)); break;
        case 'productionPerformance': dispatch(productionPerformanceActions_setLevel1Categories(response.data)); break;
        default: break;
      }
      setCategoryList(() => { return response.data; });
    });
  };

  /* ====================================================================== #5 */
  const handleCategory = (e) => {
    if (e.target.value === '') {
      props.setClickedCategory('all');
      props.setClickedLevel1Category({});
      return;
    }
    const thisFindIndex = _categoryList.findIndex((thisItem) => thisItem.categoryId === Number(e.target.value));
    if (thisFindIndex === -1) return;
    const thisFindItem = _categoryList[thisFindIndex];
    props.setClickedCategory(thisFindItem);
    props.setClickedLevel1Category(thisFindItem);
  };

  return (
    <select
      className="SelectFamily"
      value={checkNullObject(props.clickedLevel1Category, false) ? props.clickedLevel1Category.categoryId : ''}
      onChange={handleCategory}
    >
      <option value="">패밀리 1</option>
      {_categoryList.map((thisItem, index) => {
        return (<option key={index + '_category1'} value={thisItem.categoryId}>{thisItem.categoryName}</option>);
      })}
    </select>
  );
};

const CategoryLevel2 = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { currentStockReducer, elementReducer, productionPerformanceReducer, stockHistoryReducer, stockPlaceHistoryReducer, userReducer, } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_clickedLevel1Category, setClickedLevel1Category] = useState([]);

  const [_categoryList, setCategoryList] = useState(() => {
    switch (props.categoryType) {
      case 'currentStock': return currentStockReducer.level2Categories;
      case 'element': return elementReducer.level2Categories;
      case 'stockHistory': return stockHistoryReducer.level2Categories;
      case 'stockPlaceHistory': return stockPlaceHistoryReducer.level2Categories;
      case 'productionPerformance': return productionPerformanceReducer.level2Categories;
      default: return [];
    }
  });

  /* ====================================================================== #3 */
  useEffect(() => {
    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      setClickedLevel1Category(() => { return props.clickedLevel1Category; });
    }
    return () => { }
  }, [props]);

  useEffect(() => {
    getCategoryLevel2List();
    return () => { }
  }, [_clickedLevel1Category]);

  /* ====================================================================== #4 */
  const getCategoryLevel2List = async () => {
    if (Object.keys(_clickedLevel1Category).length < 1) return setCategoryList(() => { return []; });
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      parentCategoryId: _clickedLevel1Category.categoryId,
      categoryLevel: 2,
    };
    await categoryApi.searchCategory(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('categoryApi.searchCategory : ', response);
      switch (props.categoryType) {
        case 'currentStock': dispatch(currentStockActions_setLevel2Categories(response.data)); break;
        case 'element': dispatch(elementActions_setLevel2Categories(response.data)); break;
        case 'stockHistory': dispatch(stockHistoryActions_setLevel2Categories(response.data)); break;
        case 'stockPlaceHistory': dispatch(stockPlaceHistoryActions_setLevel2Categories(response.data)); break;
        case 'productionPerformance': dispatch(productionPerformanceActions_setLevel2Categories(response.data)); break;
        default: break;
      }
      setCategoryList(() => { return response.data; });
    });
  };

  /* ====================================================================== #5 */
  const handleCategory = (e) => {
    if (e.target.value === '') {
      props.setClickedCategory(props.clickedLevel1Category);
      props.setClickedLevel2Category({});
      return;
    }
    const thisFindIndex = _categoryList.findIndex((thisItem) => thisItem.categoryId === Number(e.target.value));
    if (thisFindIndex === -1) return;
    const thisFindItem = _categoryList[thisFindIndex];
    props.setClickedCategory(thisFindItem);
    props.setClickedLevel2Category(thisFindItem);
  };

  return (
    <select
      className="SelectFamily"
      value={checkNullObject(props.clickedLevel2Category, false) ? props.clickedLevel2Category.categoryId : ''}
      onChange={handleCategory}
    >
      <option value="">패밀리 2</option>
      {_categoryList.map((thisItem, index) => {
        return (<option key={index + '_category2'} value={thisItem.categoryId}>{thisItem.categoryName}</option>);
      })}
    </select>
  );
};

const CategoryLevel3 = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { currentStockReducer, elementReducer, productionPerformanceReducer, stockHistoryReducer, stockPlaceHistoryReducer, userReducer, } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_clickedLevel2Category, setClickedLevel2Category] = useState([]);

  const [_categoryList, setCategoryList] = useState(() => {
    switch (props.categoryType) {
      case 'currentStock': return currentStockReducer.level3Categories;
      case 'element': return elementReducer.level3Categories;
      case 'stockHistory': return stockHistoryReducer.level3Categories;
      case 'stockPlaceHistory': return stockPlaceHistoryReducer.level3Categories;
      case 'productionPerformance': return productionPerformanceReducer.level3Categories;
      default: return [];
    }
  });

  /* ====================================================================== #3 */
  useEffect(() => {
    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      setClickedLevel2Category(() => { return props.clickedLevel2Category; });
    }
    return () => { }
  }, [props]);

  useEffect(() => {
    getCategoryLevel3List();
    return () => { }
  }, [_clickedLevel2Category]);

  /* ====================================================================== #4 */
  const getCategoryLevel3List = async () => {
    if (Object.keys(_clickedLevel2Category).length < 1) return setCategoryList(() => { return []; });
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      parentCategoryId: _clickedLevel2Category.categoryId,
      categoryLevel: 3,
    };
    await categoryApi.searchCategory(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('categoryApi.searchCategory : ', response);
      switch (props.categoryType) {
        case 'currentStock': dispatch(currentStockActions_setLevel3Categories(response.data)); break;
        case 'element': dispatch(elementActions_setLevel3Categories(response.data)); break;
        case 'stockHistory': dispatch(stockHistoryActions_setLevel3Categories(response.data)); break;
        case 'stockPlaceHistory': dispatch(stockPlaceHistoryActions_setLevel3Categories(response.data)); break;
        case 'productionPerformance': dispatch(productionPerformanceActions_setLevel3Categories(response.data)); break;
        default: break;
      }
      setCategoryList(() => { return response.data; });
    });
  };

  /* ====================================================================== #5 */
  const handleCategory = (e) => {
    if (e.target.value === '') {
      props.setClickedCategory(props.clickedLevel2Category);
      props.setClickedLevel3Category({});
      return;
    }
    const thisFindIndex = _categoryList.findIndex((thisItem) => thisItem.categoryId === Number(e.target.value));
    if (thisFindIndex === -1) return;
    const thisFindItem = _categoryList[thisFindIndex];
    props.setClickedCategory(thisFindItem);
    props.setClickedLevel3Category(thisFindItem);
  };

  return (
    <select
      className="SelectFamily"
      value={checkNullObject(props.clickedLevel3Category, false) ? props.clickedLevel3Category.categoryId : ''}
      onChange={handleCategory}
    >
      <option value="">패밀리 3</option>
      {_categoryList.map((thisItem, index) => {
        return (<option key={index + '_category3'} value={thisItem.categoryId}>{thisItem.categoryName}</option>);
      })}
    </select>
  );
};

const CategoryLevel4 = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { currentStockReducer, elementReducer, productionPerformanceReducer, stockHistoryReducer, stockPlaceHistoryReducer, userReducer, } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_clickedLevel3Category, setClickedLevel3Category] = useState([]);

  const [_categoryList, setCategoryList] = useState(() => {
    switch (props.categoryType) {
      case 'currentStock': return currentStockReducer.level4Categories;
      case 'element': return elementReducer.level4Categories;
      case 'stockHistory': return stockHistoryReducer.level4Categories;
      case 'stockPlaceHistory': return stockPlaceHistoryReducer.level4Categories;
      case 'productionPerformance': return productionPerformanceReducer.level4Categories;
      default: return [];
    }
  });

  /* ====================================================================== #3 */
  useEffect(() => {
    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      setClickedLevel3Category(() => { return props.clickedLevel3Category; });
    }
    return () => { };
  }, [props]);

  useEffect(() => {
    getCategoryLevel4List();
    return () => { };
  }, [_clickedLevel3Category]);

  /* ====================================================================== #4 */
  const getCategoryLevel4List = async () => {
    if (Object.keys(_clickedLevel3Category).length < 1) return setCategoryList(() => { return []; });
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      parentCategoryId: _clickedLevel3Category.categoryId,
      categoryLevel: 4,
    };
    await categoryApi.searchCategory(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('categoryApi.searchCategory : ', response);
      switch (props.categoryType) {
        case 'currentStock': dispatch(currentStockActions_setLevel4Categories(response.data)); break;
        case 'element': dispatch(elementActions_setLevel4Categories(response.data)); break;
        case 'stockHistory': dispatch(stockHistoryActions_setLevel4Categories(response.data)); break;
        case 'stockPlaceHistory': dispatch(stockPlaceHistoryActions_setLevel4Categories(response.data)); break;
        case 'productionPerformance': dispatch(productionPerformanceActions_setLevel4Categories(response.data)); break;
        default: break;
      }
      setCategoryList(() => { return response.data; });
    });
  };

  /* ====================================================================== #5 */
  const handleCategory = (e) => {
    if (e.target.value === '') {
      props.setClickedCategory(props.clickedLevel3Category);
      props.setClickedLevel4Category({});
      return;
    }
    const thisFindIndex = _categoryList.findIndex((thisItem) => thisItem.categoryId === Number(e.target.value));
    if (thisFindIndex === -1) return;
    const thisFindItem = _categoryList[thisFindIndex];
    props.setClickedCategory(thisFindItem);
    props.setClickedLevel4Category(thisFindItem);
  };

  return (
    <select
      className="SelectFamily"
      value={checkNullObject(props.clickedLevel4Category, false) ? props.clickedLevel4Category.categoryId : ''}
      onChange={handleCategory}
    >
      <option value="">패밀리 4</option>
      {_categoryList.map((thisItem, index) => {
        return (<option key={index + '_category4'} value={thisItem.categoryId}>{thisItem.categoryName}</option>);
      })}
    </select>
  );
};

const CategoryLevel5 = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { currentStockReducer, elementReducer, productionPerformanceReducer, stockHistoryReducer, stockPlaceHistoryReducer, userReducer, } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_clickedLevel4Category, setClickedLevel4Category] = useState([]);

  const [_categoryList, setCategoryList] = useState(() => {
    switch (props.categoryType) {
      case 'currentStock': return currentStockReducer.level5Categories;
      case 'element': return elementReducer.level5Categories;
      case 'stockHistory': return stockHistoryReducer.level5Categories;
      case 'stockPlaceHistory': return stockPlaceHistoryReducer.level5Categories;
      case 'productionPerformance': return productionPerformanceReducer.level5Categories;
      default: return [];
    }
  });

  /* ====================================================================== #3 */
  useEffect(() => {
    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      setClickedLevel4Category(() => { return props.clickedLevel4Category; });
    }
    return () => { };
  }, [props]);

  useEffect(() => {
    getCategoryLevel5List();
    return () => { };
  }, [_clickedLevel4Category]);

  /* ====================================================================== #4 */
  const getCategoryLevel5List = async () => {
    if (Object.keys(_clickedLevel4Category).length < 1) return setCategoryList(() => { return []; });
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      parentCategoryId: _clickedLevel4Category.categoryId,
      categoryLevel: 5,
    };
    await categoryApi.searchCategory(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('categoryApi.searchCategory : ', response);
      switch (props.categoryType) {
        case 'currentStock': dispatch(currentStockActions_setLevel5Categories(response.data)); break;
        case 'element': dispatch(elementActions_setLevel5Categories(response.data)); break;
        case 'stockHistory': dispatch(stockHistoryActions_setLevel5Categories(response.data)); break;
        case 'stockPlaceHistory': dispatch(stockPlaceHistoryActions_setLevel5Categories(response.data)); break;
        case 'productionPerformance': dispatch(productionPerformanceActions_setLevel5Categories(response.data)); break;
        default: break;
      }
      setCategoryList(() => { return response.data; });
    });
  };

  /* ====================================================================== #5 */
  const handleCategory = (e) => {
    if (e.target.value === '') {
      props.setClickedCategory(props.clickedLevel4Category);
      props.setClickedLevel5Category({});
      return;
    }
    const thisFindIndex = _categoryList.findIndex((thisItem) => thisItem.categoryId === Number(e.target.value));
    if (thisFindIndex === -1) return;
    const thisFindItem = _categoryList[thisFindIndex];
    props.setClickedCategory(thisFindItem);
    props.setClickedLevel5Category(thisFindItem);
  };

  return (
    <select
      className="SelectFamily"
      value={checkNullObject(props.clickedLevel5Category, false) ? props.clickedLevel5Category.categoryId : ''}
      onChange={handleCategory}
    >
      <option value="">패밀리 5</option>
      {_categoryList.map((thisItem, index) => {
        return (<option key={index + '_category5'} value={thisItem.categoryId}>{thisItem.categoryName}</option>);
      })}
    </select>
  );
};

export {
  CategoryLevel1,
  CategoryLevel2,
  CategoryLevel3,
  CategoryLevel4,
  CategoryLevel5,
};
