import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { teamApi } from 'api/apis/teamApi';
import { userApi } from 'api/apis/userApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';

import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';

const UpdateUserSection = styled.main`
  & input:disabled {
    background-color: var(--MainNavy);
    color: var(--white);
  }

  .master select {
    background-color: var(--color-1);
    border-color: var(--color-1);
    color: var(--white);
  }
`;

const UpdateUser = () => {
  /* ========================================================================= #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [searchParams, setSearchParams] = useSearchParams();

  const companyId = searchParams.get('companyId');
  const id = searchParams.get('id');
  const teamId = searchParams.get('teamId');
  const email = searchParams.get('email');
  const name = searchParams.get('name');
  const phoneNumber = searchParams.get('phoneNumber');
  const memo = searchParams.get('memo');
  const permission = searchParams.get('permission');
  const role = searchParams.get('role');

  const [_onload, setOnload] = useState('unload');

  const [_formData, setFormData] = useState({
    companyId: checkEmptyNull(companyId, userReducer.company.companyId),
    registerType: 'normal',
    id: Number(id),
    teamId: teamId,

    email: email,
    name: name,
    phoneNumber: phoneNumber,
    memo: memo,

    permission: permission,
    role: role,
  });

  const [_teamList, setTeamList] = useState([]);

  /* ========================================================================= #3 */
  useEffect(() => {
    getTeamList();

    setOnload('loaded');
    return () => { };
  }, []);

  /* ========================================================================= #4 */
  const getTeamList = async () => {
    const paging = `?page=0&size=1000`;
    const BodyToPost = {};
    if (userReducer.user.role !== 'ROLE_MASTER') {
      BodyToPost.companyId = userReducer.company.companyId;
    }
    await teamApi.searchTeam(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('teamApi.searchTeam : ', response);

      setTeamList(() => {
        return response.data.content;
      });
    });
  };

  /* ========================================================================= #5 */
  const handleInputValue = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const makePhoneNumber = (e) => {
    const phoneNumber = e.target.value.toString().replace(/[^0-9]/g, '').replace(/(^02.{0}|^01.{1}|[0-9]{3,4})([0-9]{3,4})([0-9]{4})/g, '$1-$2-$3');
    setFormData((prev) => {
      return {
        ...prev,
        phoneNumber: phoneNumber,
      };
    });
  };

  /* 수정 */
  const actUpdate = async (e) => {
    e.preventDefault();

    if (!checkEmptyNull(_formData.teamId)) { return alert('팀을 선택해주세요.'); }
    if (!checkEmptyNull(_formData.name, false)) { return alert('이름을 입력해주세요.'); }

    const BodyToPut = {
      ..._formData,
      companyId: Number(_formData.companyId),
      teamId: Number(_formData.teamId),
    };
    if (_formData.role !== 'ROLE_MASTER') {
      // BodyToPut.registerType = 'normal'
      BodyToPut.role = 'ROLE_USER';
    }
    if (userReducer.user.role === 'ROLE_MASTER') {
      const findTeamCompany = _teamList.findIndex(team => team.teamId === Number(_formData.teamId));
      if (findTeamCompany !== -1) {
        BodyToPut.companyId = _teamList[findTeamCompany].companyId;
      }
    }
    console.log('BodyToPut : ', BodyToPut);

    await userApi.updateUser(id, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('userApi.updateUser : ', response);

      alert('사용자를 수정했습니다.');

      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body
      contents={
        <>
          <UpdateUserSection className="Main">
            <NavBar title={'사용자설정(공정별 작업자)'} nav={''} />

            <Form
              title={
                <>
                  사용자 <br />
                  수정
                </>
              }
              buttons={
                <>
                  <button className="formButton" onClick={actUpdate}>
                    저장
                  </button>
                  <button
                    className="formButton cancle"
                    onClick={() => {
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                </>
              }
              forms={
                <>
                  {/* <div className="formBox">
                    <h4>동의여부</h4>
                    <input
                      className="fixedBox"
                      type="text"
                      name="permission"
                      value={_formData.permission !== true ? '비동의' : '동의'}
                      disabled
                    />
                  </div> */}

                  <div className="formBox">
                    <h4>이메일</h4>
                    <input
                      className="fixedBox"
                      type="text"
                      name="email"
                      value={checkEmptyNull(_formData.email, '')}
                      disabled
                    />
                  </div>

                  <div className="formBox">
                    <h4>팀</h4>
                    <select
                      name="teamId"
                      data-required="required"
                      value={_formData.teamId}
                      onChange={handleInputValue}
                    >
                      <option value="">팀을 선택해주세요.</option>
                      {(() => {
                        const returnData = [];

                        if (userReducer.user.role === 'ROLE_MASTER') {
                          _teamList.forEach(team => {
                            returnData.push(<option key={team.teamId + '_teamOption'} value={team.teamId}>{`${team.companyName} : ${team.teamName}`}</option>)
                          })
                        } else {
                          _teamList.forEach(team => {
                            returnData.push(<option key={team.teamId + '_teamOption'} value={team.teamId}>{team.teamName}</option>)
                          })
                        }

                        return returnData;
                      })()}
                    </select>
                  </div>

                  <div className="formBox">
                    <h4>이름</h4>
                    <input
                      type="text"
                      name="name"
                      data-required="required"
                      value={checkEmptyNull(_formData.name, '')}
                      onInput={handleInputValue}
                    />
                  </div>

                  <div className="formBox">
                    <h4>연락처</h4>
                    <input
                      type="text"
                      name="phoneNumber"
                      data-required="required"
                      placeholder="연락처 - 를 제외하고 입력해주세요. ex) 01012341234"
                      maxLength={14}
                      value={checkEmptyNull(_formData.phoneNumber, '')}
                      onInput={makePhoneNumber}
                    />
                  </div>

                  <div className="formBox">
                    <h4>메모</h4>
                    <textarea
                      name="memo"
                      data-required="required"
                      value={checkEmptyNull(_formData.memo, '')}
                      onInput={handleInputValue}
                    />
                  </div>

                  {userReducer.user.role === 'ROLE_MASTER' ? (
                    <div className="formBox master">
                      <h4>role</h4>
                      <select
                        name="role"
                        data-required="required"
                        value={_formData.role}
                        onChange={handleInputValue}
                      >
                        <option value="ROLE_GUEST">ROLE_GUEST</option>
                        <option value="ROLE_USER">ROLE_USER</option>
                        <option value="ROLE_MASTER">ROLE_MASTER</option>
                      </select>
                    </div>
                  ) : null}
                </>
              }
            />
          </UpdateUserSection>
        </>
      }
    />
  );
};

export default UpdateUser;
