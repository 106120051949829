import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { removeCookie } from 'preferences/cookie/cookie';

import { mallSiteUserActions_setUser } from 'store/modules/actions/mallSite/mallSiteUserActions';
import { pageReducer_setMenuStauts } from 'store/modules/actions/default/pageActions';
import { userReducer_reset } from 'store/modules/actions/user/userActions';

import { OperatorMenus } from '../consts/OperatorMenus';

import { FaRegUser, FaUsers } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { GrAppsRounded } from "react-icons/gr";
import { IoHomeSharp } from "react-icons/io5";
import { LuHome } from "react-icons/lu";
import { OperatorHeaderComponent, TopMenu } from './OperatorHeader.styled';
import TotalMenus from 'components/header/menu/TotalMenus';
import Version from 'components/header/Version';

const OperatorHeader = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  const [onMenu, setOnMenu] = useState(false);

  useEffect(() => {
    if (userReducer.user.role !== 'ROLE_MASTER') return navigate('/', { replace: true });
    dispatch(pageReducer_setMenuStauts('open'));

    return () => { };
  }, []);

  const handleTotalMenu = () => {
    if (pageReducer.menuStatus === 'open') dispatch(pageReducer_setMenuStauts('close'));
    else dispatch(pageReducer_setMenuStauts('open'));
  };
  const handleUserMenu = (e) => {
    e.preventDefault();
    setOnMenu((prev) => { return !prev });
  }

  const actLogout = () => {
    console.log('================================= removeCookie');
    removeCookie('accessToken');
    removeCookie('refreshToken');
    dispatch(userReducer_reset());
    dispatch(mallSiteUserActions_setUser());
    navigate('/', { replace: true });
  }

  return (
    <OperatorHeaderComponent>
      <Link to="/operator"><LuHome /></Link>
      <div>
        <div className='btn-apps' onClick={handleTotalMenu}><GrAppsRounded /></div>
        <div className='topMenu-wrap'>
          {OperatorMenus.map((menu, index) => {
            return (
              <TopMenu
                key={index + '_header_topMenu'}
                page={menu.menuLink}
                onClick={(e) => { e.preventDefault(); navigate(menu.menuLink, { replace: true }) }}
              >
                {menu.menuName}
              </TopMenu>
            )
          })}
        </div>
      </div>

      <div>
        <button onClick={handleUserMenu}><FaRegUser /></button>
        <button onClick={actLogout}><FiLogOut /></button>
      </div>

      {onMenu ? (
        <div className='user-info'>
          <Link to='/system/companyInformation'>회사정보 <span>({userReducer.company.companyName})</span></Link>
          <Link to='/system/myProfile'>내정보 <span>({userReducer.user.name} 님)</span></Link>
          <Link to='/inquiry'>문의</Link>
          <Version />
        </div>
      ) : null}

      {pageReducer.menuStatus !== 'open' ? (<TotalMenus onClick={handleTotalMenu} />) : null}
    </OperatorHeaderComponent>
  );
};

export default OperatorHeader;
