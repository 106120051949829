import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';

import {
  AXIOS_BASE_HEADERS,
  AXIOS_BASE_URL,
} from 'preferences/server/constants';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';

const DeleteEquipment = () => {
  /* ========================================================================= #1 */
  const navigate = useNavigate();
  const { pageReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();

  const equipmentId = searchParams.get('equipmentId');
  const equipmentCode = searchParams.get('equipmentCode');
  const equipmentName = searchParams.get('equipmentName');
  console.table({
    equipmentId: equipmentId,
    equipmentCode: equipmentCode,
    equipmentName: equipmentName,
  });

  /* ====================================================================== #3 */

  /* ========================================================================= #4 */

  /* ====================================================================== #5 */
  const actDelete = async () => {
    await axios
      .delete(AXIOS_BASE_URL + '/equipment/' + equipmentId, AXIOS_BASE_HEADERS)
      .then((response) => {
        if (response === undefined) return;
        console.log('actDelete - response : ', response);
        if (response.status === 200) {
          alert('설비를 삭제했습니다.');
          navigate(pageReducer.currentPage, { replace: true });
        }
      })
      .catch((error) => {
        console.log('actDelete - error : ', error);
      });
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body
      contents={
        <>
          <main className="Main">
            <NavBar title={'설비설정'} nav={''} />

            <DeleteForm
              delteTitle={'설비'}
              deleteItem={
                <>
                  {'설비명 : ' + equipmentName}
                  <br />
                  {'설비코드 : ' + equipmentCode}
                  <br />
                </>
              }
              deleteText={
                <>
                  설비 기준정보를 삭제합니다.
                  <br />
                </>
              }
              deleteButton={
                <>
                  <button
                    className="formButton cancle"
                    onClick={() => {
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                  <button
                    className="formButton delete"
                    onClick={() => {
                      if (window.confirm('설비를 삭제합니다.')) actDelete();
                      else return;
                    }}
                  >
                    삭제
                  </button>
                </>
              }
            />
          </main>
        </>
      }
    />
  );
};

export default DeleteEquipment;
