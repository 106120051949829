import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { WS_CLIENT } from 'preferences/server/constants';
import { getCookie } from 'preferences/cookie/cookie';

import { userApi } from 'api/apis/userApi';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import NavBar from 'components/nav/NavBar';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 1001;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 80px;
  height: 85%;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const ModalHeaderButtons = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 55px;
`;
const ModalHeaderButton = styled.button``;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}
`;

const UserSection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
`;
const UserList = styled.div`
  align-items: center;
  box-sizing: border-box;
  column-gap: 10px;
  display: flex;
  height: 50px;
  justify-content: flex-start;
  padding: 25px 15px;
  width: 100%;
`;
const WorkerList = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  height: fit-content;
  padding: 0px 15px;
  width: 100%;
`;
const WorkerButton = styled.label`
  align-items: center;
  background-color: var(--MainNavy);
  border: 4px solid var(--white);
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 2em;
  font-weight: 600;
  height: 200px;
  justify-content: center;
  min-height: 100px;
  width: 100%;
`;
const UserCheckBox = styled.input`
  display: none;
  &:checked + label {
    background-color: var(--MainBlue);
    &.managers {background-color: var(--ThirdGreen);}
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--ThirdBlue);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 100%;
`;

const SelectWorkerModal = (props) => {
  /* ========================================================================= #1 */
  const { userReducer, workReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const { type, eventLog, allClose } = props;
  console.log(type, eventLog);

  const [_onload, setOnload] = useState('unload');

  const [_evnetLog, setEventLog] = useState(() => {
    if (type === 'processLog') {
      const processLog = eventLog.processLog;
      const setProcessLog = {
        ...processLog,
        managers: processLog.managers.map((worker) => {
          return {
            processLogManagerId: worker.processLogManagerId,
            id: worker.managerId,
            name: worker.managerName,
          };
        }),
        workers: processLog.workers.map((worker) => {
          return {
            processLogWorkerId: worker.processLogWorkerId,
            id: worker.workerId,
            name: worker.workerName,
          };
        }),
      };
      return setProcessLog;
    } else {
      const workOrderLog = eventLog.workOrderLog;
      const setWorkerOrderLog = {
        ...workOrderLog,
        managers: workOrderLog.managers.map((worker) => {
          return {
            workOrderLogManagerId: worker.workOrderLogManagerId,
            id: worker.managerId,
            name: worker.managerName,
          };
        }),
        workers: workOrderLog.workers.map((worker) => {
          return {
            workOrderLogWorkerId: worker.workOrderLogWorkerId,
            id: worker.workerId,
            name: worker.workerName,
          };
        }),
      };
      return setWorkerOrderLog;
    }
  });
  console.log('_evnetLog : ', _evnetLog);

  const [_userType, setUserType] = useState('worker');
  const [_userList, setUserList] = useState([]);

  const [_prevManager, setPrevManager] = useState(_evnetLog.managers);
  const [_prevWorker, setPrevWorker] = useState(_evnetLog.workers);

  const [_checkedManager, setCheckedManager] = useState([]);
  const [_checkedWorker, setCheckedWorker] = useState([]);

  const [_searchData, setSearchData] = useState({});

  /* ========================================================================= #3 */
  useEffect(() => {
    getUserList();
    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    setUserList((prev) => { return prev; });
  }, [_userType]);

  useEffect(() => { }, [_checkedManager, _checkedWorker]);

  /* ========================================================================= #4 */
  const getUserList = async (clear) => {
    const paging = `?page=0&size=100&sort=name,ASC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    if (!clear && checkEmptyNull(_searchData.teamName, false)) {
      BodyToPost.teamName = _searchData.teamName;
    }
    if (!clear && checkEmptyNull(_searchData.name, false)) {
      BodyToPost.name = _searchData.name;
    }
    await userApi.searchUser(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('userApi.searchUser : ', response);
      setUserList(() => { return response.data.content; });
    });
  };

  /* ========================================================================= #5 */
  const handleCheckedWorker = (item, isChecked) => {
    const checkedItem = [..._checkedWorker];
    const checkedIndex = checkedItem.findIndex((thisItem) => thisItem.id === item.id);
    if (checkedIndex !== -1) {
      if (!isChecked) checkedItem.splice(checkedIndex, 1);
    } else {
      if (isChecked) checkedItem.push(item);
    }
    setCheckedWorker(() => { return checkedItem; });
  };
  const handleCheckedManager = (item, isChecked) => {
    const checkedItem = [..._checkedManager];
    const checkedIndex = checkedItem.findIndex((thisItem) => thisItem.id === item.id);
    if (checkedIndex !== -1) {
      if (!isChecked) checkedItem.splice(checkedIndex, 1);
    } else {
      if (isChecked) checkedItem.push(item);
    }
    setCheckedManager(() => { return checkedItem; });
  };

  const actSearch = async () => { await getUserList(); };

  const actReset = async () => {
    setSearchData(() => { return {}; });
    await getUserList(true);
  };

  const actWorkEnd = async () => {
    if (workReducer.connectStatus !== 'connect') return alert(`
연결이 불안정합니다.
네트워크 상태 확인 후 다시 시도하여 주시기 바랍니다.
    `);

    const managerIds = _checkedManager.map(worker => worker.id);
    const workerIds = _checkedWorker.map(worker => worker.id);
    if (type === 'processLog') {
      const BodyToWS = {
        ...eventLog.BodyToWS,
        managerIds: checkNullArray(managerIds, false) ? managerIds : _prevManager.map((worker) => worker.id),
        workerIds: checkNullArray(workerIds, false) ? workerIds : _prevWorker.map((worker) => worker.id),
      };
      delete BodyToWS.managers;
      delete BodyToWS.workers;
      console.log('client.publish/pub/process - body : ', BodyToWS, JSON.stringify(BodyToWS));

      try {
        WS_CLIENT.publish({
          destination: '/pub/process',
          headers: { 'auth-token': getCookie('accessToken')?.replace('Bearer ', '') },
          body: JSON.stringify(BodyToWS),
        });
      } catch (error) {
        console.log('client.publish/pub/process - error : ', error);
      } finally {
        if (allClose !== undefined && allClose !== null) {
          props.allClose();
          return;
        }
        props.close();
      }
    } else {
      const BodyToWS = {
        ...eventLog.BodyToWS,
        managerIds: checkNullArray(managerIds, false) ? managerIds : _prevManager.map((worker) => worker.id),
        workerIds: checkNullArray(workerIds, false) ? workerIds : _prevWorker.map((worker) => worker.id),
      };
      delete BodyToWS.managers;
      delete BodyToWS.workers;
      console.log('client.publish/pub/process - body : ', BodyToWS, JSON.stringify(BodyToWS));

      try {
        WS_CLIENT.publish({
          destination: '/pub/work',
          headers: { 'auth-token': getCookie('accessToken')?.replace('Bearer ', '') },
          body: JSON.stringify(BodyToWS),
        });
      } catch (error) {
        console.log('client.publish/pub/work - error : ', error);
      } finally {
        if (allClose !== undefined && allClose !== null) {
          props.allClose();
          return;
        }
        props.close();
      }
    }
  };

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>{_userType === 'worker' ? '작업자' : '책임자'} 선택</ModalTitle>
              <ModalHeaderButtons>
                <ModalHeaderButton className="formButton"
                  style={{ backgroundColor: _userType === 'worker' ? 'var(--ThirdGreen)' : 'var(--MainBlue)' }}
                  onClick={() => {
                    if (_userType === 'worker') setUserType(() => { return 'manager'; });
                    else setUserType(() => { return 'worker'; });
                  }}
                >
                  {_userType === 'worker' ? '책임자' : '작업자'} 변경
                </ModalHeaderButton>
              </ModalHeaderButtons>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <NavBar
                title={
                  <>
                    <div className="SearchSection">
                      <div className="ContentCBox">
                        <select className="SearchOption detail">
                          <option value="elementName">팀</option>
                        </select>
                        <input
                          className="SearchBar detail"
                          placeholder="Search..."
                          value={checkEmptyNull(_searchData.teamName, '')}
                          onInput={(e) => {
                            setSearchData((prev) => { return { ...prev, teamName: e.target.value }; });
                          }}
                        />
                      </div>
                      <div className="ContentCBox">
                        <select className="SearchOption detail">
                          <option value="elementName">이름</option>
                        </select>
                        <input
                          className="SearchBar detail"
                          placeholder="Search..."
                          value={checkEmptyNull(_searchData.name, '')}
                          onInput={(e) => {
                            setSearchData((prev) => { return { ...prev, name: e.target.value }; });
                          }}
                        />
                      </div>
                      <div className="ContentCBox">
                        <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                        <div className="ResetButton" onClick={actReset}>초기화</div>
                      </div>
                    </div>
                  </>
                }
                nav={''}
              />
              <UserSection>
                <UserList>
                  기존 책임자 :{' '}
                  {_prevManager.map((worker) => {
                    return (<span key={worker.id + '_prevManager'}>{worker.name}</span>);
                  })}
                </UserList>
                <UserList>
                  변경 책임자 :{' '}
                  {_checkedManager.map((worker) => {
                    return (<span key={worker.id + '_checkedManager'}>{worker.name}</span>);
                  })}
                </UserList>
                <UserList>
                  기존 작업자 :{' '}
                  {_prevWorker.map((worker) => {
                    return (<span key={worker.id + '_prevWorker'}>{worker.name}</span>);
                  })}
                </UserList>
                <UserList>
                  변경 작업자 :{' '}
                  {_checkedWorker.map((worker) => {
                    return (<span key={worker.id + '_checkedWorker'}>{worker.name}</span>);
                  })}
                </UserList>
              </UserSection>
              <WorkerList>
                {_userType === 'worker' ? checkNullArray(_userList, []).map((user, index) => {
                  return (
                    <Fragment key={index}>
                      <UserCheckBox
                        type="checkBox"
                        id={user.id + '_user'}
                        name="users"
                        style={{ display: 'none' }}
                        data-key={user.id}
                        checked={(() => {
                          let checkedId = -1;
                          checkedId = _checkedWorker
                            ? _checkedWorker.findIndex((thisIndex) => thisIndex.id === user.id)
                            : -1;
                          if (checkedId !== -1) return true;
                          else return false;
                        })()}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          handleCheckedWorker(user, isChecked);
                        }}
                        onClick={(e) => { e.stopPropagation(); }}
                      />
                      <WorkerButton htmlFor={user.id + '_user'}>{user.name}</WorkerButton>
                    </Fragment>
                  );
                })
                  : checkNullArray(_userList, []).map((user, index) => {
                    return (
                      <Fragment key={index}>
                        <UserCheckBox
                          type="checkBox"
                          id={user.id + '_user'}
                          name="users"
                          style={{ display: 'none' }}
                          data-key={user.id}
                          checked={(() => {
                            let checkedId = -1;
                            checkedId = _checkedManager
                              ? _checkedManager.findIndex((thisIndex) => thisIndex.id === user.id)
                              : -1;
                            if (checkedId !== -1) return true;
                            else return false;
                          })()}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            handleCheckedManager(user, isChecked);
                          }}
                          onClick={(e) => { e.stopPropagation(); }}
                        />
                        <WorkerButton htmlFor={user.id + '_user'} className="managers">{user.name}</WorkerButton>
                      </Fragment>
                    );
                  })}
              </WorkerList>
            </ModalMain>

            <ModalFooter>
              <ModalButton onClick={actWorkEnd}>완료</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default SelectWorkerModal;
