import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';

import {
  modalReducer_setCurrentPageNum,
  modalReducer_setPageSize,
  modalReducer_setSearchOption,
  modalReducer_setSearchText,
  modalReducer_setTotalCount,
  modalReducer_setWorkStatus,
} from 'store/modules/actions/default/modalActions';

import {
  AXIOS_BASE_HEADERS,
  AXIOS_BASE_URL,
} from 'preferences/server/constants';

import CommaNum from 'components/format/CommaNum';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Paging from 'components/paging/Paging';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import StatusButton from 'components/buttons/StatusButton';
import TableSection from 'components/layouts/table/TableSection';

import GSBarcodeSList from 'pages/barcode/custom/company/gs/modal/procurement/GSBarcodeSList';
import GSBarcodeWList from 'pages/barcode/custom/company/gs/modal/productionOrder/GSBarcodeWList';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px calc(100% - 55px);
  height: 85%;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
  }
`;

const GSButton = styled.div`
  align-items: center;
  background-color: ${(props) => {
    return props.type === 'procurement'
      ? 'var(--MainBlue)'
      : props.type === 'CP'
      ? 'var(--ThirdBlue)'
      : props.type === 'CL'
      ? 'var(--MainNavy)'
      : null;
  }};
  border-radius: 5px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 15px;
  height: calc(90px / 2);
  justify-content: center;
  margin: 0px auto;
  width: 96%;
`;

const NavSection = styled.nav`
  align-items: center;
  background-color: var(--white);
  box-sizing: border-box;
  column-gap: 10px;
  display: flex;
  height: 60px;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0px 15px;
  width: 100%;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 4px;
  }
`;

const SelectType = styled.select`
  background-color: var(--ThirdBlue);
  border: 1px solid var(--ThirdBlue);
  border-radius: 17px;
  box-sizing: border-box;
  color: var(--white);
  height: 35px;
  padding: 5px 20px;
  width: 150px;

  &:focus {
    background-color: var(--MainNavy);
  }
`;

const SearchBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

const SearchOption = styled.select`
  border: 1px solid var(--gray-200);
  border-radius: 8px;
  /* border-top-left-radius: 8px;
  border-bottom-left-radius: 8px; */
  box-sizing: border-box;
  height: 35px;
  padding: 2px 10px;
  width: 100px;
`;

const SearchBar = styled.input`
  border: 1px solid var(--gray-200);
  border-radius: 8px;
  /* border-left: unset;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px; */
  box-sizing: border-box;
  height: 35px;
  margin-right: 5px;
  padding: 5px;
  width: 250px;

  &:focus {
    border: 1px solid var(--gray-200);

    border-left: unset;
    border-right: unset;
  }
`;

const Navigation = (props) => {
  /* ========================================================================= #1 */
  const { modalReducer } = useSelector((state) => state);
  const dispatch = useDispatch();

  /* ========================================================================= #5 */
  /* 작업상태 선택 */
  const actSelectStatus = (e) => {
    const currentWorkStatus = e.target.value;
    console.log('currentWorkStatus:', currentWorkStatus);
    dispatch(modalReducer_setWorkStatus(currentWorkStatus));

    document.getElementById('SelectType').value = currentWorkStatus;
  };
  /* 검색옵션 선택 */
  const actSelectSearchOption = (e) => {
    dispatch(modalReducer_setSearchOption(e.target.value));
  };

  /* 검색바 입력 */
  const actInputSearchText = (e) => {
    dispatch(modalReducer_setSearchText(e.target.value));
  };

  /* ====================================================================== #6 */

  return (
    <NavSection>
      {/* 물품타입 */}
      <SelectType
        id="SelectType"
        value={modalReducer.workStatus || ''}
        onChange={actSelectStatus}
      >
        <option value="" disabled>
          수주상태
        </option>
        <option value="waiting">대기</option>
        <option value="proceeding">진행</option>
        <option value="end">완료</option>
      </SelectType>
      {/* 텍스트 */}
      <SearchBox>
        {/* 검색 옵션 */}
        <SearchOption
          id="Search_Option"
          value={modalReducer.searchOption || ''}
          onChange={actSelectSearchOption}
        >
          <option value="" disabled>
            검색옵션
          </option>
          <option value="accountName">거래처</option>
          <option value="contractCode">수주코드</option>
          <option value="contractName">공사명</option>
        </SearchOption>

        {/* 검색 바 */}
        <SearchBar
          type="text"
          value={modalReducer.searchText || ''}
          placeholder="Search..."
          onInput={actInputSearchText}
        ></SearchBar>

        {/* 검색 버튼 */}
        <button id="searchButton" onClick={props.searchEvent}>
          <SearchButtonIcon />
        </button>
      </SearchBox>
    </NavSection>
  );
};

const GSContractList = (props) => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, modalReducer, userReducer } = useSelector(
    (state) => state,
  );

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_ContractList, setContractList] = useState([]);

  const [_showProcurementStatus, setShowProcurementStatus] = useState(false);
  const [_showCLStatus, setShowCLStatus] = useState(false);

  const [_selectedOrder, setSelectedOrder] = useState();
  const [_selectedWorkOrderId, setSelectedWorkOrderId] = useState();

  /* ========================================================================= #3 */
  useEffect(() => {
    dispatch(modalReducer_setCurrentPageNum(1));
    dispatch(modalReducer_setPageSize(10));

    setOnload('loaded');

    console.log(modalReducer.workStatus);
    if (modalReducer.workStatus !== 'proceeding') {
      return dispatch(modalReducer_setWorkStatus('proceeding'));
    }

    setContractTable();

    return () => {};
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      if (modalReducer.searchOption === '') {
        dispatch(modalReducer_setCurrentPageNum(1));
        return setContractTable();
      }
      setContractTable();
    }

    return () => {};
  }, [modalReducer.workStatus]);

  useEffect(() => {
    if (_onload === 'loaded') {
      if (modalReducer.searchOption === '') {
        setContractTable();
      } else {
        setContractTable();
      }
    }

    return () => {};
  }, [
    modalReducer.searchOption,
    modalReducer.currentPageNum,
    modalReducer.totalCount,
  ]);

  /* ========================================================================= #4 */
  const setDateFormat = (thisDate) => {
    // console.log('setDateFormat : ', thisDate)
    if (
      thisDate !== undefined &&
      thisDate !== null &&
      typeof thisDate === 'object'
    ) {
      const newtDate = new Date(thisDate.join('-'));
      return moment(newtDate).format('YYYY-MM-DD');
    } else {
      return thisDate;
    }
  };

  const setContractTable = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    if (modalReducer.workStatus !== '') {
      BodyToPost.contractStatus = modalReducer.workStatus;
    }
    if (modalReducer.searchOption !== '') {
      BodyToPost[modalReducer.searchOption] = modalReducer.searchText;
    }
    if (modalReducer.startDate !== '' && modalReducer.endDate !== '') {
      const thisStartDate = new Date(modalReducer.startDate);
      const setStartDate = thisStartDate.toISOString();
      BodyToPost.contractStartDate = setStartDate;

      const thisEndDate = new Date(modalReducer.endDate);
      thisEndDate.setDate(thisEndDate.getDate() + 1);
      const setEndDate = thisEndDate.toISOString();
      BodyToPost.contractEndDate = setEndDate;
    }
    console.log('setContractTable - BodyToPost : ', BodyToPost);

    const paging = `?page=${modalReducer.currentPageNum - 1}&size=${
      modalReducer.pageSize
    }`;
    console.log(paging, modalReducer.currentPageNum);

    await axios
      .post(
        AXIOS_BASE_URL + '/contract/search' + paging,
        {
          ...BodyToPost,
          companyId: userReducer.company.companyId,
        },
        AXIOS_BASE_HEADERS,
      )
      .then((response) => {
        if (response === undefined) return;
        console.log(
          'setContractTable|searchAllContract - response : ',
          response,
        );
        Finish_setContractTable(response.data);

        dispatch(modalReducer_setTotalCount(response.data.totalElements));
      })
      .catch((error) => {
        console.log('setContractTable|searchAllContract - error : ', error);
      });
  };
  const Finish_setContractTable = (thisResult) => {
    const searchContractList = thisResult.content;
    console.log(
      'Finish_setContractTable - searchContractList : ',
      searchContractList,
    );

    setContractList(searchContractList);
  };

  /* ========================================================================= #5 */
  /* 검색 */
  const actSearch = async () => {
    dispatch(modalReducer_setCurrentPageNum(1));
    dispatch(modalReducer_setPageSize(10));

    await setContractTable();
  };

  const showProcurement = (e) => {
    const contractId = e.target.dataset.contractid;
    const procurementId = e.target.dataset.procurementid;

    const thisFindIndex = _ContractList.findIndex(
      (thisData) => thisData.contractId === Number(contractId),
    );

    const thisContract = _ContractList[thisFindIndex];

    setSelectedOrder(thisContract);
    setShowProcurementStatus(true);
  };

  const showCL = (e) => {
    const contractId = e.target.dataset.contractid;
    const CLId = e.target.dataset.workorderlogid;

    const thisFindIndex = _ContractList.findIndex(
      (thisData) => thisData.contractId === Number(contractId),
    );
    const thisContract = _ContractList[thisFindIndex];

    setSelectedOrder(thisContract);
    setSelectedWorkOrderId(Number(CLId));
    setShowCLStatus(true);
  };

  /* ========================================================================= #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>수주리스트</ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              <Navigation searchEvent={actSearch} />

              <TableSection
                content={
                  <table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '100px', width: '100px' }}>
                          리스트
                        </th>
                        <th style={{ minWidth: '60px', width: '60px' }}>
                          수주상태
                        </th>
                        {/* <th style={ {minWidth: '50px', width: '50px' } }>
                    <AllCheckBox key={ 'AllCheckBox' } checkboxName={ 'Contract' } />
                  </th> */}
                        <th>거래처</th>
                        <th>수주코드</th>
                        <th>공사명</th>
                        <th>현장명</th>
                        <th>현장주소</th>
                        <th>수주총금액</th>
                        <th>수주일자</th>
                        <th>{userReducer.endDateName[0]}</th>
                        <th>{userReducer.endDateName[1]}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {_ContractList.length > 0 &&
                        _ContractList.map((thisList) => {
                          console.log(thisList);

                          let customizedContent = JSON.parse(
                            thisList.contractCustomizedContent,
                          );
                          console.log(customizedContent);
                          if (
                            customizedContent === undefined ||
                            customizedContent === null
                          ) {
                            customizedContent = {
                              workPlaceName: '',
                              workPlaceAddress: '',
                            };
                          }

                          let thisProcurementList = null;
                          if (
                            thisList.procurementList !== undefined &&
                            thisList.procurementList !== null &&
                            thisList.procurementList.length > 0
                          ) {
                            thisProcurementList = thisList.procurementList[0];
                          }

                          let thisCP = null;
                          let thisCL = null;
                          if (
                            thisList.workOrderLogList !== undefined &&
                            thisList.workOrderLogList !== null &&
                            thisList.workOrderLogList.length > 0
                          ) {
                            thisList.workOrderLogList.forEach(
                              (thisWorkOrderLog) => {
                                if (
                                  thisWorkOrderLog.workOrderName !==
                                    undefined &&
                                  thisWorkOrderLog.workOrderName !== null
                                ) {
                                  if (
                                    thisWorkOrderLog.workOrderName.endsWith(
                                      'CP',
                                    ) === true
                                  ) {
                                    thisCP = thisWorkOrderLog;
                                  }
                                  if (
                                    thisWorkOrderLog.workOrderName.endsWith(
                                      'CL',
                                    ) === true
                                  ) {
                                    thisCL = thisWorkOrderLog;
                                  }
                                }
                              },
                            );
                          }

                          return (
                            <tr
                              key={thisList.contractId + '_수주'}
                              data-key={thisList.contractId}
                              data-status={thisList.contractStatus}
                            >
                              <td
                                style={{
                                  minWidth: '100px',
                                  padding: '5px 0px',
                                  width: '100px',
                                }}
                              >
                                <div
                                  style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    rowGap: '2px',
                                  }}
                                >
                                  <GSButton
                                    type="procurement"
                                    data-contractid={thisList.contractId}
                                    onClick={showProcurement}
                                  >
                                    발주리스트
                                  </GSButton>
                                  <GSButton
                                    type="CL"
                                    data-contractid={thisList.contractId}
                                    onClick={showCL}
                                  >
                                    체크리스트
                                  </GSButton>
                                </div>
                              </td>

                              <td
                                style={{
                                  minWidth: '60px',
                                  padding: 'unset',
                                  width: '60px',
                                }}
                              >
                                {thisList.contractStatus !== null && (
                                  <StatusButton
                                    workOrderCode={thisList.contractId}
                                    status={thisList.contractStatus}
                                    statusEvent={null}
                                    statusStyle={{
                                      cursor: 'default',
                                      minHeight: '100px',
                                    }}
                                  />
                                )}
                              </td>

                              {/* <td style={ {minWidth: '50px', width: '50px' } }>
                      <CheckBox act={ 'one' } all={ false } name={ 'Contract' } />
                    </td> */}

                              <td>{thisList.accountName}</td>
                              <td>{thisList.contractCode}</td>
                              <td>{thisList.contractName}</td>
                              <td>{customizedContent.workPlaceName || ''}</td>
                              <td>
                                {customizedContent.workPlaceAddress || ''}
                              </td>
                              <td>
                                <CommaNum
                                  displayType={'text'}
                                  num={thisList.contractPrice}
                                />
                              </td>
                              <td>{setDateFormat(thisList.contractDate)}</td>
                              <td>
                                {setDateFormat(thisList.scheduledEndDate)}
                              </td>
                              <td>
                                {setDateFormat(thisList.scheduledEndDate2)}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                }
              />
            </ModalMain>

            <Paging
              page={modalReducer.currentPageNum}
              count={modalReducer.totalCount}
              size={modalReducer.pageSize}
              modal={true}
            />
          </ModalSection>
        </ModalBg>
      )}

      {_showProcurementStatus === true ? (
        <GSBarcodeSList
          content={_selectedOrder}
          open={_showProcurementStatus}
          close={async () => {
            // 발주리스트
            // await setContractTable();
            setShowProcurementStatus(false);
            props.close();
          }}
        />
      ) : null}

      {_showCLStatus === true ? (
        <GSBarcodeWList
          content={_selectedOrder}
          open={_showCLStatus}
          close={async () => {
            // 체크리스트
            // await setContractTable();
            setShowCLStatus(false);
            props.close();
          }}
        />
      ) : null}
    </>
  );
};

export default GSContractList;
