const initialState = {
  shipmentStatus: 'all',
  startDate: undefined,
  endDate: undefined,
  searchOption: '',
  searchText: '',
  searchData: {},
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
  viewScale: 100,
};
const resetState = {
  shipmentStatus: 'all',
  startDate: undefined,
  endDate: undefined,
  searchOption: '',
  searchText: '',
  searchData: {},
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const shipmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETSHIPMENTSTATUS': return {...state,shipmentStatus: action.payload};
    case 'SETSHIPMENTSTARTDATE': return {...state,startDate: action.payload};
    case 'SETSHIPMENTENDDATE': return {...state,endDate: action.payload};
    case 'SETSHIPMENTSEARCHOPTION': return {...state,searchOption: action.payload};
    case 'SETSHIPMENTSEARCHTEXT': return {...state,searchText: action.payload};
    case 'SETSHIPMENTSEARCHDATA': return {...state,searchData: action.payload};
    case 'SETSHIPMENTPAGENUMBER': return {...state,pageNumber: action.payload};
    case 'SETSHIPMENTTOTALSIZE': return {...state,totalSize: action.payload};
    case 'SETSHIPMENTISPUSHEDSEARCHBUTTON': return {...state,isPushedSearchButton: action.payload};

    case 'SETSHIPMENTVIEWSCALE': return {...state,viewScale: action.payload};

    case 'SETSHIPMENTRESET': return {...state,...resetState};
    default: return state;
  }
};

export default shipmentReducer;
