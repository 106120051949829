import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { fileDataApi } from 'api/apis/fileDataApi';

import { checkNullParse } from 'components/checkValues/checkValues';
import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

const DeleteDrawingManagement = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();

  const deleteContents = checkNullParse(searchParams.get('deleteContents'), []);
  const [_deleteContents, setDeleteContents] = useState(deleteContents);

  /* ====================================================================== #3 */
  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  let delCount = 0;
  const actAllDelete = () => {
    deleteContents.forEach(async (item) => {
      await actDelete(item.deleteContent);
    });
  };

  const actDelete = async (fileData) => {
    const actDelete_fileData = async () => {
      await fileDataApi.deleteFileData(fileData.fileDataId).then((response) => {
        if (response === undefined) return;
        setDeleteContents((prev) => {
          const contents = [...prev];
          const findIndex = contents.findIndex((item) => item.fileDataId === fileData.fileDataId);
          contents.splice(findIndex, 1);
          return contents;
        });
      }).finally(() => {
        delCount++;
        if (delCount === deleteContents.length) {
          alert('도면을 삭제했습니다.');
          setTimeout(navigate(pageReducer.currentPage, { replace: true }), 1000);
        }
      });
    };
    actDelete_fileData();
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <>
        <main className="Main">
          <NavBar title={<NavTitle menuCode={'110'} />} nav={''} />

          <DeleteForm
            delteTitle={'도면'}
            deleteItem={_deleteContents.map((thisItem) => {
              const deleteContent = thisItem.deleteContent;
              return (<Fragment key={deleteContent.fileDataId + '_deleteContent'}>
                <p><b>도면이름:</b> {deleteContent.fileDataName}</p>
                <br />
              </Fragment>);
            })}
            deleteText={<><span className="cautionText">도면</span>을 삭제합니다.</>}
            deleteButton={
              <>
                <button className="formButton cancle"
                  onClick={() => { navigate(pageReducer.currentPage, { replace: true }); }}
                >
                  취소
                </button>
                <button className="formButton delete"
                  onClick={() => {
                    if (window.confirm('도면을 삭제합니다.')) actAllDelete();
                    else return;
                  }}
                >
                  삭제
                </button>
              </>
            }
          />
        </main>
      </>
    }
    />
  );
};

export default DeleteDrawingManagement;
