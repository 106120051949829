const initialState = {
  mallSiteQnAStatus: 'all',

  startDate: undefined,
  endDate: undefined,
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const resetState = {
  mallSiteQnAStatus: 'all',

  startDate: undefined,
  endDate: undefined,
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const mallSiteQnAReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETMALLSITEQNASTATUS':
      return {
        ...state,
        mallSiteQnAStatus: action.payload,
      };
    case 'SETMALLSITEQNASTARTDATE':
      return {
        ...state,
        startDate: action.payload,
      };
    case 'SETMALLSITEQNAENDDATE':
      return {
        ...state,
        endDate: action.payload,
      };
    case 'SETMALLSITEQNASEARCHOPTION':
      return {
        ...state,
        searchOption: action.payload,
      };
    case 'SETMALLSITEQNASEARCHTEXT':
      return {
        ...state,
        searchText: action.payload,
      };
    case 'SETMALLSITEQNAPAGENUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'SETMALLSITEQNATOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };
    case 'SETMALLSITEQNAISPUSHEDSEARCHBUTTON':
      return {
        ...state,
        isPushedSearchButton: action.payload,
      };
    case 'SETMALLSITEQNARESET':
      return {
        ...state,
        ...resetState,
      };

    default:
      return state;
  }
};

export default mallSiteQnAReducer;
