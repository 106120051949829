export const stockReducer_setInItem = (thisITem) => {
  return {
    type: 'SETINITEM',
    payload: thisITem,
  };
};

export const stockReducer_setPutItem = (thisITem) => {
  return {
    type: 'SETPUTITEM',
    payload: thisITem,
  };
};

export const stockReducer_setOutItem = (thisITem) => {
  return {
    type: 'SETOUTITEM',
    payload: thisITem,
  };
};

export const stockReducer_setContractProducts = (thisProducts) => {
  return {
    type: 'SETORDERSPRODUCTS',
    payload: thisProducts,
  };
};

export const stockReducer_setContractAccount = (thisAccount) => {
  return {
    type: 'SETORDERSACCOUNT',
    payload: thisAccount,
  };
};

export const stockReducer_setProcurementProducts = (thisProducts) => {
  return {
    type: 'SETPLACINGORDERSPRODUCTS',
    payload: thisProducts,
  };
};

export const stockReducer_setProcurementAccount = (thisAccount) => {
  return {
    type: 'SETPLACINGORDERSACCOUNT',
    payload: thisAccount,
  };
};

export const stockReducer_reset = () => {
  return {
    type: 'STOCKRESET',
  };
};
