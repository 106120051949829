import React, { useEffect, useState } from 'react';

import { teamApi } from 'api/apis/teamApi';
import { userApi } from 'api/apis/userApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import PagingComponent from 'components/paging/PagingComponent';

const TeamContent = ({
  close, modalData
}) => {
  const body = {
    companyId: modalData.companyId,
    teamId: modalData.team?.teamId,
    id: modalData.id,
    name: modalData.name,
    role: modalData.role,
    email: modalData.email,
    emailVerified: modalData.emailVerified,
    phoneNumber: modalData.phoneNumber,
    memo: modalData.memo,
  };

  const [_companyList, setCompanyList] = useState([]);
  const [_teamList, setTeamList] = useState([]);
  const [_pageNumber, setPageNumber] = useState(1);
  const [_totalSize, setTotalSize] = useState(0);

  const [_teamId, setTeamId] = useState();
  const [_teamName, setTeamName] = useState();

  useEffect(() => {
    getTeamList(_pageNumber - 1);
  }, [_pageNumber]);
  useEffect(() => {}, [_teamId, _teamName]);

  const getTeamList = async (page) => { // 팀 리스트 불러오기
    const paging = `?page=${page}&size=10`;
    const searchBody = {companyId: body.companyId};
    if(checkEmptyNull(_teamName, false)) searchBody.teamName = _teamName;
    await teamApi.searchTeam(paging, searchBody).then((response) => {
      if (response === undefined) return;
      setTeamList(() => {return response.data.content;});
      setTotalSize(() => {return response.data.totalElements})
    });
  };

  const actSearch = async () => { // 검색
    setPageNumber(() => {return 1});
    await getTeamList(0);
  };

  const actTeamUpdate = (team) => { // 팀 변경
    const updateBody = {...body, teamId: team?.teamId};
    updateUser(updateBody.id, updateBody);
  };
  const updateUser = async(id, updateBody) => {
    await userApi.updateUser(id, updateBody).then(response => {
      if(response === undefined) return;
      console.log('actRoleUpdate : ', response);
      alert('사용자 팀을 변경했습니다.');
      close('update');
    })
  }

  return (
    <section className='team-content'>
      <div>
        <div>
          <input
            type='text'
            name='teamName'
            placeholder='팀 이름...'
            onInput={(e) => {
              if(e.keyCode === 13 || e.key === 'Enter') {
                return actSearch();
              }
              const name = e.target.value;
              setTeamName(() => {return name});
            }}
            onKeyUp={(e) => {
              if(e.keyCode === 13 || e.key === 'Enter') {
                actSearch();
              }
            }}
          />
          <button className="btn-set" onClick={actSearch}>검색</button>
        </div>
      </div>

      <div>
        <h4>현재 팀</h4>
        <p title={modalData.team?.teamName}>
          <span>{modalData.team?.teamName}</span>
        </p>
      </div>

      <div>
        <h4>팀 리스트</h4>
        {_teamList.map(team => {
          return(
            <p key={team.teamId + '_team'} 
              title={team.companyName + ' ' + team.teamName}
              onClick={() => {actTeamUpdate(team);}}
            >
              <span>{team.teamName}</span>
            </p>
          );
        })}
      </div>

      <PagingComponent
        page={_pageNumber}
        count={_totalSize}
        size={10}
        pageEvent={(page) => {
          console.log('page : ', page);
          setPageNumber(() => {return page});
        }}
      />
    </section>
  );
};

export default TeamContent;