import styled from "styled-components";

export const OperatingProcessTrackingComponent = styled.section`
  align-content: flex-start;
  box-sizing: border-box;
  display: grid;
  /* grid-template-rows: 50px 40px calc(100% - 120px); */
  grid-template-rows: 50px auto auto 60px;
  gap: 15px;
  height: 100%;
  overflow: hidden;
  padding: 30px 50px;
  width: 100%;

  & header {
    align-items: baseline;
    box-sizing: border-box;
    display: flex;
    height: 50px;
    justify-content: space-between;
    width: 100%;

    & h1 {
      font-size: 30px;
      font-weight: 600;
    }
    & button {
      align-items: center;
      background-color: #246BEB;
      border-radius: 8px;
      box-sizing: border-box;
      color: var(--white);
      display: flex;
      gap: 5px;
      justify-content: center;
      line-height: 26px;
      padding: 10px 16px;

      &>svg {
        height: 24px;
        margin-block-start: 3px;
        width: 24px;
      }
    }
  }

  & nav { // 검색
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;
    padding-block: 10px;

    & .nav-bar {
      align-items: center;
      box-sizing: border-box;
      border: 1px solid #1D1D1D;
      border-radius: 5px;
      display: flex;
      gap: 10px;
      height: 40px;
      justify-content: space-between;
      padding-inline: 15px;
      position: relative;
      width: fit-content;

      /* &:first-of-type {
        max-width: 200px;
      } */

      &>p {
        font-size: 14px;
        left: 5px;
        position: absolute;
        top: -60%;
      }

      &>select, &>input {
        font-size: 16px;
        width: 120px;
        /* width: 100%; */
        &[type="text"] {width: 200px;}
      }
    }

    &>button {
      height: 50px;
      /* width: 50px; */
      &:hover {background: unset;}
      &>svg {height: 24px; width: 24px;}
    }
  }

  &>div { // 작업지시 리스트
    /* height: 100%; */
    overflow-y: scroll;
    width: 100%;

    & table {
      border-collapse: collapse;
      min-width: 100%;
      height: fit-content;
      position: relative;

      & thead {
        background-color: var(--white);
        position: sticky;
        top: 0;
        z-index: 1;
      }
  
      & th, & td {
        box-sizing: border-box;
        
        padding: 10px;
        text-align: left;
      }
      & thead th {border-block-end: 1px solid #1D1D1D; font-size: 17px;}
      & tbody td {border-block-end: 1px solid #d8d8d8; font-size: 16px;}
  
      & td.cell-status { // 작업상태
        &>div {
          align-items: center;
          display: flex;
          gap: 10px;
        }
      }
      & td.cell-processLog { // 공정
        padding: unset;
        &>div {
          align-items: flex-start;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          width: 100%;
        }
      }
    }
  }
`;

export const WorkOrderStatusComponent = styled.div` // 공정트래킹 - 작업상태
  border-radius: 50%;
  height: 15px;
  width: 15px;

  &.wait {background-color: var(--Text);}
  &.work, &.working {background-color: #008A1E;}
  &.pause {background-color: #FFB724;}
  &.stop {background-color: #EB003B;}
  &.end {background-color: #0C1F4D;}
  &.cancel {background-color: var(--Bg);}
`;
export const ProcessLogComponent = styled.div` // 공정트래킹 - 공정
  align-items: center;
  border: 1px solid var(--white);
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  font-size: 14px;
  height: 40px;
  justify-content: flex-start;
  padding: 10px;
  width: 150px;

  &.wait {background-color: var(--Text);}
  &.work, &.working {background-color: #008A1E;}
  &.pause {background-color: #FFB724;}
  &.stop {background-color: #EB003B;}
  &.end {background-color: #0C1F4D;}
  &.cancel {background-color: var(--Bg);}
`;