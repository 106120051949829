import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {mallSiteElementActions_setClickedCategory, mallSiteElementActions_setClickedLevel1Category, mallSiteElementActions_setClickedLevel2Category, mallSiteElementActions_setClickedLevel3Category} from 'store/modules/actions/mallSite/mallSiteElementActions';

import {MallCategoryLevel1, MallCategoryLevel2, MallCategoryLevel3} from 'components/mallCategory/MallCategory';
import IconRightArrow from 'components/icons/IconRightArrow';

/* ~카테고리3 */
const MallSiteCategories = (props) => {
  const dispatch = useDispatch();
  const { mallSiteElementReducer } = useSelector((state) => state);

  const { categoryType } = props;

  useEffect(() => {return () => {};}, [mallSiteElementReducer.clickedCategory]);

  const actClickCategory = (category) => {
    switch (categoryType) {
      case 'mallSiteElement': return dispatch(mallSiteElementActions_setClickedCategory(category));
      default: return;
    }
  };

  return (
    <li className='mall-product-category'>
      <MallCategoryLevel1
        mallCategoryType={categoryType}
        clickedLevel1Category={(() => {
          switch (categoryType) {
            case 'mallSiteElement': return mallSiteElementReducer.clickedLevel1Category;
            default: return {};
          }
        })()}
        setClickedCategory={(category) => {actClickCategory(category);}}
        setClickedLevel1Category={(category) => {
          switch (categoryType) {
            case 'mallSiteElement':
              dispatch(mallSiteElementActions_setClickedLevel3Category({}));
              dispatch(mallSiteElementActions_setClickedLevel2Category({}));
              dispatch(mallSiteElementActions_setClickedLevel1Category(category));
              return ;
            default: return;
          }
        }}
      ></MallCategoryLevel1>

      <IconRightArrow style={{width: '24px', height: '24px', backgroundColor: '#898989'}}/>

      <MallCategoryLevel2
        mallCategoryType={categoryType}
        clickedLevel1Category={(() => {
          switch (categoryType) {
            case 'mallSiteElement': return mallSiteElementReducer.clickedLevel1Category;
            default: return {};
          }
        })()}
        clickedLevel2Category={(() => {
          switch (categoryType) {
            case 'mallSiteElement': return mallSiteElementReducer.clickedLevel2Category;
            default: return {};
          }
        })()}
        setClickedCategory={(category) => {actClickCategory(category);}}
        setClickedLevel2Category={(category) => {
          switch (categoryType) {
            case 'mallSiteElement': 
              dispatch(mallSiteElementActions_setClickedLevel3Category({}));
              dispatch(mallSiteElementActions_setClickedLevel2Category(category));
              return ;
            default: return;
          }
        }}
      ></MallCategoryLevel2>

      <IconRightArrow style={{width: '24px', height: '24px', backgroundColor: '#898989'}}/>

      <MallCategoryLevel3
        mallCategoryType={categoryType}
        clickedLevel2Category={(() => {
          switch (categoryType) {
            case 'mallSiteElement': return mallSiteElementReducer.clickedLevel2Category;
            default: return {};
          }
        })()}
        clickedLevel3Category={(() => {
          switch (categoryType) {
            case 'mallSiteElement': return mallSiteElementReducer.clickedLevel3Category;
            default: return {};
          }
        })()}
        setClickedCategory={(category) => {actClickCategory(category);}}
        setClickedLevel3Category={(category) => {
          switch (categoryType) {
            case 'mallSiteElement': return dispatch(mallSiteElementActions_setClickedLevel3Category(category));
            default: return;
          }
        }}
      ></MallCategoryLevel3>
    </li>
  );
};

export default MallSiteCategories;
