import { AxiosInterceptor } from "api/core/consts";

export const sensorDataApi = {
  sensorRealTimeData: (body) => AxiosInterceptor.post('sensor/search', body),

  countData: (paging, body) => AxiosInterceptor.post('sensorData/search' + paging, body),
  daeLimSensorData: (paging, body) => AxiosInterceptor.post('sensorData/search' + paging, body),
  gsSensorData: (paging, body) => AxiosInterceptor.post('sensorData/search' + paging, body),
  hslandSensorData: (paging, body) => AxiosInterceptor.post('sensorData/search' + paging, body),
  uriSensorData: (paging, body) => AxiosInterceptor.post('sensorData/search' + paging, body),

  getSensorDatas: (search) => AxiosInterceptor.get('sensorDatas' + search),
};
