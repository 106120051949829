const initialState = {
  currentPage: '',

  menuStatus: 'open',

  currentPageNum: 1,
  totalCount: 0,
  pageSize: 20,

  modal_currentPageNum: 1,
  modal_totalCount: 0,
  modal_pageSize: 20,
};

const updateReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'GETCURRENTPAGE':
      return {
        ...state,
        currentPage: action.payload
      };

    case 'SETMENUSTATUS':
      return {
        ...state,
        menuStatus: action.payload
      };

    case 'SETPAGENUM':
      return {
        ...state,
        currentPageNum: action.payload
      };

    case 'SETTOTALCOUNT':
      return {
        ...state,
        totalCount: action.payload
      };

    case 'SETPAGESIZE':
      return {
        ...state,
        pageSize: action.payload
      };

    case 'SETMODALPAGENUM':
      return {
        ...state,
        modal_currentPageNum: action.payload
      };

    case 'SETMODALTOTALCOUNT':
      return {
        ...state,
        modal_totalCount: action.payload
      };

    case 'SETMODALPAGESIZE':
      return {
        ...state,
        modal_pageSize: action.payload
      };

    case 'PAGERESET':
      return {
        ...state,
        menuStatus: 'open',
        currentPageNum: 1,
        totalCount: 0,
        
        modal_currentPageNum: 1,
        modal_totalCount: 0,
        modal_pageSize: 20,
      };

    default:
      return state;
  }
}

export default updateReducer;