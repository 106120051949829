import React, { useEffect, useState } from 'react';

import { checkNullArray } from 'components/checkValues/checkValues';
import TableSection from 'components/layouts/table/TableSection';

const SensorResponse = (props) => {
  console.log('props : ', props);
  const [sensorList, setSensorList] = useState(checkNullArray(props.sensorList, []));

  useEffect(() => {
    setSensorList(() => { return checkNullArray(props.sensorList, []) });
  }, [props])

  return (
    <TableSection content={
      <table>
        <colgroup>
          <col width={'50px'} />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col width={'600px'} />
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th>companyId</th>
            <th>id</th>
            <th>kind</th>
            <th>type</th>
            <th>name</th>
            <th>code</th>
            <th>childrenList</th>
            {/* <th>SensorSettingList</th> */}
          </tr>
        </thead>
        <tbody>
          {sensorList.map((sensor) => (
            <tr key={sensor.id}>
              <td>
                <input
                  type="checkbox"
                  name="sensor"
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    if (isChecked) {
                      document.getElementsByName('sensor').forEach((checkbox) => { checkbox.checked = false });
                      e.target.checked = true;
                      props.onSensorIdChange(sensor);
                    } else {
                      e.target.checked = false;
                      props.onSensorIdChange({});
                    }
                  }}
                  onClick={(e) => { e.stopPropagation() }}
                />
              </td>
              <td>{sensor.companyId}</td>
              <td>{sensor.id}</td>
              <td>{sensor.kind}</td>
              <td>{sensor.type}</td>
              <td>{sensor.name}</td>
              <td>{sensor.code}</td>
              {/* <td>{JSON.stringify(sensor.childrenList)}</td> */}
              <td style={{ textAlign: 'left' }}>
                {checkNullArray(sensor?.childrenList, []).map((child, childIndex) => {
                  return (<p key={childIndex + '_child'}>{child.id} {child.type} {child.name} {child.code}</p>)
                })}
              </td>
              {/* <td>
                <ul>
                  {sensor.sensorSettingList.map((setting) => (<li key={setting.id}>{JSON.stringify(setting)}</li>))}
                </ul>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    }
    />
  );
};

export default SensorResponse;