import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import moment from 'moment';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { manHour } from 'api/apis/manHour/manHour';

import { DateFormat } from 'components/format/DateFormat';
import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

import CreateManHour from 'pages/manHour/crud/CreateManHour';
import UpdateManHour from 'pages/manHour/crud/UpdateManHour';
import ShowManHourList from 'pages/manHour/modal/ShowManHourList';

const Section = styled.main`
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 66px calc(100% - 66px);

  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
  }
`;
const SchedulerSection = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: grid;  
  height: 100%;
  justify-content: center;
  overflow: hidden;
  padding: 20px 40px;
  width: 100%;

  &>div {
    box-sizing: border-box;
    overflow: scroll;
    width: 95svw;
  }

  .fc .fc-toolbar.fc-header-toolbar {box-sizing: border-box; padding: 16px 10px; margin: unset;}
  .fc .fc-button {padding: 8px 16px;}
  .fc .fc-button, .fc .fc-button-group, .fc .fc-button:disabled {font-size: 0.9em;}
  .fc .fc-view-harness-active > .fc-view,
  .gBRwOh .fc .fc-toolbar.fc-header-toolbar,
  .fc .fc-media-screen .fc-direction-ltr .fc-theme-standard {background-color: var(--white);}
  .fc-today-button:disabled {opacity: unset;}
  .fc-daygrid-block-event .fc-event-time, .fc-daygrid-block-event .fc-event-title {
    overflow: unset;
    max-width: 100%;
    white-space: pre-line;
    word-break: break-all;
  }
  .fc-daygrid-dot-event {align-items: flex-start;
    .fc-daygrid-event-dot {transform: translateY(7px);}
  }
  .fc-daygrid-dot-event .fc-event-title {
    font-weight: 400;
    line-height: 17px;
    overflow: unset;
    max-width: 89%;
    white-space: pre-line;
    word-break: break-all;
  }

  button[title="공수추가"] {
    background-color: var(--MainBlue); border: none;
    &:hover {background-color: var(--MainBlue); opacity: 0.6;}
  }
  button[title="수정 활성화"] {
    background-color: var(--MainYellow); border: none;
    &:hover {background-color: var(--MainYellow); opacity: 0.6;}
  }
  button[title="수정 비활성화"] {
    background-color: var(--ThirdYellow); border: none;
    &:hover {background-color: var(--ThirdYellow); opacity: 0.6;}
  }
  button[title="삭제 활성화"] {
    background-color: var(--SeconRed); border: none;
    &:hover {background-color: var(--SeconRed); opacity: 0.6;}
  }
  button[title="삭제 비활성화"] {
    background-color: var(--ThirdRed); border: none;
    &:hover {background-color: var(--ThirdRed); opacity: 0.6;}
  }
`;

const GSManHourManagement = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const fullCalendar = useRef();

  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_manHourDataList, setManHourDataList] = useState([]);
  const [_manHourList, setManHourList] = useState([]);
  const [_dataUpdateStatus, setDataUpdateStatus] = useState(true);

  const [_dayOption, setDayOption] = useState('month');
  const [_date, setDate] = useState();
  const [_worker, setWorker] = useState('');

  const [_modalStatus, setModalStatus] = useState(false);
  const [_createStatus, setCreateStatus] = useState(false);
  const [_updateStatus, setUpdateStatus] = useState(false);
  const [_deleteStatus, setDeleteStatus] = useState(false);

  const [_modalContent, setModalContent] = useState({});

  /* ========================================================================= #3*/
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('414') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => { }, [_dataUpdateStatus, _date, _manHourList])

  /* ========================================================================= #4*/
  const getManHourList = async (fetchInfo, successCallback) => {
    const startDate = moment(fetchInfo.startStr).format('YYYY-MM-DD');
    const endDate = moment(fetchInfo.endStr).format('YYYY-MM-DD');

    if (!_dataUpdateStatus) return successCallback(_manHourList);
    setDataUpdateStatus(() => { return false; });

    const BodyToPost = { companyId: userReducer.company.companyId };
    BodyToPost.startDate = startDate;
    BodyToPost.endDate = endDate;
    BodyToPost.workerName = _worker;

    const response = await manHour.searchWorkingTime(BodyToPost);
    console.log('manHour.searchWorkingTime : ', response);
    if (checkNullArray(response?.data, false)) {
      const result = [];
      response?.data.forEach(data => {
        const resultData = {
          id: data.id,
          title: `
${data.worker?.name} (${data.time})
${data.note}
${checkNullArray(data.workingTimeWorkOrderLogs, false) ? '작업[' + checkNullArray(data.workingTimeWorkOrderLogs, []).length + ']' : ''} ${checkNullArray(data.workingTimeProcessLogs, false) ? '공정[' + checkNullArray(data.workingTimeProcessLogs, []).length + ']' : ''}
          `,
          start: DateFormat(checkEmptyNull(data.date, '')),
          color: '#' + Math.floor(Math.random() * 16777215).toString(16),
        }
        result.push(resultData);
      })
      if (checkNullArray(result, false)) {
        successCallback(result);
        setManHourList(() => { return result });
        setManHourDataList(() => { return checkNullArray(response?.data, []) });
      }
    }
  }

  /* ========================================================================= #5*/
  const actSearch = (e) => {
    e.preventDefault();
    const date = moment(_date).startOf('day').format('YYYY-MM-DD');
    console.log('date : ', date);
    setDataUpdateStatus(() => { return true; });
    const fullCalendarApi = fullCalendar.current.getApi();
    fullCalendarApi.gotoDate(date);
  }
  const actReset = (e) => {
    e.preventDefault();
    setWorker(() => { return ''; });
    setDataUpdateStatus(() => { return true; });
    const fullCalendarApi = fullCalendar.current.getApi();
    fullCalendarApi.gotoDate(moment().format('YYYY-MM-DD'));
  }

  const actShow = (info) => {
    const workingTimeId = info.event.id;
    const modalContent = _manHourDataList.find((item) => {
      if (item.id === Number(workingTimeId)) return true;
      else return false;
    });
    if (modalContent === undefined) return;
    setModalContent(() => { return modalContent; });
    setTimeout(setModalStatus(true), 1000);
  }
  const actUpdate = (info) => {
    const workingTimeId = info.event.id;
    const modalContent = _manHourDataList.find((item) => {
      if (item.id === Number(workingTimeId)) return true;
      else return false;
    });
    if (modalContent === undefined) return;
    setModalContent(() => { return modalContent; });
    setTimeout(setUpdateStatus(true), 1000);
  }
  const actDelete = async (info) => {
    const workingTimeId = info.event.id;
    const confirmText = `
${info.event.title}      
공수를 삭제하시겠습니까?
    `;
    if (window.confirm(confirmText)) {
      await manHour.deleteWorkingTime(workingTimeId).then((response) => {
        if (response === undefined) return;
        console.log('manHour.deleteWorkingTime : ', response);
        alert('공수를 삭제 했습니다.');
        setDataUpdateStatus(() => { return true; });
      });
    }
  }

  /* ========================================================================= #6 */
  window.oncontextmenu = () => { return false };

  return (
    <Grid2Body contents={
      <>
        <Section className="Main">
          <NavBar
            title={
              <div className='Title'>
                <NavTitle menuCode={'414'} />
                <Link to={'/production/manHour/result'} style={{color: '#ccc'}}>공수실적</Link>
              </div>
            }
            buttons={
              <>
                <div className='SearchSection'>
                  <div className='ContentCBox'>
                    <select
                      className='SearchOption'
                      name='dayOptions'
                      value={_dayOption}
                      onChange={e => { e.preventDefault(); setDayOption(e.target.value) }}
                    >
                      {[
                        { name: '월별', value: 'month' },
                        { name: '주별', value: 'week' },
                      ].map((item, index) => {
                        return (<option key={index + '_dayOptions'} value={item.value}>{item.name}</option>)
                      })}
                    </select>

                    <input type={_dayOption} className='SelectDate' value={_date} onChange={(e) => { e.preventDefault(); setDate(e.target.value) }} />
                  </div>

                  <div className='ContentCBox'>
                    <input type='text' className='SearchBar' placeholder='작업자' value={_worker} onChange={(e) => { e.preventDefault(); setWorker(e.target.value) }} />
                  </div>

                  <button className='btn-search' onClick={actSearch}>검색</button>
                  <button className='btn-reset' onClick={actReset}>초기화</button>
                </div>
              </>
            }
            nav={''}
          />

          <SchedulerSection>
            <FullCalendar
              ref={fullCalendar}
              plugins={[dayGridPlugin]}
              dayHeaders={true}
              initialView="dayGridMonth"
              locale="ko"
              firstDay="1"
              buttonText={{ day: '일별', week: '주별', month: '월별', today: '오늘' }}
              events={(fetchInfo, successCallback, failureCallback) => { getManHourList(fetchInfo, successCallback, failureCallback); }}
              displayEventTime={false}
              customButtons={{
                create: { text: '공수추가', click: function () { setCreateStatus(true); setUpdateStatus(false); setDeleteStatus(false); } },
                activeUpdate: { text: '수정 활성화', click: function () { setUpdateStatus(true); setDeleteStatus(false); } },
                disableUpdate: { text: '수정 비활성화', click: function () { setUpdateStatus(false); } },
                activeDelete: { text: '삭제 활성화', click: function () { setUpdateStatus(false); setDeleteStatus(true); } },
                disableDelete: { text: '삭제 비활성화', click: function () { setDeleteStatus(false); } },
                customPrev: { text: '<', click: function () { setDataUpdateStatus(() => { return true; }); fullCalendar.current.getApi().prev(); } },
                customToday: { text: '오늘', click: function () { setDataUpdateStatus(() => { return true; }); fullCalendar.current.getApi().today(); } },
                customNext: { text: '>', click: function () { setDataUpdateStatus(() => { return true; }); fullCalendar.current.getApi().next(); } }
              }}
              headerToolbar={{
                left: 'dayGridWeek dayGridMonth',
                center: 'title',
                right: (() => {
                  if (_authority.indexOf('414-2') !== -1) {
                    if (_updateStatus && _deleteStatus) return 'create disableUpdate disableDelete customPrev,customToday,customNext';
                    else if (!_updateStatus && _deleteStatus) return 'create activeUpdate disableDelete customPrev,customToday,customNext';
                    else if (_updateStatus && !_deleteStatus) return 'create disableUpdate activeDelete customPrev,customToday,customNext';
                    else return 'create activeUpdate activeDelete customPrev,customToday,customNext';
                  } else {
                    return 'customPrev,customToday,customNext';
                  }
                })()
              }}
              eventClick={(e) => {
                if (_updateStatus) actUpdate(e);
                else if (_deleteStatus) actDelete(e);
                else actShow(e);
              }} // 이벤트 클릭 이벤트
              eventDisplay="list-item" // 이벤트 표기
              eventBackgroundColor="var(--SeconYellow)" // 오늘 날짜 색상
              height="100%" // 캘린더 전체 높이
            />
          </SchedulerSection>
        </Section>

        {/* 일정 이벤트 */}
        {_modalStatus === true ? (
          <ShowManHourList
            content={_modalContent}
            open={_modalStatus}
            close={() => { setModalStatus(false); setModalContent(() => { return {} }); }}
          />
        ) : null}
        {_createStatus === true ? (
          <CreateManHour
            open={_createStatus}
            close={(date) => {
              setCreateStatus(false);
              if (!checkEmptyNull(date, false) || typeof date !== 'string') return;
              const fullCalendarApi = fullCalendar.current.getApi();
              fullCalendarApi.gotoDate(date);
              setDataUpdateStatus(() => { return true });
            }}
          />
        ) : null}
        {_updateStatus === true && checkNullObject(_modalContent, false) ? (
          <UpdateManHour
            content={_modalContent}
            open={_updateStatus}
            close={(date) => {
              setUpdateStatus(false);
              setModalContent(() => { return {} });
              if (!checkEmptyNull(date, false) || typeof date !== 'string') return;
              const fullCalendarApi = fullCalendar.current.getApi();
              fullCalendarApi.gotoDate(date);
              setDataUpdateStatus(() => { return true });
            }}
          />
        ) : null}
      </>
    }
    />
  );
};

export default GSManHourManagement;
