export const failedProductionOrderActions_setWorkOrderType = (workOrderType) => {
  return {
    type: 'SETFAILEDPRODUCTIONORDERWORKORDERTYPE',
    payload: workOrderType,
  };
};

export const failedProductionOrderActions_setWorkOrderStatus = (workOrderStatus) => {
  return {
    type: 'SETFAILEDPRODUCTIONORDERWORKORDERSTATUS',
    payload: workOrderStatus,
  };
};

export const failedProductionOrderActions_setStartDate = (startDate) => {
  return {
    type: 'SETFAILEDPRODUCTIONORDERSTARTDATE',
    payload: startDate,
  };
};

export const failedProductionOrderActions_setEndDate = (endDate) => {
  return {
    type: 'SETFAILEDPRODUCTIONORDERENDDATE',
    payload: endDate,
  };
};

export const failedProductionOrderActions_setSearchOption = (searchData) => {
  return {
    type: 'SETFAILEDPRODUCTIONORDERSEARCHOPTION',
    payload: searchData,
  };
};
export const failedProductionOrderActions_setSearchText = (searchData) => {
  return {
    type: 'SETFAILEDPRODUCTIONORDERSEARCHTEXT',
    payload: searchData,
  };
};
export const failedProductionOrderActions_setSearchData = (searchData) => {
  return {
    type: 'SETFAILEDPRODUCTIONORDERSEARCHDATA',
    payload: searchData,
  };
};

export const failedProductionOrderActions_setPageNumber = (pageNumber) => {
  return {
    type: 'SETFAILEDPRODUCTIONORDERPAGENUMBER',
    payload: pageNumber,
  };
};

export const failedProductionOrderActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETFAILEDPRODUCTIONORDERTOTALSIZE',
    payload: totalSize,
  };
};

export const failedProductionOrderActions_setIsPushedSearchButton = (isPushedSearchButton) => {
  return {
    type: 'SETFAILEDPRODUCTIONORDERISPUSHEDSEARCHBUTTON',
    payload: isPushedSearchButton,
  };
};

export const failedProductionOrderActions_setReset = () => {
  return {
    type: 'SETFAILEDPRODUCTIONORDERRESET',
  };
};