import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { manHour } from 'api/apis/manHour/manHour';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import { StatusName } from 'components/status/StatusName';

import { DetailBtn, GSManHourModalComponent } from './GSDetailWorkOrderLog.style';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

const DetailWorkOrderLog = ({
  searchContent,
  update,
  open,
  close,
}) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_workingTimes, setWorkingTimes] = useState([]);
  const [_workOrderLog, setWorkOrderLog] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    if(checkEmptyNull(searchContent.workingTimeId, false)) getWorkingTimeData(searchContent.workingTimeId); 
    else searchWorkingTimeData();

    return () => { }
  }, [])
  useEffect(() => {}, [_workingTimes, _workOrderLog])

  /* ====================================================================== #4 */
  const getWorkingTimeData = async(workingTimeId) => {
    await manHour.getWorkingTime(workingTimeId).then(response => {
      if (response === undefined) return;
      setWorkingTimes(() => { return response.data});
      getWorkOrderLog(response.data.workOrderLog.workOrderLogId);
    })
  }
  const getWorkOrderLog = async (id) => {
    await workOrderLogApi.getWorkOrderLog(id).then(response => {
      if (response === undefined) return;
      setWorkOrderLog(() => { return response.data });
    })
  }
  const searchWorkingTimeData = async() => {
    let searchData = `companyId=${userReducer.company.companyId}&startDate=${searchContent?.searchDate}&endDate=${searchContent?.searchDate}`;
    const response = await manHour.groupingWork(searchData);
    const workingTimeData = checkNullArray(response?.data, []).find(workingTime => workingTime.workOrderLog.workOrderLogId === Number(searchContent.workOrderLogId));
    setWorkingTimes(() => {
      const userGroups = workingTimeData?.userGroups;
      const returnData = [];
      checkNullArray(userGroups, []).forEach((userGroup) => {
        checkNullArray(userGroup.workingTimes, []).forEach((workingTime) => {
          returnData.push({
            user: userGroup.worker,
            ...workingTime,
          });
        })
      });
      console.log('returnData : ', returnData);
      return returnData;
    });
    setWorkOrderLog(() => {return workingTimeData?.workOrderLog});
  };

  const handlerMin = (min) => {
    const days = Math.floor(min / 60 / 24)
    const hours = Math.floor((min - (days * 60 * 24)) / 60);
    const mins = min - (days * 60 * 24) - (hours * 60);

    let returnData = '';
    if (days > 0) returnData += days + '일 ';
    if (hours > 0) returnData += hours + '시간 ';
    if (mins > 0) returnData += mins + '분';

    return returnData;
  };
  /* ====================================================================== #5 */
  const actDelete = async(workingTime, index) => {
    const workingTimeId = workingTime.id;
    const confirmText = `
작업자 : ${workingTime.user.name}
작업일자 : ${checkEmptyNull(workingTime.value, false) && workingTime.value !== 0 && workingTime.value !== '0' ? moment(workingTime.occurrenceDate).format('YYYY-MM-DD') 
: `${moment(workingTime.startTime).format('YYYY-MM-DD HH:mm')} - ${moment(workingTime.endTime).format('YYYY-MM-DD HH:mm')}`}
공수 : ${checkEmptyNull(workingTime.value, false) && workingTime.value !== 0 && workingTime.value !== '0' ? workingTime.value : handlerMin(checkEmptyNull(workingTime.diffMinutes, 0))}
공수를 삭제하시겠습니까?
    `;

    if (window.confirm(confirmText)) {
      await manHour.deleteWorkingTime(workingTimeId).then((response) => {
        if (response === undefined) return;
        console.log('manHour.deleteWorkingTime : ', response);
        alert('공수를 삭제 했습니다.');
        setWorkingTimes(prev => {
          const returnData = [...prev];
          returnData.splice(index, 1);
          return returnData;
        })
        update();
      });
    }
  }
  const actOneDelete = async() => {
    const confirmText = `
작업일자 : ${checkEmptyNull(_workingTimes.value, false) && _workingTimes.value !== 0 && _workingTimes.value !== '0' ? moment(_workingTimes.occurrenceDate).format('YYYY-MM-DD') 
: `${moment(_workingTimes.startTime).format('YYYY-MM-DD HH:mm')} - ${moment(_workingTimes.endTime).format('YYYY-MM-DD HH:mm')}`}
공수 : ${checkEmptyNull(_workingTimes.value, false) && _workingTimes.value !== 0 && _workingTimes.value !== '0' ? _workingTimes.value : handlerMin(checkEmptyNull(_workingTimes.diffMinutes, 0))}
공수를 삭제하시겠습니까?
    `;

    if (window.confirm(confirmText)) {
      await manHour.deleteWorkingTime(searchContent.workingTimeId).then((response) => {
        if (response === undefined) return;
        console.log('manHour.deleteWorkingTime : ', response);
        alert('공수를 삭제 했습니다.');
        console.log(_workOrderLog);
        update(_workOrderLog);
      });
    }
  }

  /* ====================================================================== #6 */

  return (
    <>
      {open === true && (
        <GSManHourModalComponent>
          <section>
            <header>
              <h3>작업 상세보기</h3>
              <div className='btn-close' onClick={close}><CloseButtonIcon /></div>
            </header>

            <section>
              <section>
                {checkNullObject(_workOrderLog, false) ? (
                  <section className='main-section' key={'main-section'}>
                    <div className='main-info'>
                      {checkEmptyNull(searchContent.workingTimeId, false) ? (
                        <>
                          <div className='info-box'>
                            {checkEmptyNull(_workingTimes.value, false) && _workingTimes.value !== 0 && _workingTimes.value !== '0' ? (
                              <>
                                <p className='note'><span>작업일자 : </span><span>{moment(_workingTimes.occurrenceDate).format('YYYY-MM-DD')}</span></p>
                                <p className='note'><span>공수 : </span><span>{_workingTimes.value}</span></p>
                              </>
                            ) : (
                              <>
                                <p className='date'><span>작업일자 : </span>{moment(_workingTimes.startTime).format('YYYY-MM-DD HH:mm')} - {moment(_workingTimes.endTime).format('YYYY-MM-DD HH:mm')}</p>
                                <p><span>공수시간 : </span>{handlerMin(checkEmptyNull(_workingTimes.diffMinutes, 0))}</p>
                              </>
                            )}
                            <p className='note'><span>작업내용 : </span><span>{_workingTimes.note}</span></p>
                            <DetailBtn title='삭제' onClick={(e) => {e.preventDefault(); actOneDelete();}}><div></div></DetailBtn>
                          </div>
                        </>
                      ) : null}

                      <div className='info-box'>
                        {[
                          { name: '작업상태', value: _workOrderLog.workOrderStatus },
                          { name: '거래처', value: _workOrderLog.accountName },
                          { name: '작업코드', value: _workOrderLog.workOrderCode },
                          { name: '공사명', value: _workOrderLog.workOrderName },
                          { name: '현장명', value: _workOrderLog.workPlace },
                          { name: '현장주소', value: _workOrderLog.detailedWorkPlace },
                          { name: '공사기간', value: `${DateFormat(checkEmptyNull(_workOrderLog.workOrderScheduledStartDate, ''))} ~ ${DateFormat(checkEmptyNull(_workOrderLog.workOrderScheduledEndDate, ''))}` },
                        ].map((data, index) => {
                          return (<p key={index + '_workOrderLog'}><span>{data.name} : </span>{data.name === '작업상태' ? StatusName(data.value) : data.value}</p>);
                        })}
                      </div>

                      {!checkEmptyNull(searchContent.workingTimeId, false) ? checkNullArray(_workingTimes, []).map((workingTime, index) => {
                        return (
                          <>
                            <div className='info-box' key={index + '_workingTime'}>
                              <p><span>작업자 : </span><span>{workingTime.user.name}</span></p>
                              {checkEmptyNull(workingTime.value, false) && workingTime.value !== 0 && workingTime.value !== '0' ? (
                                <>
                                  <p className='note'><span>작업일자 : </span><span>{moment(workingTime.occurrenceDate).format('YYYY-MM-DD')}</span></p>
                                  <p className='note'><span>공수 : </span><span>{workingTime.value}</span></p>
                                </>
                              ) : (
                                <>
                                  <p className='date'><span>작업일자 : </span>{moment(workingTime.startTime).format('YYYY-MM-DD HH:mm')} - {moment(workingTime.endTime).format('YYYY-MM-DD HH:mm')}</p>
                                  <p><span>공수시간 : </span>{handlerMin(checkEmptyNull(workingTime.diffMinutes, 0))}</p>
                                </>
                              )}
                              <p className='note'><span>작업내용 : </span><span>{workingTime.note}</span></p>
                              <DetailBtn title='삭제' onClick={(e) => {e.preventDefault(); actDelete(workingTime, index);}}><div></div></DetailBtn>
                            </div>
                          </>
                        )
                      }) : null}
                    </div>
                  </section>
                ) : null}
              </section>
            </section>
          </section>
        </GSManHourModalComponent>
      )}
    </>
  );
};

export default DetailWorkOrderLog;
