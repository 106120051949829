import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { companyPageCode } from 'api/apis/operator/companyPageCode';

import { checkEmptyNull, checkNullObject } from 'components/checkValues/checkValues';

import NavBar from 'components/nav/NavBar';
import OperatorBody from 'components/layouts/body/OperatorBody';
import TableSection from 'components/layouts/table/TableSection';

const MainSection = styled.main`
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 60px calc(100% - 60px);
  & .TableSection {
    tr.top {background-color: var(--MainBlue); color: var(--white);}
    td, th {
      min-width: unset;
      &.actSection {
        background: none;
        color: var(--white);
        &.update {background-color: var(--MainYellow);}
        &.delete {background-color: var(--MainRed);}
      }
    }
    td {cursor: pointer;}
  }
`;

const PageCodeManagement = () => {
  /* ========================================================================= #1 */
  const { pageReducer, userReducer } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_companyPageCodeList, setCompanyPageCodeList] = useState([]);

  /* ========================================================================= #3 */
  useEffect(() => {
    if (userReducer.user.role !== 'ROLE_MASTER') return navigate('/', { replace: true });
    dispatch(pageReducer_getCurrentPage(window.location.pathname));
    getCompanyPageCode();

    setOnload('loaded');
    return () => { };
  }, []);

  /* ========================================================================= #4 */
  const getCompanyPageCode = async () => {
    await companyPageCode.getCompanyPageCodes().then(response => {
      if (!checkEmptyNull(response, false)) return;
      console.log('companyPageCodeApi.getCompanyPageCodes : ', response);
      setCompanyPageCodeList(() => { return response.data.content; })
    })
  };

  /* ========================================================================= #5 */
  const actUpdate = (companyPageCode) => {
    const checkedCompany = checkNullObject(companyPageCode, false);
    if (!checkedCompany) return;

    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({ updateContent: JSON.stringify(checkedCompany) })}`,
      replace: true,
    })
  };

  const actDelete = (companyPageCode) => {
    const checkedCompany = checkNullObject(companyPageCode, false);
    if (!checkedCompany) return;

    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({ deleteContent: JSON.stringify(checkedCompany) })}`,
      replace: true,
    })
  };

  /* ========================================================================= #6 */

  return (
    <OperatorBody contents={
      <>
        <MainSection className="Main">
          <NavBar
            title={'페이지코드'}
            buttons={
              <>
                <button className='btn-add' onClick={() => { navigate(pageReducer.currentPage + '/create', { replace: true }) }}>추가</button>
              </>
            }
            nav={''}
          />

          <TableSection content={
            <table>
              <colgroup>
                <col width={'150px'}></col>
                <col></col>
                <col></col>
                <col width={'160px'}></col>
                <col width={'160px'}></col>
                <col width={'80px'}></col>
                <col width={'80px'}></col>
              </colgroup>
              <thead>
                <tr>
                  <th>pageCode</th>
                  <th>defaultPageName</th>
                  <th>defaultPageUrl</th>
                  <th>defaultIndexX</th>
                  <th>defaultIndexY</th>
                  <th className='actSection update'></th>
                  <th className='actSection delete'></th>
                </tr>
              </thead>
              <tbody>
                {(() => {
                  const returnData = [];

                  const sortList = _companyPageCodeList.sort((a, b) => {
                    if (a.defaultIndexX > b.defaultIndexX) return 1;
                    if (a.defaultIndexX < b.defaultIndexX) return -1;
                    if (a.defaultIndexY > b.defaultIndexY) return 1;
                    if (a.defaultIndexY < b.defaultIndexY) return -1;
                    else return 0;
                  });
                  sortList.forEach((companyPageCode, index) => {
                    returnData.push(<tr key={index + '_companyPageCode'} className={companyPageCode.defaultIndexY === 0 ? 'top' : ''}>
                      <td>{companyPageCode.pageCode}</td>
                      <td>{companyPageCode.defaultPageName}</td>
                      <td style={{ textAlign: 'left' }}>{companyPageCode.defaultPageUrl}</td>
                      <td>{companyPageCode.defaultIndexX}</td>
                      <td>{companyPageCode.defaultIndexY}</td>
                      <td className='actSection update' onClick={(e) => { e.preventDefault(); actUpdate(companyPageCode); }}>수정</td>
                      <td className='actSection delete' onClick={(e) => { e.preventDefault(); actDelete(companyPageCode); }}>삭제</td>
                    </tr>)
                  })

                  return returnData;
                })()}
              </tbody>
            </table>
          }
          ></TableSection>
        </MainSection>
      </>
    }
    />
  );
};

export default PageCodeManagement;
