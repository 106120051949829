import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { processTypeApi } from 'api/apis/processTypeApi';
import { processApi } from 'api/apis/processApi';

import { bookmark } from 'api/apis/bookmark/bookmark';
import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

import Bookmark from 'components/icons/Bookmark';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import CommonCopyProcessModal from 'pages/process/modal/create/CommonCopyProcessModal';
import CommonCreateProcessModal from 'pages/process/modal/create/CommonCreateProcessModal';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import ProcessRow from 'components/row/ProcessRow';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import ShowFileList from 'pages/file/modal/ShowFileList';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 40px 50px;
  height: 85%;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const ModalHeaderButtons = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 55px;
`;
const ModalHeaderButton = styled.button``;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;

    td.blankCell {background-color: var(--Bg); opacity: 0.8;}
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const SelectProcesses = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const ProcessCheckBox = useRef();

  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_processList, setProcessList] = useState([]);

  const [_processTypes, setProcessTypes] = useState([]);
  const [_processType, setProcessType] = useState('all');

  const [_checkedItem, setCheckedItem] = useState([]);

  const [_searchData, setSearchData] = useState({});

  const [_createModalStatus, setCreateModalStatus] = useState(false);

  const [_copyContent, setCopyContent] = useState({});
  const [_putCopyItems, setPutCopyItems] = useState([]);
  const [_makeCopyItems, setMakeCopyItems] = useState([]);
  const [_copyModalStatus, setCopyModalStatus] = useState(false);

  const [_pageNum, setPageNum] = useState(1);
  const [_pageCount, setPageCount] = useState(0);

  const [_fileListModalStatus, setFileListModalStatus] = useState(false);
  const [_eventcontent, setEventContent] = useState({});

  const [_bookmark, setBookmark] = useState(true);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('112') === true ||
      thisItem.authority.startsWith('109') === true,
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    getProcessTypes();
    getProcessList(0, 'all');

    setOnload('loaded');

    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getProcessList(_pageNum - 1, _processType);
      ProcessCheckBox.current.checked = false;
    }

    return () => { };
  }, [_pageNum]);

  useEffect(() => {
    if (_onload === 'loaded') {
      getProcessList(0, _processType);
      ProcessCheckBox.current.checked = false;
    }

    return () => { };
  }, [_bookmark]);

  useEffect(() => { }, [_checkedItem]);

  /* ====================================================================== #4 */
  const getProcessTypes = async () => {
    const BodyToPost = { companyId: userReducer.company.companyId };
    await processTypeApi.searchProcessType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('processTypeApi.searchProcessType : ', response);

      setProcessTypes(() => {
        return response.data;
      });
    });
  };

  const getProcessList = async (page, processType, clear) => {
    let paging = `?page=${page}&size=10`;
    if (_bookmark) paging += '&sort=bookMark,DESC&sort=id,DESC';
    else paging += '&sort=id,DESC';

    const BodyToPost = { companyId: userReducer.company.companyId };
    if (!clear && checkEmptyNull(processType, false) && processType !== 'all') {
      BodyToPost.processTypeId = processType;
    }
    if (!clear && checkEmptyNull(_searchData.processTypeName, false) && _searchData.processTypeName !== 'all') {
      BodyToPost.processTypeName = _searchData.processTypeName;
    }
    if (!clear && checkEmptyNull(_searchData.processCode, false)) {
      BodyToPost.processCode = _searchData.processCode;
    }
    if (!clear && checkEmptyNull(_searchData.processName, false)) {
      BodyToPost.processName = _searchData.processName;
    }
    console.log('getProcessList - BodyToPost : ', BodyToPost);

    await processApi.searchProcess(paging, BodyToPost).then((response) => {
      console.log('processApi.searchProcess : ', response);

      setProcessList(() => { return response.data.content; });
      setPageCount(() => { return response.data.totalElements; });
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (item, isChecked) => {
    const checkedItem = [..._checkedItem];
    const checkedIndex = checkedItem.findIndex((thisItem) => thisItem.processId === item.processId);
    if (checkedIndex !== -1) {
      if (!isChecked) checkedItem.splice(checkedIndex, 1);
    } else {
      if (isChecked) checkedItem.push(item);
    }
    console.log('checkedItem : ', checkedItem);
    setCheckedItem(() => { return checkedItem; });
  };

  const handleBookmark = async (process, index) => {
    console.log('process : ', process);
    await bookmark.processBookmark(process.processId).then(response => {
      if (response === undefined) return;
      console.log('bookmark.processBookmark : ', response);
      getProcessList(_pageNum - 1, _processType);
    })
  }

  /* 검색 */
  const actSearch = async () => {
    setPageNum(() => { return 1; });
    await getProcessList(0, _processType);
  };

  /* 초기화 */
  const actReset = async () => {
    setProcessType(() => { return 'all'; });
    setSearchData(() => { return {}; });
    setPageNum(() => { return 1; });
    await getProcessList(0, 'all', true);
  };

  const showFileList = (process) => {
    setEventContent(() => { return process; });
    setTimeout(setFileListModalStatus(true), 1000);
  };

  const actCopy = async () => {
    if (_checkedItem.length < 1) return;
    else if (_checkedItem.length > 1) return alert('다중 선택되어 있습니다.');

    const copyContent = { ..._checkedItem[0] }
    if (copyContent === undefined) return alert('해당 공정을 복사할 수 없습니다.');

    const putItems = copyContent.processElementList.filter((thisItem) => thisItem.processElementType === 'Put');
    const returnPutItems = putItems.map((thisItem) => {
      return { ...thisItem, ...thisItem.element };
    });
    const makeItems = copyContent.processElementList.filter((thisItem) => thisItem.processElementType === 'Make');
    const returnMakeItems = makeItems.map((thisItem) => {
      return { ...thisItem, ...thisItem.element };
    });

    setPutCopyItems(returnPutItems);
    setMakeCopyItems(returnMakeItems);
    setCopyContent(copyContent);
    setTimeout(setCopyModalStatus(true), 1000);
  };
  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>공정 선택</ModalTitle>
              <ModalHeaderButtons>
                <ModalHeaderButton className="formButton" onClick={actCopy}>공정 복사</ModalHeaderButton>
                <ModalHeaderButton className="formButton" onClick={() => { setCreateModalStatus(true) }}>공정 추가</ModalHeaderButton>
              </ModalHeaderButtons>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <NavBar
                title={
                  <div className="SearchSection">
                    <select
                      className="SelectType"
                      value={_processType}
                      onChange={(e) => {
                        setProcessType(() => { return e.target.value; });
                        setPageNum(() => { return 1; });
                        getProcessList(0, e.target.value);
                      }}
                    >
                      <option value="all">전체</option>
                      {_processTypes.map((thisItem) => {
                        return (<option key={thisItem.processTypeId + '_processType'} value={thisItem.processTypeId}>{thisItem.processTypeName}</option>);
                      })}
                    </select>

                    <div className="SearchSection">
                      {_processType === 'all' && (
                        <div className="ContentCBox">
                          <select className="SearchOption detail">
                            <option value="processTypeName">공정유형</option>
                          </select>
                          <input
                            className="SearchBar detail"
                            placeholder="Search..."
                            value={checkEmptyNull(_searchData.processTypeName, '')}
                            onInput={(e) => { setSearchData((prev) => { return { ...prev, processTypeName: e.target.value }; }); }}
                          />
                        </div>
                      )}

                      <div className="ContentCBox">
                        <select className="SearchOption detail">
                          <option value="processCode">공정코드</option>
                        </select>
                        <input
                          className="SearchBar detail"
                          placeholder="Search..."
                          value={checkEmptyNull(_searchData.processCode, '')}
                          onInput={(e) => { setSearchData((prev) => { return { ...prev, processCode: e.target.value }; }); }}
                        />
                      </div>

                      <div className="ContentCBox">
                        <select className="SearchOption detail">
                          <option value="processName">공정이름</option>
                        </select>
                        <input
                          className="SearchBar detail"
                          placeholder="Search..."
                          value={checkEmptyNull(_searchData.processName, '')}
                          onInput={(e) => { setSearchData((prev) => { return { ...prev, processName: e.target.value }; }); }}
                        />
                      </div>

                      <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                      <div className="ResetButton" onClick={actReset}>초기화</div>
                    </div>
                  </div>
                }
                nav={''}
              />

              <TableSection content={
                <table>
                  <thead>
                    <tr style={{ border: 'none' }}>
                      <th rowSpan={2} style={{ minWidth: '50px', width: '50px' }}>
                        <input
                          type="checkBox"
                          name="allElements"
                          ref={ProcessCheckBox}
                          onChange={(e) => {
                            const isChecked = e.target.checked;

                            if (isChecked) {
                              setCheckedItem((prev) => {
                                const prevData = [...prev];
                                const newData = _processList.map((thisItem) => {
                                  const thisFindIndex = prevData.findIndex((thisIndex) => thisIndex.processId === thisItem.processId);
                                  if (thisFindIndex !== -1) return null;
                                  else return thisItem;
                                });
                                const filterData = newData.filter((thisItem) => thisItem !== null);
                                return [...prevData, ...filterData];
                              });

                              e.target.checked = true;
                            } else {
                              setCheckedItem((prev) => {
                                const prevData = prev.map((thisItem) => {
                                  const thisFindIndex = _processList.findIndex((thisIndex) => thisIndex.processId === thisItem.processId);
                                  if (thisFindIndex !== -1) return null;
                                  else return thisItem;
                                });
                                const filterData = prevData.filter((thisItem) => thisItem !== null);
                                return filterData;
                              });

                              e.target.checked = false;
                            }
                          }}
                        />
                      </th>
                      <td rowSpan={2} style={{ minWidth: '50px', width: '50px' }}>
                        <Bookmark bookmark={_bookmark} clickEvent={() => { setBookmark(prev => { return !prev }); }} />
                      </td>
                      {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? (
                        <th rowSpan={2} style={{ minWidth: '60px', width: '60px' }}>파일</th>
                      ) : null}
                      <th rowSpan={2}>공정유형</th>
                      <th rowSpan={2}>공정코드</th>
                      <th rowSpan={2}>공정이름</th>
                      <th rowSpan={2}>작업위치</th>
                      <th rowSpan={2}>비고</th>
                      <th colSpan={3} style={{ backgroundColor: 'var(--ThirdBlue)' }}>공정완료제품</th>
                      <th colSpan={3} style={{ backgroundColor: 'var(--SeconYellow)' }}>투입 자재/반제품</th>
                    </tr>
                    <tr>
                      <th>물품이름</th>
                      <th>물품코드</th>
                      <th>생산량</th>
                      <th>물품이름</th>
                      <th>물품코드</th>
                      <th>투입량</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkNullArray(_processList, []).map((thisItem) => {
                      return (
                        <ProcessRow
                          key={thisItem.processId}
                          authority={_authority}
                          content={thisItem}
                          checkedItem={_checkedItem}
                          handleCheckedItem={handleCheckedItem}
                          handleBookmark={handleBookmark}
                          showFileList={showFileList}
                        />
                      );
                    })}
                  </tbody>
                </table>
              }
              ></TableSection>
            </ModalMain>

            <PagingComponent
              page={_pageNum}
              count={_pageCount}
              size={10}
              pageEvent={(page) => {
                console.log('page : ', page);
                setPageNum(() => { return page; });
              }}
            />

            <ModalFooter>
              <ModalButton onClick={() => { props.buttonEvent(_checkedItem); }}>{props.buttonTitle}</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}

      {_fileListModalStatus === true && checkEmptyNull(_eventcontent.processId, false) ? (
        <ShowFileList
          type={'process'}
          content={_eventcontent}
          open={_fileListModalStatus}
          close={() => { setFileListModalStatus(false); }}
        ></ShowFileList>
      ) : null}

      {_copyModalStatus === true && (
        <CommonCopyProcessModal
          copyContent={_copyContent}
          putItems={_putCopyItems}
          makeItems={_makeCopyItems}
          open={_copyModalStatus}
          close={() => {
            setCopyModalStatus(false);
            getProcessList(0, _processType);
          }}
        />
      )}

      {_createModalStatus === true && (
        <CommonCreateProcessModal
          open={_createModalStatus}
          close={() => {
            setCreateModalStatus(false);
            getProcessList(0, _processType);
          }}
        />
      )}
    </>
  );
};

export default SelectProcesses;
