import React from 'react';
import { useOutletContext } from 'react-router-dom';

import MaterialMonitoring from 'pages/monitoring/contents/element/MaterialMonitoring';
import HalfMonitoring from 'pages/monitoring/contents/element/HalfMonitoring';
import ProductMonitoring from 'pages/monitoring/contents/element/ProductMonitoring';

const MonitoringMaterial = () => {
  const {setTimeCounter} = useOutletContext();

  return (
    <div className='monitoringBody element'>
      <MaterialMonitoring total={false} setTimeCounter={setTimeCounter} />
      <HalfMonitoring total={false} setTimeCounter={setTimeCounter} />
      <ProductMonitoring total={false} setTimeCounter={setTimeCounter} />
    </div>
  );
};

export default MonitoringMaterial;