import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { shipmentInspectionItemType } from 'api/apis/shipmentInspection/shipmentInspectionItemType';
import { shipmentInspectionItem } from 'api/apis/shipmentInspection/shipmentInspectionItem';

import { checkEmptyNull } from 'components/checkValues/checkValues';

import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

const CreateShipmentInspectionItem = () => {
  /* ========================================================================= #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_shipmentInspectionItemTypes, setShipmentInspectionItemTypes] = useState([]);
  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    shipmentInspectionItemTypeId: '',
    content: '',
    criteria: '',
  });

  /* ========================================================================= #3 */
  useEffect(() => {
    getShipmentInspectionItemTypes();

    setOnload('loaded');
    return () => { };
  }, []);

  /* ========================================================================= #4 */
  const getShipmentInspectionItemTypes = async () => {
    await shipmentInspectionItemType.searchShipmentInspectionItemType({ companyId: userReducer.company.companyId }).then((response) => {
      if (response === undefined) return;
      console.log('shipmentInspectionItemType.searchShipmentInspectionItemType : ', response);
      setShipmentInspectionItemTypes(() => { return response.data; });
    })
  };

  /* ========================================================================= #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value }; });
  };

  const actCreate = async (e) => {
    e.preventDefault();
    if (!checkEmptyNull(_formData.shipmentInspectionItemTypeId, false)) return alert('점검 / 검사 항목을 선택해 주세요.');
    if (!checkEmptyNull(_formData.content, false)) return alert('점검 / 검사 내용을 입력해 주세요.');
    if (!checkEmptyNull(_formData.criteria, false)) return alert('판정 기준을 입력해 주세요.');

    const BodyToPost = { ..._formData };

    await shipmentInspectionItem.createShipmentInspectionItem(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('shipmentInspectionItem.createShipmentInspectionItem : ', response);
      alert('점검 / 검사를 추가했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    })
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <main className="Main">
        <NavBar title={<NavTitle menuCode={'113'} />} nav={''} />

        <Form
          title={<><NavTitle menuCode={'113'} /><br />추가</>}
          buttons={
            <>
              <button className="formButton" onClick={actCreate}>저장</button>
              <button className="formButton cancle"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(pageReducer.currentPage, { replace: true });
                }}
              >
                취소
              </button>
            </>
          }
          forms={
            <>
              <div className="formBox">
                <h4>점검 / 검사 항목</h4>
                <select
                  name="shipmentInspectionItemTypeId"
                  data-required="required"
                  defaultValue={_formData.shipmentInspectionItemTypeId}
                  onChange={handleInputEvent}
                >
                  <option value="">점검 / 검사 항목 선택</option>
                  {_shipmentInspectionItemTypes.map((shipmentInspectionItemType, index) => {
                    return (<option key={index + '_shipmentInspectionItemTypes'} value={shipmentInspectionItemType.shipmentInspectionItemTypeId}>{shipmentInspectionItemType.shipmentInspectionItemTypeName}</option>);
                  })}
                </select>
              </div>

              <div className="formBox">
                <h4>점검 / 검사 내용</h4>
                <textarea
                  name="content"
                  maxLength={500}
                  placeholder="* 점검 / 검사 내용..."
                  data-required="required"
                  value={_formData.content}
                  onInput={handleInputEvent}
                />
              </div>

              <div className="formBox">
                <h4>판정 기준</h4>
                <textarea
                  name="criteria"
                  maxLength={500}
                  placeholder="* 판정 기준..."
                  data-required="required"
                  value={_formData.criteria}
                  onInput={handleInputEvent}
                />
              </div>
            </>
          }
        />
      </main>
    }
    />
  );
};

export default CreateShipmentInspectionItem;
