import styled from "styled-components";

export const ClusterFormModalComponent = styled.div`
  background-color: var(--white);
  display: grid;
  gap: 50px;
  grid-template-rows: 100px calc(100% - 150px);
  height: 100%;
  justify-items: center;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 11;

  &>header {
    align-items: flex-end;
    display: flex;
    height: 100px;
    justify-content: space-between;
    max-width: 1240px;
    width: 100%;

    & h1 { // 타이틀
      font-size: 35px;
    }

    & .btn-close { // 닫기 버튼
      height: 60px;
      width: 30px;

      &:hover {background: unset;}

      &>svg {
        height: 30px;
        width: 30px;
      }
    }
  }

  &>div {
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
    max-width: 1240px;
    position: relative;
    width: 100%;    
    
    &>section {
      align-content: flex-start;
      display: grid;
      gap: 50px;
      grid-template-rows: calc(100% - 150px) 100px;
      height: 100%;
      /* overflow-y: auto; */
      overflow: hidden;
      width: 100%;
      
      
      & .section-form { // 입력 폼 섹션
        align-content: flex-start;
        /* border: 1px solid #C6C6C6; */
        border-radius: 12px;
        box-sizing: border-box;
        gap: 20px;
        display: grid;
        /* height: fit-content; */
        height: 100%;
        overflow-y: auto;
        /* padding: 50px; */
        padding-block: 30px;

        &>h2 { // 폼 타이틀
          padding-block-end: 10px;
        }

        & .form-content { // 폼 컨텐츠
          align-content: flex-start;
          display: grid;
          gap: 10px;

          & h4 { // 인풋 이름
            font-weight: 400;
          }
          
          & input, & select, & textarea { // 텍스트 인풋 스타일
            background-color: var(--white);
            border: 1px solid #717171;
            border-radius: 8px;
            padding: 16px;
            max-width: 60%;
            &:focus {border: 2px solid #246BEB;}
          }
          & textarea {
            height: 400px;
            max-width: 90%;
            resize: none;
          }

          &.term>div{
            align-items: center;
            display: flex;
            gap: 15px;
            justify-content: flex-start;
            /* position: relative; */

            & input[type="date"], & select {
              height: 60px;
              width: 200px;
            }
          }
        }
      }
      & .section-action { // 액션 버튼 섹션
        align-items: flex-start;
        background-color: var(--white);
        bottom: 0;
        box-sizing: border-box;
        display: flex;
        height: 100px;
        justify-content: space-between;
        padding-block-start: 10px;
        position: sticky;
        width: 100%;

        & button {
          background-color: #246BEB;
          border-radius: 8px;
          box-sizing: border-box;
          color: var(--white);
          height: fit-content;
          padding: 10px 16px;
          width: fit-content;
          
          &.btn-save {width: 120px;}
          &.btn-delete {
            background-color: #EB003B;
            width: 120px;
          }
          &.btn-cancel {
            background-color: var(--white);
            border: 1px solid #1D1D1D;
            color: #1D1D1D;
            &:hover {background: unset;}
          }
        }
      }
    }
  }

  &.common { // 공동 구매, 상호 거래
    /* overflow: hidden; */

    & .section-form {
      height: 100%;
      overflow-y: auto;
      /* padding-block-end: 30px; */
    }
    &>div>section {
      gap: 10px;
      grid-template-rows: 40px calc(100% - 160px) 100px;
      overflow: hidden;

      &>nav { // 탭
        align-items: center;
        background-color: var(--white);
        display: flex;
        gap: 10px;
        height: fit-content;
        justify-content: flex-start;
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 1;
    
        & button {
          border-block-end: 1px solid #d8d8d8;
          font-size: 20px;
          font-weight: 600;
          height: 40px;
          width: 200px;

          &:hover {background: unset;}
          &.active {
            /* background-color: #edf1f5; */
            border-block-end: 3px solid #003675;
            color: #003675;
          }
        }
      }

      & .list { // 상품 목록
        align-content: flex-start;
        box-sizing: border-box;
        display: grid;
        /* grid-template-columns: repeat(auto-fit, minmax(45%, 1fr)); */
        gap: 20px;
        height: 100%;
        /* overflow-y: scroll; */
        /* padding: 30px; */
        width: 100%;

        &>button { // 상품 추가
          border-radius: 50%;
          height: 50px;
          position: absolute;
          right: 50px;
          top: 80px;
          width: 50px;
          &>svg {
            height: 50px;
            width: 50px;
          }
          &>p {
            bottom: 0px;
            left: -80px;
            position: absolute;
          }
        }
      }
    }
  }
`;

export const ItemBox = styled.div`
  box-sizing: border-box;
  border: 1px solid #C6C6C6;
  /* border-radius: 12px; */
  display: grid;
  gap: 10px;
  height: fit-content;
  padding: 40px;
  position: relative;
  width: 85%;

  & textarea {
    height: 200px !important;
  }

  & .item-header {
    position: absolute;
    top: 30px;
    right: 30px;

    &>button { // 상품 삭제
      height: 24px;
      width: 24px;
      &:hover {
        color: #ae0000;
      }
      &>svg {
        height: 100%;
        width: 100%;
      }
    }
  }

  & .item-price {
    line-height: 24px;
    /* position: absolute;
    top: 0px;
    right: 30px; */

    & p {
      &.discountInfo {
        color: #768695;
        font-size: 15px;
        & span:first-of-type {color: #ae0000;}
        & span:last-of-type {
          text-decoration: line-through;
        }
      }
      &.cal {
        color: #ae0000;
        font-size: 22px;
        font-weight: 600;
      }
      &.price {
        color: #003675;
        font-size: 22px;
        font-weight: 600;
      }
    }
  }

  & .detail {
    font-size: 15px;
    white-space: pre-wrap;
  }
`;