import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { peopleSharingAttribute, peopleSharingType } from 'api/apis/platform/cluster/cluster';

import { checkEmptyNull, checkNullObject } from 'components/checkValues/checkValues';

const SharedPeople = ({
  modalType, modalData,
  update,
  close
}) => {
  const location = useLocation();
  const clusterId = location.pathname.split('/platform/cluster')[0];
  const clusterPath = clusterId + '/platform/cluster';

  const { clusterReducer } = useSelector(state => state);
  const { user } = clusterReducer;

  const [_sharingTypeLsit, setSharingTypeList] = useState([]); // 유형 리스트
  const [_sharingType, setSharingType] = useState(modalData.peopleSharingTypeId || ''); // 유형 선택
  const [_attributeList, setAttributeList] = useState([]); // 속성 리스트
  const [_attributeValueList, setAttributeValueList] = useState([]); // 속성값 리스트

  const [_body, setBody] = useState({ ...modalData });

  useEffect(() => {
    getTypeList();
    return () => { }
  }, []);
  useEffect(() => {
    if (checkEmptyNull(_sharingType, false)) {
      getAttributeList(_sharingType);
    }
  }, [_sharingType]);
  useEffect(() => { }, [_body, _attributeList]);

  const getTypeList = async () => { // 유형 불러오기
    const id = Number(clusterId.replace('/', ''));
    const body = { clusterId: id };
    await peopleSharingType.search('', body).then(response => {
      if (response === undefined) return;
      setSharingTypeList(() => { return response.data });
    })
  };
  const getAttributeList = async (sharingType) => { // 속성 불러오기
    const id = Number(clusterId.replace('/', ''));
    const body = { clusterId: id };
    if (checkEmptyNull(sharingType, false)) body.peopleSharingTypeId = Number(sharingType);
    await peopleSharingAttribute.search('', body).then(response => {
      if (response === undefined) return;
      setAttributeList(() => { return response.data });

      const attributeValueList = response.data.map(attr => {
        const returnData = {
          peopleSharingAttributeId: attr.peopleSharingAttributeId,
          peopleSharingAttributeName: attr.peopleSharingAttributeName,
          value: '',
        };
        const prevAttrData = checkNullObject(modalData, false) &&
          modalData.peopleSharingAttributeValueList.find(item => item.peopleSharingAttributeId === attr.peopleSharingAttributeId);
        if (checkNullObject(prevAttrData, false)) { // 기존 속성값이 있는 경우
          returnData.peopleSharingAttributeValueId = prevAttrData.peopleSharingAttributeValueId;
          returnData.value = prevAttrData.value;
        }
        return returnData;
      });
      setAttributeValueList(() => { return attributeValueList });
    })
  };

  return (
    <section>
      <div className='section-form'>
        <h2>인력 정보</h2>

        <div className='form-content'>
          <h4>인력 유형</h4>
          <p>{_body.peopleSharingTypeName || ''}</p>
        </div>

        {/* <div className='form-content'>
          <h4>인력 이름</h4>
          <input
            type='text'
            readOnly
            defaultValue={_body.peopleSharingName || ''}
          />
        </div> */}
        {_attributeValueList.map((attr, attrIndex) => {
          return (
            <div key={attrIndex + '_form-content'} className='form-content'>
              <h4>{attr.peopleSharingAttributeName}</h4>
              <input
                type='text'
                readOnly
                defaultValue={attr.value || ''}
              />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default SharedPeople;