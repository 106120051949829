import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';

import { auth } from 'api/apis/auth/auth';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { checkEmail } from 'components/checkValues/sign';

const FindPwSection = styled.main`
  align-items: center;
  background-color: var(--MainNavy);
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;

  .sign {
    display: grid; justify-content: center; align-items: center; padding:10% 0; row-gap: 30px;
    &>p{color:#898989;margin:8px;}
    fieldset {display:grid;gap:18px;
      input, select{width:450px;padding:16px 24px;margin:0;border-radius:8px;border:1px solid #ddd;}
    }
    .submit {width:100%;height:60px; background-color: var(--MainBlue);color:var(--white); border-radius:10px;font-size:1.25rem;}
    .submit.google {background-color: var(--ThirdBlue); margin-top: 24px;}
    .signitem {display:flex;gap:16px;margin-top:24px; justify-content: space-between;
      button{color:var(--white);}
    }
    .googleBtn {background-color: var(--white); color: var(--gray-800);border:1px solid #ddd;}
  }

  .signup {
    padding:4% 0;
    fieldset{
      &>p{color:#898989;margin:8px;span{color:#000;}}
    }
  }

  .signTitle {
    align-items: center;
    column-gap: 20px;
    display: grid;
    grid-template-columns: 50px auto;
    /* margin: 0px auto; */
    width: fit-content;

    img {height: 50px; width: 50px;}
    h3 {color: var(--white); font-size: 30px;}
  }

  .resend {
    color: var(--white);
    
    &.time {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;

      & div {font-size: 0.8em;}
    }

    &.btn {
      align-items: center;
      display: flex;
      height: 50px;
      justify-content: center;
    }
  }
`;

const FindPw = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();

  /* ====================================================================== #2 */
  const [_companyList, setCompanyList] = useState([]);
  const [_companyId, setCompanyId] = useState('');

  const [_emailStatus, setEmailStatus] = useState(false);
  const [_email, setEmail] = useState('');

  const [_findStatus, setFindStatus] = useState(false);
  const [_findTime, setFindTime] = useState();
  const [_resendTime, setResendTime] = useState();

  /* ====================================================================== #3 */
  useEffect(() => {
    (async () => {
      await getCompanyList();
    })();
  }, []);

  const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
    useEffect(() => {
      const tick = () => {
        savedCallback.current();
      };
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };
  useInterval(() => {
    if (!_findStatus) return;
    const currentTime = moment(new Date());
    const calSeconds = moment.duration(currentTime.diff(_findTime)).asSeconds();
    setResendTime(() => {
      return calSeconds;
    });
  }, 1000);
  useEffect(() => {
    console.log('_resendTime : ', _resendTime);
    return () => { };
  }, [_resendTime]);

  /* ====================================================================== #4 */
  const getCompanyList = async () => {
    await auth.searchCompanies().then((response) => {
      if (response === undefined) return;
      console.log('auth.searchCompanies : ', response);

      setCompanyList(() => { return checkNullArray(response.data.content, response.data) });
    });
  };

  // 초 > 시:분:초
  const setSeconds = (seconds) => {
    const setHour = !isNaN(parseInt(seconds / 3600))
      ? parseInt(seconds / 3600)
      : 0;
    const hour = setHour < 10 ? '0' + setHour : setHour;
    const setMin = !isNaN(parseInt((seconds % 3600) / 60))
      ? parseInt((seconds % 3600) / 60)
      : 0;
    const min = setMin < 10 ? '0' + setMin : setMin;
    const setSec = !isNaN(parseInt(seconds % 60)) ? parseInt(seconds % 60) : 0;
    const sec = setSec < 10 ? '0' + setSec : setSec;

    return `${hour}:${min}:${sec}`;
  };

  /* ====================================================================== #5 */
  // 아이디 찾기
  const actFind = async () => {
    setFindStatus(() => { return true });
    setFindTime(() => { return new Date() });

    if (!checkEmptyNull(_companyId, false)) return alert('회사를 선택해주세요.')
    if (!checkEmptyNull(_email, false) || !checkEmail(_email)) return alert('이메일을 확인해주세요.');

    const BodyToPost = {
      companyId: Number(_companyId),
      email: _email,
    };
    console.log('actFind - BodyToPost : ', BodyToPost);

    await auth.findUserPassword(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('auth.findUserPassword : ', response);

      if (checkEmptyNull(response.data.userId, false)) {
        const alertText = `
          가입한 이메일 주소로 임시 비밀번호를 알려드립니다.
          로그인 후 비밀번호를 변경해주세요.
        `;
        alert(alertText);
      }
    });
  };

  /* ====================================================================== #6 */

  return (
    <FindPwSection>
      <section className='sign'>
        <div className='signTitle'>
          <img src={process.env.PUBLIC_URL + '/logo_Ifis.svg'} alt="아이피스 로고" />
          <h3>아이피스 비밀번호 찾기</h3>
        </div>
        <fieldset>
          <select required value={checkEmptyNull(_companyId)}
            onChange={(e) => {
              const companyId = Number(e.target.value);
              setCompanyId(() => { return companyId; });
            }}>
            <option value={""}>회사선택</option>
            {_companyList.map((company, index) => (
              <option key={index + '__companys'} value={company.companyId}>{company.companyName}</option>
            ))}
          </select>

          <input
            type="email"
            name="email"
            placeholder="이메일..."
            data-status={_emailStatus}
            value={_email}
            onInput={(e) => {
              const email = e.target.value;
              setEmailStatus(() => { return checkEmail(email) });
              setEmail(() => { return email });
            }}
          />
        </fieldset>

        {_findStatus ?
          <div className='resend time'>
            <div className='resend'>가입한 이메일로 임시 비밀번호를 전송했습니다.</div>
            <div className='resend'>{setSeconds(_resendTime)}</div>
          </div>
          : null}

        {(() => {
          if (!_findStatus) {
            return (
              <button type="submit" className='submit' onClick={actFind}>확인</button>
            );
          } else if (_findStatus && _resendTime <= 30) {
            return (
              <button type="submit" className='resend btn'>재전송</button>
            );
          } else if (_findStatus && _resendTime > 30) {
            return (
              <button type="submit" className='resend btn' onClick={() => { actFind(); }}>재전송</button>
            );
          } else {
            return null;
          }
        })()}

        <div className='signitem'>
          <button onClick={() => { navigate(`/sign/login`, { replace: true }); }} >로그인</button>
          <button onClick={() => { navigate(`/sign/email/find`, { replace: true }); }} >아이디 찾기</button>
          <button onClick={() => { navigate(`/companySelect`, { replace: true }); }} >회원가입</button>
        </div>
      </section>
    </FindPwSection>
  );
};

export default FindPw;
