import React, { Fragment } from 'react';
import TestApi from 'pages/test/TestApi';

const Test = () => {
  return (
    <Fragment>
      <TestApi />
    </Fragment>
  );
};

export default Test;