import React, { Fragment, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { checkEmptyNull, checkNull, checkNullArray } from 'components/checkValues/checkValues';

import { DateFormat } from 'components/format/DateFormat';
import { baseInformationIcon } from 'components/icons/src'

import CheckBox from 'components/checkbox/CheckBox';
import CommaNum from 'components/format/CommaNum';
import DOCButtonIcon from 'components/icons/DOCButtonIcon';
import GuideText from 'components/buttons/GuideText';

const QualityButton = styled.td`
  background-color: var(--Violet);
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  min-height: 100px;
  height: 100%;
  width: 100%;
`;

const FailedWorkRow = (props) => {
  /* ========================================================================= #1 */
  /* ========================================================================= #2 */
  const [_qualityData, setQualityData] = useState(props.content);
  const [_qualityTargetProduct, setQualityTargetProduct] = useState(checkNullArray(props.content.qualityElementList, []));
  const [_rowLength, setRowLength] = useState(_qualityTargetProduct.length);

  /* ========================================================================= #3 */
  useEffect(() => {
    setQualityData(props.content);

    const QualityTargetProduct = checkNullArray(props.content.qualityElementList, []);
    setQualityTargetProduct(() => { return QualityTargetProduct; });

    setRowLength(() => { return QualityTargetProduct.length; });
  }, [props]);

  /* ========================================================================= #4 */
  /* ========================================================================= #5 */
  /* ========================================================================= #6 */
  const returnItems = () => {
    let returnArray = [];
    for (let i = 1; i < _rowLength; i++) {
      returnArray.push(
        <tr key={props.index + '_' + i + '_qualityTargetProduct'}>
          {_qualityTargetProduct[i] ? (
            <Fragment>
              <td>
                {_qualityTargetProduct[i].elementName}
                <br />
                {_qualityTargetProduct[i].elementCode !== '' ? `(${_qualityTargetProduct[i].elementCode})` : ''}
              </td>
              <td>
                {checkNull(_qualityTargetProduct[i].baseAmount, false) ?
                  <CommaNum
                    displayType={'text'}
                    num={_qualityTargetProduct[i].baseAmount}
                  />
                  : null}
              </td>
              <td>
                <CommaNum
                  displayType="text"
                  num={BigNumber(checkNull(_qualityTargetProduct[i].makeAmount, 0)).plus(BigNumber(checkNull(_qualityTargetProduct[i].failedAmount, 0))).toNumber()}
                />
              </td>
              <td>
                <CommaNum
                  displayType="text"
                  num={checkNull(_qualityTargetProduct[i].makeAmount, 0)}
                />
              </td>
              <td>
                <CommaNum
                  displayType="text"
                  num={checkNull(_qualityTargetProduct[i].failedAmount, 0)}
                />
              </td>
            </Fragment>
          ) : (
            <Fragment>
              <td></td><td></td><td></td><td></td><td></td>
            </Fragment>
          )}
        </tr>,
      );
      return returnArray;
    }
  };

  return (
    <Fragment>
      <tr data-key={_qualityData.workOrderLogId}>
        {props.authority.indexOf('411-2') !== -1 ? (
          <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ minWidth: '50px', width: '50px' }}>
            <CheckBox
              act={'one'}
              all={false}
              checkboxKey={_qualityData.workOrderLogId}
              name={'failedWorkOrder'}
            />
          </td>
        ) : null}
        <QualityButton
          rowSpan={_rowLength === 0 ? 1 : _rowLength}
          style={{ minWidth: '100px', padding: 'unset', width: '100px' }}
          onClick={(e) => {
            e.preventDefault();
            props.showProductionProcessTracking(_qualityData);
          }}
        >
          생산추적
          <GuideText />
        </QualityButton>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          <div
            className='btn-doc'
            onClick={(e) => {
              e.preventDefault();
              props.showProcessList(_qualityData);
            }}
          >
            <img src={baseInformationIcon} alt={'공정리스트 버튼'} />
          </div>
        </td>

        {/* 파일 */}
        {(() => {
          if (props.authority.indexOf('109-1') !== -1 || props.authority.indexOf('109-2') !== -1) {
            if (checkNullArray(_qualityData.attachFileList, false)) {
              return (
                <td rowSpan={_rowLength === 0 ? 1 : _rowLength} className='table-preview'>
                  <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.showFileList(_qualityData, true);
                    }}
                  >
                    <img src={_qualityData.attachFileList[0]?.fileData?.fileDataS3URL || ''} alt='image preview' className='img-preview' />
                    <div className='btn-file'><DOCButtonIcon /></div>
                  </div>
                </td>
              );
            } else {
              return (
                <td rowSpan={_rowLength === 0 ? 1 : _rowLength} className='table-preview'>
                  <div
                    style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.showFileList(_qualityData, true);
                    }}
                  >
                    <div className='no-image'><p>이미지가 없습니다.</p></div>
                    <div className='btn-file'><DOCButtonIcon /></div>
                  </div>
                </td>
              );
            }
          }
        })()}

        <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ minWidth: '60px', padding: 'unset', width: '60px' }}>
          {_qualityData.workOrderLogType}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {_qualityData.workOrderCode}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {_qualityData.workOrderName}
        </td>
        {(() => {
          if (props.authority.indexOf('104-1') !== -1 || props.authority.indexOf('104-2') !== -1) {
            return (
              <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{checkEmptyNull(_qualityData.accountName, '')}</td>
            );
          } else return null;
        })()}

        {_rowLength > 0 && _qualityTargetProduct[0] ? (
          <Fragment>
            <td>
              {_qualityTargetProduct[0].elementName}
              <br />
              {_qualityTargetProduct[0].elementCode !== '' ? `(${_qualityTargetProduct[0].elementCode})` : ''}
            </td>
            <td>
              {checkNull(_qualityTargetProduct[0].baseAmount, false) ?
                <CommaNum
                  displayType={'text'}
                  num={_qualityTargetProduct[0].baseAmount}
                />
                : null}
            </td>
            <td>
              <CommaNum
                displayType="text"
                num={BigNumber(checkNull(_qualityTargetProduct[0].makeAmount, 0)).plus(BigNumber(checkNull(_qualityTargetProduct[0].failedAmount, 0))).toNumber()}
              />
            </td>
            <td>
              <CommaNum
                displayType="text"
                num={checkNull(_qualityTargetProduct[0].makeAmount, 0)}
              />
            </td>
            <td>
              <CommaNum
                displayType="text"
                num={checkNull(_qualityTargetProduct[0].failedAmount, 0)}
              />
            </td>
          </Fragment>
        ) : (
          <Fragment>
            <td></td><td></td><td></td><td></td><td></td>
          </Fragment>
        )}

        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {checkEmptyNull(_qualityData.workOrderLogActualStartDate, false) && DateFormat(_qualityData.workOrderLogActualStartDate)}
          <br /> ~{' '}
          {checkEmptyNull(_qualityData.workOrderLogActualEndDate, false) && DateFormat(_qualityData.workOrderLogActualEndDate)}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {_qualityData.userName}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {checkNullArray(_qualityData.managers, []).map((worker, index) => {
            return (
              <p key={props.index + '_' + index + '_managers'}>
                {worker.managerName}
              </p>
            );
          })}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {checkNullArray(_qualityData.workers, []).map((worker, index) => {
            return (
              <p key={props.index + '_' + index + '_workers'}>
                {worker.workerName}
              </p>
            );
          })}
        </td>
      </tr>
      {_rowLength > 1 && returnItems()}
    </Fragment>
  );
};

export default FailedWorkRow;
