import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fileActions_setCurrentFolder } from 'store/modules/actions/common/fileActions';

import { fileCategoryApi } from 'api/apis/fileCategoryApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { FolderComponentStyle } from './FolderComponent.style';
import ByteFormat from 'components/format/ByteFormat';
import Folder from './assets/Folder';
import FolderModal from './modal/FolderModal';

const FolderComponent = () => {
  const dispatch = useDispatch();
  const { userReducer } = useSelector((state) => state);

  const companyId = userReducer.company.companyId;
  const [_companyFileSize, setCompanyFileSize] = useState();

  const [_fileCategories, setFileCategories] = useState([]);
  const [_flatFileCategories, setFlatFileCategories] = useState([]);

  const [_currentFolder, setCurrentFolder] = useState({});
  const [_currentFolderPath, setCurrentFolderPath] = useState([]);
  const [_reset, setReset] = useState(false);
  
  const [_folderSettingToggle, setFoldeSettingToggle] = useState(false);
  const [_modalStatus, setModalStatus] = useState(false);
  const [_modalType, setModalType] = useState();
  const [_modalData, setModalData] = useState({});

  useEffect(() => {
    getCompanyFileSize();
    getFileCategories();

    return () => { }
  }, []);
  useEffect(() => { }, [_currentFolder, _currentFolderPath, _folderSettingToggle, _reset]);

  const getCompanyFileSize = async () => {
    await fileCategoryApi.getCompanyFileSize(companyId).then(response => {
      if(response === undefined) return;
      console.log('fileCategoryApi.getCompanyFileSize : ', response);
      setCompanyFileSize(() => {return ByteFormat(response.data?.fileTotalSize || 0)});
    })
  }

  const getFileCategories = async () => { // fileCategory 리스트 불러오기
    const body = {
      companyId: companyId,
      fileCategoryParentId: null,
      fileCategoryLevel: 1,
    }
    await fileCategoryApi.searchFileCategory(body).then(response => {
      if (response === undefined) return;
      console.log('fileCategoryApi.searchFileCategory : ', response);
      setFileCategories(() => { return response.data });
      setFlatFileCategories(() => { return flattenArray(response.data) });
    })
  };
  const flattenArray = (arr) => { // childrenFileCategory 1차원 배열 만들기
    let result = [];
    arr.forEach(item => {
      result.push(item);
      if (checkNullArray(item.childrenFileCategory, false)) {
        result = result.concat(flattenArray(item.childrenFileCategory));
      }
    })
    return result;
  }

  const handleCurrentFolder = (folder) => { // 현재 폴더 설정 & 폴더 경로 설정
    if (!checkEmptyNull(folder.fileCategoryLevel, false)) return;
    setCurrentFolder(() => { return folder });
    dispatch(fileActions_setCurrentFolder(folder));
    let fileCategoryLevel = Number(folder.fileCategoryLevel);
    let fileCategoryData = folder;
    const result = [];
    while (fileCategoryLevel > 1) { // 상위 폴더 찾기 > 푸시
      const parentId = fileCategoryData.fileCategoryParentId;
      const parentData = _flatFileCategories.find(item => item.fileCategoryId === parentId);
      if (checkNullObject(parentData, false)) {
        result.unshift(parentData);
        fileCategoryData = parentData;
      }
      fileCategoryLevel--;
    }
    result.push(folder); // 현재 폴더 푸시
    setCurrentFolderPath(() => { return result });
  }
  const translateCurrentFolder = (currentFolder) => { // 폴더 경로 표시
    if(!checkNullArray(currentFolder, false)) return userReducer.company.companyName;
    const fileCategoryName = currentFolder.map(item => item.fileCategoryName);
    if (fileCategoryName.length > 3) {
      const returnData = fileCategoryName.slice(-3);
      return '.../' + checkNullArray(returnData, []).join('/');
    } else {
      return checkNullArray(fileCategoryName, []).join('/');
    }
  }

  const handleFolderSetting = (type) => { // 폴더 추가/삭제, 폴더 정보 수정
    setModalType(() => { return type });
    setModalData(() => { 
      if(type === 'create') {
        return {
          companyId: companyId,
          fileCategoryName: '',
          fileCategoryParentId: checkNullObject(_currentFolder, false) ? _currentFolder.fileCategoryId : null,
        };
      } else {
        return _currentFolder;
      }
    });

    setTimeout(() => {
      setModalStatus(() => { return true });
    }, 1000);
  }

  const actReset = () => { // 폴더 모두 닫기
    setCurrentFolder(() => {return {}});
    setCurrentFolderPath(() => {return []});
    dispatch(fileActions_setCurrentFolder({}));

    setReset(() => {return true});
  }

  return (
    <>
    <FolderComponentStyle>
      <div onClick={actReset} title='폴더 모두 닫기'>
        <p>{userReducer.company.companyName}</p>
        <p>{_companyFileSize}</p>
        {/* <p>사용 용량 : {_companyFileSize}</p> */}
      </div>
      <div>
        <p>{translateCurrentFolder(_currentFolderPath)}</p>
        <button title={_currentFolder.fileCategoryName || `${userReducer.company.companyName} 폴더` + ' 설정'}
          onClick={(e) => {
            e.preventDefault();
            setFoldeSettingToggle((prev) => !prev);
          }}
        >&#8942;</button>
        {_folderSettingToggle ? (
          <div className='act-content'>
            {checkNullObject(_currentFolder, false) ? (
              <p title={_currentFolder.fileCategoryName}>{_currentFolder.fileCategoryName}</p>
            ) : null}
            <div onClick={() => {handleFolderSetting('create');}}>폴더 추가</div>
            {checkNullObject(_currentFolder, false) ? (
              <>
                <div onClick={() => {handleFolderSetting('update');}}>폴더 정보 수정</div>
                <div onClick={() => {handleFolderSetting('delete');}}>폴더 삭제</div>
              </>
            ) : null}
          </div>
        ) : null}
      </div>
      <div>
        <div>
          {_fileCategories.map((fileCategory, index) => {
            return (
              <Folder 
                key={index + '_Folder'} 
                fileCategory={fileCategory} 
                currentFolder={_currentFolder} 
                handleCurrentFolder={handleCurrentFolder} 
                reset={_reset}
                setReset={setReset}
              />
            );
          })}
        </div>
      </div>
    </FolderComponentStyle>
    {_modalStatus ? (
      <FolderModal
        open={_modalStatus}
        modalType={_modalType}
        modalData={_modalData}
        close={(update) => {
          setModalStatus(() => { return false });
          setFoldeSettingToggle(() => {return false});
          if (update === 'update') {
            console.log('update');
            getCompanyFileSize();
            getFileCategories();
          }
        }}
      />
    ) : null}
    </>
  );
};

export default FolderComponent;