import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { mallSiteElementActions_setPageNumber, mallSiteElementActions_setSearchData, mallSiteElementActions_setTotalSize, mallSiteElementActions_setReset } from 'store/modules/actions/mallSite/mallSiteElementActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { element } from 'api/apis/mall/element';

import { checkEmptyNull, checkNull, checkNullObject } from 'components/checkValues/checkValues';

import CardV from 'mall/components/CardV';
import MallSiteCategories from 'mall/components/MallSiteCategories';
import PagingComponent from 'components/paging/PagingComponent';

const MallProductAll = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const location = useLocation();
  const { mallSiteElementReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const companyCode = location.pathname.split('/')[1];

  const [_onload, setOnload] = useState('unload');

  const [_mallElementList, setMallElementList] = useState([]);

  /* ====================================================================== #3 */
  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getMallElementList(mallSiteElementReducer.pageNumber - 1, mallSiteElementReducer.mallElementType);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getMallElementList(mallSiteElementReducer.pageNumber - 1, mallSiteElementReducer.mallElementType);
    }
    return () => { };
  }, [mallSiteElementReducer.pageNumber]);

  useEffect(() => {
    if (_onload === 'loaded') {
      dispatch(mallSiteElementActions_setPageNumber(1));
      getMallElementList(0, mallSiteElementReducer.mallElementType);
    }
    return () => { };
  }, [mallSiteElementReducer.clickedCategory]);

  useEffect(() => { }, [mallSiteElementReducer]);

  /* ====================================================================== #4 */
  const getMallElementList = async (page, mallElementType, clear) => {
    const paging = `?page=${page}&size=9&sort=id,DESC`;
    const BodyToPost = { companyCode: companyCode };
    if (!clear && mallElementType !== undefined && mallElementType !== 'all') {
      BodyToPost.mallElementType = mallElementType;
    }
    if (!clear && checkNullObject(mallSiteElementReducer.clickedCategory, false) && mallSiteElementReducer.clickedCategory !== 'all') {
      BodyToPost.mallCategoryId = mallSiteElementReducer.clickedCategory.mallCategoryId;
    }
    if (!clear && checkEmptyNull(mallSiteElementReducer.searchData.mallElementName, false)) {
      BodyToPost.mallElementName = mallSiteElementReducer.searchData.mallElementName;
    }
    if (!clear && checkEmptyNull(mallSiteElementReducer.searchData.mallElementCode, false)) {
      BodyToPost.mallElementCode = mallSiteElementReducer.searchData.mallElementCode;
    }
    await element.searchMallElement(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('element.searchMallElement : ', response);
      setMallElementList(() => { return response.data.content });
      dispatch(mallSiteElementActions_setTotalSize(response.data.totalElements));
    });
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(mallSiteElementActions_setPageNumber(1));
    await getMallElementList(0, mallSiteElementReducer.mallElementType);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(mallSiteElementActions_setReset());
    dispatch(mallSiteElementActions_setPageNumber(1));
    await getMallElementList(0, 'all', true);
  };

  return (
    <>
      <section className='mall-product-list-search' style={{ width: '100%' }}>
        <ul style={{ width: 'auto' }}>
          <MallSiteCategories categoryType={'mallSiteElement'} className='product-list-category' />
          <li className='mall-product-list-search-family'>
            <div>
              {/* <p>상품코드 검색</p> */}
              <select disabled value={'mallElementCode'}>
                <option value={'mallElementCode'}>상품코드</option>
              </select>
              <input className="SearchBar" placeholder="검색할 상품코드를 입력해주세요."
                value={checkNull(mallSiteElementReducer.searchData.mallElementCode, '',)}
                onInput={(e) => { dispatch(mallSiteElementActions_setSearchData({ ...mallSiteElementReducer.searchData, mallElementCode: e.target.value }),); }}
              />
            </div>
            <div>
              {/* <p>상품이름 검색</p> */}
              <select disabled value={'mallElementName'}>
                <option value={'mallElementName'}>상품이름</option>
              </select>
              <input className="SearchBar" placeholder="검색할 상품이름을 입력해주세요."
                value={checkNull(mallSiteElementReducer.searchData.mallElementName, '',)}
                onInput={(e) => { dispatch(mallSiteElementActions_setSearchData({ ...mallSiteElementReducer.searchData, mallElementName: e.target.value })); }}
              />
              <a onClick={actReset}><img src='https://cdn-icons-png.flaticon.com/512/82/82004.png' style={{ width: '16px' }} /></a>
            </div>
          </li>
        </ul>
        <button onClick={actSearch}>검색</button>
      </section>
      <section className='mall-product-list-card'>
        {_mallElementList.map((el, idx) => {
          return <CardV key={idx + '_mallElements'} mallElement={el} />;
        })}
      </section>
      <section>
        <PagingComponent
          page={mallSiteElementReducer.pageNumber}
          count={mallSiteElementReducer.totalSize}
          size={9}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(mallSiteElementActions_setPageNumber(page));
          }}
        />
      </section>
    </>
  );
};

export default MallProductAll;
