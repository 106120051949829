import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { chatReducer_setChatInput, chatReducer_setChatRoomOpen, chatReducer_setChatRoomRead } from 'store/modules/actions/default/chatActions';

import { getCookie } from 'preferences/cookie/cookie';
import { WS_CLIENT } from 'preferences/server/constants';

import { checkNull } from 'components/checkValues/checkValues';
import ChatRoom from 'components/chatting/ChatRoom';

const ChatButton = styled.div`
  align-items: center;
  background-color: ${(props) => {
    return props.read === 'unread' && props.open === 'close' ? 'var(--MainRed)' : 'var(--MainNavy)';
  }};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 8px;
`;
const ChatButtonIcon = styled.img`
  color: red;
  height: 28px;
  width: 28px;
`;

const ChatStartButton = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { chatReducer, pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_modalStatus, setModalStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    openChatRoom();
    return () => { };
  }, [chatReducer.chatList]);

  useEffect(() => {
    setModalStatus(false);
    return () => { };
  }, [pageReducer.currentPage]);

  /* ====================================================================== #4 */

  /* ====================================================================== #5 */
  const openChatRoom = () => {
    if (WS_CLIENT.connected) {
      dispatch(chatReducer_setChatRoomOpen('open'));
      dispatch(chatReducer_setChatRoomRead('read'));
      setModalStatus(true);
    } else {
      setModalStatus(false);
    }
  };

  const actSendMessage = () => {
    const token = checkNull(getCookie('accessToken'), false);
    const chat = chatReducer.chatInput;
    if (!token || !WS_CLIENT.connected || chat === '') return;

    try {
      WS_CLIENT.publish({
        destination: '/pub/chat',
        headers: { 'auth-token': token.replace('Bearer ', '') },
        body: JSON.stringify({
          roomId: userReducer.company.companyId,
          companyId: userReducer.company.companyId,
          message: chat,
        }),
      });
      dispatch(chatReducer_setChatInput(''));
    } catch (error) {
      console.log('actSendMessage - error : ', error);
    }
  };

  /* ====================================================================== #6 */

  return (
    <>
      {WS_CLIENT.connected && chatReducer.connectStatus === 'connect' ? (
        <ChatButton
          style={props.buttonStyle}
          onClick={props.clickEvent !== 'none' ? openChatRoom : null}
          open={chatReducer.chatRoomOpen}
          read={chatReducer.chatRoomRead}
        >
          <ChatButtonIcon src={process.env.PUBLIC_URL + '/src/icons/chatButton.svg'} />
        </ChatButton>
      ) : null}

      {_modalStatus === true && (
        <ChatRoom
          chatStyle={props.chatStyle}
          sendMessage={actSendMessage}
          open={_modalStatus}
          close={() => {
            dispatch(chatReducer_setChatRoomOpen('close'));
            dispatch(chatReducer_setChatRoomRead('read'));
            setModalStatus(false);
          }}
        />
      )}
    </>
  );
};

export default ChatStartButton;
