import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import TableSection from 'components/layouts/table/TableSection';

const SerialMain = styled.div`
  display: grid;
  gap: 10px;
  grid-template-rows: ${(props => {
    return props.mobile ? '300px auto' : '300px calc(100% - 310px)';
  })};
  height: 100%;
  position: relative;
  width: 100%;

  & .sec-serial {
    align-content: flex-start;
    background-color: var(--white);
    box-sizing: border-box;
    display: grid;
    gap: 20px;
    padding: 20px;
    width: 100%;

    & .contents-serial {
      display: grid;
      grid-gap: 20px;

      & .con-serial {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: flex-start;
  
        & h4 {
          border-right: 2px solid var(--gray-100);
          width: 100px;
        }
        & p {
          box-sizing: border-box;
          font-size: 0.9em;
        }
      }
    }
  }
`;

const SerialInfo = (props) => {
  const mobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  const [_serialData, setSerialData] = useState(props.serialData);
  const [_serialConData, setSerialConData] = useState(props.serialConData);

  useEffect(() => {
    setSerialData(() => { return props.serialData });
    setSerialConData(() => { return props.serialConData });
  }, [props]);

  return (
    <>
      <SerialMain mobile={mobile()}>
        <section className='sec-serial'>
          <div className='contents-serial'>
            {checkNullArray(_serialConData, []).map((conData, index) => {
              return (
                <div key={index + '_conData'} className='con-serial'>
                  <h4>{conData.key}</h4>
                  <p>{conData.value}</p>
                </div>
              )
            })}
          </div>
        </section>
        <section className='sec-failedReasons'>
          <TableSection content={
            <table>
              <colgroup>
                <col width={'200px'}></col>
                <col></col>
              </colgroup>
              <thead>
                <tr>
                  {[
                    {name: '불량수량', color: 'var(--ThirdRed)'},
                    {name: '불량사유', color: 'var(--ThirdRed)'},
                  ].map((key, index) => {
                    return (<th key={index + '_lot_key'} style={{ backgroundColor: key.color }}>{key.name}</th>)
                  })}
                </tr>
              </thead>
              <tbody>
              {checkNullArray(_serialData.processResultElementFailedReasons, []).map((failedReason, failedReasonIndex) => {
                return(
                  <tr key={failedReasonIndex + '_failedReason'}>
                    <td>[ {checkEmptyNull(failedReason.amount, 0).toLocaleString()} ]</td>
                    <td>{failedReason.failedReason}</td>
                  </tr>
                )
              })}
              </tbody>
            </table>
          } />
        </section>
      </SerialMain>
    </>
  );
};

export default SerialInfo;