export const mallOrderActions_setMallOrderStatus = (status) => {
  return {
    type: 'SETMALLORDERSTATUS',
    payload: status,
  };
};
export const mallOrderActions_setStartDate = (date) => {
  return {
    type: 'SETMALLORDERSTARTDATE',
    payload: date,
  };
};
export const mallOrderActions_setEndDate = (date) => {
  return {
    type: 'SETMALLORDERENDDATE',
    payload: date,
  };
};
export const mallOrderActions_setSearchOption = (searchOption) => {
  return {
    type: 'SETMALLORDERSEARCHOPTION',
    payload: searchOption,
  };
};
export const mallOrderActions_setSearchText = (searchText) => {
  return {
    type: 'SETMALLORDERSEARCHTEXT',
    payload: searchText,
  };
};
export const mallOrderActions_setPageNumber = (pageNumber) => {
  return {
    type: 'SETMALLORDERPAGENUMBER',
    payload: pageNumber,
  };
};
export const mallOrderActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETMALLORDERTOTALSIZE',
    payload: totalSize,
  };
};
export const mallOrderActions_setIsPushedSearchButton = (
  isPushedSearchButton,
) => {
  return {
    type: 'SETMALLORDERISPUSHEDSEARCHBUTTON',
    payload: isPushedSearchButton,
  };
};
export const mallOrderActions_setReset = () => {
  return {
    type: 'SETMALLORDERRESET',
  };
};
