import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  AXIOS_BASE_HEADERS,
  AXIOS_BASE_URL,
} from 'preferences/server/constants';

import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';

const UpdateFailedReason = () => {
  /* ========================================================================= #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [searchParams, setSearchParams] = useSearchParams();

  const failedReasonId = searchParams.get('failedReasonId');
  const failedReasonContent = searchParams.get('failedReasonContent');
  const failedReasonType = searchParams.get('failedReasonType');
  console.table({
    failedReasonId: failedReasonId,
    failedReasonContent: failedReasonContent,
    failedReasonType: failedReasonType,
  });

  const [_onload, setOnload] = useState('unload');

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    failedReasonId: failedReasonId,
    failedReasonContent: failedReasonContent,
    failedReasonType: failedReasonType,
  });

  /* ========================================================================= #3 */
  useEffect(() => {
    setOnload('loaded');
    return () => {};
  }, []);

  /* ========================================================================= #4 */

  /* ========================================================================= #5 */
  const handleInputValue = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  /* 수정 */
  const actUpdate = async (e) => {
    e.preventDefault();
    if (_formData.failedReasonContent === '')
      return alert('불량사유를 입력해주세요');

    const BodyToPut = { ..._formData };
    console.log('actUpdate - BodyToPut : ', BodyToPut);

    await axios
      .put(
        AXIOS_BASE_URL + '/failedReason/' + failedReasonId,
        BodyToPut,
        AXIOS_BASE_HEADERS,
      )
      .then((response) => {
        if (response === undefined) return;
        console.log('actUpdate - response : ', response);
        alert('불량사유를 수정했습니다.');

        navigate(pageReducer.currentPage, { replace: true });
      })
      .catch((error) => {
        console.log('actUpdate - error : ', error);
      });
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body
      contents={
        <>
          <main className="Main">
            <NavBar title={'불량사유관리'} nav={''} />

            <Form
              title={
                <>
                  불량사유
                  <br />
                  수정
                </>
              }
              buttons={
                <>
                  <button className="formButton" onClick={actUpdate}>
                    저장
                  </button>
                  <button
                    className="formButton cancle"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                </>
              }
              forms={
                <>
                  <div className="formBox w100">
                    <h4>불량유형</h4>
                    <input
                      type="text"
                      name="failedReasonType"
                      placeholder="* 불량유형..."
                      value={_formData.failedReasonType}
                      onInput={handleInputValue}
                    />
                  </div>

                  <div className="formBox w100">
                    <h4>불량사유</h4>
                    <input
                      type="text"
                      name="failedReasonContent"
                      data-required="required"
                      placeholder="* 불량사유..."
                      value={_formData.failedReasonContent}
                      onInput={handleInputValue}
                    />
                  </div>
                </>
              }
            />
          </main>
        </>
      }
    />
  );
};

export default UpdateFailedReason;
