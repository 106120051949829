import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Sign, EmailInput, PasswordInput } from 'mall/styles/style';

import { sign } from 'api/apis/mall/sign';

import { checkEmail, checkPw } from 'components/checkValues/sign';

import AgreementModal from 'mall/components/sign/modal/AgreementModal';
// import MallLogo from 'mall/components/MallLogo';

const MallSignJoin = () => {
  /* ====================================================================== #1 */
  const location = useLocation();
  const navigate = useNavigate();

  /* ====================================================================== #2 */
  const companyCode = location.pathname.split('/')[1];

  const [_loginId, setLoginId] = useState('');

  const [_emailStatus, setEmailStatus] = useState(false);
  const [_email, setEmail] = useState('');

  const [_mallUserName, setMallUserName] = useState('');

  const [_phoneNumber, setPhoneNumber] = useState('');

  const [_pwStatus, setPwStatus] = useState(false);
  const [_pw, setPw] = useState('');
  const [_confirmPw, setConfirmPw] = useState('');

  const [_joinActive, setJoinActive] = useState(true);
  // const [_joinFormData, setJoinFormData] = useState({});
  const [_agreementModalStatus, setAgreementModalStatus] = useState(true);

  /* ====================================================================== #3 */
  useEffect(() => { checkPassword(); return () => { } }, [_pw, _confirmPw]);
  // useEffect(() => { checkPw(_pw, _confirmPw); return () => { }; }, [_pw, _confirmPw]);

  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  // 이메일 체크
  // const checkEmail = (email) => {
  //   const reg = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
  //   if (!reg.test(email)) return false; // 불통
  //   else return true; // 통과
  // };

  // 전화번호 형식 변환
  const makePhoneNumber = (e) => {
    const phoneNumber = e.target.value.toString().replace(/[^0-9]/g, '').replace(/(^02.{0}|^01.{1}|[0-9]{3,4})([0-9]{3,4})([0-9]{4})/g, '$1-$2-$3');
    setPhoneNumber(() => { return phoneNumber });
  };

  // 비밀번호 체크
  const checkPassword = () => {
    setPwStatus(() => { return checkPw(_pw, _confirmPw); })
  }
  // const checkPw = (pw, confirmPw) => {
  //   const reg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
  //   setPwStatus(() => {
  //     console.log(pw, confirmPw, !reg.test(pw), pw !== confirmPw);
  //     if (!reg.test(pw)) return false; // 불통
  //     else if (pw !== confirmPw) return false; // 불통
  //     else return true; // 통과
  //   });
  // };

  // 동의 팝업 > 회원가입
  const actJoin = async () => {
    if (_loginId.length === 0) return alert('아이디를 확인해주세요.');
    if (_email.length === 0 || !checkEmail(_email)) return alert('이메일을 확인해주세요.');
    if (_mallUserName.length === 0) return alert('이름을 확인해주세요.');
    if (_phoneNumber.length === 0) return alert('연락처를 확인해주세요.');
    if (_pw.length < 8 || !_pwStatus) return alert('비밀번호를 확인해주세요.');

    const reg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
    if (!reg.test(_pw)) return alert('비밀번호는 최소 8자리 이상 영문 + 숫자 + 특수기호 조합으로 등록하셔야 합니다.');

    const BodyToPost = {
      companyCode: companyCode,

      role: 'ROLE_MALL',
      permission: true,

      loginId: _loginId,
      password: _pw,

      email: _email,
      name: _mallUserName,
      phoneNumber: _phoneNumber,
    };
    console.log('actAgree - BodyToPost : ', BodyToPost);

    setJoinActive(() => { return false });
    await sign.join(BodyToPost).then((response) => {
      if (response === undefined) return setJoinActive(() => { return true });
      console.log('sign.join : ', response);

      const joinText = `
        회원가입이 완료되었습니다.
        이메일 인증 후 접속이 가능합니다.
        
        로그인 화면으로 이동합니다.
      `;
      alert(joinText);

      navigate(`/${companyCode}/mall/sign/login`, { replace: true });
    }).catch((error) => {
      console.log('sign.join - error : ', error);
      setJoinActive(() => { return true });
    });
  };

  return (
    <>
      <Sign>
        <header>
          <Link to={`/${companyCode}/mall/product/list`}>아이피스 몰</Link>
          <Link to={`/${companyCode}/mall/sign/login`}>로그인</Link>
        </header>
        <section className='sign signup'>
          {/* <MallLogo /> */}
          <h3>회원가입</h3>
          <fieldset>
            <input
              type="text" placeholder="아이디" required
              value={_loginId}
              onInput={(e) => {
                const loginId = e.target.value;
                setLoginId(() => { return loginId; });
              }}
            />
            <EmailInput
              type="email" placeholder="이메일" required
              data-status={_emailStatus} value={_email}
              onInput={(e) => {
                const email = e.target.value;
                setEmailStatus(() => { return checkEmail(email) });
                setEmail(() => { return email });
              }}
            />
            <input
              type="text" placeholder="이름" required
              value={_mallUserName}
              onInput={(e) => {
                const mallUserName = e.target.value;
                setMallUserName(() => { return mallUserName; });
              }}
            />
            <input
              type="text"
              name="phoneNumber"
              placeholder="연락처 - 를 제외하고 입력해주세요. ex) 01012341234"
              maxLength={14}
              value={_phoneNumber}
              onInput={makePhoneNumber}
            />
            <PasswordInput required
              type="password" name="password" placeholder="비밀번호 (8자리 이상 영문+숫자+특수기호)"
              minLength={8} value={_pw} data-status={_pwStatus}
              onInput={(e) => {
                const pw = e.target.value;
                setPw(() => { return pw; });
              }}
            />
            <PasswordInput required
              type="password" name="confirmPassword" placeholder="비밀번호 확인"
              minLength={8} data-status={_pwStatus} value={_confirmPw}
              onInput={(e) => {
                const pw = e.target.value;
                setConfirmPw(() => { return pw; });
              }}
            />
          </fieldset>
          {_joinActive ? <button type="submit" className='submit' onClick={actJoin}>가입하기</button> : null}
          <div className='signitem'>
            <button onClick={() => { navigate(`/${companyCode}/mall/sign/login`, { replace: true }); }}>로그인</button>
            <button onClick={() => { navigate(`/${companyCode}/mall/sign/email/find`, { replace: true }); }} >아이디 찾기</button>
            <button onClick={() => { navigate(`/${companyCode}/mall/sign/pw/find`, { replace: true }); }} >비밀번호 찾기</button>
          </div>
        </section>
      </Sign>
      {_agreementModalStatus ? (
        <AgreementModal
          activeJoin={() => {
            setJoinActive(() => { return true });
            setAgreementModalStatus(() => { return false });
          }}
          open={_agreementModalStatus}
          close={() => { navigate(`/${companyCode}/mall/sign/login`, { replace: true }) }}
        />
      ) : null}
    </>
  );
};

export default MallSignJoin;
