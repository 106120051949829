export const stockPlaceHistoryActions_setElementType = (elementType) => {
  return {
    type: 'SETSTOCKPLACEHISTORYTYPE',
    payload: elementType,
  };
};
export const stockPlaceHistoryActions_setStartDate = (date) => {
  return {
    type: 'SETSTOCKPLACEHISTORYSTARTDATE',
    payload: date,
  };
};
export const stockPlaceHistoryActions_setEndDate = (date) => {
  return {
    type: 'SETSTOCKPLACEHISTORYENDDATE',
    payload: date,
  };
};
export const stockPlaceHistoryActions_setSearchData = (searchData) => {
  return {
    type: 'SETSTOCKPLACEHISTORYSEARCHDATA',
    payload: searchData,
  };
};
export const stockPlaceHistoryActions_setSearchAttrs = (serachAttrs) => {
  return {
    type: 'SETSTOCKPLACEHISTORYSEARCHATTS',
    payload: serachAttrs,
  };
};

export const stockPlaceHistoryActions_setClickedCategory = (category) => {
  return {
    type: 'SETSTOCKPLACEHISTORYCLICKEDCATEGORY',
    payload: category,
  };
};
export const stockPlaceHistoryActions_setClickedLevel1Category = (category) => {
  return {
    type: 'SETSTOCKPLACEHISTORYCLICKEDLEVEL1CATEGORY',
    payload: category,
  };
};
export const stockPlaceHistoryActions_setClickedLevel2Category = (category) => {
  return {
    type: 'SETSTOCKPLACEHISTORYCLICKEDLEVEL2CATEGORY',
    payload: category,
  };
};
export const stockPlaceHistoryActions_setClickedLevel3Category = (category) => {
  return {
    type: 'SETSTOCKPLACEHISTORYCLICKEDLEVEL3CATEGORY',
    payload: category,
  };
};
export const stockPlaceHistoryActions_setClickedLevel4Category = (category) => {
  return {
    type: 'SETSTOCKPLACEHISTORYCLICKEDLEVEL4CATEGORY',
    payload: category,
  };
};
export const stockPlaceHistoryActions_setClickedLevel5Category = (category) => {
  return {
    type: 'SETSTOCKPLACEHISTORYCLICKEDLEVEL5CATEGORY',
    payload: category,
  };
};

export const stockPlaceHistoryActions_setPageNumber = (pageNum) => {
  return {
    type: 'SETSTOCKPLACEHISTORYPAGENUMBER',
    payload: pageNum,
  };
};
export const stockPlaceHistoryActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETSTOCKPLACEHISTORYTOTALSIZE',
    payload: totalSize,
  };
};
export const stockPlaceHistoryActions_setIsPushedSearchButton = (status) => {
  return {
    type: 'SETSTOCKPLACEHISTORYISPUSHEDSEARCHBUTTON',
    payload: status,
  };
};

export const stockPlaceHistoryActions_setReset = () => {
  return {
    type: 'SETSTOCKPLACEHISTORYRESET',
  };
};

export const stockPlaceHistoryActions_selectOneElement = (element) => {
  return {
    type: 'SELECTONESTOCKPLACEHISTORY',
    payload: element,
  };
};

export const stockPlaceHistoryActions_selectElements = (elements) => {
  return {
    type: 'SELECTSTOCKPLACEHISTORYS',
    payload: elements,
  };
};

export const stockPlaceHistoryActions_setLevel1Categories = (category) => {
  return {
    type: 'SETSTOCKPLACEHISTORYLEVEL1CATEGORIES',
    payload: category,
  };
};
export const stockPlaceHistoryActions_setLevel2Categories = (category) => {
  return {
    type: 'SETSTOCKPLACEHISTORYLEVEL2CATEGORIES',
    payload: category,
  };
};
export const stockPlaceHistoryActions_setLevel3Categories = (category) => {
  return {
    type: 'SETSTOCKPLACEHISTORYLEVEL3CATEGORIES',
    payload: category,
  };
};
export const stockPlaceHistoryActions_setLevel4Categories = (category) => {
  return {
    type: 'SETSTOCKPLACEHISTORYLEVEL4CATEGORIES',
    payload: category,
  };
};
export const stockPlaceHistoryActions_setLevel5Categories = (category) => {
  return {
    type: 'SETSTOCKPLACEHISTORYLEVEL5CATEGORIES',
    payload: category,
  };
};
