import React, { useEffect, useState } from "react";
import Chart from 'react-apexcharts';
import moment from "moment";

const RealTimeGraph = ({ datas }) => {
  // const hours = Array.from({ length: 24 }, (_, i) => i);  // hour
  // const xCategories = hours.map(hour => (hour < 10 ? `0${hour}시` : `${hour}시`)); // hour format

  const chartOption = {
    series: [
      {
        name: '당일 사용량',
        type: 'column',
        data: [],
        color: '#80D4FA'
      },
      {
        name: '실시간 에너지 사용 평균',
        // name: '직전 7일 평균',
        type: 'line',
        data: [],
        color: '#5162BD'
      }
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        toolbar: {show: false},
      },
      stroke: {
        curve:'smooth',
        width: [0, 4]
      },
      grid: {
        xaxis: {
          lines: {show: true},
        },   
        yaxis: {
          ines: {show: true},
        },
      },
      dataLabels: {enabled: false},
      xaxis: {
        type: 'category',
        categories: []
      },
      yaxis: {
        min: 0,
        max: 50,
        tickAmount: 5, // 200kWh 간격으로 5개의 눈금을 표시
        labels: {
          formatter: function (val) {return val + 'kWh';},
        },
      },        
    },
  };
  const [chartData, setChartData] = useState(chartOption);
  // console.log('chartData : ', chartData);

  const [_data, setData] = useState([]);
  const [_xCategories, setXCategories] = useState([]);
  const [_todayData, setTodayData] = useState([]);
  const [_dataAverage, setDataAverage] = useState([]);

  useEffect(() => {
    const dataList = datas.map(item => !isNaN(Number(item.value)) ? Number(item.value) : 0);
    const xCategories = datas.map(item => moment(item.date).format('HH:mm:ss'));
    // const todayData = Array.from(Array(24), (_, i) => (dataList[i] !== undefined ? dataList[i] : null));
    const dataAverage =  Array.from(Array(dataList.length), (_, i) => ((dataList.reduce((a, b) => a + b, 0) / dataList.length)));
    setData(() => {return dataList})
    setXCategories(() => {return xCategories})
    setTodayData(() => {return dataList});
    setDataAverage(() => {return dataAverage});
    return () => {};
  }, [datas]);

  useEffect(() => {
    const xaxis = {
      type: 'category',
      categories: _xCategories
    };
    const series = [
      {
        name: '당일 사용량',
        type: 'column',
        data: _todayData,
        color: '#80D4FA'
      },
      {
        name: '실시간 에너지 사용 평균',
        // name: '직전 7일 평균',
        type: 'line',
        data: _dataAverage,
        color: '#5162BD'
      }
    ];
    const returnData = {...chartOption};
    returnData.options.xaxis = xaxis;
    returnData.series = series;
    setChartData(() => {return returnData});
  }, [_xCategories, _todayData, _dataAverage]);
  
  useEffect(() => {}, [chartData]);

  return (
    <div>
      <p>당일 실시간 에너지 사용 현황</p>
      <Chart options={chartData.options} series={chartData.series} type="line" height={350} />
    </div>
  );
};

export default RealTimeGraph;