import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

import { accountApi } from 'api/apis/accountApi';
import { accountAttributeApi } from 'api/apis/accountAttributeApi';
import { accountTypeApi } from 'api/apis/accountTypeApi';

import { checkNull } from 'components/checkValues/checkValues';

const AccountModal = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 6px;
  }
`;

const MainContents = styled.div`
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 5px 20px;
  width: 100%;
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ModalButton = styled.div`
  align-items: center;
  background-color: var(--ThirdGreen);
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 400;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const StatusSelect = styled.select`
  border: 1px solid var(--ThirdGreen);
  border-radius: 5px;
  box-sizing: border-box;
  height: fit-content;
  margin-top: 5px;
  padding: 10px;
  width: 100%;
`;

const SearchContents = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 150px calc(100% - 160px);
  margin-top: 5px;
  width: 100%;

  &.detail {
    grid-template-columns: 150px calc(100% - 210px) 50px;
  }

  select {
    border: 1px solid var(--ThirdGreen);
    border-radius: 5px;
    box-sizing: border-box;
    height: fit-content;
    padding: 10px;
    width: 100%;

    &.detail {
      appearance: none;
      --webkit-appearance: none;

      background-color: var(--ThirdGreen);
      color: var(--white);
    }
  }

  input {
    border: 1px solid var(--ThirdGreen);
    border-radius: 5px;
    box-sizing: border-box;
    height: fit-content;
    /* outline: var(--ThirdGreen); */
    padding: 10px;
    width: 100%;

    &:focus {
      border: 1px solid var(--ThirdGreen);
    }
  }
`;

const AccountDownloadModal = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_accountAttrList, setAccountAttrList] = useState([]);
  const [_accountAttrNameList, setAccountAttrNameList] = useState([]);

  const [_accountTypeList, setAccountTypeList] = useState([]);
  const [_accountType, setAccountType] = useState('');

  const [_searchOption, setSearchOption] = useState('');
  const [_searchText, setSearchText] = useState('');

  /* ====================================================================== #3 */
  useEffect(() => {
    getAccountTypes();
    getAccountAttrs(_accountType);

    setOnload('loaded');
  }, []);

  /* ====================================================================== #4 */
  const getAccountTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await accountTypeApi.searchAccountType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('accountTypeApi.searchAccountType : ', response);

      setAccountTypeList(() => {
        return response.data;
        // return response.data.content;
      });
    });
  };

  const getAccountAttrs = async (accountType) => {
    console.log('accountTypeName : ', accountType);
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (
      accountType !== undefined &&
      accountType !== null &&
      accountType !== 'all'
    ) {
      BodyToPost.accountTypeId = Number(accountType);
    }
    console.log('getAccountAttrs - BodyToPost : ', BodyToPost);

    await accountAttributeApi
      .searchAccountAttribute(BodyToPost)
      .then((response) => {
        console.log('accountAttributeApi.searchAccountAttribute : ', response);

        setAccountAttrList(() => {
          return response.data;
        });

        const nameList = {};
        response.data.forEach((thisItem) => {
          nameList[thisItem.accountAttributeId] = thisItem.accountAttributeName;
        });
        setAccountAttrNameList(() => {
          return nameList;
        });
      });
  };

  const actDownload = () => {
    const paging = `?page=0&size=7000&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.user.companyId,
    };

    if (
      _accountType !== undefined &&
      _accountType !== null &&
      _accountType !== ''
    ) {
      BodyToPost.accountTypeId = Number(_accountType);
    }

    if (
      _searchOption !== null &&
      _searchOption !== '' &&
      _searchText !== null &&
      _searchText !== ''
    ) {
      BodyToPost[_searchOption] = _searchText;
    }

    searchAccount(paging, BodyToPost);
  };
  const searchAccount = async (paging, BodyToPost) => {
    await accountApi.searchAccount(paging, BodyToPost).then((response) => {
      console.log('accountApi.searchAccount : ', response);
      setExcelData(response.data.content);
    });
  };
  const setExcelData = (Result) => {
    const accountList = Result;
    const excelList = accountList.map((thisItem) => {
      const returnData = {
        수요기관코드: checkNull(thisItem.accountCode, ''),
        사업자등록번호: checkNull(thisItem.businessNumber, ''),
        회사명: checkNull(thisItem.accountName, ''),
        지역명: checkNull(thisItem.regionName, ''),
        주소: checkNull(thisItem.address, ''),
        상세주소: checkNull(thisItem.detailAddress, ''),
        우편번호: checkNull(thisItem.zip, ''),
        담당자: checkNull(thisItem.managerName, ''),
        전화번호: checkNull(thisItem.telNumber, ''),
        휴대폰: checkNull(thisItem.phoneNumber, ''),
        팩스번호: checkNull(thisItem.faxNumber, ''),
        이메일: checkNull(thisItem.email, ''),
      };
      thisItem.accountAttributeValueList.forEach((thisAttr) => {
        returnData[thisAttr.accountAttributeName] = checkNull(thisAttr.value, '');
      });

      return returnData;
    });
    console.log('excelList : ', excelList);

    const ws = XLSX.utils.json_to_sheet(excelList);
    const wb = XLSX.utils.book_new();

    let fileTitle = '거래처 리스트';
    const typeData = _accountTypeList.find((thisItem) => {
      if (thisItem.accountTypeId === Number(_accountType)) return true;
      else return false;
    });
    if (typeData !== undefined) {
      fileTitle = typeData.accountTypeName + ' 리스트';
    }
    XLSX.utils.book_append_sheet(wb, ws, fileTitle);
    XLSX.writeFile(wb, fileTitle + '.xlsx');

    props.close();
  };

  /* ====================================================================== #5 */

  /* ====================================================================== #6 */

  return (
    <>
      <AccountModal>
        <MainContents>
          <div>거래처 유형</div>
          <StatusSelect
            value={_accountType}
            onChange={(e) => {
              setAccountType(() => {
                return e.target.value;
              });

              getAccountAttrs(e.target.value);
            }}
          >
            <option value="">거래처 유형</option>
            {_accountTypeList.map((thisType) => {
              if (thisType.accountTypeName === userReducer.company.companyName)
                return null;
              return (
                <option
                  key={thisType.accountTypeId}
                  value={thisType.accountTypeId}
                >
                  {thisType.accountTypeName}
                </option>
              );
            })}
          </StatusSelect>
        </MainContents>

        <MainContents>
          <div>검색</div>
          <SearchContents>
            <select
              className="SearchOption"
              value={_searchOption}
              onChange={(e) => {
                setSearchOption(() => {
                  return e.target.value;
                });
              }}
            >
              <option value="">검색옵션</option>
              <option value={'accountCode'}>수요기관코드</option>
              <option value={'businessNumber'}>사업자등록번호</option>
              <option value={'accountName'}>회사명</option>
            </select>
            <input
              className="SearchBar"
              placeholder="Search..."
              value={_searchText}
              onInput={(e) => {
                setSearchText(() => {
                  return e.target.value;
                });
              }}
            />
          </SearchContents>
        </MainContents>
      </AccountModal>

      <ModalFooter>
        <ModalButton onClick={actDownload}>다운로드</ModalButton>
      </ModalFooter>
    </>
  );
};

export default AccountDownloadModal;
