import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';

import { barcodeApi } from 'api/apis/barcode/barcodeApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat';

import ElementInfo from 'pages/barcode/scan/infos/info/ElementInfo';
import LotInfo from 'pages/barcode/scan/infos/info/LotInfo';
import SerialInfo from 'pages/barcode/scan/infos/info/SerialInfo';
import StockLogInfo from 'pages/barcode/scan/infos/info/StockLogInfo';

const Serial = () => {
  const { barcodeReducer } = useSelector(state => state);

  const [_elementData, setElementData] = useState({});
  
  const setElementTypeText = (elementType) => {
    let typeText = '';
    switch (elementType) {
      case 'material': typeText = '자재'; break;
      case 'semi': typeText = '부자재'; break;
      case 'half': typeText = '반제품'; break;
      case 'product': typeText = '완제품'; break;
      case 'tool': typeText = '공구'; break;

      default: return null;
    }
    return typeText;
  }
  const handleElementConData = (data) => {
    const attrValueList = checkNullArray(data.elementAttributeValueList, []).map(attr => {
      return { key: attr.elementAttribute ? attr.elementAttribute.elementAttributeName : '', value: attr.value }
    })
    const returnData = [
      { key: '패밀리', value: data.categoryPath },
      { key: '물품유형', value: setElementTypeText(data.elementType) },
      { key: '물품이름', value: data.elementName },
      { key: '물품코드', value: data.elementCode },
      { key: '재고단위', value: data.elementUnit },
      { key: '단가', value: checkEmptyNull(data.price, 0).toLocaleString() },
      ...attrValueList
    ]
    return returnData;
  }
  const [_elementConData, setElementConData] = useState([]);

  const handleStockData = (data) => {
    const stockStatus = checkEmptyNull(data.elementSafeStock, false) ? data.elementSafeStock <= data.elementStock ? 'safeStock' : 'dangerStock' : '';
    const returnData = [
      // { key: '당일입고', value: checkEmptyNull(data.todayInStock, 0).toLocaleString() },
      // { key: '당일투입', value: checkEmptyNull(data.todayPutStock, 0).toLocaleString() },
      // { key: '당일출고', value: checkEmptyNull(data.todayOutStock, 0).toLocaleString() },
      { key: '안전재고', value: checkEmptyNull(data.elementSafeStock, 0).toLocaleString() },
      { key: '투입예정수량', value: checkEmptyNull(data.scheduledInputStock, 0).toLocaleString() },
      { key: '현재고', value: checkEmptyNull(data.elementStock, 0).toLocaleString(), status: stockStatus },
    ];
    return returnData;
  }
  const [_stockData, setStockData] = useState([])

  const [_stockLogData, setStockLogData] = useState({});
  const handleLogType = (logType) => {
    switch(logType) {
      case 'In': return '입고';
      case 'Put': return '투입';
      case 'Make': return '생산';
      case 'Out': return '출고';

      default: return '수량';
    }
  }
  const handleStockLogConData = (data) => {
    const returnData = [
      { key: '입력시각', value: DateTimeFormat(checkEmptyNull(data.createdDate, '')) },
      { key: handleLogType(data.logType), value: checkEmptyNull(data.amount, 0).toLocaleString(), logType: data.logType },
      { key: '금액', value: checkEmptyNull(data.price, 0).toLocaleString() },
      { key: '재고위치', value: checkEmptyNull(data.stockPlacedName, '') },
      { key: '비고', value: checkEmptyNull(data.note, '') },
      { key: '작업자', value: checkEmptyNull(data.userName, '') },
    ];
    return returnData;
  }
  const [_stockLogConData, setStockLogConData] = useState([]);

  const [_lotData, setLotData] = useState({});
  const handleLotConData = (data) => {
    const returnData = [
      {key: '입력시각', value: DateTimeFormat(checkEmptyNull(data.createdDate, ''))},
      {key: '공정유형', value: data.processTypeName},
      {key: '공정코드', value: data.processCode},
      {key: '공정이름', value: data.processName},
      {key: '책임자', value: checkNullArray(data.managers, []).map(manager => manager.workerName).join(' / ')},
      {key: '작업자', value: checkNullArray(data.workers, []).map(worker => worker.workerName).join(' / ')},
    ];
    return returnData;
  }
  const [_lotConData, setLotConData] = useState([]);
  
  const handleProcessResultElement = (processResultElementList, processResultElementType) => {
    const returnData = checkNullArray(processResultElementList, []).filter(item => item.processResultElementType === processResultElementType);
    return returnData;
  }
  const [_processResultPutElementList, setProcessResultPutElementList] = useState([]);
  const [_processResultMakeElementList, setProcessResultMakeElementList] = useState([]);


  const [_serialData, setSerialData] = useState(barcodeReducer.barcodeContent);
  const handleSerialConData = (data) => {
    const completedAmount = checkEmptyNull(BigNumber(checkEmptyNull(data.makeAmount, 0))
    .plus(BigNumber(checkEmptyNull(data.failedAmount, 0))).toNumber(), 0).toLocaleString();

    const returnData = [
      {key: '물품코드', value: data.elementCode},
      {key: '물품이름', value: data.elementName},
      {key: '목표 생산량', value: checkEmptyNull(data.amount, 0).toLocaleString()},
      {key: '총 생산량', value: checkEmptyNull(completedAmount, 0).toLocaleString()},
      {key: '성공수량', value: checkEmptyNull(data.makeAmount, 0).toLocaleString()},
      {key: '실패수량', value: checkEmptyNull(data.failedAmount, 0).toLocaleString()},
    ];
    return returnData;
  }
  const [_serialConData, setSerialConData] = useState(handleSerialConData(barcodeReducer.barcodeContent));

  const [_currentInfo, setCurrentInfo] = useState('serial');

  useEffect(() => {
    console.log('Serial : ', barcodeReducer.barcodeContent);
    setSerialData(() => {return barcodeReducer.barcodeContent});
    setSerialConData(() => {return handleSerialConData(barcodeReducer.barcodeContent)});

    (async() => {
      await getElementData(barcodeReducer.barcodeContent.elementId);
      await getLotData(barcodeReducer.barcodeContent.processResultId);
    })();
  }, [barcodeReducer.barcodeContent])

  const getElementData = async(elementId) => {
    await barcodeApi.getElement(elementId).then(response => {
      if (response === undefined) return;
      console.log('barcodeApi.getElement : ', response);
      const elementData = response.data;
      setElementData(() => { return elementData });
      setElementConData(() => { return handleElementConData(elementData) });
      setStockData(() => { return handleStockData(elementData) });

      setStockLogData(() => {return elementData});
    })
  }
  const getLotData = async(processResultId) => {
    await barcodeApi.getLot(processResultId).then(response => {
      if (response === undefined) return;
      console.log('barcodeApi.getLot : ', response);
      const lotData = response.data;
      setLotData(() => {return lotData});
      setLotConData(() => {return handleLotConData(lotData)});

      setProcessResultPutElementList(() => { return handleProcessResultElement(lotData?.processResultElements, 'Put')});
      setProcessResultMakeElementList(() => { return handleProcessResultElement(lotData?.processResultElements, 'Make')});
    })
  }

  const handleCurrentInfo = (info) => {
    setCurrentInfo(() => { return info });
  }

  return (
    <>
      <header className='header-barcode'>
        <h2>{_serialData.elementName}<div></div>{_serialData.elementCode}</h2>
        <div className='btn-infos'>
          {[
            { info: 'element', name: '기준정보' },
            { info: 'stockLog', name: '재고상세이력' },
            { info: 'lot', name: 'Lot' },
            { info: 'serial', name: 'Serial' },
          ].map((info) => {
            return (
              <button key={info.info + '_info'}
                className={info.info === _currentInfo ? 'btn-info active' : 'btn-info'}
                onClick={() => { handleCurrentInfo(info.info) }}
              >
                {info.name}
              </button>
            )
          })}
        </div>
      </header>
      <main className='main-barcode'>
        {_currentInfo === 'element' ? <ElementInfo elementData={_elementData} elementConData={_elementConData} stockData={_stockData} /> : null}
        {_currentInfo === 'stockLog' ? <StockLogInfo stockLogData={_stockLogData} stockLogConData={_stockLogConData} handleLogType={handleLogType} /> : null}
        {_currentInfo === 'lot' ? <LotInfo lotData={_lotData} lotConData={_lotConData} putElements={_processResultPutElementList} makeElements={_processResultMakeElementList} /> : null}
        {_currentInfo === 'serial' ? <SerialInfo serialData={barcodeReducer.barcodeContent} serialConData={_serialConData} /> : null}
      </main>
    </>
  );
};

export default Serial;