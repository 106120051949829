import styled from 'styled-components';

export const OperatorHeaderComponent = styled.header`
  align-items: center;
  background-color: var(--MainNavy);
  box-shadow: 8px 8px 8px 8px #eee;
  color: var(--white);
  display: flex;
  flex-wrap: wrap;
  height: 60px;
  justify-content: space-between;
  overflow: initial;
  padding: 0 40px;
  width: 100%;
  z-index: 900;
  &::-webkit-scrollbar {display: none;}

  &>a { // 홈 버튼
    & svg {
      color: var(--white);
      height: 30px;
      margin-block-start: 5px;
      width: 30px;
    }
  }
  &>div:first-of-type { // 메뉴 섹션
    display: flex;

    & .btn-apps { // 전체 메뉴 버튼
      align-items: center;
      background-color: #13376f;
      border-radius: 8px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      margin: 10px;
      height: 40px;
      width:  40px;
      &:hover {background-color: var(--MainNavy)}

      & svg {
        color: var(--white);
        height: 24px;
        width: 24px;
      }
    }
    & .topMenu-wrap { // 상위 메뉴 섹션
      align-items: center;
      display: flex;
      gap: 24px;
      justify-content: flex-start;
      padding-left: 20px;
      width: max-content;

      @media (max-width: 1440px){gap: 16px; font-size: 1.25rem; padding-left: 10px;}
      @media (max-width: 1200px){gap: 10px; font-size: 1.25rem; padding-left: 6px;}
      @media (max-width:  980px){gap: 8px;  font-size: 1rem; padding-left: 2px;}

      Link:nth-of-type(1) div{left: 0px;}
    }
  }
  &>div:nth-of-type(2) { // 우측 섹션
    display: flex;
    gap: 16px;

    &>button:first-of-type {
      & svg {
        height: 24px;
        width: 24px;
      }
    }
    &>button:last-of-type {
      & svg {
        height: 24px;
        width: 24px;
      }
    }
  }
`;

export const TopMenu = styled.div`
  align-items: center;
  border-left: 2px solid var(--balck);
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 1.125rem;
  font-weight: 500;
  height: 60px;
  justify-content: flex-start;
  padding: 0px 10px;
  position: relative;

  ${(props) => {
    return window.location.pathname.split('/operator')[1].includes(props.page.split('/operator')[1])
      ? 'opacity: 1;'
      : 'opacity: .4;';
  }}

  &:hover {opacity: 1;}
  &.Highlight {opacity: 1;}

  @media (max-width: 1440px){padding: 0px 8px;}
  @media (max-width: 980px){padding: 0px 6px;}
`;