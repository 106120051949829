import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { jointPurchase } from 'api/apis/platform/cluster/cluster';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import Information from './content/Information';
import ItemList from './content/ItemList';

const PurchaseBoard = ({
  modalType, modalData,
  update,
  close
}) => {
  const location = useLocation();
  const clusterId = location.pathname.split('/platform/cluster')[0];
  const clusterPath = clusterId + '/platform/cluster/mypage';

  const { clusterReducer } = useSelector(state => state);
  const { user } = clusterReducer;

  const [_body, setBody] = useState({ ...modalData });
  const [_tab, setTab] = useState('info'); // 탭 설정

  const [_jointPurchaseItemList, setJointPurchaseItemList] = useState(modalData?.jointPurchaseItemList ? [...modalData?.jointPurchaseItemList] : []); // 상품 목록

  const dataKeys = [
    { key: 'title', title: '제목', },
    { key: 'targetDate', title: '마감일', },
    { key: 'manager', title: '담당자', },
    { key: 'phoneNumber', title: '연락처', },
    { key: 'detail', title: '상세 내용', },
  ];
  const tabKeys = [
    { key: 'info', title: '게시글', content: <Information modalType={modalType} dataKeys={dataKeys} body={_body} setBody={setBody} /> },
    { key: 'list', title: '상품 목록', content: <ItemList modalType={modalType} data={_body} itemList={_jointPurchaseItemList} setItemList={setJointPurchaseItemList} /> },
  ];

  useEffect(() => { }, [_body, _tab, _jointPurchaseItemList]);

  const handleTabContent = (tab) => {
    const currentTab = tabKeys.find(item => item.key === tab);
    return currentTab.content;
  };

  const actSave = async (e) => { // create/update
    e.preventDefault();
    const id = Number(clusterId.replace('/', ''));
    const body = {
      // clusterId: id,
      companyId: user.companyId,
      ..._body,
      jointPurchaseItemList: _jointPurchaseItemList,
    };

    if (!checkEmptyNull(body.title, false)) return alert('제목을 입력해주세요.');

    if (modalType.includes('create')) { // create
      await jointPurchase.create(body).then(response => {
        if (response === undefined) return;
        console.log('jointPurchase.create : ', response);
        update();
      })
    } else { // update
      delete body?.createdDate;
      delete body?.modifiedDate;

      // deleteJointPurchaseItemList 설정 - jointPurchaseItem 삭제
      const jointPurchaseItemIdList = _jointPurchaseItemList.map(item => item.jointPurchaseItemId);
      const deleteJointPurchaseItemList = modalData?.jointPurchaseItemList.filter(item => !jointPurchaseItemIdList.includes(item.jointPurchaseItemId));
      body.deleteJointPurchaseItemList = checkNullArray(deleteJointPurchaseItemList, []).map(item => item.jointPurchaseItemId);

      await jointPurchase.update(body.jointPurchaseId, body).then(response => {
        if (response === undefined) return;
        console.log('jointPurchase.update : ', response);
        update();
      })
    }
  };
  const actDelete = async () => { // delete
    const confirmText = `해당 게시글을 삭제하시겠습니까?`;
    if (window.confirm(confirmText)) {
      await jointPurchase.delete(_body.jointPurchaseId).then(response => {
        if (response === undefined) return;
        console.log('jointPurchase.delete : ', response);
        update();
      })
    }
  };

  return (
    <section className='common'>
      <nav>
        {tabKeys.map((tabKey, index) => {
          return (
            <button
              key={index + '_tab'}
              className={_tab === tabKey.key ? 'active' : ''}
              onClick={() => { setTab(() => { return tabKey.key }) }}
            >{tabKey.title}</button>
          );
        })}
      </nav>
      {handleTabContent(_tab)}
      <div className='section-action'>
        <button className='btn-cancel' onClick={close}>취소</button>
        {!modalType.endsWith('delete') ? (<button className='btn-save' onClick={actSave}>저장</button>) : (<button className='btn-delete' onClick={actDelete}>삭제</button>)}
      </div>
    </section>
  );
};

export default PurchaseBoard;