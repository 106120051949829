import React from 'react';
import styled from 'styled-components';

import { rotateRight } from 'components/icons/src';

const ViewResetButtonStyle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  font-weight: 600;
  height: 40px;
  width: 40px;
`;
const ViewResetIcon = styled.div`
  background-color: var(--MainNavy);
  height: 20px;
  width: 20px;

  mask-image: url(${rotateRight}); mask-repeat: no-repeat; mask-size: contain;
  --webkit-mask-image: url(${rotateRight}); --webkit-mask-repeat: no-repeat; --webkit-mask-size: contain;
`;

const ViewResetButton = (props) => {
  return (
    <ViewResetButtonStyle onClick={props.handleViewScale}>
      <ViewResetIcon />
      {/* <img src={rotateRight} className='icon-default' alt='화면 줌 초기화 버튼' /> */}
    </ViewResetButtonStyle>
  );
};

export default ViewResetButton;
