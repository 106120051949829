import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { equipmentAttributeApi } from 'api/apis/equipmentAttributeApi';
import { equipmentApi } from 'api/apis/equipmentApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';

const CreateEquipment = () => {
  /* ========================================================================= #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_equipmentAttributeList, setEquipmentAttributeList] = useState([]);
  const [_equipmentAttrValueList, setEquipmentAttrValueList] = useState({});

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    equipmentName: '',
    equipmentCode: '',
    status: 'waiting',
    customeAttr: '',
    equipmentLogList: [],
    equipmentAttributeValueList: [],
  });

  /* ========================================================================= #3 */
  useEffect(() => {
    getEquipmentAttributes();
    setOnload('loaded');
    return () => {};
  }, []);

  /* ========================================================================= #4 */
  const getEquipmentAttributes = async() => {
    const BodyToPost = {
      companyId: userReducer.company.companyId
    }
    await equipmentAttributeApi.searchEquipementAttribute(BodyToPost).then(response => {
      if(response === undefined) return;
      setEquipmentAttributeList(() => {return response.data});
    })
  }
  
  /* ========================================================================= #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {return {...prev, [name]:value}});
  };

  const handleAttributeValue = (e, attr, attrValue) => {
    const { value } = e.target;
    setEquipmentAttrValueList((prev) => {return { ...prev, [attr.equipmentAttributeId]: value };});
  };

  const saveForm = async (e) => {
    e.preventDefault();

    if(!checkEmptyNull(_formData.equipmentName, false)) return alert('설비이름을 입력해주세요.');

    const mapEquipmentAttrValueList = Object.keys(_equipmentAttrValueList).map(key => {
      const returnData = {
        equipmentAttributeId: Number(key),
        value: _equipmentAttrValueList[key]
      }
      return returnData;
    })
    const BodyToPost = {
      ..._formData,
      equipmentAttributeValueList: mapEquipmentAttrValueList
    };

    await equipmentApi.createEquipment(BodyToPost).then(response => {
      if(response === undefined) return;
      console.log('equipmentApi.createEquipment : ', response);
      alert('설비를 추가했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    })
  };

  /* ========================================================================= #6 */

  return (
    <Grid2Body contents={
      <>
        <main className="Main">
          <NavBar title={'설비설정'} nav={''} />

          <Form
            title={<>설비<br />추가</>}
            buttons={
              <>
                <button className="formButton" onClick={saveForm}>저장</button>
                <button className="formButton cancle"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(pageReducer.currentPage, { replace: true });
                  }}
                >
                  취소
                </button>
              </>
            }
            forms={
              <>
                <div className="formBox">
                  <h4>설비이름</h4>
                  <input
                    type="text"
                    name="equipmentName"
                    data-required="required"
                    placeholder="* 설비이름.."
                    onInput={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>설비코드</h4>
                  <input
                    type="text"
                    name="equipmentCode"
                    placeholder="설비코드..."
                    onInput={handleInputEvent}
                  />
                </div>
                
                {checkNullArray(_equipmentAttributeList, []).map((attr, index) => {
                  const equipmentAttrValueList = {..._equipmentAttrValueList};
                  const attrIndex = Object.keys(equipmentAttrValueList).findIndex(attrId => Number(attrId) === Number(attr.equipmentAttributeId))
                  if(attrIndex !== -1) {
                    const attrValue = _equipmentAttrValueList[attr.equipmentAttributeId];
                    return (
                      <div key={index + '_attrInputs'} className="formBox">
                        <h4>{attr.equipmentAttributeName}</h4>
                        <input
                          type="text"
                          placeholder={attr.equipmentAttributeName + '...'}
                          value={attrValue}
                          onInput={(e) => { handleAttributeValue(e, attr, attrValue) }}
                        />
                      </div>
                    )
                  } else {
                    return(
                      <div key={index + '_attrInputs'} className="formBox">
                        <h4>{attr.equipmentAttributeName}</h4>
                        <input
                          type="text"
                          placeholder={attr.equipmentAttributeName + '...'}
                          defaultValue=''
                          onInput={(e) => { handleAttributeValue(e, attr) }}
                        />
                      </div>
                    )
                  }
                })}
              </>
            }
          />
        </main>
      </>
    }
    />
  );
};

export default CreateEquipment;
