import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { modalFileActions_setFileCurrentPage } from 'store/modules/actions/modal/modalFileActions';

import { attachFileApi } from 'api/apis/attachFileApi';
import { checkEmptyNull, checkNullObject } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import File from 'pages/file/assets/File';
import PagingComponent from 'components/paging/PagingComponent';
import SelectFiles from 'pages/file/modal/SelectFiles';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  height: 80%;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const ModalHeaderButtons = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 55px;
`;
const ModalHeaderButton = styled.button``;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const FileSection = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 60% auto;
  height: calc(100% - 55px);
  overflow: hidden;
  width: 100%;
`;
const FileList = styled.div`
  box-sizing: border-box;
  display: grid;
  gap: 10px;
  grid-template-rows: 50px auto 50px;
  height: 100%;
  overflow: hidden;
  padding: 0 8px;
  
  & .TableSection {
    height: 100%;
    tbody tr:hover {cursor: pointer;}
    & .fileButtons {min-width: 200px;}
  }
`;
const FileButtons = styled.div`
  align-items: center;
  column-gap: 5px;
  display: grid;
  grid-template-columns: 40% 60%;
  justify-content: center;
  width: 100%;
  zoom: 80%;

  & button {
    background: unset;
    color: var(--white);
    margin: 0px auto;

    &[data-type='다운로드'] {
      background-color: var(--ThirdGreen);
      padding: 8px;
      border-radius: 8px;
      font-size: 1rem;
    }
    &[data-type='수정'] {
      background-color: var(--MainNavy);
      /* border-bottom: 3px solid var(--MainNavy); */
    }
    &[data-type='수정']:hover {
      background: unset;
      background-color: var(--MainNavy);
    }
    &[data-type='삭제'] {
      background-color: var(--ThirdRed);
      border-bottom: 3px solid var(--ThirdRed);
      padding: 8px;
      border-radius: 8px;
      font-size: 1rem;
    }
    &[data-type='삭제']:hover {
      background: unset;
      background-color: var(--ThirdRed);
    }
  }
`;

const Preview = styled.div`
  box-sizing: border-box;
  padding-inline: 10px 20px;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}

  img {width: 100%;}
`;

const ShowFileList = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const { type, content } = props;

  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_fileList, setFileList] = useState([]);

  const [_currentFile, setCurrentFile] = useState({});

  const [_fileModalStatus, setFileModalStatus] = useState(false);

  const [_page, setPage] = useState(1);
  const [_pageTotalSize, setPageTotalSize] = useState(0);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('109') === true,
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    getFileList(_page - 1);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') getFileList(_page - 1);
    return () => { };
  }, [_page]);

  useEffect(() => {
    setCurrentFile(() => { return {} });
    return () => { };
  }, [_fileList]);

  /* ====================================================================== #4 */
  const getFileList = async (page) => {
    const paging = `?page=${page}&size=10`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    switch (type) {
      case 'element': BodyToPost.elementId = content.elementId; break;
      case 'elementStockLog': BodyToPost.elementStockLogId = content.elementStockLogId; break;
      case 'process': BodyToPost.processId = content.processId; break;
      case 'mallElement': BodyToPost.mallElementId = content.mallElementId; break;
      case 'processLog': BodyToPost.processLogId = content.processLogId; break;
      case 'workOrder': BodyToPost.workOrderId = content.workOrderId; break;
      case 'workOrderLog': BodyToPost.workOrderLogId = content.workOrderLogId; break;

      default: return;
    }
    console.log('getFileList : ', BodyToPost);

    await attachFileApi.searchAttachFile(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('attachFileApi.searchAttachFile : ', response);

      setFileList(() => { return response.data.content });
      setPageTotalSize(() => { return response.data.totalElements });
    });
  };

  /* ====================================================================== #5 */
  const handleDownload = (file) => {
    const element = document.createElement('a');
    element.href = file.fileDataS3URL;
    element.download = file.fileDataName;
    element.target = '_blank';
    element.click();
  };

  const actDelete = async (index, eventContent) => {
    console.log('actDelete : ', index, eventContent);

    const deleteText = `
파일을 삭제하시겠습니까?
파일 관리에서는 삭제되지 않습니다.
    `;

    if (window.confirm(deleteText)) {
      await attachFileApi.deleteAttachFile(eventContent.attachFileId).then((response) => {
        if (response === undefined) return;
        console.log('attachFileApi.deleteAttachFile : ', response);

        setFileList((prev) => {
          const prevData = [...prev];
          prevData.splice(index, 1);
          return prevData;
        });

        alert('파일이 삭제되었습니다.');
      });
    }
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>파일</ModalTitle>
              <ModalHeaderButtons>
                <ModalHeaderButton className="formButton"
                  onClick={() => {
                    dispatch(modalFileActions_setFileCurrentPage(1));
                    setTimeout(setFileModalStatus(true), 1000);
                  }}
                >
                  파일 선택
                </ModalHeaderButton>
              </ModalHeaderButtons>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <FileSection>
              <FileList>
                <p className='file-title'>파일 목록</p>
                <TableSection content={
                  <table>
                    <thead>
                      <tr>
                        <th>경로</th>
                        <th>파일이름</th>
                        <th style={{ minWidth: '150px', width: '150px' }}>파일유형</th>
                        {_authority.indexOf('109-2') !== -1 && <th style={{ minWidth: '100px', width: '100px' }}></th>}
                      </tr>
                    </thead>
                    <tbody>
                      {_fileList.map((thisItem, index) => {
                        let fileData = { ...thisItem };
                        if (checkNullObject(thisItem, false) && checkNullObject(thisItem.fileData, false)) {
                          fileData = { ...thisItem, ...thisItem.fileData };
                        }

                        return (<tr key={index + '_files'} onClick={() => { setCurrentFile(() => { return fileData }) }}>
                          <td>{userReducer.company.companyName + '/' + checkEmptyNull(fileData.fileCategoryPath, '')}</td>
                          <td><File content={fileData} /></td>
                          <td style={{ minWidth: '150px', width: '150px' }}>{checkEmptyNull(fileData.fileDataType, '')}</td>
                          {_authority.indexOf('109-2') !== -1 && (
                            <td className='fileButtons'>
                              <FileButtons>
                                <button data-type="삭제" onClick={(e) => { e.stopPropagation(); actDelete(index, fileData); }}>삭제</button>
                                <button data-type="다운로드" onClick={(e) => { e.stopPropagation(); handleDownload(_currentFile); }}>다운로드</button>
                              </FileButtons>
                            </td>
                          )}
                        </tr>
                        );
                      })}
                    </tbody>
                  </table>
                } />
                <PagingComponent
                  page={_page}
                  count={_pageTotalSize}
                  size={10}
                  pageEvent={(page) => { setPage(() => { return page }) }}
                />
              </FileList>

              <Preview>
                <p className='file-title'>파일 미리보기</p>
                <div className='file-img-preview'>
                  {(() => {
                    if (checkNullObject(_currentFile, false)) {
                      return (<img src={_currentFile.fileDataS3URL} alt={_currentFile.fileDataName} />);
                    }
                  })()}
                </div>
              </Preview>
            </FileSection>
          </ModalSection>
        </ModalBg>
      )}

      {_fileModalStatus === true && (
        <SelectFiles
          buttonTitle={'파일 선택'}
          buttonEvent={async (data) => {
            console.log('buttonEvent : ', data);
            console.log('_fileList : ', _fileList);

            const newDataList = data.filter((thisItem) => _fileList.findIndex((thisIndex) => thisIndex.fileDataId === thisItem.fileDataId) === -1);
            console.log('newDataList : ', newDataList);

            newDataList.forEach(async (file, index) => {
              const BodyToFilePost = {
                companyId: userReducer.company.companyId,
                fileDataId: file.fileDataId,
              };
              switch (type) {
                case 'element': BodyToFilePost.elementId = content.elementId; break;
                case 'elementStockLog': BodyToFilePost.elementStockLogId = content.elementStockLogId; break;
                case 'mallElement': BodyToFilePost.mallElementId = content.mallElementId; break;
                case 'process': BodyToFilePost.processId = content.processId; break;
                case 'processLog': BodyToFilePost.processLogId = content.processLogId; break;
                case 'workOrder': BodyToFilePost.workOrderId = content.workOrderId; break;
                case 'workOrderLog': BodyToFilePost.workOrderLogId = content.workOrderLogId; break;

                default: return;
              }
              console.log('BodyToFilePost : ', BodyToFilePost);

              await attachFileApi.createAttachFile(BodyToFilePost).then((response) => {
                if (response === undefined) return;
                console.log('attachFileApi.createAttachFile : ', response);
                getFileList(_page - 1);
              });

              if (index === newDataList.length - 1) setTimeout(setFileModalStatus(false), 1000);
            });
            if (newDataList.length === 0) setTimeout(setFileModalStatus(false), 1000);
          }}
          open={_fileModalStatus}
          close={() => { setFileModalStatus(false); }}
        />
      )}
    </>
  );
};

export default ShowFileList;
