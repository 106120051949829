const initialState = {
  folderList: [],
  folderTreeList: {
    name: 'root',
    active: true,
    toggled: true,
    root: true,
    children: [],
  },
  currentFolder: {},
  deleteFolder: {},

  fileList: [],

  search: {
    fileDataName: '',
  },
  currentPage: 1,
  totalSize: 0,
};

const fileReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETFOLDERLIST':
      return {
        ...state,
        folderList: action.payload,
      };

    case 'SETFOLDERTREELIST':
      return {
        ...state,
        folderTreeList: action.payload,
      };

    case 'SETCURRENTFOLDER':
      return {
        ...state,
        currentFolder: action.payload,
      };

    case 'SETDELETEFOLDER':
      return {
        ...state,
        deleteFolder: action.payload,
      };

    case 'SETFILELIST':
      return {
        ...state,
        fileList: action.payload,
      };

    case 'SETFILESEARCH':
      return {
        ...state,
        search: action.payload,
      };

    case 'SETFILECURRENTPAGE':
      return {
        ...state,
        currentPage: action.payload,
      };

    case 'SETFILETOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };

    case 'SETFILERESET':
      return initialState;

    default:
      return state;
  }
};

export default fileReducer;
