import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';

import { attachFileApi } from 'api/apis/attachFileApi';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

/* Form */
import UploadForm from 'pages/productionOrder/custom/company/uri/crud/components/UploadForm';
import ProcessForm from 'pages/productionOrder/custom/company/uri/crud/components/ProcessForm';
import InfoForm from 'pages/productionOrder/custom/company/uri/crud/components/InfoForm';

const ProductionOrderSection = styled.main`
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 66px calc(100% - 66px);
`;
const FormSection = styled.main``;

const CreateUriProductionOrder = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_contentType, setContentType] = useState('upload');

  const [_checkId, setCheckId] = useState(0);

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    userId: userReducer.user.id,
    user: { ...userReducer.user },

    workOrderTypeId: '',

    workOrderStatus: 'work',
    workOrderName: '',
    workOrderCode: '',

    workOrderScheduledStartDate: moment(new Date()).format('YYYY-MM-DD'),
    workOrderScheduledEndDate: '',

    accountId: null, // 거래처
    account: {},

    workPlace: '', // 주소
    // detailedWorkPlace: '', // 현장명

    // message: '', // 작업자 알림
    remark: '', // 비고

    workOrderCustomizedContent: {
      BF: { date: '', amount: '' },
      SF: { date: '', amount: '' },
      MF: { date: '', amount: '' },
      total: { date: '', amount: '' },

      // workOrderProducts: '', // 품목
      colors: '', // 색상
      phoneNumber: '', // 전화번호
    },

    processLogList: [],
  });

  const [_processLogList, setProcessLogList] = useState([]);

  /* ====================================================================== #3 */
  useEffect(() => { console.log('_processLogList : ', _processLogList); }, [_processLogList]);

  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const handleContentType = async (buttonType, contentType) => {
    switch (contentType) {
      case 'upload':
        if (buttonType === 'next') {
          await goProcess().then((result) => {
            if (result) setContentType(() => { return 'process' });
          }).catch(error => {
            console.log('error : ', error);
            const alertText = `
업로드 할 수 없는 형식입니다.
해당 파일을 첨부하여 문의해주세요.
            `;
            return alert(alertText);
          });
        }
        return;
      case 'process':
        if (buttonType === 'prev') setContentType(() => { return 'upload' });
        if (buttonType === 'next') setContentType(() => { return 'info' });
        return;
      case 'info':
        if (buttonType === 'prev') setContentType(() => { return 'process' });
        return;

      default: return;
    }
  }

  /* UploadForm */
  const goProcess = () => {
    return new Promise((resolve, reject) => {
      const table = document.getElementById('uploadFileTable');
      // console.log(table);
      if (table.innerHTML === '') {
        if (checkNullArray(_processLogList, false)) {
          return resolve(true);
        } else {
          alert('작업지시를 업로드해주세요.');
          return resolve(false);
        }
      }
      table.querySelectorAll('tbody').forEach((tbody) => {
        if (tbody.rows[0].cells[0].innerText.toUpperCase() !== 'NO') tbody.remove();
      });
      table.querySelectorAll('tr').forEach(async (rowData, index) => {
        // 필요없는 데이터 줄 삭제
        if (rowData.innerHTML === '' || rowData.cells.length === 0 || rowData.children.length === 0 || rowData.cells[0] === undefined) return rowData.remove();
        if (rowData.cells[0].innerText === '생 산 지 시 서') {
          const prevRow = table.querySelectorAll('tr')[rowData.rowIndex - 1];
          if (prevRow !== undefined) return rowData.remove();
          return rowData.remove();
        }
        if (rowData.cells[0].innerText === '거래처') return rowData.remove();
        if (rowData.cells[0].innerText === '현장') return rowData.remove();
        if (rowData.cells[0].innerText === 'NO') return rowData.remove();

        const rowSpan = table.querySelectorAll('tr')[0].cells[0].rowSpan;
        const result = await setProcessData(rowSpan, table);
        if (result) return resolve(true);
        else return reject(new Error());
      })
    })
  }
  const setProcessData = (rowSpan, table) => {
    return new Promise((resolve) => {
      let typeNums = [0, 1, 2];
      let seperateNum = 3;

      switch (rowSpan) {
        case 4: typeNums = [0, 1, 3]; seperateNum = 4; break;
        default: typeNums = [0, 1, 2]; seperateNum = 3; break;
      }

      table.querySelectorAll('tr').forEach((rowData) => {
        if (rowData.cells.length === 0) rowData.remove();
        if (rowData.cells[0]?.innerText === 'NO') rowData.remove();
      });
      table.querySelectorAll('tr').forEach((rowData) => {
        if (rowData.rowIndex % seperateNum === 0) {
          if (!checkEmptyNull(rowData.cells[0]?.innerHTML, false)) {
            let check = true;
            if (checkEmptyNull(rowData.cells[1]?.innerHTML, false)) return check = false;
            if (check) rowData.remove();
          }
        }
      });

      let processLogList = [];
      let processLogData = {
        existProcessOutElement: false,
        processingCount: '',

        processCustomizedContent: {
          no: '', // 순번
          numStatus: 'white', // NO 컬러
          bundle: '', // 몇 짝
          bundleSize: '', //
          materials: '', // 자재
          materialStatus: 'normal', // 자재 글꼴
          colors: '', // 색상
          colorStatus: 'white', // 색상 컬러
          glassType_W: '', // 유리종류_W
          glassType_H: '', // 유리종류_H
          remark: '', // 비고
        },

        attachFileList: [],
      };

      let taskLogList = [];
      const taskTypeArray = ['BF', 'CP', 'SF', 'MF', 'GB', 'MC'];
      let processNumberCount = 0;
      table.querySelectorAll('tr').forEach((rowData, index) => {
        switch (rowData.rowIndex % seperateNum) {
          case typeNums[0]:
            processNumberCount++;
            const processNumber = processNumberCount.toString().padStart(2, '0');
            processLogData = {
              existProcessOutElement: false,
              processingCount: '',

              processCustomizedContent: {
                no: processNumber, // 순번
                numStatus: 'white', // NO 컬러
                bundle: '', // 몇 짝
                bundleSize: '', //
                materials: '', // 자재
                materialStatus: 'normal', // 자재 글꼴
                colors: '', // 색상
                colorStatus: 'white', // 색상 컬러
                glassType_W: '', // 유리종류_W
                glassType_H: '', // 유리종류_H
                remark: '', // 비고
              },

              attachFileList: [],
            };

            let bundle = '';
            if (rowData.cells[1]) {
              if (rowData.cells[1].querySelector('span') !== null && rowData.cells[1].querySelector('span') !== undefined) {
                bundle = rowData.cells[1].querySelector('span').innerText;
              } else {
                bundle = rowData.cells[1].innerText;
              }
            }
            processLogData.processCustomizedContent.bundle = bundle;

            let processingCount = '';
            if (rowData.cells[2]) {
              if (rowData.cells[2].querySelector('span') !== null && rowData.cells[2].querySelector('span') !== undefined) {
                processingCount = rowData.cells[2].querySelector('span').innerText;
              } else {
                processingCount = rowData.cells[2].innerText;
              }
            }
            processLogData.processingCount = processingCount;

            let materials = '';
            if (rowData.cells[3]) {
              if (rowData.cells[3].querySelector('span') !== null && rowData.cells[3].querySelector('span') !== undefined) {
                rowData.cells[3].querySelectorAll('span').forEach((span) => {
                  const materialsCell = span.innerText;
                  materials = materials + materialsCell + ' ';
                });
              } else {
                const materialsCell = rowData.cells[3].innerText;
                materialsCell.split(' ').forEach((item) => {
                  materials = materials + item + ' ';
                });
              }
            }
            processLogData.processCustomizedContent.materials = materials?.replace(/\n/g, ' ').replace('  ', ' ');

            let colors = '';
            if (rowData.cells[4]) {
              if (rowData.cells[4].querySelector('span') !== null && rowData.cells[4].querySelector('span') !== undefined) {
                rowData.cells[4].querySelectorAll('span').forEach((span) => {
                  const colorsCell = span.innerText;
                  colors = colors + colorsCell + ' ';
                });
              } else {
                const colorsCell = rowData.cells[4].innerText;
                colorsCell.split(' ').forEach((item) => {
                  colors = colors + item + ' ';
                });
              }
            }
            processLogData.processCustomizedContent.colors = colors?.replace(/\n/g, ' ').replace('  ', ' ');

            let taskCount_0 = 5;
            taskTypeArray.forEach((taskType, taskIndex) => {
              if (rowData.cells[taskCount_0]?.querySelector('span') !== null && rowData.cells[taskCount_0]?.querySelector('span') !== undefined) {
                rowData.cells[taskCount_0].querySelectorAll('span').forEach((span) => {
                  let taskName = span.innerText;
                  if (checkEmptyNull(taskName, false)) {
                    const taskResult = { taskType: taskType + '_W' };
                    taskResult.taskName = taskName;
                    taskLogList.push(taskResult);
                  }
                });
              } else {
                const tasks = rowData.cells[taskCount_0]?.innerText.split('\n');
                for (let i = 0; i < tasks?.length; i++) {
                  if (checkEmptyNull(tasks[i], false)) {
                    const taskResult = { taskType: taskType + '_W' };
                    taskResult.taskName = tasks[i];
                    taskLogList.push(taskResult);
                  }
                }
              }
              taskCount_0++;
            })
            break;

          case typeNums[1]:
            let bundleSize = '';
            if (rowData.cells[0]) {
              if (rowData.cells[0].querySelector('span') !== null && rowData.cells[0].querySelector('span') !== undefined) {
                bundleSize = rowData.cells[0].querySelector('span').innerText;
              } else {
                bundleSize = rowData.cells[0].innerText;
              }
            }
            processLogData.processCustomizedContent.bundleSize = bundleSize;

            break;

          case typeNums[2]:
            let taskCount_3 = seperateNum === 3 ? rowData.cells.length === 6 ? 0 : 1 : 0;
            taskTypeArray.forEach((taskType, taskIndex) => {
              if (rowData.cells[taskCount_3]?.querySelector('span') !== null && rowData.cells[taskCount_3]?.querySelector('span') !== undefined) {
                rowData.cells[taskCount_3].querySelectorAll('span').forEach((span) => {
                  let taskName = span.innerText;
                  if (checkEmptyNull(taskName, false)) {
                    const taskResult = { taskType: taskType + '_H' };
                    taskResult.taskName = taskName;
                    taskLogList.push(taskResult);
                  }
                });
              } else {
                const tasks = rowData.cells[taskCount_3]?.innerText.split('\n');
                for (let i = 0; i < tasks?.length; i++) {
                  if (checkEmptyNull(tasks[i], false)) {
                    const taskResult = { taskType: taskType + '_H' };
                    taskResult.taskName = tasks[i];
                    taskLogList.push(taskResult);
                  }
                }
              }
              taskCount_3++;
            })

            let checkCount_3 = seperateNum === 3 ? rowData.cells.length === 6 ? 5 : 6 : 5;
            if (taskCount_3 > checkCount_3) {
              processLogData.taskLogList = taskLogList;
              processLogList.push(processLogData);
              taskLogList = [];

              if (index === table.querySelectorAll('tr').length - 1) {
                setProcessLogList(() => { return processLogList })
                resolve(true);
              }
            }
            break;

          default: return;
        }
      })
    })
  }

  /* ProcessForm */
  let checkId = 0;
  const actCreateProcess = () => {
    setCheckId((prev) => { return prev + 1; });
    setProcessLogList(prev => {
      const returnData = [...prev];
      const newData = {
        key: _checkId,
        existProcessOutElement: false,
        processingCount: '',

        processCustomizedContent: {
          no: '', // 순번
          numStatus: 'white', // NO 컬러
          bundle: '', // 몇 짝
          bundleSize: '', //
          materials: '', // 자재
          materialStatus: 'normal', // 자재 글꼴
          colors: '', // 색상
          colorStatus: 'white', // 색상 컬러
          glassType_W: '', // 유리종류_W
          glassType_H: '', // 유리종류_H
          remark: '', // 비고
        },

        attachFileList: [],
      };
      returnData.push(newData);
      return returnData;
    })
  }

  /* 작업지시 추가 */
  const actCreate = async () => {
    const BodyToPost = {
      ..._formData,
      workOrderCustomizedContent: JSON.stringify(_formData.workOrderCustomizedContent),
      processLogList: _processLogList.map(processLog => {
        return {
          ...processLog,
          processAmount: 0, managers: [], workers: [],
          processCustomizedContent: JSON.stringify(processLog.processCustomizedContent),
        }
      })
    }
    console.log('BodyToPost : ', BodyToPost);
    await workOrderLogApi.createWorkOrderLog(BodyToPost).then(response => {
      if (response === undefined) return;
      console.log('workOrderLogApi.createWorkOrderLog : ', response);
      response.data.processLogList.forEach(async (processLog, index) => {
        const postProcessLog = _processLogList[index];
        if (!checkNullObject(postProcessLog?.fileData, false)) return;
        const BodyToAttach = {
          companyId: userReducer.company.companyId,
          fileDataId: postProcessLog?.fileData.fileDataId,
          processLogId: processLog.processLogId,
        }
        console.log('BodyToAttach : ', BodyToAttach);
        await attachFileApi.createAttachFile(BodyToAttach).then(fileResponse => {
          if (fileResponse === undefined) return;
          console.log('attachFileApi.createAttachFile : ', response);
        })
      })
    }).then(() => {
      alert(`작업지시를 추가했습니다.`);
      setTimeout(navigate(pageReducer.currentPage, { replace: true }), 1000);
    })
  }

  /* ====================================================================== #6 */

  return (
    <Grid2Body contents={
      <>
        <ProductionOrderSection>
          <NavBar
            title={<><NavTitle menuCode={'403'} /></>}
            buttons={
              <>

                {_contentType === 'upload' ? (
                  <button className='btn-inven-1'
                    style={{ backgroundColor: 'var(--MainNavy)', color: 'var(--white)' }}
                    onClick={(e) => {
                      e.preventDefault();
                      document.getElementById('uploadFileInput').value = '';
                      document.getElementById('uploadFileTable').innerHTML = '';
                      setProcessLogList(() => { return [] });
                    }}
                  >
                    작업지시 초기화
                  </button>
                ) : null}
                {_contentType === 'process' ? (
                  <button className='btn-inven-1'
                    style={{ backgroundColor: 'var(--MainNavy)', color: 'var(--white)' }}
                    onClick={(e) => { e.preventDefault(); actCreateProcess(); }}
                  >
                    공정추가
                  </button>
                ) : null}

                {_contentType !== 'upload' ? (
                  <button className='btn-inven-1'
                    onClick={(e) => { e.preventDefault(); handleContentType('prev', _contentType) }}
                  >
                    이전
                  </button>
                ) : null}
                {_contentType !== 'info' ? (
                  <button className='btn-inven-1'
                    onClick={(e) => { e.preventDefault(); handleContentType('next', _contentType) }}
                  >
                    다음
                  </button>
                ) : null}
                {_contentType === 'info' ? (
                  <button className='btn-inven-1'
                    style={{ backgroundColor: 'var(--MainNavy)', color: 'var(--white)' }}
                    onClick={(e) => { e.preventDefault(); actCreate(); }}
                  >
                    저장
                  </button>
                ) : null}
                <button className='btn-set'
                  style={{ /* backgroundColor: 'unset', boxShadow: 'unset', */ marginLeft: '15px' }}
                  onClick={(e) => { e.preventDefault(); navigate(pageReducer.currentPage, { replace: true }); }}
                >
                  취소
                </button>
              </>
            }
            nav={''}
          />

          <FormSection>
            {_contentType === 'upload' ? (<UploadForm processLogList={_processLogList} setProcessLogList={setProcessLogList} />) : null}
            {_contentType === 'process' ? (<ProcessForm processLogList={_processLogList} setProcessLogList={setProcessLogList} />) : null}
            {_contentType === 'info' ? (<InfoForm processLogList={_processLogList} formData={_formData} setFormData={setFormData} />) : null}
          </FormSection>
        </ProductionOrderSection>
      </>
    }
    />
  );
};

export default CreateUriProductionOrder;
