const initialState = {
  user: {},
};

const mallSiteUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETMALLSITEUSER': return { ...state, user: action.payload };

    default: return state;
  }
};

export default mallSiteUserReducer;
