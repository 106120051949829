import styled from "styled-components";

export const ModuleFormComponent = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;

  & section {
    background-color: var(--white);
    border-radius: 10px;
    display: grid;
    grid-template-rows: 55px auto 50px;
    height: 85%;
    max-width: 1200px;
    overflow: hidden;
    width: 85%;

    & header {
      align-items: center;
      background-color: var(--gray-100);
      box-sizing: border-box;
      display: flex;
      height: 55px;
      justify-content: flex-start;
      padding: 0px 20px;
      position: relative;
      width: 100%;

      h4 {
        font-size: 20px;
        font-weight: 400;
        white-space: nowrap;
      }
      & .btn-close {
        height: 25px;
        position: absolute;
        right: 15px;
        top: 15px;
        width: 25px;
      }
    }

    &>div:nth-of-type(1) {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      
      & form > div {
        background-color: unset;

        &>div:first-of-type {
          input {
            max-width: 300px;
          }
        }
        &>div:nth-of-type(2) {
          width: 100%;
        }
      }
      & .companyPageCodeContainer {
        display: grid;
        gap: 30px;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-template-rows: auto;
        place-content: unset;
        width: 100%;

        & .companyPageCodeItem {
          align-items: center;
          border: 1px solid #eee;
          border-radius: 8px;
          box-sizing: border-box;
          cursor: pointer;
          display: flex;
          gap: 10px;
          justify-content: flex-start;
          padding: 10px;

          & > input {
            height: 15px;
            margin: unset;
            width: 40px;
          }
        }
      }
    }

    &>div:last-of-type {
      align-items: center;
      display: flex;
      height: 50px;
      justify-content: center;
      width: 100%;

      & div {
        align-items: center;
        background-color: var(--MainNavy);
        color: var(--white);
        cursor: pointer;
        display: flex;
        height: 50px;
        justify-content: center;
        width: 100%;
      }
    }
  }
`;