import React, { useEffect, useState } from 'react';

const RequestEquipmentDetail = ({
  modalType, modalData,
  update,
  close
}) => {
  const [_body, setBody] = useState({ ...modalData });

  const dataKeys = [
    // { key: 'title', title: '제목', },
    { key: 'term', title: '기간', },
    { key: 'price', title: '금액', },
    { key: 'manager', title: '담당자', },
    { key: 'phoneNumber', title: '연락처', },
    { key: 'address', title: '주소', },
    { key: 'detail', title: '상세 내용', },
  ];

  useEffect(() => { }, [_body]);

  return (
    <section>
      <div className='section-form'>
        <div className='form-content'>
          <h4>장비 유형</h4>
          <p>{_body.equipmentSharingTypeName || ''}</p>
        </div>

        {dataKeys.map((dataKey, index) => {
          return (
            <div key={index + '_form-content'} className='form-content term'>
              <h4>{dataKey.title}</h4>
              {dataKey.key === 'term' ? ( // 기간
                <div>
                  <p className='date'>{_body.sharingStartDate || ''}</p>
                  <span>-</span>
                  <p className='date'>{_body.sharingEndDate || ''}</p>
                </div>
              ) : dataKey.key === 'price' ? (<>
                <input
                  type='text'
                  readOnly
                  value={_body[dataKey.key]?.toLocaleString() || ''}
                />
              </>) : dataKey.key === 'detail' ? (<>
                <textarea
                  readOnly
                  defaultValue={_body[dataKey.key] || ''}
                />
              </>) : (<>
                <input
                  type='text'
                  readOnly
                  value={_body[dataKey.key] || ''}
                />
              </>)}
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default RequestEquipmentDetail;