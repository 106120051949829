import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { monitoringActions_setMonitoringView } from 'store/modules/actions/common/monitoringActions';

const MonitoringComponentFloting = ({authority, clickHeader, setTimeCounter}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [_floatingMenu, setFloatingMenu] = useState(false);
  const [_onHeader, setOnHeader] = useState(false);

  const floatingBtn = (e) => {
    e.preventDefault();
    setFloatingMenu((prev) => {return !prev;});
  }
  const handleMonitoringView = (viewType) => {
    setTimeCounter(() => {return 30});
    dispatch(monitoringActions_setMonitoringView(viewType));
    navigate(`/monitoring/${viewType}`, {replace: true});
  }

  return (
    <>
      {_floatingMenu ? (
        <div className='drop-items'>
          <button onClick={clickHeader} data-name='on'>전체메뉴 {_onHeader ? '닫기' : '열기'}</button>
          <hr />
          <p onClick={(e) => {e.preventDefault(); handleMonitoringView('total')}}>전체보기</p>
          <p onClick={(e) => {e.preventDefault(); handleMonitoringView('material')}}>재고현황</p>
          {(authority.indexOf('403-1') !== -1 || authority.indexOf('403-2') !== -1) ? (
            <p onClick={(e) => {e.preventDefault(); handleMonitoringView('production')}}>작업현황</p>
          ) : null}
          {(authority.indexOf('601-1') !== -1 || authority.indexOf('601-2') !== -1) ? (
            <p onClick={(e) => {e.preventDefault(); handleMonitoringView('quality')}}>불량현황</p>
          ) : null}
          <hr />
          <button onClick={floatingBtn}>닫기</button>
        </div>
      ): (
        <div className='drop-items' style={{padding: '16px 12px', borderRadius: '38px'}}>
          <button onClick={floatingBtn}>메뉴</button>
        </div>
      )}
    </>
  )
}

export default MonitoringComponentFloting;