import React from 'react';
import { Container, Title, Table, Tbody, Td, Th, Thead, Tr } from './Tab.style';

export default function ContractItemsTab({ data }) {
  const formatNum = (num) =>
    num != null ? new Intl.NumberFormat().format(num) : '-';

  return (
    <Container className="contract">
      <Title>수주 물품</Title>
      {data.length > 0 ? (
        data.map((contract, index) =>
          contract.contractElementList &&
          contract.contractElementList.length > 0 ? (
            <Table key={index}>
              <Thead>
                <Tr>
                  <Th><strong>물품이름</strong></Th>
                  <Th><strong>물품코드</strong></Th>
                  <Th><strong>수량</strong></Th>
                  <Th><strong>금액</strong></Th>
                </Tr>
              </Thead>
              <Tbody>
                {contract.contractElementList.map((element, elementIndex) => (
                  <Tr key={elementIndex}>
                    <Td>{element.elementName ?? '-'}</Td>
                    <Td>{element.elementCode ?? '-'}</Td>
                    <Td style={{ textAlign: 'right' }}>
                      {formatNum(element.quantity)}
                    </Td>
                    <Td style={{ textAlign: 'right' }}>
                      {formatNum(element.price)}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <p key={index}>수주 물품정보가 없습니다.</p>
          ),
        )
      ) : (
        <p>수주 데이터가 없습니다.</p>
      )}
    </Container>
  );
};
