import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import StatusTdButton from 'components/buttons/StatusTdButton';
import DetailViewButton from 'components/buttons/DetailViewButton';

const ListBox = styled.div`
  display: grid;
  justify-content: center;
`;
const ListButton = styled.div`
  background-color: ${(props) => {
    switch (props.type) {
      case 'inStock': return 'var(--MainGreen)';
      case 'notInStock': return 'var(--MainRed)';
      default: return null;
    }
  }};
  border-radius: 16px;
  box-shadow: 0 4px 18px 0 rgba(196,196,196,0.8);
  box-shadow: ${(props) => {
    switch (props.type) {
      case 'print': return 'inherit';
      default: return null;
    }
  }};
  color: var(--white);
  cursor: pointer;
  margin: 8px;
  padding: 8px;
  vertical-align: middle;
  
  &:hover {opacity: .7;}
`;
const WorkerStatusButton = styled.td`
  background-color: var(--ThirdRed);
  color: white;
  cursor: pointer;
  font-size: 1em;
  min-width: 60px;
  width: 60px;
`;

const ProcurementRow = (props) => {
  /* ========================================================================= #1 */
  /* ========================================================================= #2 */
  const [procurementData, setprocurementData] = useState(props.content);
  const [procurementElements, setProcurementElements] = useState(props.content.procurementElementList);

  const [_rowLength, setRowLength] = useState(procurementElements.length);

  const [checkedItem, setCheckedItem] = useState(props.checkedItem);

  /* ========================================================================= #3 */
  useEffect(() => {
    setprocurementData(() => { return props.content });
    setProcurementElements(() => { return props.content.procurementElementList });

    setRowLength(props.content.procurementElementList.length);

    setCheckedItem(() => { return props.checkedItem });

    return () => { }
  }, [props]);

  /* ========================================================================= #4 */
  /* ========================================================================= #5 */
  /* ========================================================================= #6 */
  const returnElements = () => {
    let returnArray = [];
    for (let i = 1; i < _rowLength; i++) {
      returnArray.push(
        <tr key={i + '_procurementElements'}>
          {procurementElements[i] !== undefined ? (
            <Fragment>
              <td>
                {checkNullObject(procurementElements[i].element, false) ? checkEmptyNull(procurementElements[i].element.elementName, '') : checkEmptyNull(procurementElements[i].elementName, '')}
                <tr />
                ({checkNullObject(procurementElements[i].element, false) ? checkEmptyNull(procurementElements[i].element.elementCode, '') : checkEmptyNull(procurementElements[i].elementCode, '')})
              </td>
              <td>{checkEmptyNull(procurementElements[i].quantity, 0).toLocaleString()}</td>
              <td>{checkNullObject(procurementElements[i].element, false) ? checkEmptyNull(procurementElements[i].element.price, 0).toLocaleString() : checkEmptyNull(procurementElements[i].price, 0).toLocaleString()}</td>
            </Fragment>
          ) : (
            <Fragment>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
            </Fragment>
          )}
        </tr>,
      );
    }

    return returnArray;
  };

  return (
    <Fragment>
      <tr name={props.authority.indexOf('202-2') !== -1 && '_procurementRows'}>
        {props.authority.indexOf('202-2') !== -1 && !props.hideCheckbox ? (
          <td
            rowSpan={_rowLength === 0 ? 1 : _rowLength}
            style={{ minWidth: '50px', width: '50px' }}
          >
            <input
              type="checkBox"
              name="procurements"
              data-key={procurementData.procurementId}
              checked={(() => {
                const checkedIndex = checkNullArray(checkedItem, false)
                  ? Array.from(checkedItem).findIndex(
                    (procurement) =>
                      procurement.procurementId ===
                      procurementData.procurementId,
                  )
                  : -1;
                return checkedIndex !== -1;
              })()}
              onChange={(e) => {
                const isChecked = e.target.checked;
                props.handleCheckedItem(procurementData, isChecked);
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </td>
        ) : null}
        <StatusTdButton
          rowSpan={_rowLength === 0 ? 1 : _rowLength}
          statusStyle={{ minWidth: '80px', width: '80px' }}
          status={procurementData.procurementStatus}
          statusEvent={(e) => {
            e.stopPropagation();
            props.handleProcurementStatus(procurementData, props.index);
          }}
        />
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ minWidth: '50px', width: '50px' }}>
          <ListBox procurementStatus={procurementData.procurementStatus}>
            <ListButton
              type="print"
              onClick={(e) => {
                e.stopPropagation();
                props.actPrintProcurement(procurementData, props.index);
              }}
            >
              <img src={process.env.PUBLIC_URL + '/src/icons/print.svg'} />
            </ListButton>
          </ListBox>
        </td>
        {!props.hideCheckbox ? (
          <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ minWidth: '50px', width: '50px', cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              props.goDetail(procurementData, props.index);
            }}
          >
            <DetailViewButton />
          </td>
        ) : null}
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ minWidth: '140px', padding: 'unset', width: '140px' }}>
          {(() => {
            if (procurementData.procurementStatus === 'proceeding' || procurementData.procurementStatus === 'incomplete' || procurementData.procurementStatus === 'end') {
              return (
                <Fragment>
                  {procurementData.procurementStatus === 'proceeding' ? (
                    <ListButton
                      type="inStock"
                      onClick={(e) => {
                        e.stopPropagation();
                        props.actPutProcurementElement(procurementData, props.index);
                      }}
                    >
                      부분입고
                    </ListButton>
                  ) : null}
                  {procurementData.procurementStatus === 'incomplete' ? (
                    <ListButton
                      type="notInStock"
                      onClick={(e) => {
                        e.stopPropagation();
                        props.actCreateNotInStock(procurementData, props.index);
                      }}
                    >
                      미입고
                    </ListButton>
                  ) : null}
                </Fragment>
              );
            } else {
              return null;
            }
          })()}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{procurementData.procurementCode}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{procurementData.procurementName}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{procurementData?.account?.accountName}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{checkEmptyNull(procurementData.procurementDate, false) ? DateFormat(procurementData.procurementDate) : ''}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{checkEmptyNull(procurementData.scheduledEndDate, false) ? DateFormat(procurementData.scheduledEndDate) : ''}</td>

        {procurementElements[0] !== undefined ? (
          <Fragment>
            <td>
              {checkNullObject(procurementElements[0].element, false) ? checkEmptyNull(procurementElements[0].element.elementName, '') : checkEmptyNull(procurementElements[0].elementName, '')}
              <tr />
              ({checkNullObject(procurementElements[0].element, false) ? checkEmptyNull(procurementElements[0].element.elementCode, '') : checkEmptyNull(procurementElements[0].elementCode, '')})
            </td>
            <td>{checkEmptyNull(procurementElements[0].quantity, 0).toLocaleString()}</td>
            <td>{checkEmptyNull(procurementElements[0].price, 0).toLocaleString()}</td>
          </Fragment>
        ) : (
          <Fragment>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
          </Fragment>
        )}

        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{checkEmptyNull(procurementData.procurementPrice, 0).toLocaleString()}</td>

        {procurementData.procurementStatus !== 'cancel' && procurementData.procurementStatus !== 'end' ? (
          <WorkerStatusButton
            rowSpan={_rowLength === 0 ? 1 : _rowLength}
            style={{ minWidth: '50px', width: '50px' }}
            onClick={() => { props.actCancel(procurementData, props.index) }}
          >
            취소
          </WorkerStatusButton>
        ) : (
          <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ minWidth: '50px', width: '50px' }}></td>
        )}
      </tr>
      {_rowLength > 1 && returnElements()}
    </Fragment>
  );
};

export default ProcurementRow;
