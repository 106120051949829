import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { userApi } from 'api/apis/userApi';

import { checkEmptyNull, checkNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 40px 50px;
  height: 85%;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const ModalHeaderButtons = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 55px;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
    td.blankCell {background-color: var(--Bg); opacity: 0.8;}
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const SelectOneUser = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_userList, setUserList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState({});

  const [_searchData, setSearchData] = useState({});
  const [_searchOption, setSearchOption] = useState('');
  const [_searchText, setSearchText] = useState('');

  const [_pageNum, setPageNum] = useState(1);
  const [_pageSize, setPageSize] = useState(0);

  /* ====================================================================== #3 */
  useEffect(() => {
    getUserList(0);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getUserList(_pageNum - 1);
    }

    return () => { };
  }, [_pageNum]);

  useEffect(() => { }, [_checkedItem]);

  /* ====================================================================== #4 */
  const getUserList = async (page, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {companyId: userReducer.company.companyId};
    if (!clear && checkEmptyNull(_searchData.teamName, false)) {
      BodyToPost.teamName = _searchData.teamName;
    }
    if (!clear && checkEmptyNull(_searchData.name, false)) {
      BodyToPost.name = _searchData.name;
    }
    await userApi.searchUser(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('userApi.searchUser : ', response);
      setUserList(() => {return response.data.content;});
      setPageSize(() => {return response.data.totalElements})
    })
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (item, isChecked) => {
    if (isChecked) setCheckedItem(() => {return item;});
    else setCheckedItem(() => {return {}})
  };

  /* 검색 */
  const actSearch = async () => {
    setPageNum(() => {return 1;});
    await getUserList(0);
  };

  /* 초기화 */
  const actReset = async () => {
    setSearchData(() => {return {};});
    setSearchOption(() => {return '';});
    setSearchText(() => {return '';});
    setPageNum(() => {return 1;});
    await getUserList(0, true);
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>사용자 선택</ModalTitle>
              <ModalHeaderButtons></ModalHeaderButtons>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <NavBar
                title={
                  <div className="SearchSection">
                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="elementName">팀</option>
                      </select>
                      <input
                        className="SearchBar detail"
                        placeholder="Search..."
                        value={checkNull(_searchData.teamName, '')}
                        onInput={(e) => {setSearchData((prev) => {return { ...prev, teamName: e.target.value };});}}
                      />
                    </div>
                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="elementName">이름</option>
                      </select>
                      <input
                        className="SearchBar detail"
                        placeholder="Search..."
                        value={checkNull(_searchData.name, '')}
                        onInput={(e) => {setSearchData((prev) => {return { ...prev, name: e.target.value };});}}
                      />
                    </div>
                    <div className="ContentCBox">
                      <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                      <div className="ResetButton" onClick={actReset}>초기화</div>
                    </div>
                  </div>
                }
                nav={''}
              />

              <TableSection content={
                <table>
                  <thead>
                    <tr>
                      <th style={{ minWidth: '50px', width: '50px' }}></th>
                      <th>팀</th>
                      <th>아이디</th>
                      <th>이름</th>
                      <th>이메일</th>
                      <th>연락처</th>
                      <th>메모</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkNullArray(_userList, []).map((user, index) => {
                      return (<tr key={index + '_user'}>
                        <td style={{ minWidth: '50px', width: '50px' }}>
                          <input
                            type="checkBox"
                            name="users"
                            checked={checkEmptyNull(_checkedItem.id, false) ? user.id === _checkedItem.id : false}
                            onChange={(e) => {
                              e.preventDefault();
                              const isChecked = e.target.checked;
                              handleCheckedItem(user, isChecked);
                              if (isChecked) e.target.checked = true;
                              else e.target.checked = false;
                            }}
                            onClick={e => {e.stopPropagation();}}
                          />
                        </td>
                        <td>{user.team?.teamName}</td>
                        <td>{user.loginId}</td>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.phoneNumber}</td>
                        <td>{user.memo}</td>
                      </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
              ></TableSection>
            </ModalMain>

            <PagingComponent
              page={_pageNum}
              count={_pageSize}
              size={10}
              pageEvent={(page) => {
                console.log('page : ', page);
                setPageNum(() => {return page;});
              }}
            />

            <ModalFooter>
              <ModalButton onClick={() => {props.buttonEvent(_checkedItem);}}>{props.buttonTitle}</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default SelectOneUser;
