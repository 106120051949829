import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { WS_CLIENT } from 'preferences/server/constants';
import { getCookie } from 'preferences/cookie/cookie';

import { failedReasonApi } from 'api/apis/failedReasonApi';
import { processLogApi } from 'api/apis/processLogApi';
import { stockPlace } from 'api/apis/stock/stockPlace';
import { userApi } from 'api/apis/userApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import FailedProcessModal from 'pages/working/process/status/modal/FailedProcessModal';
import TableSection from 'components/layouts/table/TableSection';

const ModalSection = styled.section`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100svh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100svw;
  z-index: 1000;

  &>section {
    background-color: var(--white);
    border-radius: 10px;
    display: grid;
    gap: 10px;
    grid-template-rows: 55px 80px calc(100% - 265px) 100px;
    height: 90%;
    overflow: hidden;
    width: 95%;
  }

  & header {
    align-items: center;
    background-color: var(--gray-100);
    box-sizing: border-box;
    display: flex;
    height: 55px;
    justify-content: flex-start;
    padding: 0px 20px;
    position: relative;
    width: 100%;

    h4 {white-space: nowrap;}

    & .btn-close {
      height: 25px;
      position: absolute;
      right: 15px;
      top: 15px;
      width: 25px;
    }
  }

  & nav {
    align-items: center;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
    height: 95%;
    margin: 0px auto;
    width: 98%;

    & button {
      color: var(--white);
      font-size: 30px;
      font-weight: 600;
      &.element, &.defective, &.worker {background-color: var(--MainNavy);}
    }
  }

  & .modal-footer {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    & .btn-footer {
      align-items: center;
      background-color: var(--ThirdBlue);
      color: var(--white);
      cursor: pointer;
      display: flex;
      font-size: 30px;
      font-weight: 600;
      height: 100%;
      justify-content: center;
      width: 100%;
    }
  }
`;

const Element = styled.main`
  display: grid;
  grid-template-columns: ${(props => {
    return props.half === 'true' ? '35% 65%' : '100%';
  })};

  & .TableSection {
    & td.cell-amount-event {
      min-width: 200px;
      width: 200px;
      &>div {
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;

        & input {
          border: 1px solid var(--gray-200);
          box-sizing: border-box;
          font-size: 30px;
          font-weight: 600;
          grid-column-start: 1;
          grid-column-end: 3;
          padding: 10px;
          text-align: right;
        }

        & button {
          background-color: var(--MainNavy);
          color: var(--white);
          font-size: 20px;
          line-height: 50px;
        }
      }
    }
    & td.cell-select {
      min-width: 250px;
      width: 250px;

      & select, & input {
        border: 1px solid var(--gray-200);
        box-sizing: border-box;
        margin: 10px auto;
        padding: 10px;
        width: 100%;
      }
    }
  }
`;

const Defective = styled.main`
  display: grid;
  grid-template-columns: calc(100% - 200px) 200px;

  &>button {
    background-color: var(--MainNavy);
    color: var(--white);
    font-size: 1.4em;
    font-weight: 600;
  }

  & .TableSection {
    & td>button {
      margin: 0px auto;
      min-width: fit-content;
      padding-inline: 15px;
      width: fit-content;
    }
    & td.cell-amount-event {
      min-width: 200px;
      width: 200px;
      &>div {
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;

        & input {
          border: 1px solid var(--gray-200);
          box-sizing: border-box;
          font-size: 30px;
          font-weight: 600;
          grid-column-start: 1;
          grid-column-end: 3;
          padding: 10px;
          text-align: right;
        }

        & button {
          background-color: var(--MainNavy);
          color: var(--white);
          font-size: 20px;
          line-height: 50px;
        }
      }
    }
    & td.cell-select {
      vertical-align: top;
      
      &>div:first-child {
        display: grid;
        gap: 10px;
        grid-template-columns: calc(100% - 70px) 60px;
        margin-bottom: 10px;
        width: 100%;

        & input {
          border: 1px solid var(--gray-200);
          box-sizing: border-box;
          margin: 0px auto;
          padding: 10px;
          width: 100%;
        }
        & button {
          background-color: var(--MainNavy);
          color: var(--white);
          padding-inline: 20px;
        }
      }
      &>div:last-child {
        display: grid;
        gap: 10px;
        width: 100%;
        
        &>div {
          align-items: center;
          border: 1px solid var(--gray-200);
          box-sizing: border-box;
          display: grid;
          gap: 10px;
          grid-template-columns: calc(100% - 50px) 40px;
          padding: 10px;
          width: 100%;

          & .btn-delete {
            background-color: var(--ThirdRed);
            & div {
              background-color: var(--white);
            }
          }
        }
      }
    }
  }
`;

const Worker = styled.main`
  box-sizing: border-box;
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
  padding: 20px;

  &>div {
    display: grid;
    gap: 20px;
    grid-template-rows: 200px calc(100% - 220px);
    height: 100%;
    overflow: hidden;
    width: 100%;
  }
  & table {
    width: 100%;

    & tr {border: none;}
    & th {
      background-color: var(--MainNavy);
      border: none;
      color: var(--white);
    }
    & td {
      border: 1px solid var(--gray-200);
      box-sizing: border-box;
      padding-inline-start: 20px;
    }
  }
  &>.worker th {background-color: var(--MainBlue);}

  &>div>div{
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    height: 100%;
    overflow: scroll;
    width: 100%;

    & input {
      display: none;
      &:checked + label {
        background-color: var(--MainNavy);
        border: none;
        color: var(--white);
        font-weight: 600;
      }
    }
    & label {
      align-items: center;
      background-color: var(--white);
      border: 1px solid var(--gray-800);
      border-radius: 10px;
      box-sizing: border-box;
      color: var(--gray-800);
      cursor: pointer;
      display: flex;
      font-size: 1.3em;
      font-weight: 300;
      height: 100px;
      justify-content: center;
      min-height: 100px;
      width: 100%;
    }
  }
  &>.worker input:checked + label {
    background-color: var(--MainBlue);
  }
`;

const ProcessEndModal = (props) => {
  const { userReducer } = useSelector((state) => state);

  // const processLogId = 15174;
  const { processLogId } = props;

  const [_onload, setOnload] = useState('unload');

  const [_contentType, setContentType] = useState('element');

  const [_processLogData, setProcessLogData] = useState({});
  const [_putElements, setPutElements] = useState([]);
  const [_makeElements, setMakeElements] = useState([]);

  const [_modalStatus, setModalStatus] = useState(false);
  const [_failedReasons, setFailedReasons] = useState([]); // 불량사유
  const [_defectives, setDefectives] = useState([]); // 불량

  const [_stockPlaces, setStockPlaces] = useState([]); // 재고위치

  const [_userList, setUserList] = useState([]);
  const [_managers, setManagers] = useState([]);
  const [_workers, setWorkers] = useState([]);

  useEffect(() => {
    (async () => {
      await getFailedReasons();
      await getStockPlaces();
      await getUserList();
      await getProcessLogData();
    })();

    setOnload('loaded');
    return () => { };
  }, []);
  useEffect(() => {
    console.log('check : ', _managers, _workers);
  }, [_putElements, _makeElements, _managers, _workers])

  // 불량사유
  const getFailedReasons = async () => {
    const paging = `?page=0&size=100`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await failedReasonApi.searchFailedReason(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('failedReasonApi.searchFailedReason : ', response);
      setFailedReasons(() => { return response.data.content });
    });
  };

  // 재고위치
  const getStockPlaces = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await stockPlace.searchStockPlace(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('stockPlace.searchStockPlace : ', response);
      setStockPlaces(() => { return response.data; });
    });
  }

  // 책임자/작업자
  const getUserList = async () => {
    const paging = `?page=0&size=100&sort=name,ASC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await userApi.searchUser(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('userApi.searchUser : ', response);
      setUserList(() => { return response.data.content; });
    });
  };

  // 공정
  const getProcessLogData = async () => {
    await processLogApi.getProcessLog(processLogId).then((response) => {
      if (response === undefined) return;
      console.log('processLogApi.getProcessLog : ', response);

      const processLogData = response.data;
      setProcessLogData(() => { return processLogData });

      setPutElements(() => {
        const putElements = checkNullArray(processLogData.processLogElementList, []).filter((element) => element.processLogElementType === 'Put');
        const returnData = putElements.map((element) => {
          // currentPutAmount: 투입량
          const elementData = { ...element, currentPutAmount: 0 };
          if (!isNaN(element.amount) && element.amount > 0) {
            const processingCount = BigNumber(checkEmptyNull(processLogData.processingCount, 0)).toNumber();
            const unitAmount = BigNumber(checkEmptyNull(element.amount, 0)).toNumber();
            const currentPutAmount = BigNumber(unitAmount).multipliedBy(BigNumber(processingCount)).toNumber();
            elementData.currentPutAmount = currentPutAmount;
          } else {
            elementData.amount = 0;
          }
          return elementData;
        });
        return returnData;
      });

      setMakeElements(() => {
        const makeElements = checkNullArray(processLogData.processLogElementList, []).filter((element) => element.processLogElementType === 'Make');
        const returnData = makeElements.map((element) => {
          // currentMakeAmount: 생산량
          // makeAmount: 성공수량
          // makeFailedAmount: 실패수량
          const elementData = {
            ...element,
            currentMakeAmount: 0,
            makeAmount: 1,
            makeFailedAmount: 0,
          };
          if (!isNaN(element.amount) && element.amount > 0) {
            const processingCount = BigNumber(checkEmptyNull(processLogData.processingCount, 0)).toNumber();
            const unitAmount = BigNumber(checkEmptyNull(element.amount, 0)).toNumber();
            const currentMakeAmount = BigNumber(unitAmount).multipliedBy(BigNumber(processingCount)).toNumber();
            elementData.currentMakeAmount = currentMakeAmount;
            elementData.makeAmount = currentMakeAmount;
            elementData.makeFailedAmount = 0;
          } else {
            elementData.amount = 0;
          }
          return elementData;
        });
        return returnData;
      });
    });
  };

  const handleAmount = (value, min, max) => {
    let returnAmount = value;
    if (value < min) returnAmount = min;
    if (value > max) {
      returnAmount = max;
      alert(`
      투입이 예정된 자재/반제품이 있습니다.
      최대 가용 수량(현재고-투입예정수량)은 [ ${max} ] 개입니다.

      [ ${max} ] 개 초과 사용 시 자재/반제품의 입고가 필요합니다.
      `);
    }
    if (max < 0) {
      returnAmount = value;
      alert(`
      투입이 예정된 자재/반제품이 있습니다.
      최대 가용 수량(현재고-투입예정수량)은 [ ${max} ] 개입니다.
      
      [ ${max} ] 개 초과 사용 시 자재/반제품의 입고가 필요합니다.
      `);
    }
    console.log(value, min, max, returnAmount);
    return returnAmount;
  }

  const handleManager = (user, isChecked) => {
    const checkedItem = [..._managers];
    const checkedIndex = checkedItem.findIndex((item) => item.id === user.id);
    if (checkedIndex !== -1) {
      if (!isChecked) checkedItem.splice(checkedIndex, 1);
    } else {
      if (isChecked) checkedItem.push(user);
    }
    console.log('checkedItem : ', checkedItem);
    setManagers(() => { return checkedItem });
  };
  const handleWorker = (user, isChecked) => {
    const checkedItem = [..._workers];
    const checkedIndex = checkedItem.findIndex((item) => item.id === user.id);
    if (checkedIndex !== -1) {
      if (!isChecked) checkedItem.splice(checkedIndex, 1);
    } else {
      if (isChecked) checkedItem.push(user);
    }
    console.log('checkedItem : ', checkedItem);
    setWorkers(() => { return checkedItem });
  };

  const actEnd = async () => {
    const putElements = checkNullArray(_putElements, []).map(putElement => {
      const returnData = {
        processResultElementType: putElement.processLogElementType,
        processLogElementId: putElement.processLogElementId,
        amount: BigNumber(checkEmptyNull(putElement.currentPutAmount, 0)).toNumber(),
        processResultElementFailedReasons: [],
      };
      const failedReasons = _defectives.filter((defective) => {
        if (defective.processLogElementId === putElement.processLogElementId) return true;
        else return false;
      });
      returnData.processResultElementFailedReasons = checkNullArray(failedReasons, []).map((failedReason) => {
        const failedReasonData = {
          amount: BigNumber(checkEmptyNull(failedReason.failedReasonAmount, 0)).toNumber(),
          failedReason: '',
        }
        if (checkNullArray(failedReason.processResultElementFailedReasons, []).length > 0) {
          failedReasonData.failedReason = failedReason.processResultElementFailedReasons.join('\n');
        }
        return failedReasonData;
      })
      return returnData;
    });

    let checkMakeAmount = 0;
    const makeElements = checkNullArray(_makeElements, []).map(makeElement => {
      console.log('makeElement : ', makeElement);
      const returnData = {
        processResultElementType: makeElement.processLogElementType,
        processLogElementId: makeElement.processLogElementId,
        makeAmount: BigNumber(checkEmptyNull(makeElement.makeAmount, 0)).toNumber(),
        failedAmount: BigNumber(checkEmptyNull(makeElement.makeFailedAmount, 0)).toNumber(),
        processResultElementFailedReasons: [],

        // 추가정보
        stockPlaceId: makeElement.stockPlaceId,
      };
      if (checkEmptyNull(makeElement.checkedDate, false)) {
        const checkedDate = new Date(makeElement.checkedDate);
        checkedDate.setHours(checkedDate.getHours() + 9);
        returnData.checkedDate = checkedDate.toISOString(); // 유통기한
      }
      checkMakeAmount = BigNumber(checkMakeAmount).plus(BigNumber(returnData.makeAmount)).plus(returnData.failedAmount).toNumber();
      const failedReasons = _defectives.filter((defective) => {
        if (defective.processLogElementId === makeElement.processLogElementId) return true;
        else return false;
      });
      returnData.processResultElementFailedReasons = checkNullArray(failedReasons, []).map((failedReason) => {
        const failedReasonData = {
          amount: BigNumber(checkEmptyNull(failedReason.failedReasonAmount, 0)).toNumber(),
          failedReason: '',
        }
        if (checkNullArray(failedReason.processResultElementFailedReasons, []).length > 0) {
          failedReasonData.failedReason = failedReason.processResultElementFailedReasons.join('\n');
        }
        return failedReasonData;
      })
      return returnData;
    });

    const BodyToWS = {
      roomId: userReducer.company.companyId,
      type: '/process',
      userId: userReducer.user.id,
      workOrderLogId: props.workOrderLogId,
      processLogId: processLogId,
      processStatus: 'end',
      processResult: { processResultElements: [...putElements, ...makeElements] },
    };
    console.table(BodyToWS);
    console.log(JSON.stringify(BodyToWS));

    if (makeElements.length > 0 && checkMakeAmount < 1) return alert(`공정완료물품의 성공수량, 실패수량을 확인해주세요.`);
    if (window.confirm('공정을 완료합니다.')) {
      const setBodyToWS = {
        ...BodyToWS,
        managerIds: _managers.map((manager) => manager.id),
        workerIds: _workers.map((worker) => worker.id),
      }
      // return;
      try {
        WS_CLIENT.publish({
          destination: '/pub/process',
          headers: { 'auth-token': getCookie('accessToken')?.replace('Bearer ', '') },
          body: JSON.stringify(setBodyToWS),
        });
      } catch (error) {
        console.log('client.publish/pub/process - error : ', error);
      }
      props.statusModalCloseEvent();
      setTimeout(props.close(), 1000);
    } else {
      // return;
    }
  }

  return (
    <>
      {/* {props.open ? <> */}
      {true ? <>
        <ModalSection>
          <section>
            <header>
              <h4>공정완료</h4>
              <div className='btn-close' onClick={props.close}><CloseButtonIcon /></div>
            </header>

            <nav>
              {[
                { type: 'element', name: '물품', },
                { type: 'defective', name: '불량', },
                { type: 'worker', name: '책임자/작업자', },
              ].map((contentType, index) => {
                return (
                  <button key={index + '_contentType'}
                    className={contentType.type === _contentType ? 'btn-set ' + contentType.type : 'btn-set'}
                    onClick={(e) => {
                      e.preventDefault();
                      setContentType(() => { return contentType.type })
                    }}
                  >
                    {contentType.name}
                  </button>
                )
              })}
            </nav>

            {_contentType === 'element' ?
              <Element className='main-element'
                half={checkNullArray(_putElements, []).length > 0 && checkNullArray(_makeElements, []).length > 0 ? 'true' : 'false'}
              >
                {checkNullArray(_putElements, false) ?
                  <TableSection content={
                    <table>
                      <thead>
                        <tr>
                          <th colSpan={3} style={{ backgroundColor: 'var(--MainYellow)' }}>투입 자재/반제품</th>
                        </tr>
                        <tr>
                          {[
                            { name: '물품정보', color: '', },
                            // {name: '물품이름', color: '',},
                            // {name: '물품코드', color: '',},
                            { name: '현재고', color: '', },
                            { name: '투입량', color: 'var(--MainYellow)', },
                          ].map((key, index) => {
                            return (<th key={index + '_key_putElements'} style={{ backgroundColor: key.color }}>{key.name}</th>);
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {checkNullArray(_putElements, []).map((putElement, index) => {
                          const elementStock = BigNumber(checkEmptyNull(putElement.elementStock, 0)).minus(BigNumber(checkEmptyNull(putElement.scheduledInputStock, 0))).toNumber();
                          return (
                            <tr key={index + '_putElement'}>
                              <td>{putElement.elementName}<br />{putElement.elementCode}</td>
                              <td>{checkEmptyNull(putElement.elementStock, 0).toLocaleString()} {putElement.elementUnit}</td>
                              <td className='cell-amount-event'>
                                <div>
                                  <input type='number' name='currentPutAmount' min={0} max={elementStock}
                                    value={putElement.currentPutAmount}
                                    onChange={(e) => {
                                      const amount = handleAmount(checkEmptyNull(e.target.value, 0), 0, elementStock);
                                      setPutElements(prev => {
                                        const returnData = [...prev];
                                        const newData = { ...putElement, currentPutAmount: amount }
                                        returnData.splice(index, 1, newData);
                                        return returnData;
                                      })
                                    }}
                                  />

                                  <button className='btn-set'
                                    onClick={() => {
                                      const amount = handleAmount(checkEmptyNull(putElement.currentPutAmount - 1, 0), 0, elementStock);
                                      setPutElements(prev => {
                                        const returnData = [...prev];
                                        const newData = { ...putElement, currentPutAmount: amount }
                                        returnData.splice(index, 1, newData);
                                        return returnData;
                                      })
                                    }}
                                  >
                                    ▼
                                  </button>
                                  <button className='btn-set'
                                    onClick={() => {
                                      const amount = handleAmount(checkEmptyNull(putElement.currentPutAmount + 1, 0), 0, elementStock);
                                      setPutElements(prev => {
                                        const returnData = [...prev];
                                        const newData = { ...putElement, currentPutAmount: amount }
                                        returnData.splice(index, 1, newData);
                                        return returnData;
                                      })
                                    }}
                                  >
                                    ▲
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  } />
                  : null}
                {checkNullArray(_makeElements, false) ?
                  <TableSection content={
                    <table>
                      <thead>
                        <tr>
                          <th colSpan={5} style={{ backgroundColor: 'var(--ThirdBlue)' }}>공정완료제품</th>
                        </tr>
                        <tr>
                          {[
                            { name: '물품정보', color: '', },
                            // {name: '물품이름', color: '',},
                            // {name: '물품코드', color: '',},
                            { name: '목표 생산량', color: '', },
                            { name: '성공수량', color: 'var(--MainGreen)', },
                            { name: '실패수량', color: 'var(--MainRed)', },
                            { name: '추가정보(재고위치/유통기한)', color: 'var(--MainBlue)', },
                          ].map((key, index) => {
                            return (<th key={index + '_key_makeElements'} style={{ backgroundColor: key.color }}>{key.name}</th>);
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {checkNullArray(_makeElements, []).map((makeElement, index) => {
                          return (
                            <tr key={index + '_makeElement'}>
                              <td>{makeElement.elementName}<br />{makeElement.elementCode}</td>
                              <td>{checkEmptyNull(makeElement.currentMakeAmount, 0).toLocaleString()} {makeElement.elementUnit}</td>
                              <td className='cell-amount-event'>
                                <div>
                                  <input type='number' name='makeAmount' min={0}
                                    value={makeElement.makeAmount}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const amount = handleAmount(checkEmptyNull(e.target.value, 0), 0);
                                      setMakeElements(prev => {
                                        const returnData = [...prev];
                                        const newData = { ...makeElement, makeAmount: amount }
                                        returnData.splice(index, 1, newData);
                                        return returnData;
                                      })
                                    }}
                                  />

                                  <button className='btn-set'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      const amount = handleAmount(checkEmptyNull(makeElement.makeAmount - 1, 0), 0);
                                      setMakeElements(prev => {
                                        const returnData = [...prev];
                                        const newData = { ...makeElement, makeAmount: amount }
                                        returnData.splice(index, 1, newData);
                                        return returnData;
                                      })
                                    }}
                                  >
                                    ▼
                                  </button>
                                  <button className='btn-set'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      const amount = handleAmount(checkEmptyNull(makeElement.makeAmount + 1, 0), 0);
                                      setMakeElements(prev => {
                                        const returnData = [...prev];
                                        const newData = { ...makeElement, makeAmount: amount }
                                        returnData.splice(index, 1, newData);
                                        return returnData;
                                      })
                                    }}
                                  >
                                    ▲
                                  </button>
                                </div>
                              </td>
                              <td className='cell-amount-event'>
                                <div>
                                  <input type='number' name='makeFailedAmount' min={0}
                                    value={makeElement.makeFailedAmount}
                                    onChange={(e) => {
                                      const amount = handleAmount(checkEmptyNull(e.target.value, 0), 0);
                                      setMakeElements(prev => {
                                        const returnData = [...prev];
                                        const newData = { ...makeElement, makeFailedAmount: amount }
                                        returnData.splice(index, 1, newData);
                                        return returnData;
                                      })
                                    }}
                                  />

                                  <button className='btn-set'
                                    onClick={() => {
                                      const amount = handleAmount(checkEmptyNull(makeElement.makeFailedAmount - 1, 0), 0);
                                      setMakeElements(prev => {
                                        const returnData = [...prev];
                                        const newData = { ...makeElement, makeFailedAmount: amount }
                                        returnData.splice(index, 1, newData);
                                        return returnData;
                                      })
                                    }}
                                  >
                                    ▼
                                  </button>
                                  <button className='btn-set'
                                    onClick={() => {
                                      const amount = handleAmount(checkEmptyNull(makeElement.makeFailedAmount + 1, 0), 0);
                                      setMakeElements(prev => {
                                        const returnData = [...prev];
                                        const newData = { ...makeElement, makeFailedAmount: amount }
                                        returnData.splice(index, 1, newData);
                                        return returnData;
                                      })
                                    }}
                                  >
                                    ▲
                                  </button>
                                </div>
                              </td>
                              <td className='cell-select'>
                                {checkEmptyNull(_stockPlaces, false) ?
                                  <select
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const stockPlaceId = Number(e.target.value);
                                      if (isNaN(stockPlaceId)) return;
                                      setMakeElements(prev => {
                                        const returnData = [...prev];
                                        const newData = { ...makeElement, stockPlaceId: stockPlaceId }
                                        returnData.splice(index, 1, newData);
                                        return returnData;
                                      })
                                    }}
                                  >
                                    <option value={''}>재고위치 선택</option>
                                    {checkEmptyNull(_stockPlaces, []).map((stockPlace, stockPlaceIndex) => {
                                      return (
                                        <option key={stockPlaceIndex + '_stockPlace'} value={stockPlace.stockPlaceId}>{stockPlace.placeName}</option>
                                      )
                                    })}
                                  </select>
                                  : null}
                                <input type='datetime-local'
                                  onChange={(e) => {
                                    e.preventDefault();
                                    const checkedDate = DateTimeFormat(checkEmptyNull(e.target.value, ''));
                                    setMakeElements(prev => {
                                      const returnData = [...prev];
                                      const newData = { ...makeElement, checkedDate: checkedDate }
                                      returnData.splice(index, 1, newData);
                                      return returnData;
                                    })
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  } />
                  : null}
              </Element>
              : null}
            {_contentType === 'defective' ?
              <Defective className='main-defective'>
                <TableSection content={
                  <table>
                    <colgroup>
                      <col></col>
                      <col></col>
                      <col></col>
                      <col width={'50px'}></col>
                    </colgroup>
                    <thead>
                      <tr>
                        {[
                          { name: '물품정보', color: '', },
                          // {name: '물품이름', color: '',},
                          // {name: '물품코드', color: '',},
                          { name: '불량수량', color: 'var(--ThirdRed)', },
                          { name: '불량사유', color: '', },
                        ].map((key, index) => {
                          return (<th key={index + '_key_defectives'} style={{ backgroundColor: key.color }}>{key.name}</th>);
                        })}
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {checkNullArray(_defectives, []).map((defective, index) => {
                        return (
                          <tr key={index + '_defective'}>
                            <td>{defective.elementName}<br />{defective.elementCode}</td>
                            <td className='cell-amount-event'>
                              <div>
                                <input type='number' name='failedReasonAmount' min={0}
                                  value={defective.failedReasonAmount}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    const amount = handleAmount(checkEmptyNull(e.target.value, 0), 0);
                                    setDefectives(prev => {
                                      const returnData = [...prev];
                                      const newData = { ...defective, failedReasonAmount: amount }
                                      returnData.splice(index, 1, newData);
                                      return returnData;
                                    })
                                  }}
                                />

                                <button className='btn-set'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const amount = handleAmount(checkEmptyNull(defective.failedReasonAmount - 1, 0), 0);
                                    setDefectives(prev => {
                                      const returnData = [...prev];
                                      const newData = { ...defective, failedReasonAmount: amount }
                                      returnData.splice(index, 1, newData);
                                      return returnData;
                                    })
                                  }}
                                >
                                  ▼
                                </button>
                                <button className='btn-set'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const amount = handleAmount(checkEmptyNull(defective.failedReasonAmount + 1, 0), 0);
                                    setDefectives(prev => {
                                      const returnData = [...prev];
                                      const newData = { ...defective, failedReasonAmount: amount }
                                      returnData.splice(index, 1, newData);
                                      return returnData;
                                    })
                                  }}
                                >
                                  ▲
                                </button>
                              </div>
                            </td>
                            <td className='cell-select'>
                              <div>
                                <input
                                  id={`failedReason_${index}`}
                                  list={`failedReasons_${index}`}
                                  name="failedReason"
                                  data-index={index}
                                  placeholder="입력 후 우측 [추가] 버튼을 클릭해주세요."
                                />
                                <datalist id={`failedReasons_${index}`}>
                                  {_failedReasons.map((failedReason, failedReasonIndex) => {
                                    return (
                                      <option key={index + '_failedReason_' + failedReasonIndex} value={failedReason.failedReasonContent}>
                                        {failedReason.failedReasonContent}
                                      </option>
                                    );
                                  })}
                                </datalist>
                                <button className='btn-set'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const id = `failedReason_${index}`;
                                    const input = document.getElementById(id);
                                    const { value } = input;

                                    const processResultElementFailedReasons = checkNullArray(defective.processResultElementFailedReasons, []);
                                    setDefectives((prev) => {
                                      const returnData = [...prev];
                                      const newData = { ...defective, processResultElementFailedReasons: [...processResultElementFailedReasons, value] }
                                      returnData.splice(index, 1, newData);
                                      return returnData;
                                    })

                                    input.value = '';
                                  }}
                                >추가</button>
                              </div>
                              <div>
                                {(() => {
                                  const returnArray = [];
                                  checkNullArray(defective.processResultElementFailedReasons, []).forEach((failedReason, failedReasonIndex) => {
                                    returnArray.push(
                                      <div key={index + '_processResultElementFailedReasons_' + failedReasonIndex}>
                                        <>{failedReason}</>
                                        <div className='btn-delete'
                                          onClick={(e) => {
                                            e.preventDefault();
                                            const processResultElementFailedReasons = checkNullArray(defective.processResultElementFailedReasons, []);
                                            processResultElementFailedReasons.splice(failedReasonIndex, 1);

                                            setDefectives((prev) => {
                                              const returnData = [...prev];
                                              const newData = { ...defective, processResultElementFailedReasons: processResultElementFailedReasons }
                                              returnData.splice(index, 1, newData);
                                              return returnData;
                                            })
                                          }}
                                        >
                                          <CloseButtonIcon />
                                        </div>
                                      </div>
                                    );
                                  });
                                  return returnArray;
                                })()}
                              </div>
                            </td>
                            <td><button className='btn-inven-4'
                              onClick={(e) => {
                                e.preventDefault();
                                setDefectives((prev) => {
                                  const returnData = [...prev];
                                  returnData.splice(index, 1);
                                  return returnData;
                                })
                              }}
                            >삭제</button></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                } />

                <button className='btn-set'
                  onClick={(e) => {
                    e.preventDefault();
                    setModalStatus(() => { return true })
                  }}
                >
                  불량물품<br />등록
                </button>
              </Defective>
              : null}
            {_contentType === 'worker' ?
              <Worker className='main-worker'>
                <div className='manager'>
                  <table>
                    <colgroup>
                      <col width={'100px'}></col>
                      <col></col>
                    </colgroup>
                    <tbody>
                      <tr>
                        <th colSpan={2}>책임자</th>
                      </tr>
                      <tr>
                        <th>기존</th>
                        <td>{checkNullArray(_processLogData.managers, []).map((manager) => manager.workerName).join(' / ')}</td>
                      </tr>
                      <tr>
                        <th>변경</th>
                        <td>{checkNullArray(_managers, []).map((manager) => manager.name).join(' / ')}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div>
                    {checkNullArray(_userList, []).map(((user, index) => {
                      return (
                        <Fragment key={index + '_manager'}>
                          <input
                            type="checkBox"
                            id={user.id + '_manager'}
                            name="manager"
                            style={{ display: 'none' }}
                            data-key={user.id}
                            checked={(() => {
                              let checkedId = -1;
                              checkedId = _managers?.findIndex((item) => item.id === user.id);
                              if (checkedId !== -1) return true;
                              else return false;
                            })()}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              handleManager(user, isChecked);
                            }}
                            onClick={(e) => { e.stopPropagation(); }}
                          />
                          <label htmlFor={user.id + '_manager'}>{user.name}</label>
                        </Fragment>
                      )
                    }))}
                  </div>
                </div>
                <div className='worker'>
                  <table>
                    <colgroup>
                      <col width={'100px'}></col>
                      <col></col>
                    </colgroup>
                    <tbody>
                      <tr>
                        <th colSpan={2}>작업자</th>
                      </tr>
                      <tr>
                        <th>기존</th>
                        <td>{checkNullArray(_processLogData.workers, []).map((worker) => worker.workerName).join(' / ')}</td>
                      </tr>
                      <tr>
                        <th>변경</th>
                        <td>{checkNullArray(_workers, []).map((worker) => worker.name).join(' / ')}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div>
                    {checkNullArray(_userList, []).map(((user, index) => {
                      return (
                        <Fragment key={index + '_worker'}>
                          <input
                            type="checkBox"
                            id={user.id + '_worker'}
                            name="worker"
                            style={{ display: 'none' }}
                            data-key={user.id}
                            checked={(() => {
                              let checkedId = -1;
                              checkedId = _workers?.findIndex((item) => item.id === user.id);
                              if (checkedId !== -1) return true;
                              else return false;
                            })()}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              handleWorker(user, isChecked);
                            }}
                            onClick={(e) => { e.stopPropagation(); }}
                          />
                          <label htmlFor={user.id + '_worker'}>{user.name}</label>
                        </Fragment>
                      )
                    }))}
                  </div>
                </div>
              </Worker>
              : null}
            <div className='modal-footer'>
              <div className='btn-footer' onClick={actEnd}>완료</div>
            </div>
          </section>
        </ModalSection>
      </> : null}

      {_modalStatus === true && checkNullArray(_putElements, []).length + checkNullArray(_makeElements, []).length > 0 && (
        <FailedProcessModal
          putElementList={_putElements}
          makeElementList={_makeElements}
          buttonEvent={(data) => {
            console.log('data : ', data);
            const failedProducts = data.map((failedProduct) => {
              const returnData = {
                ...failedProduct,
                failedReasonAmount: 0,
                processResultElementFailedReasons: [],
              };
              return returnData;
            });
            setDefectives((prev) => { return [...prev, ...failedProducts]; });
            setTimeout(setModalStatus(false), 1000);
          }}
          open={_modalStatus}
          close={() => { setModalStatus(false) }}
        />
      )}
    </>
  );
};

export default ProcessEndModal;