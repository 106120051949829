import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { stockPlaceHistoryActions_setPageNumber, stockPlaceHistoryActions_setReset, stockPlaceHistoryActions_setSearchData, stockPlaceHistoryActions_setTotalSize } from 'store/modules/actions/common/stockPlaceHistoryActions';

import { stockPlaceHistory } from 'api/apis/stock/stockPlaceHistory';

import { checkEmptyNull } from 'components/checkValues/checkValues';

import Grid3Body from 'components/layouts/body/Grid3Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const Section = styled.main``;

const StockPlaceHistory = () => {
  const dispatch = useDispatch();
  const { stockPlaceHistoryReducer, userReducer } = useSelector((state) => state);

  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_logList, setLogList] = useState([]);

  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('307') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getLogList(stockPlaceHistoryReducer.pageNumber - 1);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getLogList(stockPlaceHistoryReducer.pageNumber - 1);
    }

    return () => { };
  }, [stockPlaceHistoryReducer.pageNumber]);

  const getLogList = async (page, clear) => {
    const paging = `?page=${page}&size=15&sort=id,DESC`;
    const BodyToPost = { companyId: userReducer.company.companyId };

    if (!checkEmptyNull(stockPlaceHistoryReducer.searchData.elementName, false)) {
      BodyToPost.elementName = stockPlaceHistoryReducer.searchData.elementName;
    }
    if (!checkEmptyNull(stockPlaceHistoryReducer.searchData.elementCode, false)) {
      BodyToPost.elementCode = stockPlaceHistoryReducer.searchData.elementCode;
    }
    if (!checkEmptyNull(stockPlaceHistoryReducer.searchData.stockPlaceName, false)) {
      BodyToPost.stockPlaceName = stockPlaceHistoryReducer.searchData.stockPlaceName;
    }

    await stockPlaceHistory.searchStockPlaceHistory(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('stockPlaceHistory.searchStockPlaceHistory : ', response);
      setLogList(() => { return response.data.content; });
      dispatch(stockPlaceHistoryActions_setTotalSize(response.data.totalElements));
    });
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(stockPlaceHistoryActions_setPageNumber(1));
    await getLogList(0);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(stockPlaceHistoryActions_setReset());
    dispatch(stockPlaceHistoryActions_setPageNumber(1));
    await getLogList(0, true);
  };


  return (
    <>
      <Grid3Body contents={
        <>
          <Section className="Main">
            <NavBar
              title={<NavTitle menuCode={'307'} />}
              nav={'search'}
              firstRow={
                <div className="SearchSection">
                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="elementName">물품이름</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkEmptyNull(stockPlaceHistoryReducer.searchData.elementName, '')}
                      onInput={(e) => { dispatch(stockPlaceHistoryActions_setSearchData({ ...stockPlaceHistoryReducer.searchData, elementName: e.target.value })); }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="elementCode">물품코드</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkEmptyNull(stockPlaceHistoryReducer.searchData.elementCode, '')}
                      onInput={(e) => { dispatch(stockPlaceHistoryActions_setSearchData({ ...stockPlaceHistoryReducer.searchData, elementCode: e.target.value })); }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="elementCode">재고위치</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkEmptyNull(stockPlaceHistoryReducer.searchData.stockPlaceName, '')}
                      onInput={(e) => { dispatch(stockPlaceHistoryActions_setSearchData({ ...stockPlaceHistoryReducer.searchData, stockPlaceName: e.target.value })); }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton" onClick={actReset}>초기화</div>
                  </div>
                </div>
              }
            />

            <TableSection content={
              <table>
                <thead>
                  <tr>
                    <th>물품이름</th>
                    <th>물품코드</th>
                    <th>재고 위치</th>
                    <th>수량</th>
                    <th>투입예정수량</th>
                    <th>사용량</th>
                  </tr>
                </thead>
                <tbody>
                  {_logList.map((log, index) => {
                    return (
                      <tr key={index + '_stockPlaceLog'}>
                        <td>{log.elementName}</td>
                        <td>{log.elementCode}</td>
                        <td>{log.stockPlaceName}</td>
                        <td>{checkEmptyNull(log.amount, 0).toLocaleString()}</td>
                        <td>{checkEmptyNull(log.scheduledInputStock, 0).toLocaleString()}</td>
                        <td>{checkEmptyNull(log.usedStock, 0).toLocaleString()}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            }
            />
          </Section>

          <PagingComponent
            page={stockPlaceHistoryReducer.pageNumber}
            count={stockPlaceHistoryReducer.totalSize}
            size={15}
            pageEvent={(page) => {
              console.log('page : ', page);
              dispatch(stockPlaceHistoryActions_setPageNumber(page));
            }}
          />
        </>
      }
      />
    </>
  );
};

export default StockPlaceHistory;
