import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import DOCButtonIcon from 'components/icons/DOCButtonIcon';
import StatusButton from 'components/buttons/StatusButton';

const WorkerStatusButton = styled.td`
  background-color: var(--ThirdRed);
  color: white;
  cursor: pointer;
  font-size: 1em;
  min-width: 60px;
  width: 60px;
`;

const WorkerScreenProcessRow = (props) => {
  /* ========================================================================= #1 */
  const { userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_authority, setAuthority] = useState([]);

  const [ProcessData, setProcessData] = useState(props.content);

  const [ProcessPutItems, setProcessPutItems] = useState(
    checkNullArray(props.content.processLogElementList, []).filter((thisItem) =>
      thisItem.processLogElementType === 'In' || thisItem.processLogElementType === 'Put'
    ),
  );
  const [ProcessMakeItems, setProcessMakeItems] = useState(
    checkNullArray(props.content.processLogElementList, []).filter((thisItem) =>
      thisItem.processLogElementType === 'Out' || thisItem.processLogElementType === 'Make'
    ),
  );

  const [_rowLength, setRowLength] = useState(ProcessPutItems.length > ProcessMakeItems.length ? ProcessPutItems.length : ProcessMakeItems.length);

  const [_completedAmount, setCompletedAmount] = useState(BigNumber(checkEmptyNull(props.content.completedAmount, 0)).toNumber());
  const [_processAmount, setProcessAmount] = useState(BigNumber(checkEmptyNull(props.content.processAmount, 0)).toNumber());
  const [_processingCount, setProcessingCount] = useState(BigNumber(checkEmptyNull(props.content.processingCount, 0)).toNumber());

  const [_clickStatus, setClickStatus] = useState(() => {
    const currentClick = BigNumber(checkEmptyNull(props.content.completedAmount, 0)).dividedBy(BigNumber(checkEmptyNull(props.content.processingCount, 0))).toNumber();
    const totalClick = BigNumber(checkEmptyNull(props.content.processAmount, 0)).dividedBy(BigNumber(checkEmptyNull(props.content.processingCount, 0))).toNumber();
    return `${currentClick} / ${totalClick}`;
  });
  const [_workStatus, setWorkStatus] = useState(`${BigNumber(checkEmptyNull(props.content.completedAmount, 0)).toNumber()} / ${BigNumber(checkEmptyNull(props.content.processAmount, 0))}`);

  const [_viewStatus, setViewStatus] = useState(props.viewStatus);

  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('109') === true || // 파일
      thisItem.authority.startsWith('501') === true, // 작업실행
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    return () => { };
  }, []);

  useEffect(() => {
    setProcessData(props.content);

    const PutItems = checkNullArray(props.content.processLogElementList, []).filter((thisItem) =>
      thisItem.processLogElementType === 'In' || thisItem.processLogElementType === 'Put'
    );
    const MakeItems = checkNullArray(props.content.processLogElementList, []).filter((thisItem) =>
      thisItem.processLogElementType === 'Out' || thisItem.processLogElementType === 'Make'
    );
    setProcessPutItems(() => { return PutItems; });
    setProcessMakeItems(() => { return MakeItems; });
    setRowLength(() => { return PutItems.length > MakeItems.length ? PutItems.length : MakeItems.length; });

    setCompletedAmount(BigNumber(checkEmptyNull(props.content.completedAmount, 0)).toNumber());
    setProcessAmount(BigNumber(checkEmptyNull(props.content.processAmount, 0)).toNumber());
    setProcessingCount(BigNumber(checkEmptyNull(props.content.processingCount, 0)).toNumber());

    setClickStatus(() => {
      const currentClick = BigNumber(checkEmptyNull(props.content.completedAmount, 0)).dividedBy(BigNumber(checkEmptyNull(props.content.processingCount, 0))).toNumber();
      const totalClick = BigNumber(checkEmptyNull(props.content.processAmount, 0)).dividedBy(BigNumber(checkEmptyNull(props.content.processingCount, 0))).toNumber();
      return `${currentClick} / ${totalClick}`;
    });
    setWorkStatus(`${BigNumber(checkEmptyNull(props.content.completedAmount, 0)).toNumber()} / ${BigNumber(checkEmptyNull(props.content.processAmount, 0))}`);

    setViewStatus(() => { return props.viewStatus });
  }, [props]);

  useEffect(() => { }, [_viewStatus])

  /* ========================================================================= #4 */
  /* ========================================================================= #5 */
  const actForceEnd = async (process) => {
    const confirmText = `
    남은 작업을 완료하지않고,
    작업을 마치겠습니까?
    `;
    if (window.confirm(confirmText) === true) props.forceEndStatusEvent(process, 'cancel');
  };
  const actCancel = async (process) => {
    const confirmText = `
    [ 취소 ]상태로 바꿀 시,
    공정 상태를 다시 변경할 수 없습니다.
    공정을 취소하시겠습니까?
    `;
    if (window.confirm(confirmText) === true) props.cancelStatusEvent(process, 'cancel');
  };

  /* ========================================================================= #6 */
  const returnItems = () => {
    let returnArray = [];
    for (let i = 1; i < _rowLength; i++) {
      returnArray.push(
        <tr key={i + '_productRows'}>
          {ProcessMakeItems[i] ? (
            <Fragment>
              {_viewStatus === 'detail' ? (
                <>
                  <td>{ProcessMakeItems[i].elementName}</td>
                  <td>{ProcessMakeItems[i].elementCode}</td>
                  <td className="amountCell">
                    {ProcessData.processingType === 'passive' ? (
                      <>
                        '후처리' : {checkEmptyNull(_processAmount, false)
                          ? BigNumber(checkEmptyNull(ProcessMakeItems[i].amount, 0)).multipliedBy(BigNumber(checkEmptyNull(_processAmount, 0))).toNumber().toLocaleString()
                          : checkEmptyNull(ProcessMakeItems[i].amount, 0).toLocaleString()
                        }
                      </>
                    ) : null}
                  </td>
                  <td className="amountCell">{checkEmptyNull(ProcessMakeItems[i].elementStock, 0).toLocaleString()}</td>
                  <td className="amountCell">{BigNumber(checkEmptyNull(ProcessMakeItems[i].makeAmount, 0)).plus(BigNumber(checkEmptyNull(ProcessMakeItems[i].failedAmount, 0))).toNumber().toLocaleString()}</td>
                  <td className="amountCell">{BigNumber(checkEmptyNull(ProcessMakeItems[i].makeAmount, 0)).toNumber().toLocaleString()}</td>
                  <td className="amountCell">{BigNumber(checkEmptyNull(ProcessMakeItems[i].failedAmount, 0)).toNumber().toLocaleString()}</td>
                </>
              ) : (
                <>
                  <td>{ProcessMakeItems[i].elementName}<br />{`(${ProcessMakeItems[i].elementCode})`}</td>
                  <td className="amountCell">
                    {ProcessData.processingType === 'passive' ? (
                      <>
                        '후처리' : {checkEmptyNull(_processAmount, false)
                          ? BigNumber(checkEmptyNull(ProcessMakeItems[i].amount, 0)).multipliedBy(BigNumber(checkEmptyNull(_processAmount, 0))).toNumber().toLocaleString()
                          : checkEmptyNull(ProcessMakeItems[i].amount, 0).toLocaleString()
                        }
                      </>
                    ) : null}
                  </td>
                  <td className="amountCell">{BigNumber(checkEmptyNull(ProcessMakeItems[i].makeAmount, 0)).plus(BigNumber(checkEmptyNull(ProcessMakeItems[i].failedAmount, 0))).toNumber().toLocaleString()}</td>
                  <td className="amountCell">{BigNumber(checkEmptyNull(ProcessMakeItems[i].makeAmount, 0)).toNumber().toLocaleString()}</td>
                  <td className="amountCell">{BigNumber(checkEmptyNull(ProcessMakeItems[i].failedAmount, 0)).toNumber().toLocaleString()}</td>
                </>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {_viewStatus === 'detail' ? (
                <>
                  <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                  <td className="blankCell"></td>
                  <td className="amountCell"></td>
                  <td className="amountCell"></td>
                  <td className="amountCell"></td>
                  <td className="amountCell"></td>
                  <td className="amountCell"></td>
                </>
              ) : (
                <>
                  <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                  <td className="amountCell"></td>
                  <td className="amountCell"></td>
                  <td className="amountCell"></td>
                  <td className="amountCell"></td>
                </>
              )}
            </Fragment>
          )}
          {ProcessPutItems[i] ? (
            <Fragment>
              {_viewStatus === 'detail' ? (
                <>
                  <td>{ProcessPutItems[i].elementName}</td>
                  <td>{ProcessPutItems[i].elementCode}</td>
                  <td className="amountCell">{checkEmptyNull(ProcessPutItems[i].amount, 0).toLocaleString()}</td>
                  <td className="amountCell">{BigNumber(checkEmptyNull(ProcessPutItems[i].amount, 0)).multipliedBy(BigNumber(checkEmptyNull(_processAmount, 0))).toNumber().toLocaleString()}</td>
                  <td className="amountCell">{checkEmptyNull(ProcessPutItems[i].elementStock, 0).toLocaleString()}</td>
                  {/* <ElementStockLogButton style={{ minWidth: '120px', width: '120px' }} onClick={(e) => {
                    e.preventDefault();
                    props.showInputElementStockLog(ProcessPutItems[i], ProcessData);
                  }}>
                    투입추적
                    <GuideText />
                  </ElementStockLogButton> */}
                </>
              ) : (
                <>
                  <td>{ProcessPutItems[i].elementName}<br />{`(${ProcessPutItems[i].elementCode})`}</td>
                  <td className="amountCell">{BigNumber(checkEmptyNull(ProcessPutItems[i].amount, 0)).multipliedBy(BigNumber(checkEmptyNull(_processAmount, 0))).toNumber().toLocaleString()}</td>
                  {/* <ElementStockLogButton style={{ minWidth: '120px', width: '120px' }} onClick={(e) => {
                    e.preventDefault();
                    props.showInputElementStockLog(ProcessPutItems[i], ProcessData);
                  }}>
                    투입추적
                    <GuideText />
                  </ElementStockLogButton> */}
                </>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {_viewStatus === 'detail' ? (
                <>
                  <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                  <td className="blankCell"></td>
                  <td className="amountCell"></td>
                  <td className="amountCell"></td>
                  <td className="amountCell"></td>
                  {/* <td style={{ minWidth: '120px', width: '120px' }}></td> */}
                </>
              ) : (
                <>
                  <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                  <td className="amountCell"></td>
                  {/* <td style={{ minWidth: '120px', width: '120px' }}></td> */}
                </>
              )}
            </Fragment>
          )}
        </tr>
      );
    }
    return returnArray;
  };

  return (
    <Fragment>
      <tr data-key={ProcessData.processLogId}>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ minWidth: '150px', padding: 'unset', width: '150px' }}>
          <StatusButton
            workOrderCode={ProcessData.processLogId}
            status={ProcessData.processStatus}
            statusStyle={
              (_authority.indexOf('501-2') !== -1 || _authority.indexOf('501-3') !== -1 || _authority.indexOf('501-4') !== -1)
                ? ProcessData.processStatus === 'end' ? { cursor: 'default', minHeight: '150px' }
                  : ProcessData.processStatus === 'cancel' ? { cursor: 'default', minHeight: '150px' }
                    : { cursor: 'pointer', minHeight: '150px' }
                : { cursor: 'pointer', minHeight: '150px' }
            }
            statusEvent={
              (_authority.indexOf('501-2') !== -1 || _authority.indexOf('501-3') !== -1 || _authority.indexOf('501-4') !== -1)
                ? () => { props.setProcessStatus(ProcessData); }
                : null
            }
          />
        </td>
        <td
          rowSpan={_rowLength === 0 ? 1 : _rowLength}
          style={{ backgroundColor: ProcessData.target && 'var(--MainNavy)', color: ProcessData.target && 'var(--white)', minWidth: '60px', width: '60px' }}
        >
          {ProcessData.processNumber}
        </td>
        {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? (
          <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ minWidth: '60px', width: '60px' }}>
            <div onClick={(e) => { e.stopPropagation(); props.showFileList(ProcessData, true); }}><DOCButtonIcon /></div>
          </td>
        ) : null}
        {_viewStatus === 'detail' ? (
          <>
            <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{ProcessData.processTypeName}</td>
            <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{ProcessData.processCode}</td>
            <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{ProcessData.processName}</td>
            <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{ProcessData.processScheduledStartDate}</td>
            <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{ProcessData.place?.placeName}</td>
            <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{ProcessData.remark}</td>
          </>
        ) : (
          <>
            <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{ProcessData.processCode}<br />{`(${ProcessData.processName})`}</td>
          </>
        )}
        {/* <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{ProcessData.processCode}<br />{`(${ProcessData.processName})`}</td>
        {props.workOrderTypeName !== '완제품' ? (
          <>
            <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{_clickStatus}</td>
            <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{_workStatus}</td>
          </>
        ) : null} */}

        {ProcessMakeItems[0] ? (
          <Fragment>
            {_viewStatus === 'detail' ? (
              <>
                <td>{ProcessMakeItems[0].elementName}</td>
                <td>{ProcessMakeItems[0].elementCode}</td>
                <td className="amountCell">
                  {ProcessData.processingType === 'passive' ? (
                    <>
                      '후처리' : {checkEmptyNull(_processAmount, false)
                        ? BigNumber(checkEmptyNull(ProcessMakeItems[0].amount, 0)).multipliedBy(BigNumber(checkEmptyNull(_processAmount, 0))).toNumber().toLocaleString()
                        : checkEmptyNull(ProcessMakeItems[0].amount, 0).toLocaleString()
                      }
                    </>
                  ) : null}
                </td>
                <td className="amountCell">{checkEmptyNull(ProcessMakeItems[0].elementStock, 0).toLocaleString()}</td>
                <td className="amountCell">{BigNumber(checkEmptyNull(ProcessMakeItems[0].makeAmount, 0)).plus(BigNumber(checkEmptyNull(ProcessMakeItems[0].failedAmount, 0))).toNumber().toLocaleString()}</td>
                <td className="amountCell">{BigNumber(checkEmptyNull(ProcessMakeItems[0].makeAmount, 0)).toNumber().toLocaleString()}</td>
                <td className="amountCell">{BigNumber(checkEmptyNull(ProcessMakeItems[0].failedAmount, 0)).toNumber().toLocaleString()}</td>
              </>
            ) : (
              <>
                <td>{ProcessMakeItems[0].elementName}<br />{`(${ProcessMakeItems[0].elementCode})`}</td>
                <td className="amountCell">
                  {ProcessData.processingType === 'passive' ? (
                    <>
                      '후처리' : {checkEmptyNull(_processAmount, false)
                        ? BigNumber(checkEmptyNull(ProcessMakeItems[0].amount, 0)).multipliedBy(BigNumber(checkEmptyNull(_processAmount, 0))).toNumber().toLocaleString()
                        : checkEmptyNull(ProcessMakeItems[0].amount, 0).toLocaleString()
                      }
                    </>
                  ) : null}
                </td>
                <td className="amountCell">{BigNumber(checkEmptyNull(ProcessMakeItems[0].makeAmount, 0)).plus(BigNumber(checkEmptyNull(ProcessMakeItems[0].failedAmount, 0))).toNumber().toLocaleString()}</td>
                <td className="amountCell">{BigNumber(checkEmptyNull(ProcessMakeItems[0].makeAmount, 0)).toNumber().toLocaleString()}</td>
                <td className="amountCell">{BigNumber(checkEmptyNull(ProcessMakeItems[0].failedAmount, 0)).toNumber().toLocaleString()}</td>
              </>
            )}
          </Fragment>
        ) : (
          <Fragment>
            {_viewStatus === 'detail' ? (
              <>
                <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                <td className="blankCell"></td>
                <td className="amountCell"></td>
                <td className="amountCell"></td>
                <td className="amountCell"></td>
                <td className="amountCell"></td>
                <td className="amountCell"></td>
              </>
            ) : (
              <>
                <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                <td className="amountCell"></td>
                <td className="amountCell"></td>
                <td className="amountCell"></td>
                <td className="amountCell"></td>
              </>
            )}
          </Fragment>
        )}
        {ProcessPutItems[0] ? (
          <Fragment>
            {_viewStatus === 'detail' ? (
              <>
                <td>{ProcessPutItems[0].elementName}</td>
                <td>{ProcessPutItems[0].elementCode}</td>
                <td className="amountCell">{checkEmptyNull(ProcessPutItems[0].amount, 0).toLocaleString()}</td>
                <td className="amountCell">{BigNumber(checkEmptyNull(ProcessPutItems[0].amount, 0)).multipliedBy(BigNumber(checkEmptyNull(_processAmount, 0))).toNumber().toLocaleString()}</td>
                <td className="amountCell">{checkEmptyNull(ProcessPutItems[0].elementStock, 0).toLocaleString()}</td>
                {/* <ElementStockLogButton style={{ minWidth: '120px', width: '120px' }} onClick={(e) => {
                  e.preventDefault();
                  props.showInputElementStockLog(ProcessPutItems[0], ProcessData);
                }}>
                  투입추적
                  <GuideText />
                </ElementStockLogButton> */}
              </>
            ) : (
              <>
                <td>{ProcessPutItems[0].elementName}<br />{`(${ProcessPutItems[0].elementCode})`}</td>
                <td className="amountCell">{BigNumber(checkEmptyNull(ProcessPutItems[0].amount, 0)).multipliedBy(BigNumber(checkEmptyNull(_processAmount, 0))).toNumber().toLocaleString()}</td>
                {/* <ElementStockLogButton style={{ minWidth: '120px', width: '120px' }} onClick={(e) => {
                  e.preventDefault();
                  props.showInputElementStockLog(ProcessPutItems[0], ProcessData);
                }}>
                  투입추적
                  <GuideText />
                </ElementStockLogButton> */}
              </>
            )}
          </Fragment>
        ) : (
          <Fragment>
            {_viewStatus === 'detail' ? (
              <>
                <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                <td className="blankCell"></td>
                <td className="amountCell"></td>
                <td className="amountCell"></td>
                <td className="amountCell"></td>
                {/* <td style={{ minWidth: '120px', width: '120px' }}></td> */}
              </>
            ) : (
              <>
                <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
                <td className="amountCell"></td>
                {/* <td style={{ minWidth: '120px', width: '120px' }}></td> */}
              </>
            )}
          </Fragment>
        )}

        {userReducer.user.team.teamRoleList.findIndex((thisIndex) => thisIndex.authority.startsWith('108')) !== -1 ? (
          <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{ProcessData.equipmentName}</td>
        ) : null}

        {_viewStatus === 'detail' ? (
          <>
            <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
              {checkNullArray(ProcessData.managers, []).map((worker, index) => { return <p key={index + '_managers'}>{worker.managerName}</p>; })}
            </td>
            <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
              {checkNullArray(ProcessData.workers, []).map((worker, index) => { return <p key={index + '_workers'}>{worker.workerName}</p>; })}
            </td>
          </>
        ) : null}

        {ProcessData.processStatus === 'work' ? (
          <WorkerStatusButton
            rowSpan={_rowLength === 0 ? 1 : _rowLength}
            style={{ backgroundColor: 'var(--MainRed)', minWidth: '60px', width: '60px' }}
            onClick={() => { actForceEnd(ProcessData); }}
          >
            강제완료
          </WorkerStatusButton>
        ) : (
          <td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ minWidth: '60px', width: '60px' }}></td>
        )}
        {(ProcessData.processStatus !== 'cancel' && ProcessData.processStatus !== 'end') ? (
          <WorkerStatusButton rowSpan={_rowLength === 0 ? 1 : _rowLength}
            style={{ minWidth: '60px', width: '60px' }}
            onClick={() => { actCancel(ProcessData); }}
          >
            취소
          </WorkerStatusButton>
        ) : (<td rowSpan={_rowLength === 0 ? 1 : _rowLength} style={{ minWidth: '60px', width: '60px' }}></td>)}
      </tr>
      {_rowLength > 1 && returnItems()}
    </Fragment>
  );
};

export default WorkerScreenProcessRow;
