import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

import { userApi } from 'api/apis/userApi';

const DownloadModal = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 6px;
  }
`;

const MainContents = styled.div`
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 5px 20px;
  width: 100%;
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ModalButton = styled.div`
  align-items: center;
  background-color: var(--ThirdGreen);
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 400;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const SearchContents = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 150px calc(100% - 160px);
  margin-top: 5px;
  width: 100%;

  &.detail {
    grid-template-columns: 150px calc(100% - 210px) 50px;
  }

  select {
    border: 1px solid var(--ThirdGreen);
    border-radius: 5px;
    box-sizing: border-box;
    height: fit-content;
    padding: 10px;
    width: 100%;

    &.detail {
      appearance: none;
      --webkit-appearance: none;

      background-color: var(--ThirdGreen);
      color: var(--white);
    }
  }

  input {
    border: 1px solid var(--ThirdGreen);
    border-radius: 5px;
    box-sizing: border-box;
    height: fit-content;
    /* outline: var(--ThirdGreen); */
    padding: 10px;
    width: 100%;

    &:focus {
      border: 1px solid var(--ThirdGreen);
    }
  }
`;

const LoadingScreen = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  color: var(--white);
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 999;
`;

const UserDownloadModal = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_searchData, setSearchData] = useState({});

  const [_loadingStatus, setLoadingStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    setOnload('loaded');
  }, []);

  /* ====================================================================== #4 */
  const actDownload = async () => {
    setLoadingStatus(true);

    const paging = `?page=0&size=7000&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    if (
      _searchData.teamName !== undefined &&
      _searchData.teamName !== null &&
      _searchData.teamName !== ''
    ) {
      BodyToPost.teamName = _searchData.teamName;
    }
    if (
      _searchData.name !== undefined &&
      _searchData.name !== null &&
      _searchData.name !== ''
    ) {
      BodyToPost.name = _searchData.name;
    }
    console.log('getUserList - BodyToPost : ', BodyToPost);

    await userApi
      .searchUser(paging, BodyToPost)
      .then((response) => {
        if (response === undefined) return;
        console.log('userApi.searchUser : ', response);

        setExcelData(response.data.content);
      })
      .finally(() => {
        setLoadingStatus(false);
        props.close();
      });
  };
  const setExcelData = (Result) => {
    const userList = Result;

    const excelList = userList.map((thisItem) => {
      const returnData = {
        이름: thisItem.name,
        구글계정: thisItem.email,
        팀:
          thisItem.team !== undefined &&
          thisItem.team !== null &&
          thisItem.team.teamName !== null
            ? thisItem.team.teamName
            : '',
        동의여부: thisItem.permission !== true ? '비동의' : '동의',
      };

      return returnData;
    });
    console.log('excelList : ', excelList);

    const ws = XLSX.utils.json_to_sheet(excelList);
    const wb = XLSX.utils.book_new();

    let fileTitle = '사용자 리스트';
    XLSX.utils.book_append_sheet(wb, ws, fileTitle);
    XLSX.writeFile(wb, fileTitle + '.xlsx');

    // props.close();
  };

  /* ====================================================================== #5 */

  /* ====================================================================== #6 */

  return (
    <>
      <DownloadModal>
        <MainContents>
          <div>검색</div>
          <SearchContents>
            <select className="SearchOption detail">
              <option value="elementName">팀</option>
            </select>
            <input
              className="SearchBar detail"
              placeholder="Search..."
              value={_searchData.teamName || ''}
              onInput={(e) => {
                setSearchData((prev) => {
                  return { ...prev, teamName: e.target.value };
                });
              }}
            />
          </SearchContents>
          <SearchContents>
            <select className="SearchOption detail">
              <option value="elementName">이름</option>
            </select>
            <input
              className="SearchBar detail"
              placeholder="Search..."
              value={_searchData.name || ''}
              onInput={(e) => {
                setSearchData((prev) => {
                  return { ...prev, name: e.target.value };
                });
              }}
            />
          </SearchContents>
        </MainContents>
      </DownloadModal>

      <ModalFooter>
        <ModalButton onClick={actDownload}>다운로드</ModalButton>
      </ModalFooter>

      {_loadingStatus === true ? (
        <LoadingScreen>다운로드하고 있습니다...</LoadingScreen>
      ) : null}
    </>
  );
};

export default UserDownloadModal;
