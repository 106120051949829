export const stockMovingActions_setElementType = (elementType) => { return { type: 'SETSTOCKMOVINGTYPE', payload: elementType }; };
export const stockMovingActions_setStartDate = (date) => { return { type: 'SETSTOCKMOVINGSTARTDATE', payload: date }; };
export const stockMovingActions_setEndDate = (date) => { return { type: 'SETSTOCKMOVINGENDDATE', payload: date }; };
export const stockMovingActions_setSearchData = (searchData) => { return { type: 'SETSTOCKMOVINGSEARCHDATA', payload: searchData }; };
export const stockMovingActions_setSearchAttrs = (serachAttrs) => { return { type: 'SETSTOCKMOVINGSEARCHATTS', payload: serachAttrs }; };

export const stockMovingActions_setLevel1Categories = (category) => { return { type: 'SETSTOCKMOVINGLEVEL1CATEGORIES', payload: category }; };
export const stockMovingActions_setLevel2Categories = (category) => { return { type: 'SETSTOCKMOVINGLEVEL2CATEGORIES', payload: category }; };
export const stockMovingActions_setLevel3Categories = (category) => { return { type: 'SETSTOCKMOVINGLEVEL3CATEGORIES', payload: category }; };
export const stockMovingActions_setLevel4Categories = (category) => { return { type: 'SETSTOCKMOVINGLEVEL4CATEGORIES', payload: category }; };
export const stockMovingActions_setLevel5Categories = (category) => { return { type: 'SETSTOCKMOVINGLEVEL5CATEGORIES', payload: category }; };
export const stockMovingActions_setClickedCategory = (category) => { return { type: 'SETSTOCKMOVINGCLICKEDCATEGORY', payload: category }; };
export const stockMovingActions_setClickedLevel1Category = (category) => { return { type: 'SETSTOCKMOVINGCLICKEDLEVEL1CATEGORY', payload: category }; };
export const stockMovingActions_setClickedLevel2Category = (category) => { return { type: 'SETSTOCKMOVINGCLICKEDLEVEL2CATEGORY', payload: category }; };
export const stockMovingActions_setClickedLevel3Category = (category) => { return { type: 'SETSTOCKMOVINGCLICKEDLEVEL3CATEGORY', payload: category }; };
export const stockMovingActions_setClickedLevel4Category = (category) => { return { type: 'SETSTOCKMOVINGCLICKEDLEVEL4CATEGORY', payload: category }; };
export const stockMovingActions_setClickedLevel5Category = (category) => { return { type: 'SETSTOCKMOVINGCLICKEDLEVEL5CATEGORY', payload: category }; };

export const stockMovingActions_setPageNumber = (pageNum) => { return { type: 'SETSTOCKMOVINGPAGENUMBER', payload: pageNum }; };
export const stockMovingActions_setTotalSize = (totalSize) => { return { type: 'SETSTOCKMOVINGTOTALSIZE', payload: totalSize }; };
export const stockMovingActions_setIsPushedSearchButton = (status) => { return { type: 'SETSTOCKMOVINGISPUSHEDSEARCHBUTTON', payload: status }; };

export const stockMovingActions_selectOneElement = (element) => { return { type: 'SELECTONESTOCKMOVING', payload: element }; };
export const stockMovingActions_selectElements = (elements) => { return { type: 'SELECTSTOCKMOVINGS', payload: elements }; };

export const stockMovingActions_setReset = () => { return { type: 'SETSTOCKMOVINGRESET' }; };
