import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import styled from 'styled-components';

import {
  modalReducer_setCurrentPageNum,
  modalReducer_setPageSize,
  modalReducer_setTotalCount,
} from 'store/modules/actions/default/modalActions';

import {
  AXIOS_BASE_URL,
  AXIOS_BASE_HEADERS,
} from 'preferences/server/constants';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import NavBar from 'components/nav/NavBar';
import Paging from 'components/paging/Paging';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 40px 50px;
  height: 85%;
  /* max-height: 300px; */
  /* max-width: 400px; */
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  /* height: calc(100% - 55px); */
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const CommonSelectUserModal = (props) => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const { modalReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_userList, setUserList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());
  // console.log('_checkedItem : ', _checkedItem);

  const [_searchOption, setSearchOption] = useState('');
  const [_searchText, setSearchText] = useState('');

  /* ========================================================================= #3 */
  useEffect(() => {
    dispatch(modalReducer_setCurrentPageNum(1));
    dispatch(modalReducer_setPageSize(10));

    getUserList(0);

    setOnload('loaded');
    return () => {};
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getUserList(modalReducer.currentPageNum - 1);
    }

    return () => {};
  }, [modalReducer.currentPageNum]);

  /* ========================================================================= #4 */
  const getUserList = async (page, clear) => {
    const paging = `?page=${page}&size=${modalReducer.pageSize}`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    if (
      !clear &&
      _searchOption !== null &&
      _searchOption !== '' &&
      _searchText !== null &&
      _searchText !== ''
    ) {
      BodyToPost[_searchOption] = _searchText;
    }
    console.log('getUserList - BodyToPost : ', BodyToPost);

    await axios
      .post(
        AXIOS_BASE_URL + '/user/search' + paging,
        BodyToPost,
        AXIOS_BASE_HEADERS,
      )
      .then((response) => {
        if (response === undefined) return;
        console.log('getUserList - response : ', response.data);
        setUserList(() => {
          return response.data.content;
        });

        dispatch(modalReducer_setTotalCount(response.data.totalElements));
      })
      .catch((error) => {
        console.log('getUserList - error : ', error);
      });
  };

  /* ========================================================================= #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) {
      checkedItem.add(id);
    }
    setCheckedItem(() => {
      return checkedItem;
    });
  };

  /* 검색 */
  const actSearch = async () => {
    if (_searchOption === '' || _searchText === null) return;

    dispatch(modalReducer_setCurrentPageNum(1));
    await getUserList(0);
  };

  /* 초기화 */
  const actReset = async () => {
    setSearchText(() => {
      return '';
    });

    setSearchOption(() => {
      return '';
    });

    dispatch(modalReducer_setCurrentPageNum(1));
    await getUserList(0);
  };

  /* 수정 */
  const actAddUser = () => {
    const addContentIndex = _userList.findIndex(
      (thisItem) => thisItem.id === Array.from(_checkedItem)[0],
    );
    if (addContentIndex === -1) return alert('사용자를 선택해 주세요.');
    const addContent = _userList[addContentIndex];
    console.log('addContent : ', addContent);

    props.userEvent(addContent);
    setTimeout(() => {
      props.close();
    }, 1000);
  };

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>사용자/작업자 선택</ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              <NavBar
                title={
                  <>
                    <div className="ContentCBox">
                      <div className="ContentCBox">
                        <select
                          className="SearchOption"
                          value={_searchOption}
                          onChange={(e) => {
                            setSearchOption(() => {
                              return e.target.value;
                            });
                          }}
                        >
                          <option value="">검색옵션</option>
                          <option value="name">이름</option>
                          <option value="teamName">팀</option>
                        </select>
                        <input
                          className="SearchBar"
                          placeholder="Search..."
                          value={_searchText}
                          onInput={(e) => {
                            setSearchText(() => {
                              return e.target.value;
                            });
                          }}
                        />
                      </div>

                      <button data-searchbutton="true" onClick={actSearch}>
                        <SearchButtonIcon />
                      </button>
                      <div className="ResetButton" onClick={actReset}>
                        초기화
                      </div>
                    </div>
                  </>
                }
                nav={''}
              />

              <TableSection
                content={
                  <table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: 'unset', width: '50px' }}></th>
                        <th>이름</th>
                        <th>구글계정</th>
                        <th>팀</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_userList.map((thisItem) => {
                        return (
                          <tr key={thisItem.id}>
                            <td style={{ minWidth: 'unset', width: '50px' }}>
                              <input
                                type="checkBox"
                                name="users"
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  handleCheckedItem(thisItem.id, isChecked);

                                  if (isChecked) {
                                    document
                                      .getElementsByName('users')
                                      .forEach((thisAttr) => {
                                        thisAttr.checked = false;
                                      });

                                    e.target.checked = true;
                                  } else {
                                    e.target.checked = false;
                                  }
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              />
                            </td>
                            <td>{thisItem.name}</td>
                            <td>{thisItem.email}</td>
                            <td>
                              {thisItem.team !== undefined &&
                                thisItem.team !== null &&
                                thisItem.team.teamName}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                }
              />
            </ModalMain>

            <Paging
              page={modalReducer.currentPageNum}
              count={modalReducer.totalCount}
              size={modalReducer.pageSize}
              modal={true}
            />

            <ModalFooter>
              <ModalButton onClick={actAddUser}>사용자/작업자 선택</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default CommonSelectUserModal;
