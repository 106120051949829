import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { qna } from 'api/apis/mall/qna';

const FileBox = styled.div`
  align-items: center;
  background-color: var(--Bg);
  box-sizing: border-box;
  color: var(--gray-800);
  cursor: pointer;
  display: flex;
  height: 200px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  & img {
    height: auto;
    max-height: 100px;
    max-width: 100px;
    width: auto;
  }
  &:hover {opacity: 0.8;}
`;

const MallQna = (props) => {
  /* ====================================================================== #1 */
  const location = useLocation();
  const navigate = useNavigate();
  const { userReducer } = useSelector(state => state);

  /* ====================================================================== #2 */
  const companyCode = location.pathname.split('/')[1];

  const [_formData, setFormData] = useState({
    companyId: userReducer.user.companyId,
    companyCode: companyCode,
    userId: userReducer.user.id,
    status: 'waiting',
    mallQnAContent: '',
    mallQnAElementList: [],
  });

  const [_fileDataList, setFileDataList] = useState([]);

  const [_createStatus, setCreateStatus] = useState(false);

  /* ====================================================================== #3 */
  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value }; });
  };

  const handleFileEvent = (type, e) => {
    let files = [];
    switch (type) {
      case 'click': files = e.target.files; break;
      case 'drag': files = e.dataTransfer.files; break;
      default: return;
    }

    if (files === undefined || files === null || files.length === 0) return;
    const setFiles = Array.prototype.slice.call(files);
    setFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onload = () => {
          const returnData = file;
          returnData['preview'] = reader.result;
          setFileDataList((prev) => { return [...prev, returnData]; });
          resolve();
        };
      });
    });
  };

  const actDeleteFileData = (file, index) => {
    setFileDataList((prev) => {
      const returnData = [...prev];
      returnData.splice(index, 1);
      return returnData;
    });
  };

  const actCreate = async (e) => {
    e.preventDefault();
    setCreateStatus(() => { return true });

    const BodyToPost = { ..._formData };
    const postFormData = new FormData();
    postFormData.append('key', new Blob([JSON.stringify(BodyToPost)], { type: 'application/json' }));
    if (_fileDataList.length > 0) {
      _fileDataList.forEach((file) => { postFormData.append('multipartFileList', file); });
    }
    for (let values of postFormData.values()) {
      console.log('postFormData : ', values);
    }

    await qna.createMallQnA(postFormData).then((response) => {
      if (response === undefined) return;
      console.log('qna.createMallQnA : ', response);
      alert('문의를 등록했습니다.');
      setCreateStatus(() => { return false });
      props.update();
      navigate(`/${companyCode}/mall/mypage/qna/list`, { replace: true });
    }).catch(error => {
      setCreateStatus(() => { return false });
    });
  };

  /* ====================================================================== #6 */

  return (
    <>
      <div className='qna-sub'>
        <h2 className='qna-sub-title'>문의등록</h2>
        <div className='qna-sub-info' style={{ gridRowStart: 2, gridRowEnd: 4 }}>
          <div className='qna-sub-info-content'>
            <p>문의내용
              <textarea
                type="text"
                name="mallQnAContent"
                placeholder="문의 내용을 이곳에 작성해주세요."
                value={_formData.mallQnAContent}
                onInput={handleInputEvent}
              />
            </p>
            <p>
              첨부파일
              <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                multiple
                onChange={(e) => { handleFileEvent('click', e); }}
              />
              <label
                htmlFor="fileInput"
                className='list-file'
                onDragEnter={(e) => { e.stopPropagation(); e.preventDefault(); }}
                onDragLeave={(e) => { e.stopPropagation(); e.preventDefault(); }}
                onDragOver={(e) => { e.stopPropagation(); e.preventDefault(); }}
                onDrop={(e) => { e.preventDefault(); handleFileEvent('drag', e); }}
              >
                <div className='file-icon'></div>
                <p>첨부 파일 드래그 또는 <span>선택</span></p>
              </label>
            </p>
          </div>
          {_fileDataList.map((fileData, index) => {
            return (
              <FileBox key={index + '_fileDatas'}
                title='파일을 클릭하면 삭제됩니다.'
                onClick={() => { actDeleteFileData(fileData, index); }}
              >
                {(() => {
                  const returnArray = [];
                  if (fileData.attachFileId === undefined) {
                    if (fileData.type && fileData.type.startsWith('image')) {
                      returnArray.push(<img key={index + '_fileDataImg'} src={fileData.preview} alt={fileData.name} />);
                    } else {
                      returnArray.push(<>{fileData.name}</>);
                    }
                  } else {
                    if (fileData.fileData) {
                      if (fileData.fileData.fileDataType && fileData.fileData.fileDataType.startsWith('image')) {
                        returnArray.push(<img key={index + '_fileDataImg'} src={fileData.fileData.fileDataS3URL} alt={fileData.fileData.fileDataName} />);
                      } else {
                        returnArray.push(<span key={index + '_fileDataImg'}>{fileData.fileData.fileDataName}</span>);
                      }
                    } else return;
                  }
                  return returnArray;
                })()}
              </FileBox>
            );
          })}
        </div>
        <div className='qna-sub-btn'>
          {!_createStatus ? <button className="qna-sub-submit" onClick={actCreate}>문의등록</button> : null}
        </div>
      </div>
    </>
  );
};

export default MallQna;
