import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { stockMoving } from 'api/apis/stock/stockMoving';
import { stockPlace } from 'api/apis/stock/stockPlace';

import { checkEmptyNull, checkNullObject } from 'components/checkValues/checkValues';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import SelectOneStockPlaceHistory from 'pages/stockPlaceHistory/modal/SelectOneStockPlaceHistory';
import TableSection from 'components/layouts/table/TableSection';

const Section = styled.main`
  & .formBox {
    &.w50 {width: 50%;}
    & .formTitleWrap {
      display: flex;
      justify-content: space-between;
      width: 100%;

      & .formButton {
        background-color: var(--ThirdBlue);
        border-radius: 5px;
        width: 150px;
      }
    }

    & .elementInfo {
      background-color: var(--white);
      box-sizing: border-box;
      display: grid;
      gap: 10px;
      padding: 20px;
      margin-top: 10px;
    }
    
    & textarea {
      margin-top: 10px;
      min-height: 174px;
    }

    & .TableSection {
      td, th {min-width: unset;}
      & td input[name="amount"]{text-align: right;}
      & td.alert{
        height: 75px;
        padding-block: 5px;
        position: relative;
        vertical-align: top;
        &::after {
          content: '이동수량은 재고수량을 초과할 수 없습니다.';
          color: var(--MainRed);
          font-size: 16px;
          position: absolute;
          right: 10px;
          width: fit-content;
        }
        & input[name="amount"]{
          background-color: var(--MainRed);
          box-sizing: border-box;
          color: var(--white);
          font-weight: 600;
          padding: 10px;
        }
      }
    }
  }
`;
const DeleteButton = styled.div`
  height: 15px;
  width: 15px;
  div {background-color: var(--MainRed); cursor: pointer; height: 20px; width: 20px;}
`;

const StockMoving = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userReducer } = useSelector((state) => state);

  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_stockPlaceList, setStockPlaceList] = useState([]); // 재고위치 리스트

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    movingStockElementList: [],
    note: '',
  })
  const [_sendElement, setSendElement] = useState({}); // 이동물품
  const [_receiveElements, setReceiveElements] = useState([]); // 이동목록

  const [_modalStatus, setModalStatus] = useState(false); // 이동물품 선택 모달

  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('308') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    /* 권한 */
    if (authorityCodes.indexOf('308-2') === -1) {
      // 읽기 & 쓰기 권한 없을 때 바로 재고이동 로그 페이지로 이동
      alert('권한이 없습니다.');
      navigate('/stock/moving/logs', { replace: true });
      return;
    } else {
      dispatch(pageReducer_getCurrentPage(window.location.pathname));
    }
    getStockPlaces();

    setOnload('loaded');
  }, []);
  useEffect(() => {
    return () => { }
  }, [_sendElement])
  useEffect(() => { }, [_receiveElements])

  /* 재고위치 불러오기 */
  const getStockPlaces = async () => {
    const BodyToPost = { companyId: userReducer.company.companyId };
    await stockPlace.searchStockPlace(BodyToPost).then((response) => {
      if (response === undefined) return;
      // console.log('stockPlace.searchStockPlace : ', response);
      setStockPlaceList(() => { return response.data });
    });
  }

  /* 이동수량 이벤트 핸들러 */
  const handlerReceivedAmount = (e, index, receiveElement) => {
    const eventValue = e.target.value * 1;
    const receiveElements = [..._receiveElements];
    receiveElements.splice(index, 1);
    const receivedAmounts = receiveElements.map(item => checkEmptyNull(item.amount, 0) * 1);
    const amount = receivedAmounts.reduce((a, b) => a + b, 0);
    if (eventValue + amount > checkEmptyNull(_sendElement.amount, 0)) {
      document.querySelectorAll('input[name="amount"]').forEach(item => item.closest('td').classList.add('alert'));
    } else {
      document.querySelectorAll('input[name="amount"]').forEach(item => item.closest('td').classList.remove('alert'));
    }
    setReceiveElements(prev => {
      const returnData = [...prev];
      const newData = { ...receiveElement, index: index, amount: e.target.value * 1 };
      console.log('newData : ', newData);
      returnData.splice(index, 1, newData);
      return returnData;
    })
  }

  /* 초기화 */
  const actReset = () => {
    setSendElement(() => { return {} });
    setReceiveElements(() => { return [] });
    setFormData(() => {
      return {
        companyId: userReducer.company.companyId,
        movingStockElementList: [],
        note: '',
      }
    })
  }

  /* 재고이동 저장 */
  const actSave = async (e) => {
    e.preventDefault();
    if (!checkNullObject(_sendElement, false)) return alert('이동물품을 확인해주세요.');
    const receiveElements = [..._receiveElements];
    let stockPlaceStatus = false;
    const receivedAmounts = receiveElements.map(item => {
      if (!checkEmptyNull(item.stockPlaceId)) stockPlaceStatus = true;
      if (item.stockPlaceId === _sendElement.stockPlaceId) stockPlaceStatus = true;

      return checkEmptyNull(item.amount, 0) * 1;
    });
    console.log(receiveElements, receivedAmounts);
    const amount = receivedAmounts.reduce((a, b) => a + b, 0);
    if (stockPlaceStatus) return alert('이동위치를 확인해주세요.');
    if (amount === 0) return alert('이동수량을 확인해주세요.');
    if (amount > _sendElement.amount) return alert('이동수량은 재고수량을 초과할 수 없습니다.');

    const sendElement = {
      type: 'Send',
      elementId: _sendElement.elementId,
      stockPlaceId: _sendElement.stockPlaceId,
      amount: amount
    }
    const movingStockElementList = _receiveElements.map(element => {
      return {
        type: 'Receive',
        elementId: element.elementId,
        stockPlaceId: element.stockPlaceId,
        amount: element.amount,
      };
    });
    const body = {
      ..._formData,
      movingStockElementList: [sendElement, ...movingStockElementList]
    }
    console.log('body : ', body);
    const response = await stockMoving.movingStock(body);
    if (response?.data) {
      alert('재고가 이동되었습니다.');
      actReset();
      // navigate('/stock/moving/logs', { replace: true });
    }
  }

  return (
    <Grid2Body contents={
      <>
        <Section className='Main'>
          <NavBar
            title={<NavTitle menuCode={'308'} />}
            subNavTabBtnLink='/stock/moving/logs'
            subNavTabBtn='재고이동 이력'
            nav=''
          />

          <Form
            title={
              <>
                <NavTitle menuCode={'308'} />
                <br />
              </>
            }
            buttons={
              <>
                <button className="formButton" onClick={actSave}>저장</button>
                <button className="formButton cancle" onClick={(e) => { e.preventDefault(); actReset(); }}>초기화</button>
              </>
            }
            forms={
              <>
                <div className='formBox w50'>
                  <div className='formTitleWrap'>
                    <h4>이동물품</h4>
                    <div className="formButton" onClick={() => { setModalStatus(true) }}>물품 선택</div>
                  </div>
                  <div className='elementInfo'>
                    <div><span>물품이름: </span>{_sendElement.elementName}</div>
                    <div><span>물품코드: </span>{_sendElement.elementCode}</div>
                    <div><span>재고위치: </span>{_sendElement.stockPlaceName}</div>
                    <div><span>재고수량: </span>{checkEmptyNull(_sendElement.amount, 0).toLocaleString()}</div>
                  </div>
                </div>

                <div className='formBox'>
                  <h4>비고</h4>
                  <textarea
                    name='note'
                    value={_formData.note}
                    onInput={e => { setFormData(prev => { return { ...prev, note: e.target.value } }) }}
                  />
                </div>

                <div className='formBox' style={{ width: '100%' }}>
                  <div className='formTitleWrap'>
                    <h4>이동목록</h4>
                    <div className="formButton"
                      onClick={(e) => {
                        e.preventDefault();
                        if (!checkNullObject(_sendElement, false)) return alert('이동물품을 먼저 선택해주세요.');
                        setReceiveElements(prev => {
                          const returnData = [...prev];
                          returnData.push({
                            type: 'Receive',
                            elementId: _sendElement.elementId,
                            stockPlaceId: '',
                            amount: '',
                          })
                          return returnData;
                        })
                      }}
                    >
                      위치 추가
                    </div>
                  </div>
                  <TableSection content={
                    <table>
                      <colgroup>
                        <col width={'50px'} />
                        <col width={'49%'} />
                        <col width={'49%'} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th></th>
                          <th>이동위치</th>
                          <th>이동수량</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_receiveElements.map((receiveElement, index) => {
                          return (
                            <tr key={index + '_receiveElement'}>
                              <td>
                                <DeleteButton
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setReceiveElements(prev => {
                                      const returnData = [...prev];
                                      returnData.splice(index, 1);
                                      return returnData;
                                    })
                                  }}
                                >
                                  <CloseButtonIcon />
                                </DeleteButton>
                              </td>
                              <td>
                                <select
                                  key={index}
                                  value={checkEmptyNull(receiveElement.stockPlaceId, '')}
                                  onChange={(e) => {
                                    setReceiveElements(prev => {
                                      const returnData = [...prev];
                                      const newData = { ...receiveElement, stockPlaceId: e.target.value * 1 };
                                      returnData.splice(index, 1, newData);
                                      return returnData;
                                    })
                                  }}
                                >
                                  <option value={''}>이동위치 선택</option>
                                  {_stockPlaceList.map((stockPlace, stockPlaceIndex) => {
                                    return (<option key={index + '_' + stockPlaceIndex} value={stockPlace.stockPlaceId}>{stockPlace.placeName}</option>)
                                  })}
                                </select>
                              </td>
                              <td>
                                <input
                                  key={index}
                                  type='number'
                                  name='amount'
                                  max={_sendElement.amount}
                                  min={0}
                                  onInput={(e) => { handlerReceivedAmount(e, index, receiveElement) }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  } />
                </div>
              </>
            }
          />
        </Section>

        {_modalStatus === true ? (
          <SelectOneStockPlaceHistory
            modalTitle="이동물품 선택"
            buttonTitle="선택"
            buttonEvent={(data) => {
              setSendElement(() => { return data });
              setModalStatus(false);
            }}
            open={_modalStatus}
            close={() => { setModalStatus(false); }}
          />
        ) : null}
      </>
    } />
  );
};

export default StockMoving;