import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { chatReducer_setChatInput } from 'store/modules/actions/default/chatActions';

import { checkNull } from 'components/checkValues/checkValues';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const ModalBg = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  height: 100vh;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;

  ${(props) => {
    return props.chatStyle === 'WorkScreenModal' ? 'zoom: 100% !important' : null;
  }}
`;
const ModalSection = styled.div`
  background-color: #9bbbd4;
  border: 1px solid var(--gray-200);
  display: grid;
  grid-template-rows: 55px calc(100% - 55px);
  height: 85%;
  max-height: 750px;
  max-width: 450px;
  overflow: hidden;
  position: fixed;
  right: 60px;
  bottom: 100px;
  width: 90%;
  border-radius: 16px;
  box-shadow: 1px 4px 4px 0px #ccc;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--white);
  color: var(--Text);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  display: grid;
  grid-template-rows: auto 200px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  .TableSection {overflow: auto;}
`;
const ChatSection = styled.div`
  background-color: var(--gray-800);
  cursor: default;
  height: 100%;
  overflow-y: scroll;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}
`;
const ChatList = styled.div`
  box-sizing: border-box;
  color: var(--Text);
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 20px;
  row-gap: 15px;
  width: 100%;

  & p {color: var(--white);}
`;
const MyChatCon = styled.div`
  align-items: center;
  align-self: flex-end;
  column-gap: 20px;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  p {align-self: flex-end; font-size: 0.8em;}
`;
const UserChatCon = styled(MyChatCon)`
  align-self: unset;
  justify-content: unset;
  align-self: flex-start;
  justify-content: flex-start;
`;
const MyChat = styled.div`
  background-color: #fef01b;
  border-radius: 10px;
  box-sizing: border-box;
  max-width: 80%;
  /* min-width: 20%; */
  padding: 10px;
  width: fit-content;
  word-break: break-all;
`;
const UserChat = styled(MyChat)`
  background-color: var(--white);
`;

const SendSection = styled.div`
  background-color: var(--white);
  box-sizing: border-box;
  height: 100%;
  position: relative;
  width: 100%;
`;
const TextSection = styled.div`
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-bottom: 55px;
  width: 100%;

  textarea {
    box-sizing: border-box;
    border: none;
    font-size: 1.2em;
    height: 100%;
    outline: none;
    padding: 20px;
    resize: none;
    width: 100%;
  }
`;
const SendButton = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  border-radius: 4px;
  bottom: 10px;
  color: var(--gray-200);
  cursor: pointer;
  font-size: 0.8em;
  display: flex;
  height: 35px;
  justify-content: center;
  position: absolute;
  right: 10px;
  width: 60px;

  &.active {background-color: #fef01b; color: #556677;}
`;

const ChatRoom = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { chatReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const endRef = React.createRef();

  /* ====================================================================== #3 */
  useEffect(() => {
    scrollToBottom();

    return () => scrollToBottom();
  }, [chatReducer.chatList]);

  useEffect(() => {}, [chatReducer.chatInput]);

  /* ====================================================================== #4 */

  /* ====================================================================== #5 */
  const scrollToBottom = () => {
    if (!checkNull(endRef.current, false)) return;
    endRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const actSendChat = () => {
    if (chatReducer.chatRoomOpen === 'open') {
      props.sendMessage();
    }
  };
  const actEnterChat = (e) => {
    if (e.keyCode === 13 && chatReducer.chatRoomOpen === 'open') {
      props.sendMessage();
    }
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg
          chatStyle={props.chatStyle}
          onClick={(e) => {
            const clickSec = e.target.closest('#ChatRoomSection');
            if (clickSec === null) props.close();
          }}
        >
          <ModalSection id="ChatRoomSection">
            <ModalHeader>
              <ModalTitle>{userReducer.company.companyName}</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <ChatSection>
                <ChatList>
                  {chatReducer.chatList.map((chat, index) => {
                    if (chat.userId === userReducer.user.id) {
                      return (
                        <MyChatCon key={index + '_myChat'}>
                          <p>{chat.userName}</p>
                          <MyChat>{chat.message}</MyChat>
                        </MyChatCon>
                      );
                    } else {
                      return (
                        <UserChatCon key={index + '_userChat'}>
                          <UserChat>{chat.message}</UserChat>
                          <p>{chat.userName}</p>
                        </UserChatCon>
                      );
                    }
                  })}
                  <div ref={endRef}></div>
                </ChatList>
              </ChatSection>
              <SendSection>
                <TextSection>
                  <textarea
                    value={chatReducer.chatInput}
                    onKeyUp={actEnterChat}
                    onInput={(e) => {dispatch(chatReducer_setChatInput(e.target.value))}}
                  />
                </TextSection>
                <SendButton
                  className={chatReducer.chatInput.length > 0 ? 'active' : null}
                  onClick={() => {if (chatReducer.chatInput.length > 0) actSendChat()}}
                >
                  전송
                </SendButton>
              </SendSection>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default ChatRoom;
