import React, { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  height: 85%;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-shadow: 0 4px 18px 0 rgba(196,196,196,0.8);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: space-between;
  padding: 0px 60px 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(196,196,196,0.8);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: fit-content;
  justify-content: center;
  padding: 8px;
  width: 20%;
`;
const ToggleButton = styled.button`
  position: absolute;
  height: fit-content;
  right: 20px;
  top: 20px;
  width: fit-content;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  width: 100%;

  & .TableSection {height: 100%; overflow-x: auto; width: 100%;}
`;

const MainContents = styled.div`
  box-sizing: border-box;
  height: 100%;
  justify-content: center;
  padding: 10px;
  width: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {height: 8px; width: 8px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 4px;}
`;

const LoadingScreen = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  color: var(--white);
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

/* ========================= PrintContent ========================= */
const ExportSection = styled.div`
  align-items: center;
  /* background-color: var(--gray-200); */
  border: 1px solid var(--gray-200);
  box-sizing: border-box;
  display: flex;
  font-size: 0.75em;
  justify-content: center;
  margin: 30px auto;
  overflow: hidden;
  padding: 20px;
  row-gap: 30px;

  height: 297mm;
  width: 210mm;
`;

const PrintSection = styled.div`
  background-color: var(--white);
  height: 100%;
  width: 100%;
`;
const HeaderSection = styled.div`
  width: 100%;

  & h1 {
    height: 30px;
    letter-spacing: 100px; 
    text-align: center;
    transform: translateX(50px);
    width: inherit;
  }
  & .printHeader {
    align-items: center;
    display: flex;
    height: 30px;
    justify-content: space-between;
    width: inherit;
  }
`;
const CheckSection = styled.div`
  align-items: center;
  display: flex;
  gap: 150px;
  justify-content: center;
  width: inherit;

  & .checkBox {
    align-items: center;
    display: flex;
    font-size: 1.1em;
    gap: 6px;
    justify-content: center;

    &::before {
      content: '';
      border: 1px solid var(--Text);
      display: block;
      height: 18px;
      width: 18px;
    }
  }
`;

const PrintTable = styled.table`
  border-collapse: collapse;
  width: inherit;

  td, th {
    border: 1px solid var(--Text);
    height: 30px;
    text-align: center;
    vertical-align: middle;

    &.dataCell {width: 35%;}
    &[name="toggleCell"].hide {display: none;}
  }
`;

const PrintContent = (props) => {
  /* ====================================================================== #1 */
  /* ====================================================================== #2 */
  const [_printPage, setPrintPage] = useState(`${props.currentPage} / ${props.totalPage}`);
  const [_accountInformation, setAccountInformation] = useState(props.accountInformation);
  const [_companyInformation, setCompanyInformation] = useState(props.companyInformation);
  const [_deliveryData, setDeliveryData] = useState(props.deliveryData);
  const [_shipment, setProcurement] = useState(props.shipment);
  const [_shipmentElementList, setProcurementElementList] = useState(() => {
    const returnArray = [];
    for (let i = 0; i < parseFloat(10 - props.content.length); i++) {
      const emptyData = {
        empty: true,
        elementName: '',
        elementCode: '',

        price: 0, // 단가
        quantity: 0, // 수량
        calPrice: 0, // 금액

        elementStandard: '', // 규격
        elementDetailStandard: '', // 상세규격
      };

      returnArray.push(emptyData);
    }

    const returnData = [
      ...props.content.map((element) => {
        return {
          ...element,
          price: checkEmptyNull(element.price, 0).toLocaleString('ko-KR'),
          quantity: checkEmptyNull(element.quantity, 0).toLocaleString('ko-KR'),
          calPrice: checkEmptyNull(element.calPrice, 0).toLocaleString('ko-KR'),
        };
      }),
      ...returnArray,
    ];

    return returnData;
  });

  const [_viewPrice, setViewPrice] = useState(() => {
    const quantityArray = props.content.map((item) => Number(checkEmptyNull(item.quantity, 0).toString().replaceAll(',', '')));
    const setQuantityArray = quantityArray.filter((item) => item !== null && !isNaN(item));
    const totalQuantity = setQuantityArray.reduce((a, b) => BigNumber(a).plus(BigNumber(b)).toNumber(), 0);

    const sumArray = props.content.map((item) => Number(checkEmptyNull(item.calPrice, 0).toString().replaceAll(',', '')));
    const setSumArray = sumArray.filter((item) => item !== null && !isNaN(item));
    const sum = setSumArray.reduce((a, b) => BigNumber(a).plus(BigNumber(b)).toNumber(), 0);
    const tax = BigNumber(checkEmptyNull(sum, 0)).multipliedBy(10).dividedBy(100).toNumber();
    const totalSum = BigNumber(checkEmptyNull(sum, 0)).plus(BigNumber(tax)).toNumber();

    const returnData = {
      totalQuantity: totalQuantity.toLocaleString('ko-KR'),

      sum: sum.toLocaleString('ko-KR'),
      tax: tax.toLocaleString('ko-KR'),
      totalSum: totalSum.toLocaleString('ko-KR'),
    };

    return returnData;
  });

  /* ====================================================================== #3 */
  useEffect(() => {
    setPrintPage(() => { return `${props.currentPage} / ${props.totalPage}` });
    setCompanyInformation(() => { return props.companyInformation });
    setAccountInformation(() => { return props.accountInformation });
    setDeliveryData(() => { return props.deliveryData });
    setProcurement(() => { return props.shipment });
    setProcurementElementList(() => {
      const returnArray = [];
      for (let i = 0; i < parseFloat(20 - props.content.length); i++) {
        const emptyData = {
          empty: true,
          elementName: '',
          elementCode: '',

          price: 0, // 단가
          quantity: 0, // 수량
          calPrice: 0, // 금액

          elementStandard: '', // 규격
          elementDetailStandard: '', // 상세규격
        };

        returnArray.push(emptyData);
      }

      const returnData = [
        ...props.content.map((element) => {
          return {
            ...element,
            price: checkEmptyNull(element.price, 0).toLocaleString('ko-KR'),
            quantity: checkEmptyNull(element.quantity, 0).toLocaleString('ko-KR'),
            calPrice: checkEmptyNull(element.calPrice, 0).toLocaleString('ko-KR'),
          };
        }),
        ...returnArray,
      ];

      return returnData;
    });

    setViewPrice(() => {
      const quantityArray = props.content.map((item) => Number(checkEmptyNull(item.quantity, 0).toString().replaceAll(',', '')));
      const setQuantityArray = quantityArray.filter((item) => item !== null && !isNaN(item));
      const totalQuantity = setQuantityArray.reduce((a, b) => BigNumber(a).plus(BigNumber(b)).toNumber(), 0);

      const sumArray = props.content.map((item) => Number(checkEmptyNull(item.calPrice, 0).toString().replaceAll(',', '')));
      const setSumArray = sumArray.filter((item) => item !== null && !isNaN(item));
      const sum = setSumArray.reduce((a, b) => BigNumber(a).plus(BigNumber(b)).toNumber(), 0);
      const tax = BigNumber(checkEmptyNull(sum, 0)).multipliedBy(10).dividedBy(100).toNumber();
      const totalSum = BigNumber(checkEmptyNull(sum, 0)).plus(BigNumber(tax)).toNumber();

      const returnData = {
        totalQuantity: totalQuantity.toLocaleString('ko-KR'),

        sum: sum.toLocaleString('ko-KR'),
        tax: tax.toLocaleString('ko-KR'),
        totalSum: totalSum.toLocaleString('ko-KR'),
      };

      return returnData;
    });
  }, []);

  useEffect(() => { }, [_companyInformation]);
  useEffect(() => { }, [_shipmentElementList]);
  useEffect(() => { }, [_viewPrice]);

  /* ====================================================================== #4 */
  const calTax = (calPrice) => {
    if (!checkEmptyNull(calPrice, false)) return;
    const num = calPrice.toString().replace(/,/g, '');
    return BigNumber(isNaN(num) ? 0 : num).multipliedBy(10).dividedBy(100).toNumber();
  };

  /* ====================================================================== #5 */
  /* ====================================================================== #6 */

  return (
    <ExportSection id="ExportSection" name={'printContent'}>
      <PrintSection>
        <HeaderSection>
          <h1>송장</h1>
          <div className='printHeader'>
            <h4>{_printPage}</h4>
            {/* <h4>{moment(checkEmptyNull(_shipment.shipmentDate, new Date())).format('YYYY년 MM월 DD일(dd)')}</h4> */}
            <h4>{moment(new Date()).format('YYYY년 MM월 DD일(dd)')}</h4>
          </div>
        </HeaderSection>

        <PrintTable>
          <thead>
            <tr>
              <td colSpan={6}>
                <CheckSection>
                  <div className='checkBox'><p>공급자용</p></div>
                  <div className='checkBox'><p>공급받는자용</p></div>
                </CheckSection>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={4}>공급자</td>
              <td>회사명</td>
              <td className='dataCell'>{_companyInformation.selfAccountName}</td>

              <td rowSpan={4}>공급처</td>
              <td>회사명</td>
              <td className='dataCell'>{_accountInformation.shipmentAccountName}</td>
            </tr>
            <tr>
              <td>주소</td>
              <td className='dataCell'>{_companyInformation.selfAddress}</td>

              <td>주소</td>
              <td className='dataCell'>{_accountInformation.shipmentAddress}</td>
            </tr>
            <tr>
              <td>담당자</td>
              <td className='dataCell'>{_companyInformation.selfManagerName}</td>

              <td>담당자</td>
              <td className='dataCell'>{_accountInformation.shipmentManagerName}</td>
            </tr>
            <tr>
              <td>전화번호</td>
              <td className='dataCell'>{_companyInformation.selfTelNumber}</td>

              <td>전화번호</td>
              <td className='dataCell'>{_accountInformation.shipmentTelNumber}</td>
            </tr>
            <tr>
              <td rowSpan={3}>운전자</td>
              <td>이름</td>
              <td>{_deliveryData.driver}</td>

              <td rowSpan={3}>비고</td>
              <td colSpan={2} rowSpan={3}
                style={{ boxSizing: 'border-box', padding: '10px', textAlign: 'start', verticalAlign: 'top' }}
              >
                {_shipment.remark}
              </td>
            </tr>
            <tr>
              <td>전화번호</td>
              <td>{_deliveryData.telNumber}</td>
            </tr>
            <tr>
              <td>차량번호</td>
              <td>{_deliveryData.licensePlateNumber}</td>
            </tr>
          </tbody>
        </PrintTable>
        <PrintTable>
          <colgroup>
            <col width={'40px'}></col>
            <col width={'110px'}></col>
            <col width={'110px'}></col>
            <col width={'100px'}></col>
            <col width={'100px'}></col>
            <col width={'60px'}></col>
            {!props.toggleStatus ? <col width={'60px'}></col> : null}
            {!props.toggleStatus ? <col width={'60px'}></col> : null}
            {!props.toggleStatus ? <col width={'60px'}></col> : null}
            <col></col>
          </colgroup>
          <thead>
            <tr>
              <th>순번</th>
              <th>상품코드</th>
              <th>상품이름</th>
              <th>규격</th>
              <th>상세규격</th>
              <th>수량</th>
              <th name='toggleCell' className={props.toggleStatus ? 'hide' : ''}>단가</th>
              <th name='toggleCell' className={props.toggleStatus ? 'hide' : ''}>금액</th>
              <th name='toggleCell' className={props.toggleStatus ? 'hide' : ''}>세액</th>
              <th>메모</th>
            </tr>
          </thead>
          <tbody>
            {checkNullArray(_shipmentElementList, []).map((shipmentElement, index) => {
              return (<tr key={index + '_shipmentElement'}>
                <td>{(index + 1).toString().padStart(2, '0')}</td>
                <td>{shipmentElement.elementCode}</td>
                <td>{shipmentElement.elementName}</td>
                <td>{shipmentElement.elementStandard}</td>
                <td>{shipmentElement.elementDetailStandard}</td>
                <td>{checkEmptyNull(shipmentElement.quantity, '') !== 0 ? checkEmptyNull(shipmentElement.quantity, 0) : ''}</td>
                <td name='toggleCell' className={props.toggleStatus ? 'hide' : ''}>{checkEmptyNull(shipmentElement.price, '') !== 0 ? checkEmptyNull(shipmentElement.price, 0) : ''}</td>
                <td name='toggleCell' className={props.toggleStatus ? 'hide' : ''}>{checkEmptyNull(shipmentElement.calPrice, '') !== 0 ? checkEmptyNull(shipmentElement.calPrice, 0) : ''}</td>
                <td name='toggleCell' className={props.toggleStatus ? 'hide' : ''}>
                  {checkEmptyNull(calTax(checkEmptyNull(shipmentElement.calPrice, 0)), 0) !== 0 ? checkEmptyNull(calTax(checkEmptyNull(shipmentElement.calPrice, 0)), 0).toLocaleString() : ''}
                </td>
                <td>{shipmentElement.remark}</td>
              </tr>)
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={5} rowSpan={2}>합계</td>
              <td>{checkEmptyNull(_viewPrice.totalQuantity, '') !== 0 && checkEmptyNull(_viewPrice.totalQuantity, '') !== '0' ? checkEmptyNull(_viewPrice.totalQuantity, 0) : ''}</td>
              <td name='toggleCell' className={props.toggleStatus ? 'hide' : ''}></td>
              <td name='toggleCell' className={props.toggleStatus ? 'hide' : ''}>{checkEmptyNull(_viewPrice.sum, '') !== 0 && checkEmptyNull(_viewPrice.sum, '') !== '0' ? checkEmptyNull(_viewPrice.sum, 0) : ''}</td>
              <td name='toggleCell' className={props.toggleStatus ? 'hide' : ''}>{checkEmptyNull(_viewPrice.tax, '') !== 0 && checkEmptyNull(_viewPrice.tax, '') !== '0' ? checkEmptyNull(_viewPrice.tax, 0) : ''}</td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={6} name='toggleCell' className={props.toggleStatus ? 'hide' : ''}>{checkEmptyNull(_viewPrice.totalSum, '') !== 0 && checkEmptyNull(_viewPrice.totalSum, '') !== '0' ? checkEmptyNull(_viewPrice.totalSum, 0) : ''}</td>
              <td colSpan={6} name='toggleCell' className={!props.toggleStatus ? 'hide' : ''}></td>
            </tr>
          </tfoot>
        </PrintTable>
      </PrintSection>
    </ExportSection>
  );
};

const DeliveryInvoicePdfModal = (props) => {
  /* ========================================================================= #1 */
  /* ========================================================================= #2 */
  const { shipment } = props;

  const [_companyInformation, setCompanyInformation] = useState(() => {
    const returnData = {
      selfAccountCode: shipment.selfAccountCode,
      selfAccountName: shipment.selfAccountName,

      selfBusinessNumber: shipment.selfBusinessNumber,
      selfAddress: shipment.selfAddress,
      selfTelNumber: shipment.selfTelNumber,

      selfManagerName: shipment.selfManagerName,
    };
    return returnData;
  });
  const [_accountInformation, setAccountInformation] = useState(() => {
    const returnData = {
      accountId: shipment.accountId,
      shipmentAccountCode: shipment.shipmentAccountCode,
      shipmentAccountName: shipment.shipmentAccountName,

      shipmentBusinessNumber: shipment.shipmentBusinessNumber,
      shipmentAddress: shipment.shipmentAddress,
      shipmentTelNumber: shipment.shipmentTelNumber,

      shipmentManagerName: shipment.shipmentManagerName,
    };
    return returnData;
  });
  const [_deliveryData, setDeliveryData] = useState(() => {
    const customizedContent = checkNullParse(shipment.customizedContent, {
      delivery: {
        driver: '',
        telNumber: '',
        licensePlateNumber: '',
      }
    });
    const deliveryData = checkNullObject(customizedContent.delivery, {
      driver: '',
      telNumber: '',
      licensePlateNumber: '',
    })
    console.log('deliveryData : ', deliveryData);
    return deliveryData;
  })

  const [_printContents, setPrintContents] = useState(() => {
    const shipmentElementList = shipment.shipmentElementList;
    const returnList = [];
    if (checkNullArray(shipmentElementList, false)) {
      for (let i = 0; i < shipmentElementList.length; i += 20) {
        const sliceArray = shipmentElementList.slice(i, i + 20);
        const returnArray = sliceArray.map((element) => {
          const customizedContent = checkNullParse(element.customizedContent, {
            elementStandard: '',
            elementDetailStandard: '',
          });
          if (checkNullObject(element.element, false)) {
            const elementData = {
              ...element,

              elementId: element.element.elementId,
              elementName: element.element.elementName,
              elementCode: element.element.elementCode,
              scheduledInputStock: element.element.scheduledInputStock,
              elementSafeStock: element.element.elementSafeStock,
              elementStock: element.element.elementStock,

              price: element.element.price || 0, // 단가
              calPrice: element.price, // 금액

              elementStandard: customizedContent.elementStandard, // 규격
              elementDetailStandard: customizedContent.elementDetailStandard, // 상세규격
            };

            return elementData;
          } else {
            const elementData = {
              ...element,

              elementStandard: customizedContent.elementStandard, // 규격
              elementDetailStandard: customizedContent.elementDetailStandard, // 상세규격
            }
            return elementData;
          }
        });
        returnList.push(returnArray);
      }
      return returnList;
    } else {
      return [];
    }
  });

  const [_toggleStatus, setToggleStatus] = useState(false);
  const [_loadingStatus, setLoadingStatus] = useState(false);

  /* ========================================================================= #3 */
  /* ========================================================================= #4 */
  /* ========================================================================= #5 */
  const actPrintContent = () => {
    console.log('dd');
    console.log(document.getElementsByName('printContent'));

    const style = document.createElement('style');
    document.head.appendChild(style);
    style.sheet?.insertRule('body > div:last-child img { display: inline-block; }');

    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
    });

    document.getElementsByName('printContent').forEach(async (thisItem, index) => {
      const thisHeight = thisItem.offsetHeight;
      const thisWidth = thisItem.offsetWidth;
      const ratio = thisWidth / thisHeight;

      const option = {
        height: thisHeight,
        width: thisWidth,
      };

      await html2canvas(thisItem, option).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');

        let width = doc.internal.pageSize.getWidth();
        let height = 280;
        width = ratio * height;

        const margin = 6;
        const position = 6;

        doc.addImage(imgData.replace('data:image/png;base64,', ''), 'PNG', margin, position, width, height);
        if (index < document.getElementsByName('printContent').length - 1) {
          doc.addPage();
        }
      });

      if (index === document.getElementsByName('printContent').length - 1) {
        if (document.getElementsByName('printContent').length > 10) {
          style.remove();
          doc.save();
          setLoadingStatus(false);
        } else {
          style.remove();
          window.open(doc.output('bloburl'));
          setLoadingStatus(false);
        }
      }
    });
  };

  /* ========================================================================= #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>송장 출력 미리보기</ModalTitle>
              <ModalButton onClick={() => { setTimeout(actPrintContent, 1000) }}>PDF 출력</ModalButton>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <ToggleButton className='btn-set' onClick={() => {
                setToggleStatus(prev => { return !prev; });
              }}>
                {_toggleStatus ? '금액 표시' : '금액 제외'}
              </ToggleButton>
              <MainContents id="PrintSection">
                {/* checkEmptyNull(_companyInformation.accountId, false) && */
                  !checkNullArray(_printContents, false) ? <>
                    <PrintContent
                      totalPage={1}
                      currentPage={1}
                      content={[]}
                      accountInformation={_accountInformation}
                      companyInformation={_companyInformation}
                      deliveryData={_deliveryData}
                      shipment={shipment}
                      toggleStatus={_toggleStatus}
                    />
                  </>
                    : checkNullArray(_printContents, []).map((content, index) => {
                      return (
                        <PrintContent
                          key={index + '_printContents'}
                          totalPage={_printContents.length}
                          currentPage={index + 1}
                          content={content}
                          accountInformation={_accountInformation}
                          companyInformation={_companyInformation}
                          deliveryData={_deliveryData}
                          shipment={shipment}
                          toggleStatus={_toggleStatus}
                        />
                      );
                    })}
              </MainContents>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}
      ;
      {_loadingStatus === true ? (<LoadingScreen>파일 생성 중입니다...</LoadingScreen>) : null}
    </>
  );
};

export default DeliveryInvoicePdfModal;
