import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { processActions_setBookmark, processActions_setPageNumber, processActions_setProcessType, processActions_setReset, processActions_setSearchData, processActions_setTotalSize } from 'store/modules/actions/common/processActions';

import { bookmark } from 'api/apis/bookmark/bookmark';
import { processTypeApi } from 'api/apis/processTypeApi';
import { processApi } from 'api/apis/processApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { LoadingMsg } from 'components/loading/LoadingMsg'

import Bookmark from 'components/icons/Bookmark';
import ExcelDownloadModal from 'components/excel/ExcelDownloadModal';
import Grid3Body from 'components/layouts/body/Grid3Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import ProcessManagementRow from 'components/row/ProcessManagementRow';
import ProcessUploadModal from 'components/excel/upload/ProcessUploadModal';
import PutElementList from 'pages/process/modal/PutElementList';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import ShowFileList from 'pages/file/modal/ShowFileList';
import TableSection from 'components/layouts/table/TableSection';

const Section = styled.main`
  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
    & .typeButtons {
      display: flex;
      gap: 5px;
    }
  }
  .TableSection {
    td, th {padding: 8px 5px;}
    tr[name='processRows']:hover {cursor: pointer}
    td.blankCell {background-color: var(--Bg); opacity: 0.8;}
  }
`;

const ProcesseManagement = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, processReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_processTypes, setProcessTypes] = useState([]);
  const [_processList, setProcessList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_elementModalStatus, setElementModalStatus] = useState(false);
  const [_fileListModalStatus, setFileListModalStatus] = useState(false);
  const [_eventcontent, setEventContent] = useState({});

  const [_downloadModalStatus, setDownloadModalStatus] = useState(false);
  const [_uploadModalStatus, setUploadModalStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('112') === true || // 공정정의
      thisItem.authority.startsWith('109') === true, // 파일
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    setOnload('loaded');

    getProcessTypes();
    getProcessList(processReducer.pageNumber - 1, processReducer.processType);

    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getProcessList(processReducer.pageNumber - 1, processReducer.processType);
    }

    return () => { };
  }, [processReducer.pageNumber]);

  useEffect(() => {
    if (_onload === 'loaded') {
      dispatch(processActions_setPageNumber(1));
      getProcessList(0, processReducer.processType);
    }
    return () => { };
  }, [processReducer.bookmark]);

  /* ====================================================================== #4 */
  const getProcessTypes = async () => {
    const BodyToPost = { companyId: userReducer.company.companyId };
    await processTypeApi.searchProcessType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('processTypeApi.searchProcessType : ', response);
      setProcessTypes(() => { return response.data });
    });
  };

  const getProcessList = async (page, processType, clear) => {
    let paging = `?page=${page}&size=10`;
    if (processReducer.bookmark) paging += '&sort=bookMark,DESC&sort=id,DESC';
    else paging += '&sort=id,DESC';

    const BodyToPost = { companyId: userReducer.company.companyId };
    if (!clear && checkEmptyNull(processType, false) && processType !== 'all') {
      BodyToPost.processTypeId = Number(processType);
    }
    if (!clear && checkEmptyNull(processReducer.searchData.processTypeName, false) && processReducer.searchData.processTypeName !== 'all') {
      BodyToPost.processTypeName = processReducer.searchData.processTypeName;
    }
    if (!clear && checkEmptyNull(processReducer.searchData.processCode, false)) {
      BodyToPost.processCode = processReducer.searchData.processCode;
    }
    if (!clear && checkEmptyNull(processReducer.searchData.processName, false)) {
      BodyToPost.processName = processReducer.searchData.processName;
    }
    await processApi.searchProcess(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('processApi.searchProcess : ', response);
      setProcessList(() => { return response.data.content });
      dispatch(processActions_setTotalSize(response.data.totalElements));
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set([..._checkedItem]);
    if (isChecked) checkedItem.add(id);
    if (!isChecked && checkedItem.has(id)) checkedItem.delete(id);
    setCheckedItem(() => { return checkedItem });
  };

  const handleBookmark = async (process, index) => {
    await bookmark.processBookmark(process.processId).then(response => {
      if (response === undefined) return;
      console.log('bookmark.processBookmark : ', response);
      getProcessList(processReducer.pageNumber - 1, processReducer.processType);
    })
  }

  /* 검색 */
  const actSearch = async () => {
    dispatch(processActions_setPageNumber(1));
    await getProcessList(0, processReducer.processType);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(processActions_setReset());
    dispatch(processActions_setPageNumber(1));
    await getProcessList(0, 'all', true);
  };

  const showFileList = (process) => {
    setEventContent(() => { return process });
    setTimeout(setFileListModalStatus(true), 1000);
  };
  const showPutElementList = (process) => {
    const putElementList = process.processElementList.filter((element) => element.processElementType === 'Put')
    console.log('putElementList : ', putElementList);
    setEventContent(() => { return putElementList });
    setTimeout(setElementModalStatus(true), 1000);
  };

  /* 복사 */
  const actCopy = (e) => {
    e.preventDefault();
    const checkedElementList = Array.from(_checkedItem);
    if (checkedElementList.length < 1) return;
    else if (checkedElementList.length > 1) return alert('다중 선택되어 있습니다.');

    const copyContentIndex = _processList.findIndex((thisItem) => thisItem.processId === checkedElementList[0]);
    if (copyContentIndex === -1) return alert('복사할 공정을 선택해 주세요.');

    const copyContent = _processList[copyContentIndex];

    const ProcessPutItems = copyContent.processElementList.filter((thisItem) => thisItem.processElementType === 'Put');
    const setProcessPutItems = ProcessPutItems.map((thisItem) => {
      return { ...thisItem, ...thisItem.element };
    });
    const ProcessMakeItems = copyContent.processElementList.filter((thisItem) => thisItem.processElementType === 'Make');
    const setProcessMakeItems = ProcessMakeItems.map((thisItem) => {
      return { ...thisItem, ...thisItem.element };
    });

    navigate({
      pathname: pageReducer.currentPage + '/copy',
      search: `?${createSearchParams({
        copyContent: JSON.stringify(copyContent),
        putItems: JSON.stringify(setProcessPutItems),
        makeItems: JSON.stringify(setProcessMakeItems),
      })}`,
      replace: true,
    });
  };

  /* 수정 */
  const actUpdate = (e) => {
    e.preventDefault();
    const checkedElementList = Array.from(_checkedItem);
    if (checkedElementList.length < 1) return;
    else if (checkedElementList.length > 1) return alert('다중 선택되어 있습니다.');

    const updateContentIndex = _processList.findIndex((thisItem) => thisItem.processId === checkedElementList[0]);
    if (updateContentIndex === -1) return alert('수정할 공정을 선택해 주세요.');

    const updateContent = _processList[updateContentIndex];

    const ProcessPutItems = updateContent.processElementList.filter((thisItem) => thisItem.processElementType === 'Put');
    const setProcessPutItems = ProcessPutItems.map((thisItem) => {
      return { ...thisItem, ...thisItem.element };
    });
    const ProcessMakeItems = updateContent.processElementList.filter((thisItem) => thisItem.processElementType === 'Make');
    const setProcessMakeItems = ProcessMakeItems.map((thisItem) => {
      return { ...thisItem, ...thisItem.element };
    });

    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({
        updateContent: JSON.stringify(updateContent),
        putItems: JSON.stringify(setProcessPutItems),
        makeItems: JSON.stringify(setProcessMakeItems),
      })}`,
      replace: true,
    });
  };

  /* 삭제 */
  const actDelete = (e) => {
    e.preventDefault();
    const checkedElementList = Array.from(_checkedItem);
    if (checkedElementList.length < 1) return;

    const deleteContents = checkedElementList.map(processId => {
      const contentIndex = _processList.findIndex(process => process.processId === processId);
      const content = _processList[contentIndex];
      return content;
    })

    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({ deleteContents: JSON.stringify(deleteContents) })}`,
      replace: true,
    });
  };

  /* 엑셀 팝업 */
  const actUpload = () => { setUploadModalStatus(true); };
  const actDownload = () => { setDownloadModalStatus(true); };

  const goDetails = (process) => {
    const detailContent = process;

    const ProcessPutItems = detailContent.processElementList.filter((thisItem) => thisItem.processElementType === 'Put');
    const setProcessPutItems = ProcessPutItems.map((thisItem) => {
      return { ...thisItem, ...thisItem.element };
    });
    const ProcessMakeItems = detailContent.processElementList.filter((thisItem) => thisItem.processElementType === 'Make');
    const setProcessMakeItems = ProcessMakeItems.map((thisItem) => {
      return { ...thisItem, ...thisItem.element };
    });

    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({
        updateContent: JSON.stringify(detailContent),
        putItems: JSON.stringify(setProcessPutItems),
        makeItems: JSON.stringify(setProcessMakeItems),
      })}`,
      replace: true,
    });
  };

  /* ====================================================================== #6 */

  return (
    <Grid3Body contents={
      <>
        <Section className="Main">
          <NavBar
            title={
              <div className='Title'>
                <NavTitle menuCode={'112'} />
                <Link to='/baseInformation/workOrder' style={{ color: '#ccc' }}><NavTitle menuCode={'111'} /></Link>
                <div className='typeButtons' style={{ display: 'flex' }}>
                  <button
                    className={processReducer.processType === 'all' ? 'btn-inven-1' : 'btn-set'}
                    key={'all' + '_processTypes'}
                    value='all'
                    onClick={() => {
                      dispatch(processActions_setProcessType("all"));
                      dispatch(processActions_setPageNumber(1));
                      getProcessList(0, "all", 'all');
                    }}
                  >
                    전체
                  </button>
                  <select
                    className="SelectType"
                    value={processReducer.processType}
                    onChange={(e) => {
                      dispatch(processActions_setProcessType(e.target.value))
                      dispatch(processActions_setPageNumber(1));
                      getProcessList(0, e.target.value);
                    }}
                  >
                    <option value="all">공정유형</option>
                    {_processTypes.map((thisItem) => {
                      return (<option key={thisItem.processTypeId + '_processType'} value={thisItem.processTypeId}>{thisItem.processTypeName}</option>);
                    })}
                  </select>
                </div>
              </div>
            }
            buttons={
              <>
                {_authority.indexOf('112-2') !== -1 ? (
                  <>
                    <button className='btn-add'
                      onClick={(e) => {
                        e.preventDefault();
                        setTimeout(navigate(pageReducer.currentPage + '/create', { replace: true }), 1000);
                      }}
                    >
                      추가
                    </button>
                    <button className='btn-copy' onClick={actCopy}>복사</button>
                    <button className='btn-edit' onClick={actUpdate}>수정</button>
                    <button className='btn-delete' onClick={actDelete}>삭제</button>
                    <button className='btn-upload' onClick={actUpload}>공정 업로드</button>
                  </>
                ) : null}
                <button className='btn-download' onClick={actDownload}>다운로드</button>
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="SearchSection">
                  <div className="SearchSection">
                    {processReducer.processType === 'all' && (
                      <div className="ContentCBox">
                        <select className="SearchOption detail">
                          <option value="processTypeName">공정유형</option>
                        </select>
                        <input
                          className="SearchBar detail"
                          placeholder="Search..."
                          value={checkEmptyNull(processReducer.searchData.processTypeName, '')}
                          onInput={(e) => { dispatch(processActions_setSearchData({ ...processReducer.searchData, processTypeName: e.target.value })) }}
                        />
                      </div>
                    )}

                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="processCode">공정코드</option>
                      </select>
                      <input
                        className="SearchBar detail"
                        placeholder="Search..."
                        value={checkEmptyNull(processReducer.searchData.processCode, '')}
                        onInput={(e) => { dispatch(processActions_setSearchData({ ...processReducer.searchData, processCode: e.target.value })) }}
                      />
                    </div>

                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="processName">공정이름</option>
                      </select>
                      <input
                        className="SearchBar detail"
                        placeholder="Search..."
                        value={checkEmptyNull(processReducer.searchData.processName, '')}
                        onInput={(e) => { dispatch(processActions_setSearchData({ ...processReducer.searchData, processName: e.target.value })) }}
                      />
                    </div>

                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton" onClick={actReset}>초기화</div>
                  </div>
                </div>
              </>
            }
          />

          <TableSection content={
            <table>
              <thead>
                <tr style={{ border: 'none' }}>
                  {_authority.indexOf('112-2') !== -1 ? (
                    <th rowSpan={2} style={{ minWidth: '50px', width: '50px' }}>
                      <input
                        type="checkBox"
                        name="allElements"
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          const checkedItem = new Set([..._checkedItem]);
                          if (isChecked) {
                            document.getElementsByName('processes').forEach((thisAttr) => {
                              checkedItem.add(Number(thisAttr.dataset.key));
                              thisAttr.checked = true;
                            });
                            e.target.checked = true;
                          } else {
                            document.getElementsByName('processes').forEach((thisAttr) => {
                              checkedItem.delete(Number(thisAttr.dataset.key));
                              thisAttr.checked = false;
                            });
                            e.target.checked = false;
                          }
                          setCheckedItem(() => { return checkedItem });
                        }}
                      />
                    </th>
                  ) : null}
                  <th rowSpan={2} style={{ minWidth: '50px', width: '50px' }}>
                    <Bookmark
                      bookmark={processReducer.bookmark}
                      clickEvent={() => { dispatch(processActions_setBookmark(!processReducer.bookmark)) }}
                    />
                  </th>
                  {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? (<th rowSpan={2} style={{ minWidth: '70px', width: '70px' }}>파일</th>) : null}
                  <th rowSpan={2}>공정유형</th>
                  <th rowSpan={2}>공정코드</th>
                  <th rowSpan={2}>공정이름</th>
                  <th rowSpan={2}>작업위치</th>
                  <th rowSpan={2}>비고</th>
                  <th colSpan={3} style={{ backgroundColor: 'var(--ThirdBlue)' }}>공정완료제품</th>
                  {/* <th rowSpan={2} style={{ minWidth: '120px', width: '120px' }}>투입<br />자재/반제품</th> */}
                  <th colSpan={3} style={{ backgroundColor: 'var(--SeconYellow)' }}>투입 자재/반제품</th>
                </tr>
                <tr>
                  <th>물품이름</th>
                  <th>물품코드</th>
                  <th>생산량</th>
                  <th>물품이름</th>
                  <th>물품코드</th>
                  <th>투입량</th>
                </tr>
              </thead>
              <tbody>
                {!checkNullArray(_processList, false) ? LoadingMsg() :
                  checkNullArray(_processList, []).map((thisItem, index) => {
                    return (
                      <ProcessManagementRow
                        key={index + '_processRow'}
                        authority={_authority}
                        content={thisItem}
                        goDetails={goDetails}
                        handleCheckedItem={handleCheckedItem}
                        handleBookmark={handleBookmark}
                        showFileList={showFileList}
                        showPutElementList={showPutElementList}
                      />
                    );
                  })}
              </tbody>
            </table>
          }
            sectionStyle={{ height: 'calc(100% - 66px)' }}
          ></TableSection>
        </Section>

        <PagingComponent
          page={processReducer.pageNumber}
          count={processReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(processActions_setPageNumber(page));
          }}
        />

        {_fileListModalStatus === true && checkEmptyNull(_eventcontent.processId, false) ? (
          <ShowFileList
            type={'process'}
            content={_eventcontent}
            open={_fileListModalStatus}
            close={() => { setFileListModalStatus(false); }}
          ></ShowFileList>
        ) : null}

        {_elementModalStatus === true ? (
          <PutElementList
            content={_eventcontent}
            open={_elementModalStatus}
            close={() => { setElementModalStatus(false); }}
          ></PutElementList>
        ) : null}

        {_downloadModalStatus === true ? (
          <ExcelDownloadModal
            mainTitle={'Process'}
            open={_downloadModalStatus}
            close={() => { setDownloadModalStatus(false); }}
          />
        ) : null}

        {_uploadModalStatus === true ? (
          <ProcessUploadModal
            reset={actReset}
            open={_uploadModalStatus}
            close={() => { setUploadModalStatus(false); }}
          ></ProcessUploadModal>
        ) : null}
      </>
    }
    />
  );
};

export default ProcesseManagement;
