import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { companyPageCode } from 'api/apis/operator/companyPageCode';

import { checkEmptyNull, checkNullParse } from 'components/checkValues/checkValues';

import DeleteForm from 'components/layouts/form/DeleteForm';
import NavBar from 'components/nav/NavBar';
import OperatorBody from 'components/layouts/body/OperatorBody';

const MainSection = styled.main`
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 60px calc(100% - 60px);
`;

const DeletePageCode = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();
  const deleteContent = checkNullParse(searchParams.get('deleteContent'), {});
  console.log('deleteContent : ', deleteContent);

  /* ====================================================================== #3 */

  /* ====================================================================== #4 */

  /* ====================================================================== #5 */
  const actDelete = async () => {
    await companyPageCode.deleteCompanyPageCode(deleteContent.companyPageCodeId).then(response => {
      if (!checkEmptyNull(response, false)) return;
      console.log('companyPageCodeApi.deleteCompanyPageCode : ', response);
      alert('페이지를 삭제했습니다.')
      navigate(pageReducer.currentPage, { replace: true });
    })
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <OperatorBody contents={
      <>
        <MainSection className="Main">
          <NavBar title={'페이지코드'} nav={''} />

          <DeleteForm
            delteTitle={'페이지코드'}
            deleteItem={
              <>
                {Object.keys(deleteContent).map((key, index) => {
                  return (<p key={index + '_infos'}>{`${key} : ${JSON.stringify(deleteContent[key])}`}</p>)
                })}
              </>
            }
            deleteText={<>페이지코드를 삭제합니다.<br /></>}
            deleteButton={
              <>
                <button className="formButton cancle"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(pageReducer.currentPage, { replace: true });
                  }}
                >
                  취소
                </button>
                <button className="formButton delete"
                  onClick={(e) => {
                    e.preventDefault();
                    if (window.confirm('페이지를 삭제합니다.')) actDelete();
                    else return;
                  }}
                >
                  삭제
                </button>
              </>
            }
          />
        </MainSection>
      </>
    }
    />
  );
};

export default DeletePageCode;
