import styled from "styled-components";

// 스타일 컴포넌트 만들기
// 공통되는 스타일 옮기기
// main > section

export const ContractFormComponent = styled.section`
  & input[name='accountName'] {
    background-color: var(--ThirdBlue);
    color: var(--white);

    &::placeholder {color: var(--white);}
  }

  & .DelButton {
    height: 15px;
    margin: 0px auto;
    width: 15px;

    div {background-color: var(--MainRed); cursor: pointer; height: 15px; width: 15px;}
  }

  & .DateCheckBox {
    align-items: center;
    column-gap: 10px;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100px;
    input {margin: unset;}
  }

  & .TableSection {
    max-height: 350px;
    overflow: auto;

    &::-webkit-scrollbar-thumb {background-color: var(--ThirdBlue);}
  }
`;