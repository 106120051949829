import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { workOrderLogApi } from 'api/apis/workOrderLogApi';
import { workOrderTypeApi } from 'api/apis/workOrderTypeApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import GuideText from 'components/buttons/GuideText';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import SelectProcessLogs from 'pages/productionOrder/modal/SelectProcessLogs';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 40px 50px;
  height: 85%;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
  }
`;
const ListButton = styled.td`
  background-color: var(--MainNavy);
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  min-height: 100px;
  height: 100%;
  width: 100%;
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const SelectWorkOrderLog = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_workOrderLogList, setWorkOrderLogList] = useState([]);

  const [_workOrderTypes, setWorkOrderTypes] = useState([]);
  const [_workOrderType, setWorkOrderType] = useState('all');
  const [_workOrderStatus, setWorkOrderStatus] = useState('all');

  const [_startDate, setStartDate] = useState('');
  const [_endDate, setEndDate] = useState('');

  const [_searchOption, setSearchOption] = useState('');
  const [_searchText, setSearchText] = useState('');

  const [_pageNumber, setPageNumber] = useState(1);
  const [_totalSize, setPageCount] = useState(0);

  const [_modalStatus, setModalStatus] = useState(false); // 공정 리스트
  const [_selectedWorkOrder, setSelectedWorkOrder] = useState({});

  const [_processLogList, setProcessLogList] = useState([...props.content]);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('104') === true ||
      thisItem.authority.startsWith('109') === true,
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    getWorkOrderTypes();
    getWorkOrderLogList(0, 'all', 'all');

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getWorkOrderLogList(_pageNumber - 1, _workOrderType, _workOrderStatus);
    }
    return () => { };
  }, [_pageNumber]);

  useEffect(() => {console.log('_processLogList - modal : ', _processLogList);}, [_processLogList])

  /* ====================================================================== #4 */
  const getWorkOrderTypes = async () => {
    const BodyToPost = {companyId: userReducer.company.companyId};
    await workOrderTypeApi.searchWorkOrderType(BodyToPost).then((response) => {
      if (response === undefined) return;
      setWorkOrderTypes(() => { return response.data });
    });
  };

  const getWorkOrderLogList = async (page, workOrderType, workOrderStatus, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {companyId: userReducer.company.companyId};
    if (!clear && checkEmptyNull(workOrderType, false) && workOrderType !== 'all') {
      BodyToPost.workOrderTypeId = Number(workOrderType);
    }
    if (!clear && checkEmptyNull(workOrderStatus, false)) {
      switch (workOrderStatus) {
        case 'all': BodyToPost.workOrderStatus = ['wait', 'work', 'pause', 'stop', 'end', 'cancel',]; break;
        case 'waitNwork': BodyToPost.workOrderStatus = ['wait', 'work']; break;
        default: BodyToPost.workOrderStatus = [workOrderStatus]; break;
      }
    }
    if (!clear && checkEmptyNull(_startDate, false) && checkEmptyNull(_endDate, false)) {
      BodyToPost.scheduledStartDate = DateFormat(_startDate);
      BodyToPost.scheduledEndDate = DateFormat(_endDate);
      if (_startDate > _endDate) {
        BodyToPost.scheduledStartDate = DateFormat(_endDate);
        BodyToPost.scheduledEndDate = DateFormat(_startDate);
      }
    } else if (!clear && _startDate !== '') {
      BodyToPost.scheduledStartDate = DateFormat(_startDate);
    } else if (!clear && _endDate !== '') {
      BodyToPost.scheduledEndDate = DateFormat(_endDate);
    }
    if (!clear && checkEmptyNull(_searchOption, false) && checkEmptyNull(_searchText, false)) {
      BodyToPost[_searchOption] = _searchText;
    }
    await workOrderLogApi.searchWorkOrderLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      setWorkOrderLogList(() => { return response.data.data.content });
      setPageCount(() => { return response.data.data.totalElements });
      if (!clear && checkEmptyNull(_startDate, false) && checkEmptyNull(_endDate, false)) {
        if (_startDate > _endDate) {
          setStartDate(() => { return _endDate });
          setEndDate(() => { return _startDate });
        }
      }
    });
  };

  /* ====================================================================== #5 */
  /* 검색 */
  const actSearch = async () => {
    setPageNumber(() => { return 1 });
    await getWorkOrderLogList(0, _workOrderType, _workOrderStatus);
  };

  /* 초기화 */
  const actReset = async () => {
    setWorkOrderType(() => { return 'all' });
    setWorkOrderStatus(() => { return 'all' });
    setStartDate(() => { return '' });
    setEndDate(() => { return '' });
    setSearchOption(() => { return '' });
    setSearchText(() => { return '' });
    setPageNumber(() => { return 1 });
    await getWorkOrderLogList(0, 'all', 'all', true);
  };

  const actShowList = async (workOrderLog) => {
    setSelectedWorkOrder(workOrderLog);
    setTimeout(setModalStatus(true), 1000);
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>작업지시 선택</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <NavBar
                title={
                  <div className="SearchSection" style={{ columnGap: '20px' }}>
                    <select
                      className="SelectType"
                      value={_workOrderType}
                      onChange={(e) => {
                        setWorkOrderType(() => { return e.target.value });
                        setPageNumber(() => { return 1 });
                        getWorkOrderLogList(0, e.target.value, _workOrderStatus);
                      }}
                    >
                      <option value="all">전체</option>
                      {_workOrderTypes.map((thisItem, index) => {
                        return (<option key={index + '_workOrderType'} value={thisItem.workOrderTypeId}>{thisItem.workOrderTypeName}</option>);
                      })}
                    </select>
                    <select
                      className="SelectStatus"
                      value={_workOrderStatus}
                      onChange={(e) => {
                        setWorkOrderStatus(() => { return e.target.value });
                        setPageNumber(() => { return 1 });
                        getWorkOrderLogList(0, _workOrderType, e.target.value);
                      }}
                    >
                      {[
                        { optionValue: 'all', optionText: '전체' },
                        { optionValue: 'waitNwork', optionText: '대기 & 작업중' },
                        { optionValue: 'wait', optionText: '대기' },
                        { optionValue: 'work', optionText: '작업중' },
                        { optionValue: 'pause', optionText: '일지정지' },
                        { optionValue: 'stop', optionText: '중단' },
                        { optionValue: 'end', optionText: '완료' },
                        { optionValue: 'cancel', optionText: '취소' },
                      ].map((thisItem, index) => {
                        return (<option key={index + '_workOrderStatus'} value={thisItem.optionValue}>{thisItem.optionText}</option>);
                      })}
                    </select>
                    <div className="SearchSection">
                      <input
                        type="date"
                        className="SelectDate"
                        value={_startDate}
                        onChange={(e) => {setStartDate(() => { return e.target.value });}}
                      />
                      <IconRightArrow />
                      <input
                        type="date"
                        className="SelectDate"
                        value={_endDate}
                        onChange={(e) => {setEndDate(() => { return e.target.value });}}
                      />
                    </div>

                    <div className="ContentCBox">
                      <div className="ContentCBox">
                        <select
                          className="SearchOption"
                          value={_searchOption}
                          onChange={(e) => {setSearchOption(() => { return e.target.value });}}
                        >
                          <option value="">검색옵션</option>
                          <option value="workOrderCode">작업코드</option>
                          <option value="workOrderName">작업이름</option>
                        </select>
                        <input
                          className="SearchBar"
                          placeholder="Search..."
                          value={_searchText}
                          onInput={(e) => {setSearchText(() => { return e.target.value });}}
                        />
                      </div>

                      <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                      <div className="ResetButton" onClick={actReset}>초기화</div>
                    </div>
                  </div>
                }
                nav={''}
              />

              <TableSection content={
                <table>
                  <thead style={{ zIndex: '800' }}>
                    <tr>
                      <th style={{ minWidth: '150px', padding: 'unset', width: '150px' }}>공정</th>
                      <th style={{ minWidth: '60px', padding: 'unset', width: '60px' }}>작업<br />상태</th>
                      <th>작업유형</th>
                      <th>작업코드</th>
                      <th>작업이름</th>
                      {(_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) ? <th>거래처</th> : null}
                      <th>생산<br />목표수량</th>
                      <th>작업장소</th>
                      <th>기간</th>
                      <th>작성자</th>
                      <th>책임자</th>
                      <th>작업자</th>
                      <th>비고</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkNullArray(_workOrderLogList, []).map((thisItem, index) => {
                      let colNum = 13;
                      if (_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) colNum = colNum + 1;

                      return (
                        <Fragment key={index + '_workOrderRow'}>
                          <tr data-key={thisItem.workOrderLogId}>
                            <ListButton
                              style={{ minWidth: '150px', padding: 'unset', width: '150px' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                actShowList(thisItem);
                              }}
                            >
                              공정리스트
                              <GuideText />
                            </ListButton>
                            <StatusTdButton
                              statusStyle={{ cursor: 'default', minHeight: '100px', minWidth: '60px', width: '60px' }}
                              content={thisItem}
                              contentId={thisItem.workOrderLogId}
                              status={thisItem.workOrderStatus}
                            />
                            <td>{thisItem.workOrderTypeName}</td>
                            <td>{thisItem.workOrderCode}</td>
                            <td>{thisItem.workOrderName}</td>
                            {(_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) ? <td>{thisItem.accountName || ''}</td> : null}
                            <td>{(checkEmptyNull(thisItem.amount, 0) * 1).toLocaleString()}</td>
                            <td>{thisItem.workPlace}</td>
                            <td>{DateFormat(checkEmptyNull(thisItem.workOrderScheduledStartDate, ''))}<br /> ~ {DateFormat(checkEmptyNull(thisItem.workOrderScheduledEndDate, ''))}</td>
                            <td>{thisItem.userName}</td>
                            <td>{checkNullArray(thisItem.managers, []).map((worker, index) => <p key={index + '_managers'}>{worker.managerName}</p>)}</td>
                            <td>{checkNullArray(thisItem.workers, []).map((worker, index) => <p key={index + '_workers'}>{worker.workerName}</p>)}</td>
                            <td>{thisItem.remark}</td>
                          </tr>
                        </Fragment>
                      );
                    })}
                  </tbody>
                </table>
              }
              ></TableSection>
            </ModalMain>

            <PagingComponent
              page={_pageNumber}
              count={_totalSize}
              size={10}
              pageEvent={(page) => {
                console.log('page : ', page);
                setPageNumber(() => { return page });
              }}
            />

            <ModalFooter>
              <ModalButton onClick={(e) => {e.preventDefault(); props.close()}}>확인</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}

      {_modalStatus === true ? (
        <SelectProcessLogs
          content={_selectedWorkOrder}
          processLogList={_processLogList}
          update={(data) => {
            setProcessLogList((prev) => {return [...prev, ...data]});
            props.update(data);
          }}
          open={_modalStatus}
          close={() => {
            setModalStatus(false);
            getWorkOrderLogList(_pageNumber - 1, _workOrderType, _workOrderStatus);
            // props.close();
          }}
        ></SelectProcessLogs>
      ): null}
    </>
  );
};

export default SelectWorkOrderLog;
