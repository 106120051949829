const initialState = {
  elementType: 'material',

  startDate: '',
  endDate: '',

  searchData: {
    elementName: '',
    elementCode: '',
    stockPlaceName: '',
  },
  searchAttrs: {},

  clickedCategory: {},
  clickedLevel1Category: {},
  clickedLevel2Category: {},
  clickedLevel3Category: {},
  clickedLevel4Category: {},
  clickedLevel5Category: {},

  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,

  element: {},
  elements: [],

  level1Categories: [],
  level2Categories: [],
  level3Categories: [],
  level4Categories: [],
  level5Categories: [],
};

const resetState = {
  elementType: 'material',

  startDate: '',
  endDate: '',

  searchData: {
    elementName: '',
    elementCode: '',
    stockPlaceName: '',
  },
  searchAttrs: {},

  clickedCategory: {},
  clickedLevel1Category: {},
  clickedLevel2Category: {},
  clickedLevel3Category: {},
  clickedLevel4Category: {},
  clickedLevel5Category: {},

  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const stockPlaceHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETSTOCKPLACEHISTORYTYPE':
      return {
        ...state,
        elementType: action.payload,
      };
    case 'SETSTOCKPLACEHISTORYSTARTDATE':
      return {
        ...state,
        startDate: action.payload,
      };
    case 'SETSTOCKPLACEHISTORYENDDATE':
      return {
        ...state,
        endDate: action.payload,
      };
    case 'SETSTOCKPLACEHISTORYSEARCHDATA':
      return {
        ...state,
        searchData: action.payload,
      };
    case 'SETSTOCKPLACEHISTORYSEARCHATTS':
      return {
        ...state,
        searchAttrs: action.payload,
      };

    case 'SETSTOCKPLACEHISTORYCLICKEDCATEGORY':
      return {
        ...state,
        clickedCategory: action.payload,
      };
    case 'SETSTOCKPLACEHISTORYCLICKEDLEVEL1CATEGORY':
      return {
        ...state,
        clickedLevel1Category: action.payload,
      };
    case 'SETSTOCKPLACEHISTORYCLICKEDLEVEL2CATEGORY':
      return {
        ...state,
        clickedLevel2Category: action.payload,
      };
    case 'SETSTOCKPLACEHISTORYCLICKEDLEVEL3CATEGORY':
      return {
        ...state,
        clickedLevel3Category: action.payload,
      };
    case 'SETSTOCKPLACEHISTORYCLICKEDLEVEL4CATEGORY':
      return {
        ...state,
        clickedLevel4Category: action.payload,
      };
    case 'SETSTOCKPLACEHISTORYCLICKEDLEVEL5CATEGORY':
      return {
        ...state,
        clickedLevel5Category: action.payload,
      };

    case 'SETSTOCKPLACEHISTORYPAGENUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'SETSTOCKPLACEHISTORYTOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };
    case 'SETSTOCKPLACEHISTORYISPUSHEDSEARCHBUTTON':
      return {
        ...state,
        isPushedSearchButton: action.payload,
      };

    case 'SETSTOCKPLACEHISTORYRESET':
      return {
        ...state,
        ...resetState,
      };

    case 'SELECTONESTOCKPLACEHISTORY':
      return {
        ...state,
        element: action.payload,
      };

    case 'SELECTSTOCKPLACEHISTORYS':
      return {
        ...state,
        elements: action.payload,
      };

    case 'SETSTOCKPLACEHISTORYLEVEL1CATEGORIES':
      return {
        ...state,
        level1Categories: action.payload,
      };
    case 'SETSTOCKPLACEHISTORYLEVEL2CATEGORIES':
      return {
        ...state,
        level2Categories: action.payload,
      };
    case 'SETSTOCKPLACEHISTORYLEVEL3CATEGORIES':
      return {
        ...state,
        level3Categories: action.payload,
      };
    case 'SETSTOCKPLACEHISTORYLEVEL4CATEGORIES':
      return {
        ...state,
        level4Categories: action.payload,
      };
    case 'SETSTOCKPLACEHISTORYLEVEL5CATEGORIES':
      return {
        ...state,
        level5Categories: action.payload,
      };

    default:
      return state;
  }
};

export default stockPlaceHistoryReducer;
