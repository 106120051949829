import styled from 'styled-components';

export const Style = () => {
  
}

// 자재 현황 스타일
export const MainContents = styled.div`
  display: grid;
  grid-template-columns: 35% 65%;
  height: 100%;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;
  .TableSection {
    box-shadow: 0 6px 6px 0 rgba(196, 196, 196, 0.8);
    table {
      thead tr {
        th {background-color: #586a85; padding: 8px;}
        &:nth-of-type(1) {
          th {background-color: var(--MainNavy);border: none;}
        }
      }
      tbody tr td {
        max-width: 10%;
        padding: 8px;
        white-space: normal;
      }
    }
  }
`;

export const TableTitle = styled.th`
  padding: unset !important;
  position: relative;
  .TableTitleContent {
    align-items: center;
    background-color: var(--MainNavy);
    border: none;
    box-shadow: 0px 0px 28px 2px #333;
    display: flex;
    justify-content: space-between;
    padding: 8px 24px;
    width: 100%;
    &.element {background-color: var(--SubBlue);}
  }
`;

export const TableTitleContent = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  border: none;
  box-shadow: 0px 0px 28px 2px #333;
  display: flex;
  justify-content: space-between;
  padding: 8px 24px;
  width: 100%;
`;
export const SortContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  div {
    align-items: center;
    border-radius: 4px;
    color: #bdbdbd;
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    height: auto;
    justify-content: center;
    padding: 4px 8px;
    width: auto;
    &.active {color: var(--white);}
    &:hover {color: var(--color-4);}
  }
`;

// 좌측 레이아웃
export const StockContents = styled.div`
  display: grid;
  grid-template-rows: 50% 50%;
  /* grid-template-rows: 0.05fr 0.440fr 0.550fr; */
  height: 100%;

  &.single {grid-template-columns: repeat(3, 1fr); grid-template-rows: 100%;}

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}

  & .TableSection {
    box-shadow: 0 6px 6px 0 rgba(196, 196, 196, 0.8);
    border-radius: 8px;
    overflow: auto;
    margin-bottom: 0;
    max-width: 400px;
    min-width: 94%;
    width: fit-content;
    
    table {height: 100%;}
    tr {border: none;}
    td, th {max-width: 100px; min-width: unset; padding: unset;}
  }
  .monitoringStatus {
    align-items: center;
    display: flex;
    gap: 16px;
    height: auto;
    justify-content: center;
    padding: 16px;
    padding-bottom: 0px;
    white-space: nowrap;
    width: 98%; 
    li {
      width: 100%;
      padding: 10px;
      border-radius: 8px;
      text-align: center;
      background-color: var(--color-2);
      color: var(--white);

      &:nth-of-type(1), &:nth-of-type(2) {background-color: var(--SubBlue);}
      span {font-size: 2rem;}
    }
  }
`;
// 우측 레이아웃
export const ProductionStatus = styled.div`
  background-color: var(--Bg);
  display: grid; 
  gap: 16px 0;
  grid-template-rows: 0.49fr 0.485fr;

  &.single {grid-template-rows: 100%;}

  & .TableSection {
    border-radius: 8px;
    box-shadow: 0 6px 6px 0 rgba(196, 196, 196, 0.8);
    height: 100%;
    margin: 16px 0;
    margin-bottom: 0;
    margin-right: 16px;
    max-width: 400px;
    min-width: 98%;
    width: fit-content;

    tr {border: none;}
    td, th {max-width: 120px; min-width: unset; padding: unset;}
  }
`;
export const ProcessSection = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  height: fit-content;
  width: 100%;
`;
export const Process = styled.div`
  align-items: center;
  background-color: ${(props) => {
    switch (props.status) {
      case 'NO': return 'var(--MainNavy)';
      case 'wait': return 'var(--gray-200)';
      case 'work': return 'var(--WorkingColor)';
      case 'cut': return '#BCE55C';
      case 'welding': return '#9FC93C';
      case 'last': return '#689900';
      case 'end': return 'var(--ThirdBlue)';
      case 'stop': return 'var(--MainRed)';
      case 'pause': return 'var(--MainYellow)';
      case 'cancel': return 'var(--Bg)';

      default: return 'unset';
    }
  }};
  box-sizing: border-box;
  color: ${(props) => {
    return props.status.toLowerCase() === 'cancel' ? 'var(--gray-200)' : 'var(--white)';
  }};
  display: flex;
  font-size: 1em;
  font-weight: 400;
  height: fit-content;
  justify-content: center;
  padding: 2px;
  width: 100%;
`;
export const ImportantList = styled.div`
  height: fit-content;
  width: 100%;
`;
export const ImportantProcess = styled.div`
  align-items: center;
  background-color: ${(props) => {
    switch (props.status) {
      case 'NO': return 'var(--MainNavy)';
      case 'wait': return 'var(--gray-200)';
      case 'work': return 'var(--WorkingColor)';
      case 'cut': return '#BCE55C';
      case 'welding': return '#9FC93C';
      case 'last': return '#689900';
      case 'end': return 'var(--ThirdBlue)';
      case 'stop': return 'var(--MainRed)';
      case 'pause': return 'var(--MainYellow)';
      case 'cancel': return 'var(--Bg)';

      default: return 'unset';
    }
  }};
  box-sizing: border-box;
  color: var(--white);
  column-gap: 10px;
  display: flex;
  height: fit-content;
  justify-content: flex-start;
  width: 100%;
`;

export const ConnectStatus = styled.div`
  background-color: ${(props) => {
    return props.connectStatus === 'connect' ? 'var(--MainGreen)' : 'var(--MainRed)';
  }};
  border: 2px solid var(--white);
  border-radius: 50%;
  bottom: 25px;
  cursor: pointer;
  height: 42px;
  position: fixed;
  right: 80px;
  width: 42px;
  z-index: 999;
`;
export const TimeCount = styled.div`
  bottom: 25px;
  position: fixed;
  right: 130px;
  width: 100px;
  &>div>div, &>div>div>div {height: 42px !important;}
  &>div>div>div {background-color: var(--MainBlue) !important;}
`;
