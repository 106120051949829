import React, { Fragment } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Container, Table, Tbody, Td, Th, Title, Tr, TableContainer, TableRowContainer, SectionContainer, PaginationContainer } from 'pages/contract/modal/Tab.style';

export default function ShipmentInfoTab({ data }) {
  const navigate = useNavigate();

  const customizedContent = React.useMemo(() => {
    try {
      return JSON.parse(data.customizedContent || '{}');
    } catch (error) {
      console.error('Error parsing customizedContent:', error);
      return {};
    }
  }, [data.customizedContent]);

  const getStatusLabel = (status) => {
    const statusLabels = {
      wait: '대기',
      end: '완료',
    };
    return statusLabels[status] || '-';
  };

  const formatPrice = (price) =>
    price != null ? new Intl.NumberFormat().format(price) : '-';

  const handleNavigation = (path, contentKey) => {
    const searchParams = createSearchParams({
      [contentKey]: JSON.stringify(data),
    });

    navigate({
      pathname: path,
      search: `?${searchParams}`,
      replace: true,
    });
  };

  const shipmentInfoRows = [
    { label: '출하상태', value: getStatusLabel(data.shipmentStatus) },
    { label: '출하일', value: data.shipmentDate },
    { label: '출하이름', value: data.shipmentName },
    { label: '출하코드', value: data.shipmentCode },
    {
      label: '출하금액',
      value: formatPrice(data.shipmentPrice),
      align: 'right',
    },
    { label: '비고', value: data.remark },
  ];

  const accountInfoRows = [
    { label: '거래처명', value: data.shipmentAccountName },
    { label: '거래처코드', value: data.shipmentAccountCode },
    { label: '사업자등록번호', value: data.shipmentBusinessNumber },
    { label: '주소', value: data.shipmentAddress },
    { label: '전화번호', value: data.shipmentTelNumber },
    { label: '담당자', value: data.shipmentManagerName },
  ];

  const driverInfoRows = [
    { label: '운전자명', value: customizedContent.delivery?.driver },
    { label: '전화번호', value: customizedContent.delivery?.telNumber },
    {
      label: '차량번호',
      value: customizedContent.delivery?.licensePlateNumber,
    },
  ];

  return (
    <Container className="contract">
      <Title>
        출하 상세정보
        <PaginationContainer>
          <button
            onClick={() =>
              handleNavigation('/order/shipment/update', 'updateContent')
            }
          >
            수정
          </button>
          <button
            onClick={() =>
              handleNavigation('/order/shipment/copy', 'copyContent')
            }
          >
            복사
          </button>
        </PaginationContainer>
      </Title>
      <TableContainer className="column">
        <Table>
          <Tbody>
            {shipmentInfoRows.map((row, index) => (
              <Tr key={index}>
                <Th>{row.label}</Th>
                <Td style={{ textAlign: row.align || 'left' }}>
                  {row.value ?? '-'}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <TableRowContainer>
          <SectionContainer>
            <Title className="sub-title">거래처</Title>
            <Table>
              <Tbody>
                {accountInfoRows.map((row, index) => (
                  <Tr key={index}>
                    <Th>{row.label}</Th>
                    <Td>{row.value ?? '-'}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </SectionContainer>

          {driverInfoRows.some((row) => row.value) && (
            <SectionContainer>
              <Title className="sub-title">운전자</Title>
              <Table>
                <Tbody>
                  {driverInfoRows.map((row, index) => (
                    <Tr key={index}>
                      <Th>{row.label}</Th>
                      <Td>{row.value ?? '-'}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </SectionContainer>
          )}
        </TableRowContainer>
      </TableContainer>
    </Container>
  );
};
