import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { modalFileActions_setFileReset } from 'store/modules/actions/modal/modalFileActions';

import { attachFileApi } from 'api/apis/attachFileApi';
import { elementAttributeApi } from 'api/apis/elementAttributeApi';
import { elementApi } from 'api/apis/elementApi';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import SelectCategory from 'components/category/modal/SelectCategory';
import SelectFiles from 'pages/file/modal/SelectFiles';

const FileList = styled.div`
  width: 100%;
`;
const FileBox = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  height: 40px;
  justify-content: flex-start;
  margin: 5px auto;
  padding: 0px 15px;
  position: relative;
  width: 100%;
`;
const DeleteFileButton = styled.div`
  height: 15px;
  position: absolute;
  right: 15px;
  width: 15px;

  div {background-color: var(--white); cursor: pointer; height: 15px; width: 15px;}
`;

const CreateElement = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();

  const version = checkNullParse(searchParams.get('version'), 1);
  console.log('version : ', version);
  const createContent = checkNullParse(searchParams.get('createContent'), {});
  const { elementType } = createContent;

  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    // categoryId: checkEmptyNull(categoryId, 0),
    categoryId: 0,

    elementType: elementType,
    // elementType: 'material',

    elementName: '',
    elementCode: '',
    naraElementCode: '',

    elementUnit: '',
    price: 0,
    elementSafeStock: 0,
    elementStock: 0,

    elementAttributeValueList: [],

    fileDataClass: 'element',
  });

  const [elementAttributeList, setElementAttributeList] = useState([]);
  const [_elementAttrValueList, setElementAttrValueList] = useState({});

  const [_fileList, setFileList] = useState([]);
  const [_fileModalStatus, setFileModalStatus] = useState(false);

  const [_category, setCategory] = useState([]);
  const [_categoryModalStatus, setCategoryModalStatus] = useState(false);


  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('109') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    (async () => { await getElementAttrs(_formData.elementType) })();

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => { }, [_category, _elementAttrValueList])
  useEffect(() => {
    if (_onload === 'loaded') {
      getElementAttrs(_formData.elementType);
    }
  }, [_formData.elementType])

  /* ====================================================================== #4 */
  const getElementAttrs = async (elementType) => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (checkEmptyNull(elementType, false) && elementType !== 'all') {
      BodyToPost.elementType = elementType;
    }
    console.log('getElementAttrs - BodyToPost : ', BodyToPost);

    await elementAttributeApi.searchElementAttribute(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('elementAttributeApi.searchElementAttribute : ', response);
      let temp = [];
      let result = [];
      response.data.forEach((thisItem) => {
        if (!temp.includes(thisItem.elementAttributeName)) {
          temp.push(thisItem.elementAttributeName);
          result.push(thisItem);
        }
      });
      setElementAttributeList(() => { return result });
    });
  };


  /* ====================================================================== #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case 'elementSafeStock':
        let setElementSafeStock = value;
        if (!setElementSafeStock.toString().startsWith('0.')) setElementSafeStock = setElementSafeStock.toString().replace(/^0+/, '');
        if (setElementSafeStock === '' || setElementSafeStock < 0) setElementSafeStock = 0;
        if (setElementSafeStock.length > 15) return;
        console.log('setElementSafeStock : ', setElementSafeStock);
        return setFormData((prev) => {
          return { ...prev, elementSafeStock: setElementSafeStock };
        });

      case 'outsourcing':
        if (e.target.checked) {
          return setFormData((prev) => { return { ...prev, elementName: '*' + prev.elementName } });
        } else {
          return setFormData((prev) => {
            return {
              ...prev,
              elementName: checkEmptyNull(prev.elementName, false) && prev.elementName.startsWith('*') === true ? prev.elementName.replace('*', '') : prev.elementName,
            };
          });
        }

      case 'price':
        let setElementPrice = value;
        if (!setElementPrice.toString().startsWith('0.')) setElementPrice = setElementPrice.toString().replace(/^0+/, '');
        if (setElementPrice === '' || setElementPrice < 0) setElementPrice = 0;
        if (setElementPrice.length > 15) return;
        console.log('setElementPrice : ', setElementPrice);
        return setFormData((prev) => { return { ...prev, price: setElementPrice } });

      default: break;
    }

    // console.log({ [name]: value });
    setFormData((prev) => { return { ...prev, [name]: value } });
  };

  const handleAttributeValue = (e, attr, attrValue) => {
    const { value } = e.target;
    setElementAttrValueList((prev) => { return { ...prev, [attr.elementAttributeId]: value } });
  };

  const actDeleteFile = (e, index, file) => {
    setFileList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);
      return prevData;
    });
  };

  const handleCategory = (category) => {
    const { clickedLevel1Category, clickedLevel2Category, clickedLevel3Category, clickedLevel4Category, clickedLevel5Category } = category;
    let returnData = '';
    if (checkEmptyNull(clickedLevel1Category?.categoryName, false)) returnData += clickedLevel1Category?.categoryName;
    if (checkEmptyNull(clickedLevel2Category?.categoryName, false)) returnData += ' >> ' + clickedLevel2Category?.categoryName;
    if (checkEmptyNull(clickedLevel3Category?.categoryName, false)) returnData += ' >> ' + clickedLevel3Category?.categoryName;
    if (checkEmptyNull(clickedLevel4Category?.categoryName, false)) returnData += ' >> ' + clickedLevel4Category?.categoryName;
    if (checkEmptyNull(clickedLevel5Category?.categoryName, false)) returnData += ' >> ' + clickedLevel5Category?.categoryName;
    // const returnData = checkEmptyNull(clickedLevel1Category?.categoryName, '') + '>>'
    //   + checkEmptyNull(clickedLevel2Category?.categoryName, '') + '>>'
    //   + checkEmptyNull(clickedLevel3Category?.categoryName, '');
    return returnData;
  }

  /* 추가 */
  const actCreate = async (e) => {
    e.preventDefault();

    if (!checkEmptyNull(_formData.elementName, false)) return alert('물품이름을 입력해주세요.');
    if (!checkEmptyNull(_formData.elementCode, false)) return alert('물품코드를 입력해주세요.');

    const mapElementAttrValueList = Object.keys(_elementAttrValueList).map((thisKey) => {
      const returnData = {
        elementAttributeId: Number(thisKey),
        value: _elementAttrValueList[thisKey],
      };

      return returnData;
    });

    const BodyToPost = {
      ..._formData,

      elementStock: 0,
      elementAttributeValueList: mapElementAttrValueList,
    };

    // 카테고리
    // let category = {};
    if (checkNullObject(_category.clickedLevel3Category, false)) {
      BodyToPost.categoryId = _category.clickedLevel3Category.categoryId;
      // category = { ..._category.clickedLevel3Category };
    } else {
      if (checkNullObject(_category.clickedLevel2Category, false)) {
        BodyToPost.categoryId = _category.clickedLevel2Category.categoryId;
        // category = { ..._category.clickedLevel2Category };
      } else {
        if (checkNullObject(_category.clickedLevel1Category, false)) {
          BodyToPost.categoryId = _category.clickedLevel1Category.categoryId;
          // category = { ..._category.clickedLevel1Category };
        }
      }
    }
    if (!checkEmptyNull(BodyToPost.categoryId, false) || BodyToPost.categoryId === 0) return alert('패밀리를 선택해주세요.')
    console.log('BodyToPost : ', BodyToPost, JSON.stringify(BodyToPost));

    const postFormData = new FormData();
    postFormData.append('key', new Blob([JSON.stringify(BodyToPost)], { type: 'application/json' }));
    for (let values of postFormData.values()) {
      console.log('postFormData : ', values);
    }

    await elementApi.createElement(postFormData).then((response) => {
      if (response === undefined) return;
      console.log('elementApi.createElement : ', response);

      if (_fileList.length > 0) {
        _fileList.forEach(async (file) => {
          const BodyToFilePost = {
            companyId: userReducer.company.companyId,
            fileDataId: file.fileDataId,
            elementId: response.data.elementId,
          };
          console.log('BodyToFilePost : ', BodyToFilePost);

          await attachFileApi.createAttachFile(BodyToFilePost).then((response) => {
            if (response === undefined) return;
            console.log('attachFileApi.createAttachFile : ', response);
          });
        });
      }

      alert('물품을 추가했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  };

  return (
    <Grid2Body contents={
      <>
        <main className="Main">
          <NavBar title={<NavTitle menuCode={'101'} />} nav={''} />

          <Form
            title={<><NavTitle menuCode={'101'} /><br />추가</>}
            buttons={
              <>
                <button className="formButton" onClick={actCreate}>저장</button>
                <button className="formButton cancle"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(pageReducer.currentPage, { replace: true });
                  }}
                >
                  취소
                </button>
              </>
            }
            forms={
              <>
                <div className='formBox'>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <h4>패밀리</h4>
                    <div className="formButton" style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                      onClick={() => { setCategoryModalStatus(true) }}
                    >
                      패밀리 선택
                    </div>
                  </div>
                  <p>
                    {handleCategory(_category)}
                  </p>
                </div>

                <div className="formBox">
                  <h4>물품유형</h4>
                  <select name='elementType' value={_formData.elementType} onChange={handleInputEvent}>
                    {userReducer.elementType.map((elementType, index) => {
                      let typeText = '';
                      switch (elementType) {
                        case 'material': typeText = '자재'; break;
                        case 'semi': typeText = '부자재'; break;
                        case 'half':
                          if (userReducer.company.companyId === 3) typeText = '판매물품';
                          else typeText = '반제품';
                          break;
                        case 'product':
                          if (userReducer.company.companyId === 3) typeText = '보유물품';
                          else typeText = '완제품';
                          break;
                        case 'tool': typeText = '공구'; break;

                        default: return null;
                      }
                      return (<option key={elementType + '_elementType'} value={elementType}>{typeText}</option>);
                    })}
                  </select>
                </div>

                <div className="formBox">
                  <div style={{ display: 'flex', height: 'fit-content', justifyContent: 'space-between', width: '100%' }}>
                    <h4>물품이름</h4>
                    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-end', width: '80px' }}>
                      <h4>외주</h4>
                      <input
                        type="checkbox"
                        name="outsourcing"
                        defaultChecked={checkEmptyNull(_formData.elementName, false) && _formData.elementName.startsWith('*')}
                        onChange={handleInputEvent}
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    name="elementName"
                    data-required="required"
                    placeholder="* 이름..."
                    value={checkEmptyNull(_formData.elementName, '')}
                    onInput={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>물품코드</h4>
                  <input
                    type="text"
                    name="elementCode"
                    data-required="required"
                    placeholder="* 코드..."
                    value={checkEmptyNull(_formData.elementCode, '')}
                    onInput={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>단위</h4>
                  <input
                    list="UnitList"
                    name="elementUnit"
                    placeholder="단위..."
                    value={checkEmptyNull(_formData.elementUnit, '')}
                    onInput={handleInputEvent}
                  />
                  <datalist id="UnitList">
                    <option value="개">개</option>
                    <option value="m">m</option>
                    <option value="kg">kg</option>
                    <option value="g">g</option>
                  </datalist>
                </div>

                <div className="formBox">
                  <h4>안전재고</h4>
                  <input
                    type="number"
                    name="elementSafeStock"
                    min={0}
                    value={checkEmptyNull(_formData.elementSafeStock, 0)}
                    onInput={handleInputEvent}
                    placeholder="안전재고..."
                  />
                </div>

                <div className="formBox">
                  <h4>단가</h4>
                  <input
                    type="number"
                    name="price"
                    min={0}
                    value={checkEmptyNull(_formData.price, 0)}
                    onInput={handleInputEvent}
                    placeholder="단가..."
                  />
                </div>

                {checkNullArray(elementAttributeList, []).map((thisAttribute) => {
                  const elementAttrValueList = { ..._elementAttrValueList };
                  const attrIndex = Object.keys(elementAttrValueList).findIndex((attrId) => Number(attrId) === Number(thisAttribute.elementAttributeId));
                  if (attrIndex !== -1) {
                    const attrValue = _elementAttrValueList[thisAttribute.elementAttributeId];
                    return (
                      <div key={thisAttribute.elementAttributeId + '_attrInputs'} className="formBox">
                        <h4>{thisAttribute.elementAttributeName}</h4>
                        <input
                          type="text"
                          placeholder={thisAttribute.elementAttributeName + '...'}
                          value={attrValue}
                          onInput={(e) => { handleAttributeValue(e, thisAttribute, attrValue) }}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div key={thisAttribute.elementAttributeId + '_attrInputs'} className="formBox">
                        <h4>{thisAttribute.elementAttributeName}</h4>
                        <input
                          type="text"
                          placeholder={thisAttribute.elementAttributeName + '...'}
                          defaultValue=""
                          onInput={(e) => { handleAttributeValue(e, thisAttribute) }}
                        />
                      </div>
                    );
                  }
                })}

                {(() => {
                  if (_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) {
                    return (<div className="formBox">
                      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <h4>파일</h4>
                        <div className="formButton" style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                          onClick={() => {
                            dispatch(modalFileActions_setFileReset());
                            setTimeout(setFileModalStatus(true), 1000);
                          }}
                        >
                          파일 선택
                        </div>
                      </div>

                      <FileList>
                        {_fileList.map((thisItem, index) => {
                          let fileData = { ...thisItem };
                          if (checkNullObject(thisItem.fileData, false)) fileData = { ...thisItem, ...thisItem.fileData };
                          return (
                            <FileBox key={fileData.fileDataId + '_files'}>
                              {fileData.fileDataName}
                              <DeleteFileButton onClick={(e) => { actDeleteFile(e, index, fileData) }}><CloseButtonIcon /></DeleteFileButton>
                            </FileBox>
                          );
                        })}
                      </FileList>
                    </div>);
                  } else return null;
                })()}
              </>
            }
          />
        </main>

        {_fileModalStatus === true && (
          <SelectFiles
            buttonTitle={'파일 선택'}
            buttonEvent={(data) => {
              const newDataList = data.filter((thisItem) => _fileList.findIndex((thisIndex) => thisIndex.fileDataId === thisItem.fileDataId) === -1);
              setFileList((prev) => { return [...prev, ...newDataList] });
              setTimeout(setFileModalStatus(false), 1000);
            }}
            open={_fileModalStatus}
            close={() => { setFileModalStatus(false) }}
          />
        )}

        {_categoryModalStatus === true && (
          <SelectCategory
            version={version}
            category={(data) => {
              setCategory(() => { return data });
              setTimeout(setCategoryModalStatus(false), 1000);
            }}
            open={_categoryModalStatus}
            close={() => { setCategoryModalStatus(false) }}
          />
        )}
      </>
    }
    />
  );
};

export default CreateElement;
