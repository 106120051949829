import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { clusterActions_setOpenMenus } from 'store/modules/actions/cluster/clusterActions.js';

import { mainpageMenu, mypageMenu } from './consts/menu.js';

import { checkNullArray } from 'components/checkValues/checkValues.js';
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { ClusterMenuComponent, ClusterSideBarComponent } from '../styles/sidebar.style.js';

const HandleMenu = ({ menu, index, level = 0, openMenus, setOpenMenus }) => { // 메뉴 설정
  const location = useLocation();
  const navigate = useNavigate();

  const paddingLeft = 8 + (level * 20); // ㄴ 계층 단계 만들기

  const isOpen = openMenus.includes(menu.title); // 메뉴 오픈 상태

  const toggleMenu = (title) => {
    if (isOpen) {
      setOpenMenus(openMenus.filter(openMenu => openMenu !== title)); // 메뉴 닫기
    } else {
      setOpenMenus([...openMenus, title]); // 메뉴 열기
    }
  };

  return (
    <ClusterMenuComponent
      // key={index + '_ClusterMenu'}
      to={menu.link}
      className={location.pathname.includes(menu.group) ? 'menu active' : location.pathname === menu.link ? 'menu active' : 'menu'}
    >
      <div
        className='menu-title'
        style={{ paddingLeft: `${paddingLeft}px` }}
        onClick={() => {
          if (checkNullArray(menu.children, false)) toggleMenu(menu.title);
          else navigate(menu.link);
        }}
      >
        {menu.title}
        {checkNullArray(menu.children, false) ?
          isOpen ? <FaCaretUp /> : <FaCaretDown />
          : null}
      </div>
      {isOpen && checkNullArray(menu.children, false) ? ( // 하위 메뉴 -children 있을 때만
        <div className='sub-container'>
          {menu.children.map((childMenu, childIndex) =>
            <HandleMenu
              key={`${index}-${childIndex}_ClusterSubMenu`}
              menu={childMenu}
              index={`${index}-${childIndex}`}
              level={level + 1}
              openMenus={openMenus}
              setOpenMenus={setOpenMenus}
            />
          )}
        </div>
      ) : null}
    </ClusterMenuComponent>
  );
};

const ClusterSideBar = () => { // 사이드 바
  const location = useLocation();
  const clusterId = location.pathname.split('/platform/cluster')[0];
  const clusterPath = clusterId + '/platform/cluster';

  const dispatch = useDispatch();
  const { clusterReducer } = useSelector(state => state);
  const { openMenus } = clusterReducer;

  const [_menu, setMenu] = useState({});

  useEffect(() => {
    if (location.pathname.includes('/mypage')) {
      const path = clusterPath + '/mypage';
      setMenu(() => { return mypageMenu(path); });
    } else {
      setMenu(() => { return mainpageMenu(clusterPath); });
    }
  }, [location.pathname]);
  useEffect(() => { }, [_menu, openMenus]);

  const handleOpenMenus = (openMenus) => {
    dispatch(clusterActions_setOpenMenus(openMenus));
  };

  return (
    <ClusterSideBarComponent>
      <Link to={_menu.link} className='platformTitle'>
        <h1>{_menu.title}</h1>
      </Link>
      <div className='menu-container'>
        {checkNullArray(_menu.children, []).map((menu, index) => {
          return (
            <HandleMenu
              key={index + '_ClusterMenu'}
              menu={menu}
              index={index}
              level={0}
              openMenus={openMenus}
              setOpenMenus={handleOpenMenus}
            />
          );
        })}
      </div>
    </ClusterSideBarComponent>
  );
};

export default ClusterSideBar;