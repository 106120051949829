import React, { useEffect, useState } from 'react';

import { fileDataApi } from 'api/apis/fileDataApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';

const UpdateContent = ({
  close, modalData
}) => {
  const body = {
    companyId: modalData.companyId,
    fileDataId: modalData.fileDataId,
    fileDataName: modalData.fileDataName,
  };
  const [_body, setBody] = useState(body);

  useEffect(() => {}, [_body]);

  const handleInputEvent = (e) => { // 파일 정보 입력
    const {name, value} = e.target;
    setBody((prev) => {
      const returnData = {...prev, [name] : value};
      return returnData;
    })
  };
  const actUpdate = async() => { // 파일 정보 수정
    await fileDataApi.updateFileData(_body.fileDataId, _body).then(response => {
      if(response === undefined) return;
      console.log('actUpdate : ', response);
      alert('파일 정보를 수정했습니다.');
      close('update');
    })
  };

  return (
    <section className='update-content'>
      <div>
        {[
          {name: 'fileDataName', title: '파일이름', disabled: false},
        ].map(((fileData) => {
          return (
            <div key={fileData.name}>
              <h4>{fileData.title}</h4>
              <input
                type='text'
                name={fileData.name}
                disabled={fileData.disabled}
                value={checkEmptyNull(_body[fileData.name], '')}
                onInput={handleInputEvent}
              />
            </div>
          )
        }))}
      </div>

      <button className='btn-save' onClick={actUpdate}>저장</button>
    </section>
  );
};

export default UpdateContent;