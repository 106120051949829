export const qualityActions_setWorkOrderType = (workOrderType) => {
  return {
    type: 'SETQUALITYWORKORDERTYPE',
    payload: workOrderType,
  };
};

export const qualityActions_setWorkOrderStatus = (workOrderStatus) => {
  return {
    type: 'SETQUALITYWORKORDERSTATUS',
    payload: workOrderStatus,
  };
};

export const qualityActions_setStartDate = (startDate) => {
  return {
    type: 'SETQUALITYSTARTDATE',
    payload: startDate,
  };
};

export const qualityActions_setEndDate = (endDate) => {
  return {
    type: 'SETQUALITYENDDATE',
    payload: endDate,
  };
};

export const qualityActions_setSearchOption = (searchData) => {
  return {
    type: 'SETQUALITYSEARCHOPTION',
    payload: searchData,
  };
};
export const qualityActions_setSearchText = (searchData) => {
  return {
    type: 'SETQUALITYSEARCHTEXT',
    payload: searchData,
  };
};
export const qualityActions_setSearchData = (searchData) => {
  return {
    type: 'SETQUALITYSEARCHDATA',
    payload: searchData,
  };
};

export const qualityActions_setPageNumber = (pageNumber) => {
  return {
    type: 'SETQUALITYPAGENUMBER',
    payload: pageNumber,
  };
};

export const qualityActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETQUALITYTOTALSIZE',
    payload: totalSize,
  };
};

export const qualityActions_setIsPushedSearchButton = (isPushedSearchButton) => {
  return {
    type: 'SETQUALITYISPUSHEDSEARCHBUTTON',
    payload: isPushedSearchButton,
  };
};

export const qualityActions_setReset = () => {
  return {
    type: 'SETQUALITYRESET',
  };
};