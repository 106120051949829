import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { attachFileApi } from 'api/apis/attachFileApi';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import ProcessForm from 'pages/productionOrder/custom/company/uri/crud/components/ProcessForm';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 90%;
  overflow: hidden;
  width: 98%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalHeaderContents = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 50px);
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: inherit;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  width: 100%;
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;


const UpdateUriProcessLogModal = (props) => {
  const updateContent = { ...props.updateContent };
  const { userReducer } = useSelector((state) => state);

  const [_checkId, setCheckId] = useState(0);

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    userId: updateContent.userId,
    user: { id: updateContent.userId, name: updateContent.userName },

    workOrderTypeId: '',

    workOrderStatus: updateContent.workOrderStatus,
    workOrderName: updateContent.workOrderName,
    workOrderCode: updateContent.workOrderCode,

    workOrderScheduledStartDate: DateFormat(checkEmptyNull(updateContent.workOrderScheduledStartDate, '')),
    workOrderScheduledEndDate: DateFormat(checkEmptyNull(updateContent.workOrderScheduledEndDate, '')),

    accountId: updateContent.accountId, // 거래처
    account: { accountId: updateContent.accountId, accountName: updateContent.accountName },

    workPlace: updateContent.workPlace, // 주소
    // detailedWorkPlace: '', // 현장명

    // message: '', // 작업자 알림
    remark: updateContent.remark, // 비고

    workOrderCustomizedContent: checkNullParse(updateContent.workOrderCustomizedContent, {
      BF: { date: '', amount: '' },
      SF: { date: '', amount: '' },
      MF: { date: '', amount: '' },
      total: { date: '', amount: '' },

      // workOrderProducts: '', // 품목
      colors: '', // 색상
      phoneNumber: '', // 전화번호
    }),

    processLogList: checkNullArray(updateContent.processLogList, []),
    deleteProcessList: [],
  });

  const [_processLogList, setProcessLogList] = useState(() => {
    return checkNullArray(updateContent.processLogList, []).map((processLog, index) => {
      const processLogData = {
        ...processLog,
        processCustomizedContent: checkNullParse(processLog.processCustomizedContent, {
          existProcessOutElement: false,
          processingCount: '',

          processCustomizedContent: {
            no: (index + 1).toString().padStart(2, '0'), // 순번
            numStatus: 'white', // NO 컬러
            bundle: '', // 몇 짝
            bundleSize: '', //
            materials: '', // 자재
            materialStatus: 'normal', // 자재 글꼴
            colors: '', // 색상
            colorStatus: 'white', // 색상 컬러
            glassType_W: '', // 유리종류_W
            glassType_H: '', // 유리종류_H
            remark: '', // 비고
          },
        }),
      };
      return processLogData;
    })
  });

  useEffect(() => { console.log('_processLogList : ', _processLogList); }, [_processLogList])

  /* ProcessForm */
  const actCreateProcess = () => {
    setCheckId((prev) => { return prev + 1; });
    setProcessLogList(prev => {
      const returnData = [...prev];
      const newData = {
        key: _checkId,
        existProcessOutElement: false,
        processingCount: '',

        processCustomizedContent: {
          no: '', // 순번
          numStatus: 'white', // NO 컬러
          bundle: '', // 몇 짝
          bundleSize: '', //
          materials: '', // 자재
          materialStatus: 'normal', // 자재 글꼴
          colors: '', // 색상
          colorStatus: 'white', // 색상 컬러
          glassType_W: '', // 유리종류_W
          glassType_H: '', // 유리종류_H
          remark: '', // 비고
        },

        attachFileList: [],
      };
      returnData.push(newData);
      return returnData;
    })
  }

  /* 작업지시 수정 */
  const actUpdate = async () => {
    const processLogList = _processLogList.map(processLog => {
      let deleteAttachFile = [];
      if (checkNullObject(processLog.attachFileList[0], false) && checkNullObject(processLog.fileData, false)) {
        if (processLog.attachFileList[0].fileDataId !== processLog.fileData.fileDataId) {
          // deleteAttachFile.push(processLog.attachFileList[0].attachFileId);
          deleteAttachFile = checkNullArray(processLog.attachFileList, []).map(item => item.attachFileId)
        }
      }
      const deleteTaskList = [];
      const prevProcessLogIndex = updateContent.processLogList.findIndex(item => item.processLogId === processLog.processLogId);
      const prevProcessLog = updateContent.processLogList[prevProcessLogIndex];
      prevProcessLog?.taskLogList.forEach(taskLog => {
        const taskLogIndex = processLog?.taskLogList.findIndex(item => item?.taskLogId === taskLog.taskLogId);
        if (taskLogIndex === -1) deleteTaskList.push(taskLog.taskLogId)
      })
      return {
        ...processLog,
        processAmount: 0, managers: [], workers: [],
        processCustomizedContent: JSON.stringify(processLog.processCustomizedContent),

        attachFileList: [],
        deleteAttachFile: deleteAttachFile,
        deleteTaskIds: deleteTaskList,
      }
    });
    const deleteProcessLogList = [];
    updateContent.processLogList.forEach(prev => {
      if (processLogList.findIndex(item => item.processLogId === prev.processLogId) === -1) deleteProcessLogList.push(prev.processLogId);
    })
    const BodyToPut = {
      ..._formData,
      workOrderCustomizedContent: JSON.stringify(_formData.workOrderCustomizedContent),
      processLogList: processLogList,
      deleteProcessLogList: deleteProcessLogList,
    }
    console.log('BodyToPut : ', BodyToPut);
    await workOrderLogApi.updateWorkOrderLog(updateContent.workOrderLogId, BodyToPut).then(response => {
      if (response === undefined) return;
      console.log('workOrderLogApi.updateWorkOrderLog : ', response);
      props.updateProcessLog(processLogList, response.data.processLogList);
    })
  }

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalHeaderContents>
                <ModalTitle>{_formData?.account?.accountName} | {_formData.workPlace}</ModalTitle>
                <button className="formButton" onClick={actCreateProcess}>공정추가</button>
              </ModalHeaderContents>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <ProcessForm processLogList={_processLogList} setProcessLogList={setProcessLogList} />
            </ModalMain>

            <ModalFooter>
              <ModalButton onClick={actUpdate}>저장</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}

    </>
  );
};

export default UpdateUriProcessLogModal;