import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { categoryApi } from 'api/apis/categoryApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import IconRightArrow from 'components/icons/IconRightArrow';

const InlineCategories = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector(state => state);

  /* ====================================================================== #2 */
  const [_reset, setReset] = useState(props.reset);

  const [_level1Categories, setLevel1Categories] = useState([]);
  const [_level2Categories, setLevel2Categories] = useState([]);
  const [_level3Categories, setLevel3Categories] = useState([]);
  const [_level4Categories, setLevel4Categories] = useState([]);
  const [_level5Categories, setLevel5Categories] = useState([]);
  const [_clickedCategory, setClickedCategory] = useState({});
  const [_clickedLevel1Category, setClickedLevel1Category] = useState({});
  const [_clickedLevel2Category, setClickedLevel2Category] = useState({});
  const [_clickedLevel3Category, setClickedLevel3Category] = useState({});
  const [_clickedLevel4Category, setClickedLevel4Category] = useState({});
  const [_clickedLevel5Category, setClickedLevel5Category] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    getCategoryList(1);
    return () => { };
  }, []);
  useEffect(() => { setReset(() => { return props.reset }) }, [props])
  useEffect(() => {
    setClickedCategory(() => { return {} });
    setClickedLevel1Category(() => { return {} });
    setClickedLevel2Category(() => { return {} });
    setClickedLevel3Category(() => { return {} });
    setClickedLevel4Category(() => { return {} });
    setClickedLevel5Category(() => { return {} });
    getCategoryList(1);
    return () => { }
  }, [_reset])
  useEffect(() => { console.log('_clickedCategory : ', _clickedCategory); }, [_clickedCategory])
  useEffect(() => { }, [_level1Categories, _level2Categories, _level3Categories, _level4Categories, _level5Categories])
  useEffect(() => { }, [_clickedLevel1Category, _clickedLevel2Category, _clickedLevel3Category, _clickedLevel4Category, _clickedLevel5Category])

  /* ====================================================================== #4 */
  const getCategoryList = async (level, categoryContent) => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      categoryLevel: level,
    };
    if (checkNullObject(categoryContent, false)) {
      BodyToPost.parentCategoryId = categoryContent.categoryId;
    }
    console.log(level, BodyToPost);

    await categoryApi.searchCategory(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('categoryApi.searchCategory : ', response);
      switch (level) {
        case 1:
          setLevel1Categories(() => { return response.data; });
          setLevel2Categories(() => { return []; });
          setLevel3Categories(() => { return []; });
          setLevel4Categories(() => { return []; });
          setLevel5Categories(() => { return []; });
          break;
        case 2:
          setLevel2Categories(() => { return response.data; });
          setLevel3Categories(() => { return []; });
          setLevel4Categories(() => { return []; });
          setLevel5Categories(() => { return []; });
          break;
        case 3:
          setLevel3Categories(() => { return response.data; });
          setLevel4Categories(() => { return []; });
          setLevel5Categories(() => { return []; });
          break;
        case 4:
          setLevel4Categories(() => { return response.data; });
          setLevel5Categories(() => { return []; });
          break;
        case 5:
          setLevel5Categories(() => { return response.data; });
          break;
        default: break;
      }
    });
  }

  /* ====================================================================== #5 */
  const handleCategoryEvent = (level, categoryId) => {
    let categoryIndex, categoryContent;
    switch (level) {
      case 1:
        categoryIndex = _level1Categories.findIndex(item => item.categoryId === Number(categoryId));
        categoryContent = _level1Categories[categoryIndex];
        if (!checkNullObject(categoryContent, false)) return;
        setClickedCategory(() => { return categoryContent });
        setClickedLevel1Category(() => { return categoryContent });
        setClickedLevel2Category(() => { return {} });
        setClickedLevel3Category(() => { return {} });
        setClickedLevel4Category(() => { return {} });
        setClickedLevel5Category(() => { return {} });
        getCategoryList(2, categoryContent)
        break;
      case 2:
        categoryIndex = _level2Categories.findIndex(item => item.categoryId === Number(categoryId));
        categoryContent = _level2Categories[categoryIndex];
        if (!checkNullObject(categoryContent, false)) return;
        setClickedCategory(() => { return categoryContent });
        setClickedLevel2Category(() => { return categoryContent });
        setClickedLevel3Category(() => { return {} });
        setClickedLevel4Category(() => { return {} });
        setClickedLevel5Category(() => { return {} });
        getCategoryList(3, categoryContent)
        break;
      case 3:
        categoryIndex = _level3Categories.findIndex(item => item.categoryId === Number(categoryId));
        categoryContent = _level3Categories[categoryIndex];
        if (!checkNullObject(categoryContent, false)) return;
        setClickedCategory(() => { return categoryContent });
        setClickedLevel3Category(() => { return categoryContent });
        setClickedLevel4Category(() => { return {} });
        setClickedLevel5Category(() => { return {} });
        getCategoryList(4, categoryContent)
        break;
      case 4:
        categoryIndex = _level4Categories.findIndex(item => item.categoryId === Number(categoryId));
        categoryContent = _level4Categories[categoryIndex];
        if (!checkNullObject(categoryContent, false)) return;
        setClickedCategory(() => { return categoryContent });
        setClickedLevel4Category(() => { return categoryContent });
        setClickedLevel5Category(() => { return {} });
        getCategoryList(5, categoryContent)
        break;
      case 5:
        categoryIndex = _level5Categories.findIndex(item => item.categoryId === Number(categoryId));
        categoryContent = _level1Categories[categoryIndex];
        if (!checkNullObject(categoryContent, false)) return;
        setClickedCategory(() => { return categoryContent });
        setClickedLevel5Category(() => { return categoryContent });
        break;

      default: return;
    }

    if (checkNullObject(categoryContent, false)) props.handlerCategory(categoryContent);
  }

  /* ====================================================================== #6 */

  return (
    <>
      <select className="SelectFamily"
        value={checkEmptyNull(_clickedLevel1Category.categoryId, '')}
        onChange={e => { e.preventDefault(); handleCategoryEvent(1, e.target.value); }}
      >
        <option value="">패밀리 1</option>
        {checkNullArray(_level1Categories, []).map((category, index) => {
          return (<option key={index + '_category_1'} value={category.categoryId}>{category.categoryName}</option>)
        })}
      </select>
      <IconRightArrow />
      <select className="SelectFamily"
        value={checkEmptyNull(_clickedLevel2Category.categoryId, '')}
        onChange={e => { e.preventDefault(); handleCategoryEvent(2, e.target.value); }}
      >
        <option value="">패밀리 2</option>
        {checkNullArray(_level2Categories, []).map((category, index) => {
          return (<option key={index + '_category_2'} value={category.categoryId}>{category.categoryName}</option>)
        })}
      </select>
      <IconRightArrow />
      <select className="SelectFamily"
        value={checkEmptyNull(_clickedLevel3Category.categoryId, '')}
        onChange={e => { e.preventDefault(); handleCategoryEvent(3, e.target.value); }}
      >
        <option value="">패밀리 3</option>
        {checkNullArray(_level3Categories, []).map((category, index) => {
          return (<option key={index + '_category_3'} value={category.categoryId}>{category.categoryName}</option>)
        })}
      </select>
      {(userReducer.company.companyId === 1 || userReducer.company.companyId === 12) ? <>
        <IconRightArrow />
        <select className="SelectFamily"
          value={checkEmptyNull(_clickedLevel4Category.categoryId, '')}
          onChange={e => { e.preventDefault(); handleCategoryEvent(4, e.target.value); }}
        >
          <option value="">패밀리 4</option>
          {checkNullArray(_level4Categories, []).map((category, index) => {
            return (<option key={index + '_category_4'} value={category.categoryId}>{category.categoryName}</option>)
          })}
        </select>
        <IconRightArrow />
        <select className="SelectFamily"
          value={checkEmptyNull(_clickedLevel5Category.categoryId, '')}
          onChange={e => { e.preventDefault(); handleCategoryEvent(5, e.target.value); }}
        >
          <option value="">패밀리 5</option>
          {checkNullArray(_level5Categories, []).map((category, index) => {
            return (<option key={index + '_category_5'} value={category.categoryId}>{category.categoryName}</option>)
          })}
        </select>
      </> : null}
    </>
  );
};

export default InlineCategories;