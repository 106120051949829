import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import styled from 'styled-components';

import { modalFileActions_setFileReset } from 'store/modules/actions/modal/modalFileActions';
import { workerReducer_setWorkOrderLogManagerIds, workerReducer_setWorkOrderLogWorkderIds } from 'store/modules/actions/common/workerActions';

import { attachFileApi } from 'api/apis/attachFileApi';
import { workOrderTypeApi } from 'api/apis/workOrderTypeApi';
import { workOrderApi } from 'api/apis/workOrderApi';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import CommaNum from 'components/format/CommaNum';
import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import SelectElementStockLogs from 'pages/stockHistory/elementStockLog/modal/SelectElementStockLogs';
import SelectFiles from 'pages/file/modal/SelectFiles';
import SelectOneAccount from 'pages/account/modal/SelectOneAccount';
import SelectOneEquipment from 'pages/equipment/modal/SelectOneEquipment';
import SelectProcesses from 'pages/process/modal/SelectProcesses';
import SelectUsers from 'pages/user/modal/SelectUsers';
import TableSection from 'components/layouts/table/TableSection';
import UpdateProcessRow from 'components/row/UpdateProcessRow';
import { DateFormat } from 'components/format/DateFormat';
import SetWorkOrderAlarmModal from 'components/alarm/modal/SetWorkOrderAlarmModal';
import SetProcessAlarmModal from 'components/alarm/modal/SetProcessAlarmModal';
import SelectWorkPlaceModal from 'pages/types/modal/SelectWorkPlaceModal';

const MainSection = styled.main`
  width: 100vw;
  .TableSection {max-height: 500px; overflow: auto;}

    .alarm {
    /* width: 100%; */
    &>div:first-child {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      width: 100%;

      .formButton {
        background-color: var(--ThirdBlue);
        border-radius: 5px;
        width: 150px;
      }
    }
  }
  .placeBox {
    &>div:first-child {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      width: 100%;

      & .formButton {
        background-color: var(--ThirdBlue);
        border-radius: 5px;
        width: 150px;
      }
    }
    &>div:nth-child(2) {
      column-gap: 5px;
      display: grid;
      grid-template-columns: auto 50px;
      width: 100%;

      & .formButton {
        background-color: var(--MainNavy);
        border-radius: 5px;
        height: 40px;
        width: 50px;
      }
    }

    .place {
      background-color: var(--ThirdBlue);
      color: var(--white);
      &::placeholder {color: var(--white);}
    }
  }

  .space-between {
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    
    & .w100 {width: 100%;}
    & .h30 {line-height: 30px;}

    & .btn-set {
      border: 1px solid var(--gray-300);
      box-shadow: unset;
      font-size: 15px;
      height: 35px;
      padding-inline: 20px;
    }
    & .formButton {
      background-color: var(--ThirdBlue);
      border-radius: 5px;

      &.w150 {width: 150px;}
    }
  }
`;

const DateInput = styled.input`
  border: 1px solid var(--gray-200);
  border-radius: 5px;
  box-sizing: border-box;
  height: 40px;
  padding: 5px;
  &:focus {border: 1px solid var(--gray-200);}
`;
const AccountInput = styled.input`
  background-color: var(--MainNavy);
  color: var(--white);
  &::placeholder {color: var(--white);}
`;
const DateCheckBox = styled.div`
  align-items: center;
  column-gap: 10px;
  display: flex;
  height: 100%;
  justify-content: center;
  /* width: 100%; */
  input {margin: unset;}
`;

const ContentList = styled.div`
  width: 100%;
`;
const ContentBox = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  height: 40px;
  justify-content: flex-start;
  margin: 5px auto;
  padding: 0px 15px;
  position: relative;
  width: 100%;
`;
const DeleteButton = styled.div`
  height: 15px;
  position: absolute;
  right: 15px;
  width: 15px;
  div {background-color: var(--white); cursor: pointer; height: 15px; width: 15px;}
`;

const LowStockList = styled.div`
  width: 100%;
`;
const LowStockBox = styled.div`
  align-items: center;
  background-color: var(--MainRed);
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  height: 40px;
  justify-content: flex-start;
  margin: 5px auto;
  padding: 0px 15px;
  position: relative;
  width: 100%;
`;

const InputElementStockLogsTable = styled.table`
  td, th {min-width: 200px; width: 200px; }
  td.eventCell {
    background-color: var(--MainNavy);
    padding: 5px;

    input {
      background-color: var(--white);
      border-radius: 0px;
      box-sizing: border-box;
      font-size: 1.2em;
      height: 100%;
      margin: 0px auto;
      min-height: 50px;
      padding: 10px 15px;
      text-align: right;
      width: 100%;
      &:disabled {background-color: var(--gray-200);}
    }
  }
`;
const DeleteLogButton = styled.div`
  height: 15px;
  margin: 0px auto;
  width: 15px;
  div {background-color: var(--MainRed); cursor: pointer; height: 15px; width: 15px;}
`;

const CreateWorkOrderLog = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { daerimReducer, pageReducer, userReducer, workerReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();
  const workOrderId = searchParams.get('workOrderId');

  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_formData, setFormData] = useState({
    workOrderTypeId: '',

    workOrderStatus: 'wait',

    workOrderName: '',
    workOrderCode: '',

    workPlace: '',
    detailedWorkPlace: '',

    message: '',
    remark: '',

    workOrderCustomizedContent: JSON.stringify({}),

    companyId: userReducer.company.companyId,
    userId: userReducer.user.id,

    workOrderScheduledStartDate: moment(new Date()).format('YYYY-MM-DD'),
    workOrderScheduledEndDate: null,

    amount: 1,

    processLogList: [],
  });

  const [_workOrderTypes, setWorkOrderTypes] = useState([]);

  const [_processList, setProcessList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());
  const [_processAmounts, setProcessAmounts] = useState({});
  const [_availableAmount, setAvailableAmount] = useState(0);
  const [_lowStockList, setLowStockList] = useState([]);
  const [_processElementList, setProcessElementList] = useState([]);

  const [_logEventContent, setLogEventContent] = useState({});
  const [_elementStockLogModalStatus, setElementStockLogModalStatus] = useState(false);

  const [_processModalStatus, setProcessModalStatus] = useState(false);

  const [_equipmentPos, setEquipmentPos] = useState();
  const [_equipmentModalStatus, setEquipmentModalStatus] = useState(false);

  const [_eventProcessLog, setEventProcessLog] = useState({});
  const [_workerModal, setWorkerModal] = useState();
  const [_workerModalStatus, setWorkerModalStatus] = useState(false);

  const [_account, setAccount] = useState({});
  const [_accountModalStatus, setAccountModalStatus] = useState(false);

  const [_fileList, setFileList] = useState([]);
  const [_fileModalStatus, setFileModalStatus] = useState(false);

  const [_workPlace, setWorkPlace] = useState({});
  const [_workPlaceModalStatus, setWorkPlaceModalStatus] = useState(false); // 작업위치 팝업

  const [_workOrderAlarm, setWorkOrderAlarm] = useState({});
  const [_workOrderAlarmList, setWorkOrderAlarmList] = useState([]);
  const [_processAlarmList, setProcessAlarmList] = useState([]);
  const [_alarmType, setAlarmType] = useState('');
  const [_alarmModal, setAlarmModal] = useState(false);

  const [_viewStatus, setViewStatus] = useState('simple');

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('104') === true || // 거래처
      thisItem.authority.startsWith('108') === true || // 설비
      thisItem.authority.startsWith('109') === true || // 파일
      thisItem.authority.startsWith('111') === true || // 작업정의
      thisItem.authority.startsWith('403') === true, // 작업지시
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    getWorkOrderTypes();
    getWorkOrderData();

    getFileList();

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      handleLowStocks(checkEmptyNull(_formData.amount, 1));
    }
    return () => { }
  }, [_processList]);

  useEffect(() => { }, [_viewStatus, _workPlace, workerReducer]);

  // 설비 선택
  useEffect(() => {
    if (_onload === 'loaded') {
      if (Object.keys(daerimReducer.equipment).length > 0) {
        const thisKey = daerimReducer.equipment.processKey;
        if (thisKey.includes('_') !== true) return;

        const thisId = Number(thisKey.split('_')[0]);
        const thisIndex = Number(thisKey.split('_')[1]);
        console.log(thisId, thisIndex, thisKey);

        setProcessList((prev) => {
          const prevData = [...prev];
          const newData = prevData[thisIndex];
          newData.equipmentId = daerimReducer.equipment.equipmentId;
          newData.equipmentName = daerimReducer.equipment.equipmentName;
          prevData.splice(thisIndex, 1, newData);
          return prevData;
        });
      }
    }
    return () => { };
  }, [daerimReducer.equipment]);

  /* ====================================================================== #4 */
  const getWorkOrderTypes = async () => {
    const BodyToPost = { companyId: userReducer.company.companyId };
    await workOrderTypeApi.searchWorkOrderType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderTypeApi.searchWorkOrderType : ', response);
      setWorkOrderTypes(() => { return response.data; });
    });
  };

  const getWorkOrderData = async () => {
    await workOrderApi.getWorkOrder(workOrderId).then((response) => {
      const processLogElementList = response.data.workOrderProcessList.map((thisItem) => thisItem.process.processElementList);
      const setProcessLogElementList = processLogElementList.flat();
      actCalMinAmount(setProcessLogElementList);

      const Processes = response.data.workOrderProcessList.map((processLog, index) => {
        const unitAmount = new BigNumber(checkEmptyNull(_formData.amount, 1));
        const processUnitAmount = new BigNumber(checkEmptyNull(processLog.count, 1));
        const totalAmount = unitAmount.multipliedBy(processUnitAmount);

        const returnData = {
          ...processLog.process,

          processNumber: checkEmptyNull(processLog.processNumber, index + 1),
          processingCount: checkEmptyNull(totalAmount.toNumber(), checkEmptyNull(_formData.amount, 1)),
          processAmount: checkEmptyNull(totalAmount.toNumber(), checkEmptyNull(_formData.amount, 1)),

          amount: checkEmptyNull(_formData.amount, 1),
          count: checkEmptyNull(processUnitAmount.toNumber(), 1),

          target: checkEmptyNull(processLog.target, false),

          managers: [],
          workers: [],

          taskLogList: [],

          processScheduledStartDate: checkEmptyNull(processLog.processScheduledStartDate, ''),

          place: checkNullObject(processLog.place, {}),

          workAlarmSetting: checkNullArray(processLog.workAlarmSetting, []),
        };
        return returnData;
      });
      Processes.sort((a, b) => { return a.processNumber - b.processNumber });
      setProcessList(() => { return Processes });
      const processAlarmList = [];
      checkNullArray(response.data.processLogList, []).forEach((processLog) => {
        checkNullArray(processLog?.workAlarmSetting, []).forEach(alarm => {
          const returnData = { ...alarm, process: processLog };
          processAlarmList.push(returnData);
        })
      })
      setProcessAlarmList(() => { return processAlarmList });

      setFormData({
        ..._formData,

        workOrderTypeId: response.data.workOrderTypeId,
        workOrderTypeName: response.data.workOrderTypeName,
        workOrderCode: response.data.workOrderCode,
        workOrderName: response.data.workOrderName,
        workPlace: response.data.workPlace,
        remark: response.data.remark,

        managers: [],
        workers: [],
      });

      if (checkNullObject(response.data.account, false)) setAccount(() => { return response.data.account; });
      if (checkNullObject(response.data.place, false)) setWorkPlace(() => { return response.data.place; });
      if (checkNullArray(response.data.workAlarmSetting, false)) setWorkOrderAlarmList(() => { return response.data.workAlarmSetting; });

      handleLowStocks(checkEmptyNull(_formData.amount, 1));
    });
  };

  const getFileList = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      workOrderId: workOrderId,
    };
    await attachFileApi.searchAttachFile('', BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('attachFileApi.searchAttachFile : ', response);

      setFileList(() => {
        const fileList = response.data.content.map((thisItem) => {
          let returnData = thisItem;
          if (checkNullObject(thisItem.fileData, false)) returnData = { ...thisItem, ...thisItem.fileData };
          return returnData;
        });
        return fileList;
      });
    });
  };

  /* ====================================================================== #5 */
  const handleChangeEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value }; });
  };

  const actDeleteFile = (e, index, file) => {
    setFileList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);
      return prevData;
    });
  };

  const handleCheckedItem = (index, process, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(process);
    setCheckedItem(() => { return checkedItem; });
    setProcessList((prev) => {
      const newData = prev.map((thisItem, prevIndex) => {
        const returnData = { ...thisItem };
        delete returnData.target;
        if (isChecked && thisItem.processId === process.processId && prevIndex === index) returnData.target = true;
        return returnData;
      });
      return newData;
    });
  };

  /* 생산가능수량 계산 */
  // elementStock - scheduledInputStock
  const actCalMinAmount = (elementList) => {
    const calMinAmount = elementList.map((element) => {
      // 1. processElementType, processLogElementType === Put
      if (!checkNullObject(element, false) || checkEmptyNull(element.processLogElementType, checkEmptyNull(element.processElementType, '')) !== 'Put') return null;
      // 2. 현재고 - 투입예정수량 -> 실재고
      const realAmount = BigNumber(checkEmptyNull(element.elementStock, 0)).minus(BigNumber(checkEmptyNull(element.scheduledInputStock, 0))).toNumber();
      // 3. 투입수량: processAmount 1개 기준
      const putAmount = BigNumber(1).multipliedBy(BigNumber(checkEmptyNull(element.amount, 0))).toNumber();
      // 4. 실재고 / 투입수량 : 총 몇 번 할 수 있는지? -> processAmount 최댓값
      const result = BigNumber(isNaN(realAmount) ? 0 : realAmount).dividedBy(BigNumber(isNaN(putAmount) ? 0 : putAmount)).toNumber();
      return result;
    });
    // 5. processAmount 중 최솟값만큼 생산가능함
    const minAmount = Math.min.apply(null, calMinAmount.filter((item) => item !== null));
    setAvailableAmount(() => {
      return !isNaN(Math.floor(minAmount)) && Math.floor(minAmount) !== Infinity && Math.floor(minAmount) > 0 ? Math.floor(minAmount) : 0;
    });
  };

  /* 생산목표수량 변경 */
  const handleTotalAmountEvent = (e) => {
    const { name, value } = e.target;

    let eventValue = value.replace(/[^\d.-]/g, ''); // 바뀐 생산목표수량
    if (eventValue.length > 15) return;
    if (!checkEmptyNull(eventValue, false)) eventValue = 0;
    if (isNaN(eventValue) || eventValue === '0') eventValue = 0;
    eventValue = BigNumber(eventValue).toNumber();
    console.log('eventValue : ', eventValue);
    setFormData((prev) => { return { ...prev, amount: eventValue } });

    const returnList = [];
    const processLogList = [..._processList];
    processLogList.forEach(processLog => {
      const returnData = {
        ...processLog,
        amount: eventValue,
        processAmount: eventValue,
        processingCount: eventValue,
      };

      // 공정물품 리스트 설정 - 재고연동 리스트 초기화
      const returnElementList = (elementList) => {
        const eventElementList = checkNullArray(processLog[elementList], []).map(element => {
          const returnElementData = { ...element };
          returnElementData.inputElementStockLogs = [];
          return returnElementData;
        })
        return returnData[elementList] = eventElementList;
      }
      if (checkNullArray(processLog.processLogElementList, false)) returnElementList('processLogElementList')
      else if (checkNullArray(checkNullArray(processLog.processElementList, false))) returnElementList('processElementList')
      returnList.push(returnData);
    })
    console.log('returnList : ', returnList);

    setProcessList(() => { return returnList });
    handleLowStocks(checkEmptyNull(eventValue, 1));
  };

  /* 재고 부족 리스트 */
  const handleLowStocks = (num) => {
    const processElementList = _processList.map((process) => checkNullArray(process.processLogElementList, false) ? checkNullArray(process.processLogElementList, []) : checkNullArray(process.processElementList, []));
    if (processElementList.length < 1) return;
    const flatProcessElementList = processElementList.flat();
    const filterPutProcessElementList = flatProcessElementList.map((element) => {
      if (checkEmptyNull(element.processLogElementType, false) && element.processLogElementType === 'Put') {
        return element;
      } else if (checkEmptyNull(element.processElementType, false) && element.processElementType === 'Put') {
        return element;
      } else {
        return null;
      }
    });
    const filterProcessElementList = filterPutProcessElementList.filter((element) => element !== null);
    const mapProcessElementList = filterProcessElementList.map((element) => {
      const elementData = (() => {
        if (checkNullObject(element.element, false)) return { ...element.element, ...element };
        else return { ...element };
      })();
      return elementData;
    });
    console.log('mapProcessElementList : ', mapProcessElementList);
    setProcessElementList(() => { return mapProcessElementList });

    const checkNum = (num) => { return isNaN(num) ? 0 : num };
    const lowStockList = mapProcessElementList.map((element) => {
      const calStock = BigNumber(checkEmptyNull(element.elementStock, 0)).minus(BigNumber(checkEmptyNull(element.scheduledInputStock, 0))).toNumber(); // 실재고
      const calAmount = BigNumber(checkEmptyNull(element.amount, 0)).multipliedBy(BigNumber(checkEmptyNull(num, 0))).toNumber(); // 재고 * 생산목표수량
      const calResult = BigNumber(checkNum(calStock)).minus(BigNumber(checkNum(calAmount))).toNumber(); // 실재고 - (재고 * 생산목표수량)
      if (calResult < 0) {
        return { ...element, lowAmount: Math.abs(calResult) };
      } else {
        return null;
      }
    });
    const filterLowStockList = lowStockList.filter((element) => element !== null);
    setLowStockList(() => { return filterLowStockList; });
  };

  /* 공정 순서 변경 */
  const handleNumberEvent = (e, index, process) => {
    setProcessList((prev) => {
      const prevData = [...prev];
      const newData = { ...process, processNumber: BigNumber(e.target.value).toNumber() };
      prevData.splice(index, 1);
      prevData.splice(BigNumber(e.target.value).minus(1).toNumber(), 0, newData);
      const setPrevData = prevData.map((thisItem, index) => {
        return { ...thisItem, processNumber: index + 1 };
      });
      return setPrevData;
    });
  };

  /* 묶음처리횟수 변경 */
  const handleProcessCountingEvent = (e) => {
    const processKey = e.target.dataset.key;
    if (processKey.includes('_') !== true) return;
    const id = Number(processKey.split('_')[0]);
    const index = Number(processKey.split('_')[1]);
    const type = processKey.split('_')[2];

    const { value } = e.target;

    const copyList = [..._processList];

    function checkIndex(item, itemIndex) {
      if (type === 'prev') {
        if (item.processLogId === id && itemIndex === index) return true;
      }
      if (type === 'new') {
        if (item.processId === id && itemIndex === index) return true;
      }
    }
    const processLog = copyList.find(checkIndex);
    const processLogIndex = copyList.indexOf(processLog);
    processLog.processingCount = Number(value);
    copyList.splice(processLogIndex, 1, processLog);
    setProcessList(copyList);
  };

  /* 재고연동 선택 모달 */
  const actTrackingElementStockLog = (element, index, process) => {
    setLogEventContent(() => {
      return { element: element, index: index, process: process }
    })
    setTimeout(setElementStockLogModalStatus(true), 1000);
  }

  /* 최솟값/최댓값 제한 */
  const setMinMaxNum = (value, min, max) => {
    let returnNum = value;
    if (value < min) returnNum = min;
    if (value > max) returnNum = max;
    return returnNum;
  };

  /* 재고연동 투입예정수량 */
  const handleInputElementStockLogs = (e, index, elementStockLog) => {
    const { name, value } = e.currentTarget;

    const processIndex = index.split('_')[0];
    const elementIndex = index.split('_')[1];
    const logIndex = index.split('_')[2];

    let eventValue = Number(value.replaceAll(',', ''));
    if (eventValue.length > 15) return;
    if (isNaN(eventValue)) return;

    const processElementIndex = _processElementList.findIndex(element => element.elementId === elementStockLog.elementId);
    if (processElementIndex === -1) return;
    const processElement = _processElementList[processElementIndex];
    const putAmount = BigNumber(checkEmptyNull(processElement.amount, 0)).multipliedBy(BigNumber(checkEmptyNull(_formData.amount, 0))).toNumber();
    console.log(checkEmptyNull(elementStockLog.availableStock, 0), checkEmptyNull(putAmount, 0));
    if (checkEmptyNull(elementStockLog.availableStock, 0) > checkEmptyNull(putAmount, 0)) {
      eventValue = setMinMaxNum(eventValue, 0, checkEmptyNull(putAmount, 0));
    } else {
      eventValue = setMinMaxNum(eventValue, 0, checkEmptyNull(elementStockLog.availableStock, 0));
    }
    const elementStockLogData = { ...elementStockLog, [name]: eventValue }
    console.log('elementStockLogData : ', elementStockLogData);

    setProcessList(prev => {
      const processList = [...prev];
      const processData = processList[(processIndex)];
      const processElementList = [...checkNullArray(processData.processElementList, checkNullArray(processData.processLogElementList, []))]
      const processElementData = processElementList[elementIndex];
      const inputElementStockLogs = [...processElementData.inputElementStockLogs];
      inputElementStockLogs.splice(logIndex, 1, elementStockLogData);
      processElementData.inputElementStockLogs = inputElementStockLogs;
      processElementList.splice(elementIndex, 1, processElementData);
      if (!checkEmptyNull(processData.processLogId, false)) processData.processElementList = processElementList;
      else processData.processLogElementList = processElementList;
      processList.splice(processIndex, 1, processData);
      return processList;
    })
  }

  /* 재고연동 삭제 */
  const deleteInputElementStockLogs = (e, index, elementStockLog) => {
    const processIndex = index.split('_')[0];
    const elementIndex = index.split('_')[1];
    const logIndex = index.split('_')[2];

    setProcessList(prev => {
      const processList = [...prev];
      const processData = processList[(processIndex)];
      const processElementList = [...checkNullArray(processData.processElementList, checkNullArray(processData.processLogElementList, []))]
      const processElementData = processElementList[elementIndex];
      const inputElementStockLogs = [...processElementData.inputElementStockLogs];
      inputElementStockLogs.splice(logIndex, 1);
      processElementData.inputElementStockLogs = inputElementStockLogs;
      processElementList.splice(elementIndex, 1, processElementData);
      processData.processElementList = processElementList;
      processList.splice(processIndex, 1, processData);
      return processList;
    })
  }

  /* 설비 선택 모달 */
  const selectEquipmentEvent = (equipmentPos) => {
    setEquipmentPos(() => { return equipmentPos });
    setTimeout(setEquipmentModalStatus(true), 1000);
  };

  /* 책임자/작업자 선택 */
  const handleEventProcessLog = (index, processLog) => {
    setEventProcessLog(() => { return { [index]: processLog }; });
  };

  /* 책임자/작업자 삭제 */
  const actDeleteWorkers = (type, index, workerIndex) => {
    switch (type) {
      case 'workOrderLogManagerIds':
        const workOrderLogManagerIds = [...workerReducer.workOrderLogManagerIds];
        workOrderLogManagerIds.splice(index, 1);
        dispatch(workerReducer_setWorkOrderLogManagerIds(workOrderLogManagerIds));
        break;

      case 'workOrderLogWorkerIds':
        const workOrderLogWorkerIds = [...workerReducer.workOrderLogWorkerIds];
        workOrderLogWorkerIds.splice(index, 1);
        dispatch(workerReducer_setWorkOrderLogWorkderIds(workOrderLogWorkerIds));
        break;

      case 'processLogManagerIds':
        const processLogManager = { ..._processList[index] };
        const managers = checkNullArray(processLogManager.managers, [])
        if (managers.length > 0) managers.splice(workerIndex, 1);
        const newData_processLogManagerIds = { ...processLogManager, managers: managers };
        setProcessList((prev) => {
          const returnData = [...prev];
          returnData.splice(index, 1, newData_processLogManagerIds);
          return returnData;
        });
        break;

      case 'processLogWorkerIds':
        const processLogWorker = { ..._processList[index] };
        const workers = checkNullArray(processLogWorker.workers, []);
        if (workers.length > 0) workers.splice(workerIndex, 1);
        const newData_processLogWorkerIds = { ...processLogWorker, workers: workers };
        setProcessList((prev) => {
          const returnData = [...prev];
          returnData.splice(index, 1, newData_processLogWorkerIds);
          return returnData;
        });
        break;

      default: return;
    }
  };

  /* 공정 삭제 */
  const actDeleteProcess = (index, process) => {
    setLowStockList(() => { return []; })

    setProcessList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);
      const returnData = prevData.map((processLog, index) => {
        return { ...processLog, processNumber: index + 1 };
      });
      const processLogElementList = prevData.map((thisItem) => checkNullArray(thisItem.processLogElementList, false) ? checkNullArray(thisItem.processLogElementList, []) : checkNullArray(thisItem.processElementList, []));
      const flatProcessLogElementList = processLogElementList.flat();
      actCalMinAmount(flatProcessLogElementList);
      return returnData;
    });
  };

  // 공정 알림 삭제
  const handleAlarm = (type) => {
    setAlarmType(() => { return type; });
    setTimeout(setAlarmModal(() => { return true; }), 1000);
  }
  const handleWorkAlarm = (workAlarm, index) => {
    setWorkOrderAlarmList((prev) => {
      const returnData = [...prev];
      returnData.splice(index, 1);
      return returnData;
    })
  }
  const handleProcessAlarm = (processAlarm, index) => {
    setProcessAlarmList((prev) => {
      const returnData = [...prev];
      returnData.splice(index, 1);
      return returnData;
    })
  }

  const actCreate = async (e) => {
    e.preventDefault();

    if (_formData.amount <= 0) { return alert('생산목표수량을 확인해주세요.') }
    if (!checkEmptyNull(_formData.workOrderCode, false)) { return alert('작업코드를 입력해주세요.') }
    if (!checkEmptyNull(_formData.workOrderName, false)) { return alert('작업이름을 입력해주세요.') }

    // 목표공정 체크
    // const lastProcessKey = document.querySelector('input[name="processes"]:checked');
    // if (lastProcessKey === undefined || lastProcessKey === null) return alert('목표공정을 선택해주세요.');

    const emptyNumbers = _processList.filter((thisItem) => !checkEmptyNull(thisItem.processNumber, false));
    if (_processList.length > 0 && emptyNumbers.length > 0) return alert('공정순서를 입력해주세요.');

    const processNumberList = _processList.map((thisItem) => thisItem.processNumber);
    const setProcessNumberList = new Set(processNumberList);
    if (processNumberList.length !== setProcessNumberList.size) { return alert('중복된 공정 순서가 존재합니다.') }

    const sortProcessList = _processList.sort((a, b) => { return a.processNumber - b.processNumber });

    const alarmStatus = false;
    const BodyToPost = {
      ..._formData,

      companyId: userReducer.company.companyId,

      managerIds: checkNullArray(workerReducer.workOrderLogManagerIds, []).map((worker) => worker.id),
      workerIds: checkNullArray(workerReducer.workOrderLogWorkerIds, []).map((worker) => worker.id),

      amount: checkEmptyNull(_formData.amount, false) ? Number(_formData.amount) : 0,

      processLogList: sortProcessList.map((processLog, index) => {
        // 공정완료제품 체크
        const checkOutItem = checkEmptyNull(processLog.processLogId, false)
          ? checkNullArray(processLog.processLogElementList, []).filter((element) => element.processLogElementType === 'Out' || element.processLogElementType === 'Make')
          : checkNullArray(processLog.processElementList, []).filter((element) => element.processElementType === 'Out' || element.processElementType === 'Make');

        const processLogElementList = [];
        if (checkEmptyNull(processLog.processLogId, false)) {
          // processLogElementList 설정
          checkNullArray(processLog.processLogElementList, []).forEach(processLogElement => {
            const processElementData = {
              ...processLogElement,
              processLogElementType: processLogElement.processLogElementType,
              processLogElementId: processLogElement.processLogElementId,
              elementId: processLogElement.elementId,
              amount: checkEmptyNull(processLogElement.amount, 0),
              inputElementStockLogs: [],
              deleteInputElementIds: []
            }
            checkNullArray(processLogElement.inputElementStockLogs, []).forEach(elementStockLog => {
              const elementStockLogData = {
                elementStockLogId: elementStockLog.elementStockLogId,
                scheduledInputStock: checkEmptyNull(elementStockLog.scheduledInputStock, 0),
                inputElementStockLogId: elementStockLog?.inputElementStockLogId,
              }
              processElementData.inputElementStockLogs.push(elementStockLogData);
            })
            processLogElementList.push(processElementData);
          })
        } else {
          processLog.processElementList.forEach(processElement => {
            // processElementList 설정
            const processElementData = {
              ...processElement,
              processLogElementType: processElement.processElementType,
              elementId: processElement.elementId,
              amount: checkEmptyNull(processElement.amount, 0),
              inputElementStockLogs: [],
              deleteInputElementIds: []
            }
            checkNullArray(processElement.inputElementStockLogs, []).forEach(elementStockLog => {
              const elementStockLogData = {
                elementStockLogId: elementStockLog.elementStockLogId,
                scheduledInputStock: checkEmptyNull(elementStockLog.scheduledInputStock, 0),
              }
              processElementData.inputElementStockLogs.push(elementStockLogData);
            })
            processLogElementList.push(processElementData);
          })
        }

        // 알림
        const workAlarmSettingList = checkNullArray(_processAlarmList, []).map(alarm => {
          if (!checkEmptyNull(alarm.process.processScheduledStartDate, false)) alarmStatus = true;
          if (alarm.process.processLogId === processLog.processLogId) {
            const returnData = {
              id: alarm.id,
              beforeDay: alarm.beforeDay,
              alarmHour: alarm.alarmHour,
              alarmMinute: alarm.alarmMinute,
            };
            return returnData;
          } else if (alarm.process.processId === processLog.processId) {
            const returnData = {
              beforeDay: alarm.beforeDay,
              alarmHour: alarm.alarmHour,
              alarmMinute: alarm.alarmMinute,
            };
            return returnData;
          } else {
            return null;
          }
        });
        const filterWorkAlarmSettingList = checkNullArray(workAlarmSettingList, []).filter(item => item !== null);

        const returnData = {
          existProcessOutElement: checkOutItem.length > 0 ? true : false,
          processingType: checkEmptyNull(processLog.processingType, 'auto'),

          target: false,

          processTypeName: processLog.processTypeName,
          processId: processLog.processId,
          processCode: processLog.processCode,
          processName: processLog.processName,

          processNumber: checkEmptyNull(processLog.processNumber, index + 1),
          processingCount: checkEmptyNull(processLog.processingCount, checkEmptyNull(_formData.amount, 1)),
          processAmount: checkEmptyNull(processLog.processAmount, checkEmptyNull(_formData.amount, 1)),

          workOrderProcessCount: checkEmptyNull(processLog.count, 1), // 기준 공정횟수

          processCustomizedContent: JSON.stringify({}),

          equipmentId: checkEmptyNull(processLog.equipmentId, null), // 설비
          userId: checkEmptyNull(processLog.userId, null), // 작업자

          managerIds: checkNullArray(processLog.managers, false) ? processLog.managers.map((worker) => worker.id) : [],
          workerIds: checkNullArray(processLog.workers, false) ? processLog.workers.map((worker) => worker.id) : [],

          processLogElementList: processLogElementList,
          deleteProcessLogElementIds: [],

          taskLogList: [],
          deleteTaskLogIds: [],

          workAlarmSettingList: filterWorkAlarmSettingList,
          deleteWorkAlarmSettingIds: [],

          processScheduledStartDate: DateFormat(checkEmptyNull(processLog.processScheduledStartDate, '')),

          place: checkNullObject(processLog.place, {}),
        };
        if (index === sortProcessList.length - 1) returnData.target = true;
        else returnData.target = false;

        if (checkNullObject(processLog.place, false)) returnData.placeId = processLog.place.stockPlaceId;

        return returnData;
      }),
    };
    BodyToPost.workAlarmSettingList = _workOrderAlarmList;
    if (checkNullObject(_account, false)) BodyToPost.accountId = _account.accountId;
    if (checkNullObject(_workPlace, false)) BodyToPost.placeId = _workPlace.stockPlaceId;

    console.log('BodyToPost : ', BodyToPost, JSON.stringify(BodyToPost));

    if (alarmStatus) return alert('공정 작업 시작일을 확인해주세요.')

    await workOrderLogApi.createWorkOrderLog(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.createWorkOrderLog : ', response);

      if (_fileList.length > 0) {
        _fileList.forEach(async (file) => {
          const BodyToFilePost = {
            companyId: userReducer.company.companyId,
            fileDataId: file.fileDataId,
            workOrderLogId: response.data.workOrderLogId,
          };
          console.log('BodyToFilePost : ', BodyToFilePost);
          await attachFileApi.createAttachFile(BodyToFilePost).then((response) => {
            if (response === undefined) return;
            console.log('attachFileApi.createAttachFile : ', response);
          });
        });
      }

      response.data.processLogList.forEach(async (processLog, index) => {
        const BodyToSearch = {
          companyId: userReducer.company.companyId,
          processId: BodyToPost.processLogList[index].processId,
        };
        console.log('BodyToSearch : ', BodyToSearch);
        if (!checkEmptyNull(BodyToSearch.processId, false)) return;

        await attachFileApi.searchAttachFile('', BodyToSearch).then((searchResponse) => {
          if (searchResponse === undefined) return;
          console.log('attachFileApi.searchAttachFile : ', searchResponse);
          searchResponse.data.content.forEach(async (file) => {
            const BodyToFile = {
              companyId: userReducer.company.companyId,
              fileDataId: file.fileDataId,
              processLogId: processLog.processLogId,
            };
            console.log('BodyToFile : ', BodyToFile);
            await attachFileApi.createAttachFile(BodyToFile).then((response) => {
              if (response === undefined) return;
              console.log('attachFileApi.createAttachFile : ', response);
            });
          });
        });
      });

      const menuName = userReducer.userMenu.find((thisItem) => { if (thisItem.pageCode === '403') return true; else return false; })?.pageName
      alert(`${menuName}를 추가했습니다.`);
      setTimeout(navigate(pageReducer.currentPage, { replace: true }), 1000);
    });
  };

  /* ====================================================================== #6 */

  return (
    <Grid2Body contents={
      <>
        <MainSection>
          <NavBar title={<NavTitle menuCode={'403'} />} nav={''} />

          <Form
            title={
              <>
                <NavTitle menuCode={'403'} />추가<br />
                <div style={{ columnGap: '10px', color: 'var(--MainBlue)', display: 'flex', fontSize: '0.6em', justifyContent: 'flex-start', lineHeight: '30px', marginTop: '20px', width: '100%' }}>
                  <h4>생산가능수량: </h4><CommaNum displayType={'text'} num={_availableAmount} />
                </div>
                <br />
              </>
            }
            buttons={
              <>
                <button className="formButton" onClick={actCreate}>저장</button>
                <button className="formButton cancle"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(pageReducer.currentPage, { replace: true });
                  }}
                >
                  취소
                </button>
              </>
            }
            forms={
              <>
                <div className="formBox w100">
                  <div className='space-between h30 w100'>
                    <h4>공정</h4>
                    <div className='space-between h30'>
                      <button className="btn-set"
                        onClick={(e) => {
                          e.preventDefault();
                          setViewStatus((prev) => { return prev === 'simple' ? 'detail' : 'simple' });
                        }}
                      >
                        {_viewStatus === 'simple' ? '공정 상세보기' : '공정 요약보기'}
                      </button>
                      <div className="formButton w150" onClick={() => { setTimeout(setProcessModalStatus(true), 1000); }}>공정 선택</div>
                    </div>
                  </div>
                  <TableSection content={
                    <table>
                      <thead>
                        <tr style={{ border: 'none' }}>
                          <th rowSpan={2} style={{ minWidth: '50px', width: '50px' }}></th>
                          {/* <th rowSpan={2} style={{ minWidth: '80px', width: '80px' }}>목표<br />공정</th> */}
                          <th rowSpan={2} style={{ minWidth: '80px', width: '80px' }}>순서</th>
                          {/* <th rowSpan={2}>알림</th> */}
                          <th rowSpan={2}>공정유형</th>
                          <th rowSpan={2}>공정코드</th>
                          <th rowSpan={2}>공정이름</th>
                          <th rowSpan={2}>작업<br />시작일</th>
                          <th rowSpan={2}>작업위치</th>
                          {(_authority.indexOf('108-1') !== -1 || _authority.indexOf('108-2') !== -1) ? (<th rowSpan={2}>설비</th>) : null}
                          {_viewStatus === 'detail' ? (<th rowSpan={2}>비고</th>) : null}
                          {/* <th rowSpan={2}>책임자</th>
                          <th rowSpan={2}>작업자</th>
                          <th rowSpan={2}>묶음처리횟수</th>
                          <th rowSpan={2}>클릭횟수</th> */}
                          <th colSpan={_viewStatus === 'detail' ? 4 : 2} style={{ backgroundColor: 'var(--ThirdBlue)' }}>공정완료제품</th>
                          <th colSpan={_viewStatus === 'detail' ? 7 : 3} style={{ backgroundColor: 'var(--SeconYellow)' }}>투입 자재/반제품</th>
                        </tr>
                        <tr>
                          {_viewStatus === 'detail' ? (
                            <>
                              <th>물품이름</th>
                              <th>물품코드</th>
                              <th>목표<br />생산량</th>
                              <th>현재고</th>
                            </>
                          ) : (
                            <>
                              <th>물품정보</th>
                              <th>목표<br />생산량</th>
                            </>
                          )}

                          {_viewStatus === 'detail' ? (
                            <>
                              <th>물품이름</th>
                              <th>물품코드</th>
                              <th>단위<br />투입량</th>
                              <th>총<br />투입량</th>
                              <th>투입<br />예정수량</th>
                              <th>현재고</th>
                              <th></th>
                            </>
                          ) : (
                            <>
                              <th>물품정보</th>
                              <th>총<br />투입량</th>
                              <th></th>
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {_processList.map((thisItem, index) => {
                          return (
                            <UpdateProcessRow
                              key={index + '_updateProcessRow'}
                              authority={_authority}
                              thisIndex={index}
                              content={thisItem}
                              processLength={_processList.length}
                              viewStatus={_viewStatus}
                              actDeleteWorkers={actDeleteWorkers}
                              actTrackingElementStockLog={actTrackingElementStockLog}
                              delEvent={actDeleteProcess}
                              handleCheckedItem={handleCheckedItem}
                              handleEventProcessLog={handleEventProcessLog}
                              handleNumberEvent={handleNumberEvent}
                              handleProcessCountingEvent={handleProcessCountingEvent}
                              selectEquipmentEvent={selectEquipmentEvent}
                              setWorkerModal={setWorkerModal}
                              setWorkerModalStatus={setWorkerModalStatus}
                              setProcessList={setProcessList}
                              setProcessAlarmList={setProcessAlarmList}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  }
                  />
                </div>

                {(() => {
                  const returnData = [];
                  _processList.forEach((process, index) => {
                    checkNullArray(process.processElementList, checkNullArray(process.processLogElementList, [])).forEach((processElement, elementIndex) => {
                      checkNullArray(processElement.inputElementStockLogs, []).forEach((elementStockLog, logIndex) => {
                        const elementStockLogData = {
                          ...checkNullObject(elementStockLog.elementStockLog, false) ? elementStockLog.elementStockLog : {},
                          ...elementStockLog,
                        }
                        returnData.push(
                          <tr key={`${index}_${elementIndex}_${logIndex}_inputElementStockLogs`}>
                            <td style={{ minWidth: '50px', width: '50px' }}>
                              <DeleteLogButton onClick={(e) => { deleteInputElementStockLogs(e, `${index}_${elementIndex}_${logIndex}`, elementStockLogData) }}><CloseButtonIcon /></DeleteLogButton>
                            </td>
                            <td className='eventCell'>
                              <input
                                type="text"
                                name='scheduledInputStock'
                                value={checkEmptyNull(elementStockLogData.scheduledInputStock, 0)}
                                onInput={(e) => { handleInputElementStockLogs(e, `${index}_${elementIndex}_${logIndex}`, elementStockLogData); }}
                              />
                            </td>
                            <td style={{ minWidth: '70px', width: '70px' }}>{checkEmptyNull(elementStockLogData.availableStock, 0).toLocaleString()}</td>
                            <td>{DateTimeFormat(checkEmptyNull(elementStockLogData.createdDate, ''))}</td>
                            <td>{checkNullObject(processElement.element, false) ? checkEmptyNull(processElement.element.elementName, '') : checkEmptyNull(processElement.elementName, '')}</td>
                            <td>{checkNullObject(processElement.element, false) ? checkEmptyNull(processElement.element.elementCode, '') : checkEmptyNull(processElement.elementCode, '')}</td>
                            <td>{checkEmptyNull(elementStockLogData.checkedDate, false) ? DateTimeFormat(elementStockLogData.checkedDate) : ''}</td>
                            <td>{checkEmptyNull(elementStockLogData.stockPlaceName, '')}</td>
                            <td>{elementStockLogData.note}</td>
                            <td>{checkEmptyNull(elementStockLogData.userName, '')}</td>
                          </tr>
                        )
                      })
                    })
                  })
                  if (returnData.length > 0) {
                    return (
                      <div className='formBox w100'>
                        <h4>
                          재고 연동
                          <span style={{ display: 'inline-block', fontSize: '0.9em', fontWeight: 'lighter', marginLeft: '10px' }}>
                            ( 공정 - 투입 자재/반제품 각 물품의 <b>[ 재고연동 ]</b> 버튼을 클릭해서 연동할 재고를 선택해주세요.
                            연동된 재고가 없을 시. 유통기한을 기준으로 자동 선입선출됩니다. )
                          </span>
                        </h4>

                        <TableSection content={
                          <InputElementStockLogsTable>
                            <thead>
                              <tr>
                                <th style={{ minWidth: '50px', width: '50px' }}></th>
                                <th>투입예정수량</th>
                                <th style={{ minWidth: '70px', width: '70px' }}>가용<br />수량</th>
                                <th>입력시각</th>
                                <th>물품이름</th>
                                <th>물품코드</th>
                                <th>유통기한</th>
                                <th>재고장소</th>
                                <th>비고</th>
                                <th>작업자</th>
                              </tr>
                            </thead>
                            <tbody>
                              {returnData}
                            </tbody>
                          </InputElementStockLogsTable>
                        } />
                      </div>
                    )
                  } else {
                    return null;
                  }
                })()}

                <div className="formBox w100">
                  <h4>
                    생산목표수량
                    <span style={{ color: 'red', display: 'inline', fontSize: '0.9em', fontWeight: '600', marginLeft: '10px' }}>
                      ( 생산목표수량 변경 시, 재고 연동 목록이 초기화됩니다. )
                    </span>
                  </h4>
                  <input
                    type="text"
                    name="amount"
                    placeholder="* 생산목표수량..."
                    data-required="required"
                    value={_formData.amount}
                    onInput={handleTotalAmountEvent}
                  />
                  <LowStockList>
                    {_lowStockList.map((element, index) => {
                      return (
                        <LowStockBox key={index + '_lowStocks'}>
                          {`${element.elementName} [ ${element.elementCode} ] : ${isNaN(element.lowAmount) ? '' : element.lowAmount.toLocaleString()} ${element.elementUnit} 부족`}
                        </LowStockBox>
                      );
                    })}
                  </LowStockList>
                </div>

                <div className="formBox">
                  <h4>작업유형</h4>
                  <select
                    name="workOrderType"
                    value={_formData.workOrderTypeId || ''}
                    onChange={(e) => { setFormData((prev) => { return { ...prev, workOrderTypeId: e.target.value }; }); }}
                  >
                    <option value="">작업유형</option>
                    {_workOrderTypes.map((thisType) => {
                      return (<option key={thisType.workOrderTypeId} value={thisType.workOrderTypeId}>{thisType.workOrderTypeName}</option>);
                    })}
                  </select>
                </div>

                <div className="formBox">
                  <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                    <h4>작업코드</h4>
                    <DateCheckBox>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked) setFormData((prev) => { return { ...prev, workOrderCode: _formData.workOrderCode + `#${moment().format('YYYY-MM-DD')}` }; });
                          else setFormData((prev) => { return { ...prev, workOrderCode: _formData.workOrderCode.replace(`#${moment().format('YYYY-MM-DD')}`, '') }; });
                        }}
                      />
                      날짜추가
                    </DateCheckBox>
                  </div>
                  <input
                    type="data"
                    name="workOrderCode"
                    placeholder="* 작업코드..."
                    data-required="required"
                    value={_formData.workOrderCode}
                    onInput={handleChangeEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>작업이름</h4>
                  <input
                    type="text"
                    name="workOrderName"
                    placeholder="* 작업이름..."
                    data-required="required"
                    value={_formData.workOrderName}
                    onInput={handleChangeEvent}
                  />
                </div>

                {(_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) ? (
                  <div className="formBox">
                    <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                      <h4>거래처</h4>
                      <div className="formButton"
                        style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                        onClick={() => { setAccountModalStatus(true) }}
                      >
                        거래처 선택
                      </div>
                    </div>
                    <div style={{ columnGap: '5px', display: 'grid', gridTemplateColumns: 'auto 50px', width: '100%' }}>
                      <AccountInput
                        type="text"
                        name="accountName"
                        readOnly
                        value={checkEmptyNull(_account.accountName, '')}
                        placeholder="거래처..."
                      />
                      <div className="formButton"
                        style={{ backgroundColor: 'var(--MainNavy)', borderRadius: '5px', height: '40px', width: '50px' }}
                        onClick={() => { setAccount(() => { return {} }) }}
                      >
                        삭제
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="formBox">
                  <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                    <h4>책임자</h4>
                    <div className="formButton"
                      style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                      onClick={() => {
                        setWorkerModal('workOrderLogManagerIds');
                        setTimeout(setWorkerModalStatus(true), 1000);
                      }}
                    >
                      책임자 선택
                    </div>
                  </div>

                  <ContentList>
                    {workerReducer.workOrderLogManagerIds.map((thisItem, index) => {
                      return (
                        <ContentBox key={index + '_workOrderLogManagerIds'}>
                          {thisItem.name}
                          <DeleteButton onClick={(e) => { actDeleteWorkers('workOrderLogManagerIds', index, thisItem) }}><CloseButtonIcon /></DeleteButton>
                        </ContentBox>
                      );
                    })}
                  </ContentList>
                </div>
                <div className="formBox">
                  <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                    <h4>작업자</h4>
                    <div className="formButton"
                      style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                      onClick={() => {
                        setWorkerModal('workOrderLogWorkerIds');
                        setTimeout(setWorkerModalStatus(true), 1000);
                      }}
                    >
                      작업자 선택
                    </div>
                  </div>

                  <ContentList>
                    {workerReducer.workOrderLogWorkerIds.map((thisItem, index) => {
                      return (
                        <ContentBox key={index + '_workOrderLogWorkerIds'}>
                          {thisItem.name}
                          <DeleteButton onClick={(e) => { actDeleteWorkers('workOrderLogWorkerIds', index, thisItem) }}><CloseButtonIcon /></DeleteButton>
                        </ContentBox>
                      );
                    })}
                  </ContentList>
                </div>

                <div className="formBox">
                  <h4>작업 시작일</h4>
                  <DateInput
                    onChange={handleChangeEvent}
                    type="date"
                    name="workOrderScheduledStartDate"
                    defaultValue={_formData.workOrderScheduledStartDate}
                  ></DateInput>
                </div>

                <div className="formBox">
                  <h4>작업 종료일</h4>
                  <DateInput
                    onChange={handleChangeEvent}
                    type="date"
                    name="workOrderScheduledEndDate"
                    defaultValue={_formData.workOrderScheduledEndDate}
                  ></DateInput>
                </div>

                <div className="formBox"></div>

                <div className="formBox placeBox">
                  <div>
                    <h4>작업 위치</h4>
                    <div className="formButton" onClick={() => { setWorkPlaceModalStatus(true); }}>위치 선택</div>
                  </div>
                  <div>
                    <input
                      type="text"
                      className='place'
                      name="workPlace"
                      placeholder="* 작업 위치..."
                      readOnly
                      value={checkEmptyNull(_workPlace.placeName, '')}
                    />
                    <div className="formButton" onClick={() => { setWorkPlace(() => { return {}; }); }}>삭제</div>
                  </div>
                  <div>
                    <input
                      type="text"
                      name="workPlace"
                      placeholder="작업 위치..."
                      value={checkEmptyNull(_formData.workPlace, '')}
                      onInput={handleChangeEvent}
                    />
                  </div>
                </div>

                <div className="formBox">
                  <h4>비고</h4>
                  <textarea
                    name="remark"
                    maxLength={1000}
                    placeholder="비고..."
                    value={_formData.remark}
                    onInput={handleChangeEvent}
                  ></textarea>
                </div>

                {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? (
                  <div className="formBox">
                    <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                      <h4>파일</h4>
                      <div className="formButton"
                        style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                        onClick={() => {
                          dispatch(modalFileActions_setFileReset());
                          setTimeout(setFileModalStatus(true), 1000);
                        }}
                      >
                        파일 선택
                      </div>
                    </div>

                    <ContentList>
                      {_fileList.map((thisItem, index) => {
                        return (
                          <ContentBox key={thisItem.attachFileId + '_files'}>
                            {thisItem.fileDataName}
                            <DeleteButton onClick={(e) => { actDeleteFile(e, index, thisItem) }}><CloseButtonIcon /></DeleteButton>
                          </ContentBox>
                        );
                      })}
                    </ContentList>
                  </div>
                ) : null}

                <div className="formBox alarm">
                  <div>
                    <h4>작업지시 알림</h4>
                    <div className="formButton"
                      onClick={(e) => {
                        e.preventDefault();
                        handleAlarm('workOrder');
                      }}
                    >
                      알림 설정
                    </div>
                  </div>
                  <ContentList>
                    {checkNullArray(_workOrderAlarmList, []).map((workAlarm, index) => {
                      return (
                        <ContentBox key={index + '_alarm'}>
                          <span>{workAlarm.beforeDay} 일 전 {workAlarm.alarmHour} : {workAlarm.alarmMinute}</span>
                          <DeleteButton onClick={(e) => { e.preventDefault(); handleWorkAlarm(workAlarm, index); }}><CloseButtonIcon /></DeleteButton>
                        </ContentBox>
                      )
                    })}
                    {/* {checkNullObject(_workOrderAlarm, false) ? (
                      <ContentBox>
                        <span>{_workOrderAlarm.beforeDay} 일 전 {_workOrderAlarm.alarmHour} : {_workOrderAlarm.alarmMinute}</span>
                        <DeleteButton onClick={(e) => { setWorkOrderAlarm(() => { return {}; }); }}><CloseButtonIcon /></DeleteButton>
                      </ContentBox>
                    ) : null} */}
                  </ContentList>
                </div>
                <div className="formBox alarm">
                  <div>
                    <h4>공정 알림</h4>
                    <div className="formButton"
                      onClick={(e) => {
                        e.preventDefault();
                        if (_processList.length === 0) return alert('알림 설정할 공정이 없습니다.');
                        handleAlarm('process');
                      }}
                    >
                      알림 설정
                    </div>
                  </div>
                  <ContentList>
                    {checkNullArray(_processAlarmList, []).map((processAlarm, index) => {
                      return (
                        <ContentBox key={index + '_alarm'}>
                          <span>{processAlarm.process?.processCode} | {processAlarm.process?.processName}</span>
                          <span>- {processAlarm.beforeDay} 일 전 {processAlarm.alarmHour} : {processAlarm.alarmMinute}</span>
                          <DeleteButton onClick={(e) => { e.preventDefault(); handleProcessAlarm(processAlarm, index); }}><CloseButtonIcon /></DeleteButton>
                        </ContentBox>
                      )
                    })}
                  </ContentList>
                </div>
              </>
            }
          />
        </MainSection>

        {_alarmModal === true && (
          <>
            {_alarmType === 'workOrder' ? (
              <SetWorkOrderAlarmModal
                workOrderData={_formData}
                workOrderAlarmList={_workOrderAlarmList}
                buttonTitle={'확인'}
                buttonEvent={(data) => {
                  setWorkOrderAlarmList(() => { return data });
                  // setWorkOrderAlarm(() => { return data });
                  setTimeout(setAlarmModal(false), 1000);
                }}
                open={_alarmModal}
                close={() => { setAlarmModal(false) }}
              />
            ) : (
              <SetProcessAlarmModal
                processList={_processList}
                processAlarmList={_processAlarmList}
                buttonTitle={'확인'}
                buttonEvent={(data) => {
                  setProcessAlarmList(() => { return data });
                  setTimeout(setAlarmModal(false), 1000);
                }}
                open={_alarmModal}
                close={() => { setAlarmModal(false) }}
              />
            )}
          </>
        )}

        {_workPlaceModalStatus === true && (
          <SelectWorkPlaceModal
            buttonTitle={'위치 선택'}
            buttonEvent={(data) => {
              setWorkPlace(() => { return data });
              setTimeout(setWorkPlaceModalStatus(false), 1000);
            }}
            open={_workPlaceModalStatus}
            close={() => { setWorkPlaceModalStatus(false) }}
          />
        )}

        {_workerModalStatus === true && (
          <SelectUsers
            buttonTitle={'사용자 선택'}
            buttonEvent={(data) => {
              console.log('data : ', data);
              switch (_workerModal) {
                case 'workOrderLogManagerIds':
                  const workOrderLogManagerIds = workerReducer.workOrderLogManagerIds.map((thisItem) => thisItem.id);
                  const newData_workOrderLogManagerIds = data.filter((thisItem) => workOrderLogManagerIds.indexOf(thisItem.id) === -1);
                  dispatch(workerReducer_setWorkOrderLogManagerIds([...workerReducer.workOrderLogManagerIds, ...newData_workOrderLogManagerIds]));
                  break;

                case 'workOrderLogWorkerIds':
                  const workOrderLogWorkerIds = workerReducer.workOrderLogWorkerIds.map((thisItem) => thisItem.id);
                  const newData_workOrderLogWorkerIds = data.filter((thisItem) => workOrderLogWorkerIds.indexOf(thisItem.id) === -1);
                  dispatch(workerReducer_setWorkOrderLogWorkderIds([...workerReducer.workOrderLogWorkerIds, ...newData_workOrderLogWorkerIds]));
                  break;

                case 'processLogManagerIds':
                  const processLogManagerIndex = Object.keys(_eventProcessLog)[0];
                  const processLogManager = { ..._eventProcessLog[processLogManagerIndex] };
                  const managers = checkNullArray(processLogManager.managers, []);
                  const newData_processLogManagerIds = { ...processLogManager, managers: [...managers, ...data] };
                  setProcessList((prev) => {
                    const prevData = [...prev];
                    prevData.splice(processLogManagerIndex, 1, newData_processLogManagerIds);
                    return prevData;
                  });
                  break;

                case 'processLogWorkerIds':
                  const processLogWorkerIndex = Object.keys(_eventProcessLog)[0];
                  const processLogWorker = { ..._eventProcessLog[processLogWorkerIndex] };
                  const workers = checkNullArray(processLogWorker.workers, []);
                  const newData_processLogWorkerIds = { ...processLogWorker, workers: [...workers, ...data] };
                  setProcessList((prev) => {
                    const prevData = [...prev];
                    prevData.splice(processLogWorkerIndex, 1, newData_processLogWorkerIds);
                    return prevData;
                  });
                  break;

                default: return;
              }

              setTimeout(setWorkerModalStatus(false), 1000);
            }}
            open={_workerModalStatus}
            close={() => { setWorkerModalStatus(false); }}
          />
        )}

        {_equipmentModalStatus === true && (
          <SelectOneEquipment
            equipmentPos={_equipmentPos}
            buttonEvent={(data) => {
              if (checkNullObject(data, false)) {
                const processKey = data.processKey;
                if (!processKey.includes('_')) return;
                const processIndex = processKey.split('_')[1] * 1;
                setProcessList((prev) => {
                  const prevData = [...prev];
                  const newData = prevData[processIndex];
                  newData.equipmentId = data.equipmentId;
                  newData.equipmentName = data.equipmentName;
                  prevData.splice(processIndex, 1, newData);
                  return prevData;
                });
              }
              setTimeout(setEquipmentModalStatus(false), 1000);
            }}
            open={_equipmentModalStatus}
            close={() => { setEquipmentModalStatus(false) }}
          ></SelectOneEquipment>
        )}

        {_processModalStatus === true && (
          <SelectProcesses
            buttonTitle={'공정 선택'}
            buttonEvent={(data) => {
              console.log('data : ', data);
              const newDataList = [..._processList, ...data];
              const processList = newDataList.map((thisItem, index) => {
                const unitAmount = new BigNumber(_formData.amount || 1);
                const processUnitAmount = new BigNumber(thisItem.count || 1);
                const totalAmount = unitAmount.multipliedBy(processUnitAmount);

                const returnData = {
                  ...thisItem,
                  amount: _formData.amount || 1,
                  count: processUnitAmount.toNumber() || 1,
                  target: thisItem.target || false,
                  processAmount: totalAmount.toNumber() || _formData.amount || 1,
                  processingCount: totalAmount.toNumber() || _formData.amount || 1,
                };
                if (!checkEmptyNull(thisItem.processNumber, false) || thisItem.processNumber === 0) returnData.processNumber = index + 1;
                if (checkEmptyNull(thisItem.processingCount, false) && thisItem.processingCount !== 0) returnData.processingCount = thisItem.processingCount;
                console.log('returnData : ', returnData);
                return returnData;
              });

              setFormData((prev) => { return { ...prev, processLogList: processList }; });
              setProcessList(() => { return processList; });

              const processLogElementList = processList.map((thisItem) => thisItem.processLogElementList !== undefined ? thisItem.processLogElementList : thisItem.processElementList);
              const setProcessLogElementList = processLogElementList.flat();
              actCalMinAmount(setProcessLogElementList);

              setTimeout(setProcessModalStatus(false), 1000);
            }}
            open={_processModalStatus}
            close={() => { setProcessModalStatus(false); }}
          />
        )}

        {_accountModalStatus === true && (
          <SelectOneAccount
            buttonTitle={'거래처 선택'}
            buttonEvent={(data) => {
              setAccount(() => { return data; });
              setTimeout(setAccountModalStatus(false), 1000);
            }}
            open={_accountModalStatus}
            close={() => { setAccountModalStatus(false); }}
          />
        )}

        {_fileModalStatus === true && (
          <SelectFiles
            buttonTitle={'파일 선택'}
            buttonEvent={(data) => {
              const newDataList = data.filter((thisItem) => _fileList.findIndex((thisIndex) => thisIndex.fileDataId === thisItem.fileDataId) === -1);
              setFileList((prev) => { return [...prev, ...newDataList]; });
              setTimeout(setFileModalStatus(false), 1000);
            }}
            open={_fileModalStatus}
            close={() => { setFileModalStatus(false); }}
          />
        )}

        {_elementStockLogModalStatus === true && (
          <SelectElementStockLogs
            content={_logEventContent}
            buttonTitle={'재고 연동'}
            buttonEvent={(data) => {
              console.log('data : ', data);

              const processIndex = data.index.split('_')[0];
              const elementIndex = data.index.split('_')[1];

              setProcessList(prev => {
                const processList = [...prev];
                const processData = processList[(processIndex)];
                const processElementList = [...checkNullArray(processData.processElementList, checkNullArray(processData.processLogElementList, []))]
                const processElementData = processElementList[elementIndex];

                const inputElementStockLogs = data.inputElementStockLogs.map(elementStockLog => {
                  return { ...elementStockLog, scheduledInputStock: 0 }
                })
                if (!checkNullArray(processElementData.inputElementStockLogs, false)) {
                  processElementData.inputElementStockLogs = [...inputElementStockLogs];
                } else {
                  processElementData.inputElementStockLogs = [...processElementData.inputElementStockLogs, ...inputElementStockLogs];
                }

                processElementList.splice(elementIndex, 1, processElementData);
                processData.processElementList = processElementList;
                processList.splice(processIndex, 1, processData);

                return processList;
              })

              setTimeout(setElementStockLogModalStatus(false), 1000);
            }}
            open={_elementStockLogModalStatus}
            close={() => { setElementStockLogModalStatus(false); }}
          />
        )}
      </>
    }
    />
  );
};

export default CreateWorkOrderLog;
