import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullArray, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import { DateTimeFormat } from 'components/format/DateTimeFormat';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import IconRightArrow from 'components/icons/IconRightArrow';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  max-width: 800px;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseModalButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const QualityModal = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}
`;

const MainContents = styled.div`
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 5px 20px;
  width: 100%;
`;
const DateContents = styled.div`
  align-items: center;
  column-gap: 10px;
  display: grid;
  grid-template-columns: 1fr 15px 1fr;
  width: 100%;
`;
const SearchContents = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 150px calc(100% - 160px);
  margin-top: 5px;
  width: 100%;

  &.detail {grid-template-columns: 150px calc(100% - 210px) 50px;}

  select {
    border: 1px solid var(--ThirdGreen);
    border-radius: 5px;
    box-sizing: border-box;
    height: fit-content;
    padding: 10px;
    width: 100%;

    &.detail {
      background-color: var(--ThirdGreen);
      color: var(--white);

      appearance: none; --webkit-appearance: none;
    }
  }

  input {
    border: 1px solid var(--ThirdGreen);
    border-radius: 5px;
    box-sizing: border-box;
    height: fit-content;
    padding: 10px;
    width: 100%;

    &:focus {border: 1px solid var(--ThirdGreen);}
  }
`;

const SelectDate = styled.input`
  border: 1px solid var(--ThirdGreen);
  border-radius: 5px;
  box-sizing: border-box;
  height: fit-content;
  margin-top: 5px;
  min-width: 250px;
  padding: 10px;
  width: 100%;
  
  &:focus {border: 1px solid var(--ThirdGreen);}
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--ThirdGreen);
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 400;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const UriQualityDownload = (props) => {
  const { userReducer } = useSelector((state) => state);

  const { open, close } = props;

  const [_startDate, setStartDate] = useState('');
  const [_endDate, setEndDate] = useState('');
  const [_searchText, setSearchText] = useState('');

  const setExcelData = (content) => {
    const workOrderLogList = checkNullArray(content, []);
    const excelData = [];

    workOrderLogList.forEach((workOrderLog, windex) => {
      const processLogList = checkNullArray(workOrderLog.processLogList, []);
      processLogList.forEach((processLog, pindex) => {
        const customizedContent = checkNullParse(processLog.processCustomizedContent, {
          bundleSize: '',
          bundle: '',
        });

        let pushData = {};
        if (pindex === 0) {
          pushData = {
            지시일자: DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledStartDate, '')),
            거래처: workOrderLog.accountName,
            생산수량: checkEmptyNull(workOrderLog.amount, 0).toLocaleString(),
            성공수량: checkEmptyNull(workOrderLog.successfulAmount, 0).toLocaleString(),
            불량수량: checkEmptyNull(workOrderLog.failedAmount, 0).toLocaleString(),
            사이즈: customizedContent.bundleSize,
            창짝: customizedContent.bundle,
            작업시작: DateTimeFormat(checkEmptyNull(processLog.processActualStartDate, '')),
            작업종료: DateTimeFormat(checkEmptyNull(processLog.processActualEndDate, '')),
          }
        } else {
          pushData = {
            // 지시일자: '',
            // 거래처: '',
            // 생산수량: '',
            // 성공수량: '',
            // 불량수량: '',
            사이즈: customizedContent.bundleSize,
            창짝: customizedContent.bundle,
            작업시작: DateTimeFormat(checkEmptyNull(processLog.processActualStartDate, '')),
            작업종료: DateTimeFormat(checkEmptyNull(processLog.processActualEndDate, '')),
          }
        }

        excelData.push(pushData);

        if (windex === workOrderLogList.length - 1 && pindex === processLogList.length - 1) {
          const ws = XLSX.utils.json_to_sheet(excelData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, '품질리스트');
          XLSX.writeFile(wb, '품질리스트_' + DateFormat(new Date()) + '.xlsx');
        }
      })
    })

  }
  const actDownload = async () => {
    const paging = `?page=0&size=7000&sort=id,DESC`;
    const body = {
      companyId: userReducer.company.companyId,
      workOrderStatus: ['end', 'cancel'],
    };
    if (checkEmptyNull(_startDate, false)) body.scheduledStartDate = DateFormat(_startDate);
    if (checkEmptyNull(_endDate, false)) body.scheduledEndDate = DateFormat(_endDate);
    if (_startDate > _endDate) {
      body.scheduledStartDate = DateFormat(_endDate);
      body.scheduledEndDate = DateFormat(_startDate);
    }
    if (checkEmptyNull(_searchText, false)) body.accountName = _searchText;

    await workOrderLogApi.searchWorkOrderLog(paging, body).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.searchWorkOrderLog : ', response);
      setExcelData(response.data.data.content);
    });
  };

  return (
    <div className={open ? 'openModal modal' : 'modal'}>
      {open ? (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>엑셀 다운로드</ModalTitle>
              <CloseModalButton className="close" onClick={() => { close() }}><CloseButtonIcon /></CloseModalButton>
            </ModalHeader>

            <QualityModal>
              <MainContents>
                <div>날짜</div>
                <DateContents>
                  <SelectDate
                    type="date"
                    className="SelectDate"
                    value={_startDate}
                    onChange={(e) => { setStartDate(() => { return e.target.value; }); }}
                  />
                  <IconRightArrow />
                  <SelectDate
                    type="date"
                    className="SelectDate"
                    value={_endDate}
                    onChange={(e) => { setEndDate(() => { return e.target.value; }); }}
                  />
                </DateContents>
              </MainContents>

              <MainContents>
                <div>검색</div>
                <SearchContents>
                  <select className="SearchOption detail">
                    <option value="accountName">거래처</option>
                  </select>
                  <input
                    className="SearchBar"
                    placeholder="Search..."
                    value={_searchText}
                    onInput={(e) => { setSearchText(() => { return e.target.value; }); }}
                  />
                </SearchContents>
              </MainContents>
            </QualityModal>

            <ModalFooter>
              <ModalButton onClick={actDownload}>다운로드</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      ) : null}
    </div>
  );
};

export default UriQualityDownload;
