export const contentsReducer_reset = () => {
  return {
    type: 'CONTENTSRESET',
  };
};

export const contentsReducer_setCopyContents = (thisContents) => {
  return {
    type: 'SETCOPYCONTENTS',
    payload: thisContents,
  };
};

export const contentsReducer_setDeleteContents = (thisContents) => {
  return {
    type: 'SETDELETECONTENTS',
    payload: thisContents,
  };
};

export const contentsReducer_setUpdateContents = (thisContents) => {
  return {
    type: 'SETUPDATECONTENTS',
    payload: thisContents,
  };
};

/* AttributeManagement */
export const contentsReducer_setAttributeType = (thisAttribute) => {
  return {
    type: 'SETATTRIBUTETYPE',
    payload: thisAttribute,
  };
};

export const contentsReducer_setAccountAttributeType = (thisAttribute) => {
  return {
    type: 'SETACCOUNTATTRIBUTETYPE',
    payload: thisAttribute,
  };
};

/* ProductInformation */
export const contentsReducer_setBIColumns = (thisColumns) => {
  return {
    type: 'SETBICOLUMNS',
    payload: thisColumns,
  };
};

/* CurrentStock */
export const contentsReducer_setStockItem = (thisItem) => {
  return {
    type: 'SETSTOCKITEM',
    payload: thisItem,
  };
};

/* RemarkModal */
export const contentsReducer_setRemark = (thisRemark) => {
  return {
    type: 'SETREMARK',
    payload: thisRemark,
  };
};

/* WorkOrder */
export const contentsReducer_setWorkOrderContents = (thisContents) => {
  return {
    type: 'SETWORKORDER',
    payload: thisContents,
  };
};

/* Contract */
export const contentsReducer_setContractContents = (thisContract) => {
  return {
    type: 'SETORDERS',
    payload: thisContract,
  };
};
export const contentsReducer_setUpdateContractContents = (thisContract) => {
  return {
    type: 'SETUPDATEORDERS',
    payload: thisContract,
  };
};
export const contentsReducer_setDeleteContractContents = (thisContract) => {
  return {
    type: 'SETDELETEORDERS',
    payload: thisContract,
  };
};
