const initialState = {
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,

  companyId: '',
}
const resetState = {
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETTEAMSEARCHTEXT': return { ...state, searchText: action.payload };
    case 'SETTEAMPAGENUMBER': return { ...state, pageNumber: action.payload };
    case 'SETTEAMTOTALSIZE': return { ...state, totalSize: action.payload };
    case 'SETTEAMISPUSHEDSEARCHBUTTON': return { ...state, isPushedSearchButton: action.payload };

    case 'SETTEAMCOMPANYID': return { ...state, companyId: action.payload };
    case 'SETTEAMRESET': return { ...state, ...resetState };
    default: return state;
  };
};

export default teamReducer;