import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { mallElementActions_setLevel1Categories, mallElementActions_setLevel2Categories, mallElementActions_setLevel3Categories } from 'store/modules/actions/mall/mallElementActions';
import { mallSiteElementActions_setLevel1Categories, mallSiteElementActions_setLevel2Categories, mallSiteElementActions_setLevel3Categories } from 'store/modules/actions/mallSite/mallSiteElementActions';

import { category } from 'api/apis/mall/category';
import { checkEmptyNull } from 'components/checkValues/checkValues';

const MallCategoryLevel1 = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const location = useLocation();
  const { mallElementReducer, mallSiteElementReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const companyCode = checkEmptyNull(userReducer.company.companyId, false) ? userReducer.company?.companyCode : location.pathname.split('/')[1];

  const [_onload, setOnload] = useState('unload');

  const [_mallCategoryList, setCategoryList] = useState(() => {
    switch (props.mallCategoryType) {
      case 'mallElement': return mallElementReducer.level1Categories;
      case 'mallSiteElement': return mallSiteElementReducer.level1Categories;
      default: return [];
    }
  });

  /* ====================================================================== #3 */
  useEffect(() => {
    getCategoryLevel1List();

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      if (Object.keys(props.clickedLevel1Category).length === 0) {
        setCategoryList(() => { return []; });
        getCategoryLevel1List();
      }
    }
    return () => { };
  }, [props.clickedLevel1Category]);

  /* ====================================================================== #4 */
  const getCategoryLevel1List = async () => {
    const BodyToPost = {
      companyCode: companyCode,
      mallCategoryLevel: 1,
    };
    await category.searchMallCategory(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('category.searchMallCategory : ', response);
      switch (props.mallCategoryType) {
        case 'mallElement': dispatch(mallElementActions_setLevel1Categories(response.data)); break;
        case 'mallSiteElement': dispatch(mallSiteElementActions_setLevel1Categories(response.data)); break;
        default: break;
      }
      setCategoryList(() => { return response.data; });
    });
  };

  /* ====================================================================== #5 */
  const handleCategory = (e) => {
    console.log(e.target.value);
    if (e.target.value === '') {
      props.setClickedCategory('all');
      props.setClickedLevel1Category({});
      return;
    }
    const thisFindIndex = _mallCategoryList.findIndex((thisItem) => thisItem.mallCategoryId === Number(e.target.value));
    if (thisFindIndex === -1) return;
    const thisFindItem = _mallCategoryList[thisFindIndex];
    props.setClickedCategory(thisFindItem);
    props.setClickedLevel1Category(thisFindItem);
  };

  return (
    <select
      className="SelectFamily"
      value={(() => {
        if (props.clickedLevel1Category !== undefined && props.clickedLevel1Category !== null && props.clickedLevel1Category.mallCategoryId) {
          return props.clickedLevel1Category.mallCategoryId;
        } else {
          return '';
        }
      })()}
      onChange={handleCategory}
    >
      <option value="">패밀리 1</option>
      {_mallCategoryList.map((thisItem, index) => {
        return (<option key={index + '_mallCategory1'} value={thisItem.mallCategoryId}>{thisItem.mallCategoryName}</option>);
      })}
    </select>
  );
};

const MallCategoryLevel2 = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const location = useLocation();
  const { mallElementReducer, mallSiteElementReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const companyCode = checkEmptyNull(userReducer.company.companyId, false) ? userReducer.company?.companyCode : location.pathname.split('/')[1];

  const [_onload, setOnload] = useState('unload');

  const [_clickedLevel1Category, setClickedLevel1Category] = useState([]);

  const [_mallCategoryList, setCategoryList] = useState(() => {
    switch (props.mallCategoryType) {
      case 'mallElement': return mallElementReducer.level2Categories;
      case 'mallSiteElement': return mallSiteElementReducer.level2Categories;
      default: return [];
    }
  });

  /* ====================================================================== #3 */
  useEffect(() => {
    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      setClickedLevel1Category(() => { return props.clickedLevel1Category; });
    }
    return () => { };
  }, [props]);

  useEffect(() => {
    getCategoryLevel2List();
    return () => { };
  }, [_clickedLevel1Category]);

  /* ====================================================================== #4 */
  const getCategoryLevel2List = async () => {
    if (Object.keys(_clickedLevel1Category).length < 1) return setCategoryList(() => { return []; });
    const BodyToPost = {
      companyCode: companyCode,
      mallCategoryParentLevel: 1,
      mallCategoryParentCode: _clickedLevel1Category.mallCategoryCode,
      mallCategoryLevel: 2,
    };
    await category.searchMallCategory(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('category.searchMallCategory : ', response);
      switch (props.mallCategoryType) {
        case 'mallElement': dispatch(mallElementActions_setLevel2Categories(response.data)); break;
        case 'mallSiteElement': dispatch(mallSiteElementActions_setLevel2Categories(response.data)); break;
        default: break;
      }
      setCategoryList(() => { return response.data; });
    });
  };

  /* ====================================================================== #5 */
  const handleCategory = (e) => {
    console.log(e.target.value);
    if (e.target.value === '') {
      props.setClickedCategory(props.clickedLevel1Category);
      props.setClickedLevel2Category({});
      return;
    }
    const thisFindIndex = _mallCategoryList.findIndex((thisItem) => thisItem.mallCategoryId === Number(e.target.value));
    if (thisFindIndex === -1) return;
    const thisFindItem = _mallCategoryList[thisFindIndex];
    props.setClickedCategory(thisFindItem);
    props.setClickedLevel2Category(thisFindItem);
  };

  return (
    <select
      className="SelectFamily"
      value={(() => {
        if (props.clickedLevel2Category !== undefined && props.clickedLevel2Category !== null && props.clickedLevel2Category.mallCategoryId) {
          return props.clickedLevel2Category.mallCategoryId;
        } else {
          return '';
        }
      })()}
      onChange={handleCategory}
    >
      <option value="">패밀리 2</option>
      {_mallCategoryList.map((thisItem, index) => {
        return (<option key={index + '_mallCategory2'} value={thisItem.mallCategoryId}>{thisItem.mallCategoryName}</option>);
      })}
    </select>
  );
};

const MallCategoryLevel3 = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const location = useLocation();
  const { mallElementReducer, mallSiteElementReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const companyCode = checkEmptyNull(userReducer.company.companyId, false) ? userReducer.company?.companyCode : location.pathname.split('/')[1];

  const [_onload, setOnload] = useState('unload');

  const [_clickedLevel2Category, setClickedLevel2Category] = useState([]);

  const [_mallCategoryList, setCategoryList] = useState(() => {
    switch (props.mallCategoryType) {
      case 'mallElement': return mallElementReducer.level3Categories;
      case 'mallSiteElement': return mallSiteElementReducer.level3Categories;
      default: return [];
    }
  });

  /* ====================================================================== #3 */
  useEffect(() => {
    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      setClickedLevel2Category(() => {
        return props.clickedLevel2Category;
      });
    }
    return () => { };
  }, [props]);

  useEffect(() => {
    getCategoryLevel3List();
    return () => { };
  }, [_clickedLevel2Category]);

  /* ====================================================================== #4 */
  const getCategoryLevel3List = async () => {
    if (Object.keys(_clickedLevel2Category).length < 1) return setCategoryList(() => { return []; });

    const BodyToPost = {
      companyCode: companyCode,
      mallCategoryParentLevel: 2,
      mallCategoryParentCode: _clickedLevel2Category.mallCategoryCode,
      mallCategoryLevel: 3,
    };
    await category.searchMallCategory(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('category.searchMallCategory : ', response);
      switch (props.mallCategoryType) {
        case 'mallElement': dispatch(mallElementActions_setLevel3Categories(response.data)); break;
        case 'mallSiteElement': dispatch(mallSiteElementActions_setLevel3Categories(response.data)); break;
        default: break;
      }
      setCategoryList(() => { return response.data; });
    });
  };

  /* ====================================================================== #5 */
  const handleCategory = (e) => {
    console.log(e.target.value);
    if (e.target.value === '') {
      props.setClickedCategory(props.clickedLevel2Category);
      props.setClickedLevel3Category({});
      return;
    }
    const thisFindIndex = _mallCategoryList.findIndex((thisItem) => thisItem.mallCategoryId === Number(e.target.value));
    if (thisFindIndex === -1) return;
    const thisFindItem = _mallCategoryList[thisFindIndex];
    props.setClickedCategory(thisFindItem);
    props.setClickedLevel3Category(thisFindItem);
  };

  return (
    <select
      className="SelectFamily"
      value={(() => {
        if (props.clickedLevel3Category !== undefined && props.clickedLevel3Category !== null && props.clickedLevel3Category.mallCategoryId) {
          return props.clickedLevel3Category.mallCategoryId;
        } else {
          return '';
        }
      })()}
      onChange={handleCategory}
    >
      <option value="">패밀리 3</option>
      {_mallCategoryList.map((thisItem, index) => {
        return (<option key={index + '_mallCategory3'} value={thisItem.mallCategoryId}>{thisItem.mallCategoryName}          </option>);
      })}
    </select>
  );
};

export { MallCategoryLevel1, MallCategoryLevel2, MallCategoryLevel3 };
