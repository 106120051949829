import { AxiosInterceptor } from "api/core/consts";

export const equipmentAttributeApi = {
  searchEquipementAttribute: (body) =>
    AxiosInterceptor.post('equipmentAttribute/search', body),
  createEquipementAttribute: (body) =>
    AxiosInterceptor.post('equipmentAttribute', body),
  updateEquipementAttribute: (equipementAttributeId, body) =>
    AxiosInterceptor.put('equipmentAttribute/' + equipementAttributeId, body),
  deleteEquipementAttribute: (equipementAttributeId) =>
    AxiosInterceptor.delete('equipmentAttribute/' + equipementAttributeId),
};
