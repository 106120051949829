import styled from 'styled-components';

export const Section = styled.main`
  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;

    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }

    & .statusButtons {
      display: flex;
      gap: 5px;
    }
  }
`;

export const ProcurementTable = styled.table`
  thead tr {
    border: none;
  }
  tbody {
    tr {
      border-bottom: 1px solid var(--MainBlue);
    }
    td {
      border: 1px solid var(--gray-200);
      height: fit-content;
    }
  }
`;

export const ViewerButtons = styled.div`
  z-index: 999;
  position: fixed;
  right: 76px;
  bottom: 12px;
  column-gap: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;