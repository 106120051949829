import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

import { elementAttributeApi } from 'api/apis/elementAttributeApi';
import { elementApi } from 'api/apis/elementApi';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 85%;
  justify-content: flex-start;
  max-height: 520px;
  max-width: 710px;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  height: calc(100% - 55px);
  overflow: auto;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}
`;

const ContentSection = styled.div`
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
  width: 100%;
`;
const SelectSection = styled.select`
  background-color: var(--MainNavy);
  border-radius: 5px;
  color: var(--white);
  font-size: 18px;
  height: 50px;
  padding: 10px;
  width: 100%;
`;
const ButtonsSection = styled.div`
  align-items: center;
  column-gap: 10px;
  display: flex;
  justify-content: center;
  margin: 20px auto;
  width: 100%;
`;
const FormatButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  border-radius: 10px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 200px;
  justify-content: center;
  width: 50%;
`;
const UploadModalButton = styled.label`
  align-items: center;
  background-color: var(--ThirdBlue);
  border-radius: 10px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 200px;
  justify-content: center;
  width: 50%;
`;

const NoticeSection = styled.div`
  color: var(--MainRed);
  font-size: 15px;
  padding-bottom: 20px;
`;

const LoadingScreen = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  color: var(--white);
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 999;
`;

const ElementUploadModal = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_formatElementType, setFormatElementType] = useState();
  const [_formatAttributeList, setFormatAttributeList] = useState([]);

  const [_loadingStatus, setLoadingStatus] = useState(false);

  /* ====================================================================== #3 */
  /* ====================================================================== #4 */
  const getElementAttrs = async (elementType) => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      elementType: elementType,
    };
    await elementAttributeApi.searchElementAttribute(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('elementAttributeApi.searchElementAttribute : ', response);
      setFormatAttributeList(() => { return response.data });
    });
  };

  const excelExportCommon = (e, callback) => {
    const files = e.target.files;
    const file = files[0];
    if (file === undefined) return;

    const reader = new FileReader();
    reader.onload = async () => {
      const fileData = reader.result;
      const wb = XLSX.read(fileData, { type: 'binary' });
      const sheetNameList = wb.SheetNames; // 시트 이름 목록 가져오기
      for (let i = 0; i < sheetNameList.length; i++) {
        const thisSheetName = sheetNameList[i];
        const thisSheet = wb.Sheets[thisSheetName];
        await callback(thisSheet);
      }
    };

    reader.readAsBinaryString(file);
  };

  const excelSetting = async (sheet) => {
    const sendData = {
      companyId: userReducer.company.companyId,
      elementType: _formatElementType,
    };
    const result = XLSX.utils.sheet_to_json(sheet);

    const setSendData = result.map((thisItem) => {
      const newData = {
        attributeAndValue: {},
      };
      for (const key in thisItem) {
        const value = thisItem[key];
        switch (key) {
          case '패밀리': newData.category = value; break;
          case '물품코드': newData.elementCode = value; break;
          case '물품이름': newData.elementName = value; break;
          case '단위': newData.elementUnit = value; break;
          case '단가': newData.price = value; break;
          case '현재고': newData.elementStock = value; break;
          case '안전재고': newData.elementSafeStock = value; break;

          default: newData.attributeAndValue[key] = value; break;
        }
      }

      return newData;
    });
    sendData['elementList'] = setSendData;

    if (result.length === sendData.elementList.length) {
      console.log('sendData : ', sendData);
      console.log('sendData - stringify : ', JSON.stringify(sendData));
      await postElements(sendData);
    }
  };
  const postElements = async (sendData) => {
    await elementApi.uploadElements(sendData).then((response) => {
      if (response === undefined) return;
      console.log('elementApi.uploadElements : ', response);

      if (response.data === 'success') {
        alert('물품정보를 업로드했습니다.');
      } else {
        alert(`
            업로드를 할 수 없습니다.
            해당 파일을 확인해주세요.
          `);
      }
    }).catch((error) => {
      console.log('elementApi.uploadElements : ', error);
      alert(`
          업로드를 할 수 없습니다.
          해당 파일을 확인해주세요.
        `);
    }).finally(() => {
      setLoadingStatus(false);
      props.close();
    });
  };

  /* ====================================================================== #5 */
  const selectElementType = (e) => {
    const thisType = e.target.value;

    setFormatElementType(thisType);
    getElementAttrs(thisType);
  };

  const downloadFormat = () => {
    let thisTypeText = '';
    switch (_formatElementType) {
      case 'material': thisTypeText = '자재'; break;
      case 'semi': thisTypeText = '부자재'; break;
      case 'half': thisTypeText = '반제품'; break;
      case 'product': thisTypeText = '완제품'; break;
      case 'tool': thisTypeText = '공구'; break;

      default: return alert('물품유형을 선택해주세요.');
    }

    let headerRow = [];
    const OneRow = {
      패밀리: '',
      물품이름: '',
      물품코드: '',
      단위: '',
      단가: '',
      현재고: '',
      안전재고: '',
    };
    _formatAttributeList.forEach((thisItem) => {
      OneRow[thisItem.elementAttributeName] = '';
    });

    headerRow.push(OneRow);

    if (headerRow.length > 0) {
      const ws = XLSX.utils.json_to_sheet(headerRow);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `${thisTypeText} 업로드 양식`);
      XLSX.writeFile(wb, `${thisTypeText} 업로드 양식.xlsx`);
    }
  };

  const selectUploadFile = (e) => {
    let thisTypeText = '';
    switch (_formatElementType) {
      case 'material': thisTypeText = '자재'; break;
      case 'semi': thisTypeText = '부자재'; break;
      case 'half': thisTypeText = '반제품'; break;
      case 'product': thisTypeText = '완제품'; break;
      case 'tool': thisTypeText = '공구'; break;

      default: e.target.value = ''; return alert('물품유형을 선택해주세요.');
    }

    const confirmText = `[ ${thisTypeText} ] 정보를 업로드 하시겠습니까?`;
    if (window.confirm(confirmText) === true) {
      setLoadingStatus(true);
      const files = e.target.files;
      const file = files[0];
      if (file === undefined) return;

      excelExportCommon(e, excelSetting);
    } else {
      return;
    }
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>물품정보 업로드</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <ContentSection>
                <SelectSection defaultValue={''} onChange={selectElementType}>
                  <option value="" disabled>물품유형</option>
                  {userReducer.elementType.map((thisType) => {
                    let thisTypeText = '';
                    switch (thisType) {
                      case 'material': thisTypeText = '자재'; break;
                      case 'semi': thisTypeText = '부자재'; break;
                      case 'half': thisTypeText = '반제품'; break;
                      case 'product': thisTypeText = '완제품'; break;
                      case 'tool': thisTypeText = '공구'; break;

                      default: break;
                    }
                    return (<option key={thisType} value={thisType}>{thisTypeText}</option>);
                  })}
                </SelectSection>

                <ButtonsSection>
                  <FormatButton onClick={downloadFormat}>양식 다운</FormatButton>

                  <input
                    type="file"
                    id="UploadModalInput"
                    style={{ display: 'none' }}
                    accept=".xlsx, .xls, .csv"
                    onChange={selectUploadFile}
                  />
                  <UploadModalButton htmlFor="UploadModalInput">업로드</UploadModalButton>
                </ButtonsSection>

                <NoticeSection>
                  <p>※ 순서 ※</p>
                  <p>1. 물품유형 선택</p>
                  <p>2. 양식 다운 → 양식에 맞춘 엑셀 준비</p>
                  <p>※ 엑셀 내 소수점이 있는 경우 셀서식 - 표시형식 - 숫자 - 소수자릿수를 0으로 설정한 후 업로드 해주세요.</p>
                  <p>※ 패밀리를 <span>{`>>`}</span> 기호로 구분해주세요</p>
                  <p>3. 업로드</p>
                </NoticeSection>
              </ContentSection>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}

      {_loadingStatus === true ? (
        <LoadingScreen>물품정보를 업로드하고 있습니다...</LoadingScreen>
      ) : null}
    </>
  );
};

export default ElementUploadModal;
