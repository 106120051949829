import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

import Worker from 'pages/manHour/result/contents/Worker';
import Production from 'pages/manHour/result/contents/Production';

const Section = styled.main`
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 66px calc(100% - 66px);

  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
    &>h4 {border: none;}
  }

  &>section {
    box-sizing: border-box;
    display: grid;
    gap: 10px;
    /* grid-template-columns: 100px calc(100% - 110px); */
    height: 100%;
    overflow: hidden;
    padding: 10px;
    width: 100%;

    .section-type {
      align-content: flex-start;
      display:  grid;
      gap: 5px;
      button {
        height: 50px;
        &.active {
          background-color: var(--MainNavy);
          color: var(--white);
        }
      }
    }
    .section-result {
      height: 100%;
      overflow: hidden;
      width: 100%;
    }
  }
`;

const ManHourResult = () => {
  const [_resultType, setResultType] = useState('worker');

  return (
    <Grid2Body contents={
      <>
        <Section className='Main'>
          <NavBar
            title={
              <div className='Title'>
                <Link to={'/production/manHour'} style={{color: '#ccc'}}><NavTitle menuCode={'414'} /></Link>
                <h4>공수실적</h4>
              </div>
            }
            nav={''}
          />
          <section>
            {/* <div className='section-type'>
              {[
                {type: 'worker', name: '작업자'},
                {type: 'production', name: '작업'},
              ].map((resultType, index) => {
                return (
                  <button key={index + '_type'} className={_resultType === resultType.type ? 'btn-set active' : 'btn-set'}
                    onClick={(e => {e.preventDefault(); setResultType(() => {return resultType.type});})}
                  >
                    {resultType.name}
                  </button>
                );
              })}
            </div> */}
            <div className='section-result'>
              <Worker />
              {/* {_resultType === 'worker' ? <Worker /> : null} */}
              {/* {_resultType === 'production' ? <Production /> : null} */}
            </div>
          </section>
        </Section>
      </>
    }
    />
  );
};

export default ManHourResult;