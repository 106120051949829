import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { contractApi } from 'api/apis/contractApi';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

const DeleteGSContract = () => {
  const navigate = useNavigate();
  const { gsReducer, pageReducer, userReducer } = useSelector((state) => state);

  const actDelOrder = async () => {
    await contractApi.deleteContract(gsReducer.deleteOrder.contractId).then((response) => {
      if (response === undefined) return;
      console.log('contractApi.deleteContract : ', response);
      alert('수주를 삭제했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    })
  };

  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <>
        <main className="Main">
          <NavBar nav={''} title={<NavTitle menuCode={'201'} />} />

          <DeleteForm
            delteTitle={<NavTitle menuCode={'201'} />}
            deleteItem={
              <>
                {'수주코드 : ' + gsReducer.deleteOrder.contractCode}<br />
                {'공사명 : ' + gsReducer.deleteOrder.contractName} <br />
              </>
            }
            deleteText={
              <>
                해당 <span className="cautionText">모든 수주 내용</span>이 삭제됩니다.
              </>
            }
            deleteButton={
              <>
                <button className="formButton cancle"
                  onClick={() => {
                    navigate(pageReducer.currentPage, { replace: true });
                  }}
                >
                  취소
                </button>
                <button className="formButton delete" onClick={actDelOrder}>삭제</button>
              </>
            }
          />
        </main>
      </>
    }
    />
  );
};

export default DeleteGSContract;
