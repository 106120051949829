import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { contentsReducer_setUpdateContents } from 'store/modules/actions/default/contentsActions';
import { gsReducer_setDeleteOrder } from 'store/modules/actions/company/GSActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { procurementActions_setEndDate, procurementActions_setIsPushedSearchButton, procurementActions_setPageNumber, procurementActions_setProcurementAccount, procurementActions_setProcurementElementList, procurementActions_setProcurementStatus, procurementActions_setReset, procurementActions_setSearchOption, procurementActions_setSearchText, procurementActions_setStartDate, procurementActions_setTotalSize } from 'store/modules/actions/common/procurementActions';
import { stockReducer_setContractAccount, stockReducer_setContractProducts } from 'store/modules/actions/default/stockActions';

import { procurementApi } from 'api/apis/procurementApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import { LoadingMsg } from 'components/loading/LoadingMsg';

import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import ProcurementPdfModal from 'pages/procurement/modal/ProcurementPdfModal';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';

import GSNotInStock from 'pages/procurement/custom/company/gs/modal/GSNotInStock';
import GSProcurementElementList from 'pages/procurement/custom/company/gs/modal/GSProcurementEndList';
import GSProcurementIncoming from 'pages/procurement/custom/company/gs/modal/GSProcurementIncoming';

const Section = styled.main`
  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }

    & .statusButtons {display: flex; gap: 5px;}
  }
`;

const StockTable = styled.table`
  tr {border: none;}
  td, th {min-width: 120px;}
  thead tr:nth-child(2) th {height: 40px;}
  tbody tr {border-bottom: 1px solid var(--MainNavy);}
`;
const ListButton = styled.div`
  align-items: center;
  background-color: ${(props) => {
    switch (props.type) {
      case 'list': return 'var(--MainNavy)';
      case 'inStock': return 'var(--MainGreen)';
      case 'notInStock': return 'var(--MainRed)';
      default: return null;
    }
  }};
  border-radius: 5px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 15px;
  height: calc(100px / 3);
  justify-content: center;
  margin: 0px auto;
  width: 96%;
`;

/* 발주 components */
const GSProcurement = () => {
  /* ============================================================================================ 라이브 관련 함수들 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, procurementReducer, userReducer } = useSelector((state) => state);

  /* ============================================================================================ #2 */
  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_procurementList, setProcurementList] = useState([]);
  const [_selectedProcurement, setSelectedProcurement] = useState({});
  const [_checkedItem, setCheckedItem] = useState(new Set());

  /* 모달 */
  const [_printProcurementModalStatus, setPrintPocurementModalStatus] = useState(false); // 발주서
  const [_procurementEndListStatus, setProcurementEndListStatus] = useState(false); // 물품 리스트
  const [_procurementListStatus, setProcurementListStatus] = useState(false); // 부분입고
  const [_notInStockModalStatus, setNotInStockModalStatus] = useState(false); // 미입고
  const [_eventProcurement, setEventProcurement] = useState({});

  /* ============================================================================================ #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('202') === true || // procurement
      thisItem.authority.startsWith('205') === true, // procurement
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getProcurementList(procurementReducer.pageNumber - 1, procurementReducer.procurementStatus, procurementReducer.isPushedSearchButton);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getProcurementList(procurementReducer.pageNumber - 1, procurementReducer.procurementStatus, procurementReducer.isPushedSearchButton);
    }

    return () => { };
  }, [procurementReducer.pageNumber]);

  useEffect(() => { }, [procurementReducer]);

  /* ============================================================================================ #4 */
  const getProcurementList = async (page, procurementStatus, isPushedSearchButton) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    if (checkEmptyNull(procurementStatus, false) && procurementStatus !== 'all') {
      BodyToPost.procurementStatus = procurementStatus;
    }
    if (checkEmptyNull(procurementReducer.startDate, false) && checkEmptyNull(procurementReducer.endDate, false)) {
      const startDate = new Date(procurementReducer.startDate);
      const setStartDate = startDate.toISOString();
      BodyToPost.procurementStartDate = setStartDate;

      const endDate = new Date(procurementReducer.endDate);
      // endDate.setDate(endDate.getDate() + 1);
      const setEndDate = endDate.toISOString();
      BodyToPost.procurementEndDate = setEndDate;

      // 시작 날짜가 종료날짜보다 클 때
      if (procurementReducer.startDate > procurementReducer.endDate) {
        const reverseStartDate = new Date(procurementReducer.startDate);
        // reverseStartDate.setDate(reverseStartDate.getDate() + 1);
        const setReverseStartDate = reverseStartDate.toISOString();

        const reverseEndDate = new Date(procurementReducer.endDate);
        const setReversetEndDate = reverseEndDate.toISOString();

        BodyToPost.procurementStartDate = setReversetEndDate;
        BodyToPost.procurementEndDate = setReverseStartDate;
      }
    }
    if (isPushedSearchButton && checkEmptyNull(procurementReducer.searchOption, false) && checkEmptyNull(procurementReducer.searchText, false)) {
      BodyToPost[procurementReducer.searchOption] = procurementReducer.searchText;
    }

    await procurementApi.searchProcurement(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('procurementApi.searchProcurement : ', response);
      setProcurementList(() => { return response.data.content });
      dispatch(procurementActions_setTotalSize(response.data.totalElements));
    });
  };

  /* ============================================================================================ #5 */
  const handleTitleProcurementStatus = async (procurementStatus) => {
    dispatch(procurementActions_setProcurementStatus(procurementStatus));
    dispatch(procurementActions_setPageNumber(1));
    await getProcurementList(0, procurementStatus);
  }
  const handleCheckedItem = (procurement, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(procurement);
    setCheckedItem(() => { return checkedItem });
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(procurementActions_setPageNumber(1));
    dispatch(procurementActions_setIsPushedSearchButton(true));
    await getProcurementList(0, procurementReducer.procurementStatus, true);
  };

  // 발주상태
  const handleProcurementStatus = async (procurement, index) => {
    const procurementIndex = index;

    let eventStatusText = '';
    let eventStatus = '';
    switch (procurement.procurementStatus) {
      case 'waiting':
        eventStatusText = '진행';
        eventStatus = 'proceeding';
        break;

      case 'proceeding':
        eventStatusText = '완료';
        eventStatus = 'end';
        break;

      case 'incomplete':
        eventStatusText = '완료';
        eventStatus = 'end';
        break;

      default: return;
    };
    const BodyToPut = {
      ...procurement,
      companyId: userReducer.company.companyId,
      userId: userReducer.user.id,
      accountId: procurement?.account?.accountId,
      procurementStatus: eventStatus,
      // procurementElementList: [],
      procurementElementList: procurement.procurementElementList.map((element) => {
        const returnData = {
          procurementElementId: element.procurementElementId,
          elementId: checkNullObject(element.element, false) ? element.element.elementId : checkEmptyNull(element.elementId, false) ? element.elementId : null,
          quantity: element.quantity, // 발주 수량
          price: element.price,
          storeQuantity: checkEmptyNull(element.storeQuantity, 0), // 미입고 수량
          savedQuantity: 0, // 입고 수량
          customizedContent: element.customizedContent,
        };
        return returnData;
      }),
      deleteProcurementElementList: [],
      isCustom: true, // 경서 > 커스텀 표시
    };

    // confirm
    const confirmText = `발주를 ${eventStatusText}시키겠습니까?`;
    if (window.confirm(confirmText) === true) {
      switch (eventStatus) {
        case 'end':
          let notInStockQuantity = 0; // 미입고 수량 확인
          procurement.procurementElementList.forEach((element) => {
            const storeQuantity = checkEmptyNull(element.storeQuantity, 0); // 미입고 수량
            if (storeQuantity > 0) notInStockQuantity++;
          });
          switch (notInStockQuantity > 0) {
            case true:
              const confirmText = `
                미입고된 제품이 있습니다. 
                추가 입고하시겠습니까?
                `;
              switch (window.confirm(confirmText)) {
                case true:
                  setEventProcurement(() => { return { procurementData: procurement, index: procurementIndex } });
                  setTimeout(setNotInStockModalStatus(true), 1000);
                  break;

                default:
                  await procurementApi.updateProcurement(procurement.procurementId, BodyToPut).then((response) => {
                    if (response === undefined) return;
                    console.log('procurementApi.updateProcurement : ', response);

                    setProcurementList((prev) => {
                      const returnData = [...prev];
                      returnData.splice(procurementIndex, 1, response.data);
                      return returnData;
                    });
                  })
                  break;
              }
              break;

            default:
              await procurementApi.updateProcurement(procurement.procurementId, BodyToPut).then((response) => {
                if (response === undefined) return;
                console.log('procurementApi.updateProcurement : ', response);

                setProcurementList((prev) => {
                  const returnData = [...prev];
                  returnData.splice(procurementIndex, 1, response.data);
                  return returnData;
                });
              })
              break;
          }
          break;

        default:
          await procurementApi.updateProcurement(procurement.procurementId, BodyToPut).then((response) => {
            if (response === undefined) return;
            console.log('procurementApi.updateProcurement : ', response);

            setProcurementList((prev) => {
              const returnData = [...prev];
              returnData.splice(procurementIndex, 1, response.data);
              return returnData;
            });
          })
          break;
      }
    } else {
      return;
    }
  };

  // 수정
  const actUpdate = () => {
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return alert('수정할 발주를 선택해주세요.');
    const checkedContent = checkedList[0];
    console.log('checkedContents : ', checkedContent);
    dispatch(procurementActions_setProcurementAccount({}));
    dispatch(procurementActions_setProcurementElementList([]));
    dispatch(contentsReducer_setUpdateContents(checkedContent));
    dispatch(stockReducer_setContractAccount({}));
    dispatch(stockReducer_setContractProducts([]));
    navigate(pageReducer.currentPage + '/update', { replace: true });
  };

  // 삭제
  const actDelete = () => {
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return alert('삭제할 발주를 선택해주세요.');
    const checkedContent = checkedList[0];
    console.log('checkedContents : ', checkedContent);
    dispatch(gsReducer_setDeleteOrder(checkedContent));
    navigate(pageReducer.currentPage + '/delete', { replace: true });
  };

  /* 발주서 */
  const actPrintProcurement = (procurement, index) => {
    setEventProcurement(() => { return procurement; });
    setTimeout(setPrintPocurementModalStatus(true), 1000);
  };

  // 리스트
  const actShowList = (procurement, index) => {
    setSelectedProcurement(() => { return procurement; });
    setTimeout(setProcurementEndListStatus(true), 1000);
  };

  // 부분 입고
  const actIncoming = (procurement, index) => {
    setSelectedProcurement(() => { return procurement; });
    setTimeout(setProcurementListStatus(true), 1000);
  };

  /* 미입고 추가 */
  const actCreateNotInStock = (procurement, index) => {
    setEventProcurement(() => {return { procurementData: procurement, index: index };});
    setTimeout(setNotInStockModalStatus(true), 1000);
  };

  /* ============================================================================================ #6 */

  return (
    <Grid3Body contents={
      <>
        <Section className="Main">
          <NavBar
            title={
              <div className='Title'>
                <NavTitle menuCode={'202'} />
                <div className='statusButtons'>
                  {[
                    { status: 'all', name: '전체' },
                    { status: 'waiting', name: '대기' },
                    { status: 'proceeding', name: '진행' },
                    { status: 'incomplete', name: '미입고 완료' },
                    { status: 'end', name: '완료' },
                    { status: 'cancel', name: '취소' },
                  ].map((procurementStatus, index) => {
                    return (
                      <button key={index + '_statusButton'}
                        className={procurementReducer.procurementStatus === procurementStatus.status ? 'btn-inven-1' : 'btn-set'}
                        onClick={() => { handleTitleProcurementStatus(procurementStatus.status); }}
                      >
                        {procurementStatus.name}
                      </button>
                    )
                  })}
                </div>
              </div>
            }
            buttons={
              <>
                {_authority.indexOf('202-2') !== -1 ? (
                  <>
                    <button className='btn-add' onClick={(e) => {
                      e.preventDefault();
                      dispatch(procurementActions_setProcurementAccount({}));
                      dispatch(procurementActions_setProcurementElementList([]));
                      dispatch(stockReducer_setContractAccount({}));
                      dispatch(stockReducer_setContractProducts([]));
                      setTimeout(navigate(pageReducer.currentPage + '/create', { replace: true }), 1000);
                    }}
                    >
                      추가
                    </button>
                    <button className='btn-edit' onClick={actUpdate}>수정</button>
                    <button className='btn-delete' onClick={actDelete}>삭제</button>
                  </>
                ) : null}
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <div className="SearchSection">
                    <input
                      type="date"
                      className="SelectDate"
                      value={procurementReducer.startDate}
                      onChange={(e) => { dispatch(procurementActions_setStartDate(e.target.value)) }}
                    />

                    <IconRightArrow />

                    <input
                      type="date"
                      className="SelectDate"
                      value={procurementReducer.endDate}
                      onChange={(e) => { dispatch(procurementActions_setEndDate(e.target.value)) }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <div className="ContentCBox">
                      <select className="SearchOption" onChange={(e) => { dispatch(procurementActions_setSearchOption(e.target.value)) }}>
                        {[
                          { optionValue: '', optionText: '검색옵션' },
                          { optionValue: 'procurementCode', optionText: '발주코드' },
                          { optionValue: 'procurementName', optionText: '발주이름' },
                          { optionValue: 'accountName', optionText: '거래처명' },
                        ].map((thisItem) => {
                          return (<option key={thisItem.optionValue + '_procurementSearchOption'} value={thisItem.optionValue}>{thisItem.optionText}</option>
                          );
                        })}
                      </select>
                      <input
                        className="SearchBar"
                        placeholder="Search..."
                        value={procurementReducer.searchText}
                        onInput={(e) => { dispatch(procurementActions_setSearchText(e.target.value)) }}
                      />
                    </div>

                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton"
                      onClick={() => {
                        dispatch(procurementActions_setReset());
                        getProcurementList(0, 'all', false);
                      }}
                    >
                      초기화
                    </div>
                  </div>
                </div>
              </>
            }
          />

          <TableSection content={
            <StockTable>
              <thead>
                <tr>
                  {_authority.indexOf('202-2') !== -1 ? (<th style={{ minWidth: '50px', width: '50px' }}></th>) : null}
                  <th>발주상태</th>
                  {/* <th style={{ maxWidth: '70px', minWidth: '70px', padding: 'unset', width: '70px' }}>발주서</th> */}
                  <th style={{ minWidth: '100px', width: '100px' }}>리스트</th>
                  <th>거래처</th>
                  <th>발주코드</th>
                  <th>발주이름</th>
                  <th>발주총금액</th>
                  <th>발주일자</th>
                  <th>발주입고예정일자</th>
                </tr>
              </thead>

              <tbody>
                {!checkNullArray(_procurementList, false) ? LoadingMsg() :
                  checkNullArray(_procurementList, []).map((procurement, index) => {
                    return (
                      <tr key={index + '_procurement'} data-key={procurement.procurementId}>
                        {_authority.indexOf('202-2') !== -1 ? (
                          <td style={{ minWidth: '50px', width: '50px' }}>
                            <input
                              type="checkBox"
                              name="procurements"
                              data-key={procurement.procurementId}
                              checked={(() => {
                                const checkedIndex = checkNullArray(_checkedItem, false) ? Array.from(_checkedItem).findIndex((item) => item.procurementId === procurement.procurementId) : -1;
                                if (checkedIndex !== -1) return true;
                                else return false;
                              })()}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                handleCheckedItem(procurement, isChecked);
                              }}
                              onClick={(e) => { e.stopPropagation(); }}
                            />
                          </td>
                        ) : null}
                        <StatusTdButton
                          statusStyle={{ minWidth: '120px', width: '120px' }}
                          status={procurement.procurementStatus}
                          statusEvent={(e) => {
                            e.stopPropagation();
                            handleProcurementStatus(procurement, index);
                          }}
                        />
                        <td style={{ height: '120px', minWidth: '100px', padding: '5px 0px', width: '100px' }}>
                          <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', rowGap: '2px' }}>
                            <ListButton type="list" onClick={(e) => { e.stopPropagation(); actShowList(procurement, index); }}>물품 리스트</ListButton>
                            {(() => {
                              if (procurement.procurementStatus === 'proceeding' || procurement.procurementStatus === 'incomplete' || procurement.procurementStatus === 'end') {
                                return (
                                  <Fragment>
                                    {procurement.procurementStatus === 'proceeding' ? (<ListButton type="inStock" onClick={(e) => { e.stopPropagation(); actIncoming(procurement, index); }}>부분입고</ListButton>) : null}
                                    {procurement.procurementStatus === 'incomplete' ? (<ListButton type="notInStock" onClick={(e) => { e.stopPropagation(); actCreateNotInStock(procurement, index); }}>미입고</ListButton>) : null}
                                  </Fragment>
                                );
                              } else return null;
                            })()}
                          </div>
                        </td>
                        <td>{procurement?.account?.accountName}</td>
                        <td>{procurement.procurementCode}</td>
                        <td>{procurement.procurementName}</td>
                        <td>{checkEmptyNull(procurement.procurementPrice, 0).toLocaleString()}</td>
                        <td>{DateFormat(procurement.procurementDate)}</td>
                        <td>{DateFormat(procurement.scheduledEndDate)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </StockTable>
          }
          ></TableSection>
        </Section>

        <PagingComponent
          page={procurementReducer.pageNumber}
          count={procurementReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(procurementActions_setPageNumber(page));
          }}
        />

        {/* 발주서 */}
        {_printProcurementModalStatus === true && (
          <ProcurementPdfModal
            procurement={_eventProcurement}
            open={_printProcurementModalStatus}
            close={() => { setPrintPocurementModalStatus(false) }}
          ></ProcurementPdfModal>
        )}

        {/* 경서> 물품 리스트 */}
        {_procurementEndListStatus === true && (
          <GSProcurementElementList
            content={_selectedProcurement}
            open={_procurementEndListStatus}
            close={() => { 
              setProcurementEndListStatus(false); 
              getProcurementList(procurementReducer.pageNumber - 1, procurementReducer.procurementStatus, procurementReducer.isPushedSearchButton);
            }}
          ></GSProcurementElementList>
        )}
        {/* 경서> 부분 입고 */}
        {_procurementListStatus === true && (
          <GSProcurementIncoming
            content={_selectedProcurement}
            open={_procurementListStatus}
            close={() => {
              setProcurementListStatus(false);
              getProcurementList(procurementReducer.pageNumber - 1, procurementReducer.procurementStatus, procurementReducer.isPushedSearchButton);
            }}
          ></GSProcurementIncoming>
        )}
        {/* 미입고 */}
        {_notInStockModalStatus === true && (
          <GSNotInStock
            content={_eventProcurement.procurementData}
            open={_notInStockModalStatus}
            close={() => {
              setNotInStockModalStatus(false)
              getProcurementList(procurementReducer.pageNumber - 1, procurementReducer.procurementStatus, procurementReducer.isPushedSearchButton);
            }}
          ></GSNotInStock>
        )}
      </>
    }
    />
  );
};

export default GSProcurement;
