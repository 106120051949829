export const mainpageMenu = (clusterPath) => {
  return {
    title: '공동 플랫폼',
    link: `${clusterPath}`,
    children: [
      {
        title: '장비 공유',
        group: 'equipment',
        children: [
          { title: '장비 요청', link: `${clusterPath}/equipment/request` },
          { title: '공유 장비 목록', link: `${clusterPath}/equipment/share` },
        ],
      },
      {
        title: '인력 공유',
        group: 'people',
        children: [
          { title: '인력 요청', link: `${clusterPath}/people/request` },
          { title: '공유 인력 목록', link: `${clusterPath}/people/share` },
        ],
      },
      {
        title: '공동 거래',
        group: 'common',
        children: [
          { title: '공동 구매', link: `${clusterPath}/common/purchase` },
          { title: '상호 거래', link: `${clusterPath}/common/sales` },
        ],
      },
      {
        title: '게시판',
        group: 'board',
        children: [
          { title: '게시판', link: `${clusterPath}/board/public` },
        ],
      },
    ]
  };
};

export const mypageMenu = (clusterPath) => {
  return {
    title: '마이 페이지',
    link: `${clusterPath}`,
    children: [
      {
        title: '공유 장비',
        group: 'equipment',
        children: [
          { title: '요청 장비', link: `${clusterPath}/equipment/request` },
          { title: '공유 장비', link: `${clusterPath}/equipment/manage` },
          {
            title: '장비 설정',
            group: 'equipment/setting',
            children: [
              { title: '장비 유형', link: `${clusterPath}/equipment/setting/type` },
              { title: '장비 속성', link: `${clusterPath}/equipment/setting/attributes` },
            ],
          },
        ],
      },
      {
        title: '공유 인력',
        group: 'people',
        children: [
          { title: '요청 인력', link: `${clusterPath}/people/request` },
          { title: '공유 인력', link: `${clusterPath}/people/manage` },
          {
            title: '인력 설정',
            group: 'people/setting',
            children: [
              { title: '인력 유형', link: `${clusterPath}/people/setting/type` },
              { title: '인력 속성', link: `${clusterPath}/people/setting/attributes` },
            ],
          },
        ],
      },
      {
        title: '내 게시글',
        group: 'board',
        children: [
          { title: '게시판', link: `${clusterPath}/board/public` },
          { title: '공동 구매', link: `${clusterPath}/board/purchase` },
          { title: '상호 거래', link: `${clusterPath}/board/sales` },
        ],
      },
      // { title: '내 정보'/* , group: 'mypage' */, link: `${clusterPath}` },
    ]
  };
}