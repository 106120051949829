export const clusterActions_setClusterData = (data) => { return { type: 'SETCLUSTERDATA', payload: data }; };
export const clusterActions_setCompanyData = (data) => { return { type: 'SETCLUSTERCOMPANYDATA', payload: data }; };
export const clusterActions_setUserData = (data) => { return { type: 'SETCLUSTERUSERDATA', payload: data }; };
export const clusterActions_setOpenMenus = (data) => { return { type: 'SETCLUSTEROPENMENUS', payload: data }; };

export const clusterActions_setCompanyList = (data) => { return { type: 'SETCLUSTERCOMPANYLIST', payload: data }; };

export const clusterActions_setMyPage = (data) => { return { type: 'SETCLUSTERMYPAGE', payload: data }; };

export const clusterActions_setSearchData = (data) => { return { type: 'SETCLUSTERSEARCHDATA', payload: data }; };
export const clusterActions_setPageNumber = (data) => { return { type: 'SETCLUSTERPAGENUMBER', payload: data }; };
export const clusterActions_setTotalSize = (data) => { return { type: 'SETCLUSTERTOTALSIZE', payload: data }; };

export const clusterActions_reset = (data) => { return { type: 'SETCLUSTERUSERRESET', payload: data }; };
