// 웹소켓 핸들러
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { chatReducer_setChatConnectStatus, chatReducer_setChatList, chatReducer_setChatRoomRead } from 'store/modules/actions/default/chatActions';
import { userReducer_resetUserSubscribe, userReducer_setUserSubscribe } from 'store/modules/actions/user/userActions';
import { workActions_setConnectStatus, workActions_setCurrentMessage } from 'store/modules/actions/default/workActions';

import { getCookie } from 'preferences/cookie/cookie';
import { WS_CLIENT } from 'preferences/server/constants';

import { checkNull, checkNullObject } from 'components/checkValues/checkValues';

export const WSHandler = () => {
  const dispatch = useDispatch();
  const { chatReducer, pageReducer, userReducer } = useSelector((state) => state);

  const token = checkNull(getCookie('accessToken'), false);

  const [_onload, setOnload] = useState('unload');

  WS_CLIENT.onConnect = () => {
    console.log('** onConnect **');
    setOnload('onload');
    WSConnect();
  };

  WS_CLIENT.onDisconnect = () => {
    console.log('** onDisconnect **');
    setOnload('unload');
    WSConnect();
  };

  WS_CLIENT.onStompError = () => {
    console.log('** onStompError **');
    WSConnect();
  };

  WS_CLIENT.onWebSocketClose = () => {
    console.log('** onWebSocketClose **');
    setOnload('unload');
    WSConnect();
  };

  /* connect */
  const WSConnect = () => {
    if(!checkNullObject(userReducer.user, false) || userReducer.user?.role === 'ROLE_GUEST') return;
    if (!WS_CLIENT.active) {
      // 활성화 안 되어 있을 때 활성화
      dispatch(chatReducer_setChatConnectStatus('disconnect'));
      dispatch(workActions_setConnectStatus('disconnect'));
      dispatch(userReducer_resetUserSubscribe());
      return WS_CLIENT.activate();
    }
    if (!WS_CLIENT.connected) {
      dispatch(chatReducer_setChatConnectStatus('disconnect'));
      dispatch(workActions_setConnectStatus('disconnect'));
      dispatch(userReducer_resetUserSubscribe());
    } else {
      setOnload('onload');
      if (_onload !== 'onload') return;
      const subscriptions = WS_CLIENT._stompHandler._subscriptions;
      if (Object.keys(subscriptions).length === 0) {
        // 구독 안 되어 있을 때
        console.log('not subscribed!');
        dispatch(chatReducer_setChatConnectStatus('disconnect'));
        dispatch(workActions_setConnectStatus('disconnect'));
        chat_subscribe(userReducer.company.companyId, token);
        dispatch(chatReducer_setChatConnectStatus('connect'));
        return;
      } else if (Object.keys(subscriptions).length === 1) {
        console.log('subscribing...');
        if (subscriptions['sub-0'].name.includes("chat")) {
          work_subscribe(userReducer.company.companyId, token);
          dispatch(workActions_setConnectStatus('connect'));
        } else {
          chat_subscribe(userReducer.company.companyId, token);
          dispatch(chatReducer_setChatConnectStatus('connect'));
        }
      } else if (Object.keys(WS_CLIENT._stompHandler._subscriptions).length === 2) {
        if (subscriptions['sub-0'].name.includes("chat")) {
          if (!subscriptions['sub-1'].name.includes("work")) {
            console.log('subscribing...');
            dispatch(workActions_setConnectStatus('disconnect'));
            WS_CLIENT.unsubscribe('sub-1');
            work_subscribe(userReducer.company.companyId, token);
            dispatch(workActions_setConnectStatus('connect'));
          } else {
            console.log('subscribed!');
          }
        }
      } else if (Object.keys(WS_CLIENT._stompHandler._subscriptions).length > 2) {
        for (let i = 2; i < Object.keys(WS_CLIENT._stompHandler._subscriptions).length; i++) {
          const id = 'sub-' + i;
          WS_CLIENT.unsubscribe(id);
        }
      }
    }
  };

  useEffect(() => {
    if (!token) {
      dispatch(chatReducer_setChatConnectStatus('disconnect'));
      dispatch(workActions_setConnectStatus('disconnect'));
    } else {
      return WSConnect();
    }
  }, [token]);
  useEffect(() => {
    if (!token) {
      dispatch(chatReducer_setChatConnectStatus('disconnect'));
      dispatch(workActions_setConnectStatus('disconnect'));
    } else {
      return WSConnect();
    }
  }, [userReducer.user]);
  useEffect(() => {
    if (_onload !== 'onload') return;
    if (!token) {
      dispatch(chatReducer_setChatConnectStatus('disconnect'));
      dispatch(workActions_setConnectStatus('disconnect'));
    } else {
      return WSConnect();
    }
  }, [WS_CLIENT.connected]);
  useEffect(() => {
    if (_onload !== 'onload') return;
    if (!token) {
      dispatch(chatReducer_setChatConnectStatus('disconnect'));
      dispatch(workActions_setConnectStatus('disconnect'));
    } else {
      console.log('WSHandler - pageReducer.currentPage : ', pageReducer.currentPage);
      return WSConnect();
    }
  }, [pageReducer.currentPage]);
  useEffect(() => {
    if (_onload !== 'onload') return;
    if (!token) {
      dispatch(chatReducer_setChatConnectStatus('disconnect'));
      dispatch(workActions_setConnectStatus('disconnect'));
    } else {
      return WSConnect();
    }
  }, [userReducer.subscribe]);

  /* chat */
  const chat_subscribe = (roomId, token) => {
    const subscribedId = WS_CLIENT.subscribe('/sub/chat/room/' + roomId, chat_callback, { 'auth-token': token.replace('Bearer ', '') });
    dispatch(userReducer_setUserSubscribe(subscribedId)); // userActions에서 array에 추가
  };

  const chat_callback = (message) => {
    console.log('** chat_callback **');
    const messageId = message.headers['message-id'];
    const content = JSON.parse(message.body);
    console.log({
      room: 'chat',
      messageId: messageId,
      content: content,
    });

    if (!checkNullObject(content, false)) return;

    const chatIndex = chatReducer.chatList.findIndex((chat) => chat.messageId === messageId);
    if (chatIndex !== -1) return;
    const chatMessage = {
      ...content,
      messageId: messageId,
    };
    dispatch(chatReducer_setChatList(chatMessage)); // chatActions에서 array에 추가

    if (chatReducer.chatRoomOpen === 'close') {
      dispatch(chatReducer_setChatRoomRead('unread'));
    }
  };

  /* work */
  const work_subscribe = (roomId, token) => {
    const subscribedId = WS_CLIENT.subscribe('/sub/work/room/' + roomId, work_callback, { 'auth-token': token.replace('Bearer ', '') });
    dispatch(userReducer_setUserSubscribe(subscribedId));
  };

  const work_callback = (message) => {
    console.log('** work_callback **');
    const messageId = message.headers['message-id'];
    const content = JSON.parse(message.body);
    console.log({
      room: 'work',
      messageId: messageId,
      content: content,
    });

    if (!checkNullObject(content, false)) return;

    const workMessage = {
      ...content,
      messageId: messageId,
    };
    dispatch(workActions_setCurrentMessage(workMessage));
  };

  return (<></>);
};