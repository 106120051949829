import React, { Fragment, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import Bookmark from 'components/icons/Bookmark';
import DOCButtonIcon from 'components/icons/DOCButtonIcon';

const CreateButton = styled.td`
  width: 100px;

  div {
    background-color: var(--MainBlue);
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(196,196,196,0.8);
    cursor: pointer;
    color: var(--white);
    font-size: 1rem;
    padding: 16px;
    vertical-align: middle;
    width: inherit;

    &:hover {opacity: .7;}
  }
`;

const ProcessTable = styled.table`
  border-collapse: collapse;
  height: 100%;
  min-width: 100%;
  table-layout: fixed;

  td, th {
    box-sizing: border-box;
    font-size: 14px;
    min-width: 150px !important;
    padding: 5px 15px;
    text-align: center;
    white-space: normal;
    word-break: break-all;
  }
  td.blankCell {background-color: var(--Bg); opacity: 0.8;}
`;

const ProcessRow = (workOrderProcessList) => {
  const [_workOrderProcessList, setWorkOrderProcessList] = useState([]);
  const [_putElements, setPutElements] = useState([]);
  const [_makeElements, setMakeElements] = useState([]);

  useEffect(() => {
    setWorkOrderProcessList(() => { return workOrderProcessList });
  }, [workOrderProcessList])

  return (
    <table>
      <thead>
        <tr>
          <th rowSpan={2}>공정유형</th>
          <th rowSpan={2}>공정코드</th>
          <th rowSpan={2}>공정이름</th>
          <th rowSpan={2}>작업위치</th>
          <th rowSpan={2}>비고</th>
          <th colSpan={3} style={{ backgroundColor: 'var(--ThirdBlue)' }}>공정완료제품</th>
          <th rowSpan={2} style={{ minWidth: '120px', width: '120px' }}>투입<br />자재/반제품</th>
        </tr>
        <tr>
          <th>물품이름</th>
          <th>물품코드</th>
          <th>생산량</th>
        </tr>
      </thead>
      <tbody>
        {checkNullArray(workOrderProcessList, []).map((workOrderProcess, index) => {
          return (
            <tr key={index + '_workOrderProcess'}>
              <td></td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const WorkOrderRow = (props) => {
  /* ====================================================================== #1 */
  /* ====================================================================== #2 */
  const [WorkOrderData, setWorkOrderData] = useState(props.content);
  const [ProcessData, setProcessData] = useState(WorkOrderData.workOrderProcessList);
  const [_processLength, setProcessLength] = useState(WorkOrderData.workOrderProcessList.length);
  const [_availableAmount, setAvailableAmount] = useState(0);

  const [_viewContent, setViewContent] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    setWorkOrderData(() => { return props.content });
    setProcessData(() => { return props.content.workOrderProcessList });
    setProcessLength(() => { return props.content.workOrderProcessList.length });

    const processLogElementList = props.content.workOrderProcessList.map((thisItem) => thisItem.process.processElementList);
    const setProcessLogElementList = processLogElementList.flat();
    actCalMinAmount(setProcessLogElementList);

    setViewContent(() => { return props.viewContent });
  }, [props]);

  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const actCalMinAmount = (elementList) => {
    const calMinAmount = elementList.map((thisItem) => {
      if (!checkNullObject(thisItem, false) ||
        thisItem.processElementType === 'Out' || thisItem.processElementType === 'Make' ||
        thisItem.processLogElementType === 'Out' || thisItem.processLogElementType === 'Make'
      ) return null;

      const realAmount = BigNumber(checkEmptyNull(thisItem.elementStock, 0)).minus(BigNumber(checkEmptyNull(thisItem.scheduledInputStock, 0))).toNumber();
      const putAmount = BigNumber(1).multipliedBy(BigNumber(checkEmptyNull(thisItem.amount, 0))).toNumber();
      const result = BigNumber(isNaN(realAmount) ? 0 : realAmount).dividedBy(BigNumber(isNaN(putAmount) ? 0 : putAmount)).toNumber();

      return result;
    });
    const minAmount = Math.min.apply(null, calMinAmount.filter((thisItem) => thisItem !== null));
    setAvailableAmount(() => {
      return !isNaN(Math.floor(minAmount)) && Math.floor(minAmount) !== Infinity && Math.floor(minAmount) > 0
        ? Math.floor(minAmount)
        : 0;
    });
  };

  /* ====================================================================== #6 */
  const returnItems = () => {
    let returnArray = [];
    for (let i = 1; i < _processLength; i++) {
      const processData = { ...ProcessData[i], ...checkNullObject(ProcessData[i].process, {}) };
      returnArray.push(
        <tr key={i + '_processRows'}>
          {checkNullObject(ProcessData[i], false) && checkNullObject(ProcessData[i].process, false) ? (
            <Fragment>
              <td className={ProcessData[i].target && 'targetCell'} style={{ borderLeft: '1px solid var(--gray-200)', minWidth: '80px', width: '80px' }}>
                {ProcessData[i].processNumber}
              </td>
              {(() => {
                if (props.authority.indexOf('109-1') !== -1 || props.authority.indexOf('109-2') !== -1) {
                  if (checkNullArray(processData.attachFileList, false)) {
                    return (
                      <td className='table-preview'>
                        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            props.showFileList(processData);
                          }}
                        >
                          <img src={processData.attachFileList[0]?.fileData?.fileDataS3URL || ''} alt='image preview' className='img-preview' />
                          <div className='btn-file'><DOCButtonIcon /></div>
                        </div>
                      </td>
                    );
                  } else {
                    return (
                      <td className={'table-preview'}>
                        <div
                          style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            props.showFileList(ProcessData);
                          }}
                        >
                          <div className='no-image'><p>이미지가 없습니다.</p></div>
                          <div className='btn-file'><DOCButtonIcon /></div>
                        </div>
                      </td>
                    );
                  }
                } else {
                  return null;
                }
              })()}
              <td>{ProcessData[i].process.processTypeName}</td>
              <td>{ProcessData[i].process.processCode}</td>
              <td>{ProcessData[i].process.processName}</td>
              <td>{ProcessData[i].process.place?.placeName}</td>
              <td>{checkEmptyNull(ProcessData[i].process.remark, '')}</td>
              <td style={{ backgroundColor: 'var(--Bg)', borderLeft: '1px solid var(--gray-200)', padding: 'unset', verticalAlign: 'top' }}>
                <ProcessTable>
                  <tbody>
                    {(() => {
                      const ProcessMakeItems = ProcessData[i].process.processElementList.filter((thisEl) => thisEl.processElementType === 'Make');
                      return ProcessMakeItems.map((thisEl) => {
                        return (
                          <tr key={thisEl.processElementId + '_makeItem'}>
                            <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementName, '') : checkEmptyNull(thisEl.elementName, '')}</td>
                            <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementCode, '') : checkEmptyNull(thisEl.elementCode, '')}</td>
                            <td>{checkEmptyNull(thisEl.amount, 0).toLocaleString()}</td>
                            {/* <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.scheduledInputStock, 0).toLocaleString() : checkEmptyNull(thisEl.scheduledInputStock, 0).toLocaleString()}</td> */}
                            <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementStock, 0).toLocaleString() : checkEmptyNull(thisEl.elementStock, 0).toLocaleString()}</td>
                          </tr>
                        );
                      });
                    })()}
                  </tbody>
                </ProcessTable>
              </td>
              <td style={{ backgroundColor: 'var(--Bg)', borderLeft: '1px solid var(--gray-200)', padding: 'unset', verticalAlign: 'top' }}>
                <ProcessTable>
                  <tbody>
                    {(() => {
                      const ProcessPutItems = ProcessData[i].process.processElementList.filter((thisEl) => thisEl.processElementType === 'Put');
                      return ProcessPutItems.map((thisEl) => {
                        return (
                          <tr key={thisEl.processElementId + '_putItem'}>
                            <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementName, '') : checkEmptyNull(thisEl.elementName, '')}</td>
                            <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementCode, '') : checkEmptyNull(thisEl.elementCode, '')}</td>
                            <td>{checkEmptyNull(thisEl.amount, 0).toLocaleString()}</td>
                            {/* <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.scheduledInputStock, 0).toLocaleString() : checkEmptyNull(thisEl.scheduledInputStock, 0).toLocaleString()}</td> */}
                            <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementStock, 0).toLocaleString() : checkEmptyNull(thisEl.elementStock, 0).toLocaleString()}</td>
                          </tr>
                        );
                      });
                    })()}
                  </tbody>
                </ProcessTable>
              </td>
            </Fragment>
          ) : (
            <Fragment>
              <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
            </Fragment>
          )}
        </tr>
      );
    }

    return returnArray;
  };

  return (
    <Fragment>
      <tr key={WorkOrderData.workOrderId} name={props.authority.indexOf('111-2') !== -1 && 'workOrderRows'}
        onClick={(e) => { if (props.authority.indexOf('111-2') !== -1) props.goDetails(WorkOrderData); }}
      >
        {(props.authority.indexOf('111-2') !== -1) ? (
          <td rowSpan={_processLength === 0 ? 1 : _processLength} style={{ minWidth: '50px', width: '50px' }} name="checkBoxCell">
            <input
              type="checkBox"
              name="workOrder"
              data-key={WorkOrderData.workOrderId}
              onChange={(e) => {
                const isChecked = e.target.checked;
                props.handleCheckedItem(WorkOrderData.workOrderId, isChecked);
                if (isChecked) e.target.checked = true;
                else e.target.checked = false;
              }}
              onClick={(e) => { e.stopPropagation() }}
            />
          </td>
        ) : null}
        <td rowSpan={_processLength === 0 ? 1 : _processLength} style={{ minWidth: '50px', width: '50px' }}>
          <Bookmark bookmark={checkEmptyNull(WorkOrderData.bookMark, false)} clickEvent={() => { props.handleBookmark(WorkOrderData, props.index) }} />
        </td>
        {(props.authority.indexOf('111-2') !== -1) ? (
          <CreateButton
            style={{ minWidth: '100px', width: '100px' }}
            rowSpan={_processLength === 0 ? 1 : _processLength}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.createWorkOrderLog(WorkOrderData);
            }}
          >
            <div>추가</div>
          </CreateButton>
        ) : null}
        {(props.authority.indexOf('109-1') !== -1 || props.authority.indexOf('109-2') !== -1) ? (
          <>
            {(checkNullArray(WorkOrderData.attachFileList, false)) ? (
              <td rowSpan={_processLength === 0 ? 1 : _processLength}>
                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.showFileList(WorkOrderData, true);
                  }}
                >
                  <img src={WorkOrderData.attachFileList[0]?.fileData?.fileDataS3URL || ''} alt='image preview' className='img-preview' />
                  <div className='btn-file'><DOCButtonIcon /></div>
                </div>
              </td>
            ) : (
              <td rowSpan={_processLength === 0 ? 1 : _processLength}>
                <div
                  style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.showFileList(WorkOrderData, true);
                  }}
                >
                  <div className='no-image'><p>이미지가 없습니다.</p></div>
                  <div className='btn-file'><DOCButtonIcon /></div>
                </div>
              </td>
            )}
          </>
        ) : null}
        <td rowSpan={_processLength === 0 ? 1 : _processLength}>{WorkOrderData.workOrderTypeName}</td>
        <td rowSpan={_processLength === 0 ? 1 : _processLength}>{WorkOrderData.workOrderCode}</td>
        <td rowSpan={_processLength === 0 ? 1 : _processLength}>{WorkOrderData.workOrderName}</td>
        {(props.authority.indexOf('104-1') !== -1 || props.authority.indexOf('104-2') !== -1) ? (
          <td rowSpan={_processLength === 0 ? 1 : _processLength}>
            {checkNullObject(WorkOrderData.account, false) ? checkEmptyNull(WorkOrderData.account.accountName, '') : ''}
          </td>
        ) : null}
        <td rowSpan={_processLength === 0 ? 1 : _processLength}>{WorkOrderData.place?.placeName}<br />{WorkOrderData.workPlace}</td>
        <td rowSpan={_processLength === 0 ? 1 : _processLength}>{WorkOrderData.remark}</td>
        <td rowSpan={_processLength === 0 ? 1 : _processLength}>{checkEmptyNull(_availableAmount, 0).toLocaleString()}</td>

        {checkNullObject(ProcessData[0], false) && checkNullObject(ProcessData[0].process, false) ? (
          <Fragment>
            <td className={ProcessData[0].target && 'targetCell'} style={{ borderLeft: '1px solid var(--gray-200)', minWidth: '80px', width: '80px' }}>
              {ProcessData[0].processNumber}
            </td>
            {(props.authority.indexOf('109-1') !== -1 || props.authority.indexOf('109-2') !== -1) ? (
              <>
                {(checkNullArray(ProcessData[0].attachFileList, false)) ? (
                  <td className={'table-preview'}>
                    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.showFileList({ ...ProcessData[0], ...ProcessData[0].process });
                      }}
                    >
                      <img src={ProcessData[0].attachFileList[0]?.fileData?.fileDataS3URL || ''} alt='image preview' className='img-preview' />
                      <div className='btn-file'><DOCButtonIcon /></div>
                    </div>
                  </td>
                ) : (
                  <td className={'table-preview'}>
                    <div
                      style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.showFileList({ ...ProcessData[0], ...ProcessData[0].process });
                      }}
                    >
                      <div className='no-image'><p>이미지가 없습니다.</p></div>
                      <div className='btn-file'><DOCButtonIcon /></div>
                    </div>
                  </td>
                )}
              </>
            ) : null}
            <td>{ProcessData[0].process.processTypeName}</td>
            <td>{ProcessData[0].process.processCode}</td>
            <td>{ProcessData[0].process.processName}</td>
            <td>{ProcessData[0].process.place?.placeName}</td>
            <td>{checkEmptyNull(ProcessData[0].process.remark, '')}</td>
            <td style={{ backgroundColor: 'var(--Bg)', borderLeft: '1px solid var(--gray-200)', padding: 'unset', verticalAlign: 'top' }}>
              <ProcessTable>
                <tbody>
                  {(() => {
                    const ProcessMakeItems = ProcessData[0].process.processElementList.filter((thisEl) => thisEl.processElementType === 'Make');
                    return ProcessMakeItems.map((thisEl) => {
                      return (
                        <tr key={thisEl.processElementId + '_makeItem'}>
                          <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementName, '') : checkEmptyNull(thisEl.elementName, '')}</td>
                          <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementCode, '') : checkEmptyNull(thisEl.elementCode, '')}</td>
                          <td>{checkEmptyNull(thisEl.amount, 0).toLocaleString()}</td>
                          <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementStock, 0).toLocaleString() : checkEmptyNull(thisEl.elementStock, 0).toLocaleString()}</td>
                        </tr>
                      );
                    });
                  })()}
                </tbody>
              </ProcessTable>
            </td>
            <td style={{ backgroundColor: 'var(--Bg)', borderLeft: '1px solid var(--gray-200)', padding: 'unset', verticalAlign: 'top' }}>
              <ProcessTable>
                <tbody>
                  {(() => {
                    const ProcessPutItems = ProcessData[0].process.processElementList.filter((thisEl) => thisEl.processElementType === 'Put');
                    return ProcessPutItems.map((thisEl) => {
                      return (
                        <tr key={thisEl.processElementId + '_putItem'}>
                          <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementName, '') : checkEmptyNull(thisEl.elementName, '')}</td>
                          <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementCode, '') : checkEmptyNull(thisEl.elementCode, '')}</td>
                          <td>{checkEmptyNull(thisEl.amount, 0).toLocaleString()}</td>
                          <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementStock, 0).toLocaleString() : checkEmptyNull(thisEl.elementStock, 0).toLocaleString()}</td>
                        </tr>
                      );
                    });
                  })()}
                </tbody>
              </ProcessTable>
            </td>
          </Fragment>
        ) : (
          <Fragment>
            <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
          </Fragment>
        )}
      </tr>
      {_processLength > 1 && returnItems()}
      {/* {_processLength > 1 && _viewContent.workOrderId === WorkOrderData.workOrderId ? (
        <ProcessRow workOrderProcessList={ProcessData} />
      ) : null} */}
    </Fragment>
  );
};

export default WorkOrderRow;
