import { AxiosInterceptor } from "api/core/consts";

export const inquiryReplyApi = {
  findAllInquiryReply
    : (inquiryReplyId) => AxiosInterceptor
      .get('/inquiryReply/' + inquiryReplyId),
  searchAllInquiryReply
    : (paging, body) => AxiosInterceptor
      .post('/inquiryReply/search?' + paging, body),
  createInquiryReply
    : (body) => AxiosInterceptor
      .post('/inquiryReply', body),
  modifiedInquiryReply
    : (inquiryReplyId, body) => AxiosInterceptor
      .put('inquiryReply/' + inquiryReplyId, body),
  deleteInquiryReply
    : (inquiryReplyId) => AxiosInterceptor
      .delete('inquiryReply/' + inquiryReplyId),
};
