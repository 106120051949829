export const mallUserActions_setSearchOption = (searchOption) => {
  return {
    type: 'SETMALLUSERSEARCHOPTION',
    payload: searchOption,
  };
};
export const mallUserActions_setSearchText = (searchText) => {
  return {
    type: 'SETMALLUSERSEARCHTEXT',
    payload: searchText,
  };
};
export const mallUserActions_setPageNumber = (pageNumber) => {
  return {
    type: 'SETMALLUSERPAGENUMBER',
    payload: pageNumber,
  };
};
export const mallUserActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETMALLUSERTOTALSIZE',
    payload: totalSize,
  };
};
export const mallUserActions_setIsPushedSearchButton = (
  isPushedSearchButton,
) => {
  return {
    type: 'SETMALLUSERISPUSHEDSEARCHBUTTON',
    payload: isPushedSearchButton,
  };
};
export const mallUserActions_setReset = () => {
  return {
    type: 'SETMALLUSERRESET',
  };
};
