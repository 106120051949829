import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import styled from 'styled-components';
import { QRCodeSVG } from 'qrcode.react';

import { gsReducer_setBarcodeContent } from 'store/modules/actions/company/GSActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import GridNonBody from 'components/layouts/body/GridNonBody';
import GSContractList from 'pages/contract/custom/company/gs/modal/GSContractList';

const MainContents = styled.main`
  box-sizing: border-box;
  column-gap: 10px;
  display: grid;
  grid-template-columns: calc(100% - 150px) 150px;
  height: calc(100% - 60px);
  justify-content: unset;
  padding: 20px;
  width: 100%;
`;

const PrintSection = styled.div`
  background-color: var(--gray-200);
  height: 100%;
  overflow: scroll;
  width: 100%;

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 6px;
  }
`;

const BarcodeSection = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  height: auto;
  width: 100%;
`;

const ButtonSection = styled.div`
  display: grid;
  grid-template-rows: 150px 100px auto;
  row-gap: 10px;
  height: 100%;
`;

const Button = styled.div`
  align-items: center;
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;

  &:first-child {
    background-color: var(--MainNavy);
  }
  &:nth-child(2) {
    background-color: var(--MainRed);
  }
  &:last-child {
    background-color: var(--MainBlue);
  }
`;

const QRCode = styled(QRCodeSVG)`
  height: 15mm;
  justify-self: center;
  margin: 0px auto;
  width: 15mm;

  &.WContent {
    height: 15mm;
    justify-self: flex-end;
    margin: unset;
    /* margin-right: 20px; */
    width: 15mm;
  }
`;

const BarcodeContents = styled.div`
  align-content: flex-start;
  background-color: var(--white);
  box-sizing: border-box;
  column-gap: 2.6924mm;
  display: grid;
  grid-template-columns: repeat(2, 100mm);
  grid-template-rows: repeat(12, 46.4mm);
  justify-content: center;
  overflow: hidden;
  /* padding-left: 3.9878mm; */
  padding-top: 7.493mm;
  width: 210mm;
  height: 297mm;
`;

const BarcodeBox = styled.div`
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--gray-200);
  border-radius: 2.54mm;
  box-sizing: border-box;
  display: flex;
  height: 98%;
  justify-content: center;
  margin: 1% 0px 0px 1%;
  overflow: hidden;
  /* padding: 5px 0px; */
  /* position: relative; */
  /* outline: 1px solid var(--gray-200);
  outline-offset: -2mm; */
  width: 98%;

  &:hover {
    cursor: pointer;
    outline: 2px solid var(--MainRed);
  }

  /* @media ( min-width: 1919px ) {
    padding: 85px;
  } */
`;

const BarcodeWrap = styled.div`
  align-items: center;
  display: grid;
  grid-template-rows: 30px 25px calc(100% - 85px) 30px;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const TopTitle = styled.div`
  align-items: flex-end;
  box-sizing: border-box;
  display: flex;
  /* font-family: 'Nanum Myeongjo', serif; */
  /* font-family: 'Song Myung', serif; */
  /* font-family: 'Noto Serif KR', serif; */
  font-family: 'Do Hyeon', sans-serif;
  font-size: 1em;
  font-weight: 600;
  height: 30px;
  justify-content: center;
  letter-spacing: 2px;
  /* margin: 10px auto 2px auto; */
  /* padding: 5px 0px 2px 0px; */
  text-align: center;
  width: 100%;
`;

const MiddleTitle = styled.div`
  align-items: flex-end;
  column-gap: 10px;
  display: flex;
  height: 25px;
  justify-content: center;
  margin-bottom: 5px;
  width: 100%;

  & h2 {
    font-size: 0.8em;
  }
`;

const LogoBox = styled.div`
  align-items: center;
  column-gap: 5px;
  display: grid;
  grid-template-columns: 5mm auto;
  height: 25px;
  /* justify-content: center; */
  width: auto;
`;

const Logo = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  & img {
    background-color: var(--Bg);
    border-radius: 50%;
    max-height: 5mm;
    max-width: 5mm;
    width: 100%;
  }
`;

const BarcodeCon = styled.div`
  align-items: center;
  /* column-gap: 0px; */
  display: grid;
  /* grid-template-columns: 170px auto; */

  box-sizing: border-box;
  column-gap: 20px;
  /* display: flex; */
  grid-template-columns: 15mm 1fr;
  /* justify-content: center; */
  margin-bottom: 5px;
  padding: 0px 10mm;
  width: 100%;

  &.WContent {
    /* box-sizing: border-box; */
    /* column-gap: 20px; */
    /* grid-template-columns: 15mm 1fr; */
    padding: 0px 12mm;
  }
`;

const Info = styled.div`
  font-size: 0.6em;
  margin: 2px auto;
`;
const BarcodeInfo = styled.div`
  &.WContent ${Info} {
    font-size: 0.6em;
    margin: 2px auto;
  }
`;

const BottomTitle = styled.div`
  align-items: flex-start;
  /* bottom: 3mm; */
  display: flex;
  /* font-family: 'Nanum Myeongjo', serif; */
  /* font-family: 'Song Myung', serif; */
  /* font-family: 'Noto Serif KR', serif; */
  font-family: 'Do Hyeon', sans-serif;
  font-size: 0.8em;
  font-weight: bolder;
  height: 30px;
  justify-content: center;
  left: 0px;
  letter-spacing: 2px;
  margin: 0px auto;
  /* position: absolute; */
  text-align: center;
  /* transform: translateY(1mm); */
  width: 100%;
`;

const LoadingScreen = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  color: var(--white);
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const BarcodeSContent = (props) => {
  const dispatch = useDispatch();
  const { gsReducer } = useSelector((state) => state);

  const thisContent = props.content;
  const thisInfo = thisContent.info;
  const thisPlace =
    thisContent.place !== undefined
      ? JSON.parse(thisContent.place.contractCustomizedContent)
      : { workPlaceName: '' };

  let thisWeight = null;
  let thisLength = null;
  if (thisInfo !== undefined) {
    if (
      thisInfo.customizedContent !== undefined &&
      thisInfo.customizedContent !== null
    ) {
      const thisCustomizedContent = JSON.parse(thisInfo.customizedContent);
      thisWeight = thisCustomizedContent.weight;
    }

    if (thisInfo.element !== undefined) {
      const thisElementAttr = thisInfo.element.elementAttributeValueList;
      const thisElementAttrIndex = thisElementAttr.findIndex(
        (thisAttr) => thisAttr.elementAttribute.elementAttributeName === '길이',
      );
      if (thisElementAttrIndex !== -1) {
        thisLength = thisElementAttr[thisElementAttrIndex].value;
      }
    }
  }

  const actCopyBarcode = () => {
    const thisIndex = gsReducer.barcodeContent.indexOf(props.content);
    const copyNum = window.prompt('복사할 개수를 입력해 주세요.', '');
    console.log(thisIndex, copyNum);

    if (
      copyNum !== undefined &&
      copyNum !== null &&
      copyNum !== '' &&
      copyNum !== '0'
    ) {
      const updateData = [];
      for (let i = 0; i < Number(copyNum); i++) {
        updateData.push(props.content);
        console.log('updateData : ', updateData);
      }

      const newData = [...gsReducer.barcodeContent];
      newData.splice(thisIndex, 0, ...updateData);
      console.log('newData : ', newData);
      dispatch(gsReducer_setBarcodeContent(newData));
    }
  };

  const actDelBarcode = () => {
    const thisIndex = gsReducer.barcodeContent.indexOf(props.content);

    const newData = [...gsReducer.barcodeContent];
    newData.splice(thisIndex, 1);
    console.log('newData : ', newData);
    dispatch(gsReducer_setBarcodeContent(newData));
  };

  return (
    <BarcodeBox
      onClick={actDelBarcode}
      onContextMenu={actCopyBarcode}
      title="마우스 왼쪽 클릭 <삭제> 마우스 오른쪽 클릭 <복사>"
    >
      <BarcodeCon>
        <QRCode value={props.content.barcode} />
        <BarcodeInfo>
          <Info>현장명 : {thisPlace.workPlaceName || ''}</Info>
          <Info>규격 : {thisInfo.element.elementCode}</Info>
          <Info>재질 : {thisInfo.element.elementName}</Info>
          <Info>길이(m) : {thisLength}</Info>
          <Info>중량(kg) : {thisWeight}</Info>
        </BarcodeInfo>
      </BarcodeCon>
    </BarcodeBox>
  );
};

const BarcodeWContent = (props) => {
  const dispatch = useDispatch();
  const { gsReducer } = useSelector((state) => state);

  console.log('BarcodeWContent - props : ', props.content);
  const thisContent = props.content;
  const thisInfo = thisContent.info;
  const thisPlace =
    thisContent.place !== undefined
      ? JSON.parse(thisContent.place.contractCustomizedContent)
      : { workPlaceName: '' };

  let thisAsmbly = null;
  let thisProfile = null;
  let thisWeight = null;
  if (thisInfo !== undefined) {
    if (
      thisInfo.processCustomizedContent !== undefined &&
      thisInfo.processCustomizedContent !== null
    ) {
      const thisCustomizedContent = JSON.parse(
        thisInfo.processCustomizedContent,
      );
      thisAsmbly = thisCustomizedContent.asmbly;
      thisProfile = thisCustomizedContent.profile;
      thisWeight = thisCustomizedContent.weight;
    }
  }

  const actCopyBarcode = () => {
    const thisIndex = gsReducer.barcodeContent.indexOf(props.content);
    const copyNum = window.prompt('복사할 개수를 입력해 주세요.', '');
    console.log(thisIndex, copyNum);

    if (
      copyNum !== undefined &&
      copyNum !== null &&
      copyNum !== '' &&
      copyNum !== '0'
    ) {
      const updateData = [];
      for (let i = 0; i < Number(copyNum); i++) {
        updateData.push(props.content);
        console.log('updateData : ', updateData);
      }

      const newData = [...gsReducer.barcodeContent];
      newData.splice(thisIndex, 0, ...updateData);
      console.log('newData : ', newData);
      dispatch(gsReducer_setBarcodeContent(newData));
    }
  };

  const actDelBarcode = () => {
    const thisIndex = gsReducer.barcodeContent.indexOf(props.content);

    const newData = [...gsReducer.barcodeContent];
    newData.splice(thisIndex, 1);
    console.log('newData : ', newData);
    dispatch(gsReducer_setBarcodeContent(newData));
  };

  return (
    <BarcodeBox
      onClick={actDelBarcode}
      onContextMenu={actCopyBarcode}
      title="마우스 왼쪽 클릭 <삭제> 마우스 오른쪽 클릭 <복사>"
    >
      <BarcodeWrap>
        <TopTitle>특허철골 원가절감 40~15% EQ공법</TopTitle>
        <MiddleTitle>
          <LogoBox>
            <Logo>
              <img src="#" alt="" />
            </Logo>
            <h2>(주)경서</h2>
          </LogoBox>
          <LogoBox>
            <Logo>
              <img src="#" alt="" />
            </Logo>
            <h2>EQ구조엔지니어링(주)</h2>
          </LogoBox>
        </MiddleTitle>
        <BarcodeCon className="WContent">
          <QRCode className="WContent" value={props.content.barcode} />
          <BarcodeInfo className="WContent">
            <Info>현장명 : {thisPlace.workPlaceName || ''}</Info>
            <Info>부재번호 : {thisAsmbly}</Info>
            <Info>부재규격 : {thisProfile}</Info>
            <Info>중량 : {thisWeight}</Info>
          </BarcodeInfo>
        </BarcodeCon>
        <BottomTitle>"기업의 사회적 책임(CSR)을 실천하는 기업"</BottomTitle>
      </BarcodeWrap>
    </BarcodeBox>
  );
};

const PrintGSBarcode = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { gsReducer, pageReducer, userReducer } = useSelector((state) => state);

  console.log('gsReducer.barcodeContent : ', gsReducer.barcodeContent);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_modalStatus, setModalStatus] = useState(false);
  const [_loadingStatus, setLoadingStatus] = useState(false);

  const [_authority, setAuthority] = useState([]);
  console.log('_authority : ', _authority);

  const [_barcodeContent, setBarcodeContent] = useState([]);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter(
      (thisItem) => thisItem.authority.startsWith('802') === true,
    );
    const authorityCodes = authorityContent.map(
      (thisItem) => thisItem.authority,
    );
    setAuthority(authorityCodes);

    if (authorityCodes.indexOf('802-2') === -1) {
      alert('권한이 없습니다.');
      navigate(pageReducer.currentPage, { replace: true });
      return;
    } else {
      dispatch(pageReducer_getCurrentPage(window.location.pathname));
    }

    setContent(gsReducer.barcodeContent);

    setOnload('loaded');

    return () => {};
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      setContent(gsReducer.barcodeContent);
    }

    return () => {};
  }, [gsReducer.barcodeContent]);

  /* ====================================================================== #4 */
  const setContent = (thisData) => {
    const thisContent = [];
    if (thisData === undefined || thisData === null) return;
    for (let i = 0; i < thisData.length; i += 12) {
      thisContent.push(thisData.slice(i, i + 12));
    }

    setBarcodeContent(thisContent);
  };

  /* ====================================================================== #5 */
  const actAddBarcode = () => {
    setModalStatus(true);
  };

  const actPrint = () => {
    const printSection = document.getElementById('PrintSection');
    console.log('printSection: ', printSection);

    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
    });

    let count = 0;
    document.getElementsByName('BarcodeContents').forEach(async (thisItem) => {
      // - 쪽(페이지) 크기 : 210 x 297(mm) [A4용지]
      // - 장당 라벨 수 : 12칸 / 1 Sheet
      // - 라벨 1개의 크기(size) : 100.00 x 46.40(mm)
      // - 라벨 색상 : 흰색
      // - 위쪽 여백(마진) : 7.493(mm)
      // - 왼쪽 여백(마진) : 3.9878(mm)
      // - 가로 간격(좌,우) : 2.6924(mm)
      // - 세로 간격(상,하) : 0(mm)
      // - 모서리 각도 : 2.54(mm)
      // - 가로 개수 : 2
      // - 세로 개수 : 6
      // - 원단 특성 : 모조지 / 150mic

      const thisHeight = thisItem.offsetHeight;
      const thisWidth = thisItem.offsetWidth;
      const ratio = thisWidth / thisHeight;
      console.log('BarcodeContents : ', thisItem, thisHeight, thisWidth, ratio);

      const option = {
        height: thisHeight,
        width: thisWidth,
      };

      await html2canvas(thisItem, option).then((canvas) => {
        const image = canvas.toDataURL('image/png');
        let width = doc.internal.pageSize.getWidth();
        let height = doc.internal.pageSize.getHeight();
        width = ratio * height;

        const margin = 0;
        const position = 0;

        doc.addImage(image, 'jpeg', margin, position, width, height);
        if (count < document.getElementsByName('BarcodeContents').length - 1) {
          console.log(
            'addPage -------------------------------------------------------- :)',
          );
          doc.addPage();
        }
        count++;
        if (count === document.getElementsByName('BarcodeContents').length) {
          if (document.getElementsByName('BarcodeContents').length > 10) {
            doc.save();
            setLoadingStatus(false);
          } else {
            window.open(doc.output('bloburl'));
            setLoadingStatus(false);
          }
        }
      });
    });
  };

  /* ====================================================================== #6 */
  window.oncontextmenu = () => {
    return false;
  };

  return (
    <GridNonBody
      contents={
        <>
          <MainContents>
            <PrintSection style={{}}>
              <BarcodeSection id="PrintSection">
                {_barcodeContent.length > 0 &&
                  _barcodeContent.map((thisArray) => {
                    let rowCount = 0;
                    return (
                      <BarcodeContents
                        key={
                          _barcodeContent.indexOf(thisArray) +
                          '_BarcodeContents'
                        }
                        name="BarcodeContents"
                      >
                        {thisArray.length > 0 &&
                          thisArray.map((thisItem) => {
                            if (
                              gsReducer.barcodeContent.indexOf(thisItem) !== -1
                            ) {
                              rowCount++;
                              return (
                                <Fragment key={rowCount + '_row'}>
                                  {thisItem.type === 'procurement' ? (
                                    <BarcodeSContent content={thisItem} />
                                  ) : (
                                    <BarcodeWContent content={thisItem} />
                                  )}
                                </Fragment>
                              );
                            } else {
                              return null;
                            }
                          })}
                      </BarcodeContents>
                    );
                  })}
              </BarcodeSection>
            </PrintSection>

            <ButtonSection>
              <Button onClick={actAddBarcode}>
                바코드
                <br />
                물품
              </Button>
              <Button
                onClick={() => {
                  dispatch(gsReducer_setBarcodeContent([]));
                }}
              >
                초기화
              </Button>
              <Button
                onClick={() => {
                  setLoadingStatus(true);
                  setTimeout(actPrint, 1000);
                }}
              >
                출력
              </Button>
            </ButtonSection>
          </MainContents>

          {_modalStatus === true ? (
            <GSContractList
              open={_modalStatus}
              close={() => {
                setModalStatus(false);
              }}
            />
          ) : null}

          {_loadingStatus === true ? (
            <LoadingScreen>파일 생성 중입니다...</LoadingScreen>
          ) : null}
        </>
      }
    />
  );
};

export default PrintGSBarcode;
