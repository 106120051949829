import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { inquiryApi } from 'api/apis/inquiryApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat';
import { InquiryStatus } from 'pages/inquiry/components/status';

import InquiryReply from 'pages/inquiry/components/reply';

const FileBox = styled.div`
  align-items: center;
  background-color: var(--Bg);
  box-sizing: border-box;
  color: var(--gray-800);
  display: flex;
  height: 200px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;

  & img {
    height: inherit;
    max-width: inherit;
    width: auto;
  }
`;

const MasterInquiryInfo = (props) => {
  /* ====================================================================== #1 */
  // const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const { inquiry } = props;
  const {
    companyId,
    userId,

    inquiryId,
    inquiryCode,
    status,

    inquiryContent,
    inquiryReplyList,
    attachFileList,
  } = inquiry;
  const [_formData, setFormData] = useState({
    companyId: companyId,
    userId: userId,

    inquiryId: inquiryId,
    inquiryCode: inquiryCode,
    status: checkEmptyNull(status, 'waiting'),

    inquiryContent: checkEmptyNull(inquiryContent, ''),
    inquiryReplyList: checkEmptyNull(inquiryReplyList, []),
    deleteInquiryReplyList: [],
    attachFileList: checkNullArray(attachFileList, []),
    deleteFileDataList: [],
  });

  const [_originInquiryReplyList, setOriginInquiryReplyList] = useState(checkEmptyNull(inquiryReplyList, [])); // 댓글 원본
  const [_inquiryReplyList, setInquiryReplyList] = useState(checkEmptyNull(inquiryReplyList, []));
  const [_replyListModalStatus, setReplyListModalStatus] = useState(false); // 댓글 팝업

  const [_prevFileDataList, setPrevFileDataList] = useState(checkNullArray(attachFileList, [])); // 파일 원본
  const [_fileDataList, setFileDataList] = useState(checkNullArray(attachFileList, []));


  /* ====================================================================== #3 */
  useEffect(() => {
    setFormData(() => {
      return {
        companyId: companyId,
        userId: userId,

        inquiryId: inquiryId,
        inquiryCode: inquiryCode,
        status: checkEmptyNull(status, 'waiting'),

        inquiryContent: checkEmptyNull(inquiryContent, ''),
        inquiryReplyList: checkEmptyNull(inquiryReplyList, []),
        deleteInquiryReplyList: [],
        attachFileList: checkNullArray(attachFileList, []),
        deleteFileDataList: [],
      };
    });

    setOriginInquiryReplyList(() => { return checkEmptyNull(inquiryReplyList, []); });
    setInquiryReplyList(() => { return checkEmptyNull(inquiryReplyList, []); });

    setPrevFileDataList(() => { return checkNullArray(attachFileList, []); });
    setFileDataList(() => { return checkNullArray(attachFileList, []); });

    return () => { };
  }, [props]);

  /* ====================================================================== #4 */

  /* ====================================================================== #5 */
  /* 문의 상태 변경 */
  const handleQnAStatus = async (qnaStatus) => {
    const confirmText = `문의를 [${qnaStatus.name}] 상태로 변경합니다.`;

    if (window.confirm(confirmText) === true) {
      const BodyToPut = {
        companyId: companyId,
        userId: userId,

        inquiryId: inquiryId,
        inquiryCode: inquiryCode,
        status: qnaStatus.status,

        inquiryContent: checkEmptyNull(inquiryContent, ''),
        inquiryReplyList: checkEmptyNull(inquiryReplyList, []),
        deleteInquiryReplyList: [],
        attachFileList: checkNullArray(attachFileList, []),
        deleteFileDataList: [],
      };

      const putFormData = new FormData();
      putFormData.append('key', new Blob([JSON.stringify(BodyToPut)], { type: 'application/json' }));
      for (let values of putFormData.values()) {
        console.log('putFormData : ', values);
      }

      await inquiryApi.modifiedInquiry(inquiry.inquiryId, putFormData).then((response) => {
        if (response === undefined) return;
        console.log('inquiry.modifiedInquiry : ', response);
        alert('문의 상태를 변경했습니다.');
        props.update();
      });
    } else {
      return;
    }
  }

  /* ====================================================================== #6 */

  return (
    <>
      <div className='qna-sub'>
        <div className='qna-sub-title'>
          <p>문의 번호 : {inquiry.inquiryId}</p>
          <p>문의 생성일 : {DateTimeFormat(inquiry.createdDate)}</p>
          <p>마지막 업데이트 : {DateTimeFormat(inquiry.modifiedDate)}</p>
        </div>

        {/* <h2>문의 상세 내역</h2> */}
        <h2 className='qna-sub-title'>문의 내용</h2>
        <div className='qna-sub-info'>
          <div className='qna-sub-info-content'>
            <p>문의ID<span>{inquiry.inquiryId}</span></p>
            <p>회사ID<span>{inquiry.companyId}</span></p>
            <p>작성자<span>[{inquiry.userId}] {inquiry.userName}</span></p>
            <hr />
            <p>문의 코드<span>{_formData.inquiryCode}</span></p>
            <p>문의 상태<span>{InquiryStatus(checkEmptyNull(inquiry.status, 'wait'))}</span></p>
            <p>
              문의내용
              <textarea
                type="text"
                name="inquiryContent"
                placeholder="문의내용..."
                value={_formData.inquiryContent}
                disabled
              />
            </p>
          </div>
          {_fileDataList.map((fileData, index) => {
            return (
              <FileBox key={index + '_fileDatas'}>
                {(() => {
                  const returnArray = [];
                  if (fileData.attachFileId === undefined) {
                    if (fileData.type && fileData.type.startsWith('image')) {
                      returnArray.push(<img key={index + '_fileDataImg'} src={fileData.preview} alt={fileData.name} />);
                    } else {
                      returnArray.push(<>{fileData.name}</>);
                    }
                  } else {
                    if (fileData.fileData) {
                      if (fileData.fileData.fileDataType && fileData.fileData.fileDataType.startsWith('image')) {
                        returnArray.push(<img key={index + '_fileDataImg'} src={fileData.fileData.fileDataS3URL} alt={fileData.fileData.fileDataName} />);
                      } else {
                        returnArray.push(<span key={index + '_fileDataImg'}>{fileData.fileData.fileDataName}</span>);
                      }
                    } else {
                      return;
                    }
                  }

                  return returnArray;
                })()}
              </FileBox>
            );
          })}
          <div className='qna-sub-btn'>
            {[
              { status: 'check', name: '확인중', color: '#B2DFEE' },
              { status: 'cancel', name: '취소 승인', color: '#B4BCFD' },
              { status: 'end', name: '답변완료', color: '#0063C4' },
            ].map((qnaStatus, index) => {
              return (
                <button key={index + '_qnaStatus'} className="qna-sub-submit"
                  style={{ backgroundColor: qnaStatus.color }}
                  onClick={() => { handleQnAStatus(qnaStatus) }}
                >
                  {qnaStatus.name}
                </button>
              );
            })}
          </div>
        </div>
      </div>
      {/* 댓글 api 없음 */}
      {/* {_replyListModalStatus === true && inquiryId !== undefined ? (
        <InquiryReply
          inquiry={_formData}
          open={_replyListModalStatus}
          close={() => {setReplyListModalStatus(false);}}
        ></InquiryReply>
      ) : null} */}
    </>
  );
};

export default MasterInquiryInfo;
