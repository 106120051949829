import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import styled from 'styled-components';

import { shipmentApi } from 'api/apis/shipmentApi';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import TableSection from 'components/layouts/table/TableSection';

import GSSelectWorkOrderModal from 'pages/productionOrder/custom/company/gs/modal/GSSelectWorkOrderModal';

const ShipmentSection = styled.main`
  & .w100 {place-content: unset;}
  & .w50 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .TableSection {
    max-height: 550px;
  }
`;
const CustomTable = styled.table`
  background-color: var(--white);
  border-collapse: collapse;
  font-size: 0.8em;
  table-layout: fixed;
  width: 100%;

  td, th {border: 1px solid var(--gray-200); box-sizing: border-box; padding: 10px;}
  th {
    background-color: var(--MainNavy);
    color: var(--white);
    &.account {
      background-color: var(--MainBlue);
      cursor: pointer;
      &:hover {background-color: var(--SeconBlue);}
    }
  }
  th:first-child {min-width: 150px; width: 150px;}
`;
const DelButton = styled.div`
  height: 15px;
  margin: 0px auto;
  width: 15px;
  div {background-color: var(--MainRed); cursor: pointer; height: 15px; width: 15px;}
`;

const CopyGSShipment = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();
  const copyContent = checkNullParse(searchParams.get('copyContent'), {});

  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,

    accountId: undefined,

    shipmentStatus: 'wait',
    shipmentCode: copyContent.shipmentCode,
    shipmentName: copyContent.shipmentName,
    shipmentDate: moment().format('YYYY-MM-DD'),

    remark: '',

    customizedContent: checkNullParse(copyContent.customizedContent, {
      shipmentStatus: 'wait',

      supplierCompanyName: '',
      supplierName: '',
      supplierAddress: '',
      supplierCharge: '',

      driverName: '',
      driverHP: '',
      carNumber: '',

      supplyPlaceName: '',
      supplyCompanyName: '',
      supplyAddress: '',
      supplyCharge: '',

      chargeName: '',
      chargeNumber: '',
      chargeHP: '',
    }),

    shipmentElementList: [],
  });

  const [_shipmentElementList, setShipmentElementList] = useState(() => {
    const shipmentElementList = checkNullArray(copyContent.shipmentElementList, []).map((shipmentElement) => {
      return {
        amount: shipmentElement.amount,

        note: shipmentElement.note,

        customizedContent: checkNullParse(shipmentElement.customizedContent, {
          workOrderLogId: '',
          processLogId: '',

          item: '',
          membSize: '',
          length: '',
          quantity: '',
          uw: '',
          tw: '',
        }),
      };
    });
    return shipmentElementList;
  });

  const [_modalStatus, setModalStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('205') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    // console.log('_shipmentElementList : ', _shipmentElementList);
  }, [_shipmentElementList])

  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value } });
  };
  const handleCustomEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, customizedContent: { ...prev.customizedContent, [name]: value } } });
  };

  const checkDotNum = (num) => {
    let status = false;
    if (checkEmptyNull(num, false)) {
      const numString = num.toString();
      if (numString.slice(-1) !== '.') {
        if (num.toString().includes('.')) {
          const dotNum = num.toString().split('.')[1];
          if (dotNum.toString().slice(-1) === '0') status = false;
          else status = true;
        } else status = true;
      } else status = false;
    } else status = false;
    // console.log(num, ' : ', status);
    return status;
  }
  const handleElementEvent = (e, index, shipmentElement) => {
    const { name, value } = e.target;
    setShipmentElementList((prev) => {
      const returnData = [...prev];
      const returnCustomData = { ...shipmentElement.customizedContent };
      const newData = { ...shipmentElement, [name]: value, customizedContent: returnCustomData };
      // console.log('checkDotNum(e.target.value) : ', checkDotNum(e.target.value));
      if (name === 'amount' && checkEmptyNull(e.target.value, false) && (e.target.value.toString().slice(-1) !== '.') && checkDotNum(e.target.value)) {
        const customValue = checkEmptyNull(e.target.value, 0).toString().replace(/[^-\.0-9]/g, "") * 1;
        const length = checkEmptyNull(returnCustomData?.length, 0) * 1;
        const uw = checkEmptyNull(returnCustomData?.uw, 0) * 1;
        // tw = uw * qty * length / 1000
        returnCustomData.tw = BigNumber(uw).multipliedBy(BigNumber(customValue)).multipliedBy(BigNumber(length)).dividedBy(1000).toNumber();
      }
      returnData.splice(index, 1, newData);
      return returnData;
    });
  };
  const handleElementCustomEvent = (e, index, customizedContent, shipmentElement) => {
    const { name, value } = e.target;
    setShipmentElementList((prev) => {
      const returnData = [...prev];
      const returnCustomData = { ...customizedContent, [name]: value };
      if (name === 'length' && checkEmptyNull(e.target.value, false) && (e.target.value.toString().slice(-1) !== '.') && checkDotNum(e.target.value)) {
        const customValue = checkEmptyNull(e.target.value, 0).toString().replace(/[^-\.0-9]/g, "") * 1;
        returnCustomData.length = customValue;
        const uw = checkEmptyNull(customizedContent?.uw, 0) * 1;
        const amount = checkEmptyNull(shipmentElement.amount, 0) * 1;
        returnCustomData.tw = BigNumber(uw).multipliedBy(BigNumber(amount)).multipliedBy(BigNumber(customValue)).dividedBy(1000).toNumber();
      }
      if (name === 'quantity' && checkEmptyNull(e.target.value, false) && (e.target.value.toString().slice(-1) !== '.') && checkDotNum(e.target.value)) {
        const customValue = checkEmptyNull(e.target.value, 0).toString().replace(/[^-\.0-9]/g, "") * 1;
        returnCustomData.quantity = customValue;
      }
      if (name === 'uw' && checkEmptyNull(e.target.value, false) && (e.target.value.toString().slice(-1) !== '.') && checkDotNum(e.target.value)) {
        const customValue = checkEmptyNull(e.target.value, 0).toString().replace(/[^-\.0-9]/g, "") * 1;
        console.log('customValue - uw : ', customValue);
        const length = checkEmptyNull(customizedContent?.length, 0) * 1;
        const amount = checkEmptyNull(shipmentElement.amount, 0) * 1;
        returnCustomData.uw = customValue;
        returnCustomData.tw = BigNumber(customValue).multipliedBy(BigNumber(amount)).multipliedBy(BigNumber(length)).dividedBy(1000).toNumber();
      }
      if (name === 'tw' && checkEmptyNull(e.target.value, false) && (e.target.value.toString().slice(-1) !== '.') && checkDotNum(e.target.value)) {
        const customValue = checkEmptyNull(e.target.value, 0).toString().replace(/[^-\.0-9]/g, "") * 1;
        returnCustomData.tw = customValue;
      }
      const newData = { ...shipmentElement, customizedContent: returnCustomData };
      returnData.splice(index, 1, newData);
      return returnData;
    });
  };
  const deleteElement = (index, element) => {
    setShipmentElementList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);
      return prevData;
    });
  };

  const updateProcessLog = (data) => {
    const shipmentElementList = checkNullArray(data, []).map(shipmentElement => {
      const processCustomizedContent = checkNullParse(shipmentElement.processCustomizedContent, {});
      const length = checkEmptyNull(processCustomizedContent?.length, 0).toString().replace(/[^-\.0-9]/g, "") * 1;
      const uw = checkEmptyNull(processCustomizedContent?.weight, 0).toString().replace(/[^-\.0-9]/g, "") * 1;
      const amount = checkEmptyNull(processCustomizedContent?.quantity, 0).toString().replace(/[^-\.0-9]/g, "") * 1;
      const returnData = {
        amount: amount,
        note: '',
        processLogId: shipmentElement.processLogId,
        customizedContent: {
          workOrderLogId: shipmentElement.workOrderLogId,
          processLogId: shipmentElement.processLogId,

          item: processCustomizedContent?.asmbly,
          membSize: processCustomizedContent?.profile,
          length: length,
          quantity: '',
          uw: uw,
          tw: BigNumber(uw).multipliedBy(BigNumber(amount)).multipliedBy(BigNumber(length)).dividedBy(1000).toNumber()
        }
      }
      return returnData;
    })
    console.log('updateProcessLog : ', shipmentElementList);
    setShipmentElementList((prev) => { return [...prev, ...shipmentElementList] });
  }

  const actCopy = async (e) => {
    e.preventDefault();
    if (!checkEmptyNull(_formData.shipmentCode, false)) return alert('송장코드를 입력해주세요.');

    const BodyToPost = {
      ..._formData,
      customizedContent: JSON.stringify(_formData.customizedContent),

      shipmentElementList: _shipmentElementList.map((shipmentElement) => {
        const returnData = { ...shipmentElement, customizedContent: JSON.stringify(shipmentElement.customizedContent) };
        return returnData;
      }),
      stockFlag: false,
    };

    await shipmentApi.createShipment(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('shipmentApi.createShipment : ', response);
      alert('송장을 추가했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  /* ====================================================================== #6 */

  return (
    <Grid2Body contents={
      <>
        <ShipmentSection className="Main">
          <NavBar title={<NavTitle menuCode={'205'} />} nav={''} />

          <Form
            title={
              <>
                <NavTitle menuCode={'205'} />복사
                <p style={{ color: 'var(--MainNavy)', fontSize: '16px', fontWeight: '400', margin: '10px 0px' }}>
                  * 복사된 정보입니다.
                </p>
              </>
            }
            buttons={
              <>
                <button className="formButton" onClick={actCopy}>저장</button>
                <button className="formButton cancle"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(pageReducer.currentPage, { replace: true });
                  }}
                >
                  취소
                </button>
              </>
            }
            forms={
              <>
                <div className="formBox">
                  <h4>송장코드</h4>
                  <input
                    type="data"
                    name="shipmentCode"
                    placeholder="* 송장코드..."
                    data-required="required"
                    value={_formData.shipmentCode}
                    onInput={handleInputEvent}
                  />
                </div>

                {/* <div className="formBox">
                  <h4>송장이름</h4>
                  <input
                    type="text"
                    name="shipmentName"
                    placeholder="* 송장이름..."
                    value={_formData.shipmentName}
                    onInput={handleInputEvent}
                  />
                </div> */}

                <div className='w50'>
                  <div className="formBox w100">
                    <CustomTable>
                      <thead><tr><th></th><th>공급자</th></tr></thead>
                      <tbody>
                        {[
                          { title: '상호', name: 'supplierCompanyName', value: _formData.customizedContent?.supplierCompanyName },
                          { title: '대표이사', name: 'supplierName', value: _formData.customizedContent?.supplierName },
                          { title: '주소', name: 'supplierAddress', value: _formData.customizedContent?.supplierAddress },
                          { title: '출하담당', name: 'supplierCharge', value: _formData.customizedContent?.supplierCharge },
                        ].map((data, index) => {
                          return (
                            <tr key={index + '_supplier'}>
                              <th>{data.title}</th>
                              <td>
                                <input
                                  type="text"
                                  name={data.name}
                                  value={data.value}
                                  onInput={handleCustomEvent}
                                />
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </CustomTable>
                  </div>
                  <div className="formBox w100">
                    <CustomTable>
                      <thead><tr><th></th><th>공급처</th></tr></thead>
                      <tbody>
                        {[
                          { title: '현장명', name: 'supplyPlaceName', value: _formData.customizedContent?.supplyPlaceName },
                          { title: '상호', name: 'supplyCompanyName', value: _formData.customizedContent?.supplyCompanyName },
                          { title: '주소', name: 'supplyAddress', value: _formData.customizedContent?.supplyAddress },
                          { title: '담당', name: 'supplyCharge', value: _formData.customizedContent?.supplyCharge },
                        ].map((data, index) => {
                          return (
                            <tr key={index + '_supply'}>
                              <th>{data.title}</th>
                              <td>
                                <input
                                  type="text"
                                  name={data.name}
                                  value={data.value}
                                  onInput={handleCustomEvent}
                                />
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </CustomTable>
                  </div>
                  <div className="formBox w100">
                    <CustomTable>
                      <thead><tr><th></th><th>운전자</th></tr></thead>
                      <tbody>
                        {[
                          { title: '성명', name: 'driverName', value: _formData.customizedContent?.driverName },
                          { title: '차량번호', name: 'carNumber', value: _formData.customizedContent?.carNumber },
                          { title: 'H.P', name: 'driverHP', value: _formData.customizedContent?.driverHP },
                        ].map((data, index) => {
                          return (
                            <tr key={index + '_driver'}>
                              <th>{data.title}</th>
                              <td>
                                <input
                                  type="text"
                                  name={data.name}
                                  value={data.value}
                                  onInput={handleCustomEvent}
                                />
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </CustomTable>
                  </div>
                  <div className="formBox w100">
                    <CustomTable>
                      <thead><tr><th></th><th>담당자</th></tr></thead>
                      <tbody>
                        {[
                          { title: '성명', name: 'chargeName', value: _formData.customizedContent?.chargeName },
                          { title: 'TEL', name: 'chargeNumber', value: _formData.customizedContent?.chargeNumber },
                          { title: 'H.P', name: 'chargeHP', value: _formData.customizedContent?.chargeHP },
                        ].map((data, index) => {
                          return (
                            <tr key={index + '_charge'}>
                              <th>{data.title}</th>
                              <td>
                                <input
                                  type="text"
                                  name={data.name}
                                  value={data.value}
                                  onInput={handleCustomEvent}
                                />
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </CustomTable>
                  </div>
                </div>

                <div className="formBox w100">
                  <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                    <h4>송장물품</h4>
                    <div className="formButton"
                      style={{ backgroundColor: 'var(--MainBlue)', borderRadius: '5px', width: '150px' }}
                      onClick={() => { setModalStatus(true) }}
                    >
                      송장물품 추가
                    </div>
                  </div>

                  <TableSection content={
                    <table>
                      <thead style={{ backgroundColor: 'var(--MainNavy)' }}>
                        <tr>
                          <td>NO</td>
                          <td>ITEM</td>
                          <td>MEMB SIZE</td>
                          <td>LENGTH</td>
                          <td>Q'ty</td>
                          <td>U/W</td>
                          <td>Q'ty</td>
                          <td>T/W(kg)</td>
                          <td>비고</td>
                          <th style={{ minWidth: '50px', width: '50px' }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {checkNullArray(_shipmentElementList, []).map((shipmentElement, index) => {
                          const customizedContent = checkNullObject(shipmentElement.customizedContent, {
                            item: '',
                            membSize: '',
                            length: '',
                            quantity: '',
                            uw: '',
                            tw: '',
                          });

                          return (
                            <tr key={index + '_shipmentElementList'}>
                              <td>{(index + 1).toString().padStart(2, '0')}</td>
                              {[
                                { name: 'item', type: 'text', custom: true, },
                                { name: 'membSize', type: 'text', custom: true, },
                                { name: 'length', type: 'number', custom: true, },
                                { name: 'quantity', type: 'number', custom: true, },
                                { name: 'uw', type: 'number', custom: true, },
                                { name: 'amount', type: 'number', custom: false, },
                                { name: 'tw', type: 'number', custom: true, },
                                { name: 'note', type: 'text', custom: false, },
                              ].map((customData, customIndex) => {
                                const customVlaue = customData.custom ?
                                  checkEmptyNull(customizedContent[customData.name], '')
                                  : checkEmptyNull(shipmentElement[customData.name], '');
                                let value = customVlaue;
                                if (customData.type === 'number') {
                                  if (value.toString().slice(-1) !== '.' && checkDotNum(value)) {
                                    const customNumber = customVlaue.toString().replace(/[^-\.0-9]/g, "") * 1;
                                    value = customNumber.toLocaleString()
                                  } else {
                                    value = customVlaue.toLocaleString()
                                  }
                                }

                                return (
                                  <td key={index + '_custom_' + customIndex}>
                                    <input
                                      type='text'
                                      name={customData.name}
                                      value={value}
                                      onInput={(e) => {
                                        customData.custom ?
                                          handleElementCustomEvent(e, index, customizedContent, shipmentElement)
                                          : handleElementEvent(e, index, shipmentElement)
                                      }}
                                    />
                                  </td>
                                );
                              })}
                              <td style={{ minWidth: '50px', width: '50px' }}>
                                <DelButton onClick={() => { deleteElement(index, shipmentElement) }}><CloseButtonIcon /></DelButton>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  }
                  />
                </div>
              </>
            }
          />
        </ShipmentSection>

        {_modalStatus === true && (
          <GSSelectWorkOrderModal
            content={_shipmentElementList}
            update={(data) => { updateProcessLog(data) }}
            open={_modalStatus}
            close={() => { setModalStatus(false); }}
          ></GSSelectWorkOrderModal>
        )}
      </>
    }
    />
  );
};

export default CopyGSShipment;
