import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { order } from 'api/apis/mall/order';

import { checkEmptyNull, checkNull, checkNullArray } from 'components/checkValues/checkValues';
import { MallStatusName } from 'components/status/MallStatusName';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import MallSiteOrderReply from 'mall/components/reply/MallSiteOrderReply';

const DeleteButton = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 20px;
  justify-content: center;
  transform: scale(1.5);
  width: 20px;
  div {background-color: var(--MainRed); cursor: pointer; height: 15px; width: 15px;}
`;
const FileBox = styled.div`
  align-items: center;
  background-color: var(--Bg);
  box-sizing: border-box;
  color: var(--gray-800);
  display: flex;
  height: 200px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  & img {
    height: auto;
    max-height: 100px;
    max-width: 100px;
    width: auto;
  }
  &:hover {opacity: 0.8;}
`;

const MypageOrderInfo = (props) => {
  /* ====================================================================== #1 */
  const location = useLocation();
  const { userReducer } = useSelector(state => state);

  /* ====================================================================== #2 */
  const companyCode = location.pathname.split('/')[1];

  const { mallOrder } = props;
  const { mallOrderId, mallOrderCode, status, userName, recipientName, phoneNumber, zip, address, mallOrderContent, mallOrderElementList, attachFileList } = mallOrder;

  const [_formData, setFormData] = useState({
    companyId: userReducer.user.companyId,
    companyName: userReducer.user.companyName,
    mallOrderId: mallOrderId,
    mallOrderCode: mallOrderCode,
    status: checkEmptyNull(status, 'waiting'),
    userId: userReducer.user.id,
    userName: checkEmptyNull(userName, ''),
    recipientName: checkEmptyNull(recipientName, ''),
    phoneNumber: checkEmptyNull(phoneNumber, ''),
    zip: checkEmptyNull(zip, ''),
    address: checkEmptyNull(address, ''),
    mallOrderContent: checkEmptyNull(mallOrderContent, ''),
    mallOrderElementList: mallOrderElementList,
    deleteMallOrderElementList: [],
    attachFileList: attachFileList,
    deleteFileDataList: [],
  });

  const [_originOrderElementList, setOriginOrderElementList] = useState(checkNullArray(mallOrderElementList, []));
  const [_mallOrderElementList, setMallOrderElementList] = useState(checkNullArray(mallOrderElementList, []));

  const [_prevFileDataList, setPrevFileDataList] = useState(checkNullArray(attachFileList, []));
  const [_fileDataList, setFileDataList] = useState(checkNullArray(attachFileList, []));

  const [_replyListModalStatus, setReplyListModalStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    setFormData(() => {
      return {
        companyId: userReducer.user.companyId,
        companyName: userReducer.user.companyName,
        mallOrderId: mallOrderId,
        mallOrderCode: mallOrderCode,
        status: checkEmptyNull(status, 'waiting'),
        userId: userReducer.user.id,
        userName: checkEmptyNull(userName, ''),
        recipientName: checkEmptyNull(recipientName, ''),
        phoneNumber: checkEmptyNull(phoneNumber, ''),
        zip: checkEmptyNull(zip, ''),
        address: checkEmptyNull(address, ''),
        mallOrderContent: checkEmptyNull(mallOrderContent, ''),
        mallOrderElementList: mallOrderElementList,
        deleteMallOrderElementList: [],
        attachFileList: attachFileList,
        deleteFileDataList: [],
      };
    });
    setOriginOrderElementList(() => { return checkNullArray(mallOrderElementList, []); });
    setMallOrderElementList(() => { return checkNullArray(mallOrderElementList, []); });
    setPrevFileDataList(() => { return checkNullArray(attachFileList, []); });
    setFileDataList(() => { return checkNullArray(attachFileList, []); });

    return () => { };
  }, [props]);

  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value }; });
  };

  // 전화번호 형식 변환
  const makePhoneNumber = (e) => {
    const phoneNumber = e.target.value.toString().replace(/[^0-9]/g, '').replace(/(^02.{0}|^01.{1}|[0-9]{3,4})([0-9]{3,4})([0-9]{4})/g, '$1-$2-$3');
    setFormData((prev) => { return { ...prev, phoneNumber: phoneNumber }; });
  };

  const handleElementCount = (e, index, element) => {
    const { value } = e.target;

    let eventValue = value > 0 ? value : 1;
    if (!eventValue.toString().startsWith('0.')) eventValue = eventValue.toString().replace(/^0+/, '');
    if (eventValue < 0) eventValue = 0;
    if (eventValue.length > 15) return;

    const amount = BigNumber(eventValue).toNumber();
    const price = checkNull(element.mallElementPrice, 0);
    const calPrice = BigNumber(price).multipliedBy(BigNumber(amount)).toNumber();

    setMallOrderElementList((prev) => {
      const prevData = [...prev];
      const newData = { ...element, count: amount, mallElementTotalPrice: calPrice };
      prevData.splice(index, 1, newData);
      return prevData;
    });
  };
  const actPlus = (element, index) => {
    const amount = BigNumber(element.count).plus(1).toNumber();
    const price = checkNull(element.mallElementPrice, 0);
    const calPrice = BigNumber(price).multipliedBy(BigNumber(amount)).toNumber();

    setMallOrderElementList((prev) => {
      const returnData = [...prev];
      const newData = { ...element, count: amount, mallElementTotalPrice: calPrice };
      returnData.splice(index, 1, newData);
      return returnData;
    });
  };
  const actMinus = (element, index) => {
    const amount = BigNumber(element.count).minus(1).toNumber() > 0 ? BigNumber(element.count).minus(1).toNumber() : 1;
    const price = checkNull(element.mallElementPrice, 0);
    const calPrice = BigNumber(price).multipliedBy(BigNumber(amount)).toNumber();

    setMallOrderElementList((prev) => {
      const returnData = [...prev];
      const newData = { ...element, count: amount, mallElementTotalPrice: calPrice };
      returnData.splice(index, 1, newData);
      return returnData;
    });
  };
  const actDeleteOrderElement = (e, index, element) => {
    setMallOrderElementList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);
      return prevData;
    });
  };

  const handleFileEvent = (type, e) => {
    let files = [];
    switch (type) {
      case 'click': files = e.target.files; break;
      case 'drag': files = e.dataTransfer.files; break;
      default: return;
    }

    if (files === undefined || files === null || files.length === 0) return;
    const setFiles = Array.prototype.slice.call(files);
    setFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onload = () => {
          const returnData = file;
          returnData['preview'] = reader.result;
          setFileDataList((prev) => { return [...prev, returnData]; });
          resolve();
        };
      });
    });
  };
  const actDeleteFileData = (file, index) => {
    setFileDataList((prev) => {
      const returnData = [...prev];
      returnData.splice(index, 1);
      return returnData;
    });
  };

  /* 수정 */
  const actUpdate = async (e) => {
    e.preventDefault();

    const findElement = (mallOrderElementId) => {
      const index = _mallOrderElementList.findIndex((mallOrderElement) => mallOrderElement.mallOrderElementId === mallOrderElementId);
      return index;
    };
    const deleteMallOrderElementList = _originOrderElementList.filter((element) => findElement(element.mallOrderElementId) === -1);
    const setDeleteMallOrderElementList = deleteMallOrderElementList.filter((element) => element !== undefined && element !== null);

    const findFileData = (attachFileId) => {
      const index = _fileDataList.findIndex((fileData) => fileData.attachFileId === attachFileId);
      return index;
    };
    const deleteFileDataList = _prevFileDataList.filter((fileData) => findFileData(fileData.attachFileId) === -1);
    const setDeleteFileDataList = deleteFileDataList.filter((fileData) => fileData !== undefined && fileData !== null);

    const BodyToPut = {
      ..._formData,
      mallOrderElementList: _mallOrderElementList,
      deleteMallOrderElementList: setDeleteMallOrderElementList.map((deleteElement) => deleteElement.mallOrderElementId),
      deleteFileDataList: setDeleteFileDataList.map((deleteFileData) => deleteFileData.attachFileId),
    };
    console.log('BodyToPut : ', BodyToPut);
    const putFormData = new FormData();
    putFormData.append('key', new Blob([JSON.stringify(BodyToPut)], { type: 'application/json' }));
    if (_fileDataList.length > 0) {
      _fileDataList.forEach((file) => {
        console.log(file.attachFileId);
        if (file.attachFileId === undefined) putFormData.append('multipartFileList', file);
      });
    }
    for (let values of putFormData.values()) {
      console.log('putFormData : ', values);
    }
    await order.updateMallOrder(mallOrderId, putFormData).then((response) => {
      if (response === undefined) return;
      console.log('order.updateMallOrder : ', response);
      alert('주문정보를 수정했습니다.');
      props.update();
    });
  };

  /* 취소 요청 */
  const actRequestCancel = async () => {
    const confirmText = `
주문을 취소하시겠습니까?
관리자 승인 후 최종 취소 처리됩니다.
    `;
    if (window.confirm(confirmText) === true) {
      const BodyToPut = {
        ...mallOrder,
        deleteMallOrderElementList: [],
        deleteFileDataList: [],
        userId: userReducer.user.id,
        status: 'requestCancel',
      };
      console.log('actCancel : ', BodyToPut);
      const putFormData = new FormData();
      putFormData.append('key', new Blob([JSON.stringify(BodyToPut)], { type: 'application/json' }));
      for (let values of putFormData.values()) {
        console.log('putFormData : ', values);
      }
      await order.updateMallOrder(mallOrder.mallOrderId, putFormData).then((response) => {
        if (response === undefined) return;
        console.log('order.updateMallOrder : ', response);
        alert('주문 취소 요청을 했습니다.');
        props.update();
      });
    } else return;
  };

  /* ====================================================================== #6 */

  return (
    <>
      <div className='qna-sub'>
        <div className='qna-sub-title'>
          <p>주문 번호 : {mallOrder.mallOrderId}</p>
          <p>주문일자 : {mallOrder.createdDate}</p>
          <p>업데이트 : {mallOrder.modifiedDate}</p>
        </div>
        <h2 className='qna-sub-title'>주문 정보</h2>
        <div className='qna-sub-info'>
          <div className='qna-sub-info-content'>
            <p>주문 코드<span>{_formData.mallOrderCode}</span></p>
            <p>주문 상태<span>{MallStatusName(_formData.status || 'waiting')}</span></p>

            <p>받는 사람
              <span>
                <input
                  type="text"
                  name="recipientName"
                  placeholder="받는사람..."
                  value={_formData.recipientName}
                  onInput={handleInputEvent}
                />
              </span>
            </p>
            <p>연락처
              <span>
                <input
                  type="text"
                  name="phoneNumber"
                  placeholder="- 를 제외하고 입력해주세요."
                  value={_formData.phoneNumber}
                  onInput={makePhoneNumber}
                />
              </span>
            </p>
            <p>우편 번호
              <span>
                <input
                  type="text"
                  name="zip"
                  placeholder="우편번호..."
                  value={_formData.zip}
                  onInput={handleInputEvent}
                />
              </span>
            </p>
            <p>주소
              <span>
                <input
                  type="text"
                  name="address"
                  placeholder="주소..."
                  value={_formData.address}
                  onInput={handleInputEvent}
                />
              </span>
            </p>
            <p>주문내용
              <textarea
                type="text"
                name="mallOrderContent"
                placeholder="주문내용..."
                value={_formData.mallOrderContent}
                onInput={handleInputEvent}
              />
            </p>

            <p>주문상품
              <p className='qna-sub-list qna-sub-list-product'>
                {_mallOrderElementList.map((element, index) => {
                  return (
                    <li key={index + '_orderElements'}>
                      <p>
                        <>&nbsp;</>
                        <DeleteButton onClick={(e) => { actDeleteOrderElement(e, index, element); }} ><CloseButtonIcon /></DeleteButton>
                      </p>
                      <p>{`상품코드: ${element.mallElementCode}`}</p>
                      <p>{`상품명: ${element.mallElementName}`}</p>
                      {/* <p>{`${element.mallElementCategoryName}`}</p> */}
                      <p>{`단가: ${element.mallElementPrice.toLocaleString('ko-kr',)}`}</p>
                      <p>{`총 가격: ${element.mallElementTotalPrice.toLocaleString('ko-kr',)}`}</p>
                      <p className='amount'>
                        <p>수량 :</p>
                        <p>
                          <input type="number" min={1} value={element.count} onInput={(e) => { handleElementCount(e, index, element); }} />
                          <button onClick={(e) => { e.preventDefault(); actPlus(element, index); }}>+</button>
                          <button onClick={(e) => { e.preventDefault(); actMinus(element, index); }}>-</button>
                        </p>
                      </p>
                    </li>
                  );
                })}
              </p>
            </p>

            <p>
              첨부파일
              <input type="file" id="FileInput" multiple style={{ display: 'none' }} onChange={(e) => { handleFileEvent('click', e); }} />
              <label
                className='list-file'
                htmlFor="FileInput"
                onDragEnter={(e) => { e.stopPropagation(); e.preventDefault(); }}
                onDragLeave={(e) => { e.stopPropagation(); e.preventDefault(); }}
                onDragOver={(e) => { e.stopPropagation(); e.preventDefault(); }}
                onDrop={(e) => { e.preventDefault(); handleFileEvent('drag', e); }}
              >
                <p>첨부 파일 드래그 또는 <span>선택</span></p>
              </label>
            </p>
          </div>
          {_fileDataList.map((fileData, index) => {
            return (
              <FileBox key={index + '_fileDatas'}
                title='파일을 클릭하면 삭제됩니다.'
                onClick={() => { actDeleteFileData(fileData, index); }}
              >
                {(() => {
                  const returnArray = [];
                  if (fileData.attachFileId === undefined) {
                    if (fileData.type && fileData.type.startsWith('image')) {
                      returnArray.push(<img key={index + '_fileDataImg'} src={fileData.preview} alt={fileData.name} />);
                    } else {
                      returnArray.push(<>{fileData.name}</>);
                    }
                  } else {
                    if (fileData.fileData) {
                      if (fileData.fileData.fileDataType && fileData.fileData.fileDataType.startsWith('image')) {
                        returnArray.push(<img key={index + '_fileDataImg'} src={fileData.fileData.fileDataS3URL} alt={fileData.fileData.fileDataName} />);
                      } else {
                        returnArray.push(<span key={index + '_fileDataImg'}>{fileData.fileData.fileDataName}</span>);
                      }
                    } else return;
                  }
                  return returnArray;
                })()}
              </FileBox>
            );
          })}
        </div>
        <div className='qna-sub-btn' style={{ gap: '24px' }}>
          <button className="qna-sub-submit qnaBtn" onClick={() => { setReplyListModalStatus(true); }}>1:1 추가 문의</button>
          <button className="qna-sub-submit qnaBtn" onClick={actRequestCancel}>주문 취소 요청</button>
          <button className="qna-sub-submit" onClick={actUpdate}>변경된 내용 저장</button>
        </div>
      </div>

      {_replyListModalStatus === true && mallOrderId !== undefined ? (
        <MallSiteOrderReply
          mallOrder={_formData}
          open={_replyListModalStatus}
          close={() => { setReplyListModalStatus(false); }}
        ></MallSiteOrderReply>
      ) : null}
    </>
  );
};

export default MypageOrderInfo;
