import React from 'react';
import Pagination from 'react-js-pagination';
import styled from 'styled-components';

import 'components/paging/Paging.css';
import { lastPageButton, nextPageButton } from 'components/icons/src';

//
const PageButton = styled.div`
  background-color: var(--ThirdBlue);

  mask-repeat: no-repeat;
  mask-size: contain;
  --webkit-mask-repeat: no-repeat;
  --webkit-mask-size: contain;
`;

const LastPageButton = styled(PageButton)`
  mask-image: url(${lastPageButton});
  --webkit-mask-image: url(${lastPageButton});

  height: 16px;
  width: 16px;
`;

const FirstPageButton = styled(LastPageButton)`
  transform: rotate(-180deg);
`;

const NextPageButton = styled(PageButton)`
  mask-image: url(${nextPageButton});
  --webkit-mask-image: url(${nextPageButton});

  height: 20px;
  width: 20px;
`;

const PrevPageButton = styled(NextPageButton)`
  transform: rotate(-180deg);
`;

const PagingComponent = (props) => {
  const { page, count, size, pageEvent } = props;

  return (
    <Pagination
      activePage={page}
      itemsCountPerPage={size}
      onChange={(e) => {
        pageEvent(e);
      }}
      pageRangeDisplayed={5}
      totalItemsCount={count}
      firstPageText={<FirstPageButton />}
      lastPageText={<LastPageButton />}
      prevPageText={<PrevPageButton />}
      nextPageText={<NextPageButton />}
    />
  );
};

export default PagingComponent;
