import { AxiosInterceptor } from "api/core/consts";

export const shipmentInspectionItem = {
  getShipmentInspectionItem: (shipmentInspectionItemId) => AxiosInterceptor.get('shipmentInspectionItem/' + shipmentInspectionItemId),
  searchShipmentInspectionItem: (paging, body) => AxiosInterceptor.post('shipmentInspectionItem/search' + paging, body),
  createShipmentInspectionItem: (body) => AxiosInterceptor.post('shipmentInspectionItem', body),
  copyShipmentInspectionItem: (body) => AxiosInterceptor.post('shipmentInspectionItem', body),
  updateShipmentInspectionItem: (shipmentInspectionItemId, body) => AxiosInterceptor.put('shipmentInspectionItem/' + shipmentInspectionItemId, body),
  deleteShipmentInspectionItem: (shipmentInspectionItemId) => AxiosInterceptor.delete('shipmentInspectionItem/' + shipmentInspectionItemId),
};
