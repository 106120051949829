import React, { useEffect, useState } from 'react';

import { userApi } from 'api/apis/userApi';
import { checkEmptyNull } from 'components/checkValues/checkValues';

const UpdateContent = ({
  close, modalData
}) => {
  const body = {
    companyId: modalData.companyId,
    teamId: modalData.team?.teamId,
    id: modalData.id,
    loginId: modalData.loginId,
    name: modalData.name,
    role: modalData.role,
    email: modalData.email,
    emailVerified: modalData.emailVerified,
    phoneNumber: modalData.phoneNumber,
    memo: modalData.memo,
  };
  const [_body, setBody] = useState(body);

  useEffect(() => {}, [_body]);

  const handleInputEvent = (e) => { // 사용자 정보 입력
    const {name, value} = e.target;
    setBody((prev) => {
      const returnData = {...prev, [name] : value};
      return returnData;
    })
  };
  const actUserUpdate = async() => { // 사용자 정보 수정
    await userApi.updateUser(_body.id, _body).then(response => {
      if(response === undefined) return;
      console.log('actRoleUpdate : ', response);
      alert('사용자 정보를 수정했습니다.');
      close('update');
    })
  };

  return (
    <section className='user-content'>
      <div>
        {[
          {name: 'loginId', title: '아이디', disabled: true},
          {name: 'name', title: '이름', disabled: false},
          {name: 'email', title: '이메일', disabled: true},
          {name: 'phoneNumber', title: '연락처', disabled: false},
          {name: 'memo', title: '메모', disabled: false},
        ].map(((userData) => {
          return (
            <div key={userData.name}>
              <h4>{userData.title}</h4>
              {userData.name === 'memo' ? (
                <textarea
                  name={userData.name}
                  value={checkEmptyNull(_body[userData.name], '')}
                  onInput={handleInputEvent}
                ></textarea>
              ) : (
                <input
                  type='text'
                  name={userData.name}
                  disabled={userData.disabled}
                  value={checkEmptyNull(_body[userData.name], '')}
                  onInput={handleInputEvent}
                />
              )}
            </div>
          )
        }))}
      </div>

      <button className='btn-save' onClick={actUserUpdate}>저장</button>
    </section>
  );
};

export default UpdateContent;