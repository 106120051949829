export const workOrderActions_setWorkOrderType = (workOrderType) => {
  return {
    type: 'SETWORKORDERWORKORDERTYPE',
    payload: workOrderType,
  };
};

export const workOrderActions_setWorkOrderStatus = (workOrderStatus) => {
  return {
    type: 'SETWORKORDERWORKORDERSTATUS',
    payload: workOrderStatus,
  };
};

export const workOrderActions_setStartDate = (startDate) => {
  return {
    type: 'SETWORKORDERSTARTDATE',
    payload: startDate,
  };
};

export const workOrderActions_setEndDate = (endDate) => {
  return {
    type: 'SETWORKORDERENDDATE',
    payload: endDate,
  };
};

export const workOrderActions_setSearchOption = (searchData) => {
  return {
    type: 'SETWORKORDERSEARCHOPTION',
    payload: searchData,
  };
};
export const workOrderActions_setSearchText = (searchData) => {
  return {
    type: 'SETWORKORDERSEARCHTEXT',
    payload: searchData,
  };
};
export const workOrderActions_setSearchData = (searchData) => {
  return {
    type: 'SETWORKORDERSEARCHDATA',
    payload: searchData,
  };
};

export const workOrderActions_setPageNumber = (pageNumber) => {
  return {
    type: 'SETWORKORDERPAGENUMBER',
    payload: pageNumber,
  };
};

export const workOrderActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETWORKORDERTOTALSIZE',
    payload: totalSize,
  };
};

export const workOrderActions_setIsPushedSearchButton = (isPushedSearchButton) => {
  return {
    type: 'SETWORKORDERISPUSHEDSEARCHBUTTON',
    payload: isPushedSearchButton,
  };
};

export const workOrderActions_setReset = () => {
  return {
    type: 'SETWORKORDERRESET',
  };
};

export const workOrderActions_setViewScale = (scale) => {
  return {
    type: 'SETWORKORDERVIEWSCALE',
    payload: scale,
  };
};

export const workOrderActions_setBookmark = (status) => {
  return {
    type: 'SETWORKORDERBOOKMARK',
    payload: status,
  };
};