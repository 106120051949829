import React from 'react';
import styled from 'styled-components';
import Footer from 'components/footer/Footer';
import MallManagementHeader from 'components/header/MallManagementHeader';

const GridBody = styled.div`
  display: grid;
  grid-template-rows: 60px calc(100% - 60px);
  height: 100svh;
  overflow: hidden;
  /* position: relative; */
  width: 100%;

  & footer {bottom: 8px;}
`;

const MallBody = (props) => {
  return (
    <GridBody>
      <MallManagementHeader />
      <div className='main-pagination'>
        {props.contents}
      </div>
      <Footer />
    </GridBody>
  );
};

export default MallBody;