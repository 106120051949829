import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { modalReducer_setCurrentPageNum, modalReducer_setTotalCount } from 'store/modules/actions/default/modalActions';

import { workOrderApi } from 'api/apis/workOrderApi';

import { checkNullArray } from 'components/checkValues/checkValues';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import RelatedWorkOrderRow from 'components/row/RelatedWorkOrderRow';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 90%;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 400;
  gap: 10px;
  justify-content: flex-start;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const WorkOrderList = styled.div`
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
  width: 100%;

  .TableSection {
    height: 100%;
    table {
      thead tr {border: none;}
      tr[name='workOrder']:hover {cursor: pointer;}
      td, th {min-width: 150px;}
      td.targetCell {background-color: var(--MainNavy); color: var(--white);}
      p {border: 1px solid var(--white); height: 30px;}
    }
  }
`;

const ProcessTable = styled.table`
  background-color: var(--Bg);
  border-collapse: collapse;
  min-width: 100%;
  table-layout: fixed;

  td, th {
    box-sizing: border-box;
    padding: 5px 15px;
    min-width: 120px !important;
    text-align: center;
    white-space: normal;
    word-break: break-all;
    font-size: 14px;
  }
  td.blankCell {background-color: var(--Bg); opacity: 0.8;}
`;

const RelatedWorkOrder = (props) => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { modalReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  // const [searchParams, setSearchParams] = useSearchParams();
  // const elementId = searchParams.get('elementId');
  const elementId = props.elementId;

  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_workOrderList, setWorkOrderList] = useState([]);

  /* ========================================================================= #3 */
  useEffect(() => {
    dispatch(modalReducer_setCurrentPageNum(1));

    // 권한 체크
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('111') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    getWorkOrderList(0);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getWorkOrderList(modalReducer.currentPageNum - 1);
    }

    return () => { };
  }, [modalReducer.currentPageNum]);

  /* ========================================================================= #4 */
  const getWorkOrderList = (page) => {
    const paging = `?page=${page}&size=${modalReducer.pageSize}&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      elementId: elementId,
    };
    workOrderApi.searchWorkOrder(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderApi.searchWorkOrder : ', response);
      dispatch(modalReducer_setTotalCount(response.data.totalElements));

      const workOrderList = response.data.content;
      const setWorkOrderListData = workOrderList.map((thisWorkOrder) => {
        const workOrderProcessList = thisWorkOrder.workOrderProcessList;
        if (workOrderProcessList.length === 0) return thisWorkOrder;

        let elementCount = 0;
        workOrderProcessList.forEach((thisWorkOrderProcess) => {
          const processData = thisWorkOrderProcess.process;
          if (processData === undefined || processData === null || processData.length === 0) return thisWorkOrderProcess;

          const PutItems = processData.processElementList.filter((thisEl) => thisEl.processElementType === 'In' || thisEl.processElementType === 'Put');
          const MakeItems = processData.processElementList.filter((thisEl) => thisEl.processElementType === 'Out' || thisEl.processElementType === 'Make');

          const rowLength = PutItems.length > MakeItems.length ? PutItems.length : MakeItems.length;

          elementCount += rowLength;
        });

        const returnData = { ...thisWorkOrder };
        returnData.elementCount = elementCount;

        return returnData;
      });

      setWorkOrderList(() => { return setWorkOrderListData; });
    });
  };

  /* ========================================================================= #5 */
  const goDetails = (workOrder) => {
    const detailContent = workOrder;
    console.log('detailContent : ', detailContent);

    let pathname = '/baseInformation/workOrder/update';
    if (userReducer.company.companyId === 6) pathname = '/baseInformation/workOrder/dl/update';
    navigate({
      pathname: pathname,
      search: `?${createSearchParams({ workOrderId: detailContent.workOrderId })}`,
      replace: true,
    });
  };

  const createWorkOrderLog = (workOrder) => {
    const workOrderContent = workOrder;
    console.log('workOrderContent : ', workOrderContent);

    let pathname = '/baseInformation/workOrder/workOrderLog/create';
    if (userReducer.company.companyId === 6) pathname = '/productionManagement/DL/WorkOrderLog/create';
    navigate({
      pathname: pathname,
      search: `?${createSearchParams({ workOrderId: workOrderContent.workOrderId })}`,
      replace: true,
    });
  };

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>연관 <NavTitle menuCode={'111'} /></ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <WorkOrderList>
              <TableSection content={
                <table style={{ zoom: '100%' }}>
                  <thead>
                    <tr>
                      <th colSpan={13}>연관 <NavTitle menuCode={'111'} /></th>
                    </tr>
                    <tr>
                      {_authority.indexOf('111-2') !== -1 ? (<th rowSpan={2} style={{ minWidth: '100px', padding: 'unset', width: '100px' }}></th>) : null}
                      <th rowSpan={2}>작업유형</th>
                      <th rowSpan={2}>작업이름</th>
                      <th rowSpan={2}>작업코드</th>
                      <th rowSpan={2}>생산가능수량</th>
                      <th colSpan={6} style={{ backgroundColor: 'var(--MainBlue)' }}>공정</th>
                      <th style={{ backgroundColor: 'var(--ThirdBlue)' }}>공정완료제품</th>
                      <th style={{ backgroundColor: 'var(--SeconYellow)' }}>투입 자재/반제품</th>
                    </tr>
                    <tr>
                      <th style={{ minWidth: '80px', padding: 'unset', width: '80px' }}>공정순서</th>
                      <th>공정유형</th>
                      <th>공정코드</th>
                      <th>공정이름</th>
                      <th>작업위치</th>
                      <th>비고</th>
                      <th style={{ maxWidth: 'unset', padding: 'unset' }}>
                        <ProcessTable style={{ height: '100%' }}>
                          <thead style={{ backgroundColor: 'var(--MainNavy)' }}>
                            <tr style={{ border: 'none' }}>
                              <th>물품이름</th>
                              <th>물품코드</th>
                              <th>생산량</th>
                              <th>투입예정수량</th>
                              <th>현재고</th>
                            </tr>
                          </thead>
                        </ProcessTable>
                      </th>
                      <th style={{ maxWidth: 'unset', padding: 'unset' }}>
                        <ProcessTable style={{ height: '100%' }}>
                          <thead style={{ backgroundColor: 'var(--MainNavy)' }}>
                            <tr style={{ border: 'none' }}>
                              <th>물품이름</th>
                              <th>물품코드</th>
                              <th>투입량</th>
                              <th>투입예정수량</th>
                              <th>현재고</th>
                            </tr>
                          </thead>
                        </ProcessTable>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkNullArray(_workOrderList, []).map((thisItem) => {
                      return (
                        <RelatedWorkOrderRow
                          key={thisItem.workOrderId}
                          authority={_authority}
                          elementId={elementId}
                          content={thisItem}
                          goDetails={goDetails}
                          createWorkOrderLog={createWorkOrderLog}
                        />
                      );
                    })}
                  </tbody>
                </table>
              }
              />
            </WorkOrderList>

            <PagingComponent
              page={modalReducer.currentPageNum}
              count={modalReducer.totalCount}
              size={10}
              pageEvent={(page) => {
                console.log('page : ', page);
                dispatch(modalReducer_setCurrentPageNum(page));
              }}
            />
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default RelatedWorkOrder;
