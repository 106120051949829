const initialState = {
  inquiryStatus: 'all',

  startDate: undefined,
  endDate: undefined,
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const resetState = {
  inquiryStatus: 'all',

  startDate: undefined,
  endDate: undefined,
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const inquiryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETINQUIRYSTATUS':
      return {
        ...state,
        inquiryStatus: action.payload,
      };
    case 'SETINQUIRYSTARTDATE':
      return {
        ...state,
        startDate: action.payload,
      };
    case 'SETINQUIRYENDDATE':
      return {
        ...state,
        endDate: action.payload,
      };
    case 'SETINQUIRYSEARCHOPTION':
      return {
        ...state,
        searchOption: action.payload,
      };
    case 'SETINQUIRYSEARCHTEXT':
      return {
        ...state,
        searchText: action.payload,
      };
    case 'SETINQUIRYPAGENUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'SETINQUIRYTOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };
    case 'SETINQUIRYISPUSHEDSEARCHBUTTON':
      return {
        ...state,
        isPushedSearchButton: action.payload,
      };
    case 'SETINQUIRYRESET':
      return {
        ...state,
        ...resetState,
      };

    default:
      return state;
  }
};

export default inquiryReducer;
