import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { reciprocalTransactionProposer } from 'api/apis/platform/cluster/cluster';

import { checkNullArray } from 'components/checkValues/checkValues';
import { FaTrashCan } from "react-icons/fa6";
import { RiEditFill } from "react-icons/ri";
import { CommentBox } from 'platform/cluster/pages/modal/Comment.style';
import CommentForm from 'platform/cluster/pages/modal/CommentForm';

const SalesBoardComment = ({
  modalType, modalData,
  update,
  close
}) => {
  const location = useLocation();
  const clusterId = location.pathname.split('/platform/cluster')[0];
  const clusterPath = clusterId + '/platform/cluster/mypage';

  const { clusterReducer } = useSelector(state => state);
  const { user } = clusterReducer;

  const dataKeys = [
    { key: 'companyName', title: '회사명', },
    { key: 'number', title: '수량', },
    { key: 'detail', title: '신청 내용', },
  ];

  const [_body, setBody] = useState({ ...modalData });
  const [_proposerList, setProposerList] = useState([...modalData.reciprocalTransactionProposerList]);
  console.log('modalData : ', _proposerList);

  const [_modalType, setModalType] = useState();
  const [_modalData, setModalData] = useState();
  const [_modalStatus, setModalStatus] = useState(false);

  useEffect(() => { }, [_body, _proposerList]);

  const handleModalStatus = (type, data, index) => { // 모달 상태 변경(열기/닫기)
    setModalType(() => { return type });
    setModalData(() => {
      return {
        data: { ...data },
        index: index
      };
    });
    setTimeout(setModalStatus(() => { return true }), 1000);
  }

  const handleListUpdate = (type, data, index) => {
    console.log('type, data, index', type, data, index);

    setProposerList((prev) => {
      const returnList = [...prev];
      if (type.includes('update')) {
        const spliceData = { ...data };
        returnList.splice(index, 1, spliceData);
      } else if (type.includes('delete')) {
        returnList.splice(index, 1);
      }
      return returnList;
    })
  }

  const actDelete = async (proposer, index) => { // delete
    const confirmText = `해당 신청을 삭제하시겠습니까?`;
    if (window.confirm(confirmText)) {
      await reciprocalTransactionProposer.delete(proposer.reciprocalTransactionProposerId).then(response => {
        if (response === undefined) return;
        console.log('reciprocalTransactionProposer.delete : ', response);
        setProposerList((prev) => {
          const returnList = [...prev];
          returnList.splice(index, 1);
          return returnList;
        })
        // update();
      })
    }
  };

  return (<>
    <section>
      <div className='section-comment'>
        {checkNullArray(_proposerList, []).map((proposer, index) => {
          proposer = proposer || {};
          return (
            <CommentBox key={index + '_proposer'}>
              {(proposer.companyId === user.companyId && proposer.reciprocalTransactionProposerId) ? (
                <div className='form-btns'>
                  <button className='edit' title='신청 수정'
                    onClick={() => { handleModalStatus(`sales-comment-update`, proposer, index) }}><RiEditFill /></button>
                  <button className='delete' title='신청 삭제'
                    onClick={() => {
                      // handleModalStatus(`sales-comment-delete`, proposer, index)
                      actDelete(proposer, index);
                    }}
                  ><FaTrashCan /></button>
                </div>
              ) : null}
              <div className='top'>
                <div className='form-content'>
                  <h4>회사명</h4>
                  <input type='text' readOnly value={proposer.companyName || ''} />
                </div>
                <div className='form-content'>
                  <h4>수량</h4>
                  <input type='text' readOnly value={proposer.number?.toLocaleString() || ''} />
                </div>
              </div>
              <div className='form-content'>
                <h4>신청내용</h4>
                <textarea name='detail' readOnly={modalType.endsWith('detail')} value={proposer.detail || ''} />
              </div>
            </CommentBox>
          );
        })}
      </div>
    </section>

    {_modalStatus ? (
      <CommentForm
        modalType={_modalType}
        modalData={_modalData}
        update={(type, data, index) => {
          handleListUpdate(type, data, index);
          // update();
          setTimeout(setModalStatus(() => { return false }), 1000);
        }}
        open={_modalStatus}
        close={() => { setModalStatus(() => { return false }); }}
      />
    ) : null}
  </>);
};

export default SalesBoardComment;