import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Section, ProcurementTable, ViewerButtons } from './Incomplete.style';

import { procurementActions_setReset, procurementActions_setViewScale } from 'store/modules/actions/common/procurementActions';

import { procurementApi } from 'api/apis/procurementApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { LoadingMsg } from 'components/loading/LoadingMsg'

import ExcelDownloadModal from 'components/excel/ExcelDownloadModal';
import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import ProcurementRow from 'components/row/ProcurementRow';
import PutIncompleteElementModal from 'pages/procurement/modal/PutIncompleteElementModal';
import PutProcurementElementModal from 'pages/procurement/modal/PutProcurementElementModal';
import ProcurementPdfModal from 'pages/procurement/modal/ProcurementPdfModal';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';
import ViewMinusButton from 'components/buttons/ViewMinusButton';
import ViewPlusButton from 'components/buttons/ViewPlusButton';
import ViewResetButton from 'components/buttons/ViewResetButton';

const Incomplete = () => {
  const dispatch = useDispatch();
  const { procurementReducer, userReducer } = useSelector((state) => state);

  // 로컬 상태 관리
  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);
  const [_procurementList, setProcurementList] = useState([]);
  const [_checkedItem, setCheckedItem] = useState(new Set());
  const [
    _putProcurementElementModalStatus,
    setPutProcurementElementModalStatus,
  ] = useState(false);
  const [_createNotInStockModalStatus, setCreateNotInStockModalStatus] =
    useState(false);
  const [_printProcurementModalStatus, setPrintPocurementModalStatus] =
    useState(false);
  const [_eventProcurement, setEventProcurement] = useState({});
  const [_excelDownloadModalStatus, setExcelDownloadModalStatus] =
    useState(false);

  // 추가 상태 정의
  const [startDate, setStartDate] = useState(''); // 시작 날짜 상태
  const [endDate, setEndDate] = useState(''); // 끝 날짜 상태

  // 추가 상태: 페이지 번호, 검색 상태 등 리덕스를 사용하지 않으므로 로컬로 관리
  const [pageNumber, setPageNumber] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [searchOption, setSearchOption] = useState('');
  const [searchText, setSearchText] = useState('');
  const [procurementStatus, setProcurementStatus] = useState('incomplete');
  const [isPushedSearchButton, setIsPushedSearchButton] = useState(false);

  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter(
      (thisItem) =>
        thisItem.authority.startsWith('202') === true ||
        thisItem.authority.startsWith('205') === true,
    );
    const authorityCodes = authorityContent.map(
      (thisItem) => thisItem.authority,
    );
    setAuthority(authorityCodes);

    // 페이지 상태나 조달 상태를 리덕스가 아닌 로컬 상태로 관리
    setProcurementStatus('incomplete');

    getProcurementList(pageNumber - 1, 'incomplete', isPushedSearchButton);
    setOnload('loaded');
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getProcurementList(pageNumber - 1, 'incomplete', isPushedSearchButton);
    }
  }, [pageNumber]);

  const getProcurementList = async (
    page,
    procurementStatus,
    isPushedSearchButton,
  ) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;

    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    if (procurementStatus !== 'all') {
      BodyToPost.procurementStatus = procurementStatus;
    }

    if (isPushedSearchButton && searchOption && searchText) {
      BodyToPost[searchOption] = searchText;
    }

    await procurementApi
      .searchProcurement(paging, BodyToPost)
      .then((response) => {
        if (response === undefined) return;
        setProcurementList(response.data.content);
        setTotalSize(response.data.totalElements); // 페이징을 위해 totalSize 상태 업데이트
      });
  };

  const handleCheckedItem = (procurement, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(procurement);
    setCheckedItem(() => {
      return checkedItem;
    });
  };
  const actSearch = async () => {
    setPageNumber(1); // 검색 시 페이지 번호를 1로 리셋
    setIsPushedSearchButton(true);
    await getProcurementList(0, procurementStatus, true);
  };

  const handlePageChange = (page) => {
    setPageNumber(page); // 페이지 넘길 때 로컬 상태로 관리
  };

  /* 엑셀 다운로드 모달창 */
  const actExcelDownload = () => {
    setExcelDownloadModalStatus(true);
  };

  /* 발주상태 변경 */
  const handleProcurementStatus = async (procurement, index) => {
    if (!checkNullObject(procurement.account, false))
      return alert('거래처 선택후 상태변경이 가능합니다.');

    const procurementIndex = index;

    let eventStatusText = '';
    let eventStatus = '';
    switch (procurement.procurementStatus) {
      case 'waiting':
        eventStatusText = '진행';
        eventStatus = 'proceeding';
        break;
      case 'proceeding':
        eventStatusText = '완료';
        eventStatus = 'end';
        break;
      case 'incomplete':
        eventStatusText = '완료';
        eventStatus = 'end';
        break;
      default:
        return;
    }

    const BodyToPut = {
      ...procurement,
      companyId: userReducer.company.companyId,
      userId: userReducer.user.id,
      accountId: procurement?.account?.accountId,
      procurementStatus: eventStatus,
      procurementElementList: procurement.procurementElementList.map(
        (element) => {
          const returnData = {
            procurementElementId: element.procurementElementId,
            elementId: checkNullObject(element.element, false)
              ? element.element.elementId
              : checkEmptyNull(element.elementId, false)
                ? element.elementId
                : null,
            quantity: element.quantity, // 발주 수량
            price: element.price,
            storeQuantity: checkEmptyNull(element.storeQuantity, 0), // 미입고 수량
            savedQuantity: 0, // 입고 수량
          };
          return returnData;
        },
      ),
      deleteProcurementElementList: [],
    };
    console.log('handleProcurementStatus - BodyToPut : ', BodyToPut);

    // confirm
    const confirmText = `
    발주를 ${eventStatusText}시키겠습니까?
    ${procurement.procurementStatus === 'waiting' ? '발주 진행 시 발주 물품을 더 이상 수정할 수 없습니다.' : ''}
    `;
    if (window.confirm(confirmText) === true) {
      switch (eventStatus) {
        case 'end':
          let notInStockQuantity = 0; // 미입고 수량 확인
          procurement.procurementElementList.forEach((element) => {
            const storeQuantity = checkEmptyNull(element.storeQuantity, 0); // 미입고 수량
            if (storeQuantity > 0) notInStockQuantity++;
          });
          switch (notInStockQuantity > 0) {
            case true:
              const confirmText = `
                미입고된 제품이 있습니다. 
                추가 입고하시겠습니까?
                `;
              switch (window.confirm(confirmText)) {
                case true:
                  setEventProcurement(() => {
                    return {
                      procurementData: procurement,
                      index: procurementIndex,
                    };
                  });
                  setTimeout(setCreateNotInStockModalStatus(true), 1000);
                  break;

                default:
                  await procurementApi
                    .updateProcurement(procurement.procurementId, BodyToPut)
                    .then((response) => {
                      if (response === undefined) return;
                      console.log(
                        'procurementApi.updateProcurement : ',
                        response,
                      );

                      setProcurementList((prev) => {
                        const returnData = [...prev];
                        returnData.splice(procurementIndex, 1, response.data);
                        return returnData;
                      });
                    });
                  break;
              }
              break;

            default:
              await procurementApi
                .updateProcurement(procurement.procurementId, BodyToPut)
                .then((response) => {
                  if (response === undefined) return;
                  console.log('procurementApi.updateProcurement : ', response);

                  setProcurementList((prev) => {
                    const returnData = [...prev];
                    returnData.splice(procurementIndex, 1, response.data);
                    return returnData;
                  });
                });
              break;
          }
          break;

        default:
          await procurementApi
            .updateProcurement(procurement.procurementId, BodyToPut)
            .then((response) => {
              if (response === undefined) return;
              console.log('procurementApi.updateProcurement : ', response);

              setProcurementList((prev) => {
                const returnData = [...prev];
                returnData.splice(procurementIndex, 1, response.data);
                return returnData;
              });
            });
          break;
      }
    } else {
      return;
    }
  };

  /* 취소 */
  const actCancel = async (procurement, index) => {
    if (!checkNullObject(procurement.account, false))
      return alert('거래처 선택후 상태변경이 가능합니다.');

    const procurementIndex = index;

    let eventStatusText = '취소';
    let eventStatus = 'cancel';

    const BodyToPut = {
      ...procurement,
      companyId: userReducer.company.companyId,
      userId: userReducer.user.id,
      accountId: procurement?.account?.accountId,
      procurementStatus: eventStatus,
      procurementElementList: procurement.procurementElementList.map(
        (element) => {
          const returnData = {
            procurementElementId: element.procurementElementId,
            elementId: checkNullObject(element.element, false)
              ? element.element.elementId
              : checkEmptyNull(element.elementId, false)
                ? element.elementId
                : null,
            quantity: element.quantity, // 발주 수량
            price: element.price,
            storeQuantity: checkEmptyNull(element.storeQuantity, 0), // 미입고 수량
          };
          return returnData;
        },
      ),
      deleteProcurementElementList: [],
    };
    console.log('handleProcurementStatus - BodyToPut : ', BodyToPut);

    // confirm
    const confirmText = `발주를 ${eventStatusText}시키겠습니까?`;
    if (window.confirm(confirmText) === true) {
      await procurementApi
        .updateProcurement(procurement.procurementId, BodyToPut)
        .then((response) => {
          if (response === undefined) return;
          console.log('procurementApi.updateProcurement : ', response);

          setProcurementList((prev) => {
            const returnData = [...prev];
            returnData.splice(procurementIndex, 1, response.data);
            return returnData;
          });
        });
    } else {
      return;
    }
  };

  /* 발주서 */
  const actPrintProcurement = (procurement, index) => {
    setEventProcurement(() => {
      return procurement;
    });
    setTimeout(setPrintPocurementModalStatus(true), 1000);
  };

  return (
    <Grid3Body
      contents={
        <>
          <Section className="Main">
            <NavBar
              title={
                <div className="Title">
                  <NavTitle menuCode={'203'} />
                </div>
              }
              nav={'search'}
              firstRow={
                <>
                  <div className="SearchSection" style={{ columnGap: '20px' }}>
                    <div className="SearchSection">
                      <input
                        type="date"
                        className="SelectDate"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)} // 로컬 상태로 관리
                      />

                      <IconRightArrow />

                      <input
                        type="date"
                        className="SelectDate"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)} // 로컬 상태로 관리
                      />
                    </div>

                    <div className="ContentCBox">
                      <div className="ContentCBox">
                        <select
                          className="SearchOption"
                          onChange={(e) => setSearchOption(e.target.value)} // 리덕스가 아닌 로컬 상태 사용
                        >
                          <option value="">검색옵션</option>
                          <option value="procurementCode">발주코드</option>
                          <option value="procurementName">발주이름</option>
                          <option value="accountName">거래처명</option>
                        </select>
                        <input
                          className="SearchBar"
                          placeholder="Search..."
                          value={searchText}
                          onInput={(e) => setSearchText(e.target.value)} // 로컬 상태로 관리
                        />
                      </div>

                      <button data-searchbutton="true" onClick={actSearch}>
                        <SearchButtonIcon />
                      </button>
                      <div
                        className="ResetButton"
                        onClick={() => {
                          dispatch(procurementActions_setReset());
                          getProcurementList(0, 'all', false);
                        }}
                      >
                        초기화
                      </div>
                    </div>
                  </div>
                </>
              }
            />

            <TableSection
              content={
                <ProcurementTable
                  style={{ zoom: `${procurementReducer.viewScale}%` }}
                >
                  <thead>
                    <tr>
                      <th rowSpan={2} style={{ minWidth: '80px', width: '80px' }}>발주<br />상태</th>
                      <th rowSpan={2} style={{ minWidth: '50px', width: '50px' }}>발주서</th>
                      {/* <th rowSpan={2} style={{ minWidth: '50px', width: '50px' }}>상세보기</th> */}
                      <th rowSpan={2} style={{ minWidth: '140px', width: '140px' }}>미입고<tr />/부분입고</th>
                      <th rowSpan={2}>발주코드</th>
                      <th rowSpan={2}>발주이름</th>
                      <th rowSpan={2}>거래처</th>
                      <th rowSpan={2}>발주일</th>
                      <th rowSpan={2}>입고예정일</th>
                      <th colSpan={3} style={{ backgroundColor: 'var(--ThirdBlue)' }}>발주물품</th>
                      <th rowSpan={2}>발주총금액</th>
                      <th rowSpan={2} style={{ minWidth: '50px', width: '50px' }}></th>
                    </tr>
                    <tr>
                      <th>물품정보</th>
                      <th>수량</th>
                      <th>금액</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!checkNullArray(_procurementList, false) ? LoadingMsg()
                      : checkNullArray(_procurementList, []).map((procurement, index) => {
                        return (
                          <ProcurementRow
                            key={index + '_procurements'}
                            authority={_authority}
                            index={index}
                            content={procurement}
                            checkedItem={_checkedItem}
                            handleCheckedItem={handleCheckedItem}
                            handleProcurementStatus={handleProcurementStatus}
                            actCancel={actCancel}
                            actPrintProcurement={actPrintProcurement}
                            hideCheckbox={procurement.procurementStatus === 'incomplete'}
                          />
                        );
                      },
                      )}
                  </tbody>
                </ProcurementTable>
              }
              sectionStyle={{ height: 'calc(100% - 66px)' }}
            ></TableSection>
          </Section>

          <PagingComponent
            page={pageNumber}
            count={totalSize}
            size={10}
            pageEvent={handlePageChange}
          />

          <ViewerButtons>
            <ViewMinusButton
              viewScale={procurementReducer.viewScale}
              handleViewScale={(scale) => {
                dispatch(procurementActions_setViewScale(scale));
              }}
            />
            <ViewPlusButton
              viewScale={procurementReducer.viewScale}
              handleViewScale={(scale) => {
                dispatch(procurementActions_setViewScale(scale));
              }}
            />
            <ViewResetButton
              handleViewScale={() => {
                dispatch(procurementActions_setViewScale(100));
              }}
            />
          </ViewerButtons>

          {_excelDownloadModalStatus === true && (
            <ExcelDownloadModal
              mainTitle={'procurement'}
              subTitle={'procurement'}
              open={_excelDownloadModalStatus}
              close={() => {
                setExcelDownloadModalStatus(false);
              }}
            />
          )}

          {/* 발주물품 부분 입고 */}
          {_putProcurementElementModalStatus === true && (
            <PutProcurementElementModal
              procurement={_eventProcurement}
              actPut={(data) => {
                setPutProcurementElementModalStatus(false);
                getProcurementList(
                  procurementReducer.pageNumber - 1,
                  procurementReducer.procurementStatus,
                  procurementReducer.isPushedSearchButton,
                );
              }}
              open={_putProcurementElementModalStatus}
              close={() => {
                setPutProcurementElementModalStatus(false);
              }}
            ></PutProcurementElementModal>
          )}

          {/* 미입고 */}
          {_createNotInStockModalStatus === true && (
            <PutIncompleteElementModal
              procurement={_eventProcurement}
              actPut={() => {
                setCreateNotInStockModalStatus(false);
                getProcurementList(
                  procurementReducer.pageNumber - 1,
                  procurementReducer.procurementStatus,
                  procurementReducer.isPushedSearchButton,
                );
              }}
              open={_createNotInStockModalStatus}
              close={() => {
                setCreateNotInStockModalStatus(false);
              }}
            ></PutIncompleteElementModal>
          )}

          {_printProcurementModalStatus === true && (
            <ProcurementPdfModal
              procurement={_eventProcurement}
              open={_printProcurementModalStatus}
              close={() => {
                setPrintPocurementModalStatus(false);
              }}
            ></ProcurementPdfModal>
          )}
        </>
      }
    />
  );
};

export default Incomplete;