import React, { useEffect, useState } from 'react';

import { fileCategoryApi } from 'api/apis/fileCategoryApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import LoadingComponent from 'components/loading/LoadingComponent';

const DeleteContent = ({
  close, modalData
}) => {
  const body = {
    companyId: modalData.companyId,
    fileCategoryId: modalData.fileCategoryId,
    fileCategoryName: modalData.fileCategoryName,
    fileCategoryParentId: modalData.fileCategoryParentId || null,
  };
  const [_body, setBody] = useState(body);
  const [_loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => {}, [_body, _loadingStatus]);

  const actDelete = async(e) => { // 폴더 삭제
    const confirmText = `
    ${body.fileCategoryName} 폴더 내 모든 파일이 삭제됩니다.
    폴더를 삭제하시겠습니까?
    `;
    if(window.confirm(confirmText)) {
      setLoadingStatus(() => {return true});
      await fileCategoryApi.deleteFileCategory(body.fileCategoryId).then(response => {
        if(response === undefined) return;
        console.log('actDelete : ', response);
        alert(`${body.fileCategoryName} 폴더를 삭제했습니다.`);
        setLoadingStatus(() => {return false});
        close('update');
      }).finally(() => {
        setLoadingStatus(() => {return false});
      })
    } else {
      close();
    }
  };

  return (
    <>
    <section className='delete-content'>
      <div>
        {[
          {name: 'fileCategoryName', title: '폴더이름', disabled: true},
        ].map(((fileData) => {
          return (
            <div key={fileData.name}>
              <h4>{fileData.title}</h4>
              <input
                type='text'
                name={fileData.name}
                disabled={fileData.disabled}
                value={checkEmptyNull(_body[fileData.name], '')}
              />
            </div>
          )
        }))}
      </div>

      <button className='btn-save' onClick={actDelete}>삭제</button>
    </section>

    {_loadingStatus ? (<LoadingComponent close={close} title={'폴더를 삭제하고 있습니다...'} />) : null}
    </>
  );
};

export default DeleteContent;