import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { equipmentStatusActions_setPageNumber, equipmentStatusActions_setReset, equipmentStatusActions_setSearchData, equipmentStatusActions_setTotalSize } from 'store/modules/actions/common/equipmentStatusActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { equipmentAttributeApi } from 'api/apis/equipmentAttributeApi';
import { equipmentApi } from 'api/apis/equipmentApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { LoadingMsg } from 'components/loading/LoadingMsg';

import EquipmentLogListModal from 'pages/equipment/modal/EquipmentLogListModal';
import Grid3Body from 'components/layouts/body/Grid3Body';
import GuideText from 'components/buttons/GuideText';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';
import WorkEquipmentStatusModal from 'pages/equipment/working/modal/WorkEquipmentStatusModal';

const OperationalhistoryButton = styled.td`
  background-color: var(--Violet);
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  flex-direction: column;
  font-size: 20px;
  font-weight: 600;
  min-height: 120px;
  min-width: 120px;
  height: fit-content;
  width: fit-content;
`;

const EquipmentStatus = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const { equipmentStatusReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_equipmentList, setEquipmentList] = useState([]);
  const [_equipmentAttrList, setEquipmentAttrList] = useState([]);

  const [_equipmentStatusModal, setEquipmentStatusModal] = useState(false);
  const [_showEquipmentLogListModal, setEquipmentLogListModal] = useState(false);
  const [_eventEquipment, setEventEquipment] = useState({});

  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('108') === true || // 설비설정
      thisItem.authority.startsWith('503') === true // 설비가동
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getEquipmentAttrs();
    getEquipemntList(0);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getEquipemntList(equipmentStatusReducer.pageNumber - 1);
    }
    return () => { };
  }, [equipmentStatusReducer.pageNumber]);

  /* ========================================================================= #4 */
  const getEquipmentAttrs = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await equipmentAttributeApi.searchEquipementAttribute(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('equipmentAttributeApi.searchEquipementAttribute : ', response.data);
      setEquipmentAttrList(() => { return response.data; });
    })
  };

  const getEquipemntList = async (page, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && checkEmptyNull(equipmentStatusReducer.searchData.equipmentCode, false)) {
      BodyToPost.equipmentCode = equipmentStatusReducer.searchData.equipmentCode;
    }
    if (!clear && checkEmptyNull(equipmentStatusReducer.searchData.equipmentName, false)) {
      BodyToPost.equipmentName = equipmentStatusReducer.searchData.equipmentName;
    }
    await equipmentApi.searchEquipment(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('equipmentApi.searchEquipment : ', response.data);
      setEquipmentList(() => { return response.data.content; });
      dispatch(equipmentStatusActions_setTotalSize(response.data.totalElements));
    })
  };

  /* ========================================================================= #5 */
  /* 검색 */
  const actSearch = async () => {
    dispatch(equipmentStatusActions_setPageNumber(1));
    await getEquipemntList(0);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(equipmentStatusActions_setReset());
    await getEquipemntList(0, true);
  };

  /* 설비상태 팝업 오픈 이벤트 */
  const showStatusModal = (equipment) => {
    setEventEquipment(() => { return equipment });
    setTimeout(setEquipmentStatusModal(() => { return true }), 1000);
  };
  /* 설비상태버튼 이벤트 */
  const handleEquipmentStatus = async (equipment, equipmentStatus) => {
    let statusName = '';
    switch (equipmentStatus) {
      case 'waiting': statusName = '대기'; break;
      case 'working': statusName = '작동'; break;
      case 'pause': statusName = '일시정지'; break;
      case 'inspection': statusName = '점검'; break;

      default: return;
    }

    const BodyToPut = {
      ...equipment,
      status: equipmentStatus,
      equipmentLogList: [{ postStatus: equipmentStatus, preStatus: equipment.status, note: '' }],

      deleteEquipmentLogList: [],
      deleteEquipmentAttributeValueList: [],
    };
    alert(`설비상태를 ${statusName}(으)로 변경합니다.`);

    await equipmentApi.updateEquipment(equipment.equipmentId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('equipmentApi.updateEquipment : ', response);
      alert('설비상태가 변경됐습니다.');
      setEquipmentStatusModal(() => { return false });
      getEquipemntList(equipmentStatusReducer.pageNumber - 1);
    })
  };

  /* 이력조회 팝업 오픈 이벤트 */
  const showEquipmentLogListModal = (equipment) => {
    setEventEquipment(() => { return equipment });
    setTimeout(setEquipmentLogListModal(() => { return true }), 1000);
  };

  /* ========================================================================= #6 */

  return (
    <Grid3Body contents={
      <>
        <main className="Main">
          <NavBar
            title={<NavTitle menuCode={'503'} />}
            buttons={<></>}
            nav={'search'}
            firstRow={
              <>
                <div className="ContentCBox">
                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="equipmentName">설비이름</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkEmptyNull(equipmentStatusReducer?.searchData.equipmentName, '')}
                      onInput={(e) => {
                        dispatch(equipmentStatusActions_setSearchData({ ...equipmentStatusReducer?.searchData, equipmentName: e.target.value }))
                      }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="equipmentCode">설비코드</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkEmptyNull(equipmentStatusReducer?.searchData.equipmentCode, '')}
                      onInput={(e) => {
                        dispatch(equipmentStatusActions_setSearchData({ ...equipmentStatusReducer?.searchData, equipmentCode: e.target.value }))
                      }}
                    />
                  </div>

                  <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                  <div className="ResetButton" onClick={actReset}>초기화</div>
                </div>
              </>
            }
          />

          <TableSection content={
            <table>
              <thead>
                <tr>
                  <th style={{ minWidth: '120px', padding: 'unset', width: '120px' }}>설비상태</th>
                  <th>설비명</th>
                  <th>설비코드</th>
                  {_equipmentAttrList.map((attr, index) => {
                    return (<th key={index + '_equipmentAttrList'}>{attr.equipmentAttributeName}</th>);
                  })}
                  <th style={{ minWidth: '120px', padding: 'unset', width: '120px' }}>가동이력</th>
                </tr>
              </thead>
              <tbody>
                {!checkNullArray(_equipmentList, false) ? LoadingMsg() :
                  checkNullArray(_equipmentList, []).map((equipment, index) => {
                    const attrValueList = checkNullArray(equipment.equipmentAttributeValueList, []);
                    return (
                      <tr key={index + '_equipment'} data-key={equipment.equipmentId}>
                        <StatusTdButton
                          statusStyle={{ height: '120px', minWidth: '120px', width: '120px' }}
                          status={equipment.status}
                          statusEvent={() => { showStatusModal(equipment, index) }}
                        />
                        <td>{equipment.equipmentName}</td>
                        <td>{equipment.equipmentCode}</td>
                        {checkNullArray(_equipmentAttrList, []).map((attr, attrIndex) => {
                          const attrValueIndex = attrValueList.findIndex((attrValue) => attrValue.equipmentAttribute?.equipmentAttributeId === attr.equipmentAttributeId);
                          if (attrValueIndex !== -1) {
                            return (<td key={index + '_attrValue_' + attrIndex}>{attrValueList[attrValueIndex].value}</td>);
                          } else {
                            return (<td key={index + '_attrValue_' + attrIndex}></td>);
                          }
                        })}
                        <OperationalhistoryButton
                          onClick={(e) => {
                            e.preventDefault();
                            showEquipmentLogListModal(equipment);
                          }}
                        >
                          이력조회
                          <GuideText />
                        </OperationalhistoryButton>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          }
          />
        </main>

        <PagingComponent
          page={equipmentStatusReducer.pageNumber}
          count={equipmentStatusReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(equipmentStatusActions_setPageNumber(page));
          }}
        />

        {_equipmentStatusModal === true ? (
          <WorkEquipmentStatusModal
            equipment={_eventEquipment}
            statusEvent={(equipment, equipmentStatus) => { handleEquipmentStatus(equipment, equipmentStatus) }}
            open={_equipmentStatusModal}
            close={() => { setEquipmentStatusModal(false) }}
          />
        ) : null}

        {_showEquipmentLogListModal === true ? (
          <EquipmentLogListModal
            equipment={_eventEquipment}
            open={_showEquipmentLogListModal}
            close={() => { setEquipmentLogListModal(false) }}
          />
        ) : null}
      </>
    }
    />
  );
};

export default EquipmentStatus;
