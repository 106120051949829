import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { processApi } from 'api/apis/processApi';

import { checkNullParse } from 'components/checkValues/checkValues';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

const DeleteSection = styled.main`
  grid-template-rows: 66px calc(100% - 66px);
  grid-row-start: 1;
  grid-row-end: 3;
  height: 100%;
`;

const DeleteProcesses = () => {
  const navigate = useNavigate();
  const { pageReducer } = useSelector((state) => state);

  const [searchParams, setSearchParams] = useSearchParams();
  const prevData = checkNullParse(searchParams.get('deleteContents'), []);

  const [_deleteContents, setDeleteContents] = useState(prevData);
  // console.log('_deleteContents : ', _deleteContents);

  // let delCount = 0;
  const actAllDelete = () => {
    prevData.forEach(async (thisItem, index) => {
      await actDelete(thisItem.processId, index, thisItem);
    });
  };

  const actDelete = async (processId, index, process) => {
    await processApi.deleteProcess(processId).then((response) => {
      if (response === undefined) return;
      if (response.status === 200) {
        setDeleteContents((prev) => {
          const returnData = [...prev];
          returnData.splice(index, 1);
          return returnData;
        });
      }
    }).finally(() => {
      // delCount++;
      if (index === prevData.length - 1) {
        alert(`삭제를 완료했습니다.`);
        setTimeout(navigate(pageReducer.currentPage), 1000);
      }
    });
  };

  /* =================================================== #6들 =================================================== */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <>
        <DeleteSection className="Main">
          <NavBar
            title={<NavTitle menuCode={'112'} />}
            nav={''}
          />

          <DeleteForm
            delteTitle={<NavTitle menuCode={'112'} />}
            deleteItem={
              <>
                {_deleteContents.map((thisItem) => {
                  console.log('process : ', thisItem);
                  return (
                    <Fragment key={thisItem.processId + '_process'}>
                      <p><b>공정코드:</b> {thisItem.processCode}</p>
                      <p><b>공정이름:</b> {thisItem.processName}</p>
                      <br />
                    </Fragment>
                  );
                })}
              </>
            }
            deleteText={
              <>
                해당{' '}
                <span className="cautionText"><NavTitle menuCode={'112'} /></span>
                이/가 삭제됩니다.
              </>
            }
            deleteButton={
              <>
                <button className="formButton cancle" onClick={(e) => {
                    e.preventDefault();
                    navigate(pageReducer.currentPage, { replace: true });
                  }}
                >
                  취소
                </button>
                <button className="formButton delete" onClick={() => {
                    if (window.confirm(`삭제하시겠습니까?`)) actAllDelete();
                  }}
                >
                  삭제
                </button>
              </>
            }
          />
        </DeleteSection>
      </>
    }
    />
  );
};

export default DeleteProcesses;
