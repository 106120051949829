const initialState = {
  /* 거래처 항목 */
  accountAttributeList: [],

  /* 거래처 */
  accountContents: [],

  /* SetWorkOrderContent */
  setWorkOrderContent: [],

  /* 거래명세서 물품 */
  shipmentAddProducts: [],

  /* 출고송장 물품 */
  shipmentAddCLProducts: [],

  /* 출고송장 정보 */
  shipmentInfoContents: [],

  /* 일일계획 작업지시 */
  scheduleWorkOrder: {},
};

const YoungContentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETACCOUNTATTRIBUTELIST':
      return {
        ...state,
        accountAttributeList: action.payload,
      };

    case 'SETACCOUNTCONTENTS':
      return {
        ...state,
        accountContents: action.payload,
      };

    case 'SETWORKORDERCONTENT':
      return {
        ...state,
        setWorkOrderContent: action.payload,
      };

    case 'SETINVOICEADDPRODUCTS':
      return {
        ...state,
        shipmentAddProducts: action.payload,
      };

    case 'SETINVOICEADDCLPRODUCTS':
      return {
        ...state,
        shipmentAddCLProducts: action.payload,
      };

    case 'SETINVOICEINFOCONTENTS':
      return {
        ...state,
        shipmentInfoContents: action.payload,
      };

    case 'SETSCHEDULEWORKORDER':
      return {
        ...state,
        scheduleWorkOrder: action.payload,
      };

    case 'YOUNGRESET':
      return initialState;

    default:
      return state;
  }
};

export default YoungContentsReducer;
