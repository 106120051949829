import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { removeCookie } from 'preferences/cookie/cookie';

import { mallSiteUserActions_setUser } from 'store/modules/actions/mallSite/mallSiteUserActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { userReducer_reset, userReducer_setUserMenu } from 'store/modules/actions/user/userActions';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';


/* =================================================================================================================== Dashboard */
const BoardSection = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 60px auto 60px;
  height: 100svh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100svw;

  & header {box-shadow: unset;}
  & footer {bottom: 8px; box-shadow: unset;}
`;

const BarcodeIndex = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { menuReducer, userReducer } = useSelector((state) => state);
  const { totalMenuLinks, totalMenu } = menuReducer;
  const { company, userMenuCode } = userReducer;

  // const useGenerateRandomColor = () => {
  //   return { color, generateColor };
  // };
  const [color, setColor] = useState("")
  const generateColor = () => {
    // const backgroundImage = "linear-gradient(to top, #a8edea 0%, #fed6e3 100%)";
    setColor(Math.random().toString(16).substr(-6));
  };
  // const { color, generateColor }
  //       = useGenerateRandomColor();
  /* ====================================================================== #2 */
  const [_userBoardMenu, setUserBoardMenu] = useState([]);

  /* ====================================================================== #3 */
  useEffect(() => {
    if (userReducer.user.role === 'ROLE_MALL' || userReducer.user.role === 'ROLE_GUEST' || !checkEmptyNull(userReducer.user.team, false)) {
      alert(`
        부여받은 권한이 없습니다.
        관리자에게 문의해주세요.
      `);

      console.log('================================= removeCookie');
      removeCookie('accessToken');
      removeCookie('refreshToken');
      dispatch(userReducer_reset());
      dispatch(mallSiteUserActions_setUser());

      return navigate('/', { replace: true });;
    }
    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    createUserMenuArray();
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const createUserMenuArray = () => {
    const TopMenu = [...totalMenu.top];
    const SubMenu = [...totalMenu.sub];

    const setActiveSubMenuList = SubMenu.map((thisItem) => {
      const pageIndex = company.pageUrl.pageIndex;
      const thisIndex = Object.keys(pageIndex).findIndex((menuCode) => menuCode === thisItem.menuCode);
      if (thisIndex !== -1) {
        const returnData = {
          menu: thisItem,
          index: pageIndex[thisItem.menuCode] !== '' ? Number(pageIndex[thisItem.menuCode]) : 0,
        };
        return returnData;
      } else return null;
    });
    const filterActiveSubMenuList = setActiveSubMenuList.filter((thisItem) => thisItem !== null);
    const activeSubMenuArray = filterActiveSubMenuList.map((thisItem) => {
      return {
        ...thisItem.menu,
        menuIndex: thisItem.index,
        menuGroup: parseInt(thisItem.menu.menuCode.charAt(0)),
      };
    }); // 활성화된 하위메뉴

    const activeTopMenuCode = activeSubMenuArray.map((thisMenu) => {
      const checkMenu = TopMenu.findIndex((thisItem) => thisItem.menuCode.charAt(0) === thisMenu.menuCode.charAt(0)); // 하위메뉴랑 앞 코드가 같은 상위메뉴 체크 (100 ↔ 101)
      if (checkMenu !== -1) return TopMenu[checkMenu].menuCode;
      else return null;
    });
    const filterActiveTopMenuCode = activeTopMenuCode.filter((thisItem) => thisItem !== null);
    const setActiveTopMenuCode = [...new Set(filterActiveTopMenuCode)]; // 배열 중복 제거

    const activeTopMenuArray = TopMenu.filter((thisItem) => setActiveTopMenuCode.indexOf(thisItem.menuCode) !== -1); // 활성화된 상위메뉴 정보 가져오기

    const setSubMenuLinks = activeSubMenuArray.map((thisMenu) => {
      return { ...thisMenu, menuLink: totalMenuLinks[thisMenu.menuCode] };
    }); // 하위메뉴 링크 추가

    const setTopMenuLinks = activeTopMenuArray.map((thisMenu) => {
      const getFirstSubMenuCode = setSubMenuLinks.filter((thisItem) => thisItem.menuCode.charAt(0) === thisMenu.menuCode.charAt(0))[0]; // 앞 코드가 같은 하위메뉴 중 첫번째 코드 가져오기 (100 ↔ 101)
      return {
        ...thisMenu,
        menuLink: getFirstSubMenuCode.menuLink,
        menuIndex: 0,
        menuGroup: parseInt(thisMenu.menuCode.charAt(0)),
      };
    }); // 상위메뉴 링크 추가

    const userMenuArray = [...setTopMenuLinks, ...setSubMenuLinks];
    // const userMenuArray = setTopMenuLinks.concat(setSubMenuLinks); // 배열 합치기
    if (userMenuArray.length === 0) return navigate('/', { replace: true });
    const sortUserMenuList = [...userMenuArray].sort((a, b) => a.menuGroup - b.menuGroup || a.menuIndex - b.menuIndex);

    dispatch(userReducer_setUserMenu(sortUserMenuList));
    setUserBoardMenu(() => { return sortUserMenuList; });
  };

  /* ====================================================================== #5 */
  /* ====================================================================== #6 */

  /* =================================================================================================================== BoardMenu */
  const BoardMenu = (props) => {
    return (
      <Link to={props.boardmenu.menuLink} className='barcodeMenu'>
        {props.boardmenu.menuName}
      </Link>
    );
  };
  
  const [scanModal, setScanModal] = useState(false);

  const BarcodeScanModal = () => {
    setScanModal(true);
  }
  return (
    <BoardSection>
      <Header />
        {checkNullArray(_userBoardMenu, []).map((menu) => {
          if (menu.menuCode.endsWith('00') === true) {
            return (
              <></>
              // <BoardMenu key={'BoardMenu' + menu.menuCode} boardmenu={menu} />
            );
          } else {
            return null;
          }
        })}
        {(() => {
          if (userReducer.user.role === 'ROLE_MASTER' || userReducer.company.companyId === 1 || userReducer.company.companyId === 19) {
            return (
              <div className='barcodePage'>
                {/* {scanModal ? (
                  <div className='barcodePageModal'>
                    <input type='text' placeholder='이곳을 클릭 후 스캐너로 바코드를 스캔해주세요' />
                    <Link to='/barcode/scan'>확인</Link>
                  </div>
                ):
                  <button onClick={BarcodeScanModal} className='barcodeMenu'>스캔</button>
                } */}
                <BoardMenu boardmenu={{ menuLink: '/barcode/scan', menuName: '스캔' }} />
                <BoardMenu boardmenu={{ menuLink: '/barcode/print', menuName: '출력' }} />
              </div>
            );
          } else {
            return null;
          }
        })()}
      <Footer />
    </BoardSection>
  );
};

export default BarcodeIndex;
