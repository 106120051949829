import styled from 'styled-components';

export const GSManHourModalComponent = styled.section`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;


  &>section { // 모달 섹션
    background-color: var(--white);
    border-radius: 10px;
    display: grid;
    grid-template-rows: 55px auto 50px;
    height: 90%;
    overflow: hidden;
    width: 85%;

    &>header { // 모달 타이틀
      align-items: center;
      background-color: var(--gray-100);
      box-sizing: border-box;
      display: flex;
      height: 55px;
      justify-content: flex-start;
      padding: 0px 20px;
      position: relative;
      width: 100%;

      h3 { // 타이틀
        font-size: 20px;
        font-weight: 400;
        white-space: nowrap;
      }
      div.btn-close { // 닫기 버튼
        height: 25px;
        position: absolute;
        right: 15px;
        top: 15px;
        width: 25px;
      }
    }

    &>section:first-of-type { // 모달 메인
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
      width: 100%;

      /* &>nav {
        display: grid;
        grid-template-columns: 50% 50%;
        height: 50px;
        position: sticky;
        top: 0px;
        width: 100%;
        z-index: 1;

        & .btn-set.active {
          background-color: var(--MainBlue);
          color: var(--white);
          font-weight: 600;
        }
      } */

      &>section { // 모달 컨텐트
        box-sizing: border-box;
        display: grid;
        gap: 20px;
        height: fit-content;
        padding: 30px;
        width: 100%;

        &>header {
          align-items: center;
          display: flex;
          height: 40px;
          justify-content: space-between;
          /* width: 100%; */

          &>button.btn-set {
            background-color: var(--MainBlue);
            color: var(--white);
            padding-inline: 20px;
            /* width: fit-content; */
          }
        }

        & section.main-section {
          display: grid;
          gap: 2%;
          grid-template-columns: 49% 49%;
          width: 100%;

          & .main-info {
            align-content: flex-start;
            display: grid;
            gap: 20px;
            
            .info-box {
              background-color: var(--Bg);
              border-radius: 20px;
              box-sizing: border-box;
              display: grid;
              gap: 10px;
              height: fit-content;
              padding: 20px;

              & p{
                display: grid;
                gap: 10px;
                grid-template-columns: 100px calc(100% - 110px);
                width: 100%;
      
                & span:first-child{
                  display: inline-block;
                  font-weight: 600;
                  text-align: right;
                  width: 100px;
                }
              }
            }
      
            & .worker-form {
              display: grid;
              gap: 15px;
      
              &>div {
                display: grid;
                gap: 5px;
      
                & div {
                  align-items: center;
                  display: flex;
                  flex-wrap: wrap;
                  gap: 10px;
                  justify-content: flex-start;
                  width: 100%;
                }

                & h5 {
                  display: inline-block;
                  position: relative;
                  width: 100%;

                  & span {
                    color: var(--MainNavy);
                    position: absolute;
                    right: 0;
                  }
                }
              }
      
              & input, & select, & textarea {
                border: 1px solid var(--gray-200);
                border-radius: 10px;
                box-sizing: border-box;
                padding: 5px 10px;
              }

              & .btn-set {
                background-color: var(--MainBlue);
                color: var(--white);
                height: 50px;
                width: 100%;
              }
            }
          }
          & .main-worker{
            align-content: flex-start;
            display: grid;
            gap: 20px;

            & .workingTime {
              /* background-color: var(--MainNavy); */
              /* background-color: #fcfcfc; */
              border-radius: 10px;
              box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
              box-sizing: border-box;
              /* color: var(--white); */
              display: grid;
              gap: 10px;
              height: fit-content;
              padding: 20px;
              position: relative;
              width: 100%;

              & h5 {
                font-size: 1.2em;
              }
              &>div {
                display: grid;
                gap: 2px;

                p {
                  font-size: 1em;
                }
              }
            }
          }
        }
      }
    }

    &>section:last-of-type { // 모달 푸터
      align-items: center;
      display: flex;
      height: 50px;
      justify-content: center;
      width: 100%;

      div {
        align-items: center;
        background-color: var(--MainNavy);
        color: var(--white);
        cursor: pointer;
        display: flex;
        height: 50px;
        justify-content: center;
        width: 100%;
      }
    }
  }
`;

export const DeleteBtn = styled.div`
  height: 15px;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 15px;

  div {background-color: var(--MainRed); cursor: pointer; height: 20px; width: 20px;}
`;