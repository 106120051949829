import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { peopleSharingType } from 'api/apis/platform/cluster/cluster';

const PeopleType = ({
  modalType, modalData,
  update,
  close
}) => {
  const location = useLocation();
  const clusterId = location.pathname.split('/platform/cluster')[0];
  const clusterPath = clusterId + '/platform/cluster/mypage';

  const [_body, setBody] = useState({ ...modalData });
  useEffect(() => { }, [_body]);

  const actSave = async (e) => { // create/update
    e.preventDefault();
    const id = Number(clusterId.replace('/', ''));
    const body = { clusterId: id, ..._body };
    if (modalType.includes('create')) { // create
      await peopleSharingType.create(body).then(response => {
        if (response === undefined) return;
        console.log('peopleSharingType.create : ', response);
        update();
      })
    } else { // update
      delete body?.createdDate;
      delete body?.modifiedDate;

      await peopleSharingType.update(body.peopleSharingTypeId, body).then(response => {
        if (response === undefined) return;
        console.log('peopleSharingType.update : ', response);
        update();
      })
    }
  };
  const actDelete = async () => { // delete
    const confirmText = `해당 유형을 삭제하시겠습니까?`;
    if (window.confirm(confirmText)) {
      await peopleSharingType.delete(_body.peopleSharingTypeId).then(response => {
        if (response === undefined) return;
        console.log('peopleSharingType.delete : ', response);
        update();
      })
    }
  };

  return (
    <section>
      <div className='section-form'>
        <h2>인력 유형 정보</h2>
        <div className='form-content'>
          <h4>유형 이름</h4>
          <input
            type='text'
            placeholder='유형 이름을 입력해주세요.'
            disabled={modalType.endsWith('delete')}
            value={_body.peopleSharingTypeName || ''}
            onInput={(e) => {
              setBody((prev) => {
                return { ...prev, peopleSharingTypeName: e.target.value }
              })
            }}
          />
        </div>
      </div>
      <div className='section-action'>
        <button className='btn-cancel' onClick={close}>취소</button>
        {!modalType.endsWith('delete') ? (<button className='btn-save' onClick={actSave}>저장</button>) : (<button className='btn-delete' onClick={actDelete}>삭제</button>)}
      </div>
    </section>
  );
};

export default PeopleType;