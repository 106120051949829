import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { pageReducer_getCurrentPage, pageReducer_setPageNum, pageReducer_setPageSize, pageReducer_setTotalCount } from 'store/modules/actions/default/pageActions';

import { coreElementApi } from 'api/apis/coreElementApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import Grid3Body from 'components/layouts/body/Grid3Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import Paging from 'components/paging/Paging';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import SelectOneElement from 'pages/element/modal/SelectOneElement';
import TableSection from 'components/layouts/table/TableSection';

const CoreElementManagement = () => {
  /* ========================================================================= #1들 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_coreElementList, setCoreElementList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_searchElementData, setSearchElementData] = useState({});

  const [_modalStatus, setModalStatus] = useState(false);

  /* ========================================================================= #3 */
  useEffect(() => {
    // 권한 체크
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('904') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getCoreElements(0);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    dispatch(pageReducer_setPageNum(1));
    dispatch(pageReducer_setPageSize(10));

    return () => { };
  }, [pageReducer.currentPage]);

  useEffect(() => {
    if (_onload === 'loaded') {
      getCoreElements(pageReducer.currentPageNum - 1);
    }

    return () => { };
  }, [pageReducer.currentPageNum]);

  /* ========================================================================= #4 */
  const getCoreElements = async (page, clear) => {
    const paging = `?page=${page}&size=${pageReducer.pageSize}&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    if (!clear && checkEmptyNull(_searchElementData.elementName, false)) {
      BodyToPost.elementName = _searchElementData.elementName;
    }
    if (!clear && checkEmptyNull(_searchElementData.elementCode, false)) {
      BodyToPost.elementCode = _searchElementData.elementCode;
    }
    console.log('getCoreElements - BodyToPost : ', BodyToPost);

    await coreElementApi.searchCoreElement(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('coreElementApi.searchCoreElement : ', response);
      setCoreElementList(() => { return response.data.content; });
      dispatch(pageReducer_setTotalCount(response.data.totalElements));
    });
  };

  /* ========================================================================= #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(id);
    setCheckedItem(() => { return checkedItem; });
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(pageReducer_setPageNum(1));
    await getCoreElements(0);
  };

  /* 초기화 */
  const actReset = async () => {
    setSearchElementData(() => { return {}; });
    dispatch(pageReducer_setPageNum(1));
    await getCoreElements(0, true);
  };

  /* 삭제 */
  const actDelete = () => {
    const deleteContentIndex = _coreElementList.findIndex((thisItem) => thisItem.coreElementId === Array.from(_checkedItem)[0]);
    if (deleteContentIndex === -1) {
      const alertText = `
      삭제할 ${userReducer.userMenu.find((thisItem) => {
        if (thisItem.pageCode === '904') return true;
        else return false;
      }).pageName} 을 선택해주세요.
      `;
      return alert(alertText);
    }
    const deleteContent = _coreElementList[deleteContentIndex];
    console.log('deleteContent : ', deleteContent);

    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams(deleteContent)}`,
      replace: true,
    });
  };

  /* ========================================================================= #6 */

  return (
    <Grid3Body contents={
      <>
        <main className="Main">
          <NavBar
            title={<NavTitle menuCode={'904'} />}
            buttons={
              <>
                {_authority.indexOf('904-2') !== -1 ? (
                  <>
                    <button onClick={() => { setModalStatus(true); }}>추가</button>
                    <button onClick={actDelete}>삭제</button>
                  </>
                ) : null}
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="SearchSection">
                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="elementName">물품이름</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={_searchElementData.elementName || ''}
                      onInput={(e) => { setSearchElementData((prev) => { return { ...prev, elementName: e.target.value }; }); }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="elementCode">물품코드</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={_searchElementData.elementCode || ''}
                      onInput={(e) => { setSearchElementData((prev) => { return { ...prev, elementCode: e.target.value }; }); }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <div className="ResetButton detail" onClick={actReset}>초기화</div>
                    <div className="DetailButton search"
                      style={{ backgroundColor: 'var(--ThirdBlue)', columnGap: '3px' }}
                      onClick={actSearch}
                    >
                      <SearchButtonIcon />
                      검색
                    </div>
                  </div>
                </div>
              </>
            }
          />

          <TableSection content={
            <table>
              <thead>
                <tr>
                  <th style={{ minWidth: '50px', width: '50px' }}></th>
                  <th>물품이름</th>
                  <th>물품코드</th>
                </tr>
              </thead>
              <tbody>
                {checkNullArray(_coreElementList, []).map((thisItem) => {
                  return (
                    <tr key={thisItem.coreElementId + '_coreElementRow'} data-key={thisItem.coreElementId}>
                      {_authority.indexOf('904-2') !== -1 ? (
                        <td style={{ minWidth: '50px', width: '50px' }}>
                          <input
                            type="checkBox"
                            name="coreElements"
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              handleCheckedItem(thisItem.equipmentId, isChecked);
                              if (isChecked) {
                                document.getElementsByName('coreElements').forEach((thisAttr) => { thisAttr.checked = false; });
                                e.target.checked = true;
                              } else {
                                e.target.checked = false;
                              }
                            }}
                          />
                        </td>
                      ) : null}
                      <td>{thisItem.elementName}</td>
                      <td>{thisItem.elementCode}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          }
          />
        </main>

        {_modalStatus === true && (
          <SelectOneElement
            buttonEvent={async (data) => {
              console.log('data : ', data);
              const BodyToPost = {
                companyId: userReducer.company.companyId,
                coreElementId: data.elementId,
              };
              await coreElementApi.createCoreElement(BodyToPost).then((response) => {
                if (response === undefined) return;
                console.log('coreElementApi.createCoreElement : ', response);

                const alertText = `
                ${userReducer.userMenu.find((thisItem) => {
                  if (thisItem.pageCode === '904') return true;
                  else return false;
                }).pageName} 을 추가했습니다.
                  `;
                alert(alertText);
              }).finally(() => {
                setModalStatus(false);
              });
            }}
            buttonTitle={<><NavTitle menuCode={'904'} />추가</>}
            open={_modalStatus}
            close={() => { setModalStatus(false); }}
          />
        )}

        <Paging
          page={pageReducer.currentPageNum}
          count={pageReducer.totalCount}
          size={pageReducer.pageSize}
        />
      </>
    }
    />
  );
};

export default CoreElementManagement;
