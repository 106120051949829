import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { contentsReducer_setDeleteContents } from 'store/modules/actions/default/contentsActions';
import { modalFileActions_setFileReset } from 'store/modules/actions/modal/modalFileActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { workOrderActions_setBookmark, workOrderActions_setPageNumber, workOrderActions_setReset, workOrderActions_setSearchData, workOrderActions_setTotalSize, workOrderActions_setViewScale, workOrderActions_setWorkOrderType } from 'store/modules/actions/common/workOrderActions';

import { bookmark } from 'api/apis/bookmark/bookmark';
import { workOrderTypeApi } from 'api/apis/workOrderTypeApi';
import { workOrderApi } from 'api/apis/workOrderApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { LoadingMsg } from 'components/loading/LoadingMsg'

import Bookmark from 'components/icons/Bookmark';
import Grid3Body from 'components/layouts/body/Grid3Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import ShowFileList from 'pages/file/modal/ShowFileList';
import TableSection from 'components/layouts/table/TableSection';
import ViewMinusButton from 'components/buttons/ViewMinusButton';
import ViewPlusButton from 'components/buttons/ViewPlusButton';
import ViewResetButton from 'components/buttons/ViewResetButton';
import WorkOrderRow from 'components/row/WorkOrderRow';


const Section = styled.main`
  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
    & .typeButtons {
      display: flex;
      gap: 5px;
    }
  }
  & .TableSection {
    tr[name='workOrderRows']:hover {cursor: pointer;}

    td.targetCell {
      background-color: var(--MainNavy);
      color: var(--white);
      div[name='DOCButtonIcon'] {background-color: var(--white);}
    }
    td.blankCell {background-color: var(--Bg); opacity: 0.8;}
    & .table-preview {
      /* background-color: var(--MainNavy); */
      .no-image {opacity: 0.4;}
    }
  }
`;

const ProcessTable = styled.table`
  border-collapse: collapse;
  min-width: 100%;
  table-layout: fixed;

  td, th {
    box-sizing: border-box;
    padding: 5px 15px;
    min-width: 150px !important;
    text-align: center;
    white-space: normal;
    word-break: break-all;
  }
  th {border: none;}
  td.blankCell {background-color: var(--Bg); opacity: 0.8;}
`;

const ViewerButtons = styled.div`
  z-index: 999;
  position: fixed;
  right: 76px;
  bottom: 12px;
  column-gap: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WorkOrderManagement = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, userReducer, workOrderReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_workOrderTypes, setWorkOrderTypes] = useState([]);

  const [_workOrderList, setWorkOrderList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_fileListModalStatus, setFileListModalStatus] = useState(false);
  const [_eventcontent, setEventContent] = useState({});

  const [_viewContent, setViewContent] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('104') === true || // 거래처
      thisItem.authority.startsWith('109') === true || // 파일
      thisItem.authority.startsWith('111') === true, // 작업정의
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    setOnload('loaded');

    getWorkOrderTypes();
    getWorkOrderList(0, workOrderReducer.workOrderType);

    return () => { };
  }, []);


  useEffect(() => {
    if (_onload === 'loaded') {
      getWorkOrderList(workOrderReducer.pageNumber - 1, workOrderReducer.workOrderType);
    }

    return () => { };
  }, [workOrderReducer.pageNumber]);

  useEffect(() => {
    if (_onload === 'loaded') {
      dispatch(workOrderActions_setPageNumber(1));
      getWorkOrderList(0, workOrderReducer.workOrderType);
    }
    return () => { };
  }, [workOrderReducer.bookmark]);

  useEffect(() => { }, [_viewContent]);

  /* ====================================================================== #4 */
  const getWorkOrderTypes = async () => {
    const BodyToPost = { companyId: userReducer.company.companyId };
    await workOrderTypeApi.searchWorkOrderType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderTypeApi.searchWorkOrderType : ', response);
      setWorkOrderTypes(() => { return response.data });
    });
  };

  const getWorkOrderList = async (page, workOrderType, clear) => {
    let paging = `?page=${page}&size=10`;
    if (workOrderReducer.bookmark) paging += '&sort=bookMark,DESC&sort=id,DESC';
    else paging += '&sort=id,DESC';

    const BodyToPost = { companyId: userReducer.company.companyId };
    if (!clear && checkEmptyNull(workOrderType, false) && workOrderType !== 'all') {
      BodyToPost.workOrderTypeId = Number(workOrderType);
    }
    if (!clear && checkEmptyNull(workOrderReducer.searchData.workOrderCode, false)) {
      BodyToPost.workOrderCode = workOrderReducer.searchData.workOrderCode;
    }
    if (!clear && checkEmptyNull(workOrderReducer.searchData.workOrderName, false)) {
      BodyToPost.workOrderName = workOrderReducer.searchData.workOrderName;
    }
    await workOrderApi.searchWorkOrder(paging, BodyToPost).then((response) => {
      console.log('workOrderApi.searchWorkOrder : ', response);
      setWorkOrderList(() => { return response.data.content });
      dispatch(workOrderActions_setTotalSize(response.data.totalElements));
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set([..._checkedItem]);
    if (isChecked) checkedItem.add(id);
    else if (!isChecked && checkedItem.has(id)) checkedItem.delete(id);
    setCheckedItem(() => { return checkedItem });
  };

  const handleBookmark = async (workOrder, index) => {
    await bookmark.workOrderBookmark(workOrder.workOrderId).then(response => {
      if (response === undefined) return;
      console.log('bookmark.workOrderBookmark : ', response);
      getWorkOrderList(workOrderReducer.pageNumber - 1, workOrderReducer.workOrderType);
    })
  }

  const actSearch = async () => {
    dispatch(workOrderActions_setPageNumber(1));
    await getWorkOrderList(0, workOrderReducer.workOrderType);
  };
  const actReset = async () => {
    dispatch(workOrderActions_setReset());
    dispatch(workOrderActions_setPageNumber(1));
    await getWorkOrderList(0, 'all', true);
  };

  const showFileList = (content, work) => {
    setEventContent(() => {
      if (work === true) return { ...content, work: true };
      else return content;
    });
    dispatch(modalFileActions_setFileReset());
    setTimeout(setFileListModalStatus(true), 1000);
  };

  const goDetails = (workOrder) => {
    const detailContent = workOrder;
    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({ workOrderId: detailContent.workOrderId })}`,
      replace: true,
    });
  };

  const createWorkOrderLog = (workOrder) => {
    const workOrderContent = workOrder;
    navigate({
      pathname: '/baseInformation/workOrder/workOrderLog/create',
      search: `?${createSearchParams({ workOrderId: workOrderContent.workOrderId })}`,
      replace: true,
    });
  };

  const actCopy = (e) => {
    e.preventDefault();
    const checkedElementList = Array.from(_checkedItem);
    if (checkedElementList.length < 1) return;
    else if (checkedElementList.length > 1) return alert('다중 선택되어 있습니다.');

    const copyContentIndex = _workOrderList.findIndex((thisItem) => thisItem.workOrderId === checkedElementList[0]);
    if (copyContentIndex === -1) return alert('복사할 작업을 선택해 주세요.');

    const copyContent = _workOrderList[copyContentIndex];
    const workOrderProcessList = copyContent.workOrderProcessList.map((thisProcess) => {
      let returnProcessData = { ...thisProcess };
      if (checkNullObject(thisProcess.process, false)) {
        returnProcessData = { ...returnProcessData, ...thisProcess.process };
      }
      return returnProcessData;
    });
    copyContent.workOrderProcessList = workOrderProcessList;

    navigate({
      pathname: pageReducer.currentPage + '/copy',
      search: `?${createSearchParams({ workOrderId: copyContent.workOrderId })}`,
      replace: true,
    })
  };
  const actUpdate = (e) => {
    e.preventDefault();
    const checkedElementList = Array.from(_checkedItem);
    if (checkedElementList.length < 1) return;
    else if (checkedElementList.length > 1) return alert('다중 선택되어 있습니다.');

    const updateContentIndex = _workOrderList.findIndex((thisItem) => thisItem.workOrderId === checkedElementList[0]);
    if (updateContentIndex === -1) return alert('수정할 작업을 선택해 주세요.');

    const updateContent = _workOrderList[updateContentIndex];
    const workOrderProcessList = updateContent.workOrderProcessList.map((thisProcess) => {
      let returnProcessData = { ...thisProcess };
      if (checkNullObject(thisProcess.process, false)) {
        returnProcessData = { ...returnProcessData, ...thisProcess.process };
      }
      return returnProcessData;
    });
    updateContent.workOrderProcessList = workOrderProcessList;

    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({ workOrderId: updateContent.workOrderId })}`,
      replace: true,
    })
  };
  const actDelete = (e) => {
    e.preventDefault();
    const checkedElementList = Array.from(_checkedItem);
    if (checkedElementList.length < 1) return;

    const deleteContents = checkedElementList.map((thisKey) => {
      const deleteContentIndex = _workOrderList.findIndex((thisIndex) => thisIndex.workOrderId === thisKey);
      if (deleteContentIndex === -1) return null;
      const deleteContent = _workOrderList[deleteContentIndex];
      return deleteContent;
    });
    const setDeleteContents = deleteContents.filter((thisItem) => thisItem !== null);
    if (setDeleteContents.length < 1) return;

    dispatch(contentsReducer_setDeleteContents(deleteContents));
    setTimeout(navigate(pageReducer.currentPage + '/delete', { replace: true }), 1000);
  };

  /* ====================================================================== #6 */
  return (
    <Grid3Body contents={
      <>
        <Section className="Main">
          <NavBar
            title={
              <div className='Title'>
                <Link to='/baseInformation/process' style={{ color: '#ccc' }}><NavTitle menuCode={'112'} /></Link>
                <NavTitle menuCode={'111'} />
                <div className='typeButtons' style={{ display: 'flex' }}>
                  <button
                    className={workOrderReducer.workOrderType === 'all' ? 'btn-inven-1' : 'btn-set'}
                    key={'all' + '_workOrderTypes'}
                    value='all'
                    onClick={() => {
                      dispatch(workOrderActions_setWorkOrderType("all"));
                      dispatch(workOrderActions_setPageNumber(1));
                      getWorkOrderList(0, "all", 'all');
                    }}
                  >
                    전체
                  </button>
                  <select
                    className="SelectType"
                    value={workOrderReducer.workOrderType}
                    onChange={(e) => {
                      dispatch(workOrderActions_setWorkOrderType(e.target.value))
                      dispatch(workOrderActions_setPageNumber(1));
                      getWorkOrderList(0, e.target.value);
                    }}
                  >
                    <option value="all">작업유형</option>
                    {_workOrderTypes.map((thisItem) => {
                      return (<option key={thisItem.workOrderTypeId + 'workOrderReducer.workOrderType'} value={thisItem.workOrderTypeId}>{thisItem.workOrderTypeName}</option>);
                    })}
                  </select>
                </div>
              </div>
            }
            buttons={
              <>
                {_authority.indexOf('111-2') !== -1 ? (
                  <>
                    <button className='btn-add'
                      onClick={(e) => {
                        e.preventDefault();
                        setTimeout(navigate(pageReducer.currentPage + '/create', { replace: true }), 1000);
                      }}
                    >
                      추가
                    </button>
                    <button className='btn-copy' onClick={actCopy}>복사</button>
                    <button className='btn-edit' onClick={actUpdate}>수정</button>
                    <button className='btn-delete' onClick={actDelete}>삭제</button>
                  </>
                ) : null}
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="SearchSection">
                  <div className="SearchSection">
                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="workOrderCode">작업코드</option>
                      </select>
                      <input
                        className="SearchBar detail"
                        placeholder="Search..."
                        value={checkEmptyNull(workOrderReducer.searchData.workOrderCode, '')}
                        onInput={(e) => {
                          dispatch(workOrderActions_setSearchData({ ...workOrderReducer.searchData, workOrderCode: e.target.value }))
                        }}
                      />
                    </div>

                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="workOrderName">작업이름</option>
                      </select>
                      <input
                        className="SearchBar detail"
                        placeholder="Search..."
                        value={checkEmptyNull(workOrderReducer.searchData.workOrderName, '')}
                        onInput={(e) => {
                          dispatch(workOrderActions_setSearchData({ ...workOrderReducer.searchData, workOrderName: e.target.value }))
                        }}
                      />
                    </div>

                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton" onClick={actReset}>초기화</div>
                  </div>
                </div>
              </>
            }
          />

          <TableSection content={
            <table style={{ zoom: `${workOrderReducer.viewScale}%` }}>
              <thead>
                <tr style={{ border: 'none' }}>
                  {(() => {
                    if (_authority.indexOf('111-2') !== -1) {
                      return (
                        <th rowSpan={2} style={{ minWidth: '50px', width: '50px' }}>
                          <input
                            type="checkBox"
                            name="allElements"
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              const checkedItem = new Set([..._checkedItem]);

                              if (isChecked) {
                                document.getElementsByName('workOrder').forEach((thisAttr) => {
                                  checkedItem.add(Number(thisAttr.dataset.key));
                                  thisAttr.checked = true;
                                });
                                e.target.checked = true;
                              } else {
                                document.getElementsByName('workOrder').forEach((thisAttr) => {
                                  checkedItem.delete(Number(thisAttr.dataset.key));
                                  thisAttr.checked = false;
                                });
                                e.target.checked = false;
                              }

                              setCheckedItem(() => { return checkedItem });
                            }}
                          />
                        </th>
                      );
                    } else {
                      return null;
                    }
                  })()}
                  <th rowSpan={2} style={{ minWidth: '50px', width: '50px' }}>
                    <Bookmark
                      bookmark={workOrderReducer.bookmark}
                      clickEvent={() => {
                        dispatch(workOrderActions_setBookmark(!workOrderReducer.bookmark))
                      }}
                    />
                  </th>
                  {(_authority.indexOf('111-2') !== -1) ? (
                    <th rowSpan={2} style={{ minWidth: '100px', width: '100px' }}>작업지시</th>
                  ) : null}
                  {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? (
                    <th rowSpan={2}>파일</th>
                  ) : null}
                  <th rowSpan={2}>작업유형</th>
                  <th rowSpan={2}>작업코드</th>
                  <th rowSpan={2}>작업이름</th>
                  {(_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) ? (
                    <th rowSpan={2}>거래처</th>
                  ) : null}
                  <th rowSpan={2}>작업위치</th>
                  <th rowSpan={2}>비고</th>
                  <th rowSpan={2}>생산<br />가능수량</th>
                  <th colSpan={(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? 7 : 6}
                    style={{ backgroundColor: 'var(--MainBlue)' }}
                  >
                    공정
                  </th>
                  <th style={{ backgroundColor: 'var(--ThirdBlue)' }}>공정완료제품</th>
                  <th style={{ backgroundColor: 'var(--SeconYellow)' }}>투입 자재/반제품</th>
                </tr>
                <tr>
                  <th style={{ minWidth: '80px', padding: 'unset', width: '80px' }}>공정순서</th>
                  {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? (
                    <th style={{ minWidth: '70px', width: '70px' }}>파일</th>
                  ) : null}
                  <th>공정유형</th>
                  <th>공정코드</th>
                  <th>공정이름</th>
                  <th>작업위치</th>
                  <th>비고</th>
                  <th style={{ maxWidth: 'unset', padding: 'unset' }}>
                    <ProcessTable>
                      <thead style={{ backgroundColor: 'var(--MainNavy)' }}>
                        <tr style={{ border: 'none' }}>
                          <th>물품이름</th>
                          <th>물품코드</th>
                          <th>생산량</th>
                          <th>현재고</th>
                        </tr>
                      </thead>
                    </ProcessTable>
                  </th>
                  <th style={{ maxWidth: 'unset', padding: 'unset' }}>
                    <ProcessTable>
                      <thead style={{ backgroundColor: 'var(--MainNavy)' }}>
                        <tr style={{ border: 'none' }}>
                          <th>물품이름</th>
                          <th>물품코드</th>
                          <th>투입량</th>
                          <th>현재고</th>
                        </tr>
                      </thead>
                    </ProcessTable>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!checkNullArray(_workOrderList, false) ? LoadingMsg() :
                  checkNullArray(_workOrderList, []).map((thisItem, index) => {
                    return (
                      <WorkOrderRow
                        key={index + '_WorkOrderRow'}
                        authority={_authority}
                        index={index}
                        content={thisItem}
                        createWorkOrderLog={createWorkOrderLog}
                        goDetails={goDetails}
                        handleCheckedItem={handleCheckedItem}
                        handleBookmark={handleBookmark}
                        showFileList={showFileList}
                        viewContent={_viewContent}
                        setViewContent={setViewContent}
                      />
                    );
                  })}
              </tbody>
            </table>
          }
            sectionStyle={{ height: 'calc(100% - 66px)' }}
          ></TableSection>
        </Section>

        <PagingComponent
          page={workOrderReducer.pageNumber}
          count={workOrderReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(workOrderActions_setPageNumber(page));
          }}
        />

        <ViewerButtons>
          <ViewMinusButton viewScale={workOrderReducer.viewScale} handleViewScale={(scale) => { dispatch(workOrderActions_setViewScale(scale)) }} />
          <ViewPlusButton viewScale={workOrderReducer.viewScale} handleViewScale={(scale) => { dispatch(workOrderActions_setViewScale(scale)) }} />
          <ViewResetButton handleViewScale={() => { dispatch(workOrderActions_setViewScale(100)) }} />
        </ViewerButtons>

        {_fileListModalStatus === true && checkEmptyNull(_eventcontent.workOrderId, false) ? (
          <ShowFileList
            type={_eventcontent.work !== true ? 'process' : 'workOrder'}
            content={_eventcontent}
            open={_fileListModalStatus}
            close={() => { setFileListModalStatus(false) }}
          ></ShowFileList>
        ) : null}
      </>
    }
    />
  );
};

export default WorkOrderManagement;
