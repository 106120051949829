import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { stockHistoryActions_setClickedLevel1Category, stockHistoryActions_setClickedLevel2Category, stockHistoryActions_setClickedLevel3Category, stockHistoryActions_setClickedLevel4Category, stockHistoryActions_setClickedLevel5Category, stockHistoryActions_setElementType, stockHistoryActions_setEndDate, stockHistoryActions_setPageNumber, stockHistoryActions_setReset, stockHistoryActions_setSearchData, stockHistoryActions_setStartDate, stockHistoryActions_setTotalSize } from 'store/modules/actions/common/stockHistoryActions';

import { elementStockLogApi } from 'api/apis/elementStockLogApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import { DateTimeFormat } from 'components/format/DateTimeFormat';
import { LoadingMsg } from 'components/loading/LoadingMsg'

import CategoriesV2 from 'components/category/CategoriesV2';
import DOCButtonIcon from 'components/icons/DOCButtonIcon';
import ExcelDownloadModal from 'components/excel/ExcelDownloadModal';
import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import ShowFileList from 'pages/file/modal/ShowFileList';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const StockHistorySection = styled.main`
  & .Title {
    display: flex;
    gap: 10px;
    align-items: center;
    h4 {
      padding-right: 20px;
      margin-right: 10px;
      border-right: 1px solid #ddd;
    }

    & .elementTypeButtons {
      display: flex;
      gap: 5px;
    }
  }
`;

const AmountCell = styled.td`
  &.In {background-color: var(--MainGreen)}
  &.Put {background-color: var(--MainYellow)}
  &.Make {background-color: var(--MainBlue)}
  &.Out {background-color: var(--MainRed)}
  &.Send {background-color: var(--MainRed)}
  &.Receive {background-color: var(--MainGreen)}
  color: var(--white);
  min-width: 150px;
  width: 150px;
`;

const StockHistoryV2 = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, stockHistoryReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_logList, setLogList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_fileListModalStatus, setFileListModalStatus] = useState(false);
  const [_eventcontent, setEventContent] = useState({});

  const [_excelDownloadModalStatus, setExcelDownloadModalStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('109') === true || // 파일
      thisItem.authority.startsWith('305') === true,
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getLogList(stockHistoryReducer.pageNumber - 1, stockHistoryReducer.elementType);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getLogList(stockHistoryReducer.pageNumber - 1, stockHistoryReducer.elementType);
    }

    return () => { };
  }, [stockHistoryReducer.pageNumber]);

  useEffect(() => {
    if (_onload === 'loaded') {
      dispatch(stockHistoryActions_setPageNumber(1));
      getLogList(0, stockHistoryReducer.elementType);
    }
    return () => { };
  }, [stockHistoryReducer.clickedCategory]);

  /* ====================================================================== #4 */
  const getLogList = async (page, elementType, clear) => {
    const paging = `?page=${page}&size=15&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (checkEmptyNull(elementType, false) && elementType !== 'all') {
      BodyToPost.elementType = elementType;
    }
    if (!clear && checkNullObject(stockHistoryReducer.clickedCategory, false) && stockHistoryReducer.clickedCategory !== 'all') {
      BodyToPost.categoryId = stockHistoryReducer.clickedCategory.categoryId;
    }
    if (!clear && checkEmptyNull(stockHistoryReducer.startDate, false) && checkEmptyNull(stockHistoryReducer.endDate, false)) {
      BodyToPost.elementStockLogStartDate = DateFormat(stockHistoryReducer.startDate);
      BodyToPost.elementStockLogEndDate = DateFormat(stockHistoryReducer.endDate);
      if (stockHistoryReducer.startDate > stockHistoryReducer.endDate) {
        BodyToPost.elementStockLogStartDate = DateFormat(stockHistoryReducer.endDate);
        BodyToPost.elementStockLogEndDate = DateFormat(stockHistoryReducer.startDate);
      }
    } else if (!clear && checkEmptyNull(stockHistoryReducer.startDate, false) && !checkEmptyNull(stockHistoryReducer.endDate, false)) {
      BodyToPost.elementStockLogStartDate = DateFormat(stockHistoryReducer.startDate);
    } else if (!clear && checkEmptyNull(stockHistoryReducer.endDate, false) && !checkEmptyNull(stockHistoryReducer.startDate, false)) {
      BodyToPost.elementStockLogEndDate = DateFormat(stockHistoryReducer.endDate);
    }
    if (!clear && checkEmptyNull(stockHistoryReducer.searchData.elementName, false)) {
      BodyToPost.elementName = stockHistoryReducer.searchData.elementName;
    }
    if (!clear && checkEmptyNull(stockHistoryReducer.searchData.elementCode, false)) {
      BodyToPost.elementCode = stockHistoryReducer.searchData.elementCode;
    }
    console.log('getLogList - BodyToPost : ', BodyToPost);

    await elementStockLogApi.searchElementStockLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('elementStockLogApi.searchElementStockLog : ', response);
      setLogList(() => { return response.data.content });
      dispatch(stockHistoryActions_setTotalSize(response.data.totalElements));
    });
  };

  /* ====================================================================== #5 */
  const handleElementType = (elementType) => {
    if (elementType === 'all') {
      dispatch(stockHistoryActions_setClickedLevel5Category({}));
      dispatch(stockHistoryActions_setClickedLevel4Category({}));
      dispatch(stockHistoryActions_setClickedLevel3Category({}));
      dispatch(stockHistoryActions_setClickedLevel2Category({}));
      dispatch(stockHistoryActions_setClickedLevel1Category({}));
    }

    dispatch(stockHistoryActions_setElementType(elementType));
    dispatch(stockHistoryActions_setPageNumber(1));

    getLogList(0, elementType);
  }

  const handleLogTypeName = (logType) => {
    switch (logType) {
      case 'In': return '입고';
      case 'Put': return '투입';
      case 'Make': return '생산';
      case 'Out': return '출고';
      case 'Send': return '이동 ▶';
      case 'Receive': return '▶ 이동';

      default: return '';
    }
  }

  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(id);
    setCheckedItem(() => { return checkedItem });
  };

  const actSearch = async () => {
    dispatch(stockHistoryActions_setPageNumber(1));
    await getLogList(0, stockHistoryReducer.elementType);
  };
  const actReset = async () => {
    dispatch(stockHistoryActions_setReset());

    dispatch(stockHistoryActions_setPageNumber(1));
    await getLogList(0, stockHistoryReducer.elementType, true);
  };

  const actDownload = () => {
    setExcelDownloadModalStatus(true);
  };

  const showFileList = (content) => {
    setEventContent(() => { return content });
    setTimeout(setFileListModalStatus(true), 1000);
  };

  const actUpdate = () => {
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return;
    else if (checkedList.length > 1) return alert('다중 선택되어 있습니다.');

    const checkedIndex = _logList.findIndex((log) => log.elementStockLogId === checkedList[0]);
    if (checkedIndex === -1) return;
    const logData = _logList[checkedIndex];

    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({ updateContent: JSON.stringify(logData) })}`,
      replace: true,
    });
  }

  /* ====================================================================== #6 */

  return (
    <Grid3Body contents={
      <>
        <StockHistorySection className="Main">
          <NavBar
            title={
              <div className='Title'>
                <NavTitle menuCode={'305'} />
                <div className='elementTypeButtons'>
                  {userReducer.elementType.map((elementType, index) => {
                    let typeText = '';
                    switch (elementType) {
                      case 'material': typeText = '자재'; break;
                      case 'semi': typeText = '부자재'; break;
                      case 'half': typeText = '반제품'; break;
                      case 'product': typeText = '완제품'; break;
                      case 'tool': typeText = '공구'; break;

                      default: return null;
                    }
                    return (<button key={index + '_elementTypeButtons'} className={stockHistoryReducer.elementType === elementType ? 'btn-inven-1' : 'btn-set'} onClick={() => { handleElementType(elementType) }}>{typeText}</button>);
                  })}
                  <button className={stockHistoryReducer.elementType === 'all' ? 'btn-inven-1' : 'btn-set'} onClick={() => { handleElementType('all') }}>전체</button>
                </div>
              </div>
            }
            buttons={
              <>
                {_authority.indexOf('305-2') !== -1 ? (<button className='btn-edit' onClick={actUpdate}>수정</button>) : null}
                <button className="btn-download" onClick={actDownload}>다운로드</button>
              </>
            }
            nav={'all'}
            firstRow={
              <>
                <div className="SearchSection">
                  <CategoriesV2 categoryType={'stockHistory'} />
                </div>
              </>
            }
            secondRow={
              <div className="SearchSection">
                <div className="SearchSection">
                  <input
                    type="date"
                    className="SelectDate"
                    value={stockHistoryReducer.startDate}
                    onChange={(e) => { dispatch(stockHistoryActions_setStartDate(e.target.value)) }}
                  />
                  <IconRightArrow />
                  <input
                    type="date"
                    className="SelectDate"
                    value={stockHistoryReducer.endDate}
                    onChange={(e) => { dispatch(stockHistoryActions_setEndDate(e.target.value)) }}
                  />
                </div>

                <div className="ContentCBox">
                  <select className="SearchOption detail">
                    <option value="elementName">물품이름</option>
                  </select>
                  <input
                    className="SearchBar detail"
                    placeholder="Search..."
                    value={checkEmptyNull(stockHistoryReducer.searchData.elementName, '')}
                    onInput={(e) => {
                      dispatch(stockHistoryActions_setSearchData({ ...stockHistoryReducer.searchData, elementName: e.target.value, }));
                    }}
                  />
                </div>

                <div className="ContentCBox">
                  <select className="SearchOption detail">
                    <option value="elementCode">물품코드</option>
                  </select>
                  <input
                    className="SearchBar detail"
                    placeholder="Search..."
                    value={checkEmptyNull(stockHistoryReducer.searchData.elementCode, '')}
                    onInput={(e) => {
                      dispatch(stockHistoryActions_setSearchData({ ...stockHistoryReducer.searchData, elementCode: e.target.value, }));
                    }}
                  />
                </div>

                <div className="ContentCBox">
                  <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                  <div className="ResetButton" onClick={actReset}>초기화</div>
                </div>
              </div>
            }
          />

          <TableSection
            content={
              <table>
                <thead>
                  <tr>
                    {(_authority.indexOf('305-1') !== -1 || _authority.indexOf('305-2') !== -1) ? <th style={{ minWidth: '50px', width: '50px' }}></th> : null}
                    {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? <th style={{ minWidth: '150px', width: '150px' }}>파일</th> : null}
                    <th>입력시각</th>
                    <th>물품이름</th>
                    <th>물품코드</th>
                    <th style={{ minWidth: '150px', width: '150px' }}>수량</th>
                    <th>금액</th>
                    <th>유통기한</th>
                    <th>재고위치</th>
                    <th>비고</th>
                    <th>작업자</th>
                  </tr>
                </thead>
                <tbody>
                  {!checkNullArray(_logList, false) ? LoadingMsg() :
                    checkNullArray(_logList, []).map((thisLog) => {
                      return (<tr key={thisLog.elementStockLogId + '_logs'}>
                        {_authority.indexOf('305-2') !== -1 ? (
                          <td style={{ minWidth: '50px', width: '50px' }}>
                            <input
                              type="checkBox"
                              name="stockLogs"
                              data-key={thisLog.elementStockLogId}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                handleCheckedItem(thisLog.elementStockLogId, isChecked);
                                if (isChecked) e.target.checked = true;
                                else e.target.checked = false;
                              }}
                              onClick={(e) => { e.stopPropagation() }}
                            />
                          </td>
                        ) : null}
                        {/* 파일 */}
                        {(() => {
                          if (_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) {
                            if (checkNullArray(thisLog.attachFileList, false)) {
                              return (
                                <td className='table-preview'>
                                  <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                                    onClick={(e) => { e.stopPropagation(); showFileList(thisLog); }}
                                  >
                                    <img src={thisLog.attachFileList[0]?.fileData?.fileDataS3URL || ''} alt='image preview' className='img-preview' />
                                    <div className='btn-file'><DOCButtonIcon /></div>
                                  </div>
                                </td>
                              );
                            } else {
                              return (
                                <td className='table-preview'>
                                  <div
                                    style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                                    onClick={(e) => { e.stopPropagation(); showFileList(thisLog); }}
                                  >
                                    <div className='no-image'><p>이미지가 없습니다.</p></div>
                                    <div className='btn-file'><DOCButtonIcon /></div>
                                  </div>
                                </td>
                              );
                            }
                          }
                        })()}
                        <td>{checkEmptyNull(thisLog.createdDate, false) ? DateTimeFormat(thisLog.createdDate) : ''}</td>
                        <td>{thisLog.element.elementName}</td>
                        <td>{thisLog.element.elementCode}</td>
                        <AmountCell className={thisLog.logType}>
                          {handleLogTypeName(thisLog.logType) + ' ' + checkEmptyNull(thisLog.amount, 0).toLocaleString() + ' ' + checkEmptyNull(thisLog.element.elementUnit, '')}
                        </AmountCell>
                        <td>{checkEmptyNull(thisLog.price, '')}</td>
                        <td>{checkEmptyNull(thisLog.checkedDate, false) ? DateTimeFormat(thisLog.checkedDate) : ''}</td>
                        <td>{checkEmptyNull(thisLog.stockPlaceName, '')}</td>
                        <td style={{ textAlign: 'left' }}>{thisLog.note}</td>
                        <td>{checkEmptyNull(thisLog.userName, '')}</td>
                      </tr>
                      );
                    })}
                </tbody>
              </table>
            }
            sectionStyle={{ height: 'calc(100% - 66px)' }}
          ></TableSection>
        </StockHistorySection>

        <PagingComponent
          page={stockHistoryReducer.pageNumber}
          count={stockHistoryReducer.totalSize}
          size={15}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(stockHistoryActions_setPageNumber(page));
          }}
        />

        {_fileListModalStatus === true && checkEmptyNull(_eventcontent.elementStockLogId, false) ? (
          <ShowFileList
            type={'elementStockLog'}
            content={_eventcontent}
            open={_fileListModalStatus}
            close={() => { setFileListModalStatus(false) }}
          />
        ) : null}

        {_excelDownloadModalStatus === true ? (
          <ExcelDownloadModal
            open={_excelDownloadModalStatus}
            mainTitle={'Products'}
            subTitle={'StockHistory'}
            close={() => { setExcelDownloadModalStatus(false) }}
          />
        ) : null}
      </>
    }
    />
  );
};

export default StockHistoryV2;
