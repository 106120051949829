import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import axios from 'axios';

import {
  daerimReducer_setDeleteContent,
  daerimReducer_setUpdateContent,
} from 'store/modules/actions/company/daerimActions';
import {
  pageReducer_getCurrentPage,
  pageReducer_setPageNum,
  pageReducer_setPageSize,
  pageReducer_setTotalCount,
} from 'store/modules/actions/default/pageActions';

import {
  AXIOS_BASE_HEADERS,
  AXIOS_BASE_URL,
} from 'preferences/server/constants';

import CheckBox from 'components/checkbox/CheckBox';
import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import Paging from 'components/paging/Paging';
import PrintDLMovementModal from 'pages/custom/company/dl/movement/modal/PrintDLMovementModal';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ForwardingTable = styled.table`
  th {
    padding: 0px;
  }
  th:nth-child(4) {
    padding: 10px 0px;
  }

  td,
  th {
    min-width: 100px;
  }

  thead tr {
    border: none;
  }
`;

const ProcessTable = styled.table`
  border-collapse: collapse;
  min-width: 100%;
  table-layout: fixed;
  td,
  th {
    box-sizing: border-box;
    padding: 5px 15px;
    /* min-width: 150px; */
    text-align: center;
    white-space: normal;
    word-break: break-all;
    font-size: 14px;
  }
`;

const ActButton = styled.div`
  align-items: center;
  background-color: var(--MainBlue);
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  min-height: 50px;
  width: 100%;
`;

const setDateFormat = (thisDate) => {
  if (
    thisDate !== undefined &&
    thisDate !== null &&
    typeof thisDate === 'object'
  ) {
    const setDate =
      thisDate.length === 3
        ? thisDate.join('-')
        : thisDate.length > 6
          ? `${thisDate[0]}-${thisDate[1]}-${thisDate[2]} ${thisDate[3]}:${thisDate[4]}:${thisDate[5]}`
          : thisDate;
    const newDate = new Date(setDate);
    return moment(newDate).format('YYYY-MM-DD');
  } else {
    return thisDate;
  }
};

const DLMovement = (props) => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_forwardingLogList, setForwardingLogList] = useState([]);
  const [_printContents, setPrintContents] = useState([]);

  const [_startDate, setStartDate] = useState('');
  const [_endDate, setEndDate] = useState('');

  const [_searchOption, setSearchOption] = useState('');
  const [_searchText, setSearchText] = useState('');

  /* PDF 미리보기 모달 */
  const [_printModalStatus, setPrintModalStatus] = useState(false);

  /* ========================================================================= #3*/
  useEffect(() => {
    // 권한 체크
    const authorityContent = userReducer.user.team.teamRoleList.filter(
      (thisItem) => thisItem.authority.startsWith('502') === true,
    );
    const authorityCodes = authorityContent.map(
      (thisItem) => thisItem.authority,
    );
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    setForwardingLog(0);

    setOnload('loaded');

    return () => { };
  }, []);

  useEffect(() => {
    dispatch(pageReducer_setPageNum(1));
    dispatch(pageReducer_setPageSize(10));

    return () => { };
  }, [pageReducer.currentPage]);

  useEffect(() => {
    if (_onload === 'loaded') {
      setForwardingLog(pageReducer.currentPageNum - 1);
    }

    return () => { };
  }, [pageReducer.currentPageNum]);

  // useEffect(() => {
  //   if (_onload === 'loaded') {
  //     dispatch(pageReducer_setPageNum(1));
  //     setForwardingLog();
  //   }

  //   return () => {};
  // }, [daerimReducer.createPlanArray]);

  /* ========================================================================= #4*/
  /* 공정이동표 불러오기 */
  const setForwardingLog = async (page, clear) => {
    const paging = `?page=${page}&size=${pageReducer.pageSize}&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    if (!clear && _startDate !== '' && _endDate !== '') {
      BodyToPost.startDate = setDateFormat(_startDate);
      BodyToPost.endDate = setDateFormat(_endDate);
      if (_startDate > _endDate) {
        BodyToPost.startDate = setDateFormat(_endDate);
        BodyToPost.endDate = setDateFormat(_startDate);
      }
    }

    if (
      !clear &&
      _searchOption !== undefined &&
      _searchOption !== null &&
      _searchOption !== '' &&
      _searchText !== undefined &&
      _searchText !== null &&
      _searchText !== ''
    ) {
      BodyToPost[_searchOption] = _searchText;
    }
    console.log('setForwardingLog - BodyToPost : ', BodyToPost);

    await axios
      .post(
        AXIOS_BASE_URL + '/forwardingLog/search' + paging,
        BodyToPost,
        AXIOS_BASE_HEADERS,
      )
      .then((response) => {
        if (response === undefined) return;
        console.log('setForwardingLog-response : ', response);
        setForwardingLogList(() => {
          return response.data.content;
        });

        dispatch(pageReducer_setTotalCount(response.data.totalElements));
      })
      .catch((error) => {
        console.log('setForwardingLog-error : ', error);
      });
  };

  /* ========================================================================= #5*/
  /* 검색 */
  const actSearch = async () => {
    // if (_searchOption === '' || _searchText === null) return;

    dispatch(pageReducer_setPageNum(1));
    await setForwardingLog(0);
  };

  /* 초기화 */
  const actReset = async () => {
    setStartDate(() => {
      return '';
    });
    setEndDate(() => {
      return '';
    });

    setSearchOption(() => {
      return '';
    });
    setSearchText(() => {
      return '';
    });

    dispatch(pageReducer_setPageNum(1));
    await setForwardingLog(0, true);
  };

  /* 송장발행 */
  const actPrint = (e) => {
    const clickRow = e.target.parentNode;
    const thisClickedRow = _forwardingLogList.filter(
      (thisLog) =>
        thisLog.forwardingLogId === Number(clickRow.dataset.forwardinglogid),
    )[0];

    setPrintContents(thisClickedRow);
    dispatch(daerimReducer_setUpdateContent(thisClickedRow));

    setTimeout(setPrintModalStatus(true), 1000);
  };

  /* 복사 */
  const actCopy = () => {
    const checkedForwarding = document.querySelector(
      'input[name="forwardinglog"]:checked',
    );
    // console.log('checkedForwarding:', checkedForwarding);

    if (checkedForwarding === null)
      return alert('복사할 공정이동표를 선택해주세요.');

    const thischeckedForwarding = _forwardingLogList.filter(
      (thisShipment) =>
        thisShipment.forwardinglogid === Number(checkedForwarding.dataset.key),
    )[0];
    // console.log('thischeckedForwarding', thischeckedForwarding);

    dispatch(daerimReducer_setUpdateContent(thischeckedForwarding));
    navigate(pageReducer.currentPage + '/copy', { replace: true });
  };

  /* 수정 */
  const actUpdate = () => {
    const checkedForwarding = document.querySelector(
      'input[name="forwardinglog"]:checked',
    );
    console.log('checkedForwarding:', checkedForwarding);

    if (checkedForwarding === null)
      return alert('수정할 공정이동표를 선택해주세요.');

    const thisCheckedforwarding = _forwardingLogList.filter(
      (thisForwarding) =>
        thisForwarding.forwardingLogId ===
        Number(checkedForwarding.dataset.key),
    )[0];
    console.log('thisCheckedforwarding', thisCheckedforwarding);

    dispatch(daerimReducer_setUpdateContent(thisCheckedforwarding));
    navigate(pageReducer.currentPage + '/update', { replace: true });
  };

  /* 삭제 */
  const actDelete = () => {
    const checkedForwarding = document.querySelector(
      'input[name="forwardinglog"]:checked',
    );
    console.log('checkedForwarding:', checkedForwarding);

    if (checkedForwarding === null)
      return alert('삭제할 공정이동표를 선택해주세요.');

    const thisCheckedforwarding = _forwardingLogList.filter(
      (thisForwarding) =>
        thisForwarding.forwardingLogId ===
        Number(checkedForwarding.dataset.key),
    )[0];
    console.log('thisCheckedforwarding', thisCheckedforwarding);

    dispatch(daerimReducer_setDeleteContent(thisCheckedforwarding));
    navigate(pageReducer.currentPage + '/delete', { replace: true });
  };

  /* ====================================================================== #6 */

  return (
    <Grid3Body
      contents={
        <>
          <main className="Main">
            <NavBar
              title={'공정이동표'}
              buttons={
                <>
                  {_authority.indexOf('502-2') !== -1 ? (
                    <>
                      <button
                        className='btn-add'
                        onClick={() => {
                          navigate(pageReducer.currentPage + '/create', {
                            replace: true,
                          });
                        }}
                      >
                        추가
                      </button>
                      <button className='btn-edit' onClick={actUpdate}>수정</button>
                      <button className='btn-delete' onClick={actDelete}>삭제</button>
                    </>
                  ) : null}
                </>
              }
              nav={'search'}
              firstRow={
                <>
                  <div
                    className="SearchSection"
                    style={{
                      columnGap: '20px',
                    }}
                  >
                    <div className="SearchSection">
                      <input
                        type="date"
                        className="SelectDate"
                        value={_startDate}
                        onChange={(e) => {
                          setStartDate(() => {
                            return e.target.value;
                          });
                        }}
                      />

                      <IconRightArrow />

                      <input
                        type="date"
                        className="SelectDate"
                        value={_endDate}
                        onChange={(e) => {
                          setEndDate(() => {
                            return e.target.value;
                          });
                        }}
                      />
                    </div>

                    <div className="ContentCBox">
                      <div className="ContentCBox">
                        <select
                          className="SearchOption"
                          value={_searchOption}
                          onChange={(e) => {
                            setSearchOption(() => {
                              return e.target.value;
                            });
                          }}
                        >
                          <option value="">검색옵션</option>
                          <option value="deliveryTeam">전달팀</option>
                          <option value="receivingTeam">수신팀</option>
                          <option value="deliveryUserName">전달자</option>
                          <option value="receivingUserName">수신자</option>
                          <option value="qualityUserName">품관</option>
                        </select>
                        <input
                          className="SearchBar"
                          placeholder="Search..."
                          value={_searchText}
                          onInput={(e) => {
                            setSearchText(() => {
                              return e.target.value;
                            });
                          }}
                        />
                      </div>

                      <button data-searchbutton="true" onClick={actSearch}>
                        <SearchButtonIcon />
                      </button>
                      <div className="ResetButton" onClick={actReset}>
                        초기화
                      </div>
                    </div>
                  </div>
                </>
              }
            />

            <TableSection
              content={
                <ForwardingTable>
                  <thead>
                    <tr>
                      <th
                        rowSpan={2}
                        style={{ minWidth: '100px', width: '100px' }}
                      ></th>
                      <th
                        rowSpan={2}
                        style={{ minWidth: '50px', width: '50px' }}
                      ></th>
                      <th rowSpan={2}>날짜</th>
                      <th>품목</th>
                      <th rowSpan={2}>전달팀</th>
                      <th rowSpan={2}>수신팀</th>
                      <th rowSpan={2}>전달자</th>
                      <th rowSpan={2}>수신자</th>
                      <th rowSpan={2}>품관</th>
                    </tr>
                    <tr>
                      <th style={{ maxWidth: 'unset', padding: 'unset' }}>
                        <ProcessTable>
                          <thead
                            style={{ backgroundColor: 'var(--ThirdBlue)' }}
                          >
                            <tr>
                              <th>품번</th>
                              <th>품명</th>
                              <th>수량</th>
                            </tr>
                          </thead>
                        </ProcessTable>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {_forwardingLogList.map((thisLog) => {
                      const forwardingLogElementList =
                        thisLog.forwardingLogElementList;
                      const thisRowKey = thisLog.forwardingLogId;

                      return (
                        <tr key={thisRowKey} data-forwardinglogid={thisRowKey}>
                          <td
                            style={{
                              backgroundColor: 'var(--MainBlue)',
                              color: 'var(--white)',
                              cursor: 'pointer',
                              fontSize: '18px',
                              fontWeight: '600',
                              minWidth: '100px',
                              padding: 'unset',
                              width: '100px',
                            }}
                            data-forwardinglogid={thisRowKey}
                            onClick={actPrint}
                          >
                            출력
                          </td>
                          <td style={{ minWidth: '50px', width: '50px' }}>
                            <CheckBox
                              act={'one'}
                              all={''}
                              checkboxKey={thisRowKey}
                              name={'forwardinglog'}
                            />
                          </td>
                          <td>{setDateFormat(thisLog.date)}</td>
                          <td>
                            <ProcessTable>
                              <tbody>
                                {forwardingLogElementList.map((thisElement) => {
                                  return (
                                    <tr
                                      key={
                                        thisRowKey +
                                        '_' +
                                        thisElement.forwardingLogElementId
                                      }
                                      style={{ border: 'none' }}
                                    >
                                      <td>{thisElement.element.elementCode}</td>
                                      <td>{thisElement.element.elementName}</td>
                                      <td>{thisElement.amount}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </ProcessTable>
                          </td>
                          <td>{thisLog.deliveryTeam}</td>
                          <td>{thisLog.receivingTeam}</td>
                          <td>
                            {thisLog.deliveryUser === '' ||
                              thisLog.deliveryUser === undefined ||
                              thisLog.deliveryUser === null
                              ? ''
                              : thisLog.deliveryUser.name}
                          </td>
                          <td>
                            {thisLog.receivingUser === '' ||
                              thisLog.receivingUser === undefined ||
                              thisLog.receivingUser === null
                              ? ''
                              : thisLog.receivingUser.name}
                          </td>
                          <td>
                            {thisLog.qualityCheckUser === '' ||
                              thisLog.qualityCheckUser === undefined ||
                              thisLog.qualityCheckUser === null
                              ? ''
                              : thisLog.qualityCheckUser.name}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </ForwardingTable>
              }
            ></TableSection>
          </main>

          <Paging
            page={pageReducer.currentPageNum}
            count={pageReducer.totalCount}
            size={pageReducer.pageSize}
          />

          {_printModalStatus === true && (
            <PrintDLMovementModal
              open={_printModalStatus}
              close={() => {
                setPrintModalStatus(false);
              }}
            ></PrintDLMovementModal>
          )}
        </>
      }
    />
  );
};

export default DLMovement;
