const initialState = {
  elementType: 'material',

  startDate: '',
  endDate: '',

  searchData: {
    elementName: '',
    elementCode: '',
    stockPlaceName: '',
  },
  searchAttrs: {},

  clickedCategory: {},
  clickedLevel1Category: {},
  clickedLevel2Category: {},
  clickedLevel3Category: {},
  clickedLevel4Category: {},
  clickedLevel5Category: {},

  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,

  element: {},
  elements: [],

  level1Categories: [],
  level2Categories: [],
  level3Categories: [],
  level4Categories: [],
  level5Categories: [],
};

const resetState = {
  elementType: 'material',

  startDate: '',
  endDate: '',

  searchData: {
    elementName: '',
    elementCode: '',
    stockPlaceName: '',
  },
  searchAttrs: {},

  clickedCategory: {},
  clickedLevel1Category: {},
  clickedLevel2Category: {},
  clickedLevel3Category: {},
  clickedLevel4Category: {},
  clickedLevel5Category: {},

  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const stockMovingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETSTOCKMOVINGTYPE': return { ...state, elementType: action.payload };
    case 'SETSTOCKMOVINGSTARTDATE': return { ...state, startDate: action.payload };
    case 'SETSTOCKMOVINGENDDATE': return { ...state, endDate: action.payload };
    case 'SETSTOCKMOVINGSEARCHDATA': return { ...state, searchData: action.payload };
    case 'SETSTOCKMOVINGSEARCHATTS': return { ...state, searchAttrs: action.payload };

    case 'SETSTOCKMOVINGLEVEL1CATEGORIES': return { ...state, level1Categories: action.payload };
    case 'SETSTOCKMOVINGLEVEL2CATEGORIES': return { ...state, level2Categories: action.payload };
    case 'SETSTOCKMOVINGLEVEL3CATEGORIES': return { ...state, level3Categories: action.payload };
    case 'SETSTOCKMOVINGLEVEL4CATEGORIES': return { ...state, level4Categories: action.payload };
    case 'SETSTOCKMOVINGLEVEL5CATEGORIES': return { ...state, level5Categories: action.payload };
    case 'SETSTOCKMOVINGCLICKEDCATEGORY': return { ...state, clickedCategory: action.payload };
    case 'SETSTOCKMOVINGCLICKEDLEVEL1CATEGORY': return { ...state, clickedLevel1Category: action.payload };
    case 'SETSTOCKMOVINGCLICKEDLEVEL2CATEGORY': return { ...state, clickedLevel2Category: action.payload };
    case 'SETSTOCKMOVINGCLICKEDLEVEL3CATEGORY': return { ...state, clickedLevel3Category: action.payload };
    case 'SETSTOCKMOVINGCLICKEDLEVEL4CATEGORY': return { ...state, clickedLevel4Category: action.payload };
    case 'SETSTOCKMOVINGCLICKEDLEVEL5CATEGORY': return { ...state, clickedLevel5Category: action.payload };

    case 'SETSTOCKMOVINGPAGENUMBER': return { ...state, pageNumber: action.payload };
    case 'SETSTOCKMOVINGTOTALSIZE': return { ...state, totalSize: action.payload };
    case 'SETSTOCKMOVINGISPUSHEDSEARCHBUTTON': return { ...state, isPushedSearchButton: action.payload };

    case 'SELECTONESTOCKMOVING': return { ...state, element: action.payload };
    case 'SELECTSTOCKMOVINGS': return { ...state, elements: action.payload };

    case 'SETSTOCKMOVINGRESET': return { ...state, ...resetState };
    default: return state;
  }
};

export default stockMovingReducer;
