import React from 'react';
import { Outlet } from 'react-router-dom';
import { MallAPIInterceptor } from 'api/core/MallAPIInterceptor';

const MallRouteLayout = ({ children }) => {
  return (<>
    <MallAPIInterceptor></MallAPIInterceptor>
    <Outlet {...children} />
  </>);
};

export default MallRouteLayout;
