import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { ACCESS_TOKEN, AXIOS_BASE_URL } from 'preferences/server/constants';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import Nav from 'components/nav/Nav';
import { getCookie } from 'preferences/cookie/cookie';

const DeleteIncomingInspection = () => {
  /* ===================================================#======================================================*/
  const navigate = useNavigate();
  const { gsReducer, pageReducer, userReducer } = useSelector((state) => state);
  console.log('gsReducer : ', gsReducer.deleteOrder);

  /* =================================================== #2 ===================================================*/

  /* =================================================== #3 =================================================== */

  /* =================================================== #4들 =================================================== */

  /* =================================================== #5들 =================================================== */
  const actDelOrder = async () => {
    await axios
      .delete(
        AXIOS_BASE_URL +
        '/importInspection/' +
        gsReducer.deleteOrder.importInspectionId,
        {
          headers: {
            Authorization: getCookie('accessToken'),
          },
        },
      )
      .then((response) => {
        if (response === undefined) return;
        console.log('delete/importInspection - response : ', response);
        if (response.status === 200) {
          alert('검사를 삭제했습니다.');
          navigate(pageReducer.currentPage, { replace: true });
        }
      })
      .catch((error) => {
        console.log('delete/importInspection - error : ', error);
      });
  };

  /* =================================================== #6들 =================================================== */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body
      contents={
        <>
          <main className="Main">
            <Nav
              thisTitle={'조도측정'}
              content={''}
              nav={''}
              search={''}
              searchButton={''}
              searchOptions={''}
              statusOptions={''}
            />

            <DeleteForm
              delteTitle={'검사'}
              deleteItem={
                <>
                  {'검사코드 : ' + gsReducer.deleteOrder.importInspectionCode}{' '}
                  <br />
                  {'검사명 : ' +
                    gsReducer.deleteOrder.importInspectionName}{' '}
                  <br />
                </>
              }
              deleteText={
                <>
                  해당 <span className="cautionText">모든 검사 내용</span>이
                  삭제됩니다.
                </>
              }
              deleteButton={
                <>
                  <button
                    className="formButton cancle"
                    onClick={() => {
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                  <button className="formButton delete" onClick={actDelOrder}>
                    삭제
                  </button>
                </>
              }
            />
          </main>
        </>
      }
    />
  );
};

export default DeleteIncomingInspection;
