import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { equipmentSharingAttribute, equipmentSharingType } from 'api/apis/platform/cluster/cluster';
import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

const EquipmentAttributes = ({
  modalType, modalData,
  update,
  close
}) => {
  const location = useLocation();
  const clusterId = location.pathname.split('/platform/cluster')[0];
  const clusterPath = clusterId + '/platform/cluster/mypage';

  const [_sharingTypeLsit, setSharingTypeList] = useState([]); // 유형 리스트
  const [_body, setBody] = useState({ ...modalData });

  useEffect(() => {
    getTypeList();
    return () => { }
  }, []);
  useEffect(() => { }, [_body]);

  const getTypeList = async () => { // 유형 불러오기
    const id = Number(clusterId.replace('/', ''));
    const body = { clusterId: id };
    await equipmentSharingType.search('', body).then(response => {
      if (response === undefined) return;
      setSharingTypeList(() => { return response.data });
    })
  };

  const actSave = async (e) => { // create/update
    e.preventDefault();
    const id = Number(clusterId.replace('/', ''));
    const body = { clusterId: id, ..._body };

    if (!checkEmptyNull(body.equipmentSharingTypeId, false)) return alert('유형을 선택해주세요.');

    if (modalType.includes('create')) { // create
      await equipmentSharingAttribute.create(body).then(response => {
        if (response === undefined) return;
        console.log('equipmentSharingAttribute.create : ', response);
        update();
      })
    } else { // update
      delete body?.createdDate;
      delete body?.modifiedDate;

      await equipmentSharingAttribute.update(body.equipmentSharingAttributeId, body).then(response => {
        if (response === undefined) return;
        console.log('equipmentSharingAttribute.update : ', response);
        update();
      })
    }
  };
  const actDelete = async () => { // delete
    const confirmText = `해당 속성을 삭제하시겠습니까?`;
    if (window.confirm(confirmText)) {
      await equipmentSharingAttribute.delete(_body.equipmentSharingAttributeId).then(response => {
        if (response === undefined) return;
        console.log('equipmentSharingAttribute.delete : ', response);
        update();
      })
    }
  };

  return (
    <section>
      <div className='section-form'>
        <h2>장비 속성 정보</h2>

        <div className='form-content'>
          <h4>장비 유형</h4>
          <select
            disabled={modalType.endsWith('delete')}
            value={_body.equipmentSharingTypeId || ''}
            onChange={(e) => {
              setBody((prev) => {
                return { ...prev, equipmentSharingTypeId: Number(e.target.value) };
              })
            }}
          >
            <option value={''}>유형 선택</option>
            {checkNullArray(_sharingTypeLsit, []).map((sharingType, index) => {
              return (<option key={index + '_sharingType'} value={sharingType.equipmentSharingTypeId}>{sharingType.equipmentSharingTypeName}</option>);
            })}
          </select>
        </div>
        <div className='form-content'>
          <h4>속성 이름</h4>
          <input
            type='text'
            placeholder='속성 이름을 입력해주세요.'
            disabled={modalType.endsWith('delete')}
            value={_body.equipmentSharingAttributeName || ''}
            onInput={(e) => {
              setBody((prev) => {
                return { ...prev, equipmentSharingAttributeName: e.target.value }
              })
            }}
          />
        </div>
      </div>
      <div className='section-action'>
        <button className='btn-cancel' onClick={close}>취소</button>
        {!modalType.endsWith('delete') ? (<button className='btn-save' onClick={actSave}>저장</button>) : (<button className='btn-delete' onClick={actDelete}>삭제</button>)}
      </div>
    </section>
  );
};

export default EquipmentAttributes;