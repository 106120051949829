import { AxiosInterceptor } from "api/core/consts";

export const accountAttributeApi = {
  searchAccountAttribute: (body) =>
    AxiosInterceptor.post('accountAttribute/search', body),
  createAccountAttribute: (body) =>
    AxiosInterceptor.post('accountAttribute', body),
  updateAccountAttribute: (accountAttributeId, body) =>
    AxiosInterceptor.put('accountAttribute/' + accountAttributeId, body),
  deleteAccountAttribute: (accountAttributeId) =>
    AxiosInterceptor.delete('accountAttribute/' + accountAttributeId),
};
