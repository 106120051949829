import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import styled from 'styled-components';

import { accountApi } from 'api/apis/accountApi';

import { checkEmptyNull, checkNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  height: 85%;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: space-between;
  padding: 0px 60px 0px 20px;
  position: relative;
  width: 100%;
  box-shadow: 0 4px 18px 0 rgba(196,196,196,0.8);
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
  }
`;

const MainContents = styled.div`
  box-sizing: border-box;
  height: 100%;
  justify-content: center;
  padding: 10px;
  width: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {height: 8px; width: 8px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 4px;}
`;

const ModalButton = styled.div`
  width: 20%;
  border-radius: 8px;
  height: fit-content;
  padding: 8px;
  box-shadow: 0 2px 6px 0 rgba(196,196,196,0.8);
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingScreen = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  color: var(--white);
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

/* ========================= PrintContent ========================= */
const ExportSection = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 60px auto 40px;
  justify-content: center;
  margin: 30px auto;
  overflow: hidden;
  position: relative;
  row-gap: 5px;
  border: 1px solid var(--gray-500);
  height: 297mm;
  width: 210mm;
  table {
    max-width: 100%;
    td, th {vertical-align: middle !important;}
  }
  .print input {vertical-align: middle;}
`;

const PrintHeaderSection = styled.div`
  align-items: center;
  border-bottom: 10px solid var(--Text);
  column-gap: 20px;
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
  position: relative;
  width: 100%;
  z-index: 20;
`;

const PrintFooterSection = styled.div`
  align-items: flex-start;
  border-top: 10px solid var(--Text);
  column-gap: 20px;
  display: flex;
  justify-content: center;
  padding-top: 5px;
  width: 100%;
`;

const PrintMainSection = styled.div`
  width: 100%;
  input, textarea {
    &:hover {background-color: var(--Bg); transition: 0.3s;}
  }
  textarea {
    background-color: var(--white);
    border: none;
    resize: none;
    &:focus {border: none; outline: none;}
  }
`;

const Section1 = styled.div`
  display: grid;
  font-size: 0.8em;
  grid-template-columns: 60% 40%;
  height: 185px;
  width: 100%;
`;
const Section1Left = styled.div`
  column-gap: 10px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 2px;
  h2 {
    align-items: center;
    display: flex;
    font-size: 2.3em;
    justify-content: center;
    letter-spacing: 50px;
    padding-left: 50px;
    text-align: center;
  }
  & > div {display: flex; flex-direction: column; row-gap: 20px;}
  input {height: 30px;}
`;
const InputWraps = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;
const InputWrap = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: max-content auto;
  width: 100%;
  b {letter-spacing: 5px; padding-left: 10px;}
  span {display: inline-flex; font-weight: 600;}
  div[name="outputArea"] {
    background-color: transparent;
    font-size: 0.9em;
    line-height: 1em;
    height: 40px;
    overflow: visible;
    transform: translate(5px, -3px);
  }
  input, textarea {
    cursor: pointer;
    display: block;
    font-size: 1em;
    font-family: inherit;
    padding-left: 5px;
    width: 100%;
  }
`;
const Section1Right = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;

  & > div {
    display: flex;
    flex-direction: column;
    & > div {
      align-items: center;
      height: 26px;
      & span {align-items: center;height: 26px;}
    }
  }
  input {
    height: 26px;
    &:hover {z-index: 11;}
  }
`;

const PrintTable = styled.table`
  background-color: var(--white);
  border-collapse: collapse;
  width: 100%;
  &.fixTable {table-layout: fixed;}
  td, th {border: 1px solid var(--Text);}
`;

const Section2 = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  font-size: 0.7em;
  height: 140px;
  justify-content: center;
  padding-top: 10px;
  width: 100%;
  table {
    font-size: 1.1em;
    height: 100%;
    td {
      box-sizing: border-box;
      min-width: 190px;
      text-align: center;
      width: fit-content;
      &.numTd {min-width: 120px; width: 120px;}
      input {
        height: 100%;
        text-align: center;
        vertical-align: middle;
        width: 100%;
      }
    }
    th {
      box-sizing: border-box;
      width: 78px;
      &.title {letter-spacing: 8px; padding-left: 8px;}
      &.numTitle {width: 70px;}
    }
  }
`;

const Section3 = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 0.8em;
  justify-content: flex-start;
  padding: 5px 0px;
  width: 100%;
  p {width: 80%;}
  h4 {
    margin-top: 15px;
    span {display: inline-block; width: 25px;}
  }
`;

const Section4 = styled.div`
  font-size: 0.8em;
  height: 35px;
  width: 100%;
  table {
    height: 100%;
    width: 100%;
    th {
      box-sizing: border-box;
      width: 78px;
      &.title {letter-spacing: 6px; padding-left: 6px;}
    }
    td {
      box-sizing: border-box;
      font-weight: 600;
      padding: 0px 10px;
      h4 {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        position: relative;
        width: fit-content;
        &[name='totalPriceText']::before { content: '金 '; margin-right: 2px; width: 20px;}
        &[name='totalPriceText']::after { content: ' 원 整'; margin-left: 2px; width: 50px;}
        &[name='totalPriceNum']::before { content: '(₩'; margin-right: 10px; width: 15px;}
        &[name='totalPriceNum']::after {content: ')'; margin-left: 10px; width: 10px;}
      }
    }
  }
`;
const TotalPriceNumInput = styled.input`
  display: block;
  box-sizing: border-box;
  font-size: inherit;
  font-weight: 600;
  height: 30px;
  max-width: 150px;
  width: fit-content;
`;

const Section5 = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  font-size: 0.8em;
  justify-content: center;
  padding-top: 10px;
  width: 100%;
  table {
    overflow: hidden;
    table-layout: initial;
    width: 100%;
    td, th {height: 30px;}
    td {
      box-sizing: border-box;
      min-width: 40px;
      text-align: center;
      &.numTd {min-width: 60px; width: 60px;}
    }
    th {
      box-sizing: border-box;
      &.title { letter-spacing: 8px; padding-left: 8px;}
      input { font-weight: 600;}
    }
    tfoot tr {border-top: 2px solid var(--Text);}
    input {
      box-sizing: border-box;
      height: 100%;
      text-align: center;
      vertical-align: middle;
      width: 100%;
      &[type='number'] {padding-right: 5px;}
    }
  }
`;
const EtcTextBox = styled.div`
  align-items: center;
  border: 1px solid var(--Text);
  box-sizing: border-box;
  display: grid;
  font-size: 0.9em;
  grid-template-columns: 100%;
  grid-template-rows: 30% 70%;
  height: 100%;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;
  b {letter-spacing: 6px; padding-left: 6px; }
  div {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    text-align: left;
    width: 100%;
  }
`;

const PrintContent = (props) => {
  /* ====================================================================== #1 */
  /* ====================================================================== #2 */
  const num2han = (num) => {
    //여기로 들어오는 값은 무조건 네자리이다. 1234 -> 일천이백삼십사
    const _makeHan = (text) => {
      let str = '';
      for (let i = 0; i < text.length; i++) {
        const num = text[i];
        if (num === '0') continue; //0은 읽지 않는다
        str += number[num] + smallUnit[i];
      }
      return str;
    }

    let eventNum = parseInt((num + '').replace(/[^0-9]/g, ''), 10) + '';  // 숫자/문자/돈 을 숫자만 있는 문자열로 변환
    if (eventNum === '0') return '';

    const number = ['', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
    const unit = ['', '만', '억', '조'];
    const smallUnit = ['천', '백', '십', ''];
    const result = [];  //변환된 값을 저장할 배열
    let unitCnt = Math.ceil(eventNum.length / 4);  //단위 갯수. 숫자 10000은 일단위와 만단위 2개이다.
    eventNum = eventNum.padStart(unitCnt * 4, '0');  //4자리 값이 되도록 0을 채운다
    const regexp = /[\w\W]{4}/g;  //4자리 단위로 숫자 분리
    const array = eventNum.match(regexp);
    //낮은 자릿수에서 높은 자릿수 순으로 값을 만든다(그래야 자릿수 계산이 편하다)
    for (let i = array.length - 1, unitCnt = 0; i >= 0; i--, unitCnt++) {
      const hanValue = _makeHan(array[i]);  //한글로 변환된 숫자
      if (hanValue === '') continue; //값이 없을땐 해당 단위의 값이 모두 0이란 뜻. 
      result.unshift(hanValue + unit[unitCnt]);  //unshift는 항상 배열의 앞에 넣는다.
    }
    return result.join('');
  }

  const TotalPriceText = useRef();
  const TotalPriceNum = useRef();

  const [_printPage, setPrintPage] = useState(`${props.currentPage} / ${props.totalPage}`);
  const [_companyInformation, setCompanyInformation] = useState(props.companyInformation);
  const [_procurement, setProcurement] = useState(props.procurement);
  const [_procurementElementList, setProcurementElementList] = useState(() => {
    const returnArray = [];
    for (let i = 0; i < parseFloat(10 - props.content.length); i++) {
      const emptyData = {
        // emptyNum: parseFloat(props.content.length + 1 + i),
        empty: true,
        elementName: '',
        elementCode: '',

        price: 0, // 단가
        quantity: 0, // 수량
        calPrice: 0, // 금액

        elementStandard: '', // 규격
      };

      returnArray.push(emptyData);
    }

    const returnData = [...props.content.map((element) => {
      return {
        ...element,
        price: checkEmptyNull(element.price, 0).toLocaleString('ko-KR'),
        quantity: checkEmptyNull(element.quantity, 0).toLocaleString('ko-KR'),
        calPrice: checkEmptyNull(element.calPrice, 0).toLocaleString('ko-KR'),
      };
    }),
    ...returnArray,
    ];

    return returnData;
  });

  const [_totalPriceText, setTotalPriceText] = useState(() => {
    const procurement = props.procurement;
    return num2han(procurement.procurementPrice)
  });
  const [_totalPriceNum, setTotalPriceNum] = useState(() => {
    const procurement = props.procurement;
    return procurement.procurementPrice.toLocaleString('ko-KR');
  });

  const [_viewPrice, setViewPrice] = useState(() => {
    const sumArray = props.content.map((item) => checkEmptyNull(item.calPrice, false) ? Number(item.calPrice.toString().replaceAll(',', '')) : 0);
    const setSumArray = sumArray.filter((item) => item !== null && !isNaN(item));
    const sum = setSumArray.reduce((a, b) => BigNumber(a).plus(BigNumber(b)).toNumber(), 0);
    const tax = BigNumber(sum || 0).multipliedBy(10).dividedBy(100).toNumber();
    const totalSum = BigNumber(sum || 0).plus(BigNumber(tax)).toNumber();

    const returnData = {
      sum: sum.toLocaleString('ko-KR'),
      tax: tax.toLocaleString('ko-KR'),
      totalSum: totalSum.toLocaleString('ko-KR'),
    };

    return returnData;
  });

  const [_address, setAddress] = useState(_companyInformation.address);

  const [_note, setNote] = useState({
    1: '',
    2: '',
    3: '',
  });

  /* ====================================================================== #3 */
  useEffect(() => {
    setPrintPage(() => { return `${props.currentPage} / ${props.totalPage}`; });
    setCompanyInformation(() => { return props.companyInformation; });
    setProcurement(() => { return props.procurement; });
    setProcurementElementList(() => {
      const returnArray = [];
      for (let i = 0; i < parseFloat(10 - props.content.length); i++) {
        const emptyData = {
          // emptyNum: parseFloat(props.content.length + 1 + i),
          empty: true,
          elementName: '',
          elementCode: '',

          price: 0, // 단가
          quantity: 0, // 수량
          calPrice: 0, // 금액

          elementStandard: '', // 규격
        };

        returnArray.push(emptyData);
      }

      const returnData = [
        ...props.content.map((element) => {
          return {
            ...element,
            price: checkEmptyNull(element.price, 0).toLocaleString('ko-KR'),
            quantity: checkEmptyNull(element.quantity, 0).toLocaleString('ko-KR'),
            calPrice: checkEmptyNull(element.calPrice, 0).toLocaleString('ko-KR'),
          };
        }),
        ...returnArray,
      ];

      return returnData;
    });

    setViewPrice(() => {
      const sumArray = props.content.map((item) => checkEmptyNull(item.calPrice, false) ? Number(item.calPrice.toString().replaceAll(',', '')) : 0);
      const setSumArray = sumArray.filter((item) => item !== null && !isNaN(item));
      const sum = setSumArray.reduce((a, b) => BigNumber(a).plus(BigNumber(b)).toNumber(), 0);
      const tax = BigNumber(sum || 0).multipliedBy(10).dividedBy(100).toNumber();
      const totalSum = BigNumber(sum || 0).plus(BigNumber(tax)).toNumber();

      const returnData = {
        sum: sum.toLocaleString('ko-KR'),
        tax: tax.toLocaleString('ko-KR'),
        totalSum: totalSum.toLocaleString('ko-KR'),
      };

      return returnData;
    });
  }, []);

  useEffect(() => { }, [_companyInformation]);
  useEffect(() => { }, [_procurementElementList]);
  useEffect(() => { }, [_viewPrice]);

  /* ====================================================================== #4 */
  const handleInputEvent = (e, index, element) => {
    const { name, value } = e.target;

    const eventValue = Number(value.replaceAll(',', ''));
    console.log(element);
    console.log(name, ' : ', eventValue);

    if (isNaN(eventValue)) return;

    const elementData = { ...element, [name]: eventValue.toLocaleString('ko-KR') };
    switch (name) {
      case 'price':
        elementData.calPrice = BigNumber(checkEmptyNull(eventValue, 0))
          .multipliedBy(BigNumber(checkEmptyNull(element.quantity.replaceAll(',', ''), 0)))
          .toNumber()
          .toLocaleString('ko-KR');
        break;

      case 'quantity':
        elementData.calPrice = BigNumber(checkEmptyNull(eventValue, 0))
          .multipliedBy(BigNumber(checkEmptyNull(element.price.replaceAll(',', ''), 0)))
          .toNumber()
          .toLocaleString('ko-KR');
        break;

      default: break;
    }

    setProcurementElementList((prev) => {
      const returnData = [...prev];
      returnData.splice(index, 1, elementData);
      handlePriceEvent(returnData);
      return returnData;
    });
  };
  const handleSumEvent = (num) => {
    setTotalPriceText(() => { return num2han(num); });
    setTotalPriceNum(() => { return num.toLocaleString('ko-KR'); })
  }
  const handlePriceEvent = (elements) => {
    const sumArray = elements.map((item) => checkEmptyNull(item.calPrice, false) ? Number(item.calPrice.toString().replaceAll(',', '')) : 0);
    const setSumArray = sumArray.filter((item) => item !== null && !isNaN(item));
    const sum = setSumArray.reduce((a, b) => BigNumber(a).plus(BigNumber(b)).toNumber(), 0);
    const tax = BigNumber(sum || 0).multipliedBy(10).dividedBy(100).toNumber();
    const totalSum = BigNumber(sum || 0).plus(BigNumber(tax)).toNumber();

    const priceData = {
      sum: sum.toLocaleString('ko-KR'),
      tax: tax.toLocaleString('ko-KR'),
      totalSum: totalSum.toLocaleString('ko-KR'),
    };
    console.log('priceData : ', priceData);

    setViewPrice(() => { return priceData; });
    handleSumEvent(totalSum)
  };

  const actPriceEvent = (e) => {
    const { name, value } = e.target;
    const eventValue = Number(value.replaceAll(',', ''));
    if (isNaN(eventValue)) return;

    setViewPrice((prev) => {
      const returnData = { ...prev, [name]: eventValue.toLocaleString('ko-KR') };

      switch (name) {
        case 'sum':
          const sum_tax = BigNumber(eventValue || 0).multipliedBy(10).dividedBy(100).toNumber();
          const sum_totalSum = BigNumber(eventValue || 0).plus(BigNumber(sum_tax)).toNumber();
          returnData.tax = sum_tax.toLocaleString('ko-KR');
          returnData.totalSum = sum_totalSum.toLocaleString('ko-KR');
          handleSumEvent(sum_totalSum);
          break;

        default: break;
      }

      return returnData;
    });
  };

  /* ====================================================================== #5 */
  /* ====================================================================== #6 */

  return (
    <ExportSection name={'printContent'}>
      <PrintHeaderSection>
        <h4 className="print">{_companyInformation.accountName}</h4>
        <p style={{ position: 'absolute', top: '10px', right: '10px' }}>{_printPage}</p>
      </PrintHeaderSection>
      <PrintMainSection>
        <Section1>
          <Section1Left>
            <h2 className="print">발주서</h2>
            <div>
              <InputWrap>
                <b className="print">발주번호 : </b>
                <input
                  type="text"
                  className="print"
                  defaultValue={checkEmptyNull(_procurement.procurementCode)}
                />
              </InputWrap>
              <InputWrap>
                <b className="print">작성일자 : </b>
                <input
                  type="text"
                  className="print"
                  defaultValue={DateFormat(moment().format('YYYY-MM-DD'))}
                />
              </InputWrap>
            </div>
          </Section1Left>
          <Section1Right>
            <div>
              <InputWrap>
                <span
                  className="print"
                  style={{ alignSelf: 'flex-start', fontWeight: '600' }}
                >
                  주소:
                </span>
                <textarea
                  type="text"
                  name='editArea'
                  className="print"
                  style={{ width: '95%', zIndex: 10 }}
                  rows={2}
                  value={`${_address}`}
                  onInput={(e) => { setAddress(() => { return e.target.value; }) }}
                ></textarea>
                <div name="outputArea" style={{ display: 'none', width: '95%', zIndex: 10, }}>
                  {checkNull(_address, false) ? _address.indexOf('\n') !== -1 ? _address.split('\n').map((text, textIndex) => {
                    return <p key={textIndex + '_address'}>{text}</p>
                  }) : _address : ''}
                </div>
              </InputWrap>
              <InputWraps>
                <InputWrap>
                  <span className="print">TEL. </span>
                  <input
                    type="text"
                    className="print"
                    style={{ backgroundColor: 'none' }}
                    defaultValue={checkEmptyNull(_companyInformation.telNumber)}
                  />
                </InputWrap>
                <b className="print">/</b>
                <InputWrap>
                  <span className="print">FAX. </span>
                  <input
                    type="text"
                    className="print"
                    style={{ backgroundColor: 'none' }}
                    defaultValue={checkEmptyNull(_companyInformation.faxNumber)}
                  />
                </InputWrap>
              </InputWraps>
              <InputWrap>
                <span className="print">E-mail : </span>
                <input
                  type="text"
                  className="print"
                  defaultValue={checkEmptyNull(_companyInformation.email)}
                />
              </InputWrap>
            </div>

            <PrintTable className="fixTable" style={{ height: '90px' }}>
              <tbody>
                <tr style={{ fontSize: '0.9em', height: '25px' }}>
                  <th
                    rowSpan={2}
                    className="print"
                    style={{ lineHeight: '30px', minWidth: '30px', textAlign: 'center', verticalAlign: 'middle', width: '30px' }}
                  >
                    결<br />재
                  </th>
                  <th
                    className="print"
                    style={{ letterSpacing: '12px', paddingLeft: '12px' }}
                  >
                    담당
                  </th>
                  <th
                    colSpan={2}
                    className="print"
                    style={{ letterSpacing: '12px', paddingLeft: '12px' }}
                  >
                    검토
                  </th>
                  <th
                    className="print"
                    style={{ letterSpacing: '12px', paddingLeft: '12px' }}
                  >
                    승인
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </tbody>
            </PrintTable>
          </Section1Right>
        </Section1>

        <Section2>
          <PrintTable>
            <tbody>
              <tr>
                <th rowSpan="2" className="print title">수신</th>
                <td rowSpan="2">
                  <input
                    type="text"
                    className="print"
                    defaultValue={checkEmptyNull(_procurement?.account?.accountName, '')}
                  />
                </td>
                <th className="print, title">담당자</th>
                <td><input type="text" className="print" defaultValue={''} /></td>
                <th className="numTitle, print">TEL.</th>
                <td className="numTd, print">
                  <input
                    type="text"
                    className="print"
                    defaultValue={checkEmptyNull(_procurement?.account?.telNumber, '')}
                  />
                </td>
              </tr>
              <tr>
                <th className="print">E-mail</th>
                <td>
                  <input
                    type="text"
                    className="print"
                    defaultValue={checkEmptyNull(_procurement?.account?.email, '')}
                  />
                </td>
                <th className="numTitle, print">FAX.</th>
                <td className="numTd, print">
                  <input
                    type="text"
                    className="print"
                    defaultValue={checkEmptyNull(_procurement?.account?.faxNumber, '')}
                  />
                </td>
              </tr>
              <tr>
                <th className="print, title">발신</th>
                <td>
                  <input
                    type="text"
                    className="print"
                    defaultValue={checkEmptyNull(_companyInformation.accountName)}
                  />
                </td>
                <th className="print, title">발신자</th>
                <td><input type="text" className="print" defaultValue={''} /></td>
                <th className="numTitle, print">TEL.</th>
                <td className="numTd, print"><input type="text" className="print" defaultValue={''} /></td>
              </tr>
              <tr>
                <th className="print, title">제목</th>
                <td colSpan="5">
                  <input
                    type="text"
                    className="print"
                    style={{ paddingLeft: '10px', textAlign: 'left' }}
                    defaultValue={''}
                  />
                </td>
              </tr>
            </tbody>
          </PrintTable>
        </Section2>

        <Section3>
          <p className="print">1. 귀사의 일익 번창하심을 기원합니다.</p>
          <p className="print">2. 아래와 같이 물품을 발주하오니, 확인하여 공급해 주시기 바랍니다.</p>
          <h4 className="print">= 아<span></span>래 =</h4>
        </Section3>

        <Section4>
          <PrintTable>
            <thead>
              <tr>
                <th className="print, title">합계</th>
                <td>
                  <InputWraps>
                    <h4 name="totalPriceText">{_totalPriceText}</h4>
                    <h4 name="totalPriceNum">
                      <TotalPriceNumInput
                        ref={TotalPriceNum}
                        type="text"
                        name="totalPriceNum"
                        className="print"
                        value={_totalPriceNum}
                        onInput={(e) => {
                          e.preventDefault();
                          const eventValue = Number(e.target.value.replaceAll(',', ''));
                          if (isNaN(eventValue)) return;
                          console.log('eventValue : ', eventValue);
                          handleSumEvent(eventValue);
                        }}
                      />
                    </h4>
                    <p className="print" style={{ fontSize: '0.9em', fontWeight: '400' }}>{`<부가세포함>`}</p></InputWraps>
                </td>
              </tr>
            </thead>
          </PrintTable>
        </Section4>

        <Section5>
          <PrintTable>
            <thead>
              <tr>
                <th className="print">NO.</th>
                <th className="print, title">품명</th>
                <th className="print, title">규격</th>
                <th className="print, title">단가</th>
                <th className="print">수량</th>
                <th className="print, title">금액</th>
                <th className="print">납기</th>
                <th className="print, title">비고</th>
              </tr>
            </thead>
            <tbody>
              {_procurementElementList.map((element, index) => {
                return (
                  <tr key={index + '_procurementElements'}>
                    <td className="print" style={{ minWidth: '40px', width: '40px' }}>{index + 1}</td>
                    <td style={{ minWidth: '180px', width: '180px' }}>
                      <input
                        type="text"
                        className="print"
                        defaultValue={checkEmptyNull(element.elementName)}
                      />
                    </td>
                    <td style={{ minWidth: '150px', width: '150px' }}>
                      <input
                        type="text"
                        className="print"
                        defaultValue={checkEmptyNull(element.elementStandard)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="print"
                        name="price"
                        style={{ paddingRight: '5px', textAlign: 'right' }}
                        value={checkEmptyNull(element.price === 0 ? '' : element.price)}
                        onInput={(e) => { handleInputEvent(e, index, element); }}
                      />
                    </td>
                    <td className="numTd">
                      <input
                        type="text"
                        className="print"
                        name="quantity"
                        style={{ paddingRight: '5px', textAlign: 'right' }}
                        value={checkEmptyNull(element.quantity === 0 ? '' : element.quantity)}
                        onInput={(e) => { handleInputEvent(e, index, element); }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="print"
                        name="calPrice"
                        style={{ paddingRight: '5px', textAlign: 'right' }}
                        value={checkEmptyNull(element.calPrice === 0 ? '' : element.calPrice)}
                        onInput={(e) => { handleInputEvent(e, index, element); }}
                      />
                    </td>
                    <td className="numTd"><input type="text" className="print" defaultValue={''} /></td>
                    <td><input type="text" className="print" defaultValue={''} /></td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <th
                  colSpan={4}
                  className="print, title"
                  style={{ letterSpacing: '28px', paddingLeft: '28px' }}
                >
                  소계
                </th>
                <th></th>
                <th>
                  <input
                    type="text"
                    className="print"
                    name="sum"
                    style={{ paddingRight: '5px', textAlign: 'right' }}
                    value={_viewPrice.sum}
                    onInput={actPriceEvent}
                  />
                </th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th colSpan={4} className="print, title">부가세</th>
                <th></th>
                <th>
                  <input
                    type="text"
                    className="print"
                    name="tax"
                    style={{ paddingRight: '5px', textAlign: 'right' }}
                    readOnly
                    value={_viewPrice.tax}
                    onInput={actPriceEvent}
                  />
                </th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th
                  colSpan={4}
                  className="print, title"
                  style={{ letterSpacing: '28px', paddingLeft: '28px' }}
                >
                  합계
                </th>
                <th></th>
                <th>
                  <input
                    type="text"
                    className="print"
                    name="totalSum"
                    style={{ paddingRight: '5px', textAlign: 'right' }}
                    readOnly
                    value={_totalPriceNum}
                    onInput={actPriceEvent}
                  />
                </th>
                <th></th>
                <th className="print">{`<V.A.T 포함>`}</th>
              </tr>
              <tr>
                <td colSpan={8}>
                  <InputWraps style={{ columnGap: 'unset', height: '80px', overflow: 'hidden' }}>
                    <EtcTextBox style={{ paddingLeft: '5px' }}>
                      <div className="print" style={{ fontWeight: '600' }}>[발주특기사항]</div>
                      <textarea
                        type="text"
                        name='editArea'
                        className="print"
                        style={{ width: '100%', zIndex: 10 }}
                        rows={3}
                        value={`${_note[1]}`}
                        onInput={(e) => { setNote((prev) => { return { ...prev, 1: e.target.value }; }) }}
                      ></textarea>
                      <div name="outputArea" style={{ display: 'none', width: '100%', }}>
                        {checkNull(_note[1], false) ? _note[1].indexOf('\n') !== -1 ? _note[1].split('\n').map((text, textIndex) => {
                          return <p key={textIndex + '_1'}>{text}</p>
                        }) : _note[1] : ''}
                      </div>
                    </EtcTextBox>
                    <EtcTextBox>
                      <div style={{ borderBottom: '1px solid var(--Text)' }}>
                        <b className="print">대금결재방법</b>
                        <textarea
                          type="text"
                          name='editArea'
                          className="print"
                          style={{ width: '68%', zIndex: 10 }}
                          rows={1}
                          value={`${_note[2]}`}
                          onInput={(e) => { setNote((prev) => { return { ...prev, 2: e.target.value }; }) }}
                        ></textarea>
                        <div name="outputArea" style={{ display: 'none', width: '68%', }}>
                          {checkNull(_note[2], false) ? _note[2].indexOf('\n') !== -1 ? _note[2].split('\n').map((text, textIndex) => {
                            return <p key={textIndex + '_2'}>{text}</p>
                          }) : _note[2] : ''}
                        </div>
                      </div>
                      <div>
                        <b className="print" style={{ letterSpacing: '18px' }}>납품방법</b>
                        <textarea
                          type="text"
                          name='editArea'
                          className="print"
                          style={{ width: '68%', zIndex: 10 }}
                          rows={2}
                          value={`${_note[3]}`}
                          onInput={(e) => { setNote((prev) => { return { ...prev, 3: e.target.value }; }) }}
                        ></textarea>
                        <div name="outputArea" style={{ display: 'none', width: '68%', }}>
                          {checkNull(_note[3], false) ? _note[3].indexOf('\n') !== -1 ? _note[3].split('\n').map((text, textIndex) => {
                            return <p key={textIndex + '_3'}>{text}</p>
                          }) : _note[3] : ''}
                        </div>
                      </div>
                    </EtcTextBox>
                  </InputWraps>
                </td>
              </tr>
              <tr>
                <td colSpan={8}>
                  <InputWraps style={{ alignItems: 'flex-start', columnGap: '10px', height: '45px' }}>
                    <div className="print" style={{ paddingLeft: '5px' }}>
                      [공 <span style={{ display: 'inline-block', width: '6px' }}></span>통]
                    </div>
                    <div style={{ alignItems: 'flex-start', display: 'flex', flexDirection: 'column' }}>
                      <p className="print">1. 본 발주서는 접수 후 즉시 서면으로 수락/거부 통지가 없으면 효력을 발생한 것으로 간주한다.</p>
                      <p className="print">2. 검사 사항은 첨부 사항이 없는 한 당사 검사 방법에 따른다.</p>
                    </div>
                  </InputWraps>
                </td>
              </tr>
            </tfoot>
          </PrintTable>
        </Section5>
      </PrintMainSection>
      <PrintFooterSection>
        <h5 className="print">{_companyInformation.accountName}</h5>
      </PrintFooterSection>
    </ExportSection>
  );
};

const ProcurementPdfModal = (props) => {
  /* ========================================================================= #1 */
  const { userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const { procurement } = props;

  const [_companyInformation, setCompanyInformation] = useState({
    companyId: userReducer.company.companyId,
    accountId: undefined,
    // accountTypeId: undefined,
    accountTypeName: userReducer.company.companyName,

    accountCode: '', // 사업자번호(수요기관코드)
    businessNumber: '', // 사업자번호
    accountName: userReducer.company.companyName, // 회사명
    regionName: '', // 지역
    address: '', // 주소
    detailAddress: '', // 상세주소
    zip: '', // 우편번호
    email: '', // 이메일
    telNumber: '', // 전화번호
    faxNumber: '', // 팩스번호

    accountAttributeValueList: [],
    deleteAccountAttributeValueList: [],
  });
  // console.log('_companyInformation : ', _companyInformation);

  const [_printContents, setPrintContents] = useState(() => {
    const procurementElementList = procurement.procurementElementList;
    const returnList = [];
    if (checkNullArray(procurementElementList, false)) {
      for (let i = 0; i < procurementElementList.length; i += 10) {
        const sliceArray = procurementElementList.slice(i, i + 10);
        const returnArray = sliceArray.map((element) => {
          if (element.element !== undefined && element.element !== null) {
            let elementStandard = '';
            if (checkNullArray(element?.element?.elementAttributeValueList, false)) {
              const elementAttributeValueList = element.element.elementAttributeValueList;
              const elementStandardIndex = elementAttributeValueList.findIndex((attr) => attr.elementAttribute !== null && attr.elementAttribute.elementAttributeName === '규격');
              if (elementStandardIndex !== -1) elementStandard = elementAttributeValueList[elementStandardIndex].value;

            }
            const elementData = {
              ...element,

              elementId: element.element.elementId,
              elementName: element.element.elementName,
              elementCode: element.element.elementCode,
              scheduledInputStock: element.element.scheduledInputStock,
              elementSafeStock: element.element.elementSafeStock,
              elementStock: element.element.elementStock,

              price: element.element.price, // 단가
              calPrice: element.price, // 금액

              elementStandard: elementStandard, // 규격
            };

            return elementData;
          } else {
            return element;
          }
        });
        returnList.push(returnArray);
      }
      return returnList;
    } else {
      return [];
    }
  });

  const [_loadingStatus, setLoadingStatus] = useState(false);

  /* ========================================================================= #3 */
  useEffect(() => {
    getComapnyInformation();
  }, []);

  useEffect(() => { }, [_companyInformation]);

  /* ========================================================================= #4 */
  const getComapnyInformation = async () => {
    const paging = `?page=0`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      accountName: userReducer.company.companyName,
    };
    await accountApi.searchAccount(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('accountApi.searchAccount : ', response);
      if (response.data.content.length > 0) {
        setCompanyInformation((prev) => {
          const returnData = { ...prev, ...response.data.content[0] };
          return returnData;
        });
      } else {
        alert(`시스템 - 회사정보를 입력한 후 발주서 확인이 가능합니다.`);
        props.close();
      }
    });
  };

  /* ========================================================================= #5 */
  const acrPrintContent = () => {
    document.getElementsByName('editArea').forEach(content => { content.style.display = 'none' })
    document.getElementsByName('outputArea').forEach(content => { content.style.display = 'block' })
    console.log('================================================');
    const style = document.createElement('style');
    document.head.appendChild(style);
    style.sheet?.insertRule('body > div:last-child img { display: inline-block; }');

    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
    });
    // const doc = new jsPDF('portrait', 'pt', 'a4');

    let count = 0;
    document.getElementsByName('printContent').forEach(async (thisItem, index) => {
      const thisHeight = thisItem.offsetHeight;
      const thisWidth = thisItem.offsetWidth;
      const ratio = thisWidth / thisHeight;
      // console.log('BarcodeContents : ', thisItem, thisHeight, thisWidth, ratio);

      const option = {
        height: thisHeight,
        width: thisWidth,
      };

      await html2canvas(thisItem, option).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        // const imgWidth = 210;
        // const imgHeight = canvas.height * imgWidth / canvas.width;

        let width = doc.internal.pageSize.getWidth();
        // let height = doc.internal.pageSize.getHeight();
        let height = 280;
        width = ratio * height;

        const margin = 6;
        const position = 6;

        doc.addImage(imgData.replace('data:image/png;base64,', ''), 'PNG', margin, position, width, height);
        if (count < document.getElementsByName('printContent').length - 1) doc.addPage();
      });

      count++;
      console.log(count === document.getElementsByName('printContent').length, count, document.getElementsByName('printContent').length);
      if (index === document.getElementsByName('printContent').length - 1) {
        if (document.getElementsByName('printContent').length > 10) {
          style.remove();
          doc.save();
          setLoadingStatus(false);
        } else {
          style.remove();
          window.open(doc.output('bloburl'));
          setLoadingStatus(false);
        }

        document.getElementsByName('editArea').forEach(content => { content.style.display = 'block' })
        document.getElementsByName('outputArea').forEach(content => { content.style.display = 'none' })
        console.log('================================================');
      }
    });
  };

  /* ========================================================================= #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>발주서 출력 미리보기</ModalTitle>
              <ModalButton onClick={() => { setTimeout(acrPrintContent, 1000); }}>PDF 출력</ModalButton>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <MainContents id="PrintSection">
                {_companyInformation.accountId !== undefined &&
                  _printContents.map((content, index) => {
                    return (
                      <PrintContent
                        key={index + '_printContents'}
                        totalPage={_printContents.length}
                        currentPage={index + 1}
                        content={content}
                        companyInformation={_companyInformation}
                        procurement={procurement}
                      />
                    );
                  })}
              </MainContents>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )};
      {_loadingStatus === true ? (<LoadingScreen>파일 생성 중입니다...</LoadingScreen>) : null}
    </>
  );
};

export default ProcurementPdfModal;