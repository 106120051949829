export const shipmentInspectionLogActions_setShipmentInspectionItemType = (shipmentInspectionItemType) => {
  return {
    type: 'SETSHIPMENTINSPECTIONLOGTYPE',
    payload: shipmentInspectionItemType,
  };
};

export const shipmentInspectionLogActions_setStartDate = (date) => {
  return {
    type: 'SETSHIPMENTINSPECTIONLOGSTARTDATE',
    payload: date,
  };
};
export const shipmentInspectionLogActions_setEndDate = (date) => {
  return {
    type: 'SETSHIPMENTINSPECTIONLOGENDDATE',
    payload: date,
  };
};

export const shipmentInspectionLogActions_setSearchData = (searchData) => {
  return {
    type: 'SETSEARCHSHIPMENTINSPECTIONLOGDATA',
    payload: searchData,
  };
};
export const shipmentInspectionLogActions_setSearchOption = (searchOption) => {
  return {
    type: 'SETSEARCHSHIPMENTINSPECTIONLOGOPTION',
    payload: searchOption,
  };
};
export const shipmentInspectionLogActions_setSearchText = (searchText) => {
  return {
    type: 'SETSEARCHSHIPMENTINSPECTIONLOGTEXT',
    payload: searchText,
  };
};

export const shipmentInspectionLogActions_setPageNumber = (pageNumber) => {
  return {
    type: 'SETSHIPMENTINSPECTIONLOGPAGENUMBER',
    payload: pageNumber,
  };
};
export const shipmentInspectionLogActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETSHIPMENTINSPECTIONLOGTOTALSIZE',
    payload: totalSize,
  };
};

export const shipmentInspectionLogActions_setIsPushedSearchButton = (isPushedSearchButton) => {
  return {
    type: 'SETSHIPMENTINSPECTIONLOGISPUSHEDSEARCHBUTTON',
    payload: isPushedSearchButton,
  };
};

export const shipmentInspectionLogActions_setReset = () => {
  return {
    type: 'SETSHIPMENTINSPECTIONLOGRESET',
  };
};
