import React, { useEffect, useState } from 'react';
import { checkEmptyNull, checkNullObject } from 'components/checkValues/checkValues';
import { Sensor_group } from 'pages/sensorData/TmpHmdData.style';

const SensorData = (props) => {
  const [_sensorData, setSensorData] = useState(checkNullObject(props.content, {}));
  
  useEffect(() => {
    setSensorData(() => { return checkNullObject(props.content, {}) });

    return () => { }
  }, [props])

  return (
    <>
      <Sensor_group className='mg'>
        <div className='sensor-header'>
          <h1>{_sensorData.name}</h1>
        </div>
        <div className='sensor-content'>
          {[
            { name: '온도', data: _sensorData.temp, unit: '℃' },
            { name: '습도', data: _sensorData.hum, unit: '%' },
          ].map((sensor) => {
            return (
              <div className='sensor-data'>
                <div className='data-header'>
                  <h3>{sensor.name}</h3>
                </div>
                <div className='sensor current'>
                  <h4>현재</h4>
                  <p>{(checkEmptyNull(sensor.data, 0) * 1).toLocaleString()} <span>{sensor.unit}</span></p>
                </div>
              </div>
            );
          })}
        </div>
      </Sensor_group>
    </>
  );
};

export default SensorData;