import React, { useEffect, useState } from 'react';

import { GiElectric } from "react-icons/gi";
import { ShortIconWrapper, SafetySection } from 'pages/safetyManagement/SafetyManagement.style';

const ElectricalShort = ({ logList }) => {
  const [_status, setStatus] = useState('#000000');
  const [_statusWord, setStatusWord] = useState('데이터 없음');

  const getByStatus = (status) => {
    switch (status) {
      case '1': //빨강, 누전
        return setStatus('#FF0000'), setStatusWord('누전');
      case '0': //초록, 안전
        return setStatus('#00FF00'), setStatusWord('안전');
    }
  };

  useEffect(() => {
    // logList에서 온도 관련 데이터를 필터링해 첫 번째 데이터만 사용
    const alarmData = logList.find((log) => log.name.includes('alarm') || log.name.includes('알람'));
    getByStatus(alarmData?.value);
  }, [logList]);

  return (
    <SafetySection>
      <h3>누전 감지</h3>
      <div className="safety-group">
        <ShortIconWrapper status={_status}>
          <GiElectric className="electric-icon" />
        </ShortIconWrapper>
        <p className="electric-status">{_statusWord}</p>
      </div>
    </SafetySection>
  );
};

export default ElectricalShort;