import React, { Fragment } from 'react';
import { Table, Tbody, Td, Th, Title, Tr } from './Tab.style';

export default function ContractShipmentItems({ shipment }) {
  const formatNum = (num) =>
    num != null ? new Intl.NumberFormat().format(num) : '-';

  if (!shipment?.shipmentElementList?.length) {
    return (
      <Fragment>
        <Title>출하물품</Title>
        <p>출하물품이 없습니다.</p>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Title>출하물품</Title>
      <Table>
        <Tbody>
          {shipment.shipmentElementList.map((element, index) => {
            const { elementName, elementCode, elementUnit, elementStock, elementSafeStock, price: unitPrice } = element.element || {};

            return (
              <Fragment key={index}>
                <Tr>
                  <Th>물품정보</Th>
                  <Td>{elementName || '-'}</Td>
                </Tr>
                <Tr>
                  <Th>물품코드</Th>
                  <Td>{elementCode || '-'}</Td>
                </Tr>
                <Tr>
                  <Th>수량</Th>
                  <Td style={{ textAlign: 'right' }}>
                    {formatNum(element.quantity)}
                  </Td>
                </Tr>
                <Tr>
                  <Th>단위</Th>
                  <Td style={{ textAlign: 'right' }}>{elementUnit || '-'}</Td>
                </Tr>
                <Tr>
                  <Th>금액</Th>
                  <Td style={{ textAlign: 'right' }}>
                    {formatNum(element.price)}
                  </Td>
                </Tr>
                <Tr>
                  <Th>현재고</Th>
                  <Td style={{ textAlign: 'right' }}>
                    {formatNum(elementStock)}
                  </Td>
                </Tr>
                <Tr>
                  <Th>안전재고</Th>
                  <Td style={{ textAlign: 'right' }}>
                    {formatNum(elementSafeStock)}
                  </Td>
                </Tr>
                <Tr>
                  <Th>단가</Th>
                  <Td style={{ textAlign: 'right' }}>{formatNum(unitPrice)}</Td>
                </Tr>
                <Tr>
                  <Th>메모</Th>
                  <Td>{element.note || '-'}</Td>
                </Tr>
                <Tr>
                  <Th>비고</Th>
                  <Td>{element.remark || '-'}</Td>
                </Tr>
              </Fragment>
            );
          })}
        </Tbody>
      </Table>
    </Fragment>
  );
};