import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { mallSiteUserActions_setUser } from 'store/modules/actions/mallSite/mallSiteUserActions';
import { userReducer_reset } from 'store/modules/actions/user/userActions';

import { getCookie, removeCookie } from 'preferences/cookie/cookie';
import { auth } from 'api/apis/auth/auth';

import { homeIcon, userIcon, arrowRightToBracketIcon } from 'components/icons/src';
import { IfisVersion } from 'components/version/ifisVersion';
import { checkEmptyNull } from 'components/checkValues/checkValues';

const MallManagementHeaderSection = styled.header`
  align-items: center;
  background-color: var(--MainNavy);
  box-shadow: 8px 8px 8px 8px #eee;
  color: var(--white);
  display: flex;
  flex-wrap: wrap;
  height: 60px;
  justify-content: space-between;
  overflow: initial;
  padding: 0 40px;
  width: 100%;
  z-index: 900;
  &::-webkit-scrollbar {display: none;}
`;

const TopMenuSection = styled.div`
  column-gap: 50px;
  display: grid;
  grid-template-columns: repeat(6, max-content);
  height: 100%;
  padding-left: 20px;
`;
const TopMenu = styled.div`
  align-items: center;
  border-left: 2px solid var(--balck);
  color: var(--white);
  display: flex;
  font-size: 1.125rem;
  font-weight: 500;
  height: 60px;
  justify-content: flex-start;
  padding: 0px 10px;
  width: fit-content;

  ${(props) => {
    return props.page === window.location.pathname ? 'opacity: 1;' : 'opacity: .4;';
  }}
`;

const MallManagementHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userReducer } = useSelector((state) => state);

  const [onMenu, setOnMenu] = useState(false);

  const SubInfo = () => { setOnMenu(!onMenu); }
  const actLogout = async () => {
    await auth.logout(getCookie('accessToken')).then(response => {
      if (response === undefined) return;
      console.log('================================= removeCookie');
      removeCookie('accessToken');
      removeCookie('refreshToken');
      dispatch(userReducer_reset());
      dispatch(mallSiteUserActions_setUser());

      navigate('/', { replace: true });
    })
  }

  return (
    <MallManagementHeaderSection>
      <Link to="/dashboard"><img src={homeIcon} alt="대쉬보드버튼" style={{ color: 'white' }} /></Link>
      <TopMenuSection>
        {[
          { menuName: '주문관리', menuLink: '/mallManagement/order' },
          { menuName: '문의관리', menuLink: '/mallManagement/qna' },
          { menuName: '판매물품관리', menuLink: '/mallManagement/element' },
          { menuName: '카테고리관리', menuLink: '/mallManagement/category' },
          { menuName: '고객관리', menuLink: '/mallManagement/user' },
        ].map((menu, index) => {
          return (<Link key={index + '_mallManagementMenus'} to={menu.menuLink}><TopMenu page={menu.menuLink}>{menu.menuName}</TopMenu></Link>);
        })}
        {checkEmptyNull(userReducer.company.companyCode, false) ? (
          <Link to={'/' + userReducer.company.companyCode + "/mall"}>
            <TopMenu page={'/' + userReducer.company.companyCode + "/mall"}
              style={{ color: 'var(--MainBlue)', opacity: 1 }}
            >
              몰 사이트
            </TopMenu>
          </Link>
        ) : null}
      </TopMenuSection>
      <div style={{ display: 'flex', gap: '16px' }}>
        <button onClick={SubInfo}><img src={userIcon} alt="유저버튼" style={{ width: '24px', height: '24px' }} /></button>
        <button onClick={actLogout}><img src={arrowRightToBracketIcon} alt="로그아웃버튼" style={{ width: '22px', height: '22px', paddingTop: '2px' }} /></button>
      </div>
      {onMenu ? <div className='user-info'>
        <Link to='/system/companyInformation'>회사정보 <span>({userReducer.company.companyName})</span></Link>
        <Link to='/system/myProfile'>내정보 <span>({userReducer.user.name} 님)</span></Link>
        <a href='/inquiry'>문의<span></span></a>
        <p>아이피스 버전 : <IfisVersion /></p>
      </div> : null}
    </MallManagementHeaderSection>
  );
};

export default MallManagementHeader;
