import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import {
  productionHistoryActions_setCurrentPageNum,
  productionHistoryActions_setTotalSize,
} from 'store/modules/actions/common/productionHistoryActions';

import { qualityApi } from 'api/apis/qualityApi';
import { workOrderTypeApi } from 'api/apis/workOrderTypeApi';

import { DateFormat } from 'components/format/DateFormat';
import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import ProductionProcessTracking from 'pages/qualityControl/modal/ProductionProcessTracking';
import QualityRow from 'components/row/QualityRow';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';
import ShowFileList from 'pages/file/modal/ShowFileList';

const MainSection = styled.main`
  .TableSection {
    thead tr {
      border: none;
    }

    td,
    th {
      min-width: 150px;
      width: 150px;
    }
    td.resultProductStyle,
    th.resultProductStyle {
      min-width: 120px;
      width: 120px;
    }
  }
`;

const ProductionHistory = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const { userReducer, pageReducer, productionHistoryReducer } = useSelector(
    (state) => state,
  );

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_workOrderLogList, setWorkOrderLogList] = useState([]);

  const [_workOrderTypes, setWorkOrderTypes] = useState([]);
  const [_workOrderType, setWorkOrderType] = useState('all');
  const [_workOrderStatus, setWorkOrderStatus] = useState('all');

  const [_startDate, setStartDate] = useState('');
  const [_endDate, setEndDate] = useState('');

  const [_searchOption, setSearchOption] = useState('');
  const [_searchText, setSearchText] = useState('');

  const [_modalStatus, setModalStatus] = useState(false);
  const [_eventWorkOrderLogId, setEventWorkOrderLogId] = useState();

  const [_fileListModalStatus, setFileListModalStatus] = useState(false);
  const [_eventcontent, setEventContent] = useState({});

  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter(
      (thisItem) =>
        thisItem.authority.startsWith('104') === true || // 거래처
        thisItem.authority.startsWith('109') === true || // 파일
        thisItem.authority.startsWith('408') === true,
    );
    const authorityCodes = authorityContent.map(
      (thisItem) => thisItem.authority,
    );
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getWorkOrderTypes();
    getWorkOrderLogList(0, 'all', 'all');

    setOnload('loaded');

    return () => { };
  }, []);

  useEffect(() => {
    dispatch(productionHistoryActions_setCurrentPageNum(1));

    return () => { };
  }, [pageReducer.currentPage]);

  useEffect(() => {
    if (_onload === 'loaded') {
      getWorkOrderLogList(
        productionHistoryReducer.currentPageNum - 1,
        _workOrderType,
        _workOrderStatus,
      );
    }

    return () => { };
  }, [productionHistoryReducer.currentPageNum]);

  /* ========================================================================= #4 */
  const getWorkOrderTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await workOrderTypeApi.searchWorkOrderType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderTypeApi.searchWorkOrderType : ', response);

      setWorkOrderTypes(() => {
        return response.data;
        // return response.data.content;
      });
    });
  };

  const getWorkOrderLogList = async (
    page,
    workOrderType,
    workOrderStatus,
    clear,
  ) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    if (
      !clear &&
      workOrderType !== undefined &&
      workOrderType !== null &&
      workOrderType !== 'all'
    ) {
      BodyToPost.workOrderTypeId = Number(workOrderType);
    }

    if (
      // !clear &&
      workOrderStatus !== undefined &&
      workOrderStatus !== null &&
      workOrderStatus !== ''
    ) {
      switch (workOrderStatus) {
        case 'all':
          BodyToPost.workOrderStatus = [
            'wait',
            'work',
            'pause',
            'stop',
            // 'end',
            // 'cancel',
          ];
          break;

        case 'waitNwork':
          BodyToPost.workOrderStatus = ['wait', 'work'];
          break;

        default:
          BodyToPost.workOrderStatus = [workOrderStatus];
          break;
      }
    }

    if (!clear && _startDate !== '' && _endDate !== '') {
      BodyToPost.actualStartDate = DateFormat(_startDate);
      BodyToPost.actualEndDate = DateFormat(_endDate);
      if (_startDate > _endDate) {
        BodyToPost.actualStartDate = DateFormat(_endDate);
        BodyToPost.actualEndDate = DateFormat(_startDate);
      }
    }

    if (
      !clear &&
      _searchOption !== undefined &&
      _searchOption !== null &&
      _searchOption !== '' &&
      _searchText !== undefined &&
      _searchText !== null &&
      _searchText !== ''
    ) {
      BodyToPost[_searchOption] = _searchText;
    }
    console.log('getWorkOrderLogList - BodyToPost : ', BodyToPost);

    await qualityApi
      .searchQualityWorkOrderLog(paging, BodyToPost)
      .then((response) => {
        if (response === undefined) return;
        console.log('qualityApi.searchQualityWorkOrderLog : ', response);

        setWorkOrderLogList(() => {
          return response.data.content;
        });

        dispatch(
          productionHistoryActions_setTotalSize(response.data.totalElements),
        );

        if (_startDate !== '' && _endDate !== '') {
          if (_startDate > _endDate) {
            setStartDate(() => {
              return _endDate;
            });
            setEndDate(() => {
              return _startDate;
            });
          }
        }
      });
  };

  /* ========================================================================= #5 */
  /* 검색 */
  const actSearch = async () => {
    dispatch(productionHistoryActions_setCurrentPageNum(1));
    await getWorkOrderLogList(0, _workOrderType, _workOrderStatus);
  };

  /* 초기화 */
  const actReset = async () => {
    setWorkOrderType(() => {
      return 'all';
    });
    setWorkOrderStatus(() => {
      return 'all';
    });

    setStartDate(() => {
      return '';
    });
    setEndDate(() => {
      return '';
    });

    setSearchOption(() => {
      return '';
    });
    setSearchText(() => {
      return '';
    });

    dispatch(productionHistoryActions_setCurrentPageNum(1));
    await getWorkOrderLogList(0, 'all', 'all', true);
  };

  /* 생산추적 모달 */
  const showProductionProcessTracking = (eventId) => {
    setEventWorkOrderLogId(eventId);

    setTimeout(setModalStatus(true), 1000);
  };

  const showFileList = (content, work) => {
    console.log('showFileList : ', work, content);
    setEventContent(() => {
      if (work === true) {
        return { ...content, work: true };
      } else {
        return content;
      }
    });

    setTimeout(setFileListModalStatus(true), 1000);
  };

  /* ========================================================================= #6 */

  return (
    <Grid3Body
      contents={
        <>
          <MainSection className="Main">
            <NavBar
              title={<NavTitle menuCode={'408'} />}
              nav={'search'}
              firstRow={
                <>
                  <div
                    className="SearchSection"
                    style={{
                      columnGap: '20px',
                    }}
                  >
                    <select
                      className="SelectType"
                      value={_workOrderType}
                      onChange={(e) => {
                        setWorkOrderType(() => {
                          return e.target.value;
                        });

                        dispatch(productionHistoryActions_setCurrentPageNum(1));
                        getWorkOrderLogList(
                          0,
                          e.target.value,
                          _workOrderStatus,
                        );
                      }}
                    >
                      <option value="all">전체</option>
                      {_workOrderTypes.map((thisItem) => {
                        return (
                          <option
                            key={thisItem.workOrderTypeId + '_workOrderType'}
                            value={thisItem.workOrderTypeId}
                          >
                            {thisItem.workOrderTypeName}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      className="SelectStatus"
                      value={_workOrderStatus}
                      onChange={(e) => {
                        setWorkOrderStatus(() => {
                          return e.target.value;
                        });

                        dispatch(productionHistoryActions_setCurrentPageNum(1));
                        getWorkOrderLogList(0, _workOrderType, e.target.value);
                      }}
                    >
                      {[
                        { optionValue: 'all', optionText: '전체' },
                        {
                          optionValue: 'waitNwork',
                          optionText: '대기 & 작업중',
                        },
                        { optionValue: 'wait', optionText: '대기' },
                        { optionValue: 'work', optionText: '작업중' },
                        { optionValue: 'pause', optionText: '일지정지' },
                        { optionValue: 'stop', optionText: '중단' },
                        // { optionValue: 'end', optionText: '완료' },
                        // { optionValue: 'cancel', optionText: '취소' },
                      ].map((thisItem) => {
                        return (
                          <option
                            key={thisItem.optionValue + '_workOrderStatus'}
                            value={thisItem.optionValue}
                          >
                            {thisItem.optionText}
                          </option>
                        );
                      })}
                    </select>
                    <div className="SearchSection">
                      <input
                        type="date"
                        className="SelectDate"
                        value={_startDate}
                        onChange={(e) => {
                          setStartDate(() => {
                            return e.target.value;
                          });
                        }}
                      />

                      <IconRightArrow />

                      <input
                        type="date"
                        className="SelectDate"
                        value={_endDate}
                        onChange={(e) => {
                          setEndDate(() => {
                            return e.target.value;
                          });
                        }}
                      />
                    </div>

                    <div className="ContentCBox">
                      <div className="ContentCBox">
                        <select
                          className="SearchOption"
                          onChange={(e) => {
                            setSearchOption(() => {
                              return e.target.value;
                            });
                          }}
                        >
                          <option value="">검색옵션</option>
                          {/* <option value="workOrderLogType">작업유형</option> */}
                          <option value="workOrderCode">작업코드</option>
                          <option value="workOrderName">작업이름</option>
                        </select>
                        <input
                          className="SearchBar"
                          placeholder="Search..."
                          value={_searchText}
                          onInput={(e) => {
                            setSearchText(() => {
                              return e.target.value;
                            });
                          }}
                        />
                      </div>

                      <button data-searchbutton="true" onClick={actSearch}>
                        <SearchButtonIcon />
                      </button>
                      <div className="ResetButton" onClick={actReset}>
                        초기화
                      </div>
                    </div>
                  </div>
                </>
              }
            />

            <TableSection
              content={
                <table>
                  <thead>
                    <tr>
                      <th
                        rowSpan={2}
                        style={{
                          minWidth: '100px',
                          padding: 'unset',
                          width: '100px',
                        }}
                      ></th>
                      <th
                        rowSpan={2}
                        style={{
                          minWidth: '60px',
                          padding: 'unset',
                          width: '60px',
                        }}
                      >
                        작업상태
                      </th>
                      {(() => {
                        if (
                          _authority.indexOf('109-1') !== -1 ||
                          _authority.indexOf('109-2') !== -1
                        ) {
                          return (
                            <th
                              rowSpan={2}
                              style={{ minWidth: '70px', width: '70px' }}
                            >
                              파일
                            </th>
                          );
                        } else {
                          return null;
                        }
                      })()}
                      <th
                        rowSpan={2}
                        style={{
                          minWidth: '60px',
                          padding: 'unset',
                          width: '60px',
                        }}
                      >
                        작업유형
                      </th>
                      <th rowSpan={2}>작업코드</th>
                      <th rowSpan={2}>작업이름</th>
                      {(() => {
                        if (
                          _authority.indexOf('104-1') !== -1 ||
                          _authority.indexOf('104-2') !== -1
                        ) {
                          return <th rowSpan={2}>거래처</th>;
                        } else return null;
                      })()}
                      <th
                        colSpan={5}
                        style={{ backgroundColor: 'var(--ThirdBlue)' }}
                      >
                        생산목표품목
                      </th>
                      {/* <th rowSpan={2}>작업장소</th> */}
                      <th rowSpan={2}>작업기간</th>
                      <th rowSpan={2}>작성자</th>
                      <th rowSpan={2}>책임자</th>
                      <th rowSpan={2}>작업자</th>
                      {/* <th rowSpan={2}>비고</th> */}
                    </tr>
                    <tr>
                      <th>물품이름</th>
                      <th>물품코드</th>
                      <th className="resultProductStyle">목표수량</th>
                      <th
                        className="resultProductStyle"
                        style={{
                          backgroundColor: 'var(--MainGreen)',
                        }}
                      >
                        성공수량
                      </th>
                      <th
                        className="resultProductStyle"
                        style={{ backgroundColor: 'var(--MainRed)' }}
                      >
                        실패수량
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {_workOrderLogList.map((thisItem, index) => {
                      return (
                        <QualityRow
                          key={index + '_qualityRow'}
                          authority={_authority}
                          content={thisItem}
                          index={index}
                          showFileList={showFileList}
                          showProductionProcessTracking={
                            showProductionProcessTracking
                          }
                        />
                      );
                    })}
                  </tbody>
                </table>
              }
            ></TableSection>
          </MainSection>

          <PagingComponent
            page={productionHistoryReducer.currentPageNum}
            count={productionHistoryReducer.totalSize}
            size={10}
            pageEvent={(page) => {
              console.log('page : ', page);
              dispatch(productionHistoryActions_setCurrentPageNum(page));
            }}
          />

          {_modalStatus === true && (
            <ProductionProcessTracking
              productionId={_eventWorkOrderLogId}
              open={_modalStatus}
              close={() => {
                setModalStatus(false);
              }}
            />
          )}

          {_fileListModalStatus === true &&
            _eventcontent.workOrderLogId !== undefined ? (
            <ShowFileList
              type={'workOrderLog'}
              content={_eventcontent}
              open={_fileListModalStatus}
              close={() => {
                setFileListModalStatus(false);
              }}
            ></ShowFileList>
          ) : null}
        </>
      }
    />
  );
};

export default ProductionHistory;
