import React, { useState, useMemo } from 'react';
import { Container, Table, Tbody, Td, Th, Thead, Title, Tr, StyledTr, AccountInfoWrapper, PaginationContainer, ListTableContainer, ListTableWrapper } from './Tab.style';
import ContractShipmentItems from './ContractShipmentItems';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';

const STATUS_LABELS = {
  wait: '대기',
  end: '완료',
};

const getStatusLabel = (status) => STATUS_LABELS[status] || '-';
const formatNum = (num) =>
  num != null ? new Intl.NumberFormat().format(num) : '-';

export default function ContractShipment({ data }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedShipmentId, setSelectedShipmentId] = useState(null);
  const itemsPerPage = 5;

  const totalPages = useMemo(
    () => Math.ceil(data.length / itemsPerPage),
    [data.length],
  );
  const currentItems = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    return data.slice(start, start + itemsPerPage);
  }, [data, currentPage]);

  const handlePageChange = (delta) => {
    setCurrentPage((prev) => prev + delta);
    setSelectedShipmentId(null);
  };

  const selectedShipment = useMemo(
    () => data.find((shipment) => shipment.shipmentId === selectedShipmentId),
    [data, selectedShipmentId],
  );

  return (
    <Container>
      <ListTableContainer>
        <ListTableWrapper>
          <Title>
            출하목록
            <PaginationContainer>
              <button
                onClick={() => handlePageChange(-1)}
                disabled={currentPage === 1}
              >
                <AiFillCaretLeft style={{ fontSize: '24px' }} />
              </button>
              <button
                onClick={() => handlePageChange(1)}
                disabled={currentPage === totalPages}
              >
                <AiFillCaretRight style={{ fontSize: '24px' }} />
              </button>
            </PaginationContainer>
          </Title>
          <Table>
            <Thead>
              <Tr>
                <Th>출하상태</Th>
                <Th>출하일</Th>
                <Th>출하이름</Th>
                <Th>출하코드</Th>
                <Th>출하금액</Th>
                <Th>비고</Th>
                <Th>거래처</Th>
                <Th>운전자</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentItems.map((shipment) => {
                const customizedContent = JSON.parse(
                  shipment.customizedContent || '{}',
                );
                const { driver, telNumber, licensePlateNumber } =
                  customizedContent.delivery || {};

                const accountInfo = [
                  { label: '거래처명', value: shipment.shipmentAccountName },
                  { label: '거래처코드', value: shipment.shipmentAccountCode },
                  {
                    label: '사업자등록번호',
                    value: shipment.shipmentBusinessNumber,
                  },
                  { label: '주소', value: shipment.shipmentAddress },
                  { label: '전화번호', value: shipment.shipmentTelNumber },
                  { label: '담당자', value: shipment.shipmentManagerName },
                ].filter(({ value }) => value);

                const driverInfo = [
                  { label: '이름', value: driver },
                  { label: '전화번호', value: telNumber },
                  { label: '차량번호', value: licensePlateNumber },
                ].filter(({ value }) => value);

                return (
                  <StyledTr
                    key={shipment.shipmentId}
                    onClick={() =>
                      setSelectedShipmentId(
                        shipment.shipmentId === selectedShipmentId
                          ? null
                          : shipment.shipmentId,
                      )
                    }
                    isSelected={shipment.shipmentId === selectedShipmentId}
                  >
                    <Td>{getStatusLabel(shipment.shipmentStatus)}</Td>
                    <Td>{shipment.shipmentDate || '-'}</Td>
                    <Td>{shipment.shipmentName}</Td>
                    <Td>{shipment.shipmentCode}</Td>
                    <Td style={{ textAlign: 'right' }}>
                      {formatNum(shipment.shipmentPrice)}
                    </Td>
                    <Td>{shipment.remark || '-'}</Td>
                    <Td>
                      {accountInfo.length > 0 ? (
                        <AccountInfoWrapper>
                          {accountInfo.map(({ label, value }, idx) => (
                            <div key={idx}>{`${label}: ${value}`}</div>
                          ))}
                        </AccountInfoWrapper>
                      ) : ( '-' )}
                    </Td>
                    <Td>
                      {driverInfo.length > 0 ? (
                        <AccountInfoWrapper>
                          {driverInfo.map(({ label, value }, idx) => (
                            <div key={idx}>{`${label}: ${value}`}</div>
                          ))}
                        </AccountInfoWrapper>
                      ) : ( '-' )}
                    </Td>
                  </StyledTr>
                );
              })}
            </Tbody>
          </Table>
        </ListTableWrapper>

        {selectedShipment && (
          <ListTableWrapper>
            <ContractShipmentItems shipment={selectedShipment} />
          </ListTableWrapper>
        )}
      </ListTableContainer>
    </Container>
  );
};
