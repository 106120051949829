import styled from 'styled-components';

export const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

export const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px calc(100% - 55px);
  height: 85%;
  overflow: hidden;
  width: 95%;
`;

export const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

export const ModalHeaderButtons = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  position: absolute;
  right: 55px;

  & .btn-set {
    border: 1px solid var(--gray-300);
    box-shadow: unset;
    font-size: 15px;
    height: 35px;
    padding-inline: 20px;
    width: fit-content;
  }

  & > div {
    align-items: center;
    display: flex;
    gap: 10px;
  }
`;

export const TabButton = styled.button`
  /* border: 1px solid var(--gray-300);
  background-color: var(--white);
  color: var(--gray-700);
  font-size: 15px;
  height: 35px;
  padding: 0 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s; */
  background-color: var(--MainBlue);
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 5px 20px;
  transition: background-color 0.3s;
  width: fit-content;

  &:hover {
    /* background-color: var(--gray-200);
    color: var(--gray-900); */
    background-color: var(--MainNavy);
  }

  /* &:active {
    background-color: var(--gray-300);
    color: var(--gray-800);
  } */

  &.active {
    /* background-color: var(--primary-color);
    color: var(--white); */
    background-color: var(--MainNavy);
  }
`;

export const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

export const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
    td,
    th {
      min-width: 150px;
    }
    td.amountCell,
    th.amountCell {
      min-width: 100px;
    }
  }
`;

export const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;