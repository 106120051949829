const initialState = {
  folderList: [],
  folderTreeList: {
    name: 'root',
    active: true,
    toggled: true,
    root: true,
    children: [],
  },
  currentFolder: {},
  deleteFolder: {},

  fileList: [],

  search: {
    fileDataName: '',
  },
  currentPage: 1,
  totalSize: 0,
};

const modalFileReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETMODALFOLDERLIST':
      return {
        ...state,
        folderList: action.payload,
      };

    case 'SETMODALFOLDERTREELIST':
      return {
        ...state,
        folderTreeList: action.payload,
      };

    case 'SETMODALCURRENTFOLDER':
      return {
        ...state,
        currentFolder: action.payload,
      };

    case 'SETMODALDELETEFOLDER':
      return {
        ...state,
        deleteFolder: action.payload,
      };

    case 'SETMODALFILELIST':
      return {
        ...state,
        fileList: action.payload,
      };

    case 'SETMODALFILESEARCH':
      return {
        ...state,
        search: action.payload,
      };

    case 'SETMODALFILECURRENTPAGE':
      return {
        ...state,
        currentPage: action.payload,
      };

    case 'SETMODALFILETOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };

    case 'SETMODALFILERESET':
      return initialState;

    default:
      return state;
  }
};

export default modalFileReducer;
