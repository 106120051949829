import React from 'react';

const Compare = () => {
  return (
    <div className='Compare'>
      <h4>사용비율 증감정보</h4>
    </div>
  );
};

export default Compare;