import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  max-height: 300px;
  max-width: 400px;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  height: 100%;
  width: 100%;

  form {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  textarea {
    box-sizing: border-box;
    display: block;
    height: 80%;
    padding: 10px;
    width: 90%;
  }
  textarea:focus {
    outline: unset;
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const RemarkModal = (props) => {
  const [_remark, setRemark] = useState(props.remark);

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>비고</ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              <form>
                <textarea
                  maxLength={1000}
                  value={_remark}
                  onInput={(e) => {
                    if (e.target.value.length > 1000) return;
                    setRemark(() => {
                      return e.target.value;
                    });
                  }}
                ></textarea>
              </form>
            </ModalMain>

            <ModalFooter>
              <ModalButton
                onClick={() => {
                  props.setRemark(() => {
                    return _remark;
                  });

                  setTimeout(() => {
                    props.close();
                  }, 1000);
                }}
              >
                저장
              </ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default RemarkModal;
