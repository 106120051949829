import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';

import { teamApi } from 'api/apis/teamApi';
import { userApi } from 'api/apis/userApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import { LoadingScreen } from '../UserModal.style';

const DownloadContent = ({
  close
}) => {
  const {userReducer} = useSelector((state) => state);

  const body = {
    companyId: userReducer.company.companyId,
    teamName: '',
    name: '',
  };
  const [_body, setBody] = useState(body);
  const [_teamList, setTeamList] = useState([]);
  const [_loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => {
    getTeamList();
  }, []);
  useEffect(() => {}, [_body]);

  const getTeamList = async () => { // 팀 리스트 불러오기
    const paging = `?page=0&size=1000`;
    const body = {
      companyId: userReducer.company.companyId,
    };
    await teamApi.searchTeam(paging, body).then((response) => {
      if (response === undefined) return;
      setTeamList(() => {return response.data.content;});
    });
  };

  const handleInputEvent = (e) => { // 검색 정보 입력
    const {name, value} = e.target;
    setBody((prev) => {
      const returnData = {...prev, [name] : value};
      return returnData;
    })
  };

  const actDownload = async () => { // 사용자 리스트 다운로드
    const paging = `?page=0&size=7000&sort=id,DESC`;
    await userApi.searchUser(paging, _body).then((response) => {
      if (response === undefined) return;
      setExcelData(response.data.content);
    }).finally(() => {
      setLoadingStatus(false);
      close();
    })
  };
  const setExcelData = (Result) => {
    const userList = Result;

    const excelList = userList.map((userData) => {
      const name = `${!checkEmptyNull(userData.email, false) ? '[작업자] ' : ''}${userData.name}`;
      const returnData = {
        팀: checkEmptyNull(userData.team?.teamName, ''),
        이름: name,
        이메일: checkEmptyNull(userData.email, '-'),
        연락처: checkEmptyNull(userData.phoneNumber, ''),
        메모: checkEmptyNull(userData.memo, ''),
        아이디: userData.loginId,
      };

      return returnData;
    });
    console.log('excelList : ', excelList);

    const ws = XLSX.utils.json_to_sheet(excelList);
    const wb = XLSX.utils.book_new();

    let fileTitle = '사용자 리스트';
    XLSX.utils.book_append_sheet(wb, ws, fileTitle);
    XLSX.writeFile(wb, fileTitle + '.xlsx');
  };

  return (
    <>
    <section className='download-content'>
      <div>
        <div>
          <h4>팀</h4>
          <select name='teamName' value={_body.teamName} onChange={handleInputEvent}>
            <option value={''}>팀 선택</option>
          {_teamList.map((teamData, index) => {
            return (
              <option key={index + '_team'} value={teamData.teamName}>{teamData.teamName}</option>
            )
          })}
          </select>
        </div>
        {[
          {name: 'name', title: '이름', disabled: false},
        ].map(((userData) => {
          return (
            <div key={userData.name}>
              <h4>{userData.title}</h4>
              <input
                type='text'
                name={userData.name}
                disabled={userData.disabled}
                value={checkEmptyNull(_body[userData.name], '')}
                onInput={handleInputEvent}
              />
            </div>
          )
        }))}
      </div>

      <button className='btn-save' onClick={actDownload}>다운로드</button>
    </section>
    
    {_loadingStatus === true ? (
      <LoadingScreen>다운로드하고 있습니다...</LoadingScreen>
    ) : null}
    </>
  );
};

export default DownloadContent;