import React from 'react';
import { useOutletContext } from 'react-router-dom';

import ProductionMonitoring from 'pages/monitoring/contents/ProductionMonitoring';

const MonitoringProduction = () => {
  const {setTimeCounter} = useOutletContext();

  return (
    <div className='monitoringBody single'>
      <ProductionMonitoring total={false} setTimeCounter={setTimeCounter} />
    </div>
  );
};

export default MonitoringProduction;