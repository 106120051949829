import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { auth } from 'api/apis/auth/auth';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { checkEmail } from 'components/checkValues/sign';

const FindEmailSection = styled.main`
  align-items: center;
  background-color: var(--MainNavy);
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;

  .sign {
    display: grid; justify-content: center; align-items: center; padding:10% 0; row-gap: 30px;
    &>p{color:#898989;margin:8px;}
    fieldset {display:grid;gap:18px;
      input, select{width:450px;padding:16px 24px;margin:0;border-radius:8px;border:1px solid #ddd;}
    }
    .submit {width:100%;height:60px; background-color: var(--MainBlue);color:var(--white); border-radius:10px;font-size:1.25rem;}
    .submit.google {background-color: var(--ThirdBlue); margin-top: 24px;}
    .signitem {display:flex;gap:16px;margin-top:24px; justify-content: space-between;
      button{color:var(--white);}
    }
    .googleBtn {background-color: var(--white); color: var(--gray-800);border:1px solid #ddd;}
  }

  .signup {
    padding:4% 0;
    fieldset{
      &>p{color:#898989;margin:8px;span{color:#000;}}
    }
  }

  .signTitle {
    align-items: center;
    column-gap: 20px;
    display: grid;
    grid-template-columns: 50px auto;
    /* margin: 0px auto; */
    width: fit-content;

    img {height: 50px; width: 50px;}
    h3 {color: var(--white); font-size: 30px;}
  }

  .findLoginIn {
    color: var(--white);
    text-align: center;
    width: 100%;
  }
`;

const FindEmail = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();

  /* ====================================================================== #2 */
  const [_companyList, setCompanyList] = useState([]);
  const [_companyId, setCompanyId] = useState('');

  const [_userName, setUserName] = useState('');

  const [_emailStatus, setEmailStatus] = useState(false);
  const [_email, setEmail] = useState('');

  const [_loginId, setLoginId] = useState('');

  /* ====================================================================== #3 */
  useEffect(() => {
    (async () => {
      await getCompanyList();
    })();
  }, []);

  /* ====================================================================== #4 */
  const getCompanyList = async () => {
    await auth.searchCompanies().then((response) => {
      if (response === undefined) return;
      console.log('auth.searchCompanies : ', response);

      setCompanyList(() => { return checkNullArray(response.data.content, response.data) });
    });
  };

  /* ====================================================================== #5 */
  // 아이디 찾기
  const actFind = async () => {
    if (!checkEmptyNull(_companyId, false)) return alert('회사를 선택해주세요.')
    if (!checkEmptyNull(_userName, false)) return alert('이름을 확인해주세요.');
    if (!checkEmptyNull(_email, false) || !checkEmail(_email)) return alert('이메일을 확인해주세요.');

    const BodyToPost = {
      companyId: Number(_companyId),
      name: _userName,
      email: _email,
    };
    console.log('actFind - BodyToPost : ', BodyToPost);

    await auth.findUserLoginId(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('auth.findUserLoginId : ', response);

      setLoginId(() => { return response.data.loginId });
    });
  };

  /* ====================================================================== #6 */

  return (
    <FindEmailSection>
      <section className='sign'>
        <div className='signTitle'>
          <img src={process.env.PUBLIC_URL + '/logo_Ifis.svg'} alt="아이피스 로고" />
          <h3>아이피스 아이디 찾기</h3>
        </div>
        <fieldset>
          <select required value={checkEmptyNull(_companyId)}
            onChange={(e) => {
              const companyId = Number(e.target.value);
              setCompanyId(() => { return companyId; });
            }}>
            <option value={""}>회사선택</option>
            {_companyList.map((company, index) => (
              <option key={index + '__companys'} value={company.companyId}>{company.companyName}</option>
            ))}
          </select>

          <input type="text" placeholder="이름" required
            value={_userName}
            onInput={(e) => {
              const userName = e.target.value;
              setUserName(() => { return userName; });
            }}
          />
          <input
            type="email"
            name="email"
            placeholder="이메일..."
            data-status={_emailStatus}
            value={_email}
            onInput={(e) => {
              const email = e.target.value;
              setEmailStatus(() => { return checkEmail(email) });
              setEmail(() => { return email });
            }}
          />
        </fieldset>
        {_loginId.length > 0 ? (
          <div className='findLoginIn'>{_loginId}</div>
        ) : null}
        {_loginId.length === 0 ? (
          <button type="submit" className='submit' onClick={actFind}>확인</button>
        ) : null}
        <div className='signitem'>
          <button onClick={() => { navigate(`/sign/login`, { replace: true }); }} >로그인</button>
          <button onClick={() => { navigate(`/sign/pw/find`, { replace: true }); }} >비밀번호 찾기</button>
          <button onClick={() => { navigate(`/companySelect`, { replace: true }); }} >회원가입</button>
        </div>
      </section>
    </FindEmailSection>
  );
};

export default FindEmail;
