import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat';

import ProcessResultModal from 'pages/barcode/print/contents/modal/ProcessResultModal';

const ProcessResultSection = styled.section`
  align-items: flex-start;
  background-color: var(--white);
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  height: 100%;
  justify-content: center;
  overflow: auto;
  padding-block: 30px;
  width: 100%;

  & .processResultContent {
    align-items: flex-start;
    background-color: var(--white);
    border: 1px solid var(--gray-200);
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    box-sizing: border-box;
    cursor: pointer;
    display: grid;
    gap: 12px;
    height: fit-content;
    padding: 20px 30px;
    width: 90%;

    &:hover {box-shadow: unset;}
    &.active {
      background-color: var(--Bg);
      border: unset;
      box-shadow: unset;
      opacity: 0.7;
    }

    & .content-header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;

      & input[type="checkbox"] {
        margin: unset;
        z-index: 0;
      }
    }

    & .content-main {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(3, auto);
      width: 100%;

      & .content-data {
        align-items: flex-start;
        display: flex;
        /* flex-wrap: wrap; */
        gap: 10px;

        & b {
          color: var(--gray-300);
          font-size: 0.9em;
          font-weight: 600;
          min-width: 60px;
          text-align: right;
        }
      }

      & table {
        border-collapse: collapse;
        grid-column: 2/3;
        grid-row: 1/3;
        height: 100%;
        width: 100%;

        & th, & td {
          border: 1px solid var(--gray-200);
          box-sizing: border-box;
          padding: 5px;
          font-size: 0.8em;
          text-align: center;
        }
        & th {
          background-color: var(--MainNavy);
          color: var(--white);
        }
      }

      & .btn-detail {
        background-color: var(--Violet);
        border-radius: 25px;
        color: var(--white);
        height: 100%;
        padding: 10px;
        width: 100%;
      }
    }
  }
`;

const ProcessResult = (props) => {
  const { currentProcessResultEvent } = props;
  const [_processResultList, setProcessResultList] = useState(props.processResultList);
  const [_currentProcessResult, setCurrentProcessResult] = useState(props.currentProcessResult);

  const [_eventProcessResult, setEventProcessResult] = useState({});
  const [_processResultModalStatus, setProcessResultModalStatus] = useState(false);

  useEffect(() => {
    setProcessResultList(() => { return props.processResultList });
    setCurrentProcessResult(() => { return props.currentProcessResult });
  }, [props])

  return (
    <>
      <ProcessResultSection className='processResultSection'>
        {checkNullArray(_processResultList, []).map((processResult, index) => {
          return (
            <div key={index + '_processResult'}
              className={_currentProcessResult.processResultId === processResult.processResultId ? 'processResultContent active' : 'processResultContent'}
              onClick={() => {
                currentProcessResultEvent(() => { return processResult });
              }}
            >
              <div className='content-header'>
                <div></div>
                <div>{DateTimeFormat(checkEmptyNull(processResult.createdDate, ''))}</div>
              </div>
              <div className='content-main'>
                {[
                  { name: '공정유형', value: processResult.processTypeName },
                  { name: '공정코드', value: processResult.processCode },
                  { name: '공정이름', value: processResult.processName },
                ].map((content, contentIndex) => {
                  return (
                    <div key={contentIndex + '_content_processResult'} className='content-data'>
                      <b>{content.name}</b>{content.value}
                    </div>
                  )
                })}

                <table>
                  <thead>
                    <tr>
                      <th>책임자</th>
                      <th>작업자</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {checkNullArray(processResult.managers, []).map((manager, managerIndex) => {
                          return <Fragment key={managerIndex + '_managers'}>{manager.managerName}<br /></Fragment>
                        })}
                      </td>
                      <td>
                        {checkNullArray(processResult.workers, []).map((worker, workerIndex) => {
                          return <Fragment key={workerIndex + '_workers'}>{worker.workerName}<br /></Fragment>
                        })}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <button className='btn-detail'
                  onClick={(e) => {
                    e.stopPropagation();
                    setEventProcessResult(() => { return processResult });
                    setTimeout(setProcessResultModalStatus(() => { return true }), 1000);
                  }}
                >
                  상세보기
                </button>
              </div>
            </div>
          )
        })}
      </ProcessResultSection>

      {_processResultModalStatus === true && (
        <ProcessResultModal
          processResult={_eventProcessResult}
          open={_processResultModalStatus}
          close={() => { setProcessResultModalStatus(false) }}
        />
      )}
    </>
  );
};

export default ProcessResult;