import { AxiosInterceptor } from "api/core/consts";

export const contractAttributeApi = {
  searchContractAttribute: (body) =>
    AxiosInterceptor.post('contractAttribute/search', body),
  createContractAttribute: (body) =>
    AxiosInterceptor.post('contractAttribute', body),
  updateContractAttribute: (contractAttributeId, body) =>
    AxiosInterceptor.put('contractAttribute/' + contractAttributeId, body),
  deleteContractAttribute: (contractAttributeId) =>
    AxiosInterceptor.delete('contractAttribute/' + contractAttributeId),
};
