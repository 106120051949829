import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';

const DeleteProductionOrder = () => {
  const navigate = useNavigate();
  const { daerimReducer, pageReducer } = useSelector((state) => state);

  const actDelWorkOrderLog = async () => {
    await workOrderLogApi.deleteWorkOrderLog(daerimReducer.deleteContent.workOrderLogId).then((response) => {
      if (response === undefined) return;
      console.log('delete/workOrderLog - response : ', response);
      if (response.status === 200) {
        alert(
          `
          작업코드: ${daerimReducer.deleteContent.workOrderCode !== null
            ? daerimReducer.deleteContent.workOrderCode
            : ''
          } 
          작업이름: ${daerimReducer.deleteContent.workOrderName !== null
            ? daerimReducer.deleteContent.workOrderName
            : ''
          } 
          작업지시를 삭제했습니다.`,
        );
        navigate(pageReducer.currentPage, { replace: true });
      }
    });
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <>
        <main className="Main">
          <NavBar title={'작업지시'} nav={''} />

          <DeleteForm
            deleteTitle={<>작업지시</>}
            deleteItem={
              <>
                작업유형 : {checkEmptyNull(daerimReducer.deleteContent.workOrderTypeName, '')}<br />
                작업코드 : {checkEmptyNull(daerimReducer.deleteContent.workOrderCode, '')}<br />
                작업이름 : {checkEmptyNull(daerimReducer.deleteContent.workOrderName, '')}<br />
              </>
            }
            deleteText={<>해당 작업지시를 삭제합니다.</>}
            deleteButton={
              <>
                <button className="formButton cancle" onClick={() => { navigate(pageReducer.currentPage, { replace: true }); }}>뒤로</button>
                <button className="formButton delete" onClick={actDelWorkOrderLog}>삭제</button>
              </>
            }
          />
        </main>
      </>
    }
    />
  );
};

export default DeleteProductionOrder;
