import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { workActions_setCurrentMessage, workActions_setEndDate, workActions_setPageNumber, workActions_setReset, workActions_setSearchData, workActions_setSearchOption, workActions_setSearchText, workActions_setStartDate, workActions_setTotalSize, workActions_setWorkCase, workActions_setWorkOrderStatus, workActions_setWorkOrderType, workActions_setWorker } from 'store/modules/actions/default/workActions';

import { getCookie } from 'preferences/cookie/cookie';
import { WS_CLIENT } from 'preferences/server/constants';
import { workOrderTypeApi } from 'api/apis/workOrderTypeApi';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

import { DateFormat } from 'components/format/DateFormat';
import { LoadingMsg } from 'components/loading/LoadingMsg'

import { baseInformationIcon } from 'components/icons/src';
import DOCButtonIcon from 'components/icons/DOCButtonIcon';
import GridNonBody from 'components/layouts/body/GridNonBody';
import Header from 'components/header/Header';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import SelectWorkerModal from 'pages/user/worker/modal/SelectWorkerModal';
import ShowFileList from 'pages/file/modal/ShowFileList';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';
import WorkerConfirmModal from './modal/WorkerConfirmModal';
import WorkerScreenModal from 'pages/working/process/WorkerScreenModal';
import WorkerStatusModal from 'pages/working/modal/WorkerStatusModal';

const Section = styled.main`
  display: grid;
  grid-template-rows: auto minmax(calc(100% - 166px), calc(100% - 66px));
  height: ${props => {
    return props.onHeader ? 'calc(100svh - 120px)' : 'calc(100svh - 60px)';
  }};

  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
    & .statusButtons {
      display: flex;
      gap: 5px;
    }
  }

  & .TableSection {
    height: 100%;

    & .table-preview {
      min-width: 150px;
      width: 150px;
    }
  }
`;

const ProcessSection = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  height: 100%;
  width: 100%;
`;
const Process = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  font-size: 1.25rem;
  font-weight: 400;
  height: 80px;
  justify-content: flex-start;
  outline: 4px solid var(--white);
  outline-offset: -6px;
  padding-left: 20px;
  width: 100%;

  background-color: ${(props) => {
    switch (props.status) {
      case 'wait': return 'var(--gray-200)'
      case 'work': return 'var(--WorkingColor)'
      case 'end': return 'var(--ThirdBlue)'
      case 'stop': return 'var(--MainRed)'
      case 'pause': return 'var(--MainYellow)'
      case 'cancel': return 'var(--Bg)'

      case 'NO': return 'var(--MainNavy)';
      case 'cut': return '#BCE55C'
      case 'welding': return '#9FC93C'
      case 'last': return '#689900'

      default: return 'unset';
    }
  }};

  color: ${(props) => {
    return props.status.toLowerCase() === 'cancel' ? 'var(--gray-200)' : 'var(--white)';
  }};
`;
const CancelWorkOrderLogButton = styled.td`
  background-color: var(--ThirdRed);
  color: white;
  cursor: pointer;
  font-size: 1em;
  min-width: 60px;
  width: 60px;
`;

const WorkerButton = styled.div`
  align-items: center;
  background-color: ${props => {
    return props.status === true ? 'var(--MainNavy)' : 'var(--white)';
  }};
  border-radius: 20px;
  box-shadow: 0 0 16px 3px rgba(0 0 0 / 15%);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: ${props => {
    return props.status === true ? 'flex-end' : 'flex-start';
  }};
  left: 10px;
  padding-inline: 5px;
  position: fixed;
  width: 200px;

  &::after {
    content: '책임자/작업자 변경';
    color: ${props => {
    return props.status === true ? 'var(--white)' : 'var(--MainNavy)';
  }};
    font-size: 16px;
    position: absolute;
    width: fit-content;

    ${props => {
    return props.status === true ? 'left: 20px; right: unset;' : 'left: unset; right: 20px;';
  }};
  }

  & .btn-toggle{
    background-color: ${props => {
    return props.status === true ? 'var(--white)' : 'var(--MainNavy)';
  }};
    
    border-radius: 50%;
    height: 30px;
    width: 30px;
  }
`;
const ConnectStatus = styled.div`
  position: fixed;
  right: 80px;
  bottom: 25px;
  background-color: ${(props) => {
    return props.connectStatus === 'connect' ? 'var(--MainGreen)' : 'var(--MainRed)';
  }};
  border-radius: 50%;
  cursor: pointer;
  width: 42px;
  height: 42px;
`;
const DashboardButton = styled.div`
  background-color: var(--MainNavy);
  border-radius: 8px;
  bottom: 84px;
  color: var(--white);
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  gap: 4px;
  padding: 4px 10px;
  position: fixed;
  right: 24px;
  text-align: center;
  width: 110px;
`;

const WorkerScreen = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userReducer, workReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const RoomId = userReducer.company.companyId;

  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_workOrderTypes, setWorkOrderTypes] = useState([]); // 작업유형 리스트
  const [_workOrderLogList, setWorkOrderLogList] = useState([]); // 작업지시 리스트

  const [_eventWorkOrderLog, setEventWorkOrderLog] = useState({});
  const [_modalStatus, setModalStatus] = useState(false); // 공정리스트 모달
  const [_workOrderStatusModal, setWorkOrderStatusModal] = useState(false); // 작업상태 변경 모달
  const [_workEndStatus, setWorkEndStatus] = useState(false); // 작업지시 완료 모달
  const [_confirmModalStatus, setConfirmModalStatus] = useState(false); // 책임자/작업자 변경 선택 모달
  const [_workerModalStatus, setWorkerModalStatus] = useState(false); // 책임자/작업자 선택 모달
  const [_fileListModalStatus, setFileListModalStatus] = useState(false); // 파일 모달

  const [_endBody, setEndBody] = useState({}); // 작업지시 완료

  /* ====================================================================== #3 */
  useEffect(() => {
    dispatch(workActions_setCurrentMessage({}));

    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('104') === true || // 거래처
      thisItem.authority.startsWith('109') === true || // 파일
      thisItem.authority.startsWith('501') === true || // 작업실행
      thisItem.authority.startsWith('403') === true || // 작업지시
      thisItem.authority.startsWith('409') === true ||
      thisItem.authority.startsWith('410') === true,
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));
    dispatch(workActions_setWorkCase('work'));

    getWorkOrderTypes();
    getWorkOrderLogList(workReducer.pageNumber - 1, workReducer.workOrderType, workReducer.workOrderStatus);
    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getWorkOrderLogList(workReducer.pageNumber - 1, workReducer.workOrderType, workReducer.workOrderStatus);
    }
    return () => { };
  }, [workReducer.pageNumber]);

  useEffect(() => {
    if (workReducer.connectStatus === 'connect') {
      if (!checkNullObject(workReducer.currentMessage, false)) return;

      if (workReducer.currentMessage.userId === userReducer.user.id && checkNull(workReducer.currentMessage.errorMessage, false)) {
        alert(workReducer.currentMessage.errorMessage);
        dispatch(workActions_setCurrentMessage({}));
        return;
      }

      if (workReducer.currentMessage.type === '/work') applyStatus();
      if (workReducer.currentMessage.type === 'process') applyProcessStatus();
      if (workReducer.currentMessage.type === 'processes') applyProcessesStatus();
    }

    return () => { };
  }, [workReducer.currentMessage]);

  useEffect(() => { }, [workReducer])

  /* ====================================================================== #4 */
  /* 작업유형 */
  const getWorkOrderTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await workOrderTypeApi.searchWorkOrderType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderTypeApi.searchWorkOrderType : ', response);
      setWorkOrderTypes(() => { return response.data });
    });
  };

  /* 작업지시 */
  const getWorkOrderLogList = async (page, workOrderType, workOrderStatus, clear) => {
    const paging = `?page=${page}&size=5&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    if (!clear && checkEmptyNull(workOrderType, false) && workOrderType !== 'all') {
      BodyToPost.workOrderTypeId = Number(workOrderType);
    }
    if (!clear && checkEmptyNull(workOrderStatus, false)) {
      switch (workOrderStatus) {
        case 'all': BodyToPost.workOrderStatus = ['wait', 'work', 'pause', 'stop', 'end', 'cancel']; break;
        case 'waitNwork': BodyToPost.workOrderStatus = ['wait', 'work']; break;
        default: BodyToPost.workOrderStatus = [workOrderStatus]; break;
      }
    }
    if (!clear && checkEmptyNull(workReducer.startDate, false) && checkEmptyNull(workReducer.endDate, false)) {
      BodyToPost.scheduledStartDate = DateFormat(workReducer.startDate);
      BodyToPost.scheduledEndDate = DateFormat(workReducer.endDate);
      if (workReducer.startDate > workReducer.endDate) {
        BodyToPost.scheduledStartDate = DateFormat(workReducer.endDate);
        BodyToPost.scheduledEndDate = DateFormat(workReducer.startDate);
      }
    } else if (!clear && checkEmptyNull(workReducer.startDate, false) && !checkEmptyNull(workReducer.endDate, false)) {
      BodyToPost.scheduledStartDate = DateFormat(workReducer.startDate);
    } else if (!clear && checkEmptyNull(workReducer.endDate, false) && !checkEmptyNull(workReducer.startDate, false)) {
      BodyToPost.scheduledEndDate = DateFormat(workReducer.endDate);
    }
    if (!clear && checkEmptyNull(workReducer.searchOption, false) && checkEmptyNull(workReducer.searchText, false)) {
      BodyToPost[workReducer.searchOption] = workReducer.searchText;
    }
    console.log('getWorkOrderLogList - BodyToPost : ', BodyToPost);

    await workOrderLogApi.searchWorkOrderLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.searchWorkOrderLog : ', response);

      setWorkOrderLogList(() => { return response.data.data.content; });
      dispatch(workActions_setTotalSize(response.data.data.totalElements));

      if (checkEmptyNull(workReducer.startDate, false) && checkEmptyNull(workReducer.endDate, false)) {
        if (workReducer.startDate > workReducer.endDate) {
          dispatch(workActions_setStartDate(workReducer.endDate))
          dispatch(workActions_setEndDate(workReducer.startDate))
        }
      }
    });
  };

  /* 웹소켓 */
  const applyStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    if (eventWorkOrder === undefined) return;

    eventWorkOrder.workOrderStatus = applyContent.workOrderStatus;
    eventWorkOrder.managers = checkNullArray(applyContent.managers, []);
    eventWorkOrder.workers = checkNullArray(applyContent.workers, []);
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, eventWorkOrder);
    setWorkOrderLogList(eventWorkOrderList);
  };
  const applyProcessStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    if (eventWorkOrder === undefined) return;

    const eventProcessLogList = [...eventWorkOrder.processLogList];
    const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === applyContent.processLog.processLogId);
    const eventContent = eventProcessLogList[eventProcessLogIndex];
    eventContent.processStatus = applyContent.processLog.processStatus;
    eventContent.completedAmount = applyContent.processLog.completedAmount;
    eventContent.processAmount = applyContent.processLog.processAmount;
    eventContent.processingCount = applyContent.processLog.processingCount;
    eventContent.managers = checkNullArray(applyContent.managers, []);
    eventContent.workers = checkNullArray(applyContent.workers, []);
    if (checkNullArray(eventContent.processLogElementList, false)) {
      const eventResultElements = eventContent.processLogElementList.map((thisItem) => {
        const thisFindIndex = applyContent.processResultElements.findIndex((thisElement) => thisElement.processLogElementId === thisItem.processLogElementId);
        if (thisFindIndex === -1) return thisItem;
        const thisFindItem = applyContent.processResultElements[thisFindIndex];
        const returnData = { ...thisItem, ...thisFindItem };
        return returnData;
      });
      eventContent.processLogElementList = eventResultElements;
    }
    eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);

    const detailWorkOrder = {
      ...eventWorkOrder,
      processLogList: eventProcessLogList,
    };
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, detailWorkOrder);
    setWorkOrderLogList(eventWorkOrderList);
  };
  const applyProcessesStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    if (eventWorkOrder === undefined) return;

    const eventProcessLogList = [...eventWorkOrder.processLogList];
    if (checkNullArray(applyContent.socketProcessResults, false)) {
      applyContent.socketProcessResults.forEach((processResult) => {
        const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === processResult.processLog.processLogId);
        if (eventProcessLogIndex === -1) return;

        const eventContent = eventProcessLogList[eventProcessLogIndex];
        eventContent.processStatus = processResult.processLog.processStatus;
        eventContent.completedAmount = processResult.processLog.completedAmount;
        eventContent.processAmount = processResult.processLog.processAmount;
        eventContent.processingCount = processResult.processLog.processingCount;
        eventContent.managers = checkNullArray(processResult.managers, []);
        eventContent.workers = checkNullArray(processResult.workers, []);
        if (checkNullArray(eventContent.processLogElementList, false)) {
          const eventResultElements = eventContent.processLogElementList.map((thisItem) => {
            const thisFindIndex = processResult.processResultElements.findIndex((thisElement) => thisElement.processLogElementId === thisItem.processLogElementId);
            if (thisFindIndex === -1) return thisItem;
            const thisFindItem = processResult.processResultElements[thisFindIndex];
            const returnData = { ...thisItem, ...thisFindItem };
            return returnData;
          });
          eventContent.processLogElementList = eventResultElements;
        }
        eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
      });
    } else {
      applyContent.processLogIds.forEach((processLogIds) => {
        const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === processLogIds);
        if (eventProcessLogIndex === -1) return;
        const eventContent = eventProcessLogList[eventProcessLogIndex];
        eventContent.processStatus = applyContent.processStatus;
        eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
      });
    }

    const detailWorkOrder = {
      ...eventWorkOrder,
      processLogList: eventProcessLogList,
    };
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, detailWorkOrder);
    setWorkOrderLogList(eventWorkOrderList);
  };

  /* ====================================================================== #5 */
  /* 검색 - 작업상태 */
  const handleWorkOrderStatus = async (workOrderStatus) => {
    dispatch(workActions_setWorkOrderStatus(workOrderStatus));
    dispatch(workActions_setPageNumber(1));
    await getWorkOrderLogList(0, workReducer.workOrderType, workOrderStatus)
  }

  /* 검색 */
  const actSearch = async () => {
    dispatch(workActions_setPageNumber(1));
    await getWorkOrderLogList(0, workReducer.workOrderType, workReducer.workOrderStatus);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(workActions_setReset());
    dispatch(workActions_setPageNumber(1));
    await getWorkOrderLogList(0, 'all', 'all', true);
  };

  /* 모달상태 변경 */
  const handleModalStatus = (workOrderLog, modalType) => {
    setEventWorkOrderLog(() => { return workOrderLog });
    switch (modalType) {
      case 'processLogList': return setTimeout(setModalStatus(() => { return true }), 1000); // 공정리스트 모달
      case 'handleWorkOrderStatus': return setTimeout(setWorkOrderStatusModal(() => { return true }), 1000); // 작업상태 변경 모달
      case 'end': return setTimeout(setWorkEndStatus(() => { return true }), 1000); // 작업지시 완료 모달
      case 'handleWorker': return setTimeout(setConfirmModalStatus(() => { return true }), 1000); // 책임자/작업자 변경 선택 모달
      case 'selectWorker': return setTimeout(setWorkerModalStatus(() => { return true }), 1000); // 책임자/작업자 선택 모달
      case 'file': return setTimeout(setFileListModalStatus(() => { return true }), 1000); // 파일 모달

      default: return;
    }
  }

  /* 작업상태 변경 */
  const handleWorkOrderLogStatus = (workOrderLog, workOrderStatus) => {
    if (!checkNullObject(workOrderLog, false) || !checkEmptyNull(workOrderStatus, false)) return;

    const BodyToWS = {
      roomId: RoomId,
      type: '/work',
      workOrderLogId: workOrderLog.workOrderLogId,
      workOrderStatus: workOrderStatus,
    };
    console.log('client.publish/pub/work - body : ', BodyToWS, JSON.stringify(BodyToWS));

    if (workReducer.connectStatus !== 'connect') return alert(`
연결이 불안정합니다.
네트워크 상태 확인 후 다시 시도하여 주시기 바랍니다.
    `);

    if (workOrderStatus === 'end' && workReducer.setWorker) {// 완료 + 작업자/책임자 변경 시
      setEndBody(() => { return { workOrderLog: workOrderLog, BodyToWS: BodyToWS } });
      setTimeout(setConfirmModalStatus(true), 1000);
    } else {
      let statusName = null;
      switch (workOrderStatus) {
        case 'wait': statusName = '대기'; break;
        case 'work': statusName = '진행'; break;
        case 'pause': statusName = '일시정지'; break;
        case 'stop': statusName = '중단'; break;
        case 'end': statusName = '완료'; break;
        case 'cancel': statusName = '취소'; break;
        default: return;
      }
      const confirmText = `작업지시를 ${statusName}합니다.`;
      if (window.confirm(confirmText)) {
        try {
          WS_CLIENT.publish({
            destination: '/pub/work',
            headers: { 'auth-token': getCookie('accessToken')?.replace('Bearer ', '') },
            body: JSON.stringify(BodyToWS),
          });
        } catch (error) {
          console.log('client.publish/pub/work - error : ', error);
        }
        setWorkOrderStatusModal(false);
      } else {
        return;
      }
    }
  };

  /* 책임자/작업자 변경 선택 */
  const actWorkEnd = (type) => {
    if (type === 'workerEvent') {
      // 책임자/작업자 변경 O
      setTimeout(setWorkerModalStatus(true), 1000);
    } else {
      // 책임자/작업자 변경 X
      if (window.confirm('작업지시를 완료합니다.')) {
        try {
          WS_CLIENT.publish({
            destination: '/pub/work',
            headers: { 'auth-token': getCookie('accessToken')?.replace('Bearer ', '') },
            body: JSON.stringify({
              ..._endBody.BodyToWS,
              workOrderStatus: 'end',
              managerIds: _endBody.workOrderLog.managers.map((worker) => worker.managerId),
              workerIds: _endBody.workOrderLog.workers.map((worker) => worker.workerId),
            }),
          });
        } catch (error) {
          console.log('client.publish/pub/work - error : ', error);
        }
        setWorkOrderStatusModal(false);
      } else {
        return;
      }
    }
  };

  /* 작업지시 취소 */
  const actCancel = (workOrderLog) => {
    const confirmText = `
[ 취소 ]상태로 바꿀 시,
작업 상태를 다시 변경할 수 없습니다.
작업을 취소하시겠습니까?
    `;
    if (window.confirm(confirmText) === true) {
      const BodyToWS = {
        roomId: RoomId,
        type: '/work',
        workOrderLogId: workOrderLog.workOrderLogId,
        workOrderStatus: 'cancel',
      };
      console.log('client.publish/pub/work - body : ', JSON.stringify(BodyToWS));
      try {
        WS_CLIENT.publish({
          destination: '/pub/work',
          headers: { 'auth-token': getCookie('accessToken')?.replace('Bearer ', '') },
          body: JSON.stringify(BodyToWS),
        });
      } catch (error) {
        console.log('client.publish/pub/work - error : ', error);
      }
    } else {
      return;
    }
  };

  /* ====================================================================== #6 */
  const [onHeader, setOnHeader] = useState(false)
  const clickHeader = (e) => { setOnHeader(!onHeader) };

  return (
    <>
      <GridNonBody
        header={<>{onHeader ? (<Header />) : null}</>}
        contents={
          <>
            <Section className="Main" onHeader={onHeader}>
              <NavBar
                title={
                  <div className='Title'>
                    <NavTitle menuCode={'501'} />
                    <div className='statusButtons'>
                      {[
                        { status: 'all', name: '전체' },
                        { status: 'waitNwork', name: '대기/작업중' },
                        { status: 'wait', name: '대기' },
                        { status: 'work', name: '작업중' },
                        { status: 'pause', name: '일시정지' },
                        { status: 'stop', name: '중단' },
                        { status: 'end', name: '완료' },
                        { status: 'cancel', name: '취소' },
                      ].map((workOrderStatus, index) => {
                        return (
                          <button key={index + '_statusButton'}
                            className={workReducer.workOrderStatus === workOrderStatus.status ? 'btn-inven-1' : 'btn-set'}
                            onClick={() => { handleWorkOrderStatus(workOrderStatus.status); }}
                          >
                            {workOrderStatus.name}
                          </button>
                        )
                      })}
                    </div>
                  </div>
                }
                nav={'search'}
                firstRow={
                  <>
                    <div className="SearchSection" style={{ columnGap: '20px' }}>
                      <select
                        className="SelectType"
                        value={workReducer.workOrderType}
                        onChange={(e) => {
                          dispatch(workActions_setWorkOrderType(e.target.value))
                          dispatch(workActions_setPageNumber(1));
                          getWorkOrderLogList(0, e.target.value, workReducer.workOrderStatus);
                        }}
                      >
                        <option value="all">유형 전체</option>
                        {_workOrderTypes.map((thisItem) => {
                          return (
                            <option key={thisItem.workOrderTypeId + '_workOrderType'} value={thisItem.workOrderTypeId}>
                              {thisItem.workOrderTypeName}
                            </option>
                          );
                        })}
                      </select>
                      <div className="SearchSection">
                        <input
                          type="date"
                          className="SelectDate"
                          value={workReducer.startDate}
                          onChange={(e) => { dispatch(workActions_setStartDate(e.target.value)) }}
                        />
                        <IconRightArrow />
                        <input
                          type="date"
                          className="SelectDate"
                          value={workReducer.endDate}
                          onChange={(e) => { dispatch(workActions_setEndDate(e.target.value)) }}
                        />
                      </div>
                    </div>
                  </>
                }
                secondRow={
                  <div className="SearchSection">
                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="workOrderCode">작업코드</option>
                      </select>
                      <input
                        className="SearchBar detail"
                        placeholder="Search..."
                        value={checkEmptyNull(workReducer.searchData.workOrderCode, '')}
                        onInput={(e) => {
                          dispatch(workActions_setSearchData({ ...workReducer.searchData, workOrderCode: e.target.value }));
                        }}
                      />
                    </div>

                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="workOrderName">작업이름</option>
                      </select>
                      <input
                        className="SearchBar detail"
                        placeholder="Search..."
                        value={checkEmptyNull(workReducer.searchData.workOrderName, '')}
                        onInput={(e) => {
                          dispatch(workActions_setSearchData({ ...workReducer.searchData, workOrderName: e.target.value }));
                        }}
                      />
                    </div>

                    <div className="ContentCBox">
                      <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                      <div className="ResetButton" onClick={actReset}>초기화</div>
                    </div>
                  </div>
                }
              />
              <TableSection content={
                <table>
                  <thead style={{ zIndex: '800' }}>
                    <tr>
                      <th style={{ minWidth: '120px', padding: 'unset', width: '120px' }}>작업상태</th>
                      <th>공정<br />리스트</th>
                      {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? <th>파일</th> : null}
                      <th style={{ minWidth: '120px', width: '120px' }}>작업유형</th>
                      <th>작업코드</th>
                      <th>작업이름</th>
                      {(_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) ? <th>거래처</th> : null}
                      <th>생산<br />목표수량</th>
                      <th>작업위치</th>
                      <th>기간</th>
                      <th>작성자</th>
                      <th>책임자</th>
                      <th>작업자</th>
                      <th>비고</th>
                      <th style={{ minWidth: '60px', width: '60px' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!checkNullArray(_workOrderLogList, false) ? LoadingMsg() :
                      checkNullArray(_workOrderLogList, []).map((workOrderLog, index) => {
                        let processLogList = [];
                        if (checkNullArray(workOrderLog.processLogList, false)) {
                          const copyList = [...workOrderLog.processLogList];
                          copyList.sort((a, b) => { return a.processNumber - b.processNumber });
                          processLogList = copyList;
                        }

                        let colNum = 14;
                        if (_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) colNum = colNum + 1;
                        if (_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) colNum = colNum + 1;

                        return (
                          <Fragment key={index + '_workOrderRow'}>
                            <tr data-key={workOrderLog.workOrderLogId}>
                              <StatusTdButton
                                statusStyle={{ height: '120px', minWidth: '120px', width: '120px' }}
                                status={workOrderLog.workOrderStatus}
                                statusEvent={(_authority.indexOf('501-2') !== -1 || _authority.indexOf('501-3') !== -1 || _authority.indexOf('501-4') !== -1) ? () => { handleModalStatus(workOrderLog, 'handleWorkOrderStatus') } : null}
                              />
                              <td>
                                {checkNullArray(processLogList, false) ? (
                                  <div className='btn-doc'
                                    data-workorderlogid={workOrderLog.workOrderLogId}
                                    onClick={(e) => { handleModalStatus(workOrderLog, 'processLogList') }}
                                  >
                                    <img src={baseInformationIcon} alt={'공정리스트 버튼'} />
                                  </div>
                                ) : null}
                              </td>

                              {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? (
                                <>
                                  {(checkNullArray(workOrderLog.attachFileList, false)) ? (
                                    <td className='table-preview'>
                                      <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleModalStatus(workOrderLog, 'file');
                                        }}
                                      >
                                        <img src={workOrderLog.attachFileList[0]?.fileData?.fileDataS3URL || ''} alt='image preview' className='img-preview' />
                                        <div className='btn-file'><DOCButtonIcon /></div>
                                      </div>
                                    </td>
                                  ) : (
                                    <td className='table-preview'>
                                      <div
                                        style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleModalStatus(workOrderLog, 'file');
                                        }}
                                      >
                                        <div className='no-image'><p>이미지가 없습니다.</p></div>
                                        <div className='btn-file'><DOCButtonIcon /></div>
                                      </div>
                                    </td>
                                  )}
                                </>
                              ) : null}

                              <td style={{ minWidth: '120px', width: '120px' }}>{workOrderLog.workOrderTypeName}</td>
                              <td>{workOrderLog.workOrderCode}</td>
                              <td>{workOrderLog.workOrderName}</td>
                              {(_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) ? <td>{checkEmptyNull(workOrderLog.accountName, '')}</td> : null}
                              <td>{checkEmptyNull(workOrderLog.amount, 0).toLocaleString()}</td>
                              <td>{workOrderLog.place?.placeName}<br />{workOrderLog.workPlace}</td>
                              <td>{DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledStartDate, ''))}<br />~ {DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledEndDate, ''))}</td>
                              <td>{workOrderLog.userName}</td>
                              <td>{checkNullArray(workOrderLog.managers, []).map((worker, index) => { return (<p key={index + '_managers'}>{worker.managerName}</p>); })}</td>
                              <td>{checkNullArray(workOrderLog.workers, []).map((worker, index) => { return (<p key={index + '_workers'}>{worker.workerName}</p>); })}</td>
                              <td>{workOrderLog.remark}</td>
                              {(workOrderLog.workOrderStatus !== 'cancel' && workOrderLog.workOrderStatus !== 'end') ? (
                                <CancelWorkOrderLogButton
                                  style={{ minWidth: '60px', width: '60px' }}
                                  onClick={() => { actCancel(workOrderLog) }}
                                >
                                  취소
                                </CancelWorkOrderLogButton>
                              ) : (<td style={{ minWidth: '60px', width: '60px' }}></td>)}
                            </tr>

                            {checkNullArray(processLogList, false) ? (
                              <tr>
                                <td colSpan={colNum} style={{ height: '80px', padding: 'unset' }}>
                                  <ProcessSection>
                                    {checkNullArray(processLogList, []).map((processLog) => {
                                      return (<Process key={processLog.processLogId + '_processCell'} status={processLog.processStatus}>{'• ' + checkEmptyNull(processLog.processName, '')}</Process>);
                                    })}
                                  </ProcessSection>
                                </td>
                              </tr>
                            ) : null}
                          </Fragment>
                        );
                      })}
                  </tbody>
                </table>
              }
              ></TableSection>
            </Section>

            <div style={{ display: 'flex', height: '60px', justifyContent: 'space-between', alignItems: 'center', gap: '16px', margin: '0 24px' }}>
              <WorkerButton
                status={workReducer.setWorker}
                onClick={() => { dispatch(workActions_setWorker(!workReducer.setWorker)); }}
              >
                <div className='btn-toggle'></div>
              </WorkerButton>
              <PagingComponent
                page={workReducer.pageNumber}
                count={workReducer.totalSize}
                size={5}
                pageEvent={(page) => {
                  console.log('page : ', page);
                  dispatch(workActions_setPageNumber(page));
                }}
              />
              <ConnectStatus
                connectStatus={workReducer.connectStatus}
                onClick={() => { navigate(0); }}
              ></ConnectStatus>
            </div>

            {/* 모달 */}
            {/* 공정리스트 모달 */}
            {(workReducer.connectStatus === 'connect' && _modalStatus) ? (
              <WorkerScreenModal
                workOrderLogId={_eventWorkOrderLog.workOrderLogId}
                processList={_eventWorkOrderLog}
                open={_modalStatus}
                close={() => {
                  setModalStatus(false);
                  getWorkOrderLogList(workReducer.pageNumber - 1, workReducer.workOrderType, workReducer.workOrderStatus);
                }}
              />
            ) : null}

            {/* 작업상태 변경 모달 */}
            {(workReducer.connectStatus === 'connect' && _workOrderStatusModal) ? (
              <WorkerStatusModal
                content={_eventWorkOrderLog}
                statusEvent={(workOrderLog, workOrderStatus) => { handleWorkOrderLogStatus(workOrderLog, workOrderStatus) }}
                open={_workOrderStatusModal}
                close={() => { setWorkOrderStatusModal(false) }}
              />
            ) : null}

            {/* 작업지시 완료 모달 */}
            {/* 책임자/작업자 변경X -> 바로 완료 */}
            {/* 책임자/작업자 변경 선택 모달 */}
            {workReducer.setWorker && _confirmModalStatus === true ? (
              <WorkerConfirmModal
                workerEvent={() => {
                  actWorkEnd('workerEvent');
                  setTimeout(setConfirmModalStatus(false), 1000);
                }}
                endEvent={() => {
                  actWorkEnd('endEvent');
                  setTimeout(setConfirmModalStatus(false), 1000);
                }}
                open={_confirmModalStatus}
                close={() => { setConfirmModalStatus(false) }}
              />
            ) : null}

            {/* 책임자/작업자 선택 모달 */}
            {_workerModalStatus === true ? (
              <SelectWorkerModal
                type={'workOrderLog'}
                eventLog={_endBody}
                open={_workerModalStatus}
                close={() => {
                  setWorkerModalStatus(false);
                  setWorkOrderStatusModal(false);
                }}
              />
            ) : null}

            {/* 파일 모달 */}
            {(_fileListModalStatus === true && checkEmptyNull(_eventWorkOrderLog.workOrderLogId, false)) ? (
              <ShowFileList
                type={'workOrderLog'}
                content={_eventWorkOrderLog}
                open={_fileListModalStatus}
                close={() => { setFileListModalStatus(false) }}
              ></ShowFileList>
            ) : null}
          </>
        }
      />
      <DashboardButton onClick={clickHeader} data-name='on'>메뉴 {onHeader ? '닫기' : '열기'}</DashboardButton>
    </>
  );
};

export default WorkerScreen;
