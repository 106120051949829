import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { qna } from 'api/apis/mall/qna';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateTimeFormatKR } from 'components/format/DateTimeFormatKR';
import { MallStatusName } from 'components/status/MallStatusName';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import MallSiteQnAReply from 'mall/components/reply/MallSiteQnAReply';

const DeleteButton = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 20px;
  justify-content: center;
  transform: scale(1.5);
  width: 20px;
  div {background-color: var(--MainRed); cursor: pointer; height: 15px; width: 15px;}
`;
const FileBox = styled.div`
  align-items: center;
  background-color: var(--Bg);
  box-sizing: border-box;
  color: var(--gray-800);
  cursor: pointer;
  display: flex;
  height: 200px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  & img {
    height: auto;
    max-height: 100px;
    max-width: 100px;
    width: auto;
  }
  &:hover {opacity: 0.8;}
`;

const QnaInfo = (props) => {
  /* ====================================================================== #1 */
  const location = useLocation();
  const { userReducer } = useSelector(state => state);

  /* ====================================================================== #2 */
  const companyCode = location.pathname.split('/')[1];

  const { mallQnA } = props;
  const { mallQnAId, mallQnACode, status, userName, mallQnAContent, mallQnAElementList, attachFileList } = mallQnA;

  const [_formData, setFormData] = useState({
    companyId: userReducer.user.companyId,
    companyCode: companyCode,
    mallQnAId: mallQnAId,
    mallQnACode: mallQnACode,
    status: checkEmptyNull(status, 'waiting'),
    userId: userReducer.user.id,
    userName: checkEmptyNull(userName, ''),
    mallQnAContent: checkEmptyNull(mallQnAContent, ''),
    mallQnAElementList: mallQnAElementList,
    deleteMallQnAElementList: [],
    attachFileList: attachFileList,
    deleteFileDataList: [],
  });

  const [_originOrderElementList, setOriginOrderElementList] = useState(checkNullArray(mallQnAElementList, []));
  const [_mallQnAElementList, setMallQnAElementList] = useState(checkNullArray(mallQnAElementList, []));

  const [_prevFileDataList, setPrevFileDataList] = useState(checkNullArray(attachFileList, []));
  const [_fileDataList, setFileDataList] = useState(checkNullArray(attachFileList, []));

  const [_replyListModalStatus, setReplyListModalStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    setFormData(() => {
      return {
        companyId: userReducer.user.companyId,
        companyCode: companyCode,
        mallQnAId: mallQnAId,
        mallQnACode: mallQnACode,
        status: checkEmptyNull(status, 'waiting'),
        userId: userReducer.user.id,
        userName: checkEmptyNull(userName, ''),
        mallQnAContent: checkEmptyNull(mallQnAContent, ''),
        mallQnAElementList: mallQnAElementList,
        deleteMallQnAElementList: [],
        attachFileList: attachFileList,
        deleteFileDataList: [],
      };
    });
    setOriginOrderElementList(() => { return checkNullArray(mallQnAElementList, []); });
    setMallQnAElementList(() => { return checkNullArray(mallQnAElementList, []); });
    setPrevFileDataList(() => { return checkNullArray(attachFileList, []); });
    setFileDataList(() => { return checkNullArray(attachFileList, []); });

    return () => { };
  }, [props]);

  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value }; });
  };

  const actDeleteOrderElement = (e, index, element) => {
    setMallQnAElementList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);
      return prevData;
    });
  };

  const handleFileEvent = (type, e) => {
    let files = [];
    switch (type) {
      case 'click': files = e.target.files; break;
      case 'drag': files = e.dataTransfer.files; break;
      default: return;
    }

    if (files === undefined || files === null || files.length === 0) return;
    const setFiles = Array.prototype.slice.call(files);
    setFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onload = () => {
          const returnData = file;
          returnData['preview'] = reader.result;
          setFileDataList((prev) => { return [...prev, returnData]; });
          resolve();
        };
      });
    });
  };
  const actDeleteFileData = (file, index) => {
    setFileDataList((prev) => {
      const returnData = [...prev];
      returnData.splice(index, 1);
      return returnData;
    });
  };

  /* 수정 */
  const actUpdate = async (e) => {
    e.preventDefault();

    const findElement = (mallQnAElementId) => {
      const index = _mallQnAElementList.findIndex((mallQnAElement) => mallQnAElement.mallQnAElementId === mallQnAElementId);
      return index;
    };
    const deleteMallQnAElementList = _originOrderElementList.filter((element) => findElement(element.mallQnAElementId) === -1);
    const setDeleteMallQnAElementList = deleteMallQnAElementList.filter((element) => element !== undefined && element !== null);

    const findFileData = (attachFileId) => {
      const index = _fileDataList.findIndex((fileData) => fileData.attachFileId === attachFileId);
      return index;
    };
    const deleteFileDataList = _prevFileDataList.filter((fileData) => findFileData(fileData.attachFileId) === -1);
    const setDeleteFileDataList = deleteFileDataList.filter((fileData) => fileData !== undefined && fileData !== null);

    const BodyToPut = {
      ..._formData,
      mallQnAElementList: _mallQnAElementList,
      deleteMallQnAElementList: setDeleteMallQnAElementList.map((deleteElement) => deleteElement.mallQnAElementId),
      deleteFileDataList: setDeleteFileDataList.map((deleteFileData) => deleteFileData.attachFileId),
    };
    console.log('BodyToPut : ', BodyToPut);
    const putFormData = new FormData();
    putFormData.append('key', new Blob([JSON.stringify(BodyToPut)], { type: 'application/json' }));
    if (_fileDataList.length > 0) {
      _fileDataList.forEach((file) => {
        console.log(file.attachFileId);
        if (file.attachFileId === undefined) putFormData.append('multipartFileList', file);
      });
    }
    for (let values of putFormData.values()) {
      console.log('putFormData : ', values);
    }
    await qna.updateMallQnA(mallQnAId, putFormData).then((response) => {
      if (response === undefined) return;
      console.log('qna.updateMallQnA : ', response);
      alert('문의정보를 수정했습니다.');
      props.update();
    });
  };

  /* 취소 요청 */
  const actRequestCancel = async () => {
    const confirmText = `
문의을 취소하시겠습니까?
관리자 승인 후 최종 취소 처리됩니다.
    `;
    if (window.confirm(confirmText) === true) {
      const BodyToPut = {
        ...mallQnA,
        deleteMallQnAElementList: [],
        deleteFileDataList: [],
        userId: userReducer.user.id,
        status: 'cancel',
      };
      console.log('BodyToPut : ', BodyToPut);
      const putFormData = new FormData();
      putFormData.append('key', new Blob([JSON.stringify(BodyToPut)], { type: 'application/json' }));
      for (let values of putFormData.values()) {
        console.log('putFormData : ', values);
      }
      await qna.updateMallQnA(mallQnA.mallQnAId, putFormData).then((response) => {
        if (response === undefined) return;
        console.log('qna.updateMallQnA : ', response);
        alert('문의 취소 요청을 했습니다.');
        props.update();
      });
    } else return;
  };

  /* ====================================================================== #6 */

  return (
    <>
      <div className='qna-sub'>
        <div className='qna-sub-title'>
          <p>문의 번호 : {mallQnA.mallQnAId}</p>
          <p>작성일자 : {DateTimeFormatKR(mallQnA.createdDate)}</p>
          {mallQnA.modifiedDate !== mallQnA.createdDate ? <p>수정일자 : {DateTimeFormatKR(mallQnA.modifiedDate)}</p> : null}
        </div>
        <h2 className='qna-sub-title'>문의 내용</h2>
        <div className='qna-sub-info'>
          <div className='qna-sub-info-content'>
            <p>문의 코드<span>{_formData.mallQnACode}</span></p>
            <p>문의 상태<span>{MallStatusName(_formData.status || 'waiting')}</span></p>
            <p>문의내용
              <textarea
                type="text"
                name="mallQnAContent"
                placeholder="문의내용..."
                value={_formData.mallQnAContent}
                onInput={handleInputEvent}
              />
            </p>
            <p>문의상품
              <p className='qna-sub-list qna-sub-list-product'>
                {checkNullArray(_mallQnAElementList, []).map((element, index) => {
                  return (
                    <li key={index + '_orderElements'}>
                      <p>
                        <>&nbsp;</>
                        <DeleteButton onClick={(e) => { actDeleteOrderElement(e, index, element); }} ><CloseButtonIcon /></DeleteButton>
                      </p>
                      <p>상품코드: {element.mallElementCode}</p>
                      <p>상품명: {element.mallElementName}</p>
                      <p>상품단가: {`${element.mallElementPrice}`}</p>
                    </li>
                  );
                })}
              </p>
            </p>
            <p>
              첨부파일
              <input type="file" id="FileInput" multiple style={{ display: 'none' }} onChange={(e) => { handleFileEvent('click', e); }} />
              <label
                htmlFor="FileInput"
                className='list-file'
                onDragEnter={(e) => { e.stopPropagation(); e.preventDefault(); }}
                onDragLeave={(e) => { e.stopPropagation(); e.preventDefault(); }}
                onDragOver={(e) => { e.stopPropagation(); e.preventDefault(); }}
                onDrop={(e) => { e.preventDefault(); handleFileEvent('drag', e); }}
              >
                <p>첨부 파일 드래그 또는 <span>선택</span></p>
              </label>
            </p>
          </div>
          {_fileDataList.map((fileData, index) => {
            return (
              <FileBox key={index + '_fileDatas'}
                title='파일을 클릭하면 삭제됩니다.'
                onClick={() => { actDeleteFileData(fileData, index); }}
              >
                {(() => {
                  const returnArray = [];
                  if (fileData.attachFileId === undefined) {
                    if (fileData.type && fileData.type.startsWith('image')) {
                      returnArray.push(<img key={index + '_fileDataImg'} src={fileData.preview} alt={fileData.name} />);
                    } else {
                      returnArray.push(<>{fileData.name}</>);
                    }
                  } else {
                    if (fileData.fileData) {
                      if (fileData.fileData.fileDataType && fileData.fileData.fileDataType.startsWith('image')) {
                        returnArray.push(<img key={index + '_fileDataImg'} src={fileData.fileData.fileDataS3URL} alt={fileData.fileData.fileDataName} />);
                      } else {
                        returnArray.push(<span key={index + '_fileDataImg'}>{fileData.fileData.fileDataName}</span>);
                      }
                    } else return;
                  }
                  return returnArray;
                })()}
              </FileBox>
            );
          })}
        </div>
        <div className='qna-sub-btn'>
          <button className="qna-sub-submit qnaBtn" onClick={() => { setReplyListModalStatus(true); }}>1:1 추가 문의</button>
          <button className="qna-sub-submit qnaBtn" onClick={actRequestCancel}>문의 취소 요청</button>
          <button className="qna-sub-submit" onClick={actUpdate}>변경된 내용 저장</button>
        </div>
      </div>

      {_replyListModalStatus === true && mallQnAId !== undefined ? (
        <MallSiteQnAReply
          mallQnA={_formData}
          open={_replyListModalStatus}
          close={() => { setReplyListModalStatus(false); }}
        ></MallSiteQnAReply>
      ) : null}
    </>
  );
};

export default QnaInfo;
