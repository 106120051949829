import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { categoryApi } from 'api/apis/categoryApi';

import { CategoryComponent, ElementCategoryComponent } from './ElementCategory.style';
import { checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import IconRightArrow from 'components/icons/IconRightArrow';

const ElementCategory = ({
  handleSearch, 
  resetStatus, setResetStatus
}) => {
  const { userReducer } = useSelector(state => state);

  const levels = [1, 2, 3, 4, 5];
  const initialCategory = {
    level1: [], level2: [], level3: [], level4: [], level5: [], // 카테고리 리스트
    clicked1: {}, clicked2: {}, clicked3: {}, clicked4: {}, clicked5: {}, // 선택한 카테고리
  };
  const [_category, setCategory] = useState(initialCategory);

  useEffect(() => {
    getCategories(1); // 맨 처음엔 level1 카테고리 불러오기
  }, [])
  useEffect(() => { // 카테고리 초기화
    if(resetStatus) {
      setResetStatus(() => {return false});
      setCategory(() => {return initialCategory});
      getCategories(1, initialCategory); // 맨 처음엔 level1 카테고리 불러오기
    }
  }, [resetStatus]);
  useEffect(() => {}, [_category]);

  const getCategories = async(level, category) => { // 카테고리 리스트
    const body = {
      companyId: userReducer.company.companyId,
      categoryLevel: level,
    };
    if(checkNullObject(category)) {
      if(level !== 1) { // 상위 카테고리 아이디
        const parentLevel = level - 1;
        const parentKey = 'clicked' + parentLevel;
        const parentCategory = category[parentKey];
        if(checkNullObject(parentCategory, false)) {
          const parentCategoryId = parentCategory?.categoryId;
          body.parentCategoryId =parentCategoryId;
        }
      }
    }
    await categoryApi.searchCategory(body).then(response => {
      if(response === undefined) return;
      // console.log('getCategories : ', response);
      const categories = response.data;

      const resetLevels = [1, 2, 3, 4, 5]; // 카테고리 리스트 초기화
      resetLevels.splice(0, level);
      const returnData = {...category};
      checkNullArray(resetLevels, []).forEach((resetLevel) => {
        const resetLevelKey = 'level' + resetLevel;
        returnData[resetLevelKey] = [];
      })

      const currentLevelKey = 'level' + level; // 현재 Level 카테고리 리스트
      returnData[currentLevelKey] = categories;

      setCategory(() => {return returnData});
    })
  }

  const handleCategory = (e) => {
    const currentLevel = Number(e.target.dataset.level);
    const requestLevel = currentLevel + 1;
    const currentLevelKey = 'level' + currentLevel;
    const currentClickedKey = 'clicked' + currentLevel;

    const returnCategoryData = {..._category};

    const clickedCategoryId = e.target.value;
    if(clickedCategoryId === 'reset') {
      const resetLevel = currentLevel - 1;
  
      const resetClickedLevels = [1, 2, 3, 4, 5]; // 카테고리 초기화
      resetClickedLevels.splice(0, resetLevel);
      checkNullArray(resetClickedLevels, []).forEach((level) => {
        const resetClickedKey = 'clicked' + level;
        returnCategoryData[resetClickedKey] = 'reset';
      })
      handleSearch('categoryId', undefined);
      setCategory(() => {return returnCategoryData});
      getCategories(currentLevel, returnCategoryData);
    } else {
      const clickedCategory = checkNullArray(_category[currentLevelKey], []).find(item => item.categoryId === Number(clickedCategoryId));
      returnCategoryData[currentClickedKey] = clickedCategory;
  
      const resetLevels = [1, 2, 3, 4, 5]; // 하위 카테고리 초기화
      resetLevels.splice(0, currentLevel);
      checkNullArray(resetLevels, []).forEach((level) => {
        const resetClickedKey = 'clicked' + level;
        returnCategoryData[resetClickedKey] = 'reset';
      })
      handleSearch('categoryId', Number(clickedCategoryId));
      setCategory(() => {return returnCategoryData});
      getCategories(requestLevel, returnCategoryData);
    }


  }

  return (
    <ElementCategoryComponent>
      {levels.map((level => { // 패밀리 셀렉트
        const currentLevelKey = 'level' + level;
        const currentClickedKey = 'clicked' + level;

        return (
          <Fragment key={level + '_category'}>
            {(level%2) === 0 ? (<IconRightArrow />) : null}
            <CategoryComponent data-level={level} value={_category[currentClickedKey]?.categoryId || 'reset'} onChange={handleCategory}>
              <option value={'reset'}>{`패밀리 ${level}`}</option>
              {checkNullArray(_category[currentLevelKey], []).map((category, index) => { // 패밀리 옵션
                return (<option key={level + '_category_opt_' + index} value={category.categoryId}>{category.categoryName}</option>)
              })}
            </CategoryComponent>
            {(level%2) === 0 ? (<IconRightArrow />) : null}
          </Fragment>
        )
      }))}
    </ElementCategoryComponent>
  );
};

export default ElementCategory;