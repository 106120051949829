import styled from "styled-components";

export const OperatingHeaderComponent = styled.header`
  border-block-end: 1px solid #b0b0b0;
  height: 100px;
  width: 100%;
  
  &>div {
    align-items: flex-end;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin-inline: auto;
    max-width: 1240px;
    padding-block-end: 20px;
    width: 95%;

    & button {
      align-items: center;
      display: flex;
      gap: 5px;
      height: fit-content;
      justify-content: center;
      width: fit-content;

      &:hover {
        background: unset;
      }
    }

    &>div {
      align-items: center;
      display: flex;
      gap: 30px;
      justify-content: center;
    }
  }
`;