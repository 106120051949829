const initialState = {
    startDate: '',
    endDate: '',
    searchData: {},
    pageNumber: 0,
    isPushedSearchButton: false,
}
const resetState = {
    startDate: '',
    endDate: '',
    searchData: {},
    pageNumber: 0,
    isPushedSearchButton: false,
};

const dlMovementReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SETDLMOVEMENTSTARTDATE':
            return {
                ...state,
                startDate: action.payload,
            };
        case 'SETDLMOVEMENTENDDATE':
            return {
                ...state,
                endDate: action.payload,
            };
        case 'SETDLMOVEMENTSEARCHDATA':
            return {
                ...state,
                searchData: action.payload,
            };
        case 'SETDLMOVEMENTPAGENUMBER':
            return {
                ...state,
                pageNumber: action.payload,
            };
        case 'SETDLMOVEMENTISPUSHEDSEARCHBUTTON':
            return {
                ...state,
                isPushedSearchButton: action.payload,
            };
        case 'SETDLMOVEMENTRESET':
            return {
                ...state,
                ...resetState,
            };
        default:
            return state;
    }
}

export default dlMovementReducer;