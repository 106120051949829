import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

import { contractTypeApi } from 'api/apis/contractTypeApi';
import { contractAttributeApi } from 'api/apis/contractAttributeApi';
import { contractApi } from 'api/apis/contractApi';

import { DateFormat } from 'components/format/DateFormat';
import { StatusName } from 'components/status/StatusName';

import { rightArrow } from 'components/icons/src';
import { checkNull } from 'components/checkValues/checkValues';

const ContractModal = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 6px;
  }
`;

const MainContents = styled.div`
  box-sizing: border-box;
  /* height: 100%; */
  margin-bottom: 20px;
  padding: 5px 20px;
  width: 100%;
`;

const StatusSelect = styled.select`
  border: 1px solid var(--ThirdGreen);
  border-radius: 5px;
  box-sizing: border-box;
  height: fit-content;
  margin-top: 5px;
  padding: 10px;
  width: 100%;
`;

const DateContents = styled.div`
  column-gap: 10px;
  display: grid;
  grid-template-columns: 1fr 15px 1fr;
  width: 100%;
`;

const SelectDate = styled.input`
  border: 1px solid var(--gray-200);
  border-radius: 5px;
  box-sizing: border-box;
  height: fit-content;
  margin-top: 5px;
  min-width: 250px;
  padding: 10px;
  width: 100%;

  border: 1px solid var(--ThirdGreen);
  &:focus {
    border: 1px solid var(--ThirdGreen);
  }
`;

const RightArrow = styled.div`
  background-color: var(--ThirdBlue);
  height: 15px;
  margin-top: 17px;
  width: 15px;

  mask-image: url(${rightArrow});
  mask-repeat: no-repeat;
  mask-size: contain;

  --webkit-mask-image: url(${rightArrow});
  --webkit-mask-repeat: no-repeat;
  --webkit-mask-size: contain;
`;

const SearchContents = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  width: 100%;
`;

const SearchOption = styled.select`
  border: 1px solid var(--ThirdGreen);
  border-radius: 5px;
  box-sizing: border-box;
  height: fit-content;
  padding: 10px;
  width: 150px;
`;

const SearchText = styled.input`
  border: 1px solid var(--ThirdGreen);
  border-radius: 5px;
  box-sizing: border-box;
  height: fit-content;
  /* outline: var(--ThirdGreen); */
  padding: 10px;
  width: calc(100% - 160px);

  &:focus {
    border: 1px solid var(--ThirdGreen);
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ModalButton = styled.div`
  align-items: center;
  background-color: var(--ThirdGreen);
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 400;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const LoadingScreen = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  color: var(--white);
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 999;
`;

const MarketContractDownloadModal = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_contractTypes, setContractTypes] = useState([]);
  const [_contractType, setContractType] = useState('all');

  const [_contractStatus, setContractStatus] = useState('all');

  const [_contractAttrList, setContractAttrList] = useState([]);

  const [_startDate, setStartDate] = useState();
  const [_endDate, setEndDate] = useState();

  const [_searchOption, setSearchOption] = useState('');
  const [_searchText, setSearchText] = useState('');

  const [_loadingStatus, setLoadingStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    (async () => {
      await getContractTypes();
      await getContractAttrs(_contractType);
    })();

    return () => { };
  }, []);

  /* ====================================================================== #4 */
  /* 수주 유형 */
  const getContractTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    await contractTypeApi.searchContractType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('contractTypeApi.searchContractType : ', response);

      setContractTypes(() => {
        return response.data;
      });
    });
  };

  /* 수주 항목 */
  const getContractAttrs = async (contractType) => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (
      contractType !== undefined &&
      contractType !== null &&
      contractType !== '' &&
      contractType !== 'all'
    ) {
      BodyToPost.contractTypeId = Number(contractType);
    }
    console.log('getContractAttrs - BodyToPost : ', BodyToPost);

    await contractAttributeApi
      .searchContractAttribute(BodyToPost)
      .then((response) => {
        if (response === undefined) return;
        console.log(
          'contractAttributeApi.searchContractAttribute : ',
          response,
        );

        setContractAttrList(() => {
          return response.data;
        });
      });
  };

  /* ====================================================================== #5 */
  // 수주 엑셀 다운로드
  const actDownload = async () => {
    setLoadingStatus(true);

    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    if (
      _contractType !== undefined &&
      _contractType !== null &&
      _contractType !== 'all'
    ) {
      BodyToPost.contractTypeId = Number(_contractType);
    }

    if (
      _contractStatus !== undefined &&
      _contractStatus !== null &&
      _contractStatus !== '' &&
      _contractStatus !== 'all'
    ) {
      BodyToPost.contractStatus = _contractStatus;
    }

    if (
      _startDate !== undefined &&
      _endDate !== undefined &&
      _startDate !== '' &&
      _endDate !== ''
    ) {
      const startDate = new Date(_startDate);
      const setStartDate = startDate.toISOString();
      BodyToPost.contractStartDate = setStartDate;

      const endDate = new Date(_endDate);
      endDate.setDate(endDate.getDate() + 1);
      const setEndDate = endDate.toISOString();
      BodyToPost.contractEndDate = setEndDate;

      // 시작 날짜가 종료날짜보다 클 때
      if (_startDate > _endDate) {
        const reverseStartDate = new Date(_startDate);
        reverseStartDate.setDate(reverseStartDate.getDate() + 1);
        const setReverseStartDate = reverseStartDate.toISOString();

        const reverseEndDate = new Date(_endDate);
        const setReversetEndDate = reverseEndDate.toISOString();

        BodyToPost.contractStartDate = setReversetEndDate;
        BodyToPost.contractEndDate = setReverseStartDate;
      }
    }

    if (
      _searchOption !== undefined &&
      _searchOption !== null &&
      _searchOption !== ''
    ) {
      BodyToPost[_searchOption] = _searchText;
    }

    await getContractList(BodyToPost);
  };
  const getContractList = async (BodyToPost) => {
    const paging = `?page=0&size=7000&sort=id,DESC`;
    console.log('paging : ', paging);
    console.log('BodyToPost : ', BodyToPost);
    await contractApi.searchContract(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('contractApi.searchContract : ', response);
      setExcelData_contract(response.data);
    });
  };
  const setExcelData_contract = async (Result) => {
    const contractList = Result.content;
    let excelList = [];
    for (let i = 0; i < contractList.length; i++) {
      const contract = contractList[i];
      for (let j = 0; j < contract.contractElementList.length; j++) {
        const contractElement = contract.contractElementList[j];
        const contractData = {};
        contractData['수주상태'] = StatusName(contract.contractStatus);
        contractData['수주일자'] = checkNull(contract.contractDate, false) ? DateFormat(contract.contractDate) : '';
        contractData['납품요구접수일자'] = checkNull(contract.contractRegDate, false) ? DateFormat(contract.contractRegDate) : '';
        contractData['납품요구번호/계약번호'] = contract.contractCode;
        contractData['수주이름'] = contract.contractName;
        contractData['수요기관명'] = contract.accountName;
        contractData['수주주소'] = contract.address;
        contractData['수주총금액'] = contract.contractPrice;
        contractData['납품기한일자'] = checkNull(contract.scheduledEndDate, false) ? DateFormat(contract.scheduledEndDate) : '';
        contractData['납품일자'] = checkNull(contract.contractActualEndDate, false) ? DateFormat(contract.contractActualEndDate) : '';
        contractData['수주유형'] = contract.contractTypeName;
        contractData['특이사항'] = contract.remark;
        contractData['비고'] = contract.remark;
        contract.contractAttributeValueList.forEach((contractAttr) => {
          contractData[contractAttr.contractAttributeName] = contractAttr.value;
        });
        contractData['물품이름'] = contractElement.elementName;
        contractData['물품코드'] = contractElement.elementCode;
        contractData['단가'] = contractElement.price;
        contractData['수량'] = contractElement.quantity;
        contractData['금액'] = BigNumber(contractElement.price)
          .multipliedBy(BigNumber(contractElement.quantity))
          .toNumber();

        excelList.push(contractData);
      }
    }

    const ws = XLSX.utils.json_to_sheet(excelList);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '수주 리스트');
    XLSX.writeFile(wb, '수주 리스트.xlsx');

    setLoadingStatus(false);
    props.close();
  };

  /* ====================================================================== #6 */

  return (
    <>
      <ContractModal>
        <MainContents>
          <div>수주유형</div>
          <StatusSelect
            value={_contractType}
            onChange={(e) => {
              setContractType(() => {
                return e.target.value;
              });
              getContractAttrs(e.target.value);
            }}
          >
            <option value="all">전체</option>
            {_contractTypes.map((contractType) => {
              return (
                <option
                  key={contractType.contractTypeId + '_contractType'}
                  value={contractType.contractTypeId}
                >
                  {contractType.contractTypeName}
                </option>
              );
            })}
          </StatusSelect>
        </MainContents>

        <MainContents>
          <div>수주상태</div>
          <StatusSelect
            value={_contractStatus}
            onChange={(e) => {
              setContractStatus(() => {
                return e.target.value;
              });
            }}
          >
            {[
              { optionValue: 'all', optionText: '전체' },
              { optionValue: 'waiting', optionText: '대기' },
              { optionValue: 'proceeding', optionText: '진행' },
              { optionValue: 'end', optionText: '완료' },
            ].map((thisItem) => {
              return (
                <option
                  key={thisItem.optionValue + '_contractStatus'}
                  value={thisItem.optionValue}
                >
                  {thisItem.optionText}
                </option>
              );
            })}
          </StatusSelect>
        </MainContents>

        <MainContents>
          <div>날짜</div>
          <DateContents>
            <SelectDate
              type="date"
              value={_startDate}
              onChange={(e) => {
                setStartDate(() => {
                  return e.target.value;
                });
              }}
            />
            <RightArrow />
            <SelectDate
              type="date"
              value={_endDate}
              onChange={(e) => {
                setEndDate(() => {
                  return e.target.value;
                });
              }}
            />
          </DateContents>
        </MainContents>

        <MainContents>
          <div>검색</div>
          <SearchContents>
            <SearchOption
              value={_searchOption}
              onChange={(e) => {
                setSearchOption(() => {
                  return e.target.value;
                });
              }}
            >
              {[
                { optionValue: '', optionText: '검색옵션' },
                {
                  optionValue: 'contractCode',
                  optionText: '수주코드',
                },
                {
                  optionValue: 'contractName',
                  optionText: '수주이름',
                },
                {
                  optionValue: 'accountName',
                  optionText: '거래처명',
                },
              ].map((thisItem) => {
                return (
                  <option
                    key={thisItem.optionValue + '_contractSearchOption'}
                    value={thisItem.optionValue}
                  >
                    {thisItem.optionText}
                  </option>
                );
              })}
            </SearchOption>

            <SearchText
              type="text"
              placeholder="Search..."
              value={_searchText}
              onInput={(e) => {
                setSearchText(() => {
                  return e.target.value;
                });
              }}
            />
          </SearchContents>
        </MainContents>
      </ContractModal>

      <ModalFooter>
        <ModalButton onClick={actDownload}>다운로드</ModalButton>
      </ModalFooter>

      {_loadingStatus === true ? (
        <LoadingScreen>수주를 다운로드하고 있습니다...</LoadingScreen>
      ) : null}
    </>
  );
};

export default MarketContractDownloadModal;
