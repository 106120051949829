import React, { Fragment, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { qualityApi } from 'api/apis/qualityApi';

import { checkNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat';
import { StatusName } from 'components/status/StatusName';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import CommaNum from 'components/format/CommaNum';
import GuideText from 'components/buttons/GuideText';
import ProcessResultModal from './ProcessResultModal';
import ShowInputElementStockLog from 'pages/stockHistory/inputElementStockLog/ShowInputElementStockLog';
import ShowInputElementStockResultLog from 'pages/stockHistory/inputElementStockLog/ShowInputElementStockResultLog';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 55px calc(100% - 55px);
  height: 85%;
  overflow: hidden;
  width: 95%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: flex-start;
  column-gap: 10px;
  display: flex;
  /* grid-template-columns: 200px calc(100% - 210px); */
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}

  & .TableSection {
    width: 100%;

    &::-webkit-scrollbar {display: none;}
  }
`;
const ProcessNav = styled.nav`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: inherit;
  justify-content: flex-start;
  position: relative;
  overflow-y: auto;
  row-gap: 5px;
  width: 200px;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}
`;
const ProcessButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 1.4em;
  height: max-content;
  justify-content: center;
  min-height: 100px;
  padding: 10px 0px;
  width: 100%;

  &.process {
    background-color: var(--ThirdBlue);
    box-sizing: border-box;
    font-size: 1em;
    height: max-content;
    justify-content: center;
    min-height: unset;
    padding-left: 20px;
  }
`;

const ViewSection = styled.div`
  display: grid;
  grid-template-rows: ${(props) => {
    return props.viewStatus === 'summary'
      ? '150px min-content'
      : '150px min-content min-content';
    // : '150px repeat(2, calc((100% - 150px) / 2)) ';
  }};
  height: inherit;
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}
`;
const ViewTitle = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  .TableSection {
    height: 100%;
    
    table {height: 100%; table-layout: fixed; width: 100%;}
    td {font-size: 2em; font-weight: 600; max-width: unset; min-width: unset;}
    th {max-width: unset; min-width: unset;}
  }
`;
const SummaryProductionProcess = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100%;
  width: 100%;

  .TableSection {
    td, th {min-width: unset;}
    td.amountCell, th.amountCell {min-width: unset;}
  }
`;
const ElementStockLogButton = styled.td`
  background-color: var(--MainYellow);
  color: var(--white);
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
`;

const ProductionProcessTracking = (props) => {
  /* ========================================================================= #1 */
  /* ========================================================================= #2 */
  const { open, close, productionId } = props;

  const [_onload, setOnload] = useState('unload');

  // 작업지시
  const [_productionData, setProductionData] = useState({});
  const [_productionElementList, setProductionElementList] = useState({});

  // 공정 리스트
  const [_processLogList, setProcessLogList] = useState([]);

  // 선택한 공정 데이터
  const [_processLogData, setProcessLogData] = useState({});
  const [_processLogElementList, setProcessLogElementList] = useState([]);

  const [_viewStatus, setViewStatus] = useState('summary');

  // 공정 결과 팝업
  const [_processResultModalStatus, setProcessResultModalStatus] = useState(false);
  const [_eventProcessResult, setEventProcessResult] = useState({});

  const [_inputElementStockLogModalStatus, setInputElementStockLogModalStatus] = useState(false);
  const [_inputElementStockLogs, setInputElementStockLogs] = useState([]);

  /* ========================================================================= #3 */
  useEffect(() => {
    getProductionProcessData();

    setOnload('loaded');

    return () => { };
  }, []);

  useEffect(() => {
    return () => { };
  }, [_viewStatus]);

  /* ========================================================================= #4 */
  const getProductionProcessData = async () => {
    await qualityApi.getQualityTracking(productionId).then((response) => {
      if (response === undefined) return;
      console.log('qualityApi.getQualityTracking : ', response);

      setProductionData(response.data);
      setProcessLogList(response.data.processLogs);

      const totalElements = {};
      response.data.processLogs.forEach((thisItem) => {
        thisItem.elements.forEach((thisElement) => {
          if (totalElements[thisElement.id]) {
            const prevData = totalElements[thisElement.id];
            const returnData = { ...prevData };
            if (thisElement.type === 'Put') {
              returnData.baseAmount = new BigNumber(checkNull(prevData.baseAmount, 0)).plus(new BigNumber(checkNull(thisElement.baseAmount, 0))).toNumber();
              returnData.reInputAmount = new BigNumber(prevData.reInputAmount).plus(new BigNumber(thisElement.reInputAmount)).toNumber();
              returnData.reUsedAmount = new BigNumber(prevData.reUsedAmount).plus(new BigNumber(thisElement.reUsedAmount)).toNumber();
            } else {
              returnData.baseAmount = new BigNumber(checkNull(prevData.baseAmount, 0)).plus(new BigNumber(checkNull(thisElement.baseAmount, 0))).toNumber();
              returnData.makeAmount = new BigNumber(prevData.makeAmount).plus(new BigNumber(thisElement.makeAmount)).toNumber();
              returnData.failedAmount = new BigNumber(prevData.failedAmount).plus(new BigNumber(thisElement.failedAmount)).toNumber();
            }

            totalElements[thisElement.id] = returnData;
          } else {
            totalElements[thisElement.id] = thisElement;
          }
        });
      });
      setProductionElementList(totalElements);
    });
  };

  /* ========================================================================= #5 */
  const showProcessResult = (result) => {
    setEventProcessResult(() => { return result; });
    setTimeout(setProcessResultModalStatus(true), 1000);
  };
  const showInputElementStockLog = (processLogElement, processLog) => {
    setInputElementStockLogs(() => {
      return {
        processLog: processLog,
        processLogElement: processLogElement,
        inputElementStockLogs: checkNullArray(processLogElement.inputElementStockLogs, []),
      }
    })
    setTimeout(setInputElementStockLogModalStatus(true), 1000)
  }

  /* ========================================================================= #6 */

  return (
    <>
      {open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>생산추적</ModalTitle>
              <CloseButton onClick={close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <ProcessNav>
                <ProcessButton
                  style={{ position: 'sticky', top: '0px' }}
                  onClick={() => { setViewStatus(() => { return 'summary' }) }}
                >
                  작업지시
                </ProcessButton>

                {_processLogList.map((thisItem) => {
                  return (<ProcessButton key={thisItem.processLogId + '_processButton'}
                    className={'process'}
                    onClick={() => {
                      setViewStatus(() => { return 'details'; });
                      setProcessLogData(() => { return thisItem; });
                      setProcessLogElementList(() => { return thisItem.elements; });
                    }}
                  >
                    {thisItem.code}<br />{thisItem.name}
                  </ProcessButton>);
                })}
              </ProcessNav>

              <ViewSection viewStatus={_viewStatus}>
                <ViewTitle>
                  <TableSection content={
                    <table>
                      <thead>
                        <tr>
                          <th>{_viewStatus === 'summary' ? '작업코드' : '공정코드'}</th>
                          <th>{_viewStatus === 'summary' ? '작업이름' : '공정이름'}</th>
                          <th>{_viewStatus === 'summary' ? '작업상태' : '공정진행결과'}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{_viewStatus === 'summary' ? _productionData.name : _processLogData.name}</td>
                          <td>{_viewStatus === 'summary' ? _productionData.code : _processLogData.code}</td>
                          <td>
                            {_viewStatus === 'summary' ? StatusName(_productionData.status === 'work' ? 'working' : _productionData.status,)
                              : `${_processLogData.completedCount || 0} / ${_processLogData.baseCount || 0}`}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  }
                  ></TableSection>
                </ViewTitle>
                <SummaryProductionProcess>
                  <TableSection content={
                    <table>
                      <thead>
                        <tr>
                          <th colSpan={4} style={{ backgroundColor: 'var(--MainYellow)' }}>투입 자재/반제품</th>
                        </tr>
                        <tr>
                          <th>물품이름</th>
                          <th>믈품코드</th>
                          <th className="amountCell">총 투입량</th>
                          {/* <th className="amountCell">재투입량</th>
                            <th className="amountCell">재사용량</th> */}
                          {_viewStatus !== 'summary' ? <th style={{ minWidth: '120px', width: '120px' }}></th> : null}
                        </tr>
                      </thead>
                      <tbody>
                        {_viewStatus === 'summary' ? Object.keys(_productionElementList).map((key, index) => {
                          const elementData = _productionElementList[key];
                          if (elementData.type !== 'Put') return null;
                          return (<tr key={index + 'summary-put'}>
                            <td>{elementData.name}</td>
                            <td>{elementData.code}</td>
                            <td>
                              {checkNull(elementData.baseAmount, false) ?
                                <CommaNum
                                  displayType={'text'}
                                  num={elementData.baseAmount}
                                /> : null}
                            </td>
                            {/* <td>
                                      <CommaNum
                                        displayType="text"
                                        num={elementData.reInputAmount}
                                      />
                                    </td>
                                    <td>
                                      <CommaNum
                                        displayType="text"
                                        num={elementData.reUsedAmount}
                                      />
                                    </td> */}
                          </tr>
                          );
                        },
                        )
                          : _processLogElementList.map((thisItem, index) => {
                            if (thisItem.type !== 'Put') return null;
                            return (<tr key={index + 'details-put'}>
                              <td>{thisItem.name}</td>
                              <td>{thisItem.code}</td>
                              <td>
                                {checkNull(thisItem.baseAmount, false) ?
                                  <CommaNum
                                    displayType={'text'}
                                    num={thisItem.baseAmount}
                                  /> : null
                                }
                              </td>
                              {/* <td>
                                    <CommaNum
                                      displayType="text"
                                      num={checkNull(thisItem.reInputAmount, 0)}
                                    />
                                  </td>
                                  <td>
                                    <CommaNum
                                      displayType="text"
                                      num={checkNull(thisItem.reUsedAmount, 0)}
                                    />
                                  </td> */}
                              <ElementStockLogButton style={{ minWidth: '120px', width: '120px' }} onClick={(e) => {
                                e.preventDefault();
                                showInputElementStockLog(thisItem, _processLogData);
                              }}>
                                투입추적
                                <GuideText />
                              </ElementStockLogButton>
                            </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  }
                  />
                  <TableSection content={
                    <table>
                      <thead>
                        <tr>
                          <th colSpan={6} style={{ backgroundColor: 'var(--ThirdBlue)' }}>공정완료제품</th>
                        </tr>
                        <tr>
                          <th>물품이름</th>
                          <th>믈품코드</th>
                          <th className="amountCell">목표 생산량</th>
                          <th className="amountCell" style={{ backgroundColor: 'var(--MainBlue)' }}>총 생산량</th>
                          <th className="amountCell" style={{ backgroundColor: 'var(--MainGreen)' }}>성공수량</th>
                          <th className="amountCell" style={{ backgroundColor: 'var(--MainRed)' }}>실패수량</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_viewStatus === 'summary' ? Object.keys(_productionElementList).map((key, index) => {
                          const elementData = _productionElementList[key];
                          if (elementData.type !== 'Make') return null;
                          const completedAmount = BigNumber(checkNull(elementData.makeAmount, 0)).plus(BigNumber(checkNull(elementData.failedAmount, 0))).toNumber();
                          return (<tr key={index + 'summary-make'}>
                            <td>{elementData.name}</td>
                            <td>{elementData.code}</td>
                            <td>
                              {checkNull(elementData.baseAmount, false) ?
                                <CommaNum
                                  displayType={'text'}
                                  num={elementData.baseAmount}
                                /> : null
                              }
                            </td>
                            <td>
                              <CommaNum
                                displayType="text"
                                num={completedAmount}
                              />
                            </td>
                            <td>
                              <CommaNum
                                displayType="text"
                                num={checkNull(elementData.makeAmount, 0)}
                              />
                            </td>
                            <td>
                              <CommaNum
                                displayType="text"
                                num={checkNull(elementData.failedAmount, 0)}
                              />
                            </td>
                          </tr>
                          );
                        },
                        )
                          : _processLogElementList.map((thisItem, index) => {
                            if (thisItem.type !== 'Make') return null;
                            const completedAmount = BigNumber(checkNull(thisItem.makeAmount, 0)).plus(BigNumber(checkNull(thisItem.failedAmount, 0))).toNumber();
                            return (
                              <tr key={index + 'details-make'}>
                                <td>{thisItem.name}</td>
                                <td>{thisItem.code}</td>
                                <td>
                                  {checkNull(thisItem.baseAmount, false) ?
                                    <CommaNum
                                      displayType={'text'}
                                      num={thisItem.baseAmount}
                                    /> : null
                                  }
                                </td>
                                <td>
                                  <CommaNum
                                    displayType="text"
                                    num={completedAmount}
                                  />
                                </td>
                                <td>
                                  <CommaNum
                                    displayType="text"
                                    num={checkNull(thisItem.makeAmount, 0)}
                                  />
                                </td>
                                <td>
                                  <CommaNum
                                    displayType="text"
                                    num={checkNull(thisItem.failedAmount, 0)}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  }
                  />
                </SummaryProductionProcess>
                {_viewStatus !== 'summary' && (
                  <TableSection content={
                    <table>
                      <thead>
                        <tr>
                          <th>입력시각</th>
                          <th>공정상태</th>
                          <th>책임자</th>
                          <th>작업자</th>
                          <th style={{ minWidth: '120px', width: '120px' }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {_processLogData.processResults.map((result, index) => {
                          return (<tr key={index + '_processResults'}>
                            <td>{DateTimeFormat(result.createdDate)}</td>
                            <td>{StatusName(result.status)}</td>
                            <td>
                              {result.managers.map((worker, workerIndex) => {
                                return (<p key={workerIndex + '_managers'}>{worker.workerName}</p>);
                              })}
                            </td>
                            <td>
                              {result.workers.map((worker, workerIndex) => {
                                return (<p key={workerIndex + '_workers'}>{worker.workerName}</p>);
                              })}
                            </td>
                            <td
                              style={{
                                backgroundColor: result.processResultElements.length > 0 ? 'var(--Violet)' : 'unset',
                                color: 'var(--white)',
                                cursor: result.processResultElements.length > 0 ? 'pointer' : 'default',
                                height: '80px',
                                minHeight: '80px',
                                minWidth: '120px',
                                width: '120px',
                              }}
                              onClick={() => { showProcessResult(result); }}
                            >
                              {result.processResultElements.length > 0 ? '상세보기' : ''}
                            </td>
                          </tr>
                          );
                        },
                        )}
                      </tbody>
                    </table>
                  }
                  />
                )}
              </ViewSection>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}

      {_processResultModalStatus === true && (
        <ProcessResultModal
          processLog={_processLogData}
          result={_eventProcessResult}
          open={_processResultModalStatus}
          close={() => { setProcessResultModalStatus(false) }}
        />
      )}

      {_inputElementStockLogModalStatus === true ?
        checkNullObject(_inputElementStockLogs.processLog, false) &&
          _inputElementStockLogs.processLog.status === 'end' ?
          <ShowInputElementStockResultLog
            content={_inputElementStockLogs}
            open={_inputElementStockLogModalStatus}
            close={() => { setInputElementStockLogModalStatus(false) }}
          ></ShowInputElementStockResultLog>
          : <ShowInputElementStockLog
            content={_inputElementStockLogs}
            open={_inputElementStockLogModalStatus}
            close={() => { setInputElementStockLogModalStatus(false) }}
          ></ShowInputElementStockLog>
        : null}
    </>
  );
};

export default ProductionProcessTracking;
