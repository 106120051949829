import { AxiosInterceptor } from "api/core/consts";

export const accountTypeApi = {
  searchAccountType: (body) =>
    AxiosInterceptor.post('accountType/search', body),
  createAccountType: (body) => AxiosInterceptor.post('accountType', body),
  updateAccountType: (accountTypeId, body) =>
    AxiosInterceptor.put('accountType/' + accountTypeId, body),
  deleteAccountType: (accountTypeId) =>
    AxiosInterceptor.delete('accountType/' + accountTypeId),
};
