import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';

import { orderReply } from 'api/apis/mall/orderReply';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import { DateTimeFormatKR } from 'components/format/DateTimeFormatKR';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import ShowMallFileList from 'pages/mallManagement/modal/ShowMallFileList';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  box-sizing: border-box;
  display: flex;
  height: 100vh;
  justify-content: flex-start;
  left: 0px;
  padding: 100px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 1000;
`;
const ModalSection = styled.div`
  background-color: var(--Bg);
  border: 1px solid var(--gray-200);
  display: grid;
  grid-template-rows: 55px calc(100% - 55px);
  height: 85%;
  max-height: 750px;
  max-width: 500px;
  overflow: hidden;
  width: 90%;
  border-radius: 16px;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--white);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const ModalHeaderButtons = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 55px;
`;
const ModalHeaderButton = styled.button`
  max-width: 80px;

  &.cancel {background-color: var(--gray-200);}
  &.delete {background-color: var(--MainRed);}
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  display: grid;
  grid-template-rows: auto 200px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  .TableSection {overflow: auto;}
`;
const ReplySection = styled.div`
  background-color: var(--Bg);
  height: 100%;
  overflow-y: scroll;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px; }
`;
const ReplyList = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 20px;
  row-gap: 15px;
  width: 100%;

  /* & p {color: var(--white);} */
`;
const UserReplyCon = styled.div`
  align-items: center;
  align-self: flex-end;
  column-gap: 20px;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  input[type='checkbox'] {height: 20px; margin: unset; width: 20px;}
  p {align-self: flex-end; font-size: 0.8em;}
`;
const MallUserReplyCon = styled(UserReplyCon)`
  align-self: unset;
  justify-content: unset;
  align-self: flex-start;
  justify-content: flex-start;
`;
const UserReply = styled.div`
  background-color: #fef01b;
  border-radius: 10px;
  box-sizing: border-box;
  max-width: 80%;
  /* min-width: 20%; */
  padding: 10px;
  width: fit-content;
  word-break: break-all;
`;
const MallUserReply = styled(UserReply)`
  background-color: var(--white);
`;

const SendSection = styled.div`
  background-color: var(--white);
  box-sizing: border-box;
  height: 100%;
  position: relative;
  width: 100%;
`;
const TextSection = styled.div`
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-bottom: 55px;
  width: 100%;

  textarea {
    box-sizing: border-box;
    border: none;
    font-size: 1.2em;
    height: 100%;
    outline: none;
    padding: 20px;
    resize: none;
    width: 100%;
  }
`;
const SendButton = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  border-radius: 4px;
  bottom: 10px;
  color: var(--gray-200);
  cursor: pointer;
  font-size: 0.8em;
  display: flex;
  height: 35px;
  justify-content: center;
  position: absolute;
  right: 10px;
  width: 60px;

  &.active {background-color: #fef01b; color: #556677;}
`;

const MallSiteOrderReply = (props) => {
  /* ====================================================================== #1 */
  const location = useLocation();
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const { mallOrder } = props;
  const { mallOrderId, mallOrderCode, status } = mallOrder;

  const mallReplyEndRef = React.createRef();

  const [_onload, setOnload] = useState('unload');

  const [_replyList, setReplyList] = useState([]);

  const [_reply, setReply] = useState('');

  const [_page, setPage] = useState(1);
  const [_pageTotalSize, setPageTotalSize] = useState(0);

  const [_editMode, setEditMode] = useState(false);
  const [_checkedItem, setCheckedItem] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    getReplyList(_page - 1);

    return () => { };
  }, [props]);

  useEffect(() => {
    scrollToBottom();

    return () => scrollToBottom();
  }, [_replyList]);

  /* ====================================================================== #4 */
  const getReplyList = async (page) => {
    const paging = `?page=${page}&size=50`;
    const companyCode = location.pathname.split('/')[1];
    const BodyToPost = {
      companyId: userReducer.user.companyId,
      companyCode: companyCode,
      mallOrderId: mallOrder.mallOrderId,
    };
    await orderReply.searchMallOrderReply(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('orderReply.searchMallOrderReply : ', response);
      setReplyList(() => {
        const sortReplyList = response.data.content.sort((a, b) => moment(a.createdDate) - moment(b.createdDate));
        return sortReplyList;
      });
      setPageTotalSize(() => { return response.data.totalElements; });
    });
  };

  /* ====================================================================== #5 */
  const scrollToBottom = () => {
    if (mallReplyEndRef.current === null || mallReplyEndRef.current === undefined) return;
    mallReplyEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleCheckedItem = (reply, isChecked) => {
    if (isChecked) setCheckedItem(() => { return reply; });
    else setCheckedItem(() => { return {}; });
  };

  const actReply = async () => {
    const companyCode = location.pathname.split('/')[1];
    const BodyToPost = {
      companyId: userReducer.user.companyId,
      companyCode: companyCode,
      mallOrderId: mallOrderId,
      userId: userReducer.user.id,
      content: _reply,
    };
    await orderReply.createMallOrderReply(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('orderReply.createMallOrderReply : ', response);
      setReply(() => { return ''; });
      getReplyList(0);
    });
  };

  const actDeleteReply = async () => {
    console.log('_checkedItem : ', _checkedItem);
    if (_checkedItem.mallOrderReplyId === undefined || _checkedItem.mallOrderReplyId === null) return;
    const confirmText = `댓글을 삭제합니다.`;
    if (window.confirm(confirmText)) {
      await orderReply.deleteMallOrderReply(_checkedItem.mallOrderReplyId).then((response) => {
        if (response === undefined) return;
        console.log('orderReply.deleteMallOrderReply : ', response);
        alert('댓글을 삭제했습니다.');
        getReplyList(0);
      });
    }
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>{mallOrderCode || ''}</ModalTitle>
              {/* <ModalHeaderButtons>
                {!_editMode ? (
                  <ModalHeaderButton className="formButton" onClick={() => {setEditMode((prev) => {return !prev;});}}>
                    편집
                  </ModalHeaderButton>
                ) : (
                  <>
                    <ModalHeaderButton className="formButton delete" onClick={() => {actDeleteReply();}}>
                      삭제
                    </ModalHeaderButton>
                    <ModalHeaderButton className="formButton cancel" onClick={() => {setEditMode((prev) => {return !prev;});}}>
                      취소
                    </ModalHeaderButton>
                  </>
                )}
              </ModalHeaderButtons> */}
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <ReplySection>
                <ReplyList>
                  {_replyList.map((reply, index) => {
                    if (checkEmptyNull(reply.userId, false) && reply.userId === userReducer.user.id) {
                      return (
                        <UserReplyCon key={index + '_userReply'}>
                          <p>{DateTimeFormatKR(reply.createdDate)}</p>
                          <UserReply>{reply.content}</UserReply>
                          {_editMode ? (
                            <input
                              type="checkBox"
                              name="userReply"
                              data-key={reply.mallOrderReplyId}
                              checked={(() => {
                                if (_checkedItem.mallOrderReplyId === reply.mallOrderReplyId) return true;
                                else return false;
                              })()}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                handleCheckedItem(reply, isChecked);
                              }}
                              onClick={(e) => { e.stopPropagation(); }}
                            />
                          ) : null}
                        </UserReplyCon>
                      );
                    } else {
                      return (
                        <MallUserReplyCon key={index + '_mallUserReply'}>
                          <MallUserReply>{reply.content}</MallUserReply>
                          <p>{DateTimeFormatKR(reply.createdDate)}</p>
                        </MallUserReplyCon>
                      );
                    }
                  })}
                  <div ref={mallReplyEndRef}></div>
                </ReplyList>
              </ReplySection>
              <SendSection>
                <TextSection>
                  <textarea value={_reply} onInput={(e) => { setReply(() => { return e.target.value; }); }} />
                </TextSection>
                <SendButton className={_reply.length > 0 ? 'active' : null} onClick={() => { if (_reply.length > 0) actReply(); }}>
                  전송
                </SendButton>
              </SendSection>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default MallSiteOrderReply;
