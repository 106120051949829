export const modalFileActions_setFolderList = (content) => {
  return {
    type: 'SETMODALFOLDERLIST',
    payload: content,
  };
};

export const modalFileActions_setFolderTreeList = (content) => {
  return {
    type: 'SETMODALFOLDERTREELIST',
    payload: content,
  };
};

export const modalFileActions_setCurrentFolder = (content) => {
  return {
    type: 'SETMODALCURRENTFOLDER',
    payload: content,
  };
};

export const modalFileActions_setDeleteFolder = (content) => {
  return {
    type: 'SETMODALDELETEFOLDER',
    payload: content,
  };
};

export const modalFileActions_setFileList = (content) => {
  return {
    type: 'SETMODALFILELIST',
    payload: content,
  };
};

export const modalFileActions_setFileSearch = (content) => {
  return {
    type: 'SETMODALFILESEARCH',
    payload: content,
  };
};

export const modalFileActions_setFileCurrentPage = (content) => {
  return {
    type: 'SETMODALFILECURRENTPAGE',
    payload: content,
  };
};

export const modalFileActions_setFileTotalSize = (content) => {
  return {
    type: 'SETMODALFILETOTALSIZE',
    payload: content,
  };
};

export const modalFileActions_setFileReset = () => {
  return {
    type: 'SETMODALFILERESET',
  };
};
