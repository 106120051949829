import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { manHour } from 'api/apis/manHour/manHour';
import { userApi } from 'api/apis/userApi';

import { DeleteBtn, GSManHourModalComponent } from './GSManHour.style';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import { StatusName } from 'components/status/StatusName';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';

import GSSelectOnlyWorkOrderLog from 'pages/productionOrder/custom/company/gs/modal/GSSelectOnlyWorkOrderLog';
import GSSelectWorkOrderLogs from 'pages/productionOrder/custom/company/gs/modal/GSSelectWorkOrderLogs';

const GSCreateManHour = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_createType, setCreateType] = useState('work');

  const [_workOrderLogModalStatus, setWorkOrderLogModalStatus] = useState(false);
  const [_workOrderLog, setWorkOrderLog] = useState({});

  const [_modalStatus, setModalStatus] = useState(false);
  const [_processLog, setProcessLog] = useState({});

  const [_workerList, setWorkerList] = useState([]);
  const [_workingTimes, setWorkingTimes] = useState([]);

  const initialData = {
    workerId: '',
    // startTime: moment().subtract(1, 'day').hours('09').minutes('00').format('YYYY-MM-DD HH:mm'),
    // endTime: moment().subtract(1, 'day').hours('18').minutes('00').format('YYYY-MM-DD HH:mm'),
    occurrenceDate: moment().format('YYYY-MM-DD'),
    value: 1,
    note: '',
  };
  const [_workingTime, setWorkingTime] = useState(initialData);

  /* ====================================================================== #3 */
  useEffect(() => {
    getWorkerList();
    return () => { }
  }, [])

  useEffect(() => {}, [_workOrderLog, _processLog, _workingTimes, _workingTime]);

  /* ====================================================================== #4 */
  const getWorkerList = async () => {
    const paging = `?page=0&size=100&sort=id,DESC`;
    const body = { companyId: userReducer.company.companyId }
    await userApi.searchUser(paging, body).then(response => {
      if (response === undefined) return;
      setWorkerList(() => { return checkNullArray(response.data.content, []).filter(worker => worker.team?.companyId === 3 && worker.role === 'ROLE_USER') });
    })
  }

  /* ====================================================================== #5 */
  const updateWorkOrderLog = (data) => {
    setWorkOrderLog(() => { return data });
    setWorkingTime(() => {return initialData});
    setWorkingTimes(() => {return []});
  }
  const updateProcessLog = (data) => {
    setProcessLog(() => { return data });
    setWorkingTime(() => {return initialData});
    setWorkingTimes(() => {return []});
  }

  const handleWorkingTime = (e) => {
    let { name, value } = e.target;
    if (name === 'startTime' || name === 'endTime') {
      value = moment(checkEmptyNull(value, '')).format('YYYY-MM-DD HH:mm');
    } else if(name === 'occurrenceDate') {
      value = moment(checkEmptyNull(value, '')).format('YYYY-MM-DD');
    } 
    // else if(name === 'value') {
    //   const num = Number(checkEmptyNull(value, 0));
    //   value = !isNaN(num) ? num : 0;
    // }

    setWorkingTime((prev) => {
      const newData = { ...prev, [name]: value };
      return newData;
    })
  }
  const actDelete = (workingTime, index) => {
    setWorkingTimes((prev) => {
      const returnData = [...prev];
      returnData.splice(index, 1);
      return returnData;
    })
  }

  const actCreate = async (e) => {
    e.preventDefault();
    if (_createType === 'work' && !checkNullObject(_workOrderLog, false)) return alert('작업을 선택해주세요.');
    if (_createType === 'process' && !checkNullObject(_processLog, false)) return alert('공정을 선택해주세요.');
    if (!checkNullArray(_workingTimes, false)) return alert('추가할 공수가 없습니다.');

    const id = _createType === 'work' ? _workOrderLog.workOrderLogId : _processLog.processLogId;
    const body = {
      companyId: userReducer.company.companyId,
      workingTimes: checkNullArray(_workingTimes, []).map((workingTime) => {
        return {
          ...workingTime,
          id: id,
          type: _createType,
          // startTime: moment().format('YYYY-MM-DDTHH:mm'),
          // endTime: moment().format('YYYY-MM-DDTHH:mm'),
          value: !isNaN(workingTime.value) ? workingTime.value : 0,
          occurrenceDate: moment(workingTime.occurrenceDate).format('YYYY-MM-DD')
        }
      })
    }
    await manHour.createhWorkingTime(body).then(response => {
      if (response === undefined) return;
      console.log('manHour.createhWorkingTime : ', response);
      alert('공수를 추가했습니다.');
      const fetchDate = body.workingTimes[0].occurrenceDate || moment().format('YYYY-MM-DD');
      setTimeout(() => { props.update(fetchDate); }, 1000);
    })
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <GSManHourModalComponent>
          <section>
            <header>
              <h3>공수 추가</h3>
              <div className='btn-close' onClick={props.close}><CloseButtonIcon /></div>
            </header>

            <section>
              {_createType === 'work' ? (
                <section>
                  <header>
                    <h4>작업 공수 추가</h4>
                    <button className='btn-set'
                      onClick={(e) => { e.preventDefault(); setWorkOrderLogModalStatus(true); }}
                    >
                      작업 선택
                    </button>
                  </header>
                  {checkNullObject(_workOrderLog, false) ? (
                    <section className='main-section'>
                      <div className='main-info'>
                        <div className='info-box'>
                          {[
                            { name: '작업상태', value: _workOrderLog.workOrderStatus },
                            { name: '거래처', value: _workOrderLog.accountName },
                            { name: '작업코드', value: _workOrderLog.workOrderCode },
                            { name: '공사명', value: _workOrderLog.workOrderName },
                            { name: '현장명', value: _workOrderLog.workPlace },
                            { name: '현장주소', value: _workOrderLog.detailedWorkPlace },
                            { name: '공사기간', value: `${DateFormat(checkEmptyNull(_workOrderLog.workOrderScheduledStartDate, ''))} ~ ${DateFormat(checkEmptyNull(_workOrderLog.workOrderScheduledEndDate, ''))}` },
                          ].map((data, index) => {
                            return (<p key={index + '_workOrderLog'}><span>{data.name} : </span>{data.name === '작업상태' ? StatusName(data.value) : data.value}</p>);
                          })}
                        </div>
                        <div className='worker-form'>
                          <div>
                            <h5>작업자</h5>
                            <select name='workerId' value={_workingTime.workerId} onChange={handleWorkingTime}>
                              <option value={''}>작업자 선택</option>
                              {checkNullArray(_workerList, []).map((worker, index) => {
                                return (<option key={index + '_worker'} value={worker.id}>[{worker.teamName}] {worker.name}</option>)
                              })}
                            </select>
                          </div>
                          <div>
                            <h5>작업일자</h5>
                            <input type='date' name='occurrenceDate' value={_workingTime.occurrenceDate} onChange={handleWorkingTime} />
                          </div>
                          <div>
                            <h5>공수<span>*숫자만 입력해주세요.</span></h5>
                            <input type='number' name='value' min={0} value={_workingTime.value} onChange={handleWorkingTime} />
                          </div>
                          <div>
                            <h5>작업내용</h5>
                            <textarea name='note' value={_workingTime.note} onChange={handleWorkingTime}></textarea>
                          </div>

                          <button className='btn-set'
                            onClick={(e) => {
                              e.preventDefault();
                              if (!checkEmptyNull(_workingTime.workerId, false)) return alert('작업자를 선택해주세요.');
                              setWorkingTimes((prev) => {
                                const returnData = [...prev, _workingTime];
                                setWorkingTime(() => {return initialData});
                                return returnData;
                              });
                            }}
                          >
                            작업자 추가
                          </button>
                        </div>
                      </div>
                      <div className='main-worker'>
                        {checkNullArray(_workingTimes, []).map((workingTime, index) => {
                          const worker = _workerList.find(item => item.id === workingTime.workerId * 1);
                          return (
                            <div key={index + '_workingTime'} className='workingTime'>
                              <DeleteBtn onClick={(e) => { e.preventDefault(); actDelete(workingTime, index); }}><CloseButtonIcon /></DeleteBtn>
                              <h5>{worker.name}</h5>
                              <div>
                                <p>작업일자 : {moment(workingTime.occurrenceDate).format('YYYY-MM-DD')}</p>
                                <p>공수 : {checkEmptyNull(workingTime.value, 0).toLocaleString()}</p>
                                <p>작업내용 : {workingTime.note}</p>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </section>
                  ) : null}
                </section>
              ) : (
                <section>
                  <header>
                    <h4>공정 공수 추가</h4>
                    <button className='btn-set'
                      onClick={(e) => { e.preventDefault(); setModalStatus(true); }}
                    >
                      공정 선택
                    </button>
                  </header>
                  {checkNullObject(_processLog, false) ? (
                    <section className='main-section'>
                      <div className='main-info'>
                        <div className='info-box'>
                          {(() => {
                            let processLogData = [];
                            console.log('_processLog : ', _processLog);
                            switch (_processLog.processTypeName) {
                              case 'BL':
                                const processCustomizedContent_BL = checkNullParse(_processLog.processCustomizedContent, {
                                  num: 0,
                                  absentNumber: '',
                                  standard: '',
                                  unit: '',
                                  length: '',
                                  quantity: '',
                                  defaultCamber: '',
                                  realCamber: '',
                                  remark: '',
                                })
                                processLogData = [
                                  { name: '순번', value: processCustomizedContent_BL.num },
                                  { name: '부재번호', value: processCustomizedContent_BL.absentNumber },
                                  { name: '규격', value: processCustomizedContent_BL.standard },
                                  { name: '단위', value: processCustomizedContent_BL.unit },
                                  { name: '길이', value: processCustomizedContent_BL.length },
                                  { name: '수량', value: processCustomizedContent_BL.quantity },
                                  { name: '기준캠버값', value: processCustomizedContent_BL.defaultCamber },
                                  { name: '실제캠버값', value: processCustomizedContent_BL.realCamber },
                                  { name: '비고', value: processCustomizedContent_BL.remark },
                                ];
                                break;
                              case 'CP':
                                const processCustomizedContent_CP = checkNullParse(_processLog.processCustomizedContent, {
                                  num: 0,
                                  name: '',
                                  profile: '',
                                  length: '',
                                  quantity: '',
                                  combined: '',
                                  part: '',
                                  partWidth: '',
                                  each: '',
                                  total: '',
                                  mark: '',
                                })
                                processLogData = [
                                  { name: 'Num', value: processCustomizedContent_CP.num },
                                  { name: '재질', value: processCustomizedContent_CP.name },
                                  { name: '규격', value: processCustomizedContent_CP.profile },
                                  { name: '길이', value: processCustomizedContent_CP.length },
                                  { name: '수량', value: processCustomizedContent_CP.quantity },
                                  { name: '조합', value: processCustomizedContent_CP.combined },
                                  { name: '잔재', value: processCustomizedContent_CP.part },
                                  { name: '부재길이', value: processCustomizedContent_CP.partWidth },
                                  { name: '수량', value: processCustomizedContent_CP.each },
                                  { name: '총수량', value: processCustomizedContent_CP.total },
                                  { name: 'Mark', value: processCustomizedContent_CP.mark },
                                ];
                                break;
                              case 'CL':
                                const processCustomizedContent_CL = checkNullParse(_processLog.processCustomizedContent, {
                                  num: 0,
                                  asmbly: '',
                                  profile: '',
                                  length: '',
                                  quantity: '',
                                  part: '',
                                  weight: '',
                                  name: '',
                                })
                                processLogData = [
                                  { name: '순번', value: processCustomizedContent_CL.num },
                                  { name: '부재번호', value: processCustomizedContent_CL.asmbly },
                                  { name: '규격', value: processCustomizedContent_CL.profile },
                                  { name: '단위', value: processCustomizedContent_CL.length },
                                  { name: '길이', value: processCustomizedContent_CL.quantity },
                                  { name: '수량', value: processCustomizedContent_CL.part },
                                  { name: '기준캠버값', value: processCustomizedContent_CL.weight },
                                  { name: '실제캠버값', value: processCustomizedContent_CL.name },
                                ];
                                break;

                              default: return <></>;
                            }
                            const returnData = processLogData.map((data, index) => {
                              return (<p key={index + '_processLog'}><span>{data.name} : </span>{data.value}</p>);
                            });
                            return returnData;
                          })()}
                        </div>
                        <div className='worker-form'>
                          <div>
                            <h5>작업자</h5>
                            <select name='workerId' value={_workingTime.workerId} onChange={handleWorkingTime}>
                              <option value={''}>작업자 선택</option>
                              {checkNullArray(_workerList, []).map((worker, index) => {
                                return (<option key={index + '_worker'} value={worker.id}>[{worker.teamName}] {worker.name}</option>)
                              })}
                            </select>
                          </div>
                          <div>
                            <h5>작업일자</h5>
                            <input type='date' name='occurrenceDate' value={_workingTime.occurrenceDate} onChange={handleWorkingTime} />
                          </div>
                          <div>
                            <h5>공수<span>*숫자만 입력해주세요.</span></h5>
                            <input type='number' name='value' min={0} value={_workingTime.value} onChange={handleWorkingTime} />
                          </div>
                          <div>
                            <h5>작업내용</h5>
                            <textarea name='note' value={_workingTime.note} onChange={handleWorkingTime}></textarea>
                          </div>

                          <button className='btn-set'
                            onClick={(e) => {
                              e.preventDefault();
                              if (!checkEmptyNull(_workingTime.workerId, false)) return alert('작업자를 선택해주세요.');
                              setWorkingTimes((prev) => {
                                const returnData = [...prev, _workingTime];
                                setWorkingTime(() => {return initialData});
                                return returnData;
                              });
                            }}
                          >
                            작업자 추가
                          </button>
                        </div>
                      </div>
                      <div className='main-worker'>
                        {checkNullArray(_workingTimes, []).map((workingTime, index) => {
                          const worker = _workerList.find(item => item.id === workingTime.workerId * 1);
                          return (
                            <div key={index + '_workingTime'} className='workingTime'>
                              <DeleteBtn onClick={(e) => { e.preventDefault(); actDelete(workingTime, index); }}><CloseButtonIcon /></DeleteBtn>
                              <h5>{worker.name}</h5>
                              <div>
                                <p>작업일자 : {moment(workingTime.occurrenceDate).format('YYYY-MM-DD')}</p>
                                <p>공수 : {checkEmptyNull(workingTime.value, 0).toLocaleString()}</p>
                                <p>작업내용 : {workingTime.note}</p>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </section>
                  ) : null}
                </section>
              )}
            </section>

            <section className='footer'>
              <div onClick={actCreate}>추가</div>
            </section>
          </section>

          {_workOrderLogModalStatus === true ? (
            <GSSelectOnlyWorkOrderLog
              content={_workOrderLog}
              update={(data) => { updateWorkOrderLog(data) }}
              open={_workOrderLogModalStatus}
              close={() => { setWorkOrderLogModalStatus(false); }}
            ></GSSelectOnlyWorkOrderLog>
          ) : null}
          {_modalStatus === true ? (
            <GSSelectWorkOrderLogs
              content={_processLog}
              update={(data) => { updateProcessLog(data) }}
              open={_modalStatus}
              close={() => { setModalStatus(false); }}
            ></GSSelectWorkOrderLogs>
          ) : null}
        </GSManHourModalComponent>
      )}
    </>
  );
};

export default GSCreateManHour;
