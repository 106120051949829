import React, { useState } from 'react';
import Grid3Body from "components/layouts/body/Grid3Body"
import HistoricalDataPage from "./historicalDataPage/HistoricalDataPage"
import RealTimePage from './realTimePage/RealTimePage';
import styled from 'styled-components';

const Footer = styled.div`
  padding: 0 20px;
`

const FooterBtn = styled.button`
  background-color: var(--MainNavy);
  color: var(--white);
  padding: 10px 20px;
  border-radius: 10px;
`

const GsEsgMonitoring = () => {
  const [showHistoricalData, setShowHistoricalData] = useState(false);

  const handleButtonClick = () => {
    setShowHistoricalData(prevState => !prevState);
  };

  return (
    <Grid3Body contents={
      <>
      <div>
        {showHistoricalData ? <HistoricalDataPage /> : <RealTimePage />}
      </div>
      <Footer>
        <FooterBtn onClick={handleButtonClick}>{showHistoricalData ? '실시간 데이터' : '년 월 일 데이터'}</FooterBtn>
      </Footer>
      </>
    }
    />
  )
}

export default GsEsgMonitoring