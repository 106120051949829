export const userReducer_setUser = (thisUser) => { return { type: 'SETUSER', payload: thisUser }; };
export const userReducer_setCompany = (thisCompany) => { return { type: 'SETUSERCOMPANY', payload: thisCompany }; };

export const userReducer_setSelectUser = (thisUser) => { return { type: 'SETSELECTUSER', payload: thisUser }; };

export const userReducer_setUserElementType = (thisElementType) => { return { type: 'SETUSERELEMENTTYPE', payload: thisElementType }; };
export const userReducer_setUserStockType = (thisStockType) => { return { type: 'SETUSERSTOCKTYPE', payload: thisStockType }; };
export const userReducer_setUserPutName = (thisPutName) => { return { type: 'SETUSERPUTNAME', payload: thisPutName }; };
export const userReducer_setUserEndDateName = (thisEndDateName) => { return { type: 'SETUSERENDDATENAME', payload: thisEndDateName }; };

export const userReducer_userCheck = (thisCheck) => { return { type: 'USERCHECKING', payload: thisCheck }; };

export const userReducer_setUserMenuCode = (thisMenuCode) => { return { type: 'SETUSERMENUCODE', payload: thisMenuCode }; };
export const userReducer_setUserMenu = (thisMenus) => { return { type: 'SETUSERMENU', payload: thisMenus }; };

export const userReducer_setUserSubscribe = (subscribe) => { return { type: 'SETUSERSUBSCRIBE', payload: subscribe }; };
export const userReducer_resetUserSubscribe = () => { return { type: 'RESETUSERSUBSCRIBE' }; };

export const userReducer_reset = () => { return { type: 'USERRESET' }; };