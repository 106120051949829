import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { monitoringActions_setMonitoringView } from 'store/modules/actions/common/monitoringActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { ConnectStatus } from 'pages/monitoring/styles';
import Header from 'components/header/Header';

import CalendarMonitoring from 'pages/monitoring/custom/company/uri/contents/CalendarMonitoring';
import MaterialMonitoring from 'pages/monitoring/custom/company/uri/contents/MaterialMonitoring';
import ProductionOrderMonitoring from 'pages/monitoring/custom/company/uri/contents/ProductionOrderMonitoring';
import TotalMonitoring from 'pages/monitoring/custom/company/uri/contents/TotalMonitoring';

const Section = styled.main`
  display: grid;
  grid-template-rows: auto minmax(calc(100% - 120px), calc(100% - 60px)) 60px;
  height: 100svh;
  overflow: hidden;
  width: 100svw;

  header {box-shadow: none}
  /* section {overflow: auto;} */
`;
const HeaderBtn = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  bottom: 10px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-weight: 600;
  left: 10px;
  padding: 8px 16px;
  position: fixed;
  width: fit-content;
`;
const MonitoringBtns = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  width: 100%;

  button {width: fit-content;}
`;

const UriTotalMonitoring = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { monitoringReducer, workReducer } = useSelector((state) => state);

  const [_onHeader, setOnHeader] = useState(false);

  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    return () => {}
  }, [])

  useEffect(() => {}, [workReducer.connectStatus]);
  useEffect(() => {}, [monitoringReducer.monitoringView]);

  const clickHeader = (e) => {
    e.preventDefault();
    setOnHeader((prev) => {return !prev});
  };
  const handleMonitoringView = (viewType) => {
    dispatch(monitoringActions_setMonitoringView(viewType));
  }
  

  return (
    <Section>
      {_onHeader ? (<Header />) : <div></div> }
      <section>
        {monitoringReducer.monitoringView === 'all' ? <TotalMonitoring /> : null}
        {monitoringReducer.monitoringView === 'material' ? <MaterialMonitoring /> : null}
        {monitoringReducer.monitoringView === 'production' ? <ProductionOrderMonitoring /> : null}
        {monitoringReducer.monitoringView === 'calendar' ? <CalendarMonitoring /> : null}
      </section>
      <HeaderBtn onClick={clickHeader}>전체메뉴</HeaderBtn>
      <MonitoringBtns>
        <button className='btn-set' onClick={(e) => {e.preventDefault(); handleMonitoringView('all')}}>전체보기</button>
        <button className='btn-set' onClick={(e) => {e.preventDefault(); handleMonitoringView('material')}}>재고현황</button>
        <button className='btn-set' onClick={(e) => {e.preventDefault(); handleMonitoringView('production')}}>작업현황</button>
        <button className='btn-set' onClick={(e) => {e.preventDefault(); handleMonitoringView('calendar')}}>일일일정표</button>
      </MonitoringBtns>
      <ConnectStatus style={{bottom: '10px', right: '10px'}} connectStatus={workReducer.connectStatus} onClick={() => {navigate(0);}} />
    </Section>
  );
};

export default UriTotalMonitoring;