import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { stockPlaceHistory } from 'api/apis/stock/stockPlaceHistory';

import { checkEmptyNull } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 40px 50px;
  height: 85%;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {height: 100%; overflow-x: auto; width: 100%;
    & input[type="radio"] {cursor: pointer; margin: 0px auto; transform: scale(1.2);}
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const SelectOneStockPlaceHistory = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_logList, setLogList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState({});
  console.log('_checkedItem : ', _checkedItem);

  const [_searchData, setSearchData] = useState({});

  const [_pageNumber, setPageNumber] = useState(1);
  const [_totalSize, setTotalSize] = useState(0);

  /* ====================================================================== #3 */
  useEffect(() => {
    getStockPlaceHistory(_pageNumber - 1);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') getStockPlaceHistory(_pageNumber - 1);
    return () => { };
  }, [_pageNumber]);

  /* ====================================================================== #4 */
  const getStockPlaceHistory = async (page, clear) => {
    const paging = `?page=${page}&size=15&sort=id,DESC`;
    const BodyToPost = { companyId: userReducer.company.companyId };
    if (checkEmptyNull(_searchData.elementName, false)) BodyToPost.elementName = _searchData.elementName;
    if (checkEmptyNull(_searchData.elementCode)) BodyToPost.elementCode = _searchData.elementCode;
    if (checkEmptyNull(_searchData.stockPlaceName)) BodyToPost.stockPlaceName = _searchData.stockPlaceName;
    await stockPlaceHistory.searchStockPlaceHistory(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('stockPlaceHistory.searchStockPlaceHistory : ', response);
      setLogList(() => { return response.data.content; });
      setTotalSize(() => { return response.data.totalElements; });
    });
  };

  /* ====================================================================== #5 */
  /* 검색 */
  const actSearch = async () => {
    setPageNumber(() => { return 1 });
    await getStockPlaceHistory(0);
  };

  /* 초기화 */
  const actReset = async () => {
    setSearchData(() => { return {}; })
    setPageNumber(() => { return 1 });
    await getStockPlaceHistory(0, true);
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>{props.modalTitle}</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <NavBar
                title={props.modalTitle}
                nav={''}
                buttons={
                  <div className="SearchSection">
                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="elementName">물품이름</option>
                      </select>
                      <input
                        className="SearchBar detail"
                        placeholder="Search..."
                        value={checkEmptyNull(_searchData.elementName, '')}
                        onInput={(e) => { setSearchData(prev => { return { ...prev, elementName: e.target.value } }) }}
                      />
                    </div>

                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="elementCode">물품코드</option>
                      </select>
                      <input
                        className="SearchBar detail"
                        placeholder="Search..."
                        value={checkEmptyNull(_searchData.elementCode, '')}
                        onInput={(e) => { setSearchData(prev => { return { ...prev, elementCode: e.target.value } }) }}
                      />
                    </div>

                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="stockPlaceName">재고위치</option>
                      </select>
                      <input
                        className="SearchBar detail"
                        placeholder="Search..."
                        value={checkEmptyNull(_searchData.stockPlaceName, '')}
                        onInput={(e) => { setSearchData(prev => { return { ...prev, stockPlaceName: e.target.value } }) }}
                      />
                    </div>

                    <div className="ContentCBox">
                      <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                      <div className="ResetButton" onClick={actReset}>초기화</div>
                    </div>
                  </div>
                }
              />

              <TableSection content={
                <table>
                  <thead>
                    <tr>
                      <th style={{ minWidth: '50px', width: '50px' }}></th>
                      <th>물품이름</th>
                      <th>물품코드</th>
                      <th>재고 위치</th>
                      <th>수량</th>
                      <th>투입예정수량</th>
                      <th>사용량</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_logList.map((log, index) => {
                      return (<tr key={index + '_logList'}>
                        <td style={{ minWidth: '50px', width: '50px' }}>
                          <input
                            type="radio"
                            name="logs"
                            onChange={() => { setCheckedItem(() => { return log }); }}
                          />
                        </td>
                        <td>{log.elementName}</td>
                        <td>{log.elementCode}</td>
                        <td>{log.stockPlaceName}</td>
                        <td>{checkEmptyNull(log.amount, 0).toLocaleString()}</td>
                        <td>{checkEmptyNull(log.scheduledInputStock, 0).toLocaleString()}</td>
                        <td>{checkEmptyNull(log.usedStock, 0).toLocaleString()}</td>
                      </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
              />
            </ModalMain>

            <PagingComponent
              page={_pageNumber}
              count={_totalSize}
              size={10}
              pageEvent={(page) => {
                console.log('page : ', page);
                setPageNumber(() => { return page; });
              }}
            />

            <ModalFooter>
              <ModalButton onClick={(e) => { e.preventDefault(); props.buttonEvent(_checkedItem); }}>{props.buttonTitle}</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default SelectOneStockPlaceHistory;
