import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

import { procurementApi } from 'api/apis/procurementApi';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import SelectElements from 'pages/element/modal/SelectElements';
import GsSelectElements from 'pages/element/modal/GsSelectElements';
import SelectOneAccount from 'pages/account/modal/SelectOneAccount';
import TableSection from 'components/layouts/table/TableSection';

const DelButton = styled.div`
  height: 15px;
  margin: 0px auto;
  width: 15px;
  div {background-color: var(--MainRed); cursor: pointer; height: 15px; width: 15px;}
`;
const ProcurementDate = styled.input`
  border: 1px solid var(--gray-200);
  border-radius: 5px;
  box-sizing: border-box;
  height: 40px;
  padding: 5px;
  &:focus {border: 1px solid var(--gray-200);}
`;

const TableFormbox = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  width: 100%;

  .TableSection {
    overflow: auto;
    input {font-size: 24px; padding: 5px 15px;}
  }
  & > .TableSection {
    max-height: 350px;
    &::-webkit-scrollbar-thumb {background-color: var(--ThirdBlue);}
  }
`;

const SelectSection = styled.div``;
const SelectButtonSection = styled.div`
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  height: 50px;
  overflow: hidden;
  width: 100%;
`;
const SelectButton = styled.div`
  align-items: center;
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 8px;
  text-wrap: nowrap;
  width: 100%;

  background-color: ${(props) => {
    return props.type === 'add' ? 'var(--WorkingColor)'
      : props.type === 'replace' ? 'var(--Excel)'
        : props.type === 'update' ? 'var(--MainNavy)'
          : props.type === 'cancel' ? 'var(--gray-200)'
            : null;
  }};
`;

const FileTableSection = styled.div`
  cursor: pointer;
  width: 100%;
  &:hover {opacity: 0.6;}
  & > .TableSection {
    max-height: 200px;
    overflow: auto;
    td, th {min-width: unset;}
    &::-webkit-scrollbar-thumb {background-color: var(--ThirdBlue);}
  }
`;
const FileLabelStyle = styled.label`
  align-items: center;
  background-color: var(--MainBlue);
  border-radius: 5px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const UpdateGSProcurement = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { contentsReducer, pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_formData, setFormData] = useState({
    companyId: userReducer.user.companyId,
    userId: userReducer.user.id, // 작성자

    accountId: contentsReducer.updateContents?.account?.accountId,

    procurementStatus: checkEmptyNull(contentsReducer.updateContents.procurementStatus, 'waiting'),
    procurementId: contentsReducer.updateContents.procurementId,
    procurementCode: contentsReducer.updateContents.procurementCode,
    procurementName: contentsReducer.updateContents.procurementName,

    procurementDate: checkEmptyNull(contentsReducer.updateContents.procurementDate, false) ? DateFormat(contentsReducer.updateContents.procurementDate) : '',
    scheduledEndDate: checkEmptyNull(contentsReducer.updateContents.scheduledEndDate, false) ? DateFormat(contentsReducer.updateContents.scheduledEndDate) : '',

    procurementPrice: contentsReducer.updateContents.procurementPrice,

    procurementElementList: checkNullArray(contentsReducer.updateContents.procurementElementList, []).map(element => {
      return {
        procurementElementId: element.procurementElementId,
        elementId: element.elementId,
        element: element.element,

        price: element.price,
        quantity: element.quantity,
        storeQuantity: element.storeQuantity,
        savedQuantity: 0,

        customizedContent: checkNullParse(element.customizedContent, {
          num: '',
          weight: '',
        }),
      };
    }),
    deleteProcurementElementList: [],

    customizedContent: contentsReducer.updateContents.customizedContent,
  });

  const [_modalStatus, setModalStatus] = useState(false); // 물품 선택 모달

  /* 엑셀 */
  const [_fileProcurement, setFileProcurement] = useState([]);
  const [_selectType, setSelectType] = useState();

  /* 거래처 */
  const [_account, setAccount] = useState(checkNullObject(contentsReducer.updateContents?.account, {}));
  const [_accountModalStatus, setAccountModalStatus] = useState(false); // 거래처 선택 모달

  const [_loading, setLoading] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => { }, [_loading])

  /* ====================================================================== #4 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData(prev => { return { ...prev, [name]: value } });
  };

  // 엑셀
  const excelExportCommon = (e, callback) => {
    const fileList = e.target.files;
    const file = fileList[0];
    console.log(file);
    if (file === undefined) return;

    document.getElementById('procurementFileTable').innerHTML = '';

    const reader = new FileReader();
    reader.onload = async () => {
      const fileData = reader.result;
      const wb = XLSX.read(fileData, { type: 'binary' });
      const sheetNameList = wb.SheetNames; // 시트 이름 목록 가져오기
      for (let i = 0; i < sheetNameList.length; i++) {
        const sheetName = sheetNameList[i];
        const sheet = wb.Sheets[sheetName];
        await callback(sheet);
      }
    };
    reader.readAsBinaryString(file);
  };
  const excelSetting = (sheet) => {
    const procurement_result = XLSX.utils.sheet_to_html(sheet);
    console.log('procurement_result : ', procurement_result);
    document.getElementById('procurementFileTable').innerHTML = procurement_result;
    setProcurementTable();
  };

  // 발주 테이블
  const setProcurementTable = () => {
    const resultRows = document.querySelectorAll('#procurementFileTable tr');
    console.log('setProcurementTable : ', resultRows);
    let headerRowIndex = null;
    let sumRowIndex = null;
    if (resultRows.length > 0) {
      resultRows.forEach((thisItem) => {
        if (thisItem.cells[0].innerHTML === '순번') {
          headerRowIndex = thisItem.rowIndex;
          console.log('headerRowIndex : ', headerRowIndex);
        }
        if (thisItem.cells[0].innerHTML.trim() === '' || thisItem.cells[1].innerHTML.replaceAll(' ', '') === '[합계]') {
          sumRowIndex = thisItem.rowIndex;
          console.log('sumRowIndex : ', sumRowIndex);
        }
      });
    }

    if (headerRowIndex !== null && sumRowIndex !== null) {
      let result = [];
      resultRows.forEach((thisItem) => {
        if (thisItem.rowIndex >= headerRowIndex + 1 && thisItem.rowIndex < sumRowIndex) {
          if (thisItem.cells[1].innerHTML === '' && thisItem.cells[2].innerHTML === '') return;
          const OneRow = {
            status: 'waiting',
            quantity: isNaN(thisItem.cells[5].innerHTML.trim()) !== true ? Number(thisItem.cells[5].innerHTML.trim()) : 0, // 수량
            price: 0, // 금액
            // price: isNaN(thisItem.cells[9].innerHTML.trim()) !== true ? Number(thisItem.cells[9].innerHTML.trim()) : 0, // 금액
            customizedContent: JSON.stringify({
              num: thisItem.cells[0].innerHTML.trim(), // 순번
              weight: thisItem.cells[7].innerHTML.trim(), // 중량
            }),
            elementForExcelDto: {
              elementType: 'material',

              bcodeSearchCode: '',
              elementCode: thisItem.cells[2].innerHTML.trim(), // 규격
              elementName: thisItem.cells[1].innerHTML.trim(), // 재질(품명)
              elementUnit: thisItem.cells[3].innerHTML.trim(), // 단위
              elementSafeStock: 0,
              elementStock: 0,

              attributeAndValue: {},
            },
          };

          OneRow.elementForExcelDto.attributeAndValue['길이'] = thisItem.cells[4].innerHTML.trim(); // 길이
          OneRow.elementForExcelDto.attributeAndValue['단중'] = thisItem.cells[6].innerHTML.trim(); // 단중
          // OneRow.elementForExcelDto.attributeAndValue['단가'] = thisItem.cells[8].innerHTML.trim(); // 단가
          OneRow.elementForExcelDto.attributeAndValue['비고'] = thisItem.cells[8].innerHTML.trim(); // 비고
          // OneRow.elementForExcelDto.attributeAndValue['비고'] = thisItem.cells[10].innerHTML.trim(); // 비고

          result.push(OneRow);
        }
      });
      console.log('result : ', result, JSON.stringify(result));
      setFileProcurement(result);
    }
  };



  /* ====================================================================== #5 */
  const actSelectType = (e) => {
    const { type } = e.currentTarget.dataset;
    setSelectType(type);
  };

  // 발주서 업로드
  const setProcurementFile = (e) => {
    excelExportCommon(e, excelSetting);
  };

  const delStockProduct = (e) => {
    const delElement = e.target.closest('tr');
    const delElementId = delElement.dataset.key;
    const delList = _formData.procurementElementList.filter((thisItem) => thisItem.elementId === Number(delElementId));
    const setList = _formData.procurementElementList.filter((thisItem) => thisItem.elementId !== Number(delElementId));

    let setDelList = null;
    if (checkNullArray(delList, false)) setDelList = [..._formData.deleteProcurementElementList, delList[0].procurementElementId];

    const setData = {
      ..._formData,
      procurementElementList: setList,
    };
    if (checkEmptyNull(setDelList, false)) setData.deleteProcurementElementList = setDelList;
    setFormData(() => { return setData });
  };

  const saveForm = async (e) => {
    e.preventDefault();

    if (!checkEmptyNull(_formData.accountId, false)) return alert('거래처를 선택해주세요.');
    if (!checkEmptyNull(_formData.procurementCode, false)) return alert('발주코드를 입력해주세요.');
    if (!checkEmptyNull(_formData.procurementName, false)) return alert('발주명을 입력해주세요.');

    setLoading(() => { return true; });

    const procurementContents = contentsReducer.updateContents?.procurementElementList;
    let procurementElementList = checkNullArray(procurementContents?.procurementElementList, []).map((thisItem) => {
      return {
        ...thisItem,
        accountId: _account.accountId,
        procurementElementId: thisItem.procurementElementId,
        elementId: thisItem.element?.elementId,
        customizedContent: JSON.stringify(thisItem.customizedContent),
      };
    });
    let deleteProcurementElementList = [];

    switch (_selectType) {
      case 'replace':
        if (_fileProcurement.length > 0) {
          procurementElementList = _fileProcurement;
          deleteProcurementElementList = procurementContents.map((thisItem) => thisItem.procurementElementId);
        }
        break;

      case 'add':
        if (_fileProcurement.length > 0) {
          procurementElementList = [
            ...procurementContents.map((thisItem) => {
              return {
                ...thisItem,
                procurementElementId: thisItem.procurementElementId,
                elementId: thisItem.element?.elementId,
                customizedContent: JSON.stringify(thisItem.customizedContent),
              };
            }),
            ..._fileProcurement,
          ];
        }
        break;

      case 'update':
        procurementElementList = _formData.procurementElementList.map((thisItem) => {
          const checkElIndex = procurementContents.findIndex((thisElementData) => thisElementData.element?.elementId === thisItem.element?.elementId);
          if (checkElIndex === -1) {
            return {
              ...thisItem,
              elementId: thisItem.element?.elementId,
              customizedContent: JSON.stringify(thisItem.customizedContent),
            };
          } else {
            return {
              ...thisItem,
              procurementElementId: procurementContents[checkElIndex].procurementElementId,
              elementId: thisItem.element?.elementId,
              customizedContent: JSON.stringify(thisItem.customizedContent),
            };
          }
        });
        break;

      default: break;
    }

    const BodyToPut = {
      ..._formData,
      accountId: _account?.accountId,

      procurementElementList: procurementElementList.map((element) => {
        const returnData = { ...element, savedQuantity: 0, };
        delete returnData.storeQuantity;
        return returnData;
      }),

      isCustom: true, // 경서 > 커스텀 표시
    };
    if (!checkEmptyNull(BodyToPut.scheduledEndDate, false)) delete BodyToPut['scheduledEndDate'];
    if (checkNullArray(deleteProcurementElementList, false)) BodyToPut.deleteProcurementElementList = deleteProcurementElementList;
    console.log('BodyToPut : ', BodyToPut, JSON.stringify(BodyToPut));

    await procurementApi.updateProcurement(BodyToPut.procurementId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('procurementApi.updateProcurement : ', response);
      alert('발주를 수정했습니다.');
      setLoading(() => { return false; });
      navigate(pageReducer.currentPage, { replace: true });
    }).catch(() => {
      setLoading(() => { return false; });
    })
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <>
        <main className="Main">
          <NavBar title={'발주관리'} nav={''} />

          <Form
            title={<>발주<br />수정</>}
            buttons={
              <>
                {!_loading ? (<button className="formButton" onClick={saveForm}>저장</button>) : null}
                <button className="formButton cancle"
                  onClick={() => {
                    navigate(pageReducer.currentPage, { replace: true });
                  }}
                >
                  취소
                </button>
              </>
            }
            forms={
              <>
                <div className="formBox">
                  <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                    <h4>거래처</h4>
                    <div className="formButton"
                      style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                      onClick={() => { setAccountModalStatus(true); }}
                    >
                      거래처 선택
                    </div>
                  </div>
                  <input
                    type="data"
                    name="accountId"
                    readOnly
                    data-required="required"
                    defaultValue={_account?.accountName}
                    placeholder=" 거래처를 선택해주세요."
                  />
                </div>

                <div className="formBox">
                  <h4>발주코드</h4>
                  <input
                    type="data"
                    name="procurementCode"
                    data-required="required"
                    placeholder="* 발주코드..."
                    defaultValue={_formData.procurementCode}
                    onChange={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>발주명</h4>
                  <input
                    type="text"
                    name="procurementName"
                    data-required="required"
                    placeholder="* 발주명..."
                    defaultValue={_formData.procurementName}
                    onChange={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>발주일자</h4>
                  <ProcurementDate
                    type="date"
                    name="procurementDate"
                    data-required="required"
                    defaultValue={_formData.procurementDate}
                    onChange={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>입고예정일자</h4>
                  <ProcurementDate
                    type="date"
                    name="scheduledEndDate"
                    defaultValue={_formData.scheduledEndDate}
                    onChange={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>발주금액</h4>
                  <input
                    type="text"
                    name="procurementPrice"
                    placeholder="* 발주금액..."
                    defaultValue={_formData.procurementPrice}
                    onInput={handleInputEvent}
                  />
                </div>

                <div className="formBox w100">
                  <h4>발주물품</h4>
                  <SelectButtonSection>
                    <SelectButton type="add" data-type="add" onClick={actSelectType}>엑셀 추가하기</SelectButton>
                    <SelectButton type="replace" data-type="replace" onClick={actSelectType}>엑셀 덮어쓰기</SelectButton>
                    <SelectButton type="update" data-type="update" onClick={actSelectType}>발주물품 수정</SelectButton>
                    <SelectButton type="cancel" data-type="cancel" onClick={actSelectType}>취소</SelectButton>
                  </SelectButtonSection>

                  {(_selectType === 'replace' || _selectType === 'add') ? (
                    <SelectSection className="formBox w100">
                      <h4>엑셀 {_selectType === 'replace' ? '덮어쓰기' : _selectType === 'add' ? '추가하기' : null} 업로드</h4>
                      <input
                        type="file"
                        id="procurementFileInput"
                        style={{ display: 'none' }}
                        onChange={setProcurementFile}
                      />
                      <FileLabelStyle htmlFor="procurementFileInput"
                        style={_selectType === 'replace' ? { backgroundColor: 'var(--Excel)' } : _selectType === 'add' ? { backgroundColor: 'var(--WorkingColor)' } : null}
                      >
                        업로드
                      </FileLabelStyle>
                      <FileTableSection
                        onClick={() => {
                          document.getElementById('procurementFileInput').value = '';
                          document.getElementById('procurementFileTable').innerHTML = '';
                          setFileProcurement([]);
                        }}
                      >
                        <TableSection content={<table id="procurementFileTable"></table>} />
                      </FileTableSection>

                      <div className="cautionText" style={{ fontSize: '14px', textAlign: 'right', width: '100%' }}>
                        {_selectType === 'replace' ? '※ 기존 발주물품의 모든 정보가 새 엑셀 데이터로 교체됩니다.' : _selectType === 'add' ? '※ 기존 발주물품에 새 엑셀 데이터가 추가됩니다.' : null}
                      </div>
                    </SelectSection>
                  ) : _selectType === 'update' ? (
                    <SelectSection className="formBox w100">
                      <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                        <h4>발주물품 수정</h4>
                        <div className="formButton"
                          style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                          onClick={() => { setModalStatus(true); }}
                        >
                          발주물품 추가
                        </div>
                      </div>

                      <TableFormbox>
                        <TableSection content={
                          <table>
                            <thead style={{ backgroundColor: 'var(--MainNavy' }}>
                              <tr>
                                <th>재질</th>
                                <th>규격</th>
                                <th>단위</th>
                                <th>길이</th>
                                <th>수량</th>
                                <th>단중</th>
                                <th>중량</th>
                                <th>비고</th>
                                <th style={{ minWidth: '50px', width: '50px' }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              {_formData.procurementElementList.map((procurementElement, index) => {
                                let thisLength = null;
                                let thisWeightByUnit = null;
                                let thisRemark = null;

                                const element = procurementElement.element;
                                if (checkNullObject(element, false)) {
                                  const elementAttributeValueList = procurementElement.element.elementAttributeValueList;
                                  checkNullArray(elementAttributeValueList, []).forEach((attr) => {
                                    switch (attr.elementAttribute.elementAttributeName) {
                                      case '길이': thisLength = attr.value; break;
                                      case '단중': thisWeightByUnit = attr.value; break;
                                      case '비고': thisRemark = attr.value; break;
                                      default: return;
                                    }
                                  });
                                }

                                return (
                                  <tr key={index + '_ElRow'} data-key={procurementElement.elementId}>
                                    <td data-col="재질">{element.elementName}</td>
                                    <td data-col="규격">{element.elementCode}</td>
                                    <td data-col="단위">{element.elementUnit}</td>
                                    <td data-col="길이">{thisLength}</td>
                                    <td data-col="수량">
                                      <input
                                        type="number"
                                        name="quantity"
                                        min={0}
                                        data-elementid={procurementElement.elementId}
                                        defaultValue={procurementElement.quantity}
                                        onInput={(e) => {
                                          e.preventDefault();
                                          const quantity = e.target.value;
                                          setFormData(prev => {
                                            const procurementElementList = [...prev.procurementElementList];
                                            const newData = { ...procurementElement, quantity: quantity }
                                            procurementElementList.splice(index, 1, newData);
                                            const returnData = {
                                              ...prev,
                                              procurementElementList: procurementElementList
                                            };
                                            return returnData;
                                          })
                                        }}
                                      />
                                    </td>
                                    <td data-col="단중">{thisWeightByUnit}</td>
                                    <td data-col="중량">
                                      <input
                                        type="text"
                                        name="weight"
                                        data-elementid={procurementElement.elementId}
                                        defaultValue={procurementElement.customizedContent?.weight}
                                        onInput={(e) => {
                                          e.preventDefault();
                                          const weight = e.target.value;
                                          setFormData(prev => {
                                            const procurementElementList = [...prev.procurementElementList];
                                            const newData = {
                                              ...procurementElement,
                                              customizedContent: { ...procurementElement.customizedContent, weight: weight }
                                            }
                                            procurementElementList.splice(index, 1, newData);
                                            const returnData = {
                                              ...prev,
                                              procurementElementList: procurementElementList
                                            };
                                            return returnData;
                                          })
                                        }}
                                      />
                                    </td>
                                    <td data-col="비고">{thisRemark}</td>
                                    <td style={{ minWidth: '50px', width: '50px' }}>
                                      <DelButton onClick={delStockProduct}><CloseButtonIcon /></DelButton>
                                    </td>
                                  </tr>
                                );
                              },
                              )}
                            </tbody>
                          </table>
                        }
                          formLayoutStyle={{ padding: '0px 30px', width: '95%' }}
                          formSectionStyle={{ paddingTop: '10px', width: '100%' }}
                        />
                      </TableFormbox>

                      <div className="cautionText" style={{ fontSize: '14px', textAlign: 'right', width: '100%' }}>※ 발주 수량과 발주금액을 꼭! 입력해 주세요. 두 값이 모두 0인 물품은 발주물품에 추가가 되지 않습니다.</div>
                    </SelectSection>
                  ) : null}
                </div>
              </>
            }
            formSectionStyle={{ paddingTop: '10px', width: '100%' }}
          />
        </main>

        {/* 경서 커스텀 */}
        {_modalStatus === true ? (
          <GsSelectElements
            buttonEvent={(data) => {
              console.log('data : ', data);
              const procruementElementIds = checkEmptyNull(_formData.procurementElementList, []).map((thisItem) => thisItem.elementId);
              const newElements = data.map((element) => {
                if (procruementElementIds.indexOf(element.elementId) !== -1) return null;
                else return {
                  ...element,

                  element: element,
                  elementId: element.elementId,
                  elementCode: element.elementCode,
                  elementName: element.elementName,

                  customizedContent: { num: '', weight: '' },

                  price: 0,
                  quantity: 0,

                  storeQuantity: 0,
                };
              });
              const setNewElements = newElements.filter((thisItem) => thisItem !== null);
              setFormData((prev) => {
                const returnData = { ...prev, procurementElementList: [...prev.procurementElementList, ...setNewElements] }
                return returnData;
              });
              setTimeout(setModalStatus(false), 1000);
            }}
            buttonTitle="물품 선택"
            open={_modalStatus}
            close={() => { setModalStatus(false) }}
          />
        ) : null}

        {_accountModalStatus === true ? (
          <SelectOneAccount
            buttonTitle={'거래처 선택'}
            buttonEvent={(data) => {
              const accountId = data.accountId;
              setAccount(data);
              setFormData(prev => { return { ...prev, accountId: accountId } });
              setTimeout(setAccountModalStatus(false), 1000);
            }}
            open={_accountModalStatus}
            close={() => { setAccountModalStatus(false); }}
          ></SelectOneAccount>
        ) : null}
      </>
    }
    />
  );
};

export default UpdateGSProcurement;
