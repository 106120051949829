import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { MallSnb } from 'mall/components/MallSnb';

const SubMenus = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-around;
  margin: 0px auto;
  max-width: 1200px;
  width: 100%;
`;
const SubMenu = styled.div`
  color: var(--gray-200);
  cursor: pointer;
  &.active {
    border-bottom: 3px solid;
    color: var(--gray-800);
    font-weight: 600;
  }
  &:hover {border-bottom: 3px solid;}
`;

const MallMypage = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const companyCode = location.pathname.split('/')[1];

  useEffect(() => { }, []);

  return (
    <>
      <div className='mypage-info'>
        <SubMenus>
          {MallSnb.mypage.map((menu, index) => {
            return (
              <SubMenu key={index + '_menu_sub'}
                className={`/${companyCode + menu.route}` === location.pathname ? 'active' : null}
                onClick={() => { navigate(`/${companyCode + menu.route}`, { replace: true }); }}
              >
                {menu.menuName}
              </SubMenu>
            );
          })}
        </SubMenus>
      </div>
      <Outlet {...children} />
    </>
  );
};

export default MallMypage;
