import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';

import {
  gsReducer_setDeleteOrder,
  gsReducer_setInspectionContent,
} from 'store/modules/actions/company/GSActions';
import {
  pageReducer_getCurrentPage,
  pageReducer_setPageNum,
  pageReducer_setPageSize,
  pageReducer_setTotalCount,
} from 'store/modules/actions/default/pageActions';

import {
  AXIOS_BASE_HEADERS,
  AXIOS_BASE_URL,
} from 'preferences/server/constants';

import CheckBox from 'components/checkbox/CheckBox';
import Grid3Body from 'components/layouts/body/Grid3Body';
import Nav from 'components/nav/Nav';
import Paging from 'components/paging/Paging';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';
import { LoadingMsg } from 'components/loading/LoadingMsg'

import GSInspectionModal from 'pages/incomingInspection/custom/company/gs/modal/GSInspectionModal';

const StockTable = styled.table`
  tr {
    border: none;
  }

  td,
  th {
    min-width: 100px;
  }

  thead tr:nth-child(2) th {
    height: 40px;
  }

  tbody tr {
    border-bottom: 1px solid var(--MainNavy);
  }
`;

const InspectionButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  border: 4px solid var(--white);
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: inherit;
  justify-content: center;
  min-height: 100px;
  width: 100%;
`;

const GSIncomingInspection = () => {
  /* ============================================================================================ 라이브 관련 함수들 */
  const { pageReducer, searchReducer, userReducer } = useSelector(
    (state) => state,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* ============================================================================================ #2 */
  const [_onload, setOnload] = useState('unload');

  let rowCount = 0;
  // const moreAlertText = `다중 선택되어 있습니다.`;

  const [_importInspectionList, setImportInspectionList] = useState([]);
  console.log('_importInspectionList : ', _importInspectionList);

  const [_selectedImportInspection, setSelectedImportInspection] = useState({});

  /* 모달 */
  const [_modalStatus, setModalStatus] = useState(false);

  const [_authority, setAuthority] = useState([]);
  console.log('_authority : ', _authority);

  /* ============================================================================================ #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter(
      (thisItem) => thisItem.authority.startsWith('603') === true,
    );
    const authorityCodes = authorityContent.map(
      (thisItem) => thisItem.authority,
    );
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    setIncomingInspectionTable();

    setOnload('loaded');

    return () => {};
  }, []);

  useEffect(() => {
    dispatch(pageReducer_setPageNum(1));
    dispatch(pageReducer_setPageSize(10));

    return () => {};
  }, [pageReducer.currentPage]);

  useEffect(() => {
    if (_onload === 'loaded') {
      setIncomingInspectionTable();
    }

    return () => {};
  }, [pageReducer.currentPageNum, pageReducer.totalCount]);

  useEffect(() => {
    if (_onload === 'loaded') {
      if (searchReducer.searchOption === '') {
        setIncomingInspectionTable();
      }
    }

    return () => {};
  }, [searchReducer.searchOption]);

  /* ============================================================================================ #4 */
  const setIncomingInspectionTable = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    if (searchReducer.searchOption !== '') {
      BodyToPost[searchReducer.searchOption] = searchReducer.searchText;
    }
    console.log('setIncomingInspectionTable-body : ', BodyToPost);

    const paging = `?page=${pageReducer.currentPageNum - 1}&size=${
      pageReducer.pageSize
    }&sort=id,DESC`;
    console.log(paging, pageReducer.currentPageNum);

    await axios
      .post(
        AXIOS_BASE_URL + '/importInspection/search' + paging,
        BodyToPost,
        AXIOS_BASE_HEADERS,
      )
      .then((response) => {
        if (response === undefined) return;
        console.log('post/importInspection - response :', response);
        // if(response.data.content.length === 0){
        //   alert('검색 결과가 없습니다. 조건을 다시 한번 확인 해주세요.')
        // }
        setImportInspectionList(response.data.content);

        dispatch(pageReducer_setTotalCount(response.data.totalElements));
      })
      .catch((error) => {
        console.log('post/importInspection - error :', error);
      });
  };

  /* ============================================================================================ #5 */
  // 검색
  const actSearch = async () => {
    dispatch(pageReducer_setPageNum(1));
    dispatch(pageReducer_setPageSize(10));

    await setIncomingInspectionTable();
  };

  // 조도측정 팝업
  const actInspection = (e) => {
    const thisImportInspectionId = e.target.closest('tr').dataset.key;
    const thisImportInspectionIndex = _importInspectionList.findIndex(
      (ThisData) =>
        ThisData.importInspectionId === Number(thisImportInspectionId),
    );
    const thisImportInspection =
      _importInspectionList[thisImportInspectionIndex];
    console.log(
      'actInspection - thisImportInspection : ',
      thisImportInspection,
    );
    setSelectedImportInspection(thisImportInspection);
    dispatch(gsReducer_setInspectionContent(thisImportInspection));

    setModalStatus(true);
  };

  // 삭제
  const actDelete = () => {
    const moreAlertText = `다중 선택되어 있습니다.`;

    if (
      document.querySelectorAll('input[name="Inspections"]:checked').length < 1
    )
      return alert('삭제 할 검사를 선택해주세요.');
    if (
      document.querySelectorAll('input[name="Inspections"]:checked').length > 1
    )
      return alert(moreAlertText);

    const CheckedBox = document.querySelector(
      'input[name="Inspections"]:checked',
    );
    const CheckedRow = CheckedBox.parentNode.parentNode;
    console.log('삭제할 검사 : ', CheckedRow);

    const thisRowId = CheckedRow.dataset.key;
    const thisEventIndex = _importInspectionList.findIndex(
      (ThisData) => ThisData.importInspectionId === Number(thisRowId),
    );
    if (thisEventIndex === -1) return;

    dispatch(gsReducer_setDeleteOrder(_importInspectionList[thisEventIndex]));
    console.log('actDelete : ', _importInspectionList[thisEventIndex]);

    navigate(pageReducer.currentPage + '/delete', { replace: true });
  };

  /* ============================================================================================ #6 */

  return (
    <Grid3Body
      contents={
        <>
          <main className="Main">
            <Nav
              thisTitle={'조도측정'}
              content={
                <>
                  {_authority.indexOf('603-2') !== -1 ? (
                    <>
                      <button
                        className='btn-add'
                        onClick={() => {
                          navigate(pageReducer.currentPage + '/create', {
                            replace: true,
                          });
                        }}
                      >
                        추가
                      </button>
                      {/* <button onClick={ actUpdate }>수정</button> */}
                      <button className='btn-delete' onClick={actDelete}>삭제</button>
                    </>
                  ) : null}
                </>
              }
              nav={'search'}
              search={''}
              searchButton={
                <button id="searchButton" onClick={actSearch}>
                  <SearchButtonIcon />
                </button>
              }
              statusOptions={[]}
              searchOptions={[
                { optionValue: 'importInspectionCode', optionText: '검사코드' },
                { optionValue: 'importInspectionName', optionText: '검사명' },
              ]}
            />

            <TableSection
              content={
                <StockTable>
                  <thead>
                    <tr>
                      <th style={{ minWidth: '50px', width: '50px' }}></th>
                      <th>검사코드</th>
                      <th>검사명</th>
                      <th>비고</th>
                      <th style={{ minWidth: '180px', width: '180px' }}></th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                    _importInspectionList.length == 0 ? LoadingMsg() :
                    _importInspectionList !== undefined &&
                      _importInspectionList.length > 0 &&
                      _importInspectionList.map((thisList) => {
                        rowCount++;
                        console.log(thisList);
                        let customizedContent = thisList.customizedContent;
                        console.log('customizedContent : ', customizedContent);
                        if (
                          thisList.customizedContent.startsWith(`{`) === true &&
                          thisList.customizedContent.endsWith(`}`) === true
                        ) {
                          customizedContent = JSON.parse(
                            thisList.customizedContent,
                          );
                        }

                        return (
                          <tr
                            key={rowCount + '_Inspections'}
                            data-key={thisList.importInspectionId}
                          >
                            <td style={{ minWidth: '50px', width: '50px' }}>
                              <CheckBox
                                act={'one'}
                                all={false}
                                name={'Inspections'}
                              />
                            </td>
                            <td>{thisList.importInspectionCode}</td>
                            <td>{thisList.importInspectionName}</td>
                            <td>{customizedContent.remark || ''}</td>
                            <td style={{ minWidth: '180px', width: '180px' }}>
                              <InspectionButton onClick={actInspection}>
                                조도측정
                              </InspectionButton>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </StockTable>
              }
            ></TableSection>
          </main>

          <Paging
            page={pageReducer.currentPageNum}
            count={pageReducer.totalCount}
            size={pageReducer.pageSize}
          />

          {/* 모달 설정 */}
          {_modalStatus === true ? (
            <GSInspectionModal
              content={_selectedImportInspection}
              open={_modalStatus}
              close={() => {
                setModalStatus(false);
                setIncomingInspectionTable();
              }}
            />
          ) : null}
        </>
      }
    />
  );
};

export default GSIncomingInspection;
