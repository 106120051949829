import styled from "styled-components";

export const FolderComponentStyle = styled.div`
  background-color: var(--white);
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 50px 50px calc(100% - 110px);
  height: 100%;
  overflow: hidden;
  padding: 0px 10px 10px 10px;
  /* row-gap: 5px; */
  width: 100%;

  &>div:nth-of-type(1) { // 회사 폴더 정보
    align-items: center;
    background-color: var(--MainBlue);
    color: var(--white);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding-inline: 10px;
  }

  &>div:nth-of-type(2) { // 현재 폴더
    align-items: center;
    background-color: var(--MainNavy);
    box-sizing: border-box;
    color: var(--white);
    display: flex;
    height: 100%;
    justify-content: flex-start;
    padding-inline: 10px;
    position: relative;
    width: 100%;
    z-index: 1;

    & p { // 폴더 경로
      font-weight: 300;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90%;
      word-break: keep-all;
    }
    & button { // 설정 버튼
      cursor: pointer;
      font-size: 1.3em;
      font-weight: 600;
      height: fit-content;
      position: absolute;
      right: 15px;
      width: fit-content;
    }

    & .act-content { // 폴더 추가/삭제, 폴더 정보 수정
      background-color: var(--Bg);
      border-radius: 8px;
      box-shadow: 0 2px 6px 0 rgba(196, 196, 196, 0.8);
      box-sizing: border-box;
      color: var(--Text);
      height: fit-content;
      overflow: hidden;
      position: absolute;
      top: calc(50% + 30px);
      right: 20px;
      width: fit-content;
      z-index: 2;

      & div {
        align-items: center;
        cursor: pointer;
        display: flex;
        font-size: 0.8em;
        height: 50px;
        justify-content: center;
        width: 120px;

        &:hover {opacity: 0.6;}
        &:nth-of-type(2) { // 수정
          border-block: 1px solid #eee;
        }
        &:nth-of-type(3) { // 삭제
          color: var(--MainRed);
        }
      }
      & p { // 현재 폴더 명
        border-block-end: 1px solid #eee;
        box-sizing: border-box;
        cursor: default;
        font-size: 0.8em;
        height: 50px;
        line-height: 50px;
        overflow: hidden;
        padding-inline: 5px;
        text-align: center;
        text-overflow: ellipsis;
        word-break: keep-all;
        width: 120px;
        
        &:hover {opacity: unset;}
      }
    }
  }


  &>div:nth-of-type(3) { // 폴더 리스트
    align-content: flex-start;
    display: grid;
    height: 100%;
    overflow-y: scroll;
    position: relative;
    width: 100%;
    z-index: 0;

    &::-webkit-scrollbar-thumb {background-color: var(--gray-200);}
    &::-webkit-scrollbar-track {background-color: var(--Bg);}

    &>div {
      height: fit-content;
      overflow: hidden;
      width: 100%;
    }
  }
`;