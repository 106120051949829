import styled from "styled-components";

export const ClusterContentModalComponent = styled.div`
  background-color: var(--white);
  display: grid;
  gap: 50px;
  grid-template-rows: 100px calc(100% - 150px);
  height: 100%;
  justify-items: center;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 11;

  &>header {
    align-items: flex-end;
    display: flex;
    height: 100px;
    justify-content: space-between;
    max-width: 1240px;
    width: 100%;

    & h1 { // 타이틀
      font-size: 35px;
    }

    & .btn-close { // 닫기 버튼
      height: 60px;
      width: 30px;

      &:hover {background: unset;}

      &>svg {
        height: 30px;
        width: 30px;
      }
    }
  }

  &>div {
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
    max-width: 1240px;
    position: relative;
    width: 100%;    
    
    &>section {
      align-content: flex-start;
      display: grid;
      gap: 50px;
      height: 100%;
      overflow-y: auto;
      width: 100%;

      & .section-form { // 입력 폼 섹션
        align-content: flex-start;
        /* border: 1px solid #C6C6C6; */
        border-radius: 12px;
        box-sizing: border-box;
        gap: 20px;
        display: grid;
        height: fit-content;
        /* padding: 50px; */
        padding-block: 30px;

        &>h2 { // 폼 타이틀
          padding-block-end: 10px;
        }

        & .form-content { // 폼 컨텐츠
          align-content: flex-start;
          display: grid;
          gap: 10px;

          & h4 { // 인풋 이름
            font-weight: 400;
          }
          
          & p, & input, & textarea { // 텍스트 인풋 스타일
            background-color: var(--white);
            border: 1px solid #C6C6C6;
            border-radius: 8px;
            padding: 16px;
            max-width: 60%;

            &.date {
              height: 60px;
              width: 200px;
            }
          }
          & textarea {
            height: 400px;
            max-width: 100%;
            resize: none;
            &:focus {outline: none;}
          }

          &.term>div{
            align-items: center;
            display: flex;
            gap: 15px;
            justify-content: flex-start;
          }
        }
      }
    }
  }
  
  &.common { // 공동 구매, 상호 거래
    &>div>section {
      gap: 10px;
      &>nav {
        align-items: center;
        background-color: var(--white);
        display: flex;
        gap: 10px;
        height: fit-content;
        justify-content: flex-start;
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 1;
    
        & button {
          border-block-end: 1px solid #d8d8d8;
          font-size: 20px;
          font-weight: 600;
          height: 40px;
          width: 200px;

          &:hover {background: unset;}
          &.active {
            /* background-color: #edf1f5; */
            border-block-end: 3px solid #003675;
            color: #003675;
          }
        }
      }

      & .list {
        box-sizing: border-box;
        display: grid;
        /* grid-template-columns: repeat(auto-fit, minmax(45%, 1fr)); */
        gap: 20px;
        padding: 30px;
        width: 100%;
      }
    }
  }
`;

export const ItemBox = styled.div`
  box-sizing: border-box;
  border: 1px solid #C6C6C6;
  border-radius: 12px;
  display: grid;
  gap: 10px;
  height: fit-content;
  padding-block: 24px 48px;
  padding-inline: 24px;
  position: relative;
  width: 100%;

  & .item-header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    & h3 {
      font-weight: normal;
    }
    & p {
      /* color: #003675; */
      font-size: 15px;

      & span {
        font-size: 22px;
        font-weight: 600;
      }
    }
  }

  & .item-price {
    line-height: 24px;

    & p {
      &.discountInfo {
        color: #768695;
        font-size: 15px;
        & span:first-of-type {color: #ae0000;}
        & span:last-of-type {
          text-decoration: line-through;
        }
      }
      &.cal {
        color: #ae0000;
        font-size: 22px;
        font-weight: 600;
      }
      &.price {
        color: #003675;
        font-size: 22px;
        font-weight: 600;
      }
    }
  }

  & .detail {
    font-size: 15px;
    white-space: pre-wrap;
  }
  & .comment {
    align-items: center;
    bottom: 20px;
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    position: absolute;
    right: 24px;

    &>button {
      background-color: #246BEB;
      border-radius: 8px;
      box-sizing: border-box;
      color: var(--white);
      height: fit-content;
      padding: 10px 16px;
      width: fit-content;
    }
  }
`;
export const CommentButton = styled.div`
  align-items: center;
  /* color: #003675; */
  cursor: pointer;
  display: flex;
  gap: 5px;
  height: 24px;
  justify-content: center;
  width: fit-content;

  &:hover {opacity: 0.7;}
  &>svg {height: 24px; width: 24px;}
`;