import { AxiosInterceptor } from "api/core/consts";

export const contractTypeApi = {
  searchContractType: (body) =>
    AxiosInterceptor.post('contractType/search', body),
  createContractType: (body) => AxiosInterceptor.post('contractType', body),
  updateContractType: (contractTypeId, body) =>
    AxiosInterceptor.put('contractType/' + contractTypeId, body),
  deleteContractType: (contractTypeId) =>
    AxiosInterceptor.delete('contractType/' + contractTypeId),
};
