import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { barcodeActions_setPrintList } from 'store/modules/actions/common/barcodeActions';

import { elementAttributeApi } from 'api/apis/elementAttributeApi';
import { elementApi } from 'api/apis/elementApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { CategoryLevel1, CategoryLevel2, CategoryLevel3 } from 'components/category/Category';

import Bookmark from 'components/icons/Bookmark';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ElementSection = styled.main`
  display: grid;
  grid-template-rows: auto minmax(calc(100% - 276px), calc(100% - 66px)) 60px;
  height: 100%;
  overflow: hidden;
  width: 100svw;

  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    h4 {
      padding-right: 20px;
      margin-right: 10px;
      border-right: 1px solid #ddd;
      white-space: pre;
    }
    & .elementTypeButtons {
      display: flex;
      gap: 5px;
    }
  }

  & .deleteButton {
    height: 24px;
    width: 24px;
  
    div {background-color: var(--white); cursor: pointer; height: 15px; width: 15px;}
  }

  & .TableSection {
    /* height: calc(100% - 66px); */
    overflow: scroll;
  }

  & div[name='bookmark'] {
    cursor: default;
    &:hover {transform: scale(1);}
  }
`;

const SearchAttrs = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  max-width: 80%;
  /* width: calc(100% - 450px); */
`;
const SearchAttr = styled.div`
  align-items: center;
  column-gap: 10px;
  display: flex;
  height: unset;
  padding: 6px 20px;
  justify-content: center;
  width: fit-content;

  & p {width: calc(100% - 20px); word-break: break-all;}
`;


const Element = (props) => {
  const dispatch = useDispatch();
  const { barcodeReducer, userReducer } = useSelector(state => state);

  const { printList } = props;

  const ElementCheckBox = useRef();

  const [_onload, setOnload] = useState('unload');

  const [_elementType, setElementType] = useState('all');

  const [_elementAttrList, setElementAttrList] = useState([]);
  const [_elementAttrNameList, setElementAttrNameList] = useState({});

  const [_bookmark, setBookmark] = useState(true);
  const [_elementList, setElementList] = useState([]);

  const [_clickedCategory, setClickedCategory] = useState({});
  const [_clickedLevel1Category, setClickedLevel1Category] = useState({});
  const [_clickedLevel2Category, setClickedLevel2Category] = useState({});
  const [_clickedLevel3Category, setClickedLevel3Category] = useState({});

  const [_searchElementData, setSearchElementData] = useState({});

  const [_detailStatus, setDetailStatus] = useState(false);
  const [_searchElementAttrs, setSearchElementAttrs] = useState({});
  const [_searchOption, setSearchOption] = useState('');
  const [_searchText, setSearchText] = useState('');

  const [_pageNum, setPageNum] = useState(1);
  const [_totalSize, setTotalSize] = useState(0);

  useEffect(() => {
    getElementAttrs();
    getElementList(0, 'all');
    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (!checkNullArray(printList, false)) ElementCheckBox.current.checked = false;
  }, [printList])
  useEffect(() => { }, [_elementList])

  useEffect(() => {
    if (_onload === 'loaded') {
      getElementList(_pageNum - 1, _elementType);
      ElementCheckBox.current.checked = false;
    }
    return () => { };
  }, [_pageNum]);

  /* 북마크 온오프 변경 */
  useEffect(() => {
    if (_onload === 'loaded') {
      setPageNum(() => { return 1 });
      getElementList(0, _elementType);
    }
    return () => { };
  }, [_bookmark]);

  /* 패밀리 변경 */
  useEffect(() => {
    if (_onload === 'loaded') {
      if (_clickedCategory === 'all') {
        setPageNum(() => { return 1 });
        getElementList(0, _elementType);
      } else if (checkNullObject(_clickedCategory, false)) {
        setPageNum(() => { return 1 });
        getElementList(0, _elementType);
      }
    }
    return () => { };
  }, [_clickedCategory]);

  useEffect(() => {
    if (_onload === 'loaded') {
      if (_clickedCategory === 'all') {
        setClickedLevel2Category(() => { return {} });
      } else if (checkNullObject(_clickedCategory, false)) {
        setClickedLevel2Category(() => { return {} });
      }
    }
    return () => { };
  }, [_clickedLevel1Category]);
  useEffect(() => {
    if (_onload === 'loaded') {
      if (_clickedCategory === 'all') {
        setClickedLevel3Category(() => { return {} });
      } else if (checkNullObject(_clickedCategory, false)) {
        setClickedLevel3Category(() => { return {} });
      }
    }
    return () => { };
  }, [_clickedLevel2Category]);
  useEffect(() => { }, [_clickedLevel3Category]);

  /* 물품 항목 */
  const getElementAttrs = async (elementType) => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (checkEmptyNull(elementType, false) && elementType !== 'all') {
      BodyToPost.elementType = elementType;
    }

    await elementAttributeApi.searchElementAttribute(BodyToPost).then((response) => {
      if (response === undefined) return;
      setElementAttrList(() => { return response.data });
      const nameList = {};
      response.data.forEach((thisItem) => {
        nameList[thisItem.elementAttributeId] = thisItem.elementAttributeName;
      });
      setElementAttrNameList(() => { return nameList });
    });
  };

  /* 물품 리스트 */
  const getElementList = async (page, elementType, clear) => {
    let paging = `?page=${page}&size=10`;
    if (_bookmark) paging += '&sort=bookMark,DESC&sort=id,DESC';
    else paging += '&sort=id,DESC';

    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && checkEmptyNull(elementType, false) && elementType !== 'all') {
      BodyToPost.elementType = elementType;
    }
    if (!clear && checkEmptyNull(_clickedCategory, false) && _clickedCategory !== 'all') {
      BodyToPost.categoryId = _clickedCategory.categoryId;
    }
    if (!clear && checkEmptyNull(_searchElementData.elementName, false)) {
      BodyToPost.elementName = _searchElementData.elementName;
    }
    if (!clear && checkEmptyNull(_searchElementData.elementCode, false)) {
      BodyToPost.elementCode = _searchElementData.elementCode;
    }
    if (!clear && _detailStatus && checkNullObject(_searchElementAttrs, false)) {
      BodyToPost.attributeAndValue = _searchElementAttrs;
    }
    await elementApi.searchElement(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      setElementList(() => { return response.data.content });
      setTotalSize(() => { return response.data.totalElements });
    });
  };

  /* 물품유형 변경 */
  const handleElementType = (elementType) => {
    setElementType(() => { return elementType });

    if (elementType === 'all') {
      setClickedCategory(() => { return {} });
      setClickedLevel1Category(() => { return {} });
      setClickedLevel2Category(() => { return {} });
      setClickedLevel3Category(() => { return {} });
    }

    setPageNum(() => { return 1 });
    getElementList(0, elementType);
  }

  /* 검색 */
  const actSearch = async () => {
    setPageNum(() => { return 1 });
    await getElementList(0, _elementType);
  };

  /* 상세 검색 */
  const addSearchAttr = async () => {
    if (!checkEmptyNull(_searchOption, false) || !checkEmptyNull(_searchText, false)) return;
    setSearchElementAttrs((prev) => { return { ...prev, [_searchOption]: _searchText } });
  };

  /* 초기화 */
  const actReset = async () => {
    setElementType(() => { return 'all' });

    setClickedCategory(() => { return {} });
    setClickedLevel1Category(() => { return {} });
    setClickedLevel2Category(() => { return {} });
    setClickedLevel3Category(() => { return {} });

    setSearchElementData(() => { return {} });

    setSearchElementAttrs(() => { return {} });
    setSearchOption(() => { return '' });
    setSearchText(() => { return '' });

    setPageNum(() => { return 1 });

    await getElementAttrs();
    await getElementList(0, 'all', true);
  };

  /* 물품 전체 선택 */
  const handleAllCheckBox = (e) => {
    const isChecked = e.target.checked;
    const printList = [...barcodeReducer.printList];
    const newData = _elementList.map((element) => {
      const thisFindIndex = printList.findIndex((item) => item.elementId === element.elementId);
      if (thisFindIndex !== -1) return null;
      else return element;
    });
    const filterData = newData.filter((data) => data !== null);
    const returnData = filterData.map(element => { return { ...element, type: 'element' } })

    if (isChecked) {
      dispatch(barcodeActions_setPrintList([...printList, ...returnData]))
      e.target.checked = true;
    } else {
      dispatch(barcodeActions_setPrintList([...returnData]))
      e.target.checked = false;
    }
  }

  /* 물품 선택 */
  const handleCheckedItem = (element, isChecked) => {
    const checkedItem = [...barcodeReducer.printList];
    // const checkedIndex = checkedItem.findIndex((content) => content.elementId === element.elementId);
    const checkedIndex = checkedItem.findIndex((content) => content.type === 'element' && content.elementId === element.elementId);
    if (checkedIndex !== -1) {
      if (!isChecked) checkedItem.splice(checkedIndex, 1);
    } else {
      if (isChecked) checkedItem.push({ ...element, type: 'element' });
    }
    dispatch(barcodeActions_setPrintList(checkedItem))
  };


  return (
    <ElementSection>
      <NavBar
        nav={!_detailStatus ? 'all' : 'details'}
        title={
          <div className='Title'>
            <h4>기준정보</h4>
            {/* <NavTitle menuCode={'101'} /> */}
            <div className='elementTypeButtons'>
              {userReducer.elementType.map((elementType, index) => {
                let typeText = '';
                switch (elementType) {
                  case 'material': typeText = '자재'; break;
                  case 'semi': typeText = '부자재'; break;
                  case 'half': typeText = '반제품'; break;
                  case 'product': typeText = '완제품'; break;
                  case 'tool': typeText = '공구'; break;

                  default: return null;
                }
                return (<button key={index + '_elementTypeButtons'} className={_elementType === elementType ? 'btn-inven-1' : 'btn-set'} onClick={() => { handleElementType(elementType) }}>{typeText}</button>);
              })}
              <button className={_elementType === 'all' ? 'btn-inven-1' : 'btn-set'} onClick={() => { handleElementType('all') }}>전체</button>
            </div>
          </div>
        }
        firstRow={
          <>
            <div className="SearchSection">
              <CategoryLevel1
                clickedLevel1Category={_clickedLevel1Category}
                setClickedCategory={setClickedCategory}
                setClickedLevel1Category={setClickedLevel1Category}
              ></CategoryLevel1>

              <IconRightArrow />

              <CategoryLevel2
                clickedLevel1Category={_clickedLevel1Category}
                clickedLevel2Category={_clickedLevel2Category}
                setClickedCategory={setClickedCategory}
                setClickedLevel2Category={setClickedLevel2Category}
              ></CategoryLevel2>

              <IconRightArrow />

              <CategoryLevel3
                clickedLevel2Category={_clickedLevel2Category}
                clickedLevel3Category={_clickedLevel3Category}
                setClickedCategory={setClickedCategory}
                setClickedLevel3Category={setClickedLevel3Category}
              ></CategoryLevel3>
            </div>
          </>
        }
        secondRow={
          <>
            <div className="SearchSection">
              <div className="ContentCBox">
                <select className="SearchOption detail"><option value="elementName">물품이름</option></select>
                <input
                  className="SearchBar detail"
                  placeholder="Search..."
                  value={checkEmptyNull(_searchElementData.elementName, '')}
                  onInput={(e) => { setSearchElementData((prev) => { return { ...prev, elementName: e.target.value } }) }}
                />
              </div>

              <div className="ContentCBox">
                <select className="SearchOption detail"><option value="elementCode">물품코드</option></select>
                <input
                  className="SearchBar detail"
                  placeholder="Search..."
                  value={checkEmptyNull(_searchElementData.elementCode, '')}
                  onInput={(e) => { setSearchElementData((prev) => { return { ...prev, elementCode: e.target.value } }) }}
                />
              </div>

              <div className="ContentCBox">
                <div className="ResetButton detail" onClick={actReset}>초기화</div>

                <div className={`DetailButton ${!_detailStatus && 'active'}`}
                  onClick={() => { setDetailStatus((prev) => { return !prev }) }}
                >
                  상세 검색
                </div>

                <div className="DetailButton search"
                  style={{ backgroundColor: 'var(--ThirdBlue)', columnGap: '3px' }}
                  onClick={actSearch}
                >
                  <SearchButtonIcon />
                  검색
                </div>
              </div>
            </div>
          </>
        }
        thirdRow={
          <>
            {_detailStatus && <div className="SearchSection">
              <div className="ContentCBox">
                <div className="ContentCBox">
                  <select
                    className="SearchOption"
                    value={_searchOption}
                    onChange={(e) => { setSearchOption(() => { return e.target.value }) }}
                  >
                    <option value="">항목선택</option>
                    {_elementAttrList.map((thisItem) => {
                      return (
                        <option key={thisItem.elementAttributeId + '_searchOptionAttrs'} value={thisItem.elementAttributeId}>
                          {thisItem.elementAttributeName}
                        </option>
                      );
                    })}
                  </select>
                  <input
                    className="SearchBar"
                    placeholder="Search..."
                    value={_searchText}
                    onInput={(e) => { setSearchText(() => { return e.target.value }) }}
                  />
                </div>

                <button data-searchbutton="true" onClick={addSearchAttr}>
                  <div style={{ fontSize: '30px', lineHeight: '35px' }}>+</div>
                </button>
              </div>

              <SearchAttrs>
                {Object.keys(_searchElementAttrs).map((thisKey) => {
                  return (
                    <SearchAttr key={thisKey + '_searchAttr'}
                      className="formButton"
                      onClick={(e) => {
                        setSearchElementAttrs((prev) => {
                          const prevData = { ...prev };
                          delete prevData[thisKey];
                          return prevData;
                        });
                      }}
                    >
                      <p>{`${_elementAttrNameList[thisKey]}: ${_searchElementAttrs[thisKey]}`}</p>
                      <div className='deleteButton'><CloseButtonIcon /></div>
                    </SearchAttr>
                  );
                })}
              </SearchAttrs>
            </div>}
          </>
        }
      />

      <TableSection content={
        <table>
          <thead>
            <tr>
              <th style={{ minWidth: '50px', width: '50px' }}>
                <input
                  type="checkBox"
                  ref={ElementCheckBox}
                  name="alllCheckBox"
                  onChange={handleAllCheckBox}
                />
              </th>
              <th style={{ minWidth: '50px', width: '50px' }}>
                <Bookmark bookmark={_bookmark} clickEvent={() => { setBookmark(prev => { return !prev }) }} />
              </th>
              <th>패밀리</th>
              <th>물품이름</th>
              <th>물품코드</th>
              <th>단위</th>
              <th>안전재고</th>
              <th>단가</th>
              {_elementAttrList.map((attr) => (<th key={attr.elementAttributeId + '_elementAttrs'}>{attr.elementAttributeName}</th>))}
            </tr>
          </thead>
          <tbody>
            {_elementList.map((element, index) => {
              return (
                <tr key={index + '_elements'}>
                  <td style={{ minWidth: '50px', width: '50px' }}>
                    <input
                      type="checkBox"
                      name="elements"
                      data-key={element.elementId}
                      checked={(() => {
                        const checkedId = checkNullArray(barcodeReducer.printList, []).findIndex((content) => content.type === 'element' && content.elementId === element.elementId);
                        if (checkedId !== -1) return true;
                        else return false;
                      })()}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        handleCheckedItem(element, isChecked);
                      }}
                      onClick={(e) => { e.stopPropagation() }}
                    />
                  </td>
                  <td style={{ minWidth: '50px', width: '50px' }}>
                    <Bookmark bookmark={checkEmptyNull(element.bookMark, false)} clickEvent={() => { }} />
                  </td>
                  <td>{element.categoryPath}</td>
                  <td className={checkEmptyNull(element.elementName, false) && element.elementName.startsWith('*') ? 'outsourcingText' : null}>
                    {element.elementName}
                  </td>
                  <td>{element.elementCode}</td>
                  <td>{element.elementUnit}</td>
                  <td>{checkEmptyNull(element.elementSafeStock, 0).toLocaleString() + ' ' + checkEmptyNull(element.elementUnit, '')}</td>
                  <td>{checkEmptyNull(element.price, 0).toLocaleString()}</td>
                  {checkNullArray(element.elementAttributeValueList, false) && checkNullArray(_elementAttrList, []).map((thisAttribute) => {
                    const thisAttributeValue = element.elementAttributeValueList.findIndex((element) => element.elementAttribute.elementAttributeId === thisAttribute.elementAttributeId);
                    if (thisAttributeValue !== -1) {
                      return (
                        <td key={element.elementId + '_elementAttr_' + element.elementAttributeValueList[thisAttributeValue].elementAttribute.elementAttributeId}>
                          {element.elementAttributeValueList[thisAttributeValue].value}
                        </td>
                      );
                    } else {
                      return (<td key={element.elementId + '_elementAttr_' + thisAttribute.elementAttributeId}></td>);
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      }
        sectionStyle={{ height: '100%' }}
      />

      <PagingComponent
        page={_pageNum}
        count={_totalSize}
        size={10}
        pageEvent={(page) => {
          console.log('page : ', page);
          setPageNum(() => { return page; });
        }}
      />
    </ElementSection>
  );
};

export default Element;