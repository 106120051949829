export const notInStockActions_setNotInStockStatus = (status) => {
  return {
    type: 'SETNOTINSTOCKSTATUS',
    payload: status,
  };
};

export const notInStockActions_setStartDate = (startDate) => {
  return {
    type: 'SETNOTINSTOCKSTARTDATE',
    payload: startDate,
  };
};

export const notInStockActions_setEndDate = (endDate) => {
  return {
    type: 'SETNOTINSTOCKENDDATE',
    payload: endDate,
  };
};

export const notInStockActions_setSearchOption = (searchOption) => {
  return {
    type: 'SETNOTINSTOCKSEARCHOPTION',
    payload: searchOption,
  };
};

export const notInStockActions_setSearchText = (searchText) => {
  return {
    type: 'SETNOTINSTOCKSEARCHTEXT',
    payload: searchText,
  };
};

export const notInStockActions_setPageNumber = (pageNumber) => {
  return {
    type: 'SETNOTINSTOCKPAGENUMBER',
    payload: pageNumber,
  };
};

export const notInStockActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETNOTINSTOCKTOTALSIZE',
    payload: totalSize,
  };
};

export const notInStockActions_setIsPushedSearchButton = (
  isPushedSearchButton,
) => {
  return {
    type: 'SETNOTINSTOCKISPUSHEDSEARCHBUTTON',
    payload: isPushedSearchButton,
  };
};

export const notInStockActions_setReset = () => {
  return {
    type: 'SETNOTINSTOCKRESET',
  };
};

export const notInStockActions_setNotInStockAccount = (account) => {
  return {
    type: 'SETNOTINSTOCKACCOUNT',
    payload: account,
  };
};

export const notInStockActions_setNotInStockElementList = (elementList) => {
  return {
    type: 'SETNOTINSTOCKELEMENTLIST',
    payload: elementList,
  };
};

export const notInStockActions_setViewScale = (viewScale) => {
  return {
    type: 'SETNOTINSTOCKVIEWSCALE',
    payload: viewScale,
  };
};
