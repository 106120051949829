import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { manHour } from 'api/apis/manHour/manHour';
import { userApi } from 'api/apis/userApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import TableSection from 'components/layouts/table/TableSection';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';
import { workOrderTypeApi } from 'api/apis/workOrderTypeApi';

const Section = styled.section`
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100%;
  overflow: hidden;
  width: 100%;

  section {
    height: 100%;
    width: 100%;
  }
  .section-select {
    display: grid;
    gap: 10px;
    grid-template-rows: 40px calc(100% - 50px);
    overflow: hidden;
    .search {
      display: flex;
      flex-wrap: nowrap;
      gap: 10px;
      height: 40px;
      width: 100%;

      input {
        box-sizing: border-box;
        padding: 10px;
        height: 100%;
        width: calc(100% - 290px);
      }
      select {
        box-sizing: border-box;
        padding: 5px;
        width: 100px;
      }
      button {
        background-color: var(--MainNavy);
        color: var(--white);
        height: 100%;
        width: 60px;
      }
    }
    .TableSection {
      height: 100%;

      td, th {
        min-width: unset;
      }
    }
  }
`;

const Production = () => {
  const { userReducer } = useSelector((state) => state);

  const [_onload, setOnload] = useState('unload')

  const [_workOrderTypes, setWorkOrderTypes] = useState([]);
  const [_workOrderLogList, setWorkOrderLogList] = useState([]);

  const [_workOrderType, setWorkOrderType] = useState('');
  const [_searchOption, setSearchOption] = useState('');
  const [_searchText, setSearchText] = useState('');

  const [_pageNumber, setPageNumber] = useState(1);
  const [_totalSize, setTotalSize] = useState(0);

  const [_manHourData, setManHourData] = useState({});

  useEffect(() => {
    getWorkOrderTypes();
    getWorkOrderLogList(_pageNumber - 1, _workOrderType);

    setOnload('loaded')
    return () => {}
  }, []);

  useEffect(() => {
    if(_onload === 'loaded') {
      getWorkOrderLogList(_pageNumber - 1, _workOrderType);
    }
    return () => {}
  }, [_pageNumber])

  const getWorkOrderTypes = async () => {
    const BodyToPost = {companyId: userReducer.company.companyId};
    await workOrderTypeApi.searchWorkOrderType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderTypeApi.searchWorkOrderType : ', response);
      setWorkOrderTypes(() => { return response.data });
    });
  };

  const getWorkOrderLogList = async(page, workOrderType) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const body = {companyId: userReducer.company.companyId};
    if (checkEmptyNull(workOrderType, false) && workOrderType !== 'all') body.workOrderTypeId = Number(workOrderType);
    if (checkEmptyNull(_searchOption, false) && checkEmptyNull(_searchText, false)) body[_searchOption] = _searchText;
    await workOrderLogApi.searchWorkOrderLog(paging, body).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.searchWorkOrderLog : ', response);
      setWorkOrderLogList(() => { return response.data.data.content });
      setTotalSize(() => { return response.data.data.totalElements });
    });
  };

  const actSearch = (e) => {
    e.preventDefault();
    setPageNumber(() => {return 1});
    getWorkOrderLogList(0, _workOrderType);
  }

  const handleManHourData = async(workOrderLog) => {
    const body = {
      companyId: userReducer.company.companyId,
      id: workOrderLog.workOrderLogId
    };
    await manHour.searchWorkingTime(body).then(response => {
      if(response === undefined) return;
      console.log('manHour.searchWorkingTime : ', response);
    })
  };

  return (
    <Section>
      <section className='section-select'>
        <div className='search'>
          <select value={_workOrderType} onChange={(e) => {setWorkOrderType(() => {return e.target.value})}}>
            <option value="">작업유형</option>
            {_workOrderTypes.map((workOrderType, index) => {
              return(<option key={index + '_workOrderType'} value={workOrderType.workOrderTypeId}>{workOrderType.workOrderTypeName}</option>)
            })}
          </select>
          <select value={_searchOption} onChange={(e) => {setSearchOption(() => {return e.target.value})}}>
            <option value="">검색옵션</option>
            <option value="workOrderCode">작업코드</option>
            <option value="workOrderName">작업이름</option>
          </select>
          <input
            type='text'
            placeholder='검색...'
            value={_searchText}
            onInput={(e) => {setSearchText(() => {return e.target.value})}}
          />
          <button className='btn-set' onClick={actSearch}>검색</button>
        </div>
        <TableSection content={
          <table>
            <colgroup>
              <col />
              <col />
              <col />
              <col width={'50px'} />
            </colgroup>
            <thead>
              <tr>
                <th>작업유형</th>
                <th>작업코드</th>
                <th>작업이름</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {_workOrderLogList.map((workOrderLog, index) => {
                return(
                  <tr key={index + '_workOrderLog'}>                    
                    <td>{workOrderLog.workOrderTypeName}</td>
                    <td>{workOrderLog.workOrderCode}</td>
                    <td>{workOrderLog.workOrderName}</td>
                    <td>
                      <button className='btn-set' onClick={(e) => {e.preventDefault(); handleManHourData(workOrderLog)}}>보기</button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        }
        />
      </section>
      <section className='section-result'>

      </section>
    </Section>
  );
};

export default Production;