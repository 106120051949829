import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';

import {
  AXIOS_BASE_HEADERS,
  AXIOS_BASE_URL,
} from 'preferences/server/constants';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import { categoryApi } from 'api/apis/categoryApi';

const DeleteCategory = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();

  const categoryParentLevel = searchParams.get('categoryParentLevel');
  const categoryParentCode = searchParams.get('categoryParentCode');
  const categoryParentName = searchParams.get('categoryParentName');
  const categoryLevel = searchParams.get('categoryLevel');
  const categoryId = searchParams.get('categoryId');
  const categoryCode = searchParams.get('categoryCode');
  const categoryName = searchParams.get('categoryName');
  console.table({
    categoryParentLevel: categoryParentLevel,
    categoryParentCode: categoryParentCode,
    categoryParentName: categoryParentName,
    categoryLevel: categoryLevel,
    categoryId: categoryId,
    categoryCode: categoryCode,
    categoryName: categoryName,
  });

  /* ====================================================================== #3 */

  /* ====================================================================== #4 */

  /* ====================================================================== #5 */
  const actDelete = async () => {
    await categoryApi.deleteCategory(categoryId).then((response) => {
      if (response === undefined) return;
      console.log('categoryApi.deleteCategory : ', response);

      alert('패밀리를 삭제했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body
      contents={
        <>
          <main className="Main">
            <NavBar title={'패밀리설정'} nav={''} />

            <DeleteForm
              delteTitle={'패밀리'}
              deleteItem={
                <>
                  {categoryParentName && categoryParentName !== ''
                    ? '패밀리 위치 : ' + categoryParentName
                    : ''}
                  <br />
                  {'패밀리명 : ' + categoryName}
                  <br />
                </>
              }
              deleteText={
                <>
                  {categoryName} 패밀리에 포함된{' '}
                  <span className="cautionText">모든 패밀리 및 기준정보</span>가
                  삭제됩니다.
                </>
              }
              deleteButton={
                <>
                  <button
                    className="formButton cancle"
                    onClick={() => {
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                  <button
                    className="formButton delete"
                    onClick={() => {
                      if (window.confirm('패밀리를 삭제합니다.')) actDelete();
                      else return;
                    }}
                  >
                    삭제
                  </button>
                </>
              }
            />
          </main>
        </>
      }
    />
  );
};

export default DeleteCategory;
