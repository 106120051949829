export const searchReducer_reset = () => {
  return {
    type: 'SEARCHRESET'
  }
};

export const searchReducer_setProductType = (thisType) => {
  return {
    type: 'SETPRODUCTTYPE',
    payload: thisType
  }
};

export const searchReducer_setCurrentFamilyId = (thisFamilyId) => {
  return {
    type: 'SETCURRENTFAMILYID',
    payload: thisFamilyId
  }
};

export const searchReducer_setAllFamiles = (thisFamily) => {
  return {
    type: 'SETALLFAMILIES',
    payload: thisFamily
  }
};

export const searchReducer_setFirstFamily = (thisFamily) => {
  return {
    type: 'SETFIRSTFAMILY',
    payload: thisFamily
  }
};

export const searchReducer_setFirstFamilies = (thisFamilies) => {
  return {
    type: 'SETFIRSTFAMILIES',
    payload: thisFamilies
  }
};

export const searchReducer_setSecondFamily = (thisFamily) => {
  return {
    type: 'SETSECONDFAMILY',
    payload: thisFamily
  }
};

export const searchReducer_setSecondFamilies = (thisFamilies) => {
  return {
    type: 'SETSECONDFAMILIES',
    payload: thisFamilies
  }
};

export const searchReducer_setThirdFamily = (thisFamily) => {
  return {
    type: 'SETTHIRDFAMILY',
    payload: thisFamily
  }
};

export const searchReducer_setThirdFamilies = (thisFamilies) => {
  return {
    type: 'SETTHIRDFAMILIES',
    payload: thisFamilies
  }
};

/* 패밀리4 */
export const searchReducer_setFourthFamily = (thisFamily) => {
  return {
    type: 'SETFOURTHFAMILY',
    payload: thisFamily
  }
};

export const searchReducer_setFourthFamilies = (thisFamilies) => {
  return {
    type: 'SETFOURTHFAMILIES',
    payload: thisFamilies
  }
};

/* 패밀리5 */
export const searchReducer_setFifthFamily = (thisFamily) => {
  return {
    type: 'SETFIFTHFAMILY',
    payload: thisFamily
  }
};

export const searchReducer_setFifthFamilies = (thisFamilies) => {
  return {
    type: 'SETFIFTHFAMILIES',
    payload: thisFamilies
  }
};

export const searchReducer_setWorkStatus = (thisStatus) => {
  return {
    type: 'SETWORKSTATUS',
    payload: thisStatus
  }
};

export const searchReducer_setStartDate = (thisDate) => {
  return {
    type: 'SETSTARTDATE',
    payload: thisDate
  }
};

export const searchReducer_setEndDate = (thisDate) => {
  return {
    type: 'SETENDDATE',
    payload: thisDate
  }
};

export const searchReducer_setSearchOption = (thisOption) => {
  return {
    type: 'SETSEARCHOPTION',
    payload: thisOption
  }
};

export const searchReducer_setSearchText = (thisText) => {
  return {
    type: 'SETSEARCHTEXT',
    payload: thisText
  }
};