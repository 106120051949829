import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Sign } from 'mall/styles/style';
import moment from 'moment';

import { sign } from 'api/apis/mall/sign';

import { checkEmptyNull } from 'components/checkValues/checkValues';

import MallLogo from 'mall/components/MallLogo';

const MallSignPwFind = () => {
  /* ====================================================================== #1 */
  const location = useLocation();
  const navigate = useNavigate();

  /* ====================================================================== #2 */
  const companyCode = location.pathname.split('/')[1];

  const [_emailStatus, setEmailStatus] = useState(false);
  const [_email, setEmail] = useState('');

  const [_findStatus, setFindStatus] = useState(false);
  const [_findTime, setFindTime] = useState();
  const [_resendTime, setResendTime] = useState();

  /* ====================================================================== #3 */
  const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
    useEffect(() => {
      const tick = () => {
        savedCallback.current();
      };
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };
  useInterval(() => {
    if (!_findStatus) return;
    const currentTime = moment(new Date());
    const calSeconds = moment.duration(currentTime.diff(_findTime)).asSeconds();
    setResendTime(() => {
      return calSeconds;
    });
  }, 1000);
  useEffect(() => {
    console.log('_resendTime : ', _resendTime);
    return () => { };
  }, [_resendTime]);

  /* ====================================================================== #4 */
  // 초 > 시:분:초
  const setSeconds = (seconds) => {
    const setHour = !isNaN(parseInt(seconds / 3600))
      ? parseInt(seconds / 3600)
      : 0;
    const hour = setHour < 10 ? '0' + setHour : setHour;
    const setMin = !isNaN(parseInt((seconds % 3600) / 60))
      ? parseInt((seconds % 3600) / 60)
      : 0;
    const min = setMin < 10 ? '0' + setMin : setMin;
    const setSec = !isNaN(parseInt(seconds % 60)) ? parseInt(seconds % 60) : 0;
    const sec = setSec < 10 ? '0' + setSec : setSec;

    return `${hour}:${min}:${sec}`;
  };

  // 이메일 체크
  const checkEmail = (email) => {
    const reg = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
    if (!reg.test(email)) return false; // 불통
    else return true; // 통과
  };

  /* ====================================================================== #5 */
  // 비밀번호 찾기 => companyCode, email, phoneNumber
  const actFind = async () => {
    setFindStatus(() => { return true });
    setFindTime(() => { return new Date() });

    if (!checkEmptyNull(_email, false) || !checkEmail(_email)) return alert('이메일을 확인해주세요.');

    const BodyToPost = {
      companyCode: companyCode,
      email: _email,
    };
    console.log('actFind - BodyToPost : ', BodyToPost);

    // return;
    await sign.findUserPassword(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('sign.findUserPassword : ', response);

      if (checkEmptyNull(response.data.userId, false)) {
        const alertText = `
          가입한 이메일 주소로 임시 비밀번호를 알려드립니다.
          로그인 후 비밀번호를 변경해주세요.
        `;
        alert(alertText);
      }
    });
  };

  /* ====================================================================== #6 */

  return (
    <>
      <Sign>
        <header>
          <Link to={`/${companyCode}/mall/product/list`}>아이피스 몰</Link>
          <Link to={`/${companyCode}/mall/sign/login`}>로그인</Link>
        </header>
        <section className='sign emailfind'>
          {/* <MallLogo /> */}
          <h3>비밀번호 찾기</h3>
          <fieldset>
            <input
              type="email" placeholder="이메일" required
              data-status={_emailStatus} value={_email}
              onInput={(e) => {
                const email = e.target.value;
                setEmailStatus(() => { return checkEmail(email); });
                setEmail(() => { return email; });
              }}
            />
          </fieldset>

          {_findStatus ? (
            <div>{setSeconds(_resendTime)}</div>
          ) : null}
          {(() => {
            if (!_findStatus) {
              return (
                <button type="submit" className='submit' onClick={actFind}>확인</button>
              );
            } else if (_findStatus && _resendTime <= 30) {
              return (
                <button type="submit">재전송</button>
              );
            } else if (_findStatus && _resendTime > 30) {
              return (
                <button type="submit" onClick={() => { actFind(); }}>재전송</button>
              );
            } else {
              return null;
            }
          })()}
          <div className='signitem'>
            <button onClick={() => { navigate(`/${companyCode}/mall/sign/login`, { replace: true }); }} >로그인</button>
            <button onClick={() => { navigate(`/${companyCode}/mall/sign/email/find`, { replace: true }); }} >아이디 찾기</button>
            <button onClick={() => { navigate(`/${companyCode}/mall/sign/join`, { replace: true }); }} >회원가입</button>
          </div>
        </section>
      </Sign>
    </>
  );
};

export default MallSignPwFind;
