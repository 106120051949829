import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { accountTypeApi } from 'api/apis/accountTypeApi';
import { accountApi } from 'api/apis/accountApi';

import { checkEmptyNull, checkNull } from 'components/checkValues/checkValues';

import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import DaumPost from './DaumPost';

const CompanyInformation = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const { userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_companyInformation, setCompanyInformation] = useState({
    companyId: userReducer.company.companyId,
    accountId: undefined,
    accountTypeId: undefined,
    // accountTypeName: userReducer.company.companyName,

    accountCode: '', // 사업자번호(수요기관코드)
    businessNumber: '', // 사업자번호 => 계좌번호
    accountName: userReducer.company.companyName, // 회사명
    regionName: '', // 지역
    address: '', // 주소
    detailAddress: '', // 상세주소
    zip: '', // 우편번호
    email: '', // 이메일
    telNumber: '', // 전화번호
    faxNumber: '', // 팩스번호

    accountAttributeValueList: [],
    deleteAccountAttributeValueList: [],
  });

  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('999') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getCompanyInformation();

    setOnload('loaded');

    return () => { };
  }, []);

  /* ========================================================================= #4 */
  const getCompanyInformation = async () => {
    const paging = `?page=0`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      accountName: userReducer.company.companyName,
    };

    console.log('getCompanyInformation - BodyToPost : ', BodyToPost);

    await accountApi.searchAccount(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('accountApi.searchAccount : ', response);
      if (response.data.content.length === 0) return;
      setCompanyInformation((prev) => {
        const returnData = { ...prev, ...response.data.content[0] };
        return returnData;
      });
    });
  };
    
  /* ========================================================================= #5 */
  const actCreate = async (e) => {
    e.preventDefault();
    if (!checkEmptyNull(_companyInformation.accountName, false)) return alert('회사명을 입력해주세요');
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      accountTypeName: userReducer.company.companyName,
    };
    await accountTypeApi.createAccountType(BodyToPost).then(async (typeData) => {
      const AccountToPost = {
        ..._companyInformation,
        accountTypeId: typeData.data.accountTypeId,
      };
      await accountApi.createAccount(AccountToPost).then((response) => {
        if (response === undefined) return;
        console.log('accountApi.createAccount : ', response);
        alert('회사정보를 추가했습니다.');
      });
    });
  };

  const actUpdate = async (e) => {
    e.preventDefault();
    if (!checkEmptyNull(_companyInformation.accountName, false)) return alert('회사명을 입력해주세요');
    const BodyToPut = { ..._companyInformation };
    await accountApi.updateAccount(_companyInformation.accountId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('accountApi.updateAccount : ', response);
      alert('회사정보를 수정했습니다.');
    });
  };

  const changeAddress = (e) => {
    const {name, value} = e.target;
    setCompanyInformation({
      ..._companyInformation,
      [name]: value,
    });
  };

  const makeTelNumber = (e) => {
    const telNumber = e.target.value.toString().replace(/[^0-9]/g, '').replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/g, '$1-$2-$3');
    setCompanyInformation((prev) => { return { ...prev, telNumber: telNumber }});
  };

  const makeAccountCode = (e) => {
    const  accountCode = e.target.value.toString().replace(/[^0-9]/g, '').replace(/([0-9]{3})([0-9]{2})([0-9]{5})/g, '$1-$2-$3');
    setCompanyInformation((prev) => { return { ...prev,  accountCode:  accountCode }});
  };
  /* ========================================================================= #6 */

  return (
    <Grid2Body contents={
      <>
        <main className="main">
          <NavBar
            title={'회사정보'}
            buttons={
              <>
                {_authority.indexOf('999-2') !== -1 ? (
                  <>
                    {checkEmptyNull(_companyInformation.accountId, false) ? (
                      <button className='btn-save' onClick={actUpdate}>저장</button>
                    ) : (
                      <button className='btn-save' onClick={actCreate}>추가</button>
                    )}
                  </>
                ) : null}
              </>
            }
            nav={''}
          />

          <Form
            formLayout={'infoFormLayout'}
            titleClass={'infoFormTitle'}
            title={<>'{userReducer.company.companyName}'<br />정보</>}
            buttons={<></>}
            forms={
              <>
                <div className='companyInfoBox'>
                  <div className="formBox">
                    <h4>회사명</h4>
                    <input
                      className='infoInput'
                      type="text"
                      name="accountName"
                      data-required="required"
                      style={{ backgroundColor: 'var(--MainBlue)', color: 'var(--white)', fontWeight: '600' }}
                      readOnly
                      placeholder="* 회사명..."
                      value={checkNull(_companyInformation.accountName, '')}
                      onInput={(e) => {
                        setCompanyInformation((prev) => { return { ...prev, accountName: e.target.value }; });
                      }}
                    />
                  </div>

                  <div className="formBox">
                    <h4>사업자등록번호</h4>
                    <input
                      className='infoInput'
                      type="text"
                      name="accountCode"
                      data-required="required"
                      placeholder="* 사업자등록번호..."
                      maxLength={10}
                      value={checkNull(_companyInformation.accountCode, '')}
                      onInput={makeAccountCode}
                    />
                  </div>
                </div>

                <div className='companyInfoBox'>
                  <div className='companyAddressBox'>
                    <h4>주소</h4>
                    <DaumPost setCompanyInformation={setCompanyInformation}/>
                  </div>
                  <div className='companyAddressBox'>
                    <div className="formBox">
                      <input
                        className='infoInput'
                        type="text"
                        name="zip"
                        placeholder="* 우편번호..."
                        readOnly
                        value={checkNull(_companyInformation.zip, '')}
                        onChange={changeAddress}
                      />
                    </div>
                  </div>

                  <div className='companyAddressBox'>
                    <div className="formBox">
                      <input
                        className='infoInput'
                        type="text"
                        name="address"
                        placeholder="* 주소..."
                        readOnly
                        value={checkNull(_companyInformation.address, '')}
                        onChange={changeAddress}
                      />
                    </div>
                  </div>

                  <div className='companyAddressBox'>
                    <div className="formBox">
                      <input
                        className='infoInput'
                        type="text"
                        name="detailAddress"
                        placeholder="* 상세주소..."
                        data-required="required"
                        value={checkNull(_companyInformation.detailAddress, '')}
                        onInput={(e) => {
                          setCompanyInformation((prev) => { return { ...prev, detailAddress: e.target.value }; });
                        }}
                      />
                    </div>

                    <div className="formBox">
                      <input
                        className='infoInput'
                        type="text"
                        name="regionName"
                        placeholder="* 지역명..."
                        readOnly
                        value={checkNull(_companyInformation.regionName, '')}
                        onChange={changeAddress}
                      />
                    </div>
                  </div>
                </div>

                <div className='companyInfoBox'>
                  <div className="formBox" style={{ width: '23%' }}>
                    <h4>이메일</h4>
                    <input
                      className='infoInput'
                      type="text"
                      name="email"
                      placeholder="* 이메일..."
                      data-required="required"
                      value={checkNull(_companyInformation.email, '')}
                      onInput={(e) => {
                        setCompanyInformation((prev) => { return { ...prev, email: e.target.value }; });
                      }}
                    />
                  </div>

                  <div className="formBox" style={{ width: '20%' }}>
                    <h4>전화번호</h4>
                    <input
                      className='infoInput'
                      type="text"
                      name="telNumber"
                      placeholder="* 전화번호..."
                      maxLength={13}
                      data-required="required"
                      value={checkNull(_companyInformation.telNumber, '')}
                      onInput={makeTelNumber}
                    />
                  </div>

                  <div className="formBox" style={{ width: '20%' }}>
                    <h4>팩스번호</h4>
                    <input
                      className='infoInput'
                      type="text"
                      name="faxNumber"
                      placeholder="* 팩스번호..."
                      data-required="required"
                      value={checkNull(_companyInformation.faxNumber, '')}
                      onInput={(e) => {
                        setCompanyInformation((prev) => { return { ...prev, faxNumber: e.target.value }; });
                      }}
                    />
                  </div>

                  <div className="formBox" style={{ width: '29%' }}>
                    <h4>계좌번호</h4>
                    <input
                      className='infoInput'
                      type="text"
                      name="businessNumber"
                      placeholder="* 계좌번호..."
                      data-required="required"
                      value={checkNull(_companyInformation.businessNumber, '')}
                      onInput={(e) => {
                        setCompanyInformation((prev) => { return { ...prev, businessNumber: e.target.value }; });
                      }}
                    />
                  </div>
                </div>
              </>
            }
          />
        </main>
      </>
    }
    />
  );
};

export default CompanyInformation;
