import styled from 'styled-components';

export const Main = styled.main`
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 60px calc(100% - 60px);

  & .search-select {
    background-color: var(--white);
    border-radius: 8px;
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    height: 40px;
    padding: 4px 20px;
    position: relative;
    width: 120px;

    &.year::after {
      content: '년';

      background-color: var(--white);
      color: var(--Text);
      position: absolute;
      right: 5px;
      height: 40px;
      width: 40px;
    }
  }

  .btn-set {
    &.active {
      background-color: var(--MainBlue);
      color: var(--white);
      &:hover {background-color: var(--MainBlue);}
    }
  }
  .btn-search {padding-inline: 16px;}
  .ResetButton {
    box-shadow: none;
    &:hover {background: none;}
  }

  .contents {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    box-sizing: border-box;
    height: 100%;
    padding: 40px;
    width: 100%;
    
    .contentWrap {
      background-color: var(--white);
      border-radius: 20px;
      box-sizing: border-box;
      display: grid;
      gap: 1%;
      grid-template-columns: 69% 30%;
      height: 100%;
      padding: 20px;
      width: 100%;

      .section-table {
        display: grid;
        grid-gap: 10px;
        height: 100%;
        overflow-y: hidden;
      }

      .TableSection {
        height: 100%;
        
        table {
          td, th {
            border-color: var(--gray-200);
            padding-block: 6px;
          }
          th {
            background-color: var(--MainNavy);
            color: var(--white);
          }
        }
      }
    }
  }
`;
