import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  height: 85%;
  overflow: hidden;
  width: 90%;

  grid-template-rows: ${(props) => {
    return props.type === 'list' ? '55px calc(100% - 55px)' : '55px auto 50px';
  }};
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const UpdateButton = styled.button`
  position: absolute;
  right: 55px;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;

    input {
      border: 1px solid var(--gray-200);
      border-radius: 5px;
      box-sizing: border-box;
      color: var(--MainBlue);
      font-size: 20px;
      font-weight: 600;
      height: 40px;
      min-width: 200px;
      padding: 5px 15px;
      width: 98%;
    }
  }
`;
const DelButton = styled.div`
  height: 15px;
  margin: 0px auto;
  width: 15px;
  div {background-color: var(--MainRed); cursor: pointer; height: 15px; width: 15px;}
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const GSCPFromContract = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_contract, setContract] = useState(props.content);
  const [_workOrder, setWorkOrder] = useState([]);

  const [_showUpdateList, setShowUpdateList] = useState(false);

  const [_formData, setFormData] = useState({});

  const [_updateWorkOrderList, setUpdateWorkOrderList] = useState([]);
  const [_deleteWorkOrderList, setDeleteWorkOrderList] = useState([]);

  const [_BLData, setBLData] = useState([]);
  const [_CLData, setCLData] = useState([]);

  /* ====================================================================== #3 */
  useEffect(() => {
    getCPData();

    setOnload('loaded');
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const getCPData = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      contractId: _contract.contractId,
    };

    await workOrderLogApi.searchWorkOrderLog('', BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.searchWorkOrderLog : ', response);
      if (checkNullArray(response.data.data.content, false)) {
        Finished_getCPData(response.data.data.content[0]);
      } else {
        alert('연관 조합리스트가 없습니다.')
        props.close();
      }
    })
  };
  const Finished_getCPData = (Result) => {
    setFormData({
      companyId: userReducer.company.companyId,
      userId: userReducer.user.id,
      workOrderLogId: Result.workOrderLogId,
      workOrderStatus: Result.workOrderStatus,
      workOrderScheduledStartDate: DateFormat(checkEmptyNull(Result.workOrderScheduledStartDate, '')),
      workOrderScheduledEndDate: DateFormat(checkEmptyNull(Result.workOrderScheduledEndDate, '')),
      accountId: Result.accountId,
      workPlace: Result.workPlace,
      detailedWorkPlace: Result.detailedWorkPlace,
      message: Result.message,
      remark: Result.remark,
      workOrderCustomizedContent: Result.workOrderCustomizedContent,
      processLogList: [],
      deleteProcessLogList: [],
    });
    const processLogList = Result.processLogList.filter((thisItem) => thisItem.processTypeName === 'CP');
    setWorkOrder(processLogList);
    setBLData(Result.processLogList.filter((thisItem) => thisItem.processTypeName === 'BL'));
    setCLData(Result.processLogList.filter((thisItem) => thisItem.processTypeName === 'CL'));
    const updateWorkOrderList = processLogList.map((thisItem) => {
      return {
        existProcessOutElement: false,
        processTypeName: 'CP',
        processStatus: thisItem.processStatus,
        processLogId: thisItem.processLogId,
        processingCount: thisItem.processingCount,
        processCustomizedContent: JSON.parse(thisItem.processCustomizedContent),
        deleteTaskIds: [],
        taskLogList: [],

        processName: thisItem.processName,
        processCode: thisItem.processCode,
      };
    });
    setUpdateWorkOrderList(updateWorkOrderList);
  };

  /* ====================================================================== #5 */
  const handleCustomEvent = (e) => {
    const { dataset, name, value } = e.target;
    const processLogId = dataset.processlogid;
    const thisProcessIndex = _updateWorkOrderList.findIndex((thisItem) => thisItem.processLogId === Number(processLogId));
    if (thisProcessIndex === -1) return;

    const thisProcessCon = _updateWorkOrderList[thisProcessIndex];
    const copyList = [..._updateWorkOrderList];
    const newCustom = {...thisProcessCon.processCustomizedContent, [name]: value};
    const newProcessCon = {...thisProcessCon, processCustomizedContent: newCustom};
    copyList.splice(thisProcessIndex, 1, newProcessCon);
    setUpdateWorkOrderList(copyList);
  };
  const delOrder = (e) => {
    const processLogId = e.target.closest('tr').dataset.processlogid;
    const setList = _updateWorkOrderList.filter((thisItem) => thisItem.processLogId !== Number(processLogId));
    setDeleteWorkOrderList([..._deleteWorkOrderList, Number(processLogId)]);
    setUpdateWorkOrderList(setList);
  };

  const actUpdate = async () => {
    const BodyToPut = { ..._formData };
    const setAllProcessLogList = [
      ..._updateWorkOrderList.map((thisItem) => {
        return {...thisItem, processCustomizedContent: JSON.stringify(thisItem.processCustomizedContent)};
      }),
      ..._BLData, ..._CLData
    ];
    BodyToPut.processLogList = setAllProcessLogList;
    BodyToPut.deleteProcessLogList = _deleteWorkOrderList;
    console.log('BodyToPut : ', BodyToPut);

    await workOrderLogApi.updateWorkOrderLog(BodyToPut.workOrderLogId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.updateWorkOrderLog : ', response);
      alert('작업지시가 수정되었습니다.');
      props.close();
    })
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          {_showUpdateList !== true ? (
            <ModalSection type="list" style={{ zoom: '100%' }}>
              <ModalHeader>
                <ModalTitle>조합리스트</ModalTitle>
                <UpdateButton
                  className="formButton"
                  onClick={() => {
                    setUpdateWorkOrderList([]);
                    setDeleteWorkOrderList([]);

                    const updateWorkOrderList = _workOrder.map((thisItem) => {
                      return {
                        existProcessOutElement: false,
                        processTypeName: 'CP',
                        processStatus: thisItem.processStatus,
                        processLogId: thisItem.processLogId,
                        processingCount: thisItem.processingCount,
                        processCustomizedContent: JSON.parse(thisItem.processCustomizedContent),
                        deleteTaskIds: [],
                        taskLogList: [],

                        processName: thisItem.processName,
                      };
                    });
                    setUpdateWorkOrderList(updateWorkOrderList);

                    setShowUpdateList(true);
                  }}
                >
                  리스트 수정
                </UpdateButton>
                <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
              </ModalHeader>

              <ModalMain>
                <TableSection content={
                  <table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '65px', width: '65px' }}>순번</th>
                        <th>재질</th>
                        <th>규격</th>
                        <th>길이</th>
                        <th>수량</th>
                        <th>조합</th>
                        <th>잔재</th>
                        <th>부재길이</th>
                        <th>수량</th>
                        <th>총수량</th>
                        <th style={{ minWidth: '300px', width: '300px' }}>Mark</th>
                      </tr>
                    </thead>

                    <tbody>
                      {_workOrder.map((thisItem, index) => {
                        const processCustomizedContent = JSON.parse(thisItem.processCustomizedContent);
                        return (
                          <tr key={index + '_CPRow'}>
                            <td style={{ minWidth: '65px', width: '65px' }} data-col="Num">{processCustomizedContent.num}</td>
                            <td data-col="Name" data-colname="재질">{processCustomizedContent.name}</td>
                            <td data-col="Profile" data-colname="규격">{processCustomizedContent.profile}</td>
                            <td data-col="Length" data-colname="길이">{processCustomizedContent.length}</td>
                            <td data-col="Quantity" data-colname="수량">{processCustomizedContent.quantity}</td>
                            <td data-col="Combined" data-colname="조합">{processCustomizedContent.combined}</td>
                            <td data-col="Part" data-colname="잔재">{processCustomizedContent.part}</td>
                            <td data-col="PartWidth" data-colname="부재길이">{processCustomizedContent.partWidth}</td>
                            <td data-col="Each" data-colname="수량">{processCustomizedContent.each}</td>
                            <td data-col="Total" data-colname="총수량">{processCustomizedContent.total}</td>
                            <td style={{ minWidth: '300px', width: '300px' }} data-col="Mark">{processCustomizedContent.mark}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                }
                />
              </ModalMain>
            </ModalSection>
          ) : (
            <ModalSection type="update" style={{ zoom: '100%' }}>
              <ModalHeader>
                <ModalTitle>조합리스트 수정</ModalTitle>
                <UpdateButton className="formButton" onClick={() => {setShowUpdateList(false);}}>리스트 보기</UpdateButton>
                <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
              </ModalHeader>

              <ModalMain>
                <TableSection content={
                  <table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '65px', width: '65px' }}>순번</th>
                        <th>재질</th>
                        <th>규격</th>
                        <th>길이</th>
                        <th>수량</th>
                        <th>조합</th>
                        <th>잔재</th>
                        <th>부재길이</th>
                        <th>수량</th>
                        <th>총수량</th>
                        <th style={{ minWidth: '300px', width: '300px' }}>Mark</th>
                        <th style={{ minWidth: '50px', width: '50px' }}></th>
                      </tr>
                    </thead>

                    <tbody>
                      {_updateWorkOrderList.map((thisItem, index) => {
                        const processCustomizedContent = thisItem.processCustomizedContent;

                        return (
                          <tr key={index + '_CPRow'} data-processlogid={thisItem.processLogId}>
                            <td style={{ minWidth: '65px', width: '65px' }} data-col="Num">{processCustomizedContent.num}</td>
                            <td data-col="Name" data-colname="재질">
                              <input
                                type="text"
                                name="name"
                                data-processlogid={thisItem.processLogId}
                                value={processCustomizedContent.name}
                                onInput={handleCustomEvent}
                              />
                            </td>
                            <td data-col="Profile" data-colname="규격">
                              <input
                                type="text"
                                name="profile"
                                data-processlogid={thisItem.processLogId}
                                value={processCustomizedContent.profile}
                                onInput={handleCustomEvent}
                              />
                            </td>
                            <td data-col="Length" data-colname="길이">
                              <input
                                type="text"
                                name="length"
                                data-processlogid={thisItem.processLogId}
                                value={processCustomizedContent.length}
                                onInput={handleCustomEvent}
                              />
                            </td>
                            <td data-col="Quantity" data-colname="수량">
                              <input
                                type="text"
                                name="quantity"
                                data-processlogid={thisItem.processLogId}
                                value={processCustomizedContent.quantity}
                                onInput={handleCustomEvent}
                              />
                            </td>
                            <td data-col="Combined" data-colname="조합">
                              <input
                                type="text"
                                name="combined"
                                data-processlogid={thisItem.processLogId}
                                value={processCustomizedContent.combined}
                                onInput={handleCustomEvent}
                              />
                            </td>
                            <td data-col="Part" data-colname="잔재">
                              <input
                                type="text"
                                name="part"
                                data-processlogid={thisItem.processLogId}
                                value={processCustomizedContent.part}
                                onInput={handleCustomEvent}
                              />
                            </td>
                            <td data-col="PartWidth" data-colname="부재길이">
                              <input
                                type="text"
                                name="partWidth"
                                data-processlogid={thisItem.processLogId}
                                value={processCustomizedContent.partWidth}
                                onInput={handleCustomEvent}
                              />
                            </td>
                            <td data-col="Each" data-colname="수량">
                              <input
                                type="text"
                                name="each"
                                data-processlogid={thisItem.processLogId}
                                value={processCustomizedContent.each}
                                onInput={handleCustomEvent}
                              />
                            </td>
                            <td data-col="Total" data-colname="총수량">
                              <input
                                type="text"
                                name="total"
                                data-processlogid={thisItem.processLogId}
                                value={processCustomizedContent.total}
                                onInput={handleCustomEvent}
                              />
                            </td>
                            <td
                              style={{ minWidth: '300px', width: '300px' }}
                              data-col="Mark"
                            >
                              <input
                                type="text"
                                name="mark"
                                data-processlogid={thisItem.processLogId}
                                value={processCustomizedContent.mark}
                                onInput={handleCustomEvent}
                              />
                            </td>
                            <td style={{ minWidth: '50px', width: '50px' }}>
                              <DelButton onClick={delOrder}><CloseButtonIcon /></DelButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                }
                />
              </ModalMain>

              <ModalFooter>
                <ModalButton onClick={actUpdate}>저장</ModalButton>
              </ModalFooter>
            </ModalSection>
          )}
        </ModalBg>
      )}
    </>
  );
};

export default GSCPFromContract;
