import React from 'react';
import Pagination from 'react-js-pagination';
import styled from 'styled-components';

import 'components/paging/Paging.css';

import { lastPageButton, nextPageButton } from 'components/icons/src';

//
const PageButton = styled.div`
  background-color: var(--gray-800);
  mask-repeat: no-repeat; mask-size: contain;
  --webkit-mask-repeat: no-repeat; --webkit-mask-size: contain;
`;
const LastPageButton = styled(PageButton)`
  height: 16px;
  width: 16px;
  mask-image: url(${lastPageButton}); --webkit-mask-image: url(${lastPageButton});
`;
const FirstPageButton = styled(LastPageButton)`
  transform: rotate(-180deg);
`;
const NextPageButton = styled(PageButton)`
  height: 20px;
  width: 20px;
  mask-image: url(${nextPageButton}); --webkit-mask-image: url(${nextPageButton});
`;
const PrevPageButton = styled(NextPageButton)`
  transform: rotate(-180deg);
`;

const MallPaging = (props) => {
  const { page, count, size, pageEvent } = props;

  return (
    <Pagination
      activePage={page}
      itemsCountPerPage={size}
      onChange={(e) => {pageEvent(e);}}
      pageRangeDisplayed={5}
      totalItemsCount={count}
      firstPageText={<FirstPageButton />}
      lastPageText={<LastPageButton />}
      prevPageText={<PrevPageButton />}
      nextPageText={<NextPageButton />}
    />
  );
};

export default MallPaging;
