import { Mall2RedirectHandler, MallCart, MallMain, MallMypage, MallMypageInfo, MallMypageOrderList, MallMypageQnaList, MallProductAll, MallProductDetail, MallProductList, MallProductOrder, MallProductQna, MallQna, MallQnaList, MallSignEmailFind, MallSignJoin, MallSignLogin, MallSignPwFind, MallSiteMap, NotFound } from "../RouteImport";
import MallRouteLayout from "../layouts/MallRouteLayout";

export const mallRouters = [
  { path: '*', element: <NotFound /> },

  { // 몰
    path: ':code/mall', element: <MallRouteLayout />,
    children: [
      {// 토큰 받아옴
        path: 'oauth2/redirect', element: <Mall2RedirectHandler />,
      },
      {// 사이트맵 페이지
        path: 'sitemap', element: <MallSiteMap />
      },
      {// 로그인 페이지
        path: 'sign', element: <MallRouteLayout />,
        children: [
          { path: '', element: <MallSignLogin />, },
          { path: 'login', element: <MallSignLogin />, },
          { path: 'join', element: <MallSignJoin />, },
          { path: 'email/find', element: <MallSignEmailFind />, },
          { path: 'pw/find', element: <MallSignPwFind />, },
        ],
      },
      {// 로그인 후 메인 페이지
        path: '', element: <MallMain />,
        children: [
          { // 장바구니
            path: 'cart', element: <MallCart />,
          },
          { // 문의
            path: 'qna', element: <MallRouteLayout />,
            children: [
              { path: '', element: <MallQna />, },
              { path: 'list', element: <MallQnaList />, },
            ],
          },
          { // 제품
            path: 'product', element: <MallRouteLayout />,
            children: [
              { path: 'all', element: <MallProductAll />, },
              { path: 'list', element: <MallProductList />, },
              { path: 'detail', element: <MallProductDetail />, },
              { path: 'qna', element: <MallProductQna />, },
              { path: 'order', element: <MallProductOrder />, },
            ],
          },
          { // 마이페이지
            path: 'mypage', element: <MallMypage />,
            children: [
              { path: 'info', element: <MallMypageInfo />, },
              {
                path: 'order', element: <MallRouteLayout />,
                children: [
                  { path: 'list', element: <MallMypageOrderList />, },
                ],
              },
              {
                path: 'qna', element: <MallRouteLayout />,
                children: [
                  { path: 'list', element: <MallMypageQnaList />, },
                ],
              },
            ],
          },
        ]
      },
    ],
  },
];