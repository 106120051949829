import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { attachFileApi } from 'api/apis/attachFileApi';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';

import { DateFormat } from 'components/format/DateFormat';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

/* Form */
import ProcessForm from 'pages/productionOrder/custom/company/uri/crud/components/ProcessForm';
import InfoForm from 'pages/productionOrder/custom/company/uri/crud/components/InfoForm';

const ProductionOrderSection = styled.main`
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 66px calc(100% - 66px);
`;
const FormSection = styled.main``;

const UpdateUriProductionOrder = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();
  const updateContent = checkNullParse(searchParams.get('updateContent'), {});

  const [_contentType, setContentType] = useState('info');

  const [_checkId, setCheckId] = useState(0);

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    userId: updateContent.userId,
    user: { id: updateContent.userId, name: updateContent.userName },

    workOrderTypeId: '',

    workOrderStatus: updateContent.workOrderStatus,
    workOrderName: updateContent.workOrderName,
    workOrderCode: updateContent.workOrderCode,

    workOrderScheduledStartDate: DateFormat(checkEmptyNull(updateContent.workOrderScheduledStartDate, '')),
    workOrderScheduledEndDate: DateFormat(checkEmptyNull(updateContent.workOrderScheduledEndDate, '')),

    accountId: updateContent.accountId, // 거래처
    account: { accountId: updateContent.accountId, accountName: updateContent.accountName },

    workPlace: updateContent.workPlace, // 주소
    // detailedWorkPlace: '', // 현장명

    // message: '', // 작업자 알림
    remark: updateContent.remark, // 비고

    workOrderCustomizedContent: checkNullParse(updateContent.workOrderCustomizedContent, {
      BF: { date: '', amount: '' },
      SF: { date: '', amount: '' },
      MF: { date: '', amount: '' },
      total: { date: '', amount: '' },

      // workOrderProducts: '', // 품목
      colors: '', // 색상
      phoneNumber: '', // 전화번호
    }),

    processLogList: checkNullArray(updateContent.processLogList, []),
    deleteProcessList: [],
  });

  const [_processLogList, setProcessLogList] = useState(() => {
    return checkNullArray(updateContent.processLogList, []).map((processLog, index) => {
      const processLogData = {
        ...processLog,
        processCustomizedContent: checkNullParse(processLog.processCustomizedContent, {
          existProcessOutElement: false,
          processingCount: '',

          processCustomizedContent: {
            no: (index + 1).toString().padStart(2, '0'), // 순번
            numStatus: 'white', // NO 컬러
            bundle: '', // 몇 짝
            bundleSize: '', //
            materials: '', // 자재
            materialStatus: 'normal', // 자재 글꼴
            colors: '', // 색상
            colorStatus: 'white', // 색상 컬러
            glassType_W: '', // 유리종류_W
            glassType_H: '', // 유리종류_H
            remark: '', // 비고
          },
        }),
      };
      return processLogData;
    })
  });

  /* ====================================================================== #3 */
  useEffect(() => { console.log('_processLogList : ', _processLogList); }, [_processLogList]);

  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const handleContentType = async (buttonType, contentType) => {
    switch (contentType) {
      case 'process':
        if (buttonType === 'next') setContentType(() => { return 'info' });
        return;
      case 'info':
        if (buttonType === 'prev') setContentType(() => { return 'process' });
        return;

      default: return;
    }
  }

  /* ProcessForm */
  let checkId = 0;
  const actCreateProcess = () => {
    setCheckId((prev) => { return prev + 1; });
    setProcessLogList(prev => {
      const returnData = [...prev];
      const newData = {
        key: _checkId,
        existProcessOutElement: false,
        processingCount: '',

        processCustomizedContent: {
          no: '', // 순번
          numStatus: 'white', // NO 컬러
          bundle: '', // 몇 짝
          bundleSize: '', //
          materials: '', // 자재
          materialStatus: 'normal', // 자재 글꼴
          colors: '', // 색상
          colorStatus: 'white', // 색상 컬러
          glassType_W: '', // 유리종류_W
          glassType_H: '', // 유리종류_H
          remark: '', // 비고
        },

        attachFileList: [],
      };
      returnData.push(newData);
      return returnData;
    })
  }

  /* 작업지시 수정 */
  const actUpdate = async () => {
    const processLogList = _processLogList.map(processLog => {
      const deleteAttachFile = [];
      if (checkNullObject(processLog.attachFileList[0], false) && checkNullObject(processLog.fileData, false)) {
        if (processLog.attachFileList[0].fileDataId !== processLog.fileData.fileDataId) {
          deleteAttachFile.push(processLog.attachFileList[0].attachFileId);
        }
      }
      const deleteTaskList = [];
      const prevProcessLogIndex = updateContent.processLogList.findIndex(item => item.processLogId === processLog.processLogId);
      const prevProcessLog = updateContent.processLogList[prevProcessLogIndex];
      prevProcessLog?.taskLogList.forEach(taskLog => {
        const taskLogIndex = processLog?.taskLogList.findIndex(item => item?.taskLogId === taskLog.taskLogId);
        if (taskLogIndex === -1) deleteTaskList.push(taskLog.taskLogId)
      })
      return {
        ...processLog,
        processAmount: 0, managers: [], workers: [],
        processCustomizedContent: JSON.stringify(processLog.processCustomizedContent),

        deleteAttachFile: deleteAttachFile,
        deleteTaskIds: deleteTaskList,
      }
    });
    const deleteProcessLogList = [];
    updateContent.processLogList.forEach(prev => {
      if (processLogList.findIndex(item => item.processLogId === prev.processLogId) === -1) deleteProcessLogList.push(prev.processLogId);
    })
    const BodyToPut = {
      ..._formData,
      workOrderCustomizedContent: JSON.stringify(_formData.workOrderCustomizedContent),
      processLogList: processLogList,
      deleteProcessLogList: deleteProcessLogList,
    }
    console.log('BodyToPut : ', BodyToPut);
    await workOrderLogApi.updateWorkOrderLog(updateContent.workOrderLogId, BodyToPut).then(response => {
      if (response === undefined) return;
      console.log('workOrderLogApi.updateWorkOrderLog : ', response);
      response.data.processLogList.forEach(async (processLog, index) => {
        const putProcessLogIndex = processLogList.findIndex(item => item.processLogId === processLog.processLogId);
        const putProcessLog = processLogList[putProcessLogIndex];
        checkNullArray(putProcessLog.deleteAttachFile, []).forEach(async (attachFileId) => {
          await attachFileApi.deleteAttachFile(attachFileId).then((response) => {
            if (response === undefined) return;
            console.log('attachFileApi.deleteAttachFile : ', response);
          });
        })

        const postProcessLogIndex = _processLogList.findIndex(item => item.processLogId === processLog.processLogId);
        const postProcessLog = _processLogList[postProcessLogIndex];
        if (!checkNullObject(postProcessLog?.fileData, false)) return;
        if (checkNullObject(postProcessLog.attachFileList[0], false) && postProcessLog?.attachFileList[0].fileDataId === postProcessLog?.fileData.fileDataId) return;
        const BodyToAttach = {
          companyId: userReducer.company.companyId,
          fileDataId: postProcessLog?.fileData.fileDataId,
          processLogId: processLog.processLogId,
        }
        console.log('BodyToAttach : ', BodyToAttach);
        await attachFileApi.createAttachFile(BodyToAttach).then(fileResponse => {
          if (fileResponse === undefined) return;
          console.log('attachFileApi.createAttachFile : ', response);
        })
      })
    }).then(() => {
      alert(`작업지시를 수정했습니다.`);
      setTimeout(navigate(pageReducer.currentPage, { replace: true }), 1000);
    })
  }

  /* ====================================================================== #6 */

  return (
    <Grid2Body contents={
      <>
        <ProductionOrderSection>
          <NavBar
            title={<><NavTitle menuCode={'403'} /></>}
            buttons={
              <>
                {_contentType === 'process' ? (
                  <button className='btn-inven-1'
                    style={{ backgroundColor: 'var(--MainNavy)', color: 'var(--white)' }}
                    onClick={(e) => { e.preventDefault(); actCreateProcess(); }}
                  >
                    공정추가
                  </button>
                ) : null}

                {_contentType !== 'process' ? (
                  <button className='btn-inven-1'
                    onClick={(e) => { e.preventDefault(); handleContentType('prev', _contentType) }}
                  >
                    이전
                  </button>
                ) : null}
                {_contentType !== 'info' ? (
                  <button className='btn-inven-1'
                    onClick={(e) => { e.preventDefault(); handleContentType('next', _contentType) }}
                  >
                    다음
                  </button>
                ) : null}
                {_contentType === 'info' ? (
                  <button className='btn-inven-1'
                    style={{ backgroundColor: 'var(--MainNavy)', color: 'var(--white)' }}
                    onClick={(e) => { e.preventDefault(); actUpdate(); }}
                  >
                    저장
                  </button>
                ) : null}
                <button className='btn-set'
                  style={{ /* backgroundColor: 'unset', boxShadow: 'unset', */ marginLeft: '15px' }}
                  onClick={(e) => { e.preventDefault(); navigate(pageReducer.currentPage, { replace: true }); }}
                >
                  취소
                </button>
              </>
            }
            nav={''}
          />

          <FormSection>
            {_contentType === 'process' ? (<ProcessForm processLogList={_processLogList} setProcessLogList={setProcessLogList} />) : null}
            {_contentType === 'info' ? (<InfoForm processLogList={_processLogList} formData={_formData} setFormData={setFormData} />) : null}
          </FormSection>
        </ProductionOrderSection>
      </>
    }
    />
  );
};

export default UpdateUriProductionOrder;
