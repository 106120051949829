import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  MallCategoryLevel1,
  MallCategoryLevel2,
  MallCategoryLevel3,
} from 'components/mallCategory/MallCategory';

import { element } from 'api/apis/mall/element';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import CommaNum from 'components/format/CommaNum';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 40px 50px;
  height: 85%;
  /* max-height: 300px; */
  /* max-width: 400px; */
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  /* height: calc(100% - 55px); */
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;

    & td.ElementInfos {
      border-right: 1px solid var(--gray-200);
      box-sizing: border-box;
      padding: unset;
    }
  }
`;

const ElementInfos = styled.div`
  height: 100%;
  width: 100%;

  & div:last-child {
    border-top: 1px solid var(--gray-200);
  }
`;
const ElementInfo = styled.div`
  display: grid;
  grid-template-columns: 50px auto;
  height: 100%;
  width: 100%;

  & h5 {
    align-items: center;
    background-color: var(--MainNavy);
    color: var(--white);
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &.mall h5 {
    background-color: var(--MainBlue);
  }

  & p {
    align-items: center;
    box-sizing: border-box;
    justify-content: flex-start;
    padding: 10px;
    width: 100%;
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const SelectMallElements = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const ElementCheckBox = useRef();

  const [_onload, setOnload] = useState('unload');

  const [_mallElementList, setMallElementList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState([]);

  const [_clickedCategory, setClickedCategory] = useState({});
  const [_clickedLevel1Category, setClickedLevel1Category] = useState({});
  const [_clickedLevel2Category, setClickedLevel2Category] = useState({});
  const [_clickedLevel3Category, setClickedLevel3Category] = useState({});

  const [_searchData, setSearchData] = useState({});

  const [_pageNum, setPageNum] = useState(1);
  const [_pageCount, setPageCount] = useState(0);

  /* ====================================================================== #3 */
  useEffect(() => {
    getMallElementList(0);

    setOnload('loaded');

    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getMallElementList(_pageNum - 1);
      console.log(ElementCheckBox.current);
      ElementCheckBox.current.checked = false;
    }

    return () => { };
  }, [_pageNum]);

  useEffect(() => { }, [_checkedItem]);

  useEffect(() => {
    if (
      _onload === 'loaded' &&
      (_clickedCategory === 'all' || Object.keys(_clickedCategory).length > 0)
    ) {
      setPageNum(() => {
        return 1;
      });
      getMallElementList(0);
    }

    return () => { };
  }, [_clickedCategory]);

  useEffect(() => {
    if (
      _onload === 'loaded' &&
      (_clickedCategory === 'all' || Object.keys(_clickedCategory).length > 0)
    ) {
      setClickedLevel2Category(() => {
        return {};
      });
    }

    return () => { };
  }, [_clickedLevel1Category]);

  useEffect(() => {
    if (
      _onload === 'loaded' &&
      (_clickedCategory === 'all' || Object.keys(_clickedCategory).length > 0)
    ) {
      setClickedLevel3Category(() => {
        return {};
      });
    }

    return () => { };
  }, [_clickedLevel2Category]);

  useEffect(() => {
    return () => { };
  }, [_clickedLevel3Category]);

  /* ====================================================================== #4 */
  const getMallElementList = async (page, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    if (
      !clear &&
      _clickedCategory !== 'all' &&
      Object.keys(_clickedCategory).length > 0
    ) {
      BodyToPost.categoryId = _clickedCategory.categoryId;
    }

    if (
      !clear &&
      _searchData.mallElementName !== undefined &&
      _searchData.mallElementName !== null &&
      _searchData.mallElementName !== ''
    ) {
      BodyToPost.mallElementName = _searchData.mallElementName;
    }
    if (
      !clear &&
      _searchData.mallElementCode !== undefined &&
      _searchData.mallElementCode !== null &&
      _searchData.mallElementCode !== ''
    ) {
      BodyToPost.mallElementCode = _searchData.mallElementCode;
    }
    console.log('getMallElementList - BodyToPost : ', BodyToPost);

    await element.searchMallElement(paging, BodyToPost)
      .then((response) => {
        if (response === undefined) return;
        console.log('element.searchMallElement : ', response);
        if (response.data) {
          setMallElementList(() => {
            return response.data.content;
          });

          setPageCount(() => {
            return response.data.totalElements;
          });
        }
      });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (item, isChecked) => {
    const checkedItem = [..._checkedItem];
    const checkedIndex = checkedItem.findIndex(
      (thisItem) => thisItem.mallElementId === item.mallElementId,
    );

    if (checkedIndex !== -1) {
      if (!isChecked) {
        checkedItem.splice(checkedIndex, 1);
      }
    } else {
      if (isChecked) {
        checkedItem.push(item);
      }
    }
    console.log('checkedItem : ', checkedItem);

    setCheckedItem(() => {
      return checkedItem;
    });
  };

  /* 검색 */
  const actSearch = async () => {
    setPageNum(() => {
      return 1;
    });
    await getMallElementList(0);
  };

  /* 초기화 */
  const actReset = async () => {
    setSearchData(() => {
      return {};
    });

    setClickedCategory(() => {
      return {};
    });
    setClickedLevel1Category(() => {
      return {};
    });
    setClickedLevel2Category(() => {
      return {};
    });
    setClickedLevel3Category(() => {
      return {};
    });

    setPageNum(() => {
      return 1;
    });
    await getMallElementList(0, true);
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>판매 물품 선택</ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              <NavBar
                nav={'search'}
                title={
                  <>
                    <div className="SearchSection">
                      <MallCategoryLevel1
                        clickedLevel1Category={_clickedLevel1Category}
                        setClickedCategory={setClickedCategory}
                        setClickedLevel1Category={setClickedLevel1Category}
                      ></MallCategoryLevel1>

                      <IconRightArrow />

                      <MallCategoryLevel2
                        clickedLevel1Category={_clickedLevel1Category}
                        clickedLevel2Category={_clickedLevel2Category}
                        setClickedCategory={setClickedCategory}
                        setClickedLevel2Category={setClickedLevel2Category}
                      ></MallCategoryLevel2>

                      <IconRightArrow />

                      <MallCategoryLevel3
                        clickedLevel2Category={_clickedLevel2Category}
                        clickedLevel3Category={_clickedLevel3Category}
                        setClickedCategory={setClickedCategory}
                        setClickedLevel3Category={setClickedLevel3Category}
                      ></MallCategoryLevel3>
                    </div>
                  </>
                }
                firstRow={
                  <>
                    <div className="SearchSection">
                      <div className="ContentCBox">
                        <select className="SearchOption detail">
                          <option value="mallElementName">판매 이름</option>
                        </select>
                        <input
                          className="SearchBar detail"
                          placeholder="Search..."
                          value={_searchData.mallElementName || ''}
                          onInput={(e) => {
                            setSearchData((prev) => {
                              return {
                                ...prev,
                                mallElementName: e.target.value,
                              };
                            });
                          }}
                        />
                      </div>

                      <div className="ContentCBox">
                        <select className="SearchOption detail">
                          <option value="mallElementCode">판매 코드</option>
                        </select>
                        <input
                          className="SearchBar detail"
                          placeholder="Search..."
                          value={_searchData.mallElementCode || ''}
                          onInput={(e) => {
                            setSearchData((prev) => {
                              return {
                                ...prev,
                                mallElementCode: e.target.value,
                              };
                            });
                          }}
                        />
                      </div>

                      <div className="ContentCBox">
                        <div className="ResetButton detail" onClick={actReset}>
                          초기화
                        </div>

                        <div
                          className="DetailButton search"
                          style={{
                            backgroundColor: 'var(--ThirdBlue)',
                            columnGap: '3px',
                          }}
                          onClick={actSearch}
                        >
                          <SearchButtonIcon />
                          검색
                        </div>
                      </div>
                    </div>
                  </>
                }
              />
              <TableSection
                content={
                  <table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '50px', width: '50px' }}>
                          <input
                            type="checkBox"
                            name="allElements"
                            ref={ElementCheckBox}
                            onChange={(e) => {
                              const isChecked = e.target.checked;

                              if (isChecked) {
                                setCheckedItem((prev) => {
                                  const prevData = [...prev];

                                  const newData = _mallElementList.map(
                                    (thisItem) => {
                                      const thisFindIndex = prevData.findIndex(
                                        (thisIndex) =>
                                          thisIndex.mallElementId ===
                                          thisItem.mallElementId,
                                      );

                                      if (thisFindIndex !== -1) return null;
                                      else return thisItem;
                                    },
                                  );

                                  const filterData = newData.filter(
                                    (thisItem) => thisItem !== null,
                                  );

                                  return [...prevData, ...filterData];
                                });

                                e.target.checked = true;
                              } else {
                                setCheckedItem((prev) => {
                                  const prevData = prev.map((thisItem) => {
                                    const thisFindIndex =
                                      _mallElementList.findIndex(
                                        (thisIndex) =>
                                          thisIndex.mellElementId ===
                                          thisItem.mellElementId,
                                      );

                                    if (thisFindIndex !== -1) return null;
                                    else return thisItem;
                                  });

                                  const filterData = prevData.filter(
                                    (thisItem) => thisItem !== null,
                                  );

                                  return filterData;
                                });

                                e.target.checked = false;
                              }
                            }}
                          />
                        </th>
                        <th>카테고리</th>
                        <th>판매 물품정보</th>
                        <th>물품정보</th>
                        <th>단위</th>
                        <th>가격</th>
                        <th>규격</th>
                        <th>설명</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_mallElementList.map((thisItem, index) => {
                        return (
                          <tr key={index + '_mallElements'}>
                            <td style={{ minWidth: '50px', width: '50px' }}>
                              <input
                                type="checkBox"
                                name="mallElements"
                                data-key={thisItem.mallElementId}
                                checked={(() => {
                                  const checkedId = _checkedItem
                                    ? _checkedItem.findIndex(
                                      (thisIndex) =>
                                        thisIndex.mallElementId ===
                                        thisItem.mallElementId,
                                    )
                                    : -1;
                                  if (checkedId !== -1) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                })()}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  handleCheckedItem(thisItem, isChecked);
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              />
                            </td>
                            <td>{thisItem.mallCategoryName || ''}</td>
                            <td className="ElementInfos">
                              <ElementInfos>
                                <ElementInfo className="mall">
                                  <h5>이름</h5>
                                  <p>{thisItem.mallElementName}</p>
                                </ElementInfo>
                                <ElementInfo className="mall">
                                  <h5>코드</h5>
                                  <p>{thisItem.mallElementCode}</p>
                                </ElementInfo>
                              </ElementInfos>
                            </td>
                            <td className="ElementInfos">
                              <ElementInfos>
                                <ElementInfo>
                                  <h5>이름</h5>
                                  <p>{thisItem.elementName}</p>
                                </ElementInfo>
                                <ElementInfo>
                                  <h5>코드</h5>
                                  <p>{thisItem.elementCode}</p>
                                </ElementInfo>
                              </ElementInfos>
                            </td>
                            <td>{thisItem.elementUnit}</td>
                            <td>
                              {thisItem.mallElementPrice !== null && (
                                <CommaNum
                                  displayType={'text'}
                                  num={thisItem.mallElementPrice}
                                />
                              )}
                            </td>
                            <td>{thisItem.mallElementSize}</td>
                            <td>{thisItem.mallElementContent}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                }
              ></TableSection>{' '}
            </ModalMain>

            <PagingComponent
              page={_pageNum}
              count={_pageCount}
              size={10}
              pageEvent={(page) => {
                console.log('page : ', page);
                setPageNum(() => {
                  return page;
                });
              }}
            />

            <ModalFooter>
              <ModalButton
                onClick={() => {
                  props.buttonEvent(_checkedItem);
                }}
              >
                {props.buttonTitle}
              </ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default SelectMallElements;
