import axios from "axios";

import { getCookie } from "preferences/cookie/cookie";
import { API_BASE_URL, AXIOS_BASE_URL } from "preferences/server/constants";

/* auth */
export const AnyAxiosInterceptor = axios.create({ baseURL: API_BASE_URL + '/any' });
/* auth */
export const AuthAxiosInterceptor = axios.create({ baseURL: API_BASE_URL + '/auth' });

/* default */
export const AxiosInterceptor = axios.create({ baseURL: AXIOS_BASE_URL });
AxiosInterceptor.defaults.headers['Authorization'] = getCookie('accessToken');

/* mall */
export const MallAxiosInterceptor = axios.create({ baseURL: API_BASE_URL + '/mall/api' });
MallAxiosInterceptor.defaults.headers['Authorization'] = getCookie('accessToken');

/* cluster */
export const ClusterAxiosInterceptor = axios.create({ baseURL: API_BASE_URL + '/cluster' });
ClusterAxiosInterceptor.defaults.headers['Authorization'] = getCookie('accessToken');