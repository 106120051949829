import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import HalfMonitoring from 'pages/monitoring/contents/element/HalfMonitoring';
import MaterialMonitoring from 'pages/monitoring/contents/element/MaterialMonitoring';
import ProductMonitoring from 'pages/monitoring/contents/element/ProductMonitoring';
import ProductionMonitoring from 'pages/monitoring/contents/ProductionMonitoring';
import QualityMonitoring from 'pages/monitoring/contents/QualityMonitoring';

const TotalMonitoring = () => {
  const { userReducer } = useSelector((state) => state);

  const { setTimeCounter } = useOutletContext();

  const [_authority, setAuthority] = useState([]);
  const [_totalStatus, setTotalStatus] = useState(false);

  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('403') === true ||  // 작업지시
      thisItem.authority.startsWith('601') === true, // 품질관리
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);
    setTotalStatus(() => {
      if (authorityCodes.indexOf('403-1') !== -1 || authorityCodes.indexOf('403-2') !== -1 || authorityCodes.indexOf('601-1') !== -1 || authorityCodes.indexOf('601-2') !== -1) return true;
      else return false;
    })

    return () => { }
  }, [])

  return (
    <div className={_totalStatus ? 'monitoringBody total' : 'monitoringBody element'}>
      <MaterialMonitoring total={true} setTimeCounter={setTimeCounter} />
      {!_totalStatus ? (<><HalfMonitoring total={true} setTimeCounter={setTimeCounter} /></>) : null}
      <ProductMonitoring total={true} setTimeCounter={setTimeCounter} />
      {_totalStatus ? (
        <>
          <ProductionMonitoring total={true} setTimeCounter={setTimeCounter} />
          <QualityMonitoring total={true} setTimeCounter={setTimeCounter} />
        </>
      ) : null}
    </div>
  );
};

export default TotalMonitoring;