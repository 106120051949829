export const daerimReducer_reset = () => {
  return {
    type: 'DAERIMRESET'
  }
};

export const daerimReducer_setPlanContent = (thisContents) => {
  return {
    type: 'SETDAERIMPLANCONTENT',
    payload: thisContents
  }
};

export const daerimReducer_setCreatePlan = (thisContents) => {
  return {
    type: 'SETDAERIMCREATEPLAN',
    payload: thisContents
  }
};
export const daerimReducer_setCreatePlanArray = (thisContents) => {
  return {
    type: 'SETDAERIMCREATEPLANARRAY',
    payload: thisContents
  }
};

export const daerimReducer_setUpdatePlan = (thisContents) => {
  return {
    type: 'SETDAERIMUPDATEPLAN',
    payload: thisContents
  }
};

export const daerimReducer_setPlanElProduct = (thisContents) => {
  return {
    type: 'SETDAERIMPLANELPRODUCT',
    payload: thisContents
  }
};
export const daerimReducer_setPlanTargetProduct = (thisContents) => {
  return {
    type: 'SETDAERIMPLANTARGETPRODUCT',
    payload: thisContents
  }
};

export const daerimReducer_setPlanElProducts = (thisContents) => {
  return {
    type: 'SETDAERIMPLANELPRODUCTS',
    payload: thisContents
  }
};
export const daerimReducer_setPlanTargetProducts = (thisContents) => {
  return {
    type: 'SETDAERIMPLANTARGETPRODUCTS',
    payload: thisContents
  }
};
export const daerimReducer_setEquipment = (thisContents) => {
  return {
    type: 'SETDAERIMEQUIPMENT',
    payload: thisContents
  }
};
export const daerimReducer_setQualityCheckUser = (thisContents) => {
  return {
    type: 'SETDAERIMQUALITYCHECKUSER',
    payload: thisContents
  }
};
export const daerimReducer_setDeliveryUser = (thisContents) => {
  return {
    type: 'SETDAERIMDELIVERYUSER',
    payload: thisContents
  }
};
export const daerimReducer_setReceivingUser = (thisContents) => {
  return {
    type: 'SETDAERIMRECEIVINGUSER',
    payload: thisContents
  }
};
export const daerimReducer_setWorkOrderUser = (thisContents) => {
  return {
    type: 'SETDAERIMWORKORDERUSER',
    payload: thisContents
  }
};
export const daerimReducer_setCopyContent = (thisContents) => {
  return {
    type: 'SETDAERIMCOPYCONTENT',
    payload: thisContents
  }
};
export const daerimReducer_setUpdateContent = (thisContents) => {
  return {
    type: 'SETDAERIMUPDATECONTENT',
    payload: thisContents
  }
};
export const daerimReducer_setDeleteContent = (thisContents) => {
  return {
    type: 'SETDAERIMDELETECONTENT',
    payload: thisContents
  }
};

export const daerimReducer_setWorkOrderContent = (thisContents) => {
  return {
    type: 'SETDAERIMWORKORDERCONTENT',
    payload: thisContents
  }
};

export const daerimReducer_setWorkOrderContents = (thisContents) => {
  return {
    type: 'SETDAERIMWORKORDERCONTENTS',
    payload: thisContents
  }
};

export const daerimReducer_setUser = (thisContents) => {
  return {
    type: 'SETDAERIMUSER',
    payload: thisContents
  }
};

export const daerimReducer_setProcessUser = (thisContents) => {
  return {
    type: 'SETDAERIMPROCESSUSER',
    payload: thisContents
  }
};

export const daerimReducer_setPrevProcess = (thisContents) => {
  return {
    type: 'SETDAERIMPREVPROCESS',
    payload: thisContents
  }
};

export const daerimReducer_setWorkerScreenContent = (thisContents) => {
  return {
    type: 'SETDAERIMWORKERSCREENCONTENT',
    payload: thisContents
  }
};

export const daerimReducer_setFailedProcess = (thisContents) => {
  return {
    type: 'SETDAERIMFAILEDPROCESS',
    payload: thisContents
  }
};

// ===================================================================

export const daerimReducer_setCurrentFamilyId = (thisItem) => {
  return {
    type: 'MODALDAERIMCURRENTFAMILYID',
    payload: thisItem
  }
};

export const daerimReducer_setAllFamilies = (thisItem) => {
  return {
    type: 'MODALDAERIMALLFAMILIES',
    payload: thisItem
  }
};
export const daerimReducer_setFirstFamilies = (thisItem) => {
  return {
    type: 'MODALDAERIMFIRSTFAMILIES',
    payload: thisItem
  }
};
export const daerimReducer_setSecondFamilies = (thisItem) => {
  return {
    type: 'MODALDAERIMSECONDFAMILIES',
    payload: thisItem
  }
};
export const daerimReducer_setThirdFamilies = (thisItem) => {
  return {
    type: 'MODALDAERIMTHIRDFAMILIES',
    payload: thisItem
  }
};

export const daerimReducer_setFirstFamily = (thisItem) => {
  return {
    type: 'MODALDAERIMFIRSTFAMILY',
    payload: thisItem
  }
};
export const daerimReducer_setSecondFamily = (thisItem) => {
  return {
    type: 'MODALDAERIMSECONDFAMILY',
    payload: thisItem
  }
};
export const daerimReducer_setThirdFamily = (thisItem) => {
  return {
    type: 'MODALDAERIMTHIRDFAMILY',
    payload: thisItem
  }
};

export const daerimReducer_setProductType = (thisItem) => {
  return {
    type: 'MODALDAERIMPRODUCTTYPE',
    payload: thisItem
  }
};
export const daerimReducer_setWorkStatus = (thisItem) => {
  return {
    type: 'MODALDAERIMWORKSTATUS',
    payload: thisItem
  }
};
export const daerimReducer_setStartDate = (thisItem) => {
  return {
    type: 'MODALDAERIMSTARTDATE',
    payload: thisItem
  }
};
export const daerimReducer_setEndDate = (thisItem) => {
  return {
    type: 'MODALDAERIMENDDATE',
    payload: thisItem
  }
};
export const daerimReducer_setSearchOption = (thisItem) => {
  return {
    type: 'MODALDAERIMSEARCHOPTION',
    payload: thisItem
  }
};
export const daerimReducer_setSearchText = (thisItem) => {
  return {
    type: 'MODALDAERIMSEARCHTEXT',
    payload: thisItem
  }
};

export const daerimReducer_setCurrentPageNum = (thisItem) => {
  return {
    type: 'MODALDAERIMCURRENTPAGENUM',
    payload: thisItem
  }
};
export const daerimReducer_setTotalCount = (thisItem) => {
  return {
    type: 'MODALDAERIMTOTALCOUNT',
    payload: thisItem
  }
};
export const daerimReducer_setPageSize = (thisItem) => {
  return {
    type: 'MODALDAERIMPAGESIZE',
    payload: thisItem
  }
};