import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';

import { daerimReducer_setCreatePlanArray } from 'store/modules/actions/company/daerimActions';

import {
  AXIOS_BASE_HEADERS,
  AXIOS_BASE_URL,
} from 'preferences/server/constants';

import CommonSelectUserModal from 'pages/user/modal/CommonSelectUserModal';
import Grid3Body from 'components/layouts/body/Grid3Body';
import NavBar from 'components/nav/NavBar';
import SelectElements from 'pages/element/modal/SelectElements';

const MainSection = styled.main`
  display: grid;
  grid-template-rows: 66px auto 60px;
`;

const MainContents = styled.div`
  box-sizing: border-box;
  height: 100%;
  justify-content: center;
  padding: 10px;
  width: 100vw;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 6px;
  }
`;

const Info = styled.div`
  align-items: center;
  box-sizing: border-box;
  color: var(--MainRed);
  display: flex;
  font-weight: 700;
  height: 50px;
  justify-content: flex-start;
  padding: 0px 10px;
  width: 100%;
`;

const ContentSection = styled.div`
  box-sizing: border-box;
  padding: 10px 20px;
  width: 100%;
`;

const ContentHeader = styled.div`
  display: grid;
  row-gap: 20px;
`;

const HeaderTitle = styled.div`
  align-items: center;
  display: flex;
  font-size: 30px;
  font-weight: 600;
  justify-content: center;
  width: 100%;
  word-spacing: 60px;
`;

const HeaderCon = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderDate = styled.div`
  align-items: center;
  box-sizing: border-box;
  font-size: 20px;
  padding: 20px 0px;
`;

const HeaderTeam = styled.div`
  align-items: center;
  display: flex;
  column-gap: 10px;

  select {
    font-size: 16px;
    height: 40px;
    width: 150px;
  }
`;

const ContentTable = styled.div`
  margin-bottom: 66px;
  width: 100%;

  table {
    border-collapse: collapse;
    width: 100%;

    td {
      border: 1px solid var(--black);
      font-size: 15px;
      height: 50px;
      min-width: 100px;
      text-align: center;

      input {
        background-color: var(--white);
        box-sizing: border-box;
        font-size: 18px;
        height: 100%;
        padding: 10px;
        text-align: center;
        width: 100%;
      }

      input:disabled {
        background-color: var(--Bg);
      }
    }

    tfoot tr {
      height: 60px;
    }

    tfoot td {
      font-size: 20px;
      font-weight: 600;
      height: 50px;
      letter-spacing: 5px;
    }
  }
`;

const ContentBottom = styled.div`
  align-items: center;
  box-sizing: border-box;
  column-gap: 100px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const UserBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 350px;
`;

const UserInput = styled.div`
  align-items: center;
  background-color: var(--white);
  box-sizing: border-box;
  display: flex;
  height: 35px;
  justify-content: flex-start;
  padding: 10px;
  width: 180px;
`;

const FooterButtonSection = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const FooterButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  border-radius: unset;
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 50%;

  &.cancle {
    background-color: var(--white);
    color: var(--Text);
  }
`;

const SelectUserButton = styled.div`
  background-color: var(--ThirdBlue);
  border-radius: 5px;
  width: 80px;
`;

const setDateFormat = (thisDate) => {
  if (
    thisDate !== undefined &&
    thisDate !== null &&
    typeof thisDate === 'object'
  ) {
    const setDate =
      thisDate.length === 3
        ? thisDate.join('-')
        : thisDate.length > 6
        ? `${thisDate[0]}-${thisDate[1]}-${thisDate[2]} ${thisDate[3]}:${thisDate[4]}:${thisDate[5]}`
        : thisDate;
    const newDate = new Date(setDate);
    return moment(newDate).format('YYYY-MM-DD');
  } else {
    return thisDate;
  }
};

const UpdateDLMovement = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userReducer, pageReducer, daerimReducer } = useSelector(
    (state) => state,
  );

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_updateContent, setUpdateContents] = useState(
    daerimReducer.updateContent,
  );
  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,

    status: '',
    forwardingLogId: _updateContent.forwardingLogId,

    date: setDateFormat(_updateContent.date),

    deliveryTeam: _updateContent.deliveryTeam,
    receivingTeam: _updateContent.receivingTeam,

    deliveryUser: _updateContent.deliveryUser,
    deliveryUserId: _updateContent.deliveryUserId,

    receivingUser: _updateContent.receivingUser,
    receivingUserId: _updateContent.receivingUserId,

    qualityCheckUser: _updateContent.qualityCheckUser,
    qualityCheckUserId: _updateContent.qualityCheckUserId,

    forwardingLogElementList: [],
    deleteForwardingLogElementList: [],
  });

  const [_elementArray, setElementArray] = useState(
    _updateContent.forwardingLogElementList.map((thisItem) => {
      const returnData = {
        forwardingLogElementId: thisItem.forwardingLogElementId,

        ...thisItem.element,

        amount: thisItem.amount,
        remark: thisItem.remark,
      };

      return returnData;
    }),
  );

  const [_productModalStatus, setProductModalStatus] = useState(false);
  const [_qualityCheckUserModalStatus, setQualityCheckUserModalStatus] =
    useState(false);
  const [_deliveryUserModalStatus, setDeliveryUserModalStatus] =
    useState(false);
  const [_receivingUserModalStatus, setReceivingUserModalStatus] =
    useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    setOnload('loaded');

    return () => {};
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      console.log(
        'daerimReducer.createPlanArray : ',
        daerimReducer.createPlanArray,
      );
      if (daerimReducer.createPlanArray.length < 1) return;

      const newList = daerimReducer.createPlanArray.map((thisItem) => {
        const checkedIndex = _elementArray.findIndex(
          (thisIndex) => thisIndex.elementId === thisItem.elementId,
        );
        if (checkedIndex !== -1) return null;

        const returnData = {
          ...thisItem,
          amount: 0,
          remark: '',
        };
        return returnData;
      });
      const setNewList = newList.filter((thisItem) => thisItem !== null);
      if (setNewList.length > 0) {
        setElementArray((prev) => {
          return [...prev, ...setNewList];
        });
      }
    }
    return () => {};
  }, [daerimReducer.createPlanArray]);

  /* ====================================================================== #4 */

  /* ====================================================================== #5 */
  window.oncontextmenu = () => {
    return false;
  };

  const handleInputEvent = (e, element, index) => {
    const { name, value } = e.target;

    const newData = { ...element, [name]: value };

    const newList = [..._elementArray];
    newList.splice(index, 1, newData);

    setElementArray(() => {
      return newList;
    });
  };

  const actDelete = (element, index) => {
    const newList = [..._elementArray];
    newList.splice(index, 1);
    setElementArray(() => {
      return newList;
    });
  };

  const actUpdate = async (e) => {
    e.preventDefault();
    if (_elementArray.length < 1) return alert('물품을 추가해주세요');
    if (_formData.deliveryUser === undefined || _formData.deliveryUser === null)
      return alert('전달자를 선택해주세요.');

    const BodyToPut = { ..._formData };
    if (
      _formData.qualityCheckUser !== undefined &&
      _formData.qualityCheckUser !== null
    ) {
      BodyToPut.qualityCheckUserId = _formData.qualityCheckUser.id;
      delete BodyToPut.qualityCheckUser;
    }
    if (
      _formData.deliveryUser !== undefined &&
      _formData.deliveryUser !== null
    ) {
      BodyToPut.deliveryUserId = _formData.deliveryUser.id;
      delete BodyToPut.deliveryUser;
    }
    if (
      _formData.receivingUser !== undefined &&
      _formData.receivingUser !== null
    ) {
      BodyToPut.receivingUserId = _formData.receivingUser.id;
      delete BodyToPut.receivingUser;
    }

    BodyToPut.forwardingLogElementList = _elementArray.map((thisItem) => {
      const returnData = {
        elementId: thisItem.elementId,
        amount: thisItem.amount,
        remark: thisItem.remark,
      };
      if (
        thisItem.forwardingLogElementId !== undefined &&
        thisItem.forwardingLogElementId !== null
      ) {
        returnData.forwardingLogElementId = thisItem.forwardingLogElementId;
      }

      return returnData;
    });

    const checkArray = _elementArray.filter(
      (thisEl) =>
        thisEl.forwardingLogElementId !== undefined &&
        thisEl.forwardingLogElementId !== null,
    );
    const deleteForwardingLogElementList =
      _updateContent.forwardingLogElementList.map((thisItem) => {
        const checkedIndex = checkArray.findIndex(
          (thisIndex) =>
            thisIndex.forwardingLogElementId ===
            thisItem.forwardingLogElementId,
        );
        if (checkedIndex !== -1) return null;
        else return thisItem.forwardingLogElementId;
      });
    BodyToPut.deleteForwardingLogElementList =
      deleteForwardingLogElementList.filter((thisItem) => thisItem !== null);
    console.log('actUpdate - BodyToPut : ', BodyToPut);

    await axios
      .put(
        AXIOS_BASE_URL + '/forwardingLog/' + _formData.forwardingLogId,
        BodyToPut,
        AXIOS_BASE_HEADERS,
      )
      .then((response) => {
        if (response === undefined) return;
        console.log('actUpdate - response : ', response);
        alert('공정이동표를 수정했습니다');

        navigate(pageReducer.currentPage, { replace: true });
      })
      .catch((error) => {
        console.log('actUpdate - error : ', error);
      });
  };

  /* ====================================================================== #6 */

  return (
    <Grid3Body
      contents={
        <>
          <MainSection className="Main">
            <NavBar
              title={'공정이동표 수정'}
              buttons={
                <button
                  onClick={() => {
                    dispatch(daerimReducer_setCreatePlanArray([]));
                    setTimeout(setProductModalStatus(true), 1000);
                  }}
                >
                  물품 추가
                </button>
              }
              nav={''}
            />

            <MainContents>
              <Info>물품, 사용자 삭제 → 마우스 오른쪽 클릭</Info>

              <ContentSection>
                <ContentHeader>
                  <HeaderTitle>공정이동표</HeaderTitle>

                  <HeaderCon>
                    <HeaderDate>
                      날짜 :{' '}
                      {moment(_formData.date).format('YYYY년 MM월 DD일(ddd)')}
                    </HeaderDate>

                    <HeaderTeam>
                      <select
                        name="deliveryTeam"
                        value={_formData.deliveryTeam}
                        onChange={(e) => {
                          if (e.target.value === '') return;
                          setFormData((prev) => {
                            return { ...prev, deliveryTeam: e.target.value };
                          });
                        }}
                      >
                        <option value="">전달팀</option>
                        <option value="주조">주조</option>
                        <option value="가공">가공</option>
                        <option value="외주-용출">외주-용출</option>
                        <option value="외주-연마">외주-연마</option>
                        <option value="외주-도금">외주-도금</option>
                        <option value="조립">조립</option>
                      </select>

                      <div> → </div>

                      <select
                        name="receivingTeam"
                        value={_formData.receivingTeam}
                        onChange={(e) => {
                          if (e.target.value === '') return;
                          setFormData((prev) => {
                            return { ...prev, receivingTeam: e.target.value };
                          });
                        }}
                      >
                        <option value="">수신팀</option>
                        <option value="주조">주조</option>
                        <option value="가공">가공</option>
                        <option value="외주-용출">외주-용출</option>
                        <option value="외주-연마">외주-연마</option>
                        <option value="외주-도금">외주-도금</option>
                        <option value="조립">조립</option>
                      </select>
                    </HeaderTeam>
                  </HeaderCon>
                </ContentHeader>

                <ContentTable>
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ minWidth: '60px', width: '60px' }}>
                          번호
                        </td>
                        <td>품번</td>
                        <td>품명</td>
                        <td>수량</td>
                        <td>비고</td>
                      </tr>
                      {_elementArray.map((thisItem, index) => {
                        const elNumber = (index + 1)
                          .toString()
                          .padStart(2, '0');

                        return (
                          <tr
                            key={thisItem.elementId + '_' + elNumber}
                            className="RemoveRow"
                            onContextMenu={() => {
                              actDelete(thisItem, index);
                            }}
                          >
                            <td>{elNumber}</td>
                            <td>{thisItem.elementCode}</td>
                            <td>{thisItem.elementName}</td>
                            <td>
                              <input
                                type="number"
                                name="amount"
                                value={thisItem.amount}
                                onInput={(e) => {
                                  if (e.target.value.length > 15) return;
                                  handleInputEvent(e, thisItem, index);
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="remark"
                                value={thisItem.remark}
                                onInput={(e) => {
                                  handleInputEvent(e, thisItem, index);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </ContentTable>
              </ContentSection>
            </MainContents>

            <ContentBottom>
              <UserBox>
                <div>품관 : </div>
                <UserInput
                  className="RemoveRow"
                  onContextMenu={() => {
                    setFormData((prev) => {
                      const prevData = { ...prev };
                      if (
                        prev.qualityCheckUser !== undefined ||
                        prev.qualityCheckUser !== null
                      ) {
                        delete prevData.qualityCheckUser;
                      }

                      return prevData;
                    });
                  }}
                >
                  {_formData.qualityCheckUser &&
                    _formData.qualityCheckUser.name}
                </UserInput>
                <SelectUserButton
                  className="formButton"
                  onClick={() => {
                    setQualityCheckUserModalStatus(() => {
                      return true;
                    });
                  }}
                >
                  선택
                </SelectUserButton>
              </UserBox>

              <UserBox>
                <div>전달자 : </div>
                <UserInput
                  className="RemoveRow"
                  onContextMenu={() => {
                    setFormData((prev) => {
                      const prevData = { ...prev };
                      if (
                        prev.deliveryUser !== undefined ||
                        prev.deliveryUser !== null
                      ) {
                        delete prevData.deliveryUser;
                      }

                      return prevData;
                    });
                  }}
                >
                  {_formData.deliveryUser && _formData.deliveryUser.name}
                </UserInput>
                <SelectUserButton
                  className="formButton"
                  onClick={() => {
                    setDeliveryUserModalStatus(() => {
                      return true;
                    });
                  }}
                >
                  선택
                </SelectUserButton>
              </UserBox>

              <UserBox>
                <div>수신자 : </div>
                <UserInput
                  className="RemoveRow"
                  onContextMenu={() => {
                    setFormData((prev) => {
                      const prevData = { ...prev };
                      if (
                        prev.receivingUser !== undefined ||
                        prev.receivingUser !== null
                      ) {
                        delete prevData.receivingUser;
                      }

                      return prevData;
                    });
                  }}
                >
                  {_formData.receivingUser && _formData.receivingUser.name}
                </UserInput>
                <SelectUserButton
                  className="formButton"
                  onClick={() => {
                    setReceivingUserModalStatus(() => {
                      return true;
                    });
                  }}
                >
                  선택
                </SelectUserButton>
              </UserBox>
            </ContentBottom>
          </MainSection>

          <FooterButtonSection>
            <FooterButton
              className="cancle"
              onClick={(e) => {
                e.preventDefault();
                navigate(pageReducer.currentPage, { replace: true });
              }}
            >
              취소
            </FooterButton>
            <FooterButton onClick={actUpdate}>저장</FooterButton>
          </FooterButtonSection>

          {_productModalStatus === true && (
            <SelectElements
              buttonEvent={(data) => {
                console.log('data : ', data);
                const createPlanIdList = daerimReducer.createPlanArray.map(
                  (thisItem) => thisItem.elementId,
                );
                const newCreatePlan = data.map((thisItem) => {
                  if (createPlanIdList.indexOf(thisItem.elementId) !== -1)
                    return null;
                  else return thisItem;
                });
                const setNewCreatePlan = newCreatePlan.filter(
                  (thisItem) => thisItem !== null,
                );
                const createPlanArray = [
                  ...daerimReducer.createPlanArray,
                  ...setNewCreatePlan,
                ];
                dispatch(daerimReducer_setCreatePlanArray(createPlanArray));

                setProductModalStatus(false);
              }}
              buttonTitle="물품 선택"
              open={_productModalStatus}
              close={() => {
                setProductModalStatus(false);
              }}
            />
          )}

          {(_qualityCheckUserModalStatus ||
            _deliveryUserModalStatus ||
            _receivingUserModalStatus) && (
            <CommonSelectUserModal
              userEvent={(userData) => {
                console.log('userData : ', userData);
                setFormData((prev) => {
                  const eventKey = _qualityCheckUserModalStatus
                    ? 'qualityCheckUser'
                    : _deliveryUserModalStatus
                    ? 'deliveryUser'
                    : _receivingUserModalStatus
                    ? 'receivingUser'
                    : null;

                  const returnData = { ...prev };
                  returnData[eventKey] = userData;
                  console.log('returnData : ', returnData);
                  return returnData;
                });
              }}
              open={
                _qualityCheckUserModalStatus ||
                _deliveryUserModalStatus ||
                _receivingUserModalStatus
              }
              close={() => {
                if (_qualityCheckUserModalStatus) {
                  setQualityCheckUserModalStatus(() => {
                    return false;
                  });
                } else if (_deliveryUserModalStatus) {
                  setDeliveryUserModalStatus(() => {
                    return false;
                  });
                } else if (_receivingUserModalStatus) {
                  setReceivingUserModalStatus(() => {
                    return false;
                  });
                }
              }}
            ></CommonSelectUserModal>
          )}
        </>
      }
    />
  );
};

export default UpdateDLMovement;
