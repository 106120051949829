import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import styled from 'styled-components';

import { daerimReducer_setWorkOrderContents } from 'store/modules/actions/company/daerimActions';
import { modalFileActions_setFileReset } from 'store/modules/actions/modal/modalFileActions';
import { workerReducer_setWorkOrderLogManagerIds, workerReducer_setWorkOrderLogWorkderIds } from 'store/modules/actions/common/workerActions';

import { attachFileApi } from 'api/apis/attachFileApi';
import { workOrderTypeApi } from 'api/apis/workOrderTypeApi';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import SelectEquipmentModal from 'pages/equipment/modal/SelectEquipmentModal';
import SelectFiles from 'pages/file/modal/SelectFiles';
import SelectOneAccount from 'pages/account/modal/SelectOneAccount';
import SelectProcesses from 'pages/process/modal/SelectProcesses';
import SelectUsers from 'pages/user/modal/SelectUsers';
import TableSection from 'components/layouts/table/TableSection';
import UpdateProcessRow from 'components/row/UpdateProcessRow';

const MainSection = styled.main`
  width: 100vw;
`;

const TableFormbox = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  width: 100%;
  .TableSection {overflow: auto;}
  & > .TableSection {
    &::-webkit-scrollbar-thumb {background-color: var(--ThirdBlue);}
  }
`;

const DateInput = styled.input`
  border: 1px solid var(--gray-200);
  border-radius: 5px;
  box-sizing: border-box;
  height: 40px;
  padding: 5px;
  &:focus {border: 1px solid var(--gray-200);}
`;

const AccountInput = styled.input`
  background-color: var(--ThirdBlue);
  color: var(--white);
  &::placeholder {color: var(--white);}
`;

const DateCheckBox = styled.div`
  align-items: center;
  column-gap: 10px;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  input {margin: unset;}
`;

const FileList = styled.div`
  width: 100%;
`;
const FileBox = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  height: 40px;
  justify-content: flex-start;
  margin: 5px auto;
  padding: 0px 15px;
  position: relative;
  width: 100%;
`;
const DeleteFileButton = styled.div`
  height: 15px;
  position: absolute;
  right: 15px;
  width: 15px;
  div {background-color: var(--white);cursor: pointer;height: 15px;width: 15px;}
`;

const ContentList = styled.div`
  width: 100%;
`;
const ContentBox = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  height: 40px;
  justify-content: flex-start;
  margin: 5px auto;
  padding: 0px 15px;
  position: relative;
  width: 100%;
`;
const DeleteButton = styled.div`
  height: 15px;
  position: absolute;
  right: 15px;
  width: 15px;
  div {background-color: var(--white);cursor: pointer;height: 15px;width: 15px;}
`;

const CreateFailedProductionOrder = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { daerimReducer, pageReducer, userReducer, workerReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);
  console.log('_authority : ', _authority);

  const _prevData = daerimReducer.copyContent;
  const [_formData, setFormData] = useState({
    parentId: _prevData.workOrderLogId, // 불량재작업 ***

    workOrderTypeId: _prevData.workOrderTypeId || '',

    workOrderStatus: _prevData.workOrderStatus,

    workOrderName: _prevData.workOrderName,
    workOrderCode: _prevData.workOrderCode,

    workPlace: _prevData.workPlace,
    detailedWorkPlace: _prevData.detailedWorkPlace,

    message: _prevData.message,
    remark: _prevData.remark,

    workOrderCustomizedContent: JSON.stringify({}),

    companyId: userReducer.company.companyId,
    accountId: _prevData.accountId,
    accountName: _prevData.accountName,
    userId: _prevData.userId,
    userName: _prevData.userName,

    workOrderScheduledStartDate: DateFormat(_prevData.workOrderScheduledStartDate),
    workOrderScheduledEndDate: DateFormat(_prevData.workOrderScheduledEndDate),

    amount: _prevData.amount,

    processLogList: [],

    managers: [],
    workers: [],
  });

  const [_processList, setProcessList] = useState(
    _prevData.processLogList.map((thisItem, index) => {
      const returnData = {
        existProcessOutElement: thisItem.existProcessOutElement,

        processTypeName: thisItem.processTypeName,

        processStatus: thisItem.processStatus,

        processLogId: thisItem.processLogId,
        processCode: thisItem.processCode,
        processName: thisItem.processName,

        processNumber: thisItem.processNumber || index + 1,

        processingCount: thisItem.processingCount || 1,
        processAmount: thisItem.processAmount || 1,

        amount: _prevData.amount || 1,

        count: thisItem.workOrderProcessCount || 1,

        target: thisItem.target || false,

        equipmentId: thisItem.equipmentId,
        equipmentName: thisItem.equipmentName,

        userId: thisItem.userId,
        userName: thisItem.userName,

        processLogElementList:
          thisItem.processLogElementList !== undefined &&
            thisItem.processLogElementList !== null &&
            thisItem.processLogElementList !== false &&
            thisItem.processLogElementList.length > 0
            ? thisItem.processLogElementList
            : [],

        deleteProcessLogElementList: [],

        taskLogList: [],
        deleteTaskIds: [],

        managers: [],
        workers: [],
      };

      return returnData;
    }),
  );

  const [_processModalStatus, setProcessModalStatus] = useState(false);

  const [_equipmentModalStatus, setEquipmentModalStatus] = useState(false);
  const [_equipmentPos, setEquipmentPos] = useState();

  const [_workOrderTypes, setWorkOrderTypes] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_processAmounts, setProcessAmounts] = useState({});

  const [_account, setAccount] = useState({ accountId: _prevData.accountId, accountName: _prevData.accountName });
  const [_accountModalStatus, setAccountModalStatus] = useState(false);

  const [_fileList, setFileList] = useState([]);
  const [_fileModalStatus, setFileModalStatus] = useState(false);

  const [_workerModal, setWorkerModal] = useState();
  const [_workerModalStatus, setWorkerModalStatus] = useState(false);

  const [_eventProcessLog, setEventProcessLog] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('104') === true || // 거래처
      thisItem.authority.startsWith('108') === true || // 설비
      thisItem.authority.startsWith('109') === true || // 파일
      thisItem.authority.startsWith('411') === true, // 불량재작업
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    getWorkOrderTypes();
    getWorkOrderData();

    getFileList();

    setOnload('loaded');

    return () => { };
  }, []);

  // 설비 선택
  useEffect(() => {
    if (_onload === 'loaded') {
      if (Object.keys(daerimReducer.equipment).length > 0) {
        const thisKey = daerimReducer.equipment.processKey;
        if (thisKey.includes('_') !== true) return;

        const thisId = Number(thisKey.split('_')[0]);
        const thisIndex = Number(thisKey.split('_')[1]);
        console.log(thisId, thisIndex, thisKey);

        setProcessList((prev) => {
          const prevData = [...prev];
          const newData = prevData[thisIndex];
          newData.equipmentId = daerimReducer.equipment.equipmentId;
          newData.equipmentName = daerimReducer.equipment.equipmentName;
          prevData.splice(thisIndex, 1, newData);
          return prevData;
        });
      }
    }

    return () => { };
  }, [daerimReducer.equipment]);

  /* ====================================================================== #4 */
  const getWorkOrderTypes = async () => {
    const BodyToPost = { companyId: userReducer.company.companyId };
    await workOrderTypeApi.searchWorkOrderType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderTypeApi.searchWorkOrderType : ', response);
      setWorkOrderTypes(() => { return response.data; });
    });
  };

  const getWorkOrderData = async () => {
    await workOrderLogApi.getWorkOrderLog(_prevData.workOrderLogId).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.getWorkOrderLog : ', response);
      Finish_getWorkOrderData(response.data);
    });
  };
  const Finish_getWorkOrderData = (Result) => {
    const Processes = Result.processLogList.map((thisItem) => {
      const returnData = {
        existProcessOutElement: thisItem.existProcessOutElement,

        processTypeName: thisItem.processTypeName,

        processStatus: thisItem.processStatus,

        processLogId: thisItem.processLogId,
        processName: thisItem.processName,
        processCode: thisItem.processCode,

        processNumber: thisItem.processNumber,
        processingCount: thisItem.processingCount,
        processAmount: thisItem.processAmount || 1,

        amount: Result.amount || 1,

        count: thisItem.workOrderProcessCount || 1,

        target: thisItem.target || false,

        equipmentId: thisItem.equipmentId,
        equipmentName: thisItem.equipmentName,

        userId: thisItem.userId,
        userName: thisItem.userName,

        processLogElementList:
          thisItem.processLogElementList !== undefined &&
            thisItem.processLogElementList !== null &&
            thisItem.processLogElementList !== false &&
            thisItem.processLogElementList.length > 0
            ? thisItem.processLogElementList
            : [],

        deleteProcessLogElementList: [],

        taskLogList: [],
        deleteTaskIds: [],

        managers:
          thisItem.managers !== undefined &&
            thisItem.managers !== null &&
            thisItem.managers !== false &&
            thisItem.managers.length > 0
            ? thisItem.managers.map((worker) => {
              return { id: worker.managerId, name: worker.managerName };
            })
            : [],
        workers:
          thisItem.workers !== undefined &&
            thisItem.workers !== null &&
            thisItem.workers !== false &&
            thisItem.workers.length > 0
            ? thisItem.workers.map((worker) => {
              return { id: worker.workerId, name: worker.workerName };
            })
            : [],
      };
      return returnData;
    });

    Processes.sort((a, b) => { return a.processNumber - b.processNumber; });
    setProcessList(() => { return Processes; });

    const formData = {
      ..._formData,

      workOrderTypeId: Result.workOrderTypeId,
      workOrderTypeName: Result.workOrderTypeName,
      workOrderCode: Result.workOrderCode,
      workOrderName: Result.workOrderName,

      workPlace: Result.workPlace,

      remark: Result.remark,

      managers:
        Result.managers !== undefined &&
          Result.managers !== null &&
          Result.managers !== false &&
          Result.managers.length > 0
          ? Result.managers.map((worker) => {
            return { id: worker.managerId, name: worker.managerName };
          })
          : [],
      workers:
        Result.workers !== undefined &&
          Result.workers !== null &&
          Result.workers !== false &&
          Result.workers.length > 0
          ? Result.workers.map((worker) => {
            return { id: worker.workerId, name: worker.workerName };
          })
          : [],
    };
    setFormData(() => { return formData; });

    if (Result.account !== undefined && Result.account !== null) {
      setAccount(() => { return Result.account; });
    }
    if (Result.managers !== undefined && Result.managers !== null && Result.managers !== false && Result.managers.length > 0) {
      dispatch(workerReducer_setWorkOrderLogManagerIds(formData.managers));
    }
    if (Result.workers !== undefined && Result.workers !== null && Result.workers !== false && Result.workers.length > 0) {
      dispatch(workerReducer_setWorkOrderLogWorkderIds(formData.workers));
    }
  };

  const getFileList = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      workOrderLogId: _prevData.workOrderLogId,
    };
    await attachFileApi.searchAttachFile('', BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('attachFileApi.searchAttachFile : ', response);
      setFileList(() => {
        const fileList = response.data.content.map((thisItem) => {
          let returnData = thisItem;
          if (typeof thisItem.fileData === 'object' && Object.keys(thisItem.fileData).length > 0) {
            returnData = { ...thisItem, ...thisItem.fileData };
          }
          return returnData;
        });
        return fileList;
      });
    });
  };

  /* ====================================================================== #5 */
  const handleChangeEvent = (e) => {
    const { name, value } = e.target;
    setFormData({ ..._formData, [name]: value });
  };

  const actDeleteFile = (e, index, file) => {
    setFileList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);
      return prevData;
    });
  };

  const handleCheckedItem = (index, process, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(process);
    setCheckedItem(() => { return checkedItem; });
    console.log(index, process, isChecked);
    setProcessList((prev) => {
      const newData = prev.map((thisItem, prevIndex) => {
        const returnData = { ...thisItem };
        delete returnData.target;
        if (isChecked && thisItem.processId === process.processId && prevIndex === index) returnData.target = true;
        return returnData;
      });
      return newData;
    });
  };

  const handleTotalAmountEvent = (e) => {
    const { name, value } = e.target;

    let eventValue = value;
    if (!eventValue.toString().startsWith('0.')) eventValue = eventValue.toString().replace(/^0+/, '');
    if (eventValue === '' || eventValue < 0) eventValue = 0;
    if (eventValue.length > 15) return;

    setFormData({ ..._formData, [name]: eventValue });

    const copyList = [..._processList];

    const setAmountList = copyList.map((thisItem) => {
      const count = new BigNumber(thisItem.count || 1);
      const totalAmount = new BigNumber(eventValue);
      const calAmount = count.multipliedBy(totalAmount); // parseFloat(count * Number(value));
      console.log('calAmount : ', calAmount);

      return {
        ...thisItem,
        amount: totalAmount.toNumber(),
        processAmount: calAmount.toNumber(),
        processingCount: calAmount.toNumber(),
      };
    });

    setProcessList(setAmountList);
  };

  const handleNumberEvent = (e, index, process) => {
    const newData = { ...process, processNumber: BigNumber(e.target.value).toNumber() };
    setProcessList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);
      prevData.splice(BigNumber(e.target.value).minus(1).toNumber(), 0, newData);
      const setPrevData = prevData.map((thisItem, index) => {
        return { ...thisItem, processNumber: index + 1 };
      });
      return setPrevData;
    });
  };

  const handleProcessAmountEvent = (e) => {
    const { value } = e.target;
    const thisKey = e.target.dataset.key;
    if (thisKey.includes('_') !== true) return;

    const thisId = Number(thisKey.split('_')[0]);
    const thisIndex = Number(thisKey.split('_')[1]);
    const thisType = thisKey.split('_')[2];
    console.log(thisId, thisIndex, thisType, thisKey);

    const prev = { ..._processAmounts };
    prev[thisId + '_' + thisIndex + '_' + thisType] = value;

    setProcessAmounts(prev);

    const copyList = [..._processList];

    function checkIndex(element, index) {
      if (thisType === 'prev') {
        if (element.processLogId === thisId && index === thisIndex) return true;
      }
      if (thisType === 'new') {
        if (element.processId === thisId && index === thisIndex) return true;
      }
    }

    const thisItem = copyList.find(checkIndex);
    const thisFindIndex = copyList.indexOf(thisItem);
    console.log('thisItem : ', thisItem, 'thisFindIndex : ', thisFindIndex);
    thisItem.processAmount = Number(value);
    thisItem.processingCount = Number(value);

    copyList.splice(thisFindIndex, 1, thisItem);
    setProcessList(copyList);
  };

  const handleProcessCountingEvent = (e) => {
    const { value } = e.target;
    const thisKey = e.target.dataset.key;
    if (thisKey.includes('_') !== true) return;

    const thisId = Number(thisKey.split('_')[0]);
    const thisIndex = Number(thisKey.split('_')[1]);
    const thisType = thisKey.split('_')[2];
    console.log(thisId, thisIndex, thisType, thisKey);

    const copyList = [..._processList];

    function checkIndex(element, index) {
      if (thisType === 'prev') {
        if (element.processLogId === thisId && index === thisIndex) return true;
      }
      if (thisType === 'new') {
        if (element.processId === thisId && index === thisIndex) return true;
      }
    }

    const thisItem = copyList.find(checkIndex);
    const thisFindIndex = copyList.indexOf(thisItem);
    console.log('thisItem : ', thisItem, 'thisFindIndex : ', thisFindIndex);
    thisItem.processingCount = Number(value);

    copyList.splice(thisFindIndex, 1, thisItem);
    setProcessList(copyList);
  };

  const delItem = (index, process) => {
    setProcessList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);
      const setPrevData = prevData.map((thisItem, index) => {
        return { ...thisItem, processNumber: index + 1 };
      });
      return setPrevData;
    });
  };

  const actCreate = async (e) => {
    e.preventDefault();

    if (_formData.workOrderCode === '') return alert('작업코드를 입력해주세요.');
    if (_formData.workOrderName === '') return alert('작업이름을 입력해주세요.');

    // 목표공정 체크
    const lastProcessKey = document.querySelector('input[name="processes"]:checked');
    console.log('lastProcessKey : ', lastProcessKey);
    if (lastProcessKey === undefined || lastProcessKey === null) return alert('목표공정을 선택해주세요.');

    const emptyNumbers = _processList.filter((thisItem) => thisItem.processNumber === undefined || thisItem.processNumber === null);
    if (_processList.length > 0 && emptyNumbers.length > 0) return alert('공정순서를 입력해주세요.');

    const processNumberList = _processList.map((thisItem) => thisItem.processNumber);
    const setProcessNumberList = new Set(processNumberList);
    if (processNumberList.length !== setProcessNumberList.size) return alert('중복된 공정 순서가 존재합니다.');

    const sortProcessList = _processList.sort((a, b) => { return a.processNumber - b.processNumber; });
    console.log('sortProcessList : ', sortProcessList);

    const BodyToPost = {
      ..._formData,

      managerIds:
        workerReducer.workOrderLogManagerIds !== undefined &&
          workerReducer.workOrderLogManagerIds !== null &&
          workerReducer.workOrderLogManagerIds.length > 0
          ? workerReducer.workOrderLogManagerIds.map((worker) => worker.id)
          : [],
      workerIds:
        workerReducer.workOrderLogWorkerIds !== undefined &&
          workerReducer.workOrderLogWorkerIds !== null &&
          workerReducer.workOrderLogWorkerIds.length > 0
          ? workerReducer.workOrderLogWorkerIds.map((worker) => worker.id)
          : [],

      amount: Number(_formData.amount),

      processLogList: sortProcessList.map((thisItem, index) => {
        // console.log('thisItem : ', thisItem);
        // 공정완료제품 체크
        const checkOutItem =
          thisItem.processLogId !== undefined
            ? thisItem.processLogElementList.filter((thisItem) => thisItem.processLogElementType === 'Out' || thisItem.processLogElementType === 'Make')
            : thisItem.processElementList.filter((thisItem) => thisItem.processElementType === 'Out' || thisItem.processElementType === 'Make');

        const returnData = {
          existProcessOutElement: false,

          processTypeName: thisItem.processTypeName,

          processId: thisItem.processId,
          processCode: thisItem.processCode,
          processName: thisItem.processName,

          processNumber: thisItem.processNumber || index + 1, // 공정순서
          processingCount: thisItem.processingCount || Number(_formData.amount), // 묶음처리 횟수
          processAmount: thisItem.processAmount || 1, // 공정횟수

          workOrderProcessCount: thisItem.count || 1, // 기준 공정횟수

          processCustomizedContent: JSON.stringify({}),

          equipmentId: thisItem.equipmentId || null, // 설비
          userId: thisItem.userId || null, // 작업자

          processLogElementList:
            thisItem.processLogId !== undefined
              ? thisItem.processLogElementList.map((thisEl) => {
                const returnElData = {
                  processLogElementType: thisEl.processLogElementType,
                  processLogElementId: thisEl.processLogElementId,
                  elementId: thisEl.elementId,
                  amount: thisEl.amount,
                };
                return returnElData;
              })
              : thisItem.processElementList.map((thisEl) => {
                const returnElData = {
                  processLogElementType: thisEl.processElementType,
                  elementId: thisEl.elementId,
                  amount: thisEl.amount,
                };
                return returnElData;
              }),

          taskLogList: [],

          managerIds:
            thisItem.managers !== undefined &&
              thisItem.managers !== null &&
              thisItem.managers.length > 0
              ? thisItem.managers.map((worker) => worker.id)
              : [],
          workerIds:
            thisItem.workers !== undefined &&
              thisItem.workers !== null &&
              thisItem.workers.length > 0
              ? thisItem.workers.map((worker) => worker.id)
              : [],
        };

        if (thisItem.processLogId !== undefined && thisItem.processLogId !== null) returnData.processLogId = thisItem.processLogId;
        if (thisItem.processId !== undefined && thisItem.processId !== null) returnData.processId = thisItem.processId;
        if (checkOutItem.length > 0) returnData.existProcessOutElement = true;
        if (thisItem.target !== undefined && thisItem.target !== null && thisItem.target === true) returnData.target = true;
        console.log('returnData : ', returnData);
        return returnData;
      })
    };
    if (_account.accountId !== undefined && _account.accountId !== null && _account.accountId !== '') {
      BodyToPost.accountId = _account.accountId;
    }
    console.log('BodyToPost : ', BodyToPost, JSON.stringify(BodyToPost));

    await workOrderLogApi.createWorkOrderLog(BodyToPost).then(async (response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.createWorkOrderLog : ', response);

      if (_fileList.length > 0) {
        _fileList.forEach(async (file) => {
          const BodyToFilePost = {
            companyId: userReducer.company.companyId,
            fileDataId: file.fileDataId,
            workOrderLogId: response.data.workOrderLogId,
          };
          console.log('BodyToFilePost : ', BodyToFilePost);
          await attachFileApi.createAttachFile(BodyToFilePost).then((response) => {
            if (response === undefined) return;
            console.log('attachFileApi.createAttachFile : ', response);
          });
        });
      }

      response.data.processLogList.forEach(async (processLog, index) => {
        const BodyToSearch = {
          companyId: userReducer.company.companyId,
          processId: BodyToPost.processLogList[index].processId,
        };
        console.log('BodyToSearch : ', BodyToSearch);
        await attachFileApi.searchAttachFile('', BodyToSearch).then((searchResponse) => {
          if (searchResponse === undefined) return;
          console.log('attachFileApi.searchAttachFile : ', searchResponse);
          searchResponse.data.content.forEach(async (file) => {
            const BodyToFile = {
              companyId: userReducer.company.companyId,
              fileDataId: file.fileDataId,
              processLogId: processLog.processLogId,
            };
            console.log('BodyToFile : ', BodyToFile);
            await attachFileApi.createAttachFile(BodyToFile).then((response) => {
              if (response === undefined) return;
              console.log('attachFileApi.createAttachFile : ', response);
            });
          });
        });
      });

      const alertText = `
      ${userReducer.userMenu.find((thisItem) => {
        if (thisItem.pageCode === '403') return true;
        else return false;
      }).pageName}를 추가했습니다.
        `;
      alert(alertText);
      setTimeout(navigate(pageReducer.currentPage, { replace: true }), 1000);
    });
  };

  // 설비 선택 팝업
  const selectEquipmentEvent = (e) => {
    e.preventDefault();
    const equipmentPos = e.target.dataset.key;
    setEquipmentPos(equipmentPos);
    setTimeout(setEquipmentModalStatus(true), 1000);
  };

  const handleEventProcessLog = (index, processLog) => {
    setEventProcessLog(() => { return { [index]: processLog }; });
  };

  const actDeleteWorkers = (type, index, workerIndex) => {
    switch (type) {
      case 'workOrderLogManagerIds':
        const workOrderLogManagerIds = [...workerReducer.workOrderLogManagerIds];
        workOrderLogManagerIds.splice(index, 1);
        dispatch(workerReducer_setWorkOrderLogManagerIds(workOrderLogManagerIds));
        break;

      case 'workOrderLogWorkerIds':
        const workOrderLogWorkerIds = [...workerReducer.workOrderLogWorkerIds];
        workOrderLogWorkerIds.splice(index, 1);
        dispatch(workerReducer_setWorkOrderLogWorkderIds(workOrderLogWorkerIds));
        break;

      case 'processLogManagerIds':
        const processLogManager = { ..._processList[index] };
        const managers = checkNullArray(processLogManager.managers, [])
        if (checkNullArray(processLogManager.managers, false)) managers.splice(workerIndex, 1);
        const newData_processLogManagerIds = { ...processLogManager, managers: managers };
        setProcessList((prev) => {
          const prevData = [...prev];
          prevData.splice(index, 1, newData_processLogManagerIds);
          return prevData;
        });
        break;

      case 'processLogWorkerIds':
        const processLogWorker = { ..._processList[index] };
        const workers = checkNullArray(processLogWorker.workers, [])
        if (checkNullArray(processLogWorker.workers, false)) workers.splice(workerIndex, 1);
        const newData_processLogWorkerIds = { ...processLogWorker, workers: workers };
        setProcessList((prev) => {
          const prevData = [...prev];
          prevData.splice(index, 1, newData_processLogWorkerIds);
          return prevData;
        });
        break;

      default: return;
    }
  };

  /* ====================================================================== #6 */

  return (
    <Grid2Body contents={
      <>
        <MainSection>
          <NavBar title={<NavTitle menuCode={'411'} />} nav={''} />

          <Form
            title={<>불량재작업<br />작업지시<br />추가<br /></>}
            buttons={
              <>
                <button className="formButton" onClick={actCreate}>저장</button>
                <button className="formButton cancle"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(pageReducer.currentPage, { replace: true });
                  }}
                >
                  취소
                </button>
              </>
            }
            forms={
              <>
                <div className="formBox">
                  <h4>작업유형</h4>
                  <select
                    name="workOrderType"
                    value={_formData.workOrderTypeId || ''}
                    onChange={(e) => { setFormData((prev) => { return { ...prev, workOrderTypeId: e.target.value }; }); }}
                  >
                    <option value="">작업유형</option>
                    {_workOrderTypes.map((thisType) => {
                      return (<option key={thisType.workOrderTypeId} value={thisType.workOrderTypeId}>{thisType.workOrderTypeName}</option>);
                    })}
                  </select>
                </div>

                <div className="formBox">
                  <h4>작업코드</h4>
                  <div style={{ columnGap: '5px', display: 'grid', gridTemplateColumns: 'auto 120px', width: '100%' }}>
                    <input
                      type="data"
                      name="workOrderCode"
                      placeholder="* 작업코드..."
                      data-required="required"
                      value={_formData.workOrderCode}
                      onInput={handleChangeEvent}
                    />
                    <DateCheckBox>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFormData((prev) => {
                              return { ...prev, workOrderCode: _formData.workOrderCode + `#${moment().format('YYYY-MM-DD')}` };
                            });
                          } else {
                            setFormData((prev) => {
                              return { ...prev, workOrderCode: _formData.workOrderCode.replace(`#${moment().format('YYYY-MM-DD')}`, ''), };
                            });
                          }
                        }}
                      />
                      날짜추가
                    </DateCheckBox>
                  </div>
                </div>

                <div className="formBox">
                  <h4>작업이름</h4>
                  <input
                    type="text"
                    name="workOrderName"
                    placeholder="* 작업이름..."
                    data-required="required"
                    value={_formData.workOrderName}
                    onInput={handleChangeEvent}
                  />
                </div>

                {(() => {
                  if (_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) {
                    return (
                      <div className="formBox">
                        <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                          <h4>거래처</h4>
                          <div className="formButton"
                            style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                            onClick={() => { setAccountModalStatus(true); }}
                          >
                            거래처 선택
                          </div>
                        </div>
                        <div style={{ columnGap: '5px', display: 'grid', gridTemplateColumns: 'auto 50px', width: '100%' }}>
                          <AccountInput
                            type="text"
                            name="accountName"
                            value={_account.accountName || ''}
                            readOnly
                            placeholder="거래처..."
                          />
                          <div className="formButton"
                            style={{ backgroundColor: 'var(--MainNavy)', borderRadius: '5px', height: '40px', width: '50px' }}
                            onClick={() => { setAccount(() => { return {}; }); }}
                          >
                            삭제
                          </div>
                        </div>
                      </div>
                    );
                  } else return null;
                })()}

                {(() => {
                  if (_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) {
                    return (
                      <div className="formBox">
                        <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                          <h4>파일</h4>
                          <div className="formButton"
                            style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                            onClick={() => {
                              dispatch(modalFileActions_setFileReset());
                              setTimeout(setFileModalStatus(true), 1000);
                            }}
                          >
                            파일 선택
                          </div>
                        </div>

                        <FileList>
                          {_fileList.map((thisItem, index) => {
                            return (
                              <FileBox key={thisItem.attachFileId + '_files'}>
                                {thisItem.fileDataName}
                                <DeleteFileButton onClick={(e) => { actDeleteFile(e, index, thisItem); }}><CloseButtonIcon /></DeleteFileButton>
                              </FileBox>
                            );
                          })}
                        </FileList>
                      </div>
                    );
                  } else return null;
                })()}

                <div className="formBox">
                  <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                    <h4>책임자</h4>
                    <div className="formButton"
                      style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                      onClick={() => {
                        setWorkerModal('workOrderLogManagerIds');
                        setTimeout(setWorkerModalStatus(true), 1000);
                      }}
                    >
                      책임자 선택
                    </div>
                  </div>

                  <ContentList>
                    {workerReducer.workOrderLogManagerIds.map((thisItem, index) => {
                      return (
                        <ContentBox key={index + '_workOrderLogManagerIds'}>
                          {thisItem.name}
                          <DeleteButton onClick={(e) => { actDeleteWorkers('workOrderLogManagerIds', index, thisItem); }}><CloseButtonIcon /></DeleteButton>
                        </ContentBox>
                      );
                    })}
                  </ContentList>
                </div>
                <div className="formBox">
                  <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                    <h4>작업자</h4>
                    <div className="formButton"
                      style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                      onClick={() => {
                        setWorkerModal('workOrderLogWorkerIds');
                        setTimeout(setWorkerModalStatus(true), 1000);
                      }}
                    >
                      작업자 선택
                    </div>
                  </div>

                  <ContentList>
                    {workerReducer.workOrderLogWorkerIds.map((thisItem, index) => {
                      return (
                        <ContentBox key={index + '_workOrderLogWorkerIds'}>
                          {thisItem.name}
                          <DeleteButton onClick={(e) => { actDeleteWorkers('workOrderLogWorkerIds', index, thisItem); }}><CloseButtonIcon /></DeleteButton>
                        </ContentBox>
                      );
                    },
                    )}
                  </ContentList>
                </div>

                <div className="formBox">
                  <h4>작업 시작일</h4>
                  <DateInput
                    onChange={handleChangeEvent}
                    type="date"
                    name="workOrderScheduledStartDate"
                    defaultValue={_formData.workOrderScheduledStartDate}
                  ></DateInput>
                </div>

                <div className="formBox">
                  <h4>작업 종료일</h4>
                  <DateInput
                    onChange={handleChangeEvent}
                    type="date"
                    name="workOrderScheduledEndDate"
                    defaultValue={_formData.workOrderScheduledEndDate}
                  ></DateInput>
                </div>

                <div className="formBox">
                  <h4>작업장소</h4>
                  <input
                    onChange={handleChangeEvent}
                    type="text"
                    name="workPlace"
                    defaultValue={_formData.workPlace}
                    placeholder="작업장소..."
                  />
                </div>

                <div className="formBox">
                  <h4>비고</h4>
                  <textarea
                    onChange={handleChangeEvent}
                    name="remark"
                    defaultValue={_formData.remark}
                    placeholder="비고..."
                  ></textarea>
                </div>

                <div className="formBox">
                  <h4>생산목표수량</h4>
                  <input
                    onInput={handleTotalAmountEvent}
                    type="number"
                    name="amount"
                    value={_formData.amount}
                    data-required="required"
                    placeholder="* 생산목표수량..."
                    min={0}
                  />
                </div>

                <div className="formBox">
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', lineHeight: '30px' }}>
                    <h4>공정</h4>
                    <div className="formButton"
                      style={{ borderRadius: '5px', width: '150px', backgroundColor: 'var(--ThirdBlue)' }}
                      onClick={() => {
                        dispatch(daerimReducer_setWorkOrderContents([]));
                        setTimeout(setProcessModalStatus(true), 1000);
                      }}
                    >
                      공정 선택
                    </div>
                  </div>

                  <TableFormbox>
                    <TableSection content={
                      <table>
                        <thead>
                          <tr style={{ border: 'none' }}>
                            <th rowSpan={2} style={{ minWidth: '50px', width: '50px' }}></th>
                            <th rowSpan={2} style={{ minWidth: '60px', width: '60px' }}>목표공정</th>
                            <th rowSpan={2} style={{ minWidth: '80px', width: '80px' }}>순서</th>
                            <th rowSpan={2}>공정유형</th>
                            <th rowSpan={2}>공정코드</th>
                            <th rowSpan={2}>공정이름</th>
                            {(() => {
                              if (_authority.indexOf('108-1') !== -1 || _authority.indexOf('108-2') !== -1) return <th rowSpan={2}>설비</th>;
                              else return null;
                            })()}
                            <th rowSpan={2}>책임자</th>
                            <th rowSpan={2}>작업자</th>
                            <th rowSpan={2}>공정횟수</th>
                            <th rowSpan={2}>묶음처리횟수</th>
                            <th rowSpan={2}>클릭횟수</th>
                            <th colSpan={5} style={{ backgroundColor: 'var(--SeconYellow)' }}>투입 자재/반제품</th>
                            <th colSpan={4} style={{ backgroundColor: 'var(--ThirdBlue)' }}>공정완료제품</th>
                          </tr>
                          <tr>
                            <th>물품이름</th>
                            <th>물품코드</th>
                            <th>단위 투입량</th>
                            <th>총 투입량</th>
                            <th>현재고</th>
                            <th>물품이름</th>
                            <th>물품코드</th>
                            <th>총 생산량</th>
                            <th>현재고</th>
                          </tr>
                        </thead>
                        <tbody>
                          {_processList.map((thisItem, index) => {
                            return (
                              <UpdateProcessRow
                                key={thisItem.processLogId !== undefined ? thisItem.processLogId + '_' + index + '_prev' : thisItem.processId + '_' + index + '_new'}
                                authority={_authority}
                                thisIndex={index}
                                content={thisItem}
                                processLength={_processList.length}
                                actDeleteWorkers={actDeleteWorkers}
                                delEvent={delItem}
                                handleCheckedItem={handleCheckedItem}
                                handleEventProcessLog={handleEventProcessLog}
                                handleNumberEvent={handleNumberEvent}
                                handleProcessCountingEvent={handleProcessCountingEvent}
                                selectEquipmentEvent={selectEquipmentEvent}
                                setProcessAmounts={handleProcessAmountEvent}
                                setWorkerModal={setWorkerModal}
                                setWorkerModalStatus={setWorkerModalStatus}
                              />
                            );
                          })}
                        </tbody>
                      </table>
                    }
                      formSectionStyle={{ paddingTop: '10px', width: '100%' }}
                    />
                  </TableFormbox>

                  <div className="cautionText" style={{ fontSize: '14px', width: '100%', textAlign: 'right' }}>※</div>
                </div>
              </>
            }
            formSectionStyle={{ paddingTop: '10px', width: 'calc(100% - 210px)' }}
          />
        </MainSection>

        {_workerModalStatus === true && (
          <SelectUsers
            buttonTitle={'사용자 선택'}
            buttonEvent={(data) => {
              console.log('data : ', data);
              switch (_workerModal) {
                case 'workOrderLogManagerIds':
                  const workOrderLogManagerIds = workerReducer.workOrderLogManagerIds.map((thisItem) => thisItem.id);
                  const newData_workOrderLogManagerIds = data.filter((thisItem) => workOrderLogManagerIds.indexOf(thisItem.id) === -1);
                  dispatch(workerReducer_setWorkOrderLogManagerIds([...workerReducer.workOrderLogManagerIds, ...newData_workOrderLogManagerIds]));
                  break;

                case 'workOrderLogWorkerIds':
                  const workOrderLogWorkerIds = workerReducer.workOrderLogWorkerIds.map((thisItem) => thisItem.id);
                  const newData_workOrderLogWorkerIds = data.filter((thisItem) => workOrderLogWorkerIds.indexOf(thisItem.id) === -1);
                  dispatch(workerReducer_setWorkOrderLogWorkderIds([...workerReducer.workOrderLogWorkerIds, ...newData_workOrderLogWorkerIds]));
                  break;

                case 'processLogManagerIds':
                  const processLogManagerIndex = Object.keys(_eventProcessLog)[0];
                  const processLogManager = { ..._eventProcessLog[processLogManagerIndex] };
                  const managers = checkNullArray(processLogManager.managers, [])
                  const newData_processLogManagerIds = { ...processLogManager, managers: [...managers, ...data] };
                  setProcessList((prev) => {
                    const prevData = [...prev];
                    prevData.splice(processLogManagerIndex, 1, newData_processLogManagerIds);
                    return prevData;
                  });
                  break;

                case 'processLogWorkerIds':
                  const processLogWorkerIndex = Object.keys(_eventProcessLog)[0];
                  const processLogWorker = { ..._eventProcessLog[processLogWorkerIndex] };
                  const workers = checkNullArray(processLogWorker.workers, [])
                  const newData_processLogWorkerIds = { ...processLogWorker, workers: [...workers, ...data] };
                  setProcessList((prev) => {
                    const prevData = [...prev];
                    prevData.splice(processLogWorkerIndex, 1, newData_processLogWorkerIds);
                    return prevData;
                  });
                  break;

                default: return;
              }
              setTimeout(setWorkerModalStatus(false), 1000);
            }}
            open={_workerModalStatus}
            close={() => { setWorkerModalStatus(false); }}
          />
        )}

        {_equipmentModalStatus === true && (
          <SelectEquipmentModal
            equipmentPos={_equipmentPos}
            open={_equipmentModalStatus}
            close={() => { setEquipmentModalStatus(false); }}
          ></SelectEquipmentModal>
        )}

        {_processModalStatus === true && (
          <SelectProcesses
            buttonTitle={'공정 선택'}
            buttonEvent={(data) => {
              console.log('data : ', data);
              const newDataList = [..._processList, ...data];
              const processList = newDataList.map((thisItem, index) => {
                const unitAmount = new BigNumber(_formData.amount || 1);
                const processUnitAmount = new BigNumber(thisItem.count || 1);
                const totalAmount = unitAmount.multipliedBy(processUnitAmount);

                const returnData = {
                  ...thisItem,
                  amount: _formData.amount || 1,
                  count: processUnitAmount.toNumber() || 1,
                  target: thisItem.target || false,
                  processAmount: totalAmount.toNumber() || _formData.amount || 1,
                  processingCount: totalAmount.toNumber() || _formData.amount || 1,
                };

                if (!checkEmptyNull(thisItem.processNumber, false) || thisItem.processNumber === 0) returnData.processNumber = index + 1;
                if (checkEmptyNull(thisItem.processingCount, false) && thisItem.processingCount !== 0) returnData.processingCount = thisItem.processingCount;
                console.log('returnData : ', returnData);
                return returnData;
              });

              setFormData((prev) => { return { ...prev, processLogList: processList }; });
              setProcessList(() => { return processList; });
              setTimeout(setProcessModalStatus(false), 1000);
            }}
            open={_processModalStatus}
            close={() => { setProcessModalStatus(false); }}
          />
        )}

        {_accountModalStatus === true && (
          <SelectOneAccount
            buttonTitle={'거래처 선택'}
            buttonEvent={(data) => {
              setAccount(() => { return data; });
              setTimeout(setAccountModalStatus(false), 1000);
            }}
            open={_accountModalStatus}
            close={() => { setAccountModalStatus(false); }}
          />
        )}

        {_fileModalStatus === true && (
          <SelectFiles
            buttonTitle={'파일 선택'}
            buttonEvent={(data) => {
              const newDataList = data.filter((thisItem) => _fileList.findIndex((thisIndex) => thisIndex.fileDataId === thisItem.fileDataId) === -1);
              setFileList((prev) => { return [...prev, ...newDataList]; });
              setTimeout(setFileModalStatus(false), 1000);
            }}
            open={_fileModalStatus}
            close={() => { setFileModalStatus(false); }}
          />
        )}
      </>
    }
    />
  );
};

export default CreateFailedProductionOrder;
