import { AxiosInterceptor } from "api/core/consts";

export const procurementApi = {
  getProcurement: (procurementId) => AxiosInterceptor.get('procurement/' + procurementId),
  searchProcurement: (paging, body) => AxiosInterceptor.post('procurement/search' + paging, body),
  createProcurement: (body) => AxiosInterceptor.post('procurement', body),
  uploadProcurement: (body) => AxiosInterceptor.post('procurements', body),
  updateProcurement: (procurementId, body) => AxiosInterceptor.put('procurement/' + procurementId, body),
  deleteProcurement: (procurementId) => AxiosInterceptor.delete('procurement/' + procurementId),

  savePartStock: (body) => AxiosInterceptor.post('procurement/savePartStock', body),
};
