import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fileActions_setFileCurrentPage, fileActions_setFileList, fileActions_setFileSearch, fileActions_setFileTotalSize } from 'store/modules/actions/common/fileActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { fileDataApi } from 'api/apis/fileDataApi';

import { checkEmptyNull, checkNullObject } from 'components/checkValues/checkValues';
import { FileManagementComponent } from './FileManagement.style';

import File from 'pages/file/assets/File';
import FileModal from './modal/FileModal';
import FolderComponent from './folder/FolderComponent';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import StlFile from './assets/StlFile';
import TableSection from 'components/layouts/table/TableSection';

const FileManagement = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { fileReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_fileList, setFileList] = useState([]);

  const [_currentFile, setCurrentFile] = useState({}); // preview

  const [_file, setFile] = useState({});
  const [_modalStatus, setModalStatus] = useState(false);
  const [_modalType, setModalType] = useState();
  const [_modalData, setModalData] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((authority) => authority.authority.startsWith('109') === true);
    const authorityCodes = authorityContent.map((authority) => authority.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    console.log('fileReducer.currentFolder : ', fileReducer.currentFolder);
    dispatch(fileActions_setFileCurrentPage(1));
    getCurrentFolderFileList(0);
    return () => { };
  }, [fileReducer.currentFolder]); // 폴더 변경
  useEffect(() => {
    setFileList(() => { return fileReducer.fileList });
    setCurrentFile(() => { return {} });
    return () => { };
  }, [fileReducer.fileList]); // 파일 리스트 변경
  useEffect(() => {
    if (_onload === 'loaded') getCurrentFolderFileList(fileReducer.currentPage - 1);
    return () => { };
  }, [fileReducer.currentPage]); // 페이지 변경

  /* ====================================================================== #4 */
  const getCurrentFolderFileList = async (page, clear) => {
    if (!checkNullObject(fileReducer.currentFolder, false)) {
      dispatch(fileActions_setFileList([]));
      dispatch(fileActions_setFileTotalSize(0));
      return;
    }

    const paging = `?page=${page}&size=20`;
    const body = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && checkNullObject(fileReducer.currentFolder, false) && !fileReducer.currentFolder.root) {
      body.fileCategoryId = fileReducer.currentFolder.fileCategoryId;
    }
    if (!clear && fileReducer.search && fileReducer.search.fileDataName) {
      body.fileDataName = fileReducer.search.fileDataName;
    }

    await fileDataApi.searchFileData(paging, body).then((response) => {
      if (response === undefined) return;
      dispatch(fileActions_setFileList(response.data.content));
      dispatch(fileActions_setFileTotalSize(response.data.totalElements));
    });
  };

  /* ====================================================================== #5 */
  const actSearch = async () => { // 검색
    dispatch(fileActions_setFileCurrentPage(1));
    await getCurrentFolderFileList(0);
  };
  const actReset = async () => { // 초기화
    dispatch(fileActions_setFileSearch({ fileDataName: '' }));
    dispatch(fileActions_setFileCurrentPage(1));
    await getCurrentFolderFileList(0, true);
  };

  const previewContent = (dataType) => { // 미리보기 컨텐츠
    // _currentFile.fileDataType
    if (dataType.startsWith('image')) return <img src={_currentFile.fileDataS3URL} alt={_currentFile.fileDataName} onClick={previewDownload} />;
    else if (dataType.includes('octet-stream')) {
      return (
        <div className='stl-content' onClick={() => { handleModalStatus('stl', { ..._currentFile }); }}>
          <p>
            ※ 화면이 안 보일 경우, [ ctrl + 마우스 휠 ] 조정을 해주세요.<br />
            화면을 클릭하면 stl 파일뷰어 팝업창이 표시됩니다.
          </p>
          <StlFile url={_currentFile.fileDataS3URL} />
        </div>
      );
    }
    else return <button className='btn-set' onClick={(e) => { e.preventDefault(); previewDownload(); }}>다운로드</button>;
  }

  const handleModalStatus = (type, fileData) => { // 파일이름 변경 / 파일 삭제
    setModalType(() => { return type });
    setModalData(() => { return fileData });

    setTimeout(() => {
      setModalStatus(() => { return true });
    }, 1000);
  }
  const previewDownload = () => { // 미리보기 다운로드
    if (window.confirm('파일을 다운로드 하시겠습니까?')) handleDownload(_currentFile);
    else return;
  }
  const handleDownload = (file) => { // 파일 다운로드
    const element = document.createElement('a');
    element.href = file.fileDataS3URL;
    element.download = file.fileDataName;
    element.target = '_blank';
    element.click();
  };

  return (
    <>
      <Grid2Body contents={
        <FileManagementComponent className="Main">
          <NavBar
            title={
              <>
                <div className="SearchSection">
                  <div className="ContentCBox">
                    <select className="SearchOption detail"><option value="fileDataName">파일이름</option></select>
                    <input
                      className="SearchBar detail"
                      placeholder="파일이름..."
                      value={checkEmptyNull(fileReducer.search?.fileDataName, '')}
                      onInput={(e) => {
                        if (e.keyCode === 13 || e.key === 'Enter') return;
                        const fileDataName = e.target.value;
                        dispatch(fileActions_setFileSearch({ ...fileReducer.search, fileDataName: fileDataName }));
                      }}
                      onKeyUp={(e) => { if (e.keyCode === 13 || e.key === 'Enter') actSearch(); }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <div className="DetailButton search" onClick={actSearch}><SearchButtonIcon />검색</div>
                    <div className="ResetButton detail" onClick={actReset}>초기화</div>
                  </div>
                </div>
              </>
            }
            buttons={
              <>
                {_authority.indexOf('109-2') !== -1 && checkNullObject(fileReducer.currentFolder, false) && !fileReducer.currentFolder.root && (
                  <button className='btn-add'
                    onClick={(e) => {
                      e.preventDefault();
                      handleModalStatus('create', { ...fileReducer.currentFolder });
                    }}
                  >파일추가</button>
                )}
              </>
            }
            nav={''}
          />

          <div>
            <FolderComponent />

            <div className='file-content'>
              <div className='file-list'>
                <TableSection content={
                  <table>
                    <thead>
                      <tr>
                        {checkNullObject(fileReducer.currentFolder, false) && fileReducer.currentFolder.root && <th>경로</th>}
                        <th style={{ borderRight: 'none' }}>파일이름</th>
                        <th style={{ minWidth: '150px', width: '150px' }}>파일유형</th>
                        {_authority.indexOf('109-2') !== -1 && <th style={{ minWidth: '70px', width: '70px' }}>설정</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {_fileList.map((fileData, index) => {
                        return (
                          <tr key={index + '_files'} onClick={() => { setCurrentFile(() => { return fileData }) }}>
                            {checkNullObject(fileReducer.currentFolder, false) && fileReducer.currentFolder.root && (
                              <td>{userReducer.company.companyName + '/' + checkEmptyNull(fileData.fileCategoryPath, '')}</td>
                            )}
                            <td><File content={fileData} /></td>
                            <td>{checkEmptyNull(fileData.fileDataType, '')}</td>
                            {_authority.indexOf('109-2') !== -1 && (
                              <td className='act-cell' style={{ minWidth: '70px', width: '70px' }}>
                                <button className='btn-set' title={fileData.fileDataName + ' 설정'}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (_file?.fileDataId === fileData.fileDataId) setFile(() => { return {} });
                                    else setFile(() => { return fileData });
                                  }}
                                >&#8942;</button>
                                {_file?.fileDataId === fileData.fileDataId ? (
                                  <div className='act-content'>
                                    <div onClick={() => { handleDownload(fileData) }}>다운로드</div>
                                    <div onClick={() => { handleModalStatus('update', fileData) }}>파일 정보 수정</div>
                                    <div onClick={() => { handleModalStatus('delete', fileData) }}>삭제</div>
                                  </div>
                                ) : null}
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                } />

                <PagingComponent
                  page={fileReducer.currentPage}
                  count={fileReducer.totalSize}
                  size={20}
                  pageEvent={(page) => { dispatch(fileActions_setFileCurrentPage(page)) }}
                />
              </div>

              <div className='preview'>
                {checkNullObject(_currentFile, false) && (previewContent(_currentFile.fileDataType))}
              </div>
            </div>
          </div>
        </FileManagementComponent>
      }
      />
      {_modalStatus ? (
        <FileModal
          open={_modalStatus}
          modalType={_modalType}
          modalData={_modalData}
          close={(update) => {
            setModalStatus(() => { return false });
            if (update === 'update') {
              console.log('update');
              getCurrentFolderFileList(fileReducer.currentPage - 1);
            }
          }}
        />
      ) : null}
    </>
  );
};

export default FileManagement;
