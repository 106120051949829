export const productionOrderActions_setWorkOrderType = (workOrderType) => {
  return { type: 'SETPRODUCTIONORDERWORKORDERTYPE', payload: workOrderType };
};
export const productionOrderActions_setWorkOrderStatus = (workOrderStatus) => {
  return { type: 'SETPRODUCTIONORDERWORKORDERSTATUS', payload: workOrderStatus };
};
export const productionOrderActions_setStartDate = (startDate) => {
  return { type: 'SETPRODUCTIONORDERSTARTDATE', payload: startDate };
};
export const productionOrderActions_setEndDate = (endDate) => {
  return { type: 'SETPRODUCTIONORDERENDDATE', payload: endDate };
};
export const productionOrderActions_setSearchOption = (searchData) => {
  return { type: 'SETPRODUCTIONORDERSEARCHOPTION', payload: searchData };
};
export const productionOrderActions_setSearchText = (searchData) => {
  return { type: 'SETPRODUCTIONORDERSEARCHTEXT', payload: searchData };
};
export const productionOrderActions_setSearchData = (searchData) => {
  return { type: 'SETPRODUCTIONORDERSEARCHDATA', payload: searchData };
};
export const productionOrderActions_setPageNumber = (pageNumber) => {
  return { type: 'SETPRODUCTIONORDERPAGENUMBER', payload: pageNumber };
};
export const productionOrderActions_setTotalSize = (totalSize) => {
  return { type: 'SETPRODUCTIONORDERTOTALSIZE', payload: totalSize };
};
export const productionOrderActions_setIsPushedSearchButton = (isPushedSearchButton) => {
  return { type: 'SETPRODUCTIONORDERISPUSHEDSEARCHBUTTON', payload: isPushedSearchButton };
};
export const productionOrderActions_setReset = () => {
  return { type: 'SETPRODUCTIONORDERRESET' };
};