const initialState = {
  searchOption: '',
  searchText: '',
  pageNumber: 0,
  totalSize: 0,
  isPushedSearchButton: false,
};
const resetState = {
  searchOption: '',
  searchText: '',
  pageNumber: 0,
  totalSize: 0,
  isPushedSearchButton: false,
};

const drawingManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETDRAWINGMANAGEMENTSEARCHOPTION':
      return { ...state, searchOption: action.payload };
    case 'SETDRAWINGMANAGEMENTSEARCHTEXT':
      return { ...state, searchText: action.payload };
    case 'SETDRAWINGMANAGEMENTPAGENUMBER':
      return { ...state, pageNumber: action.payload };
    case 'SETDRAWINGMANAGEMENTTOTALSIZE':
      return { ...state, totalSize: action.payload };
    case 'SETDRAWINGMANAGEMENTISPUSHEDSEARCHBUTTON':
      return { ...state, isPushedSearchButton: action.payload };
    case 'SETDRAWINGMANAGEMENTRESET':
      return { ...state, ...resetState };
    default: return state;
  };
};

export default drawingManagementReducer;