import React from 'react';
import styled from 'styled-components';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import StatusButton from 'components/buttons/StatusButton';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 250px;
  justify-content: flex-start;
  min-width: 200px;
  overflow: hidden;

  width: ${(props) => {
    return props.status.toLowerCase() === 'wait' ? '200px'
      : props.status.toLowerCase() === 'work' ? '800px'
        : props.status.toLowerCase() === 'pause' ? '400px'
          : props.status.toLowerCase() === 'stop' ? '200px'
            : null;
  }};
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  box-sizing: border-box;
  column-gap: 10px;
  display: flex;
  height: calc(100% - 55px);
  justify-content: center;
  padding: 20px;
  width: 100%;
`;

const WorkerProcessStatusModal = (props) => {
  const { content, statusEvent } = props;
  const status = content.processStatus;

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection status={status}>
            <ModalHeader>
              <ModalTitle>공정상태 설정</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              {status === 'wait' ? (
                <>
                  <StatusButton
                    statusStyle={{ fontSize: '30px' }}
                    status={'work'}
                    modal={true}
                    statusEvent={() => { statusEvent(content, 'work'); }}
                  ></StatusButton>
                </>
              ) : status === 'work' ? (
                <>
                  <StatusButton
                    statusStyle={{ fontSize: '30px' }}
                    status={'pause'}
                    statusEvent={() => { statusEvent(content, 'pause'); }}
                  ></StatusButton>
                  <StatusButton
                    statusStyle={{ fontSize: '30px' }}
                    status={'stop'}
                    statusEvent={() => { statusEvent(content, 'stop'); }}
                  ></StatusButton>
                  <StatusButton
                    statusStyle={{ cursor: 'pointer', fontSize: '30px' }}
                    status={'intermediate'}
                    statusEvent={() => { statusEvent(content, 'intermediate'); }}
                  ></StatusButton>
                  <StatusButton
                    statusStyle={{ cursor: 'pointer', fontSize: '30px' }}
                    status={'end'}
                    statusEvent={() => { statusEvent(content, 'end'); }}
                  ></StatusButton>
                </>
              ) : status === 'pause' ? (
                <>
                  <StatusButton
                    statusStyle={{ fontSize: '30px' }}
                    status={'work'}
                    modal={true}
                    statusEvent={() => { statusEvent(content, 'work'); }}
                  ></StatusButton>
                  <StatusButton
                    statusStyle={{ fontSize: '30px' }}
                    status={'stop'}
                    statusEvent={() => { statusEvent(content, 'stop'); }}
                  ></StatusButton>
                </>
              ) : status === 'stop' ? (
                <>
                  <StatusButton
                    statusStyle={{ fontSize: '30px' }}
                    status={'work'}
                    modal={true}
                    statusEvent={() => { statusEvent(content, 'work'); }}
                  ></StatusButton>
                </>
              ) : status === 'end' ? (
                <>
                  <StatusButton
                    statusStyle={{ fontSize: '30px' }}
                    status={'work'}
                    modal={true}
                    statusEvent={() => { statusEvent(content, 'work'); }}
                  ></StatusButton>
                </>
              ) : status === 'cancel' ? (
                <>
                  <StatusButton
                    statusStyle={{ fontSize: '30px' }}
                    status={'work'}
                    modal={true}
                    statusEvent={() => { statusEvent(content, 'work'); }}
                  ></StatusButton>
                </>
              ) : null}
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default WorkerProcessStatusModal;
