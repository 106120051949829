import React, { Fragment, useEffect, useState } from 'react';

import { checkNull, checkNullArray } from 'components/checkValues/checkValues';

import { DateFormat } from 'components/format/DateFormat';
import CommaNum from 'components/format/CommaNum';
import StatusTdButton from 'components/buttons/StatusTdButton';

const MarketContractRow = (props) => {
  /* ========================================================================= #1 */
  /* ========================================================================= #2 */
  const [contractAttrData, setContractAttrData] = useState(props.attr);
  const [contractData, setContractData] = useState(props.content);

  const [checkedItem, setCheckedItem] = useState(props.checkedItem);

  const [contractElements, setContractElements] = useState(
    props.content.contractElementList,
  );

  const [_rowLength, setRowLength] = useState(contractElements.length);

  /* ========================================================================= #3 */
  useEffect(() => {
    setContractAttrData(() => {
      return props.attr;
    });
    setContractData(() => {
      return props.content;
    });

    setCheckedItem(() => {
      return props.checkedItem;
    });

    setContractElements(() => {
      return props.content.contractElementList;
    });

    setRowLength(props.content.contractElementList.length);
  }, [props]);

  /* ========================================================================= #4 */
  /* ========================================================================= #5 */
  /* ========================================================================= #6 */
  const returnElements = () => {
    let returnArray = [];
    for (let i = 1; i < _rowLength; i++) {
      returnArray.push(
        <tr key={i + '_contractElements'}>
          {contractElements[i] !== undefined ? (
            <Fragment>
              <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                {(() => {
                  if (
                    contractElements[i].elementName !== undefined &&
                    contractElements[i].elementName !== null
                  ) {
                    return contractElements[i].elementName;
                  } else if (
                    contractElements[i].element !== undefined &&
                    contractElements[i].element !== null &&
                    contractElements[i].element.elementName !== undefined &&
                    contractElements[i].element.elementName !== null
                  ) {
                    return contractElements[i].element.elementName;
                  } else {
                    return '';
                  }
                })()}
              </td>
              <td>
                {(() => {
                  if (
                    contractElements[i].elementCode !== undefined &&
                    contractElements[i].elementCode !== null
                  ) {
                    return contractElements[i].elementCode;
                  } else if (
                    contractElements[i].element !== undefined &&
                    contractElements[i].element !== null &&
                    contractElements[i].element.elementCode !== undefined &&
                    contractElements[i].element.elementCode !== null
                  ) {
                    return contractElements[i].element.elementCode;
                  } else {
                    return '';
                  }
                })()}
              </td>
              {/* <td>
                {(() => {
                  if (
                    contractElements[i].naraElementCode !== undefined &&
                    contractElements[i].naraElementCode !== null
                  ) {
                    return contractElements[i].naraElementCode;
                  } else if (
                    contractElements[i].element !== undefined &&
                    contractElements[i].element !== null &&
                    contractElements[i].element.naraElementCode !== undefined &&
                    contractElements[i].element.naraElementCode !== null
                  ) {
                    return contractElements[i].element.naraElementCode;
                  } else {
                    return '';
                  }
                })()}
              </td> */}
              {/* <td>
                {(() => {
                  if (
                    contractElements[i].scheduledInputStock !== undefined &&
                    contractElements[i].scheduledInputStock !== null
                  ) {
                    return (
                      <CommaNum
                        displayType={'text'}
                        num={contractElements[i].scheduledInputStock || 0}
                      />
                    );
                  } else if (
                    contractElements[i].element !== undefined &&
                    contractElements[i].element !== null &&
                    contractElements[i].element.scheduledInputStock !==
                      undefined &&
                    contractElements[i].element.scheduledInputStock !== null
                  ) {
                    return (
                      <CommaNum
                        displayType={'text'}
                        num={
                          contractElements[i].element.scheduledInputStock || 0
                        }
                      />
                    );
                  } else {
                    return 0;
                  }
                })()}
              </td>
              <td
                className={(() => {
                  if (
                    contractElements[i].elementSafeStock !== undefined &&
                    contractElements[i].elementSafeStock !== null &&
                    contractElements[i].elementStock !== undefined &&
                    contractElements[i].elementStock !== null
                  ) {
                    if (
                      contractElements[i].elementSafeStock <=
                      contractElements[i].elementStock
                    ) {
                      return 'safeStock';
                    } else {
                      return 'dangerStock';
                    }
                  } else if (
                    contractElements[i].element !== undefined &&
                    contractElements[i].element !== null &&
                    contractElements[i].element.elementStock !== undefined &&
                    contractElements[i].element.elementStock !== null &&
                    contractElements[i].element.elementStock !== undefined &&
                    contractElements[i].element.elementStock !== null
                  ) {
                    if (
                      contractElements[i].element.elementSafeStock <=
                      contractElements[i].element.elementStock
                    ) {
                      return 'safeStock';
                    } else {
                      return 'dangerStock';
                    }
                  } else {
                    return '';
                  }
                })()}
              >
                {(() => {
                  if (
                    contractElements[i].elementStock !== undefined &&
                    contractElements[i].elementStock !== null
                  ) {
                    return (
                      <CommaNum
                        displayType={'text'}
                        num={contractElements[i].elementStock || 0}
                      />
                    );
                  } else if (
                    contractElements[i].element !== undefined &&
                    contractElements[i].element !== null &&
                    contractElements[i].element.elementStock !== undefined &&
                    contractElements[i].element.elementStock !== null
                  ) {
                    return (
                      <CommaNum
                        displayType={'text'}
                        num={contractElements[i].element.elementStock || 0}
                      />
                    );
                  } else {
                    return 0;
                  }
                })()}
              </td>
              <td>
                <CommaNum
                  displayType={'text'}
                  num={contractElements[i].price || 0}
                />
              </td> */}
              <td>
                <CommaNum
                  displayType={'text'}
                  num={contractElements[i].quantity || 0}
                />
              </td>
              <td style={{ borderRight: '1px solid var(--gray-200)' }}>
                <CommaNum
                  displayType={'text'}
                  num={contractElements[i].price || 0}
                />
                {/* <CommaNum
                  displayType={'text'}
                  num={(() => {
                    const calPrice = BigNumber(contractElements[i].price || 0)
                      .multipliedBy(
                        BigNumber(contractElements[i].quantity || 0),
                      )
                      .toNumber();

                    return calPrice;
                  })()}
                /> */}
              </td>
            </Fragment>
          ) : (
            <Fragment>
              <td
                className="blankCell"
                style={{ borderLeft: '1px solid var(--gray-200)' }}
              ></td>
              <td className="blankCell"></td>
              {/* <td className="blankCell"></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td> */}
              <td className="blankCell"></td>
              <td
                className="blankCell"
                style={{ borderRight: '1px solid var(--gray-200)' }}
              ></td>
            </Fragment>
          )}
        </tr>,
      );
    }

    return returnArray;
  };

  return (
    <Fragment>
      <tr
        name={props.authority.indexOf('202-2') !== -1 ? 'contractRows' : ''}
      // onClick={(e) => {
      //   if (props.authority.indexOf('202-2') !== -1)
      //     props.goDetails(contractData);
      // }}
      >
        <StatusTdButton
          rowSpan={_rowLength === 0 ? 1 : _rowLength}
          statusStyle={{
            minWidth: '100px',
            width: '100px',
          }}
          status={contractData.contractStatus}
          statusEvent={(e) => {
            e.stopPropagation();
            props.handleContractStatus(contractData, props.index);
          }}
        />
        {props.authority.indexOf('201-2') !== -1 ? (
          <td
            rowSpan={_rowLength === 0 ? 1 : _rowLength}
            style={{ minWidth: '50px', width: '50px' }}
          >
            <input
              type="checkBox"
              name="contracts"
              data-key={contractData.contractId}
              checked={(() => {
                const checkedIndex = checkedItem
                  ? Array.from(checkedItem).findIndex(
                    (contract) =>
                      contract.contractId === contractData.contractId,
                  )
                  : -1;

                if (checkedIndex !== -1) {
                  return true;
                } else {
                  return false;
                }
              })()}
              onChange={(e) => {
                const isChecked = e.target.checked;
                props.handleCheckedItem(contractData, isChecked);

                // if (isChecked) {
                //   e.target.checked = true;
                // } else {
                //   e.target.checked = false;
                // }
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </td>
        ) : null}
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {checkNull(contractData.contractDate, false) ? DateFormat(contractData.contractDate) : ''}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {checkNull(contractData.contractRegDate, false) ? DateFormat(contractData.contractRegDate) : ''}
          {/* {DateFormat(contractData.contractRegDate)} */}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {contractData.contractCode}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {contractData.contractName}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {(() => {
            if (
              contractData.account !== undefined &&
              contractData.account !== null
            ) {
              return contractData.account.accountName;
            } else {
              return contractData.accountName;
            }
          })()}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {contractData.address}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          <CommaNum displayType={'text'} num={contractData.contractPrice} />
        </td>
        {contractElements[0] !== undefined ? (
          <Fragment>
            <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
              {(() => {
                if (
                  contractElements[0].elementName !== undefined &&
                  contractElements[0].elementName !== null
                ) {
                  return contractElements[0].elementName;
                } else if (
                  contractElements[0].element !== undefined &&
                  contractElements[0].element !== null &&
                  contractElements[0].element.elementName !== undefined &&
                  contractElements[0].element.elementName !== null
                ) {
                  return contractElements[0].element.elementName;
                } else {
                  return '';
                }
              })()}
            </td>
            <td>
              {(() => {
                if (
                  contractElements[0].elementCode !== undefined &&
                  contractElements[0].elementCode !== null
                ) {
                  return contractElements[0].elementCode;
                } else if (
                  contractElements[0].element !== undefined &&
                  contractElements[0].element !== null &&
                  contractElements[0].element.elementCode !== undefined &&
                  contractElements[0].element.elementCode !== null
                ) {
                  return contractElements[0].element.elementCode;
                } else {
                  return '';
                }
              })()}
            </td>
            {/* <td>
              {(() => {
                if (
                  contractElements[0].naraElementCode !== undefined &&
                  contractElements[0].naraElementCode !== null
                ) {
                  return contractElements[0].naraElementCode;
                } else if (
                  contractElements[0].element !== undefined &&
                  contractElements[0].element !== null &&
                  contractElements[0].element.naraElementCode !== undefined &&
                  contractElements[0].element.naraElementCode !== null
                ) {
                  return contractElements[0].element.naraElementCode;
                } else {
                  return '';
                }
              })()}
            </td> */}
            {/* <td>
              {(() => {
                if (
                  contractElements[0].scheduledInputStock !== undefined &&
                  contractElements[0].scheduledInputStock !== null
                ) {
                  return (
                    <CommaNum
                      displayType={'text'}
                      num={contractElements[0].scheduledInputStock || 0}
                    />
                  );
                } else if (
                  contractElements[0].element !== undefined &&
                  contractElements[0].element !== null &&
                  contractElements[0].element.scheduledInputStock !==
                    undefined &&
                  contractElements[0].element.scheduledInputStock !== null
                ) {
                  return (
                    <CommaNum
                      displayType={'text'}
                      num={contractElements[0].element.scheduledInputStock || 0}
                    />
                  );
                } else {
                  return 0;
                }
              })()}
            </td>
            <td
              className={(() => {
                if (
                  contractElements[0].elementSafeStock !== undefined &&
                  contractElements[0].elementSafeStock !== null &&
                  contractElements[0].elementStock !== undefined &&
                  contractElements[0].elementStock !== null
                ) {
                  if (
                    contractElements[0].elementSafeStock <=
                    contractElements[0].elementStock
                  ) {
                    return 'safeStock';
                  } else {
                    return 'dangerStock';
                  }
                } else if (
                  contractElements[0].element !== undefined &&
                  contractElements[0].element !== null &&
                  contractElements[0].element.elementStock !== undefined &&
                  contractElements[0].element.elementStock !== null &&
                  contractElements[0].element.elementStock !== undefined &&
                  contractElements[0].element.elementStock !== null
                ) {
                  if (
                    contractElements[0].element.elementSafeStock <=
                    contractElements[0].element.elementStock
                  ) {
                    return 'safeStock';
                  } else {
                    return 'dangerStock';
                  }
                } else {
                  return '';
                }
              })()}
            >
              {(() => {
                if (
                  contractElements[0].elementStock !== undefined &&
                  contractElements[0].elementStock !== null
                ) {
                  return (
                    <CommaNum
                      displayType={'text'}
                      num={contractElements[0].elementStock || 0}
                    />
                  );
                } else if (
                  contractElements[0].element !== undefined &&
                  contractElements[0].element !== null &&
                  contractElements[0].element.elementStock !== undefined &&
                  contractElements[0].element.elementStock !== null
                ) {
                  return (
                    <CommaNum
                      displayType={'text'}
                      num={contractElements[0].element.elementStock || 0}
                    />
                  );
                } else {
                  return 0;
                }
              })()}
            </td>
            <td>
              <CommaNum
                displayType={'text'}
                num={contractElements[0].price || 0}
              />
            </td> */}
            <td>
              <CommaNum
                displayType={'text'}
                num={contractElements[0].quantity || 0}
              />
            </td>
            <td style={{ borderRight: '1px solid var(--gray-200)' }}>
              <CommaNum
                displayType={'text'}
                num={contractElements[0].price || 0}
              />
              {/* <CommaNum
                displayType={'text'}
                num={(() => {
                  const calPrice = BigNumber(contractElements[0].price || 0)
                    .multipliedBy(BigNumber(contractElements[0].quantity || 0))
                    .toNumber();

                  return calPrice;
                })()}
              /> */}
            </td>
          </Fragment>
        ) : (
          <Fragment>
            <td
              className="blankCell"
              style={{ borderLeft: '1px solid var(--gray-200)' }}
            ></td>
            <td className="blankCell"></td>
            {/* <td className="blankCell"></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td> */}
            <td className="blankCell"></td>
            <td
              className="blankCell"
              style={{ borderRight: '1px solid var(--gray-200)' }}
            ></td>
          </Fragment>
        )}
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {checkNull(contractData.scheduledEndDate, false) ? DateFormat(contractData.scheduledEndDate) : ''}
          {/* {DateFormat(contractData.scheduledEndDate)} */}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {checkNull(contractData.contractActualEndDate, false) ? DateFormat(contractData.contractActualEndDate) : ''}
          {/* {DateFormat(contractData.contractActualEndDate)} */}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {contractData.contractType}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {/* {`${contractData.monitoringRemark}`} */}
          {checkNull(contractData.monitoringRemark, false) ? contractData.monitoringRemark.indexOf('\n') !== -1 ? contractData.monitoringRemark.split('\n').map((text, textIndex) => {
            return <p key={props.index + '_' + textIndex + '_monitoringRemark'}>{text}</p>
          }) : contractData.monitoringRemark : ''}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {/* {`${contractData.remark}`} */}
          {checkNull(contractData.remark, false) ? contractData.remark.indexOf('\n') !== -1 ? contractData.remark.split('\n').map((text, textIndex) => {
            return <p key={props.index + '_' + textIndex + '_monitoringRemark'}>{text}</p>
          }) : contractData.remark : ''}
        </td>
        {checkNullArray(contractData.contractAttributeValueList, false) ?
          // contractData.contractAttributeValueList !== null &&
          contractAttrData.map((contractAttr, contractAttrIndex) => {
            // console.log(contractAttr, contractData.contractAttributeValueList);
            const attrValueIndex =
              contractData.contractAttributeValueList.findIndex(
                (attr) => Number(attr.contractAttributeId) === Number(contractAttr.contractAttributeId),
              );
            if (attrValueIndex !== -1) {
              return (
                <td
                  key={props.index + '_' + contractAttrIndex + '_contractAttrValue'}
                  rowSpan={_rowLength === 0 ? 1 : _rowLength}
                >
                  {contractData.contractAttributeValueList[attrValueIndex].value}
                </td>
              );
            } else {
              return (
                <td
                  key={props.index + '_' + contractAttrIndex + '_contractAttrValue'}
                  rowSpan={_rowLength === 0 ? 1 : _rowLength}
                ></td>
              );
            }
          }) : null}
      </tr>
      {_rowLength > 1 && returnElements()}
    </Fragment>
  );
};

export default MarketContractRow;
