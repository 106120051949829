export const numpadActions_setSearch = (content) => {
  return {
    type: 'SETNUMPADSEARCH',
    payload: content,
  };
};

export const numpadActions_setCurrentPageNum = (content) => {
  return {
    type: 'SETNUMPADCURRENTPAGENUM',
    payload: content,
  };
};

export const numpadActions_setTotalSize = (content) => {
  return {
    type: 'SETNUMPADTOTALSIZE',
    payload: content,
  };
};

export const numpadActions_setRemark = (content) => {
  return {
    type: 'SETNUMPADREMARK',
    payload: content,
  };
};

export const numpadActions_setFileList = (content) => {
  return {
    type: 'SETNUMPADFILELIST',
    payload: content,
  };
};

export const numpadActions_setReset = () => {
  return {
    type: 'RESETNUMPADREDUCER',
  };
};
