import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { contractTypeApi } from 'api/apis/contractTypeApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import { Content, ContentHeader, Title, SearchBar, ButtonsWrap } from 'pages/types/typeStyle'

import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ContractType = (props) => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_typeList, setTypeList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());
  const [_searchText, setSearchText] = useState();

  /* ====================================================================== #3 */
  useEffect(() => {
    getContractTypes();

    setOnload('loaded');
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const getContractTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (checkEmptyNull(_searchText, false)) {
      BodyToPost.contractTypeName = _searchText;
    }

    await contractTypeApi.searchContractType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('contractTypeApi.searchContractType : ', response);
      setTypeList(() => { return response.data; });
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(id);
    setCheckedItem(() => { return checkedItem; });
  };

  const actSearch = async () => {
    getContractTypes();
  };

  /* 추가 */
  const actCreate = async () => {
    const promptText = `
    추가할 유형이름을 입력해주세요.
    `;
    const CreatePrompt = window.prompt(promptText, '');
    if (CreatePrompt === null) return;

    const BodyToPost = {
      companyId: userReducer.company.companyId,
      contractTypeName: CreatePrompt,
    };
    console.log('actCreate - body : ', BodyToPost);

    await contractTypeApi.createContractType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('contractTypeApi.createContractType : ', response);

      setTypeList((prev) => {
        return [...prev, response.data];
      });
      alert('유형을 추가했습니다.');
    });
  };

  /* 수정 */
  const actUpdate = async () => {
    const updateContentIndex = _typeList.findIndex((thisItem) => thisItem.contractTypeId === Array.from(_checkedItem)[0]);
    if (updateContentIndex === -1) return alert('수정할 유형을 선택해 주세요.');
    const updateContent = _typeList[updateContentIndex];
    console.log('updateContent : ', updateContent);

    const promptText = `
    수주 유형을 수정합니다.
    `;
    const UpdatePrompt = window.prompt(promptText, updateContent.contractTypeName);
    if (UpdatePrompt === null) return;

    const BodyToPut = {
      companyId: userReducer.company.companyId,
      contractTypeId: updateContent.contractTypeId,
      contractTypeName: UpdatePrompt,
    };
    console.log('actUpdate - body : ', BodyToPut);

    await contractTypeApi.updateContractType(updateContent.contractTypeId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('contractTypeApi.updateContractType : ', response);

      setTypeList((prev) => {
        const prevData = [...prev];
        prevData.splice(updateContentIndex, 1, response.data);
        return prevData;
      });
      alert('유형을 수정했습니다.');
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const deleteContentIndex = _typeList.findIndex((thisItem) => thisItem.contractTypeId === Array.from(_checkedItem)[0]);
    if (deleteContentIndex === -1) return alert('삭제할 유형을 선택해 주세요.');
    const deleteContent = _typeList[deleteContentIndex];
    console.log('deleteContent : ', deleteContent);

    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({
        ...deleteContent,
        type: 'contractType',
      })}`,
      replace: true,
    });
  };

  /* ====================================================================== #6 */

  return (
    <Content>
      <ContentHeader>
        <Title>수주 유형</Title>
        <div className='content-snb'>
          <ButtonsWrap>
            {props.authority.indexOf('201-2') !== -1 ? (
              <>
                <button className='btn-add' onClick={actCreate}>추가</button>
                <button className='btn-edit' onClick={actUpdate}>수정</button>
                <button className='btn-delete' onClick={actDelete}>삭제</button>
              </>
            ) : null}
            <SearchBar>
              <input
                placeholder="유형이름..."
                onInput={(e) => { setSearchText(() => { return e.target.value; }); }}
              />
              <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
            </SearchBar>
          </ButtonsWrap>
        </div>
      </ContentHeader>
      <TableSection content={
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th style={{ minWidth: 'unset', width: '50px' }}></th>
              <th>유형이름</th>
            </tr>
          </thead>
          <tbody>
            {_typeList.map((thisItem) => {
              return (
                <tr key={thisItem.contractTypeId}>
                  <td style={{ minWidth: 'unset', width: '50px' }}>
                    <input
                      type="checkBox"
                      name="contractTypes"
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        handleCheckedItem(thisItem.contractTypeId, isChecked);

                        if (isChecked) {
                          document.getElementsByName('contractTypes').forEach((thisAttr) => { thisAttr.checked = false; });
                          e.target.checked = true;
                        } else {
                          e.target.checked = false;
                        }
                      }}
                    />
                  </td>
                  <td>{thisItem.contractTypeName}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      }
      ></TableSection>
    </Content>
  );
};

export default ContractType;
