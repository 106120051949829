import React from 'react';
import styled from 'styled-components';

import { bookmarkIcon } from 'components/icons/src';

const Icon = styled.div`
  background-color: ${(props) => { return props.bookmark ? 'var(--MainYellow)' : 'var(--gray-200)'; }};
  cursor: pointer;
  height: 30px;
  margin: auto;
  width: 30px;

  mask-image: url(${bookmarkIcon}); mask-repeat: no-repeat; mask-size: contain;
  --webkit-mask-image: url(${bookmarkIcon}); --webkit-mask-repeat: no-repeat; --webkit-mask-size: contain;

  &:hover {transform: scale(1.2);}
`;

const Bookmark = (props) => {
  return (<Icon name='bookmark' bookmark={props.bookmark} onClick={(e) => { e.stopPropagation(); props.clickEvent(); }} />);
};

export default Bookmark;