const initialState = {
  workOrderType: 'all',
  workOrderStatus: 'all',
  startDate: '',
  endDate: '',
  searchData: {},
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
}
const resetState = {
  workOrderType: 'all',
  workOrderStatus: 'all',
  startDate: '',
  endDate: '',
  searchData: {},
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const productionHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETPRODUCTIONHISTORYWORKORDERTYPE':
      return {
        ...state,
        workOrderType: action.payload,
      };
    case 'SETPRODUCTIONHISTORYWORKORDERSTATUS':
      return {
        ...state,
        workOrderStatus: action.payload,
      };
    case 'SETPRODUCTIONHISTORYSTARTDATE':
      return {
        ...state,
        startDate: action.payload,
      };
    case 'SETPRODUCTIONHISTORYENDDATE':
      return {
        ...state,
        endDate: action.payload,
      };
    case 'SETPRODUCTIONHISTORYSEARCHDATA':
      return {
        ...state,
        searchData: action.payload,
      };
    case 'SETPRODUCTIONHISTORYSEARCHOPTION':
      return {
        ...state,
        searchOption: action.payload,
      };
    case 'SETPRODUCTIONHISTORYSEARCHTEXT':
      return {
        ...state,
        searchText: action.payload,
      };
    case 'SETPRODUCTIONHISTORYPAGENUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'SETPRODUCTIONHISTORYTOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };
    case 'SETPRODUCTIONHISTORYISPUSHEDSEARCHBUTTON':
      return {
        ...state,
        isPushedSearchButton: action.payload,
      };
    case 'SETPRODUCTIONHISTORYRESET':
      return {
        ...state,
        ...resetState,
      };
    default:
      return state;
  };
};

export default productionHistoryReducer;