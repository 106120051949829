import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { accountTypeApi } from 'api/apis/accountTypeApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import { Content, ContentHeader, Title, SearchBar, ButtonsWrap } from 'pages/types/typeStyle'

import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const AccountType = (props) => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_typeList, setTypeList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());
  const [_searchText, setSearchText] = useState();

  /* ====================================================================== #3 */
  useEffect(() => {
    getAccountTypes();

    setOnload('loaded');
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const getAccountTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (checkEmptyNull(_searchText, false)) {
      BodyToPost.accountTypeName = _searchText;
    }

    await accountTypeApi.searchAccountType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('accountTypeApi.searchAccountType : ', response);
      setTypeList(() => { return response.data; });
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(id);
    setCheckedItem(() => { return checkedItem; });
  };

  const actSearch = async () => {
    getAccountTypes();
  };

  /* 추가 */
  const actCreate = async () => {
    const promptText = `
    추가할 유형이름을 입력해주세요.
    `;
    const CreatePrompt = window.prompt(promptText, '');
    if (CreatePrompt === null) return;

    const BodyToPost = {
      companyId: userReducer.company.companyId,
      accountTypeName: CreatePrompt,
    };
    console.log('actCreate - body : ', BodyToPost);

    await accountTypeApi.createAccountType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('accountTypeApi.createAccountType : ', response);

      setTypeList((prev) => {
        return [...prev, response.data];
      });
      alert('유형을 추가했습니다.');
    });
  };

  /* 수정 */
  const actUpdate = async () => {
    const updateContentIndex = _typeList.findIndex((thisItem) => thisItem.accountTypeId === Array.from(_checkedItem)[0]);
    if (updateContentIndex === -1) return alert('수정할 유형을 선택해 주세요.');
    const updateContent = _typeList[updateContentIndex];
    console.log('updateContent : ', updateContent);

    const promptText = `
    거래처 유형을 수정합니다.
    `;
    const UpdatePrompt = window.prompt(promptText, updateContent.accountTypeName);
    if (UpdatePrompt === null) return;

    const BodyToPut = {
      companyId: userReducer.company.companyId,
      accountTypeId: updateContent.accountTypeId,
      accountTypeName: UpdatePrompt,
    };
    console.log('actUpdate - body : ', BodyToPut);

    await accountTypeApi.updateAccountType(updateContent.accountTypeId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('accountTypeApi.updateAccountType : ', response);

      setTypeList((prev) => {
        const prevData = [...prev];
        prevData.splice(updateContentIndex, 1, response.data);
        return prevData;
      });
      alert('유형을 수정했습니다.');
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const deleteContentIndex = _typeList.findIndex((thisItem) => thisItem.accountTypeId === Array.from(_checkedItem)[0]);
    if (deleteContentIndex === -1) return alert('삭제할 유형을 선택해 주세요.');
    const deleteContent = _typeList[deleteContentIndex];
    console.log('deleteContent : ', deleteContent);

    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({
        ...deleteContent,
        type: 'accountType',
      })}`,
      replace: true,
    });
  };

  /* ====================================================================== #6 */

  return (
    <>
      <Content>
        <ContentHeader>
          <Title>거래처 유형</Title>
          <div className='content-snb'>
            <ButtonsWrap>
              {props.authority.indexOf('104-2') !== -1 ? (
                <>
                  <button className='btn-add' onClick={actCreate}>추가</button>
                  <button className='btn-edit' onClick={actUpdate}>수정</button>
                  <button className='btn-delete' onClick={actDelete}>삭제</button>
                </>
              ) : null}
              <SearchBar>
                <input
                  placeholder="유형이름..."
                  onInput={(e) => { setSearchText(() => { return e.target.value; }); }}
                />
                <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
              </SearchBar>
            </ButtonsWrap>
          </div>
        </ContentHeader>
        <TableSection content={
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th style={{ minWidth: 'unset', width: '50px' }}></th>
                <th>유형이름</th>
              </tr>
            </thead>
            <tbody>
              {_typeList.map((thisItem) => {
                if (thisItem.accountTypeName === userReducer.company.companyName) return null;
                return (
                  <tr key={thisItem.accountTypeId}>
                    <td style={{ minWidth: 'unset', width: '50px' }}>
                      <input
                        type="checkBox"
                        name="accountTypes"
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          handleCheckedItem(thisItem.accountTypeId, isChecked);

                          if (isChecked) {
                            document.getElementsByName('accountTypes').forEach((thisAttr) => { thisAttr.checked = false; });
                            e.target.checked = true;
                          } else {
                            e.target.checked = false;
                          }
                        }}
                      />
                    </td>
                    <td>{thisItem.accountTypeName}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        }
        ></TableSection>
      </Content>
    </>
  );
};

export default AccountType;
