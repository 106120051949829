import React from 'react';
import { useSelector } from 'react-redux';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { cart } from 'api/apis/mall/cart';

import { checkEmptyNull, checkNullObject } from 'components/checkValues/checkValues';
import CommaNum from 'components/format/CommaNum';

const Thumbnail = styled.div`
  background-color: var(--white);
  background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url("https://st3.depositphotos.com/23594922/31822/v/600/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg");
  background-position: center;
  background-size: contain;
  height: 100%;
  width: 100%;
`;

const CardV = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userReducer } = useSelector(state => state);

  const companyCode = location.pathname.split('/')[1];

  const { mallElement } = props;

  const AddCart = async () => {
    if (!checkEmptyNull(userReducer.user.id, false)) return alert('로그인이 필요합니다.');
    const BodyToPost = {
      companyId: userReducer.user.companyId,
      companyCode: companyCode,
      userId: userReducer.user.id,
      mallElementId: mallElement.mallElementId,
      count: 1,
    };
    await cart.createMallCart(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('cart.createMallCart : ', response);
      const confirmText = `
장바구니에 추가되었습니다.
장바구니로 이동하시겠습니까?
      `;
      if (window.confirm(confirmText)) navigate({ pathname: `/${companyCode}/mall/cart`, replace: true });
      else return;
    });
  };

  return (
    <div className='card'
      onClick={(e) => {
        e.preventDefault();
        navigate({
          pathname: `/${companyCode}/mall/product/detail`,
          search: `?${createSearchParams({ mallElement: JSON.stringify(mallElement) })}`,
          replace: true,
        });
      }}
    >
      <div className='card-thumbnail'>
        {(() => {
          if (checkNullObject(mallElement.attachFileList, false)) {
            let fileData = mallElement.attachFileList[0];
            return (<img src={fileData.fileData.fileDataS3URL} alt={fileData.fileData.fileDataName} />);
          } else {
            return <Thumbnail></Thumbnail>;
          }
        })()}
      </div>
      <ul>
        <li><p>상품코드</p><p title={mallElement.mallElementCode}>{mallElement.mallElementCode}</p></li>
        <li><p>상품이름</p><p title={mallElement.mallElementName}>{mallElement.mallElementName}</p></li>
        <li>
          <p>상품가격</p><p><CommaNum displayType="text" num={checkEmptyNull(mallElement.mallElementPrice, 0)} /></p>
          <button onClick={(e) => { e.stopPropagation(); AddCart(); }} >
            <img src='https://cdn-icons-png.flaticon.com/512/1170/1170678.png' style={{ width: '24px', height: '24px' }} />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default CardV;
