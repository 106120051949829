export const stockHistoryActions_setElementType = (elementType) => {
  return {
    type: 'SETSTOCKHISTORYTYPE',
    payload: elementType,
  };
};
export const stockHistoryActions_setStartDate = (date) => {
  return {
    type: 'SETSTOCKHISTORYSTARTDATE',
    payload: date,
  };
};
export const stockHistoryActions_setEndDate = (date) => {
  return {
    type: 'SETSTOCKHISTORYENDDATE',
    payload: date,
  };
};
export const stockHistoryActions_setSearchData = (searchData) => {
  return {
    type: 'SETSTOCKHISTORYSEARCHDATA',
    payload: searchData,
  };
};
export const stockHistoryActions_setSearchAttrs = (serachAttrs) => {
  return {
    type: 'SETSTOCKHISTORYSEARCHATTS',
    payload: serachAttrs,
  };
};

export const stockHistoryActions_setClickedCategory = (category) => {
  return {
    type: 'SETSTOCKHISTORYCLICKEDCATEGORY',
    payload: category,
  };
};
export const stockHistoryActions_setClickedLevel1Category = (category) => {
  return {
    type: 'SETSTOCKHISTORYCLICKEDLEVEL1CATEGORY',
    payload: category,
  };
};
export const stockHistoryActions_setClickedLevel2Category = (category) => {
  return {
    type: 'SETSTOCKHISTORYCLICKEDLEVEL2CATEGORY',
    payload: category,
  };
};
export const stockHistoryActions_setClickedLevel3Category = (category) => {
  return {
    type: 'SETSTOCKHISTORYCLICKEDLEVEL3CATEGORY',
    payload: category,
  };
};
export const stockHistoryActions_setClickedLevel4Category = (category) => {
  return {
    type: 'SETSTOCKHISTORYCLICKEDLEVEL4CATEGORY',
    payload: category,
  };
};
export const stockHistoryActions_setClickedLevel5Category = (category) => {
  return {
    type: 'SETSTOCKHISTORYCLICKEDLEVEL5CATEGORY',
    payload: category,
  };
};

export const stockHistoryActions_setPageNumber = (pageNum) => {
  return {
    type: 'SETSTOCKHISTORYPAGENUMBER',
    payload: pageNum,
  };
};
export const stockHistoryActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETSTOCKHISTORYTOTALSIZE',
    payload: totalSize,
  };
};
export const stockHistoryActions_setIsPushedSearchButton = (status) => {
  return {
    type: 'SETSTOCKHISTORYISPUSHEDSEARCHBUTTON',
    payload: status,
  };
};

export const stockHistoryActions_setReset = () => {
  return {
    type: 'SETSTOCKHISTORYRESET',
  };
};

export const stockHistoryActions_selectOneElement = (element) => {
  return {
    type: 'SELECTONESTOCKHISTORY',
    payload: element,
  };
};

export const stockHistoryActions_selectElements = (elements) => {
  return {
    type: 'SELECTSTOCKHISTORYS',
    payload: elements,
  };
};

export const stockHistoryActions_setLevel1Categories = (category) => {
  return {
    type: 'SETSTOCKHISTORYLEVEL1CATEGORIES',
    payload: category,
  };
};
export const stockHistoryActions_setLevel2Categories = (category) => {
  return {
    type: 'SETSTOCKHISTORYLEVEL2CATEGORIES',
    payload: category,
  };
};
export const stockHistoryActions_setLevel3Categories = (category) => {
  return {
    type: 'SETSTOCKHISTORYLEVEL3CATEGORIES',
    payload: category,
  };
};
export const stockHistoryActions_setLevel4Categories = (category) => {
  return {
    type: 'SETSTOCKHISTORYLEVEL4CATEGORIES',
    payload: category,
  };
};
export const stockHistoryActions_setLevel5Categories = (category) => {
  return {
    type: 'SETSTOCKHISTORYLEVEL5CATEGORIES',
    payload: category,
  };
};
