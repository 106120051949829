import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { workOrderTypeApi } from 'api/apis/workOrderTypeApi';
import { workOrderApi } from 'api/apis/workOrderApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import SelectWorkOrderRow from 'components/row/SelectWorkOrderRow';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 40px 50px;
  height: 85%;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 400;
  gap: 10px;
  justify-content: flex-start;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {height: 100%; overflow-x: auto; width: 100%;}
`;
const ProcessTable = styled.table`
  border-collapse: collapse;
  min-width: 100%;
  table-layout: fixed;

  td, th {
    box-sizing: border-box;
    padding: 5px 15px;
    min-width: 150px !important;
    text-align: center;
    white-space: normal;
    word-break: break-all;
    font-size: 14px;
  }
  td.blankCell {background-color: var(--Bg); opacity: 0.8;}
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const SelectOneWorkOrder = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_workOrderList, setWorkOrderList] = useState([]);

  const [_workOrderTypes, setWorkOrderTypes] = useState([]);
  const [_workOrderType, setWorkOrderType] = useState('all');

  const [_checkedItem, setCheckedItem] = useState({});

  const [_searchData, setSearchData] = useState({});

  const [_pageNum, setPageNum] = useState(1);
  const [_pageCount, setPageCount] = useState(0);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('104') === true, // 거래처
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    setOnload('loaded');

    getWorkOrderTypes();
    getWorkOrderList(0, 'all');

    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getWorkOrderList(_pageNum - 1);
    }

    return () => { };
  }, [_pageNum]);
  useEffect(() => { }, [_checkedItem]);

  /* ====================================================================== #4 */
  const getWorkOrderTypes = async () => {
    const BodyToPost = { companyId: userReducer.company.companyId };
    await workOrderTypeApi.searchWorkOrderType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderTypeApi.searchWorkOrderType : ', response);
      setWorkOrderTypes(() => { return response.data; });
    });
  };

  const getWorkOrderList = async (page, workOrderType, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = { companyId: userReducer.company.companyId };
    if (!clear && checkEmptyNull(workOrderType, false) && workOrderType !== 'all') {
      BodyToPost.workOrderTypeId = Number(workOrderType);
    }
    if (!clear && checkEmptyNull(_searchData.workOrderCode, false)) {
      BodyToPost.workOrderCode = _searchData.workOrderCode;
    }
    if (!clear && checkEmptyNull(_searchData.workOrderName, false)) {
      BodyToPost.workOrderName = _searchData.workOrderName;
    }
    console.log('getWorkOrderList - BodyToPost : ', BodyToPost);

    await workOrderApi.searchWorkOrder(paging, BodyToPost).then((response) => {
      console.log('workOrderApi.searchWorkOrder : ', response);
      setWorkOrderList(() => { return response.data.content });
      setPageCount(() => { return response.data.totalElements });
    });
  };


  /* ====================================================================== #5 */
  const handleCheckedItem = (item, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(item);
    setCheckedItem(() => { return checkedItem });
  };

  const actSearch = async () => {
    setPageNum(() => { return 1 });
    await getWorkOrderList(0, _workOrderType);
  };
  const actReset = async () => {
    setWorkOrderType(() => { return '' });
    setSearchData(() => { return {} });
    setPageNum(() => { return 1 });
    await getWorkOrderList(0, 'all', true);
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>
                <NavTitle menuCode={'111'} />선택
              </ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <NavBar
                title={
                  <>
                    <div className="SearchSection">
                      <select
                        className="SelectType"
                        value={_workOrderType}
                        onChange={(e) => {
                          setWorkOrderType(() => { return e.target.value });
                          setPageNum(() => { return 1 });
                          getWorkOrderList(0, e.target.value);
                        }}
                      >
                        <option value="all">전체</option>
                        {_workOrderTypes.map((thisItem) => {
                          return (<option key={thisItem.workOrderTypeId + '_workOrderType'} value={thisItem.workOrderTypeId}>{thisItem.workOrderTypeName}</option>);
                        })}
                      </select>

                      <div className="SearchSection">
                        <div className="ContentCBox">
                          <select className="SearchOption detail">
                            <option value="workOrderCode">작업코드</option>
                          </select>
                          <input
                            className="SearchBar detail"
                            placeholder="Search..."
                            value={checkEmptyNull(_searchData.workOrderCode, '')}
                            onInput={(e) => { setSearchData((prev) => { return { ...prev, workOrderCode: e.target.value }; }); }}
                          />
                        </div>

                        <div className="ContentCBox">
                          <select className="SearchOption detail">
                            <option value="workOrderName">작업이름</option>
                          </select>
                          <input
                            className="SearchBar detail"
                            placeholder="Search..."
                            value={checkEmptyNull(_searchData.workOrderName, '')}
                            onInput={(e) => { setSearchData((prev) => { return { ...prev, workOrderName: e.target.value }; }); }}
                          />
                        </div>

                        <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                        <div className="ResetButton" onClick={actReset}>초기화</div>
                      </div>
                    </div>
                  </>
                }
                nav={''}
              />

              <TableSection content={
                <table>
                  <thead>
                    <tr style={{ border: 'none' }}>
                      <th rowSpan={2} style={{ minWidth: '50px', width: '50px' }}></th>
                      {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? (
                        <th rowSpan={2} style={{ minWidth: '60px', width: '60px' }}>파일</th>
                      ) : null}
                      <th rowSpan={2}>작업유형</th>
                      <th rowSpan={2}>작업코드</th>
                      <th rowSpan={2}>작업이름</th>
                      {(_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) ? (
                        <th rowSpan={2}>거래처</th>
                      ) : null}
                      <th rowSpan={2}>작업위치</th>
                      <th rowSpan={2}>비고</th>
                      <th rowSpan={2}>생산<br />가능수량</th>
                      <th colSpan={(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? 7 : 6} style={{ backgroundColor: 'var(--MainBlue)' }}>
                        공정
                      </th>
                      <th style={{ backgroundColor: 'var(--ThirdBlue)' }}>공정완료제품</th>
                      <th style={{ backgroundColor: 'var(--SeconYellow)' }}>투입 자재/반제품</th>
                    </tr>
                    <tr>
                      <th style={{ minWidth: '80px', padding: 'unset', width: '80px' }}>순서</th>
                      {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? (<th style={{ minWidth: '70px', width: '70px' }}>파일</th>) : null}
                      <th>공정유형</th>
                      <th>공정코드</th>
                      <th>공정이름</th>
                      <th>작업위치</th>
                      <th>비고</th>
                      <th style={{ maxWidth: 'unset', padding: 'unset' }}>
                        <ProcessTable style={{ height: '100%' }}>
                          <thead style={{ backgroundColor: 'var(--MainNavy)' }}>
                            <tr style={{ border: 'none' }}>
                              <th>물품이름</th>
                              <th>물품코드</th>
                              <th>생산량</th>
                              <th>현재고</th>
                            </tr>
                          </thead>
                        </ProcessTable>
                      </th>
                      <th style={{ maxWidth: 'unset', padding: 'unset' }}>
                        <ProcessTable style={{ height: '100%' }}>
                          <thead style={{ backgroundColor: 'var(--MainNavy)' }}>
                            <tr style={{ border: 'none' }}>
                              <th>물품이름</th>
                              <th>물품코드</th>
                              <th>투입량</th>
                              <th>현재고</th>
                            </tr>
                          </thead>
                        </ProcessTable>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {checkNullArray(_workOrderList, []).map((thisItem, index) => {
                      return (
                        <SelectWorkOrderRow
                          key={index + '_selectWorkOrderRow'}
                          authority={_authority}
                          content={thisItem}
                          checkedItem={_checkedItem}
                          handleCheckedItem={handleCheckedItem}
                        />
                      )
                    })}
                  </tbody>
                </table>
              }
              />
            </ModalMain>

            <PagingComponent
              page={_pageNum}
              count={_pageCount}
              size={10}
              pageEvent={(page) => {
                console.log('page : ', page);
                setPageNum(() => { return page });
              }}
            />

            <ModalFooter>
              <ModalButton onClick={() => { props.buttonEvent(Array.from(_checkedItem)[0]) }}>{props.buttonTitle}</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default SelectOneWorkOrder;
