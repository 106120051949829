import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { teamApi } from 'api/apis/teamApi';
import { userApi } from 'api/apis/userApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import { checkEmail, checkPw } from 'components/checkValues/sign';

import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

const CreateWorkerSection = styled.main`
  input[name="email"] {
    &[data-status="false"] {
      border-color: var(--MainRed);
      &::-webkit-input-placeholder {color: var(--MainRed);}
      &:focus {border-color: var(--MainRed);}
    }
  }
  input[type="password"] {
    &[data-status="false"] {
      border-color: var(--MainRed);
      &::-webkit-input-placeholder {color: var(--MainRed);}
      &:focus {border-color: var(--MainRed);}
    }
  }
`;

const CreateWorker = () => {
  /* ========================================================================= #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    registerType: 'normal',

    loginId: '',
    password: '',

    name: '',
    email: '',
    phoneNumber: '',
    memo: '',

    zip: '',
    address: '',
  });

  const [_teamList, setTeamList] = useState([]);

  const [_emailStatus, setEmailStatus] = useState(false);
  const [_email, setEmail] = useState('');

  const [_pwStatus, setPwStatus] = useState(false);
  const [_pw, setPw] = useState('');
  const [_confirmPw, setConfirmPw] = useState('');

  /* ========================================================================= #3 */
  useEffect(() => {
    getTeamList();
    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => { checkPassword(); return () => { } }, [_pw, _confirmPw]);

  /* ========================================================================= #4 */
  const getTeamList = async () => {
    const paging = `?page=0&size=100`;
    const BodyToPost = { companyId: userReducer.company.companyId };
    await teamApi.searchTeam(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('teamApi.searchTeam : ', response);
      setTeamList(() => { return response.data.content });
    })
  };

  /* ========================================================================= #5 */
  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value }; });
  };

  const checkPassword = () => {
    setPwStatus(() => { return checkPw(_pw, _confirmPw); })
  }
  const makePhoneNumber = (e) => {
    const phoneNumber = e.target.value.toString().replace(/[^0-9]/g, '').replace(/(^02.{0}|^01.{1}|[0-9]{3,4})([0-9]{3,4})([0-9]{4})/g, '$1-$2-$3');
    setFormData((prev) => { return { ...prev, phoneNumber: phoneNumber }; });
  };

  /* 수정 */
  const actCreate = async (e) => {
    e.preventDefault();
    if (!checkEmptyNull(_formData.loginId, false)) { return alert('아이디를 확인해주세요.') }
    if (!checkEmptyNull(_formData.name, false)) { return alert('이름을 확인해주세요.') }
    if (!checkEmptyNull(_formData.email, false) || !checkEmail(_email)) { return alert('이메일을 확인해주세요.') }
    if (!checkEmptyNull(_formData.phoneNumber, false)) { return alert('연락처를 확인해주세요.') }

    if (_pw.length < 8 || !_pwStatus) return alert('비밀번호를 확인해주세요.');
    const reg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
    if (!reg.test(_pw)) return alert('비밀번호는 최소 8자리 이상 영문 + 숫자 + 특수기호 조합으로 등록하셔야 합니다.');

    const BodyToPost = {
      ..._formData,
      teamId: Number(_formData.teamId),
      email: _email,
      password: _pw
    };
    console.log('BodyToPost : ', BodyToPost);

    await userApi.createUser(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('userApi.createUser : ', response);
      alert('사용자를 추가했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    })
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <>
        <CreateWorkerSection className="Main">
          <NavBar title={<NavTitle menuCode={'901'} />} nav={''} />

          <Form
            title={<>사용자<br />추가</>}
            buttons={
              <>
                <button className="formButton" onClick={actCreate}>저장</button>
                <button className="formButton cancle" onClick={() => { navigate(pageReducer.currentPage, { replace: true }) }}>취소</button>
              </>
            }
            forms={
              <>
                <div className="formBox">
                  <h4>아이디</h4>
                  <input
                    type="text"
                    name="loginId"
                    placeholder="아이디..."
                    value={_formData.loginId}
                    onInput={handleInputValue}
                  />
                </div>
                <div className="formBox">
                  <h4>이메일</h4>
                  <input
                    type="email"
                    name="email"
                    placeholder="이메일..."
                    data-status={_emailStatus}
                    value={_email}
                    onInput={(e) => {
                      const email = e.target.value;
                      setEmailStatus(() => { return checkEmail(email) });
                      setEmail(() => { return email });
                    }}
                  />
                </div>
                <div className="formBox">
                  <h4>이름</h4>
                  <input
                    type="text"
                    name="name"
                    placeholder="이름..."
                    value={_formData.name}
                    onInput={handleInputValue}
                  />
                </div>
                <div className="formBox">
                  <h4>연락처</h4>
                  <input
                    type="text"
                    name="phoneNumber"
                    placeholder="연락처 - 를 제외하고 입력해주세요. ex) 01012341234"
                    maxLength={14}
                    value={_formData.phoneNumber}
                    onInput={makePhoneNumber}
                  />
                </div>
                <div className='formBox'>
                  <h4>비밀번호</h4>
                  <input required
                    type="password" name="password" placeholder="비밀번호 (8자리 이상 영문+숫자+특수기호)"
                    minLength={8} value={_pw} data-status={_pwStatus}
                    onInput={(e) => {
                      const pw = e.target.value;
                      setPw(() => { return pw; });
                    }}
                  />
                  <input required
                    type="password" name="confirmPassword" placeholder="비밀번호 확인"
                    minLength={8} data-status={_pwStatus} value={_confirmPw}
                    onInput={(e) => {
                      const pw = e.target.value;
                      setConfirmPw(() => { return pw; });
                    }}
                  />
                </div>
              </>
            }
          />
        </CreateWorkerSection>
      </>
    }
    />
  );
};

export default CreateWorker;
