import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const MallCompanyLink = styled(Link)`
  align-items: center;
  color: var(--color-2);
  cursor: pointer;
  display: flex;
  font-weight: bold;
  height: 100%;
  justify-content: center;
  width: fit-content;
`;

const MallCompanyLogo = () => {
  const location = useLocation();
  const companyCode = location.pathname.split('/')[1];

  return <MallCompanyLink to={`/${companyCode}/mall/product/list`}>아이피스 몰</MallCompanyLink>;
};

export default MallCompanyLogo;
