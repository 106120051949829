import React from 'react';
import { Outlet } from 'react-router-dom';

import { ProjectManagementIndexComponent } from './ProjectManagementIndex.style';
import OperatorBody from 'components/layouts/body/OperatorBody';
import PMSideBar from './component/PMSideBar';

const ProjectManagementIndex = ({ children }) => {
  return (
    <>
      <OperatorBody contents={
        <ProjectManagementIndexComponent>
          <PMSideBar />
          <Outlet {...children} />
        </ProjectManagementIndexComponent>
      } />
    </>
  );
};

export default ProjectManagementIndex; 