import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {mallOrderActions_setPageNumber, mallOrderActions_setTotalSize, mallOrderActions_setReset, mallOrderActions_setSearchOption, mallOrderActions_setSearchText, mallOrderActions_setMallOrderStatus, mallOrderActions_setIsPushedSearchButton} from 'store/modules/actions/mall/mallOrderActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { order } from 'api/apis/mall/order';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import {MallCancelTdButtons, MallCompleteTdButtons, MallConsultingTdButtons, MallPreparingTdButtons, MallRequestCancelTdButtons, MallWaitingTdButtons} from 'components/buttons/MallOrderStatusTdButtons';
import MallBody from 'components/layouts/body/MallBody';
import MallOrderReply from 'pages/mallManagement/mallOrder/reply/MallOrderReply';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import ShowMallFileList from '../modal/ShowMallFileList';
import TableSection from 'components/layouts/table/TableSection';

const MainSection = styled.main`
  .TableSection {
    & td, & th {min-width: unset;}
    & tbody tr {height: 300px;}
    & td {border-bottom: 5px solid var(--gray-400);}
    & td.OrderInfos {
      border-right: 1px solid var(--gray-200);
      box-sizing: border-box;
      padding: unset;
      vertical-align: top;
    }
  }
`;
const StatusTd = styled.td`
  position: relative;
`;
const ActButtons = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fit, min(33.3%));
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
`;
const ActButton = styled.div`
  align-items: center;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 1em;
  height: 100%;
  justify-content: center;
  width: 100%;

  & input[type="radio"] {transform: scale(1.5);}
  &.file {background-color: var(--MainBlue); }
  &.reply {background-color: var(--MainNavy); }
`;
const OrderInfos = styled.div`
  height: 100%;
  width: 100%;

  & > div:last-child {border-bottom: 1px solid var(--gray-200);}
`;
const OrderInfo = styled.div`
  border-top: 1px solid var(--gray-200);
  display: grid;
  grid-template-columns: 80px auto;
  min-height: 50px;
  height: fit-content;
  width: 100%;

  &.orderInfo {
    grid-template-columns: 100%;
    & h5 {height: 50px;}
    & p{min-height: 50px;}
  }

  & h5 {
    align-items: center;
    background-color: var(--MainNavy);
    color: var(--white);
    display: flex;
    justify-content: center;
    width: 100%;
  }
  & p {
    align-items: center;
    box-sizing: border-box;
    justify-content: flex-start;
    padding: 10px;
    user-select: text;
    width: 100%;
  }
`;
const MallElementList = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(auto-fit, minmax(40px, fit-content));
  justify-content: flex-start;
  width: inherit;
  zoom: 100%;
`;
const MallElement = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 100%;
  min-height: 40px;
  height: fit-content;
  width: 100%;

  & p {
    align-items: center;
    border: 1px solid var(--gray-200);
    display: flex;
    height: 100%;
    justify-content: center;
  }
  &.title p { background-color: var(--MainNavy); color: var(--white); }
`;
const OrderCancelButton = styled.td`
  background-color: var(--ThirdRed);
  color: white;
  cursor: pointer;
  font-size: 1em;
  min-width: 60px;
  width: 60px;
`;

const MallOrderManagement = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mallOrderReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_mallOrderList, setMallOrderList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_fileListModalStatus, setFileListModalStatus] = useState(false);
  const [_replyListModalStatus, setReplyListModalStatus] = useState(false);
  const [_eventContent, setEventContent] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getMallOrderList(mallOrderReducer.pageNumber - 1, mallOrderReducer.mallOrderStatus, mallOrderReducer.isPushedSearchButton);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getMallOrderList(mallOrderReducer.pageNumber - 1, mallOrderReducer.mallOrderStatus, mallOrderReducer.isPushedSearchButton);
    }
    return () => { };
  }, [mallOrderReducer.pageNumber]);

  useEffect(() => { }, [mallOrderReducer]);

  /* ====================================================================== #4 */
  const getMallOrderList = async (page, mallOrderStatus, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {companyId: userReducer.company.companyId};
    if (checkEmptyNull(mallOrderStatus, false) && mallOrderStatus !== 'all') {
      BodyToPost.status = mallOrderStatus;
    }
    if (!clear && checkEmptyNull(mallOrderReducer.searchOption, false) && checkEmptyNull(mallOrderReducer.searchText, false)) {
      BodyToPost[mallOrderReducer.searchOption] = mallOrderReducer.searchText;
    }
    await order.searchMallOrder(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('order.searchMallOrder : ', response);
      setMallOrderList(() => {return response.data.content;});
      dispatch(mallOrderActions_setTotalSize(response.data.totalElements));
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set([..._checkedItem]);
    if (isChecked) checkedItem.add(id);
    else if (!isChecked && checkedItem.has(id)) checkedItem.delete(id);
    setCheckedItem(() => {return checkedItem;});
  };

  const handleMallOrderStatus = (mallOrder, index) => {
    setMallOrderList((prev) => {
      const returnData = [...prev];
      const newData = { ...mallOrder };
      returnData.splice(index, 1, newData);
      return returnData;
    });
  };

  /* 파일 리스트 */
  const showFileList = (mallOrder) => {
    setEventContent(() => {return mallOrder;});
    setTimeout(setFileListModalStatus(true), 1000);
  };

  /* 댓글 리스트 */
  const showReplyList = (mallOrder) => {
    setEventContent(() => {return mallOrder;});
    setTimeout(setReplyListModalStatus(true), 1000);
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(mallOrderActions_setPageNumber(1));
    dispatch(mallOrderActions_setIsPushedSearchButton(true));
    await getMallOrderList(0, mallOrderReducer.mallOrderStatus, true);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(mallOrderActions_setReset());
    dispatch(mallOrderActions_setPageNumber(1));
    await getMallOrderList(0, 'all', false);
  };

  /* 수정 */
  const actUpdate = () => {
    const checkedMallOrderList = Array.from(_checkedItem);
    if (checkedMallOrderList.length < 1) return;
    else if (checkedMallOrderList.length > 1) return alert('다중 선택되어 있습니다.');

    const updateContentIndex = _mallOrderList.findIndex((thisItem) => thisItem.mallOrderId === checkedMallOrderList[0]);
    if (updateContentIndex === -1) return alert('수정할 물품을 선택해 주세요.');
    const updateContent = _mallOrderList[updateContentIndex];

    navigate({
      pathname: '/mallManagement/order/update',
      search: `?${createSearchParams({updateContent: JSON.stringify(updateContent)})}`,
      replace: true,
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const checkedMallOrderList = Array.from(_checkedItem);
    console.log('checkedMallOrderList : ', checkedMallOrderList);

    if (checkedMallOrderList.length < 1) return;

    const deleteContents = checkedMallOrderList.map((thisKey) => {
      const deleteContentIndex = _mallOrderList.findIndex((thisIndex) => thisIndex.mallOrderId === thisKey);
      if (deleteContentIndex === -1) return null;
      const deleteContent = _mallOrderList[deleteContentIndex];
      return deleteContent;
    });
    const setDeleteContents = deleteContents.filter((thisItem) => thisItem !== null);

    if (setDeleteContents.length < 1) return;

    navigate({
      pathname: '/mallManagement/order/delete',
      search: `?${createSearchParams({deleteContents: JSON.stringify(setDeleteContents)})}`,
      replace: true,
    });
  };

  /* 취소 */
  const actCancel = async (mallOrder, index) => {
    const confirmText = `
    [ 취소 ]상태로 바꿀 시,
    주문정보를 다시 변경할 수 없습니다.
    주문을 취소하시겠습니까?
    `;
    if (window.confirm(confirmText) === true) {
      const BodyToPut = {
        ...mallOrder,
        deleteMallOrderElementList: [],
        deleteFileDataList: [],
        userId: userReducer.user.id,
        status: 'cancel',
      };
      console.log('actCancel : ', BodyToPut);

      const putFormData = new FormData();
      putFormData.append('key', new Blob([JSON.stringify(BodyToPut)], { type: 'application/json' }));
      for (let values of putFormData.values()) {
        console.log('putFormData : ', values);
      }

      await order.updateMallOrder(mallOrder.mallOrderId, putFormData).then((response) => {
        if (response === undefined) return;
        console.log('order.updateMallOrder : ', response);
        alert('주문을 취소했습니다.');
        handleMallOrderStatus(response.data, index);
      });
    } else return;
  };

  /* ====================================================================== #6 */

  return (
    <MallBody contents={
      <>
        <MainSection className="Main">
          <NavBar
            title={'주문관리'}
            buttons={
              <>
                <button className='btn-edit' onClick={actUpdate}>수정</button>
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="SearchSection" style={{columnGap: '20px'}}>
                  <select
                    className="SelectStatus"
                    value={mallOrderReducer.mallOrderStatus}
                    onChange={(e) => {
                      dispatch(mallOrderActions_setMallOrderStatus(e.target.value));
                      dispatch(mallOrderActions_setPageNumber(1));
                      getMallOrderList(0, e.target.value, mallOrderReducer.isPushedSearchButton);
                    }}
                  >
                    {[
                      { optionValue: 'all', optionText: '전체' },
                      { optionValue: 'waiting', optionText: '대기' },
                      { optionValue: 'consulting', optionText: '협의 중' },
                      {optionValue: 'preparing', optionText: '상품 준비 중'},
                      { optionValue: 'complete', optionText: '상품 발송' },
                      {optionValue: 'requestCancel', optionText: '취소 요청'},
                      { optionValue: 'cancel', optionText: '취소' },
                    ].map((thisItem) => {
                      return (<option key={thisItem.optionValue + '_mallOrderStatus'} value={thisItem.optionValue}>{thisItem.optionText}</option>);
                    })}
                  </select>

                  <div className="ContentCBox">
                    <div className="ContentCBox">
                      <select
                        className="SearchOption"
                        onChange={(e) => {dispatch(mallOrderActions_setSearchOption(e.target.value));}}
                      >
                        {[
                          { optionValue: '', optionText: '검색옵션' },
                          {optionValue: 'mallOrderCode', optionText: '주문코드'},
                          {optionValue: 'mallOrderContent', optionText: '주문내용'},
                        ].map((thisItem) => {
                          return (<option key={thisItem.optionValue + '_mallOrderSearchOption'} value={thisItem.optionValue}> {thisItem.optionText}</option>);
                        })}
                      </select>
                      <input
                        className="SearchBar"
                        placeholder="Search..."
                        value={mallOrderReducer.searchText}
                        onInput={(e) => {dispatch(mallOrderActions_setSearchText(e.target.value));}}
                      />
                    </div>

                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton" onClick={actReset}>초기화</div>
                  </div>
                </div>
              </>
            }
          />

          <TableSection content={
            <table>
              <colgroup>
                <col width={'120px'} />
                <col width={'60px'} />
                <col width={'25%'}/>
                <col width={'25%'}/>
                <col/>
                <col width={'60px'} />
              </colgroup>
              <thead>
                <tr>
                  <th>주문상태</th>
                  <th></th>
                  <th>주문정보</th>
                  <th>받는사람</th>
                  <th>주문물품</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {_mallOrderList.map((mallOrder, index) => {
                  return (
                    <tr key={index + '_mallOrders'} name={'mallOrderRows'}>
                      <StatusTd>
                        {(() => {
                          switch (mallOrder.status) {
                            case 'consulting': return (<MallConsultingTdButtons mallOrder={mallOrder} handleMallOrderStatus={(data) => {handleMallOrderStatus(data, index);}} />);
                            case 'preparing': return (<MallPreparingTdButtons mallOrder={mallOrder} handleMallOrderStatus={(data) => {handleMallOrderStatus(data, index);}} />);
                            case 'complete': return (<MallCompleteTdButtons mallOrder={mallOrder} handleMallOrderStatus={(data) => {handleMallOrderStatus(data, index);}} />);
                            case 'requestCancel': return (<MallRequestCancelTdButtons mallOrder={mallOrder} handleMallOrderStatus={(data) => {handleMallOrderStatus(data, index);}} />);
                            case 'cancel': return (<MallCancelTdButtons mallOrder={mallOrder} handleMallOrderStatus={(data) => {handleMallOrderStatus(data, index);}} />);
                            
                            default: return (<MallWaitingTdButtons mallOrder={mallOrder} handleMallOrderStatus={(data) => {handleMallOrderStatus(data, index);}} />);
                          }
                        })()}
                      </StatusTd>
                      <td style={{padding: 'unset', position: 'relative'}}>
                        <ActButtons>
                          <ActButton>
                            <input
                              type="radio"
                              name="mallOrders"
                              data-key={mallOrder.mallOrderId}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                handleCheckedItem(mallOrder.mallOrderId, isChecked);
                              }}
                              onClick={(e) => {e.stopPropagation();}}
                            />
                          </ActButton>
                          <ActButton className="file" onClick={() => {showFileList(mallOrder);}}>첨부<br />파일</ActButton>
                          <ActButton className="reply" onClick={() => {showReplyList(mallOrder);}}>댓글</ActButton>
                        </ActButtons>
                      </td>
                      <td className="OrderInfos">
                        <OrderInfos>
                          <OrderInfo><h5>주문코드</h5><p>{mallOrder.mallOrderCode}</p></OrderInfo>
                          <OrderInfo><h5>구매자</h5><p>{mallOrder.userName}</p></OrderInfo>
                          <OrderInfo /*  className='orderInfo' */><h5>주문내용</h5><p>{mallOrder.mallOrderContent}</p></OrderInfo>
                        </OrderInfos>
                      </td>
                      <td className="OrderInfos">
                        <OrderInfos>
                          <OrderInfo><h5>이름</h5><p>{mallOrder.recipientName}</p></OrderInfo>
                          <OrderInfo><h5>연락처</h5><p>{mallOrder.phoneNumber}</p></OrderInfo>
                          <OrderInfo><h5>우편번호</h5><p>{mallOrder.zip}</p></OrderInfo>
                          <OrderInfo /*  className='orderInfo' */><h5>주소</h5><p>{mallOrder.address}</p></OrderInfo>
                        </OrderInfos>
                      </td>
                      <td style={{minWidth: '350px', padding: 'unset', verticalAlign: 'top'}}>
                        <MallElementList>
                          <MallElement className="title">
                            <p>이름</p>
                            <p>코드</p>
                            <p>수량</p>
                          </MallElement>
                          {mallOrder.mallOrderElementList.map((element, elementIndex) => {
                            return (
                              <MallElement key={index + '_' + elementIndex + '_mallElements'}>
                                <p>{element.mallElementName}</p>
                                <p>{element.mallElementCode}</p>
                                <p>{checkEmptyNull(element.count, 0).toLocaleString()}</p>
                              </MallElement>
                            );
                          })}
                        </MallElementList>
                      </td>
                      {(() => {
                        if (mallOrder.status !== 'cancel') {
                          return (<OrderCancelButton onClick={() => {actCancel(mallOrder, index);}}>취소</OrderCancelButton>);
                        } else {
                          return (<td></td>);
                        }
                      })()}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          }
          ></TableSection>
        </MainSection>

        <PagingComponent
          page={mallOrderReducer.pageNumber}
          count={mallOrderReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(mallOrderActions_setPageNumber(page));
          }}
        />

        {_fileListModalStatus === true && _eventContent.mallOrderId !== undefined ? (
          <ShowMallFileList
            type={'mallOrder'}
            content={_eventContent}
            update={() => {getMallOrderList(mallOrderReducer.pageNumber - 1, mallOrderReducer.mallOrderStatus, mallOrderReducer.isPushedSearchButton);}}
            open={_fileListModalStatus}
            close={() => {setFileListModalStatus(false);}}
          ></ShowMallFileList>
        ) : null}

        {_replyListModalStatus === true && _eventContent.mallOrderId !== undefined ? (
          <MallOrderReply
            mallOrder={_eventContent}
            open={_replyListModalStatus}
            close={() => {setReplyListModalStatus(false);}}
          ></MallOrderReply>
        ) : null}
      </>
    }
    />
  );
};

export default MallOrderManagement;
