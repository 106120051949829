import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

import { calendarApi } from 'api/apis/calendarApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  max-height: 700px;
  max-width: 800px;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  & form > div {background-color: unset;
    &:last-child {padding-bottom: unset;}
  }
  textarea {height: 150px; padding: 10px; width: 100%}
  & .w100 {place-content: unset;}
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const CreateWorkPlan = (props) => {
  const { userReducer } = useSelector((state) => state);

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    calenderId: null,
    calenderDate: '',
    note: {note1: '', note2: ''},
  });

  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {return { ...prev, [name]: value };});
  };
  const handleNoteEvent = (e) => {
    const { name, value } = e.target;
    const noteData = { ..._formData.note };
    noteData[name] = value;
    setFormData((prev) => {return { ...prev, note: noteData };});
  };

  const actCreate = async () => {
    if (!checkEmptyNull(_formData.calenderDate, false)) return alert('날짜를 선택해주세요');
    if (!checkEmptyNull(_formData.note.note1, false) && !checkEmptyNull(_formData.note.note2, false)) return alert('일정내용을 입력해주세요');

    const BodyToPost = { ..._formData, note: JSON.stringify(_formData.note) };
    await calendarApi.createCalendar(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('calendarApi.createCalendar : ', response);
      alert('일정이 추가 되었습니다.');
      setTimeout(() => {props.close(moment(_formData.calenderDate).format('YYYY-MM-DD'));}, 1000);
    })
  };
  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>일정 추가</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <Form
                title={<>일정<br />추가<br /></>}
                buttons={<></>}
                forms={
                  <>
                    <div className="formBox">
                      <h4>날짜</h4>
                      <input
                        type="date"
                        name="calenderDate"
                        data-required="required"
                        onChange={handleInputEvent}
                      />
                    </div>

                    <div className="formBox w100">
                      <h4>일정내용</h4>
                      <textarea
                        name="note1"
                        placeholder="일정내용을 입력해주세요...."
                        onChange={handleNoteEvent}
                      />
                      <textarea
                        name="note2"
                        placeholder="일정내용을 입력해주세요...."
                        onChange={handleNoteEvent}
                      />
                    </div>
                  </>
                }
              />
            </ModalMain>

            <ModalFooter>
              <ModalButton onClick={actCreate}>추가</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default CreateWorkPlan;
