import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { userLogApi } from 'api/apis/userLogApi';

import { checkEmptyNull, checkNullArray, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import { DateTimeFormat } from 'components/format/DateTimeFormat';
import { LoadingMsg } from 'components/loading/LoadingMsg'

import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import OperatorBody from 'components/layouts/body/OperatorBody';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const MainSection = styled.main`
  display: grid;
  grid-template-rows: auto minmax(calc(100% - 120px), calc(100% - 60px));
`;

const CompanyAccessHistory = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();
  const company = checkNullParse(searchParams.get('company'), {});
  const { companyId, companyName } = company;

  const [_onload, setOnload] = useState('unload');

  const [_logList, setLogList] = useState([]);

  const [_startDate, setStartDate] = useState('');
  const [_endDate, setEndDate] = useState('');
  const [_searchText, setSearchText] = useState('');

  const [_pageNumber, setPageNumber] = useState(1);
  const [_totalSize, setTotalSize] = useState(0);


  /* ====================================================================== #3 */
  useEffect(() => {
    if (userReducer.user.role !== 'ROLE_MASTER') return navigate('/', { replace: true });
    dispatch(pageReducer_getCurrentPage(window.location.pathname));
    getUserLogs(_pageNumber - 1);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') getUserLogs(_pageNumber - 1);
    return () => { };
  }, [_pageNumber]);

  /* ====================================================================== #4 */
  const getUserLogs = async (page, clear) => {
    const paging = `?page=${page}&size=20&sort=id,DESC`;
    const BodyToPost = {
      companyId: companyId,
    };
    if (!clear && checkEmptyNull(_startDate, false) && checkEmptyNull(_endDate, false)) {
      BodyToPost.startDate = DateFormat(_startDate);
      BodyToPost.endDate = DateFormat(_endDate);
      if (_startDate > _endDate) {
        BodyToPost.startDate = DateFormat(_endDate);
        BodyToPost.endDate = DateFormat(_startDate);
      }
    } else if (!clear && checkEmptyNull(_startDate, false) && !checkEmptyNull(_endDate, false)) {
      BodyToPost.startDate = DateFormat(_startDate);
    } else if (!clear && checkEmptyNull(_endDate, false) && !checkEmptyNull(_startDate, false)) {
      BodyToPost.endDate = DateFormat(_endDate);
    }
    if (!clear && checkEmptyNull(_searchText, false)) {
      BodyToPost.name = _searchText;
    }
    await userLogApi.searchUserLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('userLogApi.searchUserLog : ', response);
      setLogList(() => { return response.data.content });
      setTotalSize(() => { return response.data.totalElements });
    });
  };

  const setDurationTime = (seconds) => {
    const hour = parseInt(seconds / 3600) < 10 ? '0' + parseInt(seconds / 3600) : parseInt(seconds / 3600);
    const min = parseInt(parseInt(seconds % 3600) / 60) < 10 ? '0' + parseInt(parseInt(seconds % 3600) / 60) : parseInt(parseInt(seconds % 3600) / 60);
    const sec = parseInt(seconds % 60) < 10 ? '0' + parseInt(seconds % 60) : parseInt(seconds % 60);
    const DurationTime = `${hour}:${min}:${sec}`;
    return DurationTime;
  };

  /* ====================================================================== #5 */
  /* 검색 */
  const actSearch = async () => {
    setPageNumber(() => { return 1 });
    await getUserLogs(0);
  };

  /* 초기화 */
  const actReset = async () => {
    setStartDate(() => { return ''; });
    setEndDate(() => { return ''; });
    setSearchText(() => { return ''; });

    setPageNumber(() => { return 1 });
    await getUserLogs(0, true);
  };

  /* ====================================================================== #6 */

  return (
    <OperatorBody contents={
      <>
        <MainSection className="Main">
          <NavBar
            title={`${companyName} 접속현황`}
            buttons={
              <>
                <button className='btn-set'
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/operator/companyManagement', { replace: true });
                  }}
                >
                  뒤로
                </button>
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="SearchSection">
                  <div className="SearchSection">
                    <div className="SearchSection">
                      <input
                        type="date"
                        className="SelectDate"
                        value={_startDate}
                        onChange={(e) => { setStartDate(() => { return e.target.value; }); }}
                      />

                      <IconRightArrow />

                      <input
                        type="date"
                        className="SelectDate"
                        value={_endDate}
                        onChange={(e) => { setEndDate(() => { return e.target.value; }); }}
                      />
                    </div>

                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="name">사용자</option>
                      </select>
                      <input
                        className="SearchBar detail"
                        placeholder="Search..."
                        value={_searchText}
                        onInput={(e) => { setSearchText(() => { return e.target.value; }); }}
                      />
                    </div>
                  </div>

                  <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                  <div className="ResetButton" onClick={actReset}>초기화</div>
                </div>
              </>
            }
          />

          <TableSection content={
            <table>
              <thead>
                <tr>
                  <th>사용자</th>
                  <th>로그인</th>
                  <th>로그아웃</th>
                  <th>접속시간</th>
                </tr>
              </thead>
              <tbody>
                {!checkNullArray(_logList, false) ? LoadingMsg() : checkNullArray(_logList, []).map((log, index) => {
                  const logInTime = DateTimeFormat(log.logInTime);
                  let logOutTime = null;
                  let DurationSec = null;
                  if (checkEmptyNull(log.logOutTime, false)) {
                    logOutTime = DateTimeFormat(log.logOutTime);
                    DurationSec = moment.duration(moment(logOutTime).diff(moment(logInTime))).asSeconds();
                  }

                  return (
                    <tr key={index + '_accessLog'}>
                      <td>{log.userName}</td>
                      <td>{logInTime}</td>
                      <td>{logOutTime}</td>
                      <td>{checkEmptyNull(logOutTime, false) ? setDurationTime(DurationSec) : '접속중'}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          }
          ></TableSection>
        </MainSection>

        <PagingComponent
          page={_pageNumber}
          count={_totalSize}
          size={20}
          pageEvent={(page) => {
            console.log('page : ', page);
            setPageNumber(() => { return page });
          }}
        />
      </>
    }
    />
  );
};

export default CompanyAccessHistory;
