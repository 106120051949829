import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { contentsReducer_setDeleteContents } from 'store/modules/actions/default/contentsActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { productionOrderActions_setEndDate, productionOrderActions_setPageNumber, productionOrderActions_setReset, productionOrderActions_setSearchText, productionOrderActions_setStartDate, productionOrderActions_setTotalSize, productionOrderActions_setWorkOrderStatus, productionOrderActions_setWorkOrderType } from 'store/modules/actions/common/productionOrderActions';
import { stockReducer_setContractAccount } from 'store/modules/actions/default/stockActions';
import { uriReducer_setUpdateWorkOrder, uriReducer_setWorkOrderContent } from 'store/modules/actions/company/UriActions';
import { userReducer_setSelectUser } from 'store/modules/actions/user/userActions';
import { workActions_setCurrentMessage } from 'store/modules/actions/default/workActions';

import { workOrderLogApi } from 'api/apis/workOrderLogApi';
import { workOrderTypeApi } from 'api/apis/workOrderTypeApi';

import { checkEmptyNull, checkNull, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';

import UriDetailWorkOrder from 'pages/productionOrder/custom/company/uri/modal/UriDetailWorkOrder';

const ProductionOrderSection = styled.main`
  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
    & .statusButtons {display: flex; gap: 5px;}
  }

  & .TableSection {
    &>table {
      background-color: var(--Bg);
      border-collapse: separate;
      border-spacing: 0px 10px;
      & td:nth-child(2), & td:nth-child(3) {background-color: var(--white);}
    }
  }
`;

const WorkOrderTable = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  table {
    background-color: var(--white);
    border-collapse: collapse;
    height: 96%;
    margin: 0px auto;
    table-layout: auto;
    width: 98%;

    td, th {
      border: 1px solid var(--gray-200);
      box-sizing: border-box;
      height: 40px;
      min-width: unset;
      padding: unset;
    }
    th {font-size: 0.8em;}
    & td.colors {
      font-size: 25px;
      font-weight: 600;
      outline: 4px solid var(--Text);
      outline-offset: -4px;
      text-align: center;
    }
    & td.remark {
      color: var(--ThirdBlue);
      font-size: 1.1em;
      padding: 10px;
      text-align: left;
      vertical-align: top;
    }
  }

  &:hover {cursor: pointer; opacity: 0.6;}
`;

const ConnectStatus = styled.div`
  background-color: ${(props) => {
    return props.connectStatus === 'connect' ? 'var(--MainGreen)' : 'var(--MainRed)';
  }};
  border-radius: 50%;
  right: 76px;
  bottom: 26px;
  position: fixed;
  height: 40px;
  width: 40px;
  z-index: 1000;
`;

const UriProductionOrder = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, productionOrderReducer, userReducer, workReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_workOrderTypes, setWorkOrderTypes] = useState([]);

  const [_workOrderLogList, setWorkOrderLogList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_modalContent, setModalContent] = useState({});
  const [_modalStatus, setModalStatus] = useState(false);
  const [_detailModalStatus, setDetailModalStatus] = useState(false);
  const [_updateModalStatus, setUpdateModalStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('403') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getWorkOrderTypes();
    getWorkOrderLogList(0, 'all', 'waitNwork');

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getWorkOrderLogList(productionOrderReducer.pageNumber - 1, productionOrderReducer.workOrderType, productionOrderReducer.workOrderStatus);
    }

    return () => { };
  }, [productionOrderReducer.pageNumber]);

  useEffect(() => {
    if (workReducer.connectStatus !== 'connect') {
      if (!checkNullObject(workReducer.currentMessage, false)) return;

      if (workReducer.currentMessage.userId === userReducer.user.id && checkNull(workReducer.currentMessage.errorMessage, false)) {
        dispatch(workActions_setCurrentMessage({}));
        return;
      }

      if (workReducer.currentMessage.type === '/work') applyStatus();
      if (workReducer.currentMessage.type === '/process') applyProcessStatus();
      if (workReducer.currentMessage.type === '/task') applyTaskStatus();
    }

    return () => { };
  }, [workReducer.currentMessage]);

  useEffect(() => { }, [workReducer.connectStatus]);

  /* ====================================================================== #4 */
  const getWorkOrderTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await workOrderTypeApi.searchWorkOrderType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderTypeApi.searchWorkOrderType : ', response);
      setWorkOrderTypes(() => { return response.data; });
    });
  };

  const getWorkOrderLogList = async (page, workOrderType, workOrderStatus, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && checkEmptyNull(workOrderType, false) && workOrderType !== 'all') {
      BodyToPost.workOrderTypeId = Number(workOrderType);
    }
    if (!clear && checkEmptyNull(workOrderStatus, false)) {
      switch (workOrderStatus) {
        case 'all': BodyToPost.workOrderStatus = ['wait', 'work', 'pause', 'stop', 'end', 'cancel']; break;
        case 'waitNwork': BodyToPost.workOrderStatus = ['wait', 'work']; break;

        default: BodyToPost.workOrderStatus = [workOrderStatus]; break;
      }
    }

    if (!clear && checkEmptyNull(productionOrderReducer.startDate, false) && checkEmptyNull(productionOrderReducer.endDate)) {
      BodyToPost.scheduledStartDate = DateFormat(productionOrderReducer.startDate);
      BodyToPost.scheduledEndDate = DateFormat(productionOrderReducer.endDate);
      if (productionOrderReducer.startDate > productionOrderReducer.endDate) {
        BodyToPost.scheduledStartDate = DateFormat(productionOrderReducer.endDate);
        BodyToPost.scheduledEndDate = DateFormat(productionOrderReducer.startDate);
      }
    } else if (!clear && checkEmptyNull(productionOrderReducer.startDate, false)) {
      BodyToPost.scheduledStartDate = DateFormat(productionOrderReducer.startDate);
    } else if (!clear && checkEmptyNull(productionOrderReducer.endDate)) {
      BodyToPost.scheduledEndDate = DateFormat(productionOrderReducer.endDate);
    }

    if (!clear && checkEmptyNull(productionOrderReducer.searchText)) {
      BodyToPost.accountName = productionOrderReducer.searchText;
    }

    await workOrderLogApi.searchWorkOrderLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.searchWorkOrderLog : ', response);

      setWorkOrderLogList(() => { return response.data.data.content; });
      dispatch(productionOrderActions_setTotalSize(response.data.data.totalElements));

      if (!clear && checkEmptyNull(productionOrderReducer.startDate, false) && checkEmptyNull(productionOrderReducer.endDate)) {
        if (productionOrderReducer.startDate > productionOrderReducer.endDate) {
          dispatch(productionOrderActions_setStartDate(productionOrderReducer.endDate));
          dispatch(productionOrderActions_setEndDate(productionOrderReducer.startDate));
        }
      }
    });
  };

  /* 웹소켓 */
  const applyStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    if (eventWorkOrderIndex === -1) return;
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    eventWorkOrder.workOrderStatus = applyContent.workOrderStatus;
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, eventWorkOrder);
    setWorkOrderLogList(eventWorkOrderList);
  };
  const applyProcessStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    if (eventWorkOrderIndex === -1) return;
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    const eventProcessLogList = [...eventWorkOrder.processLogList];
    const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === applyContent.processLogId);
    if (eventProcessLogIndex === -1) return;
    const eventContent = eventProcessLogList[eventProcessLogIndex];
    eventContent.processStatus = applyContent.processStatus;
    eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);

    const detailWorkOrder = {
      ...eventWorkOrder,
      processLogList: eventProcessLogList,
    };
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, detailWorkOrder);
    setWorkOrderLogList(eventWorkOrderList);
  };
  const applyTaskStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    if (eventWorkOrderIndex === -1) return;
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    const eventProcessLogList = [...eventWorkOrder.processLogList];
    const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === applyContent.processLogId);
    if (eventProcessLogIndex === -1) return;
    const eventContent = eventProcessLogList[eventProcessLogIndex];
    const eventTaskLogList = [...eventContent.taskLogList];
    applyContent.taskSocketDtos.forEach((thisApplyTask) => {
      const eventTaskLogIndex = eventTaskLogList.findIndex((thisItem) => thisItem.taskLogId === thisApplyTask.taskLogId);
      if (eventTaskLogIndex === -1) return;
      const eventTaskContent = eventTaskLogList[eventTaskLogIndex];
      eventTaskContent.taskStatus = thisApplyTask.taskStatus;
      eventTaskLogList.splice(eventTaskLogIndex, 1, eventTaskContent);
    });
    eventContent.taskLogList = eventTaskLogList;
    eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);

    const detailWorkOrder = {
      ...eventWorkOrder,
      processLogList: eventProcessLogList,
    };
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, detailWorkOrder);
    setWorkOrderLogList(eventWorkOrderList);
  };

  /* ====================================================================== #5 */
  /* 검색 - 작업상태 */
  const handleSearchWorkOrderStatus = async (workOrderStatus) => {
    dispatch(productionOrderActions_setWorkOrderStatus(workOrderStatus));
    dispatch(productionOrderActions_setPageNumber(1));
    await getWorkOrderLogList(0, productionOrderReducer.workOrderType, workOrderStatus)
  }

  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(id);
    setCheckedItem(() => { return checkedItem; });
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(productionOrderActions_setPageNumber(1));
    await getWorkOrderLogList(0, productionOrderReducer.workOrderType, productionOrderReducer.workOrderStatus);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(productionOrderActions_setReset());
    dispatch(productionOrderActions_setPageNumber(1));
    await getWorkOrderLogList(0, 'all', 'waitNwork', true);
  };

  /* 상세보기 */
  const showDetailWorkOrder = (item) => {
    setModalContent(item);
    dispatch(uriReducer_setWorkOrderContent(item));
    setTimeout(setDetailModalStatus(true), 1000);
  };

  /* 추가 모달 */
  const showWorkOrderModal = () => {
    const userData = {
      id: userReducer.user.id,
      name: userReducer.user.name,
    }
    dispatch(userReducer_setSelectUser(userData));
    setTimeout(setModalStatus(true), 1000);
  };

  /* 수정 */
  const actUpdateInfo = (e) => {
    if (document.querySelectorAll('input[name="workOrder"]:checked').length < 1) return alert('수정할 작업지시를 선택해주세요.');
    else if (document.querySelectorAll('input[name="workOrder"]:checked').length > 1) return alert('다중 선택되어 있습니다.');

    const CheckedRow = document.querySelector('input[name="workOrder"]:checked');
    const updateContents = _workOrderLogList.filter((thisItem) => thisItem.workOrderLogId === Number(CheckedRow.dataset.key))[0];

    if (updateContents.workOrderStatus === 'end' || updateContents.workOrderStatus === 'cancel') {
      let statusText = '';
      switch (updateContents.workOrderStatus) {
        case 'end': statusText = '완료'; break;
        case 'cancel': statusText = '취소'; break;

        default: break;
      }
      return alert(`작업상태가 [ ${statusText} ]인 작업지시은 정보수정을 할 수 없습니다.`);
    }

    setModalContent(updateContents);

    dispatch(stockReducer_setContractAccount({}));
    dispatch(userReducer_setSelectUser({}));
    dispatch(uriReducer_setUpdateWorkOrder(updateContents));

    setTimeout(setUpdateModalStatus(true), 1000);
  };

  /* 삭제 */
  const actDelete = () => {
    if (document.querySelectorAll('input[name="workOrder"]:checked').length < 1) return alert('삭제할 작업지시를 선택해주세요.');
    else if (document.querySelectorAll('input[name="workOrder"]:checked').length > 1) return alert('다중 선택되어 있습니다.');

    const CheckedRow = document.querySelector('input[name="workOrder"]:checked');
    const delContents = _workOrderLogList.filter((thisItem) => thisItem.workOrderLogId === Number(CheckedRow.dataset.key))[0];

    dispatch(contentsReducer_setDeleteContents(delContents));
    setTimeout(navigate(pageReducer.currentPage + '/delete', { replace: true }), 1000);
  };

  /* 수정 */
  const actUpdate = () => {
    const updateContentIndex = _workOrderLogList.findIndex((item) => item.workOrderLogId === Array.from(_checkedItem)[0]);
    if (updateContentIndex === -1) return alert('수정할 작업지시를 선택해 주세요.');
    const updateContent = _workOrderLogList[updateContentIndex];
    console.log('updateContent : ', updateContent);

    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({ updateContent: JSON.stringify(updateContent) })}`,
      replace: true,
    });
  }

  /* ====================================================================== #6 */

  return (
    <Grid3Body contents={
      <>
        <ProductionOrderSection className="Main">
          <NavBar
            title={
              <div className='Title'>
                <NavTitle menuCode={'403'} />
                <div className='statusButtons'>
                  {[
                    { status: 'all', name: '전체' },
                    { status: 'waitNwork', name: '대기/작업중' },
                    { status: 'wait', name: '대기' },
                    { status: 'work', name: '작업중' },
                    { status: 'pause', name: '일시정지' },
                    { status: 'stop', name: '중단' },
                  ].map((workOrderStatus, index) => {
                    return (
                      <button key={index + '_statusButton'}
                        className={productionOrderReducer.workOrderStatus === workOrderStatus.status ? 'btn-inven-1' : 'btn-set'}
                        onClick={() => { handleSearchWorkOrderStatus(workOrderStatus.status); }}
                      >
                        {workOrderStatus.name}
                      </button>
                    )
                  })}
                </div>
              </div>
            }
            buttons={
              <>
                {_authority.indexOf('403-2') !== -1 ? (
                  <>
                    <button className='btn-add'
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(pageReducer.currentPage + '/create', { replace: true });
                      }}
                    >
                      추가
                    </button>
                    <button className='btn-edit' onClick={actUpdate}>수정</button>
                    <button className='btn-delete' onClick={actDelete}>삭제</button>
                  </>
                ) : null}
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <div className="SearchSection">
                    <input
                      type="date"
                      className="SelectDate"
                      value={productionOrderReducer.startDate}
                      onChange={(e) => { dispatch(productionOrderActions_setStartDate(e.target.value)) }}
                    />

                    <IconRightArrow />

                    <input
                      type="date"
                      className="SelectDate"
                      value={productionOrderReducer.endDate}
                      onChange={(e) => { dispatch(productionOrderActions_setEndDate(e.target.value)) }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="accountName">거래처</option>
                      </select>
                      <input
                        className="SearchBar detail"
                        placeholder="Search..."
                        value={productionOrderReducer.searchText}
                        onInput={(e) => { dispatch(productionOrderActions_setSearchText(e.target.value)) }}
                      />
                    </div>

                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton" onClick={actReset}>초기화</div>
                  </div>
                </div>
              </>
            }
          />

          <TableSection content={
            <table>
              <thead style={{ zIndex: '800' }}>
                <tr>
                  <th style={{ minWidth: '100px', padding: 'unset', width: '100px' }}>작업상태</th>
                  {_authority.indexOf('403-2') !== -1 ? (<th style={{ minWidth: '50px', width: '50px' }}></th>) : null}
                  <th style={{ minWidth: '150px', width: '150px' }}>거래처</th>
                  <th style={{ minWidth: '960px' }}>작업지시</th>
                </tr>
              </thead>
              <tbody>
                {_workOrderLogList.map((workOrderLog, index) => {
                  const workOrderInfo = checkNullParse(workOrderLog.workOrderCustomizedContent, {
                    BF: { date: '', amount: '' },
                    SF: { date: '', amount: '' },
                    MF: { date: '', amount: '' },
                    total: { date: '', amount: '' },

                    colors: '', // 색상
                    phoneNumber: '', // 전화번호
                  });

                  return (<Fragment key={workOrderLog.workOrderLogId + '_' + index}>
                    <tr data-key={workOrderLog.workOrderLogId} name="workOrderRow">
                      <StatusTdButton
                        statusStyle={{ cursor: 'default', minHeight: '100px', minWidth: '80px', width: '80px' }}
                        content={workOrderLog}
                        contentId={workOrderLog.workOrderLogId}
                        status={workOrderLog.workOrderStatus}
                      />
                      {_authority.indexOf('403-2') !== -1 ? (
                        <td style={{ minWidth: '50px', width: '50px' }}>
                          <input
                            type="checkBox"
                            name="workOrder"
                            data-key={workOrderLog.workOrderLogId}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              handleCheckedItem(workOrderLog.workOrderLogId, isChecked);
                              if (isChecked) {
                                document.getElementsByName('workOrder').forEach((checkbox) => { checkbox.checked = false });
                                e.target.checked = true;
                              } else {
                                e.target.checked = false;
                              }
                            }}
                          />
                        </td>
                      ) : null}
                      <td style={{ minWidth: '150px', width: '150px' }}>{workOrderLog.accountName}</td>
                      <td style={{ minWidth: '960px', padding: 'unset' }}>
                        <WorkOrderTable onClick={() => { showDetailWorkOrder(workOrderLog); }}>
                          <table data-key={workOrderLog.workOrderLogId}>
                            <colgroup>
                              <col width='50px'></col>
                              <col width='350px'></col>
                              <col width='50px'></col>
                              <col width='200px'></col>
                              <col width='50px'></col>
                              <col width='100px'></col>
                              <col width='50px'></col>
                              <col width='50px'></col>
                            </colgroup>
                            <tbody>
                              <tr>
                                <th rowSpan={3}>색상</th>
                                <td rowSpan={3} className='colors'>
                                  {workOrderInfo.colors}
                                </td>
                                <th>거래처</th>
                                <td>{workOrderLog.accountName}</td>
                                <th>B/F납기</th>
                                <td>{checkNullObject(workOrderInfo?.BF, false) ? workOrderInfo?.BF?.date : workOrderInfo.BFDate}</td>
                                <th>B/F</th>
                                <td>{checkNullObject(workOrderInfo?.BF, false) ? workOrderInfo?.BF?.amount : workOrderInfo.BFAmount}</td>
                              </tr>
                              <tr>
                                <th>전화번호</th>
                                <td>{workOrderInfo.phoneNumber}</td>
                                <th>S/F납기</th>
                                <td>{checkNullObject(workOrderInfo?.SF, false) ? workOrderInfo?.SF?.date : workOrderInfo.TotalDate}</td>
                                <th>S/F</th>
                                <td>{checkNullObject(workOrderInfo?.SF, false) ? workOrderInfo?.SF?.amount : workOrderInfo.TotalAmount}</td>
                              </tr>
                              <tr>
                                <th>주소</th>
                                <td>{workOrderLog.workPlace}</td>
                                <th>M/F납기</th>
                                <td>{workOrderInfo?.MF?.date}</td>
                                <th>M/F</th>
                                <td>{workOrderInfo?.MF?.amount}</td>
                              </tr>
                              <tr>
                                <th rowSpan={2}>비고</th>
                                <td colSpan={3} rowSpan={2} className='remark'>{workOrderLog.remark}</td>
                                <th>지시일자</th>
                                <td colSpan={3}>{DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledStartDate, ''))}</td>
                              </tr>
                              <tr>
                                <th>작성자</th>
                                <td colSpan={3}>{workOrderLog.userName}</td>
                              </tr>
                            </tbody>
                          </table>
                        </WorkOrderTable>
                      </td>
                    </tr>
                  </Fragment>
                  );
                })}
              </tbody>
            </table>
          }
          ></TableSection>
        </ProductionOrderSection>

        <PagingComponent
          page={productionOrderReducer.pageNumber}
          count={productionOrderReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(productionOrderActions_setPageNumber(page));
          }}
        />

        <ConnectStatus connectStatus={workReducer.connectStatus} onClick={() => { navigate(0) }}></ConnectStatus>

        {_detailModalStatus === true ? (
          <UriDetailWorkOrder
            workOrderContent={_modalContent}
            open={_detailModalStatus}
            close={() => {
              setDetailModalStatus(false);
              getWorkOrderLogList(productionOrderReducer.pageNumber - 1, productionOrderReducer.workOrderType, productionOrderReducer.workOrderStatus);
            }}
          />
        ) : null}
      </>
    }
    />
  );
};

export default UriProductionOrder;
