import React from 'react';
import styled from 'styled-components';

import { rightArrow } from 'components/icons/src';

const Icon = styled.div`
  background-color: var(--ThirdBlue);
  height: 24px;
  width: 24px;

  mask-image: url(${rightArrow});
  mask-repeat: no-repeat;
  mask-size: contain;

  --webkit-mask-image: url(${rightArrow});
  --webkit-mask-repeat: no-repeat;
  --webkit-mask-size: contain;
`;

const IconRightArrow = (props) => {
  return <Icon style={props.style} />;
};

export default IconRightArrow;
