export const drawingManagementActions_setSearchOption = (searchOption) => {
  return { type: 'SETDRAWINGMANAGEMENTSEARCHOPTION', payload: searchOption };
};
export const drawingManagementActions_setSearchText = (searchText) => {
  return { type: 'SETDRAWINGMANAGEMENTSEARCHTEXT', payload: searchText };
};

export const drawingManagementActions_setPageNumber = (pageNumber) => {
  return { type: 'SETDRAWINGMANAGEMENTPAGENUMBER', payload: pageNumber };
};
export const drawingManagementActions_setTotalSize = (totalSize) => {
  return { type: 'SETDRAWINGMANAGEMENTTOTALSIZE', payload: totalSize };
};
export const drawingManagementActions_setIsPushedSearchButton = (isPushedSearchButton) => {
  return { type: 'SETDRAWINGMANAGEMENTISPUSHEDSEARCHBUTTON', payload: isPushedSearchButton };
};

export const drawingManagementActions_setReset = () => {
  return { type: 'SETDRAWINGMANAGEMENTRESET' };
};
