import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import styled from 'styled-components';

import {
  searchReducer_reset,
  searchReducer_setAllFamiles,
  searchReducer_setCurrentFamilyId,
  searchReducer_setEndDate,
  searchReducer_setFirstFamilies,
  searchReducer_setFirstFamily,
  searchReducer_setProductType,
  searchReducer_setSearchOption,
  searchReducer_setSearchText,
  searchReducer_setSecondFamilies,
  searchReducer_setSecondFamily,
  searchReducer_setStartDate,
  searchReducer_setThirdFamilies,
  searchReducer_setThirdFamily,
  searchReducer_setFourthFamily,
  searchReducer_setFourthFamilies,
  searchReducer_setFifthFamily,
  searchReducer_setFifthFamilies,
  searchReducer_setWorkStatus,
} from 'store/modules/actions/default/searchActions';

import {
  ACCESS_TOKEN,
  AXIOS_BASE_HEADERS,
  AXIOS_BASE_URL,
} from 'preferences/server/constants';
import { downArrow, rightArrow } from 'components/icons/src';
// import { handleOnKeyUp } from 'components/input/input';

const NavSection = styled.nav`
  background-color: var(--white);
  box-sizing: border-box;
  left: 0px;
  padding: 8px 20px;
  position: sticky;
  top: 0px;
  width: 100vw;
  z-index: 800;

  /* height: ${(props) => {
    return props.nav === '' ? '66px' : '96px';
  }}; */
`;

const TitleSection = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: space-between;
`;

const ContentTitle = styled.h1`
  font-size: 20px;
`;

const ButtonsSection = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;
`;

const FamilySection = styled.div`
  align-items: center;
  /* background-color: var(--MainNavy); */
  box-sizing: border-box;
  column-gap: 5px;
  display: flex;
  height: 50px;
  justify-content: flex-start;
  padding: 0px 10px;
`;

const SelectType = styled.select`
  background-color: var(--ThirdBlue);
  border: 1px solid var(--ThirdBlue);
  /* border: 1px solid var(--gray-200); */
  border-radius: 17px;
  box-sizing: border-box;
  color: var(--white);
  height: 35px;
  padding: 2px 20px;
  width: 150px;

  &:focus {
    background-color: var(--MainNavy);
  }
`;

const SelectFamily = styled.select`
  border: 1px solid var(--gray-200);
  border-radius: 5px;
  box-sizing: border-box;
  height: 35px;
  padding: 2px 10px;
  width: 150px;
`;

const RightArrow = styled.div`
  background-color: var(--ThirdBlue);
  height: 24px;
  width: 24px;

  mask-image: url(${rightArrow});
  mask-repeat: no-repeat;
  mask-size: contain;

  --webkit-mask-image: url(${rightArrow});
  --webkit-mask-repeat: no-repeat;
  --webkit-mask-size: contain;
`;

const SearchBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;

  &[data-section='date'] {
    column-gap: 5px;
  }
`;

const SelectStatus = styled.select`
  border: 1px solid var(--gray-200);
  border-radius: 5px;
  box-sizing: border-box;
  height: 35px;
  padding: 2px 10px;
  width: 150px;
`;

const SelectDate = styled.input`
  border: 1px solid var(--gray-200);
  border-radius: 5px;
  box-sizing: border-box;
  height: 35px;
  padding: 2px 10px;
  width: 180px;

  &:focus {
    border: 1px solid var(--gray-200);
  }
`;

const SearchOption = styled.select`
  border: 1px solid var(--gray-200);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-sizing: border-box;
  height: 35px;
  padding: 2px 10px;
  width: 120px;
`;

const SearchBar = styled.input`
  border: 1px solid var(--gray-200);
  border-left: unset;
  border-radius: 0px 8px 8px 0px;
  box-sizing: border-box;
  height: 35px;
  margin-right: 5px;
  padding: 5px;
  width: 250px;

  &:focus {
    border: 1px solid var(--gray-200);

    border-left: unset;
    border-right: unset;
  }
`;

const SearchSection = styled.div`
  align-items: center;
  /* background-color: var(--MainNavy); */
  box-sizing: border-box;
  column-gap: 25px;
  display: flex;
  height: 50px;
  justify-content: flex-start;
  padding: 0px 10px;
`;

const NavButtonIcon = styled.div`
  cursor: pointer;
  background-color: var(--white);
  width: 16px; height: 16px;
  mask-image: url(${downArrow});
  mask-repeat: no-repeat;
  mask-size: contain;

  --webkit-mask-image: url(${downArrow});
  --webkit-mask-repeat: no-repeat;
  --webkit-mask-size: contain;
`;

const NavButton = styled.div`
  /* background-color: var(--ThirdBlue); */
  /* border-top: 2px solid var(--ThirdBlue); */
  /* cursor: pointer; */
  display: flex;
  justify-content: center;
  align-items: center;
    height: 11px;
    width: 18px;
    position: absolute;
    right: 7px;
    top: 12px;
    cursor: pointer;
`;

const ResetButton = styled.div`
  align-items: center;
  color: var(--gray-400);
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: 600;
  height: 35px;
  justify-content: center;
  margin-left: 10px;
  width: 50px;

  &:hover {
    color: var(--gray-800);
  }
`;

const Nav = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { pageReducer, searchReducer, userReducer } = useSelector(
    (state) => state,
  );
  // console.log('searchReducer : ', searchReducer)

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  /* ====================================================================== #3 */
  useEffect(() => {
    if (searchReducer.allFamilies.length === 0 && props.nav === 'all') {
      getAllFamilies();
      // console.log(searchReducer.firstFamily);
    }
    setOnload('loaded');

    return () => {};
  }, []);

  // useEffect(() => {
  //   if (_onload === 'loaded') {
  //     if (props.nav === 'all') {
  //       getAllFamilies();
  //     }
  //   }

  //   return () => {};
  // }, [searchReducer.productType]);

  useEffect(() => {
    if (_onload === 'loaded') {
      if (pageReducer.currentPage !== window.location.pathname) {
        console.log(
          '>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>',
        );
        dispatch(searchReducer_reset());
        getAllFamilies();
      }

      // console.log(
      //   'Nav - getAllFamilies : ',
      //   pageReducer.currentPage.startsWith('/baseInformation') ||
      //     pageReducer.currentPage.startsWith('/stockManagement'),
      // );
      // if(pageReducer.currentPage.startsWith('/baseInformation') || pageReducer.currentPage.startsWith('/stockManagement')) {
      // if (props.nav === 'all') {
      //   getAllFamilies();
      // }
    }

    return () => {};
  }, [pageReducer.currentPage]);

  /* ====================================================================== #4 */
  const getAllFamilies = async () => {
    await axios
      .post(
        AXIOS_BASE_URL + '/category/search',
        {
          companyId: userReducer.company.companyId,
        },
        AXIOS_BASE_HEADERS,
      )
      .then((response) => {
        if (response === undefined) return;
        // console.log('searchAllCategory : ', response.data);
        dispatch(searchReducer_setAllFamiles(response.data));
        dispatch(
          searchReducer_setFirstFamilies(
            response.data.filter((thisItem) => thisItem.categoryLevel === 1),
          ),
        );

        // if (searchReducer.productType !== '') {
        // }
      })
      .catch((error) => {
        console.log('post/searchAllCategory-error : ', error);
      });
  };

  /* ====================================================================== #5 */
  /* 물품타입 선택 */
  const actSelectType = async (e) => {
    dispatch(searchReducer_setProductType(e.target.value));
    dispatch(searchReducer_setCurrentFamilyId(0));
    // console.log('event', e.target.value);

    document.getElementById('FirstFamily').value = '';
    document.getElementById('SecondFamily').value = '';
    document.getElementById('ThirdFamily').value = '';

    dispatch(searchReducer_setFirstFamily(''));
    dispatch(searchReducer_setSecondFamily(''));
    dispatch(searchReducer_setThirdFamily(''));

    await axios
      .post(
        AXIOS_BASE_URL + '/category',
        {
          companyId: userReducer.company.companyId,
        },
        AXIOS_BASE_HEADERS,
      )
      .then((response) => {
        if (response === undefined) return;
        Finish_findAllCategory(response.data);
      })
      .catch((error) => {
        console.log('get/findAllCategory-error : ', error);
      });
  };
  const Finish_findAllCategory = (Result) => {
    const categoriesList = Result.content;
    console.log('get/category : ', Result);
    console.log('get/category - content : ', categoriesList);

    if (categoriesList === undefined || categoriesList.length === 0) {
      return;
    }

    /* 초기화 */
    dispatch(
      searchReducer_setFirstFamilies(
        categoriesList.filter((thisItem) => thisItem.categoryLevel === 1),
      ),
    );
    dispatch(searchReducer_setSecondFamilies([]));
    dispatch(searchReducer_setThirdFamilies([]));

    // document.getElementById('FirstFamily').value = '';
    // document.getElementById('SecondFamily').value = '';
    // document.getElementById('ThirdFamily').value = '';

    // dispatch(searchReducer_setFirstFamily(''));
    // dispatch(searchReducer_setSecondFamily(''));
    // dispatch(searchReducer_setThirdFamily(''));
  };

  /* 패밀리 선택 */
  const showCategories = (e) => {
    const clickedCategory = e.target.value;
    dispatch(searchReducer_setCurrentFamilyId(Number(clickedCategory)));

    const thisCategory = searchReducer.allFamilies.filter(
      (thisItem) => thisItem.categoryId === Number(clickedCategory),
    )[0];
    // console.log(clickedCategory, thisCategory, searchReducer.allFamilies);

    const setList = searchReducer.allFamilies.filter(
      (thisItem) =>
        thisItem.categoryParentLevel === thisCategory.categoryLevel &&
        thisItem.categoryParentCode === thisCategory.categoryCode,
    );

    switch (thisCategory.categoryLevel) {
      case 1:
        dispatch(searchReducer_setSecondFamilies(setList));
        dispatch(searchReducer_setThirdFamilies([]));
        dispatch(searchReducer_setFourthFamilies([]));
        dispatch(searchReducer_setFifthFamilies([]));

        document.getElementById('SecondFamily').value = '';
        document.getElementById('ThirdFamily').value = '';
        if (
          document.getElementById('FourthFamily') !== null &&
          document.getElementById('FifthFamily') !== null
        ) {
          document.getElementById('FourthFamily').value = '';
          document.getElementById('FifthFamily').value = '';
        }

        dispatch(searchReducer_setFirstFamily(clickedCategory));
        dispatch(searchReducer_setSecondFamily(''));
        dispatch(searchReducer_setThirdFamily(''));
        dispatch(searchReducer_setFourthFamily(''));
        dispatch(searchReducer_setFifthFamily(''));
        break;

      case 2:
        dispatch(searchReducer_setThirdFamilies(setList));

        document.getElementById('ThirdFamily').value = '';
        if (
          document.getElementById('FourthFamily') !== null &&
          document.getElementById('FifthFamily') !== null
        ) {
          document.getElementById('FourthFamily').value = '';
          document.getElementById('FifthFamily').value = '';
        }

        dispatch(searchReducer_setSecondFamily(clickedCategory));
        dispatch(searchReducer_setThirdFamily(''));
        dispatch(searchReducer_setFourthFamily(''));
        dispatch(searchReducer_setFifthFamily(''));
        break;

      case 3:
        dispatch(searchReducer_setFourthFamilies(setList));

        if (
          document.getElementById('FourthFamily') !== null &&
          document.getElementById('FifthFamily') !== null
        ) {
          document.getElementById('FourthFamily').value = '';
          document.getElementById('FifthFamily').value = '';
        }

        dispatch(searchReducer_setThirdFamily(clickedCategory));
        dispatch(searchReducer_setFourthFamily(''));
        dispatch(searchReducer_setFifthFamily(''));
        break;

      // 여기서부터
      /*
          searchReducer_setFourthFamily,
          searchReducer_setFourthFamilies,
          searchReducer_setFifthFamily,
          searchReducer_setFifthFamilies,
      */
      case 4:
        dispatch(searchReducer_setFifthFamilies(setList));

        document.getElementById('FifthFamily').value = '';

        dispatch(searchReducer_setFourthFamily(clickedCategory));
        dispatch(searchReducer_setFifthFamily(''));
        break;

      case 5:
        dispatch(searchReducer_setFifthFamily(clickedCategory));
        break;

      default:
        return;
    }
  };

  /* 작업상태 선택 */
  const actSelectStatus = (e) => {
    dispatch(searchReducer_setWorkStatus(e.target.value));
  };

  /* 시작일 선택 */
  const actSelectStartDate = (e) => {
    const thisStartDate = document.getElementById('Search_StartDate').value;
    const thisEndDate = document.getElementById('Search_EndDate').value;

    // dispatch(searchReducer_setStartDate(thisStartDate));

    if (thisStartDate > thisEndDate) {
      // 시작날짜 > 종료날짜
      if (thisEndDate === '') {
        dispatch(searchReducer_setStartDate(thisStartDate));
      } else {
        dispatch(searchReducer_setStartDate(thisEndDate));
        dispatch(searchReducer_setEndDate(thisStartDate));
      }
    } else {
      // 시작날짜 < 종료날짜
      dispatch(searchReducer_setEndDate(thisEndDate));
      dispatch(searchReducer_setStartDate(thisStartDate));
    }
  };

  /* 끝일 선택 */
  const actSelectEndDate = (e) => {
    const thisStartDate = document.getElementById('Search_StartDate').value;
    const thisEndDate = document.getElementById('Search_EndDate').value;

    // dispatch(searchReducer_setEndDate(thisEndDate));

    if (thisStartDate > thisEndDate) {
      // 시작날짜 > 종료날짜
      if (thisEndDate === '') {
        dispatch(searchReducer_setStartDate(thisStartDate));
      } else {
        dispatch(searchReducer_setStartDate(thisEndDate));
        dispatch(searchReducer_setEndDate(thisStartDate));
      }
    } else {
      // 시작날짜 < 종료날짜
      dispatch(searchReducer_setEndDate(thisEndDate));
      dispatch(searchReducer_setStartDate(thisStartDate));
    }
  };

  /* 검색옵션 선택 */
  const actSelectSearchOption = (e) => {
    dispatch(searchReducer_setSearchOption(e.target.value));
  };

  /* 검색바 입력 */
  const actInputSearchText = (e) => {
    dispatch(searchReducer_setSearchText(e.target.value));
  };

  /* 네비 영역 펼치기/접기 설정 */
  const setSearchSection = () => {
    const thisSection = document.getElementById('NavSection');
    const thisButton = document.getElementById('NavButton');
    /* 펼치기 */
    if (thisButton.dataset.status === 'close') {
      if (props.nav === 'all') {
        document.getElementById('FamilySection').style.display = '';
        document.getElementById('SearchSection').style.display = '';

        // thisSection.style.height = '196px';
      } else if (props.nav === 'search') {
        document.getElementById('SearchSection').style.display = '';
        // thisSection.style.height = '146px';
      }

      thisButton.dataset.status = 'open';
      thisButton.style.transform = 'rotate(180deg)';
    } else {
      /* 접기 */
      if (props.nav === 'all') {
        document.getElementById('FamilySection').style.display = 'none';
        document.getElementById('SearchSection').style.display = 'none';
      } else if (props.nav === 'search') {
        document.getElementById('SearchSection').style.display = 'none';
      }

      // thisSection.style.height = '96px';

      thisButton.dataset.status = 'close';
      thisButton.style.transform = 'rotate(360deg)';
    }
  };

  /* ====================================================================== #6 */

  return (
    <NavSection id="NavSection" nav={props.nav}>
      {/* 타이틀 섹션 */}
      <TitleSection>
        {/* 컨텐츠 타이틀 */}
        <ContentTitle>{props.thisTitle}</ContentTitle>
        <div className='content-snb'>
          {/* 컨텐츠 버튼 */}
          <ButtonsSection>{props.content}</ButtonsSection>
        <div
          data-status="close"
          onClick={setSearchSection}
        >
          {/* 네비 영역 펼치기/접기 버튼 */}
          {props.nav === '' ? null : (
            <div className='btn-search'>
              <p>검색</p>
              <NavButton
                id="NavButton"
                >
                <NavButtonIcon></NavButtonIcon>
              </NavButton>
            </div>
          )}
        </div>
      </div>
      </TitleSection>
      <div>
        {/* 패밀리 섹션 */}
        {props.nav === '' ? null : props.nav === 'search' ? null : (
          <FamilySection id="FamilySection" style={{ display: 'none' }}>
            {/* 물품타입 */}
            <SelectType
              id="SelectType"
              value={searchReducer.productType || ''}
              onChange={actSelectType}
            >
              <option value="">전체</option>
              {userReducer.elementType.map((thisElementType) => {
                let thisTypeText = '';
                switch (thisElementType) {
                  case 'material':
                    thisTypeText = '자재';
                    break;
                  case 'semi':
                    thisTypeText = '부자재';
                    break;
                  case 'half':
                    thisTypeText = '반제품';
                    break;
                  case 'product':
                    thisTypeText = '완제품';
                    break;
                  case 'tool':
                    thisTypeText = '공구';
                    break;

                  default:
                    break;
                }

                if (thisTypeText !== '') {
                  return (
                    <option
                      key={thisElementType + '_nav'}
                      value={thisElementType}
                    >
                      {thisTypeText}
                    </option>
                  );
                }
              })}
              {/* <option value="material">자재</option>
              <option value="semi">부자재</option>
              <option value="half">반제품</option>
              <option value="product">완제품</option>
              { props.thisTitle === '물품정보' && <option value="tool">공구</option> } */}
            </SelectType>
            <RightArrow style={{ backgroundColor: 'transparent' }} />

            {/* 패밀리1 */}
            <SelectFamily
              id="FirstFamily"
              value={
                searchReducer.firstFamilies.length > 0
                  ? searchReducer.firstFamily
                  : ''
              }
              onChange={showCategories}
            >
              <option value="" disabled>
                패밀리 1
              </option>
              {
                /* searchReducer.productType !== '' && */
                searchReducer.firstFamilies.map((thisFamily) => (
                  <option
                    key={thisFamily.categoryId}
                    value={thisFamily.categoryId}
                  >
                    {thisFamily.categoryName}
                  </option>
                ))
              }
            </SelectFamily>
            <RightArrow />

            {/* 패밀리2 */}
            <SelectFamily
              id="SecondFamily"
              value={
                searchReducer.secondFamily !== '' &&
                searchReducer.secondFamilies.length > 0
                  ? searchReducer.secondFamily
                  : ''
              }
              onChange={showCategories}
            >
              <option value="" disabled>
                패밀리 2
              </option>
              {searchReducer.firstFamily !== '' &&
                searchReducer.secondFamilies.length > 0 &&
                searchReducer.secondFamilies.map((thisFamily) => (
                  <option
                    key={thisFamily.categoryId}
                    value={thisFamily.categoryId}
                  >
                    {thisFamily.categoryName}
                  </option>
                ))}
            </SelectFamily>
            <RightArrow />

            {/* 패밀리3 */}
            <SelectFamily
              id="ThirdFamily"
              value={
                searchReducer.thirdFamily !== '' &&
                searchReducer.thirdFamilies.length > 0
                  ? searchReducer.thirdFamily
                  : ''
              }
              onChange={showCategories}
            >
              <option value="" disabled>
                패밀리 3
              </option>
              {searchReducer.secondFamily !== '' &&
                searchReducer.thirdFamilies.length > 0 &&
                searchReducer.thirdFamilies.map((thisFamily) => (
                  <option
                    key={thisFamily.categoryId}
                    value={thisFamily.categoryId}
                  >
                    {thisFamily.categoryName}
                  </option>
                ))}
            </SelectFamily>

            {userReducer.company.companyId === 12 ? (
              <>
                <RightArrow />
                <SelectFamily
                  id="FourthFamily"
                  value={
                    searchReducer.fourthFamily !== '' &&
                    searchReducer.fourthFamilies.length > 0
                      ? searchReducer.fourthFamily
                      : ''
                  }
                  onChange={showCategories}
                >
                  <option value="" disabled>
                    패밀리 4
                  </option>
                  {searchReducer.secondFamily !== '' &&
                    searchReducer.fourthFamilies.length > 0 &&
                    searchReducer.fourthFamilies.map((thisFamily) => (
                      <option
                        key={thisFamily.categoryId}
                        value={thisFamily.categoryId}
                      >
                        {thisFamily.categoryName}
                      </option>
                    ))}
                </SelectFamily>
                <RightArrow />
                <SelectFamily
                  id="FifthFamily"
                  value={
                    searchReducer.fifthFamily !== '' &&
                    searchReducer.fifthFamilies.length > 0
                      ? searchReducer.fifthFamily
                      : ''
                  }
                  onChange={showCategories}
                >
                  <option value="" disabled>
                    패밀리 5
                  </option>
                  {searchReducer.Family !== '' &&
                    searchReducer.fifthFamilies.length > 0 &&
                    searchReducer.fifthFamilies.map((thisFamily) => (
                      <option
                        key={thisFamily.categoryId}
                        value={thisFamily.categoryId}
                      >
                        {thisFamily.categoryName}
                      </option>
                    ))}
                </SelectFamily>
              </>
            ) : (
              <></>
            )}
          </FamilySection>
        )}
        {/* 검색 섹션 */}
        {props.nav === '' ? null : (
          <SearchSection id="SearchSection" style={{ display: 'none' }}>
            {/* 작업 상태 */}
            {props.search.split('/').indexOf('status') === -1 ? null : (
              <SearchBox data-section="status">
                <SelectStatus
                  id="Search_WorkStatus"
                  value={searchReducer.workStatus || ''}
                  onChange={actSelectStatus}
                >
                  <option value="" disabled>
                    상태선택
                  </option>
                  {props.statusOptions !== '' &&
                    props.statusOptions.map((thisOption) => (
                      <option
                        key={thisOption.optionValue}
                        value={thisOption.optionValue}
                      >
                        {thisOption.optionText}
                      </option>
                    ))}
                </SelectStatus>
              </SearchBox>
            )}

            {/* 기간 */}
            {props.search.split('/').indexOf('date') === -1 ? null : (
              <SearchBox data-section="date">
                <SelectDate
                  id="Search_StartDate"
                  type="date"
                  value={searchReducer.startDate || ''}
                  onChange={actSelectStartDate}
                ></SelectDate>
                <RightArrow />
                <SelectDate
                  id="Search_EndDate"
                  type="date"
                  value={searchReducer.endDate || ''}
                  onChange={actSelectEndDate}
                ></SelectDate>
              </SearchBox>
            )}

            {/* 텍스트 */}
            <SearchBox>
              {/* 검색 옵션 */}
              <SearchOption
                id="Search_Option"
                value={searchReducer.searchOption || ''}
                onChange={actSelectSearchOption}
              >
                <option value="" disabled>
                  검색옵션
                </option>
                {props.searchOptions !== '' &&
                  props.searchOptions.map((thisOption) => (
                    <option
                      key={thisOption.optionValue}
                      value={thisOption.optionValue}
                    >
                      {thisOption.optionText}
                    </option>
                  ))}
              </SearchOption>

              {/* 검색 바 */}
              <SearchBar
                type="text"
                value={searchReducer.searchText || ''}
                placeholder="Search..."
                onInput={actInputSearchText}
              ></SearchBar>

              {/* 검색 버튼 */}
              {props.searchButton}

              <ResetButton
                onClick={() => {
                  dispatch(searchReducer_reset());
                }}
              >
                초기화
              </ResetButton>
            </SearchBox>
          </SearchSection>
        )}
      </div>


    </NavSection>
  );
};

export default Nav;
