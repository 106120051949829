import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { getCookie, removeCookie } from 'preferences/cookie/cookie';

import { clusterActions_reset } from 'store/modules/actions/cluster/clusterActions';

import { FaRegUser } from "react-icons/fa6";
import { FiLogIn, FiLogOut } from "react-icons/fi";
import { OperatingHeaderComponent } from '../styles/header.style';

const OperatingHeader = () => {
  const location = useLocation();
  const operatingId = location.pathname.split('/platform/operating')[0];
  const operatingPath = operatingId + '/platform/operating';

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLink = (type) => { // 페이지 이동
    switch (type) {
      case 'home': return navigate(`${operatingPath}`);
      case 'mypage': return navigate(`${operatingPath}/mypage`);
      case 'login': return navigate(`${operatingPath}/sign/login`);

      default: return;
    }
  };
  const actLogOut = (e) => { // 로그아웃
    e.preventDefault();
    console.log('================================= removeCookie');
    removeCookie('accessToken');
    removeCookie('refreshToken');
    dispatch(clusterActions_reset());
    navigate(`${operatingPath}`/* , { replace: true } */);
  }

  return (<>
    <OperatingHeaderComponent>
      <div>
        <div></div>
        <div>
          {getCookie('accessToken') ? (<>
            <button onClick={(e) => { e.preventDefault(); handleLink('mypage'); }}><FaRegUser />마이페이지</button>
            <button onClick={actLogOut}><FiLogOut />로그아웃</button>
          </>) : (
            <button onClick={(e) => { e.preventDefault(); handleLink('login'); }}><FiLogIn />로그인</button>
          )}
        </div>
      </div>
    </OperatingHeaderComponent>
  </>);
};

export default OperatingHeader;