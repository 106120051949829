import { Outlet } from "react-router-dom";

import { ClusterMyPageComponent } from "./MyPage.style";
import ClusterHeader from "platform/cluster/components/ClusterHeader";
import ClusterSideBar from "platform/cluster/components/ClusterSideBar";

export default function MyPage({ children }) {
  return (
    <ClusterMyPageComponent>
      <ClusterHeader />
      <section className="main">
        <ClusterSideBar />
        <Outlet {...children} />
      </section>
    </ClusterMyPageComponent>
  );
}
