export const productionHistoryActions_setSearch = (content) => {
  return {
    type: 'SETPRODUCTIONHISTORYSEARCH',
    payload: content,
  };
};

export const productionHistoryActions_setCurrentPageNum = (content) => {
  return {
    type: 'SETPRODUCTIONHISTORYCURRENTPAGENUM',
    payload: content,
  };
};

export const productionHistoryActions_setTotalSize = (content) => {
  return {
    type: 'SETPRODUCTIONHISTORYTOTALSIZE',
    payload: content,
  };
};

export const productionHistoryActions_setReset = () => {
  return {
    type: 'SETPRODUCTIONHISTORYRESET',
  };
};
