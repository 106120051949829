import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import AccountAttr from 'pages/attributes/attrs/AccountAttr';
import ContractAttr from 'pages/attributes/attrs/ContractAttr';
import ElementAttr from 'pages/attributes/attrs/ElementAttr';
import GsElementAttr from 'pages/attributes/attrs/GsElementAttr';
import EquipmentAttr from 'pages/attributes/attrs/EquipmentAttr';
import Grid2Body from 'components/layouts/body/Grid2Body';

const AttributeManagementSection = styled.main`
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 40px calc(100% - 56px);
  padding: 16px;
  row-gap: 16px;
  width: 100%;

  & .attr-btns {
    align-items: center;
    column-gap: 10px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
`;

const AttributeManagement = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const { userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_authority, setAuthority] = useState([]);

  const [_activeAttr, setActiveAttr] = useState('000');

  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('101') === true || // 물품정보
      thisItem.authority.startsWith('103') === true || // 항목
      thisItem.authority.startsWith('104') === true || // 거래처
      thisItem.authority.startsWith('108') === true || // 설비
      thisItem.authority.startsWith('201') === true, // 수주
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);
    setActiveAttr(authorityCodes[0]);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));
  }, []);

  useEffect(() => { }, [_activeAttr]);

  /* ========================================================================= #4 */
  const checkAuthority = (active) => {
    let activeCode;
    switch (active) {
      case 'element': activeCode = '101'; break;
      case 'account': activeCode = '104'; break;
      case 'equipment': activeCode = '108'; break;
      case 'contract': activeCode = '201'; break;

      default: return;
    }

    const findActiveCode = (authority, code) => {
      if (authority.startsWith(code)) return true;
      else return false;
    }
    if (_authority.find((authority) => findActiveCode(authority, activeCode)) !== undefined) {
      return true;
    }
  };

  /* ========================================================================= #5 */
  /* ========================================================================= #6 */

  return (
    <Grid2Body contents={
      <>
        <AttributeManagementSection className="Main">
          <div className='attr-btns'>
            {checkAuthority('element') ? (
              <button className='btn-set' onClick={() => { setActiveAttr('101'); }}>
                물품 항목 설정
              </button>
            ) : null}
            {checkAuthority('account') ? (
              <button className='btn-set' onClick={() => { setActiveAttr('104'); }}>
                거래처 항목 설정
              </button>
            ) : null}
            {checkAuthority('equipment') ? (
              <button className='btn-set' onClick={() => { setActiveAttr('108'); }}>
                설비 항목 설정
              </button>
            ) : null}
            {checkAuthority('contract') ? (
              <button className='btn-set' onClick={() => { setActiveAttr('201'); }}>
                수주 항목 설정
              </button>
            ) : null}
          </div>

          {_activeAttr.startsWith('101') ? (
            <>{userReducer.company.companyId !== 3 ? (<ElementAttr authority={_authority} />) : (<GsElementAttr authority={_authority} />)}</>
          ) : null}
          {_activeAttr.startsWith('104') ? (<AccountAttr authority={_authority} />) : null}
          {_activeAttr.startsWith('108') ? (<EquipmentAttr authority={_authority} />) : null}
          {_activeAttr.startsWith('201') ? (<ContractAttr authority={_authority} />) : null}
        </AttributeManagementSection>
      </>
    }
    />
  );
};

export default AttributeManagement;
