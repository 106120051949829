import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { ACCESS_TOKEN, AXIOS_BASE_URL } from 'preferences/server/constants';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import Nav from 'components/nav/Nav';
import { getCookie } from 'preferences/cookie/cookie';

const DeleteUriProductionOrder = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { contentsReducer, pageReducer, userReducer } = useSelector(
    (state) => state,
  );

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_customizedContent, setCustomizedcontent] = useState({});
  console.log(_customizedContent);

  /* ====================================================================== #3 */
  useEffect(() => {
    if (userReducer.company.companyId !== 1) {
      setCustomizedcontent({
        ...JSON.parse(
          contentsReducer.deleteContents.workOrderCustomizedContent,
        ),
      });
    }

    setOnload('loaded');

    return () => {};
  }, []);

  /* ====================================================================== #4 */
  const delDrawings = async () => {
    console.log(contentsReducer.deleteContents);
    const delDrawingList = contentsReducer.deleteContents.processLogList.map(
      (thisItem) => thisItem.drawingList,
    );
    console.log(delDrawingList);

    delDrawingList.forEach(async (thisDrawings) => {
      console.log(thisDrawings);
      thisDrawings.forEach(async (thisDrawing) => {
        console.log(thisDrawing);
        await axios
          .delete(AXIOS_BASE_URL + '/drawing/' + thisDrawing.drawingId, {
            headers: {
              Authorization: getCookie('accessToken'),
            },
          })
          .then((response) => {
            if (response === undefined) return;
            console.log('delete/drawing - response : ', response);
          })
          .catch((error) => {
            console.log('delete/drawing - error : ', error);
          });
      });
    });
  };

  const delAllDrawings = async (thisWorkOrder) => {
    console.log(thisWorkOrder);
    const delDrawingList = thisWorkOrder.processLogList.map(
      (thisItem) => thisItem.drawingList,
    );
    console.log(delDrawingList);

    delDrawingList.forEach(async (thisDrawings) => {
      console.log(thisDrawings);
      thisDrawings.forEach(async (thisDrawing) => {
        console.log(thisDrawing);
        await axios
          .delete(AXIOS_BASE_URL + '/drawing/' + thisDrawing.drawingId, {
            headers: {
              Authorization: getCookie('accessToken'),
            },
          })
          .then((response) => {
            if (response === undefined) return;
            console.log('delete/drawing - response : ', response);
          })
          .catch((error) => {
            console.log('delete/drawing - error : ', error);
          });
      });
    });
  };

  /* ====================================================================== #5 */
  const actDelete = async () => {
    // await delDrawings();
    await axios
      .delete(
        AXIOS_BASE_URL +
          '/workOrderLog/' +
          contentsReducer.deleteContents.workOrderLogId,
        {
          headers: {
            Authorization: getCookie('accessToken'),
          },
        },
      )
      .then((response) => {
        if (response === undefined) return;
        console.log(response);
        if (response.status === 200) {
          alert('작업지시를 삭제했습니다.');
          navigate(pageReducer.currentPage, { replace: true });
        }
      })
      .catch((error) => {
        console.log('deleteElement-error : ', error);
      });
  };

  const allDelete = async () => {
    let count = 0;
    contentsReducer.deleteContents.forEach(async (thisItem) => {
      await axios
        .delete(AXIOS_BASE_URL + '/workOrderLog/' + thisItem.workOrderLogId, {
          headers: {
            Authorization: getCookie('accessToken'),
          },
        })
        .then((response) => {
          if (response === undefined) return;
          console.log('delete/workOrderLog - response :', response);
        })
        .catch((error) => {
          console.log('delete/workOrderLog - error :', error);
        });

      count++;
      if (count === contentsReducer.deleteContents.length) {
        alert('작업지시를 삭제했습니다.');
        navigate(pageReducer.currentPage, { replace: true });
      }
    });
  };

  /* ====================================================================== #6 */

  return (
    <Grid2Body
      contents={
        <>
          <main className="Main">
            <Nav
              thisTitle={'작업지시'}
              content={''}
              nav={''}
              search={''}
              searchButton={''}
              searchOptions={''}
              statusOptions={''}
            />

            <DeleteForm
              delteTitle={'작업지시'}
              deleteItem={
                userReducer.company.companyId === 1 ? (
                  <>
                    {contentsReducer.deleteContents.map((thisItem) => {
                      const thisCustomizedContent = JSON.parse(
                        thisItem.workOrderCustomizedContent,
                      );
                      return (
                        <Fragment key={thisItem.workOrderLogId}>
                          <p>거래처 : {thisItem.accountName}</p>
                          <p>현장명 : {thisItem.detailedWorkPlace}</p>
                          <p>
                            품목 : {thisCustomizedContent.workOrderProducts}
                          </p>
                          <p>색상 : {thisCustomizedContent.colors}</p>
                          <p>작성자 : {thisItem.userName}</p>
                          <br />
                        </Fragment>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <p>거래처 : {contentsReducer.deleteContents.accountName}</p>
                    <p>
                      현장명 :{' '}
                      {contentsReducer.deleteContents.detailedWorkPlace}
                    </p>

                    <p>품목 : {_customizedContent.workOrderProducts}</p>
                    <p>색상 : {_customizedContent.colors}</p>
                    <br />

                    <p>작성자 : {contentsReducer.deleteContents.userName}</p>
                  </>
                )
              }
              deleteText={<>작업지시를 삭제합니다.</>}
              deleteButton={
                <>
                  <button
                    className="formButton cancle"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                  <button
                    className="formButton delete"
                    onClick={
                      userReducer.company.companyId === 1
                        ? allDelete
                        : actDelete
                    }
                  >
                    삭제
                  </button>
                </>
              }
            />
          </main>
        </>
      }
    />
  );
};

export default DeleteUriProductionOrder;
