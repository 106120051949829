import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { jointPurchase, reciprocalTransaction } from 'api/apis/platform/cluster/cluster';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import ClusterContentModal from './modal/ContentModal';

const CommonBoard = () => {
  const location = useLocation();
  const clusterId = location.pathname.split('/platform/cluster')[0];
  const clusterPath = clusterId + '/platform/cluster';

  const { clusterReducer } = useSelector(state => state);
  const { user } = clusterReducer;

  const [_jointPurchase, setJointPurchase] = useState([]);
  const [_reciprocalTransaction, setReciprocalTransaction] = useState([]);

  const [_modalType, setModalType] = useState();
  const [_modalData, setModalData] = useState();
  const [_detailStatus, setDetailStatus] = useState(false); // 상세 보기

  useEffect(() => {
    getDataList();
    return () => { }
  }, []);
  useEffect(() => { }, [_jointPurchase, _reciprocalTransaction]);

  const getDataList = async () => { // 데이터 불러오기
    const id = Number(clusterId.replace('/', ''));
    const body = { clusterId: id };
    await jointPurchase.search('', body).then(response => {
      if (response === undefined) return;
      console.log('purchase : ', response);
      setJointPurchase(() => { return response.data.content });
    })
    await reciprocalTransaction.search('', body).then(response => {
      if (response === undefined) return;
      console.log('sales : ', response);
      setReciprocalTransaction(() => { return response.data.content });
    })
  };

  const goDetail = (type, data) => {
    setModalType(() => { return type });
    setModalData(() => { return data || {} });
    setTimeout(setDetailStatus(() => { return true }), 1000);
  }

  return (<>
    <section>
      {(!checkNullArray(_jointPurchase, false) && !checkNullArray(_reciprocalTransaction, false)) ? (<div className='board-content'>게시글이 없습니다.</div>) : (<>
        {checkNullArray([..._jointPurchase, ..._reciprocalTransaction], []).map((data, index) => {
          return (
            <div
              key={index + '_content'}
              className='board-content'
              onClick={() => {
                let type = '';
                if (checkEmptyNull(data.jointPurchaseId, false)) type = 'purchase-detailComment';
                if (checkEmptyNull(data.reciprocalTransactionId, false)) type = 'sales-detailComment';
                goDetail(type, data);
              }}
            >
              <p><span>{checkEmptyNull(data.type) ? checkEmptyNull(data.type) === 'buy' ? '[구매] ' : '[판매] ' : '[공동 구매] '}</span>{data.title}</p>
              <p>~ {DateFormat(data.targetDate)}</p>
            </div>
          );
        })}
      </>)}
    </section>

    {_detailStatus ? (
      <ClusterContentModal
        modalType={_modalType}
        modalData={_modalData}
        update={getDataList}
        open={_detailStatus}
        close={() => { setDetailStatus(() => { return false }); }}
      />
    ) : null}
  </>);
};

export default CommonBoard;