import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';

import CommaNum from 'components/format/CommaNum';
import GuideText from 'components/buttons/GuideText';
import { checkEmptyNull, checkNullObject } from 'components/checkValues/checkValues';

const CreateButton = styled.td`
  background-color: var(--MainBlue);
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  padding: 5px 0px;
  vertical-align: middle;
`;

const ProcessTable = styled.table`
  background-color: var(--Bg);
  border-collapse: collapse;
  min-width: 100%;
  table-layout: fixed;

  td, th {
    box-sizing: border-box;
    padding: 5px 15px;
    min-width: 120px !important;
    text-align: center;
    white-space: normal;
    word-break: break-all;
    font-size: 14px;
  }
  td {}
  td.blankCell {
    background-color: var(--Bg);
    opacity: 0.8;
  }
`;

const RelatedWorkOrderRow = (props) => {
  /* ====================================================================== #1 */
  /* ====================================================================== #2 */
  const [WorkOrderData, setWorkOrderData] = useState(props.content);
  const [ProcessData, setProcessData] = useState(WorkOrderData.workOrderProcessList);
  const [_processLength, setProcessLength] = useState(() => { return ProcessData.length; });

  const [_availableAmount, setAvailableAmount] = useState(0);

  /* ====================================================================== #3 */
  useEffect(() => {
    setWorkOrderData(() => { return props.content; });
    setProcessData(() => { return props.content.workOrderProcessList; });
    setProcessLength(() => { return props.content.workOrderProcessList.length; });

    const processLogElementList = props.content.workOrderProcessList.map((thisItem) => thisItem.process.processElementList);
    const setProcessLogElementList = processLogElementList.flat();
    // actCalMinAmount(Result.amount, setProcessLogElementList);
    actCalMinAmount(setProcessLogElementList);
  }, [props]);

  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const actCalMinAmount = (elementList) => {
    const calMinAmount = elementList.map((thisItem) => {
      if (thisItem === undefined || thisItem === null ||
        thisItem.processElementType === 'Out' || thisItem.processElementType === 'Make' ||
        thisItem.processLogElementType === 'Out' || thisItem.processLogElementType === 'Make'
      ) return null;

      const realAmount = BigNumber(thisItem.elementStock).minus(BigNumber(thisItem.scheduledInputStock)).toNumber();
      const putAmount = BigNumber(1).multipliedBy(BigNumber(thisItem.amount)).toNumber();
      const result = BigNumber(isNaN(realAmount) ? 0 : realAmount).dividedBy(BigNumber(isNaN(putAmount) ? 0 : putAmount)).toNumber();

      return result;
    });
    const minAmount = Math.min.apply(null, calMinAmount.filter((thisItem) => thisItem !== null));
    setAvailableAmount(() => { return !isNaN(Math.floor(minAmount)) && Math.floor(minAmount) !== Infinity && Math.floor(minAmount) > 0 ? Math.floor(minAmount) : 0; });
  };

  /* ====================================================================== #6 */
  const returnItems = () => {
    let returnArray = [];
    for (let i = 1; i < _processLength; i++) {
      returnArray.push(
        <tr key={i + '_processRows'}>
          {ProcessData[i] !== undefined && ProcessData[i].process !== undefined && ProcessData[i].process !== null && ProcessData[i].process ? (
            <Fragment>
              <td className={ProcessData[i].target && 'targetCell'} style={{ borderLeft: '1px solid var(--gray-200)', minWidth: '80px', width: '80px' }}>{ProcessData[i].processNumber}</td>
              <td>{ProcessData[i].process.processTypeName}</td>
              <td>{ProcessData[i].process.processCode}</td>
              <td>{ProcessData[i].process.processName}</td>
              <td>{ProcessData[i].process.place?.placeName}</td>
              <td>{ProcessData[i].process.remark}</td>
              <td style={{ backgroundColor: 'var(--Bg)', borderLeft: '1px solid var(--gray-200)', padding: 'unset', verticalAlign: 'top' }}>
                <ProcessTable>
                  <tbody>
                    {(() => {
                      const ProcessMakeItems = ProcessData[i].process.processElementList.filter((thisEl) => thisEl.processElementType === 'Out' || thisEl.processElementType === 'Make');
                      return ProcessMakeItems.map((thisEl) => {
                        return (
                          <tr key={thisEl.processElementId + '_makeItem'}
                            style={{ backgroundColor: thisEl.elementId === Number(props.elementId) ? 'rgba(52, 175, 56, 0.8)' : null, color: thisEl.elementId === Number(props.elementId) ? 'var(--white)' : null }}
                          >
                            <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementName, '') : checkEmptyNull(thisEl.elementName, '')}</td>
                            <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementCode, '') : checkEmptyNull(thisEl.elementCode, '')}</td>
                            <td>{checkEmptyNull(thisEl.amount, 0).toLocaleString()}</td>
                            <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.scheduledInputStock, 0).toLocaleString() : checkEmptyNull(thisEl.scheduledInputStock, 0).toLocaleString()}</td>
                            <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementStock, 0).toLocaleString() : checkEmptyNull(thisEl.elementStock, 0).toLocaleString()}</td>
                          </tr>
                        );
                      });
                    })()}
                  </tbody>
                </ProcessTable>
              </td>
              <td style={{ backgroundColor: 'var(--Bg)', borderLeft: '1px solid var(--gray-200)', padding: 'unset', verticalAlign: 'top' }}>
                <ProcessTable>
                  <tbody>
                    {(() => {
                      const ProcessPutItems = ProcessData[i].process.processElementList.filter((thisEl) => thisEl.processElementType === 'In' || thisEl.processElementType === 'Put');
                      return ProcessPutItems.map((thisEl) => {
                        return (
                          <tr key={thisEl.processElementId + '_putItem'}
                            style={{ backgroundColor: thisEl.elementId === Number(props.elementId) ? 'rgba(52, 175, 56, 0.8)' : null, color: thisEl.elementId === Number(props.elementId) ? 'var(--white)' : null }}
                          >
                            <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementName, '') : checkEmptyNull(thisEl.elementName, '')}</td>
                            <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementCode, '') : checkEmptyNull(thisEl.elementCode, '')}</td>
                            <td>{checkEmptyNull(thisEl.amount, 0).toLocaleString()}</td>
                            <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.scheduledInputStock, 0).toLocaleString() : checkEmptyNull(thisEl.scheduledInputStock, 0).toLocaleString()}</td>
                            <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementStock, 0).toLocaleString() : checkEmptyNull(thisEl.elementStock, 0).toLocaleString()}</td>
                          </tr>
                        );
                      });
                    })()}
                  </tbody>
                </ProcessTable>
              </td>
            </Fragment>
          ) : (
            <Fragment>
              <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
            </Fragment>
          )}
        </tr>,
      );
    }

    return returnArray;
  };

  return (
    <Fragment>
      <tr key={WorkOrderData.workOrderId} name={'workOrder'} onClick={(e) => { props.goDetails(WorkOrderData); }}>
        <Fragment>
          {props.authority.indexOf('111-2') !== -1 ? (
            <CreateButton
              rowSpan={_processLength === 0 ? 1 : _processLength}
              style={{ height: '100px', minWidth: '100px', padding: '4px', width: '100px' }}
              onClick={(e) => { e.stopPropagation(); props.createWorkOrderLog(WorkOrderData); }}
            >
              작업지시<br />등록
              <GuideText />
            </CreateButton>
          ) : null}
        </Fragment>
        <td rowSpan={_processLength === 0 ? 1 : _processLength}>{WorkOrderData.workOrderTypeName}</td>
        <td rowSpan={_processLength === 0 ? 1 : _processLength}>{WorkOrderData.workOrderCode}</td>
        <td rowSpan={_processLength === 0 ? 1 : _processLength}>{WorkOrderData.workOrderName}</td>
        <td rowSpan={_processLength === 0 ? 1 : _processLength}>{checkEmptyNull(_availableAmount, 0).toLocaleString()}</td>

        {ProcessData[0] !== undefined && ProcessData[0].process !== undefined && ProcessData[0].process !== null && ProcessData[0].process ? (
          <Fragment>
            <td className={ProcessData[0].target && 'targetCell'} style={{ borderLeft: '1px solid var(--gray-200)', minWidth: '80px', width: '80px' }}>
              {ProcessData[0].processNumber}
            </td>
            <td>{ProcessData[0].process.processTypeName}</td>
            <td>{ProcessData[0].process.processCode}</td>
            <td>{ProcessData[0].process.processName}</td>
            <td>{ProcessData[0].process.place?.placeName}</td>
            <td>{ProcessData[0].process.remark}</td>
            <td style={{ backgroundColor: 'var(--Bg)', borderLeft: '1px solid var(--gray-200)', padding: 'unset', verticalAlign: 'top' }}>
              <ProcessTable>
                <tbody>
                  {(() => {
                    const ProcessMakeItems = ProcessData[0].process.processElementList.filter((thisEl) => thisEl.processElementType === 'Out' || thisEl.processElementType === 'Make');
                    return ProcessMakeItems.map((thisEl) => {
                      return (
                        <tr key={thisEl.processElementId + '_makeItem'}
                          style={{ backgroundColor: thisEl.elementId === Number(props.elementId) ? 'rgba(52, 175, 56, 0.8)' : null, color: thisEl.elementId === Number(props.elementId) ? 'var(--white)' : null }}
                        >
                          <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementName, '') : checkEmptyNull(thisEl.elementName, '')}</td>
                          <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementCode, '') : checkEmptyNull(thisEl.elementCode, '')}</td>
                          <td>{checkEmptyNull(thisEl.amount, 0).toLocaleString()}</td>
                          <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.scheduledInputStock, 0).toLocaleString() : checkEmptyNull(thisEl.scheduledInputStock, 0).toLocaleString()}</td>
                          <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementStock, 0).toLocaleString() : checkEmptyNull(thisEl.elementStock, 0).toLocaleString()}</td>
                        </tr>
                      );
                    });
                  })()}
                </tbody>
              </ProcessTable>
            </td>
            <td style={{ ackgroundColor: 'var(--Bg)', borderLeft: '1px solid var(--gray-200)', padding: 'unset', verticalAlign: 'top' }}>
              <ProcessTable>
                <tbody>
                  {(() => {
                    const ProcessPutItems = ProcessData[0].process.processElementList.filter((thisEl) => thisEl.processElementType === 'In' || thisEl.processElementType === 'Put');
                    return ProcessPutItems.map((thisEl) => {
                      return (
                        <tr key={thisEl.processElementId + '_putItem'}
                          style={{ backgroundColor: thisEl.elementId === Number(props.elementId) ? 'rgba(52, 175, 56, 0.8)' : null, color: thisEl.elementId === Number(props.elementId) ? 'var(--white)' : null }}
                        >
                          <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementName, '') : checkEmptyNull(thisEl.elementName, '')}</td>
                          <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementCode, '') : checkEmptyNull(thisEl.elementCode, '')}</td>
                          <td>{checkEmptyNull(thisEl.amount, 0).toLocaleString()}</td>
                          <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.scheduledInputStock, 0).toLocaleString() : checkEmptyNull(thisEl.scheduledInputStock, 0).toLocaleString()}</td>
                          <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementStock, 0).toLocaleString() : checkEmptyNull(thisEl.elementStock, 0).toLocaleString()}</td>
                        </tr>
                      );
                    });
                  })()}
                </tbody>
              </ProcessTable>
            </td>
          </Fragment>
        ) : (
          <Fragment>
            <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
          </Fragment>
        )}
      </tr>
      {_processLength > 1 && returnItems()}
    </Fragment>
  );
};

export default RelatedWorkOrderRow;
