import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import AccountType from 'pages/types/type/AccountType';
import ContractType from 'pages/types/type/ContractType';
import ProcessType from 'pages/types/type/ProcessType';
import WorkOrderType from 'pages/types/type/WorkOrderType';
import ShipmentInspectionItemType from 'pages/types/type/ShipmentInspectionItemType';
import StockPlace from 'pages/types/type/StockPlace';
import WorkPlace from 'pages/types/type/WorkPlace';

import Grid2Body from 'components/layouts/body/Grid2Body';

const TypeManagementSection = styled.main`
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 40px calc(100% - 56px);
  padding: 16px;
  row-gap: 16px;
  width: 100%;

  & .attr-btns {
    align-items: center;
    column-gap: 10px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
`;

const TypeManagement = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const { userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_authority, setAuthority] = useState([]);

  const [_activeType, setActiveType] = useState('000');

  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter(
      (thisItem) =>
        thisItem.authority.startsWith('104') === true || // 거래처
        thisItem.authority.startsWith('201') === true || // 수주
        thisItem.authority.startsWith('112') === true || // 공정
        thisItem.authority.startsWith('111') === true || // 작업
        thisItem.authority.startsWith('113') === true || // 시험성적서 정의
        thisItem.authority.startsWith('301') === true || // 현재고
        thisItem.authority.startsWith('403') === true, // 작업지시
    );
    const authorityCodes = authorityContent.map(
      (thisItem) => thisItem.authority,
    );
    setAuthority(authorityCodes);
    setActiveType(authorityCodes[0]);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));
  }, []);

  /* ========================================================================= #4 */
  const checkAuthority = (active) => {
    let activeCode;
    switch (active) {
      case 'account':
        activeCode = '104';
        break;
      case 'contract':
        activeCode = '201';
        break;
      case 'process':
        activeCode = '112';
        break;
      case 'workOrder':
        activeCode = '111';
        break;
      case 'shipmentInspection':
        activeCode = '113';
        break;
      case 'stockPlace':
        activeCode = '301';
        break;
      case 'workPlace':
        activeCode = '403';
        break;

      default:
        return;
    }

    if (
      _authority.find((authority) => {
        if (authority.startsWith(activeCode) === true) return true;
        else return false;
      }) !== undefined
    )
      return true;
  };

  /* ========================================================================= #5 */
  /* ========================================================================= #6 */

  return (
    <Grid2Body
      contents={
        <TypeManagementSection className="Main">
          <div className="attr-btns">
            {checkAuthority('account') ? (
              <button
                className="btn-set"
                onClick={() => {
                  setActiveType('104');
                }}
              >
                거래처 유형
              </button>
            ) : null}
            {checkAuthority('contract') ? (
              <button
                className="btn-set"
                onClick={() => {
                  setActiveType('201');
                }}
              >
                수주 유형
              </button>
            ) : null}
            {checkAuthority('process') ? (
              <button
                className="btn-set"
                onClick={() => {
                  setActiveType('112');
                }}
              >
                공정 유형
              </button>
            ) : null}

            {checkAuthority('workOrder') ? (
              <button
                className="btn-set"
                onClick={() => {
                  setActiveType('111');
                }}
              >
                작업 유형
              </button>
            ) : null}

            {checkAuthority('shipmentInspection') ? (
              <button
                className="btn-set"
                onClick={() => {
                  setActiveType('113');
                }}
              >
                점검 / 검사 항목
              </button>
            ) : null}

            {checkAuthority('stockPlace') ? (
              <button
                className="btn-set"
                onClick={() => {
                  setActiveType('301');
                }}
              >
                재고 위치
              </button>
            ) : null}

            {checkAuthority('workPlace') ? (
              <button
                className="btn-set"
                onClick={() => {
                  setActiveType('403');
                }}
              >
                작업 위치
              </button>
            ) : null}
          </div>

          {_activeType.startsWith('104') ? (
            <AccountType authority={_authority} />
          ) : null}
          {_activeType.startsWith('201') ? (
            <ContractType authority={_authority} />
          ) : null}
          {_activeType.startsWith('112') ? (
            <ProcessType authority={_authority} />
          ) : null}
          {_activeType.startsWith('111') ? (
            <WorkOrderType authority={_authority} />
          ) : null}
          {_activeType.startsWith('113') ? (
            <ShipmentInspectionItemType authority={_authority} />
          ) : null}
          {_activeType.startsWith('301') ? (
            <StockPlace authority={_authority} />
          ) : null}
          {_activeType.startsWith('403') ? (
            <WorkPlace authority={_authority} />
          ) : null}
        </TypeManagementSection>
      }
    />
  );
};

export default TypeManagement;
