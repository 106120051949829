export const workActions_setWorkOrderList = (thisWorkOrderList) => {
  return {type: 'SETWORKORDERLIST', payload: thisWorkOrderList}
};

export const workActions_setWorkCase = (thisCase) => {
  return {type: 'SETWORKCASE', payload: thisCase}
};
export const workActions_setWorkStatus = (thisStatus) => {
  return {type: 'SETWORKSTATUS', payload: thisStatus}
};

export const workActions_setWorkContent = (thisContent) => {
  return {type: 'SETWORKCONTENT', payload: thisContent}
};

export const workActions_setConnectStatus = (thisStatus) => {
  return {type: 'SETCONNECTSTATUS', payload: thisStatus}
};
export const workActions_setCurrentMessage = (thisMessage) => {
  return {type: 'SETCURRENTMESSAGE', payload: thisMessage}
};

export const workActions_setWorkOrderType = (workOrderType) => {
  return {type: 'SETWORKINGWORKORDERTYPE', payload: workOrderType};
};
export const workActions_setWorkOrderStatus = (workOrderStatus) => {
  return {type: 'SETWORKINGWORKORDERSTATUS', payload: workOrderStatus};
};
export const workActions_setStartDate = (startDate) => {
  return {type: 'SETWORKINGSTARTDATE', payload: startDate};
};
export const workActions_setEndDate = (endDate) => {
  return {type: 'SETWORKINGENDDATE', payload: endDate};
};
export const workActions_setSearchOption = (searchData) => {
  return {type: 'SETWORKINGSEARCHOPTION', payload: searchData};
};
export const workActions_setSearchText = (searchData) => {
  return {type: 'SETWORKINGSEARCHTEXT', payload: searchData};
};
export const workActions_setSearchData = (searchData) => {
  return {type: 'SETWORKINGSEARCHDATA', payload: searchData};
};
export const workActions_setPageNumber = (pageNumber) => {
  return {type: 'SETWORKINGPAGENUMBER', payload: pageNumber};
};
export const workActions_setTotalSize = (totalSize) => {
  return {type: 'SETWORKINGTOTALSIZE', payload: totalSize};
};
export const workActions_setIsPushedSearchButton = (isPushedSearchButton) => {
  return {type: 'SETWORKINGISPUSHEDSEARCHBUTTON', payload: isPushedSearchButton};
};

export const workActions_setViewScale = (scale) => {
  return {type: 'SETWORKINGVIEWSCALE', payload: scale};
};

export const workActions_setWorker = (status) => {
  return {type: 'SETWORKINGWORKER', payload: status};
};

export const workActions_setReset = () => {
  return {type: 'SETWORKINGRESET'};
};