import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { pageReducer_getCurrentPage, } from 'store/modules/actions/default/pageActions';

import { failedReasonApi } from 'api/apis/failedReasonApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { LoadingMsg } from 'components/loading/LoadingMsg'

import Grid3Body from 'components/layouts/body/Grid3Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const FailedReason = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_failedReasonList, setFailedReasonList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());
  // console.log('_checkedItem : ', _checkedItem);

  const [_searchData, setSearchData] = useState({});
  const [_searchOption, setSearchOption] = useState('');
  const [_searchText, setSearchText] = useState('');

  const [_pageNumber, setPageNumber] = useState(1);
  const [_totalSize, setTotalSize] = useState(0);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('602') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getFailedReasons(_pageNumber - 1);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getFailedReasons(_pageNumber - 1);
    }

    return () => { };
  }, [_pageNumber]);

  /* ====================================================================== #4 */
  const getFailedReasons = async (page, clear) => {
    const paging = `?page=${page}&size=20&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && checkEmptyNull(_searchData.failedReasonType, false)) {
      BodyToPost.failedReasonType = _searchData.failedReasonType;
    }
    if (!clear && checkEmptyNull(_searchData.failedReasonContent, false)) {
      BodyToPost.failedReasonContent = _searchData.failedReasonContent;
    }
    await failedReasonApi.searchFailedReason(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('failedReasonApi.searchFailedReason : ', response);
      setFailedReasonList(() => { return response.data.content; });
      setTotalSize(() => { return response.data.totalElements; });
    })
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(id);
    setCheckedItem(() => { return checkedItem });
  };

  /* 검색 */
  const actSearch = async () => {
    setPageNumber(() => { return 1 });
    await getFailedReasons(0);
  };

  /* 초기화 */
  const actReset = async () => {
    setSearchData(() => { return {}; });
    setSearchOption(() => { return ''; });
    setSearchText(() => { return ''; });

    setPageNumber(() => { return 1 });
    await getFailedReasons(0, true);
  };

  /* 수정 */
  const actUpdate = () => {
    const updateContentIndex = _failedReasonList.findIndex((thisItem) => thisItem.failedReasonId === Array.from(_checkedItem)[0]);
    if (updateContentIndex === -1) return alert('수정할 불량사유를 선택해 주세요.');
    const updateContent = _failedReasonList[updateContentIndex];
    console.log('updateContent : ', updateContent);
    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams(updateContent)}`,
      replace: true,
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const deleteContentIndex = _failedReasonList.findIndex((thisItem) => thisItem.failedReasonId === Array.from(_checkedItem)[0]);
    if (deleteContentIndex === -1) return alert('삭제할 불량사유를 선택해 주세요.');
    const deleteContent = _failedReasonList[deleteContentIndex];
    console.log('deleteContent : ', deleteContent);
    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams(deleteContent)}`,
      replace: true,
    });
  };

  /* ====================================================================== #6 */

  return (
    <Grid3Body contents={
      <>
        <main className="Main">
          <NavBar
            title={<NavTitle menuCode={'602'} />}
            buttons={
              <>
                {_authority.indexOf('602-2') !== -1 ? (
                  <>
                    <button className='btn-add'
                      onClick={() => { navigate(pageReducer.currentPage + '/create', { replace: true }); }}
                    >
                      추가
                    </button>
                    <button className='btn-edit' onClick={actUpdate}>수정</button>
                    <button className='btn-delete' onClick={actDelete}>삭제</button>
                  </>
                ) : null}
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="SearchSection">
                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="failedReasonType">불량유형</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkEmptyNull(_searchData.failedReasonType, '')}
                      onInput={(e) => { setSearchData((prev) => { return { ...prev, failedReasonType: e.target.value } }) }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="failedReasonContent">불량사유</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkEmptyNull(_searchData.failedReasonContent, '')}
                      onInput={(e) => { setSearchData((prev) => { return { ...prev, failedReasonContent: e.target.value } }) }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton" onClick={actReset}>초기화</div>
                  </div>
                </div>
              </>
            }
          />

          <TableSection content={
            <table>
              <thead>
                <tr>
                  {_authority.indexOf('602-2') !== -1 ? (<th style={{ minWidth: 'unset', width: '50px' }}></th>) : null}
                  <th>불량 유형</th>
                  <th>불량 사유</th>
                </tr>
              </thead>
              <tbody>
                {!checkNullArray(_failedReasonList, false) ? LoadingMsg() :
                  checkNullArray(_failedReasonList, []).map((failedReason, index) => {
                    return (
                      <tr key={index + '_failedReason'}>
                        {_authority.indexOf('602-2') !== -1 ? (
                          <td style={{ minWidth: 'unset', width: '50px' }}>
                            <input
                              type="checkBox"
                              name="failedReasons"
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                handleCheckedItem(failedReason.failedReasonId, isChecked);
                                if (isChecked) {
                                  document.getElementsByName('failedReasons').forEach((thisAttr) => { thisAttr.checked = false; });
                                  e.target.checked = true;
                                } else {
                                  e.target.checked = false;
                                }
                              }}
                              onClick={(e) => { e.stopPropagation(); }}
                            />
                          </td>
                        ) : null}
                        <td>{failedReason.failedReasonType}</td>
                        <td>{failedReason.failedReasonContent}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          }
          ></TableSection>
        </main>

        <PagingComponent
          page={_pageNumber}
          count={_totalSize}
          size={20}
          pageEvent={(page) => {
            console.log('page : ', page);
            setPageNumber(() => { return page });
          }}
        />
      </>
    }
    />
  );
};

export default FailedReason;
