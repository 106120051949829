import React, { useState } from 'react';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 85%;
  justify-content: flex-start;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;
`;

const PutElementList = (props) => {
  const [_elementList, setElementList] = useState(checkNullArray(props.content, []));

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>투입 자재/반제품</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <TableSection content={
                <table>
                  <thead>
                    <tr>
                      <th>물품이름</th>
                      <th>물품코드</th>
                      <th>투입량</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_elementList.map((element, index) => {
                      return (
                        <tr key={index + '_element'}>
                          <td>{element.elementName}</td>
                          <td>{element.elementCode}</td>
                          <td>{checkEmptyNull(element.amount, 0)?.toLocaleString()}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              } />
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default PutElementList;
