// 이메일 체크
const checkEmail = (email) => {
  const reg = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
  if (!reg.test(email)) return false; // 불통
  else return true; // 통과
};

// 비밀번호 체크
const checkPw = (pw, confirmPw) => {
  const reg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
  if (!reg.test(pw)) return false; // 불통
  else if (pw !== confirmPw) return false; // 불통
  else return true; // 통과
};

export { checkEmail, checkPw };