import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { removeCookie } from 'preferences/cookie/cookie';

import { mallSiteUserActions_setUser } from 'store/modules/actions/mallSite/mallSiteUserActions';
import { menuActions_setCompanySubMenu, menuActions_setCompanyTopMenu } from 'store/modules/actions/user/menuActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { userReducer_reset, userReducer_setUserMenu } from 'store/modules/actions/user/userActions';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

import { IfisVersion } from 'components/version/ifisVersion';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';

/* =================================================================================================================== BoardMenu */
const Menu = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 10px 15px -3px, rgba(255, 255, 255, 0.1) 0px 4px 6px -2px;
  box-sizing: border-box;
  color: var(--MainNavy);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 30px;
  font-weight: 500;
  height: 180px;
  justify-content: center;

  &:hover {box-shadow: unset; transform: translate(2px, 2px);}
`;
const MenuLine = styled.div`
  background-color: var(--MainNavy);
  height: 3px;
  margin-bottom: 20px;
  width: 20px;
`;

const BoardMenu = (props) => {
  return (<Link to={props.boardmenu.pageUrl}><Menu><MenuLine />{props.boardmenu.pageName}</Menu></Link>);
};

/* =================================================================================================================== Dashboard */
const BoardSection = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 60px auto 60px;
  height: 100svh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100svw;

  & header {box-shadow: unset;}
  & footer {bottom: 8px; box-shadow: unset;}
`;

const BoardContent = styled.div`
  display: grid;
  place-items: center;
  .notice {
    display: flex; justify-content: space-between; align-items: center;
    width: 1048px;
    padding: 24px; 
    background: #fff;
    color: var(--color-2);
    text-align: center;
    border-radius: 8px;
    margin-bottom: 40px;
    text-align: center;
    font-size: 1.25rem;
    &-title {font-weight: bold;}
    &-content {&:hover {color: var(--color-1); cursor: pointer;}}
    &-modal {
      position: absolute; top: 60px; left: 0; z-index: 999;
      width: fit-content; height: fit-content; padding: 24px;
      width: 100%; height: 100%;
      background: #fff; border-radius: 8px;
      display: flex; flex-direction: column; justify-content: center; align-items: center;
      img {width: 800px;}
      button {width: fit-content; height: 32px; background: var(--color-2); color: var(--white); padding: 16px; border-radius: 4px; margin-top: 24px;}
    }
    span {color: #888; padding-left: 16px;}
  }
  .dashboard-menu {
    align-items: center;
    column-gap: 16px;
    display: grid;
    grid-template-columns: repeat(4, minmax(100px, 250px));
    grid-template-rows: repeat(2, 1fr);
    justify-content: center;
    row-gap: 16px;
    background-color: var(--MainNavy);
  }
`;

const MasterButtons = styled.div`
  align-items: center;
  bottom: 25px;
  column-gap: 15px;
  display: flex;
  justify-content: flex-start;
  left: 15px;

  & input[type="text"] {
    border-radius: 5px;
    box-sizing: border-box;
    height: 38px;
    padding: 10px;
    transform: translateX(5px);
    width: 150px;
  }
`;

const LinkButton = styled(Link)`
  align-items: center;
  background-color: var(--white);
  border-radius: 5px;
  color: var(--MainNavy);
  cursor: pointer;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  justify-content: center;
  padding: 4px 20px;
`;

const MenuSelector = styled.select`
  border-radius: 5px;
  bottom: 25px;
  font-size: 20px;
  font-weight: 600;
  padding: 4px 20px;
  right: 15px;
`;
const MenuOption = styled.option`
  cursor: pointer;
  &:disabled {background-color: var(--MainNavy); color: var(--white); cursor: default;}
`;

const Dashboard = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { menuReducer, operatorReducer, userReducer } = useSelector((state) => state);
  const { IfisPageCode } = operatorReducer;

  /* ====================================================================== #2 */
  const [_dashboardMenuList, setDashboardMenuList] = useState([]);
  const [_menuList, setMenuList] = useState([]);

  const [_companyCode, setCompanyCode] = useState('');

  const [noticeModal, setNoticeModal] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    console.log('userReducer : ', userReducer);
    if (['ROLE_MALL', 'ROLE_GUEST'].includes(userReducer.user.role) || !checkEmptyNull(userReducer.user.team, false)) {
      alert(`
        부여받은 권한이 없습니다.
        관리자에게 문의해주세요.
      `);

      console.log('================================= removeCookie');
      removeCookie('accessToken');
      removeCookie('refreshToken');
      dispatch(userReducer_reset());
      dispatch(mallSiteUserActions_setUser());

      return navigate('/', { replace: true });;
    }

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    createCompanyTopMenu();
    createCompanySubMenu();

    return () => { };
  }, []);

  useEffect(() => { setDashboardMenuList(() => { return menuReducer.companyTopMenu }) }, [menuReducer.companyTopMenu])
  useEffect(() => { setMenuList(() => { return menuReducer.companySubMenu }) }, [menuReducer.companySubMenu])
  useEffect(() => { }, [_companyCode]);

  /* ====================================================================== #4 */
  const createCompanyTopMenu = () => {
    const IfisTopMenuList = Object.values(IfisPageCode).filter(pageCode => pageCode.defaultIndexY === 0); // 0으로 바꿔야 함!!!
    // console.log('IfisTopMenuList : ', Object.values(IfisPageCode), IfisTopMenuList);
    const userMenuList = Object.values(userReducer.userMenuCode).map(menuCode => {
      const defaultMenuData = IfisPageCode[menuCode.pageCode];
      const menuData = menuReducer.companyMenu[menuCode.pageCode];
      const returnData = {
        ...menuCode,
        ...menuData,
        pageUrl: checkNullObject(menuData, false) ? checkEmptyNull(menuData.pageUrl, defaultMenuData.defaultPageUrl) : checkEmptyNull(defaultMenuData.defaultPageUrl, ''),
        pageName: checkNullObject(menuData, false) ? checkEmptyNull(menuData.pageName, defaultMenuData.defaultPageName) : checkEmptyNull(defaultMenuData.defaultPageName, ''),
        indexX: checkNullObject(menuData, false) ? checkEmptyNull(menuData.indexX, defaultMenuData.defaultIndexX) : checkEmptyNull(defaultMenuData.defaultIndexX, ''),
        indexY: checkNullObject(menuData, false) ? checkEmptyNull(menuData.indexY, defaultMenuData.defaultIndexY) : checkEmptyNull(defaultMenuData.defaultIndexY, ''),
      }
      return returnData;
    })

    const findTopMenu = (value) => {
      const returnIndex = userMenuList.findIndex(menu => menu.indexX === value);
      return returnIndex;
    }
    const topMenuList = IfisTopMenuList.filter(IfisTopMenu => findTopMenu(IfisTopMenu.defaultIndexX) !== -1);
    const topMenus = topMenuList.map(menu => {
      const sortUserMenuList = userMenuList.sort((a, b) => a.indexY - b.indexY);
      const resetData = { pageUrl: '/', pageName: '' }
      const linkData = checkNullObject(sortUserMenuList.filter(userMenu => userMenu.indexX === menu.defaultIndexX)[1], resetData);
      const pageData = checkNullObject(sortUserMenuList.filter(userMenu => userMenu.indexX === menu.defaultIndexX)[0], resetData);
      const returnData = {
        ...menu,
        pageUrl: checkEmptyNull(linkData.pageUrl, menu.defaultPageUrl),
        pageName: checkEmptyNull(pageData.pageName, menu.defaultPageName)
      };
      return returnData;
    })
    dispatch(menuActions_setCompanyTopMenu(topMenus));
  };
  const createCompanySubMenu = () => {
    const userMenuList = Object.values(userReducer.userMenuCode).map(menuCode => {
      const menuData = menuReducer.companyMenu[menuCode.pageCode];
      const returnData = { ...menuCode, ...menuData }
      return returnData;
    })
    const sortList = [...userMenuList].sort((a, b) => {
      if (a.indexX > b.indexX) return 1;
      if (a.indexX < b.indexX) return -1;
      if (a.indexY > b.indexY) return 1;
      if (a.indexY < b.indexY) return -1;
      else return 0;
    });
    dispatch(menuActions_setCompanySubMenu(sortList));
    dispatch(userReducer_setUserMenu(sortList));
  };

  /* ====================================================================== #5 */
  const onModal = () => { setNoticeModal(!noticeModal); }

  /* ====================================================================== #6 */

  return (
    <BoardSection>
      <Header />
      <BoardContent>
        {/* <div className='notice'>
          <p className='notice-title'>공지사항</p>
          {(() => {
            if (userReducer.company.companyId === 2) { // 우리창호 공지
              return (
                <>
                  <p className='notice-content' onClick={onModal}><IfisVersion /> 우리창호 업데이트 안내드립니다. <span>2023년 9월 01일</span></p>
                  {noticeModal ? (
                    <div className='notice-modal'>
                      <img src='https://media.discordapp.net/attachments/1146032871627309056/1146833778849890395/image.png' />
                      <button onClick={onModal}>닫기</button>
                    </div>
                  ) : null}
                </>
              );
            } else { // 전체 공지
              return (
                <>
                  <p className='notice-content' onClick={onModal}><IfisVersion /> 전체 업데이트 안내드립니다. <span>2023년 8월 29일</span></p>
                  {noticeModal ? (
                    <div className='notice-modal'>
                      <img src='https://media.discordapp.net/attachments/1146032871627309056/1146033617273892874/image.png?width=1752&height=1308' />
                      <button onClick={onModal}>닫기</button>
                    </div>
                  ) : null}
                </>
              );
            }
          })()}
        </div> */}
        <div className='dashboard-menu'>
          {checkNullArray(_dashboardMenuList, []).map((menu, index) => { return (<BoardMenu key={index + '_boardmenu'} boardmenu={menu} />); })}
          {(userReducer.user.role === 'ROLE_MASTER' || [1, 2, 3, 19].includes(userReducer.company.companyId)) ? <BoardMenu boardmenu={{ pageUrl: '/mallManagement/order', pageName: '고객주문관리' }} /> : null}
        </div>
      </BoardContent>
      <div style={{ boxSizing: 'border-box', display: 'flex', justifyContent: 'space-between', paddingInline: '50px 80px' }}>
        <MasterButtons>
          {userReducer.user.role === 'ROLE_MASTER' ? (<><LinkButton to="/operator">아이피스 어드민</LinkButton></>) : null}
          {userReducer.user.role === 'ROLE_MASTER' ? (
            <>
              <input type='text' value={_companyCode} placeholder='companyCode' onInput={(e) => { setCompanyCode(() => { return e.target.value; }) }} />
              <LinkButton to={'/' + _companyCode + "/mall"}>몰 사이트</LinkButton>
            </>
          ) : null}
          {checkEmptyNull(userReducer.company.companyCode, false) ? (<LinkButton to={'/' + userReducer.company.companyCode + "/mall"}>몰 사이트</LinkButton>) : null}
        </MasterButtons>
        <MenuSelector
          onChange={(e) => {
            const eventUrl = e.target.value;
            if (!checkEmptyNull(eventUrl, false)) return;
            navigate(eventUrl, { replace: true });
          }}
        >
          <option value="">전체메뉴</option>
          {checkNullArray(_menuList, []).map((menu, index) => {
            return (
              <MenuOption
                key={index + '_menuOptions'}
                disabled={menu.pageCode.includes('00') ? true : false}
                value={menu.pageUrl}
              >
                {menu.pageCode.includes('00') ? `< ${menu.pageName} >` : menu.pageName}
              </MenuOption>
            );
          })}
        </MenuSelector>
        <Footer />
      </div>
    </BoardSection>
  );
};

export default Dashboard;
