import React from 'react';
import styled from 'styled-components';

import GuideText from './GuideText';

const StatusDivButtonStyle = styled.div`
  align-items: center;
  border: 4px solid var(--white);
  border-radius: 10px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 30px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  width: 100%;

  background-color: ${(props) => {
    return props.status === 'wait'
      ? 'var(--gray-900)'
      : props.status === 'work'
      ? 'var(--WorkingColor)'
      : props.status === 'end'
      ? 'var(--ThirdBlue)'
      : props.status === 'stop'
      ? 'var(--MainRed)'
      : props.status === 'pause'
      ? 'var(--MainYellow)'
      : props.status === 'cancel'
      ? 'var(--Bg)'
      : null;
  }};

  color: ${(props) => {
    return props.status === 'cancel' ? 'var(--gray-200)' : 'var(--white)';
  }};

  ${(props) => {
    return props.status === 'wait' && 'animation: WaitingStatus 1.5s infinite;';
  }};

  /* ${(props) => {
    return props.status === 'end' ? 'cursor: default;' : 'cursor: pointer';
  }}; */
`;

const StatusDivButton = (props) => {
  return (
    <StatusDivButtonStyle
      rowSpan={props.rowSpan}
      style={props.statusStyle}
      status={props.status}
      onClick={() => {
        props.statusEvent(props);
      }}
    >
      {props.status === 'wait'
        ? '대기'
        : props.status === 'work'
        ? '진행'
        : props.status === 'end'
        ? '완료'
        : props.status === 'stop'
        ? '중단'
        : props.status === 'pause'
        ? '일시정지'
        : props.status === 'cancel'
        ? '취소'
        : null}

      <GuideText />
    </StatusDivButtonStyle>
  );
};

export default StatusDivButton;
