import React, { useEffect, useState } from 'react';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import GLSensorSetting from 'pages/sensorData/custom/company/gl/modal/GLSensorSetting';

const SensorData = (props) => {
  const [_sensorData, setSensorData] = useState(checkNullObject(props.content, {}));
  // console.log('_sensorData : ', _sensorData);
  const [_run, setRun] = useState({});
  const [_win, setWin] = useState({});
  const [_temp, setTemp] = useState({});
  const [_mo, setMo] = useState({});
  const [_co2, setCo2] = useState({});

  const [_modalStatus, setModalStatus] = useState(false);
  const [_settingData, setSettingData] = useState({});

  useEffect(() => {
    setSensorData(() => { return checkNullObject(props.content, {}) });
    setRun(() => { return checkNullArray(props.content?.sensorList, []).find((item) => item.code.slice(-4) === '_run'); })
    setWin(() => { return checkNullArray(props.content?.sensorList, []).find((item) => item.code.slice(-4) === '_ao1'); })
    setTemp(() => { return checkNullArray(props.content?.sensorList, []).find((item) => item.type === '온도'); })
    setMo(() => { return checkNullArray(props.content?.sensorList, []).find((item) => item.type === '습도'); })
    setCo2(() => { return checkNullArray(props.content?.sensorList, []).find((item) => item.type === 'Co2'); })

    return () => { }
  }, [props])

  const actSet = (data) => {
    setSettingData(() => { return data });
    setModalStatus(() => { return true });
  };

  return (
    <>
      <div className='sensor-group'>
        <div className='sensor-header'>
          <h1>{_sensorData.name}</h1>
          <div className='power'>
            <h3>전원</h3>
            <p className={checkEmptyNull(_run?.value, 'OFF')}>{checkEmptyNull(_run?.value, 'OFF')}</p>
          </div>
          <div className='windVolume'>
            <h3>풍량</h3>
            <p>{checkEmptyNull(_win?.value, 'OFF')}%</p>
          </div>
          {/* <button className='btn-set' onClick={(e) => { e.preventDefault(); setModalStatus(() => { return true }); }}>설정</button> */}
        </div>
        <div className='sensor-content'>
          {[
            { name: '온도', data: _temp, unit: '℃' },
            { name: '습도', data: _mo, unit: '%' },
            { name: 'Co2', data: _co2, unit: 'ppm' },
          ].map((sensor, index) => {
            return (
              <div className='sensor-data'>
                <div className='data-header'>
                  <h3>{sensor.name}</h3>
                  <button className='btn-set' onClick={(e) => { e.preventDefault(); actSet(sensor.data); }}>알람설정</button>
                </div>
                <div className='sensor current'>
                  <h4>현재</h4>
                  <p>{(checkEmptyNull(sensor.data?.value, 0) * 1).toLocaleString()} <span>{sensor.unit}</span></p>
                </div>
                <div className='sensor set'>
                  <h4>설정</h4>
                  <p>{(checkEmptyNull(sensor.data?.setValue, 0) * 1).toLocaleString()} <span>{sensor.unit}</span></p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {_modalStatus === true ? (
        <GLSensorSetting
          content={_settingData}
          open={_modalStatus}
          close={() => { setModalStatus(false); }}
        />
      ) : null}
    </>
  );
};

export default SensorData;