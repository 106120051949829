import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BigNumber from 'bignumber.js';

import { contractTypeApi } from 'api/apis/contractTypeApi';
import { contractAttributeApi } from 'api/apis/contractAttributeApi';
import { contractApi } from 'api/apis/contractApi';

import { checkEmptyNull, checkNullArray, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import { ContractFormComponent } from './ContractForm.style';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import SelectNaraElements from 'pages/naraElement/modal/SelectNaraElements';
import SelectOneAccount from 'pages/account/modal/SelectOneAccount';
import TableSection from 'components/layouts/table/TableSection';

const UpdateContract = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  const transformDate = (date) => { // 날짜 폼
    return checkEmptyNull(date, false) ? DateFormat(date) : '';
  }

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();
  const updateContent = checkNullParse(searchParams.get('updateContent'), {});

  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId, // 회사
    userId: userReducer.user.id, // 작성자

    contractId: updateContent.contractId,
    contractDate: transformDate(updateContent.contractDate), // 수주일자
    contractRegDate: transformDate(updateContent.contractRegDate), // 납품요구접수일자
    scheduledEndDate: transformDate(updateContent.scheduledEndDate), // 납품기한일자
    contractActualEndDate: transformDate(updateContent.contractActualEndDate), // 납품일자


    contractTypeId: updateContent.contractTypeId, // 수주유형
    contractStatus: updateContent.contractStatus, // 수주상태
    contractCode: checkEmptyNull(updateContent.contractCode, ''), // 수주코드
    contractName: checkEmptyNull(updateContent.contractName, ''), // 수주명

    accountId: updateContent.accountId, // 거래처
    accountName: updateContent.accountName, // 거래처

    contractPrice: updateContent.contractPrice, // 수주총금액

    address: checkEmptyNull(updateContent.address, ''), // 수주주소

    remark: checkEmptyNull(updateContent.remark, ''), // 비고
    monitoringRemark: checkEmptyNull(updateContent.monitoringRemark ,''), // 특이사항

    contractCustomizedContent: JSON.stringify({}), // 커스텀

    contractAttributeValueList: checkNullArray(updateContent.contractAttributeValueList, []), // 항목
    deleteContractAttributeValueList: [], // 항목 삭제

    contractElementList: checkNullArray(updateContent.contractElementList, []), // 수주물품
    deleteContractElementList: [], // 수주물품 삭제

    procurementList: checkNullArray(updateContent.procurementList, []), // 발주
    workOrderLogList: checkNullArray(updateContent.workOrderLogList, []), // 작업지시
  });

  const [_contractTypes, setContractTypes] = useState([]); // 수주 유형
  const [_contractAttrList, setContractAttrList] = useState([]); // 수주 항목 리스트
  const [_contractAttrValueList, setContractAttrValueList] = useState(() => {
    const returnData = {};
    checkNullArray(updateContent.contractAttributeValueList, []).forEach((contractAttr) => {
      returnData[contractAttr.contractAttributeId] = contractAttr.value;
    });
    return returnData;
  }); // 수주 항목 값 리스트

  const [_account, setAccount] = useState({
    accountId: updateContent.accountId,
    accountName: updateContent.accountName,
  }); // 거래처

  const [_contractElements, setContractElements] = useState(() => {
    const contractElementList = checkNullArray(updateContent.contractElementList, []);
    if (checkNullArray(contractElementList, false)) {
      const returnData = contractElementList.map((element) => {
        return {...element, calPrice: element.price};
      });
      return returnData;
    } else {
      return [];
    }
  }); // 수주 물품

  const [_modalStatus, setModalStatus] = useState(false); // 나라장터 수주 물품 선택 모달
  const [_accountModalStatus, setAccountModalStatus] = useState(false); // 거래처 선택 모달

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((authority) => authority.authority.startsWith('201') === true);
    const authorityCodes = authorityContent.map((authority) => authority.authority);
    setAuthority(authorityCodes);

    getContractTypes();
    getContractAttrs(updateContent.contractTypeId);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => { }, [_account]);
  useEffect(() => { }, [_contractAttrList]);

  /* ====================================================================== #4 */
  const getContractTypes = async () => { // 수주 유형
    const BodyToPost = {companyId: userReducer.company.companyId};
    await contractTypeApi.searchContractType(BodyToPost).then((response) => {
      if (response === undefined) return;
      setContractTypes(() => {return response.data});
    });
  };

  const getContractAttrs = async (contractType) => { // 수주 항목
    const BodyToPost = {companyId: userReducer.company.companyId};
    if (checkEmptyNull(contractType, false)) {
      BodyToPost.contractTypeId = !isNaN(Number(contractType)) ? Number(contractType) : undefined;
    } else { // 수주 유형 선택 X - 리스트 초기화
      setContractAttrList(() => {return [];});
      setContractAttrValueList(() => {return {};});
      return;
    }

    await contractAttributeApi.searchContractAttribute(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('getContractAttrs : ', response);

      setContractAttrList(() => {return response.data;});
      setContractAttrValueList((prev) => {
        const returnData = { ...prev };
        response.data.forEach((attr) => {
          const checkIndex = Object.keys(returnData).findIndex((key) => Number(key) === Number(attr.contractAttributeId));
          if (checkIndex === -1) returnData[attr.contractAttributeId] = '';
        });

        return returnData;
      });
    });
  };

  /* ====================================================================== #5 */
  const handleInputEvent = (e) => { // 입력 이벤트
    const { name, value } = e.target;
    setFormData((prev) => {return { ...prev, [name]: value };});
  };
  const handleContractPrice = (e) => { // 금액 입력 이벤트
    const { name, value } = e.target;
    let eventValue = value;
    if (!eventValue.toString().startsWith('0.')) eventValue = eventValue.toString().replace(/^0+/, '');
    if (eventValue < 0 || eventValue === '') eventValue = 0;
    if (eventValue.length > 15) return;
    setFormData((prev) => {return { ...prev, [name]: BigNumber(eventValue).toNumber() };});
  };
  const handleAttributeValue = (e, attr) => { // 수주 항목 입력 이벤트
    const { value } = e.target;
    setContractAttrValueList((prev) => {return { ...prev, [attr.contractAttributeId]: value };});
  };
  const handleElementInput = (e, index, element) => { // 수주 물품 입력 이벤트 (수량, 금액)
    const { value } = e.target;

    let eventValue = value;
    if (!eventValue.toString().startsWith('0.')) eventValue = eventValue.toString().replace(/^0+/, '');
    if (eventValue < 0 || eventValue === '') eventValue = 0;
    if (eventValue.length > 15) return;

    const prevData = [..._contractElements];
    switch (e.target.name) {
      case 'quantity':
        const newData_quantity = {...element, quantity: BigNumber(eventValue).toNumber()};
        prevData.splice(index, 1, newData_quantity);
        break;

      case 'calPrice':
        const newData_calPrice = {...element, calPrice: BigNumber(eventValue).toNumber()};
        prevData.splice(index, 1, newData_calPrice);
        break;
        
      default: return;
    }

    const sumArray = prevData.map((item) => item.calPrice);
    const setSumArray = sumArray.filter((item) => item !== null);
    const sum = setSumArray.reduce((a, b) => BigNumber(a).plus(BigNumber(b)).toNumber(), 0);

    setFormData((prev) => {return {...prev, contractPrice: sum};});

    setContractElements(() => {return prevData;});
  };

  const deleteElement = (index, element) => { // 수주 물품 삭제
    setContractElements((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);

      const sumArray = prevData.map((item) => item.calPrice);
      const setSumArray = sumArray.filter((item) => item !== null);
      const sum = setSumArray.reduce((a, b) => BigNumber(a).plus(BigNumber(b)).toNumber(), 0);

      setFormData((prev) => {return {...prev, contractPrice: sum};});
      return prevData;
    });
  };

  const actUpdate = async (e) => {
    e.preventDefault();

    if (!checkEmptyNull(_formData.contractCode, false)) return alert('수주코드를 입력해주세요.');
    if (!checkEmptyNull(_account.accountId, false)) return alert('거래처를 선택해주세요.');

    // 항목
    const originAttrList = checkNullArray(updateContent.contractAttributeValueList, []);
    const mapContractAttrValueList = Object.keys(_contractAttrValueList).map((contractAttributeId) => {
      const returnData = {
        contractAttributeId: Number(contractAttributeId),
        value: _contractAttrValueList[contractAttributeId],
      };

      const checkIndex = originAttrList.findIndex((attr) => Number(attr.contractAttributeId) === Number(contractAttributeId));
      if (checkIndex !== -1) {
        const checkedItem = originAttrList[checkIndex];
        returnData['contractAttributeValueId'] = Number(checkedItem.contractAttributeValueId);
      }

      return returnData;
    });

    // 수주 물품
    const originElementList = checkNullArray(updateContent.contractElementList, []);
    const findElement = (contractElementId) => {
      const index = _contractElements.findIndex((contractElement) => contractElement.contractElementId === contractElementId);
      return index;
    };
    const deleteContractElementList = originElementList.filter((element) => findElement(element.contractElementId) === -1);

    // body
    const BodyToPut = {
      ..._formData,
      accountId: _account.accountId,
      contractAttributeValueList: mapContractAttrValueList,
      contractElementList: _contractElements.map((element) => {
        return {
          accountId: _account.accountId,
          contractElementId: element.contractElementId,
          elementId: element.elementId,
          quantity: element.quantity,
          price: element.calPrice,
        };
      }),
    };
    if (deleteContractElementList.length > 0) {
      BodyToPut.deleteContractElementList = deleteContractElementList.map((element) => element.contractElementId);
    }

    await contractApi.updateContract(BodyToPut.contractId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('actUpdate : ', response);
      alert('수주를 수정했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  /* ====================================================================== #6 */

  return (
    <>
    <Grid2Body contents={
      <ContractFormComponent>
        <NavBar title={'수주관리'} nav={''} />

        <Form
          title={
            <>
              수주<br />
              수정
            </>
          }
          buttons={
            <>
              <button className="formButton" onClick={actUpdate}>저장</button>
              <button className="formButton cancle"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(pageReducer.currentPage, { replace: true });
                }}
              >
                취소
              </button>
            </>
          }
          forms={
            <>
              <div className="formBox w100">
                <div className='flexBox'>
                  <h4>수주물품</h4>
                  <div className="formButton" onClick={() => {setModalStatus(true);}}>수주물품 추가</div>
                </div>

                <div className='table-box'>
                  <TableSection content={
                    <table>
                      <thead>
                        <tr>
                          <th>물품이름</th>
                          <th>물품코드</th>
                          <th>나라장터 물품코드</th>
                          <th>수량</th>
                          <th>금액</th>
                          <th style={{ minWidth: '50px', width: '50px' }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {checkNullArray(_contractElements, []).map((contractElement, index) => {
                          return (
                            <tr key={index + '_contractElement'}>
                              <td className={checkEmptyNull(contractElement?.elementName, '').startsWith('*') ? 'outsourcingText' : ''}>
                                {contractElement.elementName}
                              </td>
                              <td>{contractElement.elementCode}</td>
                              <td>{contractElement?.element?.naraElementCode}</td>
                              <td>
                                <input
                                  type="number"
                                  name="quantity"
                                  min={0}
                                  value={contractElement.quantity}
                                  onInput={(e) => {handleElementInput(e, index, contractElement);}}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  name="calPrice"
                                  min={0}
                                  value={contractElement.calPrice}
                                  onInput={(e) => {handleElementInput(e, index, contractElement);}}
                                />
                              </td>
                              <td style={{minWidth: '50px', width: '50px'}}>
                                <div className='delete' onClick={() => {deleteElement(index, contractElement);}}>
                                  <CloseButtonIcon />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  }
                  />
                </div>

                <div className="cautionText">※ 수주수량을 꼭! 입력해 주세요.</div>
              </div>

              <div className="formBox">
                <h4>수주유형</h4>
                <select
                  name="contractType"
                  value={checkEmptyNull(_formData.contractTypeId, '')}
                  onChange={(e) => {
                    getContractAttrs(e.target.value);
                    setFormData((prev) => {return {...prev, contractTypeId: Number(e.target.value)};});
                  }}
                >
                  <option value="">수주유형</option>
                  {checkNullArray(_contractTypes, []).map((type, index) => {
                    return (<option key={index + '_contractType'} value={type.contractTypeId}>{type.contractTypeName}</option>);
                  })}
                </select>
              </div>

              <div className="formBox">
                <h4>납품요구번호(G2B)/계약번호(S2B)</h4>
                <input
                  type="data"
                  name="contractCode"
                  placeholder="* 납품요구번호(G2B)/계약번호(S2B)..."
                  data-required="required"
                  value={_formData.contractCode}
                  onInput={handleInputEvent}
                />
              </div>

              <div className="formBox">
                <h4>수주이름</h4>
                <input
                  type="text"
                  name="contractName"
                  placeholder="* 수주이름..."
                  data-required="required"
                  value={_formData.contractName}
                  onInput={handleInputEvent}
                />
              </div>

              <div className="formBox account">
                <div className='flexBox'>
                  <h4>거래처</h4>
                  <div className="formButton" onClick={() => {setAccountModalStatus(true);}}>거래처 선택</div>
                </div>
                <div>
                  <input
                    type="text"
                    name="accountName"
                    value={checkEmptyNull(_account.accountName, checkEmptyNull(_account.accountCode, ''))}
                    readOnly
                    placeholder="거래처..."
                  />
                  <div className="formButton" onClick={() => {setAccount(() => {return {}})}}>삭제</div>
                </div>
              </div>

              {[
                {name: 'contractDate', title: '수주일자',},
                {name: 'contractRegDate', title: '납품요구접수일자',},
                {name: 'scheduledEndDate', title: '납품기한일자',},
                {name: 'contractActualEndDate', title: '납품일자',},
              ].map((contractDate, index) => {
                return (
                  <div key={index + '_contractDateForm'} className='formBox'>
                    <h4>{contractDate.title}</h4>
                    <input
                      type="date"
                      name={contractDate.name}
                      value={_formData[contractDate.name]}
                      onChange={handleInputEvent}
                    />
                  </div>
                );
              })}

              <div className="formBox">
                <h4>수주주소</h4>
                <input
                  type="text"
                  name="address"
                  placeholder="* 수주주소..."
                  value={_formData.address}
                  onInput={handleInputEvent}
                />
              </div>

              <div className="formBox">
                <h4>수주총금액</h4>
                <input
                  type="number"
                  name="contractPrice"
                  placeholder="* 수주총금액..."
                  value={_formData.contractPrice}
                  onInput={handleContractPrice}
                />
              </div>

              <div className="formBox">
                <h4>특이사항</h4>
                <textarea
                  name="monitoringRemark"
                  placeholder="* 특이사항..."
                  value={_formData.monitoringRemark}
                  onInput={handleInputEvent}
                />
              </div>

              <div className="formBox">
                <h4>비고</h4>
                <textarea
                  name="remark"
                  placeholder="* 비고..."
                  value={_formData.remark}
                  onInput={handleInputEvent}
                />
              </div>

              {checkNullArray(_contractAttrList, []).map((attr, attrIndex) => {
                  return (
                    <div key={attrIndex + '_attr'} className="formBox">
                      <h4>{attr.contractAttributeName}</h4>
                      <input
                        type="text"
                        placeholder={attr.contractAttributeName + '...'}
                        value={_contractAttrValueList[attr.contractAttributeId]}
                        onInput={(e) => {handleAttributeValue(e, attr);}}
                      />
                    </div>
                  );
                })}
            </>
          }
        />
      </ContractFormComponent>
    }
    />

    {_accountModalStatus && ( // 거래처 선택 모달
      <SelectOneAccount
        buttonTitle={'거래처 선택'}
        buttonEvent={(data) => {
          setAccount(() => {return data;});
          setFormData((prev) => {
            const address = `${checkEmptyNull(data.address, '')} ${checkEmptyNull(data.detailAddress, '')}`;
            return {...prev, address: address};
          });

          setTimeout(setAccountModalStatus(false), 1000);
        }}
        open={_accountModalStatus}
        close={() => {setAccountModalStatus(false);}}
      ></SelectOneAccount>
    )}
    {_modalStatus && ( // 나라장터 수주 물품 선택 모달
      <SelectNaraElements
        buttonEvent={(data) => {
          const contractElementIds = _contractElements.map((thisItem) => thisItem.elementId);
          const newElements = data.map((element) => {
            if (contractElementIds.indexOf(element.elementId) !== -1) return null;
            else return {...element, calPrice: 0};
          });
          const setNewElements = newElements.filter((thisItem) => thisItem !== null);
          setContractElements((prev) => {return [...prev, ...setNewElements];});
          setTimeout(setModalStatus(false), 1000);
        }}
        buttonTitle="물품 선택"
        open={_modalStatus}
        close={() => {setModalStatus(false);}}
      />
    )}
    </>
  );
};

export default UpdateContract;
