const initialState = {
  workOrderType: 'all',
  workOrderStatus: 'all',
  startDate: '',
  endDate: '',
  searchData: {
    elementName: '',
    elementCode: '',
  },
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,

  elementType: 'material',
  searchAttrs: {},

  clickedCategory: {},
  clickedLevel1Category: {},
  clickedLevel2Category: {},
  clickedLevel3Category: {},
  clickedLevel4Category: {},
  clickedLevel5Category: {},

  element: {},
  elements: [],

  level1Categories: [],
  level2Categories: [],
  level3Categories: [],
  level4Categories: [],
  level5Categories: [],

  bookmark: true,
}
const resetState = {
  workOrderType: 'all',
  workOrderStatus: 'all',
  startDate: '',
  endDate: '',
  searchData: {
    elementName: '',
    elementCode: '',
  },
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,

  searchAttrs: {},

  clickedCategory: {},
  clickedLevel1Category: {},
  clickedLevel2Category: {},
  clickedLevel3Category: {},
  clickedLevel4Category: {},
  clickedLevel5Category: {},

  element: {},
  elements: [],
};

const productionPerformanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETPRODUCTIONPERFORMANCEWORKORDERTYPE':
      return { ...state, workOrderType: action.payload };
    case 'SETPRODUCTIONPERFORMANCEWORKORDERSTATUS':
      return { ...state, workOrderStatus: action.payload };
    case 'SETPRODUCTIONPERFORMANCESTARTDATE':
      return { ...state, startDate: action.payload };
    case 'SETPRODUCTIONPERFORMANCEENDDATE':
      return { ...state, endDate: action.payload };
    case 'SETPRODUCTIONPERFORMANCESEARCHDATA':
      return { ...state, searchData: action.payload };
    case 'SETPRODUCTIONPERFORMANCESEARCHOPTION':
      return { ...state, searchOption: action.payload };
    case 'SETPRODUCTIONPERFORMANCESEARCHTEXT':
      return { ...state, searchText: action.payload };
    case 'SETPRODUCTIONPERFORMANCEPAGENUMBER':
      return { ...state, pageNumber: action.payload };
    case 'SETPRODUCTIONPERFORMANCETOTALSIZE':
      return { ...state, totalSize: action.payload };
    case 'SETPRODUCTIONPERFORMANCEISPUSHEDSEARCHBUTTON':
      return { ...state, isPushedSearchButton: action.payload };

    case 'SETPRODUCTIONPERFORMANCEELEMENTTYPE':
      return { ...state, elementType: action.payload };
    case 'SETPRODUCTIONPERFORMANCESEARCHATTS':
      return { ...state, searchAttrs: action.payload };

    case 'SETPRODUCTIONPERFORMANCECLICKEDCATEGORY':
      return { ...state, clickedCategory: action.payload };
    case 'SETPRODUCTIONPERFORMANCECLICKEDLEVEL1CATEGORY':
      return { ...state, clickedLevel1Category: action.payload };
    case 'SETPRODUCTIONPERFORMANCECLICKEDLEVEL2CATEGORY':
      return { ...state, clickedLevel2Category: action.payload };
    case 'SETPRODUCTIONPERFORMANCECLICKEDLEVEL3CATEGORY':
      return { ...state, clickedLevel3Category: action.payload };
    case 'SETPRODUCTIONPERFORMANCECLICKEDLEVEL4CATEGORY':
      return { ...state, clickedLevel4Category: action.payload };
    case 'SETPRODUCTIONPERFORMANCECLICKEDLEVEL5CATEGORY':
      return { ...state, clickedLevel5Category: action.payload };

    case 'SETPRODUCTIONPERFORMANCESELECTONEELEMENT':
      return { ...state, element: action.payload };

    case 'SETPRODUCTIONPERFORMANCESELECTELEMENTS':
      return { ...state, elements: action.payload };

    case 'SETPRODUCTIONPERFORMANCESETLEVEL1CATEGORIES':
      return { ...state, level1Categories: action.payload };
    case 'SETPRODUCTIONPERFORMANCESETLEVEL2CATEGORIES':
      return { ...state, level2Categories: action.payload };
    case 'SETPRODUCTIONPERFORMANCESETLEVEL3CATEGORIES':
      return { ...state, level3Categories: action.payload };
    case 'SETPRODUCTIONPERFORMANCESETLEVEL4CATEGORIES':
      return { ...state, level4Categories: action.payload };
    case 'SETPRODUCTIONPERFORMANCESETLEVEL5CATEGORIES':
      return { ...state, level5Categories: action.payload };

    case 'SETPRODUCTIONPERFORMANCEBOOKMARK':
      return { ...state, bookmark: action.payload };

    case 'SETPRODUCTIONPERFORMANCERESET':
      return { ...state, ...resetState };
    default: return state;
  };
};

export default productionPerformanceReducer;