import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { category } from 'api/apis/mall/category';

import DeleteForm from 'components/layouts/form/DeleteForm';
import MallBody from 'components/layouts/body/MallBody';
import MallManagementHeader from 'components/header/MallManagementHeader';
import NavBar from 'components/nav/NavBar';

const DeleteMallCategory = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();

  const mallCategoryParentLevel = searchParams.get('mallCategoryParentLevel');
  const mallCategoryParentCode = searchParams.get('mallCategoryParentCode');
  const mallCategoryParentName = searchParams.get('mallCategoryParentName');
  const mallCategoryLevel = searchParams.get('mallCategoryLevel');
  const mallCategoryId = searchParams.get('mallCategoryId');
  const mallCategoryCode = searchParams.get('mallCategoryCode');
  const mallCategoryName = searchParams.get('mallCategoryName');
  console.table({
    mallCategoryParentLevel: mallCategoryParentLevel,
    mallCategoryParentCode: mallCategoryParentCode,
    mallCategoryParentName: mallCategoryParentName,
    mallCategoryLevel: mallCategoryLevel,
    mallCategoryId: mallCategoryId,
    mallCategoryCode: mallCategoryCode,
    mallCategoryName: mallCategoryName,
  });

  /* ====================================================================== #3 */
  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const actDelete = async () => {
    await category.deleteMallCategory(mallCategoryId)
      .then((response) => {
        if (response === undefined) return;
        console.log('category.deleteMallCategory : ', response);

        alert('카테고리를 삭제했습니다.');
        navigate(pageReducer.currentPage, { replace: true });
      });
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <MallBody
      contents={
        <>
          <main className="Main">
            <NavBar title={'카테고리 설정'} nav={''} />

            <DeleteForm
              delteTitle={'카테고리'}
              deleteItem={<>{'카테고리 이름 : ' + mallCategoryName}</>}
              deleteText={
                <>
                  {mallCategoryName} 카테고리에 포함된{' '}
                  <span className="cautionText">
                    모든 카테고리 및 판매 물품정보
                  </span>
                  가 삭제됩니다.
                </>
              }
              deleteButton={
                <>
                  <button
                    className="formButton cancle"
                    onClick={() => {
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                  <button
                    className="formButton delete"
                    onClick={() => {
                      if (window.confirm('카테고리를 삭제합니다.')) actDelete();
                      else return;
                    }}
                  >
                    삭제
                  </button>
                </>
              }
            />
          </main>
        </>
      }
    />
  );
};

export default DeleteMallCategory;
