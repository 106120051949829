import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { getCookie } from 'preferences/cookie/cookie';
import { ACCESS_TOKEN, AXIOS_BASE_URL } from 'preferences/server/constants';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import Nav from 'components/nav/Nav';

const DeleteDLMovement = () => {
  /* ========================================================================= #1 */
  const navigate = useNavigate();
  const { userReducer, pageReducer, daerimReducer } = useSelector(
    (state) => state,
  );

  console.log('daerimReducer.deleteContent', daerimReducer.deleteContent);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  /* ====================================================================== #3 */
  useEffect(() => {
    setOnload('loaded');

    return () => {};
  }, []);

  /* ========================================================================= #4 */
  const actDelAccount = async () => {
    await axios
      .delete(
        AXIOS_BASE_URL +
          '/forwardingLog/' +
          daerimReducer.deleteContent.forwardingLogId,
        {
          headers: {
            Authorization: getCookie('accessToken'),
          },
        },
      )
      .then((response) => {
        if (response === undefined) return;
        console.log(response);
        if (response.status === 200) {
          alert('공정이동표를 삭제했습니다.');
          navigate(pageReducer.currentPage, { replace: true });
        }
      })
      .catch((error) => {
        console.log('deleteForwardingLog-error : ', error);
      });
  };

  /* ====================================================================== #5 */

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body
      contents={
        <>
          <main className="Main">
            <Nav
              thisTitle={'공정이동표'}
              content={''}
              nav={''}
              search={''}
              searchButton={''}
              searchOptions={''}
              statusOptions={''}
            />

            <DeleteForm
              delteTitle={<>공정이동표</>}
              deleteItem={
                <>
                  <p>
                    {'전달팀 : ' + daerimReducer.deleteContent.deliveryTeam ||
                      ''}
                  </p>
                  <p>
                    {'전달자 : ' +
                      daerimReducer.deleteContent.deliveryUser.name || ''}
                  </p>
                  <p>
                    {'수신팀 : ' + daerimReducer.deleteContent.receivingTeam ||
                      ''}
                  </p>
                  {/* <p>{ '수신자 : ' + daerimReducer.deleteContent.receivingUser && daerimReducer.deleteContent.receivingUser }</p> */}
                  {/* <p>{ '품관 : ' + daerimReducer.deleteContent.qualityCheckUser && daerimReducer.deleteContent.qualityCheckUser.name || '' }</p> */}
                </>
              }
              deleteText={<>해당 모든 공정이동표가 삭제됩니다.</>}
              deleteButton={
                <>
                  <button
                    className="formButton cancle"
                    onClick={() => {
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                  <button
                    className="formButton delete"
                    onClick={() => {
                      if (window.confirm(' 공정이동표를 삭제합니다.'))
                        actDelAccount();
                      else return;
                    }}
                  >
                    삭제
                  </button>
                </>
              }
            />
          </main>
        </>
      }
    />
  );
};

export default DeleteDLMovement;
