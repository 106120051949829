import React from 'react';
import styled from 'styled-components';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';

const GridBody = styled.div`
  display: block;
  grid-template-rows: unset;
  height: 100svh;
  overflow: hidden;
  position: relative;
  width: 100svw;
`;

const GridNonBodyGS = (props) => {
  return (
    <>
      <GridBody>
        {props.header}
        {props.contents}
      </GridBody>
      <Footer footerStyle={props.footerStyle} />
    </>
  );
};

export default GridNonBodyGS;