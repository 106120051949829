import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { fileDataApi } from 'api/apis/fileDataApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 40px 50px;
  height: 85%;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
`;
const FileSection = styled.div`
  box-sizing: border-box;
  display: flex; 
  flex-wrap: wrap; 
  gap: 24px; 
  justify-content: center;
  padding: 20px 0px;
  width: 100%;
`;
const FileContent = styled.div`
  border: 1px solid var(--gray-200);
  border-radius: 10px;
  display: grid;
  gap: 10px;
  justify-content: center;
  padding: 15px;
  width: fit-content;

  & input[type="checkbox"] {cursor: pointer;}
  & label {
    cursor: pointer;
    display: grid;
    gap: 10px;
    justify-items: center;
    margin: 0px auto;
    width: 180px;

    & img {max-height: 200px; max-width: 180px;}
  }

  &:hover {background-color: var(--gray-100)}
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const SelectDrawingModal = (props) => {
  /* ========================================================================= #1 */
  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_fileList, setFileList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_searchOption, setSearchOption] = useState('fileDataName');
  const [_searchValue, setSearchValue] = useState('');

  const [_pageNum, setPageNum] = useState(1);
  const [_pageCount, setPageCount] = useState(0);

  /* ========================================================================= #3 */
  useEffect(() => {
    getFileList(0);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getFileList(_pageNum - 1);
    }

    return () => { };
  }, [_pageNum]);

  /* ========================================================================= #4 */
  const getFileList = async (page) => {
    const paging = `?page=${page}&size=30`;
    const BodyToPost = {
      companyId: 2,
      fileCategoryId: 121,
      // fileDataClass: 'drawing',
    };
    if (checkEmptyNull(_searchOption, false) && checkEmptyNull(_searchValue, false)) {
      BodyToPost.fileDataName = _searchValue;
    }
    console.log('BodyToPost : ', BodyToPost);

    await fileDataApi.searchFileData(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('fileDataApi.searchFileData : ', response);
      setFileList(() => { return response.data.content });
      setPageCount(() => { return response.data.totalElements });
    })
  };

  /* ========================================================================= #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) {
      checkedItem.add(id);
    }
    setCheckedItem(() => { return checkedItem });
  };

  const actSearch = async () => {
    setPageNum(() => { return 1 });
    await getFileList(0);
  };

  const actSelect = () => {
    const checkedList = Array.from(_checkedItem);
    const checkedContentIndex = _fileList.findIndex((fileData) => fileData.fileDataId === checkedList[0]);
    if (checkedContentIndex === -1) return;
    const checkedContent = _fileList[checkedContentIndex];
    console.log('checkedContent : ', checkedContent);

    props.fileEvent(checkedContent);
    props.close();
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>도면 선택</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <NavBar
                title={
                  <>
                    <div className="ContentCBox">
                      <div className="ContentCBox">
                        <select className="SearchOption detail">
                          <option value="fileDataName">도면이름</option>
                        </select>

                        <input
                          type="text"
                          className='SearchBar'
                          placeholder="Search..."
                          value={_searchValue}
                          onChange={e => { setSearchValue(() => { return e.target.value }) }}
                        />
                      </div>

                      <button id="searchButton" onClick={actSearch}><SearchButtonIcon /></button>
                    </div>
                  </>
                }
                nav={''}
              />
              <FileSection>
                {checkNullArray(_fileList, []).map((fileData, index) => {
                  return <FileContent key={index + '_fileDatas'}>
                    <input type="checkBox" name="fileDatas" id={index + '_fileDatas'}
                      checked={(() => {
                        const checkedId = _checkedItem ? Array.from(_checkedItem).findIndex((fileDataId) => fileDataId === fileData.fileDataId) : -1;
                        if (checkedId !== -1) return true;
                        else return false;
                      })()}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        handleCheckedItem(fileData.fileDataId, isChecked);
                      }}
                      onClick={(e) => { e.stopPropagation() }}
                    />
                    <label htmlFor={index + '_fileDatas'}>
                      <img src={fileData.fileDataS3URL} alt={checkEmptyNull(fileData.fileDataName, '')} />
                      <p>{checkEmptyNull(fileData.fileDataName, '')}</p>
                    </label>
                  </FileContent>
                })}
              </FileSection>
            </ModalMain>

            <PagingComponent
              page={_pageNum}
              count={_pageCount}
              size={30}
              pageEvent={(page) => {
                console.log('page : ', page);
                setPageNum(() => { return page });
              }}
            />

            <ModalFooter>
              <ModalButton onClick={actSelect}>도면 선택</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default SelectDrawingModal;
