import styled from 'styled-components';

const MallFooter = styled.div`
  /* box-shadow: 0px 4px 24px 2px rgb(0 0 0 / 10%); */
  font-size: .875rem;
  bottom: 0px;
  color: #888;
  padding-block: 10px;
  position: fixed;
  text-align: center;
  width: 100%;
`
export default function Footer() {
  return (
    <MallFooter>
      <p>ⓒ DKLAB All Rights Reserved.</p>
    </MallFooter>
  )
}