import React from 'react';

import { IoClose } from "react-icons/io5";
import { ProjectFormModalComponent } from './ProjectFormModal.style';
import ProjectType from './ProjectType';
import Project from './Project';

const ProjectFormModal = ({
  modalType, modalData,
  update,
  open, close
}) => {

  const handleModalTitle = (type) => {
    switch (type) {
      case 'type-create': return '유형 추가';
      case 'type-update': return '유형 수정';
      case 'type-delete': return '유형 삭제';
      case 'project-create': return '일정 추가';
      case 'project-copy': return '일정 복사';
      case 'project-update': return '일정 수정';
      case 'project-delete': return '일정 삭제';

      default: return '';
    }
  }
  const handleModalContent = (type) => {
    if (type.split('-')[0] === 'type') {
      return (
        <ProjectType
          modalType={modalType}
          modalData={modalData}
          update={update}
          close={close}
        />
      )
    } else {
      return (
        <Project
          modalType={modalType}
          modalData={modalData}
          update={update}
          close={close}
        />
      )
    }
  }

  return (
    <>
      {open && (
        <ProjectFormModalComponent>
          <header>
            <h1>{handleModalTitle(modalType)}</h1>
            <button className='btn-close' onClick={close}><IoClose /></button>
          </header>
          <div>
            {handleModalContent(modalType)}
          </div>
        </ProjectFormModalComponent>
      )}
    </>
  );
};

export default ProjectFormModal;