import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getCookie } from 'preferences/cookie/cookie';
import { any } from 'api/apis/any/any';
import { userApi } from 'api/apis/userApi';

import { EmailInput } from 'mall/styles/style';

const MallMypageInfo = () => {
  const { userReducer } = useSelector(state => state);

  const [_currentMenu, setCurrentMenu] = useState('info');

  const [_formData, setFormData] = useState({ ...userReducer.user });

  const [_emailStatus, setEmailStatus] = useState(true);

  const [_pwStatus, setPwStatus] = useState(false);
  const [_pw, setPw] = useState('');
  const [_confirmPw, setConfirmPw] = useState('');

  useEffect(() => {
    getMallUserData();

    return () => { };
  }, []);

  useEffect(() => { }, [_currentMenu]);
  useEffect(() => { checkPw(); return () => { } }, [_pw, _confirmPw]);

  // 몰 사용자 정보 가져오기
  const getMallUserData = async () => {
    await any.getMyData(getCookie('accessToken')).then((response) => {
      if (response === undefined) return;
      console.log('any.getMyData : ', response);
      setFormData(() => { return { ...response.data }; });
    });
  };

  // 이메일 체크
  const checkEmail = (email) => {
    const reg = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
    if (!reg.test(email)) return false; // 불통
    else return true; // 통과
  };

  // 전화번호 형식 변환
  const makePhoneNumber = (e) => {
    const phoneNumber = e.target.value.toString().replace(/[^0-9]/g, '').replace(/(^02.{0}|^01.{1}|[0-9]{3,4})([0-9]{3,4})([0-9]{4})/g, '$1-$2-$3');
    setFormData((prev) => { return { ...prev, phoneNumber: phoneNumber }; });
  };

  // 비밀번호 체크
  const checkPw = () => {
    const reg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
    setPwStatus(() => {
      if (!reg.test(_pw)) return false; // 불통
      else if (_pw !== _confirmPw) return false; // 불통
      else return true; // 통과
    });
  };

  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value }; });
  };

  // 정보 업데이트
  const actUpdate = async (e) => {
    e.preventDefault();
    if (_formData.name.length === 0) return alert('이름을 확인해주세요.');
    if (_formData.email.length === 0 || !checkEmail(_formData.email)) return alert('이메일을 확인해주세요.');
    if (_formData.phoneNumber.length === 0) return alert('연락처를 확인해주세요.');
    const BodyToPut = { ..._formData };
    await any.updateMyData(getCookie('accessToken'), BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('any.updateMyData : ', response);
      alert('내정보를 수정했습니다.');
    });
  };
  // 비밀번호 변경 > 변경 안 됨
  const actPwUpdate = async (e) => {
    e.preventDefault();
    if (_pw.length < 8 || !_pwStatus) return alert('비밀번호를 확인해주세요.');
    const reg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
    if (!reg.test(_pw)) return alert('비밀번호는 최소 8자리 이상 영문 + 숫자 + 특수기호 조합으로 등록하셔야 합니다.');

    const body = { password: _pw }
    await userApi.updatePassword(userReducer.user.id, body).then(response => {
      if (response === undefined) return;
      console.log('userApi.updatePassword : ', response);
      alert('비밀번호를 변경했습니다.');
    })
  };

  return (
    <div className='mypage-info-area'>
      <div className='mypage-info-area-subnav'>
        <button
          className={_currentMenu === 'info' ? 'active' : ''}
          onClick={() => { setCurrentMenu(() => { return 'info'; }); }} >
          내정보 수정
        </button>
        <button
          className={_currentMenu === 'pw' ? 'active' : ''}
          onClick={() => { setCurrentMenu(() => { return 'pw'; }); }} >
          비밀번호 변경
        </button>
      </div>
      {/* 내정보 수정 */}
      {_currentMenu === 'info' ? (
        <>
          <ul className='form'>
            <li>
              <p>이름</p>
              <input
                type="text" name="name" placeholder="이름을 입력해주세요."
                value={_formData.name} onInput={handleInputEvent}
              />
            </li>
            <li>
              <p>이메일</p>
              <EmailInput readOnly type="text" name="email" placeholder="이메일을 입력해주세요." data-status={_emailStatus} value={_formData.email} />
            </li>
            <li>
              <p>연락처</p>
              <input type="text" name="phoneNumber" placeholder="- 를 제외하고 입력해주세요. (ex 01012345678)" value={_formData.phoneNumber} onInput={makePhoneNumber} />
            </li>
            <li>
              <p>우편번호</p>
              <input type="text" name="zip" placeholder="우편번호를 입력해주세요." value={_formData.zip} onInput={handleInputEvent} />
            </li>
            <li>
              <p>주소</p>
              <textarea type="text" name="address" placeholder="주소를 입력해주세요." value={_formData.address} onInput={handleInputEvent} />
            </li>
          </ul>
          <button className="submit" onClick={actUpdate}>저장</button>
        </>
      ) : null}
      {/* 비밀번호 변경 */}
      {_currentMenu === 'pw' ? (
        <>
          <ul className='form pwchange'>
            <li>
              <p>새 비밀번호</p>
              <input required
                type="password" name="password" placeholder="새 비밀번호"
                data-status={_pwStatus} value={_pw} minLength={8}
                onInput={(e) => { const pw = e.target.value; setPw(() => { return pw; }); }}
                onMouseOver={(e) => { e.stopPropagation(); e.target.type = 'text'; }}
                onMouseOut={(e) => { e.stopPropagation(); e.target.type = 'password'; }}
              />
            </li>
            <li>
              <p>새 비밀번호 확인</p>
              <input required
                type="password" name="confirmPassword" placeholder="새 비밀번호 확인"
                data-status={_pwStatus} value={_confirmPw} minLength={8}
                onInput={(e) => { const pw = e.target.value; setConfirmPw(() => { return pw; }); }}
                onMouseOver={(e) => { e.stopPropagation(); e.target.type = 'text'; }}
                onMouseOut={(e) => { e.stopPropagation(); e.target.type = 'password'; }}
              />
            </li>
            <p data-status={_pwStatus}>비밀번호 변경은 최소 8자리 이상 <span>영문 + 숫자 + 특수기호</span> 조합으로 등록해주세요.</p>
          </ul>
          <button className='submit' onClick={actPwUpdate}>변경</button>
        </>
      ) : null}
    </div>
  );
};

export default MallMypageInfo;
