const initialState = {
  materialFilter: 'all',
  halfFilter: 'all',
  productFilter: 'all',

  productionFilter: 'all',

  monitoringView: 'all',
};

const msReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETMSMATERIALFILTER':
      return {
        ...state,
        materialFilter: action.payload,
      };

    case 'SETMSHALFFILTER':
      return {
        ...state,
        halfFilter: action.payload,
      };

    case 'SETMSPRODUCTFILTER':
      return {
        ...state,
        productFilter: action.payload,
      };

    case 'SETMSPRODUCTIONFILTER':
      return {
        ...state,
        productionFilter: action.payload,
      };

    case 'SETMSMONITORINGVIEW':
      return {
        ...state,
        monitoringView: action.payload,
      };

    default:
      return state;
  }
};

export default msReducer;
