import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

import { getCookie } from 'preferences/cookie/cookie';

const TestSection = styled.section`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100svh;
  width: 100svw;

  & div {
    display: grid;
    gap: 20px;
    grid-template-rows: 50px 50px auto 50px;
    height: 100%;
    max-height: 500px;
    max-width: 400px;
    width: 100%;
  }

  & select, & input, & textarea {
    box-sizing: border-box;
    padding: 10px;
  }
`;

const TestApi = () => {
  const [_method, setMethod] = useState();
  const [_url, setUrl] = useState();
  const [_headers, setHeaders] = useState({headers: {Authorization: getCookie('accessToken')}});
  const [_body, setBody] = useState();

  const testApi = async(e) => {
    e.preventDefault();
    
    const body = JSON.parse(_body);
    console.log({
      _method: _method,
      _url: _url,
      _headers: _headers,
      _body: body,
    })

    switch(_method) {
      case 'GET':
        await axios.get(_url, body, _headers).then(response => {
          if (response === undefined) return;
          console.log('response - GET : ', response);
        }).catch(error => {
          console.log('error - GET : ', error);
        })
        return;
      case 'POST':
        await axios.post(_url, body, _headers).then(response => {
          if (response === undefined) return;
          console.log('response - POST : ', response);
        }).catch(error => {
          console.log('error - POST : ', error);
        })
        return;
      case 'PUT':
        await axios.put(_url, body, _headers).then(response => {
          if (response === undefined) return;
          console.log('response - PUT : ', response);
        }).catch(error => {
          console.log('error - PUT : ', error);
        })
        return;
      case 'DELETE':
        await axios.delete(_url, body, _headers).then(response => {
          if (response === undefined) return;
          console.log('response - DELETE : ', response);
        }).catch(error => {
          console.log('error - DELETE : ', error);
        })
        return;

      default: return;
    }
  }

  return (
    <TestSection>
      <div>
        <select 
          onChange={(e) => {
            e.preventDefault();
            setMethod(() => {return e.target.value});
          }}
        >
          <option value={''}>method</option>
          {['GET', 'POST', 'PUT', 'DELETE'].map(method => {
            return <option key={method} value={method}>{method}</option>
          })}
        </select>

        <input 
          type='text' 
          placeholder='url'
          onInput={(e) => {
            e.preventDefault();
            setUrl(() => {return e.target.value});
          }} 
        />

        <textarea 
          placeholder='body'
          onInput={(e) => {
            e.preventDefault();
            setBody(() => {return e.target.value});
          }} 
        />
        <button className='btn-inven-1' onClick={testApi}>테스트</button>
      </div>
    </TestSection>
  );
};

export default TestApi;