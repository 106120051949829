import styled from "styled-components";

export const PriceTypeManagementComponent = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;

  & section {
    background-color: var(--white);
    border-radius: 10px;
    display: grid;
    grid-template-rows: 55px auto 50px;
    height: 85%;
    max-width: 1200px;
    overflow: hidden;
    width: 85%;

    & header {
      align-items: center;
      background-color: var(--gray-100);
      box-sizing: border-box;
      display: flex;
      height: 55px;
      justify-content: flex-start;
      padding: 0px 20px;
      position: relative;
      width: 100%;

      h4 {
        font-size: 20px;
        font-weight: 400;
        white-space: nowrap;
      }
      & .btn-close {
        height: 25px;
        position: absolute;
        right: 15px;
        top: 15px;
        width: 25px;
      }
    }

    &>div {
      display: grid;
      grid-template-rows: 60px calc(100% - 60px);
      height: 100%;
      width: 100%;

      nav {
        align-items: center;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        padding-inline: 20px;

        & div {
          display: flex;
          gap: 5px;
        }
      }

      & .TableSection {
        grid-row-start: 2;
        grid-row-end: 4;
        margin: auto;
        width: fit-content;
        & table {
          min-width: unset;
          width: fit-content;

          th, td {
            border: none;
            font-size: 18px;
            min-width: unset;
            padding: 18px 36px;
            text-align: left;
          }

          th {
            background-color: #EDF1F5;
            color: var(--Text);
          }
      
          td {
            background: unset;

            & input[type="radio"] {
              cursor: pointer;
              transform: scale(1.2);
            }
          }

          & tbody tr {
            box-sizing: border-box;
            border-bottom: 1px solid #99B0CB;
          }
        }
      }
    }
  }
`;