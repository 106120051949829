import React from 'react';

import { FileModalComponent } from './FileModal.style';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';

import CreateContent from './content/CreateContent';
import UpdateContent from './content/UpdateContent';
import DeleteContent from './content/DeleteContent';
import StlContent from './content/StlContent';

const FileModal = ({
  open, close,
  modalType,
  modalData,
}) => {
  const translateType = (type) => {
    switch(type) {
      case 'create': return '파일 추가';
      case 'update': return '파일 정보 수정';
      case 'delete': return '파일 삭제';
      case 'stl': return 'stl 파일뷰어';

      default: return '';
    }
  }
  const typeContent = (type) => {
    switch(type) {
      case 'create': return <CreateContent close={close} modalData={modalData} />;
      case 'update': return <UpdateContent close={close} modalData={modalData} />;
      case 'delete': return <DeleteContent type={modalType} close={close} modalData={modalData} />;
      case 'stl': return <StlContent type={modalType} close={close} modalData={modalData} />;

      default: return <></>;
    }
  }

  return (
    <>
    {open && (
      <FileModalComponent>
        <div className={modalType}>
          <header>
            <h4>{translateType(modalType)}</h4>
            <div onClick={() => {close();}}><CloseButtonIcon /></div>
          </header>
          {typeContent(modalType)}
        </div>
      </FileModalComponent>
    )}
    </>
  );
};

export default FileModal;