import React from 'react';
import { Outlet } from 'react-router-dom';

import { APIInterceptor } from 'api/core';
import { WSHandler } from 'api/core/WSHandler';

const RouteLayout = ({ children }) => {
  return (<>
    <APIInterceptor></APIInterceptor>
    <WSHandler></WSHandler>
    <Outlet {...children} />
  </>);
};

export default RouteLayout;
