import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray, checkNullParse } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: space-between;
  padding: 0px 60px 0px 20px;
  position: relative;
  width: 100%;
  & button {height: fit-content; padding: 8px 16px; width: fit-content;}
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
  }
`;
const MainContents = styled.div`
  box-sizing: border-box;
  height: 100%;
  justify-content: center;
  padding: 10px;
  width: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {height: 8px; width: 8px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 4px;}
`;

const ExportSection = styled.div`
  box-sizing: border-box;
  margin: 30px auto;
  padding: 0px;
  height: 297mm;
  width: 210mm;
`;
const ShipmentSection = styled.div`
  box-sizing: border-box;
  width: 100%;
`;
const ShipmentHeader = styled.div`
  display: grid;
  grid-template-rows: 40px 30px;
`;
const HeaderTitle = styled.div`
  align-items: center;
  column-gap: 80px;
  display: flex;
  justify-content: center;
  & p {font-size: 28px; font-weight: 500; letter-spacing: 12px;}
`;
const HeaderContents = styled.div`
  align-items: center;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
`;
const HeaderCon = styled.div``;
const LayoutBox = styled.div`
  display: flex;
  justify-content: center;
`;
const ReceiverArea = styled.div`
  box-sizing: border-box;
  font-size: 1.2em;
  padding: 0px 70px;
`;
const ShipmentTable = styled.div`
  width: 100%;

  table {
    border-collapse: collapse;
    max-width: 100%;
    width: 100%;

    td, th {
      border: 1px solid var(--gray-900);
      box-sizing: border-box;
      font-size: 0.7em;
      font-weight: 400;
      height: 30px;
      padding: 0px 3px;
      text-align: center;
      white-space: nowrap;
      &.InfoHeader {max-width: 60px; min-width: 60px; width: 60px;}
    }
    thead td, tbody td {max-height: 35px; white-space: unset; word-break: break-all;}
    tfoot tr {height: 40px;}
    tfoot td {font-size: 0.9em; font-weight: 600; height: 40px;}
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
  &:first-child {background-color: var(--ThirdBlue);}
`;

const LoadingScreen = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  color: var(--white);
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const PrintContent = (props) => {
  const PringPage = `${props.currentPage} / ${props.totalPage}`;
  const PrintDatas = props.content;
  const PrintInfo = props.info;
  const TWStatus = props.tw;

  const setQuantityDataList = PrintDatas.map((thisData) => { return checkEmptyNull(thisData.customizedContent.quantity, 0) * 1; });
  const totalQuantity = setQuantityDataList.reduce((prv, curr) => BigNumber(checkEmptyNull(prv, 0)).plus(BigNumber(checkEmptyNull(curr, 0))).toNumber(), 0);

  const setAmountDataList = PrintDatas.map((thisData) => { return checkEmptyNull(thisData.amount, 0) * 1 });
  const totalAmount = setAmountDataList.reduce((prv, curr) => BigNumber(checkEmptyNull(prv, 0)).plus(BigNumber(checkEmptyNull(curr, 0))).toNumber(), 0);

  const setTW = PrintDatas.map((thisData) => { return (checkEmptyNull(thisData.customizedContent.tw, 0) * 1) });
  const totalTW = setTW.reduce((prv, curr) => BigNumber(checkEmptyNull(prv, 0)).plus(BigNumber(checkEmptyNull(curr, 0))).toNumber(), 0);

  const emptyDatas = [];
  for (let i = 0; i < parseFloat(23 - PrintDatas.length); i++) {
    const setEmptyData = { emptyNum: parseFloat(PrintDatas.length + 1 + i) };
    emptyDatas.push(setEmptyData);
  }

  return (
    <ExportSection name={props.name}>
      <ShipmentSection>
        <ShipmentHeader>
          <HeaderTitle><p>거래명세서</p></HeaderTitle>
          <HeaderContents>
            <HeaderCon>{PringPage}</HeaderCon>
            <HeaderCon>{moment().format('YYYY년 MM월 DD일(ddd)')}</HeaderCon>
          </HeaderContents>
        </ShipmentHeader>

        <ShipmentTable>
          <table>
            <tbody>
              <tr>
                <td colSpan="9"><LayoutBox><ReceiverArea>□ 공급자용</ReceiverArea><ReceiverArea>□ 공급받는자용</ReceiverArea></LayoutBox></td>
              </tr>
              <tr>
                <td className="InfoHeader" rowSpan="5">공급자</td>
              </tr>
              <tr>
                <td className="InfoHeader">상호</td>
                <td colSpan="2">{PrintInfo.supplierCompanyName}</td>
                <td className="InfoHeader" rowSpan="5">공급처</td>
                <td className="InfoHeader">현장명</td>
                <td colSpan="3">{PrintInfo.supplyPlaceName}</td>
              </tr>
              <tr>
                <td className="InfoHeader">대표이사</td>
                <td colSpan="2">{PrintInfo.supplierName}</td>
                <td className="InfoHeader">상호</td>
                <td colSpan="3">{PrintInfo.supplyCompanyName}</td>
              </tr>
              <tr>
                <td className="InfoHeader">주소</td>
                <td colSpan="2">{PrintInfo.supplierAddress}</td>
                <td className="InfoHeader">주소</td>
                <td colSpan="3">{PrintInfo.supplyAddress}</td>
              </tr>
              <tr>
                <td className="InfoHeader">출하담당</td>
                <td colSpan="2">{PrintInfo.supplierCharge}</td>
                <td className="InfoHeader">담당</td>
                <td colSpan="3">{PrintInfo.supplyCharge}</td>
              </tr>
              <tr>
                <td className="InfoHeader" rowSpan="4">운전자</td>
              </tr>
              <tr>
                <td className="InfoHeader">성명</td>
                <td colSpan="2">{PrintInfo.driverName}</td>
                <td className="InfoHeader" rowSpan="4">담당자</td>
                <td className="InfoHeader">성명</td>
                <td colSpan="3">{PrintInfo.chargeName}</td>
              </tr>
              <tr>
                <td className="InfoHeader">차량번호</td>
                <td colSpan="2">{PrintInfo.carNumber}</td>
                <td className="InfoHeader">TEL</td>
                <td colSpan="3">{PrintInfo.chargeNumber}</td>
              </tr>
              <tr>
                <td className="InfoHeader">H.P</td>
                <td colSpan="2">{PrintInfo.driverHP}</td>
                <td className="InfoHeader">H.P</td>
                <td colSpan="3">{PrintInfo.chargeHP}</td>
              </tr>
            </tbody>
          </table>
        </ShipmentTable>

        <ShipmentTable>
          <table>
            <tbody>
              <tr>
                <th>NO</th>
                <th style={{ maxWidth: '120px', width: '120px' }}>ITEM</th>
                <th style={{ maxWidth: '120px', width: '120px' }}>MEMB SIZE</th>
                <th>LENGTH</th>
                <th>Q'ty</th>
                {TWStatus ? <th style={{ maxWidth: '120px', width: '120px' }}>U/W</th> : null}
                <th>Q'ty</th>
                {TWStatus ? <th style={{ maxWidth: '120px', width: '120px' }}>T/W(kg)</th> : null}
                <th>금액</th>
                <th>비고</th>
              </tr>

              {PrintDatas.map((shipmentElement, index) => {
                const parseCustomizedContent = shipmentElement.customizedContent;
                return (
                  <tr key={index + '_ShipmentEl'}>
                    <td>{(index + 1).toString().padStart(2, '0')}</td>
                    {[
                      { name: 'item', type: 'text', custom: true, style: { maxWidth: '120px', width: '120px' } },
                      { name: 'membSize', type: 'text', custom: true, style: { maxWidth: '120px', width: '120px' } },
                      { name: 'length', type: 'number', custom: true, },
                      { name: 'quantity', type: 'number', custom: true, },
                      { name: 'uw', type: 'number', custom: true, style: { maxWidth: '120px', width: '120px' } },
                      { name: 'amount', type: 'number', custom: false, },
                      { name: 'tw', type: 'number', custom: true, style: { maxWidth: '120px', width: '120px' } },
                      { name: 'price', type: 'text', custom: false, },
                      { name: 'note', type: 'text', custom: false, },
                    ].map((customData, customIndex) => {
                      const customVlaue = customData.custom ?
                        checkEmptyNull(parseCustomizedContent[customData.name], '')
                        : checkEmptyNull(shipmentElement[customData.name], '');
                      let value = customVlaue;
                      if (customData.type === 'number') {
                        const customNumber = customVlaue.toString().replace(/[^-\.0-9]/g, "") * 1;
                        const returnNumber = !isNaN(customNumber) ? customNumber.toLocaleString() : '';
                        value = returnNumber;
                        if (returnNumber === 0 || returnNumber === '0') value = '';
                      }

                      if ((!TWStatus && customData.name === 'uw') || (!TWStatus && customData.name === 'tw')) {
                        return null;
                      } else {
                        return (<td key={index + '_custom_' + customIndex} style={customData.style || {}}>{value}</td>);
                      }
                    })}
                  </tr>
                );
              })}
              {emptyDatas.map((item, index) => {
                return (
                  <tr key={item.emptyNum + '_emptyRow'}>
                    <td>{(item.emptyNum).toString().padStart(2, '0')}</td>
                    <td style={{ maxWidth: '120px', width: '120px' }}></td>
                    <td style={{ maxWidth: '120px', width: '120px' }}></td>
                    <td></td>
                    <td></td>
                    {TWStatus ? <td style={{ maxWidth: '120px', width: '120px' }}></td> : null}
                    <td></td>
                    {TWStatus ? <td style={{ maxWidth: '120px', width: '120px' }}></td> : null}
                    <td></td>
                    <td></td>
                  </tr>
                );
              })}
            </tbody>

            <tfoot>
              <tr>
                <td colSpan="4" style={{ letterSpacing: '5px' }}>합계</td>
                <td>{totalQuantity.toLocaleString()}</td>
                {TWStatus ? <td style={{ maxWidth: '120px', width: '120px' }}></td> : null}
                <td>{totalAmount.toLocaleString()}</td>
                {TWStatus ? <td style={{ maxWidth: '120px', width: '120px' }}>{totalTW.toLocaleString()}</td> : null}
                <td></td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </ShipmentTable>
      </ShipmentSection>
    </ExportSection>
  );
};

const GSInvoicePdfModal = (props) => {
  /* ========================================================================= #1 */
  const { contentsReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_printShipmentArray, setPrintShipmentArray] = useState([]);

  const [_originArray, setOriginArray] = useState([]);
  const [_printElementArray, setPrintElementArray] = useState([]);

  const [_addClassStatus, setAddClassStatus] = useState(false); // U/W T/W 숨기기

  const [_loadingStatus, setLoadingStatus] = useState(false);

  /* ========================================================================= #3 */
  useEffect(() => {
    let shipmentCustomizedContent = contentsReducer.updateContents.customizedContent;
    if (checkNullParse(contentsReducer.updateContents.customizedContent, false)) {
      shipmentCustomizedContent = checkNullParse(contentsReducer.updateContents.customizedContent, {})
    }
    setPrintShipmentArray(shipmentCustomizedContent);

    const printShipmentElementList = contentsReducer.updateContents.shipmentElementList.map((thisResult) => {
      let elementCustomizedContent = {
        item: '',
        membSize: '',
        length: '',
        quantity: '',
        uw: '',
        tw: '',
      };
      if (checkNullParse(thisResult.customizedContent, false)) {
        elementCustomizedContent = checkNullParse(thisResult.customizedContent, elementCustomizedContent);
      }
      return {
        amount: thisResult.amount,
        shipmentElementId: thisResult.shipmentElementId,
        customizedContent: elementCustomizedContent,
        note: thisResult.note,
      };
    });

    const thisContent = [];
    if (!checkNullArray(printShipmentElementList, false)) return;
    for (let i = 0; i < printShipmentElementList.length; i += 23) {
      thisContent.push(printShipmentElementList.slice(i, i + 23));
    }
    setOriginArray(printShipmentElementList);
    setPrintElementArray(thisContent);
  }, []);

  /* ========================================================================= #4 */
  /* ========================================================================= #5 */
  const actTWOnOff = () => {
    if (!_addClassStatus) setAddClassStatus(true);
    else setAddClassStatus(false);
  };

  const actPrintShipment = () => {
    console.log(document.getElementsByName('PrintContents'));
    const style = document.createElement('style');
    document.head.appendChild(style);
    style.sheet?.insertRule('body > div:last-child img { display: inline-block; }');

    const doc = new jsPDF({ orientation: 'p', unit: 'mm', format: 'a4' });
    let count = 0;
    document.getElementsByName('PrintContents').forEach(async (thisItem) => {
      const thisHeight = thisItem.offsetHeight;
      const thisWidth = thisItem.offsetWidth;
      const ratio = thisWidth / thisHeight;
      const option = { height: thisHeight, width: thisWidth };
      await html2canvas(thisItem, option).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        let width = doc.internal.pageSize.getWidth();
        let height = 280;
        width = ratio * height;
        const margin = 6;
        const position = 6;
        doc.addImage(imgData, 'jpeg', margin, position, width, height);
        if (count < document.getElementsByName('PrintContents').length - 1) doc.addPage();
      });

      count++;
      if (count === document.getElementsByName('PrintContents').length) {
        if (document.getElementsByName('PrintContents').length > 10) {
          doc.save();
          style.remove();
          setLoadingStatus(false);
        } else {
          window.open(doc.output('bloburl'));
          style.remove();
          setLoadingStatus(false);
        }
      }
    });
  };

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>거래명세서 미리보기</ModalTitle>
              <button className='btn-inven-1' onClick={actTWOnOff}>U/W & T/W</button>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <MainContents>
                {_printElementArray.map((printElement, index) => {
                  return (
                    <PrintContent
                      key={index + '_PrintContents'}
                      name="PrintContents"
                      totalPage={_printElementArray.length}
                      currentPage={index + 1}
                      content={printElement}
                      info={_printShipmentArray}
                      tw={_addClassStatus}
                    />
                  );
                })}
              </MainContents>
            </ModalMain>

            <ModalFooter>
              <ModalButton onClick={() => { setLoadingStatus(true); setTimeout(actPrintShipment, 1000); }}>출력</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
      {_loadingStatus === true ? (<LoadingScreen>파일 생성 중입니다...</LoadingScreen>) : null}
    </>
  );
};

export default GSInvoicePdfModal;
