import React, { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment';

import { sensorDataApi } from 'api/apis/sensorDataApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

const Today = () => {
  const TodayContent = useRef();
  const TodayChart = useRef();

  const options = {
    chart: {
      id: moment().format('YYYY-MM-DD') + '_실시간_에너지_사용_현황',
      toolbar: { show: true },
    },
    stroke: { curve: 'smooth' },
    grid: {
      xaxis: { lines: { show: false } },
      yaxis: { lines: { show: true } },
      padding: { right: 30 }
    },
    dataLabels: { enabled: false },
    xaxis: {
      type: '시간',
      categories: [],
      tickPlacement: 'on',
    },
    yaxis: {
      min: 0,
      tickAmount: 5,
      labels: { formatter: (val) => { return checkEmptyNull(val, 0).toLocaleString() + ' kWh' } }
    }
  };
  const [_options, setOptions] = useState(options);

  const series = {
    name: '전력',
    data: [],
  };
  const [_series, setSeries] = useState([series]);



  useEffect(() => { getSensorData(); return () => { } }, []);
  useEffect(() => { }, [_options, _series]);

  const [_height, setHeight] = useState(250);
  useEffect(() => {
    const handleResize = () => { setHeight(() => { return TodayContent.current.offsetHeight - 50 }); };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize); }
  }, []);

  const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    useEffect(() => { savedCallback.current = callback; }, [callback]);
    useEffect(() => {
      const tick = () => { savedCallback.current(); };
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };
  useInterval(async () => { await getSensorData(); }, 600000);

  const getSensorData = async () => {
    const body = {
      companyId: 3,
      startDate: moment().startOf('date').add(9, 'hour').toISOString()?.replace(/\.\d+Z/, ''),
      endDate: moment().add(9, 'hour').toISOString()?.replace(/\.\d+Z/, ''),
      name: 'power',
    }
    const search = `?companyId=${body.companyId}&startDate=${body.startDate}&endDate=${body.endDate}&name=${body.name}`;
    await sensorDataApi.getSensorDatas(search).then(response => {
      if (response === undefined) return;
      console.log('sensorDataApi.getSensorDatas : ', response);
      const array = response.data;
      const result = {};
      array.forEach(data => {
        const date = moment(data.date).format('YYYY-MM-DD HH');
        // const date = moment(data.date).subtract(9, 'hour').format('YYYY-MM-DD HH');
        if (!checkNullArray(result[date], false)) {
          result[date] = [];
          result[date].push(data);
        } else {
          result[date].push(data);
        }
      })
      const returnDataList = Object.keys(result).map(hourData => {
        const data = result[hourData];
        // const valueList = data.map(item => Math.floor(Math.random() * 10));
        const valueList = data.map(item => item.value);
        const average = (valueList.reduce((a, b) => (a * 1) + (b * 1), 0)) / valueList.length;
        const returnData = { date: hourData, value: average.toFixed(0) };
        return returnData;
      });
      returnDataList.reverse();
      const sortCategories = returnDataList.sort((a, b) => new Date(a.date) - new Date(b.date));
      setOptions((prev) => {
        const categories = sortCategories.map(item => moment(item.date).format('HH:00'));
        const returnData = {
          ...prev,
          xaxis: { ...prev.xaxis, categories: categories }
        };
        for (let i = returnDataList.length; i < 25; i++) {
          returnData.xaxis.categories.push(`${i.toString().padStart(2, '0')}:00`);
        }
        return returnData;
      })
      setSeries(() => {
        const returnData = { ...series, data: sortCategories.map(item => checkEmptyNull(item.value, 0).toLocaleString()) };
        for (let i = returnDataList.length; i < 25; i++) {
          returnData.data.push(0);
        }
        console.log('returnData : ', returnData);
        return [returnData];
      });
    })
  }

  return (
    <div ref={TodayContent} className='Today'>
      <h4>{moment().format('YYYY-MM-DD')} 실시간 에너지 사용 현황</h4>
      <Chart ref={TodayChart} options={_options} series={_series} type='line' width={'100%'} height={_height} />
    </div>
  );
};

export default Today;