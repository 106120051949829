import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';

import {
  pageReducer_getCurrentPage,
  pageReducer_setPageNum,
  pageReducer_setPageSize,
  pageReducer_setTotalCount,
} from 'store/modules/actions/default/pageActions';
import {
  stockReducer_setContractAccount,
  stockReducer_setContractProducts,
} from 'store/modules/actions/default/stockActions';

import { AXIOS_BASE_URL } from 'preferences/server/constants';
import { getCookie } from 'preferences/cookie/cookie';

import CheckBox from 'components/checkbox/CheckBox';
import CommaNum from 'components/format/CommaNum';
import ExcelDownloadModal from 'components/excel/ExcelDownloadModal';
import Grid3Body from 'components/layouts/body/Grid3Body';
import Nav from 'components/nav/Nav';
import Paging from 'components/paging/Paging';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import StatusButton from 'components/buttons/StatusButton';
import TableSection from 'components/layouts/table/TableSection';

import GSNotInStockToProcurement from 'pages/procurement/custom/company/gs/management/modal/GSNotInStockToProcurement';

const StockTable = styled.table`
  tr {
    border: none;
  }

  td,
  th {
    min-width: 100px;
  }

  thead tr:nth-child(2) th {
    height: 40px;
  }

  tbody tr {
    border-bottom: 1px solid var(--MainNavy);
  }
`;

const StockButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  border: 4px solid var(--white);
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: inherit;
  justify-content: center;
  min-height: 100px;
  width: 120px;
`;

const ElementCells = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  width: 100%;
`;

const ElementCell = styled.div`
  border-bottom: 1px solid var(--gray-200);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  min-height: 50px;
  width: 100%;

  div[data-type='procurementElement'] {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    min-width: 100px;
    /* padding: 5px 15px; */
    width: 100%;
  }
`;

const GSNotInStock = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { pageReducer, searchReducer, userReducer } = useSelector(
    (state) => state,
  );

  /* ====================================================================== #2 */
  const TitleName = 'Contract';
  const subTitle = 'NotInStock';
  const [_excelDownloadModalStatus, setExcelDownloadModalStatus] =
    useState(false);

  const [_onload, setOnload] = useState('unload');
  const [_procurementList, setProcurementList] = useState([]);
  const [_stockProcurement, setStockProcurement] = useState({});
  const [_modalStatus, setModalStatus] = useState(false);

  const [_authority, setAuthority] = useState([]);
  console.log('_authority : ', _authority);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter(
      (thisItem) => thisItem.authority.startsWith('203') === true,
    );
    const authorityCodes = authorityContent.map(
      (thisItem) => thisItem.authority,
    );
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));
    setStockTable();

    setOnload('loaded');

    return () => { };
  }, []);

  useEffect(() => {
    dispatch(pageReducer_setPageNum(1));
    dispatch(pageReducer_setPageSize(10));

    return () => { };
  }, [pageReducer.currentPage]);

  useEffect(() => {
    if (_onload === 'loaded') {
      setStockTable();
    }

    return () => { };
  }, [pageReducer.currentPageNum, pageReducer.totalCount]);

  useEffect(() => {
    if (_onload === 'loaded') {
      dispatch(pageReducer_setPageNum(1));

      setStockTable();
    }

    return () => { };
  }, [searchReducer.workStatus]);

  /* ====================================================================== #4 */
  const setStockTable = async () => {
    const BodyToPost = {};
    // if (searchReducer.workStatus !== '')
    //   BodyToPost.status = searchReducer.workStatus; // 발주 상태 (대기, 진행, 완료)
    if (searchReducer.searchOption !== '')
      BodyToPost[searchReducer.searchOption] = searchReducer.searchText; // 거래처명, 발주코드, 발주명, 물품코드, 물품이름
    if (searchReducer.startDate !== '' && searchReducer.endDate !== '') {
      const thisStartDate = new Date(searchReducer.startDate);
      // thisStartDate.setHours(thisStartDate.getHours()+9);
      const setStartDate = thisStartDate.toISOString();
      BodyToPost.procurementStartDate = setStartDate; // 발주일자

      const thisEndDate = new Date(searchReducer.endDate);
      thisEndDate.setDate(thisEndDate.getDate() + 1);
      // thisEndDate.setHours(thisEndDate.getHours()+9);
      const setEndDate = thisEndDate.toISOString();
      BodyToPost.procurementEndDate = setEndDate; // 입고예정일자
    }

    console.log('setStockTable-body : ', BodyToPost);

    Object.keys(BodyToPost).length === 0
      ? await findAllNotInStock()
      : await searchAllNotInStock(BodyToPost);
  };

  const findAllNotInStock = async () => {
    const paging = `?page=${pageReducer.currentPageNum - 1}&size=${pageReducer.pageSize
      }&sort=id,DESC`;
    console.log(paging, pageReducer.currentPageNum);
    await axios
      .post(
        AXIOS_BASE_URL + '/notInStock/search' + paging,
        {
          companyId: userReducer.company.companyId,
        },
        {
          headers: {
            Authorization: getCookie('accessToken'),
          },
        },
      )
      .then((response) => {
        if (response === undefined) return;
        Finish_findAllNotInStock(response.data);

        dispatch(pageReducer_setTotalCount(response.data.totalElements));
      })
      .catch((error) => {
        console.log('findAllNotInStock - error : ', error);
      });
  };
  const Finish_findAllNotInStock = (Result) => {
    const thisList = Result.content;
    console.log('findAllNotInStock - result : ', Result);
    console.log('findAllNotInStock - content : ', thisList);

    setProcurementList(thisList);
  };

  const searchAllNotInStock = async (BodyToPost) => {
    const paging = `?page=${pageReducer.currentPageNum - 1}&size=${pageReducer.pageSize
      }&sort=id,DESC`;
    console.log(paging, pageReducer.currentPageNum);
    await axios
      .post(
        AXIOS_BASE_URL + '/notInStock/search' + paging,
        {
          ...BodyToPost,
          companyId: userReducer.company.companyId,
        },
        {
          headers: {
            Authorization: getCookie('accessToken'),
          },
        },
      )
      .then((response) => {
        if (response === undefined) return;
        console.log(response);
        Finish_searchAllNotInStock(response.data);

        dispatch(pageReducer_setTotalCount(response.data.totalElements));
      })
      .catch((error) => {
        console.log('searchAllNotInStock - error : ', error);
      });
  };
  const Finish_searchAllNotInStock = (Result) => {
    const thisList = Result.content;
    console.log('searchAllNotInStock - result : ', Result);
    console.log('searchAllNotInStock - content : ', thisList);

    thisList !== undefined && setProcurementList(thisList);
  };

  /* ====================================================================== #5 */
  /* 검색 */
  const actSearch = () => {
    dispatch(pageReducer_setPageNum(1));
    dispatch(pageReducer_setPageSize(10));

    setStockTable();
  };

  /* 입고 리스트 띄우기 */
  const showStockList = (e) => {
    const thisNotInStockId = e.currentTarget.dataset.key;
    const thisProcurement = _procurementList.filter(
      (thisItem) => thisItem.notInStockId === Number(thisNotInStockId),
    )[0];

    setStockProcurement(thisProcurement);

    dispatch(stockReducer_setContractProducts([]));
    dispatch(stockReducer_setContractAccount({}));

    setModalStatus(true);
  };

  /* 진행상태 설정 */
  const showStatusModal = async (e) => {
    const thisNotInStockId = e.currentTarget.dataset.workordercode;

    const thisFindIndex = _procurementList.findIndex(
      (thisData) => thisData.notInStockId === Number(thisNotInStockId),
    );
    let thisnotInStock = _procurementList[thisFindIndex];
    console.log(
      'thisnotInStock : ',
      _procurementList,
      thisNotInStockId,
      thisnotInStock,
    );

    let eventText = '';
    let eventState = '';
    switch (thisnotInStock.status) {
      case 'waiting':
        eventText = '진행';
        eventState = 'proceeding';
        break;

      case 'proceeding':
        eventText = '완료';
        eventState = 'end';
        break;

      default:
        return;
    }

    const newNotInStockElement = thisnotInStock.notInStockElementList.map(
      (thisElement) => {
        return {
          accountId: thisElement.account.accountId,
          elementId: thisElement.element.elementId,
          notInStockElementId: thisElement.notInStockElementId,
          price: thisElement.price,
          notInStockQuantity: thisElement.notInStockQuantity,
        };
      },
    );

    const BodyToPut = {
      ...thisnotInStock,
      companyId: userReducer.company.companyId,
      procurementId: thisnotInStock.procurement.procurementId,
      deleteNotInStockElementList: [],
      notInStockElementList: newNotInStockElement,
      status: eventState,
    };

    delete BodyToPut['createdDate'];
    delete BodyToPut['modifiedDate'];
    delete BodyToPut['company'];
    delete BodyToPut['procurement'];
    // delete BodyToPut['status'];
    // delete BodyToPut['deleteNotInStockElementList'];

    const confirmText = `미입고 진행상태를 ${eventText}시키겠습니까?`;
    if (window.confirm(confirmText) === true) {
      console.log(BodyToPut);

      await axios
        .put(
          AXIOS_BASE_URL + '/notInStock/' + thisnotInStock.notInStockId,
          {
            ...BodyToPut,
            companyId: userReducer.company.companyId,
          },
          {
            headers: {
              Authorization: getCookie('accessToken'),
            },
          },
        )
        .then((response) => {
          if (response === undefined) return;
          console.log('modifiedNotInStock - response : ', response);

          Finish_modifiedNotInStock(response.data);
        })
        .catch((error) => {
          console.error('modifiedNotInStock - error : ', error);
        });
    } else {
      return;
    }
  };
  const Finish_modifiedNotInStock = (Result) => {
    const thisNotInStockId = Result.notInStockId;
    // const thisProcurement = _procurementList.filter(thisItem => thisItem.notInStockId === thisNotInStockId)[0];

    // const setList = _procurementList.filter(thisItem => thisItem.notInStockId !== thisNotInStockId);
    // const newList = [...setList, Result];

    const newList = [..._procurementList];
    const thisFindIndex = newList.findIndex(
      (thisItem) => thisItem.notInStockId === thisNotInStockId,
    );
    newList.splice(thisFindIndex, 1, Result);

    setProcurementList(newList);
  };

  /* 삭제 */
  const actDelete = async () => {
    if (
      document.querySelectorAll('input[name="notInStocks"]:checked').length < 1
    )
      return alert('삭제할 미입고 정보를 선택해주세요.');

    const delId = document.querySelector('input[name="notInStocks"]:checked')
      .dataset.key;
    const delItem = _procurementList.filter(
      (thisItem) => thisItem.notInStockId === Number(delId),
    )[0];
    console.log('delItem : ', delItem);

    const confirmText = `
    발주코드: ${delItem.procurement.procurementCode}
    발주명: ${delItem.procurement.procurementName}

    미입고 정보를 삭제하시겠습니까?
    `;

    if (window.confirm(confirmText) === true) {
      await axios
        .delete(AXIOS_BASE_URL + '/notInStock/' + delId, {
          headers: {
            Authorization: getCookie('accessToken'),
          },
        })
        .then((response) => {
          if (response === undefined) return;
          console.log('deleteNotInStock - response : ', response);
          if (response.status === 200) {
            alert('미입고 정보를 삭제했습니다.');
            setStockTable();
          }
        })
        .catch((error) => {
          console.log('deleteNotInStock - error : ', error);
        });
    } else {
      return;
    }
  };

  /* ====================================================================== #6 */

  return (
    <Grid3Body
      contents={
        <>
          <main className="Main">
            <Nav
              thisTitle={'미입고관리'}
              content={
                <>
                  {_authority.indexOf('203-2') !== -1 ? (
                    <button onClick={actDelete}>삭제</button>
                  ) : null}
                </>
              }
              nav={''}
              search={''}
              searchButton={
                <button id="searchButton" onClick={actSearch}>
                  <SearchButtonIcon />
                </button>
              }
              searchOptions={[]}
              statusOptions={[]}
            />

            <TableSection
              content={
                <StockTable>
                  <thead>
                    <tr>
                      {_authority.indexOf('203-2') !== -1 ? (
                        <th
                          rowSpan={2}
                          style={{ minWidth: '100px', width: '100px' }}
                        ></th>
                      ) : null}
                      <th rowSpan={2}>진행상태</th>
                      {_authority.indexOf('203-2') !== -1 ? (
                        <th
                          rowSpan={2}
                          style={{ minWidth: '50px', width: '50px' }}
                        ></th>
                      ) : null}
                      <th rowSpan={2}>거래처</th>
                      <th
                        colSpan={6}
                        style={{ backgroundColor: 'var(--ThirdBlue)' }}
                      >
                        미입고물품
                      </th>
                      <th rowSpan={2}>발주코드</th>
                      <th rowSpan={2}>발주명</th>
                      <th rowSpan={2}>발주총금액</th>
                      <th rowSpan={2}>발주일자</th>
                    </tr>
                    <tr>
                      <th>물품이름</th>
                      <th>물품코드</th>
                      <th>발주금액</th>
                      <th
                        style={{
                          backgroundColor: 'var(--MainRed)',
                          color: 'var(--white)',
                        }}
                      >
                        미입고수량
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {_procurementList.length > 0 &&
                      _procurementList.map((thisList) => {
                        const thisProcurement = thisList.procurement;

                        let thisProcurementDate =
                          thisProcurement.procurementDate;
                        if (
                          thisProcurement.procurementDate !== undefined &&
                          thisProcurement.procurementDate !== null &&
                          typeof thisProcurement.procurementDate === 'object'
                        ) {
                          const newStartDate = new Date(
                            thisProcurement.procurementDate.join('-'),
                          );
                          thisProcurementDate =
                            moment(newStartDate).format('YYYY-MM-DD');
                        }

                        return (
                          <tr
                            key={
                              thisList.procurementId +
                              '_' +
                              thisList.notInStockId
                            }
                            data-key={thisList.procurementId}
                          >
                            {_authority.indexOf('203-2') !== -1 ? (
                              <td
                                style={{
                                  minWidth: '100px',
                                  padding: 'unset',
                                  width: '100px',
                                }}
                              >
                                {thisList.status !== null ? (
                                  thisList.status.toLowerCase() !== 'end' ? (
                                    <StockButton
                                      data-key={thisList.notInStockId}
                                      onClick={showStockList}
                                    >
                                      발주
                                    </StockButton>
                                  ) : null
                                ) : null}
                              </td>
                            ) : null}

                            <td
                              style={{
                                minWidth: '100px',
                                padding: 'unset',
                                width: '100px',
                              }}
                            >
                              {thisList.status !== null ? (
                                <StatusButton
                                  workOrderCode={thisList.notInStockId}
                                  status={
                                    thisList.status !== undefined
                                      ? thisList.status
                                      : 'waiting'
                                  }
                                  statusEvent={
                                    _authority.indexOf('203-2') !== -1
                                      ? showStatusModal
                                      : null
                                  }
                                  statusStyle={
                                    _authority.indexOf('203-2') !== -1
                                      ? { minHeight: '100px' }
                                      : {
                                        cursor: 'default',
                                        minHeight: '100px',
                                      }
                                  }
                                />
                              ) : null}
                            </td>

                            {_authority.indexOf('203-2') !== -1 ? (
                              <td style={{ minWidth: '50px', width: '50px' }}>
                                <CheckBox
                                  act={'one'}
                                  all={false}
                                  checkboxKey={thisList.notInStockId}
                                  name={'notInStocks'}
                                />
                              </td>
                            ) : null}

                            <td>
                              {thisList.notInStockElementList !== null
                                ? thisList.notInStockElementList !== undefined
                                  ? thisList.notInStockElementList.length > 0
                                    ? thisList.notInStockElementList[0].account
                                      .accountName
                                    : null
                                  : null
                                : null}
                            </td>

                            <td colSpan={6} style={{ padding: 'unset' }}>
                              <ElementCells>
                                {thisList.notInStockElementList !== null
                                  ? thisList.notInStockElementList !== undefined
                                    ? thisList.notInStockElementList.length > 0
                                      ? thisList.notInStockElementList.map(
                                        (thisElement) => {
                                          return (
                                            <ElementCell
                                              key={
                                                thisElement.notInStockElementId +
                                                '_ElementCell'
                                              }
                                            >
                                              <div
                                                data-type={
                                                  'procurementElement'
                                                }
                                              >
                                                {
                                                  thisElement.element
                                                    .elementName
                                                }
                                              </div>
                                              <div
                                                data-type={
                                                  'procurementElement'
                                                }
                                              >
                                                {
                                                  thisElement.element
                                                    .elementCode
                                                }
                                              </div>
                                              <div
                                                data-type={
                                                  'procurementElement'
                                                }
                                              >
                                                <CommaNum
                                                  displayType={'text'}
                                                  num={thisElement.price}
                                                />
                                              </div>
                                              <div
                                                data-type={
                                                  'procurementElement'
                                                }
                                                style={{
                                                  color: 'var(--MainRed)',
                                                  fontSize: '20px',
                                                  fontWeight: '600',
                                                }}
                                              >
                                                <CommaNum
                                                  displayType={'text'}
                                                  num={
                                                    thisElement.notInStockQuantity !==
                                                      null
                                                      ? thisElement.notInStockQuantity
                                                      : 0
                                                  }
                                                />
                                              </div>
                                            </ElementCell>
                                          );
                                        },
                                      )
                                      : null
                                    : null
                                  : null}
                              </ElementCells>
                            </td>

                            <td>{thisProcurement.procurementCode}</td>
                            <td>{thisProcurement.procurementName}</td>
                            <td>
                              <CommaNum
                                displayType={'text'}
                                num={thisProcurement.procurementPrice}
                              />
                            </td>
                            <td>{thisProcurementDate}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </StockTable>
              }
            />
          </main>
          <Paging
            page={pageReducer.currentPageNum}
            count={pageReducer.totalCount}
            size={pageReducer.pageSize}
          />

          {_modalStatus === true && (
            <GSNotInStockToProcurement
              open={_modalStatus}
              close={() => {
                setModalStatus(false);
                setStockTable();
              }}
              Procurement={_stockProcurement}
            />
          )}

          {_excelDownloadModalStatus === true && (
            <ExcelDownloadModal
              open={_excelDownloadModalStatus}
              mainTitle={TitleName}
              subTitle={subTitle}
              close={() => {
                setExcelDownloadModalStatus(false);
              }}
            />
          )}
        </>
      }
    />
  );
};

export default GSNotInStock;
