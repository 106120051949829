export const fileActions_setFolderList = (content) => {
  return {
    type: 'SETFOLDERLIST',
    payload: content,
  };
};

export const fileActions_setFolderTreeList = (content) => {
  return {
    type: 'SETFOLDERTREELIST',
    payload: content,
  };
};

export const fileActions_setCurrentFolder = (content) => {
  return {
    type: 'SETCURRENTFOLDER',
    payload: content,
  };
};

export const fileActions_setDeleteFolder = (content) => {
  return {
    type: 'SETDELETEFOLDER',
    payload: content,
  };
};

export const fileActions_setFileList = (content) => {
  return {
    type: 'SETFILELIST',
    payload: content,
  };
};

export const fileActions_setFileSearch = (content) => {
  return {
    type: 'SETFILESEARCH',
    payload: content,
  };
};

export const fileActions_setFileCurrentPage = (content) => {
  return {
    type: 'SETFILECURRENTPAGE',
    payload: content,
  };
};

export const fileActions_setFileTotalSize = (content) => {
  return {
    type: 'SETFILETOTALSIZE',
    payload: content,
  };
};

export const fileActions_setFileReset = () => {
  return {
    type: 'SETFILERESET',
  };
};
