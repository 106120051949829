import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { WS_CLIENT } from 'preferences/server/constants';
import { getCookie } from 'preferences/cookie/cookie';

import { stockPlace } from 'api/apis/stock/stockPlace';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import IconRightArrow from 'components/icons/IconRightArrow';
import StatusDivButton from 'components/buttons/StatusDivButton';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 85%;
  justify-content: flex-start;
  max-width: 1200px;
  min-width: 200px;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: space-between;
  padding: 0px 20px;
  /* padding-inline-end: 100px; */
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const NumSection = styled.div`
  align-items: center;
  column-gap: 5px;
  display: grid;
  grid-template-columns: 1fr 10px 1fr 60px;
  height: 40px;
  justify-items: center;
  margin-right: 40px;
  max-width: 200px;
  width: 100%;

  input {
    background-color: var(--MainNavy);
    border: 1px solid var(--gray-200);
    border-radius: 5px;
    box-sizing: border-box;
    color: var(--white);
    display: block;
    height: inherit;
    padding: 10px;
    width: 100%;
  }
`;
const AllCheckButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  border-radius: 5px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 100%;
`;

const ModalMain = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 50px calc(100% - 250px) 160px;
  height: calc(100% - 55px);
  padding: 20px;
  gap: 20px;
  width: 100%;

  & .sec-add-info {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    width: 100%;

    & select, & input {
      background-color: var(--white);
      border: 1px solid var(--gray-200);
      box-sizing: border-box;
      margin: 0px auto;
      padding: 10px;
      width: 100%;
    }
  }
`;

const NumList = styled.div`
  align-content: flex-start;
  box-sizing: border-box;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 100%;
  /* grid-template-columns: repeat(2, 1fr); */
  height: 100%;
  /* height: calc(100% - 230px); */
  overflow-y: auto;
  padding: 20px 0px;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}
`;
const ProcessCheckBox = styled.div`
  align-items: center;
  box-sizing: border-box;
  column-gap: 20px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 30px 30px auto ;
  padding: 2px 10px;
  width: 100%;

  &:hover {background-color: var(--Bg);}
  input, label {cursor: pointer;}
`;
const StatusPoint = styled.div`
  border-radius: 50%;
  min-height: 25px;
  min-width: 25px;
  height: 25px;
  width: 25px;

  background-color: ${(props) => {
    switch (props.status) {
      case 'wait': return 'var(--gray-200)'
      case 'work': return 'var(--WorkingColor)'
      case 'end': return 'var(--ThirdBlue)'
      case 'stop': return 'var(--MainRed)'
      case 'pause': return 'var(--MainYellow)'
      case 'cancel': return 'var(--Bg)'

      case 'NO': return 'var(--MainNavy)';
      case 'cut': return '#BCE55C'
      case 'welding': return '#9FC93C'
      case 'last': return '#689900'

      default: return 'unset';
    }
  }};
`;

const StatusSection = styled.div`
  align-items: center;
  box-sizing: border-box;
  column-gap: 10px;
  display: flex;
  height: 160px;
  justify-content: center;
  width: 100%;
`;

const ProcessMultiStatusModal = (props) => {
  /* ====================================================================== #1 */
  const { userReducer, workReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const { workOrderLog, processList } = props;

  const [_processList, setProcessList] = useState(processList);

  const [_startNum, setStartNum] = useState();
  const [_endNum, setEndNum] = useState();

  const [_stockPlaces, setStockPlaces] = useState([]); // 재고위치
  const [_stockPlace, setStockPlace] = useState();
  const [_checkedDate, setCheckedDate] = useState();

  const [_checkedItem, setCheckedItem] = useState([]);

  /* ====================================================================== #3 */
  useEffect(() => {
    getStockPlaces();

    return () => { }
  }, [])

  /* ====================================================================== #4 */
  const getStockPlaces = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await stockPlace.searchStockPlace(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('stockPlace.searchStockPlace : ', response);
      setStockPlaces(() => { return response.data; });
    });
  }

  const checkEvent = (start, end) => {
    if (isNaN(start) || isNaN(end)) return;
    setCheckedItem(() => {
      const newData = processList.map((process, index) => {
        if (process.processStatus !== 'end' && process.processStatus !== 'cancel' && process.processingType !== 'passive' && index >= start - 1 && index <= end - 1) return process;
        else return null;
      });
      console.log('newData : ', newData);

      const filterData = newData.filter((item) => item !== null);
      if (filterData.length > 0) {
        const lastItem = filterData.at(-1);
        const lastItemIndex = processList.findIndex(item => item.processLogId === lastItem.processLogId);
        setEndNum(() => { return lastItemIndex + 1; });
      }
      return filterData;
    });

  };
  const numEvent = (e, type) => {
    let eventNum = e.target.value;
    if (eventNum > processList.length) return;

    switch (type) {
      case 'start':
        setStartNum(() => { return eventNum; });
        if (eventNum > _endNum) return checkEvent(_endNum, eventNum);
        checkEvent(eventNum, _endNum);
        break;

      case 'end':
        setEndNum(() => { return eventNum; });
        if (eventNum < _startNum) return checkEvent(eventNum, _startNum);
        checkEvent(_startNum, eventNum);
        break;

      default: return;
    }
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (item, isChecked) => {
    const checkedItem = [..._checkedItem];
    const checkedIndex = checkedItem.findIndex((thisItem) => thisItem.processLogId === item.processLogId);
    if (checkedIndex !== -1) {
      if (!isChecked) checkedItem.splice(checkedIndex, 1);
    } else {
      if (isChecked) checkedItem.push(item);
    }
    console.log('checkedItem : ', checkedItem);
    setCheckedItem(() => { return checkedItem; });
  };

  const statusEvent = (eventProps) => {
    if (workReducer.connectStatus !== 'connect') return alert(`
연결이 불안정합니다.
네트워크 상태 확인 후 다시 시도하여 주시기 바랍니다.
    `);
    if (_checkedItem.length < 1) return;

    if (eventProps.status === 'end') {
      let checkStatus = false;
      _checkedItem.forEach((item) => {
        if (item.processStatus === 'wait' || item.processStatus === 'pause' || item.processStatus === 'stop') checkStatus = true;
      });

      if (checkStatus) {
        alert(`
공정상태를 확인해주세요.
선택된 공정 전체가 [ 작업중 ]이여야 합니다.
        `);
      } else {
        const BodyToWS = {
          roomId: userReducer.company.companyId,
          type: '/processes',
          workOrderLogId: workOrderLog.workOrderLogId,
          processLogIds: _checkedItem.map((item) => item.processLogId),
          processStatus: 'end',

          // 추가정보
          stockPlaceId: _stockPlace,
        };
        if (checkEmptyNull(_checkedDate, false)) {
          const checkedDate = new Date(_checkedDate);
          checkedDate.setHours(checkedDate.getHours() + 9);
          BodyToWS.checkedDate = checkedDate.toISOString(); // 유통기한
        }
        console.log('client.publish/pub/processes - body : ', JSON.stringify(BodyToWS));
        try {
          WS_CLIENT.publish({
            destination: '/pub/processes',
            headers: { 'auth-token': getCookie('accessToken')?.replace('Bearer ', '') },
            body: JSON.stringify(BodyToWS),
          });
        } catch (error) {
          console.log('client.publish/pub/processes - error : ', error);
        } finally {
          setTimeout(() => { props.close() }, 1000);
        }
      }
    } else {
      const BodyToWS = {
        roomId: userReducer.company.companyId,
        type: '/processes',
        workOrderLogId: workOrderLog.workOrderLogId,
        processLogIds: _checkedItem.map((item) => item.processLogId),
        processStatus: eventProps.status,
      };
      console.log('client.publish/pub/processes - body : ', JSON.stringify(BodyToWS));
      try {
        WS_CLIENT.publish({
          destination: '/pub/processes',
          headers: { 'auth-token': getCookie('accessToken')?.replace('Bearer ', '') },
          body: JSON.stringify(BodyToWS),
        });
      } catch (error) {
        console.log('client.publish/pub/processes - error : ', error);
      } finally {
        setTimeout(() => { props.close() }, 1000);
      }
    }
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>일괄 상태 변경</ModalTitle>
              <NumSection>
                <input type="number" min={0} value={_startNum} onInput={(e) => { numEvent(e, 'start'); }} />
                <IconRightArrow />
                <input type="number" min={0} value={_endNum} onInput={(e) => { numEvent(e, 'end'); }} />

                <AllCheckButton
                  onClick={(e) => {
                    e.preventDefault();
                    const startNum = 1;
                    const endNum = processList.length;
                    setStartNum(() => { return startNum; });
                    setEndNum(() => { return endNum; });
                    checkEvent(startNum, endNum);
                  }}
                >
                  전체
                </AllCheckButton>
              </NumSection>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <div className='sec-add-info'>
                {checkEmptyNull(_stockPlaces, false) ?
                  <select
                    onChange={(e) => {
                      e.preventDefault();
                      const stockPlaceId = Number(e.target.value);
                      if (isNaN(stockPlaceId)) return;
                      setStockPlace(() => { return stockPlaceId });
                    }}
                  >
                    <option value={''}>재고위치 선택</option>
                    {checkEmptyNull(_stockPlaces, []).map((stockPlace, stockPlaceIndex) => {
                      return (
                        <option key={stockPlaceIndex + '_stockPlace'} value={stockPlace.stockPlaceId}>{stockPlace.placeName}</option>
                      )
                    })}
                  </select>
                  : null}
                <input type='datetime-local'
                  onChange={(e) => {
                    e.preventDefault();
                    const checkedDate = DateTimeFormat(checkEmptyNull(e.target.value, ''));
                    setCheckedDate(() => { return checkedDate });
                  }}
                />
              </div>
              <NumList>
                {(() => {
                  const numList = [];
                  processList.forEach((process, index) => {
                    numList.push(
                      <ProcessCheckBox key={index + '_process'}>
                        <input type="checkbox"
                          id={index + '_process'}
                          name="processes"
                          checked={(() => {
                            let checkedId = -1;
                            checkedId = _checkedItem?.findIndex((item) => item.processLogId === process.processLogId);
                            if (checkedId !== -1 && process.processStatus !== 'end' && process.processStatus !== 'cancel') return true;
                            else return false;
                          })()}
                          disabled={(() => {
                            if (process.processStatus === 'end') return true;
                            else if (process.processStatus === 'cancel') return true;
                            else if (process.processingType === 'passive') return true;
                            else return false;
                          })()}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            handleCheckedItem(process, isChecked);
                          }}
                          onClick={(e) => { e.stopPropagation(); }}
                        />
                        <StatusPoint status={process.processStatus} />
                        <label htmlFor={index + '_process'}>
                          <p>{process.processNumber}. {process.processCode} - {process.processName}</p>
                        </label>
                      </ProcessCheckBox>
                    );
                  });
                  return numList;
                })()}
              </NumList>
              <StatusSection>
                {['work', 'pause', 'stop', 'end'].map((status, index) => {
                  return (<StatusDivButton key={index + '_status'} status={status} statusEvent={statusEvent}></StatusDivButton>);
                })}
              </StatusSection>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default ProcessMultiStatusModal;
