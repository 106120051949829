const initialState = {
  materialFilter: 'all',
  halfFilter: 'all',
  productFilter: 'all',

  productionFilter: 'all',

  qualityFilter: 'all',

  monitoringView: 'all',

  sensorView: 'all',
};

const monitoringReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETMONITORINGMATERIALFILTER': return { ...state, materialFilter: action.payload };
    case 'SETMONITORINGHALFFILTER': return { ...state, halfFilter: action.payload };
    case 'SETMONITORINGPRODUCTFILTER': return { ...state, productFilter: action.payload };

    case 'SETMONITORINGPRODUCTIONFILTER': return { ...state, productionFilter: action.payload };

    case 'SETMONITORINGQUALITYFILTER': return { ...state, qualityFilter: action.payload };

    case 'SETMONITORINGMONITORINGVIEW': return { ...state, monitoringView: action.payload };

    case 'SETMONITORINGSENSORVIEW': return { ...state, sensorView: action.payload };

    default: return state;
  }
};

export default monitoringReducer;
