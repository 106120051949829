export const currentStockActions_setElementType = (elementType) => {
  return {
    type: 'SETCURRENTSTOCKTYPE',
    payload: elementType,
  };
};
export const currentStockActions_setSearchData = (searchData) => {
  return {
    type: 'SETCURRENTSTOCKSEARCHDATA',
    payload: searchData,
  };
};
export const currentStockActions_setSearchAttrs = (serachAttrs) => {
  return {
    type: 'SETCURRENTSTOCKSEARCHATTS',
    payload: serachAttrs,
  };
};

export const currentStockActions_setClickedCategory = (category) => {
  return {
    type: 'SETCURRENTSTOCKCLICKEDCATEGORY',
    payload: category,
  };
};
export const currentStockActions_setClickedLevel1Category = (category) => {
  return {
    type: 'SETCURRENTSTOCKCLICKEDLEVEL1CATEGORY',
    payload: category,
  };
};
export const currentStockActions_setClickedLevel2Category = (category) => {
  return {
    type: 'SETCURRENTSTOCKCLICKEDLEVEL2CATEGORY',
    payload: category,
  };
};
export const currentStockActions_setClickedLevel3Category = (category) => {
  return {
    type: 'SETCURRENTSTOCKCLICKEDLEVEL3CATEGORY',
    payload: category,
  };
};
export const currentStockActions_setClickedLevel4Category = (category) => {
  return {
    type: 'SETCURRENTSTOCKCLICKEDLEVEL4CATEGORY',
    payload: category,
  };
};
export const currentStockActions_setClickedLevel5Category = (category) => {
  return {
    type: 'SETCURRENTSTOCKCLICKEDLEVEL5CATEGORY',
    payload: category,
  };
};

export const currentStockActions_setPageNumber = (pageNum) => {
  return {
    type: 'SETCURRENTSTOCKPAGENUMBER',
    payload: pageNum,
  };
};
export const currentStockActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETCURRENTSTOCKTOTALSIZE',
    payload: totalSize,
  };
};
export const currentStockActions_setIsPushedSearchButton = (status) => {
  return {
    type: 'SETCURRENTSTOCKISPUSHEDSEARCHBUTTON',
    payload: status,
  };
};

export const currentStockActions_setReset = () => {
  return {
    type: 'SETCURRENTSTOCKRESET',
  };
};

export const currentStockActions_selectOneElement = (element) => {
  return {
    type: 'SELECTONEELEMENT',
    payload: element,
  };
};

export const currentStockActions_selectElements = (elements) => {
  return {
    type: 'SELECTELEMENTS',
    payload: elements,
  };
};

export const currentStockActions_setLevel1Categories = (category) => {
  return {
    type: 'SETLEVEL1CATEGORIES',
    payload: category,
  };
};
export const currentStockActions_setLevel2Categories = (category) => {
  return {
    type: 'SETLEVEL2CATEGORIES',
    payload: category,
  };
};
export const currentStockActions_setLevel3Categories = (category) => {
  return {
    type: 'SETLEVEL3CATEGORIES',
    payload: category,
  };
};
export const currentStockActions_setLevel4Categories = (category) => {
  return {
    type: 'SETLEVEL4CATEGORIES',
    payload: category,
  };
};
export const currentStockActions_setLevel5Categories = (category) => {
  return {
    type: 'SETLEVEL5CATEGORIES',
    payload: category,
  };
};

export const currentStockActions_setBookmark = (status) => {
  return {
    type: 'SETCURRENTSTOCKBOOKMARK',
    payload: status,
  };
};
