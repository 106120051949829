import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { mallSiteUserActions_setUser } from 'store/modules/actions/mallSite/mallSiteUserActions';
import { userReducer_reset } from 'store/modules/actions/user/userActions';

import { removeCookie } from 'preferences/cookie/cookie';

import Dropdown from './Dropdown';

const MenuItems = ({ items, depthLevel }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const companyCode = location.pathname.split('/')[1];

  let ref = useRef();
  const [dropdown, setDropdown] = useState(false);
  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) setDropdown(false);
    };
    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => { window.innerWidth > 960 && setDropdown(true); };
  const onMouseLeave = () => { window.innerWidth > 960 && setDropdown(false); };
  const closeDropdown = () => { dropdown && setDropdown(false); };

  const actLogout = () => {
    console.log('================================= removeCookie');
    dispatch(userReducer_reset());
    dispatch(mallSiteUserActions_setUser({}));

    removeCookie('accessToken');
    removeCookie('refreshToken');

    setTimeout(navigate(`/${companyCode}/mall/sign/login`, { replace: true }), 1000)
  }

  return (
    <div
      className="menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}
    >
      {items.mypage && items.mypage ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {depthLevel > 0 && window.innerWidth < 960 ? null : depthLevel > 0 && window.innerWidth > 960 ? (
              <span>&raquo;</span>
            ) : (
              <span className="arrow" />
            )}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.mypage}
            dropdown={dropdown}
          />
        </>
      ) : !items.mypage && items.mypage ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {items.menuName}{' '}
            {depthLevel > 0 ? (
              <span>&raquo;</span>
            ) : (
              <span className="arrow" />
            )}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.mypage}
            dropdown={dropdown}
          />
        </>
      ) : (
        <>
          {items.menuName === '로그아웃' ? (
            <p onClick={(e) => { e.preventDefault(); actLogout() }}>{items.menuName}</p>
          ) : (
            <p onClick={(e) => { e.preventDefault(); navigate(`/${companyCode + items.route}`, { replace: true }); }}>{items.menuName}</p>
          )}
        </>
      )}
    </div>
  );
};

export default MenuItems;
