import React, { useEffect, useState } from 'react';

import { checkNullArray } from 'components/checkValues/checkValues';
import { CiCirclePlus } from "react-icons/ci";
import { FaTrashCan } from "react-icons/fa6";
import { ItemBox } from 'platform/cluster/pages/modal/FormModal.style';

const ItemList = ({ modalType, data, itemList, setItemList }) => {
  const [_currentType, setCurrentType] = useState('');
  const [_dataKeys, setDataKeys] = useState({
    id: '', list: '', delList: '', itemId: '', proposerList: '', proposerDelList: '',
  });
  const [_itemKeys, setItemKeys] = useState([]);

  useEffect(() => {
    const type = modalType.split('-')[0];
    setCurrentType(() => { return type; });
    setDataKeys(() => {
      const returnData = {};
      returnData.id = 'jointReciprocalTransactionId';
      returnData.list = 'jointReciprocalTransactionItemList';
      returnData.delList = 'deleteJointReciprocalTransactionItemList';
      returnData.itemId = 'jointReciprocalTransactionItemId';
      returnData.proposerList = 'jointReciprocalTransactionProposerList';
      returnData.proposerDelList = 'deleteJointReciprocalTransactionProposerList';
      return returnData;
    });
    setItemKeys(() => {
      let returnData = [
        { key: 'itemName', title: '상품명', inputType: 'text' },
        { key: 'number', title: '수량', inputType: 'number' },
        { key: 'price', title: '가격', inputType: 'number' },
        { key: 'detail', title: '설명', inputType: 'textarea' },
      ];
      return returnData;
    });
  }, [modalType]);
  useEffect(() => { }, [_dataKeys]);

  const actAddItem = () => {
    setItemList((prev) => {
      const newItem = {};
      _itemKeys.forEach((itemKey => {
        newItem[itemKey.key] = '';
      }))
      const returnList = [...prev, newItem];
      return returnList;
    })
  };
  const handleItemData = (e, index, item, itemKey) => {
    setItemList((prev) => {
      const returnList = [...prev];
      const spliceData = { ...item, [itemKey.key]: e.target.value };
      returnList.splice(index, 1, spliceData);
      return returnList;
    })
  }

  return (<>
    <div className='list'>
      {!modalType.endsWith('delete') ? (<button onClick={actAddItem}><CiCirclePlus /><p>상품 추가</p></button>) : null}
      <div className='section-form'>
        {checkNullArray(itemList, []).map((item, index) => {
          return (
            <ItemBox key={index + '_item'}>
              <div className='item-header'>
                <button
                  title='상품 삭제'
                  onClick={() => {
                    setItemList((prev) => {
                      const returnList = [...prev];
                      returnList.splice(index, 1);
                      return returnList;
                    })
                  }}
                ><FaTrashCan /></button>
              </div>

              {_itemKeys.map((itemKey, keyIndex) => {
                return (
                  <div key={index + '_form-content_' + keyIndex} className='form-content term'>
                    <h4>{itemKey.title}</h4>
                    {itemKey.inputType === 'number' ? (<>
                      <div>
                        <input
                          type='number'
                          placeholder={`숫자만 입력할 수 있습니다.`}
                          disabled={modalType.endsWith('delete')}
                          pattern="\d*"
                          min="0"
                          value={item[itemKey.key] || ''}
                          onInvalid={(e) => { e.target.setCustomValidity('숫자만 입력할 수 있습니다.'); }}
                          onInput={(e) => {
                            e.target.setCustomValidity('');
                            handleItemData(e, index, item, itemKey);
                          }}
                        />
                      </div>
                    </>) : itemKey.inputType === 'textarea' ? (<>
                      <textarea
                        placeholder={`${itemKey.title}을(를) 입력해주세요.`}
                        disabled={modalType.endsWith('delete')}
                        defaultValue={item[itemKey.key] || ''}
                        onInput={(e) => {
                          handleItemData(e, index, item, itemKey);
                        }}
                      />
                    </>) : (<>
                      <input
                        type='text'
                        placeholder={`${itemKey.title}을(를) 입력해주세요.`}
                        disabled={modalType.endsWith('delete')}
                        value={item[itemKey.key] || ''}
                        onInput={(e) => {
                          handleItemData(e, index, item, itemKey);
                        }}
                      />
                    </>)}
                  </div>
                );
              })}
            </ItemBox>
          );
        })}
      </div>
    </div>
  </>);
};

export default ItemList;