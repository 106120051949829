import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { QRCodeSVG } from 'qrcode.react';

import { DateFormat } from 'components/format/DateFormat';
import { checkEmptyNull, checkNullArray, checkNullParse } from 'components/checkValues/checkValues';
import AllCheckBox from 'components/checkbox/AllCheckBox';
import CheckBox from 'components/checkbox/CheckBox';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
  }
`;
const QRCode = styled(QRCodeSVG)`
  cursor: pointer;
  height: 50px;
  width: 50px;
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const GSAddCLWorkList = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const workOrderLog = props.content;
  const [_processLogList, setProcessLogList] = useState(workOrderLog.processLogList.filter((thisProcess) => thisProcess.processTypeName === 'CL'));

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    userId: userReducer.user.id,
    workOrderStatus: workOrderLog.workOrderStatus,
    workOrderLogId: workOrderLog.workOrderLogId,
    workOrderCode: workOrderLog.workOrderCode,
    workOrderName: workOrderLog.workOrderName,
    workOrderScheduledStartDate: DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledStartDate, '')),
    workOrderScheduledEndDate: DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledEndDate, '')),
    accountId: workOrderLog.accountId,
    workPlace: workOrderLog.workPlace,
    detailedWorkPlace: workOrderLog.detailedWorkPlace,
    message: workOrderLog.message,
    remark: workOrderLog.remark,
    workOrderCustomizedContent: workOrderLog.workOrderCustomizedContent,
    processLogList: [],
    deleteProcessList: [],
  });

  // 바코드 복사
  const copyBarcode = (e) => {
    const thisBarcode = e.target.closest('td').dataset.barcode;
    console.log(thisBarcode);

    const textArea = document.createElement('textarea');
    document.body.appendChild(textArea);

    textArea.value = thisBarcode;
    textArea.select();
    textArea.setSelectionRange(0, 99999);
    document.execCommand('copy');
    textArea.setSelectionRange(0, 0);

    document.body.removeChild(textArea);
  };

  const actAddProduct = () => {
    const processLogList = [];
    console.log('props.shipmentElementList : ', props.shipmentElementList);
    const processLogIdArray = props.shipmentElementList.map((item) => item.processLogId);

    document.querySelectorAll('input[name="processLogs"]:checked').forEach((processLogContent, index) => {
      const workOrderLogId = workOrderLog.workOrderLogId;
      const processLogId = Number(processLogContent.dataset.key);

      const processLogIndex = _processLogList.findIndex((item) => item.processLogId === processLogId);
      const processLogData = _processLogList[processLogIndex];
      const returnData = {...processLogData, workOrderLogId: workOrderLogId};
      const filterIndex = processLogIdArray.findIndex((item) => item === processLogId);
      if (filterIndex === -1) processLogList.push(returnData);
      if(index === document.querySelectorAll('input[name="processLogs"]:checked').length - 1) {
        console.log('processLogList: ', processLogList);
        props.update(processLogList);
        props.close();
      }
    });
  };


  /* ====================================================================== #6 */
  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection type="list" style={{ zoom: '100%' }}>
            <ModalHeader>
              <ModalTitle>송장물품 추가</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <TableSection content={
                  <table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '50px', width: '50px' }}><AllCheckBox key={'AllCheckBox'} checkboxName={'processLogs'} /></th>
                        <th style={{ minWidth: '65px', width: '65px' }}>순번</th>
                        <th>부재번호</th>
                        <th>규격</th>
                        <th>길이</th>
                        <th>수량</th>
                        <th>종류</th>
                        <th>개별중량</th>
                        <th>비고</th>
                        <th style={{ minWidth: '100px', width: '100px' }}>바코드</th>
                      </tr>
                    </thead>

                    <tbody>
                      {checkNullArray(_processLogList, []).map((processLog, index) => {
                        const processCustomizedContent = checkNullParse(processLog.processCustomizedContent, {
                          num: (index + 1).toString().padStart(2, '0'),
                          asmbly: '',
                          profile: '',
                          length: '',
                          quantity: '',
                          part: '',
                          weight: '',
                          name: '',
                        })
                        const barcode = `WorkOrder-${_formData.workOrderLogId}-${processLog.processLogId}-${processCustomizedContent.asmbly}`;

                        return (
                          <tr key={processLog.processLogId + '_CLRow'}>
                            <td style={{ minWidth: '50px', width: '50px' }}>
                              <CheckBox
                                act={''}
                                all={true}
                                checkboxKey={processLog.processLogId}
                                name={'processLogs'}
                              />
                            </td>
                            <td style={{ minWidth: '65px', width: '65px' }} data-col="Num">{checkEmptyNull(processCustomizedContent.num, (index + 1).toString().padStart(2, '0'))}</td>
                            <td data-col="asmbly" data-colname="부재번호">{processCustomizedContent.asmbly}</td>
                            <td data-col="profile" data-colname="규격">{processCustomizedContent.profile}</td>
                            <td data-col="length" data-colname="길이">{processCustomizedContent.length}</td>
                            <td data-col="quantity" data-colname="수량">{processCustomizedContent.quantity}</td>
                            <td data-col="part" data-colname="종류">{processCustomizedContent.part}</td>
                            <td data-col="weight" data-colname="개별중량">{processCustomizedContent.weight}</td>
                            <td data-col="name" data-colname="비고">{processCustomizedContent.name}</td>
                            <td style={{ minWidth: '100px', width: '100px' }} data-col="바코드" data-barcode={barcode}>
                              <QRCode value={barcode} onClick={copyBarcode}/>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                }
              />
            </ModalMain>
            <ModalFooter>
              <ModalButton onClick={actAddProduct}>물품 추가</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};
export default GSAddCLWorkList;
