import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { qualityActions_setEndDate, qualityActions_setPageNumber, qualityActions_setReset, qualityActions_setSearchData, qualityActions_setSearchOption, qualityActions_setSearchText, qualityActions_setStartDate, qualityActions_setTotalSize, qualityActions_setWorkOrderStatus, qualityActions_setWorkOrderType } from 'store/modules/actions/common/qualityActions';

import { qualityApi } from 'api/apis/qualityApi';
import { workOrderTypeApi } from 'api/apis/workOrderTypeApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import { LoadingMsg } from 'components/loading/LoadingMsg';

import ExcelDownloadModal from 'components/excel/ExcelDownloadModal';
import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import ProductionProcessTracking from 'pages/qualityControl/modal/ProductionProcessTracking';
import QualityRow from 'components/row/QualityRow';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import ShowFileList from 'pages/file/modal/ShowFileList';
import TableSection from 'components/layouts/table/TableSection';

const Section = styled.main`
  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;

    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
    & .typeButtons {display: flex; gap: 5px;}
  }
  .TableSection {
    thead tr {border: none;}
  }
`;

const QualityControl = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const { qualityReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_workOrderLogList, setWorkOrderLogList] = useState([]);

  const [_workOrderTypes, setWorkOrderTypes] = useState([]);

  const [_modalStatus, setModalStatus] = useState(false);
  const [_eventWorkOrderLogId, setEventWorkOrderLogId] = useState();

  const [_downloadModalStatus, setDownloadModalStatus] = useState(false);

  const [_fileListModalStatus, setFileListModalStatus] = useState(false);
  const [_eventcontent, setEventContent] = useState({});

  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('104') === true || // 거래처
      thisItem.authority.startsWith('109') === true || // 파일
      thisItem.authority.startsWith('601') === true,
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getWorkOrderTypes();
    getWorkOrderLogList(qualityReducer.pageNumber - 1, qualityReducer.workOrderType, qualityReducer.workOrderStatus);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getWorkOrderLogList(qualityReducer.pageNumber - 1, qualityReducer.workOrderType, qualityReducer.workOrderStatus);
    }

    return () => { };
  }, [qualityReducer.pageNumber]);

  /* ========================================================================= #4 */
  const getWorkOrderTypes = async () => {
    const BodyToPost = { companyId: userReducer.company.companyId };
    await workOrderTypeApi.searchWorkOrderType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderTypeApi.searchWorkOrderType : ', response);
      setWorkOrderTypes(() => { return response.data; });
    });
  };

  const getWorkOrderLogList = async (page, workOrderType, workOrderStatus, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = { companyId: userReducer.company.companyId };
    if (!clear && checkEmptyNull(workOrderType, false) && workOrderType !== 'all') {
      BodyToPost.workOrderTypeId = Number(workOrderType);
    }
    if (!clear && checkEmptyNull(workOrderStatus, false)) {
      switch (workOrderStatus) {
        case 'all': BodyToPost.workOrderStatus = ['end', 'cancel']; break;
        default: BodyToPost.workOrderStatus = [workOrderStatus]; break;
      }
    }
    if (!clear && checkEmptyNull(qualityReducer.startDate, false) && checkEmptyNull(qualityReducer.endDate, false)) {
      BodyToPost.actualStartDate = DateFormat(qualityReducer.startDate);
      BodyToPost.actualEndDate = DateFormat(qualityReducer.endDate);
      if (qualityReducer.startDate > qualityReducer.endDate) {
        BodyToPost.actualStartDate = DateFormat(qualityReducer.endDate);
        BodyToPost.actualEndDate = DateFormat(qualityReducer.startDate);
      }
    }
    if (!clear && checkEmptyNull(qualityReducer.searchData.workOrderCode, false)) {
      BodyToPost.workOrderCode = qualityReducer.searchData.workOrderCode;
    }
    if (!clear && checkEmptyNull(qualityReducer.searchData.workOrderName, false)) {
      BodyToPost.workOrderName = qualityReducer.searchData.workOrderName;
    }
    await qualityApi.searchQualityWorkOrderLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('qualityApi.searchQualityWorkOrderLog : ', response);
      setWorkOrderLogList(() => { return response.data.content; });
      dispatch(qualityActions_setTotalSize(response.data.totalElements));
      if (checkEmptyNull(qualityReducer.startDate, false) && checkEmptyNull(qualityReducer.endDate, false)) {
        if (qualityReducer.startDate > qualityReducer.endDate) {
          dispatch(qualityActions_setStartDate(qualityReducer.endDate))
          dispatch(qualityActions_setEndDate(qualityReducer.startDate))
        }
      }
    });
  };

  /* ========================================================================= #5 */
  /* 검색 - 작업상태 */
  const handleSearchWorkOrderStatus = async (workOrderStatus) => {
    dispatch(qualityActions_setWorkOrderStatus(workOrderStatus));
    dispatch(qualityActions_setPageNumber(1));
    await getWorkOrderLogList(0, qualityReducer.workOrderType, workOrderStatus)
  }

  /* 검색 */
  const actSearch = async () => {
    dispatch(qualityActions_setPageNumber(1));
    await getWorkOrderLogList(0, qualityReducer.workOrderType, qualityReducer.workOrderStatus);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(qualityActions_setReset());
    getWorkOrderLogList(0, 'all', 'all', true);
  };

  /* 생산추적 모달 */
  const showProductionProcessTracking = (eventId) => {
    setEventWorkOrderLogId(eventId);
    setTimeout(setModalStatus(true), 1000);
  };

  /* 다운로드 모달 */
  const actDownload = () => {
    setDownloadModalStatus(true);
  };

  const showFileList = (content, work) => {
    setEventContent(() => {
      if (work === true) return { ...content, work: true };
      else return content;
    });
    setTimeout(setFileListModalStatus(true), 1000);
  };

  /* ========================================================================= #6 */

  return (
    <Grid3Body contents={
      <>
        <Section className="Main">
          <NavBar
            title={
              <>
                <div className='Title'>
                  <NavTitle menuCode={'601'} />
                  <div className='typeButtons'>
                    {[
                      { status: 'all', name: '전체' },
                      { status: 'end', name: '완료' },
                      { status: 'cancel', name: '취소' },
                    ].map((workOrderStatus, index) => {
                      return (
                        <button key={index + '_statusButton'}
                          className={qualityReducer.workOrderStatus === workOrderStatus.status ? 'btn-inven-1' : 'btn-set'}
                          onClick={() => { handleSearchWorkOrderStatus(workOrderStatus.status); }}
                        >
                          {workOrderStatus.name}
                        </button>
                      )
                    })}
                  </div>
                </div>
              </>
            }
            buttons={
              <>
                <button className='btn-download' onClick={actDownload}>다운로드</button>
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <select
                    className="SelectType"
                    value={qualityReducer.workOrderType}
                    onChange={(e) => {
                      dispatch(qualityActions_setWorkOrderType(e.target.value))
                      dispatch(qualityActions_setPageNumber(1));
                      getWorkOrderLogList(0, e.target.value, qualityReducer.workOrderStatus);
                    }}
                  >
                    <option value="all">유형 전체</option>
                    {_workOrderTypes.map((thisItem) => {
                      return (<option key={thisItem.workOrderTypeId + '_workOrderType'} value={thisItem.workOrderTypeId}>{thisItem.workOrderTypeName}</option>);
                    })}
                  </select>
                  <div className="SearchSection">
                    <input
                      type="date"
                      className="SelectDate"
                      value={qualityReducer.startDate}
                      onChange={(e) => { dispatch(qualityActions_setStartDate(e.target.value)); }}
                    />
                    <IconRightArrow />
                    <input
                      type="date"
                      className="SelectDate"
                      value={qualityReducer.endDate}
                      onChange={(e) => { dispatch(qualityActions_setEndDate(e.target.value)); }}
                    />
                  </div>
                </div>
              </>
            }
            secondRow={
              <div className="SearchSection">
                <div className="ContentCBox">
                  <select className="SearchOption detail">
                    <option value="workOrderCode">작업코드</option>
                  </select>
                  <input
                    className="SearchBar detail"
                    placeholder="Search..."
                    value={checkEmptyNull(qualityReducer.searchData.workOrderCode, '')}
                    onInput={(e) => { dispatch(qualityActions_setSearchData({ ...qualityReducer.searchData, workOrderCode: e.target.value })); }}
                  />
                </div>

                <div className="ContentCBox">
                  <select className="SearchOption detail">
                    <option value="workOrderName">작업이름</option>
                  </select>
                  <input
                    className="SearchBar detail"
                    placeholder="Search..."
                    value={checkEmptyNull(qualityReducer.searchData.workOrderName, '')}
                    onInput={(e) => { dispatch(qualityActions_setSearchData({ ...qualityReducer.searchData, workOrderName: e.target.value })); }}
                  />
                </div>

                <div className="ContentCBox">
                  <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                  <div className="ResetButton" onClick={actReset}>초기화</div>
                </div>
              </div>
            }
          />

          <TableSection content={
            <table>
              <thead>
                <tr>
                  <th rowSpan={2} style={{ minWidth: '100px', padding: 'unset', width: '100px' }}></th>
                  <th rowSpan={2} style={{ minWidth: '80px', padding: 'unset', width: '80px' }}>작업<br />상태</th>
                  {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? (<th rowSpan={2}>파일</th>) : null}
                  <th rowSpan={2}>작업유형</th>
                  <th rowSpan={2}>작업코드</th>
                  <th rowSpan={2}>작업이름</th>
                  {(_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) ? (<th rowSpan={2}>거래처</th>) : null}
                  <th colSpan={5} style={{ backgroundColor: 'var(--ThirdBlue)' }}>생산목표품목</th>
                  <th rowSpan={2}>작업위치</th>
                  {![7].includes(userReducer.company.companyId) ? (<th rowSpan={2}>작업기간</th>) : null}
                  <th rowSpan={2}>작성자</th>
                  <th rowSpan={2}>책임자</th>
                  <th rowSpan={2}>작업자</th>
                </tr>
                <tr>
                  <th>물품정보</th>
                  <th className="resultProductStyle">목표<br />생산량</th>
                  <th className="resultProductStyle" style={{ backgroundColor: 'var(--MainBlue)' }}>총 생산량</th>
                  <th className="resultProductStyle" style={{ backgroundColor: 'var(--MainGreen)' }}>성공수량</th>
                  <th className="resultProductStyle" style={{ backgroundColor: 'var(--MainRed)' }}>실패수량</th>
                </tr>
              </thead>
              <tbody>
                {!checkNullArray(_workOrderLogList, false) ? LoadingMsg() :
                  checkNullArray(_workOrderLogList, []).map((thisItem, index) => {
                    return (
                      <QualityRow
                        key={index + '_qualityRow'}
                        authority={_authority}
                        content={thisItem}
                        index={index}
                        showFileList={showFileList}
                        showProductionProcessTracking={showProductionProcessTracking}
                      />
                    )
                  })}
              </tbody>
            </table>
          }
          ></TableSection>
        </Section>

        <PagingComponent
          page={qualityReducer.pageNumber}
          count={qualityReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(qualityActions_setPageNumber(page));
          }}
        />

        {_modalStatus === true && (
          <ProductionProcessTracking
            productionId={_eventWorkOrderLogId}
            open={_modalStatus}
            close={() => { setModalStatus(false) }}
          />
        )}

        {_downloadModalStatus === true ? (
          <ExcelDownloadModal
            mainTitle={'Quality'}
            subTitle={'quality'}
            open={_downloadModalStatus}
            close={() => { setDownloadModalStatus(false) }}
          />
        ) : null}

        {_fileListModalStatus === true && _eventcontent.workOrderLogId !== undefined ? (
          <ShowFileList
            type={'workOrderLog'}
            content={_eventcontent}
            open={_fileListModalStatus}
            close={() => { setFileListModalStatus(false) }}
          ></ShowFileList>
        ) : null}
      </>
    }
    />
  );
};

export default QualityControl;
