import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { accountTypeApi } from 'api/apis/accountTypeApi';
import { accountAttributeApi } from 'api/apis/accountAttributeApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const Content = styled.div`
  background-color: var(--white);
  box-sizing: border-box;
  border-radius: 8px;
  display: grid;
  grid-template-rows: 60px auto;
  width: 100%; height: 100%;
`;
const ContentHeader = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  padding: 24px 16px;
`;
const Title = styled.h1`
  font-size: 20px;
`;
const SelectType = styled.select`
  background-color: var(--white);
  border: 1px solid var(--gray-200);
  border-radius: 5px;
  height: 35px;
  padding: 2px 10px;
  width: 180px;
`;
const SearchBar = styled.div`
  align-items: center;
  border-radius: 8px;
  display: flex; gap: 8px;
  input {
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    padding: 4px 16px;
    border-radius: 8px;
    width: 100%;
    font-size: 1rem;
  }
`;
const ButtonsWrap = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  height: 100%;
  justify-content: flex-end;
`;


const AccountAttr = (props) => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_defaultList, setDefaultList] = useState([
    { key: 'accountCode', value: '거래처코드/수요기관코드', },
    { key: 'businessNumber', value: '사업자등록번호', },
    { key: 'accountName', value: '회사명', },
    { key: 'regionName', value: '지역명', },
    { key: 'address', value: '주소', },
    { key: 'detailAddress', value: '상세주소', },
    { key: 'zip', value: '우편번호', },
    { key: 'managerName', value: '담당자', },
    { key: 'telNumber', value: '전화번호', },
    { key: 'phoneNumber', value: '휴대폰', },
    { key: 'faxNumber', value: '팩스번호', },
    { key: 'email', value: '이메일', },
  ]);
  const [_attrList, setAttrList] = useState([]);

  const [_typeList, setTypeList] = useState([]);
  const [_attrType, setAttrType] = useState('all');

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_searchText, setSearchText] = useState();

  /* ====================================================================== #3 */
  useEffect(() => {
    getAccountTypes();
    // getAccountAttrs(_attrType);

    setOnload('loaded');
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const getAccountTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await accountTypeApi.searchAccountType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('accountTypeApi.searchAccountType : ', response);

      if (checkNullArray(response.data, false)) {
        setAttrType(() => { return response.data[0].accountTypeId })
        getAccountAttrs(response.data[0].accountTypeId);
      }
      setTypeList(() => { return response.data });
    });
  };

  const getAccountAttrs = async (accountType) => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (checkEmptyNull(accountType, false) && accountType !== 'all') {
      BodyToPost.accountTypeId = Number(accountType);
    }
    if (checkEmptyNull(_searchText, false)) {
      BodyToPost.accountAttributeName = _searchText;
    }
    console.log('BodyToPost : ', BodyToPost);

    await accountAttributeApi.searchAccountAttribute(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('accountAttributeApi.searchAccountAttribute : ', response);

      setAttrList(() => { return response.data });
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) {
      checkedItem.add(id);
    }
    setCheckedItem(() => {
      return checkedItem;
    });
  };

  const actSearch = async () => {
    getAccountAttrs(_attrType);
  };

  /* 추가 */
  const actCreate = async () => {
    if (_attrType === 'all') return alert('거래처 유형을 선택해주세요.');

    const typeData = _typeList.find((thisItem) => {
      if (thisItem.accountTypeId === Number(_attrType)) return true;
      else return false;
    });

    if (typeData === undefined) return;

    const promptText = `
    거래처 유형 : ${typeData.accountTypeName}
    추가할 항목명을 입력해주세요.
    `;
    const CreatePrompt = window.prompt(promptText, '');
    if (CreatePrompt === null) return;

    const BodyToPost = {
      companyId: userReducer.company.companyId,
      accountTypeId: Number(_attrType),
      accountAttributeName: CreatePrompt,
    };
    console.log('actCreate - body : ', BodyToPost);

    await accountAttributeApi.createAccountAttribute(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('accountAttributeApi.createAccountAttribute : ', response);

      setAttrList((prev) => {
        return [...prev, response.data];
      });

      alert('항목을 추가했습니다.');
    });
  };

  /* 수정 */
  const actUpdate = async () => {
    const updateContentIndex = _attrList.findIndex((thisItem) => thisItem.accountAttributeId === Array.from(_checkedItem)[0]);
    if (updateContentIndex === -1) return alert('수정할 항목을 선택해 주세요.');
    const updateContent = _attrList[updateContentIndex];
    console.log('updateContent : ', updateContent);

    const typeData = _typeList.find((thisItem) => {
      if (thisItem.accountTypeId === updateContent.accountTypeId) return true;
      else return false;
    });

    if (typeData === undefined) return;

    const promptText = `
    거래처 유형 : ${typeData.accountTypeName}
    항목을 수정합니다.
    `;
    const UpdatePrompt = window.prompt(
      promptText,
      updateContent.accountAttributeName,
    );
    if (UpdatePrompt === null) return;

    const BodyToPut = {
      companyId: userReducer.company.companyId,
      accountTypeId: updateContent.accountTypeId,
      accountAttributeId: updateContent.accountAttributeId,
      accountAttributeName: UpdatePrompt,
    };
    console.log('actUpdate - body : ', BodyToPut);

    await accountAttributeApi.updateAccountAttribute(updateContent.accountAttributeId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('accountAttributeApi.updateAccountAttribute : ', response);

      setAttrList((prev) => {
        const prevData = [...prev];
        prevData.splice(updateContentIndex, 1, response.data);

        return prevData;
      });

      alert('항목을 수정했습니다.');
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const deleteContentIndex = _attrList.findIndex((thisItem) => thisItem.accountAttributeId === Array.from(_checkedItem)[0]);
    if (deleteContentIndex === -1) return alert('삭제할 항목을 선택해 주세요.');
    const deleteContent = _attrList[deleteContentIndex];
    console.log('deleteContent : ', deleteContent);

    const typeData = _typeList.find((thisItem) => {
      if (thisItem.accountTypeId === deleteContent.accountTypeId) return true;
      else return false;
    });

    if (typeData === undefined) return;

    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({
        content: JSON.stringify(deleteContent),
        type: 'account',
        typeData: JSON.stringify(typeData),
      })}`,
      replace: true,
    });
  };

  /* ====================================================================== #6 */

  return (
    <Content>
      <ContentHeader>
        <Title>거래처 항목 설정</Title>
        <div className='content-snb'>
          <SelectType
            value={_attrType}
            onChange={(e) => {
              setAttrType(() => {
                return e.target.value;
              });
              getAccountAttrs(e.target.value);
            }}
          >
            <option value="all">거래처 유형 선택</option>
            {_typeList.map((thisType) => {
              if (thisType.accountTypeName === userReducer.company.companyName)
                return null;
              return (
                <option key={thisType.accountTypeId} value={thisType.accountTypeId}>
                  {thisType.accountTypeName}
                </option>
              );
            })}
          </SelectType>
          <ButtonsWrap>
            {props.authority.indexOf('103-2') !== -1 ? (
              props.authority.indexOf('104-2') !== -1 ? (
                <>
                  <button className='btn-add' onClick={actCreate}>추가</button>
                  <button className='btn-edit' onClick={actUpdate}>수정</button>
                  <button className='btn-delete' onClick={actDelete}>삭제</button>
                </>
              ) : null
            ) : null}
          </ButtonsWrap>
          <SearchBar>
            <input
              placeholder="항목명..."
              onInput={(e) => {
                setSearchText(() => {
                  return e.target.value;
                });
              }}
            />
            <button data-searchbutton="true" onClick={actSearch}>
              <SearchButtonIcon />
            </button>
          </SearchBar>
        </div>
      </ContentHeader>
      <TableSection
        content={
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th style={{ minWidth: 'unset', width: '50px' }}></th>
                <th>항목명</th>
              </tr>
            </thead>
            <tbody>
              {_defaultList.map((attr, index) => {
                return <tr key={index + '_default'}>
                  <td style={{ backgroundColor: 'var(--MainNavy)', minWidth: 'unset', width: '50px' }}></td>
                  <td>{attr.value}</td>
                </tr>
              })}
              {_attrList.map((attr, index) => {
                return (
                  <tr key={index + '_attr'}>
                    <td style={{ minWidth: 'unset', width: '50px' }}>
                      <input
                        type="checkBox"
                        name="accountAttrs"
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          handleCheckedItem(attr.accountAttributeId, isChecked);

                          if (isChecked) {
                            document.getElementsByName('accountAttrs').forEach((thisAttr) => { thisAttr.checked = false });
                            e.target.checked = true;
                          } else {
                            e.target.checked = false;
                          }
                        }}
                      />
                    </td>
                    <td>{attr.accountAttributeName}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        }
      ></TableSection>
    </Content>
  );
};

export default AccountAttr;
