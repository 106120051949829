const initialState = {
  tokenTime: null,
  loading: false,
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GLOBAL_TOKENTIME':
      return {
        ...state,
        tokenTime: action.payload,
      };
    case 'GLOBAL_LOADING':
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default globalReducer;
