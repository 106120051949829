import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { elementStockLogApi } from 'api/apis/elementStockLogApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat';

import DOCButtonIcon from 'components/icons/DOCButtonIcon';
import PagingComponent from 'components/paging/PagingComponent';
import ShowFileList from 'pages/file/modal/ShowFileList';
import TableSection from 'components/layouts/table/TableSection';

const StockLogMain = styled.div`
  display: grid;
  gap: 10px;
  grid-template-rows: ${(props => {
    return props.mobile ? '300px auto' : '300px calc(100% - 310px)';
  })};
  height: 100%;
  position: relative;
  width: 100%;

  &.onlyLog {
    grid-template-rows: ${(props => {
    return props.mobile ? 'auto' : '100%';
  })};
  }

  & .sec-stockLog {
    align-content: flex-start;
    background-color: var(--white);
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 30% 70%;
    gap: 20px;
    height: 100%;
    padding: 20px;
    width: 100%;

    &.hide {display: none;}

    & .thumbnail {
      align-self: center;
      height: fit-content;
      margin: 0px auto;
      position: relative;
      width: fit-content;

      & img {
        max-height: 200px;
        max-width: 90%;
        margin: 0px auto;
      }

      & .btn-file {
        bottom: 10px;
        right: 20px;
        position: absolute;
      }
    }

    & .contents-stockLog {
      display: grid;
      grid-gap: 20px;

      & .con-stockLog {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: flex-start;
  
        & h4 {
          border-right: 2px solid var(--gray-100);
          width: 100px;
        }
        & p {
          box-sizing: border-box;
          font-size: 0.9em;
        }
      }
    }

  }

  & .sec-stockLogList {
    display: grid;
    gap: 10px;
    grid-template-rows: calc(100% - 50px) 40px;
    height: 100%;
    width: 100%;

    & .TableSection {
      & tr.active td {
        background-color: var(--MainBlue);
        color: var(--white);
      }
    }
  }
`;

const StockLogInfo = (props) => {
  const mobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  const dispatch = useDispatch();

  const { userReducer } = useSelector(state => state);

  const [_stockLogData, setStockLogData] = useState(props.stockLogData);
  const [_stockLogConData, setStockLogConData] = useState(props.stockLogConData);

  const [_elementStockLogList, setElementStockLogList] = useState([]);
  const [_pageNum, setPageNum] = useState(1);
  const [_totalSize, setTotalSize] = useState(0);

  const [_fileListModalStatus, setFileListModalStatus] = useState(false);

  useEffect(() => {
    setStockLogData(() => { return props.stockLogData });
    setStockLogConData(() => { return props.stockLogConData });

    getStockLogs(props.stockLogData.elementId, 0);
  }, [props]);
  useEffect(() => {
    getStockLogs(props.stockLogData.elementId, _pageNum - 1);
  }, [_pageNum])

  const getStockLogs = async (elementId, page) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      elementId: elementId,
    };

    await elementStockLogApi.searchElementStockLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('elementStockLogApi.searchElementStockLog : ', response);
      setElementStockLogList(() => { return response.data.content });
      setTotalSize(() => { return response.data.totalElements })
    });
  }

  return (
    <>
      <StockLogMain mobile={mobile()} className={checkNullArray(_stockLogConData, false) ? '' : 'onlyLog'}>
        <section className={checkNullArray(_stockLogConData, false) ? 'sec-stockLog' : 'sec-stockLog hide'}>
          {_stockLogData.attachFileList[0] ?
            <div className='thumbnail'
              onClick={(e) => {
                e.stopPropagation();
                setFileListModalStatus(true);
              }}
            >
              <img src={_stockLogData.attachFileList[0]?.fileData?.fileDataS3URL || ''} alt='image preview' />
              <div className='btn-file'><DOCButtonIcon /></div>
            </div>
            : <div></div>}
          <div className='contents-stockLog'>
            {checkNullArray(_stockLogConData, []).map((conData, index) => {
              return (
                <div key={index + '_conData'} className='con-stockLog'>
                  <h4>{conData.key}</h4>
                  <p>{conData.value}</p>
                </div>
              )
            })}
          </div>
        </section>
        <section className='sec-stockLogList'>
          <TableSection content={
            <table>
              <thead>
                <tr>
                  <th>입력시각</th>
                  <th>수량</th>
                  <th>금액</th>
                  <th>재고위치</th>
                  <th>비고</th>
                  <th>작업자</th>
                </tr>
              </thead>
              <tbody>
                {checkNullArray(_elementStockLogList, []).map((stockLog, index) => {
                  return (
                    <tr key={index + '_stockLog'} className={_stockLogData.elementStockLogId === stockLog.elementStockLogId ? 'active' : ''}>
                      <td>{DateTimeFormat(checkEmptyNull(stockLog.createdDate, ''))}</td>
                      <td>{props.handleLogType(stockLog.logType)} {checkEmptyNull(stockLog.amount, 0).toLocaleString()}</td>
                      <td>{checkEmptyNull(stockLog.price, 0).toLocaleString()}</td>
                      <td>{stockLog.stockPlacedName}</td>
                      <td>{stockLog.note}</td>
                      <td>{stockLog.userName}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          }
          />

          <PagingComponent
            page={_pageNum}
            count={_totalSize}
            size={10}
            pageEvent={(page) => {
              console.log('page : ', page);
              setPageNum(() => { return page });
            }}
          />
        </section>
      </StockLogMain>

      {_fileListModalStatus === true && (
        <ShowFileList
          type={'elementStockLog'}
          content={_stockLogData}
          open={_fileListModalStatus}
          close={() => { setFileListModalStatus(false) }}
        ></ShowFileList>
      )}
    </>
  );
};

export default StockLogInfo;