import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { shipmentInspectionItemType } from 'api/apis/shipmentInspection/shipmentInspectionItemType';
import { shipmentInspectionItem } from 'api/apis/shipmentInspection/shipmentInspectionItem';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 40px 50px;
  height: 85%;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const ModalHeaderButtons = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 55px;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
    td.blankCell {background-color: var(--Bg); opacity: 0.8;}
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const SelectShipmentInspectionItems = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const ItemCheckBox = useRef();

  const [_onload, setOnload] = useState('unload');

  const [_shipmentInspectionItemList, setShipmentInspectionItemList] = useState([]);

  const [_shipmentInspectionItemTypes, setShipmentInspectionItemTypes] = useState([]);
  const [_shipmentInspectionItemType, setShipmentInspectionItemType] = useState('all');

  const [_checkedItem, setCheckedItem] = useState([]);

  const [_searchData, setSearchData] = useState({});
  const [_searchOption, setSearchOption] = useState('');
  const [_searchText, setSearchText] = useState('');

  const [_pageNum, setPageNum] = useState(1);
  const [_pageSize, setPageSize] = useState(0);

  /* ====================================================================== #3 */
  useEffect(() => {
    getShipmentInspectionItemTypes();
    getShipmentInspectionItemList(0, 'all');

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getShipmentInspectionItemList(_pageNum - 1, _shipmentInspectionItemType);
      ItemCheckBox.current.checked = false;
    }
    return () => { };
  }, [_pageNum]);

  useEffect(() => { }, [_checkedItem]);

  /* ====================================================================== #4 */
  const getShipmentInspectionItemTypes = async () => {
    await shipmentInspectionItemType.searchShipmentInspectionItemType({ companyId: userReducer.company.companyId }).then((response) => {
      if (response === undefined) return;
      console.log('shipmentInspectionItemType.searchShipmentInspectionItemType : ', response);
      setShipmentInspectionItemTypes(() => { return response.data; });
    })
  };

  const getShipmentInspectionItemList = async (page, shipmentInspectionItemTypeId, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && checkEmptyNull(shipmentInspectionItemTypeId)) {
      BodyToPost.shipmentInspectionItemTypeId = Number(shipmentInspectionItemTypeId);
    }
    if (!clear && checkEmptyNull(_searchOption, false) && checkEmptyNull(_searchText, false)) {
      BodyToPost[_searchOption] = _searchText;
    }

    await shipmentInspectionItem.searchShipmentInspectionItem(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('shipmentInspectionItem.searchShipmentInspectionItem : ', response);
      setShipmentInspectionItemList(() => { return response.data.content; });
      setPageSize(() => { return response.data.totalElements })
    })
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (item, isChecked) => {
    const checkedItem = [..._checkedItem];
    const checkedIndex = checkedItem.findIndex((thisItem) => thisItem.shipmentInspectionItemId === item.shipmentInspectionItemId);
    if (checkedIndex !== -1) {
      if (!isChecked) checkedItem.splice(checkedIndex, 1);
    } else {
      if (isChecked) checkedItem.push(item);
    }
    setCheckedItem(() => { return checkedItem; });
  };

  /* 검색 */
  const actSearch = async () => {
    setPageNum(() => { return 1; });
    await getShipmentInspectionItemList(0, _shipmentInspectionItemType);
  };

  /* 초기화 */
  const actReset = async () => {
    setShipmentInspectionItemType(() => { return 'all'; });
    setSearchData(() => { return {}; });
    setSearchOption(() => { return ''; });
    setSearchText(() => { return ''; });
    setPageNum(() => { return 1; });
    await getShipmentInspectionItemList(0, 'all', true);
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle><NavTitle menuCode={'113'} /> 선택</ModalTitle>
              <ModalHeaderButtons></ModalHeaderButtons>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <NavBar
                title={
                  <div className="SearchSection">
                    <select
                      className="SelectType"
                      value={_shipmentInspectionItemType}
                      onChange={(e) => {
                        const shipmentInspectionItemType = e.target.value;
                        setShipmentInspectionItemType(() => { return shipmentInspectionItemType; });
                        setPageNum(() => { return 1; });
                        getShipmentInspectionItemList(0, shipmentInspectionItemType);
                      }}
                    >
                      <option value="all">전체</option>
                      {_shipmentInspectionItemTypes.map((type, index) => {
                        return (<option key={index + '_shipmentInspectionItemType'} value={type.shipmentInspectionItemTypeId}>{type.shipmentInspectionItemTypeName}</option>);
                      })}
                    </select>

                    <div className="SearchSection">
                      <div className="ContentCBox">
                        <div className="ContentCBox">
                          <select
                            className="SearchOption"
                            value={_searchOption}
                            onChange={(e) => {
                              const searchOption = e.target.value;
                              setSearchOption(() => { return searchOption; })
                            }}
                          >
                            {[
                              { optionValue: '', optionText: '검색옵션' },
                              { optionValue: 'content', optionText: '점검 / 검사 내용' },
                              { optionValue: 'criteria', optionText: '판정 기준' },
                            ].map((option) => {
                              return (<option key={option.optionValue + '_shipmentInspectionSearchOption'} value={option.optionValue}>{option.optionText}</option>);
                            })}
                          </select>

                          <input
                            className="SearchBar"
                            placeholder="Search..."
                            value={_searchText}
                            onInput={(e) => {
                              const searchText = e.target.value;
                              setSearchText(() => { return searchText; })
                            }}
                          />
                        </div>

                        <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                        <div className="ResetButton" onClick={actReset}>초기화</div>
                      </div>
                    </div>
                  </div>
                }
                nav={''}
              />

              <TableSection content={
                <table>
                  <thead>
                    <tr>
                      <th style={{ minWidth: '50px', width: '50px' }}>
                        <input
                          type="checkBox"
                          name="allElements"
                          ref={ItemCheckBox}
                          onChange={(e) => {
                            const isChecked = e.target.checked;

                            if (isChecked) {
                              setCheckedItem((prev) => {
                                const prevData = [...prev];
                                const newData = _shipmentInspectionItemList.map((thisItem) => {
                                  const thisFindIndex = prevData.findIndex((thisIndex) => thisIndex.shipmentInspectionItemId === thisItem.shipmentInspectionItemId);
                                  if (thisFindIndex !== -1) return null;
                                  else return thisItem;
                                });
                                const filterData = newData.filter((thisItem) => thisItem !== null);
                                return [...prevData, ...filterData];
                              });
                              e.target.checked = true;
                            } else {
                              setCheckedItem((prev) => {
                                const prevData = prev.map((thisItem) => {
                                  const thisFindIndex = _shipmentInspectionItemList.findIndex((thisIndex) => thisIndex.shipmentInspectionItemId === thisItem.shipmentInspectionItemId);
                                  if (thisFindIndex !== -1) return null;
                                  else return thisItem;
                                });
                                const filterData = prevData.filter((thisItem) => thisItem !== null);
                                return filterData;
                              });
                              e.target.checked = false;
                            }
                          }}
                        />
                      </th>
                      <th>점검 / 검사 내용</th>
                      <th>판정 기준</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkNullArray(_shipmentInspectionItemList, []).map((shipmentInspectionItem, index) => {
                      return (<tr key={index + '_shipmentInspectionItem'}>
                        <td style={{ minWidth: '50px', width: '50px' }}>
                          <input
                            type="checkBox"
                            name="shipmentInspectionItems"
                            checked={_checkedItem.findIndex(item => item.shipmentInspectionItemId === shipmentInspectionItem.shipmentInspectionItemId) !== -1}
                            onChange={(e) => {
                              e.preventDefault();
                              const isChecked = e.target.checked;
                              handleCheckedItem(shipmentInspectionItem, isChecked);
                            }}
                          />
                        </td>
                        <td>{shipmentInspectionItem.content}</td>
                        <td>{shipmentInspectionItem.criteria}</td>
                      </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
              ></TableSection>
            </ModalMain>

            <PagingComponent
              page={_pageNum}
              count={_pageSize}
              size={10}
              pageEvent={(page) => {
                console.log('page : ', page);
                setPageNum(() => { return page; });
              }}
            />

            <ModalFooter>
              <ModalButton onClick={() => { props.buttonEvent(_checkedItem); }}>{props.buttonTitle}</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default SelectShipmentInspectionItems;
