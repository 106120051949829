import React, { useEffect, useState } from 'react';

import { cluster } from 'api/apis/operator/cluster';
import { company } from 'api/apis/operator/company';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { ClusterComponent } from './Cluster.style';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import LoadingComponent from 'components/loading/LoadingComponent';

const RegisterCluster = (props) => {
  const [_clusterList, setClusterList] = useState([]); // 클러스터 리스트
  const [_checkedItem, setCheckedItem] = useState(props.company.cluster?.clusterId || ''); // 선택한 클러스터

  const [_loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => {
    getClusterList();
    return () => { }
  }, [])
  useEffect(() => { }, [_checkedItem, _loadingStatus]);

  const getClusterList = async () => { // 클러스터 리스트 불러오기
    await cluster.search('', {}).then((response) => {
      if (response === undefined) return;
      setClusterList(() => { return response.data.content });
    })
  }

  const actRegister = async (e) => { // 클러스터 등록
    e.preventDefault();
    if (!checkEmptyNull(_checkedItem, false)) return alert('등록할 클러스터를 선택해주세요.');
    setLoadingStatus(() => { return true }); // 로딩 화면 표시
    await company.updateCompany(
      props.company.companyId,
      { ...props.company, clusterId: Number(_checkedItem) }
    ).then((response) => {
      if (response === undefined) return;
      alert('클러스터를 등록했습니다.');
      setLoadingStatus(() => { return false }); // 로딩 화면 숨기기
      props.update();
    })
  };

  return (<>
    {props.open === true && (
      <ClusterComponent>
        <section className='register'>
          <header>
            <h4>클러스터 등록</h4>
            <div className='btn-close' onClick={props.close}><CloseButtonIcon /></div>
          </header>

          <div>
            <Form
              title={<>클러스터<br />등록</>}
              buttons={<></>}
              forms={<>
                <div className="formBox w100">
                  <h4>클러스터</h4>
                  <select value={_checkedItem} onChange={(e) => { setCheckedItem(() => { return e.target.value; }) }}>
                    <option value={''}>클러스터 선택</option>
                    {checkNullArray(_clusterList, []).map((cluster, index) => {
                      return (<option key={cluster.clusterId + '_cluster'} value={cluster.clusterId}>{cluster.clusterName}</option>);
                    })}
                  </select>
                </div>
              </>}
            />
          </div>

          <div className='btn-modal' onClick={actRegister}>등록</div>
        </section>
      </ClusterComponent>
    )}

    {_loadingStatus ? (<LoadingComponent close={props.close} title={'클러스터를 등록하고 있습니다...'} />) : null}
  </>);
};

export default RegisterCluster;
