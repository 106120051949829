import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

import { contractApi } from 'api/apis/contractApi';
import { procurementApi } from 'api/apis/procurementApi';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullArray, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import SelectOneAccount from 'pages/account/modal/SelectOneAccount';
import TableSection from 'components/layouts/table/TableSection';

const SelectSection = styled.div``;
const SelectButtonSection = styled.div`
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 50px;
  overflow: hidden;
  width: 100%;
`;
const SelectButton = styled.div`
  align-items: center;
  background-color: ${(props) => {
    return props.type.startsWith('add_') === true ? 'var(--WorkingColor)'
      : props.type.startsWith('replace_') === true ? 'var(--Excel)'
        : props.type.startsWith('cancel_') === true ? 'var(--gray-200)'
          : null;
  }};
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 8px;
  white-space: nowrap;
  width: 100%;
`;
const FileTableSection = styled.div`
  cursor: pointer;
  width: 100%;
  &:hover {opacity: 0.6;}
  & > .TableSection {
    max-height: 200px;
    overflow: auto;
    td, th {min-width: unset;}
    &::-webkit-scrollbar-thumb {background-color: var(--ThirdBlue);}
  }
`;
const FileLabelStyle = styled.label`
  align-items: center;
  background-color: var(--MainBlue);
  border-radius: 5px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const ContractDate = styled.input`
  border: 1px solid var(--gray-200);
  border-radius: 5px;
  box-sizing: border-box;
  height: 40px;
  padding: 5px;
  &:focus {border: 1px solid var(--gray-200);}
`;

const UpdateGSContract = () => {
  /* ===================================================#======================================================*/
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* =================================================== #2 ===================================================*/
  const [searchParams, setSearchParams] = useSearchParams();
  const updateContent = checkNullParse(searchParams.get('updateContent'), {});

  const [_onload, setOnload] = useState('unload');

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId, // 회사
    userId: userReducer.user.id, // 작성자
    contractId: updateContent.contractId,
    contractDate: DateFormat(checkEmptyNull(updateContent.contractDate, '')), // 수주일자
    contractRegDate: DateFormat(checkEmptyNull(updateContent.contractRegDate, '')), // 납품요구접수일자
    scheduledEndDate: DateFormat(checkEmptyNull(updateContent.scheduledEndDate, '')), // 납품기한일자
    scheduledEndDate2: DateFormat(checkEmptyNull(updateContent.scheduledEndDate2, '')), // ?
    contractActualEndDate: DateFormat(checkEmptyNull(updateContent.contractActualEndDate, '')), // 납품일자
    contractTypeId: updateContent.contractTypeId, // 수주유형
    contractStatus: updateContent.contractStatus, // 수주상태
    contractCode: checkEmptyNull(updateContent.contractCode, ''), // 수주코드
    contractName: checkEmptyNull(updateContent.contractName, ''), // 수주명
    accountId: updateContent.accountId, // 거래처
    accountName: updateContent.accountName, // 거래처
    contractPrice: updateContent.contractPrice, // 수주총금액
    address: checkEmptyNull(updateContent.address, ''), // 수주주소
    remark: checkEmptyNull(updateContent.remark, ''), // 비고
    monitoringRemark: checkEmptyNull(updateContent.monitoringRemark, ''), // 특이사항
    contractCustomizedContent: checkNullParse(updateContent.contractCustomizedContent), // 커스텀
    contractAttributeValueList: [], // 항목
    deleteContractAttributeValueList: [], // 항목 삭제
    contractElementList: [], // 수주물품
    deleteContractElementList: [], // 수주물품 삭제
    procurementList: checkNullArray(updateContent.procurementList, []), // 발주
    workOrderLogList: checkNullArray(updateContent.workOrderLogList, []), // 작업지시
  });

  const [_account, setAccount] = useState({ accountId: updateContent.accountId, accountName: updateContent.accountName }); // 거래처

  const [_modalStatus, setModalStatus] = useState(false);

  const [_procurementFormData, setProcurementFormData] = useState({});
  const [_fileProcurement, setFileProcurement] = useState([]);
  const [_selectType, setSelectType] = useState();

  const [_workOrderFormData, setWorkOrderFormData] = useState({});
  const [_prevBL, setPrevBL] = useState([]);
  const [_prevCP, setPrevCP] = useState([]);
  const [_prevCL, setPrevCL] = useState([]);
  const [_fileBL, setFileBL] = useState([]);
  const [_fileCP, setFileCP] = useState([]);
  const [_fileCL, setFileCL] = useState([]);
  const [_selectBLType, setSelectBLType] = useState();
  const [_selectCPType, setSelectCPType] = useState();
  const [_selectCLType, setSelectCLType] = useState();

  const [_loading, setLoading] = useState(false);

  /* =================================================== #3 =================================================== */
  useEffect(() => {
    getProcurementData();
    getWorkOrderData();

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => { }, [_loading])

  /* =================================================== #4들 =================================================== */
  const getProcurementData = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      contractId: updateContent.contractId,
    };
    await procurementApi.searchProcurement('', BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('procurementApi.searchProcurement : ', response);
      Finished_getProcurementData(response.data.content[0]);
    })
  };
  const Finished_getProcurementData = (Result) => {
    const procurementData = {
      companyId: userReducer.company.companyId,
      userId: userReducer.user.id,
      procurementStatus: checkEmptyNull(Result.procurementStatus, 'waiting'),
      procurementId: Result.procurementId,
      procurementCode: Result.procurementCode,
      procurementName: Result.procurementName,
      procurementDate: DateFormat(checkEmptyNull(Result.procurementDate, '')),
      scheduledEndDate: DateFormat(checkEmptyNull(Result.scheduledEndDate, '')),
      accountId: Result?.account?.accountId,
      procurementPrice: Result.procurementPrice,
      procurementElementList: Result.procurementElementList.map((element) => {
        return {
          status: element.status,
          procurementElementId: element.procurementElementId,
          elementId: element.element?.elementId,
          element: element.element,
          customizedContent: element.customizedContent,
          // customizedContent: checkNullParse(element.customizedContent, {}),
          price: element.price,
          quantity: element.quantity,
          storeQuantity: element.storeQuantity,
        };
      }),
      deleteProcurementElementList: [],
    }
    setProcurementFormData(() => { return procurementData; });
  };

  const getWorkOrderData = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      contractId: updateContent.contractId,
    };
    await workOrderLogApi.searchWorkOrderLog('', BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.searchWorkOrderLog : ', response);
      Finished_getWorkOrderData(response.data.data.content[0]);
    })
  };
  const Finished_getWorkOrderData = (Result) => {
    const processLogList = Result.processLogList.map((thisItem) => {
      return {
        existProcessOutElement: false,
        processTypeName: thisItem.processTypeName,
        processStatus: thisItem.processStatus,
        processLogId: thisItem.processLogId,
        processName: thisItem?.processName,
        processCode: thisItem?.processCode,
        processingCount: thisItem.processingCount,
        processCustomizedContent: thisItem.processCustomizedContent,
        deleteTaskIds: [],
        taskLogList: [],
      };
    });

    const workOrderData = {
      companyId: userReducer.company.companyId,
      userId: userReducer.user.id,
      workOrderLogId: Result.workOrderLogId,
      workOrderStatus: Result.workOrderStatus,
      workOrderScheduledStartDate: DateFormat(checkEmptyNull(Result.workOrderScheduledStartDate, '')),
      workOrderScheduledEndDate: DateFormat(checkEmptyNull(Result.workOrderScheduledEndDate, '')),
      accountId: Result.accountId,
      workPlace: Result.workPlace,
      detailedWorkPlace: Result.detailedWorkPlace,
      message: Result.message,
      remark: Result.remark,
      workOrderCustomizedContent: Result.workOrderCustomizedContent,
      processLogList: processLogList,
      deleteProcessLogList: [],
    }
    setWorkOrderFormData(() => { return workOrderData });

    setPrevBL(() => { return processLogList.filter((thisItem) => thisItem.processTypeName === 'BL') });
    setPrevCP(() => { return processLogList.filter((thisItem) => thisItem.processTypeName === 'CP') });
    setPrevCL(() => { return processLogList.filter((thisItem) => thisItem.processTypeName === 'CL') });
  };



  /* =================================================== #5들 =================================================== */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value } });
  };
  const handleCustomEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return { ...prev, contractCustomizedContent: { ...prev.contractCustomizedContent, [name]: value } }
    });
  };

  // 발주서 업로드
  const setProcurementFile = (e) => {
    const excelType = 'procurement';
    excelExportCommon(e, excelSetting, excelType);
  };
  // 캠버리스트 업로드
  const setCamberFile = (e) => {
    const excelType = 'camber';
    excelExportCommon(e, excelSetting, excelType);
  };
  // 조합리스트 업로드
  const setCombinationFile = (e) => {
    const excelType = 'combination';
    excelExportCommon(e, excelSetting, excelType);
  };
  // 체크리스트 업로드
  const setCheckFile = (e) => {
    const excelType = 'check';
    excelExportCommon(e, excelSetting, excelType);
  };

  // 엑셀
  const excelExportCommon = (e, callback, excelType) => {
    const fileList = e.target.files;
    const file = fileList[0];
    console.log(file);
    if (file === undefined) return;

    switch (excelType) {
      case 'procurement': document.getElementById('procurementFileTable').innerHTML = ''; break;
      case 'camber': document.getElementById('camberFileTable').innerHTML = ''; break;
      case 'combination': document.getElementById('combinationFileTable').innerHTML = ''; break;
      case 'check': document.getElementById('checkFileTable').innerHTML = ''; break;
      default: return;
    }

    const reader = new FileReader();
    reader.onload = async () => {
      const fileData = reader.result;
      const wb = XLSX.read(fileData, { type: 'binary' });
      const sheetNameList = wb.SheetNames; // 시트 이름 목록 가져오기
      for (let i = 0; i < sheetNameList.length; i++) {
        const sheetName = sheetNameList[i];
        const sheet = wb.Sheets[sheetName];
        await callback(sheet, excelType);
      }
    };
    reader.readAsBinaryString(file);
  };
  const excelSetting = (sheet, excelType) => {
    switch (excelType) {
      case 'procurement':
        const procurement_result = XLSX.utils.sheet_to_html(sheet);
        console.log(excelType, ' : ', procurement_result);
        document.getElementById('procurementFileTable').innerHTML = procurement_result;
        setProcurementTable();
        break;

      case 'camber':
        const camber_table_result = XLSX.utils.sheet_to_html(sheet);
        const camber_result = XLSX.utils.sheet_to_json(sheet);
        console.log(excelType, ' : ', camber_result);
        document.getElementById('camberFileTable').innerHTML = camber_table_result;
        setCamberTable();
        break;
      case 'combination':
        const combination_table_result = XLSX.utils.sheet_to_html(sheet);
        const combination_result = XLSX.utils.sheet_to_json(sheet);
        console.log(excelType, ' : ', combination_result);
        document.getElementById('combinationFileTable').innerHTML = combination_table_result;
        setCombinationTable();
        break;
      case 'check':
        const check_result = XLSX.utils.sheet_to_html(sheet);
        console.log(excelType, ' : ', check_result);
        document.getElementById('checkFileTable').innerHTML = check_result;
        setCheckTable();
        break;

      default: return;
    }
  };

  // 발주 테이블
  const setProcurementTable = () => {
    const resultRows = document.querySelectorAll('#procurementFileTable tr');
    console.log('setProcurementTable : ', resultRows);
    let headerRowIndex = null;
    let sumRowIndex = null;
    if (resultRows.length > 0) {
      resultRows.forEach((thisItem) => {
        if (thisItem.cells[0].innerHTML === '순번') {
          headerRowIndex = thisItem.rowIndex;
          console.log('headerRowIndex : ', headerRowIndex);
        }
        if (thisItem.cells[0].innerHTML.trim() === '' || thisItem.cells[1].innerHTML.replaceAll(' ', '') === '[합계]') {
          sumRowIndex = thisItem.rowIndex;
          console.log('sumRowIndex : ', sumRowIndex);
        }
      });
    }

    if (headerRowIndex !== null && sumRowIndex !== null) {
      let result = [];
      resultRows.forEach((thisItem) => {
        if (thisItem.rowIndex >= headerRowIndex + 1 && thisItem.rowIndex < sumRowIndex) {
          if (thisItem.cells[1].innerHTML === '' && thisItem.cells[2].innerHTML === '') return;
          const OneRow = {
            status: 'waiting',
            quantity: isNaN(thisItem.cells[5].innerHTML.trim()) !== true ? Number(thisItem.cells[5].innerHTML.trim()) : 0, // 수량
            price: 0, // 금액
            // price: isNaN(thisItem.cells[9].innerHTML.trim()) !== true ? Number(thisItem.cells[9].innerHTML.trim()) : 0, // 금액
            customizedContent: JSON.stringify({
              num: thisItem.cells[0].innerHTML.trim(), // 순번
              weight: thisItem.cells[7].innerHTML.trim(), // 중량
            }),
            elementForExcelDto: {
              companyId: userReducer.company.companyId,

              elementType: 'material',

              bcodeSearchCode: '',
              elementCode: thisItem.cells[2].innerHTML.trim(), // 규격
              elementName: thisItem.cells[1].innerHTML.trim(), // 재질(품명)
              elementUnit: thisItem.cells[3].innerHTML.trim(), // 단위
              elementSafeStock: 0,
              elementStock: 0,

              attributeAndValue: {},
            },
          };

          OneRow.elementForExcelDto.attributeAndValue['길이'] = thisItem.cells[4].innerHTML.trim(); // 길이
          OneRow.elementForExcelDto.attributeAndValue['단중'] = thisItem.cells[6].innerHTML.trim(); // 단중
          // OneRow.elementForExcelDto.attributeAndValue['단가'] = thisItem.cells[8].innerHTML.trim(); // 단가
          OneRow.elementForExcelDto.attributeAndValue['비고'] = thisItem.cells[8].innerHTML.trim(); // 비고
          // OneRow.elementForExcelDto.attributeAndValue['비고'] = thisItem.cells[10].innerHTML.trim(); // 비고

          result.push(OneRow);
        }
      });
      console.log('result : ', result, JSON.stringify(result));
      setFileProcurement(result);
    }
  };
  // 캠버리스트 테이블
  const setCamberTable = () => {
    const resultRows = document.querySelectorAll('#camberFileTable tr');
    console.log('setCamberTable : ', resultRows);

    let headerRowIndex = null;
    let sumRowIndex = null;
    if (resultRows.length > 0) {
      resultRows.forEach((thisItem) => {
        if (thisItem.cells[0].innerHTML === '순번') {
          headerRowIndex = thisItem.rowIndex;
          console.log('headerRowIndex : ', headerRowIndex);
        }
        if (thisItem.cells[0].innerHTML.trim() === '' || thisItem.cells[1].innerHTML.replaceAll(' ', '') === '[합계]') {
          sumRowIndex = thisItem.rowIndex;
          console.log('sumRowIndex : ', sumRowIndex);
        }
      });
    }

    if (headerRowIndex !== null) {
      let result = [];
      resultRows.forEach((thisItem) => {
        if (thisItem.rowIndex >= headerRowIndex + 1 && thisItem.rowIndex < sumRowIndex) {
          if (thisItem.cells[1].innerHTML === '' && thisItem.cells[2].innerHTML === '') return;
          const OneRow = {
            existProcessOutElement: false,
            processTypeName: 'BL',
            processingCount: 1,
            processCustomizedContent: {},
            taskLogList: [],
            processCode: thisItem.cells[1].innerHTML.trim(),
            processName: thisItem.cells[2].innerHTML.trim(),
          };

          OneRow.processCustomizedContent.num = thisItem.cells[0].innerHTML.trim(); // 순번
          OneRow.processCustomizedContent.absentNumber = thisItem.cells[1].innerHTML.trim(); // 부재번호
          OneRow.processCustomizedContent.standard = thisItem.cells[2].innerHTML.trim(); // 규격
          OneRow.processCustomizedContent.unit = thisItem.cells[3].innerHTML.trim(); // 단위
          OneRow.processCustomizedContent.length = thisItem.cells[4].innerHTML.trim(); // 길이
          OneRow.processCustomizedContent.quantity = thisItem.cells[5].innerHTML.trim(); // 수량
          OneRow.processCustomizedContent.defaultCamber = thisItem.cells[6].innerHTML.trim(); // 기준캠버값
          OneRow.processCustomizedContent.realCamber = ''; // 실제캠버값
          OneRow.processCustomizedContent.remark = thisItem.cells[7].innerHTML.trim(); // 비고

          result.push(OneRow);
        }
      });
      console.log('result : ', result);

      setFileBL(result);
    }
  };
  // 조합리스트 테이블
  const setCombinationTable = () => {
    const resultRows = document.querySelectorAll('#combinationFileTable tr');
    console.log('setCombinationTable : ', resultRows);

    let headerRowIndex = null;
    if (resultRows.length > 0) {
      resultRows.forEach((thisItem) => {
        if (thisItem.cells[0].innerHTML.replaceAll(' ', '') === '재질') {
          headerRowIndex = thisItem.rowIndex;
          console.log('headerRowIndex : ', headerRowIndex);
        }
      });
    }

    if (headerRowIndex !== null) {
      let result = [];
      let count = 0;
      resultRows.forEach((thisItem) => {
        if (thisItem.rowIndex >= headerRowIndex + 1) {
          if (thisItem.cells[9] !== undefined && thisItem.cells[9].innerHTML.trim() !== null && thisItem.cells[9].innerHTML.trim() !== '') {
            const OneRow = {
              existProcessOutElement: false,
              processTypeName: 'CP',
              processingCount: 1,
              processCustomizedContent: {},
              taskLogList: [],

              processName: thisItem.cells[1].innerHTML.trim(),
            };

            count++;
            OneRow.processCustomizedContent.num = count.toString(); // 순번
            OneRow.processCustomizedContent.name = thisItem.cells[0].innerHTML.trim(); // 재질
            OneRow.processCustomizedContent.profile = thisItem.cells[1].innerHTML.trim(); // 규격
            OneRow.processCustomizedContent.length = thisItem.cells[2].innerHTML.trim(); // 길이
            OneRow.processCustomizedContent.quantity = thisItem.cells[3].innerHTML.trim(); // 수량
            OneRow.processCustomizedContent.combined = thisItem.cells[4].innerHTML.trim(); // 조합
            OneRow.processCustomizedContent.part = thisItem.cells[5].innerHTML.trim(); // 잔재
            OneRow.processCustomizedContent.partWidth = thisItem.cells[6].innerHTML.trim(); // 부재길이
            OneRow.processCustomizedContent.each = thisItem.cells[7].innerHTML.trim(); // 수량
            OneRow.processCustomizedContent.total = thisItem.cells[8].innerHTML.trim(); // 총수량
            OneRow.processCustomizedContent.mark = thisItem.cells[9].innerHTML.trim(); // Mark

            result.push(OneRow);
          }
        }
      });
      console.log('result : ', result);
      setFileCP(result);
    }
  };
  // 체크리스트 테이블
  const setCheckTable = () => {
    const resultRows = document.querySelectorAll('#checkFileTable tr');
    console.log('setCheckTable : ', resultRows);

    let headerRowIndex = null;
    if (resultRows.length > 0) {
      resultRows.forEach((thisItem) => {
        if (thisItem.cells[0].innerHTML.replaceAll(' ', '') === '부재번호') {
          headerRowIndex = thisItem.rowIndex;
          console.log('headerRowIndex : ', headerRowIndex);
        }
      });
    }

    if (headerRowIndex !== null) {
      let result = [];
      resultRows.forEach((thisItem) => {
        if (thisItem.rowIndex >= headerRowIndex + 1) {
          if (thisItem.cells[0] !== undefined && thisItem.cells[0].innerHTML.trim() !== null && thisItem.cells[0].innerHTML.trim() !== '') {
            const OneRow = {
              existProcessOutElement: false,
              processTypeName: 'CL',
              processingCount: 1,
              processCustomizedContent: {},
              taskLogList: [],

              processName: thisItem.cells[1].innerHTML.trim(),
            };

            OneRow.processCustomizedContent.asmbly = thisItem.cells[0].innerHTML.trim(); // 부재번호
            OneRow.processCustomizedContent.profile = thisItem.cells[1].innerHTML.trim(); // 규격
            OneRow.processCustomizedContent.length = thisItem.cells[2].innerHTML.trim(); // 길이
            OneRow.processCustomizedContent.quantity = thisItem.cells[3].innerHTML.trim(); // 수량
            OneRow.processCustomizedContent.part = thisItem.cells[4].innerHTML.trim(); // 종류
            OneRow.processCustomizedContent.weight = thisItem.cells[5].innerHTML.trim(); // 개별중량
            OneRow.processCustomizedContent.name = thisItem.cells[6].innerHTML.trim(); // 비고

            result.push(OneRow);
          }
        }
      });
      console.log('result : ', result);

      setFileCL(result);
    }
  };

  const actSelectType = (e) => {
    const { type } = e.target.dataset;
    if (type.endsWith('_procurement') === true) setSelectType(type);
    if (type.endsWith('_bl') === true) setSelectBLType(type);
    if (type.endsWith('_cp') === true) setSelectCPType(type);
    if (type.endsWith('_cl') === true) setSelectCLType(type);
  };

  const updateProcurementData = async () => {
    let procurementElementList = [..._procurementFormData.procurementElementList].map((procurementElement) => {
      return { ...procurementElement, elementForExcelDto: {} };
    });
    let deleteProcurementElementList = [];
    if (checkNullArray(_fileProcurement, false)) {
      switch (_selectType) {
        case 'replace_procurement':
          procurementElementList = [..._fileProcurement];
          deleteProcurementElementList = _procurementFormData.procurementElementList.map((thisItem) => thisItem.procurementElementId);
          break;
        case 'add_procurement':
          procurementElementList = [..._procurementFormData.procurementElementList, ..._fileProcurement];
          break;
        default: break;
      }
    }

    const BodyToPut = {
      ..._procurementFormData,
      accountId: _account.accountId,
      procurementElementList: procurementElementList.map((element) => {
        const returnData = { ...element, savedQuantity: 0, };
        delete returnData.storeQuantity;
        return returnData;
      }),
      deleteProcurementElementList: checkNullArray(deleteProcurementElementList, []),
      isCustom: true, // 경서 > 커스텀 표시
    };
    console.log('BodyToPut - procurement : ', BodyToPut);
    return BodyToPut;
  };
  const updateWorkOrderData = async () => {
    const formData = { ..._workOrderFormData };

    // 엑셀 업로드 리스트
    const setBL = _fileBL.map((thisItem) => {
      return { ...thisItem, processCustomizedContent: JSON.stringify(thisItem.processCustomizedContent) };
    });
    const setCP = _fileCP.map((thisItem) => {
      return { ...thisItem, processCustomizedContent: JSON.stringify(thisItem.processCustomizedContent) };
    });
    const setCL = _fileCL.map((thisItem) => {
      return { ...thisItem, processCustomizedContent: JSON.stringify(thisItem.processCustomizedContent) };
    });

    let deleteProcessLogList = [];
    let setProcessLogList_BL = [];
    switch (_selectBLType) {
      case 'replace_bl':
        if (!checkNullArray(setBL, false)) {
          setProcessLogList_BL = setBL
          _prevBL.forEach((thisItem) => { deleteProcessLogList.push(thisItem.processLogId); });
        } else {
          setProcessLogList_BL = _prevBL
        }
        break;
      case 'add_bl': setProcessLogList_BL = [..._prevBL, ...setBL]; break;
      default: setProcessLogList_BL = _prevBL; break;
    }
    let setProcessLogList_CP = [];
    switch (_selectCPType) {
      case 'replace_cp':
        if (!checkNullArray(setCP, false)) {
          setProcessLogList_CP = setBL
          _prevCP.forEach((thisItem) => { deleteProcessLogList.push(thisItem.processLogId); });
        } else {
          setProcessLogList_CP = _prevCP
        }
        break;
      case 'add_cp': setProcessLogList_CP = [..._prevCP, ...setCP]; break;
      default: setProcessLogList_CP = _prevCP; break;
    }
    let setProcessLogList_CL = [];
    switch (_selectCLType) {
      case 'replace_cl':
        if (!checkNullArray(setCL, false)) {
          setProcessLogList_CL = setBL
          _prevCL.forEach((thisItem) => { deleteProcessLogList.push(thisItem.processLogId); });
        } else {
          setProcessLogList_CL = _prevCL
        }
        break;
      case 'add_cl': setProcessLogList_CL = [..._prevCL, ...setCL]; break;
      default: setProcessLogList_CL = _prevCL; break;
    }

    formData.processLogList = [...setProcessLogList_BL, ...setProcessLogList_CP, ...setProcessLogList_CL];
    if (checkNullArray(deleteProcessLogList, false)) formData.deleteProcessLogList = deleteProcessLogList;
    if (!checkEmptyNull(_formData.workOrderScheduledStartDate, false)) delete formData['workOrderScheduledStartDate'];
    if (!checkEmptyNull(_formData.workOrderScheduledEndDate, false)) delete formData['workOrderScheduledEndDate'];

    const BodyToPut = {
      ...formData,
      workOrderCode: _formData.contractCode,
      workOrderName: _formData.contractName,
    };
    console.log('BodyToPut - workOrderLog : ', BodyToPut);
    return BodyToPut;
  };
  const actUpdate = async (e) => {
    e.preventDefault();
    if (!checkEmptyNull(_account.accountId, false)) return alert('거래처를 선택해주세요.');
    if (!checkEmptyNull(_formData.contractCode, false)) return alert('수주코드를 입력해주세요.');
    if (!checkEmptyNull(_formData.contractName, false)) return alert('공사명을 입력해주세요.');

    setLoading(() => { return true; });
    const procurement = await updateProcurementData();
    const workOrder = await updateWorkOrderData();

    const BodyToPut = {
      ..._formData,
      accountId: _account.accountId,
      contractCustomizedContent: JSON.stringify(_formData.contractCustomizedContent),
      procurements: [procurement],
      workOrders: [workOrder],
    };
    console.log('BodyToPut - contract : ', BodyToPut);

    await contractApi.updateContract(_formData.contractId, BodyToPut).then(async (response) => {
      if (response === undefined) return;
      console.log('contractApi.updateContract : ', response);
      // await updateProcurementData();
      // await updateWorkOrderData();
      alert('수주를 수정했습니다.');
      setLoading(() => { return false; });
      navigate(pageReducer.currentPage, { replace: true });
    }).catch(() => {
      setLoading(() => { return false; });
    })
  };



  /* =================================================== #6들 =================================================== */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <>
        <main className="Main">
          <NavBar nav={''} title={<NavTitle menuCode={'201'} />} />

          <Form
            title={<><NavTitle menuCode={'201'} />수정</>}
            buttons={
              <>
                {!_loading ? (<button className="formButton" onClick={actUpdate}>저장</button>) : null}
                <button className="formButton cancle"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(pageReducer.currentPage, { replace: true });
                  }}
                >
                  취소
                </button>
              </>
            }
            forms={
              <>
                <div className="formBox">
                  <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                    <h4>거래처</h4>
                    <div className="formButton"
                      style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                      onClick={(e) => { e.preventDefault(); setModalStatus(() => { return true }); }}
                    >
                      거래처 선택
                    </div>
                  </div>
                  <input
                    type="data"
                    name="accountId"
                    data-required="required"
                    readOnly
                    placeholder=" 거래처를 선택해주세요."
                    value={checkEmptyNull(_account.accountName, '')}
                  />
                </div>

                <div className="formBox">
                  <h4>수주코드</h4>
                  <input
                    type="data"
                    name="contractCode"
                    data-required="required"
                    placeholder="* 수주코드..."
                    defaultValue={_formData.contractCode}
                    onChange={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>공사명</h4>
                  <input
                    type="text"
                    name="contractName"
                    data-required="required"
                    placeholder="* 공사명..."
                    defaultValue={_formData.contractName}
                    onChange={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>수주일자</h4>
                  <ContractDate
                    type="date"
                    name="contractDate"
                    defaultValue={_formData.contractDate}
                    onChange={handleInputEvent}
                  ></ContractDate>
                </div>

                <div className="formBox">
                  <h4>{userReducer.endDateName[0]}</h4>
                  <ContractDate
                    type="date"
                    name="scheduledEndDate"
                    defaultValue={_formData.scheduledEndDate}
                    onChange={handleInputEvent}
                  ></ContractDate>
                </div>

                <div className="formBox">
                  <h4>{userReducer.endDateName[1]}</h4>
                  <ContractDate
                    type="date"
                    name="scheduledEndDate2"
                    defaultValue={_formData.scheduledEndDate2}
                    onChange={handleInputEvent}
                  ></ContractDate>
                </div>

                <div className="formBox">
                  <h4>현장명</h4>
                  <input
                    type="text"
                    name="workPlaceName"
                    placeholder="현장명..."
                    defaultValue={_formData.contractCustomizedContent?.workPlaceName}
                    onChange={handleCustomEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>현장주소</h4>
                  <input
                    type="text"
                    name="workPlaceAddress"
                    placeholder="현장주소..."
                    defaultValue={_formData.contractCustomizedContent?.workPlaceAddress}
                    onChange={handleCustomEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>수주금액</h4>
                  <input
                    type="number"
                    name="contractPrice"
                    placeholder="* 수주금액은 숫자만 입력해주세요."
                    defaultValue={_formData.contractPrice}
                    onInput={handleInputEvent}
                  />
                </div>

                <div className="formBox w100">
                  <h4>발주서</h4>
                  <SelectButtonSection>
                    <SelectButton type="add_procurement" data-type="add_procurement" onClick={actSelectType}>엑셀 추가하기</SelectButton>
                    <SelectButton type="replace_procurement" data-type="replace_procurement" onClick={actSelectType}>엑셀 덮어쓰기</SelectButton>
                    <SelectButton type="cancel_procurement" data-type="cancel_procurement" onClick={actSelectType}>취소</SelectButton>
                  </SelectButtonSection>

                  {(_selectType === 'replace_procurement' || _selectType === 'add_procurement') ? (
                    <SelectSection className="formBox w100">
                      <h4>
                        발주서 - 엑셀 {_selectType === 'replace_procurement' ? '덮어쓰기' : _selectType === 'add_procurement' ? '추가하기' : null} 업로드
                      </h4>
                      <input
                        type="file"
                        id="procurementFileInput"
                        style={{ display: 'none' }}
                        onChange={setProcurementFile}
                      />
                      <FileLabelStyle htmlFor="procurementFileInput"
                        style={_selectType === 'replace_procurement' ? { backgroundColor: 'var(--Excel)' } : _selectType === 'add_procurement' ? { backgroundColor: 'var(--WorkingColor)' } : null}
                      >
                        업로드
                      </FileLabelStyle>
                      <FileTableSection
                        onClick={() => {
                          document.getElementById('procurementFileInput').value = '';
                          document.getElementById('procurementFileTable').innerHTML = '';
                          setFileProcurement([]);
                        }}
                      >
                        <TableSection content={<table id="procurementFileTable"></table>} />
                      </FileTableSection>

                      <div className="cautionText" style={{ fontSize: '14px', textAlign: 'right', width: '100%' }}>
                        {_selectType === 'replace_procurement' ? '※ 기존 발주물품의 모든 정보가 새 엑셀 데이터로 교체됩니다.' : _selectType === 'add_procurement' ? '※ 기존 발주물품에 새 엑셀 데이터가 추가됩니다.' : null}
                      </div>
                    </SelectSection>
                  ) : null}
                </div>

                <div className="formBox w100">
                  <h4>캠버리스트</h4>
                  <SelectButtonSection>
                    <SelectButton type="add_bl" data-type="add_bl" style={{ backgroundColor: 'var(--MainBlue)' }} onClick={actSelectType}>엑셀 추가하기</SelectButton>
                    <SelectButton type="replace_bl" data-type="replace_bl" style={{ backgroundColor: 'var(--ThirdBlue)' }} onClick={actSelectType}>엑셀 덮어쓰기</SelectButton>
                    <SelectButton type="cancel_bl" data-type="cancel_bl" onClick={actSelectType}>취소</SelectButton>
                  </SelectButtonSection>

                  {(_selectBLType === 'replace_bl' || _selectBLType === 'add_bl') ? (
                    <SelectSection className="formBox w100">
                      <h4>
                        캠버리스트 - 엑셀 {_selectBLType === 'replace_bl' ? '덮어쓰기' : _selectBLType === 'add_bl' ? '추가하기' : null} 업로드
                      </h4>
                      <input
                        type="file"
                        id="camberFileInput"
                        style={{ display: 'none' }}
                        onChange={setCamberFile}
                      />
                      <FileLabelStyle htmlFor="camberFileInput"
                        style={_selectBLType === 'replace_bl' ? { backgroundColor: 'var(--ThirdBlue)' } : _selectBLType === 'add_bl' ? { backgroundColor: 'var(--MainBlue)' } : null}
                      >
                        업로드
                      </FileLabelStyle>
                      <FileTableSection
                        onClick={() => {
                          document.getElementById('camberFileInput').value = '';
                          document.getElementById('camberFileTable').innerHTML = '';
                          setFileCP([]);
                        }}
                      >
                        <TableSection content={<table id="camberFileTable"></table>} />
                      </FileTableSection>

                      <div className="cautionText" style={{ fontSize: '14px', textAlign: 'right', width: '100%' }}>
                        {_selectBLType === 'replace_bl' ? '※ 기존 캠버리스트의 모든 정보가 새 엑셀 데이터로 교체됩니다.' : _selectBLType === 'add_bl' ? '※ 기존 캠버리스트에 새 엑셀 데이터가 추가됩니다.' : null}
                      </div>
                    </SelectSection>
                  ) : null}
                </div>

                <div className="formBox w100">
                  <h4>조합리스트</h4>
                  <SelectButtonSection>
                    <SelectButton type="add_cp" data-type="add_cp" style={{ backgroundColor: 'var(--MainBlue)' }} onClick={actSelectType}>엑셀 추가하기</SelectButton>
                    <SelectButton type="replace_cp" data-type="replace_cp" style={{ backgroundColor: 'var(--ThirdBlue)' }} onClick={actSelectType}>엑셀 덮어쓰기</SelectButton>
                    <SelectButton type="cancel_cp" data-type="cancel_cp" onClick={actSelectType}>취소</SelectButton>
                  </SelectButtonSection>

                  {(_selectCPType === 'replace_cp' || _selectCPType === 'add_cp') ? (
                    <SelectSection className="formBox w100">
                      <h4>
                        조합리스트 - 엑셀 {_selectCPType === 'replace_cp' ? '덮어쓰기' : _selectCPType === 'add_cp' ? '추가하기' : null} 업로드
                      </h4>
                      <input
                        type="file"
                        id="combinationFileInput"
                        style={{ display: 'none' }}
                        onChange={setCombinationFile}
                      />
                      <FileLabelStyle htmlFor="combinationFileInput"
                        style={_selectCPType === 'replace_cp' ? { backgroundColor: 'var(--ThirdBlue)' } : _selectCPType === 'add_cp' ? { backgroundColor: 'var(--MainBlue)' } : null}
                      >
                        업로드
                      </FileLabelStyle>
                      <FileTableSection
                        onClick={() => {
                          document.getElementById('combinationFileInput').value = '';
                          document.getElementById('combinationFileTable').innerHTML = '';
                          setFileCP([]);
                        }}
                      >
                        <TableSection content={<table id="combinationFileTable"></table>} />
                      </FileTableSection>

                      <div className="cautionText" style={{ fontSize: '14px', textAlign: 'right', width: '100%' }}>
                        {_selectCPType === 'replace_cp' ? '※ 기존 조합리스트의 모든 정보가 새 엑셀 데이터로 교체됩니다.' : _selectCPType === 'add_cp' ? '※ 기존 조합리스트에 새 엑셀 데이터가 추가됩니다.' : null}
                      </div>
                    </SelectSection>
                  ) : null}
                </div>

                <div className="formBox w100">
                  <h4>체크리스트</h4>
                  <SelectButtonSection>
                    <SelectButton type="add_cl" data-type="add_cl" style={{ backgroundColor: 'var(--MainBlue)' }} onClick={actSelectType}>엑셀 추가하기</SelectButton>
                    <SelectButton type="replace_cl" data-type="replace_cl" style={{ backgroundColor: 'var(--ThirdBlue)' }} onClick={actSelectType}>엑셀 덮어쓰기</SelectButton>
                    <SelectButton type="cancel_cl" data-type="cancel_cl" onClick={actSelectType}>취소</SelectButton>
                  </SelectButtonSection>

                  {(_selectCLType === 'replace_cl' || _selectCLType === 'add_cl') ? (
                    <SelectSection className="formBox w100">
                      <h4>
                        체크리스트 - 엑셀 {_selectCLType === 'replace_cl' ? '덮어쓰기' : _selectCLType === 'add_cl' ? '추가하기' : null} 업로드
                      </h4>
                      <input
                        type="file"
                        id="checkFileInput"
                        style={{ display: 'none' }}
                        onChange={setCheckFile}
                      />
                      <FileLabelStyle htmlFor="checkFileInput"
                        style={_selectCLType === 'replace_cl' ? { backgroundColor: 'var(--ThirdBlue)' } : _selectCLType === 'add_cl' ? { backgroundColor: 'var(--MainBlue)' } : null}
                      >
                        업로드
                      </FileLabelStyle>
                      <FileTableSection
                        onClick={() => {
                          document.getElementById('checkFileInput').value = '';
                          document.getElementById('checkFileTable').innerHTML = '';
                          setFileCL([]);
                        }}
                      >
                        <TableSection content={<table id="checkFileTable"></table>} />
                      </FileTableSection>

                      <div className="cautionText" style={{ fontSize: '14px', textAlign: 'right', width: '100%' }}>
                        {_selectCLType === 'replace_cl' ? '※ 기존 체크리스트의 모든 정보가 새 엑셀 데이터로 교체됩니다.' : _selectCLType === 'add_cl' ? '※ 기존 체크리스트에 새 엑셀 데이터가 추가됩니다.' : null}
                      </div>
                    </SelectSection>
                  ) : null}
                </div>
              </>
            }
            formSectionStyle={{ paddingTop: '10px', width: '100%' }}
          />
        </main>

        {_modalStatus === true ? (
          <SelectOneAccount
            buttonTitle={'거래처 선택'}
            buttonEvent={(data) => {
              setAccount(() => { return data; });
              setTimeout(setModalStatus(false), 1000);
            }}
            open={_modalStatus}
            close={() => { setModalStatus(false); }}
          ></SelectOneAccount>
        ) : null}
      </>
    }
    />
  );
};

export default UpdateGSContract;