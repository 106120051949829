import React from 'react';
import { useSelector } from 'react-redux';

const NavText = (props) => {
  /* ========================================================================= #1 */
  const { userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const { menuCode } = props;

  /* ========================================================================= #3 */
  /* ========================================================================= #4 */
  /* ========================================================================= #5 */
  /* ========================================================================= #6 */

  return (
    <span>
      {userReducer.userMenu.find((thisItem) => {
        if (thisItem.pageCode === menuCode) return true;
        else return false;
      }) &&
        userReducer.userMenu.find((thisItem) => {
          if (thisItem.pageCode === menuCode) return true;
          else return false;
        }).pageName}
    </span>
  );
};

export default NavText;
