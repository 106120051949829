import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { operatingActions_setProductionStatus } from 'store/modules/actions/operating/operatingActions';

import { companyApi } from 'api/apis/companyApi';
import { workOrderLog } from 'api/apis/platform/operating/workOrderLog';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import { IoSearch } from "react-icons/io5";
import { OperatingProductionStatusComponent } from './OperatingProductionStatus.style';
import OperatingPagination from 'platform/operating/components/OperatingPagination';

const OperatingProductionStatus = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const operatingId = location.pathname.split('/platform/operating')[0];
  const operatingPath = operatingId + '/platform/operating';

  const { operatingReducer } = useSelector(state => state);
  const { productionStatus } = operatingReducer;

  const [_companyList, setCompanyList] = useState([]);
  const [_workOrderLogs, setWorkOrderLogs] = useState([]);

  const dataKeys = [ // workOrderLog
    { key: 'companyName', title: '소공인', },
    // { key: 'workOrderCode', title: '작업코드', },
    { key: 'workOrderName', title: '작업이름', },
    { key: 'accountName', title: '거래처', },
    { key: 'amount', title: '생산목표수량', },
    { key: 'term', title: '기간', },
  ];

  useEffect(() => {
    getClusterCompanyList();
    return () => { }
  }, []);
  useEffect(() => {
    getWorkOrderLogs(productionStatus.pageNumber - 1);
    return () => { }
  }, [productionStatus.pageNumber]);
  useEffect(() => { }, [_workOrderLogs]);

  const getClusterCompanyList = async () => { // 소공인 리스트 불러오기
    const id = Number(operatingId.replace('/', ''));
    const body = { clusterId: !isNaN(id) ? id : '' };

    await companyApi.searchCompanies('', body).then(response => {
      if (response === undefined) return;
      setCompanyList(() => { return response.data });
    })
  };
  const getWorkOrderLogs = async (page) => { // 작업지시 리스트 불러오기
    const id = Number(operatingId.replace('/', ''));
    const paging = `?page=${page}&size=15&sort=id,DESC`;
    const body = {
      clusterId: !isNaN(id) ? id : '', // 필수값
    };
    Object.keys(productionStatus.searchData).forEach((searchKey) => { // 검색 조건
      if (checkEmptyNull(productionStatus.searchData[searchKey], false)) {
        if (searchKey === 'companyId') {
          body[searchKey] = Number(productionStatus.searchData[searchKey]);
        } else {
          body[searchKey] = productionStatus.searchData[searchKey];
        }
      }
    })

    await workOrderLog.search(paging, body).then(response => {
      if (response === undefined) return;
      setWorkOrderLogs(() => { return response.data.data.content });
      dispatch(operatingActions_setProductionStatus({ ...productionStatus, totalSize: response.data.data.totalElements }));
    })
  };

  const handleSearchData = (e) => { // 검색바 입력 이벤트
    const { name, value } = e.target;
    dispatch(operatingActions_setProductionStatus({
      ...productionStatus,
      searchData: { ...productionStatus.searchData, [name]: value }
    }));
  }
  const actSearch = () => { // 검색
    dispatch(operatingActions_setProductionStatus({ ...productionStatus, pageNumber: 1 }));
    getWorkOrderLogs(0);
  }

  return (
    <OperatingProductionStatusComponent>
      <header>
        <h1>작업현황</h1>
      </header>
      <nav>
        <div className='nav-bar'>
          <p>소공인</p>
          <select
            name='companyId'
            value={productionStatus.searchData?.companyId || ''}
            onChange={handleSearchData}
          >
            <option value={''}>소공인 전체</option>
            {checkNullArray(_companyList, []).map((company, index) => {
              return (<option key={index + '_company'} value={company.companyId}>{company.companyName}</option>);
            })}
          </select>
        </div>
        <div className='nav-bar'>
          <p>작업이름</p>
          <input
            type='text'
            name='workOrderName'
            placeholder='검색어를 입력해주세요.'
            value={productionStatus.searchData?.workOrderName || ''}
            onInput={handleSearchData}
            onKeyDown={(e) => {
              if (e.key === 'Enter') actSearch();
            }}
          />
        </div>
        <button onClick={actSearch}><IoSearch /></button>
      </nav>
      <div>
        <table>
          <thead>
            <tr>
              {dataKeys.map((dataKey, index) => {
                return (<th key={index + '_th'}>{dataKey.title}</th>);
              })}
            </tr>
          </thead>
          <tbody>
            {!checkNullArray(_workOrderLogs, false) ? (<tr><td colSpan={dataKeys.length}>데이터가 없습니다.</td></tr>) :
              checkNullArray(_workOrderLogs, []).map((workOrderLog, index) => {
                return (
                  <tr key={index + '_workOrderLog'}>
                    {dataKeys.map((dataKey, dataKeyIndex) => {
                      let dataValue = workOrderLog[dataKey.key] || '-';
                      const start = DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledStartDate, ''));
                      const end = DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledEndDate, ''));
                      if (dataKey.key === 'term') dataValue = start + ' ~ ' + end;
                      return (<td key={index + '_workOrderLog' + dataKeyIndex}>{dataValue}</td>);
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <OperatingPagination
        page={productionStatus.pageNumber}
        count={productionStatus.totalSize}
        size={15}
        pageEvent={(page) => { dispatch(operatingActions_setProductionStatus({ ...productionStatus, pageNumber: page })); }}
      />
    </OperatingProductionStatusComponent>
  );
};

export default OperatingProductionStatus;