import React from 'react';
import { useOutletContext } from 'react-router-dom';

import QualityMonitoring from 'pages/monitoring/contents/QualityMonitoring';

const MonitoringQuality = () => {
  const {setTimeCounter} = useOutletContext();

  return (
    <div className='monitoringBody single'>
      <QualityMonitoring total={false} setTimeCounter={setTimeCounter} />
    </div>
  );
};

export default MonitoringQuality;