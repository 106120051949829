import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { workActions_setCurrentMessage, workActions_setViewScale, workActions_setWorkCase } from 'store/modules/actions/default/workActions';

import { getCookie } from 'preferences/cookie/cookie';
import { WS_CLIENT } from 'preferences/server/constants';
import { sendingData } from 'api/apis/sendingData/sendingData';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import GridNonBody from 'components/layouts/body/GridNonBody';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';
import WorkOrderStatusModal from 'components/modal/status/WorkOrderStatusModal';
import ViewMinusButton from 'components/buttons/ViewMinusButton';
import ViewPlusButton from 'components/buttons/ViewPlusButton';
import ViewResetButton from 'components/buttons/ViewResetButton';

import UriMultiStatusModal from 'pages/working/custom/company/uri/process/status/UriMultiStatusModal';
import UriWorkEndModal from 'pages/working/custom/company/uri/status/UriWorkEndModal';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  display: grid;
  grid-template-rows: 55px auto;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: inherit;
`;
const ModalHeaderButtons = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 55px;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  label {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 60px;
    justify-content: flex-end;
    padding: 0px 20px;
    width: 100%;
  }

  .TableSection {
    height: 100%;
    margin: 0px auto;
    overflow-x: auto;
    width: 100%;

    table {width: 100%;}
    tbody tr {border-bottom: 8px solid var(--ThirdBlue);}

    td, th {max-width: 300px; min-width: 120px;}
    td {border: 1px solid var(--gray-200); box-sizing: border-box; font-size: 1.4em; height: 250px;}

    .numStatus {font-size: 1em;}
    .materialStatus {
      min-width: 250px; width: 250px;
      p {font-size: 1.5em; line-height: 1.1em;}
    }
    .colorStatus {min-width: 140px; padding: 10px; width: 140px;}

    td[name='glassTypes'] {min-width: 120px; padding: unset; width: 120px;}
    td[name='remarks'] {
      min-width: 200px; padding: 10px; width: 200px;
      p {font-size: 1.5em;}
    }
  }
`;
const WorkOrderTable = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  table {
    background-color: var(--white);
    border-collapse: collapse;
    height: 96%;
    margin: 0px auto;
    table-layout: auto;
    width: 98%;

    td, th {
      border: 1px solid var(--gray-200);
      box-sizing: border-box;
      height: 40px;
      min-width: unset;
      padding: unset;
    }
    td {padding-inline: 10px;}
    th {font-size: 0.8em;}
    & td.colors {
      font-size: 25px;
      font-weight: 600;
      outline: 4px solid var(--Text);
      outline-offset: -4px;
      text-align: center;
    }
    & td.remark {
      color: var(--ThirdBlue);
      font-size: 1.1em;
      padding: 10px;
      text-align: left;
      vertical-align: top;
    }
  }
`;

const DrawingCell = styled.div`
  display: grid;
  grid-template-rows: 22% 22% 56%;
  height: 100%;
  width: 100%;

  div {
    align-items: center;
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    display: flex;
    font-size: 1.5em;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  div[data-pos='Top'] {
    border: unset;
    display: grid;
    grid-template-columns: 50% 50%;
    height: 100%;

    p {
      align-items: center;
      border: 1px solid var(--gray-200);
      box-sizing: border-box;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;
    }
  }

  div[data-pos='Middle'] {padding: 10px;}
`;
const Drawing = styled.div`
  align-items: center;
  background-color: var(--white);
  border: unset;
  display: flex;
  justify-content: center;
  min-height: 200px;
  width: 100%;

  img {max-height: calc(100% - 20px); max-width: 98%; width: auto;}
`;

const AmountCell = styled.div`
  display: grid;
  grid-template-rows: 50px 1fr 1fr minmax(50px, 100px);
  height: 100%;
  width: 100%;

  &.glassCell {grid-template-rows: 1fr 1fr;}
`;
const TaskCell = styled.div`
  border: 1px solid var(--gray-200);
  box-sizing: border-box;

  &[name='taskCells'] {
    display: grid;
    grid-template-rows: 1fr 50px;
  }

  div[name='tasks'] {
    align-items: center;
    box-sizing: border-box;
    column-gap: 5px;
    display: flex;
    font-size: 1.8em;
    justify-content: center;
    line-height: 1em;
    padding: 5px;
  }

  div[name='glassType_W'], div[name='glassType_H'] {
    background-color: var(--MainNavy);
    border: none;
    box-sizing: border-box;
    color: var(--white);
    height: 100%;
    padding: 12px;
    width: 100%;
  }
`;
const TaskButtonsSection = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;
const TaskButton = styled.div`
  align-items: center;
  background-color: ${(props) => {
    switch (props.status) {
      case 'reinforcementwait': return 'var(--gray-200)'
      case 'reinforcementend': return 'var(--MainNavy)'
      case 'weldingwait': return 'var(--MainNavy)'
      case 'weldingend': return 'var(--gray-400)'
      case 'lastwait': return 'var(--MainNavy)'
      case 'lastend': return 'var(--gray-400)'
      case 'actend': return 'var(--ThirdBlue)'

      default: return 'unset';
    }
  }};
  box-sizing: border-box;
  /* color: var(--white); */
  color: ${(props) => {
    switch (props.status) {
      case 'weldingwait': return 'var(--white)'
      case 'weldingend': return 'var(--white)'

      default: return 'white';
    }
  }};
  cursor: pointer;
  display: flex;
  font-size: ${(props) => {
    switch (props.status) {
      case 'weldingwait': return '1.5em'
      case 'weldingend': return '1em'

      default: return '1em';
    }
  }};
  font-weight: 600;
  height: 100%;
  /* height: ${(props) => {
    switch (props.status) {
      case 'weldingwait': return '100px'
      case 'weldingend': return '50px'
      default: return '50px';
    }
  }}; */
  justify-content: center;
  width: 100%;

  ${(props) => {
    switch (props.status) {
      case 'weldingwait': return 'outline: 4px solid var(--white); outline-offset: -8px;'
      case 'weldingend': return ''

      default: return '';
    }
  }};
`;

const CutButton = styled.div`
  align-items: center;
  background-color: ${(props) => {
    switch (props.status) {
      case 'cutwait': return 'var(--MainBlue)'
      case 'cutend': return 'var(--gray-400)'
      default: return 'var(--gray-400)';
    }
  }};
  color: var(--white);
  cursor: ${(props) => {
    switch (props.status) {
      case 'cutwait': return 'pointer'
      case 'cutend': return 'default'
      default: return 'default';
    }
  }};
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const TaskEndButton = styled.div`
  align-items: center;
  background-color: var(--gray-400);
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  height: 100%;
  justify-content: center;
  min-width: 60px;
  width: 50%;
`;

const ViewerButtons = styled.div`
  align-items: center;
  column-gap: 4px;
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;


  &>div {
    background-color: var(--MainNavy); 
    border: 2px solid var(--white); 
    border-radius: 50%; 
    box-sizing: border-box; 
    overflow: hidden;
  }
  div div {background-color: var(--white);}
`;

const ConnectStatus = styled.div`
  background-color: ${(props) => {
    return props.connectStatus === 'connect' ? 'var(--MainGreen)' : 'var(--MainRed)';
  }};
  border-radius: 50%;
  bottom: 26px;
  cursor: pointer;
  height: 40px;
  position: fixed;
  right: 80px;
  width: 40px;
  z-index: 951;
`;

const SendingDataButton = styled.div`
  align-items: center;
  background-color: var(--MainBlue);
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 1.8em;
  justify-content: center;
  line-height: 1em;
  outline: 4px solid var(--white);
  outline-offset: -8px;
  padding-block: 20px;

  &.work {background-color: var(--gray-400);}
`;


const UriCalendarWorkerScreen = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, userReducer, workReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();
  const prevPage = searchParams.get('prevPage');
  const workOrderLogId = searchParams.get('workOrderLogId');

  const RoomId = userReducer.company.companyId;

  let materials_count = 0;
  let colors_count = 0;

  const taskTypeArray = ['BF', 'CP', 'SF', 'MF', 'GB', 'MC'];

  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_workOrderLog, setWorkOrderLog] = useState({});
  const [_workOrderCustomizedContent, setWorkOrderCustomizedContent] = useState({});
  const [_processLogList, setProcessLogList] = useState([]);

  const [_workOrderStatusModal, setWorkOrderStatusModal] = useState(false); // 작업상태 변경 모달
  const [_eventProcessLogId, setEventProcessLogId] = useState('');
  const [_eventProcessStatus, setEventProcessStatus] = useState('');

  const [_multiStatusModalStatus, setMultiStatusModalStatus] = useState(false); // 일괄 상태 변경 모달

  const [_workInfoStatus, setWorkInfoStatus] = useState(false); // 작업정보 열고/닫기

  const [_workEndStatus, setWorkEndStatus] = useState(false); // 작업 완료 모달
  const [_workEndOrder, setWorkEndOrder] = useState({});

  const [_longClickTime, setLongClickTime] = useState();

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('406') === true || // calendar
      thisItem.authority.startsWith('501') === true || // workOrderLog
      thisItem.authority.startsWith('701') === true, // monitoring
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(workActions_setWorkCase('process'));

    getWorkOrderLog();

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (!checkNullObject(workReducer.currentMessage, false)) return;
    if (workReducer.currentMessage.userId === userReducer.user.id && checkNull(workReducer.currentMessage.errorMessage, false)) {
      alert(workReducer.currentMessage.errorMessage);
      dispatch(workActions_setCurrentMessage({}));
      return;
    }
    if (workReducer.currentMessage.workOrderLogId === _workOrderLog.workOrderLogId) {
      if (workReducer.currentMessage.type === '/work') applyStatus();
      if (workReducer.currentMessage.type === 'processes') applyProcessesStatus();
      if (workReducer.currentMessage.type === 'process') applyProcessStatus();
      if (workReducer.currentMessage.type === '/task') applyTaskStatus();
    }
    return () => { };
  }, [workReducer.currentMessage]);

  useEffect(() => { console.log('workReducer.connectStatus : ', workReducer.connectStatus); }, [workReducer.connectStatus]);

  /* ====================================================================== #4 */
  const getWorkOrderLog = async () => {
    await workOrderLogApi.getWorkOrderLog(workOrderLogId).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.getWorkOrderLog : ', response);

      setWorkOrderLog(() => { return response.data; });
      setWorkOrderCustomizedContent(() => {
        const workOrderCustomizedContent = checkNullParse(response.data.workOrderCustomizedContent, {
          BF: { date: '', amount: '' },
          SF: { date: '', amount: '' },
          MF: { date: '', amount: '' },
          total: { date: '', amount: '' },

          // workOrderProducts: '', // 품목
          colors: '', // 색상
          phoneNumber: '', // 전화번호
        });
        return workOrderCustomizedContent;
      });
      setProcessLogList(() => {
        const processLogList = response.data.processLogList;
        processLogList.sort((a, b) => { return a.processLogId - b.processLogId; });
        return processLogList;
      });
    });
  };

  const endCheck = (workOrderLog, processLogList) => {
    let checkCount = 0;
    processLogList.forEach(processLog => {
      if (processLog.processStatus !== 'end') checkCount++;
    })
    if (workOrderLog.workOrderStatus !== 'end' && checkCount === 0) {
      const BodyToWS = {
        roomId: RoomId,
        type: '/work',
        workOrderLogId: workOrderLog.workOrderLogId,
        workOrderStatus: 'end',
      };
      try {
        WS_CLIENT.publish({
          destination: '/pub/work',
          headers: { 'auth-token': getCookie('accessToken')?.replace('Bearer ', '') },
          body: JSON.stringify(BodyToWS),
        });
        setWorkEndOrder(() => { return { BodyToWS: BodyToWS, eventWorkOrder: { ...workOrderLog, workOrderStatus: 'end' } } });
        setTimeout(setWorkEndStatus(true), 1000);
      } catch (error) {
        console.log('client.publish/pub/work - error : ', error);
      }
    }
  }

  /* 웹소켓 */
  const applyStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);
    setWorkOrderLog((prev) => { return { ...prev, workOrderStatus: applyContent.workOrderStatus, } });
  };
  const applyProcessesStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const processLogList = [..._processLogList];
    checkNullArray(applyContent.socketProcessResults, []).forEach((processResult, index) => {
      const eventIndex = processLogList.findIndex(item => item.processLogId === processResult.processLog.processLogId);
      if (eventIndex === -1) return;
      const eventContent = checkNullObject(processLogList[eventIndex], false);
      if (!eventContent) return;
      const processData = { ...eventContent, processStatus: processResult.processLog.processStatus };
      processLogList.splice(eventIndex, 1, processData);
    })
    setProcessLogList(() => { return processLogList });
    const workOrderLog = { ..._workOrderLog, processLogList: processLogList }
    setWorkOrderLog(() => { return workOrderLog });
    endCheck(workOrderLog, processLogList);
  }
  const applyProcessStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventProcessLogList = [..._processLogList];
    const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === applyContent.processLog.processLogId);
    if (eventProcessLogIndex === -1) return;
    const eventContent = eventProcessLogList[eventProcessLogIndex];
    eventContent.processStatus = applyContent.processLog.processStatus;
    eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
    setProcessLogList(eventProcessLogList);
    const detailWorkOrder = { ..._workOrderLog, processLogList: eventProcessLogList };
    setWorkOrderLog(detailWorkOrder);
    endCheck(detailWorkOrder, eventProcessLogList);
  };
  const applyTaskStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventProcessLogList = [..._processLogList];
    const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === applyContent.processLogId);
    if (eventProcessLogIndex === -1) return;
    const eventContent = eventProcessLogList[eventProcessLogIndex];
    const eventTaskLogList = [...eventContent.taskLogList];

    applyContent.taskSocketDtos.forEach((thisApplyTask) => {
      const eventTaskLogIndex = eventTaskLogList.findIndex((thisItem) => thisItem.taskLogId === thisApplyTask.taskLogId);
      if (eventTaskLogIndex === -1) return;
      const eventTaskContent = eventTaskLogList[eventTaskLogIndex];
      eventTaskContent.taskStatus = thisApplyTask.taskStatus;
      eventTaskContent.taskCustomizedContent = thisApplyTask.taskCustomizedContent;
      eventTaskLogList.splice(eventTaskLogIndex, 1, eventTaskContent);
      console.log('eventTaskLogList : ', eventTaskLogList);
    });
    eventContent.taskLogList = eventTaskLogList;
    eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
    setProcessLogList(eventProcessLogList);
    const detailWorkOrder = { ..._workOrderLog, processLogList: eventProcessLogList };
    setWorkOrderLog(detailWorkOrder);
  };

  /* ====================================================================== #5 */
  const setProcessStatus = (processLog) => {
    const { processLogId, processStatus } = processLog;

    if (workReducer.connectStatus !== 'connect') return alert(`
연결이 불안정합니다.
네트워크 상태 확인 후 다시 시도하여 주시기 바랍니다.
    `);
    if (_workOrderLog.workOrderStatus === 'end') return;
    if (_workOrderLog.workOrderStatus !== 'work') return alert('작업지시가 작업 중이 아닙니다.');

    setEventProcessLogId(Number(processLogId));
    setEventProcessStatus(processStatus);
    setWorkOrderStatusModal(true);
  };

  const publishProcess = async (BodyToWS) => {
    try {
      WS_CLIENT.publish({
        destination: '/pub/process',
        headers: { 'auth-token': getCookie('accessToken') && getCookie('accessToken').replace('Bearer ', '') },
        body: JSON.stringify(BodyToWS),
      });
    } catch (error) {
      console.log('client.publish/pub/process - error : ', error);
      alert(`
연결이 불안정합니다.
네트워크 상태 확인 후 다시 시도하여 주시기 바랍니다.
      `);
    } finally {
      setWorkOrderStatusModal(false);
    }
  }
  const handleProcessStatus = async (e) => {
    const { workordercode, status } = e.currentTarget.dataset;
    const processLogId = Number(workordercode);
    const processStatus = status;

    const eventProcessLogList = [..._processLogList];
    if (workReducer.connectStatus !== 'connect' || !checkNullArray(eventProcessLogList, false)) return;

    const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === processLogId);
    if (eventProcessLogIndex === -1) return;
    const eventProcessLog = eventProcessLogList[eventProcessLogIndex];

    const BodyToWS = {
      roomId: RoomId,
      type: '/process',
      workOrderLogId: _workOrderLog.workOrderLogId,
      processLogId: processLogId,
      processStatus: processStatus,
    };
    console.log('client.publish/pub/process - body : ', JSON.stringify(BodyToWS));

    if (processStatus === 'end') {
      let checkTaskStatus = false;
      eventProcessLog.taskLogList.forEach(taskLog => { if (taskLog.taskStatus !== 'lastend') return checkTaskStatus = true; })

      if (checkTaskStatus) {
        const confirmText = `
마무리가 안 된 작업이 있습니다.
계속 진행하시겠습니까?
        `;
        if (window.confirm(confirmText)) await publishProcess(BodyToWS);
        else return;
      } else {
        await publishProcess(BodyToWS);
      }
    } else {
      await publishProcess(BodyToWS);
    }
  };

  const publishTask = async (processLogId, processStatus, taskSocketDtos) => {
    const BodyToWS = {
      roomId: RoomId,
      type: '/task',
      workOrderLogId: _workOrderLog.workOrderLogId,
      processLogId: processLogId,
      processStatus: processStatus,
      taskSocketDtos: taskSocketDtos,
    };
    console.log('client.publish/pub/task - body : ', JSON.stringify(BodyToWS));

    try {
      WS_CLIENT.publish({
        destination: '/pub/task',
        headers: { 'auth-token': getCookie('accessToken') && getCookie('accessToken').replace('Bearer ', '') },
        body: JSON.stringify(BodyToWS),
      });
    } catch (error) {
      console.log('client.publish/pub/task - error : ', error);
      alert(`
연결이 불안정합니다.
네트워크 상태 확인 후 다시 시도하여 주시기 바랍니다.
      `);
    }
  }
  const handleTaskStatus = async (e) => {
    const { act, processlogid, processstatus, tasktype } = e.currentTarget.dataset;
    if (processstatus !== 'work') return;

    const processLogId = Number(processlogid);
    const processStatus = processstatus;

    dispatch(workActions_setWorkCase('task'));

    const eventProcessLogList = [..._processLogList];
    if (workReducer.connectStatus !== 'connect' || !checkNullArray(eventProcessLogList, false)) return;

    const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === processLogId);
    if (eventProcessLogIndex === -1) return;
    const eventContent = eventProcessLogList[eventProcessLogIndex];

    const eventTaskContent = eventContent.taskLogList.map((thisItem) => {
      if (act === 'cut' && thisItem.taskType === tasktype) {
        return { ...thisItem, taskStatus: act + 'end' };
      } else if (act !== 'cut' && thisItem.taskType.split('_')[0] === tasktype) {
        return { ...thisItem, taskStatus: act + 'end' };
      } else {
        return null;
      }
    });
    const eventTaskLogList = eventTaskContent.filter((thisItem) => thisItem !== null);

    await publishTask(processLogId, processStatus, eventTaskLogList);
  }

  /* 일괄 완료 */
  const actSetEndStatus = async (e) => {
    const eventTaskEl = e.target;
    const { processlogid, processstatus, tasktype } = eventTaskEl.dataset;
    if (processstatus !== 'work') return;

    const processLogId = Number(processlogid);
    const processStatus = processstatus;

    dispatch(workActions_setWorkCase('task'));

    const eventProcessLogList = [..._processLogList];
    if (workReducer.connectStatus !== 'connect' || !checkNullArray(eventProcessLogList, false)) return;

    const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === processLogId);
    if (eventProcessLogIndex === -1) return;
    const eventContent = eventProcessLogList[eventProcessLogIndex];

    const eventTaskContent = eventContent.taskLogList.map((thisItem) => {
      if (thisItem.taskType.split('_')[0] === tasktype) {
        return { ...thisItem, taskStatus: 'lastend' };
      } else {
        return null;
      }
    });
    const eventTaskLogList = eventTaskContent.filter((thisItem) => thisItem !== null);

    await publishTask(processLogId, processStatus, eventTaskLogList);
  };

  /* 보강재 */
  const setReinforcementStatus = async (processLog, taskType) => {
    const { processLogId, processStatus } = processLog;
    if (processStatus !== 'work') return;

    dispatch(workActions_setWorkCase('task'));

    const eventContent = { ...processLog };
    const eventTaskContent = eventContent.taskLogList.map((taskLog) => {
      if (taskLog.taskType.split('_')[0] === taskType) {
        const taskCustomizedContent = { ...checkNullParse(taskLog.taskCustomizedContent, {}) };
        if (taskCustomizedContent.reinforcement === 'reinforcementend') taskCustomizedContent.reinforcement = '';
        else taskCustomizedContent.reinforcement = 'reinforcementend';
        const returnData = { ...taskLog, taskCustomizedContent: JSON.stringify(taskCustomizedContent) };
        return returnData;
      } else {
        return null;
      }
    });
    const eventTaskLogList = eventTaskContent.filter((thisItem) => thisItem !== null);
    await publishTask(processLogId, processStatus, eventTaskLogList);
  };

  /* 절단 사이즈 버튼 */
  const actSendingData = async (processLog, task) => {
    console.log('actSendingData');
    const { processLogId, processStatus } = processLog;
    if (processStatus !== 'work') return;

    dispatch(workActions_setWorkCase('task'));

    const taskName = checkEmptyNull(task?.taskName, '').toLowerCase();
    if (taskName.indexOf('x') === -1) return alert('사이즈를 전송할 수 없습니다.');
    const cuttingLength = taskName.split('x')[0];
    console.log('taskName : ', taskName, cuttingLength);

    try {
      const BodyToPost = [{ name: 'cuttingLength', value: cuttingLength }];
      console.log('sendingData.sendingData : ', BodyToPost);
      await sendingData.sendingData('Uri', BodyToPost).then(response => {
        if (response === undefined) return;
        console.log('sendingData.sendingData : ', response);
      })
      const eventContent = { ...processLog };
      const eventTaskContent = eventContent.taskLogList.map((taskLog) => {
        if (taskLog.taskLogId === task.taskLogId) {
          const taskCustomizedContent = { ...checkNullParse(taskLog.taskCustomizedContent, {}) };
          taskCustomizedContent.sendingStatus = 'work';
          const returnData = { ...taskLog, taskCustomizedContent: JSON.stringify(taskCustomizedContent) };
          return returnData;
        } else {
          return null;
        }
      });
      const eventTaskLogList = eventTaskContent.filter((thisItem) => thisItem !== null);
      await publishTask(processLogId, processStatus, eventTaskLogList);
    } catch {
      return alert('사이즈를 전송할 수 없습니다.');
    }
  }
  const handlerSendingStatus = async (processLog, task) => {
    console.log('handlerSendingStatus');
    const { processLogId, processStatus } = processLog;
    if (processStatus !== 'work') return;

    dispatch(workActions_setWorkCase('task'));

    const eventContent = { ...processLog };
    const eventTaskContent = eventContent.taskLogList.map((taskLog) => {
      if (taskLog.taskLogId === task.taskLogId) {
        const taskCustomizedContent = { ...checkNullParse(taskLog.taskCustomizedContent, {}) };
        taskCustomizedContent.sendingStatus = '';
        const returnData = { ...taskLog, taskCustomizedContent: JSON.stringify(taskCustomizedContent) };
        return returnData;
      } else {
        return null;
      }
    });
    const eventTaskLogList = eventTaskContent.filter((thisItem) => thisItem !== null);
    await publishTask(processLogId, processStatus, eventTaskLogList);
  }

  /* ====================================================================== #6 */

  return (
    <GridNonBody contents={
      <>
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>{_workOrderLog.accountName + ' | ' + checkEmptyNull(_workOrderLog.workPlace, '')}</ModalTitle>
              <ModalHeaderButtons>
                {_workInfoStatus ? (
                  <button className="formButton" onClick={() => { setWorkInfoStatus((prev) => { return !prev }) }}>작업정보 닫기</button>
                ) : (
                  <button className="formButton" onClick={() => { setWorkInfoStatus((prev) => { return !prev }) }}>작업정보 열기</button>
                )}
                {_workOrderLog.workOrderStatus !== 'end' && _workOrderLog.workOrderStatus !== 'cancel' ?
                  <button className="formButton"
                    onClick={() => {
                      if (_authority.indexOf('501-2') !== -1 || _authority.indexOf('501-3') !== -1 || _authority.indexOf('501-4') !== -1 || _authority.indexOf('501-5') !== -1) setMultiStatusModalStatus(true)
                      else alert('권한이 없습니다.');
                    }}
                  >일괄 상태 변경</button>
                  : null}
                <ViewerButtons>
                  <ViewMinusButton viewScale={workReducer.viewScale} handleViewScale={(scale) => { dispatch(workActions_setViewScale(scale)) }} />
                  <ViewPlusButton viewScale={workReducer.viewScale} handleViewScale={(scale) => { dispatch(workActions_setViewScale(scale)) }} />
                  <ViewResetButton handleViewScale={() => { dispatch(workActions_setViewScale(65)) }} />
                </ViewerButtons>
              </ModalHeaderButtons>
              <CloseButton onClick={() => { navigate(pageReducer.currentPage, { replace: true }) }}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              {_workInfoStatus ? (
                <WorkOrderTable>
                  <table>
                    <colgroup>
                      <col width='50px'></col>
                      <col width='350px'></col>
                      <col width='50px'></col>
                      <col width='200px'></col>
                      <col width='50px'></col>
                      <col width='100px'></col>
                      <col width='50px'></col>
                      <col width='50px'></col>
                    </colgroup>
                    <tbody>
                      <tr>
                        <th rowSpan={3}>색상</th>
                        <td rowSpan={3} className='colors'>{_workOrderCustomizedContent.colors}</td>
                        <th>거래처</th>
                        <td>{_workOrderLog.accountName}</td>
                        <th>B/F납기</th>
                        <td>{checkNullObject(_workOrderCustomizedContent?.BF, false) ? _workOrderCustomizedContent?.BF?.date : _workOrderCustomizedContent.BFDate}</td>
                        <th>B/F</th>
                        <td>{checkNullObject(_workOrderCustomizedContent?.BF, false) ? _workOrderCustomizedContent?.BF?.amount : _workOrderCustomizedContent.BFAmount}</td>
                      </tr>
                      <tr>
                        <th>전화번호</th>
                        <td>{_workOrderCustomizedContent.phoneNumber}</td>
                        <th>S/F납기</th>
                        <td>{checkNullObject(_workOrderCustomizedContent?.SF, false) ? _workOrderCustomizedContent?.SF?.date : _workOrderCustomizedContent.TotalDate}</td>
                        <th>S/F</th>
                        <td>{checkNullObject(_workOrderCustomizedContent?.SF, false) ? _workOrderCustomizedContent?.SF?.amount : _workOrderCustomizedContent.TotalAmount}</td>
                      </tr>
                      <tr>
                        <th>주소</th>
                        <td>{_workOrderLog.workPlace}</td>
                        <th>M/F납기</th>
                        <td>{_workOrderCustomizedContent?.MF?.date}</td>
                        <th>M/F</th>
                        <td>{_workOrderCustomizedContent?.MF?.amount}</td>
                      </tr>
                      <tr>
                        <th rowSpan={2}>비고</th>
                        <td colSpan={3} rowSpan={2} className='remark'>{_workOrderLog.remark}</td>
                        <th>지시일자</th>
                        <td colSpan={3}>{DateFormat(checkEmptyNull(_workOrderLog.workOrderScheduledStartDate, ''))}</td>
                      </tr>
                      <tr>
                        <th>작성자</th>
                        <td colSpan={3}>{_workOrderLog.userName}</td>
                      </tr>
                    </tbody>
                  </table>
                </WorkOrderTable>
              ) : null}

              <TableSection content={
                <table style={{ zoom: `${workReducer.viewScale}%` }}>
                  <thead>
                    <tr>
                      <th style={{ minWidth: '100px', width: '100px' }}></th>
                      <th style={{ backgroundColor: 'var(--MainNavy)', color: 'var(--white)', minWidth: '60px', width: '60px' }}>NO</th>
                      <th style={{ minWidth: '220px', width: '220px' }}></th>
                      <th>자재</th>
                      <th style={{ backgroundColor: 'var(--MainNavy)', color: 'var(--white)' }}>색상</th>
                      <th>B/F</th>
                      <th>C/P</th>
                      <th>S/F</th>
                      <th>M/F</th>
                      <th>G/B</th>
                      <th>유리종류</th>
                      <th>M/C</th>
                      <th>비고</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkNullArray(_processLogList, []).map((processLog, index) => {
                      const processCustomizedContent = checkNullParse(processLog.processCustomizedContent, {
                        numStatus: '',
                        bundle: '',
                        bundleSize: '',
                        materialStatus: '',
                        materials: '',
                        colorStatus: '',
                        colors: '',
                        glassType_W: '',
                        glassType_H: '',
                        remark: '',
                      });

                      return (
                        <tr key={index + '_processLogList'}>
                          <StatusTdButton
                            statusStyle={{ /* cursor: 'default', */ minHeight: '200px', minWidth: '100px', width: '100px' }}
                            content={processLog}
                            contentId={processLog.processLogId}
                            status={processLog.processStatus}
                            statusEvent={() => {
                              if (_authority.indexOf('501-2') !== -1 || _authority.indexOf('501-3') !== -1 || _authority.indexOf('501-4') !== -1 || _authority.indexOf('501-5') !== -1) {
                                setProcessStatus(processLog)
                              } else alert('권한이 없습니다.');
                            }}
                          />
                          <td data-key={index} className={`numStatus ${processCustomizedContent.numStatus}`} style={{ minWidth: '60px', width: '60px' }}>{(index + 1).toString().padStart(2, '0')}</td>
                          <td style={{ minWidth: '220px', padding: 'unset', width: '220px' }}>
                            <DrawingCell>
                              <div data-pos="Top">
                                <p>{processCustomizedContent.bundle}</p>
                                <p>{processLog.processingCount}</p>
                              </div>

                              <div data-pos="Middle">{processCustomizedContent.bundleSize}</div>

                              <div data-pos="Bottom">
                                <Drawing>
                                  {checkNullArray(processLog?.attachFileList, false) && checkNullArray(processLog?.attachFileList, false).length > 0 ? (
                                    <img src={processLog?.attachFileList[0]?.fileData?.fileDataS3URL} alt={(index + 1).toString().padStart(2, '0') + '파일'} />
                                  ) : checkNullArray(processLog?.drawingList, false) && checkNullArray(processLog?.drawingList, false).length > 0 ?
                                    <img src={processLog?.drawingList[0]?.fileData?.fileDataS3URL} alt={(index + 1).toString().padStart(2, '0') + '파일'} />
                                    : null}
                                </Drawing>
                              </div>
                            </DrawingCell>
                          </td>

                          <td className={`materialStatus ${processCustomizedContent.materialStatus}`}>
                            {processCustomizedContent.materials.split(processCustomizedContent.materials.indexOf('\n') === -1 ? ' ' : '\n').map((thisMaterial) => {
                              materials_count++;
                              return (<p key={index + materials_count + thisMaterial} style={{ padding: '10px', textAlign: 'left' }}>{thisMaterial}</p>);
                            })}
                          </td>

                          <td className={`colorStatus ${processCustomizedContent.colorStatus}`}>
                            {processCustomizedContent.colors.split(processCustomizedContent.colors.indexOf('\n') === -1 ? ' ' : '\n').map((thisColor) => {
                              colors_count++;
                              return (<p key={index + colors_count + thisColor} style={{ padding: '10px', textAlign: 'left' }}>{thisColor}</p>);
                            })}
                          </td>

                          {taskTypeArray.map((thisType) => {
                            let taskCustomizedContent = {};

                            /* W */
                            let thisWContent = [];
                            let thisWContentList = { BF_W: [], CP_W: [], SF_W: [], MF_W: [], GB_W: [], MC_W: [] };

                            /* H */
                            let thisHContent = [];
                            let thisHContentList = { BF_H: [], CP_H: [], SF_H: [], MF_H: [], GB_H: [], MC_H: [] };

                            if (processLog.taskLogList.length > 0) {
                              thisWContent = processLog.taskLogList.filter((thisItem) => thisItem.taskType === thisType + '_W');
                              thisWContent.forEach((thisW) => thisWContentList[thisW.taskType].push(thisW));

                              thisHContent = processLog.taskLogList.filter((thisItem) => thisItem.taskType === thisType + '_H');
                              thisHContent.forEach((thisH) => thisHContentList[thisH.taskType].push(thisH));
                            }

                            /* W */
                            let thisWCutEndContent = { BF_W: [], CP_W: [], SF_W: [], MF_W: [], GB_W: [], MC_W: [] };
                            let thisWWeldingEndContent = { BF_W: [], CP_W: [], SF_W: [], MF_W: [], GB_W: [], MC_W: [] };
                            let thisWLastEndContent = { BF_W: [], CP_W: [], SF_W: [], MF_W: [], GB_W: [], MC_W: [] };

                            /* H */
                            let thisHCutEndContent = { BF_H: [], CP_H: [], SF_H: [], MF_H: [], GB_H: [], MC_H: [] };
                            let thisHWeldingEndContent = { BF_H: [], CP_H: [], SF_H: [], MF_H: [], GB_H: [], MC_H: [] };
                            let thisHLastEndContent = { BF_H: [], CP_H: [], SF_H: [], MF_H: [], GB_H: [], MC_H: [] };

                            /*  */
                            let thisReinforcementContent = { BF: [], CP: [], SF: [], MF: [], GB: [], MC: [] };
                            let thisWeldingEndContent = { BF: [], CP: [], SF: [], MF: [], GB: [], MC: [] };
                            let thisLastEndContent = { BF: [], CP: [], SF: [], MF: [], GB: [], MC: [] };

                            let glassCount_W = 0;
                            let glassCount_H = 0;
                            let remarkCount = 0;

                            if (processLog.taskLogList.length > 0) {
                              thisWContent.forEach((thisW) => {
                                if (checkNullParse(thisW.taskCustomizedContent, false)) {
                                  taskCustomizedContent = checkNullParse(thisW.taskCustomizedContent, {});
                                  if (taskCustomizedContent.reinforcement === 'reinforcementend') thisReinforcementContent[thisW.taskType.split('_')[0]].push(thisW);
                                }
                                switch (thisW.taskStatus) {
                                  case 'cutend':
                                    thisWCutEndContent[thisW.taskType].push(thisW);
                                    return;
                                  case 'weldingend':
                                    thisWWeldingEndContent[thisW.taskType].push(thisW);
                                    thisWeldingEndContent[thisW.taskType.split('_')[0]].push(thisW);
                                    return;
                                  case 'lastend':
                                    thisWLastEndContent[thisW.taskType].push(thisW);
                                    thisLastEndContent[thisW.taskType.split('_')[0]].push(thisW);
                                    return;

                                  default: return;
                                }
                              });

                              thisHContent.forEach((thisH) => {
                                if (checkNullParse(thisH.taskCustomizedContent, false)) {
                                  taskCustomizedContent = checkNullParse(thisH.taskCustomizedContent, {});
                                  if (taskCustomizedContent.reinforcement === 'reinforcementend') thisReinforcementContent[thisH.taskType.split('_')[0]].push(thisH);
                                }
                                switch (thisH.taskStatus) {
                                  case 'cutend':
                                    thisHCutEndContent[thisH.taskType].push(thisH);
                                    return;
                                  case 'weldingend':
                                    thisHWeldingEndContent[thisH.taskType].push(thisH);
                                    thisWeldingEndContent[thisH.taskType.split('_')[0]].push(thisH);
                                    return;
                                  case 'lastend':
                                    thisHLastEndContent[thisH.taskType].push(thisH);
                                    thisLastEndContent[thisH.taskType.split('_')[0]].push(thisH);
                                    return;

                                  default: return;
                                }
                              });
                            }

                            return (<Fragment key={index + thisType + 'smallProcesses'}>
                              <td style={{ padding: 'unset' }}>
                                <AmountCell>
                                  {processLog.processStatus === 'work' ? (
                                    parseFloat(thisWContentList[thisType + '_W'].length + thisHContentList[thisType + '_H'].length) > 0 ? (
                                      <TaskButton
                                        act="reinforcement"
                                        status={
                                          parseFloat(thisWContentList[thisType + '_W'].length + thisHContentList[thisType + '_H'].length) === thisReinforcementContent[thisType].length
                                            ? 'reinforcementend'
                                            : 'reinforcementwait'
                                        }
                                        data-act="reinforcement"
                                        data-processlogid={processLog.processLogId}
                                        data-processstatus={processLog.processStatus}
                                        data-tasktype={thisType}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (_authority.indexOf('501-2') !== -1) setReinforcementStatus(processLog, thisType);
                                          else alert('권한이 없습니다.');
                                        }}
                                      >
                                        보강재
                                      </TaskButton>
                                    ) : (<div></div>)
                                  ) : (<div></div>)}

                                  <TaskCell name="taskCells" data-memo="가로">
                                    <div data-memo="taskList">
                                      {checkNullArray(thisWContent, []).map((thisAmountW) => {
                                        const taskType = thisAmountW.taskType.split('_')[0];
                                        const taskCustomizedContent = checkNullParse(thisAmountW.taskCustomizedContent, {});
                                        if (processLog.processStatus === 'work' && (taskType === 'BF' || taskType === 'SF' || taskType === 'MF')) {
                                          return (
                                            <SendingDataButton key={index + thisType + '_W' + thisAmountW.taskLogId}
                                              className={taskCustomizedContent.sendingStatus === 'work' ? 'work' : ''}
                                              onMouseDown={(e) => {
                                                e.preventDefault();
                                                const current = Date.now();
                                                setLongClickTime(() => { return current });
                                              }}
                                              onMouseUp={(e) => {
                                                e.preventDefault();
                                                const result = Date.now() - _longClickTime;
                                                console.log('mouseup : ', result);
                                                if (_authority.indexOf('501-3') !== -1) {
                                                  if (result > 800) handlerSendingStatus(processLog, thisAmountW);
                                                  else actSendingData(processLog, thisAmountW);
                                                } else alert('권한이 없습니다.');
                                              }}
                                              onTouchStart={(e) => {
                                                e.preventDefault();
                                                const current = Date.now();
                                                setLongClickTime(() => { return current });
                                              }}
                                              onTouchEnd={(e) => {
                                                e.preventDefault();
                                                const result = Date.now() - _longClickTime;
                                                console.log('touchend : ', result);
                                                if (_authority.indexOf('501-3') !== -1) {
                                                  if (result > 800) handlerSendingStatus(processLog, thisAmountW);
                                                  else actSendingData(processLog, thisAmountW);
                                                } else alert('권한이 없습니다.');
                                              }}
                                            >
                                              {thisAmountW.taskName}
                                            </SendingDataButton>
                                          )
                                        } else {
                                          return (
                                            <div key={index + thisType + '_W' + thisAmountW.taskLogId}
                                              name="tasks"
                                              data-processlogid={processLog.processLogId}
                                              data-processstatus={processLog.processStatus}
                                              data-tasklogid={thisAmountW.taskLogId}
                                              data-taskstatus={thisAmountW.taskStatus}
                                              data-tasktype={thisAmountW.taskType}
                                              data-taskname={thisAmountW.taskName}
                                            >
                                              {thisAmountW.taskName}
                                            </div>
                                          );
                                        }
                                      })}
                                    </div>

                                    {processLog.processStatus === 'work' ? (
                                      thisWContentList[thisType + '_W'].length > 0 ? (
                                        <CutButton
                                          act="cut"
                                          status={
                                            thisWContentList[thisType + '_W'].length === thisWCutEndContent[thisType + '_W'].length
                                              ? 'cutend'
                                              : parseFloat(thisWContentList[thisType + '_W'].length + thisHContentList[thisType + '_H'].length) === parseFloat(thisWCutEndContent[thisType + '_W'].length + thisHCutEndContent[thisType + '_H'].length)
                                                ? 'cutend'
                                                : parseFloat(thisWContentList[thisType + '_W'].length + thisHContentList[thisType + '_H'].length) === thisWeldingEndContent[thisType].length
                                                  ? 'cutend'
                                                  : parseFloat(thisWContentList[thisType + '_W'].length + thisHContentList[thisType + '_H'].length) === thisLastEndContent[thisType].length
                                                    ? 'cutend'
                                                    : 'cutwait'
                                          }
                                          data-act="cut"
                                          data-processlogid={processLog.processLogId}
                                          data-processstatus={processLog.processStatus}
                                          data-taskstatus={
                                            thisWContentList[thisType + '_W'].length === thisWCutEndContent[thisType + '_W'].length
                                              ? 'cutend'
                                              : parseFloat(thisWContentList[thisType + '_W'].length + thisHContentList[thisType + '_H'].length) === parseFloat(thisWCutEndContent[thisType + '_W'].length + thisHCutEndContent[thisType + '_H'].length)
                                                ? 'cutend'
                                                : parseFloat(thisWContentList[thisType + '_W'].length + thisHContentList[thisType + '_H'].length) === thisWeldingEndContent[thisType].length
                                                  ? 'cutend'
                                                  : parseFloat(thisWContentList[thisType + '_W'].length + thisHContentList[thisType + '_H'].length) === thisLastEndContent[thisType].length
                                                    ? 'cutend'
                                                    : 'cutwait'
                                          }
                                          data-tasktype={thisType + '_W'}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            if (_authority.indexOf('501-3') !== -1) handleTaskStatus(e);
                                            else alert('권한이 없습니다.')
                                          }}
                                        >
                                          절단
                                        </CutButton>
                                      ) : null
                                    ) : null}
                                  </TaskCell>

                                  <TaskCell name="taskCells" data-type="세로">
                                    <div data-memo="taskList">
                                      {checkNullArray(thisHContent, []).map((thisAmountH) => {
                                        const taskType = thisAmountH.taskType.split('_')[0];
                                        const taskCustomizedContent = checkNullParse(thisAmountH.taskCustomizedContent, {});
                                        if (processLog.processStatus === 'work' && (taskType === 'BF' || taskType === 'SF' || taskType === 'MF')) {
                                          return (
                                            <SendingDataButton key={index + thisType + '_H' + thisAmountH.taskLogId}
                                              className={taskCustomizedContent.sendingStatus === 'work' ? 'work' : ''}
                                              onMouseDown={(e) => {
                                                e.preventDefault();
                                                const current = Date.now();
                                                setLongClickTime(() => { return current });
                                              }}
                                              onMouseUp={(e) => {
                                                e.preventDefault();
                                                const result = Date.now() - _longClickTime;
                                                console.log('mouseup : ', result);
                                                if (_authority.indexOf('501-3') !== -1) {
                                                  if (result > 800) handlerSendingStatus(processLog, thisAmountH);
                                                  else actSendingData(processLog, thisAmountH);
                                                } else alert('권한이 없습니다.')
                                              }}
                                              onTouchStart={(e) => {
                                                e.preventDefault();
                                                const current = Date.now();
                                                setLongClickTime(() => { return current });
                                              }}
                                              onTouchEnd={(e) => {
                                                e.preventDefault();
                                                const result = Date.now() - _longClickTime;
                                                console.log('touchend : ', result);
                                                if (_authority.indexOf('501-3') !== -1) {
                                                  if (result > 800) handlerSendingStatus(processLog, thisAmountH);
                                                  else actSendingData(processLog, thisAmountH);
                                                } else alert('권한이 없습니다.')
                                              }}
                                            >
                                              {thisAmountH.taskName}
                                            </SendingDataButton>
                                          )
                                        } else {
                                          return (
                                            <div key={index + thisType + '_H' + thisAmountH.taskLogId}
                                              name="tasks"
                                              data-processlogid={processLog.processLogId}
                                              data-processstatus={processLog.processStatus}
                                              data-tasklogid={thisAmountH.taskLogId}
                                              data-taskstatus={thisAmountH.taskStatus}
                                              data-tasktype={thisAmountH.taskType}
                                              data-taskname={thisAmountH.taskName}
                                            >
                                              {thisAmountH.taskName}
                                            </div>
                                          );
                                        }
                                      })}
                                    </div>

                                    {processLog.processStatus === 'work' ? (
                                      thisHContentList[thisType + '_H'].length > 0 ? (
                                        <CutButton
                                          act="cut"
                                          status={
                                            thisHContentList[thisType + '_H'].length === thisHCutEndContent[thisType + '_H'].length
                                              ? 'cutend'
                                              : parseFloat(thisWContentList[thisType + '_W'].length + thisHContentList[thisType + '_H'].length) === parseFloat(thisWCutEndContent[thisType + '_W'].length + thisHCutEndContent[thisType + '_H'].length)
                                                ? 'cutend'
                                                : parseFloat(thisWContentList[thisType + '_W'].length + thisHContentList[thisType + '_H'].length) === thisWeldingEndContent[thisType].length
                                                  ? 'cutend'
                                                  : parseFloat(thisWContentList[thisType + '_W'].length + thisHContentList[thisType + '_H'].length) === thisLastEndContent[thisType].length
                                                    ? 'cutend'
                                                    : 'cutwait'
                                          }
                                          data-act="cut"
                                          data-processlogid={processLog.processLogId}
                                          data-processstatus={processLog.processStatus}
                                          data-taskstatus={
                                            thisHContentList[thisType + '_H'].length === thisHCutEndContent[thisType + '_H'].length
                                              ? 'cutend'
                                              : parseFloat(thisWContentList[thisType + '_W'].length + thisHContentList[thisType + '_H'].length) === parseFloat(thisWCutEndContent[thisType + '_W'].length + thisHCutEndContent[thisType + '_H'].length)
                                                ? 'cutend'
                                                : parseFloat(thisWContentList[thisType + '_W'].length + thisHContentList[thisType + '_H'].length) === thisWeldingEndContent[thisType].length
                                                  ? 'cutend'
                                                  : parseFloat(thisWContentList[thisType + '_W'].length + thisHContentList[thisType + '_H'].length) === thisLastEndContent[thisType].length
                                                    ? 'cutend'
                                                    : 'cutwait'
                                          }
                                          data-tasktype={thisType + '_H'}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            if (_authority.indexOf('501-3') !== -1) handleTaskStatus(e);
                                            else alert('권한이 없습니다.')
                                          }}
                                        >
                                          절단
                                        </CutButton>
                                      ) : null
                                    ) : null}
                                  </TaskCell>

                                  {processLog.processStatus === 'work' ? (
                                    parseFloat(thisWContentList[thisType + '_W'].length + thisHContentList[thisType + '_H'].length) > 0 ? (
                                      parseFloat(thisWContentList[thisType + '_W'].length + thisHContentList[thisType + '_H'].length) ===
                                        parseFloat(thisWCutEndContent[thisType + '_W'].length + thisHCutEndContent[thisType + '_H'].length) ? (
                                        <TaskButtonsSection key={index + thisType + '_TaskButtons'}>
                                          <TaskButton
                                            act="welding"
                                            status={'weldingwait'}
                                            data-act="welding"
                                            data-processlogid={processLog.processLogId}
                                            data-processstatus={processLog.processStatus}
                                            data-tasktype={thisType}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              if (_authority.indexOf('501-4') !== -1) handleTaskStatus(e);
                                              else alert('권한이 없습니다.')
                                            }}
                                          >
                                            용접
                                          </TaskButton>
                                        </TaskButtonsSection>
                                      ) : parseFloat(thisWContentList[thisType + '_W'].length + thisHContentList[thisType + '_H'].length) ===
                                        thisWeldingEndContent[thisType].length ? (
                                        <TaskButtonsSection key={index + thisType + '_TaskButtons'}>
                                          <TaskButton
                                            act="last"
                                            status={'lastwait'}
                                            data-act="last"
                                            data-processlogid={processLog.processLogId}
                                            data-processstatus={processLog.processStatus}
                                            data-tasktype={thisType}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              if (_authority.indexOf('501-5') !== -1) handleTaskStatus(e);
                                              else alert('권한이 없습니다.')
                                            }}
                                          >
                                            마무리
                                          </TaskButton>
                                        </TaskButtonsSection>
                                      ) : parseFloat(thisWContentList[thisType + '_W'].length + thisHContentList[thisType + '_H'].length) === thisLastEndContent[thisType].length ? (
                                        <TaskButtonsSection key={index + thisType + '_TaskButtons'}>
                                          <TaskEndButton>용접</TaskEndButton>
                                          <TaskEndButton>마무리</TaskEndButton>
                                        </TaskButtonsSection>
                                      ) : (
                                        <TaskButtonsSection key={index + thisType + '_actSetEndStatusButton'}>
                                          <TaskButton
                                            act="actend"
                                            status={'actend'}
                                            data-processlogid={processLog.processLogId}
                                            data-processstatus={processLog.processStatus}
                                            data-tasktype={thisType}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              if (_authority.indexOf('501-3') !== -1 && _authority.indexOf('501-4') !== -1 && _authority.indexOf('501-5') !== -1) actSetEndStatus(e);
                                              else alert('권한이 없습니다.')
                                            }}
                                          >
                                            완료
                                          </TaskButton>
                                        </TaskButtonsSection>
                                      )
                                    ) : (<div></div>)
                                  ) : (<div></div>)}
                                </AmountCell>
                              </td>

                              {thisType === 'GB' && (
                                <td name="glassTypes">
                                  <AmountCell className="glassCell">
                                    <TaskCell>
                                      <div name="glassType_W">
                                        {checkEmptyNull(processCustomizedContent.glassType_W, false)
                                          ? processCustomizedContent.glassType_W.split(processCustomizedContent.glassType_W.indexOf('\n') === -1 ? ' ' : '\n').map((thisItem) => {
                                            glassCount_W++;
                                            return (<p key={index + 'glassType_W' + glassCount_W}>{thisItem}</p>);
                                          })
                                          : null}
                                      </div>
                                    </TaskCell>

                                    <TaskCell>
                                      <div name="glassType_H">
                                        {checkEmptyNull(processCustomizedContent.glassType_H, false)
                                          ? processCustomizedContent.glassType_H.split(processCustomizedContent.glassType_H.indexOf('\n') === -1 ? ' ' : '\n').map((thisItem) => {
                                            glassCount_H++;
                                            return (<p key={index + 'glassType_H' + glassCount_H}>{thisItem}</p>);
                                          })
                                          : null}
                                      </div>
                                    </TaskCell>
                                  </AmountCell>
                                </td>
                              )}

                              {thisType === 'MC' && (
                                <td name="remarks">
                                  {checkEmptyNull(processCustomizedContent.remark, false)
                                    ? processCustomizedContent.remark.split(processCustomizedContent.remark.indexOf('\n') === -1 ? ' ' : '\n').map((thisItem) => {
                                      remarkCount++;
                                      return (<p key={index + 'remark' + remarkCount}>{thisItem}</p>);
                                    })
                                    : null}
                                </td>
                              )}
                            </Fragment>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
              />
            </ModalMain>
          </ModalSection>
        </ModalBg>

        <ConnectStatus connectStatus={workReducer.connectStatus} onClick={() => { navigate(0) }}></ConnectStatus>

        {/* 모달 */}
        {/* 작업상태 변경 모달 */}
        {_workOrderStatusModal === true && (
          <WorkOrderStatusModal
            workOrderLogId={_eventProcessLogId}
            workOrderStatus={_eventProcessStatus}
            statusEvent={handleProcessStatus}
            open={_workOrderStatusModal}
            close={() => { setWorkOrderStatusModal(false); }}
          />
        )}
        {/* 일괄 상태 변경 모달 */}
        {_multiStatusModalStatus === true && (
          <UriMultiStatusModal
            workOrderLog={_workOrderLog}
            processList={_processLogList}
            open={_multiStatusModalStatus}
            close={() => { setMultiStatusModalStatus(false) }}
          />
        )}
        {/* 작업완료 모달 */}
        {_workEndStatus === true ? (
          <UriWorkEndModal
            content={_workEndOrder}
            open={_workEndStatus}
            close={() => { setWorkEndStatus(false); }}
          ></UriWorkEndModal>
        ) : null}
      </>
    } />
  );
};

export default UriCalendarWorkerScreen;
