import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat';

import LotInfo from 'pages/barcode/scan/infos/info/LotInfo';

const Lot = () => {
  const { barcodeReducer } = useSelector(state => state);

  const [_lotData, setLotData] = useState(barcodeReducer.barcodeContent);
  const handleLotConData = (data) => {
    const returnData = [
      {key: '입력시각', value: DateTimeFormat(checkEmptyNull(data.createdDate, ''))},
      {key: '공정유형', value: data.processTypeName},
      {key: '공정코드', value: data.processCode},
      {key: '공정이름', value: data.processName},
      {key: '책임자', value: checkNullArray(data.managers, []).map(manager => manager.workerName).join(' / ')},
      {key: '작업자', value: checkNullArray(data.workers, []).map(worker => worker.workerName).join(' / ')},
    ];
    return returnData;
  }
  const [_lotConData, setLotConData] = useState(handleLotConData(barcodeReducer.barcodeContent));
  
  const handleProcessResultElement = (processResultElementList, processResultElementType) => {
    const returnData = checkNullArray(processResultElementList, []).filter(item => item.processResultElementType === processResultElementType);
    return returnData;
  }
  const [_processResultPutElementList, setProcessResultPutElementList] = useState(handleProcessResultElement(barcodeReducer.barcodeContent?.processResultElements, 'Put'));
  const [_processResultMakeElementList, setProcessResultMakeElementList] = useState(handleProcessResultElement(barcodeReducer.barcodeContent?.processResultElements, 'Make'));

  const [_currentInfo, setCurrentInfo] = useState('lot');

  useEffect(() => {
    console.log('Lot : ', barcodeReducer.barcodeContent);
    setLotData(() => {return barcodeReducer.barcodeContent});
    setLotConData(() => {return handleLotConData(barcodeReducer.barcodeContent)});

    setProcessResultPutElementList(() => { return handleProcessResultElement(barcodeReducer.barcodeContent?.processResultElements, 'Put')});
    setProcessResultMakeElementList(() => { return handleProcessResultElement(barcodeReducer.barcodeContent?.processResultElements, 'Make')});
    
  }, [barcodeReducer.barcodeContent])

  const handleCurrentInfo = (info) => {
    setCurrentInfo(() => { return info });
  }

  return (
    <>
      <header className='header-barcode'>
        <h2>{_lotData.processCode}<div></div>{_lotData.processName}</h2>
        <div className='btn-infos'>
          {[
            // { info: 'element', name: '기준정보' },
            // { info: 'stockLog', name: '재고상세이력' },
            { info: 'lot', name: 'Lot' },
            // { info: 'serial', name: 'Serial' },
          ].map((info) => {
            return (
              <button key={info.info + '_info'}
                className={info.info === _currentInfo ? 'btn-info active' : 'btn-info'}
                onClick={() => { handleCurrentInfo(info.info) }}
              >
                {info.name}
              </button>
            )
          })}
        </div>
      </header>
      <main className='main-barcode'>
        {_currentInfo === 'lot' ? <LotInfo lotData={barcodeReducer.barcodeContent} lotConData={_lotConData} putElements={_processResultPutElementList} makeElements={_processResultMakeElementList} /> : null}
      </main>
    </>
  );
};

export default Lot;