import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';

import {monitoringActions_setQualityFilter } from 'store/modules/actions/common/monitoringActions';

import { qualityApi } from 'api/apis/qualityApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

import { SortContent, TableTitle } from 'pages/monitoring/styles';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';

const QualityMonitoring = ({total, setTimeCounter}) => {
  const dispatch = useDispatch();
  const {monitoringReducer, userReducer } = useSelector((state) => state);

  const [_authority, setAuthority] = useState([]);

  const [_qualityList, setQualityList] = useState([]);
  const [_qualityPage, setQualityPage] = useState({page: 0, size: total ? 3: 10, totalSize: 0});
  const [_qualityView, setQualityView] = useState(monitoringReducer.qualityFilter);

  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('104') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    getQualityList(_qualityPage.page, _qualityView);

    return () => {}
  }, [])
  useEffect(() => {}, [monitoringReducer.qualityFilter])

  const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    useEffect(() => {savedCallback.current = callback;}, [callback]);
    useEffect(() => {
      const tick = () => {savedCallback.current();};
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };
  useInterval(async () => {
    setQualityPage((prev) => {
      if (prev.page >= parseFloat(_qualityPage.totalSize / _qualityPage.size) - 1) {
        getQualityList(0, _qualityView);
        return { ...prev, page: 0 };
      } else {
        getQualityList(prev.page + 1, _qualityView);
        return { ...prev, page: prev.page + 1 };
      }
    });
    setTimeCounter(() => {return 30});
  }, 30000);

  const getQualityList = async(page, status) => {
    const paging = `?page=${page}&size=${_qualityPage.size}&sort=id,DESC`;
    const body={
      companyId: userReducer.company.companyId,
      workOrderStatus: ['work', 'end', 'cancel'],
    }
    if(status !== 'all') body.workOrderStatus = [status];
    await qualityApi.searchQualityWorkOrderLog(paging, body).then(response => {
      if(response === undefined) return;
      console.log('qualityApi.searchQualityWorkOrderLog : ', response);
      const workOrderLogList = [...response.data.content].map(workOrderLog => {
        const returnData = {...workOrderLog};
        const processLogList = workOrderLog.processLogList;
        if(checkNullArray(processLogList, false)) {
          processLogList.sort((a,b) => {return a.processNumber - b.processNumber})
        }
        returnData.processLogList = processLogList;
        return returnData;
      })
      setQualityList(() => {return workOrderLogList})
      setQualityPage((prev) => {return {...prev, totalSize: response.data.totalElements}})
    })
  }

  const handleSorting = (option) => {
    setQualityPage((prev) => {return{...prev, page: 0}});
    setQualityView(() => {
      dispatch(monitoringActions_setQualityFilter(option));
      getQualityList(0, option); 
      return option;
    })
  }

  return (
    <TableSection content={
      <table>
        <colgroup>
          <col width={'80px'} />
          <col />
          <col />
          <col />
          {(_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) ? <col /> : null}
          <col />
        </colgroup>
        <thead>
          <tr>
            <TableTitle colSpan={11}>
              <div className='TableTitleContent'>
                <p>불량현황</p>
                <SortContent>
                  <div className={monitoringReducer.qualityFilter === 'all' ? 'active' : ''}
                    onClick={(e) => {e.preventDefault(); handleSorting('all');}}
                  >
                    전체보기
                  </div>
                  <div className={monitoringReducer.qualityFilter === 'work' ? 'active' : ''}
                    onClick={(e) => {e.preventDefault(); handleSorting('work');}}
                  >
                    작업 중
                  </div>
                  <div className={monitoringReducer.qualityFilter === 'end' ? 'active' : ''}
                    onClick={(e) => {e.preventDefault(); handleSorting('end');}}
                  >
                    완료
                  </div>
                  <div className={monitoringReducer.qualityFilter === 'cancel' ? 'active' : ''}
                    onClick={(e) => {e.preventDefault(); handleSorting('cancel');}}
                  >
                    취소
                  </div>
                </SortContent>
              </div>
            </TableTitle>
          </tr>
          <tr>
            <th rowSpan={2}>작업상태</th>
            <th rowSpan={2}>작업유형</th>
            <th rowSpan={2}>작업코드</th>
            <th rowSpan={2}>작업이름</th>
            {(_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) ? <th rowSpan={2}>거래처</th> : null}
            <th colSpan={5} style={{backgroundColor: 'var(--ThirdBlue)'}}>생산목표품목</th>
          </tr>
          <tr>
            <th>물품정보</th>
            <th>목표생산량</th>
            <th style={{ backgroundColor: 'var(--MainBlue)' }}>총생산량</th>
            <th style={{ backgroundColor: 'var(--MainGreen)' }}>성공수량</th>
            <th style={{ backgroundColor: 'var(--MainRed)' }}>실패수량</th>
          </tr>
        </thead>
        <tbody>
          {_qualityList.map((workOrderLog, index) => {
            const qualityTargetProduct = checkNullArray(workOrderLog.qualityElementList, []);
            const rowLength = qualityTargetProduct.length;
            const returnItems = () => {
              let returnArray = [];
              for (let i = 1; i < rowLength; i++) {
                returnArray.push(
                  <tr key={index + '_' + i + '_qualityTargetProduct'}>
                    {qualityTargetProduct[i] ? (
                      <Fragment>
                        <td>{checkEmptyNull(qualityTargetProduct[i].elementName, '')}<br />{checkEmptyNull(qualityTargetProduct[i].elementCode, '')}</td>
                        <td>{(checkEmptyNull(qualityTargetProduct[i].baseAmount, 0) * 1).toLocaleString()}</td>
                        <td>{checkEmptyNull(BigNumber(checkEmptyNull(qualityTargetProduct[i].makeAmount, 0)).plus(BigNumber(checkEmptyNull(qualityTargetProduct[i].failedAmount, 0))).toNumber(), 0).toLocaleString()}</td>
                        <td>{(checkEmptyNull(qualityTargetProduct[i].makeAmount, 0) * 1).toLocaleString()}</td>
                        <td>{(checkEmptyNull(qualityTargetProduct[i].failedAmount, 0) * 1).toLocaleString()}</td>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <td></td><td></td><td></td><td></td><td></td>
                      </Fragment>
                    )}
                  </tr>,
                );
                return returnArray;
              }
            };
            return (
              <Fragment key={index + '_quality'}>
                <tr>
                  <StatusTdButton 
                    rowSpan={rowLength === 0 ? 1 : rowLength}
                    statusStyle={{ cursor: 'default', minWidth: '80px', width: '80px', zoom: '90%' }}
                    status={workOrderLog.workOrderStatus}
                  />
                  <td rowSpan={rowLength === 0 ? 1 : rowLength}>{workOrderLog.workOrderTypeName}</td>
                  <td rowSpan={rowLength === 0 ? 1 : rowLength}>{workOrderLog.workOrderCode}</td>
                  <td rowSpan={rowLength === 0 ? 1 : rowLength}>{workOrderLog.workOrderName}</td>
                  {(_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) ? (<td rowSpan={rowLength === 0 ? 1 : rowLength}>{workOrderLog?.accountName}</td>) : null}
                  {rowLength > 0 && checkNullObject(qualityTargetProduct[0], false) ? (
                    <Fragment>
                      <td>{checkEmptyNull(qualityTargetProduct[0].elementName, '')}<br />{checkEmptyNull(qualityTargetProduct[0].elementCode, '')}</td>
                      <td>{(checkEmptyNull(qualityTargetProduct[0].baseAmount, 0) * 1).toLocaleString()}</td>
                      <td>{checkEmptyNull(BigNumber(checkEmptyNull(qualityTargetProduct[0].makeAmount, 0)).plus(BigNumber(checkEmptyNull(qualityTargetProduct[0].failedAmount, 0))).toNumber(), 0).toLocaleString()}</td>
                      <td>{(checkEmptyNull(qualityTargetProduct[0].makeAmount, 0) * 1).toLocaleString()}</td>
                      <td>{(checkEmptyNull(qualityTargetProduct[0].failedAmount, 0) * 1).toLocaleString()}</td>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <td></td><td></td><td></td><td></td><td></td>
                    </Fragment>
                  )}
                </tr>
                {rowLength > 1 && returnItems()}
              </Fragment>
            )
          })}
        </tbody>
      </table>
    } />
  );
};

export default QualityMonitoring;