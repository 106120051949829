import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Pagination from 'react-js-pagination';

import { barcodeActions_setPrintList } from 'store/modules/actions/common/barcodeActions';

import { barcodeApi } from 'api/apis/barcode/barcodeApi';
import { processTypeApi } from 'api/apis/processTypeApi';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';
import { workOrderTypeApi } from 'api/apis/workOrderTypeApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import { DateTimeFormat } from 'components/format/DateTimeFormat';
import { lastPageButton, nextPageButton } from 'components/icons/src';

import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import ProcessResultModal from 'pages/barcode/print/contents/modal/ProcessResultModal';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import WorkOrderLog from 'pages/barcode/print/contents/table/WorkOrderLog';

const LotSection = styled.main`
  display: grid;
  grid-template-rows: auto minmax(calc(100% - 226px), calc(100% - 66px)) 60px;
  height: 100%;
  overflow: hidden;
  width: 100svw;

  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    h4 {
      padding-right: 20px;
      margin-right: 10px;
      border-right: 1px solid #ddd;
    }
    & .searchTypeButtons {
      display: flex;
      gap: 5px;
    }
  }

  & .resultSection {
    align-items: center;
    display: flex;
    /* flex-wrap: wrap; */
    height: 100%;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
    width: 100%;

    & .processResultSection {
      align-items: flex-start;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      gap: 40px;
      height: 100%;
      justify-content: flex-start;
      overflow: auto;
      padding: 30px;
      width: 100%;

      & .section-header {
        align-items: center;
        display: flex;
        grid-column: 1/4;
        justify-content: flex-end;
        position: sticky;
        top: 0px;
        width: 100%;
        z-index: 1;
      }

      & .pagination li {
        background-color: unset;
        border: unset;
        width: fit-content;
        
        & a {
          color: var(--MainNavy);
          height: 40px;
          width: 40px;
        }

        &.active {
          background-color: var(--MainNavy);
          border-radius: 50%;
          & a {color: var(--white);}
        }
      }

      & .processResultContent {
        align-items: flex-start;
        background-color: var(--white);
        border-radius: 20px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        box-sizing: border-box;
        /* cursor: pointer; */
        display: grid;
        gap: 12px;
        height: fit-content;
        padding: 20px 30px;
        width: 580px;

        & .content-header {
          align-items: center;
          display: flex;
          justify-content: space-between;
          width: 100%;

          & input[type="checkbox"] {
            margin: unset;
            z-index: 0;
          }
        }

        & .content-main {
          display: grid;
          gap: 20px;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: repeat(3, auto);
          width: 100%;

          & .content-data {
            align-items: flex-start;
            display: flex;
            /* flex-wrap: wrap; */
            gap: 10px;

            & b {
              color: var(--gray-300);
              font-size: 0.9em;
              font-weight: 600;
              min-width: 60px;
              text-align: right;
            }
          }

          & table {
            border-collapse: collapse;
            grid-column: 2/3;
            grid-row: 1/3;
            height: 100%;
            width: 100%;

            & th, & td {
              border: 1px solid var(--gray-200);
              box-sizing: border-box;
              padding: 5px;
              font-size: 0.8em;
              text-align: center;
            }
            & th {
              background-color: var(--MainNavy);
              color: var(--white);
            }
          }

          & .btn-detail {
            background-color: var(--Violet);
            border-radius: 25px;
            color: var(--white);
            height: 100%;
            padding: 10px;
            width: 100%;
          }
        }
      }
    }

  }

  &.half .processResultSection {
    justify-content: center;
    width: 50%;

    & .section-header {
      grid-column: 1/2;
      justify-content: space-between;

      & .pagination {
        margin: unset;
        width: fit-content;
      }
    }
  }
  &.half>.pagination {
    width: 50%;
  }
`;

const PageButton = styled.div`
  background-color: var(--MainNavy);
  mask-repeat: no-repeat; mask-size: contain;
  --webkit-mask-repeat: no-repeat; --webkit-mask-size: contain;
`;
const LastPageButton = styled(PageButton)`
  height: 16px;
  width: 16px;
  mask-image: url(${lastPageButton}); --webkit-mask-image: url(${lastPageButton});
`;
const FirstPageButton = styled(LastPageButton)`
  transform: rotate(-180deg);
`;
const NextPageButton = styled(PageButton)`
  height: 20px;
  width: 20px;
  mask-image: url(${nextPageButton}); --webkit-mask-image: url(${nextPageButton});
`;
const PrevPageButton = styled(NextPageButton)`
  transform: rotate(-180deg);
`;

const Lot = (props) => {
  const dispatch = useDispatch();
  const { barcodeReducer, userReducer } = useSelector(state => state);

  const { printList } = props;

  const LotCheckBox = useRef();

  const [_onload, setOnload] = useState('unload');

  const [_searchType, setSearchType] = useState('workOrderLog');

  const [_currentWorkOrderLog, setCurrentWorkOrderLog] = useState({});

  const [_processTypes, setProcessTypes] = useState([]);
  const [_processResultList, setProcessResultList] = useState([]);
  const [_processResultSearchData, setProcessResultSearchData] = useState({
    companyId: userReducer.company.companyId,
    processTypeId: '',
    workOrderId: '',
    startDate: '',
    endDate: '',
    processCode: '',
    processName: '',
  });
  const [_processResultPageNum, setProcessResultPageNum] = useState(1);
  const [_processResultTotalSize, setProcessResultTotalSize] = useState(0);

  const [_workOrderTypes, setWorkOrderTypes] = useState([]);
  const [_workOrderLogList, setWorkOrderLogList] = useState([]);
  const [_workOrderLogSearchData, setWorkOrderLogSearchData] = useState({
    companyId: userReducer.company.companyId,
    workOrderTypeId: '',
    workOrderStatus: 'all',
    scheduledStartDate: '',
    scheduledEndDate: '',
    workOrderCode: '',
    workOrderName: '',
  });
  const [_pageNum, setPageNum] = useState(1);
  const [_totalSize, setTotalSize] = useState(0);

  const [_currentProcessResult, setCurrentProcessResult] = useState({});
  const [_processResultModalStatus, setProcessResultModalStatus] = useState(false);

  useEffect(() => {
    if (_searchType === 'workOrderLog') {
      // 작업지시 기준
      getWorkOrderTypes();
      setPageNum(() => { return 1 });
      getWorkOrderLogList(_pageNum - 1, _workOrderLogSearchData.workOrderTypeId, _workOrderLogSearchData.workOrderStatus);
    } else {
      // 공정결과 기준
      getProcessTypes();
      setProcessResultPageNum(() => { return 1 });
      getProcessResultList(_processResultPageNum - 1, _processResultSearchData.processTypeId);
    }

    setOnload('loaded');
  }, [])

  useEffect(() => { }, [printList])
  useEffect(() => { }, [_workOrderLogList, _processResultList])
  useEffect(() => { }, [_workOrderLogSearchData, _processResultSearchData])

  useEffect(() => {
    if (_onload === 'loaded') {
      if (_searchType === 'workOrderLog') {
        // 작업지시 기준
        if (!checkNullArray(_workOrderTypes, false)) getWorkOrderTypes();
        getWorkOrderLogList(_pageNum - 1, _workOrderLogSearchData.workOrderTypeId, _workOrderLogSearchData.workOrderStatus);

        setProcessResultList(() => { return [] }); // 공정결과 초기화
        if (checkNullObject(_currentWorkOrderLog, false)) {
          setProcessResultPageNum(() => { return 1 });
          getProcessResultList(0, _processResultSearchData.processTypeId, _currentWorkOrderLog?.workOrderLogId);
        }
      } else {
        // 공정결과 기준
        if (!checkNullArray(_processTypes, false)) getProcessTypes();
        getProcessResultList(_processResultPageNum - 1, _processResultSearchData.processTypeId);
      }

      LotCheckBox.current.checked = false;
    }
  }, [_searchType])

  useEffect(() => {
    if (_onload === 'loaded') {
      if (_searchType === 'workOrderLog') {
        // 작업지시 기준
        if (checkNullObject(_currentWorkOrderLog, false)) {
          getProcessResultList(_processResultPageNum - 1, _processResultSearchData.processTypeId, _currentWorkOrderLog?.workOrderLogId);
        }
      } else {
        // 공정결과 기준
        getProcessResultList(_processResultPageNum - 1, _processResultSearchData.processTypeId);
      }
    }
    return () => { };
  }, [_processResultPageNum]);

  useEffect(() => {
    if (_onload === 'loaded') {
      getWorkOrderLogList(_pageNum - 1, _workOrderLogSearchData.workOrderTypeId, _workOrderLogSearchData.workOrderStatus);

      setProcessResultList(() => { return [] }); // 공정결과 초기화
    }
    return () => { };
  }, [_pageNum]);

  useEffect(() => {
    // 작업지시 선택 시
    if (_onload === 'loaded') {
      if (_searchType === 'workOrderLog') {
        setProcessResultPageNum(() => { return 1 });
        getProcessResultList(_processResultPageNum - 1, _processResultSearchData.processTypeId, _currentWorkOrderLog?.workOrderLogId);

        LotCheckBox.current.checked = false;
      }
    }
  }, [_currentWorkOrderLog]);

  // 공정결과
  const getProcessTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await processTypeApi.searchProcessType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('processTypeApi.searchProcessType : ', response);
      setProcessTypes(() => { return response.data });
    });
  };
  const getProcessResultList = async (page, processTypeId, workOrderId, clear) => {
    const paging = `?page=${page}&size=12&sort=id,DESC`;
    let BodyToPost = { ..._processResultSearchData };
    delete BodyToPost.processTypeId;
    delete BodyToPost.workOrderId;

    if (!clear && checkEmptyNull(processTypeId, false) && processTypeId !== 'all') {
      BodyToPost.processTypeId = Number(processTypeId);
    }
    if (!clear && checkEmptyNull(workOrderId, false) && _searchType === 'workOrderLog') {
      BodyToPost.workOrderId = Number(workOrderId);
    }

    if (clear) {
      BodyToPost = {
        companyId: userReducer.company.companyId,
        processTypeId: '',
        workOrderId: '',
        startDate: '',
        endDate: '',
        processCode: '',
        processName: '',
      }
    }

    await barcodeApi.searchLot(paging, BodyToPost).then(response => {
      if (response === undefined) return;
      console.log('barcodeApi.searchLot : ', response);

      setProcessResultList(() => { return response.data.content });
      setProcessResultTotalSize(() => { return response.data.totalElements });

      if (checkEmptyNull(_processResultSearchData.startDate, false) && checkEmptyNull(_processResultSearchData.endDate, false)) {
        if (_processResultSearchData.startDate > _processResultSearchData.endDate) {
          setProcessResultSearchData((prev) => {
            return { ...prev, scheduledStartDate: _processResultSearchData.endDate, scheduledEndDate: _processResultSearchData.startDate }
          })
        }
      }
    })
  }

  // 작업지시
  const getWorkOrderTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await workOrderTypeApi.searchWorkOrderType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderTypeApi.searchWorkOrderType : ', response);
      setWorkOrderTypes(() => { return response.data });
    });
  };
  const getWorkOrderLogList = async (page, workOrderTypeId, workOrderStatus, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    let BodyToPost = { ..._workOrderLogSearchData };
    delete BodyToPost.workOrderTypeId;

    if (!clear && checkEmptyNull(workOrderTypeId, false) && workOrderTypeId !== 'all') {
      BodyToPost.workOrderTypeId = Number(workOrderTypeId);
    }
    if (!clear && checkEmptyNull(workOrderStatus, false)) {
      switch (workOrderStatus) {
        case 'all': BodyToPost.workOrderStatus = ['work', 'pause', 'stop', 'end', 'cancel']; break;
        default: BodyToPost.workOrderStatus = [workOrderStatus]; break;
      }
    }
    if (!clear && checkEmptyNull(_workOrderLogSearchData.scheduledStartDate, false) && checkEmptyNull(_workOrderLogSearchData.scheduledEndDate, false)) {
      BodyToPost.scheduledStartDate = DateFormat(_workOrderLogSearchData.scheduledStartDate);
      BodyToPost.scheduledEndDate = DateFormat(_workOrderLogSearchData.scheduledEndDate);
      if (_workOrderLogSearchData.scheduledStartDate > _workOrderLogSearchData.scheduledEndDate) {
        BodyToPost.scheduledStartDate = DateFormat(_workOrderLogSearchData.scheduledEndDate);
        BodyToPost.scheduledEndDate = DateFormat(_workOrderLogSearchData.scheduledStartDate);
      }
    } else if (!clear && checkEmptyNull(_workOrderLogSearchData.scheduledStartDate, false) && !checkEmptyNull(_workOrderLogSearchData.scheduledEndDate, false)) {
      BodyToPost.scheduledStartDate = DateFormat(_workOrderLogSearchData.scheduledStartDate);
    } else if (!clear && checkEmptyNull(_workOrderLogSearchData.scheduledEndDate, false) && !checkEmptyNull(_workOrderLogSearchData.scheduledStartDate, false)) {
      BodyToPost.scheduledEndDate = DateFormat(_workOrderLogSearchData.scheduledEndDate);
    }

    if (clear) {
      BodyToPost = {
        companyId: userReducer.company.companyId,
        workOrderTypeId: '',
        workOrderStatus: 'all',
        scheduledStartDate: '',
        scheduledEndDate: '',
        workOrderCode: '',
        workOrderName: '',
      }
    }

    await workOrderLogApi.searchWorkOrderLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.searchWorkOrderLog : ', response);

      setWorkOrderLogList(() => { return response.data.data.content });
      setTotalSize(() => { return response.data.data.totalElements });

      if (checkEmptyNull(_workOrderLogSearchData.scheduledStartDate, false) && checkEmptyNull(_workOrderLogSearchData.scheduledEndDate, false)) {
        if (_workOrderLogSearchData.scheduledStartDate > _workOrderLogSearchData.scheduledEndDate) {
          setWorkOrderLogSearchData((prev) => {
            return { ...prev, scheduledStartDate: _workOrderLogSearchData.scheduledEndDate, scheduledEndDate: _workOrderLogSearchData.scheduledStartDate }
          })
        }
      }
    });
  }

  // 검색 기준 변경
  const handleSearchType = (searchType) => {
    setSearchType(() => { return searchType });
  }

  // 검색 엔터 키
  const handleOnKeyUp = (e) => {
    if (e.key === 'Enter') actSearch();
  };

  /* 검색 */
  const actSearch = async () => {
    if (_searchType === 'workOrderLog') {
      setPageNum(() => { return 1 });
      await getWorkOrderLogList(0, _workOrderLogSearchData.workOrderTypeId, _workOrderLogSearchData.workOrderStatus);
      setProcessResultPageNum(() => { return 1 });
      await getProcessResultList(0, _processResultSearchData.processTypeId, _currentWorkOrderLog?.workOrderLogId);
    } else {
      setProcessResultPageNum(() => { return 1 });
      await getProcessResultList(0, _processResultSearchData.processTypeId);
    }
  };

  /* 초기화 */
  const actReset = async () => {
    if (_searchType === 'workOrderLog') {
      setWorkOrderLogSearchData(() => {
        return {
          companyId: userReducer.company.companyId,
          workOrderTypeId: '',
          workOrderStatus: 'all',
          scheduledStartDate: '',
          scheduledEndDate: '',
          workOrderCode: '',
          workOrderName: '',
        }
      })

      setPageNum(() => { return 1 });
      await getWorkOrderLogList(0, 'all', 'all', true);

      setProcessResultPageNum(() => { return 1 });
      await getProcessResultList(0, 'all', _currentWorkOrderLog?.workOrderLogId, true);
    } else {
      setProcessResultSearchData(() => {
        return {
          companyId: userReducer.company.companyId,
          processTypeId: '',
          workOrderId: '',
          startDate: '',
          endDate: '',
          processCode: '',
          processName: '',
        };
      })

      setProcessResultPageNum(() => { return 1 });
      await getProcessResultList(0, 'all', '', true);
    }
  };

  /* 공정결과 전체 선택 */
  const handleAllCheckBox = (e) => {
    const isChecked = e.target.checked;
    const printList = [...barcodeReducer.printList];

    if (isChecked) {
      console.log(1);
      const newData = _processResultList.map((processResult) => {
        const thisFindIndex = printList.findIndex((item) => item.processResultId === processResult.processResultId);
        if (thisFindIndex !== -1) return null;
        else return processResult;
      });
      const filterData = newData.filter((data) => data !== null);
      const returnData = filterData.map(processResult => { return { ...processResult, type: 'lot' } })
      dispatch(barcodeActions_setPrintList([...printList, ...returnData]))
      e.target.checked = true;
    } else {
      console.log(2);
      const filterData = printList.filter(data => _processResultList.findIndex(result => result.processResultId === data.processResultId) === -1);
      dispatch(barcodeActions_setPrintList([...filterData]))
      e.target.checked = false;
    }
  }

  /* 공정결과 선택 */
  const handleCheckedItem = (processResult, isChecked) => {
    const checkedItem = [...barcodeReducer.printList];
    // const checkedIndex = checkedItem.findIndex((content) => content.processResultId === processResult.processResultId);
    const checkedIndex = checkedItem.findIndex((content) => content.type === 'lot' && content.processResultId === processResult.processResultId);
    if (checkedIndex !== -1) {
      if (!isChecked) checkedItem.splice(checkedIndex, 1);
    } else {
      if (isChecked) checkedItem.push({ ...processResult, type: 'lot' });
    }
    dispatch(barcodeActions_setPrintList(checkedItem))
  };

  return (
    <>
      <LotSection className={_searchType === 'workOrderLog' ? 'half' : ''}>
        <NavBar nav={'all'}
          title={
            <div className='Title'>
              <h4>Lot</h4>
              <div className='searchTypeButtons'>
                <button className={_searchType === 'workOrderLog' ? 'btn-inven-1' : 'btn-set'}
                  onClick={() => { handleSearchType('workOrderLog') }}
                >
                  작업지시 기준
                </button>
                <button className={_searchType === 'processResult' ? 'btn-inven-1' : 'btn-set'}
                  onClick={() => { handleSearchType('processResult') }}
                >
                  공정결과 기준
                </button>
              </div>
            </div>
          }
          firstRow={
            <>
              {_searchType === 'workOrderLog' ?
                <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <select
                    className="SelectType"
                    value={_workOrderLogSearchData.workOrderTypeId}
                    onChange={(e) => {
                      setWorkOrderLogSearchData((prev) => {
                        return { ...prev, workOrderTypeId: e.target.value };
                      })
                      setPageNum(() => { return 1 });
                      getWorkOrderLogList(0, e.target.value, _workOrderLogSearchData.workOrderStatus)
                    }}
                  >
                    <option value="all">전체</option>
                    {_workOrderTypes.map((workOrderType) => {
                      return (
                        <option key={workOrderType.workOrderTypeId + '_workOrderType'} value={workOrderType.workOrderTypeId}>
                          {workOrderType.workOrderTypeName}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    className="SelectStatus"
                    value={_workOrderLogSearchData.workOrderStatus}
                    onChange={(e) => {
                      setWorkOrderLogSearchData((prev) => {
                        return { ...prev, workOrderStatus: e.target.value };
                      })
                      setPageNum(() => { return 1 });
                      getWorkOrderLogList(0, _workOrderLogSearchData.workOrderTypeId, e.target.value)
                    }}
                  >
                    {[
                      { optionValue: 'all', optionText: '전체' },
                      { optionValue: 'work', optionText: '작업중' },
                      { optionValue: 'pause', optionText: '일지정지' },
                      { optionValue: 'stop', optionText: '중단' },
                      { optionValue: 'end', optionText: '완료' },
                      { optionValue: 'cancel', optionText: '취소' },
                    ].map((workOrderStatus) => {
                      return (
                        <option key={workOrderStatus.optionValue + '_workOrderStatus'} value={workOrderStatus.optionValue}>
                          {workOrderStatus.optionText}
                        </option>
                      );
                    })}
                  </select>
                  <div className="SearchSection">
                    <input
                      type="date"
                      className="SelectDate"
                      value={_workOrderLogSearchData.scheduledStartDate}
                      onChange={(e) => {
                        setWorkOrderLogSearchData((prev) => {
                          return { ...prev, scheduledStartDate: e.target.value };
                        })
                      }}
                    />

                    <IconRightArrow />

                    <input
                      type="date"
                      className="SelectDate"
                      value={_workOrderLogSearchData.scheduledEndDate}
                      onChange={(e) => {
                        setWorkOrderLogSearchData((prev) => {
                          return { ...prev, scheduledEndDate: e.target.value };
                        })
                      }}
                    />
                  </div>
                </div>
                : <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <select
                    className="SelectType"
                    value={_processResultSearchData.processTypeId}
                    onChange={(e) => {
                      setProcessResultSearchData((prev) => {
                        return { ...prev, processTypeId: e.target.value };
                      })
                      setPageNum(() => { return 1 });
                      getProcessResultList(0, e.target.value)
                    }}
                  >
                    <option value="all">전체</option>
                    {_processTypes.map((processType) => {
                      return (
                        <option key={processType.processTypeId + '_processType'} value={processType.processTypeId}>
                          {processType.processTypeName}
                        </option>
                      );
                    })}
                  </select>
                  <div className="SearchSection">
                    <input
                      type="date"
                      className="SelectDate"
                      value={_processResultSearchData.startDate}
                      onChange={(e) => {
                        setProcessResultSearchData((prev) => {
                          return { ...prev, startDate: e.target.value };
                        })
                      }}
                    />

                    <IconRightArrow />

                    <input
                      type="date"
                      className="SelectDate"
                      value={_processResultSearchData.endDate}
                      onChange={(e) => {
                        setProcessResultSearchData((prev) => {
                          return { ...prev, endDate: e.target.value };
                        })
                      }}
                    />
                  </div>
                </div>
              }
            </>
          }
          secondRow={
            <>
              {_searchType === 'workOrderLog' ?
                <div className="SearchSection">
                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="workOrderCode">작업코드</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={_workOrderLogSearchData.workOrderCode}
                      onInput={(e) => {
                        setWorkOrderLogSearchData((prev) => {
                          return { ...prev, workOrderCode: e.target.value };
                        })
                      }}
                      onKeyUp={handleOnKeyUp}
                    />
                  </div>

                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="workOrderName">작업이름</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={_workOrderLogSearchData.workOrderName}
                      onInput={(e) => {
                        setWorkOrderLogSearchData((prev) => {
                          return { ...prev, workOrderName: e.target.value };
                        })
                      }}
                      onKeyUp={handleOnKeyUp}
                    />
                  </div>

                  <div className="ContentCBox">
                    <div className="ResetButton detail" onClick={actReset}>초기화</div>
                    <div className="DetailButton search"
                      style={{ backgroundColor: 'var(--ThirdBlue)', columnGap: '3px' }}
                      onClick={actSearch}
                    >
                      <SearchButtonIcon />검색
                    </div>
                  </div>
                </div>
                : <div className="SearchSection">
                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="processCode">공정코드</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={_processResultSearchData.processCode}
                      onInput={(e) => {
                        setProcessResultSearchData((prev) => {
                          return { ...prev, processCode: e.target.value };
                        })
                      }}
                      onKeyUp={handleOnKeyUp}
                    />
                  </div>

                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="processName">공정이름</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={_processResultSearchData.processName}
                      onInput={(e) => {
                        setProcessResultSearchData((prev) => {
                          return { ...prev, processName: e.target.value };
                        })
                      }}
                      onKeyUp={handleOnKeyUp}
                    />
                  </div>

                  <div className="ContentCBox">
                    <div className="ResetButton detail" onClick={actReset}>초기화</div>
                    <div className="DetailButton search"
                      style={{ backgroundColor: 'var(--ThirdBlue)', columnGap: '3px' }}
                      onClick={actSearch}
                    >
                      <SearchButtonIcon />검색
                    </div>
                  </div>
                </div>
              }
            </>
          }
        />

        <section className='resultSection'>
          {_searchType === 'workOrderLog' ?
            <WorkOrderLog workOrderLogList={_workOrderLogList} currentWorkOrderLog={_currentWorkOrderLog} currentWorkOrderLogEvent={setCurrentWorkOrderLog} />
            : null}

          <section className='processResultSection'>
            <div className='section-header'>
              <input
                type="checkBox"
                ref={LotCheckBox}
                id='LotCheckBox'
                name="allCheckBox"
                style={{ display: 'none', }}
                onChange={handleAllCheckBox}
              />
              <label htmlFor='LotCheckBox' className='formButton'>공정결과 전체 선택</label>
              {/* <button ref={LotCheckBox} className='formButton' data-status={false} onClick={handleAllCheckBox}>공정결과 전체 선택</button> */}

              {_searchType === 'workOrderLog' ?
                <Pagination
                  activePage={_processResultPageNum}
                  itemsCountPerPage={12}
                  onChange={(e) => {
                    console.log('_processResultPageNum : ', e);
                    setProcessResultPageNum(() => { return e; });
                  }}
                  pageRangeDisplayed={1}
                  totalItemsCount={_processResultTotalSize}
                  firstPageText={<FirstPageButton />}
                  lastPageText={<LastPageButton />}
                  prevPageText={<PrevPageButton />}
                  nextPageText={<NextPageButton />}
                />
                : null}
            </div>
            {checkNullArray(_processResultList, []).map((processResult, index) => {
              return (
                <div key={index + '_processResult'} className='processResultContent'>
                  <div className='content-header'>
                    <input
                      type="checkBox"
                      name="lot"
                      data-key={processResult.processResultId}
                      checked={(() => {
                        const checkedId = checkNullArray(barcodeReducer.printList, []).findIndex((content) => content.type === 'lot' && content.processResultId === processResult.processResultId);
                        if (checkedId !== -1) return true;
                        else return false;
                      })()}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        handleCheckedItem(processResult, isChecked);
                      }}
                      onClick={(e) => { e.stopPropagation() }}
                    />
                    <div>{DateTimeFormat(checkEmptyNull(processResult.createdDate, ''))}</div>
                  </div>
                  <div className='content-main'>
                    {[
                      { name: '공정유형', value: processResult.processTypeName },
                      { name: '공정코드', value: processResult.processCode },
                      { name: '공정이름', value: processResult.processName },
                    ].map((content, contentIndex) => {
                      return (
                        <div key={contentIndex + '_content_processResult'} className='content-data'>
                          <b>{content.name}</b>{content.value}
                        </div>
                      )
                    })}

                    <table>
                      <thead>
                        <tr>
                          <th>책임자</th>
                          <th>작업자</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {checkNullArray(processResult.managers, []).map((manager, managerIndex) => {
                              return <Fragment key={managerIndex + '_managers'}>{manager.managerName}<br /></Fragment>
                            })}
                          </td>
                          <td>
                            {checkNullArray(processResult.workers, []).map((worker, workerIndex) => {
                              return <Fragment key={workerIndex + '_workers'}>{worker.workerName}<br /></Fragment>
                            })}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <button className='btn-detail'
                      onClick={() => {
                        setCurrentProcessResult(() => { return processResult });
                        setTimeout(setProcessResultModalStatus(() => { return true }), 1000);
                      }}
                    >
                      상세보기
                    </button>
                  </div>
                </div>
              )
            })}
          </section>
        </section>

        {_searchType === 'workOrderLog' ?
          <PagingComponent
            page={_pageNum}
            count={_totalSize}
            size={10}
            pageEvent={(page) => {
              console.log('page : ', page);
              setPageNum(() => { return page; });
            }}
          /> :
          <PagingComponent
            page={_processResultPageNum}
            count={_processResultTotalSize}
            size={12}
            pageEvent={(page) => {
              console.log('page : ', page);
              setProcessResultPageNum(() => { return page; });
            }}
          />
        }
      </LotSection>

      {_processResultModalStatus === true && (
        <ProcessResultModal
          processResult={_currentProcessResult}
          open={_processResultModalStatus}
          close={() => { setProcessResultModalStatus(false) }}
        />
      )}
    </>
  );
};

export default Lot;