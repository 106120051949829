import React from 'react';
import styled from 'styled-components';

const FormSection = styled.div`
  height: 100%;
  overflow: hidden;
  width: 100%;

  & .infoFormLayout { width: 1240px; }
`;

const FormLayout = styled.form`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 200px calc(100% - 200px);
  grid-template-rows: 100%;
  /* height: 100%; */
  height: calc(100% - 60px);
  overflow: hidden;
  margin: 30px auto;
  padding: 0px 30px;
  position: relative;
  width: 95%;
`;

const FormHeader = styled.div`
  background-color: var(--Bg);
  box-sizing: border-box;
  display: grid;
  gap: 50px;
  height: fit-content;
  left: 0px;
  padding: 20px 0;
  position: sticky;
  top: 0px;
  width: 200px;
  & .infoFormTitle { text-align: center }
`;

const FormTitle = styled.div`
  box-sizing: border-box;
  display: grid;
  font-size: 30px;
  font-weight: 500;
  max-width: 150px;
  `;

const FormButtons = styled.div`
  display: grid;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 5px;
  width: 100%;
`;

const FormContents = styled.div`
  align-content: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 4px 24px;
  /* height: calc(100% - 66px); */
  height: 100%;
  overflow: auto;
  justify-content: flex-start;
  padding-block-end: 50px;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}

  & .myProfileBox{
    box-sizing: border-box;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    
    .formBox{
      width: 60%;
    }
  }

  & .companyInfoBox{
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    gap: 0px 24px;
    width: 100%;
    padding-block: 10px;

    & .formBox {padding-block: 5px;}
    
    & .companyAddressBox{
      display: flex;
      gap: 0px 5px;
      width: 100%;
    }
  }

  & .formBox {height: fit-content;}

  & .userP {
      border: 2px solid var(--MainBlue);
      border-radius: 5px;
      box-sizing: border-box;
      height: 40px;
      padding: 5px;
      text-align: center;
      width: 100%;
      background-color: var(--MainBlue);
      color: var(--white);
      font-Weight: 600;
      width: 100%;
  }

  input {
    border: 1px solid var(--gray-200);
    border-radius: 5px;
    box-sizing: border-box;
    height: 40px;
    padding: 5px;
    width: 100%;

    &:focus {border: 1px solid var(--gray-200);}
    &[data-required="required"] {border: 2px solid var(--MainBlue);}
    &[data-required="required"]::placeholder {color: var(--MainBlue);}
  }
  input[type="checkbox"] {cursor: pointer; display: inline-block; height: 20px; width: 20px;}
  & .infoInput {
    background-color: #fff;
    text-align: center;
    border: 1px solid var(--gray-200);
    border-radius: 5px;
    box-sizing: border-box;
    height: 40px;
    padding: 5px;
    width: 100%;

    &:focus {border: 1px solid var(--gray-200);}
    &[data-required="required"] {border: 2px solid var(--MainBlue);}
    &[data-required="required"]::placeholder {color: var(--MainBlue);}
  }

  select {
    border: 1px solid var(--gray-200);
    border-radius: 5px;
    box-sizing: border-box;
    color: var(--Text);
    height: 40px;
    padding: 5px;
    width: 100%;

    &:focus {border: 1px solid var(--gray-200);}
    &[data-required="required"] {border: 2px solid var(--MainBlue); color: var(--MainBlue);}
  }
  & .userSelect {
    border: 1px solid var(--gray-200);
    border-radius: 5px;
    box-sizing: border-box;
    color: var(--Text);
    height: 100%;
    padding: 5px;
    text-align: center;
    width: 100%;

    &:focus {border: 1px solid var(--gray-200);}
    &[data-required="required"] {border: 2px solid var(--MainBlue);}
  }

  textarea {
    border: 1px solid var(--gray-200);
    border-radius: 5px;
    box-sizing: border-box;
    color: var(--Text);
    height: 80px;
    padding: 5px;
    width: 100%;

    &:focus {border: 1px solid var(--gray-200);}
    &[data-required="required"] {border: 2px solid var(--MainBlue); color: var(--MainBlue);}
  }

  & h4 {height: 35px; line-height: 35px;}
`;

const Form = (props) => {
  return (
    <FormSection /* style={props.formStyle} */>
      <FormLayout className={props.formLayout}/* style={props.formLayoutStyle} */>
        <FormHeader>
          <FormTitle className={props.titleClass}>{props.title}</FormTitle>
          <FormButtons>{props.buttons}</FormButtons>
        </FormHeader>

        <FormContents /* style={props.formSectionStyle} */>{props.forms}</FormContents>
      </FormLayout>
    </FormSection>
  );
};

export default Form;