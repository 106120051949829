import { combineReducers } from 'redux';

/* global */
import globalReducer from 'store/modules/reducers/globalReducer';

/* operator */
import operatorReducer from 'store/modules/reducers/operator/operatorReducer';

/* user */
import menuReducer from 'store/modules/reducers/user/menuReducer';
import userReducer from 'store/modules/reducers/user/userReducer';

/* ifis */
import chatReducer from 'store/modules/reducers/default/chatReducer';
import contentsReducer from 'store/modules/reducers/default/contentsReducer';
import drawingReducer from 'store/modules/reducers/default/drawingReducer';
import loginReducer from 'store/modules/reducers/default/loginReducer';
import modalReducer from 'store/modules/reducers/default/modalReducer';
import pageReducer from 'store/modules/reducers/default/pageReducer';
import searchReducer from 'store/modules/reducers/default/searchReducer';
import stockReducer from 'store/modules/reducers/default/stockReducer';
import workReducer from 'store/modules/reducers/default/workReducer';

/* dev */
import YoungContentsReducer from 'store/modules/reducers/dev/YoungContentsReducer';
import JunContentsReducer from 'store/modules/reducers/dev/JunContentsReducer';

/* company */
import uriReducer from 'store/modules/reducers/company/uriReducer';
import gsReducer from 'store/modules/reducers/company/gsReducer';
import daerimReducer from 'store/modules/reducers/company/daerimReducer';
import msReducer from 'store/modules/reducers/company/msReducer';

/* common */
import alarmReducer from 'store/modules/reducers/common/alarmReducer';
import accountReducer from 'store/modules/reducers/common/accountReducer';
import barcodeReducer from 'store/modules/reducers/common/barcodeReducer';
import contractReducer from 'store/modules/reducers/common/contractReducer';
import costReducer from 'store/modules/reducers/common/costReducer';
import currentStockReducer from 'store/modules/reducers/common/currentStockReducer';
import drawingManagementReducer from 'store/modules/reducers/common/drawingManagementReducer';
import elementReducer from 'store/modules/reducers/common/elementReducer';
import equipmentReducer from 'store/modules/reducers/common/equipmentReducer';
import equipmentStatusReducer from 'store/modules/reducers/common/equipmentStatusReducer';
import failedProductionOrderReducer from 'store/modules/reducers/common/failedProductionOrderReducer';
import fileReducer from 'store/modules/reducers/common/fileReducer';
import invoiceReducer from 'store/modules/reducers/common/invoiceReducer';
import moduleReducer from 'store/modules/reducers/common/moduleReducer';
import monitoringReducer from 'store/modules/reducers/common/monitoringReducer';
import notInStockReducer from 'store/modules/reducers/common/notInStockReducer';
import numpadReducer from 'store/modules/reducers/common/numpadReducer';
import processReducer from 'store/modules/reducers/common/processReducer';
import procurementReducer from 'store/modules/reducers/common/procurementReducer';
import productionHistoryReducer from 'store/modules/reducers/common/productionHistoryReducer';
import productionOrderReducer from 'store/modules/reducers/common/productionOrderReducer';
import productionStatusReducer from 'store/modules/reducers/common/productionStatusReducer';
import productionPerformanceReducer from 'store/modules/reducers/common/productionPerformanceReducer';
import qualityReducer from 'store/modules/reducers/common/qualityReducer';
import reProductionOrderReducer from 'store/modules/reducers/common/reProductionOrderReducer';
import shipmentReducer from 'store/modules/reducers/common/shipmentReducer';
import shipmentInspectionReducer from 'store/modules/reducers/common/shipmentInspectionReducer';
import shipmentInspectionItemReducer from 'store/modules/reducers/common/shipmentInspectionItemReducer';
import shipmentInspectionLogReducer from 'store/modules/reducers/common/shipmentInspectionLogReducer';
import stockHistoryReducer from 'store/modules/reducers/common/stockHistoryReducer';
import stockPlaceHistoryReducer from 'store/modules/reducers/common/stockPlaceHistoryReducer';
import stockMovingReducer from 'store/modules/reducers/common/stockMovingReducer';
import systemReducer from 'store/modules/reducers/common/systemReducer';
import teamReducer from 'store/modules/reducers/common/teamReducer';
import userManagementReducer from 'store/modules/reducers/common/userManagementReducer';
import workerReducer from 'store/modules/reducers/common/workerReducer';
import workOrderReducer from 'store/modules/reducers/common/workOrderReducer';

import dlMovementReducer from 'store/modules/reducers/common/dlMovementReducer';
import gsIncomingInspectionReducer from 'store/modules/reducers/common/gsIncomingInspectionReducer';

/* mall */
import mallElementReducer from 'store/modules/reducers/mall/mallElementReducer';
import mallOrderReducer from 'store/modules/reducers/mall/mallOrderReducer';
import mallQnAReducer from 'store/modules/reducers/mall/mallQnAReducer';
import mallUserReducer from 'store/modules/reducers/mall/mallUserReducer';

/* mallSite */
import mallSiteUserReducer from 'store/modules/reducers/mallSite/mallSiteUserReducer';
import mallSiteElementReducer from 'store/modules/reducers/mallSite/mallSiteElementReducer';
import mallSiteCartReducer from 'store/modules/reducers/mallSite/mallSiteCartReducer';
import mallSiteOrderReducer from 'store/modules/reducers/mallSite/mallSiteOrderReducer';
import mallSiteQnAReducer from 'store/modules/reducers/mallSite/mallSiteQnAReducer';
import mallMyOrderReducer from 'store/modules/reducers/mallSite/mallMyOrderReducer';
import mallMyQnAReducer from 'store/modules/reducers/mallSite/mallMyQnAReducer';

/* modal */
import modalFileReducer from 'store/modules/reducers/modal/modalFileReducer';

/* inquiry */
import inquiryReducer from 'store/modules/reducers/inquiry/inquiryReducer';

/* platform */
/* cluster */
import clusterReducer from 'store/modules/reducers/cluster/clusterReducer';
/* operating */
import operatingReducer from 'store/modules/reducers/operating/operatingReducer';

export default combineReducers({
  globalReducer,

  operatorReducer,

  userReducer, menuReducer,

  chatReducer,
  pageReducer, searchReducer,
  loginReducer,
  contentsReducer, modalReducer,
  drawingReducer, stockReducer, workReducer,

  YoungContentsReducer, JunContentsReducer,

  uriReducer, gsReducer, daerimReducer, msReducer,

  alarmReducer,
  accountReducer,
  barcodeReducer,
  contractReducer,
  costReducer,
  currentStockReducer,
  drawingManagementReducer,
  elementReducer,
  equipmentReducer,
  equipmentStatusReducer,
  failedProductionOrderReducer,
  fileReducer,
  invoiceReducer,
  moduleReducer,
  monitoringReducer,
  notInStockReducer,
  numpadReducer,
  processReducer,
  procurementReducer,
  productionHistoryReducer,
  productionOrderReducer,
  productionStatusReducer,
  productionPerformanceReducer,
  qualityReducer,
  reProductionOrderReducer,
  shipmentReducer,
  shipmentInspectionReducer,
  shipmentInspectionItemReducer,
  shipmentInspectionLogReducer,
  stockHistoryReducer,
  stockPlaceHistoryReducer,
  stockMovingReducer,
  systemReducer,
  teamReducer,
  userManagementReducer,
  workerReducer,
  workOrderReducer,
  dlMovementReducer,
  gsIncomingInspectionReducer,

  mallElementReducer, mallOrderReducer, mallQnAReducer, mallUserReducer,

  mallSiteUserReducer, mallSiteElementReducer, mallSiteCartReducer, mallSiteOrderReducer, mallSiteQnAReducer,
  mallMyOrderReducer, mallMyQnAReducer,

  modalFileReducer, inquiryReducer,

  clusterReducer,
  operatingReducer,
});
