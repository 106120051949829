import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { menuItems } from './menu/menuItems';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import MenuItems from 'mall/components/nav/MenuItems';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userReducer } = useSelector(state => state);

  const companyCode = location.pathname.split('/')[1];

  useEffect(() => { }, [userReducer.user])

  return (
    <>
      {menuItems.map((menu, index) => {
        const depthLevel = 0;
        return (
          <Fragment key={index + '_menu'}>
            {checkEmptyNull(userReducer.user.id, false) ? (
              <>
                <p>{checkEmptyNull(userReducer.user.name, '')} 님</p>
                <MenuItems items={menu} key={index} depthLevel={depthLevel} />
              </>
            ) : (
              <>
                <p style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); navigate(`/${companyCode}/mall/sign/login`, { replace: true }); }}>로그인</p>
              </>
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default Navbar;
