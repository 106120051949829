import styled from 'styled-components';
import { deleteIcon } from 'components/icons/src';

export const GSManHourModalComponent = styled.section`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;

  &>section { // 모달 섹션
    background-color: var(--white);
    border-radius: 10px;
    display: grid;
    grid-template-rows: 55px calc(100% - 55px);
    height: fit-content;
    max-width: 600px;
    max-height: 85%;
    overflow: hidden;
    width: 85%;

    &>header { // 모달 타이틀
      align-items: center;
      background-color: var(--gray-100);
      box-sizing: border-box;
      display: flex;
      height: 55px;
      justify-content: flex-start;
      padding: 0px 20px;
      position: relative;
      width: 100%;

      h3 { // 타이틀
        font-size: 20px;
        font-weight: 400;
        white-space: nowrap;
      }
      div.btn-close { // 닫기 버튼
        height: 25px;
        position: absolute;
        right: 15px;
        top: 15px;
        width: 25px;
      }
    }

    &>section:first-of-type { // 모달 메인
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
      width: 100%;

      &>section { // 모달 컨덴트
        box-sizing: border-box;
        display: grid;
        gap: 20px;
        height: fit-content;
        padding: 30px;
        width: 100%;

        & section.main-section {
          display: grid;

          & .main-info {
            display: grid;
            gap: 20px;

            .info-box {
              background-color: var(--Bg);
              border-radius: 20px;
              box-sizing: border-box;
              display: grid;
              gap: 10px;
              padding: 20px;
              position: relative;

              & p{
                display: grid;
                gap: 10px;
                grid-template-columns: 100px calc(100% - 110px);
                width: 100%;
      
                & span:first-child{
                  display: inline-block;
                  font-weight: 600;
                  text-align: right;
                  width: 100px;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DetailBtn = styled.div`
  cursor: pointer;
  height: 25px;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 25px;

  &>div {
    background-color: var(--MainRed);
    height: 28px;
    width: 28px;

    mask-image: url(${deleteIcon}); mask-repeat: no-repeat; mask-size: contain;
    --webkit-mask-image: url(${deleteIcon}); --webkit-mask-repeat: no-repeat; --webkit-mask-size: contain;
  }
`;