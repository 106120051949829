import { Cluster2RedirectHandler, ClusterBoard, ClusterMain, ClusterSignEmailFind, ClusterSignJoin, ClusterSignLogin, ClusterSignPwFind, CompanySelect, CompanySelectFromG, FindEmail, FindPw, Join, Login, Mall2RedirectHandler, MallMain, MallProductAll, MallProductDetail, MallProductList, MallSignEmailFind, MallSignJoin, MallSignLogin, MallSignPwFind, MallSiteMap, NotFound, OAuth2RedirectHandler, Operating2RedirectHandler, OperatingMain, OperatingSignLogin } from "../RouteImport";
import ClusterRouteLayout from "../layouts/ClusterRouteLayout";
import MallRouteLayout from "../layouts/MallRouteLayout";
import OperatingRouteLayout from "../layouts/OperatingRouteLayout";
import RouteLayout from "../layouts/RouteLayout";

export const guestRoutes = [
  { path: '*', element: <NotFound />, },

  {
    path: '/', element: <RouteLayout />,
    children: [
      { path: '', element: <Login /> },

      { path: 'oauth2/redirect', element: <OAuth2RedirectHandler />, }, // 토큰 받아옴

      { // 로그인 페이지
        path: 'sign', element: <RouteLayout />,
        children: [
          { path: '', element: <Login /> },
          { path: 'join', element: <Join /> },
          { path: 'email/find', element: <FindEmail /> },
          { path: 'pw/find', element: <FindPw /> },
        ]
      },

      { // 회사 선택 >> 가입코드 입력
        path: 'companySelect', element: <RouteLayout />,
        children: [
          { path: '', element: <CompanySelect /> },
          { path: 'google', element: <CompanySelectFromG /> }
        ]
      },
    ]
  },
];

export const guestMallRoutes = [
  { path: '*', element: <NotFound />, },

  {
    path: '/', element: <MallRouteLayout />,
    children: [
      { // 몰
        path: '', element: <MallRouteLayout />,
        path: ':code/mall', element: <MallRouteLayout />,
        children: [
          { path: 'oauth2/redirect', element: <Mall2RedirectHandler /> }, // 토큰 받아옴
          { path: 'sitemap', element: <MallSiteMap /> }, // 사이트맵 페이지
          { // 로그인 페이지
            path: 'sign', element: <MallRouteLayout />,
            children: [
              { path: '', element: <MallSignLogin />, },
              { path: 'login', element: <MallSignLogin />, },
              { path: 'join', element: <MallSignJoin />, },
              { path: 'email/find', element: <MallSignEmailFind /> },
              { path: 'pw/find', element: <MallSignPwFind /> },
            ],
          },
          {
            path: '', element: <MallMain />,
            children: [
              {
                path: 'product', element: <MallRouteLayout />,
                children: [
                  { path: 'all', element: <MallProductAll />, },  // 전체 물품
                  { path: 'list', element: <MallProductList />, },
                  { path: 'detail', element: <MallProductDetail />, },
                ],
              },
            ]
          },
        ]
      },
    ]
  },
];

export const guestPlatformClusterRoutes = [
  { path: '*', element: <NotFound />, },

  {
    path: '/', element: <ClusterRouteLayout />,
    children: [
      { path: '', element: <ClusterRouteLayout /> },

      { // 클러스터
        path: ':code/platform/cluster', element: <ClusterRouteLayout />,
        children: [
          { path: 'oauth2/redirect', element: <Cluster2RedirectHandler /> }, // 토큰 받아옴

          { // 로그인 페이지
            path: 'sign', element: <ClusterRouteLayout />,
            children: [
              { path: '', element: <ClusterSignLogin />, },
              { path: 'login', element: <ClusterSignLogin />, },
              { path: 'join', element: <ClusterSignJoin />, },
              { path: 'email/find', element: <ClusterSignEmailFind />, },
              { path: 'pw/find', element: <ClusterSignPwFind />, },
            ],
          },

          {
            path: '', element: <ClusterMain />,
            children: [
              { path: '', element: <ClusterBoard /> },
            ]
          },
        ]
      }
    ]
  },
];

export const guestPlatformOperatingRoutes = [
  { path: '*', element: <NotFound />, },

  {
    path: '/', element: <OperatingRouteLayout />,
    children: [
      { path: '', element: <OperatingRouteLayout /> },

      { // 클러스터
        path: ':code/platform/operating', element: <OperatingRouteLayout />,
        children: [
          { path: 'oauth2/redirect', element: <Operating2RedirectHandler /> }, // 토큰 받아옴

          { // 로그인 페이지
            path: 'sign', element: <OperatingRouteLayout />,
            children: [
              { path: '', element: <OperatingSignLogin />, },
              { path: 'login', element: <OperatingSignLogin />, },
              // { path: 'join', element: <ClusterSignJoin />, },
              // { path: 'email/find', element: <ClusterSignEmailFind />, },
              // { path: 'pw/find', element: <ClusterSignPwFind />, },
            ],
          },

          { path: '', element: <OperatingSignLogin />, },
        ]
      }
    ]
  },
];