export const InquiryStatus = (status) => {
  const inquiryStatus = [
    { status: 'wait', name: '대기', color: '#E0E0E0' },
    { status: 'requestCancel', name: '취소요청', color: '#C0C0C0' },
    { status: 'cancel', name: '취소 승인', color: '#B4BCFD' },
    { status: 'check', name: '확인중', color: '#B2DFEE' },
    { status: 'end', name: '답변완료', color: '#0063C4' },    
    { status: 'consultation', name: '협의', color: '#7A7ED2' },
  ];

  let returnName = '대기';
  const returnIndex = inquiryStatus.findIndex(item => item.status === status);
  if (returnIndex !== -1) returnName = inquiryStatus[returnIndex].name;
  return returnName;
};
