const initialState = {
  equipmentStatus: 'all',
  searchData: {},
  isPushedSearchButton: false,
  pageNumber: 1,
  totalSize: 0,
}
const resetState = {
  equipmentStatus: 'all',
  searchData: {},
  isPushedSearchButton: false,
  pageNumber: 1,
  totalSize: 0,
};

const equipmentStatusReducer = (state = initialState, action) => {
  switch(action.type){
    case 'SETEQUIPMENTSTATUS': return {...state, equipmentStatus : action.payload};
    case 'SETEQUIPMENTSTATUSSEARCHDATA': return {...state, searchData : action.payload};
    case 'SETEQUIPMENTSTATUSISPUSHEDSEARCHBUTTON': return {...state, isPushedSearchButton: action.payload};
    case 'SETEQUIPMENTSTATUSPAGENUMBER': return {...state, pageNumber: action.payload};
    case 'SETEQUIPMENTSTATUSTOTALSIZE': return {...state, totalSize: action.payload};
    case 'SETEQUIPMENTSTATUSRESET': return {...state, ...resetState};
    default: return state;
  };
};

export default equipmentStatusReducer;