import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { order } from 'api/apis/mall/order';

const MallStatusTdButtonsStyle = styled.div`
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
`;
const MallStatusButton = styled.div`
  align-items: center;
  background-color: var(--gray-200);
  color: var(--white);
  cursor: default;
  display: flex;
  font-size: 1.1em;
  height: 100%;
  justify-content: center;

  &.current.waiting {
    animation: WaitingStatus 1.5s infinite;
    background-color: var(--gray-900);
  }
  &.end.waiting {
    background-color: var(--MainBlue);
  }

  &.current.consulting {
    background-color: var(--SeconBlue);
  }
  &.end.consulting {
    background-color: var(--SeconBlue);
  }

  &.current.preparing {
    background-color: var(--ThirdBlue);
  }
  &.end.preparing {
    background-color: var(--ThirdBlue);
  }

  &.current.complete {
    background-color: var(--MainNavy);
  }
  &.end.complete {
    background-color: var(--MainNavy);
  }

  &.current.requestCancel {
    background-color: var(--MainRed);
  }

  &.active {
    cursor: pointer;

    &:hover {
      background-color: var(--MainGreen);
    }
  }
`;

const CancelButton = styled.div`
  align-items: center;
  background-color: var(--Bg);
  color: var(--white);
  display: flex;
  font-size: 1.1em;
  height: 100%;
  justify-content: center;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
`;

const MallWaitingTdButtons = (props) => {
  const { userReducer } = useSelector(state => state);

  const { mallOrder } = props;

  const actConsulting = () => {
    const confirmText = `
      주문상태를 [ 협의 중 ]으로 변경하시겠습니까?
    `;
    if (window.confirm(confirmText) === true) {
      const BodyToPut = {
        ...mallOrder,
        deleteMallOrderElementList: [],
        deleteFileDataList: [],
        userId: userReducer.user.id,
        status: 'consulting',
      };
      console.log('actConsulting : ', BodyToPut);
      actUpdate(BodyToPut);
    } else {
      return;
    }
  };
  const actPreparing = () => {
    const confirmText = `
      주문상태를 [ 상품 준비 중 ]으로 변경하시겠습니까?
    `;
    if (window.confirm(confirmText) === true) {
      const BodyToPut = {
        ...mallOrder,
        // mallOrderElementList: mallOrder.mallOrderElementList.map((element) => {
        //   return {
        //     mallOrderElementId: element.mallOrderElementId,
        //     mallElementId: element.mallElementId,
        //     count: element.count,
        //   };
        // }),
        deleteMallOrderElementList: [],
        deleteFileDataList: [],
        userId: userReducer.user.id,
        status: 'preparing',
      };
      console.log('actPreparing : ', BodyToPut);
      actUpdate(BodyToPut);
    } else {
      return;
    }
  };
  const actUpdate = async (BodyToPut) => {
    const putFormData = new FormData();
    putFormData.append(
      'key',
      new Blob([JSON.stringify(BodyToPut)], { type: 'application/json' }),
    );
    for (let values of putFormData.values()) {
      console.log('putFormData : ', values);
    }

    await order.updateMallOrder(mallOrder.mallOrderId, putFormData)
      .then((response) => {
        if (response === undefined) return;
        console.log('order.updateMallOrder : ', response);

        alert('주문상태를 변경했습니다.');
        props.handleMallOrderStatus(response.data);
      });
  };

  return (
    <MallStatusTdButtonsStyle>
      <MallStatusButton className="current waiting">대기</MallStatusButton>
      <MallStatusButton
        className="active"
        onClick={(e) => {
          e.stopPropagation();
          actConsulting();
        }}
      >
        협의 중
      </MallStatusButton>
      <MallStatusButton
        className="active"
        onClick={(e) => {
          e.stopPropagation();
          actPreparing();
        }}
      >
        상품 준비 중
      </MallStatusButton>
      <MallStatusButton>상품 발송</MallStatusButton>
      <MallStatusButton>취소 요청</MallStatusButton>
    </MallStatusTdButtonsStyle>
  );
};

const MallConsultingTdButtons = (props) => {
  const { userReducer } = useSelector(state => state);

  const { mallOrder } = props;

  const actPreparing = () => {
    const confirmText = `
      주문상태를 [ 상품 준비 중 ]으로 변경하시겠습니까?
    `;
    if (window.confirm(confirmText) === true) {
      const BodyToPut = {
        ...mallOrder,
        deleteMallOrderElementList: [],
        deleteFileDataList: [],
        userId: userReducer.user.id,
        status: 'preparing',
      };
      console.log('actPreparing : ', BodyToPut);
      actUpdate(BodyToPut);
    } else {
      return;
    }
  };
  const actUpdate = async (BodyToPut) => {
    const putFormData = new FormData();
    putFormData.append(
      'key',
      new Blob([JSON.stringify(BodyToPut)], { type: 'application/json' }),
    );
    for (let values of putFormData.values()) {
      console.log('putFormData : ', values);
    }

    await order.updateMallOrder(mallOrder.mallOrderId, putFormData)
      .then((response) => {
        if (response === undefined) return;
        console.log('mallOrderApi.updateMallOrder : ', response);

        alert('주문상태를 변경했습니다.');
        props.handleMallOrderStatus(response.data);
      });
  };

  return (
    <MallStatusTdButtonsStyle>
      <MallStatusButton className="end waiting">대기</MallStatusButton>
      <MallStatusButton className="current consulting">
        협의 중
      </MallStatusButton>
      <MallStatusButton
        className="active"
        onClick={(e) => {
          e.stopPropagation();
          actPreparing();
        }}
      >
        상품 준비 중
      </MallStatusButton>
      <MallStatusButton>상품 발송</MallStatusButton>
      <MallStatusButton>취소 요청</MallStatusButton>
    </MallStatusTdButtonsStyle>
  );
};

const MallPreparingTdButtons = (props) => {
  const { userReducer } = useSelector(state => state);

  const { mallOrder } = props;

  const actComplete = () => {
    const confirmText = `
      주문상태를 [ 상품 발송 ]으로 변경하시겠습니까?
    `;
    if (window.confirm(confirmText) === true) {
      const BodyToPut = {
        ...mallOrder,
        deleteMallOrderElementList: [],
        deleteFileDataList: [],
        userId: userReducer.user.id,
        status: 'complete',
      };
      console.log('actComplete : ', BodyToPut);
      actUpdate(BodyToPut);
    } else {
      return;
    }
  };
  const actUpdate = async (BodyToPut) => {
    const putFormData = new FormData();
    putFormData.append(
      'key',
      new Blob([JSON.stringify(BodyToPut)], { type: 'application/json' }),
    );
    for (let values of putFormData.values()) {
      console.log('putFormData : ', values);
    }

    await order.updateMallOrder(mallOrder.mallOrderId, putFormData)
      .then((response) => {
        if (response === undefined) return;
        console.log('mallOrderApi.updateMallOrder : ', response);

        alert('주문상태를 변경했습니다.');
        props.handleMallOrderStatus(response.data);
      });
  };

  return (
    <MallStatusTdButtonsStyle>
      <MallStatusButton className="end waiting">대기</MallStatusButton>
      <MallStatusButton className="end consulting">협의 중</MallStatusButton>
      <MallStatusButton className="current preparing">
        상품 준비 중
      </MallStatusButton>
      <MallStatusButton
        className="active"
        onClick={(e) => {
          e.stopPropagation();
          actComplete();
        }}
      >
        상품 발송
      </MallStatusButton>
      <MallStatusButton>취소 요청</MallStatusButton>
    </MallStatusTdButtonsStyle>
  );
};

const MallCompleteTdButtons = (props) => {
  return (
    <MallStatusTdButtonsStyle>
      <MallStatusButton className="end waiting">대기</MallStatusButton>
      <MallStatusButton className="end consulting">협의 중</MallStatusButton>
      <MallStatusButton className="end preparing">
        상품 준비 중
      </MallStatusButton>
      <MallStatusButton className="end complete">상품 발송</MallStatusButton>
      <MallStatusButton>취소 요청</MallStatusButton>
    </MallStatusTdButtonsStyle>
  );
};

const MallRequestCancelTdButtons = (props) => {
  return (
    <MallStatusTdButtonsStyle>
      <MallStatusButton>대기</MallStatusButton>
      <MallStatusButton>협의 중</MallStatusButton>
      <MallStatusButton>상품 준비 중</MallStatusButton>
      <MallStatusButton>상품 발송</MallStatusButton>
      <MallStatusButton className="current requestCancel">
        취소 요청
      </MallStatusButton>
    </MallStatusTdButtonsStyle>
  );
};

const MallCancelTdButtons = (props) => {
  return <CancelButton>취소</CancelButton>;
};

export {
  MallWaitingTdButtons,
  MallConsultingTdButtons,
  MallPreparingTdButtons,
  MallCompleteTdButtons,
  MallRequestCancelTdButtons,
  MallCancelTdButtons,
};
