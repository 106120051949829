import { NotFound, Operating2RedirectHandler, OperatingKPIMonitoring, OperatingMain, OperatingMyPage, OperatingProcessTracking, OperatingProductionStatus, OperatingSignLogin } from "preferences/route/RouteImport";
import OperatingRouteLayout from "preferences/route/layouts/OperatingRouteLayout";

export const operatingRouters = [
  { path: '*', element: <NotFound /> },

  {
    path: '/', element: <OperatingRouteLayout />,
    children: [
      { path: '', element: <OperatingRouteLayout /> },

      { // 클러스터
        path: ':code/platform/operating', element: <OperatingRouteLayout />,
        children: [
          {// 토큰 받아옴
            path: 'oauth2/redirect', element: <Operating2RedirectHandler />,
          },

          {// 로그인 페이지
            path: 'sign', element: <OperatingRouteLayout />,
            children: [
              { path: '', element: <OperatingSignLogin />, },
              { path: 'login', element: <OperatingSignLogin />, },
              // { path: 'join', element: <ClusterSignJoin />, },
              // { path: 'id/find', element: <ClusterSignEmailFind />, },
              // { path: 'pw/find', element: <ClusterSignPwFind />, },
            ],
          },

          {
            path: '', element: <OperatingMain />,
            children: [
              { path: 'monitoring', element: <OperatingKPIMonitoring /> },
              { path: 'productionStatus', element: <OperatingProductionStatus /> },
              { path: 'processTracking', element: <OperatingProcessTracking /> },
              { path: 'mypage', element: <OperatingMyPage /> },
            ]
          },
        ],
      },
    ]
  }
];