import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { stockPlaceHistory } from 'api/apis/stock/stockPlaceHistory';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import PagingComponent from 'components/paging/PagingComponent';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 40px;
  height: 85%;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
  }
`;

const StockPlaceHistoryModal = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const { stockElement } = props;

  const [_onload, setOnload] = useState('unload');

  const [_logList, setLogList] = useState([]);

  const [_pageNum, setPageNum] = useState(1);
  const [_pageCount, setPageCount] = useState(0);

  /* ====================================================================== #3 */
  useEffect(() => {
    getStockPlaceHistoryList(0);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getStockPlaceHistoryList(_pageNum - 1);
    }

    return () => { };
  }, [_pageNum]);

  /* ====================================================================== #4 */
  const getStockPlaceHistoryList = async (page) => {
    const paging = `?page=${page}&size=20&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      elementId: stockElement.elementId
    };

    await stockPlaceHistory.searchStockPlaceHistory(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('stockPlaceHistory.searchStockPlaceHistory : ', response);

      setLogList(() => { return response.data.content });
      setPageCount(() => { return response.data.totalElements });
    });
  };

  /* ====================================================================== #5 */
  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>{stockElement.elementName + ' (' + stockElement.elementCode + ') '}재고위치추적</ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              <TableSection
                content={
                  <table>
                    <thead>
                      <tr>
                        <th>재고 위치</th>
                        <th>수량</th>
                        <th>투입예정수량</th>
                        <th>사용량</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_logList.map((log, index) => {
                        return (
                          <tr key={index + '_stockPlaceLog'}>
                            <td>{log.stockPlaceName}</td>
                            <td>{checkEmptyNull(log.amount, 0).toLocaleString()}</td>
                            <td>{checkEmptyNull(log.scheduledInputStock, 0).toLocaleString()}</td>
                            <td>{checkEmptyNull(log.usedStock, 0).toLocaleString()}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                }
              ></TableSection>
            </ModalMain>

            <PagingComponent
              page={_pageNum}
              count={_pageCount}
              size={20}
              pageEvent={(page) => {
                console.log('page : ', page);
                setPageNum(() => { return page });
              }}
            />
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default StockPlaceHistoryModal;
