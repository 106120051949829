import { AxiosInterceptor } from "api/core/consts";

export const companyMenu = {
  getCompanyMenu: () => AxiosInterceptor.get('companyMenu?size=100'),
  searchCompanyMenu: (body) => AxiosInterceptor.post('companyMenu/search?size=100', body),
  createCompanyModules: (body) => AxiosInterceptor.put('company/addPageCodes', body),
  createCompanyMenu: (body) => AxiosInterceptor.post('companyMenu', body),
  updateCompanyMenu: (companyMenuId, body) => AxiosInterceptor.put('companyMenu/' + companyMenuId, body),
  deleteCompanyMenu: (companyMenuId) => AxiosInterceptor.delete('companyMenu/' + companyMenuId),
};
