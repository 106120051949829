import React from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Container, Title, Table, Tbody, Td, Th, Tr, TableContainer, PaginationContainer } from './Tab.style';

const STATUS_LABELS = {
  waiting: '대기',
  proceeding: '진행',
  end: '완료',
};

const formatNum = (num) =>
  num != null ? new Intl.NumberFormat().format(num) : '-';

const getFilteredAttributes = (attributes) =>
  attributes.reduce((acc, attribute) => {
    const { contractAttributeId, contractAttributeValueId } = attribute;
    if (
      !acc[contractAttributeId] ||
      acc[contractAttributeId].contractAttributeValueId <
        contractAttributeValueId
    ) {
      acc[contractAttributeId] = attribute;
    }
    return acc;
  }, {});

export default function ContractInfoTab({ data, contractId }) {
  const contract = data[0];
  const navigate = useNavigate();

  const filteredAttributes = Object.values(
    getFilteredAttributes(contract.contractAttributeValueList || []),
  );

  const handleNavigation = (type) => {
    const pathname = `/order/contract/${type}`;
    const searchParams = createSearchParams({
      [`${type}Content`]: JSON.stringify(contract),
    });
    navigate({ pathname, search: `?${searchParams}`, replace: true });
  };

  const contractDetails = [
    { label: '수주유형', value: contract.contractTypeName },
    { label: '수주이름', value: contract.contractName },
    { label: '수주코드', value: contract.contractCode },
    { label: '수주상태', value: STATUS_LABELS[contract.contractStatus] || '-' },
    { label: '거래처명', value: contract.accountName },
    { label: '수주주소', value: contract.address },
    { label: '수주일', value: contract.contractDate },
    { label: '납품요구일', value: contract.contractRegDate },
    { label: '납기일', value: contract.scheduledEndDate },
    { label: '납품일', value: contract.contractActualEndDate },
    {
      label: '수주총금액',
      value: formatNum(contract.contractPrice),
      align: 'right',
    },
  ];

  const renderTableRows = (details) =>
    details.map(({ label, value, align }, index) => (
      <Tr key={index}>
        <Th>{label}</Th>
        <Td style={{ textAlign: align || 'left' }}>{value ?? '-'}</Td>
      </Tr>
    ));

  return (
    <Container className="contract">
      <Title>
        수주 상세정보
        <PaginationContainer>
          <button onClick={() => handleNavigation('update')}>수정</button>
          <button onClick={() => handleNavigation('copy')}>복사</button>
        </PaginationContainer>
      </Title>
      <TableContainer>
        <Table>
          <Tbody>
            {renderTableRows(contractDetails)}
            {filteredAttributes.map((attribute, index) => (
              <Tr key={index}>
                <Th>{attribute.contractAttributeName}</Th>
                <Td>{attribute.value ?? '-'}</Td>
              </Tr>
            ))}
            <Tr>
              <Th>비고</Th>
              <Td>{contract.remark ?? '-'}</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};
