const initialState = {
  drawingArray: [],

  drawingContent: {},

  drawingIndex: null,

  workOrderDrawing: {},
};

const drawingReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'SETDRAWINGARRAY':
      return {
        ...state,
        drawingArray: action.payload
      };

    case 'SETDRAWINGCONTENT':
      return {
        ...state,
        drawingContent: action.payload
      };

    case 'SETDRAWINGINDEX':
      return {
        ...state,
        drawingIndex: action.payload
      };

    case 'SETWORKORDERDRAWING':
      return {
        ...state,
        workOrderDrawing: action.payload
      };

    case 'DRAWINGRESET':
      return initialState;

    default:
      return state;
  }
}

export default drawingReducer;