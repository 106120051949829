import styled from "styled-components";

import { folderFillIcon, folderIcon } from "components/icons/src";

export const FolderStyle = styled.div`
  height: fit-content;
  overflow: hidden;
  width: 100%;
  
  &.current {border-inline-end: 8px solid var(--MainNavy);}

  &>div:nth-of-type(1) { // 폴더
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    gap: 10px;
    height: 40px;
    justify-content: flex-start;
    overflow: hidden;
    /* transform: ${(props) => { return `translateX(${props.depth * 20}px)`; }}; */
    /* width: ${(props) => { return `calc(100% - ${props.depth * 20 + 10}px)`; }}; */
    padding-inline-start: ${(props) => { return `${props.depth * 20}px`; }};
    width: 100%;

    /* &.current {border-inline-end: 5px solid var(--MainNavy);} */
    &:hover {background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 16, 215, 0.1)); opacity: 0.8;}

    &>div { // 폴더 아이콘
      background-color: var(--MainNavy);
      height: 24px;
      width:  24px;

      &[data-toggle="open"] {mask-image: url(${folderIcon}); --webkit-mask-image: url(${folderIcon});}
      &[data-toggle="close"] {mask-image: url(${folderFillIcon}); --webkit-mask-image: url(${folderFillIcon});}
      mask-repeat: no-repeat; --webkit-mask-repeat: no-repeat;
      mask-size: contain; --webkit-mask-size: contain;
    }
    &>p { // 폴더명
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: keep-all;
      width: calc(100% - 29px);
      white-space: nowrap;
    }
  }
  &>div:nth-of-type(2) { // 하위 폴더 리스트
    display: grid;
    height: fit-content;
    width: 100%;
  }
`;