import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { fileCategoryApi } from 'api/apis/fileCategoryApi';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';

const DeleteFolder = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer } = useSelector((state) => state);

  /* ====================================================================== 2 */
  const [searchParams, setSearchParams] = useSearchParams();

  const fileCategoryId = searchParams.get('fileCategoryId');
  const fileCategoryName = searchParams.get('fileCategoryName');
  console.table({
    fileCategoryId: fileCategoryId,
    fileCategoryName: fileCategoryName,
  });

  /* ====================================================================== 3 */
  const actDelete = async () => {
    await fileCategoryApi
      .deleteFileCategory(fileCategoryId)
      .then((response) => {
        if (response === undefined) return;
        console.log('fileCategoryApi.deleteFileCategory : ', response);

        alert('폴더를 삭제했습니다.');
        navigate(pageReducer.currentPage, { replace: true });
      });
  };

  /* ====================================================================== 4 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body
      contents={
        <>
          <main className="Main">
            <NavBar title={'파일관리'} nav={''} />

            <DeleteForm
              delteTitle={'폴더'}
              deleteItem={
                <>
                  {'폴더이름 : ' + fileCategoryName}
                  <br />
                </>
              }
              deleteText={
                <>
                  {fileCategoryName} 폴더에 포함된{' '}
                  <span className="cautionText">모든 첨부 파일</span>이
                  삭제됩니다.
                </>
              }
              deleteButton={
                <>
                  <button
                    className="formButton cancle"
                    onClick={() => {
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                  <button
                    className="formButton delete"
                    onClick={() => {
                      if (window.confirm('폴더를 삭제합니다.')) actDelete();
                      else return;
                    }}
                  >
                    삭제
                  </button>
                </>
              }
            />
          </main>
        </>
      }
    />
  );
};

export default DeleteFolder;
