import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { teamApi } from 'api/apis/teamApi';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';

const DeleteTeam = () => {
  /* ========================================================================= #1 */
  const navigate = useNavigate();
  const { pageReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();

  const teamId = searchParams.get('teamId');
  const teamName = searchParams.get('teamName');
  console.table({
    teamId: teamId,
    teamName: teamName,
  });

  /* ====================================================================== #3 */

  /* ========================================================================= #4 */

  /* ====================================================================== #5 */
  const actDelete = async () => {
    if (teamId.teamId === 12 || teamId.teamId === 22) {
      alert('어드민 팀으로 삭제가 불가합니다.');
      return navigate(pageReducer.currentPage, { replace: true });
    }

    await teamApi.deleteTeam(teamId).then((response) => {
      if (response === undefined) return;
      console.log('teamApi.deleteTeam : ', response);

      alert('팀을 삭제했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body
      contents={
        <>
          <main className="Main">
            <NavBar title={'팀설정'} nav={''} />

            <DeleteForm
              delteTitle={'팀'}
              deleteItem={<>{'팀: ' + teamName}</>}
              deleteText={
                <>
                  팀을 삭제합니다.
                  <br />
                </>
              }
              deleteButton={
                <>
                  <button
                    className="formButton cancle"
                    onClick={() => {
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                  <button
                    className="formButton delete"
                    onClick={() => {
                      if (window.confirm('팀을 삭제합니다.')) actDelete();
                      else return;
                    }}
                  >
                    삭제
                  </button>
                </>
              }
            />
          </main>
        </>
      }
    />
  );
};

export default DeleteTeam;
