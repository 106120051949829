import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Sign } from 'mall/styles/style';

import { sign } from 'api/apis/mall/sign';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import { checkEmail } from 'components/checkValues/sign';

import MallLogo from 'mall/components/MallLogo';

const MallSignEmailFind = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const companyCode = location.pathname.split('/')[1];

  const [_mallUserName, setMallUserName] = useState('');

  const [_emailStatus, setEmailStatus] = useState(false);
  const [_email, setEmail] = useState('');

  const [_loginId, setLoginId] = useState('');

  // 아이디 찾기 => companyCode, mallUserName, phoneNumber
  const actFind = async () => {
    if (_mallUserName.length === 0) return alert('이름을 확인해주세요.');
    if (!checkEmptyNull(_email, false) || !checkEmail(_email)) return alert('이메일을 확인해주세요.');

    const BodyToPost = {
      companyCode: companyCode,
      name: _mallUserName,
      email: _email,
    };
    console.log('actFind - BodyToPost : ', BodyToPost);

    await sign.findUserLoginId(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('sign.findUserLoginId : ', response);

      setLoginId(() => { return response.data.loginId });
    });
  };

  return (
    <>
      <Sign>
        <header>
          <Link to={`/${companyCode}/mall/product/list`}>아이피스 몰</Link>
          <Link to={`/${companyCode}/mall/sign/login`}>로그인</Link>
        </header>
        <section className='sign emailfind'>
          {/* <MallLogo /> */}
          <h3>아이디 찾기</h3>
          <fieldset>
            <input type="text" placeholder="이름" required value={_mallUserName}
              onInput={(e) => {
                const mallUserName = e.target.value;
                setMallUserName(() => { return mallUserName; });
              }}
            />
            <input
              type="email"
              name="email"
              placeholder="이메일..."
              data-status={_emailStatus}
              value={_email}
              onInput={(e) => {
                const email = e.target.value;
                setEmailStatus(() => { return checkEmail(email) });
                setEmail(() => { return email });
              }}
            />
            {_loginId.length > 0 ? (
              <input type="text" disabled value={_loginId} />
            ) : null}
          </fieldset>
          {_loginId.length === 0 ? (
            <button type="submit" className='submit' onClick={actFind}>확인</button>
          ) : null}
          <div className='signitem'>
            <button onClick={() => { navigate(`/${companyCode}/mall/sign/login`, { replace: true }); }} >로그인</button>
            <button onClick={() => { navigate(`/${companyCode}/mall/sign/pw/find`, { replace: true }); }} >비밀번호 찾기</button>
            <button onClick={() => { navigate(`/${companyCode}/mall/sign/join`, { replace: true }); }} >회원가입</button>
          </div>
        </section>
      </Sign>
    </>
  );
};

export default MallSignEmailFind;
