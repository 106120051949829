import { AxiosInterceptor } from "api/core/consts";

export const workOrderLogApi = {
  getWorkOrderLog: (workOrderLogId) => AxiosInterceptor.get('workOrderLog/' + workOrderLogId),
  searchWorkOrderLog: (paging, body) => AxiosInterceptor.post('workOrderLog/search' + paging, body),
  createWorkOrderLog: (body) => AxiosInterceptor.post('workOrderLog', body),
  copyWorkOrderLog: (body) => AxiosInterceptor.post('workOrderLog', body),
  updateWorkOrderLog: (workOrderLogId, body) => AxiosInterceptor.put('workOrderLog/' + workOrderLogId, body),
  deleteWorkOrderLog: (workOrderLogId) => AxiosInterceptor.delete('workOrderLog/' + workOrderLogId),
  updateEndStatus: (workOrderLogId, body) => AxiosInterceptor.post('workOrderLog/' + workOrderLogId, body),
};
