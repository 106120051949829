export const shipmentInspectionActions_setShipmentInspectionItemType = (shipmentInspectionItemType) => {
  return {
    type: 'SETSHIPMENTINSPECTIONTYPE',
    payload: shipmentInspectionItemType,
  };
};

export const shipmentInspectionActions_setSearchData = (searchData) => {
  return {
    type: 'SETSEARCHSHIPMENTINSPECTIONDATA',
    payload: searchData,
  };
};
export const shipmentInspectionActions_setSearchOption = (searchOption) => {
  return {
    type: 'SETSEARCHSHIPMENTINSPECTIONOPTION',
    payload: searchOption,
  };
};
export const shipmentInspectionActions_setSearchText = (searchText) => {
  return {
    type: 'SETSEARCHSHIPMENTINSPECTIONTEXT',
    payload: searchText,
  };
};

export const shipmentInspectionActions_setPageNumber = (pageNumber) => {
  return {
    type: 'SETSHIPMENTINSPECTIONPAGENUMBER',
    payload: pageNumber,
  };
};
export const shipmentInspectionActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETSHIPMENTINSPECTIONTOTALSIZE',
    payload: totalSize,
  };
};

export const shipmentInspectionActions_setIsPushedSearchButton = (isPushedSearchButton) => {
  return {
    type: 'SETSHIPMENTINSPECTIONISPUSHEDSEARCHBUTTON',
    payload: isPushedSearchButton,
  };
};

export const shipmentInspectionActions_setReset = () => {
  return {
    type: 'SETSHIPMENTINSPECTIONRESET',
  };
};
