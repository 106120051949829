import React from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import styled from "styled-components";
import { GrAddCircle } from "react-icons/gr";

const AddressBtn = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  height: 35px;
  width:  35px;
  & :hover{
    border-radius: 50%;
    border: 2px solid var(--MainBlue);
    height: 30px;
    width:  30px;
  }
`

const DaumPost = ({ setCompanyInformation }) => {
  const scriptUrl = '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
  const open = useDaumPostcodePopup(scriptUrl);

  const handleComplete = (data) => {
    let addr = data.address; // 주소 변수
    let extraAddr = ''; // 지역 변수

    // 도로명 타입 주소가 있을 경우 참고항목을 조합한다.
    if (data.addressType === 'R') {
      // 법정동명이 있을 경우 추가한다.
      if (data.bname !== '') {
        extraAddr += data.bname;
      }
      // 건물명이 있을 경우 추가한다.
      if (data.buildingName !== '') {
        extraAddr += (extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName);
      }
      // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
      if (extraAddr !== '') {
        extraAddr = ` (${extraAddr})`;
      }
    }
    setCompanyInformation(prev => ({
      ...prev,
      regionName: extraAddr,
      address: addr,
      zip: data.zonecode,
      detailAddress: '', // 상세주소 초기화
    })); // 조합된 참고항목을 해당 필드에 넣는다.
  };

  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  return <AddressBtn type="button" onClick={handleClick}><GrAddCircle /></AddressBtn>
};

export default DaumPost;