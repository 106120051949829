export const moduleManagementActions_setPageNumber = (pageNumber) => {
  return {
    type: 'SETMODULEPAGENUMBER',
    payload: pageNumber,
  };
};

export const moduleManagementActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETMODULETOTALSIZE',
    payload: totalSize,
  };
};

export const moduleManagementActions_setReset = () => {
  return {
    type: 'SETMODULERESET',
  };
};

export const moduleManagementActions_setSearchText = (searchText) => {
  return {
    type: 'SETMODULESEARCHTEXT',
    payload: searchText,
  };
};