import React from 'react';
import styled from 'styled-components';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import StatusButton from 'components/buttons/StatusButton';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 250px;
  justify-content: flex-start;
  /* max-width: 900px; */
  min-width: 200px;
  overflow: hidden;
  width: 85%;

  max-width: ${(props) => {
    return props.status.toLowerCase() === 'all' ? '1100px' : '900px';
  }};
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  box-sizing: border-box;
  column-gap: 10px;
  display: flex;
  height: calc(100% - 55px);
  justify-content: center;
  padding: 20px;
  width: 100%;
`;

const GSWorkOrderStatusModal = (props) => {
  /* ====================================================================== #1 */

  /* ====================================================================== #2 */
  const { workOrderLogId, workOrderStatus, statusEvent } = props;
  console.log(
    'WorkOrderStatusModal - props : ',
    workOrderLogId,
    workOrderStatus,
    statusEvent,
  );

  /* ====================================================================== #3 */

  /* ====================================================================== #4 */

  /* ====================================================================== #5 */

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection status={workOrderStatus}>
            <ModalHeader>
              <ModalTitle>작업상태 설정</ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              {workOrderStatus === 'wait' ? (
                <>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'work'}
                    modal={true}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'pause'}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'stop'}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'end'}
                    statusEvent={statusEvent}
                    statusStyle={{ cursor: 'pointer', fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'cancel'}
                    statusEvent={statusEvent}
                    statusStyle={{
                      backgroundColor: 'var(--white)',
                      color: 'var(--MainRed)',
                      cursor: 'pointer',
                      fontSize: '30px',
                      outline: '8px solid var(--MainRed)',
                      outlineOffset: '-12px',
                    }}
                  ></StatusButton>
                </>
              ) : workOrderStatus === 'work' ? (
                <>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'wait'}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'pause'}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'stop'}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'end'}
                    statusEvent={statusEvent}
                    statusStyle={{ cursor: 'pointer', fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'cancel'}
                    statusEvent={statusEvent}
                    statusStyle={{
                      backgroundColor: 'var(--white)',
                      color: 'var(--MainRed)',
                      cursor: 'pointer',
                      fontSize: '30px',
                      outline: '8px solid var(--MainRed)',
                      outlineOffset: '-12px',
                    }}
                  ></StatusButton>
                </>
              ) : workOrderStatus === 'pause' ? (
                <>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'wait'}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'work'}
                    modal={true}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'stop'}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'end'}
                    statusEvent={statusEvent}
                    statusStyle={{ cursor: 'pointer', fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'cancel'}
                    statusEvent={statusEvent}
                    statusStyle={{
                      backgroundColor: 'var(--white)',
                      color: 'var(--MainRed)',
                      cursor: 'pointer',
                      fontSize: '30px',
                      outline: '8px solid var(--MainRed)',
                      outlineOffset: '-12px',
                    }}
                  ></StatusButton>
                </>
              ) : workOrderStatus === 'stop' ? (
                <>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'wait'}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'work'}
                    modal={true}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'pause'}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'end'}
                    statusEvent={statusEvent}
                    statusStyle={{ cursor: 'pointer', fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'cancel'}
                    statusEvent={statusEvent}
                    statusStyle={{
                      backgroundColor: 'var(--white)',
                      color: 'var(--MainRed)',
                      cursor: 'pointer',
                      fontSize: '30px',
                      outline: '8px solid var(--MainRed)',
                      outlineOffset: '-12px',
                    }}
                  ></StatusButton>
                </>
              ) : workOrderStatus === 'end' ? (
                <>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'wait'}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'work'}
                    modal={true}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'pause'}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'stop'}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'cancel'}
                    statusEvent={statusEvent}
                    statusStyle={{
                      backgroundColor: 'var(--white)',
                      color: 'var(--MainRed)',
                      cursor: 'pointer',
                      fontSize: '30px',
                      outline: '8px solid var(--MainRed)',
                      outlineOffset: '-12px',
                    }}
                  ></StatusButton>
                </>
              ) : workOrderStatus === 'cancel' ? (
                <>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'wait'}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'work'}
                    modal={true}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'pause'}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'stop'}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'end'}
                    statusEvent={statusEvent}
                    statusStyle={{ cursor: 'pointer', fontSize: '30px' }}
                  ></StatusButton>
                </>
              ) : workOrderStatus === 'all' ? (
                <>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'wait'}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'work'}
                    modal={true}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'pause'}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'stop'}
                    statusEvent={statusEvent}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'end'}
                    statusEvent={statusEvent}
                    statusStyle={{ cursor: 'pointer', fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    workOrderCode={workOrderLogId}
                    status={'cancel'}
                    statusEvent={statusEvent}
                    statusStyle={{
                      backgroundColor: 'var(--white)',
                      color: 'var(--MainRed)',
                      cursor: 'pointer',
                      fontSize: '30px',
                      outline: '8px solid var(--MainRed)',
                      outlineOffset: '-12px',
                    }}
                  ></StatusButton>
                </>
              ) : null}
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default GSWorkOrderStatusModal;
