import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { category } from 'api/apis/mall/category';

import { checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import IconRightArrow from 'components/icons/IconRightArrow';

const SelectCategorySection = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;

  & .modal {
    background-color: var(--white);
    border-radius: 10px;
    display: grid;
    grid-template-rows: 55px auto 50px;
    height: 85%;
    overflow: hidden;
    width: 85%;

    & header {
      align-items: center;
      background-color: var(--gray-100);
      box-sizing: border-box;
      display: flex;
      height: 55px;
      justify-content: flex-start;
      padding: 0px 20px;
      position: relative;
      width: 100%;

      & h4 {font-size: 20px; font-weight: 400; white-space: nowrap;}
      & .btn-close {height: 25px; position: absolute; right: 15px; top: 15px; width: 25px;}
    }

    & main {
      display: grid;
      grid-template-rows: 100px auto;
      overflow-y: auto;
      position: relative;

      & nav {
        align-items: center;
        background-color: var(--white);
        box-sizing: border-box;
        display: flex;
        gap: 10px;
        height: 100%;
        padding: 30px;
        position: sticky;
        top: 0px;

        & .btn-category {
          align-items: center;
          cursor: pointer;
          display: flex;
          height: 40px;
          justify-content: flex-start;
          min-width: 150px;
          padding-inline-start: 10px;
          width: fit-content;

          &:hover {border: 1px solid var(--gray-200); border-radius: 5px;}
        }
      }

      & .categories {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        height: fit-content;
        padding: 30px;
        
        & .category {
          align-items: center;
          border: 1px solid var(--gray-200);
          cursor: pointer;
          display: flex;
          font-size: 1.2em;
          justify-content: center;
          min-width: 200px;
          padding: 20px;
          width: fit-content;

          &.unset {background-color: var(--gray-100); border: unset; color: var(--gray-200);}
          &.active {background-color: var(--MainNavy); color: var(--white);}
        }
      }
    }

    & footer {
      align-items: center;
      display: flex;
      height: 50px;
      justify-content: center;
      position: unset;
      width: 100%;

      & .btn-modal {
        align-items: center;
        background-color: var(--MainNavy);
        color: var(--white);
        cursor: pointer;
        display: flex;
        height: 50px;
        justify-content: center;
        width: 100%;
      }
    }
  }
`;

const SelectMallCategory = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector(state => state);

  /* ====================================================================== #2 */
  const [categoryList, setCategoryList] = useState([]);

  const [_currentLevel, setCurrentLevel] = useState(1);

  const [clickedCategory, setClickedCategory] = useState({});
  const [clickedLevel1Category, setClickedLevel1Category] = useState({});
  const [clickedLevel2Category, setClickedLevel2Category] = useState({});
  const [clickedLevel3Category, setClickedLevel3Category] = useState({});
  const [clickedLevel4Category, setClickedLevel4Category] = useState({});
  const [clickedLevel5Category, setClickedLevel5Category] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    getCategoryList(1);

    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const getCategoryList = async (level) => {
    setCurrentLevel(() => { return level });

    const BodyToPost = {
      companyId: userReducer.company.companyId,
      mallCategoryLevel: level,
    };
    if (level === 2) {
      BodyToPost['mallCategoryParentLevel'] = 1;
      BodyToPost['mallCategoryParentCode'] = clickedLevel1Category.mallCategoryCode;
    }
    if (level === 3) {
      BodyToPost['mallCategoryParentLevel'] = 2;
      BodyToPost['mallCategoryParentCode'] = clickedLevel2Category.mallCategoryCode;
    }

    await category.searchMallCategory(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('category.searchMallCategory : ', response);
      setCategoryList(() => { return response.data; });
    });
  }

  /* ====================================================================== #5 */
  const handleCategoryEvent = (category) => {
    setClickedCategory(() => { return category });

    switch (_currentLevel) {
      case 1:
        setClickedLevel1Category(() => { return category });
        setClickedLevel2Category(() => { return {} });
        setClickedLevel3Category(() => { return {} });
        setClickedLevel4Category(() => { return {} });
        setClickedLevel5Category(() => { return {} });
        break;
      case 2:
        setClickedLevel2Category(() => { return category });
        setClickedLevel3Category(() => { return {} });
        setClickedLevel4Category(() => { return {} });
        setClickedLevel5Category(() => { return {} });
        break;
      case 3:
        setClickedLevel3Category(() => { return category });
        break;

      default: return;
    }
  }

  const actSelect = () => {
    const reuturnData = {
      clickedLevel1Category: clickedLevel1Category,
      clickedLevel2Category: clickedLevel2Category,
      clickedLevel3Category: clickedLevel3Category,
    };

    props.category(reuturnData);
  }

  /* ====================================================================== #6 */

  return (
    <SelectCategorySection>
      <section className='modal'>
        <header>
          <h4>패밀리 선택</h4>
          <div className='btn-close' onClick={props.close}><CloseButtonIcon /></div>
        </header>
        <main>
          <nav>
            <div className='btn-category' onClick={() => { getCategoryList(1); }}>{checkNullObject(clickedLevel1Category, false) ? clickedLevel1Category.mallCategoryName : '패밀리 1'}</div>
            <IconRightArrow />
            <div className='btn-category'
              onClick={() => {
                if (!checkNullObject(clickedLevel1Category, false)) return alert('패밀리 1을 선택해주세요.');
                getCategoryList(2);
              }}
            >
              {checkNullObject(clickedLevel2Category, false) ? clickedLevel2Category.mallCategoryName : '패밀리 2'}
            </div>
            <IconRightArrow />
            <div className='btn-category'
              onClick={() => {
                if (!checkNullObject(clickedLevel1Category, false)) return alert('패밀리 1을 선택해주세요.');
                if (!checkNullObject(clickedLevel2Category, false)) return alert('패밀리 2을 선택해주세요.');
                getCategoryList(3);
              }}
            >
              {checkNullObject(clickedLevel3Category, false) ? clickedLevel3Category.mallCategoryName : '패밀리 3'}
            </div>
          </nav>
          <div className='categories'>
            <div
              className='category unset'
              onClick={() => { handleCategoryEvent({}) }}
            >
              선택 안 함
            </div>
            {checkNullArray(categoryList, []).map((category, index) => {
              return (
                <div
                  key={index + '_category'}
                  className={(() => {
                    if (!checkNullObject(clickedCategory, false)) return 'category';

                    switch (_currentLevel) {
                      case 1: return checkNullObject(clickedLevel1Category, false) && clickedLevel1Category.mallCategoryId === category.mallCategoryId ? 'category active' : 'category';
                      case 2: return checkNullObject(clickedLevel2Category, false) && clickedLevel2Category.mallCategoryId === category.mallCategoryId ? 'category active' : 'category';
                      case 3: return checkNullObject(clickedLevel3Category, false) && clickedLevel3Category.mallCategoryId === category.mallCategoryId ? 'category active' : 'category';
                      case 4: return checkNullObject(clickedLevel4Category, false) && clickedLevel4Category.mallCategoryId === category.mallCategoryId ? 'category active' : 'category';
                      case 5: return checkNullObject(clickedLevel5Category, false) && clickedLevel5Category.mallCategoryId === category.mallCategoryId ? 'category active' : 'category';
                      default: return 'category';
                    }
                  })()}
                  onClick={() => { handleCategoryEvent(category) }}
                >
                  {category.mallCategoryName}
                </div>
              );
            })}
          </div>
        </main>
        <footer>
          <div className='btn-modal' onClick={actSelect}>선택</div>
        </footer>
      </section>
    </SelectCategorySection>
  );
};

export default SelectMallCategory;