import styled from 'styled-components';

export const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

export const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px calc(100% - 55px);
  height: 85%;
  overflow: hidden;
  width: 95%;
`;

export const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
export const ModalHeaderButtons = styled.div`
  align-items: center;
  gap: 10px;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 15px;

  & .btn-set {
    border: 1px solid var(--gray-300);
    box-shadow: unset;
    font-size: 15px;
    height: 35px;
    padding-inline: 20px;
    width: fit-content;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
export const ModalHeaderButton = styled.button``;
export const ModalTitle = styled.h2`
  font-weight: 600;
  white-space: nowrap;
`;
export const CloseButton = styled.div`
  font-size: 30px;
  cursor: pointer;
`;

export const ModalMain = styled.div`
  align-items: center;
  display: grid;
  height: 100%;
  grid-template-columns: 30% 70%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  gap: 10px;
  padding-inline: 10px;

  & > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    top: 0;
    height: 100%;
    overflow-y: hidden;
    z-index: 1;
  }

  & > div:last-child {
    height: 100%;
    overflow-y: auto;
  }

  & .TableSection {
    width: 100%;
    overflow-x: auto;
    
    td,
    th {
      font-size: 1rem;
      min-width: unset;
      padding: 10px;
    }
    td.amountCell,
    th.amountCell {
      min-width: 100px;
    }
  }
`;

