import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { stockMovingActions_setPageNumber, stockMovingActions_setTotalSize } from 'store/modules/actions/common/stockMovingActions';

import { stockMoving } from 'api/apis/stock/stockMoving';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat';
import Grid3Body from 'components/layouts/body/Grid3Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import TableSection from 'components/layouts/table/TableSection';

const Section = styled.main`
  & .TableSection {
    td, th {min-width: unset;}

    & .logContents {
      align-items: center;
      display: flex;
      gap: 10px;
      justify-content: flex-start;
      flex-wrap: wrap;
      /* & .arrow {color: var(--MainBlue);} */
      & .element {
        background-color: var(--MainRed);
        border: 2px solid var(--ThirdRed);
        box-sizing: border-box;
        border-radius: 25px;
        color: var(--white);
        display: flex;
        gap: 10px;
        padding: 10px 20px;
        width: fit-content;
        & p:first-child {
          border-right: 2px solid var(--white);
          padding-right: 10px;
        }
      }
      & .receiveElements {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: flex-start;
        & .element {
          /* background-color: #effbff; */
          background-color: var(--MainGreen);
          border-color: var(--ThirdGreen);
          font-weight: 600;
        }
      }
    }
  }
`;

const StockMovingLogs = () => {
  const dispatch = useDispatch();
  const { stockMovingReducer, userReducer } = useSelector((state) => state);

  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_logList, setLogList] = useState([]);

  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('308') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getLogList(stockMovingReducer.pageNumber - 1);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getLogList(stockMovingReducer.pageNumber - 1);
    }

    return () => { };
  }, [stockMovingReducer.pageNumber]);

  const getLogList = async (page, clear) => {
    const paging = `?page=${page}&size=15&sort=id,DESC`;
    const BodyToPost = { companyId: userReducer.company.companyId };

    if (!checkEmptyNull(stockMovingReducer.searchData.elementName, false)) {
      BodyToPost.elementName = stockMovingReducer.searchData.elementName;
    }
    if (!checkEmptyNull(stockMovingReducer.searchData.elementCode, false)) {
      BodyToPost.elementCode = stockMovingReducer.searchData.elementCode;
    }
    if (!checkEmptyNull(stockMovingReducer.searchData.stockPlaceName, false)) {
      BodyToPost.stockPlaceName = stockMovingReducer.searchData.stockPlaceName;
    }

    await stockMoving.searchMovingStockLogs(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('stockMoving.searchMovingStockLogs : ', response);
      setLogList(() => { return response.data.content; });
      dispatch(stockMovingActions_setTotalSize(response.data.totalElements));
    });
  };

  return (
    <>
      <Grid3Body contents={
        <>
          <Section className="Main">
            <NavBar
              title={<><NavTitle menuCode={'308'} />이력</>}
              subNavTabBtnLink='/stock/moving'
              subNavTabBtn='재고이동'
              nav=''
            />

            <TableSection content={
              <table>
                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col width={'40%'} />
                  <col width={'15%'} />
                </colgroup>
                <thead>
                  <tr>
                    <th>이동시각</th>
                    <th>물품이름</th>
                    <th>물품코드</th>
                    <th>재고이동</th>
                    <th>비고</th>
                  </tr>
                </thead>
                <tbody>
                  {_logList.map((log, index) => {
                    const findSendElement = log.movingStockElementList.find(item => {
                      if (item.type === 'Send') return true;
                      else return false;
                    });
                    const sendElement = checkNullObject(findSendElement, { elementCode: '', elementName: '', amount: '' });
                    const receiveElements = log.movingStockElementList.filter(item => item.id !== findSendElement.id);

                    return (
                      <tr key={index + '_logs'}>
                        <td>{DateTimeFormat(log.createdDate)}</td>
                        <td>{sendElement.elementName}</td>
                        <td>{sendElement.elementCode}</td>
                        <td>
                          <div className='logContents'>
                            <div className='element'>
                              <p>{sendElement.stockPlaceName}</p>
                              <p>{checkEmptyNull(sendElement.amount, 0).toLocaleString()}</p>
                            </div>
                            <div className='arrow'>▶</div>
                            <div className='receiveElements'>
                              {checkNullArray(receiveElements, []).map((receiveElement) => {
                                return (
                                  <div key={receiveElement.id + '_receiveElement'} className='element'>
                                    <p>{receiveElement.stockPlaceName}</p>
                                    <p>{checkEmptyNull(receiveElement.amount, 0).toLocaleString()}</p>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </td>
                        <td>{log.note}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            }
            />
          </Section>

          <PagingComponent
            page={stockMovingReducer.pageNumber}
            count={stockMovingReducer.totalSize}
            size={15}
            pageEvent={(page) => {
              console.log('page : ', page);
              dispatch(stockMovingActions_setPageNumber(page));
            }}
          />
        </>
      }
      />
    </>
  );
};

export default StockMovingLogs;
