export const modalReducer_reset = () => {
  return {
    type: 'MODALRESET'
  }
};

export const modalReducer_setCurrentFamilyId = (thisItem) => {
  return {
    type: 'MODALCURRENTFAMILYID',
    payload: thisItem
  }
};

export const modalReducer_setAllFamilies = (thisItem) => {
  return {
    type: 'MODALALLFAMILIES',
    payload: thisItem
  }
};
export const modalReducer_setFirstFamilies = (thisItem) => {
  return {
    type: 'MODALFIRSTFAMILIES',
    payload: thisItem
  }
};
export const modalReducer_setSecondFamilies = (thisItem) => {
  return {
    type: 'MODALSECONDFAMILIES',
    payload: thisItem
  }
};
export const modalReducer_setThirdFamilies = (thisItem) => {
  return {
    type: 'MODALTHIRDFAMILIES',
    payload: thisItem
  }
};
/* =============================== */
export const modalReducer_setFourthFamilies = (thisItem) => {
  return {
    type: 'MODALFOURTHFAMILIES',
    payload: thisItem
  }
};
export const modalReducer_setFifthFamilies = (thisItem) => {
  return {
    type: 'MODALFIFTHFAMILIES',
    payload: thisItem
  }
};
/* =============================== */

export const modalReducer_setFirstFamily = (thisItem) => {
  return {
    type: 'MODALFIRSTFAMILY',
    payload: thisItem
  }
};
export const modalReducer_setSecondFamily = (thisItem) => {
  return {
    type: 'MODALSECONDFAMILY',
    payload: thisItem
  }
};
export const modalReducer_setThirdFamily = (thisItem) => {
  return {
    type: 'MODALTHIRDFAMILY',
    payload: thisItem
  }
};

/* =============================== */
export const modalReducer_setFourthFamily = (thisItem) => {
  return {
    type: 'MODALFOURTHFAMILY',
    payload: thisItem
  }
};
export const modalReducer_setFifthFamily = (thisItem) => {
  return {
    type: 'MODALFIFTHFAMILY',
    payload: thisItem
  }
};
/* =============================== */

export const modalReducer_setProductType = (thisItem) => {
  return {
    type: 'MODALPRODUCTTYPE',
    payload: thisItem
  }
};
export const modalReducer_setWorkStatus = (thisItem) => {
  return {
    type: 'MODALWORKSTATUS',
    payload: thisItem
  }
};
export const modalReducer_setStartDate = (thisItem) => {
  return {
    type: 'MODALSTARTDATE',
    payload: thisItem
  }
};
export const modalReducer_setEndDate = (thisItem) => {
  return {
    type: 'MODALENDDATE',
    payload: thisItem
  }
};
export const modalReducer_setSearchOption = (thisItem) => {
  return {
    type: 'MODALSEARCHOPTION',
    payload: thisItem
  }
};
export const modalReducer_setSearchText = (thisItem) => {
  return {
    type: 'MODALSEARCHTEXT',
    payload: thisItem
  }
};

export const modalReducer_setCurrentPageNum = (thisItem) => {
  return {
    type: 'MODALCURRENTPAGENUM',
    payload: thisItem
  }
};
export const modalReducer_setTotalCount = (thisItem) => {
  return {
    type: 'MODALTOTALCOUNT',
    payload: thisItem
  }
};
export const modalReducer_setPageSize = (thisItem) => {
  return {
    type: 'MODALPAGESIZE',
    payload: thisItem
  }
};