import React, { Fragment } from 'react';
import { Table, Tbody, Td, Th, Title, Tr } from './Tab.style';

export default function ContractProcurementItems({ procurement }) {
  const formatNum = (num) =>
    num != null ? new Intl.NumberFormat().format(num) : '-';

  if (!procurement?.procurementElementList?.length) {
    return (
      <Fragment>
        <Title>발주물품</Title>
        <p>발주물품이 없습니다.</p>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Title>발주물품</Title>
      <Table>
        <Tbody>
          {procurement.procurementElementList.map((element, index) => {
            const { elementName, elementCode, elementStock } =
              element.element || {};
            return (
              <Fragment key={index}>
                <Tr>
                  <Th>물품정보</Th>
                  <Td>{elementName || '-'}</Td>
                </Tr>
                <Tr>
                  <Th>물품코드</Th>
                  <Td>{elementCode || '-'}</Td>
                </Tr>
                <Tr>
                  <Th>수량</Th>
                  <Td style={{ textAlign: 'right' }}>
                    {formatNum(element.quantity)}
                  </Td>
                </Tr>
                <Tr>
                  <Th>금액</Th>
                  <Td style={{ textAlign: 'right' }}>
                    {formatNum(element.price)}
                  </Td>
                </Tr>
                <Tr>
                  <Th>재고</Th>
                  <Td style={{ textAlign: 'right' }}>
                    {formatNum(elementStock)}
                  </Td>
                </Tr>
              </Fragment>
            );
          })}
        </Tbody>
      </Table>
    </Fragment>
  );
};
