import React from 'react';
import styled from 'styled-components';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import { zoomMinus } from 'components/icons/src';

const ViewMinusButtonStyle = styled.div`
  /* background-color: var(--MainNavy); */
  /* border-radius: 50%; */
  /* box-sizing: border-box; */
  /* color: var(--white); */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  font-weight: 600;
  height: 40px;
  width: 40px;
`;
const ViewMinusIcon = styled.div`
  background-color: var(--MainNavy);
  height: 30px;
  width: 30px;

  mask-image: url(${zoomMinus});
  mask-repeat: no-repeat;
  mask-size: contain;

  --webkit-mask-image: url(${zoomMinus});
  --webkit-mask-repeat: no-repeat;
  --webkit-mask-size: contain;
`;

const ViewMinusButton = (props) => {
  const actViewMinus = () => {
    const prevScale = props.viewScale;
    let setScale = parseFloat(prevScale - checkEmptyNull(props.range, 5));
    if (setScale < 10) {
      setScale = 10;
    }

    props.handleViewScale(setScale);
  };

  return (
    <ViewMinusButtonStyle onClick={actViewMinus}>
      <ViewMinusIcon />
    </ViewMinusButtonStyle>
  );
};

export default ViewMinusButton;
