import React, { useState } from 'react';
import {
  ModalBg,
  ModalHeader,
  ModalHeaderButton,
  ModalHeaderButtons,
  ModalMain,
  ModalSection,
  ModalTitle,
  CloseButton
} from './HaccpStandardsModal.style';
import TableSection from 'components/layouts/table/TableSection';
import { shipmentInspectionLog } from 'api/apis/shipmentInspection/shipmentInspectionLog';
import { IoClose } from "react-icons/io5";
import NavText from 'components/nav/NavText';


const HaccpStandardsModal = ({ isOpen, closeModal, data, update }) => {
  const [formData, setFormData] = useState(data);

  const handleResultChange = (e, index) => {
    const { value } = e.target;
    setFormData((prevFormData) => {
      const updatedInspectionItems = [
        ...prevFormData.shipmentInspectionItemLogList,
      ];
      updatedInspectionItems[index] = {
        ...updatedInspectionItems[index],
        result: value,
      };
      return {
        ...prevFormData,
        shipmentInspectionItemLogList: updatedInspectionItems,
      };
    });
  };

  const handleSave = async () => {
    console.log('모달에서 저장할 데이터:', formData);

    const shipmentInspectionItemLogList =
      formData.shipmentInspectionItemLogList.map((item) => {
        const returnData = {
          number: item.number,
          shipmentInspectionItemId: item.shipmentInspectionItemId,
          shipmentInspectionItemTypeName: item.shipmentInspectionItemTypeName,
          content: item.content,
          criteria: item.criteria,
          result: item.result,
        };
        if (item.shipmentInspectionItemLogId) {
          returnData['shipmentInspectionItemLogId'] =
            item.shipmentInspectionItemLogId;
        }
        return returnData;
      });

    const BodyToPut = {
      ...formData,
      shipmentInspectionItemLogList: shipmentInspectionItemLogList,
      deletedShipmentInspectionItemLogList: [],
    };

    try {
      const response = await shipmentInspectionLog.updateShipmentInspectionLog(
        formData.shipmentInspectionLogId,
        BodyToPut,
      );

      if (response) {
        alert('성공적으로 수정되었습니다.');
        update();
      } else {
        console.error('데이터 저장 실패');
      }
    } catch (error) {
      console.error('서버 요청 중 오류 발생:', error);
    }
  };

  return (
    <ModalBg isOpen={isOpen} onRequestClose={closeModal}>
      <ModalSection>
        <ModalHeader>
          <ModalTitle>결과 입력</ModalTitle>
          <ModalHeaderButtons>
            <ModalHeaderButton className="formButton" onClick={handleSave}>
              저장
            </ModalHeaderButton>
            {/* <ModalHeaderButton
              className="formButton cancel"
              onClick={(e) => {
                e.preventDefault();
                closeModal();
              }}
            >
              취소
            </ModalHeaderButton> */}
            <CloseButton onClick={closeModal}><IoClose /></CloseButton>
          </ModalHeaderButtons>
        </ModalHeader>

        <ModalMain>
            <div className="formBox selectUser w100">
              <TableSection
                content={
                  <table>
                    <tbody>
                      <tr>
                      <th><NavText menuCode={'604'} /> 이름</th>
                        <td>{formData.shipmentInspectionLogCode}</td>
                      </tr>
                      <tr>
                        <th>프로젝트</th>
                        <td>{formData.shipmentInspectionLogName}</td>
                      </tr>
                      <tr>
                        <th>일자</th>
                        <td>{formData.shipmentInspectionLogDate}</td>
                      </tr>
                      <tr>
                        <th>담당자</th>
                        <td>{formData.managerName || '담당자 없음'}</td>
                      </tr>
                      <tr>
                        <th>검토자</th>
                        <td>{formData.checkerName || '검토자 없음'}</td>
                      </tr>
                      <tr>
                        <th>승인자</th>
                        <td>{formData.approvalUserName || '승인자 없음'}</td>
                      </tr>
                    </tbody>
                  </table>
                }
              />
            </div>

          <div className="formBox w100">
            <TableSection
              content={
                <table>
                  <thead>
                    <tr>
                      <th style={{ minWidth: '80px', width: '80px' }}>No.</th>
                      <th>점검 / 검사 항목</th>
                      <th>점검 / 검사 내용</th>
                      <th>판정 기준</th>
                      <th>결과</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.shipmentInspectionItemLogList.map(
                      (item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td name="shipmentInspectionItemTypeName"
                          >
                            {item.shipmentInspectionItemTypeName}
                          </td>
                          <td name="content">
                            {item.content}
                          </td>
                          <td name="criteria">
                            {item.criteria}
                          </td>
                          <td>
                            <textarea
                              name="result"
                              value={item.result}
                              onChange={(e) => handleResultChange(e, index)}
                            ></textarea>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              }
            />
          </div>
        </ModalMain>
      </ModalSection>
    </ModalBg>
  );
};

export default HaccpStandardsModal;
