import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { QRCodeSVG } from 'qrcode.react';
import styled from 'styled-components';

import { procurementApi } from 'api/apis/procurementApi';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: ${(props) => { return props.type === 'list' ? '55px calc(100% - 55px)' : '55px auto 50px'; }};
  height: 85%;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;

    input {
      border: 1px solid var(--gray-200);
      border-radius: 5px;
      box-sizing: border-box;
      color: var(--MainBlue);
      font-size: 20px;
      font-weight: 600;
      height: 40px;
      min-width: 200px;
      padding: 5px 15px;
      width: 98%;
    }
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const UpdateButton = styled.button`
  position: absolute;
  right: 55px;
`;
const DelButton = styled.div`
  height: 15px;
  margin: 0px auto;
  width: 15px;
  div {background-color: var(--MainRed);cursor: pointer;height: 15px;width: 15px;}
`;

const QRCode = styled(QRCodeSVG)`
  cursor: pointer;
  height: 50px;
  width: 50px;
`;

const GSProcurementList = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_contract, setContract] = useState(props.content);
  const [_procurementList, setProcurementList] = useState([]);

  const [_formData, setFormData] = useState({});

  const [_showUpdateList, setShowUpdateList] = useState(false);

  const [_updateProcurementList, setUpdateProcurementList] = useState([]);
  const [_deleteProcurementList, setDeleteProcurementList] = useState([]);

  /* ====================================================================== #3 */
  useEffect(() => {
    getProcurementData();

    setOnload('loaded');
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const getProcurementData = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      contractId: _contract.contractId,
    };
    await procurementApi.searchProcurement('', BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('procurementApi.searchProcurement : ', response);

      if (checkNullArray(response.data.content, false)) {
        Finished_getProcurementData(response.data.content[0]);
      } else {
        alert('연관 발주가 없습니다.')
        props.close();
      }
    })
  };
  const Finished_getProcurementData = (Result) => {
    setFormData(() => {
      return {
        companyId: userReducer.company.companyId,
        accountId: Result?.account?.accountId,
        procurementId: Result.procurementId,
        procurementCode: Result.procurementCode,
        procurementName: Result.procurementName,
        procurementStatus: Result.procurementStatus,
        procurementDate: DateFormat(checkEmptyNull(Result.procurementDate, '')),
        scheduledEndDate: DateFormat(checkEmptyNull(Result.scheduledEndDate, '')),
        procurementPrice: Result.procurementPrice,
        procurementElementList: [],
        deleteProcurementElementList: [],
      }
    });
    setProcurementList(Result.procurementElementList);

    const updateProcurementList = Result.procurementElementList.map((element) => {
      return {
        status: element.status,
        procurementElementId: element.procurementElementId,
        element: { ...element.element },
        elementId: element.element?.elementId,
        price: element.price,
        quantity: element.quantity,
        storeQuantity: element.storeQuantity,
        customizedContent: checkNullParse(element.customizedContent, {num: '', weight: ''}),
      };
    });
    setUpdateProcurementList(updateProcurementList);
  };

  /* ====================================================================== #5 */
  // 바코드 복사
  const copyBarcode = (barcode) => {
    const textArea = document.createElement('textarea');
    document.body.appendChild(textArea);
    textArea.value = barcode;
    textArea.select();
    textArea.setSelectionRange(0, 99999);
    document.execCommand('copy');
    textArea.setSelectionRange(0, 0);
    document.body.removeChild(textArea);
  };

  const actUpdate = async () => {
    const procurementElementList = checkNullArray(_updateProcurementList, []).map((element) => {
      return {
        status: element.status,
        procurementElementId: element.procurementElementId,
        element: { ...element.element },
        elementId: element.element?.elementId,
        customizedContent: JSON.stringify(element.customizedContent),
        price: element.price,
        quantity: element.quantity,
        storeQuantity: element.storeQuantity,
      };
    });
    const BodyToPut = {
      ..._formData,
      procurementElementList: procurementElementList,
      deleteProcurementElementList: _deleteProcurementList,
      isCustom: true, // 경서 > 커스텀 표시
    };
    console.log('BodyToPut : ', BodyToPut);

    await procurementApi.updateProcurement(BodyToPut.procurementId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('procurementApi.updateProcurement : ', response);
      alert('발주를 수정했습니다.');
      props.close();
    })
  };



  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          {_showUpdateList !== true ? (
            <ModalSection type="list">
              <ModalHeader>
                <ModalTitle>발주리스트</ModalTitle>
                <UpdateButton
                  className="formButton"
                  onClick={() => {
                    // reset/
                    setUpdateProcurementList(() => { return [] });
                    setDeleteProcurementList(() => { return [] });

                    const updateProcurementList = _procurementList.map((procurement) => {
                      return {
                        status: procurement.status,
                        procurementElementId: procurement.procurementElementId,
                        element: { ...procurement.element },
                        elementId: procurement.element?.elementId,
                        price: procurement.price,
                        quantity: procurement.quantity,
                        storeQuantity: procurement.storeQuantity,
                        customizedContent: checkNullParse(procurement.customizedContent, {num: '',weight: ''}),
                      };
                    });
                    setUpdateProcurementList(updateProcurementList);
                    setShowUpdateList(true);
                  }}
                >
                  리스트 수정
                </UpdateButton>
                <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
              </ModalHeader>

              <ModalMain>
                <TableSection content={
                  <table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '60px', width: '60px' }}>순번</th>
                        <th>재질</th>
                        <th>규격</th>
                        <th>단위</th>
                        <th>길이</th>
                        <th>수량</th>
                        <th>단중</th>
                        <th>중량</th>
                        <th>비고</th>
                        <th style={{ minWidth: '100px', width: '100px' }}>바코드</th>
                      </tr>
                    </thead>

                    <tbody>
                      {checkNullArray(_procurementList, []).map((procurement, index) => {
                        const customizedContent = checkNullParse(procurement.customizedContent, {num: '0',weight: '0'})
                        const number = (index + 1).toString().padStart(2, '0');
                        const weight = customizedContent.weight;

                        let element = null;
                        let length = null;
                        let weightByUnit = null;
                        let remark = null;
                        let barcode = null;

                        if (checkNullObject(procurement.element, false)) {
                          checkNullArray(procurement.element.elementAttributeValueList, []).forEach((attr) => {
                            switch (attr.elementAttribute.elementAttributeName) {
                              case '길이': length = attr.value; break;
                              case '단중': weightByUnit = attr.value; break;
                              case '비고': remark = attr.value; break;
                              default: return;
                            }
                          });
                          barcode = `Procurement-${_formData.procurementId}-${procurement.procurementElementId}-${procurement.element.elementId}`;
                        }

                        return (
                          <tr key={index + '_procurement'}>
                            <td style={{ minWidth: '60px', width: '60px' }} data-col="순번">{number}</td>
                            <td data-col="재질">{checkEmptyNull(procurement.element?.elementName, '')}</td>
                            <td data-col="규격">{checkEmptyNull(procurement.element?.elementCode, '')}</td>
                            <td data-col="단위">{checkEmptyNull(procurement.element?.elementUnit, '')}</td>
                            <td data-col="길이">{length}</td>
                            <td data-col="수량">{procurement.quantity}</td>
                            <td data-col="단중">{weightByUnit}</td>
                            <td data-col="중량">{weight}</td>
                            <td data-col="비고">{remark}</td>
                            <td style={{ minWidth: '100px', width: '100px' }} data-col="바코드" data-barcode={barcode} >
                              <QRCode value={barcode} onClick={(e) => { e.preventDefault(); copyBarcode(barcode) }} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                }
                />
              </ModalMain>
            </ModalSection>
          ) : (
            <ModalSection type="update">
              <ModalHeader>
                <ModalTitle>발주리스트 수정</ModalTitle>
                <UpdateButton className="formButton" onClick={(e) => { e.preventDefault(); setShowUpdateList(false); }}>리스트 보기</UpdateButton>
                <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
              </ModalHeader>

              <ModalMain>
                <TableSection content={
                  <table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '60px', width: '60px' }}>순번</th>
                        <th>재질</th>
                        <th>규격</th>
                        <th>단위</th>
                        <th>길이</th>
                        <th>수량</th>
                        <th>단중</th>
                        <th>중량</th>
                        <th>비고</th>
                        <th style={{ minWidth: '50px', width: '50px' }}></th>
                      </tr>
                    </thead>

                    <tbody>
                      {checkNullArray(_updateProcurementList, []).map((procurement, index) => {
                        const customizedContent = procurement.customizedContent;
                        const number = (index + 1).toString().padStart(2, '0');
                        let element = null;
                        let length = null;
                        let weightByUnit = null;
                        let remark = null;
                        let barcode = null;
                        if (checkNullObject(procurement.element, false)) {
                          checkNullArray(procurement.element.elementAttributeValueList, []).forEach((attr) => {
                            switch (attr.elementAttribute.elementAttributeName) {
                              case '길이': length = attr.value; break;
                              case '단중': weightByUnit = attr.value; break;
                              case '비고': remark = attr.value; break;
                              default: return;
                            }
                          });
                          barcode = `Procurement-${_formData.procurementId}-${procurement.procurementElementId}-${procurement.element.elementId}`;
                        }

                        return (
                          <tr key={procurement.procurementId + '_procurement'}>
                            <td style={{ minWidth: '60px', width: '60px' }} data-col="순번">{number}</td>
                            <td data-col="재질">{checkEmptyNull(procurement.element?.elementName, '')}</td>
                            <td data-col="규격">{checkEmptyNull(procurement.element?.elementCode, '')}</td>
                            <td data-col="단위">{checkEmptyNull(procurement.element?.elementUnit, '')}</td>
                            <td data-col="길이">{length}</td>
                            <td data-col="수량">
                              <input
                                key={procurement.procurementId + '_quantity'}
                                type="number"
                                name="quantity"
                                data-procurementelementid={procurement.procurementElementId}
                                value={procurement.quantity}
                                onInput={(e) => {
                                  e.preventDefault();
                                  const quantity = Number(e.target.value);
                                  if (isNaN(quantity)) return;
                                  setUpdateProcurementList(prev => {
                                    const returnData = [...prev];
                                    const newData = { ...procurement, quantity: quantity }
                                    returnData.splice(index, 1, newData);
                                    return returnData;
                                  })
                                }}
                              />
                            </td>
                            <td data-col="단중">{weightByUnit}</td>
                            <td data-col="중량">
                              <input
                                key={procurement.procurementId + '_weight'}
                                type="text"
                                name="weight"
                                data-procurementelementid={procurement.procurementElementId}
                                value={customizedContent.weight}
                                onInput={(e) => {
                                  e.preventDefault();
                                  const weight = e.target.value;
                                  setUpdateProcurementList(prev => {
                                    const returnData = [...prev];
                                    const newData = { ...procurement, customizedContent: { ...customizedContent, weight: weight } }
                                    returnData.splice(index, 1, newData);
                                    return returnData;
                                  })
                                }}
                              />
                            </td>
                            <td data-col="비고">{remark}</td>
                            <td style={{ minWidth: '50px', width: '50px' }}>
                              <DelButton
                                onClick={(e) => {
                                  e.preventDefault();
                                  setUpdateProcurementList(prev => {
                                    const returnData = [...prev];
                                    returnData.splice(index, 1);
                                    return returnData;
                                  });
                                  setDeleteProcurementList((prev) => {
                                    const reutrnData = [...prev, procurement.procurementElementId];
                                    return reutrnData;
                                  });
                                }}
                              >
                                <CloseButtonIcon />
                              </DelButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                }
                />
              </ModalMain>

              <ModalFooter><ModalButton onClick={actUpdate}>저장</ModalButton></ModalFooter>
            </ModalSection>
          )}
        </ModalBg>
      )}
    </>
  );
};

export default GSProcurementList;
