import styled from 'styled-components';

export const InquiryStyle = styled.div`
  /* 페이지 - 문의 리스트 */
  .inquiryList {
    display: flex; flex-direction: column;
    section {
      background: #fff;
      font-size: 1.25rem;
      overflow: auto;
      position: relative;
      /* min-height: 90svh; */
      .inquiry-title {
        padding: 8px 16px; border-bottom: 1px solid #ccc;
        display: flex; justify-content: space-between; align-items: center;
        text-align: center;
        border-top: 1px solid #ccc;
        font-weight: bold;
        position: sticky; top: 0; left: 0; background: #fff;
        box-shadow: 0 2px 6px 0 rgba(196, 196, 196, 0.8);
        .inquiry-detail {
          width: 120px;
          padding: 16px;
        }
      }
      ul {
        li {
          padding: 8px 16px; border-bottom: 1px solid #ccc;
          display: flex; justify-content: space-between; align-items: center;
          text-align: center;
        }
      }
      .inquiry-num { width: 80px; }
      .inquiry-status { width: 120px; }
      .inquiry-content { width: 50%; }
      .inquiry-date { width: 20%; }
      .inquiry-btn {
        width: 120px;
        display: flex; justify-content: center; align-items: center;
        padding: 16px;  border-radius: 8px;
        background: var(--color-2); color: var(--white);
      }
    }
    /* 문의하기 버튼 */
    .inquiry-form-btn { background: var(--MainBlue); border-radius: 8px; color: var(--white); display: flex; gap: 8px; padding: 4px 16px; position: relative;}
    /* 모달 */
    .inquiry-form {
      width: 100%; height: 94%; z-index: 99;
      display: flex; flex-direction: column; align-items: center; justify-content: center;
      position: absolute; top: 60px; left: 0;
      background: #eee;
      .inquiry-form-title {
        display: flex; justify-content: space-between;
        /* 닫기 버튼 */
        button { white-space: nowrap; border-radius: 8px; padding: 4px 16px; background: var(--color-2); color: var(--white);}
      }
      textarea { width: 1000px; height: 200px; border-radius: 8px; border: 1px solid #ccc; margin: 40px 0; resize: none; padding: 16px; }
      .submit { width: 100%; padding: 24px; border-radius: 8px; background: var(--color-2); color: var(--white); }
    }
    /* 타이틀 */
    h3 {margin: 40px 0;font-size:1.5rem; width:1200px;}
    /* 검색 */
    .qna-search {display: flex;  gap: 8px; justify-content: space-between; }

  }

  /* 페이지 - 문의 상세정보 */
  .inquiryInfoArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    .inquiry-buttons {
      display: flex; gap: 16px;
      a, button {
        white-space: nowrap;
        border-radius: 8px;
        padding: 4px 16px;
        background: var(--color-2);
        color: var(--white);
      }
    }
    section {
      width: 1000px;
      padding: 40px;
      .info {
        margin: 40px 0;
        display: grid; gap: 10px;
        li {
          display: flex; gap: 24px;
          padding: 40px;
          /* border: 1px solid #ccc; */
          background: #fff;
          border-radius: 8px;
          p {width: 100px; white-space: nowrap;}
          span, textarea {
            width: 100%;
          }
          textarea {
            border: 1px solid #ddd;
            padding: 10px;
            resize: none;
          }
          button {
            white-space: nowrap;
            background: #ddd;
            border-radius: 8px;
            padding: 8px;
            width: fit-content;
            height: fit-content;
          }
        }
      }
      .reply {
        margin: 40px 0;
        display: grid; gap: 10px;
        // 댓글 등록하기
        div {
          margin-top: 40px;
          display: flex; gap: 16px;
          width: 100%;
          justify-content: space-between;
          textarea {
            width: 100%;
            border: 1px solid #ddd;
            border-radius: 8px;
            padding: 10px;
            resize: none;
          }
          button {
            white-space: nowrap;
            background: #ddd;
            border-radius: 8px;
            padding: 8px;
            width: 100px;
          }
        }
        // 댓글 리스트
        li {
          display: flex; gap: 24px;
          justify-content: space-between;
          align-items: center;
          p {
            /* white-space: nowrap; */
            text-align: left;
            width: 100%;
            &:nth-of-type(1) {
              width: 200px;
            }
            &:nth-last-of-type(1) {
              width: 300px;
            }
          }
          button {
            white-space: nowrap;
            background: #ddd;
            border-radius: 8px;
            padding: 8px;
            height: fit-content;
          }
        }
      }
    }
    /* .inquiry-info {
      .inquiry-reply {
        div {
          width: 100%;
        }
        textarea {
          height: 40px;
          padding: 8px;
          resize: none;
          width: 100%;
          border: 1px solid #ddd;
          border-radius: 4px;
        }
        button {
          width: 120px;
          background: #eee;
          border-radius: 8px;
        }
      }
    } */
  }
`;
