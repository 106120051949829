import { AxiosInterceptor } from "api/core/consts";

export const fileCategoryApi = {
  getHighestLevel: (companyId) => AxiosInterceptor.get('/fileCategory/highestLevel/' + companyId),
  getFileCategory: (fileCategoryId) => AxiosInterceptor.get('/fileCategory/' + fileCategoryId),
  searchFileCategory: (body) => AxiosInterceptor.post('/fileCategory/search', body),
  createFileCategory: (body) => AxiosInterceptor.post('/fileCategory', body),
  updateFileCategory: (fileCategoryId, body) => AxiosInterceptor.put('/fileCategory/' + fileCategoryId, body),
  deleteFileCategory: (fileCategoryId) => AxiosInterceptor.delete('/fileCategory/' + fileCategoryId),

  getCompanyFileSize: (companyId) => AxiosInterceptor.get('/companyFileSize/' + companyId),
};
