import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { workOrderApi } from 'api/apis/workOrderApi';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

const DeleteWorkOrder = () => {
  const navigate = useNavigate();
  const { contentsReducer, pageReducer, userReducer } = useSelector((state) => state);

  const [_deleteContents, setDeleteContents] = useState(contentsReducer.deleteContents);
  console.log('_deleteContents : ', _deleteContents);

  let delCount = 0;
  const actAllDelete = () => {
    contentsReducer.deleteContents.forEach(async (thisItem, index) => {
      await actDelete(thisItem.workOrderId, index, thisItem);
    });
  };

  const actDelete = async (workOrderId, index, workOrder) => {
    await workOrderApi.deleteWorkOrder(workOrderId).then((response) => {
      if (response === undefined) return;
      setDeleteContents((prev) => {
        const prevData = [...prev];
        prevData.splice(index, 1);
        return prevData;
      });
    }).finally(() => {
      delCount++;
      if (delCount === contentsReducer.deleteContents.length) {
        alert(
          `
          ${userReducer.userMenu.find((thisItem) => {
            if (thisItem.pageCode === '111') return true;
            else return false;
          })?.pageName}를 삭제했습니다.
            `,
        );
        setTimeout(navigate(-1), 1000);
      }
    });
  };

  /* =================================================== #6들 =================================================== */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <>
        <main className="Main">
          <NavBar title={<NavTitle menuCode={'111'} />} nav={''} />

          <DeleteForm
            delteTitle={<NavTitle menuCode={'111'} />}
            deleteItem={
              <>
                {_deleteContents.map((thisItem) => {
                  return (
                    <Fragment key={thisItem.workOrderId}>
                      <p><b>작업코드:</b> {thisItem.workOrderCode}</p>
                      <p><b>작업이름:</b> {thisItem.workOrderName}</p>
                      <br />
                    </Fragment>
                  );
                })}
              </>
            }
            deleteText={<>해당 <span className="cautionText"><NavTitle menuCode={'111'} /></span>가 삭제됩니다.</>}
            deleteButton={
              <>
                <button className="formButton cancle"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(pageReducer.currentPage, { replace: true });
                  }}
                >
                  취소
                </button>
                <button
                  className="formButton delete"
                  onClick={() => {
                    const confirmText = `
                    ${userReducer.userMenu.find((thisItem) => {
                      if (thisItem.pageCode === '111') return true;
                      else return false;
                    }).pageName}를 삭제합니다.
                      `;
                    if (window.confirm(confirmText)) actAllDelete();
                  }}
                >
                  삭제
                </button>
              </>
            }
          />
        </main>
      </>
    }
    />
  );
};

export default DeleteWorkOrder;
