import styled from 'styled-components';

export const UserManagementComponent = styled.section`
  grid-template-rows: 66px calc(100% - 66px);

  & .TableSection {
    height: 100%;

    & thead {
      z-index: 2;
    }
    & td {
      &.act-cell { // 작업자 설정
        position: relative;

        & button { // 설정 버튼
          min-width: unset;
          width: fit-content;
        }

        & .act-content { // 팀 변경/수정/삭제
          background-color: var(--Bg);
          border-radius: 8px;
          box-shadow: 0 2px 6px 0 rgba(196, 196, 196, 0.8);
          box-sizing: border-box;
          height: fit-content;
          overflow: hidden;
          position: absolute;
          top: calc(50% + 25px);
          right: calc(50% - 15px);
          width: fit-content;
          z-index: 1;

          & div {
            align-items: center;
            cursor: pointer;
            display: flex;
            font-size: 0.8em;
            height: 50px;
            justify-content: center;
            width: 120px;

            &:hover {opacity: 0.6;}
            &:nth-of-type(2) { // 수정
              border-block: 1px solid #eee;
            }
            &:nth-of-type(3) { // 삭제
              color: var(--MainRed);
            }
          }
        }
      }

      & span { // 작업자 마크
        background-color: var(--Bg);
        border-radius: 8px;
        box-shadow: 0 2px 6px 0 rgba(196, 196, 196, 0.8);
        box-sizing: border-box;
        color: var(--color-2);
        display: inline-block;
        font-size: 0.8em;
        padding-inline: 5px;
        margin-inline-end: 10px;
      }
    }
  }
`;