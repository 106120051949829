import React from 'react';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

import TableSection from 'components/layouts/table/TableSection';

const UploadFormSection = styled.div`
  display: grid;
  grid-template-rows: 60px calc(100% - 60px);
  height: 100%;
  width: 100%;

  & label {
    align-items: center;
    background-color: var(--MainNavy);
    border-radius: 10px;
    box-sizing: border-box;
    color: var(--white);
    cursor: pointer;
    font-weight: 600;
    display: flex;
    height: 50px;
    justify-content: center;
    margin: auto;
    width: 99%;
  }

  & .TableSection {
    height: 100%;
    width: 100%;
    zoom: 65%;
  }
`;

const UploadForm = () => {
  const excelExport = (e) => {
    excelExportCommon(e, excelSetting);
  };
  const excelExportCommon = (e, callback) => {
    document.getElementById('uploadFileTable').innerHTML = '';

    const fileList = e.target.files;
    const file = fileList[0];
    if (file === undefined) return;

    const reader = new FileReader();
    reader.onload = async () => {
      const fileData = reader.result;
      const wb = XLSX.read(fileData, { type: 'binary' });
      const sheetNameList = wb.SheetNames;
      for (let i = 0; i < sheetNameList.length; i++) {
        const sheetName = sheetNameList[i];
        const sheet = wb.Sheets[sheetName];
        await callback(sheet);
      }
    };

    reader.readAsBinaryString(file);
  };
  const excelSetting = (sheet) => {
    const result = XLSX.utils.sheet_to_html(sheet);
    document.getElementById('uploadFileTable').innerHTML = document.getElementById('uploadFileTable').innerHTML + result;
  };

  return (
    <UploadFormSection>
      <input
        id='uploadFileInput'
        type="file"
        accept=".xls,.xlsx"
        style={{ display: 'none' }}
        onChange={excelExport}
      />
      <label htmlFor="uploadFileInput">작업지시 업로드</label>
      <TableSection content={<table id="uploadFileTable"></table>} />
    </UploadFormSection>
  );
};

export default UploadForm;