import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { element } from 'api/apis/mall/element';

import DeleteForm from 'components/layouts/form/DeleteForm';
import MallBody from 'components/layouts/body/MallBody';
import MallManagementHeader from 'components/header/MallManagementHeader';
import NavBar from 'components/nav/NavBar';

const DeleteMallElement = () => {
  /* ========================================================================= #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();

  const deleteContents = JSON.parse(searchParams.get('deleteContents'));
  const [_deleteContents, setDeleteContents] = useState(deleteContents);

  /* ====================================================================== #3 */

  /* ========================================================================= #4 */

  /* ====================================================================== #5 */
  let delCount = 0;
  const actAllDelete = () => {
    deleteContents.forEach(async (thisItem) => {
      await actDelete(thisItem.mallElementId, thisItem);
    });
  };

  const actDelete = async (mallElementId, mallElement) => {
    await element.deleteMallElement(mallElementId)
      .then((response) => {
        if (response === undefined) return;
        console.log('element.deleteMallElement : ', response);

        const contents = [..._deleteContents];
        const thisFindIndex = contents.findIndex(
          (thisIndex) => thisIndex.mallElementId === mallElementId,
        );
        setDeleteContents(() => {
          contents.splice(thisFindIndex, 1);
          return contents;
        });
      })
      .finally(() => {
        delCount++;
        if (delCount === deleteContents.length) {
          alert('판매 물품을 삭제했습니다.');

          setTimeout(
            navigate(pageReducer.currentPage, { replace: true }),
            1000,
          );
        }
      });
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <MallBody
      contents={
        <>
          <main className="Main">
            <NavBar title={'판매 물품'} nav={''} />

            <DeleteForm
              delteTitle={'판매 물품'}
              deleteItem={_deleteContents.map((thisItem) => (
                <Fragment key={thisItem.mallElementId}>
                  <p>
                    <b>판매 물품이름:</b> {thisItem.mallElementName}
                  </p>
                  <p>
                    <b>판매 물품코드:</b> {thisItem.mallElementCode}
                  </p>
                  <p>
                    <b>물품이름:</b> {thisItem.elementName}
                  </p>
                  <p>
                    <b>물품코드:</b> {thisItem.elementCode}
                  </p>
                  <br />
                </Fragment>
              ))}
              deleteText={
                <>
                  판매 물품정보와 관련된{' '}
                  <span className="cautionText">모든 정보</span>가 삭제됩니다.
                </>
              }
              deleteButton={
                <>
                  <button
                    className="formButton cancle"
                    onClick={() => {
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                  <button
                    className="formButton delete"
                    onClick={() => {
                      if (window.confirm('판매 물품정보를 삭제합니다.'))
                        actAllDelete();
                      else return;
                    }}
                  >
                    삭제
                  </button>
                </>
              }
            />
          </main>
        </>
      }
    />
  );
};

export default DeleteMallElement;
