const initialState = {
  workOrderType: 'all',
  workOrderStatus: 'all',
  startDate: '',
  endDate: '',
  searchData: {},
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
}
const resetState = {
  workOrderType: 'all',
  workOrderStatus: 'all',
  startDate: '',
  endDate: '',
  searchData: {},
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const reProductionOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETREPRODUCTIONORDERWORKORDERTYPE':
      return {
        ...state,
        workOrderType: action.payload,
      };
    case 'SETREPRODUCTIONORDERWORKORDERSTATUS':
      return {
        ...state,
        workOrderStatus: action.payload,
      };
    case 'SETREPRODUCTIONORDERSTARTDATE':
      return {
        ...state,
        startDate: action.payload,
      };
    case 'SETREPRODUCTIONORDERENDDATE':
      return {
        ...state,
        endDate: action.payload,
      };
    case 'SETREPRODUCTIONORDERSEARCHDATA':
      return {
        ...state,
        searchData: action.payload,
      };
    case 'SETREPRODUCTIONORDERSEARCHOPTION':
      return {
        ...state,
        searchOption: action.payload,
      };
    case 'SETREPRODUCTIONORDERSEARCHTEXT':
      return {
        ...state,
        searchText: action.payload,
      };
    case 'SETREPRODUCTIONORDERPAGENUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'SETREPRODUCTIONORDERTOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };
    case 'SETREPRODUCTIONORDERISPUSHEDSEARCHBUTTON':
      return {
        ...state,
        isPushedSearchButton: action.payload,
      };
    case 'SETREPRODUCTIONORDERRESET':
      return {
        ...state,
        ...resetState,
      };
    default:
      return state;
  };
};

export default reProductionOrderReducer;