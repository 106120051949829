import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { contractActions_setContractType, contractActions_setContractStatus, contractActions_setSearchOption, contractActions_setSearchText, contractActions_setPageNumber, contractActions_setTotalSize, contractActions_setReset, contractActions_setContractAccount, contractActions_setContractElementList, contractActions_setViewScale } from 'store/modules/actions/common/contractActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { contractTypeApi } from 'api/apis/contractTypeApi';
import { contractAttributeApi } from 'api/apis/contractAttributeApi';
import { contractApi } from 'api/apis/contractApi';
import { procurementApi } from 'api/apis/procurementApi';
import { shipmentApi } from 'api/apis/shipmentApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { LoadingMsg } from 'components/loading/LoadingMsg';

import ContractRow from 'components/row/ContractRow';
import ContractUploadModal from 'components/excel/upload/ContractUploadModal';
import ExcelDownloadModal from 'components/excel/ExcelDownloadModal';
import Grid3Body from 'components/layouts/body/Grid3Body';
import Footer from 'components/footer/Footer';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';
import ViewMinusButton from 'components/buttons/ViewMinusButton';
import ViewPlusButton from 'components/buttons/ViewPlusButton';
import ViewResetButton from 'components/buttons/ViewResetButton';
import OrderDetailModal from 'components/modal/orderDetail/OrderDetailModal';

const Section = styled.main`
  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
    & .statusButtons {
      display: flex;
      gap: 5px;
    }
    & .contractTypeButtons {
      display: flex;
      gap: 5px;
    }
  }

  & .TableSection {
    & thead th {
      padding: 5px;
    }
    tr[name='contractRows']:hover {cursor: pointer;}
  }
`;

const StockTable = styled.table`
  thead tr {border: none;}
  tbody {
    tr {border-bottom: 1px solid var(--MainBlue);}
    td {border: 1px solid var(--gray-200);}
  } 
`;

const ViewerButtons = styled.div`
  align-items: center;
  bottom: 12px;
  column-gap: 2px;
  display: flex;
  justify-content: center;
  position: fixed;
  right: 76px;
  z-index: 999;
`;

const Contract = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { contractReducer, pageReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_contractList, setContractList] = useState([]); //수주리스트

  const [_contractTypes, setContractTypes] = useState([]); //수주유형
  const [_contractAttrList, setContractAttrList] = useState([]); //수주항목

  const [_procurementList, setProcurementList] = useState([]); // 발주리스트
  const [_shipmentList, setShipmentList] = useState([]); // 출하리스트

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_eventContract, setEventContract] = useState({});

  const [_orderType, setOrderType] = useState('contract'); //수주
  const [_orderDetailModalStatus, setOrderDetailModalStatus] = useState(false);

  const [_excelUploadModalStatus, setExcelUploadModalStatus] = useState(false);
  const [_excelDownloadModalStatus, setExcelDownloadModalStatus] = useState(false);

  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('201') === true,
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    (async () => {
      await getContractTypes();
      await getContractAttrs(contractReducer.contractType);
      await getContractList(contractReducer.pageNumber - 1, contractReducer.contractType, contractReducer.contractStatus, contractReducer.isPushedSearchButton);

      // await getProcurementList(); // 발주리스트 가져오기
      // await getShipmentList(); // 출하리스트 가져오기
    })();

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getContractList(contractReducer.pageNumber - 1, contractReducer.contractType, contractReducer.contractStatus, contractReducer.isPushedSearchButton);
    }

    return () => { };
  }, [contractReducer.pageNumber]);

  useEffect(() => { }, [contractReducer]);
  useEffect(() => { }, [_contractAttrList]);

  /* ========================================================================= #4 */
  const getContractTypes = async () => { // 수주 유형
    const BodyToPost = { companyId: userReducer.company.companyId };
    await contractTypeApi.searchContractType(BodyToPost).then((response) => {
      if (response === undefined) return;
      setContractTypes(() => { return response.data });
    });
  };

  const getContractAttrs = async (contractType) => { // 수주 항목
    const BodyToPost = { companyId: userReducer.company.companyId };
    if (checkEmptyNull(contractType, false) && contractType !== 'all') {
      BodyToPost.contractTypeId = Number(contractType);
    }
    await contractAttributeApi.searchContractAttribute(BodyToPost).then((response) => {
      if (response === undefined) return;
      setContractAttrList(() => { return response.data });
    });
  };

  const getContractList = async (page, contractType, contractStatus, isPushedSearchButton) => { // 수주 리스트 불러오기
    const paging = `?page=${page}&size=10&sort=id,DESC`;

    const BodyToPost = { companyId: userReducer.company.companyId };
    if (checkEmptyNull(contractType, false) && contractType !== 'all') {
      BodyToPost.contractTypeId = Number(contractType);
    }
    if (checkEmptyNull(contractStatus, false) && contractStatus !== 'all') {
      BodyToPost.contractStatus = contractStatus;
    }
    if (checkEmptyNull(contractReducer.startDate, false) && checkEmptyNull(contractReducer.endDate, false)) {
      const startDate = new Date(contractReducer.startDate);
      const setStartDate = startDate.toISOString();
      BodyToPost.contractStartDate = setStartDate;

      const endDate = new Date(contractReducer.endDate);
      endDate.setDate(endDate.getDate() + 1);
      const setEndDate = endDate.toISOString();
      BodyToPost.contractEndDate = setEndDate;

      if (contractReducer.startDate > contractReducer.endDate) {
        const reverseStartDate = new Date(contractReducer.startDate);
        reverseStartDate.setDate(reverseStartDate.getDate() + 1);
        const setReverseStartDate = reverseStartDate.toISOString();

        const reverseEndDate = new Date(contractReducer.endDate);
        const setReversetEndDate = reverseEndDate.toISOString();

        BodyToPost.contractStartDate = setReversetEndDate;
        BodyToPost.contractEndDate = setReverseStartDate;
      }
    }
    if (!isPushedSearchButton && checkEmptyNull(contractReducer.searchOption, false) && checkEmptyNull(contractReducer.searchText, false)) {
      BodyToPost[contractReducer.searchOption] = contractReducer.searchText;
    }

    await contractApi.searchContract(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      setContractList(() => { return response.data.content });
      dispatch(contractActions_setTotalSize(response.data.totalElements));
    });
  };

  const getProcurementList = async () => { // 발주 리스트 불러오기
    const paging = `?page=0&size=10&sort=id,DESC`; // 예시로 페이지네이션 설정
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    await procurementApi.searchProcurement(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('procurementApi.searchProcurement : ', response);
      setProcurementList(response.data.content); // 데이터의 content 부분을 설정
    });
  };


  const getShipmentList = async () => { // 출하 리스트 불러오기
    const paging = `?page=0&size=10&sort=id,DESC`; // 예시로 페이지네이션 설정
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    await shipmentApi.searchShipment(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('shipmentApi.searchShipment : ', response);
      setShipmentList(response.data.content); // 데이터의 content 부분을 설정
    });
  };

  /* ========================================================================= #5 */
  const handleContractSearchStatus = async (status) => {
    dispatch(contractActions_setContractStatus(status));
    dispatch(contractActions_setPageNumber(1));
    await getContractList(0, contractReducer.contractType, status);
  }

  const handleCheckedItem = (contract, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(contract);
    setCheckedItem(() => { return checkedItem });
  };

  const actSearch = async () => { // 검색
    dispatch(contractActions_setPageNumber(1));
    await getContractList(0, contractReducer.contractType, contractReducer.contractStatus);
  };

  const actExcelUpload = () => { // 엑셀 업로드
    setExcelUploadModalStatus(true);
  };
  const actExcelDownload = () => { // 엑셀 다운로드
    setExcelDownloadModalStatus(true);
  };


  const handleContractStatus = async (contract, index) => { // 수주 상태
    const contractIndex = index;

    // 수주상태
    let eventStatusText = '';
    let eventStatus = '';
    switch (contract.contractStatus) {
      case 'waiting':
        eventStatusText = '진행';
        eventStatus = 'proceeding';
        break;

      case 'proceeding':
        eventStatusText = '완료';
        eventStatus = 'end';
        break;

      default: return;
    }

    // body
    const BodyToPut = {
      ...contract,

      companyId: userReducer.company.companyId,
      userId: userReducer.user.id,
      contractStatus: eventStatus,

      deleteContractAttributeValueList: [],
      deleteContractElementList: [],
    };

    // delete
    delete BodyToPut['createdDate'];
    delete BodyToPut['modifiedDate'];
    delete BodyToPut['account'];
    delete BodyToPut['company'];

    // confirm
    const confirmText = `수주를 ${eventStatusText}시키겠습니까?`;
    if (window.confirm(confirmText) === true) {
      await contractApi.updateContract(contract.contractId, BodyToPut).then((response) => {
        if (response === undefined) return;
        console.log('contractApi.updateContract : ', response);

        setContractList((prev) => {
          const returnData = [...prev];
          returnData.splice(contractIndex, 1, response.data);
          return returnData;
        });
      })
    } else return;
  };

  /* 상세보기 */
  const goDetail = (contract, index) => {
    setEventContract(() => { return contract });
    setTimeout(setOrderDetailModalStatus(true), 1000);
  };

  const actCopy = () => { // 수주 복사
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return alert('복사할 수주를 선택해주세요.');
    const checkedContent = checkedList[0];
    console.log('checkedContents : ', checkedContent);

    // 초기화
    dispatch(contractActions_setContractAccount({}));
    dispatch(contractActions_setContractElementList([]));

    // 복사 페이지 이동
    navigate({
      pathname: pageReducer.currentPage + '/copy',
      search: `?${createSearchParams({ copyContent: JSON.stringify(checkedContent) })}`,
      replace: true,
    });
  };
  const actUpdate = () => { // 수주 수정
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return alert('수정할 수주를 선택해주세요.');
    const checkedContent = checkedList[0];
    console.log('checkedContents : ', checkedContent);

    // 초기화
    dispatch(contractActions_setContractAccount({}));
    dispatch(contractActions_setContractElementList([]));

    // 수정 페이지 이동
    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({ updateContent: JSON.stringify(checkedContent) })}`,
      replace: true,
    });
  };
  const actDelete = () => { // 수주 삭제
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return alert('삭제할 수주를 선택해주세요.');
    const checkedContent = checkedList[0];
    console.log('checkedContents : ', checkedContent);

    // 삭제 페이지 이동
    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({ deleteContent: JSON.stringify(checkedContent) })}`,
      replace: true,
    });
  };

  /* ========================================================================= #6 */

  return (
    <Grid3Body contents={
      <>
        <Section className="Main">
          <NavBar
            title={
              <>
                <div className='Title'>
                  <NavTitle menuCode={'201'} />
                  <div className='statusButtons'>
                    {[
                      { status: 'all', name: '전체' },
                      { status: 'waiting', name: '대기' },
                      { status: 'proceeding', name: '진행' },
                      { status: 'end', name: '완료' },
                    ].map((contractStatus, index) => {
                      return (
                        <button key={index + '_statusButton'}
                          className={contractReducer.contractStatus === contractStatus.status ? 'btn-inven-1' : 'btn-set'}
                          onClick={(e) => {
                            e.preventDefault();
                            handleContractSearchStatus(contractStatus.status);
                          }}
                        >
                          {contractStatus.name}
                        </button>
                      )
                    })}
                  </div>
                </div>
              </>
            }
            buttons={
              <>
                {_authority.indexOf('201-2') !== -1 ? (
                  <>
                    <button
                      className='btn-add'
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(contractActions_setContractAccount({}));
                        dispatch(contractActions_setContractElementList([]));
                        setTimeout(navigate(pageReducer.currentPage + '/create', { replace: true }), 1000);
                      }}
                    >
                      추가
                    </button>
                    <button className='btn-copy' onClick={actCopy}>복사</button>
                    <button className='btn-edit' onClick={actUpdate}>수정</button>
                    <button className='btn-delete' onClick={actDelete}>삭제</button>
                    <button className='btn-upload' onClick={actExcelUpload}>엑셀 업로드</button>
                  </>
                ) : null}
                <button className='btn-download' onClick={actExcelDownload}>다운로드</button>
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <select
                    className="SelectType"
                    value={contractReducer.contractType}
                    onChange={(e) => {
                      dispatch(contractActions_setContractType(e.target.value));
                      dispatch(contractActions_setPageNumber(1));
                      getContractAttrs(e.target.value);
                      getContractList(0, e.target.value, contractReducer.contractStatus, contractReducer.isPushedSearchButton);
                    }}
                  >
                    <option value="all">전체</option>
                    {_contractTypes.map((contractType) => {
                      return (
                        <option key={contractType.contractTypeId + '_contractType'} value={contractType.contractTypeId}>
                          {contractType.contractTypeName}
                        </option>
                      );
                    })}
                  </select>

                  <div className="ContentCBox">
                    <div className="ContentCBox">
                      <select
                        className="SearchOption"
                        value={contractReducer.searchOption}
                        onChange={(e) => { dispatch(contractActions_setSearchOption(e.target.value)) }}
                      >
                        {[
                          { optionValue: '', optionText: '검색옵션' },
                          { optionValue: 'contractCode', optionText: '수주코드' },
                          { optionValue: 'contractName', optionText: '수주이름' },
                          { optionValue: 'accountName', optionText: '거래처명' },
                        ].map((thisItem) => {
                          return (
                            <option key={thisItem.optionValue + '_contractSearchOption'} value={thisItem.optionValue}>
                              {thisItem.optionText}
                            </option>
                          );
                        })}
                      </select>
                      <input
                        className="SearchBar"
                        placeholder="Search..."
                        value={contractReducer.searchText}
                        onInput={(e) => { dispatch(contractActions_setSearchText(e.target.value)) }}
                      />
                    </div>

                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton"
                      onClick={() => {
                        dispatch(contractActions_setReset());
                        getContractList(0, 'all', 'all', true);
                      }}
                    >
                      초기화
                    </div>
                  </div>
                </div>
              </>
            }
          />

          <TableSection
            // sectionStyle={{ height: 'calc(100% - 66px)' }}
            content={
              <StockTable style={{ zoom: `${contractReducer.viewScale}%` }}>
                <thead>
                  <tr>
                    {_authority.indexOf('201-2') !== -1 ? (<th rowSpan={2} style={{ minWidth: '50px', width: '50px' }}></th>) : null}
                    <th rowSpan={2} style={{ minWidth: '80px', width: '80px' }}>수주<br />상태</th>
                    <th rowSpan={2} style={{ minWidth: '50px', width: '50px' }}>상세보기</th>
                    <th rowSpan={2}>수주유형</th>
                    <th rowSpan={2}>수주코드</th>
                    <th rowSpan={2}>수주이름</th>
                    <th rowSpan={2}>거래처</th>
                    <th rowSpan={2}>수주주소</th>
                    <th rowSpan={2}>수주일</th>
                    <th rowSpan={2}>납기일</th>
                    <th colSpan={3} style={{ backgroundColor: 'var(--ThirdBlue)' }}>수주물품</th>
                    <th rowSpan={2}>수주총금액</th>
                  </tr>
                  <tr>
                    <th>물품정보</th>
                    <th>수량</th>
                    <th>금액</th>
                  </tr>
                </thead>

                <tbody>
                  {!checkNullArray(_contractList, false) ? LoadingMsg() :
                    checkNullArray(_contractList, []).map((contract, index) => {
                      return (
                        <ContractRow
                          key={index + '_contracts'}
                          authority={_authority}
                          index={index}
                          content={contract}
                          checkedItem={_checkedItem}
                          handleCheckedItem={handleCheckedItem}
                          handleContractStatus={handleContractStatus}
                          goDetail={goDetail}
                        />
                      );
                    })}
                </tbody>
              </StockTable>
            }
          ></TableSection>
        </Section>

        <PagingComponent
          page={contractReducer.pageNumber}
          count={contractReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(contractActions_setPageNumber(page));
          }}
        />

        <ViewerButtons>
          <ViewMinusButton
            viewScale={contractReducer.viewScale}
            handleViewScale={(scale) => { dispatch(contractActions_setViewScale(scale)) }}
          />
          <ViewPlusButton
            viewScale={contractReducer.viewScale}
            handleViewScale={(scale) => { dispatch(contractActions_setViewScale(scale)) }}
          />
          <ViewResetButton
            handleViewScale={() => { dispatch(contractActions_setViewScale(100)) }}
          />
        </ViewerButtons>

        {_excelUploadModalStatus === true && (
          <ContractUploadModal
            open={_excelUploadModalStatus}
            close={() => {
              setExcelUploadModalStatus(false);
              getContractList(contractReducer.pageNumber - 1, contractReducer.contractType, contractReducer.contractStatus, contractReducer.isPushedSearchButton);
            }}
          />
        )}
        {_excelDownloadModalStatus === true && (
          <ExcelDownloadModal
            mainTitle={'contract'}
            subTitle={'contract'}
            open={_excelDownloadModalStatus}
            close={() => { setExcelDownloadModalStatus(false) }}
          />
        )}
        {_orderDetailModalStatus === true && (
          <OrderDetailModal
            event={_eventContract}
            open={_orderDetailModalStatus}
            orderType={_orderType}
            contractList={_contractList.filter(item => item.contractId === _eventContract.contractId)}
            procurementList={_procurementList}
            shipmentList={_shipmentList}
            close={() => { setOrderDetailModalStatus(false) }}
          />
        )}
        <Footer></Footer>
      </>
    }
    />
  );
};

export default Contract;
