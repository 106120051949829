import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { accountApi } from 'api/apis/accountApi';
import { shipmentApi } from 'api/apis/shipmentApi';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import CommaNum from 'components/format/CommaNum';
import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import GuideText from 'components/buttons/GuideText';
import NavBar from 'components/nav/NavBar';
import SelectElements from 'pages/element/modal/SelectElements';
import SelectOneAccount from 'pages/account/modal/SelectOneAccount';
import TableSection from 'components/layouts/table/TableSection';

const ShipmentSection = styled.main``;

const TableFormbox = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  width: 100%;

  & > .TableSection {
    max-height: 350px;
    overflow: auto;

    &::-webkit-scrollbar-thumb {background-color: var(--ThirdBlue);}

    & > table td {
      vertical-align: middle;
      textarea {display: block; height: 40px; resize: none;}
    }
  }
`;
const DelButton = styled.div`
  height: 15px;
  margin: 0px auto;
  width: 15px;

  div {background-color: var(--MainRed); cursor: pointer; height: 15px; width: 15px;}
`;

const AccountTable = styled.table`
  background-color: var(--white);
  border-collapse: collapse;
  font-size: 0.8em;
  table-layout: fixed;
  width: 100%;

  td, th {border: 1px solid var(--gray-200); box-sizing: border-box; padding: 10px;}
  th {
    background-color: var(--MainNavy);
    color: var(--white);

    &.account {
      background-color: var(--MainBlue);
      cursor: pointer;
      &:hover {background-color: var(--SeconBlue);}
    }
  }
  th:first-child {min-width: 150px; width: 150px;}
`;


const CustomUpdateShipment = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();
  const shipment = JSON.parse(searchParams.get('updateContent'));
  // console.log('shipment : ', shipment);
  const {
    shipmentId,
    shipmentStatus,
    shipmentCode,
    shipmentName,
    shipmentDate,

    shipmentPrice,
    stockFlag,

    accountId,
    accountCode,
    accountName,

    selfAccountCode,
    selfAccountName,
    selfBusinessNumber,
    selfAddress,
    selfTelNumber,
    selfManagerName,

    shipmentAccountCode,
    shipmentAccountName,
    shipmentBusinessNumber,
    shipmentAddress,
    shipmentTelNumber,
    shipmentManagerName,

    remark,
    customizedContent,

    shipmentElementList,
  } = shipment;

  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId, // 회사

    shipmentId: shipmentId,
    accountId: accountId,

    shipmentStatus: checkEmptyNull(shipmentStatus, 'wait'),
    shipmentCode: checkEmptyNull(shipmentCode, ''),
    shipmentName: checkEmptyNull(shipmentName, ''),

    shipmentDate: checkEmptyNull(shipmentDate, false) ? DateFormat(shipmentDate) : '',

    remark: checkEmptyNull(remark, ''),

    customizedContent: checkNullParse(customizedContent, {
      delivery: {
        driver: '',
        telNumber: '',
        licensePlateNumber: '',
      }
    }),

    shipmentElementList: [],
    deleteShipmentElementList: [],
  });

  const [_company, setCompany] = useState({
    selfAccountCode: selfAccountCode,
    selfAccountName: selfAccountName,

    selfBusinessNumber: selfBusinessNumber,
    selfAddress: selfAddress,
    selfTelNumber: selfTelNumber,

    selfManagerName: selfManagerName,
  });

  const [_account, setAccount] = useState({
    accountId: accountId,
    shipmentAccountCode: shipmentAccountCode,
    shipmentAccountName: shipmentAccountName,

    shipmentBusinessNumber: shipmentBusinessNumber,
    shipmentAddress: shipmentAddress,
    shipmentTelNumber: shipmentTelNumber,

    shipmentManagerName: shipmentManagerName,
  });

  const [_deliveryData, setDeliveryData] = useState(() => {
    const deliveryData = checkNullParse(customizedContent, {
      delivery: {
        driver: '',
        telNumber: '',
        licensePlateNumber: '',
      }
    });
    return deliveryData.delivery;
  })

  const [_shipmentPrice, setShipmentPrice] = useState(checkEmptyNull(shipmentPrice, 0));

  const [_shipmentElements, setShipmentElements] = useState(() => {
    if (checkNullArray(shipmentElementList, false)) {
      const returnData = shipmentElementList.map((element) => {
        if (checkNullObject(element.element, false)) {
          const elementData = {
            ...element,

            elementId: element.element.elementId,
            elementName: checkEmptyNull(element.element.elementName),
            elementCode: checkEmptyNull(element.element.elementCode),
            scheduledInputStock: element.element.scheduledInputStock,
            elementSafeStock: element.element.elementSafeStock,
            elementStock: element.element.elementStock,

            price: checkEmptyNull(element.element.price, 0), // 단가
            calPrice: element.price, // 금액

            customizedContent: checkNullParse(element.customizedContent, {
              elementStandard: '',
              elementDetailStandard: '',
            })
          };
          return elementData;
        } else {
          const elementData = {
            ...element,

            customizedContent: checkNullParse(element.customizedContent, {
              elementStandard: '',
              elementDetailStandard: '',
            })
          }
          return elementData;
        }
      });

      return returnData;
    } else {
      return [];
    }
  });

  const [_modalStatus, setModalStatus] = useState(false);
  const [_accountModalStatus, setAccountModalStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('205') === true, // shipment
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    setOnload('loaded');
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value } });
  };
  const handleCompanyData = (e) => {
    const { name, value } = e.target;
    setCompany((prev) => { return { ...prev, [name]: value } });
  };
  const handleAccountData = (e) => {
    const { name, value } = e.target;
    setAccount((prev) => { return { ...prev, [name]: value } });
  };
  const handleDeliveryData = (e) => {
    const { name, value } = e.target;
    setDeliveryData((prev) => { return { ...prev, [name]: value } });
  };

  const handleShipmentPrice = (e) => {
    e.preventDefault();

    let eventValue = e.target.value.replace(/[^\d.-]/g, '');
    if (eventValue.length > 15) return;
    if (!checkEmptyNull(eventValue, false)) eventValue = 0;
    if (isNaN(eventValue) || eventValue === '0') eventValue = 0;
    eventValue = BigNumber(eventValue).toNumber();
    console.log('eventValue : ', eventValue);

    setShipmentPrice(() => { return eventValue });
  };

  const handleElementCustomData = (e, index, element) => {
    const { name, value } = e.target;

    const newData = { ...element, customizedContent: { ...element.customizedContent, [name]: value } };

    setShipmentElements(prev => {
      const returnData = [...prev];
      returnData.splice(index, 1, newData);
      return returnData;
    })
  }
  const handleElementInput = (e, index, element) => {
    const { name, value } = e.target;
    const numberType = ['price', 'quantity', 'calPrice'];
    let eventValue = value;

    if (numberType.includes(name)) {
      eventValue = e.target.value.replace(/[^\d.-]/g, '');
      if (eventValue.length > 15) return;
      if (!checkEmptyNull(eventValue, false)) eventValue = 0;
      if (isNaN(eventValue) || eventValue === '0') eventValue = 0;
      eventValue = BigNumber(eventValue).toNumber();
      console.log('eventValue : ', eventValue);
    }

    let newData = {};
    switch (name) {
      case 'price':
        newData = {
          ...element,
          price: BigNumber(eventValue).toNumber(),
          calPrice: handleCalPrice(eventValue, element.quantity),
        };
        break;

      case 'quantity':
        newData = {
          ...element,
          quantity: BigNumber(eventValue).toNumber(),
          calPrice: handleCalPrice(element.price, eventValue),
        };
        break;

      case 'calPrice':
        newData = { ...element, calPrice: BigNumber(eventValue).toNumber() };
        break;

      default: newData = { ...element, [name]: value }; break;
    }

    setShipmentElements(prev => {
      const returnData = [...prev];
      returnData.splice(index, 1, newData);
      if (numberType.includes(name)) calShipmentPrice(returnData);
      return returnData;
    })
  };
  const handleCalPrice = (price, quantity) => {
    const calPrice = BigNumber(price).multipliedBy(BigNumber(quantity)).toNumber();
    if (isNaN(calPrice)) return 0;
    else return calPrice;
  };
  const calShipmentPrice = (shipmentElementList) => {
    const sumArray = shipmentElementList.map((item) => item.calPrice);
    const setSumArray = sumArray.filter((item) => item !== undefined && item !== null && !isNaN(item));
    const sum = setSumArray.reduce((a, b) => BigNumber(a).plus(BigNumber(b)).toNumber(), 0);
    console.log('setSumArray : ', setSumArray);
    setShipmentPrice(() => { return sum });
  };

  const deleteElement = (index, element) => {
    setShipmentElements((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);

      const sumArray = prevData.map((item) => item.calPrice);
      const setSumArray = sumArray.filter((item) => item !== null);
      const sum = setSumArray.reduce((a, b) => BigNumber(a).plus(BigNumber(b)).toNumber(), 0);
      setShipmentPrice(() => { return BigNumber(sum).toNumber() });

      return prevData;
    });
  };

  const actUpdate = async (e) => {
    e.preventDefault();

    if (!checkEmptyNull(_formData.shipmentCode, false)) return alert('출하코드를 입력해주세요.');

    const originElementList = shipmentElementList;
    const findElement = (shipmentElemntId) => {
      const index = _shipmentElements.findIndex((shipmentElement) => shipmentElement.shipmentElemntId === shipmentElemntId);
      return index;
    };
    const deleteShipmentElementList = originElementList.filter((element) => findElement(element.shipmentElemntId) === -1);

    const BodyToPut = {
      ..._formData,

      ..._account,
      ..._company,

      shipmentPrice: _shipmentPrice,
      stockFlag: false,

      customizedContent: JSON.stringify({ delivery: _deliveryData }),

      shipmentElementList: _shipmentElements.map((element) => {
        const returnData = {
          accountId: _account.accountId,
          elementId: element.elementId,
          quantity: checkEmptyNull(element.quantity, 0),
          price: checkEmptyNull(element.calPrice, 0),
          remark: checkEmptyNull(element.remark, ''),

          amount: 0,
          note: '',

          customizedContent: JSON.stringify(element.customizedContent),
        };
        if (checkEmptyNull(element.shipmentElementId, false)) {
          returnData['shipmentElementId'] = element.shipmentElementId;
        }

        return returnData;
      }),
    };
    if (deleteShipmentElementList.length > 0) {
      BodyToPut.deleteShipmentElementList = deleteShipmentElementList;
    }
    console.log('BodyToPut : ', BodyToPut, JSON.stringify(BodyToPut));

    // return;
    await shipmentApi.updateShipment(shipmentId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('shipmentApi.updateShipment : ', response);

      alert('출하를 수정했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  /* ====================================================================== #6 */

  return (
    <Grid2Body
      contents={
        <>
          <ShipmentSection className="Main">
            <NavBar title={'출하관리'} nav={''} />

            <Form
              title={
                <>
                  출하<br />수정
                </>
              }
              buttons={
                <>
                  <button className="formButton" onClick={actUpdate}>저장</button>
                  <button className="formButton cancle"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                </>
              }
              forms={
                <>
                  <div className="formBox w100">
                    <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                      <h4>출하물품</h4>
                      <div className="formButton"
                        style={{ backgroundColor: 'var(--MainBlue)', borderRadius: '5px', width: '150px' }}
                        onClick={() => { setModalStatus(true) }}
                      >
                        출하물품 추가
                      </div>
                    </div>

                    <TableFormbox>
                      <TableSection content={
                        <table>
                          <thead style={{ backgroundColor: 'var(--MainNavy)' }}>
                            <tr>
                              <th>물품이름</th>
                              <th>물품코드</th>
                              <th>현재고</th>
                              <th>규격</th>
                              <th>상세규격</th>
                              <th>수량</th>
                              <th>단가</th>
                              <th>금액</th>
                              <th>메모</th>
                              <th style={{ minWidth: '50px', width: '50px' }}></th>
                            </tr>
                          </thead>
                          <tbody>
                            {checkNullArray(_shipmentElements, []).map((element, index) => {
                              const elementCustomData = checkNullObject(element.customizedContent, {
                                elementStandard: '',
                                elementDetailStandard: '',
                              });

                              return (<tr key={index + '_shipmentElements'} data-key={element.elementId}>
                                <td className={checkEmptyNull(element.elementName, false) && element.elementName.startsWith('*') ? 'outsourcingText' : null}>
                                  {element.elementName}
                                </td>
                                <td>{element.elementCode}</td>
                                <td className={(() => {
                                  if (checkEmptyNull(element.elementSafeStock, false)) {
                                    if (element.elementSafeStock <= element.elementStock) return 'safeStock';
                                    else return 'dangerStock';
                                  } else {
                                    return '';
                                  }
                                })()}
                                >
                                  {(element?.elementStock).toLocaleString() + ' '}
                                  {
                                    // 옵셔널체이닝으로 undefined 반환
                                    // 단위가 undefined 가 나오는 경우가 있음 -> 기본 단위를 '개' 로 설정
                                    element?.elementUnit === undefined ? '개' : element.elementUnit
                                  }
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="elementStandard"
                                    value={checkEmptyNull(elementCustomData.elementStandard, '')}
                                    onInput={(e) => { handleElementCustomData(e, index, element) }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="elementDetailStandard"
                                    value={checkEmptyNull(elementCustomData.elementDetailStandard, '')}
                                    onInput={(e) => { handleElementCustomData(e, index, element) }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="quantity"
                                    min={0}
                                    value={checkEmptyNull(element.quantity, 0).toLocaleString()}
                                    onInput={(e) => { handleElementInput(e, index, element) }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="price"
                                    min={0}
                                    value={checkEmptyNull(element.price, 0).toLocaleString()}
                                    onInput={(e) => { handleElementInput(e, index, element) }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="calPrice"
                                    min={0}
                                    value={checkEmptyNull(element.calPrice, 0).toLocaleString()}
                                    onInput={(e) => { handleElementInput(e, index, element) }}
                                  />
                                </td>
                                <td>
                                  <textarea
                                    type="text"
                                    name="remark"
                                    maxLength={1000}
                                    rows={3}
                                    value={element.remark}
                                    onInput={(e) => { handleElementInput(e, index, element) }}
                                  />
                                </td>
                                <td style={{ minWidth: '50px', width: '50px' }}>
                                  <DelButton onClick={() => { deleteElement(index, element) }}><CloseButtonIcon /></DelButton>
                                </td>
                              </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      }
                        formSectionStyle={{ paddingTop: '10px', width: '100%' }}
                      />
                    </TableFormbox>

                    <div className="cautionText" style={{ fontSize: '14px', textAlign: 'right', width: '100%' }}>※ 출하수량을 꼭! 입력해 주세요.</div>
                  </div>

                  <div className="formBox">
                    <h4>출하코드(일련번호)</h4>
                    <input
                      type="data"
                      name="shipmentCode"
                      placeholder="* 출하코드(일련번호)..."
                      data-required="required"
                      value={_formData.shipmentCode}
                      onInput={handleInputEvent}
                    />
                  </div>

                  <div className="formBox">
                    <h4>출하이름</h4>
                    <input
                      type="text"
                      name="shipmentName"
                      placeholder="* 출하이름..."
                      value={_formData.shipmentName}
                      onInput={handleInputEvent}
                    />
                  </div>

                  <div className="formBox w100">
                    <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                      <h4>회사 / 거래처</h4>
                      <div
                        className="formButton"
                        style={{ backgroundColor: 'var(--MainNavy)', borderRadius: '5px', width: '80px' }}
                        onClick={async () => {
                          setCompany(() => {
                            const returnData = {
                              selfAccountCode: selfAccountCode,
                              selfAccountName: selfAccountName,

                              selfBusinessNumber: selfBusinessNumber,
                              selfAddress: selfAddress,
                              selfTelNumber: selfTelNumber,

                              selfManagerName: selfManagerName,
                            }
                            return returnData;
                          })
                          setAccount(() => {
                            const returnData = {
                              accountId: accountId,
                              shipmentAccountCode: shipmentAccountCode,
                              shipmentAccountName: shipmentAccountName,

                              shipmentBusinessNumber: shipmentBusinessNumber,
                              shipmentAddress: shipmentAddress,
                              shipmentTelNumber: shipmentTelNumber,

                              shipmentManagerName: shipmentManagerName,
                            }
                            return returnData;
                          });
                        }}
                      >
                        초기화
                      </div>
                    </div>
                    <AccountTable>
                      <thead>
                        <tr>
                          <th></th>
                          <th>회사</th>
                          <th className="account"
                            onClick={(e) => {
                              e.preventDefault();
                              setAccountModalStatus(true);
                            }}
                          >
                            거래처 선택
                            <GuideText />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>사업자등록번호</th>
                          <td>{_company.selfAccountCode}</td>
                          <td>{checkEmptyNull(_account.shipmentBusinessNumber, '')}</td>
                        </tr>
                        <tr>
                          <th>회사명</th>
                          <td>{_company.selfAccountName}</td>
                          <td>{checkEmptyNull(_account.shipmentAccountName, '')}</td>
                        </tr>
                        <tr>
                          <th>주소</th>
                          <td>
                            <input
                              type="text"
                              name="selfAddress"
                              value={_company.selfAddress}
                              onInput={handleCompanyData}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="shipmentAddress"
                              value={checkEmptyNull(_account.shipmentAddress, '')}
                              onInput={handleAccountData}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>전화번호</th>
                          <td>
                            <input
                              type="text"
                              name="selfTelNumber"
                              value={_company.selfTelNumber}
                              onInput={handleCompanyData}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="shipmentTelNumber"
                              value={checkEmptyNull(_account.shipmentTelNumber, '')}
                              onInput={handleAccountData}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>담당자</th>
                          <td>
                            <input
                              type="text"
                              name="selfManagerName"
                              value={_company.selfManagerName}
                              onInput={handleCompanyData}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="shipmentManagerName"
                              value={checkEmptyNull(_account.shipmentManagerName, '')}
                              onInput={handleAccountData}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>계좌번호</th>
                          <td>
                            <input
                              type="text"
                              name="selfBusinessNumber"
                              value={_company.selfBusinessNumber}
                              onInput={handleCompanyData}
                            />
                          </td>
                          <td style={{ backgroundColor: 'var(--gray-100)' }}></td>
                        </tr>
                      </tbody>
                    </AccountTable>
                  </div>

                  <div className="formBox">
                    <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                      <h4>운전자</h4>
                      <div
                        className="formButton"
                        style={{ backgroundColor: 'var(--MainNavy)', borderRadius: '5px', width: '80px' }}
                        onClick={() => {
                          setDeliveryData(() => {
                            const returnData = checkNullParse(customizedContent, {
                              delivery: {
                                driver: '',
                                telNumber: '',
                                licensePlateNumber: '',
                              }
                            });
                            return returnData.delivery;
                          })
                        }}
                      >
                        초기화
                      </div>
                    </div>
                    <AccountTable>
                      <thead>
                        <tr>
                          <th></th>
                          <th>운전자</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>이름</th>
                          <td>
                            <input
                              type="text"
                              name="driver"
                              value={_deliveryData?.driver}
                              onInput={handleDeliveryData}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>전화번호</th>
                          <td>
                            <input
                              type="text"
                              name="telNumber"
                              value={_deliveryData?.telNumber}
                              onInput={handleDeliveryData}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>차량번호</th>
                          <td>
                            <input
                              type="text"
                              name="licensePlateNumber"
                              value={_deliveryData?.licensePlateNumber}
                              onInput={handleDeliveryData}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </AccountTable>
                  </div>

                  <div className="formBox">
                    <h4>출하일자(거래일자)</h4>
                    <input
                      type="date"
                      name="shipmentDate"
                      value={_formData.shipmentDate}
                      onChange={handleInputEvent}
                    />
                  </div>

                  <div className="formBox">
                    <h4>출하총금액</h4>
                    <input
                      type="text"
                      name="shipmentPrice"
                      placeholder="* 출하총금액..."
                      value={checkEmptyNull(_shipmentPrice, 0).toLocaleString()}
                      onInput={handleShipmentPrice}
                    />
                  </div>

                  <div className="formBox">
                    <h4>비고</h4>
                    <textarea
                      name="remark"
                      placeholder="* 비고..."
                      value={checkEmptyNull(_formData.remark)}
                      onInput={handleInputEvent}
                    />
                  </div>
                </>
              }
              formSectionStyle={{ paddingTop: '10px', width: 'calc(100% - 210px)' }}
            />
          </ShipmentSection>

          {_accountModalStatus === true && (
            <SelectOneAccount
              buttonTitle={'거래처 선택'}
              buttonEvent={(data) => {
                if (checkNullObject(data, false)) {
                  setAccount((prev) => {
                    const returnData = {
                      ...prev,

                      accountId: data.accountId,

                      shipmentAccountCode: data.accountCode,
                      shipmentAccountName: data.accountName,
                      shipmentBusinessNumber: data.businessNumber,
                      shipmentAddress: data.address,
                      shipmentTelNumber: data.telNumber,
                    };
                    return returnData;
                  });
                }
                setTimeout(setAccountModalStatus(false), 1000);
              }}
              open={_accountModalStatus}
              close={() => { setAccountModalStatus(false) }}
            ></SelectOneAccount>
          )}

          {_modalStatus === true && (
            <SelectElements
              buttonEvent={(data) => {
                console.log('data : ', data);
                const shipmentElementIds = _shipmentElements.map((thisItem) => thisItem.elementId);
                const newElements = data.map((element) => {
                  if (shipmentElementIds.indexOf(element.elementId) !== -1) return null;
                  else {
                    const returnData = { ...element, calPrice: 0 };
                    return returnData;
                  }
                });
                const setNewElements = newElements.filter((thisItem) => thisItem !== null);
                setShipmentElements((prev) => { return [...prev, ...setNewElements] });
                setTimeout(setModalStatus(false), 1000);
              }}
              buttonTitle="물품 선택"
              open={_modalStatus}
              close={() => { setModalStatus(false) }}
            />
          )}
        </>
      }
    />
  );
};

export default CustomUpdateShipment;
