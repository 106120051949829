import React, { useEffect, useState } from 'react';

import { fileDataApi } from 'api/apis/fileDataApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import FileEvent from 'components/file/FileEvent';
import LoadingComponent from 'components/loading/LoadingComponent';

const CreateContent = ({
  close, modalData
}) => {
  const body = {
    companyId: modalData.companyId,
    fileCategoryId: modalData.fileCategoryId,
    fileCategoryName: modalData.name,
    fileDataName: '',
  };
  const [_body, setBody] = useState(body);
  const [_files, setFiles] = useState();

  const [_loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => {}, [_body, _loadingStatus]);

  const handleInputEvent = (e) => { // 파일 정보 입력
    const {name, value} = e.target;
    setBody((prev) => {
      const returnData = {...prev, [name] : value};
      return returnData;
    })
  };
  const fileEvent = (act, e) => { // 파일 이벤트 - 클릭&드래그
    let file = null;
    switch (act) {
      case 'click': console.log(act, e.target.files); file = e.target.files; break;
      case 'drag': console.log(act, e.dataTransfer.files); file = e.dataTransfer.files; break;
      default: return;
    }
    if (file !== null) {
      const setData = Array.prototype.slice.call(file);
      const returnData = [...setData];
      setFiles(() => {return returnData;});
    }
  };
  const actCreate = async() => { // 파일 추가
    setLoadingStatus(() => {return true});
    const formData = new FormData();
    const createBody = {..._body};
    if(!checkEmptyNull(createBody.fileCategoryId, false)) delete createBody.fileCategoryId;
    formData.append('key', new Blob([JSON.stringify(createBody)], {type: 'application/json'}));
    const files = Array.prototype.slice.call(_files);
    const setFiles = [...files];
    if(checkNullArray(setFiles, false)) {
      setFiles.forEach((fileData) => {
        formData.append('multipartFile', fileData);
      })
    }
    await fileDataApi.createFileData(formData).then(response => {
      if(response === undefined) return;
      console.log('actCreate : ', response);
      alert('파일을 추가했습니다.');
      setLoadingStatus(() => {return false});
      close('update');
    }).finally(() => {
      setLoadingStatus(() => {return false});
    })
  };

  return (
    <>
    <section className='create-content'>
      <div>
        {[
          {name: 'fileCategoryName', title: '폴더', disabled: true},
          {name: 'fileDataName', title: '파일이름', disabled: false},
        ].map(((fileData) => {
          return (
            <div key={fileData.name}>
              <h4>{fileData.title}</h4>
              <input
                type='text'
                name={fileData.name}
                disabled={fileData.disabled}
                value={checkEmptyNull(_body[fileData.name], '')}
                onInput={handleInputEvent}
              />
            </div>
          )
        }))}

        <div>
          <FileEvent
            act={'create'}
            fileInputName={'createFileInput'}
            fileDataList={_files || []}
            fileEvent={fileEvent}
          />
        </div>
      </div>

      <button className='btn-save' onClick={actCreate}>저장</button>
    </section>

    {_loadingStatus ? (<LoadingComponent close={close} title={'파일을 업로드하고 있습니다...'} />) : null}
    </>
  );
};

export default CreateContent;