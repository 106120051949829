import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { equipmentAttributeApi } from 'api/apis/equipmentAttributeApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';

import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const Content = styled.div`
  background-color: var(--white);
  box-sizing: border-box;
  border-radius: 8px;
  display: grid;
  grid-template-rows: 60px auto;
  width: 100%; height: 100%;
`;
const ContentHeader = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  padding: 24px 16px;
`;
const Title = styled.h1`
  font-size: 20px;
`;
const SearchBar = styled.div`
  align-items: center;
  border-radius: 10px;
  display: flex; gap: 8px;
  input {
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    padding: 4px 16px;
    border-radius: 8px;
    width: 100%;
    font-size: 1rem;
  }
`;
const ButtonsWrap = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  height: 100%;
  justify-content: flex-end;
`;

const EquipmentAttr = (props) => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_defaultList, setDefaultList] = useState([
    { key: 'equipmentName', value: '설비이름', },
    { key: 'equipmentCode', value: '설비코드', },
  ]);
  const [_attrList, setAttrList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_searchText, setSearchText] = useState();

  /* ====================================================================== #3 */
  useEffect(() => {
    getEquipmentAttrs();

    setOnload('loaded');
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const getEquipmentAttrs = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (checkEmptyNull(_searchText, false)) {
      BodyToPost.equipmentAttributeName = _searchText;
    }

    await equipmentAttributeApi.searchEquipementAttribute(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('equipmentAttributeApi.searchEquipementAttribute : ', response);

      setAttrList(() => { return response.data });
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) {
      checkedItem.add(id);
    }
    setCheckedItem(() => {
      return checkedItem;
    });
  };

  const actSearch = async () => {
    getEquipmentAttrs();
  };

  /* 추가 */
  const actCreate = async () => {
    const promptText = `
    추가할 항목명을 입력해주세요.
    `;
    const CreatePrompt = window.prompt(promptText, '');
    if (CreatePrompt === null) return;

    const BodyToPost = {
      companyId: userReducer.company.companyId,
      equipmentAttributeName: CreatePrompt,
    };
    console.log('actCreate - body : ', BodyToPost);

    await equipmentAttributeApi.createEquipementAttribute(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('equipmentAttributeApi.createEquipementAttribute : ', response);

      setAttrList((prev) => {
        return [...prev, response.data];
      });
      alert('항목을 추가했습니다.');
    });
  };

  /* 수정 */
  const actUpdate = async () => {
    const updateContentIndex = _attrList.findIndex((thisItem) => thisItem.equipmentAttributeId === Array.from(_checkedItem)[0]);
    if (updateContentIndex === -1) return alert('수정할 항목을 선택해 주세요.');
    const updateContent = _attrList[updateContentIndex];
    console.log('updateContent : ', updateContent);

    const promptText = `
    선택한 항목을 수정합니다.
    `;
    const UpdatePrompt = window.prompt(
      promptText,
      updateContent.equipmentAttributeName,
    );
    if (UpdatePrompt === null) return;

    const BodyToPut = {
      companyId: userReducer.company.companyId,
      equipmentAttributeId: updateContent.equipmentAttributeId,
      equipmentAttributeName: UpdatePrompt,
    };
    console.log('actUpdate - body : ', BodyToPut);

    await equipmentAttributeApi.updateEquipementAttribute(updateContent.equipmentAttributeId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('equipmentAttributeApi.updateEquipementAttribute : ', response);

      setAttrList((prev) => {
        const prevData = [...prev];
        prevData.splice(updateContentIndex, 1, response.data);

        return prevData;
      });

      alert('항목을 수정했습니다.');
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const deleteContentIndex = _attrList.findIndex((thisItem) => thisItem.equipmentAttributeId === Array.from(_checkedItem)[0]);
    if (deleteContentIndex === -1) return alert('삭제할 항목을 선택해 주세요.');
    const deleteContent = _attrList[deleteContentIndex];
    console.log('deleteContent : ', deleteContent);

    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({
        content: JSON.stringify(deleteContent),
        typeName: '설비',
        type: 'equipment',
      })}`,
      replace: true,
    });
  };

  /* ====================================================================== #6 */

  return (
    <Content className="equipment">
      <ContentHeader>
        <Title>설비 항목 설정</Title>
        <div className='content-snb'>
          <ButtonsWrap>
            {(() => {
              if (props.authority.indexOf('103-2') !== -1 && props.authority.indexOf('108-2') !== -1) {
                return (
                  <>
                    <button className='btn-add' onClick={actCreate}>추가</button>
                    <button className='btn-edit' onClick={actUpdate}>수정</button>
                    <button className='btn-delete' onClick={actDelete}>삭제</button>
                  </>
                )
              } else {
                return null;
              }
            })()}
          </ButtonsWrap>
          <SearchBar>
            <input
              placeholder="항목명..."
              onInput={(e) => { setSearchText(() => { return e.target.value; }); }}
            />
            <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
          </SearchBar>
        </div>
      </ContentHeader>
      <TableSection content={
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th style={{ minWidth: 'unset', width: '50px' }}></th>
              <th>항목명</th>
            </tr>
          </thead>
          <tbody>
            {_defaultList.map((attr, index) => {
              return <tr key={index + '_default'}>
                <td style={{ backgroundColor: 'var(--MainNavy)', minWidth: 'unset', width: '50px' }}></td>
                <td>{attr.value}</td>
              </tr>
            })}
            {_attrList.map((attr, index) => {
              return (
                <tr key={index + '_attr'}>
                  <td style={{ minWidth: 'unset', width: '50px' }}>
                    <input
                      type="checkBox"
                      name="equipmentAttrs"
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        handleCheckedItem(attr.equipmentAttributeId, isChecked);

                        if (isChecked) {
                          document.getElementsByName('equipmentAttrs').forEach((thisAttr) => { thisAttr.checked = false });
                          e.target.checked = true;
                        } else {
                          e.target.checked = false;
                        }
                      }}
                    />
                  </td>
                  <td>{attr.equipmentAttributeName}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      }
      ></TableSection>
    </Content>
  );
};

export default EquipmentAttr;
