import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { coreElementApi } from 'api/apis/coreElementApi';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

const DeleteCoreElements = () => {
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  const [searchParams, setSearchParams] = useSearchParams();

  const coreElementId = searchParams.get('coreElementId');
  const elementCode = searchParams.get('elementCode');
  const elementName = searchParams.get('elementName');

  const actDelete = async () => {
    await coreElementApi.deleteCoreElement(coreElementId).then((response) => {
      if (response === undefined) return;
      console.log('coreElementApi.deleteCoreElement : ', response);

      const alertText = `
      ${userReducer.userMenu.find((thisItem) => {
        if (thisItem.pageCode === '904') return true;
        else return false;
      }).pageName} 을 삭제했습니다.
      `;
      alert(alertText);
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <main className="Main">
        <NavBar
          title={<NavTitle menuCode={'904'} />}
          nav={''}
        />

        <DeleteForm
          delteTitle={<NavTitle menuCode={'904'} />}
          deleteItem={
            <>
              {'물품이름 : ' + elementName || ''}<br />
              {'물품코드 : ' + elementCode || ''}<br />
            </>
          }
          deleteText={<><NavTitle menuCode={'904'} />을 삭제합니다.<br /></>}
          deleteButton={
            <>
              <button className="formButton cancle" onClick={() => { navigate(pageReducer.currentPage, { replace: true }); }}>
                취소
              </button>
              <button className="formButton delete"
                onClick={() => {
                  const confirmText = `
                  ${userReducer.userMenu.find((thisItem) => {
                    if (thisItem.pageCode === '904') return true;
                    else return false;
                  }).pageName} 을 삭제합니다.
                  `;
                  if (window.confirm(confirmText)) actDelete();
                  else return;
                }}
              >
                삭제
              </button>
            </>
          }
        />
      </main>
    }
    />
  );
};

export default DeleteCoreElements;
