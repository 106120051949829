export const procurementActions_setProcurementType = (procurementType) => {
  return {
    type: 'SETPROCUREMENTTYPE',
    payload: procurementType,
  };
};

export const procurementActions_setProcurementStatus = (procurementStatus) => {
  return {
    type: 'SETPROCUREMENTSTATUS',
    payload: procurementStatus,
  };
};

export const procurementActions_setStartDate = (startDate) => {
  return {
    type: 'SETPROCUREMENTSTARTDATE',
    payload: startDate,
  };
};

export const procurementActions_setEndDate = (endDate) => {
  return {
    type: 'SETPROCUREMENTENDDATE',
    payload: endDate,
  };
};

export const procurementActions_setSearchOption = (searchOption) => {
  return {
    type: 'SETPROCUREMENTSEARCHOPTION',
    payload: searchOption,
  };
};

export const procurementActions_setSearchText = (searchText) => {
  return {
    type: 'SETPROCUREMENTSEARCHTEXT',
    payload: searchText,
  };
};

export const procurementActions_setPageNumber = (pageNumber) => {
  return {
    type: 'SETPROCUREMENTPAGENUMBER',
    payload: pageNumber,
  };
};

export const procurementActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETPROCUREMENTTOTALSIZE',
    payload: totalSize,
  };
};

export const procurementActions_setIsPushedSearchButton = (
  isPushedSearchButton,
) => {
  return {
    type: 'SETPROCUREMENTISPUSHEDSEARCHBUTTON',
    payload: isPushedSearchButton,
  };
};

export const procurementActions_setReset = () => {
  return {
    type: 'SETPROCUREMENTRESET',
  };
};

export const procurementActions_setProcurementAccount = (account) => {
  return {
    type: 'SETPROCUREMENTACCOUNT',
    payload: account,
  };
};

export const procurementActions_setProcurementElementList = (elementList) => {
  return {
    type: 'SETPROCUREMENTELEMENTLIST',
    payload: elementList,
  };
};

export const procurementActions_setViewScale = (viewScale) => {
  return {
    type: 'SETPROCUREMENTVIEWSCALE',
    payload: viewScale,
  };
};
