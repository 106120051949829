import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { attachFileApi } from 'api/apis/attachFileApi';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';
import ViewMinusButton from 'components/buttons/ViewMinusButton';
import ViewPlusButton from 'components/buttons/ViewPlusButton';
import ViewResetButton from 'components/buttons/ViewResetButton';

import UpdateUriProcessLogModal from 'pages/productionOrder/custom/company/uri/crud/modal/UpdateUriProcessLogModal';
import UriWorkOrderPdfModal from 'pages/working/custom/company/uri/process/download/UriWorkOrderPdfModal';
import UriWorkOrderPdfModalV2 from 'pages/working/custom/company/uri/process/download/UriWorkOrderPdfModalV2';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto;
  height: 90%;
  overflow: hidden;
  position: relative;
  width: 98%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const ModalHeaderButton = styled.div`
  column-gap: 10px;
  display: flex;
  position: absolute;
  right: 60px;
`;
const PrintButton = styled.button``;
const UpdateButton = styled.button``;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  label {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 60px;
    justify-content: flex-end;
    padding: 0px 20px;
    width: 100%;
  }

  .TableSection {
    height: 100%;
    margin: 0px auto;
    overflow-x: auto;
    width: 100%;

    table {
      width: 100%;

      tbody tr {border-bottom: 8px solid var(--ThirdBlue);}
      td, th {font-size: 1em; max-width: 200px; min-width: 120px;}
      td {border: 1px solid var(--gray-200); box-sizing: border-box; height: 250px; line-height: 1em;}
      td.materialStatus, td.colorStatus {
      }
    }
  }
`;

const DrawingCell = styled.div`
  display: grid;
  grid-template-rows: 25% 25% 50%;
  height: 100%;
  width: 200px;

  div {
    align-items: center;
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  div[data-pos='Top'] {
    border: unset;
    display: grid;
    grid-template-columns: 50% 50%;
    height: 100%;

    p {
      align-items: center;
      border: 1px solid var(--gray-200);
      box-sizing: border-box;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;
    }
  }

  div[data-pos='Middle'] {padding: 20px;}
`;
const Drawing = styled.div`
  align-items: center;
  background-color: var(--white);
  border: unset;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  img {max-height: 98%; max-width: 180px;}
`;

const AmountCell = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100%;
  width: 100%;
`;
const TaskCell = styled.div`
  border: 1px solid var(--gray-200);
  box-sizing: border-box;

  &[name='taskCells'] {
    display: grid;
    grid-template-rows: 1fr 40px;
  }
  div[name='tasks'] {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding: 10px;
  }
  div[name='glassType_W'], div[name='glassType_H'] {
    background-color: var(--MainNavy);
    border: none;
    box-sizing: border-box;
    color: var(--white);
    font-size: 18px;
    height: 100%;
    padding: 12px;
    width: 100%;
  }
`;

const ViewerButtons = styled.div`
  align-items: center;
  column-gap: 4px;
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;
  z-index: 999;

  &>div {
    background-color: var(--MainNavy); 
    border: 2px solid var(--white); 
    border-radius: 50%; 
    box-sizing: border-box; 
    overflow: hidden;
  }
  div div {background-color: var(--white);}
`;

const UriDetailWorkOrder = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  let materials_count = 0;
  let colors_count = 0;

  const taskTypeArray = ['BF', 'CP', 'SF', 'MF', 'GB', 'MC'];

  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_workOrderLog, setWorkOrderLog] = useState(props.workOrderContent);
  const [_processLogList, setProcessLogList] = useState(() => {
    const processLogList = props.workOrderContent.processLogList;
    processLogList.sort((a, b) => { return a.processLogId - b.processLogId });
    return processLogList;
  });

  const [_modalStatus, setModalStatus] = useState(false);
  const [_pdfPreviewModalStatus, setPdfPreviewModalStatus] = useState(false);
  const [_pdfPreviewModalV2Status, setPdfPreviewModalV2Status] = useState(false);

  const [_viewScale, setViewScale] = useState(100);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('403') === true,
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => { /* console.log('_processLogList : ', _processLogList); */ }, [_processLogList])

  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const showUpdateModal = () => {
    setTimeout(setModalStatus(true), 1000);
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>
                {_workOrderLog.accountName + ' | ' + (_workOrderLog.workPlace !== null && _workOrderLog.workPlace)}
              </ModalTitle>
              <ModalHeaderButton>

                {/* <PrintButton className="formButton" onClick={() => { setPdfPreviewModalStatus(true) }}>출력 v1</PrintButton> */}
                <PrintButton className="formButton" onClick={() => { setPdfPreviewModalV2Status(true) }}>출력</PrintButton>
                {(_authority.indexOf('403-2') !== -1 && _workOrderLog.workOrderStatus !== 'end' && _workOrderLog.workOrderStatus !== 'cancel')
                  ? <UpdateButton className="formButton" onClick={showUpdateModal}>작업수정</UpdateButton>
                  : null}

                <ViewerButtons>
                  <ViewMinusButton
                    viewScale={_viewScale}
                    handleViewScale={(scale) => { setViewScale(() => { return scale }) }}
                  />
                  <ViewPlusButton
                    viewScale={_viewScale}
                    handleViewScale={(scale) => { setViewScale(() => { return scale }) }}
                  />
                  <ViewResetButton
                    handleViewScale={() => { setViewScale(() => { return 100 }) }}
                  />
                </ViewerButtons>
              </ModalHeaderButton>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <TableSection content={
                <table style={{ zoom: `${_viewScale}%` }}>
                  <thead>
                    <tr>
                      <th style={{ minWidth: '60px', padding: 'unset', width: '60px' }}></th>
                      <th style={{ backgroundColor: 'var(--MainNavy)', color: 'var(--white)', minWidth: '60px', width: '60px' }}>NO</th>
                      <th></th>
                      <th>자재</th>
                      <th style={{ backgroundColor: 'var(--MainNavy)', color: 'var(--white)' }}>색상</th>
                      <th>B/F</th>
                      <th>C/P</th>
                      <th>S/F</th>
                      <th>M/F</th>
                      <th>G/B</th>
                      <th>유리종류</th>
                      <th>M/C</th>
                      <th>비고</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkNullArray(_processLogList, []).map((processLog, index) => {
                      const processCustomizedContent = checkNullParse(processLog.processCustomizedContent, {
                        numStatus: '',
                        bundle: '',
                        bundleSize: '',
                        materialStatus: '',
                        materials: '',
                        colorStatus: '',
                        colors: '',
                        glassType_W: '',
                        glassType_H: '',
                        remark: '',
                      })

                      return (
                        <tr key={index + '_processLogList'}>
                          <StatusTdButton
                            statusStyle={{ cursor: 'default', minHeight: '200px', minWidth: '80px', width: '80px' }}
                            content={processLog}
                            contentId={processLog.processLogId}
                            status={processLog.processStatus}
                          />
                          <td className={`numStatus ${processCustomizedContent.numStatus}`}
                            style={{ minWidth: '60px', width: '60px' }}
                            data-key={index}
                          >
                            {(index + 1).toString().padStart(2, '0')}
                          </td>
                          <td style={{ minWidth: '200px', padding: 'unset', width: '200px' }}>
                            <DrawingCell>
                              <div data-pos="Top">
                                <p>{processCustomizedContent.bundle}</p>
                                <p>{processLog.processingCount}</p>
                              </div>

                              <div data-pos="Middle">{processCustomizedContent.bundleSize}</div>

                              <div data-pos="Bottom">
                                <Drawing>
                                  {checkNullArray(processLog?.attachFileList, false) && checkNullArray(processLog?.attachFileList, false).length > 0 ? (
                                    <img src={processLog?.attachFileList[0]?.fileData?.fileDataS3URL} alt={(index + 1).toString().padStart(2, '0') + '파일'} />
                                  ) : checkNullArray(processLog?.drawingList, false) && checkNullArray(processLog?.drawingList, false).length > 0 ?
                                    <img src={processLog?.drawingList[0]?.fileData?.fileDataS3URL} alt={(index + 1).toString().padStart(2, '0') + '파일'} />
                                    : null}
                                </Drawing>
                              </div>
                            </DrawingCell>
                          </td>

                          <td className={`materialStatus ${processCustomizedContent.materialStatus}`}>
                            {checkEmptyNull(processCustomizedContent.materials, false) ?
                              processCustomizedContent.materials.split(processCustomizedContent.materials.indexOf('\n') === -1 ? ' ' : '\n')
                                .map((thisMaterial) => {
                                  materials_count++;
                                  return (<p key={index + '_' + materials_count + thisMaterial} style={{ padding: '4px', textAlign: 'left' }}>{thisMaterial}</p>);
                                })
                              : null}
                          </td>

                          <td className={`colorStatus ${processCustomizedContent.colorStatus}`}>
                            {checkEmptyNull(processCustomizedContent.colors, false) ?
                              processCustomizedContent.colors.split(processCustomizedContent.colors.indexOf('\n') === -1 ? ' ' : '\n')
                                .map((thisColor) => {
                                  colors_count++;
                                  return (<p key={index + '_' + colors_count + thisColor} style={{ padding: '4px', textAlign: 'left' }}>{thisColor}</p>);
                                })
                              : null}
                          </td>

                          {taskTypeArray.map((thisType) => {
                            let thisWContent = [];
                            let thisHContent = [];
                            if (processLog.taskLogList.length > 0) {
                              thisWContent = processLog.taskLogList.filter((thisItem) => thisItem.taskType === thisType + '_W');
                              thisHContent = processLog.taskLogList.filter((thisItem) => thisItem.taskType === thisType + '_H');
                            }

                            let glassCount_W = 0;
                            let glassCount_H = 0;
                            let remarkCount = 0;

                            return (<Fragment key={index + thisType + 'smallProcesses'}>
                              <td style={{ padding: 'unset' }}>
                                <AmountCell>
                                  <TaskCell name="taskCells">
                                    <div>
                                      {checkNullArray(processLog.taskLogList, false) ?
                                        thisWContent.map((thisAmountW) => {
                                          return (<div key={index + thisType + '_W' + thisAmountW.taskLogId} name="tasks">{thisAmountW.taskName}</div>);
                                        })
                                        : null}
                                    </div>
                                  </TaskCell>

                                  <TaskCell name="taskCells">
                                    <div>
                                      {checkNullArray(processLog.taskLogList, false) ?
                                        thisHContent.map((thisAmountH) => {
                                          return (<div key={index + thisType + '_H' + thisAmountH.taskLogId} name="tasks">{thisAmountH.taskName}</div>);
                                        })
                                        : null}
                                    </div>
                                  </TaskCell>
                                </AmountCell>
                              </td>

                              {thisType === 'GB' && (
                                <td style={{ padding: 'unset' }}>
                                  <AmountCell>
                                    <TaskCell>
                                      <div name="glassType_W">
                                        {checkEmptyNull(processCustomizedContent.glassType_W, false)
                                          ? processCustomizedContent.glassType_W.split(processCustomizedContent.glassType_W.indexOf('\n') === -1 ? ' ' : '\n')
                                            .map((thisItem) => {
                                              glassCount_W++;
                                              return (<p key={index + 'glassType_W' + glassCount_W} style={{ paddingBlock: '8px' }}>{thisItem}</p>);
                                            })
                                          : null}
                                      </div>
                                    </TaskCell>

                                    <TaskCell>
                                      <div name="glassType_H">
                                        {checkEmptyNull(processCustomizedContent.glassType_H, false)
                                          ? processCustomizedContent.glassType_H.split(processCustomizedContent.glassType_H.indexOf('\n') === -1 ? ' ' : '\n')
                                            .map((thisItem) => {
                                              glassCount_H++;
                                              return (<p key={index + 'glassType_H' + glassCount_H} style={{ paddingBlock: '8px' }}>{thisItem}</p>);
                                            })
                                          : null}
                                      </div>
                                    </TaskCell>
                                  </AmountCell>
                                </td>
                              )}

                              {thisType === 'MC' && (
                                <td>
                                  {checkEmptyNull(processCustomizedContent.remark, false)
                                    ? processCustomizedContent.remark.split(processCustomizedContent.remark.indexOf('\n') === -1 ? ' ' : '\n')
                                      .map((thisItem) => {
                                        remarkCount++;
                                        return (<p key={index + 'remark' + remarkCount} style={{ padding: '10px' }}>{thisItem}</p>);
                                      })
                                    : null}
                                </td>
                              )}
                            </Fragment>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
              />
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}

      {/* 모달 */}
      {/* 출력 */}
      {_pdfPreviewModalStatus === true ? (
        <UriWorkOrderPdfModal
          content={_workOrderLog}
          open={_pdfPreviewModalStatus}
          close={() => { setPdfPreviewModalStatus(false) }}
        ></UriWorkOrderPdfModal>
      ) : null}
      {_pdfPreviewModalV2Status === true ? (
        <UriWorkOrderPdfModalV2
          content={_workOrderLog}
          open={_pdfPreviewModalV2Status}
          close={() => { setPdfPreviewModalV2Status(false) }}
        ></UriWorkOrderPdfModalV2>
      ) : null}

      {/* 수정  */}
      {_modalStatus === true ? (
        <UpdateUriProcessLogModal
          updateContent={_workOrderLog}
          updateProcessLog={(putProcessLogList, responseProcessLogList) => {
            const processLogList = checkNullArray(responseProcessLogList, []);
            setProcessLogList(() => { return processLogList; });
            setWorkOrderLog((prev) => { return { ...prev, processLogList: processLogList } })

            let updateProcessLogList = checkNullArray(responseProcessLogList, []);
            processLogList.forEach(async (processLog, index) => {
              const putProcessLogIndex = putProcessLogList.findIndex(item => item.processLogId === processLog.processLogId);
              const putProcessLog = putProcessLogList[putProcessLogIndex];
              if (putProcessLogIndex !== -1) {
                checkNullArray(putProcessLog.deleteAttachFile, []).forEach(async (attachFileId) => {
                  await attachFileApi.deleteAttachFile(attachFileId).then((response) => {
                    if (response === undefined) return;
                    console.log('attachFileApi.deleteAttachFile : ', response);
                  });
                })
                if (!checkNullObject(putProcessLog?.fileData, false)) return;
                const BodyToAttach = {
                  companyId: userReducer.company.companyId,
                  fileDataId: putProcessLog?.fileData.fileDataId,
                  processLogId: processLog.processLogId,
                }
                await attachFileApi.createAttachFile(BodyToAttach).then(fileResponse => {
                  if (fileResponse === undefined) return;
                  console.log('attachFileApi.createAttachFile : ', fileResponse);
                  updateProcessLogList = updateProcessLogList.map((resultProcessLog) => {
                    const returnData = { ...resultProcessLog, fileData: {} };
                    if (resultProcessLog.processLogId === processLog.processLogId) returnData.attachFileList = [fileResponse.data];
                    return returnData;
                  })
                  setProcessLogList(() => { return updateProcessLogList });
                  setWorkOrderLog((prev) => { return { ...prev, processLogList: updateProcessLogList } })
                })
              } else {
                const postProcessLog = putProcessLogList[index];
                if (!checkNullObject(postProcessLog?.fileData, false)) return;
                const BodyToAttach = {
                  companyId: userReducer.company.companyId,
                  fileDataId: postProcessLog?.fileData.fileDataId,
                  processLogId: processLog.processLogId,
                }
                await attachFileApi.createAttachFile(BodyToAttach).then(fileResponse => {
                  if (fileResponse === undefined) return;
                  console.log('attachFileApi.createAttachFile : ', fileResponse);
                  updateProcessLogList = updateProcessLogList.map((resultProcessLog) => {
                    const returnData = { ...resultProcessLog, fileData: {} };
                    if (resultProcessLog.processLogId === processLog.processLogId) returnData.attachFileList = [fileResponse.data];
                    return returnData;
                  })
                  setProcessLogList(() => { return updateProcessLogList });
                  setWorkOrderLog((prev) => { return { ...prev, processLogList: updateProcessLogList } })
                })
              }
            })

            setTimeout(setModalStatus(false), 1000);
          }}
          open={_modalStatus}
          close={() => { setModalStatus(false); }}
        />
      ) : null}
    </>
  );
};

export default UriDetailWorkOrder;
