import React from 'react';
import { Container, Title, Table, Tbody, Td, Th, Thead, Tr } from 'pages/contract/modal/Tab.style';

export default function ShipmentItemsTab({ data }) {
  const formatPrice = (price) =>
    price != null ? new Intl.NumberFormat().format(price) : '-';

  const { shipmentElementList = [] } = data;

  return (
    <Container className="contract">
      <Title>출하 물품정보</Title>
      {shipmentElementList.length > 0 ? (
        <Table>
          <Thead>
            <Tr>
              <Th>물품이름</Th>
              <Th>물품코드</Th>
              <Th>수량</Th>
              <Th>단위</Th>
              <Th>금액</Th>
              <Th>현재고</Th>
              <Th>안전재고</Th>
              <Th>메모</Th>
              <Th>비고</Th>
            </Tr>
          </Thead>
          <Tbody>
            {shipmentElementList.map((element, index) => {
              const {
                elementName,
                elementCode,
                elementUnit,
                elementStock,
                elementSafeStock,
              } = element.element || {};

              return (
                <Tr key={index}>
                  <Td>{elementName || '-'}</Td>
                  <Td>{elementCode || '-'}</Td>
                  <Td>{element.quantity || '-'}</Td>
                  <Td>{elementUnit || '-'}</Td>
                  <Td style={{ textAlign: 'right' }}>
                    {formatPrice(element.price)}
                  </Td>
                  <Td>{elementStock || '-'}</Td>
                  <Td>{elementSafeStock || '-'}</Td>
                  <Td>{element.note || '-'}</Td>
                  <Td>{element.remark || '-'}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      ) : (
        <p>출하 물품정보가 없습니다.</p>
      )}
    </Container>
  );
};
