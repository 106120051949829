import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { importInspection } from 'api/apis/importInspection/importInspection';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import CheckBox from 'components/checkbox/CheckBox';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import CreateGSIncomingInspectionModal from 'pages/incomingInspection/custom/company/gs/modal/create/CreateGSIncomingInspectionModal';
import TableSection from 'components/layouts/table/TableSection';

const AcceptanceMeasurement = styled.div`
  align-items: center;
  background-color: var(--WorkingColor);
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const FailedMeasurement = styled.div`
  align-items: center;
  background-color: var(--MainRed);
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  width: 100%;
`;
const InspectionCriteria = styled.div`
  align-items: center;
  display: flex;
  column-gap: 10px;
  justify-content: space-between;
  position: absolute;
  right: 250px;
  width: 240px;

  div {
    font-size: 1em;
    font-weight: 600;
    white-space: nowrap;
  }

  input[type='Number'] {
    border: 1px solid var(--gray-200);
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 20px;
    font-weight: 600;
    height: 40px;
    min-width: 200px;
    padding: 2px 10px;
    width: 80%;
  }
`;

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;

    td, th {min-width: 150px;}

    input[type='text'] {
      border: 1px solid var(--gray-200);
      border-radius: 5px;
      box-sizing: border-box;
      color: var(--MainBlue);
      font-size: 20px;
      font-weight: 600;
      height: 40px;
      min-width: 200px;
      padding: 5px;
      width: 98%;
    }
    input[type='text']:read-only {
      background-color: var(--gray-200);
      color: var(--Text);
    }
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const DelButton = styled.div`
  height: 15px;
  margin: 0px auto;
  width: 15px;
  div {background-color: var(--MainRed); cursor: pointer; height: 15px; width: 15px;}
`;

const FormButtonsSection = styled.div`
  align-items: center;
  column-gap: 10px;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 55px;
`;

const MemoSection = styled.textarea`
  border: none;
  box-sizing: border-box;
  height: 80px;
  outline: 1px solid var(--gray-200);
  outline-offset: -4px;
  padding: 20px;
  width: 100%;

  &:focus {
    border: none;
    outline: 1px solid var(--gray-200);
    outline-offset: -4px;
  }
  &:read-only {
    background-color: var(--gray-200);
    color: var(--Text);
    cursor: default;
  }
  &::placeholder {}
  &::-webkit-scrollbar {display: none;}
`;

const GSInspectionModal = (props) => {
  /* ====================================================================== #1 */
  const { gsReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  let rowCount = 0;

  const [_onload, setOnload] = useState('unload');

  const [_inspection, setInspection] = useState(gsReducer.inspectionContent);
  const [_inspectionList, setInspectionList] = useState([]);

  const [_authority, setAuthority] = useState([]);

  const [_updateStatus, setUpdateStatus] = useState(false);
  const [_modalStatus, setModalStatus] = useState(false);

  const [_memo, setMemo] = useState('');

  // 기준값 담는 스테이트
  const [_criteria, setcriteriavalue] = useState('');

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('603') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    getInspectionData();

    setOnload('loaded');
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const getInspectionData = async () => {
    const importInspectionId = gsReducer.inspectionContent.importInspectionId;
    await importInspection.get(importInspectionId).then((response) => {
      if (response === undefined) return;
      console.log('get/importInspection - response :', response);
      Finish_getInspectionData(response.data);
    }).catch((error) => {
      console.log('get/importInspection - error :', error);
    });
  };
  const Finish_getInspectionData = (Result) => {
    let newMemo = '';
    let criteriavalue = 0;
    if (Result.customizedContent.startsWith('{') && Result.customizedContent.endsWith('}')) {
      console.log('JSON.parse(Result.customizedContent).remark : ', JSON.parse(Result.customizedContent).remark,);
      if (JSON.parse(Result.customizedContent).remark !== undefined) newMemo = JSON.parse(Result.customizedContent).remark;
      if (JSON.parse(Result.customizedContent).criteriavalue !== undefined) criteriavalue = JSON.parse(Result.customizedContent).criteriavalue;
    }
    setMemo(newMemo);
    setcriteriavalue(criteriavalue);

    const newList = Result.importInspectionLogList.map((thisItem) => {
      const customizedContent = JSON.parse(thisItem.customizedContent);
      let thisElement = {
        attributeAndValue: {
          길이: '',
          // 단가: '',
          // 단중: '',
          // 비고: '',
        },
        elementName: '',
        elementCode: '',
        elementUnit: '',
      };
      if (thisItem.element !== undefined && thisItem.element !== null) {
        const { elementName, elementCode, elementUnit } = thisItem.element;
        thisElement.elementName = elementName;
        thisElement.elementCode = elementCode;
        thisElement.elementUnit = elementUnit;

        if (thisItem.element.elementAttributeValueList !== null && thisItem.element.elementAttributeValueList.length > 0) {
          const findItem = thisItem.element.elementAttributeValueList.find((thisAttr) => {
            if (thisAttr.elementAttribute !== null && thisAttr.elementAttribute.elementAttributeName === '길이') return true;
            else return false;
          });
          if (findItem !== undefined) thisElement.attributeAndValue['길이'] = findItem.value;
        }
      }

      return {
        importInspectionLogId: thisItem.importInspectionLogId,
        elementStockLogId: customizedContent.elementStockLogId,
        quantity: 1,
        customizedContent: {
          measurement1: customizedContent.measurement1,
          measurement2: customizedContent.measurement2,
          remeasurement: customizedContent.remeasurement,
        },
        info: { ...thisElement },
      };
    });
    console.log('newList : ', newList);

    setInspectionList(newList);
  };

  // 커스텀
  const handleCustomChangeEvent = (e) => {
    const { name, value } = e.target;

    if (name === 'memo') setMemo(value);
    else if (name === 'criteriavalue') setcriteriavalue(value);
  };

  /* ====================================================================== #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    console.log(name, ' : ', value);

    const thisKey = e.target.closest('tr').dataset.key;
    const thisEventIndex = _inspectionList.findIndex((thisItem) => thisItem.importInspectionLogId === Number(thisKey));
    const thisEventItem = _inspectionList[thisEventIndex];
    console.log(thisKey, ' - ', thisEventIndex, ' : ', thisEventItem);

    const newCustom = { ...thisEventItem.customizedContent, [name]: value };
    const newItem = { ...thisEventItem, customizedContent: newCustom };

    const setList = [..._inspectionList];
    setList.splice(thisEventIndex, 1, newItem);

    setInspectionList(setList);
  };

  const actSave = async () => {
    const importInspectionLogList = _inspectionList.map((thisItem) => {
      const returnData = {
        ...thisItem,
        customizedContent: JSON.stringify(thisItem.customizedContent),
      };
      if (returnData.elementStockLogId === undefined) delete returnData.elementStockLogId;
      return returnData;
    });
    const BodyToPut = {
      companyId: userReducer.company.companyId,
      userId: userReducer.user.id,

      importInspectionId: _inspection.importInspectionId,
      importInspectionCode: _inspection.importInspectionCode,
      importInspectionName: _inspection.importInspectionName,

      customizedContent: JSON.stringify({
        remark: _memo,
        criteriavalue: _criteria,
      }),

      deleteLogList: [],
      importInspectionLogList: importInspectionLogList,
    };
    console.log('actSave - BodyToPut : ', BodyToPut);

    await importInspection.update(BodyToPut.importInspectionId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('post/importInspection - response : ', response);
      if (response.status === 200) {
        alert('검사를 저장했습니다.');
        getInspectionData();
      }
    }).catch((error) => {
      console.error('post/importInspection - error : ', error);
    });
  };

  const actShowList = () => {
    setUpdateStatus(false);
    getInspectionData();
  };

  const actUpdateList = () => { setUpdateStatus(true); };
  const actAddProduct = () => { setModalStatus(true); };

  const actDeleteProduct = async () => {
    if (document.querySelectorAll('input[name="InspectionProducts"]:checked').length < 1) {
      return alert('삭제할 검사물품을 선택해주세요.');
    }

    if (window.confirm('선택한 검사물품을 삭제하시겠습니까?') === true) {
      const delList = [];
      document.querySelectorAll('input[name="InspectionProducts"]:checked').forEach((thisItem) => {
        const thisRowKey = thisItem.closest('tr').dataset.key;
        delList.push(Number(thisRowKey));
      });
      const importInspectionLogList = _inspectionList.map((thisItem) => {
        if (delList.indexOf(thisItem.importInspectionLogId) === -1) {
          const returnData = {
            ...thisItem,
            customizedContent: JSON.stringify(thisItem.customizedContent),
          };
          if (returnData.elementStockLogId === undefined) delete returnData.elementStockLogId;
          return returnData;
        } else {
          return null;
        }
      });
      const BodyToPut = {
        companyId: userReducer.company.companyId,
        userId: userReducer.user.id,

        importInspectionId: _inspection.importInspectionId,
        importInspectionCode: _inspection.importInspectionCode,
        importInspectionName: _inspection.importInspectionName,

        customizedContent: _inspection.customizedContent,

        deleteLogList: delList,
        importInspectionLogList: importInspectionLogList.filter((thisItem) => thisItem !== null),
      };
      console.log('actDeleteProduct - BodyToPut : ', BodyToPut);

      await importInspection.update(BodyToPut.importInspectionId, BodyToPut).then((response) => {
        if (response === undefined) return;
        console.log('put/importInspection - response : ', response);
        if (response.status === 200) {
          alert('검사물품을 삭제했습니다.');
          actShowList();
        }
      }).catch((error) => {
        console.error('put/importInspection - error : ', error);
      });
    } else {
      return;
    }
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>조도측정</ModalTitle>
              <FormButtonsSection>
                {_authority.indexOf('603-2') !== -1 ? (
                  <>
                    {_updateStatus !== true ? (
                      <button className="formButton" onClick={actUpdateList}>검사물품 설정</button>
                    ) : null}
                    {_updateStatus === true ? (
                      <button className="formButton" style={{ backgroundColor: 'var(--MainNavy)' }} onClick={actShowList}>리스트 보기</button>
                    ) : null}
                    {_updateStatus === true ? (
                      <button className="formButton" style={{ backgroundColor: 'var(--MainBlue)' }} onClick={actAddProduct}>검사물품 추가</button>
                    ) : null}
                  </>
                ) : null}
              </FormButtonsSection>

              {_updateStatus !== true ? (
                <InspectionCriteria>
                  <div>기준값 : </div>
                  <input
                    type="Number"
                    name="criteriavalue"
                    onInput={handleCustomChangeEvent}
                    defaultValue={_criteria}
                  ></input>
                </InspectionCriteria>
              ) : null}

              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <TableSection
                content={
                  <table>
                    <thead>
                      <tr>
                        {_updateStatus === true ? (
                          <th style={{ minWidth: '50px', width: '50px' }}></th>
                        ) : null}
                        <th style={{ minWidth: '80px', width: '80px' }}>순번</th>
                        <th>재질</th>
                        <th>규격</th>
                        <th>길이</th>
                        {/* <th>단중</th> */}
                        <th style={{ minWidth: '250px', width: '250px' }}>표면처리 전</th>
                        <th style={{ minWidth: '250px', width: '250px' }}>표면처리 후</th>
                        <th style={{ minWidth: '120px', width: '120px' }}>조도측정<br />결과</th>
                        <th style={{ minWidth: '250px', width: '250px' }}>조도재측정</th>
                      </tr>
                    </thead>

                    <tbody>
                      {_inspectionList.map((thisItem, index) => {
                        const customizedContent = thisItem.customizedContent;
                        const infos = thisItem.info;
                        return (
                          <tr key={index + '_InspectionRow'} data-key={thisItem.importInspectionLogId}>
                            {_updateStatus === true ? (
                              <td style={{ minWidth: '50px', width: '50px' }}>
                                <CheckBox act={''} all={false} name={'InspectionProducts'} />
                              </td>
                            ) : null}
                            <td style={{ minWidth: '80px', width: '80px' }} data-col="순번">{(index + 1).toString().padStart(2, '0')}</td>
                            <td data-col="재질">{infos.elementName}</td>
                            <td data-col="규격">{infos.elementCode}</td>
                            <td data-col="길이">{infos.attributeAndValue['길이']}</td>
                            {/* <td data-col="단중">{ customizedContent.unitWeight }</td> */}
                            <td style={{ minWidth: '250px', width: '250px' }} data-col="표면처리 전">
                              <input
                                type="text"
                                name="measurement1"
                                onInput={handleInputEvent}
                                defaultValue={customizedContent.measurement1}
                                readOnly={_updateStatus === true ? true : false}
                                placeholder="표면처리 전 ..."
                              />
                            </td>
                            <td style={{ minWidth: '250px', width: '250px' }} data-col="표면처리 후">
                              <input
                                type="text"
                                name="measurement2"
                                onInput={handleInputEvent}
                                defaultValue={customizedContent.measurement2}
                                readOnly={_updateStatus === true ? true : false}
                                placeholder="표면처리 후 ..."
                              />
                            </td>
                            <td style={{ minWidth: '120px', padding: 'unset', width: '120px' }} data-col="조도측정 결과">
                              {(Number(customizedContent.measurement2) <= Number(_criteria) && Number(customizedContent.measurement2) > 0)
                                || (Number(customizedContent.measurement2) > Number(_criteria) && Number(customizedContent.remeasurement) <= Number(_criteria) && Number(customizedContent.remeasurement) != '') ? (
                                <AcceptanceMeasurement name="measurementResult">
                                  {customizedContent.measurement2 === '' ? ''
                                    : Number(customizedContent.measurement2) <= Number(_criteria) || (Number(customizedContent.remeasurement) <= Number(_criteria) && Number(customizedContent.remeasurement) != '') ? '합격'
                                      : Number(customizedContent.measurement2) > Number(_criteria) ? '불합격'
                                        : ''}
                                </AcceptanceMeasurement>
                              ) : null}
                              {(Number(customizedContent.measurement2) > Number(_criteria) && Number(customizedContent.remeasurement) > Number(_criteria))
                                || (Number(customizedContent.measurement2) > Number(_criteria) && customizedContent.remeasurement === '') ? (
                                <FailedMeasurement name="measurementResult">
                                  {customizedContent.measurement2 === '' ? ''
                                    : Number(customizedContent.measurement2) <= Number(_criteria) || (Number(customizedContent.remeasurement) <= Number(_criteria) && Number(customizedContent.remeasurement) != '') ? '합격'
                                      : Number(customizedContent.measurement2) > Number(_criteria) ? '불합격'
                                        : ''}
                                </FailedMeasurement>
                              ) : null}
                            </td>
                            <td style={{ minWidth: '250px', width: '250px' }} data-col="조도재측정">
                              {checkEmptyNull(customizedContent.measurement2, false) ? (
                                // {Number(customizedContent.measurement2) > Number(_criteria) ? (
                                <input
                                  type="text"
                                  name="remeasurement"
                                  onInput={handleInputEvent}
                                  defaultValue={customizedContent.remeasurement}
                                  readOnly={_updateStatus === true ? true : false}
                                  placeholder="조도재측정 값..."
                                />
                              ) : null}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                }
              />

              <MemoSection
                onInput={handleCustomChangeEvent}
                name="memo"
                defaultValue={_memo || ''}
                readOnly={_updateStatus === true ? true : false}
                placeholder="비고..."
              />
            </ModalMain>

            <ModalFooter>
              {_updateStatus !== true ? (
                <ModalButton onClick={actSave}>저장</ModalButton>
              ) : (
                <ModalButton style={{ backgroundColor: 'var(--MainNavy)' }} onClick={actDeleteProduct}>검사물품 삭제</ModalButton>
              )}
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}

      {_modalStatus === true ? (
        <CreateGSIncomingInspectionModal
          open={_modalStatus}
          close={() => {
            setModalStatus(false);
            getInspectionData();
          }}
        />
      ) : null}
    </>
  );
};

export default GSInspectionModal;
