import React from 'react';
import RouteList from 'preferences/route/RouteList';

const App = () => {
  return (
    <>
      {/* <APIInterceptor></APIInterceptor> */}
      {/* <WSHandler></WSHandler> */}
      <RouteList></RouteList>
    </>
  );
};

export default App;
