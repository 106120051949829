import React, { useCallback, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

const CommaNum = (props) => {
  /* ====================================================================== #1 */
  const { customAttr, displayType, num, onChange, ref } = props;

  /* ====================================================================== #2 */
  const [_num, setNum] = useState(num);
  // console.log('_num : ', _num);

  /* ====================================================================== #3 */
  useEffect(() => {
    setNum(() => {
      return props.num;
    });
  }, [props]);

  /* ====================================================================== #4 */
  const isAllowed = useCallback((values) => {
    const { value } = values;

    if (String(value).includes('.')) return false;
    else if (String(value).includes(/[^0-9]/g)) return false;
    return true;
  }, []); // 소수점 처리X

  // const onHandleChange = useCallback( e => {
  //   e.target.value = e?.target?.value.replaceAll(",", "") || "";
  //   onChange(e);
  // }, [onChange] );

  /* ====================================================================== #5 */
  /* ====================================================================== #6 */

  return (
    <NumberFormat
      allowNegative={true} // 음수여부 true || false
      defaultValue={'0'}
      displayType={displayType} // input || text
      getInputRef={ref}
      isAllowed={isAllowed}
      inputMode={
        displayType === 'text'
          ? null
          : customAttr === 'readonly'
          ? 'text'
          : null
      }
      onChange={(e) => {
        e.preventDefault();
        onChange(e);
      }}
      readOnly={
        displayType === 'text'
          ? false
          : customAttr === 'readonly'
          ? true
          : false
      }
      thousandSeparator
      value={!isNaN(_num) ? _num : 0}
    />
  );
};

export default CommaNum;
