import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { stockPlace } from 'api/apis/stock/stockPlace';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { Content, ContentHeader, Title, SearchBar, ButtonsWrap } from 'pages/types/typeStyle'

import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const StockPlace = (props) => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_placeList, setPlaceList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState({});
  const [_searchText, setSearchText] = useState();

  /* ====================================================================== #3 */
  useEffect(() => {
    getStockPlaceList();

    setOnload('loaded');
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const getStockPlaceList = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      type: 'Stock',
    };
    if (checkEmptyNull(_searchText, false)) {
      BodyToPost.placeName = _searchText;
    }

    await stockPlace.searchStockPlace(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('stockPlace.searchStockPlace : ', response);
      setPlaceList(() => {
        return response.data;
      });
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (shipmentInspection) => {
    setCheckedItem(() => {
      return shipmentInspection;
    });
  };

  const actSearch = async () => {
    getStockPlaceList();
  };

  /* 추가 */
  const actCreate = async () => {
    const promptText = `추가할 재고 위치를 입력해주세요.`;
    const CreatePrompt = window.prompt(promptText, '');
    if (CreatePrompt === null) return;

    const BodyToPost = {
      companyId: userReducer.company.companyId,
      placeName: CreatePrompt,
      type: 'Stock',
    };

    await stockPlace.createStockPlace(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('stockPlace.createStockPlace : ', response);

      setPlaceList((prev) => {
        return [...prev, response.data];
      });
      alert('재고 위치를 추가했습니다.');
    });
  };

  /* 수정 */
  const actUpdate = async () => {
    const updateContent = _checkedItem;
    if (!checkNullObject(updateContent, false))
      return alert('수정할 재고 위치를 선택해주세요.');
    const updateContentIndex = _placeList.findIndex(
      (type) => type.stockPlaceId === updateContent.stockPlaceId,
    );

    const promptText = `재고 위치를 수정합니다.`;
    const UpdatePrompt = window.prompt(promptText, updateContent.placeName);
    if (UpdatePrompt === null) return;

    const BodyToPut = {
      companyId: userReducer.company.companyId,
      stockPlaceId: updateContent.stockPlaceId,
      placeName: UpdatePrompt,
      type: 'Stock',
    };

    await stockPlace
      .updateStockPlace(updateContent.stockPlaceId, BodyToPut)
      .then((response) => {
        if (response === undefined) return;
        console.log('stockPlace.updateStockPlace : ', response);

        setPlaceList((prev) => {
          const prevData = [...prev];
          prevData.splice(updateContentIndex, 1, response.data);
          return prevData;
        });
        alert('재고 위치를 수정했습니다.');
      });
  };

  /* 삭제 */
  const actDelete = () => {
    const deleteContent = _checkedItem;
    if (!checkNullObject(deleteContent, false)) return alert('삭제할 재고 위치를 선택해주세요.');

    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({
        ...deleteContent,
        type: 'stockPlace',
      })}`,
      replace: true,
    });
  };

  /* ====================================================================== #6 */

  return (
    <Content>
      <ContentHeader>
        <Title>재고 위치</Title>

        <div className='content-snb'>
          <ButtonsWrap>
            {props.authority.indexOf('301-2') !== -1 ? (
              <>
                <button className='btn-add' onClick={actCreate}>추가</button>
                <button className='btn-edit' onClick={actUpdate}>수정</button>
                <button className='btn-delete' onClick={actDelete}>삭제</button>
              </>
            ) : null}
            <SearchBar>
              <input
                placeholder="재고 위치..."
                onInput={(e) => { setSearchText(() => { return e.target.value; }); }}
              />
              <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
            </SearchBar>
          </ButtonsWrap>
        </div>
      </ContentHeader>
      <TableSection
        content={
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th style={{ minWidth: 'unset', width: '50px' }}></th>
                <th>재고 위치</th>
              </tr>
            </thead>
            <tbody>
              {checkNullArray(_placeList, []).map((type, index) => {
                return (
                  <tr key={index + '_stockPlaces'}>
                    <td style={{ minWidth: 'unset', width: '50px' }}>
                      <input
                        type="checkBox"
                        name="stockPlaces"
                        checked={type.stockPlaceId === _checkedItem.stockPlaceId}
                        onChange={(e) => {
                          e.preventDefault();
                          handleCheckedItem(type);
                        }}
                      />
                    </td>
                    <td>{type.placeName}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        }
      ></TableSection>
    </Content>
  );
};

export default StockPlace;
