import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

import { setCookie } from 'preferences/cookie/cookie';
import { checkEmptyNull } from 'components/checkValues/checkValues';

const Cluster2RedirectHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getUrlParameter = (name) => {
    const setName = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + setName + '=([^&#]*)');

    const results = regex.exec(window.location.search);
    console.log(name, ' : ', results);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  useEffect(() => {
    const clusterId = Number(location.pathname.split('/')[1]);
    const accessToken = getUrlParameter('accessToken');
    const refreshToken = getUrlParameter('refreshToken');
    console.log(clusterId, accessToken, refreshToken);
    if (checkEmptyNull(accessToken, false) && checkEmptyNull(refreshToken, false)) {
      const options = {
        // httpOnly: true,
      };
      setCookie('accessToken', accessToken, options);
      setCookie('refreshToken', refreshToken, options);
      setCookie('tokenTime', moment().format('YYYY-MM-DD HH:mm:ss'), options);

      setTimeout(navigate(`/${clusterId}/platform/cluster`, { replace: true }), 1000);
    } else {
      console.log('Cluster2RedirectHandler - error : ', accessToken);
      navigate(`/${clusterId}/platform/cluster/sign/login`, { replace: true });
    }

    return () => { };
  }, []);

  return <></>;
};

export default Cluster2RedirectHandler;
