import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';

const Section = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-area: production;
  justify-content: flex-start;
  height: 100%;
  overflow: hidden;
  width: 100%;

  .monitoring {
    height: 100%;
    width: 100%;
  }

  .TableSection {
    height: 100%;

    table {height: 100%;}
    tr.titleRow {
      td, th {background-color: var(--ThirdBlue);}
    }
    td, th {min-width: unset;}
    td.processCell {padding: unset;}
  }
`;

const ProcessCell = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  height: 100%;
  width: 100%;
`;
const ProcessSection = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 60px repeat(auto-fit, minmax(50px, 1fr));
  height: 100%;
  width: 100%;
`;
const Process = styled.div`
  align-items: center;
  background-color: ${(props) => {
    switch (props.status) {
      case 'wait': return 'var(--gray-200)'
      case 'work': return 'var(--WorkingColor)'
      case 'end': return 'var(--ThirdBlue)'
      case 'stop': return 'var(--MainRed)'
      case 'pause': return 'var(--MainYellow)'
      case 'cancel': return 'var(--Bg)'

      case 'NO': return 'var(--MainNavy)';
      case 'cut': return '#BCE55C'
      case 'welding': return '#9FC93C'
      case 'last': return '#689900'

      default: return 'unset';
    }
  }};
  box-sizing: border-box;
  color: ${(props) => {
    return props.status.toLowerCase() === 'cancel' ? 'var(--gray-200)' : 'var(--white)';
  }};
  display: flex;
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  justify-content: center;
  width: 100%;
`;

const ProductionOrderMonitoring = ({total}) => {
  const dispatch = useDispatch();
  const {monitoringReducer, userReducer } = useSelector((state) => state);

  const [_workOrderLogList, setWorkOrderLogList] = useState([]);
  const [_workOrderLogPage, setWorkOrderLogPage] = useState({page: 0, size: total ? 3 : 10, totalSize: 0});

  useEffect(() => {
    getWorkOrderLogList(_workOrderLogPage.page);

    return () => {}
  }, [])

  const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    useEffect(() => {savedCallback.current = callback;}, [callback]);
    useEffect(() => {
      const tick = () => {savedCallback.current();};
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };
  useInterval(async () => {
    setWorkOrderLogPage((prev) => {
      if (prev.page >= parseFloat(_workOrderLogPage.totalSize / _workOrderLogPage.size) - 1) {
        getWorkOrderLogList(0);
        return { ...prev, page: 0 };
      } else {
        getWorkOrderLogList(prev.page + 1);
        return { ...prev, page: prev.page + 1 };
      }
    });
  }, 30000);

  const getWorkOrderLogList = async(page) => {
    const paging = `?page=${page}&size=${_workOrderLogPage.size}&sort=id,DESC`;
    const body={
      companyId: userReducer.company.companyId,
      workOrderStatus: ['work', 'pause', 'stop'],
    }
    await workOrderLogApi.searchWorkOrderLog(paging, body).then(response => {
      if(response === undefined) return;
      console.log('workOrderLogApi.searchWorkOrderLog : ', response);
      setWorkOrderLogList(() => {return response.data.data.content})
      setWorkOrderLogPage((prev) => {return {...prev, totalSize: response.data.data.totalElements}})
    })
  }

  return (
    <Section>
      <div className='monitoring'>
        <TableSection content={
          <table>
            <colgroup>
              <col width={'120px'} />
              <col width={'15%'} />
              <col />
            </colgroup>
            <thead>
              <tr className='titleRow'>
                <th colSpan={3}>작업현황</th>
              </tr>
              <tr>
                <th>작업상태</th>
                <th>거래처</th>
                <th>작업지시</th>
              </tr>
            </thead>
            <tbody>
              {_workOrderLogList.map((workOrderLog, index) => {
                const processLogList = workOrderLog.processLogList;
                processLogList.sort((a, b) => { return a.processLogId - b.processLogId; });
                
                const taskTypeArray = ['BF', 'SF', 'MF'];
                
                return (
                  <tr key={index + 'workOrderLogs'}>
                    <StatusTdButton
                      statusStyle={{cursor: 'default', maxWidth: '120px', minWidth: '120px', width: '120px'}}
                      status={workOrderLog.workOrderStatus}
                    />
                    <td>{workOrderLog.accountName}</td>
                    <td className='processCell'>
                      <ProcessCell>
                        {processLogList.map((process, processIndex) => {
                          return (
                            <ProcessSection key={processIndex + '_ProcessSection'}>
                              <Process status={'NO'} style={{ border: '1px solid var(--white)', minWidth: '60px', width: '60px' }} >
                                {(processIndex + 1).toString().padStart(2, '0')}
                              </Process>

                              {taskTypeArray.map((thisTask) => {
                                let AllTask = [];
                                let taskList = { BF: [], CP: [], SF: [], MF: [], GB: [], MC: [] };

                                let taskCutEndContent = { BF: [], CP: [], SF: [], MF: [], GB: [], MC: [] };
                                let taskWeldingEndContent = { BF: [], CP: [], SF: [], MF: [], GB: [], MC: [] };
                                let taskLastEndContent = { BF: [], CP: [], SF: [], MF: [], GB: [], MC: [] };

                                if (process.taskLogList.length > 0) {
                                  AllTask = process.taskLogList.filter((task) => task.taskType.split('_')[0] === thisTask);
                                  AllTask.forEach((task) => {
                                    taskList[task.taskType.split('_')[0]].push(task);

                                    switch (task.taskStatus) {
                                      case 'cutend':
                                        taskCutEndContent[task.taskType.split('_')[0]].push(task);
                                        break;
                                      case 'weldingend':
                                        taskWeldingEndContent[task.taskType.split('_')[0]].push(task);
                                        taskCutEndContent[task.taskType.split('_')[0]].push(task);
                                        break;
                                      case 'lastend':
                                        taskLastEndContent[task.taskType.split('_')[0]].push(task);
                                        taskWeldingEndContent[task.taskType.split('_')[0]].push(task);
                                        taskCutEndContent[task.taskType.split('_')[0]].push(task);
                                        break;

                                      default: break;
                                    }
                                  });
                                }

                                if (taskList[thisTask].length > 0) {
                                  if (taskCutEndContent[thisTask].length > 0) {
                                    if (taskWeldingEndContent[thisTask].length > 0) {
                                      if (taskLastEndContent[thisTask].length > 0) {
                                        if (process.processStatus === 'end') {
                                          return (<Process key={thisTask.processLogId + thisTask + '_Process'} status={'end'}>{`${thisTask}`}</Process>);
                                        }
                                        return (<Process key={thisTask.processLogId + thisTask + '_Process'} status={'last'}>{`${thisTask} - 마무리`}</Process>);
                                      }
                                      return (<Process key={thisTask.processLogId + thisTask + '_Process'} status={'welding'}>{`${thisTask} - 용접`}</Process>);
                                    }
                                    return (<Process key={thisTask.processLogId + thisTask + '_Process'} status={'cut'}>{`${thisTask} - 절단`}</Process>);
                                  }
                                  return (<Process key={thisTask.processLogId + thisTask + '_Process'} status={process.processStatus}>{thisTask}</Process>);
                                } else {
                                  return null;
                                }
                              })}
                            </ProcessSection>);
                        })}
                      </ProcessCell>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        } />
      </div>
    </Section>
  );
};

export default ProductionOrderMonitoring;