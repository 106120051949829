import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { mallMyQnAActions_setIsPushedSearchButton, mallMyQnAActions_setMallQnAStatus, mallMyQnAActions_setPageNumber, mallMyQnAActions_setReset, mallMyQnAActions_setSearchOption, mallMyQnAActions_setSearchText, mallMyQnAActions_setTotalSize } from 'store/modules/actions/mallSite/mallMyQnAActions';

import { qna } from 'api/apis/mall/qna';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import { DateTimeFormatKR } from 'components/format/DateTimeFormatKR';
import { MallStatusName } from 'components/status/MallStatusName';
import MallPaging from 'mall/components/MallPaging';
import MallQna from 'mall/pages/qna';
import QnaInfo from 'mall/components/QnaInfo';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';

const MallMypageQnaList = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const location = useLocation();
  const { mallMyQnAReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const companyId = userReducer.company.companyId;

  const [_onload, setOnload] = useState('unload');

  const [_mallSiteQnAList, setMallSiteOrderList] = useState([]);

  const [_createStatus, setCreateStatus] = useState(false);

  const [_infoStatus, setInfoStatus] = useState(false);
  const [_currentQnA, setCurrentQnA] = useState({});

  const [isShown, setIsShown] = useState(false);

  const [dropdownVisibility, setDropdownVisibility] = React.useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    getMallSiteQnAList(mallMyQnAReducer.pageNumber - 1, mallMyQnAReducer.mallSiteQnAStatus, mallMyQnAReducer.isPushedSearchButton);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getMallSiteQnAList(mallMyQnAReducer.pageNumber - 1, mallMyQnAReducer.mallSiteQnAStatus, mallMyQnAReducer.isPushedSearchButton);
    }
    return () => { };
  }, [mallMyQnAReducer.pageNumber]);

  useEffect(() => { }, [mallMyQnAReducer]);
  useEffect(() => { }, [_infoStatus, _currentQnA]);

  /* ====================================================================== #4 */
  const getMallSiteQnAList = async (page, mallSiteQnAStatus, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: companyId,
      userId: userReducer.user.id,
    };
    if (checkEmptyNull(mallSiteQnAStatus, false) && mallSiteQnAStatus !== 'all') {
      BodyToPost.status = mallSiteQnAStatus;
    }
    if (clear && checkEmptyNull(mallMyQnAReducer.searchOption, false) && checkEmptyNull(mallMyQnAReducer.searchText, false)) {
      BodyToPost[mallMyQnAReducer.searchOption] = mallMyQnAReducer.searchText;
    }
    await qna.searchMallQnA(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('qna.searchMallQnA : ', response);
      if (response.data) {
        setMallSiteOrderList(() => { return response.data.content; });
        if (response.data.content.length > 0 && _currentQnA.mallQnAId !== undefined) {
          const eventOrderIndex = response.data.content.findIndex((order) => order.mallQnAId === _currentQnA.mallQnAId);
          if (eventOrderIndex !== -1) setCurrentQnA(() => { return response.data.content[eventOrderIndex]; });
        }
        dispatch(mallMyQnAActions_setTotalSize(response.data.totalElements));
      }
    });
  };

  /* ====================================================================== #5 */
  /* 검색 */
  const actSearch = async () => {
    setCreateStatus(() => { return false; });
    setInfoStatus(() => { return false; });
    setCurrentQnA(() => { return {}; });
    dispatch(mallMyQnAActions_setPageNumber(1));
    dispatch(mallMyQnAActions_setIsPushedSearchButton(true));
    await getMallSiteQnAList(0, mallMyQnAReducer.mallSiteQnAStatus, true);
  };

  /* 초기화 */
  const actReset = async () => {
    setCreateStatus(() => { return false; });
    setInfoStatus(() => { return false; });
    setCurrentQnA(() => { return {}; });
    dispatch(mallMyQnAActions_setReset());
    dispatch(mallMyQnAActions_setPageNumber(1));
    await getMallSiteQnAList(0, 'all', false);
  };

  /* 상세보기 */
  const goDetails = (mallSiteQnA) => {
    setCreateStatus(() => { return false; });
    setCurrentQnA(() => { return mallSiteQnA; });
    setInfoStatus(() => { return true; });
    setIsShown(() => { return true; });
  };

  return (
    <>
      <div className='mypage-info-area-order-list mall-product-qna non-padding'>
        <div className='mypage-info-area-order-list-search'>
          <select
            value={mallMyQnAReducer.mallSiteQnAStatus}
            onChange={(e) => {
              dispatch(mallMyQnAActions_setMallQnAStatus(e.target.value));
              dispatch(mallMyQnAActions_setPageNumber(1));
              getMallSiteQnAList(0, e.target.value, mallMyQnAReducer.isPushedSearchButton);
            }}
          >
            {[
              { optionValue: 'all', optionText: '문의상태' },
              { optionValue: 'waiting', optionText: '대기' },
              { optionValue: 'complete', optionText: '답변 완료' },
              { optionValue: 'requestCancel', optionText: '취소 요청' },
              { optionValue: 'cancel', optionText: '취소' },
            ].map((option) => {
              return (<option key={option.optionValue + '_mallSiteQnAStatus'} value={option.optionValue}>{option.optionText}</option>);
            })}
          </select>
          <select value={mallMyQnAReducer.searchOption} onChange={(e) => { dispatch(mallMyQnAActions_setSearchOption(e.target.value)); }}>
            {[
              { optionValue: '', optionText: '검색옵션' },
              { optionValue: 'mallQnACode', optionText: '문의코드' },
              { optionValue: 'mallQnAContent', optionText: '문의내용' }
            ].map((option) => {
              return (<option key={option.optionValue + '_mallSiteQnASearchOption'} value={option.optionValue}>{option.optionText}</option>);
            })}
          </select>
          <input
            className="SearchBar"
            placeholder="Search..."
            value={mallMyQnAReducer.searchText}
            onInput={(e) => { dispatch(mallMyQnAActions_setSearchText(e.target.value)); }}
          />
          <div onClick={actReset}><img src='https://cdn-icons-png.flaticon.com/512/82/82004.png' style={{ width: '16px' }} /></div>
          <button onClick={actSearch}><SearchButtonIcon />검색</button>
          {!_createStatus ? (
            <button name='createQnA' onClick={(e) => { e.preventDefault(); setCreateStatus(() => { return true; }); }}>문의하기</button>
          ) : null}
        </div>
        {/* 문의 리스트 */}
        <div className='qna-wrap'>
          <ul data-info={_infoStatus} className='qna-status'>
            <li className='qna-status-title'>
              <p className='first'>문의번호</p>
              <p>진행상태</p>
              <p>문의코드</p>
              <p>문의내용</p>
              <p>등록일</p>
              <p>상세보기</p>
            </li>
            {_mallSiteQnAList.map((mallSiteQnA, index) => {
              return (
                <li key={index + '_mallSiteQnAs'} onClick={(e) => { e.preventDefault(); setDropdownVisibility(!dropdownVisibility); }}>
                  <p className='first'>{mallSiteQnA.mallQnAId}</p>
                  <p>{MallStatusName(mallSiteQnA.status || 'waiting', 'qna')}</p>
                  <p data-type="code">{mallSiteQnA.mallQnACode}</p>
                  <p>{mallSiteQnA.mallQnAContent}</p>
                  <p data-type="date">{DateTimeFormatKR(mallSiteQnA.createdDate)}</p>
                  <p className='goDetaile' onClick={(e) => { _currentQnA.mallQnAId === mallSiteQnA.mallQnAId ? setCurrentQnA({}) : goDetails(mallSiteQnA) }}>상세보기</p>
                </li>
              );
            })}
          </ul>
        </div>
        <MallPaging
          page={mallMyQnAReducer.pageNumber}
          count={mallMyQnAReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(mallMyQnAActions_setPageNumber(page));
          }}
        />
      </div>
      {/* 문의하기 버튼 클릭 시 나오는 폼 */}
      {_createStatus ? (
        <div className='qna-modal'>
          {/* <div className='mall-product-qna-area'> */}
          <button className='closeBtn' onClick={(e) => setCreateStatus(() => { return false; })}>닫기</button>
          <MallQna
            update={() => {
              getMallSiteQnAList(mallMyQnAReducer.pageNumber - 1, mallMyQnAReducer.mallSiteQnAStatus, mallMyQnAReducer.isPushedSearchButton);
              setCreateStatus(() => { return false });
            }}
          />
        </div>
      ) : null}
      {/* 상세보기 모달 */}
      {isShown ? (
        <div className='qna-modal'>
          <div className='closeBtn' onClick={(e) => { e.preventDefault(); setIsShown(() => { return false; }) }}>닫기</div>
          <QnaInfo
            mallQnA={_currentQnA}
            update={() => {
              getMallSiteQnAList(mallMyQnAReducer.pageNumber - 1, mallMyQnAReducer.mallSiteQnAStatus, mallMyQnAReducer.isPushedSearchButton);
              setIsShown(() => { return false });
            }}
          />
        </div>
      ) : null}
    </>
  );
};

export default MallMypageQnaList;
