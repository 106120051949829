import React, { Fragment, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

import DOCButtonIcon from 'components/icons/DOCButtonIcon';

const ProcessTable = styled.table`
  border-collapse: collapse;
  min-width: 100%;
  table-layout: fixed;

  td, th {
    box-sizing: border-box;
    padding: 5px 15px;
    min-width: 150px !important;
    text-align: center;
    white-space: normal;
    word-break: break-all;
    font-size: 14px;
  }
  td.blankCell {background-color: var(--Bg); opacity: 0.8;}
`;

const SelectWorkOrderRow = (props) => {
  /* ====================================================================== #1 */
  /* ====================================================================== #2 */
  const [workOrderData, setWorkOrderData] = useState(props.content);
  const [workOrderProcessList, setWorkOrderProcessList] = useState(props.content.workOrderProcessList);

  const [checkedItem, setCheckedItem] = useState(props.checkedItem);

  const [_processLength, setProcessLength] = useState(() => { return props.content.workOrderProcessList.length });
  const [_availableAmount, setAvailableAmount] = useState(0);

  /* ====================================================================== #3 */
  useEffect(() => {
    setWorkOrderData(() => { return props.content });
    setWorkOrderProcessList(() => { return props.content.workOrderProcessList });

    setCheckedItem(() => { return props.checkedItem });

    setProcessLength(() => { return props.content.workOrderProcessList.length });

    const processLogElementList = props.content.workOrderProcessList.map((thisItem) => thisItem.process.processElementList);
    const setProcessLogElementList = processLogElementList.flat();
    actCalMinAmount(setProcessLogElementList);
  }, [props]);

  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const actCalMinAmount = (elementList) => {
    const calMinAmount = elementList.map((processElement) => {
      if (!checkEmptyNull(processElement, false) || processElement.processElementType === 'Make' || processElement.processLogElementType === 'Make') return null;
      const realAmount = BigNumber(checkEmptyNull(processElement.elementStock, 0)).minus(BigNumber(checkEmptyNull(processElement.scheduledInputStock, 0))).toNumber();
      const putAmount = BigNumber(1).multipliedBy(BigNumber(checkEmptyNull(processElement.amount, 0))).toNumber();
      const result = BigNumber(realAmount).dividedBy(BigNumber(putAmount)).toNumber();
      return result;
    });
    const minAmount = Math.min.apply(null, calMinAmount.filter((thisItem) => thisItem !== null));
    setAvailableAmount(() => {
      let availableAmount = Math.floor(minAmount);
      if (isNaN(availableAmount) || availableAmount === Infinity || availableAmount < 1) {
        availableAmount = 0;
      }
      return availableAmount;
    });
  };

  /* ====================================================================== #6 */
  const returnItems = () => {
    let returnArray = [];
    for (let i = 1; i < _processLength; i++) {
      const targetCell = workOrderProcessList[i].target ? 'targetCell' : '';
      returnArray.push(
        <tr key={i + '_processRows'}>
          {checkNullObject(workOrderProcessList[i], false) && checkNullObject(workOrderProcessList[i].process) ? (
            <Fragment>
              <td className={targetCell} style={{ borderLeft: '1px solid var(--gray-200)', minWidth: '80px', width: '80px' }}>
                {workOrderProcessList[i].processNumber}
              </td>
              {/* {(props.authority.indexOf('109-1') !== -1 || props.authority.indexOf('109-2') !== -1) ? (
                <td style={{ minWidth: '60px', width: '60px' }}>
                  <div style={{ height: '30px', width: '30px' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.showFileList({ ...workOrderProcessList[i], ...workOrderProcessList[i].process });
                    }}
                  >
                    <DOCButtonIcon />
                  </div>
                </td>
              ) : null} */}
              <td>{workOrderProcessList[i].process.processTypeName}</td>
              <td>{workOrderProcessList[i].process.processCode}</td>
              <td>{workOrderProcessList[i].process.processName}</td>
              <td>{workOrderProcessList[i].process.place?.placeName}</td>
              <td>{workOrderProcessList[i].process.remark}</td>
              <td style={{ backgroundColor: 'var(--Bg)', borderLeft: '1px solid var(--gray-200)', padding: 'unset', verticalAlign: 'top' }}>
                <ProcessTable>
                  <tbody>
                    {(() => {
                      const ProcessMakeItems = workOrderProcessList[i].process.processElementList.filter((thisEl) => thisEl.processElementType === 'Make');
                      return checkNullArray(ProcessMakeItems, []).map((thisEl) => {
                        const processElementUnit = checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementUnit, '') : checkEmptyNull(thisEl.elementUnit, '');

                        return (<tr key={thisEl.processElementId + '_makeItem'}>
                          <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementName, '') : checkEmptyNull(thisEl.elementName, '')}</td>
                          <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementCode, '') : checkEmptyNull(thisEl.elementCode, '')}</td>
                          <td>{checkEmptyNull(thisEl.amount, 0).toLocaleString() + ' ' + processElementUnit}</td>
                          <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementStock, 0).toLocaleString() : checkEmptyNull(thisEl.elementStock, 0).toLocaleString() + ' ' + processElementUnit}</td>
                        </tr>);
                      });
                    })()}
                  </tbody>
                </ProcessTable>
              </td>
              <td style={{ backgroundColor: 'var(--Bg)', borderLeft: '1px solid var(--gray-200)', padding: 'unset', verticalAlign: 'top' }}>
                <ProcessTable>
                  <tbody>
                    {(() => {
                      const ProcessPutItems = workOrderProcessList[i].process.processElementList.filter((thisEl) => thisEl.processElementType === 'Put');
                      return checkNullArray(ProcessPutItems, []).map((thisEl) => {
                        const processElementUnit = checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementUnit, '') : checkEmptyNull(thisEl.elementUnit, '');

                        return (<tr key={thisEl.processElementId + '_putItem'}>
                          <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementName, '') : checkEmptyNull(thisEl.elementName, '')}</td>
                          <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementCode, '') : checkEmptyNull(thisEl.elementCode, '')}</td>
                          <td>{checkEmptyNull(thisEl.amount, 0).toLocaleString() + ' ' + processElementUnit}</td>
                          <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementStock, 0).toLocaleString() : checkEmptyNull(thisEl.elementStock, 0).toLocaleString() + ' ' + processElementUnit}</td>
                        </tr>);
                      });
                    })()}
                  </tbody>
                </ProcessTable>
              </td>
            </Fragment>
          ) : (
            <Fragment>
              <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
            </Fragment>
          )}
        </tr>,
      );
    }

    return returnArray;
  };

  return (
    <Fragment>
      <tr key={workOrderData.workOrderId}>
        <td rowSpan={_processLength === 0 ? 1 : _processLength} style={{ minWidth: '50px', width: '50px' }}>
          <input
            type="checkBox"
            name="workOrders"
            data-key={workOrderData.workOrderId}
            checked={(() => {
              const checkedIndex = checkedItem ? Array.from(checkedItem).findIndex((workOrder) => workOrder.workOrderId === workOrderData.workOrderId) : -1;
              if (checkedIndex !== -1) return true;
              else return false;
            })()}
            onChange={(e) => {
              const isChecked = e.target.checked;
              props.handleCheckedItem(workOrderData, isChecked);
            }}
            onClick={(e) => { e.stopPropagation() }}
          />
        </td>
        {/* {(props.authority.indexOf('109-1') !== -1 || props.authority.indexOf('109-2') !== -1) ? (
          <td rowSpan={_processLength === 0 ? 1 : _processLength} style={{ minWidth: '60px', width: '60px' }}>
            <div onClick={(e) => { e.stopPropagation(); props.showFileList(workOrderData, true); }}>
              <DOCButtonIcon />
            </div>
          </td>
        ) : null} */}
        <td rowSpan={_processLength === 0 ? 1 : _processLength}>{workOrderData.workOrderTypeName}</td>
        <td rowSpan={_processLength === 0 ? 1 : _processLength}>{workOrderData.workOrderCode}</td>
        <td rowSpan={_processLength === 0 ? 1 : _processLength}>{workOrderData.workOrderName}</td>
        {(props.authority.indexOf('104-1') !== -1 || props.authority.indexOf('104-2') !== -1) ? (
          <td rowSpan={_processLength === 0 ? 1 : _processLength}>
            {checkNullObject(workOrderData.account, false) ? checkEmptyNull(workOrderData.account.accountName, '') : ''}
          </td>
        ) : null}
        <td rowSpan={_processLength === 0 ? 1 : _processLength}>{workOrderData.place?.placeName}<br />{workOrderData.workPlace}</td>
        <td rowSpan={_processLength === 0 ? 1 : _processLength}>{workOrderData.remark}</td>
        <td rowSpan={_processLength === 0 ? 1 : _processLength}>{checkEmptyNull(_availableAmount, 0).toLocaleString()}</td>

        {checkNullObject(workOrderProcessList[0], false) && checkNullObject(workOrderProcessList[0].process) ? (
          <Fragment>
            <td className={workOrderProcessList[0].target ? 'targetCell' : ''} style={{ borderLeft: '1px solid var(--gray-200)', minWidth: '80px', width: '80px' }}>
              {workOrderProcessList[0].processNumber}
            </td>
            {/* {(props.authority.indexOf('109-1') !== -1 || props.authority.indexOf('109-2') !== -1) ? (
              <td style={{ minWidth: '60px', width: '60px' }}>
                <div style={{ height: '30px', width: '30px' }} onClick={(e) => { e.stopPropagation(); props.showFileList({ ...workOrderProcessList[0], ...workOrderProcessList[0].process }); }}>
                  <DOCButtonIcon />
                </div>
              </td>
            ) : null} */}
            <td>{workOrderProcessList[0].process.processTypeName}</td>
            <td>{workOrderProcessList[0].process.processCode}</td>
            <td>{workOrderProcessList[0].process.processName}</td>
            <td>{workOrderProcessList[0].process.place?.placeName}</td>
            <td>{workOrderProcessList[0].process.remark || ''}</td>
            <td style={{ backgroundColor: 'var(--Bg)', borderLeft: '1px solid var(--gray-200)', padding: 'unset', verticalAlign: 'top' }}>
              <ProcessTable>
                <tbody>
                  {(() => {
                    const ProcessMakeItems = workOrderProcessList[0].process.processElementList.filter((thisEl) => thisEl.processElementType === 'Make');
                    return checkEmptyNull(ProcessMakeItems, []).map((thisEl) => {
                      const processElementUnit = checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementUnit, '') : checkEmptyNull(thisEl.elementUnit, '');

                      return (<tr key={thisEl.processElementId + '_makeItem'}>
                        <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementName, '') : checkEmptyNull(thisEl.elementName, '')}</td>
                        <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementCode, '') : checkEmptyNull(thisEl.elementCode, '')}</td>
                        <td>{checkEmptyNull(thisEl.amount, 0).toLocaleString() + ' ' + processElementUnit}</td>
                        <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementStock, 0).toLocaleString() : checkEmptyNull(thisEl.elementStock, 0).toLocaleString() + ' ' + processElementUnit}</td>
                      </tr>);
                    });
                  })()}
                </tbody>
              </ProcessTable>
            </td>
            <td style={{ backgroundColor: 'var(--Bg)', borderLeft: '1px solid var(--gray-200)', padding: 'unset', verticalAlign: 'top' }}>
              <ProcessTable>
                <tbody>
                  {(() => {
                    const ProcessPutItems = workOrderProcessList[0].process.processElementList.filter((thisEl) => thisEl.processElementType === 'Put');
                    return checkEmptyNull(ProcessPutItems, []).map((thisEl) => {
                      const processElementUnit = checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementUnit, '') : checkEmptyNull(thisEl.elementUnit, '');

                      return (<tr key={thisEl.processElementId + '_putItem'}>
                        <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementName, '') : checkEmptyNull(thisEl.elementName, '')}</td>
                        <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementCode, '') : checkEmptyNull(thisEl.elementCode, '')}</td>
                        <td>{checkEmptyNull(thisEl.amount, 0).toLocaleString() + ' ' + processElementUnit}</td>
                        <td>{checkNullObject(thisEl.element, false) ? checkEmptyNull(thisEl.element.elementStock, 0).toLocaleString() : checkEmptyNull(thisEl.elementStock, 0).toLocaleString() + ' ' + processElementUnit}</td>
                      </tr>);
                    });
                  })()}
                </tbody>
              </ProcessTable>
            </td>
          </Fragment>
        ) : (
          <Fragment>
            <td className="blankCell" style={{ borderLeft: '1px solid var(--gray-200)' }}></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
          </Fragment>
        )}
      </tr>
      {_processLength > 1 && returnItems()}
    </Fragment>
  );
};

export default SelectWorkOrderRow;
