const initialState = {
  shipmentInspectionItemType: 'all',

  searchData: {},
  searchOption: '',
  searchText: '',

  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
}
const resetState = {
  shipmentInspectionItemType: 'all',

  searchData: {},
  searchOption: '',
  searchText: '',

  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const shipmentInspectionItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETSHIPMENTINSPECTIONITEMTYPE':
      return {
        ...state,
        shipmentInspectionItemType: action.payload,
      };
    case 'SETSHIPMENTINSPECTIONITEMSEARCHDATA':
      return {
        ...state,
        searchData: action.payload,
      };
    case 'SETSHIPMENTINSPECTIONITEMPAGENUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'SETSHIPMENTINSPECTIONITEMTOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };
    case 'SETSHIPMENTINSPECTIONITEMISPUSHEDSEARCHBUTTON':
      return {
        ...state,
        isPushedSearchButton: action.payload,
      };
    case 'SETSHIPMENTINSPECTIONITEMRESET':
      return {
        ...state,
        ...resetState,
      };
    default:
      return state;
  };
};

export default shipmentInspectionItemReducer;