import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { order } from 'api/apis/mall/order';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { uploadSection } from 'components/icons/src';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import MallBody from 'components/layouts/body/MallBody';
import NavBar from 'components/nav/NavBar';
import SelectMallElements from 'pages/mallManagement/mallElement/modal/SelectMallElements';
import { userApi } from 'api/apis/userApi';

const MallCodeStyle = styled.p`
  word-break: break-all;
  width: 100%;
`;

const ElementTable = styled.table`
  background-color: var(--white);
  border-collapse: collapse;
  font-size: 0.9em;
  height: fit-content;
  table-layout: fixed;
  width: 100%;

  td, th {
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    height: 35px;
    padding: 2px 10px;
  }
  th {background-color: var(--MainNavy); color: var(--white);}
  thead th {height: 45px;}
  & td.ElementInfos {box-sizing: border-box; padding: unset;}
`;
const ElementInfos = styled.div`
  height: 100%;
  width: 100%;
`;
const ElementInfo = styled.div`
  border-bottom: 1px solid var(--gray-200);
  display: grid;
  grid-template-columns: 50px auto;
  height: 50%;
  width: 100%;

  & h5 {
    align-items: center;
    background-color: var(--MainNavy);
    color: var(--white);
    display: flex;
    justify-content: center;
    width: 100%;
  }
  &.mall h5 {background-color: var(--MainBlue); }
  & p {
    align-items: center;
    box-sizing: border-box;
    justify-content: flex-start;
    padding: 10px;
    width: 100%;
  }
`;

const NumFormBox = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;

  input {
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    height: 40px;
    max-width: 230px;
    padding: 5px 0px 5px 5px;
    width: 60px;
  }
`;
const NumButtonsBox = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;
`;
const NumButton = styled.div`
  align-items: center;
  background-color: var(--gray-800);
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  font-size: 1.8em;
  display: flex;
  height: 40px;
  justify-content: center;
  padding-bottom: 5px;
  width: 40px;
`;

const DeleteButton = styled.div`
  height: 15px;
  margin: 0px auto;
  width: 15px;

  div {background-color: var(--MainRed); cursor: pointer; height: 15px; width: 15px;}
`;

const MallUserTable = styled.table`
  background-color: var(--white);
  border-collapse: collapse;
  font-size: 0.9em;
  table-layout: fixed;
  width: 100%;

  & td, & th {
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    padding: 5px;
  }
  & th {
    background-color: var(--MainNavy);
    color: var(--white);
    height: 45px;
  }
  tbody th {min-width: 100px; width: 100px;}
  & input, & textarea {
    border-color: var(--MainBlue);
    &:focus { border-color: var(--MainBlue); border-width: 2px; outline: unset;}
  }
`;

const DragFileSection = styled.label`
  align-items: center;
  background-color: var(--white);
  border: 1px dashed var(--gray-200);
  column-gap: 50px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
  width: 100%;

  & p {font-size: 0.9em; }
  & span {
    color: var(--MainBlue);
    &:hover { border-bottom: 2px solid var(--MainBlue); padding-bottom: 2px;}
  }
`;
const DragFileIcon = styled.div`
  background-color: var(--gray-200);
  height: 80px;
  width: 80px;
  mask-image: url(${uploadSection}); mask-repeat: no-repeat; mask-size: contain;
  --webkit-mask-image: url(${uploadSection}); --webkit-mask-repeat: no-repeat; --webkit-mask-size: contain;
`;

const FileList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
  margin-top: 10px;
  width: 100%;
`;
const FileBox = styled.div`
  align-items: center;
  background-color: var(--white);
  box-sizing: border-box;
  color: var(--gray-400);
  display: flex;
  height: 200px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;

& img {height: inherit; max-width: inherit; width: auto;}
`;
const DeleteFileButton = styled.div`
  align-items: center;
  background-color: var(--MainRed);
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  height: 20px;
  justify-content: center;
  padding-left: 3px;
  position: absolute;
  right: 15px;
  top: 15px;
  transform: scale(1.5);
  width: 20px;

  div {background-color: var(--white); cursor: pointer; height: 15px;width: 15px;
  }
`;

const UpdateMallOrder = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();

  const updateContent = JSON.parse(searchParams.get('updateContent'));
  const {mallOrderId, mallOrderCode, status, userId, userName, recipientName, phoneNumber, zip, address, mallOrderContent, mallOrderElementList, attachFileList} = updateContent;

  const FileInput = useRef();

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    mallOrderId: mallOrderId,
    mallOrderCode: mallOrderCode,
    status: checkEmptyNull(status, 'waiting'),
    userId: userId,
    userName: checkEmptyNull(userName, ''),
    recipientName: checkEmptyNull(recipientName, ''),
    phoneNumber: checkEmptyNull(phoneNumber, ''),
    zip: checkEmptyNull(zip, ''),
    address: checkEmptyNull(address, ''),
    mallOrderContent: checkEmptyNull(mallOrderContent, ''),
    mallOrderElementList: mallOrderElementList,
    deleteMallOrderElementList: [],
    attachFileList: attachFileList,
    deleteFileDataList: [],
  });

  const [_modalStatus, setModalStatus] = useState(false);
  const [_originOrderElementList, setOriginOrderElementList] = useState(mallOrderElementList);
  const [_mallOrderElementList, setMallOrderElementList] = useState(mallOrderElementList);

  const [_prevFileDataList, setPrevFileDataList] = useState(checkNullArray(attachFileList, []));
  const [_fileDataList, setFileDataList] = useState(checkNullArray(attachFileList, []));

  const [_userData, setUserData] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    getUserData();
    return () => {}
  }, [])

  /* ====================================================================== #4 */
  const getUserData = async() => {
    await userApi.getUser(userId).then(response => {
      if(response === undefined) return;
      setUserData(() => {return response.data});
    })
  }

  /* ====================================================================== #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {return { ...prev, [name]: value };});
  };

  const handleElementCount = (e, index, element) => {
    const { value } = e.target;

    let eventValue = value > 0 ? value : 1;
    if (!eventValue.toString().startsWith('0.')) eventValue = eventValue.toString().replace(/^0+/, '');
    if (eventValue < 0) eventValue = 0;
    if (eventValue.length > 15) return;

    const amount = BigNumber(eventValue).toNumber();
    const price = checkEmptyNull(element.mallElementPrice, 0);
    const calPrice = BigNumber(price).multipliedBy(BigNumber(amount)).toNumber();

    setMallOrderElementList((prev) => {
      const prevData = [...prev];
      const newData = {...element, count: amount, mallElementTotalPrice: calPrice};
      prevData.splice(index, 1, newData);
      return prevData;
    });
  };
  const actPlus = (element, index) => {
    const amount = BigNumber(element.count).plus(1).toNumber();
    const price = checkEmptyNull(element.mallElementPrice, 0);
    const calPrice = BigNumber(price).multipliedBy(BigNumber(amount)).toNumber();

    setMallOrderElementList((prev) => {
      const returnData = [...prev];
      const newData = {...element, count: amount, mallElementTotalPrice: calPrice};
      returnData.splice(index, 1, newData);
      return returnData;
    });
  };
  const actMinus = (element, index) => {
    const amount = BigNumber(element.count).minus(1).toNumber() > 0 ? BigNumber(element.count).minus(1).toNumber() : 1;
    const price = checkEmptyNull(element.mallElementPrice, 0);
    const calPrice = BigNumber(price).multipliedBy(BigNumber(amount)).toNumber();

    setMallOrderElementList((prev) => {
      const returnData = [...prev];
      const newData = {...element, count: amount, mallElementTotalPrice: calPrice};
      returnData.splice(index, 1, newData);
      return returnData;
    });
  };

  const actDeleteOrderElement = (e, index, element) => {
    setMallOrderElementList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);
      return prevData;
    });
  };

  const handleFileEvent = (type, e) => {
    let files = [];
    switch (type) {
      case 'click': files = e.target.files; break;
      case 'drag': files = e.dataTransfer.files; break;
      default: return;
    }

    if (files === undefined || files === null || files.length === 0) return;
    const setFiles = Array.prototype.slice.call(files);
    setFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onload = () => {
          const returnData = file;
          returnData['preview'] = reader.result;
          setFileDataList((prev) => {return [...prev, returnData];});
          resolve();
        };
      });
    });
  };

  const actDeleteFileData = (file, index) => {
    setFileDataList((prev) => {
      const returnData = [...prev];
      returnData.splice(index, 1);
      return returnData;
    });
  };

  /* 수정 */
  const actUpdate = async (e) => {
    e.preventDefault();

    const findElement = (mallOrderElementId) => {
      const index = _mallOrderElementList.findIndex((mallOrderElement) => mallOrderElement.mallOrderElementId === mallOrderElementId);
      return index;
    };
    const deleteMallOrderElementList = _originOrderElementList.filter((element) => findElement(element.mallOrderElementId) === -1);
    const setDeleteMallOrderElementList = deleteMallOrderElementList.filter((element) => element !== undefined && element !== null);

    const findFileData = (attachFileId) => {
      const index = _fileDataList.findIndex((fileData) => fileData.attachFileId === attachFileId);
      return index;
    };
    const deleteFileDataList = _prevFileDataList.filter((fileData) => findFileData(fileData.attachFileId) === -1);
    const setDeleteFileDataList = deleteFileDataList.filter((fileData) => fileData !== undefined && fileData !== null);

    const BodyToPut = {
      ..._formData,
      mallOrderElementList: _mallOrderElementList,
      deleteMallOrderElementList: setDeleteMallOrderElementList.map((deleteElement) => deleteElement.mallOrderElementId),
      deleteFileDataList: setDeleteFileDataList.map((deleteFileData) => deleteFileData.attachFileId),
    };
    console.log('BodyToPut : ', BodyToPut);

    const putFormData = new FormData();
    putFormData.append('key', new Blob([JSON.stringify(BodyToPut)], { type: 'application/json' }));
    if (_fileDataList.length > 0) {
      _fileDataList.forEach((file) => {
        console.log(file.attachFileId);
        if (file.attachFileId === undefined) putFormData.append('multipartFileList', file);
      });
    }
    for (let values of putFormData.values()) {
      console.log('putFormData : ', values);
    }

    await order.updateMallOrder(mallOrderId, putFormData).then((response) => {
      if (response === undefined) return;
      console.log('order.updateMallOrder : ', response);
      alert('주문정보를 수정했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  };

  return (
    <MallBody contents={
      <>
        <main className="Main">
          <NavBar title={'주문관리'} nav={''} />

          <Form
            title={
              <>
                <MallCodeStyle>{mallOrderCode}</MallCodeStyle>
                주문정보
                <br />
                수정
              </>
            }
            buttons={
              <>
                <button className="formButton" onClick={actUpdate}>저장</button>
                <button className="formButton cancle" onClick={(e) => {e.preventDefault(); navigate(pageReducer.currentPage, { replace: true });}}>
                  취소
                </button>
              </>
            }
            forms={
              <>
                <div className="formBox">
                  <h4>받는 사람</h4>
                  <MallUserTable>
                    <thead>
                      <tr>
                        <th style={{minWidth: '100px', width: '100px'}}></th>
                        <th>구매자</th>
                        <th>받는 사람</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>이름</th>
                        <td>{userName}</td>
                        <td>
                          <input
                            type="text"
                            name="recipientName"
                            placeholder="* 이름..."
                            value={_formData.recipientName}
                            onInput={handleInputEvent}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>연락처</th>
                        <td>{_userData?.phoneNumber}</td>
                        <td>
                          <input
                            type="text"
                            name="phoneNumber"
                            placeholder="* 연락처..."
                            value={_formData.phoneNumber}
                            onInput={handleInputEvent}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>우편번호</th>
                        <td>{_userData?.zip}</td>
                        <td>
                          <input
                            type="text"
                            name="zip"
                            placeholder="* 우편번호..."
                            value={_formData.zip}
                            onInput={handleInputEvent}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>주소</th>
                        <td>{_userData?.address}</td>
                        <td>
                          <textarea
                            type="text"
                            name="address"
                            placeholder="* 주소..."
                            value={_formData.address}
                            onInput={handleInputEvent}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </MallUserTable>
                </div>

                <div className="formBox">
                  <h4>주문내용</h4>
                  <textarea
                    type="text"
                    name="mallOrderContent"
                    placeholder="* 주문내용..."
                    value={_formData.mallOrderContent}
                    onInput={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <div style={{display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%'}}>
                    <h4>주문물품</h4>
                    <div className="formButton"
                      style={{backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px'}}
                      onClick={(e) => {
                        e.preventDefault();
                        setModalStatus(true);
                      }}
                    >
                      물품 선택
                    </div>
                  </div>

                  <ElementTable>
                    <thead>
                      <tr>
                        <th>판매 물품정보</th>
                        <th>수량</th>
                        <th style={{ minWidth: '50px', width: '50px' }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {_mallOrderElementList.map((element, index) => {
                        return (
                          <tr key={index + '_mallOrderElements'}>
                            <td className="ElementInfos">
                              <ElementInfos>
                                <ElementInfo className="mall"><h5>이름</h5><p>{element.mallElementName}</p></ElementInfo>
                                <ElementInfo className="mall"><h5>코드</h5><p>{element.mallElementCode}</p></ElementInfo>
                              </ElementInfos>
                            </td>
                            <td>
                              <NumFormBox>
                                <input
                                  type="number"
                                  min={1}
                                  value={element.count}
                                  onInput={(e) => {handleElementCount(e, index, element);}}
                                />
                                <NumButtonsBox>
                                  <NumButton onClick={() => {actPlus(element, index);}}>+</NumButton>
                                  <NumButton onClick={() => {actMinus(element, index);}}>-</NumButton>
                                </NumButtonsBox>
                              </NumFormBox>
                            </td>
                            <td style={{minWidth: '50px', positon: 'relative', width: '50px'}}>
                              <DeleteButton onClick={(e) => {actDeleteOrderElement(e, index, element);}}><CloseButtonIcon /></DeleteButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </ElementTable>
                </div>

                <div className="formBox">
                  <h4>첨부파일</h4>
                  <input
                    type="file"
                    ref={FileInput}
                    id="FileInput"
                    style={{ display: 'none' }}
                    multiple
                    onChange={(e) => {handleFileEvent('click', e);}}
                  />
                  <DragFileSection
                    htmlFor="FileInput"
                    onDragEnter={(e) => {e.stopPropagation(); e.preventDefault();}}
                    onDragLeave={(e) => {e.stopPropagation(); e.preventDefault();}}
                    onDragOver={(e) => { e.stopPropagation(); e.preventDefault();}}
                    onDrop={(e) => {e.preventDefault(); handleFileEvent('drag', e);}}
                  >
                    <DragFileIcon />
                    <p>첨부할 파일 드래그 또는 <span>선택</span></p>
                  </DragFileSection>
                  <FileList>
                    {_fileDataList.map((fileData, index) => {
                      return (
                        <FileBox key={index + '_fileDatas'}>
                          {(() => {
                            const returnArray = [];
                            if (fileData.attachFileId === undefined) {
                              if (fileData.type && fileData.type.startsWith('image')) {
                                returnArray.push(<img key={index + '_fileDataImg'} src={fileData.preview} alt={fileData.name} />);
                              } else {
                                returnArray.push(<>{fileData.name}</>);
                              }
                            } else {
                              if (fileData.fileData) {
                                if (fileData.fileData.fileDataType && fileData.fileData.fileDataType.startsWith('image')) {
                                  returnArray.push(<img key={index + '_fileDataImg'} src={fileData.fileData.fileDataS3URL} alt={fileData.fileData.fileDataName} />);
                                } else {
                                  returnArray.push(<>{fileData.fileData.fileDataName}</>);
                                }
                              } else {
                                returnArray.push(<></>);
                              }
                            }

                            return returnArray;
                          })()}

                          <DeleteFileButton onClick={() => {actDeleteFileData(fileData, index);}}><CloseButtonIcon /></DeleteFileButton>
                        </FileBox>
                      );
                    })}
                  </FileList>
                </div>
              </>
            }
          />
        </main>

        {_modalStatus === true && (
          <SelectMallElements
            buttonEvent={async (data) => {
              console.log('data : ', data);
              const mallOrderElementIds = _mallOrderElementList.map((thisItem) => thisItem.mallElementId);
              const newElements = data.map((element) => {
                if (mallOrderElementIds.indexOf(element.mallElementId) !== -1) return null;
                else return {...element, count: 0};
              });
              const setNewElements = newElements.filter((thisItem) => thisItem !== null);
              setMallOrderElementList((prev) => {return [...prev, ...setNewElements];});
              setTimeout(setModalStatus(false), 1000);
            }}
            buttonTitle={`물품 선택`}
            open={_modalStatus}
            close={() => {setModalStatus(false);}}
          />
        )}
      </>
    }
    />
  );
};

export default UpdateMallOrder;
