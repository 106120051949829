import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { gsReducer_setDeleteOrder, gsReducer_setUpdateOrder, gsReducer_setWorkOrderContent, } from 'store/modules/actions/company/GSActions';
import { pageReducer_getCurrentPage} from 'store/modules/actions/default/pageActions';
import { productionOrderActions_setEndDate, productionOrderActions_setPageNumber, productionOrderActions_setReset, productionOrderActions_setSearchData, productionOrderActions_setStartDate, productionOrderActions_setTotalSize, productionOrderActions_setWorkOrderStatus } from 'store/modules/actions/common/productionOrderActions';
import { workActions_setCurrentMessage, } from 'store/modules/actions/default/workActions';

import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import { LoadingMsg } from 'components/loading/LoadingMsg';

import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';

import GSCPWorkList from 'pages/productionOrder/custom/company/gs/modal/GSCPWorkList';
import GSCLWorkList from 'pages/productionOrder/custom/company/gs/modal/GSCLWorkList';
import GSBLWorkList from 'pages/productionOrder/custom/company/gs/modal/GSBLWorkList';

const Section = styled.main`
  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
    & .statusButtons {display: flex; gap: 5px;}
  }
`;

const ListButton = styled.div`
  align-items: center;
  background-color: ${(props) => {
    switch(props.type) {
      case 'BL': return 'var(--MainBlue)';
      case 'CP': return 'var(--ThirdBlue)';
      case 'CL': return 'var(--MainNavy)';
      default: return null;
    }
  }};
  border: 4px solid var(--white);
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 600;
  gap: 6px;
  height: 100%;
  justify-content: center;
  min-height: 100px;
  width: 100%;

  & p {font-size: 0.8em;}
`;
const ConnectStatus = styled.div`
  background-color: ${(props) => {
    return props.connectStatus === 'connect' ? 'var(--MainGreen)' : 'var(--MainRed)';
  }};
  border-radius: 50%;
  right: 76px;
  bottom: 26px;
  position: fixed;
  height: 40px;
  width: 40px;
  z-index: 1000;
`;

const GSProductionOrder = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, productionOrderReducer, userReducer, workReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_workOrderLogList, setWorkOrderLogList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState([]);

  const [_modalStatus_BL, setModalStatus_BL] = useState(false);
  const [_modalStatus_CP, setModalStatus_CP] = useState(false);
  const [_modalStatus_CL, setModalStatus_CL] = useState(false);
  const [_selectedWorkOrder, setSelectedWorkOrder] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('403') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getWorkOrderLogList(productionOrderReducer.pageNumber - 1, 'all', productionOrderReducer.workOrderStatus)
    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getWorkOrderLogList(productionOrderReducer.pageNumber - 1, 'all', productionOrderReducer.workOrderStatus)
    }

    return () => { };
  }, [productionOrderReducer.pageNumber]);

  useEffect(() => {
    if (workReducer.connectStatus === 'connect') {
      if (!checkNullObject(workReducer.currentMessage, false)) return;

      if (workReducer.currentMessage.userId === userReducer.user.id && checkEmptyNull(workReducer.currentMessage.errorMessage, false)) {
        return dispatch(workActions_setCurrentMessage({}));;
      }

      if (workReducer.currentMessage.type === '/work') applyStatus();
      if (workReducer.currentMessage.type === 'process') applyProcessStatus();
      if (workReducer.currentMessage.type === 'processes') applyProcessesStatus();
    }

    return () => { };
  }, [workReducer.currentMessage]);

  useEffect(() => { }, [workReducer.connectStatus]);

  /* ====================================================================== #4 */
  const getWorkOrderLogList = async (page, workOrderType, workOrderStatus, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    if (!clear && checkEmptyNull(workOrderType, false) && workOrderType !== 'all') {
      BodyToPost.workOrderTypeId = Number(workOrderType);
    }
    if (!clear && checkEmptyNull(workOrderStatus, false)) {
      switch (workOrderStatus) {
        case 'all': BodyToPost.workOrderStatus = ['wait', 'work', 'pause', 'stop', 'end', 'cancel']; break;
        case 'waitNwork': BodyToPost.workOrderStatus = ['wait', 'work']; break;
        default: BodyToPost.workOrderStatus = [workOrderStatus]; break;
      }
    }
    if (!clear && checkEmptyNull(productionOrderReducer.startDate, false) && checkEmptyNull(productionOrderReducer.endDate, false)) {
      BodyToPost.scheduledStartDate = DateFormat(productionOrderReducer.startDate);
      BodyToPost.scheduledEndDate = DateFormat(productionOrderReducer.endDate);
      if (productionOrderReducer.startDate > productionOrderReducer.endDate) {
        BodyToPost.scheduledStartDate = DateFormat(productionOrderReducer.endDate);
        BodyToPost.scheduledEndDate = DateFormat(productionOrderReducer.startDate);
      }
    } else if (!clear && checkEmptyNull(productionOrderReducer.startDate, false) && !checkEmptyNull(productionOrderReducer.endDate, false)) {
      BodyToPost.scheduledStartDate = DateFormat(productionOrderReducer.startDate);
    } else if (!clear && checkEmptyNull(productionOrderReducer.endDate, false) && !checkEmptyNull(productionOrderReducer.startDate, false)) {
      BodyToPost.scheduledEndDate = DateFormat(productionOrderReducer.endDate);
    }
    if (!clear && checkEmptyNull(productionOrderReducer.searchData.accountName, false)) {
      BodyToPost.accountName = productionOrderReducer.searchData.accountName;
    }
    if (!clear && checkEmptyNull(productionOrderReducer.searchData.workOrderCode, false)) {
      BodyToPost.workOrderCode = productionOrderReducer.searchData.workOrderCode;
    }
    if (!clear && checkEmptyNull(productionOrderReducer.searchData.workOrderName, false)) {
      BodyToPost.workOrderName = productionOrderReducer.searchData.workOrderName;
    }
    console.log('getWorkOrderLogList - BodyToPost : ', BodyToPost);

    await workOrderLogApi.searchWorkOrderLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.searchWorkOrderLog : ', response);

      setWorkOrderLogList(() => { return response.data.data.content });
      dispatch(productionOrderActions_setTotalSize(response.data.data.totalElements));

      if (checkEmptyNull(productionOrderReducer.startDate, false) && checkEmptyNull(productionOrderReducer.endDate, false)) {
        if (productionOrderReducer.startDate > productionOrderReducer.endDate) {
          dispatch(productionOrderActions_setStartDate(productionOrderReducer.endDate))
          dispatch(productionOrderActions_setEndDate(productionOrderReducer.startDate))
        }
      }
    });
  };

  /* 웹소켓 */
  const applyStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    if (eventWorkOrder === undefined) return;
    eventWorkOrder.workOrderStatus = applyContent.workOrderStatus;
    eventWorkOrder.managers = checkNullArray(applyContent.managers, []);
    eventWorkOrder.workers = checkNullArray(applyContent.workers, []);
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, eventWorkOrder);
    setWorkOrderLogList(() => { return eventWorkOrderList });
  };
  const applyProcessStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    if (eventWorkOrder === undefined) return;

    const eventProcessLogList = [...eventWorkOrder.processLogList];
    const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === applyContent.processLog.processLogId);
    const eventContent = eventProcessLogList[eventProcessLogIndex];
    if (eventContent === undefined) return;

    eventContent.processStatus = applyContent.processLog.processStatus;
    eventContent.completedAmount = applyContent.processLog.completedAmount;
    eventContent.processAmount = applyContent.processLog.processAmount;
    eventContent.processingCount = applyContent.processLog.processingCount;
    eventContent.managers = checkNullArray(applyContent.managers, []);
    eventContent.workers = checkNullArray(applyContent.workers, []);
    if (checkNullArray(eventContent.processLogElementList, false)) {
      const eventResultElements = eventContent.processLogElementList.map((thisItem) => {
        const thisFindIndex = applyContent.processResultElements.findIndex((thisElement) => thisElement.processLogElementId === thisItem.processLogElementId);
        if (thisFindIndex === -1) return thisItem;
        const thisFindItem = applyContent.processResultElements[thisFindIndex];
        const returnData = {...thisItem, ...thisFindItem};
        return returnData;
      });
      eventContent.processLogElementList = eventResultElements;
    }
    eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);

    const detailWorkOrder = {...eventWorkOrder, processLogList: eventProcessLogList};
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, detailWorkOrder);
    setWorkOrderLogList(eventWorkOrderList);
  };
  const applyProcessesStatus = () => {
    const applyContent = workReducer.currentMessage;
    console.log('applyStatus - applyContent : ', applyContent);

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    if (eventWorkOrder === undefined) return;

    const eventProcessLogList = [...eventWorkOrder.processLogList];
    if (checkNullArray(applyContent.socketProcessResults, false)) {
      applyContent.socketProcessResults.forEach((processResult) => {
        const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === processResult.processLog.processLogId);
        if (eventProcessLogIndex === -1) return;

        const eventContent = eventProcessLogList[eventProcessLogIndex];
        eventContent.processStatus = processResult.processLog.processStatus;
        eventContent.completedAmount = processResult.processLog.completedAmount;
        eventContent.processAmount = processResult.processLog.processAmount;
        eventContent.processingCount = processResult.processLog.processingCount;
        eventContent.managers = checkNullArray(processResult.managers, []);
        eventContent.workers = checkNullArray(processResult.workers, []);
        if (checkNullArray(eventContent.processLogElementList, false)) {
          const eventResultElements = eventContent.processLogElementList.map((thisItem) => {
            const thisFindIndex = processResult.processResultElements.findIndex((thisElement) => thisElement.processLogElementId === thisItem.processLogElementId);
            if (thisFindIndex === -1) return thisItem;
            const thisFindItem = processResult.processResultElements[thisFindIndex];
            const returnData = { ...thisItem, ...thisFindItem };
            return returnData;
          });
          eventContent.processLogElementList = eventResultElements;
        }
        eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
      });
    } else {
      applyContent.processLogIds.forEach((processLogIds) => {
        const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === processLogIds);
        if (eventProcessLogIndex === -1) return;

        const eventContent = eventProcessLogList[eventProcessLogIndex];
        eventContent.processStatus = applyContent.processStatus;
        eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
      });
    }

    const detailWorkOrder = {
      ...eventWorkOrder,
      processLogList: eventProcessLogList,
    };
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, detailWorkOrder);
    setWorkOrderLogList(eventWorkOrderList);
  };

  /* ====================================================================== #5 */
  const handleWorkOrderStatus = async (workOrderStatus) => {
    dispatch(productionOrderActions_setWorkOrderStatus(workOrderStatus));
    dispatch(productionOrderActions_setPageNumber(1));
    await getWorkOrderLogList(0, 'all', workOrderStatus)
  }

  const handleCheckedItem = (item, isChecked) => {
    const checkedItem = [..._checkedItem];
    const checkedIndex = checkedItem.findIndex((thisItem) => thisItem.workOrderLogId === item.workOrderLogId);
    if (checkedIndex !== -1) {
      if (!isChecked) checkedItem.splice(checkedIndex, 1);
    } else {
      if (isChecked) checkedItem.push(item);
    }
    console.log('checkedItem : ', checkedItem);
    setCheckedItem(() => { return checkedItem });
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(productionOrderActions_setPageNumber(1));
    await getWorkOrderLogList(0, 'all', productionOrderReducer.workOrderStatus);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(productionOrderActions_setReset());
    getWorkOrderLogList(0, 'all', 'all', true);
  };

  // 리스트 팝업
  const actShowList = (type, workOrderLog) => {
    switch (type) {
      case 'BL':
        setSelectedWorkOrder(workOrderLog);
        dispatch(gsReducer_setWorkOrderContent(workOrderLog));
        setModalStatus_BL(true);
        return;
      case 'CP':
        setSelectedWorkOrder(workOrderLog);
        dispatch(gsReducer_setWorkOrderContent(workOrderLog));
        setModalStatus_CP(true);
        return;
      case 'CL':
        setSelectedWorkOrder(workOrderLog);
        dispatch(gsReducer_setWorkOrderContent(workOrderLog));
        setModalStatus_CL(true);
        return;

      default: return;
    }
  };

  const actUpdate = () => {
    if (checkEmptyNull(_checkedItem, []).length < 1 || checkEmptyNull(_checkedItem, []).length > 1) return;
    const checkedItem = _checkedItem[0];

    if (checkedItem.workOrderStatus === 'end' || checkedItem.workOrderStatus === 'cancel') {
      const statusText = checkedItem.workOrderStatus === 'end' ? '완료' : checkedItem.workOrderStatus === 'cancel' ? '취소' : null;
      return alert(`작업상태가 [ ${statusText} ]인 작업은 정보수정을 할 수 없습니다.`);
    }
    dispatch(gsReducer_setUpdateOrder(checkedItem));
    navigate(pageReducer.currentPage + '/update', { replace: true });
  };

  const actDelete = () => {
    if (checkEmptyNull(_checkedItem, []).length < 1 || checkEmptyNull(_checkedItem, []).length > 1) return;
    const checkedItem = _checkedItem[0];
    dispatch(gsReducer_setDeleteOrder(checkedItem));
    navigate(pageReducer.currentPage + '/delete', { replace: true });
  };

  /* ====================================================================== #6 */

  return (
    <Grid3Body contents={
      <>
        <Section className="Main">
          <NavBar
            title={
              <div className='Title'>
                <NavTitle menuCode={'403'} />
                <div className='statusButtons'>
                  {[
                    { status: 'all', name: '전체' },
                    { status: 'waitNwork', name: '대기/작업중' },
                    { status: 'wait', name: '대기' },
                    { status: 'work', name: '작업중' },
                    { status: 'pause', name: '일시정지' },
                    { status: 'stop', name: '중단' },
                    { status: 'end', name: '완료' },
                    { status: 'cancel', name: '취소' },
                  ].map((workOrderStatus, index) => {
                    return (
                      <button key={index + '_statusButton'}
                        className={productionOrderReducer.workOrderStatus === workOrderStatus.status ? 'btn-inven-1' : 'btn-set'}
                        onClick={() => { handleWorkOrderStatus(workOrderStatus.status); }}
                      >
                        {workOrderStatus.name}
                      </button>
                    )
                  })}
                </div>
              </div>
            }
            buttons={
              <>
                {_authority.indexOf('403-2') !== -1 ? (
                  <>
                    <button className='btn-add' onClick={() => {navigate(pageReducer.currentPage + '/create', {replace: true})}}>추가</button>
                    <button className='btn-edit' onClick={actUpdate}>정보수정</button>
                    <button className='btn-delete' onClick={actDelete}>삭제</button>
                  </>
                ) : null}
              </>
            }
            nav={'search'}
            firstRow={
              <div className="SearchSection" style={{columnGap: '20px'}}>
                <div className="SearchSection">
                  <input
                    type="date"
                    className="SelectDate"
                    value={productionOrderReducer.startDate}
                    onChange={(e) => { dispatch(productionOrderActions_setStartDate(e.target.value)); }}
                  />
                  <IconRightArrow />
                  <input
                    type="date"
                    className="SelectDate"
                    value={productionOrderReducer.endDate}
                    onChange={(e) => { dispatch(productionOrderActions_setEndDate(e.target.value)); }}
                  />
                </div>

                <div className="SearchSection">
                  <div className="ContentCBox">
                    <select className="SearchOption detail"><option value="accountName">거래처</option></select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkEmptyNull(productionOrderReducer.searchData.accountName, '')}
                      onInput={(e) => {dispatch(productionOrderActions_setSearchData({ ...productionOrderReducer.searchData, accountName: e.target.value }));}}
                    />
                  </div>

                  <div className="ContentCBox">
                    <select className="SearchOption detail"><option value="workOrderCode">작업코드</option></select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkEmptyNull(productionOrderReducer.searchData.workOrderCode, '')}
                      onInput={(e) => {dispatch(productionOrderActions_setSearchData({ ...productionOrderReducer.searchData, workOrderCode: e.target.value }));}}
                    />
                  </div>

                  <div className="ContentCBox">
                    <select className="SearchOption detail"><option value="workOrderName">공사명</option></select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkEmptyNull(productionOrderReducer.searchData.workOrderName, '')}
                      onInput={(e) => {dispatch(productionOrderActions_setSearchData({ ...productionOrderReducer.searchData, workOrderName: e.target.value }));}}
                    />
                  </div>

                  <div className="ContentCBox">
                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton" onClick={actReset}>초기화</div>
                  </div>
                </div>
              </div>
            }
          />

          <TableSection content={
            <table className='gs-table'>
              <thead style={{ zIndex: '800' }}>
                <tr>
                  <th style={{ minWidth: '120px', padding: 'unset', width: '120px'}}>작업상태</th>
                  {_authority.indexOf('403-2') !== -1 ? (<th style={{ minWidth: '50px', width: '50px' }}></th>) : null}
                  <th>거래처</th>
                  <th>작업코드</th>
                  <th>공사명</th>
                  <th>현장명</th>
                  <th>현장주소</th>
                  <th>공사기간</th>
                  <th colSpan={3}>작업지시</th>
                </tr>
              </thead>
              <tbody>
                {!checkNullArray(_workOrderLogList, false) ? LoadingMsg() :
                checkNullArray(_workOrderLogList, []).map((workOrderLog, index) => {
                  return (
                    <tr key={index + '_workOrderRow'} data-key={workOrderLog.workOrderLogId}>
                      <StatusTdButton
                        statusStyle={{ cursor: 'default', height: '120px', minWidth: '120px', width: '120px' }}
                        status={workOrderLog.workOrderStatus}
                      />
                      {_authority.indexOf('403-2') !== -1 ? (
                        <td style={{ minWidth: '50px', width: '50px' }}>
                          <input
                            type="checkBox"
                            name="workOrderLog"
                            data-key={workOrderLog.workOrderLogId}
                            checked={(() => {
                              const checkedId = _checkedItem ? _checkedItem.findIndex((item) => item.workOrderLogId === workOrderLog.workOrderLogId) : -1;
                              if (checkedId !== -1) return true;
                              else return false;
                            })()}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              handleCheckedItem(workOrderLog, isChecked);
                            }}
                            onClick={(e) => { e.stopPropagation() }}
                          />
                        </td>) : null}
                      <td>{workOrderLog.accountName}</td>
                      <td>{workOrderLog.workOrderCode}</td>
                      <td>{workOrderLog.workOrderName}</td>
                      <td>{workOrderLog.workPlace}</td>
                      <td>{workOrderLog.detailedWorkPlace}</td>
                      <td>{DateFormat(workOrderLog.workOrderScheduledStartDate)}<br /> ~ <br />{DateFormat(workOrderLog.workOrderScheduledEndDate)}</td>
                      <td style={{minWidth: '160px', padding: 'unset', width: '160px'}}>
                        <ListButton
                          type="BL"
                          data-type={'BL'}
                          data-workorderlogid={workOrderLog.workOrderLogId}
                          onClick={(e) => {e.preventDefault(); actShowList('BL', workOrderLog)}}
                        >
                          캠버리스트<p>[ BL ]</p>
                        </ListButton>
                      </td>
                      <td style={{minWidth: '160px', padding: 'unset', width: '160px'}}>
                        <ListButton
                          type="CP"
                          data-type={'CP'}
                          data-workorderlogid={workOrderLog.workOrderLogId}
                          onClick={(e) => {e.preventDefault(); actShowList('CP', workOrderLog)}}
                        >
                          조합리스트<p>[ CP ]</p>
                        </ListButton>
                      </td>
                      <td style={{ minWidth: '160px', padding: 'unset', width: '160px'}}>
                        <ListButton
                          type="CL"
                          data-type={'CL'}
                          data-workorderlogid={workOrderLog.workOrderLogId}
                          onClick={(e) => {e.preventDefault(); actShowList('CL', workOrderLog)}}
                        >
                          체크리스트<p>[ CL ]</p>
                        </ListButton>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          }
          ></TableSection>
        </Section>

        <PagingComponent
          page={productionOrderReducer.pageNumber}
          count={productionOrderReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(productionOrderActions_setPageNumber(page));
          }}
        />
        <ConnectStatus connectStatus={workReducer.connectStatus} onClick={() => { navigate(0) }}></ConnectStatus>

        {_modalStatus_BL === true && (
          <GSBLWorkList
            content={_selectedWorkOrder}
            open={_modalStatus_BL}
            close={() => {
              setModalStatus_BL(false);
              getWorkOrderLogList(productionOrderReducer.pageNumber - 1, 'all', productionOrderReducer.workOrderStatus)
            }}
          ></GSBLWorkList>
        )}
        {_modalStatus_CP === true && (
          <GSCPWorkList
            content={_selectedWorkOrder}
            open={_modalStatus_CP}
            close={() => {
              setModalStatus_CP(false);
              getWorkOrderLogList(productionOrderReducer.pageNumber - 1, 'all', productionOrderReducer.workOrderStatus)
            }}
          ></GSCPWorkList>
        )}
        {_modalStatus_CL === true && (
          <GSCLWorkList
            content={_selectedWorkOrder}
            open={_modalStatus_CL}
            close={() => {
              setModalStatus_CL(false);
              getWorkOrderLogList(productionOrderReducer.pageNumber - 1, 'all', productionOrderReducer.workOrderStatus)
            }}
          ></GSCLWorkList>
        )}
      </>
    }
    />
  );
};

export default GSProductionOrder;
