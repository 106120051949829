import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { userApi } from 'api/apis/userApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

const DeleteUser = () => {
  /* ========================================================================= #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();

  const id = searchParams.get('id');
  const teamName = searchParams.get('teamName');
  const email = searchParams.get('email');
  const name = searchParams.get('name');

  /* ====================================================================== #3 */

  /* ========================================================================= #4 */

  /* ====================================================================== #5 */
  const actDelete = async () => {
    await userApi.deleteUser(id).then((response) => {
      if (response === undefined) return;
      console.log('userApi.deleteUser : ', response);
      alert('사용자를 삭제했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    })
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <>
        <main className="Main">
          <NavBar title={<NavTitle menuCode={'901'} />} nav={''} />

          <DeleteForm
            delteTitle={'사용자'}
            deleteItem={
              <>
                <div>{checkEmptyNull(teamName, false) ? '팀: ' + teamName : ''}</div>
                <div>{'이름: ' + name}</div>
                <div>{checkEmptyNull(email, false) ? '이메일: ' + email : ''}</div>
              </>
            }
            deleteText={<>사용자 정보가 삭제됩니다.</>}
            deleteButton={
              <>
                <button className="formButton cancle"
                  onClick={() => { navigate(pageReducer.currentPage, { replace: true }); }}
                >
                  취소
                </button>
                <button className="formButton delete"
                  onClick={() => {
                    if (window.confirm('사용자를 삭제합니다.')) actDelete();
                    else return;
                  }}
                >
                  삭제
                </button>
              </>
            }
          />
        </main>
      </>
    }
    />
  );
};

export default DeleteUser;
