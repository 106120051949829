import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import BigNumber from 'bignumber.js';

import { mallSiteCartActions_setMallAddCart } from 'store/modules/actions/mallSite/mallSiteCartActions';

import { cart } from 'api/apis/mall/cart';

import { checkEmptyNull, checkNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import CommaNum from 'components/format/CommaNum';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import MallPaging from 'mall/components/MallPaging';

const MallCart = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { mallSiteCartReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const companyCode = location.pathname.split('/')[1];

  const [_mallCartList, setMallCartList] = useState([]);
  const [_totalPrice, setTotalPrice] = useState(0);

  const [_cartStatus, setCartStatus] = useState('view');

  const [_pageNum, setPageNum] = useState(1);
  const [_pageCount, setPageCount] = useState(0);

  /* ====================================================================== #3 */
  useEffect(() => { getCartList(_pageNum - 1); return () => { }; }, [_pageNum]);
  useEffect(() => { handleTotalPrice(mallSiteCartReducer.cart); return () => { }; }, [mallSiteCartReducer.cart])
  useEffect(() => { }, [_mallCartList, _totalPrice])

  /* ====================================================================== #4 */
  const getCartList = async (page) => {
    const paging = `?page=${page}&size=5&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      userId: userReducer.user.id,
    };
    await cart.searchMallCart(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('cart.searchMallCart : ', response);
      const cartList = response.data.content; // 장바구니 리스트
      const totalPriceList = []; // 가격 리스트
      if (cartList.length > 0) {
        const returnData = cartList.map((cart, index) => {
          const { mallElement } = cart;
          const amount = checkEmptyNull(cart.count, 0); // 수량
          const price = checkEmptyNull(mallElement.mallElementPrice, 0); // 단가
          const calPrice = BigNumber(price).multipliedBy(BigNumber(amount)).toNumber(); // 금액 = 수량 * 단가
          const cartData = { ...cart, ...mallElement, mallElementTotalPrice: calPrice }; // 가격 데이터
          const findMallCartIndex = mallSiteCartReducer.cart.findIndex(mallCart => mallCart.mallCartId === cart.mallCartId); // 장바구니 물품 찾기
          if (findMallCartIndex !== -1) {
            const findMallCartElement = mallSiteCartReducer.cart[findMallCartIndex];
            cartData.count = findMallCartElement.count;
            cartData.mallElementTotalPrice = findMallCartElement.mallElementTotalPrice;
            totalPriceList.push(calPrice);
            if (index === totalPriceList.length - 1) {
              const totalPrcie = totalPriceList.reduce((a, b) => BigNumber(checkNull(a, 0)).plus(BigNumber(checkNull(b, 0))).toNumber());
              setTotalPrice(() => { return totalPrcie; })
            }
          }
          return cartData;
        });
        setMallCartList(() => { return returnData });
      }
      setPageCount(() => { return response.data.totalElements });
    });
  };

  /* ====================================================================== #5 */
  // 장바구니 물품 전체 선택
  const handleAllCheck = (checked) => {
    if (checked) {
      const cartArray = [];
      _mallCartList.forEach((cart) => cartArray.push(cart));
      dispatch(mallSiteCartActions_setMallAddCart(cartArray));
    } else {
      dispatch(mallSiteCartActions_setMallAddCart([]));
    }
  }
  // 장바구니 물품 선택
  const handleCheckedItem = (item, isChecked) => {
    const cartArray = [...mallSiteCartReducer.cart];
    const cartIndex = cartArray.findIndex((cart) => cart.mallCartId === item.mallCartId);
    if (cartIndex !== -1) {
      if (!isChecked) cartArray.splice(cartIndex, 1);
    } else {
      if (isChecked) cartArray.push(item);
    }
    dispatch(mallSiteCartActions_setMallAddCart(cartArray));
  };
  // 총 가격 핸들러
  const handleTotalPrice = (mallCartList) => {
    const totalPriceList = [];
    if (mallCartList.length > 0) {
      mallCartList.forEach((cart, index) => {
        const { mallElement } = cart;
        const amount = checkEmptyNull(cart.count, 0);
        const price = checkEmptyNull(mallElement.mallElementPrice, 0);
        const calPrice = BigNumber(price).multipliedBy(BigNumber(amount)).toNumber();
        const findMallCartElement = mallSiteCartReducer.cart.findIndex(mallCart => mallCart.mallCartId === cart.mallCartId);
        if (findMallCartElement === -1) return;
        totalPriceList.push(calPrice);
        if (index === totalPriceList.length - 1) {
          const totalPrcie = totalPriceList.reduce((a, b) => BigNumber(checkNull(a, 0)).plus(BigNumber(checkNull(b, 0))).toNumber());
          setTotalPrice(() => { return totalPrcie; })
        }
      });
    } else {
      setTotalPrice(() => { return 0; })
    }
  };
  // 수량 핸들러
  const handleElementCount = (e, index, cart) => {
    const { value } = e.target;
    let eventValue = value > 0 ? value : 1;
    if (!eventValue.toString().startsWith('0.')) eventValue = eventValue.toString().replace(/^0+/, '');
    if (eventValue < 0) eventValue = 0;
    if (eventValue.length > 15) return;

    const amount = BigNumber(eventValue).toNumber();
    const price = checkNull(cart.mallElementPrice, 0);
    const calPrice = BigNumber(price).multipliedBy(BigNumber(amount)).toNumber();
    setMallCartList((prev) => {
      const returnData = [...prev];
      const newData = {
        ...cart,
        count: amount, // 수량
        mallElementTotalPrice: calPrice, // 총 가격
      };
      returnData.splice(index, 1, newData);
      const cartList = returnData.filter(item => mallSiteCartReducer.cart.findIndex(cart => cart.mallCartId === item.mallCartId) !== -1);
      dispatch(mallSiteCartActions_setMallAddCart(cartList));
      return returnData;
    });
  };
  // 수량 더하기 +1
  const actPlus = (cart, index) => {
    const amount = BigNumber(cart.count).plus(1).toNumber();
    const price = checkNull(cart.mallElementPrice, 0);
    const calPrice = BigNumber(price).multipliedBy(BigNumber(amount)).toNumber();
    setMallCartList((prev) => {
      const returnData = [...prev];
      const newData = { ...cart, count: amount, mallElementTotalPrice: calPrice };
      returnData.splice(index, 1, newData);
      const cartList = returnData.filter(item => mallSiteCartReducer.cart.findIndex(cart => cart.mallCartId === item.mallCartId) !== -1);
      dispatch(mallSiteCartActions_setMallAddCart(cartList));
      return returnData;
    });
  };
  // 수량 빼기 -1
  const actMinus = (cart, index) => {
    const amount = BigNumber(cart.count).minus(1).toNumber() > 0 ? BigNumber(cart.count).minus(1).toNumber() : 1;
    const price = checkNull(cart.mallElementPrice, 0);
    const calPrice = BigNumber(price).multipliedBy(BigNumber(amount)).toNumber();
    setMallCartList((prev) => {
      const returnData = [...prev];
      const newData = { ...cart, count: amount, mallElementTotalPrice: calPrice };
      returnData.splice(index, 1, newData);
      const cartList = returnData.filter(item => mallSiteCartReducer.cart.findIndex(cart => cart.mallCartId === item.mallCartId) !== -1);
      dispatch(mallSiteCartActions_setMallAddCart(cartList));
      return returnData;
    });
  };

  // 장바구니 상품 다중삭제
  const deleteCheckedMallCart = async () => {
    const confirmText = `장바구니에서 선택된 상품을 삭제합니다.`;
    if (window.confirm(confirmText)) {
      const cartArray = [...mallSiteCartReducer.cart];
      cartArray.forEach(async (mallCart, index) => {
        await cart.deleteMallCart(mallCart.mallCartId).then((response) => {
          if (response === undefined) return;
          console.log('cart.deleteMallCart : ', response);
          setMallCartList((prev) => {
            const returnData = [...prev];
            const cartIndex = returnData.findIndex(cart => cart.mallCartId === mallCart.mallCartId);
            if (cartIndex === -1) return;
            returnData.splice(cartIndex, 1);
            console.log(prev, '->', returnData);
            return returnData;
          });
          if (index === cartArray.length - 1) dispatch(mallSiteCartActions_setMallAddCart([]));
        });
      })
    } else return;
  }
  // 장바구니 상품 단일 삭제
  const deleteMallCart = async (e, index, cartData) => {
    const confirmText = `장바구니에서 ${cartData.mallElementName} (${cartData.mallElementCode}) 상품을 삭제합니다.`;
    if (window.confirm(confirmText)) {
      await cart.deleteMallCart(cartData.mallCartId).then((response) => {
        if (response === undefined) return;
        console.log('cart.deleteMallCart : ', response);
        alert('상품이 삭제되었습니다.');

        const cartArray = [...mallSiteCartReducer.cart];
        const cartIndex = cartArray.findIndex((cart) => cart.mallCartId === cartData.mallCartId);
        if (cartIndex !== -1) {
          cartArray.splice(cartIndex, 1);
          console.log('cartArray : ', cartArray);
          dispatch(mallSiteCartActions_setMallAddCart(cartArray));
        }
        setMallCartList((prev) => {
          const returnData = [...prev];
          returnData.splice(index, 1);
          console.log(prev, '->', returnData);
          return returnData;
        });
      });
    } else return;
  };

  const AddQnA = () => {
    navigate({
      pathname: `/${companyCode}/mall/product/qna`,
      search: `?${createSearchParams({ mallElementList: JSON.stringify(mallSiteCartReducer.cart) })}`,
      replace: true,
    });
  };
  const AddOrder = () => {
    navigate({
      pathname: `/${companyCode}/mall/product/order`,
      search: `?${createSearchParams({ mallElementList: JSON.stringify(mallSiteCartReducer.cart) })}`,
      replace: true,
    });
  };

  return (
    <>
      <div className='cart-wrap'>
        <ul data-cart={_cartStatus} className='mall-product-cart-list'>
          <li>
            <input type='checkbox' name='select-all' onChange={(e) => { handleAllCheck(e.target.checked) }} />
            <p style={{ gridColumnStart: 2, gridColumnEnd: 4, textAlign: 'center' }}>상품 리스트</p>
            <button onClick={(e) => { e.preventDefault(); deleteCheckedMallCart(); }}>삭제</button>
          </li>
          {_mallCartList.map((mallCart, index) => {
            return (
              <li key={index + '_mallCarts'}>
                <input type="checkBox" name="elements"
                  checked={(() => {
                    const checkIndex = checkNullArray(mallSiteCartReducer.cart, []).findIndex((cart) => cart.mallCartId === mallCart.mallCartId);
                    if (checkIndex !== -1) return true;
                    else return false;
                  })()}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    handleCheckedItem(mallCart, isChecked);
                  }}
                  onClick={(e) => { e.stopPropagation(); }}
                />
                <div className='thumbnail'>
                  {(() => {
                    if (checkNullObject(mallCart.attachFileList, false)) {
                      const fileData = mallCart.attachFileList[0];
                      return <img key={index + '_fileDataImg'} src={fileData.fileData.fileDataS3URL} alt={fileData.fileData.fileDataName} />;
                    } else {
                      return <img src="https://st3.depositphotos.com/23594922/31822/v/600/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg" alt="상품 썸네일 사진" />;
                    }
                  })()}
                </div>
                <div className='infos'>
                  <ol>
                    <li><p>상품코드</p><p>{mallCart.mallElementCode}</p></li>
                    <li><p>상품이름</p><p>{mallCart.mallElementName}</p></li>
                  </ol>
                  <ol>
                    <li><p>단가</p><p>{`${checkEmptyNull(mallCart.mallElementPrice, 0).toLocaleString('ko-kr')}`}</p></li>
                    <li>
                      <p>수량</p>
                      <div>
                        <input type="number" min={1} value={mallCart.count} onInput={(e) => { handleElementCount(e, index, mallCart); }} />
                        <button onClick={(e) => { e.preventDefault(); actPlus(mallCart, index); }}>+</button>
                        <button onClick={(e) => { e.preventDefault(); actMinus(mallCart, index); }}>-</button>
                      </div>
                    </li>
                    <li><p>총 가격</p><p>{`${checkEmptyNull(mallCart.mallElementTotalPrice, 0).toLocaleString('ko-kr')}`}</p></li>
                  </ol>
                </div>
                <button onClick={(e) => { e.preventDefault(); deleteMallCart(e, index, mallCart); }}><CloseButtonIcon /></button>
              </li>
            );
          })}
        </ul>
      </div>
      <MallPaging
        page={_pageNum} count={_pageCount} size={5}
        pageEvent={(page) => {
          console.log('page : ', page);
          setPageNum(() => { return page; });
        }}
      />
      <div className='mall-product-cart-order'>
        <div className='total'>
          <p>총 가격</p><CommaNum displayType={'text'} num={_totalPrice} /><p>원</p>
        </div>

        <div>
          <button style={{ backgroundColor: 'var(--gray-700)' }} onClick={(e) => { e.stopPropagation(); AddQnA(); }}>문의하기</button>
          <button style={{ backgroundColor: 'var(--MainBlue)' }} onClick={(e) => { e.stopPropagation(); AddOrder(); }}>주문하기</button>
        </div>
      </div>
    </>
  );
};

export default MallCart;