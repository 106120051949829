const initialState = {
  searchData: {},
  isPushedSearchButton: false,
  pageNumber: 1,
  totalSize: 0,
}
const resetState = {
  searchData: {},
  isPushedSearchButton: false,
  pageNumber: 1,
  totalSize: 0,
};

const equipmentReducer = (state = initialState, action) => {
  switch(action.type){
    case 'SETEQUIPMENTSEARCHDATA': return {...state, searchData : action.payload};
    case 'SETEQUIPMENTISPUSHEDSEARCHBUTTON': return {...state, isPushedSearchButton: action.payload};
    case 'SETEQUIPMENTPAGENUMBER': return {...state, pageNumber: action.payload};
    case 'SETEQUIPMENTTOTALSIZE': return {...state, totalSize: action.payload};
    case 'SETEQUIPMENTRESET': return {...state, ...resetState};
    default: return state;
  };
};

export default equipmentReducer;