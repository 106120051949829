import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {monitoringActions_setProductionFilter } from 'store/modules/actions/common/monitoringActions';

import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import { ImportantList, ImportantProcess, Process, ProcessSection, SortContent, TableTitle } from 'pages/monitoring/styles';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';

const ProductionMonitoring = ({total, setTimeCounter}) => {
  const dispatch = useDispatch();
  const {monitoringReducer, userReducer } = useSelector((state) => state);

  const [_authority, setAuthority] = useState([]);

  const [_productionList, setProductionList] = useState([]);
  const [_productionPage, setProductionPage] = useState({page: 0, size: total ? 3: 10, totalSize: 0});
  const [_productionView, setProductionView] = useState(monitoringReducer.productionFilter);

  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('104') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    getProductionList(_productionPage.page, _productionView);

    return () => {}
  }, [])
  useEffect(() => {}, [monitoringReducer.productionFilter])

  const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    useEffect(() => {savedCallback.current = callback;}, [callback]);
    useEffect(() => {
      const tick = () => {savedCallback.current();};
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };
  useInterval(async () => {
    setProductionPage((prev) => {
      if (prev.page >= parseFloat(_productionPage.totalSize / _productionPage.size) - 1) {
        getProductionList(0, _productionView);
        return { ...prev, page: 0 };
      } else {
        getProductionList(prev.page + 1, _productionView);
        return { ...prev, page: prev.page + 1 };
      }
    });
    setTimeCounter(() => {return 30});
  }, 30000);

  const getProductionList = async(page, status) => {
    const paging = `?page=${page}&size=${_productionPage.size}&sort=id,DESC`;
    const body={
      companyId: userReducer.company.companyId,
      workOrderStatus: ['work', 'pause', 'stop'],
    }
    if(status !== 'all') body.workOrderStatus = [status];
    await workOrderLogApi.searchWorkOrderLog(paging, body).then(response => {
      if(response === undefined) return;
      console.log('workOrderLogApi.searchWorkOrderLog : ', response);
      const workOrderLogList = [...response.data.data.content].map(workOrderLog => {
        const returnData = {...workOrderLog};
        const processLogList = workOrderLog.processLogList;
        if(checkNullArray(processLogList, false)) {
          processLogList.sort((a,b) => {return a.processNumber - b.processNumber})
        }
        returnData.processLogList = processLogList;
        return returnData;
      })
      setProductionList(() => {return workOrderLogList})
      setProductionPage((prev) => {return {...prev, totalSize: response.data.data.totalElements}})
    })
  }

  const handleSorting = (option) => {
    setProductionPage((prev) => {return{...prev, page: 0}});
    setProductionView(() => {
      dispatch(monitoringActions_setProductionFilter(option));
      getProductionList(0, option); 
      return option;
    })
  }

  return (
    <TableSection content={
      <table>
        <colgroup>
          <col width={'80px'} />
          <col />
          <col />
          <col />
          {(_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) ? <col /> : null}
          <col />
          <col />
          {!total ? (<><col /><col /><col /><col /><col /></>) : null}
        </colgroup>
        <thead>
          <tr>
            <TableTitle colSpan={total ? 7 : 12}>
              <div className='TableTitleContent'>
                <p>작업현황</p>
                <SortContent>
                  <div className={monitoringReducer.productionFilter === 'all' ? 'active' : ''}
                    onClick={(e) => {e.preventDefault(); handleSorting('all');}}
                  >
                    전체보기
                  </div>
                  <div className={monitoringReducer.productionFilter === 'work' ? 'active' : ''}
                    onClick={(e) => {e.preventDefault(); handleSorting('work');}}
                  >
                    작업 중
                  </div>
                  <div className={monitoringReducer.productionFilter === 'pause' ? 'active' : ''}
                    onClick={(e) => {e.preventDefault(); handleSorting('pause');}}
                  >
                    일시정지
                  </div>
                  <div className={monitoringReducer.productionFilter === 'stop' ? 'active' : ''}
                    onClick={(e) => {e.preventDefault(); handleSorting('stop');}}
                  >
                    중단
                  </div>
                </SortContent>
              </div>
            </TableTitle>
          </tr>
          <tr>
            <th>작업상태</th>
            <th>작업유형</th>
            <th>작업코드</th>
            <th>작업이름</th>
            {(_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) ? <th>거래처</th> : null}
            <th>생산목표수량</th>
            <th>기간</th>
            {!total ? (
              <>
                <th>작업장소</th>
                <th>작성자</th>
                <th>책임자</th>
                <th>작업자</th>
                <th>비고</th>
              </>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {_productionList.map((workOrderLog, index) => {
            let processLogList = [];
            let importantList = [];
            if(checkNullArray(workOrderLog.processLogList, false)) {
              const returnList = [...workOrderLog.processLogList];
              returnList.sort((a,b) => {return a.processNumber - b.processNumber});
              processLogList = returnList;

              const filterList = returnList.filter(processLog => ['pause', 'stop'].includes(processLog.processStatus) );
              importantList = filterList;
            }
            
            let colNum = total ? 5 : 10;
            if(_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) colNum = colNum + 1;
            return (
              <Fragment key={index + '_productions'}>
                <tr>
                  <StatusTdButton
                    rowSpan={2}
                    statusStyle={{ cursor: 'default', minWidth: '80px', width: '80px', zoom: '90%' }}
                    status={workOrderLog.workOrderStatus}
                  />
                  <td>{workOrderLog.workOrderTypeName}</td>
                  <td>{workOrderLog.workOrderCode}</td>
                  <td>{workOrderLog.workOrderName}</td>
                  {(_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) ? <td>{workOrderLog?.accountName}</td> : null}
                  <td>{(checkEmptyNull(workOrderLog.amount, 0) * 1).toLocaleString()}</td>
                  <td>
                    {DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledStartDate, ''))}
                    <br />~ {DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledEndDate, ''))}
                  </td>
                  {!total ? (
                    <>
                      <td>{workOrderLog.workPlace}</td>
                      <td>{workOrderLog.userName}</td>
                      <td>{checkNullArray(workOrderLog.managers, []).map((worker, workerIndex) => {return (<p key={index + '_managers_' + workerIndex}>{worker.managerName}</p>);})}</td>
                      <td>{checkNullArray(workOrderLog.workers, []).map((worker, workerIndex) => {return (<p key={index + '_workers_' + workerIndex}>{worker.workerName}</p>);})}</td>
                      <td>{workOrderLog.remark}</td>
                    </>
                  ) : null}
                </tr>
                <tr>
                  <td colSpan={colNum} style={{ height: '30px', padding: 'unset' }}>
                    <ProcessSection>
                      {processLogList.map((process, processIndex) => {
                        return (
                          <Process key={processIndex + '_processCell'} status={process.processStatus} 
                            title={`
  공정코드: ${checkEmptyNull(process.processCode, '')} 
  공정이름: ${checkEmptyNull(process.processName, '')}
                            `}
                          >
                            {checkEmptyNull(process.processNumber, processIndex + 1)}
                          </Process>
                        );
                      })}
                    </ProcessSection>
                    <ImportantList>
                      {importantList.map((process, processIndex) => {
                        return (
                          <ImportantProcess key={processIndex + 'importantList'} status={process.processStatus}>
                            <p style={{ width: '30px' }}>{checkEmptyNull(process.processNumber, processIndex + 1)}</p>
                            <p>공정코드: {process.processCode}</p>
                            <p>공정이름: {process.processName}</p>
                            <p>
                              {(() => {
                                if (checkNullArray(process.managers, false)) {
                                  const managers = process.managers.map((worker) => worker.managerName);
                                  return `책임자: ${managers.join('/')}`;
                                }
                              })()}
                            </p>
                            <p>
                              {(() => {
                                if (checkNullArray(process.workers, false)) {
                                  const workers = process.workers.map((worker) => worker.workerName);
                                  return `작업자: ${workers.join('/')}`;
                                }
                              })()}
                            </p>
                          </ImportantProcess>
                        );
                      })}
                    </ImportantList>
                  </td>
                </tr>
              </Fragment>
            )
          })}
        </tbody>
      </table>
    } />
  );
};

export default ProductionMonitoring;