import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { categoryApi } from 'api/apis/categoryApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

import Grid2Body from 'components/layouts/body/Grid2Body';
import TableSection from 'components/layouts/table/TableSection';

const Section = styled.main`
  grid-row-start: 1;
  grid-row-end: 3;
  height: 100%;
  overflow: hidden;
`;

const MainContents = styled.div`
  box-sizing: border-box;
  column-gap: 10px;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  overflow-x: auto;
  padding: 10px;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}
`;

const Content = styled.div`
  background-color: var(--white);
  border: 1px solid var(--gray-300);
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 40px calc(100% - 50px);
  /* grid-template-rows: 40px 40px calc(100% - 100px); */
  height: 100%;
  min-width: 400px;
  padding: 10px;
  row-gap: 10px;
  width: 100%;

  .TableSection {
    tbody tr:hover {
      background-color: ${(props) => { return props.isLevel3 ? 'var(--white)' : 'var(--gray-200)' }};
      cursor: pointer;
      opacity: 0.8;
    }
    tbody[data-level='5'] tr:hover {background-color: var(--white); cursor: default; opacity: unset;}
  }
`;
const ContentHeader = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`;
const Title = styled.h1`
  font-size: 20px;
`;
const Buttons = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  height: 100%;
  justify-content: center;
`;

const CategoryLevel1 = (props) => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const CategoryLevel1Table = useRef();
  const [_onload, setOnload] = useState('unload');
  const [_categoryList, setCategoryList] = useState([]);
  const [_checkedItem, setCheckedItem] = useState(new Set());
  const [_searchText, setSearchText] = useState('');

  /* ====================================================================== #3 */
  useEffect(() => {
    getCategoryLevel1List();
    setOnload('loaded');
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const getCategoryLevel1List = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      categoryLevel: 1,
    };
    if (checkEmptyNull(_searchText, false)) BodyToPost.categoryName = _searchText;
    await categoryApi.searchCategory(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('categoryApi.searchCategory : ', response);
      setCategoryList(() => { return response.data });
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(id);
    setCheckedItem(() => { return checkedItem });
  };

  /* 추가 */
  const actCreate = async () => {
    const promptText = `추가할 패밀리 이름을 입력해주세요.`;
    const CreatePrompt = window.prompt(promptText, '');
    if (!checkEmptyNull(CreatePrompt, false)) return;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      categoryLevel: 1,
      categoryName: CreatePrompt,
      parentCategoryId: null, // 최상위 카테고리는 null 로 보내면 됨
    };
    await categoryApi.createCategory(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('categoryApi.createCategory : ', response);
      if (response.data === '') {
        return alert(`${CreatePrompt} 패밀리가 이미 존재합니다.`);
      } else {
        setCategoryList((prev) => { return [...prev, response.data]; });
        alert('패밀리를 추가했습니다.');
      }
    });
  };

  /* 수정 */
  const actUpdate = async (e) => {
    const updateContentIndex = _categoryList.findIndex((thisItem) => thisItem.categoryId === Array.from(_checkedItem)[0]);
    if (updateContentIndex === -1) return alert('수정할 패밀리를 선택해 주세요.');
    const updateContent = _categoryList[updateContentIndex];
    const promptText = `패밀리 이름을 수정합니다.`;
    const UpdatePrompt = window.prompt(promptText, updateContent.categoryName);
    if (!checkEmptyNull(UpdatePrompt, false)) return;
    const BodyToPut = {
      companyId: userReducer.company.companyId,
      categoryLevel: 1,
      categoryId: updateContent.categoryId,
      categoryCode: updateContent.categoryCode,
      categoryName: UpdatePrompt,
    };
    await categoryApi.updateCategory(updateContent.categoryId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('categoryApi.updateCategory : ', response);
      if (response.data === '') {
        return alert(`${UpdatePrompt} 패밀리가 이미 존재합니다.`);
      } else {
        setCategoryList((prev) => {
          const prevData = [...prev];
          prevData.splice(updateContentIndex, 1, response.data);
          return prevData;
        });
        alert('패밀리를 수정했습니다.');
      }
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const deleteContentIndex = _categoryList.findIndex((thisItem) => thisItem.categoryId === Array.from(_checkedItem)[0]);
    if (deleteContentIndex === -1) return alert('삭제할 패밀리를 선택해 주세요.');
    const deleteContent = _categoryList[deleteContentIndex];
    console.log('deleteContent : ', deleteContent);
    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams(deleteContent)}`,
      replace: true,
    });
  };

  return (
    <Content>
      <ContentHeader>
        <Title>패밀리 1</Title>
        <Buttons>
          {props.authority.indexOf('102-2') !== -1 ? (
            <>
              <button className='btn-add' onClick={actCreate}>추가</button>
              <button className='btn-edit' onClick={actUpdate}>수정</button>
              <button className='btn-delete' onClick={actDelete}>삭제</button>
            </>
          ) : null}
        </Buttons>
      </ContentHeader>

      <TableSection content={
        <table ref={CategoryLevel1Table}>
          <thead>
            <tr>
              {props.authority.indexOf('102-2') !== -1 ? (<th style={{ minWidth: '50px', width: '50px' }}></th>) : null}
              <th>패밀리 이름</th>
            </tr>
          </thead>
          <tbody data-level="1">
            {_categoryList.map((thisItem, index) => {
              return (<tr key={thisItem.categoryId + '_category_1'}
                style={props.clickedLevel1Category && props.clickedLevel1Category.categoryId === thisItem.categoryId
                  ? { backgroundColor: 'var(--gray-200)' }
                  : {}
                }
                onClick={(e) => { props.setClickedLevel1Category(() => { return thisItem }) }}
              >
                {props.authority.indexOf('102-2') !== -1 ? (
                  <td style={{ minWidth: '50px', width: '50px' }}>
                    <input
                      type="checkBox"
                      name="categories_Level1"
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        handleCheckedItem(thisItem.categoryId, isChecked);
                        if (isChecked) {
                          document.getElementsByName('categories_Level1').forEach((thisAttr) => { thisAttr.checked = false });
                          e.target.checked = true;
                        } else {
                          e.target.checked = false;
                        }
                      }}
                      onClick={(e) => { e.stopPropagation() }}
                    />
                  </td>
                ) : null}
                <td>{thisItem.categoryName}</td>
              </tr>
              );
            })}
          </tbody>
        </table>
      }
      ></TableSection>
    </Content>
  );
};

const CategoryLevel2 = (props) => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const CategoryLevel2Table = useRef();
  const [_clickedLevel1Category, setClickedLevel1Category] = useState([]);
  const [_categoryList, setCategoryList] = useState([]);
  const [_checkedItem, setCheckedItem] = useState(new Set());
  const [_searchText, setSearchText] = useState('');

  /* ====================================================================== #3 */
  useEffect(() => {
    setClickedLevel1Category(() => { return props.clickedLevel1Category });
    setSearchText(() => { return '' });
  }, [props]);

  useEffect(() => {
    getCategoryLevel2List();
  }, [_clickedLevel1Category]);

  /* ====================================================================== #4 */
  const getCategoryLevel2List = async () => {
    if (!checkNullObject(_clickedLevel1Category, false)) {
      setCategoryList(() => { return [] });
      return;
    }
    setCategoryList(() => { return checkNullArray(_clickedLevel1Category?.childrenCategory, []) });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(id);
    setCheckedItem(() => { return checkedItem });
  };

  /* 추가 */
  const actCreate = async () => {
    if (!checkNullObject(_clickedLevel1Category, false)) return alert('상위 패밀리를 선택해주세요.');

    const promptText = `추가할 패밀리 이름을 입력해주세요.`;
    const CreatePrompt = window.prompt(promptText, '');
    if (!checkEmptyNull(CreatePrompt, false)) return;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      parentCategoryId: _clickedLevel1Category.categoryId,
      categoryLevel: 2,
      categoryName: CreatePrompt,
    };
    await categoryApi.createCategory(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('categoryApi.createCategory : ', response);
      if (response.data === '') {
        return alert(`${CreatePrompt} 패밀리가 이미 존재합니다.`);
      } else {
        setCategoryList((prev) => { return [...prev, response.data]; });
        alert('패밀리를 추가했습니다.');
      }
    });
  };

  /* 수정 */
  const actUpdate = async (e) => {
    const updateContentIndex = _categoryList.findIndex((thisItem) => thisItem.categoryId === Array.from(_checkedItem)[0]);
    if (updateContentIndex === -1) return alert('수정할 패밀리를 선택해 주세요.');
    const updateContent = _categoryList[updateContentIndex];
    const promptText = `패밀리 이름을 수정합니다.`;
    const UpdatePrompt = window.prompt(promptText, updateContent.categoryName);
    if (!checkEmptyNull(UpdatePrompt, false)) return;
    const BodyToPut = {
      companyId: userReducer.company.companyId,
      parentCategoryId: _clickedLevel1Category.categoryId,
      categoryLevel: 2,
      categoryId: updateContent.categoryId,
      categoryCode: updateContent.categoryCode,
      categoryName: UpdatePrompt,
    };
    await categoryApi.updateCategory(updateContent.categoryId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('categoryApi.updateCategory : ', response);
      if (response.data === '') {
        return alert(`${UpdatePrompt} 패밀리가 이미 존재합니다.`);
      } else {
        setCategoryList((prev) => {
          const prevData = [...prev];
          prevData.splice(updateContentIndex, 1, response.data);
          return prevData;
        });
        alert('패밀리를 수정했습니다.');
      }
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const deleteContentIndex = _categoryList.findIndex((thisItem) => thisItem.categoryId === Array.from(_checkedItem)[0]);
    if (deleteContentIndex === -1) return alert('삭제할 패밀리를 선택해 주세요.');
    const deleteContent = _categoryList[deleteContentIndex];
    console.log('deleteContent : ', deleteContent);
    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({
        ...deleteContent,
        categoryParentName: _clickedLevel1Category.categoryName,
      })}`,
      replace: true,
    });
  };

  return (
    <Content>
      <ContentHeader>
        <Title>패밀리 2</Title>
        <Buttons>
          {props.authority.indexOf('102-2') !== -1 ? (
            <>
              <button className='btn-add' onClick={actCreate}>추가</button>
              <button className='btn-edit' onClick={actUpdate}>수정</button>
              <button className='btn-delete' onClick={actDelete}>삭제</button>
            </>
          ) : null}
        </Buttons>
      </ContentHeader>

      <TableSection content={
        <table ref={CategoryLevel2Table}>
          <thead>
            <tr>
              {props.authority.indexOf('102-2') !== -1 ? (<th style={{ minWidth: '50px', width: '50px' }}></th>) : null}
              <th>패밀리 이름</th>
            </tr>
          </thead>
          <tbody data-level="2">
            {checkNullArray(_categoryList, []).map((category, index) => {
              return (
                <tr key={index + '_category_2'}
                  style={props.clickedLevel2Category?.categoryId === category.categoryId ? { backgroundColor: 'var(--gray-200)' } : {}}
                  onClick={(e) => { e.preventDefault(); props.setClickedLevel2Category(() => { return category }); }}
                >
                  {props.authority.indexOf('102-2') !== -1 ? (
                    <td style={{ minWidth: '50px', width: '50px' }}>
                      <input
                        type="checkBox"
                        name="categories_Level2"
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          handleCheckedItem(category.categoryId, isChecked);
                          if (isChecked) {
                            document.getElementsByName('categories_Level2').forEach((checkbox) => { checkbox.checked = false });
                            e.target.checked = true;
                          } else {
                            e.target.checked = false;
                          }
                        }}
                        onClick={(e) => { e.stopPropagation() }}
                      />
                    </td>
                  ) : null}
                  <td>{category?.categoryName}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      }
      ></TableSection>
    </Content>
  );
};

const CategoryLevel3 = (props) => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const CategoryLevel3Table = useRef();
  const [_clickedLevel2Category, setClickedLevel2Category] = useState([]);
  const [_categoryList, setCategoryList] = useState([]);
  const [_checkedItem, setCheckedItem] = useState(new Set());
  const [_searchText, setSearchText] = useState('');

  /* ====================================================================== #3 */
  useEffect(() => {
    setClickedLevel2Category(() => { return props.clickedLevel2Category });
    setSearchText(() => { return '' });
  }, [props]);

  useEffect(() => {
    getCategoryLevel3List();
  }, [_clickedLevel2Category]);

  /* ====================================================================== #4 */
  const getCategoryLevel3List = async () => {
    if (!checkNullObject(_clickedLevel2Category, false)) {
      setCategoryList(() => { return [] });
      return;
    }
    setCategoryList(() => { return checkNullArray(_clickedLevel2Category?.childrenCategory, []) });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(id);
    setCheckedItem(() => { return checkedItem });
  };

  /* 추가 */
  const actCreate = async () => {
    if (!checkNullObject(_clickedLevel2Category, false)) return alert('상위 패밀리를 선택해주세요.');

    const promptText = `추가할 패밀리 이름을 입력해주세요.`;
    const CreatePrompt = window.prompt(promptText, '');
    if (!checkEmptyNull(CreatePrompt, false)) return;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      parentCategoryId: _clickedLevel2Category.categoryId,
      categoryLevel: 3,
      categoryName: CreatePrompt,
    };
    await categoryApi.createCategory(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('categoryApi.createCategory : ', response);
      if (response.data === '') {
        return alert(`${CreatePrompt} 패밀리가 이미 존재합니다.`);
      } else {
        setCategoryList((prev) => { return [...prev, response.data]; });
        alert('패밀리를 추가했습니다.');
      }
    });
  };

  /* 수정 */
  const actUpdate = async (e) => {
    const updateContentIndex = _categoryList.findIndex((thisItem) => thisItem.categoryId === Array.from(_checkedItem)[0]);
    if (updateContentIndex === -1) return alert('수정할 패밀리를 선택해 주세요.');
    const updateContent = _categoryList[updateContentIndex];
    const promptText = `패밀리 이름을 수정합니다.`;
    const UpdatePrompt = window.prompt(promptText, updateContent.categoryName);
    if (!checkEmptyNull(UpdatePrompt, false)) return;
    const BodyToPut = {
      companyId: userReducer.company.companyId,
      parentCategoryId: _clickedLevel2Category.categoryId,
      categoryLevel: 3,
      categoryId: updateContent.categoryId,
      categoryCode: updateContent.categoryCode,
      categoryName: UpdatePrompt,
    };
    await categoryApi.updateCategory(updateContent.categoryId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('categoryApi.updateCategory : ', response);
      if (response.data === '') {
        return alert(`${UpdatePrompt} 패밀리가 이미 존재합니다.`);
      } else {
        setCategoryList((prev) => {
          const prevData = [...prev];
          prevData.splice(updateContentIndex, 1, response.data);
          return prevData;
        });
        alert('패밀리를 수정했습니다.');
      }
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const deleteContentIndex = _categoryList.findIndex((thisItem) => thisItem.categoryId === Array.from(_checkedItem)[0]);
    if (deleteContentIndex === -1) return alert('삭제할 패밀리를 선택해 주세요.');
    const deleteContent = _categoryList[deleteContentIndex];
    console.log('deleteContent : ', deleteContent);
    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({
        ...deleteContent,
        categoryParentName: _clickedLevel2Category.categoryName,
      })}`,
      replace: true,
    });
  };

  return (
    <Content>
      <ContentHeader>
        <Title>패밀리 3</Title>
        <Buttons>
          {props.authority.indexOf('102-2') !== -1 ? (
            <>
              <button className='btn-add' onClick={actCreate}>추가</button>
              <button className='btn-edit' onClick={actUpdate}>수정</button>
              <button className='btn-delete' onClick={actDelete}>삭제</button>
            </>
          ) : null}
        </Buttons>
      </ContentHeader>

      <TableSection content={
        <table ref={CategoryLevel3Table}>
          <thead>
            <tr>
              {props.authority.indexOf('102-2') !== -1 ? (<th style={{ minWidth: '50px', width: '50px' }}></th>) : null}
              <th>패밀리 이름</th>
            </tr>
          </thead>
          <tbody data-level="3">
            {checkNullArray(_categoryList, []).map((category, index) => {
              return (
                <tr key={index + '_category_3'}
                  style={props.clickedLevel3Category?.categoryId === category.categoryId ? { backgroundColor: 'var(--gray-200)' } : {}}
                  onClick={(e) => { e.preventDefault(); props.setClickedLevel3Category(() => { return category }); }}
                >
                  {props.authority.indexOf('102-2') !== -1 ? (
                    <td style={{ minWidth: '50px', width: '50px' }}>
                      <input
                        type="checkBox"
                        name="categories_Level3"
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          handleCheckedItem(category.categoryId, isChecked);
                          if (isChecked) {
                            document.getElementsByName('categories_Level3').forEach((checkbox) => { checkbox.checked = false });
                            e.target.checked = true;
                          } else {
                            e.target.checked = false;
                          }
                        }}
                        onClick={(e) => { e.stopPropagation() }}
                      />
                    </td>
                  ) : null}
                  <td>{category?.categoryName}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      }
      ></TableSection>
    </Content>
  );
};

const CategoryLevel4 = (props) => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const CategoryLevel4Table = useRef();
  const [_clickedLevel3Category, setClickedLevel3Category] = useState([]);
  const [_categoryList, setCategoryList] = useState([]);
  const [_checkedItem, setCheckedItem] = useState(new Set());
  const [_searchText, setSearchText] = useState('');

  /* ====================================================================== #3 */
  useEffect(() => {
    setClickedLevel3Category(() => { return props.clickedLevel3Category });
    setSearchText(() => { return '' });
  }, [props]);

  useEffect(() => {
    getCategoryLevel4List();
  }, [_clickedLevel3Category]);

  /* ====================================================================== #4 */
  const getCategoryLevel4List = async () => {
    if (!checkNullObject(_clickedLevel3Category, false)) {
      setCategoryList(() => { return [] });
      return;
    }
    setCategoryList(() => { return checkNullArray(_clickedLevel3Category?.childrenCategory, []) });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(id);
    setCheckedItem(() => { return checkedItem });
  };

  /* 추가 */
  const actCreate = async () => {
    if (!checkNullObject(_clickedLevel3Category, false)) return alert('상위 패밀리를 선택해주세요.');

    const promptText = `추가할 패밀리 이름을 입력해주세요.`;
    const CreatePrompt = window.prompt(promptText, '');
    if (!checkEmptyNull(CreatePrompt, false)) return;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      parentCategoryId: _clickedLevel3Category.categoryId,
      categoryLevel: 4,
      categoryName: CreatePrompt,
    };
    await categoryApi.createCategory(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('categoryApi.createCategory : ', response);
      if (response.data === '') {
        return alert(`${CreatePrompt} 패밀리가 이미 존재합니다.`);
      } else {
        setCategoryList((prev) => { return [...prev, response.data]; });
        alert('패밀리를 추가했습니다.');
      }
    });
  };

  /* 수정 */
  const actUpdate = async (e) => {
    const updateContentIndex = _categoryList.findIndex((thisItem) => thisItem.categoryId === Array.from(_checkedItem)[0]);
    if (updateContentIndex === -1) return alert('수정할 패밀리를 선택해 주세요.');
    const updateContent = _categoryList[updateContentIndex];
    const promptText = `패밀리 이름을 수정합니다.`;
    const UpdatePrompt = window.prompt(promptText, updateContent.categoryName);
    if (!checkEmptyNull(UpdatePrompt, false)) return;
    const BodyToPut = {
      companyId: userReducer.company.companyId,
      parentCategoryId: _clickedLevel3Category.categoryId,
      categoryLevel: 4,
      categoryId: updateContent.categoryId,
      categoryCode: updateContent.categoryCode,
      categoryName: UpdatePrompt,
    };
    await categoryApi.updateCategory(updateContent.categoryId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('categoryApi.updateCategory : ', response);
      if (response.data === '') {
        return alert(`${UpdatePrompt} 패밀리가 이미 존재합니다.`);
      } else {
        setCategoryList((prev) => {
          const prevData = [...prev];
          prevData.splice(updateContentIndex, 1, response.data);
          return prevData;
        });
        alert('패밀리를 수정했습니다.');
      }
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const deleteContentIndex = _categoryList.findIndex((thisItem) => thisItem.categoryId === Array.from(_checkedItem)[0]);
    if (deleteContentIndex === -1) return alert('삭제할 패밀리를 선택해 주세요.');
    const deleteContent = _categoryList[deleteContentIndex];
    console.log('deleteContent : ', deleteContent);
    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({
        ...deleteContent,
        categoryParentName: _clickedLevel3Category.categoryName,
      })}`,
      replace: true,
    });
  };

  return (
    <Content>
      <ContentHeader>
        <Title>패밀리 4</Title>
        <Buttons>
          {props.authority.indexOf('102-2') !== -1 ? (
            <>
              <button className='btn-add' onClick={actCreate}>추가</button>
              <button className='btn-edit' onClick={actUpdate}>수정</button>
              <button className='btn-delete' onClick={actDelete}>삭제</button>
            </>
          ) : null}
        </Buttons>
      </ContentHeader>

      <TableSection content={
        <table ref={CategoryLevel4Table}>
          <thead>
            <tr>
              {props.authority.indexOf('102-2') !== -1 ? (<th style={{ minWidth: '50px', width: '50px' }}></th>) : null}
              <th>패밀리 이름</th>
            </tr>
          </thead>
          <tbody data-level="4">
            {checkNullArray(_categoryList, []).map((category, index) => {
              return (
                <tr key={index + '_category_4'}
                  style={props.clickedLevel4Category?.categoryId === category.categoryId ? { backgroundColor: 'var(--gray-200)' } : {}}
                  onClick={(e) => { e.preventDefault(); props.setClickedLevel4Category(() => { return category }); }}
                >
                  {props.authority.indexOf('102-2') !== -1 ? (
                    <td style={{ minWidth: '50px', width: '50px' }}>
                      <input
                        type="checkBox"
                        name="categories_Level4"
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          handleCheckedItem(category.categoryId, isChecked);
                          if (isChecked) {
                            document.getElementsByName('categories_Level4').forEach((checkbox) => { checkbox.checked = false });
                            e.target.checked = true;
                          } else {
                            e.target.checked = false;
                          }
                        }}
                        onClick={(e) => { e.stopPropagation() }}
                      />
                    </td>
                  ) : null}
                  <td>{category?.categoryName}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      }
      ></TableSection>
    </Content>
  );
};

const CategoryLevel5 = (props) => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const CategoryLevel5Table = useRef();
  const [_clickedLevel4Category, setClickedLevel4Category] = useState([]);
  const [_categoryList, setCategoryList] = useState([]);
  const [_checkedItem, setCheckedItem] = useState(new Set());
  const [_searchText, setSearchText] = useState('');

  /* ====================================================================== #3 */
  useEffect(() => {
    setClickedLevel4Category(() => { return props.clickedLevel4Category });
    setSearchText(() => { return '' });
  }, [props]);

  useEffect(() => {
    getCategoryLevel5List();
  }, [_clickedLevel4Category]);

  /* ====================================================================== #4 */
  const getCategoryLevel5List = async () => {
    if (!checkNullObject(_clickedLevel4Category, false)) {
      setCategoryList(() => { return [] });
      return;
    }
    setCategoryList(() => { return checkNullArray(_clickedLevel4Category?.childrenCategory, []) });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(id);
    setCheckedItem(() => { return checkedItem });
  };

  /* 추가 */
  const actCreate = async () => {
    if (!checkNullObject(_clickedLevel4Category, false)) return alert('상위 패밀리를 선택해주세요.');

    const promptText = `추가할 패밀리 이름을 입력해주세요.`;
    const CreatePrompt = window.prompt(promptText, '');
    if (!checkEmptyNull(CreatePrompt, false)) return;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      parentCategoryId: _clickedLevel4Category.categoryId,
      categoryLevel: 5,
      categoryName: CreatePrompt,
    };
    await categoryApi.createCategory(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('categoryApi.createCategory : ', response);
      if (response.data === '') {
        return alert(`${CreatePrompt} 패밀리가 이미 존재합니다.`);
      } else {
        setCategoryList((prev) => { return [...prev, response.data]; });
        alert('패밀리를 추가했습니다.');
      }
    });
  };

  /* 수정 */
  const actUpdate = async (e) => {
    const updateContentIndex = _categoryList.findIndex((thisItem) => thisItem.categoryId === Array.from(_checkedItem)[0]);
    if (updateContentIndex === -1) return alert('수정할 패밀리를 선택해 주세요.');
    const updateContent = _categoryList[updateContentIndex];
    const promptText = `패밀리 이름을 수정합니다.`;
    const UpdatePrompt = window.prompt(promptText, updateContent.categoryName);
    if (!checkEmptyNull(UpdatePrompt, false)) return;
    const BodyToPut = {
      companyId: userReducer.company.companyId,
      parentCategoryId: _clickedLevel4Category.categoryId,
      categoryLevel: 5,
      categoryId: updateContent.categoryId,
      categoryCode: updateContent.categoryCode,
      categoryName: UpdatePrompt,
    };
    await categoryApi.updateCategory(updateContent.categoryId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('categoryApi.updateCategory : ', response);
      if (response.data === '') {
        return alert(`${UpdatePrompt} 패밀리가 이미 존재합니다.`);
      } else {
        setCategoryList((prev) => {
          const prevData = [...prev];
          prevData.splice(updateContentIndex, 1, response.data);
          return prevData;
        });
        alert('패밀리를 수정했습니다.');
      }
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const deleteContentIndex = _categoryList.findIndex((thisItem) => thisItem.categoryId === Array.from(_checkedItem)[0]);
    if (deleteContentIndex === -1) return alert('삭제할 패밀리를 선택해 주세요.');
    const deleteContent = _categoryList[deleteContentIndex];
    console.log('deleteContent : ', deleteContent);
    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({
        ...deleteContent,
        categoryParentName: _clickedLevel4Category.categoryName,
      })}`,
      replace: true,
    });
  };

  return (
    <Content>
      <ContentHeader>
        <Title>패밀리 5</Title>
        <Buttons>
          {props.authority.indexOf('102-2') !== -1 ? (
            <>
              <button className='btn-add' onClick={actCreate}>추가</button>
              <button className='btn-edit' onClick={actUpdate}>수정</button>
              <button className='btn-delete' onClick={actDelete}>삭제</button>
            </>
          ) : null}
        </Buttons>
      </ContentHeader>

      <TableSection content={
        <table ref={CategoryLevel5Table}>
          <thead>
            <tr>
              {props.authority.indexOf('102-2') !== -1 ? (<th style={{ minWidth: '50px', width: '50px' }}></th>) : null}
              <th>패밀리 이름</th>
            </tr>
          </thead>
          <tbody data-level="5">
            {checkNullArray(_categoryList, []).map((category, index) => {
              return (
                <tr key={index + '_category_5'}>
                  {props.authority.indexOf('102-2') !== -1 ? (
                    <td style={{ minWidth: '50px', width: '50px' }}>
                      <input
                        type="checkBox"
                        name="categories_Level5"
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          handleCheckedItem(category.categoryId, isChecked);
                          if (isChecked) {
                            document.getElementsByName('categories_Level5').forEach((checkbox) => { checkbox.checked = false });
                            e.target.checked = true;
                          } else {
                            e.target.checked = false;
                          }
                        }}
                        onClick={(e) => { e.stopPropagation() }}
                      />
                    </td>
                  ) : null}
                  <td>{category?.categoryName}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      }
      ></TableSection>
    </Content>
  );
};

const CategoryManagementV2 = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_clickedLevel1Category, setClickedLevel1Category] = useState({});
  const [_clickedLevel2Category, setClickedLevel2Category] = useState({});
  const [_clickedLevel3Category, setClickedLevel3Category] = useState({});
  const [_clickedLevel4Category, setClickedLevel4Category] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('102') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => { setClickedLevel2Category(() => { return {} }) }, [_clickedLevel1Category]);
  useEffect(() => { setClickedLevel3Category(() => { return {} }) }, [_clickedLevel2Category]);
  useEffect(() => { setClickedLevel4Category(() => { return {} }) }, [_clickedLevel3Category]);
  useEffect(() => { }, [_clickedLevel4Category]);

  /* ====================================================================== #4 */

  /* ====================================================================== #5 */

  /* ====================================================================== #6 */

  return (
    <Grid2Body
      contents={
        <>
          <Section className="Main">
            <MainContents>
              <CategoryLevel1
                authority={_authority}
                clickedLevel1Category={_clickedLevel1Category}
                setClickedLevel1Category={setClickedLevel1Category}
              ></CategoryLevel1>

              <CategoryLevel2
                authority={_authority}
                clickedLevel1Category={_clickedLevel1Category}
                clickedLevel2Category={_clickedLevel2Category}
                setClickedLevel2Category={setClickedLevel2Category}
              ></CategoryLevel2>

              <CategoryLevel3
                authority={_authority}
                clickedLevel2Category={_clickedLevel2Category}
                clickedLevel3Category={_clickedLevel3Category}
                setClickedLevel3Category={setClickedLevel3Category}
              ></CategoryLevel3>

              <CategoryLevel4
                authority={_authority}
                clickedLevel3Category={_clickedLevel3Category}
                clickedLevel4Category={_clickedLevel4Category}
                setClickedLevel4Category={setClickedLevel4Category}
              ></CategoryLevel4>

              <CategoryLevel5
                authority={_authority}
                clickedLevel4Category={_clickedLevel4Category}
              ></CategoryLevel5>
            </MainContents>
          </Section>
        </>
      }
    />
  );
};

export default CategoryManagementV2;
