import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { publicBoard } from 'api/apis/platform/cluster/cluster';

import { checkEmptyNull } from 'components/checkValues/checkValues';

const MyBoard = ({
  modalType, modalData,
  update,
  close
}) => {
  const location = useLocation();
  const clusterId = location.pathname.split('/platform/cluster')[0];
  const clusterPath = clusterId + '/platform/cluster/mypage';

  const { clusterReducer } = useSelector(state => state);
  const { user } = clusterReducer;

  const [_body, setBody] = useState({ ...modalData });

  const dataKeys = [
    { key: 'title', title: '제목', },
    { key: 'detail', title: '게시글', },
  ];

  useEffect(() => { }, [_body]);

  const actSave = async (e) => { // create/update
    e.preventDefault();
    const id = Number(clusterId.replace('/', ''));
    const body = {
      // clusterId: id,
      companyId: user.companyId,
      ..._body,
    };

    if (!checkEmptyNull(body.title, false)) return alert('제목을 입력해주세요.');

    if (modalType.includes('create')) { // create
      await publicBoard.create(body).then(response => {
        if (response === undefined) return;
        console.log('publicBoard.create : ', response);
        update();
      })
    } else { // update
      delete body?.createdDate;
      delete body?.modifiedDate;

      await publicBoard.update(body.publicBoardId, body).then(response => {
        if (response === undefined) return;
        console.log('publicBoard.update : ', response);
        update();
      })
    }
  };
  const actDelete = async () => { // delete
    const confirmText = `해당 게시글을 삭제하시겠습니까?`;
    if (window.confirm(confirmText)) {
      await publicBoard.delete(_body.publicBoardId).then(response => {
        if (response === undefined) return;
        console.log('publicBoard.delete : ', response);
        update();
      })
    }
  };

  return (
    <section>
      <div className='section-form'>
        {dataKeys.map((dataKey, index) => {
          return (
            <div key={index + '_form-content'} className='form-content term'>
              <h4>{dataKey.title}</h4>
              {dataKey.key === 'detail' ? (<>
                <textarea
                  placeholder={`${dataKey.title}을(를) 입력해주세요.`}
                  disabled={modalType.endsWith('delete')}
                  defaultValue={_body[dataKey.key] || ''}
                  onInput={(e) => {
                    setBody((prev) => {
                      return { ...prev, [dataKey.key]: e.target.value }
                    })
                  }}
                />
              </>) : (<>
                <input
                  type='text'
                  placeholder={`${dataKey.title}을(를) 입력해주세요.`}
                  disabled={modalType.endsWith('delete')}
                  value={_body[dataKey.key] || ''}
                  onInput={(e) => {
                    setBody((prev) => {
                      return { ...prev, [dataKey.key]: e.target.value }
                    })
                  }}
                />
              </>)}
            </div>
          );
        })}
      </div>
      <div className='section-action'>
        <button className='btn-cancel' onClick={close}>취소</button>
        {!modalType.endsWith('delete') ? (<button className='btn-save' onClick={actSave}>저장</button>) : (<button className='btn-delete' onClick={actDelete}>삭제</button>)}
      </div>
    </section>
  );
};

export default MyBoard;