import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { getCookie, removeCookie } from 'preferences/cookie/cookie';

import { clusterActions_reset } from 'store/modules/actions/cluster/clusterActions';

import { FaRegUser } from "react-icons/fa6";
import { FiLogIn, FiLogOut } from "react-icons/fi";
import { LuHome } from "react-icons/lu";
import { ClusterHeaderComponent } from 'platform/cluster/styles/header.style';

const ClusterHeader = () => {
  const location = useLocation();
  const clusterId = location.pathname.split('/platform/cluster')[0];
  const clusterPath = clusterId + '/platform/cluster';

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLink = (type) => { // 페이지 이동
    switch (type) {
      case 'home': return navigate(`${clusterPath}`);
      case 'mypage': return navigate(`${clusterPath}/mypage`);
      case 'login': return navigate(`${clusterPath}/sign/login`);

      default: return;
    }
  };
  const actLogOut = (e) => { // 로그아웃
    e.preventDefault();
    console.log('================================= removeCookie');
    removeCookie('accessToken');
    removeCookie('refreshToken');
    dispatch(clusterActions_reset());

    setTimeout(() => {
      navigate(handleLink('login'));
    }, 1000);
  }

  return (<>
    <ClusterHeaderComponent>
      <div>
        <div>
          {getCookie('accessToken') ? <button onClick={(e) => { e.preventDefault(); handleLink('home'); }}><LuHome />홈</button> : null}
        </div>
        <div>
          {getCookie('accessToken') ? (<>
            <button onClick={(e) => { e.preventDefault(); handleLink('mypage'); }}><FaRegUser />마이페이지</button>
            <button onClick={actLogOut}><FiLogOut />로그아웃</button>
          </>) : (
            <button onClick={(e) => { e.preventDefault(); handleLink('login'); }}><FiLogIn />로그인</button>
          )}
        </div>
      </div>
    </ClusterHeaderComponent>
  </>);
};

export default ClusterHeader;