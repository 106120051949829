export const operatorActions_setIfisPageCode = (IfisPageCode) => { return { type: 'SETIFISPAGECODE', payload: IfisPageCode } };

export const operatorReducer_setUpdateCompany = (thisCompany) => { return { type: 'SETUPDATEIFISCOMPANY', payload: thisCompany } };
export const operatorReducer_setDeleteCompany = (thisCompany) => { return { type: 'SETDELETEIFISCOMPANY', payload: thisCompany } };

export const operatorReducer_setPageNumber = (pageNumber) => { return { type: 'SETOPERATORPAGENUMBER', payload: pageNumber } };
export const operatorReducer_setTotalSize = (totalSize) => { return { type: 'SETOPERATORTOTALSIZE', payload: totalSize } };

export const operatorActions_setCompany = (option) => { return { type: 'SETOPERATORCOMPANY', payload: option } };
export const operatorActions_setQnA = (option) => { return { type: 'SETOPERATORQNA', payload: option } };
export const operatorActions_setUser = (option) => { return { type: 'SETOPERATORUSER', payload: option } };