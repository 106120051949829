import React from 'react';

const BarcodeContents = (props) => {
  const { content, generateBarcode } = props;

  return (
    <>
      {(() => {
        switch (content.type) {
          case 'element': return (
            <>
              <img src={generateBarcode('A' + content.elementId)} />
              {/* <p>{'A' + content.elementId}</p>
            <p>물품이름: {content.elementName}</p>
            <p>물품코드: {content.elementCode}</p> */}
              <table>
                <colgroup><col width="70px"></col><col></col></colgroup>
                <tbody>
                  <tr><td colSpan={2} style={{ lineHeight: '1.5em' }}>{'A' + content.elementId}</td></tr>
                  <tr><td>물품이름 : </td><td>{content.elementName}</td></tr>
                  <tr><td>물품코드 : </td><td>{content.elementCode}</td></tr>
                </tbody>
              </table>
            </>
          );
          case 'stockLog': return (
            <>
              <img src={generateBarcode('B' + content.elementStockLogId)} />
              {/* <p>{'B' + content.elementStockLogId}</p>
            <p>물품이름: {content.element.elementName}</p>
            <p>물품코드: {content.element.elementCode}</p> */}
              <table>
                <colgroup><col width="70px"></col><col></col></colgroup>
                <tbody>
                  <tr><td colSpan={2} style={{ lineHeight: '1.5em' }}>{'B' + content.elementStockLogId}</td></tr>
                  <tr><td>물품이름 : </td><td>{content.element.elementName}</td></tr>
                  <tr><td>물품코드 : </td><td>{content.element.elementCode}</td></tr>
                </tbody>
              </table>
            </>
          );
          case 'lot': return (
            <>
              <img src={generateBarcode('C' + content.processResultId)} />
              {/* <p>{'C' + content.processResultId}</p>
            <p>공정코드: {content.processCode}</p>
            <p>공정이름: {content.processName}</p> */}
              <table>
                <colgroup><col width="70px"></col><col></col></colgroup>
                <tbody>
                  <tr><td colSpan={2} style={{ lineHeight: '1.5em' }}>{'C' + content.processResultId}</td></tr>
                  <tr><td>공정코드 : </td><td>{content.processCode}</td></tr>
                  <tr><td>공정이름 : </td><td>{content.processName}</td></tr>
                </tbody>
              </table>
            </>
          );
          case 'serial': return (
            <>
              <img src={generateBarcode('D' + content.processResultElementId)} />
              {/* <p>{'D' + content.processResultElementId}</p>
            <p>물품이름: {content.elementName}</p>
            <p>물품코드: {content.elementCode}</p> */}
              <table>
                <colgroup><col width="70px"></col><col></col></colgroup>
                <tbody>
                  <tr><td colSpan={2} style={{ lineHeight: '1.5em' }}>{'D' + content.processResultElementId}</td></tr>
                  <tr><td>물품이름 : </td><td>{content.elementName}</td></tr>
                  <tr><td>물품코드 : </td><td>{content.elementCode}</td></tr>
                </tbody>
              </table>
            </>
          );

          default: return null;
        }
      })()}
    </>
  );
};

export default BarcodeContents;