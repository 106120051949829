export const processActions_setProcessType = (processType) => {
  return {
    type: 'SETPROCESSTYPE',
    payload: processType,
  };
};

export const processActions_setProcessingType = (processingType) => {
  return {
    type: 'SETPROCESSINGTYPE',
    payload: processingType,
  };
};

export const processActions_setSearchData = (searchData) => {
  return {
    type: 'SETPROCESSSEARCHDATA',
    payload: searchData,
  };
};

export const processActions_setSearchOption = (searchOption) => {
  return {
    type: 'SETPROCESSSEARCHOPTION',
    payload: searchOption,
  };
};

export const processActions_setSearchText = (searchText) => {
  return {
    type: 'SETPROCESSSEARCHTEXT',
    payload: searchText,
  };
};

export const processActions_setPageNumber = (pageNumber) => {
  return {
    type: 'SETPROCESSPAGENUMBER',
    payload: pageNumber,
  };
};

export const processActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETPROCESSTOTALSIZE',
    payload: totalSize,
  };
};

export const processActions_setIsPushedSearchButton = (isPushedSearchButton) => {
  return {
    type: 'SETPROCESSISPUSHEDSEARCHBUTTON',
    payload: isPushedSearchButton,
  };
};

export const processActions_setReset = () => {
  return {
    type: 'SETPROCESS',
  };
};

export const processActions_setViewScale = (viewScale) => {
  return {
    type: 'SETPROCESSVIEWSCALE',
    payload: viewScale,
  };
};

export const processActions_setBookmark = (status) => {
  return {
    type: 'SETPROCESSBOOKMARK',
    payload: status,
  };
};
