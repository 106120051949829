import React, { useEffect, useState } from 'react';

import { BsFire } from 'react-icons/bs';
import { FireIconWrapper, SafetySection } from 'pages/safetyManagement/SafetyManagement.style';

const Fire = ({ logList }) => {
  const [_tempColor, setTempColor] = useState('#000000');
  const [_tempWord, setTempWord] = useState('데이터 없음');
  const [firstData, setFirstData] = useState(null);

  const getByTemp = (temp) => {
    if (temp === null || temp === undefined)
      return setTempColor('#000000'), setTempWord('데이터 없음'); // 데이터가 없을 때
    if (temp >= 60) return setTempColor('#FF0000'), setTempWord('위험'); // 온도 60 이상: 위험
    if (temp >= 50) return setTempColor('#FF9100'), setTempWord('주의'); // 온도 50 이상 60 미만: 주의
    return setTempColor('#00FF00'), setTempWord('안전'); // 온도 50 미만: 안전
  };

  useEffect(() => {
    // logList에서 온도 관련 데이터를 필터링해 첫 번째 데이터만 사용
    const tempData = logList.find((log) => log.name.includes('temp') || log.name.includes('온도'));
    setFirstData(tempData?.value);
    getByTemp(tempData?.value);
  }, [logList]);

  return (
    <SafetySection>
      <h3>화재 감지</h3>
      <div className="safety-group">
        <FireIconWrapper temperature={_tempColor}>
          <BsFire className="fire-icon" />
          <p className="temperature">
            {firstData !== null && firstData !== undefined
              ? `${firstData}℃`
              : ''}
          </p>
        </FireIconWrapper>
        <p className="fire-status">{_tempWord}</p>
      </div>
    </SafetySection>
  );
};

export default Fire;
