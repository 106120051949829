const initialState = {
  copyContents: null,
  deleteContents: null,
  updateContents: null,

  /* AttributeManagement */
  attributeType: 'Material',
  accountAttributeType: 'Customer',

  /* ProductInformation */
  baseInformationColumns: [],

  /* CurrentStock */
  stockItem: {},

  /* RemarkModal */
  remark: '',

  /* WorkOrder */
  workOrderContents: null,
};

const contentsReducer = (state = initialState, action) => {
  // console.log(state, action);
  switch (action.type) {
    case 'SETCOPYCONTENTS':
      return {
        ...state,
        copyContents: action.payload,
      };

    case 'SETDELETECONTENTS':
      return {
        ...state,
        deleteContents: action.payload,
      };

    case 'SETUPDATECONTENTS':
      return {
        ...state,
        updateContents: action.payload,
      };

    /* AttributeManagement */
    case 'SETATTRIBUTETYPE':
      return {
        ...state,
        attributeType: action.payload,
      };

    case 'SETACCOUNTATTRIBUTETYPE':
      return {
        ...state,
        accountAttributeType: action.payload,
      };

    /* ProductInformation */
    case 'SETBICOLUMNS':
      return {
        ...state,
        baseInformationColumns: action.payload,
      };

    /* CurrentStock */
    case 'SETSTOCKITEM':
      return {
        ...state,
        stockItem: action.payload,
      };

    /* RemarkModal */
    case 'SETREMARK':
      return {
        ...state,
        remark: action.payload,
      };

    /* WorkOrder */
    case 'SETWORKORDER':
      return {
        ...state,
        workOrderContents: action.payload,
      };
    /* Contract */
    case 'SETORDERS':
      return {
        ...state,
        ContractContents: action.payload,
      };

    case 'CONTENTSRESET':
      return initialState;

    default:
      return state;
  }
};

export default contentsReducer;
