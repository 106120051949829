export const userManagementActions_setSearchData = (searchData) => {
  return {
    type: 'SETUSERMANAGEMENTSEARCHDATA',
    payload: searchData,
  };
};

export const userManagementActions_setSearchOption = (searchOption) => {
  return {
    type: 'SETUSERMANAGEMENTSEARCHOPTION',
    payload: searchOption,
  };
};

export const userManagementActions_setSearchText = (searchText) => {
  return {
    type: 'SETUSERMANAGEMENTSEARCHTEXT',
    payload: searchText,
  };
};

export const userManagementActions_setPageNumber = (pageNumber) => {
  return {
    type: 'SETUSERMANAGEMENTPAGENUMBER',
    payload: pageNumber,
  };
};

export const userManagementActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETUSERMANAGEMENTTOTALSIZE',
    payload: totalSize,
  };
};

export const userManagementActions_setIsPushedSearchButton = (isPushedSearchButton) => {
  return {
    type: 'SETUSERMANAGEMENTISPUSHEDSEARCHBUTTON',
    payload: isPushedSearchButton,
  };
};

export const userManagementActions_setReset = () => {
  return {
    type: 'SETUSERMANAGEMENTRESET',
  };
};

export const userManagementActions_setViewScale = (viewScale) => {
  return {
    type: 'SETUSERMANAGEMENTVIEWSCALE',
    payload: viewScale,
  };
};
