import React from 'react';

import { ProjectManagementComponent } from './ProjectManagement.style';

const ProjectManagement = () => {
  return (
    <ProjectManagementComponent>

    </ProjectManagementComponent>
  );
};

export default ProjectManagement;