import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { bookmark } from 'api/apis/bookmark/bookmark';
import { elementAttributeApi } from 'api/apis/elementAttributeApi';
import { elementApi } from 'api/apis/elementApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { NaraElementModalComponent } from './NaraElementModal.style';

import Bookmark from 'components/icons/Bookmark';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import ElementModalNav from './component/ElementModalNav';
import PagingComponent from 'components/paging/PagingComponent';
import TableSection from 'components/layouts/table/TableSection';

const SelectNaraElements = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const ElementCheckBox = useRef();

  const [_onload, setOnload] = useState('unload');

  const [_elementAttrList, setElementAttrList] = useState([]);
  const [_elementAttrNameList, setElementAttrNameList] = useState({});

  const [_bookmark, setBookmark] = useState(true);
  const [_searchData, setSearchData] = useState({});
  const [_elementList, setElementList] = useState([]);
  const [_checkedItem, setCheckedItem] = useState([]);
  
  const [_pageNum, setPageNum] = useState(1);
  const [_pageCount, setPageCount] = useState(0);

  /* ====================================================================== #3 */
  useEffect(() => {
    getElementAttrs();
    getElementList(0, 'all');

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getElementList(_pageNum - 1, _searchData);
      ElementCheckBox.current.checked = false;
    }

    return () => { };
  }, [_pageNum]);

  useEffect(() => {
    if (_onload === 'loaded') {
      getElementList(0, _searchData);
      ElementCheckBox.current.checked = false;
    }

    return () => { };
  }, [_bookmark]);

  useEffect(() => { }, [
    _elementAttrList, _elementAttrNameList, 
    _checkedItem
  ]);

  /* ====================================================================== #4 */
  const getElementAttrs = async (elementType) => { // 물품 항목 리스트
    const BodyToPost = {companyId: userReducer.company.companyId};
    if (checkEmptyNull(elementType, false) && elementType !== 'all') {
      BodyToPost.elementType = elementType;
    }
    await elementAttributeApi.searchElementAttribute(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('getElementAttrs : ', response);
      setElementAttrList(() => { return response.data });
      const nameList = {};
      response.data.forEach((thisItem) => {
        nameList[thisItem.elementAttributeId] = thisItem.elementAttributeName;
      });
      setElementAttrNameList(() => { return nameList });
    });
  };

  const getElementList = async (page, searchData) => { // 물품 리스트
    let paging = `?page=${page}&size=10`;
    if (_bookmark) paging += '&sort=bookMark,DESC&sort=id,DESC';
    else paging += '&sort=id,DESC';
    const body = {
      companyId: userReducer.company.companyId,
      ...searchData
    };
    await elementApi.searchElement(paging, body).then((response) => {
      if (response === undefined) return;
      console.log('getElementList : ', response);
      setElementList(() => { return response.data.content });
      setPageCount(() => { return response.data.totalElements });
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (item, isChecked) => { // 물품 선택
    const checkedItem = [..._checkedItem];
    const checkedIndex = checkedItem.findIndex((thisItem) => thisItem.elementId === item.elementId);
    if (checkedIndex !== -1) {
      if (!isChecked) checkedItem.splice(checkedIndex, 1);
    } else {
      if (isChecked) checkedItem.push(item);
    }
    console.log('checkedItem : ', checkedItem);
    setCheckedItem(() => { return checkedItem });
  };

  const handleBookmark = async (element, index) => { // 북마크 이벤트
    await bookmark.elementBookmark(element.elementId).then(response => {
      if (response === undefined) return;
      console.log('handleBookmark : ', response);
      getElementList(_pageNum - 1, _searchData);
    })
  }

  const actSearch = async (searchData) => { // 검색
    setSearchData(() => {return searchData});
    setPageNum(() => { return 1 });
    await getElementAttrs(searchData?.elementType);
    await getElementList(0, searchData);
  };
  const actReset = async () => { // 초기화
    setSearchData(() => {return {}});
    setPageNum(() => { return 1 });
    await getElementAttrs();
    await getElementList(0, {});
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <NaraElementModalComponent>
          <section>
            <div className='header-area'>
              <div className='title'>물품 선택</div>
              <div className='close-btn' onClick={props.close}><CloseButtonIcon /></div>
            </div>

            <div className='main-area'>
              <ElementModalNav
                elementAttrList={_elementAttrList}
                elementAttrNameList={_elementAttrNameList}
                actSearch={actSearch}
                actReset={actReset}
              />

              <TableSection content={
                <table>
                  <thead>
                    <tr>
                      <th style={{ minWidth: '50px', width: '50px' }}>
                        <input
                          type="checkBox"
                          name="allElements"
                          ref={ElementCheckBox}
                          onChange={(e) => {
                            const isChecked = e.target.checked;

                            if (isChecked) { // 전체 선택
                              setCheckedItem((prev) => {
                                const prevData = [...prev];
                                const newData = _elementList.map((thisItem) => {
                                  const thisFindIndex = prevData.findIndex((thisIndex) => thisIndex.elementId === thisItem.elementId);
                                  if (thisFindIndex !== -1) return null;
                                  else return thisItem;
                                });
                                const filterData = newData.filter((thisItem) => thisItem !== null);
                                return [...prevData, ...filterData];
                              });

                              e.target.checked = true;
                            } else { // 전체 선택 해제
                              setCheckedItem((prev) => {
                                const prevData = prev.map((thisItem) => {
                                  const thisFindIndex = _elementList.findIndex((thisIndex) => thisIndex.elementId === thisItem.elementId);
                                  if (thisFindIndex !== -1) return null;
                                  else return thisItem;
                                });
                                const filterData = prevData.filter((thisItem) => thisItem !== null);
                                return filterData;
                              });

                              e.target.checked = false;
                            }
                          }}
                        />
                      </th>
                      <th style={{ minWidth: '50px', width: '50px' }}>
                        <Bookmark bookmark={_bookmark} clickEvent={() => {setBookmark(prev => { return !prev });}} />
                      </th>
                      <th>패밀리</th>
                      <th>물품이름</th>
                      <th>물품코드</th>
                      <th>나라장터<br />물품코드</th>
                      <th>단위</th>
                      <th>안전재고</th>
                      <th>단가</th>
                      {_elementAttrList.map((attr) => (
                        <th key={attr.elementAttributeId + '_elementAttrs'}>{attr.elementAttributeName}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {_elementList.map((element, index) => {
                      return (
                        <tr key={index + '_elements'}>
                          <td style={{ minWidth: '50px', width: '50px' }}>
                            <input
                              type="checkBox"
                              name="elements"
                              data-key={element.elementId}
                              checked={(() => {
                                const checkedId = _checkedItem ? _checkedItem.findIndex((thisIndex) => thisIndex.elementId === element.elementId) : -1;
                                if (checkedId !== -1) return true;
                                else return false;
                              })()}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                handleCheckedItem(element, isChecked);
                              }}
                              onClick={(e) => { e.stopPropagation() }}
                            />
                          </td>
                          <td style={{ minWidth: '50px', width: '50px' }}>
                            <Bookmark bookmark={checkEmptyNull(element.bookMark, false)} clickEvent={() => { handleBookmark(element, index) }} />
                          </td>
                          <td>{element.categoryPath}</td>
                          <td className={checkEmptyNull(element.elementName, false) && element.elementName.startsWith('*') ? 'outsourcingText' : null }>
                            {element.elementName}
                          </td>
                          <td>{element.elementCode}</td>
                          <td>{element.naraElementCode}</td>
                          <td>{element.elementUnit}</td>
                          <td>{checkEmptyNull(element.elementSafeStock, 0).toLocaleString() + ' ' + checkEmptyNull(element.elementUnit, '')}</td>
                          <td>{checkEmptyNull(element.price, 0).toLocaleString()}</td>
                          {checkNullArray(_elementAttrList, []).map((elementAttr) => {
                            const attrKey = checkNullArray(element.elementAttributeValueList, []).findIndex((item) => item?.elementAttribute.elementAttributeId === elementAttr.elementAttributeId);
                            if (attrKey !== -1) { // 항목 값이 있는 경우
                              return (
                                <td key={element.elementId + '_elementAttr_' + element.elementAttributeValueList[attrKey].elementAttributeId}>
                                  {element.elementAttributeValueList[attrKey].value}
                                </td>
                              );
                            } else { // 항목 값이 없음 >> 빈칸
                              return (<td key={element.elementId + '_elementAttr_' + elementAttr.elementAttributeId}></td>);
                            }
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
              ></TableSection>
            </div>

            <PagingComponent
              page={_pageNum}
              count={_pageCount}
              size={10}
              pageEvent={(page) => {setPageNum(() => { return page; });}}
            />

            <div className='footer-area'>
              <div onClick={() => { props.buttonEvent(_checkedItem) }}>{props.buttonTitle}</div>
            </div>
          </section>
        </NaraElementModalComponent>
      )}
    </>
  );
};

export default SelectNaraElements;
