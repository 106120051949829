import React from 'react';
import styled from 'styled-components';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import StatusButton from 'components/buttons/StatusButton';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 250px;
  justify-content: flex-start;
  width: 540px;
  min-width: 300px;
  overflow: hidden;

  width: ${(props) => {
    switch (props.status.toLowerCase()) {
      case 'waiting': return '400px';
      case 'work': return '800px';
      case 'pause': return '600px';
      case 'stop': return '400px';
      case 'inspection': return '400px';

      default: return '';
    }
  }};
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  box-sizing: border-box;
  column-gap: 10px;
  display: flex;
  height: calc(100% - 55px);
  justify-content: center;
  padding: 20px;
  width: 100%;
`;

const WorkEquipmentStatusModal = (props) => {
  const { equipment, statusEvent } = props;

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection status={equipment.status}>
            <ModalHeader>
              <ModalTitle>설비상태 설정</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              {equipment.status === 'waiting' ? (
                <>
                  <StatusButton
                    status={'working'}
                    modal={'equipment'}
                    statusEvent={() => { statusEvent(equipment, 'working') }}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    status={'inspection'}
                    statusEvent={() => { statusEvent(equipment, 'inspection') }}
                    statusStyle={{ cursor: 'pointer', fontSize: '30px' }}
                  ></StatusButton>
                </>
              ) : equipment.status === 'working' ? (
                <>
                  <StatusButton
                    status={'waiting'}
                    statusEvent={() => { statusEvent(equipment, 'waiting') }}
                    statusStyle={{ cursor: 'pointer', fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    status={'inspection'}
                    statusEvent={() => { statusEvent(equipment, 'inspection') }}
                    statusStyle={{ cursor: 'pointer', fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    status={'pause'}
                    statusEvent={() => { statusEvent(equipment, 'pause') }}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                </>
              ) : equipment.status === 'pause' ? (
                <>
                  <StatusButton
                    status={'waiting'}
                    statusEvent={() => { statusEvent(equipment, 'waiting') }}
                    statusStyle={{ cursor: 'pointer', fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    status={'working'}
                    modal={'equipment'}
                    statusEvent={() => { statusEvent(equipment, 'working') }}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    status={'inspection'}
                    statusEvent={() => { statusEvent(equipment, 'inspection') }}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                </>
              ) : equipment.status === 'inspection' ? (
                <>
                  <StatusButton
                    status={'waiting'}
                    statusEvent={() => { statusEvent(equipment, 'waiting') }}
                    statusStyle={{ cursor: 'pointer', fontSize: '30px' }}
                  ></StatusButton>
                  <StatusButton
                    status={'working'}
                    modal={'equipment'}
                    statusEvent={() => { statusEvent(equipment, 'working') }}
                    statusStyle={{ fontSize: '30px' }}
                  ></StatusButton>
                </>
              ) : null}
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default WorkEquipmentStatusModal;
