import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import styled from 'styled-components';

import { shipmentInspectionLog } from 'api/apis/shipmentInspection/shipmentInspectionLog';

import { checkEmptyNull, checkNullObject } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import SelectOneShipmentInspection from 'pages/shipmentInspection/modal/SelectOneShipmentInspection';
import SelectOneUser from 'pages/user/modal/SelectOneUser';
import TableSection from 'components/layouts/table/TableSection';
import NavText from 'components/nav/NavText';

const MainSection = styled.main`
  .TableSection {overflow: unset;}
  & .selectUser>.TableSection {overflow: unset;}
`;
const SelectUserButton = styled.td`
  background-color: var(--ThirdBlue);
  color: var(--white);
  cursor: pointer;
  height: 60px;
  text-align: center;
  vertical-align: middle;
`;
const DeleteButton = styled.div`
  height: 15px;
  margin: auto;
  width: 15px;
  & div {background-color: var(--MainRed); cursor: pointer; height: 20px; width: 20px;}
`;

const CreateShipmentInspection = () => {
  /* ========================================================================= #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    shipmentInspectionLogCode: '',
    shipmentInspectionLogName: '',
    shipmentInspectionItemLogList: [],

    shipmentInspectionLogDate: moment(new Date()).format('YYYY-MM-DD'),

    managerId: null,
    checkerId: null,
    approvalUserId: null,
  });

  const [_manager, setManager] = useState({});
  const [_checker, setChecker] = useState({});
  const [_approvalUser, setApprovalUser] = useState({});

  const [_inspectionItemList, setInspectionItemList] = useState([]);
  const [_inspectionNumberArray, setInspectionNumberArray] = useState([]);

  const [_modalStatus, setModalStatus] = useState(false);

  const [_userType, setUserType] = useState('');
  const [_userModalStatus, setUserModalStatus] = useState(false);

  /* ========================================================================= #3 */
  useEffect(() => {
    setInspectionNumberArray(() => {
      const numberArray = [];
      for (let i = 1; i < _inspectionItemList.length + 1; i++) {
        numberArray.push(i);
      }
      return numberArray;
    })
    return () => { }
  }, [_inspectionItemList])

  useEffect(() => { return () => { } }, [_inspectionNumberArray]);

  /* ========================================================================= #4 */

  /* ========================================================================= #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value }; });
  };
  const handleNumberEvent = (e, index, item) => {
    const newData = { ...item, number: BigNumber(e.target.value).toNumber() };
    setInspectionItemList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);
      prevData.splice(BigNumber(e.target.value).minus(1).toNumber(), 0, newData);
      const returnData = prevData.map((item, index) => {
        return { ...item, number: index + 1 };
      });
      return returnData;
    });
  };
  const deleteInspectioniItem = (e, index, item) => {
    setInspectionItemList((prev) => {
      const returnData = [...prev];
      returnData.splice(index, 1);
      return returnData;
    });
  }

  const actCreate = async (e) => {
    e.preventDefault();
    if (!checkEmptyNull(_formData.shipmentInspectionLogCode, false)) return alert('이름를 입력해 주세요.');
    if (!checkEmptyNull(_formData.shipmentInspectionLogName, false)) return alert('프로젝트를 입력해 주세요.');

    const shipmentInspectionItemLogList = _inspectionItemList?.map(item => {
      const returnData = {
        number: item.number,
        shipmentInspectionItemId: item.shipmentInspectionItemId,
        shipmentInspectionItemTypeName: checkNullObject(item.shipmentInspectionItem, false) ? item.shipmentInspectionItem.shipmentInspectionItemTypeName : item.shipmentInspectionItemTypeName,
        content: checkNullObject(item.shipmentInspectionItem, false) ? item.shipmentInspectionItem.content : item.content,
        criteria: checkNullObject(item.shipmentInspectionItem, false) ? item.shipmentInspectionItem.criteria : item.criteria,
        result: '',
      }
      return returnData;
    })
    const BodyToPost = {
      ..._formData,
      shipmentInspectionItemLogList: shipmentInspectionItemLogList,
    };
    if (checkEmptyNull(_manager.id, false)) { BodyToPost.managerId = _manager.id }
    if (checkEmptyNull(_checker.id, false)) { BodyToPost.checkerId = _checker.id }
    if (checkEmptyNull(_approvalUser.id, false)) { BodyToPost.approvalUserId = _approvalUser.id }

    await shipmentInspectionLog.createShipmentInspectionLog(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('shipmentInspectionLog.createShipmentInspectionLog : ', response);
      alert('추가했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    })
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <>
        <MainSection className="Main">
          <NavBar title={<NavTitle menuCode={'604'} />} nav={''} />

          <Form
            title={
              <>
                <NavText menuCode={'604'} />추가<br />
                <button className="formButton"
                  style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '25px', height: '50px', marginTop: '20px' }}
                  onClick={(e) => { e.preventDefault(); setModalStatus(true); }}
                >
                  <NavText menuCode={'604'} /><br />정의 선택
                </button>
              </>
            }
            buttons={
              <>
                <button className="formButton" onClick={actCreate}>저장</button>
                <button className="formButton cancle"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(pageReducer.currentPage, { replace: true });
                  }}
                >
                  취소
                </button>
              </>
            }
            forms={
              <>
                <div className="formBox">
                  <h4><NavText menuCode={'604'} /> 이름</h4>
                  <input
                    type="text"
                    name="shipmentInspectionLogCode"
                    placeholder="* 이름..."
                    data-required="required"
                    value={_formData.shipmentInspectionLogCode}
                    onInput={handleInputEvent}
                  />
                </div>
                <div className="formBox">
                  <h4>프로젝트</h4>
                  <input
                    type="text"
                    name="shipmentInspectionLogName"
                    placeholder="* 프로젝트..."
                    data-required="required"
                    value={_formData.shipmentInspectionLogName}
                    onInput={handleInputEvent}
                  />
                </div>
                <div className="formBox">
                  <h4>일자</h4>
                  <input
                    type="date"
                    name="shipmentInspectionLogDate"
                    value={_formData.shipmentInspectionLogDate}
                    onInput={handleInputEvent}
                  />
                </div>

                <div className="formBox selectUser w100">
                  <h4>담당자 / 검토자 / 승인자</h4>
                  <TableSection content={
                    <table>
                      <thead>
                        <tr>
                          <th>담당자</th>
                          <th>검토자</th>
                          <th>승인자</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{ height: '100px' }}>
                          <td>{checkEmptyNull(_manager.name, '')}</td>
                          <td>{checkEmptyNull(_checker.name, '')}</td>
                          <td>{checkEmptyNull(_approvalUser.name, '')}</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <SelectUserButton onClick={() => { setUserType(() => { return 'manager' }); setTimeout(setUserModalStatus(true), 1000); }}>담당자 선택</SelectUserButton>
                          <SelectUserButton onClick={() => { setUserType(() => { return 'checker' }); setTimeout(setUserModalStatus(true), 1000); }}>검토자 선택</SelectUserButton>
                          <SelectUserButton onClick={() => { setUserType(() => { return 'approvalUser' }); setTimeout(setUserModalStatus(true), 1000); }}>승인자 선택</SelectUserButton>
                        </tr>
                      </tfoot>
                    </table>
                  } />
                </div>

                <div className="formBox w100">
                  <h4>점검 / 검사 리스트</h4>
                  <TableSection content={
                    <table>
                      <thead>
                        <tr>
                          <th style={{ minWidth: '80px', width: '80px' }}>No.</th>
                          <th>점검 / 검사 항목</th>
                          <th>점검 / 검사 내용</th>
                          <th>판정 기준</th>
                          <th style={{ minWidth: '50px', width: '50px' }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {_inspectionItemList.map((item, index) => {
                          return (
                            <tr key={index + '_inspectionItems'}>
                              <td style={{ minWidth: '80px', width: '80px' }}>
                                <select
                                  name="number"
                                  value={item.number}
                                  onInput={(e) => { handleNumberEvent(e, index, item) }}
                                >
                                  {_inspectionNumberArray.map((number) => {
                                    return (<option key={index + '_inspectionNumber_' + number} value={number}>{number}</option>);
                                  })}
                                </select>
                              </td>
                              <td>{checkNullObject(item.shipmentInspectionItem, false) ? item.shipmentInspectionItem.shipmentInspectionItemTypeName : item.shipmentInspectionItemTypeName}</td>
                              <td>{checkNullObject(item.shipmentInspectionItem, false) ? item.shipmentInspectionItem.content : item.content}</td>
                              <td>{checkNullObject(item.shipmentInspectionItem, false) ? item.shipmentInspectionItem.criteria : item.criteria}</td>
                              <th style={{ minWidth: '50px', width: '50px' }}>
                                <DeleteButton onClick={(e) => { deleteInspectioniItem(e, index, item) }}><CloseButtonIcon /></DeleteButton>
                              </th>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  } />
                </div>
              </>
            }
          />
        </MainSection>

        {_modalStatus ? <>
          <SelectOneShipmentInspection
            buttonTitle={'선택'}
            buttonEvent={(data) => {
              console.log('data : ', data);
              setFormData(prev => { return { ...prev, shipmentInspectionLogCode: data.shipmentInspectionCode, shipmentInspectionLogName: data.shipmentInspectionName } })
              const shipmentInspectionItemConnectorList = [...data.shipmentInspectionItemConnectorList].map((item, index) => {
                return { ...item, number: index + 1 };
              });
              setInspectionItemList(() => { return shipmentInspectionItemConnectorList; })
              setTimeout(setModalStatus(false), 1000);
            }}
            open={_modalStatus}
            close={() => { setModalStatus(false) }}
          />
        </> : null}
        {checkEmptyNull(_userType, false) && _userModalStatus ? <>
          <SelectOneUser
            buttonTitle={'선택'}
            buttonEvent={(data) => {
              console.log('data : ', data);
              switch (_userType) {
                case 'manager': setManager(() => { return data }); break;
                case 'checker': setChecker(() => { return data }); break;
                case 'approvalUser': setApprovalUser(() => { return data }); break;
                default: return;
              }
              setTimeout(setUserModalStatus(false), 1000);
            }}
            open={_userModalStatus}
            close={() => { setUserModalStatus(false) }}
          />
        </> : null}
      </>
    }
    />
  );
};

export default CreateShipmentInspection;
