import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { accountApi } from 'api/apis/accountApi';

import { checkNullParse } from 'components/checkValues/checkValues';

import DeleteForm from 'components/layouts/form/DeleteForm';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';

const DeleteAccount = () => {
  /* ========================================================================= #1 */
  const navigate = useNavigate();
  const { pageReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();
  const deleteContent = checkNullParse(searchParams.get('deleteContent'), {});
  const { accountId, accountTypeName, accountCode, accountName } = deleteContent;

  /* ====================================================================== #3 */
  /* ========================================================================= #4 */
  const actDelete = async () => {
    await accountApi.deleteAccount(accountId).then((response) => {
      if (response === undefined) return;
      console.log('accountApi.deleteAccount : ', response);

      alert('거래처를 삭제했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  /* ====================================================================== #5 */
  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body
      contents={
        <>
          <main className="Main">
            <NavBar title={'거래처 설정'} nav={''} />

            <DeleteForm
              delteTitle={<>{accountTypeName}</>}
              deleteItem={
                <>
                  {'사업자등록번호 : ' + accountCode}<br />
                  {'회사명 : ' + accountName}<br />
                </>
              }
              deleteText={<>{accountTypeName} 정보가 삭제됩니다.</>}
              deleteButton={
                <>
                  <button className="formButton cancle"
                    onClick={() => {
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                  <button className="formButton delete"
                    onClick={() => {
                      if (window.confirm(`${accountTypeName} 정보를 삭제합니다.`)) actDelete();
                      else return;
                    }}
                  >
                    삭제
                  </button>
                </>
              }
            />
          </main>
        </>
      }
    />
  );
};

export default DeleteAccount;
