import React from 'react';
import ReactDOM from 'react-dom';

/* router */
import { BrowserRouter } from 'react-router-dom';

/* redux */
import { applyMiddleware, compose, createStore } from 'redux';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import promiseMiddleware from 'redux-promise';
import ReduxThunk from 'redux-thunk';
// import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';

/* store */
import allReducers from 'store/modules/reducers';

/* react-cookie */
import { CookiesProvider } from 'react-cookie';

/* css */
import 'index.css';

/* components */
import App from 'App';

/* redux config */
const persistConfig = {
  key: 'root',
  storage: storageSession,
};
const persisted = persistReducer(persistConfig, allReducers);
const store = createStore(persisted, compose(applyMiddleware(promiseMiddleware, ReduxThunk)));
const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <CookiesProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </CookiesProvider>
    </PersistGate>
  </Provider>,

  document.getElementById('root'),
);
