const initialState = {
  shipmentInspectionItemType: 'all',

  startDate: '',
  endDate: '',

  searchData: {},
  searchOption: '',
  searchText: '',

  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
}
const resetState = {
  shipmentInspectionItemType: 'all',

  startDate: '',
  endDate: '',

  searchData: {},
  searchOption: '',
  searchText: '',

  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const shipmentInspectionLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETSHIPMENTINSPECTIONLOGTYPE':
      return {
        ...state,
        shipmentInspectionItemType: action.payload,
      };
    case 'SETSHIPMENTINSPECTIONLOGSTARTDATE':
      return {
        ...state,
        startDate: action.payload,
      };
    case 'SETSHIPMENTINSPECTIONLOGENDDATE':
      return {
        ...state,
        endDate: action.payload,
      };
    case 'SETSHIPMENTINSPECTIONLOGSEARCHDATA':
      return {
        ...state,
        searchData: action.payload,
      };
    case 'SETSHIPMENTINSPECTIONLOGPAGENUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'SETSHIPMENTINSPECTIONLOGTOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };
    case 'SETSHIPMENTINSPECTIONLOGISPUSHEDSEARCHBUTTON':
      return {
        ...state,
        isPushedSearchButton: action.payload,
      };
    case 'SETSHIPMENTINSPECTIONLOGRESET':
      return {
        ...state,
        ...resetState,
      };
    default:
      return state;
  };
};

export default shipmentInspectionLogReducer;