import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ClusterSideBarComponent = styled.nav`
  align-content: start;
  border-inline-end: 1px solid #c6c6c6;
  box-sizing: border-box;
  display: grid;
  height: 100%;
  justify-items: safe;
  overflow-y: scroll;
  padding-inline-end: 30px;
  position: relative;
  width: 100%;

  &::-webkit-scrollbar {display: none;}

  & .platformTitle {
    background-color: var(--white);
    border-block-end: 1px solid #8e8e8e;
    box-sizing: border-box;
    display: inline-block;
    font-size: 21px;
    font-weight: 600;
    height: 100px;
    line-height: 100px;
    padding-inline-start: 8px;
    position: sticky;
    top: 0px;
    z-index: 10;
  }
`;
export const ClusterMenuComponent = styled.div`
  &.menu {
    display: inline-block;
    border-block-end: 1px solid #d8d8d8;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 17px;
    font-weight: 600;
    height: fit-content;
    min-height: 65px;
    line-height: 65px;
    width: 100%;
    
    &.active {
      background-color: #edf1f5;
      border-block: 3px solid #003675;
      color: #003675;
    }
    
    & .menu-title {
      box-sizing: border-box;
      height: 65px;
      /* padding-inline-start: 8px; */
      position: relative;
      width: 100%;

      &>svg {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  & .sub-container { // 하위 메뉴
    display: flex;
    flex-direction: column;

    & .menu {
      border: none;
      
      & .menu-title {
        background-color: var(--white);
      }
    }
  }
`;
