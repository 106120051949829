import React from 'react';
import styled from 'styled-components';

import { docButtonIcon } from 'components/icons/src';

const Icon = styled.div`
  background-color: #95989f;
  cursor: pointer;
  height: 28px;
  width:  28px;
  /* margin-left: 3px; */
  margin-top: 2px;  
  mask-image: url(${docButtonIcon});
  mask-repeat: no-repeat;
  mask-size: contain;

  --webkit-mask-image: url(${docButtonIcon});
  --webkit-mask-repeat: no-repeat;
  --webkit-mask-size: contain;

  &:hover {
    background-color: var(--MainNavy);
  }
`;

const DOCButtonIcon = (props) => {
  return <Icon name="DOCButtonIcon" style={props.style} />;
};

export default DOCButtonIcon;
