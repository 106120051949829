import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import styled from 'styled-components';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  /* max-height: 300px; */
  /* max-width: 400px; */
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: space-between;
  padding: 0px 60px 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  /* height: calc(100% - 55px); */
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
  }
`;

const MainContents = styled.div`
  /* background-color: var(--white); */
  box-sizing: border-box;
  /* column-gap: 10px; */
  /* display: flex; */
  height: 100%;
  justify-content: center;
  padding: 10px;
  width: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 4px;
  }
`;

const ExportSection = styled.div`
  /* border: 1px solid var(--gray-200); */
  box-sizing: border-box;
  margin: 30px auto;
  padding: 0px;

  height: 297mm;
  width: 210mm;
`;

const ContentSection = styled.div`
  /* border: 2px solid var(--black); */
  box-sizing: border-box;
  /* padding: 10px 20px; */
  width: 100%;
`;

const ContentHeader = styled.div`
  display: grid;
  grid-template-rows: 80px 100px 30px;
`;

const HeaderSignBox = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  /* padding: 0px 20px; */

  table {
    border-collapse: collapse;
    height: 100%;
    table-layout: fixed;
    width: 180px;

    td {
      border: 1px solid var(--black);
      font-size: 15px;
      height: 20px;
      /* min-width: 100px; */
      text-align: center;
    }
  }
`;

const HeaderTitle = styled.div`
  align-items: center;
  column-gap: 20px;
  display: flex;
  justify-content: center;

  & p {
    font-size: 28px;
    font-weight: 700;
  }
`;

const HeaderContents = styled.div`
  align-items: center;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
`;

const HeaderCon = styled.div`
  padding-bottom: 25px;
`;

const TeamBox = styled.div`
  align-items: center;
  column-gap: 10px;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  justify-content: center;
  width: 200px;
`;

const ContentTable = styled.div`
  width: 100%;

  table {
    border-collapse: collapse;
    max-width: 100%;
    /* table-layout: fixed; */
    width: 100%;

    td,
    th {
      border: 1px solid var(--gray-900);
      box-sizing: border-box;
      font-size: 0.7em;
      font-weight: 400;
      height: 30px;
      padding: 8px 3px;
      text-align: center;
      white-space: nowrap;
    }

    thead td,
    tbody td {
      max-height: 35px;
      white-space: unset;
      word-break: break-all;
    }
  }
`;

const ContentFooter = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 80px;
  justify-content: center;
  margin-top: 60px;
`;

const ContentBottom = styled.div`
  align-items: center;
  column-gap: 60px;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
`;

const UserBox = styled.div`
  column-gap: 10px;
  display: flex;
  font-size: 18px;
`;

const LogoBox = styled.div`
  /* background-color: yellow; */
  height: 100%;
  width: 280px;
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;

  &:first-child {
    background-color: var(--ThirdBlue);
  }
`;

const LoadingScreen = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  color: var(--white);
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const PrintContent = (props) => {
  const PringPage = `${props.currentPage} / ${props.totalPage}`;
  const PrintDatas = props.content;
  const PrintUser = props.user;

  const emptyDatas = [];
  for (let i = 0; i < parseFloat(20 - PrintDatas.length); i++) {
    const setEmptyData = {
      emptyNum: parseFloat(PrintDatas.length + 1 + i),
    };

    emptyDatas.push(setEmptyData);
  }

  return (
    <ExportSection name={props.name}>
      <ContentSection>
        <ContentHeader>
          <HeaderContents>
            <HeaderCon>{PringPage}</HeaderCon>
            <HeaderSignBox>
              <table>
                <tbody>
                  <tr>
                    <td rowSpan={2} style={{ width: '40px' }}>
                      결재
                    </td>
                    <td>담당</td>
                    <td>부서장</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </HeaderSignBox>
          </HeaderContents>
          <HeaderTitle>
            <p>공</p>
            <p>정</p>
            <p>이</p>
            <p>동</p>
            <p>표</p>
          </HeaderTitle>
          <HeaderContents>
            <HeaderCon style={{ fontSize: '18px' }}>
              날짜 : {moment().format('YYYY년 MM월 DD일(ddd)')}
            </HeaderCon>
            <HeaderCon>
              <TeamBox>
                <div>{PrintUser.deliveryTeam}</div>
                <div> → </div>
                <div>{PrintUser.receivingTeam}</div>
              </TeamBox>
            </HeaderCon>
          </HeaderContents>
        </ContentHeader>

        <ContentTable>
          <table>
            <tbody>
              <tr>
                <td>번호</td>
                <td>품번</td>
                <td>품명</td>
                <td>수량</td>
                <td>비고</td>
              </tr>

              {PrintDatas.map((thisData) => {
                const thisRowKey = thisData.element.elementId;
                const thisNum = (PrintDatas.indexOf(thisData) + 1)
                  .toString()
                  .padStart(2, '0');

                return (
                  <tr key={thisRowKey}>
                    <td style={{ minWidth: '40px', width: '40px' }}>
                      {thisNum}
                    </td>
                    <td /* style={ { minWidth: '150px', width: '150px' } } */>
                      {thisData.element.elementCode}
                    </td>
                    <td>{thisData.element.elementName}</td>
                    <td style={{ minWidth: '80px', width: '80px' }}>
                      {thisData.amount}
                    </td>
                    <td>{thisData.remark}</td>
                  </tr>
                );
              })}

              {emptyDatas.map((thisItem) => {
                const thisNum = thisItem.emptyNum.toString().padStart(2, '0');

                return (
                  <tr key={thisItem.emptyNum + '_emptyRow'}>
                    <td>{thisNum}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ maxWidth: '150px' }}></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </ContentTable>
        <ContentBottom>
          <UserBox>
            <div>품관 :</div>
            <div>{PrintUser.qualityCheckUser}</div>
          </UserBox>
          <UserBox>
            <div>전달자 :</div>
            <div>{PrintUser.deliveryUser}</div>
          </UserBox>
          <UserBox>
            <div>수신자 :</div>
            <div>{PrintUser.receivingUser}</div>
          </UserBox>
        </ContentBottom>

        <ContentFooter>
          <LogoBox></LogoBox>
        </ContentFooter>
      </ContentSection>
    </ExportSection>
  );
};

const DRPrintModal = (props) => {
  /* ========================================================================= #1 */
  const { daerimReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_originArray, setOriginArray] = useState([]);
  const [_printElementArray, setPrintElementArray] = useState([]);
  const [_printUserContent, setPrintUserContent] = useState([]);

  const [_loadingStatus, setLoadingStatus] = useState(false);

  /* ========================================================================= #3 */
  useEffect(() => {
    /* 물품 */
    const printContentElementList =
      daerimReducer.updateContent.forwardingLogElementList.map((thisResult) => {
        return thisResult;
      });

    /* 사용자 */
    const setPrintUserList = {
      deliveryTeam: daerimReducer.updateContent.deliveryTeam,
      deliveryUser:
        daerimReducer.updateContent.deliveryUser &&
        daerimReducer.updateContent.deliveryUser.name,
      receivingTeam: daerimReducer.updateContent.receivingTeam,
      receivingUser:
        daerimReducer.updateContent.receivingUser &&
        daerimReducer.updateContent.receivingUser.name,
      qualityCheckUser:
        daerimReducer.updateContent.qualityCheckUser &&
        daerimReducer.updateContent.qualityCheckUser.name,
    };

    const thisContent = [];
    if (
      printContentElementList === undefined ||
      printContentElementList === null
    )
      return;
    for (let i = 0; i < printContentElementList.length; i += 20) {
      thisContent.push(printContentElementList.slice(i, i + 20));
    }

    setOriginArray(printContentElementList);
    setPrintElementArray(thisContent);
    setPrintUserContent(setPrintUserList);
  }, []);

  /* ========================================================================= #4 */
  // const handlePartNum = (e) => {
  //   const thisEventNum = e.target.value;

  //   const thisContent = [];
  //   for(let i=0; i<_originArray.length; i+=thisEventNum) {
  //     thisContent.push(_originArray.slice(i, i + thisEventNum));
  //   }
  //   console.log('handlePartNum - thisContent : ', thisContent);
  //   setPrintElementArray(thisContent);
  // }

  /* ========================================================================= #5 */

  const acrPrintContent = () => {
    console.log('dd');
    console.log(document.getElementsByName('printContent'));

    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
    });

    let count = 0;
    document.getElementsByName('printContent').forEach(async (thisItem) => {
      const thisHeight = thisItem.offsetHeight;
      const thisWidth = thisItem.offsetWidth;
      const ratio = thisWidth / thisHeight;
      // console.log('BarcodeContents : ', thisItem, thisHeight, thisWidth, ratio);

      const option = {
        height: thisHeight,
        width: thisWidth,
      };

      await html2canvas(thisItem, option).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        // const imgWidth = 210;
        // const imgHeight = canvas.height * imgWidth / canvas.width;

        let width = doc.internal.pageSize.getWidth();
        // let height = doc.internal.pageSize.getHeight();
        let height = 280;
        width = ratio * height;

        const margin = 6;
        const position = 6;

        doc.addImage(imgData, 'jpeg', margin, position, width, height);
        if (count < document.getElementsByName('printContent').length - 1) {
          doc.addPage();
        }
      });

      count++;
      console.log(
        count === document.getElementsByName('printContent').length,
        count,
        document.getElementsByName('printContent').length,
      );
      if (count === document.getElementsByName('printContent').length) {
        if (document.getElementsByName('printContent').length > 10) {
          doc.save();
          setLoadingStatus(false);
        } else {
          window.open(doc.output('bloburl'));
          setLoadingStatus(false);
        }
      }
    });
  };

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>공정이동표 미리보기</ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              <MainContents>
                {_printElementArray.map((thisArray) => {
                  return (
                    <PrintContent
                      key={
                        _printElementArray.indexOf(thisArray) + 'printContent'
                      }
                      name="printContent"
                      totalPage={_printElementArray.length}
                      currentPage={_printElementArray.indexOf(thisArray) + 1}
                      content={thisArray}
                      user={_printUserContent}
                    />
                  );
                })}
              </MainContents>
            </ModalMain>

            <ModalFooter>
              <ModalButton
                onClick={() => {
                  setLoadingStatus(true);
                  setTimeout(acrPrintContent, 1000);
                }}
              >
                출력
              </ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
      ;
      {_loadingStatus === true ? (
        <LoadingScreen>파일 생성 중입니다...</LoadingScreen>
      ) : null}
    </>
  );
};

export default DRPrintModal;
