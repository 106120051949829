import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import ProgressBar from '@ramonak/react-progress-bar';
import styled from 'styled-components';

import { monitoringActions_setHalfFilter, monitoringActions_setMaterialFilter, monitoringActions_setMonitoringView, monitoringActions_setProductFilter, monitoringActions_setProductionFilter, monitoringActions_setQualityFilter } from 'store/modules/actions/common/monitoringActions';
import { workActions_setCurrentMessage } from 'store/modules/actions/default/workActions';

import { elementApi } from 'api/apis/elementApi';
import { qualityApi } from 'api/apis/qualityApi';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import { LoadingMsg } from 'components/loading/LoadingMsg';

import GridMonitoringBody from 'components/layouts/body/GridMonitoringBody';
import Header from 'components/header/Header';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';

const MonitoringBody = styled.div`
  height: ${props => {
    return props.onHeader ? 'calc(100svh - 60px)' : '100svh';
  }} !important;

  & .subContents {
    box-sizing: border-box;
    margin: unset;
    padding: 24px;
  }
`;

const MainContents = styled.div`
  display: grid;
  justify-content: flex-start;
  grid-template-columns: 35% 65%;
  height: 100%;
  overflow: hidden;
  width: 100%;

  .TableSection {
    box-shadow: 0 6px 6px 0 rgba(196, 196, 196, 0.8);
    table {
      thead {
        tr {
          th {
            padding: 8px;
            background-color: #586a85;
          }
          &:nth-of-type(1) {
            th {
              border: none;
              background-color: var(--MainNavy);
            }
          }
        }
      }
      tbody {
        tr {
          td {
            max-width: 10%;
            padding: 8px;
            white-space: normal;
          }
        }
      }
    }
  }
`;

const ConnectStatus = styled.div`
  background-color: ${(props) => {
    return props.connectStatus === 'connect'
      ? 'var(--MainGreen)'
      : 'var(--MainRed)';
  }};
  border-radius: 50%;
  cursor: pointer;
  width: 42px;
  height: 42px;
  position: fixed;
  right: 80px;
  bottom: 25px;
  z-index: 999;
`;
const TimeCount = styled.div`
  position: fixed;
  bottom: 25px;
  right: 130px;
  width: 100px;
  &>div>div, &>div>div>div {height: 42px !important;}
  &>div>div>div {background-color: var(--MainBlue) !important;}
`;

const TableTitle = styled.th`
  background-color: var(--ThirdBlue);
  padding: unset !important;
  position: relative;
  .elementStatus {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* height: 30px; */
    padding: 8px 24px;
    border: none;
    box-shadow: 0px 0px 28px 2px #333;
    background-color: var(--SubBlue);
  }
`;
const TableTitleContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* height: 30px; */
  padding: 8px 24px;
  border: none;
  box-shadow: 0px 0px 28px 2px #333;
`;
const SortContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  div {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    height: auto;
    width: auto;
    padding: 4px 8px;
    color: #bdbdbd;
    border-radius: 4px;
    font-size: 1rem;

    &.active {color: var(--white);}
    &:hover {color: var(--color-4);}
  }

`;

// 좌측 레이아웃
const StockContents = styled.div`
  display: grid;
  grid-template-rows: 0.05fr 0.440fr 0.550fr;

  &.single {grid-template-columns: repeat(3, 1fr); grid-template-rows: 100%;}

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}

  & .TableSection {
    box-shadow: 0 6px 6px 0 rgba(196, 196, 196, 0.8);
    /* height: 100%; */
    /* margin: 0px auto; */
    overflow: auto;
    /* width: 100%; */
    margin: 16px;
    border-radius: 8px;
    width: fit-content;
    min-width: 94%;
    max-width: 400px;
    margin-bottom: 0;

    tr {border: none;}
    td, th {max-width: 100px; min-width: unset; padding: unset;}
  }
  .monitoringStatus {
    padding: 16px;
    padding-bottom: 0px;
    white-space: nowrap;
    width: 98%; height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    /* min-width: 90%; */
    /* max-width: 400px; */
    li {
      width: 100%;
      padding: 10px;
      border-radius: 8px;
      text-align: center;
      background-color: var(--color-2);
      color: var(--white);
      span {
        font-size: 2rem;
      }
      &:nth-of-type(1),
      &:nth-of-type(2) {
        background-color: var(--SubBlue);
      }
    }
  }
`;
// 우측 레이아웃
const ProductionStatus = styled.div`
  background-color: var(--Bg);
  display: grid; gap: 16px 0;
  grid-template-rows: 0.49fr 0.485fr;

  &.single {grid-template-rows: 100%;}

  & .TableSection {
    box-shadow: 0 6px 6px 0 rgba(196, 196, 196, 0.8);
    height: 100%;
    margin: 16px 0;
    border-radius: 8px;
    width: fit-content;
    margin-right: 16px;
    min-width: 98%;
    max-width: 400px;
    margin-bottom: 0;

    tr {border: none;}
    td, th {max-width: 120px; min-width: unset; padding: unset;}
  }
`;
const ProcessSection = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  height: fit-content;
  width: 100%;
`;
const Process = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  font-size: 1em;
  font-weight: 400;
  height: fit-content;
  justify-content: center;
  padding: 2px;
  width: 100%;

  background-color: ${(props) => {
    switch (props.status) {
      case 'NO': return 'var(--MainNavy)';
      case 'wait': return 'var(--gray-200)';
      case 'work': return 'var(--WorkingColor)';
      case 'cut': return '#BCE55C';
      case 'welding': return '#9FC93C';
      case 'last': return '#689900';
      case 'end': return 'var(--ThirdBlue)';
      case 'stop': return 'var(--MainRed)';
      case 'pause': return 'var(--MainYellow)';
      case 'cancel': return 'var(--Bg)';

      default: return 'unset';
    }
  }};

  color: ${(props) => {
    return props.status.toLowerCase() === 'cancel'
      ? 'var(--gray-200)'
      : 'var(--white)';
  }};
`;
const ImportantList = styled.div`
  height: fit-content;
  width: 100%;
`;
const ImportantProcess = styled.div`
  align-items: center;
  box-sizing: border-box;
  color: var(--white);
  column-gap: 10px;
  display: flex;
  height: fit-content;
  justify-content: flex-start;
  width: 100%;

  background-color: ${(props) => {
    switch (props.status) {
      case 'NO': return 'var(--MainNavy)';
      case 'wait': return 'var(--gray-200)';
      case 'work': return 'var(--WorkingColor)';
      case 'cut': return '#BCE55C';
      case 'welding': return '#9FC93C';
      case 'last': return '#689900';
      case 'end': return 'var(--ThirdBlue)';
      case 'stop': return 'var(--MainRed)';
      case 'pause': return 'var(--MainYellow)';
      case 'cancel': return 'var(--Bg)';

      default: return 'unset';
    }
  }};
`;

const MSTotalMonitoring = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { monitoringReducer, userReducer, workReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_authority, setAuthority] = useState([]);

  const [_materialList, setMaterialList] = useState([]);
  const [_halfList, setHalfList] = useState([]);
  const [_productList, setProductList] = useState([]);
  const [_workOrderLogList, setWorkOrderLogList] = useState([]);
  const [_qualityLogList, setQualityLogList] = useState([]);

  const [_stockMaterialPage, setStockMaterialPage] = useState({
    page: 0,
    filterPage: 0,
    totalCount: 0,
  });
  const [_stockHalfPage, setStockHalfPage] = useState({
    page: 0,
    filterPage: 0,
    totalCount: 0,
  });
  const [_stockProductPage, setStockProductPage] = useState({
    page: 0,
    filterPage: 0,
    totalCount: 0,
  });
  const [_productionPage, setProductionPage] = useState({
    page: 0,
    filterPage: 0,
    totalCount: 0,
  });
  const [_qualityPage, setQualityPage] = useState({
    page: 0,
    filterPage: 0,
    totalCount: 0,
  });

  const [_stockMaterialTotalPage, setStockMaterialTotalPage] = useState(0);
  const [_stockHalfTotalPage, setStockHalfTotalPage] = useState(0);
  const [_stockProductTotalPage, setStockProductTotalPage] = useState(0);
  const [_productionTotalPage, setProductionTotalPage] = useState(0);
  const [_qualityTotalPage, setQualityTotalPage] = useState(0);
  const [_timeCounter, setTimeCounter] = useState(30);

  const [floatingMenu, setFloatingMenu] = useState(false);

  /* ====================================================================== #3 */
  const floatingBtn = () => {
    setFloatingMenu(true);
  }
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('104') === true,
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    setMaterialTable(0, monitoringReducer.materialFilter);
    setHalfTable(0, monitoringReducer.halfFilter);
    setProductTable(0, monitoringReducer.productFilter);
    setProductionTable(0, monitoringReducer.productionFilter);
    setQualityTable(0, monitoringReducer.qualityFilter);

    return () => { };
  }, []);

  const useInterval = (callback, delay) => {
    const savedCallback = useRef(); // 최근에 들어온 callback을 저장할 ref를 하나 만든다.
    useEffect(() => {
      savedCallback.current = callback; // callback이 바뀔 때마다 ref를 업데이트 해준다.
    }, [callback]);
    useEffect(() => {
      const tick = () => {
        savedCallback.current(); // tick이 실행되면 callback 함수를 실행시킨다.
      };
      if (delay !== null) {
        // 만약 delay가 null이 아니라면
        let id = setInterval(tick, delay); // delay에 맞추어 interval을 새로 실행시킨다.
        return () => clearInterval(id); // unmount될 때 clearInterval을 해준다.
      }
    }, [delay]); // delay가 바뀔 때마다 새로 실행된다.
  };
  useInterval(async () => {
    try {
      setStockMaterialPage((prev) => {
        if (prev.page >= parseFloat(_stockMaterialTotalPage / 8) - 1) {
          setMaterialTable(0, monitoringReducer.materialFilter);
          return { ...prev, page: 0 };
        }

        switch (monitoringReducer.materialFilter) {
          case 'all':
            setMaterialTable(prev.page + 1, monitoringReducer.materialFilter);
            break;
          default:
            setMaterialTable(prev.filterPage + 1, monitoringReducer.materialFilter);
            break;
        }

        return { ...prev, page: prev.page + 1 };
      });
      setStockHalfPage((prev) => {
        if (prev.page >= parseFloat(_stockHalfTotalPage / 8) - 1) {
          setHalfTable(0, monitoringReducer.halfFilter);
          return { ...prev, page: 0 };
        }

        switch (monitoringReducer.halfFilter) {
          case 'all':
            setHalfTable(prev.page + 1, monitoringReducer.halfFilter);
            break;
          default:
            setHalfTable(prev.filterPage + 1, monitoringReducer.halfFilter);
            break;
        }

        return { ...prev, page: prev.page + 1 };
      });
      setStockProductPage((prev) => {
        if (prev.page >= parseFloat(_stockProductTotalPage / 8) - 1) {
          setProductTable(0, monitoringReducer.productFilter);
          return { ...prev, page: 0 };
        }

        switch (monitoringReducer.productFilter) {
          case 'all':
            setProductTable(prev.page + 1, monitoringReducer.productFilter);
            break;
          default:
            setProductTable(prev.filterPage + 1, monitoringReducer.productFilter);
            break;
        }

        return { ...prev, page: prev.page + 1 };
      });
      // setProductionPage((prev) => {
      //   if (prev.page >= parseFloat(_productionTotalPage / 6) - 1) {
      //     setProductionTable(0, monitoringReducer.productionFilter);
      //     return { ...prev, page: 0 };
      //   }

      //   switch (monitoringReducer.productionFilter) {
      //     case 'all':
      //       setProductionTable(prev.page + 1, monitoringReducer.productionFilter);
      //       break;
      //     default:
      //       setProductionTable(prev.filterPage + 1, monitoringReducer.productionFilter);
      //       break;
      //   }

      //   return { ...prev, page: prev.page + 1 };
      // });
      setQualityPage((prev) => {
        if (prev.page >= parseFloat(_qualityTotalPage / 6) - 1) {
          setProductionTable(0, monitoringReducer.qualityFilter);
          return { ...prev, page: 0 };
        }

        switch (monitoringReducer.qualityFilter) {
          case 'all':
            setQualityTable(prev.page + 1, monitoringReducer.qualityFilter);
            break;
          default:
            setQualityTable(prev.filterPage + 1, monitoringReducer.qualityFilter);
            break;
        }

        return { ...prev, page: prev.page + 1 };
      });
    } finally {
      setTimeCounter(() => {
        return 30;
      });
    }
  }, 30000);
  useEffect(() => {
    const timeCount = setInterval(() => { setTimeCounter((prev) => prev - 1) }, 1000);
    return () => clearInterval(timeCount);
  }, [_timeCounter]);

  useEffect(() => {
    switch (monitoringReducer.monitoringView) {
      case 'stock':
        (async () => {
          console.log('monitoringReducer.monitoringView : ', monitoringReducer.monitoringView);
          switch (monitoringReducer.materialFilter) {
            case 'all':
              await setMaterialTable(_stockMaterialPage.page, monitoringReducer.materialFilter);
              break;

            default:
              await setMaterialTable(_stockMaterialPage.filterPage, monitoringReducer.materialFilter);
              break;
          }
          switch (monitoringReducer.halfFilter) {
            case 'all':
              await setHalfTable(_stockHalfPage.page, monitoringReducer.halfFilter);
              break;

            default:
              await setHalfTable(_stockHalfPage.filterPage, monitoringReducer.halfFilter);
              break;
          }
          switch (monitoringReducer.productFilter) {
            case 'all':
              await setProductTable(_stockProductPage.page, monitoringReducer.productFilter);
              break;

            default:
              await setProductTable(_stockProductPage.filterPage, monitoringReducer.productFilter);
              break;
          }
        })();
        return;

      default:
        (async () => {
          console.log('monitoringReducer.monitoringView : ', monitoringReducer.monitoringView);
          switch (monitoringReducer.materialFilter) {
            case 'all':
              await setMaterialTable(_stockMaterialPage.page, monitoringReducer.materialFilter);
              break;

            default:
              await setMaterialTable(_stockMaterialPage.filterPage, monitoringReducer.materialFilter);
              break;
          }
          switch (monitoringReducer.halfFilter) {
            case 'all':
              await setHalfTable(_stockHalfPage.page, monitoringReducer.halfFilter);
              break;

            default:
              await setHalfTable(_stockHalfPage.filterPage, monitoringReducer.halfFilter);
              break;
          }
          switch (monitoringReducer.productFilter) {
            case 'all':
              await setProductTable(_stockProductPage.page, monitoringReducer.productFilter);
              break;

            default:
              await setProductTable(_stockProductPage.filterPage, monitoringReducer.productFilter);
              break;
          }
        })();
        return;
    }
  }, [monitoringReducer.monitoringView]);

  useEffect(() => {
    if (workReducer.connectStatus === 'connect') {
      if (!checkNullObject(workReducer.currentMessage, false)) return;

      if (workReducer.currentMessage.userId === userReducer.user.id && checkNull(workReducer.currentMessage.errorMessage, false)) {
        dispatch(workActions_setCurrentMessage({}));
        return;
      }

      if (workReducer.currentMessage.type === '/work') {
        console.log('work');
        applyStatus();
      }
      if (workReducer.currentMessage.type === 'process') {
        console.log('process');
        applyProcessStatus();
      }
      if (workReducer.currentMessage.type === 'processes') {
        console.log('processes');
        applyProcessesStatus();
      }
    }

    return () => { };
  }, [workReducer.currentMessage]);

  /* ====================================================================== #4 */
  const setMaterialTable = async (page, status) => {
    const paging = `?page=${page}&size=${monitoringReducer.monitoringView === 'stock' ? 16 : 8}&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      elementType: 'material',
    };
    if (status === 'safeStock') {
      BodyToPost.lessThanSafeStock = true;
    }

    await elementApi.searchElementStockList(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('elementApi.searchElementStockList : ', response);

      setMaterialList(() => { return response.data.content; });
      setStockMaterialTotalPage(() => { return response.data.totalElements; });
    });
  };
  const setHalfTable = async (page, status) => {
    const paging = `?page=${page}&size=${monitoringReducer.monitoringView === 'stock' ? 16 : 8}&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      elementType: 'half',
    };
    if (status === 'safeStock') {
      BodyToPost.lessThanSafeStock = true;
    }

    await elementApi.searchElementStockList(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('elementApi.searchElementStockList : ', response);

      setHalfList(() => { return response.data.content; });
      setStockHalfTotalPage(() => { return response.data.totalElements; });
    });
  };
  const setProductTable = async (page, status) => {
    const paging = `?page=${page}&size=${monitoringReducer.monitoringView === 'stock' ? 16 : 8}&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      elementType: 'product',
    };
    if (status === 'stock') {
      BodyToPost.overThanZeroStock = true;
    }

    await elementApi.searchElementStockList(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('elementApi.searchElementStockList : ', response);

      setProductList(() => { return response.data.content; });
      setStockProductTotalPage(() => { return response.data.totalElements; });
    });
  };
  const setProductionTable = async (page, status) => {
    const paging = `?page=${page}&size=6&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      workOrderStatus: ['wait', 'work', 'pause', 'stop'],
    };
    if (status !== 'all') {
      BodyToPost.workOrderStatus = [status];
    }

    await workOrderLogApi.searchWorkOrderLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.searchWorkOrderLog : ', response);

      const workOrderLogList = [...response.data.data.content];
      const returnData = workOrderLogList.map((thisItem) => {
        const processLogList = thisItem.processLogList;
        if (checkNullArray(processLogList, false)) {
          processLogList.sort((a, b) => { return a.processNumber - b.processNumber; });
        }
        thisItem.processLogList = processLogList;
        return thisItem;
      });

      setWorkOrderLogList(() => { return returnData; });
      setProductionTotalPage(() => { return response.data.data.totalElements; });
    });
  };
  const setQualityTable = async (page, status) => {
    const paging = `?page=${page}&size=6&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      workOrderStatus: ['work', 'pause', 'stop', 'end', 'cancel'],
    };
    if (status !== 'all') {
      BodyToPost.workOrderStatus = [status];
    }

    await qualityApi.searchQualityWorkOrderLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('qualityApi.searchQualityWorkOrderLog : ', response);

      const qualityLogList = [...response.data.content];
      const returnData = qualityLogList.map((thisItem) => {
        const processLogList = thisItem.processLogList;
        if (checkNullArray(processLogList, false)) {
          processLogList.sort((a, b) => { return a.processNumber - b.processNumber; });
        }
        thisItem.processLogList = processLogList;
        return thisItem;
      });

      setQualityLogList(() => { return returnData; });
      setQualityTotalPage(() => { return response.data.totalElements; });
    });
  };

  const applyStatus = () => {
    const applyContent = workReducer.currentMessage;

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    if (eventWorkOrder === undefined) return;
    eventWorkOrder.workOrderStatus = applyContent.workOrderStatus;
    eventWorkOrder.managers = checkNullArray(applyContent.managers, []);
    eventWorkOrder.workers = checkNullArray(applyContent.workers, []);
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, eventWorkOrder);
    setWorkOrderLogList(eventWorkOrderList);
  };
  const applyProcessStatus = () => {
    const applyContent = workReducer.currentMessage;

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    if (eventWorkOrder === undefined) return;
    const eventProcessLogList = [...eventWorkOrder.processLogList];
    const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === applyContent.processLog.processLogId);
    const eventContent = eventProcessLogList[eventProcessLogIndex];
    eventContent.processStatus = applyContent.processLog.processStatus;
    eventContent.completedAmount = applyContent.processLog.completedAmount;
    eventContent.processAmount = applyContent.processLog.processAmount;
    eventContent.processingCount = applyContent.processLog.processingCount;
    eventContent.managers = checkNullArray(applyContent.managers, []);
    eventContent.workers = checkNullArray(applyContent.workers, []);

    if (checkNullArray(eventContent.processLogElementList, false)) {
      const eventResultElements = eventContent.processLogElementList.map((thisItem) => {
        const thisFindIndex = applyContent.processResultElements.findIndex((thisElement) => thisElement.processLogElementId === thisItem.processLogElementId);
        if (thisFindIndex === -1) return thisItem;

        const thisFindItem = applyContent.processResultElements[thisFindIndex];
        const returnData = { ...thisItem, ...thisFindItem };
        return returnData;
      },
      );
      eventContent.processLogElementList = eventResultElements;
    }
    eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
    const detailWorkOrder = {
      ...eventWorkOrder,
      processLogList: eventProcessLogList,
    };
    eventWorkOrderList.splice(eventWorkOrderIndex, 1, detailWorkOrder);

    setWorkOrderLogList(eventWorkOrderList);
  };
  const applyProcessesStatus = () => {
    const applyContent = workReducer.currentMessage;

    const eventWorkOrderList = [..._workOrderLogList];
    const eventWorkOrderIndex = eventWorkOrderList.findIndex((thisItem) => thisItem.workOrderLogId === applyContent.workOrderLogId);
    const eventWorkOrder = eventWorkOrderList[eventWorkOrderIndex];
    if (eventWorkOrder === undefined) return;

    const eventProcessLogList = [...eventWorkOrder.processLogList];
    if (checkNullArray(applyContent.socketProcessResults, false)) {
      applyContent.socketProcessResults.forEach((processResult) => {
        const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === processResult.processLog.processLogId);
        if (eventProcessLogIndex === -1) return;

        const eventContent = eventProcessLogList[eventProcessLogIndex];
        eventContent.processStatus = processResult.processLog.processStatus;
        eventContent.completedAmount = processResult.processLog.completedAmount;
        eventContent.processAmount = processResult.processLog.processAmount;
        eventContent.processingCount = processResult.processLog.processingCount;
        eventContent.managers = checkNullArray(processResult.managers, []);
        eventContent.workers = checkNullArray(processResult.workers, []);

        if (checkNullArray(eventContent.processLogElementList, false)) {
          const eventResultElements = eventContent.processLogElementList.map((thisItem) => {
            const thisFindIndex = processResult.processResultElements.findIndex((thisElement) => thisElement.processLogElementId === thisItem.processLogElementId);
            if (thisFindIndex === -1) return thisItem;

            const thisFindItem = processResult.processResultElements[thisFindIndex];
            const returnData = { ...thisItem, ...thisFindItem };
            return returnData;
          },
          );
          eventContent.processLogElementList = eventResultElements;
        }
        console.log('eventContent : ', eventContent);

        eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
      });
    } else {
      applyContent.processLogIds.forEach((processLogIds) => {
        const eventProcessLogIndex = eventProcessLogList.findIndex((thisItem) => thisItem.processLogId === processLogIds);
        if (eventProcessLogIndex === -1) return;

        const eventContent = eventProcessLogList[eventProcessLogIndex];
        eventContent.processStatus = applyContent.processStatus;
        eventProcessLogList.splice(eventProcessLogIndex, 1, eventContent);
      });
    }

    const detailWorkOrder = {
      ...eventWorkOrder,
      processLogList: eventProcessLogList,
    };

    eventWorkOrderList.splice(eventWorkOrderIndex, 1, detailWorkOrder);
    console.log('eventWorkOrderList : ', eventWorkOrderList);

    setWorkOrderLogList(eventWorkOrderList);
  };

  /* ====================================================================== #5 */
  const allChart = () => { dispatch(monitoringActions_setMonitoringView('all')); };
  const onlyStock = () => { dispatch(monitoringActions_setMonitoringView('stock')); };
  const onlyWorkOrder = () => { dispatch(monitoringActions_setMonitoringView('work')); };
  const onlyQuality = () => { dispatch(monitoringActions_setMonitoringView('quality')); };

  /* ====================================================================== #6 */
  // window.onkeydown = (e) => {
  //   const thisKeyCode = e.keyCode;
  //   if (thisKeyCode === 116) e.returnValue = false;
  // }; // F5 막기

  const [onHeader, setOnHeader] = useState(false)
  const clickHeader = (e) => {
    setOnHeader(!onHeader)
  }
  return (
    <>
      <GridMonitoringBody
      header={
        <>
          {onHeader ? (<Header />) : null }
        </>
      }
        contents={
          <>
            <MonitoringBody className='monitoringBody' onHeader={onHeader}>
              {/* 메뉴 > 모니터링 */}
              {monitoringReducer.monitoringView === 'all' ? (
                <MainContents>
                  {/* 좌측 레이아웃 */}
                  <StockContents>
                    <ul className='monitoringStatus'>
                      <li>
                        <span>{_materialList.length}</span>
                        <p>자재</p>
                      </li>
                      <li>
                        <span>{_halfList.length}</span>
                        <p>완제품</p>
                      </li>
                      <li>
                        <span>{_workOrderLogList.length}</span>
                        <p>작업</p>
                      </li>
                      <li>
                        <span>{_qualityLogList.length}</span>
                        <p>품질</p>
                      </li>
                    </ul>
                    <TableSection content={
                      <table>
                        <thead>
                          <tr>
                            <TableTitle colSpan={5}>
                              <div className='elementStatus'>
                                <p>자재 현황</p>
                                <SortContent>
                                  <div className={monitoringReducer.materialFilter === 'all' ? 'active' : ''}
                                    onClick={() => {
                                      dispatch(monitoringActions_setMaterialFilter('all'));
                                      setMaterialTable(_stockMaterialPage.page, 'all');
                                    }}
                                  >
                                    전체보기
                                  </div>
                                  <div className={monitoringReducer.materialFilter === 'safeStock' ? 'active' : ''}
                                    onClick={() => {
                                      dispatch(monitoringActions_setMaterialFilter('safeStock'));
                                      setMaterialTable(_stockMaterialPage.page, 'safeStock');
                                    }}
                                  >
                                    안전재고
                                  </div>
                                </SortContent>
                              </div>
                            </TableTitle>
                          </tr>
                          <tr>
                            {(() => {
                              const defaultColumns = [/* '패밀리',  */'물품이름', '물품코드'/* , '전일재고', '당일입고', '당일투입', '당일차감/출하', '안전재고', '투입예정수량' */, '현재고'];
                              const returnArray = [];
                              defaultColumns.forEach((column, index) => { returnArray.push(<th key={index + '_column'}>{column}</th>) });
                              return returnArray;
                            })()}
                            {/* {[1, 18, 19, 20, 21].includes(userReducer.company.companyId) ? <th>적정재고</th> : null} */}
                          </tr>
                        </thead>
                        <tbody>
                          {!checkNullArray(_materialList, false) ? LoadingMsg() :
                            checkNullArray(_materialList, []).map((stockElement, index) => {
                              const prevStock = BigNumber(checkEmptyNull(stockElement.elementStock, 0)).minus(BigNumber(checkEmptyNull(stockElement.todayInStock, 0)))
                                .plus(BigNumber(checkEmptyNull(stockElement.todayPutStock, 0))).plus(BigNumber(checkEmptyNull(stockElement.todayOutStock, 0))).toNumber();
                              const elementUnit = checkNull(stockElement.elementUnit, '');

                              return (<tr key={index + '_materialList'}>
                                {/* <td>{stockElement.categoryPath}</td> */}
                                <td className={checkEmptyNull(stockElement.elementName, false) && stockElement.elementName.startsWith('*') ? 'outsourcingText' : null}>
                                  {stockElement.elementName}
                                </td>
                                <td>{stockElement.elementCode}</td>
                                {/* <td>{checkEmptyNull(prevStock, 0).toLocaleString() + elementUnit}</td> */}
                                {/* <td>{checkEmptyNull(stockElement.todayInStock, 0).toLocaleString() + elementUnit}</td> */}
                                {/* <td>{checkEmptyNull(stockElement.todayPutStock, 0).toLocaleString() + elementUnit}</td> */}
                                {/* <td>{checkEmptyNull(stockElement.todayOutStock, 0).toLocaleString() + elementUnit}</td> */}
                                {/* <td>{checkEmptyNull(stockElement.elementSafeStock, 0).toLocaleString() + elementUnit}</td> */}
                                {/* <td>{checkEmptyNull(stockElement.scheduledInputStock, 0).toLocaleString() + elementUnit}</td> */}
                                <td className={checkEmptyNull(stockElement.elementSafeStock, false) ? stockElement.elementSafeStock <= stockElement.elementStock ? 'safeStock' : 'dangerStock' : ''}>
                                  {checkEmptyNull(stockElement.elementStock, 0).toLocaleString() + ' ' + elementUnit}
                                </td>
                                {/* {[1, 18, 19, 20, 21].includes(userReducer.company.companyId) ? <td></td> : null} */}
                              </tr>)
                            })}
                        </tbody>
                      </table>
                    }
                      sectionStyle={{ height: '100%' }}
                    ></TableSection>
                    <TableSection content={
                      <table>
                        <thead>
                          <tr>
                            <TableTitle colSpan={5}>
                              <div className='elementStatus'>
                                <p>완제품 현황</p>
                                <SortContent>
                                  <div className={monitoringReducer.productFilter === 'all' ? 'active' : ''}
                                    onClick={() => {
                                      dispatch(monitoringActions_setProductFilter('all'));
                                      setProductTable(_stockProductPage.page, 'all');
                                    }}
                                  >
                                    전체보기
                                  </div>
                                  <div className={monitoringReducer.productFilter === 'stock' ? 'active' : ''}
                                    onClick={() => {
                                      dispatch(monitoringActions_setProductFilter('stock'));
                                      setProductTable(_stockProductPage.page, 'stock');
                                    }}
                                  >
                                    재고
                                  </div>
                                </SortContent>
                              </div>
                            </TableTitle>
                          </tr>
                          <tr>
                            {(() => {
                              const defaultColumns = [/* '패밀리',  */'물품이름', '물품코드'/* , '전일재고', '당일입고', '당일차감/출하', '안전재고', '투입예정수량' */, '현재고'];
                              const returnArray = [];
                              defaultColumns.forEach((column, index) => { returnArray.push(<th key={index + '_column'}>{column}</th>) });
                              return returnArray;
                            })()}

                            {/* {[1, 18, 19, 20, 21].includes(userReducer.company.companyId) ? <th>적정재고</th> : null} */}
                          </tr>
                        </thead>
                        <tbody>
                          {!checkNullArray(_productList, false) ? LoadingMsg() :
                            checkNullArray(_productList, []).map((stockElement, index) => {
                              const prevStock = BigNumber(checkEmptyNull(stockElement.elementStock, 0)).minus(BigNumber(checkEmptyNull(stockElement.todayInStock, 0)))
                                .plus(BigNumber(checkEmptyNull(stockElement.todayPutStock, 0))).plus(BigNumber(checkEmptyNull(stockElement.todayOutStock, 0))).toNumber();
                              const elementUnit = checkNull(stockElement.elementUnit, '');

                              return (<tr key={index + '_productList'}>
                                {/* <td>{stockElement.categoryPath}</td> */}
                                <td className={checkEmptyNull(stockElement.elementName, false) && stockElement.elementName.startsWith('*') ? 'outsourcingText' : null}>
                                  {stockElement.elementName}
                                </td>
                                <td>{stockElement.elementCode}</td>
                                {/* <td>{checkEmptyNull(prevStock, 0).toLocaleString() + elementUnit}</td> */}
                                {/* <td>{checkEmptyNull(stockElement.todayInStock, 0).toLocaleString() + elementUnit}</td> */}
                                {/* <td>{checkEmptyNull(stockElement.todayOutStock, 0).toLocaleString() + elementUnit}</td> */}
                                {/* <td>{checkEmptyNull(stockElement.elementSafeStock, 0).toLocaleString() + elementUnit}</td> */}
                                {/* <td>{checkEmptyNull(stockElement.scheduledInputStock, 0).toLocaleString() + elementUnit}</td> */}
                                <td className={checkEmptyNull(stockElement.elementSafeStock, false) ? stockElement.elementSafeStock <= stockElement.elementStock ? 'safeStock' : 'dangerStock' : ''}>
                                  {checkEmptyNull(stockElement.elementStock, 0).toLocaleString() + ' ' + elementUnit}
                                </td>
                                {/* {[1, 18, 19, 20, 21].includes(userReducer.company.companyId) ? <td></td> : null} */}
                              </tr>)
                            })}
                        </tbody>
                      </table>
                    }
                      sectionStyle={{ height: '100%' }}
                    ></TableSection>
                  </StockContents>
                  {/* 우측 레이아웃 */}
                  <ProductionStatus>
                    <TableSection content={
                      <table>
                        <thead style={{ zIndex: '800' }}>
                          <tr>
                            <TableTitle colSpan={7}>
                              <TableTitleContent>
                                <p>작업 현황</p>
                                <SortContent>
                                  <div className={monitoringReducer.productionFilter === 'all' ? 'active' : ''}
                                    onClick={() => {
                                      dispatch(monitoringActions_setProductionFilter('all'));
                                      setProductionTable(_productionPage.page, 'all');
                                    }}
                                  >
                                    전체보기
                                  </div>
                                  <div className={monitoringReducer.productionFilter === 'wait' ? 'active' : ''}
                                    onClick={() => {
                                      dispatch(monitoringActions_setProductionFilter('wait'));
                                      setProductionPage((prev) => {
                                        return { ...prev, filterPage: 0 };
                                      });
                                      setProductionTable(0, 'wait');
                                    }}
                                  >
                                    대기
                                  </div>
                                  <div className={monitoringReducer.productionFilter === 'work' ? 'active' : ''}
                                    onClick={() => {
                                      dispatch(monitoringActions_setProductionFilter('work'));
                                      setProductionPage((prev) => {
                                        return { ...prev, filterPage: 0 };
                                      });
                                      setProductionTable(0, 'work');
                                    }}
                                  >
                                    작업 중
                                  </div>
                                  <div className={monitoringReducer.productionFilter === 'pause' ? 'active' : ''}
                                    onClick={() => {
                                      dispatch(monitoringActions_setProductionFilter('pause'));
                                      setProductionPage((prev) => {
                                        return { ...prev, filterPage: 0 };
                                      });
                                      setProductionTable(0, 'pause');
                                    }}
                                  >
                                    일시정지
                                  </div>
                                  <div className={monitoringReducer.productionFilter === 'stop' ? 'active' : ''}
                                    onClick={() => {
                                      dispatch(monitoringActions_setProductionFilter('stop'));
                                      setProductionPage((prev) => {
                                        return { ...prev, filterPage: 0 };
                                      });
                                      setProductionTable(0, 'stop');
                                    }}
                                  >
                                    중단
                                  </div>
                                </SortContent>
                              </TableTitleContent>
                            </TableTitle>
                          </tr>
                          <tr>
                            <th style={{ minWidth: '80px', padding: 'unset', width: '80px' }}>작업상태</th>
                            <th>작업유형</th>
                            <th>작업코드</th>
                            <th>작업이름</th>
                            {(() => {
                              if (_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) {
                                return <th>거래처</th>;
                              } else return null;
                            })()}
                            <th>생산목표수량</th>
                            <th>기간</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!checkNullArray(_workOrderLogList, false) ? LoadingMsg() :
                            checkNullArray(_workOrderLogList, []).map((thisItem, index) => {
                              let thisProcessList = [];
                              let importantProcessList = [];
                              if (checkNullArray(thisItem.processLogList, false)) {
                                const copyList = [...thisItem.processLogList];
                                copyList.sort((a, b) => { return a.processNumber - b.processNumber; });

                                thisProcessList = copyList;
                                const filterList = copyList.filter((process) => process.processStatus === 'pause' || process.processStatus === 'stop');
                                importantProcessList = filterList;
                              }

                              let colNum = 5;
                              if (_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) {
                                colNum = colNum + 1;
                              }

                              return (
                                <Fragment key={index + '_workOrderLog_all'}>
                                  <tr data-key={thisItem.workOrderLogId}>
                                    <StatusTdButton
                                      rowSpan={2}
                                      statusStyle={{ cursor: 'default', minWidth: '80px', width: '80px' }}
                                      status={thisItem.workOrderStatus}
                                    />
                                    <td>{thisItem.workOrderTypeName}</td>
                                    <td>{thisItem.workOrderCode}</td>
                                    <td>{thisItem.workOrderName}</td>
                                    {(() => {
                                      if (_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) {
                                        return (<td>{thisItem.accountName || ''}</td>);
                                      } else return null;
                                    })()}
                                    <td>{checkEmptyNull(thisItem.amount, 0).toLocaleString()}</td>
                                    <td>
                                      {checkEmptyNull(thisItem.workOrderScheduledStartDate, false) ? DateFormat(thisItem.workOrderScheduledStartDate) : ''}
                                      <br />
                                      {checkEmptyNull(thisItem.workOrderScheduledEndDate, false) ? ` ~ ${DateFormat(thisItem.workOrderScheduledEndDate)}` : ''}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td colSpan={colNum} style={{ height: '30px', padding: 'unset' }}>
                                      <ProcessSection>
                                        {thisProcessList.map((process, processIndex) => {
                                          return (
                                            <Process
                                              key={processIndex + '_processCell'}
                                              status={process.processStatus}
                                              title={`
  공정코드: ${checkNull(process.processCode, '')} 
  공정이름: ${checkNull(process.processName, '')}
                                            `}
                                            >
                                              {checkNull(process.processNumber, processIndex + 1)}
                                            </Process>
                                          );
                                        },
                                        )}
                                      </ProcessSection>
                                      <ImportantList>
                                        {importantProcessList.map((process, processIndex) => {
                                          return (
                                            <ImportantProcess
                                              key={processIndex + 'importantList'}
                                              status={process.processStatus}
                                            >
                                              <p style={{ width: '30px' }}>{checkNull(process.processNumber, processIndex + 1)}</p>
                                              <p>공정코드: {process.processCode}</p>
                                              <p>공정이름: {process.processName}</p>
                                              <p>
                                                {(() => {
                                                  if (checkNullArray(process.managers, false)) {
                                                    const managers = process.managers.map((worker) => worker.managerName);
                                                    return `책임자: ${managers.join('/')}`;
                                                  }
                                                })()}
                                              </p>
                                              <p>
                                                {(() => {
                                                  if (checkNullArray(process.workers, false)) {
                                                    const workers = process.workers.map((worker) => worker.workerName);
                                                    return `작업자: ${workers.join('/')}`;
                                                  }
                                                })()}
                                              </p>
                                            </ImportantProcess>
                                          );
                                        })}
                                      </ImportantList>
                                    </td>
                                  </tr>
                                </Fragment>
                              )
                            })}
                        </tbody>
                      </table>
                    }
                      sectionStyle={{ height: '100%' }}
                    ></TableSection>

                    <TableSection content={
                      <table>
                        <thead>
                          <tr>
                            <TableTitle colSpan={10}>
                              <TableTitleContent>
                                <p>품질 현황</p>
                                <SortContent>
                                  <div className={monitoringReducer.qualityFilter === 'all' ? 'active' : ''}
                                    onClick={() => {
                                      dispatch(monitoringActions_setQualityFilter('all'));
                                      setQualityTable(_productionPage.page, 'all');
                                    }}
                                  >
                                    전체보기
                                  </div>
                                  <div className={monitoringReducer.qualityFilter === 'work' ? 'active' : ''}
                                    onClick={() => {
                                      dispatch(monitoringActions_setQualityFilter('work'));
                                      setQualityPage((prev) => {
                                        return { ...prev, filterPage: 0 };
                                      });
                                      setQualityTable(0, 'work');
                                    }}
                                  >
                                    작업 중
                                  </div>
                                  <div className={monitoringReducer.qualityFilter === 'pause' ? 'active' : ''}
                                    onClick={() => {
                                      dispatch(monitoringActions_setQualityFilter('pause'));
                                      setQualityPage((prev) => {
                                        return { ...prev, filterPage: 0 };
                                      });
                                      setQualityTable(0, 'pause');
                                    }}
                                  >
                                    일시정지
                                  </div>
                                  <div className={monitoringReducer.qualityFilter === 'stop' ? 'active' : ''}
                                    onClick={() => {
                                      dispatch(monitoringActions_setQualityFilter('stop'));
                                      setQualityPage((prev) => {
                                        return { ...prev, filterPage: 0 };
                                      });
                                      setQualityTable(0, 'stop');
                                    }}
                                  >
                                    중단
                                  </div>
                                  <div className={monitoringReducer.qualityFilter === 'end' ? 'active' : ''}
                                    onClick={() => {
                                      dispatch(monitoringActions_setQualityFilter('end'));
                                      setQualityPage((prev) => {
                                        return { ...prev, filterPage: 0 };
                                      });
                                      setQualityTable(0, 'end');
                                    }}
                                  >
                                    완료
                                  </div>
                                  <div className={monitoringReducer.qualityFilter === 'cancel' ? 'active' : ''}
                                    onClick={() => {
                                      dispatch(monitoringActions_setQualityFilter('cancel'));
                                      setQualityPage((prev) => {
                                        return { ...prev, filterPage: 0 };
                                      });
                                      setQualityTable(0, 'cancel');
                                    }}
                                  >
                                    취소
                                  </div>
                                </SortContent>
                              </TableTitleContent>
                            </TableTitle>
                          </tr>
                          <tr>
                            <th rowSpan={2} style={{ minWidth: '80px', padding: 'unset', width: '80px' }}>작업상태</th>
                            <th rowSpan={2}>작업유형</th>
                            <th rowSpan={2}>작업코드</th>
                            <th rowSpan={2}>작업이름</th>
                            {(() => {
                              if (_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) return <th rowSpan={2}>거래처</th>;
                              else return null;
                            })()}
                            <th colSpan={5} style={{ backgroundColor: 'var(--ThirdBlue)' }}>생산목표품목</th>
                          </tr>
                          <tr>
                            <th>물품정보</th>
                            <th>목표 생산량</th>
                            <th style={{ backgroundColor: 'var(--MainBlue)' }}>총 생산량</th>
                            <th style={{ backgroundColor: 'var(--MainGreen)' }}>성공수량</th>
                            <th style={{ backgroundColor: 'var(--MainRed)' }}>실패수량</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!checkNullArray(_qualityLogList, false) ? LoadingMsg() :
                            checkNullArray(_qualityLogList, []).map((qualityLog, index) => {
                              const qualityTargetProduct = checkNullArray(qualityLog.qualityElementList, []);
                              const rowLength = qualityTargetProduct.length;

                              const returnItems = () => {
                                let returnArray = [];
                                for (let i = 1; i < rowLength; i++) {
                                  returnArray.push(
                                    <tr key={index + '_' + i + '_qualityTargetProduct'}>
                                      {qualityTargetProduct[i] ? (
                                        <Fragment>
                                          <td>
                                            {checkEmptyNull(qualityTargetProduct[i].elementName, '')}
                                            <br />
                                            {checkEmptyNull(qualityTargetProduct[i].elementCode, '')}
                                          </td>
                                          <td>{checkEmptyNull(qualityTargetProduct[i].baseAmount, 0).toLocaleString()}</td>
                                          <td>
                                            {checkEmptyNull(BigNumber(checkEmptyNull(qualityTargetProduct[i].makeAmount, 0))
                                              .plus(BigNumber(checkEmptyNull(qualityTargetProduct[i].failedAmount, 0))).toNumber(), 0).toLocaleString()}
                                          </td>
                                          <td>{checkEmptyNull(checkEmptyNull(qualityTargetProduct[i].makeAmount, 0).toLocaleString())}</td>
                                          <td>{checkEmptyNull(checkEmptyNull(qualityTargetProduct[i].failedAmount, 0).toLocaleString())}</td>
                                        </Fragment>
                                      ) : (
                                        <Fragment>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </Fragment>
                                      )}
                                    </tr>,
                                  );
                                  return returnArray;
                                }
                              };

                              return (<Fragment key={index + '_qualityLog'}>
                                <tr>
                                  <StatusTdButton
                                    rowSpan={rowLength === 0 ? 1 : rowLength}
                                    statusStyle={{ cursor: 'default', minWidth: '80px', width: '80px' }}
                                    status={qualityLog.workOrderStatus}
                                  />
                                  <td rowSpan={rowLength === 0 ? 1 : rowLength}>{qualityLog.workOrderTypeName}</td>
                                  <td rowSpan={rowLength === 0 ? 1 : rowLength}>{qualityLog.workOrderCode}</td>
                                  <td rowSpan={rowLength === 0 ? 1 : rowLength}>{qualityLog.workOrderName}</td>
                                  {(() => {
                                    if (_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) {
                                      return <td rowSpan={rowLength === 0 ? 1 : rowLength}>{checkNull(qualityLog.accountName, '')}</td>;
                                    } else {
                                      return null;
                                    }
                                  })()}
                                  {rowLength > 0 && checkNullObject(qualityTargetProduct[0], false) ? (
                                    <Fragment>
                                      <td>
                                        {checkEmptyNull(qualityTargetProduct[0].elementName, '')}
                                        <br />
                                        {checkEmptyNull(qualityTargetProduct[0].elementCode, '')}
                                      </td>
                                      <td>{checkEmptyNull(qualityTargetProduct[0].baseAmount, 0).toLocaleString()}</td>
                                      <td>
                                        {checkEmptyNull(BigNumber(checkEmptyNull(qualityTargetProduct[0].makeAmount, 0))
                                          .plus(BigNumber(checkEmptyNull(qualityTargetProduct[0].failedAmount, 0))).toNumber(), 0).toLocaleString()}
                                      </td>
                                      <td>{checkEmptyNull(checkEmptyNull(qualityTargetProduct[0].makeAmount, 0).toLocaleString())}</td>
                                      <td>{checkEmptyNull(checkEmptyNull(qualityTargetProduct[0].failedAmount, 0).toLocaleString())}</td>
                                    </Fragment>
                                  ) : (
                                    <Fragment>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                    </Fragment>
                                  )}
                                </tr>
                                {rowLength > 1 && returnItems()}
                              </Fragment>)
                            })}
                        </tbody>
                      </table>
                    }
                      sectionStyle={{ height: '100%' }}
                    ></TableSection>
                  </ProductionStatus>
                </MainContents>
              ) : null}
              {/* 메뉴 > 재고현황 */}
              {monitoringReducer.monitoringView === 'stock' ? (
                <div className='subContents'>
                  <TableSection content={
                    <table>
                      <thead>
                        <tr>
                          <TableTitle colSpan={6}>
                            <TableTitleContent>
                              <p>자재 재고현황</p>
                              <SortContent>
                                <div className={monitoringReducer.materialFilter === 'all' ? 'active' : ''}
                                  onClick={() => {
                                    dispatch(monitoringActions_setMaterialFilter('all'));
                                    setMaterialTable(_stockMaterialPage.page, 'all');
                                  }}
                                >
                                  전체보기
                                </div>
                                <div
                                  className={monitoringReducer.materialFilter === 'safeStock' ? 'active' : ''}
                                  onClick={() => {
                                    dispatch(monitoringActions_setMaterialFilter('safeStock'));
                                    setMaterialTable(_stockMaterialPage.page, 'safeStock');
                                  }}
                                >
                                  안전재고
                                </div>
                              </SortContent>
                            </TableTitleContent>
                          </TableTitle>
                        </tr>
                        <tr>
                          {(() => {
                            const defaultColumns = [/* '패밀리',  */'물품이름', '물품코드', /* '전일재고',  */'당일입고', '당일투입', '당일차감', /* '안전재고', '투입예정수량',  */'현재고'];
                            const returnArray = [];
                            defaultColumns.forEach((column, index) => { returnArray.push(<th key={index + '_column'}>{column}</th>) });
                            return returnArray;
                          })()}

                          {/* {[1, 18, 19, 20, 21].includes(userReducer.company.companyId) ? <th>적정재고</th> : null} */}
                        </tr>
                      </thead>
                      <tbody>
                        {!checkNullArray(_materialList, false) ? LoadingMsg() :
                          checkNullArray(_materialList, []).map((stockElement, index) => {
                            const prevStock = BigNumber(checkEmptyNull(stockElement.elementStock, 0)).minus(BigNumber(checkEmptyNull(stockElement.todayInStock, 0)))
                              .plus(BigNumber(checkEmptyNull(stockElement.todayPutStock, 0))).plus(BigNumber(checkEmptyNull(stockElement.todayOutStock, 0))).toNumber();
                            const elementUnit = checkNull(stockElement.elementUnit, '');

                            return (<tr key={index + '_materialList'}>
                              {/* <td>{stockElement.categoryPath}</td> */}
                              <td className={checkEmptyNull(stockElement.elementName, false) && stockElement.elementName.startsWith('*') ? 'outsourcingText' : null}>
                                {stockElement.elementName}
                              </td>
                              <td>{stockElement.elementCode}</td>
                              {/* <td>{checkEmptyNull(prevStock, 0).toLocaleString() + elementUnit}</td> */}
                              <td>{checkEmptyNull(stockElement.todayInStock, 0).toLocaleString() + elementUnit}</td>
                              <td>{checkEmptyNull(stockElement.todayPutStock, 0).toLocaleString() + elementUnit}</td>
                              <td>{checkEmptyNull(stockElement.todayOutStock, 0).toLocaleString() + elementUnit}</td>
                              {/* <td>{checkEmptyNull(stockElement.elementSafeStock, 0).toLocaleString() + elementUnit}</td> */}
                              {/* <td>{checkEmptyNull(stockElement.scheduledInputStock, 0).toLocaleString() + elementUnit}</td> */}
                              <td className={checkEmptyNull(stockElement.elementSafeStock, false) ? stockElement.elementSafeStock <= stockElement.elementStock ? 'safeStock' : 'dangerStock' : ''}>
                                {checkEmptyNull(stockElement.elementStock, 0).toLocaleString() + elementUnit}
                              </td>
                              {/* {[1, 18, 19, 20, 21].includes(userReducer.company.companyId) ? <td></td> : null} */}
                            </tr>)
                          })}
                      </tbody>
                    </table>
                  }
                    sectionStyle={{ height: '100%' }}
                  ></TableSection>
                  <TableSection content={
                    <table>
                      <thead>
                        <tr>
                          <TableTitle colSpan={6}>
                            <TableTitleContent>
                              <p>반제품 재고현황</p>
                              <SortContent>
                                <div className={monitoringReducer.halfFilter === 'all' ? 'active' : ''}
                                  onClick={() => {
                                    dispatch(monitoringActions_setHalfFilter('all'));
                                    setHalfTable(_stockHalfPage.page, 'all');
                                  }}
                                >
                                  전체보기
                                </div>
                                <div className={monitoringReducer.halfFilter === 'safeStock' ? 'active' : ''}
                                  onClick={() => {
                                    dispatch(monitoringActions_setHalfFilter('safeStock'));
                                    setHalfTable(_stockHalfPage.page, 'safeStock');
                                  }}
                                >
                                  안전재고
                                </div>
                              </SortContent>
                            </TableTitleContent>
                          </TableTitle>
                        </tr>
                        <tr>
                          {(() => {
                            const defaultColumns = [/* '패밀리',  */'물품이름', '물품코드', /* '전일재고',  */'당일입고', '당일투입', '당일차감', /* '안전재고', '투입예정수량',  */'현재고'];
                            const returnArray = [];
                            defaultColumns.forEach((column, index) => { returnArray.push(<th key={index + '_column'}>{column}</th>) });
                            return returnArray;
                          })()}

                          {/* {[1, 18, 19, 20, 21].includes(userReducer.company.companyId) ? <th>적정재고</th> : null} */}
                        </tr>
                      </thead>
                      <tbody>
                        {!checkNullArray(_halfList, false) ? LoadingMsg() :
                          checkNullArray(_halfList, []).map((stockElement, index) => {
                            const prevStock = BigNumber(checkEmptyNull(stockElement.elementStock, 0)).minus(BigNumber(checkEmptyNull(stockElement.todayInStock, 0)))
                              .plus(BigNumber(checkEmptyNull(stockElement.todayPutStock, 0))).plus(BigNumber(checkEmptyNull(stockElement.todayOutStock, 0))).toNumber();
                            const elementUnit = checkNull(stockElement.elementUnit, '');

                            return (<tr key={index + '_halfList'}>
                              {/* <td>{stockElement.categoryPath}</td> */}
                              <td className={checkEmptyNull(stockElement.elementName, false) && stockElement.elementName.startsWith('*') ? 'outsourcingText' : null}>
                                {stockElement.elementName}
                              </td>
                              <td>{stockElement.elementCode}</td>
                              {/* <td>{checkEmptyNull(prevStock, 0).toLocaleString() + elementUnit}</td> */}
                              <td>{checkEmptyNull(stockElement.todayInStock, 0).toLocaleString() + elementUnit}</td>
                              <td>{checkEmptyNull(stockElement.todayPutStock, 0).toLocaleString() + elementUnit}</td>
                              <td>{checkEmptyNull(stockElement.todayOutStock, 0).toLocaleString() + elementUnit}</td>
                              {/* <td>{checkEmptyNull(stockElement.elementSafeStock, 0).toLocaleString() + elementUnit}</td> */}
                              {/* <td>{checkEmptyNull(stockElement.scheduledInputStock, 0).toLocaleString() + elementUnit}</td> */}
                              <td className={checkEmptyNull(stockElement.elementSafeStock, false) ? stockElement.elementSafeStock <= stockElement.elementStock ? 'safeStock' : 'dangerStock' : ''}>
                                {checkEmptyNull(stockElement.elementStock, 0).toLocaleString() + elementUnit}
                              </td>
                              {/* {[1, 18, 19, 20, 21].includes(userReducer.company.companyId) ? <td></td> : null} */}
                            </tr>)
                          })}
                      </tbody>
                    </table>
                  }
                    sectionStyle={{ height: '100%' }}
                  ></TableSection>
                  <TableSection content={
                    <table>
                      <thead>
                        <tr>
                          <TableTitle colSpan={5}>
                            <TableTitleContent>
                              <p>완제품 재고현황</p>
                              <SortContent>
                                <div className={monitoringReducer.productFilter === 'all' ? 'active' : ''}
                                  onClick={() => {
                                    dispatch(monitoringActions_setProductFilter('all'));
                                    setProductTable(_stockProductPage.page, 'all');
                                  }}
                                >
                                  전체보기
                                </div>
                                <div
                                  className={monitoringReducer.productFilter === 'stock' ? 'active' : ''}
                                  onClick={() => {
                                    dispatch(monitoringActions_setProductFilter('stock'));
                                    setProductTable(_stockProductPage.page, 'stock');
                                  }}
                                >
                                  재고
                                </div>
                              </SortContent>
                            </TableTitleContent>
                          </TableTitle>
                        </tr>
                        <tr>
                          {(() => {
                            const defaultColumns = [/* '패밀리',  */'물품이름', '물품코드', /* '전일재고',  */'당일입고', '당일출하', /* '안전재고', '투입예정수량',  */'현재고'];
                            const returnArray = [];
                            defaultColumns.forEach((column, index) => { returnArray.push(<th key={index + '_column'}>{column}</th>) });
                            return returnArray;
                          })()}

                          {/* {[1, 18, 19, 20, 21].includes(userReducer.company.companyId) ? <th>적정재고</th> : null} */}
                        </tr>
                      </thead>
                      <tbody>
                        {!checkNullArray(_productList, false) ? LoadingMsg() :
                          checkNullArray(_productList, []).map((stockElement, index) => {
                            const prevStock = BigNumber(checkEmptyNull(stockElement.elementStock, 0)).minus(BigNumber(checkEmptyNull(stockElement.todayInStock, 0)))
                              .plus(BigNumber(checkEmptyNull(stockElement.todayPutStock, 0))).plus(BigNumber(checkEmptyNull(stockElement.todayOutStock, 0))).toNumber();
                            const elementUnit = checkNull(stockElement.elementUnit, '');

                            return (<tr key={index + '_productList'}>
                              {/* <td>{stockElement.categoryPath}</td> */}
                              <td className={checkEmptyNull(stockElement.elementName, false) && stockElement.elementName.startsWith('*') ? 'outsourcingText' : null}>
                                {stockElement.elementName}
                              </td>
                              <td>{stockElement.elementCode}</td>
                              {/* <td>{checkEmptyNull(prevStock, 0).toLocaleString() + elementUnit}</td> */}
                              <td>{checkEmptyNull(stockElement.todayInStock, 0).toLocaleString() + elementUnit}</td>
                              <td>{checkEmptyNull(stockElement.todayOutStock, 0).toLocaleString() + elementUnit}</td>
                              {/* <td>{checkEmptyNull(stockElement.elementSafeStock, 0).toLocaleString() + elementUnit}</td> */}
                              {/* <td>{checkEmptyNull(stockElement.scheduledInputStock, 0).toLocaleString() + elementUnit}</td> */}
                              <td className={checkEmptyNull(stockElement.elementSafeStock, false) ? stockElement.elementSafeStock <= stockElement.elementStock ? 'safeStock' : 'dangerStock' : ''}>
                                {checkEmptyNull(stockElement.elementStock, 0).toLocaleString() + elementUnit}
                              </td>
                              {/* {[1, 18, 19, 20, 21].includes(userReducer.company.companyId) ? <td></td> : null} */}
                            </tr>)
                          })}
                      </tbody>
                    </table>
                  }
                    sectionStyle={{ height: '100%' }}
                  ></TableSection>
                </div>
              ) : null}
              {/* 메뉴 > 작업현황 */}
              {monitoringReducer.monitoringView === 'work' ? (
                <div className='subContents'>
                  <TableSection content={
                    <table>
                      <thead style={{ zIndex: '800' }}>
                        <tr>
                          <TableTitle
                            colSpan={(() => {
                              let colNum = 11;
                              if (_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) colNum = colNum + 1;
                              return colNum;
                            })()}
                          >
                            <TableTitleContent>
                              <p>작업 현황</p>
                              <SortContent>
                                <div className={monitoringReducer.productionFilter === 'all' ? 'active' : ''}
                                  onClick={() => {
                                    dispatch(monitoringActions_setProductionFilter('all'));
                                    setProductionTable(_productionPage.page, 'all');
                                  }}
                                >
                                  전체보기
                                </div>
                                <div className={monitoringReducer.productionFilter === 'wait' ? 'active' : ''}
                                  onClick={() => {
                                    dispatch(monitoringActions_setProductionFilter('wait'));
                                    setProductionPage((prev) => {
                                      return { ...prev, filterPage: 0 };
                                    });
                                    setProductionTable(0, 'wait');
                                  }}
                                >
                                  대기
                                </div>
                                <div className={monitoringReducer.productionFilter === 'work' ? 'active' : ''}
                                  onClick={() => {
                                    dispatch(monitoringActions_setProductionFilter('work'));
                                    setProductionPage((prev) => {
                                      return { ...prev, filterPage: 0 };
                                    });
                                    setProductionTable(0, 'work');
                                  }}
                                >
                                  작업 중
                                </div>
                                <div className={monitoringReducer.productionFilter === 'pause' ? 'active' : ''}
                                  onClick={() => {
                                    dispatch(monitoringActions_setProductionFilter('pause'));
                                    setProductionPage((prev) => {
                                      return { ...prev, filterPage: 0 };
                                    });
                                    setProductionTable(0, 'pause');
                                  }}
                                >
                                  일시정지
                                </div>
                                <div className={monitoringReducer.productionFilter === 'stop' ? 'active' : ''}
                                  onClick={() => {
                                    dispatch(monitoringActions_setProductionFilter('stop'));
                                    setProductionPage((prev) => {
                                      return { ...prev, filterPage: 0 };
                                    });
                                    setProductionTable(0, 'stop');
                                  }}
                                >
                                  중단
                                </div>
                              </SortContent>
                            </TableTitleContent>
                          </TableTitle>
                        </tr>
                        <tr>
                          <th style={{ minWidth: '80px', padding: 'unset', width: '80px' }}>작업상태</th>
                          <th style={{ minWidth: '120px', width: '120px' }}>작업유형</th>
                          <th>작업코드</th>
                          <th>작업이름</th>
                          {(() => {
                            if (_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) return <th>거래처</th>;
                            else return null;
                          })()}
                          <th>생산목표수량</th>
                          <th>작업장소</th>
                          <th>기간</th>
                          <th>작성자</th>
                          <th>책임자</th>
                          <th>작업자</th>
                          <th>비고</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_workOrderLogList.map((thisItem, index) => {
                          let thisProcessList = [];
                          let importantProcessList = [];
                          if (checkNullArray(thisItem.processLogList, false)) {
                            const copyList = [...thisItem.processLogList];
                            copyList.sort((a, b) => { return a.processNumber - b.processNumber });

                            thisProcessList = copyList;
                            const filterList = copyList.filter((process) => process.processStatus === 'pause' || process.processStatus === 'stop');
                            importantProcessList = filterList;
                          }

                          let colNum = 10;
                          if (_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) colNum = colNum + 1;

                          return (<Fragment key={index + '_workOrderLog'}>
                            <tr data-key={thisItem.workOrderLogId}>
                              <StatusTdButton
                                rowSpan={2}
                                statusStyle={{ cursor: 'default', minWidth: '80px', width: '80px' }}
                                status={thisItem.workOrderStatus}
                              />
                              <td style={{ minWidth: '120px', width: '120px' }}>{thisItem.workOrderTypeName}</td>
                              <td>{thisItem.workOrderCode}</td>
                              <td>{thisItem.workOrderName}</td>
                              {(() => {
                                if (_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) return <td>{thisItem.accountName || ''}</td>;
                                else return null;
                              })()}
                              <td>{checkEmptyNull(thisItem.amount, 0).toLocaleString()}</td>
                              <td>{thisItem.workPlace}</td>
                              <td>
                                {checkEmptyNull(thisItem.workOrderScheduledStartDate, false) ? DateFormat(thisItem.workOrderScheduledStartDate) : ''}
                                <br />
                                {checkEmptyNull(thisItem.workOrderScheduledEndDate, false) ? ` ~ ${DateFormat(thisItem.workOrderScheduledEndDate)}` : ''}
                              </td>
                              <td>{thisItem.userName}</td>
                              <td>
                                {checkNullArray(thisItem.managers, []).map((worker, index) => {
                                  return (<p key={index + '_managers'}>{worker.managerName}</p>);
                                })}
                              </td>
                              <td>
                                {checkNullArray(thisItem.workers, []).map((worker, index) => {
                                  return (<p key={index + '_workers'}>{worker.workerName}</p>);
                                })}
                              </td>
                              <td>{thisItem.remark}</td>
                            </tr>

                            <tr>
                              <td
                                colSpan={(() => {
                                  let returnData = 11;
                                  if (_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) returnData = 12;
                                  return returnData;
                                })()}
                                style={{ height: '30px', padding: 'unset' }}
                              >
                                <ProcessSection>
                                  {thisProcessList.map((process, processIndex) => {
                                    return (<Process key={process.processLogId + '_processCell'}
                                      status={process.processStatus}
                                      title={`
  공정코드: ${checkNull(process.processCode, '')}
  공정이름: ${checkNull(process.processName, '')}
                                          `}
                                    >
                                      {checkNull(process.processNumber, processIndex + 1)}
                                    </Process>
                                    );
                                  },
                                  )}
                                </ProcessSection>
                                <ImportantList>
                                  {importantProcessList.map((process, processIndex) => {
                                    return (
                                      <ImportantProcess
                                        key={processIndex + 'importantList'}
                                        status={process.processStatus}
                                      >
                                        <p style={{ width: '30px' }}>{checkNull(process.processNumber, processIndex + 1)}</p>
                                        <p>공정코드: {process.processCode}</p>
                                        <p>공정이름: {process.processName}</p>
                                      </ImportantProcess>
                                    );
                                  },
                                  )}
                                </ImportantList>
                              </td>
                            </tr>
                          </Fragment>
                          );
                        })}
                      </tbody>
                    </table>
                  }
                    sectionStyle={{ height: '100%' }}
                  ></TableSection>
                </div>
              ) : null}
              {/* 메뉴 > 품질현황(불량현황) */}
              {monitoringReducer.monitoringView === 'quality' ? (
                <div className='subContents'>
                  <TableSection content={
                    <table>
                      <thead>
                        <tr>
                          <TableTitle colSpan={14}>
                            <TableTitleContent>
                              <p>품질현황</p>
                              <SortContent>
                                <div className={monitoringReducer.qualityFilter === 'all' ? 'active' : ''}
                                  onClick={() => {
                                    dispatch(monitoringActions_setQualityFilter('all'));
                                    setQualityTable(_productionPage.page, 'all');
                                  }}
                                >
                                  전체보기
                                </div>
                                <div className={monitoringReducer.qualityFilter === 'work' ? 'active' : ''}
                                  onClick={() => {
                                    dispatch(monitoringActions_setQualityFilter('work'));
                                    setQualityPage((prev) => {
                                      return { ...prev, filterPage: 0 };
                                    });
                                    setQualityTable(0, 'work');
                                  }}
                                >
                                  작업 중
                                </div>
                                <div className={monitoringReducer.qualityFilter === 'pause' ? 'active' : ''}
                                  onClick={() => {
                                    dispatch(monitoringActions_setQualityFilter('pause'));
                                    setQualityPage((prev) => {
                                      return { ...prev, filterPage: 0 };
                                    });
                                    setQualityTable(0, 'pause');
                                  }}
                                >
                                  일시정지
                                </div>
                                <div className={monitoringReducer.qualityFilter === 'stop' ? 'active' : ''}
                                  onClick={() => {
                                    dispatch(monitoringActions_setQualityFilter('stop'));
                                    setQualityPage((prev) => {
                                      return { ...prev, filterPage: 0 };
                                    });
                                    setQualityTable(0, 'stop');
                                  }}
                                >
                                  중단
                                </div>
                                <div className={monitoringReducer.qualityFilter === 'end' ? 'active' : ''}
                                  onClick={() => {
                                    dispatch(monitoringActions_setQualityFilter('end'));
                                    setQualityPage((prev) => {
                                      return { ...prev, filterPage: 0 };
                                    });
                                    setQualityTable(0, 'end');
                                  }}
                                >
                                  완료
                                </div>
                                <div className={monitoringReducer.qualityFilter === 'cancel' ? 'active' : ''}
                                  onClick={() => {
                                    dispatch(monitoringActions_setQualityFilter('cancel'));
                                    setQualityPage((prev) => {
                                      return { ...prev, filterPage: 0 };
                                    });
                                    setQualityTable(0, 'cancel');
                                  }}
                                >
                                  취소
                                </div>
                              </SortContent>
                            </TableTitleContent>
                          </TableTitle>
                        </tr>
                        <tr>
                          <th rowSpan={2} style={{ minWidth: '80px', padding: 'unset', width: '80px' }}>작업상태</th>
                          <th rowSpan={2}>작업유형</th>
                          <th rowSpan={2}>작업코드</th>
                          <th rowSpan={2}>작업이름</th>
                          {(() => {
                            if (_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) return <th rowSpan={2}>거래처</th>;
                            else return null;
                          })()}
                          <th colSpan={5} style={{ backgroundColor: 'var(--ThirdBlue)' }}>생산목표품목</th>
                          <th rowSpan={2}>작업기간</th>
                          <th rowSpan={2}>작성자</th>
                          <th rowSpan={2}>책임자</th>
                          <th rowSpan={2}>작업자</th>
                        </tr>
                        <tr>
                          <th>물품정보</th>
                          <th>목표 생산량</th>
                          <th style={{ backgroundColor: 'var(--MainBlue)' }}>총 생산량</th>
                          <th style={{ backgroundColor: 'var(--MainGreen)' }}>성공수량</th>
                          <th style={{ backgroundColor: 'var(--MainRed)' }}>실패수량</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!checkNullArray(_qualityLogList, false) ? LoadingMsg() :
                          checkNullArray(_qualityLogList, []).map((qualityLog, index) => {
                            const qualityTargetProduct = checkNullArray(qualityLog.qualityElementList, []);
                            const rowLength = qualityTargetProduct.length;

                            const returnItems = () => {
                              let returnArray = [];
                              for (let i = 1; i < rowLength; i++) {
                                returnArray.push(
                                  <tr key={index + '_' + i + '_qualityTargetProduct'}>
                                    {qualityTargetProduct[i] ? (
                                      <Fragment>
                                        <td>
                                          {checkEmptyNull(qualityTargetProduct[i].elementName, '')}
                                          <br />
                                          {checkEmptyNull(qualityTargetProduct[i].elementCode, '')}
                                        </td>
                                        <td>{checkEmptyNull(qualityTargetProduct[i].baseAmount, 0).toLocaleString()}</td>
                                        <td>
                                          {checkEmptyNull(BigNumber(checkEmptyNull(qualityTargetProduct[i].makeAmount, 0))
                                            .plus(BigNumber(checkEmptyNull(qualityTargetProduct[i].failedAmount, 0))).toNumber(), 0).toLocaleString()}
                                        </td>
                                        <td>{checkEmptyNull(checkEmptyNull(qualityTargetProduct[i].makeAmount, 0).toLocaleString())}</td>
                                        <td>{checkEmptyNull(checkEmptyNull(qualityTargetProduct[i].failedAmount, 0).toLocaleString())}</td>
                                      </Fragment>
                                    ) : (
                                      <Fragment>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                      </Fragment>
                                    )}
                                  </tr>,
                                );
                                return returnArray;
                              }
                            };

                            return (<Fragment key={index + '_qualityLog'}>
                              <tr>
                                <StatusTdButton
                                  rowSpan={rowLength === 0 ? 1 : rowLength}
                                  statusStyle={{ cursor: 'default', minWidth: '80px', width: '80px' }}
                                  status={qualityLog.workOrderStatus}
                                />
                                <td rowSpan={rowLength === 0 ? 1 : rowLength}>{qualityLog.workOrderTypeName}</td>
                                <td rowSpan={rowLength === 0 ? 1 : rowLength}>{qualityLog.workOrderCode}</td>
                                <td rowSpan={rowLength === 0 ? 1 : rowLength}>{qualityLog.workOrderName}</td>
                                {(() => {
                                  if (_authority.indexOf('104-1') !== -1 || _authority.indexOf('104-2') !== -1) {
                                    return <td rowSpan={rowLength === 0 ? 1 : rowLength}>{checkNull(qualityLog.accountName, '')}</td>;
                                  } else {
                                    return null;
                                  }
                                })()}
                                {rowLength > 0 && checkNullObject(qualityTargetProduct[0], false) ? (
                                  <Fragment>
                                    <td>
                                      {checkEmptyNull(qualityTargetProduct[0].elementName, '')}
                                      <br />
                                      {checkEmptyNull(qualityTargetProduct[0].elementCode, '')}
                                    </td>
                                    <td>{checkEmptyNull(qualityTargetProduct[0].baseAmount, 0).toLocaleString()}</td>
                                    <td>
                                      {checkEmptyNull(BigNumber(checkEmptyNull(qualityTargetProduct[0].makeAmount, 0))
                                        .plus(BigNumber(checkEmptyNull(qualityTargetProduct[0].failedAmount, 0))).toNumber(), 0).toLocaleString()}
                                    </td>
                                    <td>{checkEmptyNull(checkEmptyNull(qualityTargetProduct[0].makeAmount, 0).toLocaleString())}</td>
                                    <td>{checkEmptyNull(checkEmptyNull(qualityTargetProduct[0].failedAmount, 0).toLocaleString())}</td>
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </Fragment>
                                )}

                                <td rowSpan={rowLength === 0 ? 1 : rowLength}>
                                  {checkNull(qualityLog.workOrderActualStartDate, false) ? DateFormat(qualityLog.workOrderActualStartDate) : ''}
                                  <br /> ~{' '}{checkNull(qualityLog.workOrderActualEndDate, false) ? DateFormat(qualityLog.workOrderActualEndDate) : ''}
                                </td>
                                <td rowSpan={rowLength === 0 ? 1 : rowLength}>{qualityLog.userName}</td>
                                <td rowSpan={rowLength === 0 ? 1 : rowLength}>
                                  {checkNullArray(qualityLog.managers, []).map((worker, managerIndex) => {
                                    return (<p key={index + '_' + managerIndex + '_managers'}>{worker.managerName}</p>);
                                  })}
                                </td>
                                <td rowSpan={rowLength === 0 ? 1 : rowLength}>
                                  {checkNullArray(qualityLog.workers, []).map((worker, workerIndex) => {
                                    return (<p key={index + '_' + workerIndex + '_workers'}>{worker.workerName}</p>);
                                  })}
                                </td>
                              </tr>
                              {rowLength > 1 && returnItems()}
                            </Fragment>)
                          })}
                      </tbody>
                    </table>
                  }
                    sectionStyle={{ height: '100%' }}
                  ></TableSection>
                </div>
              ) : null}
            </MonitoringBody>
          </>
        }
      />
      {/* 플로팅 메뉴 */}
      {floatingMenu ? (
        <div className='drop-items'>
          <button onClick={clickHeader} data-name='on'>전체메뉴 {onHeader ? '닫기' : '열기'}</button>
          <hr />
          <button onClick={allChart}>전체현황</button>
          <button onClick={onlyStock}>재고현황</button>
          <button onClick={onlyWorkOrder}>작업현황</button>
          <button onClick={onlyQuality}>품질현황</button>
          <hr />
          <button onClick={() => setFloatingMenu(false)}>닫기</button>
        </div>
      ):
        <div className='drop-items' style={{padding: '16px 12px', borderRadius: '38px'}}>
          <button onClick={floatingBtn}>메뉴</button>
        </div>
      }
      <ConnectStatus
        connectStatus={workReducer.connectStatus}
        onClick={() => {navigate(0);}}
      ></ConnectStatus>
      <TimeCount>
        <ProgressBar
          completed={_timeCounter}
          maxCompleted={30}
          customLabel={' '}
        />
      </TimeCount>
    </>
  );
};

export default MSTotalMonitoring;
