import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat';

import ElementInfo from 'pages/barcode/scan/infos/info/ElementInfo';
import StockLogInfo from 'pages/barcode/scan/infos/info/StockLogInfo';

const StockLog = () => {
  const { barcodeReducer } = useSelector(state => state);

  const [_elementData, setElementData] = useState(barcodeReducer.barcodeContent.element);
  
  const setElementTypeText = (elementType) => {
    let typeText = '';
    switch (elementType) {
      case 'material': typeText = '자재'; break;
      case 'semi': typeText = '부자재'; break;
      case 'half': typeText = '반제품'; break;
      case 'product': typeText = '완제품'; break;
      case 'tool': typeText = '공구'; break;

      default: return null;
    }
    return typeText;
  }
  const handleElementConData = (data) => {
    const attrValueList = checkNullArray(data.elementAttributeValueList, []).map(attr => {
      return { key: attr.elementAttribute ? attr.elementAttribute.elementAttributeName : '', value: attr.value }
    })
    const returnData = [
      { key: '패밀리', value: data.categoryPath },
      { key: '물품유형', value: setElementTypeText(data.elementType) },
      { key: '물품이름', value: data.elementName },
      { key: '물품코드', value: data.elementCode },
      { key: '재고단위', value: data.elementUnit },
      { key: '단가', value: checkEmptyNull(data.price, 0).toLocaleString() },
      ...attrValueList
    ]
    return returnData;
  }
  const [_elementConData, setElementConData] = useState(handleElementConData(barcodeReducer.barcodeContent.element));

  const handleStockData = (data) => {
    const stockStatus = checkEmptyNull(data.elementSafeStock, false) ? data.elementSafeStock <= data.elementStock ? 'safeStock' : 'dangerStock' : '';
    const returnData = [
      // { key: '당일입고', value: checkEmptyNull(data.todayInStock, 0).toLocaleString() },
      // { key: '당일투입', value: checkEmptyNull(data.todayPutStock, 0).toLocaleString() },
      // { key: '당일출고', value: checkEmptyNull(data.todayOutStock, 0).toLocaleString() },
      { key: '안전재고', value: checkEmptyNull(data.elementSafeStock, 0).toLocaleString() },
      { key: '투입예정수량', value: checkEmptyNull(data.scheduledInputStock, 0).toLocaleString() },
      { key: '현재고', value: checkEmptyNull(data.elementStock, 0).toLocaleString(), status: stockStatus },
    ];
    return returnData;
  }
  const [_stockData, setStockData] = useState(handleStockData(barcodeReducer.barcodeContent.element))

  const [_stockLogData, setStockLogData] = useState(barcodeReducer.barcodeContent);
  const handleLogType = (logType) => {
    switch(logType) {
      case 'In': return '입고';
      case 'Put': return '투입';
      case 'Make': return '생산';
      case 'Out': return '출고';

      default: return '수량';
    }
  }
  const handleStockLogConData = (data) => {
    const returnData = [
      { key: '입력시각', value: DateTimeFormat(checkEmptyNull(data.createdDate, '')) },
      { key: handleLogType(data.logType), value: checkEmptyNull(data.amount, 0).toLocaleString(), logType: data.logType },
      { key: '금액', value: checkEmptyNull(data.price, 0).toLocaleString() },
      { key: '재고위치', value: checkEmptyNull(data.stockPlacedName, '') },
      { key: '비고', value: checkEmptyNull(data.note, '') },
      { key: '작업자', value: checkEmptyNull(data.userName, '') },
    ];
    return returnData;
  }
  const [_stockLogConData, setStockLogConData] = useState(handleStockLogConData(barcodeReducer.barcodeContent));
  console.log('_stockLogConData : ', _stockLogConData);

  const [_currentInfo, setCurrentInfo] = useState('stockLog');

  useEffect(() => {
    console.log('StockLog : ', barcodeReducer.barcodeContent);
    setElementData(() => { return barcodeReducer.barcodeContent.element });
    setElementConData(() => { return handleElementConData(barcodeReducer.barcodeContent.element) });
    setStockData(() => { return handleStockData(barcodeReducer.barcodeContent.element) });

    setStockLogData(() => {return barcodeReducer.barcodeContent});
    setStockLogConData(() => {return handleStockLogConData(barcodeReducer.barcodeContent)});
  }, [barcodeReducer.barcodeContent])

  const handleCurrentInfo = (info) => {
    setCurrentInfo(() => { return info });
  }

  return (
    <>
      <header className='header-barcode'>
        <h2>{_elementData.elementName}<div></div>{_elementData.elementCode}</h2>
        <div className='btn-infos'>
          {[
            { info: 'element', name: '기준정보' },
            { info: 'stockLog', name: '재고상세이력' },
            // { info: 'lot', name: 'Lot' },
            // { info: 'serial', name: 'Serial' },
          ].map((info) => {
            return (
              <button key={info.info + '_info'}
                className={info.info === _currentInfo ? 'btn-info active' : 'btn-info'}
                onClick={() => { handleCurrentInfo(info.info) }}
              >
                {info.name}
              </button>
            )
          })}
        </div>
      </header>
      <main className='main-barcode'>
        {_currentInfo === 'element' ? <ElementInfo elementData={barcodeReducer.barcodeContent.element} elementConData={_elementConData} stockData={_stockData} /> : null}
        {_currentInfo === 'stockLog' ? <StockLogInfo stockLogData={barcodeReducer.barcodeContent} stockLogConData={_stockLogConData} handleLogType={handleLogType} /> : null}
      </main>
    </>
  );
};

export default StockLog;