import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { procurementApi } from 'api/apis/procurementApi';
import { stockPlace } from 'api/apis/stock/stockPlace';

import { checkEmptyNull, checkNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import { DateTimeFormat } from 'components/format/DateTimeFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import CommaNum from 'components/format/CommaNum';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  /* height: calc(100% - 55px); */
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;

    input, select {
      border: 1px solid var(--gray-200);
      border-radius: 5px;
      box-sizing: border-box;
      font-size: 20px;
      font-weight: 600;
      height: 40px;
      min-width: 200px;
      padding: 5px;
      width: 98%;
    }
    input[name="savedQuantity"] {color: var(--MainGreen); text-align: right;}
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainGreen);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const PutProcurementElementModal = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const procurementData = props.procurement.procurementData;
  console.log('procurementData : ', procurementData);

  const [_onload, setOnload] = useState('unload');

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId, // 회사
    userId: userReducer.user.id, // 작성자

    procurementId: procurementData.procurementId,
    procurementDate: checkEmptyNull(procurementData.procurementDate, false) ? DateFormat(procurementData.procurementDate) : '', // 발주일자
    scheduledEndDate: checkEmptyNull(procurementData.scheduledEndDate, false) ? DateFormat(procurementData.scheduledEndDate) : '', // 입고예정일자

    procurementStatus: procurementData.procurementStatus, // 발주상태
    procurementCode: procurementData.procurementCode, // 발주코드
    procurementName: procurementData.procurementName, // 발주이름

    accountId: procurementData?.account?.accountId, // 거래처
    accountName: procurementData?.account?.accountName, // 거래처

    procurementPrice: procurementData.procurementPrice, // 발주총금액

    customizedContent: JSON.stringify({}), // 커스텀

    procurementElementList: [], // 발주물품
    deleteProcurementElementList: [], // 발주물품 삭제
  });

  const [_procurementElements, setProcurementElements] = useState(() => {
    const procurementElementList = procurementData.procurementElementList;
    if (checkNullObject(procurementElementList, false)) {
      const returnData = procurementElementList.map((element) => {
        if (checkNullObject(element.element, false)) {
          const elementData = {
            ...element,

            elementId: element.element.elementId,
            elementName: element.element.elementName,
            elementCode: element.element.elementCode,
            scheduledInputStock: element.element.scheduledInputStock,
            elementSafeStock: element.element.elementSafeStock,
            elementStock: element.element.elementStock,

            price: element.element.price || 0, // 단가
            calPrice: element.price, // 금액

            storeQuantity: checkEmptyNull(element.storeQuantity, element.quantity), // 미입고
            savedQuantity: checkEmptyNull(element.savedQuantity, 0), // 입고 입력 수량
          };
          return elementData;
        } else {
          return element;
        }
      });
      return returnData;
    } else {
      return [];
    }
  });

  const [_account, setAccount] = useState(checkNullObject(procurementData?.account, {}));

  const [_stockPlaceList, setStockPlaceList] = useState([]);

  /* ====================================================================== #3 */
  useEffect(() => {
    getStockPlaceList();

    setOnload('loaded');
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const getStockPlaceList = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId
    }
    await stockPlace.searchStockPlace(BodyToPost).then(response => {
      if (response === undefined) return;
      console.log('stockPlace.searchStockPlace : ', response);
      setStockPlaceList(() => { return response.data });
    })
  }


  /* ====================================================================== #5 */
  const setMinMaxNum = (value, min, max) => {
    let returnNum = value;
    if (value < min) returnNum = min;
    if (value > max) returnNum = max;
    return returnNum;
  };

  const handleElementInput = (e, index, element) => {
    const { name, value } = e.target;

    let newData = { ...element };
    switch (name) {
      case 'savedQuantity':
        let eventValue = value;
        if (!eventValue.toString().startsWith('0.')) eventValue = eventValue.toString().replace(/^0+/, '');
        if (eventValue < 0 || eventValue === '') eventValue = 0;
        if (eventValue.length > 15) return;
        eventValue = setMinMaxNum(eventValue, 0, element.storeQuantity);
        newData.savedQuantity = BigNumber(eventValue).toNumber();
        break;

      case 'checkedDate': newData.checkedDate = DateTimeFormat(value); break;
      case 'stockPlaceId': newData.stockPlaceId = Number(value); break;

      default: newData[name] = value; break;
    }

    setProcurementElements((prev) => {
      const returnData = [...prev];
      returnData.splice(index, 1, newData);
      return returnData;
    });
  };

  const actPut = async () => {
    const BodyToPost = {
      ..._formData,
      accountId: _account?.accountId,
      procurementElementList: _procurementElements.map((element) => {
        const returnData = {
          procurementElementId: element.procurementElementId,
          elementId: element.elementId,
          quantity: element.quantity,
          price: element.calPrice,

          // storeQuantity: element.storeQuantity, // 미입고
          savedQuantity: element.savedQuantity, // 입력 입고 수량

          checkedDate: checkEmptyNull(element.checkedDate, ''), // 유통기한
          stockPlaceId: checkEmptyNull(element.stockPlaceId, null), // 재고위치
        };
        return returnData;
      }),
    };

    await procurementApi.savePartStock(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('procurementApi.savePartStock : ', response);
      alert('부분 입고했습니다.');
      props.actPut(response.data);
    });
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>발주입고</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <TableSection content={
                <table>
                  <thead>
                    <tr>
                      <th>물품이름</th>
                      <th>물품코드</th>
                      <th style={{ minWidth: '150px', width: '150px' }}>단가</th>
                      <th style={{ minWidth: '150px', width: '150px' }}>수량</th>
                      <th style={{ minWidth: '150px', width: '150px' }}>금액</th>
                      <th style={{ minWidth: '150px', width: '150px' }}>기입고수량</th>
                      <th style={{ backgroundColor: 'var(--MainRed)', color: 'var(--white)', minWidth: '150px', width: '150px' }}>미입고수량</th>
                      <th style={{ backgroundColor: 'var(--MainGreen)', color: 'var(--white)' }}>입고수량</th>
                      <th>유통기한</th>
                      <th>재고위치</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_procurementElements.map((element, index) => {
                      return (
                        <tr key={index + '_putProcurementElements'}>
                          <td>{element.elementName}</td>
                          <td>{element.elementCode}</td>
                          <td style={{ minWidth: '150px', width: '150px' }}>{checkEmptyNull(element.price, 0).toLocaleString()}</td>
                          <td style={{ minWidth: '150px', width: '150px' }}>{checkEmptyNull(element.quantity, 0).toLocaleString() + ' ' + checkEmptyNull(element.elementUnit, '')}</td>
                          <td style={{ minWidth: '150px', width: '150px' }}>{checkEmptyNull(element.calPrice, 0).toLocaleString()}</td>
                          <td style={{ minWidth: '150px', width: '150px' }}>
                            {BigNumber(checkEmptyNull(element.quantity, 0)).minus(BigNumber(checkEmptyNull(element.storeQuantity, 0))).toNumber() + ' ' + checkEmptyNull(element.elementUnit, '')}
                          </td>
                          <td style={{ color: 'var(--MainRed)', fontSize: '20px', fontWeight: '600', minWidth: '150px', width: '150px' }}>
                            {checkEmptyNull(element.storeQuantity, 0) + ' ' + checkEmptyNull(element.elementUnit, '')}
                          </td>
                          <td style={{ color: 'var(--MainGreen)', fontSize: '20px', fontWeight: '600' }}>
                            <input
                              type="number"
                              style={{ borderColor: 'var(--MainGreen)' }}
                              name="savedQuantity"
                              min={0}
                              max={element.quantity}
                              value={checkNull(element.savedQuantity, 0)}
                              onInput={(e) => { handleElementInput(e, index, element) }}
                            />
                          </td>
                          <td>
                            <input
                              type="datetime-local"
                              name='checkedDate'
                              value={checkEmptyNull(element.checkedDate, '')}
                              onChange={(e) => { handleElementInput(e, index, element) }}
                            />
                          </td>
                          <td>
                            <select
                              name='stockPlaceId'
                              value={checkEmptyNull(element.stockPlaceId, '')}
                              onChange={(e) => { handleElementInput(e, index, element) }}
                            >
                              <option value={''}>선택</option>
                              {checkNullArray(_stockPlaceList, []).map((option, index) => {
                                return <option key={index + '_stockPlaces'} value={option.stockPlaceId}>{option.placeName}</option>
                              })}
                            </select>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
              />
            </ModalMain>

            <ModalFooter>
              <ModalButton onClick={actPut}>발주 입고</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default PutProcurementElementModal;
