import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { qna } from 'api/apis/mall/qna';

const MallStatusTdButtonsStyle = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
`;
const MallStatusButton = styled.div`
  align-items: center;
  background-color: var(--gray-200);
  color: var(--white);
  cursor: default;
  display: flex;
  font-size: 1.1em;
  height: 100%;
  justify-content: center;

  &.current.waiting {
    animation: WaitingStatus 1.5s infinite;
    background-color: var(--gray-900);
  }
  &.end.waiting {
    background-color: var(--MainBlue);
  }

  &.current.complete {
    background-color: var(--MainNavy);
  }
  &.end.complete {
    background-color: var(--MainNavy);
  }

  &.current.requestCancel {
    background-color: var(--MainRed);
  }

  &.active {
    cursor: pointer;

    &:hover {
      background-color: var(--MainGreen);
    }
  }
`;

const CancelButton = styled.div`
  align-items: center;
  background-color: var(--Bg);
  color: var(--white);
  display: flex;
  font-size: 1.1em;
  height: 100%;
  justify-content: center;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
`;

const MallQnAWaitingTdButtons = (props) => {
  const { userReducer } = useSelector(state => state);

  const { mallQnA } = props;

  const actComplete = () => {
    const confirmText = `
      문의상태를 [ 답변 완료 ]으로 변경하시겠습니까?
    `;
    if (window.confirm(confirmText) === true) {
      const BodyToPut = {
        ...mallQnA,
        deleteMallQnAElementList: [],
        deleteFileDataList: [],
        userId: userReducer.user.id,
        status: 'complete',
      };
      console.log('actComplete : ', BodyToPut);
      actUpdate(BodyToPut);
    } else {
      return;
    }
  };
  const actUpdate = async (BodyToPut) => {
    const putFormData = new FormData();
    putFormData.append(
      'key',
      new Blob([JSON.stringify(BodyToPut)], { type: 'application/json' }),
    );
    for (let values of putFormData.values()) {
      console.log('putFormData : ', values);
    }

    await qna.updateMallQnA(mallQnA.mallQnAId, putFormData)
      .then((response) => {
        if (response === undefined) return;
        console.log('qna.updateMallQnA : ', response);

        alert('문의상태를 변경했습니다.');
        props.handleMallQnAStatus(response.data);
      });
  };

  return (
    <MallStatusTdButtonsStyle>
      <MallStatusButton className="current waiting">대기</MallStatusButton>
      <MallStatusButton
        className="active"
        onClick={(e) => {
          e.stopPropagation();
          actComplete();
        }}
      >
        답변 완료
      </MallStatusButton>
      <MallStatusButton>취소 요청</MallStatusButton>
    </MallStatusTdButtonsStyle>
  );
};

const MallQnACompleteTdButtons = (props) => {
  return (
    <MallStatusTdButtonsStyle>
      <MallStatusButton className="end waiting">대기</MallStatusButton>
      <MallStatusButton className="end complete">답변 완료</MallStatusButton>
      <MallStatusButton>취소 요청</MallStatusButton>
    </MallStatusTdButtonsStyle>
  );
};

const MallQnARequestCancelTdButtons = (props) => {
  return (
    <MallStatusTdButtonsStyle>
      <MallStatusButton>대기</MallStatusButton>
      <MallStatusButton>답변 완료</MallStatusButton>
      <MallStatusButton className="current requestCancel">
        취소 요청
      </MallStatusButton>
    </MallStatusTdButtonsStyle>
  );
};

const MallQnACancelTdButtons = (props) => {
  return <CancelButton>취소</CancelButton>;
};

export {
  MallQnAWaitingTdButtons,
  MallQnACompleteTdButtons,
  MallQnARequestCancelTdButtons,
  MallQnACancelTdButtons,
};
