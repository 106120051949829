import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { jointPurchase, publicBoard, reciprocalTransaction } from 'api/apis/platform/cluster/cluster';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { CiCirclePlus } from "react-icons/ci";
import { HiMiniDocumentMagnifyingGlass } from "react-icons/hi2";
import { MdDeleteForever } from "react-icons/md";
import { IoSearch } from "react-icons/io5";
import { RiEditFill } from "react-icons/ri";
import { MainBoardComponent } from './MainBoard.style';
import ClusterContentModal from '../modal/ContentModal';
import ClusterFormModal from '../modal/FormModal';
import ClusterPagination from 'platform/cluster/components/ClusterPagination';

const MainBoard = () => {
  const location = useLocation();
  const clusterId = location.pathname.split('/platform/cluster')[0];
  const clusterPath = clusterId + '/platform/cluster';

  const { clusterReducer } = useSelector(state => state);
  const { user } = clusterReducer;

  const [_onload, setOnLoad] = useState(false);

  const [_currentType, setCurrentType] = useState('');
  const [_title, setTitle] = useState('');
  const [_dataKeys, setDataKeys] = useState([]);

  const [_dataList, setDataList] = useState([]);

  const reset = { title: '', type: '', };
  const [_searchData, setSearchData] = useState(reset); // 거래유형, 제목
  const [_pageNumber, setPageNumber] = useState(1);
  const [_totalSize, setTotalSize] = useState(0);

  const [_modalType, setModalType] = useState();
  const [_modalData, setModalData] = useState();
  const [_modalStatus, setModalStatus] = useState(false);
  const [_detailStatus, setDetailStatus] = useState(false); // 상세 보기

  useEffect(() => {
    setSearchData(() => { return reset });
    setDataList(() => { return [] });
    if (location.pathname.includes('/public')) { // 공용 게시판
      setCurrentType(() => { return 'public'; });
      getDataList(_pageNumber - 1, 'public');
    } else if (location.pathname.includes('/purchase')) { // 공동 구매
      setCurrentType(() => { return 'purchase'; });
      getDataList(_pageNumber - 1, 'purchase');
    } else if (location.pathname.includes('/sales')) { // 상호 거래
      setCurrentType(() => { return 'sales'; });
      getDataList(_pageNumber - 1, 'sales');
    }
  }, [location.pathname]);
  useEffect(() => {
    switch (_currentType) {
      case 'public':
        setTitle(() => { return '게시판'; });
        setDataKeys(() => {
          return [
            { key: 'title', title: '제목', },
          ];
        });
        break;
      case 'purchase':
        setTitle(() => { return '공동 구매'; });
        setDataKeys(() => {
          return [
            { key: 'title', title: '제목', },
            { key: 'targetDate', title: '마감일', },
            { key: 'manager', title: '담당자', },
          ];
        });
        break;
      case 'sales':
        setTitle(() => { return '상호 거래'; });
        setDataKeys(() => {
          return [
            { key: 'type', title: '거래 유형', },
            { key: 'title', title: '제목', },
            { key: 'targetDate', title: '마감일', },
            { key: 'manager', title: '담당자', },
          ];
        });
        break;
      default: break;
    }
  }, [_currentType]);
  useEffect(() => {
    setOnLoad(() => { return true });
    return () => { }
  }, []);
  useEffect(() => {
    if (_onload) getDataList(_pageNumber - 1, _currentType);
    return () => { }
  }, [_pageNumber]);
  useEffect(() => { // 유형 변경 >> 데이터 불러오기
    if (_onload) {
      setPageNumber(() => { return 1; });
      getDataList(0, _currentType);
    }
    return () => { }
  }, [_searchData.type]);
  useEffect(() => { }, [_dataKeys, _dataList, _totalSize]);

  const getDataList = async (page, currentType) => { // 데이터 불러오기
    console.log(currentType);
    const id = Number(clusterId.replace('/', ''));
    const paging = `?page=${page}&size=15&sort=id,DESC`;
    // const body = {};
    // const body = { clusterId: id };
    const body = { clusterId: id, companyId: user.companyId };

    switch (currentType) {
      case 'public': // 공용 게시판
        await publicBoard.search(paging, body).then(response => {
          if (response === undefined) return;
          console.log('public : ', response);
          setDataList(() => { return response.data.content });
          setTotalSize(() => { return response.data.totalElements });
        })
        break;
      case 'purchase': // 공동 구매
        body.title = _searchData.title;
        await jointPurchase.search(paging, body).then(response => {
          if (response === undefined) return;
          console.log('purchase : ', response);
          setDataList(() => { return response.data.content });
          setTotalSize(() => { return response.data.totalElements });
        })
        break;
      case 'sales': // 상호 거래
        body.title = _searchData.title;
        body.type = _searchData.type;
        await reciprocalTransaction.search(paging, body).then(response => {
          if (response === undefined) return;
          console.log('sales : ', response);
          setDataList(() => { return response.data.content });
          setTotalSize(() => { return response.data.totalElements });
        })
        break;

      default: return;
    }
  };

  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setSearchData((prev) => {
      return { ...prev, [name]: value };
    });
  }

  const actSearch = () => { // 검색
    setPageNumber(() => { return 1; });
    getDataList(0, _currentType);
  }

  const handleModalStatus = (type, data) => { // 모달 상태 변경(열기/닫기)
    setModalType(() => { return type });
    setModalData(() => { return data || {} });
    setTimeout(setModalStatus(() => { return true }), 1000);
  }
  const goDetail = (type, data) => {
    setModalType(() => { return type });
    setModalData(() => { return data || {} });
    setTimeout(setDetailStatus(() => { return true }), 1000);
  }

  return (<>
    <MainBoardComponent>
      <header>
        <h1>{_title}</h1>
        <button onClick={() => { handleModalStatus(`${_currentType}-board-create`) }}>
          <CiCirclePlus />
          <p>등록하기</p>
        </button>
      </header>
      <nav>
        {_currentType === 'sales' ? (<>
          <div className='nav-bar'>
            <p>거래 유형</p>
            <select
              name='type'
              value={_searchData.type || ''}
              onChange={handleInputEvent}
            >
              {[
                { title: '유형 선택', value: '' },
                { title: '구매', value: 'buy' },
                { title: '판매', value: 'sell' },
              ].map((option) => {
                return (<option key={option.value} value={option.value}>{option.title}</option>);
              })}
            </select>
          </div>
        </>) : null}
        {_currentType !== 'public' ? (<>
          <div className='nav-bar'>
            <p>제목</p>
            <input
              type='text'
              name='title'
              placeholder='검색어를 입력해주세요.'
              value={_searchData.title || ''}
              onInput={handleInputEvent}
              onKeyDown={(e) => { if (e.key === 'Enter') actSearch(); }}
            />
          </div>
          <button onClick={actSearch}><IoSearch /></button>
        </>) : null}
      </nav>
      <div>
        <table>
          <colgroup>
            {_dataKeys.map((dataKey, index) => {
              return (
                <col
                  key={index + '_col'}
                  width={_currentType === 'public' ? dataKey.key !== 'title' ? '120px' : '' : dataKey.key === 'title' ? '45%' : ''}
                />
              );
            })}
            <col width={'200px'} />
          </colgroup>
          <thead>
            <tr>
              {_dataKeys.map((dataKey, index) => {
                return (<th key={index + '_th'}>{dataKey.title}</th>);
              })}
              <th></th>{/* 수정, 삭제 셀 */}
            </tr>
          </thead>
          <tbody>
            {!checkNullArray(_dataList, false) ? (<tr><td colSpan={_dataKeys.length + 1}>데이터가 없습니다.</td></tr>) :
              checkNullArray(_dataList, []).map((data, index) => {
                return (
                  <tr key={index + '_data'}>
                    {_dataKeys.map((dataKey, dataKeyIndex) => {
                      let dataValue = data[dataKey.key] || '-';
                      if (dataKey.key === 'type') {
                        dataValue = data[dataKey.key] === 'buy' ? '구매' : '판매';
                      }
                      return (<td key={index + '_data_' + dataKeyIndex}>{dataValue}</td>);
                    })}
                    <td>
                      <div>
                        <div></div>
                        <button onClick={() => { goDetail(`${_currentType}-detail`, data); }}><HiMiniDocumentMagnifyingGlass /></button>
                        <div></div>
                        <button onClick={() => { handleModalStatus(`${_currentType}-board-update`, data) }}><RiEditFill />수정</button>
                        <div></div>
                        <button onClick={() => { handleModalStatus(`${_currentType}-board-delete`, data) }}><MdDeleteForever />삭제</button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <ClusterPagination
        page={_pageNumber}
        count={_totalSize}
        size={15}
        pageEvent={(page) => { setPageNumber(() => { return page; }) }}
      />
    </MainBoardComponent>

    {_detailStatus ? (
      <ClusterContentModal
        modalType={_modalType}
        modalData={_modalData}
        open={_detailStatus}
        close={() => { setDetailStatus(() => { return false }); }}
      />
    ) : null}
    {_modalStatus ? (
      <ClusterFormModal
        modalType={_modalType}
        modalData={_modalData}
        update={() => {
          console.log('update');
          getDataList(_pageNumber - 1, _currentType);
          setTimeout(setModalStatus(() => { return false }), 1000);
        }}
        open={_modalStatus}
        close={() => { setModalStatus(() => { return false }); }}
      />
    ) : null}
  </>);
};

export default MainBoard;