import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { QRCodeSVG } from 'qrcode.react';

import { gsReducer_setBarcodeContent } from 'store/modules/actions/company/GSActions';

import { ACCESS_TOKEN, AXIOS_BASE_URL } from 'preferences/server/constants';

import AllCheckBox from 'components/checkbox/AllCheckBox';
import CheckBox from 'components/checkbox/CheckBox';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import TableSection from 'components/layouts/table/TableSection';
import { getCookie } from 'preferences/cookie/cookie';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  /* max-height: 300px; */
  /* max-width: 900px; */
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  /* height: calc(100% - 55px); */
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const QRCode = styled(QRCodeSVG)`
  cursor: pointer;
  height: 50px;
  width: 50px;
`;

const SearchSection = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-end;
  padding: 0px 10px;
  width: 100%;

  input {
    border: 1px solid var(--MainNavy);
    border-radius: 5px;
    box-sizing: border-box;
    height: 40px;
    min-width: 400px;
    padding: 10px;
    width: 40%;
  }
`;

const GSBarcodeWList = (props) => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { gsReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const setDateFormat = (thisDate) => {
    if (
      thisDate !== undefined &&
      thisDate !== null &&
      typeof thisDate === 'object'
    ) {
      const newtDate = new Date(thisDate.join('-'));
      return moment(newtDate).format('YYYY-MM-DD');
    } else {
      return thisDate;
    }
  };

  const [_onload, setOnload] = useState('unload');

  const [_contract, setContract] = useState(props.content);
  const [_workOrder, setWorkOrder] = useState([]);

  const [_formData, setFormData] = useState({});

  const [_updateCLWorkOrderList, setUpdateCLWorkOrderList] = useState([]);
  const [_deleteCLWorkOrderList, setDeleteCLWorkOrderList] = useState([]);
  console.log(
    '_updateCLWorkOrderList : ',
    _updateCLWorkOrderList,
    '_deleteCLWorkOrderList : ',
    _deleteCLWorkOrderList,
  );

  const [_CPData, setCPData] = useState([]);

  /* ====================================================================== #3 */
  useEffect(() => {
    getCLData();

    setOnload('loaded');

    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const getCLData = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      contractId: _contract.contractId,
    };
    console.log('getCLData - BodyToPost : ', BodyToPost);

    await axios
      .post(AXIOS_BASE_URL + '/workOrderLog/search', BodyToPost, {
        headers: {
          Authorization: getCookie('accessToken'),
        },
      })
      .then((response) => {
        if (response === undefined) return;
        console.log('getCLData - response : ', response);
        if (response.status === 200) {
          Finished_getCLData(response.data.data.content[0]);
        }
      })
      .catch((error) => {
        console.log('getCLData - error : ', error);
      });
  };
  const Finished_getCLData = (Result) => {
    setFormData({
      companyId: userReducer.company.companyId,
      userId: userReducer.user.id,

      workOrderLogId: Result.workOrderLogId,
      workOrderStatus: Result.workOrderStatus,

      workOrderScheduledStartDate: setDateFormat(
        Result.workOrderScheduledStartDate,
      ),
      workOrderScheduledEndDate: setDateFormat(
        Result.workOrderScheduledEndDate,
      ),

      accountId: Result.accountId,
      workPlace: Result.workPlace,
      detailedWorkPlace: Result.detailedWorkPlace,

      message: Result.message,
      remark: Result.remark,

      workOrderCustomizedContent: Result.workOrderCustomizedContent,

      processLogList: [],
      deleteProcessList: [],
    });
    setWorkOrder(
      Result.processLogList.filter(
        (thisItem) => thisItem.processTypeName === 'CL',
      ),
    );
    setCPData(
      Result.processLogList.filter(
        (thisItem) => thisItem.processTypeName === 'CP',
      ),
    );

    const thisProcessLogList = Result.processLogList.filter(
      (thisItem) => thisItem.processTypeName === 'CL',
    );
    const updateWorkOrderList = thisProcessLogList.map((thisItem) => {
      return {
        existProcessOutElement: false,
        processTypeName: 'CL',
        processStatus: thisItem.processStatus,
        processLogId: thisItem.processLogId,
        processingCount: thisItem.processingCount,
        processCustomizedContent: JSON.parse(thisItem.processCustomizedContent),
        deleteTaskIds: [],
        taskLogList: [],
      };
    });
    setUpdateCLWorkOrderList(updateWorkOrderList);
  };

  /* ====================================================================== #5 */
  const actBarcodeProduct = async () => {
    const checkedProducts = document.querySelectorAll(
      'input[name="barcode_process"]:checked',
    );

    let BarcodeProducts = [];
    checkedProducts.forEach((thisItem) => {
      const thisItemKey = thisItem.closest('tr').dataset.key;
      const thisItemBarcode = thisItem.closest('tr').dataset.barcode;

      const thisFindIndex = _workOrder.findIndex(
        (thisIndex) => thisIndex.processLogId === Number(thisItemKey),
      );
      const thisFindItem = _workOrder[thisFindIndex];

      const thisData = {
        type: 'process',
        place: _contract,
        info: thisFindItem,
        barcode: thisItemBarcode,
      };

      BarcodeProducts.push(thisData);
    });

    if (checkedProducts.length === BarcodeProducts.length) {
      console.log('BarcodeProducts : ', BarcodeProducts);

      const updateData = [...gsReducer.barcodeContent, ...BarcodeProducts];
      dispatch(gsReducer_setBarcodeContent(updateData));
      props.close();
    }
  };

  // 바코드 복사
  const copyBarcode = (e) => {
    const thisBarcode = e.target.closest('td').dataset.barcode;
    console.log(thisBarcode);

    const textArea = document.createElement('textarea');
    document.body.appendChild(textArea);

    textArea.value = thisBarcode;
    textArea.select();
    textArea.setSelectionRange(0, 99999);
    document.execCommand('copy');
    textArea.setSelectionRange(0, 0);

    document.body.removeChild(textArea);
  };

  const actFilter = (e) => {
    const eventValue = e.target.value;

    document.querySelectorAll('#processListTbody tr').forEach((thisRow) => {
      let checkInclude = 0;
      thisRow.querySelectorAll('td').forEach((thisCell) => {
        if (thisCell.dataset.search !== undefined) {
          if (
            thisCell.dataset.search
              .toLowerCase()
              .includes(eventValue.toLowerCase()) === true
          ) {
            checkInclude++;
          }
        }
      });

      if (checkInclude > 0) {
        thisRow.style.display = '';
      } else {
        thisRow.style.display = 'none';
      }
    });
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection style={{ zoom: '100%' }}>
            <ModalHeader>
              <ModalTitle>체크리스트</ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              <SearchSection>
                <input onKeyUp={actFilter} placeholder="Search..." />
              </SearchSection>
              <TableSection
                content={
                  <table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '50px', width: '50px' }}>
                          <AllCheckBox checkboxName={'barcode_process'} />
                        </th>
                        <th style={{ minWidth: '65px', width: '65px' }}>
                          순번
                        </th>
                        <th>부재번호</th>
                        <th>규격</th>
                        <th>길이</th>
                        <th>수량</th>
                        <th>종류</th>
                        <th>개별중량</th>
                        <th>비고</th>
                        <th style={{ minWidth: '100px', width: '100px' }}>
                          바코드
                        </th>
                      </tr>
                    </thead>

                    <tbody id="processListTbody">
                      {_workOrder.map((thisItem) => {
                        const processCustomizedContent = JSON.parse(
                          thisItem.processCustomizedContent,
                        );
                        const thisNum = (_workOrder.indexOf(thisItem) + 1)
                          .toString()
                          .padStart(2, '0');

                        const thisBarcode = `WorkOrder-${_formData.workOrderLogId}-${thisItem.processLogId}-${processCustomizedContent.asmbly}`;

                        return (
                          <tr
                            key={thisNum + '_CLRow'}
                            data-key={thisItem.processLogId}
                            data-barcode={thisBarcode}
                          >
                            <td style={{ minWidth: '50px', width: '50px' }}>
                              <CheckBox
                                act={''}
                                all={true}
                                name={'barcode_process'}
                              />
                            </td>
                            <td
                              style={{ minWidth: '65px', width: '65px' }}
                              data-col="Num"
                            >
                              {thisNum}
                            </td>
                            <td
                              data-col="asmbly"
                              data-colname="부재번호"
                              data-search={processCustomizedContent.asmbly}
                            >
                              {processCustomizedContent.asmbly}
                            </td>
                            <td
                              data-col="profile"
                              data-colname="규격"
                              data-search={processCustomizedContent.profile}
                            >
                              {processCustomizedContent.profile}
                            </td>
                            <td
                              data-col="length"
                              data-colname="길이"
                              data-search={processCustomizedContent.length}
                            >
                              {processCustomizedContent.length}
                            </td>
                            <td
                              data-col="quantity"
                              data-colname="수량"
                              data-search={processCustomizedContent.quantity}
                            >
                              {processCustomizedContent.quantity}
                            </td>
                            <td
                              data-col="part"
                              data-colname="종류"
                              data-search={processCustomizedContent.part}
                            >
                              {processCustomizedContent.part}
                            </td>
                            <td
                              data-col="weight"
                              data-colname="개별중량"
                              data-search={processCustomizedContent.weight}
                            >
                              {processCustomizedContent.weight}
                            </td>
                            <td
                              data-col="name"
                              data-colname="비고"
                              data-search={processCustomizedContent.name}
                            >
                              {processCustomizedContent.name}
                            </td>
                            <td
                              style={{ minWidth: '100px', width: '100px' }}
                              data-col="바코드"
                              data-barcode={thisBarcode}
                            >
                              <QRCode
                                value={thisBarcode}
                                onClick={copyBarcode}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                }
              />
            </ModalMain>
            <ModalFooter>
              <ModalButton onClick={actBarcodeProduct}>추가</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default GSBarcodeWList;
