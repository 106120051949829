import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { shipmentInspectionActions_setPageNumber, shipmentInspectionActions_setReset, shipmentInspectionActions_setSearchOption, shipmentInspectionActions_setTotalSize } from 'store/modules/actions/common/shipmentInspectionActions';

import { shipmentInspection } from 'api/apis/shipmentInspection/shipmentInspection';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { LoadingMsg } from 'components/loading/LoadingMsg';

import Grid3Body from 'components/layouts/body/Grid3Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import ShipmentInspectionFileModal from 'pages/shipmentInspection/modal/ShipmentInspectionFileModal';
import TableSection from 'components/layouts/table/TableSection';
import NavText from 'components/nav/NavText';

const MainSection = styled.main`
  & .TableSection tbody tr {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`;
const InspectionFileButton = styled.td`
  max-width: 70px!important;
  min-width: 70px!important;
  cursor: pointer;
  padding: 8px;
  vertical-align: middle;
  display: flex; justify-content: center; align-items: center;
  &:hover {opacity: .7;}
`;

const ShipmentInspection = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, shipmentInspectionReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_shipmentInspectionList, setShipmentInspectionList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState({});
  console.log('_checkedItem : ', _checkedItem);

  const [_modalStatus, setModalStatus] = useState(false);
  const [_eventShipmentInspection, setEventShipmentInspection] = useState({});

  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('114') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getShipmentInspectionList(shipmentInspectionReducer.pageNumber - 1);

    setOnload('loaded');

    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getShipmentInspectionList(shipmentInspectionReducer.pageNumber - 1);
    }

    return () => { };
  }, [shipmentInspectionReducer.pageNumber]);

  useEffect(() => { }, [_checkedItem])

  /* ========================================================================= #4 */
  const getShipmentInspectionList = async (page, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && checkEmptyNull(shipmentInspectionReducer.searchOption, false) && checkEmptyNull(shipmentInspectionReducer.searchText, false)) {
      BodyToPost[shipmentInspectionReducer.searchOption] = shipmentInspectionReducer.searchText;
    }

    await shipmentInspection.searchShipmentInspection(paging, BodyToPost)
      .then((response) => {
        if (response === undefined) return;
        console.log('shipmentInspection.searchShipmentInspection : ', response);

        setShipmentInspectionList(() => {
          return response.data.content;
        });

        dispatch(shipmentInspectionActions_setTotalSize(response.data.totalElements));
      })
  };

  /* ========================================================================= #5 */
  const handleCheckedItem = (item, isChecked) => {
    if (isChecked) {
      setCheckedItem(() => {
        return item;
      });
    } else {
      setCheckedItem(() => {
        return {}
      })
    }
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(shipmentInspectionActions_setPageNumber(1));
    await getShipmentInspectionList(0);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(shipmentInspectionActions_setReset());

    dispatch(shipmentInspectionActions_setPageNumber(1));
    await getShipmentInspectionList(0, true);
  };

  /* 상세보기 */
  const goDetail = (detailContent) => {
    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({
        updateContent: JSON.stringify(detailContent)
      })}`,
      replace: true,
    });
  };

  /* 수정 */
  const actUpdate = () => {
    const updateContent = _checkedItem;
    if (!checkNullObject(updateContent, false)) return alert('수정할 항목을 선택해주세요.');

    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({
        updateContent: JSON.stringify(updateContent)
      })}`,
      replace: true,
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const deleteContent = _checkedItem;
    if (!checkNullObject(deleteContent, false)) return alert('삭제할 항목을 선택해주세요.');

    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({
        deleteContent: JSON.stringify(deleteContent)
      })}`,
      replace: true,
    });
  };

  /* ========================================================================= #6 */

  return (
    <Grid3Body contents={
      <>
        <MainSection className="Main">
          <NavBar
            title={<NavTitle menuCode={'114'} />}
            buttons={
              <>
                {_authority.indexOf('114-2') !== -1 ? (
                  <>
                    <button className='btn-add' onClick={() => { navigate(pageReducer.currentPage + '/create', { replace: true }) }}>추가</button>
                    <button className='btn-edit' onClick={actUpdate}>수정</button>
                    <button className='btn-delete' onClick={actDelete}>삭제</button>
                  </>
                ) : null}
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="ContentCBox">
                  <div className="ContentCBox">
                    <select
                      className="SearchOption"
                      value={shipmentInspectionReducer.searchOption}
                      onChange={(e) => {
                        const searchOption = e.target.value;
                        dispatch(shipmentInspectionActions_setSearchOption(searchOption))
                      }}
                    >
                      {[
                        { optionValue: '', optionText: '검색옵션' },
                        { optionValue: 'shipmentInspectionCode', optionText: '이름' },
                        { optionValue: 'shipmentInspectionName', optionText: '프로젝트' },
                      ].map((option) => {
                        return (<option key={option.optionValue + '_shipmentInspectionSearchOption'} value={option.optionValue}>
                          {<NavText menuCode={'604'} />}{option.optionText}
                        </option>);
                      })}
                    </select>

                    <input
                      className="SearchBar"
                      placeholder="Search..."
                      value={shipmentInspectionReducer.searchText}
                      onInput={(e) => {
                        const searchText = e.target.value;
                        dispatch(shipmentInspectionActions_setSearchOption(searchText))
                      }}
                    />
                  </div>

                  <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                  <div className="ResetButton" onClick={actReset}>초기화</div>
                </div>
              </>
            }
          />

          <TableSection content={
            <table>
              <thead>
                <tr>
                  {_authority.indexOf('114-2') !== -1 ? (<th style={{ minWidth: '50px', width: '50px' }}></th>) : null}
                  <th style={{ minWidth: '70px', padding: 'unset', width: '70px' }}></th>
                  <th><NavText menuCode={'604'} /> 이름</th>
                  <th>프로젝트</th>
                </tr>
              </thead>
              <tbody>
                {!checkNullArray(_shipmentInspectionList, false) ? LoadingMsg() :
                  checkNullArray(_shipmentInspectionList, []).map((shipmentInspection, index) => {
                    return (
                      <tr key={index + '_shipmentInspection'}
                        onClick={(e) => {
                          e.preventDefault();
                          goDetail(shipmentInspection)
                        }}
                      >
                        {_authority.indexOf('114-2') !== -1 ? (
                          <td style={{ minWidth: '50px', width: '50px' }}>
                            <input
                              type="checkBox"
                              name="shipmentInspections"
                              checked={checkEmptyNull(_checkedItem.shipmentInspectionId, false) ? shipmentInspection.shipmentInspectionId === _checkedItem.shipmentInspectionId : false}
                              onChange={(e) => {
                                e.preventDefault();
                                const isChecked = e.target.checked;
                                handleCheckedItem(shipmentInspection, isChecked);
                                if (isChecked) e.target.checked = true;
                                else e.target.checked = false;
                              }}
                              onClick={e => { e.stopPropagation(); }}
                            />
                          </td>
                        ) : null}
                        <InspectionFileButton onClick={(e) => {
                          e.stopPropagation();
                          setEventShipmentInspection(shipmentInspection);
                          setTimeout(setModalStatus(true), 1000);
                        }}>
                          <img src={process.env.PUBLIC_URL + '/src/icons/print.svg'} />
                        </InspectionFileButton>
                        <td>{shipmentInspection.shipmentInspectionCode}</td>
                        <td>{shipmentInspection.shipmentInspectionName}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          }
          />
        </MainSection>

        <PagingComponent
          page={shipmentInspectionReducer.pageNumber}
          count={shipmentInspectionReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(shipmentInspectionActions_setPageNumber(page));
          }}
        />

        {_modalStatus === true && checkNullObject(_eventShipmentInspection, false) && (
          <ShipmentInspectionFileModal open={_modalStatus} close={() => { setModalStatus(false) }} shipmentInspection={_eventShipmentInspection} />
        )}
      </>
    }
    />
  );
};

export default ShipmentInspection;
