import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { productionPerformanceActions_setEndDate, productionPerformanceActions_setPageNumber, productionPerformanceActions_setReset, productionPerformanceActions_setSearchText, productionPerformanceActions_setStartDate, productionPerformanceActions_setTotalSize, productionPerformanceActions_setWorkOrderStatus } from 'store/modules/actions/common/productionPerformanceActions';

import { workOrderTypeApi } from 'api/apis/workOrderTypeApi';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';

import UriDetailWorkOrder from 'pages/productionOrder/custom/company/uri/modal/UriDetailWorkOrder';

const Section = styled.main`
  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
    & .selectButtons {display: flex; gap: 5px;}
  }

  & .TableSection {
    &>table {
      background-color: var(--Bg);
      /* border-collapse: separate;
      border-spacing: 0px 10px; */
      & td:nth-child(2), & td:nth-child(3) {background-color: var(--white);}
    }
  }
`;

const WorkOrderTable = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  table {
    background-color: var(--white);
    border-collapse: collapse;
    height: 96%;
    margin: 0px auto;
    table-layout: auto;
    width: 98%;

    td, th {
      border: 1px solid var(--gray-200);
      box-sizing: border-box;
      height: 40px;
      min-width: unset;
      padding: unset;
    }
    th {font-size: 0.8em;}
    & td.colors {
      font-size: 25px;
      font-weight: 600;
      outline: 4px solid var(--Text);
      outline-offset: -4px;
      text-align: center;
    }
    & td.remark {
      color: var(--ThirdBlue);
      font-size: 1.1em;
      padding: 10px;
      text-align: left;
      vertical-align: top;
    }
  }

  &:hover {cursor: pointer; opacity: 0.6;}
`;

const ProcessCell = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 100%;
  width: 100%;
`;

const ProcessSection = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 60px repeat(auto-fit, minmax(100px, 1fr));
  height: 100%;
  width: 100%;
`;

const Process = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  justify-content: center;
  width: 100%;

  background-color: ${(props) => {
    switch (props.status) {
      case 'wait': return 'var(--gray-200)'
      case 'work': return 'var(--WorkingColor)'
      case 'end': return 'var(--ThirdBlue)'
      case 'stop': return 'var(--MainRed)'
      case 'pause': return 'var(--MainYellow)'
      case 'cancel': return 'var(--Bg)'

      case 'NO': return 'var(--MainNavy)';
      case 'cut': return '#BCE55C'
      case 'welding': return '#9FC93C'
      case 'last': return '#689900'

      default: return 'unset';
    }
  }};

  color: ${(props) => {
    return props.status.toLowerCase() === 'cancel' ? 'var(--gray-200)' : 'var(--white)';
  }};
`;

const UriProductionPerformance = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { productionPerformanceReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const taskTypeArray = ['BF', 'SF', 'MF'];

  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_workOrderLogList, setWorkOrderLogList] = useState([]);

  const [_workOrderTypes, setWorkOrderTypes] = useState([]);

  const [_modalStatus, setModalStatus] = useState(false);
  const [_modalContent, setModalContent] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('407') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));


    getWorkOrderTypes();
    getWorkOrderLogList(productionPerformanceReducer.pageNumber - 1, productionPerformanceReducer.workOrderType, productionPerformanceReducer.workOrderStatus);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getWorkOrderLogList(productionPerformanceReducer.pageNumber - 1, productionPerformanceReducer.workOrderType, productionPerformanceReducer.workOrderStatus);
    }

    return () => { };
  }, [productionPerformanceReducer.pageNumber]);

  /* ====================================================================== #4 */
  const getWorkOrderTypes = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await workOrderTypeApi.searchWorkOrderType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderTypeApi.searchWorkOrderType : ', response);
      setWorkOrderTypes(() => { return response.data; });
    });
  };

  const getWorkOrderLogList = async (page, workOrderType, workOrderStatus, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    if (!clear && checkEmptyNull(workOrderType, false) && workOrderType !== 'all') {
      BodyToPost.workOrderTypeId = Number(workOrderType);
    }

    if (!clear && checkEmptyNull(workOrderStatus, false)) {
      switch (workOrderStatus) {
        case 'all': BodyToPost.workOrderStatus = ['end', 'cancel']; break;

        default: BodyToPost.workOrderStatus = [workOrderStatus]; break;
      }
    }

    if (!clear && checkEmptyNull(productionPerformanceReducer.startDate, false) && checkEmptyNull(productionPerformanceReducer.endDate)) {
      BodyToPost.scheduledStartDate = DateFormat(productionPerformanceReducer.startDate);
      BodyToPost.scheduledEndDate = DateFormat(productionPerformanceReducer.endDate);
      if (productionPerformanceReducer.startDate > productionPerformanceReducer.endDate) {
        BodyToPost.scheduledStartDate = DateFormat(productionPerformanceReducer.endDate);
        BodyToPost.scheduledEndDate = DateFormat(productionPerformanceReducer.startDate);
      }
    } else if (!clear && checkEmptyNull(productionPerformanceReducer.startDate, false)) {
      BodyToPost.scheduledStartDate = DateFormat(productionPerformanceReducer.startDate);
    } else if (!clear && checkEmptyNull(productionPerformanceReducer.endDate)) {
      BodyToPost.scheduledEndDate = DateFormat(productionPerformanceReducer.endDate);
    }

    if (!clear && checkEmptyNull(productionPerformanceReducer.searchOption) && checkEmptyNull(productionPerformanceReducer.searchText)) {
      BodyToPost[productionPerformanceReducer.searchOption] = productionPerformanceReducer.searchText;
    }

    await workOrderLogApi.searchWorkOrderLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.searchWorkOrderLog : ', response);

      setWorkOrderLogList(() => { return response.data.data.content; });
      dispatch(productionPerformanceActions_setTotalSize(response.data.data.totalElements));

      if (!clear && checkEmptyNull(productionPerformanceReducer.searchOption) && checkEmptyNull(productionPerformanceReducer.searchText)) {
        if (productionPerformanceReducer.startDate > productionPerformanceReducer.endDate) {
          dispatch(productionPerformanceActions_setStartDate(productionPerformanceReducer.endDate))
          dispatch(productionPerformanceActions_setEndDate(productionPerformanceReducer.startDate))
        }
      }
    });
  };

  /* ====================================================================== #5 */
  /* 검색 - 작업상태 */
  const handleSearchWorkOrderStatus = async (workOrderStatus) => {
    dispatch(productionPerformanceActions_setWorkOrderStatus(workOrderStatus));
    dispatch(productionPerformanceActions_setPageNumber(1));
    await getWorkOrderLogList(0, productionPerformanceReducer.workOrderType, workOrderStatus)
  }

  /* 검색 */
  const actSearch = async () => {
    dispatch(productionPerformanceActions_setPageNumber(1));
    await getWorkOrderLogList(0, productionPerformanceReducer.workOrderType, productionPerformanceReducer.workOrderStatus)
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(productionPerformanceActions_setReset());
    dispatch(productionPerformanceActions_setPageNumber(1));
    getWorkOrderLogList(0, 'all', 'all', true);
  };

  /* 상세보기 */
  const showDetailWorkOrder = (item) => {
    setModalContent(item);
    setTimeout(setModalStatus(true), 1000);
  };

  /* ====================================================================== #6 */

  return (
    <Grid3Body contents={
      <>
        <Section className="Main">
          <NavBar
            title={
              <div className='Title'>
                <NavTitle menuCode={'407'} />
                <div className='selectButtons'>
                  {[
                    { status: 'all', name: '전체' },
                    { status: 'end', name: '완료' },
                    { status: 'cancel', name: '취소' },
                  ].map((workOrderStatus, index) => {
                    return (
                      <button key={index + '_statusButton'}
                        className={productionPerformanceReducer.workOrderStatus === workOrderStatus.status ? 'btn-inven-1' : 'btn-set'}
                        onClick={() => { handleSearchWorkOrderStatus(workOrderStatus.status); }}
                      >
                        {workOrderStatus.name}
                      </button>
                    )
                  })}
                </div>
              </div>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <div className="SearchSection">
                    <input
                      type="date"
                      className="SelectDate"
                      value={productionPerformanceReducer.startDate}
                      onChange={(e) => { dispatch(productionPerformanceActions_setStartDate(e.target.value)) }}
                    />

                    <IconRightArrow />

                    <input
                      type="date"
                      className="SelectDate"
                      value={productionPerformanceReducer.endDate}
                      onChange={(e) => { dispatch(productionPerformanceActions_setEndDate(e.target.value)) }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <div className="ContentCBox">
                      <select className="SearchOption detail">
                        <option value="accountName">거래처</option>
                      </select>
                      <input
                        className="SearchBar detail"
                        placeholder="Search..."
                        value={productionPerformanceReducer.searchText}
                        onInput={(e) => { dispatch(productionPerformanceActions_setSearchText(e.target.value)) }}
                      />
                    </div>

                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton" onClick={actReset}>초기화</div>
                  </div>
                </div>
              </>
            }
          />

          <TableSection content={
            <table>
              <thead style={{ zIndex: '800' }}>
                <tr>
                  <th style={{ minWidth: '120px', padding: 'unset', width: '120px' }}>작업상태</th>
                  <th style={{ minWidth: '150px', width: '150px' }}>거래처</th>
                  <th style={{ minWidth: '960px' }}>작업지시</th>
                </tr>
              </thead>
              <tbody>
                {_workOrderLogList.map((workOrderLog, index) => {
                  const workOrderInfo = checkNullParse(workOrderLog.workOrderCustomizedContent, {
                    BF: { date: '', amount: '' },
                    SF: { date: '', amount: '' },
                    MF: { date: '', amount: '' },
                    total: { date: '', amount: '' },

                    colors: '', // 색상
                    phoneNumber: '', // 전화번호
                  });

                  const processLogList = workOrderLog.processLogList;
                  processLogList.sort((a, b) => { return a.processLogId - b.processLogId });

                  return (<Fragment key={workOrderLog.workOrderLogId + '_' + index}>
                    <tr name="workOrderRow" data-key={workOrderLog.workOrderLogId}>
                      <StatusTdButton
                        statusStyle={{ cursor: 'default', minHeight: '100px', minWidth: '120px', width: '120px' }}
                        content={workOrderLog}
                        contentId={workOrderLog.workOrderLogId}
                        status={workOrderLog.workOrderStatus}
                      />
                      <td style={{ minWidth: '150px', width: '150px' }}>{workOrderLog.accountName}</td>
                      <td style={{ minWidth: '960px', padding: 'unset' }}>
                        <WorkOrderTable onClick={() => { showDetailWorkOrder(workOrderLog); }}>
                          <table data-key={workOrderLog.workOrderLogId}>
                            <colgroup>
                              <col width='50px'></col>
                              <col width='350px'></col>
                              <col width='50px'></col>
                              <col width='200px'></col>
                              <col width='50px'></col>
                              <col width='100px'></col>
                              <col width='50px'></col>
                              <col width='50px'></col>
                            </colgroup>
                            <tbody>
                              <tr>
                                <th rowSpan={3}>색상</th>
                                <td rowSpan={3} className='colors'>
                                  {workOrderInfo.colors}
                                </td>
                                <th>거래처</th>
                                <td>{workOrderLog.accountName}</td>
                                <th>B/F납기</th>
                                <td>{checkNullObject(workOrderInfo?.BF, false) ? workOrderInfo?.BF?.date : workOrderInfo.BFDate}</td>
                                <th>B/F</th>
                                <td>{checkNullObject(workOrderInfo?.BF, false) ? workOrderInfo?.BF?.amount : workOrderInfo.BFAmount}</td>
                              </tr>
                              <tr>
                                <th>전화번호</th>
                                <td>{workOrderInfo.phoneNumber}</td>
                                <th>S/F납기</th>
                                <td>{checkNullObject(workOrderInfo?.SF, false) ? workOrderInfo?.SF?.date : workOrderInfo.TotalDate}</td>
                                <th>S/F</th>
                                <td>{checkNullObject(workOrderInfo?.SF, false) ? workOrderInfo?.SF?.amount : workOrderInfo.TotalAmount}</td>
                              </tr>
                              <tr>
                                <th>주소</th>
                                <td>{workOrderLog.workPlace}</td>
                                <th>M/F납기</th>
                                <td>{workOrderInfo?.MF?.date}</td>
                                <th>M/F</th>
                                <td>{workOrderInfo?.MF?.amount}</td>
                              </tr>
                              <tr>
                                <th rowSpan={2}>비고</th>
                                <td colSpan={3} rowSpan={2} className='remark'>{workOrderLog.remark}</td>
                                <th>지시일자</th>
                                <td colSpan={3}>{DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledStartDate, ''))}</td>
                              </tr>
                              <tr>
                                <th>작성자</th>
                                <td colSpan={3}>{workOrderLog.userName}</td>
                              </tr>
                            </tbody>
                          </table>
                        </WorkOrderTable>
                      </td>
                    </tr>

                    <tr key={workOrderLog.workOrderLogId + '_ProcessRow'} style={{ borderBottom: '2px solid var(--Bg)', borderTop: '2px solid var(--Bg)' }}>
                      <td colSpan={5} style={{ height: '20px', padding: 'unset' }}>
                        <ProcessCell>
                          {processLogList.map((process, processIndex) => {
                            return (
                              <ProcessSection key={process.processLogId + '_ProcessSection'}>
                                <Process style={{ border: '1px solid var(--white)', minWidth: '60px', width: '60px' }} status={'NO'}>
                                  {(processIndex + 1).toString().padStart(2, '0')}
                                </Process>

                                {taskTypeArray.map((thisTask) => {
                                  let AllTask = [];
                                  let thisTaskList = { BF: [], CP: [], SF: [], MF: [], GB: [], MC: [] };

                                  if (process.taskLogList.length > 0) {
                                    AllTask = process.taskLogList.filter((thisItem) => thisItem.taskType.split('_')[0] === thisTask);
                                    AllTask.forEach((thisItem) => thisTaskList[thisItem.taskType.split('_')[0]].push(thisItem));
                                  }

                                  let thisTaskCutEndContent = { BF: [], CP: [], SF: [], MF: [], GB: [], MC: [] };
                                  let thisTaskWeldingEndContent = { BF: [], CP: [], SF: [], MF: [], GB: [], MC: [] };
                                  let thisTaskLastEndContent = { BF: [], CP: [], SF: [], MF: [], GB: [], MC: [] };

                                  if (process.taskLogList.length > 0) {
                                    AllTask.forEach((task) => {
                                      switch (task.taskStatus) {
                                        case 'cutend':
                                          thisTaskCutEndContent[task.taskType.split('_')[0]].push(task);
                                          return;
                                        case 'weldingend':
                                          thisTaskWeldingEndContent[task.taskType.split('_')[0]].push(task);
                                          thisTaskCutEndContent[task.taskType.split('_')[0]].push(task);
                                          return;
                                        case 'lastend':
                                          thisTaskLastEndContent[task.taskType.split('_')[0]].push(task);
                                          thisTaskWeldingEndContent[task.taskType.split('_')[0]].push(task);
                                          thisTaskCutEndContent[task.taskType.split('_')[0]].push(task);
                                          return;

                                        default: return;
                                      }
                                    });
                                  }

                                  if (
                                    checkNullArray(thisTaskList[thisTask], false) && checkEmptyNull(thisTaskCutEndContent[thisTask], false) &&
                                    checkNullArray(thisTaskWeldingEndContent[thisTask], false) && checkEmptyNull(thisTaskLastEndContent[thisTask], false)
                                  ) {
                                    if (process.processStatus === 'end') {
                                      return (<Process key={thisTask.processLogId + thisTask + '_Process'} status={'end'}>{`${thisTask}`}</Process>);
                                    } else {
                                      return (<Process key={thisTask.processLogId + thisTask + '_Process'} status={'last'}>{`${thisTask} - 마무리`}</Process>);
                                    }
                                  } else if (
                                    checkNullArray(thisTaskList[thisTask], false) && checkEmptyNull(thisTaskCutEndContent[thisTask], false) &&
                                    checkNullArray(thisTaskWeldingEndContent[thisTask], false) && !checkEmptyNull(thisTaskLastEndContent[thisTask], false)
                                  ) {
                                    return (<Process key={thisTask.processLogId + thisTask + '_Process'} status={'welding'}>{`${thisTask} - 용접`}</Process>);
                                  } else if (
                                    checkNullArray(thisTaskList[thisTask], false) && checkEmptyNull(thisTaskCutEndContent[thisTask], false) &&
                                    !checkNullArray(thisTaskWeldingEndContent[thisTask], false) && !checkEmptyNull(thisTaskLastEndContent[thisTask], false)
                                  ) {
                                    return (<Process key={thisTask.processLogId + thisTask + '_Process'} status={'cut'}>{`${thisTask} - 절단`}</Process>);
                                  } else {
                                    if (checkNullArray(thisTaskList[thisTask], false)) {
                                      return (<Process key={thisTask.processLogId + thisTask + '_Process'} status={process.processStatus}>{thisTask}</Process>);
                                    } else {
                                      return null;
                                    }
                                  }
                                })}
                              </ProcessSection>
                            );
                          })}
                        </ProcessCell>
                      </td>
                    </tr>
                  </Fragment>);
                })}
              </tbody>
            </table>
          }
          ></TableSection>
        </Section>

        <PagingComponent
          page={productionPerformanceReducer.pageNumber}
          count={productionPerformanceReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(productionPerformanceActions_setPageNumber(page));
          }}
        />

        {/* 모달 */}
        {/* 공정리스트 */}
        {_modalStatus === true ? (
          <UriDetailWorkOrder
            workOrderContent={_modalContent}
            open={_modalStatus}
            close={() => {
              // detail
              setModalStatus(false);
              getWorkOrderLogList(productionPerformanceReducer.pageNumber - 1, productionPerformanceReducer.workOrderType, productionPerformanceReducer.workOrderStatus);
            }}
          />
        ) : null}
      </>
    }
    />
  );
};

export default UriProductionPerformance;
