import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import ProgressBar from '@ramonak/react-progress-bar';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { ConnectStatus, TimeCount } from 'pages/monitoring/styles';
import Header from 'components/header/Header';
import MonitoringComponentFloting from 'pages/monitoring/components/floting';

const Section = styled.main`
  display: grid;
  grid-template-rows: auto minmax(calc(100% - 60px), 100%);
  height: 100svh;
  overflow: hidden;
  width: 100svw;

  header {box-shadow: none}

  section {
    box-sizing: border-box;
    height: 100%;
    padding: 20px;
    width: 100%;
    .monitoringBody {
      display: grid;
      gap: 20px;
      height: 100%;
      width: 100%;

      .TableSection {
        border-radius: 10px;
        box-shadow: 0 6px 6px 0 rgba(196, 196, 196, 0.8);
        height: 100%;
        zoom: 90%;
        table {
          height: 100%;
          td, th {min-width: unset;}
          thead th {
            background-color: #586a85;
            padding-block: 8px;
            padding-inline: 2px;
            word-break: keep-all;
            white-space: nowrap;
          }
        }
      }
      
      &.total {
        grid-template-columns: 34% 65%;
        grid-template-rows: repeat(2, 49%);
        grid-template-areas: 
        "material production"
        "product quality"
        ;
        .TableSection:first-child {grid-area: material;}
        .TableSection:nth-child(2) {grid-area: product;}
        .TableSection:nth-child(3) {grid-area: production;}
        .TableSection:last-child {grid-area: quality;}
      }
      &.element {
        grid-template-columns: repeat(2, 49%);
        grid-template-rows: repeat(2, 49%);
        grid-template-areas: 
        "material product"
        "half product"
        ;
        .TableSection:first-child {grid-area: material;}
        .TableSection:nth-child(2) {grid-area: half;}
        .TableSection:nth-child(3) {grid-area: product;}
      }
    }
  }
`;

const MonitoringIndex = ({children}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { monitoringReducer, userReducer, workReducer } = useSelector((state) => state);

  const [_authority, setAuthority] = useState([]);

  const [_onHeader, setOnHeader] = useState(false);
  const [_timeCounter, setTimeCounter] = useState(30);

  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('403') === true ||  // 작업지시
      thisItem.authority.startsWith('601') === true, // 품질관리
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    return () => {}
  }, [])

  useEffect(() => {
    const timeCount = setInterval(() => { setTimeCounter((prev) => {return prev - 1}) }, 1000);
    return () => clearInterval(timeCount);
  }, [_timeCounter]);

  useEffect(() => {}, [workReducer.connectStatus]);
  useEffect(() => {}, [monitoringReducer.monitoringView]);

  const clickHeader = (e) => {
    e.preventDefault();
    setOnHeader((prev) => {return !prev});
  };

  return (
    <Section>
      {_onHeader ? (<Header />) : <div></div> }
      <section>
        <Outlet context={{_authority, setTimeCounter}} {...children} />
      </section>
      <MonitoringComponentFloting authority={_authority} clickHeader={clickHeader} setTimeCounter={setTimeCounter} />
      <ConnectStatus connectStatus={workReducer.connectStatus} onClick={() => {navigate(0);}} />
      <TimeCount><ProgressBar completed={_timeCounter} maxCompleted={30} customLabel={' '} /></TimeCount>
    </Section>
  );
};

export default MonitoringIndex;