import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';

import { stockReducer_setContractAccount } from 'store/modules/actions/default/stockActions';

import {
  ACCESS_TOKEN,
  AXIOS_BASE_HEADERS,
  AXIOS_BASE_URL,
} from 'preferences/server/constants';

import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import Nav from 'components/nav/Nav';
import SelectOneAccount from 'pages/account/modal/SelectOneAccount';

const ContractDate = styled.input`
  border: 1px solid var(--gray-200);
  border-radius: 5px;
  box-sizing: border-box;
  height: 40px;
  padding: 5px;

  &:focus {
    border: 1px solid var(--gray-200);
  }
`;

const CopyGSProductionOrder = () => {
  /* ===================================================#======================================================*/
  const { contentsReducer, pageReducer, userReducer, stockReducer } =
    useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /* =================================================== #2 ===================================================*/
  const setDateFormat = (thisDate) => {
    if (
      thisDate !== undefined &&
      thisDate !== null &&
      typeof thisDate === 'object'
    ) {
      const newtDate = new Date(thisDate.join('-'));
      return moment(newtDate).format('YYYY-MM-DD');
    } else {
      return thisDate;
    }
  };

  const [_onload, setOnload] = useState('unload');

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,

    workOrderCode: contentsReducer.copyContents.workOrderCode,
    workOrderName: contentsReducer.copyContents.workOrderName,

    workOrderScheduledStartDate: setDateFormat(
      contentsReducer.copyContents.workOrderScheduledStartDate,
    ),
    workOrderScheduledEndDate: setDateFormat(
      contentsReducer.copyContents.workOrderScheduledEndDate,
    ),

    accountId: contentsReducer.copyContents.accountId,
    workPlace: contentsReducer.copyContents.workPlace,
    detailedWorkPlace: contentsReducer.copyContents.detailedWorkPlace,

    userId: userReducer.user.id,
    message: '',
    remark: '',

    workOrderCustomizedContent: JSON.stringify({}),

    processLogList: contentsReducer.copyContents.processLogList.map(
      (thisItem) => {
        return {
          existProcessOutElement: false,
          processTypeName: thisItem.processTypeName,
          processingCount: thisItem.processingCount,
          processCustomizedContent: thisItem.processCustomizedContent,
          deleteTaskIds: [],
          taskLogList: [],

          processName: thisItem.processName,
        };
      },
    ),
    deleteProcessList: [],
  });
  console.log(_formData);

  const [_ordersAccountName, setContractAccountName] = useState(
    contentsReducer.copyContents.accountName,
  );

  const [_modalStatus, setModalStatus] = useState(false);

  /* =================================================== #3 =================================================== */
  useEffect(() => {
    dispatch(stockReducer_setContractAccount({}));

    setOnload('loaded');

    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      if (Object.keys(stockReducer.ordersAccount).length > 0) {
        const thisAccountId = stockReducer.ordersAccount.accountId;
        const thisAccountName = stockReducer.ordersAccount.accountName;

        setFormData({
          ..._formData,
          accountId: thisAccountId,
          accountName: thisAccountName,
        });
        setContractAccountName(thisAccountName);
      }
    }

    return () => { };
  }, [stockReducer.ordersAccount]);

  /* =================================================== #4들 =================================================== */
  //
  const handleChangeEvent = (e) => {
    const { name, value } = e.target;
    setFormData({ ..._formData, [name]: value });
  };

  /* =================================================== #5들 =================================================== */
  // 작업저장
  const saveForm = async (e) => {
    e.preventDefault();
    if (_formData.accountId === undefined || _formData.accountId === '')
      return alert('거래처를 선택해주세요.');
    if (_formData.workOrderCode === '')
      return alert('작업코드를 입력해주세요.');
    if (_formData.workOrderName === '') return alert('공사명을 입력해주세요.');

    const formData = { ..._formData };

    if (_formData.workOrderScheduledStartDate === '') {
      delete formData['workOrderScheduledStartDate'];
    }
    if (_formData.workOrderScheduledEndDate === '') {
      delete formData['workOrderScheduledEndDate'];
    }

    const BodyToPost = { ...formData };
    console.table(BodyToPost);
    console.log('BodyToPost : ', BodyToPost, JSON.stringify(BodyToPost));

    await axios
      .post(AXIOS_BASE_URL + '/workOrderLog', BodyToPost, AXIOS_BASE_HEADERS)
      .then((response) => {
        if (response === undefined) return;
        console.log('put/workOrderLog - response : ', response);
        if (response.status === 200) {
          alert('불량 작업지시를 추가했습니다.');
          navigate(pageReducer.currentPage, { replace: true });
        }
      })
      .catch((error) => {
        console.error('put/workOrderLog - error : ', error);
      });
  };

  // 거래처 팝업 열기
  const actSelectAccount = () => {
    setModalStatus(true);
  };

  /* =================================================== #6들 =================================================== */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body
      contents={
        <>
          <main className="Main">
            <Nav
              thisTitle={'작업정의'}
              content={''}
              nav={''}
              search={''}
              searchButton={''}
              searchOptions={''}
              statusOptions={''}
            />

            <Form
              title={
                <>
                  불량
                  <br />
                  작업지시
                  <br />
                  추가
                </>
              }
              buttons={
                <>
                  <button className="formButton" onClick={saveForm}>
                    추가
                  </button>
                  <button
                    className="formButton cancle"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                </>
              }
              forms={
                <>
                  <div className="formBox">
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        lineHeight: '30px',
                      }}
                    >
                      <h4>거래처</h4>
                      <div
                        className="formButton"
                        style={{
                          borderRadius: '5px',
                          width: '150px',
                          backgroundColor: 'var(--ThirdBlue)',
                        }}
                        onClick={actSelectAccount}
                      >
                        거래처 선택
                      </div>
                    </div>
                    <input
                      readOnly
                      type="data"
                      name="accountId"
                      placeholder=" 거래처를 선택해주세요."
                      defaultValue={_ordersAccountName}
                      data-required="required"
                    />
                  </div>

                  <div className="formBox">
                    <h4>작업코드</h4>
                    <input
                      onChange={handleChangeEvent}
                      type="data"
                      name="workOrderCode"
                      placeholder="* 작업코드..."
                      defaultValue={_formData.workOrderCode || ''}
                      data-required="required"
                    />
                  </div>

                  <div className="formBox">
                    <h4>공사명</h4>
                    <input
                      onChange={handleChangeEvent}
                      type="text"
                      name="workOrderName"
                      placeholder="* 공사명..."
                      defaultValue={_formData.workOrderName || ''}
                      data-required="required"
                    />
                  </div>

                  <div className="formBox">
                    <h4>{userReducer.endDateName[0]}</h4>
                    <ContractDate
                      onChange={handleChangeEvent}
                      type="date"
                      name="workOrderScheduledStartDate"
                      defaultValue={_formData.workOrderScheduledStartDate || ''}
                    ></ContractDate>
                  </div>

                  <div className="formBox">
                    <h4>{userReducer.endDateName[1]}</h4>
                    <ContractDate
                      onChange={handleChangeEvent}
                      type="date"
                      name="workOrderScheduledEndDate"
                      defaultValue={_formData.workOrderScheduledEndDate || ''}
                    ></ContractDate>
                  </div>

                  <div className="formBox">
                    <h4>현장명</h4>
                    <input
                      onChange={handleChangeEvent}
                      type="text"
                      name="workPlace"
                      placeholder="현장명..."
                      defaultValue={_formData.workPlace || ''}
                    />
                  </div>

                  <div className="formBox">
                    <h4>현장주소</h4>
                    <input
                      onChange={handleChangeEvent}
                      type="text"
                      name="detailedWorkPlace"
                      placeholder="현장주소..."
                      defaultValue={_formData.detailedWorkPlace || ''}
                    />
                  </div>

                  {/* <div className="formBox">
              <h4>작업자들에게 알림</h4>
              <NoticeInput type="text" name="message" onChange={ handleChangeEvent } placeholder="작업자들에게 알림..." />
            </div>

            <div className="formBox">
              <h4>비고</h4>
              <textarea name="remark" onChange={ handleChangeEvent } placeholder="비고..."></textarea>
            </div>    */}
                </>
              }
              // formLayoutStyle={{
              // padding: '0px 30px',
              // width: '95%',
              // }}
              formSectionStyle={{
                paddingTop: '10px',
                width: '100%',
              }}
            />
          </main>

          {_modalStatus === true && (
            <SelectOneAccount
              buttonTitle={'거래처 선택'}
              buttonEvent={(data) => {
                dispatch(stockReducer_setContractAccount(data));

                setTimeout(setModalStatus(false), 1000);
              }}
              open={_modalStatus}
              close={() => {
                setModalStatus(false);
              }}
            ></SelectOneAccount>
          )}
        </>
      }
    />
  );
};

export default CopyGSProductionOrder;
