import React, { useState } from 'react';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import StatusTdButton from 'components/buttons/StatusTdButton';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto;
  height: 85%;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & form > div {background-color: unset;}
  & input[name="worker"]:disabled {
    background-color: var(--ThirdBlue);
    color: var(--white);
  }

  .TableSection {zoom: 85%;}
`;

const ShowManHourList = (props) => {
  const [_manHour, setManHour] = useState({ ...props.content });
  const workingTimeWorkOrderLogs = checkNullArray(props.content.workingTimeWorkOrderLogs, []).map((workingTimeWorkOrderLog) => {
    return { ...workingTimeWorkOrderLog, ...checkNullObject(workingTimeWorkOrderLog.workOrderLog, {}) };
  });
  const workingTimeProcessLogs = checkNullArray(props.content.workingTimeProcessLogs, []).map((workingTimeProcessLog) => {
    return { ...workingTimeProcessLog, ...checkNullObject(workingTimeProcessLog.processLog, {}) };
  })

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>공수 정보</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <Form
                title={<>공수<br />정보</>}
                buttons={<></>}
                forms={
                  <>
                    <div className="formBox w100">
                      <h4>작업자</h4>
                      <input type="text" name='worker' disabled value={_manHour.worker?.name} />
                    </div>

                    <div className="formBox">
                      <h4>날짜</h4>
                      <input type="date" name='date' disabled value={checkEmptyNull(_manHour.date, '')} />
                    </div>

                    <div className="formBox">
                      <h4>시간</h4>
                      <input type="text" name='time' disabled value={checkEmptyNull(_manHour.time, '')} />
                    </div>

                    <div className="formBox" style={{ width: '100%' }}>
                      <h4>작업내용</h4>
                      <textarea name='note' disabled value={checkEmptyNull(_manHour.note, '')} />
                    </div>

                    {checkNullArray(workingTimeWorkOrderLogs, false) ? <>
                      <div className="formBox" style={{ width: '100%' }}>
                        <h4>작업지시</h4>
                        <TableSection content={
                          <table>
                            <thead>
                              <tr>
                                <th>작업상태</th>
                                <th>작업유형</th>
                                <th>작업코드</th>
                                <th>작업이름</th>
                                <th>거래처</th>
                                <th>작업장소</th>
                                <th>기간</th>
                              </tr>
                            </thead>
                            <tbody>
                              {checkNullArray(workingTimeWorkOrderLogs, []).map((workOrderLog, index) => {
                                return (
                                  <tr key={index + '_workOrderRow'} data-key={workOrderLog.workOrderLogId}>
                                    <StatusTdButton
                                      statusStyle={{ cursor: 'default', height: '120px', minWidth: '120px', width: '120px' }}
                                      status={workOrderLog.workOrderStatus}
                                    />
                                    <td>{workOrderLog.workOrderTypeName}</td>
                                    <td>{workOrderLog.workOrderCode}</td>
                                    <td>{workOrderLog.workOrderName}</td>
                                    <td>{workOrderLog.accountName}</td>
                                    <td>{workOrderLog.workPlace}</td>
                                    <td>{DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledStartDate, ''))}<br /> ~ <br />{DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledEndDate, ''))}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        }></TableSection>
                      </div>
                    </> : null}

                    {checkNullArray(workingTimeProcessLogs, false) ? <>
                      <div className="formBox" style={{ width: '100%' }}>
                        <h4>공정</h4>
                        <TableSection content={
                          <table>
                            <thead>
                              <tr>
                                <th rowSpan={2}>공정상태</th>
                                <th rowSpan={2} style={{ minWidth: '80px', width: '80px' }}>순번</th>
                                <th colSpan={2}>공정정보</th>
                                <th colSpan={2}>진행상태</th>
                              </tr>
                              <tr>
                                <th>공정코드</th>
                                <th>공정이름</th>
                                <th>클릭</th>
                                <th>진행도</th>
                              </tr>
                            </thead>
                            <tbody>
                              {checkNullArray(workingTimeProcessLogs, []).map((processLog, index) => {
                                const currentClick = BigNumber(checkEmptyNull(processLog.completedAmount, 0)).dividedBy(BigNumber(checkEmptyNull(processLog.processingCount, 0))).toNumber();
                                const totalClick = BigNumber(checkEmptyNull(processLog.processAmount, 0)).dividedBy(BigNumber(checkEmptyNull(processLog.processingCount, 0))).toNumber();
                                return (
                                  <tr key={index + '_processLogs'}>
                                    <StatusTdButton
                                      statusStyle={{ cursor: 'default', height: '120px', minWidth: '120px', width: '120px' }}
                                      status={processLog.processStatus}
                                    />
                                    <td style={{ minWidth: '80px', width: '80px' }}>{processLog.processNumber}</td>
                                    <td>{processLog.processCode}</td>
                                    <td>{processLog.processName}</td>
                                    <td>{currentClick.toLocaleString()} / {totalClick.toLocaleString()}</td>
                                    <td>{(checkEmptyNull(processLog.completedAmount, 0) * 1).toLocaleString()} / {(checkEmptyNull(processLog.processAmount, 0) * 1).toLocaleString()}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        }></TableSection>
                      </div>
                    </> : null}
                  </>
                }
                formLayoutStyle={{ paddingInlineEnd: 'unset' }}
              />
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default ShowManHourList;
