import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { elementStockLogApi } from 'api/apis/elementStockLogApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import PagingComponent from 'components/paging/PagingComponent';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 40px 50px;
  height: 85%;
  overflow: hidden;
  width: 90%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const ModalHeaderButtons = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 55px;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;

    & input {cursor: pointer;}

    td, th {min-width: 200px; width: 200px;}
    td.blankCell {background-color: var(--Bg); opacity: 0.8;}
    td.eventCell, th.eventCell {background-color: var(--MainNavy); color: var(--white);}
    td.eventCell {padding: 5px;
      input {
        background-color: var(--white);
        box-sizing: border-box;
        font-size: 1.2em;
        height: 100%;
        margin: 0px auto;
        min-height: 50px;
        padding: 10px 15px;
        text-align: right;
        width: 100%;

        &:disabled {background-color: var(--gray-200);}
      }
    }
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const SelectElementStockLogs = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const ItemCheckBox = useRef();

  const { content } = props;
  const { element, index, process } = content;

  const [_onload, setOnload] = useState('unload');

  const [_logList, setLogList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState([]);

  const [_startDate, setStartDate] = useState('');
  const [_endDate, setEndDate] = useState('');

  const [_pageNum, setPageNum] = useState(1);
  const [_pageSize, setPageSize] = useState(0);

  /* ====================================================================== #3 */
  useEffect(() => {
    getElementStockLogList(0);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getElementStockLogList(_pageNum - 1);
      ItemCheckBox.current.checked = false;
    }

    return () => { };
  }, [_pageNum]);

  useEffect(() => { }, [_checkedItem]);

  /* ====================================================================== #4 */
  const getElementStockLogList = async (page, clear) => {
    const paging = `?page=${page}&size=10&sort=checkedDate,ASC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      elementId: element.elementId,
      logType: 'In',
      haveAvailableStock: true
    }
    if (!clear && checkEmptyNull(_startDate, false) && checkEmptyNull(_endDate, false)) {
      BodyToPost.elementStockLogStartDate = new Date(_startDate).toISOString();
      BodyToPost.elementStockLogEndDate = new Date(_endDate).toISOString();
      if (_startDate > _endDate) {
        BodyToPost.elementStockLogStartDate = new Date(_endDate).toISOString();
        BodyToPost.elementStockLogEndDate = new Date(_startDate).toISOString();
      }
    }
    await elementStockLogApi.searchElementStockLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('elementStockLogApi.searchElementStockLog : ', response);
      setLogList(() => {
        return response.data.content.map(item => {
          return {...item, scheduledInputStock: ''}
        });
      });
      setPageSize(() => {
        return response.data.totalElements
      })
    })
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (item, isChecked) => {
    const checkedItem = [..._checkedItem];
    const checkedIndex = checkedItem.findIndex((thisItem) => thisItem.elementStockLogId === item.elementStockLogId);
    if (checkedIndex !== -1) {
      if (!isChecked) checkedItem.splice(checkedIndex, 1);
    } else {
      if (isChecked) checkedItem.push(item);
    }
    setCheckedItem(() => {return checkedItem;});
  };

  const setMinMaxNum = (value, min, max) => {
    let returnNum = value;
    if (value < min) returnNum = min;
    if (value > max) returnNum = max;
    return returnNum;
  };
  const actSelect = () => {
    const checkedItemList = [..._checkedItem];
    const returnData = {
      index: index,
      process: process,
      element: element,
      inputElementStockLogs: checkedItemList
    }
    props.buttonEvent(returnData);
  }

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>재고 연동</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <TableSection content={
                <table>
                  <thead>
                    <tr>
                      <th style={{ minWidth: '50px', width: '50px' }}>
                        <input
                          type="checkBox"
                          name="allElements"
                          ref={ItemCheckBox}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            if (isChecked) {
                              setCheckedItem((prev) => {
                                const prevData = [...prev];
                                const newData = _logList.map((thisItem) => {
                                  const thisFindIndex = prevData.findIndex((thisIndex) => thisIndex.elementStockLogId === thisItem.elementStockLogId);
                                  if (thisFindIndex !== -1) return null;
                                  else return thisItem;
                                });
                                const filterData = newData.filter((thisItem) => thisItem !== null);
                                return [...prevData, ...filterData];
                              });
                              e.target.checked = true;
                            } else {
                              setCheckedItem((prev) => {
                                const prevData = prev.map((thisItem) => {
                                  const thisFindIndex = _logList.findIndex((thisIndex) => thisIndex.elementStockLogId === thisItem.elementStockLogId);
                                  if (thisFindIndex !== -1) return null;
                                  else return thisItem;
                                });
                                const filterData = prevData.filter((thisItem) => thisItem !== null);
                                return filterData;
                              });
                              e.target.checked = false;
                            }
                          }}
                        />
                      </th>
                      <th>가용수량</th>
                      <th>입고수량</th>
                      <th>입력시각</th>
                      <th>물품이름</th>
                      <th>물품코드</th>
                      <th>유통기한</th>
                      <th>금액</th>
                      <th>재고 장소</th>
                      <th>비고</th>
                      <th>작업자</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkNullArray(_logList, []).map((elementStockLog, index) => {
                      if (elementStockLog.logType !== 'In') return null;
                      return (<tr key={index + '_elementStockLog'}>
                        <td style={{ minWidth: '50px', width: '50px' }}>
                          <input
                            type="checkBox"
                            name="elementStockLogs"
                            checked={_checkedItem.findIndex(item => item.elementStockLogId === elementStockLog.elementStockLogId) !== -1}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              handleCheckedItem(elementStockLog, isChecked);
                            }}
                          />
                        </td>
                        <td>{checkEmptyNull(elementStockLog.availableStock, 0).toLocaleString()}</td>
                        <td>{checkEmptyNull(elementStockLog.amount, 0).toLocaleString()}</td>
                        <td>{DateTimeFormat(checkEmptyNull(elementStockLog.createdDate, ''))}</td>
                        <td>{elementStockLog?.element.elementName}</td>
                        <td>{elementStockLog?.element.elementCode}</td>
                        <td>{DateTimeFormat(checkEmptyNull(elementStockLog.checkedDate, ''))}</td>
                        <td>{(checkEmptyNull(elementStockLog.price, 0) * 1).toLocaleString()}</td>
                        <td>{checkEmptyNull(elementStockLog.stockPlaceName, '')}</td>
                        <td>{elementStockLog.note}</td>
                        <td>{checkEmptyNull(elementStockLog.userName, '')}</td>
                      </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
              ></TableSection>
            </ModalMain>

            <PagingComponent
              page={_pageNum}
              count={_pageSize}
              size={10}
              pageEvent={(page) => {
                console.log('page : ', page);
                setPageNum(() => { return page });
              }}
            />

            <ModalFooter>
              <ModalButton onClick={actSelect}>{props.buttonTitle}</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default SelectElementStockLogs;
