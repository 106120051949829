import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  height: 85%;
  overflow: hidden;
  width: 90%;

  grid-template-rows: ${(props) => {
    return props.type === 'list' ? '55px calc(100% - 55px)' : '55px auto 50px';
  }};
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const UpdateButton = styled.button`
  position: absolute;
  right: 55px;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;

    input, textarea {
      border: 1px solid var(--gray-200);
      border-radius: 5px;
      box-sizing: border-box;
      color: var(--MainBlue);
      font-size: 20px;
      font-weight: 600;
      height: 40px;
      min-width: 200px;
      padding: 5px 15px;
      width: 98%;
    }
    textarea {height: 95%;}
  }
`;
const DelButton = styled.div`
  height: 15px;
  margin: 0px auto;
  width: 15px;
  div {background-color: var(--MainRed); cursor: pointer; height: 15px; width: 15px;}
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const GSBLWorkList = (props) => {
  /* ====================================================================== #1 */
  const { gsReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const workOrderLog = gsReducer.workOrderContent;
  const [_processLogList, setProcessLogList] = useState(gsReducer.workOrderContent.processLogList.filter((thisProcess) => thisProcess.processTypeName === 'BL'));

  const [_showUpdateList, setShowUpdateList] = useState(false);

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    userId: userReducer.user.id,
    workOrderStatus: workOrderLog.workOrderStatus,
    workOrderLogId: workOrderLog.workOrderLogId,
    workOrderCode: workOrderLog.workOrderCode,
    workOrderName: workOrderLog.workOrderName,
    workOrderScheduledStartDate: DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledStartDate, '')),
    workOrderScheduledEndDate: DateFormat(checkEmptyNull(workOrderLog.workOrderScheduledEndDate, '')),
    accountId: workOrderLog.accountId,
    workPlace: workOrderLog.workPlace,
    detailedWorkPlace: workOrderLog.detailedWorkPlace,
    message: workOrderLog.message,
    remark: workOrderLog.remark,
    workOrderCustomizedContent: workOrderLog.workOrderCustomizedContent,
    processLogList: [],
    deleteProcessList: [],
  });

  const [_updateWorkOrderList, setUpdateWorkOrderList] = useState([]);
  const [_deleteWorkOrderList, setDeleteWorkOrderList] = useState([]);

  const [_CPData, setCPData] = useState([]);
  const [_CLData, setCLData] = useState([]);

  /* ====================================================================== #3 */
  useEffect(() => {
    const processLogList = gsReducer.workOrderContent.processLogList.filter((thisItem) => thisItem.processTypeName === 'BL');
    setProcessLogList(processLogList);
    setCPData(gsReducer.workOrderContent.processLogList.filter((thisItem) => thisItem.processTypeName === 'CP'));
    setCLData(gsReducer.workOrderContent.processLogList.filter((thisItem) => thisItem.processTypeName === 'CL'));
    const updateWorkOrderList = processLogList.map((thisItem) => {
      return {
        existProcessOutElement: false,
        processTypeName: 'BL',
        processStatus: thisItem.processStatus,
        processLogId: thisItem.processLogId,
        processingCount: thisItem.processingCount,
        processCustomizedContent: JSON.parse(thisItem.processCustomizedContent),
        deleteTaskIds: [],
        taskLogList: [],

        processName: thisItem.processName,
        processCode: thisItem.processCode,
      };
    });
    setUpdateWorkOrderList(updateWorkOrderList);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {}, [_updateWorkOrderList])

  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const handleCustomEvent = (e, index, processCustomizedContent, processLog) => {
    const { name, value } = e.target;
    console.log(name, ' : ', value);
    setUpdateWorkOrderList((prev) => {
      const returnData = [...prev];
      const newData = {...processLog, processCustomizedContent: {...processCustomizedContent, [name]: value}}
      returnData.splice(index, 1, newData);
      return returnData;
    });
  };
  const delOrder = (index, processLog) => {
    setDeleteWorkOrderList([..._deleteWorkOrderList, processLog.processLogId]);
    setUpdateWorkOrderList((prev) => {
      const returnData = [...prev];
      returnData.splice(index, 1);
      return returnData;
    });
  };

  const actUpdate = async () => {
    const BodyToPut = { ..._formData };
    const BLData = _updateWorkOrderList.map((thisItem) => {return {...thisItem, processCustomizedContent: JSON.stringify(thisItem.processCustomizedContent)}});
    const setAllProcessLogList = [...BLData, ..._CPData, ..._CLData];
    BodyToPut.processLogList = setAllProcessLogList;
    BodyToPut.deleteProcessLogList = _deleteWorkOrderList;
    console.log('BodyToPut : ', BodyToPut);

    await workOrderLogApi.updateWorkOrderLog(BodyToPut.workOrderLogId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.updateWorkOrderLog : ', response);
      alert('작업지시가 수정되었습니다.');
      props.close();
    })
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          {_showUpdateList !== true ? (
            <ModalSection type="list" style={{ zoom: '100%' }}>
              <ModalHeader>
                <ModalTitle>캠버리스트</ModalTitle>
                <UpdateButton
                  className="formButton"
                  onClick={() => {
                    // reset
                    setUpdateWorkOrderList([]);
                    setDeleteWorkOrderList([]);

                    const updateWorkOrderList = _processLogList.map((processLog) => {
                      return {
                        existProcessOutElement: false,
                        processTypeName: 'BL',
                        processStatus: processLog.processStatus,
                        processLogId: processLog.processLogId,
                        processingCount: processLog.processingCount,
                        processCustomizedContent: JSON.parse(processLog.processCustomizedContent),
                        deleteTaskIds: [],
                        taskLogList: [],

                        processName: processLog.processName,
                      };
                    });
                    setUpdateWorkOrderList(updateWorkOrderList);

                    setShowUpdateList(true);
                  }}
                >
                  리스트 수정
                </UpdateButton>
                <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
              </ModalHeader>

              <ModalMain>
                <TableSection content={
                  <table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '65px', width: '65px' }}>순번</th>
                        <th>부재번호</th>
                        <th>규격</th>
                        <th>단위</th>
                        <th>길이(m)</th>
                        <th>수량</th>
                        <th>기준<br />캠버값(mm)</th>
                        <th>실제<br />캠버값(mm)</th>
                        <th>비고</th>
                      </tr>
                    </thead>

                    <tbody>
                      {checkNullArray(_processLogList, []).map((processLog, index) => {
                        const processCustomizedContent = checkNullParse(processLog.processCustomizedContent, {
                          num: (index + 1).toString().padStart(2, '0'),
                          absentNumber: '',
                          standard: '',
                          unit: '',
                          length: '',
                          quantity: '',
                          defaultCamber: '',
                          realCamber: '',
                          remark: '',
                        })
                        return (
                          <tr key={index + '_BLRow'}>
                            <td style={{ minWidth: '65px', width: '65px' }} data-col="num"  data-colname="순번">{processCustomizedContent.num}</td>
                            <td data-col="absentNumber" data-colname="부재번호">{processCustomizedContent.absentNumber}</td>
                            <td data-col="standard" data-colname="규격">{processCustomizedContent.standard}</td>
                            <td data-col="unit" data-colname="단위">{processCustomizedContent.unit}</td>
                            <td data-col="length" data-colname="길이">{processCustomizedContent.length}</td>
                            <td data-col="quantity" data-colname="수량">{processCustomizedContent.quantity}</td>
                            <td data-col="defaultCamber" data-colname="기준캠버값">{processCustomizedContent.defaultCamber}</td>
                            <td data-col="realCamber" data-colname="실제캠버값">{processCustomizedContent.realCamber}</td>
                            <td data-col="remark" data-colname="비고">{processCustomizedContent.remark}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                }
                />
              </ModalMain>
            </ModalSection>
          ) : (
            <ModalSection type="update" style={{ zoom: '100%' }}>
              <ModalHeader>
                <ModalTitle>캠버리스트 수정</ModalTitle>
                <UpdateButton className="formButton" onClick={() => {setShowUpdateList(false);}}>리스트 보기</UpdateButton>
                <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
              </ModalHeader>

              <ModalMain>
                <TableSection content={
                  <table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '65px', width: '65px' }}>순번</th>
                        <th>부재번호</th>
                        <th>규격</th>
                        <th>단위</th>
                        <th>길이(m)</th>
                        <th>수량</th>
                        <th>기준<br />캠버값(mm)</th>
                        <th>실제<br />캠버값(mm)</th>
                        <th>비고</th>
                        <th style={{ minWidth: '50px', width: '50px' }}></th>
                      </tr>
                    </thead>

                    <tbody>
                      {checkNullArray(_updateWorkOrderList, []).map((processLog, index) => {
                        const processCustomizedContent = checkNullObject(processLog.processCustomizedContent, {
                          num: (index + 1).toString().padStart(2, '0'),
                          absentNumber: '',
                          standard: '',
                          unit: '',
                          length: '',
                          quantity: '',
                          defaultCamber: '',
                          realCamber: '',
                          remark: '',
                        })
                        return (
                          <tr key={index + '_BLRow'}>
                            <td style={{ minWidth: '65px', width: '65px' }} data-col="num"  data-colname="순번">{processCustomizedContent.num}</td>
                            <td data-col="absentNumber" data-colname="부재번호">
                              <input
                                type="text"
                                name="absentNumber"
                                value={processCustomizedContent.absentNumber}
                                onInput={(e) => {e.preventDefault(); handleCustomEvent(e, index, processCustomizedContent, processLog);}}
                              />                              
                            </td>
                            <td data-col="standard" data-colname="규격">
                              <input
                                type="text"
                                name="standard"
                                value={processCustomizedContent.standard}
                                onInput={(e) => {e.preventDefault(); handleCustomEvent(e, index, processCustomizedContent, processLog);}}
                              />                              
                            </td>
                            <td data-col="unit" data-colname="단위">
                              <input
                                type="text"
                                name="unit"
                                value={processCustomizedContent.unit}
                                onInput={(e) => {e.preventDefault(); handleCustomEvent(e, index, processCustomizedContent, processLog);}}
                              />                                                            
                            </td>
                            <td data-col="length" data-colname="길이">
                              <input
                                type="text"
                                name="length"
                                value={processCustomizedContent.length}
                                onInput={(e) => {e.preventDefault(); handleCustomEvent(e, index, processCustomizedContent, processLog);}}
                              />                                                            
                            </td>
                            <td data-col="quantity" data-colname="수량">
                              <input
                                type="text"
                                name="quantity"
                                value={processCustomizedContent.quantity}
                                onInput={(e) => {e.preventDefault(); handleCustomEvent(e, index, processCustomizedContent, processLog);}}
                              />                                                            
                            </td>
                            <td data-col="defaultCamber" data-colname="기준캠버값">
                              <input
                                type="text"
                                name="defaultCamber"
                                value={processCustomizedContent.defaultCamber}
                                onInput={(e) => {e.preventDefault(); handleCustomEvent(e, index, processCustomizedContent, processLog);}}
                              />                                                            
                            </td>
                            <td data-col="realCamber" data-colname="실제캠버값">
                              <input
                                type="text"
                                name="realCamber"
                                value={processCustomizedContent.realCamber}
                                onInput={(e) => {e.preventDefault(); handleCustomEvent(e, index, processCustomizedContent, processLog);}}
                              />                                                            
                            </td>
                            <td data-col="remark" data-colname="비고">
                              <textarea
                                type="text"
                                name="remark"
                                value={processCustomizedContent.remark}
                                onInput={(e) => {e.preventDefault(); handleCustomEvent(e, index, processCustomizedContent, processLog);}}
                              />                                                            
                            </td>
                            <td style={{ minWidth: '50px', width: '50px' }}>
                              <DelButton onClick={(e) => {e.preventDefault(); delOrder(index, processLog);}}><CloseButtonIcon /></DelButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                }
                />
              </ModalMain>

              <ModalFooter>
                <ModalButton onClick={actUpdate}>저장</ModalButton>
              </ModalFooter>
            </ModalSection>
          )}
        </ModalBg>
      )}
    </>
  );
};

export default GSBLWorkList;
