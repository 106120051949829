import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';

const CalendarSection = styled.main`
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 66px calc(100% - 66px);
  `;

const SchedulerSection = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: grid;  
  height: 100%;
  justify-content: center;
  overflow: hidden;
  padding: 20px 40px;
  width: 100%;

  &>div {
    box-sizing: border-box;
    overflow: scroll;
    width: 95svw;
  }

  a {cursor: default; // 이벤트 클릭 비활성화
    &:hover {background: none;} // 이벤트 호버 비활성화
  }

  /* .fc-daygrid-body {
    width: 100%;
    &>div {width: 100%}
  } */

  /* .fc {width: 80vw;} */
  // 헤더 마진
  .fc .fc-toolbar.fc-header-toolbar {box-sizing: border-box; padding: 16px 10px; margin: unset;}
  // 헤더 버튼 패딩
  .fc .fc-button {padding: 8px 16px;}
  // 헤더 버튼 폰트 사이즈
  .fc .fc-button, .fc .fc-button-group, .fc .fc-button:disabled {font-size: 0.9em;}

  // 헤더 버튼 컬러
  .fc .fc-button-primary,
  .fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled).fc-button-active {}

  .fc .fc-view-harness-active > .fc-view,
  .gBRwOh .fc .fc-toolbar.fc-header-toolbar,
  .fc .fc-media-screen .fc-direction-ltr .fc-theme-standard {background-color: var(--white);}

  .fc-today-button:disabled {opacity: unset;}

  .fc-daygrid-block-event .fc-event-time,
  .fc-daygrid-block-event .fc-event-title {
    padding: 2px 10px;
    white-space: nowrap;
    word-break: break-all;
    text-overflow: ellipsis;
    font-size: 1rem;
    &:hover {overflow: inherit; z-index: 999;}
  }

  .fc-daygrid-event-harness {
    &:hover {
      background-color: #fff;
      box-shadow: 0 2px 6px 0 rgba(196,196,196,0.9);
      color: rgb(35 94 30);
      font-weight: bold;
      opacity: 1;
      z-index: 999;
    }
  }

  .fc-daygrid-dot-event {align-items: flex-start;
    .fc-daygrid-event-dot {transform: translateY(7px);}
  }

  .fc-daygrid-dot-event .fc-event-title {
    font-weight: 400;
    line-height: 17px;
    overflow: unset;
    max-width: 89%;
    white-space: pre-line;
    word-break: break-all;
  }

  .fc-h-event .fc-event-main {
    color: #000;
    font-size: 18px;
  }
`;

const Scheduler = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const { userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const fullCalendar = useRef();

  const [_onload, setOnload] = useState('unload');

  const [_currentCalenderList, setCurrentCalendarList] = useState([]);
  const [_calenderDataList, setCalendarDataList] = useState([]);

  const [_startDate, setStartDate] = useState();
  const [_endDate, setEndDate] = useState();

  const [_searchDate, setSearchDate] = useState();

  const [_searchOption, setSearchOption] = useState('');
  const [_searchText, setSearchText] = useState('');

  const [_searchStatus, setSearchStatus] = useState(false);

  /* ========================================================================= #3*/
  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => { }, [_currentCalenderList]);

  /* ========================================================================= #4*/
  const getCalendarList = async (fetchInfo, successCallback) => {
    const startDate = moment(fetchInfo.startStr).format('YYYY-MM-DD');
    const endDate = moment(fetchInfo.endStr).format('YYYY-MM-DD');
    if (startDate === _startDate && endDate === _endDate && !_searchStatus) return successCallback(_calenderDataList);
    setStartDate(() => { return startDate; });
    setEndDate(() => { return endDate; });
    setSearchStatus(() => { return false; });

    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    BodyToPost.scheduledStartDate = startDate;
    BodyToPost.scheduledEndDate = endDate;
    await workOrderLogApi.searchWorkOrderLog('?size=100', BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('getCalendarList - response : ', response);

      const matchArray = [];
      const returnMapData = response.data.data.content.map((thisItem) => {
        const returnData = {};
        returnData['id'] = thisItem.workOrderLogId;
        returnData['title'] = `${thisItem.workOrderName === null ? '' : thisItem.workOrderName} 
        (${thisItem.workOrderCode === null ? '' : thisItem.workOrderCode})`;
        returnData['start'] = DateFormat(thisItem.workOrderScheduledStartDate);
        returnData['end'] = moment(thisItem.workOrderScheduledEndDate).add(1, 'd').format('YYYY-MM-DD');
        returnData['color'] = 'rgba(145, 202, 140, 0.8)';

        if (checkEmptyNull(_searchOption, false) && checkEmptyNull(_searchText, false)) {
          if (thisItem[_searchOption].includes(_searchText)) returnData['color'] = 'rgba(52, 175, 56, 0.8)';
        }
        return returnData;
      });

      setCurrentCalendarList(() => { return response.data.data.content; });
      setCalendarDataList(() => { return [...returnMapData, ...matchArray]; });
      successCallback([...returnMapData, ...matchArray]);
    })
  };

  /* ========================================================================= #5*/
  /* 오른쪽 클릭 기본 이벤트 막기 */
  window.oncontextmenu = () => { return false; };

  const actSearch = async () => {
    if (checkEmptyNull(_searchDate, false)) {
      if (checkEmptyNull(_searchOption, false) && checkEmptyNull(_searchText, false)) {
        setSearchStatus(() => { return true; });
      } else {
        const fullCalendarApi = fullCalendar.current.getApi();
        fullCalendarApi.gotoDate(_searchDate);
      }
    } else {
      if (checkEmptyNull(_searchOption, false) && checkEmptyNull(_searchText, false)) {
        setSearchStatus(() => { return true; });
      }
    }
  };
  const actReset = () => {
    setSearchDate(() => { return ''; });
    setSearchOption(() => { return ''; });
    setSearchText(() => { return ''; });
    setSearchStatus(() => { return true; });
    const fullCalendarApi = fullCalendar.current.getApi();
    fullCalendarApi.gotoDate(moment().format('YYYY-MM-DD'));
  };

  /* ========================================================================= #6 */

  return (
    <Grid2Body contents={
      <>
        <CalendarSection className="Main">
          <NavBar
            title={'작업일정'}
            buttons={
              <>
                <div className="SearchSection">
                  <div className="ContentCBox">
                    <input
                      type={'date'}
                      className="SelectDate"
                      style={{ marginRight: '10px' }}
                      value={_searchDate}
                      onChange={(e) => { setSearchDate(() => { return moment(e.target.value).format('YYYY-MM-DD'); }); }}
                    />

                    <div className="ContentCBox">
                      <select
                        className="SearchOption"
                        value={_searchOption}
                        onChange={(e) => { setSearchOption(() => { return e.target.value; }); }}
                      >
                        <option value="">검색옵션</option>
                        <option value="workOrderCode">작업코드</option>
                        <option value="workOrderName">작업이름</option>
                      </select>
                      <input
                        className="SearchBar"
                        placeholder="Search..."
                        value={_searchText}
                        onInput={(e) => { setSearchText(() => { return e.target.value; }); }}
                      />
                    </div>
                  </div>

                  <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                  <div className='ResetButton' onClick={actReset}>초기화</div>
                </div>
              </>
            }
            nav={''}
          />

          <SchedulerSection>
            <FullCalendar
              ref={fullCalendar}
              plugins={[dayGridPlugin]}
              dayHeaders={true}
              initialView="dayGridMonth"
              locale="ko" // 한글로 변경
              firstDay="0" // 시작 요일(월요일)
              buttonText={{
                week: '주별',
                month: '월별',
                today: '오늘',
              }}
              events={(fetchInfo, successCallback, failureCallback) => {
                getCalendarList(fetchInfo, successCallback, failureCallback);
              }} // 이벤트 데이터
              displayEventTime={false} // 이벤트 시간 표기 여부
              headerToolbar={{
                left: 'dayGridMonth dayGridWeek',
                center: 'title',
                right: 'prev,today,next',
              }}
              eventBackgroundColor="var(--SeconYellow)" // 오늘 날짜 색상
              height="100%" // 캘린더 전체 높이
            />
          </SchedulerSection>
        </CalendarSection>
      </>
    }
    />
  );
};

export default Scheduler;
