import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

import { qna } from 'api/apis/mall/qna';
import { qnaReply } from 'api/apis/mall/qnaReply';

import { checkEmptyNull } from 'components/checkValues/checkValues';
import { DateTimeFormatKR } from 'components/format/DateTimeFormatKR';
import { MallStatusName } from 'components/status/MallStatusName';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import ShowMallFileList from 'pages/mallManagement/modal/ShowMallFileList';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--Bg);
  display: grid;
  grid-template-rows: 55px calc(100% - 55px);
  height: 85%;
  overflow: hidden;
  width: 90%;
  border-radius: 16px;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--white);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const ModalHeaderButtons = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 55px;
`;
const ModalHeaderButton = styled.button`
  max-width: 80px;

  &.cancel {background-color: var(--gray-200);}
  &.delete {background-color: var(--MainRed);}
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  display: grid;
  grid-template-rows: 300px auto 20%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  .TableSection {overflow: auto;}
`;

const MallQnAInfo = styled(TableSection)`
  background-color: var(--white);
  height: 100%;
  overflow: auto;
  width: 100%;
`;
const MallQnAInfoTable = styled.table`
  height: 100%;

  & td, & th {min-width: unset;}
  & td.QnAInfos {
    border-right: 1px solid var(--gray-200);
    box-sizing: border-box;
    padding: unset;
  }
`;
const MallStatusTd = styled.td`
  color: var(--white);

  &.waiting {animation: WaitingStatus 1.5s infinite; background-color: var(--gray-900);}
  &.consulting {background-color: var(--SeconBlue);}
  &.preparing {background-color: var(--ThirdBlue);}
  &.complete {background-color: var(--MainNavy);}
  &.requestCancel {background-color: var(--MainRed);}
  &.cancel {background-color: var(--Bg);}
`;
const FileTd = styled.td`
  background-color: var(--MainBlue);
  color: var(--white);
  cursor: pointer;
  min-width: 50px;
  width: 50px;
`;
const MallElementList = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(auto-fit, minmax(40px, fit-content));
  justify-content: flex-start;
  width: inherit;
  zoom: 100%;
`;
const MallElement = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 100%;
  min-height: 40px;
  height: fit-content;
  width: 100%;

  & p {
    align-items: center;
    border: 1px solid var(--gray-200);
    display: flex;
    height: 100%;
    justify-content: center;
  }
  &.title p {
    background-color: var(--MainNavy);
    color: var(--white);
  }
`;

const ReplySection = styled.div`
  height: 100%;
  overflow-y: scroll;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}
`;
const ReplyList = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 20px;
  row-gap: 15px;
  width: 100%;
`;
const UserReplyCon = styled.div`
  align-items: center;
  align-self: flex-end;
  column-gap: 20px;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  input[type='checkbox'] {height: 20px; margin: unset; width: 20px;}
  p {align-self: flex-end; font-size: 0.8em;}
`;
const MallUserReplyCon = styled(UserReplyCon)`
  align-self: unset;
  justify-content: unset;
  align-self: flex-start;
  justify-content: flex-start;
`;
const UserReply = styled.div`
  background-color: #fef01b;
  border-radius: 10px;
  box-sizing: border-box;
  max-width: 80%;
  padding: 10px;
  width: fit-content;
`;
const MallUserReply = styled(UserReply)`
  background-color: var(--white);
`;

const SendSection = styled.div`
  background-color: var(--white);
  height: 100%;
  position: relative;
  width: 100%;
`;
const TextSection = styled.div`
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-bottom: 55px;
  width: 100%;

  textarea {
    box-sizing: border-box;
    border: none;
    font-size: 1.2em;
    height: 100%;
    outline: none;
    padding: 20px;
    resize: none;
    width: 100%;
  }
`;
const SendButton = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  border-radius: 4px;
  bottom: 10px;
  color: var(--gray-200);
  cursor: pointer;
  font-size: 0.8em;
  display: flex;
  height: 35px;
  justify-content: center;
  position: absolute;
  right: 10px;
  width: 60px;

  &.active {background-color: #fef01b; color: #556677;}
`;

const MallQnAReply = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const { mallQnA } = props;
  const { mallQnAId, mallQnACode, status } = mallQnA;

  const mallReplyEndRef = React.createRef();

  const [_onload, setOnload] = useState('unload');

  const [_mallQnA, setMallQnA] = useState(mallQnA);

  const [_replyList, setReplyList] = useState([]);

  const [_reply, setReply] = useState('');

  const [_page, setPage] = useState(1);
  const [_pageTotalSize, setPageTotalSize] = useState(0);

  const [_fileListModalStatus, setFileListModalStatus] = useState(false);

  const [_editMode, setEditMode] = useState(false);
  const [_checkedItem, setCheckedItem] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    getReplyList(_page - 1);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {scrollToBottom(); return () => scrollToBottom();}, [_replyList]);

  /* ====================================================================== #4 */
  const getMallQnAData = async () => {
    await qna.getMallQnA(mallQnA.mallQnAId).then((response) => {
      if (response === undefined) return;
      console.log('qna.getMallQnA : ', response);
      setMallQnA(() => {return response.data; });
    });
  };

  const getReplyList = async (page) => {
    const paging = `?page=${page}&size=50`;
    const BodyToPost = {companyId: userReducer.company.companyId, mallQnAId: mallQnA.mallQnAId};
    console.log('BodyToPost : ', BodyToPost);
    await qnaReply.searchMallQnAReply(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('qnaReply.searchMallQnAReply : ', response);

      setReplyList(() => {
        const sortReplyList = response.data.content.sort((a, b) => moment(a.createdDate) - moment(b.createdDate));
        return sortReplyList;
      });
      setPageTotalSize(() => {return response.data.totalElements;});
    });
  };

  /* ====================================================================== #5 */
  const scrollToBottom = () => {
    if (!checkEmptyNull(mallReplyEndRef.current, false)) return;
    mallReplyEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleCheckedItem = (reply, isChecked) => {
    if (isChecked) setCheckedItem(() => {return reply;});
    else setCheckedItem(() => {return {};});
  };

  const actReply = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      mallQnAId: mallQnAId,
      userId: userReducer.user.id,
      content: _reply,
    };
    await qnaReply.createMallQnAReply(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('qnaReply.createMallQnAReply : ', response);
      setReply(() => {return '';});
      getReplyList(0);
    });
  };

  const actDeleteReply = async () => {
    if (!checkEmptyNull(_checkedItem.mallQnAReplyId, false)) return;

    const confirmText = `댓글을 삭제합니다.`;
    if (window.confirm(confirmText)) {
      await qnaReply.deleteMallQnAReply(_checkedItem.mallQnAReplyId).then((response) => {
        if (response === undefined) return;
        console.log('qnaReply.deleteMallQnAReply : ', response);
        alert('댓글을 삭제했습니다.');
        getReplyList(0);
      });
    }
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>{mallQnACode || ''}</ModalTitle>
              <ModalHeaderButtons>
                {!_editMode ? (
                  <ModalHeaderButton className="formButton" onClick={() => {setEditMode((prev) => {return !prev;});}}>편집</ModalHeaderButton>
                ) : (
                  <>
                    <ModalHeaderButton className="formButton delete" onClick={() => {actDeleteReply();}}>삭제</ModalHeaderButton>
                    <ModalHeaderButton className="formButton cancel" onClick={() => {setEditMode((prev) => {return !prev;});}}>취소</ModalHeaderButton>
                  </>
                )}
              </ModalHeaderButtons>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <MallQnAInfo content={
                  <MallQnAInfoTable style={{height: '100%'}}>
                    <colgroup>
                      <col width={'60px'} />
                      <col width={'60px'} />
                      <col width={'15%'}/>
                      <col width={'15%'}/>  
                      <col/>
                      <col/>
                    </colgroup>
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th>문의코드</th>
                        <th>문의자</th>
                        <th>문의내용</th>
                        <th>문의물품</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <MallStatusTd className={status || 'waiting'} >{MallStatusName(status || 'waiting')}</MallStatusTd>
                        <FileTd onClick={() => {setFileListModalStatus(true);}}>첨부<br />파일</FileTd>
                        <td>{_mallQnA.mallQnACode}</td>
                        <td>{_mallQnA.mallUserName}</td>
                        <td>{_mallQnA.mallQnAContent}</td>
                        <td style={{minWidth: '350px', padding: 'unset', verticalAlign: 'top'}}>
                          <MallElementList>
                            <MallElement className="title">
                              <p>이름</p>
                              <p>코드</p>
                            </MallElement>
                            {_mallQnA.mallQnAElementList.map((element, elementIndex) => {
                              return (
                                <MallElement key={elementIndex + '_mallQnAElements'}>
                                  <p>{element.mallElementName}</p>
                                  <p>{element.mallElementCode}</p>
                                </MallElement>
                              );
                            })}
                          </MallElementList>
                        </td>
                      </tr>
                    </tbody>
                  </MallQnAInfoTable>
                }
              />
              <ReplySection>
                <ReplyList>
                  {_replyList.map((reply, index) => {
                    if (checkEmptyNull(reply.userId, false) && reply.userId === userReducer.user.id) {
                      return (
                        <UserReplyCon key={index + '_userReply'}>
                          <p>{DateTimeFormatKR(reply.createdDate)}</p>
                          <UserReply>{reply.content}</UserReply>
                          {_editMode ? (
                            <input
                              type="checkBox"
                              name="userReply"
                              data-key={reply.mallQnAReplyId}
                              checked={(() => {
                                if (_checkedItem.mallQnAReplyId === reply.mallQnAReplyId) return true;
                                else return false;
                              })()}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                handleCheckedItem(reply, isChecked);
                              }}
                              onClick={(e) => {e.stopPropagation();}}
                            />
                          ) : null}
                        </UserReplyCon>
                      );
                    } else {
                      return (
                        <MallUserReplyCon key={index + '_mallUserReply'}>
                          <MallUserReply>{reply.content}</MallUserReply>
                          <p>{DateTimeFormatKR(reply.createdDate)}</p>
                        </MallUserReplyCon>
                      );
                    }
                  })}
                  <div ref={mallReplyEndRef}></div>
                </ReplyList>
              </ReplySection>
              <SendSection>
                <TextSection>
                  <textarea
                    value={_reply}
                    onInput={(e) => {setReply(() => {return e.target.value;});}}
                  />
                </TextSection>
                <SendButton
                  className={_reply.length > 0 ? 'active' : null}
                  onClick={() => {if (_reply.length > 0) actReply();}}
                >
                  전송
                </SendButton>
              </SendSection>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}

      {_fileListModalStatus === true && mallQnAId !== undefined ? (
        <ShowMallFileList
          type={'mallQnA'}
          content={mallQnA}
          update={() => {getMallQnAData();}}
          open={_fileListModalStatus}
          close={() => {setFileListModalStatus(false);}}
        ></ShowMallFileList>
      ) : null}
    </>
  );
};

export default MallQnAReply;
