import { AxiosInterceptor } from "api/core/consts";

export const userApi = {
  getUserMe: () => AxiosInterceptor.get('/user/me'),
  updateUserMe: (body) => AxiosInterceptor.put('/user/selfCompany', body),
  getUsers: (paging) => AxiosInterceptor.get('/user' + paging),
  getUser: (userId) => AxiosInterceptor.get('/user/' + userId),
  searchUser: (paging, body) => AxiosInterceptor.post('/user/search' + paging, body),
  createUser: (body) => AxiosInterceptor.post('/user', body),
  updateUser: (userId, body) => AxiosInterceptor.put('/user/' + userId, body),
  deleteUser: (userId) => AxiosInterceptor.delete('/user/' + userId),
  updatePassword: (userId, body) => AxiosInterceptor.put('/user/' + userId + '/password', body),

  createWorker: (body) => AxiosInterceptor.post('/user/worker', body),
};
