const initialState = {
  elementType: 'material',
  searchData: {
    elementName: '',
    elementCode: '',
  },
  searchAttrs: {},

  clickedCategory: {},
  clickedLevel1Category: {},
  clickedLevel2Category: {},
  clickedLevel3Category: {},
  clickedLevel4Category: {},
  clickedLevel5Category: {},

  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,

  element: {},
  elements: [],

  level1Categories: [],
  level2Categories: [],
  level3Categories: [],
  level4Categories: [],
  level5Categories: [],

  bookmark: true,
};

const resetState = {
  elementType: 'material',
  searchData: {
    elementName: '',
    elementCode: '',
  },
  searchAttrs: {},

  clickedCategory: {},
  clickedLevel1Category: {},
  clickedLevel2Category: {},
  clickedLevel3Category: {},
  clickedLevel4Category: {},
  clickedLevel5Category: {},

  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const currentStockReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETCURRENTSTOCKTYPE':
      return {
        ...state,
        elementType: action.payload,
      };
    case 'SETCURRENTSTOCKSEARCHDATA':
      return {
        ...state,
        searchData: action.payload,
      };
    case 'SETCURRENTSTOCKSEARCHATTS':
      return {
        ...state,
        searchAttrs: action.payload,
      };

    case 'SETCURRENTSTOCKCLICKEDCATEGORY':
      return {
        ...state,
        clickedCategory: action.payload,
      };
    case 'SETCURRENTSTOCKCLICKEDLEVEL1CATEGORY':
      return {
        ...state,
        clickedLevel1Category: action.payload,
      };
    case 'SETCURRENTSTOCKCLICKEDLEVEL2CATEGORY':
      return {
        ...state,
        clickedLevel2Category: action.payload,
      };
    case 'SETCURRENTSTOCKCLICKEDLEVEL3CATEGORY':
      return {
        ...state,
        clickedLevel3Category: action.payload,
      };
    case 'SETCURRENTSTOCKCLICKEDLEVEL4CATEGORY':
      return {
        ...state,
        clickedLevel4Category: action.payload,
      };
    case 'SETCURRENTSTOCKCLICKEDLEVEL5CATEGORY':
      return {
        ...state,
        clickedLevel5Category: action.payload,
      };

    case 'SETCURRENTSTOCKPAGENUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'SETCURRENTSTOCKTOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };
    case 'SETCURRENTSTOCKISPUSHEDSEARCHBUTTON':
      return {
        ...state,
        isPushedSearchButton: action.payload,
      };

    case 'SETCURRENTSTOCKRESET':
      return {
        ...state,
        ...resetState,
      };

    case 'SELECTONESTOCK':
      return {
        ...state,
        element: action.payload,
      };

    case 'SELECTSTOCKS':
      return {
        ...state,
        elements: action.payload,
      };

    case 'SETLEVEL1CATEGORIES':
      return {
        ...state,
        level1Categories: action.payload,
      };
    case 'SETLEVEL2CATEGORIES':
      return {
        ...state,
        level2Categories: action.payload,
      };
    case 'SETLEVEL3CATEGORIES':
      return {
        ...state,
        level3Categories: action.payload,
      };
    case 'SETLEVEL4CATEGORIES':
      return {
        ...state,
        level4Categories: action.payload,
      };
    case 'SETLEVEL5CATEGORIES':
      return {
        ...state,
        level5Categories: action.payload,
      };

    case 'SETCURRENTSTOCKBOOKMARK':
      return {
        ...state,
        bookmark: action.payload,
      };

    default:
      return state;
  }
};

export default currentStockReducer;
