const initialState = {
  userCheck: false,

  user: {},
  company: {},

  selectUser: {},

  elementType: [],
  stockType: 'IN/PUT/OUT',

  putName: '투입',
  endDateName: ['1차 납기일', '2차 납기일'],

  userMenuCode: [],
  userMenu: [],

  subscribe: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USERCHECKING': return { ...state, userCheck: action.payload };

    case 'SETUSER': return { ...state, user: action.payload };
    case 'SETUSERCOMPANY': return { ...state, company: action.payload };

    case 'SETSELECTUSER': return { ...state, selectUser: action.payload };

    case 'SETUSERELEMENTTYPE': return { ...state, elementType: action.payload };
    case 'SETUSERSTOCKTYPE': return { ...state, stockType: action.payload };
    case 'SETUSERPUTNAME': return { ...state, putName: action.payload };
    case 'SETUSERENDDATENAME': return { ...state, endDateName: action.payload };

    case 'SETUSERMENUCODE': return { ...state, userMenuCode: action.payload };
    case 'SETUSERMENU': return { ...state, userMenu: action.payload };

    case 'SETUSERSUBSCRIBE': return { ...state, subscribe: [...state.subscribe, action.payload] };
    case 'RESETUSERSUBSCRIBE': return { ...state, subscribe: [] };

    case 'USERRESET': return initialState;

    default: return state;
  }
};

export default userReducer;

// import { createAction, handleActions } from 'redux-actions';

// const USERCHECKING = 'userReducer/user_checking';
// export const user_checking = createAction(USERCHECKING);

// const initialState = {
//   userCheck: 0
// };

// export default handleActions({
//   [USERCHECKING] : (state, data) => {
//     let userCheck = state.userCheck;

//     if(data.payload.bool)
//       userCheck += 1;

//     return {
//       ...state,
//       userCheck: userCheck
//     }
//   }

// }, initialState);

// menuLinks : {
//   '100': '/baseInformation/ProductInformation',
//   '101': '/baseInformation/ProductInformation',
//   '102': '/baseInformation/CategoryManagement',
//   '103': '/baseInformation/AttributeManagement',
//   '104': '/baseInformation/Account',
//   '105': '/baseInformation/UserManagement',
//   '106': '/baseInformation/AuthorityManagement',

//   '200': '/orderManagement/Contract',
//   '201': '/orderManagement/Contract',
//   '202': '/orderManagement/Procurement',
//   '203': '/orderManagement/NotInStock',
//   '204': '/orderManagement/DrawingManagement',

//   '300': '/stockManagement/CurrentStock',
//   '301': '/stockManagement/CurrentStock',
//   '302': '/stockManagement/StockIn',
//   '303': '/stockManagement/StockPut',
//   '304': '/stockManagement/StockOut',
//   '305': '/stockManagement/StockHistory',
//   '306': '/stockManagement/Transactions',

//   '400': '/productionManagement/ProductionOrder',
//   '401': '/productionManagement/Processes',
//   '402': '/productionManagement/WorkOrder',
//   '403': '/productionManagement/ProductionOrder',
//   '404': '/productionManagement/Scheduler',
//   '405': '/productionManagement/ProductionStatus',
//   '406': '/productionManagement/CalendarPlan',
//   '407': '/productionManagement/ProductionReport',

//   '500': '/working/WorkerScreen',

//   '600': '/qualityManagement/DefectStatus',
//   '601': '/qualityManagement/Causes',
//   '602': '/qualityManagement/DefectStatus',

//   '700': '/',
// },

// topMenus : [
//   {menuCode: '100',menuName: '기준정보'},
//   {menuCode: '200',menuName: '수주관리'},
//   {menuCode: '300',menuName: '재고관리'},
//   {menuCode: '400',menuName: '작업관리'},
//   {menuCode: '500',menuName: '작업진행'},
//   // {menuCode: '600',menuName: '품질관리'},
//   // {menuCode: '700',menuName: '모니터링'},
// ],

// subMenus : [
//   {menuCode: '101',menuName: '물품정보'},
//   {menuCode: '102',menuName: '패밀리설정'},
//   {menuCode: '103',menuName: '항목설정'},
//   {menuCode: '104',menuName: '거래처 설정'},
//   {menuCode: '105',menuName: '사용자설정'},
//   // {menuCode: '106',menuName: '권한설정'},

//   {menuCode: '201',menuName: '수주관리'},
//   {menuCode: '202',menuName: '발주관리'},
//   {menuCode: '203',menuName: '미입고관리'},
//   {menuCode: '204',menuName: '도면관리'},

//   {menuCode: '301',menuName: '현재고'},
//   {menuCode: '302',menuName: '입고'},
//   {menuCode: '303',menuName: '투입'},
//   // {menuCode: '304',menuName: '출하'},
//   {menuCode: '305',menuName: '재고상세이력'},
//   // {menuCode: '306',menuName: '거래명세서'},

//   // {menuCode: '401',menuName: '공정정의'},
//   // {menuCode: '402',menuName: '작업정의'},
//   {menuCode: '403',menuName: '작업지시'},
//   {menuCode: '404',menuName: '작업일정'},
//   // {menuCode: '405',menuName: '작업현황'},
//   // {menuCode: '406',menuName: '일일계획'},
//   // {menuCode: '407',menuName: '작업실적'},

//   {menuCode: '500',menuName: '작업진행'},

//   // {menuCode: '601',menuName: '불량사유관리'},
//   // {menuCode: '602',menuName: '불량현황'},

//   // {menuCode: '700',menuName: '모니터링'},
// ],

// case 'SETMENULINKS':
//   return {
//     ...state,
//     menuLinks: action.payload
//   };

// case 'SETSUBMENUS':
//   return {
//     ...state,
//     subMenus: action.payload
//   };
