import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { elementStockLogApi } from 'api/apis/elementStockLogApi';
import { stockPlace } from 'api/apis/stock/stockPlace';

import { checkEmptyNull, checkNullArray, checkNullParse } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat';

import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

const StockHistorySection = styled.main`
  & input:disabled {
    background-color: var(--Bg);
    border: none;
  }
  & input[name="amount"] {
    &.In {background-color: var(--MainGreen)}
    &.Put {background-color: var(--MainYellow)}
    &.Make {background-color: var(--MainBlue)}
    &.Out {background-color: var(--MainRed)}
    color: var(--white);
  }
`;

const UpdateStockHistory = () => {
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  const [searchParams, setSearchParams] = useSearchParams();
  const updateContent = checkNullParse(searchParams.get('updateContent'), {});

  const [_formData, setFormData] = useState({ ...updateContent });

  const [_stockPlaceList, setStockPlaceList] = useState([]);

  const [_price, setPrice] = useState(checkEmptyNull(updateContent.price, 0));
  const [_checkedDate, setCheckedDate] = useState(checkEmptyNull(updateContent.checkedDate, false) ? DateTimeFormat(updateContent.checkedDate) : '');
  const [_stockPlace, setStockPlace] = useState(checkEmptyNull(updateContent.stockPlaceId, ''));
  const [_note, setNote] = useState(checkEmptyNull(updateContent.note, ''));

  useEffect(() => {
    getStockPlaceList();
    return () => { };
  }, []);

  const getStockPlaceList = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId
    }
    await stockPlace.searchStockPlace(BodyToPost).then(response => {
      if (response === undefined) return;
      console.log('stockPlace.searchStockPlace : ', response);
      setStockPlaceList(() => { return response.data });
    })
  }

  const handleLogTypeName = (logType) => {
    switch (logType) {
      case 'In': return '입고';
      case 'Put': return '투입';
      case 'Make': return '생산';
      case 'Out': return '출고';

      default: return '';
    }
  }

  const actUpdate = async (e) => {
    e.preventDefault();
    const BodyToPut = {
      companyId: userReducer.company.companyId,

      elementStockLogId: _formData.elementStockLogId,

      price: !isNaN(Number(_price)) ? Number(_price) : 0,
      stockPlaceId: _stockPlace,
      note: _note
    }
    if (checkEmptyNull(_checkedDate, false)) {
      const checkedDate = new Date(_checkedDate);
      checkedDate.setHours(checkedDate.getHours() + 9);
      BodyToPut.checkedDate = checkedDate.toISOString(); // 유통기한
    }

    await elementStockLogApi.updateElementStockLog(updateContent.elementStockLogId, BodyToPut).then(response => {
      if (response === undefined) return;
      console.log('elementStockLogApi.updateElementStockLog : ', response);
      alert('재고상세이력을 수정했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    })
  }

  window.onkeydown = (e) => {
    const keyCode = e.keyCode;
    if (keyCode === 116) e.returnValue = false;
  };

  return (
    <Grid2Body contents={
      <>
        <StockHistorySection className='Main'>
          <NavBar title={<NavTitle menuCode={'305'} />} nav={''} />

          <Form
            title={<><NavTitle menuCode={'305'} /><br />수정</>}
            buttons={
              <>
                <button className="formButton" onClick={actUpdate}>저장</button>
                <button className="formButton cancle"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(pageReducer.currentPage, { replace: true });
                  }}
                >
                  취소
                </button>
              </>
            }
            forms={
              <>
                <div className='formBox'>
                  <h4>입력시각</h4>
                  <input type='text' disabled value={DateTimeFormat(checkEmptyNull(_formData.createdDate, ''))} />
                </div>
                <div className='formBox'>
                  <h4>물품이름</h4>
                  <input type='text' disabled value={_formData.element?.elementName} />
                </div>
                <div className='formBox'>
                  <h4>물품코드</h4>
                  <input type='text' disabled value={_formData.element?.elementCode} />
                </div>
                <div className='formBox'>
                  <h4>수량</h4>
                  <input type='text' name='amount' className={_formData.logType} disabled value={handleLogTypeName(_formData.logType) + ' ' + checkEmptyNull(_formData.amount, 0).toLocaleString() + ' ' + checkEmptyNull(_formData.element?.elementUnit, '')} />
                </div>
                <div className='formBox'>
                  <h4>작업자</h4>
                  <input type='text' disabled value={_formData.userName} />
                </div>
                <div className='formBox'></div>

                <div className='formBox'>
                  <h4>금액</h4>
                  <input
                    type='text'
                    name='price'
                    placeholder='금액...'
                    value={checkEmptyNull(_price, 0).toLocaleString()}
                    onInput={e => {
                      e.preventDefault();
                      let eventValue = e.target.value.replace(/[^\d.-]/g, '');
                      if (eventValue.length > 15) return;
                      if (!checkEmptyNull(eventValue, false)) eventValue = 0;
                      if (isNaN(eventValue) || eventValue === '0') eventValue = 0;
                      eventValue = BigNumber(eventValue).toNumber();
                      console.log('eventValue : ', eventValue);
                      setPrice(() => { return eventValue });
                    }}
                  />
                </div>
                <div className='formBox'>
                  <h4>유통기한</h4>
                  <input
                    type='datetime-local'
                    name='checkedDate'
                    value={_checkedDate}
                    onInput={e => {
                      e.preventDefault();
                      const checkedDate = e.target.value;
                      if (!checkEmptyNull(checkedDate, false)) return;
                      setCheckedDate(() => { return DateTimeFormat(checkedDate) })
                    }}
                  />
                </div>
                <div className='formBox'>
                  <h4>재고위치</h4>
                  <select
                    name='stockPlace'
                    value={_stockPlace}
                    onChange={(e) => {
                      e.preventDefault();
                      const stockPlace = e.target.value;
                      if (!checkEmptyNull(stockPlace, false)) return setStockPlace(() => { return '' });
                      setStockPlace(() => { return Number(stockPlace) })
                    }}
                  >
                    <option value={''}>선택</option>
                    {checkNullArray(_stockPlaceList, []).map((option, index) => {
                      return <option key={index + '_stockPlaces'} value={option.stockPlaceId}>{option.placeName}</option>
                    })}
                  </select>
                </div>
                <div className='formBox'>
                  <h4>비고</h4>
                  <textarea
                    name='note'
                    maxLength={1000}
                    value={_note}
                    onInput={e => {
                      e.preventDefault();
                      const note = e.target.value;
                      if (note.length > 1000) return;
                      setNote(() => { return note; });
                    }}
                  ></textarea>
                </div>


              </>
            }
          />
        </StockHistorySection>
      </>
    } />
  );
};

export default UpdateStockHistory;