import styled from 'styled-components';

export const SensorDataSection = styled.main`
  display: grid;
  grid-template-rows: 66px calc(100% - 66px);

  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    width: fit-content;

    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
    }
  }

  & nav {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    /* overflow-x: auto; */
    /* overflow-y: hidden; */
    width: 100svw;
    
    &>div {
      display: flex;
      /* grid-template-columns: 20% 80%; */
      justify-content: space-between;
      overflow: hidden;
      width: 100%;

      &>div {
        justify-content: unset;

        & input, & select {
          width: 200px;
          &.SearchBar {width: 120px;}
          &.SearchOption {width: 120px;}
        }
      }

      &>div:first-of-type {
        justify-self: flex-start;
        max-width: 20%;
        width: fit-content;
      }
      &>div:last-of-type {
        justify-self: flex-end;
        /* justify-content: flex-end; */
        overflow-x: auto;
        max-width: 80%;
        width: fit-content;

        &::-webkit-scrollbar {display: none;}

        &>div {
          box-sizing: border-box;
          padding-inline: 10px;
          width: fit-content;
          /* width: 100%; */
        }
        & .SearchSection {flex-wrap: unset;}
      }

    }
  }
`;

export const SensorTable = styled.div`
  background-color: #fff;
  display: grid;
  grid-template-rows: calc(100% - 60px) 60px;
  height: 100%;
  justify-items: center;
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  
  & table {
    border-collapse: collapse;
    width: 100%;
    max-width: 1280px;
    position: relative;

    th {
      border-bottom: 1px solid #000;
      padding: 10px;
      padding-top: 20px;
      text-align: left;
      position: sticky;
      top: 0px;
      z-index: 1;
      background-color: #fff;
    }
    
    td {
      padding: 10px;
      text-align: left;
    }
  }
`;