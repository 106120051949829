import styled from "styled-components";

export const ProjectTypeManagementComponent = styled.section`
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 100px 50px calc(100% - 190px);
  gap: 20px;
  height: 100%;
  overflow: hidden;
  padding: 50px;
  width: 100%;

  & header {
    align-items: baseline;
    box-sizing: border-box;
    display: flex;
    height: 100px;
    justify-content: space-between;
    width: 100%;

    & h1 {
      font-size: 40px;
      font-weight: 600;
    }
    & button {
      align-items: center;
      background-color: #246BEB;
      border-radius: 8px;
      box-sizing: border-box;
      color: var(--white);
      display: flex;
      gap: 5px;
      justify-content: center;
      line-height: 26px;
      padding: 10px 16px;

      &>svg {
        height: 24px;
        margin-block-start: 3px;
        width: 24px;
      }
    }
  }

  & nav { // 검색
    & .search-bar {
      align-items: center;
      box-sizing: border-box;
      border: 1px solid #1D1D1D;
      border-radius: 5px;
      display: flex;
      gap: 10px;
      height: 50px;
      justify-content: space-between;
      padding-inline: 15px;
      width: 45%;

      &>input {
        width: 100%;
      }

      &>button {
        height: 50px;
        width: 50px;
        &:hover {
          background: unset;
        }

        &>svg {
          height: 24px;
          width: 24px;
        }
      }
    }
  }

  &>div { // 유형 리스트
    height: 100%;
    overflow-y: scroll;
    width: 100%;

    & table {
      border-collapse: collapse;
      min-width: 100%;
      height: fit-content;
      position: relative;

      & thead {
        background-color: var(--white);
        position: sticky;
        top: 0;
        z-index: 1;
      }
  
      & th, & td {
        box-sizing: border-box;
        
        padding: 10px;
        text-align: left;
      }
      & thead th {border-block-end: 1px solid #1D1D1D; font-size: 17px;}
      & tbody td {border-block-end: 1px solid #d8d8d8; font-size: 16px;}
  
      & td:last-of-type { // 수정/삭제 버튼
        &>div {
          align-items: center;
          display: flex;
          justify-content: space-between;
  
          &>div {
            background-color: #717171;
            height: 15px;
            width: 1px;
          }
          &>button {
            align-items: center;
            border-radius: 3px;
            box-sizing: border-box;
            display: flex;
            font-size: 15px;
            /* color: #717171; */
            gap: 2px;
            justify-content: center;
            padding-inline: 5px;
            width: fit-content;
  
            &>svg {
              height: 20px;
              width: 20px;
            }
  
            &:hover {background: unset;}
            &:first-of-type:hover {color: #173E99;}
            &:last-of-type:hover {
              background-color: #EB003B;
              color: var(--white);
            }
          }
        }
      }
  
      & .type-color { // 유형 색상
        /* border: 1px solid; */
        border-radius: 50%;
        box-sizing: border-box;
        height: 25px;
        width: 25px;
      }
    }
  }

`;