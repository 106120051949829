export const shipmentInspectionItemActions_setShipmentInspectionItemType = (shipmentInspectionItemType) => {
  return {
    type: 'SETSHIPMENTINSPECTIONITEMTYPE',
    payload: shipmentInspectionItemType,
  };
};

export const shipmentInspectionItemActions_setSearchData = (searchData) => {
  return {
    type: 'SETSEARCHSHIPMENTINSPECTIONITEMDATA',
    payload: searchData,
  };
};
export const shipmentInspectionItemActions_setSearchOption = (searchOption) => {
  return {
    type: 'SETSEARCHSHIPMENTINSPECTIONITEMOPTION',
    payload: searchOption,
  };
};
export const shipmentInspectionItemActions_setSearchText = (searchText) => {
  return {
    type: 'SETSEARCHSHIPMENTINSPECTIONITEMTEXT',
    payload: searchText,
  };
};

export const shipmentInspectionItemActions_setPageNumber = (pageNumber) => {
  return {
    type: 'SETSHIPMENTINSPECTIONITEMPAGENUMBER',
    payload: pageNumber,
  };
};
export const shipmentInspectionItemActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETSHIPMENTINSPECTIONITEMTOTALSIZE',
    payload: totalSize,
  };
};

export const shipmentInspectionItemActions_setIsPushedSearchButton = (isPushedSearchButton) => {
  return {
    type: 'SETSHIPMENTINSPECTIONITEMISPUSHEDSEARCHBUTTON',
    payload: isPushedSearchButton,
  };
};

export const shipmentInspectionItemActions_setReset = () => {
  return {
    type: 'SETSHIPMENTINSPECTIONITEMRESET',
  };
};
