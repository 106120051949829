import React from 'react';
import styled from 'styled-components';

import { refreshButtonIcon } from 'components/icons/src';

const Icon = styled.div`
  background-color: var(--MainNavy);
  cursor: pointer;
  height: 25px;
  width: 25px;

  mask-image: url(${refreshButtonIcon});
  mask-repeat: no-repeat;
  mask-size: contain;

  --webkit-mask-image: url(${refreshButtonIcon});
  --webkit-mask-repeat: no-repeat;
  --webkit-mask-size: contain;

  &:hover {
    transform: scale(1.2);
  }
`;

const RefreshButtonIcon = (props) => {
  return <Icon style={props.style} />;
};

export default RefreshButtonIcon;
