import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { reciprocalTransactionProposer } from 'api/apis/platform/cluster/cluster';

const SalesCommentForm = ({
  modalType, modalData,
  update,
  close
}) => {
  const location = useLocation();
  const clusterId = location.pathname.split('/platform/cluster')[0];
  const clusterPath = clusterId + '/platform/cluster/mypage';

  const { clusterReducer } = useSelector(state => state);
  const { user } = clusterReducer;

  const [_body, setBody] = useState({ ...modalData.data });
  useEffect(() => { }, [_body]);
  console.log(_body);


  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setBody((prev) => { return { ...prev, [name]: value }; });
  }

  const actSave = async () => { // create/update
    const body = { ..._body, number: Number(_body.number) };
    console.log('save:', body);

    if (modalType.includes('create')) { // create
      await reciprocalTransactionProposer.create(body).then(response => {
        if (response === undefined) return;
        console.log('reciprocalTransactionProposer.create : ', response);
        // update(modalType, response.data, modalData.index);
        update();
      })
    } else if (modalType.includes('update')) { // update
      delete body?.createdDate;
      delete body?.modifiedDate;

      // return update(modalType, _body, modalData.index);
      await reciprocalTransactionProposer.update(_body.reciprocalTransactionProposerId, body).then(response => {
        if (response === undefined) return;
        console.log('reciprocalTransactionProposer.update : ', response);
        update(modalType, response.data, modalData.index);
        // update();
      })
    }
  };
  const actDelete = async () => { // delete
    const confirmText = `해당 신청을 삭제하시겠습니까?`;
    if (window.confirm(confirmText)) {
      // return update(modalType, _body, modalData.index);
      await reciprocalTransactionProposer.delete(_body.reciprocalTransactionProposerId).then(response => {
        if (response === undefined) return;
        console.log('reciprocalTransactionProposer.delete : ', response);
        // setProposerList((prev) => {
        //   const returnList = [...prev];
        //   returnList.splice(index, 1);
        //   return returnList;
        // })
        update(modalType, _body, modalData.index);
        // update();
      })
    }
  };

  return (
    <section>
      <div className='section-form'>
        <div className='form-content'>
          <h4>수량</h4>
          <input
            type='number'
            name='number'
            placeholder={`숫자만 입력할 수 있습니다.`}
            disabled={modalType.endsWith('delete')}
            pattern="\d*"
            min="0"
            value={_body.number || ''}
            onInvalid={(e) => { e.target.setCustomValidity('숫자만 입력할 수 있습니다.'); }}
            onInput={(e) => {
              e.target.setCustomValidity('');
              handleInputEvent(e);
            }}
          />
        </div>
        <div className='form-content'>
          <h4>신청내용</h4>
          <textarea
            name='detail'
            disabled={modalType.endsWith('delete')}
            defaultValue={_body.detail || ''}
            onInput={(e) => {
              handleInputEvent(e);
            }}
          />
        </div>
      </div>
      <div className='section-action'>
        <button className='btn-cancel' onClick={close}>취소</button>
        {!modalType.endsWith('delete') ? (<button className='btn-save' onClick={actSave}>저장</button>) : (<button className='btn-delete' onClick={actDelete}>삭제</button>)}
      </div>
    </section>
  );
};

export default SalesCommentForm;
