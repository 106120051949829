import React from 'react';

const TreeHeader = ({ onSelect, style, customStyles, node }) => {
  const iconType = node.children ? 'folder' : 'file-text';
  const iconClass = `fa fa-${iconType}`;
  const iconStyle = { marginRight: '5px' };

  return (
    <span style={style.base} onClick={onSelect}>
      <i className={iconClass} style={iconStyle} />
      {node.name}
    </span>
  );
};

export default TreeHeader;
