const initialState = {
  updateOrder: {},
  deleteOrder: {},

  procurementContent: {},
  workOrderContent: {},
  inspectionContent: {},

  orderProducts: [],
  barcodeContent: [],

  statusContent: {},
};

const gsReducer = (state = initialState, action) => {
  // console.log(state, action);
  switch (action.type) {
    case 'SETGSUPDATEORDER':
      return {
        ...state,
        updateOrder: action.payload,
      };

    case 'SETGSDELETEORDER':
      return {
        ...state,
        deleteOrder: action.payload,
      };
    case 'SETGSSHIPMENTCONTENT':
      return {
        ...state,
        procurementContent: action.payload,
      };
    case 'SETGSWORKORDERCONTENT':
      return {
        ...state,
        workOrderContent: action.payload,
      };
    case 'SETGSINSPECTIONCONTENT':
      return {
        ...state,
        inspectionContent: action.payload,
      };
    case 'SETGSORDERPRODUCTS':
      return {
        ...state,
        orderProducts: action.payload,
      };
    case 'SETGSBARCODECONTENT':
      return {
        ...state,
        barcodeContent: action.payload,
      };
    case 'SETGSSTATUSCONTENT':
      return {
        ...state,
        statusContent: action.payload,
      };

    case 'GSRESET':
      return initialState;

    default:
      return state;
  }
};

export default gsReducer;
