const initialState = {
  workOrderType: 'all',
  workOrderStatus: 'all',
  startDate: undefined,
  endDate: undefined,
  searchData: {},
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
}
const resetState = {
  workOrderType: 'all',
  workOrderStatus: 'all',
  startDate: undefined,
  endDate: undefined,
  searchData: {},
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const costReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETCOSTWORKORDERTYPE': return {...state, workOrderType: action.payload};
    case 'SETCOSTWORKORDERSTATUS': return {...state, workOrderStatus: action.payload};
    case 'SETCOSTSTARTDATE': return {...state, startDate: action.payload};
    case 'SETCOSTENDDATE': return {...state, endDate: action.payload};
    case 'SETCOSTSEARCHDATA': return {...state, searchData: action.payload};
    case 'SETCOSTSEARCHOPTION': return {...state, searchOption: action.payload};
    case 'SETCOSTSEARCHTEXT': return {...state, searchText: action.payload};
    case 'SETCOSTPAGENUMBER': return {...state, pageNumber: action.payload};
    case 'SETCOSTTOTALSIZE': return {...state, totalSize: action.payload};
    case 'SETCOSTISPUSHEDSEARCHBUTTON': return {...state, isPushedSearchButton: action.payload};
    case 'SETCOSTRESET': return {...state, ...resetState};
    default: return state;
  };
};

export default costReducer;