import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

import { processTypeApi } from 'api/apis/processTypeApi';
import { processApi } from 'api/apis/processApi';

import { checkEmptyNull, checkNullObject } from 'components/checkValues/checkValues';

const DownloadModal = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}
`;

const MainContents = styled.div`
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 5px 20px;
  width: 100%;
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--ThirdGreen);
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 400;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const StatusSelect = styled.select`
  border: 1px solid var(--ThirdGreen);
  border-radius: 5px;
  box-sizing: border-box;
  height: fit-content;
  margin-top: 5px;
  padding: 10px;
  width: 100%;
`;
const SearchContents = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 150px calc(100% - 160px);
  margin-top: 5px;
  width: 100%;

  &.detail {grid-template-columns: 150px calc(100% - 210px) 50px;}

  select {
    border: 1px solid var(--ThirdGreen);
    border-radius: 5px;
    box-sizing: border-box;
    height: fit-content;
    padding: 10px;
    width: 100%;
    &.detail {
      appearance: none; --webkit-appearance: none;
      background-color: var(--ThirdGreen);
      color: var(--white);
    }
  }

  input {
    border: 1px solid var(--ThirdGreen);
    border-radius: 5px;
    box-sizing: border-box;
    height: fit-content;
    padding: 10px;
    width: 100%;
    &:focus {border: 1px solid var(--ThirdGreen);}
  }
`;

const LoadingScreen = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  color: var(--white);
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 999;
`;

const ProcessDownloadModal = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_processTypes, setProcessTypes] = useState([]);
  const [_processType, setProcessType] = useState('all');

  const [_searchData, setSearchData] = useState({});

  const [_loadingStatus, setLoadingStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    getProcessTypes();

    setOnload('loaded');
  }, []);

  /* ====================================================================== #4 */
  const getProcessTypes = async () => {
    const BodyToPost = { companyId: userReducer.company.companyId };
    await processTypeApi.searchProcessType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('processTypeApi.searchProcessType : ', response);
      setProcessTypes(() => { return response.data; });
    });
  };

  const actDownload = async () => {
    setLoadingStatus(true);

    const paging = `?page=0&size=7000&sort=id,DESC`;
    const BodyToPost = { companyId: userReducer.company.companyId };
    if (checkEmptyNull(_processType, false) && _processType !== 'all') {
      BodyToPost.processTypeId = Number(_processType);
    }
    if (checkEmptyNull(_searchData.processCode, false)) {
      BodyToPost.processCode = _searchData.processCode;
    }
    if (checkEmptyNull(_searchData.processName, false)) {
      BodyToPost.processName = _searchData.processName;
    }

    await processApi.searchProcess(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('processApi.searchProcess : ', response);
      setExcelData(response.data.content);
    }).finally(() => {
      setLoadingStatus(false);
      props.close();
    });
  };
  const setExcelData = (Result) => {
    const processList = Result;

    const array = [];
    processList.forEach((process) => {
      process.processElementList.forEach((element) => {
        array.push({ ...element, process: process });
      });
    });

    const excelList = array.map((thisItem) => {
      const returnData = {
        공정유형: thisItem.process.processTypeName,
        공정코드: thisItem.process.processCode,
        공정이름: thisItem.process.processName,
        작업위치: thisItem.process.place?.placeName,
        비고: thisItem.process.remark,
      };

      if (checkEmptyNull(thisItem.elementName, false)) {
        if (thisItem.processElementType === 'Put') {
          returnData['투입 물품이름'] = thisItem.elementName;
        }
        if (thisItem.processElementType === 'Make') {
          returnData['공정완료 물품이름'] = thisItem.elementName;
        }
      } else if (checkEmptyNull(checkNullObject(thisItem.element, false)?.elementName, false)) {
        if (thisItem.processElementType === 'Put') {
          returnData['투입 물품이름'] = thisItem.element.elementName;
        }
        if (thisItem.processElementType === 'Make') {
          returnData['공정완료 물품이름'] = thisItem.element.elementName;
        }
      } else {
        if (thisItem.processElementType === 'Put') {
          returnData['투입 물품이름'] = '';
        }
        if (thisItem.processElementType === 'Make') {
          returnData['공정완료 물품이름'] = '';
        }
      }
      if (checkEmptyNull(thisItem.elementCode, false)) {
        if (thisItem.processElementType === 'Put') {
          returnData['투입 물품코드'] = thisItem.elementCode;
        }
        if (thisItem.processElementType === 'Make') {
          returnData['공정완료 물품코드'] = thisItem.elementCode;
        }
      } else if (checkEmptyNull(checkNullObject(thisItem.element, false)?.elementCode, false)) {
        if (thisItem.processElementType === 'Put') {
          returnData['투입 물품코드'] = thisItem.element.elementCode;
        }
        if (thisItem.processElementType === 'Make') {
          returnData['공정완료 물품코드'] = thisItem.element.elementCode;
        }
      } else {
        if (thisItem.processElementType === 'Put') {
          returnData['투입 물품코드'] = '';
        }
        if (thisItem.processElementType === 'Make') {
          returnData['공정완료 물품코드'] = '';
        }
      }
      if (thisItem.processElementType === 'Put') {
        returnData['단위 투입량'] = thisItem.amount;
      }
      if (thisItem.processElementType === 'Make') {
        returnData['단위 생산량'] = thisItem.amount;
      }

      return returnData;
    });
    console.log('excelList : ', excelList);

    const ws = XLSX.utils.json_to_sheet(excelList);
    const wb = XLSX.utils.book_new();

    let fileTitle = '공정 리스트';
    const typeData = _processTypes.find((thisItem) => {
      if (thisItem.processTypeId === Number(_processType)) return true;
      else return false;
    });
    if (typeData !== undefined) fileTitle = typeData.processTypeName + ' 공정 리스트';
    XLSX.utils.book_append_sheet(wb, ws, fileTitle);
    XLSX.writeFile(wb, fileTitle + '.xlsx');
  };

  /* ====================================================================== #5 */
  /* ====================================================================== #6 */

  return (
    <>
      <DownloadModal>
        <MainContents>
          <div>공정유형</div>
          <StatusSelect value={_processType} onChange={(e) => { setProcessType(() => { return e.target.value; }); }}>
            <option value="all">전체</option>
            {_processTypes.map((thisItem) => {
              return (<option key={thisItem.processTypeId + '_processType'} value={thisItem.processTypeId}>{thisItem.processTypeName}</option>);
            })}
          </StatusSelect>
        </MainContents>

        <MainContents>
          <div>검색</div>
          <SearchContents>
            <select className="SearchOption detail">
              <option value="processCode">공정코드</option>
            </select>
            <input
              className="SearchBar detail"
              placeholder="Search..."
              value={_searchData.processCode || ''}
              onInput={(e) => { setSearchData((prev) => { return { ...prev, processCode: e.target.value }; }); }}
            />
          </SearchContents>
          <SearchContents>
            <select className="SearchOption detail">
              <option value="processName">공정이름</option>
            </select>
            <input
              className="SearchBar detail"
              placeholder="Search..."
              value={_searchData.processName || ''}
              onInput={(e) => { setSearchData((prev) => { return { ...prev, processName: e.target.value }; }); }}
            />
          </SearchContents>
        </MainContents>
      </DownloadModal>

      <ModalFooter>
        <ModalButton onClick={actDownload}>다운로드</ModalButton>
      </ModalFooter>

      {_loadingStatus === true ? (<LoadingScreen>다운로드하고 있습니다...</LoadingScreen>) : null}
    </>
  );
};

export default ProcessDownloadModal;
