import React, { useEffect, useState } from 'react';

import TmpHmpContent from './TmpHmpContent';

import { TmpHmdSection } from 'pages/safetyManagement/SafetyManagement.style';
import { checkNullArray } from 'components/checkValues/checkValues';

const TmpHmd = ({ logList, viewList }) => {
  const [_sensorView, setSensorView] = useState('');
  const [_sensorData, setSensorData] = useState([]);
  
  useEffect(() => {
    const tempDatas = logList
        .filter(log => log.name.includes('temp') || log.name.includes('온도'))
        .map(log => log.value);

    const humDatas = logList
        .filter(log => log.name.includes('humidity') || log.name.includes('습도'))
        .map(log => log.value);

    // 모든 데이터를 하나의 배열에 객체 형태로 담기
    const combinedData = viewList?.map((name, index) => ({
        name: name || '데이터 없음',
        temp: tempDatas[index] || '데이터 없음',
        hum: humDatas[index] || '데이터 없음',
    }));

    // 상태 업데이트
    setSensorView(viewList[0]);
    setSensorData(combinedData);
  }, [logList]);

  return (
    <TmpHmdSection>
      <div className="nav">
        <h3>온 습도</h3>
        <select
          className="SelectType"
          style={{ width: '180px' }}
          value={_sensorView}
          onChange={(e) => {
            const newSensorView = e.target.value;
            setSensorView(newSensorView);
          }}
        >
          {checkNullArray(_sensorData, []).map((sensor, index) => {
            return (
              <option key={index + '_sensor'} value={sensor.name}>
                {sensor.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className="contents">
        <TmpHmpContent
          sensorView={_sensorView}
          sensorData={_sensorData}
        />
      </div>
    </TmpHmdSection>
  );
};

export default TmpHmd;