export const menuItems = [{
  title : '마이페이지',
  mypage: [
    {menuName: '내정보', route: '/mall/mypage/info'},
    {menuName: '주문내역', route: '/mall/mypage/order/list'},
    {menuName: '문의내역', route: '/mall/mypage/qna/list'},
    // {menuName: 'QnA', route: '/mall/qna/list'},
    {menuName: '로그아웃', route: '/'},
  ],
}];
