import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { teamActions_setCompanyId, teamActions_setPageNumber, teamActions_setReset, teamActions_setTotalSize } from 'store/modules/actions/common/teamActions';

import { auth } from 'api/apis/auth/auth';
import { companyMenu } from 'api/apis/operator/companyMenu';
import { teamApi } from 'api/apis/teamApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { LoadingMsg } from 'components/loading/LoadingMsg';

import Grid3Body from 'components/layouts/body/Grid3Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const TeamManagementSection = styled.main`
  .TableSection {
    &:nth-of-type(2) {
      table {thead {background-color: var(--MainBlue);}}
    }
    tr[name='teamRows']:hover {background-color: var(--gray-200); cursor: pointer; opacity: 0.8;}
    td.joinCodeCell:hover {background-color: var(--gray-300);}
  }
`;

const Contents = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;

const TeamListSection = styled(TableSection)``;
const AuthoritySection = styled(TableSection)``;

const WorkAuthorityCell = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  zoom: 80%;
`;
const WorkAuthority = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & h4 {
    align-items: center;
    background-color: var(--MainNavy);
    border: 1px solid var(--MainNavy);
    box-sizing: border-box;
    color: var(--white);
    display: flex;
    height: 40px;
    justify-content: center;
    width: 100%;
  }

  & div {
    align-items: center;
    background-color: var(--white);
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 100%;
  }
`;

const TeamManagement = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { menuReducer, pageReducer, teamReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_companyList, setCompanyList] = useState([]);
  const [_company, setCompany] = useState(() => {
    const companyId = userReducer.user.role !== 'ROLE_MASTER' ? userReducer.company.companyId : checkEmptyNull(teamReducer.companyId, userReducer.company.companyId);
    return companyId;
  });
  const [_companyMenus, setCompanyMenus] = useState([]);
  const [_teamList, setTeamList] = useState([]);

  const [_clickedTeam, setClickedTeam] = useState();
  const [_teamAuthorityList, setTeamAuthorityList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());
  const [_searchText, setSearchText] = useState('');

  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('106') === true ||
      thisItem.authority.startsWith('902') === true,
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getCompanyList();
    const companyId = userReducer.user.role !== 'ROLE_MASTER' ? userReducer.company.companyId : checkEmptyNull(teamReducer.companyId, userReducer.company.companyId);
    getCompanyMenu(companyId);
    getTeamList(companyId, teamReducer.pageNumber - 1);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') getTeamList(_company, teamReducer.pageNumber - 1);
    return () => { };
  }, [teamReducer.pageNumber]);

  useEffect(() => {
    if (_onload === 'loaded') {
      setTeamAuthorityList(() => { return []; });
      setClickedTeam(() => { return undefined; });
      dispatch(teamActions_setReset());
      dispatch(teamActions_setCompanyId(_company));
      getCompanyMenu(_company);
      getTeamList(_company, 0, true);
    }
    return () => { };
  }, [_company]);

  /* ========================================================================= #4 */
  const getCompanyList = async () => {
    console.log('check');
    await auth.searchCompanies().then((response) => {
      if (response === undefined) return;
      console.log('auth.searchCompanies : ', response);
      setCompanyList(() => { return checkNullArray(response.data.content, response.data); });
    });
  };

  const getCompanyMenu = async (companyId) => {
    const BodyToPost = { companyId: companyId }
    if (userReducer.user.role !== 'ROLE_MASTER') BodyToPost.companyId = userReducer.company.companyId;
    await companyMenu.searchCompanyMenu(BodyToPost).then(response => {
      if (!checkEmptyNull(response, false)) return;
      console.log('companyMenuApi.searchCompanyMenu : ', response);
      const companyMenus = response.data.content.sort((a, b) => {
        if (a.indexX > b.indexX) return 1;
        if (a.indexX < b.indexX) return -1;
        if (a.indexY > b.indexY) return 1;
        if (a.indexY < b.indexY) return -1;
        else return 0;
      });
      setCompanyMenus(() => { return companyMenus; })
    })
  }
  const getTeamList = async (companyId, page, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = { companyId: companyId };
    if (userReducer.user.role !== 'ROLE_MASTER') BodyToPost.companyId = userReducer.company.companyId;
    if (!clear && checkEmptyNull(_searchText, false)) BodyToPost.teamName = _searchText;
    await teamApi.searchTeam(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('teamApi.searchTeam : ', response);
      setTeamList(() => { return response.data.content; });
      dispatch(teamActions_setTotalSize(response.data.totalElements));
    })
  };

  /* ========================================================================= #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(id);
    setCheckedItem(() => { return checkedItem; });
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(teamActions_setPageNumber(1));
    await getTeamList(_company, 0);
  };

  /* 초기화 */
  const actReset = async () => {
    setTeamAuthorityList(() => { return []; });
    setClickedTeam(() => { return undefined; });
    dispatch(teamActions_setReset());
    await getTeamList(_company, 0, true);
  };

  const copyJoinCode = (joinCode) => {
    const textArea = document.createElement('textarea');
    document.body.appendChild(textArea);

    textArea.value = joinCode;
    textArea.select();
    textArea.setSelectionRange(0, 99999);
    document.execCommand('copy');
    textArea.setSelectionRange(0, 0);

    document.body.removeChild(textArea);
  }

  /* 수정 */
  const actUpdate = () => {
    const updateContentIndex = _teamList.findIndex((thisItem) => thisItem.teamId === Array.from(_checkedItem)[0]);
    if (updateContentIndex === -1) return alert('수정할 팀을 선택해 주세요.');
    const updateContent = _teamList[updateContentIndex];
    console.log('updateContent : ', updateContent);

    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({ updateContent: JSON.stringify(updateContent) })}`,
      replace: true,
    });
  };

  /* 삭제 */
  const actDelete = () => {
    if (Array.from(_checkedItem)[0] === 12 || Array.from(_checkedItem)[0] === 22) return alert('어드민 팀으로 삭제가 불가합니다.');

    const deleteContentIndex = _teamList.findIndex((thisItem) => thisItem.teamId === Array.from(_checkedItem)[0]);
    if (deleteContentIndex === -1) return alert('삭제할 항목을 선택해 주세요.');
    const deleteContent = _teamList[deleteContentIndex];
    console.log('deleteContent : ', deleteContent);

    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams(deleteContent)}`,
      replace: true,
    });
  };

  /* ========================================================================= #6 */

  return (
    <Grid3Body contents={
      <>
        <TeamManagementSection className="Main">
          <NavBar
            title={<NavTitle menuCode={'902'} />}
            buttons={
              <>
                {userReducer.user.role === 'ROLE_MASTER' ? <>
                  <select
                    className="SelectType"
                    style={{ backgroundColor: 'var(--color-1)' }}
                    value={checkEmptyNull(_company, '')}
                    onChange={(e) => {
                      const companyId = Number(e.target.value);
                      setCompany(() => { return companyId });
                    }}>
                    <option value={""}>회사선택</option>
                    {_companyList.map((company, index) => (
                      <option key={index + '__companys'} value={company.companyId}>{company.companyName}</option>
                    ))}
                  </select>
                </> : null}
                {(_authority.indexOf('106-2') !== -1 || _authority.indexOf('902-2') !== -1) ? (
                  <>
                    <button className='btn-add'
                      onClick={() => {
                        navigate({
                          pathname: pageReducer.currentPage + '/create',
                          search: `?${createSearchParams({companyId: _company})}`,
                          replace: true,
                        });
                      }}
                    >
                      추가
                    </button>
                    <button className='btn-edit' onClick={actUpdate}>수정</button>
                    <button className='btn-delete' onClick={actDelete}>삭제</button>
                  </>
                ) : null}
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="ContentCBox">
                  <div className="ContentCBox">
                    <select className="SearchOption">
                      <option value="teamName">팀 이름</option>
                    </select>
                    <input
                      className="SearchBar"
                      placeholder="Search..."
                      value={_searchText}
                      onInput={(e) => { setSearchText(() => { return e.target.value }) }}
                    />
                  </div>

                  <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                  <div className="ResetButton" onClick={actReset}>초기화</div>
                </div>
              </>
            }
          />

          <Contents>
            <TeamListSection content={
              <table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    {(_authority.indexOf('106-2') !== -1 || _authority.indexOf('902-2') !== -1) ? (
                      <th style={{ minWidth: 'unset', width: '50px' }}></th>
                    ) : null}
                    <th>팀 이름</th>
                    <th>팀 가입코드<br/><span style={{color: 'var(--MainBlue)', fontSize: '0.7em', fontWeight: '600'}}>클릭하면 복사가 됩니다.</span></th>
                  </tr>
                </thead>
                <tbody>
                  {!checkNullArray(_teamList, false) ? LoadingMsg() :
                    checkNullArray(_teamList, []).map((thisItem) => {
                      return (
                        <tr
                          key={thisItem.teamId + '_teamRow'}
                          name="teamRows"
                          onClick={() => {
                            setClickedTeam(() => { return thisItem.teamName; });
                            setTeamAuthorityList(() => { return thisItem.teamRoleList; });
                          }}
                        >
                          {(_authority.indexOf('106-2') !== -1 || _authority.indexOf('902-2') !== -1) ? (
                            <td style={{ minWidth: 'unset', width: '50px' }}>
                              <input
                                type="checkBox"
                                name="teams"
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  handleCheckedItem(thisItem.teamId, isChecked);
                                  if (isChecked) {
                                    document.getElementsByName('teams').forEach((thisAttr) => { thisAttr.checked = false; });
                                    e.target.checked = true;
                                  } else {
                                    e.target.checked = false;
                                  }
                                }}
                                onClick={(e) => { e.stopPropagation() }}
                              />
                            </td>
                          ) : null}
                          <td>{thisItem.teamName}</td>
                          <td className='joinCodeCell' title='가입코드 복사하기' onClick={(e) => {e.preventDefault(); copyJoinCode(thisItem.teamJoinCode)}}>{thisItem.teamJoinCode}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            }
              sectionStyle={{ height: '100%' }}
            ></TeamListSection>

            <AuthoritySection content={
              <table>
                <thead>
                  <tr>
                    <th colSpan={3} style={{ fontSize: '1.4em' }}>{(_clickedTeam || '') + ' 팀 권한'}</th>
                  </tr>
                  <tr>
                    <th rowSpan={2}>메뉴</th>
                    <th colSpan={2}>권한</th>
                  </tr>
                  <tr>
                    <th>읽기</th>
                    <th>읽기 & 쓰기</th>
                  </tr>
                </thead>
                <tbody>
                  {checkNullArray(_companyMenus, []).map((menu, index) => {
                    // console.log('menu : ', menu);
                    const teamMenuList = _teamAuthorityList.filter(item => item.pageCode === menu.pageCode);
                    const authority = teamMenuList.map(teamMenu => teamMenu.authority);
                    // console.log('authority : ', authority);
                    if (menu.pageCode.includes('00')) {
                      return (
                        <tr key={index + '_00'}>
                          <td colSpan={3} style={{ backgroundColor: 'var(--MainNavy)', color: 'var(--white)' }}>{menu.pageName}</td>
                        </tr>
                      )
                    } else {
                      return (
                        <tr key={index + '_authority'}>
                          <td>{menu.pageName}</td>
                          <td>{authority.includes('1') ? 'O' : ''}</td>
                          <td>
                            {(() => {
                              if (authority.includes('2')) {
                                if (userReducer.company.companyId === 1 || userReducer.company.companyId === 2) {
                                  if (menu.pageCode === '501') {
                                    return (
                                      <WorkAuthorityCell>
                                        <WorkAuthority><h4>보강재</h4><div>{authority.includes('2') ? 'O' : ''}</div></WorkAuthority>
                                        <WorkAuthority><h4>절단</h4><div>{authority.includes('3') ? 'O' : ''}</div></WorkAuthority>
                                        <WorkAuthority><h4>용접</h4><div>{authority.includes('4') ? 'O' : ''}</div></WorkAuthority>
                                        <WorkAuthority><h4>마무리</h4><div>{authority.includes('5') ? 'O' : ''}</div></WorkAuthority>
                                      </WorkAuthorityCell>
                                    );
                                  } else {
                                    return 'O';
                                  }
                                } else {
                                  return 'O';
                                }
                              }
                            })()}
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            }
              sectionStyle={{ height: '100%' }}
            ></AuthoritySection>
          </Contents>
        </TeamManagementSection>

        <PagingComponent
          page={teamReducer.pageNumber}
          count={teamReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(teamActions_setPageNumber(page));
          }}
        />
      </>
    }
    />
  );
};

export default TeamManagement;
