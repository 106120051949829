import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { barcodeActions_setBarcodeContent } from 'store/modules/actions/common/barcodeActions';

import { barcodeApi } from 'api/apis/barcode/barcodeApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

import DOCButtonIcon from 'components/icons/DOCButtonIcon';
import MobileNumpad from 'pages/stock/numpad/MobileNumpad';
import ShowFileList from 'pages/file/modal/ShowFileList';
import StockPlaceHistoryModal from 'pages/stockPlaceHistory/modal/StockPlaceHistoryModal';

const ElementMain = styled.div`
  display: grid;
  grid-template-columns: 50% calc(50% - 30px);
  gap: 30px;
  position: relative;
  width: 100%;

  ${(props => {
    return props.mobile ? 'display: flex; flex-wrap: wrap;' : null;
  })}

  & .sec-baseInformaion {
    align-content: flex-start;
    background-color: var(--white);
    box-sizing: border-box;
    display: grid;
    gap: 20px;
    padding: 20px;
    width: 100%;

    & .con-baseInformaion {
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: flex-start;

      & h4 {
        border-right: 2px solid var(--gray-100);
        width: 100px;
      }
      & p {
        /* border: 1px solid var(--Text); */
        box-sizing: border-box;
        font-size: 0.9em;
        /* padding: 8px 16px; */
      }
    }
  }
  & .sec-stock {
    box-sizing: border-box;
    display: grid;
    gap: 20px;
    height: fit-content;
    overflow: hidden;
    /* padding: 20px; */
    position: sticky;
    top: 0px;
    width: 100%;

    & .thumbnail {
      margin: 0px auto;
      position: relative;
      width: fit-content;

      & img {
        max-height: 200px;
        max-width: 90%;
        margin: 0px auto;
      }

      & .btn-file {
        bottom: 10px;
        right: 20px;
        position: absolute;
      }
    }

    & .table-stock {
      border-collapse: collapse;

      & th {
        background-color: var(--MainNavy);
        color: var(--white);
        height: 60px;
      }
      & td {
        background-color: var(--white);
        border: 1px solid var(--MainNavy);
        font-size: 40px;
        font-weight: 600;
        height: 120px;
        text-align: center;
        word-break: break-all;

        &.safeStock {background-color: var(--MainGreen); color: var(--white);}
        &.dangerStock {background-color: var(--MainRed); color: var(--white);}
      }
    }

    & .btn-stocks {
      display: grid;
      grid-template-rows: 1fr 1fr;
      gap: 10px;
      height: 100%;
      width: 100%;

      & .btn-stock {
        align-items: center;
        background-color: var(--MainBlue);
        box-sizing: border-box;
        color: var(--white);
        display: flex;
        font-size: 2em;
        height: 100%;
        justify-content: center;
        padding-block: 20px;
        width: 100%;

        &.in {background-color: var(--ThirdGreen);}
        &.out {background-color: var(--ThirdRed);}
      }
    }
  }
`;

const ElementInfo = (props) => {
  const mobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  const dispatch = useDispatch();

  const [_elementData, setElementData] = useState(props.elementData);
  const [_elementConData, setElementConData] = useState(props.elementConData);
  const [_stockData, setStockData] = useState(props.stockData);

  const [_stockPlaceModalStatus, setStockPlaceModalStatus] = useState(false);

  const [_numpadModalStatus, setNumpadModalStatus] = useState(false);
  const [_stockType, setStockType] = useState();

  const [_fileListModalStatus, setFileListModalStatus] = useState(false);

  useEffect(() => {
    setElementData(() => { return props.elementData });
    setElementConData(() => { return props.elementConData });
    setStockData(() => { return props.stockData });
  }, [props])

  const actStock = (stockType) => {
    setStockType(() => { return stockType });
    setNumpadModalStatus(() => { return true });
  };

  const setResponse = (response) => {
    if (response === undefined) return;
    console.log('response : ', response.data);
    dispatch(barcodeActions_setBarcodeContent(response.data));
  };
  const getElement = async () => {
    const elementId = _elementData.elementId;
    await barcodeApi.getElement(elementId).then(response => {
      setResponse(response);
    })
  };

  return (
    <>
      <ElementMain mobile={mobile()}>
        <section className='sec-baseInformaion'>
          {checkNullArray(_elementConData, []).map((conData, index) => {
            return (
              <div key={index + '_conData'} className='con-baseInformaion'>
                <h4>{conData.key}</h4>
                <p>{conData.value}</p>
              </div>
            )
          })}
        </section>
        <section className='sec-stock'>
          {_elementData.attachFileList[0] ?
            <div className='thumbnail'
              onClick={(e) => {
                e.stopPropagation();
                setFileListModalStatus(true);
              }}
            >
              <img src={_elementData.attachFileList[0]?.fileData?.fileDataS3URL || ''} alt='image preview' />
              <div className='btn-file'><DOCButtonIcon /></div>
            </div>
            : <div></div>}
          {/* <img src={_elementData.attachFileList[0]?.fileData?.fileDataS3URL || ''} alt='image preview' className='thumbnail' /> */}

          <table className='table-stock'>
            <thead>
              <tr>
                {checkNullArray(_stockData, []).map((stockData, index) => {
                  return <th key={index + '_stockData_key'}>{stockData.key}</th>
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                {checkNullArray(_stockData, []).map((stockData, index) => {
                  return <td key={index + '_stockData_value'} className={checkEmptyNull(stockData.status, '')}>{stockData.value}</td>
                })}
              </tr>
            </tbody>
          </table>

          <div className='btn-stocks'>
            <button className='btn-set btn-stock' onClick={() => { setStockPlaceModalStatus(() => { return true }) }}>재고위치</button>
            <button className='btn-set btn-stock in' onClick={() => { actStock('In') }}>입고</button>
            <button className='btn-set btn-stock out' onClick={() => { actStock('Out') }}>출고</button>
          </div>
        </section>
      </ElementMain>

      {_stockPlaceModalStatus === true && (
        <StockPlaceHistoryModal
          stockElement={_elementData}
          open={_stockPlaceModalStatus}
          close={() => { setStockPlaceModalStatus(false) }}
        ></StockPlaceHistoryModal>
      )}

      {_numpadModalStatus === true && (
        <MobileNumpad
          type={_stockType}
          content={_elementData}
          open={_numpadModalStatus}
          close={async () => {
            setNumpadModalStatus(false);
            await getElement();
          }}
        ></MobileNumpad>
      )}

      {_fileListModalStatus === true && (
        <ShowFileList
          type={'element'}
          content={_elementData}
          open={_fileListModalStatus}
          close={() => { setFileListModalStatus(false) }}
        ></ShowFileList>
      )}
    </>
  );
};

export default ElementInfo;