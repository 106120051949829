import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateTimeFormat } from 'components/format/DateTimeFormat';

import { sensorDataApi } from 'api/apis/sensorDataApi';

import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import { CountTable, SensorDataSection } from './CountData.style';


const CountData = () => {
  const { userReducer } = useSelector((state) => state);

  const [_onload, setOnload] = useState('unload');

  const [_startDate, setStartDate] = useState(moment().add('-1', 'm').format('YYYY-MM-DD hh:mm:ss'));
  const [_endDate, setEndDate] = useState(moment().format('YYYY-MM-DD hh:mm:ss'));
  const [_name, setName] = useState('');

  const [_logList, setLogList] = useState([]);

  const [_pageNumber, setPageNumber] = useState(1);
  const [_totalSize, setTotalSize] = useState(0);

  useEffect(() => {
    getSensorData(_pageNumber - 1);

    setOnload('loaded');
    return () => { }
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getSensorData(_pageNumber - 1);
    }

    return () => { }
  }, [_pageNumber]);

  const getSensorData = async (page) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      startDate: moment().add('-1', 'm').add(9, 'hour').toISOString(),
      endDate: moment().add(9, 'hour').toISOString(),
      name: 'count'
    }

    if (checkEmptyNull(_startDate, false)) BodyToPost.startDate = moment(_startDate).add(9, 'hour').toISOString();
    if (checkEmptyNull(_endDate, false)) BodyToPost.endDate = moment(_endDate).add(9, 'hour').toISOString();

    await sensorDataApi.countData(paging, BodyToPost).then(response => {
      if (response === undefined) return;
      setLogList(() => { return response.data.content });
      setTotalSize(() => { return response.data.totalElements });
    })
  }

  const actSearch = () => {
    setPageNumber(() => { return 1 });
    getSensorData(0);
  }
  const actReset = () => {
    setStartDate(() => { return '' })
    setEndDate(() => { return '' })
    setName(() => { return '' })

    setPageNumber(() => { return 1 });
    getSensorData(0);
  }

  return (
    <Grid3Body contents={
      <>
        <SensorDataSection className='Main'>
          <NavBar
            title={
              <>
                <div className='Title'>
                  <NavTitle menuCode={'702'} />
                </div>
              </>
            }
            buttons={
              <>
                <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <div className="SearchSection">
                    <input
                      type="datetime-local"
                      className="SelectDate"
                      value={checkEmptyNull(_startDate, false) ? DateTimeFormat(checkEmptyNull(_startDate, '')) : ''}
                      onChange={(e) => { e.preventDefault(); setStartDate(() => { return e.target.value }) }}
                    />
                    <IconRightArrow />
                    <input
                      type="datetime-local"
                      className="SelectDate"
                      value={checkEmptyNull(_endDate, false) ? DateTimeFormat(checkEmptyNull(_endDate, '')) : ''}
                      onChange={(e) => { e.preventDefault(); setEndDate(() => { return e.target.value }) }}
                    />
                  </div>
                  <div className="ContentCBox">
                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton" onClick={actReset}>초기화</div>
                  </div>
                </div>
              </>
            }
            nav={''}
          />

          <CountTable>
            <table>
              <colgroup><col width={'250px'} /><col /></colgroup>
              <thead>
                <tr>
                  <th>작업 시각</th>
                  <th>수량</th>
                </tr>
              </thead>
              <tbody>
                {checkNullArray(_logList, false) ? (<>
                  {checkNullArray(_logList, []).map((logData, index) => {
                    const date = moment(logData.date).format('YYYY-MM-DD HH:mm:ss');
                    return (
                      <tr key={index + '_logData'}>
                        <td>{date}</td>
                        <td>{checkEmptyNull(logData.value, 0).toLocaleString()}</td>
                      </tr>
                    )
                  })}
                </>) : <tr><td colSpan={2}>데이터가 없습니다</td></tr>}
              </tbody>
            </table>

          </CountTable>
        </SensorDataSection>
        <PagingComponent
          page={_pageNumber}
          count={_totalSize}
          size={10}
          pageEvent={(page) => {
            setPageNumber(() => { return page })
          }}
        />
      </>
    } />
  );
};

export default CountData;