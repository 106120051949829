import React, { useState, useEffect } from 'react';
import axios from "axios";
import styled from 'styled-components';

import RealTimeGraph from './RealTimeGraph';
import RealTimeTable from './RealTimeTable';

const PageWrapper = styled.div`
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
  padding: 20px;
`
const RealTimePage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let apiUrl = '/api/sensorRealTimeData/search';
    let requestBody = {
      name: null,
      companyId: 1,
    };

    // API 요청 및 데이터 가져오기
    axios.post(apiUrl, requestBody).then(response => {
      const { content } = response.data;

      // 필요한 필드만 추출하여 저장
      const processedData = content.map(item => ({
        date: item.date,
        value: item.value,
      }));

      setData(processedData);
      setLoading(false);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      setLoading(false);
    });
  }, []); // 빈 배열을 넘겨서 컴포넌트가 마운트될 때 한 번만 실행

  return (
    <PageWrapper>
      {loading ? (<p></p>) : (
        <>
          <RealTimeGraph datas={data} />
          <RealTimeTable data={data} />
        </>
      )}
    </PageWrapper>
  )
}

export default RealTimePage;