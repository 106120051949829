import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

import { manHour } from 'api/apis/manHour/manHour';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import DetailWorkOrderLog from 'pages/manHour/custom/company/gs/result/modal/DetailWorkOrderLog';
import TableSection from 'components/layouts/table/TableSection';

const Section = styled.section`
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100%;
  overflow: hidden;
  width: 100%;

  section {
    height: 100%;
    width: 100%;
  }
  .section-select {
    display: grid;
    gap: 10px;
    grid-template-rows: 40px calc(100% - 50px);
    overflow: hidden;
    .search {
      display: grid;
      gap: 10px;
      grid-template-columns: calc(100% - 80px) 60px;
      height: 40px;
      width: 100%;

      input {
        box-sizing: border-box;
        padding: 5px;
        height: 100%;
        width: 100%;
      }
      button {
        background-color: var(--MainNavy);
        color: var(--white);
        height: 100%;
        width: 60px;
      }
    }
    .TableSection {
      height: 100%;
      td, th {min-width: unset;}
    }
  }
  .section-result {
    align-content: flex-start;
    box-sizing: border-box;
    display: grid;
    gap: 10px;
    grid-template-rows: 40px 80px 40px calc(100% - 190px);
    height: 100%;
    overflow: hidden;
    padding: 20px;
    width: 100%;

    .title {
      align-items: center;
      display: flex;
      height: 40px;
      justify-content: space-between;

      nav {
        align-items: center;
        display: flex;
        gap: 5px;
        height: 100%;
        justify-content: center;

        input, select {
          border-radius: 10px;
          box-sizing: border-box;
          height: 100%;
          padding: 5px 10px;
        }
        button {
          padding: unset;
          width: 60px;
          &.btn-reset {
            background: none;
            box-shadow: none;
          }
        }
      }
    }
    .totalTime {
      align-items: center;
      background-color: var(--white);
      border-radius: 10px;
      box-sizing: border-box;
      display: flex;
      height: 80px;
      padding: 20px;
      justify-content: flex-start;
      span {
        border-right: 1px solid var(--gray-200);
        height: fit-content;
        padding-inline-end: 20px;
        margin-inline-end: 20px;
      }
    }

    .section-workingTime {
      align-content: flex-start;
      display: grid;
      gap: 10px;
      height: 100%;
      overflow-y: scroll;
      width: 100%;

      .workingTimeContent {
        background-color: var(--white);
        border-radius: 10px;
        display: grid;
        gap: 10px;
        grid-template-columns: 30% 70%;
        height: fit-content;
        padding: 20px;
        width: 100%;

        &>div {
          align-content: flex-start;
          display: grid;
          gap: 10px;
        }
        span:first-child {
          border-right: 1px solid var(--gray-200);
          height: fit-content;
          padding-inline-end: 20px;
          margin-inline-end: 20px;
        }
        p.date {
          font-weight: 600;
        }
        p.note {
          display: flex;
          flex-wrap: wrap;
        }
        button {
          background-color: var(--MainNavy);
          color: var(--white);
          height: 100%;
          width: 100px;
        }

        & .workingTimeData {
          border: 1px solid var(--MainNavy);
          border-radius: 10px;
          box-sizing: border-box;
          gap: 10px;
          grid-template-columns: calc(100% - 110px) 100px;
          padding: 10px;
        }
      }
    }
  }
`;

const Production = () => {
  const { userReducer } = useSelector((state) => state);

  const [_workOrderName, setWorkOrderName] = useState('');
  const [_workOrderLogList, setWorkOrderLogList] = useState([]);

  const [_manHourData, setManHourData] = useState({});

  const [_workingTimeData, setWorkingTimeData] = useState({});
  const [_workingTimeModalStatus, setWorkingTimeModalStatus] = useState(false);

  useEffect(() => {
    getManHourList(_workOrderName);

    return () => { }
  }, []);
  useEffect(() => { console.log('_manHourData : ', _manHourData);}, [_manHourData])

  const getManHourList = async (name, workOrderLog) => {
    const companyId = userReducer.company.companyId;
    const searchData = `companyId=${companyId}&workOrderLogName=${name}`;
    await manHour.groupingWork(searchData).then(response => {
      if (response === undefined) return;
      // console.log('manHour.groupingWork : ', response);
      setWorkOrderLogList(() => { return response.data });
      if(checkNullObject(workOrderLog, false)) {
        console.log('workOrderLog : ', workOrderLog);
        const findData = response?.data.find(data => data?.workOrderLog.workOrderLogId === workOrderLog.workOrderLogId);
        setManHourData(() => { return findData });
      }
    })
  };

  const handlerMin = (min) => {
    const days = Math.floor(min / 60 / 24)
    const hours = Math.floor((min - (days * 60 * 24)) / 60);
    const mins = min - (days * 60 * 24) - (hours * 60);

    let returnData = '';
    if (days > 0) returnData += days + '일 ';
    if (hours > 0) returnData += hours + '시간 ';
    if (mins > 0) returnData += mins + '분';

    return returnData;
  }
  const handleManHourData = async (data) => {
    console.log('data : ', data);
    setManHourData(() => { return data });
  };

  const handleModalStatus = (workingTimeData) => {
    setWorkingTimeData(() => { 
      return {
        // searchDate: checkEmptyNull(workingTimeData.searchDate, moment(workingTimeData.startTime).format('YYYY-MM-DD')),
        // workOrderLogId: workingTimeData.workOrderLogId,
        workingTimeId: workingTimeData.id
      } 
    });
    setWorkingTimeModalStatus(true);
  }

  return (
    <>
      <Section>
        <section className='section-select'>
          <div className='search'>
            <input
              type='text'
              placeholder='작업이름...'
              value={_workOrderName}
              onInput={(e) => { setWorkOrderName(() => { return e.target.value }) }}
            />
            <button className='btn-set' onClick={(e) => { e.preventDefault(); getManHourList(_workOrderName); }}>검색</button>
          </div>
          <TableSection content={
            <table>
              <colgroup>
                <col />
                <col />
                <col />
                <col width={'50px'} />
              </colgroup>
              <thead>
                <tr>
                  <th>거래처</th>
                  <th>작업코드</th>
                  <th>공사명</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {_workOrderLogList.map((workOrderLog, index) => {
                  return (
                    <tr key={index + '_worker'}>
                      <td>{workOrderLog.workOrderLog?.accountName}</td>
                      <td>{workOrderLog.workOrderLog?.workOrderCode}</td>
                      <td>{workOrderLog.workOrderLog?.workOrderName}</td>
                      <td>
                        <button className='btn-set' onClick={(e) => { e.preventDefault(); handleManHourData(workOrderLog) }}>보기</button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          }
          />
        </section>
        <section className='section-result'>
          {checkNullObject(_manHourData, false) ? (
            <>
              <div className='title'>
                <h3>공수실적</h3>
              </div>
              <p className='totalTime'><span>총 공수</span>{(checkEmptyNull(_manHourData.sumValues, false) && _manHourData.sumValues !== 0 && _manHourData.sumValues !== '0') ? checkEmptyNull(_manHourData.sumValues, 0).toLocaleString() : handlerMin(checkEmptyNull(_manHourData.diffMinutes, ''))}</p>
              <div className='title'>
                <h4>공수리스트</h4>
              </div>
              <section className='section-workingTime'>
                {checkNullArray(_manHourData.userGroups, []).map((userGroup, index) => {
                  return (
                    <div key={index + '_userGroups'} className='workingTimeContent'>
                      <div>
                        <p className='date'>{userGroup.worker.name}</p>
                        <p><span>공수</span>{(checkEmptyNull(userGroup.sumValues, false) && userGroup.sumValues !== 0 && userGroup.sumValues !== '0') ? checkEmptyNull(userGroup.sumValues, 0).toLocaleString() : handlerMin(checkEmptyNull(userGroup.diffMinutes, ''))}</p>
                      </div>
                      <div>
                        {checkNullArray(userGroup.workingTimes, []).map((workingTimeData, i) => {
                          return (
                            <div key={index + '_workingTime_' + i} className='workingTimeContent workingTimeData'>
                              <div>
                                <p className='date'>
                                  {(checkEmptyNull(userGroup.sumValues, false) && userGroup.sumValues !== 0 && userGroup.sumValues !== '0') ? moment(workingTimeData.occurrenceDate).format('YYYY-MM-DD')
                                    : `${moment(workingTimeData.startTime).format('YYYY-MM-DD HH:mm')} - ${moment(workingTimeData.endTime).format('YYYY-MM-DD HH:mm')}`}
                                </p>
                                <p className='date'>{(checkEmptyNull(userGroup.sumValues, false) && userGroup.sumValues !== 0 && userGroup.sumValues !== '0') ? checkEmptyNull(workingTimeData.value, 0).toLocaleString() : handlerMin(checkEmptyNull(workingTimeData.diffMinutes, ''))}</p>
                                <p>{workingTimeData.note}</p>
                              </div>
                              <button className='btn-set btn-list' onClick={(e) => { e.preventDefault(); handleModalStatus(workingTimeData) }}>작업<br />상세보기</button>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  );
                })}
              </section>
            </>
          ) : null}
        </section>
      </Section>

      {_workingTimeModalStatus ? (
        <DetailWorkOrderLog
          searchContent={_workingTimeData}
          update={(workOrderLog) => {
            console.log('workOrderLog : ', workOrderLog);
            setWorkingTimeModalStatus(false); 
            getManHourList(_workOrderName, workOrderLog);
          }}
          open={_workingTimeModalStatus}
          close={() => { setWorkingTimeModalStatus(false); }}
        />
      ) : null}
    </>
  );
};

export default Production;