import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { qna } from 'api/apis/mall/qna';

import { uploadSection } from 'components/icons/src';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import MallBody from 'components/layouts/body/MallBody';
import MallManagementHeader from 'components/header/MallManagementHeader';
import NavBar from 'components/nav/NavBar';
import SelectMallElements from 'pages/mallManagement/mallElement/modal/SelectMallElements';

const ElementTable = styled.table`
  background-color: var(--white);
  border-collapse: collapse;
  font-size: 0.9em;
  height: fit-content;
  table-layout: fixed;
  width: 100%;

  td,
  th {
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    height: 35px;
    padding: 2px 10px;
  }
  th {
    background-color: var(--MainNavy);
    color: var(--white);
  }

  thead th {
    height: 45px;
  }

  & td.ElementInfos {
    /* border-right: 1px solid var(--gray-200); */
    box-sizing: border-box;
    padding: unset;
  }
`;
const ElementInfos = styled.div`
  height: 100%;
  width: 100%;
`;
const ElementInfo = styled.div`
  border-bottom: 1px solid var(--gray-200);
  display: grid;
  grid-template-columns: 50px auto;
  height: 50%;
  width: 100%;

  & h5 {
    align-items: center;
    background-color: var(--MainNavy);
    color: var(--white);
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &.mall h5 {
    background-color: var(--MainBlue);
  }

  & p {
    align-items: center;
    box-sizing: border-box;
    justify-content: flex-start;
    padding: 10px;
    width: 100%;
  }
`;

const DeleteButton = styled.div`
  height: 15px;
  margin: 0px auto;
  width: 15px;

  div {
    background-color: var(--MainRed);
    cursor: pointer;
    height: 15px;
    width: 15px;
  }
`;

const MallUserTable = styled.table`
  background-color: var(--white);
  border-collapse: collapse;
  font-size: 0.9em;
  table-layout: fixed;
  width: 100%;

  & td,
  & th {
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    padding: 5px;
  }

  & th {
    background-color: var(--MainNavy);
    color: var(--white);
    height: 45px;
  }

  tbody th {
    min-width: 100px;
    width: 100px;
  }

  & input,
  & textarea {
    border-color: var(--MainBlue);

    &:focus {
      border-color: var(--MainBlue);
      border-width: 2px;
      outline: unset;
    }
  }
`;

const DragFileSection = styled.label`
  align-items: center;
  background-color: var(--white);
  border: 1px dashed var(--gray-200);
  column-gap: 50px;
  cursor: pointer;
  display: flex;

  flex-direction: column;
  height: 200px;
  justify-content: center;
  width: 100%;

  & p {
    font-size: 0.9em;
  }
  & span {
    color: var(--MainBlue);

    &:hover {
      border-bottom: 2px solid var(--MainBlue);
      padding-bottom: 2px;
    }
  }
`;
const DragFileIcon = styled.div`
  background-color: var(--gray-200);
  height: 80px;
  width: 80px;

  mask-image: url(${uploadSection});
  mask-repeat: no-repeat;
  mask-size: contain;

  --webkit-mask-image: url(${uploadSection});
  --webkit-mask-repeat: no-repeat;
  --webkit-mask-size: contain;
`;

const FileList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
  margin-top: 10px;
  width: 100%;
`;
const FileBox = styled.div`
  align-items: center;
  background-color: var(--white);
  box-sizing: border-box;
  color: var(--gray-400);
  display: flex;
  height: 200px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;

  & img {
    height: inherit;
    max-width: inherit;
    width: auto;
  }
`;
const DeleteFileButton = styled.div`
  align-items: center;
  background-color: var(--MainRed);
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  height: 20px;
  justify-content: center;
  padding-left: 3px;
  position: absolute;
  right: 15px;
  top: 15px;
  transform: scale(1.5);
  width: 20px;

  div {
    background-color: var(--white);
    cursor: pointer;
    height: 15px;
    width: 15px;
  }
`;

const CreateMallQnA = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const FileInput = useRef();

  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,

    status: 'waiting',

    userId: userReducer.user.id,

    mallQnAContent: '',

    mallQnAElementList: [],
  });

  const [_modalStatus, setModalStatus] = useState(false);
  const [_mallQnAElementList, setMallQnAElementList] = useState([]);

  const [_fileDataList, setFileDataList] = useState([]);

  /* ====================================================================== #3 */
  useEffect(() => {
    // const authorityContent = userReducer.user.team.teamRoleList.filter(
    //   (thisItem) => thisItem.authority.startsWith('109') === true,
    // );
    // const authorityCodes = authorityContent.map(
    //   (thisItem) => thisItem.authority,
    // );
    // setAuthority(authorityCodes);

    setOnload('loaded');
    return () => { };
  }, []);
  // useEffect(() => {
  //   const check = setTimeout(
  //     setFileDataList((prev) => prev),
  //     1000,
  //   );
  //   return clearTimeout(check);
  // }, [_fileDataList]);

  /* ====================================================================== #4 */

  /* ====================================================================== #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleElementCount = (e, index, element) => {
    const { value } = e.target;

    let eventValue = value;
    if (!eventValue.toString().startsWith('0.')) {
      eventValue = eventValue.toString().replace(/^0+/, '');
    }
    if (eventValue < 0) eventValue = 0;
    if (eventValue.length > 15) return;

    const newData = {
      ...element,
      count: BigNumber(eventValue).toNumber(),
    };

    setMallQnAElementList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1, newData);
      return prevData;
    });
  };

  const actDeleteOrderElement = (e, index, element) => {
    setMallQnAElementList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);
      return prevData;
    });
  };

  const handleFileEvent = (type, e) => {
    let files = [];
    switch (type) {
      case 'click':
        files = e.target.files;
        break;

      case 'drag':
        files = e.dataTransfer.files;
        break;

      default:
        return;
    }

    if (files === undefined || files === null || files.length === 0) return;
    const setFiles = Array.prototype.slice.call(files);
    setFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onload = () => {
          const returnData = file;
          returnData['preview'] = reader.result;
          setFileDataList((prev) => {
            return [...prev, returnData];
          });
          resolve();
        };
      });
    });
  };

  const actDeleteFileData = (file, index) => {
    setFileDataList((prev) => {
      const returnData = [...prev];
      returnData.splice(index, 1);

      return returnData;
    });
  };

  /* 추가 */
  const actCreate = async (e) => {
    e.preventDefault();

    const BodyToPost = {
      ..._formData,
      mallQnAElementList: _mallQnAElementList,
    };
    console.log('BodyToPost : ', BodyToPost);

    const postFormData = new FormData();
    postFormData.append(
      'key',
      new Blob([JSON.stringify(BodyToPost)], { type: 'application/json' }),
    );
    if (_fileDataList.length > 0) {
      _fileDataList.forEach((file) => {
        postFormData.append('multipartFileList', file);
      });
    }
    for (let values of postFormData.values()) {
      console.log('postFormData : ', values);
    }

    // return;
    await qna.createMallQnA(postFormData).then((response) => {
      if (response === undefined) return;
      console.log('qna.createMallQnA : ', response);

      alert('문의를 추가했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  };

  return (
    <MallBody
      contents={
        <>
          <main className="Main">
            <NavBar title={'문의관리'} nav={''} />

            <Form
              title={
                <>
                  문의
                  <br />
                  추가
                </>
              }
              buttons={
                <>
                  <button className="formButton" onClick={actCreate}>
                    저장
                  </button>
                  <button
                    className="formButton cancle"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(pageReducer.currentPage, { replace: true });
                    }}
                  >
                    취소
                  </button>
                </>
              }
              forms={
                <>
                  <div className="formBox">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        lineHeight: '30px',
                        width: '100%',
                      }}
                    >
                      <h4>문의물품</h4>
                      <div
                        className="formButton"
                        style={{
                          backgroundColor: 'var(--MainBlue)',
                          borderRadius: '5px',
                          width: '150px',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setModalStatus(true);
                        }}
                      >
                        물품 추가
                      </div>
                    </div>

                    <ElementTable>
                      <thead>
                        <tr>
                          <th>판매 물품정보</th>
                          <th>물품정보</th>
                          {/* <th>수량</th> */}
                          <th style={{ minWidth: '50px', width: '50px' }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {_mallQnAElementList.map((element, index) => {
                          return (
                            <tr key={index + '_mallQnAElements'}>
                              <td className="ElementInfos">
                                <ElementInfos>
                                  <ElementInfo className="mall">
                                    <h5>이름</h5>
                                    <p>{element.mallElementName}</p>
                                  </ElementInfo>
                                  <ElementInfo className="mall">
                                    <h5>코드</h5>
                                    <p>{element.mallElementCode}</p>
                                  </ElementInfo>
                                </ElementInfos>
                              </td>
                              <td className="ElementInfos">
                                <ElementInfos>
                                  <ElementInfo>
                                    <h5>이름</h5>
                                    <p>{element.elementName}</p>
                                  </ElementInfo>
                                  <ElementInfo>
                                    <h5>코드</h5>
                                    <p>{element.elementCode}</p>
                                  </ElementInfo>
                                </ElementInfos>
                              </td>
                              {/* <td>
                                <input
                                  type="number"
                                  name="count"
                                  value={element.count}
                                  onInput={(e) => {
                                    handleElementCount(e, index, element);
                                  }}
                                />
                              </td> */}
                              <td
                                style={{
                                  minWidth: '50px',
                                  positon: 'relative',
                                  width: '50px',
                                }}
                              >
                                <DeleteButton
                                  onClick={(e) => {
                                    actDeleteOrderElement(e, index, element);
                                  }}
                                >
                                  <CloseButtonIcon />
                                </DeleteButton>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </ElementTable>
                  </div>

                  <div className="formBox">
                    <h4>문의내용</h4>
                    <textarea
                      type="text"
                      name="mallQnAContent"
                      placeholder="* 문의내용..."
                      value={_formData.mallQnAContent}
                      onInput={handleInputEvent}
                    />
                  </div>

                  <div className="formBox">
                    <h4>첨부파일</h4>
                    <input
                      type="file"
                      ref={FileInput}
                      id="FileInput"
                      style={{ display: 'none' }}
                      multiple
                      onChange={(e) => {
                        handleFileEvent('click', e);
                      }}
                    />
                    <DragFileSection
                      htmlFor="FileInput"
                      onDragEnter={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      onDragLeave={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      onDragOver={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      onDrop={(e) => {
                        e.preventDefault();
                        handleFileEvent('drag', e);
                      }}
                    >
                      <DragFileIcon />
                      <p>
                        첨부할 파일 드래그 또는 <span>선택</span>
                      </p>
                    </DragFileSection>
                    <FileList>
                      {_fileDataList.map((fileData, index) => {
                        // console.log('fileData : ', fileData);
                        return (
                          <FileBox key={index + '_fileDatas'}>
                            {fileData.type &&
                              fileData.type.startsWith('image') ? (
                              <img src={fileData.preview} alt={fileData.name} />
                            ) : (
                              fileData.name
                            )}

                            <DeleteFileButton
                              onClick={() => {
                                actDeleteFileData(fileData, index);
                              }}
                            >
                              <CloseButtonIcon />
                            </DeleteFileButton>
                          </FileBox>
                        );
                      })}
                    </FileList>
                  </div>
                </>
              }
            />
          </main>

          {_modalStatus === true && (
            <SelectMallElements
              buttonEvent={async (data) => {
                console.log('data : ', data);
                const mallQnAElementIds = _mallQnAElementList.map(
                  (thisItem) => thisItem.mallElementId,
                );
                const newElements = data.map((element) => {
                  if (mallQnAElementIds.indexOf(element.mallElementId) !== -1)
                    return null;
                  else {
                    const returnData = {
                      ...element,
                      count: 0,
                    };

                    return returnData;
                  }
                });
                const setNewElements = newElements.filter(
                  (thisItem) => thisItem !== null,
                );

                setMallQnAElementList((prev) => {
                  return [...prev, ...setNewElements];
                });

                setTimeout(setModalStatus(false), 1000);
              }}
              buttonTitle={`물품 선택`}
              open={_modalStatus}
              close={() => {
                setModalStatus(false);
              }}
            />
          )}
        </>
      }
    />
  );
};

export default CreateMallQnA;
