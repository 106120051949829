import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { downArrow } from 'components/icons/src';

const NavSection = styled.nav`
  background-color: var(--white);
  box-sizing: border-box;
  left: 0px;
  padding: 8px 20px;
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 801;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}

  div.ContentCBox {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 50px;
    gap: 8px;
  }
  div.ContentFLBox {
    align-items: center;
    display: flex;
    justify-content: flex-start;
  }
  div.SearchSection {
    align-items: center;
    box-sizing: border-box;
    column-gap: 10px;
    display: flex;
    justify-content: flex-start;
    min-height: 50px;
    flex-wrap: wrap;
    gap: 10px;
  }

  input.SelectDate {
    border: 1px solid var(--gray-200);
    border-radius: 8px;
    box-sizing: border-box;
    height: 35px;
    padding: 2px 10px;
    width: 180px;
    padding-left: 16px;
    &:focus {border: 1px solid var(--gray-200);}
  }

  select.SelectType {
    background-color: var(--ThirdBlue);
    border-radius: 8px;
    box-sizing: border-box;
    color: var(--white);
    padding: 4px 20px;
    width: 150px;

    &:focus {background-color: var(--MainNavy);}
  }
  select.SelectFamily {
    border: 1px solid var(--gray-200);
    border-radius: 8px;
    box-sizing: border-box;
    height: 38px;
    padding: 2px 10px;
    width: 208px;
  }
  select.SelectStatus {
    border: 1px solid var(--gray-200);
    border-radius: 5px;
    box-sizing: border-box;
    height: 35px;
    padding: 2px 10px;
    width: 150px;
  }
  select.SearchOption {
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    height: 35px;
    padding: 2px 10px;
    width: 110px;
    border-radius: 8px;
    &.detail {
      appearance: none; --webkit-appearance: none;
      width: 90px;
      text-align: center;
      line-height: 120%;
    }
  }
  input.SearchBar {
    border: 1px solid var(--gray-200);
    border-radius: 8px;
    box-sizing: border-box;
    height: 35px;
    margin-right: 4px;
    padding: 10px;
    width: 250px;

    &:focus {border: 1px solid var(--gray-200);}

    &.detail {
      border-radius: 8px;
      &:focus {border: 1px solid var(--gray-200);}
    }
  }

  div.DetailButton {
    align-items: center;
    background-color: var(--MainNavy);
    box-sizing: border-box;
    color: var(--white);
    cursor: pointer;
    display: flex;
    font-size: 15px;
    font-weight: 600;
    height: 35px;
    justify-content: center;
    text-align: center;
    transform: translateX(-5px);
    transition: 0.2s;
    width: 80px;

    &:hover {color: var(--white);}
    &.active {
      background-color: var(--white);
      border: 1px solid var(--MainNavy);
      color: var(--MainNavy);
      border-radius: 8px;
      &:hover {background-color: var(--MainNavy); color: var(--white);}
    }
    &.search {border-radius: 8px;}
  }
  div.ResetButton {
    align-items: center;
    box-sizing: border-box;
    color: var(--gray-400);
    cursor: pointer;
    display: flex;
    font-size: 15px;
    font-weight: 600;
    height: 35px;
    justify-content: center;
    margin-left: 10px;
    transition: 0.2s;
    width: 50px;

    &:hover {color: var(--gray-800);}
    &.detail {
      border: 1px solid var(--gray-400);
      margin: unset;
      transform: translateX(-5px);
      width: 80px;
      border-radius: 8px;
      &:hover {background-color: var(--gray-200); border-color: var(--gray-200); color: var(--white);}
    }
  }
`;

const TitleSection = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Title = styled.div`
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  justify-content: center;
`;
const ButtonsSection = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;
`;

const NavSubSection = styled.div`
  align-items: flex-start;
  background-color: var(--white);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 10px;
`;

const NavDrawerButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 16px;
  width: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const NavDrawerIcon = styled.div`
  background-color: var(--white);
  height: 16px;
  width: 16px;

  mask-image: url(${downArrow}); mask-repeat: no-repeat; mask-size: contain;
  --webkit-mask-image: url(${downArrow}); --webkit-mask-repeat: no-repeat; --webkit-mask-size: contain;
`;

const NavBar = (props) => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();

  /* ====================================================================== #2 */
  const Nav = useRef();
  const NavDrawer = useRef();

  const [_nav, setNav] = useState(props.nav);
  const [_navStatus, setNavStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    setNav(() => { return props.nav });
  }, [props]);

  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  /* 네비 영역 펼치기/접기 설정 */
  const handleNavDrawer = (e) => {
    e.preventDefault();
    setNavStatus(!_navStatus);

    if (_navStatus) NavDrawer.current.style.transform = 'rotate(360deg)';
    else NavDrawer.current.style.transform = 'rotate(180deg)';
  };

  /* ====================================================================== #6 */

  return (
    <NavSection ref={Nav} nav={_nav}>
      <TitleSection>
        <Title>
          {props.title}
          <span className='subNavTabBtn' onClick={() => { navigate(props.subNavTabBtnLink, { replace: true }) }}>
            {props.subNavTabBtn}
          </span>
        </Title>
        <ButtonsSection status={_navStatus}>
          {props.buttons}
          {_nav !== '' ? (
            <button onClick={handleNavDrawer} className='btn-search'>
              <p>검색</p>
              <NavDrawerButton ref={NavDrawer} ><NavDrawerIcon></NavDrawerIcon></NavDrawerButton>
            </button>
          ) : null}
        </ButtonsSection>
      </TitleSection>
      {_navStatus && (
        <NavSubSection>
          {props.firstRow}
          {props.secondRow}
          {props.thirdRow}
        </NavSubSection>
      )}
    </NavSection>
  );
};

export default NavBar;
