import React from 'react';
import styled from 'styled-components';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';

const GridBody = styled.div`
  display: grid;
  grid-template-rows: 60px calc(100% - 60px);
  height: 100svh;
  overflow: auto;
  /* position: relative; */
  width: 100%;

  & footer {bottom: 8px;}
`;

const Grid4Body = (props) => {
  return (
    <GridBody>
      <Header />
      {props.contents}
      <Footer />
    </GridBody>
  );
};

export default Grid4Body;