export const YoungContentsReducer_reset = () => {
  return {
    type: 'YOUNGRESET',
  };
};

export const YoungContentsReducer_setAccountAttributeList = (
  thisAttributes,
) => {
  return {
    type: 'SETACCOUNTATTRIBUTELIST',
    payload: thisAttributes,
  };
};

export const YoungContentsReducer_setAccountContents = (thisContents) => {
  return {
    type: 'SETACCOUNTCONTENTS',
    payload: thisContents,
  };
};

export const YoungContentsReducer_setWorkOrderContent = (thisContents) => {
  return {
    type: 'SETWORKORDERCONTENT',
    payload: thisContents,
  };
};

export const YoungContentsReducer_setShipmentAddProducts = (thisProducts) => {
  return {
    type: 'SETINVOICEADDPRODUCTS',
    payload: thisProducts,
  };
};

export const YoungContentsReducer_setShipmentAddCLProducts = (thisProducts) => {
  return {
    type: 'SETINVOICEADDCLPRODUCTS',
    payload: thisProducts,
  };
};

export const YoungContentsReducer_setShipmentInfoContents = (thisContents) => {
  return {
    type: 'SETINVOICEINFOCONTENTS',
    payload: thisContents,
  };
};
export const YoungContentsReducer_setScheduleWorkOrder = (thisWorkOrder) => {
  return {
    type: 'SETSCHEDULEWORKORDER',
    payload: thisWorkOrder,
  };
};
