import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { monitoringActions_setSensorView } from 'store/modules/actions/common/monitoringActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { sensor } from 'api/apis/sensor';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import SensorData from 'pages/sensorData/custom/company/gl/contents/SensorData';

const Section = styled.main`
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 60px calc(100% - 60px);
  overflow: hidden;

  .contents {
    box-sizing: border-box;
    display: grid;
    gap: 50px;
    height: 100%;
    overflow: auto;
    width: 100%;
    
    &.single {
      padding: 50px;
    }


    table {
      background-color: var(--white);
      border-collapse: collapse;
      height: 100%;
      table-layout: fixed;
      width: 100%;

      td, th {
        border: 1px solid var(--gray-200);
        font-size: 1.2em;
        padding: 8px;
        text-align: center;
      }
      th {
        background-color: var(--MainNavy);
        color: var(--white);

        &.current {background-color: var(--MainBlue);}
        &.set {background-color: var(--gray-500);}
      }
      td {
        &.ON {color: var(--SeconGreen); font-weight: 600;}
        &.OFF {color: var(--SeconRed); font-weight: 600;}
        &.windVolume {color: var(--LightBlue); font-weight: 600;}

        font-weight: 600;

        span {
          font-size: 0.7em;
          font-weight: 300;
        }
      }
    }

    .sensor-group {
      background-color: var(--white);
      border-radius: 20px;
      box-sizing: border-box;
      display: grid;
      grid-template-rows: 100px calc(100% - 100px);
      height: 100%;
      margin: auto;
      max-width: 1200px;
      max-height: 800px;
      overflow: hidden;
      width: 100%;

      .sensor-header {
        align-items: center;
        background-color: var(--MainNavy);
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr auto auto;
        color: var(--white);
        gap: 12px;
        justify-content: space-between;
        padding: 20px 40px;

        h1 {
          font-size: 2em;
          width: fit-content;
        }
        h3 {
          font-size: 1.6em;
          width: fit-content;
        }

        div {
          align-items: center;
          box-sizing: border-box;
          display: flex;
          gap: 20px;
          height: 60px;
          justify-content: flex-start;
          outline: 1px solid var(--gray-200);
          outline-offset: 2px;
          padding-inline: 30px;
          width: fit-content;

          &.power {
            p {
              font-size: 2em;
              font-weight: 600;

              &.ON {color: var(--SeconGreen);}
              &.OFF {color: var(--SeconRed);}
            }
          }
          &.windVolume {
            p {
              color: var(--LightBlue);
              font-size: 2em;
              font-weight: 600;
            }
          }
        }
      }
      .sensor-content {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: repeat(3, 30%);
        height: 100%;
        overflow: hidden;
        justify-content: space-between;
        padding: 50px;
        width: 100%;

        .sensor-data {
          border: 1px solid var(--gray-200);
          border-radius: 20px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          box-sizing: border-box;
          display: grid;
          grid-template-columns: 100%;
          grid-template-rows: 100px 1fr 1fr;
          height: 100%;
          overflow: hidden;
          justify-content: center;
          width: 100%;

          .data-header {
            align-items: center;
            background-color: var(--MainNavy);
            box-sizing: border-box;
            display: flex;
            height: 100px;
            justify-content: space-between;
            padding: 30px;
            width: 100%;

            h3{
              align-items: center;
              color: var(--white);
              display: flex;
              font-size: 1.6em;
              justify-content: center;
            }

            button {
              height: 40px;
              width: 80px;
              &:hover {background-color: #eaebed;}
            }
          }


          .sensor {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: flex-start;
            width: 100%;

            h4 {
              align-items: center;
              background-color: var(--gray-500);
              border-right: 1px solid var(--gray-200);
              box-sizing: border-box;
              color: var(--white);
              display: flex;
              font-size: 1.4em;
              height: 100%;
              justify-content: center;
              width: 100px;
            }
            p {
              align-items: baseline;
              display: flex;
              font-size: 3em;
              font-weight: 600;
              gap: 10px;
              justify-content: center;
              width: calc(100% - 100px);
            }
            span {
              font-size: 0.6em;
              font-weight: 300;
            }

            &.current {
              border-bottom: 1px solid var(--gray-200);

              h4 {
                background-color: var(--MainBlue);
              }
            }
          }
        }
      }
    }
  }
`;

const GLSensorData = () => {
  const dispatch = useDispatch();
  const { monitoringReducer, userReducer } = useSelector((state) => state);

  const [_sensorList, setSensorList] = useState([]);
  const [_sensorDataList, setSensorDataList] = useState([]);
  // console.log('_sensorDataList : ', _sensorDataList);

  const [_modalStatus, setModalStatus] = useState(false);
  const [_sensorData, setSensorData] = useState({});

  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getSensorList();
    getSensorDataList(monitoringReducer.sensorView);
    const sensorInterval = setInterval(() => { getSensorDataList(monitoringReducer.sensorView); }, 60000);
    return () => clearInterval(sensorInterval)
  }, []);

  useEffect(() => { }, [monitoringReducer.sensorView]);
  useEffect(() => { /* console.log('_sensorDataList : ', _sensorDataList); */ }, [_sensorDataList]);

  const getSensorList = async () => {
    const body = { companyId: userReducer.company.companyId };
    await sensor.sensorGroup(body).then(response => {
      if (response === undefined) return;
      // console.log('sensor.sensorGroup : ', response);
      setSensorList(() => { return response.data });
    })
  }
  const getSensorDataList = async (sensorView) => {
    const body = { companyId: userReducer.company.companyId };
    if (checkEmptyNull(sensorView, false) && sensorView !== 'all') body.name = sensorView;
    // console.log('body : ', body);
    await sensor.sensorGroup(body).then(response => {
      if (response === undefined) return;
      console.log('sensor.sensorGroup : ', response);
      setSensorDataList(() => { return checkNullArray(response.data, []) });
      if (sensorView !== 'all') {
        const filterList = checkNullArray(response.data, []).filter(item => item.name === sensorView);
        setSensorDataList(() => { return filterList });
      }
    })
  }

  return (
    <Grid2Body contents={
      <Section className="Main">
        <NavBar
          title={<NavTitle menuCode={'702'} />}
          buttons={
            <>
              {/* <button className='btn-set' onClick={(e) => { e.preventDefault(); dispatch(monitoringActions_setSensorView('all')) }}>전체</button> */}
              <select className='SelectType' style={{ width: '180px' }} value={monitoringReducer.sensorView}
                onChange={(e) => {
                  dispatch(monitoringActions_setSensorView(e.target.value));
                  getSensorDataList(e.target.value);
                }}
              >
                <option value={'all'}>전체</option>
                {checkNullArray(_sensorList, []).map((sensor, index) => {
                  return (<option key={index + '_sensor'} value={sensor.name}>{sensor.name}</option>)
                })}
              </select>
            </>
          }
          nav={''}
        />

        <div className={monitoringReducer.sensorView === 'all' ? 'contents' : 'contents single'}>
          {monitoringReducer.sensorView === 'all' ? (
            <table>
              <colgroup>
                <col width={'200px'} />
              </colgroup>
              <thead>
                <tr>
                  <th rowSpan={2}></th>
                  <th rowSpan={2}>전원</th>
                  <th rowSpan={2}>풍량</th>
                  <th colSpan={2}>온도</th>
                  <th colSpan={2}>습도</th>
                  <th colSpan={2}>Co2</th>
                </tr>
                <tr>
                  <th className='current'>현재</th>
                  <th className='set'>설정</th>
                  <th className='current'>현재</th>
                  <th className='set'>설정</th>
                  <th className='current'>현재</th>
                  <th className='set'>설정</th>
                </tr>
              </thead>
              <tbody>
                {_sensorDataList.map((sensorData, index) => {
                  const data_run = checkNullArray(sensorData.sensorList, []).find((item) => item.code.slice(-4) === '_run');
                  const data_win = checkNullArray(sensorData.sensorList, []).find((item) => item.code.slice(-4) === '_ao1');
                  const data_temp = checkNullArray(sensorData.sensorList, []).find((item) => item.type === '온도');
                  const data_mo = checkNullArray(sensorData.sensorList, []).find((item) => item.type === '습도');
                  const data_co2 = checkNullArray(sensorData.sensorList, []).find((item) => item.type === 'Co2');
                  return (
                    <tr key={index + '_sensorData'}>
                      <th>{sensorData.name}</th>
                      <td className={checkEmptyNull(data_run?.value, 'OFF')}>{checkEmptyNull(data_run?.value, 'OFF')}</td>
                      <td className='windVolume'>{checkEmptyNull(data_win?.value, 0)} <span>%</span></td>
                      <td>{(checkEmptyNull(data_temp?.value, 0) * 1).toLocaleString()} <span>℃</span></td>
                      <td>{(checkEmptyNull(data_temp?.setValue, 0) * 1).toLocaleString()} <span>℃</span></td>
                      <td>{(checkEmptyNull(data_mo?.value, 0) * 1).toLocaleString()} <span>%</span></td>
                      <td>{(checkEmptyNull(data_mo?.setValue, 0) * 1).toLocaleString()} <span>%</span></td>
                      <td>{(checkEmptyNull(data_co2?.value, 0) * 1).toLocaleString()} <span>ppm</span></td>
                      <td>{(checkEmptyNull(data_co2?.setValue, 0) * 1).toLocaleString()} <span>ppm</span></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ) : (<SensorData content={_sensorDataList[0]} />)}
        </div>
      </Section>
    } />
  );
};

export default GLSensorData;