import styled from 'styled-components';

export const MyProfileComponent = styled.section`
  &>div:last-of-type {
    max-width: 1240px;
    margin-inline: auto;
  }

  & .myprofile-btns {
    display: grid;
    gap: 15px;
    
    & .formButton.pw {
      background-color: var(--MainBlue);
    }
  }

  & .myprofile-main {
    display: grid;
    gap: 30px;
    height: fit-content;
    width: 100%;

    & .myprofile-info, & .myprofile-form {
      background-color: var(--white);
      border-radius: 12px;
      box-sizing: border-box;
      display: grid;
      gap: 20px;
      height: fit-content;
      padding: 24px 40px;
      width: 100%;

      & .formBox {
        padding: unset;
        width: fit-content;

        &.master:first-of-type h4 {
          color: var(--color-1);
        }
        &.master input, &.master select {
          /* background-color: var(--color-1); */
          border-color: var(--color-1);
          /* color: var(--Text); */
          color: var(--color-1);
          /* color: var(--white); */
        }
      }
    }
  }
`;