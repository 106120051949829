import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { contractActions_setContractType, contractActions_setContractStatus, contractActions_setStartDate, contractActions_setEndDate, contractActions_setSearchOption, contractActions_setSearchText, contractActions_setPageNumber, contractActions_setTotalSize, contractActions_setReset, contractActions_setContractAccount, contractActions_setContractElementList, contractActions_setViewScale } from 'store/modules/actions/common/contractActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { contractTypeApi } from 'api/apis/contractTypeApi';
import { contractAttributeApi } from 'api/apis/contractAttributeApi';
import { contractApi } from 'api/apis/contractApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { LoadingMsg } from 'components/loading/LoadingMsg';

import ExcelDownloadModal from 'components/excel/ExcelDownloadModal';
import Footer from 'components/footer/Footer';
import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import MarketContractRow from 'components/row/MarketContractRow';
import MarketContractUploadModal from 'components/excel/upload/MarketContractUploadModal';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';
import ViewMinusButton from 'components/buttons/ViewMinusButton';
import ViewPlusButton from 'components/buttons/ViewPlusButton';
import ViewResetButton from 'components/buttons/ViewResetButton';

const Section = styled.main`
  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }
    & .statusButtons {
      display: flex;
      gap: 5px;
    }
    & .contractTypeButtons {
      display: flex;
      gap: 5px;
    }
  }

  & .TableSection {
    table {
      min-width: 100%;
      width: unset;
    }
    & thead th {
      padding: 5px;
    }
    tr[name='contractRows']:hover {cursor: pointer;}
    td {

    }
  }
`;

const StockTable = styled.table`
  thead tr {border: none;}
  tbody {
    tr {border-bottom: 1px solid var(--MainBlue);}
    td {border: 1px solid var(--gray-200);}
  } 
`;

const ViewerButtons = styled.div`
  align-items: center;
  bottom: 12px;
  column-gap: 2px;
  display: flex;
  justify-content: center;
  position: fixed;
  right: 76px;
  z-index: 999;
`;

const MarketContract = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { contractReducer, pageReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_contractList, setContractList] = useState([]);

  const [_contractTypes, setContractTypes] = useState([]);
  const [_contractAttrList, setContractAttrList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_excelUploadModalStatus, setExcelUploadModalStatus] = useState(false);
  const [_excelDownloadModalStatus, setExcelDownloadModalStatus] = useState(false);

  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('201') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    (async () => {
      await getContractTypes();
      await getContractAttrs(contractReducer.contractType);
      await getContractList(contractReducer.pageNumber - 1, contractReducer.contractType, contractReducer.contractStatus, contractReducer.isPushedSearchButton);
    })();

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getContractList(contractReducer.pageNumber - 1, contractReducer.contractType, contractReducer.contractStatus, contractReducer.isPushedSearchButton);
    }

    return () => { };
  }, [contractReducer.pageNumber]);

  useEffect(() => { }, [contractReducer, _contractList, _contractAttrList]);

  /* ========================================================================= #4 */
  const getContractTypes = async () => { // 수주 유형
    const BodyToPost = {companyId: userReducer.company.companyId};
    await contractTypeApi.searchContractType(BodyToPost).then((response) => {
      if (response === undefined) return;
      setContractTypes(() => { return response.data });
    });
  };
  const getContractAttrs = async (contractType) => { // 수주 항목
    const BodyToPost = {companyId: userReducer.company.companyId};
    if (checkEmptyNull(contractType, false) && contractType !== 'all') {
      BodyToPost.contractTypeId = Number(contractType);
    }
    await contractAttributeApi.searchContractAttribute(BodyToPost).then((response) => {
      if (response === undefined) return;
      setContractAttrList(() => { return response.data });
    });
  };

  const getContractList = async (page, contractType, contractStatus, isPushedSearchButton) => { // 수주 리스트
    console.log('props : ', page, contractType, contractStatus, isPushedSearchButton);
    const paging = `?page=${page}&size=10&sort=id,DESC`;

    const BodyToPost = {companyId: userReducer.company.companyId};
    if (checkEmptyNull(contractType, false) && contractType !== 'all') {
      BodyToPost.contractTypeId = Number(contractType);
    }
    if (checkEmptyNull(contractStatus, false) && contractStatus !== 'all') {
      BodyToPost.contractStatus = contractStatus;
    }
    if (checkEmptyNull(contractReducer.startDate, false) && checkEmptyNull(contractReducer.endDate, false)) {
      const startDate = new Date(contractReducer.startDate);
      const setStartDate = startDate.toISOString();
      BodyToPost.contractStartDate = setStartDate;

      const endDate = new Date(contractReducer.endDate);
      endDate.setDate(endDate.getDate() + 1);
      const setEndDate = endDate.toISOString();
      BodyToPost.contractEndDate = setEndDate;

      if (contractReducer.startDate > contractReducer.endDate) {
        const reverseStartDate = new Date(contractReducer.startDate);
        reverseStartDate.setDate(reverseStartDate.getDate() + 1);
        const setReverseStartDate = reverseStartDate.toISOString();

        const reverseEndDate = new Date(contractReducer.endDate);
        const setReversetEndDate = reverseEndDate.toISOString();

        BodyToPost.contractStartDate = setReversetEndDate;
        BodyToPost.contractEndDate = setReverseStartDate;
      }
    }
    if (isPushedSearchButton && checkEmptyNull(contractReducer.searchOption, false) && checkEmptyNull(contractReducer.searchText, false)) {
      BodyToPost[contractReducer.searchOption] = contractReducer.searchText;
    }

    await contractApi.searchContract(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      setContractList(() => { return response.data.content });
      dispatch(contractActions_setTotalSize(response.data.totalElements));
    });
  };

  /* ========================================================================= #5 */
  const handleContractSearchStatus = async (status) => { // 네비 > 수주 상태 변경
    console.log('status : ', status);
    dispatch(contractActions_setContractStatus(status));
    dispatch(contractActions_setPageNumber(1));
    await getContractList(0, contractReducer.contractType, status);
  }

  const handleCheckedItem = (contract, isChecked) => { // 수주 선택
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(contract);
    setCheckedItem(() => { return checkedItem });
  };

  const actSearch = async () => { // 검색
    dispatch(contractActions_setPageNumber(1));
    await getContractList(0, contractReducer.contractType, contractReducer.contractStatus);
  };

  const actExcelUpload = () => { // 엑셀 업로드
    setExcelUploadModalStatus(true);
  };
  const actExcelDownload = () => { // 엑셀 다운로드
    setExcelDownloadModalStatus(true);
  };

  const handleContractStatus = async (contract, index) => { // 수주 상태
    const contractIndex = index;

    // 수주상태
    let eventStatusText = '';
    let eventStatus = '';
    switch (contract.contractStatus) {
      case 'waiting':
        eventStatusText = '진행';
        eventStatus = 'proceeding';
        break;

      case 'proceeding':
        eventStatusText = '완료';
        eventStatus = 'end';
        break;

      default: return;
    }

    // body
    const BodyToPut = {
      ...contract,

      companyId: userReducer.company.companyId,
      userId: userReducer.user.id,
      contractStatus: eventStatus,

      deleteContractAttributeValueList: [],
      deleteContractElementList: [],
    };

    // delete
    delete BodyToPut['createdDate'];
    delete BodyToPut['modifiedDate'];
    delete BodyToPut['account'];
    delete BodyToPut['company'];

    // confirm
    const confirmText = `수주를 ${eventStatusText}시키겠습니까?`;
    if (window.confirm(confirmText) === true) {
      await contractApi.updateContract(contract.contractId, BodyToPut).then((response) => {
        if (response === undefined) return;
        console.log('contractApi.updateContract : ', response);

        setContractList((prev) => {
          const returnData = [...prev];
          returnData.splice(contractIndex, 1, response.data);
          return returnData;
        });
      })
    }
  };

  const actCopy = () => { // 수주 복사
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return alert('복사할 수주를 선택해주세요.');
    const checkedContent = checkedList[0];
    console.log('checkedContents : ', checkedContent);

    // 초기화
    dispatch(contractActions_setContractAccount({}));
    dispatch(contractActions_setContractElementList([]));

    navigate({
      pathname: pageReducer.currentPage + '/copy',
      search: `?${createSearchParams({copyContent: JSON.stringify(checkedContent)})}`,
      replace: true,
    });
  };
  const actUpdate = () => { // 수주 수정
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return alert('수정할 수주를 선택해주세요.');
    const checkedContent = checkedList[0];
    console.log('checkedContents : ', checkedContent);

    // 초기화
    dispatch(contractActions_setContractAccount({}));
    dispatch(contractActions_setContractElementList([]));

    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({updateContent: JSON.stringify(checkedContent)})}`,
      replace: true,
    });
  };
  const actDelete = () => { // 수주 삭제
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return alert('삭제할 수주를 선택해주세요.');
    const checkedContent = checkedList[0];
    console.log('checkedContents : ', checkedContent);

    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({deleteContent: JSON.stringify(checkedContent)})}`,
      replace: true,
    });
  };

  /* ========================================================================= #6 */

  return (
    <Grid3Body contents={
      <>
        <Section className="Main">
          <NavBar
            title={
              <div className='Title'>
                <NavTitle menuCode={'201'} />
                <div className='statusButtons'>
                  {[
                    { status: 'all', name: '전체' },
                    { status: 'waiting', name: '대기' },
                    { status: 'proceeding', name: '진행' },
                    { status: 'end', name: '완료' },
                  ].map((contractStatus, index) => {
                    return (
                      <button key={index + '_statusButton'}
                        className={contractReducer.contractStatus === contractStatus.status ? 'btn-inven-1' : 'btn-set'}
                        onClick={(e) => {
                          e.preventDefault();
                          handleContractSearchStatus(contractStatus.status);
                        }}
                      >
                        {contractStatus.name}
                      </button>
                    )
                  })}
                </div>
              </div>
            }
            buttons={
              <>
                {_authority.indexOf('201-2') !== -1 ? (
                  <>
                    <button
                      className='btn-add-nara'
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(contractActions_setContractAccount({}));
                        dispatch(contractActions_setContractElementList([]));
                        setTimeout(navigate(pageReducer.currentPage + '/create/market', { replace: true }), 1000);
                      }}
                    >
                      나라장터 수주 추가
                    </button>
                    <button
                      className='btn-add'
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(contractActions_setContractAccount({}));
                        dispatch(contractActions_setContractElementList([]));
                        setTimeout(navigate(pageReducer.currentPage + '/create', { replace: true }), 1000);
                      }}
                    >
                      추가
                    </button>
                    <button className='btn-copy' onClick={actCopy}>복사</button>
                    <button className='btn-edit' onClick={actUpdate}>수정</button>
                    <button className='btn-delete' onClick={actDelete}>삭제</button>
                    <button className='btn-upload' onClick={actExcelUpload}>엑셀 업로드</button>
                  </>
                ) : null}
                <button className='btn-download' onClick={actExcelDownload}>다운로드</button>
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <select
                    className="SelectType"
                    value={contractReducer.contractType}
                    onChange={(e) => {
                      dispatch(contractActions_setContractType(e.target.value));
                      dispatch(contractActions_setPageNumber(1));
                      getContractAttrs(e.target.value);
                      getContractList(0, e.target.value, contractReducer.contractStatus, contractReducer.isPushedSearchButton);
                    }}
                  >
                    <option value="all">전체</option>
                    {_contractTypes.map((contractType) => {
                      return (
                        <option key={contractType.contractTypeId + '_contractType'} value={contractType.contractTypeId}>
                          {contractType.contractTypeName}
                        </option>
                      );
                    })}
                  </select>
                  {/* <select
                    className="SelectStatus"
                    value={contractReducer.contractStatus}
                    onChange={(e) => {
                      dispatch(contractActions_setContractStatus(e.target.value));
                      dispatch(contractActions_setPageNumber(1));
                      getContractList(0, contractReducer.contractType, e.target.value, contractReducer.isPushedSearchButton);
                    }}
                  >
                    {[
                      { optionValue: 'all', optionText: '전체' },
                      { optionValue: 'waiting', optionText: '대기' },
                      { optionValue: 'proceeding', optionText: '진행' },
                      { optionValue: 'end', optionText: '완료' },
                    ].map((thisItem) => {
                      return (
                        <option key={thisItem.optionValue + '_contractStatus'} value={thisItem.optionValue}>
                          {thisItem.optionText}
                        </option>
                      );
                    })}
                  </select> */}
                  <div className="SearchSection">
                    <input
                      type="date"
                      className="SelectDate"
                      value={contractReducer.startDate}
                      onChange={(e) => { dispatch(contractActions_setStartDate(e.target.value)) }}
                    />

                    <IconRightArrow />

                    <input
                      type="date"
                      className="SelectDate"
                      value={contractReducer.endDate}
                      onChange={(e) => { dispatch(contractActions_setEndDate(e.target.value)) }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <div className="ContentCBox">
                      <select
                        className="SearchOption"
                        value={contractReducer.searchOption}
                        onChange={(e) => { dispatch(contractActions_setSearchOption(e.target.value)) }}
                      >
                        {[
                          { optionValue: '', optionText: '검색옵션' },
                          { optionValue: 'contractCode', optionText: '수주코드' },
                          { optionValue: 'contractName', optionText: '수주이름' },
                          { optionValue: 'accountName', optionText: '거래처명' },
                        ].map((thisItem) => {
                          return (
                            <option key={thisItem.optionValue + '_contractSearchOption'} value={thisItem.optionValue}>
                              {thisItem.optionText}
                            </option>
                          );
                        })}
                      </select>
                      <input
                        className="SearchBar"
                        placeholder="Search..."
                        value={contractReducer.searchText}
                        onInput={(e) => { dispatch(contractActions_setSearchText(e.target.value)) }}
                      />
                    </div>

                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton"
                      onClick={() => {
                        dispatch(contractActions_setReset());
                        getContractList(0, 'all', 'all', true);
                      }}
                    >
                      초기화
                    </div>
                  </div>
                </div>
              </>
            }
          />

          <TableSection content={
            <StockTable style={{ zoom: `${contractReducer.viewScale}%` }}>
              <thead>
                <tr>
                  <th rowSpan={2} style={{ minWidth: '100px', width: '100px' }}>수주상태</th>
                  {_authority.indexOf('201-2') !== -1 ? (<th rowSpan={2} style={{ minWidth: '50px', width: '50px' }}></th>) : null}
                  <th rowSpan={2}>수주일자</th>
                  <th rowSpan={2}>납품요구접수일자</th>
                  <th rowSpan={2}>납품요구번호(G2B)<br />계약번호(S2B)</th>
                  <th rowSpan={2}>수주이름</th>
                  <th rowSpan={2}>수요기관명</th>
                  <th rowSpan={2}>수주주소</th>
                  <th rowSpan={2}>수주총금액</th>
                  <th colSpan={4} style={{ backgroundColor: 'var(--ThirdBlue)' }}>수주물품</th>
                  <th rowSpan={2}>납품기한일자</th>
                  <th rowSpan={2}>납품일자</th>
                  <th rowSpan={2}>수주유형</th>
                  <th rowSpan={2}>특이사항</th>
                  <th rowSpan={2}>비고</th>

                  {_contractAttrList.map((contractAttr, index) => {
                    return (<th key={index + '_contractAttr'} rowSpan={2}>{contractAttr.contractAttributeName}</th>);
                  })}
                </tr>
                <tr>
                  <th>물품이름</th>
                  <th>물품코드</th>
                  <th>수량</th>
                  <th>금액</th>
                </tr>
              </thead>

              <tbody>
                {!checkNullArray(_contractList, false) ? LoadingMsg() :
                  checkNullArray(_contractList, []).map((contract, index) => {
                    return (
                      <MarketContractRow
                        key={index + '_contracts'}
                        authority={_authority}
                        index={index}
                        attr={_contractAttrList}
                        content={contract}
                        checkedItem={_checkedItem}
                        handleCheckedItem={handleCheckedItem}
                        handleContractStatus={handleContractStatus}
                      />
                    );
                  })}
              </tbody>
            </StockTable>
          } />
        </Section>

        <PagingComponent
          page={contractReducer.pageNumber}
          count={contractReducer.totalSize}
          size={10}
          pageEvent={(page) => {dispatch(contractActions_setPageNumber(page));}}
        />

        <ViewerButtons>
          <ViewMinusButton
            viewScale={contractReducer.viewScale}
            handleViewScale={(scale) => { dispatch(contractActions_setViewScale(scale)) }}
          />
          <ViewPlusButton
            viewScale={contractReducer.viewScale}
            handleViewScale={(scale) => { dispatch(contractActions_setViewScale(scale)) }}
          />
          <ViewResetButton
            handleViewScale={() => { dispatch(contractActions_setViewScale(100)) }}
          />
        </ViewerButtons>

        {_excelUploadModalStatus === true && (
          <MarketContractUploadModal
            open={_excelUploadModalStatus}
            close={() => {
              setExcelUploadModalStatus(false);
              getContractList(contractReducer.pageNumber - 1, contractReducer.contractType, contractReducer.contractStatus, contractReducer.isPushedSearchButton);
            }}
          />
        )}
        {_excelDownloadModalStatus === true && (
          <ExcelDownloadModal
            mainTitle={'marketcontract'}
            subTitle={'contract'}
            open={_excelDownloadModalStatus}
            close={() => { setExcelDownloadModalStatus(false) }}
          />
        )}

        <Footer></Footer>
      </>
    }
    />
  );
};

export default MarketContract;
