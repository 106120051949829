import React, { Fragment, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { DateFormat } from 'components/format/DateFormat';
import CommaNum from 'components/format/CommaNum';
import StatusTdButton from 'components/buttons/StatusTdButton';
import { checkEmptyNull, checkNullObject } from 'components/checkValues/checkValues';

const PriceGrid = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  height: 100%;
  width: 100%;
`;
const PriceBox = styled.div`
  border-bottom: 1px solid var(--gray-200);
  box-sizing: border-box;
  display: grid;
  height: 100%;
  grid-template-columns: 60px auto;
  width: 100%;
`;
const PriceName = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  border-right: 1px solid var(--gray-200);
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 5px;
  width: 100%;
`;
const PriceNum = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  padding-right: 15px;
  width: 100%;
`;

const ShipmentRowV2 = (props) => {
  /* ========================================================================= #1 */
  /* ========================================================================= #2 */
  const [shipmentData, setShipmentData] = useState(props.content);

  const [checkedItem, setCheckedItem] = useState(props.checkedItem);

  /* ========================================================================= #3 */
  useEffect(() => {
    setShipmentData(() => { return props.content });
    setCheckedItem(() => { return props.checkedItem });
  }, [props]);

  /* ========================================================================= #4 */
  /* ========================================================================= #5 */
  /* ========================================================================= #6 */

  return (
    <tr
      style={{ cursor: 'pointer' }}
      onClick={(e) => {
        e.preventDefault();
        props.goDetail(shipmentData);
      }}
    >
      {props.authority.indexOf('205-2') !== -1 ? (
        <td style={{ minWidth: '50px', width: '50px' }}>
          <input
            type="checkBox"
            name="shipments"
            data-key={shipmentData.shipmentId}
            checked={(() => {
              const checkedIndex = checkedItem ? Array.from(checkedItem).findIndex((shipment) => shipment.shipmentId === shipmentData.shipmentId) : -1;
              if (checkedIndex !== -1) return true;
              else return false;
            })()}
            onChange={(e) => {
              const isChecked = e.target.checked;
              props.handleCheckedItem(shipmentData, isChecked);
            }}
            onClick={(e) => { e.stopPropagation(); }}
          />
        </td>
      ) : null}
      <StatusTdButton
        statusStyle={{ minWidth: '100px', width: '100px' }}
        status={checkEmptyNull(shipmentData.shipmentStatus, 'wait')}
        statusEvent={(e) => {
          e.stopPropagation();
          if (shipmentData.shipmentStatus !== 'wait') return null;
          console.log('shipmentData : ', shipmentData);
          props.handleShipmentStatus(shipmentData, props.index);
        }}
      />
      <td style={{ minWidth: '60px', padding: 'unset', width: '60px' }}
        onClick={(e) => {
          e.stopPropagation();
          props.actPrintInvoice(shipmentData, props.index);
        }}
      >
        <img style={{ margin: '0px auto' }} src={process.env.PUBLIC_URL + '/src/icons/print.svg'} alt='거래명세서 출력 팝업 버튼' />
      </td>
      <td style={{ minWidth: '60px', padding: 'unset', width: '60px' }}
        onClick={(e) => {
          e.stopPropagation();
          props.actPrintDeliveryInvoice(shipmentData, props.index);
        }}
      >
        <img style={{ margin: '0px auto' }} src={process.env.PUBLIC_URL + '/src/icons/print.svg'} alt='송장 출력 팝업 버튼' />
      </td>
      <td>{checkEmptyNull(shipmentData.shipmentDate, false) ? DateFormat(shipmentData.shipmentDate) : ''}</td>
      <td>{shipmentData.shipmentCode}</td>
      <td>{shipmentData.shipmentName}</td>
      <td>
        {(() => {
          if (checkNullObject(shipmentData.account, false)) return shipmentData.account.accountName;
          else return shipmentData.accountName;
        })()}
      </td>
      <td style={{ padding: 'unset' }}>
        <PriceGrid>
          {(() => {
            const shipmentPrice = BigNumber(checkEmptyNull(shipmentData.shipmentPrice, 0)).toNumber();
            const tax = BigNumber(checkEmptyNull(shipmentPrice, 0)).multipliedBy(10).dividedBy(100).toNumber();
            const totalPrice = BigNumber(checkEmptyNull(shipmentPrice, 0)).plus(BigNumber(tax)).toNumber();

            return (
              <>
                <PriceBox>
                  <PriceName>매출<br />총액</PriceName>
                  <PriceNum><CommaNum displayType={'text'} num={shipmentPrice} /></PriceNum>
                </PriceBox>
                <PriceBox>
                  <PriceName>세액</PriceName>
                  <PriceNum><CommaNum displayType={'text'} num={tax} /></PriceNum>
                </PriceBox>
                <PriceBox>
                  <PriceName>합계</PriceName>
                  <PriceNum><CommaNum displayType={'text'} num={totalPrice} /></PriceNum>
                </PriceBox>
              </>
            );
          })()}
        </PriceGrid>
      </td>
      <td>{checkEmptyNull(shipmentData.remark, '')}</td>
    </tr>
  );
};

export default ShipmentRowV2;
