import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

import { sensorDataApi } from 'api/apis/sensorDataApi';

import { Section } from 'pages/sensorData/TmpHmdData.style';

import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import SensorData from './contents/SensorData';

const MgSensorData = () => {
  const { userReducer } = useSelector((state) => state);

  const [_pageNumber, setPageNumber] = useState(1);

  const [_startDate, setStartDate] = useState(
    moment().add('-1', 'm').format('YYYY-MM-DD hh:mm:ss'),
  );
  const [_endDate, setEndDate] = useState(
    moment().format('YYYY-MM-DD hh:mm:ss'),
  );

  const [_logList, setLogList] = useState([]);
  const [_viewList, setViewList] = useState([]);
  const [_sensorView, setSensorView] = useState('');
  const [_sensorData, setSensorData] = useState([]);
  
  // ---

  useEffect(() => {
    getSensorData(_pageNumber - 1, userReducer.company.companyId);
    return () => {};
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getSensorData(_pageNumber - 1, userReducer.company.companyId);
      findViewList();
    };

    fetchData();

    return () => {};
  }, [_pageNumber, userReducer.company.companyId]); // 의존성 배열에 필요한 값 추가

  useEffect(() => {
    const tempDatas = _logList
        .filter(log => log.name.includes('온도'))
        .map(log => log.value);

    const humDatas = _logList
        .filter(log => log.name.includes('습도'))
        .map(log => log.value);

    // 모든 데이터를 하나의 배열에 객체 형태로 담기
    const combinedData = _viewList?.map((name, index) => ({
        name: name || '데이터 없음',
        temp: tempDatas[index] || '데이터 없음',
        hum: humDatas[index] || '데이터 없음',
    }));
    
    findViewList(_logList)
    
    // 상태 업데이트
    setSensorData(combinedData);
  }, [_logList]);

  // ---

  // 온습도 API 호출 관련 함수
  const getSensorData = async (page, companyId) => {
    const paging = `?page=${page}&size=100&sort=id,DESC`;
    const BodyToPost = {
      companyId: 29,
      startDate: moment().add('-1', 'm').add(9, 'hour').toISOString(),
      endDate: moment().add(9, 'hour').toISOString(),
      name: '',
    };

    if (checkEmptyNull(_startDate, false))
      BodyToPost.startDate = moment(_startDate).add(9, 'hour').toISOString();
    if (checkEmptyNull(_endDate, false))
      BodyToPost.endDate = moment(_endDate).add(9, 'hour').toISOString();

    await sensorDataApi.countData(paging, BodyToPost).then((response) => {
      // 센서 누적 데이터
      if (response === undefined) return;
      setLogList(() => {
        return response.data.content;
      });
    });
  };

  // ---
  const findViewList = () => {
    const viewList = _logList
        .filter(log => log.name.includes('온도'))
        .map(log => log.name.split('_')[0]);
    setViewList(viewList);
  };

  // ---

  return (
    <Grid2Body
      contents={
        <Section className="Main">
          <NavBar
            title={<NavTitle menuCode={'702'} />}
            buttons={
              <>
                <select
                  className="SelectType"
                  style={{ width: '180px' }}
                  value={_sensorView}
                  onChange={(e) => {
                    const newSensorView = e.target.value;
                    setSensorView(newSensorView);
                  }}
                >
                  <option value={''}>전체</option>
                  {checkNullArray(_sensorData, []).map((sensor, index) => {
                    return (
                      <option key={index + '_sensor'} value={sensor.name}>
                        {sensor.name}
                      </option>
                    );
                  })}
                </select>
              </>
            }
            nav={''}
          />

          <div
            className={
              _sensorView === ''
                ? 'contents'
                : 'contents single'
            }
          >
            {_sensorView === '' ? (
              <table className='mg'>
                <colgroup>
                  <col width={'350px'} />
                </colgroup>
                <thead>
                  <tr>
                    <th rowSpan={2}></th>
                    <th>온도</th>
                    <th>습도</th>
                  </tr>
                  <tr>
                    <th className="current">현재</th>
                    <th className="current">현재</th>
                  </tr>
                </thead>
                <tbody>
                  {_sensorData.map((sensorData, index) => {
                    const data_temp = sensorData.temp;
                    const data_mo = sensorData.hum;
                    return (
                      <tr key={index + '_sensorData'}>
                        <th>{sensorData.name}</th>
                        <td>
                          {(
                            checkEmptyNull(data_temp, 0) * 1
                          ).toLocaleString()}{' '}
                          <span>℃</span>
                        </td>
                        <td>
                          {(
                            checkEmptyNull(data_mo, 0) * 1
                          ).toLocaleString()}{' '}
                          <span>%</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <SensorData content={_sensorData[0]} />
            )}
          </div>
        </Section>
      }
    />
  );
};

export default MgSensorData;
