import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';

import { contentsReducer_reset } from 'store/modules/actions/default/contentsActions';
import {
  pageReducer_getCurrentPage,
  pageReducer_setPageNum,
  pageReducer_setPageSize,
  pageReducer_setTotalCount,
} from 'store/modules/actions/default/pageActions';
import {
  searchReducer_reset,
  searchReducer_setProductType,
} from 'store/modules/actions/default/searchActions';

import { getCookie } from 'preferences/cookie/cookie';
import { ACCESS_TOKEN, AXIOS_BASE_URL } from 'preferences/server/constants';

import CommaNum from 'components/format/CommaNum';
import ExcelDownloadModal from 'components/excel/ExcelDownloadModal';
import Grid3Body from 'components/layouts/body/Grid3Body';
import Nav from 'components/nav/Nav';
import Paging from 'components/paging/Paging';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const GSStockHistory = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { pageReducer, searchReducer, userReducer } = useSelector(
    (state) => state,
  );

  /* ====================================================================== #2 */
  const TitleName = 'Products';
  const subTitle = 'StockHistory';

  const [_onload, setOnload] = useState('unload');

  const [_logList, setLogList] = useState([]);
  const [_excelDownloadModalStatus, setExcelDownloadModalStatus] =
    useState(false);

  const [_authority, setAuthority] = useState([]);
  console.log('_authority : ', _authority);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter(
      (thisItem) => thisItem.authority.startsWith('305') === true,
    );
    const authorityCodes = authorityContent.map(
      (thisItem) => thisItem.authority,
    );
    setAuthority(authorityCodes);

    dispatch(contentsReducer_reset());
    dispatch(searchReducer_reset());

    setOnload('loaded');

    if (window.location.pathname === pageReducer.currentPage) {
      setHistoryTable();
    } else {
      dispatch(pageReducer_getCurrentPage(window.location.pathname));
    }

    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      dispatch(pageReducer_setPageNum(1));
      dispatch(pageReducer_setPageSize(20));

      setHistoryTable();
    }

    return () => { };
  }, [pageReducer.currentPage]);

  useEffect(() => {
    if (_onload === 'loaded') {
      if (
        searchReducer.productType === '' ||
        searchReducer.productType === 'all'
      ) {
        return dispatch(searchReducer_setProductType('material'));
      }

      dispatch(pageReducer_setPageNum(1));
      setHistoryTable();
    }

    return () => { };
  }, [searchReducer.productType, searchReducer.currentFamilyId]);

  useEffect(() => {
    if (_onload === 'loaded') {
      if (searchReducer.searchOption === '') {
        setHistoryTable();
      }
    }

    return () => { };
  }, [searchReducer.searchOption, pageReducer.currentPageNum]);

  /* ====================================================================== #4 */
  const setHistoryTable = async () => {
    const BodyToPost = {};
    if (searchReducer.productType !== '')
      if (searchReducer.productType.toLowerCase() !== 'all') {
        BodyToPost.elementType = searchReducer.productType;
      }
    if (searchReducer.currentFamilyId !== 0)
      BodyToPost.categoryId = searchReducer.currentFamilyId;
    if (searchReducer.searchOption !== '')
      BodyToPost[searchReducer.searchOption] = searchReducer.searchText;
    if (searchReducer.startDate !== '' && searchReducer.endDate !== '') {
      const thisStartDate = new Date(searchReducer.startDate);
      const setStartDate = thisStartDate.toISOString();
      console.log('setStartDate:', setStartDate);
      BodyToPost.elementStockLogStartDate = setStartDate;

      const thisEndDate = new Date(searchReducer.endDate);
      thisEndDate.setDate(thisEndDate.getDate() + 1);
      const setEndDate = thisEndDate.toISOString();
      console.log('setEndDate:', setEndDate);
      BodyToPost.elementStockLogEndDate = setEndDate;

      // 시작날짜가 종료날짜보다 클 때
      if (searchReducer.startDate > searchReducer.endDate) {
        const reverseStartDate = new Date(searchReducer.startDate);
        reverseStartDate.setDate(reverseStartDate.getDate() + 1);
        const setReverseStartDate = reverseStartDate.toISOString();

        const reverseEndDate = new Date(searchReducer.endDate);
        const setReversetEndDate = reverseEndDate.toISOString();

        BodyToPost.scheduledStartDate = setReversetEndDate;
        BodyToPost.scheduledEndDate = setReverseStartDate;
      }
    }
    console.log('set HistoryTable-body : ', BodyToPost);

    Object.keys(BodyToPost).length === 0 || searchReducer.productType === 'all'
      ? await findAllElementStockLog()
      : await searchAllElementStockLog(BodyToPost);
  };

  const findAllElementStockLog = async () => {
    const paging = `?page=${pageReducer.currentPageNum - 1}&size=${pageReducer.pageSize
      }&sort=id,DESC`;
    console.log(paging, pageReducer.currentPageNum);

    await axios
      .post(
        AXIOS_BASE_URL + '/elementStockLog/search' + paging,
        {
          companyId: userReducer.company.companyId,
        },
        {
          headers: {
            Authorization: getCookie('accessToken'),
          },
        },
      )
      .then((response) => {
        if (response === undefined) return;
        console.log(response);
        Finish_findAllElementStockLog(response.data);
        dispatch(pageReducer_setTotalCount(response.data.totalElements));
      })
      .catch((error) => {
        console.log('setHistoryTable-error : ', error);
      });
  };
  const Finish_findAllElementStockLog = (Result) => {
    const thisList = Result.content;
    console.log('post/elementStockLog', Result);
    console.log('post/elementStockLog - content', thisList);

    setLogList(thisList);
  };

  const searchAllElementStockLog = async (BodyToPost) => {
    const paging = `?page=${pageReducer.currentPageNum - 1}&size=${pageReducer.pageSize
      }&sort=id,DESC`;
    console.log(paging, pageReducer.currentPageNum);

    await axios
      .post(
        AXIOS_BASE_URL + '/elementStockLog/search' + paging,
        {
          ...BodyToPost,
          companyId: userReducer.company.companyId,
        },
        {
          headers: {
            Authorization: getCookie('accessToken'),
          },
        },
      )
      .then((response) => {
        if (response === undefined) return;
        // if(response.data.totalElements === 0)
        //   alert('검색 결과가 없습니다.');
        Finish_searchAllElementStockLog(response.data);
        dispatch(pageReducer_setTotalCount(response.data.totalElements));
      })
      .catch((error) => {
        console.log('setHistoryTable-error : ', error);
      });
  };
  const Finish_searchAllElementStockLog = (Result) => {
    const thisList = Result.content;
    // if(thisList.length === 0){
    //   alert('검색 결과가 없습니다. 조건을 다시 한번 확인 해주세요.')
    // }
    console.log('post/elementStockLog/search', Result);
    console.log('post/elementStockLog/search - content', thisList);

    setLogList(thisList);
  };

  /* ====================================================================== #5 */
  const showExcelDownloadModal = () => {
    setExcelDownloadModalStatus(true);
  };

  const actSearch = async () => {
    dispatch(pageReducer_setPageNum(1));
    dispatch(pageReducer_setPageSize(20));

    await setHistoryTable();
  };

  /* ====================================================================== #6 */

  return (
    <Grid3Body
      contents={
        <>
          <main className="Main">
            <Nav
              thisTitle={'재고상세이력'}
              content={
                <button className='btn-download' onClick={showExcelDownloadModal}>
                  다운로드
                </button>
              }
              nav={'all'}
              search={'date'}
              searchButton={
                <button id="searchButton" onClick={actSearch}>
                  <SearchButtonIcon />
                </button>
              }
              searchOptions={[
                { optionValue: 'all', optionText: '전체' },
                { optionValue: 'elementCode', optionText: '물품코드' },
                { optionValue: 'elementName', optionText: '물품이름' },
                { optionValue: 'userName', optionText: '작업자' },
              ]}
              statusOptions={''}
            />
            <TableSection
              content={
                <table>
                  <thead>
                    <tr>
                      <th>입력시각</th>
                      <th>물품이름</th>
                      <th>물품코드</th>
                      <th style={{ minWidth: '150px', width: '150px' }}>
                        입고
                      </th>
                      <th style={{ minWidth: '150px', width: '150px' }}>
                        {searchReducer.productType.toLowerCase() === 'material'
                          ? userReducer.putName
                          : searchReducer.productType.toLowerCase() === 'semi'
                            ? userReducer.putName
                            : searchReducer.productType.toLowerCase() === 'half'
                              ? userReducer.putName
                              : searchReducer.productType.toLowerCase() ===
                                'product'
                                ? '출하'
                                : userReducer.putName + '/출하'}
                      </th>
                      <th>비고</th>
                      <th>작업자</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_logList.map((thisLog) => {
                      let thisLogDate = '';
                      if (typeof thisLog.checkedDate === 'object') {
                        const thisCheckedDate = `${thisLog.checkedDate[0]}-${thisLog.checkedDate[1]}-${thisLog.checkedDate[2]} ${thisLog.checkedDate[3]}:${thisLog.checkedDate[4]}:${thisLog.checkedDate[5]}`;
                        const newStartDate = new Date(thisCheckedDate);
                        thisLogDate = moment(newStartDate).format(
                          'YYYY-MM-DD HH:mm:ss',
                        );
                      }

                      return (
                        <tr key={thisLog.elementStockLogId}>
                          <td>{thisLogDate}</td>
                          <td>{thisLog.element.elementName}</td>
                          <td>{thisLog.element.elementCode}</td>
                          <td
                            style={{
                              color: 'var(--MainGreen)',
                              minWidth: '150px',
                              width: '150px',
                            }}
                          >
                            {thisLog.logType === 'In' && (
                              <CommaNum
                                displayType={'text'}
                                num={thisLog.amount}
                              />
                            )}
                            {thisLog.logType === 'In' &&
                              ' ' + thisLog.element.elementUnit}
                          </td>
                          <td
                            style={{
                              color: 'var(--MainRed)',
                              minWidth: '150px',
                              width: '150px',
                            }}
                          >
                            {thisLog.logType !== 'In' && (
                              <CommaNum
                                displayType={'text'}
                                num={thisLog.amount}
                              />
                            )}
                            {thisLog.logType !== 'In' &&
                              ' ' + thisLog.element.elementUnit}
                          </td>
                          <td>{thisLog.note}</td>
                          <td>{thisLog.userName}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
            ></TableSection>
          </main>

          <Paging
            page={pageReducer.currentPageNum}
            count={pageReducer.totalCount}
            size={pageReducer.pageSize}
          />

          {_excelDownloadModalStatus === true && (
            <ExcelDownloadModal
              open={_excelDownloadModalStatus}
              mainTitle={TitleName}
              subTitle={subTitle}
              close={() => {
                setExcelDownloadModalStatus(false);
              }}
            />
          )}
        </>
      }
    />
  );
};

export default GSStockHistory;
