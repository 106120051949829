import React from 'react';
import styled from 'styled-components';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';

const GridBody = styled.div`
  display: grid;
  grid-template-rows: 60px calc(100% - 60px);
  height: 100svh;
  overflow: hidden;
  /* position: relative; */
  width: 100%;

  /* & footer {bottom: 8px;} */
`;

const Grid3Body = (props) => {
  return (
    <GridBody>
      <Header />
      <div className='main-pagination'>
        {props.contents}
      </div>
      <Footer />
    </GridBody>
  );
};

export default Grid3Body;