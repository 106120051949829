import React, { useEffect, useState, } from 'react';
import { useSelector } from 'react-redux';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import BigNumber from 'bignumber.js';

import { cart } from 'api/apis/mall/cart';

import { checkEmptyNull, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import CommaNum from 'components/format/CommaNum';

const MallProductDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userReducer } = useSelector((state) => state);

  const companyCode = location.pathname.split('/')[1];

  const [searchParams, setSearchParams] = useSearchParams();
  const mallElement = checkNullParse(searchParams.get('mallElement'), {});

  const [_amount, setAmount] = useState(1);
  const [_totalPrice, setTotalPrice] = useState(() => {
    const amount = _amount;
    const price = checkEmptyNull(mallElement.mallElementPrice, 0);
    const calPrice = BigNumber(price).multipliedBy(BigNumber(amount)).toNumber();
    return calPrice;
  });

  // 수량 더하기 +1
  const actPlus = () => {
    const amount = BigNumber(_amount).plus(1).toNumber();
    const price = checkEmptyNull(mallElement.mallElementPrice, 0);
    const calPrice = BigNumber(price).multipliedBy(BigNumber(amount)).toNumber();
    setAmount(() => { return amount; });
    setTotalPrice(() => { return calPrice; });
  };
  // 수량 빼기 -1
  const actMinus = () => {
    const amount = BigNumber(_amount).minus(1).toNumber() > 0 ? BigNumber(_amount).minus(1).toNumber() : 1;
    const price = checkEmptyNull(mallElement.mallElementPrice, 0);
    const calPrice = BigNumber(price).multipliedBy(BigNumber(amount)).toNumber();
    setAmount(() => { return amount; });
    setTotalPrice(() => { return calPrice; });
  };

  // 장바구니 추가
  const AddCart = async () => {
    const userId = userReducer.user.id;
    if (!checkEmptyNull(userId, false)) return alert('로그인이 필요합니다.')
    const BodyToPost = {
      companyId: userReducer.user.companyId,
      userId: userId,
      mallElementId: mallElement.mallElementId,
      count: 1,
    };
    await cart.createMallCart(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('cart.createMallCart : ', response);
      const confirmText = `
장바구니에 추가되었습니다.
장바구니로 이동하시겠습니까?
      `;
      if (window.confirm(confirmText)) {
        navigate({ pathname: `/${companyCode}/mall/cart`, replace: true });
      } else {
        return;
      }
    });
  };

  // 문의하기
  const AddQnA = () => {
    const userId = userReducer.user.id;
    if (!checkEmptyNull(userId, false)) return alert('로그인이 필요합니다.')
    navigate({
      pathname: `/${companyCode}/mall/product/qna`,
      search: `?${createSearchParams({ mallElementList: JSON.stringify([mallElement]) })}`,
      replace: true,
    });
  };

  // 주문하기
  const AddOrder = () => {
    const userId = userReducer.user.id;
    if (!checkEmptyNull(userId, false)) return alert('로그인이 필요합니다.')
    const mallElementData = {
      ...mallElement,
      count: _amount,
      mallElementTotalPrice: _totalPrice,
    };
    navigate({
      pathname: `/${companyCode}/mall/product/order`,
      search: `?${createSearchParams({ mallElementList: JSON.stringify([mallElementData]) })}`,
      replace: true,
    });
  };

  return (
    <>
      <div>
        <div className='image'>
          <p className='detailTitle'>상품 이미지</p>
          {(() => {
            if (checkNullObject(mallElement.attachFileList, false)) {
              const fileData = mallElement.attachFileList[0];
              return (<img src={fileData.fileData.fileDataS3URL} alt={fileData.fileData.fileDataName} />);
            } else {
              return <div className='thumbnail'></div>;
            }
          })()}
        </div>
        <div>
          <p className='detailTitle'>상품 정보</p>
          <ul>
            <li><p>카테고리</p><p title={mallElement.mallCategoryName}>{mallElement.mallCategoryName}</p></li>
            <hr />
            <li><p>상품코드</p><p title={mallElement.mallElementCode}>{mallElement.mallElementCode}</p></li>
            <li><p>상품이름</p><p title={mallElement.mallElementName}>{mallElement.mallElementName}</p></li>
            <hr />
            <li><p>단가</p><p><CommaNum displayType="text" num={checkEmptyNull(mallElement.mallElementPrice, 0)} />&nbsp;{`원`}</p></li>
            <li><p>규격</p><p title={mallElement.mallElementSize}>{mallElement.mallElementSize}</p></li>
            <li><p>설명</p><p title={mallElement.mallElementContent}>{mallElement.mallElementContent}</p></li>
            <hr />
            <li className='quantity'>
              <p>수량</p>
              <input
                type="number"
                min={1}
                value={_amount}
                onInput={(e) => {
                  const amount = e.target.value > 0 ? e.target.value : 1;
                  setAmount(() => { return amount; });
                }}
              />
              <button onClick={actPlus}>+</button>
              <button onClick={actMinus}>-</button>
            </li>
            <li className='totalprice'><p>총 가격</p><p><CommaNum displayType="text" num={_totalPrice} />&nbsp;{`원`}</p></li>
            <li style={{ marginTop: '40px' }}>
              <button onClick={(e) => { e.stopPropagation(); AddCart(); }}>장바구니 추가</button>
              <button onClick={(e) => { e.stopPropagation(); AddQnA(); }}>문의하기</button>
              <button onClick={(e) => { e.stopPropagation(); AddOrder(); }}>주문하기</button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default MallProductDetail;
