import { AxiosInterceptor } from "api/core/consts";

export const equipmentApi = {
  searchEquipment: (paging, body) =>
    AxiosInterceptor.post('equipment/search' + paging, body),
  createEquipment: (body) => AxiosInterceptor.post('equipment', body),
  updateEquipment: (equipementId, body) =>
    AxiosInterceptor.put('equipment/' + equipementId, body),
  deleteEquipment: (equipementId) =>
    AxiosInterceptor.delete('equipment/' + equipementId),
};
