const initialState = {
  workOrderLogManagerIds: [],
  workOrderLogWorkerIds: [],

  processLogManagerIds: {},
  processLogWorkerIds: {},
};

const workerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETWORKORDERLOGMANAGERIDS':
      return {
        ...state,
        workOrderLogManagerIds: action.payload,
      };
    case 'SETWORKORDERLOGWORKERIDS':
      return {
        ...state,
        workOrderLogWorkerIds: action.payload,
      };

    case 'SETPROCESSLOGMANAGERIDS':
      return {
        ...state,
        processLogManagerIds: action.payload,
      };
    case 'SETPROCESSLOGWORKERIDS':
      return {
        ...state,
        processLogWorkerIds: action.payload,
      };

    case 'RESETWORKDERREDUCER':
      return initialState;

    default:
      return state;
  }
};

export default workerReducer;
