import { MallAxiosInterceptor } from "api/core/consts";

export const order = {
  getMallOrder: (mallOrderId) => MallAxiosInterceptor.get('mallOrder/' + mallOrderId),
  searchMallOrder: (paging, body) => MallAxiosInterceptor.post('mallOrder/search' + paging, body),
  createMallOrder: (body) => MallAxiosInterceptor.post('mallOrder', body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  updateMallOrder: (mallOrderId, body) => MallAxiosInterceptor.put('mallOrder/' + mallOrderId, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  deleteMallOrder: (mallOrderId) => MallAxiosInterceptor.delete('mallOrder/' + mallOrderId),
};
