import styled from "styled-components";

export const ClusterComponent = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;

  & header {
    align-items: center;
    background-color: var(--gray-100);
    box-sizing: border-box;
    display: flex;
    height: 55px;
    justify-content: flex-start;
    padding: 0px 20px;
    position: relative;
    width: 100%;

    h4 {
      font-size: 20px;
      font-weight: 400;
      white-space: nowrap;
    }
    & .btn-close {
      height: 25px;
      position: absolute;
      right: 15px;
      top: 15px;
      width: 25px;
    }
  }

  & section {
    background-color: var(--white);
    border-radius: 10px;
    display: grid;
    height: 85%;
    overflow: hidden;
    width: 85%;
  }

  & section.management {
    grid-template-rows: 55px auto;
    max-width: 1200px;

    &>div {
      display: grid;
      grid-template-rows: 60px calc(100% - 60px);
      height: 100%;
      width: 100%;

      nav {
        align-items: center;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        padding-inline: 20px;

        & div {
          display: flex;
          gap: 5px;
        }
      }

      & .TableSection {
        grid-row-start: 2;
        grid-row-end: 4;
        margin: auto;
        width: fit-content;
        & table {
          min-width: unset;
          width: fit-content;

          th, td {
            border: none;
            font-size: 18px;
            min-width: unset;
            padding: 18px 36px;
            text-align: left;
          }

          th {
            background-color: #EDF1F5;
            color: var(--Text);
          }
      
          td {
            background: unset;

            & input[type="radio"] {
              cursor: pointer;
              transform: scale(1.2);
            }
          }

          & tbody tr {
            box-sizing: border-box;
            border-bottom: 1px solid #99B0CB;
          }
        }
      }
    }
  }

  & section.register {
    grid-template-rows: 55px auto 50px;
    max-height: 400px;
    max-width: 600px;

    &>div:first-of-type {
      align-items: center;
      display: flex;
      justify-content: center;

      & form > div {
        background-color: unset;
        place-content: unset;

        & select {
          border: 1px solid var(--gray-200);
          border-radius: 10px;
          height: 50px;
          text-align: center;
          width: 100%;
        }
      }
    }

    &>div:last-of-type {
      align-items: center;
      background-color: var(--MainNavy);
      color: var(--white);
      cursor: pointer;
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  }
`;