
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';

import { inquiryApi } from 'api/apis/inquiryApi';
import { inquiryReplyApi } from 'api/apis/inquiryReplyApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateTimeFormatKR } from 'components/format/DateTimeFormatKR';
import { InquiryStatus } from 'pages/inquiry/components/status';
import { uploadSection } from 'components/icons/src';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import InquiryReplyList from 'pages/inquiry/components/reply';

import Grid4Body from 'components/layouts/body/Grid4Body';
import NavBar from 'components/nav/NavBar';

import { InquiryStyle } from './style'

const DeleteButton = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 20px;
  justify-content: center;
  transform: scale(1.5);
  width: 20px;

  div {
    background-color: var(--MainRed);
    cursor: pointer;
    height: 15px;
    width: 15px;
  }
`;
const FileBox = styled.div`
  align-items: center;
  background-color: var(--Bg);
  box-sizing: border-box;
  color: var(--gray-800);
  display: flex;
  height: 200px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;

  & img {
    height: inherit;
    max-width: inherit;
    width: auto;
  }
`;
const DeleteFileButton = styled.div`
  align-items: center;
  background-color: var(--MainRed);
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  height: 20px;
  justify-content: center;
  padding-left: 3px;
  position: absolute;
  right: 15px;
  top: 15px;
  transform: scale(1.5);
  width: 20px;

  div {
    background-color: var(--white);
    cursor: pointer;
    height: 15px;
    width: 15px;
  }
`;



export default function InquiryInfo(props) {
  // 라우터 path 에서 ':inquiryId' 로 넘겨주고
  // 리스트 map 에서 props로 넘겨주는 inquiry를 Link에 넣어서 {`/inquiry/${inquiry.inquiryId}`} 넘겨주고
  // 상세페이지에서는 useParams 로 받음
  const { inquiryId } = useParams();

  const [_formData, setFormData] = useState({
    companyId: '',
    userId: '',

    inquiryId: inquiryId,
    inquiryReplyId: '',
    inquiryCode: '',
    status: '',

    inquiryContent: '',
    inquiryReplyContent: '',
    inquiryReplyList: [],
    deleteInquiryReplyList: [],
    // attachFileList: [],
    deleteFileDataList: [],
  });
  const [inquiryInfo, setInquiryInfo] = useState({});

  useEffect(() => {
    const info = getInquiryInfo(inquiryId);
    if (!info) {
      return;
    }
    setFormData(() => {
      return {
        companyId: info.companyId,
        userId: info.userId,

        inquiryId: inquiryId,
        inquiryCode: info.inquiryCode,
        status: checkEmptyNull(info.status, 'waiting'),
        inquiryContent: checkEmptyNull(info.inquiryContent, ''),

        inquiryReplyContent: checkEmptyNull(info.inquiryReplyContent, ''),
        inquiryReplyList: checkEmptyNull(info.inquiryReplyList, []),
        deleteInquiryReplyList: [],

        // attachFileList: checkNullArray(info.attachFileList, []),
        deleteFileDataList: [],
      }
    });
  }, [inquiryId]);
  const [_inquiryReplyList, setInquiryReplyList] = useState([]);

  /* ====================================================================== #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value }; });
  };


  /* 읽기 */
  const getInquiryInfo = async () => {
    await inquiryApi.getInquiryId(inquiryId).then((response) => {
      console.log('InquiryId : ', response.data);
      if (response.data) {
        setInquiryInfo(() => { return response.data; });
      }
      return response.data
    });
  };

  /* 수정 */

  // 문의 내용 수정 버튼
  const [editContent, setEditContent] = useState(true)
  const editInquiryContent = () => { setEditContent(!editContent); }
  const actUpdate = (e) => {
    e.preventDefault();

    const BodyToPut = {
      ..._formData,
      inquiryContent: _formData.inquiryContents,
      status: inquiryInfo.status,
    };
    console.log('BodyToPut 수정한 문의 : ', BodyToPut);

    const putFormData = new FormData();
    putFormData.append('key', new Blob([JSON.stringify(BodyToPut)], { type: 'application/json' }));

    for (let values of putFormData.values()) {
      console.log('putFormData : ', values);
    }

    // return;
    inquiryApi.modifiedInquiry(inquiryId, putFormData).then((response) => {
      if (response === undefined) return;
      console.log('inquiry.modifiedInquiry : ', response);
      // alert('문의정보를 수정했습니다.');
      // location.reload();
      setEditContent(!editContent);
      setInquiryInfo(() => {
        console.log(`do set inquiry info: before: ${inquiryInfo.inquiryContent} to ${_formData.inquiryContents}`)
        return {
          ...inquiryInfo,
          inquiryContent: _formData.inquiryContents
        }
      })
    });
  };

  /* 취소 요청 */
  const [_inquiryCancle, setInquiryCancle] = useState([]);
  const actRequestCancel = async () => {
    // const info = getInquiryInfo(inquiryId);
    const confirmText = `
문의을 취소하시겠습니까?
관리자 승인 후 최종 취소 처리됩니다.
    `;
    if (window.confirm(confirmText) === true) {
      const BodyToPut = {
        companyId: inquiryInfo.companyId,
        userId: inquiryInfo.userId,

        inquiryId: inquiryInfo.inquiryId,
        inquiryCode: inquiryInfo.inquiryCode,
        status: 'requestCancel',
        inquiryContent: inquiryInfo.inquiryContent,
        // inquiryContent: info.inquiryContent,
        // inquiryReplyContent: checkEmptyNull(info.inquiryReplyContent),

        // inquiryReplyList: checkEmptyNull(info.inquiryReplyList),
        // deleteInquiryReplyList: [],
        // attachFileList: checkNullArray(info.attachFileList),
        deleteFileDataList: [],
      };

      const putFormData = new FormData();
      putFormData.append('key', new Blob([JSON.stringify(BodyToPut)], { type: 'application/json' }));
      for (let values of putFormData.values()) {
        console.log('putFormData : ', values);
      }

      const res = await inquiryApi.modifiedInquiry(inquiryId, putFormData).then((response) => {
        if (response === undefined) return;
        console.log('info.modifiedInquiry : ', response);
        alert('문의 취소를 요청 했습니다.');
        // props.update();
        // setInquiryCancle(() => {
        //   return _inquiryCancle.concat((cancleNum) => cancleNum.inquiryId !== inquiryId)
        // })
      });
    } else {
      return;
    }
  };

  /* ====================================================================== #6 */

  return (
    <InquiryStyle>
      <Grid4Body
        contents={
          <main className='inquiryInfoArea'>
            <NavBar
              title={`문의번호 : ${inquiryInfo.inquiryId} 번`}
              buttons={
                <>
                  <div className='inquiry-buttons'>
                    <Link to={'/inquiry'}>닫기</Link>
                  </div>
                </>
              }
              nav=''
            />
            <section>
              <h3>문의 상세 내용</h3>
              <ul className='info'>
                <li><p>등록일</p><span>{DateTimeFormatKR(inquiryInfo.createdDate)}</span></li>
                <li><p>수정일</p><span>{DateTimeFormatKR(inquiryInfo.modifiedDate)}</span></li>
                {/* <li><p>문의 수정일 : {DateTimeFormatKR(inquiryInfo.modifiedDate)}</p></li> */}
                {/* <li><p>문의 코드</p><span>{inquiryInfo.inquiryCode}</span></li> */}
                <li>
                  <p>문의 상태</p>
                  <span>{InquiryStatus(checkEmptyNull(inquiryInfo.status, 'wait'))}</span>
                  {InquiryStatus.status !== 'cancel'
                    ? <button onClick={actRequestCancel}>
                      문의 취소 요청
                    </button>
                    : null
                  }
                </li>
                <li>
                  <p>문의 내용</p>
                  {editContent ? (
                    <>
                      <span>{inquiryInfo.inquiryContent}</span>
                      <button onClick={editInquiryContent}>수정</button>
                    </>
                  )
                    : <>
                      <textarea
                        type="text" placeholder={inquiryInfo.inquiryContent}
                        name="inquiryContents" value={_formData.inquiryContents}
                        defaultValue={inquiryInfo.inquiryContent}
                        onInput={handleInputEvent}
                      />
                      <button className="qna-sub-submit" onClick={actUpdate}>저장</button>
                      <button onClick={editInquiryContent}>닫기</button>
                    </>
                  }
                </li>
              </ul>
              {/* <hr style={{margin: '40px 0'}}/> */}
              <h3>댓글</h3>
              <InquiryReplyList
                userId={inquiryInfo.userId}
                inquiryId={inquiryInfo.inquiryId}
                inquiryReplyId={inquiryInfo.inquiryReplyId}
                inquiryReplyContent={inquiryInfo.inquiryReplyContent}
                inquiryReplyList={inquiryInfo.inquiryReplyList}
              />
            </section>
          </main>
        }
      />
    </InquiryStyle>
  );
};