import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import * as s from './AlarmTemplate.styles?1';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { checkNullObject } from 'components/checkValues/checkValues';
import NavBar from 'components/nav/NavBar';
import OperatorBody from 'components/layouts/body/OperatorBody';
import TableSection from 'components/layouts/table/TableSection';

const AlarmTemplate = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const [searchId, setSearchId] = useState('');
  const [searchOptions, setSearchOptions] = useState({
    templateName: '',
    templateCode: '',
  });

  const [checkedItem, setCheckedItem] = useState({});
  const [newData, setNewData] = useState({
    templateName: '',
    templateCode: '',
    title: '',
  });
  const [editingData, setEditingData] = useState({});

  const fetchData = async () => {
    const apiUrl = '/api/alarmTemplate';
    try {
      const response = await axios.get(apiUrl);
      setData(response.data.content);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    fetchData();
  }, []);

  const handleCheckedItem = (data) => {
    setCheckedItem(() => { return data });
    setEditingData(() => { return data });
  }

  // 알람 생성
  const handleCreateAlarm = async (e) => {
    e.preventDefault();

    const apiUrl = '/api/alarmTemplate';
    try {
      await axios.post(apiUrl, newData);
      await fetchData();
      setNewData({ templateName: '', templateCode: '', title: '' });
    } catch (error) {
      console.error('Error creating data:', error);
    }
  };
  // 알람 수정
  const handleUpdateAlarm = async (e) => {
    e.preventDefault();

    const apiUrl = '/api/alarmTemplate';
    try {
      await axios.put(`${apiUrl}/${checkedItem.id}`, editingData);
      await fetchData();
      setEditingData({});
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };
  // 알람 삭제
  const handleDeleteAlarm = async (e) => {
    e.preventDefault();

    const apiUrl = '/api/alarmTemplate';
    try {
      await axios.delete(`${apiUrl}/${checkedItem.alarmId}`);
      await fetchData();
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };
  // 단일 검색
  const SingleSearch = async (e) => {
    e.preventDefault();

    const apiUrl = `/api/alarmTemplate/${searchId}`;
    try {
      const response = await axios.get(apiUrl);
      setData([response.data]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  // 조건 검색
  const ConditionalSearch = async (e) => {
    e.preventDefault();

    const apiUrl = '/api/alarmTemplate/search';
    try {
      const response = await axios.post(apiUrl, searchOptions);
      setData(response.data.content);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <OperatorBody contents={
      <s.Section className="Main">
        <NavBar
          title={'알람'}
          nav={'search'}
          firstRow={
            <div className='ContentCBox'>
              <input
                type="text"
                placeholder="템플릿 아이디"
                value={searchId}
                onChange={(e) => { setSearchId(e.target.value); }}
              />
              <button className='btn-search' onClick={SingleSearch}>단일 검색</button>
            </div>
          }
          secondRow={
            <div className='ContentCBox'>
              <input
                type="text"
                placeholder="템플릿 이름"
                value={searchOptions.templateName}
                onChange={(e) => setSearchOptions({ ...searchOptions, templateName: e.target.value })}
              />
              <input
                type="text"
                placeholder="템플릿 코드"
                value={searchOptions.templateCode}
                onChange={(e) => setSearchOptions({ ...searchOptions, templateCode: e.target.value })}
              />
              <button className='btn-search' onClick={ConditionalSearch}>조건 검색</button>
            </div>
          }
        />
        <section className="section-form">
          <div>
            <input
              type="text"
              placeholder="템플릿 이름"
              value={newData.templateName}
              onChange={(e) => setNewData({ ...newData, templateName: e.target.value })}
            />
            <input
              type="text"
              placeholder="템플릿 코드"
              value={newData.templateCode}
              onChange={(e) => setNewData({ ...newData, templateCode: e.target.value })}
            />
            <input
              type="text"
              placeholder="타이틀"
              value={newData.title}
              onChange={(e) => setNewData({ ...newData, title: e.target.value })}
            />
            <button className="btn-save create" onClick={handleCreateAlarm}>추가</button>
          </div>
          <div>
            {checkNullObject(checkedItem, false) ? (
              <>
                <input
                  type="text"
                  placeholder="템플릿 이름"
                  value={editingData.templateName}
                  onChange={(e) => setEditingData({ ...editingData, templateName: e.target.value })}
                />
                <input
                  type="text"
                  placeholder="템플릿 코드"
                  value={editingData.templateCode}
                  onChange={(e) => setEditingData({ ...editingData, templateCode: e.target.value })}
                />
                <input
                  type="text"
                  placeholder="타이틀"
                  value={editingData.title}
                  onChange={(e) => setEditingData({ ...editingData, title: e.target.value })}
                />
                <button className="btn-save update" onClick={handleUpdateAlarm}>저장</button>
                <button className="btn-save delete" onClick={handleDeleteAlarm}>삭제</button>
              </>
            ) : null}
          </div>
        </section>

        <TableSection content={
          <table>
            <colgroup>
              <col width={'50px'} />
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th></th>
                <th>탬블릿 ID</th>
                <th>탬블릿 이름</th>
                <th>탬블릿 코드</th>
                <th>타이틀</th>
              </tr>
            </thead>
            <tbody>
              {data.map((template, index) => {
                return (
                  <tr key={index + '_template'}>
                    <td>
                      <input
                        type="checkbox"
                        name="template"
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          if (isChecked) {
                            document.getElementsByName('template').forEach((checkbox) => { checkbox.checked = false });
                            e.target.checked = true;
                            handleCheckedItem(template);
                          } else {
                            e.target.checked = false;
                            handleCheckedItem({});
                          }
                        }}
                        onClick={(e) => { e.stopPropagation() }}
                      />
                    </td>
                    <td>{template.id}</td>
                    <td>{template.templateName}</td>
                    <td>{template.templateCode}</td>
                    <td>{template.title}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        } />
      </s.Section>
    }
    />
  );
};

export default AlarmTemplate;
