const initialState = {
  chatList: [],

  chatRoomOpen: 'close',
  chatRoomRead: 'read',

  chatInput: '',

  connectStatus: 'disconnect',
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETCHATLIST':
      return {
        ...state,
        chatList: [...state.chatList, action.payload],
      };

    case 'SETCHATROOMOPEN':
      return {
        ...state,
        chatRoomOpen: action.payload,
      };

    case 'SETCHATROOMREAD':
      return {
        ...state,
        chatRoomRead: action.payload,
      };

    case 'SETCHATINPUT':
      return {
        ...state,
        chatInput: action.payload,
      };

    case 'SETCHATCONNECTSTATUS':
      return {
        ...state,
        connectStatus: action.payload,
      };

    default:
      return state;
  }
};

export default chatReducer;
