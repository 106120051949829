
import React from 'react';
import { useSelector } from 'react-redux';

import { AiOutlineCluster } from "react-icons/ai";
import { BsBuildingsFill, BsPersonWorkspace } from "react-icons/bs";
import { FaRegCalendarAlt, FaUsers } from "react-icons/fa";
import { FaBasketShopping } from "react-icons/fa6";
import { MdOutlineWebAsset, MdQuestionAnswer, MdSensors, MdViewModule } from "react-icons/md";
import { RiAlarmWarningFill } from "react-icons/ri";

export const OperatorMenus = [
  { menuLink: '/operator/projectManagement', menuName: '프로젝트', icon: <FaRegCalendarAlt /> },

  { menuLink: '/operator/pageCode', menuName: '페이지코드', icon: <MdOutlineWebAsset /> },
  { menuLink: '/operator/module', menuName: '모듈', icon: <MdViewModule /> },

  { menuLink: '/operator/companyManagement', menuName: '회사', icon: <BsBuildingsFill /> },

  { menuLink: '/operator/user', menuName: '사용자', icon: <FaUsers /> },
  { menuLink: '/operator/hr', menuName: '근태', icon: <BsPersonWorkspace /> },

  { menuLink: '/operator/alarm', menuName: '알람', icon: <RiAlarmWarningFill /> },
  { menuLink: '/operator/sensor', menuName: '센서', icon: <MdSensors /> },

  { menuLink: '', menuName: '클러스터', icon: <AiOutlineCluster /> },
  { menuLink: '', menuName: '쇼핑몰', icon: <FaBasketShopping /> },
  // { menuLink: '/' + ClusterId() + '/platform/cluster/sign/login', menuName: '클러스터', icon: <AiOutlineCluster /> },
  // { menuLink: '/' + CompanyId() + '/mall/sign/login', menuName: '쇼핑몰', icon: <FaBasketShopping /> },

  // {menuLink: '', menuName: '', icon: <MdOutlineWebAsset />},

  { menuLink: '/operator/qna', menuName: '문의', icon: <MdQuestionAnswer /> },
];