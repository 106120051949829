import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';
import { productionPerformanceActions_setClickedCategory, productionPerformanceActions_setElementType, productionPerformanceActions_setEndDate, productionPerformanceActions_setPageNumber, productionPerformanceActions_setReset, productionPerformanceActions_setSearchData, productionPerformanceActions_setStartDate, productionPerformanceActions_setTotalSize } from 'store/modules/actions/common/productionPerformanceActions';

import { performanceApi } from 'api/apis/performanceApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import { LoadingMsg } from 'components/loading/LoadingMsg';
import Categories from 'components/category/Categories';
import Grid3Body from 'components/layouts/body/Grid3Body';
import GuideText from 'components/buttons/GuideText';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import ProductionPerformanceElements from 'pages/productionPerformance/modal/ProductionPerformanceElements';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const Section = styled.main`
  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    
    h4 {
      border-right: 1px solid #ddd;
      margin-right: 10px;
      padding-right: 20px;
      white-space: pre;
    }

    & .typeButtons {display: flex; gap: 5px;}
  }
  .TableSection {height: 100%;}
`;

const DetailsButton = styled.div`
  align-items: center;
  background-color: var(--Violet);
  border: 4px solid var(--white);
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 600;
  min-height: 100px;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const ProductionPerformance = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const { productionPerformanceReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_performanceElementList, setPerformanceElementList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_clickedCategory, setClickedCategory] = useState(checkEmptyNull(productionPerformanceReducer.clickedCategory, productionPerformanceReducer?.level1Categories[0]));

  const [_startDate, setStartDate] = useState(checkEmptyNull(productionPerformanceReducer.startDate, moment().startOf('month').format('YYYY-MM-DD')));
  const [_endDate, setEndDate] = useState(checkEmptyNull(productionPerformanceReducer.endDate, moment().format('YYYY-MM-DD')));

  const [_modalStatus, setModalStatus] = useState(false);
  const [_modalContent, setModalContent] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('407') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getElementList(productionPerformanceReducer.pageNumber - 1, productionPerformanceReducer.elementType);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getElementList(productionPerformanceReducer.pageNumber - 1, productionPerformanceReducer.elementType);
    }

    return () => { };
  }, [productionPerformanceReducer.pageNumber]);

  useEffect(() => {
    if (_onload === 'loaded' && Object.keys(productionPerformanceReducer.clickedCategory).length > 0) {
      setClickedCategory(() => {return productionPerformanceReducer.clickedCategory});
      dispatch(productionPerformanceActions_setPageNumber(1));
      getElementList(0, productionPerformanceReducer.elementType);
    }

    return () => { };
  }, [productionPerformanceReducer.clickedCategory]);

  useEffect(() => {
    if (_onload === 'loaded') {
      setStartDate(() => {return productionPerformanceReducer.startDate});
      setEndDate(() => {return productionPerformanceReducer.endDate});
    }
    return () => {}
  }, [productionPerformanceReducer.startDate, productionPerformanceReducer.endDate]);

  useEffect(() => { }, [productionPerformanceReducer]);

  /* ====================================================================== #4 */
  const getElementList = async (page, elementType, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (checkEmptyNull(elementType, false) && elementType !== 'all') {
      BodyToPost.elementType = elementType;
    }
    BodyToPost.categoryId = checkEmptyNull(productionPerformanceReducer.clickedCategory.categoryId, productionPerformanceReducer?.level1Categories[0]?.categoryId);
    if (!checkNullObject(productionPerformanceReducer.clickedCategory)) {
      dispatch(productionPerformanceActions_setClickedCategory(checkNullObject(productionPerformanceReducer?.level1Categories[0], {})));
    }

    // 날짜 기본값 설정
    BodyToPost.stockLogStartDate = DateFormat(checkEmptyNull(_startDate, moment().startOf('month').format('YYYY-MM-DD')));
    BodyToPost.stockLogEndDate = DateFormat(checkEmptyNull(_endDate, moment().format('YYYY-MM-DD')));
    if (_startDate > _endDate) {
      BodyToPost.stockLogStartDate = DateFormat(checkEmptyNull(_endDate, moment().format('YYYY-MM-DD')));
      BodyToPost.stockLogEndDate = DateFormat(checkEmptyNull(_startDate, moment().startOf('month').format('YYYY-MM-DD')));
    }
    if (!checkEmptyNull(_startDate, false) || !checkEmptyNull(_endDate, false)) {
      dispatch(productionPerformanceActions_setStartDate(DateFormat(checkEmptyNull(_startDate, moment().startOf('month').format('YYYY-MM-DD')))));
      dispatch(productionPerformanceActions_setEndDate(DateFormat(checkEmptyNull(_endDate, moment().format('YYYY-MM-DD')))));
    }
    if (!clear && checkEmptyNull(productionPerformanceReducer.searchData.elementName, false)) {
      BodyToPost.elementName = productionPerformanceReducer.searchData.elementName;
    }
    if (!clear && checkEmptyNull(productionPerformanceReducer.searchData.elementCode, false)) {
      BodyToPost.elementCode = productionPerformanceReducer.searchData.elementCode;
    }

    await performanceApi.searchPerformance(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('getElementList - response : ', response);
      setPerformanceElementList(() => { return response.data.content; });
      dispatch(productionPerformanceActions_setTotalSize(response.data.totalElements));
    })
  };

  /* ====================================================================== #5 */
  /* 검색 - 작업상태 */
  const handleSearchElementType = async (elementType) => {
    dispatch(productionPerformanceActions_setElementType(elementType));
    dispatch(productionPerformanceActions_setPageNumber(1));
    getElementList(0, elementType);
  }

  /* 검색 */
  const actSearch = async () => {
    dispatch(productionPerformanceActions_setPageNumber(1));
    await getElementList(0, productionPerformanceReducer.elementType);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(productionPerformanceActions_setReset());
    dispatch(productionPerformanceActions_setPageNumber(1));
    getElementList(0, productionPerformanceReducer.elementType, true);
  };

  /* 상세보기 */
  const showProductionPerformanceElements = (item) => {
    setModalContent(() => { return item; });
    setTimeout(setModalStatus(true), 1000);
  };

  /* ====================================================================== #6 */

  return (
    <Grid3Body contents={
      <>
        <Section className="Main">
          <NavBar
            title={
              <div className='Title'>
                <NavTitle menuCode={'407'} />
                <div className='typeButtons' style={{ display: 'flex' }}>
                  {userReducer.elementType.map((type, index) => {
                    let typeText = '';
                    switch (type) {
                      case 'all': break;
                      case 'material': typeText = '자재'; break;
                      case 'semi': typeText = '부자재'; break;
                      case 'half': typeText = '반제품'; break;
                      case 'product': typeText = '완제품'; break;
                      case 'tool': typeText = '공구'; break;
                      default: return null;
                    }
                    return (
                      <button
                        key={index + '_typeButtons'}
                        className={productionPerformanceReducer.elementType === type ? 'btn-inven-1' : 'btn-set'}
                        onClick={(e) => { e.preventDefault(); handleSearchElementType(type) }}
                      >
                        {typeText}
                      </button>
                    );
                  })}
                </div>
              </div>
            }
            buttons={
              <>
                <p>검색 기간: {DateFormat(_startDate)} - {DateFormat(_endDate)}</p>
              </>
            }
            nav={'all'}
            firstRow={
              <>
                <div className="SearchSection">
                  <Categories categoryType={'productionPerformance'} />
                </div>
              </>
            }
            secondRow={
              <div className="SearchSection">
                <div className="SearchSection">
                  <input
                    type="date"
                    className="SelectDate"
                    value={_startDate}
                    onChange={(e) => { dispatch(productionPerformanceActions_setStartDate(e.target.value)); }}
                  />

                  <IconRightArrow />

                  <input
                    type="date"
                    className="SelectDate"
                    value={_endDate}
                    onChange={(e) => { dispatch(productionPerformanceActions_setEndDate(e.target.value)); }}
                  />
                </div>

                <div className="ContentCBox">
                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="elementName">물품이름</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkEmptyNull(productionPerformanceReducer.searchData.elementName, '')}
                      onInput={(e) => {dispatch(productionPerformanceActions_setSearchData({ ...productionPerformanceReducer.searchData, elementName: e.target.value }));}}
                    />
                  </div>

                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="elementCode">물품코드</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkEmptyNull(productionPerformanceReducer.searchData.elementCode, '')}
                      onInput={(e) => {dispatch(productionPerformanceActions_setSearchData({ ...productionPerformanceReducer.searchData, elementCode: e.target.value }));}}
                    />
                  </div>

                  <div className="ContentCBox">
                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton" onClick={actReset}>초기화</div>
                  </div>
                </div>
              </div>
            }
          />

          <TableSection content={
            <table>
              <thead>
                <tr>
                  <th style={{ minWidth: '100px', padding: 'unset', width: '100px' }}></th>
                  <th>물품유형</th>
                  <th>물품이름</th>
                  <th>물품코드</th>
                  <th>총 투입량</th>
                  <th>목표 생산량</th>
                </tr>
              </thead>
              <tbody>
                {!checkNullArray(_performanceElementList, false) ? LoadingMsg() :
                  checkNullArray(_performanceElementList, []).map((thisItem) => {
                    return (
                      <tr key={thisItem.elementId + '_performanceElement'}>
                        <td style={{ minWidth: '100px', padding: 'unset', width: '100px' }}>
                          <DetailsButton onClick={() => { showProductionPerformanceElements(thisItem); }}>
                            상세보기
                            <GuideText />
                          </DetailsButton>
                        </td>
                        <td>
                          {thisItem.elementType === 'material' ? '자재'
                            : thisItem.elementType === 'semi' ? '부자재'
                              : thisItem.elementType === 'half' ? '반제품'
                                : thisItem.elementType === 'product' ? '완제품'
                                  : ''}
                        </td>
                        <td>{thisItem.elementName}</td>
                        <td>{thisItem.elementCode}</td>
                        <td>{thisItem.sumPut}</td>
                        <td>{thisItem.sumMake}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          }
          ></TableSection>
        </Section>

        <PagingComponent
          page={productionPerformanceReducer.pageNumber}
          count={productionPerformanceReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(productionPerformanceActions_setPageNumber(page));
          }}
        />

        {_modalStatus === true ? (
          <ProductionPerformanceElements
            eventElement={_modalContent}
            eventStartDate={DateFormat(productionPerformanceReducer.startDate)}
            eventEndDate={DateFormat(productionPerformanceReducer.endDate)}
            open={_modalStatus}
            close={() => { setModalStatus(false); }}
          ></ProductionPerformanceElements>
        ) : null}
      </>
    }
    />
  );
};

export default ProductionPerformance;
