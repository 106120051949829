import styled from 'styled-components';


export const Section = styled.main`
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: 60px calc(100% - 60px);
  overflow: hidden;

  .contents {
    box-sizing: border-box;
    display: grid;
    gap: 50px;
    height: 100%;
    overflow: auto;
    width: 100%;

    &.single {
      padding: 50px;
    }

    table {
      background-color: var(--white);
      border-collapse: collapse;
      height: 100%;
      table-layout: fixed;
      width: 100%;

      td,
      th {
        border: 1px solid var(--gray-200);
        font-size: 1.2em;
        padding: 8px;
        text-align: center;
      }
      th {
        background-color: var(--MainNavy);
        color: var(--white);

        &.current {
          background-color: var(--MainBlue);
        }
        &.set {
          background-color: var(--gray-500);
        }
      }
      td {
        &.ON {
          color: var(--SeconGreen);
          font-weight: 600;
        }
        &.OFF {
          color: var(--SeconRed);
          font-weight: 600;
        }
        &.windVolume {
          color: var(--LightBlue);
          font-weight: 600;
        }

        font-weight: 600;

        span {
          font-size: 0.7em;
          font-weight: 300;
        }
      }
    }
    .mg {
      & th {
        font-size: 1.8rem;
      }
      & td {
        font-size: 2.3rem;
        span {
          font-size: 2rem;
        }
      }
    }
  }
`;

export const Sensor_group = styled.div`
  background-color: var(--white);
  border-radius: 20px;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 100px calc(100% - 100px);
  height: 100%;
  margin: auto;
  max-width: 1200px;
  max-height: 800px;
  overflow: hidden;
  width: 100%;

  .sensor-header {
    align-items: center;
    background-color: var(--MainNavy);
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr auto auto;
    color: var(--white);
    gap: 12px;
    justify-content: space-between;
    padding: 20px 40px;

    h1 {
      font-size: 2em;
      width: fit-content;
    }

    h3 {
      font-size: 1.6em;
      width: fit-content;
    }

    div {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      gap: 20px;
      height: 60px;
      justify-content: flex-start;
      outline: 1px solid var(--gray-200);
      outline-offset: 2px;
      padding-inline: 30px;
      width: fit-content;

      &.power {
        p {
          font-size: 2em;
          font-weight: 600;
          &.ON {
            color: var(--SeconGreen);
          }
          &.OFF {
            color: var(--SeconRed);
          }
        }
      }

      &.windVolume {
        p {
          color: var(--LightBlue);
          font-size: 2em;
          font-weight: 600;
        }
      }
    }
  }

  .sensor-content {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    height: 100%;
    overflow: hidden;
    justify-content: center;
    padding: 50px;
    width: 100%;

    .sensor-data {
      border: 1px solid var(--gray-200);
      border-radius: 20px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 100px 1fr 1fr;
      height: 100%;
      overflow: hidden;
      justify-content: center;
      width: 100%;

      .data-header {
        align-items: center;
        background-color: var(--MainNavy);
        box-sizing: border-box;
        display: flex;
        height: 100px;
        justify-content: space-between;
        padding: 30px;
        width: 100%;

        h3 {
          align-items: center;
          color: var(--white);
          display: flex;
          font-size: 1.6em;
          justify-content: center;
        }

        button {
          height: 40px;
          width: 80px;
          &:hover {
            background-color: #eaebed;
          }
        }
      }

      .sensor {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: flex-start;
        width: 100%;

        h4 {
          align-items: center;
          background-color: var(--gray-500);
          border-right: 1px solid var(--gray-200);
          box-sizing: border-box;
          color: var(--white);
          display: flex;
          font-size: 1.4em;
          height: 100%;
          justify-content: center;
          width: 100px;
        }

        p {
          align-items: baseline;
          display: flex;
          font-size: 3em;
          font-weight: 600;
          gap: 10px;
          justify-content: center;
          width: calc(100% - 100px);
        }

        span {
          font-size: 0.6em;
          font-weight: 300;
        }

        &.current {
          border-bottom: 1px solid var(--gray-200);
          h4 {
            background-color: var(--MainBlue);
          }
        }
      }
    }
  }

  &.mg {
    .sensor-content {
      .sensor-data {
        grid-template-rows: 0.5fr 1fr;
        .data-header {
          height: 100%;
          justify-content: center;
          font-size: 1.5rem;
        }
        .sensor {
          &.current {
            h4 {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
`