import React from 'react';

const Information = ({ dataKeys, data }) => {
  return (
    <div className='section-form'>
      {dataKeys.map((dataKey, index) => {
        return (
          <div key={index + '_form-content'} className='form-content term'>
            <h4>{dataKey.title}</h4>
            {dataKey.key === 'type' ? ( // 거래 유형
              <div>
                <p className='date'>{data[dataKey.key] === 'buy' ? '구매' : '판매' || ''}</p>
              </div>
            ) : dataKey.key === 'targetDate' ? ( // 마감일
              <div>
                <p className='date'>{data[dataKey.key] || ''}</p>
              </div>
            ) : dataKey.key === 'price' ? (<>
              <input
                type='text'
                readOnly
                value={data[dataKey.key]?.toLocaleString() || ''}
              />
            </>) : dataKey.key === 'detail' ? (<>
              <textarea
                readOnly
                defaultValue={data[dataKey.key] || ''}
              />
            </>) : (<>
              <input
                type='text'
                readOnly
                value={data[dataKey.key] || ''}
              />
            </>)}
          </div>
        );
      })}
    </div>
  );
};

export default Information;