const initialState = {
  elementType: 'material',

  startDate: '',
  endDate: '',

  searchData: {
    elementName: '',
    elementCode: '',
  },
  searchAttrs: {},

  clickedCategory: {},
  clickedLevel1Category: {},
  clickedLevel2Category: {},
  clickedLevel3Category: {},
  clickedLevel4Category: {},
  clickedLevel5Category: {},

  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,

  element: {},
  elements: [],

  level1Categories: [],
  level2Categories: [],
  level3Categories: [],
  level4Categories: [],
  level5Categories: [],
};

const resetState = {
  elementType: 'material',

  startDate: '',
  endDate: '',

  searchData: {
    elementName: '',
    elementCode: '',
  },
  searchAttrs: {},

  clickedCategory: {},
  clickedLevel1Category: {},
  clickedLevel2Category: {},
  clickedLevel3Category: {},
  clickedLevel4Category: {},
  clickedLevel5Category: {},

  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const stockHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETSTOCKHISTORYTYPE':
      return {
        ...state,
        elementType: action.payload,
      };
    case 'SETSTOCKHISTORYSTARTDATE':
      return {
        ...state,
        startDate: action.payload,
      };
    case 'SETSTOCKHISTORYENDDATE':
      return {
        ...state,
        endDate: action.payload,
      };
    case 'SETSTOCKHISTORYSEARCHDATA':
      return {
        ...state,
        searchData: action.payload,
      };
    case 'SETSTOCKHISTORYSEARCHATTS':
      return {
        ...state,
        searchAttrs: action.payload,
      };

    case 'SETSTOCKHISTORYCLICKEDCATEGORY':
      return {
        ...state,
        clickedCategory: action.payload,
      };
    case 'SETSTOCKHISTORYCLICKEDLEVEL1CATEGORY':
      return {
        ...state,
        clickedLevel1Category: action.payload,
      };
    case 'SETSTOCKHISTORYCLICKEDLEVEL2CATEGORY':
      return {
        ...state,
        clickedLevel2Category: action.payload,
      };
    case 'SETSTOCKHISTORYCLICKEDLEVEL3CATEGORY':
      return {
        ...state,
        clickedLevel3Category: action.payload,
      };
    case 'SETSTOCKHISTORYCLICKEDLEVEL4CATEGORY':
      return {
        ...state,
        clickedLevel4Category: action.payload,
      };
    case 'SETSTOCKHISTORYCLICKEDLEVEL5CATEGORY':
      return {
        ...state,
        clickedLevel5Category: action.payload,
      };

    case 'SETSTOCKHISTORYPAGENUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'SETSTOCKHISTORYTOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };
    case 'SETSTOCKHISTORYISPUSHEDSEARCHBUTTON':
      return {
        ...state,
        isPushedSearchButton: action.payload,
      };

    case 'SETSTOCKHISTORYRESET':
      return {
        ...state,
        ...resetState,
      };

    case 'SELECTONESTOCKHISTORY':
      return {
        ...state,
        element: action.payload,
      };

    case 'SELECTSTOCKHISTORYS':
      return {
        ...state,
        elements: action.payload,
      };

    case 'SETSTOCKHISTORYLEVEL1CATEGORIES':
      return {
        ...state,
        level1Categories: action.payload,
      };
    case 'SETSTOCKHISTORYLEVEL2CATEGORIES':
      return {
        ...state,
        level2Categories: action.payload,
      };
    case 'SETSTOCKHISTORYLEVEL3CATEGORIES':
      return {
        ...state,
        level3Categories: action.payload,
      };
    case 'SETSTOCKHISTORYLEVEL4CATEGORIES':
      return {
        ...state,
        level4Categories: action.payload,
      };
    case 'SETSTOCKHISTORYLEVEL5CATEGORIES':
      return {
        ...state,
        level5Categories: action.payload,
      };

    default:
      return state;
  }
};

export default stockHistoryReducer;
