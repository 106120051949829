export const inquiryActions_setInquiryStatus = (status) => {
  return {
    type: 'SETINQUIRYSTATUS',
    payload: status,
  };
};
export const inquiryActions_setStartDate = (date) => {
  return {
    type: 'SETINQUIRYSTARTDATE',
    payload: date,
  };
};
export const inquiryActions_setEndDate = (date) => {
  return {
    type: 'SETINQUIRYENDDATE',
    payload: date,
  };
};
export const inquiryActions_setSearchOption = (searchOption) => {
  return {
    type: 'SETINQUIRYSEARCHOPTION',
    payload: searchOption,
  };
};
export const inquiryActions_setSearchText = (searchText) => {
  return {
    type: 'SETINQUIRYSEARCHTEXT',
    payload: searchText,
  };
};
export const inquiryActions_setPageNumber = (pageNumber) => {
  return {
    type: 'SETINQUIRYPAGENUMBER',
    payload: pageNumber,
  };
};
export const inquiryActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETINQUIRYTOTALSIZE',
    payload: totalSize,
  };
};
export const inquiryActions_setIsPushedSearchButton = (
  isPushedSearchButton,
) => {
  return {
    type: 'SETINQUIRYISPUSHEDSEARCHBUTTON',
    payload: isPushedSearchButton,
  };
};
export const inquiryActions_setReset = () => {
  return {
    type: 'SETINQUIRYRESET',
  };
};
