import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { DateFormat } from 'components/format/DateFormat';
import { checkEmptyNull, checkNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

import DOCButtonIcon from 'components/icons/DOCButtonIcon';
import GuideText from 'components/buttons/GuideText';
import StatusTdButton from 'components/buttons/StatusTdButton';

const QualityButton = styled.td`
  background-color: var(--Violet);
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  flex-direction: column;
  font-size: 20px;
  font-weight: 600;
  min-height: 100px;
  height: 100%;
  width: 100%;
`;

const QualityRow = (props) => {
  /* ========================================================================= #1 */
  const { userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_qualityData, setQualityData] = useState(props.content);
  const [_qualityTargetProduct, setQualityTargetProduct] = useState(checkNullArray(props.content.qualityElementList, []));
  const [_rowLength, setRowLength] = useState(_qualityTargetProduct.length);

  /* ========================================================================= #3 */
  useEffect(() => {
    setQualityData(() => { return props.content });

    const QualityTargetProduct = checkNullArray(props.content.qualityElementList, []);
    setQualityTargetProduct(() => { return QualityTargetProduct });
    setRowLength(() => { return QualityTargetProduct.length });
  }, [props]);

  /* ========================================================================= #4 */
  /* ========================================================================= #5 */
  /* ========================================================================= #6 */
  const returnItems = () => {
    let returnArray = [];
    for (let i = 1; i < _rowLength; i++) {
      returnArray.push(
        <tr key={props.index + '_' + i + '_qualityTargetProduct'}>
          {_qualityTargetProduct[i] ? (
            <Fragment>
              <td>{checkEmptyNull(_qualityTargetProduct[i].elementName, '')}<br />{checkEmptyNull(_qualityTargetProduct[i].elementCode, '')}</td>
              <td>{checkEmptyNull(_qualityTargetProduct[i].baseAmount, 0).toLocaleString()}</td>
              <td>{checkEmptyNull(BigNumber(checkEmptyNull(_qualityTargetProduct[i].makeAmount, 0)).plus(BigNumber(checkEmptyNull(_qualityTargetProduct[i].failedAmount, 0))).toNumber(), 0).toLocaleString()}</td>
              <td>{checkEmptyNull(checkEmptyNull(_qualityTargetProduct[i].makeAmount, 0).toLocaleString())}</td>
              <td>{checkEmptyNull(checkEmptyNull(_qualityTargetProduct[i].failedAmount, 0).toLocaleString())}</td>
            </Fragment>
          ) : (
            <Fragment>
              <td></td><td></td><td></td><td></td><td></td>
            </Fragment>
          )}
        </tr>,
      );
      return returnArray;
    }
  };

  return (
    <Fragment>
      <tr data-key={_qualityData.workOrderLogId}>
        <QualityButton
          rowSpan={_rowLength === 0 ? 1 : _rowLength}
          style={{ minWidth: '100px', padding: 'unset', width: '100px' }}
          onClick={() => { props.showProductionProcessTracking(_qualityData.workOrderLogId) }}
        >
          생산추적
          <GuideText />
        </QualityButton>
        <StatusTdButton
          rowSpan={_rowLength === 0 ? 1 : _rowLength}
          statusStyle={{ cursor: 'default', minHeight: '100px', minWidth: '80px', width: '80px' }}
          content={_qualityData}
          contentId={_qualityData.workOrderLogId}
          status={_qualityData.workOrderStatus}
        />
        {(props.authority.indexOf('109-1') !== -1 || props.authority.indexOf('109-2') !== -1) ? (
          <>
            {(checkNullArray(_qualityData.attachFileList, false)) ? (
              <td rowSpan={_rowLength === 0 ? 1 : _rowLength} className='table-preview'>
                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.showFileList(_qualityData, true);
                  }}
                >
                  <img src={_qualityData.attachFileList[0]?.fileData?.fileDataS3URL || ''} alt='image preview' className='img-preview' />
                  <div className='btn-file'><DOCButtonIcon /></div>
                </div>
              </td>
            ) : (
              <td rowSpan={_rowLength === 0 ? 1 : _rowLength} className='table-preview'>
                <div
                  style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.showFileList(_qualityData, true);
                  }}
                >
                  <div className='no-image'><p>이미지가 없습니다.</p></div>
                  <div className='btn-file'><DOCButtonIcon /></div>
                </div>
              </td>
            )}
          </>
        ) : null}
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{_qualityData.workOrderTypeName}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{_qualityData.workOrderCode}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{_qualityData.workOrderName}</td>
        {(props.authority.indexOf('104-1') !== -1 || props.authority.indexOf('104-2') !== -1) ? (
          <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{checkNull(_qualityData.accountName, '')}</td>
        ) : null}
        {_rowLength > 0 && checkNullObject(_qualityTargetProduct[0], false) ? (
          <Fragment>
            <td>{checkEmptyNull(_qualityTargetProduct[0].elementName, '')}<br />{checkEmptyNull(_qualityTargetProduct[0].elementCode, '')}</td>
            <td>{checkEmptyNull(_qualityTargetProduct[0].baseAmount, 0).toLocaleString()}</td>
            <td>{checkEmptyNull(BigNumber(checkEmptyNull(_qualityTargetProduct[0].makeAmount, 0)).plus(BigNumber(checkEmptyNull(_qualityTargetProduct[0].failedAmount, 0))).toNumber(), 0).toLocaleString()}</td>
            <td>{checkEmptyNull(checkEmptyNull(_qualityTargetProduct[0].makeAmount, 0).toLocaleString())}</td>
            <td>{checkEmptyNull(checkEmptyNull(_qualityTargetProduct[0].failedAmount, 0).toLocaleString())}</td>
          </Fragment>
        ) : (
          <Fragment>
            <td></td><td></td><td></td><td></td><td></td>
          </Fragment>
        )}

        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{_qualityData.place?.placeName}<br />{_qualityData.workPlace}</td>
        {![7].includes(userReducer.company.companyId) ? (
          <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{DateFormat(checkEmptyNull(_qualityData.workOrderActualStartDate, ''))}<br /> ~ {DateFormat(checkEmptyNull(_qualityData.workOrderActualEndDate, ''))}</td>
        ) : null}
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{_qualityData.userName}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{checkNullArray(_qualityData.managers, []).map((worker, index) => { return (<p key={props.index + '_' + index + '_managers'}>{worker.managerName}</p>); })}</td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>{checkNullArray(_qualityData.workers, []).map((worker, index) => { return (<p key={props.index + '_' + index + '_workers'}>{worker.workerName}</p>); })}</td>
      </tr>
      {_rowLength > 1 && returnItems()}
    </Fragment>
  );
};

export default QualityRow;
