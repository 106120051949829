const initialState = {
  processType: 'all', // 공정유형
  processingType: 'all', // 처리유형 (공정완료제품 개수 추후 처리)

  searchData: {},
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,

  viewScale: 100,

  bookmark: true,
};
const resetState = {
  processType: 'all', // 공정유형
  processingType: 'all', // 처리유형 (공정완료제품 개수 추후 처리)

  searchData: {},
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const processReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETPROCESSTYPE':
      return {
        ...state,
        processType: action.payload,
      };
    case 'SETPROCESSINGTYPE':
      return {
        ...state,
        processingType: action.payload,
      };
    case 'SETPROCESSSEARCHDATA':
      return {
        ...state,
        searchData: action.payload,
      };
    case 'SETPROCESSSEARCHOPTION':
      return {
        ...state,
        searchOption: action.payload,
      };
    case 'SETPROCESSSEARCHTEXT':
      return {
        ...state,
        searchText: action.payload,
      };
    case 'SETPROCESSPAGENUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'SETPROCESSTOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };
    case 'SETPROCESSISPUSHEDSEARCHBUTTON':
      return {
        ...state,
        isPushedSearchButton: action.payload,
      };

    case 'SETPROCESSRESET':
      return {
        ...state,
        ...resetState,
      };

    case 'SETPROCESSVIEWSCALE':
      return {
        ...state,
        viewScale: action.payload,
      };
    case 'SETPROCESSBOOKMARK':
      return {
        ...state,
        bookmark: action.payload,
      };

    default:
      return state;
  }
};

export default processReducer;
