import React from 'react';

import StlFile from 'pages/file/assets/StlFile';

const StlContent = ({
  modalData
}) => {
  return (
    <section className='stl-content'>
      <p>※ 화면이 안 보일 경우, [ ctrl + 마우스 휠 ] 조정을 해주세요.</p>
      <StlFile url={modalData?.fileDataS3URL} />
    </section>
  );
};

export default StlContent;