import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { modalFileActions_setFileReset } from 'store/modules/actions/modal/modalFileActions';

import { attachFileApi } from 'api/apis/attachFileApi';
import { element } from 'api/apis/mall/element';

import { checkEmptyNull, checkNullObject } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import MallBody from 'components/layouts/body/MallBody';
import NavBar from 'components/nav/NavBar';
import SelectFiles from 'pages/file/modal/SelectFiles';
import SelectOneElement from 'pages/element/modal/SelectOneElement';
import SelectMallCategory from 'pages/mallManagement/mallCategory/modal/SelectMallCategory';

const ElementTable = styled.table`
  background-color: var(--white);
  font-size: 0.9em;
  max-width: 500px;
  table-layout: fixed;
  width: 100%;
  td, th {height: 35px; padding: 2px 10px;}
  th {background-color: var(--MainNavy); color: var(--white);}
`;
const FileList = styled.div`
  width: 100%;
`;
const FileBox = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  height: 40px;
  justify-content: flex-start;
  margin: 5px auto;
  padding: 0px 15px;
  position: relative;
  width: 100%;
`;
const DeleteFileButton = styled.div`
  height: 15px;
  position: absolute;
  right: 15px;
  width: 15px;
  div {background-color: var(--white); cursor: pointer; height: 15px; width: 15px;}
`;

const CreateMallElement = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    mallCategoryId: 0,

    elementId: undefined,

    mallElementCode: '',
    mallElementName: '',

    mallElementPrice: '',
    mallElementSize: '',
    mallElementContent: '',
  }); // 판매 물품정보

  const [_modalStatus, setModalStatus] = useState(false);
  const [_element, setElement] = useState({}); // 물품정보

  const [_categoryModalStatus, setCategoryModalStatus] = useState(false);
  const [_category, setCategory] = useState({}); // 카테고리

  const [_fileModalStatus, setFileModalStatus] = useState(false);
  const [_fileList, setFileList] = useState([]); // 파일 리스트

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('109') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    setOnload('loaded');
    return () => { };
  }, []);

  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'mallElementPrice':
        let setMallElementPrice = value;
        if (!setMallElementPrice.toString().startsWith('0.')) setMallElementPrice = setMallElementPrice.toString().replace(/^0+/, '');
        if (setMallElementPrice === '' || setMallElementPrice < 0) setMallElementPrice = 0;
        if (setMallElementPrice.length > 15) return;
        console.log('setMallElementPrice : ', setMallElementPrice);
        return setFormData((prev) => { return { ...prev, mallElementPrice: setMallElementPrice }; });

      default: break;
    }
    setFormData((prev) => { return { ...prev, [name]: value }; });
  };
  const handleCategory = (category) => {
    const { clickedLevel1Category, clickedLevel2Category, clickedLevel3Category } = category;
    let returnData = '';
    if (checkEmptyNull(clickedLevel1Category?.mallCategoryName, false)) returnData += clickedLevel1Category?.mallCategoryName;
    if (checkEmptyNull(clickedLevel2Category?.mallCategoryName, false)) returnData += ' >> ' + clickedLevel2Category?.mallCategoryName;
    if (checkEmptyNull(clickedLevel3Category?.mallCategoryName, false)) returnData += ' >> ' + clickedLevel3Category?.mallCategoryName;
    return returnData;
  }
  const actDeleteFile = (e, index, file) => {
    setFileList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);
      return prevData;
    });
  };

  /* 추가 */
  const actCreate = async (e) => {
    e.preventDefault();

    if (!checkEmptyNull(_element.elementId, false)) return alert('물품을 선택해주세요.');
    if (!checkEmptyNull(_formData.mallElementName, false)) return alert('판매 물품이름을 입력해주세요.');
    if (!checkEmptyNull(_formData.mallElementCode, false)) return alert('판매 물품코드를 입력해주세요.');

    const BodyToPost = {
      ..._formData,
      elementId: _element.elementId,
      mallElementPrice: checkEmptyNull(_formData.mallElementPrice, 0)
    };
    if (checkNullObject(_category.clickedLevel3Category, false)) {
      BodyToPost.mallCategoryId = _category.clickedLevel3Category.mallCategoryId;
    } else {
      if (checkNullObject(_category.clickedLevel2Category, false)) {
        BodyToPost.mallCategoryId = _category.clickedLevel2Category.mallCategoryId;
      } else {
        if (checkNullObject(_category.clickedLevel1Category, false)) {
          BodyToPost.mallCategoryId = _category.clickedLevel1Category.mallCategoryId;
        }
      }
    }
    if (!checkEmptyNull(BodyToPost.mallCategoryId, false) || BodyToPost.mallCategoryId === 0) return alert('카테고리를 선택해주세요.')

    await element.createMallElement(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('element.createMallElement : ', response);
      if (_fileList.length > 0) {
        _fileList.forEach(async (file) => {
          const BodyToFilePost = {
            companyId: userReducer.company.companyId,
            fileDataId: file.fileDataId,
            mallElementId: response.data.mallElementId,
          };
          await attachFileApi.createAttachFile(BodyToFilePost).then((response) => {
            if (response === undefined) return;
            console.log('attachFileApi.createAttachFile : ', response);
          });
        });
      }
      alert('판매 물품을 추가했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  };

  return (
    <MallBody contents={
      <>
        <main className="Main">
          <NavBar title={'판매 물품정보'} nav={''} />

          <Form
            title={<>판매<br />물품정보<br />추가</>}
            buttons={
              <>
                <button className="formButton" onClick={actCreate}>저장</button>
                <button className="formButton cancle"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(pageReducer.currentPage, { replace: true });
                  }}
                >
                  취소
                </button>
              </>
            }
            forms={
              <>
                <div className="formBox w100">
                  <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                    <h4>물품</h4>
                    <div className="formButton" style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                      onClick={(e) => { e.preventDefault(); setModalStatus(true); }}
                    >
                      물품 선택
                    </div>
                  </div>

                  <ElementTable>
                    <thead>
                      <tr><th>물품이름</th><th>물품코드</th></tr>
                    </thead>
                    <tbody>
                      <tr><td>{checkEmptyNull(_element.elementName, '')}</td><td>{checkEmptyNull(_element.elementCode, '')}</td></tr>
                    </tbody>
                  </ElementTable>
                </div>

                <div className='formBox'>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <h4>패밀리</h4>
                    <div className="formButton" style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                      onClick={() => { setCategoryModalStatus(true) }}
                    >
                      패밀리 선택
                    </div>
                  </div>
                  <p>{handleCategory(_category)}</p>
                </div>

                <div className="formBox">
                  <h4>판매 물품이름</h4>
                  <input
                    type="text"
                    name="mallElementName"
                    data-required="required"
                    placeholder="* 판매 물품이름..."
                    value={_formData.mallElementName}
                    onInput={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>판매 물품코드</h4>
                  <input
                    type="text"
                    name="mallElementCode"
                    data-required="required"
                    placeholder="* 판매 물품코드..."
                    value={_formData.mallElementCode}
                    onInput={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>가격</h4>
                  <input
                    type="number"
                    name="mallElementPrice"
                    min={0}
                    value={checkEmptyNull(_formData.mallElementPrice, 0)}
                    onInput={handleInputEvent}
                    placeholder="가격..."
                  />
                </div>

                <div className="formBox">
                  <h4>규격</h4>
                  <textarea
                    type="text"
                    name="mallElementSize"
                    placeholder="* 규격..."
                    value={_formData.mallElementSize}
                    onInput={handleInputEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>설명</h4>
                  <textarea
                    type="text"
                    name="mallElementContent"
                    placeholder="* 설명..."
                    value={_formData.mallElementContent}
                    onInput={handleInputEvent}
                  />
                </div>

                {(() => {
                  if (_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) {
                    return (
                      <div className="formBox">
                        <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                          <h4>파일</h4>
                          <div
                            className="formButton"
                            style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(modalFileActions_setFileReset());
                              setTimeout(setFileModalStatus(true), 1000);
                            }}
                          >
                            파일 선택
                          </div>
                        </div>

                        <FileList>
                          {_fileList.map((thisItem, index) => {
                            return (
                              <FileBox key={index + '_files'}>
                                {thisItem.fileDataName}
                                <DeleteFileButton onClick={(e) => { actDeleteFile(e, index, thisItem); }}><CloseButtonIcon /></DeleteFileButton>
                              </FileBox>
                            );
                          })}
                        </FileList>
                      </div>
                    );
                  } else return null;
                })()}
              </>
            }
          />
        </main>

        {_modalStatus === true && (
          <SelectOneElement
            buttonEvent={async (data) => {
              console.log('data : ', data);
              setElement(() => { return data; });
              setTimeout(setModalStatus(false), 1000);
            }}
            buttonTitle={`물품 선택`}
            open={_modalStatus}
            close={() => { setModalStatus(false); }}
          />
        )}

        {_categoryModalStatus === true && (
          <SelectMallCategory
            category={(data) => {
              setCategory(() => { return data });
              setTimeout(setCategoryModalStatus(false), 1000);
            }}
            open={_categoryModalStatus}
            close={() => { setCategoryModalStatus(false) }}
          />
        )}

        {_fileModalStatus === true && (
          <SelectFiles
            buttonTitle={'파일 선택'}
            buttonEvent={(data) => {
              const newDataList = data.filter((thisItem) => _fileList.findIndex((thisIndex) => thisIndex.fileDataId === thisItem.fileDataId) === -1);
              setFileList((prev) => { return [...prev, ...newDataList]; });
              setTimeout(setFileModalStatus(false), 1000);
            }}
            open={_fileModalStatus}
            close={() => { setFileModalStatus(false); }}
          />
        )}
      </>
    }
    />
  );
};

export default CreateMallElement;
