import moment from 'moment';

export const DateTimeFormatKR = (eventDate) => {
  if (
    eventDate !== undefined &&
    eventDate !== null &&
    typeof eventDate === 'object'
  ) {
    const setDate =
      eventDate.length === 3
        ? eventDate.join('-')
        : eventDate.length > 6
        ? `${eventDate[0]}-${eventDate[1]}-${eventDate[2]} ${eventDate[3]}:${eventDate[4]}:${eventDate[5]}`
        : eventDate;
    const newDate = new Date(setDate);
    const returnDate = moment(newDate).format('MM월DD일 HH:mm');

    return returnDate;
  } else {
    const newDate = new Date(eventDate);
    const returnDate = moment(newDate).format('MM월DD일 HH:mm');
    if (returnDate === 'Invalid date') {
      return eventDate;
    }

    return returnDate;
  }
};
