import Grid4Body from 'components/layouts/body/Grid4Body';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { operatorActions_setQnA } from 'store/modules/actions/operator/operatorActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { auth } from 'api/apis/auth/auth';
import { inquiryApi } from 'api/apis/inquiryApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateTimeFormatKR } from 'components/format/DateTimeFormatKR';
import { downArrow } from 'components/icons/src';
import { InquiryStatus } from 'pages/inquiry/components/status';

import InquiryPaging from 'pages/inquiry/components/paging';
import MasterInquiryInfo from 'pages/operator/inquiry/MasterInquiryInfo';
import NavBar from 'components/nav/NavBar';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';

const QnaStyle = styled.div`
  .qnaFormBtn {
    background: var(--MainBlue);
    /* background: var(--color-2); */
    border-radius: 8px;
    color: var(--white);
    display: flex;
    gap: 8px;
    padding: 4px 16px;
    position: relative;
  }

  h3 {margin: 40px 0;font-size:1.5rem; width:1200px;}

  .qna-search {
    display: flex;  gap: 8px; justify-content: space-between;

    &.master select {
      background-color: var(--color-1);
      color: var(--white);
    }
  }

  .qna-status{
    display: grid;
    /* position: relative; */

    li {
      background-color: #fff;
      border: 1px solid var(--gray-200);
      box-sizing: border-box;
      display: flex; flex-wrap: wrap;
      font-size: 1.25rem;
      line-height: 100%;
      padding: 8px 16px;
      text-align: center;
      vertical-align: middle;
      word-break: break-word;

      p {
        width: 20%;
        &:nth-of-type(2){
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-all;
        }
      }

      &:nth-of-type(1){
        cursor: auto;
        &:hover{opacity:1;}
      }

      .qna-li {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;

        /* p {&:nth-last-of-type(1) {}} */

        &> p.first, &> p.first + p {font-weight: 600; width: 80px;} /* 문의번호, 진행상태 */
        &> p.goDetaile {font-weight: 600; width: 120px;} /* 상세보기 */
        &> p:nth-child(4) {text-align: left;}
        &> p:nth-child(5) {width: 150px;}
      }

      .qna-sub {
        &-list {
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          font-size: 1rem;
          gap: 8px;
          padding: 20px 40px;
          width: 100%;

          li{
            border: none;
            border: 1px solid #eee;
            border-radius: 6px;
            cursor: auto;
            padding: 0%;
            padding: 8px;

            &:nth-of-type(1){
              font-weight: inherit;
              margin: inherit; 
              position: relative;

              &::before {
                content: '문의 상품'; position: absolute; top: -30px; left: 10px;
                width: 100%; height: 100%;
              }
            }

            &:hover {color: var(--MainRed);}

            p{cursor: auto; text-align: left; width: 20%;}
          }
        }

        &-info {
          border-radius: 10px;
          font-size: 1rem;
          margin-bottom: 24px;
          padding: 30px 40px;
          width: 100%;

          p {
            display: flex; 
            gap: 24px;
            width: 100%; 
            word-break: keep-all;
            
            span {height:fit-content;}
            textarea {height: 122px; padding: 8px; resize: none; width: 100%;}
          }

          label, div {
            display: flex; 
            gap: 16px;

            .list-file {
              border: 1px solid;
              border-radius: 2px;
              cursor: pointer;
              display: flex;
              justify-content: space-around;
              padding: 8px 16px;
              width: 100%;
            }
          }

          &>div {
            width: 100%;

            &>div {
              display: flex;
              flex-direction: column;
              max-width: inherit;

              .btn {
                align-items: center;
                background: unset;
                background-color: var(--MainNavy);
                border: unset;
                border-radius: 16px;
                color: var(--white);
                cursor: pointer;
                display: flex;
                font-size: 15px;
                font-weight: 600;
                height: 40px;
                justify-content: center;
              }
            }

            p{&:nth-of-type(2){width: 100%;}}
          }
        }

        &-submit {
          background: var(--MainNavy);
          border-radius: 10px;
          font-size: 1.25rem;
          color: var(--white);
          padding: 24px;
          width: fit-content;
        }
      }

      .toggle{
        background-color: var(--gray-800);
        height: 20px;
        margin: 0 50px;
        transform: rotate(180deg);
        width: 20px;

        mask-image: url(${downArrow}); mask-repeat: no-repeat; mask-size: contain;
        --webkit-mask-image: url(${downArrow}); --webkit-mask-repeat: no-repeat; --webkit-mask-size: contain;

        &[data-status='open'] {}
      }
    }

    .qna-status-title {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      position: sticky;
      top: 0px;

      .qna-li {
        p {background: #fff !important; color: #000 !important; font-weight: 600; text-align: center !important;}
      }
    }
  }

  .qna-form {
    align-items: center;
    background: #eee;
    display: flex;
    flex-direction: column;
    height: 94%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 60px;
    width: 100%;
    z-index: 99;

    & .qna-form-header {
      position: relative;

      h3{width:inherit;}

      .closeBtn {
        background: #ddd;
        border-radius: 8px;
        color: #333;
        cursor: pointer;
        font-size: 1rem;
        font-weight: bold;
        height: auto;
        padding: 8px 16px;
        position: absolute;
        top: 0px;
        right: 0px;
        width: auto;
        z-index: 9999;
      }
    }

    .form {
      &-input {
        display:grid;gap:16px;
        li {background:#fff; display: flex; align-items: center; justify-content: center; border-radius: 8px;
          label{width:100px;text-align:left;padding-left:16px;}
          input{width:100%;padding:16px 24px;margin:0;border-radius:8px;}
          textarea {width:100%;height:180px;resize:none;padding:24px;margin:16px 0;border:none;border-radius:8px;
            &:nth-of-type(1){height:60px;margin:0;padding:16px 24px;}
          }
        }
      }

      .file {
        background: #fff;border-radius: 10px;padding: 16px;margin-top:32px;
        ol {
          display: flex;
          flex-wrap: wrap;
          gap: 16px;
          max-width: 770px;
          li {
            position: relative;
            width: inherit;

            img {width: 180px;}

            button {
              background: #fff; padding: 2px;
              border-radius: 32px; text-align: center;
              padding-left: 8px;
              position: absolute;
              right: 8px;
              top: 8px;
            }
          }
        }

        &:hover{opacity:.8;}

        label{
          display: flex;flex-direction: row;align-items: center;justify-content: center; gap:20px; color:#898989;
          width: 100%; height: inherit;padding: 8px;max-width: inherit;border: none;background: none;
        }
      }
    }

    textarea {
      border: 1px solid #ddd;
      border-radius: 8px;
      height: 200px;
      padding: 16px;
      width: 800px;
    }

    .submit {width:100%;height:60px; background-color: var(--gray-800);color:var(--white); border-radius:10px;margin-top:24px;font-size:1.25rem;margin-bottom:24px;}
  }

  .qna-modal {
    display: grid;
    height: 100%;
    width: 100%;
    z-index: 2;
    
    .closeBtn {
      background: #ddd;
      border-radius: 8px;
      color: #333;
      cursor: pointer;
      font-size: 1rem;
      font-weight: bold;
      height: auto;
      padding: 8px 16px;
      position: absolute;
      top:   40px;
      right: 40px;
      width: auto;
      z-index: 9999;
    }

    .qna-sub {
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 8px;
      box-sizing: border-box;
      display: flex;
      cursor: auto;
      flex-direction: column;
      gap: 40px;
      height: inherit;
      left: 0;
      padding: 40px;
      position: absolute;
      top: 0;
      width: 100%;

      button {height: 40px;}

      p {text-align: left; width: 100%;}

      &-title {
        display: flex;
        margin-right: 100px;

        h2 {color: var(--color-2); width: 100%;}

        p {
          color: #ccc;
          line-height: 200%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-all;
        }
      }

      &-list {
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        font-size: 1rem;
        gap: 8px;
        width: 100%;

        li{
          align-items: center;
          border: none;
          border: 1px solid #ddd;
          border-radius: 8px;
          cursor: auto;
          flex-direction: column;
          justify-content: flex-start;
          padding: 0%;
          padding: 8px;
          text-align: left;

          &:nth-of-type(1){
            font-weight: inherit;
            margin: inherit; 
            position: relative;
          }

          &:hover {color: var(--MainRed);}

          p{
            cursor: auto;
            display: flex;
            justify-content: space-between;
            text-align: left;
            width: 100%;
          }
        }
      }

      .qna-sub-list-product {
        flex-wrap: wrap;
        li {width: auto;}
      }

      &-info {
        margin-bottom: 24px;
        /* border: 1px solid #ddd; */
        /* padding: 30px 40px; */
        border-radius: 10px;
        font-size: 1rem;
        width: 100%;
        p {width: 100%; display: flex; gap: 24px;
          word-break: keep-all;
          span {height:fit-content;}
          textarea {
            width: 100%;
            height: 122px;
            resize: none;
            padding: 8px;
            border: 1px solid #ddd;
            border-radius: 4px;
          }
        }
        label, div {
          display: flex; gap: 16px;
          .list-file {
            cursor: pointer;
            padding: 8px 16px;
            border-radius: 2px;
            width: 100%;
            display: flex;
            justify-content: space-around;
            border: 1px solid #ddd;
            border-radius: 4px;
          }
        }
        &-content {
          /* margin: 24px 0; */
          width: 100%;
          flex-direction: column;
          &>div {
            /* padding: 24px; */
            /* width: 230px; */
            max-width: inherit;
            display: flex;
            flex-direction: column;
            /* width: 50%; */
            .btn {
              display: flex;
              align-items: center;
              justify-content: center;
              background: unset;
              background-color: var(--MainNavy);
              border: unset;
              border-radius: 16px;
              color: var(--white);
              cursor: pointer;
              font-size: 15px;
              font-weight: 600;
              height: 40px;
            }
          }
          p{
            &:nth-of-type(2){width: 100%;}
          }
        }
      }
      .qna-sub-btn {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: stretch;
        margin-top: 40px;
        width: 100%;
      }
      &-submit {
        background: var(--MainNavy);
        border-radius: 10px;
        box-sizing: border-box;
        color: var(--white);
        font-size: 1rem;
        padding: 24px;
        width: fit-content;
      }
      .qnaBtn {
        background: #888;
        /* width: 40%; */
      }
    }
  }

  .goDetaile {
    align-items: center;
    background: #ddd;
    background: var(--color-2);
    border-radius: 8px;
    box-sizing: border-box;
    color: var(--white);
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 16px;
    /* width: 10% !important; */

    &:hover { background: #000; color: #fff;}
  }

  .pagination {
    height: 40px;
    margin-block-start: 10px;
  }

  .qna-section {
    align-content: space-between;
    display: grid;
    grid-template-rows: auto minmax(calc(100% - 176px), calc(100% - 126px)) 60px;
    height: 100%;
    overflow: hidden;
    /* position: relative; */
    width: 100%;
  }
  .qna-list-section {
    height: inherit;
    overflow: auto;
    position: relative;
    width: inherit;
  }
`;

const QnAManagement = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { operatorReducer, pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_companyList, setCompanyList] = useState([]);

  const [_inquiryList, setInquiryList] = useState([]); // 문의 리스트
  const [_currentQnA, setCurrentQnA] = useState({}); // 현재 문의

  const [_isShown, setIsShown] = useState(false); // 문의 상세보기

  /* ====================================================================== #3 */
  useEffect(() => {
    if (userReducer.user.role !== 'ROLE_MASTER') {
      alert('권한이 없습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    }

    getCompanyList();
    getInquiryList(operatorReducer.qna.pageNumber - 1, operatorReducer.qna.inquiryStatus, operatorReducer.qna.isPushedSearchButton);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));
    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getInquiryList(operatorReducer.qna.pageNumber - 1, operatorReducer.qna.inquiryStatus, operatorReducer.qna.isPushedSearchButton);
    }
    return () => { };
  }, [operatorReducer.qna.currentCompany, operatorReducer.qna.pageNumber]);

  useEffect(() => {
    console.log('operatorReducer.qna : ', operatorReducer.qna);
  }, [operatorReducer.qna]);
  useEffect(() => { }, [_currentQnA]);

  /* ====================================================================== #4 */
  const getCompanyList = async () => {
    await auth.searchCompanies().then((response) => {
      if (response === undefined) return;
      console.log('auth.searchCompanies : ', response);
      setCompanyList(() => { return checkNullArray(response.data.content, response.data); });
    });
  }

  const getInquiryList = async (page, inquiryStatus, clear) => {
    const paging = `?page=${page}&size=1000&sort=id,DESC`;
    const BodyToPost = {};
    if (checkEmptyNull(operatorReducer.qna.currentCompany, false) && operatorReducer.qna.currentCompany !== 'all') {
      BodyToPost.companyId = Number(operatorReducer.qna.currentCompany);
    }
    if (checkEmptyNull(inquiryStatus, false) && inquiryStatus !== 'all') {
      BodyToPost.status = inquiryStatus;
    }
    if (clear && checkEmptyNull(operatorReducer.qna.searchOption, false) && checkEmptyNull(operatorReducer.qna.searchText, false)) {
      BodyToPost[operatorReducer.qna.searchOption] = operatorReducer.qna.searchText;
    }

    await inquiryApi.searchAllInquiry(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('inquiry.searchAllInquiry : ', response);

      if (response.data) {
        setInquiryList(() => { return response.data.content; });

        if (checkNullArray(response.data.content, false) && _currentQnA.inquiryId !== undefined) {
          const eventOrderIndex = response.data.content.findIndex((order) => order.inquiryId === _currentQnA.inquiryId);
          if (eventOrderIndex !== -1) {
            setCurrentQnA(() => { return response.data.content[eventOrderIndex]; });
          }
        }

        dispatch(operatorActions_setQnA({ ...operatorReducer.qna, inquiryStatus: inquiryStatus, totalSize: response.data.totalElements }));
      }
    });
  };

  /* ====================================================================== #5 */
  /* 검색 */
  const actSearch = async () => {
    setCurrentQnA(() => { return {}; });

    dispatch(operatorActions_setQnA({ ...operatorReducer.qna, pageNumber: 1 }));
    dispatch(operatorActions_setQnA({ ...operatorReducer.qna, isPushedSearchButton: true }));
    await getInquiryList(0, operatorReducer.qna.inquiryStatus, true);
  };

  /* 초기화 */
  const actReset = async () => {
    setCurrentQnA(() => { return {}; });

    dispatch(operatorActions_setQnA({
      currentCompany: 'all',
      inquiryStatus: 'all',

      searchOption: '',
      searchText: '',

      isPushedSearchButton: false,

      pageNumber: 1,
      totalSize: 0,
    }));
    await getInquiryList(0, 'all', false);
  };

  /* 상세보기 */
  const goDetails = (inquiry) => {
    setIsShown(() => { return true; });
    setCurrentQnA(() => { return inquiry; });
  };

  return (
    <QnaStyle>
      <Grid4Body contents={
        <div className='qna-section'>
          <NavBar
            title='아이피스 문의관리'
            buttons={
              <>
                <div className="SearchSection">
                  <div className="ContentCBox">
                    <div className='qna-search master'>
                      <select className="SearchOption detail" value={operatorReducer.qna.currentCompany}
                        onChange={(e) => {
                          const companyId = e.target.value;
                          dispatch(operatorActions_setQnA({ ...operatorReducer.qna, currentCompany: companyId }));
                        }}
                      >
                        <option value={'all'}>전체</option>
                        {checkNullArray(_companyList, []).map((company, index) => {
                          return <option key={index + '_company'} value={company.companyId}>{company.companyName}</option>
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </>
            }
            firstRow={
              <>
                <div className="SearchSection">
                  <div className="ContentCBox">
                    <div className='qna-search master'>
                      <select className="SearchOption detail" value={operatorReducer.qna.currentCompany}
                        onChange={(e) => {
                          const companyId = e.target.value;
                          dispatch(operatorActions_setQnA({ ...operatorReducer.qna, currentCompany: companyId }));
                        }}
                      >
                        <option value={'all'}>전체</option>
                        {checkNullArray(_companyList, []).map((company, index) => {
                          return <option key={index + '_company'} value={company.companyId}>{company.companyName}</option>
                        })}
                      </select>
                    </div>

                    <div className='qna-search'>
                      <select className="SearchOption detail"
                        value={operatorReducer.qna.inquiryStatus}
                        onChange={(e) => {
                          dispatch(operatorActions_setQnA({ ...operatorReducer.qna, inquiryStatus: e.target.value, pageNumber: 1 }));
                          getInquiryList(0, e.target.value, operatorReducer.qna.isPushedSearchButton);
                        }}
                      >
                        {[
                          { status: 'all', name: '전체' },
                          { status: 'cancel', name: '취소 승인', color: '#B4BCFD' },
                          { status: 'check', name: '확인중', color: '#B2DFEE' },
                          { status: 'end', name: '답변완료', color: '#0063C4' },
                        ].map((qnaStatus) => {
                          return (<option key={qnaStatus.status + '_inquiryStatus'} value={qnaStatus.status}>{qnaStatus.name}</option>);
                        })}
                      </select>

                      <select className="SearchOption detail"
                        value={operatorReducer.qna.searchOption}
                        onChange={(e) => { dispatch(operatorActions_setQnA({ ...operatorReducer.qna, searchOption: e.target.value })); }}
                      >
                        {[
                          { optionValue: '', optionText: '검색옵션' },
                          { optionValue: 'inquiryCode', optionText: '문의코드' },
                          { optionValue: 'inquiryContent', optionText: '문의내용' },
                        ].map((option) => {
                          return (<option key={option.optionValue + '_inquirySearchOption'} value={option.optionValue}>{option.optionText}</option>);
                        })}
                      </select>

                      <input
                        className="SearchBar"
                        placeholder="Search..."
                        value={operatorReducer.qna.searchText}
                        onInput={(e) => { dispatch(operatorActions_setQnA({ ...operatorReducer.qna, searchText: e.target.value })); }}
                      />
                      <Link onClick={actReset} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 8px' }}>
                        <img src='https://cdn-icons-png.flaticon.com/512/82/82004.png' style={{ width: '16px' }} />
                      </Link>
                      <button onClick={actSearch} className='btn-search'><SearchButtonIcon />검색</button>
                    </div>
                  </div>
                </div>
              </>
            }
          />

          {/* 문의 리스트 */}
          <div className='qna-list-section'>
            <ul className='qna-status'>
              <li className='qna-status-title'>
                <div className='qna-li'>
                  <p className='first'>문의번호</p>
                  <p>진행상태</p>
                  <p>문의코드</p>
                  <p>문의내용</p>
                  <p>등록인</p>
                  <p>등록일</p>
                  <p className='goDetaile'>상세보기</p>
                </div>
              </li>

              {_inquiryList.map((inquiry, index) => {
                return (
                  <li key={index + '_inquiry'}>
                    <div className='qna-li'>
                      <p className='first'>{inquiry.inquiryId}</p>
                      <p>{InquiryStatus(checkEmptyNull(inquiry.status, 'wait'))}</p>
                      <p data-type="code">{inquiry.inquiryCode}</p>
                      <p>{inquiry.inquiryContent}</p>
                      <p>{inquiry.userName}</p>
                      <p data-type="date">{DateTimeFormatKR(inquiry.createdDate)}</p>
                      <p className='goDetaile'
                        onClick={(e) => {
                          // console.log('click', inquiry.inquiryId)
                          _currentQnA.inquiryId === inquiry.inquiryId ? setCurrentQnA({}) : goDetails(inquiry)
                        }}
                      >
                        상세보기
                      </p>

                      {/* 상세보기 모달 */}
                      {_currentQnA.inquiryId === inquiry.inquiryId && _isShown ? (
                        <div className='qna-modal'>
                          <div className='closeBtn' onClick={(e) => setIsShown(() => { e.preventDefault(); return false; })}>닫기</div>
                          <MasterInquiryInfo
                            inquiry={_currentQnA}
                            update={() => {
                              getInquiryList(operatorReducer.qna.pageNumber - 1, operatorReducer.qna.inquiryStatus, operatorReducer.qna.isPushedSearchButton);
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>

          <InquiryPaging
            page={operatorReducer.qna.pageNumber}
            count={operatorReducer.qna.totalSize}
            size={10}
            pageEvent={(page) => {
              console.log('page : ', page);
              dispatch(operatorActions_setQnA({ ...operatorReducer.qna, pageNumber: page }));
            }}
          />
        </div>
      }
      />
    </QnaStyle>
  );
};

export default QnAManagement;
