import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { LoadingMsg } from 'components/loading/LoadingMsg'

import { equipmentActions_setPageNumber, equipmentActions_setReset, equipmentActions_setSearchData, equipmentActions_setTotalSize } from 'store/modules/actions/common/equipmentActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { equipmentAttributeApi } from 'api/apis/equipmentAttributeApi';
import { equipmentApi } from 'api/apis/equipmentApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

import Grid3Body from 'components/layouts/body/Grid3Body';
import NavBar from 'components/nav/NavBar';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const EquipmentManagement = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { equipmentReducer, pageReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_equipmentList, setEquipmentList] = useState([]);
  const [_equipmentAttrList, setEquipmentAttrList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  /* ========================================================================= #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('108') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getEquipmentAttrs();
    getEquipemntList(equipmentReducer.pageNumber - 1);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      getEquipemntList(equipmentReducer.pageNumber - 1);
    }
    return () => { };
  }, [equipmentReducer.pageNumber]);

  /* ========================================================================= #4 */
  const getEquipmentAttrs = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await equipmentAttributeApi.searchEquipementAttribute(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('equipmentAttributeApi.searchEquipementAttribute : ', response.data);
      setEquipmentAttrList(() => { return response.data; });
    })
  };

  const getEquipemntList = async (page, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && checkEmptyNull(equipmentReducer.searchData.equipmentCode, false)) {
      BodyToPost.equipmentCode = equipmentReducer.searchData.equipmentCode;
    }
    if (!clear && checkEmptyNull(equipmentReducer.searchData.equipmentName, false)) {
      BodyToPost.equipmentName = equipmentReducer.searchData.equipmentName;
    }
    await equipmentApi.searchEquipment(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('equipmentApi.searchEquipment : ', response.data);
      setEquipmentList(() => { return response.data.content; });
      dispatch(equipmentActions_setTotalSize(response.data.totalElements));
    })
  };

  /* ========================================================================= #5 */
  const handleCheckedItem = (id, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(id);
    setCheckedItem(() => { return checkedItem; });
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(equipmentActions_setPageNumber(1));
    await getEquipemntList(0);
  };

  /* 초기화 */
  const actReset = async () => {
    dispatch(equipmentActions_setReset());
    await getEquipemntList(0, true);
  };

  /* 수정 */
  const actUpdate = () => {
    const updateContentIndex = _equipmentList.findIndex((thisItem) => thisItem.equipmentId === Array.from(_checkedItem)[0]);
    if (updateContentIndex === -1) return alert('수정할 설비를 선택해 주세요.');
    const updateContent = _equipmentList[updateContentIndex];
    console.log('updateContent : ', updateContent);

    navigate({
      pathname: pageReducer.currentPage + '/update',
      search: `?${createSearchParams({
        updateContent: JSON.stringify(updateContent)
        // ...updateContent,
        // equipmentLogList: JSON.stringify(updateContent.equipmentLogList),
        // equipmentAttributeValueList: JSON.stringify(updateContent.equipmentAttributeValueList),
        // equipmentAttributeList: JSON.stringify(_equipmentAttrList),
      })}`,
      replace: true,
    });
  };

  /* 삭제 */
  const actDelete = () => {
    const deleteContentIndex = _equipmentList.findIndex((thisItem) => thisItem.equipmentId === Array.from(_checkedItem)[0]);
    if (deleteContentIndex === -1) return alert('삭제할 설비를 선택해 주세요.');
    const deleteContent = _equipmentList[deleteContentIndex];
    console.log('deleteContent : ', deleteContent);

    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams(deleteContent)}`,
      replace: true,
    });
  };

  /* ========================================================================= #6 */

  return (
    <Grid3Body contents={
      <>
        <main className="Main">
          <NavBar
            title={'설비설정'}
            buttons={
              <>
                {_authority.indexOf('108-2') !== -1 ? (
                  <>
                    <button className='btn-add'
                      onClick={() => {
                        navigate(pageReducer.currentPage + '/create', { replace: true });
                      }}
                    >
                      추가
                    </button>
                    <button className='btn-edit' onClick={actUpdate}>수정</button>
                    <button className='btn-delete' onClick={actDelete}>삭제</button>
                  </>
                ) : null}
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="ContentCBox">
                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="equipmentName">설비이름</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkEmptyNull(equipmentReducer?.searchData.equipmentName, '')}
                      onInput={(e) => {
                        dispatch(equipmentActions_setSearchData({ ...equipmentReducer?.searchData, equipmentName: e.target.value }))
                      }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <select className="SearchOption detail">
                      <option value="equipmentCode">설비코드</option>
                    </select>
                    <input
                      className="SearchBar detail"
                      placeholder="Search..."
                      value={checkEmptyNull(equipmentReducer?.searchData.equipmentCode, '')}
                      onInput={(e) => {
                        dispatch(equipmentActions_setSearchData({ ...equipmentReducer?.searchData, equipmentCode: e.target.value }))
                      }}
                    />
                  </div>

                  <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                  <div className="ResetButton" onClick={actReset}>초기화</div>
                </div>
              </>
            }
          />

          <TableSection content={
            <table>
              <thead>
                <tr>
                  <th style={{ minWidth: '50px', width: '50px' }}></th>
                  <th>설비이름</th>
                  <th>설비코드</th>
                  {checkNullArray(_equipmentAttrList, []).map((equipmentAttr, index) => {
                    return (<th key={index + '_equipmentAttr'}>{equipmentAttr.equipmentAttributeName}</th>);
                  })}
                </tr>
              </thead>
              <tbody>
                {!checkNullArray(_equipmentList, false) ? LoadingMsg() :
                  checkNullArray(_equipmentList, []).map((equipment, index) => {
                    return (
                      <tr key={index + '_equipmentRow'} data-key={equipment.equipmentId}>
                        {_authority.indexOf('108-2') !== -1 ? (
                          <td style={{ minWidth: '50px', width: '50px' }}>
                            <input
                              type="checkBox"
                              name="equipments"
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                handleCheckedItem(equipment.equipmentId, isChecked);
                                if (isChecked) {
                                  document.getElementsByName('equipments').forEach((thisAttr) => { thisAttr.checked = false });
                                  e.target.checked = true;
                                } else {
                                  e.target.checked = false;
                                }
                              }}
                            />
                          </td>
                        ) : null}
                        <td>{equipment.equipmentName}</td>
                        <td>{equipment.equipmentCode}</td>

                        {checkNullArray(_equipmentAttrList, []).map((attr, attrIndex) => {
                          const equipmentAttrValueIndex = equipment.equipmentAttributeValueList.findIndex((item) => item.equipmentAttribute.equipmentAttributeId === attr.equipmentAttributeId);
                          if (equipmentAttrValueIndex !== -1) {
                            const equipmentAttrValue = equipment.equipmentAttributeValueList[equipmentAttrValueIndex];
                            return (<td key={index + '_equipmentAttrValue_' + attrIndex}>{equipmentAttrValue.value}</td>);
                          } else {
                            return (<td key={index + '_equipmentAttrValue_' + attrIndex}></td>);
                          }
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          }
          />
        </main>

        <PagingComponent
          page={equipmentReducer.pageNumber}
          count={equipmentReducer.totalSize}
          size={15}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(equipmentActions_setPageNumber(page));
          }}
        />
      </>
    }
    />
  );
};

export default EquipmentManagement;
