import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { fileDataApi } from 'api/apis/fileDataApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';

const DrawingImg = styled.img`
  max-height: 300px;
  width: auto;
`;
const DrawingZoneSection = styled.label`
  align-items: center;
  border: 1px dashed var(--MainNavy);
  box-sizing: border-box;
  column-gap: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
  width: 100%;
  p {font-weight: 600;}
`;
const DrawingIcon = styled.div`
  background-color: var(--MainNavy);
  height: 50px;
  width: 50px;
  mask-image: url(${process.env.PUBLIC_URL + '/src/icons/uploadSection.svg'}); mask-repeat: no-repeat; mask-size: contain;
  --webkit-mask-image: url(${process.env.PUBLIC_URL + '/src/icons/uploadSection.svg'}); --webkit-mask-repeat: no-repeat; --webkit-mask-size: contain;
`;

const CreateDrawingMangement = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    fileCategoryId: 121,
    // fileDataClass: 'drawing',
    fileDataName: '',
  });
  const [_viewDrawing, setViewDrawing] = useState([]);

  /* ====================================================================== #3 */
  /* ====================================================================== #4 */
  const handleInputEvent = async (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value } });
  };

  // 도면 사진 넣는 함수
  const setProductDrawing = (act, e) => {
    let files;
    switch (act) {
      case 'click': files = e.target.files; break;
      case 'drag': files = e.dataTransfer.files; break;
      default: break;
    }
    // 변수이름 다 바꾸기
    const setFileList = Array.prototype.slice.call(files);
    console.log(setFileList);
    setViewDrawing([...setFileList]);
    // 사진 미리보기
    const reader = new FileReader();
    reader.onload = (Event) => {
      document.getElementById('ViewDrawingImg').src = Event.target.result;
    };
    reader.readAsDataURL(files[0]);
  };

  /* ====================================================================== #5 */
  // 도면
  const actCreate = async (e) => {
    e.preventDefault();

    if (!checkEmptyNull(_formData.fileDataName, false)) return alert('도면 이름을 입력해주세요.');

    const BodyToPost = { ..._formData };
    const postFormData = new FormData();
    /* 파일 */
    if (checkNullArray(_viewDrawing, false)) {
      _viewDrawing.forEach((thisFile) => { postFormData.append('multipartFile', thisFile); });
    }
    /* 데이터 */
    postFormData.append('key', new Blob([JSON.stringify(BodyToPost)], { type: 'application/json' }));
    for (let values of postFormData.values()) { console.log(values); }

    await fileDataApi.createFileData(postFormData).then((response) => {
      if (response === undefined) return;
      console.log('fileDataApi.createFileData : ', response);
      alert('도면을 추가했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    })
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <>
        <main className="Main">
          <NavBar title={<NavTitle menuCode={'110'} />} nav={''} />

          <Form
            title={<>도면<br />추가</>}
            buttons={
              <>
                <button className="formButton" onClick={actCreate}>저장</button>
                <button className="formButton cancle"
                  onClick={() => { navigate(pageReducer.currentPage, { replace: true }); }}
                >
                  취소
                </button>
              </>
            }
            forms={
              <>
                <div className="formBox">
                  <h4>도면이름</h4>
                  <input
                    type="text"
                    name="fileDataName"
                    data-required="required"
                    placeholder=" 도면이름을 입력해주세요."
                    value={_formData.fileDataName}
                    onChange={handleInputEvent}
                  />
                </div>

                <div className="formBox" style={{ width: '100%' }}>
                  <h4>도면</h4>
                  <input
                    type="file"
                    id="DrawingZoneInput"
                    name={'Drawing'}
                    style={{ display: 'none' }}
                    onChange={(e) => { setProductDrawing('click', e); }}
                  />
                  <DrawingImg id="ViewDrawingImg"></DrawingImg>
                  <DrawingZoneSection
                    htmlFor="DrawingZoneInput"
                    onDragEnter={(e) => { e.stopPropagation(); e.preventDefault(); }}
                    onDragLeave={(e) => { e.stopPropagation(); e.preventDefault(); }}
                    onDragOver={(e) => { e.stopPropagation(); e.preventDefault(); }}
                    onDrop={(e) => { e.preventDefault(); setProductDrawing('drag', e); }}
                  >
                    <DrawingIcon />
                    <p>사진을 드래그해주세요.</p>
                  </DrawingZoneSection>
                </div>
              </>
            }
          />
        </main>
      </>
    }
    />
  );
};

export default CreateDrawingMangement;
