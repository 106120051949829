import React, { useEffect, useState } from 'react';
import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

const SensorData = (props) => {
  const [_sensorData, setSensorData] = useState(checkNullObject(props.content, {}));
  // console.log('_sensorData : ', _sensorData);
  const [_temp, setTemp] = useState({});
  const [_mo, setMo] = useState({});

  const [_modalStatus, setModalStatus] = useState(false);
  const [_settingData, setSettingData] = useState({});

  useEffect(() => {
    setSensorData(() => { return checkNullObject(props.content, {}) });
    setTemp(() => { return checkNullArray(props.content?.sensorList, []).find((item) => item.type === '온도'); })
    setMo(() => { return checkNullArray(props.content?.sensorList, []).find((item) => item.type === '습도'); })

    return () => { }
  }, [props])

  const actSet = (data) => {
    setSettingData(() => { return data });
    setModalStatus(() => { return true });
  };

  return (
    <>
      <div className='sensor-group'>
        <div className='sensor-header'>
          <h1>{_sensorData.name}</h1>
        </div>
        <div className='sensor-content'>
          {[
            { name: '온도', data: _temp, unit: '℃' },
            { name: '습도', data: _mo, unit: '%' },
          ].map((sensor, index) => {
            return (
              <div className='sensor-data'>
                <div className='data-header'>
                  <h3>{sensor.name}</h3>
                </div>
                <div className='sensor current'>
                  <h4>현재</h4>
                  <p>{(checkEmptyNull(sensor.data?.value, 0) * 1).toLocaleString()} <span>{sensor.unit}</span></p>
                </div>
                <div className='sensor set'>
                  <h4>설정</h4>
                  <p>{(checkEmptyNull(sensor.data?.setValue, 0) * 1).toLocaleString()} <span>{sensor.unit}</span></p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SensorData;