import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { shipmentInspection } from 'api/apis/shipmentInspection/shipmentInspection';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import SelectShipmentInspectionItems from 'pages/shipmentInspectionItem/modal/SelectShipmentInspectionItems';
import TableSection from 'components/layouts/table/TableSection';
import NavText from 'components/nav/NavText';

const MainSection = styled.main`
  .TableSection {overflow:  unset;}
`;
const CrateInspectionButton = styled.th`
  background-color: var(--MainBlue);
  color: var(--white);
  cursor: pointer;
  font-size: 1.5em;
  text-align: center;
  vertical-align: middle;
`;
const DeleteButton = styled.div`
  height: 15px;
  margin: 0px auto;
  width: 15px;
  & div {background-color: var(--MainRed); cursor: pointer; height: 15px; width: 15px;}
`;

const UpdateShipmentInspection = () => {
  /* ========================================================================= #1 */
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [searchParams, setSearchParams] = useSearchParams();
  const updateContent = checkNullParse(searchParams.get('updateContent'), {});

  const [_onload, setOnload] = useState('unload');

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,
    shipmentInspectionCode: updateContent.shipmentInspectionCode,
    shipmentInspectionName: updateContent.shipmentInspectionName,
    shipmentInspectionItemConnectorList: updateContent.shipmentInspectionItemConnectorList.map(item => {
      const returnData = {
        shipmentInspectionItemId: item.shipmentInspectionItemId,
        shipmentInspectionItemConnectorId: item.shipmentInspectionItemConnectorId
      }
      return returnData;
    }),
  });

  const [_inspectionItemList, setInspectionItemList] = useState(updateContent.shipmentInspectionItemConnectorList);
  const [_inspectionNumberArray, setInspectionNumberArray] = useState(() => {
    const numberArray = [];
    for (let i = 1; i < updateContent.shipmentInspectionItemConnectorList.length + 1; i++) {
      numberArray.push(i);
    }
    return numberArray;
  });

  const [_modalStatus, setModalStatus] = useState(false);

  /* ========================================================================= #3 */
  useEffect(() => {
    setInspectionNumberArray(() => {
      const numberArray = [];
      for (let i = 1; i < _inspectionItemList.length + 1; i++) {
        numberArray.push(i);
      }
      return numberArray;
    })
    return () => { }
  }, [_inspectionItemList])

  useEffect(() => { return () => { } }, [_inspectionNumberArray]);

  /* ========================================================================= #4 */

  /* ========================================================================= #5 */
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value }; });
  };
  const handleNumberEvent = (e, index, item) => {
    const newData = { ...item, number: BigNumber(e.target.value).toNumber() };
    setInspectionItemList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);
      prevData.splice(BigNumber(e.target.value).minus(1).toNumber(), 0, newData);
      const returnData = prevData.map((item, index) => {
        return { ...item, number: index + 1 };
      });
      return returnData;
    });
  };
  const deleteInspectioniItem = (e, index, item) => {
    setInspectionItemList((prev) => {
      const returnData = [...prev];
      returnData.splice(index, 1);
      return returnData;
    });
  }

  const actUpdate = async (e) => {
    e.preventDefault();
    if (!checkEmptyNull(_formData.shipmentInspectionCode, false)) return alert('이름를 입력해 주세요.');
    if (!checkEmptyNull(_formData.shipmentInspectionName, false)) return alert('프로젝트를 입력해 주세요.');
    if (!checkNullArray(_inspectionItemList, false)) return alert('점검 / 검사를 추가해 주세요.');

    const shipmentInspectionItemConnectorList = _inspectionItemList.map(item => {
      const returnData = {
        number: item.number,
        shipmentInspectionItemId: item.shipmentInspectionItemId
      };
      if (checkEmptyNull(item.shipmentInspectionItemConnectorId, false)) {
        returnData['shipmentInspectionItemConnectorId'] = item.shipmentInspectionItemConnectorId;
      }
      return returnData;
    })
    const deleteinspectionItemList = updateContent.shipmentInspectionItemConnectorList.filter(prevItem =>
      shipmentInspectionItemConnectorList.findIndex(item => item.shipmentInspectionItemConnectorId === prevItem.shipmentInspectionItemConnectorId) === -1
    );
    const deletedShipmentInspectionItemConnectorList = deleteinspectionItemList.map(item => item.shipmentInspectionItemConnectorId);
    const BodyToPut = { ..._formData, shipmentInspectionItemConnectorList: shipmentInspectionItemConnectorList, deletedShipmentInspectionItemConnectorList: deletedShipmentInspectionItemConnectorList };

    await shipmentInspection.updateShipmentInspection(updateContent.shipmentInspectionId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('shipmentInspection.updateShipmentInspection : ', response);
      alert('정의를 수정했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    })
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <Grid2Body contents={
      <>
        <MainSection className="Main">
          <NavBar title={<NavTitle menuCode={'114'} />} nav={''} />

          <Form
            title={<><NavTitle menuCode={'114'} /><br />수정</>}
            buttons={
              <>
                <button className="formButton" onClick={actUpdate}>저장</button>
                <button className="formButton cancle"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(pageReducer.currentPage, { replace: true });
                  }}
                >
                  취소
                </button>
              </>
            }
            forms={
              <>
                <div className="formBox">
                  <h4><NavText menuCode={'604'} /> 이름</h4>
                  <input
                    type="text"
                    name="shipmentInspectionCode"
                    placeholder="* 이름..."
                    data-required="required"
                    value={_formData.shipmentInspectionCode}
                    onInput={handleInputEvent}
                  />
                </div>
                <div className="formBox">
                  <h4>프로젝트</h4>
                  <input
                    type="text"
                    name="shipmentInspectionName"
                    placeholder="* 프로젝트..."
                    data-required="required"
                    value={_formData.shipmentInspectionName}
                    onInput={handleInputEvent}
                  />
                </div>

                <div className="formBox w100">
                  <h4>점검 / 검사 리스트</h4>
                  <TableSection content={
                    <table>
                      <thead>
                        <tr>
                          <CrateInspectionButton colSpan={5} onClick={() => { setModalStatus(true); }}>점검 / 검사 선택</CrateInspectionButton>
                        </tr>
                        <tr>
                          <th style={{ minWidth: '80px', width: '80px' }}>No.</th>
                          <th>점검 / 검사 항목</th>
                          <th>점검 / 검사 내용</th>
                          <th>판정 기준</th>
                          <th style={{ minWidth: '50px', width: '50px' }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {_inspectionItemList.map((item, index) => {
                          return (
                            <tr key={index + '_inspectionItems'}>
                              <td style={{ minWidth: '80px', width: '80px' }}>
                                <select
                                  name="number"
                                  value={checkEmptyNull(item.number, index + 1)}
                                  onInput={(e) => { handleNumberEvent(e, index, item) }}
                                >
                                  {_inspectionNumberArray.map((number) => {
                                    return (<option key={index + '_inspectionNumber_' + number} value={number}>{number}</option>);
                                  })}
                                </select>
                              </td>
                              <td>{checkNullObject(item.shipmentInspectionItem, false) ? item.shipmentInspectionItem.shipmentInspectionItemTypeName : item.shipmentInspectionItemTypeName}</td>
                              <td>{checkNullObject(item.shipmentInspectionItem, false) ? item.shipmentInspectionItem.content : item.content}</td>
                              <td>{checkNullObject(item.shipmentInspectionItem, false) ? item.shipmentInspectionItem.criteria : item.criteria}</td>
                              <th style={{ minWidth: '50px', width: '50px' }}><DeleteButton onClick={(e) => { deleteInspectioniItem(e, index, item) }}><CloseButtonIcon /></DeleteButton></th>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  } />
                </div>
              </>
            }
          />
        </MainSection>

        {_modalStatus ? <>
          <SelectShipmentInspectionItems
            buttonTitle={'선택'}
            buttonEvent={(data) => {
              console.log('data : ', data);
              const inspectionItemList = data;
              const newData = inspectionItemList.filter(item => _inspectionItemList.findIndex(prevItem => prevItem.shipmentInspectionItemId === item.shipmentInspectionItemId) === -1);
              setInspectionItemList(prev => {
                const returnData = [...prev, ...newData].map((item, index) => {
                  return { ...item, number: index + 1 };
                });
                return returnData;
              })
              setTimeout(setModalStatus(false), 1000);
            }}
            open={_modalStatus}
            close={() => { setModalStatus(false) }}
          />
        </> : null}
      </>
    }
    />
  );
};

export default UpdateShipmentInspection;
