import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { order } from 'api/apis/mall/order';

import DeleteForm from 'components/layouts/form/DeleteForm';
import MallBody from 'components/layouts/body/MallBody';
import NavBar from 'components/nav/NavBar';

const DeleteMallOrder = () => {
  const navigate = useNavigate();
  const { pageReducer } = useSelector((state) => state);

  const [searchParams, setSearchParams] = useSearchParams();

  const deleteContents = JSON.parse(searchParams.get('deleteContents'));
  const [_deleteContents, setDeleteContents] = useState(deleteContents);

  let delCount = 0;
  const actAllDelete = () => {
    deleteContents.forEach(async (deleteContent) => {
      await actDelete(deleteContent.mallOrderId, deleteContent);
    });
  };

  const actDelete = async (mallOrderId) => {
    await order.deleteMallOrder(mallOrderId).then((response) => {
      if (response === undefined) return;
      console.log('order.deleteMallOrder : ', response);
      setDeleteContents((prev) => {
        const returnData = [...prev];
        const index = returnData.findIndex((item) => item.mallOrderId === mallOrderId);
        returnData.splice(index, 1);
        return returnData;
      });
    }).finally(() => {
      delCount++;
      if (delCount === deleteContents.length) {
        alert(`주문정보를 삭제했습니다.`);
        setTimeout(navigate(pageReducer.currentPage, { replace: true }), 1000);
      }
    });
  };

  /* ====================================================================== #6 */
  window.onkeydown = (e) => {
    const thisKeyCode = e.keyCode;
    if (thisKeyCode === 116) e.returnValue = false;
  }; // F5 막기

  return (
    <MallBody contents={
      <>
        <main className="Main">
          <NavBar title={'주문관리'} nav={''} />

          <DeleteForm
            delteTitle={'주문정보'}
            deleteItem={_deleteContents.map((thisItem) => (
              <Fragment key={thisItem.mallOrderId}>
                <p><b>주문코드:</b> {thisItem.mallOrderCode}</p><br />
              </Fragment>
            ))}
            deleteText={<>해당 <span className="cautionText">주문정보</span>가 삭제됩니다.</>}
            deleteButton={
              <>
                <button className="formButton cancle" onClick={() => { navigate(pageReducer.currentPage, { replace: true });}}>취소</button>
                <button className="formButton delete"
                  onClick={() => {
                    if (window.confirm('주문정보를 삭제합니다.')) actAllDelete();
                    else return;
                  }}
                >
                  삭제
                </button>
              </>
            }
          />
        </main>
      </>
    }
    />
  );
};

export default DeleteMallOrder;
