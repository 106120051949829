import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { checkEmptyNull } from 'components/checkValues/checkValues';

import { sensorDataApi } from 'api/apis/sensorDataApi';

import { DashboardBtn, SafetyMain, Sensing } from './SafetyManagement.style';

import GridNonBody from 'components/layouts/body/GridNonBody';
import Header from 'components/header/Header';
import TmpHmd from './contents/tmpHmd/TmpHmd';
import Fire from './contents/Fire';
import ElectricalShort from './contents/ElectricalShort';


const SafetyManagement = () => {
  const { userReducer } = useSelector((state) => state);

  const [_onHeader, setOnHeader] = useState(false);
  const [_pageNumber, setPageNumber] = useState(1);

  const [_startDate, setStartDate] = useState(
    moment().add('-1', 'm').format('YYYY-MM-DD hh:mm:ss'),
  );
  const [_endDate, setEndDate] = useState(
    moment().format('YYYY-MM-DD hh:mm:ss'),
  );

  const [_logList, setLogList] = useState([]);
  const [_viewList, setViewList] = useState([]);
  
  // ---

useEffect(() => {
  getSensorData(_pageNumber - 1, userReducer.company.companyId);
  return () => {};
}, []);

useEffect(() => {
  const fetchData = async () => {
    await getSensorData(_pageNumber - 1, userReducer.company.companyId);
    findViewList();
  };

  fetchData();

  return () => {};
}, [_pageNumber, userReducer.company.companyId]); // 의존성 배열에 필요한 값 추가

useEffect(() => {
  findViewList(_logList)
}, [_logList]);

  // ---

  // 온습도 API 호출 관련 함수
  const getSensorData = async (page, companyId) => {
    const paging = `?page=${page}&size=100&sort=id,DESC`;
    const BodyToPost = {
      companyId: companyId,
      startDate: moment().add('-1', 'm').add(9, 'hour').toISOString(),
      endDate: moment().add(9, 'hour').toISOString(),
      name: '',
    };

    if (checkEmptyNull(_startDate, false))
      BodyToPost.startDate = moment(_startDate).add(9, 'hour').toISOString();
    if (checkEmptyNull(_endDate, false))
      BodyToPost.endDate = moment(_endDate).add(9, 'hour').toISOString();

    await sensorDataApi.countData(paging, BodyToPost).then((response) => {
      // 센서 누적 데이터
      if (response === undefined) return;
      setLogList(() => {
        const filterLogs = response.data.content.reduce((acc, log) => {
          // log.name이 중복되지 않도록 검사
          if (!acc.some(item => item.name === log.name)) {
            acc.push(log);
          }
          return acc;
        }, []);

        return filterLogs;
      });
    });
  };

  // ---

  const clickHeader = (e) => {
    e.preventDefault();
    setOnHeader((prev) => {
      return !prev;
    });
  };

  const findViewList = () => {
    const viewList = _logList
        .filter(log => log.name.includes('temp') || log.name.includes('온도'))
        .map(log => log.name.split('_')[0]);
    setViewList(viewList);
  };

  // ---

  return (
    <>
      <GridNonBody
        header={<>{_onHeader ? <Header /> : null}</>}
        contents={
          <>
            <SafetyMain onHeader={_onHeader}>
              <section>
                <TmpHmd
                  logList={_logList}
                  viewList={_viewList}
                />
                <Sensing>
                  <Fire
                    logList={_logList}
                  />
                  <ElectricalShort
                    logList={_logList}
                  />
                </Sensing>
              </section>
            </SafetyMain>
          </>
        }
      />
      <DashboardBtn onClick={clickHeader} data-name="on">
        메뉴 {_onHeader ? '닫기' : '열기'}
      </DashboardBtn>
    </>
  );
};

export default SafetyManagement;
