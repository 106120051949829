import styled from 'styled-components';

export const SearchAttrs = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  width: calc(100vw - 450px);
`;
export const SearchAttr = styled.div`
  align-items: center;
  column-gap: 10px;
  display: flex;
  height: unset;
  padding: 6px 20px;
  justify-content: center;
  width: fit-content;

  & p {width: calc(100% - 20px); word-break: break-all;}
`;
export const DeleteAttrButton = styled.div`
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;

  div {
    background-color: var(--white);
    cursor: pointer;
    height: 15px;
    width: 15px;
  }
`;