import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';

import { ACCESS_TOKEN, AXIOS_BASE_URL } from 'preferences/server/constants';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import CommaNum from 'components/format/CommaNum';
import { getCookie } from 'preferences/cookie/cookie';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  box-sizing: border-box;
  column-gap: 10px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 55px);
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px;
  row-gap: 10px;
  width: 100%;

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 6px;
  }

  & .TableSection {
    height: 100%;
    max-height: 200px;
    min-width: unset;
    overflow-x: auto;
    width: 100%;
  }
`;

const Right = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  min-width: unset;
  overflow: auto;
  /* padding: 20px; */
  row-gap: 10px;
  width: 100%;

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 6px;
  }
`;

const RightTop = styled.div`
  align-items: flex-start;
  column-gap: 10px;
  display: flex;
  height: 100%;
  justify-content: space-between;
  min-width: unset;
  overflow: auto;
  width: 100%;

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-100);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-400);
    border: 2px solid var(--gray-100);
    border-radius: 6px;
  }
`;

const StockButton = styled.button`
  background: unset;
  background-color: ${(props) => {
    return props.thisStockType === '입고'
      ? 'var(--MainGreen)'
      : props.thisStockType === props.thisPutName
      ? 'var(--MainYellow)'
      : props.thisStockType === '출하' && 'var(--MainRed)';
  }};
  border: unset;
  color: var(--white);
  font-size: 45px;
  font-weight: bolder;
  height: 200px;
  width: 100%;

  &:hover {
    background: unset;
    background-color: ${(props) => {
      return props.thisStockType === '입고'
        ? 'var(--MainGreen)'
        : props.thisStockType === props.thisPutName
        ? 'var(--MainYellow)'
        : props.thisStockType === '출하' && 'var(--MainRed)';
    }};
  }
`;

const NumpadSection = styled.div`
  display: grid;
  grid-template-rows: 120px 120px auto;
  height: 100%;
  row-gap: 10px;
  width: 100%;
`;

const NumpadDisplay = styled.div`
  column-gap: 5px;
  cursor: default;
  display: grid;
  grid-template-columns: auto minmax(30px, 60px);
  height: 100%;
  justify-content: flex-end;
  outline: 4px solid
    ${(props) => {
      return props.stockStatus === 'null'
        ? 'var(--gray-100);'
        : props.stockStatus === 'safe'
        ? 'var(--MainGreen);'
        : 'var(--MainRed);';
    }};
  /* outline: 4px solid var(--gray-100); */
  outline-offset: -4px;
  position: relative;
  width: 100%;

  &.weight {
    outline: 4px solid var(--MainNavy);
  }
`;

const NumSection = styled.div`
  align-items: flex-end;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  padding: 10px;
  text-align: right;
  width: 100%;

  input {
    background-color: unset;
    color: inherit;
    font-size: 60px;
    font-weight: 600;
    text-align: right;
    width: 100%;
  }
`;

const UnitSection = styled.div`
  align-items: flex-end;
  box-sizing: border-box;
  display: flex;
  font-size: 20px;
  height: 100%;
  justify-content: flex-start;
  padding-bottom: 20px;
  width: 100%;
`;

const NumButtons = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  height: 100%;
  width: 100%;
`;

const NumButton = styled.button`
  color: var(--gray-700);
  font-size: 50px;
  font-weight: 600;
  min-height: 57px;
  height: 100%;
  width: 100%;
`;

const EctButtonsSection = styled.div`
  display: grid;
  grid-template-rows: 120px repeat(4, 1fr);
  height: 100%;
  row-gap: 10px;
  width: 110px;

  button {
    margin: 0px auto;
  }
`;

const TypeButton = styled.button`
  align-self: center;
  background: unset;
  background-color: ${(props) => {
    return props.thisStockType === '입고'
      ? 'var(--MainGreen)'
      : props.thisStockType === props.thisPutName
      ? 'var(--MainYellow)'
      : props.thisStockType === '출하' && 'var(--MainRed)';
  }};
  border: unset;
  border-radius: 50%;
  box-sizing: border-box;
  color: var(--white);
  font-size: 18px;
  font-weight: 600;
  height: 80px;
  margin: 20px auto;
  position: relative;
  width: 80px;

  &:hover {
    background: unset;
    background-color: ${(props) => {
      return props.thisStockType === '입고'
        ? 'var(--ThirdGreen)'
        : props.thisStockType === props.thisPutName
        ? 'var(--SeconYellow)'
        : props.thisStockType === '출하' && 'var(--ThirdRed)';
    }};
    padding-right: 10px;
  }

  &:hover::before {
    content: '▸';
    left: 12px;
    position: absolute;
    transition: all 0.5s;
  }
`;

const StockNumSection = styled.div`
  align-items: center;
  /* background-color: var(--white); */
  column-gap: 10px;
  display: flex;
  justify-content: center;
  left: 5px;
  padding: 5px 15px;
  position: absolute;
  top: 5px;
`;

const StockNum = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;

  ${(props) => {
    return props.stockStatus === 'safe'
      ? 'color: var(--MainGreen)'
      : props.stockStatus === 'danger'
      ? 'color: var(--MainRed)'
      : 'color: inherit';
  }};
`;

const GSNumpad = (props) => {
  /* ====================================================================== #1 */
  const { contentsReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const date = new Date();
  const StartTime = moment().utc(date).startOf('day');
  const setStartTime = StartTime.toISOString();

  const EndTime = moment().utc(date).endOf('day');
  const setEndTime = EndTime.toISOString();

  const [_stockItem, setStockItem] = useState();

  const [_logList, setLogList] = useState([]);

  const [_displayNum, setDisplayNum] = useState('0');
  const [_displayUnit, setDisplayUnit] = useState();
  const [_productType, setProductType] = useState();
  const [_subProductType, setSubProductType] = useState();

  // const [_remarkModal, setRemarkModal] = useState(false);

  const [_mobile, setMobile] = useState(false);

  const [_currentStock, setCurrentStock] = useState(0);
  console.log('_currentStock : ', _currentStock);

  const [_procurement, setProcurement] = useState(props.Procurement);
  console.log(_procurement);

  const [_InputPart, setInputPart] = useState('Numpad');
  const [_weight, setWeight] = useState('0');

  const [_thisProcurementEl, setThisProcurementEl] = useState({});
  console.log(_thisProcurementEl);

  const [_customWeight, setCustomWeight] = useState(0);

  /* ====================================================================== #3 */
  useEffect(() => {
    if (mobile()) {
      setMobile(true);
    } else {
      setMobile(false);
    }

    console.log(contentsReducer.stockItem);
    setStockItem(contentsReducer.stockItem);
    setCurrentStock(contentsReducer.stockItem.elementStock);
    setDisplayUnit(contentsReducer.stockItem.elementUnit);

    searchAllElementStockLog();

    switch (contentsReducer.stockItem.elementType.toLowerCase()) {
      case 'material':
        setProductType('입고');
        setSubProductType(userReducer.putName);
        break;
      case 'semi':
        setProductType('입고');
        setSubProductType(userReducer.putName);
        break;
      case 'half':
        setProductType('입고');
        setSubProductType(userReducer.putName);
        break;
      case 'product':
        setProductType('출하');
        setSubProductType('입고');
        break;

      default:
        break;
    }

    if (
      props.Procurement.procurementElementList !== undefined &&
      props.Procurement.procurementElementList.length > 0
    ) {
      const thisElIndex = props.Procurement.procurementElementList.findIndex(
        (thisEl) =>
          thisEl.procurementElementId === Number(props.Barcode.split('-')[2]),
      );
      const thisEl = props.Procurement.procurementElementList[thisElIndex];

      setThisProcurementEl(thisEl);
    }

    const thisProcurementCustomizedContent = JSON.parse(
      props.Procurement.customizedContent,
    );
    if (
      thisProcurementCustomizedContent !== undefined &&
      thisProcurementCustomizedContent !== null &&
      typeof thisProcurementCustomizedContent.actualWeight === 'number'
    ) {
      setCustomWeight(thisProcurementCustomizedContent.actualWeight);
    }

    setOnload('loaded');

    return () => {};
  }, []);

  /* ====================================================================== #4 */
  const mobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  };

  const setDateFormat = (thisDate) => {
    console.log('setDateFormat : ', thisDate);
    if (
      thisDate !== undefined &&
      thisDate !== null &&
      typeof thisDate === 'object'
    ) {
      const newtDate = new Date(thisDate.join('-'));
      return moment(newtDate).format('YYYY-MM-DD');
    } else {
      return thisDate;
    }
  };

  const searchAllElementStockLog = async () => {
    const BodyToPost = {
      elementId: contentsReducer.stockItem.elementId,
      elementStockLogStartDate: setStartTime,
      elementStockLogEndDate: setEndTime,
    };
    console.log('/searchAllElementStockLog-body : ', BodyToPost);

    await axios
      .post(
        AXIOS_BASE_URL + '/elementStockLog/search',
        {
          ...BodyToPost,
          companyId: userReducer.company.companyId,
        },
        {
          headers: {
            Authorization: getCookie('accessToken'),
          },
        },
      )
      .then((response) => {
        if (response === undefined) return;
        Finish_searchAllElementStockLog(response.data);
      })
      .catch((error) => {
        console.log('/searchAllElementStockLog-error : ', error);
      });
  };
  const Finish_searchAllElementStockLog = (Result) => {
    const logList = Result.content;
    console.log('post/elementStockLog/search : ', Result);
    console.log('post/elementStockLog/search - content : ', logList);

    setLogList(logList);
  };

  const changeStockType = () => {
    switch (_stockItem.elementType.toLowerCase()) {
      case 'product':
        if (_subProductType === '입고') {
          setSubProductType('출하');
          setProductType('입고');
        } else {
          setSubProductType('입고');
          setProductType('출하');
        }

        break;

      default:
        if (_subProductType === '입고') {
          setSubProductType(userReducer.putName);
          setProductType('입고');
        } else {
          setSubProductType('입고');
          setProductType(userReducer.putName);
        }

        break;
    }
  };

  /* ====================================================================== #5 */
  const actNumButton = (e) => {
    if (_InputPart === 'Numpad') {
      const currentNum = _displayNum;
      const thisNum = e.target.dataset.num;
      if (thisNum === '.')
        if (currentNum.indexOf('.') !== -1)
          // '.' 1개 이상 막기
          return;

      if (thisNum === '00')
        if (currentNum === '0')
          // '00'으로 시작 막기
          return;

      let resultNum = currentNum + thisNum;

      if (resultNum.startsWith('0.') !== true)
        if (resultNum.length === 2)
          if (resultNum.slice(0, 1) === '0')
            resultNum = resultNum.replace('0', ''); // 첫 숫자 0 막기

      let checkNum = resultNum;
      if (
        Number(resultNum) >
        parseFloat(
          _thisProcurementEl.quantity - _thisProcurementEl.storeQuantity,
        )
      ) {
        alert('미입고 수량을 초과했습니다.');
        checkNum = parseFloat(
          _thisProcurementEl.quantity - _thisProcurementEl.storeQuantity,
        ).toString();
      }
      console.log(resultNum, checkNum);
      setDisplayNum(checkNum); // '.' 인 경우 그 다음 숫자(0 제외)를 클릭해야 '.' 이 같이 표시됨
    }

    if (_InputPart === 'Weight') {
      const currentNum = _weight;
      const thisNum = e.target.dataset.num;
      if (thisNum === '.')
        if (currentNum.indexOf('.') !== -1)
          // '.' 1개 이상 막기
          return;

      if (thisNum === '00')
        if (currentNum === '0')
          // '00'으로 시작 막기
          return;

      let resultNum = currentNum + thisNum;

      if (resultNum.startsWith('0.') !== true)
        if (resultNum.length === 2)
          if (resultNum.slice(0, 1) === '0')
            resultNum = resultNum.replace('0', ''); // 첫 숫자 0 막기

      console.log(resultNum);
      setWeight(resultNum); // '.' 인 경우 그 다음 숫자(0 제외)를 클릭해야 '.' 이 같이 표시됨
    }
  };

  const actACButton = () => {
    if (_InputPart === 'Numpad') {
      setDisplayNum('0'); // 초기화
    }

    if (_InputPart === 'Weight') {
      setWeight('0'); // 초기화
    }
  };

  const actStock = async () => {
    const displayAmount = document.querySelector('#NumSection input').value;
    const amount = Number(displayAmount.replace(/[^0-9\.]/g, ''));
    if (isNaN(amount)) return alert('숫자만 입력 가능합니다.');

    let logType;
    if (userReducer.stockType === 'In/Out') {
      switch (_stockItem.elementType.toLowerCase()) {
        case 'material':
          _productType === '입고' ? (logType = 'In') : (logType = 'Out');
          break;
        case 'semi':
          _productType === '입고' ? (logType = 'In') : (logType = 'Out');
          break;
        case 'half':
          _productType === '입고' ? (logType = 'In') : (logType = 'Out');
          break;
        case 'product':
          _productType === '입고' ? (logType = 'In') : (logType = 'Out');
          break;

        default:
          logType = '';
          break;
      }
    }
    if (userReducer.stockType === 'In/Put/Out') {
      switch (_stockItem.elementType.toLowerCase()) {
        case 'material':
          _productType === '입고' ? (logType = 'In') : (logType = 'Put');
          break;
        case 'semi':
          _productType === '입고' ? (logType = 'In') : (logType = 'Put');
          break;
        case 'half':
          _productType === '입고' ? (logType = 'In') : (logType = 'Put');
          break;
        case 'product':
          _productType === '입고' ? (logType = 'In') : (logType = 'Out');
          break;

        default:
          logType = '';
          break;
      }
    }

    console.log(
      userReducer.stockType,
      _stockItem.elementType,
      _productType,
      logType,
    );

    if (logType === 'In') {
      // _procurement
      const BodyToPost = {
        companyId: userReducer.company.companyId,
        userId: userReducer.user.id,

        procurementStatus: _procurement.procurementStatus,
        procurementId: _procurement.procurementId,

        procurementCode: _procurement.procurementCode,
        procurementName: _procurement.procurementName,

        procurementDate: setDateFormat(_procurement.procurementDate),
        scheduledEndDate: setDateFormat(_procurement.scheduledEndDate),

        procurementPrice: _procurement.procurementPrice,

        deleteProcurementElementList: [],
        procurementElementList: [],

        customizedContent: JSON.stringify({
          actualWeight: parseFloat(_customWeight + Number(_weight)),
        }),
      };
      const setProcurementElList = _procurement.procurementElementList.map(
        (thisItem) => {
          if (
            thisItem.procurementElementId ===
            Number(props.Barcode.split('-')[2])
          ) {
            let thisActualWeight = JSON.parse(
              thisItem.customizedContent,
            ).actualWeight;
            if (typeof Number(thisActualWeight) === 'number') {
              thisActualWeight = parseFloat(
                Number(thisActualWeight) + Number(_weight),
              );
            }
            return {
              accountId: thisItem.account.accountId,

              elementId: thisItem.element.elementId,
              procurementElementId: thisItem.procurementElementId,

              customizedContent:
                JSON.parse(thisItem.customizedContent) !== null
                  ? JSON.stringify({
                      ...JSON.parse(thisItem.customizedContent),
                      actualWeight: thisActualWeight,
                    })
                  : JSON.stringify({
                      num: '',
                      weight: '',
                      actualWeight: _weight,
                    }),

              price: thisItem.price,
              quantity: thisItem.quantity,
              storeQuantity: parseFloat(thisItem.storeQuantity + amount),
            };
          } else {
            return null;
          }
        },
      );
      BodyToPost.procurementElementList = setProcurementElList.filter(
        (thisItem) => thisItem !== null,
      );
      console.log(
        '/post/procurement/savePartStock-body : ',
        BodyToPost,
        JSON.stringify(BodyToPost),
      );

      await axios
        .post(AXIOS_BASE_URL + '/procurement/savePartStock', BodyToPost, {
          headers: {
            Authorization: getCookie('accessToken'),
          },
        })
        .then(async (response) => {
          console.log(
            '/post/procurement/savePartStock - response : ',
            response,
          );
          if (response.status === 200) {
            await axios
              .put(
                AXIOS_BASE_URL + '/procurement/' + BodyToPost.procurementId,
                BodyToPost,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      ACCESS_TOKEN,
                    )}`,
                  },
                },
              )
              .then((response) => {
                if (response === undefined) return;
                console.log('/put/procurement - response : ', response);
                if (response.status === 200) {
                  alert('물품을 입고했습니다.');

                  setThisProcurementEl({
                    ..._thisProcurementEl,
                    storeQuantity: parseFloat(
                      _thisProcurementEl.storeQuantity + amount,
                    ),
                  });
                  setCustomWeight(parseFloat(_customWeight + Number(_weight)));

                  setDisplayNum('0'); // 초기화
                  setWeight('0'); // 초기화
                }
              })
              .catch((error) => {
                console.log('/put/procurement - error : ', error);
              });
          }
        })
        .catch((error) => {
          console.log('/post/procurement/savePartStock - error : ', error);
        });
    } else {
      const newDate = new Date();
      newDate.setHours(newDate.getHours() + 9);
      const CurrentTime = newDate.toISOString();

      const PutToPost = {
        companyId: userReducer.company.companyId,
        userId: userReducer.user.id,
        elementId: _thisProcurementEl.element.elementId,

        logType: logType,
        amount: amount,

        note: _procurement.procurementName + ' - 자재 투입',

        fileFath: '',
        fileName: '',

        checkedDate: CurrentTime,
      };
      console.log('/post/elementStockLog-body : ', PutToPost);

      await axios
        .post(AXIOS_BASE_URL + '/elementStockLog', PutToPost, {
          headers: {
            Authorization: getCookie('accessToken'),
          },
        })
        .then((response) => {
          if (response === undefined) return;
          console.log('/post/elementStockLog - response : ', response);
          if (response.status === 200) {
            alert('물품을 투입했습니다.');
            setDisplayNum('0'); // 초기화
            setWeight('0'); // 초기화
            // props.close();
          }
        })
        .catch((error) => {
          console.log('/post/elementStockLog - error : ', error);
        });
    }
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection mobile={_mobile}>
            <ModalHeader>
              <ModalTitle>
                {contentsReducer.stockItem.elementCode +
                  ' • ' +
                  contentsReducer.stockItem.elementName}
              </ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain mobile={_mobile}>
              <Right mobile={_mobile}>
                <RightTop mobile={_mobile}>
                  <NumpadSection>
                    <NumpadDisplay
                      style={
                        _InputPart === 'Numpad'
                          ? {
                              backgroundColor: 'var(--MainNavy)',
                              color: 'var(--white)',
                            }
                          : {}
                      }
                      stockStatus={
                        contentsReducer.stockItem.elementSafeStock === null
                          ? 'null'
                          : contentsReducer.stockItem.elementSafeStock <=
                            _currentStock
                          ? 'safe'
                          : 'danger'
                      }
                      onClick={() => {
                        setInputPart('Numpad');
                      }}
                    >
                      <StockNumSection>
                        <StockNum>
                          <p>미입고 수량: </p>
                          <CommaNum
                            customAttr={'readonly'}
                            displayType={'text'}
                            num={parseFloat(
                              _thisProcurementEl.quantity -
                                _thisProcurementEl.storeQuantity,
                            )}
                          />
                        </StockNum>
                      </StockNumSection>
                      <NumSection id="NumSection">
                        <CommaNum
                          customAttr={'readonly'}
                          displayType={'input'}
                          num={_displayNum}
                        />
                      </NumSection>
                      <UnitSection>{_displayUnit}</UnitSection>
                    </NumpadDisplay>

                    <NumpadDisplay
                      className="weight"
                      style={
                        _InputPart === 'Weight'
                          ? {
                              backgroundColor: 'var(--MainNavy)',
                              color: 'var(--white)',
                            }
                          : {}
                      }
                      onClick={() => {
                        setInputPart('Weight');
                      }}
                    >
                      <StockNumSection>
                        <StockNum>
                          <p>실제 무게: </p>
                          <CommaNum
                            customAttr={'readonly'}
                            displayType={'text'}
                            num={_customWeight}
                          />
                        </StockNum>
                      </StockNumSection>
                      <NumSection id="WeightSection">
                        <CommaNum
                          customAttr={'readonly'}
                          displayType={'input'}
                          num={_weight}
                        />
                      </NumSection>
                      <UnitSection>{'Kg'}</UnitSection>
                    </NumpadDisplay>

                    <NumButtons>
                      <NumButton data-num="1" onClick={actNumButton}>
                        1
                      </NumButton>
                      <NumButton data-num="2" onClick={actNumButton}>
                        2
                      </NumButton>
                      <NumButton data-num="3" onClick={actNumButton}>
                        3
                      </NumButton>

                      <NumButton data-num="4" onClick={actNumButton}>
                        4
                      </NumButton>
                      <NumButton data-num="5" onClick={actNumButton}>
                        5
                      </NumButton>
                      <NumButton data-num="6" onClick={actNumButton}>
                        6
                      </NumButton>

                      <NumButton data-num="7" onClick={actNumButton}>
                        7
                      </NumButton>
                      <NumButton data-num="8" onClick={actNumButton}>
                        8
                      </NumButton>
                      <NumButton data-num="9" onClick={actNumButton}>
                        9
                      </NumButton>

                      <NumButton onClick={actACButton}>AC</NumButton>
                      <NumButton data-num="0" onClick={actNumButton}>
                        0
                      </NumButton>
                      <NumButton data-num="." onClick={actNumButton}>
                        .
                      </NumButton>
                    </NumButtons>
                  </NumpadSection>

                  <EctButtonsSection>
                    <TypeButton
                      thisStockType={_subProductType}
                      thisPutName={userReducer.putName}
                      onClick={changeStockType}
                    >
                      {_subProductType}
                    </TypeButton>
                  </EctButtonsSection>
                </RightTop>

                <StockButton
                  thisStockType={_productType}
                  thisPutName={userReducer.putName}
                  onClick={actStock}
                >
                  {_productType}
                </StockButton>
              </Right>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}

      {/* { _remarkModal === true && <RemarkModal open={ _remarkModal } close={ () => { setRemarkModal(false) } }></RemarkModal> } */}
    </>
  );
};

export default GSNumpad;
