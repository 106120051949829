const initialState = {
  workOrderType: 'all',
  workOrderStatus: 'all',
  startDate: '',
  endDate: '',
  searchData: {},
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,

  viewScale: 100,

  bookmark: true,
}
const resetState = {
  workOrderType: 'all',
  workOrderStatus: 'all',
  startDate: '',
  endDate: '',
  searchData: {},
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const workOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETWORKORDERWORKORDERTYPE':
      return {
        ...state,
        workOrderType: action.payload,
      };
    case 'SETWORKORDERWORKORDERSTATUS':
      return {
        ...state,
        workOrderStatus: action.payload,
      };
    case 'SETWORKORDERSTARTDATE':
      return {
        ...state,
        startDate: action.payload,
      };
    case 'SETWORKORDERENDDATE':
      return {
        ...state,
        endDate: action.payload,
      };
    case 'SETWORKORDERSEARCHDATA':
      return {
        ...state,
        searchData: action.payload,
      };
    case 'SETWORKORDERPAGENUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'SETWORKORDERTOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };
    case 'SETWORKORDERISPUSHEDSEARCHBUTTON':
      return {
        ...state,
        isPushedSearchButton: action.payload,
      };

    case 'SETWORKORDERRESET':
      return {
        ...state,
        ...resetState,
      };

    case 'SETWORKORDERVIEWSCALE':
      return {
        ...state,
        viewScale: action.payload,
      };

    case 'SETWORKORDERBOOKMARK':
      return {
        ...state,
        bookmark: action.payload,
      };

    default: return state;
  };
};

export default workOrderReducer;