const initialState = {
  mallSiteOrderStatus: 'all',

  startDate: undefined,
  endDate: undefined,
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const resetState = {
  mallSiteOrderStatus: 'all',

  startDate: undefined,
  endDate: undefined,
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const mallSiteOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETMALLSITEORDERSTATUS':
      return {
        ...state,
        mallSiteOrderStatus: action.payload,
      };
    case 'SETMALLSITEORDERSTARTDATE':
      return {
        ...state,
        startDate: action.payload,
      };
    case 'SETMALLSITEORDERENDDATE':
      return {
        ...state,
        endDate: action.payload,
      };
    case 'SETMALLSITEORDERSEARCHOPTION':
      return {
        ...state,
        searchOption: action.payload,
      };
    case 'SETMALLSITEORDERSEARCHTEXT':
      return {
        ...state,
        searchText: action.payload,
      };
    case 'SETMALLSITEORDERPAGENUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'SETMALLSITEORDERTOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };
    case 'SETMALLSITEORDERISPUSHEDSEARCHBUTTON':
      return {
        ...state,
        isPushedSearchButton: action.payload,
      };
    case 'SETMALLSITEORDERRESET':
      return {
        ...state,
        ...resetState,
      };

    default:
      return state;
  }
};

export default mallSiteOrderReducer;
