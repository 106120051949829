import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { equipmentSharingAttribute, equipmentSharingType, peopleSharingAttribute, peopleSharingType } from 'api/apis/platform/cluster/cluster';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { CiCirclePlus } from "react-icons/ci";
// import { FiPlusCircle } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { IoSearch } from "react-icons/io5";
import { RiEditFill } from "react-icons/ri";
import { SettingComponent } from './Setting.style';
import ClusterFormModal from '../modal/FormModal';
import ClusterPagination from 'platform/cluster/components/ClusterPagination';

const MyPageSettingAttributes = () => {
  const location = useLocation();
  const clusterId = location.pathname.split('/platform/cluster')[0];
  const clusterPath = clusterId + '/platform/cluster/mypage';

  const [_onload, setOnLoad] = useState(false);

  const [_currentType, setCurrentType] = useState('');
  const [_title, setTitle] = useState('');
  const [_dataKeys, setDataKeys] = useState([]);

  const [_dataList, setDataList] = useState([]);

  const [_sharingTypeList, setSharingTypeList] = useState([]); // 유형 리스트 (장비/인력)
  const [_sharingType, setSharingType] = useState(''); // 유형 선택 (장비/인력)

  const [_searchData, setSearchData] = useState(''); // 속성 이름 검색
  const [_pageNumber, setPageNumber] = useState(1);
  const [_totalSize, setTotalSize] = useState(0);

  const [_modalType, setModalType] = useState();
  const [_modalData, setModalData] = useState();
  const [_modalStatus, setModalStatus] = useState(false);

  useEffect(() => {
    if (location.pathname.includes('/equipment')) { // 징비 속성
      setCurrentType(() => { return 'equipment'; });
      getTypeList('equipment');
      getDataList(_pageNumber - 1, 'equipment');
    } else if (location.pathname.includes('/people')) { // 인력 속성
      setCurrentType(() => { return 'people'; });
      getTypeList('people');
      getDataList(_pageNumber - 1, 'people');
    }
  }, [location.pathname]);
  useEffect(() => {
    switch (_currentType) {
      case 'equipment':
        setTitle(() => { return '장비 속성 설정'; });
        setDataKeys(() => {
          return [
            { key: 'equipmentSharingTypeName', title: '장비 유형', },
            { key: 'equipmentSharingAttributeName', title: '장비 속성', },
          ];
        });
        break;
      case 'people':
        setTitle(() => { return '인력 속성 설정'; });
        setDataKeys(() => {
          return [
            { key: 'peopleSharingTypeName', title: '인력 유형', },
            { key: 'peopleSharingAttributeName', title: '인력 속성', },
          ];
        });
        break;
      default: break;
    }
  }, [_currentType]);
  useEffect(() => {
    setOnLoad(() => { return true });
    // getDataList(_pageNumber - 1, _currentType);
    return () => { }
  }, []);
  useEffect(() => {
    if (_onload) getDataList(_pageNumber - 1, _currentType);
    return () => { }
  }, [_pageNumber]);
  useEffect(() => { // 유형 변경 >> 데이터 불러오기
    if (_onload) {
      setPageNumber(() => { return 1; });
      getDataList(0, _currentType);
    }
    return () => { }
  }, [_sharingType]);
  useEffect(() => { }, [_dataKeys, _dataList, , _totalSize]);

  const getTypeList = async (currentType) => { // 유형 불러오기
    const id = Number(clusterId.replace('/', ''));
    const body = { clusterId: id };

    switch (currentType) {
      case 'equipment': // 장비 유형
        await equipmentSharingType.search('', body).then(response => {
          if (response === undefined) return;
          setSharingTypeList(() => { return response.data });
        })
        break;
      case 'people': // 인력 유형
        if (checkEmptyNull(_searchData, false)) body.peopleSharingTypeName = _searchData;
        await peopleSharingType.search('', body).then(response => {
          if (response === undefined) return;
          setSharingTypeList(() => { return response.data });
        })
        break;

      default: return;
    }
  };

  const getDataList = async (page, currentType) => { // 데이터 불러오기
    const id = Number(clusterId.replace('/', ''));
    // const paging = `?page=${page}&size=15&sort=id,DESC`;
    const body = { clusterId: id };

    switch (currentType) {
      case 'equipment': // 장비 속성
        if (checkEmptyNull(_sharingType, false)) body.equipmentSharingTypeId = Number(_sharingType);
        if (checkEmptyNull(_searchData, false)) body.equipmentSharingAttributeName = _searchData;
        await equipmentSharingAttribute.search('', body).then(response => {
          if (response === undefined) return;
          setDataList(() => { return response.data });
          setTotalSize(() => { return response.data.totalElements });
        })
        break;
      case 'people': // 인력 속성
        if (checkEmptyNull(_sharingType, false)) body.peopleSharingTypeId = Number(_sharingType);
        if (checkEmptyNull(_searchData, false)) body.peopleSharingAttributeName = _searchData;
        await peopleSharingAttribute.search('', body).then(response => {
          if (response === undefined) return;
          setDataList(() => { return response.data });
          setTotalSize(() => { return response.data.totalElements });
        })
        break;

      default: return;
    }
  };

  const actSearch = () => { // 검색
    setPageNumber(() => { return 1; });
    getDataList(0, _currentType);
  }

  const handleModalStatus = (type, data) => { // 모달 상태 변경(열기/닫기)
    setModalType(() => { return type });
    setModalData(() => { return data || {} });
    setTimeout(setModalStatus(() => { return true }), 1000);
  }

  return (<>
    <SettingComponent>
      <header>
        <h1>{_title}</h1>
        <button onClick={() => { handleModalStatus(`${_currentType}-attr-create`) }}>
          <CiCirclePlus />
          <p>속성 추가</p>
        </button>
      </header>
      <nav>
        <div className='nav-bar'>
          <p>유형</p>
          <select
            value={_sharingType || ''}
            onChange={(e) => { setSharingType(() => { return e.target.value; }); }}
          >
            <option value={''}>유형 선택</option>
            {checkNullArray(_sharingTypeList, []).map((sharingType, index) => {
              const sharingTypeId = `${_currentType}SharingTypeId`;
              const sharingTypeName = `${_currentType}SharingTypeName`;
              return (<option key={index + '_sharingType'} value={sharingType[sharingTypeId]}>{sharingType[sharingTypeName]}</option>);
            })}
          </select>
        </div>
        <div className='nav-bar'>
          <p>속성 이름</p>
          <input
            type='text'
            placeholder='검색어를 입력해주세요.'
            value={_searchData || ''}
            onInput={(e) => { setSearchData(() => { return e.target.value; }); }}
            onKeyDown={(e) => { if (e.key === 'Enter') actSearch(); }}
          />
        </div>
        <button onClick={actSearch}><IoSearch /></button>
      </nav>
      <div>
        <table>
          <colgroup>
            <col /><col /><col width={'180px'} />
          </colgroup>
          <thead>
            <tr>
              {_dataKeys.map((dataKey, index) => {
                return (<th key={index + '_th'}>{dataKey.title}</th>);
              })}
              <th></th>{/* 수정, 삭제 셀 */}
            </tr>
          </thead>
          <tbody>
            {!checkNullArray(_dataList, false) ? (<tr><td colSpan={_dataKeys.length + 1}>데이터가 없습니다.</td></tr>) :
              checkNullArray(_dataList, []).map((data, index) => {
                return (
                  <tr key={index + '_data'}>
                    {_dataKeys.map((dataKey, dataKeyIndex) => {
                      let dataValue = data[dataKey.key] || '-';
                      return (<td key={index + '_data_' + dataKeyIndex}>{dataValue}</td>);
                    })}
                    <td>
                      <div>
                        <div></div>
                        <button onClick={() => { handleModalStatus(`${_currentType}-attr-update`, data) }}><RiEditFill />수정</button>
                        <div></div>
                        <button onClick={() => { handleModalStatus(`${_currentType}-attr-delete`, data) }}><MdDeleteForever />삭제</button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {/* <ClusterPagination
        page={_pageNumber}
        count={_totalSize}
        size={15}
        pageEvent={(page) => { setPageNumber(() => { return page; }) }}
      /> */}
    </SettingComponent>

    {_modalStatus ? (
      <ClusterFormModal
        modalType={_modalType}
        modalData={_modalData}
        update={() => {
          console.log('update');
          getDataList(_pageNumber - 1, _currentType);
          setTimeout(setModalStatus(() => { return false }), 1000);
        }}
        open={_modalStatus}
        close={() => { setModalStatus(() => { return false }); }}
      />
    ) : null}
  </>);
};

export default MyPageSettingAttributes;