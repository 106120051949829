import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';

import { DateFormat } from 'components/format/DateFormat';
import CommaNum from 'components/format/CommaNum';
import GuideText from 'components/buttons/GuideText';
import StatusTdButton from 'components/buttons/StatusTdButton';

const ListBox = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
  width: 100%;
`;

const ListButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  margin: 0px auto;
  width: 100%;
`;

const NotInStockRow = (props) => {
  /* ========================================================================= #1 */
  /* ========================================================================= #2 */
  const [notInStockData, setNotInStockData] = useState(props.content);
  const [procurementData, setProcurementData] = useState(props.procurement);

  const [checkedItem, setCheckedItem] = useState(props.checkedItem);

  const [notInStockElements, setNotInStockElements] = useState(
    props.content.notInStockElementList,
  );
  const [_procurementElements, setProcurementElements] = useState(() => {
    const returnData = {};
    props.procurement.procurementElementList.forEach((element) => {
      if (element.element !== undefined && element.element !== null) {
        const elementData = element.element;
        returnData[elementData.elementId] = {
          ...elementData,
          procurementElement: element,
        };
      }
    });

    return returnData;
  });
  // console.log('notInStockElements : ', notInStockElements);
  // console.log('_procurementElements : ', _procurementElements);

  const [_rowLength, setRowLength] = useState(
    props.content.notInStockElementList.length,
  );

  /* ========================================================================= #3 */
  useEffect(() => {
    setNotInStockData(() => {
      return props.content;
    });
    setProcurementData(() => {
      return props.procurement;
    });

    setCheckedItem(() => {
      return props.checkedItem;
    });

    setNotInStockElements(() => {
      return props.content.notInStockElementList;
      // return props.procurement.procurementElementList;
    });
    setProcurementElements(() => {
      const returnData = {};
      props.procurement.procurementElementList.forEach((element) => {
        if (element.element !== undefined && element.element !== null) {
          const elementData = element.element;
          returnData[elementData.elementId] = {
            ...elementData,
            procurementElement: element,
          };
        }
      });

      return returnData;
    });

    setRowLength(props.content.notInStockElementList.length);
  }, [props]);

  /* ========================================================================= #4 */
  /* ========================================================================= #5 */
  /* ========================================================================= #6 */
  const returnElements = () => {
    let returnArray = [];
    for (let i = 1; i < _rowLength; i++) {
      returnArray.push(
        <tr key={i + '_notInStockElements'}>
          {notInStockElements[i] !== undefined ? (
            <Fragment>
              <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                {(() => {
                  if (
                    notInStockElements[i].elementName !== undefined &&
                    notInStockElements[i].elementName !== null
                  ) {
                    return notInStockElements[i].elementName;
                  } else if (
                    notInStockElements[i].element !== undefined &&
                    notInStockElements[i].element !== null &&
                    notInStockElements[i].element.elementName !== undefined &&
                    notInStockElements[i].element.elementName !== null
                  ) {
                    return notInStockElements[i].element.elementName;
                  } else {
                    return '';
                  }
                })()}
              </td>
              <td>
                {(() => {
                  if (
                    notInStockElements[i].elementCode !== undefined &&
                    notInStockElements[i].elementCode !== null
                  ) {
                    return notInStockElements[i].elementCode;
                  } else if (
                    notInStockElements[i].element !== undefined &&
                    notInStockElements[i].element !== null &&
                    notInStockElements[i].element.elementCode !== undefined &&
                    notInStockElements[i].element.elementCode !== null
                  ) {
                    return notInStockElements[i].element.elementCode;
                  } else {
                    return '';
                  }
                })()}
              </td>
              <td>
                {(() => {
                  if (
                    notInStockElements[i].scheduledInputStock !== undefined &&
                    notInStockElements[i].scheduledInputStock !== null
                  ) {
                    return (
                      <CommaNum
                        displayType={'text'}
                        num={notInStockElements[i].scheduledInputStock || 0}
                      />
                    );
                  } else if (
                    notInStockElements[i].element !== undefined &&
                    notInStockElements[i].element !== null &&
                    notInStockElements[i].element.scheduledInputStock !==
                      undefined &&
                    notInStockElements[i].element.scheduledInputStock !== null
                  ) {
                    return (
                      <CommaNum
                        displayType={'text'}
                        num={
                          notInStockElements[i].element.scheduledInputStock || 0
                        }
                      />
                    );
                  } else {
                    return 0;
                  }
                })()}
              </td>
              <td
                className={(() => {
                  if (
                    notInStockElements[i].elementSafeStock !== undefined &&
                    notInStockElements[i].elementSafeStock !== null &&
                    notInStockElements[i].elementStock !== undefined &&
                    notInStockElements[i].elementStock !== null
                  ) {
                    if (
                      notInStockElements[i].elementSafeStock <=
                      notInStockElements[i].elementStock
                    ) {
                      return 'safeStock';
                    } else {
                      return 'dangerStock';
                    }
                  } else if (
                    notInStockElements[i].element !== undefined &&
                    notInStockElements[i].element !== null &&
                    notInStockElements[i].element.elementStock !== undefined &&
                    notInStockElements[i].element.elementStock !== null &&
                    notInStockElements[i].element.elementStock !== undefined &&
                    notInStockElements[i].element.elementStock !== null
                  ) {
                    if (
                      notInStockElements[i].element.elementSafeStock <=
                      notInStockElements[i].element.elementStock
                    ) {
                      return 'safeStock';
                    } else {
                      return 'dangerStock';
                    }
                  } else {
                    return '';
                  }
                })()}
              >
                {(() => {
                  if (
                    notInStockElements[i].elementStock !== undefined &&
                    notInStockElements[i].elementStock !== null
                  ) {
                    return (
                      <CommaNum
                        displayType={'text'}
                        num={notInStockElements[i].elementStock || 0}
                      />
                    );
                  } else if (
                    notInStockElements[i].element !== undefined &&
                    notInStockElements[i].element !== null &&
                    notInStockElements[i].element.elementStock !== undefined &&
                    notInStockElements[i].element.elementStock !== null
                  ) {
                    return (
                      <CommaNum
                        displayType={'text'}
                        num={notInStockElements[i].element.elementStock || 0}
                      />
                    );
                  } else {
                    return 0;
                  }
                })()}
              </td>
              <td>
                {(() => {
                  if (
                    notInStockElements[i].element !== undefined &&
                    notInStockElements[i].element !== null &&
                    notInStockElements[i].element.price !== undefined &&
                    notInStockElements[i].element.price !== null
                  ) {
                    return (
                      <CommaNum
                        displayType={'text'}
                        num={notInStockElements[i].element.price || 0}
                      />
                    );
                  } else {
                    return 0;
                  }
                })()}
              </td>
              <td>
                {(() => {
                  if (
                    notInStockElements[i].element !== undefined &&
                    notInStockElements[i].element !== null
                  ) {
                    return (
                      <CommaNum
                        displayType={'text'}
                        num={
                          _procurementElements[
                            notInStockElements[i].element.elementId
                          ].procurementElement.quantity || 0
                        }
                      />
                    );
                  } else {
                    return 0;
                  }
                })()}
                {/* <CommaNum
                  displayType={'text'}
                  num={notInStockElements[i].quantity || 0}
                /> */}
              </td>
              <td>
                <CommaNum
                  displayType={'text'}
                  num={notInStockElements[i].price || 0}
                />
              </td>
              {/* <td>
                {(() => {
                  if (
                    notInStockElements[i].element !== undefined &&
                    notInStockElements[i].element !== null
                  ) {
                    return (
                      <CommaNum
                        displayType={'text'}
                        num={
                          _procurementElements[
                            notInStockElements[i].element.elementId
                          ].procurementElement.storeQuantity || 0
                        }
                      />
                    );
                  } else {
                    return 0;
                  }
                })()}
                <CommaNum
                  displayType={'text'}
                  num={notInStockElements[i].storeQuantity || 0}
                />
              </td> */}
              <td
                style={{
                  borderRight: '1px solid var(--gray-200)',
                  color: 'var(--MainRed)',
                  fontSize: '20px',
                  fontWeight: '600',
                }}
              >
                <CommaNum
                  displayType={'text'}
                  num={notInStockElements[i].notInStockQuantity || 0}
                />
              </td>
            </Fragment>
          ) : (
            <Fragment>
              <td
                className="blankCell"
                style={{ borderLeft: '1px solid var(--gray-200)' }}
              ></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
              <td className="blankCell"></td>
              {/* <td className="blankCell"></td> */}
              <td
                className="blankCell"
                style={{ borderRight: '1px solid var(--gray-200)' }}
              ></td>
            </Fragment>
          )}
        </tr>,
      );
    }

    return returnArray;
  };

  return (
    <Fragment>
      <tr
        name={props.authority.indexOf('203-2') !== -1 && '_notInStockRows'}
        // onClick={(e) => {
        //   if (props.authority.indexOf('203-2') !== -1)
        //     props.goDetails(notInStockData);
        // }}
      >
        <StatusTdButton
          rowSpan={_rowLength === 0 ? 1 : _rowLength}
          statusStyle={{
            minWidth: '100px',
            width: '100px',
          }}
          status={notInStockData.status}
          statusEvent={(e) => {
            e.stopPropagation();
            props.handleNotInStockStatus(notInStockData, props.index);
          }}
        />
        <td
          rowSpan={_rowLength === 0 ? 1 : _rowLength}
          style={{
            height:
              notInStockData.status !== 'wait' &&
              notInStockData.status !== 'waiting' &&
              notInStockData.status !== 'cancel'
                ? '200px'
                : '100px',
            minWidth: '100px',
            padding: 'unset',
            position: 'relative',
            width: '100px',
          }}
        >
          <ListBox>
            <ListButton
              type="print"
              onClick={(e) => {
                e.stopPropagation();
                props.actToProcurement(notInStockData, props.index);
              }}
            >
              발주
              <GuideText />
            </ListButton>
          </ListBox>
        </td>
        {props.authority.indexOf('203-2') !== -1 ? (
          <td
            rowSpan={_rowLength === 0 ? 1 : _rowLength}
            style={{ minWidth: '50px', width: '50px' }}
          >
            <input
              type="checkBox"
              name="notInStocks"
              data-key={notInStockData.notInStockId}
              checked={(() => {
                const checkedIndex = checkedItem
                  ? Array.from(checkedItem).findIndex(
                      (notInStock) =>
                        notInStock.notInStockId === notInStockData.notInStockId,
                    )
                  : -1;

                if (checkedIndex !== -1) {
                  return true;
                } else {
                  return false;
                }
              })()}
              onChange={(e) => {
                const isChecked = e.target.checked;
                props.handleCheckedItem(notInStockData, isChecked);

                // if (isChecked) {
                //   e.target.checked = true;
                // } else {
                //   e.target.checked = false;
                // }
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </td>
        ) : null}

        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {DateFormat(procurementData.createdDate)}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {DateFormat(procurementData.scheduledEndDate)}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {procurementData.procurementCode}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {procurementData.procurementName}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          {(() => {
            if (
              notInStockElements[0] !== undefined &&
              notInStockElements[0].account !== undefined &&
              notInStockElements[0].account !== null &&
              notInStockElements[0].account.accountName !== undefined &&
              notInStockElements[0].account.accountName !== null
            ) {
              return notInStockElements[0].account.accountName;
            } else {
              return '';
            }
            // if (
            //   procurementData.account !== undefined &&
            //   procurementData.account !== null
            // ) {
            //   return procurementData.account.accountName;
            // } else {
            //   return procurementData.accountName;
            // }
          })()}
        </td>
        <td rowSpan={_rowLength === 0 ? 1 : _rowLength}>
          <CommaNum
            displayType={'text'}
            num={procurementData.procurementPrice}
          />
        </td>

        {notInStockElements[0] !== undefined ? (
          <Fragment>
            <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
              {(() => {
                if (
                  notInStockElements[0].elementName !== undefined &&
                  notInStockElements[0].elementName !== null
                ) {
                  return notInStockElements[0].elementName;
                } else if (
                  notInStockElements[0].element !== undefined &&
                  notInStockElements[0].element !== null &&
                  notInStockElements[0].element.elementName !== undefined &&
                  notInStockElements[0].element.elementName !== null
                ) {
                  return notInStockElements[0].element.elementName;
                } else {
                  return '';
                }
              })()}
            </td>
            <td>
              {(() => {
                if (
                  notInStockElements[0].elementCode !== undefined &&
                  notInStockElements[0].elementCode !== null
                ) {
                  return notInStockElements[0].elementCode;
                } else if (
                  notInStockElements[0].element !== undefined &&
                  notInStockElements[0].element !== null &&
                  notInStockElements[0].element.elementCode !== undefined &&
                  notInStockElements[0].element.elementCode !== null
                ) {
                  return notInStockElements[0].element.elementCode;
                } else {
                  return '';
                }
              })()}
            </td>
            <td>
              {(() => {
                if (
                  notInStockElements[0].scheduledInputStock !== undefined &&
                  notInStockElements[0].scheduledInputStock !== null
                ) {
                  return (
                    <CommaNum
                      displayType={'text'}
                      num={notInStockElements[0].scheduledInputStock || 0}
                    />
                  );
                } else if (
                  notInStockElements[0].element !== undefined &&
                  notInStockElements[0].element !== null &&
                  notInStockElements[0].element.scheduledInputStock !==
                    undefined &&
                  notInStockElements[0].element.scheduledInputStock !== null
                ) {
                  return (
                    <CommaNum
                      displayType={'text'}
                      num={
                        notInStockElements[0].element.scheduledInputStock || 0
                      }
                    />
                  );
                } else {
                  return 0;
                }
              })()}
            </td>
            <td
              className={(() => {
                if (
                  notInStockElements[0].elementSafeStock !== undefined &&
                  notInStockElements[0].elementSafeStock !== null &&
                  notInStockElements[0].elementStock !== undefined &&
                  notInStockElements[0].elementStock !== null
                ) {
                  if (
                    notInStockElements[0].elementSafeStock <=
                    notInStockElements[0].elementStock
                  ) {
                    return 'safeStock';
                  } else {
                    return 'dangerStock';
                  }
                } else if (
                  notInStockElements[0].element !== undefined &&
                  notInStockElements[0].element !== null &&
                  notInStockElements[0].element.elementStock !== undefined &&
                  notInStockElements[0].element.elementStock !== null &&
                  notInStockElements[0].element.elementStock !== undefined &&
                  notInStockElements[0].element.elementStock !== null
                ) {
                  if (
                    notInStockElements[0].element.elementSafeStock <=
                    notInStockElements[0].element.elementStock
                  ) {
                    return 'safeStock';
                  } else {
                    return 'dangerStock';
                  }
                } else {
                  return '';
                }
              })()}
            >
              {(() => {
                if (
                  notInStockElements[0].elementStock !== undefined &&
                  notInStockElements[0].elementStock !== null
                ) {
                  return (
                    <CommaNum
                      displayType={'text'}
                      num={notInStockElements[0].elementStock || 0}
                    />
                  );
                } else if (
                  notInStockElements[0].element !== undefined &&
                  notInStockElements[0].element !== null &&
                  notInStockElements[0].element.elementStock !== undefined &&
                  notInStockElements[0].element.elementStock !== null
                ) {
                  return (
                    <CommaNum
                      displayType={'text'}
                      num={notInStockElements[0].element.elementStock || 0}
                    />
                  );
                } else {
                  return 0;
                }
              })()}
            </td>
            <td>
              {(() => {
                if (
                  notInStockElements[0].element !== undefined &&
                  notInStockElements[0].element !== null &&
                  notInStockElements[0].element.price !== undefined &&
                  notInStockElements[0].element.price !== null
                ) {
                  return (
                    <CommaNum
                      displayType={'text'}
                      num={notInStockElements[0].element.price || 0}
                    />
                  );
                } else {
                  return 0;
                }
              })()}
            </td>
            <td>
              {(() => {
                if (
                  notInStockElements[0].element !== undefined &&
                  notInStockElements[0].element !== null
                ) {
                  return (
                    <CommaNum
                      displayType={'text'}
                      num={
                        _procurementElements[
                          notInStockElements[0].element.elementId
                        ].procurementElement.quantity || 0
                      }
                    />
                  );
                } else {
                  return 0;
                }
              })()}
              {/* <CommaNum
                displayType={'text'}
                num={notInStockElements[0].quantity || 0}
              /> */}
            </td>
            <td>
              <CommaNum
                displayType={'text'}
                num={notInStockElements[0].price || 0}
              />
            </td>
            {/* <td>
              {(() => {
                if (
                  notInStockElements[0].element !== undefined &&
                  notInStockElements[0].element !== null
                ) {
                  return (
                    <CommaNum
                      displayType={'text'}
                      num={
                        _procurementElements[
                          notInStockElements[0].element.elementId
                        ].procurementElement.storeQuantity || 0
                      }
                    />
                  );
                } else {
                  return 0;
                }
              })()}
              <CommaNum
                displayType={'text'}
                num={notInStockElements[0].storeQuantity || 0}
              />
            </td> */}
            <td
              style={{
                borderRight: '1px solid var(--gray-200)',
                color: 'var(--MainRed)',
                fontSize: '20px',
                fontWeight: '600',
              }}
            >
              <CommaNum
                displayType={'text'}
                num={notInStockElements[0].notInStockQuantity || 0}
              />
            </td>
          </Fragment>
        ) : (
          <Fragment>
            <td
              className="blankCell"
              style={{ borderLeft: '1px solid var(--gray-200)' }}
            ></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
            <td className="blankCell"></td>
            {/* <td className="blankCell"></td> */}
            <td
              className="blankCell"
              style={{ borderRight: '1px solid var(--gray-200)' }}
            ></td>
          </Fragment>
        )}
      </tr>
      {_rowLength > 1 && returnElements()}
    </Fragment>
  );
};

export default NotInStockRow;
