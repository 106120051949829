const initialState = {
  authenticated: false,
  currentUser: null,
};

const loginReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'SETAUTHENTICATED':
      return {...state, authenticated: action.payload};

    case 'SETCURRENTUSER':
      return {...state, currentUser: action.payload};

    case 'LOGINRESET':
      return initialState;

    default:
      return state;
  }
}

export default loginReducer;