import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { notInStockActions_setNotInStockStatus, notInStockActions_setStartDate, notInStockActions_setEndDate, notInStockActions_setSearchOption, notInStockActions_setSearchText, notInStockActions_setPageNumber, notInStockActions_setTotalSize, notInStockActions_setIsPushedSearchButton, notInStockActions_setReset } from 'store/modules/actions/common/notInStockActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { notInStockApi } from 'api/apis/notInStockApi';

import { checkNullArray } from 'components/checkValues/checkValues';
import { LoadingMsg } from 'components/loading/LoadingMsg'
import Grid3Body from 'components/layouts/body/Grid3Body';
import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import NotInStockRow from 'components/row/NotInStockRow';
import NotInStockToProcurement from 'pages/procurement/management/modal/NotInStockToProcurement';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const NotInStockTable = styled.table`
  thead tr {border: none;}
  tbody tr {border-bottom: 1px solid var(--MainBlue);}
  tbody td {border: 1px solid var(--gray-200);height: fit-content;}
`;

const NotInStock = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notInStockReducer, pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_authority, setAuthority] = useState([]);

  const [_notInStockList, setNotInStockList] = useState([]);

  const [_checkedItem, setCheckedItem] = useState(new Set());

  const [_modalStatus, setModalStatus] = useState(false);
  const [_eventNotInStock, setEventNotInStock] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) => thisItem.authority.startsWith('203') === true);
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    setNotInStockTable(notInStockReducer.pageNumber - 1, notInStockReducer.status, notInStockReducer.isPushedSearchButton);

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (_onload === 'loaded') {
      setNotInStockTable(notInStockReducer.pageNumber - 1, notInStockReducer.status, notInStockReducer.isPushedSearchButton);
    }
    return () => { };
  }, [notInStockReducer.pageNumber]);

  useEffect(() => { }, [notInStockReducer]);

  /* ====================================================================== #4 */
  const setNotInStockTable = async (page, status, isPushedSearchButton) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;

    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (status !== undefined && status !== null && status !== '' && status !== 'all') {
      BodyToPost.status = status;
    }
    if (notInStockReducer.startDate !== undefined && notInStockReducer.endDate !== undefined && notInStockReducer.startDate !== '' && notInStockReducer.endDate !== '') {
      const startDate = new Date(notInStockReducer.startDate);
      const setStartDate = startDate.toISOString();
      BodyToPost.procurementStartDate = setStartDate;

      const endDate = new Date(notInStockReducer.endDate);
      endDate.setDate(endDate.getDate() + 1);
      const setEndDate = endDate.toISOString();
      BodyToPost.procurementEndDate = setEndDate;

      // 시작 날짜가 종료날짜보다 클 때
      if (notInStockReducer.startDate > notInStockReducer.endDate) {
        const reverseStartDate = new Date(notInStockReducer.startDate);
        reverseStartDate.setDate(reverseStartDate.getDate() + 1);
        const setReverseStartDate = reverseStartDate.toISOString();

        const reverseEndDate = new Date(notInStockReducer.endDate);
        const setReversetEndDate = reverseEndDate.toISOString();

        BodyToPost.procurementStartDate = setReversetEndDate;
        BodyToPost.procurementEndDate = setReverseStartDate;
      }
    }
    if (isPushedSearchButton && notInStockReducer.searchOption !== undefined && notInStockReducer.searchOption !== null && notInStockReducer.searchOption !== '') {
      BodyToPost[notInStockReducer.searchOption] = notInStockReducer.searchText;
    }
    await getNotInStockList(paging, BodyToPost);
  };
  const getNotInStockList = async (paging, BodyToPost) => {
    console.log('paging : ', paging);
    console.log('BodyToPost : ', BodyToPost);
    await notInStockApi.searchNotInStock(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('notInStockApi.searchNotInStock : ', response);
      setNotInStockList(() => { return response.data.content; });
      dispatch(notInStockActions_setTotalSize(response.data.totalElements));
    });
  };

  /* ====================================================================== #5 */
  const handleCheckedItem = (notInStock, isChecked) => {
    const checkedItem = new Set();
    if (isChecked) checkedItem.add(notInStock);
    setCheckedItem(() => { return checkedItem; });
  };

  /* 검색 */
  const actSearch = async () => {
    dispatch(notInStockActions_setPageNumber(1));
    dispatch(notInStockActions_setIsPushedSearchButton(true));
    await setNotInStockTable(0, notInStockReducer.procurementStatus, true);
  };

  /* 미입고 상태 변경 */
  const handleNotInStockStatus = async (notInStock, index) => {
    const notInStockIndex = index;
    console.log('handleNotInStockStatus - notInStock : ', notInStock);

    let eventStatusText = '';
    let eventStatus = '';
    switch (notInStock.status) {
      case 'waiting':
        eventStatusText = '진행';
        eventStatus = 'proceeding';
        break;

      case 'proceeding':
        eventStatusText = '완료';
        eventStatus = 'end';
        break;

      default: return;
    }

    const BodyToPut = {
      companyId: userReducer.company.companyId,
      notInStockId: notInStock.notInStockId,
      procurementId: notInStock.procurement.procurementId,
      status: eventStatus,

      notInStockElementList: notInStock.notInStockElementList.map((element) => {
        const returnData = {
          accountId: element.account !== undefined && element.account !== null && element.account.accountId,
          elementId: element.element !== undefined && element.element !== null && element.element.elementId,
          notInStockElementId: element.notInStockElementId,
          notInStockQuantity: element.notInStockQuantity,
          price: element.price,
        };
        return returnData;
      }),
      deleteNotInStockElementList: [],
    };

    console.log('BodyToPut : ', BodyToPut);

    // confirm
    const confirmText = `미입고 진행상태를 ${eventStatusText}시키겠습니까?`;
    if (window.confirm(confirmText) === true) {
      await notInStockApi.updateNotInStock(notInStock.notInStockId, BodyToPut).then((response) => {
        if (response === undefined) return;
        console.log('notInStockApi.updateNotInStock : ', response);
        setNotInStockList((prev) => {
          const returnData = [...prev];
          returnData.splice(notInStockIndex, 1, response.data);
          return returnData;
        });
      });
    } else {
      return;
    }
  };

  /* 미입고 -> 발주 추가 */
  const actToProcurement = (notInStock, index) => {
    setEventNotInStock(() => { return notInStock; });
    setTimeout(setModalStatus(true), 1000);
  };

  /* 삭제 */
  const actDelete = async () => {
    const checkedList = Array.from(_checkedItem);
    if (checkedList.length < 1) return alert('삭제할 미입고 정보를 선택해주세요.');
    const checkedContent = checkedList[0];
    console.log('checkedContents : ', checkedContent);
    navigate({
      pathname: pageReducer.currentPage + '/delete',
      search: `?${createSearchParams({ deleteContent: JSON.stringify(checkedContent) })}`,
      replace: true,
    });
  };

  /* ====================================================================== #6 */

  return (
    <Grid3Body contents={
      <>
        <main className="Main">
          <NavBar
            title={<NavTitle menuCode={'203'} />}
            buttons={
              <>
                {_authority.indexOf('203-2') !== -1 ? (
                  <button className='btn-delete' onClick={actDelete}>삭제</button>
                ) : null}
              </>
            }
            nav={'search'}
            firstRow={
              <>
                <div className="SearchSection" style={{ columnGap: '20px' }}>
                  <select
                    className="SelectStatus"
                    value={notInStockReducer.status}
                    onChange={(e) => {
                      dispatch(notInStockActions_setNotInStockStatus(e.target.value));
                      dispatch(notInStockActions_setPageNumber(1));
                      setNotInStockTable(0, e.target.value, notInStockReducer.isPushedSearchButton);
                    }}
                  >
                    {[
                      { optionValue: 'all', optionText: '전체' },
                      { optionValue: 'waiting', optionText: '대기' },
                      { optionValue: 'proceeding', optionText: '진행' },
                      { optionValue: 'end', optionText: '완료' },
                    ].map((thisItem) => {
                      return (<option key={thisItem.optionValue + '_notInStockStatus'} value={thisItem.optionValue}>{thisItem.optionText}</option>);
                    })}
                  </select>
                  <div className="SearchSection">
                    <input
                      type="date"
                      className="SelectDate"
                      value={notInStockReducer.startDate}
                      onChange={(e) => { dispatch(notInStockActions_setStartDate(e.target.value)); }}
                    />

                    <IconRightArrow />

                    <input
                      type="date"
                      className="SelectDate"
                      value={notInStockReducer.endDate}
                      onChange={(e) => { dispatch(notInStockActions_setEndDate(e.target.value)); }}
                    />
                  </div>

                  <div className="ContentCBox">
                    <div className="ContentCBox">
                      <select
                        className="SearchOption"
                        onChange={(e) => { dispatch(notInStockActions_setSearchOption(e.target.value)); }}
                      >
                        {[
                          { optionValue: '', optionText: '검색옵션' },
                          { optionValue: 'procurementCode', optionText: '발주코드' },
                          { optionValue: 'procurementName', optionText: '발주이름' },
                          { optionValue: 'accountName', optionText: '거래처명' },
                        ].map((thisItem) => {
                          return (<option key={thisItem.optionValue + '_notInStockSearchOption'} value={thisItem.optionValue}>{thisItem.optionText}</option>);
                        })}
                      </select>
                      <input
                        className="SearchBar"
                        placeholder="Search..."
                        value={notInStockReducer.searchText}
                        onInput={(e) => { dispatch(notInStockActions_setSearchText(e.target.value)); }}
                      />
                    </div>

                    <button data-searchbutton="true" onClick={actSearch}><SearchButtonIcon /></button>
                    <div className="ResetButton"
                      onClick={() => {
                        dispatch(notInStockActions_setReset());
                        setNotInStockTable(0, 'all', false);
                      }}
                    >
                      초기화
                    </div>
                  </div>
                </div>
              </>
            }
          />

          <TableSection content={
            <NotInStockTable>
              <thead>
                <tr>
                  <th rowSpan={2} style={{ minWidth: '100px', width: '100px' }}>진행상태</th>
                  {_authority.indexOf('203-2') !== -1 ? (
                    <Fragment>
                      <th rowSpan={2} style={{ minWidth: '100px', width: '100px' }}>발주</th>
                      <th rowSpan={2} style={{ minWidth: '50px', width: '50px' }}></th>
                    </Fragment>
                  ) : null}
                  <th rowSpan={2}>발주일자</th>
                  <th rowSpan={2}>입고예정일자</th>
                  <th rowSpan={2}>발주코드</th>
                  <th rowSpan={2}>발주이름</th>
                  <th rowSpan={2}>거래처</th>
                  <th rowSpan={2}>발주총금액</th>
                  <th colSpan={9} style={{ backgroundColor: 'var(--ThirdBlue)' }}>미입고 물품</th>
                </tr>
                <tr>
                  <th>물품이름</th>
                  <th>물품코드</th>
                  <th>투입예정수량</th>
                  <th>현재고</th>
                  <th>단가</th>
                  <th>수량</th>
                  <th>금액</th>
                  <th style={{ backgroundColor: 'var(--MainRed)', color: 'var(--white)' }}>미입고수량</th>
                </tr>
              </thead>
              <tbody>
                {!checkNullArray(_notInStockList, false) ? LoadingMsg() :
                  checkNullArray(_notInStockList, []).map((notInStock, index) => {
                    const procurement = notInStock.procurement;
                    return (
                      <NotInStockRow
                        key={index + '_notInStocks'}
                        authority={_authority}
                        index={index}
                        content={notInStock}
                        checkedItem={_checkedItem}
                        procurement={procurement}
                        actToProcurement={actToProcurement}
                        handleCheckedItem={handleCheckedItem}
                        handleNotInStockStatus={handleNotInStockStatus}
                      />
                    );
                  })}
              </tbody>
            </NotInStockTable>
          }
          />
        </main>
        <PagingComponent
          page={notInStockReducer.pageNumber}
          count={notInStockReducer.totalSize}
          size={10}
          pageEvent={(page) => {
            console.log('page : ', page);
            dispatch(notInStockActions_setPageNumber(page));
          }}
        />

        {_modalStatus === true ? (
          <NotInStockToProcurement
            notInStock={_eventNotInStock}
            open={_modalStatus}
            close={() => { setModalStatus(false); }}
          />
        ) : null}
      </>
    }
    />
  );
};

export default NotInStock;
