import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { barcodeActions_setPrintList } from 'store/modules/actions/common/barcodeActions';

import { elementStockLogApi } from 'api/apis/elementStockLogApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';
import { CategoryLevel1, CategoryLevel2, CategoryLevel3 } from 'components/category/Category';
import { DateFormat } from 'components/format/DateFormat';
import { DateTimeFormat } from 'components/format/DateTimeFormat';
import { LoadingMsg } from 'components/loading/LoadingMsg';

import IconRightArrow from 'components/icons/IconRightArrow';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import PagingComponent from 'components/paging/PagingComponent';
import SearchButtonIcon from 'components/icons/SearchButtonIcon';
import TableSection from 'components/layouts/table/TableSection';

const StockLogSection = styled.main`
  display: grid;
  grid-template-rows: auto minmax(calc(100% - 226px), calc(100% - 66px)) 60px;
  height: 100%;
  overflow: hidden;
  width: 100svw;

  & .Title {
    align-items: center;
    display: flex;
    gap: 10px;
    h4 {
      padding-right: 20px;
      margin-right: 10px;
      border-right: 1px solid #ddd;
      white-space: pre;
    }
    & .elementTypeButtons {
      display: flex;
      gap: 5px;
    }
  }

  & .TableSection {
    /* height: calc(100% - 66px); */
    overflow: scroll;

    & table {
      width: 100%;

      td, th {
        max-width: fit-content;
      }
    }
  }
`;

const StockLog = (props) => {
  const dispatch = useDispatch();
  const { barcodeReducer, userReducer } = useSelector(state => state);

  const { printList } = props;

  const StockLogCheckBox = useRef();

  const [_onload, setOnload] = useState('unload');

  const [_elementType, setElementType] = useState('all');

  const [_stockLogList, setStockLogList] = useState([]);

  const [_clickedCategory, setClickedCategory] = useState({});
  const [_clickedLevel1Category, setClickedLevel1Category] = useState({});
  const [_clickedLevel2Category, setClickedLevel2Category] = useState({});
  const [_clickedLevel3Category, setClickedLevel3Category] = useState({});

  const [_searchStockLogData, setSearchStockLogData] = useState({});

  const [_startDate, setStartDate] = useState('');
  const [_endDate, setEndDate] = useState('');

  const [_pageNum, setPageNum] = useState(1);
  const [_totalSize, setTotalSize] = useState(0);

  useEffect(() => {
    getStockLogList(0, 'all');
    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => {
    if (!checkNullArray(printList, false)) StockLogCheckBox.current.checked = false;
  }, [printList])
  useEffect(() => { }, [_stockLogList])

  useEffect(() => {
    if (_onload === 'loaded') {
      getStockLogList(_pageNum - 1, _elementType);
      StockLogCheckBox.current.checked = false;
    }
    return () => { };
  }, [_pageNum]);

  /* 패밀리 변경 */
  useEffect(() => {
    if (_onload === 'loaded') {
      if (_clickedCategory === 'all') {
        setPageNum(() => { return 1 });
        getStockLogList(0, _elementType);
      } else if (checkNullObject(_clickedCategory, false)) {
        setPageNum(() => { return 1 });
        getStockLogList(0, _elementType);
      }
    }
    return () => { };
  }, [_clickedCategory]);

  useEffect(() => {
    if (_onload === 'loaded') {
      if (_clickedCategory === 'all') {
        setClickedLevel2Category(() => { return {} });
      } else if (checkNullObject(_clickedCategory, false)) {
        setClickedLevel2Category(() => { return {} });
      }
    }
    return () => { };
  }, [_clickedLevel1Category]);
  useEffect(() => {
    if (_onload === 'loaded') {
      if (_clickedCategory === 'all') {
        setClickedLevel3Category(() => { return {} });
      } else if (checkNullObject(_clickedCategory, false)) {
        setClickedLevel3Category(() => { return {} });
      }
    }
    return () => { };
  }, [_clickedLevel2Category]);
  useEffect(() => { }, [_clickedLevel3Category]);

  /* 재고상세이력 리스트 */
  const getStockLogList = async (page, elementType, clear) => {
    const paging = `?page=${page}&size=10&sort=id,DESC`;

    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    if (!clear && checkEmptyNull(elementType, false) && elementType !== 'all') {
      BodyToPost.elementType = elementType;
    }
    if (!clear && checkEmptyNull(_clickedCategory, false) && _clickedCategory !== 'all') {
      BodyToPost.categoryId = _clickedCategory.categoryId;
    }
    if (!clear && checkEmptyNull(_searchStockLogData.elementName, false)) {
      BodyToPost.elementName = _searchStockLogData.elementName;
    }
    if (!clear && checkEmptyNull(_searchStockLogData.elementCode, false)) {
      BodyToPost.elementCode = _searchStockLogData.elementCode;
    }
    if (!clear && checkEmptyNull(_startDate, false) && checkEmptyNull(_endDate, false)) {
      BodyToPost.elementStockLogStartDate = DateFormat(_startDate);
      BodyToPost.elementStockLogEndDate = DateFormat(_endDate);
      if (_startDate > _endDate) {
        BodyToPost.elementStockLogStartDate = DateFormat(_endDate);
        BodyToPost.elementStockLogEndDate = DateFormat(_startDate);
      }
    } else if (!clear && checkEmptyNull(_startDate, false) && !checkEmptyNull(_endDate, false)) {
      BodyToPost.elementStockLogStartDate = DateFormat(_startDate);
    } else if (!clear && checkEmptyNull(_endDate, false) && !checkEmptyNull(_startDate, false)) {
      BodyToPost.elementStockLogEndDate = DateFormat(_endDate);
    }
    await elementStockLogApi.searchElementStockLog(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      setStockLogList(() => { return response.data.content });
      setTotalSize(() => { return response.data.totalElements });
    });
  };

  /* 물품유형 변경 */
  const handleElementType = (elementType) => {
    setElementType(() => { return elementType });

    if (elementType === 'all') {
      setClickedCategory(() => { return {} });
      setClickedLevel1Category(() => { return {} });
      setClickedLevel2Category(() => { return {} });
      setClickedLevel3Category(() => { return {} });
    }

    setPageNum(() => { return 1 });
    getStockLogList(0, elementType);
  }

  /* 검색 */
  const actSearch = async () => {
    setPageNum(() => { return 1 });
    await getStockLogList(0, _elementType);
  };

  /* 초기화 */
  const actReset = async () => {
    setElementType(() => { return 'all' });

    setClickedCategory(() => { return {} });
    setClickedLevel1Category(() => { return {} });
    setClickedLevel2Category(() => { return {} });
    setClickedLevel3Category(() => { return {} });

    setSearchStockLogData(() => { return {} });

    setStartDate(() => { return '' });
    setEndDate(() => { return '' });

    setPageNum(() => { return 1 });
    await getStockLogList(0, 'all', true);
  };

  /* 물품 전체 선택 */
  const handleAllCheckBox = (e) => {
    const isChecked = e.target.checked;
    const printList = [...barcodeReducer.printList];
    const newData = _stockLogList.map((stockLog) => {
      const thisFindIndex = printList.findIndex((item) => item.elementStockLogId === stockLog.elementStockLogId);
      if (thisFindIndex !== -1) return null;
      else return stockLog;
    });
    const filterData = newData.filter((data) => data !== null);
    const returnData = filterData.map(stockLog => { return { ...stockLog, type: 'stockLog' } })

    if (isChecked) {
      dispatch(barcodeActions_setPrintList([...printList, ...returnData]))
      e.target.checked = true;
    } else {
      dispatch(barcodeActions_setPrintList([...returnData]))
      e.target.checked = false;
    }
  }

  /* 물품 선택 */
  const handleCheckedItem = (stockLog, isChecked) => {
    const checkedItem = [...barcodeReducer.printList];
    // const checkedIndex = checkedItem.findIndex((content) => content.elementStockLogId === stockLog.elementStockLogId);
    const checkedIndex = checkedItem.findIndex((content) => content.type === 'stockLog' && content.elementStockLogId === stockLog.elementStockLogId);
    if (checkedIndex !== -1) {
      if (!isChecked) checkedItem.splice(checkedIndex, 1);
    } else {
      if (isChecked) checkedItem.push({ ...stockLog, type: 'stockLog' });
    }
    dispatch(barcodeActions_setPrintList(checkedItem))
  };


  return (
    <StockLogSection>
      <NavBar
        nav={'all'}
        title={
          <div className='Title'>
            <NavTitle menuCode={'305'} />
            <div className='elementTypeButtons'>
              {userReducer.elementType.map((elementType, index) => {
                let typeText = '';
                switch (elementType) {
                  case 'material': typeText = '자재'; break;
                  case 'semi': typeText = '부자재'; break;
                  case 'half': typeText = '반제품'; break;
                  case 'product': typeText = '완제품'; break;
                  case 'tool': typeText = '공구'; break;

                  default: return null;
                }
                return (<button key={index + '_elementTypeButtons'} className={_elementType === elementType ? 'btn-inven-1' : 'btn-set'} onClick={() => { handleElementType(elementType) }}>{typeText}</button>);
              })}
              <button className={_elementType === 'all' ? 'btn-inven-1' : 'btn-set'} onClick={() => { handleElementType('all') }}>전체</button>
            </div>
          </div>
        }
        firstRow={
          <>
            <div className="SearchSection">
              <CategoryLevel1
                clickedLevel1Category={_clickedLevel1Category}
                setClickedCategory={setClickedCategory}
                setClickedLevel1Category={setClickedLevel1Category}
              ></CategoryLevel1>

              <IconRightArrow />

              <CategoryLevel2
                clickedLevel1Category={_clickedLevel1Category}
                clickedLevel2Category={_clickedLevel2Category}
                setClickedCategory={setClickedCategory}
                setClickedLevel2Category={setClickedLevel2Category}
              ></CategoryLevel2>

              <IconRightArrow />

              <CategoryLevel3
                clickedLevel2Category={_clickedLevel2Category}
                clickedLevel3Category={_clickedLevel3Category}
                setClickedCategory={setClickedCategory}
                setClickedLevel3Category={setClickedLevel3Category}
              ></CategoryLevel3>
            </div>
          </>
        }
        secondRow={
          <>
            <div className="SearchSection">
              <div className="SearchSection">
                <input
                  type="date"
                  className="SelectDate"
                  value={_startDate}
                  onChange={(e) => { setStartDate(() => { return e.target.value }) }}
                />

                <IconRightArrow />

                <input
                  type="date"
                  className="SelectDate"
                  value={_endDate}
                  onChange={(e) => { setEndDate(() => { return e.target.value }) }}
                />
              </div>

              <div className="ContentCBox">
                <select className="SearchOption detail"><option value="elementName">물품이름</option></select>
                <input
                  className="SearchBar detail"
                  placeholder="Search..."
                  value={checkEmptyNull(_searchStockLogData.elementName, '')}
                  onInput={(e) => { setSearchStockLogData((prev) => { return { ...prev, elementName: e.target.value } }) }}
                />
              </div>

              <div className="ContentCBox">
                <select className="SearchOption detail"><option value="elementCode">물품코드</option></select>
                <input
                  className="SearchBar detail"
                  placeholder="Search..."
                  value={checkEmptyNull(_searchStockLogData.elementCode, '')}
                  onInput={(e) => { setSearchStockLogData((prev) => { return { ...prev, elementCode: e.target.value } }) }}
                />
              </div>

              <div className="ContentCBox">
                <div className="ResetButton detail" onClick={actReset}>초기화</div>

                <div className="DetailButton search"
                  style={{ backgroundColor: 'var(--ThirdBlue)', columnGap: '3px' }}
                  onClick={actSearch}
                >
                  <SearchButtonIcon />
                  검색
                </div>
              </div>
            </div>
          </>
        }
      />

      <TableSection content={
        <table>
          <thead>
            <tr>
              <th style={{ minWidth: '50px', width: '50px' }}>
                <input
                  type="checkBox"
                  ref={StockLogCheckBox}
                  name="allCheckBox"
                  onChange={handleAllCheckBox}
                />
              </th>
              <th>입력시각</th>
              <th>물품이름</th>
              <th>물품코드</th>
              <th>입고</th>
              <th>투입</th>
              <th>생산</th>
              <th>차감/출하</th>
              <th>금액</th>
              <th>유통기한</th>
              <th>재고위치</th>
              <th>비고</th>
              <th>작업자</th>
            </tr>
          </thead>
          <tbody>
            {!checkNullArray(_stockLogList, false) ? LoadingMsg() :
              checkNullArray(_stockLogList, []).map((stockLog, index) => {
                return (
                  <tr key={index + '_elements'}>
                    <td style={{ minWidth: '50px', width: '50px' }}>
                      <input
                        type="checkBox"
                        name="stockLogs"
                        data-key={stockLog.elementStockLogId}
                        checked={(() => {
                          const checkedId = checkNullArray(barcodeReducer.printList, []).findIndex((content) => content.type === 'stockLog' && content.elementStockLogId === stockLog.elementStockLogId);
                          if (checkedId !== -1) return true;
                          else return false;
                        })()}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          handleCheckedItem(stockLog, isChecked);
                        }}
                        onClick={(e) => { e.stopPropagation() }}
                      />
                    </td>
                    <td>{checkEmptyNull(stockLog.createdDate, false) ? DateTimeFormat(stockLog.createdDate) : ''}</td>
                    <td className={checkEmptyNull(stockLog.element, false) && checkEmptyNull(stockLog.element.elementName, false) && stockLog.element.elementName.startsWith('*') ? 'outsourcingText' : null}>
                      {stockLog.element.elementName}
                    </td>
                    <td>{stockLog.element.elementCode}</td>
                    <td style={{ color: 'var(--MainGreen)' }}>
                      {stockLog.logType === 'In' && checkEmptyNull(stockLog.amount, 0).toLocaleString() + ' ' + checkEmptyNull(stockLog.element.elementUnit, '')}
                    </td>
                    <td style={{ color: 'var(--MainYellow)' }}>
                      {stockLog.logType === 'Put' && checkEmptyNull(stockLog.amount, 0).toLocaleString() + ' ' + checkEmptyNull(stockLog.element.elementUnit, '')}
                    </td>
                    <td style={{ color: 'var(--MainBlue)' }}>
                      {stockLog.logType === 'Make' && checkEmptyNull(stockLog.amount, 0).toLocaleString() + ' ' + checkEmptyNull(stockLog.element.elementUnit, '')}
                    </td>
                    <td style={{ color: 'var(--MainRed)' }}>
                      {stockLog.logType === 'Out' && checkEmptyNull(stockLog.amount, 0).toLocaleString() + ' ' + checkEmptyNull(stockLog.element.elementUnit, '')}
                    </td>
                    <td>{checkEmptyNull(stockLog.price, 0).toLocaleString()}</td>
                    <td>{checkEmptyNull(stockLog.checkedDate, false) ? DateTimeFormat(stockLog.checkedDate) : ''}</td>
                    <td>{checkEmptyNull(stockLog.stockPlaceName, '')}</td>
                    <td>{stockLog.note}</td>
                    <td>{checkEmptyNull(stockLog.userName, '')}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      }
        sectionStyle={{ height: '100%' }}
      />

      <PagingComponent
        page={_pageNum}
        count={_totalSize}
        size={10}
        pageEvent={(page) => {
          console.log('page : ', page);
          setPageNum(() => { return page; });
        }}
      />
    </StockLogSection>
  );
};

export default StockLog;