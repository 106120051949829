import React, { Fragment, useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import ViewMinusButton from 'components/buttons/ViewMinusButton';
import ViewPlusButton from 'components/buttons/ViewPlusButton';
import ViewResetButton from 'components/buttons/ViewResetButton';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto;
  height: 90%;
  overflow: hidden;
  width: 98%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: inherit;
`;
const ModalHeaderButton = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  position: absolute;
  right: 60px;

  input {
    border: 2px solid var(--MainNavy);
    padding-inline: 10px;
    width: 150px;
  }
  button {padding: 4px 8px;}
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: grid;
  gap: 30px;
  height: 100%;
  padding-block: 30px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-200);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-200); border-radius: 6px;}
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const LoadingScreen = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  color: var(--white);
  display: flex;
  font-size: 30px;
  font-weight: 600;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const PrintSection = styled.div`
  display: grid;
  grid-template-rows: 25px 150px calc(100% - 175px);
  height: 297mm;
  margin: 0px auto;
  min-height: 297mm;
  overflow: hidden;
  position: relative;
  width: 210mm;
`;
const HeaderContents = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 28px;
  justify-content: flex-end;
  padding-right: 10px;
  position: relative;
  width: 100%;
  z-index: 600;
`;
const HeaderCon = styled.div`
  font-size: 0.7em;
`;

const WorkOrderTable = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  max-height: 150px;
  overflow: hidden;
  width: 210mm;

  table {
    background-color: var(--white);
    border-collapse: collapse;
    height: 100%;
    margin: 0px auto;
    table-layout: auto;
    width: 100%;

    td, th {
      border: 1px solid var(--gray-200);
      box-sizing: border-box;
      height: 24px;
      min-width: unset;
      padding-inline: 2px;
    }
    th {font-size: 0.55em; padding-inline: 0px}
    td {font-size: 0.7em; line-height: 1em;}
    & td.colors {
      font-size: 25px;
      font-weight: 600;
      outline: 4px solid var(--Text);
      outline-offset: -4px;
      text-align: center;
    }
    & td.remark {
      color: var(--ThirdBlue);
      font-size: 18px;
      padding: 5px 10px;
      text-align: left;
      vertical-align: top;
    }
  }
`;

const ProcessTable = styled.div`
  overflow: hidden;
  height: 100%;
  position: relative;
  width: 210mm;

  table {
    border-collapse: collapse;
    font-size: 1em;
    height: fit-content;
    left: 0px;
    line-height: 1.1em;
    max-height: 100%;
    position: absolute;
    table-layout: fixed;
    text-align: center;
    top: 0px;
    width: 100%;

    thead {
      background-color: var(--MainNavy);
      th {color: var(--white); height: 28px;}
    }

    td, th {
      border: 1px solid var(--gray-200);
      text-align: center;
      word-break: break-all;
    }

    td.materialStatus, td.colorStatus {
      p {font-size: 0.7em; padding: 1px; text-align: left;}
    }
  }
`; // 47.625mm
const DrawingCell = styled.div`
  display: grid;
  grid-template-rows: 40px 40px auto;
  height: 100%;
  width: 100%;

  div {
    align-items: center;
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  div[data-pos='Top'] {
    border: unset;
    display: grid;
    grid-template-columns: 50% 50%;

    p {
      align-items: center;
      border: 1px solid var(--gray-200);
      box-sizing: border-box;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;
    }
  }

  div[data-pos='Bottom'] {border: unset;}
`;
const Drawing = styled.div`
  align-items: center;
  background-color: var(--white);
  border: unset;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  img {max-height: 65px; max-width: 65px;}
`;

const AmountCell = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100%;
  width: 100%;
`;
const TaskCell = styled.div`
  align-self: flex-start;
  box-sizing: border-box;
  height: inherit;
  width: 100%;
  
  div[name='taskCells'] {
    align-items: center;
    border-bottom: 1px solid var(--gray-200);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;
    height: inherit;
    min-height: 85px;
    width: inherit;
    
    p[name='tasks'] {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      font-size: 0.9em;
      justify-content: center;
      line-height: 1em;
      width: fit-content;
    }
  }

  div[name='glassType_W'], div[name='glassType_H'] {
    border: none;
    border-bottom: 1px solid var(--gray-200);
    box-sizing: border-box;
    font-size: 0.7em;
    height: 100%;
    line-height: 1.1em;
    padding: unset;
    width: 100%;

    p {padding-block: 4px;}
  }
`;

const PrintDrawing = (props) => {
  const imgToBase64ByCanvas = async (thisfileDataS3URL) => {
    const newUrl = thisfileDataS3URL + '?' + new Date().getTime(); // ******************************** 중요!!!!!!!!!
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = newUrl;
      image.crossOrigin = 'Anonymous';
      image.onload = () => {
        let canvas = document.createElement('canvas');
        canvas.width = image.naturalWidth;
        canvas.height = image.naturalHeight;
        canvas.getContext('2d').drawImage(image, 0, 0);

        let imageSrc = canvas.toDataURL('image/png', 1.0);

        resolve(imageSrc);
      };
    });
  };

  const [_drawingSrc, setDrawingScr] = useState(() => {
    if (checkEmptyNull(props.content, false)) {
      imgToBase64ByCanvas(props.content).then((response) => {
        if (response === undefined) return;
        setDrawingScr(response);
        return response;
      }).catch((error) => {
        console.log('imgToBase64ByCanvas - error : ', error);
        return '';
      });
    } else {
      return '';
    }
  });

  return (checkEmptyNull(_drawingSrc, false) ? <img src={_drawingSrc} alt="" /> : null);
};

const PrintContent = (props) => {
  let materials_count = 0;
  let colors_count = 0;

  const taskTypeArray = ['BF', 'CP', 'SF', 'MF', 'GB', 'MC'];

  const [_printPage, setPrintPage] = useState(`${props.currentPage} / ${props.totalPage}`);
  const [_printDatas, setPrintDatas] = useState(props.content);
  const [_printInfo, setPrintInfo] = useState(() => {
    const returnData = {
      ...props.info,
      workOrderCustomizedContent: checkNullParse(props.info.workOrderCustomizedContent, {
        BF: { date: '', amount: '' },
        SF: { date: '', amount: '' },
        MF: { date: '', amount: '' },
        total: { date: '', amount: '' },

        // workOrderProducts: '', // 품목
        colors: '', // 색상
        phoneNumber: '', // 전화번호
      }),
    }
    return returnData;
  });

  useEffect(() => {
    materials_count = 0;
    colors_count = 0;

    setPrintPage(`${props.currentPage} / ${props.totalPage}`);
    setPrintDatas(props.content);
    setPrintInfo(() => {
      const returnData = {
        ...props.info,
        workOrderCustomizedContent: checkNullParse(props.info.workOrderCustomizedContent, {
          BF: { date: '', amount: '' },
          SF: { date: '', amount: '' },
          MF: { date: '', amount: '' },
          total: { date: '', amount: '' },

          // workOrderProducts: '', // 품목
          colors: '', // 색상
          phoneNumber: '', // 전화번호
        }),
      }
      return returnData;
    })
  }, [props])

  return (
    <PrintSection name={props.name}>
      <HeaderContents><HeaderCon>{_printPage}</HeaderCon></HeaderContents>

      <WorkOrderTable>
        <table>
          <colgroup>
            <col width='50px'></col>
            <col width='350px'></col>
            <col width='50px'></col>
            <col width='200px'></col>
            <col width='50px'></col>
            <col width='100px'></col>
            <col width='50px'></col>
            <col width='50px'></col>
          </colgroup>
          <tbody>
            <tr>
              <th rowSpan={3}>색상</th>
              <td rowSpan={3} className='colors' style={{ border: '4px solid var(--Text)' }}>
                {_printInfo?.workOrderCustomizedContent.colors}
              </td>
              <th>거래처</th>
              <td>{_printInfo.accountName}</td>
              <th>B/F납기</th>
              <td>{checkNullObject(_printInfo?.workOrderCustomizedContent?.BF, false) ? _printInfo?.workOrderCustomizedContent?.BF?.date : _printInfo?.workOrderCustomizedContent.BFDate}</td>
              <th>B/F</th>
              <td>{checkNullObject(_printInfo?.workOrderCustomizedContent?.BF, false) ? _printInfo?.workOrderCustomizedContent?.BF?.amount : _printInfo?.workOrderCustomizedContent.BFAmount}</td>
            </tr>
            <tr>
              <th>전화번호</th>
              <td>{_printInfo?.workOrderCustomizedContent.phoneNumber}</td>
              <th>S/F납기</th>
              <td>{checkNullObject(_printInfo?.workOrderCustomizedContent?.SF, false) ? _printInfo?.workOrderCustomizedContent?.SF?.date : _printInfo?.workOrderCustomizedContent.TotalDate}</td>
              <th>S/F</th>
              <td>{checkNullObject(_printInfo?.workOrderCustomizedContent?.SF, false) ? _printInfo?.workOrderCustomizedContent?.SF?.amount : _printInfo?.workOrderCustomizedContent.TotalAmount}</td>
            </tr>
            <tr>
              <th>주소</th>
              <td>{_printInfo.workPlace}</td>
              <th>M/F납기</th>
              <td>{_printInfo?.workOrderCustomizedContent?.MF?.date}</td>
              <th>M/F</th>
              <td>{_printInfo?.workOrderCustomizedContent?.MF?.amount}</td>
            </tr>
            <tr>
              <th rowSpan={2}>비고</th>
              <td colSpan={3} rowSpan={2} className='remark'>{_printInfo.remark}</td>
              <th>지시일자</th>
              <td colSpan={3}>{DateFormat(checkEmptyNull(_printInfo.workOrderScheduledStartDate, ''))}</td>
            </tr>
            <tr>
              <th>작성자</th>
              <td colSpan={3}>{_printInfo.userName}</td>
            </tr>
          </tbody>
        </table>
      </WorkOrderTable>

      <ProcessTable>
        <table style={{ fontSize: `${props.zoomScale}%` }}>
          <colgroup>
            <col width='30px'></col>
            <col width='70px'></col>
            <col width='95px'></col>
            <col width='95px'></col>

            <col width='65px'></col>
            <col width='65px'></col>
            <col width='65px'></col>
            <col width='65px'></col>
            <col width='65px'></col>

            <col width='80px'></col>

            <col width='65px'></col>
          </colgroup>
          <thead>
            <tr>
              <th>NO</th>
              <th>도면</th>
              <th>자재</th>
              <th>색상</th>
              <th>B/F</th>
              <th>C/P</th>
              <th>S/F</th>
              <th>M/F</th>
              <th>G/B</th>
              <th>유리종류</th>
              <th>M/C</th>
            </tr>
          </thead>

          <tbody>
            {checkNullArray(_printDatas, []).map((processLog, index) => {
              const processCustomizedContent = checkNullParse(processLog.processCustomizedContent, {
                numStatus: '',
                bundle: '',
                bundleSize: '',
                materialStatus: '',
                materials: '',
                colorStatus: '',
                colors: '',
                glassType_W: '',
                glassType_H: '',
                remark: '',
              });

              return (
                <tr key={index + '_workOrder'}>
                  <td className={`numStatus ${processCustomizedContent.numStatus}`} data-key={index}>
                    {((index + 1) + (props.itemPerPage * (props.currentPage - 1))).toString().padStart(2, '0')}
                  </td>

                  <td style={{ padding: 'unset', verticalAlign: 'top' }}>
                    <DrawingCell>
                      <div data-pos="Top">
                        <p>{processCustomizedContent.bundle}</p>
                        <p>{processLog.processingCount}</p>
                      </div>

                      <div data-pos="Middle">
                        {processCustomizedContent.bundleSize}
                      </div>

                      <div data-pos="Bottom">
                        <Drawing id={'Drawing_' + processLog.processLogId}>
                          <PrintDrawing content={checkNullArray(processLog?.attachFileList, false) && checkNullArray(processLog?.attachFileList, false).length > 0
                            ? processLog?.attachFileList[0]?.fileData?.fileDataS3URL
                            : checkNullArray(processLog?.drawingList, false) && checkNullArray(processLog?.drawingList, false).length > 0
                              ? processLog?.drawingList[0]?.fileData?.fileDataS3URL
                              : null
                          } />
                        </Drawing>
                      </div>
                    </DrawingCell>
                  </td>

                  <td className={`materialStatus ${processCustomizedContent.materialStatus}`}>
                    {checkEmptyNull(processCustomizedContent.materials, false) ?
                      processCustomizedContent.materials.split(processCustomizedContent.materials.indexOf('\n') === -1 ? ' ' : '\n')
                        .map((thisMaterial) => {
                          materials_count++;
                          return (<p key={index + materials_count + thisMaterial}>{thisMaterial}</p>);
                        })
                      : null}
                  </td>

                  <td className={`colorStatus ${processCustomizedContent.colorStatus}`}>
                    {checkEmptyNull(processCustomizedContent.colors, false) ?
                      processCustomizedContent.colors.split(processCustomizedContent.colors.indexOf('\n') === -1 ? ' ' : '\n')
                        .map((thisColor) => {
                          colors_count++;
                          return (<p key={index + colors_count + thisColor}>{thisColor}</p>);
                        })
                      : null}
                  </td>

                  {taskTypeArray.map((thisType) => {
                    let thisWContent = [];
                    let thisHContent = [];
                    if (checkNullArray(processLog.taskLogList, false)) {
                      thisWContent = processLog.taskLogList.filter((thisItem) => thisItem.taskType === thisType + '_W');
                      thisHContent = processLog.taskLogList.filter((thisItem) => thisItem.taskType === thisType + '_H');
                    }

                    let glassCount_W = 0;
                    let glassCount_H = 0;
                    let remarkCount = 0;

                    return (
                      <Fragment key={index + thisType + 'smallProcesses'}>
                        <td style={{ padding: 'unset' }}>
                          <AmountCell>
                            <TaskCell>
                              <div name="taskCells">
                                {checkNullArray(thisWContent, []).map((thisAmountW) => {
                                  return (<p key={index + thisType + '_W' + thisAmountW.taskLogId} name="tasks">{thisAmountW.taskName}</p>);
                                })}
                              </div>
                            </TaskCell>

                            <TaskCell>
                              <div name="taskCells">
                                {checkNullArray(thisHContent, []).map((thisAmountH) => {
                                  return (<p key={index + thisType + '_H' + thisAmountH.taskLogId} name="tasks">{thisAmountH.taskName}</p>);
                                })}
                              </div>
                            </TaskCell>
                          </AmountCell>
                        </td>

                        {thisType === 'GB' && (
                          <td style={{ padding: 'unset' }}>
                            <AmountCell>
                              <TaskCell>
                                <div name="glassType_W">
                                  {checkEmptyNull(processCustomizedContent.glassType_W, false) ?
                                    processCustomizedContent.glassType_W.split(processCustomizedContent.glassType_W.indexOf('\n') === -1 ? ' ' : '\n')
                                      .map((thisItem) => {
                                        glassCount_W++;
                                        return (<p key={index + 'glassType_W' + glassCount_W}>{thisItem}</p>);
                                      })
                                    : null}
                                </div>
                              </TaskCell>

                              <TaskCell>
                                <div name="glassType_H">
                                  {checkEmptyNull(processCustomizedContent.glassType_H, false) ?
                                    processCustomizedContent.glassType_H.split(processCustomizedContent.glassType_H.indexOf('\n') === -1 ? ' ' : '\n')
                                      .map((thisItem) => {
                                        glassCount_H++;
                                        return (<p key={index + 'glassType_H' + glassCount_H}>{thisItem}</p>);
                                      })
                                    : null}
                                </div>
                              </TaskCell>
                            </AmountCell>
                          </td>
                        )}
                      </Fragment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </ProcessTable>
    </PrintSection>
  );
};

const UriWorkOrderPdfModalV2 = (props) => {
  /* ====================================================================== #1 */
  /* ====================================================================== #2 */
  const { content } = props;

  const [_processLogList, setProcessLogList] = useState(() => {
    const processLogList = content.processLogList;
    processLogList.sort((a, b) => { return a.processLogId - b.processLogId });
    return processLogList;
  });
  const [_pdfContents, setPdfContents] = useState(() => {
    if (!checkNullArray(_processLogList, false)) return [];
    const pdfContents = [];
    for (let i = 0; i < _processLogList.length; i += 5) {
      pdfContents.push(_processLogList.slice(i, i + 5));
    }
    return pdfContents;
  });
  const [_itemPerPage, setItemPerPage] = useState(5);
  const [_zoomScale, setZoomScale] = useState(100);

  const [_loadingStatus, setLoadingStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => { }, [_pdfContents]);

  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  const handleItemPerPage = (e) => {
    let itemPerPage = Number(e.target.value);
    setItemPerPage(() => { return !isNaN(itemPerPage) ? itemPerPage : 5 });
  }
  const handleZoomScale = (e) => {
    let zoomScale = Number(e.target.value);
    setZoomScale(() => { return !isNaN(zoomScale) ? zoomScale : 100 });
  }

  const actPrint = () => {
    console.log(document.getElementsByName('PrintContents'));
    console.log('================================================');
    const style = document.createElement('style');
    document.head.appendChild(style);
    style.sheet?.insertRule('body > div:last-child img { display: inline-block; }');

    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
    });

    document.getElementsByName('PrintContents').forEach(async (thisItem, index) => {
      const thisHeight = thisItem.offsetHeight;
      const thisWidth = thisItem.offsetWidth;
      const ratio = thisWidth / thisHeight;

      const option = { height: thisHeight, width: thisWidth };

      await html2canvas(thisItem, option).then((canvas) => {
        const image = canvas.toDataURL('image/png');
        let width = doc.internal.pageSize.getWidth();
        let height = 290;
        width = ratio * height;

        const margin = 2;
        const position = 2;

        doc.addImage(image, 'jpeg', margin, position, width, height);
        if (index < document.getElementsByName('PrintContents').length - 1) doc.addPage();
      });

      if (index === document.getElementsByName('PrintContents').length - 1) {
        if (document.getElementsByName('PrintContents').length > 6) {
          doc.save();
          setLoadingStatus(false);
        } else {
          window.open(doc.output('bloburl'));
          setLoadingStatus(false);
        }
      }
    });
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>작업지시 미리보기</ModalTitle>
              <ModalHeaderButton>
                <p>페이지당 공정 출력 수 : </p>
                <input
                  type='number'
                  min={1}
                  value={_itemPerPage.toLocaleString()}
                  onInput={handleItemPerPage}
                />
                <button className='btn-set' style={{ backgroundColor: 'var(--MainNavy)', color: 'var(--white)', marginRight: '10px' }}
                  onClick={() => {
                    setPdfContents(() => {
                      if (!checkNullArray(_processLogList, false)) return [];
                      const pdfContents = [];
                      for (let i = 0; i < _processLogList.length; i += _itemPerPage) {
                        pdfContents.push(_processLogList.slice(i, i + _itemPerPage));
                      }
                      return pdfContents;
                    })
                  }}>적용</button>

                <p>글자 크기 : </p>
                <input
                  type='number'
                  min={1}
                  value={_zoomScale.toLocaleString()}
                  onInput={handleZoomScale}
                />
                <ViewMinusButton
                  range={2}
                  viewScale={_zoomScale}
                  handleViewScale={(scale) => { setZoomScale(() => { return scale }) }}
                />
                <ViewPlusButton
                  range={2}
                  viewScale={_zoomScale}
                  handleViewScale={(scale) => { setZoomScale(() => { return scale }) }}
                />
                <ViewResetButton
                  handleViewScale={() => { setZoomScale(() => { return 100 }) }}
                />
              </ModalHeaderButton>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              {checkNullArray(_pdfContents, []).map((thisItem, index) => {
                return (
                  <PrintContent
                    key={index + '_PrintContents'}
                    name="PrintContents"
                    totalPage={_pdfContents.length}
                    currentPage={index + 1}
                    content={thisItem}
                    info={content}
                    itemPerPage={_itemPerPage}
                    zoomScale={_zoomScale}
                  />
                );
              })}
            </ModalMain>

            <ModalFooter>
              <ModalButton onClick={() => { setLoadingStatus(true); setTimeout(actPrint, 1000); }}>출력</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}

      {_loadingStatus === true ? (<LoadingScreen>파일 생성 중입니다...</LoadingScreen>) : null}
    </>
  );
};

export default UriWorkOrderPdfModalV2;
