import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { operatingActions_setUserData } from 'store/modules/actions/operating/operatingActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { getCookie } from 'preferences/cookie/cookie';

import { OperatingMainComponent } from '../styles/main.style';
import OperatingHeader from '../components/OperatingHeader';
import OperatingSideBar from '../components/OperatingSideBar';

export default function OperatingMain({ children }) {
  const location = useLocation();
  const operatingId = location.pathname.split('/platform/operating')[0];
  const operatingPath = operatingId + '/platform/operating';
  console.log('operatingPath : ', operatingPath);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { operatingReducer } = useSelector(state => state);

  useEffect(() => {
    const splits = location.pathname.split('/');
    const last = splits[splits.length - 1];
    if (last === 'operating') return navigate(operatingPath, { replace: true });
  }, [])
  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));
    if (!getCookie('accessToken')) navigate(operatingPath + '/sign/login');
    getMyData();
  }, [location.pathname])
  useEffect(() => {
    if (getCookie('accessToken')) {
      console.log('operatingReducer.user : ', operatingReducer.user);
      // navigate(clusterPath + '/sign/login');
    }
  }, [operatingReducer.user]);

  const getMyData = async () => { // 소공인 정보 불러오기
    const headers = { headers: { Authorization: getCookie('accessToken') } };
    await axios.get('/any/oneSelf', headers).then(async (response) => {
      if (response === undefined) return;
      console.log('mydata : ', response);
      dispatch(operatingActions_setUserData(response.data));
    });
  };

  return (
    <OperatingMainComponent>
      <OperatingHeader />
      <section className='main'>
        <OperatingSideBar path={location.pathname} />
        <Outlet {...children} />
      </section>
    </OperatingMainComponent>
  );
};
