import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { qna } from 'api/apis/mall/qna';

import { checkEmptyNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import { FileContent } from 'mall/styles/style';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const MallProductQnA = () => {
  /* ====================================================================== #1 */
  const navigate = useNavigate();
  const location = useLocation();
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const companyCode = location.pathname.split('/')[1];

  const [searchParams, setSearchParams] = useSearchParams();
  const mallElementList = checkNullParse(searchParams.get('mallElementList'), []);

  const [_formData, setFormData] = useState({
    companyId: userReducer.user.companyId,
    companyCode: companyCode,
    status: 'waiting',
    userId: userReducer.user.userId,
    userName: userReducer.user.userName,
    mallQnAContent: '',
    mallQnAElementList: [],
    attachFileList: [],
  });

  const [_mallQnAElementList, setMallQnAElementList] = useState(mallElementList);
  const [_fileDataList, setFileDataList] = useState([]);

  /* ====================================================================== #3 */
  /* ====================================================================== #4 */
  /* ====================================================================== #5 */
  // 문의 내용
  const handleInputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => { return { ...prev, [name]: value }; });
  };
  // 문의 물품 삭제
  const actDeleteQnAElement = (e, index, element) => {
    setMallQnAElementList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);
      return prevData;
    });
  };
  // 파일 이벤트
  const handleFileEvent = (type, e) => {
    let files = [];
    switch (type) {
      case 'click': files = e.target.files; break;
      case 'drag': files = e.dataTransfer.files; break;
      default: return;
    }
    if (!checkNullArray(files, false)) return;
    const setFiles = Array.prototype.slice.call(files);
    setFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onload = () => {
          const returnData = file;
          returnData['preview'] = reader.result;
          setFileDataList((prev) => { return [...prev, returnData]; });
          resolve();
        };
      });
    });
  };
  // 파일 삭제
  const actDeleteFileData = (file, index) => {
    setFileDataList((prev) => {
      const returnData = [...prev];
      returnData.splice(index, 1);
      return returnData;
    });
  };

  /* 추가 */
  const actCreate = async (e) => {
    e.preventDefault();

    const BodyToPut = { ..._formData, mallQnAElementList: _mallQnAElementList };
    console.log('BodyToPut : ', BodyToPut);
    const postFormData = new FormData();
    postFormData.append('key', new Blob([JSON.stringify(BodyToPut)], { type: 'application/json' }));

    if (_fileDataList.length > 0) {
      _fileDataList.forEach((file) => {
        if (!checkEmptyNull(file.attachFileId, false)) postFormData.append('multipartFileList', file);
      });
    }
    for (let values of postFormData.values()) {
      console.log('postFormData : ', values);
    }

    await qna.createMallQnA(postFormData).then((response) => {
      if (response === undefined) return;
      console.log('qna.createMallQnA : ', response);
      alert(`
상품을 문의했습니다.
문의내역 페이지로 이동합니다.
      `);
      navigate(`/${companyCode}/mall/mypage/qna/list`, { replace: true });
    });
  };
  /* ====================================================================== #6 */

  return (
    <>
      <div className='qna-wrap'>
        <ul>
          <li>
            <p>상품이미지</p>
            <p>상품코드</p>
            <p>상품이름</p>
            <p>단가</p>
            <p>삭제</p>
          </li>
          {_mallQnAElementList.map((element, index) => {
            return (
              <li key={index + '_qnaElements'}>
                <div className='image'>
                  {(() => {
                    if (checkNullObject(element.attachFileList, false)) {
                      const fileData = element.attachFileList[0];
                      return <img key={index + '_fileDataImg'} src={fileData.fileData.fileDataS3URL} alt={fileData.fileData.fileDataName} />;
                    } else {
                      return <img src="https://i-shop.link/assets/images/no-image.png" alt="상품 썸네일 사진" />
                    }
                  })()}
                </div>
                <p>{element.mallElementCode}</p>
                <p>{element.mallElementName}</p>
                <p>{`${checkEmptyNull(element.mallElementPrice, 0).toLocaleString('ko-kr')}`}</p>
                <button onClick={(e) => { actDeleteQnAElement(e, index, element); }}><CloseButtonIcon /></button>
              </li>
            );
          })}
        </ul>
      </div>
      <div className='qna-wrap'>
        <div className="form">
          <h3>문의정보</h3>
          <textarea
            type="text"
            name="mallQnAContent"
            placeholder="문의 내용을 이곳에 작성해주세요."
            value={_formData.mallQnAContent}
            onInput={handleInputEvent}
          />
          <div className='file'>
            <input
              type="file" id="fileInput" multiple style={{ display: 'none' }}
              onChange={(e) => { handleFileEvent('click', e); }}
            />
            <label
              htmlFor="fileInput"
              onDragEnter={(e) => { e.stopPropagation(); e.preventDefault(); }}
              onDragLeave={(e) => { e.stopPropagation(); e.preventDefault(); }}
              onDragOver={(e) => { e.stopPropagation(); e.preventDefault(); }}
              onDrop={(e) => { e.preventDefault(); handleFileEvent('drag', e); }}
            >
              <div className='file-icon'></div>
              <p>첨부 파일 드래그 또는 <span>선택</span></p>
            </label>

            <ol>
              {_fileDataList.map((fileData, index) => {
                return (
                  <FileContent key={index + '_fileDatas'} className="horizontal" style={{ marginTop: index === 0 ? '20px' : '' }}
                    title='파일을 클릭하면 삭제됩니다.'
                    onClick={() => { actDeleteFileData(fileData, index); }}
                  >
                    {(() => {
                      const returnArray = [];
                      if (fileData.attachFileId === undefined) {
                        if (fileData.type && fileData.type.startsWith('image')) {
                          returnArray.push(<img key={index + '_fileDataImg'} src={fileData.preview} alt={fileData.name} />);
                        } else {
                          returnArray.push(<Fragment key={index + '_fileDataImg'}>{fileData.name}</Fragment>);
                        }
                      } else {
                        if (fileData.fileData) {
                          if (fileData.fileData.fileDataType && fileData.fileData.fileDataType.startsWith('image')) {
                            returnArray.push(<img key={index + '_fileDataImg'} src={fileData.fileData.fileDataS3URL} alt={fileData.fileData.fileDataName} />);
                          } else {
                            returnArray.push(<Fragment key={index + '_fileDataImg'}>{fileData.fileData.fileDataName}</Fragment>);
                          }
                        } else {
                          returnArray.push(<Fragment key={index + '_fileDataImg'}></Fragment>);
                        }
                      }
                      return returnArray;
                    })()}
                  </FileContent>
                );
              })}
            </ol>
          </div>
        </div>
      </div>
      <button className="submit" onClick={actCreate}>문의하기</button>
    </>
  );
};

export default MallProductQnA;
