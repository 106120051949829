import React, { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

import ShowInputElementStockResultLog from 'pages/stockHistory/inputElementStockLog/ShowInputElementStockResultLog';
import TableSection from 'components/layouts/table/TableSection';

const LotMain = styled.div`
  display: grid;
  gap: 10px;
  grid-template-rows: ${(props => {
    return props.mobile ? '300px 60px auto' : '300px 60px calc(100% - 370px)';
  })};
  height: 100%;
  position: relative;
  width: 100%;

  & .sec-lot {
    align-content: flex-start;
    background-color: var(--white);
    box-sizing: border-box;
    display: grid;
    gap: 20px;
    padding: 20px;
    width: 100%;

    & .contents-lot {
      display: grid;
      grid-gap: 20px;

      & .con-lot {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: flex-start;
  
        & h4 {
          border-right: 2px solid var(--gray-100);
          width: 100px;
        }
        & p {
          box-sizing: border-box;
          font-size: 0.9em;
        }
      }
    }
  }

  & .sec-buttons {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    width: 100%;

    & button {
      color: var(--white);
      &.Put, &.Make {background-color: var(--MainNavy);}
      /* &.Put {background-color: var(--MainYellow);}
      &.Make {background-color: var(--MainBlue);} */
    }
  }

  & .sec-lotList {
    height: 100%;
    width: 100%;

    & .TableSection {
      & .cell-inputElementStockLog {
        width: 60px;
      }
      & .failedReason {
        text-align: left;
        & b {
          margin-right: 10px;
          font-weight: 600;
        }
      }
    }
  }
`;

const LotInfo = (props) => {
  const mobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  const [_lotData, setLotData] = useState(props.lotData);
  const [_lotConData, setLotConData] = useState(props.lotConData);

  const [_putElements, setPutElements] = useState(props.putElements);
  const [_makeElements, setMakeElements] = useState(props.makeElements);

  const [_inputElementStockLogModalStatus, setInputElementStockLogModalStatus] = useState(false);
  const [_inputElementStockLog, setInputElementStockLog] = useState({
    processLogElement: {},
    inputElementStockLogs: []
  })

  const [_currentProcessResultElementType, setCurrentProcessResultElementType] = useState('Put');

  useEffect(() => {
    setLotData(() => { return props.lotData });
    setLotConData(() => { return props.lotConData });

    setPutElements(() => { return props.putElements });
    setMakeElements(() => { return props.makeElements });
  }, [props]);

  return (
    <>
      <LotMain mobile={mobile()}>
        <section className='sec-lot'>
          <div className='contents-lot'>
            {checkNullArray(_lotConData, []).map((conData, index) => {
              return (
                <div key={index + '_conData'} className='con-lot'>
                  <h4>{conData.key}</h4>
                  <p>{conData.value}</p>
                </div>
              )
            })}
          </div>
        </section>
        <section className='sec-buttons'>
          {[
            {type: 'Put', name: '투입 자재/반제품'},
            {type: 'Make', name: '공정완료제품'},
          ].map(processResultElementType => {
            return (
              <button key={processResultElementType.type + '_processResultElementType'} 
              className={processResultElementType.type === _currentProcessResultElementType ? 'btn-set ' + processResultElementType.type : 'btn-set'}
              onClick={e => {
                e.preventDefault();
                setCurrentProcessResultElementType(() => {return processResultElementType.type});
              }}
              >
                {processResultElementType.name}
              </button>
            )
          })}
        </section>
        <section className='sec-lotList'>
          <TableSection content={
            <table>
              <thead>
                <tr>
                  {(_currentProcessResultElementType === 'Put' ? [
                    {name: '투입추적', color: ''},
                    {name: '물품이름', color: ''},
                    {name: '물품코드', color: ''},
                    {name: '총 투입량', color: 'var(--MainYellow)'},
                    {name: '불량', color: 'var(--ThirdRed)'},
                  ] : [
                    {name: '물품이름', color: ''},
                    {name: '물품코드', color: ''},
                    {name: '목표 생산량', color: ''},
                    {name: '총 생산량', color: 'var(--MainBlue)'},
                    {name: '성공수량', color: 'var(--MainGreen)'},
                    {name: '실패수량', color: 'var(--MainRed)'},
                    {name: '불량', color: 'var(--ThirdRed)'},
                  ]).map((key, index) => {
                    return (<th key={index + '_lot_key'} style={{ backgroundColor: key.color }}>{key.name}</th>)
                  })}
                </tr>
              </thead>
              <tbody>
                {(_currentProcessResultElementType === 'Put' ? checkNullArray(_putElements, []) 
                : checkNullArray(_makeElements, [])).map((processResultElement, index) => {
                  const completedAmount = checkEmptyNull(BigNumber(checkEmptyNull(processResultElement.makeAmount, 0))
                  .plus(BigNumber(checkEmptyNull(processResultElement.failedAmount, 0))).toNumber(), 0).toLocaleString();

                  return (
                    <tr key={index + '_lot'}>
                      {_currentProcessResultElementType === 'Put'? <>
                        <td className='cell-inputElementStockLog'>
                          <button className='btn-inven-3' onClick={e => {
                            e.preventDefault();
                            setInputElementStockLog(() => {
                              return {
                                processLogElement: checkNullObject(processResultElement, {}),
                                inputElementStockLogs: checkNullArray(processResultElement.inputResultElementStockLogs, [])
                              }
                            })
                            setTimeout(setInputElementStockLogModalStatus(() => {return true}), 1000)
                          }}>투입추적</button>
                        </td>
                        <td className={processResultElement.elementName.startsWith('*') ? 'outsourcingText' : null}>{processResultElement.elementName}</td>
                        <td>{processResultElement.elementCode}</td>
                        <td>{checkEmptyNull(processResultElement.amount, 0).toLocaleString()}</td>
                        <td>
                          {checkNullArray(processResultElement.processResultElementFailedReasons, []).map((failedReason, failedReasonIndex) => {
                            return(
                              <div key={failedReasonIndex + '_failedReason'} className='failedReason'>
                                <b>[ {checkEmptyNull(failedReason.amount, 0).toLocaleString()} ]</b>
                                <span>{failedReason.failedReason}</span>
                              </div>
                            )
                          })}
                        </td>
                      </> : <>
                        <td className={processResultElement.elementName.startsWith('*') ? 'outsourcingText' : null}>{processResultElement.elementName}</td>
                        <td>{processResultElement.elementCode}</td>
                        <td>{checkEmptyNull(processResultElement.amount, 0).toLocaleString()}</td>
                        <td>{checkEmptyNull(completedAmount, 0).toLocaleString()}</td>
                        <td>{checkEmptyNull(processResultElement.makeAmount, 0).toLocaleString()}</td>
                        <td>{checkEmptyNull(processResultElement.failedAmount, 0).toLocaleString()}</td>
                        <td>
                          {checkNullArray(processResultElement.processResultElementFailedReasons, []).map((failedReason, failedReasonIndex) => {
                            return(
                              <div key={failedReasonIndex + '_failedReason'} className='failedReason'>
                                <b>[ {checkEmptyNull(failedReason.amount, 0).toLocaleString()} ]</b>
                                <span>{failedReason.failedReason}</span>
                              </div>
                            )
                          })}
                        </td>
                      </>}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          }
          />
        </section>
      </LotMain>

      {_inputElementStockLogModalStatus === true && (
        <ShowInputElementStockResultLog
          content={_inputElementStockLog}
          open={_inputElementStockLogModalStatus}
          close={() => { setInputElementStockLogModalStatus(false) }}
        ></ShowInputElementStockResultLog>
      )}
    </>
  );
};

export default LotInfo;