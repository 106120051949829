import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { modalFileActions_setFileReset } from 'store/modules/actions/modal/modalFileActions';

import { attachFileApi } from 'api/apis/attachFileApi';
import { processTypeApi } from 'api/apis/processTypeApi';
import { processApi } from 'api/apis/processApi';

import { checkEmptyNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import SelectElements from 'pages/element/modal/SelectElements';
import SelectFiles from 'pages/file/modal/SelectFiles';
import SelectWorkPlaceModal from 'pages/types/modal/SelectWorkPlaceModal';
import TableSection from 'components/layouts/table/TableSection';

const Section = styled.main`
  .placeBox {
    &>div:first-child {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      width: 100%;

      & .formButton {
        background-color: var(--ThirdBlue);
        border-radius: 5px;
        width: 150px;
      }
    }
    &>div:last-child {
      column-gap: 5px;
      display: grid;
      grid-template-columns: auto 50px;
      width: 100%;

      & .formButton {
        background-color: var(--MainNavy);
        border-radius: 5px;
        height: 40px;
        width: 50px;
      }
    }

    .place {
      background-color: var(--ThirdBlue);
      color: var(--white);
      &::placeholder {color: var(--white);}
    }
  }
  .TableSection {
    table {
      td, th {padding-block: 5px;}
    }
  }
`;

const FileList = styled.div`
  width: 100%;
`;
const FileBox = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  height: 40px;
  justify-content: flex-start;
  margin: 5px auto;
  padding: 0px 15px;
  position: relative;
  width: 100%;
`;
const DeleteFileButton = styled.div`
  height: 15px;
  position: absolute;
  right: 15px;
  width: 15px;
  div {background-color: var(--white); cursor: pointer; height: 15px; width: 15px;}
`;
const DelButton = styled.div`
  height: 15px;
  margin: 0px auto;
  width: 15px;
  div {background-color: var(--MainRed); cursor: pointer; height: 15px; width: 15px;}
`;

const CopyProcesses = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [searchParams, setSearchParams] = useSearchParams();
  const prevData = JSON.parse(searchParams.get('copyContent'));
  const { processingType, processTypeId, processStatus, processId, processCode, processName, place, remark, processElementList } = prevData;

  const prevProcessElementList = processElementList.map((element) => {
    if (element.processElementType !== 'Put' && element.processElementType !== 'Make') return null;
    const reutrnData = {
      ...element,
      processElementType: element.processElementType,
      processElementId: element.processElementId,
      amount: element.amount,
    };
    if (checkNullObject(element.element, false)) {
      if (reutrnData.elementId === null) reutrnData.elementId = element.element.elementId;
    }
    return reutrnData;
  });

  const [_onload, setOnload] = useState('unload');
  const [_authority, setAuthority] = useState([]);

  const [_dataType, setDataType] = useState();

  const [_formData, setFormData] = useState({
    companyId: userReducer.company.companyId,

    existOutElement: 0,
    processingType: checkEmptyNull(processingType, 'auto'),

    processTypeId: processTypeId,
    processStatus: processStatus,

    processCode: processCode,
    processName: processName,

    processingCount: 0,
    processAmount: 0,
    processCustomizedContent: JSON.stringify({}),

    remark: remark,

    processElementList: prevProcessElementList.filter((element) => element !== null),
  });

  const [_processTypes, setProcessTypes] = useState([]);

  const [_putElements, setPutElements] = useState(() => {
    const prevElmeents = prevProcessElementList.filter((element) => element !== null);
    const elements = prevElmeents.map((element) => {
      if (element.processElementType === 'Make') return null;
      const reutrnData = {
        ...element,
        processElementType: element.processElementType,
        processElementId: element.processElementId,
        amount: element.amount,
      };
      if (checkNullObject(element.element, false)) {
        if (reutrnData.elementId === null) reutrnData.elementId = element.element.elementId;
      }
      return reutrnData;
    });

    return elements.filter((element) => element !== null);
  });
  const [_makeElements, setMakeElements] = useState(() => {
    const prevElmeents = prevProcessElementList.filter((element) => element !== null);
    const elements = prevElmeents.map((element) => {
      if (element.processElementType === 'Put') return null;
      const reutrnData = {
        ...element,
        processElementType: element.processElementType,
        processElementId: element.processElementId,
        amount: element.amount,
      };
      if (checkNullObject(element.element, false)) {
        if (reutrnData.elementId === null) reutrnData.elementId = element.element.elementId;
      }
      return reutrnData;
    });

    return elements.filter((element) => element !== null);
  });
  const [_modalStatus, setModalStatus] = useState(false);

  const [_fileList, setFileList] = useState([]);
  const [_fileModalStatus, setFileModalStatus] = useState(false);

  const [_workPlace, setWorkPlace] = useState(checkNullObject(place, {}));
  const [_workPlaceModalStatus, setWorkPlaceModalStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    const authorityContent = userReducer.user.team.teamRoleList.filter((thisItem) =>
      thisItem.authority.startsWith('112') === true || // 공정정의
      thisItem.authority.startsWith('109') === true, // 파일
    );
    const authorityCodes = authorityContent.map((thisItem) => thisItem.authority);
    setAuthority(authorityCodes);

    getProcessTypes();
    getFileList();

    setOnload('loaded');
    return () => { };
  }, []);

  useEffect(() => { }, [_workPlace, _putElements, _makeElements]);

  /* ====================================================================== #4 */
  const getProcessTypes = async () => {
    const BodyToPost = { companyId: userReducer.company.companyId };
    await processTypeApi.searchProcessType(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('processTypeApi.searchProcessType : ', response);
      setProcessTypes(() => { return response.data; });
    });
  };

  const getFileList = async () => {
    const BodyToPost = { companyId: userReducer.company.companyId, processId: processId };
    await attachFileApi.searchAttachFile('', BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('attachFileApi.searchAttachFile : ', response);
      setFileList(() => { return response.data.content; });
    });
  };

  /* ====================================================================== #5 */
  const handleChangeEvent = (e) => {
    const { name, value } = e.target;
    setFormData({ ..._formData, [name]: value });
  };

  const actDeleteFile = (e, index, file) => {
    setFileList((prev) => {
      const prevData = [...prev];
      prevData.splice(index, 1);
      return prevData;
    });
  };

  const handleElementAmount = (e, index, element) => {
    const { name, value } = e.target;

    let eventValue = value;
    if (!eventValue.toString().startsWith('0.')) eventValue = eventValue.toString().replace(/^0+/, '');
    if (/* eventValue === '' ||  */eventValue < 0) eventValue = 0;
    if (eventValue.length > 15) return;

    let processElementType = null;
    switch (name) {
      case 'put_quantity': processElementType = 'Put'; break;
      case 'make_quantity': processElementType = 'Make'; break;

      default: return;
    }

    const eventItem = {
      ...element,
      processElementType: processElementType,
      amount: eventValue,
    };

    if (processElementType === 'Put') {
      setPutElements((prev) => {
        const prevData = [...prev];
        prevData.splice(index, 1, eventItem);
        return prevData;
      });
    } else if (processElementType === 'Make') {
      setMakeElements((prev) => {
        const prevData = [...prev];
        prevData.splice(index, 1, eventItem);
        return prevData;
      });
    }
  };
  const actAdd = (e) => {
    const { type } = e.target.dataset;
    setDataType(type);
    setTimeout(setModalStatus(true), 1000);
  };
  const actDelete = (index, element) => {
    const delElementType = element.processElementType;

    const putEvent = () => {
      setPutElements((prev) => {
        const returnData = [...prev];
        if (returnData.length > 1) returnData.splice(index, 1);
        else returnData.splice(0, returnData.length);
        console.log('returnData : ', returnData);
        return returnData;
      });
    };
    const makeEvent = () => {
      setMakeElements((prev) => {
        const returnData = [...prev];
        if (returnData.length > 1) returnData.splice(index, 1);
        else returnData.splice(0, returnData.length);
        console.log('returnData : ', returnData);
        return returnData;
      });
    };

    switch (delElementType) {
      case 'Put': putEvent(); break;
      case 'Make': makeEvent(); break;

      default: return;
    }

    const setList = _formData.processElementList.filter((thisItem) => thisItem.elementId !== element.elementId);
    setFormData({ ..._formData, processElementList: setList });
  };

  const actCopy = async (e) => {
    e.preventDefault();

    if (!checkEmptyNull(_formData.processCode, false)) return alert('공정코드를 입력해주세요.');
    if (!checkEmptyNull(_formData.processName, false)) return alert('공정이름을 입력해주세요.');

    const BodyToPost = { ..._formData };

    // processElement 설정
    const putElements = [..._putElements].filter((element) => element.amount > 0);
    if (_putElements.length !== putElements.length) return alert('투입량을 확인해 주세요.')

    let makeElements = [..._makeElements];
    if (_formData.processingType !== 'passive') {
      const filterMakeElements = makeElements.filter(element => element.amount > 0);
      if (makeElements.length !== filterMakeElements.length) return alert('생산량을 확인해 주세요.')
    } else {
      makeElements = makeElements.map((element) => {
        const reutrnData = { ...element, amount: null };
        return reutrnData;
      })
    }
    const filterMakeElements = makeElements.filter((element) => element !== null);
    const processElementList = [...putElements, ...filterMakeElements];
    BodyToPost.processElementList = processElementList;

    if (checkNullObject(_workPlace, false)) BodyToPost.placeId = _workPlace.stockPlaceId;

    await processApi.createProcess(BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('processApi.createProcess : ', response);

      if (_fileList.length > 0) {
        _fileList.forEach(async (file) => {
          const BodyToFilePost = {
            companyId: userReducer.company.companyId,
            fileDataId: file.fileDataId,
            processId: response.data.processId,
          };
          await attachFileApi.createAttachFile(BodyToFilePost).then((response) => {
            if (response === undefined) return;
            console.log('attachFileApi.createAttachFile : ', response);
          });
        });
      }

      alert('공정을 추가했습니다.');
      navigate(pageReducer.currentPage, { replace: true });
    });
  };

  /* ====================================================================== #6 */

  return (
    <Grid2Body contents={
      <>
        <Section className="Main">
          <NavBar title={<NavTitle menuCode={'112'} />} nav={''} />

          <Form
            title={
              <>
                <NavTitle menuCode={'112'} />추가
                <p style={{ color: 'var(--MainNavy)', fontSize: '16px', fontWeight: '400', margin: '10px 0px' }}>
                  * 복사된 정보입니다.
                </p>
              </>
            }
            buttons={
              <>
                <button className="formButton" onClick={actCopy}>저장</button>
                <button className="formButton cancle"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(pageReducer.currentPage, { replace: true });
                  }}
                >
                  취소
                </button>
              </>
            }
            forms={
              <>
                <div className="formBox">
                  <h4>공정완료제품 처리방식</h4>
                  <select
                    name="processingType"
                    value={checkEmptyNull(_formData.processingType, 'auto')}
                    onChange={(e) => { setFormData((prev) => { return { ...prev, processingType: e.target.value }; }); }}
                  >
                    <option value="auto">선처리 (목표관리)</option>
                    <option value="passive">후처리 (결과관리)</option>
                  </select>
                </div>

                <div className="formBox w100">
                  <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                    <h4>공정완료제품</h4>
                    <div className="formButton"
                      style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                      data-type="Make"
                      onClick={actAdd}
                    >
                      공정완료제품 추가
                    </div>
                  </div>
                  <TableSection content={
                    <table>
                      <thead style={{ backgroundColor: 'var(--MainNavy' }}>
                        <tr>
                          <th>물품이름</th>
                          <th>물품코드</th>
                          {(_formData.processingType !== 'passive') ? <th>생산량</th> : null}
                          <th style={{ minWidth: '50px', width: '50px' }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {checkNullArray(_makeElements, []).map((thisItem, index) => {
                          return (
                            <tr key={thisItem.elementId + '_MakeElements'} data-key={thisItem.elementId} data-type="Make">
                              <td className={checkEmptyNull(thisItem.elementName, false) && thisItem.elementName.startsWith('*') ? 'outsourcingText' : null}>
                                {thisItem.elementName}
                              </td>
                              <td>{thisItem.elementCode}</td>
                              {(_formData.processingType !== 'passive') ? (
                                <td>
                                  <input
                                    type="number"
                                    name="make_quantity"
                                    data-elementid={thisItem.elementId}
                                    min={0}
                                    value={checkEmptyNull(thisItem.amount, 0)}
                                    onChange={(e) => { handleElementAmount(e, index, thisItem) }}
                                  />
                                </td>
                              ) : null}
                              <td style={{ minWidth: '50px', width: '50px' }}>
                                <DelButton data-type="Make" onClick={() => { actDelete(index, thisItem) }}><CloseButtonIcon /></DelButton>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  }
                    formSectionStyle={{ paddingTop: '10px', width: '100%' }}
                  />
                  {_formData.processingType !== 'passive' &&
                    <div className="cautionText" style={{ fontSize: '14px', textAlign: 'right', width: '100%' }}>
                      ※ 생산량을 꼭! 입력해 주세요.
                    </div>
                  }
                </div>

                <div className="formBox w100">
                  <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                    <h4>투입 자재/반제품</h4>
                    <div className="formButton"
                      style={{ backgroundColor: 'var(--SeconYellow)', borderRadius: '5px', width: '150px' }}
                      data-type="Put"
                      onClick={actAdd}
                    >
                      투입 자재/반제품 추가
                    </div>
                  </div>
                  <TableSection content={
                    <table>
                      <thead style={{ backgroundColor: 'var(--MainNavy' }}>
                        <tr>
                          <th>물품이름</th>
                          <th>물품코드</th>
                          <th>투입량</th>
                          <th style={{ minWidth: '50px', width: '50px' }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {checkNullArray(_putElements, []).map((thisItem, index) => {
                          return (
                            <tr key={thisItem.elementId + '_PutElements'} data-key={thisItem.elementId} data-type="Put">
                              <td className={checkEmptyNull(thisItem.elementName, false) && thisItem.elementName.startsWith('*') ? 'outsourcingText' : null}>
                                {thisItem.elementName}
                              </td>
                              <td>{thisItem.elementCode}</td>
                              <td>
                                <input
                                  type="number"
                                  name="put_quantity"
                                  data-elementid={thisItem.elementId}
                                  min={0}
                                  value={checkEmptyNull(thisItem.amount, 0)}
                                  onChange={(e) => { handleElementAmount(e, index, thisItem) }}
                                />
                              </td>
                              <td style={{ minWidth: '50px', width: '50px' }}>
                                <DelButton data-type="Put" onClick={() => { actDelete(index, thisItem) }}><CloseButtonIcon /></DelButton>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  }
                    formSectionStyle={{ paddingTop: '10px', width: '100%' }}
                  />
                  <div className="cautionText" style={{ fontSize: '14px', textAlign: 'right', width: '100%' }}>
                    ※ 투입량을 꼭! 입력해 주세요.
                  </div>
                </div>

                <div className="formBox">
                  <h4>공정유형</h4>
                  <select
                    name="processTypeName"
                    value={checkEmptyNull(_formData.processTypeId, '')}
                    onChange={(e) => { setFormData((prev) => { return { ...prev, processTypeId: e.target.value }; }); }}
                  >
                    <option value="">공정유형</option>
                    {_processTypes.map((thisType) => {
                      return (<option key={thisType.processTypeId} value={thisType.processTypeId}>{thisType.processTypeName}</option>);
                    })}
                  </select>
                </div>

                <div className="formBox">
                  <h4>공정코드</h4>
                  <input
                    type="data"
                    name="processCode"
                    placeholder="* 공정코드..."
                    data-required="required"
                    value={_formData.processCode}
                    onInput={handleChangeEvent}
                  />
                </div>

                <div className="formBox">
                  <h4>공정이름</h4>
                  <input
                    type="text"
                    name="processName"
                    placeholder="* 공정이름..."
                    data-required="required"
                    value={_formData.processName}
                    onInput={handleChangeEvent}
                  />
                </div>

                <div className="formBox placeBox">
                  <div>
                    <h4>작업 위치</h4>
                    <div className="formButton" onClick={() => { setWorkPlaceModalStatus(true); }}>위치 선택</div>
                  </div>
                  <div>
                    <input
                      type="text"
                      className='place'
                      name="workPlace"
                      placeholder="* 작업 위치..."
                      readOnly
                      value={checkEmptyNull(_workPlace.placeName, '')}
                    />
                    <div className="formButton" onClick={() => { setWorkPlace(() => { return {}; }); }}>삭제</div>
                  </div>
                </div>

                <div className="formBox">
                  <h4>비고</h4>
                  <textarea
                    name="remark"
                    placeholder="비고..."
                    value={checkEmptyNull(_formData.remark, '')}
                    onInput={handleChangeEvent}
                  ></textarea>
                </div>

                {(_authority.indexOf('109-1') !== -1 || _authority.indexOf('109-2') !== -1) ? (
                  <div className="formBox">
                    <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '30px', width: '100%' }}>
                      <h4>파일</h4>
                      <div className="formButton"
                        style={{ backgroundColor: 'var(--ThirdBlue)', borderRadius: '5px', width: '150px' }}
                        onClick={() => {
                          dispatch(modalFileActions_setFileReset());
                          setTimeout(setFileModalStatus(true), 1000);
                        }}
                      >
                        파일 선택
                      </div>
                    </div>

                    <FileList>
                      {_fileList.map((thisItem, index) => {
                        let fileData = { ...thisItem };
                        if (checkNullObject(thisItem.fileData, false)) fileData = { ...thisItem, ...thisItem.fileData };
                        return (
                          <FileBox key={fileData.fileDataId + '_files'}>
                            {fileData.fileDataName}
                            <DeleteFileButton onClick={(e) => { actDeleteFile(e, index, fileData) }}><CloseButtonIcon /></DeleteFileButton>
                          </FileBox>
                        );
                      })}
                    </FileList>
                  </div>
                ) : null}
              </>
            }
          />
        </Section>

        {_modalStatus === true && (
          <SelectElements
            buttonEvent={(data) => {
              console.log('data : ', data);
              if (_dataType === 'Put') {
                const putItemIdList = _putElements.map((thisItem) => thisItem.elementId);
                const newPutItem = data.map((thisItem) => {
                  if (putItemIdList.indexOf(thisItem.elementId) !== -1) return null;
                  else {
                    const returnData = {
                      ...thisItem,
                      processElementType: 'Put',
                      amount: 0
                    };
                    return returnData;
                  }
                });
                const setNewPutItem = newPutItem.filter((thisItem) => thisItem !== null);
                setPutElements((prev) => {
                  const returnData = [...prev, ...setNewPutItem];
                  return returnData;
                });
              } else if (_dataType === 'Make') {
                const outItemIdList = _makeElements.map((thisItem) => thisItem.elementId);
                const newOutItem = data.map((thisItem) => {
                  if (outItemIdList.indexOf(thisItem.elementId) !== -1) return null;
                  else {
                    const returnData = {
                      ...thisItem,
                      processElementType: 'Make',
                      amount: 0
                    };
                    return returnData;
                  }
                });
                const setNewOutItem = newOutItem.filter((thisItem) => thisItem !== null);
                setMakeElements((prev) => {
                  const returnData = [...prev, ...setNewOutItem];
                  return returnData;
                });
              }
              setModalStatus(false);
            }}
            buttonTitle="물품 선택"
            open={_modalStatus}
            close={() => { setModalStatus(false); }}
          />
        )}

        {_fileModalStatus === true && (
          <SelectFiles
            buttonTitle={'파일 선택'}
            buttonEvent={(data) => {
              const newDataList = data.filter((thisItem) => _fileList.findIndex((thisIndex) => thisIndex.fileDataId === thisItem.fileDataId) === -1);
              setFileList((prev) => { return [...prev, ...newDataList] });
              setTimeout(setFileModalStatus(false), 1000);
            }}
            open={_fileModalStatus}
            close={() => { setFileModalStatus(false) }}
          />
        )}

        {_workPlaceModalStatus === true && (
          <SelectWorkPlaceModal
            buttonTitle={'위치 선택'}
            buttonEvent={(data) => {
              setWorkPlace(() => { return data });
              setTimeout(setWorkPlaceModalStatus(false), 1000);
            }}
            open={_workPlaceModalStatus}
            close={() => { setWorkPlaceModalStatus(false) }}
          />
        )}
      </>
    }
    />
  );
};

export default CopyProcesses;
