import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 85%;
  justify-content: flex-start;
  /* max-height: 300px;
  max-width: 300px;
  min-width: 200px; */
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100% - 55px);
  justify-content: flex-start;
  padding: 20px;
  row-gap: 20px;
  width: 100%;

  table {
    border-collapse: collapse;
    font-size: 3em;
    font-weight: 600;
    height: 100%;
    table-layout: fixed;
    text-align: center;
    width: 100%;

    td,
    th {
      border: 1px solid var(--gray-200);
    }

    th {
      background-color: var(--MainNavy);
      color: var(--white);
    }

    td {
      &.normal {
        background-color: var(--MainGreen);
        color: var(--white);
      }
      &.high {
        background-color: var(--MainRed);
        color: var(--white);
      }
      &.low {
        background-color: var(--ThirdBlue);
        color: var(--white);
      }
    }
  }
`;

const HsSensorDataModal = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const { tempName, tempKey, PV, SV, HIGH, LOW } = props.tempData;

  /* ====================================================================== #3 */
  /* ====================================================================== #4 */
  const checkNum = (num) => {
    const eventNum = Number(num);
    if (isNaN(eventNum)) {
      return 0;
    } else {
      return eventNum;
    }
  };
  const handleEquipmentName = (key) => {
    if (key === undefined || key === null || key === '') return '';

    let name = key;
    name = name.replace('STEAM', '스팀온도');
    name = name.replace('HEAT', '가열온도');
    name = name.replace('ROOM', '실온');
    name = name.replace('HO', '호기');
    name = name.replace('_', ' ');

    return name;
  };

  /* ====================================================================== #5 */
  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>{handleEquipmentName(tempKey) || ''}</ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              <table>
                <tbody>
                  <tr>
                    <th>현재온도</th>
                    <td
                      className={(() => {
                        let status = 'normal';
                        if (checkNum(PV) > checkNum(HIGH)) status = 'high';
                        if (checkNum(PV) < checkNum(LOW)) status = 'low';

                        return status;
                      })()}
                    >
                      {PV}
                    </td>
                  </tr>
                  <tr>
                    <th>설정온도</th>
                    <td>{SV}</td>
                  </tr>
                  <tr>
                    <th>상한값</th>
                    <td>{HIGH}</td>
                  </tr>
                  <tr>
                    <th>하한값</th>
                    <td>{LOW}</td>
                  </tr>
                </tbody>
              </table>
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default HsSensorDataModal;
