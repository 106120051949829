import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { getCookie } from 'preferences/cookie/cookie';
import { WS_CLIENT } from 'preferences/server/constants';

import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';
import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import CopyUriWorkOrderInfo from 'pages/productionOrder/custom/company/uri/modal/crud/CopyUriWorkOrderInfo';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  max-height: 400px;
  max-width: 500px;
  overflow: hidden;
  width: 85%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: inherit;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
`;
const FormSection = styled.div`
  align-items: center;
  row-gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const FormBox = styled.div`
  display: grid;
  column-gap: 10px;
  grid-template-columns: 100px auto;
  width: 100%;

  input {
    border: 1px solid var(--Text);
    border-radius: 5px;
    box-sizing: border-box;
    font-weight: 600;
    padding: 10px 20px;

    &[name='successAmount'] {
      background-color: var(--MainGreen);
      border-color: var(--MainGreen);
      color: var(--white);
    }
    &[name='failedAmount'] {
      border-color: var(--MainRed);
      color: var(--MainRed);
    }
  }
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;
const ModalButton = styled.div`
  align-items: center;
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const UriWorkEndModal = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_onload, setOnload] = useState('unload');

  const [_defaultAmount, setDefaultAmount] = useState(props.content.eventWorkOrder.processLogList.length);
  const [_totalAmount, setTotalAmount] = useState(0);
  const [_successAmount, setSuccessAmount] = useState(0);
  const [_failedAmount, setFailedAmount] = useState(0);

  const [_modalStatus, setModalStatus] = useState(false);
  const [_copyContent, setCopyContent] = useState({});

  /* ====================================================================== #3 */
  useEffect(() => {
    if (userReducer.company.companyId === 1 || userReducer.company.companyId === 2) {
      setTotalAmount(() => { return props.content.eventWorkOrder.processLogList.length });
      setSuccessAmount(() => { return props.content.eventWorkOrder.processLogList.length });
    }
    setOnload('loaded');
    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const handleAmountEvent = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case 'endAmount':
        setTotalAmount(value);
        setSuccessAmount(parseFloat(value - _failedAmount));
        return;
      case 'failedAmount':
        let failedNum = value;
        if (Number(failedNum) > Number(_totalAmount)) failedNum = _totalAmount;
        setFailedAmount(failedNum);
        setSuccessAmount(parseFloat(_totalAmount - failedNum));
        document.getElementById('failedAmountInput').value = failedNum;
        return;

      default: return;
    }
  };

  /* ====================================================================== #5 */
  const actWorkEnd = async () => {
    const BodyToWS = { ...props.content.BodyToWS };
    BodyToWS.workOrderStatus = 'end';
    console.log('BodyToWS : ', JSON.stringify(BodyToWS));

    try {
      WS_CLIENT.publish({
        destination: '/pub/work',
        headers: { 'auth-token': getCookie('accessToken')?.replace('Bearer ', '') },
        body: JSON.stringify(BodyToWS),
      });
    } catch (error) {
      console.log('client.publish/pub/work - error : ', error);
    }

    const eventWorkOrder = { ...props.content.eventWorkOrder };
    const setProcessLogList = eventWorkOrder.processLogList.map((processLog) => {
      const ProcessLogItem = {
        existProcessOutElement: false,

        processLogId: processLog.processLogId,
        processStatus: processLog.processStatus,

        processingCount: processLog.processingCount,

        processCustomizedContent: processLog.processCustomizedContent,

        taskLogList: checkNullArray(processLog.taskLogList, []),
        deleteTaskIds: [],

        attachFileList: checkNullArray(processLog.attachFileList, []),
      };

      return ProcessLogItem;
    });
    const BodyToPut = {
      companyId: userReducer.company.companyId,
      userId: userReducer.user.id,
      userName: userReducer.user.name,

      workOrderStatus: 'end',
      workOrderLogId: eventWorkOrder.workOrderLogId,

      workOrderScheduledStartDate: DateFormat(checkEmptyNull(eventWorkOrder.workOrderScheduledStartDate, '')),
      workOrderScheduledEndDate: DateFormat(checkEmptyNull(eventWorkOrder.workOrderScheduledEndDate, '')),

      accountId: eventWorkOrder.accountId,
      accountName: eventWorkOrder.accountName,

      workPlace: eventWorkOrder.workPlace,
      detailedWorkPlace: eventWorkOrder.detailedWorkPlace,

      message: eventWorkOrder.message,
      remark: eventWorkOrder.remark,

      workOrderCustomizedContent: eventWorkOrder.workOrderCustomizedContent,

      processLogList: setProcessLogList,
      deleteProcessList: [],

      amount: _totalAmount,
      successfulAmount: _successAmount,
      failedAmount: _failedAmount,
    };
    console.log('BodyToPut : ', BodyToPut);
    await workOrderLogApi.updateWorkOrderLog(BodyToPut.workOrderLogId, BodyToPut).then((response) => {
      if (response === undefined) return;
      console.log('/put/workOrderLog - response : ', response);
      if (Number(_failedAmount) > 0) {
        if (window.confirm('불량 작업지시를 추가하시겠습니까?') === true) {
          setCopyContent(() => { return BodyToPut })
          setTimeout(() => { setModalStatus(true) }, 1000);
        } else {
          alert('생산을 완료했습니다.');
          props.close();
        }
      } else {
        alert('생산을 완료했습니다.');
        props.close();
      }
    })
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>생산완료</ModalTitle>
              <CloseButton onClick={props.close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <FormSection>
                <FormBox>
                  <h4>총 완료 수량</h4>
                  <input
                    type="number"
                    id="endAmountInput"
                    name="endAmount"
                    value={_totalAmount}
                    onChange={handleAmountEvent}
                    min={0}
                    data-required="required"
                    placeholder="* 총 완료 수량..."
                  />
                </FormBox>
                <FormBox>
                  <h4>성공수량</h4>
                  <input
                    type="number"
                    id="successAmountInput"
                    name="successAmount"
                    value={_successAmount}
                    min={0}
                    data-required="required"
                    placeholder="* 성공수량..."
                    readOnly={true}
                  />
                </FormBox>
                <FormBox>
                  <h4>불량수량</h4>
                  <input
                    type="number"
                    id="failedAmountInput"
                    name="failedAmount"
                    onChange={handleAmountEvent}
                    defaultValue={0}
                    min={0}
                    max={_totalAmount}
                    data-required="required"
                    placeholder="* 불량수량..."
                  />
                </FormBox>
              </FormSection>
            </ModalMain>

            <ModalFooter>
              <ModalButton style={{ backgroundColor: 'var(--ThirdBlue)' }} onClick={actWorkEnd}>완료</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}

      {_modalStatus === true ? (
        <CopyUriWorkOrderInfo
          copyContent={_copyContent}
          open={_modalStatus}
          close={() => { setModalStatus(false); props.close(); }}
        ></CopyUriWorkOrderInfo>
      ) : null}
    </>
  );
};

export default UriWorkEndModal;
