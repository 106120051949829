import Header from 'mall/components/Header';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Styles = styled.section`
padding: 100px;
  display: flex; gap: 24px; flex-wrap: wrap;
  div {
    display: grid; padding: 24px; border:1px solid; gap: 16px; align-items: flex-start;
    h2 {border-bottom: 1px solid; margin-bottom: 16px; padding-bottom: 8px;}
    a {padding: 8px; &:hover{color: orange;}}
    .inprogress {color: red;}
  }
`

export default function MallSiteMap() {
  return (
    <>
      {/*
      - Router 설정
        컴포넌트가 수정되면 vscode에서 페이지로 직접 이동할 수 있게 router 설정하는 컴포넌트 추가해둠
        아래 주석 풀고 ctrl + 클릭으로 이동하면 됨
        mall 관련된 router는 line 1619 부터 참고
        링크에 회사 아이디 1 붙임 // 20230302 다현
        <RouteList />
      */}
      <Header />
      <Styles>
        <div>
          <h2>Sign</h2>
          <Link to='/1/mall/sign/login'>로그인 : /mall/sign/login</Link>
          <Link to='/1/mall/sign/join'>회원가입 : /mall/sign/join</Link>
          <Link to='/1/mall/sign/email/find'>이메일 찾기 : /mall/sign/email/find</Link>
          <Link to='/1/mall/sign/pw/find'>비밀번호 찾기 : /mall/sign/pw/find</Link>
        </div>
        <div>
          <h2>Product</h2>
          <Link to='/1/mall/product/list'>상품 목록 보기 : /mall/product/list</Link>
          <Link to='/1/mall/product/detail'>상품 상세정보 보기 : /mall/product/detail</Link>
          <Link to='/1/mall/product/order'>상품 주문하기 : /mall/product/order</Link>
          <Link to='/1/mall/product/qna'>상품 문의하기 :/mall/product/qna</Link>
        </div>
        <div>
          <h2>Cart & Qna</h2>
          <Link to='/1/mall/cart'>장바구니 목록 보기: /mall/cart</Link>
          <Link to='/1/mall/qna'>일반 문의하기 : /mall/qna</Link>
        </div>
        <div>
          <h2>Mypage</h2>
          <Link to='/1/mall/mypage/info'>내정보 : /mall/mypage/info</Link>
          <Link to='/1/mall/mypage/order/list'>상품 주문내역 : /mall/mypage/order/list</Link>
          <Link to='/1/mall/mypage/qna/list'>상품 및 일반 문의 내역 : /mall/mypage/qna/list</Link>
        </div>
        <div>
          <h2>Management</h2>
          <Link to='/mallManagement/order'>주문관리 : /mallManagement/order</Link>
          <Link to='/mallManagement/qna'>문의관리 : /mallManagement/qna</Link>
          <Link to='/mallManagement/element'>판매물품관리 : /mallManagement/element</Link>
          <Link to='/mallManagement/category'>카테고리관리 : /mallManagement/category</Link>
          <Link to='/mallManagement/user'>고객관리 : /mallManagement/user</Link>
        </div>
      </Styles>
    </>
  );
};