const initialState = {
  inItem: [],
  putItem: [],
  outItem: [],

  /* 수주물품, 발주물품 */
  ordersProducts: [],

  /* 수주, 발주 거래처 */
  ordersAccount: {},
};

const stockReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'SETINITEM':
      return {
        ...state,
        inItem: action.payload
      };

    case 'SETPUTITEM':
      return {
        ...state,
        putItem: action.payload
      };

    case 'SETOUTITEM':
      return {
        ...state,
        outItem: action.payload
      };

    case 'SETORDERSPRODUCTS':
      return {
        ...state,
        ordersProducts: action.payload
      };

    case 'SETORDERSACCOUNT':
      return {
        ...state,
        ordersAccount: action.payload
      };

    case 'SETPLACINGORDERSACCOUNT':
      return {
        ...state,
        PlacingordersAccount: action.payload
      };
    case 'SETPLACINGORDERSPRODUCTS':
      return {
        ...state,
        PlacingordersProducts: action.payload
      };

    case 'STOCKRESET':
      return initialState;

    default:
      return state;
  }
}

export default stockReducer;