const initialState = {
  cluster: {}, company: {}, user: {},

  productionStatus: { searchData: {}, pageNumber: 1, totalSize: 0, },
  processTracking: { searchData: {}, pageNumber: 1, totalSize: 0, },
  monitoring: { searchData: {}, pageNumber: 1, totalSize: 0, },
  mypage: { searchData: {}, pageNumber: 1, totalSize: 0, },
};
const resetState = {
  cluster: {}, company: {}, user: {},

  productionStatus: { searchData: {}, pageNumber: 1, totalSize: 0, },
  processTracking: { searchData: {}, pageNumber: 1, totalSize: 0, },
  monitoring: { searchData: {}, pageNumber: 1, totalSize: 0, },
  mypage: { searchData: {}, pageNumber: 1, totalSize: 0, },
};

const operatingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETOPERATINGCLUSTER': return { ...state, cluster: action.payload };
    case 'SETOPERATINGCOMPANYDATA': return { ...state, company: action.payload };
    case 'SETOPERATINGUSERDATA': return { ...state, user: action.payload };

    case 'SETOPERATINGPRODUCTIONSTATUS': return { ...state, productionStatus: action.payload };
    case 'SETOPERATINGPROCESSTRACKING': return { ...state, processTracking: action.payload };
    case 'SETOPERATINGMONITORING': return { ...state, monitoring: action.payload };
    case 'SETOPERATINGMYPAGE': return { ...state, mypage: action.payload };

    case 'SETOPERATINGRESET': return { ...state, ...resetState };
    default: return state;
  }
};

export default operatingReducer;
