const initialState = {
  planContent: {},

  createPlan: {},
  createPlanArray: [],

  updatePlan: {},

  planElProduct: {},
  planTargetProduct: {},

  planElProducts: [],
  planTargetProducts: [],

  equipment: {},

  qualityCheckUser: '',
  deliveryUser: '',
  receivingUser: '',

  workOrderUser: '',

  copyContent: {},
  updateContent: {},
  deleteContent: {},

  workOrderContent: {},
  workOrderContents: [],

  user: {},
  processUser: {},

  prevProcess: {},

  workerScreenContent: {},

  failedProcess: {},

  // ===================================================================

  currentFamilyId: 0,
  
  allFamilies: [],
  firstFamilies: [],
  secondFamilies: [],
  thirdFamilies: [],

  firstFamily: '',
  secondFamily: '',
  thirdFamily: '',

  productType: '',
  workStatus: '',
  startDate: '',
  endDate: '',
  searchOption: '',
  searchText: '',

  currentPageNum: 1,
  totalCount: 0,
  pageSize: 20,
};

const daerimReducer = (state = initialState, action) => {
  // console.log(state, action);
  switch(action.type) {
    case 'SETDAERIMPLANCONTENT':
      return {
        ...state,
        planContent: action.payload
      };
    case 'SETDAERIMCREATEPLAN':
      return {
        ...state,
        createPlan: action.payload
      };
    case 'SETDAERIMCREATEPLANARRAY':
      return {
        ...state,
        createPlanArray: action.payload
      };
    case 'SETDAERIMUPDATEPLAN':
      return {
        ...state,
        updatePlan: action.payload
      };
    case 'SETDAERIMPLANELPRODUCT':
      return {
        ...state,
        planElProduct: action.payload
      };
    case 'SETDAERIMPLANTARGETPRODUCT':
      return {
        ...state,
        planTargetProduct: action.payload
      };
    case 'SETDAERIMPLANELPRODUCTS':
      return {
        ...state,
        planElProducts: action.payload
      };
    case 'SETDAERIMPLANTARGETPRODUCTS':
      return {
        ...state,
        planTargetProducts: action.payload
      };
    case 'SETDAERIMEQUIPMENT':
      return {
        ...state,
        equipment: action.payload
      };
    case 'SETDAERIMQUALITYCHECKUSER':
      return {
        ...state,
        qualityCheckUser: action.payload
      };
    case 'SETDAERIMDELIVERYUSER':
      return {
        ...state,
        deliveryUser: action.payload
      };
    case 'SETDAERIMRECEIVINGUSER':
      return {
        ...state,
        receivingUser: action.payload
      };
    case 'SETDAERIMWORKORDERUSER':
      return {
        ...state,
        workOrderUser: action.payload
      };
    case 'SETDAERIMCOPYCONTENT':
      return {
        ...state,
        copyContent: action.payload

      };
    case 'SETDAERIMUPDATECONTENT':
      return {
        ...state,
        updateContent: action.payload
      };
    case 'SETDAERIMDELETECONTENT':
      return {
        ...state,
        deleteContent: action.payload
      };

    case 'SETDAERIMWORKORDERCONTENT':
      return {
        ...state,
        workOrderContent: action.payload
      };

    case 'SETDAERIMWORKORDERCONTENTS':
      return {
        ...state,
        workOrderContents: action.payload
      };

    case 'SETDAERIMUSER':
      return {
        ...state,
        user: action.payload
      };

    case 'SETDAERIMPROCESSUSER':
      return {
        ...state,
        processUser: action.payload
      };

    case 'SETDAERIMPREVPROCESS':
      return {
        ...state,
        prevProcess: action.payload
      };

    case 'SETDAERIMWORKERSCREENCONTENT':
      return {
        ...state,
        workerScreenContent: action.payload
      };

    case 'SETDAERIMFAILEDPROCESS':
      return {
        ...state,
        failedProcess: action.payload
      };

    // ===================================================================

    case 'MODALDAERIMCURRENTFAMILYID':
      return {
        ...state,
        currentFamilyId: action.payload
      };

    case 'MODALDAERIMALLFAMILIES':
      return {
        ...state,
        allFamilies: action.payload
      };
    case 'MODALDAERIMFIRSTFAMILIES':
      return {
        ...state,
        firstFamilies: action.payload
      };
    case 'MODALDAERIMSECONDFAMILIES':
      return {
        ...state,
        secondFamilies: action.payload
      };
    case 'MODALDAERIMTHIRDFAMILIES':
      return {
        ...state,
        thirdFamilies: action.payload
      };

    case 'MODALDAERIMFIRSTFAMILY':
      return {
        ...state,
        firstFamily: action.payload
      };
    case 'MODALDAERIMSECONDFAMILY':
      return {
        ...state,
        secondFamily: action.payload
      };
    case 'MODALDAERIMTHIRDFAMILY':
      return {
        ...state,
        thirdFamily: action.payload
      };

    case 'MODALDAERIMPRODUCTTYPE':
      return {
        ...state,
        productType: action.payload
      };
    case 'MODALDAERIMWORKSTATUS':
      return {
        ...state,
        workStatus: action.payload
      };
    case 'MODALDAERIMSTARTDATE':
      return {
        ...state,
        startDate: action.payload
      };
    case 'MODALDAERIMENDDATE':
      return {
        ...state,
        endDate: action.payload
      };
    case 'MODALDAERIMSEARCHOPTION':
      return {
        ...state,
        searchOption: action.payload
      };
    case 'MODALDAERIMSEARCHTEXT':
      return {
        ...state,
        searchText: action.payload
      };

    case 'MODALDAERIMCURRENTPAGENUM':
      return {
        ...state,
        currentPageNum: action.payload
      };
    case 'MODALDAERIMTOTALCOUNT':
      return {
        ...state,
        totalCount: action.payload
      };
    case 'MODALDAERIMPAGESIZE':
      return {
        ...state,
        pageSize: action.payload
      };

    case 'DAERIMRESET':
      return initialState;

    default:
      return state;
  }
}

export default daerimReducer;