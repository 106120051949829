import styled from 'styled-components';

export const ElementCategoryComponent = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
`;

export const CategoryComponent = styled.select`
  border: 1px solid var(--gray-200);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 4px 12px;
  max-width: 200px;
`;