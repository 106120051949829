import React, { Fragment, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { checkNull, checkNullArray, checkNullObject } from 'components/checkValues/checkValues';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import CommaNum from 'components/format/CommaNum';
import GuideText from 'components/buttons/GuideText';
import ShowInputElementStockResultLog from 'pages/stockHistory/inputElementStockLog/ShowInputElementStockResultLog';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;
const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto;
  height: 85%;
  overflow: hidden;
  width: 95%;
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;
const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  & .TableSection {
    height: 100%;
    overflow-x: auto;
    width: 100%;

    td, th {min-width: 100px;}
    td.amountCell, th.amountCell {min-width: unset;}
  }
`;

const ElementStockLogButton = styled.td`
  background-color: var(--MainYellow);
  color: var(--white);
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
`;

const ProcessResultModal = (props) => {
  /* ========================================================================= #1 */
  /* ========================================================================= #2 */
  const { open, close, processLog, result } = props;
  const { processResultElements } = result;
  console.log('processResultElements : ', processResultElements);

  const [_putElements, setPutElements] = useState(() => {
    const returnData = processResultElements.filter((element) => element.processResultElementType === 'Put')
    return returnData;
  });
  const [_makeElements, setMakeElements] = useState(() => {
    const returnData = processResultElements.filter((element) => element.processResultElementType === 'Make');
    return returnData;
  });
  const [_failedElements, setFailedElements] = useState(() => {
    const returnData = [];
    processResultElements.forEach(element => {
      if (checkNullArray(element.processResultElementFailedReasons, false)) {
        element.processResultElementFailedReasons.forEach(reason => {
          const elementData = {
            ...element,
            processResultElementId: reason.processResultElementId,
            amount: BigNumber(checkNull(reason.amount, 0)).toNumber(),
            failedReason: checkNull(reason.failedReason, '')
          }
          returnData.push(elementData)
        })
      }
    })
    return returnData;
  })

  const [_rowLength, setRowLength] = useState(() => {
    const rowLength = (_putElements.length >= _makeElements.length && _putElements.length >= _failedElements.length) ? _putElements.length :
      (_makeElements.length >= _putElements.length && _makeElements.length >= _failedElements.length) ? _makeElements.length :
        _failedElements.length;

    const returnData = [];
    let count = 0;
    while (count < rowLength) {
      returnData.push(count);
      count++;
    }
    return returnData;
    // min = (a <= b && a <= c) ? a :  // if      (a < b && a < c) return a;
    //   (b <= a && b <= c) ? b : c;   // else if (b < a && b < c) return b; else return c;

    // max = (a >= b && a >= c) ? a :  // if      (a > b && a > c) return a;
    //   (b >= a && b >= c) ? b : c;   // else if (b > a && b > c) return b; else return c;

    // mid = (a >= b && a <= c) ? a :
    //   (b >= a && b <= c) ? b :
    //     (c >= a && c <= b) ? c :
    //       (a >= c && a <= b) ? a :
    //         (b >= c && a >= b) ? b : c;
  });

  const [_inputElementStockLogModalStatus, setInputElementStockLogModalStatus] = useState(false);
  const [_inputElementStockLogs, setInputElementStockLogs] = useState([]);

  /* ========================================================================= #3 */
  /* ========================================================================= #4 */
  /* ========================================================================= #5 */
  const showInputElementStockLog = (processLogElement, processLog) => {
    setInputElementStockLogs(() => {
      return {
        processLog: processLog,
        processLogElement: processLogElement,
        inputElementStockLogs: checkNullArray(processLogElement.inputElementStockLogs, []),
      }
    })

    setTimeout(setInputElementStockLogModalStatus(true), 1000)
  }

  /* ========================================================================= #6 */

  return (
    <>
      {open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>{processLog.code + ' - ' + processLog.name}</ModalTitle>
              <CloseButton onClick={close}><CloseButtonIcon /></CloseButton>
            </ModalHeader>

            <ModalMain>
              <TableSection content={
                <table>
                  <thead>
                    <tr>
                      <th colSpan={3} style={{ backgroundColor: 'var(--MainYellow)' }}>투입 자재/반제품</th>
                      <th colSpan={5} style={{ backgroundColor: 'var(--ThirdBlue)' }}>공정완료제품</th>
                      <th colSpan={3} style={{ backgroundColor: 'var(--ThirdRed)' }}>불량물품</th>
                    </tr>
                    <tr>
                      <th>물품정보</th>
                      <th className="amountCell">총 투입량</th>
                      {/* <th className="amountCell">재투입량</th>
                        <th className="amountCell">재사용량</th> */}
                      <th style={{ minWidth: '120px', width: '120px' }}></th>

                      <th>물품정보</th>
                      <th className="amountCell">목표 생산량</th>
                      <th className="amountCell" style={{ backgroundColor: 'var(--MainBlue)' }}>총 생산량</th>
                      <th className="amountCell" style={{ backgroundColor: 'var(--MainGreen)' }}>성공수량</th>
                      <th className="amountCell" style={{ backgroundColor: 'var(--MainRed)' }}>실패수량</th>

                      <th>물품정보</th>
                      <th className="amountCell">불량수량</th>
                      <th>불량사유</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_rowLength.map((item, index) => {
                      const putElement = {
                        elementName: '',
                        elementCode: '',
                        amount: '',
                        // reInputAmount: '',
                        // reUsedAmount: '',
                      };
                      if (checkNullObject(_putElements[index], false)) {
                        putElement.elementName = _putElements[index].elementName;
                        putElement.elementCode = _putElements[index].elementCode;
                        putElement.amount = BigNumber(checkNull(_putElements[index].amount, 0)).toNumber();
                        // putElement.reInputAmount = BigNumber(checkNull(_putElements[index].reInputAmount, 0)).toNumber();
                        // putElement.reUsedAmount = BigNumber(checkNull(_putElements[index].reUsedAmount, 0)).toNumber();
                        putElement.inputElementStockLogs = checkNullArray(_putElements[index].inputResultElementStockLogs, [])
                      }

                      const makeElement = {
                        elementName: '',
                        elementCode: '',
                        amount: '',
                        completedAmount: '',
                        makeAmount: '',
                        failedAmount: '',
                      };
                      if (checkNullObject(_makeElements[index], false)) {
                        makeElement.elementName = _makeElements[index].elementName;
                        makeElement.elementCode = _makeElements[index].elementCode;
                        makeElement.amount = BigNumber(checkNull(_makeElements[index].amount, 0)).toNumber();
                        makeElement.completedAmount = BigNumber(checkNull(_makeElements[index].makeAmount, 0)).plus(BigNumber(checkNull(_makeElements[index].failedAmount, 0))).toNumber();
                        makeElement.makeAmount = BigNumber(checkNull(_makeElements[index].makeAmount, 0)).toNumber();
                        makeElement.failedAmount = BigNumber(checkNull(_makeElements[index].failedAmount, 0)).toNumber();
                      }

                      const failedElement = {
                        elementName: '',
                        elementCode: '',
                        amount: '',
                        failedReason: '',
                      }
                      if (checkNullObject(_failedElements[index], false)) {
                        failedElement.elementName = _failedElements[index].elementName;
                        failedElement.elementCode = _failedElements[index].elementCode;
                        failedElement.amount = _failedElements[index].amount;
                        failedElement.failedReason = _failedElements[index].failedReason;
                      }
                      // console.log({ putElement: putElement, makeElement: makeElement, failedElement: failedElement });

                      return (
                        <tr key={index + '_results'}>
                          <td>{putElement.elementName}<br />{putElement.elementCode !== '' ? `(${putElement.elementCode})` : ''}</td>
                          <td><CommaNum displayType="text" num={putElement.amount} /></td>
                          {/* <td><CommaNum displayType="text" num={putElement.reInputAmount} /></td>
                            <td><CommaNum displayType="text" num={putElement.reUsedAmount} /></td> */}
                          <ElementStockLogButton style={{ minWidth: '120px', width: '120px' }} onClick={(e) => {
                            e.preventDefault();
                            showInputElementStockLog(putElement, processLog);
                          }}>
                            투입추적
                            <GuideText />
                          </ElementStockLogButton>

                          <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                            {makeElement.elementName}<br />{makeElement.elementCode !== '' ? `(${makeElement.elementCode})` : ''}
                          </td>
                          <td><CommaNum displayType="text" num={makeElement.amount} /></td>
                          <td><CommaNum displayType="text" num={makeElement.completedAmount} /></td>
                          <td><CommaNum displayType="text" num={makeElement.makeAmount} /></td>
                          <td><CommaNum displayType="text" num={makeElement.failedAmount} /></td>

                          <td style={{ borderLeft: '1px solid var(--gray-200)' }}>
                            {failedElement.elementName}<br />{failedElement.elementCode !== '' ? `(${failedElement.elementCode})` : ''}
                          </td>
                          <td><CommaNum displayType="text" num={failedElement.amount} /></td>
                          <td>
                            {checkNull(failedElement.failedReason, false) ? failedElement.failedReason.indexOf('\n') !== -1 ?
                              failedElement.failedReason.split('\n').map((text, textIndex) => {
                                return <p key={props.index + '_' + textIndex + '_failedReason'}>{text}</p>
                              }) : failedElement.failedReason : ''}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              }
              />
            </ModalMain>
          </ModalSection>
        </ModalBg>
      )}

      {_inputElementStockLogModalStatus === true ? (
        <ShowInputElementStockResultLog
          content={_inputElementStockLogs}
          open={_inputElementStockLogModalStatus}
          close={() => { setInputElementStockLogModalStatus(false) }}
        ></ShowInputElementStockResultLog>
      ) : null}
    </>
  );
};

export default ProcessResultModal;
