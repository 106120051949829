import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Mall } from 'mall/styles/style';

import Header from 'mall/components/Header';
import Footer from 'mall/components/Footer';

const Section = styled.div`
  display: grid;
  grid-template-rows: 60px calc(100% - 60px);
  height: 100svh;
  overflow: hidden;
  position: relative;
  width: 100svw;
`;

export default function Main({ children }) {
  const navigate = useNavigate();
  const currentPage = window.location.pathname;

  useEffect(() => {
    const splits = currentPage.split('/');
    const last = splits[splits.length - 1];
    const companyCode = splits[1];
    if (last === 'mall') return navigate(`/${companyCode}/mall/product/list`, { replace: true });
  }, [])

  return (
    <Section>
      <Header />
      <Mall>
        <div
          className={(() => {
            if (currentPage.includes('/mall/product/list')) {
              return 'mall-product-list mainpage'
            } else {
              // url 슬래쉬 앞 부분 문자열 제거
              const tokenized = currentPage.split('/')
              const postfix = tokenized[tokenized.length - 1]
              // 해당 페이지에 맞는 클래스명 적용
              if (currentPage.includes('/mall/mypage/order') || currentPage.includes('/mall/mypage/qna')) return `mall-product-info mall-product-list`
              else return `mall-product-${postfix}`
            }
          })()}
        >
          <Outlet {...children} />
        </div>
        <Footer />
      </Mall>
    </Section>
  );
};
