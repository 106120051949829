const initialState = {
  status: 'all',
  startDate: undefined,
  endDate: undefined,
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,

  account: {},
  notInStockElementList: [],

  viewScale: 100,
};
const resetState = {
  status: 'all',
  startDate: undefined,
  endDate: undefined,
  searchOption: '',
  searchText: '',
  pageNumber: 1,
  totalSize: 0,
  isPushedSearchButton: false,
};

const notInStockReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETNOTINSTOCKSTATUS':
      return {
        ...state,
        status: action.payload,
      };
    case 'SETNOTINSTOCKSTARTDATE':
      return {
        ...state,
        startDate: action.payload,
      };
    case 'SETNOTINSTOCKENDDATE':
      return {
        ...state,
        endDate: action.payload,
      };
    case 'SETNOTINSTOCKSEARCHOPTION':
      return {
        ...state,
        searchOption: action.payload,
      };
    case 'SETNOTINSTOCKSEARCHTEXT':
      return {
        ...state,
        searchText: action.payload,
      };
    case 'SETNOTINSTOCKPAGENUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };
    case 'SETNOTINSTOCKTOTALSIZE':
      return {
        ...state,
        totalSize: action.payload,
      };
    case 'SETNOTINSTOCKISPUSHEDSEARCHBUTTON':
      return {
        ...state,
        isPushedSearchButton: action.payload,
      };
    case 'SETNOTINSTOCKRESET':
      return {
        ...state,
        ...resetState,
      };

    case 'SETNOTINSTOCKACCOUNT':
      return {
        ...state,
        account: action.payload,
      };

    case 'SETNOTINSTOCKELEMENTLIST':
      return {
        ...state,
        notInStockElementList: action.payload,
      };

    case 'SETNOTINSTOCKVIEWSCALE':
      return {
        ...state,
        viewScale: action.payload,
      };

    default:
      return state;
  }
};

export default notInStockReducer;
