import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';

import {
  AXIOS_BASE_HEADERS,
  AXIOS_BASE_URL,
} from 'preferences/server/constants';

import CloseButtonIcon from 'components/icons/CloseButtonIcon';
import Form from 'components/layouts/form/Form';
import TableSection from 'components/layouts/table/TableSection';

const ModalBg = styled.div`
  align-items: center;
  background-color: var(--ModalBg);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 950;
`;

const ModalSection = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 55px auto 50px;
  height: 85%;
  /* max-height: 300px; */
  /* max-width: 400px; */
  overflow: hidden;
  width: 85%;

  & form > div {
    background-color: unset;
  }
`;

const ModalHeader = styled.div`
  align-items: center;
  background-color: var(--gray-100);
  box-sizing: border-box;
  display: flex;
  height: 55px;
  justify-content: flex-start;
  padding: 0px 20px;
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
`;

const CloseButton = styled.div`
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 25px;
`;

const ModalMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  /* height: calc(100% - 55px); */
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
`;

const ModalFooter = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const ModalButton = styled.div`
  align-items: center;
  /* background-color: var(--MainGreen); */
  background-color: var(--MainNavy);
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

const FormDate = styled.input`
  border: 1px solid var(--gray-200);
  border-radius: 5px;
  box-sizing: border-box;
  height: 40px;
  padding: 5px;

  &:focus {
    border: 1px solid var(--gray-200);
  }
`;

const TableFormbox = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  width: 100%;

  .TableSection {
    overflow: auto;
  }

  & > .TableSection {
    max-height: 350px;

    &::-webkit-scrollbar-thumb {
      background-color: var(--ThirdBlue);
    }
  }
`;

const AccountInput = styled.input`
  background-color: var(--MainNavy);
  color: var(--white);
`;

const GSNotInStockToProcurement = (props) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  console.log('GSNotInStockToProcurement - props : ', props);

  const [_onload, setOnload] = useState('unload');

  const notInStock = props.Procurement;
  const thisProcurement = notInStock.procurement;
  const procurementElements = thisProcurement.procurementElementList;
  console.log(props.Procurement);

  const [_procurement, setProcurement] = useState({
    notInStock: notInStock,
    procurement: thisProcurement,
    procurementElements: procurementElements,
  });

  const [_formData, setFormData] = useState({});
  console.log(_formData);

  const [_accountModalStatus, setAccountModalStatus] = useState(false);
  const [_modalStatus, setModalStatus] = useState(false);

  /* ====================================================================== #3 */
  useEffect(() => {
    const postProcurementElements = notInStock.notInStockElementList.map(
      (thisItem) => {
        return {
          accountId: thisItem.account.accountId,
          elementId: thisItem.element.elementId,
          price: thisItem.price,
          quantity: thisItem.notInStockQuantity,
        };
      },
    );

    let thisProcurementDate = thisProcurement.procurementDate;
    if (
      thisProcurement.procurementDate !== undefined &&
      thisProcurement.procurementDate !== null &&
      typeof thisProcurement.procurementDate === 'object'
    ) {
      const newStartDate = new Date(thisProcurement.procurementDate.join('-'));
      thisProcurementDate = moment(newStartDate).format('YYYY-MM-DD');
    }

    let thisScheduledEndDate = thisProcurement.scheduledEndDate;
    if (
      thisProcurement.scheduledEndDate !== undefined &&
      thisProcurement.scheduledEndDate !== null &&
      typeof thisProcurement.scheduledEndDate === 'object'
    ) {
      const newStartDate = new Date(thisProcurement.scheduledEndDate.join('-'));
      thisScheduledEndDate = moment(newStartDate).format('YYYY-MM-DD');
    }

    setFormData({
      companyId: userReducer.company.companyId,

      accountId: notInStock.notInStockElementList[0].account.accountId,

      procurementStatus: 'waiting',

      procurementCode: thisProcurement.procurementCode,
      procurementName: thisProcurement.procurementName,

      procurementDate: thisProcurementDate,
      scheduledEndDate: thisScheduledEndDate,

      procurementPrice: thisProcurement.procurementPrice,

      procurementElementList: postProcurementElements,
    });

    setOnload('loaded');

    return () => { };
  }, []);

  /* ====================================================================== #4 */
  const setDateFormat = (thisDate) => {
    console.log('setDateFormat : ', thisDate);
    if (
      thisDate !== undefined &&
      thisDate !== null &&
      typeof thisDate === 'object'
    ) {
      const newtDate = new Date(thisDate.join('-'));
      return moment(newtDate).format('YYYY-MM-DD');
    } else {
      return thisDate;
    }
  };

  const handleChangeEvent = (e) => {
    const { name, value } = e.target;

    setFormData({ ..._formData, [name]: value });
  };

  const handleDateEvent = (e) => {
    const { name, value } = e.target;
    const thisDate = new Date(value);
    const postDate = moment(thisDate).format('YYYY-MM-DD');
    // const postDate = thisDate.toISOString();

    setFormData({ ..._formData, [name]: postDate });
  };

  const handleStockProduct = (e) => {
    const { name, value } = e.target;
    const thisElementId = e.currentTarget.dataset.elementid;
    const thisFindIndex = _formData.procurementElementList.findIndex(
      (thisItem) => thisItem.elementId === Number(thisElementId),
    );

    if (thisFindIndex !== -1) {
      const setList = _formData.procurementElementList.filter(
        (thisItem) => thisItem.elementId !== Number(thisElementId),
      );
      const newProduct = {
        ..._formData.procurementElementList[thisFindIndex],
        [name]: Number(value),
      };
      const newProcurementElement = [...setList, newProduct];

      setFormData({
        ..._formData,
        procurementElementList: newProcurementElement,
      });
    } else {
      const newProduct = {
        elementId: Number(thisElementId),
        price: 0,
        quantity: 0,
      };
      const setNewProduct = { ...newProduct, [name]: Number(value) };
      const newProcurementElement = [
        ..._formData.procurementElementList,
        setNewProduct,
      ];

      setFormData({
        ..._formData,
        procurementElementList: newProcurementElement,
      });
    }
  };

  /* ====================================================================== #5 */
  const actProcurement = async (e) => {
    e.preventDefault();

    const setProcurementElementList = _formData.procurementElementList.map(
      (thisItem) => {
        return {
          accountId: thisItem.accountId,
          elementId: thisItem.elementId,
          price: thisItem.price,
          quantity: thisItem.quantity,
        };
      },
    );
    const setFormData = {
      ..._formData,
      procurementElementList: setProcurementElementList,
    };
    // delete setFormData['accountId'];

    const BodyToPost = { ...setFormData };
    console.log('post/createProcurement - body : ', BodyToPost);

    await axios
      .post(AXIOS_BASE_URL + '/procurement', BodyToPost, AXIOS_BASE_HEADERS)
      .then((response) => {
        if (response === undefined) return;
        console.log('post/createProcurement - response : ', response);
        alert('발주가 추가되었습니다.');
        props.close();
      })
      .catch((error) => {
        console.error('post/createProcurement - error : ', error);
      });
  };

  /* ====================================================================== #6 */

  return (
    <>
      {props.open === true && (
        <ModalBg>
          <ModalSection>
            <ModalHeader>
              <ModalTitle>미입고 발주추가</ModalTitle>
              <CloseButton onClick={props.close}>
                <CloseButtonIcon />
              </CloseButton>
            </ModalHeader>

            <ModalMain>
              <Form
                title={
                  <>
                    미입고
                    <br />
                    발주
                    <br />
                    추가
                  </>
                }
                buttons={<></>}
                forms={
                  <>
                    <div className="formBox">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          lineHeight: '30px',
                          width: '100%',
                        }}
                      >
                        <h4>거래처</h4>
                      </div>
                      <AccountInput
                        type="text"
                        name="accountId"
                        defaultValue={
                          notInStock.notInStockElementList[0].account
                            .accountName
                        }
                        readOnly
                      />
                    </div>

                    <div className="formBox">
                      <h4>발주일자</h4>
                      <FormDate
                        type="date"
                        name="procurementDate"
                        defaultValue={setDateFormat(
                          _procurement.procurement.procurementDate,
                        )}
                        data-required="required"
                        onChange={handleDateEvent}
                      />
                    </div>

                    <div className="formBox">
                      <h4>발주코드</h4>
                      <input
                        type="text"
                        name="procurementCode"
                        defaultValue={_procurement.procurement.procurementCode}
                        data-required="required"
                        onChange={handleChangeEvent}
                        placeholder="* 발주코드..."
                      />
                    </div>

                    <div className="formBox">
                      <h4>발주명</h4>
                      <input
                        type="text"
                        name="procurementName"
                        defaultValue={_procurement.procurement.procurementName}
                        data-required="required"
                        onChange={handleChangeEvent}
                        placeholder="* 발주명..."
                      />
                    </div>

                    <div className="formBox">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          lineHeight: '30px',
                          width: '100%',
                        }}
                      >
                        <h4>발주물품</h4>
                      </div>

                      <TableFormbox>
                        <TableSection
                          content={
                            <table>
                              <thead
                                style={{ backgroundColor: 'var(--MainNavy)' }}
                              >
                                <tr>
                                  <th>물품이름</th>
                                  <th>물품코드</th>
                                  <th>발주금액</th>
                                  <th>발주수량</th>
                                </tr>
                              </thead>
                              <tbody>
                                {notInStock.notInStockElementList.map(
                                  (thisItem) => {
                                    const thisElement = thisItem.element;
                                    const notInStockElementKey =
                                      thisItem.notInStockElementId;
                                    const elementKey =
                                      thisItem.element.elementId;
                                    return (
                                      <tr
                                        key={notInStockElementKey + '_발주물품'}
                                        data-key={notInStockElementKey}
                                        data-elkey={elementKey}
                                      >
                                        <td
                                          className={
                                            thisElement.elementName.startsWith(
                                              '*',
                                            )
                                              ? 'outsourcingText'
                                              : null
                                          }
                                        >
                                          {thisElement.elementName}
                                        </td>
                                        <td>{thisElement.elementCode}</td>
                                        <td>
                                          <input
                                            type="number"
                                            name="price"
                                            data-elementid={elementKey}
                                            min={0}
                                            defaultValue={thisItem.price}
                                            onChange={handleStockProduct}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            name="quantity"
                                            data-elementid={elementKey}
                                            min={0}
                                            defaultValue={
                                              thisItem.notInStockQuantity ===
                                                null
                                                ? 0
                                                : thisItem.notInStockQuantity
                                            }
                                            onChange={handleStockProduct}
                                          />
                                        </td>
                                      </tr>
                                    );
                                  },
                                )}
                              </tbody>
                            </table>
                          }
                        />
                      </TableFormbox>
                    </div>

                    <div className="formBox">
                      <h4>발주금액</h4>
                      <input
                        type="number"
                        name="procurementPrice"
                        defaultValue={_procurement.procurement.procurementPrice}
                        onChange={handleChangeEvent}
                        placeholder="* 발주금액..."
                      />
                    </div>

                    <div className="formBox">
                      <h4>입고예정일</h4>
                      <FormDate
                        type="date"
                        name="scheduledEndDate"
                        defaultValue={setDateFormat(
                          _procurement.procurement.scheduledEndDate,
                        )}
                        onChange={handleDateEvent}
                      ></FormDate>
                    </div>
                  </>
                }
                formStyle={{
                  height: '100%',
                }}
                formLayoutStyle={{
                  padding: '0px 30px',
                  width: '95%',
                }}
                formSectionStyle={{
                  paddingTop: '10px',
                  width: '100%',
                }}
              />
            </ModalMain>

            <ModalFooter>
              <ModalButton onClick={actProcurement}>발주추가</ModalButton>
            </ModalFooter>
          </ModalSection>
        </ModalBg>
      )}
    </>
  );
};

export default GSNotInStockToProcurement;
