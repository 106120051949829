import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { cluster } from 'api/apis/platform/cluster/cluster';

import { OperatingMyPageComponent } from './OperatingMyPage.style';

const OperatingMyPage = () => {
  const location = useLocation();
  const operatingId = location.pathname.split('/platform/operating')[0];
  const operatingPath = operatingId + '/platform/operating';

  const [_cluster, setCluster] = useState();

  useEffect(() => {
    getClusterData();
  }, []);

  const getClusterData = async () => {
    await cluster.get(operatingId).then(response => {
      if (response === undefined) return;
      setCluster(() => { return response.data });
    })
  };

  return (
    <OperatingMyPageComponent>
      <header>
        <h1>마이 페이지</h1>
      </header>
      <div>
        <p>클러스터 : <span>{_cluster?.clusterName || ''}</span></p>
      </div>
    </OperatingMyPageComponent>
  );
};

export default OperatingMyPage;