import React from 'react';
import { StlViewer } from 'react-stl-viewer';

const StlFile = ({
  url
}) => {
  const style = {
    height: '100%',
    left: 0,
    top: 0,
    width: '100%',
  }

  return (
    <StlViewer
      style={style}
      orbitControls
      // shadows
      url={url}
    />
  );
};

export default StlFile;