import React from 'react';
import { Container, Table, Tbody, Td, Th, Thead, Title, Tr } from 'pages/contract/modal/Tab.style';

export default function ProcurementItemsTab({ data }) {
  const formatPrice = (price) =>
    price != null ? new Intl.NumberFormat().format(price) : '-';

  const { procurementElementList = [] } = data;

  return (
    <Container className="contract">
      <Title>발주 물품정보</Title>
      {procurementElementList.length > 0 ? (
        <Table>
          <Thead>
            <Tr>
              <Th>물품정보</Th>
              <Th>물품코드</Th>
              <Th>수량</Th>
              <Th>금액</Th>
              <Th>재고</Th>
            </Tr>
          </Thead>
          <Tbody>
            {procurementElementList.map((element, index) => {
              const { elementName, elementCode, elementStock } =
                element.element || {};
              return (
                <Tr key={index}>
                  <Td>{elementName || '-'}</Td>
                  <Td>{elementCode || '-'}</Td>
                  <Td>{element.quantity || '-'}</Td>
                  <Td style={{ textAlign: 'right' }}>
                    {formatPrice(element.price)}
                  </Td>
                  <Td>{elementStock || '-'}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      ) : (
        <p>발주 물품정보가 없습니다.</p>
      )}
    </Container>
  );
};
