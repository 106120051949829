export const mallSiteQnAActions_setMallQnAStatus = (status) => {
  return {
    type: 'SETMALLSITEQNASTATUS',
    payload: status,
  };
};
export const mallSiteQnAActions_setStartDate = (date) => {
  return {
    type: 'SETMALLSITEQNASTARTDATE',
    payload: date,
  };
};
export const mallSiteQnAActions_setEndDate = (date) => {
  return {
    type: 'SETMALLSITEQNAENDDATE',
    payload: date,
  };
};
export const mallSiteQnAActions_setSearchOption = (searchOption) => {
  return {
    type: 'SETMALLSITEQNASEARCHOPTION',
    payload: searchOption,
  };
};
export const mallSiteQnAActions_setSearchText = (searchText) => {
  return {
    type: 'SETMALLSITEQNASEARCHTEXT',
    payload: searchText,
  };
};
export const mallSiteQnAActions_setPageNumber = (pageNumber) => {
  return {
    type: 'SETMALLSITEQNAPAGENUMBER',
    payload: pageNumber,
  };
};
export const mallSiteQnAActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETMALLSITEQNATOTALSIZE',
    payload: totalSize,
  };
};
export const mallSiteQnAActions_setIsPushedSearchButton = (
  isPushedSearchButton,
) => {
  return {
    type: 'SETMALLSITEQNAISPUSHEDSEARCHBUTTON',
    payload: isPushedSearchButton,
  };
};
export const mallSiteQnAActions_setReset = () => {
  return {
    type: 'SETMALLSITEQNARESET',
  };
};
