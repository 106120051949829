export const mallQnAActions_setMallQnAStatus = (status) => {
  return {
    type: 'SETMALLQNASTATUS',
    payload: status,
  };
};
export const mallQnAActions_setStartDate = (date) => {
  return {
    type: 'SETMALLQNASTARTDATE',
    payload: date,
  };
};
export const mallQnAActions_setEndDate = (date) => {
  return {
    type: 'SETMALLQNAENDDATE',
    payload: date,
  };
};
export const mallQnAActions_setSearchOption = (searchOption) => {
  return {
    type: 'SETMALLQNASEARCHOPTION',
    payload: searchOption,
  };
};
export const mallQnAActions_setSearchText = (searchText) => {
  return {
    type: 'SETMALLQNASEARCHTEXT',
    payload: searchText,
  };
};
export const mallQnAActions_setPageNumber = (pageNumber) => {
  return {
    type: 'SETMALLQNAPAGENUMBER',
    payload: pageNumber,
  };
};
export const mallQnAActions_setTotalSize = (totalSize) => {
  return {
    type: 'SETMALLQNATOTALSIZE',
    payload: totalSize,
  };
};
export const mallQnAActions_setIsPushedSearchButton = (
  isPushedSearchButton,
) => {
  return {
    type: 'SETMALLQNAISPUSHEDSEARCHBUTTON',
    payload: isPushedSearchButton,
  };
};
export const mallQnAActions_setReset = () => {
  return {
    type: 'SETMALLQNARESET',
  };
};
