import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { monitoringActions_setSensorView } from 'store/modules/actions/common/monitoringActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { sensor } from 'api/apis/sensor';

import { Section } from './TmpHmdData.style';
import {
  checkEmptyNull,
  checkNullArray,
} from 'components/checkValues/checkValues';
import Grid2Body from 'components/layouts/body/Grid2Body';
import NavBar from 'components/nav/NavBar';
import NavTitle from 'components/nav/NavTitle';
import SensorData from 'pages/sensorData/contents/SensorData';

const TmpHmdData = () => {
  const dispatch = useDispatch();
  const { monitoringReducer, userReducer } = useSelector((state) => state);

  const [_sensorList, setSensorList] = useState([]);
  const [_sensorDataList, setSensorDataList] = useState([]);

  const [_modalStatus, setModalStatus] = useState(false);
  const [_sensorData, setSensorData] = useState({});

  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    getSensorList();
    getSensorDataList(monitoringReducer.sensorView);
    const sensorInterval = setInterval(() => {
      getSensorDataList(monitoringReducer.sensorView);
    }, 60000);
    return () => clearInterval(sensorInterval);
  }, []);

  useEffect(() => {}, [monitoringReducer.sensorView]);

  // API 호출 관련 함수
  const getSensorList = async () => {
    const body = { companyId: userReducer.company.companyId };
    await sensor.sensorGroup(body).then((response) => {
      if (response === undefined) return;
      setSensorList(() => {
        return response.data;
      });
    });
  };
  const getSensorDataList = async (sensorView) => {
    const body = { companyId: userReducer.company.companyId };
    if (checkEmptyNull(sensorView, false) && sensorView !== 'all')
      body.name = sensorView;
    await sensor.sensorGroup(body).then((response) => {
      if (response === undefined) return;
      setSensorDataList(() => {
        return checkNullArray(response.data, []);
      });
      if (sensorView !== 'all') {
        const filterList = checkNullArray(response.data, []).filter(
          (item) => item.name === sensorView,
        );
        setSensorDataList(() => {
          return filterList;
        });
      }
    });
  };

  return (
    <Grid2Body
      contents={
        <Section className="Main">
          <NavBar
            title={<NavTitle menuCode={'702'} />}
            buttons={
              <>
                <select
                  className="SelectType"
                  style={{ width: '180px' }}
                  value={monitoringReducer.sensorView}
                  onChange={(e) => {
                    dispatch(monitoringActions_setSensorView(e.target.value));
                    getSensorDataList(e.target.value);
                  }}
                >
                  <option value={'all'}>전체</option>
                  {checkNullArray(_sensorList, []).map((sensor, index) => {
                    return (
                      <option key={index + '_sensor'} value={sensor.name}>
                        {sensor.name}
                      </option>
                    );
                  })}
                </select>
              </>
            }
            nav={''}
          />

          <div
            className={
              monitoringReducer.sensorView === 'all'
                ? 'contents'
                : 'contents single'
            }
          >
            {monitoringReducer.sensorView === 'all' ? (
              <table>
                <colgroup>
                  <col width={'200px'} />
                </colgroup>
                <thead>
                  <tr>
                    <th rowSpan={2}></th>
                    <th colSpan={2}>온도</th>
                    <th colSpan={2}>습도</th>
                  </tr>
                  <tr>
                    <th className="current">현재</th>
                    <th className="set">설정</th>
                    <th className="current">현재</th>
                    <th className="set">설정</th>
                  </tr>
                </thead>
                <tbody>
                  {_sensorDataList.map((sensorData, index) => {
                    const data_temp = checkNullArray(
                      sensorData.sensorList,
                      [],
                    ).find((item) => item.type === '온도');
                    const data_mo = checkNullArray(
                      sensorData.sensorList,
                      [],
                    ).find((item) => item.type === '습도');
                    return (
                      <tr key={index + '_sensorData'}>
                        <th>{sensorData.name}</th>
                        <td>
                          {(
                            checkEmptyNull(data_temp?.value, 0) * 1
                          ).toLocaleString()}{' '}
                          <span>℃</span>
                        </td>
                        <td>
                          {(
                            checkEmptyNull(data_temp?.setValue, 0) * 1
                          ).toLocaleString()}{' '}
                          <span>℃</span>
                        </td>
                        <td>
                          {(
                            checkEmptyNull(data_mo?.value, 0) * 1
                          ).toLocaleString()}{' '}
                          <span>%</span>
                        </td>
                        <td>
                          {(
                            checkEmptyNull(data_mo?.setValue, 0) * 1
                          ).toLocaleString()}{' '}
                          <span>%</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <SensorData content={_sensorDataList[0]} />
            )}
          </div>
        </Section>
      }
    />
  );
};

export default TmpHmdData;
